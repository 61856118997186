import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';

import { DateInput } from '~/components/DateInput';
import ToolTip from '~/components/Tooltip';
import { isReviewStartDateDisabled } from '~/pages/Reviews/utils';

import { InputContainer, SubTitle } from '../../../design';
import { DateRow } from '../design';

import type { IReviewIndividualForm } from '../../../types';
import type { UseFormReturn } from 'react-hook-form';

interface StartEndSectionProps {
  formMethods: UseFormReturn<IReviewIndividualForm>;
}

const StartEndSection = ({ formMethods }: StartEndSectionProps) => {
  const { watch, setValue, formState, trigger } = formMethods;
  const { i18n } = useLingui();
  const startDate = watch('settings.startDate');
  const endDate = watch('settings.endDate');

  const onChangeStartDate = (date: Date, isStartDate?: boolean) => {
    const key = isStartDate ? 'settings.startDate' : 'settings.endDate';
    setValue(key, date, { shouldDirty: true });
    if (!isStartDate) {
      setValue('settings.isAutoTimeline', false, { shouldDirty: true });
    }
    formState.errors?.settings?.startDate || formState.errors?.settings?.endDate
      ? trigger('settings')
      : trigger(key);
  };

  const getFormattedDateToString = (date: Date | null) => {
    const datePattern = 'DD-MM-YYYY';

    if (date) {
      return moment(date).format(datePattern);
    }
    return;
  };

  return (
    <div>
      <SubTitle>
        <Trans>Start and end date of the review</Trans>
      </SubTitle>
      <DateRow marginTop="8px" marginBottom="16px">
        <ToolTip
          tooltip={i18n._(
            t`It is not possible to change this date after the review cycle has been published`,
          )}
          disabled={!isReviewStartDateDisabled(watch('fetchedReview'))}
        >
          <InputContainer width="fit-content">
            <DateInput
              value={getFormattedDateToString(startDate)}
              label={i18n._(t`Start date`)}
              onChange={(date) => onChangeStartDate(date as Date, true)}
              error={formState.errors?.settings?.startDate?.message}
              disabled={isReviewStartDateDisabled(watch('fetchedReview'))}
            />
          </InputContainer>
        </ToolTip>
        <DateInput
          value={getFormattedDateToString(endDate)}
          label={i18n._(t`Deadline`)}
          onChange={(date) => onChangeStartDate(date as Date)}
          error={formState.errors?.settings?.endDate?.message}
        />
      </DateRow>
    </div>
  );
};

export { StartEndSection };
