import React from 'react';

import { REVIEW_QUESTION_EVALUATORS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Icon, ICONS } from '~/components/Icon';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import Switch from '~/components/Switch';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import { NominatePeersSection } from './components/NominatePeersSection';
import { StartEndSection } from './components/StartEndSection';
import { TasksSection } from './components/TasksSection';
import { AdvancedSettingsWrapper } from './design';

import useBoolState from '~/hooks/useBoolState';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';

import { Form, Separator, Title, ToggleRow, ToggleText } from '../../design';
import { useReviewTasks } from '../../hooks/useReviewTasks';
import { StepFooter } from '../StepFooter';

import type { IReviewIndividualForm } from '../../types';
import type { UseFormReturn } from 'react-hook-form';

interface TimelineSectionProps {
  formMethods: UseFormReturn<IReviewIndividualForm>;
  sectionState: ISectionState;
  languageState: ILanguageStateReturn;
}

const TimelineSection = ({ formMethods, sectionState, languageState }: TimelineSectionProps) => {
  const $isAdvanceSettingsVisible = useBoolState(false);
  const { watch, setValue } = formMethods;
  const evaluators = watch('evaluators').map(
    (evaluator: { value: REVIEW_QUESTION_EVALUATORS; icon: ICONS; title: string }) =>
      evaluator.value,
  );
  const { i18n } = useLingui();

  const { resetGeneratedDates, handleReEnableAutoGenerate } = useReviewTasks({ formMethods });
  const previouslyEnabled = watch('tasks.isPreviouslyAutoGenerateEnabled');

  const onToggleChange = async (value: boolean) => {
    setValue('settings.isAutoTimeline', value, { shouldDirty: true });
    if (!value) {
      resetGeneratedDates();
      return;
    }
    if (previouslyEnabled) {
      await handleReEnableAutoGenerate();
    }
  };

  return (
    <Form>
      <Title>
        <Trans>Timeline</Trans>
      </Title>
      <StartEndSection formMethods={formMethods} />
      <AdvancedSettingsWrapper isCollapsed={$isAdvanceSettingsVisible.value}>
        {/* eslint-disable-next-line  */}
        <div className="head" onClick={$isAdvanceSettingsVisible.toggle}>
          <div className="title">
            <Trans>Advanced settings</Trans>
          </div>
          <div className="icon">
            <Icon icon={ICONS.BACK} />
          </div>
        </div>
        <div className="content">
          <Separator marginBottom="24px" marginTop="0px" />
          <ToggleRow>
            <Switch onChange={onToggleChange} checked={watch('settings.isAutoTimeline')} />
            <ToolTip
              tooltip={i18n._(
                t`Based on the selected start date, we fill the remaining date fields with recommended dates`,
              )}
              placement={TOOLTIP_PLACEMENTS.BOTTOM}
            >
              <ToggleText>
                <Trans>Automatically generate a timeline based on the start date</Trans>
              </ToggleText>
            </ToolTip>
          </ToggleRow>
          {evaluators.includes(REVIEW_QUESTION_EVALUATORS.PEER) && (
            <NominatePeersSection formMethods={formMethods} languageState={languageState} />
          )}
          <TasksSection formMethods={formMethods} evaluators={evaluators} />
        </div>
      </AdvancedSettingsWrapper>
      <StepFooter
        onNext={() => sectionState.setCurrentSection(++sectionState.currentSection)}
        onPrev={() => sectionState.setCurrentSection(--sectionState.currentSection)}
      />
    </Form>
  );
};

export { TimelineSection };
