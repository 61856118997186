import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const SearchIcon = ({ size, onClick, className, fill }) => (
  <InlineSvg height={size} width={size} onClick={onClick} className={className}>
    <g fill="none" fillRule="evenodd" stroke={fill} strokeWidth="1.5" transform="translate(2 3)">
      <circle cx="7" cy="7" r="6.25" fill="" />
      <path d="M11.5 11.5l7.155 6.028" />
    </g>
  </InlineSvg>
);

SearchIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

SearchIcon.defaultProps = {
  size: 24,
  className: '',
};

export default SearchIcon;
