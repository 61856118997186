import React, { useState } from 'react';
import type { Dispatch } from 'react';

import { t, Trans } from '@lingui/macro';
import { DragDropContext } from 'react-beautiful-dnd';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import RickTextView from '~/components/RickTextView';

import { FocusAreas } from './components/FocusAreas';
import { SkillCategory } from './components/SkillCategory';
import {
  Card,
  Container,
  Description,
  Footer,
  Form,
  Levels,
  SectionHeader,
  SectionTitle,
  SkillDetails,
  SkillTitleAndCategory,
  SummarySection,
  Subtitle,
  Title,
  StyledTableList,
} from './design';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { usePagination } from '~/hooks/usePagination';
import { COLORS } from '~/styles';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';

import type { IGeneralForm } from './types';
import type { IMultiLangString, ISkillCategory } from '@learned/types';
import type { I18n } from '@lingui/core';
import type { UseFormReturn } from 'react-hook-form';

type StepSummaryProps = {
  setCurrentSection: Dispatch<number>;
  formMethods: UseFormReturn<IGeneralForm>;
  onPublish: (data: IGeneralForm) => void;
  onSave: (data: IGeneralForm) => void;
  onFail: () => void;
  skillCategory?: ISkillCategory;
  languageState: ILanguageStateReturn;
  currentStatus: boolean;
};

export enum JOB_SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
  SKILL_LEVEL_HIGH_LOW = 'SKILL_LEVEL_HIGH_LOW',
  SKILL_LEVEL_LOW_HIGH = 'SKILL_LEVEL_LOW_HIGH',
}

const createColumns = (
  getMultiLangString: (multiLangString: Record<string, string> | string) => string,
) => [
  {
    renderCell: ({ name }: { name: IMultiLangString }) => {
      return <span>{getMultiLangString(name)}</span>;
    },
    name: (i18n: I18n) => i18n._(t`Job`),
    accessor: 'name',
    minWidth: '335px',
    sortBy: {
      asc: {
        key: JOB_SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: JOB_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    renderCell: ({ skillLevel }: { skillLevel: { level: number; name: IMultiLangString } }) => {
      return <span>{getMultiLangString(skillLevel.name)}</span>;
    },
    name: (i18n: I18n) => i18n._(t`Required level`),
    accessor: 'skillLevel',
    minWidth: '335px',
    sortBy: {
      asc: {
        key: JOB_SORT_OPTIONS.SKILL_LEVEL_HIGH_LOW,
        label: (i18n: I18n) => i18n._(t`Skill level high - low`),
      },
      desc: {
        key: JOB_SORT_OPTIONS.SKILL_LEVEL_LOW_HIGH,
        label: (i18n: I18n) => i18n._(t`Skill level low - high`),
      },
    },
  },
];

function StepSummary({
  formMethods,
  setCurrentSection,
  skillCategory,
  languageState,
  onPublish,
  onSave,
  onFail,
  currentStatus,
}: StepSummaryProps) {
  const [sortBy, setSortBy] = useState(JOB_SORT_OPTIONS.NAME_A_Z);
  const [search, setSearch] = useState('');
  const getMultiLangString = useMultiLangString();
  const pagination = usePagination(10);
  const { handleSubmit, getValues, watch, formState } = formMethods;
  const { isSubmitting } = formState;

  const jobProfiles = Object.entries(watch('jobProfiles'))
    .map(([index, jobProfiles]) =>
      jobProfiles.map((jp) => ({
        ...jp,
        skillLevel: {
          name: skillCategory?.skillLevels[index as unknown as number],
          level: parseInt(index, 10),
        },
      })),
    )
    .flat();

  const goToStepDetails = () => setCurrentSection(0);
  const goToStepFocusAreas = () => setCurrentSection(1);
  const goToStepJobProfiles = () => setCurrentSection(2);

  return (
    <Container>
      <Form>
        <Title>
          <Trans>Final check</Trans>
        </Title>
        <Subtitle>
          <Trans>
            Congratulations! You have successfully created a new skill in your company. Take a
            moment to review the details below. Once you are satisfied, you can proceed to publish
            the skill.
          </Trans>
        </Subtitle>
        <Footer>
          {currentStatus === false && (
            <Button
              type="button"
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.SECONDARY}
              label={<Trans>Save as draft</Trans>}
              disabled={isSubmitting}
              onClick={handleSubmit(onSave, onFail)}
            />
          )}
          <Button
            type="button"
            size={ButtonSize.BIG}
            variant={ButtonVariant.PRIMARY}
            disabled={isSubmitting}
            label={currentStatus === true ? <Trans>Save skill</Trans> : <Trans>Publish</Trans>}
            onClick={handleSubmit(onPublish, onFail)}
          />
        </Footer>
      </Form>
      <Card>
        <SummarySection>
          <SectionHeader>
            <SectionTitle>
              <Icon icon={ICONS.VALID} size={ICON_SIZES.MEDIUM} fill={COLORS.COMPANY} />
              <Trans>1. Details</Trans>
            </SectionTitle>
            <Button
              onClick={goToStepDetails}
              icon={ICONS.EDIT_PENCIL}
              label={<Trans>Edit</Trans>}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              color={COLORS.ICONS_PRIMARY}
            />
          </SectionHeader>
          <SkillDetails>
            <SkillTitleAndCategory>
              <Title>{getMultiLangString(convertLanguageValue(getValues('name')))}</Title>

              <SkillCategory>
                {skillCategory ? (
                  getMultiLangString(skillCategory.name)
                ) : (
                  <Trans>No category selected</Trans>
                )}
              </SkillCategory>
            </SkillTitleAndCategory>
            <Description>
              <h1>
                <Trans>Description</Trans>
              </h1>
              {/* @ts-ignore */}
              <RickTextView
                color={COLORS.TEXT_MAIN}
                html={getMultiLangString(convertLanguageValue(getValues('description')))}
              />
            </Description>
          </SkillDetails>
        </SummarySection>
        <SummarySection>
          <SectionHeader>
            <SectionTitle>
              <Icon icon={ICONS.VALID} size={ICON_SIZES.MEDIUM} fill={COLORS.COMPANY} />
              <Trans>2. Levels & Focus area&apos;s</Trans>
            </SectionTitle>
            <Button
              onClick={goToStepFocusAreas}
              icon={ICONS.EDIT_PENCIL}
              label={<Trans>Edit</Trans>}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              color={COLORS.ICONS_PRIMARY}
            />
          </SectionHeader>
          <div style={{ marginBottom: '64px' }}>
            <Levels>
              <DragDropContext onDragEnd={() => undefined}>
                {skillCategory?.skillLevels.map((levelName, index) => (
                  <FocusAreas
                    formMethods={formMethods}
                    levelName={levelName}
                    key={index}
                    index={index}
                    languageState={languageState}
                    readonly
                  />
                ))}
              </DragDropContext>
            </Levels>
          </div>
        </SummarySection>
        <SummarySection noGap>
          <SectionHeader>
            <SectionTitle>
              <Icon icon={ICONS.VALID} size={ICON_SIZES.MEDIUM} fill={COLORS.COMPANY} />
              <Trans>3. Jobs that require this skill or KPI</Trans>
            </SectionTitle>
            <Button
              onClick={goToStepJobProfiles}
              icon={ICONS.EDIT_PENCIL}
              label={<Trans>Edit</Trans>}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              color={COLORS.ICONS_PRIMARY}
            />
          </SectionHeader>
          <StyledTableList
            columns={createColumns(getMultiLangString)}
            sortProps={{
              sortBy,
              setSortBy,
            }}
            data={jobProfiles
              .filter((jp) =>
                getMultiLangString(jp.name).toLowerCase().includes(search.toLowerCase()),
              )
              .sort((a, b) => {
                const nameA = getMultiLangString(a.name).toLowerCase();
                const nameB = getMultiLangString(b.name).toLowerCase();

                if (sortBy === JOB_SORT_OPTIONS.NAME_Z_A) {
                  return nameB.localeCompare(nameA);
                } else if (sortBy === JOB_SORT_OPTIONS.SKILL_LEVEL_HIGH_LOW) {
                  return a.skillLevel.level - b.skillLevel.level;
                } else if (sortBy === JOB_SORT_OPTIONS.SKILL_LEVEL_LOW_HIGH) {
                  return b.skillLevel.level - a.skillLevel.level;
                } else {
                  return nameA.localeCompare(nameB);
                }
              })}
            paginationProps={pagination}
            filtersProps={{
              filters: {
                search,
                setSearch,
              },
              isFiltered: true,
            }}
          />
        </SummarySection>
      </Card>
    </Container>
  );
}

export { StepSummary };
