import type { IGetServerRoute, IServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

import type { IReviewSummary, IUserReview } from '@learned/types';

export function createReviewSummary(data: {
  userReview: IUserReview['id'];
  content: IReviewSummary['content'];
}) {
  return cloudRequest(
    serverRoutes.reviewSummaries.createOrUpdate as IServerRoute,
    {},
    data,
  ) as Promise<{
    code: number;
    data: { reviewSummary: IReviewSummary };
  }>;
}

export function getReviewSummaries({
  userReviewId,
  createdBy,
}: {
  userReviewId: string;
  createdBy?: string[];
}) {
  return cloudRequest(
    (serverRoutes.userReviews.getReviewSummaries as IGetServerRoute)(userReviewId),
    {},
    { createdBy },
  ) as Promise<{
    code: number;
    data: { reviewSummaries: IReviewSummary[] };
  }>;
}
