import React from 'react';

import { REVIEW_RATING_TYPE } from '@learned/constants';
import { Trans } from '@lingui/macro';

function RoleName({ ratingType }: { ratingType: REVIEW_RATING_TYPE }) {
  switch (ratingType) {
    case REVIEW_RATING_TYPE.SELF:
      return <Trans>Self</Trans>;
    case REVIEW_RATING_TYPE.COACH:
      return <Trans>Coach</Trans>;
    case REVIEW_RATING_TYPE.PEER:
    case REVIEW_RATING_TYPE.PEER_EMAIL:
      return <Trans>Peer</Trans>;
  }
}

export { RoleName };
