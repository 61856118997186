import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getUserPath(pathId, userId) {
  return cloudRequest(serverRoutes.userPaths.getUserPath(pathId), {
    userId,
  });
}

export function getUserPaths(userId) {
  return cloudRequest(serverRoutes.userPaths.getUserPaths, { userId });
}
