import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';
import Editor from '~/components/Editor';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import IconButton from '~/components/IconButton';
import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';
import RTFeedbackCard from '~/pages/RTFeedbacks/components/RTFeedbackCard';

import expandIcon from '~/assets/ic-keyboard-arrow-down.svg';
import collapseIcon from '~/assets/ic-keyboard-arrow-up.svg';

import { RT_FEEDBACK_TYPES, TALKING_POINT_TYPES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { getUser, getUsers } from '~/selectors/baseGetters';
import { COLOR_PALETTE, COLORS } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledEditor = styled(Editor)`
  .ql-editor {
    padding: 12px 54px 12px 12px;
  }
`;

const IconButtonWrapper = styled.div`
  position: absolute;
  top: 54px;
  right: 8px;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  border-radius: 6px;
  border: solid 1px #dbe1ef;
  background-color: #fff;
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer !important;
`;

const Header = styled.div`
  flex: 1;
`;

const CardBody = styled.div`
  padding: 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
`;

const CheckBoxContainer = styled.div`
  width: 56px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-left: solid 1px #dbe1ef;
`;

const NormalTitleText = styled.span`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
`;

const BoldTitleText = styled.span`
  font-weight: 600;
`;

const CheckBoxWrapper = styled.div``;

const RTFBItem = ({
  item,
  i18n,
  user,
  disabledItems,
  selectedItems,
  toggleItem,
  isExpanded = false,
  onShareFeedback,
}) => {
  const $isExpanded = useBoolState(isExpanded);
  const users = useSelector(getUsers);
  const disabled = disabledItems.includes(item.description);

  const getHeader = (item) => {
    const isAsked = !item.originalRTFeedback && item.type === RT_FEEDBACK_TYPES.ASK_FEEDBACK.key;
    const isProactiveBy =
      item.type === RT_FEEDBACK_TYPES.GIVE_FEEDBACK.key && item.createdBy === user.id;
    const isProactiveFor =
      item.type === RT_FEEDBACK_TYPES.GIVE_FEEDBACK.key && item.createdFor === user.id;
    const isAskedFor = item.originalRTFeedback && item.type === RT_FEEDBACK_TYPES.ASK_FEEDBACK.key;

    const RTFeedbackUsersName = isAsked
      ? [...(item.users.map((user) => getUserFullName(users[user])) || []), ...(item.emails || [])]
      : isAskedFor
      ? getUserFullName(users[item.createdBy])
      : isProactiveFor
      ? getUserFullName(users[item.createdBy])
      : getUserFullName(users[item.createdFor]);

    let titleText;
    if (isAsked) {
      titleText = (
        <NormalTitleText>
          {`${i18n._(t`You have asked feedback from`)} `}
          {RTFeedbackUsersName.length > 1 ? (
            RTFeedbackUsersName.map((RTFBUserName) => (
              <>
                {` ${i18n._(t`and`)} `}
                <BoldTitleText>{RTFBUserName}</BoldTitleText>
              </>
            ))
          ) : (
            <BoldTitleText>{RTFeedbackUsersName[0]}</BoldTitleText>
          )}
        </NormalTitleText>
      );
      // i18n._(t`You have asked feedback from ${RTFeedbackUsersName[0]}`);
    } else if (isProactiveFor) {
      titleText = (
        <NormalTitleText>
          <BoldTitleText>{RTFeedbackUsersName}</BoldTitleText>
          {` ${i18n._(t`has provided you with feedback`)}`}
        </NormalTitleText>
      );
    } else if (isProactiveBy) {
      titleText = (
        <NormalTitleText>
          {`${i18n._(t`You have provided feedback to`)} `}
          <BoldTitleText>{RTFeedbackUsersName}</BoldTitleText>
        </NormalTitleText>
      );
    } else {
      titleText = (
        <NormalTitleText>
          <BoldTitleText>{RTFeedbackUsersName}</BoldTitleText>
          {` ${i18n._(t` has asked you for feedback`)}`}
        </NormalTitleText>
      );
    }

    return titleText;
  };

  const createTP = (item) => ({
    type: TALKING_POINT_TYPES.RTFEEDBACK.key,
    name: item.description || item.feedback,
    target: item.id,
    targetUser: item.createdFor,
  });

  return (
    <CardWrapper>
      <CardBody>
        <CardHeader onClick={$isExpanded.toggle}>
          <Header>{getHeader(item)}</Header>
          <IconButton size={40} noBorder={true}>
            <SvgIcon
              width="18px"
              height="18px"
              url={$isExpanded.value ? collapseIcon : expandIcon}
            />
          </IconButton>
        </CardHeader>
        {$isExpanded.value && (
          <RTFeedbackCard
            RTFeedback={item}
            isHideCardHeader
            isNoCardPadding
            onShareFeedback={onShareFeedback}
          />
        )}
      </CardBody>
      <CheckBoxContainer>
        <CheckBoxWrapper
          onClick={() => {
            !disabled && toggleItem(createTP(item));
          }}
        >
          <CheckBox checked={!isEmpty(selectedItems[item.id])} size="24px" />
        </CheckBoxWrapper>
      </CheckBoxContainer>
    </CardWrapper>
  );
};

const TabRTFeedback = ({
  i18n,
  items,
  user,
  selectedItems,
  disabledItems,
  toggleItem,
  createRTFB,
  loading,
  expandedRTFB,
  onRTFeedbackUpdate,
}) => {
  const [newRTFBText, setNewRTFBText] = useState('');
  const currentUser = useSelector(getUser);

  return (
    <Wrapper>
      <ShowSpinnerIfLoading loading={loading}>
        {user.id !== currentUser.id && (
          <div style={{ position: 'relative' }}>
            <StyledEditor
              value={newRTFBText}
              placeholder={i18n._(t`Your feedback to ${user.firstName || user.email}`)}
              compact={true}
              small={true}
              onChange={setNewRTFBText}
            />

            <IconButtonWrapper>
              <IconButton
                size={48}
                onClick={async () => {
                  await createRTFB(newRTFBText);
                  setNewRTFBText('');
                }}
                noBorder={true}
                disabled={!newRTFBText}
              >
                <div>
                  <Icon
                    icon={ICONS.ADD_PLUS}
                    size={ICON_SIZES.LARGE}
                    color={!newRTFBText ? COLOR_PALETTE.GRAY_MIDDLE : 'var(--company-color)'}
                  />
                </div>
              </IconButton>
            </IconButtonWrapper>
          </div>
        )}
        {!isEmpty(items) ? (
          items.map((item) => {
            return (
              <RTFBItem
                key={item.id}
                item={item}
                i18n={i18n}
                user={currentUser}
                selectedItems={selectedItems}
                disabledItems={disabledItems}
                toggleItem={toggleItem}
                isExpanded={item.id === expandedRTFB}
                onShareFeedback={({ feedback }) => onRTFeedbackUpdate(item.id, { feedback })}
              />
            );
          })
        ) : (
          <Placeholder
            title={i18n._(t`No feedback available`)}
            subTitle={i18n._(
              user.id === currentUser.id
                ? t`Feedback related to you will be shown here`
                : t`Feedback between you and this member will show here`,
            )}
            Icon={() => <Icon icon={ICONS.CHAT} height={50} width={50} color={COLORS.BG_PAGE} />}
          />
        )}
      </ShowSpinnerIfLoading>
    </Wrapper>
  );
};

export default withI18n()(TabRTFeedback);
