import React, { useEffect, useRef } from 'react';

import { REVIEW_THEME_STATUS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { DebouncedFunc, size } from 'lodash';
import { Controller, UseFormReturn } from 'react-hook-form';

import Editor from '~/components/Editor';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import { IconOld } from '~/components/IconOld';
import { Input } from '~/components/Input';
import Popover from '~/components/Popover';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { IconColorPicker } from '~/pages/UpdateTheme/components/IconColorPicker';
import { ERRORS } from '~/pages/UpdateTheme/validation';

import useBoolState from '~/hooks/useBoolState';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';

import {
  DescriptionWrapper,
  EditIconColorButton,
  Error,
  Footer,
  IconPreview,
  IconPreviewWrapper,
  InputContainer,
  Label,
  Row,
  RowSwitch,
  SubmitButton,
  Title,
  Form,
  LabelWrapper,
  SubText,
} from '../design';

export interface IProps {
  onNext: () => void;
  isLoading: boolean;
  formMethods: UseFormReturn<any>;
  setCurrentSection: (index: number) => void;
  languageState: ILanguageStateReturn;
  handleUpdateTheme: () => void;
  triedToSubmit: boolean;
  debounceUpdateTheme: DebouncedFunc<() => Promise<void>>;
}

const CreateThemeStepOne = ({
  onNext,
  isLoading,
  languageState,
  formMethods,
  handleUpdateTheme,
  triedToSubmit,
  debounceUpdateTheme,
}: IProps) => {
  const { i18n } = useLingui();
  const popoverRef = useRef();
  const $isDisableNextButton = useBoolState(false);

  const {
    register,
    watch,
    control,
    unregister,
    formState: { errors, isValid },
    trigger,
    setError,
    getValues,
    clearErrors,
  } = formMethods;

  useEffect(() => {
    if (!watch('icon')) {
      setError('icon', { type: 'required' });
    } else if (!watch('iconColor')) {
      setError('iconColor', { type: 'required' });
    } else {
      clearErrors(['icon', 'iconColor']);
    }
  }, [clearErrors, setError, watch]);

  const nameFieldArray = useMultiLangFieldArray({
    name: 'name',
    control,
    unregister,
    languageState,
  });

  const descriptionFieldArray = useMultiLangFieldArray({
    name: 'description',
    control,
    unregister,
    languageState,
  });

  const handleOnChangeEvent = async (
    onChange: (...event: any[]) => void,
    args: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChange(args);
    await trigger(['name', 'icon']);
    debounceUpdateTheme();
  };

  const iconErrorMessage =
    !!errors.icon && triedToSubmit ? i18n._(t`Icon field is required`) : undefined;

  useEffect(() => {
    const data = getValues();
    if (data.status === REVIEW_THEME_STATUS.PUBLISHED && !data.questions.length) {
      $isDisableNextButton.off();
    } else {
      $isDisableNextButton.set(!!size(errors) || !isValid);
    }
  }, [$isDisableNextButton, errors, getValues, isValid]);

  return (
    <Form>
      <Title>{i18n._(t`General`)}</Title>
      <ShowSpinnerIfLoading loading={isLoading}>
        <Row>
          <InputContainer>
            <Label>
              <Trans>Name</Trans>
            </Label>
            {nameFieldArray.fields.map((field: any) => {
              const primaryLangError =
                errors.name?.message === ERRORS.missingPrimaryLang &&
                field.locale === languageState.companyPrimaryLanguage.locale;
              return (
                <Controller
                  key={field.id}
                  {...register(`name.${field.index}.value`)}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Input
                        error={
                          primaryLangError && triedToSubmit
                            ? i18n._(t`Name field is required`)
                            : undefined
                        }
                        value={value}
                        onChange={(args) => handleOnChangeEvent(onChange, args)}
                        placeholder={i18n._(t`Example: Business performance`)}
                        key={field.id}
                        width="281px"
                        leftIcon={
                          size(languageState.languages) > 1
                            ? getUnicodeFlagIcon(
                                field.locale.substring(field.locale.indexOf('_') + 1),
                              )
                            : undefined
                        }
                        maxLength={60}
                      />
                    );
                  }}
                />
              );
            })}
          </InputContainer>
          <div>
            <div>
              <IconPreviewWrapper>
                <IconPreview colors={watch('iconColor')?.split('-')}>
                  {/* @ts-ignore */}
                  <IconOld name={watch('icon')} width={32} height={32} />
                </IconPreview>
                {/* @ts-ignore */}
                <Popover
                  ref={popoverRef}
                  trigger="click"
                  content={<IconColorPicker formMethods={formMethods} />}
                  minWidth={480}
                  maxWidth={480}
                  boxShadow="0 8px 8px 0 rgba(30, 8, 99, 0.21)"
                  placement="bottom"
                  $border={10}
                  noBorder
                  appendTo={document.body}
                  onHide={() => handleUpdateTheme()}
                >
                  <EditIconColorButton type="button">
                    {/* <Pencil2Icon size={20} /> */}
                    <Icon icon={ICONS.EDIT_PENCIL} size={ICON_SIZES.MEDIUM} />
                    <Trans>Edit icon & color</Trans>
                  </EditIconColorButton>
                </Popover>
              </IconPreviewWrapper>
            </div>
            <div>{iconErrorMessage && <Error>{iconErrorMessage}</Error>}</div>
          </div>
        </Row>
        <DescriptionWrapper>
          <LabelWrapper>
            <Label>
              <Trans>Description</Trans>
            </Label>
            <SubText>
              <Trans>(optional)</Trans>
            </SubText>
          </LabelWrapper>
          <InputContainer>
            {descriptionFieldArray.fields.map((field: any) => (
              <Controller
                key={field.id}
                {...register(`description.${field.index}.value`)}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Editor
                    minHeight={'250px'}
                    value={value}
                    compact
                    // @ts-ignore
                    onChange={(args) => {
                      onChange(args);
                      debounceUpdateTheme();
                    }}
                    big
                    leftIcon={
                      size(languageState.languages) > 1
                        ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                        : undefined
                    }
                  />
                )}
              />
            ))}
          </InputContainer>
        </DescriptionWrapper>
        <RowSwitch />
        <Footer>
          <SubmitButton type="button" onClick={onNext} disabled={$isDisableNextButton.value}>
            <span>{i18n._(t`Next`)}</span>
            <IconOld name="ChevronForward" width={16} height={16} className="icon" />
          </SubmitButton>
        </Footer>
      </ShowSpinnerIfLoading>
    </Form>
  );
};

export { CreateThemeStepOne };
