import React, { useState } from 'react';

import { ProductName, ProductStatus } from '@learned/constants';
import { Trans } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS, ICON_SIZES } from '~/components/Icon';
import { ProductSettingsModal } from '~/components/Modals/ProductSettingsModal';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import { COLORS } from '~/styles';

import {
  Wrapper,
  Title,
  BottomStatement,
  ProductRow,
  ProductInfoBlock,
  ProductColumn,
  OnStatus,
  StyledProductName,
  TrialLabel,
  SettingsBlock,
  ButtonLabel,
  SettingsButton,
  IconWrapper,
  InfoIcon,
  ViewAllText,
  Link,
} from '../design';

interface ProductProps {
  key: ProductName;
  name: string;
  status: ProductStatus;
  lastStatusChanged: string;
  settings?: {};
  daysToEnd: number;
}

interface ComponentProps {
  products: ProductProps[];
}

const ActiveProducts = ({ products }: ComponentProps) => {
  const history = useHistory();
  const [productSettingsModal, setProductSettingsModal] = useState<Pick<
    ProductProps,
    'key' | 'settings'
  > | null>(null);

  const handleRedirect = () => {
    // @ts-ignore
    const route = routes.SETTINGS_MODULES.build({ role: ROLES.ADMIN }, { isBackPath: true });
    history.push(route);
  };

  return (
    <Wrapper>
      <Title>
        <Trans>Products</Trans>
      </Title>
      {products.map((product, index) => (
        <ProductRow key={index}>
          <ProductInfoBlock>
            <ProductColumn>
              <OnStatus>
                <Trans>ON</Trans>
              </OnStatus>
              <StyledProductName>
                <Trans>{product.name}</Trans>
              </StyledProductName>
              {product.status !== 'active' ? (
                <TrialLabel backgroundColor={COLORS.INFO_LIGHT} color={COLORS.COMPANY}>
                  <Trans>
                    Trial active: {product.daysToEnd < 0 ? 0 : product.daysToEnd} day left
                  </Trans>
                </TrialLabel>
              ) : (
                <></>
              )}
            </ProductColumn>
          </ProductInfoBlock>
          {!isEmpty(product.settings) && (
            <SettingsBlock>
              <ButtonLabel>
                <Trans>Settings</Trans>
              </ButtonLabel>
              {/* @ts-ignore */}
              <SettingsButton
                icon={ICONS.SETTINGS}
                variant={ButtonVariant.ICON}
                size={ButtonSize.MEDIUM}
                onClick={() =>
                  setProductSettingsModal({
                    key: product.key,
                    settings: product.settings,
                  })
                }
              />
            </SettingsBlock>
          )}
        </ProductRow>
      ))}
      <BottomStatement>
        <IconWrapper>
          <InfoIcon icon={ICONS.INFO_3} size={ICON_SIZES.LARGE} />
        </IconWrapper>
        <ViewAllText>
          <Trans>
            View all available products on the <Link onClick={handleRedirect}>Plan page</Link>
          </Trans>
        </ViewAllText>
      </BottomStatement>
      {productSettingsModal && (
        <ProductSettingsModal
          name={productSettingsModal.key}
          closeModal={() => setProductSettingsModal(null)}
        />
      )}
    </Wrapper>
  );
};

export { ActiveProducts };
