import styled, { css } from 'styled-components';

import Editor from '~/components/Editor';

import { COLORS } from '~/styles';

export const FooterRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  width: 100%;
  margin: 30px 0;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.TEXT_HOVER};
`;

export const Description = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
`;

export const StyledTextArea = styled(Editor)`
  .ql-toolbar {
    display: none;
  }
`;

export const ProductsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 45px;
  gap: 16px;
`;

export const ProductWrapper = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  gap: 20px;
  height: 48px;
  border: 1px solid;
  border-color: ${COLORS.BORDERS};
  color: ${COLORS.TEXT_HOVER};
  border-radius: 6px;
  padding: 0 19px;
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: ${COLORS.COMPANY};
        opacity: 0.1;
      }
      border-color: ${COLORS.COMPANY};
    `}

  & .name {
    font-size: 14px;
    flex-grow: 1;
  }
`;
