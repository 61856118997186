import { LANGUAGES } from '~/constants';

export default (focusArea, lang = 'en', full) => {
  const isEN = lang === 'en';
  let beh = '';
  if (full) {
    beh =
      !isEN && focusArea.exampleBehaviourNL
        ? focusArea.exampleBehaviourNL
        : focusArea.exampleBehaviour || '';
  }

  return `${
    lang === LANGUAGES.NL && focusArea.name?.nl_NL
      ? focusArea.name?.nl_NL
      : focusArea.name?.en_GB || ''
  } ${beh}`.trim();
};
