import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import IconButton from '~/components/IconButton';
import ActionsPopover from '~/components/UI/ActionsPopover';

const Wrapper = styled.div`
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  background-color: transparent;
  padding: 0;
  position: relative;
`;

/**
 * @deprecated since version 3.0 {@see ContextMenu}
 */
class IconMenu extends PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        action: PropTypes.func,
      }),
    ).isRequired,
    placement: PropTypes.oneOf([
      'top',
      'top-start',
      'top-end',
      'right',
      'right-start',
      'right-end',
      'bottom',
      'bottom-start',
      'bottom-end',
      'left',
      'left-start',
      'left-end',
      'auto',
      'auto-start',
      'auto-end',
    ]),
    size: PropTypes.number,
    iconSize: PropTypes.number,
    icon: PropTypes.node,
    iconColor: PropTypes.string,
    iconWidth: PropTypes.number,
  };

  static defaultProps = {
    placement: 'bottom-end',
    size: 24,
    iconSize: 18,
    iconWidth: 17,
    iconColor: 'var(--company-color)',
  };

  render = () => {
    const { items, icon, size, disabled, width, className, iconColor, placement } = this.props;

    if (!items || !items.length) {
      return null;
    }

    return (
      <Wrapper $size={size} className={className}>
        <ActionsPopover
          isRows
          items={items}
          disabled={disabled}
          contentWidth={width}
          placement={placement}
        >
          <IconButton
            tabIndex={0}
            noBorder
            color={iconColor}
            size={size}
            disabled={disabled}
            onClick={(e) => e.stopPropagation()}
          >
            {icon || <Icon size={ICON_SIZES.SMALL} icon={ICONS.KEBAB_MENU} color={iconColor} />}
          </IconButton>
        </ActionsPopover>
      </Wrapper>
    );
  };
}

export default IconMenu;
