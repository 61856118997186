import React from 'react';

import Tippy from '@tippyjs/react';
import styled from 'styled-components';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

import { COLORS, COLOR_PALETTE, COLOR_SET } from '~/styles';

const OptionListItem = styled.div`
  box-sizing: border-box;
  padding: 11px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  &:hover {
    background-color: ${COLORS.BG_PAGE};
  }
  &:last-child {
    border-bottom: none;
  }
`;

const OptionListItemLabel = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.TEXT_BLACK};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    color: ${COLOR_SET.MIDNICHT_BLUE};
  }
`;

const tippyOpts = { modifiers: [{ name: 'computeStyles', options: { gpuAcceleration: false } }] };

const SearchListItem = React.memo((props) => {
  const { option, onChange, showTooltip = false } = props;

  return (
    <OptionListItem onClick={() => onChange(option)}>
      <Tippy
        trigger="mouseenter"
        theme="light"
        popperOptions={tippyOpts}
        disabled={!showTooltip}
        content={option.name}
      >
        <OptionListItemLabel>{option.name}</OptionListItemLabel>
      </Tippy>
    </OptionListItem>
  );
});

export default SearchListItem;
