import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import Modal from '~/components/Modal';
import SvgIcon from '~/components/SvgIcon';

import ActivityIcon from '~/assets/activity-inactive.svg';
import CloseIcon from '~/assets/ic-close-18-px.svg';
import PathIcon from '~/assets/path-symbol-inactive.svg';

import { COLORS, COLOR_PALETTE, COLOR_SET } from '~/styles';

export interface ICreateLearningLibraryItemModalProps {
  onClose: () => void;
  onOpenCreateLibaryActivityModal: () => void;
  onOpenCreatePathFlow: () => void;
}

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  & .closeButton {
    position: absolute;
    top: 10px;
    left: 0px;
    cursor: pointer;
  }

  & .title {
    color: ${COLOR_PALETTE.BLACK};
    margin: 8px 0 20px;
  }
`;

const Column = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 214px;
  border-radius: 6px;
  border: solid 1px #dae4f7;
  background-color: ${COLOR_PALETTE.WHITE};
  padding-bottom: 25px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px var(--company-color);
    background-color: ${COLORS.BG_PAGE};
  }

  & .main {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`;

const IconBlock = styled.div<{
  $colors: string[];
}>`
  width: 100%;
  min-height: 64px;
  box-sizing: border-box;
  padding: 8px 11px;
  background: ${({ $colors }) =>
    $colors.length ? `linear-gradient(to bottom, ${$colors[0]}, ${$colors[1]})` : 'unset'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SubTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: center;
  padding: 0 15px;
  color: ${COLORS.TEXT_SECONDARY};
  margin-bottom: 23px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${COLOR_SET.MIDNICHT_BLUE};
  margin-top: 24px;
  margin-bottom: 3px;
`;

const ContinueButton = styled.button`
  width: 113px;
  padding: 8px 18px 7px 22px;
  background-color: var(--company-color);
  border-radius: 100px;
  color: ${COLORS.TEXT_WHITE};
  cursor: pointer;
`;

function CreateLearningLibraryItemModal({
  onClose,
  onOpenCreateLibaryActivityModal,
  onOpenCreatePathFlow,
}: ICreateLearningLibraryItemModalProps) {
  return (
    <Modal
      contentStyles={{ padding: '15px 24px 44px 24px' }}
      width={500}
      hideFooter
      isHideHeader
      showDivider={false}
    >
      <Header>
        <button onClick={onClose} className="closeButton">
          <SvgIcon
            url={CloseIcon}
            width={'17px'}
            height={'17px'}
            isDefaultColor
            defaultColor={COLOR_PALETTE.BLACK}
          />
        </button>
        <span className="title">
          <Trans>Create a new learning</Trans>
        </span>
      </Header>
      <Row>
        <Column
          onClick={() => {
            onOpenCreateLibaryActivityModal();
            onClose();
          }}
        >
          <IconBlock $colors={['#ddfcfe', '#b8f8fc']}>
            <SvgIcon
              width="12px"
              height="12px"
              url={ActivityIcon}
              isDefaultColor
              defaultColor={COLOR_PALETTE.DARK_GRAY}
            />
          </IconBlock>
          <div className="main">
            <Title>
              <Trans>Activity</Trans>
            </Title>
            <SubTitle>
              <Trans>
                Create a single activity. E.g watching a video, reading a book or attending a
                training.
              </Trans>
            </SubTitle>
            <ContinueButton>
              <Trans>Continue</Trans>
            </ContinueButton>
          </div>
        </Column>
        <Column
          onClick={() => {
            onOpenCreatePathFlow();
            onClose();
          }}
        >
          <IconBlock $colors={['#e6ddfe', '#c8b8fc']}>
            <SvgIcon
              width="22px"
              height="12px"
              url={PathIcon}
              isDefaultColor
              defaultColor={COLOR_PALETTE.DARK_GRAY}
            />
          </IconBlock>
          <div className="main">
            <Title>
              <Trans>Path</Trans>
            </Title>
            <SubTitle>
              <Trans>
                A learning path consists of multiple activities. Add existing or custom activities.
              </Trans>
            </SubTitle>
            <ContinueButton>
              <Trans>Continue</Trans>
            </ContinueButton>
          </div>
        </Column>
      </Row>
    </Modal>
  );
}

export { CreateLearningLibraryItemModal };
