import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { REVIEW_QUESTION_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import type { IconNames } from '~/components/IconOld';
import { MultiLayerList } from '~/components/MultiLayerList';
import { TablePlaceholder } from '~/components/TablePlaceholder';

import {
  Content,
  Header,
  ModalFooter,
  SearchFieldWrapper,
  StyledModal,
  SubTitle,
  Title,
} from './design';

import useDebounce from '~/hooks/useDebounce';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { usePagination } from '~/hooks/usePagination';
import { importQuestions } from '~/services/reviewTemplates';
import { getReviewThemes } from '~/services/reviewThemes';

import type { IReviewTheme, IReviewQuestion } from '@learned/types';

type PopulatedReviewTheme = Omit<IReviewTheme, 'questions'> & {
  questions: IReviewQuestion[];
  showQuestions: boolean;
};

const ANSWER_TYPES: Record<REVIEW_QUESTION_TYPES, { key: string; icon: ICONS }> = {
  [REVIEW_QUESTION_TYPES.TEXT]: {
    key: REVIEW_QUESTION_TYPES.TEXT,
    icon: ICONS.TEXT_ANSWER,
  },
  [REVIEW_QUESTION_TYPES.CUSTOM_SKILL]: {
    key: REVIEW_QUESTION_TYPES.CUSTOM_SKILL,
    icon: ICONS.CUSTOM_SKILL,
  },
  [REVIEW_QUESTION_TYPES.SKILL_CATEGORY]: {
    key: REVIEW_QUESTION_TYPES.SKILL_CATEGORY,
    icon: ICONS.SKILL_CATEGORY,
  },
  [REVIEW_QUESTION_TYPES.GOAL_PLAN]: {
    key: REVIEW_QUESTION_TYPES.GOAL_PLAN,
    icon: ICONS.PLAN_GOALS,
  },
  [REVIEW_QUESTION_TYPES.RATING]: {
    key: REVIEW_QUESTION_TYPES.RATING,
    icon: ICONS.RATING,
  },
};

function ImportQuestionsModal({
  questionsToHide,
  onClose,
  index,
  createNewQuestion,
  reviewTemplateId,
  onSubmit,
}: {
  questionsToHide: string[];
  reviewTemplateId: string;
  index?: number;
  languageState: ILanguageStateReturn;
  onClose: () => void;
  onSubmit: () => void;
  createNewQuestion: (index?: number) => void;
}) {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const [search, setSearch] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const debouncedSearch = useDebounce(search, 300);

  const [themes, setThemes] = useState<PopulatedReviewTheme[]>([]);
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const { pagination, changePagination } = usePagination(10);
  const questionsFilter = useCallback(
    (question) => !questionsToHide.includes(question.id),
    [questionsToHide],
  );

  useEffect(() => {
    let mounted = true;
    const getThemes = async () => {
      setLoading(true);
      const result: {
        data: { reviewThemes: Record<string, PopulatedReviewTheme>; total: number };
      } = await getReviewThemes(
        {
          search: debouncedSearch,
          skip: pagination.skip,
          limit: pagination.limit,
          onlyWithQuestions: true,
        },
        ['questions'],
      );
      setLoading(false);
      if (!mounted) {
        return;
      }

      setThemes(
        Object.values(result.data.reviewThemes).map((theme) => ({
          ...theme,
          questions: theme.questions.filter(questionsFilter),
          showQuestions: false,
        })),
      );
      setTotalCount(result.data.total);
    };

    getThemes();

    return () => {
      mounted = false;
    };
  }, [questionsFilter, debouncedSearch, pagination.skip, pagination.limit]);

  const questionIds: string[] = [];
  themes.forEach((theme) => {
    theme.questions.forEach((q) => {
      if (!questionsToHide.includes(q.id)) {
        questionIds.push(q.id);
      }
    });
  });

  const handleOnSubmit = async () => {
    const result = await importQuestions(reviewTemplateId, {
      position: index,
      questions: selectedQuestions,
    });
    if (result.status === 'success') {
      onSubmit();
    }
  };

  return (
    <StyledModal minWidth={0} borderRadius={6}>
      <Header>
        <div>
          <Title>
            <Trans>Import questions</Trans>
          </Title>
          <SubTitle>
            <Trans>Select the theme(s) you want to cover in this review template.</Trans>
          </SubTitle>
        </div>
        <Button variant={ButtonVariant.CLOSE} size={ButtonSize.MEDIUM} onClick={onClose} />
      </Header>
      <Content>
        <SearchFieldWrapper
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.currentTarget.value)}
          value={search}
          placeholder={i18n._(t`Search...`)}
          style={{
            borderRadius: '10rem',
            fontSize: '14px',
            fontWeight: 600,
          }}
        />
        <Button
          label={i18n._(t`Create new questions`)}
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          onClick={(e) => {
            onClose();
            createNewQuestion(index);
            e.preventDefault();
          }}
        />
      </Content>
      {totalCount > 0 ? (
        <MultiLayerList
          columnName={i18n._(t`Themes & questions`)}
          selectedCounterLabel={i18n._(t`selected questions`)}
          counterLabel={i18n._(t`questions`)}
          toggles={{
            hideItems: i18n._(t`Hide all questions`),
            showItems: i18n._(t`Show all questions`),
          }}
          data={themes.map((theme) => ({
            id: theme.id,
            icon: (theme?.icon || 'Chatbubble') as IconNames,
            iconColor: theme?.iconColor || undefined,
            name: getMultiLangString(theme.name),
            showSubItems: theme.showQuestions,
            subItems: theme.questions?.map((question) => ({
              id: question.id,
              name: getMultiLangString(question.name),
              icon: ANSWER_TYPES[question.type as REVIEW_QUESTION_TYPES].icon,
            })),
          }))}
          selectedItems={selectedQuestions}
          setSelectedItems={setSelectedQuestions}
          paginationProps={{
            pagination,
            changePagination,
            totalCount,
          }}
        />
      ) : (
        <TablePlaceholder
          isLoading={loading}
          isFiltered={false}
          emptyStateText={i18n._(t`There are no questions here..`)}
          isAllDone={false}
          onResetSearch={() => setSearch('')}
        />
      )}
      <ModalFooter>
        <Button
          label={i18n._(t`Cancel`)}
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          onClick={onClose}
        />
        <Button
          label={i18n._(t`Import ${selectedQuestions.length || ''} questions`)}
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          disabled={selectedQuestions.length <= 0}
          onClick={handleOnSubmit}
        />
      </ModalFooter>
    </StyledModal>
  );
}

export { ImportQuestionsModal };
