import React, { useState } from 'react';

import { useLocation } from 'react-router-dom';

import { MainContainer } from './Luca.design';
import { LucaActions } from './LucaActions';
import { LucaSummary } from './LucaSummary';

enum STEPS {
  ASK_LUCA = 'ask_luca',
  LUCA_SUMMARY = 'luca_summary',
}

const MARGIN = '32px 0 0 0';

const Luca = (): JSX.Element => {
  const [step, setStep] = useState<STEPS>(STEPS.ASK_LUCA);
  const location = useLocation();
  const slicedData = location.pathname.split('/');
  const reportId = slicedData.length > 0 ? slicedData[slicedData.length - 1] : '';

  const onLucaButtonClick = () => {
    setStep(STEPS.LUCA_SUMMARY);
  };

  const getLucaContent = () => {
    if (step === STEPS.LUCA_SUMMARY) {
      return <LucaSummary id={reportId} />;
    }
    return <LucaActions onLucaButtonClick={onLucaButtonClick} />;
  };

  return <MainContainer margin={MARGIN}>{getLucaContent()}</MainContainer>;
};

export { Luca };
