import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import drop from 'lodash/drop';
import orderBy from 'lodash/orderBy';
import size from 'lodash/size';
import take from 'lodash/take';

import { SearchSelectModal } from '~/components/SearchSelectModal';

import type { IColumnTable } from '~/@types/table';
import { usePagination } from '~/hooks/usePagination';

import type { ITeam } from '@learned/types';
import type { I18n } from '@lingui/core';

export enum SORT_OPTIONS {
  NAME_A_Z = 'asc',
  NAME_Z_A = 'desc',
}

const columns: IColumnTable<ITeam>[] = [
  {
    name: 'Teams',
    accessor: 'name',
    renderCell: (item: ITeam) => {
      return <span>{item.name}</span>;
    },
    sortBy: {
      asc: {
        key: SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
];

const TeamsModal = ({
  allTeams,
  onClose,
  onSubmit,
}: {
  allTeams: ITeam[];
  onClose: () => void;
  onSubmit: (teams: string[]) => void;
}) => {
  const { i18n } = useLingui();
  const [filteredTeams, setFilteredTeams] = useState<ITeam[] | []>([]);
  const [search, setSearch] = useState('');
  const [selectedTeams, setSelectedTeams] = useState<ITeam[]>([]);
  const { pagination, changePagination } = usePagination(10);
  const [sortBy, setSortBy] = useState(SORT_OPTIONS.NAME_A_Z);

  useEffect(() => {
    let teamsForModal = orderBy(
      [...(allTeams || [])],
      [(item) => item?.name?.trim().toLowerCase()],
      sortBy,
    );
    if (search) {
      teamsForModal = teamsForModal.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase()),
      );
    }
    const paginatedTeams = take(drop(teamsForModal, pagination.skip), pagination.limit);
    setFilteredTeams(paginatedTeams as ITeam[]);
  }, [allTeams, search, sortBy, pagination]);

  return (
    <SearchSelectModal
      title={i18n._(t`Add team`)}
      onPrimaryAction={() => onSubmit(selectedTeams.map((t: ITeam) => t.id))}
      primaryActionLabel={i18n._(t`Add team`)}
      disablePrimary={selectedTeams.length === 0}
      tableListProps={{
        columns,
        data: filteredTeams,
        multiSelectProps: {
          multiSelect: {
            checkedCount: selectedTeams.length,
            isAllChecked: selectedTeams.length === allTeams.length,
            onSelectItem: (item) =>
              setSelectedTeams((prevState) => {
                if (prevState.includes(item)) {
                  return prevState.filter((value) => value !== item);
                }
                return [...prevState, item];
              }),
            isItemChecked: (item) => selectedTeams.includes(item),
            onCheckAll: () =>
              selectedTeams.length !== allTeams.length
                ? // @ts-ignore
                  setSelectedTeams(allTeams)
                : setSelectedTeams([]),
          },
        },
        sortProps: { sortBy, setSortBy },
        filtersProps: {
          filters: {
            search,
            setSearch,
          },
          isFiltered: false,
          isToggleHideFilterVisible: false,
        },
        paginationProps: {
          pagination,
          changePagination,
          totalCount: size(allTeams),
        },
        placeholderProps: {
          noResultText: i18n._(t`No teams found`),
          emptyStateText: i18n._(t`No teams found`),
        },
      }}
      onClose={onClose}
    />
  );
};

export { TeamsModal };
