import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { AutocompleteFilterGoals } from '~/components/AutocompleteFilters';

import { SectionContainer, Title } from './styledComponets';

const StyledAutocompleteFilterGoals = styled(AutocompleteFilterGoals)`
  margin-top: 8px;
  width: 238px;
`;

const GoalBlock = ({ disabled, checkedList, onChange }) => (
  <SectionContainer>
    <Title>
      <Trans>Connect feedback to goal (optional)</Trans>
    </Title>
    <StyledAutocompleteFilterGoals
      onChange={onChange}
      checkedList={checkedList}
      disableSearch={true}
      disabled={disabled}
    />
  </SectionContainer>
);

export default GoalBlock;
