import React, { cloneElement } from 'react';

import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import DragDropIcon from '~/assets/drag-and-drop-icon.svg';

import { COLOR_SET } from '~/styles';

const ItemBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const DragArea = styled.div`
  padding: 0 24px 0 9px;
`;

const Item = ({
  item,
  index,
  isDragAreaEverywhere = false,
  isDragDisabled = false,
  hasCustomDragBehavior = false,
}) => {
  return (
    <Draggable draggableId={item.id} index={index} isDragDisabled={isDragDisabled}>
      {(provided) => (
        <ItemBlock ref={provided.innerRef} {...provided.draggableProps}>
          {!isDragAreaEverywhere && (
            <>
              <DragArea {...provided.dragHandleProps}>
                <SvgIcon
                  width="16px"
                  height="16px"
                  url={DragDropIcon}
                  isDefaultColor={true}
                  defaultColor={COLOR_SET.VAGUELY_VIOLET}
                />
              </DragArea>
              {item.content}
            </>
          )}
          {isDragAreaEverywhere && !hasCustomDragBehavior && (
            <div style={{ width: '100%' }} {...provided.dragHandleProps}>
              {item.content}
            </div>
          )}
          {isDragAreaEverywhere &&
            hasCustomDragBehavior &&
            cloneElement(item.content, { dragHandleProps: provided.dragHandleProps })}
        </ItemBlock>
      )}
    </Draggable>
  );
};

export default React.memo(Item);
