import React, { useEffect, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import chunk from 'lodash/chunk';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import uniqueId from 'lodash/uniqueId';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import LearnMore from '~/components/LearnMore';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { ImportMembersModal } from '~/components/Modals/ImportMembers';
import { IMPORT_MEMBERS_HEADER_ROWS } from '~/components/Modals/ImportMembers/types';
import PaginationBar from '~/components/PaginationBar';
import SearchSelectButton from '~/components/SearchSelectButton';
import Switch from '~/components/Switch';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import Tooltip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';
import Divider from '~/components/UI/Divider';

import { InviteMembersLoading } from './components/InviteMembersLoading';

import removeIcon from '~/assets/ic-close-primary-18-px.svg';

import { JOB_PROFILE_STATUSES } from '~/constants';
import { INSTRUCTIONS } from '~/constants/instructions';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { usePagination } from '~/hooks/usePagination';
import { getUsers } from '~/selectors/baseGetters';
import { getCompanyActiveConnections } from '~/services/companies';
import { sendInvites } from '~/services/invites';
import { getJobProfiles } from '~/services/jobProfiles';
import { COLORS } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';
import isValidEmail from '~/utils/isValidEmail';

// TODO: Update the button to use the new component
import OldButton from '../Button';
import ShowSpinnerIfLoading from '../ShowSpinnerIfLoading';
import SvgIcon from '../SvgIcon';

const TOOLTIP_SEVERITIES = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'informational',
};

const Wrapper = styled.div`
  margin-top: 12px;
`;

const CustomUserHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 310px 175px 175px 220px 50px;
  align-content: center;
`;

const CustomUserHeaderItem = styled.div`
  font-size: 12px;
  text-transform: uppercase;
`;
const CustomUserItem = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 0 10px 0 0;
`;
const CustomUserItemEmail = styled.div`
  margin: 0 5px 0 16px;
  width: 270px;
  padding: 4px 4px 4px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CustomUserHeaderItemEmail = styled.div`
  font-size: 12px;
  margin-left: 16px;
  text-transform: uppercase;
`;
const CustomUserElementsStacked = styled.div``;

const ErrorText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #f26c5d;
  margin-top: 8px;
`;

const RowWrap = styled.div`
  width: 100%;
  margin-bottom: 26px;
  margin-right: 25px;
`;

const RemoveButton = styled(SvgIcon)`
  cursor: pointer;
  :hover {
    background-color: ${COLORS.COMPANY};
  }
`;
const CustomUserWrapper = styled.div`
  width: 100%;
  background-color: ${(props) => (props.selected ? '#e6e8ec' : '#f6f8fc')};
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
  min-height: 64px;
  height: auto;
  display: grid;
  grid-template-columns: 4fr 2.5fr 2.5fr 2.5fr 3fr 0.7fr;
  align-items: center;
  ${(props) => {
    if (props.severity === TOOLTIP_SEVERITIES.ERROR) {
      return css`
        border: 1px solid ${COLORS.ACCENT_ERROR};
      `;
    }
    if (props.severity === TOOLTIP_SEVERITIES.WARNING) {
      return css`
        border: 1px solid ${COLORS.ACCENT_WARNING};
      `;
    }
    if (props.severity === TOOLTIP_SEVERITIES.INFO) {
      return css`
        border: 1px solid ${COLORS.ACCENT_INFO};
      `;
    }
  }}
`;

const EmailInputField = styled.input`
  width: 248px;
  height: 26px;
  border-radius: 4px;
  border: solid 1px #e4e8ef;
  margin-left: 16px;
  margin-right: 16px;
  padding: 4px;
  padding-left: 16px;
  align-self: center;
  font-size: 14px;
`;

const RoleProfileSelect = styled(SearchSelectButton)`
  font-size: 12px;
  height: 34px;
  align-self: center;
`;

const MemberTeamSelect = styled(SearchSelectButton)`
  font-size: 12px;
  align-self: center;
  height: 34px;
`;

const SelectWrapper = styled.div`
  width: 160px;
  height: 36px;
  padding: 8px;
`;

const CoachTeamSelect = styled(SearchSelectButton)`
  font-size: 12px;
  align-self: center;
`;

const AdminSwitchWrapper = styled.div`
  align-self: center;
  justify-self: center;
`;

const AdminSwitch = styled(Switch)`
  align-self: center;
  justify-self: center;
`;

const ButtonSection = styled.div`
  margin: 106px 0 19px 0;
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop};
    `};
  display: flex;
  padding: 8px;
  padding-right: 24px;
  justify-content: flex-end;
`;

const AddButton = styled(OldButton)`
  margin-top: 4px;
  margin-right: 16px;
`;

const UsersToInviteWrap = styled.div`
  margin-top: 24px;
`;

const SubHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

function renderNames(options, userToInvite, field) {
  const namesList = options
    .filter((obj) => userToInvite[field].includes(obj.value))
    .map((obj) => obj.name);
  return namesList.map((item, index) => (
    <React.Fragment key={index}>
      <CustomUserElementsStacked>{item}</CustomUserElementsStacked>
    </React.Fragment>
  ));
}

function sortByEmail(users) {
  return users.sort((a, b) => a.email.localeCompare(b.email));
}

const BATCH_SIZE = 50;

function renderUserRow(userToInvite, teamOptions, roleOptions, usersToInvite, setUsersToInvite) {
  const email = userToInvite.email;
  const roleNames = renderNames(roleOptions, userToInvite, 'jobProfiles');
  const coachTeamNames = renderNames(teamOptions, userToInvite, 'coachTeams');
  const memberTeamNames = renderNames(teamOptions, userToInvite, 'memberTeams');
  const isAdmin = userToInvite.isAdmin;

  return (
    <Tooltip
      placement="top-start"
      disabled={!userToInvite.error}
      tooltip={userToInvite.error}
      key={userToInvite.rowId}
    >
      <CustomUserWrapper
        key={userToInvite.rowId}
        severity={userToInvite.error ? TOOLTIP_SEVERITIES.ERROR : ''}
      >
        <Tooltip
          tooltip={email}
          maxWidth="100%"
          placement={TOOLTIP_PLACEMENTS.TOP}
          disabled={userToInvite.error}
        >
          <CustomUserItemEmail>{email}</CustomUserItemEmail>
        </Tooltip>
        <CustomUserItem>{roleNames}</CustomUserItem>
        <CustomUserItem>{memberTeamNames}</CustomUserItem>
        <CustomUserItem>{coachTeamNames}</CustomUserItem>
        <CustomUserItem style={{ margin: 'auto' }}>
          <AdminSwitchWrapper>
            <AdminSwitch disabled={true} checked={isAdmin} />
          </AdminSwitchWrapper>
        </CustomUserItem>
        <CustomUserItem style={{ width: '44px' }}>
          <RemoveButton
            url={removeIcon}
            onClick={() =>
              setUsersToInvite(usersToInvite.filter((x) => x.rowId !== userToInvite.rowId))
            }
          />
        </CustomUserItem>
      </CustomUserWrapper>
    </Tooltip>
  );
}

const EMPTY_USER = { email: '', jobProfiles: [], memberTeams: [], coachTeams: [], isAdmin: false };

function CustomUsersTab({ i18n, onModalClose, teams, invites, onChangeLoading }) {
  const PAGE_LIMIT = 10;
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(true);
  const [invitesMemberLoading, setInvitesMemberLoading] = useState(false);
  const [usersToInvite, setUsersToInvite] = useState([]);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [currentUser, setCurrentUser] = useState(EMPTY_USER);

  const [companyActiveConnections, setCompanyActiveConnections] = useState({});
  const users = useSelector(getUsers);
  const [error, setError] = useState(false);
  const getMultiLangString = useMultiLangString();

  const [showImportModal, setShowImportModal] = useState(false);

  const { pagination, changePagination, changePageSize } = usePagination(PAGE_LIMIT);

  const [inviteProgress, setInviteProgress] = useState({
    showInvitesProgress: false,
    invitesSent: 0,
    totalInvites: 0,
  });

  useEffect(() => {
    onChangeLoading(invitesMemberLoading);
  }, [invitesMemberLoading, onChangeLoading]);

  useEffect(() => {
    getCompanyActiveConnections().then(setCompanyActiveConnections);
    getJobProfiles({ status: JOB_PROFILE_STATUSES.ACTIVE.key }).then((roles = {}) => {
      // Override correct multi lang string for name
      Object.values(roles).forEach((jp) => {
        jp.name = getMultiLangString(jp.name);
      });

      setAvailableRoles(sortBy(roles, (role) => role.name.toLowerCase()));
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const teamOptions = [...map(teams, (team) => ({ name: team.name, value: team.id }))];

  const roleOptions = [...map(availableRoles, (role) => ({ name: role.name, value: role.id }))];

  const addUser = () => {
    const lowerCaseEmail = currentUser.email.toLowerCase();
    const predicate = (u) => u.email === lowerCaseEmail;
    const existUser = find(users, predicate);
    const userAlreadyInvited = find(invites, predicate);
    const userAlreadyAddedToList = find(usersToInvite, predicate);

    const activeUserConnection = find(
      companyActiveConnections,
      (c) => existUser && c.user === existUser.id, // userConnection has no email, so we need to check by userId
    );

    if (existUser && activeUserConnection) {
      setError(i18n._(t`This user is a company member`));
    } else if (userAlreadyInvited) {
      setError(i18n._(t`The user has already been invited`));
    } else if (userAlreadyAddedToList) {
      setError(i18n._(t`The user has already been added to the list`));
    } else if (isValidEmail(lowerCaseEmail)) {
      setUsersToInvite(
        sortByEmail([
          ...usersToInvite,
          { ...currentUser, email: lowerCaseEmail, rowId: uniqueId() },
        ]),
      );
      setCurrentUser(EMPTY_USER);
    }
  };

  const handleCsvUsers = (usersCsv = []) => {
    try {
      const usersToAdd = [];
      usersCsv.forEach((user) => {
        let error = '';
        // Set email
        const email = user[IMPORT_MEMBERS_HEADER_ROWS.email].toLowerCase();

        // Email Validations
        const predicate = (u) => u.email === email;
        const existUser = find(users, predicate);
        const userAlreadyInvited = find(invites, predicate);
        const userAlreadyAddedToList = find([...usersToInvite, ...usersToAdd], predicate);

        const activeUserConnection = find(
          companyActiveConnections,
          (c) => existUser && c.user === existUser.id, // userConnection has no email, so we need to check by userId
        );

        if (existUser && activeUserConnection) {
          error = i18n._(t`This user is a company member`);
        } else if (userAlreadyInvited) {
          error = i18n._(t`The user has already been invited`);
        } else if (userAlreadyAddedToList) {
          error = i18n._(t`The user has already been added to the list`);
        } else if (!isValidEmail(email)) {
          error = i18n._(t`Invalid Email`);
        }

        // Search for role profile
        let jobProfiles = availableRoles
          .filter((item) =>
            user[IMPORT_MEMBERS_HEADER_ROWS.jobs]
              ? user[IMPORT_MEMBERS_HEADER_ROWS.jobs].includes(item.name.toLowerCase().trim())
              : false,
          )
          .map((item) => item.id);
        jobProfiles = [...new Set(jobProfiles)];

        // Search for member of teams
        let memberTeams = teamOptions
          .filter((item) =>
            user[IMPORT_MEMBERS_HEADER_ROWS.inTeams]
              ? user[IMPORT_MEMBERS_HEADER_ROWS.inTeams].includes(item.name.toLowerCase().trim())
              : false,
          )
          .map((item) => item.value);
        memberTeams = [...new Set(memberTeams)];

        // Search for coach of teams
        let coachTeams = teamOptions
          .filter((item) =>
            user[IMPORT_MEMBERS_HEADER_ROWS.coachOfTeams]
              ? user[IMPORT_MEMBERS_HEADER_ROWS.coachOfTeams].includes(
                  item.name.toLowerCase().trim(),
                )
              : false,
          )
          .map((item) => item.value);

        coachTeams = [...new Set(coachTeams)];

        let isCoachOnMembers = coachTeams.some((item) => memberTeams.includes(item));

        if (isCoachOnMembers) {
          error = i18n._(t`A user can only be either a member or a coach of a team, not both`);
        }

        usersToAdd.push({
          rowId: uniqueId(),
          email,
          jobProfiles,
          memberTeams,
          coachTeams,
          isAdmin: user[IMPORT_MEMBERS_HEADER_ROWS.admin].toLowerCase() === 'yes',
          error,
        });
      });
      setUsersToInvite(sortByEmail([...usersToInvite, ...usersToAdd]));
      setShowImportModal(false);
    } catch (error) {
      addToast({
        title: error.message,
        type: TOAST_TYPES.ERROR,
      });
    }
  };

  const onInviteUsers = async () => {
    if (usersToInvite.find((item) => item.error)) {
      const confirmResult = await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        description: i18n._(
          t`One or multiple members contain errors. If you want to continue to invite the members without errors click import. Otherwise click cancel and check the errors.`,
        ),
      });

      if (!confirmResult) {
        return;
      }
    }

    setInvitesMemberLoading(true);

    const invites = Object.values(usersToInvite)
      .filter((item) => !item.error)
      .map((userToInvite) => ({
        email: userToInvite.email,
        ...(!isEmpty(userToInvite.memberTeams) && {
          teamsMember: userToInvite.memberTeams,
        }),
        ...(!isEmpty(userToInvite.coachTeams) && {
          teamsCoach: userToInvite.coachTeams,
        }),
        ...(!isEmpty(userToInvite.jobProfiles) && {
          jobProfiles: userToInvite.jobProfiles,
        }),
        isAdmin: userToInvite.isAdmin,
      }));

    const inviteChunks = chunk(invites, BATCH_SIZE);

    if (invites.length > BATCH_SIZE) {
      setInviteProgress({
        showInvitesProgress: true,
        invitesSent: 0,
        totalInvites: invites.length,
      });
    }

    for (let i = 0; i < inviteChunks.length; i++) {
      await sendInvites(inviteChunks[i]);

      if (invites.length > BATCH_SIZE) {
        setInviteProgress((prevState) => ({ ...prevState, invitesSent: BATCH_SIZE * (i + 1) }));
      }
    }

    setInvitesMemberLoading(false);
    onModalClose(false);
  };

  return (
    <Wrapper>
      {showImportModal && (
        <ImportMembersModal
          closeModal={() => setShowImportModal(false)}
          onCancel={() => setShowImportModal(false)}
          onNext={handleCsvUsers}
        />
      )}

      <SubHeaderContainer>
        <LearnMore
          label={i18n._(t`Manually invited members cannot be synchronised or delete via en API.`)}
          LearnMoreLink={getInstructionUrl(INSTRUCTIONS.INVITING_MANAGING_MEMBERS)}
        />
        <Button
          label={i18n._(t`Import via csv`)}
          onClick={() => setShowImportModal(true)}
          icon={ICONS.EXPORT}
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          disabled={invitesMemberLoading}
        />
      </SubHeaderContainer>

      {invitesMemberLoading ? (
        <InviteMembersLoading
          showInvitesProgress={inviteProgress.showInvitesProgress}
          invitesSent={inviteProgress.invitesSent}
          totalInvites={inviteProgress.totalInvites}
        />
      ) : (
        <ShowSpinnerIfLoading loading={loading}>
          <CustomUserHeader>
            <CustomUserHeaderItemEmail>
              <Trans>Email</Trans>
            </CustomUserHeaderItemEmail>
            <CustomUserHeaderItem>
              <Trans>Role profile</Trans>
            </CustomUserHeaderItem>
            <CustomUserHeaderItem>
              <Trans>Member of team</Trans>
            </CustomUserHeaderItem>
            <CustomUserHeaderItem>
              <Trans>Coach of team</Trans>
            </CustomUserHeaderItem>
            <CustomUserHeaderItem>
              <Trans>Admin</Trans>
            </CustomUserHeaderItem>
          </CustomUserHeader>
          <RowWrap>
            <CustomUserWrapper>
              <EmailInputField
                placeholder="example@email.com"
                value={currentUser.email}
                onChange={(e) => {
                  if (error) {
                    setError(false);
                  }
                  setCurrentUser({ ...currentUser, email: e.target.value });
                }}
              />
              <SelectWrapper>
                <RoleProfileSelect
                  checkedList={currentUser.jobProfiles}
                  options={map(availableRoles, (t) => ({ id: t.id, label: t.name }))}
                  title={i18n._(t`Roles`)}
                  handleChange={(e) => setCurrentUser({ ...currentUser, jobProfiles: e })}
                  height="36px"
                  fontSize="12px"
                />
              </SelectWrapper>
              <SelectWrapper>
                <MemberTeamSelect
                  checkedList={currentUser.memberTeams}
                  options={map(teamOptions, (t) => ({ id: t.value, label: t.name })).filter(
                    (team) => !currentUser.coachTeams.includes(team.id),
                  )}
                  title={i18n._(t`Teams`)}
                  handleChange={(e) => setCurrentUser({ ...currentUser, memberTeams: e })}
                  height="36px"
                  fontSize="12px"
                />
              </SelectWrapper>
              <SelectWrapper>
                <CoachTeamSelect
                  checkedList={currentUser.coachTeams}
                  options={map(teamOptions, (t) => ({ id: t.value, label: t.name })).filter(
                    (team) => !currentUser.memberTeams.includes(team.id),
                  )}
                  title={i18n._(t`Coach teams`)}
                  handleChange={(e) => setCurrentUser({ ...currentUser, coachTeams: e })}
                  height="34px"
                  fontSize="12px"
                />
              </SelectWrapper>
              <AdminSwitchWrapper>
                <AdminSwitch
                  onChange={(e) => setCurrentUser({ ...currentUser, isAdmin: e })}
                  checked={currentUser.isAdmin}
                />
              </AdminSwitchWrapper>
              <AddButton
                label="Add"
                type="primary-border"
                disabled={!isValidEmail(currentUser.email.toLowerCase())}
                onClick={addUser}
              />
            </CustomUserWrapper>
            {error && <ErrorText>{error}</ErrorText>}
          </RowWrap>
          {!isEmpty(usersToInvite) && <Divider $color={COLORS.BORDER_HARD} />}
          <UsersToInviteWrap>
            {usersToInvite
              .slice(pagination.skip, pagination.skip + pagination.limit)
              .map((userToInvite) =>
                renderUserRow(
                  userToInvite,
                  teamOptions,
                  roleOptions,
                  usersToInvite,
                  setUsersToInvite,
                ),
              )}
            <PaginationBar
              pagination={pagination}
              changePagination={changePagination}
              changePageSize={changePageSize}
              count={usersToInvite.length}
              noShadow
              noBorder
              noTopBorder
              showCount
            />
          </UsersToInviteWrap>

          <ButtonSection marginTop={usersToInvite.length >= pagination.limit && '16px'}>
            <OldButton
              label={i18n._(t`Invite`)}
              disabled={isEmpty(usersToInvite) || error}
              onClick={onInviteUsers}
              loading={loading}
              type="primary"
            />
          </ButtonSection>
        </ShowSpinnerIfLoading>
      )}
    </Wrapper>
  );
}

export default withI18n()(CustomUsersTab);
