import React, { useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  SURVEY_THEMES_SORT_OPTIONS,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import { Dropdown } from '~/components/Dropdown';
import { ICONS } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { TableList } from '~/components/TableList';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import { CreationInProcessModal } from '~/pages/Surveys/creationInProcessModal';

import routes from '~/constants/routes';
import type { ISurveyThemesStatus } from '~/constants/survey';
import { SURVEY_THEME_STATUS_WITH_NAMES } from '~/constants/survey';
import useDebounce from '~/hooks/useDebounce';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import {
  copySurveyTheme,
  createSurveyTheme,
  deleteSurveyThemes,
  getSurveyThemes,
} from '~/services/surveyThemes';

import { THEMES_COLUMNS } from '../config';

import type { ISurveyThemeWithMultiLangName } from '@learned/types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
`;

const PAGE_SIZE = 10;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };
const LS_KEY = LS_KEYS.LS_SURVEYS_THEMES;

const initialFilters = {
  isShowFilters: false,
  search: '',
  status: undefined,
  sortBy: SURVEY_THEMES_SORT_OPTIONS.CREATED_NEW_OLD,
  pagination: DEFAULT_PAGINATION,
};

const ThemesOverview = () => {
  const { addToast } = useToasts();
  const { i18n } = useLingui();
  const [themes, setThemes] = useState<ISurveyThemeWithMultiLangName[] | []>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentFilters, setCurrentFilters] = useLocalStorage(LS_KEY, initialFilters);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateThemeLoading, setIsCreateThemeLoading] = useState(false);
  const [checkedThemes, setCheckedThemes] = useState<string[]>([]);
  const [forceUpdateCounter, setForceUpdateCounter] = useState(0);
  const { isShowFilters: _isShowFilters, ...debCurrentFilters } = useDebounce(currentFilters, 300);
  const [deleteThemesIds, setDeleteThemesIds] = useState<string[]>([]);
  const [isDeleteThemesModalVisible, setIsDeleteThemesModalVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const { data } = await getSurveyThemes({
        search: isEmpty(currentFilters.search) ? undefined : currentFilters.search,
        sortBy: currentFilters.sortBy,
        ...(!isEmpty(currentFilters.status) && { status: currentFilters.status.key }),
        skip: currentFilters.pagination.skip,
        limit: currentFilters.pagination.limit,
      });

      setThemes(data[API_RETURN_FIELDS.SURVEY_THEMES]);
      setTotalCount(data[API_RETURN_FIELDS.TOTAL]);
      setIsLoading(false);
    };

    fetchData();

    // eslint-disable-next-line
  }, [JSON.stringify(debCurrentFilters), forceUpdateCounter]);

  const onForceFetch = () => setForceUpdateCounter(forceUpdateCounter + 1);

  const isAllChecked = (themes as ISurveyThemeWithMultiLangName[]).every((theme) => {
    return checkedThemes.includes(theme.id);
  });

  const onCheckAll = () => {
    const checkedThemes = isAllChecked ? [] : themes.map((theme) => theme.id);
    setCheckedThemes(checkedThemes);
  };

  const onCheckTheme = (checkedThemeId: string) => {
    const isChecked = checkedThemes.includes(checkedThemeId);
    setCheckedThemes(
      // @ts-ignore
      isChecked
        ? checkedThemes.filter((themeId) => themeId !== checkedThemeId)
        : [...checkedThemes, checkedThemeId],
    );
  };

  const onDuplicate = async (themeId: string) => {
    const { data } = await copySurveyTheme(themeId);
    const newActivity = data[API_RETURN_FIELDS.SURVEY_THEME];
    if (!isEmpty(newActivity)) {
      onForceFetch();
      addToast({
        title: i18n._(t`Theme duplicated`),
        type: TOAST_TYPES.INFO,
      });
    }
  };

  const onDelete = (themesIds: string[]) => {
    setDeleteThemesIds(themesIds);
    setIsDeleteThemesModalVisible(true);
  };

  const closeDeleteThemesModal = () => {
    setIsDeleteThemesModalVisible(false);
    setDeleteThemesIds([]);
  };

  const deleteThemes = async () => {
    try {
      setIsLoading(true);
      await deleteSurveyThemes(deleteThemesIds);
      onForceFetch();
      setCheckedThemes([]);
      addToast({
        title: i18n._(t`Themes deleted!`),
        subtitle: i18n._(t`Themes are deleted from the survey themes.`),
        type: TOAST_TYPES.INFO,
      });
    } finally {
      setIsLoading(false);
    }
    closeDeleteThemesModal();
  };

  const createMenuItems = (item: ISurveyThemeWithMultiLangName) => {
    return [
      {
        label: i18n._(t`Edit`),
        action: () => {
          routes.THEME_UPDATE.go({}, { themeId: item.id, isBackPath: true });
        },
        icon: ICONS.EDIT_PENCIL,
      },
      {
        label: i18n._(t`Duplicate`),
        action: () => onDuplicate(item.id),
        icon: ICONS.DUPLICATE,
      },
      {
        label: i18n._(t`Delete`),
        action: () => onDelete([item.id]),
        icon: ICONS.DELETE_BIN,
        isWarning: true,
      },
    ];
  };

  const createTheme = async () => {
    setIsCreateThemeLoading(true);
    const { data } = await createSurveyTheme();
    setIsCreateThemeLoading(false);

    if (!!data[API_RETURN_FIELDS.SURVEY_THEME] && data[API_RETURN_FIELDS.SURVEY_THEME]?.id) {
      routes.THEME_UPDATE.go(
        {},
        { themeId: data[API_RETURN_FIELDS.SURVEY_THEME].id, isBackPath: true },
      );
    }
  };

  const actionButton = {
    label: t`Create new`,
    onClick: () => createTheme(),
  };

  const filters = {
    isShowFilters: currentFilters.isShowFilters,
    search: currentFilters.search,
    setSearch: (value: string) => {
      setCurrentFilters({ ...currentFilters, search: value, pagination: DEFAULT_PAGINATION });
    },

    // @ts-ignore
    onChangeFilter: (key, value) => setCurrentFilters({ ...currentFilters, [key]: value }),
    resetFilters: () => setCurrentFilters(initialFilters),
    status: currentFilters.status,
  };

  const multiSelect = {
    checkedCount: checkedThemes.length,
    onCheckAll,
    onSelectItem: (theme: ISurveyThemeWithMultiLangName) => onCheckTheme(theme.id),
    isItemChecked: (theme: ISurveyThemeWithMultiLangName) => checkedThemes.includes(theme.id),
    isAllChecked,
    onDelete: () => onDelete(checkedThemes),
  };

  const onThemeClick = {
    column: 'name',
    onClick: (theme: ISurveyThemeWithMultiLangName) =>
      routes.THEME_VIEW.go({}, { themeId: theme.id, isBackPath: true }),
  };

  return (
    <Wrapper>
      {isCreateThemeLoading && <CreationInProcessModal title={i18n._(t`Creating a new theme…`)} />}
      <TableList
        data={themes}
        columns={THEMES_COLUMNS}
        onColClick={onThemeClick}
        sortProps={{
          sortBy: currentFilters.sortBy,
          setSortBy: (sortBy: SURVEY_THEMES_SORT_OPTIONS) =>
            setCurrentFilters({ ...currentFilters, sortBy }),
        }}
        isDraftStatusVisible
        menuProps={{
          createMenuItems,
          isMenuVisible: true,
        }}
        multiSelectProps={{ isMultiSelectVisible: true, multiSelect }}
        paginationProps={{
          pagination: currentFilters.pagination,
          changePagination: ({ skip, limit, index }) =>
            setCurrentFilters({
              ...currentFilters,
              pagination: { ...currentFilters.pagination, skip, limit, index },
            }),
          totalCount,
        }}
        isLoading={isLoading}
        placeholderProps={{
          noResultText: i18n._(t`No themes found`),
          emptyStateText: i18n._(t`No themes yet… Let’s create one!`),
        }}
        actionButton={actionButton}
        filtersProps={{
          filters,
          isFiltered: !!currentFilters.search.length || !!currentFilters.status,
          isToggleHideFilterVisible: true,
          resetFilters: filters.resetFilters,
          filterComponents: (
            <Dropdown
              placeholder={i18n._(t`Status`)}
              selectedItem={Object.values(SURVEY_THEME_STATUS_WITH_NAMES).find(
                (item) => filters?.status?.key === item.key,
              )}
              items={Object.values(SURVEY_THEME_STATUS_WITH_NAMES)}
              onChange={(value?: ISurveyThemesStatus) => filters.onChangeFilter('status', value)}
              stringifyItem={(item) => item.translated(i18n)}
              isSingleSelect
              isClearOption
            />
          ),
        }}
      />
      {isDeleteThemesModalVisible && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.DELETE}
          title={i18n._(t`Delete theme?`)}
          description={i18n._(
            t`Deleting this theme will delete it from the survey report. You can no longer view the data for this theme and the related question. This action cannot be undone.`,
          )}
          onClose={() => closeDeleteThemesModal()}
          onSubmit={() => deleteThemes()}
        />
      )}
    </Wrapper>
  );
};

export { ThemesOverview };
