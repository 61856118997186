import React, { memo } from 'react';

import styled from 'styled-components';

import { COLORS } from '~/styles';
import sanitizeHtml from '~/utils/sanitize';

const Text = styled.div<{ fontColor?: string; color?: string; fontWeight?: string }>`
  p {
    margin: 0;
    font-size: 14px;
    font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
  }
  li {
    padding: 0;
    font-size: 14px;
    font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
  }
  ul {
    padding: 12px 0 0 0;
    list-style-position: inside;
    margin: 0 0 0 12px;
  }
  ol {
    padding: 12px 0 0 0;
    list-style-position: inside;
    margin: 0 0 0 12px;

    + ul {
      padding-left: 24px;
    }
  }

  color: ${COLORS.TEXT_BLACK};
  ${({ fontColor }) => fontColor && `color: ${fontColor}`};
  ${({ color }) => color && `color: ${color}`}
`;

type RickTextViewProps = {
  color?: string;
  fontColor?: string;
  fontWeight?: string;
  isHtmlWithoutStyles?: boolean;
  className?: string;
  html?: string;
};

const RickTextView = ({ html, isHtmlWithoutStyles, className, ...props }: RickTextViewProps) => {
  return (
    <Text
      className={className}
      dangerouslySetInnerHTML={{ __html: sanitizeHtml(html, isHtmlWithoutStyles) }}
      {...props}
    />
  );
};
export default memo(RickTextView);
