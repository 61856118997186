export enum GOAL_TYPES {
  PERSONAL = 'personal', // LEARNING
  BUSINESS = 'business',
  TEAM = 'team',
  COMPANY = 'company',
}

export enum VIRTUAL_GOAL_TYPES {
  HIDDEN = 'hidden', // hidden goal details are not shown in the UI
}

export enum GOAL_STATUSES {
  ON_TRACK = 'onTrack', // (todo, progress)
  PROBLEM = 'problem', // (problem)
  COMPLETED = 'completed', // (done, expired)
  DRAFT = 'draft',
  ARCHIVED = 'archived',
}

export enum GOAL_PROGRESS_TYPES {
  AVG = 'avg',
  AVG_OF_CONTRIBUTING_GOALS = 'avgOfContributingGoals',
  METRIC = 'metric',
  NUMBERS = 'numbers',
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
  IS_DONE = 'isDone',
}

// Euro, Dollar, Pounds
export enum CURRENCY {
  EURO = 'euro',
  DOLLAR = 'dollar',
  POUNDS = 'pounds',
}

export enum SYMBOLS {
  euro = '€',
  dollar = '$',
  pounds = '£',
}

export enum GOAL_VIEWERS {
  OWNER_AND_COACHES = 'ownerAndCoaches',
  TEAMS = 'teams',
  PUBLIC = 'public',
}

export const GOAL_ALLOWED_PARENT_TYPES = {
  [GOAL_TYPES.BUSINESS]: [
    GOAL_TYPES.BUSINESS,
    GOAL_TYPES.PERSONAL,
    GOAL_TYPES.TEAM,
    GOAL_TYPES.COMPANY,
  ],
  // LEARNING
  [GOAL_TYPES.PERSONAL]: [
    GOAL_TYPES.BUSINESS,
    GOAL_TYPES.PERSONAL,
    GOAL_TYPES.TEAM,
    GOAL_TYPES.COMPANY,
  ],
  [GOAL_TYPES.TEAM]: [GOAL_TYPES.TEAM, GOAL_TYPES.COMPANY],
  [GOAL_TYPES.COMPANY]: [],
};

export const NEW_GOAL_ID = 'new-goal';

export enum GOAL_SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
  SKILL_A_Z = 'SKILL_A_Z',
  SKILL_Z_A = 'SKILL_Z_A',
  TYPE_A_Z = 'TYPE_A_Z',
  TYPE_Z_A = 'TYPE_Z_A',
  STATUS_A_Z = 'STATUS_A_Z',
  STATUS_Z_A = 'STATUS_Z_A',
  PROGRESS_A_Z = 'PROGRESS_A_Z',
  PROGRESS_Z_A = 'PROGRESS_Z_A',
}

export const GOAL_SUGGESTIONS_SORT_MAP = (locale: string) => ({
  [GOAL_SORT_OPTIONS.NAME_A_Z]: [`name.${locale}`, 'asc'],
  [GOAL_SORT_OPTIONS.NAME_Z_A]: [`name.${locale}`, 'desc'],
  [GOAL_SORT_OPTIONS.SKILL_A_Z]: [`skill.name.${locale}`, 'asc'],
  [GOAL_SORT_OPTIONS.SKILL_Z_A]: [`skill.name.${locale}`, 'desc'],
});

/* some of these statuses are resolved in on fly */
export enum GOAL_STATUSES_NEW {
  TODO = 'todo',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  DRAFT = 'draft',
  PROBLEM = 'problem',
  ARCHIVED = 'archived',
}
