import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const Container = styled.div<{ error?: string | string[] }>`
  position: relative;
  display: flex;
  align-items: start;
  max-width: 436px;
  border-width: 1px;
  border-style: solid;
  border-color: ${COLORS.BORDERS};
  border-radius: 6px;
  padding: 6px;
  min-height: 38px;
  box-sizing: border-box;

  ${({ error }) =>
    error &&
    css`
      border-color: ${COLORS.ACCENT_ERROR};
    `}

  .searchIcon {
    display: flex;
    align-items: start;
    padding: 4px 6px 0 5px;
    cursor: pointer;
  }

  .inputWrapper {
    display: inline-flex;
    flex-direction: column;
    flex-grow: 1;
    input {
      background-color: transparent;
      padding-left: 1px;
      border: none;
    }
  }

  &:hover {
    background-color: ${COLORS.HOVER};
  }

  .avatarWrapper {
    gap: 7px;
    .avatar {
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px;
    }
  }
`;

export const Labels = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 436px;
  width: 100%;
  gap: 6px;

  .content {
    display: flex;
    align-items: center;
    justify-content: start;
  }
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 275px;
  position: absolute;
  top: calc(100% + 4px);
  left: 40px;

  z-index: 99999;

  width: 228px;
  border-radius: 6px;
  box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
  border: solid 1px ${COLORS.BORDERS};
  background-color: ${COLORS.BG_LIST};

  .content {
    display: flex;
    align-items: center;
    justify-content: start;
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  background-color: ${COLORS.WHITE};
  color: ${COLORS.ICONS_PRIMARY};
  padding: 10px 18px;
  user-select: none;
  cursor: pointer;

  svg {
    visibility: hidden;
  }

  :not(:first-child) {
    border-top: 1px solid ${COLORS.BORDERS};
  }

  :hover {
    color: ${COLORS.COMPANY};
    background-color: ${COLORS.HOVER};

    svg {
      visibility: visible;
    }
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 28px;
  min-height: 28px;
  padding-left: 7px;
  padding-right: 10px;
  gap: 5px;
  user-select: none;

  border-radius: 6px;
  background-color: ${COLORS.BG_ELEMENTS};
  color: ${COLORS.ICONS_PRIMARY};
  font-size: 14px;
  line-height: -1.86;
  letter-spacing: -0.16px;

  svg {
    cursor: pointer;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: start;
  }
`;
