import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Banner } from '~/components/Banner';
import { BANNER_TYPES } from '~/components/Banner/types';
import { Button, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { COLORS } from '~/styles';

interface IAdvancedReportingBannerProps {
  isBannerHovered: boolean;
  setIsBannerHovered: (hovered: boolean) => void;
  onGoToReportClick: () => void;
  onBannerCloseClick: () => void;
}

const AdvancedReportingBanner = ({
  isBannerHovered,
  setIsBannerHovered,
  onGoToReportClick,
  onBannerCloseClick,
}: IAdvancedReportingBannerProps) => {
  const { i18n } = useLingui();
  return (
    <Banner
      isBannerHovered={isBannerHovered}
      setIsBannerHovered={setIsBannerHovered}
      actions={
        <Button
          label={i18n._(t`Advanced reporting`)}
          onClick={onGoToReportClick}
          color={COLORS.ICONS_PRIMARY}
          variant={ButtonVariant.SECONDARY}
        />
      }
      subTitle={i18n._(t`Choose different view types and ask Luca for to analyze the data and suggest
    improvements.`)}
      title={i18n._(t`Go to Advanced Reporting for more data analysis options`)}
      type={BANNER_TYPES.INFO}
      bannerHoverIcon={
        <Icon
          icon={ICONS.CLOSE}
          size={ICON_SIZES.SMALL}
          color={COLORS.WHITE}
          onClick={onBannerCloseClick}
        />
      }
    />
  );
};

export default AdvancedReportingBanner;
