import React from 'react';

import styled from 'styled-components';

import Spinner from '~/components/Spinner';

import { COLORS } from '~/styles';

const Col = styled.div``;

const Text = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.SUB};
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgb(246, 248, 252);

  & > * {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const LoadingPage = ({ placeholder }) => (
  <Wrapper>
    <Col>
      <Spinner type="square" height="100px" />
      {placeholder && <Text>{placeholder}</Text>}
    </Col>
  </Wrapper>
);

export default LoadingPage;
