import React from 'react';

import foreach from 'lodash/forEach';
import { Redirect, Route } from 'react-router-dom';

import PrivateRoute from '~/components/PrivateRoute';

import routesDeprecated from '~/constants/routes/routesDeprecated';

export default function redirects() {
  const redirects = [];

  foreach(routesDeprecated, (route, name) => {
    const { urlOld, url, type } = typeof route === 'function' ? route() : route;

    if (!(urlOld && url)) {
      console.warn('Redirect without from/to url', name, urlOld, url); // eslint-disable
    }

    if (urlOld && url) {
      const DynamicRoute = type === 'private' ? PrivateRoute : Route;
      redirects.push(
        <DynamicRoute
          key={urlOld}
          exact
          path={urlOld}
          render={(props) => {
            const {
              location,
              match: { params },
            } = props;

            return (
              <Redirect
                exact
                to={{
                  pathname: url({
                    ...params,
                    ...(params.RTFeedbackId && { rtfeedbackId: params.RTFeedbackId }),
                  }),
                  search: location.search,
                }}
              />
            );
          }}
        />,
      );
    }
  });

  return redirects;
}
