import { QUESTION_TYPES } from '@learned/constants';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export const getReviewQuestionsWithSkills = (review, isOriginal) => {
  let reviewQuestionsWithSkills = [];
  const reviewSkillCategoriesIds =
    review.skillCategories && review.skillCategories.map((c) => c.id);

  if (!review.questions || review.questions.length === 0) {
    // Self review from skill page
    if (review.skills && review.skills.length > 0 && review.request && review.request.isSelf) {
      const reviewSkills = !isEmpty(reviewSkillCategoriesIds)
        ? review.skills
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))
            .sort(
              (a, b) =>
                reviewSkillCategoriesIds.indexOf(a.categories[0]) -
                reviewSkillCategoriesIds.indexOf(b.categories[0]),
            )
        : review.skills.sort((a, b) => a.name.localeCompare(b.name));

      reviewQuestionsWithSkills.push({
        skills: [...reviewSkills],
        levels: { ...review.skillsLevels },
        type: QUESTION_TYPES.SKILL,
      });
    }

    return reviewQuestionsWithSkills;
  }

  // sort questions by order
  const reviewSortedQuestions = review.questions.slice().sort((a, b) => {
    if (a.sectionIndex === b.sectionIndex) {
      return a.order - b.order;
    }
    return a.sectionIndex - b.sectionIndex;
  });

  // add skills to questions by type

  reviewSortedQuestions.forEach((q) => {
    switch (q.type) {
      case QUESTION_TYPES.CUSTOM: {
        reviewQuestionsWithSkills = [...reviewQuestionsWithSkills, q];
        break;
      }
      case QUESTION_TYPES.SKILL: {
        if (!isEmpty(review.skills)) {
          const reviewSkills = !isEmpty(reviewSkillCategoriesIds)
            ? review.skills
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .sort(
                  (a, b) =>
                    reviewSkillCategoriesIds.indexOf(a.categories[0]) -
                    reviewSkillCategoriesIds.indexOf(b.categories[0]),
                )
            : review.skills.sort((a, b) => a.name.localeCompare(b.name));
          q.skills = [...reviewSkills].filter((s) => s);
          q.levels = { ...review.skillsLevels };
          reviewQuestionsWithSkills = [...reviewQuestionsWithSkills, q];
        }
        break;
      }
      case QUESTION_TYPES.JOB_PROFILE: {
        if (!isEmpty(review.skillsJobProfile)) {
          const reviewSkills = !isEmpty(reviewSkillCategoriesIds)
            ? review.skillsJobProfile
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .sort(
                  (a, b) =>
                    reviewSkillCategoriesIds.indexOf(a.categories[0]) -
                    reviewSkillCategoriesIds.indexOf(b.categories[0]),
                )
            : review.skillsJobProfile.sort((a, b) => a.name.localeCompare(b.name));
          q.skills = [...reviewSkills].filter((s) => s);
          q.levels = { ...review.skillsJobProfileLevels };
          reviewQuestionsWithSkills = [...reviewQuestionsWithSkills, q];
        } else if (isOriginal) {
          reviewQuestionsWithSkills = [...reviewQuestionsWithSkills, q];
        }
        break;
      }
      case QUESTION_TYPES.GOAL_BUSINESS_EVAL:
      case QUESTION_TYPES.GOAL_BUSINESS_PLAN:
      case QUESTION_TYPES.GOAL_LEARNING_PLAN:
      case QUESTION_TYPES.GOAL_LEARNING_EVAL: {
        reviewQuestionsWithSkills = [...reviewQuestionsWithSkills, joinGoalsToQuestion(q, review)];
        break;
      }
      default:
        break;
    }
  });

  return reviewQuestionsWithSkills;
};

function joinGoalsToQuestion(question, review) {
  if (!question || typeof question !== 'object') {
    return;
  }
  if (!review) {
    return;
  }

  let goals = [];
  switch (question.type) {
    case QUESTION_TYPES.GOAL_BUSINESS_EVAL: {
      goals = get(review, 'goalsBusinessEval', []);
      break;
    }
    case QUESTION_TYPES.GOAL_LEARNING_EVAL: {
      goals = get(review, 'goalsLearningEval', []);
      break;
    }
    case QUESTION_TYPES.GOAL_BUSINESS_PLAN: {
      goals = get(review, 'goalsBusinessPlan', []);
      break;
    }
    case QUESTION_TYPES.GOAL_LEARNING_PLAN: {
      goals = get(review, 'goalsLearningPlan', []);
      break;
    }
    default:
      break;
  }

  return {
    ...question,
    goals,
  };
}
