import React from 'react';

import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import CollapseIndicator from '~/pages/Reports/Components/IconWrapper';

import { CellContent, CellWrapper, OneLineWrapper } from '../designs';

import type { IRowTransformed } from '../types';

interface IFirstCellTeamProps {
  item: IRowTransformed;
  onClick: (item: IRowTransformed) => void;
}

const FirstCellTeam = ({ item, onClick }: IFirstCellTeamProps) => {
  return (
    <CellWrapper
      className="cell"
      clickable={!!onClick}
      onClick={() => {
        onClick ? onClick(item) : {};
      }}
    >
      <Tooltip tooltip={item.name} size={TOOLTIP_SIZES.BIG}>
        <OneLineWrapper>
          <CollapseIndicator
            isCollapsed={item.isCollapsed}
            handleOnClick={() => {
              onClick ? onClick(item) : {};
            }}
          />
          <CellContent>{item.name}</CellContent>
        </OneLineWrapper>
      </Tooltip>
    </CellWrapper>
  );
};

export { FirstCellTeam };
