import React from 'react';

import styled from 'styled-components';

import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import { COLORS } from '~/styles';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface IProps {
  message: string;
  size?: ICON_SIZES;
}

export const WarningIcon = ({ message, size = ICON_SIZES.LARGE }: IProps) => {
  return (
    <Tooltip tooltip={message} size={TOOLTIP_SIZES.BIG} maxWidth="400px">
      <IconWrapper>
        <Icon icon={ICONS.WARNING_2} color={COLORS.WARNING_COLOR} size={size} />
      </IconWrapper>
    </Tooltip>
  );
};
