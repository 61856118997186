import { QUESTION_TYPES } from '@learned/constants';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { createReducer } from 'redux-act';

import * as currentReviewActions from './actions';

import { REVIEW_OPTIONS } from '~/constants';

function updateReviewParams(state, { selectedSkills }) {
  const updateCurrentReview = {
    ...state,
  };
  // add skills
  if (!isEmpty(selectedSkills)) {
    updateCurrentReview.skills = [...selectedSkills];
  }

  return updateCurrentReview;
}

const INITIAL_STATE = {
  questions: [],
  users: [],
  templateItem: null,
  goalCyclesBusinessEval: [],
  goalCyclesBusinessPlan: [],
  goalCyclesLearningEval: [],
  goalCyclesLearningPlan: [],
  includeLinkMeeting: false,
};

export const currentReview = createReducer(
  {
    [currentReviewActions.setCurrentReview]: (_state, payload) => {
      return {
        ...INITIAL_STATE,
        ...(payload || {}),
      };
    },

    [currentReviewActions.updateCurrentReview]: (state, payload) => {
      return {
        ...state,
        ...(payload || {}),
      };
    },

    [currentReviewActions.updateCurrentReviewTemplate]: (state, template) => {
      return {
        ...state,
        name: template.name,
        description: template.description || '',
        template: template.id,
        templateItem: template,
        [REVIEW_OPTIONS.IS_SELF_REVIEW.key]: (template.sections || []).some(
          (s) => s[REVIEW_OPTIONS.IS_SELF_REVIEW.key],
        ),
        [REVIEW_OPTIONS.IS_USERS_REVIEW.key]: (template.sections || []).some(
          (s) => s[REVIEW_OPTIONS.IS_USERS_REVIEW.key],
        ),
        [REVIEW_OPTIONS.IS_COACHES_REVIEW.key]: (template.sections || []).some(
          (s) => s[REVIEW_OPTIONS.IS_COACHES_REVIEW.key],
        ),
        goalCyclesBusinessEval: [],
        goalCyclesBusinessPlan: [],
        goalCyclesLearningEval: [],
        goalCyclesLearningPlan: [],
      };
    },

    [currentReviewActions.createCurrentReviewQuestion]: (state, payload) => {
      const { question, selectedSkills } = payload;

      return updateReviewParams(
        {
          ...state,
          questions: [
            ...(state.questions || []),
            {
              ...question,
              order: state.questions.length,
            },
          ],
        },
        {
          selectedSkills,
          type: question.type,
        },
      );
    },

    [currentReviewActions.updateCurrentReviewQuestion]: (state, payload) => {
      const { question, name, selectedSkills, selectedLevels } = payload;

      return updateReviewParams(
        {
          ...state,
          questions: (state.questions || []).map((q) => {
            if (q === question) {
              return {
                ...question,
                name,
              };
            }
            return q;
          }),
        },
        {
          selectedSkills,
          selectedLevels,
          type: question.type,
        },
      );
    },

    [currentReviewActions.removeCurrentReviewQuestion]: (state, question) => {
      const updateCurrentReview = {
        questions: [
          ...sortBy(
            (state.questions || []).filter((q) => q && q !== question),
            ['order'],
          ).map((q, i) => ({ ...q, order: i })),
        ],
      };

      switch (question.type) {
        case QUESTION_TYPES.CUSTOM:
          break;
        case QUESTION_TYPES.SKILL:
          updateCurrentReview.skills = [];
          break;
        case QUESTION_TYPES.JOB_PROFILE:
          break;
        default:
          break;
      }

      return {
        ...state,
        ...updateCurrentReview,
      };
    },

    [currentReviewActions.changeCurrentReviewQuestionOrder]: (state, payload) => {
      const { question, order } = payload;

      return {
        ...state,
        questions: state.questions.map((q) => {
          if (question.order === q.order) {
            return {
              ...(find(state.questions, (quest) => quest.order === order) || {}),
              order: question.order,
            };
          } else if (q.order === order) {
            return {
              ...question,
              order,
            };
          }

          return q;
        }),
      };
    },
  },
  INITIAL_STATE,
);
