import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getInvites(status) {
  return cloudRequest(serverRoutes.invites.getInvites, { status });
}

export function getInvite(id) {
  return cloudRequest(serverRoutes.invites.getInvite, { id });
}

export function acceptInvite(id) {
  return cloudRequest(serverRoutes.invites.acceptInvite, { id });
}

export function cancelInvite(id) {
  return cloudRequest(serverRoutes.invites.cancelInvite, { id });
}

export function sendInvites(invites) {
  return cloudRequest(serverRoutes.invites.sendInvites, {}, { invites });
}

export function resendInvite(id) {
  return cloudRequest(serverRoutes.invites.resendInvite, { id });
}

export function updateInviteDetails(id, update) {
  return cloudRequest(serverRoutes.invites.updateInviteDetails, { id }, update);
}
