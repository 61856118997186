import { useCallback, useState } from 'react';

function useOnHover() {
  const [isHovering, setIsHovering] = useState(false);
  const onMouseEnter = useCallback(() => setIsHovering(true), []);
  const onMouseLeave = useCallback(() => setIsHovering(false), []);

  const ref = useCallback(
    (node: HTMLElement | null) => {
      node?.addEventListener('mouseenter', onMouseEnter);
      node?.addEventListener('mouseleave', onMouseLeave);

      return () => {
        node?.removeEventListener('mouseenter', onMouseEnter);
        node?.removeEventListener('mouseleave', onMouseLeave);
      };
    },
    [onMouseEnter, onMouseLeave],
  );

  return { ref, isHovering };
}

export { useOnHover };
