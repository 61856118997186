import React from 'react';

import styled from 'styled-components';

import type { ILegend } from '~/@types/legend';
import { COLOR_PALETTE } from '~/styles';

export interface LegendProps {
  items: ILegend[];
  className?: string;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }
`;

const Box = styled.div<{ $color: string }>`
  width: 6px;
  height: 19px;
  margin: 1px 8px 0 0;
  border-radius: 21px;
  background-color: ${(props) => props.$color};
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const Legend = ({ items, className }: LegendProps) => {
  return (
    <Wrapper className={className}>
      {items?.map(({ color, label }, key) => (
        <LegendItem key={key}>
          <Box $color={color} />
          <Label>{label}</Label>
        </LegendItem>
      ))}
    </Wrapper>
  );
};

export default Legend;
