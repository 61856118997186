import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const Container = styled.div`
  width: 750px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-content: center;
  box-sizing: border-box;
`;

export const AnswerView = styled.div`
  width: 750px;
  padding: 23px 33px;
  background: ${COLORS.WHITE};
  border-radius: 6px;
  overflow: auto;
  box-sizing: border-box;
`;

export const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 1.18;
  letter-spacing: -0.24px;
  color: ${COLORS.ICONS_PRIMARY};
`;

export const Description = styled.div`
  p {
    width: 750px;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.16px;
    color: ${COLORS.SUBTEXT};
  }
`;

export const ThemeTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 2.17;
  letter-spacing: -0.13px;
  color: ${COLORS.SUBTEXT};
`;

export const HeaderWrapper = styled.div<{ $isZeroState: boolean }>`
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  gap: 7px;

  ${({ $isZeroState }) =>
    $isZeroState
      ? css`
          > div {
            color: ${COLORS.INACTIVE};

            p {
              color: ${COLORS.INACTIVE};
            }
          }
        `
      : null}
`;
