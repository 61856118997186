import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Form = styled.form`
  border-radius: 10px;
  box-sizing: border-box;
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 32px 40px;
`;

export const Title = styled.h2`
  font-size: 26px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: normal;
  margin-top: 0;
`;

export const Label = styled.label`
  font-size: 14px;
  color: ${COLORS.INPUT_TITLE};
  padding-bottom: 4px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  border-top: 1px solid rgba(151, 151, 151, 0.1);
  padding-top: 19px;
  box-sizing: border-box;
`;
