import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { DatePicker } from '~/components/DatePicker';
import { ICONS, Icon } from '~/components/Icon';
import { Input } from '~/components/Input';
import Modal from '~/components/Modal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TableHeader } from '~/pages/Conversations/components/TableHeader';

import { calculateDuration } from './utils';

import { INVITE_STATUSES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { getCompanyConnections, getUser } from '~/selectors/baseGetters';
import { getInvites } from '~/services/invites';
import { updateUserConnection } from '~/services/userConnections';
import { updateCompanyConnection } from '~/store/companyConnections/actions';

import { Wrapper, SectionBody, FieldLabel, FieldValue, ModalBody, ModalFooter } from '../../design';

import type { FormDataType } from '../../types';
import type { IUser, IUserConnection, IInvite } from '@learned/types';

interface ISummaryProps {
  user: IUser;
}

const SummarySection = ({ user }: ISummaryProps) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const $isShowModal = useBoolState(false);
  const $loading = useBoolState(false);
  const $isShowCalendarForStartDate = useBoolState(false);
  const $isShowCalendarForEndDate = useBoolState(false);
  const formMethods = useForm<FormDataType>({
    mode: 'all',
    defaultValues: {
      startDate: null,
      endDate: null,
    },
  });
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = formMethods;
  const [userConnection, setUserConnection] = useState<IUserConnection>();
  const [duration, setDuration] = useState<string>('Not set');
  const [tempDate, setTempDate] = useState<string>('');
  const companyConnections = useSelector(getCompanyConnections);
  const currentUser = useSelector(getUser);
  const isAdmin = currentUser.isAdmin;

  const findUserInvite = (invites: Record<string, IInvite>) => {
    return Object.values(invites).find((invite) => invite.toUser === user.id) as IInvite;
  };

  const findUserConnection = (connections: Record<string, IUserConnection>) => {
    return Object.values(connections).find(
      (connection) => connection.user === user.id,
    ) as IUserConnection;
  };

  const updateUserConnectionStartDate = (connection: IUserConnection, invite: IInvite) => {
    if (invite && !connection.startDate) {
      return { ...connection, startDate: invite.meta.createdDate };
    }
    return connection;
  };

  useEffect(() => {
    const fetch = async () => {
      $loading.on();
      const companyInvites = await getInvites(INVITE_STATUSES.ACCEPTED.key);
      const userInvite = findUserInvite(companyInvites);

      const userConnection = findUserConnection(companyConnections);
      const updatedUserConnection = updateUserConnectionStartDate(userConnection, userInvite);

      setDuration(calculateDuration(updatedUserConnection as IUserConnection));
      setUserConnection(updatedUserConnection as IUserConnection);

      $loading.off();
    };

    fetch();
    // eslint-disable-next-line
  }, [user]);

  const editButton = {
    label: t`Edit`,
    onClick: () => {
      setValue(
        'startDate',
        userConnection?.startDate
          ? moment(userConnection.startDate).format('DD-MM-YYYY') || ''
          : moment(userConnection?.meta?.createdDate).format('DD-MM-YYYY') || '',
      );
      setValue(
        'endDate',
        userConnection?.endDate ? moment(userConnection.endDate).format('DD-MM-YYYY') : '',
      );
      $isShowModal.on();
    },
  };

  const handleCloseJoinDate = (date?: string | null) => {
    $isShowCalendarForStartDate.off();
    date ? setValue('startDate', moment(date).format('DD-MM-YYYY')) : '';
  };

  const handleCloseEndDate = (date?: string | null) => {
    $isShowCalendarForEndDate.off();
    date ? setValue('endDate', moment(date).format('DD-MM-YYYY')) : '';
  };

  const onCancel = () => {
    reset();
    $isShowModal.off();
  };

  const onSave = async (formData: FormDataType) => {
    const data = {
      startDate: moment(formData.startDate, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00.000'),
      endDate: formData.endDate
        ? moment(formData.endDate, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00.000')
        : null,
    };

    // @ts-ignore
    const updatedConnection = await updateUserConnection(userConnection.id, data);

    setUserConnection(updatedConnection.data.userConnection as IUserConnection);
    setDuration(calculateDuration(updatedConnection.data.userConnection as IUserConnection));
    // @ts-ignore
    dispatch(updateCompanyConnection(updatedConnection.data.userConnection));
    reset();
    $isShowModal.off();
  };

  const onClickStartDate = () => {
    setTempDate(
      userConnection?.startDate
        ? moment(userConnection.startDate).format('YYYY-MM-DDT00:00:00.000')
        : moment(userConnection?.meta?.createdDate).format('YYYY-MM-DDT00:00:00.000'),
    );
    $isShowCalendarForStartDate.on();
  };

  const onClickEndDate = () => {
    setTempDate(
      userConnection?.endDate
        ? moment(userConnection.endDate).format('YYYY-MM-DDT00:00:00.000')
        : moment().format('YYYY-MM-DDT00:00:00.000'),
    );
    $isShowCalendarForEndDate.on();
  };

  return (
    <Wrapper>
      <TableHeader
        filters={{ search: '', setSearch: () => {} }}
        actionButton={isAdmin ? editButton : null}
        actionButtonVariant={ButtonVariant.SECONDARY}
        headerTitle={i18n._(t`Summary`)}
        actionButtonIcon={ICONS.EDIT_PENCIL}
      />
      <ShowSpinnerIfLoading loading={$loading.value}>
        <SectionBody>
          <div>
            <FieldLabel>{i18n._(t`Start date at company`)}</FieldLabel>
            <FieldValue>
              {userConnection?.startDate
                ? moment(userConnection.startDate).format('DD-MM-YYYY')
                : moment(userConnection?.meta.createdDate).format('DD-MM-YYYY')}
            </FieldValue>
          </div>
          <div>
            <FieldLabel>{i18n._(t`Duration`)}</FieldLabel>
            <FieldValue>{duration}</FieldValue>
          </div>
          <div>
            <FieldLabel>{i18n._(t`End Date at company`)}</FieldLabel>
            <FieldValue>
              {userConnection?.endDate
                ? moment(userConnection.endDate).format('DD-MM-YYYY')
                : i18n._(t`Not set`)}
            </FieldValue>
          </div>
        </SectionBody>
      </ShowSpinnerIfLoading>

      {$isShowModal.value && (
        <Modal
          contentStyles={{ padding: '30px' }}
          headerStyles={{ padding: '27px', fontSize: '26px' }}
          width={500}
          hideFooter
          showDivider={false}
          onClose={$isShowModal.off}
          title={i18n._(t`Edit job dates`)}
        >
          <ModalBody>
            <div>
              <Input
                label={i18n._(t`Start date`)}
                placeholder={i18n._(t`Select date`)}
                leftIcon={<Icon icon={ICONS.CALENDAR} />}
                register={register('startDate', {
                  required: { value: true, message: i18n._(t`Start date is required`) },
                  validate: (value) => {
                    if (value && moment(value, 'DD-MM-YYYY').isAfter(moment())) {
                      return i18n._(t`Start date cannot be in the future`);
                    }
                    return true;
                  },
                })}
                onClick={onClickStartDate}
                error={errors.startDate?.message}
              />
              {$isShowCalendarForStartDate.value && (
                <DatePicker onClose={handleCloseJoinDate} initialDate={tempDate} />
              )}
            </div>
            <div>
              <Input
                label={i18n._(t`End date`)}
                placeholder={i18n._(t`Select date`)}
                leftIcon={<Icon icon={ICONS.CALENDAR} />}
                register={register('endDate', {
                  validate: (value) => {
                    if (
                      value &&
                      moment(value, 'DD-MM-YYYY').isBefore(
                        moment(getValues('startDate'), 'DD-MM-YYYY'),
                      )
                    ) {
                      return i18n._(t`End date must be after start date`);
                    }
                    return true;
                  },
                })}
                error={errors.endDate?.message}
                onClick={onClickEndDate}
              />
              {$isShowCalendarForEndDate.value && (
                <DatePicker onClose={handleCloseEndDate} initialDate={tempDate} />
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              label={i18n._(t`Cancel`)}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              onClick={onCancel}
            />
            <Button
              label={i18n._(t`Save changes`)}
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              onClick={handleSubmit((formData) => onSave(formData as FormDataType))}
            />
          </ModalFooter>
        </Modal>
      )}
    </Wrapper>
  );
};

export { SummarySection };
