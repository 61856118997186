import { ROLES } from '~/constants';
import { pageCreate, pageUpdate } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';
const routeName = 'conversation-templates';

// overview
// CONVERSATION_TEMPLATES now in CONVERSATIONS
// history.push(routes.CONVERSATIONS.build(
//         { role: ROLES.USER },
//         { hash: 'meetings-templates' },
//       ))

// create
export const CONVERSATION_TEMPLATE_CREATE = new LinkConstructor([ROLES.ADMIN], () =>
  pageCreate(routeName),
);

// update
export const CONVERSATION_TEMPLATE_UPDATE = new LinkConstructor(
  [ROLES.ADMIN],
  ({ templateId = ':templateId' }) => pageUpdate(routeName, { id: templateId }),
);
