import React, { useState, useEffect } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Modal from '~/components/Modal';
import { FeedbackBlock } from '~/components/Modals/SkillFullInfoModal/components/FeedbackBlock';
import { LearningBlock } from '~/components/Modals/SkillFullInfoModal/components/LearningBlock';
import { RatingsBlock } from '~/components/Modals/SkillFullInfoModal/components/RatingsBlock';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';

import { SkillFocusAreasBlock } from './components/SkillFocusAreasBlock';

import viewIcon from '~/assets/view-1.svg';

import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { checkModuleConversations, checkModuleLearning } from '~/selectors/baseGetters';
import getLang from '~/selectors/getLang';
import { getUserLibraryActivitiesWithFilter } from '~/services/activities';
import { getSkillsRatings, getSkillRatings } from '~/services/ratings';
import { getSkill } from '~/services/skills';
import { COLOR_PALETTE, COLORS } from '~/styles';
import { getSkillName } from '~/utils/skillUtils';

const Description = styled.div`
  margin-left: 6px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  background-color: ${COLORS.BG_PAGE};
  padding: 2px 12px;
  position: relative;
  border-radius: 3px;
  margin-left: 14px;
  display: flex;
  align-items: center;
  max-height: 26px;
  box-sizing: border-box;
  &:before {
    content: '';
    border-style: solid;
    border-width: 6px 6px 6px 0;
    border-color: transparent ${COLORS.BG_PAGE} transparent transparent;
    position: absolute;
    left: -6px;
    top: 6px;
    width: 0;
    height: 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const SkillFullInfoModal = ({ skillId, userId, expectedLevel, onClose, isProfile }) => {
  const lang = useSelector(getLang);
  const isModuleLearningEnabled = useSelector(checkModuleLearning);
  const isModuleConversationsEnabled = useSelector(checkModuleConversations);
  const { i18n } = useLingui();
  const [skill, setSkill] = useState(null);
  const [skillLevel, setSkillLevel] = useState(expectedLevel);
  const [ratings, setRatings] = useState([]);
  const [activityCount, setActivityCount] = useState(0);
  const [skillRatingsData, setSkillRatingsData] = useState({});
  const $loading = useBoolState();
  const scaleLabels = useSelector((state) => state.companySettings.skillLabels);
  const getMultiLangString = useMultiLangString();

  const _getSkillRatings = async ({ limit }) => {
    return getSkillRatings({
      skill: skillId,
      limit,
      sortField: 'meta.lastModifiedDate',
      sortDirection: -1,
    }).then((response) => response.data);
  };

  useEffect(() => {
    let isMount = true;

    async function fetchData() {
      $loading.on();
      const [selectedSkill, activityCount, ratings, skillRatingsData] = await Promise.all([
        getSkill(skillId, ['focusAreas', 'categories']),
        isModuleLearningEnabled &&
          getUserLibraryActivitiesWithFilter({
            filters: { skills: [skillId] },
            includeEnrolled: true,
            includeOnlyTotal: true,
          }).then((response) => response?.data?.total),
        isModuleConversationsEnabled &&
          getSkillsRatings({ skills: [skillId], createdFor: userId }).then((ratings) => [
            ...ratings.coachRatings,
            ...ratings.peerRatings,
            ...ratings.selfRatings,
          ]),
        _getSkillRatings({ limit: 2 }),
      ]);
      if (!skillLevel) {
        setSkillLevel(Object.values(skillRatingsData?.ratings)?.[0]?.skillLevel);
      }
      if (isMount) {
        setSkill({ ...selectedSkill, expectedLevel: expectedLevel || null });
        setActivityCount(activityCount ?? 0);
        setRatings(ratings);
        setSkillRatingsData(skillRatingsData);
      }
      $loading.off();
    }
    fetchData();
    return () => {
      isMount = false;
    };
    // eslint-disable-next-line
  }, [skillId]);

  const getTitle = () => {
    if (!skill) {
      return;
    }
    return (
      <Row>
        <div>{getSkillName(skill, lang)}</div>
        {!isEmpty(skill.categories) &&
          skill.categories.map((c) => <Label key={c.id}>{getMultiLangString(c.name)}</Label>)}
      </Row>
    );
  };

  return (
    <Modal
      onClose={onClose}
      title={getTitle()}
      headerStyles={{ padding: '20px 20px 16px' }}
      contentStyles={{ padding: '4px 20px 32px' }}
      showDivider={false}
      greyDescription={
        <Row>
          <SvgIcon
            url={viewIcon}
            width={'24px'}
            height={'14px'}
            isDefaultColor
            defaultColor={COLOR_PALETTE.DARK_GRAY}
          />
          <Description>
            {i18n._(t`Visible to you. This modal is not visible to other people.`)}
          </Description>
        </Row>
      }
      hideFooter
      width={786}
    >
      <ShowSpinnerIfLoading loading={$loading.value}>
        {!isEmpty(skill) && (
          <SkillFocusAreasBlock
            isProfile={isProfile}
            skill={skill}
            expectedLevel={skillLevel}
            scaleLabels={scaleLabels}
          />
        )}
        {isModuleConversationsEnabled && skill && (
          <RatingsBlock
            userId={userId}
            skill={skill}
            ratings={ratings}
            expectedLevel={skillLevel}
            isHideExpectedLevel={isProfile}
          />
        )}
        {skillRatingsData.ratings && (
          <FeedbackBlock
            skillRatings={skillRatingsData.ratings}
            skillRatingsTotal={skillRatingsData.total}
            expectedLevel={skillLevel}
            getAllRatings={async () => {
              const ratingsData = await _getSkillRatings({ limit: 0 });
              setSkillRatingsData(ratingsData);
            }}
          />
        )}
        {isModuleLearningEnabled && (
          <LearningBlock skillId={skillId} activityCount={activityCount} />
        )}
      </ShowSpinnerIfLoading>
    </Modal>
  );
};

export { SkillFullInfoModal };
