import React, { useEffect, useState, useRef } from 'react';

import isNil from 'lodash/isNil';
import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

import { useTabSlider } from '../hooks/useTabSlider';

import type { ITabSliderItem } from '../types';

interface ITablSliderProps {
  items: ITabSliderItem[];
  isHideTabs?: boolean;
  tabTitleAlignment?: 'left' | 'center';
  noPadding?: boolean;
  paddingWrapper?: string;
}

interface HighlightProps {
  activeTab: HTMLDivElement | undefined;
}

const Wrapper = styled.div<{ $isHideTabs?: boolean; $padding?: string }>`
  max-width: 1134px; //1070 max width + padding right 32 + padding left 32
  margin: auto;
  ${(props) =>
    !props.$isHideTabs &&
    isNil(props.$padding) &&
    css`
      padding-top: 32px;
    `}
  ${({ $padding }) =>
    $padding &&
    css`
      padding: ${$padding};
    `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Content = styled.div`
  width: 100%;
`;

export const TabsWrapper = styled.div<{ alignment?: string; noPadding?: boolean }>`
  display: flex;
  padding: ${({ noPadding }) => (noPadding ? '0px' : '0 32px')};
  align-self: ${({ alignment }) => alignment || 'center'};
`;

const Tabs = styled.div`
  height: 40px;
  display: flex;
  border-radius: 6px;
  z-index: 2;
  background-color: ${COLORS.BG_ELEMENTS};
  padding: 4px 5px;
  box-sizing: border-box;
  border: 1px solid ${COLORS.BORDER_HARD};
  gap: 3px;
  align-items: center;
  position: relative;
`;

const Tab = styled.div<{ isActive?: boolean }>`
  padding: 6px 8px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  z-index: 8;
  color: ${COLORS.SUBTEXT};
  ${({ isActive }) => {
    if (isActive) {
      return css`
        color: ${COLORS.COMPANY};
      `;
    } else {
      return css`
        &:hover {
          color: ${COLORS.GRAVEL};
          background-color: ${COLORS.HOVER};
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
        }
      `;
    }
  }}
`;

const Highlight = styled.div<HighlightProps>`
  position: absolute;
  left: 0;
  height: 32px; //height 40 - (padding top 4 + padding bottom 4)
  border-radius: 6px;
  z-index: 6;
  background-color: ${COLORS.WHITE};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
  transform-origin: left center;
  transform: ${({ activeTab }) =>
    activeTab ? `translateX(${activeTab.offsetLeft}px)` : 'translateX(0)'};
  width: ${({ activeTab }) => (activeTab ? `${activeTab.offsetWidth - 2}px` : '0')};
`;

const TabSlider = ({
  items,
  isHideTabs,
  tabTitleAlignment,
  noPadding = false,
  paddingWrapper,
}: ITablSliderProps) => {
  const { currentItem, setCurrentItem } = useTabSlider({ items });
  const [activeTab, setActiveTab] = useState<HTMLDivElement>();
  const tabRefs = useRef<Record<string, HTMLDivElement | null>>({});

  useEffect(() => {
    if (currentItem && currentItem.key) {
      const headerElement = tabRefs.current[currentItem.key];
      if (headerElement) {
        setActiveTab(headerElement);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleTabClick = (tabRef: any) => {
    setActiveTab(tabRef);
  };

  return (
    <Wrapper $isHideTabs={isHideTabs} $padding={paddingWrapper}>
      <ContentWrapper>
        {!isHideTabs && (
          <TabsWrapper alignment={tabTitleAlignment} noPadding={noPadding}>
            <Tabs>
              {items?.map((item) => (
                <Tab
                  key={item.key}
                  ref={(el) => {
                    tabRefs.current[item.key] = el;
                  }}
                  isActive={currentItem?.key === item.key}
                  onClick={() => {
                    handleTabClick(tabRefs.current[item.key]);
                    setCurrentItem(item);
                    item.onChange && item.onChange();
                  }}
                >
                  {item.label}
                </Tab>
              ))}
              <Highlight activeTab={activeTab} />
            </Tabs>
          </TabsWrapper>
        )}
        <Content>{currentItem?.content}</Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export { TabSlider };
