import React from 'react';

import { ACTIVITY_PROGRESS_TYPES, SYMBOLS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Tippy from '@tippyjs/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';
import IconMenu from '~/components/IconMenu';
import ProgressBarWide from '~/components/ProgressBarWide';
import SvgIcon from '~/components/SvgIcon';

import { ACTIVITY_TYPES } from '~/constants';
import { ACTIVITY_STATUS_STYLES } from '~/constants/activities';
import { COLORS, COLOR_PALETTE } from '~/styles';

const tippyOpts = { modifiers: [{ name: 'computeStyles', options: { gpuAcceleration: false } }] };

const Text = styled.div`
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

const ActivityRow = styled.div`
  width: 100%;
  border-radius: 6px;
  background: ${COLOR_PALETTE.WHITE};
  border: 1px solid ${COLORS.BORDER};
  padding: 0 18px 0 0;
  min-height: 64px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  ${(props) => props.$isHover && 'cursor: pointer'};

  ${(props) =>
    props.$isHover &&
    `
    &:hover {
      box-shadow: 0 2px 6px 0 rgba(107, 120, 164, 0.3);
    }
  `};
`;

const ProgressBarWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: 48px;
  min-width: 48px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActivityInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-left: 12px;
  margin-right: 12px;
  overflow: hidden;
`;

const ActivityName = styled.div`
  font-size: 15.2px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
`;

const TippyWrapper = styled.div`
  display: flex;

  & span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 600px;
  }
`;

const ProgressDetails = styled.div`
  display: flex;
  align-items: center;
`;

const ProgressDetailsMin = styled.div`
  font-size: 13.3px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin-right: 8px;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  min-width: ${(props) => props.minWidth || 90}px;
  max-width: ${(props) => props.maxWidth || 90}px;
  margin-right: 12px;
`;

const ProgressDetailsMax = styled(ProgressDetailsMin)`
  color: ${COLOR_PALETTE.BLACK};
`;

const ActivityStatus = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.color};
  ${(props) =>
    props.isGoalTree &&
    `
    min-width: 90px;
    margin-right: 26px;
  `};
`;

const UpdateProgressButton = styled(Button)`
  height: 32px;
  padding: 0 8px;
  margin-right: 32px;
`;

const addPercentage = (label) => `${label}%`;

const Progress = styled(Text)`
  font-weight: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const GoalDashboardActivityItem = ({
  activity,
  onEdit,
  onRemove,
  onClick,
  onEditProgress,
  isEditProgress,
  isCurrentProgress,
  isStatusSection,
  isMenuSection,
  isProgressBar,
  isGoalTree,
}) => {
  const { i18n } = useLingui();
  let menu = [];

  if (!isEmpty(activity) && activity.root !== 'library') {
    menu.push({
      action: () => onEdit(activity),
      label: <Trans>Update</Trans>,
    });
  }

  menu.push({
    action: () => onRemove(activity),
    label: <Trans>Delete</Trans>,
  });

  const activityIcon = Object.values(ACTIVITY_TYPES).find(
    (type) => type.key === activity.type,
  ).icon;
  const activityStatusStyles = ACTIVITY_STATUS_STYLES[activity.status];

  const isDoneProgressType = activity.progressType === ACTIVITY_PROGRESS_TYPES.IS_DONE;
  const min = isDoneProgressType ? 0 : get(activity, 'progressDetails.min', null);
  const progress = get(activity, 'progress', null);
  const max = isDoneProgressType ? 100 : get(activity, 'progressDetails.max', null);
  const currency = get(activity, 'progressDetails.currency', null);
  const isProgressDetails =
    !isDoneProgressType &&
    (typeof min === 'number' || typeof max === 'number' || Boolean(progress));
  const isPercentageProgressType = activity.progressType === ACTIVITY_PROGRESS_TYPES.PERCENTAGE;
  const startString = i18n._(t`Start: ${SYMBOLS[currency] || ''}${min}`);
  const currentString = i18n._(t`Current: ${SYMBOLS[currency] || ''}${progress || 0}`);
  const goalString = i18n._(t`Goal: ${SYMBOLS[currency] || ''}${max}`);

  return (
    <ActivityRow
      $isHover={Boolean(onClick)}
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick();
      }}
    >
      {/* hide progress bar with 0 progress, looks ugly in UI */}
      {isProgressBar && Boolean(activity.calculatedProgress) && (
        <ProgressBarWrapper>
          <ProgressBarWide value={activity.calculatedProgress} rounded isActive height="4px" />
        </ProgressBarWrapper>
      )}
      <Content>
        <IconWrapper>
          <SvgIcon
            width="20px"
            height="20px"
            url={activityIcon}
            isDefaultColor={true}
            defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
          />
        </IconWrapper>
        <ActivityInfo>
          <ActivityName>
            <TippyWrapper>
              <Tippy
                trigger="mouseenter"
                theme="light"
                popperOptions={tippyOpts}
                content={activity.name}
              >
                <span>{activity.name}</span>
              </Tippy>
            </TippyWrapper>
          </ActivityName>
          {isProgressDetails && (
            <ProgressDetails>
              <ProgressDetailsMin>
                {isPercentageProgressType ? addPercentage(startString) : startString}
              </ProgressDetailsMin>
              {isCurrentProgress && (
                <ProgressDetailsMax>
                  {isPercentageProgressType ? addPercentage(currentString) : currentString}
                </ProgressDetailsMax>
              )}
              <ProgressDetailsMax>
                {isPercentageProgressType ? addPercentage(goalString) : goalString}
              </ProgressDetailsMax>
            </ProgressDetails>
          )}
        </ActivityInfo>
        <Column>
          <Progress>{`${round(activity.calculatedProgress || 0, 2)}%`}</Progress>
        </Column>

        <Column>
          {isStatusSection && (
            <ActivityStatus isGoalTree={isGoalTree} color={activityStatusStyles?.color}>
              {activityStatusStyles?.title(i18n)}
            </ActivityStatus>
          )}
        </Column>

        {isEditProgress && (
          <Column>
            <UpdateProgressButton
              type={Button.types.primaryBorder}
              label={i18n._(t`Update`)}
              onClick={onEditProgress}
            />
          </Column>
        )}
        <Column minWidth={20} maxWidth={20}>
          {isMenuSection && <IconMenu items={menu} />}
        </Column>
      </Content>
    </ActivityRow>
  );
};

GoalDashboardActivityItem.propTypes = {
  activity: PropTypes.object,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onClick: PropTypes.func,
  onEditProgress: PropTypes.func,
  isCurrentProgress: PropTypes.bool,
  isEditProgress: PropTypes.bool,
  isStatusSection: PropTypes.bool,
  isMenuSection: PropTypes.bool,
  isProgressBar: PropTypes.bool,
};

GoalDashboardActivityItem.defaultProps = {
  isStatusSection: false,
  isMenuSection: false,
  isEditProgress: false,
  isCurrentProgress: false,
  isProgressBar: false,
  onClick: () => {},
  onEdit: () => {},
  onRemove: () => {},
  onEditProgress: () => {},
};

export default React.memo(GoalDashboardActivityItem);
