import React from 'react';

import { StickyContainer } from 'react-sticky';
import styled from 'styled-components';

import Footer from '~/components/Footer';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const Main = styled(StickyContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  background: ${COLORS.BG_PAGE};
`;

const FocusWithHeightLayout = ({ children }) => (
  <Wrapper>
    <Main id="main-content">
      {children}
      <Footer />
    </Main>
  </Wrapper>
);

export default FocusWithHeightLayout;
