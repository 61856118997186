import React from 'react';

import { Route } from 'react-router-dom';

const OpenRoute = ({
  component: Component,
  layout: Layout,
  path,
  layoutImgStyle = undefined,
  ...rest
}) => {
  let WrapperComponent = React.useMemo(
    () => Component,

    // eslint-disable-next-line
    [Component, path],
  );

  const render = React.useCallback(
    (props) => {
      return Layout ? (
        <Layout layoutImgStyle={layoutImgStyle}>
          <WrapperComponent {...props} />
        </Layout>
      ) : (
        <WrapperComponent {...props} />
      );
    },
    [Layout, WrapperComponent, layoutImgStyle],
  );

  return <Route path={path} render={render} {...rest} />;
};

export default React.memo(OpenRoute);
