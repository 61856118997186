import styled, { css } from 'styled-components';

import { ButtonPrimary, ButtonWhite } from '~/components/Button';

import { COLORS } from '~/styles';

const SubmitButton = styled(ButtonPrimary)`
  height: 32px;
  min-width: 80px;
  padding: 0 10px 0 14px;
  border-radius: 100px;
  font-size: 12px;
  text-align: center;
  gap: 4px;
`;

const IconPreview = styled.div<{ colors: string[] }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 46px;
  min-height: 46px;
  ${({ colors }) =>
    colors?.length && colors.length > 1
      ? css`
          background: linear-gradient(to bottom, ${colors[0]}, ${colors[1]});
        `
      : css`
          background-color: ${colors[0]};
        `}
  border-radius: 6px;
`;

const BackButton = styled(ButtonWhite)`
  height: 32px;
  min-width: 80px;
  padding: 0 14px 0 10px;
  border-radius: 100px;
  font-size: 12px;
  border: none;
  color: var(--company-color);
  gap: 4px;

  &:hover {
    color: var(--company-color);
    background-color: unset;
  }
`;

const Form = styled.form`
  border-radius: 10px;
  box-sizing: border-box;
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 32px 40px;
`;

const Title = styled.h2`
  font-size: 26px;
  color: ${COLORS.BLACK};
  font-weight: normal;
  margin-top: 0;
`;

export const ImportQuestionButton = styled(ButtonPrimary)`
  height: 32px;
  border-radius: 100px;
  font-size: 12px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 31px;
`;

const Label = styled.label`
  font-size: 14px;
  color: ${COLORS.BLACK};
  padding-bottom: 4px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ActionItemBlock = styled.div`
  display: flex;
  align-items: center;
  border-left: solid 1.2px ${COLORS.BORDER_HARD};
  justify-content: center;
  padding-left: 17px;
  padding-right: 17px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
`;

export {
  IconPreview,
  Actions,
  ActionItemBlock,
  SubmitButton,
  BackButton,
  Form,
  Title,
  Label,
  InputContainer,
  Footer,
};
