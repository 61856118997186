import React, { useMemo, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';
import parseISO from 'date-fns/parseISO';
import isEmpty from 'lodash/isEmpty';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { DatePicker } from '~/components/DatePicker';
import { Input } from '~/components/Input';
import Modal from '~/components/Modal';

import {
  Content,
  Footer,
  Header,
  Subtitle,
  Title,
  InputContainer,
  Inputs,
  Text,
} from './StartEndDateModal.design';

import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { updateCareerPlan } from '~/services/careerPlans';
import getUserFullName from '~/utils/getUserFullName';

import type { ICareerPlanPopulated } from '../types';

function StartEndDateModal({
  careerPlan,
  onClose,
}: {
  careerPlan: ICareerPlanPopulated;
  onClose: (shouldRefetch?: boolean) => void;
}) {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  const $isShowCalendarForStartDate = useBoolState();
  const $isShowCalendarForEndDate = useBoolState();
  const $isLoading = useBoolState();
  const [startDate, setStartDate] = useState<Date | undefined>(careerPlan.startDate);
  const [endDate, setEndDate] = useState<Date | undefined>(careerPlan.endDate);

  const handleCloseStartDate = (date?: string | null) => {
    if (date === '' || date === null || date === undefined) {
      setStartDate(undefined);
    } else {
      setStartDate(parseISO(date));
    }
    $isShowCalendarForStartDate.off();
  };

  const handleCloseEndDate = (date?: string | null) => {
    if (date === '' || date === null || date === undefined) {
      setEndDate(undefined);
    } else {
      setEndDate(parseISO(date));
    }
    $isShowCalendarForEndDate.off();
  };

  const errors = useMemo(() => {
    const errors: Record<string, string> = {};
    if (startDate && endDate && startDate > endDate) {
      errors.endDate = i18n._(t`End date should be after start date`);
    }

    if (startDate === undefined || startDate === null) {
      errors.startDate = i18n._(t`Start date is required`);
    }

    return errors;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <Modal
      width={486}
      contentStyles={{ padding: '30px' }}
      hideFooter
      isHideHeader
      showDivider={false}
    >
      <Header>
        <Title>
          <Trans>Edit Dates</Trans>
        </Title>
        <Subtitle>
          <Trans>
            {getUserFullName(careerPlan.createdFor)} for {getMultiLangString(careerPlan.name)}
          </Trans>
        </Subtitle>
      </Header>
      <Content>
        <Inputs>
          <InputContainer>
            <Input
              label={i18n._(t`Start`)}
              placeholder={i18n._(t`Start date`)}
              value={startDate ? format(startDate, 'dd-MM-yyyy') : ''}
              error={errors.startDate}
              onClick={$isShowCalendarForStartDate.on}
              readOnly
            />
            {$isShowCalendarForStartDate.value && (
              <DatePicker
                onClose={handleCloseStartDate}
                initialDate={startDate && formatISO(startDate)}
              />
            )}
          </InputContainer>
          <InputContainer>
            <Input
              readOnly
              label={i18n._(t`End`)}
              placeholder={i18n._(t`End date`)}
              value={endDate ? format(endDate, 'dd-MM-yyyy') : ''}
              error={errors.endDate}
              onClick={$isShowCalendarForEndDate.on}
            />
            {$isShowCalendarForEndDate.value && (
              <DatePicker
                onClose={handleCloseEndDate}
                initialDate={endDate && formatISO(endDate)}
              />
            )}
          </InputContainer>
        </Inputs>
        <Text>
          <Trans>At the end date this job will no longer be active for this member</Trans>
        </Text>
      </Content>
      <Footer>
        <Button
          label={i18n._(t`Cancel`)}
          type="button"
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          onClick={() => {
            onClose();
          }}
        />
        <Button
          label={i18n._(t`Save`)}
          type="button"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          disabled={!isEmpty(errors)}
          isLoading={$isLoading.value}
          onClick={async () => {
            $isLoading.on();
            await updateCareerPlan(careerPlan.id, {
              startDate: startDate && formatISO(startDate),
              endDate: endDate ? formatISO(endDate) : null,
            });
            onClose(true);
            $isLoading.off();
          }}
        />
      </Footer>
    </Modal>
  );
}

export { StartEndDateModal };
