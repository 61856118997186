import React from 'react';

import { IActivity } from '@learned/types';
import { isEmpty } from 'lodash';
import YouTube from 'react-youtube';
import styled from 'styled-components';

import { ACTIVITY_TYPES } from '~/constants';
import { COLORS } from '~/styles';
import abbr from '~/utils/abbr';
import getVideoId from '~/utils/getVideoId';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 400px;
`;

const Title = styled.span`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.ICONS_PRIMARY};
  :hover {
    color: ${COLORS.TEXT_HOVER};
  }

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CoverImg = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin: 10px;

  min-width: 81.8px;
  min-height: 46px;
  width: 81.8px;
  height: 46px;

  background-color: ${COLORS.BG_PAGE};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var(--company-color);
  font-size: 12px;
  font-weight: bold;

  & iframe.youtube-video-cover {
    border-radius: 6px;
  }
`;

function ActivityName({ activity }: { activity: IActivity }) {
  const videoId =
    activity.link && activity.type === ACTIVITY_TYPES.VIDEO.key ? getVideoId(activity.link) : null;
  const shortName = abbr(activity.name);

  return (
    <Container>
      {!isEmpty(videoId) ? (
        <CoverImg>
          <YouTube
            className="youtube-video-cover"
            videoId={videoId!}
            opts={{
              width: '100%',
              height: '48px',
            }}
          />
        </CoverImg>
      ) : (
        <CoverImg
          style={{
            backgroundImage: activity.coverUrl ? 'url(' + activity.coverUrl + ')' : 'none',
          }}
        >
          {!activity.coverUrl && shortName}
        </CoverImg>
      )}
      <Title>{activity.name}</Title>
    </Container>
  );
}

export { ActivityName };
