import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

const Placeholder = styled.div`
  font-size: 14px;
  line-height: 1.43;
  color: ${COLORS.PLACEHOLDERS};
`;

const Row = styled.div<{ isAlignCenter?: boolean; enableHover?: boolean }>`
  width: 218px;
  min-height: 32px;
  display: flex;
  cursor: ${({ enableHover }) => (enableHover ? 'pointer' : 'default')};
  padding: 5px 1px 5px 8px;
  box-sizing: border-box;
  gap: 16px;

  ${({ isAlignCenter }) =>
    isAlignCenter &&
    css`
      align-items: center;
    `}

  ${({ enableHover }) =>
    enableHover &&
    css`
      &:hover {
        border-radius: 6px;
        background-color: ${COLORS.HOVER};

        .row-name {
          color: ${COLORS.TEXT_HOVER};

          span {
            color: ${COLORS.SUBTEXT};
          }
        }
      }
    `}
`;

const RowName = styled.div`
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
  display: flex;
  align-items: center;

  span {
    font-size: 12px;
    text-align: center;
    color: ${COLORS.SUBTEXT};
    margin-left: 7px;
  }
`;

const ConvoName = styled.div`
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
  display: flex;
  align-items: center;

  div {
    max-height: 2.7em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.3;
  }
`;

const RowWrap = styled.div`
  margin-bottom: 19px;
  width: 100%;
  user-select: none;
`;

const Title = styled.div`
  font-size: 12px;
  line-height: 1.33;
  color: ${COLORS.SUBTEXT};
  margin-bottom: 5px;
`;

const NameWrap = styled.div`
  div {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export { Title, Row, RowName, ConvoName, NameWrap, RowWrap, Placeholder };
