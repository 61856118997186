import { QUICK_START_PARTS } from '~/constants/quickStartSteps';

export default (quickStart) => {
  const learnedParts = Object.values(QUICK_START_PARTS);
  const stepsKeysSet = new Set();
  learnedParts
    .map((part) => part.steps)
    .forEach((steps) => steps.forEach((step) => stepsKeysSet.add(step.key)));
  const stepsKeys = Array.from(stepsKeysSet);

  const allStepsCount = stepsKeys.length;
  const completedStepsCount = stepsKeys.filter((key) => quickStart[key]).length;
  return Math.round((completedStepsCount / allStepsCount) * 100);
};
