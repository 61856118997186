import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import PlusIcon from '~/components/Icons/Plus';
import Tooltip from '~/components/Tooltip';
import BoxWithBorder from '~/components/UI/BoxWithBorder';

import { COLORS, COLOR_PALETTE } from '~/styles';

const Header = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  color: Black;
`;

const IconWrap = styled.div`
  width: 22px;
  margin-right: 6px;
  display: flex;
  align-items: center;
`;

const Row = styled.div`
  height: 48px;
  width: 240px;
  display: flex;
  align-items: center;
  border: 1px solid ${COLORS.BORDER_HARD};
  border-radius: 6px;
  padding: 12px 8px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  background: initial;
  margin-bottom: 2px;

  &:hover {
    background-color: ${(props) => !props.$disabled && COLORS.BG_PAGE};
  }

  h6 {
    margin: 0 0 5px 0;
    color: ${(props) => props.$disabled && COLORS.BORDER_HARD};
  }

  p {
    color: ${(props) => props.$disabled && COLORS.BORDER_HARD};
  }
`;

const RowHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  color: ${(props) => (props.$disabled ? COLOR_PALETTE.GRAY_MIDDLE : 'black')};
`;

const Wrapper = styled(BoxWithBorder)`
  margin-bottom: 12px;
  padding: 12px;
  padding-bottom: 16px;
  padding-top: 16px;
`;

const Layout = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

const ToolboxColumn = styled.div`
  align-self: flex-end;
  max-width: 268px;
  height: calc(100% - 120px);
  position: fixed;
  top: 110px;
  overflow: auto;
`;

const ChildrenWrapper = styled.div`
  width: calc(100% - 280px);
`;

const ItemRow = ({ item }) => (
  <Tooltip tooltip={item.text}>
    <Row onClick={() => !item.disabled && item.action()} $disabled={item.disabled}>
      <IconWrap>
        <PlusIcon size={26} fill={item.disabled && COLOR_PALETTE.GRAY_MIDDLE} />
      </IconWrap>
      <div>
        <RowHeader $disabled={item.disabled}>{item.label}</RowHeader>
      </div>
    </Row>
  </Tooltip>
);

const ItemRowWithToolTip = ({ item }) => (
  <Tooltip tooltip={item.hoverText}>
    <Row $disabled={item.disabled}>
      <IconWrap>
        <PlusIcon size={26} fill={COLOR_PALETTE.GRAY_MIDDLE} />
      </IconWrap>
      <div>
        <RowHeader $disabled={item.disabled}>{item.label}</RowHeader>
      </div>
    </Row>
  </Tooltip>
);

const mapItems = (item, index) =>
  item.disabled && item.hoverText ? (
    <ItemRowWithToolTip key={index} item={item} />
  ) : (
    <ItemRow key={index} item={item} />
  );

const ToolBox = ({
  items,
  children,
  splitToolbox = false,
  mainSectionHeader,
  subSectionHeader,
  settingsSectionHeader,
  mainItems,
  subItems,
  settingsItems,
}) => (
  <Layout>
    <ChildrenWrapper>{children}</ChildrenWrapper>
    <ToolboxColumn>
      {splitToolbox ? (
        <>
          <Wrapper>
            <Header>{mainSectionHeader || <Trans>Toolbox</Trans>}</Header>
            {mainItems.map(mapItems)}
          </Wrapper>
          <Wrapper>
            <Header>{subSectionHeader || <Trans>Toolbox</Trans>}</Header>
            {subItems.map(mapItems)}
          </Wrapper>
          {settingsItems && (
            <Wrapper>
              <Header>{settingsSectionHeader || <Trans>Settings</Trans>}</Header>
              {settingsItems}
            </Wrapper>
          )}
        </>
      ) : (
        <Wrapper>
          <Header>
            <Trans>Toolbox</Trans>
          </Header>
          {items.map(mapItems)}
        </Wrapper>
      )}
    </ToolboxColumn>
  </Layout>
);

export default React.memo(ToolBox);
