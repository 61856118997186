import React from 'react';

import styled from 'styled-components';

import { Icon, ICON_SIZES } from '~/components/Icon';

import { Option } from './design';
import { IAction } from './types';

import { COLORS } from '~/styles';

export const StyledOption = styled(Option)`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  font-size: 12px;

  svg {
    visibility: visible;
  }
  padding: 7px 8px;
  justify-content: start;
  width: 100%;
  cursor: pointer;
  color: ${COLORS.COMPANY};
  align-items: center;

  background-color: ${COLORS.WHITE};
  :hover {
    color: ${COLORS.COMPANY};
    background-color: ${COLORS.HOVER};
  }

  svg {
    margin: 10px;
  }
`;

const Action = ({ action, hideDropdown }: { action: IAction; hideDropdown: () => void }) => {
  return (
    <StyledOption
      onClick={() => {
        action.handler();
        hideDropdown();
      }}
    >
      <Icon icon={action.icon} size={ICON_SIZES.SMALL} color={COLORS.COMPANY} /> {action.name}
    </StyledOption>
  );
};

export { Action };
