import React, { Component } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import Button from '~/components/Button';
import Modal from '~/components/Modal';
import AvatarCard from '~/components/UI/AvatarCard';

import getUserFullName from '~/utils/getUserFullName';

const BlockTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #3b3b3b;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const Row = styled.div`
  display: flex;
  width: 100%;

  &:first-child {
    margin-bottom: 48px;
  }

  &:last-child {
    flex-direction: column;
  }
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #3b3b3b;
`;

const ActionWrapper = styled.div`
  display: flex;
`;

const DeclineWrapper = styled.div`
  display: flex;
  margin-right: 25px;
`;

class FeedbackRequestModal extends Component {
  render() {
    const {
      i18n,
      user,
      deadline,
      request,
      onClose,
      onStart,
      onDecline,
      loading,
      hideHeaderClose,
    } = this.props;

    if (typeof request.description !== 'string' || request.description.length === 0) {
      request.description = i18n._(
        t`You have been requested to provide feedback for ${getUserFullName(user)}`,
      );
    }

    return (
      <Modal
        title={i18n._(t`A feedback request!`)}
        onClose={onClose}
        width={625}
        minWidth={625}
        minHeight="410px"
        hideHeaderClose={hideHeaderClose}
        footerRight={
          <ActionWrapper>
            {onDecline && (
              <DeclineWrapper>
                <Button
                  label={i18n._(t`Decline`)}
                  type={Button.types.linkSecondary}
                  onClick={() => onDecline(request)}
                  loading={loading}
                />
              </DeclineWrapper>
            )}
            <Button
              label={i18n._(t`Start`)}
              type="primary-border"
              onClick={onStart}
              loading={loading}
            />
          </ActionWrapper>
        }
      >
        <Row>
          <Column>
            <BlockTitle>
              <Trans>From</Trans>
            </BlockTitle>
            <AvatarCard userId={user.id} />
          </Column>
          {deadline && (
            <Column>
              <BlockTitle>
                <Trans>Deadline</Trans>
              </BlockTitle>
              <Text>{deadline}</Text>
            </Column>
          )}
        </Row>
        <Row>
          <BlockTitle>
            <Trans>About</Trans>
          </BlockTitle>
          <Text>{request.description}</Text>
        </Row>
      </Modal>
    );
  }
}

export default withI18n()(FeedbackRequestModal);
