export enum SURVEY_TEMPLATE_STATUSES {
  active = 'active',
  draft = 'draft',
}

export enum SURVEY_TEMPLATES_SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
  CREATED_NEW_OLD = 'CREATED_NEW_OLD',
  CREATED_OLD_NEW = 'CREATED_OLD_NEW',
  UPDATED_NEW_OLD = 'UPDATED_NEW_OLD',
  UPDATED_OLD_NEW = 'UPDATED_OLD_NEW',
}
