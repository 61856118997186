import React from 'react';

import styled from 'styled-components';

import { COLORS } from '~/styles';

const AllProgress = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  height: ${(props) => props.height || '12px'};
  overflow: hidden;
  background-color: ${(props) => props.$backgroundColor || '#f6f8fc'};
  border-radius: ${(props) => props.$rounded && '6px'};
`;

const DoneProgress = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: ${(props) => props.$height || '12px'};
  width: ${(props) => props.$width}%;
  background-color: ${(props) => props.$statusColor};
  border-radius: ${(props) => props.$rounded && '6px'};
`;

const ProgressBarWide = ({
  value,
  height,
  isActive,
  style,
  backgroundColor,
  customStatusColor,
  rounded,
}) => (
  <AllProgress height={height} style={style} $backgroundColor={backgroundColor} $rounded={rounded}>
    <DoneProgress
      $width={value}
      $height={height}
      $rounded={rounded}
      $statusColor={
        isActive ? (customStatusColor ? customStatusColor : 'var(--company-color)') : COLORS.BG_PAGE
      }
    />
  </AllProgress>
);

export default ProgressBarWide;
