import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Switch from '~/components/Switch';

import {
  Separator,
  SubSectionWrapper,
  SubTitle,
  ToggleContainer,
  ToggleRow,
  ToggleText,
} from '../../design';

import type { IReviewIndividualForm, NotificationSettingsType } from '../../types';
import type { I18n } from '@lingui/core';
import type { UseFormReturn } from 'react-hook-form';

interface NotificationSettingsProps {
  formMethods: UseFormReturn<IReviewIndividualForm>;
}

type ToggleItem = {
  key: NotificationSettingsType;
  text: (i18n: I18n) => string;
};

const TOGGLE_LIST: Array<ToggleItem> = [
  {
    key: 'isOnTaskStartDate',
    text: (i18n: I18n) => i18n._(t`On start date`),
  },
  {
    key: 'isOn7DaysBeforeTaskDeadline',
    text: (i18n: I18n) => i18n._(t`7 days before deadline`),
  },
  {
    key: 'isOnTaskDeadline',
    text: (i18n: I18n) => i18n._(t`On the day of the deadline`),
  },
];

const NotificationSettings = ({ formMethods }: NotificationSettingsProps) => {
  const { watch, setValue } = formMethods;
  const { i18n } = useLingui();

  const onChangeToggle = (key: NotificationSettingsType) => {
    setValue(`notifications.${key}`, !watch(`notifications.${key}`), { shouldDirty: true });
  };

  return (
    <SubSectionWrapper>
      <SubTitle>
        <Trans>Task notifications</Trans>
      </SubTitle>
      <Separator />
      <ToggleContainer>
        {TOGGLE_LIST.map((item) => (
          <ToggleRow key={item.key}>
            <Switch
              onChange={() => onChangeToggle(item.key)}
              checked={watch(`notifications.${item.key}`)}
            />
            <ToggleText>{item.text(i18n)}</ToggleText>
          </ToggleRow>
        ))}
      </ToggleContainer>
    </SubSectionWrapper>
  );
};

export { NotificationSettings };
