import { t } from '@lingui/macro';

export const HELPCENTER_ARTICLE = 'https://intercom.help/learned/en/';
export const CUSTOMER_STORIES_ARTICLE = 'https://www.learned.io/en/klantcases/';

export const QUICK_START_STEPS = {
  LEARNED_INTRODUCTION: {
    key: 'learnedIntroduction',
    title: (i18n) => i18n._(t`Watch an introduction to Learned`),
    article: 'https://www.learned.io/en/get-started-video',
  },
  LOGO_AND_COLORS: {
    key: 'logoAndColors',
    title: (i18n) => i18n._(t`Learn how to upload your logo and colors`),
    article:
      'https://intercom.help/learned/en/articles/3950514-designing-the-company-profile-settings-corporate-identity',
  },
  INVITE_COLLEAGUE: {
    key: 'inviteColleague',
    title: (i18n) => i18n._(t`Learn how to invite your first colleague`),
    article: 'https://intercom.help/learned/en/articles/3950800-inviting-and-managing-members',
  },
  CREATE_TEAM: {
    key: 'createTeam',
    title: (i18n) => i18n._(t`Learn how to create your first team`),
    article: 'https://intercom.help/learned/en/articles/3950876-creating-and-managing-teams',
  },
  CREATE_REVIEW: {
    key: 'createReview',
    title: (i18n) => i18n._(t`Learn how to create a review`),
    article: 'https://intercom.help/learned/en/articles/3950568-creating-a-review-round-as-admin',
  },
  MANAGE_ACTIVE_REVIEW: {
    key: 'manageActiveReview',
    title: (i18n) => i18n._(t`Learn how to manage an active review`),
    article:
      'https://intercom.help/learned/en/articles/5609336-learn-how-to-manage-an-active-review',
  },
  COMPLETE_AND_ANALYSE_REVIEW: {
    key: 'completeAndAnalyseReview',
    title: (i18n) => i18n._(t`Learn how to complete and analyse a review`),
    article:
      'https://intercom.help/learned/en/articles/5609352-completing-and-analysing-a-review-or-development-conversation',
  },
  CREATE_TEMPLATE: {
    key: 'createTemplate',
    title: (i18n) => i18n._(t`Learn how to create a template`),
    article:
      'https://intercom.help/learned/en/articles/4710573-building-review-templates-for-formal-conversations',
  },
  SET_UP_REVIEW_CYCLE: {
    key: 'setupReviewCycle',
    title: (i18n) => i18n._(t`How to set up a review cycle`),
    article: 'https://intercom.help/learned/en/articles/6019179-how-to-set-up-review-cycle',
  },
  WORK_WITH_SKILLS: {
    key: 'skillsIntro',
    title: (i18n) => i18n._(t`Learn how to work with skills`),
    article: 'https://intercom.help/learned/en/articles/5609374-learn-how-to-work-with-skills',
  },
  CREATE_ROLE_PROFILE: {
    key: 'createRoleProfile',
    title: (i18n) => i18n._(t`Learn how to create a role profile`),
    article: 'https://intercom.help/learned/en/articles/3950928-creating-role-profiles-in-learned',
  },
  INTEGRATIONS: {
    key: 'integrationsIntro',
    title: (i18n) => i18n._(t`Learn about the power of integrations`),
    article: 'https://intercom.help/learned/en/articles/4921781-the-learned-api-integrations',
  },
  SET_UP_GOAL_UPDATES: {
    key: 'setupGoalUpdates',
    title: (i18n) => i18n._(t`How to set up goal updates`),
    article: 'https://intercom.help/learned/en/articles/5894311-how-goal-updates-work',
  },
  SET_UP_FEEDBACK_CYCLE: {
    key: 'setupFeedbackCycle',
    title: (i18n) => i18n._(t`How to set up a feedback cycle`),
    article: 'https://intercom.help/learned/en/articles/6007328-how-to-set-up-a-feedback-cycle',
  },
};

export const QUICK_START_PARTS = {
  BASICS: {
    key: 'setUpBasics',
    title: (i18n) => i18n._(t`Setting up the basics`),
    steps: [
      QUICK_START_STEPS.LEARNED_INTRODUCTION,
      QUICK_START_STEPS.LOGO_AND_COLORS,
      QUICK_START_STEPS.INTEGRATIONS,
    ],
  },
  COLLEAGUES_AND_TEAMS: {
    key: 'startWithColleagueAndTeams',
    title: (i18n) => i18n._(t`Start with colleagues and teams`),
    steps: [QUICK_START_STEPS.INVITE_COLLEAGUE, QUICK_START_STEPS.CREATE_TEAM],
  },
  SKILLS_AND_ROLES: {
    key: 'setUpSkillsAndRoles',
    title: (i18n) => i18n._(t`Set-up your skills and roles`),
    steps: [QUICK_START_STEPS.WORK_WITH_SKILLS, QUICK_START_STEPS.CREATE_ROLE_PROFILE],
  },
  FIRST_REVIEW: {
    key: 'createFirstReview',
    title: (i18n) => i18n._(t`Creating your first review`),
    steps: [
      QUICK_START_STEPS.SET_UP_REVIEW_CYCLE,
      QUICK_START_STEPS.CREATE_TEMPLATE,
      QUICK_START_STEPS.CREATE_REVIEW,
      QUICK_START_STEPS.MANAGE_ACTIVE_REVIEW,
      QUICK_START_STEPS.COMPLETE_AND_ANALYSE_REVIEW,
    ],
  },
  CYCLES_SETTINGS: {
    key: 'cyclesSettings',
    title: (i18n) => i18n._(t`Make it continuous`),
    steps: [QUICK_START_STEPS.SET_UP_GOAL_UPDATES, QUICK_START_STEPS.SET_UP_FEEDBACK_CYCLE],
  },
};
