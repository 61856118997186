import React from 'react';

import { Trans } from '@lingui/macro';

import { Dropdown } from '~/components/Dropdown';

import { getGoalCycles } from '~/services/goalCycles';
import convertToTimeString from '~/utils/convertToTimeString';

import type { IGoalCycle } from '@learned/types';

const LIMIT = 20;

function getGoalCyclesLabel(goalCycle: IGoalCycle) {
  return `${goalCycle.name} ${convertToTimeString(goalCycle.startDate)} | ${convertToTimeString(
    goalCycle.endDate,
  )}`;
}

interface IGoalsCycleProps {
  type?: string;
  placeholder?: React.ReactNode;
  excludes?: string[];
  onChange: (items: IGoalCycle[]) => void;
  selectedItems?: IGoalCycle[];
}

const AutocompleteFilterGoalCycles = React.memo((props: IGoalsCycleProps) => {
  const [items, setItems] = React.useState<IGoalCycle[]>([]);
  const [selectedItems, setSelectedItems] = React.useState<IGoalCycle[]>(props.selectedItems || []);

  const fetchGoalCycles = async (search: string) => {
    const data = await getGoalCycles({
      limit: LIMIT,
      search,
      excludes: props.excludes,
      type: props.type,
    });

    // @ts-ignore
    const timeframes = Object.values(data).map((item: IGoalCycle) => ({ ...item, key: item.id }));
    setItems(timeframes);
  };

  const handleOnChange = (items: IGoalCycle[]) => {
    setSelectedItems(items);
    props.onChange(items);
  };

  return (
    <Dropdown
      // @ts-ignore
      items={items}
      isSearchable
      selectedItems={selectedItems}
      onChange={handleOnChange}
      isSingleSelect={false}
      stringifyItem={getGoalCyclesLabel}
      // @ts-ignore
      placeholder={props.placeholder || <Trans>Goal Cycles</Trans>}
      onSearchChange={fetchGoalCycles}
    />
  );
});

export { AutocompleteFilterGoalCycles };
