import React, { Component } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import FeedbackIcon from '~/components/Icons/Feedback';
import Placeholder from '~/components/Placeholder';
import RatingScale from '~/components/RatingScale';
import RickTextView from '~/components/RickTextView';
import AvatarCard from '~/components/UI/AvatarCard';

import { COLORS } from '~/styles';

const FbOtherCards = styled.div`
  width: 100%;
`;

const RequestWrapper = styled.div`
  box-sizing: border-box;
  border-radius: 6px;
  border: solid 1px #e4e8ef;
`;

const RequestHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e4e8ef;
  padding: 16px;
`;

const RequestContent = styled.div`
  border-radius: 0 0 6px 6px;
  padding: 16px;
`;

const RatingScaleWrapper = styled.div`
  margin: 16px 0 32px;
`;

const Rate = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RateHeader = styled.div`
  width: 100%;
`;

const RateQuestion = styled.div`
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

const RateComment = styled(RickTextView)`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: #3b3b3b;
  word-break: break-word;
  white-space: pre-wrap;
`;

const RateLabel = styled.div`
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_PRIMARY_2};
  margin-bottom: 8px;
`;

const CommentWrapper = styled.div`
  margin-top: 14px;
`;

const CommentBlock = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-bottom: 34px;
  }
`;

class FeedbackSelfReport extends Component {
  static propTypes = {
    questionText: PropTypes.string,
  };

  renderSelfRating = (rating) => {
    const { review, questionText } = this.props;

    return (
      <RequestWrapper key={rating.id}>
        <RequestHeader>
          <AvatarCard userId={review.createdFor} />
        </RequestHeader>
        <RequestContent>
          <Rate>
            <RateHeader>
              <RateQuestion>
                {!rating.rate && !rating.explanation ? (
                  <Trans>You did not answer this question</Trans>
                ) : (
                  questionText
                )}
              </RateQuestion>
              {!!rating.rate && (
                <RatingScaleWrapper>
                  <RatingScale
                    selectedRate={rating.rate}
                    rateType={rating.rateType}
                    scale={rating.scale}
                    compact
                    readonly
                    labelRight
                    width="144px"
                  />
                </RatingScaleWrapper>
              )}
              {!!rating.explanation && (
                <CommentWrapper>
                  {/* comment */}
                  <CommentBlock>
                    <RateLabel>
                      <Trans>Comment</Trans>
                    </RateLabel>
                    <RateComment html={rating.explanation} />
                  </CommentBlock>
                </CommentWrapper>
              )}
            </RateHeader>
          </Rate>
        </RequestContent>
      </RequestWrapper>
    );
  };

  render() {
    const { rating, i18n } = this.props;

    return (
      <FbOtherCards>
        {!isEmpty(rating) ? (
          this.renderSelfRating(rating)
        ) : (
          <Placeholder
            title={i18n._(t`No self review`)}
            subTitle={i18n._(t`Your self review will show here`)}
            Icon={FeedbackIcon}
          />
        )}
      </FbOtherCards>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(FeedbackSelfReport)));
