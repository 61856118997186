import React from 'react';

import styled from 'styled-components';

import Editor from '~/components/Editor';

import { SectionContainer, Title, Subtitle } from './styledComponets';

const StyledEditor = styled(Editor)`
  margin-top: 8px;
`;

const MessageBlock = ({ title, subtitle, placeholder, message, setMessage }) => (
  <SectionContainer>
    <Title>{title}</Title>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
    <StyledEditor
      placeholder={placeholder}
      value={message}
      small={true}
      compact={true}
      onChange={setMessage}
    />
  </SectionContainer>
);

export default MessageBlock;
