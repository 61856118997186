import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import SettingBlock from '~/components/ProfileSettingsComponents/MainDashboard/SettingBlock';

import DeadlineRow from './components/DeadlineRow';
import RowWithField from './components/RowWithField';
import RowWithSwitch from './components/RowWithSwitch';

const RowWrapper = styled.div`
  > div {
    padding: 10px;
  }
`;

const isNumber = (value) => typeof value === 'number';

const CompanySettingBlock = ({ settings, updateSettings, localUpdate, title }) => {
  const { i18n } = useLingui();

  const getTooltipForDayField = (value) => {
    return isNumber(value) || value === '0'
      ? value >= 1 && value <= 365
        ? ''
        : i18n._(t`The value must be between 1 & 365`)
      : i18n._(t`The value is not a number`);
  };

  const getTooltipForWeekField = (value) => {
    return isNumber(value) || value === '0'
      ? value >= 1 && value <= 53
        ? ''
        : i18n._(t`The value must be between 1 & 53`)
      : i18n._(t`The value is not a number`);
  };
  return (
    <SettingBlock
      noPadding
      noBackGround
      style={{ padding: '12px 0' }}
      styleChild={{ marginTop: '12px', marginBottom: '12px' }}
    >
      <RowWithSwitch
        value={settings.isEnabled}
        label={title}
        onChange={(value) => updateSettings({ isEnabled: value })}
        isBold={true}
      />
      {settings.isEnabled && (
        <RowWrapper>
          <DeadlineRow
            value={settings.weekDay}
            label={i18n._(t`Deadline for task, every:`)}
            onChange={(value) => updateSettings({ weekDay: value })}
          />
          <RowWithField
            labelBefore={i18n._(t`Repeat task every`)}
            labelAfter={i18n._(t`week(s)`)}
            value={settings.repeat}
            onChange={(value) => localUpdate({ repeat: Number(value) > 0 ? Number(value) : value })} // only local update
            onBlur={(value) => updateSettings({ repeat: Number(value) })} // request to API
            isError={Boolean(getTooltipForWeekField(settings.repeat))}
            tooltip={getTooltipForWeekField(settings.repeat)}
          />
          <RowWithField
            labelBefore={i18n._(t`Show task`)}
            labelAfter={i18n._(t`day(s) before deadline`)}
            value={settings.showTaskDaysBefore}
            onChange={(value) =>
              localUpdate({ showTaskDaysBefore: Number(value) > 0 ? Number(value) : value })
            } // only local update
            onBlur={(value) => updateSettings({ showTaskDaysBefore: Number(value) })} // request to API
            isError={Boolean(getTooltipForDayField(settings.showTaskDaysBefore))}
            tooltip={getTooltipForDayField(settings.showTaskDaysBefore)}
          />
          <RowWithField
            labelBefore={i18n._(t`Automatically dismiss task`)}
            labelAfter={i18n._(t`day(s) after deadline`)}
            value={settings.hideTaskDaysAfter}
            onChange={(value) =>
              localUpdate({ hideTaskDaysAfter: Number(value) > 0 ? Number(value) : value })
            } // only local update
            onBlur={(value) => updateSettings({ hideTaskDaysAfter: Number(value) })} // request to API
            isError={Boolean(getTooltipForDayField(settings.hideTaskDaysAfter))}
            tooltip={getTooltipForDayField(settings.hideTaskDaysAfter)}
          />
        </RowWrapper>
      )}
    </SettingBlock>
  );
};

export default React.memo(CompanySettingBlock);
