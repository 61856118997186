import React from 'react';

import { ROLES, SIGNATURE_VIEWS } from '@learned/constants';
import { Trans } from '@lingui/macro';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import SignAgreeIcon from '~/assets/sign-agree.svg';
import SignDisagreeIcon from '~/assets/sign-disagree.svg';

import { COLOR_PALETTE, COLOR_SET, COLORS } from '~/styles';
import sanitizeHtml from '~/utils/sanitize';

const Wrapper = styled.div`
  margin: 16px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 16px 22px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
`;

const Signature = styled.div`
  font-family: SignPainter-HouseScript;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.36px;
  color: ${COLOR_PALETTE.BLACK};
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin-bottom: 8px;
  margin-top: 16px;
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const UserAgreeBlock = styled.div`
  border-bottom: 1px solid ${COLORS.BORDER_LIGHT};
  margin-left: -16px;
  margin-right: -16px;
`;

const SignatureBlock = ({ view, digitalSign, userSignature }) => {
  const renderAgreeSection = () => {
    return (
      (digitalSign?.isAgreeSeen || digitalSign?.isAgreeContent) && (
        <UserAgreeBlock>
          {digitalSign?.isAgreeSeen && (
            <Row>
              <Trans>I have seen the contents of this form</Trans>
              <SvgIcon
                width="16px"
                height="16px"
                isDefaultColor={true}
                defaultColor={userSignature.isSeen ? COLOR_SET.CYAN_GREEN : COLOR_SET.ORANGE2}
                url={userSignature.isSeen ? SignAgreeIcon : SignDisagreeIcon}
                alt={userSignature.isSeen ? <Trans>Agree</Trans> : <Trans>Disagree</Trans>}
              />
            </Row>
          )}
          {digitalSign?.isAgreeContent && (
            <Row>
              <Trans>I agree with the contents of this form</Trans>
              <SvgIcon
                width="16px"
                height="16px"
                isDefaultColor={true}
                defaultColor={userSignature.isAgree ? COLOR_SET.CYAN_GREEN : COLOR_SET.ORANGE2}
                url={userSignature.isAgree ? SignAgreeIcon : SignDisagreeIcon}
                alt={userSignature.isAgree ? <Trans>Agree</Trans> : <Trans>Disagree</Trans>}
              />
            </Row>
          )}
        </UserAgreeBlock>
      )
    );
  };

  const renderCommentSection = () => {
    return (
      <>
        <Label>
          <Trans>Comment</Trans>
        </Label>
        <Text dangerouslySetInnerHTML={{ __html: sanitizeHtml(userSignature.comment) }} />
      </>
    );
  };

  return (
    <Wrapper>
      {view === SIGNATURE_VIEWS.USER && renderAgreeSection()}
      {view === SIGNATURE_VIEWS.USER && renderCommentSection()}
      {view === SIGNATURE_VIEWS.COACH && renderCommentSection()}
      <Label>
        <Trans>Date</Trans>
      </Label>
      <Text isNormal>{moment(userSignature.signatureDate).format('YYYY-MM-DD HH:mm')}</Text>
      {userSignature.signature && (
        <>
          <Label>
            <Trans>Signature</Trans>
          </Label>
          <Signature>{userSignature.signature}</Signature>
        </>
      )}
    </Wrapper>
  );
};

SignatureBlock.propTypes = {
  view: PropTypes.oneOf([ROLES.USER, ROLES.COACH]),
  digitalSign: PropTypes.shape({
    isAgreeSeen: PropTypes.bool,
    isAgreeContent: PropTypes.bool,
    isUserComment: PropTypes.bool,
    isCoachComment: PropTypes.bool,
  }),
  userSignature: PropTypes.shape({
    role: PropTypes.oneOf([ROLES.USER, ROLES.COACH]),
    userId: PropTypes.string,
    signature: PropTypes.string,
    isSeen: PropTypes.bool,
    isAgree: PropTypes.bool,
    comment: PropTypes.string,
    signatureDate: PropTypes.string,
  }),
};

export default SignatureBlock;
