import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import ConversationTemplateSetup from '~/pages/ConversationTemplateSetup';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('conversationTemplates', {
  routesPrivate: {
    admin: [
      <PrivateRoute
        key={routes.CONVERSATION_TEMPLATE_CREATE.routePath(ROLES.ADMIN)}
        exact
        path={[
          routes.CONVERSATION_TEMPLATE_CREATE.routePath(ROLES.ADMIN),
          routes.CONVERSATION_TEMPLATE_UPDATE.routePath(ROLES.ADMIN),
        ]}
        component={ConversationTemplateSetup}
        allowedRoles={[ROLES.ADMIN]}
      />,
    ],
  },
});
