import { useEffect, useMemo, useState } from 'react';

import { NEXT_STEP_TYPES, USER_REVIEW_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useForm } from 'react-hook-form';

import { STATUS, STATUS_PROVIDER } from '~/constants/statusProvider';
import useBoolState from '~/hooks/useBoolState';
import {
  createStep,
  deleteStep,
  getRelevantSteps,
  switchCompletedState,
  updateStep,
} from '~/services/nextSteps';

import type { INextStepEditorForm, IReviewNextStep, IReviewNextStepsForm } from '../types';

const useNextSteps = ({
  userReviewId,
  status,
  isCreator,
  isEmployee,
  isInputCoach,
  isAdmin,
}: {
  userReviewId?: string;
  status: USER_REVIEW_STATUS;
  isCreator: boolean;
  isEmployee: boolean;
  isInputCoach: boolean;
  isAdmin: boolean;
}) => {
  const { i18n } = useLingui();
  const isVisible = useBoolState(false);
  const [nextSteps, setNextSteps] = useState<IReviewNextStep[]>([]);
  const [editStepId, setEditStepId] = useState('');
  const formMethods = useForm<IReviewNextStepsForm>({
    defaultValues: {},
  });

  const editorFormMethods = useForm<INextStepEditorForm>();

  const fetchNextSteps = async () => {
    const res = await getRelevantSteps({ targetId: userReviewId, type: NEXT_STEP_TYPES.REVIEW });
    setNextSteps(res.data.currentConvSteps.reverse());
  };

  useEffect(() => {
    fetchNextSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async ({ comment, participant }: INextStepEditorForm) => {
    try {
      if (editStepId) {
        await updateStep({
          stepId: editStepId,
          comment,
          participant,
        });
      } else {
        await createStep({
          targetId: userReviewId,
          type: NEXT_STEP_TYPES.REVIEW,
          comment,
          participant,
        });
      }
    } finally {
      setEditStepId('');
      fetchNextSteps();
    }
  };

  const onDelete = async (stepId: string) => {
    await deleteStep(stepId);

    setNextSteps((prevState) => prevState.filter(({ id }) => stepId !== id));
  };

  const onCheck = async ({ id, isCompleted }: Pick<IReviewNextStep, 'id' | 'isCompleted'>) => {
    const res = await switchCompletedState({ stepId: id, isCompleted });

    if (res) {
      setNextSteps((prevState) =>
        prevState.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              isCompleted,
            };
          } else {
            return item;
          }
        }),
      );
    }
  };

  const openNextStepsTooltip = useMemo(() => {
    if (!isCreator && !isEmployee && !isInputCoach && !isAdmin) {
      return i18n._(t`You can't edit the next steps.`);
    }

    const statusProps =
      STATUS_PROVIDER[status === USER_REVIEW_STATUS.PUBLISHED ? STATUS.UPCOMING : status];
    if (statusProps) {
      return i18n._(
        t`You can't edit the next steps because the review has status ${statusProps.text(i18n)}.`,
      );
    }

    return '';
  }, [i18n, isAdmin, isCreator, isEmployee, isInputCoach, status]);

  return {
    isVisible,
    formMethods,
    editorFormMethods,
    editStepId,
    setEditStepId,
    onSubmit,
    onDelete,
    onCheck,
    nextSteps,
    openNextStepsTooltip,
  };
};

export { useNextSteps };
