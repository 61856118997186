import React from 'react';

import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';

import { COLOR_PALETTE, COLOR_SET } from '~/styles';

const ExternalRoleWrapper = styled.div`
  width: 100%;
  background-color: ${(props) => (props.selected ? '#e6e8ec' : '#f6f8fc')};
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
  height: 64px;
  display: grid;
  grid-template-columns: 0.7fr 6fr 13.5fr;
  align-content: center;
`;

const SelectedCheckMark = styled(CheckBox)`
  align-self: center;
  padding: 8px;
`;

const RoleName = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLOR_PALETTE.BLACK};
  display: flex;
  align-items: center;
`;

const ErrorMessage = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 17px;
  color: ${COLOR_SET.RED};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
`;

const ExternalRole = ({ externalRole, isSelected, onToggle, disabled, isShowExternalId }) => (
  <ExternalRoleWrapper
    selected={isSelected}
    onClick={disabled ? null : onToggle}
    key={externalRole.id}
  >
    <SelectedCheckMark disabled={disabled} checked={isSelected} size={24} />
    <RoleName>{`${externalRole.name}${isShowExternalId ? ` (${externalRole.id})` : ''}`}</RoleName>
    {disabled ? (
      <ErrorMessage>
        <Trans>This role already exists. Remove the manual team first.</Trans>
      </ErrorMessage>
    ) : null}
  </ExternalRoleWrapper>
);

ExternalRole.propTypes = {
  disabled: PropTypes.bool,
  externalRole: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default withI18n()(ExternalRole);
