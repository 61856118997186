import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';

import { LucaContainer, Notice } from './Luca.design';

type TProps = { onLucaButtonClick: () => void; maxWidth?: string };

const LucaActions = ({ onLucaButtonClick, maxWidth = '250px' }: TProps): JSX.Element => {
  const { i18n } = useLingui();

  return (
    <LucaContainer>
      <Notice maxWidth={maxWidth}>
        {i18n._(t`Need help analyzing the data or coming up with improvements?`)}
      </Notice>
      <Button
        label={i18n._(t`Ask luca`)}
        type="button"
        variant={ButtonVariant.PRIMARY}
        size={ButtonSize.MEDIUM}
        onClick={onLucaButtonClick}
        icon={ICONS.LUCA}
      />
    </LucaContainer>
  );
};

export { LucaActions };
