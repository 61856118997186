import React, { FunctionComponent } from 'react';

import { PATH_STATUSES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import styled from 'styled-components';

import { LearningItemType } from '~/components/LearningItemType';
import { Marker } from '~/components/Marker';
import RickTextView from '~/components/RickTextView';
import ShowMore from '~/components/ShowMore';

import { COLOR_PALETTE, COLORS } from '~/styles';

const ActivityProgressContainer = styled.div`
  margin-top: auto;
`;

const ActivityProgressRow = styled.div`
  height: 6px;
  border-radius: 3px;
  margin: 0 20px;
  box-sizing: border-box;
  background-color: ${COLOR_PALETTE.WHITE};
`;

const ActivityFill = styled.div<{
  progress: number;
}>`
  background-color: var(--company-color);
  width: ${(props) => props.progress}%;
  height: 100%;
  border-radius: 5.5px;
`;

const CoverImage = styled.div<{
  src: string;
}>`
  height: 152px;
  background: url('${(props) => props.src}');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #a9a9a9;
  width: 100%;
`;

const InfoSection = styled.div<{
  $highMargin: boolean;
}>`
  box-sizing: border-box;
  margin: ${(props) => (props.$highMargin ? '70px 20px 9px' : '9px 20px')};
  color: ${COLORS.TEXT_PRIMARY_2};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
`;

const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px 31px 0px 31px;
  justify-self: flex-end;
`;

const LabelCell = styled.div<{
  $isFlexStart?: boolean;
}>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.$isFlexStart ? 'flex-start' : 'center')};
`;

const ProgressionText = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--company-color);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressWrapp = styled.div`
  margin-top: 32px;
`;

const RichTextViewWrapper = styled(RickTextView)`
  color: ${COLORS.TEXT_BLACK};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.13px;
`;

interface ITopSideBarBlock {
  image?: string;
  description?: string;
  progress?: number | null;
  status: PATH_STATUSES;
}

const TopSideBarBlock: FunctionComponent<ITopSideBarBlock> = ({
  image,
  description,
  progress,
  status,
}) => {
  const { i18n } = useLingui();
  const isProgress = !!progress && typeof progress === 'number' && progress > 0;
  const isDraft = status === PATH_STATUSES.DRAFT;
  return (
    <>
      {!_.isEmpty(image) && <CoverImage src={image as string} />}
      <InfoSection $highMargin={_.isEmpty(image)}>
        {!_.isEmpty(description) && (
          // @ts-ignore
          <ShowMore maxHeight={84} showMoreClassName="show-more-path">
            <RichTextViewWrapper html={description} />
          </ShowMore>
        )}
      </InfoSection>
      <ProgressWrapp>
        {isProgress && (
          <ActivityProgressContainer>
            <ActivityProgressRow>
              <ActivityFill progress={progress as number} />
            </ActivityProgressRow>
          </ActivityProgressContainer>
        )}
        <LabelRow>
          <LabelCell $isFlexStart>
            <LearningItemType isActivity={false} type={null} />
          </LabelCell>
          {isDraft ? (
            <LabelCell>
              <Marker label={i18n._(t`draft`)} />
            </LabelCell>
          ) : (
            isProgress && (
              <LabelCell>
                <ProgressionText>{i18n._(t`Progr. ${progress}%`)}</ProgressionText>
              </LabelCell>
            )
          )}
        </LabelRow>
      </ProgressWrapp>
    </>
  );
};

export default TopSideBarBlock;
