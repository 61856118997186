import { useMemo, useState } from 'react';

interface MultiSelectState<T> {
  onChange: (items: T[]) => void;
  preselectedItems?: T[];
  hashItem?: (item: T) => string;
  selectedItems: T[];
}

interface SingleSelectState<T> {
  onChange: (item?: T) => void;
  preselectedItem?: T;
  hashItem?: (item: T) => string;
  selectedItem: T | undefined;
}

interface BaseReturnType<T> {
  hasSelectedItem: boolean;
  showDropdown: boolean;
  handleNewItemSelected: (item: T) => void;
  toggleDropdown: () => void;
  isItemSelected: (item: T) => boolean;
  hideDropdown: () => void;
  reset: () => void;
}

function useMultiSelectState<T>({
  hashItem,
  selectedItems,
  onChange,
}: MultiSelectState<T>): BaseReturnType<T> {
  const [showDropdown, setShowDropdown] = useState(false);
  const hashes = useMemo(() => {
    return new Set(selectedItems.map((item) => uniqueItemIdentifier(item)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  function uniqueItemIdentifier(item: T) {
    if (hashItem) {
      return hashItem(item);
    }
    if ((item as unknown as { id: string }).id) {
      return (item as unknown as { id: string }).id;
    }
    return item;
  }

  function handleNewItemSelected(newItem: T) {
    const newHash = uniqueItemIdentifier(newItem);
    if (hashes.has(newHash)) {
      onChange(selectedItems.filter((item) => uniqueItemIdentifier(item) !== newHash));
    } else {
      onChange([...selectedItems, newItem]);
    }
  }

  function reset() {
    onChange([]);
  }

  function toggleDropdown() {
    setShowDropdown((prev) => !prev);
  }

  function isItemSelected(item: T) {
    return hashes.has(uniqueItemIdentifier(item));
  }

  function hideDropdown() {
    setShowDropdown(false);
  }

  const hasSelectedItem = selectedItems.length > 0;

  return {
    showDropdown,
    hasSelectedItem,
    isItemSelected,
    handleNewItemSelected,
    toggleDropdown,
    hideDropdown,
    reset,
  };
}

function useSingleSelectState<T>({
  hashItem,
  selectedItem,
  onChange,
}: SingleSelectState<T>): BaseReturnType<T> {
  const [showDropdown, setShowDropdown] = useState(false);

  function uniqueItemIdentifier(item?: T) {
    if (hashItem && item) {
      return hashItem(item);
    }
    if ((item as { id?: string })?.id) {
      return (item as { id?: string })?.id;
    }
    return item;
  }

  function handleNewItemSelected(newItem: T) {
    onChange(newItem);
    setShowDropdown(false);
  }

  function reset() {
    onChange(undefined);
    setShowDropdown(false);
  }

  function isItemSelected(item: T) {
    return uniqueItemIdentifier(selectedItem) === uniqueItemIdentifier(item);
  }

  const hasSelectedItem = !!selectedItem;

  function toggleDropdown() {
    setShowDropdown((prev) => !prev);
  }

  function hideDropdown() {
    setShowDropdown(false);
  }

  return {
    showDropdown,
    hasSelectedItem,
    isItemSelected,
    handleNewItemSelected,
    reset,
    toggleDropdown,
    hideDropdown,
  };
}

export { useMultiSelectState, useSingleSelectState };
