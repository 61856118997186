const minutesPerBlock = 30;
const minutesInDay = Array.from(
  { length: 24 * (60 / minutesPerBlock) },
  (_, index) => index * minutesPerBlock,
);

const stringifyTime = (minutes: number) =>
  `${Math.floor(minutes / 60)
    .toString()
    .padStart(2, '0')}:${(minutes % 60).toString().padStart(2, '0')}`;

const getHours = (minutes: number) => {
  return Math.floor(minutes / 60);
};

const getMinutes = (minutes: number) => {
  return minutes % 60;
};

export { minutesInDay, stringifyTime, getHours, getMinutes };
