import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  margin-top: 43px;
`;

export const HeaderSection = styled.div`
  margin: 0 32px 0 32px;
  display: flex;
  justify-content: space-between;
`;

export const SubTitle = styled.div`
  font-size: 14px;
  color: ${COLORS.INPUT_TITLE};
`;
