export enum GOAL_TASK_STATUSES {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
}

export enum GOAL_TASK_REMINDER_STATUSES {
  NOT_SENT = 'not-sent',
  REMINDER_SENT = 'reminder-sent',
  DEADLINE_REMINDER_SENT = 'deadline-reminder-sent',
}
