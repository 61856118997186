import React from 'react';

import { GOAL_TYPES, GOAL_VIEWERS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch, useSelector } from 'react-redux';

import { confirm } from '~/components/ConfirmDialog';
import { Dropdown } from '~/components/Dropdown';
import ToolTip, { TOOLTIP_PLACEMENTS, TOOLTIP_SIZES } from '~/components/Tooltip';

import { getDeadline, getViewers, getType, getParent } from '~/selectors/currentGoal';
import {
  setCurrentGoalDeadline,
  syncGoalActivitiesDeadline,
  setCurrentGoalViewers,
} from '~/store/currentGoal/actions';

import {
  ItemContainer,
  ItemLabel,
  ItemRow,
  ItemSubLabel,
  ItemLabelRow,
  StyledDatePicker,
} from '../design';

import type { I18n } from '@lingui/core';

const PRIVACY_ITEMS = [
  {
    value: GOAL_VIEWERS.OWNER_AND_COACHES,
    name: (i18n: I18n) => i18n._(t`Private`),
    types: [GOAL_TYPES.BUSINESS, GOAL_TYPES.PERSONAL],
    tooltip: (i18n: I18n) => i18n._(t`Goal owner and coach(es) of goal owner`),
  },
  {
    value: GOAL_VIEWERS.TEAMS,
    name: (i18n: I18n) => i18n._(t`Team`),
    types: [GOAL_TYPES.BUSINESS, GOAL_TYPES.PERSONAL, GOAL_TYPES.TEAM],
    tooltip: (i18n: I18n) =>
      i18n._(
        t`Owner(s), coach(es) of owner(s), coach(es) of selected team(s), members of selected team(s)`,
      ),
  },
  {
    value: GOAL_VIEWERS.PUBLIC,
    name: (i18n: I18n) => i18n._(t`Public`),
    types: [...Object.values(GOAL_TYPES)],
    tooltip: (i18n: I18n) => i18n._(t`All members in the company`),
  },
];

const PrivacyAndDeadlineSection = () => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const deadline = useSelector(getDeadline);
  const viewers = useSelector(getViewers);
  const type = useSelector(getType);
  const goalParent = useSelector(getParent);

  const handleChangeDeadline = (date: Date) => {
    const oldDeadline = deadline;
    // @ts-ignore
    dispatch(setCurrentGoalDeadline(date));
    if (oldDeadline) {
      dispatch(syncGoalActivitiesDeadline(oldDeadline));
    }
  };

  const onChangeViewers = async (viewer: GOAL_VIEWERS) => {
    if (goalParent) {
      const confirmed = await confirm(
        i18n,
        // @ts-ignore
        i18n._(
          t`You are about to change the goal viewers. The selection made in the alignment step will be lost.`,
        ),
        { title: i18n._(t`Are you sure?`) },
      );
      if (!confirmed) {
        return;
      }
    }
    // @ts-ignore
    dispatch(setCurrentGoalViewers(viewer));
  };

  const privacyOptions = PRIVACY_ITEMS.map((privacy) => {
    return {
      ...privacy,
      tooltip: privacy.tooltip(i18n),
    };
  });

  return (
    <ItemRow marginTop="16px" marginBottom="25px">
      <ToolTip
        maxWidth="600px"
        size={TOOLTIP_SIZES.MEDIUM}
        placement={TOOLTIP_PLACEMENTS.BOTTOM}
        tooltip={privacyOptions.find((o) => o.value === viewers)?.tooltip}
      >
        <div>
          <ItemContainer marginRight="21px">
            <ItemLabel>
              <Trans>People who can view this goal</Trans>
            </ItemLabel>
            {/* @ts-ignore */}
            <Dropdown
              items={privacyOptions}
              selectedItem={privacyOptions.find(
                (item) => item.value === GOAL_VIEWERS.OWNER_AND_COACHES,
              )}
              // @ts-ignore
              onChange={(selectedItem) => onChangeViewers(selectedItem.value)}
              stringifyItem={(item: any) => item.name(i18n)}
              isSingleSelect
              isClickable={type !== GOAL_TYPES.COMPANY}
              className="goal-setup-dropdown"
              selectHeight="38px"
              skipSort
            />
          </ItemContainer>
        </div>
      </ToolTip>
      <ItemContainer>
        <ItemLabelRow>
          <ItemLabel>
            <Trans>Deadline</Trans>
          </ItemLabel>
          <ItemSubLabel>
            <Trans>(optional)</Trans>
          </ItemSubLabel>
        </ItemLabelRow>
        <StyledDatePicker
          // @ts-ignore
          selected={deadline}
          width={260}
          height="38px"
          minDate={new Date()}
          onChange={handleChangeDeadline}
          placeholder={i18n._(t`Select date`)}
        />
      </ItemContainer>
    </ItemRow>
  );
};

export { PrivacyAndDeadlineSection };
