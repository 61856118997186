import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const CollapseIcon = ({ width, height, ...props }) => (
  <InlineSvg width={width} height={height} viewBox="0 0 20 16" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M.781 6.114 6.115.78a2.668 2.668 0 0 1 4.552 1.886c0 .712-.278 1.382-.782 1.885L6.437 7.999l3.448 3.449c.504.504.782 1.173.782 1.885s-.278 1.382-.782 1.886A2.648 2.648 0 0 1 8 16a2.648 2.648 0 0 1-1.885-.781L.78 9.885A2.647 2.647 0 0 1 0 7.999c0-.712.277-1.382.781-1.885zm.943 2.828 5.333 5.334c.506.504 1.38.504 1.886 0 .52-.52.52-1.366 0-1.886L4.552 8l4.39-4.392c.253-.252.391-.586.391-.942s-.138-.691-.39-.943a1.334 1.334 0 0 0-1.885-.001L1.723 7.054a1.33 1.33 0 0 0-.39.944c0 .356.138.691.39.943z" />
      <path d="M10.115 6.114 15.448.78A2.668 2.668 0 0 1 20 2.666c0 .712-.277 1.382-.781 1.885L15.77 7.999l3.448 3.449c.504.504.781 1.173.781 1.885s-.277 1.382-.781 1.886a2.648 2.648 0 0 1-1.886.781 2.648 2.648 0 0 1-1.885-.781l-5.333-5.334a2.647 2.647 0 0 1-.782-1.886c0-.712.278-1.382.782-1.885zm.942 2.828 5.334 5.334c.505.504 1.38.504 1.885 0 .52-.52.52-1.366 0-1.886L13.886 8l4.39-4.392c.252-.252.39-.586.39-.942s-.138-.691-.39-.943a1.334 1.334 0 0 0-1.885-.001l-5.334 5.333a1.33 1.33 0 0 0-.39.944c0 .356.138.691.39.943z" />
    </g>
  </InlineSvg>
);

CollapseIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

CollapseIcon.defaultProps = {
  width: 20,
  height: 16,
  className: '',
};

export default CollapseIcon;
