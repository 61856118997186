import React, { useCallback } from 'react';

import { t } from '@lingui/macro';
import styled from 'styled-components';

import ProgressBarWide from '~/components/ProgressBarWide';
import Table, { TableRow, TableCol } from '~/components/UI/Table';
import { Header5 } from '~/components/UI/Typographics/headers';

import { COLORS } from '~/styles';

const ProgressRow = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
`;

const ProgressValue = styled.span`
  color: ${COLORS.TEXT_BLACK};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  margin-left: 12px;
`;

const StyledHeader5 = styled(Header5)`
  margin: 0;
`;

const cols = [
  {
    title: t`Team`,
  },
  {
    title: t`Members`,
    width: '106px',
  },
  {
    title: t`Total`,
    width: '210px',
  },
  {
    title: t`Avg. per member`,
    width: '210px',
  },
];

const ConversationsTeamsTable = ({ tableData }) => {
  let maxTotalCount = 0;
  let maxAvgCount = 0;

  tableData.map((t) => {
    if (t.totalConversations > maxTotalCount) {
      maxTotalCount = t.totalConversations;
    }
    if (t.totalConversations / t.membersCount > maxAvgCount) {
      maxAvgCount = t.totalConversations / t.membersCount;
    }
  });

  const renderRow = useCallback(
    (item) => {
      const total = (item.totalConversations / maxTotalCount) * 100;
      const itemAvg =
        item.totalConversations === 0 || item.membersCount === 0
          ? 0
          : (item.totalConversations / item.membersCount).toFixed(1);
      const avg = (itemAvg / maxAvgCount) * 100;

      return (
        <TableRow key={item.id} $border height="56px">
          <TableCol height="56px">
            <StyledHeader5>{item.name}</StyledHeader5>
          </TableCol>
          <TableCol height="56px">{item.membersCount}</TableCol>
          <TableCol height="56px">
            <ProgressRow>
              <ProgressBarWide value={total || 0} rounded isActive height="8px" />
              <ProgressValue>{item.totalConversations || 0}</ProgressValue>
            </ProgressRow>
          </TableCol>
          <TableCol height="56px">
            <ProgressRow>
              <ProgressBarWide value={avg || 0} rounded isActive height="8px" />
              <ProgressValue>{itemAvg || 0}</ProgressValue>
            </ProgressRow>
          </TableCol>
        </TableRow>
      );
    },
    [maxAvgCount, maxTotalCount],
  );

  return (
    <>
      <Table cols={cols} items={tableData} renderRow={renderRow} hideHeader noTopBorder />
    </>
  );
};

export default React.memo(ConversationsTeamsTable);
