import React, { useState, ReactElement, useEffect } from 'react';

import { ACTIVITY_TYPES as TACTIVITY_TYPES, API_RETURN_FIELDS } from '@learned/constants';
import { IActivity, ISkill, IActivityCategory } from '@learned/types';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import filter from 'lodash/filter';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ActivityMainInfoEdit } from '~/components/Activity/ActivityMainInfoEdit';
import {
  BottomRow,
  Header,
  HeaderTitle,
  ContentWrap,
  CloseIconButton,
  ModalWrap,
  DeleteIcon,
} from '~/components/Activity/base_components';
import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import Dialog from '~/components/Dialog';
import { ManageActivityCategoriesModal } from '~/components/Modals/ManageActivityCategoriesModal';
import SelectCheckboxItemsModal from '~/components/SelectCheckboxItemsModal';
import SkillsLevelsModal from '~/components/SkillsLevelsModal';
import SvgIcon from '~/components/SvgIcon';

import Measurement from './Measurement';
import { Settings } from './Settings';
import { SkillsAndCategories } from './SkillsAndCategories';

import closeIcon from '~/assets/close-icn.svg';

import { ACTIVITY_TYPES } from '~/constants';
import { getCurrentCompanyId, getUser } from '~/selectors/baseGetters';
import {
  getActivity,
  removeActivityAttachment,
  createOriginalLibraryActivity,
  updateOriginalLibraryActivity,
  createOriginalCustomActivity,
} from '~/services/activities';
import { getActivityCategories } from '~/services/activityCategories';
import { uploadSingle } from '~/services/files';
import { COLORS } from '~/styles';
import isValidUrl from '~/utils/isValidUrl';
import isValidYoutubeUrl from '~/utils/isValidYoutubeUrl';

import type { AxiosResponse } from 'axios';

function IDMapper(item: ISkill | IActivityCategory) {
  return item.id || item;
}

type IEditLibraryActivityModalProps = {
  currentActivity: IActivity;
  onHideModal: () => void;
  onSaveActivity: (activity: IActivity) => void;
  deleteActivity?: () => void;
  isCustom?: boolean; // define if custom original activity (could be created for Path)
};
interface IFile {
  link: string;
  id: string;
  name: string;
}

const ManageCategoriesButton = styled(Button)`
  height: 40px;
  text-transform: uppercase;
  font-size: 12px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 14px;
`;

function EditLibraryActivityModal({
  currentActivity,
  onHideModal,
  onSaveActivity,
  deleteActivity,
  isCustom = false,
}: IEditLibraryActivityModalProps): ReactElement {
  const { i18n } = useLingui();
  const currentCompanyId = useSelector(getCurrentCompanyId);
  const user = useSelector(getUser);
  const [activity, setActivity] = useState<IActivity>({
    ...currentActivity,
    ...(isCustom && { type: TACTIVITY_TYPES.OTHER }),
  });
  const [update, setUpdate] = useState<IActivity | {}>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const [isShowMainModal, setIsShowMainModal] = useState<boolean>(true);
  const [isShowSkillsModal, setIsShowSkillsModal] = useState<boolean>(false);
  const [isShowCategoryModal, setIsShowCategoryModal] = useState<boolean>(false);
  const [isManageCategoryModalVisible, setIsManageCategoryModalVisible] = useState<boolean>(false);
  const [isValidLink, setIsValidLink] = useState<boolean>(true);
  const [activityCategories, setActivityCategories] = useState<{
    [key: string]: IActivityCategory;
  }>({});
  const [selectedCategories, setSelectedCategories] = useState<IActivityCategory[]>([]);
  const [defaultSelectedCategories, setDefaultSelectedCategories] = useState<IActivityCategory[]>(
    [],
  );
  const [selectedSkills, setSelectedSkills] = useState<ISkill[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      let updatedActivity: AxiosResponse<{ activity: IActivity }> | {} = {};
      if (currentActivity && currentActivity.id) {
        const populate: string[] = ['skills', 'files'];
        const updatedActivityData = await getActivity(currentActivity.id, populate);
        updatedActivity = updatedActivityData.data[API_RETURN_FIELDS.ACTIVITY];
      }
      const { data } = await getActivityCategories();
      const activityCategories = data[API_RETURN_FIELDS.ACTIVITY_CATEGORIES];
      if (!_.isEmpty(updatedActivity)) {
        // check skills
        let activitySelectedSkills: ISkill[] | [] = [];
        if (!_.isEmpty((updatedActivity as IActivity).skills)) {
          activitySelectedSkills = ((updatedActivity as IActivity).skills as ISkill[]).filter(
            (s: ISkill): boolean => !_.isEmpty(s),
          );
        }

        // check activity categories
        const selectedCategories = activity.categories
          ? activity.categories.map((categoryId) => activityCategories[categoryId]).filter((c) => c)
          : [];
        // @ts-ignore
        setActivity({ ...updatedActivity, ...(activity.links && { link: activity.links[0] }) });
        setSelectedSkills(activitySelectedSkills);
        setSelectedCategories(selectedCategories);
        setDefaultSelectedCategories(selectedCategories);
      }
      setActivityCategories(activityCategories);
    };
    fetchData();
    // eslint-disable-next-line
  }, [currentActivity, isManageCategoryModalVisible]);

  // check required fields
  const checkRequiredFields = () => {
    if (!isValidLink) {
      return true;
    }
    if (_.isEmpty(activity.type) || _.isEmpty(activity.name)) {
      return true;
    }
    switch (activity.type) {
      case ACTIVITY_TYPES.VIDEO.key:
      case ACTIVITY_TYPES.ARTICLE.key:
      case ACTIVITY_TYPES.PODCAST.key:
      case ACTIVITY_TYPES.WEBINAR.key:
        return _.isEmpty(activity.link);
      default:
        return false;
    }
  };

  const downloadFile = (file: IFile) => () => {
    setTimeout(() => {
      // server sent the url to the file!
      // now, let's download:
      window.open(file.link);
      // you could also do:
      // window.location.href = response.file;
    }, 100);
  };

  const handleChangeDescription = (value: string) => {
    setActivity({ ...activity, description: value });
    setUpdate({ ...update, description: value });
  };

  const onChangeType = (type: TACTIVITY_TYPES) => {
    setActivity({ ...activity, type });
    setUpdate({ ...update, type });
    const isValidLink = !_.isEmpty(activity.link)
      ? type === ACTIVITY_TYPES.VIDEO.key
        ? isValidYoutubeUrl(activity.link)
        : isValidUrl(activity.link)
      : true;
    setIsValidLink(isValidLink);
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;
    setUpdate({ ...update, [name]: value });
    setActivity({ ...activity, [name]: value });
    if (name === 'link') {
      const isValidLink = value
        ? activity?.type === ACTIVITY_TYPES.VIDEO.key
          ? isValidYoutubeUrl(value)
          : isValidUrl(value)
        : true;
      setIsValidLink(isValidLink);
    }
  };

  const hideCategoryModal = () => {
    setIsShowCategoryModal(false);
    setIsShowMainModal(true);
  };

  const hideSkillsModal = () => {
    setIsShowSkillsModal(false);
    setIsShowMainModal(true);
  };

  const onCoverUpload = (url: string) => {
    setUpdate({ ...update, coverUrl: url });
    setActivity({ ...activity, coverUrl: url });
  };

  const onUploadChange = (file: IFile) => {
    if (file) {
      setFileUploading(true);

      uploadSingle(file, file.name).then((result) => {
        const link = result.url;
        const id = result.id;
        const name = result.name;
        const f = { link, id, name };
        setUpdate({
          ...update,
          files: [...(activity.files || []), f] as IFile[], // activity.files need to prevent overwrite all attachments
        });
        setActivity({
          ...activity,
          files: [...(activity.files || []), f] as IFile[], // activity.files need to prevent overwrite all attachments
        });
        setFileUploading(false);
      });
    }
  };

  const onToggleAvailableInLibrary = () => {
    setUpdate({ ...update, isAvailableInUserLibrary: !activity.isAvailableInUserLibrary });
    setActivity({ ...activity, isAvailableInUserLibrary: !activity.isAvailableInUserLibrary });
  };

  // hidden for now
  /* const onToggleRateAvailable = () => {
    setUpdate({ ...update, isRateAvailable: !activity.isRateAvailable });
    setActivity({ ...activity, isRateAvailable: !activity.isRateAvailable });
  }; */

  const removeAttachment = async (fileId: string) => {
    const activityFiles = (activity.files as IFile[]).filter((file: IFile) => file.id !== fileId);

    if (activity.id && fileId && currentActivity.files && !_.isEmpty(currentActivity.files)) {
      const isFileInActivity = (currentActivity.files as IFile[]).filter(
        (file: IFile) => file.id === fileId,
      );
      if (isFileInActivity) {
        await removeActivityAttachment(activity.id, fileId);
      }
    }
    setUpdate({ ...update, files: activityFiles });
    setActivity({ ...activity, files: activityFiles });
  };

  const onChange = (data = {}) => {
    setUpdate({ ...update, ...data });
    setActivity({ ...activity, ...data });
  };

  const saveActivity = async () => {
    setLoading(true);

    // save only files id
    if (!_.isEmpty(activity.files)) {
      if (activity.id) {
        if (!_.isEmpty((update as IActivity).files)) {
          (update as IActivity).files = ((update as IActivity).files as IFile[]).map(
            (file: IFile) => (file.id ? file.id : file),
          ) as IFile[];
        }
      } else {
        activity.files = (activity.files as IFile[]).map((file: IFile) =>
          file.id ? file.id : file,
        ) as IFile[];
      }
    }

    const activitySkillsBefore = ((activity.skills as ISkill[]) || []).map(IDMapper);
    const activitySkillsAfter = selectedSkills.map(IDMapper);
    // add selected skills id
    if (!_.isEqual(activitySkillsBefore, activitySkillsAfter)) {
      if (activity.id) {
        (update as IActivity).skills = activitySkillsAfter as ISkill[];
      } else {
        activity.skills = activitySkillsAfter as ISkill[];
      }
    }

    const activityCategoriesBefore = defaultSelectedCategories.map(IDMapper);
    const activityCategoriesAfter = selectedCategories.map(IDMapper);
    // add selected categories
    if (!_.isEqual(activityCategoriesBefore, activityCategoriesAfter)) {
      if (activity.id) {
        (update as IActivity).categories = activityCategoriesAfter as IActivityCategory[];
      } else {
        activity.categories = activityCategoriesAfter as IActivityCategory[];
      }
    }

    let newActivity: IActivity | {} = {};
    if (!activity.id) {
      newActivity = isCustom
        ? await createOriginalCustomActivity(activity)
        : await createOriginalLibraryActivity(activity);
    } else {
      if (!_.isEmpty(update)) {
        const textConfirm = i18n._(
          t`Are you sure want to update activity? This changes will be immediately visible for assigned users.`,
        );
        if (await confirm(i18n, textConfirm)) {
          // update logic for library and custom activity are the same
          newActivity = await updateOriginalLibraryActivity(activity.id, update);
        } else {
          setLoading(false);
          return;
        }
      }
    }
    onSaveActivity(newActivity as IActivity);
    setLoading(false);
    onHideModal();
  };

  const showCategoryModal = () => {
    setIsShowCategoryModal(true);
    setIsShowMainModal(false);
  };

  const showSkillsModal = () => {
    setIsShowSkillsModal(true);
    setIsShowMainModal(false);
  };

  const updateSelectedCategories = (ids: string[]) => {
    setSelectedCategories(ids.map((id: string) => activityCategories[id]));
  };

  const updateSelectedSkills = (newSelectedSkills: ISkill[]) => {
    setSelectedSkills(newSelectedSkills);
  };

  const unSelectCategory = (category: IActivityCategory) => {
    updateSelectedCategories(
      selectedCategories.filter((c) => c && c.id !== category.id).map((c) => c.id),
    );
  };

  const unSelectSkill = (skill: ISkill) => {
    let newSelectedSkills = [...selectedSkills];
    newSelectedSkills = newSelectedSkills.filter((s) => s.id !== skill.id);
    updateSelectedSkills(newSelectedSkills);
  };

  const isSubmitDisabled = checkRequiredFields();

  const selectedCategoriesIds = selectedCategories.map((a) => a.id);

  return (
    <Dialog width="auto" minWidth="auto" isCloseOnOutsideClick={true}>
      {isShowMainModal && (
        <ModalWrap>
          {/* @ts-ignore */}
          <Header height="81px" padding="20px 26px 20px 33px">
            <HeaderTitle>
              {activity.id
                ? i18n._(t`Edit learning activity`)
                : i18n._(t`Create new learning activity`)}
            </HeaderTitle>
            <CloseIconButton onClick={onHideModal}>
              <SvgIcon
                width="20px"
                height="20px"
                url={closeIcon}
                isDefaultColor
                defaultColor={COLORS.TEXT_BLACK}
                // @ts-ignore
                alt={i18n._(t`Close`)}
              />
            </CloseIconButton>
          </Header>
          <ContentWrap
            // @ts-ignore
            $padding="24px 31px 69px 33px"
          >
            <ActivityMainInfoEdit
              activity={activity}
              isValidLink={isValidLink}
              fileUploading={fileUploading}
              handleChangeInput={handleChangeInput}
              onCoverUpload={onCoverUpload}
              handleChangeDescription={handleChangeDescription}
              downloadFile={downloadFile}
              removeAttachment={removeAttachment}
              onUploadChange={onUploadChange}
              onChangeType={onChangeType}
              isCustom={isCustom}
            />
            {!isCustom && (
              <SkillsAndCategories
                selectedSkills={selectedSkills}
                unSelectSkill={unSelectSkill}
                showSkillsModal={showSkillsModal}
                selectedCategories={selectedCategories}
                unSelectCategory={unSelectCategory}
                showCategoryModal={showCategoryModal}
              />
            )}
            <Measurement value={activity.progressType} onChange={onChange} itemWidth="338px" />
            {!isCustom && (
              <Settings
                onToggleAvailableInLibrary={onToggleAvailableInLibrary}
                isAvailableInUserLibrary={activity.isAvailableInUserLibrary}
              />
            )}
            <BottomRow>
              <div>
                {activity.id && deleteActivity && (
                  <DeleteIcon onClick={deleteActivity}>
                    <Trans>Delete this learning activity</Trans>
                  </DeleteIcon>
                )}
              </div>
              <Button
                type="white"
                disabled={loading || isSubmitDisabled}
                loading={loading}
                onClick={saveActivity}
                styles={{
                  height: '48px',
                  minWidth: '111px',
                }}
                label={activity.id ? i18n._(t`Save`) : i18n._(t`Publish`)}
              />
            </BottomRow>
          </ContentWrap>
        </ModalWrap>
      )}
      {isShowSkillsModal && (
        <SkillsLevelsModal
          // @ts-ignore
          selectedSkills={selectedSkills}
          saveSelectedSkills={updateSelectedSkills}
          closeModal={hideSkillsModal}
          withoutLevels={true}
        />
      )}
      {isShowCategoryModal && (
        <SelectCheckboxItemsModal
          closeModal={hideCategoryModal}
          itemsList={filter(
            activityCategories,
            (category) =>
              String(category.company) === String(currentCompanyId) &&
              selectedCategoriesIds.indexOf(category.id) === -1,
          )}
          selectedIds={selectedCategoriesIds}
          updateSelectedList={updateSelectedCategories}
          headerTitle={i18n._(t`Add categories`)}
          searchTitle={i18n._(t`Search for categories`)}
          footerButtons={
            user?.isAdmin && (
              <ManageCategoriesButton
                type="white"
                onClick={() => {
                  setIsManageCategoryModalVisible(true);
                }}
                label={i18n._(t`Manage categories`)}
              />
            )
          }
        />
      )}
      {isManageCategoryModalVisible && (
        <ManageActivityCategoriesModal
          // @ts-ignore
          title={i18n._(t`Manage categories`)}
          onClose={() => {
            setIsManageCategoryModalVisible(false);
          }}
        />
      )}
    </Dialog>
  );
}

export { EditLibraryActivityModal };
