import React from 'react';

import { LangOption } from './LangOption';

import { SYSTEM_LANG } from '~/constants/languages';

import type { ILangItem } from './';

const SystemLangOptionComponent = ({
  item,
  isSelected,
  handleNewItemSelected,
}: {
  item: 'en' | 'nl';
  isSelected: boolean;
  handleNewItemSelected: (item: ILangItem) => void;
}) => {
  const langToRender = Object.values(SYSTEM_LANG).find((i) => i.key === item);
  return (
    <LangOption
      langToRender={langToRender as ILangItem}
      isSelected={isSelected}
      // @ts-ignore
      onClick={() => handleNewItemSelected(langToRender.key)}
    />
  );
};

export { SystemLangOptionComponent };
