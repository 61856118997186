import { IJobLevelGroup } from '@learned/types';

import type { IGetServerRoute, IServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

/**
 * @param options
 * @param [options.search]
 * @param [options.status]
 * @param [options.limit]
 * @param {string[]} populate
 * @param {string[]} [fields]
 * @returns {Promise<AxiosResponse<*>>}
 */
export function getJobLevelGroups(options = {}, populate = [], fields = []) {
  return cloudRequest(serverRoutes.jobLevelGroups.getJobLevelGroups as IServerRoute, {
    options,
    populate,
    fields,
  });
}

export function getJobLevelGroup(jobLevelGroupId: string) {
  return cloudRequest(
    (serverRoutes.jobLevelGroups.getJobLevelGroup as IGetServerRoute)(jobLevelGroupId),
  );
}

export function updateJobLevelGroup(data: Omit<IJobLevelGroup, 'company' | 'meta'>) {
  return cloudRequest(serverRoutes.jobLevelGroups.updateJobLevelGroup as IServerRoute, {}, data);
}

export function deleteJobLevelGroups(jobGroupIds: string[]) {
  return cloudRequest(
    serverRoutes.jobLevelGroups.deleteJobLevelGroups as IServerRoute,
    {},
    {
      jobGroupIds,
    },
  );
}

export function createJobLevelGroup(body: Omit<IJobLevelGroup, 'id' | 'meta'>) {
  return cloudRequest(serverRoutes.jobLevelGroups.createJobLevelGroup as IServerRoute, {}, body);
}
