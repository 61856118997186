import React from 'react';

import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { UseFormReturn } from 'react-hook-form';

import type {
  IQuestionForm,
  ISelectedSkill,
  ISkill,
  ISkillCategory,
} from '~/pages/ReviewThemeSetup/types';

import { AddSkillButtonContainer } from './design';

import emptyState from '~/assets/no-result.webp';

import { SkillTable } from '../../../Questions/CustomSkill/SkillTable';
import { QuestionDescription, Button } from '../EditableQuestion/design';

interface ICustomSkillSectionProps {
  formMethods: UseFormReturn<IQuestionForm>;
  skillCategories: ISkillCategory[];
  skillsList: ISkill[];
  selectedSkills: ISelectedSkill[] | undefined;
  setSelectedSkills: (skills: ISelectedSkill[]) => void;
  setSelectedSkill?: (skill: ISkill | null) => void;
  setIsSkillModalOpen: (open: boolean) => void;
  setCurrentStep?: (step: number) => void;
  onAddSkillClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const CustomSkillSection = ({
  formMethods,
  skillCategories,
  skillsList,
  selectedSkills,
  setSelectedSkills,
  setSelectedSkill,
  setIsSkillModalOpen,
  setCurrentStep,
  onAddSkillClick,
}: ICustomSkillSectionProps) => {
  const {
    formState: { errors },
  } = formMethods;

  return (
    <>
      {selectedSkills?.length ? (
        <>
          <QuestionDescription>
            <Trans>A separate question will be generated for each selected skill.</Trans>
          </QuestionDescription>
          <SkillTable
            formMethods={formMethods}
            skillCategories={skillCategories}
            skills={skillsList}
            selectedSkills={selectedSkills}
            setSelectedSkills={setSelectedSkills}
            setSelectedSkill={setSelectedSkill}
            setIsSkillModalOpen={setIsSkillModalOpen}
            setCurrentStep={setCurrentStep}
          />
        </>
      ) : (
        <AddSkillButtonContainer>
          <p>No skills yet...</p>
          <img src={emptyState} alt={i18n._(t`No data found`)} width="216px" />
          <Button onClick={onAddSkillClick}>
            <Trans>Add skill</Trans>
          </Button>
          {errors.settings?.skills && (
            <span>
              <Trans>Please select a custom skill</Trans>
            </span>
          )}
        </AddSkillButtonContainer>
      )}
    </>
  );
};

export { CustomSkillSection };
