import React from 'react';

import { VIRTUAL_SURVEY_TASK_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { ColorByPercentage } from '~/components/TableGrid';
import { isValueAllowed } from '~/components/TableGrid/utils';

import { COLORS } from '~/styles';

import type { ISurveyMember, ISurveyMemberEvent } from '../../../types';
import type { IUser } from '@learned/types';

const CellBlock = styled.div`
  height: 59px;
  box-sizing: border-box;
  min-width: 151px;
`;

const WhiteCell = styled.div`
  height: 59px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.INACTIVE};
`;

export const VIRTUAL_SURVEY_TASK_STATUS_STYLES = {
  [VIRTUAL_SURVEY_TASK_STATUS.TODO]: {
    key: VIRTUAL_SURVEY_TASK_STATUS.TODO,
    title: t`To do`,
    value: 19,
  },
  [VIRTUAL_SURVEY_TASK_STATUS.DRAFT]: {
    key: VIRTUAL_SURVEY_TASK_STATUS.DRAFT,
    title: t`In progress`,
    value: 40,
  },
  [VIRTUAL_SURVEY_TASK_STATUS.COMPLETED]: {
    key: VIRTUAL_SURVEY_TASK_STATUS.COMPLETED,
    title: t`Completed`,
    value: 80,
  },
  [VIRTUAL_SURVEY_TASK_STATUS.ARCHIVED]: {
    key: VIRTUAL_SURVEY_TASK_STATUS.ARCHIVED,
    title: t`Archived`,
    value: 60,
  },
};

function Cell({
  surveyMembers,
  selectedItems,
  eventId,
  user,
}: {
  surveyMembers: ISurveyMember[];
  selectedItems: string[];
  eventId: string;
  user: string;
}) {
  const { i18n } = useLingui();

  // @ts-ignore
  const userEvents =
    surveyMembers.find(
      (member: ISurveyMember) => (member.user as IUser).id === user || member.user === user,
    )?.events || [];

  if (!userEvents.length) {
    return <CellBlock />;
  }

  // @ts-ignore
  const eventStatus = (userEvents as ISurveyMemberEvent[]).find(
    (event: { eventId: string; status: VIRTUAL_SURVEY_TASK_STATUS }) => event.eventId === eventId,
  ).status;

  const eventStatusStyle =
    eventStatus !== VIRTUAL_SURVEY_TASK_STATUS.NOT_PARTICIPATED
      ? // @ts-ignore
        VIRTUAL_SURVEY_TASK_STATUS_STYLES[eventStatus as VIRTUAL_SURVEY_TASK_STATUS]
      : {};

  return (
    <CellBlock>
      {eventStatus !== VIRTUAL_SURVEY_TASK_STATUS.NOT_PARTICIPATED ? (
        <ColorByPercentage value={isValueAllowed(eventStatusStyle.value, selectedItems)}>
          {i18n._(eventStatusStyle.title)}
        </ColorByPercentage>
      ) : (
        <WhiteCell>{i18n._(t`Not participated`)}</WhiteCell>
      )}
    </CellBlock>
  );
}

export { Cell };
