import React from 'react';

import styled from 'styled-components';

import RickTextView from '~/components/RickTextView';

import { COLORS } from '~/styles';

const Block = styled.div``;

const Name = styled.div`
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  color: ${COLORS.BLACK};
  margin-bottom: 16px;
`;

const Description = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.BLACK};
  margin-bottom: 16px;
`;

interface ISectionNameAndDescription {
  name: string;
  description: string | undefined;
}

const SectionNameAndDescription = ({ name, description }: ISectionNameAndDescription) => {
  return (
    <Block>
      <Name>{name}</Name>
      {description && (
        <Description>
          {/* @ts-ignore*/}
          <RickTextView html={description} />
        </Description>
      )}
    </Block>
  );
};

export { SectionNameAndDescription };
