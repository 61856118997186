import React from 'react';

import styled from 'styled-components';

import { COLOR_PALETTE } from '~/styles';

const Wrapper = styled.div`
  position: relative;
  background: ${COLOR_PALETTE.WHITE};
  width: 100%;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  position: relative;
  width: ${(props) => props.$width || '731px'};
  max-width: ${(props) => props.$width || '731px'};
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  padding: 32px 24px 27px;
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.$marginTop || '80px'};
  margin-bottom: 24px;
  border-radius: 3px;
  box-shadow: 1px 1px 2px 0 rgba(25, 42, 70, 0.08);
  border: solid 0.5px #c7cfe0;
  background-color: ${COLOR_PALETTE.WHITE};
`;

const RedBackground = styled.div`
  width: 100%;
  height: 566px;
  background: linear-gradient(81deg, #e1d9f8 27%, #f6f3ff 87%);
  position: absolute;
  top: 0;
  right: 0;
`;

const AuthLayout = ({ children, bottom, width, marginTop }) => (
  <Wrapper>
    <RedBackground />
    <Container $width={width} $marginTop={marginTop}>
      {children}
    </Container>
    {bottom}
  </Wrapper>
);

export default AuthLayout;
