import React from 'react';

import {
  LEARNING_REPORT_MEMBERS_SORT_OPTIONS,
  LEARNING_REPORT_TEAMS_SORT_OPTIONS,
  LEARNING_REPORT_PATHS_SORT_OPTIONS,
  LEARNING_REPORT_ACTIVITIES_SORT_OPTIONS,
} from '@learned/constants';
import { t } from '@lingui/macro';

import { Percentage } from '~/components/Percentage';
import { TD } from '~/components/Table/design';
import AvatarCard from '~/components/UI/AvatarCard';

import { Name } from './design';

import type { IColumnTable } from '~/@types/table';

import type { I18n } from '@lingui/core';

const ACTIVITIES_COLUMNS: IColumnTable[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'name',
    // @ts-ignore
    renderCell: ({ name }) => {
      return <Name>{name}</Name>;
    },
    sortBy: {
      asc: {
        key: LEARNING_REPORT_ACTIVITIES_SORT_OPTIONS.NAME_A_Z,

        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: LEARNING_REPORT_ACTIVITIES_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Participants`),
    accessor: 'participants',
    sortBy: {
      asc: {
        key: LEARNING_REPORT_ACTIVITIES_SORT_OPTIONS.PARTICIPANTS_HIGH_LOW,

        label: (i18n: I18n) => i18n._(t`High - low`),
      },
      desc: {
        key: LEARNING_REPORT_ACTIVITIES_SORT_OPTIONS.PARTICIPANTS_LOW_HIGH,
        label: (i18n: I18n) => i18n._(t`Low - high`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`AVG.Progress`),
    accessor: 'progressAVG',
    sortBy: {
      asc: {
        key: LEARNING_REPORT_ACTIVITIES_SORT_OPTIONS.AVG_PROGRESS_HIGH_LOW,

        label: (i18n: I18n) => i18n._(t`High - low`),
      },
      desc: {
        key: LEARNING_REPORT_ACTIVITIES_SORT_OPTIONS.AVG_PROGRESS_LOW_HIGH,
        label: (i18n: I18n) => i18n._(t`Low - high`),
      },
    },
    // @ts-ignore
    renderCell: ({ progressAVG }) => {
      return <TD>{progressAVG || 0}%</TD>;
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Act. Compl.`),
    accessor: 'completed',
    minWidth: '150px',
    sortBy: {
      asc: {
        key: LEARNING_REPORT_ACTIVITIES_SORT_OPTIONS.COMPLETED_HIGH_LOW,

        label: (i18n: I18n) => i18n._(t`High - low`),
      },
      desc: {
        key: LEARNING_REPORT_ACTIVITIES_SORT_OPTIONS.COMPLETED_LOW_HIGH,
        label: (i18n: I18n) => i18n._(t`Low - high`),
      },
    },
    // @ts-ignore
    renderCell: ({ completedPercentage, completed }) => {
      return (
        <TD>
          <span className="withPercentage">
            <span>{completed}</span>
            <Percentage value={completedPercentage} />
          </span>
        </TD>
      );
    },
  },
];

const TEAMS_COLUMNS: IColumnTable[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'name',
    // @ts-ignore
    renderCell: ({ name }) => {
      return <Name>{name}</Name>;
    },
    sortBy: {
      asc: {
        key: LEARNING_REPORT_TEAMS_SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: LEARNING_REPORT_TEAMS_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Members`),
    accessor: 'members',
    sortBy: {
      asc: {
        key: LEARNING_REPORT_TEAMS_SORT_OPTIONS.MEMBERS_HIGH_LOW,
        label: (i18n: I18n) => i18n._(t`High - low`),
      },
      desc: {
        key: LEARNING_REPORT_TEAMS_SORT_OPTIONS.MEMBERS_LOW_HIGH,
        label: (i18n: I18n) => i18n._(t`Low - high`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`AVG. Compl Per Member`),
    accessor: 'completedAVG',
    minWidth: '180px',
    sortBy: {
      asc: {
        key: LEARNING_REPORT_TEAMS_SORT_OPTIONS.COMPLETED_AVG_HIGH_LOW,
        label: (i18n: I18n) => i18n._(t`High - low`),
      },
      desc: {
        key: LEARNING_REPORT_TEAMS_SORT_OPTIONS.COMPLETED_AVG_LOW_HIGH,
        label: (i18n: I18n) => i18n._(t`Low - high`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Compl. Activities`),
    accessor: 'ratingAVG',
    minWidth: '180px',
    // @ts-ignore
    renderCell: ({ completedPercentage, completed }) => {
      return (
        <TD>
          <span className="withPercentage">
            <span>{completed}</span>
            <Percentage value={completedPercentage} />
          </span>
        </TD>
      );
    },
    sortBy: {
      asc: {
        key: LEARNING_REPORT_TEAMS_SORT_OPTIONS.COMPLETED_HIGH_LOW,
        label: (i18n: I18n) => i18n._(t`High - low`),
      },
      desc: {
        key: LEARNING_REPORT_TEAMS_SORT_OPTIONS.COMPLETED_LOW_HIGH,
        label: (i18n: I18n) => i18n._(t`Low - high`),
      },
    },
  },
];

const MEMBERS_COLUMN: IColumnTable[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'name',
    // @ts-ignore
    renderCell: ({ id }) => {
      return (
        <Name>
          {/* @ts-ignore */}
          <AvatarCard
            userId={id}
            showTooltip={false}
            size={32}
            type="small"
            isEmailStyles // add ... for long names
            styles={{ padding: 0 }}
          />
        </Name>
      );
    },
    sortBy: {
      asc: {
        key: LEARNING_REPORT_MEMBERS_SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: LEARNING_REPORT_MEMBERS_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Compl. Activities`),
    accessor: 'ratingAVG',
    minWidth: '180px',
    // @ts-ignore
    renderCell: ({ completedPercentage, completed }) => {
      return (
        <TD>
          <span className="withPercentage">
            <span>{completed}</span>
            <Percentage value={completedPercentage} />
          </span>
        </TD>
      );
    },
    sortBy: {
      asc: {
        key: LEARNING_REPORT_MEMBERS_SORT_OPTIONS.COMPLETED_HIGH_LOW,
        label: (i18n: I18n) => i18n._(t`High - low`),
      },
      desc: {
        key: LEARNING_REPORT_MEMBERS_SORT_OPTIONS.COMPLETED_LOW_HIGH,
        label: (i18n: I18n) => i18n._(t`Low - high`),
      },
    },
  },
];

const PATHS_COLUMNS: IColumnTable[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'name',
    // @ts-ignore
    renderCell: ({ name }) => {
      return <Name>{name}</Name>;
    },
    sortBy: {
      asc: {
        key: LEARNING_REPORT_PATHS_SORT_OPTIONS.NAME_A_Z,

        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: LEARNING_REPORT_PATHS_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Participants`),
    accessor: 'participants',
    sortBy: {
      asc: {
        key: LEARNING_REPORT_PATHS_SORT_OPTIONS.PARTICIPANTS_HIGH_LOW,

        label: (i18n: I18n) => i18n._(t`High - low`),
      },
      desc: {
        key: LEARNING_REPORT_PATHS_SORT_OPTIONS.PARTICIPANTS_LOW_HIGH,
        label: (i18n: I18n) => i18n._(t`Low - high`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`AVG.Progress`),
    accessor: 'progressAVG',
    sortBy: {
      asc: {
        key: LEARNING_REPORT_PATHS_SORT_OPTIONS.AVG_HIGH_LOW,

        label: (i18n: I18n) => i18n._(t`High - low`),
      },
      desc: {
        key: LEARNING_REPORT_PATHS_SORT_OPTIONS.AVG_LOW_HIGH,
        label: (i18n: I18n) => i18n._(t`Low - high`),
      },
    },
    // @ts-ignore
    renderCell: ({ progressAVG }) => {
      return <TD>{progressAVG || 0}%</TD>;
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Completed`),
    accessor: 'completed',
    sortBy: {
      asc: {
        key: LEARNING_REPORT_PATHS_SORT_OPTIONS.COMPLETED_HIGH_LOW,

        label: (i18n: I18n) => i18n._(t`High - low`),
      },
      desc: {
        key: LEARNING_REPORT_PATHS_SORT_OPTIONS.COMPLETED_LOW_HIGH,
        label: (i18n: I18n) => i18n._(t`Low - high`),
      },
    },
    // @ts-ignore
    renderCell: ({ completedPercentage, completed }) => {
      return (
        <TD>
          <span className="withPercentage">
            <span>{completed}</span>
            <Percentage value={completedPercentage} />
          </span>
        </TD>
      );
    },
  },
];

export { ACTIVITIES_COLUMNS, PATHS_COLUMNS, TEAMS_COLUMNS, MEMBERS_COLUMN };
