import { ISurveyRating } from '@learned/types';

import type { IServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function setSurveyRatings(surveyRatings: Partial<ISurveyRating>[]) {
  return cloudRequest(
    serverRoutes.surveyRatings.setSurveyRatings as IServerRoute,
    {},
    { surveyRatings },
  );
}
