import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function createReviewTheme() {
  return cloudRequest(serverRoutes.reviewThemes.createTheme);
}

export function updateReviewTheme(reviewThemeId, reviewTheme) {
  return cloudRequest(serverRoutes.reviewThemes.updateTheme(reviewThemeId), {}, reviewTheme);
}

export function copyReviewTheme(reviewThemeId) {
  return cloudRequest(serverRoutes.reviewThemes.copyTheme(reviewThemeId));
}

export function getReviewTheme(reviewThemeId, populate = []) {
  const body = { populate };
  return cloudRequest(serverRoutes.reviewThemes.getTheme(reviewThemeId), {}, body);
}

export function getReviewThemes(filters, populate = []) {
  const body = {
    filters: {
      search: filters.search,
      status: filters.status,
    },
    options: {
      skip: filters.skip,
      limit: filters.limit,
      sortBy: filters.sortBy,
    },
    populate,
    onlyWithQuestions: filters.onlyWithQuestions,
  };
  return cloudRequest(serverRoutes.reviewThemes.getThemes, {}, body);
}

export function deleteReviewThemes(reviewThemesIds) {
  return cloudRequest(serverRoutes.reviewThemes.deleteThemes, {}, { reviewThemesIds });
}

export function setReviewThemeStatusToDraft(id) {
  return cloudRequest(serverRoutes.reviewThemes.setThemeStatusToDraft(id), {}, {});
}
