import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getCompanyLogs(filters = {}) {
  return cloudRequest(serverRoutes.logs.getCompanyLogs, {}, { ...filters });
}

export function getLogsAdmins(filters = {}) {
  return cloudRequest(serverRoutes.logs.getLogsAdmins, { ...filters });
}
