import React from 'react';

import { useLingui } from '@lingui/react';

import { THLabel, TH, THead, TRHeader } from '../design';
import { IColumnTable } from '../types';

interface IHeaderProps {
  columns: IColumnTable[];
  isMultiSelectVisible?: boolean;

  isMenuVisible?: boolean;
  isSelectedCountVisible?: boolean;
  isSelectAllVisible?: boolean;
}

const Header = ({
  isMultiSelectVisible = false,
  columns,
  isMenuVisible,
  isSelectedCountVisible = true,
}: IHeaderProps) => {
  const { i18n } = useLingui();

  const isHideHeader =
    // @ts-ignore
    !!isMultiSelectVisible && multiSelect?.checkedCount > 0 && isSelectedCountVisible;

  return (
    <THead>
      <TRHeader isHideHeader={isHideHeader}>
        {isHideHeader && (
          <>
            <TH key={'th-multi-select'} minWidth="20px" maxWidth="20px" padding="0 0 0 32px" />
            {columns.map((column) => (
              <TH
                key={`th-${column.accessor}`}
                padding="0 24px"
                minWidth={column?.minWidth}
                maxWidth={column?.maxWidth}
              />
            ))}
          </>
        )}
        {columns.map((column) => (
          <TH key={`th-${column.accessor}`} minWidth={column?.minWidth} maxWidth={column?.maxWidth}>
            <THLabel>
              <span>{column.name(i18n)}</span>
            </THLabel>
          </TH>
        ))}
        {isMenuVisible && <TH key={'th-menu'} minWidth="40px" />}
      </TRHeader>
    </THead>
  );
};

export { Header };
