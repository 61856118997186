import { createReducer } from 'redux-act';

import * as authActions from '~/store/auth/actions';

const INITIAL_STATE = {
  user: null,
};

export const auth = createReducer(
  {
    [authActions.setAuthUser]: (state, payload) => {
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };
    },
    [authActions.setUnreadNotificationCount]: (state, newUnreadNotificationCount) => {
      return {
        ...state,
        'user.unreadNotifications': newUnreadNotificationCount,
      };
    },
    [authActions.addIntegration]: (state, integration) => {
      if (!integration) {
        return state;
      }
      const newIntegrations = [
        ...(state.user.integrations || []).filter((i) => i.id !== integration.id),
        integration,
      ];

      return {
        ...state,
        user: {
          ...state.user,
          integrations: newIntegrations,
        },
      };
    },

    [authActions.deleteIntegration]: (state, id) => {
      return {
        ...state,
        user: {
          ...state.user,
          integrations: (state.user.integrations || []).filter((i) => i.id !== id),
        },
      };
    },

    [authActions.updateIntegration]: (state, integration) => {
      if (!integration) {
        return state;
      }
      const updatedIntegrations = (state.user.integrations || []).map((i) =>
        i.id === integration.id ? { ...i, ...integration } : i,
      );

      return {
        ...state,
        user: {
          ...state.user,
          integrations: updatedIntegrations,
        },
      };
    },

    [authActions.updateUserConnection]: (state, connection) => {
      return {
        ...state,
        user: {
          ...state.user,
          connections: state.user.connections.map((i) => (i.id === connection.id ? connection : i)),
        },
      };
    },
  },
  INITIAL_STATE,
);
