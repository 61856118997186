import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Radio from '~/components/Radio';
import { TableGrid } from '~/components/TableGrid';

import {
  CancelButton,
  Content,
  Footer,
  Header,
  HeaderContainer,
  Line,
  NameRow,
  SubTitle,
  NextButton,
  Title,
  Wrapper,
  RadioContainer,
} from './design';
import { FilterDropdown } from './SkillCategoryFilter';

import type { IColumnTable } from '~/@types/table';

import type { I18n } from '@lingui/core';

export const SKILL_CATEGORY_COLUMNS: IColumnTable<any>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'name',
    renderCell: ({ name, isSelected }) => (
      <NameRow $isSelected={isSelected}>
        <RadioContainer>{isSelected && <Radio checked={isSelected} size={20} />}</RadioContainer>
        {name}
      </NameRow>
    ),
  },
  {
    name: (i18n: I18n) => i18n._(t`Category`),
    accessor: 'category',
    maxWidth: '200px',
    renderCell: ({ skillCategoryName, isSelected }) => (
      <NameRow $isSelected={isSelected}>{skillCategoryName}</NameRow>
    ),
  },
];

interface IProps {
  skills: any[];
  pagination: {
    skip: number;
    limit: number;
    index: number;
  };
  totalCount: number;
  customPaginationList: {
    id: number;
    label: string;
  }[];
  filters: {
    search: string;
    setSearch: (value: string) => void;
  };
  filteredCategories: any[];
  skillCategories: any[];
  isValidForm: boolean;
  handleSkillCategorySelect: (skillCategoryId: string) => void;
  changePagination: (pagination: { skip: number; limit: number; index: number }) => void;
  resetPagination: () => void;
  onClose: () => void;
  setStep: (step: number) => void;
  handleRowClick: (item: any) => void;
}

const SelectSkillStep = ({
  skills,
  pagination,
  totalCount,
  filters,
  isValidForm,
  skillCategories,
  filteredCategories,
  customPaginationList,
  changePagination,
  onClose,
  setStep,
  handleRowClick,
  handleSkillCategorySelect,
}: IProps) => {
  const { i18n } = useLingui();

  return (
    <Wrapper>
      <HeaderContainer>
        <Header>
          <Title>{i18n._(t`Add skill`)}</Title>
          <Button
            label={''}
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.CLOSE}
            onClick={onClose}
          />
        </Header>
        <SubTitle>Select a skill to add to the review template.</SubTitle>
        <Line />
      </HeaderContainer>
      <Content>
        <TableGrid
          thColumnPadding="16px 19px"
          onRowClick={handleRowClick}
          data={skills}
          columns={SKILL_CATEGORY_COLUMNS}
          paginationProps={{
            pagination,
            changePagination,
            totalCount,
            customPaginationList,
          }}
          filtersProps={{
            filters,
            isFiltered: false,
            isToggleHideFilterVisible: true,
            filterComponents: (
              <>
                <FilterDropdown
                  onClickItem={handleSkillCategorySelect}
                  selectedItems={filteredCategories}
                  items={skillCategories}
                  label={i18n._(t`Category`)}
                />
              </>
            ),
          }}
        />
      </Content>
      <Footer>
        <CancelButton type="button" onClick={onClose}>
          {i18n._(t`Cancel`)}
        </CancelButton>
        <NextButton className="submitButton" onClick={() => setStep(1)} disabled={!isValidForm}>
          {i18n._(t`Next`)}
        </NextButton>
      </Footer>
    </Wrapper>
  );
};

export { SelectSkillStep };
