import { REVIEW_VERSION } from '@learned/constants';
import { IReviewOld } from '@learned/types';

export const isReviewCompleted = (review: IReviewOld) => {
  const { signatures, createdFor, conversationCoaches } = review;
  const usersIds = [createdFor, ...conversationCoaches];
  const isAllSigned = usersIds.every((userId) => {
    return signatures?.some((signature) => signature.userId === userId);
  });
  return isAllSigned;
};

export const isOldReview = (version?: REVIEW_VERSION) => {
  return (
    version && [REVIEW_VERSION.V0_1, REVIEW_VERSION.V1_0, REVIEW_VERSION.V2_0].includes(version)
  );
};
