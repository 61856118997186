import React, { Component } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import { Dropdown } from '~/components/Dropdown';
import Modal from '~/components/Modal';

import './styles.scss';

const TeamDropdown = styled(Dropdown)`
  width: 100%;

  & > div:first-child {
    height: 48px;
  }
`;

const TeamModal = styled(Modal)`
  & > div:nth-child(3) {
    overflow: visible;
  }
`;

const INITIAL_STATE = {
  loading: false,
  selectedTeam: [],
};

class SelectTeamModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
  }

  static propTypes = {
    teams: PropTypes.object,
    onModalClose: PropTypes.func,
    title: PropTypes.string,
    onSubmit: PropTypes.func,
    selectedUser: PropTypes.object,
    selectedInvite: PropTypes.object,
  };

  onChangeSelectedTeam = (selectedTeam) => {
    this.setState({
      selectedTeam,
    });
  };

  closeModal = () => {
    this.props.onModalClose();
  };

  render() {
    const { i18n, title, onSubmit, teams, selectedUser, selectedInvite } = this.props;
    const { loading, selectedTeam } = this.state;

    // hide team where user coach
    let companyTeams = Object.values(teams)
      .filter((companyTeam) => {
        let isUserAlreadyCoach = false;

        // check user
        if (!isEmpty(selectedUser)) {
          if (!isEmpty(companyTeam.coaches)) {
            companyTeam.coaches.forEach((coachId) => {
              if (String(coachId) === String(selectedUser.id)) {
                isUserAlreadyCoach = true;
              }
            });
          }
        }

        // check invite
        if (!isEmpty(selectedInvite)) {
          if (!isEmpty(selectedInvite.teamsCoach)) {
            selectedInvite.teamsCoach.forEach((teamCoachId) => {
              if (String(companyTeam.id) === String(teamCoachId)) {
                isUserAlreadyCoach = true;
              }
            });
          }
        }

        return !isUserAlreadyCoach;
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    // hide team where user Member
    companyTeams = companyTeams.filter((companyTeam) => {
      let isUserAlreadyMember = false;

      // check user
      if (!isEmpty(selectedUser)) {
        if (!isEmpty(companyTeam.members)) {
          companyTeam.members.forEach((memberId) => {
            if (String(memberId) === String(selectedUser.id)) {
              isUserAlreadyMember = true;
            }
          });
        }
      }

      // check invite
      if (!isEmpty(selectedInvite)) {
        if (!isEmpty(selectedInvite.teamsMember)) {
          selectedInvite.teamsMember.forEach((teamMemberId) => {
            if (String(companyTeam.id) === String(teamMemberId)) {
              isUserAlreadyMember = true;
            }
          });
        }
      }
      return !isUserAlreadyMember;
    });

    return (
      <TeamModal
        className="new-team-modal"
        title={title}
        onClose={this.closeModal}
        footerRight={
          <Button
            label={i18n._(t`Save`)}
            disabled={!selectedTeam}
            onClick={async () => {
              this.setState({ loading: true });
              await onSubmit(selectedTeam);
              this.setState({ loading: false });
            }}
            loading={loading}
            type="primary"
          />
        }
        width={500}
        minWidth={500}
      >
        <div>
          <div className="modal_subtitle">
            <Trans>Select Team</Trans>
          </div>
          <TeamDropdown
            items={companyTeams}
            placeholder={i18n._(t`Teams`)}
            onChange={this.onChangeSelectedTeam}
            selectedItems={selectedTeam}
            stringifyItem={(item) => item.name}
            skipSort
          />
        </div>
      </TeamModal>
    );
  }
}

export default withI18n()(withRouter(SelectTeamModal));
