import React, { useRef, useEffect, useState } from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import CollapsingBlock from '~/components/CollapsingBlock';

import useBoolState from '~/hooks/useBoolState';

const ShowMoreRow = styled.div`
  color: var(--company-color);
  height: auto;
  cursor: pointer;
  text-align: right;
  font-size: 14px;
`;

const ShowMore = ({ children, className, maxHeight = 95, showMoreClassName }) => {
  const moreState = useBoolState(true);
  const [height, setHeight] = useState(0);

  const blockRef = useRef();
  useEffect(() => {
    if (blockRef.current) {
      const height = blockRef.current.offsetHeight;
      if (height > maxHeight) {
        setHeight(height);
      }
    }
  }, [maxHeight, setHeight]);

  return (
    <>
      <CollapsingBlock
        className={className}
        ref={blockRef}
        open={!moreState.value}
        minHeight={maxHeight}
      >
        {children}
      </CollapsingBlock>
      {height ? (
        <ShowMoreRow onClick={moreState.toggle} className={showMoreClassName}>
          {!moreState.value ? <Trans>Show less</Trans> : <Trans>Show more</Trans>}
        </ShowMoreRow>
      ) : null}
    </>
  );
};

export default React.memo(ShowMore);
