import React from 'react';

import { USER_REVIEW_STATUS, TASK_STATUS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonVariant } from '~/components/Buttons';
import { DashboardHeader } from '~/components/DashboardHeader';
import { ICONS } from '~/components/Icon';
import { LastSaved } from '~/components/LastSaved';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { ReturnTypeUseReviewRatingsState } from '~/pages/ReviewGiveFeedback/components/GiveReview.hooks';
import { ActionItemBlock, ActionsWrap } from '~/pages/ReviewThemeView/design';

import { DisabledBadge, LastSavedWrapper, StyledLabel, Wrapper } from './GiveReview';

import { IPopulatedReviewTask, IPopulatedUserReview } from '../types';

interface IProps {
  goBack: () => void;
  hideActions: boolean;
  userReview: IPopulatedUserReview;
  reviewTask: IPopulatedReviewTask;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
  reviewRatingsState: ReturnTypeUseReviewRatingsState;
}

export const Header = ({
  goBack,
  userReview,
  reviewTask,
  hideActions,
  useMultiLangString,
  reviewRatingsState,
}: IProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  const { isSubmitting, autoSaveState, onSave, onPublish, autosaveEnabled } = reviewRatingsState;

  return (
    <DashboardHeader
      onBack={goBack}
      title={
        userReview?.name && (
          <>
            {getMultiLangString(userReview.name)}{' '}
            {userReview.status === USER_REVIEW_STATUS.ARCHIVED && (
              <StyledLabel icon={ICONS.ARCHIVE_2}>
                <Trans>Archived</Trans>
              </StyledLabel>
            )}
          </>
        )
      }
      actions={
        !hideActions && (
          <ActionsWrap>
            {!autosaveEnabled.value ? (
              <Tooltip
                size={TOOLTIP_SIZES.DEFAULT}
                tooltip={`${i18n._(
                  t`Autosave is not available in completed reviews. Use the manual save option instead or click here to turn it back on`,
                )}`}
              >
                <Wrapper onClick={autosaveEnabled.toggle}>
                  <DisabledBadge />
                  {i18n._(t`Autosave disabled`)}
                </Wrapper>
              </Tooltip>
            ) : (
              <LastSavedWrapper
                onClick={() => {
                  reviewTask.status === TASK_STATUS.COMPLETED && autosaveEnabled.toggle();
                }}
              >
                <LastSaved
                  time={autoSaveState.state.time}
                  status={autoSaveState.state.status}
                  errorMessage={autoSaveState.state.message}
                />
              </LastSavedWrapper>
            )}

            {reviewTask.status !== TASK_STATUS.COMPLETED && (
              <ActionItemBlock>
                <Button
                  onClick={onSave}
                  variant={ButtonVariant.ICON}
                  icon={ICONS.SAVE}
                  isLoading={isSubmitting}
                />
              </ActionItemBlock>
            )}
            <ActionItemBlock>
              <Button
                onClick={onPublish}
                variant={ButtonVariant.PRIMARY}
                isLoading={isSubmitting}
                label={
                  reviewTask.status !== TASK_STATUS.COMPLETED ? (
                    <Trans>Complete</Trans>
                  ) : (
                    <Trans>Save</Trans>
                  )
                }
              />
            </ActionItemBlock>
          </ActionsWrap>
        )
      }
    />
  );
};
