import styled from 'styled-components';

import RickTextView from '~/components/RickTextView';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  margin-top: 20px;
`;

export const CommentItem = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  margin-bottom: 45px;
`;

export const Header = styled.div<{ isRotate?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 24px;
  & .chevron {
    transform: ${({ isRotate }) => !isRotate && 'rotate(90deg)'};
  }
`;

export const Content = styled.div`
  margin-left: 10px;
  width: 470px;
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
`;

export const Username = styled.div`
  font-size: 14px;
`;

export const Date = styled.div`
  font-size: 12px;
`;

export const Comment = styled.div`
  position: relative;
  font-size: 14px;
  padding: 8px;
  background-color: ${COLORS.BG_PAGE};
  border-radius: 0 6px 6px 6px;
  margin-left: 12px;
  & p {
    padding: 0;
    margin: 0;
    word-break: break-all;
  }

  &:before {
    position: absolute;
    top: 0;
    left: -10px;
    content: '';
    background-color: ${COLORS.BG_PAGE};
    border-left: 6px solid ${COLORS.WHITE};
    border-right: 4px solid transparent;
    border-bottom: 4px solid ${COLORS.WHITE};
    border-top: 6px solid transparent;
  }
`;

export const StyledRickTextView = styled(RickTextView)`
  color: ${COLORS.DARK_GRAY};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
`;
