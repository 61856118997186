import React from 'react';

import { SURVEY_QUESTION_TYPES } from '@learned/constants';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Icon, ICON_SIZES } from '~/components/Icon';

import { SURVEY_QUESTIONS_UI } from '~/constants/surveyQuestions';
import { COLORS } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.13px;
  color: ${COLORS.DARK_GRAY};
  margin-right: 6px;
`;

interface IQuestionTypeProps {
  type: SURVEY_QUESTION_TYPES;
}
const QuestionType = ({ type }: IQuestionTypeProps) => {
  const { i18n } = useLingui();

  const item = SURVEY_QUESTIONS_UI.find((item) => item.key === type);

  if (!item) {
    return null;
  }

  return (
    <Wrapper>
      <Label>{item.title(i18n)}</Label>
      <Icon icon={item.icon} size={ICON_SIZES.LARGE} />
    </Wrapper>
  );
};

export { QuestionType };
