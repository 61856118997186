import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Search from '~/components/Search';
import SvgIcon from '~/components/SvgIcon';

import User from './User';

import checkedOnIcon from '~/assets/correct.svg';

import { COLOR_SET, COLOR_PALETTE } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';

const AvatarsGroup = styled.div`
  display: flex;
  margin-right: 10px;
  align-items: center;
  position: relative;

  .user_avatar-wrapper {
    margin-left: -25px;
    &:first-child {
      margin-left: 0px;
    }
  }
`;

const AvatarGroupNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background-color: ${(props) => (props.isActive ? 'var(--company-color)' : COLOR_PALETTE.WHITE)};
  font-size: 14.4px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  color: ${(props) => (props.isActive ? COLOR_PALETTE.WHITE : '#70747f')};
  margin-left: -25px;
  z-index: 1;
  cursor: pointer;
  outline: none;
`;

const DropdownMenu = styled.ul`
  position: absolute;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 5px 10px 14px 0 rgba(55, 70, 95, 0.08);
  border: solid 0.5px #ecedf0;
  background-color: ${COLOR_PALETTE.WHITE};
  list-style: none;
  padding: 0;
  right: -150px;
  top: 28px;
  z-index: 100;
  width: 248px;

  li:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  li:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  li {
    padding: 6px;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    color: #3b3b3b;
    border-bottom: 1px solid #f8f8f8;
    text-transform: uppercase;
    white-space: nowrap;
    box-sizing: border-box;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:last-child {
      border-bottom: none;
    }
  }
`;

const DropdownRow = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  color: #70747f;
  height: 32px;
  padding-top: 10px;
  padding-left: 8px;
  border-bottom: 1px solid #f8f8f8;
`;

const IconWrapper = styled.div`
  max-height: 24px;
  margin-right: 11px;
`;

const UserInfoWrap = styled.div`
  display: flex;
  align-items: center;
`;

const UserName = styled.div`
  padding: 0 20px 0 7px;
`;

const Rectangle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 24px;
  border: solid 1px #ccced5;
`;

const SearchRow = styled.div`
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #70747f;
  height: 48px;
  padding-top: 4px;
  padding-left: 8px;
  border-bottom: 1px solid #f8f8f8;
`;

const StateMark = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${(props) => (props.isShare ? COLOR_SET.BLUE : '#f27171')};
`;

class UserGroupCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      search: null,
    };
  }

  static propTypes = {
    users: PropTypes.array,
    visibleRole: PropTypes.string,
    isAlwaysCircle: PropTypes.bool,
    hideName: PropTypes.bool,
    isCompanyInfo: PropTypes.bool,
    maxUsersToShow: PropTypes.number,
    showCheckIcon: PropTypes.bool,
    isChecked: PropTypes.bool,
  };

  static defaultProps = {
    maxUsersToShow: 2,
    showCheckIcon: false,
    isChecked: false,
    onClick: () => {},
  };

  input = React.createRef();
  componentDidUpdate = () => {
    if (this.input.current) {
      this.input.current.focus();
    }
  };

  onShowUserList = () => () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    } else {
      this.setState({ isOpen: true });
    }
  };

  hideUserList = () => () => {
    this.setState({ isOpen: false });
  };

  handleChange = (e) => {
    let value = e.target.value;
    this.setState({ search: value });
  };

  searchStyles = {
    height: '40px',
    boxSizing: 'border-box',
    backgroundColor: '#fbfbfb',
    width: '232px',
    borderRadius: '6px',
    fontSize: '12px',
    fontWeight: '300',
    color: '#70747f',
    border: 'none',
    paddingLeft: '2px',
  };

  renderUser = (user) => {
    const { onClick, selectedUser, isRequestState } = this.props;
    const isSelected =
      user &&
      user.id &&
      (user.id === selectedUser ||
        (selectedUser && selectedUser.id && user.id === selectedUser.id));

    return (
      <div
        onMouseDown={(e) => e.preventDefault()}
        onMouseUp={() => onClick(user.id)}
        role="button"
        tabIndex={0}
      >
        <UserInfoWrap>
          {user.avatarUrl ? (
            <div
              className="user-card-light_avatar"
              style={{
                backgroundImage: 'url(' + user.avatarUrl + ')',
                width: '40px',
                height: '40px',
                minWidth: '40px',
                marginRight: '0',
              }}
            />
          ) : (
            <div className="user-card-light_avatar" />
          )}
          <UserName>{user.firstName ? user.firstName : ''}</UserName>
        </UserInfoWrap>
        <IconWrapper>
          {!isRequestState ? (
            isSelected ? (
              <SvgIcon
                width="18px"
                height="18px"
                classNameIcon="checkbox-image"
                url={checkedOnIcon}
              />
            ) : (
              <Rectangle classNameIcon="checkbox-image" />
            )
          ) : (
            <StateMark isShare={user.userRequestIsShared} />
          )}
        </IconWrapper>
      </div>
    );
  };

  render = () => {
    const { maxUsersToShow, onClick, selectedUser, users, i18n, isRequestState } = this.props;

    const { search } = this.state;

    const { isOpen } = this.state;

    if (!isEmpty(users)) {
      let circleUsers = [];
      let listUsers = [];
      let isActive = false;

      if (maxUsersToShow > 0) {
        circleUsers = users.filter((_, index) => maxUsersToShow >= index + 1);
        listUsers = users.filter((_, index) => maxUsersToShow < index + 1);
        isActive = listUsers.some(
          (user) =>
            user.id === selectedUser ||
            (selectedUser && selectedUser.id && user.id === selectedUser.id),
        );
        if (search) {
          listUsers = listUsers.filter(
            (user) => getUserFullName(user).toLowerCase().indexOf(search.toLowerCase()) !== -1,
          );
        }
      }
      return (
        <AvatarsGroup>
          {circleUsers.map((user, index) => (
            <User
              onClick={onClick}
              isRequestState={isRequestState}
              key={index}
              user={user}
              width={40}
              height={40}
              selectedUser={selectedUser}
              showTooltip
            />
          ))}
          {maxUsersToShow > 0 && users.length > maxUsersToShow && (
            <AvatarGroupNumber onClick={this.onShowUserList()} isActive={isActive}>
              {`+${users.length - maxUsersToShow}`}
            </AvatarGroupNumber>
          )}
          {isOpen && (
            <DropdownMenu tabIndex={0} onBlur={this.hideUserList()}>
              <DropdownRow onMouseDown={(e) => e.preventDefault()}>
                {isRequestState ? i18n._(t`People`) : i18n._(t`Team`)}
              </DropdownRow>
              <SearchRow>
                <Search
                  value={search}
                  onChange={this.handleChange}
                  style={this.searchStyles}
                  noIcon
                  placeholder={i18n._(t`Type to search`)}
                  ref={this.input}
                />
              </SearchRow>
              {listUsers.map(this.renderUser)}
            </DropdownMenu>
          )}
        </AvatarsGroup>
      );
    }

    return null;
  };
}

export default withI18n()(UserGroupCard);
