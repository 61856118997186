import { TEngagementData } from './types';

export const isFirst = (tempArr: TEngagementData[], i: number) => {
  if (i === 0) {
    return true;
  }
  const currentRow = tempArr[i];
  const previousRow = tempArr[i - 1];
  return currentRow.name !== previousRow.name;
};

export const isCorrectDimensionSelected = (primary: string, secondary: string, measure: string) => {
  return !!(
    primary &&
    measure &&
    primary !== secondary &&
    secondary !== measure &&
    primary !== 'primary_none' &&
    primary !== 'secondary_none' &&
    measure !== 'measure_none'
  );
};
