import React, { useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useSelector } from 'react-redux';

import ShowMore from '~/components/ShowMore';
import SvgIcon from '~/components/SvgIcon';
import Avatar from '~/components/UI/Avatar';

import { Avatars } from './Avatars';
import {
  Wrapper,
  Content,
  Top,
  Username,
  CommentItem,
  Comment,
  StyledRickTextView,
  Header,
  Date,
} from './design';
import { Incognito } from './Incognito';

import chevronIcon from '~/assets/ic-arrow-drop-down-down.svg';
import feedbackIcon from '~/assets/main-menu-icons/feedback.svg';

import { getUsers } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';

import type { IItem } from './types';
import type { IUser } from '@learned/types';

interface ISurveyQuestionCommentsProps {
  item: IItem;
}

const SurveyQuestionComments = ({ item }: ISurveyQuestionCommentsProps) => {
  const { i18n } = useLingui();
  const users = useSelector(getUsers);
  const [isOpen, setIsOpen] = useState(false);

  return !isEmpty(item.comments) ? (
    <Wrapper>
      <Header onClick={() => setIsOpen((prevState) => !prevState)} isRotate={isOpen}>
        <SvgIcon
          width="22px"
          height="22px"
          url={feedbackIcon}
          isDefaultColor
          defaultColor={COLORS.DARK_GRAY}
        />
        <span>
          <Trans>Comments</Trans> <span>({item.comments.length})</span>
        </span>
        <SvgIcon
          width="11px"
          height="6px"
          url={chevronIcon}
          isDefaultColor
          defaultColor={COLORS.DARK_GRAY}
          className="chevron"
        />
      </Header>
      {isOpen ? (
        <>
          {item.comments.map(({ user, value, date }) => {
            const userData: IUser = users[user];
            return (
              <>
                <CommentItem key={value}>
                  {user === 'anonymous' ? (
                    <Incognito />
                  ) : (
                    /* @ts-ignore */
                    <Avatar userId={user} borderRadius="100px" />
                  )}
                  <Content>
                    <Top>
                      <Username>
                        {user === 'anonymous'
                          ? i18n._(t`Anonymous user`)
                          : userData
                          ? `${userData.firstName || ''} ${userData.lastName || ''}`
                          : ''}
                      </Username>
                      <Date>{moment(date).fromNow()}</Date>
                    </Top>
                    <Comment>
                      {/* @ts-ignore */}
                      <ShowMore maxHeight={150}>
                        <StyledRickTextView html={value} />
                      </ShowMore>
                    </Comment>
                  </Content>
                </CommentItem>
              </>
            );
          })}
        </>
      ) : (
        <Avatars users={Object.values(item.comments).map(({ user }) => user)} />
      )}
    </Wrapper>
  ) : (
    <></>
  );
};

export { SurveyQuestionComments };
