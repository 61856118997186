import { ROLES } from '~/constants';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getRequest(id) {
  return cloudRequest(serverRoutes.requests.getRequest, { id });
}

export function getRequests(filters) {
  return cloudRequest(serverRoutes.requests.getRequests, filters);
}

export function getPersonalRequests(filters) {
  return cloudRequest(serverRoutes.requests.getRequests, filters, {}, { forceRole: ROLES.USER });
}

export function acceptTeamRequest(id) {
  return cloudRequest(serverRoutes.requests.acceptTeamRequest, { id });
}

export function declineTeamRequest(id) {
  return cloudRequest(serverRoutes.requests.declineTeamRequest, { id });
}

export function acceptRequest(id, status) {
  return cloudRequest(serverRoutes.requests.acceptRequest, { id }, { ...(status && { status }) });
}

export function declineRequest(id) {
  return cloudRequest(serverRoutes.requests.declineRequest, { id });
}

export function cancelRequest(id) {
  return cloudRequest(serverRoutes.requests.cancelRequest, { id });
}

export function deleteRequest(id) {
  return cloudRequest(serverRoutes.requests.deleteRequest, { id });
}

export function getIncomingRequestsForOriginalReview(id, userId) {
  return cloudRequest(serverRoutes.requests.getIncomingRequestsForOriginalReview, { id, userId });
}

export function sendRequestReminder(requestId) {
  return cloudRequest(serverRoutes.requests.sendRequestReminder(requestId));
}
