import React, { useRef, useEffect, useState } from 'react';

import { useLingui } from '@lingui/react';
import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

import type { I18n } from '@lingui/core';

interface HighlightProps {
  activeTab?: HTMLDivElement;
}

const Tab = styled.div<{
  $isSelected: boolean;
  defaultBackground?: string;
  borderRadius?: string;
  extraPadding?: string;
  isDisabled: boolean;
}>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: ${(props) => (props.$isSelected ? '600' : 'normal')};
  color: ${(props) => (props.$isSelected ? COLORS.COMPANY : COLORS.SUBTEXT)};
  height: 40px;
  padding: 10px 17px;
  background-color: ${(props) => props.defaultBackground};
  &:first-child {
    border-top-left-radius: ${(props) => props.borderRadius};
    padding-left: ${(props) => props.extraPadding};
  }
  &:last-child {
    border-top-right-radius: ${(props) => props.borderRadius};
    padding-right: ${(props) => props.extraPadding};
  }

  ${({ $isSelected }) =>
    !$isSelected &&
    css`
      &:hover {
        color: ${COLORS.GRAVEL};
        background-color: ${COLORS.HOVER};
      }
    `}
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
`;

const Highlight = styled.div<HighlightProps>`
  height: 1px;
  background-color: ${COLORS.COMPANY};
  transition: transform 0.3s ease;
  transform-origin: left center;
  transform: ${({ activeTab }) =>
    activeTab ? `translateX(${activeTab.offsetLeft}px)` : 'translateX(0)'};
  width: ${({ activeTab }) => (activeTab ? `${activeTab.offsetWidth - 2}px` : '0')};
`;

export interface IHeaderTabsProps {
  isDisabled?: boolean;
  selectedTab: string;
  handleChangeTab: (key: string) => void;
  defaultBackground?: string;
  borderRadius?: string;
  extraPadding?: string;
  tabs: {
    key: string;
    label: (i18n: I18n) => void;
  }[];
}

const HeaderTabs = ({
  isDisabled = false,
  tabs,
  selectedTab,
  handleChangeTab,
  defaultBackground = 'transparent',
  borderRadius = '0',
  extraPadding = '17px',
}: IHeaderTabsProps) => {
  const { i18n } = useLingui();
  const [activeTab, setActiveTab] = useState<HTMLDivElement>();
  const tabRefs = useRef<Record<string, HTMLDivElement | null>>({});

  useEffect(() => {
    if (tabRefs.current && selectedTab) {
      const headerElement = tabRefs.current[selectedTab];
      if (headerElement) {
        setActiveTab(headerElement);
      }
    }
    // eslint-disable-next-line
  }, [tabs]);

  const handleTabClick = (tabRef: any) => {
    setActiveTab(tabRef);
  };

  return (
    <div>
      <Tabs>
        {tabs.map((tab) => (
          <Tab
            isDisabled={isDisabled}
            key={tab.key}
            ref={(el) => {
              tabRefs.current[tab.key] = el;
            }}
            $isSelected={selectedTab === tab.key}
            defaultBackground={defaultBackground}
            borderRadius={borderRadius}
            extraPadding={extraPadding}
            onClick={() => {
              if (!isDisabled) {
                handleTabClick(tabRefs.current[tab.key]);
                handleChangeTab(tab.key);
              }
            }}
          >
            {tab.label && tab.label(i18n)}
          </Tab>
        ))}
      </Tabs>
      <Highlight activeTab={activeTab} />
    </div>
  );
};

export { HeaderTabs };
