import React from 'react';

import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import incognitoIcon from '~/assets/incognito.svg';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: ${COLORS.BG_PAGE};
  border-radius: 10rem;
`;

const Incognito = () => {
  return (
    <Wrapper>
      <SvgIcon
        width="18px"
        height="18px"
        url={incognitoIcon}
        isDefaultColor
        defaultColor={COLORS.DARK_GRAY}
      />
    </Wrapper>
  );
};

export { Incognito };
