import styled from 'styled-components';

import { COLORS } from '~/styles';

export const ActionsWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionItemBlock = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  min-width: 130px;
  height: 20px;
  border-right: solid 1.2px ${COLORS.BORDERS};
  justify-content: center;
`;

export const SaveButtonWrap = styled.span`
  svg {
    height: 15px;
  }
`;

export const DeleteButtonWrap = styled.span`
  svg {
    color: ${COLORS.TIPPY_BACKGROUND};
  }

  &:hover {
    svg {
      color: ${COLORS.ACCENT_ERROR};
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  margin-top: 49px;
  gap: 150px;
`;
