function sortItems<T>(
  items: T[],
  { stringifyItem }: { stringifyItem?: (item: T) => string | undefined },
) {
  const formatString = (s: string | undefined) => (s ?? '').toLowerCase().trim();

  return items.sort((a, b) => {
    if (typeof a === 'string') {
      return formatString(a).localeCompare(formatString(b as string));
    } else if (typeof a === 'number') {
      return a - (b as number);
    } else {
      return formatString(stringifyItem!(a)).localeCompare(
        formatString(stringifyItem!(b) as string),
      );
    }
  });
}

export { sortItems };
