import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { LANGUAGES } from '~/constants';
import getLang from '~/selectors/getLang';

const SkillName = ({ skill = {} }) => {
  const locale = useSelector(getLang);
  return locale === LANGUAGES.NL && skill.name.nl_NL ? skill.name.nl_NL : skill.name.en_GB || '';
};

SkillName.propTypes = {
  skill: PropTypes.object,
};

export default SkillName;
