const BrowserConstants = {
  /**
   * Default popup window width
   */
  POPUP_WIDTH: 483,
  /**
   * Default popup window height
   */
  POPUP_HEIGHT: 600,
  /**
   * Name of the popup window starts with
   */
  POPUP_NAME_PREFIX: 'oauth',
  /**
   * Default popup monitor poll interval in milliseconds
   */
  POLL_INTERVAL_MS: 50,
};

export default class PopupUtils {
  currentWindow;

  constructor(provider) {
    this.provider = provider;
    this.unloadWindow = this.unloadWindow.bind(this);
  }

  /**
   * @hidden
   *
   * Configures popup window for login.
   *
   * @param urlNavigate
   * @param popupName
   * @ignore
   * @hidden
   */
  openPopup(urlNavigate, popupName) {
    try {
      if (this.currentWindow) {
        throw new Error('PopupUtils: already opened');
      }
      if (!urlNavigate) {
        throw new Error('PopupUtils: no urlNavigate');
      }
      // Popup window passed in, setting url to navigate to
      // Popup will be undefined if it was not passed in
      const popupWindow = PopupUtils.openSizedPopup(
        urlNavigate,
        PopupUtils.generatePopupName(popupName),
      );

      // Popup will be null if popups are blocked
      if (!popupWindow) {
        throw new Error('PopupUtils: open window error');
      }
      if (popupWindow.focus) {
        popupWindow.focus();
      }
      this.currentWindow = popupWindow;
      window.addEventListener('beforeunload', this.unloadWindow);
      return popupWindow;
    } catch (e) {
      throw e.toString();
    }
  }

  static openSizedPopup(urlNavigate, popupName) {
    /**
     * adding winLeft and winTop to account for dual monitor
     * using screenLeft and screenTop for IE8 and earlier
     */
    const winLeft = window.screenLeft ? window.screenLeft : window.screenX;
    const winTop = window.screenTop ? window.screenTop : window.screenY;
    /**
     * window.innerWidth displays browser window"s height and width excluding toolbars
     * using document.documentElement.clientWidth for IE8 and earlier
     */
    const width =
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const height =
      window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const left = Math.max(0, width / 2 - BrowserConstants.POPUP_WIDTH / 2 + winLeft);
    const top = Math.max(0, height / 2 - BrowserConstants.POPUP_HEIGHT / 2 + winTop);

    return window.open(
      urlNavigate,
      popupName,
      `width=${BrowserConstants.POPUP_WIDTH}, height=${BrowserConstants.POPUP_HEIGHT}, top=${top}, left=${left}, scrollbars=yes`,
    );
  }

  /**
   * Event callback to unload main window.
   */
  unloadWindow(e) {
    if (this.currentWindow) {
      this.currentWindow.close();
      this.currentWindow = null;
    }
    // Guarantees browser unload will happen, so no other errors will be thrown.
    e.preventDefault();
  }

  /**
   * Closes popup, removes any state vars created during popup calls.
   * @param popupWindow
   */
  cleanPopup(popupWindow) {
    if (popupWindow) {
      // Close window.
      popupWindow.close();
      if (this.currentWindow === popupWindow) {
        this.currentWindow = null;
      }
    }
    // Remove window unload function
    window.removeEventListener('beforeunload', this.unloadWindow);
  }

  /**
   * Monitors a window until it loads a url with the same origin.
   * @param popupWindow - window that is being monitored
   */
  monitorPopupForSameOrigin(popupWindow) {
    return new Promise((resolve, reject) => {
      const intervalId = setInterval(() => {
        if (popupWindow.closed) {
          // Window is closed
          this.cleanPopup(popupWindow);
          clearInterval(intervalId);
          reject();
          // BrowserAuthError.createUserCancelledError()
          return;
        }

        let href = '';
        try {
          /*
           * Will throw if cross origin,
           * which should be caught and ignored
           * since we need the interval to keep running while on STS UI.
           */
          href = popupWindow.location.href;
        } catch (e) {
          // ignore
        }

        // Don't process blank pages or cross domain
        if (!href || href === 'about:blank') {
          return;
        }

        clearInterval(intervalId);
        resolve();
      }, BrowserConstants.POLL_INTERVAL_MS);
    });
  }

  /**
   * Generates the name for the popup based on the client id and request
   */
  static generatePopupName(name) {
    return `${BrowserConstants.POPUP_NAME_PREFIX}.${name}`;
  }
}
