import {
  SURVEY_THEME_STATUS,
  SURVEY_STATUS,
  SURVEY_TYPE,
  SURVEY_FREQUENCY,
} from '@learned/constants';
import { t } from '@lingui/macro';
import _ from 'lodash';

import { PROJECT_ID } from '~/constants/env';

import type { ISurvey } from '@learned/types';
import type { I18n } from '@lingui/core';

// only for stage and live
// we show option to sent Pulse survey every day
// to have option properly test
export const isShowPulseEveryDayFrequency = !['learned-production'].includes(PROJECT_ID as string);

export interface ISurveyThemesStatus {
  id: string;
  key: string;
  name: string;
  translated: (i18n: any) => string;
}

export const SURVEY_THEME_STATUS_WITH_NAMES = {
  DRAFT: {
    id: SURVEY_THEME_STATUS.DRAFT,
    key: SURVEY_THEME_STATUS.DRAFT,
    name: 'Draft',
    translated: (i18n: I18n) => i18n._(t`Draft`),
  },
  PUBLISHED: {
    id: SURVEY_THEME_STATUS.PUBLISHED,
    key: SURVEY_THEME_STATUS.PUBLISHED,
    name: 'Published',
    translated: (i18n: I18n) => i18n._(t`Published`),
  },
};

export interface ISurveyStatus {
  id: ISurvey['status'];
  key: ISurvey['status'];
  name: string;
  translated: (i18n: I18n) => string;
}

export const SURVEY_STATUS_WITH_NAMES = {
  DRAFT: {
    id: SURVEY_STATUS.DRAFT,
    key: SURVEY_STATUS.DRAFT,
    name: 'Draft',
    translated: (i18n: I18n) => i18n._(t`Draft`),
  },
  PUBLISHED: {
    id: SURVEY_STATUS.ACTIVE,
    key: SURVEY_STATUS.ACTIVE,
    name: 'Published',
    translated: (i18n: I18n) => i18n._(t`Active`),
  },
  ARCHIVED: {
    id: SURVEY_STATUS.ARCHIVED,
    key: SURVEY_STATUS.ARCHIVED,
    name: 'Archived',
    translated: (i18n: I18n) => i18n._(t`Archived`),
  },
};

export interface ISurveyType {
  id: ISurvey['type'];
  key: ISurvey['type'];
  name: string;
  translated: (i18n: I18n) => string;
}

export const SURVEY_TYPE_WITH_NAMES = {
  [SURVEY_TYPE.TRADITIONAL]: {
    id: SURVEY_TYPE.TRADITIONAL,
    key: SURVEY_TYPE.TRADITIONAL,
    name: 'Traditional',
    translated: (i18n: I18n) => i18n._(t`Traditional`),
  },
  [SURVEY_TYPE.PULSE]: {
    id: SURVEY_TYPE.PULSE,
    key: SURVEY_TYPE.PULSE,
    name: 'Pulse',
    translated: (i18n: I18n) => i18n._(t`Pulse`),
  },
};

export const SURVEY_REPORTING_THRESHOLD = _.chain([3, 4, 5, 6, 7, 8, 9, 10])
  .map((key) => ({
    value: key,
    key,
    name: (i18n: I18n) => i18n._(t`${key} answers`),
  }))
  .keyBy('value')
  .value();

export const SURVEY_FREQUENCY_WITH_NAMES = {
  [SURVEY_FREQUENCY.WEEK]: {
    value: SURVEY_FREQUENCY.WEEK,
    name: (i18n: I18n) => i18n._(t`Every week send question(s)`),
  },
  [SURVEY_FREQUENCY.TWO_WEEK]: {
    value: SURVEY_FREQUENCY.TWO_WEEK,
    name: (i18n: I18n) => i18n._(t`Every 2 weeks send question(s)`),
  },
  [SURVEY_FREQUENCY.FOUR_WEEK]: {
    value: SURVEY_FREQUENCY.FOUR_WEEK,
    name: (i18n: I18n) => i18n._(t`Every 4 weeks send question(s)`),
  },
  ...(isShowPulseEveryDayFrequency && {
    [SURVEY_FREQUENCY.DAY]: {
      value: SURVEY_FREQUENCY.DAY,
      name: (i18n: I18n) => i18n._(t`Every 1 day send question(s) (only for stage/live)`),
    },
  }),
};

export const SURVEY_QUESTIONS_PER_PARTICIPANT_WITH_NAMES = _.chain([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
  .map((key) => ({
    value: key,
    name: (i18n: I18n) =>
      key === 1
        ? i18n._(t`${key} per participant (recommended)`)
        : i18n._(t`${key} per participant`),
  }))
  .keyBy('value')
  .value();
