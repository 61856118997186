import React from 'react';

import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';
import Radio from '~/components/Radio';
import { Header5 } from '~/components/UI/Typographics/headers';

import { COLORS, COLOR_PALETTE } from '~/styles';

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 12px 24px;
  box-sizing: border-box;
  background-color: ${COLOR_PALETTE.WHITE};
  cursor: pointer;
  border-bottom: 1px solid ${COLORS.BG_PAGE};

  &:hover {
    background: ${COLORS.BG_PAGE};
  }

  &:first-of-type {
    border-top: 1px solid ${COLORS.BG_PAGE};
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

const Label = styled(Header5)`
  display: flex;
  align-items: center;

  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
`;

const Item = ({ onSelect = () => {}, disabled, singleChoice, item, selected }) => {
  let button;
  if (!disabled) {
    if (singleChoice) {
      button = <Radio checked={selected} size={'24px'} />;
    } else {
      button = <CheckBox checked={selected} size={'24px'} />;
    }
  }

  return (
    item && (
      <ItemWrapper onClick={() => (disabled ? {} : onSelect(item))}>
        <Label>{item.label}</Label>
        {button}
      </ItemWrapper>
    )
  );
};

export default Item;
