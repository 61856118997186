import React from 'react';

import { GOAL_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { Dropdown } from '~/components/Dropdown';
import type { IQuestionForm, ISubType } from '~/pages/ReviewThemeSetup/types';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import { AnswerTypeContainer, Label, AnswerTypeWrapper } from '../EditableQuestion/design';

interface ISubSkillCategorySelectorProps {
  formMethods: UseFormReturn<IQuestionForm>;
}

const SubGoalTypeSelector = ({ formMethods }: ISubSkillCategorySelectorProps) => {
  const getMultiLangString = useMultiLangString();
  const { control } = formMethods;
  const { i18n } = useLingui();

  const SUB_TYPES: ISubType[] = [
    {
      id: `${GOAL_TYPES.PERSONAL}_${GOAL_TYPES.BUSINESS}`,
      title: i18n._(t`All`),
      value: [GOAL_TYPES.PERSONAL, GOAL_TYPES.BUSINESS],
    },
    { id: GOAL_TYPES.BUSINESS, title: i18n._(t`Business goals`), value: [GOAL_TYPES.BUSINESS] },
    { id: GOAL_TYPES.PERSONAL, title: i18n._(t`Learning goals`), value: [GOAL_TYPES.PERSONAL] },
  ];

  const findSelectedSubTypes = (subTypes: ISubType['value']) => {
    if (subTypes?.includes(GOAL_TYPES.PERSONAL) && subTypes?.includes(GOAL_TYPES.BUSINESS)) {
      return SUB_TYPES[0];
    }

    if (subTypes?.length === 1 && subTypes?.includes(GOAL_TYPES.BUSINESS)) {
      return SUB_TYPES[1];
    }

    if (subTypes?.length === 1 && subTypes?.includes(GOAL_TYPES.PERSONAL)) {
      return SUB_TYPES[2];
    }

    return SUB_TYPES[0];
  };

  return (
    <>
      <AnswerTypeContainer>
        <div>
          <Label>
            <Trans>Goal type</Trans>
          </Label>

          <Controller
            name="settings.subTypes"
            rules={{ required: true }}
            control={control}
            render={({ field: { value, onChange } }) => (
              <AnswerTypeWrapper>
                <Dropdown
                  items={SUB_TYPES}
                  placeholder={i18n._(t`Select a type`)}
                  isSingleSelect
                  onChange={(item) => item && onChange(item.value)}
                  stringifyItem={(item: ISubType) => getMultiLangString(item ? item.title : '')}
                  // @ts-ignore
                  selectedItem={findSelectedSubTypes(value)}
                />
              </AnswerTypeWrapper>
            )}
          />
        </div>
      </AnswerTypeContainer>
    </>
  );
};

export { SubGoalTypeSelector };
