import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import { CompanyHRISIntegrationSettings } from '~/pages/CompanyHRISIntegrationSettings';
import CompanyIntegrationSettings from '~/pages/CompanyIntegrationSettings';
import { CompanySettings } from '~/pages/CompanySettings';
import { CompanySubscription } from '~/pages/CompanySubscriptions';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('settings', {
  routesPrivate: {
    user: [],
    coach: [],
    admin: [
      <PrivateRoute
        key={routes.SETTINGS.routePath(ROLES.ADMIN)}
        exact
        path={routes.SETTINGS.routePath(ROLES.ADMIN)}
        component={CompanySettings}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.SETTINGS_MODULES.routePath(ROLES.ADMIN)}
        exact
        path={routes.SETTINGS_MODULES.routePath(ROLES.ADMIN)}
        component={CompanySubscription}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.SETTINGS_INTEGRATIONS.routePath(ROLES.ADMIN)}
        exact
        path={routes.SETTINGS_INTEGRATIONS.routePath(ROLES.ADMIN)}
        component={CompanyIntegrationSettings}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.SETTINGS_INTEGRATIONS_HRIS.routePath(ROLES.ADMIN)}
        exact
        path={routes.SETTINGS_INTEGRATIONS_HRIS.routePath(ROLES.ADMIN)}
        component={CompanyHRISIntegrationSettings}
        allowedRoles={[ROLES.ADMIN]}
      />,
    ],
  },
  routesPublic: [],
});
