import styled from 'styled-components';

import { COLORS } from '~/styles';

const Container = styled.div`
  margin: 16px 0 37px 0;
`;

const NotesContainer = styled.div`
  margin: 20px 3px 0 3px;
`;

const NoteTitle = styled.div`
  margin: 0 0 3px;
  font-size: 12px;
  font-weight: 600;
  color: ${COLORS.SUBTEXT};
`;

const NotePoints = styled.div`
  margin: 3px 0 0;
  font-size: 12px;
  line-height: 1.67;
  color: ${COLORS.TEXT_MAIN};
`;

export { Container, NoteTitle, NotePoints, NotesContainer };
