import React, { MouseEventHandler } from 'react';

import { PATH_STATUSES } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components';

import { LearningItemType } from '~/components/LearningItemType';
import { FlexRow, NameRow, TD } from '~/pages/Surveys/design';
import { NameWithQuestions } from '~/pages/Surveys/NameWithQuestions';

import type { IColumnTable } from '~/@types/table';
import convertToTimeString from '~/utils/convertToTimeString';

import type { ILibraryItem, ILocalPath, ILocalActivity } from './types';
import type { I18n } from '@lingui/core';

const CategoryRow = styled.div`
  display: flex;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }
`;

const LEARNING_LIBRARY_COLUMNS: IColumnTable<ILibraryItem>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'name',
    renderCell: (item, onClick: MouseEventHandler<HTMLDivElement> | undefined) => {
      return (
        <NameRow>
          {/* @ts-ignore */}
          <FlexRow onClick={() => onClick(item)}>
            <NameWithQuestions
              name={item.name as string}
              isGrayColor={(item as unknown as ILocalPath).status === PATH_STATUSES.DRAFT}
              isLeftMargin={false}
            />
          </FlexRow>
        </NameRow>
      );
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Category`),
    accessor: 'category',
    minWidth: '162px',
    renderCell: (item) => {
      const categoriesAmount = (item as unknown as ILocalActivity)?.categoriesPopulated?.length;

      return (
        <TD isGrayColor={(item as unknown as ILocalPath).status === PATH_STATUSES.DRAFT}>
          {categoriesAmount ? (
            <CategoryRow>
              <span>{(item as unknown as ILocalActivity).categoriesPopulated[0]?.name}</span>
              {categoriesAmount > 1 && `, +${categoriesAmount - 1}`}
            </CategoryRow>
          ) : (
            <Trans>None</Trans>
          )}
        </TD>
      );
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Type`),
    accessor: 'type',
    renderCell: (item) => {
      const isActivity = item.collection === 'activities';
      return (
        <NameRow>
          <FlexRow>
            <LearningItemType
              isActivity={isActivity}
              type={isActivity ? (item as unknown as ILocalActivity).type : null}
            />
          </FlexRow>
        </NameRow>
      );
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Updated`),
    accessor: 'updated',
    minWidth: '162px',
    renderCell: (item) => {
      return (
        <NameRow>
          <FlexRow>
            <TD isGrayColor={(item as unknown as ILocalPath).status === PATH_STATUSES.DRAFT}>
              {convertToTimeString(item.meta?.lastModifiedDate || item.meta?.createdDate)}
            </TD>
          </FlexRow>
        </NameRow>
      );
    },
  },
];

export { LEARNING_LIBRARY_COLUMNS };
