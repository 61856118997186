import React from 'react';

import { VIEW_TYPE } from './constants';
import JobMatrixCommon from './JobMatrixCommon';

type TProps = { onExpandClick: (status: boolean) => void; expanded: boolean };

export default function ({ onExpandClick, expanded }: TProps) {
  return (
    <JobMatrixCommon type={VIEW_TYPE.JOB_VIEW} onExpandClick={onExpandClick} expanded={expanded} />
  );
}
