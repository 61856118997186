import { Scrollbars } from 'react-custom-scrollbars-2';
import styled, { css } from 'styled-components';

import { Icon } from '~/components/Icon';

import { COLOR_PALETTE, COLORS } from '~/styles';

const Box = styled.div<{ $error?: boolean; isSelected?: boolean }>`
  height: 32px;
  border-radius: 6px;
  border: solid 1px
    ${({ $error, isSelected }) =>
      $error ? COLORS.ACCENT_ERROR : isSelected ? COLORS.COMPANY : COLORS.BORDERS};
  background-color: ${COLORS.WHITE};
  color: ${COLORS.SUBTEXT};
  min-width: 150px;
  padding: ${(props) => (props.isSelected ? '0 8px 0 4px' : '8px 11px 8px 17px')};
  box-sizing: border-box;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SelectedItem = styled.div<{ background?: string }>`
  background: ${({ background }) => (background ? background : COLORS.BG_PAGE)};
  color: ${({ background }) => (background ? 'white' : COLOR_PALETTE.DARK_GRAY)};
  padding: 1px 8px;
  border-radius: 4px;
  margin-right: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ChevronIcon = styled(Icon)`
  margin-left: auto;
  min-width: 12px;
  min-height: 12px;
`;

const PoperWrap = styled.div`
  outline: none;
`;

const DropdownWrap = styled.div<{ width?: string }>`
  background: ${COLOR_PALETTE.WHITE};
  border-radius: 6px;
  overflow: hidden;
  width: ${(props) => props.width || '238px'};
  border: solid 1px ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  outline: none;
`;

const Select = styled.div<{ $clicked?: boolean; isActive: boolean; selectHeight?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${({ selectHeight }) => selectHeight || '32px'};
  box-sizing: border-box;
  padding: 6.9px 9px 6.9px 9px;
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDERS};
  font-size: 12px;
  color: ${COLORS.SUBTEXT};
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;

  svg {
    min-width: 11px;
    min-height: 11px;
    margin-left: 5px;
  }

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  ${(props) =>
    props.$clicked &&
    css`
      color: ${COLORS.TEXT_HOVER};
      background-color: ${COLORS.HOVER};
    `}

  ${(props) =>
    props.isActive &&
    css`
      color: ${COLORS.TEXT_MAIN};
      border: solid 1px ${COLORS.COMPANY};

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: ${COLORS.COMPANY};
        opacity: 0.1;
      }
    `}
`;

const PlaceholderContainer = styled.div`
  margin-right: 4px;
`;

const Wrapper = styled.div`
  min-width: 100px;
  max-width: 195px;
  position: relative;
`;

const OptionList = styled(Scrollbars)``;

export {
  Box,
  SelectedItem,
  ChevronIcon,
  PoperWrap,
  DropdownWrap,
  OptionList,
  Select,
  PlaceholderContainer,
  Wrapper,
};
