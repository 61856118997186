import { REVIEW_QUESTION_TYPES } from '@learned/constants';

import { ILanguageStateReturn } from '~/hooks/useLanguageState';

type RatingLabel = {
  name: {
    [key: string]: string;
  };
};

export const getRatingScale = (
  type: REVIEW_QUESTION_TYPES,
  ratingLabels: Array<RatingLabel>,
  skillLabels: Array<RatingLabel>,
  languageState: ILanguageStateReturn,
) => {
  switch (type) {
    case REVIEW_QUESTION_TYPES.RATING:
      return mapRatingScale(ratingLabels, languageState);
    case REVIEW_QUESTION_TYPES.CUSTOM_SKILL:
    case REVIEW_QUESTION_TYPES.SKILL_CATEGORY:
      return mapRatingScale(skillLabels, languageState);
  }
};

const mapRatingScale = (
  ratingScale: Array<{
    name: {
      [key: string]: string;
    };
  }>,
  languageState: ILanguageStateReturn,
) =>
  ratingScale.map(({ name }) => ({
    label: languageState.languages.map(({ locale }) => ({
      locale,
      value: name[locale] || '',
    })),
  }));
