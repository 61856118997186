import React from 'react';

import { Draggable } from 'react-beautiful-dnd';

import { ContentText, ContentWrap, IconWrap, ItemWrap } from './design';

import { ICONS, ICON_SIZES, Icon } from '../Icon';

import type { Item } from './types';

type Props = {
  item: Item;
  index: number;
  isDragAreaEverywhere: boolean;
  isDragDisabled: boolean;
};

const DraggableItem = ({ item, index, isDragAreaEverywhere, isDragDisabled }: Props) => {
  return (
    <Draggable draggableId={item.id} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <ItemWrap
          ref={provided.innerRef}
          {...provided.draggableProps}
          isDragging={snapshot.isDragging}
        >
          {!isDragAreaEverywhere && (
            <>
              <IconWrap {...provided.dragHandleProps} className="icon-container">
                <Icon icon={ICONS.GRAB_GRID} size={ICON_SIZES.SMALL} />
              </IconWrap>
              <ContentWrap className="content-container">
                <ContentText>{item.content}</ContentText>
              </ContentWrap>
            </>
          )}
          {isDragAreaEverywhere && (
            <ContentWrap {...provided.dragHandleProps} className="content-container">
              <ContentText>{item.content}</ContentText>
            </ContentWrap>
          )}
        </ItemWrap>
      )}
    </Draggable>
  );
};

export { DraggableItem };
