import React, { useMemo } from 'react';

import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import moment from 'moment';
import DatePickerCustom from 'react-datepicker';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import arrowLeft from '~/assets/arrow-left.svg';
import arrowRight from '~/assets/arrow-right.svg';

const Button = styled.button`
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const DatePickerWrapper = styled(DatePickerCustom)`
  width: 40px;
`;

const Header = styled.div`
  margin: 10px;
  display: flex;
  justify-content: space-around;
`;

const YearHeader = styled(Header)`
  margin: 0;
`;

const getYearsPeriod = (date, yearItemNumber = 12) => {
  const endPeriod = Math.ceil(getYear(date) / yearItemNumber) * yearItemNumber;
  const startPeriod = endPeriod - (yearItemNumber - 1);
  return { startPeriod, endPeriod };
};

const renderYearHeader = (date) => {
  const { startPeriod, endPeriod } = getYearsPeriod(date);
  return <div className="react-datepicker-year-header">{`${startPeriod} - ${endPeriod}`}</div>;
};

const IconButton = ({ onClick, disabled, iconUrl }) => (
  <Button onClick={onClick} disabled={disabled} type="button">
    <SvgIcon width="16px" height="16px" url={iconUrl} />
  </Button>
);

const DatePickerHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  minDate,
  maxDate,
  locale,
}) => {
  const months = useMemo(() => moment.localeData(locale).months(), [locale]);

  return (
    <Header>
      <IconButton onClick={decreaseMonth} disabled={prevMonthButtonDisabled} iconUrl={arrowLeft} />
      <div>
        <DatePickerWrapper
          selected={date}
          onChange={(value) => changeYear(getYear(value))}
          showYearPicker
          dateFormat="yyyy"
          minDate={minDate}
          maxDate={maxDate}
          locale={locale}
          renderCustomHeader={({
            decreaseYear,
            prevYearButtonDisabled,
            increaseYear,
            nextYearButtonDisabled,
          }) => (
            <YearHeader>
              <IconButton
                onClick={decreaseYear}
                disabled={prevYearButtonDisabled}
                iconUrl={arrowLeft}
              />
              {renderYearHeader(date)}
              <IconButton
                onClick={increaseYear}
                disabled={nextYearButtonDisabled}
                iconUrl={arrowRight}
              />
            </YearHeader>
          )}
        />
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-onchange */}
      <select
        value={months[getMonth(date)]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <IconButton onClick={increaseMonth} disabled={nextMonthButtonDisabled} iconUrl={arrowRight} />
    </Header>
  );
};

export default DatePickerHeader;
