import { ROLES } from '~/constants';
import {
  pageOverview,
  pageCreate,
  pageUpdate,
  pageDashboardREST,
} from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'goals';
const defaultGoalId = ':goalId';

// overview
export const GOALS = new LinkConstructor(
  [ROLES.USER, ROLES.COACH, ROLES.ADMIN],
  () => `${pageOverview(routeName)}`,
);

// dashboard
export const GOAL = new LinkConstructor(
  [ROLES.USER, ROLES.COACH, ROLES.ADMIN],
  ({ goalId = defaultGoalId }) => `${pageDashboardREST(routeName, { id: goalId })}`,
);

// create
export const GOAL_CREATE = new LinkConstructor([ROLES.USER, ROLES.COACH, ROLES.ADMIN], () =>
  pageCreate(routeName),
);

// update
export const GOAL_UPDATE = new LinkConstructor(
  [ROLES.USER, ROLES.COACH, ROLES.ADMIN],
  ({ goalId = defaultGoalId }) => pageUpdate(routeName, { id: goalId }),
);
