import React, { useMemo, useState } from 'react';

import { ISkillCategory } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import Modal from '~/components/Modal';
import { CreateSkillFromLucaModal } from '~/components/Modals/CreateFromLucaModals';
import { ISkillGenerated } from '~/pages/SkillSetsOverview/types';
import {
  ConnectModalContent,
  Footer,
  Header,
  StyledButton,
  Title,
  TitleWrapper,
} from '~/pages/SuperAdminDashboard/SuperAdminSkillMatrix/components/design';

import { CustomOptionComponent } from './CustomOptionComponent';
import { StyledSearchSelect, ModalDescription, SearchTitle } from './design';
import { PopulatedSkillTemplate, SKILL_CREATION_STEPS } from './types';

import routes from '~/constants/routes';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { setGeneratedSkill } from '~/store/skills/actions';

import { useSkillTemplates } from '../hooks';
import { useSkillCategories } from '../SkillForm/hooks';

type SkillCreateModalProps = {
  onClose: () => void;
  defaultSkillCategory?: ISkillCategory['id'];
};

const SkillCreateModal = ({ onClose, defaultSkillCategory }: SkillCreateModalProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const { skillTemplates, search, setSearch } = useSkillTemplates();
  const { skillCategories } = useSkillCategories();

  const dispatch = useDispatch();

  const [selectedSkillTemplate, setSelectedSkillTemplate] = useState<PopulatedSkillTemplate>();

  const [step, setStep] = useState<SKILL_CREATION_STEPS>(
    SKILL_CREATION_STEPS.SEARCH_SKILL_TEMPLATE,
  );

  const populatedSkillTemplates = useMemo(() => {
    return skillTemplates.map((skillTemplate) => ({
      ...skillTemplate,
      skillCategory: skillCategories.find(
        (skillCategory) => skillCategory.skillCategoryTemplate === skillTemplate.skillCategory,
      ),
    }));
  }, [skillTemplates, skillCategories]);

  const handleCreateSkill = (templateId?: string) => {
    if (templateId) {
      return routes.SKILLS_CREATE.go(
        {},
        { query: { createFromSkillTemplateId: templateId, name: search.trim() }, isBackPath: true },
      );
    }
    if (search) {
      setStep(SKILL_CREATION_STEPS.CREATE_SKILL_WITH_LUCA);
    }
  };

  const onClickManually = (selectedCategoryId: string) => {
    return routes.SKILLS_CREATE.go(
      {},
      { query: { name: search.trim(), skillCategoryId: selectedCategoryId }, isBackPath: true },
    );
  };

  const onClickUsingAI = (data: ISkillGenerated, selectedCategoryId: string) => {
    dispatch(setGeneratedSkill({ ...data }));
    return routes.SKILLS_CREATE.go(
      {},
      { query: { name: search.trim(), skillCategoryId: selectedCategoryId }, isBackPath: true },
    );
  };

  return step === SKILL_CREATION_STEPS.SEARCH_SKILL_TEMPLATE ? (
    <Modal
      width={500}
      onClose={onClose}
      showDivider={false}
      isHideHeader
      footerStyles={{ paddingBottom: 32, paddingTop: 0, height: 'unset', minHeight: 'unset' }}
      contentStyles={{ padding: '9px 24px 32px 25.5px', overflowY: 'unset' }}
      footerRight={
        <Footer>
          <Button
            onClick={onClose}
            label={<Trans>Cancel</Trans>}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
          />

          <Button
            onClick={() => handleCreateSkill(selectedSkillTemplate?.id)}
            label={<Trans>Start</Trans>}
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            disabled={!search.trim() && !selectedSkillTemplate}
          />
        </Footer>
      }
    >
      <Header>
        <TitleWrapper>
          <Title>
            <Trans>Create a skill or KPI</Trans>
          </Title>
          <StyledButton onClick={onClose} variant={ButtonVariant.CLOSE} size={ButtonSize.MEDIUM} />
        </TitleWrapper>
      </Header>
      <ConnectModalContent>
        <ModalDescription>
          <Trans>
            Search for the skill or KPI you want to add. Use a template from the database and
            customize this template.
          </Trans>
        </ModalDescription>
        <div>
          <SearchTitle>
            <Trans>Search for template</Trans>
          </SearchTitle>
          <StyledSearchSelect
            placeholder={i18n._(t`Communication`)}
            noResultsPlaceholder={i18n._(t`No skills were found`)}
            items={populatedSkillTemplates}
            hashItem={(item) => item.id}
            selectedItem={selectedSkillTemplate}
            onChange={setSelectedSkillTemplate}
            onSearchChange={setSearch}
            stringifyItem={(item) => getMultiLangString(item.name)}
            CustomOptionComponent={CustomOptionComponent}
            actions={[
              {
                handler: () => handleCreateSkill(),
                name: i18n._(t`Create new skill for '${search}''`),
                icon: ICONS.ADD_PLUS,
              },
            ]}
            isSingleSelect
          />
        </div>
      </ConnectModalContent>
    </Modal>
  ) : (
    <CreateSkillFromLucaModal
      onClose={onClose}
      skillName={search}
      defaultCategoryId={defaultSkillCategory}
      onClickManually={(selectedCategoryId) => onClickManually(selectedCategoryId)}
      onClickUsingAI={(data, selectedCategoryId) => onClickUsingAI(data, selectedCategoryId)}
    />
  );
};

export { SkillCreateModal };
