import React, { useEffect, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { TeamsModal } from './TeamsModal';

import { getTeams as getCurrentTeams, getErrors } from '~/selectors/currentGoal';
import { getTeams } from '~/services/teams';
import { setCurrentGoalTeams } from '~/store/currentGoal/actions';
import { COLORS } from '~/styles';

import {
  ItemLabel,
  ItemRow,
  RowText,
  GrayRow,
  ItemLabelRow,
  CloseIconButton,
  Error,
} from '../../stepsDesign';

import type { ITeam } from '@learned/types';

const TeamsSection = () => {
  const dispatch = useDispatch();
  const { i18n } = useLingui();
  const teams = useSelector(getCurrentTeams);
  const errors = useSelector(getErrors);
  const [allTeams, setAllTeams] = useState<Record<string, ITeam>>({});
  const [showTeamModal, setShowTeamModal] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;
    const fetch = async () => {
      const fetchedTeams = await getTeams();
      if (isMounted && !isEmpty(fetchedTeams)) {
        setAllTeams(fetchedTeams);
      }
    };

    fetch();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  const onChangeTeams = (selectedTeams: string[]) => {
    const newTeams = [...teams, ...selectedTeams];
    // @ts-ignore
    dispatch(setCurrentGoalTeams(newTeams));
    setShowTeamModal(false);
  };

  const onDeleteTeam = (teamToDelete: string) => {
    const update = teams.filter((id: string) => id !== teamToDelete);
    // @ts-ignore
    dispatch(setCurrentGoalTeams(update));
  };

  const renderTeam = (teamId: string) => {
    const team = allTeams[teamId];

    return team ? (
      <GrayRow key={teamId} isSpaceBetween height="62px" marginTop="8px">
        <RowText>{team?.name}</RowText>
        <CloseIconButton onClick={() => onDeleteTeam(teamId)}>
          <Icon size={ICON_SIZES.LARGE} icon={ICONS.DELETE_BIN} color={COLORS.ACCENT_WARNING} />
        </CloseIconButton>
      </GrayRow>
    ) : null;
  };

  return (
    <div>
      <ItemRow
        marginTop="12px"
        marginBottom="24px"
        paddingTop="12px"
        flexColumn
        isError={errors && errors.teams}
      >
        <ItemLabelRow marginBottom="6px">
          <ItemLabel bold>
            <Trans>Team(s)</Trans>
          </ItemLabel>
          <Button
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={() => setShowTeamModal(true)}
            label={i18n._(t`Add team`)}
          />
        </ItemLabelRow>
        {(teams || []).map((teamId: string) => renderTeam(teamId))}
        {showTeamModal && (
          <TeamsModal
            allTeams={Object.values(allTeams).filter((t) => !teams.includes(t.id))}
            onSubmit={onChangeTeams}
            onClose={() => setShowTeamModal(false)}
          />
        )}
      </ItemRow>
      {errors && errors.teams && <Error>{errors.teams}</Error>}
    </div>
  );
};

export { TeamsSection };
