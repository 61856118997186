import React from 'react';

import SecondaryMenuItem from './SecondaryMenuItem';

import { SecondaryMenuWrapper, SecondaryMenuTitle, MenuDivider } from '../styles/design';

import type { IMenuItem } from '../types';

interface ISecondaryMenuProps {
  items: IMenuItem[];
  title: string;
  pathname: string;
  selectedTeam: string;
  isMenuWithIcons?: boolean;
  hash?: string;
}

const SecondaryMenu = ({
  items,
  title,
  pathname,
  selectedTeam,
  isMenuWithIcons,
  hash,
}: ISecondaryMenuProps) => {
  return (
    <SecondaryMenuWrapper isMenuWithIcons={isMenuWithIcons}>
      {!isMenuWithIcons && <SecondaryMenuTitle>{title}</SecondaryMenuTitle>}
      <div>
        {items.map((menuItem, idx) => {
          return (
            <div key={menuItem.key || idx}>
              <SecondaryMenuItem
                menuItem={menuItem}
                pathname={pathname}
                selectedTeamId={selectedTeam}
                hash={hash}
              />
              {menuItem.isDivider && <MenuDivider />}
            </div>
          );
        })}
        {!isMenuWithIcons && <MenuDivider />}
      </div>
    </SecondaryMenuWrapper>
  );
};

export default SecondaryMenu;
