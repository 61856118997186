import React from 'react';

import { GOAL_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';

import { AdvancedSettingsSection } from './AdvancedSettingsSection';
import { NameSection } from './NameSection';
import { OwnersSection } from './OwnersSection';
import { PrivacyAndDeadlineSection } from './PrivacyAndDeadlineSection';
import { TeamsSection } from './TeamsSection';

import { getType } from '~/selectors/currentGoal';

import { Form, FooterContainer, Title, StyledDivider } from '../stepsDesign';

interface IStepOneProps {
  onNext: () => void;
  isUpdate?: boolean;
}

const StepOne = ({ onNext, isUpdate }: IStepOneProps) => {
  const { i18n } = useLingui();
  const type = useSelector(getType);

  return (
    <Form>
      <Title>
        <Trans>Goal details</Trans>
      </Title>
      <NameSection />
      <PrivacyAndDeadlineSection />
      {type === GOAL_TYPES.TEAM && (
        <>
          <StyledDivider />
          <TeamsSection />
        </>
      )}
      <StyledDivider />
      <OwnersSection isUpdate={isUpdate} />
      <AdvancedSettingsSection />
      <FooterContainer isLeftAction>
        <Button
          label={i18n._(t`Next`)}
          onClick={onNext}
          variant={ButtonVariant.NAVIGATION_PRIMARY}
          size={ButtonSize.MEDIUM}
        />
      </FooterContainer>
    </Form>
  );
};

export { StepOne };
