import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import InvitesPage from '~/pages/InvitesPage';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('members', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.INVITES.routePath(ROLES.USER)}
        exact
        path={routes.INVITES.routePath(ROLES.USER)}
        component={InvitesPage}
        allowedRoles={[ROLES.USER]}
      />,
    ],
    coach: [],
    admin: [],
  },
  routesPublic: [],
});
