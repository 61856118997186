import styled, { css } from 'styled-components';

import { StyledIconButton } from '~/components/Buttons/design';

import { COLORS } from '~/styles';

export const Label = styled.label<{ color?: string }>`
  color: ${({ color }) => color || COLORS.TEXT_HOVER};
  font-size: 14px;
  letter-spacing: -0.16px;
`;

export const Title = styled.span`
  font-size: 26px;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
`;

export const Subtitle = styled.span`
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${COLORS.SUBTEXT};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 13px;
  padding-bottom: 7px;
  margin-bottom: 20px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-top: 24px;
  border-top: 1px solid ${COLORS.BORDERS};
`;

export const Error = styled.div`
  color: ${COLORS.ACCENT_ERROR};
  padding: 6px 4px 20px;
  font-size: 12px;
  font-style: italic;
`;

export const SettingItem = styled.div<{ error?: boolean }>`
  margin-bottom: 4px;
  display: flex;
  padding: 17px 20px;
  gap: 16px;
  border-radius: 6px;
  border-width: 1px;
  border-color: transparent;
  border-style: solid;
  background-color: ${COLORS.BG_LIST};
  max-height: 48px;
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
  cursor: pointer;

  ${({ error }) =>
    error &&
    css`
      border-color: ${COLORS.ACCENT_ERROR};
    `}

  &:hover {
    background-color: ${COLORS.HOVER};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;

  .field {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    ${Label} {
      width: 20%;
    }

    .content {
      font-size: 14px;
      color: ${COLORS.TEXT_MAIN};
    }
  }

  .settings {
    margin-top: 30px;
  }

  .comment {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    ${Label} {
      padding-bottom: 10px;
    }
  }

  .signature {
    display: flex;
    gap: 30px;
    margin-top: 24px;
    .input {
      width: 100%;
      display: flex;
      flex-direction: column;
      ${Label} {
        padding-bottom: 4px;
      }
    }

    .preview {
      color: ${COLORS.TEXT_MAIN};
      margin-top: 28px;
      width: 100%;
      font-family: Merriweather;
      font-size: 20px;
      font-weight: normal;
      font-style: italic;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLORS.HOVER};
  padding: 6px 12px;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.ICONS_PRIMARY};
  cursor: pointer;

  gap: 12px;

  ${StyledIconButton} {
    width: 14px;
    height: 14px;
    color: ${COLORS.INACTIVE};

    svg {
      width: 14px;
      height: 14px;
    }

    :hover {
      color: ${COLORS.ACCENT_ERROR};
    }
  }
`;

export const Tags = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
`;
