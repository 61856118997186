import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';

import Placeholder from '~/components/Placeholder';

const SignaturePlaceholder = ({ subTitle }) => {
  const { i18n } = useLingui();

  return (
    <div>
      <Placeholder
        title={i18n._(t`Waiting for the conversation to be completed`)}
        subTitle={subTitle}
        subTitleStyles={{ width: '260px' }}
      />
    </div>
  );
};

SignaturePlaceholder.propTypes = {
  subTitle: PropTypes.string,
};

export default SignaturePlaceholder;
