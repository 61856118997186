import React from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import InfoIcon from '~/components/Icons/Info';
import RickTextView from '~/components/RickTextView';
import SvgIcon from '~/components/SvgIcon';
import BoxWithBorder from '~/components/UI/BoxWithBorder';
import { Header7, Header3 } from '~/components/UI/Typographics/headers';

import QuestionPreview from './QuestionPreview';

import { REVIEW_SECTION_THEMES_STYLES } from '~/constants/reviews';
import { COLOR_PALETTE, COLORS } from '~/styles';

const ColoredBlock = styled.div`
  background: linear-gradient(
    56deg,
    ${(props) => props.darkGradient},
    ${(props) => props.lightGradient}
  );
  width: 58px;
  min-width: 58px;
  height: 70px;
  border-top-left-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  margin-bottom: 24px;
`;

const DescriptionContainer = styled.div`
  padding: 24px;
`;

const HeaderDescription = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  margin-bottom: 12px;
  color: ${COLOR_PALETTE.DARK_GRAY};
  p {
    color: ${COLOR_PALETTE.DARK_GRAY};
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 24px 12px 14px;
  width: 100%;
  align-items: center;
`;

const HeaderSection = styled.div`
  display: flex;
  width: 100%;
  ${Header3} {
    margin: 0;
  }
`;

const HeaderText = styled.div`
  ${Header7} {
    color: ${COLORS.TEXT_SECONDARY};
    margin: 0;
    text-transform: uppercase;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  flex: 1;
`;

const SectionInfoContainer = styled(BoxWithBorder)``;

const SectionInfo = styled.div`
  padding: 12px 8px;
  border-radius: 6px;
  background-color: ${COLORS.BG_PAGE};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SectionInfoText = styled.div`
  margin-left: 4px;
  font-size: 14px;
  line-height: 1.57;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const SectionPreview = ({ section, sectionCount, amountOfSections, categories, i18n }) => {
  const getPeople = () => {
    const peopleToAnswer = [];
    if (section.isCoachesReview) {
      peopleToAnswer.push(i18n._(t`the nominated coach(es)`));
    }
    if (section.isSelfReview) {
      peopleToAnswer.push(i18n._(t`the participant`));
    }
    if (section.isUsersReview) {
      peopleToAnswer.push(i18n._(t`the nominated peers`));
    }
    let returnString = '';
    peopleToAnswer.forEach((answer, index) => {
      if (index === 0) {
        returnString = answer;
      } else if (index < peopleToAnswer.length - 1) {
        returnString = `${returnString}, ${answer}`;
      } else {
        returnString = `${returnString} ${i18n._(t`and`)} ${answer}`;
      }
    });
    return returnString;
  };

  const sectionTheme = Object.values(REVIEW_SECTION_THEMES_STYLES).find(
    (t) => t.key === section.theme,
  );

  return (
    <Container>
      <SectionInfoContainer>
        <HeaderSection>
          <ColoredBlock
            lightGradient={sectionTheme.lightGradient}
            darkGradient={sectionTheme.darkGradient}
          >
            <SvgIcon
              url={sectionTheme.icon}
              width={'22px'}
              height={'22px'}
              isDefaultColor
              defaultColor={sectionTheme.color}
            />
          </ColoredBlock>
          <HeaderContent>
            <HeaderText>
              <Header7>
                <Trans>Section</Trans> {sectionCount}/{amountOfSections}
              </Header7>
              <HeaderTitle>
                <Header3>{section.name}</Header3>
              </HeaderTitle>
            </HeaderText>
          </HeaderContent>
        </HeaderSection>
        <DescriptionContainer>
          <HeaderDescription>
            <RickTextView html={section.description || ''} />
          </HeaderDescription>
          <SectionInfo>
            <InfoIcon backgroundColor={COLOR_PALETTE.WHITE} />
            <SectionInfoText>
              <Trans>The questions in this section needs to be answered by</Trans>: {getPeople()}
            </SectionInfoText>
          </SectionInfo>
        </DescriptionContainer>
      </SectionInfoContainer>
      {section.questions.map((question) => {
        return (
          <QuestionPreview
            key={question.id}
            question={question}
            section={section}
            categories={question.type === QUESTION_TYPES.JOB_PROFILE && categories}
          />
        );
      })}
    </Container>
  );
};

export default withI18n()(SectionPreview);
