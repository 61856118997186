import React, { useState } from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getSections } from '~/components/GiveFeedback/utils';

import JobProfileQuestionCard from './components/JobProfileQuestionCard';
import QuestionCard from './components/QuestionCard';
import SectionCard from './components/SectionCard';
import DigitallySignSection from './sections/DigitallySign';
import { NextSteps } from './sections/NextSteps';

import { REQUEST_TYPES, REVIEW_STAGES } from '~/constants';
import { getIsRightSideBarOpen } from '~/selectors/app';
import { checkHasReviewCompleteLogic } from '~/utils/checkHasReviewCompleteLogic';

const NewReviewReport = ({
  review,
  isReadOnly,
  pdfView,
  viewerId,
  handleOnRatingUpdated,
  handleOnSignaturesUpdated,
  users,
  requestType = undefined,
  openSection = 0,
  isDigitalSignModal,
  isShowDigitalSignSection, // we use it to hide section for peers in ReviewRequestReport
  onCloseDigitalSignModal,
  isYourReview,
  userFirstName,
}) => {
  const [activeSection, setActiveSection] = useState(openSection);
  const hideSelf = !review.isSelfReview;
  const hidePeers = !review.isUsersReview;
  const hideCoaches = !review.isCoachesReview;
  const isRightSideBarOpen = useSelector(getIsRightSideBarOpen);
  const isLockedFeedback = get(review, `lockedStages[${REVIEW_STAGES.FEEDBACK}]`, false);
  const isLockedSignatures = !isEmpty(get(review, 'signatures', []));
  const isLocked = isLockedFeedback || isLockedSignatures;
  const sections = getSections(review, undefined, review.skillCategories).filter((s) => {
    if (requestType === REQUEST_TYPES.FEEDBACK_FROM_PEER) {
      return s.isUsersReview;
    }
    if (requestType === REQUEST_TYPES.FEEDBACK_FROM_COACH) {
      return s.isCoachesReview;
    }
    return true;
  });
  const sectionCount = size(sections);

  // complete task logic
  const createdDate = get(review, 'metaOriginal.createdDate');
  const isDigitalSign = review.digitalSign;
  const isCompleteTask = checkHasReviewCompleteLogic(isDigitalSign, createdDate);

  const toggleSection = (index) =>
    activeSection !== index ? setActiveSection(index) : setActiveSection(null);

  // job profile questions
  let allQuestions = [];
  sections.map((s) => (allQuestions = allQuestions.concat(s.questions)));
  const rolesQuestions = allQuestions.filter(
    ({ question } = {}) => question?.type === QUESTION_TYPES.JOB_PROFILE,
  );
  // old reviews don't have jobProfiles
  const roles =
    !isEmpty(review.jobProfiles) && !isEmpty(rolesQuestions)
      ? review.jobProfiles
      : !isEmpty(rolesQuestions)
      ? [
          {
            id: '0',
            skills: rolesQuestions?.map(({ skill } = {}) => skill.id),
          },
        ]
      : [];
  // need for correct questions count in sections with JP questions
  let isRoleQuestionsRendered = false;
  return (
    <>
      {sections.map((s, i) => (
        <SectionCard
          key={i}
          title={s.name}
          description={s.description}
          sectionCount={sectionCount}
          sectionIndex={i + 1}
          isOpen={pdfView || i === activeSection}
          review={review}
          sectionType={s.type}
          pdfView={pdfView}
          theme={s.theme}
          isOnlyJobProfileQuestions={
            !isEmpty(s.questions) &&
            s.questions.filter((q) => q.question.type === QUESTION_TYPES.JOB_PROFILE).length ===
              s.questions.length
          }
          toggleSection={() => toggleSection(i)}
          userFirstName={userFirstName}
          isYourReview={isYourReview}
        >
          {s.questions
            .filter(
              ({ question }) =>
                !(
                  [QUESTION_TYPES.GOAL_BUSINESS_EVAL, QUESTION_TYPES.GOAL_LEARNING_EVAL].includes(
                    question?.type,
                  ) &&
                  !question?.isAverageQuestionEnabled &&
                  isEmpty(question?.goals)
                ),
            )
            .map(({ question, skill }, idx) => {
              const isSectionWithJPQuestion = s.questions.some(
                (q) => q.question.type === QUESTION_TYPES.JOB_PROFILE,
              );
              const indexCurrent =
                isRoleQuestionsRendered && isSectionWithJPQuestion
                  ? idx + size(roles) - size(rolesQuestions)
                  : idx;
              const filteredQuestions = s.questions.filter(
                ({ question }) =>
                  !(
                    [QUESTION_TYPES.GOAL_BUSINESS_EVAL, QUESTION_TYPES.GOAL_LEARNING_EVAL].includes(
                      question?.type,
                    ) &&
                    !question?.isAverageQuestionEnabled &&
                    isEmpty(question?.goals)
                  ),
              );
              const indexTotal = isSectionWithJPQuestion
                ? filteredQuestions
                    .filter((q) => q.question.type !== QUESTION_TYPES.JOB_PROFILE)
                    .concat(roles).length
                : filteredQuestions.length;
              // render all roles questions
              if (
                !isRoleQuestionsRendered &&
                question.type === QUESTION_TYPES.JOB_PROFILE &&
                !isEmpty(rolesQuestions)
              ) {
                isRoleQuestionsRendered = true; // render roles questions only ones

                return roles.map((jobProfile, questionRoleIndex) => {
                  return (
                    <JobProfileQuestionCard
                      key={jobProfile.id}
                      jobProfile={jobProfile}
                      questions={rolesQuestions}
                      pdfView={pdfView}
                      isReadOnly={isReadOnly}
                      hideSelf={hideSelf}
                      hidePeers={hidePeers}
                      hideCoaches={hideCoaches}
                      indexCurrent={indexCurrent + questionRoleIndex}
                      indexTotal={indexTotal}
                      review={review}
                      isOpen={pdfView || idx === 0}
                      viewerId={viewerId}
                      users={users}
                      sectionIndex={i}
                      isLockedFeedback={isLocked}
                      handleOnRatingUpdated={handleOnRatingUpdated}
                      isRightSideBarOpen={isRightSideBarOpen}
                    />
                  );
                });
              }

              switch (question.type) {
                case QUESTION_TYPES.SKILL: {
                  return (
                    <QuestionCard
                      key={skill.id}
                      review={review}
                      question={question}
                      skill={skill}
                      isReadOnly={isReadOnly}
                      hideSelf={hideSelf}
                      hidePeers={hidePeers}
                      hideCoaches={hideCoaches}
                      isOpen={pdfView || idx === 0}
                      handleOnRatingUpdated={handleOnRatingUpdated}
                      isLockedFeedback={isLocked}
                      viewerId={viewerId}
                      pdfView={pdfView}
                      users={users}
                      sectionIndex={i}
                      indexTotal={indexTotal}
                      indexCurrent={indexCurrent}
                    />
                  );
                }
                case QUESTION_TYPES.GOAL_BUSINESS_EVAL:
                case QUESTION_TYPES.GOAL_LEARNING_EVAL: {
                  return (
                    <QuestionCard
                      key={question.type}
                      question={question}
                      review={review}
                      isReadOnly={isReadOnly}
                      hideSelf={hideSelf}
                      hidePeers={hidePeers}
                      hideCoaches={hideCoaches}
                      isOpen={pdfView || idx === 0}
                      handleOnRatingUpdated={handleOnRatingUpdated}
                      isLockedFeedback={isLocked}
                      viewerId={viewerId}
                      pdfView={pdfView}
                      users={users}
                      sectionIndex={i}
                      indexTotal={indexTotal}
                      indexCurrent={indexCurrent}
                    />
                  );
                }
                case QUESTION_TYPES.CUSTOM:
                case QUESTION_TYPES.GOAL_BUSINESS_PLAN:
                case QUESTION_TYPES.GOAL_LEARNING_PLAN: {
                  return (
                    <QuestionCard
                      key={question.id}
                      question={question}
                      review={review}
                      isReadOnly={isReadOnly}
                      hideSelf={hideSelf}
                      hidePeers={hidePeers}
                      hideCoaches={hideCoaches}
                      isOpen={pdfView || idx === 0}
                      handleOnRatingUpdated={handleOnRatingUpdated}
                      isLockedFeedback={isLocked}
                      viewerId={viewerId}
                      pdfView={pdfView}
                      users={users}
                      sectionIndex={i}
                      indexTotal={indexTotal}
                      indexCurrent={indexCurrent}
                    />
                  );
                }
                default:
                  return null;
              }
            })}
        </SectionCard>
      ))}

      {pdfView && <NextSteps targetId={review.id} pdfView={pdfView} />}

      {isCompleteTask && isShowDigitalSignSection && (
        <DigitallySignSection
          review={review}
          pdfView={pdfView}
          isOpen={pdfView || isDigitalSignModal}
          handleOnSignaturesUpdated={handleOnSignaturesUpdated}
          isDigitalSignModal={isDigitalSignModal}
          onCloseDigitalSignModal={onCloseDigitalSignModal}
          isLocked={isLockedFeedback}
        />
      )}
    </>
  );
};

NewReviewReport.propTypes = {
  review: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool,
  handleOnRatingUpdated: PropTypes.func,
  handleOnSignaturesUpdated: PropTypes.func,
  users: PropTypes.objectOf(PropTypes.object),
  isDigitalSignModal: PropTypes.bool,
  isShowDigitalSignSection: PropTypes.bool,
  onCloseDigitalSignModal: PropTypes.func,
  isYourReview: PropTypes.bool,
  userFirstName: PropTypes.string,
};

NewReviewReport.defaultProps = {
  isShowDigitalSignSection: true,
};

export default NewReviewReport;
