import { ProductStatus } from '@learned/constants';
import styled from 'styled-components';

import { Button } from '~/components/Buttons';
import Switch from '~/components/Switch';

import { COLORS } from '~/styles';

const BlockWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border-radius: 6px;
  border: 1px solid ${COLORS.BORDERS};
  background-color: ${COLORS.WHITE};
`;

const BlockHead = styled.div`
  background-color: ${COLORS.BG_LIST};
  border-radius: 6px;
  padding: 18px 27px;
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
`;

const ProductSwitch = styled(Switch)`
  margin: 0px;
`;

const ProductHeading = styled.span<{ status: string }>`
  font-size: 18px;
  flex: auto;
  color: ${(props) => (props.status === ProductStatus.ACTIVE ? COLORS.TEXT_MAIN : COLORS.SUBTEXT)};
  text-transform: capitalize;
`;

const SettingsBlock = styled.span`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

const ButtonLabel = styled.span`
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
`;

const SettingsButton = styled(Button)`
  color: ${COLORS.TEXT_MAIN};
`;

const BlockBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 15px 0px 45px 30px;
`;

const ProductDescription = styled.span`
  font-size: 14px;
  color: ${COLORS.SUBTEXT};
  flex: auto;
  align-items: center;
  padding: 0px 30px 0px 0px;
  line-height: 1.5;
  letter-spacing: normal;
`;

const SubscriptionInfo = styled.span`
  min-width: 250px;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  font-size: 14px;
  border-left: 1px solid ${COLORS.BORDERS};
  border-right: 1px solid ${COLORS.BORDERS};
  padding: 25px 0px;
`;

const SubscriptionStatus = styled.p`
  margin: 0px;
  color: ${COLORS.TEXT_MAIN};
`;

const StatusChangedDay = styled.p`
  margin: 0px;
  color: ${COLORS.SUBTEXT};
`;

const SubscriptionUpgrade = styled.span`
  min-width: 175px;
  max-width: 175px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 0px 25px;
`;

const MoreInfoLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  color: ${COLORS.SUBTEXT};
`;

const OnStatus = styled.span`
  font-size: 14px;
  color: ${COLORS.COMPANY};
  min-width: 44px;
  max-width: 44px;
`;

const OffStatus = styled.span`
  font-size: 14px;
  color: ${COLORS.INACTIVE};
  min-width: 44px;
  max-width: 44px;
`;

const TrailDaysToEnd = styled.span`
  font-size: 14px;
  color: ${COLORS.COMPANY};
`;

const BlackText = styled.span`
  color: ${COLORS.TEXT_MAIN};
  font-size: 14px;
  margin-top: 5px;
`;

const GreyText = styled.span`
  font-size: 14px;
  color: ${COLORS.SUBTEXT};
`;

const RedText = styled.span`
  color: ${COLORS.ACCENT_ERROR};
  font-size: 14px;
  margin-top: 5px;
`;

export {
  BlockWrapper,
  BlockHead,
  ProductSwitch,
  ProductHeading,
  SettingsBlock,
  ButtonLabel,
  SettingsButton,
  BlockBody,
  ProductDescription,
  SubscriptionInfo,
  SubscriptionStatus,
  StatusChangedDay,
  SubscriptionUpgrade,
  MoreInfoLink,
  OnStatus,
  OffStatus,
  TrailDaysToEnd,
  BlackText,
  GreyText,
  RedText,
};
