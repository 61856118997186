import React, { MouseEventHandler } from 'react';

import { SURVEY_TEMPLATE_STATUSES, SURVEY_TEMPLATES_SORT_OPTIONS } from '@learned/constants';
import { t } from '@lingui/macro';

import { FlexRow, NameRow, TD } from '~/pages/Surveys/design';
import { NameWithQuestions } from '~/pages/Surveys/NameWithQuestions';

import type { IColumnTable } from '~/@types/table';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

import type { ISurveyTemplate } from '@learned/types';
import type { I18n } from '@lingui/core';

const TemplateNameRow = ({
  template,
  onClick,
}: {
  template: ISurveyTemplate;
  onClick: MouseEventHandler<HTMLDivElement> | undefined;
}) => {
  const getString = useMultiLangString();
  return (
    <NameRow>
      {/* @ts-ignore */}
      <FlexRow onClick={() => onClick(template)}>
        <NameWithQuestions
          name={getString(template.name)}
          questionsTotal={(template.questions || []).length}
          isGrayColor={template.status === SURVEY_TEMPLATE_STATUSES.draft}
          isLeftMargin={false}
        />
      </FlexRow>
    </NameRow>
  );
};

const TEMPLATES_COLUMNS: IColumnTable<ISurveyTemplate>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Templates`),
    accessor: 'name',
    renderCell: (
      template: ISurveyTemplate,
      onClick: MouseEventHandler<HTMLDivElement> | undefined,
    ) => {
      return <TemplateNameRow template={template} onClick={onClick} />;
    },
    sortBy: {
      asc: {
        key: SURVEY_TEMPLATES_SORT_OPTIONS.NAME_A_Z,

        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: SURVEY_TEMPLATES_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Created`),
    accessor: 'meta.createdDate',
    minWidth: '162px',
    sortBy: {
      asc: {
        key: SURVEY_TEMPLATES_SORT_OPTIONS.CREATED_NEW_OLD,

        label: (i18n: I18n) => i18n._(t`New - old`),
      },
      desc: {
        key: SURVEY_TEMPLATES_SORT_OPTIONS.CREATED_OLD_NEW,
        label: (i18n: I18n) => i18n._(t`Old - new`),
      },
    },
    renderCell: ({ meta, status }) => {
      return (
        <TD isGrayColor={status === SURVEY_TEMPLATE_STATUSES.draft}>
          <span>{convertToTimeString(meta?.createdDate, TIME_FORMATS.CLASSIC)}</span>
        </TD>
      );
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Updated`),
    accessor: 'meta.lastModifiedDate',
    minWidth: '162px',
    sortBy: {
      asc: {
        key: SURVEY_TEMPLATES_SORT_OPTIONS.UPDATED_NEW_OLD,

        label: (i18n: I18n) => i18n._(t`New - old`),
      },
      desc: {
        key: SURVEY_TEMPLATES_SORT_OPTIONS.UPDATED_OLD_NEW,
        label: (i18n: I18n) => i18n._(t`Old - new`),
      },
    },
    renderCell: ({ meta, status }) => {
      return (
        <TD isGrayColor={status === SURVEY_TEMPLATE_STATUSES.draft}>
          <span>{convertToTimeString(meta?.lastModifiedDate, TIME_FORMATS.CLASSIC)}</span>
        </TD>
      );
    },
  },
];

export { TEMPLATES_COLUMNS };
