import React from 'react';

import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { isEmpty } from 'lodash';
import YouTube from 'react-youtube';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';

import { ACTIVITY_TYPES } from '~/constants';
import { COLORS } from '~/styles';
import abbr from '~/utils/abbr';
import getVideoId from '~/utils/getVideoId';

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDER_LIGHT};
  width: 100%;
  box-sizing: border-box;
  color: ${COLORS.TEXT_PRIMARY};
  margin-left: 17px;
  &:hover {
    background: ${COLORS.BG_PAGE};
  }
`;

const CardWrapp = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 0 25px;
`;

const CoverImg = styled.div`
  width: 99px;
  height: 63px;
  background-color: #edf0f7;
  border-radius: 6px 0 0 6px;
  margin-right: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var(--company-color);
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
`;

const ItemName = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  white-space: nowrap;
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PreviewCol = styled.div`
  width: 112px;
  margin: 18px 0;
  border-left: solid 1px ${COLORS.BORDER_LIGHT};
  cursor: pointer;
`;

const PreviewText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: var(--company-color);
  margin-left: 22px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ActivityItem = ({ checked, activity, onCheckActivity, onClick }) => {
  const videoId =
    activity.link && activity.type === ACTIVITY_TYPES.VIDEO.key ? getVideoId(activity.link) : null;

  const shortName = abbr(activity.name);

  const handleChange = (e, isSelected) => {
    e.stopPropagation();
    onCheckActivity(isSelected, activity);
  };

  return (
    <CardWrapp key={activity.id}>
      <CheckBox checked={checked} onChange={handleChange} size="24px" />
      <Card>
        <Row onClick={(e) => handleChange(e, !checked)}>
          {!isEmpty(videoId) ? (
            <CoverImg>
              <YouTube
                style={{ marginBottom: '24px' }}
                className="youtube-video-cover"
                videoId={videoId}
                opts={{
                  width: '100%',
                  height: '48px',
                }}
              />
            </CoverImg>
          ) : (
            <CoverImg
              style={{
                backgroundImage: activity.coverUrl ? 'url(' + activity.coverUrl + ')' : 'none',
              }}
            >
              {!activity.coverUrl && shortName}
            </CoverImg>
          )}
          <div>
            <ItemName>{activity.name}</ItemName>
          </div>
        </Row>
        <PreviewCol onClick={() => onClick(activity)}>
          <PreviewText>
            <Trans>Preview</Trans>
          </PreviewText>
        </PreviewCol>
      </Card>
    </CardWrapp>
  );
};

export default React.memo(withI18n()(ActivityItem));
