import React from 'react';

import { ISurveyTemplate } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled, { css } from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import StringInfinite from '~/components/StringInfinite';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

const defineBorder = (isSelected: boolean) =>
  isSelected ? `2px solid ${COLORS.BLUE_DARK}` : `1px solid ${COLORS.BORDER_HARD}`;

const SubRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Row = styled.div<{ $isLast: boolean; $isFirst: boolean; $isSelected: boolean }>`
  display: flex;
  border-top: ${(props) => defineBorder(props.$isSelected)};
  border-left: ${(props) => defineBorder(props.$isSelected)};
  border-right: ${(props) => defineBorder(props.$isSelected)};
  border-bottom: ${(props) => (props.$isLast ? defineBorder(props.$isSelected) : undefined)};
  height: 80px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-top-left-radius: ${(props) => (props.$isFirst ? '6px' : null)};
  border-top-right-radius: ${(props) => (props.$isFirst ? '6px' : null)};
  border-bottom-left-radius: ${(props) => (props.$isLast ? '6px' : null)};
  border-bottom-right-radius: ${(props) => (props.$isLast ? '6px' : null)};

  ${(props) =>
    !props.$isSelected &&
    css`
      ${SubRow} {
        display: none;
      }
    `}

  &:hover {
    background-color: ${(props) => (props.$isSelected ? 'unset' : COLORS.HOVER)};
    ${SubRow} {
      display: flex;
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 0;
`;

const Title = styled.div<{ $isSelected: boolean }>`
  font-size: 16px;
  font-weight: ${(props) => (props.$isSelected ? 600 : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${(props) => (props.$isSelected ? COLORS.BLUE_DARK : COLORS.BLACK)};
`;

const SubTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.17;
  letter-spacing: -0.13px;
  color: ${COLORS.MIDDLE_GRAY};
`;

interface ITemplateRow {
  item: ISurveyTemplate;
  isLast: boolean;
  isFirst: boolean;
  isSelected?: boolean;
  onClick?: (surveyTemplate: ISurveyTemplate) => void;
  onDelete?: () => void;
  onPreview: (item: ISurveyTemplate) => void;
}
const TemplateRow = ({
  item,
  isLast,
  isFirst,
  isSelected = false,
  onClick,
  onDelete,
  onPreview,
}: ITemplateRow) => {
  const { i18n } = useLingui();
  const getString = useMultiLangString();
  const questionsTotal = (item.questions || []).length;

  return (
    <Row $isLast={isLast} $isFirst={isFirst} $isSelected={isSelected}>
      <TitleWrapper>
        <Title $isSelected={isSelected}>
          <StringInfinite>{getString(item.name)}</StringInfinite>
        </Title>
        <SubTitle>{i18n._(t`Includes ${questionsTotal} questions`)}</SubTitle>
      </TitleWrapper>
      <SubRow>
        <Button
          variant={ButtonVariant.TEXT_PRIMARY}
          size={ButtonSize.MEDIUM}
          type="button"
          label={i18n._(t`Preview`)}
          onClick={() => onPreview(item)}
        />
        {/* only template with min 1 question possible to select */}
        {onClick && Boolean(questionsTotal) && (
          <Button
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            label={i18n._(t`Select`)}
            onClick={() => onClick(item)}
          />
        )}
        {onDelete && (
          <Button
            label={i18n._(t`Unselect`)}
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.TEXT_DELETE}
            type="button"
            onClick={onDelete}
          />
        )}
      </SubRow>
    </Row>
  );
};

export { TemplateRow };
