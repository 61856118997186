import React, { useState } from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import Button from '~/components/Button';
import { Body } from '~/components/GiveFeedback/components/Section/components/ReviewGoalQuestion/Body';
import { Header } from '~/components/GiveFeedback/components/Section/components/ReviewGoalQuestion/Header';
import GoalDashboardCardModal from '~/components/GoalDashboardCardModal';
import { TemplateCardWithFooter } from '~/components/SetupTools/components/TemplateBaseComponents';
import Tabs from '~/components/Tabs';

import { GOAL_TYPES, RATING_TYPES, REVIEW_QUESTIONS_TABS } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { COLOR_SET, COLORS } from '~/styles';

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.$spaceBetween ? 'space-between' : 'flex-end')};
  border-bottom: 1px solid ${COLORS.BG_PAGE};
  padding-right: 10px;
`;

const StyledButton = styled(Button)`
  height: 32px;
  font-weight: bold;
  border-color: ${COLOR_SET.MIDNICHT_BLUE};
  color: ${COLOR_SET.MIDNICHT_BLUE};
  margin: 9px 24px;

  &:hover {
    background-color: ${COLOR_SET.MIDNICHT_BLUE};
  }
`;

const Container = styled.div`
  border-top: solid 1px ${COLORS.BG_PAGE};
`;

const StyledTemplateCardWithFooter = styled(TemplateCardWithFooter)`
  margin-bottom: 8px;
`;

const ReviewGoalQuestion = React.memo(
  ({
    ratings,
    fetchedRatings,
    goal,
    question,
    onChange,
    review,
    forUser,
    isReadOnly,
    tabs,
    viewerId,
    ratingType,
    isOpenDefault = false,
  }) => {
    const { i18n } = useLingui();
    const $isOpen = useBoolState(isOpenDefault);
    const [isGoalInfoModal, setIsGoalInfoModal] = useState(false);
    const [currentTab, setCurrentTab] = useState(REVIEW_QUESTIONS_TABS.OWN_ANSWER.key);

    const rating = ratings[goal.id] || {};
    const typeRatings = fetchedRatings.filter((rating) => rating.goal === goal.id);

    const goalType =
      question.type === QUESTION_TYPES.GOAL_BUSINESS_EVAL
        ? GOAL_TYPES.BUSINESS.key
        : GOAL_TYPES.PERSONAL.key;

    return (
      <StyledTemplateCardWithFooter $noShadow $isOpen={$isOpen.value}>
        <Header $isOpen={$isOpen} goalType={goalType}>
          {goal.name}
        </Header>
        {$isOpen.value && (
          <Container>
            {ratingType !== RATING_TYPES.OUTSIDE_RATING && (
              <TabsContainer $spaceBetween={!isEmpty(tabs)}>
                {!isEmpty(tabs) && (
                  <Tabs
                    items={tabs}
                    currentItem={currentTab}
                    handleChangeTab={setCurrentTab}
                    isSmall
                    borderColor={COLORS.BORDER_LIGHT}
                    noBorder
                  />
                )}
                <StyledButton
                  type={Button.types.white}
                  label={i18n._(t`View goal`)}
                  onClick={() => setIsGoalInfoModal(true)}
                />
              </TabsContainer>
            )}
            <Body
              review={review}
              question={question}
              rating={rating}
              typeRatings={typeRatings}
              onChange={(updateData) => onChange(goal.id, updateData)}
              forUser={forUser}
              isReadOnly={isReadOnly}
              currentTab={currentTab}
            />
          </Container>
        )}
        {isGoalInfoModal && (
          <GoalDashboardCardModal
            onClose={() => setIsGoalInfoModal(false)}
            goalId={goal.id}
            reviewId={rating.review}
            viewerId={viewerId}
          />
        )}
      </StyledTemplateCardWithFooter>
    );
  },
);

export { ReviewGoalQuestion };
