import styled, { css } from 'styled-components';

import { Dropdown } from '~/components/Dropdown';
import { Option } from '~/components/Dropdown/design';

import { COLORS } from '~/styles';

export const StyledDropdown = styled(Dropdown)`
  width: 237px;
` as typeof Dropdown;

export const Select = styled.div<{ $clicked?: boolean; isWithoutLeftPadding?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: ${({ isWithoutLeftPadding }) => (isWithoutLeftPadding ? '0' : '12px')};
  padding-right: 12px;
  height: 46px;

  background-color: ${COLORS.BG_PAGE};
  ${(props) =>
    props.$clicked &&
    css`
      background-color: ${COLORS.WHITE};
    `}
  border-radius: 6px;

  :hover {
    background-color: ${COLORS.HOVER};
  }
`;

export const CompanyLabel = styled.span<{
  isSmallImage?: boolean;
}>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: ${COLORS.TEXT_HOVER};

  span {
    display: -webkit-box;
    max-width: 150px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  img {
    border-radius: 6px;
  }

  img,
  svg {
    min-height: 46px;
    min-width: 46px;
    height: 46px;
    width: 46px;
  }

  ${(props) =>
    props.isSmallImage &&
    css`
      svg {
        min-height: 26px;
        min-width: 26px;
        height: 26px;
        width: 26px;
        color: var(--company-color);
      }
    `}
`;

export const CompanyOptionLabel = styled(Option)`
  gap: 10px;
  color: ${COLORS.TEXT_HOVER};

  ${(props) =>
    props.$selected &&
    css`
      display: none;
      color: ${COLORS.TEXT_HOVER};
      background-color: inherit;
    `}

  :hover {
    color: ${COLORS.TEXT_HOVER};
  }

  svg,
  img {
    min-height: 24px;
    min-width: 24px;
    height: 24px;
    width: 24px;
  }
`;
