import { useEffect, useState } from 'react';

import { LINK_STATUS } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';

import { confirm } from '~/components/ConfirmDialog';
import { useToasts, TOAST_TYPES } from '~/components/Toast';

import { CONVERS_TOAST_TYPES } from './userConversationConstants';

import { CONVERSATION_STATUSES, ROLES, COMMENT_SOURCES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import useListState from '~/hooks/useListState';
import { updateComment, deleteComment, createComment } from '~/services/comments';
import * as conversationsServices from '~/services/conversations';
import { getRelevantSteps } from '~/services/nextSteps';
import {
  copyTalkingPoint,
  createTalkingPoints,
  deleteTalkingPoint,
} from '~/services/talkingPoints';
import {
  downloadConversationPdf,
  getUserConversation,
  updateUserConversationStatus,
  updateUserConversationTPOrder,
  updateUserTalkingPointsCheck,
  updateUserConversationDate,
} from '~/services/userConversations';

const lockWarning = t`None of the participants are able to make changes anymore. The conversation’s content remains visible.`;
const unlockWarning = t`All participants are able to make changes again.`;

function getFlags(conversation, user, currentRole) {
  const isDone = conversation.status === CONVERSATION_STATUSES.DONE.key;

  // user conversation owner
  const isOwner =
    currentRole !== ROLES.ADMIN &&
    (get(conversation, 'participants') || []).map((p) => (p.id ? p.id : p)).includes(user.id);

  // original conversation owner
  const isOriginalOwner =
    currentRole !== ROLES.ADMIN &&
    get(conversation, 'originalConversation.createdBy') === user.id &&
    conversation.originalConversation.createdInRole !== ROLES.ADMIN;

  const isApprovalFlow = conversation.isApprovalFlow || false;
  const isCoachCompleted = conversation.isCoachCompleted || false;
  const readOnly = isDone || !(isOwner || isOriginalOwner) || (isApprovalFlow && isCoachCompleted);
  let isCompleted;

  if (!conversation.isApprovalFlow) {
    isCompleted = isDone;
  } else {
    // approval flow
    isCompleted =
      currentRole === ROLES.COACH ? conversation.isCoachCompleted : conversation.isUserCompleted;
  }

  return {
    isApprovalFlow,
    isCoachCompleted,
    isCompleted,
    isAdminLockedConversation:
      get(conversation, 'originalConversation.status') === CONVERSATION_STATUSES.DONE_ADMIN.key,
    isOwner,
    isOriginalOwner,
    canComplete:
      (isOwner &&
        conversation.isApprovalFlow &&
        conversation.isCoachCompleted &&
        !conversation.isUserCompleted) ||
      isOriginalOwner,
    isDone,
    readOnly,
  };
}

function useConversationState(
  conversationId,
  user,
  currentRole,
  pdf = false,
  showToastMessage = () => {},
) {
  const { i18n } = useLingui();
  const $loading = useBoolState(true);
  const $isApproveUserWarning = useBoolState(false);
  const [conversation, setConversation] = useState({});
  const [nextSteps, setNextSteps] = useState({});
  const $attachments = useListState();
  const $talkingPoints = useListState([]);
  const $donePoints = useListState();
  const $conversationComments = useListState();
  const $privateNotes = useListState();
  const $TPOrder = useListState([]);
  const { addToast } = useToasts();
  const $copyTalkingPointModal = useBoolState();
  const [TPtoCopy, setTPtoCopy] = useState(null);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [passedEvents, setPassedEvents] = useState([]);

  async function fetchConversation(conversationId, { isAddPendingLinks = false } = {}) {
    const [conversationData, { data: nextStepsData }] = await Promise.all([
      getUserConversation(conversationId, {
        populate: ['originalConversation', 'files', 'createdBy', pdf && 'participants'],
        join: ['comments', 'calendarEvents'],
        isRepeated: !pdf, // join repeated conversations
      }),
      pdf && getRelevantSteps({ targetId: conversationId }),
    ]);

    if (pdf) {
      setNextSteps(nextStepsData);
    }

    if (isEmpty(conversationData)) {
      $loading.off();
      return;
    }

    const {
      talkingPoints = [],
      attachments = [],
      donePoints = [],
      comments = [],
      ...otherProp
    } = conversationData;

    if (isAddPendingLinks) {
      const calendarEventIntegration =
        otherProp.calendarEvent[`${otherProp.calendarEvent.integration}Connection`];
      if (calendarEventIntegration) {
        calendarEventIntegration.linkStatus = LINK_STATUS.PENDING;
        conversationData.calendarEvent[`${conversationData.calendarEvent.integration}Connection`] =
          { ...calendarEventIntegration };
      }
    }

    $talkingPoints.set(
      orderBy(talkingPoints, ['original', 'order'], ['desc', 'asc'])
        .filter((talkingPoint) => talkingPoint)
        .map((p) => ({
          ...p,
          comments: comments.filter((c) => c.source === 'talkingPoint' && c.sourceId === p.id),
        })),
    );
    $conversationComments.set(
      filter(comments || [], (c) => !c.isApprovalFlow && !c.source && !c.isPrivate),
    );
    $privateNotes.set(
      filter(
        comments || [],
        (c) => !c.isApprovalFlow && !c.source && c.isPrivate && c.author === user.id,
      ),
    );
    $donePoints.set(donePoints);
    $TPOrder.set(conversationData.TPOrder);
    $attachments.set(attachments.map((a) => a.file));
    setConversation({ comments, ...otherProp }); // comments also need to approval flow

    // set upcomingEvents and passedEvents
    let upcoming = false;
    let tempUpcoming = [];
    let tempPassed = [];
    const tempEvents = (otherProp.repeatedConversations || []).sort(
      (a, b) => new Date(a.startDate) - new Date(b.startDate),
    );
    tempEvents.forEach((event) => {
      if (upcoming) {
        tempUpcoming.push(event);
      } else {
        if (event.id === conversationId) {
          upcoming = true;
        } else {
          tempPassed.push(event);
        }
      }
    });

    setUpcomingEvents(tempUpcoming);
    setPassedEvents(tempPassed);

    $loading.off();
  }

  useEffect(() => {
    fetchConversation(conversationId);
    // eslint-disable-next-line
  }, [conversationId]);

  const flags = getFlags(conversation, user, currentRole);
  return {
    repeatedConversations: [],
    ...conversation,
    ...flags,
    loading: $loading.value,
    talkingPoints: $talkingPoints.items,
    TPOrder: $TPOrder.items,
    conversationComments: $conversationComments.items,
    privateNotes: $privateNotes.items,
    donePoints: $donePoints.items,
    attachments: $attachments.items,
    isApproveUserWarning: $isApproveUserWarning.value,
    isCopyTalkingPointModal: $copyTalkingPointModal.value,
    isCanCopy: !isEmpty(upcomingEvents),
    upcomingEvents,
    passedEvents,
    ...(pdf && { nextSteps }),
    downloadPdf() {
      downloadConversationPdf(conversationId, conversation.name);
      addToast({
        title: i18n._(t`Downloading conversation PDF`),
        subtitle: i18n._(t`Generating the conversation in PDF. Please wait a few seconds.`),
        type: TOAST_TYPES.INFO,
      });
    },
    async toggleConversationStatus() {
      if (
        await confirm(i18n, !flags.isDone ? i18n._(lockWarning) : i18n._(unlockWarning), {
          title: flags.isDone ? i18n._(t`Unlock conversation?`) : i18n._(t`Lock conversation?`),
        })
      ) {
        const newStatus = !flags.isDone
          ? CONVERSATION_STATUSES.DONE.key
          : CONVERSATION_STATUSES.TODO.key;
        setConversation((conv) => ({ ...conv, status: newStatus }));
        await updateUserConversationStatus(conversation.id, newStatus);
      }
    },

    async onUpdateEvent({ startDate, endDate, includeLinkMeeting }, isUpdateFuture = false) {
      if (isUpdateFuture) {
        const isAddPendingLinks =
          !conversation.originalConversation.includeLinkMeeting && includeLinkMeeting;

        await fetchConversation(conversationId, { isAddPendingLinks });
      } else {
        setConversation((conv) => {
          const updated = {
            ...conv,
            repeatedConversations: (conversation.repeatedConversations || []).map(
              (repeatedConversation) => {
                if (repeatedConversation.id === conversationId) {
                  return {
                    ...repeatedConversation,
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                  };
                }
                return repeatedConversation;
              },
            ),
            startDate,
            endDate,
            originalConversation: { ...conv.originalConversation, includeLinkMeeting },
          };

          if (!conv.originalConversation.includeLinkMeeting && includeLinkMeeting) {
            const calendarEventIntegration =
              conv.calendarEvent[`${conv.calendarEvent.integration}Connection`];
            if (calendarEventIntegration) {
              calendarEventIntegration.linkStatus = LINK_STATUS.PENDING;
              updated.calendarEvent[`${conv.calendarEvent.integration}Connection`] = {
                ...calendarEventIntegration,
              };
            }
          }

          return updated;
        });
      }
    },

    async setDateOfConversation(data) {
      const result = await updateUserConversationDate(
        data.id,
        data.startDate,
        data.endDate,
        data.updateAll,
        data.includeLinkMeeting,
      );

      setConversation((conv) => {
        const updated = {
          ...conv,
          originalConversation: {
            ...conv.originalConversation,
            includeLinkMeeting: data.includeLinkMeeting,
          },
        };

        if (!conv.originalConversation.includeLinkMeeting && data.includeLinkMeeting) {
          const calendarEventIntegration =
            conv.calendarEvent[`${conv.calendarEvent.integration}Connection`];
          if (calendarEventIntegration) {
            calendarEventIntegration.linkStatus = LINK_STATUS.PENDING;
            updated.calendarEvent[`${conv.calendarEvent.integration}Connection`] = {
              ...calendarEventIntegration,
            };
          }
        }

        return updated;
      });

      return result;
    },

    async setTalkingPointState(id, isDone) {
      if (isDone) {
        $donePoints.push(id);
      } else {
        $donePoints.remove(id);
      }
      await updateUserTalkingPointsCheck(
        conversation.id,
        isDone ? [...$donePoints.items, id] : $donePoints.items.filter((p) => p !== id),
      );
    },

    createUpdatePrivateNote(comment, isUpdate = false) {
      if (isUpdate) {
        $privateNotes.set(
          map($privateNotes.items, (c) => {
            return c.id === comment.id ? comment : c;
          }),
        );
      } else {
        $privateNotes.push(comment);
      }
    },

    deletePrivateNote(id) {
      $privateNotes.set(filter($privateNotes.items, (c) => c.id !== id));
    },

    createUpdateConversationComment(comment, isUpdate = false) {
      if (isUpdate) {
        $conversationComments.set(
          map($conversationComments.items, (c) => {
            return c.id === comment.id ? comment : c;
          }),
        );
      } else {
        $conversationComments.push(comment);
      }
    },

    deleteConversationComment(id) {
      $conversationComments.set(filter($conversationComments.items, (c) => c.id !== id));
    },

    fetchConversation,

    async reorderTalkingPoints(TPOrder) {
      $TPOrder.set(TPOrder);
      await updateUserConversationTPOrder(conversationId, { TPOrder });
    },

    async onAddTalkingPoints(points) {
      const talkingPoints = points.map((tp) => ({
        ...tp,
        comments: [],
        conversation: conversationId,
      }));

      const TPs = await createTalkingPoints({ talkingPoints });

      $talkingPoints.set([...$talkingPoints.items, ...TPs.map((t) => ({ ...t, comments: [] }))]);
    },
    async deleteUserTalkingPoint(id) {
      await deleteTalkingPoint(id);
      $talkingPoints.remove((p) => p.id === id);
    },
    async updateTalkingPointComment(pointId, commentId, update) {
      await updateComment(commentId, update.comment);
      $talkingPoints.update(
        (p) => p.id === pointId,
        (point) => ({
          ...point,
          comments: (point.comments || []).map((c) => {
            if (c.id === commentId) {
              return {
                ...c,
                ...update,
              };
            }
            return c;
          }),
        }),
      );
    },
    async deleteTalkingComment(pointId, commentId) {
      await deleteComment(commentId);
      $talkingPoints.update(
        (p) => p.id === pointId,
        (point) => ({
          ...point,
          comments: (point.comments || []).filter((c) => c.id !== commentId),
        }),
      );
    },
    async addTalkingPointComment(pointId, text) {
      const comment = await createComment({
        comment: text,
        source: COMMENT_SOURCES.TALKING_POINT,
        sourceId: pointId,
        parentSource: COMMENT_SOURCES.CONVERSATION,
        parentSourceId: conversation.id,
      });

      $talkingPoints.update(
        (i) => i.id === pointId,
        (point) => ({
          ...point,
          comments: (point.comments || []).concat([comment]),
        }),
      );
    },

    async onCopyTalkingPoint(nextConversation) {
      await copyTalkingPoint(TPtoCopy, nextConversation[0].id);
      showToastMessage(CONVERS_TOAST_TYPES.TP_COPY_TO_NEXT);
    },

    openCopyTalkingPointModal(tp) {
      $copyTalkingPointModal.on();
      setTPtoCopy(tp);
    },

    closeCopyTalkingPointModal() {
      $copyTalkingPointModal.off();
      setTPtoCopy(null);
    },

    deleteConversation() {
      const id = get(conversation, 'originalConversation.id', conversation.originalConversation);
      return conversationsServices.deleteConversation(id);
    },

    onAddAttachment(file) {
      $attachments.push(file);
    },

    onDeleteAttachment(fileId) {
      $attachments.removeBy(fileId);
    },

    updateCalendarEvent(event) {
      setConversation((conv) => ({
        ...conv,
        calendarEvent: event,
      }));
    },
  };
}

export default useConversationState;
