import styled, { css } from 'styled-components';

import { Button } from '~/components/Buttons';
import { Wrapper } from '~/components/Input/design';
import Modal from '~/components/Modal';

import { COLORS } from '~/styles';

export const Title = styled.h2`
  font-size: 26px;
  line-height: 1;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin: 0px;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${COLORS.SUBTEXT};
`;

export const NameModalContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 16px;
  border-top: solid 1px ${COLORS.BORDERS};
  gap: 20px;
`;

export const SkillAlias = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

export const SectionHeader = styled.span`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_HOVER};
`;

export const ConnectModalContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  padding-top: 12px;
  border-top: solid 1px ${COLORS.BORDERS};
  gap: 20px;
`;

export const NameModal = styled(Modal)`
  padding: 32px;
  padding-top: 0;
  padding-bottom: 13px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
`;

export const StyledButton = styled(Button)`
  top: 5px;
  width: 34px;
  height: 34px;
  padding: 0px;
  margin-right: 7px;
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
`;

export const Error = styled.span`
  font-size: 12px;
  color: ${COLORS.ACCENT_ERROR};
`;

export const SkillCategoryName = styled.div`
  font-size: 14px;
  color: ${COLORS.TEXT_HOVER};
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  span {
    margin-bottom: 3px;
  }
  ${Wrapper} {
    margin-bottom: 9px;
  }
`;

export const Categories = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  gap: 12px;
`;

export const CategoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;

  width: 100%;
  padding: 19px 16px 19px 16px;
  border-radius: 6px;
  background-color: ${COLORS.HOVER};

  font-size: 16px;
  line-height: 1.63;
  letter-spacing: 0.18px;
  color: ${COLORS.TEXT_MAIN};

  :hover > span > svg {
    display: inline;
  }

  span > svg {
    margin-left: 5px;
    display: none;
    cursor: pointer;
  }
`;

export const Levels = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: 7px;
  margin-bottom: 3px;

  transform: rotateX(180deg);
`;

export const ScrollWrapper = styled.div<{ hasScrollbar?: boolean }>`
  transform: rotateX(180deg);
  overflow-x: auto;

  margin-top: 3px;

  ${({ hasScrollbar }) =>
    !hasScrollbar &&
    css`
      margin-top: 7px;
    `}

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${COLORS.BG_ELEMENTS};
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.PLACEHOLDERS};
    border-radius: 100px;
  }

  &::-webkit-scrollbar:hover {
    cursor: pointer;
  }
`;

export const Level = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 3px;
`;

export const LevelName = styled.span`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_HOVER};
`;

export const LevelIndex = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  border-radius: 6px;
  background-color: ${COLORS.TODO_BLUE_LIGHT};
  text-transform: uppercase;
`;

export const CategoryName = styled.span`
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: 0.18px;
  color: ${COLORS.TEXT_MAIN};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

export const LevelInput = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7px 15px 16px 15px;
  border-radius: 6px;
  background-color: ${COLORS.BG_LIST};
  ${Wrapper} {
    margin-bottom: 10px;
  }
`;
