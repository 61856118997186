import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import logo from '~/assets/logo.svg';

import { COLORS, COLOR_PALETTE } from '~/styles';

const Wrapper = styled.div`
  background-color: ${COLORS.BG_PAGE};
  width: 100%;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  position: relative;
`;

const Banner = styled.div`
  background-color: ${COLOR_PALETTE.WHITE};
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    height: 23px;
    width: 133px;
  }
`;

const PublicLayout = ({ children, i18n, layoutImgStyle = undefined }) => (
  <Wrapper>
    <Banner>
      <img style={layoutImgStyle} src={logo} alt={i18n._(t`logo`)} />
    </Banner>
    <Container>{children}</Container>
  </Wrapper>
);

export default withI18n()(PublicLayout);
