import React, { useEffect, useRef, useState } from 'react';

import { ROLES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import CustomDatesModal from '~/components/CustomDatesModal';
import DatePicker from '~/components/DatePickerDeprecated';
import { DigitalMeeting } from '~/components/DigitalMeeting';
import DropdownButton from '~/components/DropdownButton';
import ExpandMoreIcon from '~/components/Icons/ExpandMore';
import { FieldGroup, FieldTitle } from '~/components/Text';
import { NoCalendarIntegrationCreateWarning } from '~/components/Warnings/NoCalendarIntegrationCreateWarning';

import {
  CONVERSATION_STATUSES,
  CONVERSATION_REPEAT_TYPES,
  USER_INTEGRATION_TYPES,
} from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { useUserCalendarIntegration } from '~/hooks/useUserHasIntegration';
import { getSelectedRole } from '~/selectors/baseGetters';
import { getCurrentConversation } from '~/selectors/currentConversation';
import {
  setEndDate,
  setStartDate,
  setRepeat as setConversationRepeat,
  setIncludeLinkMeeting,
} from '~/store/currentConversation/actions';
import { COLOR_PALETTE, COLOR_SET, COLORS } from '~/styles';

const RowInline = styled.div`
  display: flex;
`;

const FromWrapper = styled.div`
  display: flex;
  margin-right: 10px;
`;

const RepeatWrapper = styled.div`
  width: 182px;
  ${(props) => !props.$isUpdate && 'margin-left: 50px;'}
`;

const RepeatUntilWrapper = styled.div`
  width: 124px;
  margin-left: 8px;
`;

const TimeWrapper = styled.div`
  display: flex;
  ${(props) => !props.$isUpdate && 'margin-left: 50px;'}
`;

const IntervalButtonLabel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 150px;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
`;

const ButtonText = styled.div`
  display: flex;
  color: ${COLOR_PALETTE.BLACK};
  width: 100%;
  flex: 1;
  align-items: center;
`;

const DisabledButtonText = styled.div`
  display: flex;
  color: ${COLOR_SET.DARK_GRAY};
  width: 100%;
  flex: 1;
  align-items: center;
`;

const IntervalDiv = styled.div`
  padding: 0 16px;
  width: 182px;
  height: 44px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${COLORS.BG_PAGE};
    color: var(--company-color);
    cursor: pointer;
  }
`;

const IntervalWrapper = styled.div`
  width: 182px;
`;

const StyledDigitalMeeting = styled(DigitalMeeting)`
  margin-bottom: 51px;
`;

const StyledNoCalendarIntegrationCreateWarning = styled(NoCalendarIntegrationCreateWarning)`
  margin-bottom: 23px;
`;

const intervalButtonStyles = {
  width: 182,
  marginBottom: 12,
  height: 40,
  borderRadius: 6,
  border: `solid 1px ${COLOR_SET.LIGHT_GREY}`,
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: COLOR_PALETTE.WHITE,
  justifyContent: 'center',
};

const disabledIntervalButtonStyles = {
  width: 182,
  marginBottom: 12,
  height: 40,
  borderRadius: 6,
  border: `solid 1px ${COLOR_SET.LIGHT_GREY}`,
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: '#e3e3e3',
  color: COLOR_SET.DARK_GRAY,
  justifyContent: 'center',
};

const DatesSection = () => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const intervalRef = useRef();
  const { hasCalendarIntegration, calendarIntegration } = useUserCalendarIntegration();

  const interval = [
    {
      value: CONVERSATION_REPEAT_TYPES.NO_REPEAT,
      label: i18n._(t`No repeat`),
      selectUntil: false,
      repeatInterval: false,
    },
    {
      value: CONVERSATION_REPEAT_TYPES.WEEK,
      label: i18n._(t`Weekly`),
      selectUntil: true,
      repeatInterval: 'weeks',
    },
    {
      value: CONVERSATION_REPEAT_TYPES.YEAR,
      label: i18n._(t`Yearly`),
      selectUntil: true,
      repeatInterval: 'years',
    },
    {
      value: CONVERSATION_REPEAT_TYPES.CUSTOM,
      label: i18n._(t`Custom`),
      selectUntil: false,
      repeatInterval: false,
    },
  ];

  const conversation = useSelector(getCurrentConversation);
  const role = useSelector(getSelectedRole);
  const [repeat, setRepeat] = useState(interval[0]);
  const [every, setEvery] = useState(null);
  const [until, setUntil] = useState(null);
  const [customDeadlines, setCustomDeadlines] = useState();
  const isEndSetManually = useBoolState(false);
  const customModal = useBoolState(false);
  const onChangeStartDate = (date) => dispatch(setStartDate(date));
  const onChangeEndDate = (date) => dispatch(setEndDate(date));
  const changeIncludeLinkMeeting = (value) => void dispatch(setIncludeLinkMeeting(value));

  const isAdmin = role === ROLES.ADMIN;

  const isUpdate =
    conversation && conversation.id && conversation.status !== CONVERSATION_STATUSES.DRAFT.key;

  // first render (update state with currentConversation data)
  useEffect(() => {
    if (conversation.repeat && conversation.repeat.type !== CONVERSATION_REPEAT_TYPES.NO_REPEAT) {
      setRepeat(interval.map((i) => i.value === conversation.repeat.type && i).filter((i) => i)[0]);
      setEvery(conversation.repeat.every);
      setUntil(conversation.repeat.until);
      if (conversation.repeat.deadlines.length > 1) {
        setCustomDeadlines(conversation.repeat.deadlines);
      }
    }
    // eslint-disable-next-line
  }, [conversation.id]);

  // update currentConversation.repeat, when some state param changed
  useEffect(() => {
    if (conversation) {
      const newRepeatField = {
        type: repeat.value,
        every: !customDeadlines ? every : null,
        until: !customDeadlines ? until : null,
        deadlines: customDeadlines || [
          { startDate: conversation.startDate, endDate: conversation.endDate },
        ],
      };
      dispatch(setConversationRepeat(newRepeatField));
    }

    // eslint-disable-next-line
  }, [repeat, every, until, customDeadlines]);

  useEffect(() => {
    if (repeat.value !== CONVERSATION_REPEAT_TYPES.CUSTOM) {
      setCustomDeadlines();
    }
  }, [repeat]);

  const handleFromChange = (value, isFromDatepicker = false) => {
    const endTime = conversation.endDate && moment(conversation.endDate);
    if (value && endTime && moment.min(moment(value), endTime).isSame(endTime)) {
      onChangeStartDate(moment(value).format());
      onChangeEndDate(moment(value).clone().add('30', 'minutes').format());
      isEndSetManually.off();
    } else {
      const selectedTime =
        conversation.startDate || !isFromDatepicker
          ? moment(value).clone()
          : moment(value).clone().add('8', 'hours');
      onChangeStartDate(selectedTime.format());
      if (!isEndSetManually.value) {
        onChangeEndDate(selectedTime.add('30', 'minutes').format());
      }
    }

    if (until) {
      const d = new Date(value);
      const nextUntil = new Date(until);
      nextUntil.setHours(d.getHours());
      nextUntil.setMinutes(d.getMinutes());
      setUntil(nextUntil);
    }
  };

  const handleToChange = (value) => {
    const endHours = moment(value).hours();
    const endMinutes = moment(value).minutes();
    let endDate = moment(value);
    isEndSetManually.on();
    // set year/month/date from startDate
    if (conversation.startDate) {
      const start = moment(conversation.startDate);
      endDate = start.clone().hours(endHours).minutes(endMinutes);
      if (moment.max(start, endDate).isSame(start)) {
        onChangeStartDate(endDate.clone().subtract('15', 'minutes').format());
      }
    }
    onChangeEndDate(endDate.format());
  };

  const renderInterval = () => {
    return (
      <IntervalWrapper>
        {(isUpdate
          ? interval
              .map((i) => i.value !== CONVERSATION_REPEAT_TYPES.NO_REPEAT && i)
              .filter((i) => i)
          : interval
        ).map((i) => {
          return (
            <IntervalDiv
              key={i.value}
              onClick={() => {
                if (i.value === CONVERSATION_REPEAT_TYPES.CUSTOM) {
                  customModal.on();
                } else {
                  setRepeat(i);
                }
                intervalRef.current._tippy.hide();
                if (i.value === CONVERSATION_REPEAT_TYPES.NO_REPEAT) {
                  setUntil(null);
                  setEvery(null);
                } else {
                  if (!until) {
                    setUntil(new Date(moment(conversation.startDate).add(1, 'year')));
                  }
                  if (i.value !== CONVERSATION_REPEAT_TYPES.CUSTOM) {
                    setEvery(1);
                  }
                }
              }}
            >
              {i.label}
            </IntervalDiv>
          );
        })}
      </IntervalWrapper>
    );
  };

  const renderDate = (conv, disabled = false) => {
    return (
      <RowInline>
        {!isUpdate && (
          <FieldGroup>
            <FieldTitle>
              <Trans>Date</Trans>
            </FieldTitle>
            <DatePicker
              selected={conv.startDate}
              disabled={disabled}
              onChange={(value) => {
                // update endDate
                if (conv.endDate) {
                  const endHours = moment(conv.endDate).hours();
                  const endMinutes = moment(conv.endDate).minutes();
                  const newEndDate = moment(moment(value).format())
                    .hours(endHours)
                    .minutes(endMinutes);
                  handleFromChange(value, true);
                  onChangeEndDate(newEndDate.format());
                } else {
                  handleFromChange(value, true);
                }
              }}
              width={125}
              placeholder="12-02-2020"
              isHideIcon={true}
            />
          </FieldGroup>
        )}
        <TimeWrapper $isUpdate={isUpdate}>
          {!isUpdate && (
            <>
              <FromWrapper>
                <FieldGroup>
                  <FieldTitle>
                    <Trans>From</Trans>
                  </FieldTitle>
                  <DatePicker
                    disabled={disabled}
                    selected={conv.startDate}
                    onChange={handleFromChange}
                    width={80}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    placeholder="11:00"
                    isHideIcon={true}
                  />
                </FieldGroup>
              </FromWrapper>
              <FieldGroup>
                <FieldTitle>
                  <Trans>To</Trans>
                </FieldTitle>
                <DatePicker
                  disabled={disabled}
                  selected={conv.endDate}
                  onChange={handleToChange}
                  width={80}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  placeholder="12:00"
                  isHideIcon={true}
                />
              </FieldGroup>
            </>
          )}
          <RepeatWrapper $isUpdate={isUpdate}>
            {!(
              isUpdate &&
              [CONVERSATION_REPEAT_TYPES.NO_REPEAT, CONVERSATION_REPEAT_TYPES.CUSTOM].includes(
                get(conversation, 'repeat.type'),
              )
            ) && (
              <FieldGroup>
                <FieldTitle>
                  <Trans>Repeat</Trans>
                </FieldTitle>
                {isUpdate &&
                get(conversation, 'repeat.type') === CONVERSATION_REPEAT_TYPES.CUSTOM ? (
                  <Button
                    disabled={true}
                    styles={disabledIntervalButtonStyles}
                    label={
                      <IntervalButtonLabel>
                        <DisabledButtonText>{repeat && repeat.label}</DisabledButtonText>
                        <div>
                          <ExpandMoreIcon fill={COLOR_SET.DARK_GRAY} />
                        </div>
                      </IntervalButtonLabel>
                    }
                  />
                ) : (
                  <DropdownButton
                    contentWidth={182}
                    content={renderInterval()}
                    maxWidth={182}
                    popoverRef={intervalRef}
                    placement="bottom"
                    disabled={isAdmin}
                  >
                    <Button
                      styles={intervalButtonStyles}
                      disabled={disabled || isAdmin}
                      label={
                        <IntervalButtonLabel>
                          <ButtonText>
                            {repeat && every >= 2 ? i18n._(t`Custom`) : repeat.label}
                          </ButtonText>
                          {!disabled && !isAdmin && <ExpandMoreIcon fill={COLOR_PALETTE.BLACK} />}
                        </IntervalButtonLabel>
                      }
                    />
                  </DropdownButton>
                )}
              </FieldGroup>
            )}
          </RepeatWrapper>
          {(repeat.selectUntil || (customDeadlines && customDeadlines.length === 1)) && (
            <RepeatUntilWrapper>
              <FieldGroup>
                <FieldTitle>
                  <Trans>Until</Trans>
                </FieldTitle>
                <DatePicker
                  disabled={
                    isAdmin ||
                    (isUpdate && conversation?.repeat?.type === CONVERSATION_REPEAT_TYPES.CUSTOM)
                  }
                  selected={until}
                  onChange={(value) => {
                    const newUntil = new Date(value);
                    const from = new Date(conv.startDate);
                    newUntil.setHours(from.getHours());
                    newUntil.setMinutes(from.getMinutes());
                    setUntil(newUntil);
                  }}
                  width={124}
                  isHideIcon={true}
                />
              </FieldGroup>
            </RepeatUntilWrapper>
          )}
        </TimeWrapper>
      </RowInline>
    );
  };

  return (
    <>
      {customDeadlines && customDeadlines.length > 1
        ? renderDate(customDeadlines[0], true)
        : renderDate(conversation, isAdmin)}

      {!isUpdate && hasCalendarIntegration && calendarIntegration.hasOnlineMeetingProvider && (
        <StyledDigitalMeeting
          checked={conversation.includeLinkMeeting}
          onChange={changeIncludeLinkMeeting}
          calendarType={calendarIntegration.type}
        />
      )}

      {!isUpdate && hasCalendarIntegration && !calendarIntegration.hasOnlineMeetingProvider && (
        <StyledDigitalMeeting
          tippyProps={{
            ...(calendarIntegration.type === USER_INTEGRATION_TYPES.azure
              ? {
                  content: i18n._(
                    t`Your integration email account does not have access to create Microsoft Teams meetings`,
                  ),
                }
              : {}),
          }}
          calendarType={calendarIntegration.type}
          disabled={true}
        />
      )}

      {!isUpdate && !hasCalendarIntegration && <StyledNoCalendarIntegrationCreateWarning />}
      {customModal.value && (
        <CustomDatesModal
          i18n={i18n}
          onClose={customModal.off}
          repeat={repeat}
          setRepeat={setRepeat}
          every={every}
          setEvery={setEvery}
          interval={
            isUpdate
              ? interval
                  .map(
                    (i) =>
                      i.value !== CONVERSATION_REPEAT_TYPES.CUSTOM &&
                      i.value !== CONVERSATION_REPEAT_TYPES.NO_REPEAT &&
                      i,
                  )
                  .filter((i) => i)
              : interval
                  .map((i) => {
                    if (i.value === CONVERSATION_REPEAT_TYPES.NO_REPEAT) {
                      return false;
                    }
                    if (i.value === CONVERSATION_REPEAT_TYPES.CUSTOM) {
                      i.label = i18n._(t`Adjusted`);
                    }
                    return i;
                  })
                  .filter((i) => i)
          }
          deadLine={
            customDeadlines || { startDate: conversation.startDate, endDate: conversation.endDate }
          }
          setDeadline={setCustomDeadlines}
        />
      )}
    </>
  );
};

export default React.memo(DatesSection);
