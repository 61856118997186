import React from 'react';

import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import gearIcon from '~/assets/gear.svg';
import conversationIcon from '~/assets/main-menu-icons/chat-1.svg';
import groupIcon from '~/assets/main-menu-icons/men.svg';
import starIcon from '~/assets/main-menu-icons/star.svg';
import mdiRocketIcon from '~/assets/mdi-rocket.svg';

import { QUICK_START_PARTS } from '~/constants/quickStartSteps';

const IconContainer = styled.div`
  min-width: 24px;
`;

const partIcon = {
  [QUICK_START_PARTS.BASICS.key]: {
    url: mdiRocketIcon,
    width: '24px',
    height: '24px',
  },
  [QUICK_START_PARTS.COLLEAGUES_AND_TEAMS.key]: {
    url: groupIcon,
    width: '17px',
    height: '17px',
  },
  [QUICK_START_PARTS.SKILLS_AND_ROLES.key]: {
    url: gearIcon,
    width: '18px',
    height: '17px',
  },
  [QUICK_START_PARTS.FIRST_REVIEW.key]: {
    url: starIcon,
    width: '18px',
    height: '17px',
  },
  [QUICK_START_PARTS.CYCLES_SETTINGS.key]: {
    url: conversationIcon,
    width: '18px',
    height: '17px',
  },
};

const StepIcon = ({ partKey, color }) => {
  const icon = partIcon[partKey];
  return (
    <IconContainer>
      <SvgIcon
        isDefaultColor={!!color}
        defaultColor={color}
        width={icon.width}
        height={icon.height}
        url={icon.url}
      />
    </IconContainer>
  );
};

const wrapped = React.memo(StepIcon);

export default wrapped;
