import React, { useRef } from 'react';

import size from 'lodash/size';

import CheckBox from '~/components/CheckBox';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import Popover from '~/components/Popover';

import { ChevronIcon, Color, Dropdown, Item, SelectWrapper } from './design';

import { COLORS } from '~/styles';

interface HeatmapFilterProps {
  label: string;
  items?: { label: string; value: string; color: string }[];
  onClickItem: (value: string) => void;
  selectedItems: string[];
}

const DEFAULT_ITEMS = [
  { label: '80-100', value: '80-100', color: '#52dbb9' },
  { label: '60-79', value: '60-79', color: '#adeee3' },
  { label: '40-59', value: '40-59', color: '#f6efd1' },
  { label: '20-39', value: '20-39', color: '#fed0c4' },
  { label: '0-19', value: '0-19', color: '#fd8e73' },
];

const HeatmapFilter = ({
  label,
  items = DEFAULT_ITEMS,
  onClickItem,
  selectedItems,
}: HeatmapFilterProps) => {
  const popoverRef = useRef();

  return (
    // @ts-ignore
    <Popover
      ref={popoverRef}
      trigger="click"
      content={
        <Dropdown>
          {items.map(({ label, value, color }) => (
            <Item key={value} onClick={() => onClickItem(value)}>
              <Color color={color} />
              <CheckBox size={'20px'} checked={selectedItems.includes(value)} />
              <span>{label}</span>
            </Item>
          ))}
        </Dropdown>
      }
      minWidth={140}
      maxWidth={140}
      boxShadow="0 4px 4px 0 rgba(145, 157, 165, 0.12)"
      placement="bottom"
      $border={6}
      appendTo={document.body}
    >
      <SelectWrapper>
        <span className="value">
          {size(selectedItems)
            ? selectedItems
                .map((selectedItem) => items.find((item) => item.value === selectedItem)?.label)
                .join(', ')
            : label}
        </span>
        <ChevronIcon icon={ICONS.DROPDOWN} color={COLORS.ICONS_SECONDARY} size={ICON_SIZES.SMALL} />
      </SelectWrapper>
    </Popover>
  );
};

export { HeatmapFilter };
