import React from 'react';

import { t } from '@lingui/macro';
import find from 'lodash/find';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';

import type { IColumnTable } from '~/@types/table';

import { NameRow } from '../design';

import type { ISkillCategoryColumn } from '../types';
import type { I18n } from '@lingui/core';

export const createSkillCategoryColumns = ({
  isEditMode = false,
}: {
  isEditMode?: boolean;
}): { columns: IColumnTable<ISkillCategoryColumn>[] } => {
  return {
    columns: [
      {
        name: (i18n: I18n) => i18n._(t`Skill or KPI`),
        accessor: 'name',
        maxWidth: '200px',
        renderCell: ({ name, onDelete, onEdit }) => {
          return name ? (
            <NameRow maxWidth="200px" isBold>
              <span className="text">{name.en_GB || find(name)}</span>
              {isEditMode && (
                <div className="buttons">
                  <Button
                    type="button"
                    variant={ButtonVariant.ICON}
                    size={ButtonSize.MEDIUM}
                    icon={ICONS.EDIT_PENCIL}
                    onClick={onEdit}
                  />
                  <Button
                    type="button"
                    variant={ButtonVariant.ICON}
                    size={ButtonSize.MEDIUM}
                    icon={ICONS.DELETE_BIN}
                    onClick={onDelete}
                  />
                </div>
              )}
            </NameRow>
          ) : (
            <></>
          );
        },
      },
      {
        name: (i18n: I18n) => i18n._(t`Focus Area`),
        accessor: 'focusArea',
        renderCell: ({ focusArea }) => {
          return <NameRow>{focusArea.en_GB || find(focusArea)}</NameRow>;
        },
      },
      {
        name: (i18n: I18n) => i18n._(t`Level`),
        accessor: 'level',
        maxWidth: '100px',
        renderCell: ({ level, totalLevels }) => {
          return (
            <NameRow>
              <div className="center">
                <span className="black">{level}</span>/{totalLevels}
              </div>
            </NameRow>
          );
        },
      },
    ],
  };
};
