import styled from 'styled-components';

import { COLORS } from '~/styles';

const OvertimeCtr = styled.div`
  box-sizing: border-box;
  min-height: 500px;
  width: 100%;
`;

const OvertimeHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 20px;
  & > .icon {
    cursor: pointer;
  }
`;

const DropDownCtr = styled.div`
  position: relative;
`;

const BodyCtr = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 500px;
  position: relative;
  flex-direction: column;
  gap: 10px;
`;

const NoDataTextContainer = styled.span`
  color: ${COLORS.PLACEHOLDERS};
`;

export { OvertimeCtr, OvertimeHeader, DropDownCtr, BodyCtr, NoDataTextContainer };
