import React from 'react';

import { Trans } from '@lingui/macro';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';

import { LikeButtonWrapper } from './design';

import { COLORS } from '~/styles';

const Themes = {
  DEFAULT: {
    backgroundColor: COLORS.INACTIVE,
    iconColor: COLORS.WHITE,
    hoverBackgroundColor: COLORS.ACCENT_SUCCESS,
    hoverIconColor: COLORS.WHITE,
    hoverTextColor: COLORS.SUBTEXT,
  },
  LIKED: {
    backgroundColor: COLORS.WHITE,
    iconColor: COLORS.ACCENT_SUCCESS,
    textColor: COLORS.ACCENT_SUCCESS,
    hoverBackgroundColor: COLORS.WHITE,
    hoverIconColor: COLORS.INACTIVE,
    hoverTextColor: COLORS.SUBTEXT,
  },
};

interface ILikeButtonProps {
  onSave: () => void;
  isSaved?: boolean;
  isDisabled: boolean;
}

const LikeButton = ({ onSave, isSaved, isDisabled }: ILikeButtonProps) => {
  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    event.preventDefault();
    onSave();
  };

  const theme = isSaved ? Themes.LIKED : Themes.DEFAULT;

  return (
    <LikeButtonWrapper {...theme} isSaved={isSaved}>
      <Button
        type="button"
        variant={ButtonVariant.ICON}
        size={ButtonSize.MEDIUM}
        onClick={(event) => handleOnClick(event)}
        icon={ICONS.HEART}
        disabled={isDisabled}
      />
      <span className="btn-text">
        <Trans>{isSaved ? 'Liked' : 'Like'}</Trans>
      </span>
    </LikeButtonWrapper>
  );
};

export { LikeButton };
