import React from 'react';

import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS, ICON_SIZES } from '~/components/Icon';

import { COLORS } from '~/styles';

export interface IHeaderProps {
  actionButton?: {
    label: string;
    onClick: () => void;
  } | null;
  actionButtonIcon?: ICONS;
  actionButtonVariant?: ButtonVariant;
  secondaryButton?: {
    label: string;
    onClick: () => void;
  };
  secondaryButtonVariant?: ButtonVariant;
  filters?: {
    search: string;
    setSearch: (value: string) => void;
  };
  headerTitle?: string;
}

const LeftSideActions = styled.div`
  display: flex;
`;

const RightSide = styled.div`
  display: flex;
  gap: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${COLORS.WHITE};
  padding: 0px 0px 15px 0px;
  border-top-right-radius: 10px;
`;

const Title = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
`;

const TableHeader: React.FC<IHeaderProps> = ({
  children,
  actionButton,
  actionButtonIcon,
  actionButtonVariant,
  secondaryButton,
  secondaryButtonVariant,
  headerTitle,
}) => {
  const { i18n } = useLingui();

  return (
    <Wrapper>
      <LeftSideActions>
        <Title>{headerTitle}</Title>
      </LeftSideActions>
      <RightSide>
        {children}
        {secondaryButton && (
          <Button
            variant={secondaryButtonVariant ? secondaryButtonVariant : ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
            label={i18n._(secondaryButton.label)}
            onClick={secondaryButton.onClick}
          />
        )}
        {actionButton && (
          <Button
            variant={actionButtonVariant ? actionButtonVariant : ButtonVariant.SECONDARY}
            icon={actionButtonIcon}
            iconSize={ICON_SIZES.SMALL}
            size={ButtonSize.MEDIUM}
            label={i18n._(actionButton.label)}
            onClick={actionButton.onClick}
          />
        )}
      </RightSide>
    </Wrapper>
  );
};

export { TableHeader };
