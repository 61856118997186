import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { confirm } from '~/components/ConfirmDialog';
import { ProfileSettingsBlock } from '~/components/ProfileSettingsComponents/MainDashboard/ProfileSettingsBlock';
import Switch from '~/components/Switch';

import { INSTRUCTIONS } from '~/constants/instructions';
import { getUser } from '~/selectors/baseGetters';
import { updateMFA } from '~/services/users';
import { setAuthUser } from '~/store/auth/actions';
import { COLORS } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';

import { SectionHeader } from '../SectionHeader';

const LinkStyled = styled.a`
  color: var(--company-color);
  text-decoration: none;
  margin-left: 4px;
  margin-right: 4px;
`;

const RowOnLine = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.TEXT_MAIN};
  margin-left: 8px;
`;

const SecurityBlock = ({ onLogout }: { onLogout: () => void }) => {
  const user = useSelector(getUser);
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const security = user.security || {};

  const handleUpdateMFA = async (use_mfa: string) => {
    const here = (
      <LinkStyled
        // @ts-ignore
        href={getInstructionUrl(INSTRUCTIONS.HOW_MFA_WORKS)}
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </LinkStyled>
    );

    if (use_mfa) {
      const confirmOptions = {
        title: i18n._(t`Do you want to enable MFA?`),
        confirmText: i18n._(t`Enable`),
        cancelText: i18n._(t`Cancel`),
      };

      if (
        await confirm(
          i18n,
          <div>
            <div>
              {i18n._(
                t`If you enable MFA you are required to set up authentication via a authenticator app (Example: Google authenticator). This will help to keep your account safe but will require you to login with your password and an additional security step. You will need to log out and set-up MFA immediately. Click cancel if you do not want to do this.`,
              )}
            </div>
            <RowOnLine>
              <div>{i18n._(t`Click`)}</div>
              {here}
              <div>{i18n._(t`for more information about how MFA works.`)}</div>
            </RowOnLine>
          </div>,
          confirmOptions,
        )
      ) {
        // @ts-ignore
        dispatch(setAuthUser(await updateMFA(use_mfa)));
        onLogout();
      }
    } else {
      // @ts-ignore
      dispatch(setAuthUser(await updateMFA(use_mfa)));
    }
  };

  const Content = (
    <Row>
      <Switch onChange={handleUpdateMFA} checked={security.use_mfa || security.force_mfa} />
      <Text>
        <Trans>Enable multi factor authenticaion</Trans>
      </Text>
    </Row>
  );

  return <ProfileSettingsBlock header={<SectionHeader title="Security" />} content={Content} />;
};

export { SecurityBlock };
