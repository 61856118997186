import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import styled from 'styled-components';

import { GoalCycleTitle } from '~/components/GiveFeedback/components/Section/components/GoalCycleTitle';
import QuestionCard from '~/components/GiveFeedback/components/Section/components/QuestionCard';
import { ReviewGoalQuestion } from '~/components/GiveFeedback/components/Section/components/ReviewGoalQuestion';
import { ReviewGoalAverageQuestion } from '~/components/GiveFeedback/components/Section/components/ReviewGoalQuestion/ReviewAverageGoalQuestion';
import { validateRating } from '~/components/GiveFeedback/utils';
import Placeholder from '~/components/Placeholder';

import { RATING_TYPES } from '~/constants';

const NoGoalsPlaceholder = styled(Placeholder)`
  border-radius: 6px;
  background-color: #ebf1fe;
  padding: 12px;
  margin-bottom: 8px;
`;

function ReviewGoalsQuestion({
  question,
  ratings,
  review,
  activeQuestionStep,
  onChange,
  onQuestionClick,
  tabs,
  fetchedRatings,
  forUser,
  indexCurrent,
  indexTotal,
  isOpenDefault,
  isOpenForce,
  ratingType,
  isReadOnly,
  showErrors,
  cycle,
  viewerId,
}) {
  const { i18n } = useLingui();
  const handleClickQuestion = () => {
    if (onQuestionClick) {
      onQuestionClick(question.id);
    }
  };

  const isSelf = ratingType === RATING_TYPES.SELF_RATING;
  const hasErrors = _.some((_.values(ratings) ?? []).map((rating) => validateRating(rating)));

  return (
    <QuestionCard
      title={
        <GoalCycleTitle
          isSelf={isSelf}
          userName={forUser.firstName}
          questionType={question.type}
          cycleName={cycle.name}
        />
      }
      // Eval questions do not have description instead we use name
      description={question.name}
      isActive={activeQuestionStep === question.id}
      hideFooter
      isInlineTitleStyle
      onClick={handleClickQuestion}
      isError={showErrors && hasErrors}
      indexCurrent={indexCurrent}
      indexTotal={indexTotal}
      isOpenDefault={isOpenDefault}
      isOpenForce={isOpenForce}
      hideBorder
    >
      {(question.goals ?? []).length > 0 &&
        question.goals.map((goal, index) => {
          return (
            <ReviewGoalQuestion
              key={goal.id}
              question={question}
              goal={goal}
              ratings={ratings}
              forUser={forUser}
              ratingType={ratingType}
              fetchedRatings={fetchedRatings}
              onQuestionClick={onQuestionClick}
              onChange={onChange}
              isOpenDefault={index === 0}
              isOpenForce={isOpenForce}
              isReadOnly={isReadOnly}
              tabs={tabs}
              viewerId={viewerId}
            />
          );
        })}
      {(question.goals ?? []).length === 0 && (
        <NoGoalsPlaceholder
          title={i18n._(t`No goals`)}
          subTitle={i18n._(t`No goals existed at the time of the creation of this conversation.`)}
          subTitleStyles={{ fontSize: '12px' }}
        />
      )}
      {question.isAverageQuestionEnabled && (
        <ReviewGoalAverageQuestion
          isSelf={isSelf}
          ratings={ratings}
          fetchedRatings={fetchedRatings}
          question={question}
          forUser={forUser}
          ratingType={ratingType}
          onQuestionClick={onQuestionClick}
          onChange={onChange}
          isOpenDefault={(question.goals ?? []).length === 0}
          isOpenForce={isOpenForce}
          isReadOnly={isReadOnly}
          tabs={tabs}
          cycle={cycle}
          review={review}
          viewerId={viewerId}
        />
      )}
    </QuestionCard>
  );
}

export { ReviewGoalsQuestion };
