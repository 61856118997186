import { PATH_STATUSES } from '@learned/constants';
import filter from 'lodash/filter';
import get from 'lodash/get';
import map from 'lodash/map';
import size from 'lodash/size';
import nanoId from 'nanoid';
import { createReducer } from 'redux-act';

import * as currentPathActions from './actions';

const INITIAL_STATE = {
  name: '',
  description: '',
  image: '',
  sections: [],
  activities: [],
  activitiesProps: {},
  status: PATH_STATUSES.ACTIVE,
  users: [],
  owners: [],
  isAvailableInUserLibrary: true,
};

export const currentPath = createReducer(
  {
    [currentPathActions.setCurrentPath]: (_state, payload) => {
      return {
        ...INITIAL_STATE,
        ...(payload || {}),
        sections: get(payload, 'sections', []).map((s) => ({ ...s, id: nanoId() })),
      };
    },

    [currentPathActions.updateCurrentPath]: (state, payload) => {
      return {
        ...state,
        ...(payload || {}),
      };
    },

    [currentPathActions.createCurrentPathSection]: (state, payload) => {
      const oldSections = state.sections || [];
      return {
        ...state,
        sections: [
          ...oldSections,
          {
            ...payload,
            id: nanoId(),
            order: oldSections.length,
          },
        ],
      };
    },

    [currentPathActions.updateCurrentPathSection]: (state, updateData = {}) => {
      const oldSections = state.sections || [];

      return {
        ...state,
        sections: oldSections.map((section) => {
          if (section.order === updateData.order) {
            return {
              ...section,
              ...updateData,
            };
          }
          return section;
        }),
      };
    },

    [currentPathActions.deleteCurrentPathSection]: (state, deleteData = {}) => {
      const oldSections = state.sections || [];
      const deletedOrder = deleteData.order;
      const oldActivities = state.activities || [];
      const oldActivitiesProps = state.activitiesProps || {};
      let newActivitiesProps = {};
      let activitiesToDelete = [];

      // update order in activitiesProps
      map(oldActivitiesProps, (activity, key) => {
        if (activity.section > deletedOrder) {
          newActivitiesProps[key] = {
            ...activity,
            section: activity.section - 1,
          };
        } else if (activity.section < deletedOrder) {
          newActivitiesProps[key] = { ...activity };
        } else {
          // activities from deleted section
          activitiesToDelete = [...activitiesToDelete, activity.id];
        }
      });
      return {
        ...state,
        activitiesProps: newActivitiesProps,
        activities: oldActivities.filter((a) => !activitiesToDelete.includes(a.id)), // update activities
        sections: oldSections
          .filter((section) => section.order !== deleteData.order)
          .map((s) => {
            if (s.order > deleteData.order) {
              s.order = s.order - 1;
            }
            return s;
          }),
      };
    },

    [currentPathActions.addActivitiesToCurrentPathSection]: (state, payload) => {
      const { section, activities: addedActivities } = payload;

      // count activities
      const sectionActivities = filter(state.activitiesProps, (activityProps) => {
        return activityProps.section === section.order;
      });
      const activitiesLength = size(sectionActivities);

      // create props for new activities
      const addedActivitiesProps = {};
      addedActivities.map((activity, index) => {
        addedActivitiesProps[activity.id] = {
          order: activitiesLength + index,
          section: section.order,
          isObligated: false,
        };
      });

      return {
        ...state,
        activities: [...(state.activities || []), ...addedActivities],
        activitiesProps: {
          ...(state.activitiesProps || {}),
          ...addedActivitiesProps,
        },
      };
    },

    [currentPathActions.deleteActivityFromCurrentPathSection]: (state, activityId) => {
      const otherActivitiesProps = { ...(state.activitiesProps || {}) };
      const deletedOrder = otherActivitiesProps[activityId].order;
      const deletedSection = otherActivitiesProps[activityId].section;
      delete otherActivitiesProps[activityId];

      // change order
      Object.keys(otherActivitiesProps).map((activityId) => {
        const activityProps = otherActivitiesProps[activityId];
        // change order if activity from deleted activity section
        // + old activity order higher than deleted activity order
        if (activityProps.section === deletedSection) {
          if (activityProps.order > deletedOrder) {
            activityProps.order = activityProps.order - 1;
          }
        }
      });

      return {
        ...state,
        activities: state.activities.filter((a) => a.id !== activityId),
        activitiesProps: otherActivitiesProps,
      };
    },

    [currentPathActions.updateActivityInPath]: (state, payload) => {
      const { activities: updatedActivities } = payload;

      return {
        ...state,
        activities: (state.activities || []).map((activity) => {
          const updatedActivity = updatedActivities.find(
            (updatedActivity) => updatedActivity.id === activity.id,
          );

          return updatedActivity || activity;
        }),
      };
    },
  },
  INITIAL_STATE,
);
