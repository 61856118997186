import React, { useContext } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import IconButton from '~/components/IconButton';
import ConversationIcon from '~/components/Icons/Conversation';
import AddTalkingPointsModal from '~/components/Modals/AddTalkingPointsModal';
import Placeholder from '~/components/Placeholder';
import RickTextView from '~/components/RickTextView';
import SvgIcon from '~/components/SvgIcon';
import { FieldTitle, FieldGroup } from '~/components/Text';
import Divider from '~/components/UI/Divider';
import { ConversationSetupContext } from '~/pages/ConversationSetup';

import dndDots from '~/assets/dnd-dots.svg';

import { CONVERSATION_STATUSES, TALKING_POINT_TYPES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import {
  getId,
  getParticipants,
  getStatus,
  getTalkingPoints,
} from '~/selectors/currentConversation';
import {
  addTalkingPoints,
  removeTalkingPoint,
  setTalkingPoints,
} from '~/store/currentConversation/actions';
import { COLOR_PALETTE, COLORS } from '~/styles';
import { reorder } from '~/utils/reorder';

const Content = styled.div``;

const TalkingPoint = styled.div`
  display: flex;
  justify-content: normal;
  align-items: center;
  padding: 4px 10px 4px 20px;
  min-height: 26px;
  border-bottom: 1px solid #dbe1ef;
`;

const TalkingPointName = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin-left: 16px;
  word-break: break-word;
`;

const StyledRickTextView = styled(RickTextView)`
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin-left: 16px;
`;

const TalkingPointLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  color: ${COLOR_PALETTE.DARK_GRAY};
  background-color: ${COLORS.BG_PAGE};
  padding: 2px 12px;
  position: relative;
  border-radius: 3px;
  margin-left: 22px;
  &:before {
    position: absolute;
    content: '';
    border-style: solid;
    border-width: 6px 6px 6px 0;
    border-color: transparent ${COLORS.BG_PAGE} transparent transparent;
    left: -6px;
    top: 6px;
    width: 0;
    height: 0;
  }
`;

const WrapperIconButton = styled.div`
  margin-left: auto;
`;

const WideButton = styled(Button)`
  border: 1px solid #dbe1ef;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 14px;
`;

const translations = {
  goals: t`Goals`,
  reviews: t`Reviews`,
  paths: t`Paths`,
  activities: t`Activities`,
  rtfeedback: t`Realtime feedback`,
};

const TalkingPointsSection = () => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const $isModal = useBoolState(false);
  const conversationId = useSelector(getId);
  const status = useSelector(getStatus);
  const participants = useSelector(getParticipants);
  const talkingPoints = useSelector(getTalkingPoints);
  const { error, isAdmin } = useContext(ConversationSetupContext);

  const isUpdate = conversationId && status !== CONVERSATION_STATUSES.DRAFT.key;
  const onAddTalkingPoints = (points) => dispatch(addTalkingPoints(points));
  const onRemoveTalkingPoint = (point) => dispatch(removeTalkingPoint(point));
  const setConversation = (result) => {
    if (!result.destination) {
      return;
    }
    dispatch(
      setTalkingPoints(reorder(talkingPoints, result.source.index, result.destination.index)),
    );
  };

  const renderTalkingPoint = (tp, showDots) => {
    return (
      <TalkingPoint key={tp.name}>
        {showDots === true && (
          <SvgIcon
            width="16px"
            height="16px"
            url={dndDots}
            defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            isDefaultColor
          />
        )}
        {tp.type === TALKING_POINT_TYPES.RTFEEDBACK.key ? (
          <StyledRickTextView html={tp.key || tp.name} />
        ) : (
          <TalkingPointName>{tp.name}</TalkingPointName>
        )}
        {tp.type !== TALKING_POINT_TYPES.CUSTOM.key &&
          tp.type !== TALKING_POINT_TYPES.TEMPLATES.key && (
            <TalkingPointLabel>{i18n._(translations[tp.type])}</TalkingPointLabel>
          )}
        <WrapperIconButton>
          <IconButton
            size={48}
            onClick={() => {
              if (
                !(isUpdate && tp.conversation) ||
                confirm(
                  i18n._(
                    t`Are you sure you want to delete this talking point? This will also delete all comments connected to this talking point`,
                  ),
                )
              ) {
                onRemoveTalkingPoint(tp);
              }
            }}
            noBorder={true}
            color="none"
            disabled={isAdmin}
          >
            <Icon
              icon={ICONS.DELETE_BIN}
              size={ICON_SIZES.LARGE}
              color={COLOR_PALETTE.GRAY_MIDDLE}
            />
          </IconButton>
        </WrapperIconButton>
      </TalkingPoint>
    );
  };

  return (
    <FieldGroup>
      <FieldTitle>
        <Trans>Talking points</Trans>
      </FieldTitle>
      <Divider />
      <Content>
        {isEmpty(talkingPoints) ? (
          <Placeholder
            Icon={ConversationIcon}
            title={i18n._(t`No talking points`)}
            subTitle={i18n._(t`Add talking points to structure the conversation`)}
            isError={error.value}
          />
        ) : isUpdate ? (
          talkingPoints.map((point) => {
            return renderTalkingPoint(point, false);
          })
        ) : (
          <DragDropContext onDragEnd={setConversation}>
            <Droppable droppableId="TP-droppable">
              {(provided) => {
                return (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {talkingPoints.map((point, index) => {
                      return (
                        <Draggable
                          key={point.name}
                          draggableId={point.name}
                          index={index}
                          isDragDisabled={talkingPoints.length < 2}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {renderTalkingPoint(point, talkingPoints.length > 1)}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        )}
        <WideButton
          label={`+ ${i18n._(t`Talking points`)}`}
          type="primary-border-wide"
          onClick={$isModal.on}
          disabled={isAdmin}
        />
      </Content>
      {$isModal.value && (
        <AddTalkingPointsModal
          onClose={$isModal.off}
          participants={participants}
          disabledItems={talkingPoints.map((tp) =>
            [TALKING_POINT_TYPES.CUSTOM.key, TALKING_POINT_TYPES.TEMPLATES.key].includes(tp.type)
              ? tp.name
              : tp.target,
          )}
          onSubmit={onAddTalkingPoints}
        />
      )}
    </FieldGroup>
  );
};

export default React.memo(TalkingPointsSection);
