import React from 'react';
import type { ReactNode } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import size from 'lodash/size';
import { Controller, useForm } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Input } from '~/components/Input';
import Modal from '~/components/Modal';

import { Actions, Content, Footer, Header, Subtitle, Title } from './design';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';

import { InputContainer, Label } from '../design';

import type { ILanguageValue, IMultiLangString } from '@learned/types';

type FocusAreaCreateModalProps = {
  onCreate: (newValues: { name: IMultiLangString }) => void;
  focusArea?: never;
  onSave?: never;
};

type FocusAreaUpdateModalProps = {
  onCreate?: never;
  onSave: (newValues: { name: IMultiLangString; _id: string }) => void;
  focusArea: { name: IMultiLangString; _id: string };
};

function FocusAreaModal({
  title,
  onSave,
  onClose,
  onCreate,
  onDelete,
  languageState,
  focusArea,
  skillName,
}: {
  title: ReactNode;
  onClose: () => void;
  onDelete: (id: string) => void;
  languageState: ILanguageStateReturn;
  skillName?: string;
} & (FocusAreaCreateModalProps | FocusAreaUpdateModalProps)) {
  const { i18n } = useLingui();
  const generalFormMethods = useForm<{ focusArea: ILanguageValue[] }>({
    mode: 'all',
    defaultValues: {
      focusArea: languageState.companyLanguages.map(({ locale }) => ({
        locale,
        value: focusArea?.name?.[locale] ?? '',
      })),
    },
  });
  const { register, unregister, control } = generalFormMethods;

  const descriptionFieldArray = useMultiLangFieldArray({
    name: 'focusArea',
    control,
    unregister,
    languageState,
  });

  return (
    <Modal
      width={486}
      contentStyles={{ padding: '30px' }}
      hideFooter
      isHideHeader
      showDivider={false}
    >
      <Header>
        <Title>
          <Trans>{title}</Trans>
        </Title>
        <Subtitle>{skillName}</Subtitle>
      </Header>
      <Content>
        <Label>
          <Trans>Focus area</Trans>
        </Label>
        <InputContainer>
          {descriptionFieldArray.fields.map((field) => (
            <Controller
              key={field.id}
              {...register(`focusArea.${field.index}.value`)}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    placeholder={i18n._(t`Focus area`)}
                    value={value}
                    onChange={onChange}
                    key={field.id}
                    width="281px"
                    leftIcon={
                      size(languageState.languages) > 1
                        ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                        : undefined
                    }
                  />
                );
              }}
            />
          ))}
        </InputContainer>
      </Content>
      <Footer>
        <Button
          label={i18n._(t`Delete`)}
          type="button"
          variant={ButtonVariant.TEXT_DELETE}
          size={ButtonSize.MEDIUM}
          onClick={() => {
            focusArea && onDelete(focusArea._id);
            onClose();
          }}
        />
        <Actions>
          <Button
            label={i18n._(t`Cancel`)}
            type="button"
            variant={ButtonVariant.TEXT_PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={onClose}
          />
          <Button
            label={i18n._(t`Save`)}
            type="button"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={() => {
              const newValues = generalFormMethods.getValues();
              const aliasValue = newValues.focusArea.reduce((acc, alias) => {
                return { ...acc, [alias.locale]: alias.value };
              }, {} as IMultiLangString & { _id: string });

              if (focusArea) {
                onSave({ name: { ...focusArea.name, ...aliasValue }, _id: focusArea._id });
              } else {
                onCreate({ name: aliasValue });
              }
              onClose();
            }}
          />
        </Actions>
      </Footer>
    </Modal>
  );
}

export { FocusAreaModal };
