import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon, ICONS } from '~/components/Icon';

import { COLORS, COLOR_PALETTE } from '~/styles';

import './styles.scss';

const InputStyled = styled.input`
  border: 1px solid ${(props) => (props.isValidated ? COLOR_PALETTE.WHITE : COLORS.ERROR)};
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  color: #70747f;
  &::placeholder {
    color: ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  }
`;

const Search = forwardRef((props, ref) => {
  const {
    placeholder,
    value,
    onChange,
    name,
    onFocus,
    onBlur,
    disabled,
    style,
    className,
    isValidated,
    noIcon,
    iconPrepend,
    defaultColor,
  } = props;

  return (
    <div className={`search ${className}`}>
      {!noIcon && iconPrepend && <Icon icon={ICONS.SEARCH} color={defaultColor} />}
      <InputStyled
        name={name}
        type="text"
        className="search_input search_input-with-icon"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        style={style}
        ref={ref}
        isValidated={isValidated}
      />
      {!noIcon && !iconPrepend && <Icon icon={ICONS.SEARCH} color={defaultColor} />}
    </div>
  );
});

Search.displayName = 'Search';

Search.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  isValidated: PropTypes.bool,
  iconPrepend: PropTypes.bool,
  defaultColor: PropTypes.string,
};

Search.defaultProps = {
  className: '',
  isValidated: true,
  onFocus: () => {},
  onBlur: () => {},
  iconPrepend: false,
  defaultColor: COLORS.BORDER,
};

export default Search;
