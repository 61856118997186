import styled from 'styled-components';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLORS.WHITE};
  color: ${COLORS.TEXT_MAIN};
  border-radius: 6px;
  font-size: 14px;
  gap: 4px;
`;

const SingleRow = styled.div`
  display: flex;
  align-items: center;
`;

const RowTitle = styled.div`
  background: ${COLORS.BG_LIST};
  padding: 8px 20px;
  flex: auto;
  font-size: 12px;
  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 35ch;
    display: block;
  }
`;

const RowValue = styled.div<{ opacity: number; gradients: string[] }>`
  padding: 8px 0;
  font-size: 12px;
  box-sizing: border-box;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  isolation: isolate;
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    opacity: ${({ opacity }) => opacity};
    background: ${({ gradients }) =>
      gradients.length > 0 ? `linear-gradient(${gradients[0]}, ${gradients[1]})` : COLORS.COMPANY};
  }
`;

export { Wrapper, SingleRow, RowTitle, RowValue };
