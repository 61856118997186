import { joiResolver } from '@hookform/resolvers/joi';
import { REVIEW_THEME_STATUS } from '@learned/constants';
import Joi from 'joi';

const ERRORS = {
  missingPrimaryLang: 'missingPrimaryLang',
  iconIsNotValid: 'iconIsNotValid',
};

const schema = Joi.object({
  name: Joi.array()
    .items(
      Joi.object({
        locale: Joi.string(),
        value: Joi.string().allow(''),
      }),
    )
    .custom((items: { locale: string; value: string }[], helpers) => {
      const companyPrimaryLanguage: string | undefined =
        helpers?.prefs?.context?.companyPrimaryLanguage;
      if (
        companyPrimaryLanguage &&
        !items
          .filter((item) => !!item.value)
          .map((item) => item.locale)
          .includes(companyPrimaryLanguage)
      ) {
        return helpers.message({ custom: ERRORS.missingPrimaryLang });
      }
      return items;
    }),
  description: Joi.array().items(
    Joi.object({
      locale: Joi.string(),
      value: Joi.string().allow(''),
    }),
  ),
  status: Joi.string().valid(...Object.values(REVIEW_THEME_STATUS)),
  icon: Joi.when(Joi.ref('status'), {
    is: REVIEW_THEME_STATUS.PUBLISHED,
    then: Joi.custom((value: string, helpers) => {
      if (!value) {
        return helpers.message({ custom: ERRORS.iconIsNotValid });
      }
      return value;
    }),
  }),
  iconColor: Joi.string().required(),
  questions: Joi.when(Joi.ref('status'), {
    is: REVIEW_THEME_STATUS.PUBLISHED,
    then: Joi.array().min(1).required(),
    otherwise: Joi.array().required(),
  }),
});

const resolver = joiResolver(schema);

export { resolver, ERRORS };
