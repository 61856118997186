import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Container = styled.div`
  width: 690px;
  display: flex;
  flex-direction: column;
`;

export const LabelContainer = styled.div`
  width: 690px;
  display: flex;
  gap: 10px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > button {
    height: 20px;
    width: 20px;

    div {
      height: 20px;
      width: 20px;
    }
  }
`;

export const Title = styled.span`
  font-size: 24px;
  letter-spacing: -0.27px;
  color: ${COLORS.TEXT_HOVER};
`;

export const Subtitle = styled.span`
  div {
    font-size: 14px;
    color: ${COLORS.LABEL_GRAY};
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding-bottom: 7px;
  margin-bottom: 20px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 20px;
`;

export const FocusAreaLabel = styled.div`
  display: flex;
  color: ${COLORS.LABEL_GRAY};
  padding: 25px 0;
`;

export const FocusAreaRatingLabelContainer = styled.div<{ $isPlaceholder?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  flex-grow: ${({ $isPlaceholder }) => ($isPlaceholder ? 0 : 1)};
`;

export const DownwardArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 11px solid ${COLORS.HOVER};
`;

export const ExpectedLevelLabel = styled.div`
  position: absolute;
  top: -20%;
  left: 0;
  left: -20%;
  height: 45px;
  border-radius: 6px;
  width: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & span {
    display: inline-block;
    text-align: center;
    padding-top: 10px;
    height: 32px;
    width: 130px;
    background-color: ${COLORS.HOVER};
    color: ${COLORS.LABEL_GRAY};
    font-size: 12px;
  }
`;

export const PlaceholderExpectedLevelLabel = styled.div`
  position: absolute;
  top: -20%;
  left: 0;
  left: -80%;
  height: 45px;
  border-radius: 6px;
  width: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & span {
    display: inline-block;
    text-align: center;
    padding-top: 10px;
    height: 32px;
    width: 130px;
    background-color: ${COLORS.HOVER};
    color: ${COLORS.LABEL_GRAY};
    font-size: 12px;
  }
`;
