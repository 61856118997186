import React, { useState } from 'react';

import { SURVEY_THEME_STATUS, SURVEY_TEMPLATE_STATUSES } from '@learned/constants';
import { Trans } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';

import DashboardHeader from '~/components/DashboardHeader';
import { PreviewQuestionModal } from '~/components/Modals/PreviewQuestionModal';
import BaseLayout from '~/layouts/BaseLayout';
import type { PopulatedSurveyTemplate } from '~/pages/SurveyTemplateUpdate/types';

import { HeaderActions } from './HeaderActions';
import { MainInfoBlock } from './MainInfoBlock';
import { QuestionsBlock } from './QuestionsBlock';
import { SubHeader } from './SubHeader';

import { useFromQuery } from '~/hooks/useFromQuery';
import { useLanguageState } from '~/hooks/useLanguageState';
import { convertQuestionOptions } from '~/utils/questions';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import { DASHBOARD_TYPE } from '../constants';
import { DashboardWrapper } from '../design';

import type { PopulatedSurveyTheme } from '../types';
import type { ISurveyQuestion, ISurveyQuestionDefaultData } from '@learned/types';
interface IThemeTemplateDashboard {
  dashboardType: DASHBOARD_TYPE;
  onPublish?: () => Promise<void>;
  showPreview?: () => void;
  onEdit: () => void;
  isLoading: boolean;
  item: PopulatedSurveyTheme | PopulatedSurveyTemplate | undefined;
  isPublishDisabled?: boolean;
}

const ThemeTemplateDashboard = ({
  dashboardType,
  onPublish,
  item,
  onEdit,
  isLoading,
  isPublishDisabled,
  showPreview,
}: IThemeTemplateDashboard) => {
  const { goBack } = useFromQuery({ includeHash: true });
  const languageState = useLanguageState(true);
  const { languages } = languageState;
  const [previewQuestion, setPreviewQuestion] = useState<ISurveyQuestion | undefined>();
  const itemName = isEmpty(item) ? (
    !isLoading ? (
      <Trans>No data</Trans>
    ) : (
      ''
    )
  ) : (
    (item as PopulatedSurveyTheme | PopulatedSurveyTemplate)?.name[languages[0].locale] || (
      <Trans>No data in selected language</Trans>
    )
  );
  return (
    <>
      <DashboardHeader
        title={itemName}
        onBack={goBack}
        height="108px"
        actions={
          <HeaderActions
            isDraft={
              item?.status === SURVEY_THEME_STATUS.DRAFT ||
              item?.status === SURVEY_TEMPLATE_STATUSES.draft
            }
            onPublish={onPublish}
            onEdit={onEdit}
            showHideForUsersHint={
              dashboardType === DASHBOARD_TYPE.THEME &&
              !isEmpty(item) &&
              item?.status !== SURVEY_THEME_STATUS.DRAFT &&
              (item as PopulatedSurveyTheme)?.isHideOnUserDashboard
            }
            isLoading={isLoading}
            languageState={languageState}
            showPreview={showPreview}
            isPublishDisabled={isPublishDisabled}
          />
        }
        subHeader={
          item?.status === SURVEY_THEME_STATUS.DRAFT && <SubHeader dashboardType={dashboardType} />
        }
      />
      <BaseLayout maxWidth="750px" loading={isLoading}>
        {!isEmpty(item) && (
          <DashboardWrapper>
            <MainInfoBlock item={item} dashboardType={dashboardType} locale={languages[0].locale} />
            <QuestionsBlock
              questions={item.questions}
              languageState={languageState}
              dashboardType={dashboardType}
              onClick={(item: ISurveyQuestion) => setPreviewQuestion(item)}
            />
          </DashboardWrapper>
        )}
        {previewQuestion && (
          <PreviewQuestionModal
            defaultValues={{
              name: turnMultiLangIntoArray(previewQuestion.name, languages),
              type: previewQuestion.type,
              options: convertQuestionOptions(previewQuestion, languages),
              settings: {
                isCommentsAllowed:
                  (previewQuestion?.data as ISurveyQuestionDefaultData)?.isCommentsAllowed ?? false,
                isCommentsObligated:
                  (previewQuestion?.data as ISurveyQuestionDefaultData)?.isCommentsObligated ??
                  false,
                isMeasurementReversed:
                  (previewQuestion?.data as ISurveyQuestionDefaultData)?.isMeasurementReversed ??
                  false,
              },
            }}
            languageState={languageState}
            onClose={() => setPreviewQuestion(undefined)}
            isPreselectedLang
          />
        )}
      </BaseLayout>
    </>
  );
};

export { ThemeTemplateDashboard };
