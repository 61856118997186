import styled from 'styled-components';

export const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 36px;
  height: 54px;
`;

export const ButtonLabel = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;
