import { SURVEY_TASK_STATUS } from '@learned/constants';

import type { ICreateServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getSurveyTask(surveyTaskId: string) {
  return cloudRequest(
    (serverRoutes.surveyTasks.getSurveyTask as ICreateServerRoute)(surveyTaskId),
    {},
  );
}

export function changeSurveyTaskStatus(surveyTaskId: string, status: SURVEY_TASK_STATUS) {
  return cloudRequest(
    (serverRoutes.surveyTasks.changeSurveyTaskStatus as ICreateServerRoute)(surveyTaskId),
    {},
    { status },
  );
}
