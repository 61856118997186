/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef } from 'react';

interface ImageWithAltProps {
  src?: string;
  alt: string;
}

const ImageWithAlt = ({ src, alt }: ImageWithAltProps = { src: '', alt: '' }) => {
  const imageRef = useRef<HTMLImageElement>();

  if (!src) {
    return <></>;
  }

  return (
    <img
      // @ts-ignore
      ref={imageRef}
      src={src}
      alt={alt}
      onError={() => {
        if (imageRef.current) {
          imageRef.current.style.marginLeft = '-40px';
          imageRef.current.style.visibility = 'visible';
        }
      }}
      onLoad={() => {
        if (imageRef.current) {
          imageRef.current.style.visibility = 'visible';
        }
      }}
    />
  );
};

export { ImageWithAlt };
