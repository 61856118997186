import React from 'react';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { COLORS } from '~/styles';

import { ListRow, RowRightBlock, RowLeftBlock, Text } from '../design';

interface ListRowPlaceholderProps {
  text: string;
  isWarning?: boolean;
}

const ListRowPlaceholder = ({ text, isWarning = true }: ListRowPlaceholderProps) => {
  return (
    <ListRow>
      <RowRightBlock>
        <Text>{text}</Text>
      </RowRightBlock>
      <RowLeftBlock>
        {isWarning && (
          <Icon icon={ICONS.WARNING_2} size={ICON_SIZES.SMALL} color={COLORS.TABLE_WARNING} />
        )}
      </RowLeftBlock>
    </ListRow>
  );
};

export { ListRowPlaceholder };
