import styled from 'styled-components';

import { COLORS } from '~/styles';

export const SubHeaderRow = styled.div<{
  marginTop?: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
`;

export const SummaryFieldWrapper = styled.div<{
  marginTop?: string;
  marginBottom?: string;
}>`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0px')};
`;

export const SummaryFieldLabel = styled.div`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.INPUT_TITLE};
`;

export const SummaryFieldValue = styled.div`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_HOVER};
`;

export const SectionTitle = styled.div<{
  marginTop?: string;
  marginBottom?: string;
}>`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${COLORS.TEXT_MAIN};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0px')};
`;

export const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

export const SubHeaderTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: -0.22px;
  color: ${COLORS.TEXT_MAIN};
`;

export const SectionHeaderWithIconRow = styled.div<{
  marginTop?: string;
  marginBottom?: string;
}>`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0px')};
  display: flex;
  gap: 8px;
  align-items: center;
`;
