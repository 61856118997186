import styled from 'styled-components';

import { Button } from '~/components/Buttons';
import { LevelHeader } from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateForm/components/design';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  border-radius: 10px;
  width: 750px;
  margin: auto;
  margin-top: 96px;
  background-color: ${COLORS.WHITE};
  padding: 31.5px 41px 61px 36px;
  box-sizing: border-box;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24.5px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0px;
  border-top: solid 1px ${COLORS.BORDERS};
  margin-top: 37px;
  margin-bottom: 27px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-size: 26px;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
`;

export const Definition = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.86;
    color: ${COLORS.TEXT_HOVER};
  }
`;

export const StyledLevelHeader = styled(LevelHeader)`
  background-color: ${COLORS.ANY_OTHER_PRODUCTS};
`;

export const StyledButton = styled(Button)`
  margin-right: 20px;
`;

export const Levels = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;

  h2 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.86;
    margin: 0;
    margin-bottom: 7px;
    color: ${COLORS.TEXT_HOVER};
  }
`;

export const NameRow = styled.div<{
  maxWidth?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 17px 14px;
  max-width: ${({ maxWidth }) => maxWidth || 'unset'};
  height: 100%;
  color: ${COLORS.TEXT_MAIN};
  font-size: 16px;
`;
