import { GOAL_TYPES, ROLES } from '@learned/constants';
import { IGoalCycle, ITeam } from '@learned/types';
import moment from 'moment';

export const dataMappingGoalCycles = (c: IGoalCycle) => ({
  ...c,
  ...(!c.fullName && {
    fullName: `${c.name} ${moment(c.startDate).format('DD-MM-YY')}|${moment(c.endDate).format(
      'DD-MM-YY',
    )}`,
  }),
});

export const getOwnersRequestParams = ({
  isAdmin,
  isCoach,
  type, // selected goal type
  team, // selected goal team
  teamsForFilter, // teams list to display in filter
}: {
  isAdmin: boolean;
  isCoach: boolean;
  type: GOAL_TYPES;
  team: ITeam;
  teamsForFilter: ITeam[] | [];
}) => {
  const settingsRole = isAdmin ? ROLES.ADMIN : isCoach ? ROLES.COACH : ROLES.USER;

  switch (type) {
    case GOAL_TYPES.BUSINESS:
    case GOAL_TYPES.PERSONAL: {
      const settingsParams = {
        [ROLES.USER]: {}, // no permission (component hidden for user role)
        [ROLES.COACH]: {
          filterTeamsItems: teamsForFilter,
          isTeamFilterDisabled: false,
          isTeamsFilterSearchAlsoCoaches: false,
          isTeamsFilterSearchAlsoMembers: true,
        },
        [ROLES.ADMIN]: {}, // admin see everybody
      };
      return settingsParams[settingsRole];
    }
    case GOAL_TYPES.TEAM: {
      const settingsParams = {
        [ROLES.USER]: {}, // no permissions
        [ROLES.COACH]: {
          preSelectedTeamsInFilter: team ? [team.id || team] : undefined,
          isTeamFilterDisabled: true,
          isTeamsFilterSearchAlsoCoaches: true,
          isTeamsFilterSearchAlsoMembers: true,
        },
        [ROLES.ADMIN]: {
          preSelectedTeamsInFilter: team ? [team.id || team] : undefined,
          isTeamFilterDisabled: true,
          isTeamsFilterSearchAlsoCoaches: true,
          isTeamsFilterSearchAlsoMembers: true,
        },
      };
      return settingsParams[settingsRole];
    }
    case GOAL_TYPES.COMPANY: {
      const settingsParams = {
        [ROLES.USER]: {}, // no permissions
        [ROLES.COACH]: {}, // no permissions
        [ROLES.ADMIN]: {}, // admin see everybody
      };
      return settingsParams[settingsRole];
    }
  }
};

export const getFromForDeletedGoals = (from: string) => {
  if (from.includes('review')) {
    return from;
  }
  if (from.includes('team') && from.split('team').length - 1 > 2) {
    const fromIndex = from.indexOf('from=');
    return from.substring(fromIndex + 5);
  } else if (from.includes('goals') && from.split('goals').length - 1 === 2) {
    // it will be redirected to goals page
    return null;
  }
  return from;
};
