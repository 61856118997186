import { createAction } from 'redux-act';

import * as companiesActions from '~/store/companies/actions';

export const finishSetTheme = createAction('finish update app theme');

export function setTheme(company) {
  return function (dispatch) {
    dispatch(finishSetTheme(company));
    dispatch(companiesActions.finishGetCompany(company));
  };
}
