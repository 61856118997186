import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { Separator } from '~/pages/Reviews/DashboardCycle/tabs/Setup/design';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
`;

interface IStepSection {
  title: string;
  onEdit?: () => void;
}

const SectionHeader = ({ title, onEdit }: IStepSection) => {
  const { i18n } = useLingui();

  return (
    <Wrapper>
      <Header>
        <Title>{title}</Title>
        {onEdit && (
          <Button
            size={ButtonSize.MEDIUM}
            icon={ICONS.EDIT_PENCIL}
            variant={ButtonVariant.TEXT_PRIMARY}
            label={i18n._(t`edit`)}
            onClick={onEdit}
          />
        )}
      </Header>
      <Separator marginBottom="12px" />
    </Wrapper>
  );
};

export { SectionHeader };
