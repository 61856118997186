// Most of the code found in this file is very similar to components/Graphs/custom/HorizontalBarChart.tsx
// Later we may spend some time to merge this two.
// this component is used in the dashboard page. we might be able to parepare the original component itself rather using a seperate one for this
import React, { Fragment, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { barLines, numberLine } from '~/components/Graphs/custom/HorizontalBarChart';
import {
  DataCtr,
  DataCtrBackground,
  DataMultiCtr,
  DataRaw,
  NumberLineBorder,
  DataMultiOuterCtr,
  DataMultiCtrBackground,
} from '~/components/Graphs/custom/HorizontalBarChart.design';
import { TTotalGraphData } from '~/components/Graphs/types';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import {
  TableCtr,
  RowTitle,
  Container,
  ItemNr,
  GraphLineContainer,
} from './HorizontalChartContainer.design';

import { COLORS } from '~/styles';
import { toFixed } from '~/utils/math';

const BAR_HEIGHT = 30;
const BAR_MAX_HEIGHT = 40;
const MAX_CHARACTERS = 16;

interface IProps {
  chartData: TTotalGraphData[];
}

const getMultiComponent = (item: TTotalGraphData, isActive: boolean) => {
  const rlcValue = item.rlc || 100;

  if (item.benchmark || item.team || item.peer || item.self) {
    return (
      <DataMultiOuterCtr mHeight={`${BAR_MAX_HEIGHT}px`}>
        {!!item?.value && (
          <DataMultiCtr barWidth={`${(100 / rlcValue) * item.value}%`}>
            <DataMultiCtrBackground isActive={isActive} bgClr={COLORS.CONFIRMATION_MODAL_INFO} />
          </DataMultiCtr>
        )}
        {!!item?.team && (
          <DataMultiCtr barWidth={`${toFixed(item.team || 0, 2)}%`}>
            <DataMultiCtrBackground isActive={isActive} bgClr={COLORS.ACCENT_WARNING} />
          </DataMultiCtr>
        )}
        {!!item?.benchmark && (
          <DataMultiCtr barWidth={`${toFixed(item.benchmark || 0, 2)}%`}>
            <DataMultiCtrBackground isActive={isActive} bgClr={COLORS.COMPANY} />
          </DataMultiCtr>
        )}
        {!!item?.self && (
          <DataMultiCtr barWidth={`${toFixed(item.self || 0, 2)}%`}>
            <DataMultiCtrBackground
              isActive={isActive}
              bgClr={COLORS.TALENT || COLORS.CONFIRMATION_MODAL_INFO}
            />
          </DataMultiCtr>
        )}
        {!!item?.peer && (
          <DataMultiCtr barWidth={`${toFixed(item.peer || 0, 2)}%`}>
            <DataMultiCtrBackground
              isActive={isActive}
              bgClr={COLORS.ACCENT_SUCCESS || COLORS.CONFIRMATION_MODAL_INFO}
            />
          </DataMultiCtr>
        )}
      </DataMultiOuterCtr>
    );
  }
  if (item.value) {
    return (
      <DataCtr
        barWidth={`${(100 / rlcValue) * item.value || 0}%`}
        mHeight={`${BAR_HEIGHT}px`}
        isActive={isActive}
        deviation={toFixed(item.deviation || 0, 2)}
      >
        <DataCtrBackground isActive={isActive} bgClr={COLORS.CAREER} />
        <ItemNr isActive={isActive}>
          {!item.rlc ? `${toFixed(item.value, 1)}%` : `${item.value}/${item.rlc}`}
        </ItemNr>
      </DataCtr>
    );
  }
};

function HorizontalChartContainer({ chartData }: IProps): JSX.Element {
  const { i18n } = useLingui();
  const [activeRow, setActiveRow] = useState<null | number>(null);

  return (
    <Container>
      <TableCtr maxCharacters={MAX_CHARACTERS}>
        <GraphLineContainer maxCharacters={MAX_CHARACTERS}>
          {barLines(chartData[0]?.rlc)}
          {numberLine(chartData[0]?.rlc)}
          <NumberLineBorder> </NumberLineBorder>
        </GraphLineContainer>
        {chartData.map((item, i) => (
          <Fragment key={`data-${i + 1}`}>
            <RowTitle
              isActive={activeRow === i}
              onMouseEnter={() => setActiveRow(i)}
              onMouseLeave={() => setActiveRow(null)}
              isClickable={!!item.id}
              onClick={() => {}}
              mHeight={Array.isArray(item.value) ? `${BAR_MAX_HEIGHT}px` : `${BAR_HEIGHT}px`}
              maxCharacters={MAX_CHARACTERS}
            >
              <Tooltip tooltip={i18n._(t`${item.name}`)} size={TOOLTIP_SIZES.BIG}>
                <span>{i18n._(t`${item.name}`)}</span>
              </Tooltip>
            </RowTitle>
            <DataRaw
              key={`data-${i + 1}`}
              onMouseEnter={() => setActiveRow(i)}
              onMouseLeave={() => setActiveRow(null)}
              isActive={activeRow === i}
              mHeight={Array.isArray(item.value) ? `${BAR_MAX_HEIGHT}px` : `${BAR_HEIGHT}px`}
            >
              {getMultiComponent(item, activeRow === i)}
            </DataRaw>
          </Fragment>
        ))}
      </TableCtr>
    </Container>
  );
}

export { HorizontalChartContainer };
