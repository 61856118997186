import React, { FunctionComponent, useState } from 'react';

import { IUserActivity } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import styled from 'styled-components';

import Section from './Section';

import useBoolState from '~/hooks/useBoolState';
import { COLORS, COLOR_PALETTE } from '~/styles';

const OpenAllButton = styled.div`
  cursor: pointer;
  color: ${COLOR_PALETTE.DARK_GRAY};
  &:hover {
    color: ${COLOR_PALETTE.BLUE_DARK};
  }
  margin-left: 3px;
`;

const Sections = styled.div``;

const TotalRow = styled.div`
  margin-left: 17px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.13px;
  color: ${COLORS.TEXT_SECONDARY};
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  margin-top: 74px;
`;

interface IPathSections {
  sections: { name: string; description: string; order: number }[];
  activities: IUserActivity[];
  activitiesProps: {
    order: number;
    isObligated: boolean;
    section: number;
    id: string;
    userActivity: IUserActivity;
  }[];
  onActivityClick: (activityId: IUserActivity['id']) => void;
  activeActivityId: string;
  isOriginalPathWithoutSubPath: boolean;
}

const PathSections: FunctionComponent<IPathSections> = ({
  sections,
  activities,
  activitiesProps,
  onActivityClick,
  activeActivityId,
  isOriginalPathWithoutSubPath,
}) => {
  const { i18n } = useLingui();
  const [openSections, setOpenSections] = useState([0]);
  const $openAll = useBoolState(sections.length === 1 ? true : false);

  const openCloseSection = (idx: number) => {
    if (openSections.includes(idx)) {
      setOpenSections(openSections.filter((s) => s !== idx));
    } else {
      setOpenSections([...openSections, idx]);
    }
  };

  const openCloseSections = () => {
    if (openSections.length === 0) {
      $openAll.on();
      const openAllSections = _.range(sections.length);
      setOpenSections(openAllSections);
    } else if (openSections.length === sections.length) {
      $openAll.off();
      setOpenSections([]);
    } else if ($openAll.value) {
      $openAll.off();
      setOpenSections([]);
    } else {
      $openAll.on();
      const openAllSections = _.range(sections.length);
      setOpenSections(openAllSections);
    }
  };

  return (
    <Wrapper>
      <TotalRow>
        {i18n._(t`${sections.length} Sections |`)}
        <OpenAllButton onClick={() => openCloseSections()}>
          {openSections.length < sections.length ? (
            <Trans>Open all</Trans>
          ) : (
            <Trans>Close all</Trans>
          )}
        </OpenAllButton>
      </TotalRow>
      <Sections>
        {sections.map((section, i: number) => (
          <Section
            isFirst={i === 0}
            isLast={sections.length - 1 === i}
            key={i}
            section={section}
            sections={sections}
            onSectionClick={() => openCloseSection(i)}
            isOpen={openSections.includes(i)}
            activities={activities}
            activitiesProps={activitiesProps}
            onActivityClick={onActivityClick}
            activeActivityId={activeActivityId}
            isOriginalPathWithoutSubPath={isOriginalPathWithoutSubPath}
          />
        ))}
      </Sections>
    </Wrapper>
  );
};

export default PathSections;
