import React from 'react';

import { SECTION_STATUS, SECTION_TYPE } from '~/components/TableOfContents/types';

import { BasicCircle } from './BasicCircle';
import { ChildCircle } from './ChildCircle';
import { ParentCircle } from './ParentCircle';

function Circle({
  status,
  sectionType,
  index,
  currentSection,
}: {
  status?: SECTION_STATUS;
  sectionType: SECTION_TYPE;
  index: number;
  currentSection: number;
}) {
  if (sectionType === SECTION_TYPE.BASIC) {
    return <BasicCircle currentSection={currentSection} status={status} index={index} />;
  }
  if (sectionType === SECTION_TYPE.CHILD) {
    return <ChildCircle status={status} />;
  }
  if (sectionType === SECTION_TYPE.PARENT) {
    return <ParentCircle currentSection={currentSection} status={status} index={index} />;
  }

  return <></>;
}

export { Circle };
