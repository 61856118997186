export enum JobTemplatesTab {
  PENDING = 'pending',
  ALL = 'all',
}

interface IPagination {
  skip: number;
  limit: number;
  index: number;
}

export interface IFilterType {
  isShow?: boolean;
  search?: string;
  sortBy?: Array<{ key: string }>;
  pagination: IPagination;
}
