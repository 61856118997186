import map from 'lodash/map';
import qs from 'qs';
import { useLocation } from 'react-router';

function useQueryURL({ keys }: { keys: string[] }) {
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  let values: Record<string, string> = {};

  map(keys, (key) => {
    values = {
      ...values,
      [key]: query[key] as string,
    };
  });

  return { values };
}

export { useQueryURL };
