import React, { useState, useEffect, useContext } from 'react';

import createAuth0Client from '@auth0/auth0-spa-js';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath } from 'react-router';

import LoadingPage from '~/pages/LoadingPage';

import { ROLES } from '~/constants';
import AUTH_ERRORS from '~/constants/authErrors';
import routes from '~/constants/routes';
import { login, logout } from '~/services/users';
import store from '~/store';
import { updateApp } from '~/store/app/actions';
import {
  initializeSelection,
  updateSelectedCompany,
  updateSelectedTeam,
  updateSelectedRole,
} from '~/store/selected/actions';
import { fetchUserData } from '~/store/users/actions';
import getCurrentPath from '~/utils/getCurrentPath';
import history from '~/utils/history';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, getCurrentPath());

export const auth0 = {};

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);

  useEffect(() => {
    const initAuth0 = async () => {
      auth0.client = await createAuth0Client(initOptions);
      setAuth0(auth0.client);

      // Handle redirect to right page
      if (window.location.search.includes('error=')) {
        // get params from url
        let href = window.location.href;
        let url = new URL(href);
        let errorCode = url.searchParams.get('error_description');

        await auth0.client.logout({
          returnTo:
            window.location.origin + routes.ERROR + `?errorCode=${encodeURIComponent(errorCode)}`,
        });
        return;
      } else if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        if (app.isLogout) {
          // app.isLogout - do not redirect on logout
          return;
        }
        const { appState } = await auth0.client.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      // Check if user is authenticated
      const isAuthenticated = await auth0.client.isAuthenticated();
      if (isAuthenticated) {
        const companyMatch = matchPath(window.location.pathname, {
          path: '/company/:company',
          strict: false,
        });
        const adminMatch = matchPath(window.location.pathname, {
          path: '/company/:company/admin',
          strict: false,
        });
        const teamMatch = matchPath(window.location.pathname, {
          path: '/company/:company/team/:teamId',
          strict: false,
        });
        // we have synced companyId and teamId in CompanyModules at that time
        await dispatch(initializeSelection());
        const match = teamMatch || companyMatch;

        let company;
        let team;
        if (match) {
          company = match.params.company;
          team = match.params.teamId;

          if (company) {
            dispatch(updateSelectedCompany(company));
          }

          if (team) {
            dispatch(updateSelectedTeam(team));
            dispatch(updateSelectedRole(ROLES.COACH));
          } else if (adminMatch) {
            dispatch(updateSelectedRole(ROLES.ADMIN));
          } else {
            dispatch(updateSelectedRole(ROLES.USER));
          }
        }
        let userCompaniesData;
        const user = await auth0.client.getUser();

        // Check if user has account
        try {
          userCompaniesData = await login();
        } catch (e) {
          let success = false;
          // Server unavailable

          if (e.response) {
            // Maintenance mode
            const maintenanceTitle = get(e, 'response.data.data.maintenanceTitle');
            const maintenanceBody = get(e, 'response.data.data.maintenanceBody');
            const isMaintenanceMode =
              e?.response?.status === 503 && e?.response?.data?.message === 'Maintenance mode';
            const isTooManyRequests = e?.response?.status === 429;

            if (isMaintenanceMode) {
              // add maintenance title and body to store
              dispatch(updateApp({ maintenanceTitle, maintenanceBody }));
              history.push(routes.MAINTENANCE_MODE);
            }

            if (isTooManyRequests) {
              history.push(routes.TOO_MANY_REQUESTS);
            }

            // No account or disabled account
            const code = get(e.response, 'data.errorCode');
            switch (code) {
              case AUTH_ERRORS.ACCOUNT_DISABLED: {
                history.push(
                  `${routes.DISABLED_ACCOUNT}${user.email ? `?email=${user.email}` : ''}`,
                );
                break;
              }
              case AUTH_ERRORS.USER_NOT_FOUND:
              case AUTH_ERRORS.NO_ACTIVE_CONNECTIONS: {
                history.push(`${routes.NO_ACCOUNT}${user.email ? `?email=${user.email}` : ''}`);
                break;
              }
              case AUTH_ERRORS.INVALID_ROLE_FOR_USER:
              case AUTH_ERRORS.NOT_COACH_OF_TEAM:
              case AUTH_ERRORS.COMPANY_NOT_FOUND:
              case AUTH_ERRORS.TEAM_NOT_FOUND: {
                // clear data
                await dispatch(initializeSelection(true));
                if (company || team) {
                  try {
                    // try again
                    userCompaniesData = await login();
                    history.push(routes.HOME);
                    success = true;
                  } catch (_e) {
                    auth0.client.logout({ returnTo: window.location.origin });
                  }
                } else {
                  auth0.client.logout({ returnTo: window.location.origin });
                }
                break;
              }
            }
          }
          if (!success) {
            setIsAuthenticated(false);
            setLoading(false);
            return;
          }
        }

        // Get user data
        await dispatch(fetchUserData({ userCompaniesData, avatarUrl: user.picture }));
        setUser(user);
        setIsAuthenticated(true);
        setLoading(false);
      } else {
        setIsAuthenticated(false);
        setLoading(false);
      }
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  const onLogout = async ({ returnTo } = {}) => {
    // we need 2 layers for sessions: auth0 and app, because auth0 token does not expire after logout.
    // https://auth0.com/docs/manage-users/sessions/session-layers

    // logout from learned session (Application Session Layer)
    await logout(); // !important to have await, otherwise request fails, because we clear default selection in the next step

    // clear data
    store.dispatch(updateApp({ isLogout: true }));
    setIsAuthenticated(false);

    // clear localstorage (Auth0 Session Layer)
    localStorage.clear();

    // clear default selection
    store.dispatch(initializeSelection(true));

    // logout from auth0 session (Auth0 Session Layer)
    auth0Client.logout({
      returnTo: returnTo || window.location.origin + routes.LOGOUT,
    });
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: onLogout,
      }}
    >
      {loading ? <LoadingPage /> : children}
    </Auth0Context.Provider>
  );
};
