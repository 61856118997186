import React, { useEffect, useState } from 'react';

import {
  ENGAGEMENT_REPORT_CHART_DIMENSIONS,
  REPORT_CHART_TYPES,
  REPORT_TYPES,
} from '@learned/constants';
import { IReview } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { CircularProgressCard } from '~/components/CircularProgressCard';
import Card, { ETypes, TBarChart } from '~/components/Reports/DashboardCharts/Card';
import { getHeatmapColors } from '~/components/TableGrid/utils';

import ResultsTable from './ResultsTable';

import { useAsyncFetch } from '~/hooks/useAsyncFetch';
import { getSettingsRole } from '~/selectors/baseGetters';
import {
  getEngagementCharts,
  IChartReportRequest,
  TEngagementReportResponse,
} from '~/services/reports';
import { getTopAndBottomNElements, getMonthRangeFromDates } from '~/utils/reports';

const ResultsTabWrapper = styled.div`
  max-width: 1000px;
  margin: 24px auto 0;
  display: flex;
  flex-direction: column;
`;

const CardsSectionWrapper = styled.div<{ $size: number }>`
  max-width: 1000px;
  margin-top: 24px;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: ${(props) => `repeat(${props.$size}, minmax(312px, 484px))`};
`;

const ResultsTabNewView = ({
  review,
  onGoToReportClick,
}: {
  review: IReview;
  reportId?: string;
  onGoToReportClick: () => void;
}) => {
  const { i18n } = useLingui();
  const params: Record<string, string | undefined> = useParams();
  const reviewId = params.reviewId as string;

  const [getChartsDataPayload, setGetChartsDataPayload] = useState<IChartReportRequest>();
  const userRole = useSelector(getSettingsRole);

  const [averageValue, setAverageValue] = useState<number>(0);
  const [top3Teams, setTop3Teams] = useState<TBarChart[]>([]);
  const [bottom3Teams, setBottom3Teams] = useState<TBarChart[]>([]);
  const [ratingLabelsCount, setRatingLabelsCount] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!review) {
      return;
    }

    const filters = {
      themes: [],
      teams: [],
      surveys: [],
      jobs: [],
      jobGroups: [],
      genders: [],
      ageGroups: [],
      educationLevels: [],
      reviews: [reviewId],
      skills: [],
      skillCategories: [],
      members: [],
    };

    const getChartsDataPayload: IChartReportRequest = {
      viewAs: userRole,
      reportType: REPORT_TYPES.PERFORMANCE,
      chartType: REPORT_CHART_TYPES.BY_ONE_DIMENSION,
      primaryDimension: ENGAGEMENT_REPORT_CHART_DIMENSIONS.TEAM,
      sorting: {
        // sort by high to low
        orderBy: 'secondary',
        order: 'desc',
      },
      filters,
      options: {
        includeCompanyAverage: true,
      },
      dateRange: getMonthRangeFromDates(
        review?.settings.startDate.toString(),
        review?.settings.endDate?.toString() || '',
      ),
    };

    setGetChartsDataPayload(getChartsDataPayload);
  }, [review, reviewId, userRole]);

  const [chartsData, isChartsDataLoading] = useAsyncFetch(
    {
      fetch: async () => {
        if (!getChartsDataPayload) {
          return;
        }
        const chartData = await getEngagementCharts(getChartsDataPayload);
        const heatmapData = chartData.data;
        return heatmapData;
      },
      initialState: {
        dimensionAverage: [],
      } as Partial<TEngagementReportResponse>,
    },
    [getChartsDataPayload],
  );

  useEffect(() => {
    if (!chartsData) {
      return;
    }

    // average row is a generated row so that it has no id
    const averageData = chartsData?.dimensionAverage?.find((dimension) => dimension.id === '');
    const [top3Teams, bottom3Teams] = getTopAndBottomNElements<TBarChart>(
      // average row should be omitted
      chartsData?.dimensionAverage
        ?.filter((item) => item.id !== '')
        .map((item) => ({
          ...item,
          title: item.name,
          value: item.value || 0,
          deviation: item.deviation || 0,
        })) || [],
      3,
    );

    setRatingLabelsCount(averageData?.rlc || undefined);
    setAverageValue(averageData?.value || 0);
    setTop3Teams(top3Teams);
    setBottom3Teams(bottom3Teams);
  }, [chartsData, chartsData?.dimensionAverage]);

  const backgroundColors = React.useMemo(
    () => getHeatmapColors(averageValue, ratingLabelsCount),
    [averageValue, ratingLabelsCount],
  );

  return (
    <ResultsTabWrapper>
      <CardsSectionWrapper $size={3}>
        <CircularProgressCard
          title="Average rating"
          color={backgroundColors[0]}
          overrideText={
            averageValue === 0
              ? '-'
              : ratingLabelsCount
              ? `${averageValue}/${ratingLabelsCount}`
              : `${averageValue}%`
          }
          progress={ratingLabelsCount ? (100 / ratingLabelsCount) * averageValue : averageValue}
          isLoading={isChartsDataLoading}
        />
        <Card
          title={i18n._(t`Top 3 teams`)}
          type={ETypes.BAR_CHART}
          data={top3Teams}
          isLoading={isChartsDataLoading}
          setBarBgColor={(item) => {
            const { value, rlc } = item;
            return getHeatmapColors(typeof value === 'string' ? parseFloat(value) : value, rlc)[0];
          }}
        />
        <Card
          title={i18n._(t`Bottom 3 teams`)}
          type={ETypes.BAR_CHART}
          data={bottom3Teams}
          isLoading={isChartsDataLoading}
          setBarBgColor={(item) => {
            const { value, rlc } = item;
            return getHeatmapColors(typeof value === 'string' ? parseFloat(value) : value, rlc)[0];
          }}
        />
      </CardsSectionWrapper>
      <ResultsTable
        viewAs={userRole}
        review={review}
        onAdvancedReportNavigation={onGoToReportClick}
      />
    </ResultsTabWrapper>
  );
};

export default ResultsTabNewView;
