import React from 'react';

import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import CloseIcon from '~/assets/ic-close-18-px.svg';

import { COLORS } from '~/styles';
import noMultyRun from '~/utils/noMultyRun';

const DEFAULT_COLOR = COLORS.BLUE_DARK;
const DEFAULT_HOVER_COLOR = COLORS.BG_PAGE;

const Wrapper = styled.button<{ $isDisabled?: boolean }>`
  background: none;
  color: ${(props) => (props.$isDisabled ? COLORS.INACTIVE : DEFAULT_COLOR)};
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 14.8px 17.4px 15.2px 16.8px;
  transition: background-color 0.1s, padding 0.3s;
  border-radius: 6px;

  &:hover:enabled {
    padding: 12.8px 15.4px 13.2px 14.8px;
    background-color: ${DEFAULT_HOVER_COLOR};
  }
`;

interface ICloseButton {
  onClick: () => void;
  isDisabled?: boolean;
}
/**
 * @deprecated since version 3.0 {@see Button}
 */
export const CloseButton = ({ onClick, isDisabled = false }: ICloseButton) => {
  const ClosePageIcon = (
    <SvgIcon
      url={CloseIcon}
      width={'16px'}
      height={'16px'}
      isDefaultColor
      defaultColor={DEFAULT_COLOR}
    />
  );

  return (
    <Wrapper
      $isDisabled={isDisabled}
      onClick={onClick === Function ? noMultyRun(onClick) : onClick}
      disabled={isDisabled}
    >
      {ClosePageIcon}
    </Wrapper>
  );
};
