import React from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import Button from '~/components/Button';
import IconMenu from '~/components/IconMenu';
import Placeholder from '~/components/Placeholder';
import RickTextView from '~/components/RickTextView';
import StringInfinite from '~/components/StringInfinite';
import SvgIcon from '~/components/SvgIcon';
import { PathActivity } from '~/pages/PathSetup/steps/PathStepContent/PathActivity';

import chevronIcon from '~/assets/drop-down-down.svg';
import LibraryIcon from '~/assets/main-menu-icons/cap.svg';
import MenuIcon from '~/assets/menu.svg';

import useBoolState from '~/hooks/useBoolState';
import { COLORS, COLOR_PALETTE } from '~/styles';

const CONTENT_PADDING = 32;

const SectionHeader = styled.div`
  max-height: 64px;
  min-height: 64px;
  border-radius: ${(props) => (props.$isOpen ? '6px 6px 0 0' : '6px')};
  background-color: ${(props) => (props.$isError ? COLORS.ERROR : COLORS.BG_PAGE)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.2s ease;
  padding-right: ${CONTENT_PADDING}px;
`;

const SectionHeaderLeft = styled.div`
  display: flex;
`;

const SectionContent = styled.div`
  margin: ${CONTENT_PADDING}px;
`;

const SectionDescription = styled.div`
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

const SectionActivitiesTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_PRIMARY_2};
  margin-top: 32px;
  margin-bottom: 10px;
`;

const SectionActivities = styled.div`
  transition: 0.2s linear;
  background-color: ${(props) => (props.$isDraggingOver ? COLORS.BG_PAGE : 'white')};
  border-radius: 6px;
  height: ${(props) => (props.$placeholder ? '149px' : 'auto')};
`;

const DragWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 34px;
  max-width: 34px;
  cursor: pointer;
`;

const HeaderInfoRow = styled.div`
  display: flex;
  max-width: 500px;
`;

const HeaderInfoOrder = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

const HeaderInfoName = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_PRIMARY_2};
  margin-right: 8px;
  display: flex;
  width: 100%;
`;

const ActivitiesCount = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_PRIMARY_2};
  margin-right: 8px;
  display: flex;
  white-space: nowrap;
  margin-left: 5px;
`;

const ExpandChevron = styled(SvgIcon)`
  transform: ${(props) => (props.rotated ? 'rotate(0deg)' : 'rotate(-90deg)')};
  transition-duration: 0.2s;
  transition-property: transform;
  grid-column: 0;
  align-self: center;
  background: ${COLOR_PALETTE.DARK_GRAY};
`;

const SettingBlock = styled.div``;

const AddActivitiesRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

// replaced sectionActivities in separate component
// to improve performance and prevent unnecessary rerender on drag&drop
class InnerSectionActivities extends React.PureComponent {
  render() {
    const { path, sectionActivities, onDeleteActivity, onClickActivity, changeActivityObligation } =
      this.props;
    return sectionActivities.map((activity) => (
      <PathActivity
        key={activity.id}
        activity={activity}
        activityProps={path.activitiesProps[activity.id]}
        onDelete={onDeleteActivity}
        onClick={onClickActivity}
        changeActivityObligation={changeActivityObligation}
      />
    ));
  }
}

const Icon = () => (
  <SvgIcon
    url={LibraryIcon}
    width={'32px'}
    height={'32px'}
    isDefaultColor
    defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
  />
);

const SectionItem = (props) => {
  const {
    i18n,
    section,
    path,
    isDragging,
    showErrors,
    onUpdate,
    onDelete,
    onDeleteActivity,
    onClickActivity,
    onCreateCustomActivity,
    changeActivityObligation,
    onAddActivity,
    ...dragHandleProps
  } = props;
  const showContent = useBoolState(!section.order);
  let sectionActivities = (path.activities || []).filter((a) => {
    const activityProps = path.activitiesProps[a.id];
    return activityProps && activityProps.section === section.order;
  });

  const menuItems = [
    {
      order: 0,
      label: i18n._(t`Update section`),
      action: () => onUpdate(section),
    },
    {
      order: 1,
      label: i18n._(t`Delete section`),
      action: () => onDelete(section),
    },
  ];

  // order
  sectionActivities = sortBy(sectionActivities, (a) => path.activitiesProps[a.id].order);

  const renderHeaderInfo = (section) => (
    <div>
      <HeaderInfoOrder>
        {i18n._(t`Section ${section.order + 1} of ${path.sections.length}`)}
      </HeaderInfoOrder>
      <HeaderInfoRow>
        <HeaderInfoName>
          <StringInfinite>{section.name}</StringInfinite>
          <ActivitiesCount>{`| ${sectionActivities.length} activities `}</ActivitiesCount>
        </HeaderInfoName>
        <ExpandChevron rotated={showContent.value} width="12px" height="7px" url={chevronIcon} />
      </HeaderInfoRow>
    </div>
  );

  return (
    <>
      <SectionHeader
        onClick={() => showContent.toggle()}
        $isError={showErrors && !showContent.value}
        $isDragging={isDragging}
        $isOpen={showContent.value}
      >
        <SectionHeaderLeft>
          <DragWrapper {...dragHandleProps}>
            <SvgIcon
              width="16px"
              height="16px"
              url={MenuIcon}
              defaultColor="var(--company-color)"
              isDefaultColor={true}
            />
          </DragWrapper>
          {renderHeaderInfo(section)}
        </SectionHeaderLeft>
        <SettingBlock>
          <IconMenu items={menuItems} />
        </SettingBlock>
      </SectionHeader>
      {showContent.value && (
        <SectionContent>
          <SectionDescription>
            <RickTextView html={section.description} />
          </SectionDescription>
          {sectionActivities.length > 0 && (
            <SectionActivitiesTitle>
              <Trans>Activities</Trans>
            </SectionActivitiesTitle>
          )}
          <div>
            {sectionActivities.length > 0 ? (
              <Droppable droppableId={String(section.order)} type="ACTIVITY">
                {(provided, snapshot) => (
                  <SectionActivities
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    $count={sectionActivities.length}
                    $isDraggingOver={snapshot.isDraggingOver}
                  >
                    <InnerSectionActivities
                      sectionActivities={sectionActivities}
                      path={path}
                      onDeleteActivity={onDeleteActivity}
                      onClickActivity={onClickActivity}
                      changeActivityObligation={changeActivityObligation}
                    />
                    {provided.placeholder}
                  </SectionActivities>
                )}
              </Droppable>
            ) : (
              <Droppable droppableId={String(section.order)} type="ACTIVITY">
                {(provided, snapshot) => (
                  <SectionActivities
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    $placeholder={true}
                    $isDraggingOver={snapshot.isDraggingOver}
                  >
                    {!snapshot.isDraggingOver && (
                      <Placeholder
                        Icon={Icon}
                        title={i18n._(t`No activities`)}
                        subTitle={i18n._(t`Add learning activities to this section`)}
                        isError={showErrors && isEmpty(sectionActivities)}
                      />
                    )}
                    {provided.placeholder}
                  </SectionActivities>
                )}
              </Droppable>
            )}
          </div>
          <AddActivitiesRow>
            <Button
              type={Button.types.primaryBorder}
              label={i18n._(t`Create custom activity`)}
              styles={{ marginRight: '24px' }}
              onClick={() => onCreateCustomActivity(section)}
            />
            <Button
              type={Button.types.primary}
              label={i18n._(t`Add activity from library`)}
              onClick={() => onAddActivity(section)}
            />
          </AddActivitiesRow>
        </SectionContent>
      )}
    </>
  );
};

export default React.memo(withI18n()(SectionItem));
