import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';
import Modal from '~/components/Modal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import SelectItems from './components/SelectItems';

import { COLORS } from '~/styles';

const FooterActions = styled.div`
  display: flex;
`;

const Title = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

const SelectModal = ({
  singleChoice,
  onModalClose = () => {},
  onSubmit,
  title,
  buttonLabel,
  items = [],
  disabled,
  emptySetPlaceholder,
  loading,
  loadingPlaceholder,
  width = 625,
  minHeight = 600,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const submitSelectedItems = async () => {
    try {
      if (!isEmpty(selectedItems)) {
        setSubmitLoading(true);
        await onSubmit(selectedItems);
        setSubmitLoading(false);
      }
      onModalClose();
    } catch (_e) {
      // stop loading if request failed
      setSubmitLoading(false);
    }
  };

  return (
    <Modal
      title={title}
      onClose={onModalClose}
      width={width}
      minWidth={width}
      minHeight={minHeight}
      contentStyles={{ background: '#fff', height: '100%', padding: '0' }}
      hideFooter={false}
      footerCenter={false}
      footerLeft={<></>}
      footerRight={
        <FooterActions>
          <Button
            styles={{
              height: '40px',
              marginLeft: '10px',
              textTransform: 'uppercase',
              padding: '0px 15px',
              fontSize: '12px',
              minWidth: '120px',
            }}
            label={buttonLabel}
            disabled={!selectedItems.length}
            type="white"
            onClick={submitSelectedItems}
            loading={submitLoading}
          />
        </FooterActions>
      }
    >
      <ShowSpinnerIfLoading loading={loading}>
        <SelectItems
          items={items}
          disabled={disabled}
          selectedItems={selectedItems}
          updateSelectedItems={setSelectedItems}
          singleChoice={singleChoice}
          emptySetPlaceholder={emptySetPlaceholder}
        />
      </ShowSpinnerIfLoading>
      {loading && loadingPlaceholder ? <Title>{loadingPlaceholder}</Title> : null}
    </Modal>
  );
};

SelectModal.propTypes = {
  singleChoice: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
  disabled: PropTypes.bool,
  emptySetPlaceholder: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

export default SelectModal;
