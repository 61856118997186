import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 * still in use, no replacement yet
 */
const ArrowButtonTallIcon = ({ size, ...props }) => (
  <InlineSvg width={size} height={size} {...props}>
    {(color) => (
      <g fill="none" fillRule="evenodd">
        <g stroke={color} strokeWidth="1.5">
          <path d="M4 8L12 16 20 8" />
        </g>
      </g>
    )}
  </InlineSvg>
);

ArrowButtonTallIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

ArrowButtonTallIcon.defaultProps = {
  size: 24,
  className: '',
};

export default ArrowButtonTallIcon;
