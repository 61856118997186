import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';

import { Separator } from '../../../design';
import { SubHeaderRow, SubHeaderTitle } from '../design';

interface SubHeaderProps {
  title: string;
  onEdit: () => void;
  marginTop?: string;
}

const SubHeader = ({ title, onEdit, marginTop }: SubHeaderProps) => {
  const { i18n } = useLingui();

  return (
    <>
      <SubHeaderRow marginTop={marginTop}>
        <SubHeaderTitle>{title}</SubHeaderTitle>
        <Button
          size={ButtonSize.MEDIUM}
          icon={ICONS.EDIT_PENCIL}
          variant={ButtonVariant.TEXT_PRIMARY}
          label={i18n._(t`edit`)}
          onClick={onEdit}
        />
      </SubHeaderRow>
      <Separator marginBottom="12px" />
    </>
  );
};

export { SubHeader };
