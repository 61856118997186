import React, { useCallback } from 'react';

import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { COLOR_SET, COLORS, COLOR_PALETTE } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  border: ${(props) =>
    props.disabled ? 'solid 1px ' + COLOR_SET.LIGHT_GREY : 'solid 1px var(--company-color)'};
  background-color: ${COLOR_PALETTE.WHITE};
  border-radius: 6px;
  overflow: hidden;
  width: ${(props) => props.width * 2}px;
`;

const Button = styled.button`
  background-color: ${(props) => (props.isActive ? 'var(--company-color)' : COLOR_PALETTE.WHITE)};
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  text-align: center;
  color: ${(props) => (props.isActive ? COLOR_PALETTE.WHITE : COLORS.TEXT_PRIMARY_2)};
  cursor: pointer;

  &:first-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  &:last-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:disabled {
    cursor: no-drop;
    background-color: ${(props) => (props.isActive ? COLOR_SET.LIGHT_GREY : COLOR_PALETTE.WHITE)};
  }
`;

const SwitchYesNo = ({ onChange, value, disabled, width, height }) => {
  const turnOn = useCallback(() => onChange(true), [onChange]);
  const turnOff = useCallback(() => onChange(false), [onChange]);

  return (
    <Wrapper width={width} disabled={disabled}>
      <Button isActive={value} onClick={turnOn} disabled={disabled} width={width} height={height}>
        <Trans>Yes</Trans>
      </Button>
      <Button isActive={!value} onClick={turnOff} disabled={disabled} width={width} height={height}>
        <Trans>No</Trans>
      </Button>
    </Wrapper>
  );
};

SwitchYesNo.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

SwitchYesNo.defaultProps = {
  onChange: () => {},
  width: 70,
  height: 40,
};

export default React.memo(withI18n()(SwitchYesNo));
