import React from 'react';

import FeedbackModal from '~/components/Modals/FeedbackModal';

import { RT_FEEDBACK_SUBTYPES, RT_FEEDBACK_TYPES } from '~/constants';
import { createRTFeedback } from '~/services/RTFeedbacks';

const RTFeedbackAskModal = ({ onClose, type, goal, userId }) => {
  const isAskFeedback = type === RT_FEEDBACK_TYPES.ASK_FEEDBACK.key;

  const sendFeedback = async ({ users, emails, message, selectedGoal }) => {
    let newRTFeedback;
    if (isAskFeedback) {
      newRTFeedback = {
        users,
        emails,
        type: RT_FEEDBACK_TYPES.ASK_FEEDBACK.key,
        description: message,
        subType: RT_FEEDBACK_SUBTYPES.GOAL.key,
      };

      if (selectedGoal) {
        newRTFeedback.name = selectedGoal.name;
        newRTFeedback.goal = selectedGoal.id;
      }
    } else {
      newRTFeedback = {
        createdFor: users[0],
        type: RT_FEEDBACK_TYPES.GIVE_FEEDBACK.key,
        feedback: message,
      };
      if (selectedGoal) {
        newRTFeedback.goal = selectedGoal.id;
      }
    }
    await createRTFeedback(newRTFeedback);
  };

  return (
    <FeedbackModal
      onClose={onClose}
      type={type}
      goal={goal}
      userId={userId}
      onSubmit={sendFeedback}
    />
  );
};

export default RTFeedbackAskModal;
