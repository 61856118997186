import React, { Dispatch, useCallback } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { debounce, size } from 'lodash';
import { Controller, UseFormReturn } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Input } from '~/components/Input';

import { Form, InputContainer, Label, StyledFooter, Title } from './design';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';

import { FIELDS } from '../../constants';
import { Errors } from '../../validations';

import type { IGeneralForm } from '../../types';

export interface IGeneralProps {
  formMethods: UseFormReturn<IGeneralForm>;
  setCurrentSection: Dispatch<number>;
  languageState: ILanguageStateReturn;
  triedToSubmit: boolean;
  autoSave: () => void;
}

const General = ({
  formMethods,
  setCurrentSection,
  languageState,
  triedToSubmit,
  autoSave,
}: IGeneralProps) => {
  const { i18n } = useLingui();
  const {
    register,
    control,
    trigger,
    unregister,
    formState: { errors },
  } = formMethods;

  // hard coded for now (faced some issues with the name array)
  const nameFieldArray = useMultiLangFieldArray({
    name: 'name',
    control,
    unregister,
    languageState,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdateTemplate = useCallback(
    debounce(() => autoSave(), 10_000),
    [],
  );

  const onSubmit = () => setCurrentSection(1);

  return (
    <Form>
      <Title>
        <Trans>General</Trans>
      </Title>
      <Label>{i18n._(t`Name`)}</Label>
      <InputContainer>
        {nameFieldArray.fields.map((field) => (
          <Controller
            key={field.id}
            {...register(`name.${field.index}.value`)}
            control={control}
            render={({ field: { onBlur, onChange, value } }) => {
              const primaryLangError =
                errors.name?.message === Errors.missingPrimaryLang &&
                field.locale === languageState.companyPrimaryLanguage.locale;
              return (
                <Input
                  error={
                    primaryLangError && triedToSubmit
                      ? i18n._(t`This field is required`)
                      : undefined
                  }
                  value={value}
                  onBlur={async () => {
                    onBlur();
                    autoSave();
                    await trigger(FIELDS.NAME);
                  }}
                  onChange={async (...args) => {
                    onChange(...args);
                    debounceUpdateTemplate();
                    await trigger(FIELDS.NAME);
                  }}
                  key={field.id}
                  placeholder={i18n._(t`Example: Annual satisfaction`)}
                  width="281px"
                  leftIcon={
                    size(languageState.languages) > 1
                      ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                      : undefined
                  }
                />
              );
            }}
          />
        ))}
      </InputContainer>
      <StyledFooter>
        <Button
          label={i18n._(t`Next`)}
          type="button"
          onClick={onSubmit}
          variant={ButtonVariant.NAVIGATION_PRIMARY}
          size={ButtonSize.MEDIUM}
        />
      </StyledFooter>
    </Form>
  );
};

export { General };
