import React from 'react';

import { useForm } from 'react-hook-form';

import type { IReviewDashboardUserForm } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/types';

import { ReviewDashboardUserForm } from './ReviewDashboardUserForm';

import { useLanguageState } from '~/hooks/useLanguageState';

const ReviewDashboardUser = () => {
  const languageState = useLanguageState();

  const formMethods = useForm<IReviewDashboardUserForm>({
    mode: 'all',
    defaultValues: {
      tasksSelf: [],
      tasksReceivedPeers: [],
      tasksReceivedCoaches: [],
      taskNominatePeers: null,
      tasksProvidedPeers: [],
      tasksProvidedCoaches: [],
      guests: [],
      coaches: [],
      signatures: [],
      settings: {},
      description: [],
      attachments: [],
      privacy: {},
      evaluators: [],
      // TODO
    },
  });

  return <ReviewDashboardUserForm formMethods={formMethods} languageState={languageState} />;
};

export { ReviewDashboardUser };
