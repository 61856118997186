import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  padding: 23px 20px 23px 20px;
  border-radius: 6px;
  background-color: ${COLORS.BG_LIST};
  gap: 32px;

  font-size: 14px;
  line-height: 1.5;
  color: ${COLORS.TEXT_MAIN};
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;

  gap: 19px;
  padding: 32px;
  border-radius: 6px;
  background-color: ${COLORS.WHITE};
  margin-bottom: 32px;
`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: -0.22px;
  color: ${COLORS.TEXT_HOVER};
`;
