import React, { useEffect, useState } from 'react';

import { IMultiLangString } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Modal from '~/components/Modal';
import RickTextView from '~/components/RickTextView';

import { Container, Footer, Header, LabelContainer, Subtitle, Title, TitleWrapper } from './design';

import { isNotNil } from '~/utils/typePredicates';

import { FocusAreaSection } from '../FocusAreaSection';
import { SkillQuestionLabelPlaceholder } from '../SkillQuestionLabelPlaceholder';
import { SkillQuestionRatingLabel } from '../SkillQuestionRatingLabel';

interface IProps {
  options: {
    label: IMultiLangString;
  }[];
  focusAreas: {
    level: number;
    values: {
      id: string;
      name: IMultiLangString;
    }[];
  }[];
  expectedLevel?: number;
  title: string;
  description: string;
  onClose: () => void;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
}

export const FocusAreaModal = ({
  title,
  description,
  expectedLevel,
  focusAreas,
  onClose,
  useMultiLangString,
  options,
}: IProps) => {
  const { i18n } = useLingui();

  const [selectedLevel, setSelectedLevel] = useState<{
    label: IMultiLangString;
    focusAreas: {
      label: IMultiLangString;
    }[];
  } | null>(null);

  useEffect(() => {
    if (isNotNil(expectedLevel)) {
      setSelectedLevel({
        label: options[expectedLevel - 1].label,
        focusAreas: focusAreas[expectedLevel - 1]?.values?.map((value) => ({ label: value.name })),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (index?: number) => {
    if (!index) {
      return;
    }
    setSelectedLevel({
      label: options[index - 1].label,
      focusAreas: focusAreas[index - 1]?.values?.map((value) => ({ label: value.name })),
    });
  };

  return (
    <Modal
      width={750}
      contentStyles={{ padding: '19px 30px 24px' }}
      hideFooter
      isHideHeader
      centerModal
      showDivider={false}
    >
      <Header>
        <TitleWrapper>
          <Title>{title}</Title>
          <Button
            type="button"
            variant={ButtonVariant.CLOSE}
            size={ButtonSize.MEDIUM}
            onClick={onClose}
          />
        </TitleWrapper>
        <Subtitle>
          <RickTextView html={description} />
        </Subtitle>
      </Header>
      <Container>
        {
          <LabelContainer>
            {options.length <= 5 ? (
              options.map((option, index: number) => {
                index += 1;

                return (
                  <SkillQuestionRatingLabel
                    isExpected={index === expectedLevel}
                    option={option}
                    key={index}
                    useMultiLangString={useMultiLangString}
                    onHover={() => onChange(index)}
                    onReset={() => onChange(expectedLevel)}
                  />
                );
              })
            ) : options.length > 5 ? (
              <>
                {options.map((_, index: number) => {
                  index += 1;

                  if (index === 1) {
                    return (
                      <SkillQuestionRatingLabel
                        isExpected={index === expectedLevel}
                        option={options[0]}
                        key={index}
                        useMultiLangString={useMultiLangString}
                        onHover={() => onChange(index)}
                        onReset={() => onChange(expectedLevel)}
                      />
                    );
                  } else if (index === options.length) {
                    return (
                      <SkillQuestionRatingLabel
                        isExpected={index === expectedLevel}
                        option={options[options.length - 1]}
                        key={index}
                        useMultiLangString={useMultiLangString}
                        onHover={() => onChange(index)}
                        onReset={() => onChange(expectedLevel)}
                      />
                    );
                  }
                  return (
                    <SkillQuestionLabelPlaceholder
                      key={index}
                      index={index}
                      // @ts-ignore
                      options={options}
                      // @ts-ignore
                      option={options[index - 1]}
                      isExpected={index === expectedLevel}
                      useMultiLangString={useMultiLangString}
                      onHover={() => onChange(index)}
                      onReset={() => onChange(expectedLevel)}
                    />
                  );
                })}
              </>
            ) : null}
          </LabelContainer>
        }
        {selectedLevel && (
          <FocusAreaSection isModal level={selectedLevel} useMultiLangString={useMultiLangString} />
        )}
      </Container>

      <Footer>
        <Button
          label={i18n._(t`Close`)}
          type="button"
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          onClick={onClose}
        />
      </Footer>
    </Modal>
  );
};
