import styled from 'styled-components';

import { SearchSelect } from '~/components/SearchSelect';
import { NoOptions } from '~/components/SearchSelect/design';

import { COLORS } from '~/styles';

export const CustomOption = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 446px;
  align-items: center;
  justify-content: space-between;
  padding: 7px 14px;
  box-sizing: border-box;
  background-color: ${COLORS.WHITE};

  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.ICONS_PRIMARY};

  :hover {
    color: ${COLORS.TEXT_HOVER};
    background-color: ${COLORS.HOVER};
  }
`;

export const ModalDescription = styled.span`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
`;

export const SearchTitle = styled.span`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_HOVER};
`;

export const StyledSearchSelect = styled(SearchSelect)`
  margin-top: 6px;
  ${NoOptions} {
    box-sizing: border-box;
    width: 446px;
    padding: 7px 14px;

    font-size: 14px;
    font-style: italic;
    line-height: 1.86;
    letter-spacing: -0.16px;
    color: ${COLORS.SUBTEXT};
  }
` as typeof SearchSelect;
