import React from 'react';

import { LIBRARY_ITEM_COLLECTIONS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Dropdown } from '~/components/Dropdown';
import { ICONS } from '~/components/Icon';
import { USER_LIBRARY_STATUSES } from '~/pages/OnboardAndLearn/tabs/PersonalLearningsTab/StatusLabel';

import type { IFilterType } from './types';

interface IFilters {
  currentFilters: IFilterType;
  changeFilters: (filters: any) => void;
  resetFilters: (filters: any) => void;
  isLearningActivities: boolean;
  refetchData: () => void;
  isAdmin: boolean;
}

const Filters = ({ currentFilters, changeFilters, resetFilters }: IFilters) => {
  const { i18n } = useLingui();

  const statusDropDownItems = Object.values(USER_LIBRARY_STATUSES).map((option) => ({
    id: option.key,
    key: option.key,
    name: option.text(i18n),
    value: option.key,
  }));

  const collectionDropDownItems = [
    {
      id: LIBRARY_ITEM_COLLECTIONS.PATHS,
      key: LIBRARY_ITEM_COLLECTIONS.PATHS,
      name: i18n._(t`Paths`),
      value: LIBRARY_ITEM_COLLECTIONS.PATHS,
    },
    {
      id: LIBRARY_ITEM_COLLECTIONS.ACTIVITIES,
      key: LIBRARY_ITEM_COLLECTIONS.ACTIVITIES,
      name: i18n._(t`Activities`),
      value: LIBRARY_ITEM_COLLECTIONS.ACTIVITIES,
    },
  ];

  return (
    <>
      <Dropdown
        placeholder={i18n._(t`All`)}
        items={collectionDropDownItems}
        selectedItems={currentFilters.collections || []}
        onChange={(selectedItems) => changeFilters({ collections: selectedItems })}
        stringifyItem={(item: any) => `${i18n._(t`${item.name}`)}`}
        isSingleSelect={false}
      />
      <Dropdown
        placeholder={i18n._(t`Status`)}
        // @ts-ignore
        items={statusDropDownItems}
        selectedItems={currentFilters.statuses || []}
        onChange={(selectedItems) => changeFilters({ statuses: selectedItems })}
        stringifyItem={(item: any) => `${i18n._(t`${item.name}`)}`}
        isSingleSelect={false}
      />
      <Button
        variant={ButtonVariant.SECONDARY}
        size={ButtonSize.MEDIUM}
        label={i18n._(t`Reset all filters`)}
        onClick={resetFilters}
        icon={ICONS.CLOSE}
      />
    </>
  );
};

export { Filters };
