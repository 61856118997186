import { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import useDebounce from '~/hooks/useDebounce';
import getLang from '~/selectors/getLang';
import { getJobTemplateNames } from '~/services/jobTemplates';

import type { IJobTemplate } from '@learned/types';

const useManageJobTemplates = () => {
  const [search, setSearch] = useState('');
  const [jobTemplates, setJobTemplates] = useState<IJobTemplate[]>([]);
  const [selectedJobTemplate, setSelectedJobTemplate] = useState<IJobTemplate>();
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSearch = useDebounce(search, 300);
  const lang = useSelector(getLang);

  const listJobTemplates = useCallback(
    async () =>
      getJobTemplateNames(
        {
          search: debouncedSearch,
        },
        {
          skip: 0,
          limit: 20,
          projection: { name: 1, id: 1 },
        },
        lang,
      ),
    [debouncedSearch, lang],
  );

  useEffect(() => {
    let mounted = true;

    async function fetch() {
      setIsLoading(true);
      const response = await listJobTemplates();
      if (!mounted && response.code !== 200) {
        return;
      }
      setJobTemplates(response.data.jobTemplates);
      setIsLoading(false);
    }

    fetch();
    return () => void (mounted = false);
  }, [listJobTemplates]);

  return {
    jobTemplates,
    selectedJobTemplate,
    setSelectedJobTemplate,
    setSearch,
    search,
    isLoading,
  };
};

export { useManageJobTemplates };
