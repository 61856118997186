import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { UserAvatar } from '~/components/UserAvatar';

import { useOnHover } from '~/hooks/useOnHover';

import { ListRow, RowRightBlock, RowLeftBlock, EvaluatorLabel } from '../design';

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

interface ListRowTaskProps {
  guestId: string;
  isCoach?: boolean;
  onDelete?: (guestId: string) => void;
}

const ListRowGuest = ({ guestId, isCoach = false, onDelete }: ListRowTaskProps) => {
  const { i18n } = useLingui();
  const { ref, isHovering } = useOnHover();

  return (
    <ListRow ref={ref} $isHovering={onDelete && isHovering}>
      <RowRightBlock>
        <UserAvatar userId={guestId} options={{ showTooltip: true }} />
        {isCoach && <EvaluatorLabel>({i18n._(t`Coach`)})</EvaluatorLabel>}
      </RowRightBlock>
      <RowLeftBlock>
        <ButtonWrapper>
          {isHovering && onDelete && (
            <Button
              label=""
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.ICON_DELETE}
              isLoading={false}
              onClick={() => onDelete(guestId)}
            />
          )}
        </ButtonWrapper>
      </RowLeftBlock>
    </ListRow>
  );
};

export { ListRowGuest };
