import React from 'react';

import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';
import AvatarCard from '~/components/UI/AvatarCard';

import { EmailRow } from './styledComponets';

import trashIcon from '~/assets/trash.svg';

import { COLOR_PALETTE } from '~/styles';

const FlexAvatarCard = styled(AvatarCard)`
  flex: 1;
`;

const UserBlock = ({ userId, isDelete, onClick }) => (
  <EmailRow>
    <FlexAvatarCard type="small" size={28} userId={userId} />
    {isDelete && (
      <SvgIcon
        width="26px"
        height="26px"
        url={trashIcon}
        isDefaultColor={true}
        defaultColor={COLOR_PALETTE.GRAY_SEMI_SOFT}
        onClick={onClick}
      />
    )}
  </EmailRow>
);

export default UserBlock;
