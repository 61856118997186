import styled from 'styled-components';

import { COLORS } from '~/styles';

export const ActionsWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 11px;
`;

export const ActionItemBlock = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  height: 20px;
  justify-content: center;
  margin-left: 8px;
`;

export const HeaderButtonWrap = styled.span`
  svg {
    color: ${COLORS.TIPPY_BACKGROUND};
  }
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
