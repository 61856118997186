import styled, { css } from 'styled-components';

import Modal, { ModalContent } from '~/components/Modal';
import Divider from '~/components/UI/Divider';

import { RatingItem } from './design';

import { COLORS } from '~/styles';

export const Comments = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  min-width: 654px;
  max-width: 654px;
  gap: 17px;
  margin-top: 17px;
  margin-bottom: 17px;
  box-sizing: border-box;
  z-index: 1;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: ${COLORS.SUBTEXT};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const StyledModal = styled(Modal)`
  width: 750px;
  box-sizing: border-box;

  padding: 20px 30px 24px;

  ${ModalContent} {
    padding: 0;
  }

  ${Divider} {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const SubTable = styled.div<{ hasScrollbar?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 3px;
  overflow: auto;
  transform: rotateX(180deg);

  &::-webkit-scrollbar {
    height: 8px;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
    background: ${COLORS.BG_ELEMENTS};
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.COMPANY};
    border-radius: 100px;
  }

  &::-webkit-scrollbar:hover {
    cursor: pointer;
  }
  > div {
    display: flex;
    ${(props) =>
      props.hasScrollbar &&
      css`
        padding-top: 3px;
      `}
    width: 100%;
    flex-direction: column;
    gap: 3px;
    transform: rotateX(180deg);
  }
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 323px;
  width: 100%;
  box-sizing: border-box;
  gap: 3px;
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 360px;
  gap: 3px;
`;

export const RightColumnHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 360px;
  height: 32px;
  border-radius: 4px;
  background-color: ${COLORS.GRAY_LIGHT_BLUE};

  gap: 5px;
  font-size: 12px;
  font-weight: 600;
  color: ${COLORS.SUBTEXT};
`;

export const TableItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
  background-color: ${COLORS.BG_LIST};
  color: ${COLORS.TEXT_MAIN};
`;

export const RowHeader = styled(TableItem)<{ isOpen?: boolean }>`
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  width: auto;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;

  :hover {
    background-color: ${COLORS.HOVER};

    ${(props) =>
      props.isOpen &&
      css`
        background-color: ${COLORS.BG_ELEMENTS};
      `}
  }

  > svg {
    min-width: 18px;
    margin-right: 20px;
  }

  .commentCount {
    display: flex;
    flex-direction: row;
    color: ${COLORS.PLACEHOLDERS};
    > svg {
      margin-left: 10px;
      margin-right: 7px;
    }
  }

  .skillName {
    white-space: nowrap;
    display: inline-block;
    flex-direction: row;
    text-overflow: ellipsis;
    align-items: center;
    overflow: hidden;
  }

  ${(props) =>
    props.isOpen &&
    css`
      background-color: ${COLORS.BG_ELEMENTS};
    `}
`;

export const RowChildHeader = styled(TableItem)<{ height?: number }>`
  justify-content: flex-start;
  padding: 10px 14px 16px;
  margin-left: 20px;
  font-size: 12px;
  height: auto;
  width: auto;
  box-sizing: border-box;

  ${(props) =>
    props.height !== undefined &&
    css`
      min-height: ${props.height}px;
      max-height: ${props.height}px;
    `}
`;

export const OverflowText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
`;

export const ColumnHeader = styled(TableItem)`
  display: flex;
  flex-direction: row;
  background-color: ${COLORS.GRAY_LIGHT_BLUE};
  color: ${COLORS.SUBTEXT};
  max-width: 118px;
  min-width: 118px;
  justify-content: center;
  gap: 5px;
  text-transform: capitalize;
  font-weight: 600;
  padding: 8px;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;

  > .roleCount {
    font-size: 12px;
    color: ${COLORS.PLACEHOLDERS};
    font-weight: 400;
  }

  > svg {
    cursor: pointer;
  }
  font-size: 12px;
`;

export const ExpandableColumnHeader = styled(TableItem)<{ hasScrollbar?: boolean }>`
  background-color: ${COLORS.GRAY_LIGHT_BLUE};
  color: ${COLORS.SUBTEXT};
  justify-content: flex-start;
  padding-left: 20px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  width: 100%;
  ${(props) =>
    props.hasScrollbar
      ? css`
          height: 78px;
        `
      : css`
          height: 67px;
        `}

  box-sizing: border-box;
`;

export const Row = styled.div<{ height?: number }>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: auto;
  gap: 3px;

  ${RatingItem} {
    min-width: 118px;
    max-width: 100%;
    width: auto;
  }

  ${(props) =>
    props.height !== undefined &&
    css`
      min-height: ${props.height}px;
      max-height: ${props.height}px;
    `}
`;

export const Table = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  gap: 3px;
`;
