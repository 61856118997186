import styled from 'styled-components';

import { COLORS } from '~/styles';

const TooltipCtr = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.BORDERS};
  border-radius: 6px;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
  padding: 15px 17px;
  width: 312px;
  height: auto;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  transition: visibility 0.4s ease-in-out;
  transform: translate(0%, -100%);
  box-sizing: border-box;
  pointer-events: none;
  user-select: none;

  div.data-row {
    display: flex;
  }

  hr {
    border: 1px solid ${COLORS.BORDERS};
    width: 100%;
    margin: 10px 0;
  }

  .tooltip-primary-title {
    color: ${COLORS.TEXT_HOVER};
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 4px;
    letter-spacing: -0.2px;
    line-height: 1.44;
  }

  .tooltip-secondary-title {
    font-size: 14px;
    line-height: 1.86;
    letter-spacing: -0.16px;
    color: ${COLORS.ACTIVITY_GREY};
  }

  .tooltip-secondary {
    color: ${COLORS.TEXT_HOVER};
    display: flex;
    gap: 8px;
    margin-top: 8px;
    box-sizing: border-box;
    justify-content: space-between;
    font-size: 14px;
  }

  .value-display {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 118px;
  }

  .hidden {
    display: none;
  }

  .color-chip {
    font-size: 10px;
    height: 14px;
    min-width: 9px;
    border-radius: 4px;
    position: relative;
  }
`;

export { TooltipCtr };
