import FileSaver from 'file-saver';
import isEmpty from 'lodash/isEmpty';

import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getActivities() {
  return cloudRequest(serverRoutes.activities.getActivities);
}

// NEW
// get custom company activities + library company activities
export function getCompanyActivities({
  includeParticipants = false,
  filters = {},
  options = {},
  recommendedForRoles = [],
  hideActivities = [],
  isRecommended = false,
} = {}) {
  return cloudRequest(serverRoutes.activities.getCompanyActivities, null, {
    includeParticipants,
    filters,
    options,
    isRecommended,
    recommendedForRoles,
    hideActivities,
  });
}

// NEW
export function getUserLibraryActivitiesWithFilter({
  includeParticipants = false,
  includeOnlyTotal = false,
  filters = {},
  options = {},
  includeEnrolled = false,
  rolesFilter = [],
  relevanciesFilter = [],
} = {}) {
  return cloudRequest(serverRoutes.activities.getUserLibraryActivitiesWithFilter, null, {
    includeParticipants,
    includeOnlyTotal,
    filters,
    options,
    includeEnrolled,
    rolesFilter,
    relevanciesFilter,
  });
}

export function getLibraryItems(
  options = { includeParticipants: false },
  {
    pagination,
    search,
    types,
    collections,
    skills,
    sources,
    show,
    sortBy,
    roles,
    rolesRelevancies,
    activityCategories,
    activitiesToHide,
  } = {},
) {
  return cloudRequest(
    serverRoutes.activities.getLibraryItems,
    { ...options },
    {
      pagination,
      ...(search && { search }),
      ...(types && !isEmpty(types) && { types }),
      ...(collections && !isEmpty(collections) && { collections }),
      ...(skills && !isEmpty(skills) && { skills }),
      ...(sources && !isEmpty(sources) && { sources }),
      ...(show && !isEmpty(show) && { show }),
      ...(sortBy && !isEmpty(sortBy) && { sortBy }),
      ...(roles && !isEmpty(roles) && { roles }),
      ...(rolesRelevancies && !isEmpty(rolesRelevancies) && { rolesRelevancies }),
      ...(activityCategories && !isEmpty(activityCategories) && { activityCategories }),
      ...(activitiesToHide && !isEmpty(activitiesToHide) && { activitiesToHide }),
    },
  );
}

export function getUserLibraryItems({ pagination, search, statuses, collections } = {}) {
  return cloudRequest(serverRoutes.activities.getPersonalLibraryItems, null, {
    filters: {
      ...(search && { search }),
      ...(!isEmpty(statuses) && { statuses }),
    },
    options: {
      ...pagination,
      ...(collections && !isEmpty(collections) && { collections }),
    },
  });
}

export function getUserLibraryActivities(
  userId,
  { pagination, search, types, skills, activityCategories } = {},
) {
  return cloudRequest(
    serverRoutes.activities.getUserLibraryActivities,
    { userId },
    {
      pagination,
      ...(search && { search }),
      ...(types && !isEmpty(types) && { types }),
      ...(skills && !isEmpty(skills) && { skills }),
      ...(activityCategories && !isEmpty(activityCategories) && { activityCategories }),
    },
  );
}

export function getActivity(id, populate = [], join = []) {
  return cloudRequest(serverRoutes.activities.getActivity(id), { populate, join });
}

export function createOriginalLibraryActivity(data) {
  return cloudRequest(serverRoutes.activities.createOriginalLibraryActivity, {}, data);
}

export function updateOriginalLibraryActivity(id, data) {
  return cloudRequest(serverRoutes.activities.updateOriginalLibraryActivity(id), {}, data);
}

export function deleteOriginalLibraryActivities(activityIds) {
  return cloudRequest(serverRoutes.activities.deleteOriginalLibraryActivities, {}, { activityIds });
}

export function removeActivityAttachment(id, fileId) {
  return cloudRequest(serverRoutes.activities.removeActivityAttachment(id), { fileId });
}

export function getFilteredActivities(ids, filters = {}) {
  return cloudRequest(serverRoutes.activities.getFilteredActivities, {}, { ids, filters });
}

export function addActivityOwners(id, newOwners) {
  return cloudRequest(serverRoutes.activities.addActivityOwners(id), {}, { newOwners });
}

export function addActivityUsers(id, usersToAdd) {
  return cloudRequest(serverRoutes.activities.addActivityUsers(id), {}, { usersToAdd });
}

export function deleteActivityUsers(id, usersToDelete) {
  return cloudRequest(serverRoutes.activities.deleteActivityUsers(id), {}, { usersToDelete });
}

export function deleteActivityOwners(id, removedOwners) {
  return cloudRequest(serverRoutes.activities.deleteActivityOwners(id), {}, { removedOwners });
}

export function copyOriginalActivity(id) {
  return cloudRequest(serverRoutes.activities.copyOriginalActivity(id));
}

export function deleteLibraryItems({ pathsIds, activitiesIds } = {}) {
  return cloudRequest(
    serverRoutes.activities.deleteLibraryItems,
    {},
    {
      ...(pathsIds && !isEmpty(pathsIds) && { pathsIds }),
      ...(activitiesIds && !isEmpty(activitiesIds) && { activitiesIds }),
    },
  );
}

// for instance custom original activity in the path
export function createOriginalCustomActivity(data) {
  return cloudRequest(serverRoutes.activities.createOriginalCustomActivity, {}, data);
}
// for learning report
export function getLearningKPIs({ startDate }) {
  return cloudRequest(serverRoutes.activities.getLearningKPIs, {}, { startDate });
}

// learning report members
export function getLearningReportMembers(filters) {
  const body = {
    filters: {
      startDate: filters.startDate,
      endDate: filters.endDate,
      search: filters.search,
      teams: filters.teams,
    },
    options: {
      skip: filters.skip,
      limit: filters.limit,
      sortBy: filters.sortBy,
    },
  };
  return cloudRequest(serverRoutes.activities.getLearningReportMembers, {}, body);
}

export function downloadLearningReportMembersCSV(filters) {
  const body = {
    filters: {
      startDate: filters.startDate,
      endDate: filters.endDate,
      search: filters.search,
      teams: filters.teams,
    },
    options: {
      skip: filters.skip,
      limit: filters.limit,
      sortBy: filters.sortBy,
    },
  };
  return cloudRequest(serverRoutes.activities.downloadLearningReportMembersCSV, {}, body).then(
    (response) => {
      FileSaver.saveAs(response, 'learning_members.csv');
    },
  );
}

export function getLearningReportTeams({ startDate, endDate, search, sortBy, skip, limit }) {
  return cloudRequest(
    serverRoutes.activities.getLearningReportTeams,
    {},
    {
      filters: {
        startDate,
        endDate,
        search,
      },
      options: {
        skip,
        sortBy,
        limit,
      },
    },
  );
}

export function downloadLearningReportTeamsCSV(filters) {
  const body = {
    filters: {
      startDate: filters.startDate,
      endDate: filters.endDate,
      search: filters.search,
    },
    options: {
      skip: filters.skip,
      limit: filters.limit,
      sortBy: filters.sortBy[0].id,
    },
  };
  return cloudRequest(serverRoutes.activities.downloadLearningReportTeamsCSV, {}, body).then(
    (response) => {
      FileSaver.saveAs(response, 'learning_teams.csv');
    },
  );
}

export function getLearningReportActivities({ startDate, endDate, search, sortBy, skip, limit }) {
  return cloudRequest(
    serverRoutes.activities.getLearningReportActivities,
    {},
    {
      filters: {
        startDate,
        endDate,
        search,
      },
      options: {
        skip,
        sortBy,
        limit,
      },
    },
  );
}

export function downloadLearningReportActivitiesCSV(filters) {
  const body = {
    filters: {
      startDate: filters.startDate,
      endDate: filters.endDate,
      search: filters.search,
    },
    options: {
      skip: filters.skip,
      limit: filters.limit,
      sortBy: filters.sortBy,
    },
  };
  return cloudRequest(serverRoutes.activities.downloadLearningReportActivitiesCSV, {}, body).then(
    (response) => {
      FileSaver.saveAs(response, 'learning_activities.csv');
    },
  );
}

export function getLearningReportPaths({ startDate, endDate, search, sortBy, skip, limit }) {
  return cloudRequest(
    serverRoutes.activities.getLearningReportPaths,
    {},
    {
      filters: {
        startDate,
        endDate,
        search,
      },
      options: {
        skip,
        sortBy,
        limit,
      },
    },
  );
}

export function downloadLearningReportPathsCSV(filters) {
  const body = {
    filters: {
      startDate: filters.startDate,
      endDate: filters.endDate,
      search: filters.search,
    },
    options: {
      skip: filters.skip,
      limit: filters.limit,
      sortBy: filters.sortBy,
    },
  };
  return cloudRequest(serverRoutes.activities.downloadLearningReportPathsCSV, {}, body).then(
    (response) => {
      FileSaver.saveAs(response, 'learning_paths.csv');
    },
  );
}
