import React, { useCallback } from 'react';

import { Trans } from '@lingui/macro';
import styled, { css } from 'styled-components';

import { COLOR_SET, COLORS } from '~/styles';

const Wrapper = styled.div<{ disabled: boolean; width: number }>`
  display: flex;
  background-color: ${COLORS.WHITE};
  border-radius: 6px;
  overflow: hidden;
  width: ${(props) => props.width * 2}px;
  border: 1px solid color-mix(in srgb, ${COLORS.BORDER_GRAY}, transparent 75%);
`;

const Button = styled.button<{ isActive: boolean; width: number; height: number }>`
  background-color: ${(props) =>
    props.isActive ? `color-mix(in srgb, ${COLORS.COMPANY}, transparent 85%);` : COLORS.WHITE};
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  text-align: center;
  color: ${(props) => (props.isActive ? COLORS.COMPANY : COLORS.BORDER_GRAY)};
  cursor: pointer;
  ${(props) =>
    props.isActive &&
    css`
      border: 1px solid ${COLORS.COMPANY};
    `};
  border-radius: 6px;

  &:disabled {
    cursor: no-drop;
    background-color: ${(props) => (props.isActive ? COLOR_SET.LIGHT_GREY : COLORS.WHITE)};
  }
`;

interface IProps {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled: boolean;
  width?: number;
  height?: number;
}

const Switch = ({ onChange, value, disabled, width = 70, height = 40 }: IProps) => {
  const turnOn = useCallback(() => onChange(true), [onChange]);
  const turnOff = useCallback(() => onChange(false), [onChange]);

  return (
    <Wrapper width={width} disabled={disabled}>
      <Button isActive={!value} onClick={turnOff} disabled={disabled} width={width} height={height}>
        <Trans>No</Trans>
      </Button>
      <Button isActive={value} onClick={turnOn} disabled={disabled} width={width} height={height}>
        <Trans>Yes</Trans>
      </Button>
    </Wrapper>
  );
};

export { Switch };
