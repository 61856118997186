import React, { useState, useEffect } from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ButtonVariant } from '~/components/Buttons';
import { Icon, ICONS, ICON_SIZES } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { TableList } from '~/components/TableList';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import BaseLayout from '~/layouts/BaseLayout';

import { AddEditIndustryModal } from './components/AddEditIndustryModal';
import { Header, Wrapper } from './design';
import { INDUSTRIES_COLUMNS } from './SuperAdminIndustries.columns';

import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import {
  listIndustries,
  deleteIndustries,
  insertIndustry,
  updateIndustry,
} from '~/services/industries';

import type { IIndustry, INewIndustry } from '@learned/types';

const SuperAdminIndustries = () => {
  const { i18n } = useLingui();

  const { pagination, changePagination } = usePagination(20);
  const [total, setTotal] = useState<number>();
  const [industries, setIndustries] = useState<IIndustry[]>([]);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<Record<string, number>>({ 'meta.createdDate': -1 });
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [forceFetch, setForceFetch] = useState(true);
  const debouncedSearch = useDebounce(search, 500);
  const { addToast } = useToasts();
  const $isAddModalVisible = useBoolState(false);
  const $isUpdateModalVisible = useBoolState(false);
  const [industryToUpdate, setIndustryToUpdate] = useState<IIndustry>();

  useEffect(() => {
    async function fetch() {
      const sortOption = typeof sortBy === 'string' ? JSON.parse(sortBy) : sortBy;
      const response = await listIndustries(
        { search: debouncedSearch },
        { skip: pagination.skip, limit: pagination.limit, sort: sortOption },
      );
      if (response?.code === 200) {
        setIndustries(response?.data?.industries ?? []);
        setTotal(response?.data?.total ?? 0);
      }
    }

    fetch();
  }, [debouncedSearch, forceFetch, pagination.limit, pagination.skip, sortBy]);

  const onDelete = async (ids: string[]) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Are you sure want to delete industry(ies)`),
      description: i18n._(t`Deleting industry(ies) cannot be undone!`),
    });
    if (isConfirmed) {
      const response = await deleteIndustries(ids);

      if (response?.code === 200) {
        // confirm toast
        addToast({
          title: i18n._(t`Industry(ies) deleted!`),
          subtitle: i18n._(t`Your industry(ies) has(have) been deleted!`),
          type: TOAST_TYPES.INFO,
        });

        setForceFetch(!forceFetch);

        // uncheck selectedItems
        if (selectedItems.length > 0) {
          setSelectedItems([]);
        }
      }
    }
  };

  const isAllSelected = industries.every((industry) => {
    return selectedItems.includes(industry.id);
  });

  const onSelectAll = () => {
    const itemsToSelect = isAllSelected ? [] : industries.map((industry) => industry.id);
    setSelectedItems(itemsToSelect);
  };

  const onSelectItem = (selectedIndustryId: string) => {
    const isSelected = selectedItems.includes(selectedIndustryId);
    setSelectedItems(
      isSelected
        ? selectedItems.filter((id) => id !== selectedIndustryId)
        : [...selectedItems, selectedIndustryId],
    );
  };

  const multiSelect = {
    checkedCount: selectedItems.length,
    onCheckAll: onSelectAll,
    onSelectItem: (industry: IIndustry) => onSelectItem(industry.id),
    isItemChecked: (industry: IIndustry) => selectedItems.includes(industry.id),
    isAllChecked: isAllSelected,
    onDelete: () => onDelete(selectedItems),
  };

  const handleSetSortBy = (value: string) => {
    setForceFetch(!forceFetch);
    setSortBy(JSON.parse(value));
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handlePagination = (pagination: { skip: number; limit: number; index: number }) => {
    setForceFetch(!forceFetch);
    return changePagination(pagination);
  };

  const createMenuItems = (item: IIndustry) => {
    return [
      {
        label: i18n._(t`Edit`),
        action: () => onUpdate(item),
        icon: ICONS.EDIT_PENCIL,
      },
      {
        label: i18n._(t`Delete`),
        action: () => onDelete([item.id]),
        icon: ICONS.DELETE_BIN,
        isWarning: true,
      },
    ];
  };

  const submitIndustry = async (formData: INewIndustry) => {
    const response = await insertIndustry(formData);

    if (response?.code === 200) {
      // confirm toast
      $isAddModalVisible.off();
      addToast({
        title: i18n._(t`Industry added!`),
        subtitle: i18n._(t`Your industry has been added!`),
        type: TOAST_TYPES.SUCCESS,
      });

      setForceFetch(!forceFetch);
    }
  };

  const onUpdate = (item: IIndustry) => {
    setIndustryToUpdate(item);
    $isUpdateModalVisible.on();
  };

  const submitUpdateIndustry = async (formData: INewIndustry) => {
    const body = { ...industryToUpdate, name: formData.name };
    const response = await updateIndustry(body as IIndustry);

    if (response?.code === 200) {
      // confirm toast
      $isUpdateModalVisible.off();
      addToast({
        title: i18n._(t`Industry updated!`),
        subtitle: i18n._(t`Your industry has been updated!`),
        type: TOAST_TYPES.SUCCESS,
      });

      setForceFetch(!forceFetch);
    }
  };

  return (
    <>
      <Header title={i18n._(t`Industries`)}>
        <Icon icon={ICONS.SUPERADMIN} size={ICON_SIZES.MEDIUM} />
      </Header>
      <BaseLayout>
        <Wrapper>
          <TableList
            data={industries}
            columns={INDUSTRIES_COLUMNS}
            filtersProps={{ isFiltered: true, filters: { search, setSearch: handleSearch } }}
            paginationProps={{
              pagination,
              changePagination: handlePagination,
              totalCount: total,
              paginationItemLabel: i18n._(t`Industries`),
            }}
            sortProps={{ sortBy: JSON.stringify(sortBy), setSortBy: handleSetSortBy }}
            actionButton={{
              label: i18n._(t`New industry`),
              onClick: $isAddModalVisible.on,
              variant: ButtonVariant.PRIMARY,
              icon: ICONS.ADD_PLUS,
            }}
            multiSelectProps={{
              isMultiSelectVisible: true,
              isSelectedCountVisible: true,
              multiSelect,
              isSelectAllVisible: true,
            }}
            menuProps={{ isMenuVisible: true, createMenuItems }}
          />
        </Wrapper>
      </BaseLayout>

      {$isAddModalVisible.value && (
        <AddEditIndustryModal
          onClose={$isAddModalVisible.off}
          title={i18n._(t`Create an industry`)}
          subTitle={i18n._(t`Super admin`)}
          action={submitIndustry}
          actionTitle={i18n._(t`Save`)}
        />
      )}

      {$isUpdateModalVisible.value && (
        <AddEditIndustryModal
          onClose={$isUpdateModalVisible.off}
          title={i18n._(t`Edit industry`)}
          subTitle={i18n._(t`Super admin`)}
          action={submitUpdateIndustry}
          actionTitle={i18n._(t`Save`)}
          data={industryToUpdate}
        />
      )}
    </>
  );
};

export { SuperAdminIndustries };
