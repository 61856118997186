import React from 'react';

import styled from 'styled-components';

import Modal from '~/components/Modal';
import Spinner from '~/components/Spinner';

import { COLOR_SET } from '~/styles';

const NameWrapper = styled.div`
  display: flex;
  text-align: center;
  font-size: 16px;
  color: ${COLOR_SET.MIDDLE_GRAY_BLUE};
  align-self: center;
`;

const Content = styled.div`
  display: flex;
  padding: 40px;
  flex-direction: column;
`;

const SpinnerWrapper = styled.div`
  padding: 40px;
`;

function LoadingModal({ title }: { title: string }) {
  return (
    <Modal centerModal width={'auto'} minWidth={0} isHideHeader hideFooter showDivider={false}>
      <Content>
        <SpinnerWrapper>
          <Spinner height={'auto'} loading={true} />
        </SpinnerWrapper>
        <NameWrapper>{title}</NameWrapper>
      </Content>
    </Modal>
  );
}

export { LoadingModal };
