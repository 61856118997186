import React, { ReactNode, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { ButtonPrimary, ButtonWhite } from '~/components/Button';
import Modal from '~/components/Modal';
import { EditableQuestion } from '~/components/Question/EditableQuestion';

import type { IQuestion, IQuestionForm } from '~/@types/question';
import { INSTRUCTIONS } from '~/constants/instructions';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { COLORS } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';

import { PreviewQuestionModal } from '../PreviewQuestionModal';

interface IQuestionModelProps {
  onClose?: () => void;
  onSubmit?: (data: IQuestionForm) => void;
  onDelete?: () => void;
  onDuplicate?: (data: IQuestionForm) => void;
  cancelButton?: ReactNode;
  submitButton?: ReactNode;
  defaultValues?: IQuestion;
  languageState: ILanguageStateReturn;
  isEditMode?: boolean;
  selectTheme?: boolean;
}

const Wrapper = styled.form`
  justify-content: center;
  border-radius: 6px;
  overflow: hidden;
  padding: 32px 80px;
`;

const Title = styled.h2`
  font-size: 26px;
  color: ${COLORS.BLACK};
  font-weight: normal;
  margin-top: 0;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;

  & .section {
    display: flex;
    gap: 20px;
  }
`;

const PreviewButton = styled.button`
  font-size: 12px;
  height: 32px;
  border-radius: 100px;
  min-width: 83px;
  text-align: center;
  border: none;
  cursor: pointer;
  color: var(--company-color);
  &:disabled {
    color: ${COLORS.INACTIVE_HARD};
  }
`;

const NeedHelp = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  height: 32px;
  border-radius: 100px;
  min-width: 83px;
  text-align: center;
  border: none;
  cursor: pointer;
  color: var(--company-color);
`;

const CancelButton = styled(ButtonWhite)`
  font-size: 12px;
  height: 32px;
  border-radius: 100px;
  min-width: 83px;
  text-align: center;
  border: none;
`;

const SubmitButton = styled(ButtonPrimary)`
  font-size: 12px;
  height: 32px;
  border-radius: 100px;
  min-width: 83px;
  text-align: center;
`;

const QuestionModal = ({
  languageState,
  onClose,
  onSubmit,
  defaultValues,
  onDelete,
  onDuplicate,
  isEditMode,
  selectTheme,
}: IQuestionModelProps) => {
  const { i18n } = useLingui();

  const formMethods = useForm<IQuestionForm>({
    mode: 'all',
    defaultValues: defaultValues || {
      name: languageState.companyLanguages.map(({ locale }) => ({ locale, value: '' })),
      settings: {
        isCommentsAllowed: false,
        isCommentsObligated: false,
        isMeasurementReversed: false,
      },
    },
  });
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);

  const {
    handleSubmit,
    formState: { isValid },
    getValues,
  } = formMethods;

  const closeModal = () => {
    onClose?.();
  };

  const submitModal = (data: IQuestionForm) => {
    onSubmit?.(data);
    onClose?.();
  };

  const openPreviewQuestion = () => {
    setIsPreviewModalVisible(true);
  };

  return (
    <Modal
      isHideHeader
      hideFooter
      onClose={closeModal}
      showDivider={false}
      centerModal
      width={750}
      borderRadius={10}
      contentStyles={{ padding: '0', overflow: 'hidden' }}
    >
      <Wrapper onSubmit={handleSubmit(submitModal)}>
        <Title>{isEditMode ? i18n._(t`Edit question`) : i18n._(t`Create a question`)}</Title>
        <EditableQuestion
          languageState={languageState}
          formMethods={formMethods}
          onDelete={onDelete}
          onDuplicate={onDuplicate ? () => onDuplicate(getValues()) : undefined}
          isEditMode={isEditMode}
          selectTheme={selectTheme}
        />
        <Footer>
          <div className="section">
            <PreviewButton type="button" onClick={openPreviewQuestion} disabled={!isValid}>
              {i18n._(t`Preview question`)}
            </PreviewButton>
            <NeedHelp>
              <a
                href={
                  getInstructionUrl(INSTRUCTIONS.HOW_TO_CREATE_SURVEY_THEME) as string | undefined
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {i18n._(t`Need help?`)}
              </a>
            </NeedHelp>
          </div>
          <div className="section">
            <CancelButton type="button" onClick={closeModal}>
              {i18n._(t`Close`)}
            </CancelButton>
            <SubmitButton type="submit" className="submitButton" disabled={!isValid}>
              {i18n._(t`Save`)}
            </SubmitButton>
          </div>
        </Footer>
      </Wrapper>
      {isPreviewModalVisible && (
        <PreviewQuestionModal
          defaultValues={getValues()}
          languageState={languageState}
          onClose={() => setIsPreviewModalVisible(false)}
        />
      )}
    </Modal>
  );
};

export { QuestionModal };
