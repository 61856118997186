import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';

interface IUploadContentWrapperProps {
  label: string;
  icon?: ICONS;
  handleOnClick: () => void;
  description: string;
}

const UploadContentWrapper = ({
  label,
  icon,
  description,
  handleOnClick,
}: IUploadContentWrapperProps) => {
  const { i18n } = useLingui();

  return (
    <>
      <Button
        type="button"
        size={ButtonSize.MEDIUM}
        variant={ButtonVariant.SECONDARY}
        label={i18n._(t`${label}`)}
        icon={icon}
        className="uploadButton"
        onClick={handleOnClick}
      />
      <span className="placeholder">
        <Trans>{description}</Trans>
      </span>
    </>
  );
};

export default UploadContentWrapper;
