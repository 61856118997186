import styled from 'styled-components';

import { COLORS } from '~/styles';

const HeaderItem = styled.div<{ rowSpan: number; colSpan: number }>`
  text-transform: uppercase;
  grid-row: span ${({ rowSpan }) => rowSpan};
  grid-column: span ${({ colSpan }) => colSpan};
  padding: 10px 20px;
  box-sizing: border-box;
  background: ${COLORS.BG_PAGE};
  border-radius: 6px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: none;
  font-size: 12px;
  .context-menu {
    display: flex;
    opacity: 0;
  }
  &:hover .context-menu {
    opacity: 1;
  }
`;

const EmptyHeaderItem = styled.div<{
  rowSpan: number;
  colSpan: number;
  isHeader: boolean;
}>`
  grid-row: span ${({ rowSpan }) => rowSpan};
  grid-column: span ${({ colSpan }) => colSpan};
  background: ${({ isHeader }) => (isHeader ? COLORS.BG_PAGE : COLORS.BG_LIST)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: ${({ isHeader }) => (isHeader ? 'pointer' : 'default')};
  border-radius: 6px;
`;

const AddInitialSkillFamily = styled.div<{ rowSpan: number; colSpan: number }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  grid-row: span ${({ rowSpan }) => rowSpan};
  grid-column: span ${({ colSpan }) => colSpan};
  padding: 10px;
  box-sizing: border-box;
  background: ${COLORS.BG_PAGE};
  border-radius: 6px;
  cursor: pointer;
  gap: 8px;
  .add_skill_icon {
    color: ${COLORS.SUBTEXT};
  }
`;

const HeaderItemInner = styled.div`
  cursor: pointer;
  inline-size: 16ch;
  overflow-wrap: break-word;
  word-break: break-word;
`;

const SortingContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  .up {
    transform: rotate(180deg);
    color: ${({ isSelected }) => (isSelected ? COLORS.COMPANY : COLORS.TEXT_HOVER)};
  }
  .down {
    color: ${({ isSelected }) => (isSelected ? COLORS.TEXT_HOVER : COLORS.COMPANY)};
  }
`;

const ExtendedHeader = styled.div<{ colSpan: number; rowSpan: number }>`
  grid-row: span ${({ rowSpan }) => rowSpan};
  grid-column: span ${({ colSpan }) => colSpan};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  background: ${COLORS.COMPANY};
  color: ${COLORS.WHITE};
  border-radius: 6px;
`;

const TableGridContainer = styled.div<{
  rowSpan: number;
  colSpan: number;
  calculatedWidth: string;
}>`
  display: grid;
  gap: 4px;
  grid-template-columns: repeat(${({ colSpan }) => colSpan}, auto);
  grid-template-rows: repeat(${({ rowSpan }) => rowSpan}, auto);
  max-width: ${({ calculatedWidth }) => calculatedWidth};
  font-size: 14px;
`;

const TableGridLeft = styled.div<{ rowSpan: number; colSpan: number }>`
  grid-row: span ${({ rowSpan }) => rowSpan};
  grid-column: span ${({ colSpan }) => colSpan};
  min-width: 300px;
  display: grid;
  grid-template-columns: repeat(${({ colSpan }) => colSpan}, auto);
  grid-template-rows: subgrid;
  box-sizing: border-box;
  gap: 4px;
  overflow-x: scroll;
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 8px;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
    background: ${COLORS.BG_ELEMENTS};
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.COMPANY};
    border-radius: 100px;
  }

  &::-webkit-scrollbar:hover {
    cursor: pointer;
  }
`;

const TableGridRight = styled.div<{ rowSpan: number; colSpan: number; isEditMode: boolean }>`
  grid-row: span ${({ rowSpan }) => rowSpan};
  grid-column: span ${({ colSpan }) => colSpan};
  display: grid;
  grid-template-columns: repeat(
    ${({ colSpan }) => colSpan},
    minmax(${({ isEditMode }) => (isEditMode ? '250px' : '220px')}, 1fr)
  );
  grid-template-rows: subgrid;
  box-sizing: border-box;
  gap: 4px;
  overflow-x: scroll;
  overflow-y: auto;
  position: relative;
  &::-webkit-scrollbar {
    height: 8px;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
    background: ${COLORS.BG_ELEMENTS};
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.COMPANY};
    border-radius: 100px;
  }

  &::-webkit-scrollbar:hover {
    cursor: pointer;
  }
`;

const FirstCell = styled.div<{
  bColor?: string;
  rowSpan: number;
  colSpan: number;
  colorInRgb?: string;
  isEditMode?: boolean;
}>`
  grid-column: span ${({ colSpan }) => colSpan};
  grid-row: span ${({ rowSpan }) => rowSpan};
  background: ${COLORS.BG_LIST};
  color: ${COLORS.TEXT_HOVER};
  display: flex;
  box-sizing: border-box;
  padding: ${({ isEditMode }) => (isEditMode ? '8px 0' : '8px 20px')};
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: 6px;
  height: 100%;
  max-width: 250px;
  min-height: 56px;
  position: relative;
  .plus_icon_rotate {
    transform: rotate(90deg);
  }
  .context-menu {
    display: flex;
    opacity: 0;
  }
  &:hover .context-menu {
    opacity: 1;
  }
  .context-menu-active {
    opacity: 1;
  }
`;

const SecondCell = styled.div<{
  bColor?: string;
  rowHeight: number;
  isEditMode?: boolean;
}>`
  grid-column: 2 / span 1;
  grid-row: span ${({ rowHeight }) => rowHeight};
  background: ${COLORS.BG_LIST};
  color: ${COLORS.SUBTEXT};
  height: 100%;
  display: flex;
  box-sizing: border-box;
  padding: ${({ isEditMode }) => (isEditMode ? '2px 0' : '2px 20px')};
  align-items: center;
  border-radius: 6px;
  max-width: 200px;
  position: relative;
  min-height: 56px;
  .context-menu {
    display: flex;
    opacity: 0;
  }
  &:hover .context-menu {
    opacity: 1;
  }
`;

const DraggableBox = styled.div<{ isEditMode: boolean }>`
  display: flex;
  align-items: center;
  text-align: center;
  padding: ${({ isEditMode }) => (isEditMode ? '16px 0px 16px 20px' : '16px 20px')};
  gap: 8px;
  background: ${COLORS.BG_LIST};
  border-radius: 6px;
  cursor: pointer;
  box-sizing: border-box;
  justify-content: space-between;
  min-width: ${({ isEditMode }) => (isEditMode ? '250px' : '220px')};
  border: 1px solid ${({ isEditMode }) => (isEditMode ? COLORS.INACTIVE : 'transparent')};
  position: relative;
  &.draggable-box {
    width: 100%;
  }
  .context-menu {
    display: flex;
    opacity: 0;
  }
  &:hover .context-menu {
    opacity: 1;
  }
  .context-menu-active {
    opacity: 1;
  }
`;

const CenterItem = styled.div`
  flex: auto;
  inline-size: auto;
  text-align: left;
  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  inline-size: 30ch;
  overflow-wrap: break-word;
  min-height: 44px;
  box-sizing: border-box;
  color: ${COLORS.ICONS_PRIMARY};
  p {
    margin: 0;
    padding: 0;
  }
`;

const TableFullHeader = styled.div``;

const Unassigned = styled.div`
  background-color: ${COLORS.ICONS_SECONDARY};
  border-radius: 6px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.WHITE};
  position: relative;
`;

const UnassignEnd = styled.div`
  flex: none;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  gap: 10px;
`;

const UnassignMiddle = styled.div`
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SkillTitlesContainer = styled.div<{ isDraggingOver: boolean }>`
  display: flex;
  padding: 4px 0 10px;
  gap: 8px;
  background-color: ${({ isDraggingOver }) => (isDraggingOver ? `${COLORS.HOVER}` : 'transparent')};
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 8px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 8px;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
    background: ${COLORS.BG_ELEMENTS};
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.COMPANY};
    border-radius: 100px;
  }

  &::-webkit-scrollbar:hover {
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  min-width: 20px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  .plus_icon_rotate {
    transform: rotate(180deg);
  }
`;

const EmptyHolder = styled.div<{ rowSpan: number; colSpan: number }>`
  background: ${COLORS.BG_LIST};
  color: ${COLORS.SUBTEXT};
  grid-row: span ${({ rowSpan }) => rowSpan};
  grid-column: span ${({ colSpan }) => colSpan};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FirstCellInner = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const SpaceDivider = styled.div<{ heightVal: string; widthVal: string }>`
  width: ${({ widthVal }) => widthVal};
  height: ${({ widthVal }) => widthVal};
  flex: none;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LoaderContainer = styled.div`
  min-height: 22px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const EmptyPlaceHolder = styled.div`
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 2px dashed ${COLORS.ACTIVITY_BORDER};
  display: none;
  background: ${COLORS.HOVER};
  border-radius: 6px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

const AddSkillSquare = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    box-sizing: border-box;
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 24px;
    border: 2px solid ${COLORS.ICONS_SECONDARY};
    border-radius: 6px;
  }
  .plus_icon {
    cursor: pointer;
    color: ${COLORS.PLACEHOLDERS};
  }
  &:hover {
    .plus_icon {
      color: ${COLORS.COMPANY};
    }
  }
`;

const OtherCell = styled.div<{ isDraggingOver: boolean; isEmpty: boolean }>`
  grid-column: span 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: ${({ isDraggingOver }) => (isDraggingOver ? `${COLORS.HOVER}` : 'transparent')};
  position: relative;
  box-sizing: border-box;
  &:hover {
    ${EmptyPlaceHolder} {
      display: ${({ isEmpty }) => (isEmpty ? 'flex' : 'none')};
    }
  }
`;

const SkillGroupPlaceholder = styled.div<{ rowSpan: number; colSpan: number }>`
  grid-row: span ${({ rowSpan }) => rowSpan};
  grid-column: span ${({ colSpan }) => colSpan};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  padding: 10px;
  box-sizing: border-box;
  background: ${COLORS.BG_PAGE};
  border-radius: 6px;
  cursor: pointer;
  gap: 8px;
  .add_skill_icon {
    color: ${COLORS.SUBTEXT};
  }
  min-height: 200px;
`;

export {
  TableGridLeft,
  TableGridRight,
  TableGridContainer,
  FirstCell,
  SecondCell,
  OtherCell,
  DraggableBox,
  ExtendedHeader,
  HeaderItem,
  TableFullHeader,
  Unassigned,
  SkillTitlesContainer,
  IconContainer,
  EmptyHolder,
  UnassignEnd,
  UnassignMiddle,
  HeaderItemInner,
  FirstCellInner,
  SpaceDivider,
  IconWrapper,
  SortingContainer,
  LoaderContainer,
  AddInitialSkillFamily,
  CenterItem,
  EmptyPlaceHolder,
  AddSkillSquare,
  EmptyHeaderItem,
  SkillGroupPlaceholder,
};
