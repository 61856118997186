import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 * This is not a chevron icon but an arrow up
 * Still in use, no replacement
 */
const ChevronIcon = ({ size, ...props }) => (
  <InlineSvg height={size} width={size} {...props} childrenFirst viewBox="0 0 9 12">
    <g fill="none" fillRule="evenodd">
      <path stroke="currentColor" strokeLinecap="square" d="M4.5 11.5v-10" />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.018 4.08 4.5 1.06 1.048 4.08"
      />
    </g>
  </InlineSvg>
);

ChevronIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

ChevronIcon.defaultProps = {
  size: 12,
  className: '',
};

export default ChevronIcon;
