import {
  SURVEY_RESULTS_MODE,
  SURVEY_SORT_OPTIONS,
  SURVEY_TASK_STATUS,
  SURVEY_TYPE,
} from '@learned/constants';
import FileSaver from 'file-saver';
import isEmpty from 'lodash/isEmpty';

import type { IServerRoute, ICreateServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

// TODO: add types for filters statuses and types
export function getUserSurveys(
  filters: { search?: string; statuses?: SURVEY_TASK_STATUS[]; types?: SURVEY_TYPE[] },
  options: { skip: number; limit: number; sortBy: SURVEY_SORT_OPTIONS },
) {
  const body = {
    filters: {
      search: filters.search,
      ...(!isEmpty(filters.statuses) && { statuses: filters.statuses }),
      ...(!isEmpty(filters.types) && { types: filters.types }),
    },
    options: {
      skip: options.skip,
      limit: options.limit,
      sortBy: options.sortBy,
    },
  };
  return cloudRequest(serverRoutes.userSurveys.getUserSurveys as IServerRoute, {}, body);
}

export function getUserSurvey(userSurveyId: string) {
  return cloudRequest((serverRoutes.userSurveys.getUserSurvey as ICreateServerRoute)(userSurveyId));
}

export function getUserSurveyResultsThemes(
  userSurveyId: string,
  filters: { search?: string; teams?: string[] },
  options?: { skip?: number; limit?: number; mode?: SURVEY_RESULTS_MODE },
) {
  const body = {
    filters: {
      search: filters?.search,
      teams: filters?.teams,
    },
    options: {
      skip: options?.skip,
      limit: options?.limit,
      mode: options?.mode ?? SURVEY_RESULTS_MODE.USER,
    },
  };

  return cloudRequest(
    (serverRoutes.userSurveys.getUserSurveyResultsThemes as ICreateServerRoute)(userSurveyId),
    {},
    body,
  );
}

export function getUserSurveyResultsThemesCSV(
  userSurveyId: string,
  filters: { search?: string; teams?: string[] },
  options?: { mode?: SURVEY_RESULTS_MODE },
) {
  const body = {
    filters,
    options: {
      mode: options?.mode ?? SURVEY_RESULTS_MODE.USER,
    },
  };

  return cloudRequest(
    (serverRoutes.userSurveys.getUserSurveyResultsThemesCSV as ICreateServerRoute)(userSurveyId),
    {},
    body,
  ).then((response) => {
    FileSaver.saveAs(response, 'userSurveyResultThemes.csv');
  });
}

export function getUserSurveyResultsThemesChart(userSurveyId: string) {
  const body = {
    options: {
      mode: SURVEY_RESULTS_MODE.USER,
    },
  };

  return cloudRequest(
    (serverRoutes.userSurveys.getUserSurveyResultsThemesChart as ICreateServerRoute)(userSurveyId),
    {},
    body,
  );
}

export function getUserSurveyResultsQuestions(
  userSurveyId: string,
  filters: {
    event: string;
    search?: string;
    themes?: string[];
    teams?: string[];
  },
  options?: { skip?: number; limit?: number; sortBy?: SURVEY_SORT_OPTIONS },
) {
  const body = {
    filters: {
      event: filters.event,
      search: filters?.search,
      themes: filters?.themes,
      teams: filters?.teams,
    },
    options: {
      skip: options?.skip,
      limit: options?.limit,
      sortBy: options?.sortBy,
    },
  };

  return cloudRequest(
    (serverRoutes.userSurveys.getUserSurveyResultsQuestions as ICreateServerRoute)(userSurveyId),
    {},
    body,
  );
}

export function downloadUserSurveyResultsQuestionsCSV(
  userSurveyId: string,
  filters: {
    event: string;
    search?: string;
    themes?: string[];
    teams?: string[];
  },
  options?: { skip?: number; limit?: number; sortBy?: SURVEY_SORT_OPTIONS },
) {
  const body = {
    filters: {
      event: filters.event,
      search: filters?.search,
      themes: filters?.themes,
      teams: filters?.teams,
    },
    options: {
      skip: options?.skip,
      limit: options?.limit,
      sortBy: options?.sortBy,
    },
  };

  return cloudRequest(
    (serverRoutes.userSurveys.downloadUserSurveyResultsQuestionsCSV as ICreateServerRoute)(
      userSurveyId,
    ),
    {},
    body,
  ).then((response) => {
    FileSaver.saveAs(response, 'userSurveyQuestions.csv');
  });
}

export function getUserSurveyResultsTeams(
  userSurveyId: string,
  filters: { search?: string; themes?: string[] },
  options?: { skip?: number; limit?: number; sortBy?: SURVEY_SORT_OPTIONS },
) {
  const body = {
    options,
    filters,
  };

  return cloudRequest(
    (serverRoutes.userSurveys.getUserSurveyResultsTeams as ICreateServerRoute)(userSurveyId),
    {},
    body,
  );
}

export function downloadUserSurveyResultsTeamsCSV(
  userSurveyId: string,
  filters: { search?: string; themes?: string[] },
) {
  const body = {
    filters,
  };

  return cloudRequest(
    (serverRoutes.userSurveys.resultsTeamsCSV as ICreateServerRoute)(userSurveyId),
    {},
    body,
  ).then((response) => {
    FileSaver.saveAs(response, 'userSurveyResultTeams.csv');
  });
}
