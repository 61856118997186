import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

// @deprecated Use getTemplates instead.
export function getTemplatesOld() {
  return cloudRequest(serverRoutes.conversationTemplates.getTemplatesDepricated);
}

export function getTemplates(params = {}) {
  return cloudRequest(serverRoutes.conversationTemplates.getTemplates, params);
}

export function getTemplate(id) {
  return cloudRequest(serverRoutes.conversationTemplates.getTemplate(id));
}

export function createTemplate(body) {
  return cloudRequest(serverRoutes.conversationTemplates.createTemplate, {}, body);
}

export function updateTemplate(id, body) {
  return cloudRequest(serverRoutes.conversationTemplates.updateTemplate(id), {}, body);
}

export function deleteTemplate(id) {
  return cloudRequest(serverRoutes.conversationTemplates.deleteTemplate(id));
}

export function deleteMultipleTemplates(templateIds) {
  return cloudRequest(serverRoutes.conversationTemplates.deleteMultipleTemplates, { templateIds });
}
