import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getCalendarEvent(id) {
  return cloudRequest(serverRoutes.calendarEvents.getEvent(id), {}, {});
}

export function renewMeetingLink(data) {
  return cloudRequest(serverRoutes.calendarEvents.meetingLink(data.id), {}, data);
}
