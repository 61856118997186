import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { IActivityCategory } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Dropdown } from '~/components/Dropdown';
import { ICONS } from '~/components/Icon';

import useDebounce from '~/hooks/useDebounce';
import { getActivityCategories } from '~/services/activityCategories';

const AutocompleteFilterActivityCategories = forwardRef(
  (
    {
      onChange,
      action,
      selectedCategories,
      className,
    }: {
      action?: () => void;
      onChange: (categories: IActivityCategory[]) => void;
      selectedCategories: IActivityCategory[];
      className?: string;
    },
    ref: Ref<{ reFetch: () => void }>,
  ) => {
    const { i18n } = useLingui();
    const [categories, setCategories] = useState<IActivityCategory[]>([]);
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 300);

    useImperativeHandle(
      ref,
      () => {
        return {
          reFetch: async () => {
            const categories = await fetchActivityCategories(debouncedSearch);
            setCategories(categories);
          },
        };
      },
      [debouncedSearch],
    );

    useEffect(() => {
      let mounted = true;
      async function fetch() {
        const categories = await fetchActivityCategories(debouncedSearch);
        if (mounted) {
          setCategories(categories);
        }
      }

      fetch();

      return () => {
        mounted = false;
      };
    }, [debouncedSearch]);

    const fetchActivityCategories = async (search: string): Promise<IActivityCategory[]> => {
      const categories = await getActivityCategories({
        search,
        limit: 20,
      });
      return Object.values(categories?.data[API_RETURN_FIELDS.ACTIVITY_CATEGORIES]);
    };

    return (
      <Dropdown
        placeholder={i18n._(t`Categories: All`)}
        items={categories}
        selectedItems={selectedCategories}
        onChange={onChange}
        onSearchChange={setSearch}
        actions={
          action
            ? [{ handler: action, name: i18n._(t`Create new`), icon: ICONS.ADD_PLUS }]
            : undefined
        }
        stringifyItem={(item) => item.name}
        className={className}
        isSearchable
      />
    );
  },
);

export { AutocompleteFilterActivityCategories };
