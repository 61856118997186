import React, { PureComponent, Fragment } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import BoxWithBorder from '~/components/UI/BoxWithBorder';

import chatIcon from '~/assets/mdi-chat-processing-big.svg';

const Card = styled(BoxWithBorder)`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: ${(props) => props.minHeight && props.minHeight};
  color: #000000;
  padding: 3px 9px;
`;

const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  align-items: center;

  img {
    padding-bottom: 15px;
  }

  h6 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
  }

  p {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
  }
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  text-transform: uppercase;
  margin-bottom: 8px;
  padding-left: 9px;
`;

class CardWithRows extends PureComponent {
  render() {
    const { title, renderRow, items, placeholderTitle, placeholderText, minHeight, i18n } =
      this.props;
    return (
      <Card minHeight={minHeight}>
        {isEmpty(items) ? (
          <Placeholder>
            <img src={chatIcon} alt={i18n._(t`No items`)} height="50px" width="50px" />
            <h6>{placeholderTitle}</h6>
            <p>{placeholderText}</p>
          </Placeholder>
        ) : (
          <Fragment>
            <Title>{title}</Title>
            {items.map(renderRow)}
          </Fragment>
        )}
      </Card>
    );
  }
}

export default withI18n()(CardWithRows);
