/* eslint no-unused-vars: 0 */
import React, { useEffect, useRef, useState } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Button from '~/components/Button';
import Modal from '~/components/Modal';

import { SelectCategories } from './SelectCategories';

import { getActivityCategories } from '~/services/activityCategories';

import type { IActivityCategory } from '@learned/types';

const FooterActions = styled.div`
  display: flex;
  flex-direction: row;
`;

const CloseButton = styled.button`
  color: var(--company-color);
  cursor: pointer;
`;

export enum Mode {
  VIEW = 'view',
  RENAME = 'rename',
  ADD = 'add',
}

interface IManageActivityCategoriesModalProps {
  title: string;
  onClose: () => void;
}

const ManageActivityCategoriesModal = ({ title, onClose }: IManageActivityCategoriesModalProps) => {
  const { i18n } = useLingui();
  const [categories, setCategories] = useState<IActivityCategory[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<IActivityCategory['id'][]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mode, setMode] = useState(Mode.VIEW);

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCategories = async () => {
    // @ts-ignore
    const categories = await getActivityCategories();

    setCategories(Object.values(categories?.data[API_RETURN_FIELDS.ACTIVITY_CATEGORIES]));
    setIsLoading(false);
  };

  const closeModal = () => {
    setSelectedCategories([]);
    setIsLoading(false);
    onClose?.();
  };

  const onUpdate = (id: IActivityCategory['id'][]) => {
    setSelectedCategories(id);
  };

  const defaultFooter = <CloseButton onClick={closeModal}>{i18n._(t`Close`)}</CloseButton>;

  const cancelButtonRef = useRef<HTMLButtonElement>();

  const editModeFooter = (
    <>
      <Button
        styles={{
          border: 'none',
          marginRight: '10px',
          width: '96px',
          height: '32px',
          minWidth: 'unset',
          borderRadius: '200px',
        }}
        label={i18n._(t`Cancel`)}
        type="white"
        buttonRef={cancelButtonRef}
        onClick={() => setMode(Mode.VIEW)}
      />
      <Button
        styles={{
          width: '96px',
          height: '32px',
          minWidth: 'unset',
          borderRadius: '200px',
        }}
        label={i18n._(t`Save`)}
        loading={isLoading}
      />
    </>
  );

  return (
    <Modal
      title={title}
      onClose={closeModal}
      showDivider={false}
      centerModal
      minWidth={750}
      contentStyles={{ padding: '41px 41px 0', overflow: 'visible' }}
      headerStyles={{ padding: '25px 41px 0' }}
      footerStyles={{ padding: '40px 41px 65px' }}
      footerRight={
        <FooterActions>{mode !== Mode.VIEW ? editModeFooter : defaultFooter}</FooterActions>
      }
    >
      <SelectCategories
        setMode={setMode}
        mode={mode}
        categories={categories}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        updateSelectedCategories={onUpdate}
        isLoading={isLoading}
        cancelButtonRef={cancelButtonRef}
      />
    </Modal>
  );
};

export { ManageActivityCategoriesModal };
