import { isEmpty } from 'lodash';
import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const ButtonWrap = styled.div`
  width: fit-content;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: ${COLORS.SUBTEXT};
`;

export const DropdownContainer = styled.div`
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDERS};
  background-color: ${COLORS.WHITE};
  width: 417px;
  box-shadow: 0 2px 5px 0 rgba(30, 8, 99, 0.21);
  box-sizing: border-box;
`;

export const IconBlock = styled.div<{
  colors?: string;
  color?: string;
}>`
  width: 40px;
  min-width: 40px;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin-right: 16px;
  box-shadow: 0 2px 5px 0 rgba(145, 157, 165, 0.12);
  ${({ colors }) =>
    !isEmpty(colors) &&
    css`
      background-image: linear-gradient(to right, ${colors});
    `}
  ${({ color }) =>
    !isEmpty(color) &&
    css`
      background: ${color};
    `}
`;

export const Options = styled.div`
  box-sizing: border-box;
`;

export const OptionContainer = styled.div<{
  $noBorderBottom?: boolean;
}>`
  cursor: pointer;
  display: flex;
  padding: 14px 14px 14px 13px;
  box-sizing: border-box;
  ${({ $noBorderBottom }) =>
    !$noBorderBottom &&
    css`
      border-bottom: solid 1px ${COLORS.BORDERS};
    `}

  &:hover {
    background-color: ${COLORS.HOVER};
  }
`;

export const Name = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: normal;
  color: ${COLORS.TEXT_MAIN};
`;

export const TextCol = styled.div``;

export const SliderAnimation = styled.div<{ $showDropdown?: boolean; $height: number }>`
  max-height: 0;
  box-sizing: border-box;
  position: absolute;
  transition: max-height 0.2s ease-out, border 0s ease-out 0.2s;
  width: 417px;
  z-index: 1000;
  right: 0;
  overflow: hidden;
  ${(props) =>
    props.$showDropdown &&
    css`
      transition: max-height 0.2s ease-out, border 0s ease-out 0s;
      box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
      max-height: ${props.$height}px;
    `}
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const StoryWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 1000px;
`;
