import React, { useEffect } from 'react';

import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import useBoolState from '~/hooks/useBoolState';
import { getUser } from '~/selectors/baseGetters';
import { axiosCloud } from '~/server_config';
import {
  updateSelectedCompany,
  updateSelectedRole,
  updateSelectedTeam,
} from '~/store/selected/actions';
import { fetchUserData } from '~/store/users/actions';
import { COLOR_PALETTE } from '~/styles';

const Layout = styled.div`
  padding: 0 32px 32px;
  width: 1060px;
`;

const GlobalStyle = createGlobalStyle`
  html {
    background: ${COLOR_PALETTE.WHITE};
  }
  @media print {
    @page {
      size: auto;
      margin: 32px 0 20px 0;
      }
    body {
        margin:0;
        padding:0;
    }
  }
`;

function PDFLayout({ children, loading = true, isChildrenLoading = false }) {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  // we need to be sure that use data applied to redux
  const user = useSelector(getUser);
  const $loading = useBoolState(true);
  const urlParams = new URLSearchParams(location.search);
  const userId = urlParams.get('userId');
  const role = urlParams.get('role');
  const companyId = urlParams.get('companyId');
  const teamId = urlParams.get('teamId');

  async function load() {
    await dispatch(fetchUserData());
    $loading.off();
  }

  useEffect(() => {
    axiosCloud.defaults.headers.common.token = match.params.token;
    axiosCloud.defaults.headers.common.role = role;
    axiosCloud.defaults.headers.common.company = companyId;
    axiosCloud.defaults.headers.common.userId = userId;
    axiosCloud.defaults.headers.common.team = teamId;

    dispatch(updateSelectedRole(role));
    dispatch(updateSelectedCompany(companyId));
    dispatch(updateSelectedTeam(teamId));
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <GlobalStyle />
      {loading || !user ? (
        <p>Loading...</p>
      ) : (
        <>
          {children}
          {!isChildrenLoading && <div id="loaded" />}
        </>
      )}
    </Layout>
  );
}

PDFLayout.propTypes = {
  loading: PropTypes.bool,
};

export default PDFLayout;
