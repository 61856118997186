import { ROLES } from '~/constants';
import { pageCreate, pageDashboard, pageUpdate } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';
const routeName = 'review-templates';

// overview
// REVIEW_TEMPLATES now in CONVERSATIONS
// history.push(routes.CONVERSATIONS.build(
//         { role: ROLES.USER },
//         { hash: 'development-templates' },
//       ))

// create
export const REVIEW_TEMPLATE_CREATE = new LinkConstructor([ROLES.ADMIN], () =>
  pageCreate(routeName),
);

// update
export const REVIEW_TEMPLATE_UPDATE = new LinkConstructor(
  [ROLES.ADMIN],
  ({ reviewTemplateId = ':reviewTemplateId' }) => pageUpdate(routeName, { id: reviewTemplateId }),
);

export const REVIEW_TEMPLATE_PREVIEW = new LinkConstructor(
  [ROLES.COACH, ROLES.ADMIN],
  ({ templateId = ':templateId' }) => `${pageUpdate(`${routeName}-preview`, { id: templateId })}`,
);

export const REVIEW_TEMPLATE_VIEW = new LinkConstructor(
  [ROLES.USER],
  ({ reviewTemplateId = ':reviewTemplateId' }) =>
    pageDashboard(routeName, { id: reviewTemplateId }),
);
