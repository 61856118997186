import { css } from 'styled-components';

export const GRADIENT = {
  GREEN: css`
    background-image: linear-gradient(to bottom, #b9efe4, #c6f7f1 98%);
  `,
  LESS_GREEN: css`
    background-image: linear-gradient(to bottom, #d8fff7, #ecfaf8);
  `,
  YELLOW: css`
    background-image: linear-gradient(to bottom, #fffbe9 2%, #fff5de 98%);
  `,
  ORANGE: css`
    background-image: linear-gradient(to bottom, #fff0e6 4%, #ffdec1 99%);
  `,
  RED: css`
    background-image: linear-gradient(to bottom, #fdd6c9 5%, #fec6b8 99%);
  `,
};
