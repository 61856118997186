import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 * still in use, no replacement yet
 */
const ArrowRightIcon = ({ width, height, ...props }) => (
  <InlineSvg height={height} width={width} {...props} childrenFirst viewBox="0 0 47 9">
    <path d="m38 0 9 4.5L38 9V5H0V4h38V0z" fill="currentColor" fillRule="nonzero" />
  </InlineSvg>
);

ArrowRightIcon.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

ArrowRightIcon.defaultProps = {
  height: 9,
  width: 47,
  className: '',
};

export default ArrowRightIcon;
