import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { ILanguage } from '~/constants/languages';
import getCurrentCompany from '~/selectors/getCurrentCompany';

export type ILanguageStateReturn = {
  languages: ILanguage[];
  setLanguages: (arg0: ILanguage[]) => void;
  companyLanguages: ILanguage[];
  companyPrimaryLanguage: ILanguage;
};

export type IBaseLanguageStateReturn = {
  languages: ILanguage[];
  setLanguages: (arg0: ILanguage[]) => void;
  defaultLanguages: ILanguage[];
  primaryLanguage: ILanguage;
};

export type IBaseLanguageState = {
  isSingleSelect?: boolean;
  primaryLanguage: ILanguage;
  defaultLanguages: ILanguage[];
  localStorageKey: string;
};

const LS_LANGUAGES = 'LS_LANGUAGES';

const useGenericLanguageState = ({
  isSingleSelect = false,
  primaryLanguage,
  defaultLanguages,
  localStorageKey,
}: IBaseLanguageState): IBaseLanguageStateReturn => {
  const [languages, setLanguages] = useState<ILanguage[]>(
    isSingleSelect ? [primaryLanguage] : defaultLanguages,
  );

  useEffect(() => {
    if (isSingleSelect) {
      return;
    }
    const localStorageData = localStorage.getItem(localStorageKey);
    const isLocalStorageData = !isEmpty(localStorageData);
    if (isLocalStorageData) {
      const LSLanguages = JSON.parse(localStorageData as string);

      if (!LSLanguages.find((i: ILanguage) => i.locale === primaryLanguage.locale)) {
        LSLanguages.push(primaryLanguage);
      }

      setLanguages(
        LSLanguages.filter((localStorageLanguages: ILanguage) =>
          defaultLanguages.find(
            (defaultLanguage) => localStorageLanguages.locale === defaultLanguage.locale,
          ),
        ),
      );
    }
    // eslint-disable-next-line
  }, []);

  const setLanguagesFunction = (newLanguages: ILanguage[]) => {
    setLanguages(newLanguages);
    if (!isSingleSelect) {
      localStorage.setItem(LS_LANGUAGES, JSON.stringify(newLanguages));
    }
  };
  return {
    languages,
    setLanguages: setLanguagesFunction,
    defaultLanguages,
    primaryLanguage,
  };
};

const useLanguageState = (isSingleSelect = false) => {
  const currentCompany = useSelector(getCurrentCompany);
  const companyLanguages = currentCompany.languages as ILanguage[];
  const companyPrimaryLanguage = currentCompany.primaryLang as ILanguage;
  const { languages, setLanguages } = useGenericLanguageState({
    isSingleSelect,
    primaryLanguage: companyPrimaryLanguage,
    defaultLanguages: companyLanguages,
    localStorageKey: LS_LANGUAGES,
  });

  return {
    languages,
    setLanguages,
    companyLanguages,
    companyPrimaryLanguage,
  } as ILanguageStateReturn;
};

export { useLanguageState, useGenericLanguageState };
