import React, { Component, Fragment } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import moment from 'moment';
import { connect } from 'react-redux';
import styled from 'styled-components';

import FiltersHeading from '~/components/FiltersHeading';
import OverviewHeading from '~/components/OverviewHeading';
import ActionsContainer from '~/components/UI/ActionsContainer';
import AvatarCard from '~/components/UI/AvatarCard';
import TableCard, { TableCol, TableRow } from '~/components/UI/TableCard';
import BaseLayout from '~/layouts/BaseLayout';

import getUserFullName from '~/utils/getUserFullName';

const Section = styled.div`
  margin-bottom: 48px;
`;

const UserRow = styled(TableCol)`
  padding: 12px 4px;
`;

class EmployeeReportPage extends Component {
  constructor(props) {
    super(props);
    this.employees = Object.values(props.users || {});
    this.state = {
      filteredEmployees: this.employees,
      loading: true,
    };
  }

  handleChangeFilter = (filter) => {
    this.setState({ filteredEmployees: this.employees.filter(filter) });
  };

  customSearchFilter = (searchValue) => (item) => {
    // search in first and last name
    const isFirstNameMatch =
      item.firstName && item.firstName.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
    const isLastNameMatch =
      item.lastName && item.lastName.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
    return isFirstNameMatch || isLastNameMatch;
  };

  getDuration = (date) => {
    if (date) {
      const days = moment().diff(date, 'days');
      if (!days) {
        return '<1D';
      }

      return days > 30 ? `>${Math.floor(days / 30)}M` : `${days}D`;
    }
    return '-';
  };

  getLoginCount = (user) => {
    const entry = (user.lastEntrances || []).filter((date) => moment().diff(date, 'days') <= 30);
    if (entry.length === 0 && user.lastActivity && moment().diff(user.lastActivity, 'days') < 30) {
      return 1;
    }
    return entry.length;
  };

  getEmployeeCols = (i18n) => [
    { title: i18n._(t`Employee`), name: 'employee', getValue: getUserFullName },
    {
      title: i18n._(t`Last activity`),
      width: '110px',
      dataType: 'date',
      property: 'lastActivity',
      sort: true,
    },
    {
      title: i18n._(t`Sessions last 30d`),
      width: '155px',
      getValue: this.getLoginCount,
      sort: true,
    },
  ];

  renderUser = (user) => {
    return (
      <TableRow key={user.id}>
        <UserRow>
          <AvatarCard userId={user.id} />
        </UserRow>
        <TableCol>{this.getDuration(user.lastActivity)}</TableCol>
        <TableCol>{this.getLoginCount(user)}</TableCol>
      </TableRow>
    );
  };

  render() {
    const { i18n } = this.props;
    const { filteredEmployees } = this.state;

    return (
      <Fragment>
        <OverviewHeading title={i18n._(t`Employee activity`)} useSearch={false} />
        <BaseLayout>
          <Section>
            <ActionsContainer noBottomBorder>
              <FiltersHeading
                onFilterChange={this.handleChangeFilter}
                customSearchFilter={this.customSearchFilter}
              />
            </ActionsContainer>
            <TableCard
              hideHeader
              cols={this.getEmployeeCols(i18n)}
              items={filteredEmployees}
              firstPlaceholder={i18n._(t`No employee activity available.`)}
              renderRow={this.renderUser}
              noTopBorder
              hideSearch
            />
          </Section>
        </BaseLayout>
      </Fragment>
    );
  }
}
export default connect((state) => ({
  users: state.users.data,
}))(withI18n()(EmployeeReportPage));
