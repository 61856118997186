import styled from 'styled-components';

import Button from '~/components/Button';

import { COLOR_SET, COLORS } from '~/styles';

const Container = styled.div`
  @media print {
    ${(props) => props.$pdfView && 'break-inside: avoid;'};
  }
`;

const FeedbackTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.TEXT_BLACK};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 11px;
  border-top: solid 1px ${COLORS.BG_PAGE};
`;

const StyledButton = styled(Button)`
  height: 32px;
  font-weight: bold;
  border-color: ${COLOR_SET.MIDNICHT_BLUE};
  color: ${COLOR_SET.MIDNICHT_BLUE};

  &:hover {
    background-color: ${COLOR_SET.MIDNICHT_BLUE};
  }
`;

export { StyledButton, FeedbackTitle, Container };
