import React from 'react';

import { SURVEY_QUESTION_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Answer } from './Answer';

import { COLORS } from '~/styles';

import { QuestionWrapper } from '../components/QuestionWrapper';

const Answers = styled.div`
  margin-top: 4px;
`;

const Placeholder = styled.div`
  height: 70px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.HOVER};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  color: ${COLORS.BLACK};
`;

export interface IItem {
  type: SURVEY_QUESTION_TYPES;
  title: string;
  titleNumber: string;
  themeName: string;
  answers: {
    label?: string;
    isValue: boolean;
    value?: number | string;
    percentage?: number | string;
  }[];
  isAnswersHidden: boolean;
}
interface ISurveyQuestionProps {
  item: IItem;
}

const SurveyQuestion = ({ item }: ISurveyQuestionProps) => {
  const { i18n } = useLingui();

  return (
    <QuestionWrapper item={item}>
      <Answers>
        {item.answers.length > 0 ? (
          item.answers.map((answer, key) => <Answer key={key} {...answer} />)
        ) : (
          <Placeholder>{i18n._(t`No answers yet`)}</Placeholder>
        )}
      </Answers>
    </QuestionWrapper>
  );
};

export { SurveyQuestion };
