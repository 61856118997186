import type { Dispatch, SetStateAction } from 'react';

import { ISelectedFocusArea, ISelectedSkillTemplate } from '~/@types/job';

import type { ILanguageValue, ISkill, ISkillCategory, ISkillTemplate } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

export interface IForm {
  name: ILanguageValue[];
  skills: {
    [key in string]: ISelectedSkillTemplate;
  };
}

export enum AddSkillModalResource {
  SKILLS = 'skills',
  SKILL_TEMPLATES = 'skillTemplates',
}

export interface AddSkillModalProps<T extends IForm> {
  onClose: () => void;
  skillCategoryName: string;
  defaultFocusAreaLevel: number | null;
  isDefaultFocusAreaLevelEnabled?: boolean;
  formMethods: UseFormReturn<T>;
  skillCategoryId: string;
  skillCategoryTemplateId?: string;
  skillCategoryLevels?: ISkillCategory['skillLevels'];
  initSource?: ISkillTemplate | ISkill;
  resource?: AddSkillModalResource;
  setSkillTemplates?: Dispatch<SetStateAction<ISkillTemplate[]>>;
  setSkills?: Dispatch<SetStateAction<ISkill[]>>;
  jobTemplateId?: string;
  skillTemplates?: ISkillTemplate[];
  selectedSkills?: (ISkill | ISkillTemplate)[];
}

export interface SearchSkillProps<T extends IForm> {
  onClose: () => void;
  skillCategoryName: string;
  skillCategoryId: string;
  searchInputValue: string;
  setSearchInputValue: Dispatch<SetStateAction<string>>;
  skillNames?: (ISkillTemplate | ISkill)[];
  skills: Array<ISkillTemplate>;
  defaultFocusAreaLevel: number | null;
  isDefaultFocusAreaLevelEnabled?: boolean;
  setIsSelectLevelAndFocusAreasVisible: Dispatch<SetStateAction<boolean>>;
  setSource: Dispatch<SetStateAction<ISkillTemplate | ISkill | undefined>>;
  formMethods: UseFormReturn<T>;
  setSkillTemplates?: Dispatch<SetStateAction<ISkillTemplate[]>>;
  setSkills?: Dispatch<SetStateAction<ISkill[]>>;
}

export interface SelectLevelAndFocusAreasProps<T extends IForm> {
  onClose: () => void;
  skillCategoryName: string;
  skillCategoryId: string;
  skillCategoryLevels?: ISkillCategory['skillLevels'];
  source: ISkillTemplate | ISkill;
  setIsSelectLevelAndFocusAreasVisible: Dispatch<SetStateAction<boolean>>;
  formMethods: UseFormReturn<T>;
  setSkillTemplates?: Dispatch<SetStateAction<ISkillTemplate[]>>;
  setSkills?: Dispatch<SetStateAction<ISkill[]>>;
}

export interface WatchSkill {
  skill?: string;
  skillTemplate?: string;
  selectedFocusAreas?: ISelectedFocusArea[];
}
