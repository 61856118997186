import find from 'lodash/find';

import type { ILanguage } from '~/constants/languages';
import { getMultiLangString } from '~/utils/getMultiLangString';

export const getSuperAdminMultiLangString = (
  object: { [key: string]: string },
  languageState: {
    languages: ILanguage[];
    primaryLanguage: ILanguage;
  },
) => {
  const { _id, id: __id, ...rest } = object;
  return getMultiLangString(
    rest,
    {},
    {
      primaryLang: languageState.primaryLanguage,
      languages: languageState.languages,
    },
  );
};

export const getSuperAdminMultiLangStringForSingleSelect = (
  object: { [key: string]: string },
  languageState: {
    languages: ILanguage[];
    primaryLanguage: ILanguage;
  },
) => {
  const locale = languageState.languages[0].locale;
  const primaryLocale = languageState.primaryLanguage.locale;

  return object[locale || primaryLocale] || find(object) || '';
};
