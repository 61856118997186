import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Button from '~/components/Button';
import PlusIcon from '~/components/Icons/Plus';
import SvgIcon from '~/components/SvgIcon';

import {
  SectionContainer,
  Title,
  Subtitle,
  InputWrapper,
  StyledInput,
  EmailRow,
  EmailText,
  AddExternalPeople,
} from './styledComponets';

import trashIcon from '~/assets/trash.svg';

import { COLOR_PALETTE } from '~/styles';

const AddButton = styled(Button)`
  position: absolute;
  right: 12px;
`;

const ExternalEmailsBlock = ({
  isShowAddExternalPeople,
  subTitle,
  email,
  changeEmail,
  isEmailError,
  onAddEmail,
  selectedEmails,
  removeEmail,
  onAddExternalPeople,
}) => {
  const { i18n } = useLingui();
  return (
    <SectionContainer>
      {isShowAddExternalPeople ? (
        <>
          <Title>
            <Trans>External people</Trans>
          </Title>
          <Subtitle>{subTitle}</Subtitle>
          <InputWrapper>
            <StyledInput
              value={email}
              onChange={changeEmail}
              placeholder={i18n._(t`Type an email..`)}
            />
            <AddButton
              label={i18n._(t`Add`)}
              type="link-primary"
              disabled={isEmailError}
              onClick={onAddEmail}
            />
          </InputWrapper>
          {selectedEmails.map((selectedEmail, index) => {
            return (
              <EmailRow key={index}>
                <EmailText>{selectedEmail}</EmailText>
                <SvgIcon
                  width="26px"
                  height="26px"
                  url={trashIcon}
                  isDefaultColor={true}
                  defaultColor={COLOR_PALETTE.GRAY_SEMI_SOFT}
                  onClick={() => removeEmail(selectedEmail)}
                />
              </EmailRow>
            );
          })}
        </>
      ) : (
        <AddExternalPeople onClick={onAddExternalPeople}>
          <PlusIcon size={16} />
          <Trans>Add external people</Trans>
        </AddExternalPeople>
      )}
    </SectionContainer>
  );
};

export default ExternalEmailsBlock;
