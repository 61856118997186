import styled from 'styled-components';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: 6px;
  td:first-child > div {
    position: relative;
    top: 3px;
  }
  th:first-child > div {
    position: relative;
    top: 2px;
  }
`;

const BoldMissingText = styled.div`
  font-weight: 500;
`;

const SkillItem = styled.div`
  padding: 0 5px;
  max-width: 24ch;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  cursor: pointer;
`;

const EditorCircle = styled.div<{ itemNr: number; avatarUrl: string }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;
  transform: translateX(-50%);
  transform: translateX(-${({ itemNr }) => itemNr * 100}%);
  border: 2px solid ${COLORS.WHITE};
  background-color: ${COLORS.PLACEHOLDERS};
  flex: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ avatarUrl }) => `url("${avatarUrl}")`};
`;

const EditorCircleCtr = styled.div`
  display: flex;
  align-items: center;
`;

const EditorsCount = styled.div`
  color: ${COLORS.PLACEHOLDERS};
`;

export { Wrapper, SkillItem, EditorCircle, EditorCircleCtr, EditorsCount, BoldMissingText };
