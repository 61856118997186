import React, { useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Editor from '~/components/Editor';
import RickTextView from '~/components/RickTextView';
import ShowMore from '~/components/ShowMore';

import { RATING_TYPES, REVIEW_QUESTIONS_TABS } from '~/constants';
import { getCurrentReview } from '~/selectors/baseGetters';
import getLang from '~/selectors/getLang';
import { COLOR_PALETTE, COLORS } from '~/styles';
import { getFocusAreaValuesByLevels } from '~/utils/focusAreas';
import { getSkillDescription, getSkillName } from '~/utils/skillUtils';

import QuestionCard from '../QuestionCard';
import RatingRow from '../RatingRow';
import RatingStatusRows from '../RatingStatusRow';
import { SkillFocusAreas } from '../SkillFocusAreas';

const DefinitionBlock = styled.div`
  border-radius: 3px;
  border: solid 1px #dae4f7;
  padding: 12px 22px 14px 17px;
  margin-bottom: 8px;
`;

const DefinitionTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLORS.TEXT_PRIMARY};
`;

const StyledRickTextView = styled(RickTextView)`
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
`;

const StyledEditor = styled(Editor)`
  .ql-container {
    max-height: 550px;
  }
`;

const ReviewSkillQuestion = ({
  i18n,
  question,
  skill,
  rating = {},
  review: reviewProp,
  ratingType,
  forUser,
  isActive = false,
  isError = false,
  expectedLevel,
  skillCategories,
  onChange,
  tabs,
  typeRatings = [],
  onQuestionClick,
  indexCurrent,
  indexTotal,
  isOpenDefault,
  isOpenForce,
  isReadOnly,
}) => {
  const [currentTab, setCurrentTab] = useState(REVIEW_QUESTIONS_TABS.OWN_ANSWER.key);
  const reviewStore = useSelector(getCurrentReview);
  const lang = useSelector(getLang);
  const review = reviewProp || reviewStore;

  const onChangeSkipped = (_e, isSkipped) => {
    const updateData = { isSkipped, ...(isSkipped && { rate: 0 }) };
    return onChange(skill.id, updateData);
  };

  const handleClickQuestion = () => {
    if (onQuestionClick) {
      onQuestionClick(skill.id);
    }
  };

  const renderFocusAreas = () => {
    const skillLevel = get(review, `skillsLevels[${skill.id}]`);
    const skillsJobProfileLevel = get(review, `skillsJobProfileLevels[${skill.id}]`);
    const skillFocusAreas = getFocusAreaValuesByLevels(
      skill.focusAreas || [],
      skillLevel,
      skillsJobProfileLevel,
    );

    if (skillFocusAreas.length === 0) {
      return null;
    }

    return <SkillFocusAreas focusAreas={skillFocusAreas} />;
  };

  const isSelf = ratingType === RATING_TYPES.SELF_RATING;
  const skillName = getSkillName(skill, lang);

  const isSkillRateWithLevel = review.isRateWithLevel;
  const skillDefinition = getSkillDescription(skill, lang);

  return (
    <QuestionCard
      title={
        !isSelf
          ? i18n._(t`How did ${forUser.firstName} perform on the skill: ${skillName}`)
          : i18n._(t`How did you perform on the skill: ${skillName}`)
      }
      skillCategories={skill.categories}
      reviewSkillCategories={skillCategories}
      isActive={isActive}
      onClick={handleClickQuestion}
      hideFooter
      isError={isError}
      tabs={tabs}
      handleChangeTab={setCurrentTab}
      currentTab={currentTab}
      isNoChildrenPadding={currentTab !== REVIEW_QUESTIONS_TABS.OWN_ANSWER.key}
      indexCurrent={indexCurrent}
      indexTotal={indexTotal}
      isOpenDefault={isOpenDefault}
      isOpenForce={isOpenForce}
    >
      {currentTab === REVIEW_QUESTIONS_TABS.OWN_ANSWER.key ? (
        <>
          {skillDefinition && (
            <DefinitionBlock>
              <DefinitionTitle>
                <Trans>Definition:</Trans>
              </DefinitionTitle>
              <ShowMore maxHeight={70}>
                <StyledRickTextView html={skillDefinition} />
              </ShowMore>
            </DefinitionBlock>
          )}
          {!isSkillRateWithLevel && renderFocusAreas()}
          {!question.hideRating && (
            <RatingRow
              rate={rating.rate}
              scale={rating.scale}
              scaleLabels={rating.scaleLabels}
              onChange={(rate) => onChange(skill.id, { rate })}
              isSkipped={rating.isSkipped}
              isSkippable={question.isSkippable}
              changeSkipped={onChangeSkipped}
              skillFocusAreas={skill.focusAreas}
              hideFocusAreas={!isSkillRateWithLevel}
              expectedLevel={expectedLevel}
              isSingleHover
              disabled={isReadOnly}
            />
          )}
          <StyledEditor
            value={rating.explanation || ''}
            placeholder={`${i18n._(t`Explain your answer`)}${rating.isCommentObligated ? '*' : ''}`}
            onChange={(explanation) => onChange(skill.id, { explanation })}
            compact
            small
            readOnly={isReadOnly}
          />
        </>
      ) : (
        <RatingStatusRows
          ratings={typeRatings}
          requests={review.requests}
          currentTab={currentTab}
          forUser={forUser}
        />
      )}
    </QuestionCard>
  );
};

export default withI18n()(ReviewSkillQuestion);
