import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import TextFieldWithButton from '~/components/TextFieldWithButton';

import ListSelectAll from './ListSelectAll';

import { TALKING_POINT_TYPES } from '~/constants';

const InputWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  min-height: 56px;
`;

function TabDefault({
  i18n,
  loading,
  type,
  items,
  createItem,
  selectedItems,
  disabledItems,
  toggleItem,
  toggleItems,
  placeholder,
  autoCompleteFilter,
}) {
  const placeholderDefault = <Placeholder title={i18n._(t`No talking points`)} />;

  return (
    <>
      {createItem && (
        <InputWrapper>
          <TextFieldWithButton
            placeholder={i18n._(t`Example: How are you feeling this week?`)}
            onSubmit={createItem}
            noDuplicateList={[...disabledItems, ...Object.keys(selectedItems)]}
          />
        </InputWrapper>
      )}
      <ShowSpinnerIfLoading loading={loading}>
        {autoCompleteFilter}
        {isEmpty(items) ? (
          placeholder || placeholderDefault
        ) : (
          <ListSelectAll
            type={type}
            items={items}
            selectedItems={selectedItems}
            disabledItems={disabledItems}
            toggleItem={toggleItem}
            toggleItems={toggleItems}
          />
        )}
      </ShowSpinnerIfLoading>
    </>
  );
}

TabDefault.propTypes = {
  type: PropTypes.oneOf(map(TALKING_POINT_TYPES, (type) => type.key)),
  items: PropTypes.array,
  selectedItems: PropTypes.object,
  disabledItems: PropTypes.array,
  toggleItem: PropTypes.func,
  toggleItems: PropTypes.func,
  loading: PropTypes.bool,
  placeholder: PropTypes.node,
  createItem: PropTypes.func,
  autoCompleteFilter: PropTypes.node,
};

export default withI18n()(TabDefault);
