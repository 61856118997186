import nanoId from 'nanoid';
import { createReducer } from 'redux-act';

import * as currentGoalActions from './actions';

const INITIAL_STATE = {};

function cleanGoalState(goal) {
  return {
    ...goal,
    skills: (goal.skills || []).filter(Boolean),
  };
}

export const currentGoal = createReducer(
  {
    [currentGoalActions.resetCurrentGoal]: () => {
      return {
        ...INITIAL_STATE,
        session: nanoId(), // it's identification of started create/update setup
      };
    },

    [currentGoalActions.setCurrentGoal]: (_state, payload = {}) => {
      let activities = payload.activities || [];

      return cleanGoalState({
        ...INITIAL_STATE,
        ...payload,
        activities,
        session: nanoId(), // it's identification of started create/update setup
      });
    },

    [currentGoalActions.updateCurrentGoal]: (state, payload) => {
      return cleanGoalState({
        ...state,
        ...payload,
      });
    },

    [currentGoalActions.updateCurrentGoalActivity]: (state, { id, ...payload }) => {
      // use activityIndex for update new activities without Id
      const activityIndex = payload.activityIndex;
      delete payload.activityIndex;
      const index =
        activityIndex !== undefined && activityIndex !== null
          ? activityIndex
          : state.activities.map((a) => a && a.id).indexOf(id);
      const newActivities = [...state.activities];
      newActivities[index] = { ...newActivities[index], ...payload };

      return {
        ...state,
        activities: newActivities,
      };
    },

    [currentGoalActions.addCurrentGoalActivity]: (state, payload) => {
      if (payload) {
        const activities = [...(state.activities || []), payload];
        return {
          ...state,
          activities,
        };
      }
      return state;
    },

    [currentGoalActions.removeCurrentGoalActivity]: (state, payload) => {
      const activities = state.activities.filter((a) => (a ? a !== payload : false));
      return {
        ...state,
        activities,
      };
    },

    // NEW LOGIC
    [currentGoalActions.setCurrentGoalType]: (state, payload) => {
      return {
        ...state,
        type: payload,
      };
    },
    [currentGoalActions.setCurrentGoalCategory]: (state, payload) => {
      return {
        ...state,
        parent: null,
        category: payload,
      };
    },
    [currentGoalActions.setCurrentGoalTeams]: (state, payload) => {
      return {
        ...state,
        teams: payload,
      };
    },
    [currentGoalActions.setCurrentGoalOwners]: (state, payload) => {
      return {
        ...state,
        owners: payload,
      };
    },
    [currentGoalActions.setCurrentGoalCreators]: (state, payload) => {
      return {
        ...state,
        creators: payload,
      };
    },
    [currentGoalActions.setCurrentGoalViewers]: (state, payload) => {
      return {
        ...state,
        parent: null,
        viewers: payload,
      };
    },
    [currentGoalActions.setCurrentGoalName]: (state, payload) => {
      return {
        ...state,
        name: payload,
      };
    },
    [currentGoalActions.setCurrentGoalDescription]: (state, payload) => {
      return {
        ...state,
        description: payload,
      };
    },
    [currentGoalActions.setCurrentGoalProgressType]: (state, payload) => {
      return {
        ...state,
        progressType: payload,
      };
    },
    [currentGoalActions.setCurrentGoalIsChildProgress]: (state, payload) => {
      return {
        ...state,
        isChildProgress: payload,
      };
    },
    [currentGoalActions.setCurrentGoalProgressDetails]: (state, payload) => {
      return {
        ...state,
        progressDetails: payload,
      };
    },
    [currentGoalActions.setCurrentGoalDeadline]: (state, payload) => {
      return {
        ...state,
        deadline: payload,
      };
    },
    [currentGoalActions.setCurrentGoalCycles]: (state, payload) => {
      return {
        ...state,
        goalCycles: payload,
      };
    },
    [currentGoalActions.setCurrentGoalParent]: (state, payload) => {
      return {
        ...state,
        parent: payload,
      };
    },
    [currentGoalActions.setCurrentGoalActivities]: (state, payload) => {
      return {
        ...state,
        activities: payload,
      };
    },
    [currentGoalActions.setCurrentGoalSkills]: (state, payload) => {
      return {
        ...state,
        skills: payload,
      };
    },
    [currentGoalActions.setCurrentGoalIsOwnerCanEdit]: (state, payload) => {
      return {
        ...state,
        isOwnerCanEdit: payload,
      };
    },
    [currentGoalActions.setCurrentGoalIsCoachesOfOwnerCanEdit]: (state, payload) => {
      return {
        ...state,
        isCoachesOfOwnerCanEdit: payload,
      };
    },
    [currentGoalActions.setCurrentGoalErrors]: (state, payload) => {
      return {
        ...state,
        errors: payload,
      };
    },
    [currentGoalActions.setIsAddCurrentGoalActivities]: (state, payload) => {
      return {
        ...state,
        isCurrentGoalAddActivities: payload,
      };
    },
  },
  INITIAL_STATE,
);
