import React from 'react';

import { ITask } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import { ListRowPlaceholder } from '~/pages/Reviews/Modals/ReviewEmployeeOverviewModal/ReviewEmployeeOverviewForm/components/ListRowPlaceholder';
import type { IReviewEmployeeOverviewForm } from '~/pages/Reviews/Modals/ReviewEmployeeOverviewModal/ReviewEmployeeOverviewForm/types';

import { COLORS } from '~/styles';

import { List } from '../components/List';
import { ListRowTask } from '../components/ListRowTask';
import { Section } from '../components/Section';

import type { UseFormReturn } from 'react-hook-form';

const SubTitle = styled.div`
  font-size: 14px;
  color: ${COLORS.SUBTEXT};
  margin: 20px 17px 0 23px;
`;

const ButtonWrapper = styled.div`
  margin-top: 12px;
`;

interface IReceivedInputSectionProps {
  tasksPeers: ITask[];
  tasksCoaches: ITask[];
  isPeerInputTasks: boolean;
  isCoachInputTasks: boolean;
  formMethods: UseFormReturn<IReviewEmployeeOverviewForm>;
  isAllowToDeletePeers: boolean;
  isAllowToDeleteCoaches: boolean;
  isAllowToAddPeers: boolean;
  isAllowToAddCoaches: boolean;
  onDeletePeer: (task: ITask) => void;
  onDeleteCoach: (task: ITask) => void;
  onAddPeers: () => void;
  onAddCoaches: () => void;
}

const ReceivedInputSection = ({
  tasksPeers,
  tasksCoaches,
  isPeerInputTasks,
  isCoachInputTasks,
  formMethods: _formMethods,
  isAllowToDeletePeers,
  isAllowToDeleteCoaches,
  isAllowToAddCoaches,
  isAllowToAddPeers,
  onDeletePeer,
  onDeleteCoach,
  onAddPeers,
  onAddCoaches,
}: IReceivedInputSectionProps) => {
  const { i18n } = useLingui();

  const data = [
    ...(isCoachInputTasks
      ? [
          {
            tasks: tasksCoaches,
            subTitle: i18n._(t`From coaches`),
            button: isAllowToAddCoaches && (
              <Button
                variant={ButtonVariant.TEXT_PRIMARY}
                size={ButtonSize.MEDIUM}
                label={i18n._(t`Add coaches`)}
                onClick={onAddCoaches}
                icon={ICONS.ADD_USER}
                iconSize={ICON_SIZES.SMALL}
              />
            ),
            placeholder: i18n._(t`No coaches selected`),
            onDelete: isAllowToDeleteCoaches ? onDeleteCoach : undefined,
          },
        ]
      : []),
    ...(isPeerInputTasks
      ? [
          {
            tasks: tasksPeers,
            subTitle: i18n._(t`From peers`),
            button: isAllowToAddPeers && (
              <Button
                variant={ButtonVariant.TEXT_PRIMARY}
                size={ButtonSize.MEDIUM}
                label={i18n._(t`Add peers`)}
                onClick={onAddPeers}
                icon={ICONS.ADD_USER}
                iconSize={ICON_SIZES.SMALL}
              />
            ),
            placeholder: i18n._(t`No peers asked for input yet.`),
            onDelete: isAllowToDeletePeers ? onDeletePeer : undefined,
          },
        ]
      : []),
  ];

  return (
    <Section title={i18n._(t`Input to receive`)}>
      {data.map((item, i) => (
        <div key={i}>
          <SubTitle>{item.subTitle}</SubTitle>
          <List>
            {isEmpty(item.tasks) ? (
              <ListRowPlaceholder text={item.placeholder} />
            ) : (
              item.tasks.map((task, key) => (
                <ListRowTask key={key} task={task} onDelete={item.onDelete} />
              ))
            )}
          </List>
          {item.button && <ButtonWrapper>{item.button}</ButtonWrapper>}
        </div>
      ))}
    </Section>
  );
};

export { ReceivedInputSection };
