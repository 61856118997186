import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import Label from '~/components/Label';

import { COLORS } from '~/styles';

import { ProductItem, ProductSwitch, ReadMore } from '../design';

interface IProductProps {
  product: {
    name: string;
    description: string;
    link: string;
    icon: ICONS;
    color: string;
    isFree?: boolean;
    isDescriptionVisible?: boolean;
    isDisabled: boolean;
    isSelected: boolean;
    tooltip?: string;
    onSwitch: (value: boolean) => void;
    onReadMore: () => void;
  };
}

export const Product = ({
  product: {
    name,
    description,
    isDescriptionVisible,
    isFree,
    color,
    icon,
    isSelected,
    isDisabled,
    tooltip,
    onSwitch,
    onReadMore,
  },
}: IProductProps) => {
  const { i18n } = useLingui();

  return (
    <ProductItem isSelected={isSelected} isDescriptionVisible={isDescriptionVisible}>
      <div className="icon">
        <span>{icon && <Icon icon={icon} size={ICON_SIZES.LARGE} color={color} />}</span>
      </div>
      <button type="button" className="main" onClick={onReadMore}>
        <span className="name">
          {name}
          {isFree && (
            <Label color={COLORS.COMPANY} backgroundByPercentage={10}>
              <Trans>Free</Trans>
            </Label>
          )}
          <ReadMore>{isDescriptionVisible ? i18n._(t`Show less`) : i18n._(t`Read more…`)}</ReadMore>
        </span>
        {isDescriptionVisible && <div className="description">{description}</div>}
      </button>
      <div className="switch">
        <span>
          <ProductSwitch
            checked={isSelected}
            onChange={onSwitch}
            disabled={isDisabled}
            tooltip={tooltip}
          />
        </span>
      </div>
    </ProductItem>
  );
};
