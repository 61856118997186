import React, { useEffect, useState } from 'react';

import { NEXT_STEP_TYPES } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import CheckBox from '~/components/CheckBox';
import { TemplateCardWithFooter } from '~/components/SetupTools/components/TemplateBaseComponents';
import UserAvatar from '~/components/UI/Avatar';
import { Header4 } from '~/components/UI/Typographics/headers';

import { getUsers } from '~/selectors/baseGetters';
import { getRelevantSteps } from '~/services/nextSteps';
import { COLOR_PALETTE, COLOR_SET } from '~/styles';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';
import getUserFullName from '~/utils/getUserFullName';

const Header = styled(Header4)`
  margin: 0;
  padding: 15px 16px;
  border-bottom: solid 1px ${COLOR_SET.VAGUELY_VIOLET};
`;

const StepWrapper = styled.div`
  width: 100%;
  padding: 20px 20px 0px;
  &:last-child {
    padding: 20px;
  }
`;

const StepContent = styled.div`
  display: flex;
  & .checkbox {
    margin: 0 10px;
  }
`;

const StepComment = styled.div`
  color: ${COLOR_SET.TOTAL_BLACK};
  ${(props) =>
    props.$isCompleted &&
    css`
      text-decoration: line-through;
    `}
`;

const StepDate = styled.div`
  font-size: 14px;
  padding: 5px 0px 0px;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const Section = styled.div`
  display: block;
  @media print {
    margin-top: 40px;
    ${(props) => props.$break && 'page-break-before: always;'};
    ${(props) => props.$pdfView && 'break-inside: avoid;'};
  }
`;

const Wrapper = styled.div`
  ${(props) => !props.$pdfView && 'margin-bottom: 24px'};
`;

function NextSteps({ targetId, pdfView }) {
  const [nextSteps, setNextSteps] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data: fetchedSteps } = await getRelevantSteps({
        type: NEXT_STEP_TYPES.REVIEW,
        targetId,
      });
      setNextSteps(fetchedSteps.currentConvSteps);
    }
    fetchData();
  }, [targetId]);

  const { i18n } = useLingui();
  const allUsers = useSelector(getUsers);

  return (
    <Wrapper $pdfView={pdfView}>
      <Section $pdfView={pdfView}>
        <TemplateCardWithFooter $noShadow={pdfView}>
          <Header>
            <Trans>Next steps</Trans>
          </Header>
          {nextSteps?.map(({ id, comment, createdFor, createdBy, isCompleted, meta }) => (
            <StepWrapper key={id}>
              <StepContent>
                <UserAvatar size={28} userId={createdFor} />
                <CheckBox
                  className="checkbox"
                  size={18}
                  checked={isCompleted}
                  isDefaultColor={false}
                />
                <StepComment $isCompleted={isCompleted}>{comment}</StepComment>
              </StepContent>
              <StepDate>
                {`${i18n._(t`Created by`)} ${getUserFullName(
                  allUsers[createdBy],
                )}, ${convertToTimeString(meta.createdDate, TIME_FORMATS.DAY_MONTH_YEAR)}`}
              </StepDate>
            </StepWrapper>
          ))}
        </TemplateCardWithFooter>
      </Section>
    </Wrapper>
  );
}

NextSteps.propTypes = {
  targetId: PropTypes.string,
  pdfView: PropTypes.bool,
};

export { NextSteps };
