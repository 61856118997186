import { SIGNATURE_STATUSES, SIGNATURE_VIEWS } from '@learned/constants';

export const getSignatureStatus = (view, { signatures, conversationCoaches, userId }) => {
  switch (view) {
    case SIGNATURE_VIEWS.USER:
      return defineUserSignCardStatus({ signatures, conversationCoaches, userId });
    case SIGNATURE_VIEWS.COACH:
      return defineCoachSignCardStatus({ signatures, conversationCoaches, userId });
    default:
      return;
  }
};

const defineUserSignCardStatus = ({ signatures = [], conversationCoaches = [], userId }) => {
  const isSigned = signatures.find((s) => s.userId === userId);
  if (isSigned) {
    return SIGNATURE_STATUSES.SIGNED;
  }

  const isActive = conversationCoaches.length === signatures.length;
  if (isActive) {
    return SIGNATURE_STATUSES.ACTIVE;
  }

  return SIGNATURE_STATUSES.WAITING;
};
const defineCoachSignCardStatus = ({ signatures = [], conversationCoaches = [], userId }) => {
  const isSigned = signatures.find((s) => s.userId === userId);
  if (isSigned) {
    return SIGNATURE_STATUSES.SIGNED;
  }

  const isActive = conversationCoaches.length > signatures.length;
  if (isActive) {
    return SIGNATURE_STATUSES.ACTIVE;
  }

  return SIGNATURE_STATUSES.WAITING;
};
