import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { COLOR_PALETTE, COLORS } from '~/styles';

const RateBlock = styled.div`
  background: ${(props) =>
    props.$isSelected
      ? props.$isHovered
        ? '#cadafc'
        : COLORS.BG_PAGE
      : props.$isHovered && !props.$readonly
      ? '#cadafc'
      : 'none'};
  height: ${(props) => props.$blockHeight || '24px'};
  border-style: solid;
  border-color: ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  border-width: ${(props) => (props.$gap === '0px' ? '0 1px 0 0' : '1px')};
  border-radius: ${(props) => (props.$gap === '0px' ? '0' : '3px')};
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  flex: 1 1 auto;
  margin-right: ${(props) => props.$gap};
  cursor: ${(props) => (props.$readonly || props.$disabled ? 'default' : 'pointer')};
  position: relative;
  text-align: center;
`;

const RatingScaleText = styled.p`
  font-weight: ${(props) => (props.$isSelected ? '600' : 'normal')};
  color: ${(props) => (props.$isSelected ? 'var(--company-color)' : COLOR_PALETTE.DARK_GRAY)};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  font-size: 14px;
`;

const ExpectedBlock = styled.div`
  position: absolute;
  top: -35px;
  background: ${COLORS.BG_PAGE};
  width: 146px;
  border-radius: 3px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 26px;
  color: ${COLORS.TEXT_SECONDARY};
  height: 26px;
  &:after {
    content: '';
    position: absolute;
    display: block;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${COLORS.BG_PAGE};
    bottom: -5px;
    left: 68px;
  }
`;

const RatingBlock = ({
  rate,
  readonly,
  isHovered,
  label,
  isBig,
  isSelected,
  blockGap,
  blockHeight,
  onChange,
  setHoverValue,
  className,
  isExpected,
  disabled = false,
}) => {
  return (
    <RateBlock
      $readonly={readonly}
      onClick={() => !readonly && onChange(rate)}
      onMouseOver={() => {
        if (!readonly) {
          setHoverValue(rate);
        }
      }}
      onFocus={() => {
        if (!readonly) {
          setHoverValue(rate);
        }
      }}
      onMouseLeave={() => {
        if (!readonly) {
          setHoverValue(null);
        }
      }}
      $isSelected={isSelected}
      $isHovered={!disabled && isHovered}
      $blockHeight={blockHeight}
      tabIndex={0}
      $gap={blockGap}
      className={className}
      $isExpected={isExpected}
      $disabled={disabled}
    >
      {isBig && <RatingScaleText $isSelected={isSelected}>{label}</RatingScaleText>}
      {isExpected && (
        <ExpectedBlock>
          <Trans>Expected level</Trans>
        </ExpectedBlock>
      )}
    </RateBlock>
  );
};

export default React.memo(RatingBlock);
