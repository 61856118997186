import { ROLES } from '@learned/constants';
import { matchPath } from 'react-router';

import pagesToCollapseMenu from './pagesToCollapseMenu';

import routes from '~/constants/routes';
import getCoachTeams from '~/utils/getCoachTeams';

interface AutoCollapseMenuParams {
  role: ROLES;
  companyId: string;
  teamId?: string;
  teams: any;
  userId: string;
  location: Location;
  isSecondaryMenuOpen: boolean;
}

function isPageToCollapseWithCondition(
  location: Location,
  companyId: string,
  teamId: string,
  userId: string,
  teams: any,
): boolean {
  const teamRoute = routes.TEAM_COACH.build({ companyId, role: ROLES.COACH, teamId });

  return location.pathname === teamRoute && getCoachTeams(userId, teams, companyId).length > 1;
}

export const autoCollapseMenu = ({
  role,
  companyId,
  teamId,
  teams,
  userId,
  location,
  isSecondaryMenuOpen,
}: AutoCollapseMenuParams): boolean => {
  teamId = teamId || ':teamId';

  const isPageToCollapse = pagesToCollapseMenu({
    role,
    companyId,
    teamId,
  }).some((path) => matchPath(location.pathname, { path }));

  const isPageToCollapseWithSpecialCondition = isPageToCollapseWithCondition(
    location,
    companyId,
    teamId,
    userId,
    teams,
  );
  const isAdminPages = location.pathname.indexOf(`company/${companyId}/admin`) !== -1;
  const isSuperAdminPages = location.pathname.indexOf('superadmin') !== -1;

  return (
    isPageToCollapse ||
    isPageToCollapseWithSpecialCondition ||
    isAdminPages ||
    isSuperAdminPages ||
    isSecondaryMenuOpen
  );
};
