import React, { useState, useEffect } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { withRouter } from 'react-router-dom';

import OverviewHeading from '~/components/OverviewHeading';
import BaseLayout from '~/layouts/BaseLayout';

import { ROLES, RT_FEEDBACK_SUBTYPES } from '~/constants';
import routes from '~/constants/routes';
import { getRTFeedback } from '~/services/RTFeedbacks';

import RTFeedbackCard from '../RTFeedbacks/components/RTFeedbackCard';

export const RTFeedbackProvided = ({ i18n, match, history }) => {
  const [loading, setLoading] = useState(true);
  const [RTFeedback, setRTFeedback] = useState({});
  const { rtfeedbackId } = match.params;
  const params = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const backPath = params.from || routes.RTFEEDBACKS_PROVIDED.build({ role: ROLES.USER });

  useEffect(() => {
    const mount = async () => {
      const userRTFeedback = await getRTFeedback(rtfeedbackId, { populate: ['goal'] });
      setRTFeedback(userRTFeedback);
      setLoading(false);
    };
    mount();
  }, [rtfeedbackId]);

  const onShareFeedback = ({ feedback }) => {
    setRTFeedback({ ...RTFeedback, feedback });
    history.goBack();
  };

  const onRemoveRequest = () => {
    history.push(backPath);
  };

  // TODO (check for Kate) delete when RT_FB page for different subTypes is ready
  const allowedSubTypes = [RT_FEEDBACK_SUBTYPES.GOAL.key];
  return (
    <>
      <OverviewHeading smallWidth={true} title={i18n._(t`Feedback`)} backPath={backPath} />
      <BaseLayout smallWidth={true}>
        {!loading && !isEmpty(RTFeedback) && allowedSubTypes.includes(RTFeedback.subType) && (
          <RTFeedbackCard
            RTFeedback={RTFeedback}
            onShareFeedback={onShareFeedback}
            onRemoveRequest={onRemoveRequest}
          />
        )}
      </BaseLayout>
    </>
  );
};

export default withI18n()(withRouter(RTFeedbackProvided));
