import React from 'react';

import { t } from '@lingui/macro';

import type { IColumnTable } from '~/@types/table';

import type { ITeam } from '@learned/types';
import type { I18n } from '@lingui/core';

export const getColumns = () => {
  const columns: IColumnTable<ITeam>[] = [
    {
      name: (i18n: I18n) => i18n._(t`Teams`),
      accessor: 'name',
      renderCell: (item) => {
        return <span>{item.name}</span>;
      },
    },
  ];

  return columns;
};
