import React, { useCallback } from 'react';
import type { Dispatch } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import debounce from 'lodash/debounce';
import size from 'lodash/size';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';

import Editor from '~/components/Editor';
import { IconOld } from '~/components/IconOld';
import { Input } from '~/components/Input';
import { Errors } from '~/pages/SurveyTemplateUpdate/validation';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';

import { Footer, Form, InputContainer, Label, SubmitButton, Title } from '../design';

import type { IGeneralForm } from '../types';
import type { UseFormReturn } from 'react-hook-form';

export interface IGeneralProps {
  formMethods: UseFormReturn<IGeneralForm>;
  setCurrentSection: Dispatch<number>;
  languageState: ILanguageStateReturn;
  triedToSubmit: boolean;
  autosave: () => void;
}

const StyledFooter = styled(Footer)`
  justify-content: flex-end;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 26px;
`;

const exampleText = `
Example: As a valued member of our team, your feedback is extremely important to us. This annual satisfaction survey is a critical tool for us to understand your perceptions, opinions and overall satisfaction about your work at our company.

By taking the time to participate in this survey, you will be helping us to identify areas where we can improve our support, benefits, and work environment. Your responses will be kept confidential and used only for the purpose of making positive changes within our company.
`;

const General = ({
  setCurrentSection,
  formMethods,
  languageState,
  triedToSubmit,
  autosave,
}: IGeneralProps) => {
  const { i18n } = useLingui();
  const {
    register,
    unregister,
    control,
    trigger,
    formState: { errors },
  } = formMethods;
  const nameFieldArray = useMultiLangFieldArray({
    name: 'name',
    control,
    unregister,
    languageState,
  });
  const descriptionFieldArray = useMultiLangFieldArray({
    name: 'description',
    control,
    unregister,
    languageState,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdateTemplate = useCallback(
    debounce(() => autosave(), 10_000),
    [],
  );

  const onSubmit = () => setCurrentSection(1);

  return (
    <Form>
      <Title>
        <Trans>General</Trans>
      </Title>
      <Label>{i18n._(t`Name*`)}</Label>
      <InputContainer>
        {nameFieldArray.fields.map((field) => (
          <Controller
            key={field.id}
            {...register(`name.${field.index}.value`)}
            control={control}
            render={({ field: { onBlur, onChange, value } }) => {
              const primaryLangError =
                errors.name?.message === Errors.missingPrimaryLang &&
                field.locale === languageState.companyPrimaryLanguage.locale;
              return (
                <Input
                  error={
                    primaryLangError && triedToSubmit
                      ? i18n._(t`This field is required`)
                      : undefined
                  }
                  value={value}
                  onBlur={async () => {
                    onBlur();
                    autosave();
                    await trigger('name');
                  }}
                  onChange={async (...args) => {
                    onChange(...args);
                    debounceUpdateTemplate();
                    await trigger('name');
                  }}
                  key={field.id}
                  placeholder={i18n._(t`Example: Annual satisfaction survey`)}
                  width="281px"
                  leftIcon={
                    size(languageState.languages) > 1
                      ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                      : undefined
                  }
                />
              );
            }}
          />
        ))}
      </InputContainer>
      <DescriptionWrapper>
        <Label>
          <Trans>Introduction for the participant</Trans>
        </Label>
        <InputContainer>
          {descriptionFieldArray.fields.map((field) => (
            <Controller
              key={field.id}
              {...register(`description.${field.index}.value`)}
              control={control}
              render={({ field: { onBlur, onChange, value } }) => (
                <Editor
                  minHeight={'250px'}
                  placeholder={i18n._(t`${exampleText}`)}
                  value={value}
                  compact
                  onChange={(...args: unknown[]) => {
                    onChange(...args);
                    debounceUpdateTemplate();
                  }}
                  onBlur={() => {
                    onBlur();
                    autosave();
                  }}
                  big
                  leftIcon={
                    size(languageState.languages) > 1
                      ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                      : undefined
                  }
                />
              )}
            />
          ))}
        </InputContainer>
      </DescriptionWrapper>
      <StyledFooter>
        <SubmitButton type="button" onClick={onSubmit}>
          <Trans>Next</Trans>
          <IconOld name="ChevronForward" width={16} height={16} className="icon" />
        </SubmitButton>
      </StyledFooter>
    </Form>
  );
};

export { General };
