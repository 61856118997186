import React from 'react';

import { SURVEY_QUESTION_TYPES } from '@learned/constants';

import { QuestionWrapper } from '~/components/SurveyQuestionReport/components/QuestionWrapper';

import { StarsGraph } from './StarsGraph';

export interface IItem {
  type: SURVEY_QUESTION_TYPES;
  title: string;
  titleNumber: string;
  themeName: string;
  answers: {
    label?: string;
    isValue: boolean;
    value?: number | string;
    percentage?: number | string;
  }[];
  isAnswersHidden: boolean;
}

interface ISurveyStarsQuestionProps {
  item: IItem;
}

const SurveyStarsQuestion = ({ item }: ISurveyStarsQuestionProps) => {
  return (
    <QuestionWrapper item={item}>
      <StarsGraph item={item} />
    </QuestionWrapper>
  );
};

export { SurveyStarsQuestion };
