import React from 'react';

import { Oval, OvalWrapper } from './design';

export const OvalCard = () => {
  return (
    <OvalWrapper>
      <Oval />
    </OvalWrapper>
  );
};
