import React from 'react';

import styled from 'styled-components';

import RickTextView from '~/components/RickTextView';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  color: ${COLORS.BLACK};
`;

const AboveDone = styled.div`
  display: flex;
  align-items: center;
  z-index: 1; // we need this to show answer above "done" background
`;

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  height: 38px;
  margin-right: 16px;
`;

const Icon = styled.div`
  display: inline-flex;
  align-items: center;
  width: 36px;
  font-size: 35px;
`;

const Light = styled.div<{ color?: string }>`
  border-radius: 100px;
  min-width: 32px;
  min-height: 32px;
  background-color: ${({ color }) => color || 'unset'};
`;

const Label = styled.div``;

const Box = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 51px 5px 13px;
  min-height: 48px;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: ${COLORS.HOVER};
`;

const Done = styled.div<{ percentage?: number | string }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${(props) => props.percentage || 0}%;
  border-radius: 6px;
  background-color: ${COLORS.GREEN_LIGHT};
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  width: 70px;
  min-width: 70px;
  margin-left: 30px;

  & > div:first-child {
    margin-right: 5px;
  }
`;

interface IOptionProps {
  label?: string;
  isValue: boolean;
  value?: string | number;
  percentage?: string | number;
  icon?: string;
  light?: string;
}

const Answer = ({ icon, light, label, isValue, value, percentage }: IOptionProps) => {
  return (
    <Wrapper>
      <Box>
        <AboveDone>
          {(icon || light) && (
            <IconWrapper>
              {icon && <Icon>{icon}</Icon>} {/* for smileys*/}
              {light && <Light color={light} />} {/* for trafficLight*/}
            </IconWrapper>
          )}
          {label && (
            <Label>
              {/* @ts-ignore*/}
              <RickTextView html={label} />
            </Label>
          )}
        </AboveDone>
        <Done percentage={percentage} />
      </Box>
      {isValue && (
        <Details>
          {value && <div>{value}</div>}
          {percentage && <div>{`(${percentage}%)`}</div>}
        </Details>
      )}
    </Wrapper>
  );
};

export { Answer };
