import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Modal from '~/components/Modal';
import Notebook from '~/components/Notebook';
import SvgIcon from '~/components/SvgIcon';

import CloseIcon from '~/assets/ic-close-18-px.svg';

const HeaderActions = styled.div`
  display: flex;
`;

const CloseIconWrapper = styled.div`
  margin-left: 13px;
  cursor: pointer;
`;

class NotebookModal extends Component {
  static propTypes = {
    onClose: PropTypes.func,
  };

  render() {
    const { i18n, onClose } = this.props;

    return (
      <Modal
        className="new-team-modal"
        title={i18n._(t`Private notes`)}
        onClose={onClose}
        width={750}
        minWidth={750}
        minHeight="600px"
        contentStyles={{ background: '#fff', height: '100%', padding: '0 16px' }}
        headerStyles={{ borderBottom: 'solid 1px #ecedf0', padding: '0 16px' }}
        hideHeaderClose={true}
        headerActions={() => (
          <HeaderActions>
            <CloseIconWrapper onClick={onClose}>
              <SvgIcon width="16px" height="16px" url={CloseIcon} />
            </CloseIconWrapper>
          </HeaderActions>
        )}
        hideFooter={false}
        footerCenter={false}
        footerLeft={true}
      >
        <Notebook />
      </Modal>
    );
  }
}

export default withI18n()(withRouter(NotebookModal));
