import React from 'react';

import { GOAL_PROGRESS_TYPES, SYMBOLS, GOALS_UPDATES_TARGET_TYPE } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import Editor from '~/components/Editor';
import IconMenu from '~/components/IconMenu';
import Placeholder from '~/components/Placeholder';
import RickTextView from '~/components/RickTextView';
import Avatar from '~/components/UI/Avatar';

import { GOAL_STATUSES } from '~/constants';
import { getUser, getUsers, getDeletedUsers } from '~/selectors/baseGetters';
import { COLORS, COLOR_PALETTE } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  & .icon {
    rotate: 90deg;
  }
`;

const ProgressStatus = styled.span`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${(props) => props.color};
`;

const RichTextViewWrapper = styled(RickTextView)`
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  margin-bottom: 8px;
`;

const SaveButton = styled(Button)`
  position: absolute;
  bottom: 43px;
  right: 24px;
  min-width: 40px;
`;

const StyledEditor = styled(Editor)`
  .ql-editor {
    padding: 12px 74px 12px 12px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
  }
`;

const TextInputContainer = styled.div`
  padding: 16px;
  position: relative;
`;

const Title = styled.div`
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLORS.TEXT_BLACK};
  margin-left: 9px;

  span {
    color: ${COLORS.TEXT_SECONDARY};
  }
`;

const UpdateCommentBlock = styled.div`
  margin-left: 28px;
  padding: 12px 12px 9px;
  box-sizing: border-box;
  border-radius: 3px;
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.25px;
`;

const UpdateDate = styled.span`
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.25px;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const UpdateInfo = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-right: 8px;
  & .content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const UpdateWrapper = styled.div`
  margin-bottom: 20px;
  height: fit-content;

  &:last-child {
    margin-bottom: 0px;
  }
`;

function getSymbol(progress) {
  switch (progress.type) {
    case GOAL_PROGRESS_TYPES.AVG:
    case GOAL_PROGRESS_TYPES.PERCENTAGE: {
      return '%';
    }
    case GOAL_PROGRESS_TYPES.CURRENCY: {
      return SYMBOLS[progress.currency];
    }
    default: {
      return '';
    }
  }
}

const Update = ({
  update,
  goalName,
  activityName,
  i18n,
  isOwner,
  updateGoalsUpdate,
  updateCommentText,
  updateCommentFunction,
  isEditMode,
}) => {
  // user and update item name
  const userName = update.userName;
  const isActivityUpdate = update.targetType === GOALS_UPDATES_TARGET_TYPE.ACTIVITY;
  const name = isActivityUpdate ? activityName || update.target?.name : goalName;

  // status
  const statuses = Object.values(GOAL_STATUSES);
  const fromStatus = statuses.find((s) => s.key === update?.status?.old);
  const toStatus = statuses.find((s) => s.key === update?.status?.new);

  // progress
  let fromProgress = null;
  let toProgress = null;
  let symbol = null;

  // If it is done type, leave progress fields as null, otherwise fill them
  if (!isEmpty(update.progress) && !(update.progress.type === GOAL_PROGRESS_TYPES.IS_DONE)) {
    symbol = getSymbol(update.progress);
    fromProgress = update.progress.old;
    toProgress = update.progress.new;
  }

  // update date
  const updateDate = get(update, 'meta.lastModifiedDate', get(update, 'meta.createdDate'));
  const currentDate = moment().startOf('day');
  const lastUpdateDate = moment(updateDate).startOf('day');
  const days = currentDate.diff(lastUpdateDate, 'days');
  const isToday = days === 0;
  const menuItems = isOwner && [
    {
      label: i18n._(t`Update`),
      action: () => updateGoalsUpdate(update.id),
    },
  ];

  const renderCommentInput = () => (
    <TextInputContainer>
      <StyledEditor
        key={update.id}
        small={true}
        compact={true}
        onChange={(e) => updateCommentText(e, update.id)}
        value={update.comment}
      />
      <SaveButton onClick={() => updateCommentFunction(update.id)} label={i18n._(t`Save`)} />
    </TextInputContainer>
  );

  return (
    <UpdateWrapper>
      <Header>
        <UpdateInfo>
          <Avatar userId={update.createdBy} />
          <div className="content">
            <HeaderInfo>
              <Title>
                <span>{userName}</span>
              </Title>
              {!isEditMode && (
                <UpdateDate>
                  {isToday ? (
                    <Trans>Today</Trans>
                  ) : (
                    <Trans>{moment(updateDate).format('DD-MM-YYYY')}</Trans>
                  )}
                </UpdateDate>
              )}
            </HeaderInfo>
            <Title>
              {fromProgress === null && isEmpty(fromStatus) && !isEmpty(update.comment) ? (
                <Trans>added a comment to</Trans>
              ) : (
                <Trans>updated</Trans>
              )}{' '}
              <span>{name}</span>{' '}
              {fromProgress !== null && (
                <>
                  <Trans>from</Trans>{' '}
                  <span>
                    {fromProgress}
                    {symbol}
                  </span>{' '}
                  <Trans>to</Trans>{' '}
                  <span>
                    {toProgress}
                    {symbol}
                  </span>{' '}
                </>
              )}
              {fromProgress !== null && fromStatus && (
                <>
                  <Trans>and</Trans>{' '}
                </>
              )}
              {fromStatus && (
                <>
                  <Trans>status from</Trans>{' '}
                  <ProgressStatus color={fromStatus?.color}>
                    {fromStatus?.title(i18n)}
                  </ProgressStatus>{' '}
                  <Trans>to</Trans>{' '}
                  <ProgressStatus color={toStatus?.color}>{toStatus?.title(i18n)}</ProgressStatus>
                </>
              )}
            </Title>
          </div>
        </UpdateInfo>
        {!isEmpty(menuItems) && <IconMenu iconClassName="icon" items={menuItems} />}
      </Header>
      {isEditMode ? (
        renderCommentInput()
      ) : (
        <UpdateCommentBlock>
          {update.comment && <RichTextViewWrapper html={update.comment} />}
        </UpdateCommentBlock>
      )}
    </UpdateWrapper>
  );
};

const UpdatesBlock = ({
  updates,
  goalName,
  activityName,
  updateGoalsUpdate,
  updateComments = [],
  updateCommentText,
  updateCommentFunction,
  isReadOnly,
}) => {
  const { i18n } = useLingui();
  const user = useSelector(getUser);
  const allUsers = useSelector(getUsers);
  const deletedUsers = useSelector(getDeletedUsers);
  const users = { ...allUsers, ...deletedUsers };
  const goalsUpdates = updates.map((u) => {
    const updateUser = users[u.createdBy];
    const userName = getUserFullName(updateUser);
    return {
      ...u,
      userName,
    };
  });
  return (
    <>
      {!isEmpty(goalsUpdates) ? (
        goalsUpdates.map((u) => (
          <Update
            key={u.id}
            update={u}
            goalName={goalName}
            activityName={activityName}
            i18n={i18n}
            isOwner={!isReadOnly && user.id === u.createdBy}
            updateGoalsUpdate={updateGoalsUpdate}
            isEditMode={updateComments.includes(u.id)}
            updateCommentText={updateCommentText}
            updateCommentFunction={updateCommentFunction}
          />
        ))
      ) : (
        <Placeholder
          title={i18n._(t`No updates`)}
          subTitle={i18n._(t`A log of your activity and goal updates will show here`)}
        />
      )}
    </>
  );
};

export default UpdatesBlock;
