import styled, { css } from 'styled-components';

import { Dropdown } from '~/components/Dropdown';
import { SliderAnimation, Option, Wrapper } from '~/components/Dropdown/design';

import { COLORS } from '~/styles';

export const StyledDropdown = styled(Dropdown)`
  width: fit-content;
  min-width: 73px;

  ${SliderAnimation} {
    width: 191px;
  }

  ${Wrapper} {
    min-width: unset;
    max-width: unset;
  }
` as typeof Dropdown;

export const Row = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  align-self: center;
  padding: 3px 15px 3px 15px;
  border-radius: 6px;
  gap: 10px;
  box-sizing: border-box;
  cursor: pointer;

  svg {
    min-width: 11px;
    min-height: 11px;
    width: 11px;
    height: 11px;
  }

  &:hover {
    height: 32px;
    background-color: ${COLORS.INFO_LIGHT};
  }
`;

export const StyledOption = styled(Option)<{ $disabled?: boolean }>`
  gap: 15px;
  color: ${COLORS.TEXT_MAIN};

  ${(props) =>
    props.$disabled &&
    css`
      color: ${COLORS.INACTIVE};
      cursor: not-allowed;

      :hover {
        color: ${COLORS.INACTIVE};
        cursor: not-allowed;
        background-color: ${COLORS.WHITE};
      }

      svg {
        color: ${COLORS.INACTIVE};
      }
    `}

  svg {
    min-width: 20px;
    min-height: 20px;
  }
`;

export const SelectedLang = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const LanguageCounter = styled.span`
  font-size: 14px;
  letter-spacing: -0.16px;
  text-align: center;
  color: ${COLORS.SUBTEXT};
  width: 23px;
`;

export const Flag = styled.span`
  font-size: 23px;
  color: ${COLORS.TEXT_HOVER};
  text-align: center;
`;
