import React, { Component } from 'react';

import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Spinner from '~/components/Spinner';

import { COLOR_PALETTE } from '~/styles';

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 102;
  background-color: rgba(0, 0, 0, 0.35);
`;

const Content = styled.div`
  width: 400px;
  height: 166px;
  border-radius: 10px;
  box-shadow: 5px 10px 14px 0 rgba(55, 70, 95, 0.08);
  border: solid 0.5px #ecedf0;
  background-color: ${COLOR_PALETTE.WHITE};
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  text-align: center;
  color: #3b3b3b;
  margin-top: 40px;
  margin-bottom: 8px;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  text-align: center;
  color: var(--company-color);
`;

class SpinnerStart extends Component {
  static propTypes = {
    label: PropTypes.string,
  };

  render() {
    return (
      <Wrapper>
        <Content>
          <Title>
            <Trans>Loading your:</Trans>
          </Title>
          <Description>{this.props.message}</Description>
          <Spinner styles={{ height: '50px' }} />
        </Content>
      </Wrapper>
    );
  }
}

export default withI18n()(SpinnerStart);
