import React, { forwardRef } from 'react';
import type { ComponentType } from 'react';

import { ToastConsumer } from 'react-toast-notifications';

import type { ToastManager } from '~/components/Toast/types';

function withToasts<T extends { toasts: ToastManager }>(OriginalComponent: ComponentType<T>) {
  return forwardRef<unknown, Omit<T, 'toasts'>>((props, ref) => (
    <ToastConsumer>
      {(context) => (
        <OriginalComponent
          {...(props as T)}
          toasts={{
            add: ({ title, subtitle, type }, options) =>
              context.add(title, { subtitle, type, ...options }),
            remove: (toastId, cb) => context.remove(toastId, cb),
          }}
          ref={ref}
        />
      )}
    </ToastConsumer>
  ));
}

export { withToasts };
