import React from 'react';

import { IActivity } from '@learned/types';

import Dialog from '~/components/Dialog';

import { ActivityProgressCard } from './ActivityProgressCard';

interface IProps {
  onClose: () => void;
  activityId: string;
  isProgressEditable: boolean;
  updateActivity: (activityId: string, data: IActivity) => void;
}

const ActivityProgressModal = ({
  onClose,
  activityId,
  isProgressEditable,
  updateActivity,
}: IProps) => {
  return (
    <Dialog borderRadius={10} width={750}>
      <ActivityProgressCard
        activityId={activityId}
        onClose={onClose}
        isProgressEditable={isProgressEditable}
        updateActivity={updateActivity}
      />
    </Dialog>
  );
};

export { ActivityProgressModal };
