import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import intersectionBy from 'lodash/intersectionBy';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import sumBy from 'lodash/sumBy';
import union from 'lodash/union';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  AutocompleteFilterMembers,
  AutocompleteFilterTeams,
  AutocompleteFilterRoles,
} from '~/components/AutocompleteFilters';
import { Button } from '~/components/Buttons/Button';
import { ButtonVariant, ButtonSize } from '~/components/Buttons/types';
import { Dropdown } from '~/components/Dropdown';
import { ICONS } from '~/components/Icon';
import SvgIcon from '~/components/SvgIcon';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import Tooltip from '~/components/Tooltip';
import Avatar from '~/components/UI/Avatar';
import BoxWithShadow from '~/components/UI/BoxWithShadow';

import { ListViewComponent } from './components/ListViewComponent';
import { AutoCompleteReviewsFilter } from './components/ReviewsAutoCompleteFilter';
import UpdateNineGridLabelsModal from './components/UpdateNineGridLabelsModal';
import {
  INineGridReview,
  IListItem,
  INineGridReviewTheme,
  INineGridUserReview,
  INineGridLabel,
} from './types';

import ninegridIcon from '~/assets/ninegrid.svg';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import getAllUsers from '~/selectors/getAllUsers';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import {
  fetchReviewNineGridMatrix,
  downloadNineGridCSV,
} from '~/services/reviews/reports/ninegrid';
import { setCompanyLabels } from '~/store/companies/actions';
import { COLOR_PALETTE, COLORS } from '~/styles';
import BrowserStorage from '~/utils/browserStorage';

import CollapseIcon from '../Icons/Collapse';
import HandPointingIcon from '../Icons/HandPointing';
import Placeholder from '../Placeholder';

import type { ITeam, IUser, IJobProfile } from '@learned/types';

export const REPORT_VIEW_TYPES = {
  GRID: 'grid',
  LIST: 'list',
};

const NINE_GRID_HEIGHT = 500;

const BoxWrapper = styled(BoxWithShadow)`
  display: flex;
  border-color: transparent;
  align-items: stretch;
  border-radius: 10px;
`;

const Sidebar = styled.div<{ isOpen: boolean }>`
  padding: 24px 22px 33px;
  border-right: 1px solid ${COLORS.TAB_WRAPPER_GRAY};
  max-width: 204px;
  box-sizing: border-box;
  position: relative;
  min-height: 624px;
  transition: all ease-in 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      transition: all ease-in 0.3s;
      justify-content: flex-end;

      & .content {
        display: flex;
        flex: 1;
      }
    `}

  & .top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  & .content {
    flex: 1;
  }

  & .collapseButton {
    display: flex;
    align-items: flex-end;
  }
`;

const Main = styled.div`
  width: 100%;
  overflow-x: auto;

  & .placeholderNoData {
    margin: 184px auto;
    width: 270px;
    color: ${COLORS.GRAY_MIDDLE};
  }

  & .placeholderEmpty {
    margin: 184px auto;
    width: 255px;
    color: ${COLORS.ACTIVITY_GREY};
  }

  & .grid {
    padding: 24px 48px 24px 24px;
  }

  & .list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  gap: 10px;
  border-bottom: 1px solid ${COLORS.TAB_WRAPPER_GRAY};
`;

const Title = styled.div<{ isRotated: boolean }>`
  font-size: 14px;
  line-height: -0.16px;
  color: black;
  color: ${COLORS.ACTIVITY_GREY};
  margin-bottom: 60px;

  ${({ isRotated }) =>
    !isRotated &&
    css`
      position: absolute;
      bottom: 52px;
      transform-origin: 0 0;
      margin-bottom: 0px;
      transform: rotate(-90deg) translate(0);
      min-width: 200px;
    `}
`;

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid ${COLORS.TAB_WRAPPER_GRAY};
  margin-bottom: 23px;
  width: 100%;
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  & .title {
    font-size: 14px;
    color: ${COLORS.TEXT_HOVER};
    margin-bottom: -2px;
  }
  margin-bottom: 24px;
`;

const NineGridWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

const YAxis = styled.div<{ width?: number }>`
  display: block;
  width: ${({ width }) => width ?? '66px'};
`;

const Rotation = styled.div<{
  transformRotate?: number;
  transformOrigin?: string;
  marginLeft?: string;
  transformTranslate?: number;
}>`
  transform-origin: ${({ transformOrigin }) => transformOrigin || '0 0'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transform: ${({ transformRotate, transformTranslate }) =>
    `rotate(${transformRotate ?? -90}deg) translate(${transformTranslate ?? -100}%)`};
  margin-left: ${({ marginLeft }) => marginLeft ?? 'unset'};
  width: ${NINE_GRID_HEIGHT}px;
  height: 66px;
`;

const XAxis = styled.div`
  height: 72px;
  width: calc(100% - 66px);
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  gap: 26px;
`;

const GraphTitle = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${({ color }) => (color ? color : COLORS.TEXT_HOVER)};
`;

const GraphLabels = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const GraphLabel = styled.div<{
  $justifyContent?: string;
}>`
  display: flex;
  justify-content: ${(props) => props.$justifyContent};
  align-items: center;
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: ${COLOR_PALETTE.DARK_GRAY};
  text-transform: uppercase;
`;

const NineGridContainerContainer = styled.div`
  position: relative;
  flex: 1;
  height: ${NINE_GRID_HEIGHT}px;
  display: flex;
  flex-direction: column-reverse;
`;

const StyledAutocompleteFilterReviews = styled(AutoCompleteReviewsFilter)`
  ${(props) => props.disabled && 'cursor: not-allowed;'}
  height: 32px;
  border-color: ${(props) => props.$error && 'red'};
`;

const StyledAutocompleteFilterTeams = styled(AutocompleteFilterTeams)`
  height: 32px;
`;

const StyledAutocompleteFilterMembers = styled(AutocompleteFilterMembers)`
  height: 32px;
`;

const StyledAutocompleteFilterRoles = styled(AutocompleteFilterRoles)`
  height: 32px;
`;

const ExtraCounter = styled.div<{
  $bottom?: number;
  $left?: number;
}>`
  position: absolute;
  left: min(calc(${(props) => props.$left}%), calc(100% - 28px));
  bottom: min(calc(${(props) => props.$bottom}%), calc(100% - 28px));
  z-index: 2;
`;

const AvatarContainer = styled.div<{
  $bottom: null | number;
  $left: null | number;
  $blur?: boolean | string | null;
  borderColor: string | null;
}>`
  position: absolute;
  visibility: ${(props) => (!props.$left && !props.$bottom ? 'hidden' : 'visible')};
  left: min(calc(${(props) => props.$left}%), calc(100% - 28px));
  bottom: min(calc(${(props) => props.$bottom}%), calc(100% - 28px));
  z-index: 2;
  filter: ${(props) => props.$blur && 'blur(4px)'};
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-radius: 3px;
  border-color: ${({ borderColor }) => borderColor || 'unset'};
`;

const SecondReviewAvatarContainer = styled(AvatarContainer)`
  z-index: 1;
`;

const Chip = styled.div<{
  background?: string;
}>`
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: ${({ background }) => background || 'unset'};
  color: ${COLOR_PALETTE.WHITE};
  position: absolute;
  right: -10px;
  top: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
`;

const GridRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const GridColumn = styled.div<{
  $index: number;
}>`
  flex: 1;
  padding: 12px;
  background-color: ${(props) => (props.$index % 2 ? 'white' : COLORS.BG_PAGE)};
  font-size: 14px;
  max-width: 300px;
  font-weight: 600;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const SquareName = styled.div`
  width: 100%;
  cursor: default;

  & > small {
    margin-left: 5px;
  }
`;

const ApplyButton = styled(Button)`
  width: 100%;
  height: 48px;
  margin-bottom: 22px;
`;

const ViewTypeButton = styled(Button)<{ isActive?: boolean }>`
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${COLORS.WHITE};
      border-color: ${COLORS.COMPANY_HOVER};
      color: ${COLORS.COMPANY_HOVER};
    `}
`;

const CollapseButton = styled.button<{ isRotated: boolean }>`
  color: var(--company-color);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0px;
  margin: 0px;
  cursor: pointer;

  ${({ isRotated }) =>
    !isRotated &&
    css`
      width: fit-content;
      & svg {
        rotate: 180deg;
      }
    `}
`;

const ThemeDropdown = styled(Dropdown)<{ isError?: boolean }>`
  border-radius: 6px;
  border: ${(props) => (props.isError ? `1px solid ${COLORS.ACCENT_ERROR}` : 'none')};
`;

const NineGridBox = () => {
  const { i18n } = useLingui();
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { ninegridLabels: nineGridLabels }: Record<string, INineGridLabel[]> =
    useSelector(getCurrentCompany);
  const users: { [key: string]: IUser } = useSelector(getAllUsers);

  const storage = new BrowserStorage('review.reports.nineGrid');

  // props to hold the dropdown selection
  const [primaryReviewOption, setPrimaryReviewOption] = useState<INineGridReview>();
  const [secondaryReviewOption, setSecondaryReviewOption] = useState<INineGridReview>();

  // props to hold the review
  const [primaryReview, setPrimaryReview] = useState<INineGridReview | null>();
  const [secondaryReview, setSecondaryReview] = useState<INineGridReview | null>();

  const [xTheme, setXTheme] = useState<INineGridReviewTheme>();
  const [yTheme, setYTheme] = useState<INineGridReviewTheme>();

  const [themes, setThemes] = useState<INineGridReviewTheme[]>([]);
  const [isXThemeError, setIsXThemeError] = useState(false);
  const [isYThemeError, setIsYThemeError] = useState(false);
  // const [selectedReview, setSelectedReview] = useState(undefined);
  const [loadedFromParam, setLoadedFromParam] = useState(0);
  // const [secondSelectedReview, setSecondSelectedReview] = useState(undefined);
  // const [reviewWithData, setReviewWithData] = useState(undefined);
  // const [secondReviewWithData, setSecondReviewWithData] = useState(undefined);
  // const [xKPI, setXKPI] = useState('');
  // const [yKPI, setYKPI] = useState('');
  const [selectedTeams, setSelectedTeams] = useState<ITeam[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<IUser[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<IJobProfile[]>([]);

  const [hovered, setHovered] = useState('');
  // const [kpis, setKpis] = useState([]);
  // const [isXKPIError, setIsXKPIError] = useState(false);
  // const [isYKPIError, setIsYKPIError] = useState(false);

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // const [KPIs, setKPIForCSV] = useState([]);
  const $showUpdateLabelsModal = useBoolState(false);

  const [viewType, setViewType] = useState(REPORT_VIEW_TYPES.GRID);

  const preparedFilters = useMemo(() => {
    let members = selectedMembers.map((member) => member.id);
    selectedTeams.forEach((team) => {
      members = union(members, team.members);
    });
    const jobProfiles = selectedRoles.map((role) => role.id);

    const filters: Record<string, string[]> = {};

    if (!isEmpty(members)) {
      filters.employees = members;
    }

    if (!isEmpty(jobProfiles)) {
      filters.jobProfiles = jobProfiles;
    }
    return size(filters) ? filters : null;
  }, [selectedMembers, selectedRoles, selectedTeams]);

  const onApplyButton = () => {
    if (!xTheme && !yTheme) {
      addToast({
        title: i18n._(t`Error`),
        subtitle: i18n._(t`Please select Values for X & Y axis`),
        type: TOAST_TYPES.ERROR,
      });
      setIsXThemeError(true);
      setIsYThemeError(true);
    } else if (xTheme && !yTheme) {
      addToast({
        title: i18n._(t`Error`),
        subtitle: i18n._(t`Please select Value for Y axis`),
        type: TOAST_TYPES.ERROR,
      });
      setIsXThemeError(false);
      setIsYThemeError(true);
    } else if (!xTheme && yTheme) {
      addToast({
        title: i18n._(t`Error`),
        subtitle: i18n._(t`Please select Value for X axis`),
        type: TOAST_TYPES.ERROR,
      });
      setIsXThemeError(true);
      setIsYThemeError(false);
    } else {
      setIsXThemeError(false);
      setIsYThemeError(false);
      fetchReview();
    }
  };

  const fetchReview = async () => {
    if (!primaryReviewOption || !xTheme || !yTheme) {
      return;
    }

    setIsLoading(true);

    const [primaryReviewResult, secondaryReviewResult] = await Promise.all([
      fetchReviewNineGridMatrix(primaryReviewOption.id, {
        themes: [xTheme.id, yTheme.id],
        ...preparedFilters,
      }),
      secondaryReviewOption &&
        fetchReviewNineGridMatrix(secondaryReviewOption.id, {
          themes: [xTheme.id, yTheme.id],
          ...preparedFilters,
        }),
    ]);

    if (primaryReviewResult?.data?.review) {
      setPrimaryReview(primaryReviewResult.data.review);
    }

    if (secondaryReviewResult?.data?.review) {
      setSecondaryReview(secondaryReviewResult.data.review);
    }

    if (primaryReviewResult?.data?.review && secondaryReviewResult?.data?.review) {
      constructURL(primaryReviewResult.data.review, secondaryReviewResult.data.review);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!secondaryReviewOption) {
      setSecondaryReview(undefined);
    }
  }, [secondaryReviewOption]);

  const constructURL = (primaryReview: INineGridReview, secondaryReview: INineGridReview) => {
    storage.setItem(
      'query',
      JSON.stringify({
        primaryReview: {
          id: primaryReview.id,
          name: primaryReview.name,
          themes: primaryReview.themes,
        },
        ...(secondaryReview && {
          secondaryReview: {
            id: secondaryReview.id,
            name: secondaryReview.name,
            themes: secondaryReview.themes,
          },
        }),
        xTheme,
        yTheme,
        selectedTeams: selectedTeams.map((team) => ({
          id: team.id,
          name: team.name,
          members: team.members,
        })),
        selectedMembers: selectedMembers.map((member) => ({
          id: member.id,
          name: `${member.firstName} ${member.lastName}`,
        })),
        selectedRoles: selectedRoles.map((role) => ({
          id: role.id,
          name: role.name,
        })),
      }),
    );
    const url = history.location.pathname + `?favorite=${!!primaryReviewOption}`;
    history.replace(url);
  };

  // init load
  useEffect(() => {
    // load favorite nine grid
    const params = new URLSearchParams(history.location.search);
    if (params.get('favorite') === 'true') {
      const nineGridFavoriteQuery = JSON.parse(storage.getItem('query') ?? '');
      if (nineGridFavoriteQuery) {
        onPrimaryReviewOptionSelect(nineGridFavoriteQuery.primaryReview);
        fillSecondaryReviewOption(nineGridFavoriteQuery.secondaryReview);
        setXTheme(nineGridFavoriteQuery.xTheme);
        setYTheme(nineGridFavoriteQuery.yTheme);
        setSelectedTeams(nineGridFavoriteQuery.selectedTeams);
        setSelectedMembers(nineGridFavoriteQuery.selectedMembers);
        setSelectedRoles(nineGridFavoriteQuery.selectedRoles);
        setLoadedFromParam(loadedFromParam + 1);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loadedFromParam <= 1) {
      fetchReview();
    }
    // eslint-disable-next-line
  }, [loadedFromParam]);

  const getError = () => {
    if (
      primaryReview &&
      primaryReviewOption &&
      primaryReview.id === primaryReviewOption.id &&
      xTheme &&
      yTheme
    ) {
      const xThemes = primaryReview?.themes?.filter((theme) => theme.id === xTheme.id);
      const yThemes = primaryReview?.themes?.filter((theme) => theme.id === yTheme.id);
      return isEmpty(xThemes) && isEmpty(yThemes);
    }
    return false;
  };

  const getGridColumns = (rowIndex: number) => {
    const columns = [];
    for (let i = 0; i < 3; i += 1) {
      const { value, percentage } = matrixArea[i][rowIndex];
      columns.push(
        <GridColumn $index={rowIndex + i}>
          <Tooltip
            disabled={!nineGridLabels[i * 3 + rowIndex].description}
            tooltip={nineGridLabels[i * 3 + rowIndex].description.replace(/<\/?[^>]+(>|$)/g, '')}
          >
            <SquareName>
              {nineGridLabels[i * 3 + rowIndex].label}
              <small>{value > 0 && i18n._(t`(${value} people | ${percentage.toFixed(1)}%)`)}</small>
            </SquareName>
          </Tooltip>
        </GridColumn>,
      );
    }

    return columns;
  };

  const getGridRows = () => {
    const rows = [];
    for (let i = 0; i < 3; i += 1) {
      rows.push(<GridRow>{getGridColumns(i)}</GridRow>);
    }
    return rows;
  };

  const onPrimaryReviewOptionSelect = (review: INineGridReview) => {
    if (review) {
      setPrimaryReviewOption({
        ...review,
        name: `A. ${review.name}`,
      });
      setThemes(review.themes);
    } else {
      setPrimaryReviewOption(undefined);
      setThemes([]);
    }
  };

  const fillSecondaryReviewOption = (review?: INineGridReview) => {
    if (review) {
      setSecondaryReviewOption({
        ...review,
        name: `B. ${review.name}`,
      });
    } else {
      setSecondaryReviewOption(undefined);
    }
  };

  const onSecondaryReviewOptionSelect = (selectedOption: INineGridReview) => {
    if (selectedOption && primaryReviewOption && selectedOption.id === primaryReviewOption.id) {
      addToast({
        title: i18n._(t`Attention`),
        subtitle: i18n._(t`You can't select the same review for both review 1 and review 2`),
        type: TOAST_TYPES.ERROR,
      });
      return;
    }

    if (selectedOption && primaryReviewOption) {
      const commonThemes = intersectionBy(selectedOption.themes, primaryReviewOption.themes, 'id');

      if (!commonThemes?.length) {
        addToast({
          title: i18n._(t`Attention`),
          subtitle: i18n._(t`Second review must have same KPI as first review`),
          type: TOAST_TYPES.INFO,
        });
        return;
      }

      setThemes(commonThemes || []);
    }

    fillSecondaryReviewOption(selectedOption);
  };

  const navigateToProfile = (userId: string) => {
    const backPath = encodeURIComponent(history.location.pathname + history.location.search);
    history.push(
      routes.USER_PUBLIC_SKILL_PASSPORT.build(
        {},
        { userId, isBackPath: true, hash: 'reviews', backPathDefault: backPath },
      ),
    );
  };

  const getValue = useCallback(
    (userReview: INineGridUserReview, theme: INineGridReviewTheme) =>
      userReview.themeAverage ? userReview.themeAverage[theme.id] : [],
    [],
  );

  // used for list view
  const getPureValue = useCallback(
    (userReview: INineGridUserReview, theme: INineGridReviewTheme) =>
      userReview.themeAverage?.[theme.id]?.[2],
    [],
  );

  // used for list view
  const getRatingLabelCountValue = useCallback(
    (userReview: INineGridUserReview, theme: INineGridReviewTheme) =>
      userReview.themeAverage?.[theme.id]?.[3],
    [],
  );

  /**
   * Splits ratingsLabelsCount to given chunks
   * @param ratingsLabelsCount company labels count
   * @param chunks chunks, 3 for nine grid
   * @returns array of chunk
   *
   * Example partitionAndCumulativeSum(4, 3)
   * [2.333333333333333, 3.666666666666666, 4.999999999999999]
   */
  const partitionAndCumulativeSum = (ratingsLabelsCount: number, chunks: number) => {
    // Calculate the base partition value
    const partitionValue = ratingsLabelsCount / chunks;

    const cumulative: number[] = [];

    // Variable to store the running total (cumulative sum)
    let sum = 1;

    // Loop to calculate each cumulative value
    for (let i = 0; i < chunks; i++) {
      sum += partitionValue;
      cumulative.push(sum);
    }

    return cumulative;
  };

  const prepareIndex = (values: number[]): number => {
    if (!values) {
      return 0;
    }

    const [average, ratingsLabelsCount] = values;

    // 2nd param for chunks is fixed. Nine Grid has 3 partitions
    // get the possible ranges between 1 - 5, that is 4
    // therefore ratingsLabelsCount - 1
    const [min, mid] = partitionAndCumulativeSum(ratingsLabelsCount - 1, 3);

    // when the rating labels count is 5
    // average less than 2.333333333333333 => 1st Cell
    // average between 2.33333333333334 - 3.666666666666666 => 2nd Cell
    // average greater than 3.666666666666666 => 3rd Cell

    if (!average || average <= min) {
      return 0;
    } else if (average > min && average <= mid) {
      return 1;
    }

    return 2;
  };

  const findIndexes = (x: number[], y: number[]) => [prepareIndex(x), prepareIndex(y)];

  const matrixArea = useMemo(() => {
    let matrix: {
      value: number;
      percentage: number;
      users: { userId: string; reviewId: string }[];
    }[][] = [];

    // 1. make a 2D array with default value
    for (let i = 0; i < 3; i++) {
      matrix[i] = [];
      for (let j = 0; j < 3; j++) {
        matrix?.[i]?.push({ value: 0, percentage: 0, users: [] });
      }
    }

    // 2. count users and append numbers of firstReview
    if (xTheme && yTheme && primaryReview) {
      primaryReview?.userReviews.map((userReview) => {
        const [x, y] = findIndexes(getValue(userReview, xTheme), getValue(userReview, yTheme));
        const userId = userReview.createdFor;
        const reviewId = userReview.reviewId;

        matrix[x][y].value += 1;

        // keep userId and originalReviewId of first review
        if (
          !matrix?.[x]?.[y]?.users.find(
            (item) => item.userId === userId && item.reviewId === reviewId,
          )
        ) {
          matrix?.[x]?.[y]?.users.push({
            userId,
            reviewId,
          });
        }
      });
    }

    // 3. count users and append numbers of secondReview
    if (xTheme && yTheme && secondaryReview) {
      secondaryReview?.userReviews.map((userReview) => {
        const [x, y] = findIndexes(getValue(userReview, xTheme), getValue(userReview, yTheme));
        const userId = userReview.createdFor;
        const reviewId = userReview.reviewId;

        matrix[x][y].value += 1;

        if (
          !matrix?.[x]?.[y]?.users.find(
            (item) => item.userId === userId && item.reviewId === reviewId,
          )
        ) {
          matrix?.[x]?.[y]?.users.push({
            userId,
            reviewId,
          });
        }
      });
    }

    // 4. calculate the total number of users
    const total = matrix.reduce((previous, current) => {
      return previous + sumBy(current, 'value');
    }, 0);

    // 5. append correct percentage for each block of matrix
    matrix = matrix.map((row) => {
      return row.map((item) => ({
        ...item,
        percentage: (item.value / total) * 100,
      }));
    });

    return matrix;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify({
      primaryReviewUserReviews: primaryReview?.userReviews,
      secondaryReviewUserReviews: secondaryReview?.userReviews,
      xThemeId: xTheme?.id,
      yTheme: yTheme?.id,
      isLoading,
    }),
  ]);

  // hide kpi selected in X-axis
  // hide kpi selected in Y-axis
  const themeItems = (themes || []).filter((kpi) => ![xTheme?.id, yTheme?.id].includes(kpi.id));

  const toggleSidebar = () => setIsSidebarOpen((prevState) => !prevState);

  const exportCSV = async () => {
    if (!primaryReview?.id || !xTheme?.id || !yTheme?.id) {
      return;
    }

    const reviewIds = [primaryReview.id];
    if (secondaryReview?.id) {
      reviewIds.push(secondaryReview.id);
    }

    await downloadNineGridCSV({
      reviewIds,
      themes: [xTheme.id, yTheme.id],
      jobProfiles: preparedFilters?.jobProfiles || [],
      employees: preparedFilters?.employees || [],
    });
  };

  const update = async (labels: INineGridLabel[]) => {
    await dispatch(setCompanyLabels(labels));
  };

  const getRatingTrendOverReviews = (
    primaryAverage?: number,
    secondaryAverage?: number,
  ): 'UP' | 'DOWN' | null => {
    if (primaryAverage && secondaryAverage) {
      if (primaryAverage < secondaryAverage) {
        return 'UP';
      } else if (primaryAverage > secondaryAverage) {
        return 'DOWN';
      }
    }

    return null;
  };

  const items = useMemo(() => {
    if (!primaryReview || !xTheme || !yTheme) {
      return [];
    }

    const tableListItems: Record<string, IListItem> = {};

    primaryReview?.userReviews.forEach((userReview) => {
      tableListItems[userReview.createdFor] = {
        primaryReview: {
          id: userReview.id,
          name: primaryReview?.name,
          xTheme: {
            average: getPureValue(userReview, xTheme),
            rlc: getRatingLabelCountValue(userReview, xTheme),
          },
          yTheme: {
            average: getPureValue(userReview, yTheme),
            rlc: getRatingLabelCountValue(userReview, yTheme),
          },
        },
        user: users?.[userReview.createdFor] || { email: 'Deleted User' },
      };
    });

    secondaryReview?.userReviews.forEach((userReview) => {
      const tableListItem = tableListItems[userReview.createdFor];

      const secondReview = {
        id: userReview.id,
        name: secondaryReview?.name,
        xTheme: {
          average: getPureValue(userReview, xTheme),
          rlc: getRatingLabelCountValue(userReview, xTheme),
        },
        yTheme: {
          average: getPureValue(userReview, yTheme),
          rlc: getRatingLabelCountValue(userReview, yTheme),
        },
      };

      if (tableListItem) {
        tableListItem.secondaryReview = secondReview;

        tableListItem.secondaryReview.xTheme.trend = getRatingTrendOverReviews(
          tableListItem.primaryReview?.xTheme?.average,
          secondReview.xTheme?.average,
        );
        tableListItem.secondaryReview.yTheme.trend = getRatingTrendOverReviews(
          tableListItem.primaryReview?.yTheme?.average,
          secondReview.yTheme?.average,
        );
      } else {
        tableListItems[userReview.createdFor] = {
          secondaryReview: secondReview,
          user: users?.[userReview.createdFor] || { email: 'Deleted User' },
        };
      }
    });

    return tableListItems ? Object.values(tableListItems) : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify({
      primaryReview,
      xTheme,
      yTheme,
      secondaryReview,
      selectedRoles,
      selectedTeams,
      selectedMembers,
      matrixArea,
    }),
  ]);

  const getPositions = useCallback(
    (
      userReview: INineGridUserReview,
      xTheme: INineGridReviewTheme,
      yTheme: INineGridReviewTheme,
    ) => {
      return getPositionValue(
        userReview.createdFor,
        userReview.reviewId,
        getGridPosition(userReview.themeAverage?.[xTheme.id]),
        getGridPosition(userReview.themeAverage?.[yTheme.id]),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [matrixArea],
  );

  // calculate correct position for avatars
  const getPositionValue = (userId: string, reviewId: string, x: number, y: number) => {
    const index = matrixArea?.[x]?.[y]?.users.findIndex(
      (item) => item.userId === userId && item.reviewId === reviewId,
    );

    if (index < 0) {
      return { x: null, y: null };
    }

    // max number of avatars per column
    const maxColumn = 5;
    const margin = 1.5;
    const gapBetweenAvatars = 6.5;
    const rowHeight = 6;

    // first row
    if (index < maxColumn) {
      return {
        x: x * 33 + gapBetweenAvatars * index + margin,
        y: y * 33 + rowHeight * 2 + 3,
      };
    }

    // second row
    if (index < maxColumn * 2) {
      return {
        x: x * 33 + gapBetweenAvatars * (index - maxColumn) + margin,
        y: y * 33 + rowHeight,
        // show the length of remaining avatars instead of avatar
        extraCounter:
          matrixArea?.[x]?.[y]?.users?.length > maxColumn * 2 && index + 1 === maxColumn * 2
            ? `+${matrixArea?.[x]?.[y]?.users?.length + 1 - maxColumn * 2}`
            : null,
      };
    }

    return {
      x: null,
      y: null,
    };
  };

  const getGridPosition = (values: number[]) => {
    return prepareIndex(values);
  };

  return (
    <BoxWrapper>
      <Sidebar isOpen={isSidebarOpen}>
        <div className="content">
          <Title isRotated={isSidebarOpen}>
            <Trans>Set-up your 9-grid</Trans>
          </Title>
          {isSidebarOpen && (
            <div className="top">
              <FiltersWrapper>
                <DropdownWrapper>
                  <span className="title">
                    <Trans>1. Review(s)</Trans>
                  </span>
                  <StyledAutocompleteFilterReviews
                    showTooltip
                    checkedList={primaryReviewOption ? [primaryReviewOption] : []}
                    onChange={(reviews: INineGridReview[]) => {
                      setPrimaryReview(null);
                      if (reviews.length === 0) {
                        fillSecondaryReviewOption(undefined);
                      }
                      onPrimaryReviewOptionSelect(reviews.slice(-1)[0]);
                    }}
                    $error={getError()}
                    selectedItemBackground="linear-gradient(to left, #e253e8, #c129cc);"
                  />
                  <StyledAutocompleteFilterReviews
                    showTooltip
                    disabled={!primaryReviewOption}
                    checkedList={secondaryReviewOption ? [secondaryReviewOption] : []}
                    onChange={(reviews: INineGridReview[]) => {
                      if (!primaryReviewOption) {
                        return;
                      }
                      const secondaryOption = reviews.slice(-1)[0];
                      onSecondaryReviewOptionSelect(secondaryOption);
                    }}
                    selectedItemBackground="linear-gradient(to left, #95aeff, #5c76ff);"
                  />
                </DropdownWrapper>
                <DropdownWrapper>
                  <span className="title">
                    <Trans>2. Themes X & Y axis</Trans>
                  </span>
                  <ThemeDropdown
                    items={themeItems}
                    selectedItem={xTheme}
                    isError={getError() || isXThemeError}
                    onChange={(value) => {
                      setPrimaryReview(null);
                      setSecondaryReview(null);
                      setXTheme(value as INineGridReviewTheme);
                      setIsXThemeError(false);
                    }}
                    stringifyItem={(item) => `${i18n._(t`${(item as INineGridReviewTheme).name}`)}`}
                    isSingleSelect
                    placeholder={i18n._(t`Theme X-axis`)}
                  />
                  <ThemeDropdown
                    items={themeItems}
                    selectedItem={yTheme}
                    isError={getError() || isYThemeError}
                    onChange={(value) => {
                      setPrimaryReview(null);
                      setSecondaryReview(null);
                      setYTheme(value as INineGridReviewTheme);
                      setIsYThemeError(false);
                    }}
                    stringifyItem={(item) => `${i18n._(t`${(item as INineGridReviewTheme).name}`)}`}
                    isSingleSelect
                    placeholder={i18n._(t`Theme Y-axis`)}
                  />
                </DropdownWrapper>
                <DropdownWrapper>
                  <span className="title">
                    <Trans>3. Team / member</Trans>
                  </span>
                  <StyledAutocompleteFilterTeams
                    checkedList={selectedTeams}
                    onChange={setSelectedTeams}
                  />
                  <StyledAutocompleteFilterMembers
                    checkedList={selectedMembers}
                    onChange={setSelectedMembers}
                  />
                  <StyledAutocompleteFilterRoles
                    checkedList={selectedRoles}
                    onChange={setSelectedRoles}
                  />
                </DropdownWrapper>
              </FiltersWrapper>
              <ApplyButton
                disabled={!primaryReviewOption}
                label={i18n._(t`Apply`)}
                onClick={onApplyButton}
                variant={ButtonVariant.PRIMARY}
                isLoading={isLoading}
              />
            </div>
          )}
        </div>
        <CollapseButton
          className="collapseButton"
          onClick={toggleSidebar}
          isRotated={isSidebarOpen}
        >
          <CollapseIcon width={20} height={16} />
        </CollapseButton>
      </Sidebar>
      <Main>
        <Header>
          <Button
            label={i18n._(t`Edit labels`)}
            icon={ICONS.EDIT_PENCIL}
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.TEXT_PRIMARY}
            isLoading={isLoading}
            onClick={$showUpdateLabelsModal.on}
          />
          <Button
            label={i18n._(t`Export csv`)}
            disabled={isEmpty(items)}
            icon={ICONS.EXPORT}
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.TEXT_PRIMARY}
            isLoading={isLoading}
            onClick={exportCSV}
          />
          <ViewTypeButton
            icon={ICONS.LIST_VIEW}
            disabled={isEmpty(items)}
            variant={ButtonVariant.ICON}
            isLoading={isLoading}
            isActive={viewType === REPORT_VIEW_TYPES.LIST}
            onClick={() => setViewType(REPORT_VIEW_TYPES.LIST)}
          />
          <ViewTypeButton
            icon={ICONS.GRID_VIEW}
            disabled={isEmpty(items)}
            variant={ButtonVariant.ICON}
            isLoading={isLoading}
            isActive={viewType === REPORT_VIEW_TYPES.GRID}
            onClick={() => setViewType(REPORT_VIEW_TYPES.GRID)}
          />
        </Header>
        {viewType === REPORT_VIEW_TYPES.GRID &&
          (isEmpty(items) && primaryReview && xTheme && yTheme ? (
            <Placeholder
              className="placeholderNoData"
              Icon={() => (
                <SvgIcon
                  width="32px"
                  height="32px"
                  url={ninegridIcon}
                  isDefaultColor={true}
                  defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                />
              )}
              subTitle={i18n._(t`No data available within this selection.`)}
            />
          ) : (
            <div className="grid">
              {primaryReview && xTheme && yTheme && !getError() ? (
                <NineGridWrapper>
                  <ContentWrapper>
                    <YAxis>
                      <Rotation>
                        <GraphTitle>
                          <Trans>{yTheme.name}</Trans>
                        </GraphTitle>
                        <GraphLabels>
                          <GraphLabel $justifyContent="flex-start">Low</GraphLabel>
                          <GraphLabel $justifyContent="center">Medium</GraphLabel>
                          <GraphLabel $justifyContent="flex-end">High</GraphLabel>
                        </GraphLabels>
                      </Rotation>
                    </YAxis>
                    <NineGridContainerContainer>
                      {getGridRows()}
                      {xTheme &&
                        yTheme &&
                        primaryReview &&
                        primaryReview.userReviews.map((userReview) => {
                          const { x, y, extraCounter } = getPositions(userReview, xTheme, yTheme);
                          return extraCounter ? (
                            <ExtraCounter key={userReview.createdFor} $left={x} $bottom={y}>
                              {extraCounter}
                            </ExtraCounter>
                          ) : (
                            primaryReview?.users?.includes(userReview.createdFor) && (
                              <AvatarContainer
                                onMouseEnter={() => {
                                  setHovered(userReview.createdFor);
                                }}
                                onMouseLeave={() => setHovered('')}
                                onClick={() => {
                                  navigateToProfile(userReview.createdFor);
                                }}
                                key={userReview.createdFor}
                                $left={x}
                                $bottom={y}
                                $blur={hovered && hovered !== userReview.createdFor}
                                borderColor="#c129cc"
                              >
                                <Chip background="#c129cc">A</Chip>
                                {/* @ts-ignore */}
                                <Avatar
                                  borderRadius="2px"
                                  showTooltip
                                  size={28}
                                  userId={userReview.createdFor}
                                />
                              </AvatarContainer>
                            )
                          );
                        })}
                      {xTheme &&
                        yTheme &&
                        secondaryReview &&
                        secondaryReview.userReviews.map((userReview) => {
                          const { x, y, extraCounter } = getPositions(userReview, xTheme, yTheme);
                          return extraCounter ? (
                            <ExtraCounter key={userReview.createdFor} $left={x} $bottom={y}>
                              {extraCounter}
                            </ExtraCounter>
                          ) : (
                            <SecondReviewAvatarContainer
                              onMouseEnter={() => setHovered(userReview.createdFor)}
                              onMouseLeave={() => setHovered('')}
                              key={userReview.createdFor}
                              $left={x}
                              $blur={hovered && hovered !== userReview.createdFor}
                              $bottom={y}
                              borderColor="#5c76ff"
                            >
                              <Chip background="#5c76ff">B</Chip>
                              {/* @ts-ignore */}
                              <Avatar
                                borderRadius="2px"
                                showTooltip
                                size={28}
                                userId={userReview.createdFor}
                              />
                            </SecondReviewAvatarContainer>
                          );
                        })}
                    </NineGridContainerContainer>
                    <YAxis width={0}>
                      <Rotation
                        transformTranslate={0}
                        transformRotate={90}
                        marginLeft="-20px"
                        transformOrigin="20px 20px"
                      >
                        <GraphLabels>
                          <GraphLabel $justifyContent="flex-start">High</GraphLabel>
                          <GraphLabel $justifyContent="center">Medium</GraphLabel>
                          <GraphLabel $justifyContent="flex-end">Low</GraphLabel>
                        </GraphLabels>
                      </Rotation>
                    </YAxis>
                  </ContentWrapper>
                  <XAxis>
                    <GraphLabels>
                      <GraphLabel $justifyContent="flex-start">
                        <Trans>Low</Trans>
                      </GraphLabel>
                      <GraphLabel $justifyContent="center">
                        <Trans>Medium</Trans>
                      </GraphLabel>
                      <GraphLabel $justifyContent="flex-end">
                        <Trans>High</Trans>
                      </GraphLabel>
                    </GraphLabels>
                    <GraphTitle>
                      <Trans>{xTheme.name}</Trans>
                    </GraphTitle>
                  </XAxis>
                </NineGridWrapper>
              ) : (
                <Placeholder
                  className="placeholderEmpty"
                  Icon={() => <HandPointingIcon size={32} />}
                  subTitle={i18n._(t`Select review(s), values and members to set up your 9-grid.`)}
                />
              )}
            </div>
          ))}
        {viewType === REPORT_VIEW_TYPES.LIST &&
          (isEmpty(items) && primaryReview && xTheme && yTheme ? (
            <Placeholder
              className="placeholderNoData"
              Icon={() => (
                <SvgIcon
                  width="32px"
                  height="32px"
                  url={ninegridIcon}
                  isDefaultColor={true}
                  defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                />
              )}
              subTitle={i18n._(t`No data available within this selection.`)}
            />
          ) : (
            <div className="list">
              <ListViewComponent
                items={items}
                themes={[xTheme as INineGridReviewTheme, yTheme as INineGridReviewTheme]}
                isSecondReviewSelected={!!secondaryReview}
                isLoading={isLoading}
              />
            </div>
          ))}
      </Main>
      {$showUpdateLabelsModal.value && (
        <UpdateNineGridLabelsModal
          onClose={$showUpdateLabelsModal.off}
          labels={nineGridLabels}
          update={update}
        />
      )}
    </BoxWrapper>
  );
};

export default NineGridBox;
