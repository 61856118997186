import type { Dispatch, SetStateAction } from 'react';

import { createAction } from 'redux-act';

import { ROLES } from '~/constants';

export const updateSelectedCompany = createAction<string | null>('Update selected company');
export const updateSelectedTeam = createAction<string | null>('Update selected team');
export const updateSelectedRole = createAction<string | null>('Update selected role');

interface Store {
  selected: {
    role?: string;
    company?: string;
    team?: string;
  };
}

export const initializeSelection = (clear = false) => {
  return function (dispatch: Dispatch<SetStateAction<string | any>>, getStore: () => Store) {
    if (clear) {
      dispatch(updateSelectedRole(null));
      dispatch(updateSelectedCompany(null));
      dispatch(updateSelectedTeam(null));
      return;
    }
    const { selected } = getStore();
    const selectedRole = selected.role || localStorage.getItem('selectedRole') || ROLES.USER;
    dispatch(updateSelectedRole(selectedRole));

    const selectedCompany = selected.company || localStorage.getItem('selectedCompany');
    if (selectedCompany) {
      dispatch(updateSelectedCompany(selectedCompany));
    }

    const selectedTeam = selected.team || localStorage.getItem('selectedTeam');

    if (selectedTeam && selectedRole !== ROLES.USER) {
      dispatch(updateSelectedTeam(selectedTeam));
    }
  };
};
