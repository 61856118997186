import React from 'react';
import type { ReactNode, PropsWithChildren } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import HeadingNavigation from '~/components/HeadingNavigation';

import { ROLES } from '~/constants';
import { getSelectedRole } from '~/selectors/baseGetters';

const StyledNavigation = styled(HeadingNavigation)`
  width: 100%;
`;

function getTitle(currentRole: string) {
  switch (currentRole) {
    case ROLES.ADMIN:
      return t`Organisation`;
    case ROLES.COACH: {
      return t`Team members`;
    }
    case ROLES.USER:
    default:
      return t`Personal`;
  }
}

type OverviewHeadingProps = {
  title: ReactNode;
  description?: ReactNode;
  filters?: ReactNode;
  className?: string;
  logoUrl?: string;
  isDisabledSticky?: boolean;
  noPadding?: boolean;
  maxWidth?: number | string;
  smallWidth?: boolean;
  onBack?: () => void;
  backPath?: string | null;
  instructions?: string;
  instructionsUrl?: string;
};

const OverviewHeading = ({
  children,
  title,
  instructions,
  instructionsUrl,
  description,
  backPath,
  onBack,
  filters,
  smallWidth,
  maxWidth,
  className,
  logoUrl,
  isDisabledSticky,
  noPadding,
}: PropsWithChildren<OverviewHeadingProps>) => {
  const { i18n } = useLingui();
  const currentRole: string = useSelector(getSelectedRole);

  return (
    <StyledNavigation
      label={title || i18n._(getTitle(currentRole))}
      description={description}
      actions={children}
      backPath={backPath}
      onBack={onBack}
      instructions={instructions}
      instructionsUrl={instructionsUrl}
      smallWidth={smallWidth}
      maxWidth={maxWidth}
      className={className}
      logoUrl={logoUrl}
      isDisabledSticky={isDisabledSticky}
      noPadding={noPadding}
    >
      {filters && filters}
    </StyledNavigation>
  );
};

export default React.memo(OverviewHeading);
