import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Editor from '~/components/Editor';
import { ICONS } from '~/components/Icon';
import RickTextView from '~/components/RickTextView';

import {
  ActionContainer,
  Title,
  MessageBox,
  MessageContainer,
  MessageInnerContainer,
  MessageTriangle,
} from './design';

import { COLORS } from '~/styles';
import { isHTMLContentEmpty } from '~/utils/isHTMLContentEmpty';

type InvitationMessageProps = {
  value?: string;
  onEdit?(): void;
  onCancel?(): void;
  onSave?(value: string): void;
  onChange?(value: string): void;
  editMode?: boolean;
};

const InvitationMessage = ({
  value = '',
  onCancel,
  onSave,
  onEdit,
  editMode = false,
}: InvitationMessageProps) => {
  const { i18n } = useLingui();

  const [content, setContent] = useState(value);

  return (
    <>
      <Title>{i18n._(t`Invitation message`)}</Title>

      {!editMode && (
        <MessageContainer>
          <MessageInnerContainer>
            <MessageTriangle />
            <MessageBox>
              <RickTextView
                html={value}
                color={COLORS.TEXT_MAIN}
                isHtmlWithoutStyles={undefined}
                className={undefined}
              />
            </MessageBox>
          </MessageInnerContainer>
        </MessageContainer>
      )}
      {editMode && (
        <Editor
          placeholder={i18n._(t`Type your message here...`)}
          value={content}
          onChange={(args: string) => {
            setContent(args);
          }}
          small
          compact
        />
      )}

      {editMode && (
        <ActionContainer>
          <Button
            label={i18n._(t`Cancel`)}
            type="button"
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
            onClick={() => {
              setContent(value);
              onCancel?.();
            }}
          />

          <Button
            label={i18n._(t`Save`)}
            type="button"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={() => {
              onSave?.(content);
            }}
            disabled={isHTMLContentEmpty(content)}
          />
        </ActionContainer>
      )}

      {!editMode && (
        <ActionContainer>
          <Button
            label={i18n._(t`Edit invite`)}
            type="button"
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
            onClick={() => onEdit?.()}
            icon={ICONS.EDIT_PENCIL}
          />
        </ActionContainer>
      )}
    </>
  );
};
export { InvitationMessage };
