import React, { useState } from 'react';

import { INTEGRATIONS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import Modal from '~/components/Modal';
import SwitchYesNo from '~/components/SwitchYesNo';
import { TextField } from '~/components/Text';
import Divider from '~/components/UI/Divider';

import AzureConnectionInfo from './ConnectionInfo/AzureConnectionInfo';

import {
  DISABLED_DELETE_INTEGRATIONS,
  DISABLED_TEAMS_INTEGRATIONS,
  DISABLED_ROLES_INTEGRATIONS,
} from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { updateIntegrationSettings, testIntegrationSettings } from '~/services/integrationSettings';
import { COLOR_PALETTE } from '~/styles';
import getIntegrationToolName from '~/utils/getIntegrationToolName';

const MainContent = styled.div``;

const SettingLabel = styled.div``;
const SettingBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin: 4px;
  margin-left: 8px;
  margin-top: 8px;
`;

const IntegrationSettingsSection = styled.div`
  margin-top: 16px;
  padding: 8px;
  border-radius: 6px;
  background-color: #f6f8fc;
`;

const SettingTextField = styled(TextField)`
  width: 100%;
  height: 48px;
`;
const ButtonSection = styled.div`
  display: flex;
`;

const IntegrationSettingsHeader = styled.div`
  font-size: 1.15em;
  font-weight: 600;
  margin: 8px;
  margin-bottom: 16px;
`;

const TestStateBoxInvalid = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin: 4px;
  color: red;
`;

const TestStateBoxValid = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin: 4px;
  color: green;
`;

const ModulesWrapper = styled.div`
  background-color: ${COLOR_PALETTE.WHITE};
  padding: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 6px;
`;

const ModulesHeader = styled.div`
  display: grid;
  grid-template-columns: 4fr 2fr 2fr;
`;

const ModulesHeaderItem = styled.div`
  font-size: 0.8em;
  font-weight: 300;
`;

const ToggleOption = styled.div`
  display: grid;
  grid-template-columns: 4fr 2fr 2fr;
  margin: 16px;
`;

const ToggleLabel = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
`;

const ToggleSetting = styled.div``;

function UpdateIntegrationModal({ onClose, i18n, integration }) {
  const loadingTest = useBoolState();
  const loadingUpdate = useBoolState();
  const [integrationSettings, setIntegrationSettings] = useState(integration);
  const [testState, setTestState] = useState({ tested: false, isValid: false });

  /*
   * module - module name, for example "deleteRoles"
   * entries - array of array, where index 0 - key, index 1 - value: for instance:
   * [[enabled, true], [automatic, false]]
   * */

  const changeIntegrationModules = ({ module, entries }) => {
    const newModule = {
      ...get(integrationSettings, `integrationModules[${module}]`, {}),
      ...entries,
    };

    setIntegrationSettings({
      ...integrationSettings,
      integrationModules: {
        ...integrationSettings.integrationModules,
        [module]: newModule,
      },
    });
  };

  return (
    <Modal
      title={i18n._(t`Update integration ${integration.name}`)}
      onClose={onClose}
      isCloseOnOutsideClick={true}
      footerLeft={<Button type="primary-border" label={i18n._(t`Cancel`)} onClick={onClose} />}
      footerRight={
        <ButtonSection>
          {testState.tested && testState.isValid && (
            <TestStateBoxValid>
              {i18n._(t`Connection to ${getIntegrationToolName(integration)} successful!`)}
            </TestStateBoxValid>
          )}
          {testState.tested && !testState.isValid && (
            <TestStateBoxInvalid>
              {i18n._(t`Could not connect to ${getIntegrationToolName(integration)}!`)}
            </TestStateBoxInvalid>
          )}
          <Button
            type="primary"
            width="auto"
            styles={{ minWidth: '67px', margin: '4px' }}
            label={i18n._(t`Test integration settings`)}
            loading={loadingTest.value || loadingUpdate.value}
            onClick={async () => {
              loadingTest.on();
              const result = await testIntegrationSettings({
                externalSoftware: integration.externalSoftware,
                ...integrationSettings,
              });
              setTestState({ tested: true, isValid: result.isValid });
              loadingTest.off();
            }}
          />
          <Button
            type="primary"
            width="auto"
            styles={{ minWidth: '67px', margin: '4px' }}
            label={integration ? i18n._(t`Update`) : i18n._(t`Add integration`)}
            loading={loadingUpdate.value}
            onClick={async () => {
              loadingTest.on();
              loadingUpdate.on();
              const result = await testIntegrationSettings({
                externalSoftware: integration.externalSoftware,
                ...integrationSettings,
              });
              setTestState({ tested: true, isValid: result.isValid });
              if (result.isValid) {
                if (!integrationSettings.name || integrationSettings.name === '') {
                  integrationSettings.name = i18n._(t`Integration`);
                }
                await updateIntegrationSettings(integration.id, {
                  externalSoftware: integration.externalSoftware,
                  ...integrationSettings,
                });

                onClose();
              } else {
                alert('The integration settings could not be verified...');
              }
              loadingUpdate.off();
              loadingTest.off();
            }}
          />
        </ButtonSection>
      }
    >
      <MainContent>
        <IntegrationSettingsSection>
          <IntegrationSettingsHeader>
            {' '}
            {getIntegrationToolName(integration)} <Trans>Connection Information</Trans>
          </IntegrationSettingsHeader>
          <SettingBlock>
            <SettingLabel>
              <Trans>Name</Trans>
            </SettingLabel>
            <SettingTextField
              placeholder={i18n._(t`Enter name for integration...`)}
              value={integrationSettings.name}
              onChange={(e) => {
                setTestState({ tested: false, isValid: false });
                setIntegrationSettings({ ...integrationSettings, name: e.target.value });
              }}
            />
          </SettingBlock>
          <SettingBlock>
            <SettingLabel>
              <Trans>Filter</Trans>
            </SettingLabel>
            <SettingTextField
              placeholder={i18n._(t`Enter filter text for user emails...`)}
              value={integrationSettings.domainFilter}
              onChange={(e) => {
                setTestState({ tested: false, isValid: false });
                setIntegrationSettings({ ...integrationSettings, domainFilter: e.target.value });
              }}
            />
          </SettingBlock>
          {integration.externalSoftware === INTEGRATIONS.AZURE_AD && (
            <AzureConnectionInfo
              testHook={[testState, setTestState]}
              settingsHook={[integrationSettings, setIntegrationSettings]}
              updateMode={true}
            />
          )}
          <SettingBlock>
            <SettingLabel>
              <Trans>Options</Trans>
            </SettingLabel>
            <ModulesWrapper>
              <ModulesHeader>
                <ModulesHeaderItem>
                  <Trans>Module</Trans>
                </ModulesHeaderItem>
                <ModulesHeaderItem>
                  <Trans>Enabled</Trans>
                </ModulesHeaderItem>
                <ModulesHeaderItem>
                  <Trans>Automatic</Trans>
                </ModulesHeaderItem>
              </ModulesHeader>
              <ToggleOption>
                <ToggleLabel>
                  <Trans>Invite module</Trans>
                </ToggleLabel>
                <ToggleSetting>
                  <SwitchYesNo
                    width={64}
                    value={integrationSettings.integrationModules.importUsers.enabled}
                    onChange={() => {
                      setIntegrationSettings({
                        ...integrationSettings,
                        integrationModules: {
                          ...integrationSettings.integrationModules,
                          importUsers: {
                            ...integrationSettings.integrationModules.importUsers,
                            enabled: !integrationSettings.integrationModules.importUsers.enabled,
                            automatic: integrationSettings.integrationModules.importUsers.enabled
                              ? false
                              : integrationSettings.integrationModules.importUsers.automatic,
                          },
                        },
                      });
                    }}
                  />
                </ToggleSetting>
                <ToggleSetting>
                  <SwitchYesNo
                    disabled={!integrationSettings.integrationModules.importUsers.enabled}
                    width={64}
                    value={integrationSettings.integrationModules.importUsers.automatic}
                    onChange={() => {
                      setIntegrationSettings({
                        ...integrationSettings,
                        integrationModules: {
                          ...integrationSettings.integrationModules,
                          importUsers: {
                            ...integrationSettings.integrationModules.importUsers,
                            automatic:
                              !integrationSettings.integrationModules.importUsers.automatic,
                          },
                        },
                      });
                    }}
                  />
                </ToggleSetting>
              </ToggleOption>
              <ToggleOption>
                <ToggleLabel>
                  <Trans>Sync Users</Trans>
                </ToggleLabel>
                <ToggleSetting>
                  <SwitchYesNo
                    width={64}
                    value={integrationSettings.integrationModules.syncUsers.enabled}
                    onChange={() => {
                      setIntegrationSettings({
                        ...integrationSettings,
                        integrationModules: {
                          ...integrationSettings.integrationModules,
                          syncUsers: {
                            ...integrationSettings.integrationModules.syncUsers,
                            enabled: !integrationSettings.integrationModules.syncUsers.enabled,
                            automatic: integrationSettings.integrationModules.syncUsers.enabled
                              ? false
                              : integrationSettings.integrationModules.syncUsers.automatic,
                          },
                        },
                      });
                    }}
                  />
                </ToggleSetting>
                <ToggleSetting>
                  <SwitchYesNo
                    disabled={!integrationSettings.integrationModules.syncUsers.enabled}
                    width={64}
                    value={integrationSettings.integrationModules.syncUsers.automatic}
                    onChange={() => {
                      setIntegrationSettings({
                        ...integrationSettings,
                        integrationModules: {
                          ...integrationSettings.integrationModules,
                          syncUsers: {
                            ...integrationSettings.integrationModules.syncUsers,
                            automatic: !integrationSettings.integrationModules.syncUsers.automatic,
                          },
                        },
                      });
                    }}
                  />
                </ToggleSetting>
              </ToggleOption>

              {!DISABLED_DELETE_INTEGRATIONS.includes(integrationSettings.externalSoftware) && (
                <ToggleOption>
                  <ToggleLabel>
                    <Trans>Delete user accounts</Trans>
                  </ToggleLabel>
                  <ToggleSetting>
                    <SwitchYesNo
                      width={64}
                      value={integrationSettings.integrationModules.deleteUsers.enabled}
                      onChange={() => {
                        setIntegrationSettings({
                          ...integrationSettings,
                          integrationModules: {
                            ...integrationSettings.integrationModules,
                            deleteUsers: {
                              ...integrationSettings.integrationModules.deleteUsers,
                              enabled: !integrationSettings.integrationModules.deleteUsers.enabled,
                              automatic: integrationSettings.integrationModules.deleteUsers.enabled
                                ? false
                                : integrationSettings.integrationModules.deleteUsers.automatic,
                            },
                          },
                        });
                      }}
                    />
                  </ToggleSetting>
                  <ToggleSetting>
                    <SwitchYesNo
                      disabled={!integrationSettings.integrationModules.deleteUsers.enabled}
                      width={64}
                      value={integrationSettings.integrationModules.deleteUsers.automatic}
                      onChange={() => {
                        setIntegrationSettings({
                          ...integrationSettings,
                          integrationModules: {
                            ...integrationSettings.integrationModules,
                            deleteUsers: {
                              ...integrationSettings.integrationModules.deleteUsers,
                              automatic:
                                !integrationSettings.integrationModules.deleteUsers.automatic,
                            },
                          },
                        });
                      }}
                    />
                  </ToggleSetting>
                </ToggleOption>
              )}
              {!DISABLED_TEAMS_INTEGRATIONS.includes(integrationSettings.externalSoftware) && (
                <>
                  <Divider />
                  <ToggleOption>
                    <ToggleLabel>
                      <Trans>Import Teams</Trans>
                    </ToggleLabel>
                    <ToggleSetting>
                      <SwitchYesNo
                        width={64}
                        value={get(
                          integrationSettings,
                          'integrationModules.importTeams.enabled',
                          false,
                        )}
                        onChange={() =>
                          changeIntegrationModules({
                            module: 'importTeams',
                            entries: {
                              enabled: !get(
                                integrationSettings,
                                'integrationModules.importTeams.enabled',
                                false,
                              ),
                              automatic: get(
                                integrationSettings,
                                'integrationModules.importTeams.enabled',
                                false,
                              )
                                ? false
                                : get(
                                    integrationSettings,
                                    'integrationModules.importTeams.automatic',
                                    false,
                                  ),
                            },
                          })
                        }
                      />
                    </ToggleSetting>
                    <ToggleSetting>
                      <SwitchYesNo
                        disabled={
                          !get(integrationSettings, 'integrationModules.importTeams.enabled', false)
                        }
                        width={64}
                        value={get(
                          integrationSettings,
                          'integrationModules.importTeams.automatic',
                          false,
                        )}
                        onChange={() =>
                          changeIntegrationModules({
                            module: 'importTeams',
                            entries: {
                              automatic: !get(
                                integrationSettings,
                                'integrationModules.importTeams.automatic',
                                false,
                              ),
                            },
                          })
                        }
                      />
                    </ToggleSetting>
                  </ToggleOption>
                  <ToggleOption>
                    <ToggleLabel>
                      <Trans>Sync Teams</Trans>
                    </ToggleLabel>
                    <ToggleSetting>
                      <SwitchYesNo
                        width={64}
                        value={get(
                          integrationSettings,
                          'integrationModules.syncTeams.enabled',
                          false,
                        )}
                        onChange={() =>
                          changeIntegrationModules({
                            module: 'syncTeams',
                            entries: {
                              enabled: !get(
                                integrationSettings,
                                'integrationModules.syncTeams.enabled',
                                false,
                              ),
                              automatic: get(
                                integrationSettings,
                                'integrationModules.syncTeams.enabled',
                                false,
                              )
                                ? false
                                : get(
                                    integrationSettings,
                                    'integrationModules.syncTeams.automatic',
                                    false,
                                  ),
                            },
                          })
                        }
                      />
                    </ToggleSetting>
                    <ToggleSetting>
                      <SwitchYesNo
                        disabled={
                          !get(integrationSettings, 'integrationModules.syncTeams.enabled', false)
                        }
                        width={64}
                        value={get(
                          integrationSettings,
                          'integrationModules.syncTeams.automatic',
                          false,
                        )}
                        onChange={() =>
                          changeIntegrationModules({
                            module: 'syncTeams',
                            entries: {
                              automatic: !get(
                                integrationSettings,
                                'integrationModules.syncTeams.automatic',
                                false,
                              ),
                            },
                          })
                        }
                      />
                    </ToggleSetting>
                  </ToggleOption>
                  <ToggleOption>
                    <ToggleLabel>
                      <Trans>Delete Teams</Trans>
                    </ToggleLabel>
                    <ToggleSetting>
                      <SwitchYesNo
                        width={64}
                        value={get(
                          integrationSettings,
                          'integrationModules.deleteTeams.enabled',
                          false,
                        )}
                        onChange={() =>
                          changeIntegrationModules({
                            module: 'deleteTeams',
                            entries: {
                              enabled: !get(
                                integrationSettings,
                                'integrationModules.deleteTeams.enabled',
                                false,
                              ),
                              automatic: get(
                                integrationSettings,
                                'integrationModules.deleteTeams.enabled',
                                false,
                              )
                                ? false
                                : get(
                                    integrationSettings,
                                    'integrationModules.deleteTeams.automatic',
                                    false,
                                  ),
                            },
                          })
                        }
                      />
                    </ToggleSetting>
                    <ToggleSetting>
                      <SwitchYesNo
                        disabled={
                          !get(integrationSettings, 'integrationModules.deleteTeams.enabled', false)
                        }
                        width={64}
                        value={get(
                          integrationSettings,
                          'integrationModules.deleteTeams.automatic',
                          false,
                        )}
                        onChange={() =>
                          changeIntegrationModules({
                            module: 'deleteTeams',
                            entries: {
                              automatic: !get(
                                integrationSettings,
                                'integrationModules.deleteTeams.automatic',
                                false,
                              ),
                            },
                          })
                        }
                      />
                    </ToggleSetting>
                  </ToggleOption>
                </>
              )}
              {!DISABLED_ROLES_INTEGRATIONS.includes(integrationSettings.externalSoftware) && (
                <>
                  <Divider />
                  <ToggleOption>
                    <ToggleLabel>
                      <Trans>Import Roles</Trans>
                    </ToggleLabel>
                    <ToggleSetting>
                      <SwitchYesNo
                        width={64}
                        value={get(
                          integrationSettings,
                          'integrationModules.importRoles.enabled',
                          false,
                        )}
                        onChange={() =>
                          changeIntegrationModules({
                            module: 'importRoles',
                            entries: {
                              enabled: !get(
                                integrationSettings,
                                'integrationModules.importRoles.enabled',
                                false,
                              ),
                              automatic: get(
                                integrationSettings,
                                'integrationModules.importRoles.enabled',
                                false,
                              )
                                ? false
                                : get(
                                    integrationSettings,
                                    'integrationModules.importRoles.automatic',
                                    false,
                                  ),
                            },
                          })
                        }
                      />
                    </ToggleSetting>
                    <ToggleSetting>
                      <SwitchYesNo
                        disabled={
                          !get(integrationSettings, 'integrationModules.importRoles.enabled', false)
                        }
                        width={64}
                        value={get(
                          integrationSettings,
                          'integrationModules.importRoles.automatic',
                          false,
                        )}
                        onChange={() =>
                          changeIntegrationModules({
                            module: 'importRoles',
                            entries: {
                              automatic: !get(
                                integrationSettings,
                                'integrationModules.importRoles.automatic',
                                false,
                              ),
                            },
                          })
                        }
                      />
                    </ToggleSetting>
                  </ToggleOption>
                  <ToggleOption>
                    <ToggleLabel>
                      <Trans>Sync Roles</Trans>
                    </ToggleLabel>
                    <ToggleSetting>
                      <SwitchYesNo
                        width={64}
                        value={get(
                          integrationSettings,
                          'integrationModules.syncRoles.enabled',
                          false,
                        )}
                        onChange={() =>
                          changeIntegrationModules({
                            module: 'syncRoles',
                            entries: {
                              enabled: !get(
                                integrationSettings,
                                'integrationModules.syncRoles.enabled',
                                false,
                              ),
                              automatic: get(
                                integrationSettings,
                                'integrationModules.syncRoles.enabled',
                                false,
                              )
                                ? false
                                : get(
                                    integrationSettings,
                                    'integrationModules.syncRoles.automatic',
                                    false,
                                  ),
                            },
                          })
                        }
                      />
                    </ToggleSetting>
                    <ToggleSetting>
                      <SwitchYesNo
                        disabled={
                          !get(integrationSettings, 'integrationModules.syncRoles.enabled', false)
                        }
                        width={64}
                        value={get(
                          integrationSettings,
                          'integrationModules.syncRoles.automatic',
                          false,
                        )}
                        onChange={() =>
                          changeIntegrationModules({
                            module: 'syncRoles',
                            entries: {
                              automatic: !get(
                                integrationSettings,
                                'integrationModules.syncRoles.automatic',
                                false,
                              ),
                            },
                          })
                        }
                      />
                    </ToggleSetting>
                  </ToggleOption>
                  <ToggleOption>
                    <ToggleLabel>
                      <Trans>Delete Roles</Trans>
                    </ToggleLabel>
                    <ToggleSetting>
                      <SwitchYesNo
                        width={64}
                        value={get(
                          integrationSettings,
                          'integrationModules.deleteRoles.enabled',
                          false,
                        )}
                        onChange={() =>
                          changeIntegrationModules({
                            module: 'deleteRoles',
                            entries: {
                              enabled: !get(
                                integrationSettings,
                                'integrationModules.deleteRoles.enabled',
                                false,
                              ),
                              automatic: get(
                                integrationSettings,
                                'integrationModules.deleteRoles.enabled',
                                false,
                              )
                                ? false
                                : get(
                                    integrationSettings,
                                    'integrationModules.deleteRoles.automatic',
                                    false,
                                  ),
                            },
                          })
                        }
                      />
                    </ToggleSetting>
                    <ToggleSetting>
                      <SwitchYesNo
                        disabled={
                          !get(integrationSettings, 'integrationModules.deleteRoles.enabled', false)
                        }
                        width={64}
                        value={get(
                          integrationSettings,
                          'integrationModules.deleteRoles.automatic',
                          false,
                        )}
                        onChange={() =>
                          changeIntegrationModules({
                            module: 'deleteRoles',
                            entries: {
                              automatic: !get(
                                integrationSettings,
                                'integrationModules.deleteRoles.automatic',
                                false,
                              ),
                            },
                          })
                        }
                      />
                    </ToggleSetting>
                  </ToggleOption>
                </>
              )}
            </ModulesWrapper>
          </SettingBlock>
        </IntegrationSettingsSection>
      </MainContent>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    users: state.users.data,
    teams: state.teams.data,
  };
};

export default withI18n()(connect(mapStateToProps)(UpdateIntegrationModal));
