import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getGoalsUpdate(id) {
  return cloudRequest(serverRoutes.goalsUpdates.getGoalsUpdate(id));
}

export function getGoalsUpdates({ goalId, populate = [], isTotal = false, startDate }) {
  return cloudRequest(serverRoutes.goalsUpdates.getGoalsUpdates, {
    goalId,
    populate,
    isTotal,
    startDate,
  });
}

export function updateGoalsUpdate(id, comment) {
  return cloudRequest(serverRoutes.goalsUpdates.updateGoalsUpdate(id), {}, { comment });
}
