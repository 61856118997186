import React, { ReactNode, useEffect, useState } from 'react';

import { ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { get } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS, ICON_SIZES } from '~/components/Icon';
import PaginationBar from '~/components/PaginationBar';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TablePlaceholder } from '~/components/TablePlaceholder';
import UserDevelopmentCycleModal from '~/components/UserDevelopmentCycleModal';
import { CalendarDropdown, DateOption } from '~/pages/Conversations/components/CalendarDropdown';
import { TableHeader } from '~/pages/Conversations/components/TableHeader';

import { COLUMNS, SORT_OPTIONS } from './columns';

import { CONVERSATION_COLLECTION_TYPES } from '~/constants';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import { getSelectedRole } from '~/selectors/baseGetters';
import { getConversationsAndReviews } from '~/services/userConversations';
import { getConversationPath } from '~/utils/userConversationsUtils';

import {
  Wrapper,
  FilterMargin,
  StyledButton,
  StyledTable,
  FiltersWrapper,
  FilterWrapper,
} from '../../design';

import type { IReviewOld, IUser } from '@learned/types';

export interface IType {
  id: string;
  key: string;
  name: string;
  translated: (i18n: any) => ReactNode;
}

type IFilter = {
  isShowFilters: boolean;
  search: string;
  sortBy: SORT_OPTIONS;
  selectedDateOption: DateOption | undefined;
};

const initialFilters = {
  isShowFilters: false,
  search: '',
  statuses: [],
  types: [],
  sortBy: SORT_OPTIONS.DATE_LATER_SOON,
  selectedDateOption: undefined,
};

const LS_KEY = 'LS_DEVELOPMENT_MEETINGS_USER_PUBLIC';

const Meetings = ({ user }: { user: IUser }) => {
  const { i18n } = useLingui();
  const params = useParams();
  const userId = get(params, 'userId') || user.id;

  const history = useHistory();
  const selectedRole = useSelector(getSelectedRole);
  const [items, setItems] = useState<IReviewOld[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const { pagination, changePagination, resetPagination } = usePagination(3);
  const [currentFilters, setCurrentFilters] = useState<IFilter>(initialFilters);
  const [isPreviewYearModal, setIsPreviewYearModal] = useState(false);
  const { isShowFilters: _isShowFilters, ...debCurrentFilters } = useDebounce(currentFilters, 300); // isShowFilters does not affect on reFetch
  const $loading = useBoolState(false);

  const fetchData = async () => {
    $loading.on();

    const {
      conversations: items,
      total,
      upcoming,
    } = await getConversationsAndReviews({
      search: currentFilters.search || null,

      // filters
      ...(currentFilters.selectedDateOption && {
        startDate: currentFilters.selectedDateOption.fromDate,
      }),
      ...(currentFilters.selectedDateOption && {
        endDate: currentFilters.selectedDateOption.toDate,
      }),

      // requirements
      // when date is selected  use range filter to include past events also
      tabsType: currentFilters.selectedDateOption ? 'range' : 'upcoming',
      collectionType: CONVERSATION_COLLECTION_TYPES.CONVERSATION.key,
      userId,

      // options
      skip: pagination.skip,
      limit: pagination.limit,
      order: currentFilters.sortBy,
    });

    setItems(Object.values(items));
    // when there is a date selected we should base on the total as it could have past events also
    setTotalCount(currentFilters.selectedDateOption ? total : upcoming);
    $loading.off();
  };

  // first render
  useEffect(() => {
    // get filters from localStorage
    const localStorageData = localStorage.getItem(LS_KEY);
    const isLocalStorageData = !isEmpty(localStorageData);

    if (isLocalStorageData) {
      const parsedData = JSON.parse(localStorageData as string);

      setCurrentFilters(parsedData);

      // set pagination from localstorage
      if (parsedData.pagination) {
        changePagination(parsedData.pagination);
      }
    }

    // eslint-disable-next-line
  }, []);

  // change filters fetch
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, [userId, pagination.limit, pagination.skip, JSON.stringify(debCurrentFilters), user]);

  const handleChangeItemsPerPage = ({ limit }: { limit: number }) => {
    const newPagination = {
      ...pagination,
      limit,
    };
    onPaginationChange(newPagination);
  };

  const onPageChangeClick = async ({ index, skip }: { index: number; skip: number }) => {
    const newPagination = {
      ...pagination,
      skip,
      index,
    };
    onPaginationChange(newPagination);
  };

  // admin can not create meetings
  // only coach or user
  const actionButton =
    selectedRole === ROLES.ADMIN
      ? undefined
      : {
          label: t`Create`,
          onClick: () => {
            history.push(
              routes.CONVERSATION_CREATE.build(
                undefined,
                // @ts-ignore
                {
                  isBackPath: true,
                  // @ts-ignore
                  hash: 'meetings',
                  query: { users: [userId] },
                },
              ),
            );
          },
        };

  const onCurrentFiltersChange = (newFilters: IFilter) => {
    setCurrentFilters(newFilters);

    // store in localstorage
    localStorage.setItem(LS_KEY, JSON.stringify(newFilters));
  };

  const onPaginationChange = (newPagination: typeof pagination) => {
    changePagination(newPagination);

    // store in localstorage
    localStorage.setItem(LS_KEY, JSON.stringify({ ...currentFilters, pagination: newPagination }));
  };

  const filters = {
    isShowFilters: currentFilters.isShowFilters,
    search: currentFilters.search,
    setSearch: (value: string) => {
      const newFilters = { ...currentFilters, search: value };
      onCurrentFiltersChange(newFilters);
      resetPagination();
    },

    // @ts-ignore
    onChangeFilter: (key, value) => {
      const newFilters = { ...currentFilters, [key]: value };
      onCurrentFiltersChange(newFilters);

      // do not reset pagination for keys
      if (!['isShowFilters'].includes(key)) {
        resetPagination();
      }
    },
    resetFilters: () => onCurrentFiltersChange(initialFilters),
    selectedDateOption: currentFilters.selectedDateOption,
  };

  const onItemClick = {
    column: 'name',
    onClick: (item: any) => {
      const path = getConversationPath({
        conversation: item,
        selectedRole,
        userId: user.id,
        user,
        teams: undefined,
      });
      history.push(path as string);
    },
  };

  return (
    <Wrapper>
      <TableHeader
        filters={filters}
        actionButton={actionButton}
        actionButtonIcon={ICONS.ADD_PLUS}
        headerTitle={i18n._(t`Meetings`)}
      >
        <FilterMargin>
          <StyledButton
            icon={filters.isShowFilters ? ICONS.HIDE : ICONS.SHOW}
            label={filters.isShowFilters ? i18n._(t`Hide filters`) : i18n._(t`Show filters`)}
            variant={ButtonVariant.TEXT_PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={() => filters.onChangeFilter('isShowFilters', !filters.isShowFilters)}
          />
        </FilterMargin>
      </TableHeader>

      {filters.isShowFilters && (
        <FiltersWrapper>
          <CalendarDropdown
            disabled={false}
            selectedItem={filters.selectedDateOption}
            // @ts-ignore
            setSelectedItem={(value: IType[]) =>
              filters.onChangeFilter('selectedDateOption', value)
            }
            filterOptions={[2, 1, 5, 6, 9]}
          />
          <FilterWrapper>
            <Button
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              label={i18n._(t`Reset all filters`)}
              onClick={filters.resetFilters}
              icon={ICONS.CLOSE}
              iconSize={ICON_SIZES.SMALL}
            />
          </FilterWrapper>
        </FiltersWrapper>
      )}

      <ShowSpinnerIfLoading loading={$loading.value}>
        <StyledTable
          data={items}
          columns={COLUMNS}
          sortBy={currentFilters.sortBy}
          setSortBy={(sortBy: SORT_OPTIONS) =>
            onCurrentFiltersChange({ ...currentFilters, sortBy })
          }
          onColClick={onItemClick}
          hideHeaders={false}
        />

        {isEmpty(items) && (
          <TablePlaceholder
            isLoading={false}
            isFiltered={!!currentFilters.search.length}
            noResultText={i18n._(t`No meetings found`)}
            emptyStateText={i18n._(t`No meetings yet… Let's create one!`)}
          />
        )}

        {pagination && (
          <PaginationBar
            pagination={pagination}
            changePagination={onPageChangeClick}
            changePageSize={handleChangeItemsPerPage}
            count={totalCount}
            noShadow
            noBorder
            noTopBorder
            showCount
            itemLabel={i18n._(t`Meetings`)}
          />
        )}
      </ShowSpinnerIfLoading>

      {isPreviewYearModal && (
        <UserDevelopmentCycleModal onClose={() => setIsPreviewYearModal(false)} />
      )}
    </Wrapper>
  );
};

export { Meetings };
