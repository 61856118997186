import { serverRoutes } from '~/server_config';

import cloudRequest from '../cloudRequest';

export const getGoalTask = (goalTaskId) => {
  return cloudRequest(serverRoutes.goalTasks.getGoalTask(goalTaskId));
};

export const updateGoalTask = (goalTaskId, body) => {
  return cloudRequest(serverRoutes.goalTasks.updateGoalTask(goalTaskId), null, body);
};
