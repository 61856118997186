import sanitizeHtml from 'sanitize-html';

// <u></u> - removed by sanitize-html
// so we allow it manually
const defaultSettings = {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(['u', 'span', 'hi', 'h2', 'a']),
  allowedAttributes: {
    p: ['style'],
    ol: ['style', 'class'],
    ul: ['style', 'class'],
    li: ['class', 'style'],
    a: ['href', 'target'],
    '*': ['style'],
  },
  allowedStyles: {
    '*': {
      // Match HEX and RGB
      color: [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
      'text-align': [/^left$/, /^right$/, /^center$/],
      // Match any number with px, em, or %
      'font-size': [/^\d+(?:px|em|%)$/],
    },
    p: {
      'font-size': [/^\d+rem$/],
    },
  },
};

export default (value, isWithoutStyles = false) => {
  const settings = isWithoutStyles
    ? Object.assign({}, defaultSettings, { allowedAttributes: {} })
    : defaultSettings;
  return sanitizeHtml(value, settings);
};
