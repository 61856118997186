import React, { useContext } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import size from 'lodash/size';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import Button from '~/components/Button';
import { ConfirmModal } from '~/components/ConfirmModal';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import { ConversationSetupContext } from '~/pages/ConversationSetup';
import { TABS_ENUM } from '~/pages/PassportPage';

import { CONVERSATION_STATUSES, ROLES } from '~/constants';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { getSelectedRole, getUser } from '~/selectors/baseGetters';
import { getCurrentConversation } from '~/selectors/currentConversation';
import {
  createConversation,
  deleteConversation,
  updateConversation,
  updateConversationParticipants,
  updateConversationPublished,
} from '~/services/conversations';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

const ButtonWrapper = styled.div`
  margin-right: 8px;
`;

const HeaderActions = () => {
  const { i18n } = useLingui();
  const history = useHistory();
  const user = useSelector(getUser);
  const conversation = useSelector(getCurrentConversation);
  const role = useSelector(getSelectedRole);
  const $isShowConfirmModal = useBoolState(false);
  const { addToast } = useToasts();
  const { error, loading, initConversation, isAdmin } = useContext(ConversationSetupContext);

  const isUpdatePublished =
    conversation.id && conversation.status !== CONVERSATION_STATUSES.DRAFT.key;

  const handleClose = () => {
    const url = new URL(window.location.href);
    const from = location.hash.slice(1)
      ? url.searchParams.get('from') + '#' + location.hash.slice(1)
      : url.searchParams.get('from');

    if (from) {
      return history.push(from);
    }
    history.goBack();
  };

  const updateConversationPublishedCall = async () => {
    let data = {
      name: conversation.name,
      description: conversation.description,
      talkingPoints: conversation.talkingPoints,
      participants: conversation.participants,
      repeat: conversation.repeat,
    };

    // admin can update only participants
    isAdmin
      ? await updateConversationParticipants(conversation.id, {
          participants: conversation.participants,
        })
      : await updateConversationPublished(conversation.id, data);

    // if changes in repeat rule -> redirect to overview page
    const newRepeat = data.repeat;
    const oldRepeat = initConversation.repeat;

    if (!isEmpty(newRepeat) && !isEmpty(oldRepeat) && !isEqual(newRepeat, oldRepeat)) {
      const userId =
        conversation.participants[0] === user.id
          ? conversation.participants[1]
          : conversation.participants[0];
      return role === ROLES.USER
        ? history.push(routes.CONVERSATIONS.build({}, { hash: TABS_ENUM.MEETINGS }))
        : history.push(
            routes.USER_PUBLIC_SKILL_PASSPORT.build({}, { userId, hash: TABS_ENUM.CONVERSATIONS }),
          );
    }
    handleClose();
  };

  const handleSubmit = async (isDraft = false) => {
    try {
      if (!isValid(isDraft)) {
        error.on();
        return;
      }

      loading.on();
      let data;

      if (isUpdatePublished) {
        // update published conversation
        const isRepeatRuleChanged = !isEqual(initConversation.repeat, conversation.repeat);
        return isRepeatRuleChanged
          ? $isShowConfirmModal.on()
          : await updateConversationPublishedCall();
      } else {
        // create conversation or update draft conversation
        data = {
          ...conversation,
          deadline: convertToTimeString(conversation.deadline, TIME_FORMATS.ISO),
          startDate: convertToTimeString(conversation.startDate, TIME_FORMATS.ISO),
          endDate: convertToTimeString(conversation.endDate, TIME_FORMATS.ISO),
          status: isDraft ? CONVERSATION_STATUSES.DRAFT.key : CONVERSATION_STATUSES.TODO.key,
        };

        if (data.id) {
          await updateConversation(data.id, data);
        } else {
          await createConversation(data);
        }
      }

      handleClose();
    } catch (e) {
      // cancel loading
      loading.off();
    }
  };

  const handleDelete = async () => {
    loading.on();
    await deleteConversation(conversation.id);
    handleClose();
  };

  const isValid = (isDraft) => {
    let isPass = true;
    if (isDraft) {
      return conversation.name;
    }

    if (!conversation.name) {
      addToast({
        title: i18n._(t`Warning`),
        subtitle: i18n._(t`Please add name for the conversation`),
        type: TOAST_TYPES.ERROR,
      });
      isPass = false;
    }

    if (size(conversation.participants) < 2) {
      addToast({
        title: i18n._(t`Warning`),
        subtitle: i18n._(t`Please add min 1 participant`),
        appearance: TOAST_TYPES.ERROR,
      });
      isPass = false;
    }
    return isPass;
  };

  return (
    <>
      {conversation.id && conversation.status === CONVERSATION_STATUSES.DRAFT.key && (
        <ButtonWrapper>
          <Button
            label={<Trans>Delete</Trans>}
            loading={loading.value}
            type="primary-border"
            onClick={handleDelete}
          />
        </ButtonWrapper>
      )}
      {!isUpdatePublished && (
        <Button
          type="white"
          height={48}
          loading={loading.value}
          label={conversation.id ? <Trans>Update draft</Trans> : <Trans>Save as draft</Trans>}
          disabled={!conversation.name}
          onClick={() => handleSubmit(true)}
        />
      )}
      <Button
        label={isUpdatePublished ? <Trans>Save</Trans> : <Trans>Publish</Trans>}
        onClick={() => handleSubmit()}
        loading={loading.value}
        {...(isUpdatePublished && {
          tooltip: i18n._(t`Update all future events for this conversation`),
        })}
        disabled={
          isUpdatePublished ? false : !conversation.name || size(conversation.participants) < 2
        }
      />
      {$isShowConfirmModal?.value && (
        <ConfirmModal
          title={i18n._(t`Are you sure?`)}
          onSubmit={updateConversationPublishedCall}
          onModalClose={() => {
            loading.off();
            $isShowConfirmModal.off();
          }}
          i18n={i18n}
          text={i18n._(
            t`You are about to update the repetion rule of a repeating conversation. This will delete all future conversations from ${convertToTimeString(
              initConversation.repeat.until,
            )} and generate new conversations.`,
          )}
        />
      )}
    </>
  );
};

export default HeaderActions;
