import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function createSurveyTheme() {
  return cloudRequest(serverRoutes.surveyThemes.createTheme);
}

export function updateSurveyTheme(surveyThemeId, surveyTheme) {
  return cloudRequest(serverRoutes.surveyThemes.updateTheme(surveyThemeId), {}, surveyTheme);
}

export function copySurveyTheme(surveyThemeId) {
  return cloudRequest(serverRoutes.surveyThemes.copyTheme(surveyThemeId));
}

export function getSurveyTheme(surveyThemeId, populate = []) {
  const body = { populate };
  return cloudRequest(serverRoutes.surveyThemes.getTheme(surveyThemeId), {}, body);
}

export function getSurveyThemes(filters, populate = []) {
  const body = {
    filters: {
      search: filters.search,
      status: filters.status,
    },
    options: {
      skip: filters.skip,
      limit: filters.limit,
      sortBy: filters.sortBy,
    },
    populate,
    onlyWithQuestions: filters.onlyWithQuestions,
  };
  return cloudRequest(serverRoutes.surveyThemes.getThemes, {}, body);
}

export function deleteSurveyThemes(surveyThemesIds) {
  return cloudRequest(serverRoutes.surveyThemes.deleteThemes, {}, { surveyThemesIds });
}
