import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AddPeopleAutoComplete from '~/components/AddPeopleAutoComplete';
import Button from '~/components/Button';
import CheckBox from '~/components/CheckBox';
import Modal from '~/components/Modal';
import Tooltip from '~/components/Tooltip';

import useBoolState from '~/hooks/useBoolState';
import { COLOR_PALETTE, COLORS } from '~/styles';

const CheckBoxBlock = styled.div`
  display: flex;
  align-items: center;
`;

const CheckBoxText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.TEXT_BLACK};
  margin-left: 8px;
`;

const FooterActions = styled.div`
  display: flex;
`;

const AddPeopleAutoCompleteModal = ({
  onModalClose,
  onSubmit,
  usersToHide,
  usersToAdd,
  title,
  singleChoice,
  preSelectedTeamsInFilter,
  preSelectedRoles,
  isTeamFilterDisabled,
  isIgnoreQuickSelect,
  isDontGetOwnCoaches,
  width = 625,
  buttonStyles = {},
  filterTeamsItems,
  isAddMyself,
  isTeamsFilterSearchAlsoCoaches,
  isTeamsFilterSearchAlsoMembers,
  usersToSearchCoaches,
  isWithCheckBox,
  checkBoxToolTipText,
  checkBoxText,
}) => {
  const { i18n } = useLingui();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const isChecked = useBoolState(true);
  const $loading = useBoolState();

  const onChangeSelectedUsers = (selectedUsers) => setSelectedUsers(selectedUsers);

  const closeModal = () => onModalClose();

  const addUsers = async () => {
    if (!isEmpty(selectedUsers)) {
      try {
        $loading.on();
        await onSubmit(selectedUsers, isChecked.value);
      } finally {
        $loading.off();
      }
    }
    closeModal();
  };

  const handleCheckBox = () => {
    isChecked.value ? isChecked.off() : isChecked.on();
  };

  return (
    <Modal
      title={title}
      onClose={closeModal}
      width={width}
      minWidth={width}
      minHeight="600px"
      contentStyles={{ background: COLOR_PALETTE.WHITE, height: '100%', padding: '0' }}
      hideFooter={false}
      footerLeft={
        isWithCheckBox && (
          <CheckBoxBlock onClick={handleCheckBox}>
            <CheckBox checked={isChecked.value} size={24} />
            <Tooltip tooltip={checkBoxToolTipText}>
              <CheckBoxText>{checkBoxText}</CheckBoxText>
            </Tooltip>
          </CheckBoxBlock>
        )
      }
      footerRight={
        <FooterActions>
          <Button
            styles={{
              height: '40px',
              marginLeft: '10px',
              textTransform: buttonStyles.textTransform || 'uppercase',
              padding: '0px 15px',
              fontSize: buttonStyles.fontSize || '12px',
              minWidth: '120px',
            }}
            label={`${i18n._(buttonStyles.prefix || t`Add`)} ${selectedUsers.length || 0} ${i18n._(
              t`users`,
            )}`}
            disabled={!(selectedUsers.length || 0)}
            type={buttonStyles.type || 'white'}
            onClick={addUsers}
            loading={$loading.value}
          />
        </FooterActions>
      }
    >
      <div>
        <AddPeopleAutoComplete
          isSelectMode={true}
          usersToHide={usersToHide}
          usersToAdd={usersToAdd}
          selectedUsers={selectedUsers}
          onChange={onChangeSelectedUsers}
          singleChoice={singleChoice}
          preSelectedTeamsInFilter={preSelectedTeamsInFilter}
          preSelectedRoles={preSelectedRoles}
          isTeamFilterDisabled={isTeamFilterDisabled}
          isIgnoreQuickSelect={isIgnoreQuickSelect}
          isDontGetOwnCoaches={isDontGetOwnCoaches}
          filterTeamsItems={filterTeamsItems}
          isAddMyself={isAddMyself}
          isTeamsFilterSearchAlsoCoaches={isTeamsFilterSearchAlsoCoaches}
          isTeamsFilterSearchAlsoMembers={isTeamsFilterSearchAlsoMembers}
          usersToSearchCoaches={usersToSearchCoaches}
        />
      </div>
    </Modal>
  );
};

AddPeopleAutoCompleteModal.propTypes = {
  onModalClose: PropTypes.func,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  usersToHide: PropTypes.array,
  usersToAdd: PropTypes.array,
  singleChoice: PropTypes.bool,
  preSelectedTeamsInFilter: PropTypes.arrayOf(PropTypes.string),
  preSelectedRoles: PropTypes.arrayOf(PropTypes.string),
  isTeamFilterDisabled: PropTypes.bool,
  isIgnoreQuickSelect: PropTypes.bool,
  isDontGetOwnCoaches: PropTypes.bool,
  filterTeamsItems: PropTypes.arrayOf(PropTypes.object),
  isAddMyself: PropTypes.bool,
  isTeamsFilterSearchAlsoCoaches: PropTypes.bool,
  isTeamsFilterSearchAlsoMembers: PropTypes.bool,
  usersToSearchCoaches: PropTypes.arrayOf(PropTypes.string),
  isWithCheckBox: PropTypes.bool,
};

AddPeopleAutoCompleteModal.defaultProps = {
  usersToHide: [],
  singleChoice: false,
};

export default React.memo(AddPeopleAutoCompleteModal);
