import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector, useDispatch } from 'react-redux';

import InfoPageNew from '~/components/FirstTimeSetupModalNew/InfoPageNew';
import { ProfileSettingsBlock } from '~/components/ProfileSettingsComponents/MainDashboard/ProfileSettingsBlock';

import useBoolState from '~/hooks/useBoolState';
import { getUser } from '~/selectors/baseGetters';
import getLang from '~/selectors/getLang';
import { updateUserProfile as updateUserProfileAction } from '~/store/auth/actions';

import { SectionHeader } from '../SectionHeader';

interface UserData {
  firstName: string;
  lastName: string;
  locale: string;
  gender?: string | null;
  dateOfBirth: string;
  email: string;
  phone: string;
  bio: string;
}

interface ProfileSetupType {
  avatarUploaded: boolean;
  validation: {
    valid: boolean;
  };
}

const validators = (
  userData: UserData | undefined,
  profileSetupData: ProfileSetupType | undefined,
) => {
  const phoneRegex = new RegExp('^\\d{10}$');
  const nameRegex = new RegExp('^[^\\s]+(?:$|.*[^\\s]+$)');
  const validation = {
    validAvatar: true,
    validGender: true,
    dateOfBirth: {
      isValid: true,
      message: '',
    },
    valid: true,
    phone: {
      isValid: true,
      message: '',
    },
    firstName: {
      isValid: true,
      message: '',
    },
    lastName: {
      isValid: true,
      message: '',
    },
  };

  if (!profileSetupData || !profileSetupData.avatarUploaded) {
    validation.validAvatar = false;
    validation.valid = false;
  }

  if (!userData || !userData.firstName || userData.firstName.length === 0) {
    validation.valid = false;
    validation.firstName.isValid = false;
    validation.firstName.message = t`First name is required`;
  } else if (userData.firstName.length > 50) {
    validation.valid = false;
    validation.firstName.isValid = false;
    validation.firstName.message = t`Exceeds maximum length of 50 characters`;
  } else if (!nameRegex.test(userData.firstName)) {
    validation.valid = false;
    validation.firstName.isValid = false;
    validation.firstName.message = t`Whitespace not allowed`;
  }

  if (!userData || !userData.lastName || userData.lastName.length === 0) {
    validation.valid = false;
    validation.lastName.isValid = false;
    validation.lastName.message = t`Last name is required`;
  } else if (userData.lastName.length > 50) {
    validation.valid = false;
    validation.lastName.isValid = false;
    validation.lastName.message = t`Exceeds maximum length of 50 characters`;
  } else if (!nameRegex.test(userData.lastName)) {
    validation.valid = false;
    validation.lastName.isValid = false;
    validation.lastName.message = t`Whitespace not allowed`;
  }

  if (userData && userData.phone && !phoneRegex.test(userData.phone)) {
    validation.valid = false;
    validation.phone.isValid = false;
    validation.phone.message = t`Invalid phone`;
  } else if (userData && userData.phone && userData.phone.length > 10) {
    validation.valid = false;
    validation.phone.isValid = false;
    validation.phone.message = t`Exceeds maximum length of 10 characters`;
  }

  if (!userData || !userData.dateOfBirth) {
    validation.valid = false;
    validation.dateOfBirth.isValid = false;
    validation.dateOfBirth.message = t`Invalid date of birth`;
  }

  return validation;
};

const InformationBlock = () => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const lang = useSelector(getLang);
  const $showFormErrors = useBoolState(false);
  const loadingState = useState();
  const userDataState = useState<UserData>();
  const [userData, setUserData] = userDataState;
  const profileSetupDataState = useState<ProfileSetupType>();
  const [profileSetupData, setProfileSetupData] = profileSetupDataState;
  const [userAgreement, setUserAgreement] = useState(true);
  const [isEditable, setIsEditable] = useState<boolean>(false);

  if (user && !userData) {
    setUserData({
      firstName: user.firstName,
      lastName: user.lastName,
      locale: user.locale || lang,
      gender: user.gender || null,
      dateOfBirth: user.dateOfBirth || '',
      email: user.email,
      phone: user.phone,
      bio: user.bio,
    });
  }

  if (user && !profileSetupData) {
    setProfileSetupData({
      avatarUploaded: !!user.avatarUrl,
      validation: { valid: true },
    });
  }

  const validations = validators(userData, profileSetupData);

  const onCompleteClick = async () => {
    if (!validations.valid || !userAgreement) {
      $showFormErrors.on();
      return;
    }

    await dispatch(
      updateUserProfileAction({
        ...userData,
        bio: userData?.bio || ' ',
      }),
    );
    setIsEditable(!isEditable);
  };

  const onCancelClick = () => {
    setIsEditable(!isEditable);
    $showFormErrors.off();
    if (user) {
      setUserData({
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        locale: user.locale || lang,
        gender: user.gender || null,
        dateOfBirth: user.dateOfBirth || '',
        email: user.email,
        phone: user.phone || '',
        bio: user.bio || '',
      });
    }
  };

  const Header = (
    <SectionHeader
      title={i18n._(t`Personal information`)}
      isEditMode={isEditable}
      isEditable={true}
      onCancel={onCancelClick}
      onSave={onCompleteClick}
      onEdit={() => setIsEditable(!isEditable)}
    />
  );

  const Content = userData && (
    <InfoPageNew
      user={user}
      userDataState={userDataState}
      profileSetupDataState={profileSetupDataState}
      loadingState={loadingState}
      validation={validations}
      isShowError={$showFormErrors.value}
      submitTo={onCompleteClick}
      agreement={userAgreement}
      onAgreement={setUserAgreement}
      skipFirstTimeSetup={true}
      layOut="1fr 1fr 10%"
      isEditable={isEditable}
    />
  );

  return <ProfileSettingsBlock header={Header} content={Content} />;
};

export { InformationBlock };
