import { CONNECTION_STATUSES } from '@learned/constants';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import { createAction } from 'redux-act';

import {
  getEvalGoalCyclesIDs,
  hasGoalBusinessEvalQuestion,
  hasGoalLearningEvalQuestion,
} from '~/pages/ReviewSetup/helpers';

import { getCurrentReview } from '~/selectors/baseGetters';
import { getCompanyUsers } from '~/services/users';

export const setCurrentReview = createAction('set current Review');
export const updateCurrentReviewTemplate = createAction('update current review template');
export const updateCurrentReview = createAction('update current Review');
export const createCurrentReviewQuestion = createAction('create current review question');
export const updateCurrentReviewQuestion = createAction('update current review question');
export const removeCurrentReviewQuestion = createAction('remove current review question');
export const changeCurrentReviewQuestionOrder = createAction(
  'change order of current review question',
);

export function fetchReviewUsers() {
  return async function (dispatch, getState) {
    const review = getCurrentReview(getState());
    const isJoinGoals = hasGoalBusinessEvalQuestion(review) || hasGoalLearningEvalQuestion(review);
    const goalCyclesIds = getEvalGoalCyclesIDs(review);
    const { data } = await getCompanyUsers(
      {
        users: review.users.map((r) => r.id || r),
        goalCycles: isJoinGoals ? goalCyclesIds : undefined,
        statuses: [CONNECTION_STATUSES.ACTIVE, CONNECTION_STATUSES.INACTIVE],
      },
      ['jobProfiles', 'coaches'],
    );
    const users = values(data?.users ?? {});

    // need this check, otherwise we join all company users
    if (!isEmpty(review.users)) {
      dispatch(updateCurrentReview({ users }));
    }

    const reviewWithUsers = {
      ...review,
      ...(!isEmpty(review.users) && { users }), // need this check, otherwise we join all company users
    };

    return reviewWithUsers;
  };
}
