import React, { useEffect, useMemo, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { CardSection } from './CardSection';

import ActivityIcon from '~/assets/activity-inactive.svg';
import PathIcon from '~/assets/path-symbol-inactive.svg';
import RocketIcon from '~/assets/rocket.png';

import { getLearningKPIs } from '~/services/activities';

import type { ICardSectionProps } from './CardSection';

const Wrapper = styled.div`
  display: flex;
  gap: 34px;
  width: 100%;
`;

interface IKPIValue {
  total: number;
  timeFrame: number;
}

interface IKPIsData {
  activities: IKPIValue;
  paths: IKPIValue;
  activitiesCompleted: IKPIValue;
}

interface ITopKPIsProps {
  startDate?: string;
}

const TopKPIs = ({ startDate }: ITopKPIsProps) => {
  const { i18n } = useLingui();
  const [kpisData, setKpisData] = useState<IKPIsData>();
  const [isLoading, setIsLoading] = useState(false);

  const KPIs: ICardSectionProps[] = useMemo(() => {
    let completedActivitiesValueByTime = 0;
    if (kpisData?.activitiesCompleted?.total && kpisData?.activitiesCompleted?.timeFrame) {
      completedActivitiesValueByTime =
        (kpisData?.activitiesCompleted?.timeFrame / kpisData?.activitiesCompleted?.total) * 100;
    }

    return [
      {
        title: i18n._(t`Library activities`),
        value: kpisData?.activities?.total || 0,
        valueByTime: kpisData?.activities?.timeFrame,
        icon: {
          url: ActivityIcon,
          backgroundColors: ['#ebf1fe', ' #b8f8fc'],
          width: 10,
          height: 10,
        },
      },
      {
        title: i18n._(t`Library paths`),
        value: kpisData?.paths?.total || 0,
        valueByTime: kpisData?.paths?.timeFrame,
        icon: {
          url: PathIcon,
          backgroundColors: ['#e6ddfe ', ' #c8b8fc'],
          width: 20,
          height: 10,
        },
      },
      {
        title: i18n._(t`Completed activities`),
        value: kpisData?.activitiesCompleted?.total || 0,
        valueByTime: Number(completedActivitiesValueByTime.toFixed(0)),
        valueByTimeSymbol: '%',
        icon: {
          url: RocketIcon,
          backgroundColors: ['#f7f9ff', ' #ebf1fe'],
          width: 28,
          height: 28,
        },
      },
    ];
  }, [
    i18n,
    kpisData?.activities?.timeFrame,
    kpisData?.activities?.total,
    kpisData?.activitiesCompleted?.timeFrame,
    kpisData?.activitiesCompleted?.total,
    kpisData?.paths?.timeFrame,
    kpisData?.paths?.total,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const { data } = await getLearningKPIs({ startDate: startDate || new Date().toISOString() });

      setKpisData(data);
      setIsLoading(false);
    };

    fetchData();
  }, [startDate]);

  return (
    <Wrapper>
      {KPIs?.map(({ title, value, valueByTime, valueByTimeSymbol, icon }) => (
        <CardSection
          key={title}
          title={title}
          value={value}
          valueByTime={valueByTime}
          valueByTimeSymbol={valueByTimeSymbol}
          icon={icon}
          isLoading={isLoading}
        />
      ))}
    </Wrapper>
  );
};

export { TopKPIs };
