import { ROLES } from '~/constants';
import { pageDashboard, pageSupervisionUser } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'user-paths';
const defaultPathId = ':pathId';

// dashboard
export const USER_PATH = new LinkConstructor(
  [ROLES.USER, ROLES.COACH, ROLES.ADMIN],
  ({ pathId = defaultPathId }) => pageDashboard(routeName, { id: pathId }),
);

// supervision
export const USER_PATH_SUPERVISION_USER = new LinkConstructor(
  [ROLES.COACH, ROLES.ADMIN],
  ({ pathId = defaultPathId, userId = ':userId' }) =>
    pageSupervisionUser(routeName, { id: pathId, userId }),
);
