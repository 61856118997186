import React, { useMemo } from 'react';

import styled from 'styled-components';

import { TableGrid } from '~/components/TableGrid';

import { SKILL_CATEGORY_COLUMNS } from './SkillCategory.columns';
import { convertSkillsDataToTable } from './utils';

import type { ISelectedSkillTemplate } from '~/@types/job';

import type { ISkillCategory, ISkillTemplate } from '@learned/types';

const Wrapper = styled.div`
  margin-top: 23px;
`;

interface ISkillTemplateTable {
  skillTemplates: ISkillTemplate[];
  skills: ISelectedSkillTemplate['skills'];
  skillCategoryId: string;
  skillCategories: ISkillCategory[];
}

const SkillTemplateTable = ({
  skillTemplates,
  skills,
  skillCategoryId,
  skillCategories,
}: ISkillTemplateTable) => {
  const data = useMemo(() => {
    const skillCategory = skillCategories.find((item) => item.id === skillCategoryId);
    if (!skillCategory) {
      return [];
    }

    return convertSkillsDataToTable({
      skillTemplates,
      skillCategory,
      skills,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify([skillTemplates, skills])]);

  return (
    <Wrapper>
      <TableGrid thColumnPadding="16px 19px" data={data} columns={SKILL_CATEGORY_COLUMNS} />
    </Wrapper>
  );
};

export { SkillTemplateTable };
