import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Button from '~/components/Button';
import ExportIcon from '~/components/Icons/Export';
import { SearchField } from '~/components/Text';

import { COLOR_PALETTE } from '~/styles';

export interface IHeaderProps {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  isExportCSVButtonEnabled?: boolean;
  exportCSV: () => void | Promise<void>;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${COLOR_PALETTE.WHITE};
  padding: 15px 24px;
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
`;

const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  margin-right: 12px;
  width: 340px;
`;

const ActionButton = styled(Button)`
  white-space: nowrap;
  height: 32px;
  box-shadow: 0 2px 4px 0 rgba(191, 191, 191, 0.5);

  &:hover {
    font-weight: 600;
    box-shadow: unset;
  }
`;

const Header = ({
  search,
  setSearch,
  isExportCSVButtonEnabled = true,
  exportCSV,
}: IHeaderProps) => {
  const { i18n } = useLingui();

  return (
    <Wrapper>
      <SearchFieldWrapper
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.currentTarget.value)}
        value={search}
        placeholder={i18n._(t`Search`)}
        style={{
          borderRadius: '6px',
          fontSize: '14px',
          fontWeight: 600,
        }}
      />
      <ActionButton
        // @ts-ignore
        type={'shadow'}
        label={i18n._(t`Export csv`)}
        disabled={!isExportCSVButtonEnabled}
        iconLeft={<ExportIcon size={20} />}
        onClick={exportCSV}
      />
    </Wrapper>
  );
};

export { Header };
