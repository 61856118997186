import styled from 'styled-components';

import { Button } from '~/components/Buttons';
import Switch from '~/components/Switch';

import { COLORS } from '~/styles';

export const HeaderWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  width: fit-content;
  border-radius: 6px 6px 0 0;
`;

export const StyledHeaderButton = styled(Button)`
  color: ${COLORS.TEXT_HOVER};
` as typeof Button;

export const FilterForQualityWrapper = styled.div`
  margin-left: 20px;
  display: flex;
`;

export const FilterForQualityTitle = styled.div`
  font-size: 14px;
`;

export const StyledSwitch = styled(Switch)`
  margin-left: 0px;
  margin-right: 10px;
`;
