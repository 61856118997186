import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ActionItemBlock = styled.div`
  display: flex;
  align-items: center;
  border-left: solid 1.2px ${COLORS.BORDER_HARD};
  justify-content: center;
  padding-left: 17px;
  padding-right: 17px;
  gap: 10px;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 151px;
  margin-top: 49px;
`;

export const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 750px;
`;
