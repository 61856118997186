import styled from 'styled-components';

export default styled.div`
  min-height: 56px;
  border-radius: 6px;
  border: solid 1px #f9fafc;
  background-color: #f6f8fc;
  color: #000000;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
  box-sizing: border-box;
`;
