import React from 'react';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import type { IMultiLangString } from '@learned/types';

interface FocusAreaNameProps {
  focusArea: {
    name: IMultiLangString;
  };
}

function FocusAreaName({ focusArea }: FocusAreaNameProps) {
  const getMultiLangString = useMultiLangString();

  return <span>{getMultiLangString(focusArea.name)}</span>;
}

export { FocusAreaName };
