import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Separator = styled.div<{
  marginTop?: string;
  marginBottom?: string;
}>`
  border-top: solid 1px ${COLORS.BORDERS};
  width: 100%;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '9px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0px')};
`;

export const SectionFieldWrapper = styled.div<{
  marginTop?: string;
  marginBottom?: string;
}>`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0px')};
`;

export const SectionFieldLabel = styled.div`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.INPUT_TITLE};
`;

export const SectionFieldValue = styled.div`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
`;

export const SubSectionTitle = styled.div<{
  marginTop?: string;
  marginBottom?: string;
}>`
  color: ${COLORS.TEXT_MAIN};
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0px')};
`;

export const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

export const ExplanationText = styled.div<{
  marginTop?: string;
  marginBottom?: string;
}>`
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${COLORS.SUBTEXT};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '10px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '16px')};
`;

export const SectionHeaderWithIconRow = styled.div<{
  marginTop?: string;
  marginBottom?: string;
}>`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0px')};
  display: flex;
  gap: 8px;
  align-items: center;
`;
