import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import IconMenu from '~/components/IconMenu';
import { TableCol, TableRow } from '~/components/UI/TableCard';
import { Header3 } from '~/components/UI/Typographics/headers';

import { COLORS } from '~/styles';
import convertToTimeString from '~/utils/convertToTimeString';

const ColWrapp = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DateCol = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

function GoalCycleItem({ goalCycle, removeItem, updateItem, i18n, linkedReviews = [] }) {
  const isDeleteDisabled = !isEmpty(linkedReviews);
  // display only first review name + amount of remaining reviews
  const getReviewsString = () => {
    const amount = size(linkedReviews);
    return i18n._(t`"${linkedReviews[0].name}"${amount > 1 ? ` + ${amount - 1}item(s)` : ''}`);
  };

  const menuItems = [
    {
      label: i18n._(t`Edit`),
      action: () => updateItem(goalCycle),
    },
    {
      label: i18n._(t`Delete`),
      action: () => removeItem(goalCycle.id),
      isDisabled: isDeleteDisabled,
      tooltip:
        isDeleteDisabled &&
        i18n._(t`Goal cycles that have been selected in a review can’t be deleted. First
delete the review if you want to delete this cycle: ${getReviewsString()}`),
    },
  ];

  return goalCycle ? (
    <TableRow key={goalCycle.id} $border>
      <TableCol>
        <Header3>{goalCycle.name}</Header3>
      </TableCol>
      <TableCol>
        <DateCol>{convertToTimeString(goalCycle.startDate)}</DateCol>
      </TableCol>
      <TableCol>
        <ColWrapp>
          <DateCol>{convertToTimeString(goalCycle.endDate)}</DateCol>
          <IconMenu items={menuItems} />
        </ColWrapp>
      </TableCol>
    </TableRow>
  ) : null;
}

GoalCycleItem.propTypes = {
  linkedReviews: PropTypes.array,
  goalCycle: PropTypes.object,
  removeItem: PropTypes.func,
  updateItem: PropTypes.func,
};

export default withI18n()(GoalCycleItem);
