import { createReducer } from 'redux-act';

import { updateNumberOfItemsPerPageAction } from '~/store/pagination/actions';

const INITIAL_STATE = 10;

export const pagination = createReducer({}, INITIAL_STATE).on(
  updateNumberOfItemsPerPageAction,
  (_state, payload) => {
    return payload;
  },
);
