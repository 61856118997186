import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { AutocompleteFilterGoalCycles } from '~/components/AutocompleteFilters';
import { GoalDashboardModalContent } from '~/components/GoalDashboardCardModal/components/GoalDashboardModalContent';
import { GoalTabs, TABS_ENUM } from '~/components/GoalsBlock/components/GoalTabs';
import GoalItems from '~/components/GoalsBlock/sections/GoalItems';
import Modal from '~/components/Modal';
import PaginationBar from '~/components/PaginationBar';
import SearchSelectButton from '~/components/SearchSelectButton';
import SvgIcon from '~/components/SvgIcon';
import { SearchField } from '~/components/Text';
import TextField from '~/components/TextField';

import backIcon from '~/assets/ic-arrow-back-24-px.svg';

import { GOAL_TYPES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import useSearchState from '~/hooks/useSearchState';
import * as goalsService from '~/services/goals';
import { COLORS } from '~/styles';

const StyledPaginationBar = styled(PaginationBar)`
  border-bottom: 1px solid ${COLORS.BG_PAGE};
`;

const Section = styled.div`
  padding: 0 23px 0 18px;
  border-bottom: 1px solid ${COLORS.BG_PAGE};
`;

const RowItem = styled.div`
  margin-right: 8px;
  margin-bottom: 8px;
`;

const Filters = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 12px 4px 12px;
  flex-wrap: wrap;
`;

const StyledSearch = styled(SearchField)`
  width: 244px;
  ${TextField} {
    border-radius: 6px;
    font-size: 14px;
    height: 32px;
  }
`;

const BackButton = styled.button`
  border: none;
  background: none;
  padding: 4px;
  cursor: pointer;
`;

const StyledGoalDashboardModalContent = styled(GoalDashboardModalContent)`
  margin-bottom: 0;
  border: none;
`;

const GoalsOverview = ({
  reviewId,
  onModalClose,
  forUser,
  preSelectedGoalCycle,
  preSelectedGoalType,
  viewerId,
}) => {
  const { i18n } = useLingui();
  const [currentTab, setCurrentTab] = useState(TABS_ENUM.individual);
  const { pagination, changePagination, resetPagination } = usePagination(10);
  const [goals, setGoals] = useState([]);
  const $searchFilter = useSearchState();
  const [typesFilter, setTypesFilter] = useState([preSelectedGoalType]);
  const [goalCyclesFilter, setGoalCyclesFilter] = useState([preSelectedGoalCycle]);
  const debouncedSearch = useDebounce($searchFilter.value, 300); // to send request not immediately, but with delay  const [goals, setGoals] = useState();
  const [totalGoals, setTotalGoals] = useState();
  const $loading = useBoolState();
  const [goalId, setGoalId] = useState();
  const [fetchTrigger, setFetchTrigger] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const fetchGoals = async () => {
      $loading.on();

      const response = await goalsService.getGoals(prepareFetchBody());

      if (isMounted) {
        setGoals(response.data);
        setTotalGoals(response.total);
        $loading.off();
      }
    };

    fetchGoals();

    return () => void (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, debouncedSearch, typesFilter, goalCyclesFilter, fetchTrigger]);

  const fetchTypes = {
    [TABS_ENUM.team]: [GOAL_TYPES.TEAM.key],
    [TABS_ENUM.organisation]: [GOAL_TYPES.COMPANY.key],
    [TABS_ENUM.individual]: [GOAL_TYPES.BUSINESS.key, GOAL_TYPES.PERSONAL.key],
  };

  const prepareFetchBody = () => ({
    limit: pagination.limit,
    skip: pagination.skip,
    isMyGoalsOnly: true,
    isGetProgress: true,
    owners: [forUser],
    search: debouncedSearch,
    types:
      typesFilter.length > 0 && currentTab === TABS_ENUM.individual
        ? typesFilter
        : fetchTypes[currentTab],
    goalCycles: goalCyclesFilter.map((cycle) => cycle.id),
    join: ['children'],
    forUser,
  });

  const GoalPreviewTitle = (
    <div>
      <BackButton onClick={() => setGoalId(null)}>
        <SvgIcon classNameIcon="header-page-name_icon" url={backIcon} width="30px" height="16px" />
      </BackButton>
      {i18n._(t`Goal preview`)}
    </div>
  );

  return (
    <Modal
      title={goalId ? GoalPreviewTitle : i18n._(t`Goal overview`)}
      width={1004}
      minWidth={1004}
      minHeight={'350px'}
      contentStyles={{ background: '#fff', height: '100%', padding: '0' }}
      headerStyles={{ padding: '0 24px' }}
      onClose={onModalClose}
      hideFooter
    >
      {goalId ? (
        <StyledGoalDashboardModalContent
          goalId={goalId}
          reviewId={currentTab === TABS_ENUM.individual && reviewId}
          viewerId={viewerId}
          onGoalChanged={() => {
            setFetchTrigger((s) => !s);
          }}
        />
      ) : (
        <>
          <Filters>
            <RowItem>
              <StyledSearch
                placeholder={i18n._(t`Search for goal`)}
                value={$searchFilter.value}
                onChange={$searchFilter.setForInput}
              />
            </RowItem>
            <RowItem>
              <AutocompleteFilterGoalCycles
                checkedList={goalCyclesFilter}
                onChange={setGoalCyclesFilter}
                styles={{ height: '32px', width: '164px' }}
              />
            </RowItem>
            {currentTab === TABS_ENUM.individual && (
              <RowItem>
                <SearchSelectButton
                  checkedList={typesFilter}
                  title={i18n._(t`Types`)}
                  options={[
                    { id: GOAL_TYPES.BUSINESS.key, label: GOAL_TYPES.BUSINESS.title(i18n) },
                    { id: GOAL_TYPES.PERSONAL.key, label: GOAL_TYPES.PERSONAL.title(i18n) },
                  ]}
                  handleChange={setTypesFilter}
                />
              </RowItem>
            )}
          </Filters>
          <GoalTabs currentTab={currentTab} handleChangeTab={setCurrentTab} />
          <Section>
            <GoalItems
              onGoalClick={setGoalId}
              items={goals}
              loading={$loading.value}
              refreshGoals={resetPagination}
              placeholderSubTitle={i18n._(t`No results based on your search criteria`)}
            />
          </Section>

          <StyledPaginationBar
            pagination={pagination}
            changePagination={changePagination}
            count={totalGoals}
            noBorder
            showCount
          />
        </>
      )}
    </Modal>
  );
};

export { GoalsOverview };
