import { t } from '@lingui/macro';

import { ROLES } from '~/constants';

export default {
  REVIEWS: {
    key: 'reviews',
    title: (i18n) => i18n._(t`Filter by review creator`),
    data: {
      CREATED_BY_ADMIN: {
        key: 'reviews-created-by-admin',
        name: 'Created by admin',
      },
      CREATED_BY_COACHES: {
        key: 'reviews-created-by-coaches',
        name: 'Created by coaches',
      },
      CREATED_BY_USER: {
        key: 'reviews-created-by-users',
        name: 'Created by users',
      },
      CREATED_BY_ME_AS_COACH: {
        key: 'reviews-created-by-me-as-coach',
        name: 'Created by me as coach',
        roles: [ROLES.COACH],
      },
    },
  },
  GOALS: {
    key: 'goals',
    title: (i18n) => i18n._(t`Filter by type`),
    data: {
      BUSINESS: {
        key: 'goals-business',
        name: 'Business goals',
      },
      PERSONAL: {
        key: 'goals-personal',
        name: 'Personal goals',
      },
    },
  },
  GOALS_CREATED_BY: {
    key: 'goals-created-by',
    title: (i18n) => i18n._(t`FILTER BY GOAL CREATOR`),
    data: {
      USER: {
        key: 'user',
        name: 'User',
      },
      COACH: {
        key: 'coach',
        name: 'Coach',
      },
      ADMIN: {
        key: 'admin',
        name: 'Admin',
      },
    },
  },
  CONVERSATIONS_FOR: {
    key: 'conversations-for',
    title: (i18n) => i18n._(t`CONVERSATIONS FOR`),
    data: {},
  },
  MICRO_LEARNINGS_FOR: {
    key: 'micro-learnings-for',
    title: (i18n) => i18n._(t`MICRO LEARNINGS FOR`),
    data: {},
  },
  ACTIVITIES: {
    key: 'activities',
    title: (i18n) => i18n._(t`Filter by type`),
    data: {
      VIDEO: {
        key: 'video',
        name: 'Video',
      },
      BOOK: {
        key: 'book',
        name: 'Book',
      },
      ARTICLE: {
        key: 'article',
        name: 'Article',
      },
      COURSE: {
        key: 'course',
        name: 'Course',
      },
      OTHER: {
        key: 'other',
        name: 'Other',
      },
    },
  },
  ACTIVITIES_COMPANY: {
    key: 'activities-company',
    title: (i18n) => i18n._(t`Filter by company`),
    data: {},
  },
  ACTIVITIES_SOURCE: {
    key: 'activities-source',
    title: (i18n) => i18n._(t`Filter by source`),
    data: {},
  },
  DATE_FROM: {
    key: 'date-from',
    data: {},
  },
  DATE_TO: {
    key: 'date-to',
    data: {},
  },
  ADMIN_ACTIVITY_LIBRARY_CATEGORY: {
    key: 'admin-activity-library-category',
    title: (i18n) => i18n._(t`Filter by category`),
    data: {},
  },
  COMPANY_CONNECTION: {
    key: 'company-connection',
    title: (i18n) => i18n._(t`Filter by company`),
    data: {},
  },
  MEMBERS_TEAM: {
    key: 'members-team',
    title: (i18n) => i18n._(t`Filter by team`),
    data: {},
  },
  MEMBERS_RIGHTS: {
    key: 'members-rights',
    title: (i18n) => i18n._(t`Rights`),
    data: {
      USERS: {
        id: ROLES.USER,
        label: (i18n) => i18n._(t`User`),
      },
      COACHES: {
        id: ROLES.COACH,
        label: (i18n) => i18n._(t`Coach`),
      },
      ADMINS: {
        id: ROLES.ADMIN,
        label: (i18n) => i18n._(t`Admin`),
      },
    },
  },
  PROFILE_ACTIVATION: {
    key: 'profile-activation',
    title: (i18n) => i18n._(t`Profile activation`),
    data: {
      YES: {
        id: 'yes',
        label: (i18n) => i18n._(t`Yes`),
      },
      NO: {
        id: 'no',
        label: (i18n) => i18n._(t`No`),
      },
    },
  },
};
