import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';
import ProgressBarWide from '~/components/ProgressBarWide';

import { GOAL_STATUSES } from '~/constants';
import { COLOR_PALETTE, COLORS } from '~/styles';

const Row = styled.div`
  margin: 16px -24px 0;
  padding: 0 24px 20px;
  border-bottom: 1px solid ${COLORS.BORDER_SOFT};
`;

const ProgressRow = styled.div`
  display: flex;
  align-items: center;
`;

const ProgressStatus = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  margin-left: 12px;
  color: ${(props) => props.color};
  white-space: nowrap;
`;

const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const StartLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const CurrentLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin-left: 8px;
`;

const UpdateWrapper = styled.div`
  margin-left: 8px;
`;

const CalculationExplanation = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  margin-left: 16px;
  color: ${COLOR_PALETTE.GRAY_MIDDLE};
`;

const UpdateProgressButton = styled(Button)`
  height: 32px;
  padding: 0 8px;
`;

const GoalLabel = styled(CurrentLabel)``;

const Progress = ({
  progress,
  progressBar,
  status,
  min,
  max,
  symbol,
  disabled,
  onUpdate,
  isHideUpdateProgress,
  isAvgType,
  isDoneType,
  isChildProgress,
}) => {
  const { i18n } = useLingui();

  const goalStyle = Object.values(GOAL_STATUSES).find((s) => s.key === status);

  return (
    <Row>
      <ProgressRow>
        <ProgressBarWide
          value={progressBar}
          isActive
          rounded
          height="12px"
          backgroundColor="#e4eefd"
        />
        <ProgressStatus color={goalStyle?.color}>{goalStyle?.title(i18n)}</ProgressStatus>
      </ProgressRow>
      <DetailsRow>
        {!isDoneType && (
          <>
            {!isAvgType && <StartLabel>{i18n._(t`Start: ${min}${symbol}`)}</StartLabel>}
            <CurrentLabel>{i18n._(t`Current: ${progress}${symbol}`)}</CurrentLabel>
            {!isAvgType && <GoalLabel>{i18n._(t`Goal: ${max}${symbol}`)}</GoalLabel>}
            {isAvgType && (
              <CalculationExplanation>
                {isChildProgress
                  ? i18n._(t`Average of the activities/key results and the child goals.`)
                  : i18n._(t`Average of the activities/key results.`)}
              </CalculationExplanation>
            )}
          </>
        )}

        <UpdateWrapper>
          {!isHideUpdateProgress && (
            <UpdateProgressButton
              label={i18n._(t`Update progress`)}
              type={Button.types.primaryBorder}
              onClick={onUpdate}
              disabled={disabled}
            />
          )}
        </UpdateWrapper>
      </DetailsRow>
    </Row>
  );
};

Progress.proptypes = {
  progress: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  symbol: PropTypes.string,
  onUpdate: PropTypes.func,
  disabled: PropTypes.bool,
  isHideUpdateProgress: PropTypes.bool,
};

export default React.memo(Progress);
