import React from 'react';

import { StyledDropdown, CompanyOptionLabel } from './CompanyDropdown.design';
import { CompanySelect } from './components/CompanySelect';

import defaultCompanyAvatar from '~/assets/learned-logo/learned-iconic.png';

import type { ICompany } from '@learned/types';

interface ICompanyDropdownProps {
  companies: ICompany[];
  onChange: (company?: ICompany) => void;
  onSearchChange?: (search: string) => void;
  selectedCompany?: ICompany;
  isSearchable?: boolean;
  isShowUploadLogoButton?: boolean;
  onUploadChange?: (e: any) => void;
  isClickable?: boolean;
}

function CompanyOption({
  item,
  isSelected,
  handleNewItemSelected,
}: {
  item: ICompany;
  isSelected: boolean;
  handleNewItemSelected: (item: ICompany) => void;
}) {
  return (
    <CompanyOptionLabel $selected={isSelected} onClick={() => handleNewItemSelected(item)}>
      <img src={item?.logoUrl || defaultCompanyAvatar} alt="company logo" />
      <span>{item.name}</span>
    </CompanyOptionLabel>
  );
}

function CompanyDropdown({
  companies,
  selectedCompany,
  onChange,
  onSearchChange,
  isSearchable = true,
  isShowUploadLogoButton = false,
  onUploadChange,
  isClickable = true,
}: ICompanyDropdownProps) {
  function CompanySelectComponent({
    onClick,
    selectedItem,
    clicked,
  }: {
    clicked: boolean;
    active: boolean;
    onClick: () => void;
    selectedItem?: ICompany;
  }) {
    return (
      <CompanySelect
        onClick={onClick}
        clicked={clicked}
        selectedItem={selectedItem}
        isShowUploadLogoButton={isShowUploadLogoButton}
        onUploadChange={onUploadChange}
        isClickable={isClickable}
      />
    );
  }

  return (
    <StyledDropdown
      onChange={onChange}
      items={companies}
      isSingleSelect
      isSearchable={isSearchable}
      onSearchChange={onSearchChange}
      CustomOptionComponent={CompanyOption}
      CustomDropdownComponent={CompanySelectComponent}
      stringifyItem={(company) => company.name}
      selectedItem={selectedCompany}
    />
  );
}

export { CompanyDropdown };
export type { ICompanyDropdownProps };
