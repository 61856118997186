import { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, SKILL_TEMPLATE_SORT_OPTIONS } from '@learned/constants';
import flatten from 'lodash/flatten';

import { listSkillTemplatesSuperAdmin } from '~/services/skillTemplates';

import type { IJobTemplate, ISkillTemplate } from '@learned/types';

const useSkillTemplates = ({ jobTemplate }: { jobTemplate?: IJobTemplate }) => {
  const [skillTemplates, setSkillTemplates] = useState<ISkillTemplate[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (jobTemplate) {
        const skillTemplateIds = flatten(
          jobTemplate?.skills?.map(({ skillTemplate }) => skillTemplate),
        );

        const result: { data: { skillTemplates: ISkillTemplate[] } } =
          await listSkillTemplatesSuperAdmin(
            {
              skillTemplateIds,
            },
            {
              skip: 0,
              limit: skillTemplateIds.length,
              sortBy: SKILL_TEMPLATE_SORT_OPTIONS.NAME_A_Z,
            },
          );

        setSkillTemplates(result.data[API_RETURN_FIELDS.SKILL_TEMPLATES]);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(jobTemplate?.skills || [])]);

  return { skillTemplates };
};

export { useSkillTemplates };
