import React, { FunctionComponent } from 'react';

import { ACTIVITY_STATUSES } from '@learned/constants';
import { IUserActivity } from '@learned/types';
import styled, { css } from 'styled-components';

import CheckIcon from '~/components/Icons/Check';

import { COLOR_SET, COLORS, COLOR_PALETTE } from '~/styles';

const Circle = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: inherit;
  z-index: 20;
`;

const Dot = styled.span`
  width: 6px;
  height: 6px;
  background-color: ${COLOR_PALETTE.BLUE_DARK};
  border-radius: 100px;
`;

const Minus = styled.span`
  width: 6px;
  height: 1px;
  background-color: ${COLOR_PALETTE.WHITE};
`;

const Line = styled.div<{
  top?: string;
  bottom?: string;
  color?: string;
}>`
  position: absolute;
  width: 1px;
  background-color: ${({ color }) => color || COLOR_PALETTE.DARK_GRAY};
  left: 13px;
  top: ${({ top }) => top || 'unset'};
  bottom: ${({ bottom }) => bottom || 'unset'};
  box-sizing: border-box;
`;

const Name = styled.div<{
  $isCompletedActivity: boolean;
}>`
  padding-left: 12px;
  width: 208px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: -0.16px;
  ${(props) =>
    props.$isCompletedActivity &&
    css`
      color: ${COLOR_PALETTE.DARK_GRAY};
    `}
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const NameRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;

  span {
    margin-top: 5px;
  }
`;

const Progress = styled.div<{
  $isProblemActivity: boolean;
}>`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.13px;
  ${(props) => props.$isProblemActivity && 'color: #ff5252;'}
`;

const Row = styled.div<{
  $isReadOnly: boolean;
  $isActive: boolean;
  status: ACTIVITY_STATUSES;
}>`
  position: relative;
  cursor: ${(props) => !props.$isReadOnly && 'pointer'};
  width: 291px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${COLOR_SET.MIDDLE_GRAY_BLUE};
  box-sizing: border-box;
  padding: 11px 7px;

  margin: 4px 0;
  border-radius: 6px;

  background-color: ${COLORS.BG_PAGE};
  &:hover {
    background-color: #f5f7fd;
  }

  & .circle {
    border: 1px solid ${COLOR_PALETTE.DARK_GRAY};
    min-width: 12px;
    height: 12px;
  }

  ${({ status }) =>
    status &&
    status === ACTIVITY_STATUSES.PROBLEM &&
    css`
      color: ${COLORS.ACCENT_ERROR};
      & .circle {
        min-width: 12px;
        height: 12px;
        background-color: ${COLORS.ACCENT_ERROR};
        border: 1px solid ${COLORS.ACCENT_ERROR};
        color: ${COLORS.WHITE};
      }
    `}

  ${({ status, $isActive }) =>
    status &&
    $isActive &&
    ![ACTIVITY_STATUSES.COMPLETED, ACTIVITY_STATUSES.PROBLEM].includes(status) &&
    css`
      background-color: ${COLORS.WHITE};
      & .circle {
        background-color: inherit;
        border: 1px solid ${COLORS.ACTIVITY_GREY};
        min-width: 12px;
        height: 12px;
      }
    `}


    ${({ status }) =>
    status &&
    status === ACTIVITY_STATUSES.COMPLETED &&
    css`
      & .circle {
        min-width: 12px;
        height: 12px;
        background-color: ${COLOR_PALETTE.BLUE_DARK};
        border: 1px solid ${COLOR_PALETTE.BLUE_DARK};
      }
    `}

 ${(props) =>
    props.$isActive &&
    css`
      box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
      background-color: ${COLORS.WHITE};
    `};
`;

const Wrapper = styled.div`
  position: relative;
  margin: 0 13px 0 7px;
  min-height: 58px;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  :hover {
    .activity-row {
      background-color: #f5f7fd;
    }
  }
`;

interface IActivityRow {
  isReadOnly: boolean;
  onActivityClick: () => void;
  activity: IUserActivity;
  key: string;
  isActive: boolean;
  isObligated: boolean;
  topLineColor?: string;
  bottomLineColor?: string;
}

const ActivityRow: FunctionComponent<IActivityRow> = ({
  activity,
  isReadOnly,
  onActivityClick,
  isActive,
  topLineColor,
  bottomLineColor,
  isObligated,
}) => {
  const isCompletedActivity = activity?.status === ACTIVITY_STATUSES.COMPLETED;
  const isProblemActivity = activity?.status === ACTIVITY_STATUSES.PROBLEM;
  const isOnTrackActivity = [
    ACTIVITY_STATUSES.ON_TRACK,
    ACTIVITY_STATUSES.TODO,
    ACTIVITY_STATUSES.IN_PROGRESS,
  ].includes(activity?.status);

  return (
    <Wrapper>
      <Row
        $isReadOnly={isReadOnly}
        onClick={isReadOnly ? undefined : () => onActivityClick()}
        $isActive={isActive}
        className="activity-row"
        status={activity?.status}
      >
        {topLineColor && <Line color={topLineColor} top="-11px" bottom="50%" />}
        {bottomLineColor && <Line color={bottomLineColor} top="50%" bottom="0" />}
        <Circle className="circle">
          {isCompletedActivity && <CheckIcon fill={COLOR_PALETTE.WHITE} size={10} />}
          {isOnTrackActivity && isActive && <Dot />}
          {isProblemActivity && <Minus />}
        </Circle>
        <NameRow>
          <Name title={activity.name} $isCompletedActivity={isCompletedActivity}>
            {activity.name}
          </Name>
          {isObligated && <span>*</span>}
        </NameRow>
        {activity?.progress > 0 && (
          <Progress $isProblemActivity={isProblemActivity}>{activity.progress}%</Progress>
        )}
      </Row>
    </Wrapper>
  );
};

export default ActivityRow;
