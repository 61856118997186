import React, { Fragment, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { HeaderTabs } from '~/components/HeaderTabs';
import OverviewHeading from '~/components/OverviewHeading';
import BaseLayout from '~/layouts/BaseLayout';

import { AllLearningsTab } from './tabs/AllLearningsTab';
import { PersonalLearningsTab } from './tabs/PersonalLearningsTab';

import { COLORS } from '~/styles';

import type { I18n } from '@lingui/core';

const HeaderWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  width: fit-content;
  border-radius: 6px 6px 0 0;
`;

export const TABS_ENUM = {
  PERSONAL: 'personal',
  ALL: 'all',
};

const OnboardAndLearn = () => {
  const { i18n } = useLingui();
  const [currentTab, setCurrentTab] = useState(
    // @ts-ignore
    TABS_ENUM[window.location.hash.substring(1).toUpperCase()] || TABS_ENUM.PERSONAL,
  );

  const TABS = [
    {
      label: (i18n: I18n) => i18n._(t`Your plan`),
      key: TABS_ENUM.PERSONAL,
    },
    {
      label: (i18n: I18n) => i18n._(t`All`),
      key: TABS_ENUM.ALL,
    },
  ];

  const handleChangeTab = (key: string) => {
    window.location.hash = key;
    setCurrentTab(key);
  };

  return (
    <Fragment>
      {/* @ts-ignore */}
      <OverviewHeading title={i18n._(t`Onboard & Learn`)} smallWidth />
      {/* @ts-ignore */}
      <BaseLayout smallWidth>
        <HeaderWrapper>
          <HeaderTabs tabs={TABS} selectedTab={currentTab} handleChangeTab={handleChangeTab} />
        </HeaderWrapper>
        {currentTab === TABS_ENUM.PERSONAL && <PersonalLearningsTab />}
        {currentTab === TABS_ENUM.ALL && <AllLearningsTab />}
      </BaseLayout>
    </Fragment>
  );
};

export { OnboardAndLearn };
