import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const TrashIcon = ({ size, ...props }) => (
  <InlineSvg height={size} width={size} {...props} childrenFirst viewBox="0 0 14 14">
    <g fill="none" fillRule="evenodd">
      <path d="M.125.125h13.75v13.75H.125z" />
      <path
        stroke="currentColor"
        strokeWidth=".868"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.726 3.156H2.273M5.734 5.711v3.437M8.312 5.711v3.437M10.867 3.133v8.164a.43.43 0 0 1-.43.43H3.564a.43.43 0 0 1-.43-.43V3.133M9.148 3.133v-.86a.86.86 0 0 0-.859-.859H5.711a.86.86 0 0 0-.86.86v.859"
      />
    </g>
  </InlineSvg>
);

TrashIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

TrashIcon.defaultProps = {
  size: 14,
  className: '',
};

export default TrashIcon;
