import iconFactory from './iconFactory';

const d = 'M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z';

/**
 * @deprecated since version 3.0 {@see Icon}
 * still in use, no replacement yet
 */
const CheckIcon = iconFactory('CheckIcon', d);

CheckIcon.defaultProps.viewBox = '0 0 24 24';

export default CheckIcon;
