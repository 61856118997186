import React, { Children } from 'react';

import styled from 'styled-components';

import BoxWithBorder from '~/components/UI/BoxWithBorder';

const Box = styled(BoxWithBorder)`
  margin-bottom: 32px;
`;

const BoxHeader = styled.h4`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #3b3b3b;
  margin: 0;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BoxContent = styled.div`
  padding: ${(props) => (props.$noPadding ? '0' : '8px')};
  display: flex;
  border-radius: 6px;
  border: ${(props) => !props.$noBackGround && 'solid 1px #f9fafc'};
  background-color: ${(props) => !props.$noBackGround && '#f6f8fc'};
  margin: 8px 16px;
`;

const BoxContentHeader = styled(BoxContent)`
  background-color: inherit;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
`;

const BoxFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 72px;
  padding: 0 40px;
  border-top: solid 1px #c7cfe0;
`;

const HeaderText = styled.div`
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
`;

const SubTitle = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  margin-top: 15px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
`;

const SettingBlock = ({
  title,
  subTitle,
  style,
  children,
  actions,
  noPadding,
  noBackGround,
  buttonBlock,
  contentHeader,
  headerText,
  styleChild,
}) => (
  <Box style={style}>
    {(title || subTitle || headerText) && (
      <BoxHeader>
        <div>
          {title && <Title>{title}</Title>}
          {subTitle && <SubTitle>{subTitle}</SubTitle>}
          {headerText && <HeaderText>{headerText}</HeaderText>}
        </div>
        {buttonBlock}
      </BoxHeader>
    )}
    {contentHeader && <BoxContentHeader>{contentHeader}</BoxContentHeader>}
    {Children.map(children, (child) => (
      <BoxContent $noPadding={noPadding} $noBackGround={noBackGround} style={styleChild}>
        {child}
      </BoxContent>
    ))}
    {actions && <BoxFooter>{actions}</BoxFooter>}
  </Box>
);

export default SettingBlock;
