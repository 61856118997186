import React, { useEffect, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { orderBy } from 'lodash';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import size from 'lodash/size';
import styled, { css } from 'styled-components';

import { AutocompleteFilterGoalCycles } from '~/components/AutocompleteFilters';
import CheckBox from '~/components/CheckBox';
import SearchSelectButton from '~/components/SearchSelectButton';
import { FieldGroup, SearchField } from '~/components/Text';

import { SORT_BY } from '~/constants';
import { usePagination } from '~/hooks/usePagination';
import { COLORS, COLOR_PALETTE, COLOR_SET } from '~/styles';

import PaginationBar from '../../../PaginationBar';

import type { IGoal } from '@learned/types';

interface ISelectListProps {
  items: IGoal[];
  selectedItems: IGoal['id'][];
  isMultiCheck?: boolean;
  isSearchable?: boolean;
  onChange: (items: any[]) => void;
  className?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: solid 1px #f3f7fd;
  overflow-x: visible;

  ${FieldGroup} {
    margin: 12px 24px;
  }
`;

const FilterGroups = styled(FieldGroup)`
  display: flex;
  gap: 12px;
  & .search {
    height: 32px;
  }
`;

const FilterGoalCycles = styled(AutocompleteFilterGoalCycles)`
  height: 32px;
  width: 140px;
`;

const SortBySelect = styled(SearchSelectButton)`
  width: 160px;
  z-index: 100;
`;

const EmptyText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: ${COLOR_SET.BLACK};
  height: 60px;
  width: 100%;
`;

const Row = styled.div<{ isSelected?: boolean }>`
  box-sizing: border-box;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: ${COLOR_SET.BLACK};
  display: flex;
  justify-content: flex-start;
  margin: 0;

  &:hover {
    background-color: ${COLORS.BG_PAGE};
    cursor: pointer;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${COLORS.BG_PAGE};
    `}

  & .name {
    margin-left: 16px;
  }
`;

const Header = styled.div<{ isSelected?: boolean }>`
  box-sizing: border-box;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  margin: 0;
  color: ${({ isSelected }) => (isSelected ? COLORS.TEXT_WHITE : COLOR_PALETTE.DARK_GRAY)};
  background-color: ${({ isSelected }) => (isSelected ? 'var(--company-color)' : COLORS.BG_PAGE)};

  & .name {
    margin-left: 16px;
  }
`;

const SelectList = ({
  isMultiCheck = false,
  isSearchable = false,
  items,
  selectedItems,
  onChange,
  className,
}: ISelectListProps) => {
  const { i18n } = useLingui();
  const { pagination, changePagination } = usePagination(10);
  const [search, setSearch] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);
  const [paginatedItems, setPaginatedItems] = useState(filteredItems);
  const [selectedGoalCycles, setSelectedGoalCycles] = useState<IGoal[]>([]);
  const [sortedBy, setSortedBy] = useState<string[]>([]);

  // Sort by
  useEffect(() => {
    switch (sortedBy?.[0]) {
      case SORT_BY.LAST_UPDATED.id:
        setFilteredItems(orderBy(filteredItems, (item) => item.meta.lastModifiedDate, ['desc']));
        break;
      case SORT_BY.A_Z.id:
        setFilteredItems(orderBy(filteredItems, (item) => item.name));
        break;
      case SORT_BY.Z_A.id:
        setFilteredItems(orderBy(filteredItems, (item) => item.name, ['desc']));
        break;
      case SORT_BY.CREATED_NEW_OLD.id:
        setFilteredItems(orderBy(filteredItems, (item) => item.meta.createdDate, ['desc']));
        break;
      case SORT_BY.CREATED_OLD_NEW.id:
        setFilteredItems(orderBy(filteredItems, (item) => item.meta.createdDate));
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, sortedBy]);

  useEffect(() => {
    setPaginatedItems(filteredItems.slice(pagination.skip, pagination.index * pagination.limit));
  }, [filteredItems, pagination]);

  const filterGoalCycle = (item: IGoal) => {
    const selectedGoalCyclesIds = selectedGoalCycles.map((item) => item.id);

    if (!isEmpty(selectedGoalCyclesIds)) {
      return filter(item.goalCycles, (i: IGoal) => selectedGoalCyclesIds.includes(i.id)).length > 0;
    }

    return item;
  };

  const filterSearch = (item: IGoal) => {
    if (search) {
      return (item.name || '').toLowerCase().includes(search.toLowerCase());
    }

    return item;
  };

  useEffect(() => {
    setFilteredItems(items.filter(filterSearch).filter(filterGoalCycle));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify({ selectedGoalCycles, search, items })]);

  // @ts-ignore
  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  const onSortByChange = (value: string[]) => {
    setSortedBy(value);
  };

  // @ts-ignore
  const checkItem = (item) => {
    if (isMultiCheck) {
      if (selectedItems?.indexOf(item.id) !== -1) {
        onChange(selectedItems?.filter((s) => s !== item.id));
      } else {
        onChange(selectedItems?.concat([item.id]));
      }
    } else {
      if (selectedItems?.indexOf(item.id) !== -1) {
        onChange([]);
      } else {
        onChange([item.id]);
      }
    }
  };

  const checkAllItems = () => {
    if (selectedItems.length === items.length) {
      onChange([]);
    } else {
      onChange(items.map((item) => item.id));
    }
  };

  const isAllItemsSelected = selectedItems.length === items.length;

  return (
    <Container className={className}>
      {isSearchable && (
        <FilterGroups>
          {/* @ts-ignore */}
          <SearchField
            data-mqdid="select-goal_input"
            value={search}
            onChange={onSearch}
            placeholder={i18n._(t`Search`)}
            className="search"
          />
          <FilterGoalCycles
            // @ts-ignore
            checkedList={selectedGoalCycles}
            // @ts-ignore
            onChange={(goals) => setSelectedGoalCycles(goals)}
          />
          <SortBySelect
            title={i18n._(t`Sort by`)}
            checkedList={sortedBy}
            handleChange={onSortByChange}
            options={Object.values(SORT_BY).map((item) => ({ ...item, label: item.label(i18n) }))}
            minWidth="80px"
            isRadio
            noHeader
          />
        </FilterGroups>
      )}
      {isEmpty(paginatedItems) ? (
        <EmptyText>
          <span>
            <Trans>Sorry! Nothing available.</Trans>
          </span>
        </EmptyText>
      ) : (
        <div>
          <Header onClick={checkAllItems} isSelected={!isEmpty(selectedItems)}>
            {/* @ts-ignore */}
            <CheckBox
              checked={isAllItemsSelected}
              size={20}
              defaultColor={!isEmpty(selectedItems) && COLORS.TEXT_WHITE}
            />
            <span className="name">
              {isEmpty(selectedItems)
                ? i18n._(t`GOALS`)
                : i18n._(t`${size(selectedItems)} SELECTED`)}
            </span>
          </Header>
          {map(paginatedItems, (item) => {
            const isSelected = selectedItems?.indexOf(item.id) !== -1;

            return (
              <Row onClick={() => checkItem(item)} key={item.id} isSelected={isSelected}>
                {/* @ts-ignore */}
                <CheckBox checked={isSelected} size={20} />
                <span className="name">{item.name}</span>
              </Row>
            );
          })}
        </div>
      )}
      <PaginationBar
        pagination={pagination}
        changePagination={changePagination}
        count={filteredItems.length}
        showCount
        noBorder
        noShadow
      />
    </Container>
  );
};

export { SelectList };
