import React, { PureComponent } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import Tippy from '@tippyjs/react';
import isEmpty from 'lodash/isEmpty';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';
import IconButton from '~/components/IconButton';
import DeleteIcon from '~/components/Icons/Delete';
import EditIcon from '~/components/Icons/Edit';
import PlusIcon from '~/components/Icons/Plus';
import Placeholder from '~/components/Placeholder';
import Radio from '~/components/Radio';
import Search from '~/components/Search';

import { COLOR_PALETTE, COLORS } from '~/styles';

const AddButton = styled.div`
  height: 44px;
  width: 24px;
  margin: 0px;
  padding: 0px;
`;

const AddNewRow = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
`;

const DropDownContainer = styled.div`
  width: ${(props) => props.$width || '238px'};
  border-radius: 6px;
  border: solid 1px ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  background-color: ${COLOR_PALETTE.WHITE};
  outline: none;
`;

const DropDownBox = styled.div`
  padding: 10px 12px;
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  display: ${(props) => (props.$isHideSearch ? 'none' : 'block')};
`;

const IconsWrapp = styled.div`
  display: flex;
  align-items: center;
`;

const OptionList = styled.div`
  min-width: 100%;
  ${(props) =>
    props.$isScroll && {
      maxHeight: '200px',
      overflowY: 'auto',
    }};
`;

const OptionListItem = styled.div`
  height: 48px;
  box-sizing: border-box;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: ${COLORS.BG_PAGE};
  }
`;

const OptionListItemLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #3b3b3b;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 9px;
  cursor: pointer;
`;

const Header = styled.div`
  padding: 4px 8px;
  height: 32px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  color: #70747f;
  text-transform: uppercase;
`;

const tippyOpts = {
  modifiers: [
    {
      name: 'computeStyles',
      options: {
        gpuAcceleration: false,
      },
    },
  ],
};

function getId(option) {
  return option.id || option.key;
}

function getOptionLabel(option, i18n) {
  let label;
  if (typeof option.label === 'function') {
    label = option.label(i18n);
  } else if (typeof option.name === 'function') {
    label = option.name(i18n);
  } else if (option.label) {
    label = option.label;
  } else if (option.name) {
    label = option.name;
  } else {
    label = option.key;
  }
  return label;
}

const ButtonStyles = { marginLeft: '6px' };
const searchStyles = {
  padding: '0 8px',
  minHeight: '20px',
  height: '20px',
  boxSizing: 'border-box',
  width: '100%',
  fontSize: '12px',
  border: 'none',
  fontFamily: 'Open Sans, sans-serif',
  color: COLOR_PALETTE.DARK_GRAY,
};

const OptionItem = React.memo(
  ({ isActive, option, updateItem, removeItem, i18n, isRadio, onChange }) => {
    const label = getOptionLabel(option, i18n);
    return (
      <OptionListItem>
        <Column onClick={(e) => onChange(e, getId(option))}>
          {isRadio ? <Radio checked={isActive} /> : <CheckBox checked={isActive} size={'24px'} />}
          {!isEmpty(label) && label.length < 18 ? (
            <OptionListItemLabel>{getOptionLabel(option, i18n)}</OptionListItemLabel>
          ) : (
            <Tippy trigger="mouseenter" theme="light" popperOptions={tippyOpts} content={label}>
              <OptionListItemLabel>{getOptionLabel(option, i18n)}</OptionListItemLabel>
            </Tippy>
          )}
        </Column>
        <IconsWrapp>
          {removeItem && (
            <IconButton size={24} color={COLORS.SUBTEXT} onClick={() => removeItem(option)}>
              <DeleteIcon size={16} />
            </IconButton>
          )}
          {updateItem && (
            <IconButton
              style={ButtonStyles}
              size={24}
              color={COLORS.SUBTEXT}
              onClick={() => updateItem(option)}
            >
              <EditIcon size={16} />
            </IconButton>
          )}
        </IconsWrapp>
      </OptionListItem>
    );
  },
);

OptionItem.displayName = 'OptionItem';

class SearchSelect extends PureComponent {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.node,
        payload: PropTypes.any,
      }),
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    checkedList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    placeholder: PropTypes.string,
    isMenuAlignRight: PropTypes.bool,
    disabled: PropTypes.bool,
    isRadio: PropTypes.bool,
    isScroll: PropTypes.bool,
  };

  static defaultProps = {
    checkedList: [],
    placeholder: '',
    isRadio: false,
  };

  state = {
    search: '',
  };

  input = React.createRef();

  handleSearchChange = (e) => {
    this.setState({ search: e.target.value });
  };

  handleChange = (e, id) => {
    const { checkedList, onChange, isRadio } = this.props;
    e.stopPropagation();

    const active = checkedList.indexOf(id) !== -1;

    if (isRadio) {
      onChange(active ? [] : [id]);
    } else {
      onChange(active ? checkedList.filter((i) => i !== id) : [...checkedList, id]);
    }

    if (this.timount) {
      clearTimeout(this.timount);
    }
    if (this.input.current) {
      this.input.current.focus();
    }
  };

  componentDidMount() {
    this.input.current.focus();
  }

  render() {
    const {
      options,
      placeholder,
      checkedList,
      addNewItem,
      i18n,
      updateItem,
      removeItem,
      isRadio,
      width,
      title,
      noHeader,
      isScroll,
      isSearchAll,
    } = this.props;
    const { search } = this.state;

    const list =
      search === ''
        ? options.filter((o) => o)
        : options.filter((o) => {
            return (
              o &&
              (isSearchAll
                ? getOptionLabel(o, i18n).toLowerCase().indexOf(search.toLowerCase()) !== -1
                : checkedList.indexOf(getId(o)) !== -1 ||
                  getOptionLabel(o, i18n).toLowerCase().indexOf(search.toLowerCase()) !== -1)
            );
          });

    return (
      <DropDownContainer onMouseDown={(e) => e.preventDefault()} $width={width}>
        {!noHeader ? <Header>{title}</Header> : null}
        <DropDownBox $isHideSearch={list.length < 4 && search === ''}>
          <Search
            name="searchValue"
            value={search}
            onChange={this.handleSearchChange}
            style={searchStyles}
            placeholder={placeholder}
            defaultColor={COLOR_PALETTE.GRAY_SEMI_SOFT}
            ref={this.input}
            iconPrepend
          />
        </DropDownBox>
        {!isEmpty(list) ? (
          <OptionList $isScroll={isScroll}>
            {list.map((option) => {
              const id = getId(option);
              return (
                <OptionItem
                  key={id}
                  option={option}
                  checkedList={checkedList}
                  updateItem={updateItem}
                  removeItem={removeItem}
                  isRadio={isRadio}
                  isActive={checkedList.indexOf(id) !== -1}
                  onChange={this.handleChange}
                  i18n={i18n}
                />
              );
            })}
          </OptionList>
        ) : (
          <Placeholder
            title={i18n._(t`No options available`)}
            titleStyles={{
              color: COLOR_PALETTE.GRAY_MIDDLE,
              fontSize: '14px',
              fontWeight: 'normal',
            }}
          />
        )}
        {addNewItem && (
          <AddNewRow>
            <AddButton onMouseUp={addNewItem}>
              <IconButton size={24}>
                <PlusIcon size={16} />
              </IconButton>
            </AddButton>
          </AddNewRow>
        )}
      </DropDownContainer>
    );
  }
}

export default withI18n()(SearchSelect);
