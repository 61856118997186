import styled from 'styled-components';

import { COLORS } from '~/styles';

const ContentWrap = styled.div``;

const Description = styled.div`
  max-width: 389px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.SUBTEXT};
  margin-top: 7px;
`;

const LangRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  align-items: center;
`;

const TextCol = styled.div``;

const SelectedLang = styled.div`
  display: flex;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.TEXT_MAIN};
`;

export { ContentWrap, SelectedLang, Title, Description, TextCol, LangRow };
