const OPTIONS = {
  ABOUT_SECTION: 'aboutSection',
  MAININFO: 'mainInfo',
  ABOUT: 'about',
  EMAIL: 'email',
  PHONE: 'phone',
  SKILLS_SECTION: 'skillsSection',
  SKILLS: 'skills',
  WORK: 'work',
  EDUCATION: 'education',
  CERTIFICATES: 'certificates',
};

const DEFAULT_SECTIONS = {
  [OPTIONS.ABOUT_SECTION]: {
    key: OPTIONS.ABOUT_SECTION,
    title: 'Employee data',
    options: {
      [OPTIONS.MAININFO]: {
        key: OPTIONS.MAININFO,
        title: 'Avatar, name and job title',
        isAvailable: true,
        isVisible: true,
      },
      [OPTIONS.ABOUT]: {
        key: OPTIONS.ABOUT,
        title: 'About',
        isAvailable: true,
        isVisible: true,
      },
      [OPTIONS.EMAIL]: {
        key: OPTIONS.EMAIL,
        title: 'Email',
        isAvailable: true,
        isVisible: true,
      },
      [OPTIONS.PHONE]: {
        key: OPTIONS.PHONE,
        title: 'Phone',
        isAvailable: true,
        isVisible: true,
      },
    },
  },
  [OPTIONS.SKILLS_SECTION]: {
    key: OPTIONS.SKILLS_SECTION,
    title: 'Skills',
    options: {
      [OPTIONS.SKILLS]: {
        key: OPTIONS.SKILLS,
        title: 'Skills',
        isAvailable: true,
        isVisible: true,
      },
    },
  },
  [OPTIONS.WORK]: {
    key: OPTIONS.WORK,
    title: 'Work experience',
    options: [],
    visibleOptions: [],
  },
  [OPTIONS.EDUCATION]: {
    key: OPTIONS.EDUCATION,
    title: 'Educational',
    options: [],
    visibleOptions: [],
  },
  [OPTIONS.CERTIFICATES]: {
    key: OPTIONS.CERTIFICATES,
    title: 'Certificate',
    options: [],
    visibleOptions: [],
  },
};

export { OPTIONS, DEFAULT_SECTIONS };
