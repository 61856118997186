import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

import { Option } from '~/components/Dropdown/design';

import { SelectedLang } from './design';

import type { ILangItem } from './';

const LangOption = ({
  langToRender,
  isSelected,
  onClick,
}: {
  langToRender: ILangItem;
  isSelected: boolean;
  onClick: () => void;
}) => {
  const { i18n } = useLingui();
  return (
    // @ts-ignore
    <Option $selected={isSelected} onClick={onClick}>
      <SelectedLang>{`${
        langToRender?.locale
          ? getUnicodeFlagIcon(
              // @ts-ignore
              langToRender?.locale.substring(langToRender?.locale.indexOf('_') + 1),
            )
          : ''
      } ${i18n._(t`${langToRender?.language}`)}`}</SelectedLang>
    </Option>
  );
};

export { LangOption };
