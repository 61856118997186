import React from 'react';

import styled from 'styled-components';

import { COLOR_SET } from '~/styles';

interface IPercentageProps {
  value: number;
}

const Wrapper = styled.span<{ color?: string }>`
  color: ${({ color }) => color || 'unset'};
  font-size: 12px;
  font-weight: 600;
`;

const Percentage = ({ value }: IPercentageProps) => {
  return (
    <Wrapper color={value > 0 ? COLOR_SET.CYAN_GREEN : value === 0 ? '#dcdcdc' : '#ff5252'}>
      {value >= 0 ? '+' : '-'}
      {value}%
    </Wrapper>
  );
};

export { Percentage };
