import React, { ChangeEvent, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import formatISO from 'date-fns/formatISO';
import parseISO from 'date-fns/parseISO';
import moment from 'moment';

import { ButtonSize } from '~/components/Buttons';
import { StyledPrimaryButton, StyledSecondaryButton } from '~/components/Buttons/design';
import Calendar from '~/components/Calendar';
import { TimePicker } from '~/components/DateRangePicker/components';
import { getHours, getMinutes, stringifyTime } from '~/components/DateRangePicker/utils';
import { IconOld } from '~/components/IconOld';

import {
  Body,
  ButtonContainer,
  FromToContainer,
  Header,
  LeftBody,
  LeftHeader,
  ResetDates,
  RightBody,
  RightHeader,
  SelectedNumberOfDays,
  SelectToday,
  StyledModal,
  TimeContainer,
  TimePickerContainer,
} from './design';

import useBoolState from '~/hooks/useBoolState';
import { COLORS } from '~/styles';
import convertToTimeString from '~/utils/convertToTimeString';
import { regexDateValidator } from '~/utils/dates';

import { Input } from '../Input';

type IDateRangePickerProps = {
  onClose: (from?: string | null, to?: string | null) => void;
  // ISO formatted string (use 'date-fns/formatISO')
  initialFrom?: string;
  // ISO formatted string (use 'date-fns/formatISO')
  initialTo?: string;
  isSingleDate?: boolean;
  isTimeSelect?: boolean;
};

const DateRangePicker = ({
  onClose,
  initialFrom,
  initialTo,
  isSingleDate = false,
  isTimeSelect = false,
}: IDateRangePickerProps) => {
  const { i18n } = useLingui();

  const [rangeStart, setRangeStart] = useState(initialFrom ?? null);
  const [rangeEnd, setRangeEnd] = useState(initialTo ?? null);
  const [from, setFrom] = useState(initialFrom ? convertToTimeString(initialFrom) : '');
  const [to, setTo] = useState('');
  const [fromError, setFromError] = useState(false);
  const [toError, setToError] = useState(false);
  const [timeStart, setTimeStart] = useState<number | undefined>(9 * 60);
  const [timeEnd, setTimeEnd] = useState<number | undefined>(10 * 60);

  const $isChangingFromDate = useBoolState(true);
  const $isChangingToDate = useBoolState(false);
  const $externalReset = useBoolState(false);
  const $externalResetCalendar = useBoolState(false);
  const $isExternalToFromChanged = useBoolState(false);

  const onSetRange = (range1: string, range2: string) => {
    const rightOrder = !range2 || moment(range1).isBefore(moment(range2));
    const start = rightOrder ? range1 : range2;
    const end = rightOrder ? range2 : range1;
    setRangeStart(start);
    setRangeEnd(end);
    if (!$isExternalToFromChanged.value) {
      setFrom(convertToTimeString(start));
      setTo(convertToTimeString(end));
    }
    if ((!range1 || !range2 || !rightOrder) && !$externalReset.value) {
      $isChangingToDate.toggle();
      $isChangingFromDate.toggle();
    }
    $externalReset.off();
    $isExternalToFromChanged.off();
  };

  const setToDate = () => {
    $isChangingFromDate.off();
    $isChangingToDate.on();
  };

  const setFromDate = () => {
    $isChangingFromDate.on();
    $isChangingToDate.off();
  };

  const selectToday = () => {
    $externalResetCalendar.on();
  };

  const resetDates = () => {
    $isChangingFromDate.on();
    $isChangingToDate.off();
    setRangeStart(null);
    setRangeEnd(null);
    setFrom('');
    setTo('');
    $externalReset.on();
  };

  function isDateFormatValid(value: string) {
    const date = moment(value, 'DD-MM-YYYY', true);
    return date.isValid() && date.format('DD-MM-YYYY') === value;
  }

  const toFromInput = (e: ChangeEvent<HTMLInputElement>, paramToChange: (arg0: string) => void) => {
    let input = e.target.value.replace(/[^0-9-]/gi, '');
    if (input.match(/[0-9]{8}/)) {
      input = `${input.substring(0, 2)}-${input.substring(2, 4)}-${input.substring(4)}`;
    }
    if (input.match(regexDateValidator)) {
      $isExternalToFromChanged.on();
    }
    paramToChange(input);

    if (!isDateFormatValid(input)) {
      e.target.name === 'from' ? setFromError(true) : setToError(true);
    } else {
      e.target.name === 'from' ? setFromError(false) : setToError(false);
    }
  };

  const handleClose = () => {
    if (isSingleDate && isTimeSelect) {
      if (rangeStart && timeStart && timeEnd) {
        const newRangeStart = parseISO(rangeStart);
        newRangeStart.setHours(getHours(timeStart), getMinutes(timeStart));

        const newRangeEnd = parseISO(rangeStart);
        newRangeEnd.setHours(getHours(timeEnd), getMinutes(timeEnd));
        onClose(formatISO(newRangeStart), formatISO(newRangeEnd));
      } else {
        onClose(undefined, undefined);
      }

      // For date picker we provide only rangeStart but with start and end time
    } else if (isSingleDate) {
      // For date picker we provide only rangeStart
      onClose(rangeStart, undefined);
    } else {
      // For date range picker we provide rangeStart and rangeEnd
      onClose(rangeStart, rangeEnd);
    }
  };

  const handleTimeStartChange = (timeStart?: number) => {
    if (timeStart === undefined) {
      setTimeStart(undefined);
      return;
    }

    // In case start time is after end time, we update end time to be 1 hour after start
    if (timeEnd !== undefined && timeStart >= timeEnd) {
      // In case we try to set start time as last possible time we cannot add 1 hour to endTime
      // so in this case we manually change start and end to be as late as possible
      if (timeStart === 23 * 60 + 30) {
        setTimeStart(23 * 60);
        setTimeEnd(23 * 60 + 30);
      } else {
        setTimeStart(timeStart);
        setTimeEnd(timeStart + 60);
      }
    } else {
      setTimeStart(timeStart);
    }
  };

  const handleTimeEndChange = (timeEnd?: number) => {
    if (timeEnd === undefined) {
      setTimeEnd(undefined);
      return;
    }

    // In case end time is before start time, we update start time to be 1 hour before end
    if (timeStart !== undefined && timeEnd <= timeStart) {
      // In case we try to set end time as early possible time we cannot subtract 1 hour to start time
      // so in this case we manually change start and end to be as early as possible
      if (timeEnd === 0) {
        setTimeEnd(0);
        setTimeStart(60);
      } else {
        setTimeEnd(timeEnd);
        setTimeStart(timeEnd - 60);
      }
    } else {
      setTimeEnd(timeEnd);
    }
  };

  return (
    <StyledModal
      width={583}
      onClose={onClose}
      contentStyles={{ background: COLORS.WHITE, height: '100%', padding: '30px' }}
      isHideHeader
      hideFooter
    >
      <Header>
        <LeftHeader>
          <Trans>Select a date</Trans>
        </LeftHeader>
        <RightHeader>
          <SelectToday onClick={selectToday}>
            <Trans>Select today</Trans>
          </SelectToday>
          <ResetDates onClick={resetDates}>
            <IconOld className="mirror" name="Refresh" width={18} height={18} />{' '}
            {isSingleDate ? <Trans>Reset date</Trans> : <Trans>Reset dates</Trans>}
          </ResetDates>
        </RightHeader>
      </Header>
      <Body>
        <LeftBody>
          <Calendar
            onSetRange={onSetRange}
            disableBackButton={false}
            preSelectMonth={false}
            externalReset={$externalReset}
            externalResetCalendar={$externalResetCalendar}
            externalClear={true}
            externalToFromChange={$isExternalToFromChanged}
            externalFrom={from}
            changingFromDate={isSingleDate ? true : $isChangingFromDate.value}
            changingToDate={isSingleDate ? false : $isChangingToDate.value}
            $isChangingToDate={$isChangingToDate}
            $isChangingFromDate={$isChangingFromDate}
            externalTo={to}
            isSingleDate={isSingleDate}
            initialTo={initialTo && new Date(initialTo)}
            initialFrom={initialFrom && new Date(initialFrom)}
          />
        </LeftBody>
        <RightBody>
          <FromToContainer>
            {isSingleDate ? <Trans>Date</Trans> : <Trans>From</Trans>}
            <Input
              onClick={setFromDate}
              type="text"
              value={from}
              onInput={(e: ChangeEvent<HTMLInputElement>) => toFromInput(e, setFrom)}
              name="from"
              error={fromError && i18n._(t`Date not valid`)}
              placeholder="DD-MM-YYYY"
            />
          </FromToContainer>
          {!isSingleDate && (
            <FromToContainer>
              <Trans>Till</Trans>
              <Input
                onClick={setToDate}
                type="text"
                value={to}
                onInput={(e: ChangeEvent<HTMLInputElement>) => toFromInput(e, setTo)}
                name="to"
                error={toError && i18n._(t`Date not valid`)}
                placeholder="DD-MM-YYYY"
              />
            </FromToContainer>
          )}
          {rangeStart && rangeEnd && (
            <SelectedNumberOfDays>
              {moment(rangeEnd).diff(moment(rangeStart), 'days') === 1
                ? i18n._(t`1 day`)
                : i18n._(t`${moment(rangeEnd).diff(moment(rangeStart), 'days')} days`)}
            </SelectedNumberOfDays>
          )}
          {isTimeSelect && isSingleDate && (
            <TimeContainer>
              <TimePickerContainer>
                <Trans>Time start</Trans>
                <TimePicker selectedTime={timeStart} onChange={handleTimeStartChange} />
              </TimePickerContainer>
              <TimePickerContainer>
                <Trans>Time end</Trans>
                <TimePicker selectedTime={timeEnd} onChange={handleTimeEndChange} />
              </TimePickerContainer>
            </TimeContainer>
          )}
          {isTimeSelect &&
            timeStart !== undefined &&
            timeEnd !== undefined &&
            timeStart < timeEnd && (
              <SelectedNumberOfDays>
                <Trans>Total time: {stringifyTime(timeEnd - timeStart)} hours</Trans>
              </SelectedNumberOfDays>
            )}
          <ButtonContainer>
            <StyledSecondaryButton size={ButtonSize.MEDIUM} onClick={() => onClose()}>
              <Trans>Cancel</Trans>
            </StyledSecondaryButton>
            <StyledPrimaryButton size={ButtonSize.MEDIUM} onClick={handleClose}>
              <Trans>Apply</Trans>
            </StyledPrimaryButton>
          </ButtonContainer>
        </RightBody>
      </Body>
    </StyledModal>
  );
};

export { DateRangePicker };
