import React, { MouseEventHandler } from 'react';

import { PATH_STATUSES } from '@learned/constants';
import { t } from '@lingui/macro';

import { LearningItemType } from '~/components/LearningItemType';
import { FlexRow, NameRow, TD } from '~/pages/Surveys/design';
import { NameWithQuestions } from '~/pages/Surveys/NameWithQuestions';

import { StatusLabel } from './StatusLabel';

import type { IColumnTable } from '~/@types/table';

import type { ILibraryItem, ILocalPath, ILocalActivity } from './types';
import type { I18n } from '@lingui/core';

const LEARNING_LIBRARY_COLUMNS: IColumnTable<ILibraryItem>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'name',
    renderCell: (item, onClick: MouseEventHandler<HTMLDivElement> | undefined) => {
      return (
        <NameRow>
          {/* @ts-ignore */}
          <FlexRow onClick={() => onClick(item)}>
            <NameWithQuestions
              name={item.name as string}
              isGrayColor={(item as unknown as ILocalPath).status === PATH_STATUSES.DRAFT}
              isLeftMargin={false}
            />
          </FlexRow>
        </NameRow>
      );
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Type`),
    accessor: 'type',
    renderCell: (item) => {
      const isActivity = item.collection === 'activities';
      return (
        <NameRow>
          <FlexRow>
            <LearningItemType
              isActivity={isActivity}
              type={isActivity ? (item as unknown as ILocalActivity).type : null}
            />
          </FlexRow>
        </NameRow>
      );
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Progress`),
    accessor: 'progress',
    minWidth: '162px',
    renderCell: (item) => {
      return (
        <NameRow>
          <FlexRow>
            <TD isGrayColor={(item as unknown as ILocalPath).status === PATH_STATUSES.DRAFT}>
              {/* @ts-ignore */}
              {`${item?.progress}%`}
            </TD>
          </FlexRow>
        </NameRow>
      );
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Status`),
    accessor: 'status',
    minWidth: '162px',
    renderCell: (item) => {
      return (
        <NameRow>
          <FlexRow>
            <TD isGrayColor={(item as unknown as ILocalPath).status === PATH_STATUSES.DRAFT}>
              <StatusLabel status={item.status} />
            </TD>
          </FlexRow>
        </NameRow>
      );
    },
  },
];

export { LEARNING_LIBRARY_COLUMNS };
