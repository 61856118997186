import React from 'react';

import { ROLES } from '@learned/constants';
import { t } from '@lingui/macro';

import { UserAvatar } from '~/components/UserAvatar';

import type { IColumnTable } from '~/@types/table';

import { SORT_OPTIONS } from '.';

import type { User } from '.';
import type { I18n } from '@lingui/core';

export const getColumns = (role: ROLES) => {
  const columns: IColumnTable<User>[] = [
    {
      maxWidth: '240px',
      minWidth: '240px',
      name: (i18n: I18n) => i18n._(t`Members`),
      accessor: 'name',
      renderCell: (item) => {
        return <UserAvatar user={item} />;
      },
      sortBy: {
        asc: {
          key: SORT_OPTIONS.NAME_A_Z,
          label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
        },
        desc: {
          key: SORT_OPTIONS.NAME_Z_A,
          label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
        },
      },
    },
  ];

  if ([ROLES.COACH, ROLES.ADMIN].includes(role)) {
    columns.push({
      name: (i18n: I18n) => i18n._(t`Job`),
      accessor: 'job',
      maxWidth: '140px',
      minWidth: '140px',
      renderCell: (item) => {
        return <span>{item.role}</span>;
      },
      sortBy: {
        asc: {
          key: SORT_OPTIONS.ROLE_A_Z,
          label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
        },
        desc: {
          key: SORT_OPTIONS.ROLE_Z_A,
          label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
        },
      },
    });
  }

  return columns;
};
