import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Dialog from '~/components/Dialog';

import InfoPageNew from './InfoPageNew';

import useBoolState from '~/hooks/useBoolState';
import { getUser } from '~/selectors/baseGetters';
import getLang from '~/selectors/getLang';
import { updateUserProfile } from '~/services/users';
import { setAuthUser } from '~/store/auth/actions';

const SetupPage = styled.div`
  height: 100%;
  padding: 27px 40px 18px 40px;
`;

async function completeSetup(userData, dispatch) {
  await updateUserProfile({ ...userData, completedFirstTimeSetup: true });
  await dispatch(setAuthUser({ ...userData, completedFirstTimeSetup: true }));
}

const validators = (userData, profileSetupData) => {
  /**
   * I have made a quick for validations LR-1910
   * The InfoPageNew component should be re written with ts and react form library
   */
  const phoneRegex = new RegExp('^\\d{10}$');
  const nameRegex = new RegExp('^[^\\s]+(?:$|.*[^\\s]+$)');
  let validation = {
    validAvatar: true,
    dateOfBirth: {
      isValid: true,
      message: '',
    },
    valid: true,
    phone: {
      isValid: true,
      message: '',
    },
    firstName: {
      isValid: true,
      message: '',
    },
    lastName: {
      isValid: true,
      message: '',
    },
  };
  if (!profileSetupData || !profileSetupData.avatarUploaded) {
    validation.validAvatar = false;
    validation.valid = false;
  }

  if (!userData || !userData.firstName || userData.firstName.length === 0) {
    validation.valid = false;
    validation.firstName.isValid = false;
    validation.firstName.message = t`First name is required`;
  } else if (userData.firstName.length > 50) {
    validation.valid = false;
    validation.firstName.isValid = false;
    validation.firstName.message = t`Exceeds maximum length of 50 characters`;
  } else if (!nameRegex.test(userData.firstName)) {
    validation.valid = false;
    validation.firstName.isValid = false;
    validation.firstName.message = t`Whitespace not allowed`;
  }

  if (!userData || !userData.lastName || userData.lastName.length === 0) {
    validation.valid = false;
    validation.lastName.isValid = false;
    validation.lastName.message = t`Last name is required`;
  } else if (userData.lastName.length > 50) {
    validation.valid = false;
    validation.lastName.isValid = false;
    validation.lastName.message = t`Exceeds maximum length of 50 characters`;
  } else if (!nameRegex.test(userData.lastName)) {
    validation.valid = false;
    validation.lastName.isValid = false;
    validation.lastName.message = t`Whitespace not allowed`;
  }

  if (userData && userData.phone && !phoneRegex.test(userData.phone)) {
    validation.valid = false;
    validation.phone.isValid = false;
    validation.phone.message = t`Invalid phone`;
  } else if (userData && userData.phone && userData.phone.length > 10) {
    validation.valid = false;
    validation.phone.isValid = false;
    validation.phone.message = t`Exceeds maximum length of 10 characters`;
  }

  if (!userData || !userData.dateOfBirth) {
    validation.valid = false;
    validation.dateOfBirth.isValid = false;
    validation.dateOfBirth.message = t`Invalid date of birth`;
  }

  return validation;
};

function FirstTimeSetupModalNew({ onClose = () => {} }) {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const lang = useSelector(getLang);

  const $showFormErrors = useBoolState(false);
  const loadingState = useState();
  const userDataState = useState();
  const [userData, setUserData] = userDataState;
  const profileSetupDataState = useState();
  const [profileSetupData, setProfileSetupData] = profileSetupDataState;
  const [userAgreement, setUserAgreement] = useState(false);

  if (user && !userData) {
    setUserData({
      firstName: user.firstName,
      lastName: user.lastName,
      locale: user.locale || lang,
      gender: user.gender || '',
      dateOfBirth: user.dateOfBirth || '',
    });
  }

  if (user && !profileSetupData) {
    setProfileSetupData({
      avatarUploaded: !!user.avatarUrl,
      validation: { valid: true },
    });
  }

  const validations = validators(userData, profileSetupData);

  const onCompleteClick = () => {
    if (!validations.valid || (user.isOwner && !userAgreement)) {
      $showFormErrors.on();
      return;
    }

    if (userData.gender === '' || userData.gender === null) {
      userData.gender = undefined;
    }
    completeSetup(userData, dispatch);
  };

  return (
    <Dialog
      className={''}
      onClose={onClose}
      width={750}
      minWidth={750}
      isCloseOnOutsideClick={false}
      borderRadius={10}
    >
      <SetupPage>
        {userData && (
          <>
            <InfoPageNew
              user={user}
              userDataState={userDataState}
              profileSetupDataState={profileSetupDataState}
              loadingState={loadingState}
              validation={validations}
              isShowError={$showFormErrors.value}
              submitTo={onCompleteClick}
              agreement={userAgreement}
              onAgreement={setUserAgreement}
              skipFirstTimeSetup={false}
              layOut="1fr 1fr"
              isEditable={true}
            />
          </>
        )}
      </SetupPage>
    </Dialog>
  );
}

export default FirstTimeSetupModalNew;
