import React from 'react';

import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';

import { ROLES } from '~/constants';
import { updateSelectedRole, updateSelectedTeam } from '~/store/selected/actions';

import { MENU_ITEMS, SECOND_MENU_ITEMS } from '../constants';
import MenuItemBlock from '../MenuItemBlock';
import { IMenuItem } from '../types';

interface ISecondaryMenuItemProps {
  menuItem: IMenuItem;
  pathname: string;
  selectedTeamId: string;
  hash?: string;
}

const SecondaryMenuItem = ({
  menuItem,
  pathname,
  selectedTeamId,
  hash,
}: ISecondaryMenuItemProps) => {
  const dispatch = useDispatch();

  // we show the resume page twice in different second menu's
  const isCareerResumePath =
    pathname.indexOf(SECOND_MENU_ITEMS.RESUME) !== -1 && pathname.indexOf(MENU_ITEMS.CAREER) !== -1;
  const isCareerItem =
    (menuItem.url || '')?.indexOf(SECOND_MENU_ITEMS.RESUME) === -1 &&
    (menuItem.url || '')?.indexOf(MENU_ITEMS.CAREER) !== -1;

  const isActiveRoute =
    isCareerResumePath && isCareerItem
      ? false
      : (menuItem.url === pathname ||
          (menuItem.hash &&
            !isEmpty(hash) &&
            (menuItem.aliases || []).some((alias) => pathname.indexOf(alias) !== -1) &&
            hash?.includes(menuItem.hash)) ||
          (!menuItem.hash &&
            (menuItem.aliases || []).some((alias) => pathname.indexOf(alias) !== -1)) ||
          menuItem.childUrls?.includes(pathname)) &&
        ([ROLES.USER, ROLES.ADMIN].includes(menuItem.role as string) ||
          (menuItem.role === ROLES.COACH && menuItem.team === selectedTeamId) ||
          menuItem.superAdminRoute);

  return (
    <MenuItemBlock
      item={menuItem}
      isMenuCollapsed={false}
      isShowTooltip={false}
      onLinkClick={() => {
        // @ts-ignore
        dispatch(updateSelectedRole(menuItem.role));
        if (menuItem.role === ROLES.COACH) {
          // @ts-ignore
          dispatch(updateSelectedTeam(menuItem.team));
        }
      }}
      // @ts-ignore
      linkTo={menuItem.url}
      isCurrent={isActiveRoute}
    />
  );
};

export default SecondaryMenuItem;
