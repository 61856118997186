import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { UseFormReturn } from 'react-hook-form';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Input } from '~/components/Input';

import routes from '~/constants/routes';
import { COLORS } from '~/styles';
import { EMAIL_PATTERN } from '~/utils/isValidEmail';

import { ButtonsWrapper, FormWrapper, Subtitle, Title, SectionWrapper } from '../design';
import { IRegisterWithCompanyForm } from '../types';

const AgreementWrapper = styled.div`
  margin: 60px 0 140px;
  width: 347px;
  text-align: center;
  & a {
    color: ${COLORS.COMPANY};
    text-decoration: none;
  }
  font-size: 14px;
`;

const LoginWrapper = styled.div`
  width: 226px;
  text-align: center;
  color: ${COLORS.TEXT_MAIN};
  font-size: 14px;
  & a {
    color: ${COLORS.COMPANY};
    text-decoration: none;
  }
`;

interface IMainSectionProps {
  formMethods: UseFormReturn<IRegisterWithCompanyForm>;
}

const MainSection = ({ formMethods }: IMainSectionProps) => {
  const { i18n } = useLingui();
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = formMethods;

  return (
    <SectionWrapper>
      <Title>
        <Trans>Welcome at Learned</Trans>
      </Title>
      <Subtitle>
        <Trans>Get started. Try Learned 14 days for free.</Trans>
      </Subtitle>
      <FormWrapper>
        <Input
          type="email"
          width="285px"
          label={i18n._(t`Work email`)}
          placeholder={i18n._(t`Emma@mycompany.com`)}
          register={register('email', {
            required: {
              value: true,
              message: i18n._(t`Email is not valid`),
            },
            pattern: {
              value: EMAIL_PATTERN,
              message: i18n._(t`Email is not valid`),
            },
          })}
        />
        <ButtonsWrapper>
          <Button
            type="button"
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.NAVIGATION_PRIMARY}
            label={i18n._(t`Next`)}
            disabled={!!errors.email || !watch('email')?.length}
            onClick={() => setValue('step', 2)}
          />
        </ButtonsWrapper>
      </FormWrapper>
      <AgreementWrapper>
        <Trans>
          By continuing you accept the{' '}
          <a
            href="https://www.learned.io/wp-content/uploads/2020/12/Learned-Algemene-Voorwaarden.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            T&C
          </a>
          {', '}
          <a href="https://www.learned.io/nl/privacy/" target="_blank" rel="noopener noreferrer">
            Privacy Statement
          </a>{' '}
          and{' '}
          <a
            href="https://www.learned.io/wp-content/uploads/2020/12/Learned-Verwerkersovereenkomst.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            processing agreement
          </a>
          .
        </Trans>
      </AgreementWrapper>
      <LoginWrapper>
        <Trans>
          Already have an account? <Link to={routes.HOME}>Login</Link>
        </Trans>
      </LoginWrapper>
    </SectionWrapper>
  );
};

export { MainSection };
