import React from 'react';

import { TASK_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { TaskStatus } from './TaskStatus';

import { COLORS } from '~/styles';

import { ListRow, RowRightBlock, RowLeftBlock } from '../design';

import type { ITask } from '@learned/types';

const Title = styled.div`
  font-size: 14px;
  line-height: 1.86;
  color: ${COLORS.TEXT_MAIN};
`;

interface ListRowSelfProps {
  task: ITask;
}

const ListRowSelf = ({ task }: ListRowSelfProps) => {
  const { i18n } = useLingui();

  let title = '';

  switch (task.type) {
    case TASK_TYPE.REVIEW_SELF_EVALUATE:
      title = i18n._(t`Prepare self review`);
      break;
    case TASK_TYPE.REVIEW_PEER_NOMINATE:
      title = i18n._(t`Ask peers for input`);
      break;
    default:
      break;
  }

  return (
    <ListRow>
      <RowRightBlock>
        <Title>{title}</Title>
      </RowRightBlock>
      <RowLeftBlock>
        <TaskStatus task={task} />
      </RowLeftBlock>
    </ListRow>
  );
};

export { ListRowSelf };
