import React, { Dispatch, SetStateAction, useRef } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';

import PopOver, { PopOverPlacement } from './PopOver';

import { COLORS } from '~/styles';

const ContextMenuBtn = styled.div<{ heightVal: string; widthVal: string }>`
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  width: ${({ widthVal }) => widthVal};
  height: ${({ heightVal }) => heightVal};
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLORS.WHITE};
  color: ${COLORS.TEXT_HOVER};
  font-weight: 400;
  font-size: 14px;
  border: 1px solid ${COLORS.ICONS_SECONDARY};
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
`;

const OptionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  min-width: 80px;
  &:hover {
    background: ${COLORS.HOVER};
  }
`;

const ContextWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .tippy-content {
    padding: 0;
  }
  .tippy-box {
    background: transparent;
  }
`;

type TProps = {
  opened: string | null;
  itemOpened: string;
  setOpened: Dispatch<SetStateAction<string | null>>;
  onEdit?: () => void;
  onDelete?: () => void;
  widthVal: string;
  heightVal: string;
};

function ContextMenu({
  opened,
  setOpened,
  itemOpened,
  onEdit,
  onDelete,
  heightVal,
  widthVal,
}: TProps): JSX.Element {
  const { i18n } = useLingui();
  const ref = useRef<HTMLDivElement>(null);
  return (
    <ContextWrapper>
      <PopOver
        placement={PopOverPlacement.AUTO_END}
        arrow={false}
        interactive={true}
        visible={opened === itemOpened}
        onClickOutside={() => {
          setOpened(null);
        }}
        offset={[0, 5]}
        reference={ref}
        content={
          <Options>
            {onEdit && (
              <OptionItem
                onClick={() => {
                  onEdit();
                }}
              >
                <Icon icon={ICONS.DUPLICATE} size={ICON_SIZES.SMALL} />
                {i18n._(t`Edit`)}
              </OptionItem>
            )}
            {onDelete && (
              <OptionItem
                onClick={() => {
                  onDelete();
                }}
              >
                <Icon icon={ICONS.DELETE_BIN} size={ICON_SIZES.SMALL} />
                {i18n._(t`Delete`)}
              </OptionItem>
            )}
          </Options>
        }
      >
        <ContextMenuBtn
          onClick={() => setOpened(itemOpened)}
          className={`${
            opened === itemOpened ? 'context-menu context-menu-active' : 'context-menu'
          }`}
          heightVal={heightVal}
          widthVal={widthVal}
        >
          <Icon icon={ICONS.KEBAB_MENU} size={ICON_SIZES.MEDIUM} />
        </ContextMenuBtn>
      </PopOver>
    </ContextWrapper>
  );
}

export default ContextMenu;
