import styled from 'styled-components';

import { COLOR_PALETTE, COLORS, COLOR_SET } from '~/styles';

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseIconButton = styled.div`
  margin-left: 13px;
  cursor: pointer;
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: ${(props) => (props.$isTabs ? '8px 16px 12px' : props.$padding || '24px 16px 12px')};
`;

const DeleteIcon = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: #e93c3c;
  cursor: pointer;
`;

const Footer = styled.div`
  box-sizing: border-box;
  position: relative;
  min-height: 72px;
  height: 72px;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  color: #3b3b3b;
  border-top: 1px solid #ecedf0;
  z-index: 10;
`;

const Header = styled.div`
  box-sizing: border-box;
  position: relative;
  min-height: 72px;
  height: ${(props) => props.height || '72px'};
  width: 100%;
  padding: ${(props) => props.padding || '20px 16px'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ecedf0;
  z-index: 10;
`;

const HeaderTitle = styled.h5`
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  color: #3b3b3b;
  margin: 0;
`;

const ModalWrap = styled.div`
  display: flex;
  width: 750px;
  box-sizing: border-box;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 1px 1px 2px 0 rgba(25, 42, 70, 0.08);
  border: solid 0.5px #ecedf0;
  background-color: ${COLOR_PALETTE.WHITE};
  position: relative;
  z-index: 1000;
`;

const SettingsRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const SettingsText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${(props) => (props.isActive ? 'var(--company-color)' : COLOR_SET.MIDDLE_GRAY_BLUE)};
`;

const TextBox = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 52px;
  align-items: center;
  border-radius: 6px;
  margin-bottom: 2px;
  padding: 0 16px;
  margin-bottom: 8px;
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDER_HARD};
}
`;

const TextBlock = styled.h6`
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #333333;
`;

export {
  Footer,
  Header,
  HeaderTitle,
  ContentWrap,
  CloseIconButton,
  ModalWrap,
  TextBox,
  TextBlock,
  SettingsRow,
  SettingsText,
  BottomRow,
  DeleteIcon,
};
