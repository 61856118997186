import React, { useState } from 'react';

import { DashboardSidebar } from '~/components/DashboardSidebar';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import { MainWrapper, Wrapper } from './design';
import { DashboardLayoutProps } from './types';

const DashboardLayout = ({ sidebar, main, isLoading }: DashboardLayoutProps) => {
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(true);

  return (
    <ShowSpinnerIfLoading loading={!!isLoading}>
      <Wrapper isMenuCollapsed={isMenuCollapsed}>
        <DashboardSidebar
          isMenuCollapsed={isMenuCollapsed}
          setIsMenuCollapsed={setIsMenuCollapsed}
          items={sidebar.items}
          buttons={sidebar.buttons}
        />
        <MainWrapper>
          <div className="content">{main}</div>
        </MainWrapper>
      </Wrapper>
    </ShowSpinnerIfLoading>
  );
};

export { DashboardLayout };
