import React from 'react';
import type { ReactNode } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS, ICON_SIZES } from '~/components/Icon';

import { RightActionContainer, Wrapper } from './design';

interface IFooter {
  isPrev?: boolean;
  isNext?: boolean;
  onPrev?: () => void;
  children?: ReactNode;
  hideSeparator?: boolean;
}
const StepFooter = ({
  isPrev = true,
  isNext = true,
  onPrev,
  children,
  hideSeparator = false,
}: IFooter) => {
  const { i18n } = useLingui();

  return (
    <Wrapper $hideSeparator={hideSeparator}>
      <div>
        {isPrev && (
          <Button
            label={i18n._(t`Back`)}
            type="button"
            variant={ButtonVariant.TEXT_PRIMARY}
            icon={ICONS.BACK}
            size={ButtonSize.MEDIUM}
            iconSize={ICON_SIZES.SMALL}
            onClick={onPrev}
          />
        )}
      </div>
      {children}
      <RightActionContainer>
        {isNext && (
          <Button
            label={i18n._(t`Next`)}
            variant={ButtonVariant.NAVIGATION_PRIMARY}
            size={ButtonSize.MEDIUM}
            type="submit"
          />
        )}
      </RightActionContainer>
    </Wrapper>
  );
};

export { StepFooter };
