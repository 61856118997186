import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const HandPointingIcon = ({ size, ...props }) => (
  <InlineSvg height={size} width={size} {...props} childrenFirst viewBox="0 0 32 32">
    <g id="Reports" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Report---9grid---list-view-" transform="translate(-936.000000, -490.000000)">
        <g id="Group-15" transform="translate(346.000000, 124.000000)">
          <g id="Group-13" transform="translate(478.000000, 366.000000)">
            <g
              id="Hand-Pointing"
              transform="translate(128.000000, 16.000000) scale(-1, 1) rotate(90.000000) translate(-128.000000, -16.000000) translate(112.000000, 0.000000)"
            >
              <rect id="Rectangle" x="0" y="0" width="32" height="32" />
              <path
                d="M16,13 L16,4.5 C16,3.11928813 14.8807119,2 13.5,2 C12.1192881,2 11,3.11928813 11,4.5 L11,19.65826 L8.25905875,14.9108138 C7.812875,14.1365065 6.98730921,13.6592522 6.09364729,13.6590032 C5.19998537,13.6587543 4.37415382,14.1355486 3.92753876,14.9096072 C3.4809237,15.6836657 3.48145554,16.6372538 3.92893375,17.4108138 C8,26 10.4771525,29 16,29 C18.6521649,29 21.195704,27.9464316 23.0710678,26.0710678 C24.9464316,24.195704 26,21.6521649 26,19 L26,14 C26,12.6192881 24.8807119,11.5 23.5,11.5 C22.1192881,11.5 21,12.6192881 21,14"
                id="Path"
                stroke="currentColor"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21,14 L21,12.5 C21,11.1192881 19.8807119,10 18.5,10 C17.1192881,10 16,11.1192881 16,12.5 L16,13"
                id="Path"
                stroke="currentColor"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </InlineSvg>
);

HandPointingIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

HandPointingIcon.defaultProps = {
  size: 32,
  className: '',
};

export default HandPointingIcon;
