import styled, { css } from 'styled-components';

import { ButtonPrimary, ButtonWhite } from '~/components/Button';

import { COLORS } from '~/styles';

const ActionItemBlock = styled.div`
  display: flex;
  align-items: center;
  border-left: solid 1.2px ${COLORS.BORDER_HARD};
  justify-content: center;
  padding-left: 17px;
  padding-right: 17px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
`;

const BackButton = styled(ButtonWhite)<{ isHidden?: boolean }>`
  height: 32px;
  min-width: 80px;
  padding: 0 14px 0 10px;
  border-radius: 100px;
  font-size: 12px;
  border: none;
  color: var(--company-color);
  background-color: unset;

  ${(props) =>
    props.isHidden
      ? css`
          visibility: hidden;
        `
      : ''}

  &:hover {
    color: var(--company-color);
    background-color: unset;
  }

  & .icon {
    color: var(--company-color);
    margin-right: 4px;
    fill: currentColor;
  }
`;

const SubmitButton = styled(ButtonPrimary)<{ isHidden?: boolean }>`
  height: 32px;
  min-width: 80px;
  padding: 0 10px 0 14px;
  border-radius: 100px;
  font-size: 12px;
  text-align: center;
  gap: 4px;
  ${(props) =>
    props.isHidden
      ? css`
          visibility: hidden;
        `
      : ''}
`;

export { Actions, ActionItemBlock, SubmitButton, BackButton };
