import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const Pencil2Icon = ({ size, ...props }) => (
  <InlineSvg height={size} width={size} {...props} childrenFirst viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M7.241 16.875H3.75a.625.625 0 0 1-.625-.625v-3.491c0-.166.066-.325.183-.442l9.375-9.375a.625.625 0 0 1 .884 0l3.491 3.491a.625.625 0 0 1 0 .884l-9.375 9.375a.625.625 0 0 1-.442.183zM10.625 5 15 9.375M7.46 16.835 3.165 12.54"
        stroke="currentColor"
        strokeWidth="1.24"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </InlineSvg>
);

Pencil2Icon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

Pencil2Icon.defaultProps = {
  size: 20,
  className: '',
};

export default Pencil2Icon;
