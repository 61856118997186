import React, { UIEvent, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Trans } from '@lingui/macro';
import Tippy from '@tippyjs/react';

import {
  DropdownContainer,
  NoResults,
  OptionContainer,
  Options,
  SliderAnimation,
  Wrapper,
} from '~/components/Dropdown/design';
import type { BaseDropdownProps } from '~/components/Dropdown/types';

import { Action } from './Action';
import { Search } from './Search';

import useDebounce from '~/hooks/useDebounce';
import { useOutsideClick } from '~/hooks/useOutsideClick';

const tippyOpts = { modifiers: [{ name: 'computeStyles', options: { gpuAcceleration: false } }] };

function BaseDropdown<T>({
  items,
  isSearchable,
  actions,
  stringifyItem,
  onScrollToBottom,
  onSearchChange,
  children,
  showDropdown,
  hideDropdown,
  renderOption,
  disableTooltip,
  className,
}: BaseDropdownProps<T>) {
  const [search, setSearch] = useState('');
  // TODO remove debounced search (the dropdown can be used in a regular context too and debounced search is only when
  //  dealing with an API call, the debouncing should be handled in the parent component)
  const debouncedSearch = useDebounce(search, 500);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const outsideClickRef = useOutsideClick<HTMLDivElement>(() => {
    hideDropdown();
  });
  const [sliderHeight, setSliderHeight] = useState(0);

  const filteredItems =
    isSearchable && !onSearchChange
      ? items.filter((item) =>
          (stringifyItem ? stringifyItem(item) : (item as string))
            .toLowerCase()
            .includes(search.toLowerCase()),
        )
      : items;

  useEffect(() => {
    onSearchChange?.(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  useLayoutEffect(() => {
    const height = dropdownRef.current?.clientHeight ?? 0;
    setSliderHeight(height + (actions?.length ? actions.length * 20 : 20));
  }, [filteredItems, actions, isSearchable, items, showDropdown]);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const target = e?.target as HTMLDivElement;
    if (!target) {
      return;
    }

    const bottom = target.scrollHeight - target.scrollTop === target.clientHeight;
    if (!bottom) {
      return;
    }

    onScrollToBottom?.();
  };

  return (
    <Wrapper ref={outsideClickRef} className={className}>
      {children}
      <SliderAnimation
        $height={sliderHeight < 400 ? sliderHeight : 400}
        $showDropdown={showDropdown}
      >
        <DropdownContainer ref={dropdownRef}>
          {isSearchable && <Search search={search} setSearch={setSearch} />}
          <Options onScroll={handleScroll}>
            {filteredItems.map((item, i) => (
              <OptionContainer
                $skipTopBorderRadius={isSearchable}
                $skipBottomBorderRadius={actions && actions.length > 0}
                key={i}
              >
                {disableTooltip ? (
                  renderOption(item)
                ) : (
                  <Tippy
                    trigger="mouseenter"
                    theme="dark"
                    popperOptions={tippyOpts}
                    // TODO remove tooltip custom property and ts-ignore. This should be solved in the parent component
                    //  by passing a CustomOptionComponent or in a different way but not a magic attribute
                    // @ts-ignore
                    content={(item?.tooltip || stringifyItem?.(item)) ?? item}
                    delay={[300, 0]}
                  >
                    {renderOption(item)}
                  </Tippy>
                )}
              </OptionContainer>
            ))}
            {filteredItems.length === 0 && (
              <OptionContainer
                $skipTopBorderRadius={isSearchable}
                $skipBottomBorderRadius={actions && actions.length > 0}
              >
                <NoResults>
                  <Trans>No results...</Trans>
                </NoResults>
              </OptionContainer>
            )}
          </Options>
          {actions &&
            actions?.length > 0 &&
            actions.map((action) => (
              <Action key={action.name} action={action} hideDropdown={hideDropdown} />
            ))}
        </DropdownContainer>
      </SliderAnimation>
    </Wrapper>
  );
}

export { BaseDropdown };
