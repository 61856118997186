import React from 'react';

import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 32px;
`;

const BaseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  margin-top: ${(props) => !props.$noMarginTop && '28px'};
  max-width: ${(props) => props.$width || '1270px'};
  width: 100%;
  ${(props) =>
    props.$isFullWidth && {
      width: '100%',
    }};
`;

const Container = styled.div`
  width: 100%;
  max-width: ${(props) => props.maxWidth || null};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const NoMaxWidthContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const BaseLayout = ({
  children,
  loading,
  maxWidth,
  noMaxWidth,
  noMarginTop,
  containerStyles,
  smallWidth,
}) => {
  const calcWidth = () => {
    if (maxWidth) {
      if (typeof maxWidth === 'number') {
        return maxWidth + 'px';
      }
      return maxWidth;
    }
    if (smallWidth) {
      return '1070px;';
    }
    return '1270px';
  };
  const width = calcWidth();
  return (
    <ShowSpinnerIfLoading loading={loading}>
      <Wrapper>
        <BaseContainer $noMarginTop={noMarginTop} $width={width} $isFullWidth={noMaxWidth}>
          {noMaxWidth ? (
            <NoMaxWidthContainer style={containerStyles}>{children}</NoMaxWidthContainer>
          ) : (
            <Container style={containerStyles}>{children}</Container>
          )}
        </BaseContainer>
      </Wrapper>
    </ShowSpinnerIfLoading>
  );
};

BaseLayout.propTypes = {
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  smallWidth: PropTypes.bool,
  loading: PropTypes.bool,
  noMaxWidth: PropTypes.bool,
  noMarginTop: PropTypes.bool,
  containerStyles: PropTypes.object,
};

export default React.memo(BaseLayout);
