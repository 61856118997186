import React from 'react';

import { Trans } from '@lingui/macro';
import { UseFormReturn } from 'react-hook-form';

import type { ISectionState } from '~/components/SideBar/SectionStateHook';

import { GeneralSettings } from './GeneralSettings';
import { NotificationSettings } from './NotificationSettings';
import { PrivacySettings } from './PrivacySettings';

import { Form, Title } from '../../design';

import type { IReviewIndividualForm } from '../../types';

interface SettingsSectionProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewIndividualForm>;
}

const SettingsSection = ({ formMethods }: SettingsSectionProps) => {
  return (
    <>
      <Form>
        <Title>
          <Trans>Settings</Trans>
        </Title>
        <GeneralSettings formMethods={formMethods} />
        <NotificationSettings formMethods={formMethods} />
        <PrivacySettings formMethods={formMethods} />
      </Form>
    </>
  );
};

export { SettingsSection };
