import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Shadow from '~/components/UI/Shadow';

import { COLOR_PALETTE } from '~/styles';

const Wrapper = styled(Shadow)`
  background-color: ${COLOR_PALETTE.WHITE};
`;

const Header = styled.div`
  padding: 12px 22px 22px;
  border-bottom: solid 1px ${COLOR_PALETTE.GRAY_MIDDLE};
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin-bottom: 4px;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

export default function SectionTemplate({ title, description, hideHeader, children }) {
  return (
    <Wrapper>
      {!hideHeader && (
        <Header>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Header>
      )}
      {children}
    </Wrapper>
  );
}

SectionTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  hideHeader: PropTypes.bool,
};
