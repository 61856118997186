import React from 'react';

import { ICONS, Icon } from '~/components/Icon';

import { AddItemHorizontal, ItemBoxHorizontal } from './design';

import { COLORS } from '~/styles';

type TProps = { isFirst?: boolean; onClick: () => void };

const ActionButton = ({ isFirst = false, onClick }: TProps): JSX.Element => {
  return (
    <AddItemHorizontal isFirst={isFirst} onClick={onClick}>
      <ItemBoxHorizontal>
        <Icon
          icon={ICONS.ADD_PLUS}
          className="plus_icon"
          width={12}
          height={12}
          color={COLORS.PLACEHOLDERS}
        />
      </ItemBoxHorizontal>
    </AddItemHorizontal>
  );
};

export { ActionButton };
