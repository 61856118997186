import filter from 'lodash/filter';
import get from 'lodash/get';
import { createSelector } from 'reselect';

import { getTeams, getUser } from '~/selectors/baseGetters';

export default createSelector([getTeams, getUser], getCoachTeams);

function getCoachTeams(teams, currentUser) {
  return filter(teams, (t) => get(t, 'coaches', []).includes(currentUser.id));
}
