import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { ButtonPrimary } from '~/components/Button';
import Modal from '~/components/Modal';
import { QuestionView } from '~/components/QuestionView';

import type { IQuestionForm } from '~/@types/question';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { COLORS } from '~/styles';

interface IPreviewQuestionModelProps {
  defaultValues: IQuestionForm;
  languageState: ILanguageStateReturn;
  onClose: () => void;
  isPreselectedLang?: boolean;
}

const Wrapper = styled.form`
  justify-content: center;
  border-radius: 6px;
  overflow: hidden;
  padding: 32px 80px;
`;

const Title = styled.h2`
  font-size: 26px;
  color: ${COLORS.BLACK};
  font-weight: normal;
  margin-top: 0;
`;

const Footer = styled.div`
  display: flex;
  justify-content: end;
  gap: 20px;
  margin-top: 32px;
`;

const SubmitButton = styled(ButtonPrimary)`
  font-size: 12px;
  height: 32px;
  border-radius: 100px;
  min-width: 83px;
  text-align: center;
`;

const Card = styled.div`
  border-radius: 10px;
  border: 1px solid ${COLORS.BORDER_HARD};
  padding: 10px 40px 46px;
`;

const PreviewQuestionModal = ({
  defaultValues,
  languageState,
  onClose,
  isPreselectedLang = false,
}: IPreviewQuestionModelProps) => {
  const { i18n } = useLingui();

  const closeModal = () => {
    onClose();
  };

  return (
    <Modal
      isHideHeader
      hideFooter
      onClose={closeModal}
      showDivider={false}
      centerModal
      width={750}
      borderRadius={10}
      contentStyles={{ padding: '0', overflow: 'hidden' }}
    >
      <Wrapper>
        <Title>{i18n._(t`Preview`)}</Title>
        <Card>
          <QuestionView
            defaultValues={defaultValues}
            languageState={languageState}
            isPreselectedLang={isPreselectedLang}
          />
        </Card>
        <Footer>
          <div className="section">
            <SubmitButton type="button" onClick={closeModal}>
              {i18n._(t`Close`)}
            </SubmitButton>
          </div>
        </Footer>
      </Wrapper>
    </Modal>
  );
};

export { PreviewQuestionModal };
