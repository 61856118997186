import React, { ChangeEvent, useEffect, useState } from 'react';

import { ISurveyEvent, ISurveyTheme, ITeam } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import size from 'lodash/size';
import qs from 'qs';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import AutocompleteFilter from '~/components/AutocompleteFilter';
import Button from '~/components/Button';
import ExportIcon from '~/components/Icons/Export';
import PaginationBar from '~/components/PaginationBar';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SurveyQuestionComments } from '~/components/SurveyQuestionComments';
import { SurveyQuestionReport } from '~/components/SurveyQuestionReport';
import { prepareSurveyQuestionReportItem } from '~/components/SurveyQuestionReport/utils';
import { EventsSelector } from '~/components/Surveys/EventsSelector';
import { SearchField } from '~/components/Text';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import { Wrapper } from '~/pages/SurveyDashboard/design';

import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getSurveyEventsByUserSurveyId } from '~/services/surveyEvents';
import {
  downloadUserSurveyResultsQuestionsCSV,
  getUserSurveyResultsQuestions,
} from '~/services/userSurveys';
import { COLORS } from '~/styles';

const WrapperToCenter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  max-width: 628px;
  width: 628px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Filters = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 36px;
  margin-top: 24px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${COLORS.MIDDLE_GRAY};
`;

const ActionButton = styled(Button)`
  white-space: nowrap;
  height: 32px;
  box-shadow: 0 2px 4px 0 rgba(191, 191, 191, 0.5);
`;

const QuestionWrapper = styled.div`
  margin-bottom: 60px;
`;
const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  width: 100%;
  margin-right: 12px;
`;

const FiltersMargin = styled.div`
  margin-right: 12px;
`;

const initialFilters = {
  search: '',
  themes: [],
  teams: [],

  // pagination
  skip: 0,
  limit: 10, // 10 items per page
  page: 1, // start from first page
};

const QuestionsTab = () => {
  const params = useParams();
  const userSurveyId = get(params, 'userSurveyId') as unknown as string;
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const {
    eventId: preSelectedEventId,
    themeId: preSelectedThemeId,
    teamId: preSelectedTeamId,
  } = query;

  const { i18n } = useLingui();
  const { addToast } = useToasts();
  const getMultiLangString = useMultiLangString();
  const $isEventsLoading = useBoolState(true);
  const $isItemsLoading = useBoolState(true);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [events, setEvents] = useState<ISurveyEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<ISurveyEvent | undefined>();
  const [filters, setFilters] = useState(initialFilters);
  const debFilters = useDebounce(filters, 300); // to send request not immediately, but with delay

  // initial fetch (surveyEvents)
  useEffect(() => {
    const fetch = async () => {
      try {
        if (!userSurveyId) {
          return;
        }

        const events = await getSurveyEventsByUserSurveyId(userSurveyId, {
          include: ['themes.name', 'themes.id', 'teams.name', 'teams.id', 'startDate', 'endDate'],
        });

        setEvents(events.data.surveyEvents);

        // pre-select event
        let preSelectedEvent = null;
        if (preSelectedEventId) {
          preSelectedEvent = events.data.surveyEvents.find(
            (event: ISurveyEvent) => event.id === preSelectedEventId,
          );
        }
        const tempSelectedEvent = preSelectedEvent || last(events.data.surveyEvents);
        setSelectedEvent(tempSelectedEvent);

        // pre-select theme
        let preSelectedTheme = null;
        if (preSelectedThemeId) {
          preSelectedTheme = tempSelectedEvent.themes.find(
            (theme: ISurveyTheme) => theme.id === preSelectedThemeId,
          );
        }

        // pre-select team
        let preSelectedTeam = null;
        if (preSelectedTeamId) {
          preSelectedTeam = tempSelectedEvent.teams.find(
            (team: ITeam) => team.id === preSelectedTeamId,
          );
        }

        if (preSelectedTheme || preSelectedTeam) {
          onChangeFilters({
            ...(preSelectedTheme && { themes: [preSelectedTheme] }),
            ...(preSelectedTeam && { teams: [preSelectedTeam] }),
          });
        }
      } finally {
        $isEventsLoading.off();
      }
    };

    fetch();

    // eslint-disable-next-line
  }, []);

  // fetch surveyQuestions
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!userSurveyId) {
          return;
        }

        $isItemsLoading.on();
        if (selectedEvent?.id) {
          const questions = await getUserSurveyResultsQuestions(
            userSurveyId,
            {
              event: selectedEvent?.id,
              ...(filters.search && { search: filters.search }),
              ...(!isEmpty(filters.themes) && {
                themes: filters.themes.map((i: { id: string }) => i.id),
              }),
              ...(!isEmpty(filters.teams) && {
                teams: filters.teams.map((i: { id: string }) => i.id),
              }),
            },
            {
              skip: filters.skip,
              limit: filters.limit,
            },
          );

          setItems(questions.data.surveyResultsQuestions);
          setTotal(questions.data.total);
        }
      } finally {
        $isItemsLoading.off();
      }
    };

    fetchData();

    // eslint-disable-next-line
  }, [selectedEvent, debFilters]);

  const onEventChange = (value: ISurveyEvent['id']) => {
    const event = events.find((event) => event.id === value);
    setSelectedEvent(event);
  };

  const onExportCSV = async () => {
    addToast({
      title: i18n._(t`Exporting CSV`),
      subtitle: i18n._(
        t`Your CSV is being downloaded. This can take some time. It will download when it is ready.`,
      ),
      type: TOAST_TYPES.INFO,
    });

    if (selectedEvent?.id) {
      await downloadUserSurveyResultsQuestionsCSV(userSurveyId, {
        event: selectedEvent?.id,
        ...(filters.search && { search: filters.search }),
        ...(!isEmpty(filters.themes) && {
          themes: filters.themes.map((i: { id: string }) => i.id),
        }),
        ...(!isEmpty(filters.teams) && {
          teams: filters.teams.map((i: { id: string }) => i.id),
        }),
      });
    }
  };

  const onChangeFilters = (obj: Partial<typeof initialFilters>) => {
    setFilters({
      ...filters,

      // set default pagination on filters change
      skip: initialFilters.skip,
      limit: initialFilters.limit,
      page: initialFilters.page,

      // obj always in the end, in case we need to change pagination
      ...obj,
    });
  };

  const onChangeItemsPerPage = ({ limit }: { limit: number }) => {
    onChangeFilters({ limit, skip: 0, page: 1 }); // reset to default pagination (first page)
  };

  const onPageChangeClick = ({ skip, index }: { skip: number; index: number }) => {
    onChangeFilters({ skip, page: index });
  };

  return (
    <Wrapper>
      <WrapperToCenter>
        <ShowSpinnerIfLoading loading={$isEventsLoading.value}>
          <Content>
            {/* event selector*/}
            {events.length > 1 && (
              <EventsSelector
                items={events}
                selectedItem={selectedEvent?.id}
                onChange={onEventChange}
              />
            )}

            {/* csv export */}
            <Row>
              <Title>{i18n._(t`All questions and answers`)}</Title>
              <ActionButton
                // @ts-ignore
                type={'shadow'}
                label={i18n._(t`Export csv`)}
                iconLeft={<ExportIcon size={20} />}
                onClick={onExportCSV}
              />
            </Row>

            {/* Filters */}
            <Filters>
              <SearchFieldWrapper
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  onChangeFilters({ search: e.currentTarget.value })
                }
                value={filters.search}
                placeholder={i18n._(t`Search`)}
                style={{
                  borderRadius: '6px',
                  fontSize: '14px',
                  fontWeight: 600,
                }}
              />

              <FiltersMargin>
                <AutocompleteFilter
                  placeholder={<Trans>{`${i18n._(t`Themes`)} | ${i18n._(t`All`)}`}</Trans>}
                  fetch={() => Object.values(selectedEvent?.themes || [])}
                  // @ts-ignore
                  labelProperty={(i: ISurveyTheme) => getMultiLangString(i.name)}
                  labelPropertyReserve={'name'}
                  onChange={(value) => onChangeFilters({ themes: value })}
                  checkedList={filters.themes}
                />
              </FiltersMargin>
            </Filters>

            <ShowSpinnerIfLoading loading={$isItemsLoading.value}>
              {items.map((item, key) => (
                <QuestionWrapper key={key}>
                  <SurveyQuestionReport
                    item={prepareSurveyQuestionReportItem(item, filters.skip + key + 1)}
                  />
                  <SurveyQuestionComments item={item} />
                </QuestionWrapper>
              ))}

              {/* pagination*/}
              {!!size(items) && (
                <PaginationBar
                  pagination={{ index: filters.page, limit: filters.limit, skip: filters.skip }}
                  changePagination={onPageChangeClick}
                  changePageSize={onChangeItemsPerPage}
                  count={total}
                  noTopBorder
                  showCount
                  noBorder
                  noShadow
                />
              )}
            </ShowSpinnerIfLoading>
          </Content>
        </ShowSpinnerIfLoading>
      </WrapperToCenter>
    </Wrapper>
  );
};

export { QuestionsTab };
