import React from 'react';

import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { UserAvatar } from '~/components/UserAvatar';

import { CellWrapper, CellContent } from '../designs';
import { IChildTransformed } from '../types';

import type { IUser } from '@learned/types';

interface IFirstCellTeamProps {
  item: IChildTransformed;
  onClick: (item: IChildTransformed) => void;
}

const FirstCellEmployee = ({ item, onClick }: IFirstCellTeamProps) => {
  return (
    <CellWrapper
      className="cell"
      clickable={!!onClick}
      $margin={'0 0 0 30px'}
      $padding={'0 0 0 20px'}
      onClick={() => {
        onClick ? onClick(item) : {};
      }}
    >
      <Tooltip tooltip={item.name} size={TOOLTIP_SIZES.BIG}>
        <CellContent>
          <UserAvatar
            user={
              {
                firstName: item.name,
                avatarUrl: item.avatarUrl,
              } as unknown as IUser
            }
          />
        </CellContent>
      </Tooltip>
    </CellWrapper>
  );
};

export { FirstCellEmployee };
