import { useForm } from 'react-hook-form';

import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';

import type { UseEditItemModalProps } from './types';
import type { ILanguageValue } from '@learned/types';

export function useEditItemModal({ item, languageState }: UseEditItemModalProps) {
  const formMethods = useForm<{ item: ILanguageValue[] }>({
    mode: 'all',
    defaultValues: {
      item: languageState.defaultLanguages.map(({ locale }) => ({
        locale,
        value: item?.[locale] ?? '',
      })),
    },
  });
  const { unregister, control } = formMethods;

  const itemFieldArray = useMultiLangFieldArray({
    name: 'item',
    control,
    unregister,
    languageState,
  });

  return { itemFieldArray, formMethods };
}
