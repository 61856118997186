import React from 'react';

import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import bookIcon from '~/assets/mdi-book-open-page-variant.svg';

const InstructionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InstructionsIcon = styled(SvgIcon)`
  margin-right: 12px;
`;

const InstructionsLink = styled.a`
  text-decoration: none;
`;

const Instructions = styled.div`
  color: var(--company-color);
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
`;

const ExternalInstructions = ({ instructions, instructionsUrl, className }) => {
  return (
    <InstructionsLink
      className={className}
      href={instructionsUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      <InstructionsContainer>
        <InstructionsIcon
          url={bookIcon}
          width={'20px'}
          height={'20px'}
          isDefaultColor
          defaultColor="var(--company-color)"
        />
        <Instructions>{instructions}</Instructions>
      </InstructionsContainer>
    </InstructionsLink>
  );
};

ExternalInstructions.propTypes = {
  instructions: PropTypes.string.isRequired,
  instructionsUrl: PropTypes.string.isRequired,
};

export default ExternalInstructions;
