import React from 'react';

import type { ISectionState } from '~/components/SideBar/SectionStateHook';

import { InvitationSection } from './components/InvitationSection';
import { TimelineSection } from './components/TimelineSection';
import { StepTimelineContainer } from './design';

import { ILanguageStateReturn } from '~/hooks/useLanguageState';

import type { IReviewCycleForm } from './types';
import type { IReviewInvitationTemplate } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface StepTimelineProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewCycleForm>;
  languageState: ILanguageStateReturn;
  invitationTemplates: Array<IReviewInvitationTemplate>;
  refreshInvitationTemplates: () => void;
}

const StepTimeline = ({
  formMethods,
  sectionState,
  languageState,
  invitationTemplates,
  refreshInvitationTemplates,
}: StepTimelineProps) => {
  return (
    <StepTimelineContainer>
      <InvitationSection
        formMethods={formMethods}
        languageState={languageState}
        invitationTemplates={invitationTemplates}
        refreshInvitationTemplates={refreshInvitationTemplates}
      />
      <TimelineSection
        formMethods={formMethods}
        sectionState={sectionState}
        languageState={languageState}
      />
    </StepTimelineContainer>
  );
};

export { StepTimeline };
