import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';

import usersIcon from '~/assets/a-users-icn.svg';

import { COLOR_PALETTE } from '~/styles';

import Placeholder from '../Placeholder';
import SvgIcon from '../SvgIcon';

function PlaceholderTab({ i18n }) {
  return (
    <Placeholder
      Icon={() => (
        <SvgIcon
          url={usersIcon}
          width={'50px'}
          height={'50px'}
          isDefaultColor
          defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
        />
      )}
      title={i18n._(t`No API available`)}
      subTitle={i18n._(
        t`Integrate Learned with your HR systems to easily import your employees. Contact your customer success manager.`,
      )}
    />
  );
}

export default withI18n()(PlaceholderTab);
