import React from 'react';

import styled from 'styled-components';

import type { IReviewDashboardCycleForm } from '~/pages/Reviews/DashboardCycle/ReviewDashboardForm/types';

import { PerTeamSection } from './PerTeamSection';
import { TotalSection } from './TotalSection';

import type { UseFormReturn } from 'react-hook-form';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface IParticipationProps {
  formMethods: UseFormReturn<IReviewDashboardCycleForm>;
}

const ParticipationTab = ({ formMethods }: IParticipationProps) => {
  const [isExpandMode, setIsExpandMode] = React.useState(false);

  const onToggleExpandMode = () => setIsExpandMode(!isExpandMode);

  return (
    <Wrapper>
      <TotalSection isExpandMode={isExpandMode} />
      <PerTeamSection
        isExpandMode={isExpandMode}
        onToggleExpandMode={onToggleExpandMode}
        formMethods={formMethods}
      />
    </Wrapper>
  );
};

export { ParticipationTab };
