import React from 'react';

import { useLingui } from '@lingui/react';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import chevronIcon from '~/assets/ic-arrow-drop-down-down.svg';

import { COLORS, COLOR_PALETTE } from '~/styles';

import SvgIcon from '../SvgIcon';

const SelectDropdownIcon = styled(SvgIcon)`
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 16px;
  pointer-events: none;
`;

const SelectContainer = styled.div`
  position: relative;
  ${(props) =>
    props.$width && {
      width: props.$width,
    }}
`;

const SelectElement = styled.select`
  height: ${(props) => (props.height ? props.height : '49px')};

  padding-left: 10px;
  padding-right: 36px;
  padding-top: 3px;
  padding-bottom: 3px;

  border-radius: 6px;
  background-color: ${COLOR_PALETTE.WHITE};
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  border: solid 1px ${(props) => (props.error ? COLORS.ERROR : '#ccced5')};
  width: 100%;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  appearance: none;
  color: ${(props) => (props.disabled ? '#999' : COLOR_PALETTE.DARK_GRAY)};
  outline: none;
`;

const Option = styled.option`
  padding: 10px;

  ${(props) =>
    props.isHide && {
      display: 'none',
    }};
`;

const ColorHint = styled.span`
  position: absolute;
  left: -6px;
  width: 12px;
  height: 100%;
`;

const Select = ({
  options,
  type,
  value,
  disabled,
  emptyOption,
  onBlur,
  name,
  height,
  fontSize,
  width,
  error,
  onChange,
}) => {
  const { i18n } = useLingui();
  return (
    <SelectContainer $width={width}>
      <SelectElement
        onChange={(e) => onChange(e.target.value)}
        value={value}
        disabled={disabled}
        onBlur={onBlur}
        name={name}
        height={height}
        fontSize={fontSize}
        error={error}
      >
        {emptyOption && <Option value="">-</Option>}
        {options &&
          map(options, (option, index) => {
            return (
              <Option key={index} value={option.value}>
                {typeof option.name === 'function' ? option.name(i18n) : option.name}
              </Option>
            );
          })}
      </SelectElement>
      {type && type === 'color' && <ColorHint style={{ backgroundColor: value }} />}
      <SelectDropdownIcon url={chevronIcon} isDefaultColor={disabled} width="11px" height="8px" />
    </SelectContainer>
  );
};

const optionShape = PropTypes.shape({
  name: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

Select.propTypes = {
  label: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.arrayOf(optionShape), PropTypes.objectOf(optionShape)]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default React.memo(Select);
