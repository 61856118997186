import React, { ReactNode, useEffect, useState } from 'react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';

import { ButtonWrap } from './design';
import { Dropdown } from './Dropdown';

import type { IOption } from './Dropdown';

export interface IDropdownButtonNewProps {
  buttonType?: ButtonVariant;
  buttonLabel: ReactNode;
  items: IOption[];
  hideDropdown?: boolean;
}

/**
 * rename to DropdownButton, when old DropdownButton will be deleted.
 */
const DropdownButtonNew = ({
  buttonType,
  buttonLabel,
  items,
  hideDropdown,
}: IDropdownButtonNewProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => setShowDropdown((prev) => !prev);

  useEffect(() => {
    if (hideDropdown) {
      setShowDropdown(false);
    }
  }, [hideDropdown]);

  return (
    <Dropdown items={items} showDropdown={showDropdown} hideDropdown={() => setShowDropdown(false)}>
      <ButtonWrap>
        <Button
          variant={buttonType || ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          label={buttonLabel}
          onClick={toggleDropdown}
        />
      </ButtonWrap>
    </Dropdown>
  );
};

export { DropdownButtonNew };
