import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { isEmpty } from 'lodash';

import PDFLayout from '~/layouts/PDFLayout';
import { PassportHeader } from '~/pages/PassportPage/components/PassportHeader';
import ProfileTab from '~/pages/PassportPage/tabs/ProfileTab';

import useBoolState from '~/hooks/useBoolState';
import { getCompanyOutside } from '~/services/companies';
import { getCvSettingsByUserId } from '~/services/cvSettings';
import { getUserById } from '~/services/users';

const UserProfileCvPDF = ({ match, location }) => {
  const userId = match.params.userId;
  const urlParams = new URLSearchParams(location.search);
  const companyId = urlParams.get('companyId');
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState(null);
  const [cvSettings, setCvSettings] = useState(null);

  const $isSkillsLoading = useBoolState(true);
  const $loading = useBoolState(true);

  const fetchData = async () => {
    const [userData, companyData, cvSettingsData] = await Promise.all([
      getUserById(userId),
      getCompanyOutside(companyId),
      getCvSettingsByUserId(userId),
    ]);
    setUser(userData);
    setCompany(companyData);
    setCvSettings(cvSettingsData.data[API_RETURN_FIELDS.CV_SETTINGS]);
    $loading.off();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PDFLayout loading={$loading.value} isChildrenLoading={$isSkillsLoading.value}>
      {!isEmpty(user) && !isEmpty(cvSettings) && (
        <>
          <PassportHeader
            user={user}
            companyLogo={company?.logoUrl}
            isPDF
            cvSettings={cvSettings}
          />
          <ProfileTab
            user={user}
            readOnly
            isPDF
            cvSettings={cvSettings}
            finishSkillsLoading={() => $isSkillsLoading.off()}
          />
        </>
      )}
    </PDFLayout>
  );
};

export { UserProfileCvPDF };
