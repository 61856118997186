import React from 'react';

import { Trans } from '@lingui/macro';

import { Container, Subtitle, Text } from './OutsideDeclined.design';

function OutsideDeclined() {
  return (
    <Container>
      <Text>
        <Trans>The request for input is declined</Trans>
      </Text>
      <Subtitle>
        <Trans>
          You have declined the request to give input. You can close the browser window.
        </Trans>
      </Subtitle>
    </Container>
  );
}

export { OutsideDeclined };
