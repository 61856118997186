import styled from 'styled-components';

const Wrapper = styled.div<{ expanded?: boolean }>`
  margin: 0 auto;
  border-radius: 6px;
`;

const JobColumn = styled.div`
  padding: 0 5px;
  max-width: 10ch;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`;

export { Wrapper, JobColumn };
