import styled from 'styled-components';

import Select from '~/components/Select';

export const LoginMethodSelect = styled(Select)`
  align-self: center;
`;

export const SelectWrapper = styled.div`
  padding: 8px;
`;

export const ButtonWrapper = styled.div`
  padding-right: 8px;
`;

export const BlockRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 250px;
  column-gap: 16px;
  width: 100%;
  align-items: center;
`;

export const BlockHeaderRow = styled(BlockRow)``;

export const HeaderRowLabel = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  text-transform: uppercase;
`;

export const RowName = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  white-space: nowrap;
`;

export const RowDescription = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
`;

export const RowAction = styled.div`
  display: flex;
  justify-content: flex-end;
`;
