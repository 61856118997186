import { useState } from 'react';

import nanoid from 'nanoid';

import type { Item, UseAddMultipleItemsProps } from './types';
import type { IMultiLangString } from '@learned/types';

export function useAddMultipleItems<T extends object>({
  field,
  formMethods,
  languageState,
}: UseAddMultipleItemsProps<T>) {
  const [item, setItem] = useState<string>('');
  const [editItem, setEditItem] = useState<string>('');
  const { getValues, reset } = formMethods;
  const editItemForm = (formMethods.getValues(field) as unknown as Array<Item>)?.find(
    (alias) => alias.id === editItem,
  );

  const addItem = () => {
    if (!item) {
      return;
    }

    const itemArray: Item[] = getValues(field) || [];
    itemArray.push({ [languageState.primaryLanguage.locale]: item, id: nanoid() });
    reset({
      ...getValues(),
      [field]: itemArray,
    });
    setItem('');
  };

  const updateItem = (newItems: IMultiLangString & Item) => {
    const itemArray: Item[] = getValues(field) || [];
    const itemIndex = itemArray.findIndex((item) => item.id === newItems.id);
    itemArray[itemIndex] = newItems;
    reset({
      ...getValues(),
      [field]: itemArray,
    });
    setEditItem('');
  };

  const deleteItem = (id: string) => {
    const itemArray: Item[] = getValues(field) || [];
    const itemIndex = itemArray.findIndex((item) => item.id === id);
    itemArray.splice(itemIndex, 1);
    reset({
      ...getValues(),
      [field]: itemArray,
    });
    setEditItem('');
  };

  return { item, setItem, editItem, setEditItem, editItemForm, addItem, updateItem, deleteItem };
}
