import moment from 'moment';

import { CONVERSATION_REPEAT_TYPES } from '~/constants';

export default ({ currentUser, role, preSelectParticipants = [] }) => {
  // set default start/end date
  const { startDate, endDate } = getDefaultDates();

  // default repeat
  const repeat = {
    type: CONVERSATION_REPEAT_TYPES.NO_REPEAT,
    every: null,
    until: null,
    deadlines: [{ startDate, endDate }],
  };

  return {
    participants: [currentUser.id, ...preSelectParticipants],
    createdInRole: role,
    startDate,
    endDate,
    repeat,
  };
};

const getDefaultDates = () => {
  const currentTime = new Date();
  const startDate = new Date(
    currentTime.getFullYear(),
    currentTime.getMonth(),
    currentTime.getDate(),
    currentTime.getHours() + 1,
  );
  const endDate = moment(startDate).add(30, 'minutes').toDate();
  return { startDate, endDate };
};
