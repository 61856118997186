import React from 'react';

import { ACTIVITY_STATUSES } from '@learned/constants';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  REVIEW_STATUSES,
  REQUEST_STATUSES,
  CONNECTION_STATUSES,
  CONVERSATION_STATUSES,
} from '~/constants';
import { COLOR_SET } from '~/styles';

const ReviewLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: ${(props) => props.$labelColor};
`;

const ReviewWrapper = styled.div`
  display: flex;
  width: 95px;
  height: 28px;
`;

const colorScheme = {
  todo: {
    color: COLOR_SET.BLUE,
    label: t`To Do`,
  },
  draft: {
    color: COLOR_SET.BLUE,
    label: t`Draft`,
  },
  inProgress: {
    color: COLOR_SET.BLUE,
    label: t`In progress`,
  },
  active: {
    color: COLOR_SET.BLUE,
    label: t`Active`,
  },
  done: {
    color: COLOR_SET.GREEN,
    label: t`Completed`,
  },
  problem: {
    color: COLOR_SET.DARK_RED,
    label: t`Problem`,
  },
  expired: {
    color: COLOR_SET.DARK_GRAY2,
    label: t`Expired`,
  },
  archived: {
    color: COLOR_SET.DARK_GRAY2,
    label: t`Archived`,
  },
};

const TYPES = {
  activity: 'activity',
  review: 'review',
  reviewRequest: 'review-request',
};

export const STATUSES = {
  ARCHIVED: {
    key: 'archived',
    name: (i18n) => i18n._(t`Archived`),
  },
};
const StatusIcon = ({ status, i18n, type, className, noBg = false, label: customLabel }) => {
  let styles;

  switch (type) {
    case TYPES.activity: {
      const ITEMS = {
        [ACTIVITY_STATUSES.ON_TRACK]: colorScheme.todo,
        [ACTIVITY_STATUSES.COMPLETED]: colorScheme.todo,
        [ACTIVITY_STATUSES.TODO]: colorScheme.todo,
        [ACTIVITY_STATUSES.IN_PROGRESS]: colorScheme.inProgress,
      };
      styles = ITEMS[status];
      break;
    }
    case TYPES.review: {
      const ITEMS = {
        [REVIEW_STATUSES.DRAFT.key]: colorScheme.draft,
        [REVIEW_STATUSES.ACTIVE.key]: colorScheme.active,
        [REVIEW_STATUSES.DONE.key]: colorScheme.done,
      };
      styles = ITEMS[status];
      break;
    }
    // only for review request,
    // because ACCEPTED status in review request, should not be done.
    case TYPES.reviewRequest: {
      const ITEMS = {
        [REQUEST_STATUSES.REQUESTED.key]: colorScheme.todo,
        [REQUEST_STATUSES.STARTED.key]: {
          ...colorScheme.active,
          label: i18n._(REQUEST_STATUSES.STARTED.label),
        },
        [REQUEST_STATUSES.ACCEPTED.key]: {
          ...colorScheme.active,
          label: i18n._(REQUEST_STATUSES.STARTED.label),
        },
        [REQUEST_STATUSES.CANCELLED.key]: {
          ...colorScheme.problem,
          label: i18n._(REQUEST_STATUSES.CANCELLED.label),
        },
        [REQUEST_STATUSES.REJECTED.key]: {
          ...colorScheme.problem,
          label: i18n._(REQUEST_STATUSES.REJECTED.label),
        },
        [REQUEST_STATUSES.EXPIRED.key]: colorScheme.expired,
        [REQUEST_STATUSES.SHARED.key]: colorScheme.done,
      };
      styles = ITEMS[status];
      break;
    }
    default: {
      const ITEMS = {
        [REVIEW_STATUSES.DRAFT.key]: colorScheme.draft,
        [REVIEW_STATUSES.ACTIVE.key]: colorScheme.active,
        [REVIEW_STATUSES.DONE.key]: colorScheme.done,
        [ACTIVITY_STATUSES.ON_TRACK]: colorScheme.todo,
        [REQUEST_STATUSES.REQUESTED.key]: {
          ...colorScheme.todo,
          label: i18n._(REQUEST_STATUSES.REQUESTED.label),
        },
        [REQUEST_STATUSES.CANCELLED.key]: {
          ...colorScheme.problem,
          label: i18n._(REQUEST_STATUSES.CANCELLED.label),
        },
        [REQUEST_STATUSES.EXPIRED.key]: colorScheme.expired,
        [REQUEST_STATUSES.ACCEPTED.key]: colorScheme.done,
        [REQUEST_STATUSES.REJECTED.key]: {
          ...colorScheme.problem,
          label: i18n._(REQUEST_STATUSES.REJECTED.label),
        },
        [REQUEST_STATUSES.STARTED.key]: {
          ...colorScheme.active,
          label: i18n._(REQUEST_STATUSES.STARTED.label),
        },
        [REQUEST_STATUSES.SHARED.key]: colorScheme.done,
        [CONNECTION_STATUSES.ACTIVE.key]: {
          ...colorScheme.done,
          label: CONNECTION_STATUSES.ACTIVE.name(i18n),
        },
        [CONNECTION_STATUSES.WAITING.key]: {
          ...colorScheme.todo,
          label: CONNECTION_STATUSES.WAITING.name(i18n),
        },
        [CONNECTION_STATUSES.INACTIVE.key]: {
          ...colorScheme.problem,
          label: CONNECTION_STATUSES.INACTIVE.name(i18n),
        },
        [CONVERSATION_STATUSES.OVERDUE.key]: {
          ...colorScheme.problem,
          label: CONVERSATION_STATUSES.OVERDUE.name(i18n),
        },
        [CONVERSATION_STATUSES.DONE.key]: colorScheme.done,
        [CONVERSATION_STATUSES.DONE_ADMIN.key]: colorScheme.done,
        [CONVERSATION_STATUSES.TODO.key]: colorScheme.todo,
        [CONVERSATION_STATUSES.EXPIRED.key]: colorScheme.expired,
        [STATUSES.ARCHIVED.key]: colorScheme.archived,
      };
      styles = ITEMS[status];
    }
  }

  if (!status || !styles) {
    return null;
  }
  const label = (
    <ReviewLabel $labelColor={styles.color}>
      {customLabel || (typeof styles.label === 'string' ? styles.label : i18n._(styles.label))}
    </ReviewLabel>
  );
  return noBg ? (
    <div className={className}>{label}</div>
  ) : (
    <ReviewWrapper className={className}>{label}</ReviewWrapper>
  );
};

const memoStatusIcon = React.memo(withI18n()(StatusIcon));

memoStatusIcon.types = TYPES;
memoStatusIcon.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.oneOf(values(TYPES)),
};

export default memoStatusIcon;
