import React, { useState } from 'react';

import { CONFIRMATION_MODAL_TYPE, ProductName, ProductStatus } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import Label from '~/components/Label';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { ProductSettingsModal } from '~/components/Modals/ProductSettingsModal';
import { useToasts, TOAST_TYPES } from '~/components/Toast';

import {
  BlockWrapper,
  BlockHead,
  ProductSwitch,
  ProductHeading,
  SettingsBlock,
  ButtonLabel,
  SettingsButton,
  BlockBody,
  ProductDescription,
  SubscriptionInfo,
  SubscriptionStatus,
  StatusChangedDay,
  SubscriptionUpgrade,
  MoreInfoLink,
  OnStatus,
  OffStatus,
  TrailDaysToEnd,
  BlackText,
  GreyText,
  RedText,
} from './design';

import { getCurrentCompanyId } from '~/selectors/baseGetters';
import { productsUpdate } from '~/services/companies';
import { updateCompanyProductsToReduxStore } from '~/store/products/actions';
import { COLORS } from '~/styles';

import type { IProducts } from '@learned/types';

interface ProductProps {
  key: ProductName;
  name: string;
  status: ProductStatus;
  lastStatusChanged: string;
  daysToEnd: number;
  description: string;
  moreInfoURL: string;
  // @ts-ignore
  settings: IProducts[keyof IProducts]['settings'];
  upgradeRequest?: {
    message?: string | null;
    dateTime: string;
  };
  isDisabled: boolean;
}

interface ComponentProps {
  product: ProductProps;
  onRequestUpgradeClick: () => void;
}

const ProductBlock = ({ product, onRequestUpgradeClick }: ComponentProps) => {
  const { i18n } = useLingui();
  const { key, name, status, lastStatusChanged, daysToEnd, description, moreInfoURL, isDisabled } =
    product;
  const [productStatus, setProductStatus] = useState(status);
  const [isProductSettingsModalVisible, setIsProductSettingsModalVisible] = useState(false);
  const { addToast } = useToasts();
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const currentCompanyId = useSelector(getCurrentCompanyId);
  const dispatch = useDispatch();

  const handleChangeProductStatus = async () => {
    try {
      if (key === ProductName.JOB_MATRIX) {
        const newStatus =
          productStatus === ProductStatus.INACTIVE ? ProductStatus.ACTIVE : ProductStatus.INACTIVE;

        // update local status
        setProductStatus(newStatus);

        // update status in DB
        const response = await productsUpdate({
          companyId: currentCompanyId,
          products: {
            [ProductName.JOB_MATRIX]: {
              status: newStatus,
            },
          },
        });
        if (response.data) {
          dispatch(updateCompanyProductsToReduxStore(currentCompanyId));
          addToast({
            title: i18n._(t`Success`),
            subtitle: i18n._(t`Job matrix product updated`),
            type: TOAST_TYPES.SUCCESS,
          });
        }
      }
    } catch (error) {
      console.error(error);
      setProductStatus(product.status);
    }
  };

  const onActivateTrial = async () => {
    try {
      await productsUpdate({
        products: { [key]: { status: ProductStatus.TRIAL_ACTIVE } },
        companyId: currentCompanyId,
      });
      dispatch(updateCompanyProductsToReduxStore(currentCompanyId));
      setProductStatus(ProductStatus.TRIAL_ACTIVE);
      addToast({
        title: i18n._(t`Success`),
        subtitle: i18n._(t`Trial period activated`),
        type: TOAST_TYPES.SUCCESS,
      });
    } catch (err) {
      addToast({
        title: i18n._(t`Error`),
        subtitle: i18n._(t`Due to an internal error, trial period didn't activate`),
        type: TOAST_TYPES.ERROR,
      });
    }
  };

  return (
    <>
      <BlockWrapper>
        <BlockHead>
          {key === ProductName.JOB_MATRIX ? (
            <ProductSwitch
              checked={productStatus === ProductStatus.ACTIVE}
              onChange={() => {
                if (productStatus === ProductStatus.ACTIVE) {
                  setIsConfirmationModalVisible(true);
                } else {
                  handleChangeProductStatus();
                }
              }}
              disabled={isDisabled}
              tooltip={
                isDisabled &&
                i18n._(t`Job matrix can only be disabled when the career product is disabled!`)
              }
            />
          ) : [ProductStatus.ACTIVE, ProductStatus.TRIAL_ACTIVE].includes(productStatus) ? (
            <OnStatus>
              <Trans>ON</Trans>
            </OnStatus>
          ) : (
            <OffStatus>
              <Trans>OFF</Trans>
            </OffStatus>
          )}
          <ProductHeading status={status}>{name}</ProductHeading>
          {[ProductStatus.ACTIVE, ProductStatus.TRIAL_ACTIVE].includes(productStatus) &&
          !isEmpty(product.settings) ? (
            <SettingsBlock>
              <ButtonLabel>
                <Trans>Settings</Trans>
              </ButtonLabel>
              {/* @ts-ignore */}
              <SettingsButton
                icon={ICONS.SETTINGS}
                variant={ButtonVariant.ICON}
                size={ButtonSize.MEDIUM}
                onClick={() => setIsProductSettingsModalVisible(true)}
              />
            </SettingsBlock>
          ) : (
            <></>
          )}
        </BlockHead>
        <BlockBody>
          <ProductDescription>{description}</ProductDescription>
          {key === ProductName.INTEGRATIONS ? (
            <SubscriptionInfo />
          ) : (
            <SubscriptionInfo>
              {productStatus === ProductStatus.ACTIVE ? (
                <>
                  <SubscriptionStatus>
                    <Trans>Activated since</Trans>
                  </SubscriptionStatus>
                  <StatusChangedDay>
                    {moment(lastStatusChanged).format('DD-MM-YYYY')}
                  </StatusChangedDay>
                </>
              ) : productStatus === ProductStatus.INACTIVE ? (
                <>
                  <SubscriptionStatus>
                    <Trans>Deactivated since</Trans>
                  </SubscriptionStatus>
                  <StatusChangedDay>
                    {moment(lastStatusChanged).format('DD-MM-YYYY')}
                  </StatusChangedDay>
                </>
              ) : productStatus === ProductStatus.TRIAL_ACTIVE ? (
                <>
                  <TrailDaysToEnd>
                    {daysToEnd < 0 ? 0 : daysToEnd} <Trans>days</Trans>
                  </TrailDaysToEnd>
                  <ButtonLabel>
                    <Trans>left in trial</Trans>
                  </ButtonLabel>
                </>
              ) : productStatus === ProductStatus.TRIAL_NOT_STARTED ? (
                <>
                  <Button
                    variant={ButtonVariant.SECONDARY}
                    label={i18n._(t`Start trial`)}
                    size={ButtonSize.MEDIUM}
                    onClick={onActivateTrial}
                  />
                  <BlackText>
                    <Trans>14 days</Trans>
                  </BlackText>
                  <GreyText>
                    <Trans>free trial</Trans>
                  </GreyText>
                </>
              ) : (
                <>
                  <RedText>
                    <Trans>0 days</Trans>
                  </RedText>
                  <ButtonLabel>
                    <Trans>left in trial</Trans>
                  </ButtonLabel>
                </>
              )}
            </SubscriptionInfo>
          )}
          <SubscriptionUpgrade>
            {key === ProductName.JOB_MATRIX ? (
              <Label backgroundColor={COLORS.INFO_LIGHT} color={COLORS.COMPANY}>
                <Trans>Free</Trans>
              </Label>
            ) : /* we display request upgrade, even if one already were sent (CS/Sales wants to see all requests) */
            productStatus !== ProductStatus.ACTIVE ? (
              <Button
                variant={ButtonVariant.PRIMARY}
                label={i18n._(t`Request upgrade`)}
                size={ButtonSize.MEDIUM}
                onClick={onRequestUpgradeClick}
              />
            ) : (
              <></>
            )}

            <MoreInfoLink href={moreInfoURL} target="_blank">
              <Trans>More info</Trans>
            </MoreInfoLink>
          </SubscriptionUpgrade>
        </BlockBody>
      </BlockWrapper>
      {isConfirmationModalVisible && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.WARNING}
          title={i18n._(t`Turn product off for everybody?`)}
          description={i18n._(
            t`Are your sure you want to turn off this product? This has effect on all your members.`,
          )}
          onClose={() => setIsConfirmationModalVisible(false)}
          onSubmit={handleChangeProductStatus}
          cancelButtonTextColor={COLORS.TEXT_MAIN}
        />
      )}
      {isProductSettingsModalVisible && (
        <ProductSettingsModal
          name={product.key}
          closeModal={() => setIsProductSettingsModalVisible(false)}
        />
      )}
    </>
  );
};

export { ProductBlock };
