import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getRoleRelevancies(
  ids,
  filters = {
    search: '',
  },
) {
  return cloudRequest(serverRoutes.roleRelevancies.getRoleRelevancies, filters, { ids });
}

export function getRoleRelevancy(id) {
  return cloudRequest(serverRoutes.roleRelevancies.getRoleRelevancy(id), null);
}
