import React from 'react';

import { ACTIVITY_STATUSES } from '@learned/constants';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { ActivityProgressCard } from '~/components/GoalDashboardCard/components/GoalsProgressCard/ActivityProgressCard';
import { FieldGroup } from '~/components/Text';

import { COLORS } from '~/styles';

import type { IGoalsUpdate, IActivity } from '@learned/types';

interface IProgressProps {
  activity: IActivity;
  onChange: (data: { progress?: number; status?: ACTIVITY_STATUSES }) => void;
  updateCommentText: (e: string, updateId: string) => void;
  updateCommentFunction: (updateId: string) => void;
  onUpdateGoalsUpdate: (updateId: string) => void;
  onCommentChange: (comment: string) => void;
  onSaveActivity?: (data: {
    progress?: number;
    status?: ACTIVITY_STATUSES;
    comment?: string;
  }) => void;
  comment: string;
  isDisabled?: boolean;
  goalsUpdates?: IGoalsUpdate[];
  updateComments?: string[];
  isProgressEditable?: boolean;
  onChangeActivityProgress?: (data: IActivity) => void;
  updateActivity: (activityId: string, data: IActivity) => void;
}

const Title = styled.h3`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  margin: 7px 0px 21px 0px;
  color: ${COLORS.TEXT_HOVER};
`;

const Content = styled.div``;

const Progress = ({
  activity,
  updateActivity,
  onChangeActivityProgress,
  isProgressEditable = false,
}: IProgressProps) => {
  return (
    /* @ts-ignore */
    <FieldGroup marginTop="59px">
      <Title>
        <Trans>My Progress</Trans>
      </Title>

      <Content>
        <ActivityProgressCard
          activityId={activity.id}
          onClose={() => {}}
          isLibrary={true}
          updateActivity={updateActivity}
          isProgressEditable={isProgressEditable}
          onChangeActivityProgress={onChangeActivityProgress}
        />
      </Content>
    </FieldGroup>
  );
};

export { Progress };
