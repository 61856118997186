import { serverRoutes } from '~/server_config';
import cloudRequest, { makeSingleRequest } from '~/services/cloudRequest';

export function getTeam(id) {
  return cloudRequest(serverRoutes.teams.getTeam(id));
}

export function getTeamsByIds(ids) {
  return cloudRequest(serverRoutes.teams.getTeamsByIds, {}, { ids });
}

export function getTeams({ search, limit = 0, isPermissionsCheck = false } = {}) {
  return cloudRequest(serverRoutes.teams.getTeams, {
    search,
    limit,
    ...(isPermissionsCheck && { isPermissionsCheck }),
  });
}

export function getUserTeams(userId, { isCoach, isMember, isRequested } = {}) {
  return cloudRequest(serverRoutes.teams.getUserTeams(userId), { isCoach, isMember, isRequested });
}

/* teams param - array of teams
  example: [team1, team2]
 */
export function createTeams(teams) {
  return cloudRequest(serverRoutes.teams.createTeams, {}, teams);
}

/*
 * teamIds example ['teamId1', 'teamId2', ...]
 * */
export function deleteTeams(teamIds = []) {
  return makeSingleRequest(serverRoutes.teams.deleteTeams, {}, teamIds);
}

/*
 * data example [[Team1Id, updateTeam1Data{}], [Team2Id, updateTeam2Data{}], ...]
 * */
export function updateTeams(data) {
  return cloudRequest(serverRoutes.teams.updateTeams, {}, data);
}

export function updateTeamName(id, name) {
  return cloudRequest(serverRoutes.teams.updateTeam(id), {}, { name });
}

export function removeCoachFromTeam(coach, team, { sendRequestAsAdmin = false } = {}) {
  return makeSingleRequest(
    serverRoutes.teams.removeCoachFromTeam(team, coach),
    undefined,
    undefined,
    { forceRole: sendRequestAsAdmin ? 'admin' : undefined },
  );
}

export function removeMemberFromTeam(member, team, { sendRequestAsAdmin = false } = {}) {
  return makeSingleRequest(
    serverRoutes.teams.removeMemberFromTeam(team, member),
    undefined,
    undefined,
    { forceRole: sendRequestAsAdmin ? 'admin' : undefined },
  );
}

export function addMembersToTeam(
  users,
  team,
  isPermissionsCheck = true,
  { sendRequestAsAdmin = false } = {},
) {
  return cloudRequest(
    serverRoutes.teams.addMembersToTeam(team),
    { isPermissionsCheck },
    { users },
    { forceRole: sendRequestAsAdmin ? 'admin' : undefined },
  );
}

export function addCoachesToTeam(users, team, { sendRequestAsAdmin = false } = {}) {
  return cloudRequest(
    serverRoutes.teams.addCoachesToTeam(team),
    {},
    { users },
    { forceRole: sendRequestAsAdmin ? 'admin' : undefined },
  );
}

export function inviteMembersToTeam(users, team) {
  return cloudRequest(serverRoutes.teams.inviteMembersToTeam(team), {}, { users });
}
