import React from 'react';

import { ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { Placeholder } from '~/pages/CoachMembersPage/components/MemberRow';
import { getDaysSinceConversation } from '~/pages/CoachMembersPage/components/utils';

import routes from '~/constants/routes';
import { COLOR_SET } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const LastActionWrapper = styled.div`
  padding: 4px 8px;
  border-radius: 3px;
  background: ${(props) => props.$color};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  min-width: 98px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

// Options
// last conversation > 30d: Problem
// last conversation < 30d: Good
const Last1on1 = ({ userId, lastConversation }) => {
  const { i18n } = useLingui();
  const history = useHistory();
  const path = routes.USER_PUBLIC_SKILL_PASSPORT.build(
    { role: ROLES.COACH },
    { userId, isBackPath: true, hash: 'meetings' },
  );
  const days = 30;

  const result = getDaysSinceConversation(lastConversation);
  const isProblem = result.days > days;
  const label =
    result.days > days
      ? i18n._(t`Last: >${days}d`)
      : result.days === 0
      ? i18n._(t`Last: today`)
      : i18n._(t`Last: ${result.days}d`);

  return (
    <Wrapper onClick={() => history.push(path)}>
      {!result ? (
        <Placeholder>{i18n._(t`No 1-1`)}</Placeholder>
      ) : (
        <LastActionWrapper
          onClick={() => history.push(path)}
          $color={isProblem ? '#ffc9c9' : COLOR_SET.CYAN_LIGHT}
        >
          {label}
        </LastActionWrapper>
      )}
    </Wrapper>
  );
};

export default React.memo(Last1on1);
