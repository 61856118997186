import React from 'react';

import { t } from '@lingui/macro';

import { StatusLabel } from './StatusLabel';

import { FlexRow, IconImage, IconPlaceholder, NameRow, TD } from '../design';
import { IIntegration } from '../types';

import type { I18n } from '@lingui/core';

const getIntegrationOverviewColumns = (isLoading: boolean) => {
  return [
    {
      name: (i18n: I18n) => i18n._(t`Name`),
      accessor: 'name',
      maxWidth: '80%',
      renderCell: (item: IIntegration) => {
        return (
          <NameRow>
            <FlexRow>
              {item?.komboData?.icon ? (
                <IconImage src={item.komboData.icon} />
              ) : (
                <IconPlaceholder />
              )}
              <span>{item.name as string}</span>
            </FlexRow>
          </NameRow>
        );
      },
    },
    {
      name: (i18n: I18n) => i18n._(t`Status`),
      accessor: 'status',
      maxWidth: '15%',
      renderCell: (item: IIntegration) => {
        return (
          <NameRow>
            <FlexRow>
              <TD>
                <StatusLabel isLoading={isLoading} status={item.status} />
              </TD>
            </FlexRow>
          </NameRow>
        );
      },
    },
  ];
};

export { getIntegrationOverviewColumns };
