import React, { useEffect } from 'react';

import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Icon, ICONS } from '~/components/Icon';

import { SubMenuItem } from './SubMenuItem';

import { SecondaryMenuSubTitle } from '../../styles/design';

import type { IMenuItem } from '../../types';

const Accordion = styled.div``;

const AccordionContentArea = styled.div``;

interface ISecondaryMenuItemProps {
  subMenuItem: IMenuItem;
  pathname: string;
  deleteCustomReport: (id: string, isActiveRoute: boolean) => void;
  duplicateCustomReport: (id: string) => void;
  isDefaultReport: (key: string) => boolean;
  isAdmin: boolean;
  onToggleMenuItem: (itemId: string) => void;
  openedMenuItems: Set<string>;
  onRenameItem: (menuItem: IMenuItem) => void;
}

const SubMenu = ({
  subMenuItem,
  pathname,
  deleteCustomReport,
  duplicateCustomReport,
  isDefaultReport,
  isAdmin,
  onToggleMenuItem,
  openedMenuItems,
  onRenameItem,
}: ISecondaryMenuItemProps) => {
  const { i18n } = useLingui();
  const item = subMenuItem as IMenuItem;
  const isActive = openedMenuItems.has(`${item.key}-${item.role}`);
  const toggleCollapsed = () => {
    onToggleMenuItem(`${item.key}-${item.role}`);
  };

  const getAdminProps = (subSubMenuItem: IMenuItem) => ({
    ...(isAdmin && {
      ...(!isDefaultReport(subSubMenuItem.key) && { onDeleteItem: deleteCustomReport }),
      onDuplicateItem: duplicateCustomReport,
      onRenameItem,
    }),
  });

  useEffect(() => {
    const activePaths = item.children?.map((child) => (child as IMenuItem).url || null);
    if (activePaths?.includes(pathname)) {
      onToggleMenuItem(`${item.key}-${item.role}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasActiveChild = item.children?.some((child) => (child as IMenuItem).url === pathname);

  return (
    <Accordion>
      <SecondaryMenuSubTitle onClick={toggleCollapsed} isActive={!!hasActiveChild}>
        {item.title?.(i18n)}
        <Icon
          icon={ICONS.DROPDOWN}
          width={'12px'}
          className={isActive ? 'collapsed' : 'not-collapsed'}
        />
      </SecondaryMenuSubTitle>
      {isActive && (
        <AccordionContentArea>
          {item.children?.map((subSubMenuItem) => (
            <SubMenuItem
              key={(subSubMenuItem as IMenuItem).key}
              menuItem={subSubMenuItem as IMenuItem}
              pathname={pathname}
              showContextMenu={isAdmin}
              {...getAdminProps(subSubMenuItem as IMenuItem)}
            />
          ))}
        </AccordionContentArea>
      )}
    </Accordion>
  );
};
export { SubMenu };
