import { ROLES, USER_REVIEW_STATUS } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

export interface IMultiSelectOption {
  id: string;
  key: string;
  name: string;
  translated: (i18n: I18n) => string;
}

export const USER_REVIEW_CREATED_BY_OPTIONS: IMultiSelectOption[] = [
  {
    id: ROLES.ADMIN,
    key: ROLES.ADMIN,
    name: 'Admin',
    translated: (i18n: I18n) => i18n._(t`Admin`),
  },
  {
    id: ROLES.COACH,
    key: ROLES.COACH,
    name: 'Coach',
    translated: (i18n: I18n) => i18n._(t`Coach`),
  },
  {
    id: ROLES.USER,
    key: ROLES.USER,
    name: 'Employee',
    translated: (i18n: I18n) => i18n._(t`Employee`),
  },
];

export const USER_REVIEW_STATUS_OPTIONS: IMultiSelectOption[] = [
  {
    id: USER_REVIEW_STATUS.DRAFT,
    key: USER_REVIEW_STATUS.DRAFT,
    name: 'Draft',
    translated: (i18n: I18n) => i18n._(t`Draft`),
  },
  // Act as Upcoming
  {
    id: USER_REVIEW_STATUS.PUBLISHED,
    key: USER_REVIEW_STATUS.PUBLISHED,
    name: 'Upcoming',
    translated: (i18n: I18n) => i18n._(t`Upcoming`),
  },
  {
    id: USER_REVIEW_STATUS.SIGNING,
    key: USER_REVIEW_STATUS.SIGNING,
    name: 'Signing',
    translated: (i18n: I18n) => i18n._(t`Signing`),
  },
  {
    id: USER_REVIEW_STATUS.ACTIVE,
    key: USER_REVIEW_STATUS.ACTIVE,
    name: 'Active',
    translated: (i18n: I18n) => i18n._(t`Active`),
  },
  {
    id: USER_REVIEW_STATUS.COMPLETED,
    key: USER_REVIEW_STATUS.COMPLETED,
    name: 'Completed',
    translated: (i18n: I18n) => i18n._(t`Completed`),
  },
  {
    id: USER_REVIEW_STATUS.ARCHIVED,
    key: USER_REVIEW_STATUS.ARCHIVED,
    name: 'Archived',
    translated: (i18n: I18n) => i18n._(t`Archived`),
  },
];
