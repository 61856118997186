import React from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Header3 } from '~/components/UI/Typographics/headers';

import ApproveSectionRow from './ApproveSectionRow';

import CompleteIcon from '~/assets/conversation-complete.svg';
import LockIcon from '~/assets/conversation-lock.svg';
import WaitIcon from '~/assets/conversation-wait.svg';

import { ROLES } from '~/constants';
import { getSelectedRole, getUser } from '~/selectors/baseGetters';
import convertToTimeString from '~/utils/convertToTimeString';
// Icons

const Wrapper = styled.div``;

const Content = styled.div`
  padding: 25px;
`;

const ThinText = styled.div`
  width: 100%;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  margin-bottom: 26px;
`;

const ConversationApprove = ({ i18n, conversation, reFetchConversation, isApproveUserWarning }) => {
  const role = useSelector(getSelectedRole);
  const user = useSelector(getUser);

  const renderCoachRow = () => {
    const getCoachProps = () => {
      switch (role) {
        case ROLES.USER: {
          return {
            icon: conversation.isCoachCompleted ? CompleteIcon : WaitIcon,
            label: conversation.isCoachCompleted ? i18n._(t`Completed`) : i18n._(t`Waiting...`),
            description: conversation.isCoachCompleted
              ? i18n._(
                  t`The other participant(s) completed the conversation at ${convertToTimeString(
                    conversation.dateCoachCompleted,
                  )}`,
                )
              : i18n._(t`The other participants has not yet approved the conversation`),
          };
        }
        case ROLES.COACH: {
          return {
            icon: conversation.isCoachCompleted ? CompleteIcon : null,
            label: conversation.isCoachCompleted
              ? i18n._(t`Completed`)
              : i18n._(t`Approve the 1-1`),
            description: conversation.isCoachCompleted
              ? i18n._(
                  t`You completed the conversation at ${convertToTimeString(
                    conversation.dateCoachCompleted,
                  )}`,
                )
              : i18n._(t`Add a final remark and/or complete the 1-1`),
          };
        }
        default: {
          return {
            icon: conversation.isCoachCompleted ? CompleteIcon : null,
            label: conversation.isCoachCompleted
              ? i18n._(t`Completed`)
              : i18n._(t`The other participant(s) needs to approve the 1-1`),
            description: conversation.isCoachCompleted
              ? i18n._(
                  t`The other participant(s) completed the conversation at ${convertToTimeString(
                    conversation.dateCoachCompleted,
                  )}`,
                )
              : i18n._(t`The other participant(s) has not yet approved the conversation`),
          };
        }
      }
    };

    const coachId = conversation.participants[1]; // in approval flow was only one coach
    const isCoachOwner = conversation.participants.includes(user.id) && coachId === user.id;
    const { icon, label, description } = getCoachProps();

    // final comments
    const coachesComments = (conversation.comments || []).filter(
      (c) => !c.source && !c.sourceId && c.author === coachId,
    );

    return (
      <ApproveSectionRow
        conversation={conversation}
        userId={coachId}
        icon={icon}
        label={label}
        description={description}
        isOwner={isCoachOwner}
        isOwnerCompleted={conversation.isCoachCompleted}
        comments={coachesComments}
        reFetchConversation={reFetchConversation}
      />
    );
  };

  const renderUserRow = () => {
    const getUserProps = () => {
      switch (role) {
        case ROLES.USER: {
          return {
            icon: conversation.isUserCompleted
              ? CompleteIcon
              : conversation.isCoachCompleted
              ? null
              : LockIcon,
            label: conversation.isUserCompleted
              ? i18n._(t`Completed`)
              : conversation.isCoachCompleted
              ? i18n._(t`Approve the 1-1`)
              : i18n._(t`Locked`),
            description: conversation.isUserCompleted
              ? i18n._(
                  t`You completed the conversation at ${convertToTimeString(
                    conversation.dateUserCompleted,
                  )}`,
                )
              : conversation.isCoachCompleted
              ? i18n._(t`Add a final remark and/or complete the 1-1`)
              : i18n._(
                  t`You can approve the conversation after the other participant(s) has approved the conversation`,
                ),
          };
        }
        case ROLES.COACH: {
          return {
            icon: conversation.isUserCompleted
              ? CompleteIcon
              : conversation.isCoachCompleted
              ? WaitIcon
              : LockIcon,
            label: conversation.isUserCompleted
              ? i18n._(t`Completed`)
              : conversation.isCoachCompleted
              ? i18n._(t`Waiting...`)
              : i18n._(t`Locked`),
            description: conversation.isUserCompleted
              ? i18n._(
                  t`User completed the conversation at ${convertToTimeString(
                    conversation.dateUserCompleted,
                  )}`,
                )
              : conversation.isCoachCompleted
              ? i18n._(t`User has not yet approved the conversation`)
              : i18n._(t`The employee approve the conversation after you approve the 1-1.`),
          };
        }
        default: {
          return {
            icon: conversation.isUserCompleted
              ? CompleteIcon
              : conversation.isCoachCompleted
              ? WaitIcon
              : LockIcon,
            label: conversation.isUserCompleted
              ? i18n._(t`Completed`)
              : conversation.isCoachCompleted
              ? i18n._(t`Waiting...`)
              : i18n._(t`Locked`),
            description: conversation.isUserCompleted
              ? i18n._(
                  t`User completed the conversation at ${convertToTimeString(
                    conversation.dateUserCompleted,
                  )}`,
                )
              : conversation.isCoachCompleted
              ? i18n._(t`User has not yet approved the conversation`)
              : i18n._(t`The employee approve the conversation after you approve the 1-1.`),
          };
        }
      }
    };

    const createdById = get(conversation, 'createdBy.id', conversation.createdBy);
    const isUserOwner = createdById === user.id && role === ROLES.USER;
    const { icon, label, description } = getUserProps();

    // final comments
    const userComments = (conversation.comments || []).filter(
      (c) => !c.source && !c.sourceId && c.author === createdById,
    );

    return (
      <ApproveSectionRow
        conversation={conversation}
        userId={createdById}
        icon={icon}
        label={label}
        description={description}
        isOwner={isUserOwner}
        isOwnerCompleted={conversation.isUserCompleted}
        comments={userComments}
        reFetchConversation={reFetchConversation}
        isApproveUserWarning={isApproveUserWarning}
      />
    );
  };

  return (
    <Wrapper
      header={
        <Header3>
          <Trans>Approve the conversation</Trans>
        </Header3>
      }
    >
      <Content>
        <ThinText>
          {conversation.createdBy === user.id && role === ROLES.USER
            ? i18n._(
                t`When the other participant(s) has approved the 1-1 you will be asked to do the same.`,
              )
            : i18n._(
                t`Approve the 1-1 to automatically request the employee to approve the conversation.`,
              )}
        </ThinText>
        {renderUserRow()}
        {renderCoachRow()}
      </Content>
    </Wrapper>
  );
};

export default React.memo(withI18n()(ConversationApprove));
