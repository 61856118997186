import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Modal from '~/components/Modal';

import { COLORS } from '~/styles';

interface IAddToPlanModal {
  setIsShowAddedToPlanModal: (value: boolean) => void;
  setIsShowSelectLPModal: (value: boolean) => void;
}

const Button = styled.button`
  width: 188px;
  height: 48px;
  padding: 4px 15px;
  border-radius: 6px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
`;

const AddToPlanButton = styled(Button)`
  background-color: var(--company-color);
  color: ${COLORS.TEXT_WHITE};
`;

const ConnectToGoalButton = styled(Button)`
  color: var(--company-color);
  background-color: ${COLORS.TEXT_WHITE};
  border: 1px solid var(--company-color);
  margin-right: 16px;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -5px;
  padding-bottom: 48px;
`;

const AddToPlanModal = ({ setIsShowAddedToPlanModal, setIsShowSelectLPModal }: IAddToPlanModal) => {
  const { i18n } = useLingui();

  const closeModal = () => setIsShowAddedToPlanModal(false);

  return (
    <Modal
      title={i18n._(t`Successfully added to plan`)}
      greyDescription={i18n._(t`Do you also want to connect this activity to a goal?`)}
      onClose={closeModal}
      centerModal
      width={500}
      hideFooter
      showDivider={false}
    >
      <Content>
        <ConnectToGoalButton onClick={() => setIsShowSelectLPModal(true)}>
          {i18n._(t`Yes, connect to goal`)}
        </ConnectToGoalButton>
        <AddToPlanButton onClick={closeModal}>{i18n._(t`No, just add to plan`)}</AddToPlanButton>
      </Content>
    </Modal>
  );
};

export { AddToPlanModal };
