import React, { ReactNode } from 'react';

import styled from 'styled-components';

import { COLORS } from '~/styles';
import noMultyRun from '~/utils/noMultyRun';

const DEFAULT_COLOR = COLORS.BLUE_DARK;
const DEFAULT_HOVER_COLOR = COLORS.BG_PAGE;

const MAX_PADDING = '5px 28.5px';

const Wrapper = styled.div`
  position: relative;
`;

const SizeDefiner = styled.div<{ $fontSize?: number }>`
  opacity: 0;
  font-size: ${(props) => props.$fontSize || 14}px;
  padding: ${MAX_PADDING};
`;

const Button = styled.button<{
  $color?: string;
  $hoverColor?: string;
  $isDisabled?: boolean;
  $fontSize?: number;
}>`
  background: none;
  color: ${(props) => (props.$isDisabled ? COLORS.INACTIVE : props.$color || DEFAULT_COLOR)};
  border: none;
  cursor: pointer;
  font-size: ${(props) => props.$fontSize || 14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 5px 24.4px 5px 23.9px;
  border-radius: 20px;
  transition: background-color 0.1s, padding 0.3s;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  white-space: nowrap;

  &:hover:enabled {
    padding: ${MAX_PADDING};
    background-color: ${(props) => props.$hoverColor || DEFAULT_HOVER_COLOR};
  }

  span {
    margin-right: 6px;
    display: flex;
    align-items: center;
  }
`;

interface IInlineButton {
  label: string | ReactNode;
  onClick: () => void;
  isDisabled?: boolean;
  color?: string;
  hoverColor?: string;
  fontSize?: number;
  type?: 'button' | 'submit';
  icon?: ReactNode;
  className?: string;
}
/**
 * @deprecated since version 3.0 {@see Button}
 */
export const InlineButton = ({
  label,
  onClick,
  color,
  hoverColor,
  isDisabled = false,
  fontSize,
  type = 'button',
  icon,
  className,
}: IInlineButton) => {
  return (
    <Wrapper>
      {/* We need this to do not change button position on hover (when padding changes)*/}
      <SizeDefiner $fontSize={fontSize}>{label}</SizeDefiner>

      <Button
        type={type}
        $color={color}
        $hoverColor={hoverColor}
        $isDisabled={isDisabled}
        $fontSize={fontSize}
        onClick={onClick === Function ? noMultyRun(onClick) : onClick}
        disabled={isDisabled}
        className={className}
      >
        {icon && <span>{icon}</span>}
        {label}
      </Button>
    </Wrapper>
  );
};
