import FileSaver from 'file-saver';

import { serverRoutes } from '~/server_config';

import cloudRequest from '../cloudRequest';

/* request example */
/*
* getUserConversation(conversationId, {
        populate: ['users'],
        join: ['talkingPoints'],
        isRepeated: true, // join repeated conversations
      }),
* */

/* response example */
/* {
      ...conversation,
      talkingPoints: [],
      comments: [],
      subConversations: [],
}; */
export function getUserConversation(id, { populate = [], join = [], isRepeated = false } = {}) {
  return cloudRequest(serverRoutes.userConversations.getUserConversation, {
    id,
    populate,
    join,
    isRepeated,
  });
}

export function getUserConversations(filters) {
  return cloudRequest(serverRoutes.userConversations.getUserConversations, { ...filters });
}

export function getUserConversationsForGraph(filters) {
  return cloudRequest(serverRoutes.userConversations.getUserConversationsForGraph, { ...filters });
}

export function getUserConversationsDataPerTeam(filters) {
  return cloudRequest(serverRoutes.userConversations.getUserConversationsDataPerTeam, {
    ...filters,
  });
}

export function downloadAdminConversationReportCSV(filters) {
  return cloudRequest(serverRoutes.userConversations.downloadAdminConversationReportCSV, {
    ...filters,
  }).then((response) => {
    FileSaver.saveAs(response, 'company_conversations_report.csv');
  });
}

export function getUsersConversationsByCoach(filters) {
  return cloudRequest(serverRoutes.userConversations.getUsersConversationsByCoach, { ...filters });
}

export function getConversationsAndReviews(filters) {
  return cloudRequest(serverRoutes.userConversations.getConversationsAndReviews, { ...filters });
}

export function getUserPublicConversations({ userId, isJoinDraft, isFilterByCoach, join = [] }) {
  return cloudRequest(serverRoutes.userConversations.getUserPublicConversations, {
    userId,
    isFilterByCoach,
    ...(isJoinDraft && { isJoinDraft }),
    join,
  });
}

export function getUserConversationsByTalkinPointTarget({ target, userId, join = [] } = {}) {
  return cloudRequest(serverRoutes.userConversations.getUserConversationsByTalkinPointTarget, {
    target,
    userId,
    join,
  });
}

export function updateUserTalkingPointsCheck(id, donePoints) {
  return cloudRequest(
    serverRoutes.userConversations.updateUserTalkingPointsCheck,
    { id },
    { donePoints },
  );
}

export function updateUserConversationStatus(id, status) {
  return cloudRequest(
    serverRoutes.userConversations.updateUserConversationStatus,
    { id },
    { status },
  );
}

export function deleteUserConversation(id) {
  return cloudRequest(serverRoutes.userConversations.deleteUserConversation, { id });
}

export function uploadConversationAttachment(file, conversationId, talkingPointId) {
  return cloudRequest(
    serverRoutes.userConversations.uploadConversationAttachment,
    {},
    { name, file, conversation: conversationId, talkingPoint: talkingPointId },
    { sendAsFormData: true },
  );
}

export function deleteConversationAttachment(fileId, conversationId) {
  return cloudRequest(serverRoutes.userConversations.deleteConversationAttachment, {
    file: fileId,
    conversation: conversationId,
  });
}

export function downloadCalendarICS(conversationId) {
  return cloudRequest(serverRoutes.userConversations.downloadCalendarICS, {
    conversation: conversationId,
  }).then((response) => {
    FileSaver.saveAs(response, 'calendar_event.ics');
  });
}

export function updateUserConversationTPOrder(id, data) {
  return cloudRequest(serverRoutes.userConversations.updateUserConversationTPOrder, { id }, data);
}

export function downloadConversationPdf(id, name) {
  return cloudRequest(
    serverRoutes.generatePDF.getConversationPDF(id),
    {},
    {},
    { arrayBuffer: true },
  ).then((response) => {
    if (response) {
      const blob = new Blob([response], { type: 'application/pdf' });
      FileSaver.saveAs(blob, `${name} report.pdf`);
    }
  });
}

export function updateUserConversationDate(
  id,
  startDate,
  endDate,
  updateOption,
  includeLinkMeeting,
) {
  return cloudRequest(
    serverRoutes.userConversations.updateUserConversationDate,
    { id },
    { startDate, endDate, updateOption, includeLinkMeeting },
  );
}
