import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const SvgIconDiv = styled.div`
  width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${(props) => props.companyColor};
  -webkit-mask: url(${(props) => props.url}) no-repeat center / contain;
  mask: url(${(props) => props.url}) no-repeat center / contain;
`;

const baseColor = '#010101';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const SvgIcon = (props) => {
  const { width, height, url, className, classNameIcon, isDefaultColor, defaultColor, styles } =
    props;
  const { i18n } = useLingui();

  let isOldBrowser = false;

  if (navigator && navigator.userAgent) {
    const isEdge17 = navigator.userAgent.search(/Edge\/17/) !== -1;
    if (isEdge17) {
      isOldBrowser = true;
    }
  }

  return isOldBrowser ? (
    <img
      src={url}
      alt={i18n._(t`icon`)}
      style={{
        width: width || '18px',
        minWidth: width || '18px',
        height: height || '18px',
      }}
      className={classNames(classNameIcon, className)}
    />
  ) : (
    <SvgIconDiv
      className={classNames(classNameIcon, className)}
      url={url}
      width={width ? width : '18px'}
      height={height ? height : '18px'}
      companyColor={isDefaultColor ? defaultColor || baseColor : 'var(--company-color)'}
      style={styles}
      {...props}
    />
  );
};

SvgIcon.propTypes = {
  url: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  isDefaultColor: PropTypes.bool,
  defaultColor: PropTypes.string,
  className: PropTypes.string,
  styles: PropTypes.object,
};

SvgIcon.defaultTypes = {
  isDefaultColor: false,
  classNameIcon: '',
  className: '',
  styles: {},
};

export default React.memo(SvgIcon);
