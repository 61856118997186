import { createReducer } from 'redux-act';

import * as invitesActions from './actions';

const INITIAL_STATE = {
  fetchInvites: true,
  invitesCount: 0,
};

export const invites = createReducer(
  {
    [invitesActions.finishSetInvites]: (state, payload) => {
      if (!payload) {
        return { ...INITIAL_STATE };
      }
      return {
        ...state,
        ...payload,
      };
    },
  },
  INITIAL_STATE,
);
