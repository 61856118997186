import React, { useEffect, useState } from 'react';

import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';

import Switch from '~/components/Switch';
import { Row, Block, Title } from '~/pages/CompanySettings/components/CoachSettings.design';

import { getCompanySettings, updateCompanySettings } from '~/services/companySettings';
import * as companySettingsActions from '~/store/companySettings/actions';

const CoachSettings = ({ companyId }: { companyId: string }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!companyId) {
      return;
    }
    setLoading(true);
    let mounted = true;
    const fetch = async () => {
      const response = await getCompanySettings();
      if (!mounted) {
        return;
      }
      setChecked(response?.data?.companySettings?.coaches?.isAllowedToInviteMembers ?? false);
      setLoading(false);
    };
    fetch();

    return () => void (mounted = false);
  }, [companyId]);

  async function onChange(value: boolean) {
    setLoading(true);
    const response = await updateCompanySettings({
      coaches: {
        isAllowedToInviteMembers: value,
      },
    });
    const companySettings = response?.data?.companySettings;
    dispatch(companySettingsActions.setCompanySettings(companySettings));
    setChecked(companySettings?.coaches?.isAllowedToInviteMembers ?? false);
    setLoading(false);
  }

  return (
    <Block>
      <Title>
        <Trans>Coaches</Trans>
      </Title>
      <Row>
        <Switch disabled={loading} onChange={onChange} checked={checked} />
        <Trans>Coaches are allowed/restricted to invite members</Trans>
      </Row>
    </Block>
  );
};

export { CoachSettings };
