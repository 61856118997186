import React, { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '~/components/Button';
import DeleteCompanyModal from '~/components/DeleteCompanyModal';
import SettingBlock from '~/components/ProfileSettingsComponents/MainDashboard/SettingBlock';

import { BlockRow, RowName, RowDescription, RowAction } from '../design';

const DeleteCompany = ({
  isAllowedToDelete,
  loading,
  deleteCompany,
  companyName,
}: {
  isAllowedToDelete: boolean;
  loading: boolean;
  deleteCompany: () => void;
  companyName: string;
}) => {
  const { i18n } = useLingui();
  const [isDeleteCompanyModal, setIsDeleteCompanyModal] = useState(false);

  return (
    <>
      {/* @ts-ignore */}
      <SettingBlock
        title={i18n._(t`Delete company`)}
        subTitle={i18n._(t`Delete your company workspace and the accounts of all your employees.`)}
      >
        <BlockRow>
          <RowName>
            <Trans>Delete</Trans>
          </RowName>
          <RowDescription>
            <Trans>This action cannot be undone</Trans>
          </RowDescription>
          <RowAction>
            <Button
              type="primary-border"
              label={i18n._(t`Delete company`)}
              onClick={() => setIsDeleteCompanyModal(true)}
              disabled={!isAllowedToDelete}
              tooltip={
                !isAllowedToDelete ? i18n._(t`Only the company owner can delete the company`) : null
              }
            />
          </RowAction>
        </BlockRow>
      </SettingBlock>
      {isDeleteCompanyModal && (
        <DeleteCompanyModal
          // @ts-ignore
          onClose={() => setIsDeleteCompanyModal(false)}
          loading={loading}
          onDelete={() => {
            setIsDeleteCompanyModal(false);
            deleteCompany();
          }}
          companyName={companyName}
        />
      )}
    </>
  );
};

export { DeleteCompany };
