import React, { useState } from 'react';

import { QUESTION_TYPES, ROLES } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import QuestionReport from '~/components/ReviewReport/QuestionReport';

import { COLORS } from '~/styles';
import { getReviewQuestionsWithSkills } from '~/utils/helper';

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

const QuestionsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const renderQuestionWithSkill = (question, index, props) => {
  const {
    review,
    isReadOnly,
    hideSelf,
    hidePeers,
    hideCoaches,
    openedQuestions,
    toggleQuestion,
    pdfView,
  } = props;

  return question.skills.map((skill, skillIndex) => (
    <QuestionReport
      key={skill.id}
      review={review}
      question={question}
      skill={skill}
      isReadOnly={isReadOnly}
      type={QUESTION_TYPES.SKILL}
      isExpandedByDefault={!index && !skillIndex}
      hideSelf={hideSelf}
      hidePeers={hidePeers}
      hideCoaches={hideCoaches}
      open={openedQuestions.includes(skill.id)}
      toggleQuestion={toggleQuestion}
      pdfView={pdfView}
    />
  ));
};

const renderQuestion = (question, index, props) => {
  const {
    review,
    isReadOnly,
    hideSelf,
    hidePeers,
    hideCoaches,
    openedQuestions,
    toggleQuestion,
    pdfView,
  } = props;

  return question.skills ? (
    renderQuestionWithSkill(question, index, {
      review,
      hideSelf,
      hidePeers,
      hideCoaches,
      openedQuestions,
      toggleQuestion,
      pdfView,
    })
  ) : (
    <QuestionReport
      key={question.id}
      review={review}
      question={question}
      isReadOnly={isReadOnly}
      type={QUESTION_TYPES.CUSTOM}
      isExpandedByDefault={!index}
      hideSelf={hideSelf}
      hidePeers={hidePeers}
      hideCoaches={hideCoaches}
      open={openedQuestions.includes(question.id)}
      toggleQuestion={toggleQuestion}
      pdfView={pdfView}
    />
  );
};

function ReviewReport({ review, isAdmin, currentRole, i18n, pdfView }) {
  const [openedQuestions, setOpenedQuestions] = useState([0]);
  if (isEmpty(review)) {
    return null;
  }
  const questions = getReviewQuestionsWithSkills(review, isAdmin);

  // create list of all questions + skills (for open all / collapse all)
  const questionsKeys = [];
  questions.forEach((question) => {
    if (question.skills) {
      question.skills.forEach((skill) => questionsKeys.push(skill.id));
    } else {
      questionsKeys.push(question.id);
    }
  });

  const toggleQuestion = (key) => {
    const updatedOpenedQuestions = openedQuestions.includes(key)
      ? openedQuestions.filter((i) => i !== key)
      : [...openedQuestions, key];
    setOpenedQuestions(updatedOpenedQuestions);
  };

  return (
    <>
      <QuestionsHeader>
        <Title>
          <Trans>The report</Trans>
        </Title>
        <div>
          <Button
            type={Button.types.linkSecondary}
            label={i18n._(t`Open all`)}
            styles={{ color: 'var(--company-color)', fontSize: '14px', fontWeight: 'bold' }}
            onClick={() => setOpenedQuestions(questionsKeys)}
          />
          |
          <Button
            type={Button.types.linkSecondary}
            label={i18n._(t`Collapse all`)}
            styles={{ color: 'var(--company-color)', fontSize: '14px', fontWeight: 'bold' }}
            onClick={() => setOpenedQuestions([])}
          />
        </div>
      </QuestionsHeader>
      {questions &&
        questions.map((question, index) => {
          return renderQuestion(question, index, {
            review,
            isReadOnly: pdfView || ROLES.USER !== currentRole,
            hideSelf: !review.isSelfReview,
            hidePeers: !review.isUsersReview,
            hideCoaches: !review.isCoachesReview,
            openedQuestions,
            toggleQuestion,
            pdfView,
          });
        })}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    currentRole: state.selected.role,
  };
};

export default withI18n()(connect(mapStateToProps)(ReviewReport));
