import React from 'react';

import { t } from '@lingui/macro';
import find from 'lodash/find';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { ISkillCategoryColumn } from '~/pages/ReviewThemeSetup/types';

import type { IColumnTable } from '~/@types/table';
import { COLORS } from '~/styles';

import type { I18n } from '@lingui/core';

export const NameRow = styled.div<{
  isBold?: boolean;
  padding?: string;
  maxWidth?: string;
  background?: string;
  borderRadius?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: ${({ background }) => background || COLORS.BG_LIST};
  padding: ${({ padding }) => padding || '12px 22px'};
  max-width: ${({ maxWidth }) => maxWidth || 'unset'};
  border-radius: 6px;
  height: 100%;
  min-height: 64px;
  color: ${({ isBold }) => (isBold ? COLORS.TEXT_HOVER : COLORS.SUBTEXT)};
  font-weight: ${({ isBold }) => (isBold ? '600' : 'normal')};

  & .text {
    white-space: normal;
    word-break: break-all;
  }

  & .buttons {
    display: none;
  }

  & .center {
    width: 100%;
    text-align: center;
  }

  & .black {
    color: ${COLORS.TEXT_HOVER};
  }

  &:hover {
    & .buttons {
      display: flex;
      margin-right: -20px;
    }
  }
`;

export const createSkillCategoryColumns = (): { columns: IColumnTable<ISkillCategoryColumn>[] } => {
  return {
    columns: [
      {
        name: (i18n: I18n) => i18n._(t`Skills`),
        accessor: 'name',
        maxWidth: '200px',
        renderCell: (props) => {
          const { name, onDelete, onEdit } = props;
          return name ? (
            <NameRow maxWidth="200px" isBold>
              <span className="text">{name.en_GB || find(name)}</span>

              <div className="buttons">
                <Button
                  type="button"
                  variant={ButtonVariant.ICON}
                  size={ButtonSize.MEDIUM}
                  icon={ICONS.EDIT_PENCIL}
                  onClick={onEdit}
                />
                <Button
                  type="button"
                  variant={ButtonVariant.ICON}
                  size={ButtonSize.MEDIUM}
                  icon={ICONS.DELETE_BIN}
                  onClick={onDelete}
                />
              </div>
            </NameRow>
          ) : (
            <></>
          );
        },
      },
      {
        name: (i18n: I18n) => i18n._(t`Focus Area`),
        accessor: 'focusArea',
        renderCell: ({ focusArea }) => {
          return <NameRow>{focusArea.en_GB || find(focusArea)}</NameRow>;
        },
      },
      {
        name: (i18n: I18n) => i18n._(t`Level`),
        accessor: 'level',
        maxWidth: '100px',
        renderCell: ({ level, totalLevels }) => {
          return (
            <NameRow>
              <div className="center">
                <span className="black">{level}</span>/{totalLevels}
              </div>
            </NameRow>
          );
        },
      },
    ],
  };
};
