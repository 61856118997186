import React, { useEffect, useRef } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import filter from 'lodash/filter';
import { Scrollbars } from 'react-custom-scrollbars-2';
import styled from 'styled-components';

import Search from '~/components/Search';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import SearchListItem from './SearchListItem';

import { COLOR_SET, COLOR_PALETTE } from '~/styles';

const OptionList = styled(Scrollbars)``;

const DropDownBox = styled.div`
  padding: 10px 12px;
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
`;

const Wrap = styled.div`
  background: ${COLOR_PALETTE.WHITE};
  border-radius: 6px;
  overflow: hidden;
  width: ${(props) => props.width || '238px'};
  border: solid 1px ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  outline: none;
`;

const SelectedTitle = styled.div`
  font-size: 12px;
  font-weight: 300;
  padding: 8px 10px;
  color: ${COLOR_SET.GRAY};
`;

const NoResultTitle = styled.div`
  height: 100%;
  box-sizing: border-box;
  color: ${COLOR_SET.GRAY};
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const searchStyles = {
  padding: '0 8px',
  minHeight: '20px',
  height: '20px',
  boxSizing: 'border-box',
  width: '100%',
  fontSize: '12px',
  border: 'none',
  fontFamily: 'Open Sans, sans-serif',
  color: COLOR_PALETTE.DARK_GRAY,
};

const List = ({
  list,
  labelProperty = 'name',
  labelPropertyReserve = 'name',
  checkedList,
  onCheck,
  onUncheck,
  search,
  onChangeSearch,
  loading,
  alphabeticalList = false,
  searchPlaceholder,
  width,
  disableSearch = false,
  showTooltip,
  lastItem,
}) => {
  const { i18n } = useLingui();
  const input = useRef();
  useEffect(() => {
    if (input.current) {
      input.current.focus();
    }
    // eslint-disable-next-line
  }, [input.current]);
  const handleFocus = () => {};
  const handleChange = (option, e) => {
    e.stopPropagation();
    onCheck(option);
  };

  const handleChangeSearch = (e) => {
    onChangeSearch(e.target.value);
  };

  const checkedIds = checkedList.map((i) => i.id);
  const foundItems = filter(list, (i) => checkedIds.indexOf(i.id) === -1);
  if (alphabeticalList) {
    foundItems.sort((a, b) => a.name.localeCompare(b.name));
  }

  return (
    <Wrap width={width}>
      {checkedList.length > 0 && (
        <SelectedTitle>
          <Trans>Selected</Trans>
        </SelectedTitle>
      )}
      <OptionList autoHeightMax={150} autoHeight>
        {checkedList.map((o) => (
          <SearchListItem
            key={o.id}
            option={o}
            labelProperty={labelProperty}
            labelPropertyReserve={labelPropertyReserve}
            isActive
            onRemove={onUncheck}
            showTooltip={showTooltip}
          />
        ))}
      </OptionList>
      {!disableSearch && (
        <DropDownBox>
          <Search
            name="searchValue"
            value={search}
            onChange={handleChangeSearch}
            onFocus={handleFocus}
            style={searchStyles}
            placeholder={searchPlaceholder || i18n._(t`Search...`)}
            ref={input}
            iconPrepend
            defaultColor={COLOR_PALETTE.GRAY_SEMI_SOFT}
          />
        </DropDownBox>
      )}
      <ShowSpinnerIfLoading loading={loading}>
        <OptionList autoHeightMax={199} autoHeight>
          {foundItems.length > 0 ? (
            foundItems.map((o, index) => (
              <SearchListItem
                key={o.id || index}
                option={o}
                labelProperty={labelProperty}
                labelPropertyReserve={labelPropertyReserve}
                isActive={checkedList.map((o) => o.id).indexOf(o.id) !== -1}
                onChange={handleChange}
                showTooltip={showTooltip}
              />
            ))
          ) : (
            <NoResultTitle>
              <Trans>No results</Trans>
            </NoResultTitle>
          )}
        </OptionList>
      </ShowSpinnerIfLoading>
      {lastItem}
    </Wrap>
  );
};

export default React.memo(List);
