import React from 'react';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { confirmable, createConfirmation } from 'react-confirm';

import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import type { IConfirmationModelProps } from '~/components/Modals/ConfirmationModal';

interface IConfirmDialog extends IConfirmationModelProps {
  proceed: (value?: boolean) => void;
}

const ConfirmDialog = confirmable((props: IConfirmDialog) => {
  // <I18nProvider i18n={i18n}> need for correct work of buttons
  return (
    <I18nProvider i18n={i18n}>
      <ConfirmationModal {...props} />
    </I18nProvider>
  );
});

export const confirm = (params: IConfirmationModelProps) =>
  createConfirmation(ConfirmDialog, 0)(params);
