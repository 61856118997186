import React from 'react';

import { ITeam } from '@learned/types';

import { ParentGoalTeamName } from '../../stepsDesign';

interface IProps {
  teams: ITeam[];
}

const TeamsCell = ({ teams }: IProps) => {
  return <ParentGoalTeamName>{teams.map((team) => team.name).join(', ')}</ParentGoalTeamName>;
};

export { TeamsCell };
