import React, { SVGAttributes } from 'react';

import SVG from 'react-inlinesvg';
import styled from 'styled-components';

import Accessibility from './svgs/accessibility-outline.svg';
import AddCircle from './svgs/add-circle-outline.svg';
import Add from './svgs/add-outline.svg';
import Airplane from './svgs/airplane-outline.svg';
import Alarm from './svgs/alarm-outline.svg';
import Albums from './svgs/albums-outline.svg';
import AlertCircle from './svgs/alert-circle-outline.svg';
import Alert from './svgs/alert-outline.svg';
import AmericanFootball from './svgs/american-football-outline.svg';
import Analytics from './svgs/analytics-outline.svg';
import Aperture from './svgs/aperture-outline.svg';
import Apps from './svgs/apps-outline.svg';
import Archive from './svgs/archive-outline.svg';
import ArrowBackCircle from './svgs/arrow-back-circle-outline.svg';
import ArrowBack from './svgs/arrow-back-outline.svg';
import ArrowDownCircle from './svgs/arrow-down-circle-outline.svg';
import ArrowDown from './svgs/arrow-down-outline.svg';
import ArrowForwardCircle from './svgs/arrow-forward-circle-outline.svg';
import ArrowForward from './svgs/arrow-forward-outline.svg';
import ArrowRedoCircle from './svgs/arrow-redo-circle-outline.svg';
import ArrowRedo from './svgs/arrow-redo-outline.svg';
import ArrowUndoCircle from './svgs/arrow-undo-circle-outline.svg';
import ArrowUndo from './svgs/arrow-undo-outline.svg';
import ArrowUpCircle from './svgs/arrow-up-circle-outline.svg';
import ArrowUp from './svgs/arrow-up-outline.svg';
import Arrows from './svgs/arrows.svg';
import AtCircle from './svgs/at-circle-outline.svg';
import At from './svgs/at-outline.svg';
import Attach from './svgs/attach-outline.svg';
import Backspace from './svgs/backspace-outline.svg';
import BagAdd from './svgs/bag-add-outline.svg';
import BagCheck from './svgs/bag-check-outline.svg';
import BagHandle from './svgs/bag-handle-outline.svg';
import Bag from './svgs/bag-outline.svg';
import BagRemove from './svgs/bag-remove-outline.svg';
import Balloon from './svgs/balloon-outline.svg';
import Ban from './svgs/ban-outline.svg';
import Bandage from './svgs/bandage-outline.svg';
import BarChart from './svgs/bar-chart-outline.svg';
import Barbell from './svgs/barbell-outline.svg';
import Barcode from './svgs/barcode-outline.svg';
import Baseball from './svgs/baseball-outline.svg';
import Basket from './svgs/basket-outline.svg';
import Basketball from './svgs/basketball-outline.svg';
import BatteryCharging from './svgs/battery-charging-outline.svg';
import BatteryDead from './svgs/battery-dead-outline.svg';
import BatteryFull from './svgs/battery-full-outline.svg';
import BatteryHalf from './svgs/battery-half-outline.svg';
import Beaker from './svgs/beaker-outline.svg';
import Bed from './svgs/bed-outline.svg';
import Beer from './svgs/beer-outline.svg';
import Bicycle from './svgs/bicycle-outline.svg';
import Bluetooth from './svgs/bluetooth-outline.svg';
import Boat from './svgs/boat-outline.svg';
import Body from './svgs/body-outline.svg';
import Bonfire from './svgs/bonfire-outline.svg';
import Book from './svgs/book-outline.svg';
import Bookmark from './svgs/bookmark-outline.svg';
import Bookmarks from './svgs/bookmarks-outline.svg';
import BowlingBall from './svgs/bowling-ball-outline.svg';
import Briefcase from './svgs/briefcase-outline.svg';
import Browsers from './svgs/browsers-outline.svg';
import Brush from './svgs/brush-outline.svg';
import Bug from './svgs/bug-outline.svg';
import Build from './svgs/build-outline.svg';
import Bulb from './svgs/bulb-outline.svg';
import Bus from './svgs/bus-outline.svg';
import Business from './svgs/business-outline.svg';
import Cafe from './svgs/cafe-outline.svg';
import Calculator from './svgs/calculator-outline.svg';
import CalendarClear from './svgs/calendar-clear-outline.svg';
import CalendarNumber from './svgs/calendar-number-outline.svg';
import Calendar from './svgs/calendar-outline.svg';
import Call from './svgs/call-outline.svg';
import Camera from './svgs/camera-outline.svg';
import CameraReverse from './svgs/camera-reverse-outline.svg';
import Car from './svgs/car-outline.svg';
import CarSport from './svgs/car-sport-outline.svg';
import Card from './svgs/card-outline.svg';
import CaretBackCircle from './svgs/caret-back-circle-outline.svg';
import CaretBack from './svgs/caret-back-outline.svg';
import CaretDownCircle from './svgs/caret-down-circle-outline.svg';
import CaretDown from './svgs/caret-down-outline.svg';
import CaretForwardCircle from './svgs/caret-forward-circle-outline.svg';
import CaretForward from './svgs/caret-forward-outline.svg';
import CaretUpCircle from './svgs/caret-up-circle-outline.svg';
import CaretUp from './svgs/caret-up-outline.svg';
import Cart from './svgs/cart-outline.svg';
import Cash from './svgs/cash-outline.svg';
import Cellular from './svgs/cellular-outline.svg';
import ChatboxEllipses from './svgs/chatbox-ellipses-outline.svg';
import Chatbox from './svgs/chatbox-outline.svg';
import ChatbubbleEllipses from './svgs/chatbubble-ellipses-outline.svg';
import Chatbubble from './svgs/chatbubble-outline.svg';
import Chatbubbles from './svgs/chatbubbles-outline.svg';
import Checkbox from './svgs/checkbox-outline.svg';
import CheckmarkCircle from './svgs/checkmark-circle-outline.svg';
import CheckmarkDoneCircle from './svgs/checkmark-done-circle-outline.svg';
import CheckmarkDone from './svgs/checkmark-done-outline.svg';
import Checkmark from './svgs/checkmark-outline.svg';
import ChevronBackCircle from './svgs/chevron-back-circle-outline.svg';
import ChevronBack from './svgs/chevron-back-outline.svg';
import ChevronDownCircle from './svgs/chevron-down-circle-outline.svg';
import ChevronDown from './svgs/chevron-down-outline.svg';
import ChevronForwardCircle from './svgs/chevron-forward-circle-outline.svg';
import ChevronForward from './svgs/chevron-forward-outline.svg';
import ChevronUpCircle from './svgs/chevron-up-circle-outline.svg';
import ChevronUp from './svgs/chevron-up-outline.svg';
import Clipboard from './svgs/clipboard-outline.svg';
import CloseCircle from './svgs/close-circle-outline.svg';
import Close from './svgs/close-outline.svg';
import CloudCircle from './svgs/cloud-circle-outline.svg';
import CloudDone from './svgs/cloud-done-outline.svg';
import CloudDownload from './svgs/cloud-download-outline.svg';
import CloudOffline from './svgs/cloud-offline-outline.svg';
import Cloud from './svgs/cloud-outline.svg';
import CloudUpload from './svgs/cloud-upload-outline.svg';
import CloudyNight from './svgs/cloudy-night-outline.svg';
import Cloudy from './svgs/cloudy-outline.svg';
import CodeDownload from './svgs/code-download-outline.svg';
import Code from './svgs/code-outline.svg';
import CodeSlash from './svgs/code-slash-outline.svg';
import CodeWorking from './svgs/code-working-outline.svg';
import Cog from './svgs/cog-outline.svg';
import ColorFill from './svgs/color-fill-outline.svg';
import ColorFilter from './svgs/color-filter-outline.svg';
import ColorPalette from './svgs/color-palette-outline.svg';
import ColorWand from './svgs/color-wand-outline.svg';
import Compass from './svgs/compass-outline.svg';
import Construct from './svgs/construct-outline.svg';
import Contract from './svgs/contract-outline.svg';
import Contrast from './svgs/contrast-outline.svg';
import Copy from './svgs/copy-outline.svg';
import Create from './svgs/create-outline.svg';
import Crop from './svgs/crop-outline.svg';
import Cube from './svgs/cube-outline.svg';
import Cut from './svgs/cut-outline.svg';
import Desktop from './svgs/desktop-outline.svg';
import Diamond from './svgs/diamond-outline.svg';
import Dice from './svgs/dice-outline.svg';
import Disc from './svgs/disc-outline.svg';
import DocumentAttach from './svgs/document-attach-outline.svg';
import DocumentLock from './svgs/document-lock-outline.svg';
import Document from './svgs/document-outline.svg';
import DocumentText from './svgs/document-text-outline.svg';
import Documents from './svgs/documents-outline.svg';
import Download from './svgs/download-outline.svg';
import Duplicate from './svgs/duplicate-outline.svg';
import Ear from './svgs/ear-outline.svg';
import Earth from './svgs/earth-outline.svg';
import Easel from './svgs/easel-outline.svg';
import Egg from './svgs/egg-outline.svg';
import Ellipse from './svgs/ellipse-outline.svg';
import EllipsisHorizontalCircle from './svgs/ellipsis-horizontal-circle-outline.svg';
import EllipsisHorizontal from './svgs/ellipsis-horizontal-outline.svg';
import EllipsisVerticalCircle from './svgs/ellipsis-vertical-circle-outline.svg';
import EllipsisVertical from './svgs/ellipsis-vertical-outline.svg';
import Enter from './svgs/enter-outline.svg';
import Exit from './svgs/exit-outline.svg';
import Expand from './svgs/expand-outline.svg';
import ExtensionPuzzle from './svgs/extension-puzzle-outline.svg';
import EyeOff from './svgs/eye-off-outline.svg';
import Eye from './svgs/eye-outline.svg';
import Eyedrop from './svgs/eyedrop-outline.svg';
import FastFood from './svgs/fast-food-outline.svg';
import Female from './svgs/female-outline.svg';
import FileTrayFull from './svgs/file-tray-full-outline.svg';
import FileTray from './svgs/file-tray-outline.svg';
import FileTrayStacked from './svgs/file-tray-stacked-outline.svg';
import Film from './svgs/film-outline.svg';
import FilterCircle from './svgs/filter-circle-outline.svg';
import Filter from './svgs/filter-outline.svg';
import FingerPrint from './svgs/finger-print-outline.svg';
import Fish from './svgs/fish-outline.svg';
import Fitness from './svgs/fitness-outline.svg';
import Flag from './svgs/flag-outline.svg';
import Flame from './svgs/flame-outline.svg';
import FlashOff from './svgs/flash-off-outline.svg';
import Flash from './svgs/flash-outline.svg';
import Flashlight from './svgs/flashlight-outline.svg';
import Flask from './svgs/flask-outline.svg';
import Flower from './svgs/flower-outline.svg';
import FolderOpen from './svgs/folder-open-outline.svg';
import Folder from './svgs/folder-outline.svg';
import Football from './svgs/football-outline.svg';
import Footsteps from './svgs/footsteps-outline.svg';
import Funnel from './svgs/funnel-outline.svg';
import GameController from './svgs/game-controller-outline.svg';
import Gift from './svgs/gift-outline.svg';
import GitBranch from './svgs/git-branch-outline.svg';
import GitCommit from './svgs/git-commit-outline.svg';
import GitCompare from './svgs/git-compare-outline.svg';
import GitMerge from './svgs/git-merge-outline.svg';
import GitNetwork from './svgs/git-network-outline.svg';
import GitPullRequest from './svgs/git-pull-request-outline.svg';
import Glasses from './svgs/glasses-outline.svg';
import Globe from './svgs/globe-outline.svg';
import Golf from './svgs/golf-outline.svg';
import Grid from './svgs/grid-outline.svg';
import Hammer from './svgs/hammer-outline.svg';
import HandLeft from './svgs/hand-left-outline.svg';
import HandRight from './svgs/hand-right-outline.svg';
import Happy from './svgs/happy-outline.svg';
import HardwareChip from './svgs/hardware-chip-outline.svg';
import Headset from './svgs/headset-outline.svg';
import HeartCircle from './svgs/heart-circle-outline.svg';
import HeartDislikeCircle from './svgs/heart-dislike-circle-outline.svg';
import HeartDislike from './svgs/heart-dislike-outline.svg';
import HeartHalf from './svgs/heart-half-outline.svg';
import Heart from './svgs/heart-outline.svg';
import HelpBuoy from './svgs/help-buoy-outline.svg';
import HelpCircle from './svgs/help-circle-outline.svg';
import Help from './svgs/help-outline.svg';
import Home from './svgs/home-outline.svg';
import Hourglass from './svgs/hourglass-outline.svg';
import IceCream from './svgs/ice-cream-outline.svg';
import IdCard from './svgs/id-card-outline.svg';
import Image from './svgs/image-outline.svg';
import Images from './svgs/images-outline.svg';
import Infinite from './svgs/infinite-outline.svg';
import InformationCircle from './svgs/information-circle-outline.svg';
import Information from './svgs/information-outline.svg';
import InvertMode from './svgs/invert-mode-outline.svg';
import Journal from './svgs/journal-outline.svg';
import Key from './svgs/key-outline.svg';
import Keypad from './svgs/keypad-outline.svg';
import Language from './svgs/language-outline.svg';
import Laptop from './svgs/laptop-outline.svg';
import Layers from './svgs/layers-outline.svg';
import Leaf from './svgs/leaf-outline.svg';
import Library from './svgs/library-outline.svg';
import Link from './svgs/link-outline.svg';
import ListCircle from './svgs/list-circle-outline.svg';
import List from './svgs/list-outline.svg';
import Locate from './svgs/locate-outline.svg';
import Location from './svgs/location-outline.svg';
import LockClosed from './svgs/lock-closed-outline.svg';
import LockOpen from './svgs/lock-open-outline.svg';
import LogIn from './svgs/log-in-outline.svg';
import LogOut from './svgs/log-out-outline.svg';
import Magnet from './svgs/magnet-outline.svg';
import MailOpen from './svgs/mail-open-outline.svg';
import Mail from './svgs/mail-outline.svg';
import MailUnread from './svgs/mail-unread-outline.svg';
import MaleFemale from './svgs/male-female-outline.svg';
import Male from './svgs/male-outline.svg';
import Man from './svgs/man-outline.svg';
import Map from './svgs/map-outline.svg';
import Medal from './svgs/medal-outline.svg';
import Medical from './svgs/medical-outline.svg';
import Medkit from './svgs/medkit-outline.svg';
import Megaphone from './svgs/megaphone-outline.svg';
import Menu from './svgs/menu-outline.svg';
import MicCircle from './svgs/mic-circle-outline.svg';
import MicOffCircle from './svgs/mic-off-circle-outline.svg';
import MicOff from './svgs/mic-off-outline.svg';
import Mic from './svgs/mic-outline.svg';
import Moon from './svgs/moon-outline.svg';
import Move from './svgs/move-outline.svg';
import MusicalNote from './svgs/musical-note-outline.svg';
import MusicalNotes from './svgs/musical-notes-outline.svg';
import NavigateCircle from './svgs/navigate-circle-outline.svg';
import Navigate from './svgs/navigate-outline.svg';
import Newspaper from './svgs/newspaper-outline.svg';
import NotificationsCircle from './svgs/notifications-circle-outline.svg';
import NotificationsOffCircle from './svgs/notifications-off-circle-outline.svg';
import NotificationsOff from './svgs/notifications-off-outline.svg';
import Notifications from './svgs/notifications-outline.svg';
import Nuclear from './svgs/nuclear-outline.svg';
import Nutrition from './svgs/nutrition-outline.svg';
import Open from './svgs/open-outline.svg';
import Options from './svgs/options-outline.svg';
import PaperPlane from './svgs/paper-plane-outline.svg';
import PartlySunny from './svgs/partly-sunny-outline.svg';
import PauseCircle from './svgs/pause-circle-outline.svg';
import Pause from './svgs/pause-outline.svg';
import Paw from './svgs/paw-outline.svg';
import Pencil from './svgs/pencil-outline.svg';
import PeopleCircle from './svgs/people-circle-outline.svg';
import People from './svgs/people-outline.svg';
import PersonAdd from './svgs/person-add-outline.svg';
import PersonCircle from './svgs/person-circle-outline.svg';
import Person from './svgs/person-outline.svg';
import PersonRemove from './svgs/person-remove-outline.svg';
import PhoneLandscape from './svgs/phone-landscape-outline.svg';
import PhonePortrait from './svgs/phone-portrait-outline.svg';
import PieChart from './svgs/pie-chart-outline.svg';
import Pin from './svgs/pin-outline.svg';
import Pint from './svgs/pint-outline.svg';
import Pizza from './svgs/pizza-outline.svg';
import Planet from './svgs/planet-outline.svg';
import PlayBackCircle from './svgs/play-back-circle-outline.svg';
import PlayBack from './svgs/play-back-outline.svg';
import PlayCircle from './svgs/play-circle-outline.svg';
import PlayForwardCircle from './svgs/play-forward-circle-outline.svg';
import PlayForward from './svgs/play-forward-outline.svg';
import Play from './svgs/play-outline.svg';
import PlaySkipBackCircle from './svgs/play-skip-back-circle-outline.svg';
import PlaySkipBack from './svgs/play-skip-back-outline.svg';
import PlaySkipForwardCircle from './svgs/play-skip-forward-circle-outline.svg';
import PlaySkipForward from './svgs/play-skip-forward-outline.svg';
import Podium from './svgs/podium-outline.svg';
import Power from './svgs/power-outline.svg';
import Pricetag from './svgs/pricetag-outline.svg';
import Pricetags from './svgs/pricetags-outline.svg';
import Print from './svgs/print-outline.svg';
import Prism from './svgs/prism-outline.svg';
import Pulse from './svgs/pulse-outline.svg';
import Push from './svgs/push-outline.svg';
import QrCode from './svgs/qr-code-outline.svg';
import RadioButtonOff from './svgs/radio-button-off-outline.svg';
import RadioButtonOn from './svgs/radio-button-on-outline.svg';
import Radio from './svgs/radio-outline.svg';
import Rainy from './svgs/rainy-outline.svg';
import Reader from './svgs/reader-outline.svg';
import Receipt from './svgs/receipt-outline.svg';
import Recording from './svgs/recording-outline.svg';
import RefreshCircle from './svgs/refresh-circle-outline.svg';
import Refresh from './svgs/refresh-outline.svg';
import ReloadCircle from './svgs/reload-circle-outline.svg';
import Reload from './svgs/reload-outline.svg';
import RemoveCircle from './svgs/remove-circle-outline.svg';
import Remove from './svgs/remove-outline.svg';
import ReorderFour from './svgs/reorder-four-outline.svg';
import ReorderThree from './svgs/reorder-three-outline.svg';
import ReorderTwo from './svgs/reorder-two-outline.svg';
import Repeat from './svgs/repeat-outline.svg';
import Resize from './svgs/resize-outline.svg';
import Restaurant from './svgs/restaurant-outline.svg';
import ReturnDownBack from './svgs/return-down-back-outline.svg';
import ReturnDownForward from './svgs/return-down-forward-outline.svg';
import ReturnUpBack from './svgs/return-up-back-outline.svg';
import ReturnUpForward from './svgs/return-up-forward-outline.svg';
import Ribbon from './svgs/ribbon-outline.svg';
import Rocket from './svgs/rocket-outline.svg';
import Rose from './svgs/rose-outline.svg';
import Sad from './svgs/sad-outline.svg';
import Save from './svgs/save-outline.svg';
import Scale from './svgs/scale-outline.svg';
import ScanCircle from './svgs/scan-circle-outline.svg';
import Scan from './svgs/scan-outline.svg';
import School from './svgs/school-outline.svg';
import SearchCircle from './svgs/search-circle-outline.svg';
import Search from './svgs/search-outline.svg';
import Send from './svgs/send-outline.svg';
import Server from './svgs/server-outline.svg';
import Settings from './svgs/settings-outline.svg';
import Shapes from './svgs/shapes-outline.svg';
import Share from './svgs/share-outline.svg';
import ShareSocial from './svgs/share-social-outline.svg';
import ShieldCheckmark from './svgs/shield-checkmark-outline.svg';
import ShieldHalf from './svgs/shield-half-outline.svg';
import Shield from './svgs/shield-outline.svg';
import Shirt from './svgs/shirt-outline.svg';
import Shuffle from './svgs/shuffle-outline.svg';
import Skull from './svgs/skull-outline.svg';
import Snow from './svgs/snow-outline.svg';
import Sparkles from './svgs/sparkles-outline.svg';
import Speedometer from './svgs/speedometer-outline.svg';
import Square from './svgs/square-outline.svg';
import StarHalf from './svgs/star-half-outline.svg';
import Star from './svgs/star-outline.svg';
import StatsChart from './svgs/stats-chart-outline.svg';
import StopCircle from './svgs/stop-circle-outline.svg';
import Stop from './svgs/stop-outline.svg';
import Stopwatch from './svgs/stopwatch-outline.svg';
import Storefront from './svgs/storefront-outline.svg';
import Subway from './svgs/subway-outline.svg';
import Sunny from './svgs/sunny-outline.svg';
import SwapHorizontal from './svgs/swap-horizontal-outline.svg';
import SwapVertical from './svgs/swap-vertical-outline.svg';
import SyncCircle from './svgs/sync-circle-outline.svg';
import Sync from './svgs/sync-outline.svg';
import TabletLandscape from './svgs/tablet-landscape-outline.svg';
import TabletPortrait from './svgs/tablet-portrait-outline.svg';
import Telescope from './svgs/telescope-outline.svg';
import Tennisball from './svgs/tennisball-outline.svg';
import Terminal from './svgs/terminal-outline.svg';
import Text from './svgs/text-outline.svg';
import Thermometer from './svgs/thermometer-outline.svg';
import ThumbsDown from './svgs/thumbs-down-outline.svg';
import ThumbsUp from './svgs/thumbs-up-outline.svg';
import Thunderstorm from './svgs/thunderstorm-outline.svg';
import Ticket from './svgs/ticket-outline.svg';
import Time from './svgs/time-outline.svg';
import Timer from './svgs/timer-outline.svg';
import Today from './svgs/today-outline.svg';
import Toggle from './svgs/toggle-outline.svg';
import TrailSign from './svgs/trail-sign-outline.svg';
import Train from './svgs/train-outline.svg';
import Transgender from './svgs/transgender-outline.svg';
import TrashBin from './svgs/trash-bin-outline.svg';
import Trash from './svgs/trash-outline.svg';
import TrendingDown from './svgs/trending-down-outline.svg';
import TrendingUp from './svgs/trending-up-outline.svg';
import Triangle from './svgs/triangle-outline.svg';
import Trophy from './svgs/trophy-outline.svg';
import Tv from './svgs/tv-outline.svg';
import Umbrella from './svgs/umbrella-outline.svg';
import Unlink from './svgs/unlink-outline.svg';
import VideocamOff from './svgs/videocam-off-outline.svg';
import Videocam from './svgs/videocam-outline.svg';
import VolumeHigh from './svgs/volume-high-outline.svg';
import VolumeLow from './svgs/volume-low-outline.svg';
import VolumeMedium from './svgs/volume-medium-outline.svg';
import VolumeMute from './svgs/volume-mute-outline.svg';
import VolumeOff from './svgs/volume-off-outline.svg';
import Walk from './svgs/walk-outline.svg';
import Wallet from './svgs/wallet-outline.svg';
import Warning from './svgs/warning-outline.svg';
import Watch from './svgs/watch-outline.svg';
import Water from './svgs/water-outline.svg';
import Wifi from './svgs/wifi-outline.svg';
import Wine from './svgs/wine-outline.svg';
import Woman from './svgs/woman-outline.svg';

export const IconsList = {
  Accessibility,
  AddCircle,
  Add,
  Airplane,
  Alarm,
  Albums,
  AlertCircle,
  Alert,
  AmericanFootball,
  Analytics,
  Aperture,
  Apps,
  Archive,
  ArrowBackCircle,
  ArrowBack,
  ArrowDownCircle,
  ArrowDown,
  ArrowForwardCircle,
  ArrowForward,
  ArrowRedoCircle,
  ArrowRedo,
  ArrowUndoCircle,
  ArrowUndo,
  ArrowUpCircle,
  ArrowUp,
  Arrows,
  AtCircle,
  At,
  Attach,
  Backspace,
  BagAdd,
  BagCheck,
  BagHandle,
  BagRemove,
  Bag,
  Balloon,
  Ban,
  Bandage,
  BarChart,
  Barbell,
  Barcode,
  Baseball,
  Basket,
  Basketball,
  BatteryCharging,
  BatteryDead,
  BatteryFull,
  BatteryHalf,
  Beaker,
  Bed,
  Beer,
  Bicycle,
  Bluetooth,
  Boat,
  Body,
  Bonfire,
  Book,
  Bookmark,
  Bookmarks,
  BowlingBall,
  Briefcase,
  Browsers,
  Brush,
  Bug,
  Build,
  Bulb,
  Bus,
  Business,
  Cafe,
  Calculator,
  CalendarClear,
  CalendarNumber,
  Calendar,
  Call,
  CameraReverse,
  Camera,
  CarSport,
  Car,
  Card,
  CaretBackCircle,
  CaretBack,
  CaretDownCircle,
  CaretDown,
  CaretForwardCircle,
  CaretForward,
  CaretUpCircle,
  CaretUp,
  Cart,
  Cash,
  Cellular,
  ChatboxEllipses,
  Chatbox,
  ChatbubbleEllipses,
  Chatbubble,
  Chatbubbles,
  Checkbox,
  CheckmarkCircle,
  CheckmarkDoneCircle,
  CheckmarkDone,
  Checkmark,
  ChevronBackCircle,
  ChevronBack,
  ChevronDownCircle,
  ChevronDown,
  ChevronForwardCircle,
  ChevronForward,
  ChevronUpCircle,
  ChevronUp,
  Clipboard,
  CloseCircle,
  Close,
  CloudCircle,
  CloudDone,
  CloudDownload,
  CloudOffline,
  CloudUpload,
  Cloud,
  CloudyNight,
  Cloudy,
  CodeDownload,
  CodeSlash,
  CodeWorking,
  Code,
  Cog,
  ColorFill,
  ColorFilter,
  ColorPalette,
  ColorWand,
  Compass,
  Construct,
  Contract,
  Contrast,
  Copy,
  Create,
  Crop,
  Cube,
  Cut,
  Desktop,
  Diamond,
  Dice,
  Disc,
  DocumentAttach,
  DocumentLock,
  DocumentText,
  Document,
  Documents,
  Download,
  Duplicate,
  Ear,
  Earth,
  Easel,
  Egg,
  Ellipse,
  EllipsisHorizontalCircle,
  EllipsisHorizontal,
  EllipsisVerticalCircle,
  EllipsisVertical,
  Enter,
  Exit,
  Expand,
  ExtensionPuzzle,
  EyeOff,
  Eye,
  Eyedrop,
  FastFood,
  Female,
  FileTrayFull,
  FileTrayStacked,
  FileTray,
  Film,
  FilterCircle,
  Filter,
  FingerPrint,
  Fish,
  Fitness,
  Flag,
  Flame,
  FlashOff,
  Flash,
  Flashlight,
  Flask,
  Flower,
  FolderOpen,
  Folder,
  Football,
  Footsteps,
  Funnel,
  GameController,
  Gift,
  GitBranch,
  GitCommit,
  GitCompare,
  GitMerge,
  GitNetwork,
  GitPullRequest,
  Glasses,
  Globe,
  Golf,
  Grid,
  Hammer,
  HandLeft,
  HandRight,
  Happy,
  HardwareChip,
  Headset,
  HeartCircle,
  HeartDislikeCircle,
  HeartDislike,
  HeartHalf,
  Heart,
  HelpBuoy,
  HelpCircle,
  Help,
  Home,
  Hourglass,
  IceCream,
  IdCard,
  Image,
  Images,
  Infinite,
  InformationCircle,
  Information,
  InvertMode,
  Journal,
  Key,
  Keypad,
  Language,
  Laptop,
  Layers,
  Leaf,
  Library,
  Link,
  ListCircle,
  List,
  Locate,
  Location,
  LockClosed,
  LockOpen,
  LogIn,
  LogOut,
  Magnet,
  MailOpen,
  MailUnread,
  Mail,
  MaleFemale,
  Male,
  Man,
  Map,
  Medal,
  Medical,
  Medkit,
  Megaphone,
  Menu,
  MicCircle,
  MicOffCircle,
  MicOff,
  Mic,
  Moon,
  Move,
  MusicalNote,
  MusicalNotes,
  NavigateCircle,
  Navigate,
  Newspaper,
  NotificationsCircle,
  NotificationsOffCircle,
  NotificationsOff,
  Notifications,
  Nuclear,
  Nutrition,
  Open,
  Options,
  PaperPlane,
  PartlySunny,
  PauseCircle,
  Pause,
  Paw,
  Pencil,
  PeopleCircle,
  People,
  PersonAdd,
  PersonCircle,
  PersonRemove,
  Person,
  PhoneLandscape,
  PhonePortrait,
  PieChart,
  Pin,
  Pint,
  Pizza,
  Planet,
  PlayBackCircle,
  PlayBack,
  PlayCircle,
  PlayForwardCircle,
  PlayForward,
  PlaySkipBackCircle,
  PlaySkipBack,
  PlaySkipForwardCircle,
  PlaySkipForward,
  Play,
  Podium,
  Power,
  Pricetag,
  Pricetags,
  Print,
  Prism,
  Pulse,
  Push,
  QrCode,
  RadioButtonOff,
  RadioButtonOn,
  Radio,
  Rainy,
  Reader,
  Receipt,
  Recording,
  RefreshCircle,
  Refresh,
  ReloadCircle,
  Reload,
  RemoveCircle,
  Remove,
  ReorderFour,
  ReorderThree,
  ReorderTwo,
  Repeat,
  Resize,
  Restaurant,
  ReturnDownBack,
  ReturnDownForward,
  ReturnUpBack,
  ReturnUpForward,
  Ribbon,
  Rocket,
  Rose,
  Sad,
  Save,
  Scale,
  ScanCircle,
  Scan,
  School,
  SearchCircle,
  Search,
  Send,
  Server,
  Settings,
  Shapes,
  ShareSocial,
  Share,
  ShieldCheckmark,
  ShieldHalf,
  Shield,
  Shirt,
  Shuffle,
  Skull,
  Snow,
  Sparkles,
  Speedometer,
  Square,
  StarHalf,
  Star,
  StatsChart,
  StopCircle,
  Stop,
  Stopwatch,
  Storefront,
  Subway,
  Sunny,
  SwapHorizontal,
  SwapVertical,
  SyncCircle,
  Sync,
  TabletLandscape,
  TabletPortrait,
  Telescope,
  Tennisball,
  Terminal,
  Text,
  Thermometer,
  ThumbsDown,
  ThumbsUp,
  Thunderstorm,
  Ticket,
  Time,
  Timer,
  Today,
  Toggle,
  TrailSign,
  Train,
  Transgender,
  TrashBin,
  Trash,
  TrendingDown,
  TrendingUp,
  Triangle,
  Trophy,
  Tv,
  Umbrella,
  Unlink,
  VideocamOff,
  Videocam,
  VolumeHigh,
  VolumeLow,
  VolumeMedium,
  VolumeMute,
  VolumeOff,
  Walk,
  Wallet,
  Warning,
  Watch,
  Water,
  Wifi,
  Wine,
  Woman,
};

export type IconNames = keyof typeof IconsList;

export interface IconProps extends SVGAttributes<SVGElement> {
  name?: IconNames;
  className?: string;
  color?: string;
}

const Wrapper = styled.div<{ width?: string; height?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: ${({ width }) => `${width}px` || '24px'};
    height: ${({ height }) => `${height}px` || '24px'};
  }
`;

/**
 * @deprecated since version 3.0 {@see Icon}
 */
export function IconOld({ name, className, width, height, color }: IconProps) {
  if (!name) {
    return <></>;
  }

  const icon = IconsList[name];

  if (!icon) {
    return <></>;
  }

  return (
    <Wrapper>
      <SVG
        className={className}
        src={icon}
        color={color}
        width={width}
        height={height}
        uniqueHash="a1f8d1"
      />
    </Wrapper>
  );
}
