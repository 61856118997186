import { SURVEY_QUESTION_TYPES } from '@learned/constants';
import { ISurveyQuestion, ISurveyQuestionStarsData, ISurveyTheme } from '@learned/types';
import times from 'lodash/times';

import { IItem } from '~/components/SurveyQuestionReport/SurveyQuestion';

import { LIGHTS } from '~/constants/surveyQuestions';

interface IAnswer {
  label?: string;
  isValue: boolean;
  value?: string;
  percentage?: string;
  icon?: string;
  light?: string;
}

const prepareAnswers = (
  options: { label?: string; icon?: string }[],
  answers: { value?: string; percentage?: string; respondents?: number }[],
  addAttr?: (option: { label?: string; icon?: string }, index: number) => {},
): IAnswer[] => {
  const result = options.map((option, index) => {
    const currentAnswer = answers.find((answer) => Number(answer.value) === Number(index));

    return {
      label: option.label,
      isValue: true,
      ...(currentAnswer && {
        value: String(currentAnswer?.respondents),
        percentage: currentAnswer?.percentage,
      }),
      ...(addAttr && addAttr(option, index)),
    };
  });
  return result;
};

interface prepareSurveyQuestionReportItemProps {
  question: ISurveyQuestion & { theme: ISurveyTheme };
  comments: string[];
  answers: {
    value: string;
    percentage?: string;
  }[];
  isAnswersHidden?: boolean;
}
export const prepareSurveyQuestionReportItem = (
  item: prepareSurveyQuestionReportItemProps,
  titleNumber?: string | number,
): IItem => {
  const result = {
    title: item.question.name as unknown as string,
    titleNumber,
    themeName: item?.question?.theme?.name,
    type: item?.question?.type,
    answers: [] as unknown as IAnswer[],
    isAnswersHidden: item.isAnswersHidden || false,
  };

  // @ts-ignore
  const options = item?.question?.data?.options;
  const answers = item.answers;

  switch (item.question.type) {
    case SURVEY_QUESTION_TYPES.TEXT: {
      result.answers = answers.map(({ value }) => ({
        label: value,
        isValue: false,
      }));
      break;
    }
    case SURVEY_QUESTION_TYPES.DROPDOWN:
    case SURVEY_QUESTION_TYPES.SINGLE:
    case SURVEY_QUESTION_TYPES.MULTIPLE: {
      result.answers = prepareAnswers(options, answers);
      break;
    }
    case SURVEY_QUESTION_TYPES.TRAFFIC: {
      result.answers = prepareAnswers(options, answers, (_option, index) => ({
        light: LIGHTS[index]?.color,
      })).reverse(); // reverse array, so red color always in the bottom
      break;
    }
    case SURVEY_QUESTION_TYPES.SMILEYS: {
      result.answers = prepareAnswers(options, answers, (option, _index) => ({
        icon: option.icon,
      })).reverse(); // reverse array so bad answer in the bottom
      break;
    }
    case SURVEY_QUESTION_TYPES.STARS: {
      // prepare options for starts
      const options = times(item?.question?.data?.size, (index) => {
        const result: { label?: string } = {};
        const isFirst = index === 0;
        const isLast = index === (item?.question?.data as ISurveyQuestionStarsData).size - 1;
        if (isFirst) {
          result.label = (item.question?.data as ISurveyQuestionStarsData)
            .firstLabel as unknown as string;
        }
        if (isLast) {
          result.label = (item.question?.data as ISurveyQuestionStarsData)
            .lastLabel as unknown as string;
        }
        return result;
      });

      // create answers
      result.answers = prepareAnswers(options, answers);
      break;
    }
    default:
      break;
  }

  // @ts-ignore
  return result;
};
