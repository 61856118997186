import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button } from '~/components/Buttons/Button';
import { ButtonVariant, ButtonSize } from '~/components/Buttons/types';
import { MultiLangComponent } from '~/components/Dropdown/MultiLangualDropdown';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import { ILanguageStateReturn } from '~/hooks/useLanguageState';

import { ActionsWrap, ActionItemBlock, ActionHint } from '../design';

interface IHeaderActions {
  isDraft: boolean;
  showHideForUsersHint: boolean;
  isLoading: boolean;
  onPublish?: () => Promise<void>;
  onEdit: () => void;
  showPreview?: () => void;
  languageState: ILanguageStateReturn;
  isPublishDisabled?: boolean;
}

const HeaderActions = ({
  isDraft,
  isLoading,
  onPublish,
  showHideForUsersHint,
  onEdit,
  languageState,
  isPublishDisabled,
  showPreview,
}: IHeaderActions) => {
  const { i18n } = useLingui();
  return (
    <ActionsWrap>
      {showHideForUsersHint && (
        <ActionItemBlock hideBorder>
          <Icon icon={ICONS.HIDE} size={ICON_SIZES.LARGE} />
          <ActionHint>
            <Trans> Theme results hidden for users</Trans>
          </ActionHint>
        </ActionItemBlock>
      )}
      <ActionItemBlock>
        <MultiLangComponent languageState={languageState} isSingleSelect />
      </ActionItemBlock>
      {showPreview !== undefined && (
        <ActionItemBlock>
          <Tooltip disabled={false} size={TOOLTIP_SIZES.BIG} tooltip={i18n._(t`Preview`)}>
            <span>
              <Button
                label=""
                icon={ICONS.SHOW}
                size={ButtonSize.MEDIUM}
                variant={ButtonVariant.ICON}
                onClick={showPreview}
              />
            </span>
          </Tooltip>
        </ActionItemBlock>
      )}
      <ActionItemBlock>
        <Button
          label={i18n._(t`edit`)}
          icon={ICONS.EDIT_PENCIL}
          size={ButtonSize.MEDIUM}
          variant={ButtonVariant.TEXT_PRIMARY}
          isLoading={isLoading}
          onClick={onEdit}
        />
      </ActionItemBlock>
      {isDraft && Boolean(onPublish) && (
        <ActionItemBlock>
          <Button
            label={i18n._(t`Publish`)}
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.PRIMARY}
            isLoading={isLoading}
            disabled={isPublishDisabled}
            onClick={onPublish as () => void}
          />
        </ActionItemBlock>
      )}
    </ActionsWrap>
  );
};

export { HeaderActions };
