import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';

import {
  SearchDropdownItem,
  SearchIcon,
  SearchPlaceholder,
  SearchInput,
} from '~/components/Dropdown/design';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

const Search = ({
  setSearch,
  search,
}: {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <SearchDropdownItem>
      {search === '' && (
        <SearchPlaceholder>
          <SearchIcon icon={ICONS.SEARCH} size={ICON_SIZES.MEDIUM} />
          Search
        </SearchPlaceholder>
      )}
      <SearchInput
        value={search}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
      />
      {search && <Icon onClick={() => setSearch('')} icon={ICONS.CLOSE} size={ICON_SIZES.SMALL} />}
    </SearchDropdownItem>
  );
};

export { Search };
