import React from 'react';

import styled from 'styled-components';

import CloseIcon from '~/components/Icons/Close';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import BoxWithBorder from '~/components/UI/BoxWithBorder';
import Divider from '~/components/UI/Divider';

import { COLORS } from '~/styles';

const Box = styled(BoxWithBorder)`
  flex-direction: column;
`;

const Header = styled.div`
  padding: 20px 16px 16px;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  color: ${COLORS.TEXT_PRIMARY};
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

const ChildContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BodyContainer = styled.div`
  padding: 20px 16px;
`;

const Navigation = styled.div`
  padding: 16px 16px 12px;
  display: flex;
`;

const LeftPart = styled.div`
  flex: 1;
`;

const RightPart = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const CenterPart = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const CloseButton = styled.div`
  margin-top: -9px;
  margin-right: -5px;
  border: none;
  cursor: pointer;
`;

const CardWithActions = ({
  className,
  children,
  title,
  loading,
  noFooter,
  leftFooterActions,
  rightFooterActions,
  headerActions,
  onClose,
  collapsed,
  centerFooterActions,
}) => {
  return (
    <Box className={className}>
      <Header>
        {title}
        <Actions>
          {headerActions}
          {onClose && (
            <CloseButton onClick={onClose}>
              <CloseIcon size={32} />
            </CloseButton>
          )}
        </Actions>
      </Header>
      {!collapsed && (
        <>
          <Divider />
          <BodyContainer>
            <ShowSpinnerIfLoading loading={loading}>
              <ChildContainer>{children}</ChildContainer>
            </ShowSpinnerIfLoading>
          </BodyContainer>
          {!noFooter && (
            <>
              <Divider />
              <Navigation>
                <LeftPart>{leftFooterActions}</LeftPart>
                <CenterPart>{centerFooterActions}</CenterPart>
                <RightPart>{rightFooterActions}</RightPart>
              </Navigation>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default React.memo(CardWithActions);
