import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import Button from '~/components/Button';
import Editor from '~/components/Editor';
import ShowMore from '~/components/ShowMore';
import AvatarCard from '~/components/UI/AvatarCard';

import {
  FeedbackBlock,
  ProvidedFeedbackWrap,
  FeedbackBlockWrapper,
  SubFBWrap,
  UserFeedback,
  Date,
} from './styledComponets';

import { COLOR_PALETTE } from '~/styles';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';
import sanitizeHtml from '~/utils/sanitize';

const StyledAvatarCard = styled(AvatarCard)`
  margin-top: 8px;
  flex-direction: row-reverse;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  min-width: 73px;
  border-radius: 6px;
  height: 40px !important;
`;

const StyledEditor = styled(Editor)`
  background-color: ${COLOR_PALETTE.WHITE};
`;

const ProvidedFeedback = ({
  feedback,
  user,
  isActiv,
  shareFeedback,
  isProactive = false,
  dateStamp,
  isOutside = false,
  outsideFeedback,
  setOutsideFeedback,
  isAskRTFB,
}) => {
  const { i18n } = useLingui();
  const [currentFeedback, setCurrentFeedback] = useState(feedback || '');
  const userId = get(user, 'id'); // to prevent crash, if user does not exist

  return (
    <ProvidedFeedbackWrap>
      <SubFBWrap>
        <FeedbackBlockWrapper $isProactive={isProactive} $isWithoutBG={isAskRTFB}>
          {isEmpty(feedback) || isActiv ? (
            <StyledEditor
              small={true}
              compact={true}
              value={isOutside ? outsideFeedback : currentFeedback}
              onChange={isOutside ? setOutsideFeedback : setCurrentFeedback}
              placeholder={i18n._(t`Type your feedback...`)}
            />
          ) : (
            <FeedbackBlock $background={!isProactive && !isAskRTFB} $isAskRTFB={isAskRTFB}>
              <ShowMore maxHeight={78}>
                <UserFeedback dangerouslySetInnerHTML={{ __html: sanitizeHtml(feedback) }} />
              </ShowMore>
              <Date>{convertToTimeString(dateStamp, TIME_FORMATS.CLASSIC)}</Date>
            </FeedbackBlock>
          )}
          {!(isProactive || isActiv || isOutside) && (
            <StyledAvatarCard type="small" size={28} userId={userId} />
          )}
        </FeedbackBlockWrapper>
      </SubFBWrap>
      {(isEmpty(feedback) || isActiv) && !isOutside && (
        <StyledButton
          label={i18n._(t`Share`)}
          onClick={() => shareFeedback(currentFeedback, user)}
          width={73}
          height={24}
          disabled={isEmpty(currentFeedback)}
        />
      )}
    </ProvidedFeedbackWrap>
  );
};

export default React.memo(ProvidedFeedback);
