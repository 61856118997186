import React, { FunctionComponent, useMemo } from 'react';

import { ACTIVITY_STATUSES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import styled from 'styled-components';

import RickTextView from '~/components/RickTextView';
import ShowMore from '~/components/ShowMore';
import SvgIcon from '~/components/SvgIcon';
import Tooltip from '~/components/Tooltip';

import ActivityRow from './ActivityRow';

import arrowLeft from '~/assets/drop-down-left.svg';
import arrowDown from '~/assets/ic-arrow-drop-down-down.svg';
import LockedIcon from '~/assets/locked-state.svg';

import { COLORS, COLOR_PALETTE, COLOR_SET } from '~/styles';

import type { IUserActivity } from '@learned/types';

const NameRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ProgressRow = styled.div<{
  $isProblemSection: boolean;
}>`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.13px;
  color: ${(props) => (props.$isProblemSection ? '#ff5252' : '#6d7aa6')};
`;

const RichTextViewWrapper = styled(RickTextView)`
  color: ${COLORS.TEXT_BLACK};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.13px;
`;

const SectionName = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_BLACK};
`;

const Line = styled.div<{
  top?: string;
  bottom?: string;
  color?: string;
}>`
  position: absolute;
  width: 1px;
  background-color: ${({ color }) => color || COLOR_PALETTE.DARK_GRAY};
  left: 0;
  top: ${({ top }) => top || 'unset'};
  bottom: ${({ bottom }) => bottom || 'unset'};
  box-sizing: border-box;
`;

const SectionNameRow = styled.div`
  margin: 0 20px;
  padding: 16px 0 16px 20px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
`;

const StyledArrowSvgIcon = styled(SvgIcon)`
  margin-top: 7px;
`;

const StyledSvgIconWrapper = styled.div`
  position: absolute;
  top: 21px;
  left: -6.5px;
`;

const Wrapper = styled.div``;

interface ISection {
  isFirst: boolean;
  isLast: boolean;
  section: { name: string; description: string; order: number };
  sections: { name: string; description: string; order: number }[];
  isOpen: boolean;
  onSectionClick: () => void;
  onActivityClick: (activityId: IUserActivity['id']) => void;
  activities: IUserActivity[];
  activitiesProps: {
    order: number;
    isObligated: boolean;
    section: number;
    id: string;
    userActivity: IUserActivity;
  }[];
  activeActivityId: string;
  isOriginalPathWithoutSubPath: boolean;
}

const Section: FunctionComponent<ISection> = ({
  isFirst,
  isLast,
  section,
  activities,
  activitiesProps,
  isOpen,
  onActivityClick,
  activeActivityId,
  sections: sectionsActivities,
  onSectionClick,
  isOriginalPathWithoutSubPath,
}) => {
  const { i18n } = useLingui();

  const sectionActivities = _.filter(activitiesProps, (a) => a.section === section.order)
    .map((a) => {
      const userActivity = activities.find(
        (act) => act.originalActivity === a.id || act.id === a.id,
      );
      a.userActivity = userActivity as IUserActivity;
      return a;
    })
    .filter((a) => !_.isEmpty(a.userActivity))
    .sort((a, b) => a.order - b.order);

  const obligatedActiveActivity = activitiesProps.find((a) => {
    if (!a.isObligated || _.isEmpty(a.userActivity)) {
      return false;
    }

    const userActivity = activities.find((act) => act.originalActivity === a.id || act.id === a.id);
    return (userActivity as IUserActivity)?.status !== ACTIVITY_STATUSES.COMPLETED;
  });

  const obligatedSection =
    !_.isEmpty(obligatedActiveActivity) && !isOriginalPathWithoutSubPath
      ? sectionsActivities.find((s) => s.order === obligatedActiveActivity.section)
      : null;
  const isReadOnly = !_.isEmpty(obligatedSection) && obligatedSection.order < section.order;

  let progressSum = 0;
  let isProblemSection = false;
  sectionActivities.forEach((a) => {
    if (a?.userActivity?.progress) {
      progressSum = progressSum + a.userActivity.progress;
    }
    if (a?.userActivity?.status === ACTIVITY_STATUSES.PROBLEM) {
      isProblemSection = true;
    }
  });

  progressSum = Math.round(progressSum / sectionActivities.length);

  const bucketForActivities = useMemo(() => {
    return _.groupBy(activitiesProps, 'section');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify({ activitiesProps })]);

  const getLineColors = (
    sectionsActivities: Array<{ order: number; userActivity: IUserActivity }>,
    index: number,
  ) => {
    let topLineColor: string | undefined = COLOR_PALETTE.DARK_GRAY;
    let bottomLineColor: string | undefined = COLOR_PALETTE.DARK_GRAY;

    if (isReadOnly || isOriginalPathWithoutSubPath) {
      return {
        topLineColor,
        bottomLineColor,
      };
    }

    if (index === 0) {
      if (isFirst) {
        // the first activity of first section
        if (sectionsActivities[index].userActivity.status === ACTIVITY_STATUSES.COMPLETED) {
          topLineColor = COLOR_PALETTE.BLUE_DARK;
        } else {
          topLineColor = COLOR_PALETTE.DARK_GRAY;
        }
      } else {
        // the first activity of sections and the last activity of previous section is completed.
        if (
          _.last(bucketForActivities[section.order - 1])?.userActivity?.status ===
            ACTIVITY_STATUSES.COMPLETED &&
          sectionsActivities[index].userActivity.status === ACTIVITY_STATUSES.COMPLETED
        ) {
          topLineColor = COLOR_PALETTE.BLUE_DARK;
        } else {
          topLineColor = COLOR_PALETTE.DARK_GRAY;
        }
      }
    }

    if (index) {
      switch (sectionsActivities[index].userActivity.status) {
        case ACTIVITY_STATUSES.COMPLETED:
          // the activity is completed and the previous activity is completed too.
          if (sectionsActivities[index - 1].userActivity.status === ACTIVITY_STATUSES.COMPLETED) {
            topLineColor = COLOR_PALETTE.BLUE_DARK;
          } else {
            topLineColor = COLOR_PALETTE.DARK_GRAY;
          }
          break;
        case ACTIVITY_STATUSES.ON_TRACK:
        case ACTIVITY_STATUSES.TODO:
        case ACTIVITY_STATUSES.IN_PROGRESS:
        case ACTIVITY_STATUSES.PROBLEM:
        default:
          topLineColor = COLOR_PALETTE.DARK_GRAY;
          break;
      }
    }

    if (sectionsActivities.length - 1 > index) {
      switch (sectionsActivities[index].userActivity.status) {
        case ACTIVITY_STATUSES.COMPLETED:
          // the activity is completed and the next activity is completed too.
          if (sectionsActivities[index + 1].userActivity.status === ACTIVITY_STATUSES.COMPLETED) {
            bottomLineColor = COLOR_PALETTE.BLUE_DARK;
          } else {
            bottomLineColor = COLOR_PALETTE.DARK_GRAY;
          }
          break;
      }
    }

    if (sectionsActivities.length - 1 === index) {
      // last activity of last section
      if (isLast) {
        bottomLineColor = undefined;
      } else {
        // last activity of other sections and the first activity of the next section is completed
        if (
          bucketForActivities[section.order + 1][0].userActivity?.status ===
            ACTIVITY_STATUSES.COMPLETED &&
          sectionsActivities[index].userActivity.status === ACTIVITY_STATUSES.COMPLETED
        ) {
          bottomLineColor = COLOR_PALETTE.BLUE_DARK;
        } else {
          bottomLineColor = COLOR_PALETTE.DARK_GRAY;
        }
      }
    }

    return { topLineColor, bottomLineColor };
  };

  const lineColor = useMemo(() => {
    if (
      (!isReadOnly &&
        progressSum > 0 &&
        // the last activity of pervious section is completed
        !isFirst &&
        _.last(bucketForActivities[section.order - 1])?.userActivity?.status ===
          ACTIVITY_STATUSES.COMPLETED) ||
      // the activity is completed and last activity of pervious section is completed
      (!isFirst &&
        _.first(bucketForActivities[section.order])?.userActivity?.status ===
          ACTIVITY_STATUSES.COMPLETED &&
        _.last(bucketForActivities[section.order - 1])?.userActivity?.status ===
          ACTIVITY_STATUSES.COMPLETED) ||
      // the first activity of first section is completed
      (isFirst &&
        _.first(bucketForActivities[section.order])?.userActivity?.status ===
          ACTIVITY_STATUSES.COMPLETED)
    ) {
      return COLOR_PALETTE.BLUE_DARK;
    } else {
      return COLOR_PALETTE.DARK_GRAY;
    }
  }, [bucketForActivities, isFirst, isReadOnly, progressSum, section.order]);

  return (
    <Wrapper>
      {/* @ts-ignore */}
      <SectionNameRow onClick={onSectionClick}>
        <Line color={lineColor} top={isFirst ? '0' : '-6px'} bottom="0" />
        {isReadOnly && (
          /* @ts-ignore */
          <Tooltip
            tooltip={i18n._(
              t`This section is locked. Please complete your mandatory activities from the previous section to continue`,
            )}
          >
            <StyledSvgIconWrapper>
              <SvgIcon
                isDefaultColor
                defaultColor={COLOR_SET.MIDDLE_GRAY_BLUE}
                width="14px"
                height="14px"
                url={LockedIcon}
              />
            </StyledSvgIconWrapper>
          </Tooltip>
        )}
        <NameRow>
          <SectionName>
            {section.order + 1}. {section.name}
          </SectionName>
          <StyledArrowSvgIcon
            url={isOpen ? arrowDown : arrowLeft}
            width={isOpen ? '10px' : '11px'}
            height={isOpen ? '6px' : '11px'}
            isDefaultColor
            defaultColor={COLOR_SET.MIDDLE_GRAY_BLUE}
          />
        </NameRow>
        {section.description && (
          // @ts-ignore
          <ShowMore maxHeight={84} showMoreClassName="show-more-path">
            <RichTextViewWrapper html={section.description} />
          </ShowMore>
        )}
        {!isOpen && (
          <ProgressRow $isProblemSection={isProblemSection}>
            {progressSum > 0
              ? i18n._(t`${sectionActivities.length} activities ${progressSum}% progress`)
              : i18n._(t`${sectionActivities.length} activities`)}
          </ProgressRow>
        )}
      </SectionNameRow>
      {isOpen && (
        <>
          {sectionActivities.map((activity, index) => {
            return (
              <ActivityRow
                key={activity.id as string}
                activity={activity.userActivity}
                isObligated={activity.isObligated}
                isReadOnly={isReadOnly}
                topLineColor={getLineColors(sectionActivities, index).topLineColor}
                bottomLineColor={getLineColors(sectionActivities, index).bottomLineColor}
                isActive={activeActivityId === activity.userActivity?.id}
                onActivityClick={() => onActivityClick(activity.userActivity?.id as string)}
              />
            );
          })}
        </>
      )}
    </Wrapper>
  );
};

export default Section;
