import { useEffect, useState } from 'react';

import { useParams } from 'react-router';

import { getSkillTemplateSuperAdmin } from '~/services/skillTemplates';

import type { ISkillTemplate } from '@learned/types';

const useSkillTemplate = () => {
  const params: Record<string, string | undefined> = useParams();
  const skillTemplateId = params.skillTemplateId;
  const [skillTemplate, setSkillTemplate] = useState<ISkillTemplate>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);

    async function fetchSkillTemplate() {
      if (!skillTemplateId) {
        return;
      }

      const response = await getSkillTemplateSuperAdmin(skillTemplateId);
      if (!mounted || response.code !== 200) {
        setIsLoading(false);
        return;
      }

      setSkillTemplate(response.data.skillTemplate);
      setIsLoading(false);
    }

    fetchSkillTemplate();

    return () => void (mounted = false);
  }, [skillTemplateId]);

  return { skillTemplate, isLoading };
};

export { useSkillTemplate };
