import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { AxiosResponse } from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import { Loader } from '~/components/Buttons/components/Loader';
import OverviewHeading from '~/components/OverviewHeading';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import BaseLayout from '~/layouts/BaseLayout';

import { MODAL_TYPE } from './constants';
import { BodyWrapper, LoadingContainer, Wrapper } from './JobLibrary.design';
import JobOptionMenu from './JobOptionMenu';
import JobProfiles from './JobProfiles';
import { TIntegrationSettings } from './types';

import { JOB_PROFILE_STATUSES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getCompanyIntegrationSettings } from '~/services/integrationSettings';
import { getJobFamilies } from '~/services/jobFamilies';
import { getJobLevelGroups } from '~/services/jobLevelGroups';
import { getJobProfiles } from '~/services/jobProfiles';
import { getCareerPlans } from '~/store/careerPlans/actions';
import { setJobFamilies } from '~/store/jobFamilies/actions';
import { setJobLevelGroups } from '~/store/jobLevelGroups/actions';
import { setJobProfiles } from '~/store/jobProfiles/actions';

import type { IJobFamily, IJobLevelGroup, IJobProfile } from '@learned/types';

const JobProfilesOverview = () => {
  const { i18n } = useLingui();
  const [openedModal, setOpenedModal] = useState<MODAL_TYPE | null>(null);
  const [opened, setOpened] = useState(false);
  const company = useSelector(getCurrentCompany);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const $loading = useBoolState(true);
  const [settings, setSettings] = useState<{ [key: string]: TIntegrationSettings }>({});

  const initializeData = async () => {
    try {
      $loading.set(true);
      const [jobProfiles, jobLevelGroups, jobFamilies, integrationSettings] = await Promise.all([
        getJobProfiles(
          { status: JOB_PROFILE_STATUSES.ACTIVE.key },
          {
            projection: {
              name: 1,
              jobFamily: 1,
              jobLevelGroup: 1,
              meta: 1,
              status: 1,
              externalId: 1,
              externalSource: 1,
              company: 1,
              createdBy: 1,
            },
          },
        ) as Promise<{ [key: string]: IJobProfile }[]>,
        getJobLevelGroups() as Promise<
          AxiosResponse<{ jobLevelGroups: { [key: string]: IJobLevelGroup } }>
        >,
        getJobFamilies() as Promise<AxiosResponse<{ jobFamilies: { [key: string]: IJobFamily } }>>,
        getCompanyIntegrationSettings() as Promise<{ [key: string]: TIntegrationSettings }>,
      ]);
      setSettings(integrationSettings);
      dispatch(getCareerPlans());

      const jobProfileData = Object.values(jobProfiles || {});
      dispatch(setJobProfiles(jobProfileData));
      const jobLevelGroupsData = Object.values(jobLevelGroups?.data?.jobLevelGroups || {});
      dispatch(setJobLevelGroups(jobLevelGroupsData));
      const jobFamiliesData = Object.values(jobFamilies?.data?.jobFamilies || {});
      dispatch(setJobFamilies(jobFamiliesData));
    } catch (error) {
      addToast({
        title: i18n._(t`Something went wrong`),
        subtitle: i18n._(t`Something went wrong while fetching the data. Please try again later.`),
        type: TOAST_TYPES.ERROR,
      });
    } finally {
      $loading.set(false);
    }
  };

  useEffect(() => {
    if (company.id) {
      initializeData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.id]);

  return (
    <>
      <Wrapper expanded={false}>
        <OverviewHeading title={i18n._(t`Job profiles`)}>
          <JobOptionMenu
            setOpened={setOpened}
            opened={opened}
            setOpenedModal={setOpenedModal}
            openedModal={openedModal}
          />
        </OverviewHeading>
        <BaseLayout>
          {!$loading.value ? (
            <BodyWrapper>
              <JobProfiles settings={settings} />
            </BodyWrapper>
          ) : (
            <LoadingContainer>
              <Loader />
            </LoadingContainer>
          )}
        </BaseLayout>
      </Wrapper>
    </>
  );
};

export { JobProfilesOverview };
