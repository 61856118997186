import React, { useEffect, useMemo, useState } from 'react';
import type { Dispatch } from 'react';

import { Locals_all } from '@learned/constants';
import { IGeneratedFocusArea, IMultiLangString, ISkillCategory } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import nanoid from 'nanoid';
import { DragDropContext } from 'react-beautiful-dnd';
import { useFieldArray, UseFormReturn, useWatch } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';

import { FocusAreas } from './components/FocusAreas';
import { StepFooter } from './components/StepFooter';
import { Form, Title, Subtitle, Levels, ActionContainerHeader, FocusAreaHeader } from './design';

import { FocusArea } from '~/constants/focusAreas';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { generateSkillFocusAreas, generateTextTranslation } from '~/services/completions';

import type { IGeneralForm } from './types';

type StepFocusAreasProps = {
  setCurrentSection: Dispatch<number>;
  formMethods: UseFormReturn<IGeneralForm>;
  skillCategory?: ISkillCategory;
  languageState: ILanguageStateReturn;
  setMinHeight: (height: number) => void;
};

function StepFocusAreas({
  setCurrentSection,
  skillCategory,
  languageState,
  formMethods,
  setMinHeight,
}: StepFocusAreasProps) {
  const { handleSubmit, getValues, setValue, control, watch } = formMethods;
  const focusAreaLevels = getValues('focusAreas');
  const focusAreaLevelsWatch = useWatch({ control, name: 'focusAreas' });
  const { i18n } = useLingui();

  const [isGenerationLoading, setIsGenerationLoading] = useState(false);
  const [isTranslationLoading, setIsTranslationLoading] = useState(false);

  const onSubmit = () => setCurrentSection(2);

  const skillName = watch('name')?.find(
    (name) => name.locale === languageState.companyPrimaryLanguage.locale,
  );

  const { replace } = useFieldArray({
    control,
    name: 'focusAreas',
  });

  const moveItem = ({
    sourceId,
    targetId,
    sourceIndex,
    targetIndex,
  }: {
    sourceId: string;
    targetId?: string;
    sourceIndex: number;
    targetIndex?: number;
  }) => {
    if (targetId === undefined || targetIndex === undefined) {
      return;
    }

    const sourceFocusAreaIndex = parseInt(sourceId, 10);
    const targetFocusAreaIndex = parseInt(targetId, 10);
    if (sourceId === targetId) {
      const fieldsCopy = getValues(`focusAreas.${sourceFocusAreaIndex}.values`);
      const [removed] = fieldsCopy.splice(sourceIndex, 1);
      fieldsCopy.splice(targetIndex, 0, removed);
      setValue(`focusAreas.${sourceFocusAreaIndex}.values`, fieldsCopy);
    } else {
      const sourceFields = getValues(`focusAreas.${sourceFocusAreaIndex}.values`);
      const targetFields = getValues(`focusAreas.${targetFocusAreaIndex}.values`);
      const [removed] = sourceFields.splice(sourceIndex, 1);
      targetFields.splice(targetIndex, 0, removed);
      setValue(`focusAreas.${sourceFocusAreaIndex}.values`, sourceFields);
      setValue(`focusAreas.${targetFocusAreaIndex}.values`, targetFields);
    }
  };

  const [openedFocusAreaLevel, setOpenedFocusAreaLevel] = useState<number>();

  const levelWithMostFocusAreas = useMemo(() => {
    return focusAreaLevels.reduce((acc: number, { values }) => {
      if (values.length > acc) {
        return values.length;
      }

      return acc;
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusAreaLevels, focusAreaLevelsWatch]);

  useEffect(() => {
    const levelHeaderHeight = 53;
    const focusAreaHeight = 53;
    const buttonHeight = 51;
    setMinHeight(
      focusAreaLevels.length * levelHeaderHeight +
        (levelWithMostFocusAreas * focusAreaHeight + buttonHeight),
    );
  }, [focusAreaLevels, levelWithMostFocusAreas, setMinHeight]);

  const onGenerateFocusAreas = async () => {
    if (skillCategory?.id && skillName) {
      setIsGenerationLoading(true);

      const response = await generateSkillFocusAreas(
        skillName.value,
        skillCategory?.id,
        languageState.companyPrimaryLanguage.locale as Locals_all,
      );
      const focusAreas = (response.data?.focusAreas as IGeneratedFocusArea[]) || [];

      const newFocusAreas: FocusArea[] = watch('focusAreas');

      focusAreas.forEach((focusAreaItem, index) => {
        newFocusAreas[index].level = focusAreaItem.level;
        newFocusAreas[index].values = [];

        new Array(focusAreaItem.values.length).fill(true).map(() =>
          newFocusAreas[index].values.push({
            name: {},
            _id: nanoid(),
          }),
        );

        focusAreaItem.values.forEach((value, i) => {
          newFocusAreas[index].values[i].name = {};

          languageState.companyLanguages.map((language) => {
            if (language.locale === languageState.companyPrimaryLanguage.locale) {
              newFocusAreas[index].values[i].name[language.locale] = value;
            } else {
              newFocusAreas[index].values[i].name[language.locale] = '';
            }
          });
        });
      });

      replace(newFocusAreas);
      setIsGenerationLoading(false);
    }
  };

  const onTranslate = async () => {
    setIsTranslationLoading(true);

    const newFocusAreas: FocusArea[] = watch('focusAreas');

    // Getting strings from Primary Locale fields
    const focusAreaNames = newFocusAreas.flatMap((item) =>
      item.values.map((value) => value.name[languageState.companyPrimaryLanguage.locale]),
    );

    if (focusAreaNames.length === 0) {
      setIsTranslationLoading(false);
      return;
    }

    const response: {
      data: {
        translatedTexts: IMultiLangString[];
      };
    } = await generateTextTranslation(
      focusAreaNames,
      languageState.companyLanguages
        .filter((language) => language.locale !== languageState.companyPrimaryLanguage.locale)
        .map((item) => item.locale),
    );

    // Iterate over focusAreaNames and values to update translations
    let counter = 0;
    newFocusAreas.map((item, i) => {
      item.values.map((value, j) => {
        const newValue = value;

        languageState.companyLanguages.map((language) => {
          if (language.locale !== languageState.companyPrimaryLanguage.locale) {
            newValue.name[language.locale] =
              response.data.translatedTexts[counter][language.locale];
          }
        });

        counter++;
        item.values[j] = newValue;
      });
      newFocusAreas[i] = item;
    });

    replace(newFocusAreas);
    setIsTranslationLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmit)}>
      <FocusAreaHeader>
        <Title>
          <Trans>Levels & focus areas</Trans>
        </Title>
        <ActionContainerHeader>
          <Button
            className={'buttons'}
            type={'button'}
            label={i18n._(t`Translate`)}
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.SECONDARY}
            isLoading={isTranslationLoading}
            onClick={() => onTranslate()}
            disabled={isTranslationLoading || isGenerationLoading}
          />
          <Button
            className={'buttons'}
            type={'button'}
            label={i18n._(t`Generate focus areas`)}
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.SECONDARY}
            isLoading={isGenerationLoading}
            onClick={() => onGenerateFocusAreas()}
            disabled={isGenerationLoading}
          />
        </ActionContainerHeader>
      </FocusAreaHeader>

      <Subtitle>
        <Trans>
          A description of what characteristics an employee needs to meet. Add or remove levels if
          necessary.
        </Trans>
      </Subtitle>

      {!skillCategory && (
        <Trans>No category selected, go back to step 1 and select a category first</Trans>
      )}

      {!isGenerationLoading && !isTranslationLoading && skillCategory && (
        <Levels>
          <DragDropContext
            onDragEnd={(result) => {
              const sourceId = result.source.droppableId;
              const targetId = result.destination?.droppableId;

              const sourceIndex = result.source.index;
              const targetIndex = result.destination?.index;
              moveItem({ sourceId, targetId, sourceIndex, targetIndex });
            }}
          >
            {skillCategory.skillLevels.map((levelName, index) => (
              <FocusAreas
                formMethods={formMethods}
                levelName={levelName}
                key={index}
                index={index}
                languageState={languageState}
                setOpenedFocusAreaLevel={setOpenedFocusAreaLevel}
                isForceClosed={openedFocusAreaLevel !== index}
              />
            ))}
          </DragDropContext>
        </Levels>
      )}

      <StepFooter hideSeparator onPrev={() => setCurrentSection(0)} />
    </Form>
  );
}

export { StepFocusAreas };
