import React from 'react';

import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import List from './List';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DragDropList = ({
  items,
  onChange,
  isDragAreaEverywhere,
  isDragDisabled,
  hasCustomDragBehavior,
}) => {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const itemsReordered = reorder(items, result.source.index, result.destination.index);

    onChange(itemsReordered);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <List
              items={items}
              isDragAreaEverywhere={isDragAreaEverywhere}
              isDragDisabled={isDragDisabled}
              hasCustomDragBehavior={hasCustomDragBehavior}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DragDropList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      item: PropTypes.object,
      content: PropTypes.node,
    }),
  ),
  onChange: PropTypes.func,
  isDragAreaEverywhere: PropTypes.bool,
  isDragDisabled: PropTypes.bool,
};

export default React.memo(DragDropList);
