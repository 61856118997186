import { t } from '@lingui/macro';

import { QUESTION_TYPES } from '~/constants/questionsTypes';

import type { I18n } from '@lingui/core';

export const preparePlaceholder = ({
  i18n,
  questionType,
  isKpiType,
}: {
  i18n: I18n;
  questionType: QUESTION_TYPES;
  isKpiType: boolean;
}) => {
  // skill category
  if (questionType === QUESTION_TYPES.SKILL_CATEGORY) {
    if (isKpiType) {
      return i18n._(t`Example: How did you/this employee perform on the KPI?`);
    } else {
      return i18n._(t`Example: How often do you/this employee show the expected behavior?`);
    }
  }

  if (questionType === QUESTION_TYPES.CUSTOM_SKILL) {
    return i18n._(t`Example: How often do you/this employee show the expected behavior?`);
  }

  // goal plan
  if (questionType === QUESTION_TYPES.GOAL_PLAN) {
    return i18n._(
      t`Example: What personal goals will you/employee work on in the upcoming period?`,
    );
  }

  // default
  return i18n._(
    t`Example: Are you on track to meet your Key Performance Indicators for this year?`,
  );
};

export const prepareDefaultValue = ({
  questionType,
  isKpiType,
  messages,
}: {
  questionType: QUESTION_TYPES;
  isKpiType: boolean;
  currentValue: string;
  messages: {
    behavior: string;
    kpi: string;
    goal: string;
  };
}) => {
  // custom skill
  if (questionType === QUESTION_TYPES.CUSTOM_SKILL) {
    return messages.behavior;
  }

  // skill category
  if (questionType === QUESTION_TYPES.SKILL_CATEGORY) {
    if (isKpiType) {
      return messages.kpi;
    } else {
      return messages.behavior;
    }
  }

  // goal plan
  if (questionType === QUESTION_TYPES.GOAL_PLAN) {
    return messages.goal;
  }

  // default
  return '';
};
