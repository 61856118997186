import React, { useContext } from 'react';

import { LUCA_INSIGHT_STATUS, REPORT_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Content } from './LucaInsights.design';

import { EngagementReportContext } from '../EngagementContext';

type TProps = {
  content: string | null;
  status: LUCA_INSIGHT_STATUS;
};

function LucaInsights({ content, status }: TProps): JSX.Element {
  const { reportType } = useContext(EngagementReportContext);
  const { i18n } = useLingui();

  if (status === LUCA_INSIGHT_STATUS.NO_DATA) {
    return (
      <>
        <Content>
          {i18n._(t`There is no data to analyse yet. Start your first survey with Learned!`)}
        </Content>
        <Content>
          {reportType === REPORT_TYPES.ENGAGEMENT
            ? i18n._(
                t`When the results are available, I will share my detailed analysis of employee engagement within your company and provide you with actionable recommendations.`,
              )
            : i18n._(
                t`When the results are available, I will share my detailed analysis of employee performance within your company and provide you with actionable recommendations.`,
              )}
        </Content>
      </>
    );
  }

  return <>{content && <Content>{content}</Content>}</>;
}

export { LucaInsights };
