import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

const Container = styled.div<{ margin?: string }>`
  background-color: ${COLORS.BG_ELEMENTS};
  border-radius: 6px;
  padding: 14px 21px;
  ${({ margin }) =>
    margin
      ? css`
          margin: ${margin};
        `
      : ''}
`;

const Text = styled.div`
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
`;

export { Container, Text };
