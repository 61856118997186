import React from 'react';

import { JOB_TEMPLATES_SORT_OPTIONS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import find from 'lodash/find';

import { NameRow } from './SuperAdminAllJobTemplates.design';

import type { IColumnTable } from '~/@types/table';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

import type { IJobFamily, IJobTemplate } from '@learned/types';
import type { I18n } from '@lingui/core';

export const JOB_TEMPLATES_COLUMNS: IColumnTable<IJobTemplate>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Jobs`),
    accessor: 'name',
    maxWidth: '30%',
    renderCell: (item) => {
      return <NameRow>{item.name.en_GB || item.name.nl_NL || find(item.name)}</NameRow>;
    },
    sortBy: {
      asc: {
        key: JOB_TEMPLATES_SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: JOB_TEMPLATES_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Job Family`),
    accessor: 'jobFamily',
    renderCell: (item) => {
      const jobFamily = item.jobFamily as IJobFamily;
      return (
        <NameRow>{jobFamily?.name.en_GB || jobFamily?.name.nl_NL || find(jobFamily?.name)}</NameRow>
      );
    },
    sortBy: {
      asc: {
        key: JOB_TEMPLATES_SORT_OPTIONS.JOB_FAMILY_NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: JOB_TEMPLATES_SORT_OPTIONS.JOB_FAMILY_NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Used`),
    accessor: 'used',
    renderCell: (item) => {
      return <NameRow>{item.used?.toFixed(2)}%</NameRow>;
    },
    sortBy: {
      asc: {
        key: JOB_TEMPLATES_SORT_OPTIONS.USED_LEAST_MOST,
        label: (i18n: I18n) => i18n._(t`Least - Most`),
      },
      desc: {
        key: JOB_TEMPLATES_SORT_OPTIONS.USED_MOST_LEAST,
        label: (i18n: I18n) => i18n._(t`Most -  Least`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Status`),
    maxWidth: '200px',
    renderCell: (item) => {
      return (
        <NameRow>
          <Trans>{item.status}</Trans>
        </NameRow>
      );
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Last Updated`),
    accessor: 'meta.lastModifiedDate',
    maxWidth: '200px',
    sortBy: {
      asc: {
        key: JOB_TEMPLATES_SORT_OPTIONS.CREATED_NEW_OLD,
        label: (i18n: I18n) => i18n._(t`New - old`),
      },
      desc: {
        key: JOB_TEMPLATES_SORT_OPTIONS.CREATED_OLD_NEW,
        label: (i18n: I18n) => i18n._(t`Old - new`),
      },
    },
    renderCell: (item) => {
      return (
        <NameRow>{convertToTimeString(item.meta?.lastModifiedDate, TIME_FORMATS.CLASSIC)}</NameRow>
      );
    },
  },
];
