import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { InlineButton } from '~/components/Buttons';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import type { ISurveyTemplate } from '@learned/types';

const Row = styled.div`
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border: 1px solid ${COLORS.BORDER_HARD};
  border-radius: 6px;
`;

const SubRow = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${COLORS.BLACK};
`;

const SubTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.17;
  letter-spacing: -0.13px;
  color: ${COLORS.MIDDLE_GRAY};
`;

interface ITemplateRow {
  item: ISurveyTemplate;
  onPreview: (item: ISurveyTemplate) => void;
}
const TemplateRow = ({ item, onPreview }: ITemplateRow) => {
  const { i18n } = useLingui();
  const getString = useMultiLangString();
  const questionsTotal = (item.questions || []).length;

  return (
    <Row>
      <div>
        <Title>{getString(item.name)}</Title>
        <SubTitle>{i18n._(t`Includes ${questionsTotal} questions`)}</SubTitle>
      </div>
      <SubRow>
        <InlineButton
          type="button"
          label={i18n._(t`Preview`)}
          onClick={() => onPreview(item)}
          fontSize={12}
        />
      </SubRow>
    </Row>
  );
};

export { TemplateRow };
