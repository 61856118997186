import React, { useState } from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import FeedbackIcon from '~/components/Icons/Feedback';
import UpdateRatingModal from '~/components/Modals/UpdateRatingModal';
import Placeholder from '~/components/Placeholder';
import SkillDefinitionModal from '~/components/SkillDefinitionModal';

import ProgressBarList from './ProgressBarList';
import QuestionDescription from './QuestionDescription';
import RateRow from './RateRow';
import RejectedRequest from './RejectedRequest';
import { getFeedbackRatings } from './utils';

import { RATING_TYPES, REQUEST_STATUSES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import getLang from '~/selectors/getLang';
import { COLOR_SET } from '~/styles';

const RatingsWrapper = styled.div`
  margin-top: 30px;
`;

const Wrapper = styled.div`
  padding: 0 0 21px;
  box-sizing: border-box;
`;

const StyledRateRow = styled(RateRow)`
  padding-top: 0;
`;

function Feedback({
  review,
  skill,
  question,
  questionText,
  i18n,
  hideSelf,
  hideCoaches,
  hidePeers,
  handleOnRatingUpdated,
  isLockedFeedback,
  viewerId,
  pdfView,
  users,
  goal,
}) {
  const lang = useSelector(getLang);
  const requests = get(review, 'requests', []);
  const rejectedRequests = requests.filter((r) => r.status === REQUEST_STATUSES.REJECTED.key);
  const showSkillModal = useBoolState();
  const $isUpdateRatingModal = useBoolState(false);
  const [selectedRating, setSelectedRating] = useState(null);

  // show only rates for shared requests
  const sharedRequestsUsers = requests
    .filter(
      (r) =>
        r.status === REQUEST_STATUSES.SHARED.key || r.status === REQUEST_STATUSES.CALIBRATE.key,
    )
    .map((r) => (r.toEmail ? r.toEmail : r.toUser));

  const feedbackRatings = getFeedbackRatings({
    ratings: review.ratings,
    reviewUser: review.createdFor,
    skill,
    goal,
    question,
    hideSelf,
    hidePeers,
    hideCoaches,
    sharedRequestsUsers,
  });

  let progressItems = [];

  const expectedLevel =
    question.type === QUESTION_TYPES.JOB_PROFILE &&
    review.skillsJobProfileLevels &&
    review.skillsJobProfileLevels[skill.id];

  const selfRatedRating =
    !hideSelf && feedbackRatings.find((r) => r.type === RATING_TYPES.SELF_RATING && r.rate !== 0);
  const peersRatedRatings =
    !hidePeers &&
    feedbackRatings.filter(
      (r) =>
        (r.type === RATING_TYPES.OTHER_RATING || r.type === RATING_TYPES.OUTSIDE_RATING) &&
        r.rate !== 0,
    );
  const coachesRatedRatings =
    !hideCoaches &&
    feedbackRatings.filter((r) => r.type === RATING_TYPES.COACH_RATING && r.rate !== 0);

  const scale = !isEmpty(feedbackRatings) ? feedbackRatings[0].scale : [];
  const scaleLabels = !isEmpty(feedbackRatings) ? feedbackRatings[0].scaleLabels : [];

  const avgRate = (ratings) => {
    return (
      Math.round(
        (ratings.reduce((acc, el) => {
          return acc + el.rate;
        }, 0) /
          ratings.length) *
          10,
      ) / 10
    );
  };

  if (!isEmpty(feedbackRatings) && expectedLevel) {
    const expectedLevelIdx = scale.indexOf(expectedLevel);
    const expectedProgress =
      expectedLevelIdx !== -1
        ? Math.round(((expectedLevelIdx + 1) / scale.length) * 100)
        : Math.round((expectedLevel / scale[scale.length - 1]) * 100);

    progressItems = [
      ...progressItems,
      {
        label: t`expected level`,
        color: COLOR_SET.CYAN_GREEN,
        progress: expectedProgress,
        scaleLabel: question.scaleLabels[Math.round(expectedLevel) - 1],
        rate: expectedLevel,
      },
    ];
  }

  if (!isEmpty(selfRatedRating)) {
    const selfRateIdx = scale.indexOf(selfRatedRating.rate);
    progressItems = [
      ...progressItems,
      {
        label: t`self review`,
        color: COLOR_SET.BLUE,
        progress: Math.round(((selfRateIdx + 1) / scale.length) * 100),
        scaleLabel: scaleLabels[selfRateIdx],
        rate: selfRatedRating.rate,
      },
    ];
  }

  if (!isEmpty(peersRatedRatings)) {
    const avgPeersRate = avgRate(peersRatedRatings);
    const avgPeersRateIndex = scale.indexOf(Math.round(avgPeersRate));

    progressItems = [
      ...progressItems,
      {
        label: t`Average peer review`,
        color: COLOR_SET.AMBER_YELLOW,
        progress: Math.round((avgPeersRate / scale[scale.length - 1]) * 100),
        scaleLabel: scaleLabels[avgPeersRateIndex],
        rate: avgPeersRate,
      },
    ];
  }

  if (!isEmpty(coachesRatedRatings)) {
    const avgCoachesRate = avgRate(coachesRatedRatings);
    const avgCoachesRateIndex = scale.indexOf(Math.round(avgCoachesRate));

    progressItems = [
      ...progressItems,
      {
        label: t`coach review`,
        color: COLOR_SET.PINK,
        progress: Math.round((avgCoachesRate / scale[scale.length - 1]) * 100),
        scaleLabel: scaleLabels[avgCoachesRateIndex],
        rate: avgCoachesRate,
      },
    ];
  }

  return (
    <Wrapper>
      {isEmpty(feedbackRatings) ? (
        <Placeholder
          Icon={FeedbackIcon}
          title={i18n._(t`Nothing here yet`)}
          subTitle={i18n._(t`No answers to this question yet`)}
        />
      ) : (
        <>
          <QuestionDescription
            type={question.type}
            description={question?.description}
            expectedLevel={expectedLevel}
            skill={skill}
            lang={lang}
            pdfView={pdfView}
            openSkillModal={showSkillModal.on}
            scaleLabels={scaleLabels}
            padding="0 20px 10px"
          />
          {!isEmpty(progressItems) && (
            <ProgressBarList
              items={progressItems}
              isWideLabel={lang === 'nl'}
              maxScale={scale[scale.length - 1]}
            />
          )}
          <RatingsWrapper>
            {feedbackRatings.map((r) => (
              <StyledRateRow
                rating={r}
                key={r.id}
                userId={r.type === RATING_TYPES.SELF_RATING ? review.createdFor : r.user}
                i18n={i18n}
                viewerId={viewerId}
                onUpdate={() => {
                  setSelectedRating(r);
                  $isUpdateRatingModal.on();
                }}
                isLockedFeedback={isLockedFeedback}
                pdfView={pdfView}
                users={users}
              />
            ))}
          </RatingsWrapper>
          {!isEmpty(rejectedRequests) &&
            rejectedRequests.map((r) => <RejectedRequest request={r} key={r.id} />)}
        </>
      )}
      {showSkillModal.value && (
        <SkillDefinitionModal
          onModalClose={showSkillModal.off}
          skill={skill}
          scaleLabels={question.scaleLabels}
          expectedLevel={expectedLevel}
        />
      )}
      {$isUpdateRatingModal.value && (
        <UpdateRatingModal
          review={review}
          rating={selectedRating}
          question={question}
          skill={skill}
          questionText={questionText}
          onClose={(updatedRating) => {
            $isUpdateRatingModal.off();
            // update rating in review (local)
            if (!isEmpty(updatedRating)) {
              handleOnRatingUpdated(updatedRating);
            }
          }}
        />
      )}
    </Wrapper>
  );
}

Feedback.propTypes = {
  review: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired,
  questionText: PropTypes.node,
  skill: PropTypes.object,
  goal: PropTypes.object,
  hideSelf: PropTypes.bool,
  hideCoaches: PropTypes.bool,
  hidePeers: PropTypes.bool,
  handleOnRatingUpdated: PropTypes.func,
  isLockedFeedback: PropTypes.bool,
};

export default withI18n()(Feedback);
