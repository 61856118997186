import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

const Container = styled.div``;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
  gap: 10px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
  align-items: center;
  border-width: 1px;
`;

const TitleContainer = styled.div`
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 26px;
  letter-spacing: -0.27px;
  color: ${COLORS.TEXT_HOVER};
`;

const SubTitle = styled.div`
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ItemBox = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
`;

const ItemTextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

const ItemActionBox = styled.div`
  align-content: center;
`;

const ItemTitle = styled.span`
  font-size: 16px;
  color: ${COLORS.TEXT_MAIN};
`;

const ItemDescription = styled.span`
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
`;

const FileBox = styled.div`
  margin-top: 21px;
  display: flex;
  flex-direction: row;
  background-color: ${COLORS.BG_LIST};
  padding: 14px;
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

const FileName = styled.span`
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
`;

export {
  Container,
  Footer,
  Header,
  Title,
  ActionContainer,
  SubTitle,
  TitleContainer,
  ItemTitle,
  ItemDescription,
  ItemBox,
  ItemTextBox,
  ItemActionBox,
  FileBox,
  FileName,
};
