import styled from 'styled-components';

export const RowItem = styled.div`
  margin-bottom: 0px;
  margin-right: 12px;
  width: 137px;
  & > div:first-child {
    width: 100%;
  }
`;
