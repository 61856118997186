import React, { useLayoutEffect, useRef, useState } from 'react';

import { Trans } from '@lingui/macro';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import size from 'lodash/size';
import { Controller, useFieldArray } from 'react-hook-form';

import { ICON_SIZES, ICONS, Icon } from '~/components/Icon';
import { Input } from '~/components/Input';
import {
  CategoryHeader,
  Level,
  LevelIndex,
  LevelInput,
  LevelName,
  Levels,
  ScrollWrapper,
} from '~/pages/SuperAdminDashboard/SuperAdminSkillMatrix/components/design';
import { StyledIcon } from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateForm/components/design';

import { SkillCategoryNameModal } from './SkillCategoryNameModal';

import type { IBaseLanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';
import { getSuperAdminMultiLangString } from '~/utils/superAdmin';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import type { IGeneralForm } from './types';
import type { ILanguageValue } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

function SkillCategoryLevel({
  formMethods,
  levelIndex,
  categoryIndex,
  languageState,
}: {
  languageState: IBaseLanguageStateReturn;
  formMethods: UseFormReturn<IGeneralForm>;
  levelIndex: number;
  categoryIndex: number;
}) {
  const { register, unregister, control } = formMethods;
  const nameFieldArray = useMultiLangFieldArray({
    name: `skillCategories.${categoryIndex}.skillLevels.${levelIndex}.name`,
    control,
    unregister,
    languageState,
  });

  function getPlaceholder(locale: string) {
    switch (locale) {
      case 'en_GB':
        return 'Novice';
      case 'nl_NL':
        return 'Beginner';
      case 'de_DE':
        return 'Neuling';
    }
  }

  return (
    <Level>
      <LevelIndex>
        <Trans>Level {levelIndex + 1}</Trans>
      </LevelIndex>

      <LevelInput>
        <LevelName>
          <Trans>Level name</Trans>
        </LevelName>

        {nameFieldArray.fields.map((field) => {
          return (
            <Controller
              key={field.id}
              {...register(
                `skillCategories.${categoryIndex}.skillLevels.${levelIndex}.name.${field.index}.value`,
              )}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    key={field.id}
                    value={value}
                    onChange={onChange}
                    placeholder={getPlaceholder(field.locale)}
                    width="281px"
                    leftIcon={
                      size(languageState.languages) > 1
                        ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                        : undefined
                    }
                    maxLength={60}
                  />
                );
              }}
            />
          );
        })}
      </LevelInput>
    </Level>
  );
}

function SkillCategoryLevels({
  categoryIndex,
  languageState,
  formMethods,
}: {
  categoryIndex: number;
  languageState: IBaseLanguageStateReturn;
  formMethods: UseFormReturn<IGeneralForm>;
}) {
  const ref = useRef<HTMLDivElement>();
  const { control, watch } = formMethods;
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const [opened, setOpened] = useState(false);
  const [editNameId, setEditNameId] = useState<number>();
  const skillLevelFields = useFieldArray({
    name: `skillCategories.${categoryIndex}.skillLevels`,
    control,
  });
  const name = watch(`skillCategories.${categoryIndex}.name`) as ILanguageValue[];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    setHasScrollbar(
      ref.current?.scrollWidth && ref.current?.clientWidth
        ? ref.current?.scrollWidth > ref.current?.clientWidth
        : false,
    );
  });

  const showEditNameModal = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setEditNameId(categoryIndex);
  };

  return (
    <div>
      <CategoryHeader onClick={() => setOpened((value) => !value)}>
        <span>
          {getSuperAdminMultiLangString(turnArrayIntoMultiLang(name), languageState)}
          <Icon icon={ICONS.EDIT_PENCIL} size={ICON_SIZES.SMALL} onClick={showEditNameModal} />
        </span>
        <StyledIcon icon={ICONS.DROPDOWN} size={ICON_SIZES.SMALL} $opened={opened} />
      </CategoryHeader>
      {opened && (
        <ScrollWrapper hasScrollbar={hasScrollbar}>
          {/* @ts-ignore */}
          <Levels ref={ref}>
            {skillLevelFields.fields.map((field, index) => {
              return (
                <SkillCategoryLevel
                  languageState={languageState}
                  formMethods={formMethods}
                  key={field.id}
                  categoryIndex={categoryIndex}
                  levelIndex={index}
                />
              );
            })}
          </Levels>
        </ScrollWrapper>
      )}
      {editNameId !== undefined && (
        <SkillCategoryNameModal
          categoryIndex={editNameId}
          formMethods={formMethods}
          languageState={languageState}
          onClose={() => {
            setEditNameId(undefined);
          }}
        />
      )}
    </div>
  );
}

export { SkillCategoryLevels };
