import styled from 'styled-components';

import CompanyAvatar from '~/components/CompanyAvatar';

import { COLORS } from '~/styles';

export const ReviewIntroWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 24px;
  border-radius: 6px;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
  background-color: ${COLORS.WHITE};
  max-height: calc(100vh - 200px);
  box-sizing: border-box;
  gap: 25px;
  overflow: hidden;
  margin-top: 24px;
  margin-bottom: 112px;
`;

export const StyledCompanyAvatar = styled(CompanyAvatar)`
  align-items: start;
  justify-content: start;
  margin: 0;
  margin-bottom: 32px;
`;

export const Text = styled.span`
  font-size: 14px;
  line-height: 1.86;
  color: ${COLORS.SUBTEXT};
  margin-bottom: 7px;
`;

export const Header = styled.div`
  display: flex;
  font-size: 24px;
  line-height: 1.08;
  color: ${COLORS.TEXT_HOVER};
`;

export const Description = styled.div`
  display: flex;
  padding: 14px 21px;
  border-radius: 6px;
  background-color: ${COLORS.BG_PAGE};
  font-size: 14px;
  line-height: 1.86;
  color: ${COLORS.TEXT_MAIN};
  overflow-y: auto;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-top: 23px;
  gap: 10px;
  border-top: solid 1px ${COLORS.BORDERS};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const PeerMessage = styled.div`
  margin-bottom: 16px;
`;
