export enum REVIEW_RATING_TYPE {
  SELF = 'self',
  PEER = 'peer',
  COACH = 'coach',
  PEER_EMAIL = 'peer-email',
}

export enum REVIEW_RATING_STATUS {
  TODO = 'todo',
  PUBLISHED = 'published',
  CALIBRATE = 'calibrate',
}
