import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import FocusLayout from '~/layouts/FocusLayout';
import { SurveyThemeView } from '~/pages/SurveyThemeView';
import { UpdateTheme } from '~/pages/UpdateTheme';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('themes', {
  routesPrivate: {
    user: [
      <PrivateRoute
        layout={FocusLayout}
        key={routes.THEME_UPDATE.routePath(ROLES.USER)}
        exact
        path={routes.THEME_UPDATE.routePath(ROLES.USER)}
        component={UpdateTheme}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        layout={FocusLayout}
        key={routes.THEME_VIEW.routePath(ROLES.USER)}
        exact
        path={routes.THEME_VIEW.routePath(ROLES.USER)}
        component={SurveyThemeView}
        allowedRoles={[ROLES.USER]}
      />,
    ],
  },
  routesPublic: [],
});
