import React from 'react';

import { REVIEW_THEME_STATUS, ROLES } from '@learned/constants';
import { IReviewTheme } from '@learned/types';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { UseFormReturn } from 'react-hook-form';

import { AutocompleteReviewThemes } from '~/components/AutocompleteFilters';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import type { IQuestionForm } from '~/pages/ReviewThemeSetup/types';

import { EditThemes } from './design';

import routes from '~/constants/routes';
import { COLORS } from '~/styles';

import { Label } from '../EditableQuestion/design';

interface IThemeSelectorProps {
  formMethods: UseFormReturn<IQuestionForm>;
  selectedTheme: IReviewTheme | undefined;
  onThemeChange: (theme: IReviewTheme) => void;
}

const ThemeSelector = ({ formMethods, selectedTheme, onThemeChange }: IThemeSelectorProps) => {
  const {
    formState: { errors },
  } = formMethods;

  return (
    <>
      <Label>
        <Trans>Select a theme for this question</Trans>*
      </Label>
      <AutocompleteReviewThemes
        onChange={onThemeChange}
        checkedList={selectedTheme ? [selectedTheme] : []}
        isSingleSelect
        styles={{
          width: '262px',
          border: errors.theme ? `${COLORS.ACCENT_ERROR} 1px solid` : '',
        }}
        status={REVIEW_THEME_STATUS.PUBLISHED}
        // @ts-ignore
        lastItem={
          <EditThemes onClick={() => routes.REVIEWS.go({ role: ROLES.USER }, { hash: 'themes' })}>
            <Icon icon={ICONS.EDIT_PENCIL} size={ICON_SIZES.SMALL} />
            <span className="title">{i18n._(t`Edit themes`)}</span>
          </EditThemes>
        }
      />
    </>
  );
};

export { ThemeSelector };
