import React, { useState } from 'react';

import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { SkillFocusAreas } from '~/components/GiveFeedback/components/Section/components/SkillFocusAreas';
import ExpandMoreIcon from '~/components/Icons/ArrowButtonTall';
import RatingScale from '~/components/RatingScale';
import RickTextView from '~/components/RickTextView';
import ShowMore from '~/components/ShowMore';

import { InfoBlock, Title } from './styledComponents';

import useBoolState from '~/hooks/useBoolState';
import getLang from '~/selectors/getLang';
import { COLOR_PALETTE } from '~/styles';
import { getFocusAreaValuesByLevels } from '~/utils/focusAreas';
import { getSkillDescription } from '~/utils/skillUtils';

const FocusAreaWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: -16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const RatingWrapp = styled.div`
  margin-top: ${(props) => (props.$isExpectedLevel ? '44px' : '10px')};
`;

const StyledRickTextView = styled(RickTextView)`
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const SkillFocusAreasBlock = ({ skill, scaleLabels, expectedLevel, isProfile }) => {
  const lang = useSelector(getLang);
  const $isExpanded = useBoolState(!isProfile);
  const [hover, setHover] = useState(undefined);

  const renderSkill = () => {
    const scale = [];
    const labels = [];
    for (let i = 0; i < skill.levelsEnabled.length; i += 1) {
      if (skill.levelsEnabled[i]) {
        scale.push(i + 1);
        labels.push(scaleLabels[i]);
      }
    }
    return (
      <RatingWrapp $isExpectedLevel={!!expectedLevel}>
        <RatingScale
          selectedRate={null}
          scale={scale}
          scaleLabels={labels}
          isBig
          onChangeHoverLevel={setHover}
          isSingleHover={true}
          expectedRate={expectedLevel}
        />
      </RatingWrapp>
    );
  };

  const getFocusAreas = () => {
    const focusAreas = getFocusAreaValuesByLevels(skill.focusAreas, hover);

    return (
      <FocusAreaWrapper>
        <SkillFocusAreas focusAreas={focusAreas} />
      </FocusAreaWrapper>
    );
  };

  return (
    <InfoBlock>
      <Header onClick={() => ($isExpanded.value ? $isExpanded.off() : $isExpanded.on())}>
        <Title>
          <Trans>Skill info</Trans>
        </Title>
        {$isExpanded.value ? (
          <ExpandMoreIcon size={14} style={{ transform: 'rotate(180deg)' }} />
        ) : (
          <ExpandMoreIcon size={14} />
        )}
      </Header>
      <ShowMore maxHeight={50}>
        <StyledRickTextView html={getSkillDescription(skill, lang)} />
      </ShowMore>
      {$isExpanded.value && (
        <div>
          {renderSkill()}
          {!!hover && skill.focusAreas && getFocusAreas()}
        </div>
      )}
    </InfoBlock>
  );
};

export { SkillFocusAreasBlock };
