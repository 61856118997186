import styled from 'styled-components';

import { Dropdown } from '~/components/Dropdown';
import BoxWithBorder from '~/components/UI/BoxWithBorder';

import { DropDownProps } from './types';

import { COLORS } from '~/styles';

export const ArrowContainer = styled.div<{ justifyContent?: string; showCount?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin-right: ${(props) => (props.showCount ? '120px' : '0')};
`;

export const Arrow = styled.div<{ $isClickable: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;

  & > svg > path {
    ${(props) =>
      props.$isClickable ? `stroke: ${COLORS.TEXT_MAIN};` : `stroke: ${COLORS.BORDERS};`}
  }

  & > svg:hover {
    & > path {
      ${(props) => props.$isClickable && `stroke: ${COLORS.COMPANY};`}
    }
  }

  &:hover {
    ${(props) => props.$isClickable && 'cursor: pointer;'}
  }
`;

export const ArrowSingle = styled.div<{ $isClickable: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;

  & > svg:hover {
    ${(props) => props.$isClickable && `color: ${COLORS.COMPANY};`}
  }

  &:hover {
    ${(props) => props.$isClickable && 'cursor: pointer;'}
  }
`;

export const PageNumber = styled.div`
  margin-right: 8px;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.12px;
  color: ${COLORS.TEXT_HOVER};
`;

export const ItemCount = styled.div`
  margin-right: auto;
  min-width: 120px;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.SUBTEXT};
`;

export const BarContainer = styled(BoxWithBorder)<{
  $noTopBorder?: boolean;
  $noBorder?: boolean;
  $noShadow?: boolean;
}>`
  display: flex;
  padding: 16px;
  flex-direction: row;
  align-content: center;
  align-items: center;
  height: 51px;
`;

export const NumbersDropdown = styled(Dropdown)<DropDownProps>`
  width: 120px;
  min-width: 120px; // without this width does below 120px

  & > div {
    ::before {
      background-color: transparent;
    }

    border: none;
    background-color: transparent;
  }

  & > div:hover {
    border: 1px solid ${COLORS.BORDERS};
    background-color: ${COLORS.HOVER};
  }
`;
