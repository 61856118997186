import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { DatePicker } from '~/components/DatePicker';
import { ICONS, Icon } from '~/components/Icon';
import { Input } from '~/components/Input';
import Modal from '~/components/Modal';
import { ModalBody, ModalFooter } from '~/pages/PassportPage/design';
import { FormDataType } from '~/pages/PassportPage/types';

import useBoolState from '~/hooks/useBoolState';

import type {
  FieldErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

interface IProps {
  onClose: () => void;
  onSave: (formData: FormDataType) => void;
  submit: UseFormHandleSubmit<FormDataType>;
  register: UseFormRegister<FormDataType>;
  getValues: UseFormGetValues<FormDataType>;
  setValue: UseFormSetValue<FormDataType>;
  errors: FieldErrors<FormDataType>;
}

const DateEditModal = ({
  onClose,
  onSave,
  submit,
  register,
  getValues,
  setValue,
  errors,
}: IProps) => {
  const { i18n } = useLingui();

  const $isShowCalendarForStartDate = useBoolState(false);
  const $isShowCalendarForEndDate = useBoolState(false);

  const handleCloseJoinDate = (date?: string | null) => {
    $isShowCalendarForStartDate.off();
    date ? setValue('startDate', moment(date).format('DD-MM-YYYY')) : '';
  };

  const handleCloseEndDate = (date?: string | null) => {
    $isShowCalendarForEndDate.off();
    setValue('endDate', date ? moment(date).format('DD-MM-YYYY') : '');
  };

  return (
    <Modal
      contentStyles={{ padding: '30px' }}
      headerStyles={{ padding: '27px', fontSize: '26px' }}
      width={500}
      hideFooter
      showDivider={false}
      onClose={onClose}
      title={i18n._(t`Edit job dates`)}
    >
      <ModalBody>
        <div>
          <Input
            label={i18n._(t`Start date`)}
            placeholder={i18n._(t`Select date`)}
            leftIcon={<Icon icon={ICONS.CALENDAR} />}
            register={register('startDate', {
              required: { value: true, message: i18n._(t`Start date is required`) },
            })}
            onClick={$isShowCalendarForStartDate.on}
            error={errors.startDate?.message}
          />
          {$isShowCalendarForStartDate.value && (
            <DatePicker
              onClose={handleCloseJoinDate}
              // @ts-ignore
              initialDate={moment(getValues('startDate'), 'DD-MM-YYYY')}
            />
          )}
        </div>
        <div>
          <Input
            label={i18n._(t`End date`)}
            placeholder={i18n._(t`Select date`)}
            leftIcon={<Icon icon={ICONS.CALENDAR} />}
            register={register('endDate', {
              validate: (value) => {
                if (
                  value &&
                  moment(value, 'DD-MM-YYYY').isBefore(moment(getValues('startDate'), 'DD-MM-YYYY'))
                ) {
                  return i18n._(t`End date must be after start date`);
                }
                return true;
              },
            })}
            error={errors.endDate?.message}
            onClick={$isShowCalendarForEndDate.on}
          />
          {$isShowCalendarForEndDate.value && (
            <DatePicker
              onClose={handleCloseEndDate}
              // @ts-ignore
              initialDate={moment(getValues('endDate'), 'DD-MM-YYYY')}
            />
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          label={i18n._(t`Cancel`)}
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          onClick={onClose}
        />
        <Button
          label={i18n._(t`Save changes`)}
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          onClick={submit((formData) => onSave(formData as FormDataType))}
        />
      </ModalFooter>
    </Modal>
  );
};

export { DateEditModal };
