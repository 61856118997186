import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getUsersFromIntegration(integrationId, skipExistingUsers = false) {
  return cloudRequest(serverRoutes.integrations.getUsersFromIntegration(integrationId), {
    skipExistingUsers,
  });
}

export function findUsersToDeleteFromCompanyIntegrations() {
  return cloudRequest(serverRoutes.integrations.findUsersToDeleteFromCompanyIntegrations);
}

export function getTeamsFromIntegration(integrationId, skipExistingTeams = false) {
  return cloudRequest(serverRoutes.integrations.getTeamsFromIntegration(integrationId), {
    skipExistingTeams,
  });
}

export function getJobProfilesFromIntegration(integrationId, skipExistingJobProfiles = false) {
  return cloudRequest(serverRoutes.integrations.getJobProfilesFromIntegration(integrationId), {
    skipExistingJobProfiles,
  });
}

export function findTeamsToDeleteFromCompanyIntegrations() {
  return cloudRequest(serverRoutes.integrations.findTeamsToDeleteFromCompanyIntegrations());
}

export function findRolesToDeleteFromCompanyIntegrations() {
  return cloudRequest(serverRoutes.integrations.findRolesToDeleteFromCompanyIntegrations());
}

export function findTeamsToSyncInCompanyIntegrations() {
  return cloudRequest(serverRoutes.integrations.findTeamsToSyncInCompanyIntegrations());
}

export function findRolesToSyncInCompanyIntegrations() {
  return cloudRequest(serverRoutes.integrations.findRolesToSyncInCompanyIntegrations());
}

export function findMembersToSyncInCompanyIntegrations() {
  return cloudRequest(serverRoutes.integrations.findMembersToSyncInCompanyIntegrations);
}

export function syncMembersFromCompanyIntegrations(syncMembersBody) {
  return cloudRequest(
    serverRoutes.integrations.syncMembersFromCompanyIntegrations,
    {},
    syncMembersBody,
  );
}

export function initializeIntegration() {
  return cloudRequest(serverRoutes.integrationsHRIS.initialize);
}

export function activateIntegration(token) {
  return cloudRequest(serverRoutes.integrationsHRIS.activate, {}, { token });
}

export function relinkIntegration(integrationSettingsId) {
  return cloudRequest(serverRoutes.integrationsHRIS.relink(integrationSettingsId));
}
