import React, { useEffect } from 'react';

import { Trans } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import size from 'lodash/size';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';

import ArrowRightIcon from '~/components/Icons/ArrowRight';
import { Input } from '~/components/Input';

import type { IQuestionForm } from '~/@types/question';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { COLORS } from '~/styles';

interface ITrafficLightsProps {
  formMethods: UseFormReturn<IQuestionForm>;
  languageState: ILanguageStateReturn;
  isPreloaded?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;

  & .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const Light = styled.div<{ color?: string }>`
  border-radius: 100px;
  min-width: 32px;
  min-height: 32px;
  background-color: ${({ color }) => color || 'unset'};
`;

const Header = styled.div`
  display: flex;
  color: ${COLORS.BLACK};
  gap: 30px;
  font-size: 14px;
`;

const Row = styled.div`
  display: flex;
  align-items: start;
  color: ${COLORS.BORDER_HARD};
  & svg {
    margin: 0 18px 0 10px;
  }
`;

const Rows = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
`;

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  height: 38px;
`;

const InputGroups = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LIGHTS = [{ color: COLORS.ERROR }, { color: COLORS.ORANGE }, { color: COLORS.GREEN }];

const TrafficLights = ({ languageState, formMethods, isPreloaded }: ITrafficLightsProps) => {
  const { control, register, resetField } = formMethods;
  const { fields, append } = useFieldArray({ name: 'options', control });
  const languages = languageState.companyLanguages.filter(({ locale }) =>
    languageState.languages.find((language) => language.locale === locale),
  );

  useEffect(() => {
    if (!isPreloaded) {
      resetField('options', { defaultValue: [] });
      LIGHTS.map(({ color }) => {
        append(
          {
            icon: color,
            label: languages.map(({ locale }) => ({ locale, value: '' })),
          },
          { shouldFocus: false },
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Header>
        <div>
          <Trans id="Traffic light" />
        </div>
        <div>
          <Trans id="Label" />
        </div>
      </Header>
      <Rows>
        {fields.map(({ icon, label }, index) => (
          <Row key={`traffic-light-${icon}-${index}`}>
            <IconWrapper>
              <Light color={icon} />
            </IconWrapper>

            <IconWrapper>
              <ArrowRightIcon width={47} height={9} />
            </IconWrapper>

            <InputGroups>
              {label.map(({ locale }, i) => {
                const isLocale = languages.find((lang) => lang.locale === locale);
                return (
                  isLocale && (
                    <div className="inputWrapper" key={`${icon}-${locale}`}>
                      <Input
                        tabIndex={fields.length - index}
                        name={`options.${index}.label.${i}.value`}
                        width="100%"
                        height="38px"
                        register={register(`options.${index}.label.${i}.value`, { required: true })}
                        leftIcon={
                          size(languages) > 1
                            ? getUnicodeFlagIcon(locale.substring(locale.indexOf('_') + 1))
                            : undefined
                        }
                      />
                    </div>
                  )
                );
              })}
            </InputGroups>
          </Row>
        ))}
      </Rows>
    </Wrapper>
  );
};

export { TrafficLights };
