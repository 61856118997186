import React, { useEffect, useMemo, useState } from 'react';

import { SKILL_SORTING } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Modal from '~/components/Modal';
import Radio from '~/components/Radio';

import { NameRow, RadioContainer } from './design';
import { FocusAreasStep } from './FocusAreasStep';
import { SelectSkillStep } from './SelectSkillStep';

import type { IColumnTable } from '~/@types/table';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { usePagination } from '~/hooks/usePagination';
import { getSkillCategories } from '~/services/skillCategories';
import { getSkills } from '~/services/skills';

import type { ISelectedSkill, ISkill } from '../../types';
import type { IMultiLangString } from '@learned/types';

interface IProps {
  selectedSkills?: ISelectedSkill[];
  setSelectedSkill?: (skill: ISkill) => void;
  selectedSkill?: ISkill | null;
  skills?: ISkill[];
  currentStep?: number;
  setSkills?: (skills: ISkill[]) => void;
  onClose: () => void;
  onSubmit: (selectedFocusAreas: ISelectedSkill) => void;
}

interface ISkillCategoryColumn {
  id: string;
  name: IMultiLangString | null;
  focusArea: IMultiLangString;
  level: number;
  isSelected?: boolean;
  skillCategoryName?: string;
  totalLevels?: number;
  onDelete: () => void;
  onEdit: () => void;
}

export const SKILL_CATEGORY_COLUMNS: IColumnTable<ISkillCategoryColumn>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'name',
    renderCell: ({ name, isSelected = false }) => (
      <NameRow $isSelected={isSelected}>
        <RadioContainer>{isSelected && <Radio checked={isSelected} size={20} />}</RadioContainer>
        {name}
      </NameRow>
    ),
  },
  {
    name: (i18n: I18n) => i18n._(t`Category`),
    accessor: 'category',
    maxWidth: '200px',
    renderCell: ({ skillCategoryName, isSelected = false }) => (
      <NameRow $isSelected={isSelected}>{skillCategoryName}</NameRow>
    ),
  },
];

export interface ISkillsColumn {
  id: string;
  isSelected?: boolean;
  skillId?: string;
  name: string | null;
  skillCategory: string;
  skillCategoryId?: string;
  totalLevels?: number;
}

const SkillModal = ({
  onClose,
  onSubmit,
  selectedSkill,
  selectedSkills = [],
  setSelectedSkill,
  skills = [],
  setSkills,
  currentStep = 0,
}: IProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [search, setSearch] = useState('');
  const [filteredCategories, setFilteredCategories] = useState<any[]>([]);
  const debouncedSearch = useDebounce(search, 500);
  const { pagination, changePagination, resetPagination } = usePagination(5);
  const $isValid = useBoolState();
  const [skillCategories, setSkillCategories] = useState<any[]>([]);
  const [step, setStep] = useState(currentStep);

  const customPaginationList = useMemo(
    () => [
      { id: 5, label: i18n._(t`5 per page`) },
      { id: 10, label: i18n._(t`10 per page`) },
      { id: 25, label: i18n._(t`25 per page`) },
      { id: 50, label: i18n._(t`50 per page`) },
      { id: 100, label: i18n._(t`100 per page`) },
    ],
    [i18n],
  );

  const filters = {
    search,
    setSearch: (value: string) => setSearch(value.toLowerCase()),
  };

  const handleRowClick = (item: ISkill) => {
    setSelectedSkill?.(item);
    setSkills?.(
      skills.map((row) => {
        if (row.id === item.id) {
          return {
            ...item,
            isSelected: !item.isSelected,
          };
        }
        return { ...row, isSelected: false };
      }),
    );
  };

  useEffect(() => {
    if (skills.find((skill) => skill.isSelected)) {
      $isValid.on();
    } else {
      $isValid.off();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skills]);

  useEffect(() => {
    const fetchSkills = getSkills({
      search: debouncedSearch,
      sortBy: SKILL_SORTING.NAME_A_Z,
      limit: pagination.limit,
      skip: pagination.skip,
      // @ts-ignore
      categories: filteredCategories,
    });
    const fetchSkillCategories = getSkillCategories();

    const fetchData = async () => {
      const [skillsResponse, skillCategoriesResponse] = await Promise.all([
        fetchSkills,
        fetchSkillCategories,
      ]);

      setTotalCount(skillsResponse.data.total);
      setSkills?.(
        Object.values(skillsResponse.data.skills).map((skill: any) => {
          return {
            ...skill,
            name: getMultiLangString(skill.name || ''),
            skillCategoryName: getMultiLangString(skill.skillCategoryName || ''),
            isSelected: false,
          };
        }) || [],
      );
      setSkillCategories(
        Object.values(skillCategoriesResponse).map((skillCategory: any) => {
          return {
            value: skillCategory.id,
            label: getMultiLangString(skillCategory.name || ''),
          };
        }) || [],
      );
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, debouncedSearch, filteredCategories, getMultiLangString]);

  const handleSkillCategorySelect = (skillCategoryId: string) => {
    if (filteredCategories.includes(skillCategoryId)) {
      setFilteredCategories(
        filteredCategories.filter((skillCategory: string) => skillCategory !== skillCategoryId),
      );
    } else {
      setFilteredCategories([...filteredCategories, skillCategoryId]);
    }
    resetPagination();
  };

  return (
    <Modal
      isHideHeader
      hideFooter
      onClose={onClose}
      showDivider={false}
      centerModal
      width={750}
      height={'fit-content'}
      borderRadius={10}
      contentStyles={{ padding: '0', overflow: 'auto' }}
    >
      {step === 0 && (
        <SelectSkillStep
          skills={skills}
          pagination={pagination}
          totalCount={totalCount}
          filters={filters}
          isValidForm={$isValid.value}
          skillCategories={skillCategories}
          filteredCategories={filteredCategories}
          customPaginationList={customPaginationList}
          setStep={setStep}
          changePagination={changePagination}
          resetPagination={resetPagination}
          onClose={onClose}
          handleRowClick={handleRowClick}
          handleSkillCategorySelect={handleSkillCategorySelect}
        />
      )}
      {step === 1 && selectedSkill && (
        <FocusAreasStep
          selectedSkill={selectedSkill}
          onSubmit={onSubmit}
          setStep={setStep}
          onClose={onClose}
          selectedSkills={selectedSkills}
        />
      )}
    </Modal>
  );
};

export { SkillModal };
