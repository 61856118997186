import { ROLES } from '~/constants';
import { pageOverview, pageReport, pageDashboard } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'skills';
const skillIdDefault = ':skillId';

// overview
export const SKILLS = new LinkConstructor([ROLES.ADMIN], () => pageOverview(routeName));

export const SKILLS_CREATE = new LinkConstructor([ROLES.ADMIN], () =>
  pageOverview(`${routeName}/create`),
);
export const SKILL_EDIT = new LinkConstructor([ROLES.ADMIN], ({ skillId = skillIdDefault }) =>
  pageOverview(`${routeName}/${skillId}/edit`),
);

export const SKILLS_REPORT = new LinkConstructor([ROLES.ADMIN], () => pageReport(routeName));

export const SKILL_REPORT = new LinkConstructor([ROLES.ADMIN], ({ skillId = skillIdDefault }) =>
  pageReport(`${routeName}/${skillId}`),
);

export const SKILL_COVERAGE_ITEM_REPORT = new LinkConstructor(
  [ROLES.ADMIN],
  ({ skillId = skillIdDefault }) => pageReport(`${routeName}/${skillId}`),
);

export const SKILLS_PASSPORT = new LinkConstructor(
  [ROLES.USER],
  () => `${pageOverview(routeName)}/passport`,
);

export const SKILL_VIEW = new LinkConstructor([ROLES.ADMIN], ({ skillId = skillIdDefault }) =>
  pageDashboard(routeName, { id: skillId }),
);
