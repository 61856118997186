import React, { ReactNode } from 'react';

import styled from 'styled-components';

import { COLORS } from '~/styles';

const Row = styled.div`
  display: flex;
`;

const IconBlockLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const IconBlockRight = styled.div`
  width: 46px;
  height: 46px;
  margin: 0 14px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: ${COLORS.BG_PAGE};
`;

const IconBlockLabel = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.16px;
  color: ${COLORS.DARK_GRAY};
`;

const IconBlockValue = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.17px;
  color: ${COLORS.BLACK};
`;

const IconBlock = ({
  label,
  value,
  icon,
}: {
  label: string;
  value: string | number | undefined;
  icon: ReactNode | string;
}) => {
  return (
    <Row>
      <IconBlockRight>{icon}</IconBlockRight>
      <IconBlockLeft>
        <IconBlockLabel>{label}</IconBlockLabel>
        {value !== undefined && <IconBlockValue>{value}</IconBlockValue>}
      </IconBlockLeft>
    </Row>
  );
};

export { IconBlock };
