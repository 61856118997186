import { SURVEY_QUESTION_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';

import { ICONS } from '~/components/Icon';

import { COLORS } from '~/styles';

import type { I18n } from '@lingui/core';

export const SURVEY_QUESTIONS_UI = [
  {
    key: SURVEY_QUESTION_TYPES.TEXT,
    title: (i18n: I18n) => i18n._(t`Text area`),
    icon: ICONS.TEXT_ANSWER,
  },
  {
    key: SURVEY_QUESTION_TYPES.SINGLE,
    title: (i18n: I18n) => i18n._(t`Single`),
    icon: ICONS.SINGLE_SELECT,
  },
  {
    key: SURVEY_QUESTION_TYPES.MULTIPLE,
    title: (i18n: I18n) => i18n._(t`Multiple`),
    icon: ICONS.MULTI_SELECT,
  },
  {
    key: SURVEY_QUESTION_TYPES.TRAFFIC,
    title: (i18n: I18n) => i18n._(t`Traffic lights`),
    icon: ICONS.TRAFFIC,
  },
  {
    key: SURVEY_QUESTION_TYPES.STARS,
    title: (i18n: I18n) => i18n._(t`Stars`),
    icon: ICONS.STAR,
  },
  {
    key: SURVEY_QUESTION_TYPES.SMILEYS,
    title: (i18n: I18n) => i18n._(t`Smileys's`),
    icon: ICONS.SMILEY,
  },
];

export const LIGHTS = [{ color: COLORS.ERROR }, { color: COLORS.ORANGE }, { color: COLORS.GREEN }];
