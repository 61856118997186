import React, { Component } from 'react';

import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SkillName from '~/components/Skill/SkillName';
import SvgIcon from '~/components/SvgIcon';

import collapseIcon from '~/assets/ic-unfold-less-24.svg';
import expandIcon from '~/assets/ic-unfold-more-24.svg';

import { FOCUSAREA_LEVELS } from '~/constants';
import { COLOR_PALETTE } from '~/styles';
import convertToTimeString from '~/utils/convertToTimeString';

const BtnToggle = styled.div`
  margin-right: 7px;
`;

const Column = styled.div`
  display: flex;
  width: ${(props) => props.width};
  align-items: center;
`;

const RateWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const FeedbacksTable = styled.div`
  display: flex;
  width: 100%;
`;

const LevelRate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 24px;
  border-radius: 6px;
  background-color: var(--company-color);
  color: ${COLOR_PALETTE.WHITE};
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  text-align: center;
  margin-left: auto;
`;

const LevelTitle = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 1.33;
  color: #70747f;
  text-transform: uppercase;
  margin-bottom: 6px;
`;

const LevelWrapper = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
`;

const RateExplanation = styled.div`
  word-break: break-word;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  color: #3b3b3b;
  border-radius: 10px;
  background-color: #f8f8f8;
  padding: 8px;
  min-height: 24px;
`;

const SkillHeader = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 20px 0;
  box-sizing: border-box;
  //border-bottom: solid 0.5px #ecedf0;
`;
const Divider = styled.div`
  height: 1px;
  border-bottom: solid 0.5px #ecedf0;
  margin: 0 -16px;
  width: calc(100% + 32px);
`;
const SkillInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 0;
  box-sizing: border-box;
`;

const SkillNameWrapper = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #3b3b3b;
`;

const SkillWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  box-sizing: border-box;
`;

const LabelDate = styled.div`
  height: 16px;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #70747f;
  margin-top: 8px;
`;

class Skill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.isExpandedByDefault,
    };
  }

  UNSAFE_componentWillReceiveProps = (newProps) => {
    if (newProps.isAllExpanded !== this.props.isAllExpanded) {
      this.setState({
        open: newProps.isAllExpanded,
      });
    }
  };

  static propTypes = {
    isExpandedByDefault: PropTypes.bool,
    isAllExpanded: PropTypes.bool,
  };

  static defaultTypes = {
    isExpandedByDefault: false,
    isAllExpanded: false,
  };

  toggleSkill = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
    });
  };

  renderSkillInfo = () => {
    const { type, skillLevel, reviewRatings, skill, i18n } = this.props;

    const skillRating =
      (reviewRatings || []).find((rating) => {
        let isRatingForSkill = false;
        if (rating) {
          if (type === 'skill' && String(rating.skill) === String(skill.id)) {
            isRatingForSkill = true;
          }
          if (type === 'question' && rating.question === skill.id) {
            isRatingForSkill = true;
          }
        }
        return isRatingForSkill;
      }) || {};

    const maxValue = 4;
    const lastUpdate =
      get(skillRating, 'meta.lastModifiedDate') || get(skillRating, 'meta.createdDate');

    return (
      <SkillInfoWrapper>
        {type === 'skill' && skillLevel && (
          <LevelWrapper>
            <LevelTitle>
              <Trans>Behavior at level: {FOCUSAREA_LEVELS[String(skillLevel)].label(i18n)}</Trans>
            </LevelTitle>
            <LevelRate>
              {skillRating.rate ? `${skillRating.rate.toFixed()}/${maxValue}` : 'N/A'}
            </LevelRate>
          </LevelWrapper>
        )}
        <FeedbacksTable>
          <Column width="100%">
            <RateWrapper>
              <RateExplanation>{skillRating.explanation || ''}</RateExplanation>
            </RateWrapper>
          </Column>
        </FeedbacksTable>
        {lastUpdate && (
          <LabelDate>
            <Trans>Completed at: </Trans>
            {convertToTimeString(lastUpdate)}
          </LabelDate>
        )}
      </SkillInfoWrapper>
    );
  };

  render() {
    const { skill, type } = this.props;
    const { open } = this.state;

    return (
      <SkillWrapper key={skill.id}>
        <SkillHeader>
          <BtnToggle onClick={this.toggleSkill}>
            <SvgIcon
              width="24px"
              height="24px"
              isDefaultColor={!open}
              defaultColor="#ccced5"
              url={open ? collapseIcon : expandIcon}
            />
          </BtnToggle>
          {type === 'skill' && (
            <SkillNameWrapper>
              <Trans>
                How did this person perform on <SkillName skill={skill} />?
              </Trans>
            </SkillNameWrapper>
          )}
          {type === 'question' && <SkillNameWrapper>{skill.name}</SkillNameWrapper>}
        </SkillHeader>
        <Divider />
        {open && this.renderSkillInfo()}
      </SkillWrapper>
    );
  }
}

export default withI18n()(Skill);
