import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, SURVEY_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { Trans, useLingui } from '@lingui/react';
import map from 'lodash/map';
import size from 'lodash/size';
import { useParams } from 'react-router';

import { ButtonVariant } from '~/components/Buttons';
import { HeatmapFilter } from '~/components/HeatmapFilter';
import { useHeatmapFilter } from '~/components/HeatmapFilter/useHeatmapFilter';
import { ICONS } from '~/components/Icon';
import { TableGrid } from '~/components/TableGrid';
import { useToasts, TOAST_TYPES } from '~/components/Toast';

import { TEAMS_COLUMNS } from './teamsColumns';
import { prepareColumn } from './utils';

import type { IColumnTable } from '~/@types/table';
import { ColumnPosition } from '~/@types/table';
import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import useSearchState from '~/hooks/useSearchState';
import { downloadSurveyParticipationCSV, getSurveyParticipation } from '~/services/surveys';
import convertToTimeString from '~/utils/convertToTimeString';

import { Header, Title, Wrapper } from '../../../design';

import type { ISurveyParticipation } from '../../../types';
import type { ISurvey, ISurveyEvent } from '@learned/types';
import type { I18n } from '@lingui/core';

interface SurveyResultProps {
  survey: ISurvey;
}

const createColumns = (
  survey: ISurvey,
  events: ISurveyEvent[],
  i18n: I18n,
  selectedItems: string[],
) => {
  if (survey?.type === SURVEY_TYPE.TRADITIONAL) {
    return {
      columns: [
        TEAMS_COLUMNS[0],
        ...map(events, ({ id }: ISurveyEvent) =>
          prepareColumn({
            id,
            name: i18n._(t`Average`),
            reportingThreshold: survey.anonymity.reportingThreshold,
            selectedItems,
          }),
        ),
      ],
    };
  } else {
    const additionColumns: IColumnTable[] = map(events, ({ id, startDate }: ISurveyEvent) =>
      prepareColumn({
        id,
        name: convertToTimeString(startDate),
        reportingThreshold: survey.anonymity.reportingThreshold,
        selectedItems,
      }),
    );

    return {
      columns: [
        TEAMS_COLUMNS[0],
        ...additionColumns,
        prepareColumn({
          id: 'average',
          name: i18n._(t`Average`),
          isFixed: true,
          position: ColumnPosition.RIGHT,
          reportingThreshold: survey.anonymity.reportingThreshold,
          selectedItems,
        }),
      ],
      additionColumns,
    };
  }
};

const TeamsOverview = ({ survey }: SurveyResultProps) => {
  const [data, setData] = useState<ISurveyParticipation[]>([]);
  const [columns, setColumns] = useState(TEAMS_COLUMNS);
  const [totalCount, setTotalCount] = useState(0);
  const { pagination, changePagination } = usePagination(20);
  const [isLoading, setIsLoading] = useState(false);
  const $search = useSearchState();
  const debSearch = useDebounce($search.value, 300);
  const { addToast } = useToasts();
  const { i18n } = useLingui();
  const { onClickItem, selectedItems } = useHeatmapFilter({
    defaultValues: ['80-100', '60-79', '40-59', '20-39', '0-19'],
  });

  const params: Record<string, string | undefined> = useParams();

  const fetchData = async () => {
    if (params.surveyId) {
      setIsLoading(true);

      const { data } = await getSurveyParticipation(
        params.surveyId,
        {
          skip: pagination.skip,
          limit: pagination.limit,
        },
        {
          search: debSearch,
        },
      );

      const events = data[API_RETURN_FIELDS.SURVEY_EVENTS];
      if (!survey) {
        setIsLoading(false);
        return;
      }

      // @ts-ignore
      const { columns } = createColumns(survey, events, i18n, selectedItems);

      setColumns(columns);

      // add tooltip for Average (first row)
      if (data[API_RETURN_FIELDS.SURVEY_PARTICIPATION][0]) {
        data[API_RETURN_FIELDS.SURVEY_PARTICIPATION][0].tooltip = i18n._(
          t`The total number of participants may differ from the sum of all participants in all teams. Participants may be in multiple teams`,
        );
      }

      setData(data[API_RETURN_FIELDS.SURVEY_PARTICIPATION]);
      setTotalCount(data[API_RETURN_FIELDS.TOTAL]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [pagination.limit, pagination.skip, params.surveyId, debSearch, selectedItems]);

  const exportCSV = async () => {
    addToast({
      title: i18n._(t`Exporting CSV`),
      subtitle: i18n._(
        t`Your CSV is being downloaded. This can take some time. It will download when it is ready.`,
      ),
      type: TOAST_TYPES.INFO,
    });

    if (params.surveyId) {
      await downloadSurveyParticipationCSV(params.surveyId);
    }
  };

  const filters = {
    search: $search.value,
    setSearch: (value: string) => $search.set(value.toLowerCase()),
  };

  const actionButton = {
    label: t`Export csv`,
    variant: ButtonVariant.SECONDARY,
    icon: ICONS.EXPORT,
    disabled: !size(data),
    onClick: exportCSV,
  };

  const mainTablePlaceholder = {
    isVisible: false,
    title: '',
    icon: ICONS.LOCK,
  };

  if (!size(data)) {
    mainTablePlaceholder.title = i18n._(t`Results are not yet shared`);
    mainTablePlaceholder.icon = ICONS.TIME;
    mainTablePlaceholder.isVisible = true;
  }

  return (
    <Wrapper>
      <Header>
        <Title>
          <Trans id="Participation per team" />
        </Title>
      </Header>

      <TableGrid
        data={data}
        columns={columns}
        isScrollbarVisible
        isLeftColumnsStriped
        leftMinWidth="150px"
        rightMinWidth={survey?.type === SURVEY_TYPE.PULSE ? '120px' : undefined}
        isLoading={isLoading}
        actionButton={actionButton}
        placeholderProps={{
          noResultText: i18n._(t`No surveys found`),
          emptyStateText: i18n._(t`No surveys yet… Let’s create one!`),
          mainTable: mainTablePlaceholder,
        }}
        paginationProps={{
          pagination,
          changePagination,
          totalCount,
        }}
        filtersProps={{
          filters,
          isFiltered: false,
          isToggleHideFilterVisible: true,
          filterComponents: (
            <>
              <HeatmapFilter
                onClickItem={onClickItem}
                selectedItems={selectedItems}
                label={i18n._(t`Heatmap`)}
              />
            </>
          ),
        }}
      />
    </Wrapper>
  );
};

export { TeamsOverview };
