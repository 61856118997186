export enum KPI_TYPES {
  SKILLS = 'skills',
  POTENTIAL = 'potential',
  PERFORMANCE = 'performance',
}
// if KPI_TYPE === null (it means none type)

export const KPI_NONE = {
  id: null,
  name: 'none',
  type: null,
};
