import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import size from 'lodash/size';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useFieldArray, type UseFormReturn } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import { TablePlaceholder } from '~/components/TablePlaceholder';

import { ActionButton } from './ActionButton';
import {
  Table,
  Header,
  Cell,
  Row,
  Wrapper,
  CellWrapper,
  AddNewCell,
} from './RecommendedCareerPathTable.design';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import { AddRoleModal } from '../../components/AddRoleModal';

import type { IGeneralForm } from '../types';
import type { IJobProfile } from '@learned/types';

type RecommendedCareerPathTableProps = {
  formMethods: UseFormReturn<IGeneralForm>;
  isEditMode?: boolean;
};

const RecommendedCareerPathTable = ({
  formMethods,
  isEditMode = false,
}: RecommendedCareerPathTableProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const [isAddRoleModalVisible, setIsAddRoleModalVisible] = useState(false);
  const [insertIndex, setIndexInsert] = useState<number | null>(null);
  const { control } = formMethods;
  const { fields, insert, append, remove, move } = useFieldArray({
    control,
    name: 'recommendedJobs',
  });

  const onAddRoleButton = (index: number | null) => {
    setIsAddRoleModalVisible(true);
    setIndexInsert(index);
  };

  return (
    <Wrapper>
      <DragDropContext
        onDragEnd={(result) => {
          move(result.source.index, result?.destination?.index || 0);
        }}
      >
        <>
          <Header>{i18n._(t`Recommended career path`)}</Header>
          {!isEditMode && !size(fields) ? (
            <TablePlaceholder />
          ) : (
            <Table>
              {size(fields) < 10 && size(fields) > 0 && isEditMode && (
                <ActionButton isFirst onClick={() => onAddRoleButton(0)} />
              )}
              <>
                <Droppable
                  droppableId="droppable"
                  direction="horizontal"
                  isDropDisabled={!isEditMode}
                >
                  {(provided) => (
                    <Row ref={provided.innerRef} {...provided.droppableProps}>
                      {fields.map((item, index) => (
                        <>
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                            isDragDisabled={!isEditMode}
                          >
                            {(provided, snapshot) => (
                              <CellWrapper
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Cell isDraggingOver={snapshot.isDragging}>
                                  <span className="title">{getMultiLangString(item.name)}</span>
                                  {isEditMode && (
                                    <Button
                                      type="button"
                                      variant={ButtonVariant.ICON}
                                      size={ButtonSize.MEDIUM}
                                      icon={ICONS.DELETE_BIN}
                                      onClick={() => remove(index)}
                                    />
                                  )}
                                </Cell>
                                {index < size(fields) - 1 && size(fields) < 10 && isEditMode && (
                                  <ActionButton onClick={() => onAddRoleButton(++index)} />
                                )}
                              </CellWrapper>
                            )}
                          </Draggable>
                        </>
                      ))}
                      {provided.placeholder}
                      {size(fields) < 10 && isEditMode && (
                        <AddNewCell type="button" onClick={() => onAddRoleButton(null)}>
                          <div className="iconWrapper">
                            <Icon icon={ICONS.ADD_PLUS} size={ICON_SIZES.SMALL} />
                          </div>
                        </AddNewCell>
                      )}
                    </Row>
                  )}
                </Droppable>
              </>
            </Table>
          )}
        </>
      </DragDropContext>
      {isAddRoleModalVisible && (
        <AddRoleModal
          onClose={() => setIsAddRoleModalVisible(false)}
          onSubmit={(jobProfile: IJobProfile) => {
            if (insertIndex === null) {
              append({ id: jobProfile.id, name: jobProfile.name });
            } else {
              insert(insertIndex, { id: jobProfile.id, name: jobProfile.name });
            }
            setIndexInsert(null);
          }}
          formMethods={formMethods}
        />
      )}
    </Wrapper>
  );
};

export { RecommendedCareerPathTable };
