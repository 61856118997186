import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';

import { CollapseMenuBlock, CollapseIconWrapper } from '../styles/menuItemDesign';

interface ICollapseMenuItem {
  isMenuCollapsed: boolean;
  onClick: () => void;
}

const StyledIcon = styled(Icon).withConfig({
  shouldForwardProp: (prop) => !['isRotate'].includes(prop),
})<{ isRotate: boolean }>`
  rotate: ${(props) => (props.isRotate ? '180deg' : '0deg')};
`;

const CollapseMenuItem = ({ isMenuCollapsed, onClick }: ICollapseMenuItem) => {
  return (
    <CollapseMenuBlock
      role="button"
      onClick={onClick}
      onBlur={() => {}}
      tabIndex={0}
      onKeyDown={onClick}
      isMenuCollapsed={isMenuCollapsed}
    >
      {!isMenuCollapsed && (
        <span>
          <Trans>collapse menu</Trans>
        </span>
      )}
      <CollapseIconWrapper>
        <StyledIcon icon={ICONS.COLLAPSE_MENU} size={ICON_SIZES.SMALL} isRotate={isMenuCollapsed} />
      </CollapseIconWrapper>
    </CollapseMenuBlock>
  );
};

export default CollapseMenuItem;
