import React, { MouseEventHandler } from 'react';

import {
  SURVEY_SORT_OPTIONS,
  SURVEY_TYPE,
  SURVEY_FREQUENCY,
  SURVEY_TASK_STATUS,
  SURVEY_STATUS,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Button, ButtonVariant, ButtonSize } from '~/components/Buttons';
import SvgIcon from '~/components/SvgIcon';
import { FlexRow, NameRow, TD } from '~/pages/Surveys/design';
import { NameWithQuestions } from '~/pages/Surveys/NameWithQuestions';

import SurveyPulseIcon from '~/assets/survey-pulse.svg';
import SurveyTraditionalIcon from '~/assets/survey-traditional.svg';

import type { IColumnTable } from '~/@types/table';
import { isShowPulseEveryDayFrequency } from '~/constants/survey';
import { USER_SURVEY_STATUS_BUTTONS } from '~/constants/userSurveys';
import { COLORS } from '~/styles';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

import type { IUserSurveyPopulated } from '@learned/types';
import type { I18n } from '@lingui/core';

const IconBlock = styled.div<{
  $colors: string[];
}>`
  width: 46px;
  min-height: 46px;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 8px 11px;
  background: ${({ $colors }) =>
    $colors.length ? `linear-gradient(to bottom, ${$colors[0]}, ${$colors[1]})` : 'unset'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StatusText = styled.span`
  font-size: 12px;
`;

const data = [
  {
    type: SURVEY_TYPE.TRADITIONAL,
    colors: ['#e6f3ff', '#d5dffc'],
    icon: (
      <SvgIcon
        width="24px"
        height="24px"
        url={SurveyTraditionalIcon}
        isDefaultColor
        defaultColor={COLORS.MIDDLE_GRAY}
      />
    ),
  },
  {
    type: SURVEY_TYPE.PULSE,
    colors: ['#ffe6e6', '#fcd5d5'],
    icon: (
      <SvgIcon
        width="22px"
        height="22px"
        url={SurveyPulseIcon}
        isDefaultColor
        defaultColor={COLORS.MIDDLE_GRAY}
      />
    ),
  },
];

const RepeatColumn = ({ item }: { item: IUserSurveyPopulated }) => {
  const { i18n } = useLingui();
  const transform = {
    [SURVEY_FREQUENCY.WEEK]: i18n._(t`Weekly`),
    [SURVEY_FREQUENCY.TWO_WEEK]: i18n._(t`Bi-Weekly`),
    [SURVEY_FREQUENCY.FOUR_WEEK]: i18n._(t`Monthly`),
    ...(isShowPulseEveryDayFrequency && {
      [SURVEY_FREQUENCY.DAY]: i18n._(t`Daily (only for stage/live)`),
    }),
  };

  return (
    <TD isGrayColor={item?.status === SURVEY_TASK_STATUS.ARCHIVED}>
      <span>{item.survey.pulse?.frequency ? transform[item.survey.pulse?.frequency] : 'N/A'}</span>
    </TD>
  );
};

const StatusColumn = ({
  item,
  onClick,
  isHovered,
}: {
  item: IUserSurveyPopulated;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
  isHovered?: boolean | undefined;
}) => {
  const { i18n } = useLingui();
  const isSurveyArchived =
    item.survey.status === SURVEY_STATUS.ARCHIVED || item.status === SURVEY_TASK_STATUS.ARCHIVED;
  const status = Object.values(USER_SURVEY_STATUS_BUTTONS).find((i) =>
    isSurveyArchived ? i.id === SURVEY_TASK_STATUS.ARCHIVED : i.id === item.status,
  );
  return (
    <TD isGrayColor={isSurveyArchived} $isPointer={!isSurveyArchived}>
      {(!isHovered ||
        [SURVEY_TASK_STATUS.COMPLETED, SURVEY_TASK_STATUS.ARCHIVED].includes(
          status?.id as SURVEY_TASK_STATUS,
        )) && <StatusText>{status?.translatedText(i18n)}</StatusText>}
      {isHovered &&
        [SURVEY_TASK_STATUS.TODO, SURVEY_TASK_STATUS.DRAFT].includes(
          status?.id as SURVEY_TASK_STATUS,
        ) && (
          <Button
            label={status?.translatedButton(i18n)}
            variant={ButtonVariant.PRIMARY}
            onClick={(e) => {
              e.stopPropagation();
              // @ts-ignore
              onClick(item);
            }}
            size={ButtonSize.MEDIUM}
          />
        )}
    </TD>
  );
};

const USER_SURVEYS_COLUMNS: IColumnTable<IUserSurveyPopulated>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'survey.name',
    minWidth: '420px',
    renderCell: (item: IUserSurveyPopulated) => {
      const colorItem = data.find((colorType) => colorType.type === item.survey.type) || data[0];
      return (
        <NameRow>
          <FlexRow>
            <IconBlock $colors={colorItem?.colors}>{colorItem?.icon}</IconBlock>
            <NameWithQuestions
              name={item.survey?.local?.name as string}
              isGrayColor={item?.status === SURVEY_TASK_STATUS.ARCHIVED}
            />
          </FlexRow>
        </NameRow>
      );
    },
    sortBy: {
      asc: {
        key: SURVEY_SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: SURVEY_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Repeat`),
    accessor: 'survey.pulse.frequency',
    minWidth: '162px',
    sortBy: {
      asc: {
        key: SURVEY_SORT_OPTIONS.REPEAT_OFTEN_RARE,

        label: (i18n: I18n) => i18n._(t`Often - rare`),
      },
      desc: {
        key: SURVEY_SORT_OPTIONS.REPEAT_RARE_OFTEN,
        label: (i18n: I18n) => i18n._(t`Rare - often`),
      },
    },
    renderCell: (item: IUserSurveyPopulated) => <RepeatColumn item={item} />,
  },
  {
    name: (i18n: I18n) => i18n._(t`Deadline`),
    accessor: 'survey.dates.endDate',
    minWidth: '162px',
    sortBy: {
      asc: {
        key: SURVEY_SORT_OPTIONS.END_DATE_SOON_LATER,

        label: (i18n: I18n) => i18n._(t`Soon - later`),
      },
      desc: {
        key: SURVEY_SORT_OPTIONS.END_DATE_LATER_SOON,
        label: (i18n: I18n) => i18n._(t`Later - soon`),
      },
    },
    renderCell: (item: IUserSurveyPopulated) => {
      return (
        <TD isGrayColor={item?.status === SURVEY_TASK_STATUS.ARCHIVED}>
          <span>{convertToTimeString(item.survey?.dates?.endDate, TIME_FORMATS.CLASSIC)}</span>
        </TD>
      );
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Status`),
    accessor: 'status',
    minWidth: '154px',
    renderCell: (
      item: IUserSurveyPopulated,
      onClick: MouseEventHandler<HTMLDivElement> | undefined,
      isHovered: boolean | undefined,
    ) => <StatusColumn item={item} onClick={onClick} isHovered={isHovered} />,
  },
];

export { USER_SURVEYS_COLUMNS };
