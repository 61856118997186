import React, { ChangeEvent, Dispatch, useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  SURVEY_STATUS,
  SURVEY_TEMPLATE_STATUSES,
  SURVEY_TEMPLATES_SORT_OPTIONS,
  SURVEY_TYPE,
} from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';

import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import PaginationBar from '~/components/PaginationBar';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TablePlaceholder } from '~/components/TablePlaceholder';
import { SearchField } from '~/components/Text';
import { StepFooter } from '~/pages/SurveyUpdate/components';

import { TemplateRow } from './TemplateRow';

import { useAutoSaveFunc } from '~/hooks/useAutoSave';
import useDebounce from '~/hooks/useDebounce';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { usePagination } from '~/hooks/usePagination';
import { getSurveyTemplates } from '~/services/surveyTemplates';
import { COLORS } from '~/styles';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import type { IGeneralForm } from '../../types';
import type { ISurveyTemplate } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

const Form = styled.form`
  border-radius: 10px;
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 32px 40px;
`;

const Title = styled.h2`
  font-size: 26px;
  color: ${COLORS.BLACK};
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 0;
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  margin-bottom: 24px;
`;

const SelectedTemplateWrapper = styled.div`
  margin-bottom: 32px;
`;

const TemplatesBlock = styled.div<{ $isError: boolean }>`
  ${(props) =>
    props.$isError &&
    css`
      border: 1px solid ${COLORS.ERROR};
      border-radius: 6px;
    `}
`;

const ErrorLabel = styled.div`
  margin-left: 8px;
  margin-top: 8px;
  color: ${COLORS.ERROR};
  font-size: 12px;
`;

const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  width: 100%;
  margin-bottom: 10px;
`;

type IFilter = {
  search: string;
};

const initialFilters = {
  search: '',
};

export interface IStepChooseTemplateProps {
  formMethods: UseFormReturn<IGeneralForm>;
  setCurrentSection: Dispatch<number>;
  languageState: ILanguageStateReturn;
  autosave: useAutoSaveFunc;
  onTemplatePreview: (item: ISurveyTemplate) => void;
}

const StepChooseTemplate = ({
  setCurrentSection,
  formMethods,
  languageState,
  autosave,
  onTemplatePreview,
}: IStepChooseTemplateProps) => {
  const { i18n } = useLingui();
  const [surveyTemplates, setSurveyTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const { pagination, changePagination, changePageSize, resetPagination } = usePagination(10);
  const [currentFilters, setCurrentFilters] = useState<IFilter>(initialFilters);
  const debCurrentFilters = useDebounce(currentFilters, 300);

  const {
    watch,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
  } = formMethods;

  const selectedTemplate = watch('template');
  const isLockedToEdit =
    watch('type') === SURVEY_TYPE.TRADITIONAL && watch('status') !== SURVEY_STATUS.DRAFT;
  const filters = {
    search: currentFilters.search,
    setSearch: (value: string) => {
      setCurrentFilters({ ...currentFilters, search: value });
      resetPagination();
    },
  };

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      setIsLoading(true);
      const { data } = await getSurveyTemplates(
        {
          search: isEmpty(currentFilters.search) ? undefined : currentFilters.search,
          statuses: [SURVEY_TEMPLATE_STATUSES.active],
        },
        {
          skip: pagination.skip,
          limit: pagination.limit,
          sortBy: SURVEY_TEMPLATES_SORT_OPTIONS.CREATED_NEW_OLD,
        },
      );

      if (isMounted) {
        setSurveyTemplates(data[API_RETURN_FIELDS.SURVEY_TEMPLATES]);
        setTotalCount(data[API_RETURN_FIELDS.TOTAL]);
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line
  }, [pagination.limit, pagination.skip, debCurrentFilters]);

  const onSubmit = () => setCurrentSection(1);

  const changeTemplate = async (surveyTemplate: ISurveyTemplate | null) => {
    setValue('template', surveyTemplate);

    // set name and description from selected template
    if (surveyTemplate) {
      setValue('name', turnMultiLangIntoArray(surveyTemplate.name, languageState.companyLanguages));
      setValue(
        'description',
        turnMultiLangIntoArray(surveyTemplate.description, languageState.companyLanguages),
      );
    }

    // re-check validation
    await trigger(['template', 'name', 'description']);
    autosave.run('template');
  };

  const onTemplateSelect = async (surveyTemplate: ISurveyTemplate | null) => {
    let isConfirmed = true;

    // confirmation modal only if template was already selected
    if (selectedTemplate) {
      isConfirmed = (await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Are you sure want to change template?`),
        description: i18n._(
          t`All questions, name, description, etc... will be replaced with new data from new template. This action cannot be undone.`,
        ),
      })) as unknown as boolean;
    }

    if (isConfirmed) {
      changeTemplate(surveyTemplate);
    }
  };

  const onTemplateDelete = async () => {
    if (
      await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        title: i18n._(t`Are you sure want to delete template?`),
        description: i18n._(
          t`Survey will not work without template. All questions from current template will be removed from survey. This action cannot be undone.`,
        ),
      })
    ) {
      changeTemplate(null);
    }
  };

  const surveyTemplatesFiltered = surveyTemplates.filter(({ id }) => selectedTemplate?.id !== id); // filter out selected template

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Title>
        <Trans>Templates</Trans>
      </Title>
      <SubTitle>
        <Trans>Select a template as a starting point for your new survey</Trans>
      </SubTitle>
      {selectedTemplate && (
        <SelectedTemplateWrapper>
          <TemplateRow
            item={selectedTemplate}
            isFirst={true}
            isLast={true}
            isSelected={true}
            onDelete={isLockedToEdit ? undefined : onTemplateDelete}
            onPreview={onTemplatePreview}
          />
        </SelectedTemplateWrapper>
      )}
      <SearchFieldWrapper
        onChange={(e: ChangeEvent<HTMLInputElement>) => filters.setSearch(e.currentTarget.value)}
        value={filters.search}
        placeholder={i18n._(t`Search`)}
        style={{
          borderRadius: '6px',
          fontSize: '14px',
          fontWeight: 600,
        }}
      />
      <ShowSpinnerIfLoading loading={isLoading}>
        <TemplatesBlock $isError={Boolean(errors.template)}>
          {isEmpty(surveyTemplatesFiltered)
            ? /* Do not display placeholder, if only 1 template exist in company, and it is selected*/
              !selectedTemplate && (
                <TablePlaceholder
                  isLoading={isLoading}
                  isFiltered={!!currentFilters.search.length}
                  noResultText={i18n._(t`No templates found`)}
                  emptyStateText={i18n._(t`No templates yet… Let’s create one!`)}
                />
              )
            : surveyTemplatesFiltered.map((item, key) => (
                <TemplateRow
                  key={key}
                  item={item}
                  isFirst={!key}
                  isLast={surveyTemplatesFiltered.length - 1 === key}
                  onClick={isLockedToEdit ? undefined : onTemplateSelect}
                  onPreview={onTemplatePreview}
                />
              ))}
        </TemplatesBlock>
        {Boolean(errors.template) && (
          <ErrorLabel>{i18n._(t`Template is required. Please select template`)}</ErrorLabel>
        )}
      </ShowSpinnerIfLoading>
      <PaginationBar
        pagination={pagination}
        changePagination={changePagination}
        changePageSize={changePageSize}
        count={totalCount}
        noShadow
        noBorder
        noTopBorder
        showCount
      />
      <StepFooter isPrev={false} />
    </Form>
  );
};

export { StepChooseTemplate };
