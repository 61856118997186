import React, { Fragment, useEffect } from 'react';

import { ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import OverviewHeading from '~/components/OverviewHeading';

import { Overview } from './Overview';

import { INSTRUCTIONS } from '~/constants/instructions';
import routes from '~/constants/routes';
import { checkModuleReviews, checkModuleConversations } from '~/selectors/baseGetters';
import getInstructionUrl from '~/utils/getInstructionUrl';

const Reviews = () => {
  const { i18n } = useLingui();
  const history = useHistory();
  const isModuleReviewsEnabled = useSelector(checkModuleReviews);
  const isModuleMeetingsEnabled = useSelector(checkModuleConversations);

  // TEMP solution for old reviews pages (redirected from calendar event)
  // Redirect wrong url to UserReview Dashboard
  const {
    userReview: userReviewFromQuery,
    originalConversation: originalConversationFromQuery,
  }: { userReview?: string; originalConversation?: string } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  // redirect to new conversation page (for old url from calendar events)
  if (userReviewFromQuery) {
    routes.REVIEW.go({ role: ROLES.USER }, { reviewId: userReviewFromQuery, isBackPath: true });
  }

  // redirect to new meetings page (for old url from calendar events
  if (originalConversationFromQuery && window.location.hash !== '#meetings') {
    routes.CONVERSATIONS.go(
      { role: ROLES.USER },
      { hash: 'meetings', query: { originalConversation: originalConversationFromQuery } },
    );
  }

  useEffect(() => {
    if (!isModuleReviewsEnabled && !isModuleMeetingsEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleReviewsEnabled, isModuleMeetingsEnabled, history]);

  return (
    <Fragment>
      {/* @ts-ignore */}
      <OverviewHeading
        title={i18n._(t`Reviews`)}
        instructions={i18n._(t`How reviews work`)}
        instructionsUrl={getInstructionUrl(INSTRUCTIONS.HOW_CONVERSATIONS_WORK_USER)}
        smallWidth
      />
      {isModuleReviewsEnabled && <Overview />}
    </Fragment>
  );
};

export default Reviews;
