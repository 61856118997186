import React, { ReactNode } from 'react';

import styled from 'styled-components';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid ${COLORS.BORDER_HARD};
  margin-bottom: 18px;
  padding-bottom: 6px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: ${COLORS.MIDDLE_GRAY};
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${COLORS.BLACK};
  margin-bottom: 16px;
`;

interface IStepSection {
  title: string;
  description?: string;
  children: ReactNode;
}

const StepSection = ({ title, description, children }: IStepSection) => {
  return (
    <Wrapper>
      <Header>
        <Title>{title}</Title>
      </Header>
      {description && <Description>{description}</Description>}
      {children}
    </Wrapper>
  );
};

export { StepSection };
