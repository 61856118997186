import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import styled from 'styled-components';

import { Select } from '~/components/Dropdown/design';
import { Icon, ICONS } from '~/components/Icon';

import { SelectedLang } from './design';

import { COLORS } from '~/styles';

import type { ILangItem } from './';

const StyledSelect = styled(Select)`
  height: 38px;
`;

const LangSelect = ({
  langToRender,
  onClick,
  clicked,
}: {
  langToRender: ILangItem;
  onClick: () => void;
  clicked: boolean;
}) => {
  const { i18n } = useLingui();
  return (
    <StyledSelect onClick={onClick} $clicked={clicked} $active={false}>
      <SelectedLang>{`${
        langToRender?.locale
          ? getUnicodeFlagIcon(
              // @ts-ignore
              langToRender?.locale.substring(langToRender?.locale.indexOf('_') + 1),
            )
          : ''
      } ${i18n._(t`${langToRender?.language}`)}`}</SelectedLang>
      <Icon color={COLORS.ICONS_PRIMARY} height="11" width="11" icon={ICONS.DROPDOWN} />
    </StyledSelect>
  );
};

export { LangSelect };
