import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Button } from '~/components/Buttons/Button';
import { ICONS, Icon } from '~/components/Icon';

import {
  DropDownCtr,
  FilterButton,
  FilterContainer,
  FilterWrapper,
  SearchCtr,
  SearchFieldWrapper,
  StyledDropdown,
} from './SkillMatrixCommon.design';

import routes from '~/constants/routes';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import { MODEL_TYPE } from '../constants';

import type { TMultiSelect } from '../types';

type TProps = {
  onGetSkills: (selectedItem: TMultiSelect) => void;
  options: TMultiSelect[];
  showFilters: boolean;
  categoryFilter: TMultiSelect | null;
  onTableItemClick: (type: MODEL_TYPE) => void;
  setSearch: (search: string) => void;
  search: string;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
  handleExportCSV: () => void;
  showEdit: boolean;
  onExpandClick?: (expanded: boolean) => void;
  expanded: boolean;
  onSkillCreate?: () => void;
};
const FilterMenu = ({
  onGetSkills,
  options,
  showFilters,
  categoryFilter,
  onTableItemClick,
  setSearch,
  search,
  setShowFilters,
  handleExportCSV,
  showEdit,
  onExpandClick,
  expanded,
  onSkillCreate,
}: TProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  return (
    <FilterWrapper>
      {showFilters && (
        <DropDownCtr>
          <StyledDropdown
            isSingleSelect={true}
            items={options}
            selectedItem={categoryFilter}
            onChange={(selectedItem) => {
              onGetSkills(selectedItem as TMultiSelect);
            }}
            placeholder={i18n._(t`Skill Categories`)}
            stringifyItem={(item) => getMultiLangString((item as TMultiSelect).title || '')}
            actions={
              !showEdit
                ? [
                    {
                      handler: () => {
                        onTableItemClick?.(MODEL_TYPE.EDIT_SKILL_CATEGORY);
                      },
                      icon: ICONS.EDIT_PENCIL,
                      name: i18n._(t`Edit`),
                    },
                  ]
                : []
            }
          />
        </DropDownCtr>
      )}
      <FilterContainer>
        <SearchCtr>
          <SearchFieldWrapper
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearch(e.currentTarget.value);
            }}
            value={search}
            placeholder={i18n._(t`Search...`)}
          />
        </SearchCtr>
        <FilterButton onClick={() => setShowFilters((prevState) => !prevState)}>
          <Icon icon={showFilters ? ICONS.HIDE : ICONS.SHOW} />
          {i18n._(showFilters ? t`Hide filters` : t`Show filters`)}
        </FilterButton>
      </FilterContainer>
      {showEdit && (
        <Button
          icon={ICONS.EDIT_PENCIL}
          disabled={false}
          label={`${i18n._(t`Edit Matrix`)}`}
          variant={ButtonVariant.TEXT_PRIMARY}
          onClick={() => {
            routes.SKILL_MATRIX_EDIT.go();
          }}
          size={ButtonSize.MEDIUM}
        />
      )}
      <Button
        icon={ICONS.EXPORT}
        disabled={false}
        label={`${i18n._(t`Export`)}`}
        variant={ButtonVariant.TEXT_PRIMARY}
        onClick={handleExportCSV}
        size={ButtonSize.MEDIUM}
      />
      {!showEdit && (
        <Button
          icon={ICONS.ADD_PLUS}
          label={i18n._(t`Add Skill`)}
          onClick={onSkillCreate}
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
        />
      )}
      {showEdit && (
        <Icon
          icon={expanded ? ICONS.COLLAPSE_WINDOW : ICONS.EXPAND}
          color={COLORS.PLACEHOLDERS}
          className="skill-button"
          onClick={() => {
            onExpandClick?.(!expanded);
          }}
        />
      )}
    </FilterWrapper>
  );
};

export { FilterMenu };
