import React from 'react';

import PropTypes from 'prop-types';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const SvgLoader = (props) => {
  const { width, height, url, className, alt } = props;

  return <img src={url} width={width} height={height} className={className} alt={alt} />;
};

SvgLoader.propTypes = {
  url: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
};

export default React.memo(SvgLoader);
