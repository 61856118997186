import React, { Component } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import Modal from '~/components/Modal';
import { TextField } from '~/components/Text';

const Description = styled.div`
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-bottom: 40px;
`;

const MagicWordWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 16px;
`;

const MagicWordLabel = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
`;

const MagicWord = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-left: 10px;
`;

class DeleteCompanyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmText: '',
    };
  }

  static propTypes = {
    onDelete: PropTypes.func,
    onClose: PropTypes.func,
    loading: PropTypes.bool,
    companyName: PropTypes.string,
  };

  updateConfirmText = (e) => {
    const value = e.target.value;
    this.setState({ confirmText: value });
  };

  render() {
    const { onClose, i18n, onDelete, loading, companyName } = this.props;
    const { confirmText } = this.state;

    return (
      <Modal
        title={i18n._(t`Are you sure you want to delete your company account?`)}
        onClose={onClose}
        footerRight={
          <Button
            label={i18n._(t`Delete`)}
            disabled={confirmText !== companyName}
            onClick={onDelete}
            loading={loading}
            type="primary"
          />
        }
        width={500}
        minWidth={500}
        showDivider={false}
      >
        <Description>
          <Trans>This will delete all data for all employees. This cannot be undone.</Trans>
        </Description>

        <MagicWordWrapper>
          <MagicWordLabel>{i18n._(t`Type`)}:</MagicWordLabel>
          <MagicWord>{companyName}</MagicWord>
        </MagicWordWrapper>

        <TextField value={confirmText} onChange={this.updateConfirmText} maxLength={100} />
      </Modal>
    );
  }
}

export default withI18n()(withRouter(DeleteCompanyModal));
