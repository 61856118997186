import React, { useState } from 'react';

import { ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';
import Checkbox from '~/components/CheckBox';
import { confirm } from '~/components/ConfirmDialog';
import Editor from '~/components/Editor';
import Modal from '~/components/Modal';
import { TextField } from '~/components/Text';

import useBoolState from '~/hooks/useBoolState';
import { COLOR_PALETTE } from '~/styles';

const CheckList = styled.div`
  margin-top: 14px;
  margin-bottom: 24px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  margin-right: 16px;
  width: 265px;
  color: ${COLOR_PALETTE.BLACK};
`;

const LabelGrey = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: ${COLOR_PALETTE.BLACK};
  margin: 16px 0 8px;
`;

const LabelGreySmall = styled(LabelGrey)`
  margin: 4px 0 8px;
`;

const SignatureRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const SignatureView = styled.div`
  margin-left: 24px;
  font-family: SignPainter-HouseScript;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: 0.43px;
  color: ${COLOR_PALETTE.BLACK};
`;

const getDefaultSignature = ({ role, userId, digitalSign } = {}) => {
  const defaultSignature = {
    role,
    userId,
    signature: '',
    comment: '',
  };

  if (role === ROLES.USER) {
    if (digitalSign?.isAgreeSeen) {
      defaultSignature.isSeen = false;
    }

    if (digitalSign?.isAgreeContent) {
      defaultSignature.isAgree = false;
    }
  }

  return defaultSignature;
};

const SignModal = ({ view, userId, digitalSign, onClose, onSubmit }) => {
  const { i18n } = useLingui();
  const $loading = useBoolState(false);
  const [signature, setSignature] = useState(
    getDefaultSignature({ role: view, userId, digitalSign }),
  );

  const renderChecklist = () => {
    const list = [
      digitalSign.isAgreeSeen && {
        label: `${i18n._(t`I have seen the contents of this form`)}*`,
        value: signature.isSeen,
        onChange: () => setSignature({ ...signature, isSeen: !signature.isSeen }),
      },
      digitalSign.isAgreeContent && {
        label: i18n._(t`I agree with the contents of this form`),
        value: signature.isAgree,
        onChange: () => setSignature({ ...signature, isAgree: !signature.isAgree }),
      },
    ].filter((i) => i);

    return (
      <CheckList>
        {list.map((item, index) => (
          <Row key={index} onClick={item.onChange}>
            <Label>{item.label}</Label>
            <Checkbox size={24} checked={item.value} />
          </Row>
        ))}
      </CheckList>
    );
  };

  const renderComment = () => {
    return (
      <>
        <LabelGreySmall>{i18n._(t`Comment`)}</LabelGreySmall>
        <Editor
          onChange={(value) => setSignature({ ...signature, comment: value })}
          value={signature.comment}
          placeholder={i18n._(t`Type your comment here…`)}
          className="activity_description"
          compact={true}
        />
      </>
    );
  };

  const renderSignature = () => {
    return (
      <>
        <LabelGrey>{i18n._(t`Signature`)}*</LabelGrey>
        <SignatureRow>
          <TextField
            value={signature.signature}
            maxLength={100}
            onChange={(e) => setSignature({ ...signature, signature: e.target.value })}
            placeholder={i18n._(t`Type your name here`)}
            style={{ width: '300px' }}
            $accent
          />
          <SignatureView>{signature.signature}</SignatureView>
        </SignatureRow>
      </>
    );
  };

  const isDisabledForUser = (digitalSign?.isAgreeSeen && !signature.isSeen) || !signature.signature;
  const isDisabledForCoach = !signature.signature;

  return (
    <Modal
      title={i18n._(t`Complete conversation`)}
      onClose={onClose}
      width={680}
      minWidth={680}
      minHeight="200px"
      footerRight={
        <Button
          styles={{
            height: '40px',
            marginLeft: '10px',
            padding: '0px 15px',
            fontSize: '14px',
            width: '83px',
            minWidth: '83px',
          }}
          type="primary"
          label={i18n._(t`Save`)}
          onClick={async () => {
            const confirmMessage =
              view === ROLES.COACH
                ? i18n._(
                    t`Are you sure? When you complete this report it will be locked. No changes can be made to the self, peer and coach input or to the comments in this report.`,
                  )
                : i18n._(t`Are you sure? This action cannot be undone`);
            if (await confirm(i18n, confirmMessage)) {
              $loading.on();
              await onSubmit(signature);
              onClose();
              $loading.off();
            }
          }}
          // do not disable button if digital sign disabled
          disabled={digitalSign && (view === ROLES.COACH ? isDisabledForCoach : isDisabledForUser)}
          loading={$loading.value}
        />
      }
    >
      {/* checklist only for user and review with digital sign enabled */}
      {view === ROLES.USER && digitalSign && renderChecklist()}

      {renderComment()}

      {/* signature only for review with digital sign enabled*/}
      {digitalSign && renderSignature()}
    </Modal>
  );
};

SignModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  view: PropTypes.oneOf([ROLES.USER, ROLES.COACH]),
  userId: PropTypes.string,
  digitalSign: PropTypes.shape({
    isAgreeSeen: PropTypes.bool,
    isAgreeContent: PropTypes.bool,
    isUserComment: PropTypes.bool,
    isCoachComment: PropTypes.bool,
  }),
};

export default SignModal;
