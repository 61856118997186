import { useEffect, useState } from 'react';

import { useParams } from 'react-router';

import { getJobTemplateSuperAdmin } from '~/services/jobTemplates';

import type { IJobTemplate } from '@learned/types';

const useJobTemplate = () => {
  const params: Record<string, string | undefined> = useParams();
  const jobTemplateId = params.jobTemplateId;
  const [jobTemplate, setJobTemplate] = useState<IJobTemplate>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);

    async function fetchJobTemplate() {
      if (!jobTemplateId) {
        return;
      }

      const response = await getJobTemplateSuperAdmin(jobTemplateId);
      if (!mounted || response.code !== 200) {
        setIsLoading(false);
        return;
      }

      setJobTemplate(response.data.jobTemplate);
      setIsLoading(false);
    }

    fetchJobTemplate();

    return () => void (mounted = false);
  }, [jobTemplateId]);

  return { jobTemplate, isLoading };
};

export { useJobTemplate };
