import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon, ICONS } from '~/components/Icon';
import Search from '~/components/Search';

import { COLOR_PALETTE, COLOR_SET, COLORS } from '~/styles';

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;
  margin-left: ${(props) => (props.overrideMarginLeft !== null ? props.overrideMarginLeft : 12)}px;
  margin-top: ${(props) => (props.overrideMarginTop !== null ? props.overrideMarginTop : 0)}px;
`;

const SearchWrap = styled.div`
  margin-left: 12px;
  display: ${(props) => (props.hidden ? 'none' : '')};
`;

class SearchButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowSearch: false,
    };
  }

  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultValue = {
    value: '',
  };

  searchStyles = {
    paddingLeft: '8px',
    minHeight: '40px',
    height: '40px',
    boxSizing: 'border-box',
    width: '200px',
    fontSize: '12px',
    backgroundColor: COLOR_PALETTE.WHITE,
    border: 'none',
  };

  inputRef = React.createRef();
  componentDidUpdate() {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  showSearch = () => {
    this.setState({ isShowSearch: !this.state.isShowSearch });
  };

  focusSearchThis = (e) => {
    e.preventDefault();
    e.stopPropagation();

    e.target.focus();
  };

  hideSearch = () => {
    if (!this.props.value) {
      this.setState({
        isShowSearch: false,
      });
    }
  };

  render() {
    const { i18n, value, onChange, className } = this.props;
    const overrideMarginLeft = this.props.overrideMarginLeft ? this.props.overrideMarginLeft : null;
    const overrideMarginTop = this.props.overrideMarginTop ? this.props.overrideMarginTop : null;

    const { isShowSearch } = this.state;

    return (
      <div className={className}>
        <IconWrap
          overrideMarginLeft={overrideMarginLeft}
          overrideMarginTop={overrideMarginTop}
          onClick={this.showSearch}
        >
          {!isShowSearch && (
            <Icon icon={ICONS.SEARCH} color={isEmpty(value) ? COLOR_SET.GRAY : COLORS.COMPANY} />
          )}
        </IconWrap>
        {isShowSearch && (
          <SearchWrap>
            <Search
              name="searchValue"
              value={value}
              onChange={onChange}
              onBlur={this.hideSearch}
              onFocus={this.focusSearchThis}
              style={this.searchStyles}
              placeholder={i18n._(t`Type to search`)}
              ref={this.inputRef}
            />
          </SearchWrap>
        )}
      </div>
    );
  }
}

export default withI18n()(SearchButton);
