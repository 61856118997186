import React from 'react';

import RickTextView from '~/components/RickTextView';

import { SectionFieldWrapper, SectionFieldLabel, SectionFieldValue } from '../design';

interface ISectionFieldProps {
  label?: string;
  value: string | React.ReactNode;
  marginTop?: string;
  marginBottom?: string;
  isRichText?: boolean;
}

const SectionField = ({
  label,
  value,
  marginBottom,
  marginTop,
  isRichText,
}: ISectionFieldProps) => {
  return (
    <SectionFieldWrapper marginTop={marginTop} marginBottom={marginBottom}>
      {label && <SectionFieldLabel>{label}</SectionFieldLabel>}
      <SectionFieldValue>
        {isRichText ? (
          <RickTextView html={value as unknown as string} isHtmlWithoutStyles={true} />
        ) : (
          value
        )}
      </SectionFieldValue>
    </SectionFieldWrapper>
  );
};

export { SectionField };
