import React, { ReactNode, useEffect, useState } from 'react';

import { REVIEW_QUESTION_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Modal from '~/components/Modal';

import { EditableQuestion } from './components/EditableQuestion';
import {
  CancelButton,
  Footer,
  HeaderWrapper,
  NeedHelp,
  SubmitButton,
  Title,
  Wrapper,
} from './design';
import { getRatingScale } from './utils';

import { INSTRUCTIONS } from '~/constants/instructions';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import getInstructionUrl from '~/utils/getInstructionUrl';

import { getQuestionType } from '../../utils';

import type { IQuestionForm, ISelectedSkill, ISkill, ISkillCategory } from '../../types';

interface IQuestionModelProps {
  onClose?: () => void;
  onSubmit?: (data: IQuestionForm, reviewThemeId?: string) => void;
  onDelete?: (id: IQuestionForm['id']) => void;
  onDuplicate?: (id: IQuestionForm['id'], index?: number) => Promise<void>;
  setSelectedSkill?: (skill: ISkill | null) => void;
  setSelectedSkills: (skills: ISelectedSkill[]) => void;
  setCurrentStep?: (step: number) => void;
  setIsSkillModalOpen: (open: boolean) => void;
  cancelButton?: ReactNode;
  submitButton?: ReactNode;
  defaultValues?: IQuestionForm;
  languageState: ILanguageStateReturn;
  selectTheme?: boolean;
  skillCategories: ISkillCategory[];
  selectedSkills: ISelectedSkill[];
  skills?: ISkill[];
  selectedQuestionToEdit?: IQuestionForm | null;
}

const QuestionModal = ({
  skills,
  languageState,
  defaultValues,
  selectTheme,
  skillCategories,
  selectedSkills,
  selectedQuestionToEdit,
  onDelete,
  onClose,
  onSubmit,
  onDuplicate,
  setSelectedSkills,
  setCurrentStep,
  setSelectedSkill,
  setIsSkillModalOpen,
}: IQuestionModelProps) => {
  const { i18n } = useLingui();
  const currentCompany = useSelector(getCurrentCompany);
  const [selectedTheme, setSelectedTheme] = useState<string>();
  const getMultiLangString = useMultiLangString();

  const {
    products: {
      performance: {
        settings: {
          labels: { ratingLabels, skillLabels },
        },
      },
    },
  } = currentCompany;

  const formMethods = useForm<IQuestionForm>({
    mode: 'onSubmit',
    defaultValues: defaultValues || {
      name: languageState.languages.map(({ locale }) => ({
        locale,
        value: '',
      })),
      description: languageState.languages.map(({ locale }) => ({ locale, value: '' })),
      options: getRatingScale(
        REVIEW_QUESTION_TYPES.RATING,
        ratingLabels,
        skillLabels,
        languageState,
      ),
      type: { value: i18n._(t`Rating`), key: REVIEW_QUESTION_TYPES.RATING },
      skillOrKpiCategory: null,
      settings: {
        evaluators: {
          employee: true,
          peer: false,
          coach: true,
        },
        skills: selectedSkills,
        isCommentsAllowed: true,
        isCommentsObligated: false,
        isAnswerObligated: false,
        isMeasurementReversed: false,
        isManualScale: false,
      },
    },
  });

  const { handleSubmit, getValues, setError, watch, reset, setValue, clearErrors } = formMethods;

  const evaluatorWatch = watch('settings.evaluators');
  const manualScaleWatch = watch('settings.isManualScale');
  const skillsWatch = watch('settings.skills');
  const typeWatch = watch('type');

  const hasSkills = (formData: IQuestionForm) => {
    return formData.settings?.skills && formData.settings?.skills?.length > 0;
  };

  const hasEvaluators = (formData: IQuestionForm) => {
    return (
      formData?.type?.key === REVIEW_QUESTION_TYPES.GOAL_PLAN ||
      Object.values(formData.settings?.evaluators).some((evaluator) => evaluator)
    );
  };

  useEffect(() => {
    if (!manualScaleWatch) {
      const defaultRatingScale = getRatingScale(
        getValues().type.key as REVIEW_QUESTION_TYPES,
        ratingLabels,
        skillLabels,
        languageState,
      );
      setValue('options', defaultRatingScale);
    } else {
      setValue('options', getValues().options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeWatch, manualScaleWatch, setValue]);

  useEffect(() => {
    if (selectedQuestionToEdit) {
      const questionType = getQuestionType(selectedQuestionToEdit);
      let skillCategory;
      if (questionType.key === REVIEW_QUESTION_TYPES.SKILL_CATEGORY) {
        skillCategory = skillCategories.find(
          (category) => category.value === selectedQuestionToEdit.settings?.skillCategory,
        );
      }
      if (questionType) {
        const { key, title, id } = questionType;
        const type = {
          value: i18n._(`${title}`),
          key,
          ...(key === REVIEW_QUESTION_TYPES.SKILL_CATEGORY && { id }),
        };

        reset({
          ...selectedQuestionToEdit,
          ...(skillCategory
            ? {
                skillOrKpiCategory: {
                  id: skillCategory.value,
                  key: 'skill-category',
                  value: getMultiLangString(skillCategory.label),
                },
              }
            : {}),
          type,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuestionToEdit]);

  useEffect(() => {
    const formData = getValues();

    if (formData?.type?.key === REVIEW_QUESTION_TYPES.CUSTOM_SKILL && hasSkills(formData)) {
      clearErrors('settings.skills');
    }

    if (hasEvaluators(formData)) {
      clearErrors('settings.evaluators');
    }
  }, [evaluatorWatch, skillsWatch, getValues, clearErrors]);

  const closeModal = () => {
    reset();
    onClose?.();
  };

  const submitModal = (data: IQuestionForm) => {
    selectTheme && selectedTheme ? onSubmit?.(data, selectedTheme) : onSubmit?.(data);
    onClose?.();
  };

  const onThemeSelect = (themeId?: string) => {
    setSelectedTheme(themeId);
  };

  const validateTheme = () => {
    if (selectTheme && !selectedTheme) {
      setError('theme', {
        message: i18n._(t`Theme is required`),
      });
    } else {
      clearErrors('theme');
    }
  };

  const validateEvaluators = (formData: IQuestionForm) => {
    if (
      formData.type.key !== REVIEW_QUESTION_TYPES.GOAL_PLAN &&
      Object.values(formData.settings?.evaluators).filter((evaluator) => evaluator).length === 0
    ) {
      setError('settings.evaluators', {
        message: i18n._(t`There should be at least one evaluator!`),
      });
    } else {
      clearErrors('settings.evaluators');
    }
  };

  const validationQuestionType = (formData: IQuestionForm) => {
    if (
      formData.type.key === REVIEW_QUESTION_TYPES.CUSTOM_SKILL &&
      !formData.settings?.skills?.length
    ) {
      setError('settings.skills', {
        message: i18n._(t`Skills are required!`),
      });
    } else {
      clearErrors('settings.skills');
    }
  };

  const onSave = async (e?: React.BaseSyntheticEvent) => {
    e?.preventDefault();
    const formData = getValues();

    validateTheme();
    validateEvaluators(formData);
    validationQuestionType(formData);

    const submit = handleSubmit(() => {
      return submitModal(formData);
    });

    return submit(e);
  };

  return (
    <Modal
      isHideHeader
      hideFooter
      onClose={closeModal}
      showDivider={false}
      centerModal
      width={750}
      height={'fit-content'}
      borderRadius={10}
      contentStyles={{ padding: '0', overflow: 'auto' }}
    >
      <Wrapper onSubmit={onSave}>
        <HeaderWrapper>
          <Title>
            {selectedQuestionToEdit ? i18n._(t`Edit question`) : i18n._(t`Create a question`)}
          </Title>
          <Button
            label={''}
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.CLOSE}
            onClick={closeModal}
          />
        </HeaderWrapper>
        <EditableQuestion
          skills={skills}
          languageState={languageState}
          formMethods={formMethods}
          onDelete={onDelete}
          onDuplicate={onDuplicate}
          selectedQuestionToEdit={selectedQuestionToEdit}
          selectTheme={selectTheme}
          skillCategories={skillCategories}
          selectedSkills={selectedSkills}
          setSelectedSkill={setSelectedSkill}
          setIsSkillModalOpen={setIsSkillModalOpen}
          onThemeSelect={onThemeSelect}
          setCurrentStep={setCurrentStep}
          setSelectedSkills={setSelectedSkills}
        />
        <Footer>
          <div className="section">
            <NeedHelp>
              <a
                href={
                  getInstructionUrl(INSTRUCTIONS.HOW_TO_CREATE_REVIEW_THEME) as string | undefined
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {i18n._(t`Need help?`)}
              </a>
            </NeedHelp>
          </div>
          <div className="section">
            <CancelButton type="button" onClick={closeModal}>
              {i18n._(t`Cancel`)}
            </CancelButton>
            <SubmitButton type="submit" className="submitButton">
              {i18n._(t`Save`)}
            </SubmitButton>
          </div>
        </Footer>
      </Wrapper>
    </Modal>
  );
};

export { QuestionModal };
