import React, { useEffect, useState } from 'react';

import {
  GOAL_CYCLES_VIRTUAL_STATUSES,
  GOAL_STATUSES_NEW,
  GOAL_TASK_STATUSES,
  GOAL_TYPES,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import DashboardHeader from '~/components/DashboardHeader';
import { GoalDashboardModal } from '~/components/GoalDashboardModal';
import GoalTreeItem from '~/components/GoalsBlock/components/GoalTreeItem';
import { TableList } from '~/components/TableList';
import { Header } from '~/components/TableList/components/Header';
import { TableWrapper } from '~/components/TableList/design';

import {
  CompleteButton,
  ContentContainer,
  GoalsContainer,
  HeaderContainer,
  InnerContainer,
  ListContainer,
  ListHeader,
  SubTitle,
  Title,
  WrongStatusLabel,
} from './design';

import type { IColumnTable } from '~/@types/table';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { usePagination } from '~/hooks/usePagination';
import { getUser } from '~/selectors/baseGetters';
import { getGoalCycles } from '~/services/goalCycles';
import * as goalsService from '~/services/goals';
import * as goalTasksService from '~/services/goalTasks';

import type { IGoal, IGoalTask } from '@learned/types';

const GoalTask = () => {
  const { i18n } = useLingui();
  const history = useHistory();
  const params = useParams();
  const { pagination, changePagination } = usePagination(10);

  const [goalTask, setGoalTask] = useState<IGoalTask>();
  const [goals, setGoals] = useState<IGoal[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedGoal, setSelectedGoal] = useState<IGoal>();

  const $loading = useBoolState(false);
  const $submitting = useBoolState(false);
  const $isDashboard = useBoolState(false);

  const goalTaskId = get(params, 'goalTaskId');
  const user = useSelector(getUser);
  const isOwner = goalTask?.createdFor === user.id;

  const handleClose = () => {
    history.push(routes.DASHBOARD.build());
  };

  const fetchGoals = async () => {
    // find all active goal cycles (same as in Goals page)
    const activeGoalCycles = await getGoalCycles({
      type: GOAL_CYCLES_VIRTUAL_STATUSES.ACTIVE,
    });
    // @ts-ignore
    const goalCycles = Object.values(activeGoalCycles).map((g) => g.id);

    // fetch goals
    // @ts-ignore
    const response = await goalsService.getGoals({
      types: [...Object.values(GOAL_TYPES)], // all goal types
      isMyGoalsOnly: true,
      isGetProgress: true,
      goalCycles,
      statuses: [GOAL_STATUSES_NEW.TODO, GOAL_STATUSES_NEW.IN_PROGRESS, GOAL_STATUSES_NEW.PROBLEM],
      join: ['children'], // we need children to get activities
      limit: pagination.limit,
      skip: pagination.skip,
    });

    setTotalCount(response?.total || 0);
    return response?.data?.map((goal: IGoal) => ({ ...goal, children: [] })); // remove children from all goals (leave only activities)
  };

  const onCompleteTask = async () => {
    $submitting.on();
    await goalTasksService.updateGoalTask(goalTaskId, { status: GOAL_TASK_STATUSES.COMPLETED });
    history.push(routes.DASHBOARD.build());
    $submitting.off();
  };

  useEffect(() => {
    const isMounted = true;

    const fetch = async () => {
      $loading.on();
      const task = await goalTasksService.getGoalTask(goalTaskId);
      if (isMounted) {
        setGoalTask(task);
      }

      if (task && task.status === GOAL_TASK_STATUSES.ACTIVE) {
        const items = await fetchGoals();

        if (isMounted) {
          setGoals(items);
        }
      }
      $loading.off();
    };
    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalTaskId, pagination]);

  const refreshGoals = async () => {
    const goals = await fetchGoals();
    setGoals(goals);
  };

  const onModalClose = () => {
    $isDashboard.off();
    setSelectedGoal(undefined);
    refreshGoals();
  };

  const COLUMNS: IColumnTable<IGoal>[] = [
    {
      name: '',
      accessor: '',
      maxWidth: '920px',
      minWidth: '920px',
      renderCell: (goal: IGoal) => {
        return (
          <GoalTreeItem
            key={goal.id}
            item={goal}
            refreshGoals={refreshGoals}
            isUpdateGoalProgress
            isUpdateActivityProgress
            activitiesReadonly
            isIndividualTab
            isOpenAll={false}
            onGoalClick={(id: string) => {
              setSelectedGoal(goals.find((g) => g.id === id));
              $isDashboard.on();
            }}
            isHideMenuItems={true}
          />
        );
      },
    },
  ];

  const renderListHeader = () => {
    const COLUMNS: IColumnTable[] = [
      {
        accessor: 'type',
        name: i18n._(t`Type`),
        minWidth: '20px',
      },
      {
        accessor: 'name',
        name: i18n._(t`Name`),
        maxWidth: '490px',
      },
      {
        accessor: 'owner',
        name: i18n._(t`Owner(s)`),
        minWidth: '20px',
      },
      {
        accessor: 'progress',
        name: i18n._(t`Progress`),
        minWidth: '20px',
      },
      {
        accessor: 'status',
        name: i18n._(t`Status`),
        minWidth: '20px',
      },
    ];
    return (
      <TableWrapper $isPointer>
        <ListHeader>
          <Header columns={COLUMNS} />
        </ListHeader>
      </TableWrapper>
    );
  };

  return (
    <>
      <DashboardHeader
        title={i18n._(t`Goal update`)}
        // @ts-ignore
        onBack={handleClose}
        actions={
          <CompleteButton
            label={i18n._(t`Complete`)}
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={onCompleteTask}
            isLoading={$submitting.value}
            disabled={!isOwner || goalTask?.status !== GOAL_TASK_STATUSES.ACTIVE}
          />
        }
      />
      <GoalsContainer>
        <InnerContainer>
          <HeaderContainer>
            <Title>{i18n._(t`Update your goals`)}</Title>
            <SubTitle>{i18n._(t`Please update the progress of your active goals.`)}</SubTitle>
          </HeaderContainer>
          <ContentContainer>
            {goalTask?.status === GOAL_TASK_STATUSES.ACTIVE && renderListHeader()}
            {goalTask?.status === GOAL_TASK_STATUSES.ACTIVE ? (
              <ListContainer>
                <TableList
                  isLoading={$loading.value}
                  data={goals}
                  columns={COLUMNS}
                  filtersProps={{
                    // according to designs, there are no filters
                    filters: { search: '', setSearch: () => {} },
                    isFiltered: false,
                  }}
                  paginationProps={{
                    pagination: {
                      skip: pagination.skip,
                      limit: pagination.limit,
                      index: pagination.index,
                    },
                    changePagination,
                    totalCount,
                    paginationItemLabel: i18n._(t`item`),
                  }}
                  isHideTop={true}
                  placeholderProps={{
                    emptyStateText: i18n._(t`No goals to update`),
                  }}
                />
              </ListContainer>
            ) : (
              goalTask && (
                <WrongStatusLabel>
                  {goalTask?.status === GOAL_TASK_STATUSES.EXPIRED
                    ? i18n._(t`This task has ${goalTask?.status}`)
                    : i18n._(t`Goal task is ${goalTask?.status}`)}
                </WrongStatusLabel>
              )
            )}
          </ContentContainer>
        </InnerContainer>
        {$isDashboard.value && selectedGoal && (
          <GoalDashboardModal
            goalId={selectedGoal.id}
            onCloseModal={onModalClose}
            isUpdatesAndNotes={true}
          />
        )}
      </GoalsContainer>
    </>
  );
};

export default GoalTask;
