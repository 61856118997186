import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

import { MenuBlockIcon } from '../../styles/menuItemDesign';

const LinkStyled = styled(Link)`
  text-decoration: none;
  width: 100%;
  display: flex;
`;

const MenuBlockIconStyled = styled(MenuBlockIcon)`
  width: 20px;
  min-width: 20px;
`;

const MenuColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-right: 14px;
  height: 40px;
  box-sizing: border-box;
`;

const MenuColumnStyled = styled(MenuColumn)<{
  isHovering?: boolean;
}>`
  overflow: hidden;
  margin-right: ${({ isHovering }) => (isHovering ? '0px' : '14px')};
`;

const ContextMenuWrapper = styled.div`
  flex: 1 1 20%;
`;

const ContextOptionWrapper = styled.div`
  & svg {
    color: ${COLORS.TEXT_MAIN} !important;
  }
`;

const MenuItem = styled.div<{ isCurrentRoute?: boolean }>`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
  color: ${COLORS.TEXT_MAIN};
  border-radius: 6px;
  &:hover {
    background-color: ${COLORS.WHITE};
    color: ${COLORS.TEXT_HOVER};
  }
  ${({ isCurrentRoute }) =>
    isCurrentRoute &&
    css`
      background-color: ${COLORS.COMPANY};
      color: ${COLORS.WHITE};
      &:hover {
        background-color: ${COLORS.COMPANY};
        color: ${COLORS.WHITE};
      }
    `}
`;

const SecondaryMenuWrapper = styled.div`
  background-color: ${COLORS.BG_PAGE};
  width: 200px;
  overflow: auto;
  border-right: 1px solid ${COLORS.BORDERS};
  padding: 10px 10px 0;
  box-sizing: border-box;
`;

const Divider = styled.div<{ halfMargin?: boolean }>`
  width: 100%;
  height: 1.5px;
  background: ${COLORS.BORDERS};
  margin: ${({ halfMargin }) => (halfMargin ? '8px 0 0' : '16px 0 0')};
  flex: none;
  border-radius: 2px;
`;

const RenameModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: ${COLORS.WHITE};
  padding: 24px;
  border-radius: 6px;
`;

const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.TEXT_MAIN};
  font-weight: 500;
  font-size: 22px;
  justify-content: space-between;
  .close {
    cursor: pointer;
  }
`;

const ModalBody = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  color: ${COLORS.TEXT_MAIN};
  font-size: 14px;
  gap: 6px;
`;

const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

const ModalInput = styled.input`
  padding: 6px 12px;
  border: 1px solid ${COLORS.BORDERS};
  border-radius: 6px;
`;

export {
  LinkStyled,
  MenuColumn,
  MenuBlockIconStyled,
  MenuColumnStyled,
  ContextMenuWrapper,
  ContextOptionWrapper,
  MenuItem,
  SecondaryMenuWrapper,
  Divider,
  RenameModal,
  ModalTitle,
  ModalContent,
  ModalBody,
  ModalInput,
  ModalFooter,
};
