import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Col = styled.div<{
  $withBorders?: boolean;
}>`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.DARK_GRAY};
  box-sizing: border-box;
  padding: 0 36px;

  ${(props) =>
    props.$withBorders &&
    css`
      border: 1.2px solid ${COLORS.BORDER_HARD};
      border-top: none;
      border-bottom: none;
    `}
`;

const Label = styled.span`
  margin-right: 6px;
`;

interface ISectionInfo {
  deadline?: Date | null;
  isAnonymous: boolean;
  amountOfQuestions: number;
}

const SectionInfo = ({ deadline, isAnonymous, amountOfQuestions }: ISectionInfo) => {
  const { i18n } = useLingui();
  return (
    <Block>
      <Col>
        <Label>{i18n._(t`Deadline:`)}</Label>
        {deadline ? convertToTimeString(deadline, TIME_FORMATS.CLASSIC) : i18n._(t`no`)}
      </Col>
      <Col $withBorders>
        <Label>{i18n._(t`Questions:`)}</Label>
        {amountOfQuestions}
      </Col>
      <Col>
        <Label>{i18n._(t`Anonymous:`)}</Label>
        {isAnonymous ? i18n._(t`yes`) : i18n._(t`no`)}
      </Col>
    </Block>
  );
};

export { SectionInfo };
