import { useCallback } from 'react';

import { useToasts as useToastsLibrary } from 'react-toast-notifications';

import type { AddToast } from '~/components/Toast/types';

function useToasts() {
  const { addToast } = useToastsLibrary();

  const add: AddToast = useCallback(
    ({ title, subtitle, type }) => addToast(title, { subtitle, type }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { addToast: add };
}

export { useToasts };
