import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 * still in use, no replacement yet
 */
const ArrowIcon = ({ size, ...props }) => (
  <InlineSvg height={size} width={size} {...props} childrenFirst viewBox="0 0 8 6">
    <text
      transform="rotate(90 1544.5 456)"
      fill="currentColor"
      fillRule="evenodd"
      fontFamily="Poppins-Regular, Poppins"
      fontSize="14"
      letterSpacing=".7"
    >
      <tspan x="1087.5" y="2001.5">
        &lt;
      </tspan>
    </text>
  </InlineSvg>
);

ArrowIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

ArrowIcon.defaultProps = {
  size: 8,
  className: '',
};

export default ArrowIcon;
