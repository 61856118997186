import React from 'react';

import { StyledDropdown, StyledSelect } from '~/components/DateRangePicker/design';
import { minutesInDay, stringifyTime } from '~/components/DateRangePicker/utils';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { COLORS } from '~/styles';

function CustomSelectComponent({
  clicked,
  onClick,
  selectedItem,
}: {
  clicked: boolean;
  onClick: () => void;
  selectedItem: number | undefined;
}) {
  return (
    <StyledSelect $clicked={clicked} $active={false} onClick={onClick}>
      <Icon icon={ICONS.TIME} size={ICON_SIZES.MEDIUM} color={COLORS.TIPPY_BACKGROUND} />
      {selectedItem !== undefined && stringifyTime(selectedItem)}
    </StyledSelect>
  );
}

function TimePicker({
  selectedTime,
  onChange,
}: {
  selectedTime?: number;
  onChange: (item?: number) => void;
}) {
  return (
    <StyledDropdown
      items={minutesInDay}
      selectedItem={selectedTime}
      onChange={onChange}
      stringifyItem={stringifyTime}
      CustomDropdownComponent={CustomSelectComponent}
      isSingleSelect
      skipSort
      disableTooltip
    />
  );
}

export { TimePicker };
