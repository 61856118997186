import React from 'react';

import { MultiSearchSelect } from './MultiSelectSearch';
import { MultiSelectSearchExpandable } from './MultiSelectSearchExpandable';
import { SingleSearchSelect } from './SingleSelectSearch';

import type { SearchSelectProps } from './types';

function SearchSelect<T = unknown>(props: SearchSelectProps<T>) {
  const stringifyItem = props.stringifyItem
    ? props.stringifyItem
    : (item: unknown) => item as string;

  if (props.isSingleSelect) {
    return <SingleSearchSelect<T> {...props} stringifyItem={stringifyItem} />;
  }

  if (!props.isSingleSelect && !props.isExpandable) {
    return <MultiSearchSelect<T> {...props} stringifyItem={stringifyItem} />;
  }

  if (!props.isSingleSelect && props.isExpandable) {
    return <MultiSelectSearchExpandable<T> {...props} stringifyItem={stringifyItem} />;
  }

  return <></>;
}

export { SearchSelect };
