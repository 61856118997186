import React from 'react';

import { SKILL_TEMPLATE_SORT_OPTIONS } from '@learned/constants';
import { t } from '@lingui/macro';
import find from 'lodash/find';

import { NameRow } from './SuperAdminPendingSkills.design';

import type { IColumnTable } from '~/@types/table';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

import type { ISkillTemplate } from '@learned/types';
import type { I18n } from '@lingui/core';

export const SKILL_TEMPLATES_COLUMNS: IColumnTable<
  ISkillTemplate & { fractionUsedByCompanies: number }
>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Skill`),
    accessor: 'skill',
    renderCell: (item) => {
      return <NameRow>{item.name.en_GB || item.name.nl_NL || find(item.name)}</NameRow>;
    },
    sortBy: {
      asc: {
        key: SKILL_TEMPLATE_SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: SKILL_TEMPLATE_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Status`),
    accessor: 'status',
    renderCell: (item) => {
      return <NameRow>{item.status}</NameRow>;
    },
    sortBy: {
      asc: {
        key: SKILL_TEMPLATE_SORT_OPTIONS.STATUS_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: SKILL_TEMPLATE_SORT_OPTIONS.STATUS_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Used`),
    accessor: 'fractionUsedByCompany',
    maxWidth: '200px',

    renderCell: (item) => {
      let percentage = (item.fractionUsedByCompanies ?? 0) * 100;
      // modify percentage to always be two decimal places
      percentage = Math.round(percentage * 100) / 100;
      return <NameRow>{`${percentage}%`}</NameRow>;
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Last updated`),
    accessor: 'meta.lastModifiedDate',
    maxWidth: '200px',
    sortBy: {
      asc: {
        key: SKILL_TEMPLATE_SORT_OPTIONS.LAST_UPDATED_NEW_OLD,
        label: (i18n: I18n) => i18n._(t`New - old`),
      },
      desc: {
        key: SKILL_TEMPLATE_SORT_OPTIONS.LAST_UPDATED_OLD_NEW,
        label: (i18n: I18n) => i18n._(t`Old - new`),
      },
    },
    renderCell: (item) => {
      return (
        <NameRow>{convertToTimeString(item.meta?.lastModifiedDate, TIME_FORMATS.CLASSIC)}</NameRow>
      );
    },
  },
];
