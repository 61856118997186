import React from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Input } from '~/components/Input';
import Modal from '~/components/Modal';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { Footer, Form } from './design';
import { type IJobFamiliesModalProps, Locals } from './types';
import { useJobFamiliesModal } from './useJobFamiliesModal';

import { deleteJobFamiliesSuperAdmin } from '~/services/jobFamilies';

const JobFamiliesModal = ({ jobFamily, closeModal, refetchData }: IJobFamiliesModalProps) => {
  const { addToast } = useToasts();
  const { onSubmit, formMethods, i18n } = useJobFamiliesModal({
    jobFamily,
    closeModal,
    refetchData,
  });
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const onDelete = async () => {
    if (jobFamily) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        title: i18n._(t`Are you sure want to delete the job family?`),
        description: i18n._(t`Deleting job family cannot be undone!`),
      });
      if (isConfirmed) {
        await deleteJobFamiliesSuperAdmin([jobFamily.id]);

        // confirm toast
        addToast({
          title: i18n._(t`Job family deleted!`),
          type: TOAST_TYPES.INFO,
        });
        // // re-fetch items
        await refetchData();
        closeModal();
      }
    }
  };

  return (
    <Modal
      title={i18n._(t`Create job family`)}
      onClose={closeModal}
      showDivider={false}
      centerModal
      width={500}
      contentStyles={{ padding: '20px 40px 0', overflow: 'visible' }}
      headerStyles={{
        padding: '20px 40px',
        fontSize: '26px',
        fontWeight: 'normal',
      }}
      borderRadius={6}
      hideFooter
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        {Object.values(Locals).map((lang, index) => (
          <Input
            key={`${lang}-${index}`}
            label={index === 0 && i18n._(t`Job family`)}
            register={register(`name.${lang}`, { required: true })}
            leftIcon={getUnicodeFlagIcon(lang.substring(lang.indexOf('_') + 1))}
            placeholder={i18n._(t`Job family`)}
          />
        ))}

        <Footer>
          <div>
            <Button
              label={i18n._(t`Cancel`)}
              onClick={closeModal}
              type="button"
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
            />
            <Button
              label={i18n._(t`Save`)}
              type="submit"
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              disabled={!isValid}
            />
          </div>
          {jobFamily && (
            <Button
              label={i18n._(t`Delete`)}
              onClick={onDelete}
              type="button"
              variant={ButtonVariant.TEXT_DELETE}
              size={ButtonSize.MEDIUM}
              className="delete"
            />
          )}
        </Footer>
      </Form>
    </Modal>
  );
};

export { JobFamiliesModal };
