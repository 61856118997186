import { useCallback } from 'react';

import qs from 'qs';
import { useHistory, useLocation } from 'react-router';

import routes from '~/constants/routes';

function useFromQuery({ includeHash = false, defaultRoute = routes.HOME } = {}) {
  const location = useLocation();
  const history = useHistory();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const from = query.from;
  const hash = includeHash ? location.hash : '';

  const goBack = useCallback(() => {
    // @ts-ignore
    from ? history.push(`${from}${hash}` as string) : history.push(defaultRoute);
  }, [from, hash, history, defaultRoute]);

  return { from, goBack };
}

export { useFromQuery };
