import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getCompanyDeleteRequests({ filters = {}, options = {} } = {}) {
  return cloudRequest(serverRoutes.companyDeleteRequests.getCompanyDeleteRequests, null, {
    filters,
    options,
  });
}

export function createCompanyDeleteRequest({ name, company } = {}) {
  return cloudRequest(serverRoutes.companyDeleteRequests.createCompanyDeleteRequest, null, {
    name,
    company,
  });
}

export function handleCompanyDeleteRequest(id, { comment, status } = {}) {
  return cloudRequest(serverRoutes.companyDeleteRequests.handleCompanyDeleteRequest(id), null, {
    comment,
    status,
  });
}
