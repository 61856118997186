export default class BrowserStorage {
  /**
   * @param {string} provider
   */
  constructor(provider) {
    this.provider = provider;
  }

  /**
   * @param {string} key
   * @returns {string}
   */
  generateKey(key) {
    return `${this.provider}.${key}`;
  }

  removeItem(key) {
    localStorage.removeItem(this.generateKey(key));
  }

  setItem(key, value) {
    localStorage.setItem(this.generateKey(key), value);
  }

  getItem(key) {
    return localStorage.getItem(this.generateKey(key));
  }

  clearAll() {
    // TODO (task is create) add method createAll in BrowserStorage and check where it could be used
  }
}
