import styled from 'styled-components';

import { Button } from '~/components/Buttons';

import { COLORS } from '~/styles';

export const Card = styled.div<{
  marginTop?: string;
  noFrontPadding?: boolean;
}>`
  margin-top: ${({ marginTop }) => marginTop || '16px'};
  background-color: ${COLORS.WHITE};
  padding: 30px 0;
  border-radius: 10px;
  box-sizing: border-box;
`;

export const Content = styled.div`
  max-height: 80vh;
  overflow-x: hidden;
  padding: 23px 36px 0;
`;

export const Row = styled.div`
  margin-bottom: 12px;
`;

export const Footer = styled.div`
  padding: 20px 0;
  margin: 0 36px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${COLORS.HOVER};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: auto;
  padding: 10px 15px;
  border-bottom: 1px solid ${COLORS.HOVER};
`;

export const TitleWrapper = styled.div`
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 26px;
  line-height: 1;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin: 0px;
  padding: 8px 14px 0;
`;

export const HeaderActions = styled.div`
  display: flex;
  gap: 2px;
  color: ${COLORS.SUBTEXT};
  align-items: center;
`;

export const DeleteButton = styled(Button)`
  svg {
    height: 18px;
    width: 18px;
  }
`;

export const ArchiveButton = styled(Button)`
  svg {
    height: 20px;
    width: 20px;
  }
`;

export const SaveButton = styled(Button)`
  svg {
    height: 18px;
    width: 18px;
  }
`;

export const CloseButton = styled(Button)`
  height: 34px;
  width: 34px;
`;
