import styled from 'styled-components';

import { COLORS } from '~/styles';

const Title = styled.div`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
  padding: 0 0 4px 0;
  font-weight: 600;
`;

const MessageContainer = styled.div`
  padding-left: 10px;
`;

const MessageInnerContainer = styled.div`
  display: flex;
`;

const MessageTriangle = styled.div`
  width: 0;
  height: 0;
  border-top: 0 solid ${COLORS.BG_LIST};
  border-bottom: 14px solid transparent; // height
  border-right: 20px solid ${COLORS.BG_LIST}; // width
  margin-right: 0; /* Space between the triangle and the rectangle */
`;

const MessageBox = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${COLORS.BG_LIST};
  border-radius: 6px;
  padding: 10px 18px;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 8px 0;
  gap: 20px;
`;

export {
  Title,
  MessageContainer,
  MessageInnerContainer,
  MessageTriangle,
  MessageBox,
  ActionContainer,
};
