import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import Button from '~/components/Button';
import Modal from '~/components/Modal';

import { RATING_TYPES, RT_FEEDBACK_TYPES } from '~/constants';
import { COLORS } from '~/styles';

const BlockTitle = styled.div`
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: ${COLORS.TEXT_PRIMARY_2};
  margin-bottom: 24px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

class FeedbackThanksModal extends Component {
  getTitle = () => {
    const { i18n, isSelfReview, type } = this.props;
    const feedbackType = isSelfReview ? 'isSelfReview' : type;
    switch (feedbackType) {
      case 'isSelfReview':
        return i18n._(t`Great job!`);
      case RT_FEEDBACK_TYPES.ASK_FEEDBACK.key:
        return i18n._(t`Request sent!`);
      case RT_FEEDBACK_TYPES.GIVE_FEEDBACK.key:
        return i18n._(t`Feedback sent!`);
      default:
        return i18n._(t`Thanks for your feedback!`);
    }
  };

  getSubTitle = () => {
    const { i18n, fromUser, isSelfReview, type } = this.props;

    let fromUserName = '';
    if (type === RATING_TYPES.OUTSIDE_RATING) {
      fromUserName = fromUser;
    } else if (!isEmpty(fromUser)) {
      fromUserName = `${fromUser.firstName}${fromUser.lastName ? ' ' + fromUser.lastName : ''}`;
    }

    const feedbackType = isSelfReview ? 'isSelfReview' : type;

    switch (feedbackType) {
      case 'isSelfReview':
        return i18n._(t`Keep on working on your development!`);
      case RT_FEEDBACK_TYPES.ASK_FEEDBACK.key:
        return i18n._(t`Good job asking for feedback`);
      default:
        return i18n._(t`${fromUserName} will probably be very happy`);
    }
  };

  render() {
    const { i18n, onClose } = this.props;

    return (
      <Modal
        className="new-team-modal"
        title={<Title>{this.getTitle()}</Title>}
        onClose={() => this.props.onClose()}
        width={350}
        minWidth={350}
        minHeight={'435px'}
        contentStyles={{
          background: '#fff',
          height: '100%',
          padding: '8px 16px 24px',
          boxSizing: 'border-box',
        }}
        headerStyles={{
          padding: '32px 0 0 0',
          height: '60px',
          minHeight: '60px',
          display: 'flex',
          justifyContent: 'center',
        }}
        hideFooter={true}
        hideHeaderClose={true}
        showDivider={false}
      >
        <Column>
          <BlockTitle>{this.getSubTitle()}</BlockTitle>
          {onClose && (
            <Button
              type="primary"
              label={i18n._(t`Ok`)}
              styles={{ width: '131px' }}
              onClick={() => onClose()}
            />
          )}
        </Column>
      </Modal>
    );
  }
}

export default withI18n()(FeedbackThanksModal);
