import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import findIndex from 'lodash/findIndex';
import findLastIndex from 'lodash/findLastIndex';
import isEqual from 'lodash/isEqual';
import maxBy from 'lodash/maxBy';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Legend from '~/components/Legend';
import { getAverageRatesForSkill } from '~/components/NewReviewReport/components/utils';
import Placeholder from '~/components/Placeholder';
import SkillProgressBar from '~/components/SkillProgressBar';

import { InfoBlock, Info, Title, PlaceholderWrapper } from './styledComponents';

import { RATING_TYPES } from '~/constants';
import { GIVE_FEEDBACK_LEGEND } from '~/constants/reviews';
import { getUser } from '~/selectors/baseGetters';
import getAllUsers from '~/selectors/getAllUsers';
import { COLOR_PALETTE } from '~/styles';

const ProgressBarWrap = styled.div`
  box-sizing: border-box;
  padding-right: 20px;
  width: 100%;
  margin-top: 40px;
`;

const HiddenTalentText = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledInfo = styled(Info)`
  flex-direction: column;
`;

const StyledLegend = styled(Legend)`
  margin-top: 40px;
`;

const getScaleAndLabels = (skill, companyLabels, ratings) => {
  // Get scale and label from most recent rating
  if (ratings.length > 0) {
    const mostRecentRating = maxBy(ratings, (rating) => rating.meta.createdDate);
    return [mostRecentRating.scaleLabels, mostRecentRating.scale];
  }

  // Get scale and label company settings but remove disabled levels from skill
  const firstLevel = findIndex(skill.levelsEnabled, (elem) => elem === true);
  const lastLevel = findLastIndex(skill.levelsEnabled, (elem) => elem === true) + 1;
  const scaleLabels = companyLabels
    .map((label, index) => (skill.levelsEnabled[index] ? label : label)) // Set labels that are disabled as empty string
    .slice(firstLevel, lastLevel); // Remove trailing and leading disabled labels
  const scale = Array.from({ length: lastLevel - firstLevel }, (_, k) => k + 1);

  return [scaleLabels, scale];
};

const filterRatings = (ratings, scaleLabels, scale) => {
  return ratings.filter(
    (rating) => isEqual(rating.scale, scale) && isEqual(rating.scaleLabels, scaleLabels),
  );
};

const RatingsBlock = ({
  ratings,
  skill,
  expectedLevel,
  userId,
  className,
  isHideExpectedLevel = false,
}) => {
  const { i18n } = useLingui();
  const users = useSelector(getAllUsers);
  const viewer = useSelector(getUser);
  const companyLabels = useSelector((state) => state.companySettings.skillLabels);

  const [scaleLabels, scale] = getScaleAndLabels(skill, companyLabels, ratings);
  const filteredRatings = filterRatings(ratings, scaleLabels, scale);
  const averageRatings = getAverageRatesForSkill({
    i18n,
    feedbackRatings: filteredRatings,
    expectedLevel,
  });
  const user = userId ? users[userId] : viewer;

  const selfRating = averageRatings?.avgSelfRating?.progress ?? 0;
  const isHiddenTalent =
    averageRatings?.avgCoachesRating?.progress > selfRating ||
    averageRatings?.avgPeersRating?.progress > selfRating;

  let expectedLevelPercentage;
  if (expectedLevel) {
    const expectedLevelIdx = scale.indexOf(expectedLevel);
    expectedLevelPercentage =
      expectedLevelIdx !== -1
        ? Math.round(((expectedLevelIdx + 1) / scale.length) * 100)
        : Math.round((expectedLevel / scale[scale.length - 1]) * 100);
  }

  const legend = [
    {
      color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.SELF_RATING].color,
      label: i18n._(t`${user?.firstName}’s input`),
    },
    {
      color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.OTHER_RATING].color,
      label: GIVE_FEEDBACK_LEGEND[RATING_TYPES.OTHER_RATING].label(i18n),
    },
    {
      color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.COACH_RATING].color,
      label: GIVE_FEEDBACK_LEGEND[RATING_TYPES.COACH_RATING].label(i18n),
    },
    !isHideExpectedLevel && {
      color: COLOR_PALETTE.GRAY_MIDDLE,
      label: i18n._(t`Expected level for role`),
    },
  ].filter((l) => l);

  return (
    <InfoBlock className={className}>
      <Title>
        <Trans>Average rating</Trans>
      </Title>
      <StyledInfo>
        {isHiddenTalent && (
          <HiddenTalentText>
            <Trans>
              Hidden Talent: Peers and/or coaches evaluate this skill higher than yourself
            </Trans>
          </HiddenTalentText>
        )}
        {(ratings.length > 0 || expectedLevel) && (
          <ProgressBarWrap>
            <SkillProgressBar
              items={averageRatings}
              scale={scale}
              scaleLabels={scaleLabels}
              isShowLabels
              expectedLevel={expectedLevelPercentage}
            />
            <StyledLegend items={legend} />
          </ProgressBarWrap>
        )}
      </StyledInfo>
      {ratings.length === 0 && !expectedLevel && (
        <PlaceholderWrapper>
          <Placeholder
            title={i18n._(t`No ratings`)}
            subTitle={i18n._(t`You have not been rated on this skill`)}
          />
        </PlaceholderWrapper>
      )}
    </InfoBlock>
  );
};

export { RatingsBlock };
