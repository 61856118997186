import React from 'react';
import type { ReactNode } from 'react';

import { Icon, ICONS } from '~/components/Icon';

import { Container, IconContainer, Subtitle, Text, Title } from './IconInfo.design';

function IconInfo({
  title,
  subtitle,
  icon,
}: {
  title: ReactNode;
  subtitle: ReactNode;
  icon: ICONS;
}) {
  return (
    <Container>
      <IconContainer>
        <Icon icon={icon} />
      </IconContainer>
      <Text>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Text>
    </Container>
  );
}

export { IconInfo };
