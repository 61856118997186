import React, { PureComponent } from 'react';

import { Trans } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import size from 'lodash/size';
import values from 'lodash/values';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import styled, { css } from 'styled-components';

import Hint from '~/components/Hint';
import SortDownIcon from '~/components/Icons/SortDown';
import SortUpIcon from '~/components/Icons/SortUp';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import Tooltip from '~/components/Tooltip';
import Divider from '~/components/UI/Divider';

import { COLORS, COLOR_PALETTE } from '~/styles';

const ROW_HEIGHT = 64;

const TableCol = styled.td`
  padding: 4px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  position: relative;
  height: ${(props) => props.height || ROW_HEIGHT + 'px'};
  color: ${(props) => (props.warn ? COLORS.ERROR : COLORS.TEXT_PRIMARY)};
  vertical-align: ${(props) => props.verticalAlign || 'middle'};
  cursor: ${(props) => (props.onClick || props.pointer ? 'pointer' : 'default')};
  ${(props) => {
    if (props.right) {
      return css`
        position: sticky;
        right: 0;
        z-index: 2;
        padding-right: 0 !important;
        height: 100%;
      `;
    }
    if (props.left) {
      return css`
        position: sticky;
        left: 0;
        z-index: 2;
        padding-left: 0 !important;
        height: 100%;
      `;
    }
  }}
`;

const TableHeaderCol = styled.th`
  padding: 10px 4px;
  box-sizing: border-box;
  line-height: 1.6;
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => (props.accent ? COLORS.TEXT_PRIMARY_2 : COLOR_PALETTE.DARK_GRAY)};
  text-align: left;
  text-transform: uppercase;
  cursor: ${(props) => (props.onClick ? 'pointer' : '')};
  vertical-align: ${(props) => props.verticalAlign || 'middle'};
`;

const TableRow = styled.tr`
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  height: ${(props) => props.height || ROW_HEIGHT + 'px'};
  border-bottom: ${(props) => (!props.$noBorder ? `1px solid ${COLORS.BG_PAGE}` : 'none')};

  ${(props) =>
    props.verticalAlign &&
    css`
      & ${TableCol} {
        vertical-align: ${props.verticalAlign};
      }
    `}

  ${(props) =>
    !props.noHover &&
    css`
      &:hover {
        z-index: 3;
        box-shadow: 0 -1px 2px -2px rgba(0, 0, 0, 0.2), 0 3px 2px -2px rgba(0, 0, 0, 0.2);
        td {
          -webkit-box-shadow: 0 -1px 2px -2px rgba(0, 0, 0, 0.2), 0 3px 2px -2px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 -1px 2px -2px rgba(0, 0, 0, 0.2), 0 3px 2px -2px rgba(0, 0, 0, 0.2);
        }
      }
    `}

  .board-item_actions {
    padding: 0;
    padding-right: 0 !important;
    margin: 0;
  }
  h3 {
    margin: 0;
  }
`;

const TableBlock = styled.table`
  height: 1px;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  border-bottom: 1px solid ${COLORS.BG_PAGE};

  td,
  th {
    &:first-child {
      padding-left: ${(props) => (props.sidePadding ? '16px' : '0')};
    }

    &:last-child {
      padding-right: ${(props) => (props.sidePadding ? '16px' : '0')};
    }
  }
  & tr:last-child {
    border-bottom: ${(props) => !props.lastBorder && 'none'};
  }
`;

const TableHeader = styled.tr`
  display: ${(props) => props.isHideHeader && 'none'};
`;

const HintWrapper = styled(Hint)``;

const HiddenHeader = styled.tr`
  &.tr-hidden th {
    padding-top: 0;
    font-size: 0;
    padding-bottom: 0;
  }

  &.tr-hidden th ${HintWrapper} {
    height: 0px;
    overflow-y: hidden;
  }
`;

export const Placeholder = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 144px;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  color: ${(props) => (props.error ? COLORS.ERROR : '#434343')};
`;

const SortContainer = styled.span`
  vertical-align: middle;
  margin-left: 4px;
  display: inline-block;
`;

const TitleWrap = styled.span`
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
`;

class Table extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool,
    sidePadding: PropTypes.bool,
    className: PropTypes.string,
    cols: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        width: PropTypes.string,
        name: PropTypes.string,
        accent: PropTypes.bool,
        hint: PropTypes.string,
      }),
    ),
    colsSecond: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        width: PropTypes.string,
        name: PropTypes.string,
        accent: PropTypes.bool,
        hint: PropTypes.string,
      }),
    ),
    renderHeaderCol: PropTypes.func,
    renderRow: PropTypes.func,
    defaultSort: PropTypes.object,
  };

  static defaultProps = {
    loading: false,
    scrollAutoHide: false,
    sidePadding: true,
    renderHeaderCol: Table.renderHeaderCol,
    className: '',
    cols: [],
    colsSecond: [], // second row of cols
    items: [],
  };

  static renderHeaderCol(column, onClick, SortComponent, hidden = false) {
    if (Object.prototype.hasOwnProperty.call(column, 'show') && !column.show) {
      return null;
    }

    return (
      <TableHeaderCol
        key={column.name || column.title}
        width={column.width}
        accent={column.accent}
        onClick={onClick}
        verticalAlign={column.verticalAlign}
        style={column.style}
      >
        {column.title &&
          (column.tippy ? (
            <Tooltip
              placement={column.placement}
              tooltip={column.tippy}
              {...(column.tooltipProps || {})}
            >
              <TitleWrap>
                <Trans id={column.title} />
              </TitleWrap>
            </Tooltip>
          ) : (
            <Trans id={column.title} />
          ))}
        {column.hint && <HintWrapper>{column.hint}</HintWrapper>}
        {column.action}
        {SortComponent}
        {!hidden && column.node}
      </TableHeaderCol>
    );
  }

  static renderRow(row) {
    return (
      <TableRow $border>
        {row.cols.map((c) => (
          <TableCol key={c}>{c}</TableCol>
        ))}
      </TableRow>
    );
  }

  static SORT_DIRECTION = {
    ASC: 'asc',
    DESC: 'desc',
  };

  static sortIcon = {
    [Table.SORT_DIRECTION.ASC]: SortUpIcon,
    [Table.SORT_DIRECTION.DESC]: SortDownIcon,
  };

  defaultDirection = Table.SORT_DIRECTION.ASC;

  constructor(props) {
    super(props);

    this.state = {
      sortCol: props.defaultSort,
      sortDirection: this.defaultDirection,
    };
  }

  defaultSortGetter = (key) => (val) => val[key];

  getSortItems() {
    const { items } = this.props;
    const { sortCol, sortDirection } = this.state;
    if (sortCol && (sortCol.property || sortCol.getValue) && sortCol.sort) {
      const getter = sortCol.getValue || this.defaultSortGetter(sortCol.property);

      let result;
      if (sortCol.property === 'custom' && !isEmpty(sortCol.orderKeys)) {
        result = orderBy(values(items), sortCol.orderKeys, sortCol.orderDirections);
      } else {
        result = values(items).sort((a, b) => {
          let x1 = getter(a);
          let x2 = getter(b);

          switch (sortCol.dataType) {
            case 'date': {
              if (!x1 && !x2) {
                return 0;
              }
              if (x1 && !x2) {
                return 1;
              }
              if (!x1 && x2) {
                return -1;
              }
              return moment(x1).diff(x2);
            }
            case 'number':
            case 'string': {
              if (x1 && !x2) {
                return 1;
              }
              if (x2 && !x1) {
                return -1;
              }
              if (!x1 && !x2) {
                return 0;
              }
              return x1.localeCompare(x2);
            }
            default: {
              if (typeof x1 === 'string') {
                x1 = x1.toLowerCase();
              }
              if (typeof x2 === 'string') {
                x2 = x2.toLowerCase();
              }

              return x1 - x2;
            }
          }
        });
      }

      if (sortDirection === Table.SORT_DIRECTION.DESC) {
        return result.reverse();
      }
      return result;
    }

    return values(items);
  }

  renderContent() {
    const { items, renderRow, cols, loading, hidePlaceHolder, textPlaceholder } = this.props;

    if (loading || size(items) === 0) {
      return !hidePlaceHolder ? (
        <TableRow noHover>
          <TableCol colSpan={cols.length}>
            <Placeholder>
              <ShowSpinnerIfLoading type="button-primary" loading={loading}>
                {textPlaceholder || <Trans>There is nothing here yet</Trans>}
              </ShowSpinnerIfLoading>
            </Placeholder>
          </TableCol>
        </TableRow>
      ) : null;
    }

    return this.getSortItems().map(renderRow || Table.renderRow);
  }

  changeSorting = (column) => {
    if (!column.sort || (!column.property && !column.getValue)) {
      return null;
    }

    return () => {
      const { sortCol, sortDirection } = this.state;
      const sameCol = sortCol && sortCol.title === column.title;
      const isDefault = sortDirection === this.defaultDirection;
      const differentDirection =
        this.defaultDirection === Table.SORT_DIRECTION.ASC
          ? Table.SORT_DIRECTION.DESC
          : Table.SORT_DIRECTION.ASC;
      this.setState({
        sortCol: column,
        sortDirection: sameCol && isDefault ? differentDirection : this.defaultDirection,
      });
    };
  };

  render() {
    const {
      cols,
      colsSecond,
      renderHeaderCol,
      className,
      maxHeight,
      scrollAutoHide,
      scrollMaxItems,
      items,
      sidePadding,
      isHideHeader,
      lastBorder,
    } = this.props;
    const { sortCol, sortDirection } = this.state;

    const height =
      maxHeight || (scrollMaxItems && size(items) > scrollMaxItems && scrollMaxItems * ROW_HEIGHT);
    const content = (
      <TableBlock sidePadding={sidePadding} lastBorder={lastBorder}>
        <tbody>
          <HiddenHeader className="tr-hidden">
            {cols.map((c) => renderHeaderCol(c, null, null, true))}
          </HiddenHeader>
          {this.renderContent()}
        </tbody>
      </TableBlock>
    );
    let defSortIcon = Table.sortIcon[this.defaultDirection];

    return (
      <div className={className}>
        <TableBlock sidePadding={sidePadding}>
          {/* Second header */}
          {!isEmpty(colsSecond) && (
            <thead>
              <TableHeader isHideHeader={isHideHeader}>
                {colsSecond.map((c) => renderHeaderCol(c))}
              </TableHeader>
            </thead>
          )}
          <thead>
            <TableHeader isHideHeader={isHideHeader}>
              {cols.map((c) => {
                const current = sortCol && sortCol.title === c.title;
                const SortIcon = current ? Table.sortIcon[sortDirection] : defSortIcon;

                const SortComponent =
                  (c.property || c.getValue) && c.sort ? (
                    <SortContainer>
                      <SortIcon fill={current ? COLORS.COMPANY : COLORS.SUBTEXT} size={14} />
                    </SortContainer>
                  ) : null;
                return renderHeaderCol(c, this.changeSorting(c), SortComponent);
              })}
            </TableHeader>
          </thead>
        </TableBlock>
        {height ? (
          <Scrollbars autoHide={scrollAutoHide} autoHeightMax={height} style={{ height }}>
            {content}
          </Scrollbars>
        ) : (
          content
        )}
      </div>
    );
  }
}

export { TableRow, TableCol, TableHeaderCol, Divider };
export default Table;
