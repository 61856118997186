import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isNull from 'lodash/isNull';
import styled from 'styled-components';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import ProgressBarWide from '~/components/ProgressBarWide';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import { COLORS } from '~/styles';

import { CellViewWrapper } from '../designs';

import type { ICell } from '../types';

const WarningWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const WarningNumber = styled.div`
  font-size: 12px;
  line-height: 1.5;
  color: ${COLORS.ACCENT_WARNING};
`;

const ProgressValue = styled.div`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
  margin-left: 9px;
`;

export const StatusIcon = styled(Icon)`
  margin-right: 4px;
`;

interface ICellViewProgressProps {
  cell: ICell;
  withWarning?: number;
  warningTooltip?: string;
  isWarningCounter?: boolean;
}

const CellViewProgress = ({
  cell: { value },
  withWarning,
  warningTooltip,
  isWarningCounter = false,
}: ICellViewProgressProps) => {
  const { i18n } = useLingui();
  const isWarning = Boolean(withWarning); // amount of warnings
  return (
    <CellViewWrapper>
      {isNull(value) ? (
        <div>{i18n._(t`N/A`)}</div>
      ) : (
        <>
          <ProgressBarWide
            value={value}
            height="5px"
            rounded={true}
            isActive={true}
            style={{ width: `${isWarning ? '58px' : '80px'}` }}
            customStatusColor={false}
            backgroundColor={COLORS.PROGRESSBAR_BACKGROUND}
          />
          <ProgressValue>{value}%</ProgressValue>
          {isWarning && (
            <Tooltip tooltip={warningTooltip} size={TOOLTIP_SIZES.MEDIUM}>
              {/* added span to display tooltip, without span tooltip does not work for icon */}
              <WarningWrapper>
                <StatusIcon
                  icon={ICONS.WARNING_2}
                  size={ICON_SIZES.SMALL}
                  color={COLORS.ACCENT_WARNING}
                />
                {isWarningCounter && <WarningNumber>{withWarning}</WarningNumber>}
              </WarningWrapper>
            </Tooltip>
          )}
        </>
      )}
    </CellViewWrapper>
  );
};

export { CellViewProgress };
