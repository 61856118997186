import React, { useEffect, useMemo, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { ICONS } from '~/components/Icon';
import { ImageWithAlt } from '~/components/ImageWithAlt';
import { SkillModal } from '~/components/Modals/SkillModal';
import RickTextView from '~/components/RickTextView';

import { EDUCATION_LEVELS, WORK_EXPERIENCE_LEVELS } from '~/constants';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getCompanySettings } from '~/selectors/companySettings';
import { getSkillCategories } from '~/services/skillCategories';
import { COLORS } from '~/styles';
import { convertMarkdownStarsToStrong } from '~/utils/markdown';

import { IconInfo } from '../components/IconInfo';
import { SkillTemplateTable } from '../components/SkillTemplatesTable';
import { TabSlider } from '../components/TabSlider';
import {
  Description,
  Details,
  Divider,
  Header,
  IconsSection,
  IconsWrapper,
  Image,
  SkillsWrapper,
  SubTitle,
  TextWrapper,
  Title,
  Wrapper,
} from '../design';
import { convertSkillsDataToTable } from '../utils';

function AboutRoleTab({ i18n, jobProfile }) {
  const getMultiLangString = useMultiLangString();
  const companySettings = useSelector(getCompanySettings);
  const [skillCategories, setSkillCategories] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const skillCategoryIds =
        jobProfile.skills
          .filter((item) => item.skill?.skillCategory)
          .map((item) => item.skill?.skillCategory.id) || [];
      const skillCategories = await getSkillCategories(skillCategoryIds);

      setSkillCategories(
        companySettings.skillCategories.map((item) => skillCategories[item]).filter(Boolean) || [],
      );
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const skills = useMemo(() => {
    const res = {};
    if (jobProfile) {
      skillCategories?.forEach((skillCategory) => {
        const categoryId = skillCategory.id;
        const skills = jobProfile.skills.filter(
          (item) => item.skill.skillCategory.id === categoryId,
        );

        res[categoryId] = { skills: skills.length > 0 ? skills : [] };
      });
    }

    return res;
  }, [jobProfile, skillCategories]);

  const skillsMap = useMemo(() => {
    return jobProfile?.skills.reduce((acc, skill) => {
      acc[skill.skill.id] = skill.skill;
      return acc;
    }, {});
  }, [jobProfile]);

  const onRowClick = (item) => {
    const skillCategory = skillCategories.find((category) => category.id === item.skillCategoryId);
    setSelectedSkill({ ...skillsMap[item.skillId], skillCategory });
  };

  const tabItems = useMemo(() => {
    return skillCategories
      .map((item) => {
        const { id, name } = item;

        const skillsData = convertSkillsDataToTable({
          skills: skills?.[id]?.skills || [],
          skillCategory: item,
          getMultiLangString,
        });

        if (!skillsData?.length) {
          return null;
        }

        return {
          key: id,
          label: getMultiLangString(name || ''),
          content: <SkillTemplateTable data={skillsData} onRowClick={onRowClick} />,
        };
      })
      .filter(Boolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify({ skillCategories, skills })]);

  const workExperienceLevels = Object.values(WORK_EXPERIENCE_LEVELS).map(
    ({ value, shortName }) => ({
      id: value,
      shortName,
    }),
  );

  const educationLevels = Object.values(EDUCATION_LEVELS).map(({ value, shortName }) => ({
    id: value,
    shortName,
  }));

  const { jobLevelGroup } = jobProfile || {};
  const jobLevelGroupName = getMultiLangString(jobLevelGroup?.value?.name || '');
  const jobLevelGroupOrder = jobLevelGroup?.value?.order ? `(${jobLevelGroup.value.order})` : '';

  const jobLevelGroupSubtitle = `${jobLevelGroupName} ${jobLevelGroupOrder}`.trim();
  const jobFamilySubtitle = getMultiLangString(jobProfile?.jobFamily?.value?.name || '');

  return (
    <Wrapper>
      {jobProfile && (
        <>
          <Details>
            <Header>
              <Title>{getMultiLangString(jobProfile.name)}</Title>
            </Header>
            {jobProfile.coverImage && (
              <Image>
                <ImageWithAlt
                  src={jobProfile.coverImage}
                  alt={getMultiLangString(jobProfile.name).substring(0, 2)}
                />
              </Image>
            )}

            <Description>
              <SubTitle>
                <Trans>Description</Trans>
              </SubTitle>
              <TextWrapper>
                <RickTextView
                  html={convertMarkdownStarsToStrong(
                    getMultiLangString(jobProfile?.description || ''),
                  )}
                  color={COLORS.ICONS_PRIMARY}
                  isHtmlWithoutStyles={undefined}
                  className={undefined}
                />
              </TextWrapper>
            </Description>
          </Details>

          {!isEmpty(tabItems) && (
            <>
              <Divider />
              <SubTitle>
                <Trans>Expected behavior and knowledge</Trans>
              </SubTitle>
              <SkillsWrapper>
                <TabSlider
                  items={tabItems}
                  tabTitleAlignment="left"
                  noPadding
                  paddingWrapper="23px 0 0"
                />
              </SkillsWrapper>
            </>
          )}
          <Divider />
          <IconsSection>
            <SubTitle>
              <Trans>Job requirements</Trans>
            </SubTitle>
            <IconsWrapper>
              <IconInfo
                title={i18n._(t`Work Experience`)}
                subtitle={
                  workExperienceLevels
                    .find((item) => item.id === jobProfile.careerLevel?.toString())
                    ?.shortName(i18n) || i18n._(t`N/A`)
                }
                icon={ICONS.WORK_EXPERIENCE}
              />
              <IconInfo
                title={i18n._(t`Education`)}
                subtitle={
                  educationLevels
                    .find((item) => item.id === jobProfile.educationLevel?.toString())
                    ?.shortName(i18n) || i18n._(t`N/A`)
                }
                icon={ICONS.EDUCATION}
              />
            </IconsWrapper>
          </IconsSection>
          <Divider />
          <IconsSection>
            <SubTitle>
              <Trans>Job matrix</Trans>
            </SubTitle>
            <IconsWrapper>
              <IconInfo
                title={i18n._(t`Job Group`)}
                subtitle={jobFamilySubtitle ? i18n._(jobFamilySubtitle) : i18n._(t`N/A`)}
                icon={ICONS.JOB_FAMILY}
              />
              <IconInfo
                title={i18n._(t`Job Level`)}
                subtitle={jobLevelGroupSubtitle ? i18n._(jobLevelGroupSubtitle) : i18n._(t`N/A`)}
                icon={ICONS.JOB_LEVEL}
              />
            </IconsWrapper>
          </IconsSection>
          {selectedSkill && (
            <SkillModal onClose={() => setSelectedSkill(undefined)} skill={selectedSkill} />
          )}
        </>
      )}
    </Wrapper>
  );
}

AboutRoleTab.propTypes = {
  onSkillClick: PropTypes.func,
  jobProfile: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    appTheme: state.appTheme.data,
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(AboutRoleTab)));
