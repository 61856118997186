import React, { useState, useEffect } from 'react';

import { FEEDBACK_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import CompanySettingBlock from '~/components/CompanySettingBlock';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import BaseLayout from '~/layouts/BaseLayout';

import useBoolState from '~/hooks/useBoolState';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getCompanyFeedbackCycle, updateCompanyFeedbackCycle } from '~/services/companySettings';

const CompanyFeedbackCycleSettings = () => {
  const { i18n } = useLingui();
  const { addToast } = useToasts();
  const company = useSelector(getCurrentCompany);
  const $loading = useBoolState(true);
  const [askFeedbackSettings, setAskFeedbackSettings] = useState(null);
  const [giveFeedbackSettings, setGiveFeedbackSettings] = useState(null);
  const [savedAskFeedbackSettings, setSavedAskFeedbackSettings] = useState(null);
  const [savedGiveFeedbackSettings, setSavedGiveFeedbackSettings] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const fetch = async () => {
      const settings = await getCompanyFeedbackCycle(company.id);

      if (isMounted) {
        setAskFeedbackSettings(settings.askFeedbackCycle);
        setSavedAskFeedbackSettings(settings.askFeedbackCycle);
        setGiveFeedbackSettings(settings.giveFeedbackCycle);
        setSavedGiveFeedbackSettings(settings.giveFeedbackCycle);
      }
      $loading.off();
    };

    fetch();

    return () => (isMounted = false);
    // eslint-disable-next-line
  }, []);

  const localUpdate = (data, type) => {
    type === FEEDBACK_TYPE.ASK_FEEDBACK
      ? setAskFeedbackSettings({ ...askFeedbackSettings, ...data })
      : setGiveFeedbackSettings({ ...giveFeedbackSettings, ...data });
  };

  const updateSettings = async (data = {}, type) => {
    const backup = type === FEEDBACK_TYPE.ASK_FEEDBACK ? askFeedbackSettings : giveFeedbackSettings;

    // Check if anything changed, otherwise only update local
    const savedSettings =
      type === FEEDBACK_TYPE.ASK_FEEDBACK ? savedAskFeedbackSettings : savedGiveFeedbackSettings;
    const isFieldsChanged = Object.keys(data).some((key) => data[key] !== savedSettings[key]);

    if (!isFieldsChanged) {
      localUpdate(data, type);
      return;
    }

    // If any relevant field is updated, reset the timer so it is valid for the next pubsub execution
    const inputFields = ['weekDay', 'repeat', 'showTaskDaysBefore', 'hideTaskDaysAfter'];
    const isResetTimer = Object.keys(data).some((key) =>
      ['isEnabled', ...inputFields].includes(key),
    );
    if (isResetTimer) {
      type === FEEDBACK_TYPE.ASK_FEEDBACK
        ? (data.lastCreatedAskFeedbackDate = null)
        : (data.lastCreatedGiveFeedbackDate = null);
    }

    localUpdate(data, type);
    try {
      await updateCompanyFeedbackCycle(company.id, data, type);

      // Update saved settings locally
      type === FEEDBACK_TYPE.ASK_FEEDBACK
        ? setSavedAskFeedbackSettings({ ...askFeedbackSettings, ...data })
        : setSavedGiveFeedbackSettings({ ...giveFeedbackSettings, ...data });

      // Show toast if any input field has changed
      const isInputFieldChanged = Object.keys(data).some((key) => inputFields.includes(key));
      if (isInputFieldChanged) {
        addToast({
          title: i18n._(t`Saved`),
          subtitle: i18n._(t`Your changes have been saved and will be applied after 23:59`),
          type: TOAST_TYPES.INFO,
        });
      }
    } catch (e) {
      // restore settings locally, if the changes does not apply on back-end
      localUpdate(backup, type);
    }
  };

  return (
    <>
      <BaseLayout>
        <ShowSpinnerIfLoading loading={$loading.value}>
          {company.id && !isEmpty(askFeedbackSettings) && (
            <CompanySettingBlock
              settings={askFeedbackSettings}
              updateSettings={(data) => updateSettings(data, FEEDBACK_TYPE.ASK_FEEDBACK)}
              localUpdate={(data) => localUpdate(data, FEEDBACK_TYPE.ASK_FEEDBACK)}
              title={i18n._(t`Task to ask feedback`)}
            />
          )}
          {company.id && !isEmpty(giveFeedbackSettings) && (
            <CompanySettingBlock
              settings={giveFeedbackSettings}
              updateSettings={(data) => updateSettings(data, FEEDBACK_TYPE.GIVE_FEEDBACK)}
              localUpdate={(data) => localUpdate(data, FEEDBACK_TYPE.GIVE_FEEDBACK)}
              title={i18n._(t`Task to give feedback or a compliment`)}
            />
          )}
        </ShowSpinnerIfLoading>
      </BaseLayout>
    </>
  );
};

export default CompanyFeedbackCycleSettings;
