import React from 'react';

import styled from 'styled-components';

import { COLOR_PALETTE } from '~/styles';

interface IRowHeaderProps {
  themes: Array<{ id: string; name: string }>;
  isSecondReviewSelected?: boolean;
}

const Row = styled.tr`
  height: 40px;
  border-bottom: 1px solid #ecf1ff;

  & th {
    white-space: nowrap;
  }
`;

const TableColHeader = styled.th<{ width?: number }>`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  width: ${({ width }) => (width ? `${width}px` : '200px')};
  color: ${({ color }) => (color ? color : COLOR_PALETTE.DARK_GRAY)};
  padding: 12px 24px;
`;

const ListViewRowHeader = ({ themes, isSecondReviewSelected }: IRowHeaderProps) => {
  const themeColumnComponent = themes?.map(({ id, name }) => (
    <TableColHeader key={id} width={216}>
      {name}
    </TableColHeader>
  ));

  return (
    <Row>
      <TableColHeader width={193}>name</TableColHeader>
      <TableColHeader width={193} color="#c129cc">
        review name
      </TableColHeader>
      {themeColumnComponent}
      {isSecondReviewSelected && (
        <>
          <TableColHeader width={193} color="#5c76ff">
            review name
          </TableColHeader>
          {themeColumnComponent}
        </>
      )}
    </Row>
  );
};

export { ListViewRowHeader };
