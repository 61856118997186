import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const modulesMapping = {
  isImportUsersEnabled: 'importUsers',
  isSyncUsersEnabled: 'syncUsers',
  isDeleteUsersEnabled: 'deleteUsers',
  isImportTeamsEnabled: 'importTeams',
  isSyncTeamsEnabled: 'syncTeams',
  isDeleteTeamsEnabled: 'deleteTeams',
  isImportRolesEnabled: 'importRoles',
  isSyncRolesEnabled: 'syncRoles',
  isDeleteRolesEnabled: 'deleteRoles',
};

const updateModulesStatus = function (modulesStatuses, integrationModules) {
  for (let [key, value] of Object.entries(modulesMapping)) {
    modulesStatuses[key] = get(integrationModules, `[${value}].enabled`)
      ? true
      : modulesStatuses[key];
  }
};

export default (integrations) => {
  if (isEmpty(integrations)) {
    return {};
  }

  let modulesStatuses = Object.fromEntries(Object.keys(modulesMapping).map((k) => [k, false]));

  for (const integration in integrations) {
    if (Object.prototype.hasOwnProperty.call(integrations, integration)) {
      const integrationModules = integrations[integration].integrationModules;
      updateModulesStatus(modulesStatuses, integrationModules);
    }
  }

  return modulesStatuses;
};
