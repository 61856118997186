import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Label = styled.label`
  color: ${COLORS.SUBTEXT};
  padding-bottom: 4px;
  font-size: 14px;
  letter-spacing: -0.16px;
`;

export const Title = styled.span`
  font-size: 26px;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 28px;

  & .avatar {
    width: 35px;
    height: 35px;
  }

  & .inputWrapper {
    display: flex;
    flex-direction: column;
  }
`;
