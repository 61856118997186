import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';

import { COLORS } from '~/styles';

import { CheckBox } from '../CheckBox';

import type { IMultiSelect } from '../../types';

const Wrapper = styled.div`
  padding: 10px 0 3px;
`;

const CheckBoxContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  min-width: 20px;
  padding: 0 25px 0;
  height: 100%;
`;

const SelectedContainer = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${COLORS.COMPANY};
  border-radius: 6px;
`;

const SelectText = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-transform: uppercase;
  color: ${COLORS.WHITE};
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
`;

const SelectActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  padding-right: 6px;
`;

interface ISelectedHeader {
  multiSelect: IMultiSelect;
}

const SelectedHeader = ({ multiSelect }: ISelectedHeader) => {
  const { i18n } = useLingui();

  return (
    <Wrapper>
      <SelectedContainer>
        <CheckBoxContainer>
          <CheckBox
            isSelected={multiSelect.isAllChecked}
            onChange={multiSelect.onCheckAll}
            color={COLORS.WHITE}
            isHovered={multiSelect.checkedCount > 0}
          />
        </CheckBoxContainer>
        <SelectText>{i18n._(t`Selected: ${multiSelect.checkedCount}`)}</SelectText>
        <SelectActions>
          {multiSelect.onDuplicate && (
            <Button
              type="button"
              label={i18n._(t`Duplicate`)}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.PRIMARY}
              onClick={multiSelect.onDuplicate}
              icon={ICONS.DUPLICATE}
            />
          )}
          {multiSelect.onArchive && (
            <Button
              type="button"
              label={i18n._(t`Archive`)}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.PRIMARY}
              onClick={multiSelect.onArchive}
              icon={ICONS.ARCHIVE}
            />
          )}
          {Boolean(multiSelect.onDelete) && (
            <Button
              type="button"
              label={multiSelect.onDeleteButtonText ?? i18n._(t`Delete`)}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.PRIMARY}
              onClick={multiSelect.onDelete}
              icon={ICONS.DELETE_BIN}
            />
          )}
        </SelectActions>
      </SelectedContainer>
    </Wrapper>
  );
};

export { SelectedHeader };
