import { createAction } from 'redux-act';

import * as usersServices from '~/services/users';
import { setPreferredLanguage } from '~/services/users';

import { updateUser } from '../users/actions';
export const setAuthUser = createAction('setAuthUser');
export const setUnreadNotificationCount = createAction('setUnreadNotificationCount');
export const addIntegration = createAction('add integration');
export const updateIntegration = createAction('update integration');
export const deleteIntegration = createAction('delete integration');
export const updateUserConnection = createAction('update user connection');

export function updateUserProfile(data) {
  return async function (dispatch) {
    const updatedUser = await usersServices.updateUserProfile(data);

    dispatch(setAuthUser(updatedUser));
    dispatch(updateUser({ userId: updatedUser.id, update: data }));
  };
}

export function updateUserAvatar(file) {
  return async function (dispatch) {
    const updatedUser = await usersServices.updateUserAvatar(file);
    dispatch(setAuthUser(updatedUser));
    dispatch(updateUser({ userId: updatedUser.id, update: updatedUser }));
  };
}

export function addSkillsToUser(data) {
  return async function (dispatch) {
    const { skills } = await usersServices.addSkillsToUser(data);
    dispatch(setAuthUser({ skills }));
  };
}

export function changePreferredLang(connection) {
  return async function (dispatch) {
    await setPreferredLanguage(connection.preferredLang);
    dispatch(updateUserConnection(connection));
  };
}
