import React, { useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  REVIEW_INVITATION_TEMPLATES_SORT_OPTIONS,
} from '@learned/constants';
import { IReviewInvitationTemplate } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { InvitationMessagesEdit } from '~/components/Modals/ProductSettingsModal/InvitationMessagesEdit';
import { InvitationMessagesPreview } from '~/components/Modals/ProductSettingsModal/InvitationMessagesPreview';
import { TableList } from '~/components/TableList';

import { COLUMNS } from './columns';
import { HeaderSection, Wrapper, SubTitle } from './design';

import {
  getReviewInvitationTemplate,
  deleteReviewInvitationTemplate,
} from '~/services/reviewInvitationTemplates';

import { Title } from '../design';

const InvitationMessages = () => {
  const { i18n } = useLingui();
  const [isLoading, setIsLoading] = useState(false);
  const [invitationTemplates, setInvitationTemplates] = useState<IReviewInvitationTemplate[]>([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedInvite, setSelectedInvite] = useState<IReviewInvitationTemplate>();
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setIsLoading(true);
    const result = await getReviewInvitationTemplate({
      options: {
        sortBy: REVIEW_INVITATION_TEMPLATES_SORT_OPTIONS.UPDATED_NEW_OLD,
      },
    });
    const templates: IReviewInvitationTemplate[] =
      result.data[API_RETURN_FIELDS.REVIEW_INVITATION_TEMPLATES];
    setInvitationTemplates(templates);
    setIsLoading(false);
  };

  const onCreate = () => {
    setSelectedInvite(undefined);
    setShowCreateModal(true);
  };

  const onEdit = (item: IReviewInvitationTemplate) => {
    setSelectedInvite(item);
    setShowCreateModal(true);
  };

  const onDelete = async (item: IReviewInvitationTemplate) => {
    setShowDeleteModal(true);
    setSelectedInvite(item);
  };

  const createMenuItems = (item: IReviewInvitationTemplate) => {
    return [
      {
        label: i18n._(t`Edit`),
        action: () => onEdit(item),
        icon: ICONS.EDIT_PENCIL,
      },
      {
        label: i18n._(t`Delete`),
        action: () => onDelete(item),
        icon: ICONS.DELETE_BIN,
        isWarning: true,
      },
    ];
  };

  const onItemClick = {
    column: 'name',
    onClick: (item: IReviewInvitationTemplate) => {
      setSelectedInvite(item);
      setShowPreviewModal(true);
    },
  };

  const closeCreateModal = async () => {
    setShowCreateModal(false);
    await fetch();
  };

  return (
    <Wrapper>
      <HeaderSection>
        <div>
          <Title>{i18n._(t`Invitation messages`)}</Title>
          <SubTitle>
            {i18n._(t`An invitation message can be selected while creating a review (cycle).`)}
          </SubTitle>
        </div>
        <div>
          <Button
            variant={ButtonVariant.PRIMARY}
            label={i18n._(t`Create message`)}
            size={ButtonSize.MEDIUM}
            onClick={() => {
              onCreate();
            }}
          />
        </div>
      </HeaderSection>
      <div>
        <TableList
          data={invitationTemplates}
          columns={COLUMNS}
          onColClick={onItemClick}
          isLoading={isLoading}
          isHideHeader={isEmpty(invitationTemplates)}
          placeholderProps={{
            noResultText: i18n._(t`No review invitation templates found`),
            emptyStateText: i18n._(t`No review invitation templates yet… Let's create one! `),
            emptyButtonText: i18n._(t`Add message`),
            onClickEmptyButton: () => onCreate(),
          }}
          menuProps={{
            createMenuItems,
            isMenuVisible: true,
          }}
        />
      </div>
      {showCreateModal && (
        <InvitationMessagesEdit closeModal={closeCreateModal} value={selectedInvite} />
      )}
      {showPreviewModal && selectedInvite && (
        <InvitationMessagesPreview
          closeModal={() => setShowPreviewModal(false)}
          value={selectedInvite}
          onEditClick={() => {
            setShowPreviewModal(false);
            setShowCreateModal(true);
          }}
        />
      )}
      {showDeleteModal && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.DELETE}
          onClose={() => setShowDeleteModal(false)}
          description={i18n._(
            t`Are you sure you want to delete this invitation message? This action cannot be undone.`,
          )}
          onSubmit={async () => {
            if (selectedInvite) {
              await deleteReviewInvitationTemplate(selectedInvite.id);
              setSelectedInvite(undefined);
              await fetch();
            }
          }}
        />
      )}
    </Wrapper>
  );
};

export { InvitationMessages };
