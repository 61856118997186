import styled, { css } from 'styled-components';

import Button, { ButtonPrimary, ButtonShadow, ButtonWhite } from '~/components/Button';

import { COLORS } from '~/styles';

export const ActionsWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionItemBlock = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  min-width: 130px;
  height: 20px;
  border-left: solid 1.2px ${COLORS.BORDERS};
  justify-content: center;
`;

export const IconButtonContainer = styled.div`
  display: flex;
  gap: 10px;

  & button {
    color: ${COLORS.TIPPY_BACKGROUND};
  }

  & .delete-btn:hover {
    background-color: ${COLORS.ERROR_LIGHT};
    color: ${COLORS.ACCENT_ERROR};
  }

  & .save-btn {
    svg {
      height: 16px;
      width: 16px;
    }
  }
`;

export const PublishButton = styled(Button)`
  max-width: 98px;
  height: 32px;
  margin: 14px 15px 16px 16px;
  border-radius: 24px;
  background-color: ${COLORS.INACTIVE};
`;

export const SubmitButton = styled(ButtonPrimary)`
  height: 32px;
  min-width: 80px;
  padding: 0 10px 0 14px;
  border-radius: 100px;
  font-size: 12px;
  & .icon {
    margin-left: 4px;
  }
`;

export const BackButton = styled(ButtonWhite)`
  height: 32px;
  min-width: 80px;
  padding: 0 14px 0 10px;
  border-radius: 100px;
  font-size: 12px;
  border: none;
  color: var(--company-color);

  &:hover {
    color: var(--company-color);
    background-color: unset;
  }

  & .icon {
    color: var(--company-color);
    margin-right: 4px;
    fill: currentColor;
  }
`;

export const StepNavigationWrapper = styled.div`
  display: flex;
  margin-top: 49px;
  gap: 150px;
`;

export const Error = styled.span`
  color: ${COLORS.ERROR};
  font-size: 12px;
`;

export const Form = styled.form`
  border-radius: 10px;
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 32px 40px;
`;

export const Title = styled.h2`
  font-size: 26px;
  color: ${COLORS.BLACK};
  font-weight: normal;
  margin-top: 0;
`;

export const LabelWrapper = styled.label`
  display: flex;
  justify-content: space-between;
`;

export const SubText = styled.label`
  font-size: 12px;
  color: ${COLORS.TEXT_HOVER};
  padding-bottom: 4px;
`;

export const Label = styled.label`
  font-size: 14px;
  color: ${COLORS.TEXT_HOVER};
  padding-bottom: 4px;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 14px;
  gap: 68px;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowSwitch = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 26px;
`;

export const EditIconColorButton = styled(ButtonShadow)`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  font-size: 12px;
  margin-top: 10px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: right;
`;

export const IconPreview = styled.div<{ colors: string[] }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  ${({ colors }) =>
    colors?.length && colors.length > 1
      ? css`
          background: linear-gradient(to bottom, ${colors[0]}, ${colors[1]});
        `
      : css`
          background-color: ${colors[0]};
        `};
  border-radius: 6px;
  &:after {
    position: absolute;
    top: -10px;
    right: -10px;
    color: ${COLORS.TEXT_HOVER};
  }
`;

export const IconPreviewWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-top: 22px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 57px auto 0;

  & img {
    margin-right: 20px;
  }

  & .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    color: ${COLORS.SUBTEXT};
    margin-bottom: 34px;
  }
`;

export const QuestionStepTitle = styled.h2`
  font-size: 26px;
  color: ${COLORS.BLACK};
  font-weight: normal;
  margin-top: 0;
`;

export const QuestionStepFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
`;

export const ThemePublishButton = styled(ButtonPrimary)`
  min-width: 154px;
  height: 48px;
  padding: 0 10px 0 14px;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 80px;
`;

export const Wrapper = styled.div`
  border-radius: 10px;
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 32px 40px;
`;

export const CreateNewButton = styled(ButtonWhite)`
  height: 32px;
  border-radius: 100px;
  font-size: 12px;
  margin: 68px auto 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const ImportQuestionButton = styled(ButtonPrimary)`
  height: 32px;
  border-radius: 100px;
  font-size: 12px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 68px auto 0;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: ${COLORS.SUBTEXT};
`;

export const List = styled.div`
  margin: 40px;
`;

export const AddQuestion = styled(ButtonShadow)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 41px;
  border-radius: 6px;
  padding: 0;
  border: solid 1px ${COLORS.BORDER_HARD};
  box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
`;

export const HoverIcon = styled.div<{ isVisible?: boolean }>`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 10px;
  left: -36px;
`;

export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  & .question {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    &:hover ${HoverIcon} {
      visibility: visible;
    }
  }

  & .newQuestion {
    position: relative;
    height: 10px;
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    & ${AddQuestion} {
      position: absolute;
      display: none;
      right: -42px;
      top: -16px;
    }

    &:hover {
      background-color: ${COLORS.BG_PAGE};

      & ${AddQuestion} {
        display: flex;
      }
    }
  }
`;

export const CreateNewQuestionWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  padding: 7px 0;
  justify-content: center;
  background-color: ${COLORS.BG_PAGE};
  border-radius: 6px;
  font-size: 12px;
`;

export const CreateNewQuestion = styled(ButtonWhite)`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 7px 0;
  height: 38px;
  min-width: 138px;
  border-radius: 6px;
  border: 1px solid ${COLORS.BORDER_HARD};
  font-size: 12px;
`;
