import React, { useState, useEffect } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ButtonVariant } from '~/components/Buttons';
import { Dropdown } from '~/components/Dropdown';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import BoxWithBorder from '~/components/UI/BoxWithBorder';
import { TableHeader } from '~/pages/Conversations/components/TableHeader';

import { EDUCATION_LEVELS, WORK_EXPERIENCE_LEVELS } from '~/constants';
import { getUser } from '~/selectors/baseGetters';
import { updateMembersProfiles } from '~/store/users/actions';
import { COLORS } from '~/styles';

import { Wrapper, SectionBody, FieldLabel, FieldValue, FieldSectionWrapper } from '../../design';

import type { IUser } from '@learned/types';
import type { I18n } from '@lingui/core';

interface IDropdownItem {
  key: number;
  value: string;
  name: (i18n: I18n) => string;
  shortName: (i18n: I18n) => string;
}

const TabContainer = styled.div`
  margin: 19px 39px;

  .user-public-conversations_header {
    margin-bottom: 24px;
  }
`;

const AdditionalInformation = ({ user }: { user: IUser }) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const [careerPhase, setCareerPhase] = useState<IDropdownItem>();
  const [education, setEducation] = useState<IDropdownItem>();
  const [isEnabled, setIsEnabled] = useState(false);

  const educationOptions = Object.values(EDUCATION_LEVELS);
  const careerOptions = Object.values(WORK_EXPERIENCE_LEVELS);

  const currentUser = useSelector(getUser);

  useEffect(() => {
    if (user?.careerLevel) {
      const careerPhase = careerOptions.find((option) => option.key === user.careerLevel);
      setCareerPhase(careerPhase);
    }
    if (user?.educationLevel) {
      const education = educationOptions.find((option) => option.key === user.educationLevel);
      setEducation(education);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.careerLevel, user.educationLevel]);

  const onSaveAdditionalInformation = async () => {
    const data = {
      careerLevel: careerPhase?.key,
      educationLevel: education?.key,
    };
    await dispatch(updateMembersProfiles([{ userId: user.id, ...data }]));
    setIsEnabled(!isEnabled);
  };

  const onCancelAdditionalInformation = () => {
    if (user?.careerLevel) {
      const careerPhase = careerOptions.find((option) => option.key === user.careerLevel);
      setCareerPhase(careerPhase);
    }
    if (user?.educationLevel) {
      const education = educationOptions.find((option) => option.key === user.educationLevel);
      setEducation(education);
    }
    setIsEnabled(!isEnabled);
  };

  const editButton = {
    label: t`Edit`,
    onClick: () => setIsEnabled(!isEnabled),
  };

  const saveButton = {
    label: t`Save`,
    onClick: () => onSaveAdditionalInformation(),
  };

  const cancelButton = {
    label: t`Cancel`,
    onClick: () => onCancelAdditionalInformation(),
  };

  return (
    <BoxWithBorder>
      <TabContainer>
        <Wrapper>
          <TableHeader
            filters={{ search: '', setSearch: () => {} }}
            actionButton={currentUser.isAdmin && (isEnabled ? saveButton : editButton)}
            actionButtonVariant={isEnabled ? ButtonVariant.PRIMARY : ButtonVariant.SECONDARY}
            headerTitle={i18n._(t`Additional information`)}
            actionButtonIcon={isEnabled ? undefined : ICONS.EDIT_PENCIL}
            secondaryButton={isEnabled ? cancelButton : undefined}
            secondaryButtonVariant={ButtonVariant.SECONDARY}
          />

          <SectionBody>
            <div>
              <FieldSectionWrapper>
                <div>
                  <Icon icon={ICONS.CAREER} size={ICON_SIZES.LARGE} color={COLORS.ICONS_PRIMARY} />
                </div>
                <div>
                  <FieldLabel>{i18n._(t`Career phase`)}</FieldLabel>
                  {isEnabled ? (
                    <Dropdown
                      selectedItem={careerPhase}
                      items={careerOptions}
                      onChange={(item) => setCareerPhase(item as IDropdownItem)}
                      stringifyItem={(item) => item.name(i18n)}
                      isSingleSelect
                      skipSort
                      placeholder="Not set"
                    />
                  ) : (
                    <FieldValue>
                      {careerPhase ? careerPhase?.name(i18n) : i18n._(t`Not set`)}
                    </FieldValue>
                  )}
                </div>
              </FieldSectionWrapper>
            </div>
            <div>
              <FieldSectionWrapper>
                <div>
                  <Icon
                    icon={ICONS.LEARNING}
                    size={ICON_SIZES.LARGE}
                    color={COLORS.ICONS_PRIMARY}
                  />
                </div>
                <div>
                  <FieldLabel>{i18n._(t`Education`)}</FieldLabel>
                  {isEnabled ? (
                    <Dropdown
                      selectedItem={education}
                      items={educationOptions}
                      onChange={(item) => setEducation(item as IDropdownItem)}
                      stringifyItem={(item) => item.name(i18n)}
                      isSingleSelect
                      skipSort
                      placeholder="Not set"
                    />
                  ) : (
                    <FieldValue>
                      {education ? education?.name(i18n) : i18n._(t`Not set`)}
                    </FieldValue>
                  )}
                </div>
              </FieldSectionWrapper>
            </div>
          </SectionBody>
        </Wrapper>
      </TabContainer>
    </BoxWithBorder>
  );
};

export { AdditionalInformation };
