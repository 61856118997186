import React, { ChangeEvent, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { SketchPicker } from 'react-color';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS, ICON_SIZES } from '~/components/Icon';

import { COLORS } from '~/styles';

import {
  AddButton,
  Wrapper,
  ColorTray,
  ColorsWrapper,
  ColorInputWrapper,
  ColorInput,
  Color,
  CustomColor,
  CustomColorButton,
  BottomButtons,
} from '../design';

export interface IColorPickerProps {
  onClose: () => void;
  onChangeColor: (color: string) => void;
  toggleColorTray?: boolean;
  isVisibleInitially?: boolean;
}

const colors: string[] = [
  '#c6e0e7-#94beca',
  '#e9ebfd-#ced3f9',
  '#c0f8f0-#8ceddb',
  '#e5f3d6-#c6e3ad',
  '#ffedd6-#ffd6ac',
  '#fcd8a7-#f8af6f',
  '#fbe991-#f5ce58',
  '#f9bab7-#f08480',
  '#d9bebb-#b18985',
  '#f2b2b5-#e17a7e',
  '#f9d6f8-#f0aded',
  '#ecbee5-#d489c7',
  '#ded7ff-#bbaeff',
  '#e7c7ff-#ca96ff',
  '#f0f0f0-#dddddd',
  '#f0f1fd-#dddefb',
];

const ColorPicker = ({
  onClose,
  onChangeColor,
  toggleColorTray = true,
  isVisibleInitially = true,
}: IColorPickerProps) => {
  const { i18n } = useLingui();
  const [customColor, setCustomColor] = useState(COLORS.WHITE);
  const [pickedColor, setPickedColor] = useState(COLORS.WHITE);
  const [isColorTrayVisible, setIsColorTrayVisible] = useState(isVisibleInitially);

  const handleTextInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCustomColor(value);
    setPickedColor(value);
  };

  const onclickColorBlock = (gradient: string) => {
    const colors = gradient.split('-');
    const color = colors.length > 1 ? colors[1] : colors[0];
    setCustomColor(color);
    onChangeColor(color);
  };

  const isColorSelected = (gradient: string) => {
    const colors = gradient.split('-');
    return colors[1] === customColor;
  };

  const onToggleColorTray = () => {
    if (toggleColorTray) {
      setIsColorTrayVisible(!isColorTrayVisible);
    }
  };

  return (
    <Wrapper>
      <div>
        <ColorInputWrapper>
          <ColorInput
            name="color"
            placeholder={COLORS.WHITE}
            onChange={handleTextInput}
            value={customColor}
          />
        </ColorInputWrapper>
        <ColorsWrapper>
          <div className="grid">
            <AddButton
              variant={ButtonVariant.ICON}
              size={ButtonSize.MEDIUM}
              icon={ICONS.ADD_PLUS}
              iconSize={ICON_SIZES.SMALL}
              onClick={onToggleColorTray}
              toggleIcon={isColorTrayVisible}
            />
            <CustomColorButton
              type="button"
              className={`colorWrapper ${isColorSelected(pickedColor) ? 'selected' : ''}`}
              key={pickedColor}
              onClick={() => onclickColorBlock(pickedColor)}
            >
              <CustomColor colors={pickedColor.split('-')} />
            </CustomColorButton>

            {colors.map((color) => (
              <button
                type="button"
                className={`colorWrapper ${isColorSelected(color) ? 'selected' : ''}`}
                key={color}
                onClick={() => onclickColorBlock(color)}
              >
                <Color className="color" colors={color.split('-')} />
              </button>
            ))}
          </div>
        </ColorsWrapper>
      </div>
      {isColorTrayVisible && (
        <ColorTray>
          <SketchPicker
            width="250px"
            presetColors={[]}
            color={pickedColor}
            onChange={(color) => {
              setCustomColor(color.hex);
              setPickedColor(color.hex);
            }}
          />
          <BottomButtons>
            <Button
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              label={i18n._(t`Cancel`)}
              onClick={onClose}
            />
            <Button
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              label={i18n._(t`Add color`)}
              onClick={() => {
                onChangeColor(customColor);
              }}
            />
          </BottomButtons>
        </ColorTray>
      )}
    </Wrapper>
  );
};

export { ColorPicker };
