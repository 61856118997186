import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import ReportPerformancePage from '~/pages/AdminReports/ReportPerformancePage';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('reports', {
  routesPrivate: {
    user: [],
    coach: [],
    admin: [
      <PrivateRoute
        key={routes.REPORT_PERFORMANCE.routePath(ROLES.ADMIN)}
        exact
        path={routes.REPORT_PERFORMANCE.routePath(ROLES.ADMIN)}
        component={ReportPerformancePage}
        allowedRoles={[ROLES.ADMIN]}
      />,
    ],
  },
  routesPublic: [],
});
