import React, { useEffect, useRef, useState } from 'react';

import { USER_INTEGRATION_SYNC_STATUS } from '@learned/types';
import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled, { css } from 'styled-components';

import { Button, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { RotatingSpinner } from '~/components/Spinners';

import { COLORS, COLOR_PALETTE } from '~/styles';

const Box = styled.div`
  display: flex;
  align-items: center;
  width: 674px;
  max-width: 674px;
  height: 62px;
  padding: 0 20px 0 16px;
  border-radius: 6px;
  box-sizing: border-box;
  border: solid 1px ${COLORS.BORDERS};
  margin-top: 16px;
  justify-content: space-between;

  ${(props) =>
    props.isWithOpacity &&
    css`
      opacity: 0.5;
    `}
`;

const Col = styled.div`
  display: flex;
  align-items: center;
`;

const RowLogo = styled.div`
  display: flex;
  width: 36px;
  justify-content: flex-start;
`;

const DescriptionCall = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.SUBTEXT};
`;

const SyncButton = styled(Button)`
  color: ${COLORS.GRAVEL};
  background-color: ${COLOR_PALETTE.WHITE};
  &:hover {
    background-color: ${COLOR_PALETTE.WHITE};
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  margin-right: 8px;
`;

const ProgressButton = styled(Button)`
  &:hover {
    cursor: not-allowed;
  }
`;

const PROGRESS_MESSAGES = {
  NONE: {
    LABEL: '',
  },
  CONNECTING: {
    LABEL: t`Connecting`,
    DESCRIPTION: t`Your integration is being connected and your events are being synced with your calendar. This may take some time.`,
  },
  DISCONNECTING: {
    LABEL: t`Disconnecting`,
    DESCRIPTION: t`Your integration is being disconnected. This may take some time.`,
  },
  SYNCING: {
    LABEL: t`Syncing`,
    DESCRIPTION: t`Your events are being synced with your calendar. This may take some time.`,
  },
};

const IntegrationRow = ({
  integration,
  type,
  icon,
  description,
  onIntegrationChange: changeIntegration,
  disabled,
  i18n,
  isBlocked,
  refreshUserIntegration,
  initIntegrationSync,
}) => {
  const refreshInterval = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [currentProcess, setCurrentProcess] = useState(PROGRESS_MESSAGES.NONE);

  const withProblem = integration && (integration.isExpire || integration.isRevoked);

  const processComponent = (
    <Row>
      <Row>{`${i18n._(currentProcess.LABEL)}...`}</Row>
      <RotatingSpinner
        isLoading
        content={<Icon icon={ICONS.REFRESH} size={ICON_SIZES.MEDIUM} color={COLORS.WHITE} />}
      />
    </Row>
  );

  useEffect(() => {
    if (refreshInterval.current) {
      clearInterval(refreshInterval.current);
    }

    if (isLoading && integration?.id) {
      refreshInterval.current = window.setInterval(async () => {
        try {
          await refreshUserIntegration(integration);
        } catch (ex) {
          if (ex.response.status === 404) {
            clearInterval(refreshInterval.current);
          }
        }
      }, 5000);
    }

    return () => {
      clearInterval(refreshInterval.current);
    };
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    if (!integration) {
      setIsLoading(false);
      setCurrentProcess(PROGRESS_MESSAGES.NONE);
      return;
    }

    const syncStatus = integration?.syncStatus;

    if (syncStatus === USER_INTEGRATION_SYNC_STATUS.CONNECTING) {
      setCurrentProcess(PROGRESS_MESSAGES.CONNECTING);
      setIsLoading(true);
    } else if (syncStatus === USER_INTEGRATION_SYNC_STATUS.DISCONNECTING) {
      setCurrentProcess(PROGRESS_MESSAGES.DISCONNECTING);
      setIsLoading(true);
    } else if (syncStatus === USER_INTEGRATION_SYNC_STATUS.SYNCHRONIZING) {
      setCurrentProcess(PROGRESS_MESSAGES.SYNCING);
      setIsLoading(true);
    } else {
      setIsLoading(false);
      setCurrentProcess(PROGRESS_MESSAGES.NONE);
    }
  }, [integration]);

  const toggleConnection = async (integration, isExist) => {
    if (isExist) {
      setCurrentProcess(PROGRESS_MESSAGES.DISCONNECTING);
      setIsLoading(true);
    }

    await changeIntegration(integration, isExist);
  };

  const runIntegrationSync = async (integration) => {
    setCurrentProcess(PROGRESS_MESSAGES.SYNCING);
    setIsLoading(true);
    initIntegrationSync(integration);
  };

  return (
    <Box isWithOpacity={isBlocked}>
      <Col>
        <RowLogo>
          <Icon icon={icon} size={26} />
        </RowLogo>
        <DescriptionCall>
          <Trans id={description} />
        </DescriptionCall>
      </Col>
      <Col>
        {isLoading && (
          <ProgressButton
            label={processComponent}
            tooltip={currentProcess.DESCRIPTION ? i18n._(t`${currentProcess.DESCRIPTION}`) : ''}
          />
        )}

        {withProblem && (
          <ButtonWrapper>
            <Button
              variant={ButtonVariant.PRIMARY}
              label={<Trans>Reconnect</Trans>}
              onClick={() => toggleConnection(integration, false)}
              disabled={disabled || isBlocked}
            />
          </ButtonWrapper>
        )}
        {integration && !isLoading && !withProblem && !disabled && !isBlocked && (
          <ButtonWrapper>
            <SyncButton
              label={i18n._(t`Manual Sync Calendar`)}
              onClick={() => runIntegrationSync(integration)}
            />
          </ButtonWrapper>
        )}
        {!isLoading && (
          <Button
            variant={ButtonVariant.SECONDARY}
            label={integration ? t`Disconnect` : t`Connect`}
            onClick={() => toggleConnection(integration || { type }, !!integration)}
            disabled={disabled || isBlocked || isLoading}
            tooltip={
              isBlocked
                ? i18n._(
                    t`You can only connect to 1 integration. Disconnect your current integration to connect to this one`,
                  )
                : ''
            }
          />
        )}
      </Col>
    </Box>
  );
};

export default React.memo(withI18n()(IntegrationRow));
