import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import TextArea from '~/components/TextArea';
import Avatar from '~/components/UI/Avatar';
import Divider from '~/components/UI/Divider';

import { getInactiveUsers, getUsers } from '~/selectors/baseGetters';
import { COLOR_PALETTE } from '~/styles';

const OuterWrapper = styled.div`
  background-color: ${COLOR_PALETTE.WHITE};
`;

const Content = styled.div`
  margin: 12px 16px;
  border: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  border-radius: 3px;
  min-height: 97px;
`;

const PeopleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  height: auto;
`;

const EditorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-height: 50px;
  max-height: 254px;
  overflow-y: auto;
`;

const StepTextArea = styled(TextArea)`
  padding: 12px;
  width: 100%;
  height: auto;
  border: none;
  overflow: hidden;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${({ value }) => (value ? COLOR_PALETTE.BLACK : COLOR_PALETTE.GRAY_MIDDLE)};
  outline: none;
`;

const AddStepButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  margin-top: 12px;
  margin-right: 12px;
  line-height: 1.33;
  letter-spacing: 0.6px;
`;

const AvatarSelector = styled.div`
  border: solid 2px ${({ selected }) => (selected ? 'var(--company-color)' : 'transparent')};
  background: ${({ selected }) => (selected ? 'var(--company-color);' : 'transparent;')};
  border-radius: 6px;
`;

const StepEditor = ({ participants = [], onAddStep = async () => {} }) => {
  const [comment, setComment] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const { i18n } = useLingui();
  const allUsers = { ...useSelector(getUsers), ...useSelector(getInactiveUsers) };

  useEffect(() => {
    const existingParticipants = participants.map((id) => allUsers[id]).filter(Boolean);
    setUsers(existingParticipants);
    if (existingParticipants.length) {
      setSelectedUser(existingParticipants[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants]);

  const onChangeComment = (e) => {
    e.preventDefault();
    setComment(e.target.value.replace('\n', ''));
  };

  const onAvatarClick = (userId) => {
    setSelectedUser(userId);
  };

  const onButtonClick = async () => {
    setLoading(true);
    await onAddStep(selectedUser, comment);
    setComment('');
    setLoading(false);
  };

  return (
    <OuterWrapper>
      <Content>
        <PeopleContainer>
          {users.map((user) => (
            <AvatarSelector
              key={`selector_${user.id}`}
              selected={user.id === selectedUser}
              onClick={() => onAvatarClick(user.id)}
            >
              <Avatar
                key={`avatar_${user.id}`}
                userId={user.id}
                email={user.email}
                size={28}
                showTooltip
                noBorder
              />
            </AvatarSelector>
          ))}
        </PeopleContainer>
        <Divider />
        <EditorContainer>
          <StepTextArea
            placeholder={i18n._(t`Describe the next step…`)}
            onChange={onChangeComment}
            value={comment}
          />
          <AddStepButton>
            <Button
              label={i18n._(t`Save`)}
              onClick={onButtonClick}
              height={28}
              width={62}
              disabled={!comment || !selectedUser || loading}
            />
          </AddStepButton>
        </EditorContainer>
      </Content>
    </OuterWrapper>
  );
};

export default StepEditor;
