import { Locals, ProductName } from '@learned/constants';

import { LANGUAGES } from '~/constants';

import type { LocalProductName } from '../types';

export const disableProductCheck = (key: LocalProductName, products: LocalProductName[]) => {
  switch (key) {
    case ProductName.JOB_MATRIX:
      // if Career product enabled, not allow to disable jobMatrix
      return products.includes(ProductName.CAREER) && products.includes(ProductName.JOB_MATRIX);
    default:
      return false;
  }
};

export const convertLangToLocale = (lang?: keyof typeof LANGUAGES) => {
  switch (lang) {
    case LANGUAGES.NL:
      return Locals.nl_NL;
    default:
    case LANGUAGES.EN:
      return Locals.en_GB;
  }
};
