const PAGEHEIGHT = 1614;

export default function pageBreak(top, bottom) {
  const elementHeight = bottom - top;
  if (elementHeight >= PAGEHEIGHT) {
    return false;
  }
  if (Math.floor(bottom / PAGEHEIGHT) > Math.floor(top / PAGEHEIGHT)) {
    return true;
  }
  return false;
}
