import React, { useEffect } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { useForm } from 'react-hook-form';

import { Button, ButtonVariant, ButtonSize } from '~/components/Buttons';
import { Input } from '~/components/Input';

import { SYSTEM_LANG } from '~/constants/languages';
import useBoolState from '~/hooks/useBoolState';

import { ModalWrapper, ModalContent, ModalFooter, InputBlock } from '../design';

import type { INewIndustry } from '@learned/types';

interface IAddEditIndustryModalProps {
  title: string;
  onClose: () => void;
  action: (val: INewIndustry) => void;
  subTitle: string;
  actionTitle: string;
  data?: INewIndustry;
}

const AddEditIndustryModal = ({
  title,
  subTitle,
  onClose,
  action,
  actionTitle,
  data,
}: IAddEditIndustryModalProps) => {
  const { i18n } = useLingui();
  const industry = data;
  const $isLoading = useBoolState(false);

  const formMethods = useForm<INewIndustry>({
    mode: 'all',
    defaultValues: {
      name: {
        en_GB: '',
        nl_NL: '',
        de_DE: '',
      },
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = formMethods;

  useEffect(() => {
    if (industry) {
      setValue('name.en_GB', industry?.name?.en_GB);
      setValue('name.nl_NL', industry?.name?.nl_NL);
      setValue('name.de_DE', industry?.name?.de_DE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industry]);

  const handleSubmitIndustry = async (data: INewIndustry) => {
    $isLoading.on();
    await action(data as INewIndustry);
    $isLoading.off();
  };

  return (
    <ModalWrapper
      title={title}
      greyDescription={subTitle}
      contentStyles={{ padding: '23px 32px 32px 32px' }}
      onClose={onClose}
      centerModal
      minWidth={500}
      width={500}
      actions={action}
      hideFooter
    >
      <ModalContent>
        <>
          <InputBlock>
            <Input
              type="text"
              label={i18n._(t`Industry name - English`)}
              placeholder={i18n._(t`Industry name`)}
              height="38px"
              leftIcon={getUnicodeFlagIcon(SYSTEM_LANG.en.locale.split('_')[1])}
              register={register('name.en_GB', {
                required: { value: true, message: i18n._(t`Industry name - English is required`) },
                pattern: {
                  value: /^[^\s]+(?:$|.*[^\s]+$)/,
                  message: i18n._(t`Whitespace not allowed`),
                },
              })}
              error={errors.name?.en_GB?.message}
            />
          </InputBlock>
          <InputBlock>
            <Input
              type="text"
              label={i18n._(t`Industry name - Dutch`)}
              placeholder={i18n._(t`Industry name`)}
              height="38px"
              leftIcon={getUnicodeFlagIcon(SYSTEM_LANG.nl.locale.split('_')[1])}
              register={register('name.nl_NL', {
                required: { value: true, message: i18n._(t`Industry name - Dutch is required`) },
                pattern: {
                  value: /^[^\s]+(?:$|.*[^\s]+$)/,
                  message: i18n._(t`Whitespace not allowed`),
                },
              })}
              error={errors.name?.nl_NL?.message}
            />
          </InputBlock>
          <InputBlock>
            <Input
              type="text"
              label={i18n._(t`Industry name - German`)}
              placeholder={i18n._(t`Industry name`)}
              height="38px"
              leftIcon={getUnicodeFlagIcon(SYSTEM_LANG.de.locale.split('_')[1])}
              register={register('name.de_DE', {
                required: { value: true, message: i18n._(t`Industry name - German is required`) },
                pattern: {
                  value: /^[^\s]+(?:$|.*[^\s]+$)/,
                  message: i18n._(t`Whitespace not allowed`),
                },
              })}
              error={errors.name?.de_DE?.message}
            />
          </InputBlock>
        </>
      </ModalContent>
      <ModalFooter>
        <Button
          label={i18n._(t`Cancel`)}
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          onClick={onClose}
        />
        <Button
          label={actionTitle}
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          onClick={handleSubmit(handleSubmitIndustry)}
          isLoading={$isLoading.value}
          disabled={$isLoading.value}
        />
      </ModalFooter>
    </ModalWrapper>
  );
};

export { AddEditIndustryModal };
