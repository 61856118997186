import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const InputWrapper = styled.div``;

export const Wrapper = styled.div<{
  disabled?: boolean;
  error?: string | boolean;
  textSize?: string;
  width?: string;
  height?: string;
  isLeftIcon?: boolean;
  isRightIcon?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || 'unset'};

  & ${InputWrapper} {
    position: relative;

    & .leftIcon {
      position: absolute;
      left: 12px;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
    }

    & .rightIcon {
      position: absolute;
      right: 12px;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
    }

    & input {
      width: 100%;
      border-width: 1px;
      border-style: solid;
      border-color: ${COLORS.BORDERS};
      border-radius: 6px;
      background-color: ${COLORS.WHITE};
      font-size: ${({ textSize }) => textSize || '14px'};
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.86;
      color: ${COLORS.TEXT_HOVER};
      height: ${({ height }) => height || '34px'};
      box-sizing: border-box;

      &::placeholder {
        color: ${COLORS.PLACEHOLDERS};
      }

      ${({ isLeftIcon, isRightIcon }) => {
        if (isLeftIcon && isRightIcon) {
          return css`
            padding: 12px 38px 12px 38px;
          `;
        } else if (isRightIcon) {
          return css`
            padding: 12px 38px 12px 14px;
          `;
        } else if (isLeftIcon) {
          return css`
            padding: 12px 14px 12px 38px;
          `;
        } else {
          return css`
            padding: 12px 14px;
          `;
        }
      }}

      &:focus {
        ${({ error }) =>
          !error &&
          css`
            border-color: var(--company-color);
          `}
      }

      &:hover {
        ${({ disabled }) =>
          !disabled &&
          css`
            background-color: ${COLORS.HOVER};
          `}
      }

      ${({ disabled }) =>
        disabled &&
        css`
          border-color: ${COLORS.INACTIVE};
          color: ${COLORS.INACTIVE};
          cursor: not-allowed;
        `}

      ${({ error }) =>
        error &&
        css`
          border-color: ${COLORS.ACCENT_ERROR};
        `}
    }
  }

  & label {
    ${({ disabled }) =>
      disabled &&
      css`
        color: ${COLORS.INACTIVE};
        cursor: not-allowed;
      `}

    ${({ error }) =>
      error &&
      css`
        color: ${COLORS.ACCENT_ERROR};
      `}
  }
`;

export const Error = styled.span`
  color: ${COLORS.ACCENT_ERROR};
  font-size: 12px;
`;

export const Label = styled.label`
  font-size: 14px;
  color: ${COLORS.TEXT_HOVER};
  margin-bottom: 4px;
`;

export const EyeButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
`;
