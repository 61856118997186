import React, { memo, MouseEventHandler } from 'react';

import { REVIEW_SORT_OPTIONS, REVIEW_STATUS, USER_REVIEW_STATUS } from '@learned/constants';
import { IReview } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment/moment';

import Label from '~/components/Label';
import ToolTip from '~/components/Tooltip';
import { UserAvatarGroup } from '~/components/UserAvatar/UserAvatarGroup';

import type { IColumnTable } from '~/@types/table';
import { STATUS, STATUS_PROVIDER } from '~/constants/statusProvider';
import { useMultiLangString } from '~/hooks/useMultiLangString';

import { FlexRow, TextRow, DateRow } from '../../design';

import type { I18n } from '@lingui/core';

const StatusLabel = ({ status }: { status: string }) => {
  const { i18n } = useLingui();
  const statusProps =
    STATUS_PROVIDER[status === USER_REVIEW_STATUS.PUBLISHED ? STATUS.UPCOMING : status];
  return statusProps ? <Label {...statusProps}>{statusProps.text(i18n)}</Label> : null;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WithColumn = ({ item }: { item: IReview }) => {
  return (
    <UserAvatarGroup
      users={item.employees?.map((item: string) => ({ userId: item })) || []}
      options={{ isCounterClickable: true }}
    />
  );
};

const NameColumn = memo(
  ({ item, onClick }: { item: IReview; onClick?: MouseEventHandler<HTMLDivElement> }) => {
    const getMultiLangString = useMultiLangString();
    return (
      <TextRow isDraft={item.status === REVIEW_STATUS.DRAFT}>
        {/* @ts-ignore */}
        <FlexRow onClick={() => onClick(item)}>
          <ToolTip tooltip={getMultiLangString(item.name)}>
            <span>{getMultiLangString(item.name)}</span>
          </ToolTip>
        </FlexRow>
      </TextRow>
    );
  },
);

const COLUMNS: IColumnTable<IReview>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'name',
    maxWidth: '420px',
    renderCell: (item, onClick: MouseEventHandler<HTMLDivElement> | undefined) => {
      return <NameColumn item={item} onClick={onClick} />;
    },
    sortBy: {
      asc: {
        key: REVIEW_SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: REVIEW_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Employees`),
    accessor: 'with',
    minWidth: '162px',
    renderCell: (item: IReview) => <WithColumn item={item} />,
  },
  {
    name: (i18n: I18n) => i18n._(t`Start Date`),
    accessor: 'date',
    minWidth: '100px',
    sortBy: {
      asc: {
        key: REVIEW_SORT_OPTIONS.START_DATE_OLD_NEW,
        label: (i18n: I18n) => i18n._(t`Soon - later`),
      },
      desc: {
        key: REVIEW_SORT_OPTIONS.START_DATE_NEW_OLD,
        label: (i18n: I18n) => i18n._(t`Later - soon`),
      },
    },
    renderCell: (item: IReview) => {
      return (
        <DateRow>
          {item?.settings?.startDate ? moment(item?.settings?.startDate).format('DD-MM-YYYY') : ''}
        </DateRow>
      );
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Status`),
    accessor: 'status',
    minWidth: '162px',
    renderCell: (item) => {
      return <StatusLabel status={item.status} />;
    },
    sortBy: {
      asc: {
        key: REVIEW_SORT_OPTIONS.STATUS_ASC,
        label: (i18n: I18n) => i18n._(t`Ascending`),
      },
      desc: {
        key: REVIEW_SORT_OPTIONS.STATUS_DESC,
        label: (i18n: I18n) => i18n._(t`Descending`),
      },
    },
  },
];

export { COLUMNS };
