import { IGoal, ITeam, IUser } from '@learned/types';

import { isUserInAnyTeam } from '~/utils/isUserInAnyTeam';

export const isUserCanEditGoal = ({
  goal,
  user,
  teams,
}: {
  goal: IGoal;
  user: IUser;
  teams: Record<string, ITeam>;
}) => {
  if (!goal) {
    return false;
  }
  if (user.isAdmin) {
    return true;
  }
  if (goal.isOwnerCanEdit && goal.owners?.includes(user.id)) {
    return true;
  }
  if (user.isCoach) {
    return goal.isCoachesOfOwnerCanEdit && isUserInAnyTeam(goal.owners as string[], teams, user);
  }

  return false;
};
