import styled from 'styled-components';

import { Button } from '~/components/Buttons';
import { DropdownTemp } from '~/components/Dropdown';
import { Input } from '~/components/Input';
import RickTextView from '~/components/RickTextView';
import Divider from '~/components/UI/Divider';

import { COLORS } from '~/styles';

export const GoalsProgressContainer = styled.div`
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
`;

export const CardTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.63;
  letter-spacing: normal;
  color: ${COLORS.TEXT_MAIN};
  margin-bottom: 12px;
`;

export const CalculationText = styled.div`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
`;

export const ProgressContainer = styled.div`
  margin-top: 16px;
  background-color: ${COLORS.BG_LIST};
  padding: 8px 0px 10px 19px;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 62px;
`;

export const CurrentValueContainer = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
  min-width: 50px;
`;

export const ProgressBarContainer = styled.div`
  margin-left: 20px;
`;

export const StartValueContainer = styled.div`
  margin-left: 23px;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: -0.16px;
`;

export const GoalValueContainer = styled.div`
  margin-left: 20px;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: -0.13px;
  color: ${COLORS.TEXT_HOVER};
`;

export const LabelContainer = styled.div<{
  isMetric?: boolean;
}>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-right: ${({ isMetric }) => (isMetric ? '31px' : '33px')};
  min-height: 32px;
`;

// ------ activity progress section ------ //

export const ActivityProgressContainer = styled.div`
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
`;

export const StyledDivider = styled(Divider)`
  margin-top: 42px;
  margin-bottom: 24px;
`;

export const ActivityItemContainer = styled.div<{
  isHovered: boolean;
  isMetric: boolean;
}>`
  background-color: ${({ isHovered }) => (isHovered ? COLORS.HOVER : COLORS.BG_LIST)};
  border-radius: 6px;
  margin-bottom: 8px;
  cursor: pointer;
  height: ${({ isMetric }) => (isMetric ? '82px' : '62px')};
  align-items: center;
`;

export const ActivityItemInnerContainer = styled.div`
  box-sizing: border-box;
  padding: 16px 20px 16px 20px;
  display: grid;
  grid-template-columns: 60% 20% 20%;
  grid-template-rows: 1fr; /* 1fr makes the row take up the full height */
  gap: 10px;
  align-items: center;
`;

export const ActivityDetailsContainer = styled.div``;

export const ActivityCurrentProgressContainer = styled.div`
  font-size: 13px;
  line-height: 2;
  letter-spacing: -0.14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 32px;
`;

export const ActityStatusLabelContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 31px;
  min-height: 34px;
`;

export const NameContainer = styled.div`
  font-size: 14px;
  height: 21px;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${COLORS.TEXT_MAIN};
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ProgressDetailsContainer = styled.div`
  display: flex;
  gap: 15px;
  font-size: 12px;
  line-height: 2.17;
  letter-spacing: -0.13px;
`;

export const StartValue = styled.div``;

export const CurrentValue = styled.div``;

export const GoalValue = styled.div`
  color: ${COLORS.TEXT_HOVER};
  align-items: center;
`;

export const StyledUpdateButton = styled(Button)`
  margin-right: 10px;
  height: 32px;
`;

// --------- Activity progress card --------- //

export const ActivityCardContainer = styled.div<{
  isLibrary: boolean;
}>`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin: ${({ isLibrary }) => (isLibrary ? '0' : '36px 36px 40px 30px')};
`;
export const ActivityHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ActivityTitle = styled.div`
  letter-spacing: -0.29px;
  font-size: 26px;
  color: ${COLORS.TEXT_HOVER};
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ProgressValuesContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 13px;
`;

export const ProgressValueRow = styled.div<{
  isEdit?: boolean;
}>`
  display: grid;
  grid-template-columns: 21px 97px 400px;
  align-items: center;
  margin-bottom: 0px;
  margin-top: ${({ isEdit }) => (isEdit ? '6px' : '8px')};
`;

export const ProgressIconContainer = styled.div`
  align-items: center;
`;

export const ProgressLabelContainer = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
  align-items: center;
  align-items: center;
`;

export const ProgressValueContainer = styled.div`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
  display: flex;
  align-items: center;
`;

export const DescriptionContainer = styled.div<{
  isEdit?: boolean;
}>`
  margin-top: ${({ isEdit }) => (isEdit ? '6px' : '11px')};
`;

export const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.63;
  color: ${COLORS.ICONS_PRIMARY};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
`;

export const DescriptionText = styled(RickTextView)`
  margin-top: 6px;
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
`;

export const AttachmentsContainer = styled.div`
  margin-top: 16px;
`;

export const AttachmentsList = styled.div`
  margin-right: 12px;
`;

export const AttachmentItem = styled.div`
  height: 62px;
  margin: 10px 0 0;
  padding: 18px 46px 18px 24px;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: ${COLORS.BG_LIST};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FileName = styled.div`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing:.ICONS_PRIMARY
  color: ${COLORS.ICONS_PRIMARY};
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
`;

export const DownloadIconContainer = styled.div``;

export const ActivityModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  gap: 10px;
`;

export const CurrentProgressContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledInput = styled(Input)`
  width: 90px;
  height: 39px;
  margin-right: 10px;
`;

export const StyledDropdown = styled(DropdownTemp)`
  width: 168px;
`;

// --------- Goal progress modal --------- //

export const ProgressModalContainer = styled.div`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin: 36px 36px 40px 30px;
`;

export const ProgressModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProgressModalTitle = styled.div`
  letter-spacing: -0.29px;
  font-size: 26px;
  color: ${COLORS.TEXT_HOVER};
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
