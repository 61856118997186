import React from 'react';

import PropTypes from 'prop-types';

import { GoalDashboardModal } from '../GoalDashboardModal';

export default function GoalDashboardCardModal({ goalId, reviewId, onClose, isNotSharedFeedback }) {
  return (
    <GoalDashboardModal
      goalId={goalId}
      onCloseModal={onClose}
      reviewId={reviewId}
      isNotSharedFeedback={isNotSharedFeedback}
    />
  );
}

GoalDashboardCardModal.propTypes = {
  goalId: PropTypes.string.isRequired,
  reviewId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};
