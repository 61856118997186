import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getQuickStartByCompany() {
  return cloudRequest(serverRoutes.quickStarts.getQuickStartByCompany);
}

export function updateQuickStart(id, data) {
  return cloudRequest(serverRoutes.quickStarts.updateQuickStart, { id }, data);
}

export function resetQuickStartProgress() {
  return cloudRequest(serverRoutes.quickStarts.resetQuickStartProgress);
}

export function getQuickStartsSuperAdmin(companyId = 'all') {
  return cloudRequest(serverRoutes.quickStarts.getQuickStartsSuperAdmin(companyId));
}
