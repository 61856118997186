import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { getCoachTeamsForUser } from '~/components/SideMenu/utils';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import { getCurrentCompanyId, getUser, getTeams } from '~/selectors/baseGetters';
import { updateSelectedTeam } from '~/store/selected/actions';

import { SIDEBAR_ITEM_TYPES } from '../../constants';
import { SidebarRow } from '../SidebarRow';

const TeamsCard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentCompanyId = useSelector(getCurrentCompanyId);
  const user = useSelector(getUser);
  const teams = useSelector(getTeams);

  const userTeams = getCoachTeamsForUser(user, teams, currentCompanyId);
  const firstTeamId = userTeams[0]?.id;

  return (
    <>
      {userTeams.length > 0 ? (
        <SidebarRow
          type={SIDEBAR_ITEM_TYPES.TEAMS}
          // @ts-ignore
          onClick={() => {
            // @ts-ignore
            dispatch(updateSelectedTeam(firstTeamId));
            // @ts-ignore
            history.replace(routes.TEAM_COACH.build({ teamId: firstTeamId, role: ROLES.COACH }));
          }}
          isLoading={false}
          name={userTeams[0].name}
          nameCount={userTeams.length > 0 ? userTeams.length - 1 : null}
        />
      ) : null}
    </>
  );
};

export { TeamsCard };
