import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

const Container = styled.div`
  margin-bottom: 24px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 36px;
`;

const Title = styled.div<{ color?: string; margin?: string; fontWeight?: number }>`
  letter-spacing: -0.16px;
  color: ${({ color }) => (color ? color : COLORS.TEXT_MAIN)};
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
  font-size: 14px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 600)};
`;

const SubTitle = styled.div`
  margin: 3px 0 10px 0;
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
`;

const ExpandContainer = styled.div`
  display: flex;
  &:hover {
    cursor: pointer;
  }
`;

const Question = styled.div`
  margin: 3px 0 10px 0;
  padding: 19px 21px;
  border-radius: 10px;
  background-color: ${COLORS.BG_LIST};
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_HOVER};
`;

const QuestionStrong = styled.span`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: 600;
`;

const ViewAll = styled.div`
  display: flex;
  color: ${COLORS.COMPANY};
  margin: 13px 0;
  font-size: 12px;
  letter-spacing: -0.13px;
  justify-content: center;
`;

const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
`;

export {
  Container,
  Title,
  HeaderContainer,
  ExpandContainer,
  SubTitle,
  Question,
  ViewAll,
  QuestionsContainer,
  QuestionStrong,
};
