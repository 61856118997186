import styled, { css } from 'styled-components';

import { SearchField } from '~/components/Text';
import TextField from '~/components/TextField';

import { COLOR_PALETTE, COLOR_SET, COLORS } from '~/styles';

const MainRow = styled.div<{ hoverstate?: boolean }>`
  display: flex;
  height: 64px;
  box-sizing: border-box;
  background-color: ${(props) => props.color};
  border: 1px solid ${COLORS.BG_PAGE};

  ${(props) =>
    props.hoverstate
      ? css`
          &:hover {
            border-radius: 6px;
            border: solid 1px #dae4f7;
            background-color: #f5f7fd;
          }
        `
      : css`
          &:hover {
            box-shadow: 0 2px 6px 0 rgba(107, 120, 164, 0.3);
          }
        `}

  padding: 0 32px;
`;

const ConversationInfoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: auto;
  margin-left: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ConversationInfoCol = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  width: 100%;
`;

const ConversationInfo = styled.div<{ disabled?: boolean }>`
  display: flex;
  ${(props) => !props.disabled && 'cursor: pointer;'}
  align-items: center;
  overflow: hidden;
  width: 100%;
`;

const NumberOfQuestions = styled.div`
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.17;
  letter-spacing: -0.13px;
  color: ${COLORS.MIDDLE_GRAY};
`;

const Text = styled.div`
  word-break: break-word;
  white-space: pre-wrap;
  max-width: 250px;
`;

const Name = styled.div<{ disabled?: boolean }>`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${(props) => !props.disabled && 'cursor: pointer;'}
  max-width: calc(100% - 20px);

  &::after {
    content: '';
    display: block;
  }
`;

const DateRow = styled.div<{ unplanned?: boolean; wide?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Poppins;
  font-size: 14px;
  min-width: ${(props) => (props.wide ? '162' : '100')}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: ${(props) => (props.unplanned ? COLOR_SET.RED_3 : COLOR_PALETTE.BLACK)};
`;

const ModalHeader = styled.div`
  font-family: Poppins;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  color: ${COLORS.BLACK};
`;

const ModalSubHeader = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLOR_SET.MIDDLE_GRAY_BLUE};
`;

const StyledSearch = styled(SearchField)`
  margin: 16px 0 12px 0;
  ${TextField} {
    border-radius: 6px;
    font-size: 14px;
    height: 40px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  margin-top: 54px;
  justify-content: flex-end;
`;

const CloseButton = styled.div`
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--company-color);
  cursor: pointer;
`;

const AddButton = styled.div<{ disabled?: boolean }>`
  height: 32px;
  padding: 0 16px;
  border-radius: 16px;
  box-shadow: 0 2px 5px 0 rgba(145, 157, 165, 0.12);
  color: ${COLORS.WHITE};
  background-color: ${(props) => (props.disabled ? '#dcdcdc' : 'var(--company-color)')};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const MenuContainer = styled.div`
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CycleOrderWrapper = styled.div<{ selected?: boolean }>`
  padding: 12px 32px;
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  align-items: center;
  ${(props) => props.selected && 'background-color: var(--company-color);'}
`;

const TemplateTitle = styled.div`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${COLORS.MIDDLE_GRAY};
  margin-left: 20px;
`;

const TemplateHeader = styled.div`
  background-color: ${COLORS.BORDER_HARD};
`;

const TemplatesWrapper = styled.div`
  height: 380px;
  overflow: auto;
`;

export {
  MainRow,
  ConversationInfoWrap,
  ConversationInfoCol,
  ConversationInfo,
  Text,
  Name,
  DateRow,
  ModalHeader,
  ModalSubHeader,
  StyledSearch,
  ButtonContainer,
  CloseButton,
  AddButton,
  MenuContainer,
  NumberOfQuestions,
  CycleOrderWrapper,
  TemplateTitle,
  TemplateHeader,
  TemplatesWrapper,
};
