import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import ReviewJobProfileSkillQuestion from '~/components/GiveFeedback/components/Section/components/ReviewJobProfileSkillQuestion';
import { validateRating } from '~/components/GiveFeedback/utils';
import Legend from '~/components/Legend';

import QuestionCard from './QuestionCard';

import { RATING_TYPES } from '~/constants';
import { GIVE_FEEDBACK_LEGEND } from '~/constants/reviews';
import { getCurrentReview, checkModuleIsShowRolesInReview } from '~/selectors/baseGetters';
import { COLOR_PALETTE, COLOR_SET } from '~/styles';

const Role = styled.span`
  min-height: 26px;
  height: 100%;
  background: ${COLOR_SET.TURQUOISE};
  padding: 0 9px 0 7px;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 8px;
  white-space: break-spaces;

  // text
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};

  &:last-child {
    margin-right: 0;
  }
`;

const LegendWrapper = styled.div`
  margin-bottom: 24px;
`;

const CategoryName = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin-bottom: 8px;
  margin-top: 20px;
`;

const JobProfileQuestion = ({
  jobProfile,
  questions,
  ratings,
  fetchedRatings,
  changeQuestionStep,
  showErrors,
  skillLevels,
  onRatingChange,
  i18n,
  skill,
  review: reviewProp,
  ratingType,
  forUser,
  isActive = false,
  tabs,
  onQuestionClick,
  indexCurrent,
  indexTotal,
  isOpenDefault,
  isOpenForce,
  isReadOnly,
}) => {
  const reviewStore = useSelector(getCurrentReview);
  const review = reviewProp || reviewStore;
  const isShowRolesInReview = useSelector(checkModuleIsShowRolesInReview);

  const handleClickQuestion = () => {
    if (onQuestionClick) {
      onQuestionClick(skill.id);
    }
  };

  const isSelf = ratingType === RATING_TYPES.SELF_RATING;
  const questionsForSection = questions.filter(({ skill } = {}) =>
    jobProfile?.skills?.includes(skill?.id),
  );

  const categories = questionsForSection.reduce((arr, { skill, question }) => {
    // check skill categories that active in review template (question)
    // !important if all categories active => review template has empty array [].
    // default categories has categoryId (instead id) for comparison
    const activeCategories = isEmpty(question.skillCategories)
      ? skill.categories || []
      : (skill.categories || []).filter((skillCategoryId) => {
          // define category
          // for default categories -> we need to check "categoryId" -> like soft_skills, it_skills, professional_skills
          // for custom activities - > we check just "id" (they do not have categoryId)
          const category = (review?.skillCategories || []).find(
            (category) =>
              skillCategoryId === category.id || skillCategoryId === category.categoryId, // for company skills // for learned skills, they have categoryId = soft_skills
          );
          return question.skillCategories.includes(category.id);
        });
    return [...arr, ...(activeCategories || [])];
  }, []);

  const categoriesUniq = uniq(
    categories
      .map((categoryId) =>
        (review?.skillCategories || []).find(
          (category) => categoryId === category.id || categoryId === category.categoryId, // for company skills // for learned skills, they have categoryId = soft_skills
        ),
      )
      .filter((c) => c),
  );

  const { color, label } = GIVE_FEEDBACK_LEGEND[ratingType];

  const legend = [
    { color, label: label(i18n) },
    { color: COLOR_PALETTE.GRAY_MIDDLE, label: i18n._(t`Expected level for role`) },
  ];

  return (
    <QuestionCard
      title={
        <>
          <span>
            {isSelf
              ? i18n._(t`How did you perform at the relevant skills & competenties for the role`)
              : i18n._(
                  t`How did ${forUser.firstName} perform at the relevant skills & competenties for the role`,
                )}
          </span>
          {jobProfile.name && isShowRolesInReview && <Role>{jobProfile.name}</Role>}
        </>
      }
      isActive={isActive}
      onClick={handleClickQuestion}
      hideFooter
      indexCurrent={indexCurrent}
      indexTotal={indexTotal}
      isOpenDefault={isOpenDefault}
      isOpenForce={isOpenForce}
      cardContentStyles={{ padding: '24px' }}
    >
      <LegendWrapper>
        <Legend items={legend} />
      </LegendWrapper>
      {categoriesUniq.map((category, categoryIndex) => {
        return (
          <div key={category.id}>
            <CategoryName>{category.name}</CategoryName>
            {questionsForSection
              // filter questions only for current category
              .filter(
                ({ skill }) =>
                  (skill.categories || []).includes(category.id) || // for company skills
                  (skill.categories || []).includes(category.categoryId), // for learned skills
              )
              .map(({ question, skill }, subQuestionIndex) => {
                const isOpenDefault = categoryIndex === 0 && subQuestionIndex === 0; // open first question in first category

                const rating = ratings[skill.id] || {};
                const typeRatings = fetchedRatings.filter((rating) => rating.skill === skill.id);

                return (
                  <ReviewJobProfileSkillQuestion
                    key={skill.id}
                    question={question}
                    skill={skill}
                    forUser={forUser}
                    rating={rating}
                    typeRatings={typeRatings}
                    onQuestionClick={changeQuestionStep}
                    isError={showErrors && validateRating(rating)}
                    expectedLevel={skillLevels[skill.id]}
                    onChange={onRatingChange}
                    tabs={tabs}
                    isOpenDefault={isOpenDefault}
                    color={color}
                    isReadOnly={isReadOnly}
                  />
                );
              })}
          </div>
        );
      })}
    </QuestionCard>
  );
};

export default withI18n()(JobProfileQuestion);
