import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import PropTypes from 'prop-types';

import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';

import TemplateItem from './TemplateItem';

import TemplateIcon from '~/assets/template.svg';

import { TALKING_POINT_TYPES } from '~/constants';
import { COLOR_PALETTE } from '~/styles';

function TabDefault({
  i18n,
  loading,
  items,
  selectedItems,
  disabledItems,
  toggleItem,
  toggleItems,
}) {
  return (
    <>
      <ShowSpinnerIfLoading loading={loading}>
        {isEmpty(items) ? (
          <Placeholder
            title={i18n._(t`No templates available`)}
            subTitle={i18n._(t`Create a conversation template.`)}
            Icon={() => (
              <SvgIcon
                url={TemplateIcon}
                width={'48px'}
                height={'48px'}
                isDefaultColor
                defaultColor={COLOR_PALETTE.GRAY_SEMI_SOFT}
              />
            )}
          />
        ) : (
          items.map((template, index) => (
            <TemplateItem
              key={template.id}
              template={template}
              selectedItems={selectedItems}
              disabledItems={disabledItems}
              toggleItem={toggleItem}
              toggleItems={toggleItems}
              openByDefault={!index}
            />
          ))
        )}
      </ShowSpinnerIfLoading>
    </>
  );
}

TabDefault.propTypes = {
  type: PropTypes.oneOf(map(TALKING_POINT_TYPES, (type) => type.key)),
  items: PropTypes.array,
  selectedItems: PropTypes.object,
  disabledItems: PropTypes.array,
  toggleItem: PropTypes.func,
  toggleItems: PropTypes.func,
  loading: PropTypes.bool,
  placeholder: PropTypes.node,
};

export default withI18n()(TabDefault);
