import styled from 'styled-components';

import { SearchField } from '~/components/Text';

import { COLORS } from '~/styles';

const TopLayer = styled.div`
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: ${COLORS.WHITE};
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
`;

const Wrapper = styled.div<{ expanded: boolean; isDisabled: boolean }>`
  width: ${({ expanded }) => (expanded ? '100%' : '1000px')};
  margin: 0 auto;
  user-select: none;
  cursor: default;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  position: relative;
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  box-sizing: border-box;
  margin-top: 6px;
  gap: 10px;
  padding: 0 10px;
  .skill-button {
    margin-left: 10px;
    cursor: pointer;
  }
`;

const SearchContainer = styled.div`
  flex: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  & > .active:hover {
    border-color: ${COLORS.COMPANY};
    color: ${COLORS.COMPANY};
  }
  & > .active {
    border-color: ${COLORS.COMPANY};
    color: ${COLORS.COMPANY};
  }
`;

const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  width: 320px;
  font-size: 14px;
  font-weight: 600;
  & input {
    border-radius: 10rem;
  }
`;

const ActionsWrap = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
`;

const ActionItemBlock = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
`;

const WrapperOuter = styled.div`
  padding: 16px 32px 32px;
  background: ${COLORS.WHITE};
  margin-top: 20px;
  position: relative;
  min-height: calc(100vh - 220px);
`;

const MainContainer = styled.div`
  font-size: 14px;
  & > .dashboard-header {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.9);
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
`;

const ContextWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .tippy-content {
    padding: 0;
  }
  .tippy-box {
    background: transparent;
  }
`;

const OptionsBtn = styled.div`
  padding: 4px 20px;
  box-sizing: border-box;
  border-radius: 6px;
  background: ${COLORS.COMPANY};
  color: ${COLORS.WHITE};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  user-select: none;
  border-radius: 40px;
  .icon {
    flex: none;
  }
`;

const TitleContainer = styled.div`
  font-size: 14px;
`;

const MainTitle = styled.div`
  font-weight: 500;
`;

const Description = styled.div`
  color: ${COLORS.SUBTEXT};
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLORS.WHITE};
  color: ${COLORS.TEXT_HOVER};
  font-weight: 400;
  font-size: 14px;
  border: 1px solid ${COLORS.ICONS_SECONDARY};
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
`;

const OptionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 10px 15px;
  min-width: 300px;
  border-bottom: 1px solid ${COLORS.BORDERS};
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background: ${COLORS.HOVER};
  }
`;

const BoxBtnContainer = styled.div<{ firstColor: string; secondColor: string }>`
  width: 40px;
  height: 40px;
  padding: 11px 11px 11px;
  border-radius: 6px;
  background-image: linear-gradient(
    48deg,
    ${(props) => props.firstColor} 2%,
    ${(props) => props.secondColor} 93%
  );
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.WHITE};
`;

const PaginationContainer = styled.div`
  position: relative;
`;

export {
  FilterWrapper,
  Wrapper,
  SearchContainer,
  SearchFieldWrapper,
  ActionsWrap,
  ActionItemBlock,
  WrapperOuter,
  MainContainer,
  ModalContainer,
  LoadingContainer,
  TopLayer,
  ContextWrapper,
  Options,
  TitleContainer,
  MainTitle,
  Description,
  OptionItem,
  OptionsBtn,
  BoxBtnContainer,
  PaginationContainer,
};
