import { ROLES } from '~/constants';
import { pageOverview } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'tasks';

// overview
/**
 * @deprecated Should not be used anymore (we use it only in redirect routers)
 */
export const TASKS = new LinkConstructor([ROLES.USER], () => pageOverview(routeName));
