import { createReducer } from 'redux-act';

import * as actions from './actions';

export default createReducer(
  {
    [actions.setCompanyConnections]: (state, payload) => {
      return {
        ...state,
        ...payload,
      };
    },
    [actions.updateCompanyConnection]: (state, payload) => {
      return {
        ...state,
        [payload.id]: payload,
      };
    },
  },
  {},
);
