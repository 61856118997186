import React from 'react';

import { t } from '@lingui/macro';

import type { IColumnTable } from '~/@types/table';

import { SORT_OPTIONS } from '../../types';
import { NameRow } from '../design';

import type { I18n } from '@lingui/core';

export const createJobProfilesColumns = (): {
  columns: IColumnTable<{ name: string; requiredSkillLevel: string }>[];
} => {
  return {
    columns: [
      {
        name: (i18n: I18n) => i18n._(t`Job`),
        accessor: 'name',
        maxWidth: '400px',
        renderCell: ({ name }) => {
          return name ? <NameRow maxWidth="400px">{name}</NameRow> : <></>;
        },
        sortBy: {
          asc: {
            key: SORT_OPTIONS.NAME_A_Z,
            label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
          },
          desc: {
            key: SORT_OPTIONS.NAME_Z_A,
            label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
          },
        },
      },
      {
        name: (i18n: I18n) => i18n._(t`Required Skill level`),
        accessor: 'requiredSkillLevel',
        renderCell: ({ requiredSkillLevel }) => {
          return requiredSkillLevel ? (
            <NameRow maxWidth="400px">{requiredSkillLevel}</NameRow>
          ) : (
            <></>
          );
        },
        sortBy: {
          asc: {
            key: SORT_OPTIONS.LEVEL_ASC,
            label: (i18n: I18n) => i18n._(t`High - low`),
          },
          desc: {
            key: SORT_OPTIONS.LEVEL_DESC,
            label: (i18n: I18n) => i18n._(t`Low - high`),
          },
        },
      },
    ],
  };
};
