import styled from 'styled-components';

import { Table } from '~/components/Table';

import { COLORS } from '~/styles';

const Container = styled.div`
  border-top: solid 1px ${COLORS.BORDERS};
`;

const FlexRow = styled.div<{
  $isHidePointer?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: ${({ $isHidePointer }) => (!$isHidePointer ? 'pointer' : 'default')};
`;

const TextRow = styled.div<{ isDraft?: boolean }>`
  color: ${({ isDraft }) => (isDraft ? COLORS.ACTIVITY_GREY : COLORS.TEXT_HOVER)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  cursor: pointer;
  padding: 0 32px;

  &::after {
    content: '';
    display: block;
  }
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: -0.18px;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const StyledTable = styled(Table)`
  box-sizing: border-box;
  border-top: 1px solid ${COLORS.BORDERS};
  padding-top: 14px;
  & * {
    box-sizing: border-box;
  }

  & th:first-child {
    text-align: left;
  }

  td {
    padding: 0;
    margin: 0;
    min-width: auto;
  }

  th > div {
    margin: 0;
    padding: 16px 32px;
    min-width: min-content !important;
  }

  th:nth-child(2) > div {
    margin: 0;
    padding: 0;
  }

  tbody > tr:nth-child(2n + 1) {
    background: ${COLORS.BG_LIST};
    border-radius: 6px;
    :hover {
      background-color: ${COLORS.HOVER};
    }
  }

  tr {
    border: none;
    height: 64px;
    border-radius: 6px;
    :hover {
      background-color: ${COLORS.HOVER};
    }
  }

  thead {
    & tr {
      user-select: none;
      cursor: default;
      :hover,
      span:hover,
      div:hover {
        background-color: ${COLORS.WHITE};
      }
    }
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 24px;
`;

export { Container, TextRow, FlexRow, StyledTable, Footer };
