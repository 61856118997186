import { useMemo } from 'react';

import { ProductName } from '@learned/constants';
import { useForm } from 'react-hook-form';

import { LANGUAGES } from '~/constants';

import type { IRegisterWithCompanyForm } from './types';

const useRegisterWithCompany = () => {
  const defaultLanguage = useMemo(() => {
    const lang = navigator.language;
    if (lang.match(LANGUAGES.NL)) {
      return LANGUAGES.NL;
    }

    return LANGUAGES.EN;
  }, []);

  const formMethods = useForm<IRegisterWithCompanyForm>({
    mode: 'all',
    defaultValues: {
      step: 1,
      password: '',
      // @ts-ignore
      lang: defaultLanguage,
      products: [ProductName.JOB_MATRIX],
    },
  });

  return { formMethods };
};

export { useRegisterWithCompany };
