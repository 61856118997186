import React from 'react';

import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';
import ExternalInstructions from '~/components/UI/ExternalInstructions';

import { COLOR_PALETTE } from '~/styles';

const Wrapper = styled.div`
  display: flex;
`;

const Box = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  box-sizing: border-box;
  height: 100%;
  flex-direction: column;
`;

const BoxRight = styled(Box)`
  align-items: flex-start;
  flex: none;
  flex-direction: row;
  justify-content: flex-end;
`;

const Title = styled.div`
  color: ${COLOR_PALETTE.BLACK};
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
`;

const Description = styled.div`
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 144px;
  height: 32px;
  border-radius: 16px;
  ${(props) => !props.noBorder && 'box-shadow: 0 2px 4px 0 rgba(145, 157, 165, 0.12);'}
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  margin-left: 8px;
  ${(props) => {
    if (props.primary) {
      return 'background-color: var(--company-color); color: white';
    } else {
      return 'background-color: white, color: var(--company-color);';
    }
  }}
`;

const TabHeader = ({
  title,
  description,
  instructions,
  instructionsUrl,
  className,
  action,
  actionLabel,
}) => {
  return (
    <Wrapper className={className}>
      <Box>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Box>
      {((instructions && instructionsUrl) || (action && actionLabel)) && (
        <BoxRight>
          {instructions && instructionsUrl && (
            <ExternalInstructions instructions={instructions} instructionsUrl={instructionsUrl} />
          )}
          {action && actionLabel && <StyledButton primary label={actionLabel} onClick={action} />}
        </BoxRight>
      )}
    </Wrapper>
  );
};

TabHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  instructions: PropTypes.string,
  instructionsUrl: PropTypes.string,
};

export default TabHeader;
