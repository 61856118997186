import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS, Icon } from '~/components/Icon';

import useBoolState from '~/hooks/useBoolState';
import { getParent, getType } from '~/selectors/currentGoal';
import { setCurrentGoalParent } from '~/store/currentGoal/actions';

import {
  ContributeGoalIcon,
  ContributeGoalItemWrap,
  ContributeGoalName,
  ContributeSectionRow,
  ContributeSectionWrap,
  ItemLabel,
} from '../../stepsDesign';
import { GoalsToContributeModal } from '../GoalsToContributeModal';

import type { IGoal } from '@learned/types';

const ContributeSection = () => {
  const { i18n } = useLingui();
  const type = useSelector(getType);
  const dispatch = useDispatch();
  const $isContributeModalVisible = useBoolState(false);
  const parentGoal = useSelector(getParent) as IGoal;

  const onSelectGoal = (parentGoal: IGoal) => {
    // @ts-ignore
    dispatch(setCurrentGoalParent({ id: parentGoal.id, name: parentGoal.name }));
    $isContributeModalVisible.off();
  };

  const onRemoveParentGoal = () => {
    // @ts-ignore
    dispatch(setCurrentGoalParent(null));
  };

  return (
    <ContributeSectionWrap>
      <ContributeSectionRow>
        <ItemLabel bold noMarginBottom>
          <Trans>Contributes to goal</Trans>
        </ItemLabel>
        <Button
          label={i18n._(t`Add goal`)}
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          onClick={$isContributeModalVisible.on}
          disabled={!!parentGoal}
        />
      </ContributeSectionRow>

      {parentGoal && (
        <ContributeGoalItemWrap>
          <ContributeGoalName>{parentGoal.name}</ContributeGoalName>
          <ContributeGoalIcon>
            {/* @ts-ignore */}
            <Icon icon={ICONS.DELETE_BIN} onClick={onRemoveParentGoal} size="20px" />
          </ContributeGoalIcon>
        </ContributeGoalItemWrap>
      )}

      {$isContributeModalVisible.value && (
        <GoalsToContributeModal
          type={type}
          onClose={$isContributeModalVisible.off}
          onSelectGoal={onSelectGoal}
        />
      )}
    </ContributeSectionWrap>
  );
};

export { ContributeSection };
