import React, { forwardRef } from 'react';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import Tooltip from '~/components/Tooltip';
import type {
  IUserReviewQuestionCustomSkillGrouped,
  IUserReviewQuestionSkillCategoryGrouped,
} from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/utils';

import { RowHeader } from './SkillAnswersModal.design';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import type { IReviewRating, ISkill } from '@learned/types';

const SkillRowModal = forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    onClick?: () => void;
    skill:
      | IUserReviewQuestionSkillCategoryGrouped['skills'][0]
      | IUserReviewQuestionCustomSkillGrouped['skills'][0];
    ratingFilter?: (rating?: IReviewRating) => boolean;
  }
>(({ skill, isOpen, onClick, ratingFilter = (value) => value }, ref) => {
  const getMultiLangString = useMultiLangString();

  const totalComments = (skill.questions?.at(0)?.reviewRatings ?? [])
    .filter(ratingFilter)
    .map((rating) => rating.comment)
    .filter(Boolean).length;

  return (
    <RowHeader ref={ref} isOpen={isOpen} onClick={onClick}>
      <Icon
        transform={isOpen ? 'rotate(90)' : 'rotate(0)'}
        size={ICON_SIZES.MEDIUM}
        icon={ICONS.NEXT}
        color={isOpen ? COLORS.COMPANY : COLORS.INACTIVE}
      />
      <Tooltip
        tooltip={getMultiLangString((skill.questions.at(0)?.settings?.skill as ISkill)?.name ?? '')}
      >
        <span className="skillName">
          {getMultiLangString((skill.questions.at(0)?.settings?.skill as ISkill)?.name ?? '')}
        </span>
      </Tooltip>
      {totalComments > 0 && (
        <span className="commentCount">
          <Icon size={ICON_SIZES.MEDIUM} icon={ICONS.COMMENT} /> {totalComments}
        </span>
      )}
    </RowHeader>
  );
});

export { SkillRowModal };
