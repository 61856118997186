export enum ROLES {
  ADMIN = 'admin',
  COACH = 'coach',
  USER = 'user',
  OWNER = 'owner',
  USER_OUTSIDE = 'user-outside',
}

export enum NOTIFICATION_PREFERENCES {
  reviews = 'reviews',
  conversations = 'conversations',
  feedback = 'feedback',
  goals = 'goals',
  career = 'career',
  development = 'development',
  surveys = 'surveys',
  teams = 'teams',
}

export enum USER_GENDER {
  MAN = 'man',
  WOMAN = 'woman',
  OTHER = 'other',
}

export enum NOTIFICATION_OPTION {
  EMAIL = 'email',
  SYSTEM = 'system',
}
