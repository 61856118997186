import groupBy from 'lodash/groupBy';

import { REQUEST_STATUSES, REQUEST_TYPES } from '~/constants';

const userRequestMap = (r) => r.toUser;
const emailRequestMap = (r) => r.toEmail;

export function getRequestsUsers(
  requests = [],
  requestFilter = (r) => r.status !== REQUEST_STATUSES.CANCELLED.key,
) {
  const requestsList = requests.filter(requestFilter);
  const requestsByType = groupBy(requestsList, (r) => r.type);

  return {
    peers: requestsList
      .filter(
        (r) =>
          r.type === REQUEST_TYPES.FEEDBACK_FROM_PEER ||
          r.type === REQUEST_TYPES.FEEDBACK_FROM_OUTSIDE_PEER,
      )
      .map((r) => userRequestMap(r) || emailRequestMap(r)),
    users: (requestsByType[REQUEST_TYPES.FEEDBACK_FROM_PEER] || []).map(userRequestMap),
    coaches: (requestsByType[REQUEST_TYPES.FEEDBACK_FROM_COACH] || []).map(userRequestMap),
    conversationCoaches: (requestsByType[REQUEST_TYPES.NOMINATE_CONVERSATION_COACH] || []).map(
      userRequestMap,
    ),
    outside: (requestsByType[REQUEST_TYPES.FEEDBACK_FROM_OUTSIDE_PEER] || []).map(emailRequestMap),
  };
}
