import React, { Component } from 'react';

import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';
import DefaultSlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './styles.scss';

class Slider extends Component {
  static propTypes = {
    value: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    onChange: PropTypes.func,
    onAfterChange: PropTypes.func,
    railStyle: PropTypes.object,
    trackStyle: PropTypes.object,
    dotStyle: PropTypes.object,
    handleStyle: PropTypes.object,
    disabled: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    value: 0,
    min: 0,
    max: 10,
    step: 0.1,
    onChange: () => {},
    onAfterChange: () => {},
    disabled: false,
    railStyle: {
      height: '12px',
      backgroundColor: '#ecedf0',
      borderRadius: '#f8f8f8',
    },
  };

  render() {
    const {
      value,
      min,
      max,
      step,
      onChange,
      railStyle,
      dotStyle,
      disabled,
      trackStyle,
      handleStyle,
      className,
      onAfterChange,
    } = this.props;

    const companyTrackStyle = {
      height: '12px',
      borderRadius: 'none',
      backgroundColor: 'var(--company-color)',
    };

    const companyhandleStyle = {
      marginLeft: '0px',
      marginTop: '-6px',
      borderColor: 'transparent',
      boxShadow: 'none',
      width: '24px',
      height: '24px',
      backgroundColor: 'var(--company-color)',
    };

    return (
      <DefaultSlider
        className={className || 'rc-slider-custom'}
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={onChange}
        onAfterChange={onAfterChange}
        railStyle={railStyle}
        trackStyle={trackStyle || companyTrackStyle}
        dotStyle={dotStyle}
        disabled={disabled}
        handleStyle={handleStyle || companyhandleStyle}
      />
    );
  }
}

export default withI18n()(Slider);
