import { WithPartial } from '@learned/types/src/generic';

import type { ICreateServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

import type { ISurveyQuestion } from '@learned/types';

interface ICreateSurveyQuestionProps {
  question: ISurveyQuestion;
  position: number;
}

export function createSurveyQuestion(data: ICreateSurveyQuestionProps) {
  // @ts-ignore
  return cloudRequest(serverRoutes.surveyQuestions.createSurveyQuestion, {}, data);
}

export function updateSurveyQuestion(
  questionId: ISurveyQuestion['id'],
  question: Omit<
    WithPartial<ISurveyQuestion, 'isRequired' | 'name' | 'type'>,
    'id' | 'company' | 'meta' | 'theme'
  >,
) {
  // @ts-ignore
  return cloudRequest(serverRoutes.surveyQuestions.updateSurveyQuestion(questionId), {}, question);
}

export function copySurveyQuestion(questionId: ISurveyQuestion['id']) {
  // @ts-ignore
  return cloudRequest(serverRoutes.surveyQuestions.copySurveyQuestion(questionId));
}

export function deleteSurveyQuestion(questionId: ISurveyQuestion['id']) {
  return cloudRequest(
    (serverRoutes.surveyQuestions.deleteSurveyQuestion as ICreateServerRoute)(questionId),
  );
}
