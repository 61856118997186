import React, { useEffect, useMemo, useState } from 'react';

import last from 'lodash/last';
import size from 'lodash/size';
import { useFieldArray, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import { Comment } from './Comment';

import starIcon from '~/assets/main-menu-icons/star.svg';

import type { IQuestionForm } from '~/@types/question';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';
import { getMultiLangString as getMultiLangStringOriginal } from '~/utils/getMultiLangString';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import type { ICompany, IConnection, ILanguage, ISurveyRating } from '@learned/types';

interface IQuestionViewStarsProps {
  defaultValues: IQuestionForm;
  languageState: ILanguageStateReturn;
  isPreselectedLang?: boolean;
  error?: boolean;
  commentError?: boolean;
  isPreferredLanguage?: boolean;
  onChange?: (data: {
    answer?: ISurveyRating['answer'];
    comment?: ISurveyRating['comment'];
  }) => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;

  & .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const Title = styled.div`
  font-size: 26px;
  color: ${COLORS.BLACK};
  text-align: center;
  margin-bottom: 48px;
`;

const AnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StarWrapper = styled.div<{ error?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 5px;
  color: ${COLORS.BORDER_HARD};
  ${(props) =>
    props.error
      ? css`
          border: solid 2px ${COLORS.ERROR};
        `
      : css`
          border: solid 2px transparent;
        `}
`;

const Star = styled.div<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  color: ${COLORS.INACTIVE};

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${COLORS.STAR};
    `}

  &:hover {
    color: ${COLORS.STAR};
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Label = styled.label`
  font-size: 14px;
  color: ${COLORS.MIDDLE_GRAY};
`;

const QuestionViewStars = ({
  languageState,
  defaultValues,
  isPreselectedLang = false,
  onChange,
  error,
  commentError,
  isPreferredLanguage = false,
}: IQuestionViewStarsProps) => {
  const formMethods = useForm<IQuestionForm>({ defaultValues });
  const { reset, control, watch } = formMethods;
  const nameWatch = watch('name');
  const { fields, update } = useFieldArray({ name: 'options', control });
  const { companyPrimaryLanguage, languages } = languageState;
  const getMultiLangString = useMultiLangString();

  const locale = isPreselectedLang ? languages[0].locale : companyPrimaryLanguage.locale;

  const [mouseHoverIndex, setMouseHoverIndex] = useState(-1);

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const questionTitle = useMemo(() => {
    const multiLang = turnArrayIntoMultiLang(nameWatch);
    if (isPreferredLanguage) {
      return getMultiLangString(multiLang);
    } else {
      return getMultiLangStringOriginal(
        multiLang,
        { preferredLang: { locale } as ILanguage } as IConnection,
        {
          primaryLang: companyPrimaryLanguage,
          languages,
        } as ICompany,
      );
    }
  }, [
    nameWatch,
    isPreferredLanguage,
    getMultiLangString,
    locale,
    companyPrimaryLanguage,
    languages,
  ]);

  const firstFieldLabel = useMemo(() => {
    const multiLang = turnArrayIntoMultiLang(fields[0].label);
    if (isPreferredLanguage) {
      return getMultiLangString(multiLang);
    } else {
      return getMultiLangStringOriginal(
        multiLang,
        { preferredLang: { locale } as ILanguage } as IConnection,
        {
          primaryLang: companyPrimaryLanguage,
          languages,
        } as ICompany,
      );
    }
  }, [fields, isPreferredLanguage, getMultiLangString, locale, companyPrimaryLanguage, languages]);

  const lastFieldLabel = useMemo(() => {
    const multiLang = turnArrayIntoMultiLang(last(fields)?.label ?? []);
    if (isPreferredLanguage) {
      return getMultiLangString(multiLang);
    } else {
      return getMultiLangStringOriginal(
        multiLang,
        { preferredLang: { locale } as ILanguage } as IConnection,
        {
          primaryLang: companyPrimaryLanguage,
          languages,
        } as ICompany,
      );
    }
  }, [fields, isPreferredLanguage, getMultiLangString, locale, companyPrimaryLanguage, languages]);

  const selectOption = (index: number) => {
    fields.map((item, i) => {
      update(i, { ...item, value: i <= index });
    });
    onChange?.({ answer: index });
  };

  return (
    <Wrapper>
      <Title>{questionTitle}</Title>
      <AnswerWrapper>
        <Options>
          <StarWrapper error={error}>
            {fields.map(({ value }, index) => (
              <Star
                isSelected={index <= mouseHoverIndex || value}
                key={`star-${index}`}
                onMouseOver={() => setMouseHoverIndex(index)}
                onMouseOut={() => setMouseHoverIndex(-1)}
                onClick={() => selectOption(index)}
              >
                <SvgIcon
                  width={size(fields) < 6 ? '48px' : '32px'}
                  height={size(fields) < 6 ? '48px' : '32px'}
                  isDefaultColor
                  defaultColor="currentColor"
                  url={starIcon}
                />
              </Star>
            ))}
          </StarWrapper>
          <Row>
            <Label>{firstFieldLabel}</Label>
            <Label>{lastFieldLabel}</Label>
          </Row>
        </Options>

        <Comment error={commentError} formMethods={formMethods} onChange={onChange} />
      </AnswerWrapper>
    </Wrapper>
  );
};

export { QuestionViewStars };
