import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';

export default (userId, teams, companyId = '') => {
  if (!userId || isEmpty(teams)) {
    return [];
  }

  const userTeams = filter(teams, (team) => (team.coaches || []).indexOf(userId) !== -1);

  if (companyId) {
    return userTeams.filter((team) => (team.company.id || team.company) === companyId);
  }

  return userTeams;
};
