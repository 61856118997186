import styled from 'styled-components';

import Shadow from '~/components/UI/Shadow';

import { COLOR_PALETTE } from '~/styles';

const BoxWithBorder = styled(Shadow)`
  background-color: ${COLOR_PALETTE.WHITE};
  box-sizing: border-box;
  ${(props) => props.$noBorder && 'border: none;'}
  ${(props) => props.$noShadow && 'box-shadow: none;'}
  ${(props) => props.$noTopBorder && 'border-top: none;'}
  ${(props) => props.$noBottomBorder && 'border-bottom: none;'}
  ${(props) =>
    props.$noTopBorder && props.$noBottomBorder
      ? 'border-radius: 0;'
      : props.$noTopBorder
      ? 'border-radius: 0 0 6px 6px;'
      : props.$noBottomBorder && 'border-radius: 6px 6px 0 0;'}
`;

export default BoxWithBorder;
