import React, { useState } from 'react';

import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { HeaderTabs } from '~/components/HeaderTabs';
import NoCalendarIntegrationWarning from '~/components/Warnings/NoCalendarIntegrationWarning';
import BaseLayout from '~/layouts/BaseLayout';

import { MeetingsTab } from './tabs/Meetings';
import { TemplatesTab } from './tabs/Templates';

import { getUser } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';

const HeaderWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  width: fit-content;
  border-radius: 6px 6px 0 0;
`;

export const TABS_ENUM = {
  MEETINGS: 'meetings',
  TEMPLATES: 'templates',
};

const TABS = [
  {
    label: (i18n: I18n) => i18n._(t`Meetings`),
    key: TABS_ENUM.MEETINGS,
  },
  {
    label: (i18n: I18n) => i18n._(t`Templates`),
    key: TABS_ENUM.TEMPLATES,
  },
];

interface IMeetingsSectionProps {
  parentHash: string;
}

const MeetingsSection = ({ parentHash }: IMeetingsSectionProps) => {
  const user = useSelector(getUser);

  // currentHash everything after "-", so for #development-meetings -> the hash is meetings
  const currentHash = window.location.hash.substring(1).split('-')[1] || TABS_ENUM.MEETINGS;
  const defaultTab = TABS.find((item) => item.key === currentHash);
  const [currentTab, setCurrentTab] = useState(defaultTab?.key as string);

  const handleChangeTab = (key: string) => {
    window.location.hash = `${parentHash}-${key}`;
    setCurrentTab(key);
  };

  return (
    <>
      <BaseLayout smallWidth>
        <NoCalendarIntegrationWarning />
        {user.isAdmin && (
          <HeaderWrapper>
            <HeaderTabs tabs={TABS} selectedTab={currentTab} handleChangeTab={handleChangeTab} />
          </HeaderWrapper>
        )}
        {currentTab === TABS_ENUM.MEETINGS && <MeetingsTab />}
        {currentTab === TABS_ENUM.TEMPLATES && <TemplatesTab />}
      </BaseLayout>
    </>
  );
};

export { MeetingsSection };
