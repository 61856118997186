import { useCallback, useState } from 'react';

import every from 'lodash/every';

function useMultiSelectState(items: { id: string }[]) {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const onSelectItem = useCallback(
    (item: { id: string }) =>
      setSelectedItems((prevState) => {
        if (prevState.includes(item.id)) {
          return prevState.filter((value) => value !== item.id);
        }
        return [...prevState, item.id];
      }),
    [],
  );

  const isItemChecked = useCallback(
    (item: { id: string }) => selectedItems.includes(item.id),
    [selectedItems],
  );

  const onCheckAll = useCallback(() => {
    if (every(items.map((item) => selectedItems.includes(item.id)))) {
      setSelectedItems((prev) =>
        prev.filter((itemId) => !items.map((item) => item.id).includes(itemId)),
      );
    } else {
      const selectedIds = new Set(selectedItems);
      items.forEach((item) => selectedIds.add(item.id));
      setSelectedItems(Array.from(selectedIds));
    }
  }, [selectedItems, items]);

  const reset = useCallback(() => setSelectedItems([]), []);

  return { selectedItems, onSelectItem, isItemChecked, onCheckAll, resetSelectedItems: reset };
}

export { useMultiSelectState };
