import { SURVEY_SORT_OPTIONS } from '@learned/constants';
import FileSaver from 'file-saver';
import isEmpty from 'lodash/isEmpty';

import type { IServerRoute, ICreateServerRoute, IUpdateServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

import type { ISurvey } from '@learned/types';

export function getSurveys(
  filters: { search?: string; statuses?: ISurvey['status'][]; types?: ISurvey['type'][] },
  options: { skip: number; limit: number; sortBy: SURVEY_SORT_OPTIONS },
) {
  const body = {
    filters: {
      search: filters.search,
      ...(!isEmpty(filters.statuses) && { statuses: filters.statuses }),
      ...(!isEmpty(filters.types) && { types: filters.types }),
    },
    options: {
      skip: options.skip,
      limit: options.limit,
      sortBy: options.sortBy,
    },
  };
  return cloudRequest(serverRoutes.surveys.getSurveys as IServerRoute, {}, body);
}

export function createSurvey(body: { type: ISurvey['type'] }) {
  return cloudRequest(serverRoutes.surveys.createSurvey as IServerRoute, {}, body);
}

export function updateSurvey(surveyId: string, body: { type: ISurvey['type'] }) {
  return cloudRequest(
    (serverRoutes.surveys.updateSurvey as IUpdateServerRoute)(surveyId),
    {},
    body,
  );
}

export function getSurvey(surveyId: string, { populate }: { populate: string[] }) {
  return cloudRequest((serverRoutes.surveys.getSurvey as ICreateServerRoute)(surveyId), null, {
    populate,
  });
}

export function deleteSurveys(surveys: string[]) {
  return cloudRequest(serverRoutes.surveys.deleteSurveys as IServerRoute, {}, { surveys });
}

export function getSurveyParticipation(
  surveyId: string,
  options?: { skip?: number; limit?: number; sortBy?: SURVEY_SORT_OPTIONS },
  filters?: { search?: string },
) {
  const body = {
    options: {
      skip: options?.skip,
      limit: options?.limit,
      sortBy: options?.sortBy,
    },
    filters,
  };

  return cloudRequest(
    (serverRoutes.surveys.getSurveyParticipation as ICreateServerRoute)(surveyId),
    {},
    body,
  );
}

export function getSurveyResultsTeams(
  surveyId: string,
  filters: { search?: string; themes?: string[] },
  options?: { skip?: number; limit?: number; sortBy?: SURVEY_SORT_OPTIONS },
) {
  const body = {
    options,
    filters,
  };

  return cloudRequest(
    (serverRoutes.surveys.getSurveyResultsTeams as ICreateServerRoute)(surveyId),
    {},
    body,
  );
}

export function downloadSurveyResultsTeamsCSV(
  surveyId: string,
  filters: { search?: string; themes?: string[] },
) {
  const body = {
    filters,
  };

  return cloudRequest(
    (serverRoutes.surveys.resultsTeamsCSV as ICreateServerRoute)(surveyId),
    {},
    body,
  ).then((response) => {
    FileSaver.saveAs(response, 'surveyResultTeams.csv');
  });
}

export function getSurveyMembers(
  surveyId: string,
  filters: { search?: string },
  options?: { skip?: number; limit?: number },
) {
  const body = {
    options,
    filters,
  };

  return cloudRequest(
    (serverRoutes.surveys.getSurveyMembers as ICreateServerRoute)(surveyId),
    {},
    body,
  );
}

export function downloadSurveyMembersCSV(surveyId: string, filters: { search?: string }) {
  const body = {
    filters,
  };

  return cloudRequest(
    (serverRoutes.surveys.getSurveyMembersCSV as ICreateServerRoute)(surveyId),
    {},
    body,
  ).then((response) => {
    FileSaver.saveAs(response, 'surveyMembers.csv');
  });
}

export function downloadSurveyParticipationCSV(surveyId: string) {
  return cloudRequest(
    (serverRoutes.surveys.downloadSurveyParticipationCSV as ICreateServerRoute)(surveyId),
    {},
  ).then((response) => {
    FileSaver.saveAs(response, 'surveyParticipation.csv');
  });
}

export function getSurveyParticipationChart(surveyId: string) {
  return cloudRequest(
    (serverRoutes.surveys.getSurveyParticipationChart as ICreateServerRoute)(surveyId),
    {},
    {},
  );
}

export function getSurveyResultsThemesChart(surveyId: string) {
  return cloudRequest(
    (serverRoutes.surveys.getSurveyResultsThemesChart as ICreateServerRoute)(surveyId),
    {},
    {},
  );
}

export function getSurveyResultsThemes(
  surveyId: string,
  { teams, skip, limit, search }: { teams?: string[]; skip: number; limit: number; search: string },
) {
  return cloudRequest(
    (serverRoutes.surveys.getSurveyResultsThemes as ICreateServerRoute)(surveyId),
    {},
    { filters: { teams, search }, options: { skip, limit } },
  );
}

export function getSurveyResultsThemesCSV(
  surveyId: string,
  { teams, search }: { teams?: string[]; search: string },
) {
  return cloudRequest(
    (serverRoutes.surveys.getSurveyResultsThemesCSV as ICreateServerRoute)(surveyId),
    {},
    { filters: { teams, search } },
  ).then((response) => {
    FileSaver.saveAs(response, 'surveyResultsThemes.csv');
  });
}

export function getSurveyResultsQuestions(
  surveyId: string,
  filters: {
    event: string;
    search?: string;
    themes?: string[];
    teams?: string[];
  },
  options?: { skip?: number; limit?: number; sortBy?: SURVEY_SORT_OPTIONS },
) {
  const body = {
    filters: {
      event: filters.event,
      search: filters?.search,
      themes: filters?.themes,
      teams: filters?.teams,
    },
    options: {
      skip: options?.skip,
      limit: options?.limit,
      sortBy: options?.sortBy,
    },
  };

  return cloudRequest(
    (serverRoutes.surveys.getSurveyResultsQuestions as ICreateServerRoute)(surveyId),
    {},
    body,
  );
}

export function downloadSurveyResultsQuestionsCSV(
  surveyId: string,
  filters: {
    event: string;
    search?: string;
    themes?: string[];
    teams?: string[];
  },
  options?: { skip?: number; limit?: number; sortBy?: SURVEY_SORT_OPTIONS },
) {
  const body = {
    filters: {
      event: filters.event,
      search: filters?.search,
      themes: filters?.themes,
      teams: filters?.teams,
    },
    options: {
      skip: options?.skip,
      limit: options?.limit,
      sortBy: options?.sortBy,
    },
  };

  return cloudRequest(
    (serverRoutes.surveys.downloadSurveyResultsQuestionsCSV as ICreateServerRoute)(surveyId),
    {},
    body,
  ).then((response) => {
    FileSaver.saveAs(response, 'surveyQuestions.csv');
  });
}

export function updateSurveyIsShared(surveyId: string, body: { isShared: ISurvey['isShared'] }) {
  return cloudRequest(
    (serverRoutes.surveys.shareResults as IUpdateServerRoute)(surveyId),
    {},
    body,
  );
}

export function archiveSurvey(surveyId: string) {
  return cloudRequest((serverRoutes.surveys.archiveSurvey as ICreateServerRoute)(surveyId), {});
}

export function unarchiveSurvey(
  surveyId: string,
  body: { endDate: Date | null; isEndDate: boolean },
) {
  return cloudRequest(
    (serverRoutes.surveys.unarchiveSurvey as ICreateServerRoute)(surveyId),
    {},
    body,
  );
}
