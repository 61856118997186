import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import styled from 'styled-components';

import DatePicker from '~/components/DatePickerDeprecated';
import Editor from '~/components/Editor';
import CheckedUrlIcon from '~/components/Icons/CheckedUrlIcon';
import ErrorUrlIcon from '~/components/Icons/ErrorUrlIcon';
import ImageUploadCover from '~/components/ImageUploadCover';
import SelectDropDown from '~/components/SelectDropDown';
import SvgIcon from '~/components/SvgIcon';
import { TextField, FieldTitle, FieldGroup } from '~/components/Text';

import closeIcon from '~/assets/close-icn.svg';

import { ACTIVITY_TYPES } from '~/constants';
import { COLOR_SET, COLORS, COLOR_PALETTE } from '~/styles';

const AddButton = styled.button`
  position: relative;
  background: none;
  border: 1px solid var(--company-color);
  height: 48px;
  width: 100%;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  text-align: center;
  color: var(--company-color);
  align-items: center;
  input[type='file'] {
    width: 100%;
    height: 40px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
  &:hover {
    background-color: var(--company-color);
    color: ${COLOR_PALETTE.WHITE};
    border-color: var(--company-color);
  }
`;

const CloseIconButton = styled.div`
  margin-left: 13px;
  cursor: pointer;
`;

const Column = styled.div`
  width: ${(props) => props.$width || '100%'};
  margin-right: ${(props) => props.$marginRight || 0};
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
`;

const FileContainer = styled.div`
  display: flex;
  justify-content: ${(props) => !props.noSpace && 'space-between'};
  box-sizing: border-box;
  height: 48px;
  align-items: center;
  border-radius: 6px;
  margin-bottom: 2px;
  padding: 0 9px;
  margin-bottom: 8px;
  background-color: #f9fafc;
`;

const FileName = styled.h6`
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #333333;
  cursor: pointer;
`;

const InputBlock = styled.div`
  display: flex;
  margin-bottom: 25px;
  justify-content: space-between;
`;

const InputBlockCol = styled.div`
  flex: 1;
  max-width: 277px;
`;

const LinkBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${(props) => (props.error ? COLORS.ERROR : COLORS.BORDER)} !important;
  border-radius: 6px;
  padding: 12px 9px;
  height: 48px;
  box-sizing: border-box;
`;

const Row = styled.div`
  display: flex;
`;

const StyledFieldTitle = styled(FieldTitle)`
  margin-bottom: 8px;
`;

const StyledTextField = styled.input.attrs({
  autocomplete: 'off',
})`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 422px;
  max-width: 422px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  color: ${COLORS.TEXT_BLACK};
  border: none;
  background-color: transparent;
  outline: none;
  height: 48px;
  &::placeholder {
    color: ${COLOR_SET.MIDDLE_GRAY_BLUE};
  }
`;

const options = Object.values(ACTIVITY_TYPES).map((t) => ({
  key: t.key,
  title: t.translated,
}));

const ActivityMainInfoEdit = ({
  activity,
  handleChangeInput,
  isValidLink,
  onCoverUpload,
  handleChangeDescription,
  handleChangeDeadline = () => {},
  downloadFile,
  removeAttachment,
  onUploadChange,
  fileUploading,
  onChangeType,
  isCustom,
}) => {
  const { i18n } = useLingui();
  let input;

  const onUploadFile = () => {
    let file = input.files[0];
    onUploadChange(file);
  };

  return (
    <ContentWrap>
      <FieldGroup marginBottom="33px">
        <StyledFieldTitle>
          <Trans>Name*</Trans>
        </StyledFieldTitle>
        <TextField
          type="text"
          name="name"
          textSize="14px"
          value={activity.name}
          placeholder={i18n._(t`Example: Learn about everything`)}
          onChange={handleChangeInput}
          placeholderColor={COLOR_SET.MIDDLE_GRAY_BLUE}
        />
      </FieldGroup>
      {!isCustom && (
        <FieldGroup marginBottom="33px">
          <Row>
            <Column $width="188px" $marginRight="32px">
              <StyledFieldTitle>
                <Trans>Content type*</Trans>
              </StyledFieldTitle>
              <SelectDropDown
                options={options}
                value={activity.type || null}
                onChange={onChangeType}
                keyName="key"
                borderColor="#dae4f7"
                listMarginTop="8px"
                labelStyle={{
                  fontSize: '14px',
                  fontWeight: 'normal',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: '1.86',
                  letterSpacing: '-0.16px',
                  color: '#6d7aa6',
                }}
                optionStyles={{
                  fontSize: '12px',
                  fontWeight: '600',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  color: '#6b78a4',
                  borderBottom: 'solid 1px #dae4f7',
                  height: '32px',
                }}
                label={i18n._(t`Select type`)}
                renderLabel={(item) => <div>{item.title(i18n)}</div>}
                width="188px"
                listHeight="226px"
              />
            </Column>
            <Column>
              <StyledFieldTitle>
                <Trans>Link to</Trans>
                {[
                  ACTIVITY_TYPES.VIDEO.key,
                  ACTIVITY_TYPES.ARTICLE.key,
                  ACTIVITY_TYPES.PODCAST.key,
                  ACTIVITY_TYPES.WEBINAR.key,
                ].includes(activity.type)
                  ? '*'
                  : ''}
              </StyledFieldTitle>
              <LinkBlock>
                <StyledTextField
                  type="text"
                  name="link"
                  value={activity.link}
                  placeholder={
                    activity.type === ACTIVITY_TYPES.VIDEO.key
                      ? i18n._(t`Example: www.youtube.com/example`)
                      : i18n._(t`Example: www.news.com/example`)
                  }
                  onChange={handleChangeInput}
                  className={isValidLink ? '' : ' modal_input_error'}
                />
                {activity.type &&
                  !isEmpty(activity.link) &&
                  (isValidLink ? <CheckedUrlIcon size={21} /> : <ErrorUrlIcon size={21} />)}
              </LinkBlock>
            </Column>
          </Row>
        </FieldGroup>
      )}
      {activity.type !== ACTIVITY_TYPES.VIDEO.key && !isCustom && (
        <FieldGroup marginBottom="28px">
          <StyledFieldTitle>
            <Trans>Image</Trans>
          </StyledFieldTitle>
          <ImageUploadCover
            height="184px"
            imageUrl={activity.coverUrl || ''}
            placeholder={i18n._(t`Upload image or use URL thumpnail as cover image`)}
            onUpload={onCoverUpload}
          />
        </FieldGroup>
      )}
      <FieldGroup marginBottom="33px">
        <StyledFieldTitle>
          <Trans>Description</Trans>
        </StyledFieldTitle>
        <Editor
          onChange={handleChangeDescription}
          value={activity.description || ''}
          placeholder={i18n._(t`What will people learn during this activity?`)}
          className="activity_description"
          minHeight={isCustom && '300px'}
          maxHeight={isCustom && '300px'}
        />
      </FieldGroup>
      {/* TODO check this rule */}
      {activity.origin === 'user' && (
        <InputBlock>
          <InputBlockCol>
            <StyledFieldTitle>
              <Trans>Deadline</Trans>
            </StyledFieldTitle>
            <DatePicker
              selected={activity.deadline ? moment(activity.deadline) : ''}
              width={277}
              onChange={handleChangeDeadline}
            />
          </InputBlockCol>
        </InputBlock>
      )}
      <FieldGroup marginBottom="33px">
        <StyledFieldTitle>
          <Trans>Attachments</Trans>
        </StyledFieldTitle>
        {activity.files &&
          activity.files.map((file) => {
            return (
              <FileContainer key={file.id}>
                <FileName onClick={downloadFile(file)}>{file.name}</FileName>
                <CloseIconButton onClick={() => removeAttachment(file.id)}>
                  <SvgIcon width="16px" height="16px" url={closeIcon} alt={i18n._(t`delete`)} />
                </CloseIconButton>
              </FileContainer>
            );
          })}
        <AddButton>
          {!fileUploading ? <Trans>+ Attachment</Trans> : <span className="loader" />}
          <input
            id="uploadNewFile"
            ref={(ref) => {
              input = ref;
            }}
            onChange={onUploadFile}
            type="file"
          />
        </AddButton>
      </FieldGroup>
    </ContentWrap>
  );
};

export { ActivityMainInfoEdit };
