import React from 'react';

import PropTypes from 'prop-types';
import SpinnerPlugin from 'react-spinkit';
import styled from 'styled-components';

import { Loader } from '~/components/Buttons/components/Loader';

const SpinnerHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.$height};
  width: 100%;
`;

const Spinner = ({ type, styles, height }) => {
  switch (type) {
    case 'button-primary': {
      return <SpinnerPlugin name="three-bounce" fadeIn="none" color="var(--company-color)" />;
    }
    default: {
      return (
        <SpinnerHolder style={styles} $height={height}>
          <Loader />
        </SpinnerHolder>
      );
    }
  }
};

Spinner.propTypes = {
  type: PropTypes.oneOf(['button-primary', 'action', 'square']),
  styles: PropTypes.object,
  height: PropTypes.string,
};

Spinner.defaultProps = {
  loading: false,
  styles: {},
  height: '200px',
};

export default React.memo(Spinner);
