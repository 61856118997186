import React from 'react';

import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import SectionItem from './SectionItem';

import { COLOR_PALETTE, COLORS } from '~/styles';

const SectionWrapper = styled.div`
  border-radius: 6px;
  border: solid 1px ${COLORS.BG_PAGE};
  background-color: ${COLOR_PALETTE.WHITE};
  margin-bottom: 32px;
`;

function PathSection({
  path,
  section,
  onUpdate,
  onDelete,
  onAddActivity,
  onDeleteActivity,
  onClickActivity,
  onCreateCustomActivity,
  changeActivityObligation,
  showErrors,
}) {
  return (
    <Draggable draggableId={String(section.id)} index={section.order}>
      {(provided, snapshot) => (
        <SectionWrapper ref={provided.innerRef} {...provided.draggableProps}>
          <SectionItem
            path={path}
            section={section}
            isDragging={snapshot.isDragging}
            showErrors={showErrors}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onAddActivity={onAddActivity}
            onDeleteActivity={onDeleteActivity}
            onClickActivity={onClickActivity}
            onCreateCustomActivity={onCreateCustomActivity}
            changeActivityObligation={changeActivityObligation}
            {...provided.dragHandleProps}
          />
        </SectionWrapper>
      )}
    </Draggable>
  );
}

PathSection.propTypes = {
  section: PropTypes.object.isRequired,
  path: PropTypes.object.isRequired,
};

export default React.memo(PathSection);
