import React, { useState, useCallback, useRef, useEffect } from 'react';

import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import StepStatus from '~/components/StepStatus';
import SetupLayout from '~/layouts/SetupLayout';

import SetupCard from './components/SetupCard';

import useBoolState from '~/hooks/useBoolState';
import { getSelectedRole } from '~/selectors/baseGetters';
import debounceValue from '~/utils/debounceValue';

function validate(item, step) {
  const required = step.requiredProps || [];

  if (required.length === 0) {
    return true;
  }

  return required.every((prop) => {
    if (typeof prop === 'function') {
      return prop(item);
    }
    return !isEmpty(item[prop]);
  });
}

function getStatus(item, step, isCurrent, visited, errors = false) {
  if (visited.indexOf(step) === -1) {
    if (isCurrent) {
      if (validate(item, step)) {
        return StepStatus.statuses.done;
      }
      return StepStatus.statuses.active;
    }
    return StepStatus.statuses.todo;
  }

  if (validate(item, step)) {
    return StepStatus.statuses.done;
  }

  if (errors) {
    return StepStatus.statuses.error;
  }

  return StepStatus.statuses.warn;
}

const SetupGlobalState = {
  lastVisited: null,
  visited: [],
  _ident: null,
  checkIndent(ident, initial) {
    if (this._ident !== ident || !ident) {
      this.lastVisited = initial;
      this.visited = [initial];
      this._ident = ident;
    }
  },
  record(step) {
    this.lastVisited = step;
    if (this.visited.indexOf(step) === -1) {
      this.visited = this.visited.concat(step);
    }
  },
};

SetupGlobalState.checkIndent.bind(SetupGlobalState);
SetupGlobalState.record.bind(SetupGlobalState);

const scrollToRef = (ref, conpens, isBack) => {
  const offset = conpens
    ? ref.offsetTop - conpens.clientHeight + (isBack ? -67 : 67)
    : ref.offsetTop;
  // EI and EDGE do not support scrollTo(0, offset)
  document.getElementById('main-content').style.scrollBehavior = 'smooth';
  document.getElementById('main-content').scrollLeft = 0;
  document.getElementById('main-content').scrollTop = offset;
  document.getElementById('main-content').style.scrollBehavior = '';
};

const isLocked = new debounceValue(false);

const SetupFlow = ({
  className,
  onClose,
  headerActions,
  bottomActions,
  steps,
  i18n,
  onSubmit,
  item,
  componentsProps,
  type,
  title,
  noLastAction,
  loading,
  session,
  hideHeader,
  isHidePublishButton,
  contentHeader,
  setupLayoutWidth = '1070px',
  noTopMargin = false,
  headerInstructions,
  headerInstructionsUrl,
  pageTitle,
  pageDescription,
}) => {
  const currentRole = useSelector(getSelectedRole);
  const itemsRef = useRef([]);
  // check identification of started flow, in SetupGlobalState recorded visited and last step of
  // current setup session
  SetupGlobalState.checkIndent(session, steps[0]);
  // you can access the elements with itemsRef.current[n]
  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, steps.length);
  }, [steps]);

  let initialStep = steps[0];
  if (SetupGlobalState.lastVisited) {
    initialStep = SetupGlobalState.lastVisited;
  }
  const [currentStep, setStep] = useState(initialStep);
  const changeStep = useCallback(
    (step) => {
      setStep(step);
      SetupGlobalState.record(step);
    },
    [setStep],
  );
  const [visited, setVisited] = useState(
    SetupGlobalState.visited.length > 0 ? SetupGlobalState.visited : [],
  );
  const stepNumber = steps.findIndex((i) => i.key === currentStep.key);
  const errorStatus = useBoolState();
  const toTheNextStep = useCallback(() => {
    const next = steps[stepNumber + 1];
    scrollToRef(itemsRef.current[stepNumber + 1], itemsRef.current[stepNumber], false);
    changeStep(next);
    if (visited.indexOf(next) === -1) {
      setVisited(visited.concat(currentStep));
    }
  }, [changeStep, stepNumber, steps, visited, currentStep]);

  const toTheBackStep = useCallback(() => {
    const next = steps[stepNumber - 1];
    scrollToRef(itemsRef.current[stepNumber], itemsRef.current[stepNumber - 1], true);
    changeStep(next);
    if (visited.indexOf(next) === -1) {
      setVisited(visited.concat(currentStep));
    }
  }, [changeStep, stepNumber, steps, visited, currentStep]);

  const handleEdit = useCallback(
    (toStep) => {
      const nextStepNumber = toStep - 1;
      changeStep(steps[nextStepNumber]);
      if (visited.indexOf(stepNumber) === -1) {
        setVisited(visited.concat(currentStep));
      }
    },
    [changeStep, visited, setVisited, steps, stepNumber, currentStep],
  );

  let statuses = steps.map((s) =>
    getStatus(item, s, s === currentStep, item.id ? steps : visited, errorStatus.value),
  );
  if (type === 'give-feedback' || type === 'goal') {
    statuses = steps.map((s) => getStatus(item, s, s === currentStep, steps, errorStatus.value));
  }

  const readyToPublish = statuses.every((s) => s === StepStatus.statuses.done);

  const handleSubmit =
    (force) =>
    async (...arg) => {
      if (visited.length !== steps.length) {
        setVisited(steps);
      }
      if (readyToPublish || force) {
        if (!isLocked.value) {
          isLocked.set(true);
          await onSubmit(...arg);
        }
      } else {
        let statuses = steps.map((s) =>
          getStatus(item, s, s === currentStep, steps, errorStatus.value),
        );
        const index = statuses.indexOf(StepStatus.statuses.error);
        if (index !== -1) {
          changeStep(steps[index]);
          scrollToRef(itemsRef.current[index]);
        } else {
          const index = statuses.indexOf(StepStatus.statuses.warn);
          if (index !== -1) {
            changeStep(steps[index]);
            scrollToRef(itemsRef.current[index]);
            errorStatus.on();
          }
        }
      }
    };
  const forceSubmit = handleSubmit(true);
  const regularSubmit = handleSubmit(false);

  return (
    <SetupLayout
      className={className}
      noTopMargin={noTopMargin}
      width={setupLayoutWidth}
      hideHeader={hideHeader}
      title={title}
      onClose={onClose}
      headerActions={headerActions}
      actions={
        typeof headerActions === 'function'
          ? headerActions(regularSubmit, forceSubmit)
          : headerActions
      }
      headerInstructions={headerInstructions}
      headerInstructionsUrl={headerInstructionsUrl}
      pageTitle={pageTitle}
      pageDescription={pageDescription}
    >
      {contentHeader}
      {steps.map((step, index) => {
        const last = steps.length === index + 1;
        const nextAction = last ? () => regularSubmit() : toTheNextStep;
        return (
          <div key={step.key} ref={(el) => (itemsRef.current[index] = el)}>
            <SetupCard
              title={step.title(i18n, { currentRole })}
              subTitle={step.subTitle && step.subTitle(i18n)}
              stepNumber={index + 1}
              stepCount={steps.length}
              active={currentStep.key === step.key}
              hideFooter={last && noLastAction}
              onNextAction={nextAction}
              onEdit={handleEdit}
              status={statuses[index]}
              nextDisabled={step.nextDisabled}
              nextLabel={step.nextLabel && step.nextLabel(i18n)}
              nextLabelTooltip={step.nextLabelTooltip && step.nextLabelTooltip(i18n)}
              submitLabel={step.submitLabel && step.submitLabel(i18n, item)}
              onBackAction={step.isBackStepButton && (step.onBackAction || toTheBackStep)}
              backLabel={step.backLabel && step.backLabel(i18n)}
              backLabelTooltip={step.backLabelTooltip && step.backLabelTooltip(i18n)}
              loading={loading}
              isHidePublishButton={isHidePublishButton}
              lowerSubtitle={step.lowerSubtitle}
            >
              {
                <step.component
                  showErrors={errorStatus.value}
                  item={item}
                  {...{ ...componentsProps, ...step.componentProps }}
                />
              }
            </SetupCard>
          </div>
        );
      })}
      {bottomActions && (
        <div>
          {typeof bottomActions === 'function'
            ? bottomActions(regularSubmit, forceSubmit)
            : bottomActions}
        </div>
      )}
    </SetupLayout>
  );
};

SetupFlow.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  componentsProps: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.elementType])
        .isRequired,
      requiredProps: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])),
      title: PropTypes.func,
    }),
  ),
  headerActions: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  bottomActions: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  noLastAction: PropTypes.bool,
  className: PropTypes.string,
  hideHeader: PropTypes.bool,
  isHidePublishButton: PropTypes.bool,
  contentHeader: PropTypes.object,
  headerInstructions: PropTypes.string,
  headerInstructionsUrl: PropTypes.string,
  pageTitle: PropTypes.string,
  pageDescription: PropTypes.string,
};

SetupFlow.defaultProps = {
  noLastAction: false,
  hideHeader: false,
  className: '',
  componentsProps: {},
};

export default withI18n()(SetupFlow);
