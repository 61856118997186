export enum SKILL_CATEGORY_TYPE {
  SOFT = 'soft',
  HARD = 'hard',
}

export enum CATEGORY_SKILL_RATING_KEYS {
  AVG_EXPECTED_LEVEL = 'avgExpectedLevel',
  AVG_SELF_RATING = 'avgSelfRating',
  AVG_PEERS_RATING = 'avgPeersRating',
  AVG_COACHES_RATING = 'avgCoachesRating',
}

export enum SKILL_SORTING {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
  UPDATED_NEW_OLD = 'UPDATED_NEW_OLD',
  UPDATED_OLD_NEW = 'UPDATED_OLD_NEW',
  CATEGORY_A_Z = 'CATEGORY_A_Z',
  CATEGORY_Z_A = 'CATEGORY_Z_A',
}
