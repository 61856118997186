import React from 'react';

import { CustomOption } from '~/pages/SkillSetsOverview/SkillCreateModal/design';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import type { PopulatedSkillTemplate } from './types';

function CustomOptionComponent({
  item,
  handleNewItemSelected,
}: {
  item: PopulatedSkillTemplate;
  handleNewItemSelected: (item: PopulatedSkillTemplate) => void;
}) {
  const getMultiLangString = useMultiLangString();

  return (
    <CustomOption onClick={() => handleNewItemSelected(item)}>
      <span>{getMultiLangString(item.name)}</span>
      {item.skillCategory && <span>{getMultiLangString(item.skillCategory.name)}</span>}
    </CustomOption>
  );
}

export { CustomOptionComponent };
