import React, { ReactNode } from 'react';

import { SummaryFieldLabel, SummaryFieldValue, SummaryFieldWrapper } from '../design';

interface SummaryFieldProps {
  label: string;
  value: ReactNode;
  marginTop?: string;
  marginBottom?: string;
}

const SummaryField = ({ label, value, marginBottom, marginTop }: SummaryFieldProps) => {
  return (
    <SummaryFieldWrapper marginTop={marginTop} marginBottom={marginBottom}>
      <SummaryFieldLabel>{label}</SummaryFieldLabel>
      <SummaryFieldValue>{value}</SummaryFieldValue>
    </SummaryFieldWrapper>
  );
};

export { SummaryField };
