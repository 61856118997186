import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Editor from '~/components/Editor';
import RatingRow from '~/components/GiveFeedback/components/Section/components/RatingRow';
import RatingStatusRow from '~/components/GiveFeedback/components/Section/components/RatingStatusRow';

import { REVIEW_QUESTIONS_TABS } from '~/constants';
import { getCurrentReview } from '~/selectors/baseGetters';

const StyledEditor = styled(Editor)`
  .ql-container {
    max-height: 550px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }
`;

const BodyContainer = styled.div`
  padding: 20px;
`;

function Body({
  review,
  question,
  rating,
  typeRatings,
  onChange,
  forUser,
  isReadOnly,
  currentTab,
}) {
  const { i18n } = useLingui();
  const reviewStore = useSelector(getCurrentReview);
  review = review ?? reviewStore;

  const onChangeSkipped = (_e, isSkipped) => {
    const updateData = { isSkipped, ...(isSkipped && { rate: 0 }) };
    return onChange(updateData);
  };

  return (
    <>
      {currentTab === REVIEW_QUESTIONS_TABS.OWN_ANSWER.key ? (
        <BodyContainer>
          {!question.hideRating && (
            <RatingRow
              rate={rating.rate}
              scale={rating.scale}
              scaleLabels={rating.scaleLabels}
              onChange={(rate) => onChange({ rate })}
              isSkipped={rating.isSkipped}
              isSkippable={question.isSkippable}
              isSingleHover
              changeSkipped={onChangeSkipped}
              disabled={isReadOnly}
            />
          )}
          <StyledEditor
            value={rating.explanation || ''}
            placeholder={`${i18n._(t`Explain your answer`)}${rating.isCommentObligated ? '*' : ''}`}
            onChange={(explanation) => onChange({ explanation })}
            compact
            small
            readOnly={isReadOnly}
          />
        </BodyContainer>
      ) : (
        <RatingStatusRow
          ratings={typeRatings}
          requests={review.requests}
          currentTab={currentTab}
          forUser={forUser}
        />
      )}
    </>
  );
}

export { Body };
