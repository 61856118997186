import React from 'react';

import { StickyContainer } from 'react-sticky';
import styled from 'styled-components';

import Footer from '~/components/Footer';
import SideMenu from '~/components/SideMenu/SideMenu';
import { TrialBanner } from '~/components/TrialBanner';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const Menu = styled.div`
  height: 100%;
  background: ${COLORS.WHITE};
  display: flex;
`;

const Main = styled(StickyContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  background: ${COLORS.BG_PAGE};
`;

const PageContent = styled.div`
  flex: 1;
`;

const MainLayout = ({ children }) => (
  <Wrapper>
    <Menu>
      <SideMenu />
    </Menu>
    <Main id="main-content">
      <TrialBanner />
      <PageContent>{children}</PageContent>
      <Footer />
    </Main>
  </Wrapper>
);

export default React.memo(MainLayout);
