import React from 'react';

import { ISurveyEvent } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { format } from 'date-fns';
import last from 'lodash/last';
import styled from 'styled-components';

import SelectDropDown from '~/components/SelectDropDown';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  margin-bottom: 26px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.BLACK};
  margin-left: 32px;
`;

interface IEventSelectorProps {
  items: { id: string }[];
  selectedItem?: string;
  onChange: (value: ISurveyEvent['id']) => void;
}

const EventsSelector = ({ items, selectedItem, onChange }: IEventSelectorProps) => {
  const { i18n } = useLingui();

  return (
    <Wrapper>
      <Label>{i18n._(t`Pulse`)}</Label>
      <SelectDropDown
        options={items}
        value={selectedItem}
        onChange={onChange}
        keyName="id"
        renderLabel={(item: ISurveyEvent) => {
          const startDate = format(new Date(item?.startDate), 'dd-MM-yyyy');
          const endDate = format(new Date(item?.endDate), 'dd-MM-yyyy');
          const isLatest = item.id === last(items)?.id;
          return <div>{`${startDate} - ${endDate}${isLatest ? ' (latest)' : ''}`}</div>;
        }}
        width="324px"
        isSteps
      />
    </Wrapper>
  );
};

export { EventsSelector };
