import styled from 'styled-components';

import { COLORS } from '~/styles';

export const SectionWrapper = styled.div`
  margin: 24px 0;
`;

export const Title = styled.div`
  margin-top: 20px;
  font-size: 24px;
  color: ${COLORS.TEXT_HOVER};
`;

export const SubTitle = styled.div`
  margin-top: 6px;
  font-size: 14px;
  color: ${COLORS.SUBTEXT};
  margin-bottom: 10px;
`;

export const Row = styled.div`
  display: flex;
`;

export const Label = styled.div`
  font-size: 12px;
  color: ${COLORS.TEXT_HOVER};
`;

export const ButtonMargin = styled.div`
  margin-right: 20px;
`;
