import React, { useState, useEffect, FunctionComponent } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import Button from '~/components/Button';
import Editor from '~/components/Editor';
import Modal from '~/components/Modal';
import { FieldGroup, FieldTitle, TextField } from '~/components/Text';

const HeaderActions = styled.div`
  display: flex;
`;

interface ICreateUpdateSectionModal {
  item: any;
  onCreate: ({ name, description }: { name: string; description: string }) => void;
  onUpdate: (item: any) => void;
  onClose: () => void;
  title: string;
  namePlaceholder: string;
  descriptionPlaceholder: string;
}

const CreateUpdateSectionModal: FunctionComponent<ICreateUpdateSectionModal> = ({
  item,
  onCreate,
  onUpdate,
  onClose,
  title,
  namePlaceholder,
  descriptionPlaceholder,
}) => {
  const { i18n } = useLingui();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const isCreate = isEmpty(item);

  useEffect(() => {
    if (item) {
      if (item.name) {
        setName(item.name);
      }

      if (item.description) {
        setDescription(item.description);
      }
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = () => {
    isCreate ? onCreate({ name, description }) : onUpdate({ ...item, name, description });
    onClose();
  };

  return (
    <Modal
      className="new-team-modal"
      title={title}
      onClose={onClose}
      width={625}
      minWidth={625}
      contentStyles={{ background: '#fff', height: '100%', padding: '16px 16px 0px' }}
      headerStyles={{ padding: '0 16px', borderBottom: 'solid 0.5px #ecedf0' }}
      footerRight={
        <HeaderActions>
          <Button
            label={isCreate ? i18n._(t`Create`) : i18n._(t`Update`)}
            disabled={!name}
            type="primary-border"
            onClick={onSubmit}
          />
        </HeaderActions>
      }
    >
      <FieldGroup>
        <FieldTitle>
          <Trans>Name</Trans>*
        </FieldTitle>
        <TextField
          value={name}
          // @ts-ignore
          textSize="14px"
          onChange={(e) => setName(e.target.value)}
          placeholder={namePlaceholder || i18n._(t`Enter name`)}
        />
      </FieldGroup>
      <FieldGroup>
        <FieldTitle>
          <Trans>Description</Trans>
        </FieldTitle>
        <Editor
          placeholder={descriptionPlaceholder || i18n._(t`Enter description`)}
          value={description}
          onChange={(value: string) => setDescription(value)}
          compact={true}
        />
      </FieldGroup>
    </Modal>
  );
};

export { CreateUpdateSectionModal };
