import React, { ReactNode } from 'react';

import styled from 'styled-components';

import { COLORS, COLOR_PALETTE } from '~/styles';

const Card = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
  padding: 28px 32px;
  border-radius: 6px;
  border: solid 1px ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  background-color: ${COLOR_PALETTE.WHITE};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  width: 46px;
  height: 46px;
  margin-right: 14px;
  padding: 8px;
  border-radius: 6px;
  background-image: linear-gradient(to bottom, #f7f9ff, ${COLORS.BG_PAGE});
`;

const TitleWrapper = styled.div`
  font-size: 12px;
  letter-spacing: -0.13px;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin-bottom: 6px;
`;

const ContentWrapper = styled.div`
  font-size: 27px;
  font-weight: 600;
  letter-spacing: -0.3px;
  color: ${COLOR_PALETTE.BLACK};
`;

interface IStatisticCardProps {
  title: ReactNode;
  icon: ReactNode;
  children: ReactNode;
}

function StatisticCard({ title, icon, children }: IStatisticCardProps) {
  return (
    <Card>
      <IconWrapper>{icon}</IconWrapper>
      <div>
        <TitleWrapper>{title}</TitleWrapper>
        <ContentWrapper>{children}</ContentWrapper>
      </div>
    </Card>
  );
}

export { StatisticCard };
