import React from 'react';

import { UserAvatar } from '~/components/UserAvatar';

import { TaskStatus } from './TaskStatus';

import { useOnHover } from '~/hooks/useOnHover';

import { ListRow, RowRightBlock, RowLeftBlock } from '../design';

import type { IUser, ITask } from '@learned/types';

interface ListRowTaskProps {
  task: ITask;
  onDelete?: (task: ITask) => void;
}

const ListRowTask = ({ task, onDelete }: ListRowTaskProps) => {
  const { ref, isHovering } = useOnHover();

  return (
    <ListRow ref={ref} $isHovering={onDelete && isHovering}>
      <RowRightBlock>
        {task.userTo.email ? (
          <UserAvatar
            user={{ email: task.userTo.email } as IUser}
            options={{ showTooltip: true }}
          />
        ) : (
          // @ts-ignore
          <UserAvatar userId={task.userTo.id} options={{ showTooltip: true }} />
        )}
      </RowRightBlock>
      <RowLeftBlock>
        <TaskStatus
          task={task}
          onDelete={isHovering && onDelete ? () => onDelete(task) : undefined}
        />
      </RowLeftBlock>
    </ListRow>
  );
};

export { ListRowTask };
