import type { IJobFamily } from '@learned/types';

export interface IJobFamiliesModalProps {
  jobFamily?: IJobFamily;
  closeModal: () => void;
  refetchData: () => void;
}

export type IUseJobFamiliesModalProps = IJobFamiliesModalProps;

export enum Locals {
  en_GB = 'en_GB',
  nl_NL = 'nl_NL',
  de_DE = 'de_DE',
}

export interface IJobFamilyForm {
  name: {
    en_GB: Locals;
    nl_NL: Locals;
    de_DE: Locals;
  };
}
