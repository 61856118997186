import React, { Component } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import FeedbackIcon from '~/components/Icons/Feedback';
import Placeholder from '~/components/Placeholder';
import RatingScale from '~/components/RatingScale';
import RickTextView from '~/components/RickTextView';
import StatusIcon from '~/components/StatusIcon';
import AvatarCard from '~/components/UI/AvatarCard';

import { REQUEST_STATUSES } from '~/constants';
import { COLORS } from '~/styles';

const FbOtherCards = styled.div`
  width: 100%;
`;

const RequestWrapper = styled.div`
  box-sizing: border-box;
  border-radius: 6px;
  border: solid 1px #e4e8ef;
  margin-bottom: 32px;
`;

const RequestHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.showRating && 'border-bottom: solid 1px #e4e8ef;'}
  padding: 16px;
`;

const RatingScaleWrapper = styled.div`
  margin: 16px 0 32px;
`;

const RequestContent = styled.div`
  border-radius: 0 0 6px 6px;
  padding: 16px;
`;

const Rate = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RateHeader = styled.div`
  width: 100%;
`;

const RateComment = styled(RickTextView)`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: #3b3b3b;
  word-break: break-word;
  white-space: pre-wrap;
`;

const RateQuestion = styled.div`
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

const CommentWrapper = styled.div`
  margin-top: 4px;
`;

const RateLabel = styled.div`
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_PRIMARY_2};
  margin-bottom: 8px;
`;

const CommentBlock = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-bottom: 34px;
  }
`;

class FeedbackOthersReport extends Component {
  static propTypes = {
    questionText: PropTypes.string,
  };

  renderRequests = (request) => {
    const { ratings, questionText } = this.props;

    const rating = ratings.find((rating) =>
      request.toEmail ? rating.email === request.toEmail : rating.user === request.toUser,
    );
    const fullySkipped = rating && !rating.rate && !rating.explanation;
    const showRating =
      rating &&
      request.status === REQUEST_STATUSES.SHARED.key &&
      (!!rating.rate || !!rating.explanation);

    return (
      <RequestWrapper key={request.id}>
        <RequestHeader showRating={showRating}>
          <AvatarCard userId={request.toUser} email={request.toEmail} />
          {!showRating && <StatusIcon status={request.status} />}
        </RequestHeader>
        {fullySkipped && (
          <RequestContent>
            <Rate>
              <RateHeader>
                <RateQuestion>
                  <Trans>He/She did not answer this question</Trans>
                </RateQuestion>
              </RateHeader>
            </Rate>
          </RequestContent>
        )}
        {showRating && (
          <RequestContent>
            <Rate>
              <RateHeader>
                {rating.rate > 0 && (
                  <>
                    <RateQuestion>{questionText}</RateQuestion>
                    <RatingScaleWrapper>
                      <RatingScale
                        selectedRate={rating.rate}
                        rateType={rating.rateType}
                        scale={rating.scale}
                        compact
                        readonly
                        labelRight
                        width="144px"
                      />
                    </RatingScaleWrapper>
                  </>
                )}
                {!!rating.explanation && (
                  <CommentWrapper>
                    {/* comment */}
                    <CommentBlock>
                      <RateLabel>
                        <Trans>Comment</Trans>
                      </RateLabel>
                      <RateComment html={rating.explanation} />
                    </CommentBlock>
                  </CommentWrapper>
                )}
              </RateHeader>
            </Rate>
          </RequestContent>
        )}
      </RequestWrapper>
    );
  };

  render() {
    const { i18n, requests, reportType } = this.props;

    return (
      <FbOtherCards>
        {!isEmpty(requests) ? (
          requests.map(this.renderRequests)
        ) : (
          <Placeholder
            title={
              reportType === 'peer' ? i18n._(t`No peer review(s)`) : i18n._(t`No coach review(s)`)
            }
            subTitle={
              reportType === 'peer'
                ? i18n._(t`The completed coach reviews will show here`)
                : i18n._(t`The completed peer reviews will show here`)
            }
            Icon={FeedbackIcon}
          />
        )}
      </FbOtherCards>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(FeedbackOthersReport)));
