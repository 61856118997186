import { serverRoutes } from '~/server_config';

import cloudRequest from '../cloudRequest';

export function getTalkingPoint(id, populate) {
  return cloudRequest(serverRoutes.talkingPoints.getTalkingPoint, { id, populate });
}

export function getTalkingPoints() {
  return cloudRequest(serverRoutes.talkingPoints.getTalkingPoints);
}

export function deleteTalkingPoint(id) {
  return cloudRequest(serverRoutes.talkingPoints.deleteTalkingPoint, { id });
}

export function deleteTalkingPointByTargetAndConversation({ target, conversation }) {
  return cloudRequest(serverRoutes.talkingPoints.deleteTalkingPointByTargetAndConversation, {
    target,
    conversation,
  });
}

// only for user and coach, to add talking point in Published user conversation
// for original talking Points logic inside createConversation API
export function createTalkingPoints(data) {
  return cloudRequest(serverRoutes.talkingPoints.createTalkingPoints, {}, data);
}

// copy TP in published converastion (for participants)
export function copyTalkingPoint(talkingPointId, conversationId) {
  return cloudRequest(serverRoutes.talkingPoints.copyTalkingPoint(talkingPointId, conversationId));
}
