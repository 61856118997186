import React from 'react';

import { Trans } from '@lingui/macro';

import { Button, ButtonVariant } from '~/components/Buttons';
import { Icon, ICONS } from '~/components/Icon';

import { ButtonLabel, FooterContainer } from './Footer.design';

function Footer({
  isLoading,
  onBack,
  onNext,
}: {
  onBack?: () => void;
  onNext?: () => void;
  isLoading: boolean;
}) {
  return (
    <FooterContainer>
      {onBack && (
        <Button
          onClick={onBack}
          isLoading={isLoading}
          variant={ButtonVariant.SECONDARY}
          label={
            <ButtonLabel>
              <Icon icon={ICONS.BACK} transform="rotate(90)" />
              <Trans>Back</Trans>
            </ButtonLabel>
          }
        />
      )}
      {onNext && (
        <Button
          onClick={onNext}
          isLoading={isLoading}
          variant={ButtonVariant.PRIMARY}
          label={
            <ButtonLabel>
              <Trans>Next</Trans>
              <Icon icon={ICONS.BACK} transform="rotate(-90)" />
            </ButtonLabel>
          }
        />
      )}
    </FooterContainer>
  );
}

export { Footer };
