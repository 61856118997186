import { QUESTION_TYPES, RATING_STATUSES, RATING_QUESTION_TYPES } from '@learned/constants';
import find from 'lodash/find';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import range from 'lodash/range';

import { RATE_TYPES, RATING_TYPES } from '~/constants';
import { getReviewQuestionsWithSkills } from '~/utils/helper';

function sortQuestionsByCategories(categories, steps, stepsSorted) {
  let skillQuestionsAdded = false;
  const sortQuestionsWithSkills = () => {
    let ret = [];
    categories &&
      categories.forEach((category) => {
        ret = ret.concat(
          steps.filter((step) => {
            // sort only questions with type skills by categories
            // type jobProfile we sort in jobProfile Question
            if (
              step.skill &&
              step?.question?.type === QUESTION_TYPES.SKILL &&
              ret.find((r) => r.skill && r.skill.id === step.skill.id)
            ) {
              return false;
            }
            return (
              step.skill &&
              step?.question?.type === QUESTION_TYPES.SKILL &&
              step.skill.categories &&
              step.skill.categories.includes(category.id)
            );
          }),
        );
      });
    return ret;
  };
  steps.forEach((step) => {
    if (step.skill && step?.question?.type === QUESTION_TYPES.SKILL && !skillQuestionsAdded) {
      stepsSorted = stepsSorted.concat(sortQuestionsWithSkills());
      skillQuestionsAdded = true;
    } else if (!step.skill || step?.question?.type !== QUESTION_TYPES.SKILL) {
      stepsSorted = stepsSorted.concat([step]);
    }
  });
  return stepsSorted;
}

function getSections(review, ratingType, categories) {
  let steps = [];
  const questions = getReviewQuestionsWithSkills(review);

  questions.forEach((q) => {
    switch (q.type) {
      case QUESTION_TYPES.CUSTOM: {
        steps.push({ question: q });
        break;
      }
      case QUESTION_TYPES.SKILL:
      case QUESTION_TYPES.JOB_PROFILE: {
        // create separate question for each skill
        if (categories) {
          const t = [];
          q.skills.forEach((skill) => {
            t.push({
              question: q,
              skill,
            });
          });
          const qSorted = sortQuestionsByCategories(categories, t, []);
          steps = steps.concat(qSorted);
        } else {
          q.skills.forEach((skill) => {
            steps.push({
              question: q,
              skill,
            });
          });
        }
        break;
      }
      case QUESTION_TYPES.GOAL_BUSINESS_EVAL:
      case QUESTION_TYPES.GOAL_LEARNING_EVAL:
      case QUESTION_TYPES.GOAL_LEARNING_PLAN:
      case QUESTION_TYPES.GOAL_BUSINESS_PLAN: {
        steps.push({ question: q });
        break;
      }
      default:
        break;
    }
  });

  return get(review, 'template.sections', [])
    .map((section, idx) => ({
      ...section,
      questions: orderBy(
        steps.filter(({ question }) => question.sectionIndex === idx),
        ({ question }) => question.order,
      ),
    }))
    .filter((s) => {
      // filter sections per ratingType
      // so section with isSelfReview available only in self-review flow
      switch (ratingType) {
        case RATING_TYPES.SELF_RATING:
          return s.isSelfReview;
        case RATING_TYPES.OTHER_RATING:
        case RATING_TYPES.OUTSIDE_RATING:
          return s.isUsersReview;
        case RATING_TYPES.COACH_RATING:
          return s.isCoachesReview;
        default:
          return true;
      }
    });
}

function validateRating(rating) {
  const isRateFailed = !rating.isHideScale && !rating.isSkipped && !rating.rate;
  // When N/A is selected the comment is not mandatory anymore
  // and the review can be shared without filling in this comment
  const isCommentFailed =
    !rating.isSkipped &&
    ((has(rating, 'isCommentObligated') &&
      rating.isCommentObligated &&
      isEmpty(rating.explanation)) ||
      (!has(rating, 'isCommentObligated') && !rating.explanation));

  return isRateFailed || isCommentFailed;
}

function* findRatings(ratings, question, skill) {
  switch (question.type) {
    case QUESTION_TYPES.CUSTOM:
      yield ratings[question.id] || {};
      break;
    case QUESTION_TYPES.SKILL:
    case QUESTION_TYPES.JOB_PROFILE:
      yield ratings[skill.id] || {};
      break;
    case QUESTION_TYPES.GOAL_BUSINESS_EVAL:
    case QUESTION_TYPES.GOAL_LEARNING_EVAL:
      for (const goal of question.goals) {
        yield ratings[goal.id] || {};
      }
      break;
    case QUESTION_TYPES.GOAL_BUSINESS_PLAN:
    case QUESTION_TYPES.GOAL_LEARNING_PLAN:
      return 0;
    default:
      break;
  }
}

function getErrorsCount(ratings, questions) {
  return questions.reduce((count, { question, skill }) => {
    for (const rating of findRatings(ratings, question, skill)) {
      const isError = rating !== 0 && validateRating(rating);
      count += Number(isError);
    }

    return count;
  }, 0);
}

function getReviewErrorsCount(sections, ratings) {
  return sections.reduce((count, { questions }) => count + getErrorsCount(ratings, questions), 0);
}

function getFirstErrorLocation(sections, ratings, jobProfiles = []) {
  let isError = false;
  const result = {};

  sections.forEach((section, indexSection) => {
    (section.questions || []).forEach(({ question, skill }, indexQuestion) => {
      for (const rating of findRatings(ratings, question, skill)) {
        // first error found
        if (!isError) {
          // rating !== 0 to skip plan goals questions
          if (rating !== 0 && validateRating(rating)) {
            isError = true;
            result.errorSection = indexSection;
            result.errorQuestion =
              question.type === QUESTION_TYPES.JOB_PROFILE
                ? jobProfiles.reduce((jpIndex, jp, currentIndex) => {
                    return (jp.skills || []).includes(skill.id) ? currentIndex : jpIndex;
                  }, 0)
                : indexQuestion;
          }
        }
      }
    });
  });
  return result;
}

function createRatingData({
  review,
  question,
  skill,
  goal,
  ratingType,
  isRealTime,
  user,
  email,
  ratingQuestionType,
}) {
  const sectionType = get(review, `template.sections[${question.sectionIndex}].type`);
  const sectionKpi = get(review, `template.sections[${question.sectionIndex}].kpi`);

  // create rating structure
  const rating = {
    rate: 0,
    rateType: review.isRateWithLevel && skill ? RATE_TYPES.LEVEL : RATE_TYPES.RATE,
    explanation: '',
    [isRealTime ? 'RTFeedback' : 'review']: review.id,
    type: ratingType,
    company: review.company,
    user: ratingType === RATING_TYPES.OUTSIDE_RATING ? '' : user.id,
    email: ratingType === RATING_TYPES.OUTSIDE_RATING ? email : '',
    createdFor: isRealTime ? review.createdBy : review.createdFor,
    status: RATING_STATUSES.TODO,
    isSkipped: false,
    ...(question &&
      Object.prototype.hasOwnProperty.call(question, 'isCommentObligated') && {
        isCommentObligated: question.isCommentObligated,
      }),
    isHideScale: get(question, 'hideRating', false),
    sectionType,
    sectionKpi,
  };

  // add scale only on rating creation
  // add scaleLabels only on rating creation
  const scaleLabels = get(question, 'scaleLabels', []);

  switch (question.type) {
    case QUESTION_TYPES.CUSTOM: {
      rating.question = question.id;
      // scale creates from scaleLabels
      rating.scale = scaleLabels.map((_i, index) => index + 1);
      rating.scaleLabels = scaleLabels;
      break;
    }
    case QUESTION_TYPES.SKILL:
    case QUESTION_TYPES.JOB_PROFILE: {
      const id = skill.id;
      rating.skill = id;
      rating.skillLevel =
        get(review, `skillsLevels[${id}]`) || get(review, `skillsJobProfileLevels[${id}]`);
      if (!review.isRateWithLevel) {
        rating.scale = range(1, 5); // old [1,2,3,4]
        rating.scaleLabels = scaleLabels.slice(0, 4);
      } else {
        // filter scaleLabels depends on enables levels in skill
        rating.scaleLabels = (skill.levelsEnabled || [])
          .map((flag, index) => flag && (scaleLabels[index] || `Level ${index + 1}`))
          .filter((v) => v);
        rating.scale = (skill.levelsEnabled || [])
          .map((state, idx) => (state ? idx + 1 : 0))
          .filter((v) => v);
      }
      break;
    }
    case QUESTION_TYPES.GOAL_BUSINESS_EVAL:
    case QUESTION_TYPES.GOAL_LEARNING_EVAL: {
      if (goal) {
        rating.goal = goal.id;
      }
      rating.questionId = question.id;
      rating.questionType = ratingQuestionType;

      // scale creates from scaleLabels
      rating.scale = scaleLabels.map((_i, index) => index + 1);
      rating.scaleLabels = scaleLabels;
      break;
    }
    case QUESTION_TYPES.GOAL_BUSINESS_PLAN:
    case QUESTION_TYPES.GOAL_LEARNING_PLAN: {
      // we don't need create rating for this type
      return null;
    }
  }

  return rating;
}

function getRatingBySections({ sections, ratingType, user, isRealTime, email, review }) {
  // get Ratings;
  let reviewRatings = [];
  if (!isEmpty(review.ratings)) {
    // filter only other rating rates
    reviewRatings = review.ratings.filter((r) => {
      if (r.type === ratingType && ratingType === RATING_TYPES.OUTSIDE_RATING) {
        return r.email === email;
      }
      return r.type === ratingType && String(r.user) === String(user.id);
    });
  }

  // create reviewRatingsObj
  const reviewRatingsObj = {};

  sections.forEach((section) => {
    section.questions.forEach(({ skill, question }) => {
      const findObjectByKey = (ratingKey, value) => {
        return find(
          reviewRatings,
          (reviewRating) => String(reviewRating[ratingKey]) === String(value),
        );
      };

      const addRatingToReviewRatings = (
        id,
        rating,
        ratingQuestionType = undefined,
        goal = undefined,
      ) => {
        if (!isEmpty(rating)) {
          reviewRatingsObj[id] = rating;
        } else {
          reviewRatingsObj[id] = createRatingData({
            review,
            isRealTime,
            question,
            skill,
            user,
            email,
            ratingType,
            goal,
            ratingQuestionType,
          });
        }
      };

      switch (question.type) {
        case QUESTION_TYPES.SKILL:
        case QUESTION_TYPES.JOB_PROFILE: {
          const rating = findObjectByKey('skill', skill.id);
          addRatingToReviewRatings(skill.id, rating);
          break;
        }
        case QUESTION_TYPES.CUSTOM: {
          const rating = findObjectByKey('question', question.id);
          addRatingToReviewRatings(question.id, rating);
          break;
        }
        case QUESTION_TYPES.GOAL_BUSINESS_EVAL: {
          for (const goal of question.goals) {
            const rating = findObjectByKey('goal', goal.id);
            addRatingToReviewRatings(
              goal.id,
              rating,
              RATING_QUESTION_TYPES.GOAL_BUSINESS_EVAL,
              goal,
            );
          }
          if (question.isAverageQuestionEnabled) {
            const ratingQuestionType = RATING_QUESTION_TYPES.GOAL_BUSINESS_EVAL_AVERAGE;
            const rating = find(
              reviewRatings,
              (reviewRating) =>
                reviewRating.questionId === question.id &&
                reviewRating.questionType === ratingQuestionType,
            );
            addRatingToReviewRatings(`${question.id}-${question.type}`, rating, ratingQuestionType);
          }
          break;
        }
        case QUESTION_TYPES.GOAL_LEARNING_EVAL: {
          for (const goal of question.goals) {
            const rating = findObjectByKey('goal', goal.id);
            addRatingToReviewRatings(
              goal.id,
              rating,
              RATING_QUESTION_TYPES.GOAL_LEARNING_EVAL,
              goal,
            );
          }
          if (question.isAverageQuestionEnabled) {
            const ratingQuestionType = RATING_QUESTION_TYPES.GOAL_LEARNING_EVAL_AVERAGE;
            const rating = find(
              reviewRatings,
              (reviewRating) =>
                reviewRating.questionId === question.id &&
                reviewRating.questionType === ratingQuestionType,
            );
            addRatingToReviewRatings(`${question.id}-${question.type}`, rating, ratingQuestionType);
          }
          break;
        }
        case QUESTION_TYPES.GOAL_BUSINESS_PLAN:
        case QUESTION_TYPES.GOAL_LEARNING_PLAN: {
          // we don't have ratings for this type
          return;
        }
        default:
          break;
      }
    });
  });

  return reviewRatingsObj;
}

function calcFeedbackProgress(sections = [], ratings = []) {
  const questionsAll = sections.reduce((sum, section) => {
    const sectionQuestionCount = section.questions.reduce((innerSum, { question }) => {
      if (
        question.type === QUESTION_TYPES.GOAL_BUSINESS_PLAN ||
        question.type === QUESTION_TYPES.GOAL_LEARNING_PLAN
      ) {
        // Planning doesn't need actions so has no progress
        return innerSum;
      } else if (
        question.type === QUESTION_TYPES.GOAL_BUSINESS_EVAL ||
        question.type === QUESTION_TYPES.GOAL_LEARNING_EVAL
      ) {
        // Eval questions have item to answer per goal and one more if average question is enabled
        return (
          innerSum + (question.goals ?? []).length + (question.isAverageQuestionEnabled ? 1 : 0)
        );
      } else {
        // Normal questions have one item to answer
        return innerSum + 1;
      }
    }, 0);

    return sum + sectionQuestionCount;
  }, 0);

  const questionsDone = ratings.filter((rating) => {
    const isRateDone = rating.rate > 0 || rating.isSkipped || rating.isHideScale;
    const isExplanationDone = rating.isCommentObligated
      ? rating.isSkipped || !!rating.explanation
      : true;
    return isRateDone && isExplanationDone;
  }).length;

  const progress = questionsDone / questionsAll;
  return !questionsAll ? 100 : Math.round(progress * 100);
}

function setRef({ itemsRefs, el, sectionIndex, questionIndex }) {
  // stop logic if itemsRefs is undefined
  if (!itemsRefs) {
    return;
  }

  if (!itemsRefs.current) {
    itemsRefs.current = [];
  }

  if (!itemsRefs.current[sectionIndex]) {
    itemsRefs.current[sectionIndex] = {};
  }

  if (!itemsRefs.current[sectionIndex][questionIndex]) {
    itemsRefs.current[sectionIndex][questionIndex] = el;
  }
}

export {
  validateRating,
  getRatingBySections,
  getSections,
  getErrorsCount,
  getReviewErrorsCount,
  sortQuestionsByCategories,
  calcFeedbackProgress,
  getFirstErrorLocation,
  setRef,
};
