import React from 'react';

import { LangSelect } from './LangSelect';

import { SYSTEM_LANG } from '~/constants/languages';

import type { ILangItem } from './';

const SystemLangSelectComponent = ({
  onClick,
  selectedItem,
  clicked,
}: {
  clicked: boolean;
  onClick: () => void;
  selectedItem?: string;
}) => {
  const langToRender = Object.values(SYSTEM_LANG).find((i) => i.key === selectedItem);
  return (
    <LangSelect
      onClick={onClick}
      // @ts-ignore
      $clicked={clicked}
      langToRender={langToRender as ILangItem}
    />
  );
};

export { SystemLangSelectComponent };
