import { SURVEY_TASK_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';

import type { I18n } from '@lingui/core';

export interface IUserSurveyStatus {
  id: SURVEY_TASK_STATUS;
  key: SURVEY_TASK_STATUS;
  name: string;
  translated: (i18n: I18n) => string;
}

export const USER_SURVEY_STATUS_WITH_NAMES = {
  ACTIVE: {
    id: SURVEY_TASK_STATUS.TODO,
    key: SURVEY_TASK_STATUS.TODO,
    name: 'Active',
    translated: (i18n: I18n) => i18n._(t`Active`),
  },
  CONTINUE: {
    id: SURVEY_TASK_STATUS.DRAFT,
    key: SURVEY_TASK_STATUS.DRAFT,
    name: 'Continue',
    translated: (i18n: I18n) => i18n._(t`Continue`),
  },
  COMPLETED: {
    id: SURVEY_TASK_STATUS.COMPLETED,
    key: SURVEY_TASK_STATUS.COMPLETED,
    name: 'Completed',
    translated: (i18n: I18n) => i18n._(t`Completed`),
  },
  ARCHIVED: {
    id: SURVEY_TASK_STATUS.ARCHIVED,
    key: SURVEY_TASK_STATUS.ARCHIVED,
    name: 'Archived',
    translated: (i18n: I18n) => i18n._(t`Archived`),
  },
};

export const USER_SURVEY_STATUS_BUTTONS = {
  ACTIVE: {
    id: SURVEY_TASK_STATUS.TODO,
    name: 'Active',
    translatedText: (i18n: I18n) => i18n._(t`Open`),
    translatedButton: (i18n: any) => i18n._(t`Start`),
  },
  CONTINUE: {
    id: SURVEY_TASK_STATUS.DRAFT,
    name: 'Continue',
    translatedText: (i18n: I18n) => i18n._(t`Continue`),
    translatedButton: (i18n: any) => i18n._(t`Continue`),
  },
  COMPLETED: {
    id: SURVEY_TASK_STATUS.COMPLETED,
    name: 'Completed',
    translatedText: (i18n: I18n) => i18n._(t`Completed`),
    translatedButton: (i18n: any) => i18n._(t`Completed`),
  },
  ARCHIVED: {
    id: SURVEY_TASK_STATUS.ARCHIVED,
    name: 'Archived',
    translatedText: (i18n: I18n) => i18n._(t`Archived`),
    translatedButton: (i18n: any) => i18n._(t`Archived`),
  },
};
