import React, { useMemo } from 'react';

import { RATING_STATUSES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import RequestRow from '~/components/GiveFeedback/components/Section/components/RequestRow';
import RateRow from '~/components/NewReviewReport/components/RateRow';
import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';

import reviewIcon from '~/assets/main-menu-icons/star.svg';

import { RATING_TYPES, REQUEST_STATUSES, REQUEST_TYPES, REVIEW_QUESTIONS_TABS } from '~/constants';
import { getUser } from '~/selectors/baseGetters';
import { COLOR_PALETTE, COLORS } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';

const RatingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowWrapper = styled.div`
  border-top: 1px solid ${COLORS.BG_PAGE};
  :first-child {
    border-top: none;
  }
`;

const RatingStatusRow = ({ requests = [], ratings = [], currentTab, forUser = {} }) => {
  const { i18n } = useLingui();
  const user = useSelector(getUser);
  const { SELF_REVIEW, PEER_REVIEW, COACH_REVIEW } = REVIEW_QUESTIONS_TABS;

  const sortByStatus = (isInverseOrder) => (a, b) => {
    const result = isInverseOrder ? -1 : 1;
    return a.status < b.status ? result : -result;
  };

  const ratingsFilter = (r) => {
    const { SELF_RATING, OTHER_RATING, OUTSIDE_RATING, COACH_RATING } = RATING_TYPES;

    // Filter not rated ratings, and ratings from the current user
    if (
      (r.status !== RATING_STATUSES.RATED && r.status !== RATING_STATUSES.CALIBRATE) ||
      r.user === user.id
    ) {
      return false;
    }
    if (currentTab === SELF_REVIEW.key) {
      return SELF_RATING === r.type;
    }
    if (currentTab === COACH_REVIEW.key) {
      return COACH_RATING === r.type;
    }
    if (currentTab === PEER_REVIEW.key) {
      return [OTHER_RATING, OUTSIDE_RATING].includes(r.type);
    }
    return false;
  };

  const requestFilter = (r) => {
    const { FEEDBACK_FROM_PEER, FEEDBACK_FROM_COACH, FEEDBACK_FROM_OUTSIDE_PEER } = REQUEST_TYPES;
    const { CANCELLED, SHARED, CALIBRATE } = REQUEST_STATUSES;

    // Filter shared and cancelled requests, and request for the current user
    if ([SHARED.key, CANCELLED.key, CALIBRATE.key].includes(r.status) || r.toUser === user.id) {
      return false;
    }
    if (currentTab === COACH_REVIEW.key) {
      return FEEDBACK_FROM_COACH === r.type;
    }
    if (currentTab === PEER_REVIEW.key) {
      return [FEEDBACK_FROM_PEER, FEEDBACK_FROM_OUTSIDE_PEER].includes(r.type);
    }
    return false;
  };

  const filteredRatings = useMemo(
    () => ratings.filter(ratingsFilter),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTab],
  );

  const filteredRequests = useMemo(
    () => requests.filter(requestFilter).sort(sortByStatus()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTab],
  );
  return (
    <RatingsWrapper>
      {filteredRatings.map((r) => (
        <RowWrapper key={r.id}>
          <RateRow rating={r} userId={r.user} i18n={i18n} />
        </RowWrapper>
      ))}
      {filteredRequests.map((r) => (
        <RowWrapper key={r.id}>
          <RequestRow request={r} />
        </RowWrapper>
      ))}
      {isEmpty(filteredRatings) && isEmpty(filteredRequests) && (
        <>
          {currentTab === PEER_REVIEW.key && (
            <Placeholder
              title={i18n._(t`No peers nominated yet`)}
              subTitle={i18n._(
                t`Peer reviews will be shown here after ${getUserFullName(
                  forUser,
                )} nominates peers.`,
              )}
              titleStyles={{
                color: COLOR_PALETTE.BLACK,
                fontSize: '16px',
                fontWeight: 'normal',
                lineHeight: 1.38,
              }}
              subTitleStyles={{
                width: '373px',
                color: COLOR_PALETTE.DARK_GRAY,
                fontSize: '14px',
                fontWeight: 'normal',
                lineHeight: 1.67,
              }}
              Icon={() => (
                <SvgIcon
                  url={reviewIcon}
                  width={'22px'}
                  height={'21px'}
                  isDefaultColor
                  defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                />
              )}
            />
          )}
          {currentTab === SELF_REVIEW.key && (
            <RequestRow
              request={{
                toUser: forUser.id,
                toEmail: forUser.email,
                status: REQUEST_STATUSES.REQUESTED.key,
              }}
              key={`self_review_${forUser.id}`}
            />
          )}
        </>
      )}
    </RatingsWrapper>
  );
};

export default RatingStatusRow;
