import { useEffect } from 'react';

import nanoid from 'nanoid';

const useScript = ({ type, src, async, innerHTML } = {}) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.type = type || 'text/javascript';

    if (src) {
      script.src = src;
    }

    if (async) {
      script.async = async;
    }

    if (innerHTML) {
      script.innerHTML = innerHTML;
    }

    // Add id to be able to find it and delete it after
    const id = nanoid();
    script.id = id;

    document.body.appendChild(script);

    return () => {
      document.getElementById(id)?.remove();
    };

    // eslint-disable-next-line
  }, []);
};

export default useScript;
