import React from 'react';

import { CAREER_PLAN_STATUSES } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import format from 'date-fns/format';
import styled from 'styled-components';

import { ICONS } from '~/components/Icon';
import Label from '~/components/Label';

import { IconInfo } from './IconInfo';

import { COLORS } from '~/styles';

import { Header, Title } from '../design';

import type { ICareerPlan } from '@learned/types';

interface DetailsProps {
  careerPlan?: ICareerPlan;
}

const IconsInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const DetailsSection = ({ careerPlan }: DetailsProps) => {
  const { i18n } = useLingui();
  const isActive = careerPlan?.status === CAREER_PLAN_STATUSES.CURRENT;
  return (
    <div>
      <Header>
        <Title>
          <Trans>Details</Trans>
        </Title>
      </Header>
      <IconsInfoWrapper>
        <IconInfo
          title={i18n._(t`Status`)}
          subtitle={
            isActive ? (
              <Label color={COLORS.ACTIVE} backgroundColor={COLORS.INFO_LIGHT}>
                <Trans>Active</Trans>
              </Label>
            ) : (
              <Label
                color={COLORS.PREVIOUS}
                backgroundColor={COLORS.PREVIOUS}
                backgroundByPercentage={30}
              >
                <Trans>Previous</Trans>
              </Label>
            )
          }
          icon={ICONS.WORK_EXPERIENCE}
        />
        <IconInfo
          title={i18n._(t`Start date`)}
          subtitle={
            careerPlan?.startDate ? format(new Date(careerPlan.startDate), 'dd-MM-yyyy') : '-'
          }
          icon={ICONS.AGENDA}
        />
        <IconInfo
          title={i18n._(t`End date`)}
          subtitle={
            isActive || !careerPlan?.endDate
              ? '-'
              : format(new Date(careerPlan.endDate), 'dd-MM-yyyy')
          }
          icon={ICONS.AGENDA}
        />
      </IconsInfoWrapper>
    </div>
  );
};

export { DetailsSection };
