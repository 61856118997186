import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const ExportIcon = ({ size, ...props }) => (
  <InlineSvg height={size} width={size} {...props} childrenFirst viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.719 4.53 10 1.25l3.281 3.28M10.011 10V1.252M13.75 7.5h1.875c.345 0 .625.28.625.625v8.125c0 .345-.28.625-.625.625H4.375a.625.625 0 0 1-.625-.625V8.125c0-.345.28-.625.625-.625H6.25"
      />
    </g>
  </InlineSvg>
);

ExportIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

ExportIcon.defaultProps = {
  size: 20,
  className: '',
};

export default ExportIcon;
