import React from 'react';

import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import InfoIcon from '~/components/Icons/Info';

import { COLOR_PALETTE } from '~/styles';

const InfoMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 24px;
`;

const Message = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 8px;
  margin-right: 8px;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const InfoLink = styled.a`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--company-color);
`;

const InfoMessage = ({ infoMessage, infoUrl }) => {
  return infoMessage ? (
    <InfoMessageWrapper>
      <InfoIcon />
      <Message>{infoMessage}</Message>
      {infoUrl && (
        <InfoLink target="_blank" href={infoUrl} rel="noopener noreferrer">
          <Trans>Learn more</Trans>
        </InfoLink>
      )}
    </InfoMessageWrapper>
  ) : null;
};

InfoMessage.propTypes = {
  infoMessage: PropTypes.string.isRequired,
  infoUrl: PropTypes.string,
};

export default withI18n()(InfoMessage);
