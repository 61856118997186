import React from 'react';

import { Trans } from '@lingui/macro';
import { useDispatch, useSelector } from 'react-redux';

import Switch from '~/components/Switch';

import { getIsCoachesOfOwnerCanEdit, getIsOwnerCanEdit } from '~/selectors/currentGoal';
import {
  setCurrentGoalIsCoachesOfOwnerCanEdit,
  setCurrentGoalIsOwnerCanEdit,
} from '~/store/currentGoal/actions';

import { ItemLabel, ItemRow, ToggleText, GrayRow } from '../design';

const EDITORS = {
  OWNER: 'isOwnerCanEdit',
  COACHES: 'isCoachesOfOwnerCanEdit',
};

const EditorsSection = () => {
  const dispatch = useDispatch();
  const isCoachesOfOwnerCanEdit = useSelector(getIsCoachesOfOwnerCanEdit);
  const isOwnerCanEdit = useSelector(getIsOwnerCanEdit);

  const onToggleChange = (_value: boolean, type: string) => {
    if (type === EDITORS.COACHES) {
      // @ts-ignore
      dispatch(setCurrentGoalIsCoachesOfOwnerCanEdit(!isCoachesOfOwnerCanEdit));
    } else {
      // @ts-ignore
      dispatch(setCurrentGoalIsOwnerCanEdit(!isOwnerCanEdit));
    }
  };

  return (
    <ItemRow marginTop="24px" marginBottom="24px" flexColumn>
      <ItemLabel bold>
        <Trans>Editors</Trans>
      </ItemLabel>
      <GrayRow>
        <Switch
          onChange={(value: boolean) => onToggleChange(value, EDITORS.OWNER)}
          checked={isOwnerCanEdit}
        />
        <ToggleText>
          <Trans>Owner can edit (When disabled, the owner can only update the goal progress)</Trans>
        </ToggleText>
      </GrayRow>
      <GrayRow marginTop="14px">
        <Switch
          onChange={(value: boolean) => onToggleChange(value, EDITORS.COACHES)}
          checked={isCoachesOfOwnerCanEdit}
        />
        <ToggleText>
          <Trans>Coach(es) of owner can edit</Trans>
        </ToggleText>
      </GrayRow>
    </ItemRow>
  );
};

export { EditorsSection };
