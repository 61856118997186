import React from 'react';

import { Trans } from '@lingui/macro';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';

import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';

import { ColorByPercentage } from './ColorByPercentage';

import { COLORS } from '~/styles';
import { toFixed } from '~/utils/math';

import { isValueAllowed } from '../../utils';

import type { ISurveyEventTask } from '@learned/types';

const tippyOpts = { modifiers: [{ name: 'computeStyles', options: { gpuAcceleration: false } }] };

const TippyName = styled.div`
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 12px;
  text-align: center;
  padding: 7px 12px 7px 12px;
`;

const StyledTippy = styled(Tippy)`
  border-radius: 6px;
  background-color: ${COLORS.MIDDLE_GRAY};
  .tippy-content {
    padding: 0;
  }

  .tippy-arrow {
    color: ${COLORS.MIDDLE_GRAY};
  }
`;

const Dash = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
`;

function GradientTaskCell({
  event,
  id,
  selectedItems,
  reportingThreshold,
}: {
  event?: ISurveyEventTask;
  id: string;
  reportingThreshold: number;
  selectedItems: string[];
}) {
  // @ts-ignore
  if (event?.tasks?.done <= 0 && event?.tasks?.all <= 0) {
    return <ColorByPercentage value={isValueAllowed(0, selectedItems)}>0/0</ColorByPercentage>;
  }

  if (event?.tasks) {
    const value = Math.floor((event.tasks.done / event.tasks.all) * 100);
    return (
      <ColorByPercentage value={isValueAllowed(value, selectedItems)}>
        {id !== 'average' ? (
          <>
            {' '}
            {toFixed(event.tasks.done, 1)}/{toFixed(event.tasks.all, 1)}{' '}
          </>
        ) : (
          <> {toFixed((event.tasks.done / event.tasks.all) * 100, 1)}%</>
        )}
      </ColorByPercentage>
    );
  } else {
    return (
      <ColorByPercentage>
        <StyledTippy
          trigger="mouseenter"
          popperOptions={tippyOpts}
          content={
            <TippyName>
              <Trans>Anonymity Threshold ({reportingThreshold} answers) not met</Trans>
            </TippyName>
          }
        >
          <Dash>
            <Icon icon={ICONS.INCOGNITO} size={ICON_SIZES.LARGE} color={COLORS.PLACEHOLDERS} />
          </Dash>
        </StyledTippy>
      </ColorByPercentage>
    );
  }
}

export { GradientTaskCell };
