import { ACTIVITY_PROGRESS_TYPES } from '@learned/constants';
import isEmpty from 'lodash/isEmpty';

export function isUserCompaniesActivity(userCompanies, activity) {
  if (!isEmpty(userCompanies)) {
    return userCompanies.some((company) => String(company.id) === String(activity.company));
  }

  return false;
}

export function isLibraryActivity(activity) {
  return activity && String(activity.origin) === 'library';
}

export function isCompanyActivity(activity, company) {
  if (isEmpty(company)) {
    return false;
  }
  return isLibraryActivity(activity) && String(activity.company) === String(company.id);
}

export const calculateProgressPercentage = ({
  progress,
  progressType,
  progressDetails,
  capNegatives = true,
}) => {
  switch (progressType) {
    case ACTIVITY_PROGRESS_TYPES.IS_DONE:
      return progress;

    case ACTIVITY_PROGRESS_TYPES.CURRENCY:
    case ACTIVITY_PROGRESS_TYPES.NUMBERS:
    case ACTIVITY_PROGRESS_TYPES.PERCENTAGE: {
      const total = progressDetails.max - progressDetails.min;
      const relativeProgress = progress - progressDetails.min;
      const result = (relativeProgress * 100) / total;

      // If negative percentages are not allowed, return zero instead
      if (capNegatives && result < 0) {
        return 0;
      }
      return result;
    }

    default:
      return 0;
  }
};
