import React, { PureComponent } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';
import { FieldGroup, FieldTitle, SearchField } from '~/components/Text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmptyText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #3b3b3b;
  height: 60px;
  width: 100%;
`;

const Row = styled.div`
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #3b3b3b;
  min-height: 64px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  background-color: #f8f8f8;
  margin: 0;
  margin-bottom: 1px;

  &:hover {
    background-color: rgba(161, 166, 180, 0.3);
    cursor: pointer;
  }
`;

class CheckBoxList extends PureComponent {
  static propTypes = {
    multiCheck: PropTypes.bool,
    enableSearch: PropTypes.bool,
  };

  static defaultProps = {
    multiCheck: false,
    enableSearch: false,
    searchLabel: '',
    selected: [],
  };

  state = {
    search: '',
  };

  handleSearch = (e) => {
    this.setState({ search: e.target.value });
  };

  checkItem = (item) => {
    const { multiCheck, selected, onChange } = this.props;

    if (multiCheck) {
      if (selected.indexOf(item.id) !== -1) {
        onChange(selected.filter((s) => s !== item.id));
      } else {
        onChange(selected.concat([item.id]));
      }
    } else {
      if (selected.indexOf(item.id) !== -1) {
        onChange([]);
      } else {
        onChange([item.id]);
      }
    }
  };

  render() {
    const { className, searchLabel, items, i18n, selected, enableSearch } = this.props;
    const { search, error } = this.state;

    let filteredItems = items;
    if (search) {
      filteredItems = filter(items, (item) =>
        (item.name || '').toLowerCase().includes(search.toLowerCase()),
      );
    }

    return (
      <Container className={className}>
        {enableSearch && (
          <FieldGroup>
            <FieldTitle>{searchLabel}</FieldTitle>
            <SearchField
              data-mqdid="select-goal_input"
              value={search}
              error={error}
              onChange={this.handleSearch}
              placeholder={i18n._(t`Search`)}
            />
          </FieldGroup>
        )}
        {isEmpty(filteredItems) ? (
          <EmptyText>
            <span>
              <Trans>Sorry! Nothing available.</Trans>
            </span>
          </EmptyText>
        ) : (
          <div>
            {map(filteredItems, (item) => {
              let isSelected = selected.indexOf(item.id) !== -1;

              return (
                <Row onClick={() => this.checkItem(item)} key={item.id}>
                  {item.name}
                  <CheckBox checked={isSelected} />
                </Row>
              );
            })}
          </div>
        )}
      </Container>
    );
  }
}

export default withI18n()(CheckBoxList);
