import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import { AutocompleteFilterGoalCycles } from '~/components/AutocompleteFilters';
import { Icon, ICONS } from '~/components/Icon';
import FeedbackIcon from '~/components/Icons/Feedback';
import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';

import TabDefault from './TabDefault';
import TabRTFeedback from './TabRTFeedback';
import TabTemplates from './TabTemplates';

import ActivitiesIcon from '~/assets/collections_bookmark.svg';
import TalkingPointsPlaceholderIcon from '~/assets/placeholders/talking-points-placeholder.svg';

import { COLOR_PALETTE } from '~/styles';

const StyledAutoCompleteGoalFilter = styled(AutocompleteFilterGoalCycles)`
  width: 300px;
  height: 32px;
  margin: 8px;
`;

const SIDE_TAB_KEYS = {
  custom: 'custom',
  templates: 'templates',
  goals: 'goals',
  learningPaths: 'paths',
  learningActivities: 'activities',
  rtFeedback: 'rtFeedback',
  reviews: 'reviews',
};

const RightColumnContent = ({
  i18n,
  currentSideTab,
  RTFeedbacks,
  disabledItems,
  onRTFeedbackUpdate,
  selectedItems,
  toggleItem,
  toggleItems,
  user,
  createRTFB,
  loading,
  expandedRTFB,
  templates,
  activities,
  paths,
  selectedAutoCompleteFilters,
  createCustomItem,
  setSelectedAutoCompleteFilters,
  setItemFilter,
  goals,
  customItems,
  reviews,
  itemFilter,
}) => {
  let items;
  let createItem;
  let placeholder;
  let autoCompleteFilter;

  switch (currentSideTab) {
    case SIDE_TAB_KEYS.rtFeedback: {
      return (
        <TabRTFeedback
          items={RTFeedbacks?.filter((RTFeedback) => !disabledItems.includes(RTFeedback.id))}
          selectedItems={selectedItems}
          disabledItems={disabledItems}
          toggleItem={toggleItem}
          toggleItems={toggleItems}
          user={user}
          createRTFB={createRTFB}
          loading={loading}
          expandedRTFB={expandedRTFB}
          onRTFeedbackUpdate={onRTFeedbackUpdate}
        />
      );
    }
    case SIDE_TAB_KEYS.templates: {
      return (
        <TabTemplates
          items={templates
            .map((template) => {
              const talkingPoints = template.talkingPoints.filter(
                (tp) => !disabledItems.includes(tp),
              );
              return talkingPoints.length
                ? {
                    ...template,
                    talkingPoints,
                  }
                : undefined;
            })
            .filter((i) => i)}
          selectedItems={selectedItems}
          disabledItems={disabledItems}
          toggleItem={toggleItem}
          toggleItems={toggleItems}
        />
      );
    }
    case SIDE_TAB_KEYS.learningActivities: {
      items = activities.filter((activity) => !disabledItems.includes(activity.id));
      placeholder = (
        <Placeholder
          Icon={() => (
            <SvgIcon
              url={ActivitiesIcon}
              width={'50px'}
              height={'50px'}
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
          )}
          title={i18n._(t`No activities available`)}
          subTitle={i18n._(t`There are no learning activities available`)}
        />
      );
      break;
    }
    case SIDE_TAB_KEYS.learningPaths: {
      items = paths.filter((path) => !disabledItems.includes(path.id));
      placeholder = (
        <Placeholder
          Icon={() => (
            <SvgIcon
              url={ActivitiesIcon}
              width={'50px'}
              height={'50px'}
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
          )}
          title={i18n._(t`No paths available`)}
          subTitle={i18n._(t`There are no paths available`)}
        />
      );
      break;
    }
    case SIDE_TAB_KEYS.goals: {
      const selectGoalCycle = (goalCycles) => {
        const selectedGoalCyclesIds = goalCycles.map((gc) => gc.id);
        setSelectedAutoCompleteFilters(goalCycles);
        setItemFilter(
          () => (goal) =>
            selectedGoalCyclesIds.length
              ? selectedGoalCyclesIds.some((id) =>
                  goal?.goalCycles.some((goalCycle) => goalCycle.id === id),
                )
              : true,
        );
      };
      autoCompleteFilter = (
        <StyledAutoCompleteGoalFilter
          onChange={selectGoalCycle}
          checkedList={selectedAutoCompleteFilters}
        />
      );
      items = goals.filter((goal) => {
        return !disabledItems.includes(goal.id);
      });
      placeholder = (
        <Placeholder
          title={i18n._(t`No goals`)}
          subTitle={i18n._(t`There have not been created any goals`)}
          Icon={() => <Icon icon={ICONS.DEVELOPMENT} width={32} height={32} color="#cadafc" />}
        />
      );
      break;
    }
    case SIDE_TAB_KEYS.custom: {
      items = customItems.filter((item) => !disabledItems.includes(item.name));
      createItem = createCustomItem;
      placeholder = (
        <Placeholder
          Icon={() => (
            <SvgIcon
              url={TalkingPointsPlaceholderIcon}
              width={'32px'}
              height={'32px'}
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
          )}
          title={i18n._(t`No talking points`)}
          subTitle={i18n._(t`No custom talking points yet`)}
        />
      );
      break;
    }
    case SIDE_TAB_KEYS.reviews: {
      items = reviews.filter((item) => !disabledItems.includes(item.id));
      placeholder = (
        <Placeholder
          title={i18n._(t`No reviews`)}
          subTitle={i18n._(t`There have not been created any review rounds`)}
          Icon={FeedbackIcon}
        />
      );
      break;
    }
    default:
  }
  return (
    <TabDefault
      key={currentSideTab}
      type={currentSideTab}
      items={items.filter(itemFilter)}
      createItem={createItem}
      placeholder={placeholder}
      loading={loading}
      selectedItems={selectedItems}
      disabledItems={disabledItems}
      toggleItem={toggleItem}
      toggleItems={toggleItems}
      autoCompleteFilter={autoCompleteFilter}
    />
  );
};

export default withI18n()(RightColumnContent);
