enum RATING_STATUSES {
  RATED = 'rated',
  TODO = 'todo',
  SKIPPED = 'skipped',
  CALIBRATE = 'calibrate',
}

enum RATING_QUESTION_TYPES {
  GOAL_BUSINESS_EVAL_AVERAGE = 'goalBusinessEvalAverage',
  GOAL_LEARNING_EVAL_AVERAGE = 'goalLearningEvalAverage',
  GOAL_BUSINESS_EVAL = 'goalBusinessEval',
  GOAL_LEARNING_EVAL = 'goalLearningEval',
}

export { RATING_QUESTION_TYPES, RATING_STATUSES };
