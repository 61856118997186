import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const Body14Styles = css`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  color: ${COLORS.TEXT_SECONDARY};
  margin: 0;
`;

export const Body16Styles = css`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  color: ${COLORS.TEXT_SECONDARY};
`;

export const Body14 = styled.p(Body14Styles);
export const Body16 = styled.div(Body16Styles);
