import type { ICreateServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getSurveyEventsBySurveyId(surveyId: string, options = {}) {
  return cloudRequest(
    (serverRoutes.surveyEvents.getSurveyEventsBySurveyId as ICreateServerRoute)(surveyId),
    undefined,
    options,
  );
}

export function getSurveyEventsByUserSurveyId(userSurveyId: string, options = {}) {
  return cloudRequest(
    (serverRoutes.surveyEvents.getSurveyEventsByUserSurveyId as ICreateServerRoute)(userSurveyId),
    undefined,
    options,
  );
}
