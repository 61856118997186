import React, { useState, useEffect } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';
import { confirm } from '~/components/ConfirmDialog';
import PeopleIcon from '~/components/Icons/People';
import Placeholder from '~/components/Placeholder';
import SearchSelectButton from '~/components/SearchSelectButton';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SearchField } from '~/components/Text';

import UserItem from './components/UserItem';

import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { getTeams } from '~/selectors/baseGetters';
import { getCompanyUsers } from '~/services/users';
import * as teamActions from '~/store/teams/actions';
import { COLOR_PALETTE, COLORS } from '~/styles';
import getUserTeams from '~/utils/getUserTeams';

const ContentMainWrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400px;
`;

const ContentWrapper = styled.div`
  margin: 5px 0 0px;
  max-height: ${(props) => props.isSelectMode && '400px'};
  overflow: auto;

  h5 {
    font-weight: 600;
  }
`;

const SelectAll = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px 24px 12px;
  cursor: pointer;
  height: 20px;
`;

const SelectAllLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const ModalFilters = styled.div`
  display: flex;
  margin: 12px 0 0;
  border-bottom: 1px solid ${COLORS.BORDER_HARD};
  padding: 0 24px 12px;
`;

const SearchFieldWrapper = styled(SearchField)`
  height: 36px;
  margin-right: 12px;
  width: 100%;
`;

const SearchSelectButtonWrapper = styled(SearchSelectButton)`
  box-sizing: border-box;
`;

const LIMIT = 20;

const AddPeopleAutoComplete = ({
  selectedUsers,
  usersToHide,
  usersToAdd,
  onChange,
  singleChoice,
  disabled,
  isSelectMode,
  preSelectedTeamsInFilter,
  preSelectedRoles,
  isTeamFilterDisabled,
  isIgnoreQuickSelect,
  isDontGetOwnCoaches,
  filterTeamsItems,
  isAddMyself,
  isTeamsFilterSearchAlsoCoaches,
  isTeamsFilterSearchAlsoMembers,
  usersToSearchCoaches,
}) => {
  const dispatch = useDispatch();
  const { i18n } = useLingui();
  const teamsFromStore = useSelector(getTeams);
  const teams = filterTeamsItems || teamsFromStore; // if teams undefined, show all company teams
  const filterTeamsItemsIds = filterTeamsItems?.map((t) => t.id || t);
  const [selectedTeams, setSelectedTeams] = useState(preSelectedTeamsInFilter || []);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);
  const $loading = useBoolState(true);
  const [allUsers, setAllUsers] = useState([]);

  const fetchMembers = async (search, teams) => {
    const { data } = await getCompanyUsers({
      limit: teams && teams.length > 0 ? 0 : LIMIT,
      search,
      // with search (do not display checked users)
      // if search empty display checked + users from request
      usersToHide: debouncedSearch
        ? usersToHide
        : uniq([...usersToHide, ...selectedUsers.map((u) => u.id)]),
      usersToAdd,
      teams,
      ...(!isEmpty(preSelectedRoles) && { roles: preSelectedRoles }),
      isPermissionsCheck: true,
      isIgnoreQuickSelect,
      isDontGetOwnCoaches,
      isAddUserFromMeta: isAddMyself,
      isTeamsFilterSearchAlsoCoaches,
      isTeamsFilterSearchAlsoMembers,
      usersToSearchCoaches,
    });
    const users = Object.values(data.users ?? {});

    const allUsers = debouncedSearch
      ? users // with search display only founded users
      : [...selectedUsers, ...users]; // without search also checked users
    setAllUsers(allUsers);
  };

  // init fetch
  useEffect(() => {
    const fetchData = async () => {
      // fetch teams
      dispatch(teamActions.getTeams());

      $loading.off();
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  // fetch new members when search change
  useEffect(() => {
    const fetchData = async () => {
      $loading.on();
      // if teams for filter from props -> fetch also only users from that teams.
      await fetchMembers(debouncedSearch, filterTeamsItemsIds || selectedTeams);
      $loading.off();
    };

    fetchData();
    // eslint-disable-next-line
  }, [debouncedSearch, JSON.stringify(selectedTeams)]);

  const selectAll = (allUsers) => onChange(allUsers);
  const deselectAll = () => onChange([]);
  const selectUser = (user) => {
    // single choice
    if (singleChoice) {
      onChange([user]);
      return;
    }

    // multiple choice
    let newSelectedUsers = [];
    if (!isEmpty(selectedUsers)) {
      newSelectedUsers = [...selectedUsers];
    }
    let index = newSelectedUsers.map((u) => u.id).indexOf(user.id);
    if (index !== -1) {
      newSelectedUsers = newSelectedUsers.filter((_, i) => i !== index);
    } else {
      newSelectedUsers.push(user);
    }

    // clear search on check/uncheck action
    setSearch('');
    onChange(newSelectedUsers);
  };

  const removeUser = async (user) => {
    let newSelectedUsers = [];
    if (!isEmpty(selectedUsers)) {
      newSelectedUsers = [...selectedUsers];
    }
    let index = newSelectedUsers.map((u) => u.id).indexOf(user.id);
    if (index !== -1) {
      if (
        await confirm(
          i18n,
          i18n._(
            t`Are you sure you want to delete this person? This will delete all progress made by this user and can’t be undone.`,
          ),
        )
      ) {
        newSelectedUsers = newSelectedUsers.filter((_, i) => i !== index);
      }
    }
    onChange(newSelectedUsers);
  };

  const getUserItemTeams = (user) => {
    return getUserTeams(user.id, teamsFromStore);
  };

  const isUserSelected = (user) => {
    let isSelected = false;
    if (!isEmpty(selectedUsers)) {
      selectedUsers.forEach((selectedUser) => {
        if (String(selectedUser.id) === String(user.id)) {
          isSelected = true;
        }
      });
    }
    return isSelected;
  };

  const renderUser = (user) => {
    const isSelected = isUserSelected(user);

    return (
      user && (
        <UserItem
          key={user.id}
          user={user}
          selected={isSelected}
          onSelect={selectUser}
          onRemove={removeUser}
          isSelectMode={isSelectMode}
          disabled={disabled}
          singleChoice={singleChoice}
          userTeams={getUserItemTeams(user)}
        />
      )
    );
  };

  return (
    <ContentMainWrap>
      <ModalFilters>
        <SearchFieldWrapper
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          placeholder={i18n._(t`Search for people`)}
          style={{
            borderRadius: '6px',
            fontSize: '14px',
            fontWeight: 600,
          }}
        />
        <SearchSelectButtonWrapper
          title={i18n._(t`Select teams`)}
          options={Object.values(teams)}
          checkedList={selectedTeams}
          handleChange={(teams) => setSelectedTeams(teams)}
          isScroll={true}
          disabled={isTeamFilterDisabled}
          style={{
            borderRadius: '6px',
            fontFamily: 'OpenSans',
            fontSize: '14px',
            fontWeight: 600,
          }}
          width="100%"
          height="34px"
        />
      </ModalFilters>

      {/* Do not display select all row for single choice */}
      {!singleChoice && (
        <SelectAll
          onClick={() =>
            selectedUsers.length === allUsers.length ? deselectAll() : selectAll(allUsers)
          }
        >
          <SelectAllLabel>
            <Trans>Select all</Trans>
          </SelectAllLabel>
          <CheckBox
            checked={!isEmpty(allUsers) && size(selectedUsers) === size(allUsers)}
            size={'24px'}
          />
        </SelectAll>
      )}

      <ContentWrapper isSelectMode={isSelectMode}>
        <ShowSpinnerIfLoading loading={$loading.value}>
          {!isEmpty(allUsers) ? (
            <div>{allUsers.map(renderUser)}</div>
          ) : (
            <Placeholder title={i18n._(t`No people available`)} Icon={PeopleIcon} />
          )}
        </ShowSpinnerIfLoading>
      </ContentWrapper>
    </ContentMainWrap>
  );
};

AddPeopleAutoComplete.propTypes = {
  selectedUsers: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  singleChoice: PropTypes.bool,
  isSelectMode: PropTypes.bool,
  usersToHide: PropTypes.arrayOf(PropTypes.string),
  usersToAdd: PropTypes.arrayOf(PropTypes.string),
  preSelectedTeamsInFilter: PropTypes.arrayOf(PropTypes.string),
  preSelectedRoles: PropTypes.arrayOf(PropTypes.string),
  isTeamFilterDisabled: PropTypes.bool,
  filterTeamsItems: PropTypes.arrayOf(PropTypes.object),
  isAddMyself: PropTypes.bool,
  isTeamsFilterSearchAlsoCoaches: PropTypes.bool,
  isTeamsFilterSearchAlsoMembers: PropTypes.bool,
  usersToSearchCoaches: PropTypes.arrayOf(PropTypes.string),
};

AddPeopleAutoComplete.defaultProps = {
  singleChoice: false,
  isSelectMode: true,
};

export default React.memo(AddPeopleAutoComplete);
