import { createReducer } from 'redux-act';

import * as actions from './actions';

export default createReducer(
  {
    [actions.setJobLevelGroups]: (state, payload) => {
      return {
        ...state,
        data: payload,
      };
    },
  },
  {},
);
