import React from 'react';

import { ITask } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';

import { List } from '../components/List';
import { ListRowPlaceholder } from '../components/ListRowPlaceholder';
import { ListRowProvideFeedback } from '../components/ListRowProvideFeedback';
import { Section } from '../components/Section';

import type { IReviewEmployeeOverviewForm } from '../types';
import type { UseFormReturn } from 'react-hook-form';

interface ProvidePeerInputSectionProps {
  tasks: ITask[];
  formMethods: UseFormReturn<IReviewEmployeeOverviewForm>;
}

const ProvidePeerInputSection = ({ tasks, formMethods }: ProvidePeerInputSectionProps) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;
  const employee = watch('employee');

  const userFirstName = employee?.firstName;

  return (
    <Section title={i18n._(t`${userFirstName}’s tasks to provide peer input`)}>
      <List>
        {isEmpty(tasks) ? (
          <ListRowPlaceholder text={i18n._(t`None`)} isWarning={false} />
        ) : (
          tasks.map((task, key) => <ListRowProvideFeedback key={key} task={task} />)
        )}
      </List>
    </Section>
  );
};

export { ProvidePeerInputSection };
