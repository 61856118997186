import React from 'react';

import styled from 'styled-components';

import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';

import { COLOR_PALETTE, COLOR_SET } from '~/styles';

export interface ICardSectionProps {
  title: string;
  value: number;
  valueByTime?: number;
  valueByTimeSymbol?: string;
  icon: {
    url: string;
    backgroundColors: [string, string];
    width: number;
    height: number;
  };
  isLoading?: boolean;
}

const Card = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  background-color: ${COLOR_PALETTE.WHITE};
  padding: 32px;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
  border-radius: 6px;

  & .content {
    display: flex;
    flex-direction: column;
    width: 100%;

    & .title {
      font-size: 14px;
    }

    & .values {
      display: inline-flex;
      justify-content: space-between;
      align-items: flex-end;
      margin: -6px 0;

      & .total {
        font-size: 27px;
        font-weight: 600;
        color: ${COLOR_PALETTE.BLACK};
      }

      & .valueByTime {
        font-size: 14px;
        font-weight: 600;
        color: ${COLOR_SET.CYAN_GREEN};
      }
    }
  }
`;

const Icon = styled.div<{ backgroundColors: [string, string] }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  min-height: 48px;
  border-radius: 6.3px;
  background-image: ${({ backgroundColors }) =>
    backgroundColors &&
    `linear-gradient(to bottom, ${backgroundColors[0]}, ${backgroundColors[1]})`};
`;

const CardSection = ({
  title,
  value,
  valueByTime,
  valueByTimeSymbol,
  icon,
  isLoading = false,
}: ICardSectionProps) => {
  return (
    <Card>
      <ShowSpinnerIfLoading height="49px" loading={isLoading}>
        <Icon backgroundColors={icon.backgroundColors}>
          <SvgIcon
            width={`${icon.width}px`}
            height={`${icon.height}px`}
            url={icon.url}
            isDefaultColor
            defaultColor={COLOR_PALETTE.DARK_GRAY}
          />
        </Icon>
        <div className="content">
          <div className="title">{title}</div>
          <div className="values">
            <span className="total">{value}</span>
            {!!valueByTime && (
              <span className="valueByTime">
                +{valueByTime}
                {valueByTimeSymbol}
              </span>
            )}
          </div>
        </div>
      </ShowSpinnerIfLoading>
    </Card>
  );
};

export { CardSection };
