class debounceValue {
  constructor(startValue, delay = 1000) {
    this.delay = delay;
    this._val = startValue;
    this._def = startValue;
  }

  get value() {
    return this._val;
  }

  set(newVal) {
    this._val = newVal;
    return new Promise((res) => {
      setTimeout(() => {
        this.reset();
        res();
      });
    });
  }

  reset() {
    this._val = this._def;
  }
}

export default debounceValue;
