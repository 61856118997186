import React from 'react';

import { Trans } from '@lingui/macro';
import get from 'lodash/get';
import styled from 'styled-components';

import Legend from '~/components/Legend';

import JobProfileSkillQuestion from './JobProfileSkillQuestion';

import { REQUEST_STATUSES } from '~/constants';
import { COLORS, COLOR_PALETTE } from '~/styles';

const CategoryName = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin-bottom: 8px;
  margin-top: 20px;
`;

const LegendWrapper = styled.div`
  margin-bottom: 24px;
`;

const SectionDescription = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLORS.TEXT_SECONDARY};
  margin-bottom: 16px;
`;

const JobProfileSkillsList = ({
  isLockedFeedback,
  isReadOnly,
  review,
  pdfView,
  users,
  viewerId,
  handleOnRatingUpdated,
  feedbackRatings,
  legend,
  categoriesUniq,
  questionsForList,
  hideCoaches,
  hidePeers,
  hideSelf,
  isRightSideBarOpen,
}) => {
  const requests = get(review, 'requests', []);
  const rejectedRequests = requests.filter((r) => r.status === REQUEST_STATUSES.REJECTED.key);

  return (
    <>
      <SectionDescription>
        <Trans>All the competenties and skills required for this role.</Trans>
      </SectionDescription>
      <LegendWrapper>
        <Legend items={legend} />
      </LegendWrapper>
      {categoriesUniq.map((category, categoryIndex) => {
        return (
          <div key={category.id}>
            <CategoryName>{category.name}</CategoryName>
            {questionsForList
              // filter questions only for current category
              .filter(
                ({ skill }) =>
                  (skill.categories || []).includes(category.id) || // for company skills
                  (skill.categories || []).includes(category.categoryId), // for learned skills
              )
              .map(({ question, skill }, subQuestionIndex) => {
                const isOpenDefault = (categoryIndex === 0 && subQuestionIndex === 0) || pdfView; // open first question in first category
                const skillRatings = feedbackRatings.filter((rating) => rating.skill === skill.id);

                return (
                  <JobProfileSkillQuestion
                    key={skill.id}
                    question={question}
                    skill={skill}
                    pdfView={pdfView}
                    users={users}
                    viewerId={viewerId}
                    skillRatings={skillRatings}
                    isOpenDefault={isOpenDefault}
                    expectedLevel={
                      review?.skillsJobProfileLevels && review.skillsJobProfileLevels[skill.id]
                    }
                    rejectedRequests={rejectedRequests}
                    isLockedFeedback={isLockedFeedback}
                    review={review}
                    isReadOnly={isReadOnly}
                    handleOnRatingUpdated={handleOnRatingUpdated}
                    hideSelf={hideSelf}
                    hidePeers={hidePeers}
                    hideCoaches={hideCoaches}
                    isRightSideBarOpen={isRightSideBarOpen}
                  />
                );
              })}
          </div>
        );
      })}
    </>
  );
};

export default JobProfileSkillsList;
