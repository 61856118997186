import React, { useState } from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import IconButton from '~/components/IconButton';
import ExpandMoreIcon from '~/components/Icons/ArrowButtonTall';
import UpdateRatingModal from '~/components/Modals/UpdateRatingModal';
import { TemplateQuestionCardWithFooter } from '~/components/SetupTools/components/TemplateBaseComponents';
import SkillDefinitionModal from '~/components/SkillDefinitionModal';
import SkillProgressBar from '~/components/SkillProgressBar';
import Tooltip from '~/components/Tooltip';
import { Header4 } from '~/components/UI/Typographics/headers';

import QuestionDescription from './QuestionDescription';
import RateRow from './RateRow';
import RejectedRequest from './RejectedRequest';
import { getAverageRatesForSkill } from './utils';

import { RATING_TYPES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import getLang from '~/selectors/getLang';
import { getSkillName } from '~/utils/skillUtils';

const DescriptionWrapper = styled.div`
  margin: 0 16px 16px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  padding: ${(props) => (props.$isOpen ? '14px 16px 0' : '16px')};
  min-height: ${(props) => (props.$isOpen ? '96px' : '56px')};
  box-sizing: border-box;
`;

const ProgressBarWrap = styled.div`
  width: 100%;
  margin-top: -10px;
`;

const RatingsWrapper = styled.div``;

const Row = styled.div`
  width: 100%;
  ${Header4} {
    margin: 0 40px 0 0;
    max-width: 240px;
    min-width: 240px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  display: flex;
  align-items: center;
  margin-right: 65px;
`;

const TemplateCardWithFooterStyled = styled(TemplateQuestionCardWithFooter)`
  box-shadow: none;
  margin-bottom: 8px;
`;

const ToggleWrapper = styled.div`
  position: absolute;
  right: 24px;
  top: calc(50% - 12px);
`;

const StyledRateRow = styled(RateRow)`
  padding-top: 0;
`;

const JobProfileSkillQuestion = ({
  isOpenDefault,
  handleOnRatingUpdated,
  question,
  expectedLevel,
  pdfView,
  skill,
  skillRatings,
  viewerId,
  users,
  isLockedFeedback,
  rejectedRequests,
  review,
  hideCoaches,
  hidePeers,
  hideSelf,
  isRightSideBarOpen,
}) => {
  const { i18n } = useLingui();
  const lang = useSelector(getLang);
  const $open = useBoolState(isOpenDefault);
  const showSkillModal = useBoolState();
  const $isUpdateRatingModal = useBoolState(false);
  const [selectedRating, setSelectedRating] = useState(null);
  const skillName = getSkillName(skill, lang);

  const toggleItem = () => {
    $open.value ? $open.off() : $open.on();
  };

  const scaleLabels = !isEmpty(skillRatings) ? skillRatings[0].scaleLabels : [];
  const scale = !isEmpty(skillRatings) ? skillRatings[0].scale : [];
  const skillRatesBarData = getAverageRatesForSkill({
    i18n,
    feedbackRatings: skillRatings,
    expectedLevel,
    hideCoaches,
    hidePeers,
    hideSelf,
  });

  return (
    <TemplateCardWithFooterStyled $isOpen={$open.value}>
      <HeaderContainer onClick={toggleItem} $isOpen={$open.value}>
        <Row>
          <Tooltip tooltip={skillName}>
            <Header4>{skillName}</Header4>
          </Tooltip>
          <ProgressBarWrap>
            <SkillProgressBar
              title={skillName}
              items={skillRatesBarData}
              isShowLabels={$open.value}
              isOnlySelectedLabel={isRightSideBarOpen} // not enough space for labels, so we display only selected rate labels (when sidebar is opened)
              scale={scale}
              scaleLabels={scaleLabels}
            />
          </ProgressBarWrap>
        </Row>
        <ToggleWrapper>
          <IconButton size={24} noBorder onClick={toggleItem} style={{ marginLeft: '24px' }}>
            {$open.value ? (
              <ExpandMoreIcon size={24} style={{ transform: 'rotate(180deg)' }} />
            ) : (
              <ExpandMoreIcon size={24} />
            )}
          </IconButton>
        </ToggleWrapper>
      </HeaderContainer>
      {$open.value && (
        <>
          <DescriptionWrapper>
            <QuestionDescription
              type={QUESTION_TYPES.JOB_PROFILE}
              expectedLevel={expectedLevel}
              expectedLevelLabel={
                (scaleLabels && scaleLabels[scale.indexOf(Number(expectedLevel))]) || null
              }
              skill={skill}
              lang={lang}
              pdfView={pdfView}
              openSkillModal={showSkillModal.on}
            />
          </DescriptionWrapper>
          <RatingsWrapper>
            {skillRatings.map((r) => (
              <StyledRateRow
                rating={r}
                key={r.id}
                userId={r.type === RATING_TYPES.SELF_RATING ? review.createdFor : r.user}
                i18n={i18n}
                viewerId={viewerId}
                onUpdate={() => {
                  setSelectedRating(r);
                  $isUpdateRatingModal.on();
                }}
                isLockedFeedback={isLockedFeedback}
                pdfView={pdfView}
                users={users}
              />
            ))}
          </RatingsWrapper>
          {!isEmpty(rejectedRequests) &&
            rejectedRequests.map((r) => <RejectedRequest request={r} key={r.id} />)}
        </>
      )}
      {showSkillModal.value && (
        <SkillDefinitionModal
          onModalClose={showSkillModal.off}
          skill={skill}
          scaleLabels={question.scaleLabels}
          expectedLevel={expectedLevel}
        />
      )}
      {$isUpdateRatingModal.value && (
        <UpdateRatingModal
          review={review}
          rating={selectedRating}
          question={question}
          skill={skill}
          onClose={(updatedRating) => {
            $isUpdateRatingModal.off();
            // update rating in review (local)
            if (!isEmpty(updatedRating)) {
              handleOnRatingUpdated(updatedRating);
            }
          }}
        />
      )}
    </TemplateCardWithFooterStyled>
  );
};

export default JobProfileSkillQuestion;
