import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import getCurrentCompany from '~/selectors/getCurrentCompany';
import { COLORS } from '~/styles';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const GraphWrapper = styled.div`
  box-sizing: border-box;
  margin: 24px;
`;

const LabelsLine = styled.div`
  justify-content: center;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.08;
  letter-spacing: 0.21px;
  color: ${COLORS.TEXT_SECONDARY};
  margin-top: 24px;
`;

const Square = styled.span`
  width: 12px;
  height: 12px;
  background-color: ${(props) => props.color || '#ccced5'};
  border-radius: 2px;
  display: inline-block;
  margin-right: 8px;
`;

function ConversationsStatistics({ i18n, graphData, className }) {
  const { color } = useSelector(getCurrentCompany);
  const getLegendData = () => {
    return [{ color, text: i18n._(t`Total number of conversations per month`) }];
  };

  const legendData = getLegendData();

  const getOptions = () => {
    return {
      plugins: {
        crosshair: false,
        legend: {
          display: false,
        },
        tooltip: {
          position: 'average',
          shadowColor: 'rgba(55, 70, 95, 0.08)',
          backgroundColor: COLORS.WHITE,
          titleColor: '#3b3b3b',
          bodyColor: '#3b3b3b',
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
        },
        y: {
          stacked: true,
          ticks: { precision: 1, maxTicksLimit: 5, beginAtZero: true },
        },
      },
    };
  };

  const options = getOptions();

  const prepareDataset = (graphData) => {
    // init template dataset
    let dataset = {
      labels: graphData.map((d) => d.month),
      datasets: [
        {
          barThickness: 43,
          label: i18n._(t`Conversations`),
          data: graphData.map((d) => d.conversationsPerMonth),
          backgroundColor: color,
        },
      ],
    };

    return dataset;
  };

  return (
    <GraphWrapper className={className}>
      <Bar height={50} data={prepareDataset(graphData)} options={options} />
      <LabelsLine>
        {legendData.length > 0 &&
          legendData.map((label) => (
            <div key={label.text}>
              <Square color={label.color} /> {label.text}
            </div>
          ))}
      </LabelsLine>
    </GraphWrapper>
  );
}

ConversationsStatistics.propTypes = {
  graphData: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string,
      conversationsPerMonth: PropTypes.number,
    }),
  ),
};

export default withI18n()(ConversationsStatistics);
