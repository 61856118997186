import React, { useEffect, useState, useRef } from 'react';

import styled from 'styled-components';

const Wrap = styled.div`
  overflow: hidden;
  transition: 0.3s;
  padding: 0 15px;
  margin: 0 -15px;
`;

const statuses = {
  waiting: 'waiting',
  collapsing: 'collapsing',
  opened: 'opened',
  closing: 'closing',
};

function getHeightValue(status, realHeight, minHeight) {
  switch (status) {
    case statuses.waiting: {
      return minHeight;
    }
    case statuses.collapsing: {
      return realHeight;
    }
    case statuses.opened: {
      return '';
    }
  }
}

const CollapsingBlock = ({ children, className, minHeight = 0, open, bottomOffset = 0 }, ref) => {
  const [realHeight, setHeight] = useState(0);
  const [status, setStatus] = useState(open ? statuses.opened : statuses.waiting);
  const localRef = useRef();
  const currentRef = ref || localRef;

  useEffect(() => {
    setStatus(statuses.collapsing);
    if (open) {
      const timout = setTimeout(() => {
        setStatus(statuses.opened);
      }, 310);
      return () => {
        clearInterval(timout);
      };
    }
    const timout = setTimeout(() => {
      setStatus(statuses.waiting);
    }, 5);
    return () => {
      clearInterval(timout);
    };
  }, [open]);

  useEffect(() => {
    if (currentRef && currentRef.current) {
      const height = currentRef.current.offsetHeight + bottomOffset;
      if (height !== realHeight) {
        setHeight(height);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  return (
    <Wrap
      className={className}
      style={{
        maxHeight: getHeightValue(status, realHeight, minHeight),
      }}
    >
      <div ref={currentRef}>{children}</div>
    </Wrap>
  );
};

export default React.memo(React.forwardRef(CollapsingBlock));
