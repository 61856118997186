export enum SURVEY_TASK_STATUS {
  DRAFT = 'draft',
  TODO = 'todo',
  COMPLETED = 'completed',
  ARCHIVED = 'archived',
}

export enum VIRTUAL_SURVEY_TASK_STATUS {
  DRAFT = 'draft',
  TODO = 'todo',
  COMPLETED = 'completed',
  ARCHIVED = 'archived',
  NOT_PARTICIPATED = 'not_participated'
}

