import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Switch from '~/components/Switch';

import { COLOR_PALETTE } from '~/styles';

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: ${(props) => (props.$isBold ? 'bold' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
`;

const RowWithSwitch = ({ label, value, onChange, isBold }) => {
  return (
    <Row>
      <Label $isBold={isBold}>{label}</Label>
      <Switch onChange={onChange} checked={value} />
    </Row>
  );
};

RowWithSwitch.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  isBold: PropTypes.bool,
};

export default RowWithSwitch;
