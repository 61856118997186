import React, { useState, useEffect } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment/moment';
import styled from 'styled-components';

import OverviewHeading from '~/components/OverviewHeading';
import PaginationBar from '~/components/PaginationBar';
import BoxWithBorder from '~/components/UI/BoxWithBorder';
import Divider from '~/components/UI/Divider';
import TableCard, { TableRow, TableCol } from '~/components/UI/TableCard';
import BaseLayout from '~/layouts/BaseLayout';

import FiltersBar from './components/FiltersBar';

import { LOGS_TYPES, LOGS_CATEGORIES, LOGS_DESCRIPTIONS } from '~/constants';
import { getCompanyLogs } from '~/services/logs';
import { COLOR_PALETTE } from '~/styles';
import { getAdminValue } from '~/utils/logsUtils';

const PAGE_SIZE = 50;

const defaultPagination = {
  skip: 0,
  limit: PAGE_SIZE,
  index: 1,
};

const defaultFilters = {
  selectedAdmins: [],
  selectedTypes: [],
  selectedCategories: [],
  search: '',
};

const logsTypes = Object.values(LOGS_TYPES);
const logsDescriptions = Object.values(LOGS_DESCRIPTIONS);
const logsCategories = Object.values(LOGS_CATEGORIES);

const LogText = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: ${(props) => props.paddingRight};
  padding-left: ${(props) => props.paddingLeft};
  word-wrap: break-word;
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
`;

const formatDate = (date) => moment(date).format('DD-MM-YYYY HH:mm');

const AdminLogsPage = ({ i18n }) => {
  const [currentFilters, setCurrentFilters] = useState(defaultFilters);
  const [pagination, setPagination] = useState(defaultPagination);
  const [logs, setLogs] = useState({ data: [], totalCount: 0 });
  const [isLoading, setIsLoading] = useState(true);

  const applyFilters = async (i18n, filters, pagination = defaultPagination) => {
    const { skip, limit } = pagination;
    const { selectedAdmins, selectedTypes, selectedCategories, search } = filters;

    // Get logs count and results from backend
    const logsData = await getCompanyLogs({
      ...(!isEmpty(selectedCategories) && { categories: selectedCategories }),
      ...(!isEmpty(selectedTypes) && { types: selectedTypes }),
      ...(!isEmpty(selectedAdmins) && { admins: selectedAdmins.map((admin) => admin.id) }),
      ...(search && { search }),
      skip,
      limit,
      sort: { 'meta.createdDate': -1 },
    });

    // Find the description template by the log type and populate it
    const getConstantLabel = (collection, key, log) => {
      const result = collection.find((elem) => elem.key === key);
      return result ? result.label(i18n, log) : '';
    };

    const logsValues = Object.values(logsData.result).map((log) => ({
      ...log,
      date: formatDate((log.meta && log.meta.createdDate) || ''),
      admin: getAdminValue(i18n, log.createdBy),
      categoryValue: getConstantLabel(logsCategories, log.category),
      typeValue: getConstantLabel(logsTypes, log.type),
      description: getConstantLabel(logsDescriptions, log.type, log),
      item: log.target,
    }));

    setLogs({ data: logsValues, totalCount: logsData.count });
    setCurrentFilters(filters);
    setPagination(pagination);
    setIsLoading(false);
  };

  useEffect(() => {
    applyFilters(i18n, defaultFilters, defaultPagination);
  }, [i18n]);

  const onPageChangeClick = ({ skip, limit, index }) => {
    return applyFilters(i18n, currentFilters, { skip, limit, index });
  };

  const getCols = (i18n) => [
    { title: i18n._(t`Date`), width: '140px' },
    { title: i18n._(t`Admin`), width: '140px' },
    { title: i18n._(t`Category`), width: '91px' },
    { title: i18n._(t`Type`), width: '96px' },
    { title: i18n._(t`Item`), width: '200px' },
    { title: i18n._(t`Description`), width: '25%' },
  ];

  const renderRow = (log) => {
    return (
      <TableRow key={log.id} verticalAlign="top">
        <TableCol>
          <LogText paddingLeft="4px">{log.date}</LogText>
        </TableCol>
        <TableCol>
          <LogText paddingRight="24px">{log.admin}</LogText>
        </TableCol>
        <TableCol>
          <LogText>{log.categoryValue}</LogText>
        </TableCol>
        <TableCol>
          <LogText>{log.typeValue}</LogText>
        </TableCol>
        <TableCol>
          <LogText paddingRight="24px">{log.item}</LogText>
        </TableCol>
        <TableCol>
          <LogText paddingRight="4px">{log.description}</LogText>
        </TableCol>
      </TableRow>
    );
  };

  return (
    <>
      <OverviewHeading
        title={i18n._(t`Log`)}
        description={i18n._(
          t`Keep track of the manual or automatic (API) changes being made with your members`,
        )}
      />
      <BaseLayout>
        <BoxWithBorder $noBottomBorder>
          <FiltersBar
            filters={currentFilters}
            applyFilters={(filters) => applyFilters(i18n, filters)}
            disabled={isLoading}
          />
        </BoxWithBorder>

        <TableCard
          loading={isLoading}
          cols={getCols(i18n)}
          hideHeader
          items={logs.data}
          renderRow={renderRow}
          noTopBorder
          hideSearch
          noMarginBottom
          noBottomBorder
        />
        {logs.totalCount > PAGE_SIZE && (
          <>
            <Divider />
            <PaginationBar
              pagination={pagination}
              changePagination={onPageChangeClick}
              count={logs.totalCount}
              noTopBorder
              showCount
            />
          </>
        )}
      </BaseLayout>
    </>
  );
};

export default withI18n()(AdminLogsPage);
