export const generateChildStyles = (count: number) => {
  let styles = '';
  const defaultDiff = -185; // Replace with your actual value

  for (let i = 1; i <= count; i++) {
    styles += `
        &:nth-child(${i}) {
          top: ${defaultDiff * (i - 1)}px;
        }
      `;
  }

  return styles;
};
