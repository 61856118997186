import { GOAL_STATUSES } from '@learned/constants';

import routes from '~/constants/routes';

export default (goal, backUrl) => {
  if (goal.status === GOAL_STATUSES.DRAFT) {
    return routes.GOAL_UPDATE.build(
      {},
      {
        goalId: goal.id,
        isBackPath: true,
        backPathDefault: backUrl,
      },
    );
  }

  return routes.GOAL.build(
    {},
    {
      goalId: goal.id,
      isBackPath: true,
      backPathDefault: backUrl,
    },
  );
};
