import styled from 'styled-components';

import { SearchField } from '~/components/Text';

export const FirstRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
`;

export const RowLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const RowRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const SearchFieldWrapper = styled(SearchField)`
  height: 36px;
  margin-right: 12px;
  width: 100%;
`;

export const ItemWrapper = styled.div`
  margin-left: 20px;
`;
