import { QUESTION_TYPES } from '@learned/constants';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import { GOAL_TYPES } from '~/constants';

export const hasJobProfileQuestion = ({ templateItem }) =>
  hasQuestionWithType({ templateItem }, QUESTION_TYPES.JOB_PROFILE);

export const hasGoalBusinessEvalQuestion = ({ templateItem }) =>
  hasQuestionWithType({ templateItem }, QUESTION_TYPES.GOAL_BUSINESS_EVAL);

export const hasGoalLearningEvalQuestion = ({ templateItem }) =>
  hasQuestionWithType({ templateItem }, QUESTION_TYPES.GOAL_LEARNING_EVAL);

export const hasTemplateCoachesEnabled = ({ templateItem }) =>
  get(templateItem, 'sections', []).some((s) => s.isCoachesReview);

function isSectionHasQuestionType(section, questionType) {
  return section.questions.some((q) => q.type === questionType);
}

function hasQuestionWithType({ templateItem }, type) {
  if (!templateItem || !type) {
    return false;
  }

  return templateItem.sections.some((section) => isSectionHasQuestionType(section, type));
}

export function isSectionHasPlanQuestion(section) {
  return (
    isSectionHasQuestionType(section, QUESTION_TYPES.GOAL_BUSINESS_PLAN) ||
    isSectionHasQuestionType(section, QUESTION_TYPES.GOAL_LEARNING_PLAN)
  );
}

export function hasUserAnyJobProfile(user) {
  return (user.jobProfiles || []).length > 0;
}

export function didUserJobProfilesValid(user, selectedJP = []) {
  if (!hasUserAnyJobProfile(user)) {
    return false;
  }

  return (user.jobProfiles || [])
    .filter((profile) => selectedJP.includes(profile.id))
    .every((profile) => (profile.skills || []).length > 0);
}

export function didReviewUsersHaveValidJobProfiles(review) {
  if (!hasJobProfileQuestion(review)) {
    return true;
  }

  return (review.users || []).every((u) => {
    if (typeof u !== 'object') {
      return false;
    }
    const reviewJobProfiles = review?.jobProfilesPerUser || {};
    return didUserJobProfilesValid(u, reviewJobProfiles[u.id]);
  });
}

export function isJobProfileSelectedForEveryUser(review) {
  if (!hasJobProfileQuestion(review)) {
    return true;
  }
  // for published review
  const newUsers = !isEmpty(review.initReviewUsers)
    ? (review.users || []).filter((u) => !review.initReviewUsers.includes(u.id))
    : review.users || [];

  return newUsers.every((user) => {
    return !isEmpty(get(review, `jobProfilesPerUser.${user.id}`, []));
  });
}

export function goalCycleIdMapper(goalCycle) {
  return goalCycle.id;
}

export function isReviewUsersHaveGoalsBusinessEval(review) {
  if (!hasGoalBusinessEvalQuestion(review)) {
    return true;
  }
  return (review.users || []).every((user) => !isEmpty(getUserGoalsBusinessEval(user, review)));
}

export function isReviewUsersHaveGoalsLearningEval(review) {
  if (!hasGoalLearningEvalQuestion(review)) {
    return true;
  }
  return (review.users || []).every((user) => !isEmpty(getUserGoalsLearningEval(user, review)));
}

export function isReviewHaveValidGoalCyclesQuestion(review, type) {
  if (!hasQuestionWithType(review, type)) {
    return true;
  }

  return review[BRIGE_QUESTION_TYPE_TO_GOAL_CYCLE_KEY[type]].length > 0;
}

export function isValidGoalCycles(review) {
  return [
    isReviewHaveValidGoalCyclesQuestion(review, QUESTION_TYPES.GOAL_LEARNING_PLAN),
    isReviewHaveValidGoalCyclesQuestion(review, QUESTION_TYPES.GOAL_BUSINESS_PLAN),
    isReviewHaveValidGoalCyclesQuestion(review, QUESTION_TYPES.GOAL_BUSINESS_EVAL),
    isReviewHaveValidGoalCyclesQuestion(review, QUESTION_TYPES.GOAL_LEARNING_EVAL),
  ].every(Boolean);
}

const BRIGE_QUESTION_TYPE_TO_GOAL_CYCLE_KEY = {
  [QUESTION_TYPES.GOAL_BUSINESS_EVAL]: 'goalCyclesBusinessEval',
  [QUESTION_TYPES.GOAL_LEARNING_EVAL]: 'goalCyclesLearningEval',
  [QUESTION_TYPES.GOAL_BUSINESS_PLAN]: 'goalCyclesBusinessPlan',
  [QUESTION_TYPES.GOAL_LEARNING_PLAN]: 'goalCyclesLearningPlan',
};

const BRIGE_QUESTION_TYPE_TO_GOAL_TYPE = {
  [QUESTION_TYPES.GOAL_BUSINESS_EVAL]: GOAL_TYPES.BUSINESS.key,
  [QUESTION_TYPES.GOAL_LEARNING_EVAL]: GOAL_TYPES.PERSONAL.key,
  [QUESTION_TYPES.GOAL_BUSINESS_PLAN]: GOAL_TYPES.BUSINESS.key,
  [QUESTION_TYPES.GOAL_LEARNING_PLAN]: GOAL_TYPES.PERSONAL.key,
};

function getUserGoalsForCycle(user, review, questionType) {
  const userGoals = get(user, 'goals', []);
  if (isEmpty(userGoals)) {
    return [];
  }

  return userGoals
    .filter((g) => g.type === BRIGE_QUESTION_TYPE_TO_GOAL_TYPE[questionType]) // filter goal by type
    .filter((g) => {
      const goalCyclesIds = get(g, 'goalCycles', []);
      const reviewCyclesIds = get(
        review,
        BRIGE_QUESTION_TYPE_TO_GOAL_CYCLE_KEY[questionType],
        [],
      ).map((c) => c.id);
      if (isEmpty(goalCyclesIds) || isEmpty(reviewCyclesIds)) {
        return false;
      }
      return goalCyclesIds.some((gcId) => reviewCyclesIds.includes(gcId));
    });
}

export function getUserGoalsBusinessEval(user, review) {
  return getUserGoalsForCycle(user, review, QUESTION_TYPES.GOAL_BUSINESS_EVAL);
}

export function getUserGoalsLearningEval(user, review) {
  return getUserGoalsForCycle(user, review, QUESTION_TYPES.GOAL_LEARNING_EVAL);
}
export function getEvalGoalCycles(review) {
  return uniqBy(
    (review.goalCyclesBusinessEval || []).concat(review.goalCyclesLearningEval || []),
    goalCycleIdMapper,
  );
}

export function getEvalGoalCyclesIDs(review) {
  return getEvalGoalCycles(review).map(goalCycleIdMapper);
}

export const getSelectedCoaches = (
  reviewCoaches,
  selectedUsers = [],
  { isDelete, usersAvailable } = {},
) => {
  const selectedCoaches = {};

  selectedUsers.forEach((selectedUser) => {
    let reviewUserCoaches = get(reviewCoaches, `[${selectedUser.id}]`, []);
    let defaultUserCoaches = get(selectedUser, 'coaches', []);
    if (!isEmpty(usersAvailable)) {
      defaultUserCoaches = defaultUserCoaches.filter((u) => usersAvailable?.[u] !== undefined);
    }

    if (isDelete) {
      // do not change coaches of existed users
      selectedCoaches[selectedUser.id] = reviewUserCoaches;
    } else {
      // for new user add default (all) coaches
      // for old user
      const isNewUser = !reviewCoaches[selectedUser.id];

      selectedCoaches[selectedUser.id] = isNewUser
        ? defaultUserCoaches // add all coaches
        : reviewUserCoaches; // copy user coaches from review
    }
  });

  return selectedCoaches;
};

// filter out jobProfiles that user does not have anymore (before user might have it)
export const checkSelectedJobProfilesPerUser = (
  jobProfilesPerUser,
  selectedUsers = [],
  isDraft = false,
) => {
  const selectedJobProfilesPerUser = {};

  selectedUsers.forEach((selectedUser) => {
    const reviewUserJobProfiles = get(jobProfilesPerUser, `[${selectedUser.id}]`, []);
    if (isDraft && isEmpty(reviewUserJobProfiles) && !isEmpty(selectedUser?.jobProfiles)) {
      selectedJobProfilesPerUser[selectedUser.id] = selectedUser.jobProfiles.map((jp) => jp.id);
    } else {
      selectedJobProfilesPerUser[selectedUser.id] = reviewUserJobProfiles.filter((jpId) => {
        return (selectedUser.jobProfiles || []).map((jp) => jp.id).includes(jpId);
      });
    }
  });

  return selectedJobProfilesPerUser;
};

export const getReviewSkillCategories = ({ templateItem }) => {
  let skillCategories;
  templateItem.sections.map((s) =>
    s.questions.map((q) => {
      if (q.type === QUESTION_TYPES.JOB_PROFILE) {
        skillCategories = q.skillCategories;
      }
    }),
  );
  return skillCategories;
};
