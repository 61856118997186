import React, { useEffect, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import moment from 'moment';
import styled from 'styled-components';

import Button from '~/components/Button';
import ConversationsStatistics from '~/components/ConversationsStatistics';
import OverviewHeading from '~/components/OverviewHeading';
import PaginationBar from '~/components/PaginationBar';
import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';
import BoxWithBorder from '~/components/UI/BoxWithBorder';
import BaseLayout from '~/layouts/BaseLayout';

import ConversationFiltersRow from './components/ConversationFiltersRow';
import ConversationsTeamsTable from './components/ConversationsTeamsTable';

import ConversationIcon from '~/assets/main-menu-icons/chat-1.svg';

import { SORT_DIRECTION } from '~/constants';
import { INSTRUCTIONS } from '~/constants/instructions';
import useBoolState from '~/hooks/useBoolState';
import {
  getUserConversationsForGraph,
  getUserConversationsDataPerTeam,
  downloadAdminConversationReportCSV,
} from '~/services/userConversations';
import { COLOR_PALETTE, COLORS } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';

const Section = styled.div`
  margin-bottom: 24px;
`;

const TableHeader = styled.div`
  border-bottom: 1px solid #d8dfed;
  padding: 16px 24px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${COLORS.TEXT_BLACK};
`;

const sortOptions = [
  {
    label: (i18n) => i18n._(t`Team name`),
    id: 'name',
  },
  {
    label: (i18n) => i18n._(t`Member count`),
    id: 'members',
  },
  {
    label: (i18n) => i18n._(t`Total`),
    id: 'total',
  },
  {
    label: (i18n) => i18n._(t`Avg per member`),
    id: 'avgPerMember',
  },
];

const PAGE_SIZE = 25;

const defaultPagination = {
  skip: 0,
  limit: PAGE_SIZE,
  index: 1,
};

const defaultTableFilters = {
  startDate: '',
  endDate: '',
  selectedTeams: [],
  sortBy: ['name'],
  sortDirection: SORT_DIRECTION.ASC,
};

const defaultGraphFilters = {
  startDate: '',
  endDate: '',
  selectedTeams: [],
};

const StyledSvgIcon = styled(SvgIcon)`
  margin-top: 31px;
`;

function ConversationsReportPage({ i18n }) {
  const [graphData, setGraphData] = useState([]);
  const [graphFilters, setGraphFilters] = useState(defaultGraphFilters);
  const [tableData, setTableData] = useState([]);
  const [tableFilters, setTableFilters] = useState(defaultTableFilters);
  const [tablePagination, setTablePagination] = useState(defaultPagination);
  const [teamsCount, setTeamsCount] = useState(0);
  const $isApplyGraphFilters = useBoolState();
  const $isApplyTableFilters = useBoolState();
  const $loadingGraph = useBoolState();
  const $loadingTable = useBoolState();
  const $isTableFilterChanged = useBoolState();
  const $isGraphFilterChanged = useBoolState();

  useEffect(() => {
    const fetchData = async () => {
      $loadingGraph.on();
      $loadingTable.on();
      const graphConversationsData = await getUserConversationsForGraph({ isFirstDate: true });
      const tableConversationsData = await getUserConversationsDataPerTeam({
        sortField: tableFilters.sortBy[0],
        sortDirection: tableFilters.sortDirection,
        skip: tablePagination.skip,
        limit: tablePagination.limit,
        isTotal: true,
      });
      setGraphData(graphConversationsData.groupedConversations);
      setTableData(tableConversationsData.teamsConversations);
      setTeamsCount(tableConversationsData.teamsCount);
      $loadingGraph.off();
      $loadingTable.off();
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  // refetch graph data
  useEffect(() => {
    const fetchData = async () => {
      $loadingGraph.on();
      let teamsMembers = [];
      graphFilters.selectedTeams.map((t) => {
        teamsMembers = teamsMembers.concat(t.members);
        return t;
      });

      const uniqMembes = uniq(teamsMembers);
      const graphConversationsData = await getUserConversationsForGraph({
        startDate: graphFilters.startDate
          ? moment(graphFilters.startDate).format('YYYY-MM-DD hh:mm')
          : '',
        endDate: graphFilters.endDate
          ? moment(graphFilters.endDate).format('YYYY-MM-DD hh:mm')
          : '',
        teamsMembers: uniqMembes,
      });
      setGraphData(graphConversationsData.groupedConversations);
      $isApplyGraphFilters.off();
      $isGraphFilterChanged.off();
      $loadingGraph.off();
    };
    if ($isApplyGraphFilters.value) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [$isApplyGraphFilters]);

  // refetch table data
  useEffect(() => {
    const fetchData = async () => {
      $loadingTable.on();
      const teamsIds = tableFilters.selectedTeams.map((t) => t.id);
      const tableConversationsData = await getUserConversationsDataPerTeam({
        startDate: tableFilters.startDate
          ? moment(tableFilters.startDate).format('YYYY-MM-DD hh:mm')
          : '',
        endDate: tableFilters.endDate
          ? moment(tableFilters.endDate).format('YYYY-MM-DD hh:mm')
          : '',
        teamsIds,
        sortField: tableFilters.sortBy[0],
        sortDirection: tableFilters.sortDirection,
        skip: tablePagination.skip,
        limit: tablePagination.limit,
        isTotal: true,
      });
      setTableData(tableConversationsData.teamsConversations);
      setTeamsCount(tableConversationsData.teamsCount);
      $isApplyTableFilters.off();
      $isTableFilterChanged.off();
      $loadingTable.off();
    };
    if ($isApplyTableFilters.value) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [$isApplyTableFilters]);

  const onPageChangeClick = ({ skip, limit, index }) => {
    $loadingTable.on();
    setTablePagination({ skip, limit, index });
    $isApplyTableFilters.on();
  };

  const downloadCSV = async () => {
    const teamsIds = tableFilters.selectedTeams.map((t) => t.id);
    await downloadAdminConversationReportCSV({
      startDate: tableFilters.startDate,
      endDate: tableFilters.endDate,
      teamsIds,
    });
  };

  return (
    <>
      <OverviewHeading
        title={i18n._(t`1:1 Conversations`)}
        useSearch={false}
        description={i18n._(t`Keep track of the conversations being conducted`)}
        instructions={i18n._(t`How the conversation report works`)}
        instructionsUrl={getInstructionUrl(INSTRUCTIONS.HOW_CONVERSATION_REPORT_WORKS)}
      >
        <Button label={i18n._(t`Export CSV`)} onClick={downloadCSV} />
      </OverviewHeading>
      <BaseLayout>
        <Section>
          <BoxWithBorder>
            <ShowSpinnerIfLoading loading={$loadingGraph.value}>
              <ConversationFiltersRow
                dateFrom={graphFilters.startDate}
                dateTo={graphFilters.endDate}
                handleFrom={(value) => {
                  setGraphFilters({ ...graphFilters, startDate: value });
                  $isGraphFilterChanged.on();
                }}
                handleTo={(value) => {
                  setGraphFilters({ ...graphFilters, endDate: value });
                  $isGraphFilterChanged.on();
                }}
                applyFilters={$isApplyGraphFilters.on}
                handleTeams={(value) => {
                  setGraphFilters({ ...graphFilters, selectedTeams: value });
                  $isGraphFilterChanged.on();
                }}
                selectedTeams={graphFilters.selectedTeams}
                filtersChanged={$isGraphFilterChanged.value}
              />
              {isEmpty(graphData) ? (
                <Placeholder
                  title={i18n._(t`No data available yet`)}
                  Icon={() => (
                    <StyledSvgIcon
                      url={ConversationIcon}
                      width={'32px'}
                      height={'32px'}
                      isDefaultColor
                      defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                    />
                  )}
                  subTitleStyles={{
                    width: '435px',
                    color: COLOR_PALETTE.DARK_GRAY,
                    fontWeight: 600,
                    lineHeight: 1.57,
                  }}
                />
              ) : (
                <ConversationsStatistics graphData={graphData} />
              )}
            </ShowSpinnerIfLoading>
          </BoxWithBorder>
        </Section>
        <Section>
          <BoxWithBorder>
            <ShowSpinnerIfLoading loading={$loadingTable.value}>
              {isEmpty(tableData) ? (
                <Placeholder
                  title={i18n._(t`No data available yet`)}
                  Icon={() => (
                    <SvgIcon
                      url={ConversationIcon}
                      width={'50px'}
                      height={'50px'}
                      isDefaultColor
                      defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                    />
                  )}
                  subTitleStyles={{
                    width: '435px',
                    color: COLOR_PALETTE.DARK_GRAY,
                    fontWeight: 600,
                    lineHeight: 1.57,
                  }}
                />
              ) : (
                <>
                  <TableHeader>
                    <Trans>Per team</Trans>
                  </TableHeader>
                  <ConversationFiltersRow
                    dateFrom={tableFilters.startDate}
                    dateTo={tableFilters.endDate}
                    handleFrom={(value) => {
                      setTableFilters({ ...tableFilters, startDate: value });
                      $isTableFilterChanged.on();
                    }}
                    handleTo={(value) => {
                      setTableFilters({ ...tableFilters, endDate: value });
                      $isTableFilterChanged.on();
                    }}
                    applyFilters={$isApplyTableFilters.on}
                    handleTeams={(value) => {
                      setTableFilters({ ...tableFilters, selectedTeams: value });
                      $isTableFilterChanged.on();
                    }}
                    selectedTeams={tableFilters.selectedTeams}
                    sortBy={tableFilters.sortBy}
                    sortOptions={sortOptions}
                    handleSortBy={(value) => {
                      setTableFilters({ ...tableFilters, sortBy: value });
                      $isTableFilterChanged.on();
                    }}
                    sortDirection={tableFilters.sortDirection}
                    handleSortDirection={() => {
                      const newSortDirection =
                        tableFilters.sortDirection === SORT_DIRECTION.ASC
                          ? SORT_DIRECTION.DESC
                          : SORT_DIRECTION.ASC;
                      setTableFilters({ ...tableFilters, sortDirection: newSortDirection });
                      $isTableFilterChanged.on();
                    }}
                    filtersChanged={$isTableFilterChanged.value}
                  />
                  <ConversationsTeamsTable tableData={tableData} />
                  <PaginationBar
                    pagination={tablePagination}
                    changePagination={onPageChangeClick}
                    count={teamsCount || 0}
                    showCount
                    noTopBorder
                  />
                </>
              )}
            </ShowSpinnerIfLoading>
          </BoxWithBorder>
        </Section>
      </BaseLayout>
    </>
  );
}

export default withI18n()(ConversationsReportPage);
