import React, { useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';
import PeopleIcon from '~/components/Icons/People';
import Placeholder from '~/components/Placeholder';
import { SearchField } from '~/components/Text';

import Item from './components/Item';

import { COLOR_PALETTE, COLORS } from '~/styles';

const ContentMainWrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400px;
`;

const ContentWrapper = styled.div`
  max-height: 385px;
  overflow: auto;
`;

const SelectAll = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px 24px 12px;
  cursor: pointer;
  height: 20px;
`;

const SelectAllLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const ModalFilters = styled.div`
  display: flex;
  margin: 12px 0 0;
  border-bottom: 1px solid ${COLORS.BORDER_LIGHT};
  padding: 0 12px 12px;
`;

const SearchFieldWrapper = styled(SearchField)`
  height: 36px;
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
`;

const SelectItems = ({
  singleChoice = false,
  items,
  selectedItems,
  updateSelectedItems,
  disabled,
  i18n,
  emptySetPlaceholder,
}) => {
  const [search, setSearch] = useState('');

  const selectAll = (allItems) => updateSelectedItems(allItems);

  const deselectAll = () => updateSelectedItems([]);

  const selectItem = (item) => {
    // single choice
    if (singleChoice) {
      updateSelectedItems([item]);
      return;
    }

    // multiple choice
    let newSelectedItems = [];
    if (!isEmpty(selectedItems)) {
      newSelectedItems = [...selectedItems];
    }

    let index = newSelectedItems.map((u) => u.id).indexOf(item.id);
    if (index !== -1) {
      newSelectedItems = newSelectedItems.filter((_, i) => i !== index);
    } else {
      newSelectedItems.push(item);
    }

    updateSelectedItems(newSelectedItems);
  };

  const isItemSelected = (item) => {
    if (isEmpty(selectedItems)) {
      return false;
    }

    const isSelected = selectedItems.find((elem) => elem.id === item.id);
    return !!isSelected;
  };

  const renderItem = (item) => {
    const isSelected = isItemSelected(item);

    return (
      item && (
        <Item
          key={item.id}
          item={item}
          selected={isSelected}
          onSelect={selectItem}
          disabled={disabled}
          singleChoice={singleChoice}
        />
      )
    );
  };

  let filteredItems = items;

  // search
  if (search) {
    filteredItems = filteredItems.filter(
      (item) => item.label && item.label.toLowerCase().includes(search.toLowerCase()),
    );
  }

  return (
    <ContentMainWrap>
      <ModalFilters>
        <SearchFieldWrapper
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          placeholder={i18n._(t`Search`)}
        />
      </ModalFilters>

      {/* Do not display select all row for single choice */}
      {!singleChoice && filteredItems.length > 0 && (
        <SelectAll
          onClick={() =>
            selectedItems.length === filteredItems.length
              ? deselectAll(filteredItems)
              : selectAll(filteredItems)
          }
        >
          <SelectAllLabel>
            <Trans>Select all</Trans>
          </SelectAllLabel>
          <CheckBox checked={selectedItems.length === filteredItems.length} size={'24px'} />
        </SelectAll>
      )}

      <ContentWrapper>
        {!isEmpty(filteredItems) ? (
          <div>{filteredItems.map(renderItem)}</div>
        ) : (
          <Placeholder
            title={emptySetPlaceholder || i18n._(t`No items available`)}
            Icon={PeopleIcon}
          />
        )}
      </ContentWrapper>
    </ContentMainWrap>
  );
};

export default withI18n()(SelectItems);
