import React from 'react';

import { Trans } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import RickTextView from '~/components/RickTextView';
import AvatarCard from '~/components/UI/AvatarCard';

import { COLORS, COLOR_PALETTE } from '~/styles';
import convertToTimeString from '~/utils/convertToTimeString';
import getUserFullName from '~/utils/getUserFullName';

const Wrapper = styled.div`
  border-radius: 6px;
  box-shadow: 1px 1px 2px 0 rgba(25, 42, 70, 0.08);
  border: solid 1px #e4e8ef;
  background: ${COLOR_PALETTE.WHITE};
`;

const Title = styled.div`
  color: ${COLORS.TEXT_BLACK};
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
`;

const Description = styled.div`
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  margin-top: 12px;
`;

const Top = styled.div`
  padding: 24px 20px 12px;
`;

const Bottom = styled.div`
  padding: 12px 20px 24px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #d8dfed;
`;

const ReviewInfoSection = styled.div`
  display: flex;
  flex-direction: row;

  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const UserAvatar = styled.div`
  margin-top: 12px;
`;

const Uppercase = styled.div`
  text-transform: uppercase;
`;

const CreatedDateContainer = styled.div`
  width: 100px;
`;

const DateContainer = styled.div`
  font-weight: 600;
  line-height: 1.57;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin-top: 12px;
`;

const CoachesContainer = styled.div`
  margin-left: 54px;
`;

const CoachAvatars = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

const CoachAvatarCard = styled(AvatarCard)`
  margin-right: 12px;
`;

const RenderCoaches = (review, users) => {
  return (
    <CoachesContainer>
      <Uppercase>
        <Trans>Review coach(es)</Trans>
      </Uppercase>
      <CoachAvatars>
        {(review.coaches || []).map((coach) => {
          return (
            <CoachAvatarCard
              key={coach}
              userId={coach}
              type={AvatarCard.TYPES.SMALL}
              userFullname={getUserFullName(users[coach])}
              userAvatar={users[coach]?.avatarUrl}
            />
          );
        })}
      </CoachAvatars>
    </CoachesContainer>
  );
};

const RenderConversationCoaches = (review, users) => {
  return (
    <CoachesContainer>
      <Uppercase>
        <Trans>Participant(s)</Trans>
      </Uppercase>
      <CoachAvatars>
        {(review.conversationCoaches || []).map((coach) => {
          return (
            <CoachAvatarCard
              key={coach}
              userId={coach}
              type={AvatarCard.TYPES.SMALL}
              userFullname={getUserFullName(users[coach])}
              userAvatar={users[coach]?.avatarUrl}
            />
          );
        })}
      </CoachAvatars>
    </CoachesContainer>
  );
};

const Header = ({ review, users, userId }) => {
  let userName;
  const isYourReview = review?.createdFor && review?.createdFor === userId;
  if (!isEmpty(review) && review?.createdFor && users[review.createdFor]) {
    userName = getUserFullName(users[review.createdFor]);
  }
  return (
    <Wrapper>
      <Top>
        <Title>
          {isYourReview ? (
            <>
              <Trans>Your</Trans> {review.name}
            </>
          ) : !isEmpty(userName) ? (
            <>
              {userName}
              <Trans>&apos;s</Trans> {review.name}
            </>
          ) : (
            review.name
          )}
        </Title>
        {review.description && (
          <Description>
            <RickTextView html={review.description} />
          </Description>
        )}
      </Top>
      <Divider />
      <Bottom>
        <ReviewInfoSection>
          <div>
            <Uppercase>
              <Trans>Participant</Trans>
            </Uppercase>
            <UserAvatar>
              <AvatarCard
                userId={review.createdFor}
                type={AvatarCard.TYPES.SMALL}
                userFullname={getUserFullName(users[review.createdFor])}
                userAvatar={users[review.createdFor]?.avatarUrl}
              />
            </UserAvatar>
          </div>
          {!isEmpty(review.coaches) && RenderCoaches(review, users)}
          {!isEmpty(review.conversationCoaches) && RenderConversationCoaches(review, users)}
          <div style={{ flex: 1 }} />
          <CreatedDateContainer>
            <Uppercase>
              <Trans>Created at</Trans>
            </Uppercase>
            <DateContainer>
              {review && review.meta && convertToTimeString(review.meta.createdDate)}
            </DateContainer>
          </CreatedDateContainer>
        </ReviewInfoSection>
      </Bottom>
    </Wrapper>
  );
};

export default Header;
