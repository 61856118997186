import { NOTIFICATION_PREFERENCES } from '@learned/constants';
import { t } from '@lingui/macro';

import type { I18n } from '@lingui/core';

export const NOTIFICATION_SETTINGS = {
  [NOTIFICATION_PREFERENCES.reviews]: {
    title: (i18n: I18n) => i18n._(t`Reviews`),
  },
  [NOTIFICATION_PREFERENCES.conversations]: {
    title: (i18n: I18n) => i18n._(t`1:1 and Team meetings`),
  },
  [NOTIFICATION_PREFERENCES.feedback]: {
    title: (i18n: I18n) => i18n._(t`Feedback`),
  },
  [NOTIFICATION_PREFERENCES.career]: {
    title: (i18n: I18n) => i18n._(t`Jobs and career`),
  },
  [NOTIFICATION_PREFERENCES.development]: {
    title: (i18n: I18n) => i18n._(t`Your development plan`),
  },
  [NOTIFICATION_PREFERENCES.goals]: {
    title: (i18n: I18n) => i18n._(t`Business goals`),
  },
  [NOTIFICATION_PREFERENCES.surveys]: {
    title: (i18n: I18n) => i18n._(t`Surveys`),
  },
  [NOTIFICATION_PREFERENCES.teams]: {
    title: (i18n: I18n) => i18n._(t`Your team members`),
  },
};
