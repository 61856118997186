import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import styled, { css } from 'styled-components';

import Button from '~/components/Button';
import CheckBox from '~/components/CheckBox';
import { confirm } from '~/components/ConfirmDialog';
import Modal from '~/components/Modal';
import Radio from '~/components/Radio';

import { INSTRUCTIONS } from '~/constants/instructions';
import { ILanguage } from '~/constants/languages';
import { COLORS } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';

export interface ILanguageSelectModalProps {
  onClose: () => void;
  // eslint-disable-next-line
  onSave: ((arg0: ILanguage) => void) | ((arg0: ILanguage[]) => void);
  singleSelect: boolean;
  languages: ILanguage[];
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SaveButton = styled(Button)`
  margin-left: 12px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;
`;

const LanguageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4px;
  align-items: center;
`;

const StyledRadio = styled(Radio)`
  margin-right: 4px;
`;

const StyledCheckBox = styled(CheckBox)`
  margin-right: 4px;
`;

const LanguageText = styled.div<{ $isSelected?: boolean }>`
  height: 26px;
  font-family: Poppins;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;

  ${({ $isSelected }) =>
    $isSelected
      ? css`
          color: var(--company-color);
          font-weight: 600;
        `
      : css`
          color: ${COLORS.BLACK};
          font-weight: normal;
        `}
`;

// eslint-disable-next-line
const LanguageSelectModal = ({
  onClose,
  onSave,
  singleSelect,
  languages,
}: ILanguageSelectModalProps) => {
  const [selected, setSelected] = useState<ILanguage[]>([]);
  const { i18n } = useLingui();
  const save = async () => {
    if (
      await confirm(
        i18n,
        i18n._(t`You're about to add ${selected.length} languages to the company.`),
        {
          link: getInstructionUrl(
            INSTRUCTIONS.HOW_TO_SETUP_MULTIPLE_LANGUAGES_WITHIN_COMPANY_ACCOUNT,
          ),
        },
      )
    ) {
      if (onSave) {
        // @ts-ignore
        onSave(singleSelect ? selected[0] : selected);
      }
      onClose();
    }
  };
  return (
    <Modal
      title={i18n._(t`Select language(s)`)}
      onClose={onClose}
      minWidth={666}
      width={666}
      footerRight={
        <ButtonContainer>
          <Button label={i18n._(t`Cancel`)} onClick={onClose} type="link-primary" />
          <SaveButton
            disabled={!selected.length}
            label={i18n._(t`Add language (${selected.length})`)}
            onClick={save}
            type="primary-border"
          />
        </ButtonContainer>
      }
    >
      <Wrapper>
        {languages.map((lang) => {
          const checked = !!selected.find((s) => s.locale === lang.locale);
          return (
            <LanguageWrapper key={lang.locale}>
              {singleSelect ? (
                // @ts-ignore
                <StyledRadio checked={checked} onChange={() => setSelected([lang])} />
              ) : (
                // @ts-ignore
                <StyledCheckBox
                  checked={checked}
                  onChange={() =>
                    setSelected(
                      checked
                        ? selected.filter((i) => i.locale !== lang.locale)
                        : [...selected, lang],
                    )
                  }
                />
              )}
              <LanguageText $isSelected={checked}>
                {`${getUnicodeFlagIcon(lang.locale.substring(lang.locale.indexOf('_') + 1))} ${
                  lang.language
                }`}
              </LanguageText>
            </LanguageWrapper>
          );
        })}
      </Wrapper>
    </Modal>
  );
};

export { LanguageSelectModal };
