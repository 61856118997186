import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getComment(id) {
  return cloudRequest(serverRoutes.comments.getComment, { id });
}

export function createComment(data) {
  return cloudRequest(serverRoutes.comments.createComment, {}, data);
}

export function deleteComment(id) {
  return cloudRequest(serverRoutes.comments.deleteComment, { id });
}

export function updateComment(id, comment) {
  return cloudRequest(serverRoutes.comments.updateComment, { id }, { comment });
}
