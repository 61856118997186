import React, { useRef, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';

import {
  AutocompleteFilterActivityShowEditors,
  AutocompleteFilterActivitySources,
  AutocompleteFilterActivityTypes,
  AutocompleteFilterLearningLibraryCollections,
  AutocompleteFilterLearningLibrarySort,
  AutocompleteFilterRelevancies,
  AutocompleteFilterRoles,
  AutocompleteFilterSkills,
} from '~/components/AutocompleteFilters';
import { AutocompleteFilterActivityCategories } from '~/components/AutocompleteFilters/Activity';
import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { ManageActivityCategoriesModal } from '~/components/Modals/ManageActivityCategoriesModal';

import type { IFilterType } from './types';
import type { IActivityCategory } from '@learned/types';

const StyledAutocompleteFilterActivityCategories = styled(AutocompleteFilterActivityCategories)`
  min-width: 150px;
  width: unset;
`;

interface IFilters {
  currentFilters: IFilterType;
  changeFilters: (filters: any) => void;
  resetFilters: (filters: any) => void;
  isLearningActivities: boolean;
  refetchData: () => void;
  isAdmin: boolean;
}

const Filters = ({
  currentFilters,
  changeFilters,
  resetFilters,
  isLearningActivities,
  refetchData,
  isAdmin,
}: IFilters) => {
  const { i18n } = useLingui();
  const [isManageActivityCategoriesModalVisible, setIsManageActivityCategoriesModalVisible] =
    useState<boolean>(false);
  const ref = useRef<{ reFetch: () => void }>(null);

  return (
    <>
      <AutocompleteFilterActivityTypes
        onChange={(e) => changeFilters({ activityTypes: e })}
        checkedList={currentFilters.activityTypes}
      />
      <AutocompleteFilterSkills
        onChange={(e) => changeFilters({ skills: e })}
        checkedList={currentFilters.skills}
      />
      <AutocompleteFilterRoles
        onChange={(e) => changeFilters({ roles: e })}
        checkedList={currentFilters.roles}
      />
      <StyledAutocompleteFilterActivityCategories
        ref={ref}
        onChange={(e: IActivityCategory[]) => changeFilters({ activityCategories: e })}
        selectedCategories={currentFilters.activityCategories ?? []}
        action={isAdmin ? () => setIsManageActivityCategoriesModalVisible(true) : undefined}
      />
      {isLearningActivities && (
        <AutocompleteFilterActivitySources
          onChange={(e) => changeFilters({ activitySources: e })}
          checkedList={currentFilters.activitySources}
        />
      )}
      <AutocompleteFilterActivityShowEditors
        onChange={(e) => changeFilters({ activityShowEditors: e ? [e] : [] })} // !important put e in array for isSingleSelect
        checkedList={currentFilters.activityShowEditors}
        isSingleSelect
      />
      <AutocompleteFilterLearningLibraryCollections
        onChange={(e) => changeFilters({ collections: e })}
        checkedList={currentFilters.collections}
      />
      <AutocompleteFilterLearningLibrarySort
        onChange={(e) => changeFilters({ sortBy: e ? [e] : [] })} // !important put e in array for isSingleSelect
        checkedList={currentFilters.sortBy}
        isSingleSelect
        isDeleteDisabled
      />
      {!isEmpty(currentFilters.roles) && (
        <AutocompleteFilterRelevancies
          onChange={(e) => changeFilters({ rolesRelevancies: e })}
          checkedList={currentFilters.rolesRelevancies}
        />
      )}
      {isManageActivityCategoriesModalVisible && (
        <ManageActivityCategoriesModal
          title={i18n._(t`Manage categories`)}
          onClose={() => {
            setIsManageActivityCategoriesModalVisible(false);
            refetchData();
            ref?.current?.reFetch();
          }}
        />
      )}
      <Button
        variant={ButtonVariant.SECONDARY}
        size={ButtonSize.MEDIUM}
        label={i18n._(t`Reset all filters`)}
        onClick={resetFilters}
        icon={ICONS.CLOSE}
      />
    </>
  );
};

export { Filters };
