import React, { PureComponent } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import Modal from '~/components/Modal';
import SelectUsers from '~/components/SelectUsers';
import { ErrorText } from '~/components/UI/Typographics/system';

const INITIAL_STATE = {
  selectedItems: [],
};

class SelectUsersModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: props.selectedUsers || [],
    };
  }

  static propTypes = {
    items: PropTypes.array,
    onSubmit: PropTypes.func,
    onModalClose: PropTypes.func,
    loading: PropTypes.bool,
    searchCoaches: PropTypes.bool,
    selectedUsers: PropTypes.array,
  };

  static defaultProps = {
    searchCoaches: false,
  };

  closeModal = () => {
    this.setState({ ...INITIAL_STATE });
    this.props.onModalClose();
  };

  onSelectedItemsUpdated = (newSelectedItems) => {
    this.setState({
      selectedItems: newSelectedItems,
    });
  };

  handleSubmit = () => this.props.onSubmit(this.state.selectedItems);

  render() {
    const { selectedItems } = this.state;
    const {
      i18n,
      title,
      items,
      loading,
      singleChoice,
      submitLabel,
      errorText,
      searchCoaches,
      hideDisabledUsers,
    } = this.props;

    const labelText = () => {
      if (!isEmpty(selectedItems)) {
        return i18n._(t`Add ${selectedItems.length} participant(s)`);
      }
      return i18n._(t`Select`);
    };

    return (
      <Modal
        title={title}
        onClose={this.closeModal}
        width={784}
        minWidth={784}
        minHeight="400px"
        contentStyles={{ background: '#fff', height: '100%', padding: '0' }}
        headerStyles={{ padding: '0 24px' }}
        centerModal
        footerLeft={
          errorText && (
            <ErrorText>
              <Trans>{errorText}</Trans>
            </ErrorText>
          )
        }
        footerRight={
          <Button
            styles={{
              height: '40px',
              marginLeft: '10px',
              textTransform: 'uppercase',
              padding: '0px 15px',
              fontSize: '12px',
              minWidth: '120px',
            }}
            disabled={loading || isEmpty(selectedItems)}
            onClick={this.handleSubmit}
            loading={loading}
            label={submitLabel || labelText()}
            type="primary"
          />
        }
      >
        <SelectUsers
          isSelectMode={true}
          singleChoice={singleChoice}
          users={items}
          selectedUsers={selectedItems}
          updateSelectedUsers={this.onSelectedItemsUpdated}
          hideTeamFilter
          searchCoaches={searchCoaches}
          hideDisabledUsers={hideDisabledUsers}
        />
      </Modal>
    );
  }
}

export default withI18n()(SelectUsersModal);
