import {
  REVIEW_QUESTION_TYPES,
  REVIEW_QUESTION_TYPES_V1,
  TASK_STATUS,
  USER_REVIEW_STATUS,
} from '@learned/constants';

import type { UserReview } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/hooks/useReviewRatings';

import { AnswerType } from './types';

import type {
  IJobProfile,
  IMultiLangString,
  IReviewRating,
  ITask,
  IUser,
  IUserReview,
  IUserReviewQuestionCustomSkill,
  IUserReviewQuestionCustomSkillV1,
  IUserReviewQuestionCustomSkillWithSkill,
  IUserReviewQuestionSkillCategory,
  IUserReviewQuestionSkillCategoryV1,
  IUserReviewQuestionSkillCategoryWithJobProfile,
} from '@learned/types';

export type IUserReviewQuestionSkillCategoryGrouped = {
  type: REVIEW_QUESTION_TYPES.SKILL_CATEGORY;
  skillCategory: string;
  name: IMultiLangString;
  description?: IMultiLangString;
  theme: string;
  jobProfiles?: IJobProfile[];
  skills: {
    skillId: string;
    questions: (IUserReviewQuestionSkillCategoryWithJobProfile & {
      reviewRatings?: IReviewRating[];
    })[];
  }[];
};

export type IUserReviewQuestionCustomSkillGrouped = {
  type: REVIEW_QUESTION_TYPES.CUSTOM_SKILL;
  skillCategory: string;
  skillId: string;
  name: IMultiLangString;
  description?: IMultiLangString;
  theme: string;

  skills: {
    skillId: string;
    questions: (IUserReviewQuestionCustomSkillWithSkill & { reviewRatings?: IReviewRating[] })[];
  }[];
};

export type IUserReviewQuestionSkillCategoryV1Grouped = {
  type: REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1;
  skillCategory: string;
  jobProfileId: string;
  name: IMultiLangString;
  description?: IMultiLangString;
  theme: string;

  skills: {
    skillId: string;
    questions: (IUserReviewQuestionSkillCategoryWithJobProfile & {
      reviewRatings?: IReviewRating[];
    })[];
  }[];
};

export type IUserReviewQuestionCustomSkillV1Grouped = {
  type: REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1;
  skillCategory: string;
  skillId: string;
  name: IMultiLangString;
  description?: IMultiLangString;
  theme: string;

  skills: {
    skillId: string;
    questions: (IUserReviewQuestionCustomSkillWithSkill & { reviewRatings?: IReviewRating[] })[];
  }[];
};

export function groupQuestionsBasedOnType(userReview: UserReview) {
  const groupedSkillCategoryQuestions = userReview.userReviewQuestions.reduce(
    (
      acc: Record<
        string,
        Record<
          string,
          Record<
            string,
            {
              questions: (IUserReviewQuestionSkillCategory & { reviewRatings?: IReviewRating[] })[];
              skillId: string;
            }
          >
        >
      >,
      question,
    ) => {
      if (!question || question.type !== REVIEW_QUESTION_TYPES.SKILL_CATEGORY) {
        return acc;
      }
      const skillCategory = question.settings.skillCategory;
      // @ts-ignore
      const skill: string = question.settings.skill.id;
      const jobProfile: string = (question.settings?.jobProfile as unknown as IJobProfile)?.id;

      acc[skillCategory] = acc[skillCategory] ?? {};
      acc[skillCategory][jobProfile] = acc[skillCategory][jobProfile] ?? {};
      acc[skillCategory][jobProfile][skill] = acc[skillCategory][jobProfile][skill] ?? {
        skillId: skill,
        questions: [],
      };
      acc[skillCategory][jobProfile][skill].questions.push(question);
      return acc;
    },
    {},
  );

  const groupedCustomSkillQuestions = userReview.userReviewQuestions.reduce(
    (
      acc: Record<
        string,
        Record<
          string,
          {
            questions: (IUserReviewQuestionCustomSkill & { reviewRatings?: IReviewRating[] })[];
            skillId: string;
          }
        >
      >,
      question,
    ) => {
      if (!question || question.type !== REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
        return acc;
      }
      // as key we use skill (we can not use skill-category for first key - because every custom skill separately, even if from same skill category
      // @ts-ignore
      const key = question.settings.skill.id;
      acc[key] = acc[key] ?? {};
      acc[key][key] = acc[key][key] ?? {
        skillId: key,
        questions: [],
      };
      acc[key][key].questions.push(question);
      return acc;
    },
    {},
  );

  const groupedSkillCategoryV1Questions = userReview.userReviewQuestions.reduce(
    (
      acc: Record<
        string,
        Record<
          string,
          {
            questions: (IUserReviewQuestionSkillCategoryV1 & { reviewRatings?: IReviewRating[] })[];
            skillId: string;
          }
        >
      >,
      question,
    ) => {
      if (!question || question.type !== REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1) {
        return acc;
      }
      // @ts-ignore
      const key = question.settings.jobProfile.id;
      // @ts-ignore
      const skill = question.settings.skill?.id;
      acc[key] = acc[key] ?? {};
      acc[key][skill] = acc[key][skill] ?? {
        skillId: skill,
        questions: [],
      };
      acc[key][skill].questions.push(question);
      return acc;
    },
    {},
  );

  const groupedCustomSkillV1Questions = userReview.userReviewQuestions.reduce(
    (
      acc: Record<
        string,
        Record<
          string,
          {
            questions: (IUserReviewQuestionCustomSkillV1 & { reviewRatings?: IReviewRating[] })[];
            skillId: string;
          }
        >
      >,
      question,
    ) => {
      if (!question || question.type !== REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1) {
        return acc;
      }
      // as key we use skill (we can not use skill-category for first key - because every custom skill separately, even if from same skill category
      // @ts-ignore
      const key = question.settings.skill.id;
      acc[key] = acc[key] ?? {};
      acc[key][key] = acc[key][key] ?? {
        skillId: key,
        questions: [],
      };
      acc[key][key].questions.push(question);
      return acc;
    },
    {},
  );

  const seenSkillCategoriesForSkillCategory = new Set();
  const seenSkillCategoriesForCustomSkill = new Set();
  const seenSkillCategoriesForSkillCategoryV1 = new Set();
  const seenSkillCategoriesForCustomSkillV1 = new Set();

  const result = userReview.userReviewQuestions.reduce(
    (
      acc: (
        | IUserReviewQuestionSkillCategoryGrouped
        | IUserReviewQuestionCustomSkillGrouped
        | IUserReviewQuestionSkillCategoryV1Grouped
        | IUserReviewQuestionCustomSkillV1Grouped
      )[],
      question,
    ) => {
      switch (question.type) {
        case REVIEW_QUESTION_TYPES.SKILL_CATEGORY: {
          if (seenSkillCategoriesForSkillCategory.has(question.settings.skillCategory)) {
            return acc;
          }
          seenSkillCategoriesForSkillCategory.add(question.settings.skillCategory);
          acc.push({
            type: REVIEW_QUESTION_TYPES.SKILL_CATEGORY,
            skillCategory: question.settings.skillCategory,
            skills: Object.values(
              groupedSkillCategoryQuestions[question.settings.skillCategory][
                (question.settings.jobProfile as unknown as IJobProfile).id
              ],
            ),
            jobProfiles: Object.keys(
              groupedSkillCategoryQuestions[question.settings.skillCategory],
            ).map((jobProfileId) => {
              const groupedByJobProfiles =
                groupedSkillCategoryQuestions[question.settings.skillCategory][jobProfileId];

              const skills = Object.values(groupedByJobProfiles);

              return skills?.[0].questions?.[0]?.settings?.jobProfile as unknown as IJobProfile;
            }),
            name: question.name,
            description: question.description,
            theme: question.theme,
          });
          return acc;
        }
        case REVIEW_QUESTION_TYPES.CUSTOM_SKILL: {
          // @ts-ignore
          const key = question.settings.skill.id;
          if (seenSkillCategoriesForCustomSkill.has(key)) {
            return acc;
          }
          seenSkillCategoriesForCustomSkill.add(key);
          acc.push({
            type: REVIEW_QUESTION_TYPES.CUSTOM_SKILL,
            skillId: key,
            skillCategory: question.settings.skillCategory,
            skills: Object.values(groupedCustomSkillQuestions[key]),
            name: question.name,
            description: question.description,
            theme: question.theme,
          });
          return acc;
        }

        case REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1: {
          // @ts-ignore
          const key = question.settings.jobProfile.id;
          if (seenSkillCategoriesForSkillCategoryV1.has(key)) {
            return acc;
          }
          seenSkillCategoriesForSkillCategoryV1.add(key);
          acc.push({
            type: REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1,
            skillCategory: question.settings.skillCategory,
            // @ts-ignore
            jobProfileId: question.settings.jobProfile.id,
            skills: Object.values(groupedSkillCategoryV1Questions[key]),
            name: question.name,
            description: question.description,
            theme: question.theme,
          });
          return acc;
        }

        case REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1: {
          // @ts-ignore
          const key = question.settings.skill.id;
          if (seenSkillCategoriesForCustomSkillV1.has(key)) {
            return acc;
          }
          seenSkillCategoriesForCustomSkillV1.add(key);
          acc.push({
            type: REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1,
            skillCategory: question.settings.skillCategory,
            skillId: key,
            skills: Object.values(groupedCustomSkillV1Questions[key]),
            name: question.name,
            description: question.description,
            theme: question.theme,
          });
          return acc;
        }
        default:
          break;
      }

      return acc;
    },
    [],
  );

  return result;
}

export const mapRatingsList = (
  reviewRatings: (IReviewRating & {
    reviewType: string;
  })[],
) => {
  let isFirstAnswerFound = false;
  let answers: AnswerType[] = [];

  answers = reviewRatings.map((rating) => {
    let isOpened = false;
    if (!isFirstAnswerFound && rating.answer && rating.answer !== -1) {
      isOpened = true;
      isFirstAnswerFound = true;
    } else {
      isOpened = false;
    }
    return {
      ...rating,
      isOpened,
    };
  });

  if (!isFirstAnswerFound && answers[0]) {
    answers[0].isOpened = true;
  }

  return answers;
};

export const showCalibratedLabel = (
  rating: AnswerType,
  userReview: IUserReview & { tasks?: ITask[] },
  currentUser: IUser,
) => {
  const isValidUserRole = userReview.coaches.includes(currentUser.id) || currentUser.isAdmin;

  const task = userReview.tasks?.find((task) => task.id === rating.task);

  return (
    isValidUserRole &&
    rating.createdFor !== currentUser.id &&
    (task?.status === TASK_STATUS.CALIBRATE ||
      (task?.status === TASK_STATUS.EXPIRED && task.lastStatus === TASK_STATUS.CALIBRATE))
  );
};

export const showCalibratePlaceholder = (
  taskId: string,
  userReview: IUserReview & { tasks?: ITask[] },
  currentUser: IUser,
) => {
  const task = userReview.tasks?.find((task) => task.id === taskId);

  return (
    task?.status === TASK_STATUS.CALIBRATE &&
    (!userReview.coaches.includes(currentUser.id) || !currentUser.isAdmin)
  );
};

export const isUserReviewEditable = (userReview: IUserReview) => {
  if (
    [
      USER_REVIEW_STATUS.PUBLISHED,
      USER_REVIEW_STATUS.SIGNING,
      USER_REVIEW_STATUS.ARCHIVED,
    ].includes(userReview.status)
  ) {
    return false;
  }

  if (!!userReview.settings.isDigitalSign && userReview.status === USER_REVIEW_STATUS.COMPLETED) {
    return false;
  }

  return true;
};
