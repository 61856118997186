import styled from 'styled-components';

import { COLORS } from '~/styles';

const Wrapper = styled.div<{ minSize: string }>`
  background-color: ${COLORS.WHITE};
  padding: 24px 32px;
  border-radius: 8px;
  width: ${({ minSize }) => minSize};
  overflow-y: auto;
  user-select: none;
`;

const Header = styled.div`
  border-bottom: 1px solid ${COLORS.BORDERS};
  padding-bottom: 8px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: ${COLORS.TEXT_HOVER};
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Subtitle = styled.div`
  font-size: 18px;
  color: ${COLORS.SUBTEXT};
`;

const BodyArea = styled.div`
  margin-top: 16px;
`;

const SecondaryTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${COLORS.TEXT_HOVER};
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 32px;
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

const ColorContainer = styled.div`
  margin-top: 16px;
  box-sizing: border-box;
`;

const ColorItem = styled.div<{ itemColor: string; isSelected?: boolean }>`
  background-color: ${({ itemColor }) => itemColor};
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.13);
  display: flex;
  align-items: center;
  justify-content: center;
  &::after {
    content: '';
    display: ${({ isSelected }) => (isSelected ? 'block' : 'none')};
    position: absolute;
    width: 40%;
    height: 40%;
    background: ${COLORS.WHITE};
    border-radius: 50%;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.13);
  }

  &:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.41);
  }
`;

const ColorTitle = styled.div`
  color: ${COLORS.TEXT_HOVER};
  margin-bottom: 8px;
  box-sizing: border-box;
`;

const ColorItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  color: ${COLORS.WHITE};
  flex-wrap: wrap;
`;

const AddColorBtn = styled.div<{ active: boolean }>`
  background: ${({ active }) => (active ? COLORS.COMPANY : COLORS.WHITE)};
  border: 1.5px solid ${({ active }) => (active ? 'transparent' : COLORS.COMPANY)};
  color: ${({ active }) => (active ? COLORS.WHITE : COLORS.COMPANY)};
  border-radius: 100%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  .icon-inner {
    transform: rotate(45deg);
    box-sizing: border-box;
    width: 12px;
    height: 12px;
  }
  &:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.41);
  }
`;

const SketchPickerWrapper = styled.div`
  position: absolute;
  top: 14px;
  left: 0;
  transform: translateY(-100%);
  background: ${COLORS.WHITE};
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;
  border-radius: 6px;
  overflow: hidden;
  .color-picker-ctr {
    box-shadow: none !important;
    margin: auto;
    margin-bottom: 5px;
  }
`;

const SketchActionHeader = styled.div`
  font-size: 12px;
  padding: 10px 10px 0;
  color: ${COLORS.SUBTEXT};
  box-sizing: border-box;
`;

const SketchActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  gap: 5px;
  border-top: 1px solid ${COLORS.BORDERS};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  overflow-y: auto;
  max-height: 400px;
`;

const InputHolder = styled.div`
  position: relative;
`;

const DeleteBtn = styled.div`
  flex: none;
  button {
    width: 100px;
    height: auto;
  }
`;

const SecondaryBtnContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex: auto;
  justify-content: flex-end;
`;

export {
  AddColorBtn,
  BodyArea,
  ButtonContainer,
  ColorContainer,
  ColorItem,
  ColorItemContainer,
  ColorTitle,
  Header,
  IconContainer,
  InputContainer,
  SecondaryTitle,
  SketchActionContainer,
  SketchActionHeader,
  SketchPickerWrapper,
  Subtitle,
  Title,
  Wrapper,
  InputHolder,
  DeleteBtn,
  SecondaryBtnContainer,
};
