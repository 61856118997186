import { createReducer } from 'redux-act';

import * as userActions from './actions';

const INITIAL_STATE = {
  lang: 'en',
};

export const locale = createReducer(
  {
    [userActions.setLocale]: (state, payload) => {
      return {
        ...state,
        lang: payload,
      };
    },
  },
  INITIAL_STATE,
);
