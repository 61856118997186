import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';
import SyncModal from '~/components/SyncModal';

import usersIcon from '~/assets/a-users-icn.svg';

import { INTEGRATIONS_CONN_ERROR_MSG } from '~/constants';
import { INSTRUCTIONS } from '~/constants/instructions';
import useBoolState from '~/hooks/useBoolState';
import { findTeamsToSyncInCompanyIntegrations } from '~/services/integrations';
import { COLORS, COLOR_PALETTE } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';

function SyncTeamsModal({ i18n, onClose, onSubmit }) {
  const [integrations, setIntegrations] = useState([]);
  const [isIntegrationError, setIsIntegrationError] = useState([]);
  const $loading = useBoolState(true);
  const itemsPath = 'result.updatedTeamNames'; // path where to get items
  const searchByField = 'oldValue'; // search will filter by this field

  useEffect(() => {
    const fetchData = async () => {
      let integrationsWithSyncTeams;
      try {
        integrationsWithSyncTeams = await findTeamsToSyncInCompanyIntegrations();
      } catch (e) {
        // Do nothing
      }

      // If it is undefined then the fetching of integration data failed
      if (!integrationsWithSyncTeams) {
        setIsIntegrationError(true);
        $loading.off();
        return;
      }

      if (!isEmpty(integrationsWithSyncTeams)) {
        setIntegrations(integrationsWithSyncTeams);
      }

      $loading.off();
    };

    fetchData();

    // eslint-disable-next-line
  }, []);

  const cols = [
    {
      title: i18n._(t`Team`),
      width: '150px',
    },
    {
      title: i18n._(t`Type`),
      width: '120px',
    },
    { title: i18n._(t`Description`) },
  ];

  return (
    <SyncModal
      title={i18n._(t`Synchronise teams`)}
      integrations={integrations}
      cols={cols}
      searchByField={searchByField}
      loading={$loading.value}
      onClose={onClose}
      onSubmit={() => {
        if (!isEmpty(integrations)) {
          const teamsToSync = [];
          integrations.forEach((i) => teamsToSync.push(...get(i, itemsPath, [])));
          return onSubmit(teamsToSync);
        }
      }}
      placeholder={
        <Placeholder
          Icon={() => (
            <SvgIcon
              url={usersIcon}
              width={'50px'}
              height={'50px'}
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
          )}
          title={i18n._(t`No API available`)}
          subTitle={i18n._(
            isIntegrationError
              ? INTEGRATIONS_CONN_ERROR_MSG
              : t`Integrate Learned with your HR systems. Contact your customer success manager.`,
          )}
          subTitleStyles={{ ...(isIntegrationError && { color: COLORS.ACCENT_ERROR }) }}
        />
      }
      infoMessage={i18n._(
        t`Teams can only be synchronised if they have been imported via an API and can only be synchronised with that specific API.`,
      )}
      infoUrl={getInstructionUrl(INSTRUCTIONS.SYNC_TEAMS)}
      confirmMessage={i18n._(
        t`Are you sure you want to synchronise this teams from Learned? This cannot be undone!`,
      )}
      isSubmitDisabled={integrations.every((i) => isEmpty(get(i, itemsPath, [])))}
      pathToItems={itemsPath}
      getIntegrationRowData={(item) => [
        item.oldValue,
        i18n._(t`Name`),
        i18n._(t`From ${item.oldValue} to ${item.newValue}`),
      ]}
      integrationPlaceholder={
        <Placeholder
          Icon={() => (
            <SvgIcon
              url={usersIcon}
              width={'50px'}
              height={'50px'}
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
          )}
          title={i18n._(t`No teams to synchronise`)}
        />
      }
      integrationExplanation={i18n._(
        t`These team changes have been detected. Click synchronise to apply the changes.`,
      )}
    />
  );
}

SyncTeamsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withI18n()(SyncTeamsModal);
