import React, { useRef } from 'react';

import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import DropdownButton from '~/components/DropdownButton';
import SvgIcon from '~/components/SvgIcon';

import DropdownArrow from '~/assets/ic-keyboard-arrow-down.svg';

import { LANGUAGES } from '~/constants';
import getLang from '~/selectors/getLang';
import { changeLang } from '~/store/locale/actions';
import { COLOR_SET } from '~/styles';

const width = '72px';

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const SelectedLang = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_SET.BLACK};
  text-transform: uppercase;
  margin-right: 8px;
`;

const Items = styled.div`
  width: ${width};
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  padding: 0 10px 0 14px;
  border-bottom: solid 1px #f3f2ef;
  cursor: pointer;
`;

const ItemName = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => (props.$isActive ? 'var(--company-color)' : COLOR_SET.BLACK)};
  text-transform: uppercase;
`;

function LangDropdown({ onLanguageChange }) {
  const popoverRef = useRef();
  const locale = useSelector(getLang);
  const dispatch = useDispatch();

  const renderButton = () => {
    return (
      <Row>
        <SelectedLang>{locale}</SelectedLang>
        <SvgIcon
          url={DropdownArrow}
          width="16px"
          height="13px"
          isDefaultColor
          defaultColor="var(--company-color)"
        />
      </Row>
    );
  };

  const renderMenu = () => {
    return (
      <Items>
        {Object.values(LANGUAGES).map((lang) => (
          <Item
            key={lang}
            onClick={() => {
              dispatch(changeLang(lang));
              if (onLanguageChange) {
                onLanguageChange(lang);
              }
            }}
          >
            <ItemName $isActive={lang === locale}>{lang}</ItemName>
          </Item>
        ))}
      </Items>
    );
  };

  return (
    <DropdownButton maxWidth={526} popoverRef={popoverRef} content={renderMenu()}>
      {renderButton()}
    </DropdownButton>
  );
}

LangDropdown.propTypes = {
  onLanguageChange: PropTypes.func,
};

export default LangDropdown;
