import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';
import CheckBoxList from '~/components/CheckBoxList';
import Modal from '~/components/Modal';

const Footer = styled.div`
  display: flex;
`;

class SelectCheckboxItemsModal extends Component {
  static propTypes = {
    itemsList: PropTypes.array,
    selectedIds: PropTypes.arrayOf(PropTypes.string),
    updateSelectedList: PropTypes.func,
    footerButtons: PropTypes.node,
  };

  static defaultProps = {
    selectedIds: [],
  };

  state = {
    currentItems: this.props.selectedIds,
  };

  updateItems = () => {
    const { updateSelectedList, closeModal } = this.props;
    const { currentItems } = this.state;

    updateSelectedList(currentItems);
    closeModal();
  };

  handleChange = (list) => {
    this.setState({ currentItems: list });
  };

  render() {
    const { i18n, itemsList, closeModal, headerTitle, searchTitle, footerButtons } = this.props;
    const { currentItems } = this.state;

    return (
      <Modal
        title={headerTitle}
        onClose={closeModal}
        width={600}
        footerRight={
          <Footer>
            {footerButtons}
            <Button
              type="white"
              disabled={isEmpty(itemsList)}
              onClick={this.updateItems}
              styles={{
                height: '40px',
                textTransform: 'uppercase',
                fontSize: '12px',
                minWidth: '78px',
              }}
              label={i18n._(t`Save`)}
            />
          </Footer>
        }
      >
        <CheckBoxList
          enableSearch
          multiCheck
          searchLabel={searchTitle}
          items={itemsList}
          selected={currentItems}
          onChange={this.handleChange}
        />
      </Modal>
    );
  }
}

export default withI18n()(SelectCheckboxItemsModal);
