import React, { useMemo, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import { Input } from '~/components/Input';

import { COLORS } from '~/styles';

import {
  ButtonsWrapper,
  FormWrapper,
  Subtitle,
  Title,
  SectionWrapper,
  PasswordFormItem,
  PasswordRequirementBlock,
  PasswordRequirement,
  PasswordText,
} from '../design';

import type { IRegisterWithCompanyForm } from '../types';
import type { UseFormReturn } from 'react-hook-form';

interface IPasswordSectionProps {
  formMethods: UseFormReturn<IRegisterWithCompanyForm>;
}

const PasswordSection = ({ formMethods }: IPasswordSectionProps) => {
  const { i18n } = useLingui();
  const { watch, setValue, register } = formMethods;
  const [isPasswordRequirementVisible, setIsPasswordRequirementVisible] = useState(false);

  const passwordValidation = useMemo(() => {
    const password = watch('password');
    const confirmationPassword = watch('confirmationPassword');

    const result = {
      correctNumChars: password.length >= 8 && password.length <= 20,
      correctNumber: /\d/.test(password),
      correctLetter: /[A-Za-z]/.test(password),
      correctSymbol: /[-`~!@#$%^&*()_=+[{}\]}\\|;:'",<.>/?]/.test(password),
      correctMatch: !!password && password.length > 0 && password === confirmationPassword,
      valid: false,
    };

    result.valid =
      result.correctNumChars &&
      result.correctNumber &&
      result.correctLetter &&
      result.correctSymbol &&
      result.correctMatch;

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify([watch('password'), watch('confirmationPassword')])]);

  return (
    <SectionWrapper>
      <Title>
        <Trans>Create your account</Trans>
      </Title>
      <Subtitle>
        <Trans>Step 3/5</Trans>
      </Subtitle>
      <FormWrapper>
        <PasswordFormItem>
          <Input
            type="password"
            width="285px"
            label={i18n._(t`Create Password`)}
            placeholder={i18n._(t`•••••••••`)}
            register={register('password')}
            onFocus={() => setIsPasswordRequirementVisible(true)}
            onBlur={() => setIsPasswordRequirementVisible(false)}
          />
          {isPasswordRequirementVisible && (
            <PasswordRequirementBlock>
              <PasswordRequirement>
                {validationIcon(passwordValidation.correctNumChars)}
                <PasswordText>
                  <Trans>Between 8 - 20 characters</Trans>
                </PasswordText>
              </PasswordRequirement>
              <PasswordRequirement>
                {validationIcon(passwordValidation.correctNumber)}
                <PasswordText>
                  <Trans>Contains a number</Trans>
                </PasswordText>
              </PasswordRequirement>
              <PasswordRequirement>
                {validationIcon(passwordValidation.correctLetter)}
                <PasswordText>
                  <Trans>Contains a letter</Trans>
                </PasswordText>
              </PasswordRequirement>
              <PasswordRequirement>
                {validationIcon(passwordValidation.correctSymbol)}
                <PasswordText>
                  <Trans>Contains a symbol</Trans>
                </PasswordText>
              </PasswordRequirement>
              <PasswordRequirement>
                {validationIcon(passwordValidation.correctMatch)}
                <PasswordText>
                  <Trans>Passwords match</Trans>
                </PasswordText>
              </PasswordRequirement>
            </PasswordRequirementBlock>
          )}
        </PasswordFormItem>
        <Input
          type="password"
          width="285px"
          label={i18n._(t`Confirm password`)}
          placeholder={i18n._(t`•••••••••`)}
          register={register('confirmationPassword')}
          onFocus={() => setIsPasswordRequirementVisible(true)}
          onBlur={() => setIsPasswordRequirementVisible(false)}
        />
        <ButtonsWrapper>
          <Button
            type="button"
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.NAVIGATION_PRIMARY}
            label={i18n._(t`Next`)}
            disabled={!passwordValidation.valid}
            onClick={() => setValue('step', 4)}
          />
          <Button
            className="back"
            label={i18n._(t`Back`)}
            type="button"
            variant={ButtonVariant.TEXT_PRIMARY}
            icon={ICONS.BACK}
            size={ButtonSize.MEDIUM}
            iconSize={ICON_SIZES.SMALL}
            onClick={() => setValue('step', 2)}
          />
        </ButtonsWrapper>
      </FormWrapper>
    </SectionWrapper>
  );
};

const validationIcon = (value: Boolean) => {
  return (
    <Icon
      icon={value ? ICONS.CHECKMARK : ICONS.CLOSE_2}
      color={value ? COLORS.ACCENT_SUCCESS : COLORS.ACCENT_ERROR}
      size={value ? ICON_SIZES.MEDIUM : ICON_SIZES.LARGE}
    />
  );
};

export { PasswordSection };
