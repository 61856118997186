import React from 'react';

import { IGoal } from '@learned/types';
import { Trans } from '@lingui/macro';

import { ActivityProgressSection } from './ActivityProgressSection';
import {
  ActivityProgressContainer,
  CardTitle,
  GoalsProgressContainer,
  StyledDivider,
} from './design';
import { GoalProgressSection } from './GoalProgressSection';

const GoalProgressCard = ({ goal, disableUpdate }: { goal: IGoal; disableUpdate?: boolean }) => {
  const isActivitiesAvailable = goal.activities && goal.activities.length > 0;

  return (
    <>
      <GoalsProgressContainer>
        <CardTitle>
          <Trans>Goal progress</Trans>
        </CardTitle>
        <GoalProgressSection goal={goal} disableUpdate={disableUpdate} />
      </GoalsProgressContainer>

      {isActivitiesAvailable && <StyledDivider />}
      {isActivitiesAvailable && (
        <ActivityProgressContainer>
          <CardTitle>
            <Trans>Activities</Trans>
          </CardTitle>
          <ActivityProgressSection goal={goal} disableUpdate={disableUpdate} />
        </ActivityProgressContainer>
      )}
    </>
  );
};

export { GoalProgressCard };
