import styled from 'styled-components';

export const OvalWrapper = styled.div<{ left?: number }>`
  width: 20px;
  height: 20px;
  padding: 7px;
  border: solid 1px #e5e7ec;
  background-color: #fff;
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;
`;

export const Oval = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #c2c9dd;
  position: absolute;
  top: calc(50% - 3px);
  left: calc(50% - 3px);
`;
