import styled, { css } from 'styled-components';

import AutocompleteFilter from '~/components/AutocompleteFilter';
import {
  AutocompleteFilterGoalCycles,
  AutocompleteFilterTeams,
} from '~/components/AutocompleteFilters';
import { Button } from '~/components/Buttons';
import DatePicker from '~/components/DatePickerDeprecated';
import Dialog from '~/components/Dialog';
import { Dropdown, DropdownTemp } from '~/components/Dropdown';
import { Input } from '~/components/Input';
import { Top } from '~/components/TableList/components/Top';
import TextArea from '~/components/TextArea';
import Divider from '~/components/UI/Divider';

import { COLORS } from '~/styles';

export const StyledDialogBox = styled(Dialog)``;

export const Form = styled.div`
  border-radius: 10px;
  width: 750px;
  max-width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 32px 0px 32px 0px;
  box-sizing: border-box;
`;

export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px 0px 40px;
`;

export const ActivityModalTitle = styled.div`
  font-size: 26px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
`;

export const Title = styled.h2`
  font-size: 26px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0px 40px 0px 40px;
  box-sizing: border-box;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 19px;
`;

export const RowText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
`;

export const SubTitle = styled(RowText)`
  margin-bottom: 24px;
  padding: 0px 40px 0px 40px;
  box-sizing: border-box;
`;

export const ItemRow = styled.div<{
  marginTop?: string;
  marginBottom?: string;
  flexColumn?: boolean;
  isError?: boolean;
  paddingTop?: string;
}>`
  display: flex;
  flex-direction: ${({ flexColumn }) => (flexColumn ? 'column' : 'row')};
  margin-bottom: ${({ marginBottom }) => marginBottom || '16px'};
  padding: 0px 40px 0px 40px;
  box-sizing: border-box;
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop};
    `}
  ${({ isError }) =>
    isError &&
    css`
      border: solid 1px ${COLORS.ACCENT_ERROR};
      margin-bottom: 0px;
    `}
  ${({ paddingTop }) =>
    paddingTop &&
    css`
      padding-top: ${paddingTop};
    `}
`;
export const ItemContainer = styled.div<{
  width?: string;
  isPadding?: boolean;
  marginRight?: string;
}>`
  margin-right: ${({ marginRight }) => marginRight || '16px'};

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  ${({ isPadding }) =>
    isPadding &&
    css`
      padding: 0px 40px 0px 40px;
      box-sizing: border-box;
    `}

  .goal-setup-dropdown {
    width: 260px;
  }
`;
export const ItemLabel = styled.div<{
  bold?: true;
  noMarginBottom?: boolean;
}>`
  font-size: 14px;
  margin-bottom: ${({ bold, noMarginBottom }) => (noMarginBottom ? '0' : bold ? '12px' : '4px')};
  font-weight: ${({ bold }) => (bold ? '600' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: ${({ bold }) => (bold ? 'normal' : '-0.16px')};
  color: ${({ bold }) => (bold ? COLORS.TEXT_MAIN : COLORS.INPUT_TITLE)};
  display: flex;
  align-items: center;
`;

export const ItemSubLabel = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.17;
  letter-spacing: -0.13px;
  color: ${COLORS.TEXT_MAIN};
`;

export const ItemLabelRow = styled.div<{
  marginBottom?: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom};
    `}
`;

export const GrayRow = styled.div<{
  marginTop?: string;
  isSpaceBetween?: boolean;
  height?: string;
}>`
  display: flex;
  align-items: center;
  height: ${({ height }) => height || '64px'};
  width: 100%;
  border-radius: 6px;
  background-color: ${COLORS.BG_LIST};
  box-sizing: border-box;
  padding-left: 20px;

  ${({ isSpaceBetween }) =>
    isSpaceBetween &&
    css`
      justify-content: space-between;
      padding-right: 24px;
    `}

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop};
    `}
`;

export const ToggleText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${COLORS.TEXT_MAIN};
  margin-left: 10px;
`;

export const StyledDropdownLong = styled(DropdownTemp)`
  width: 262px;
`;
export const StyledDropdownShort = styled(Dropdown)`
  width: 169px;
`;

export const ActivitySectionContainer = styled.div<{
  isError?: boolean;
}>`
  padding: 16px 40px 16px 40px;
  box-sizing: border-box;

  ${({ isError }) =>
    isError &&
    css`
      border: solid 1px ${COLORS.ACCENT_ERROR};
      margin-bottom: 2px;
    `}
`;

export const ActivityHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ActivityToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ActivityToggleText = styled.div`
  color: ${COLORS.TEXT_MAIN};
`;

export const ActivityButtonContainer = styled.div``;

export const ActivitiesTitle = styled.div`
  font-size: 16px;
  color: ${COLORS.TEXT_MAIN};
  font-weight: 600;
`;

export const FooterContainer = styled.div<{
  isLeftAction?: boolean;
}>`
  display: flex;
  justify-content: ${({ isLeftAction }) => (isLeftAction ? 'flex-end' : 'space-between')};
  margin-top: 19px;
  padding: 0px 40px 0px 40px;
  box-sizing: border-box;
`;

export const ActivityListContainer = styled.div`
  margin-top: 16px;
`;

// -------- Activity Modal Components ------------- //

export const ActivityModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 40px 0px 40px;
  box-sizing: border-box;
  gap: 10px;
  margin-top: 19px;
`;

export const ContentWrapper = styled.div`
  padding: 30px 0px 26px 0px;
  box-sizing: border-box;
`;

export const SectionWrapper = styled.div`
  margin-top: 16px;
`;

export const ActivityNameInput = styled(Input)`
  width: 540px;
`;

export const AdvanceSettingsRow = styled.div<{
  isCollapsed: boolean;
  marginTop?: string;
  marginBottom?: string;
}>`
  margin-top: ${({ marginTop }) => marginTop || '20px'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '16px'};
  display: flex;
  justify-content: space-between;
  padding: 0px 40px 0px 40px;
  box-sizing: border-box;

  svg {
    transform: ${(props) => (props.isCollapsed ? 'rotate(90deg)' : 'rotate(-90deg)')};
  }

  align-items: center;
  cursor: pointer;
`;

export const AdvanceSettingsTitle = styled.div`
  font-size: 16px;
  color: ${COLORS.TEXT_MAIN};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
`;

export const AdvanceSettingsContent = styled.div`
  margin-top: 24px;
  margin-bottom: 20px;
`;

export const ArrowSingle = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DescriptionWrap = styled.div`
  margin-top: 19px;
  width: 580px;
  padding: 0px 0px 0px 40px;
  box-sizing: border-box;
`;

export const DescriptionTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OptionalLabel = styled.div`
  color: ${COLORS.TEXT_MAIN};
  font-size: 12px;
`;

export const StyledTextArea = styled(TextArea)`
  height: 100px;
  &:focus {
    border: 1px solid ${COLORS.COMPANY};
    outline: none;
  }

  &::placeholder {
    font-size: 14px;
  }
`;

export const MeasurementSection = styled.div`
  margin-top: 16px;
  margin-bottom: 20px;
`;

export const AttachmentsSection = styled.div`
  margin-top: 24px;
  padding: 0px 40px 0px 40px;
  box-sizing: border-box;
`;

export const AttachmentTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: normal;
  color: ${COLORS.TEXT_MAIN};
`;

export const AttachmentTitle = styled.div`
  font-size: 16px;
  color: ${COLORS.TEXT_MAIN};
  font-weight: 600;
`;

// ------------- Attachments -------- //

export const FileContainer = styled.div<{
  noSpace?: boolean;
}>`
  display: flex;
  justify-content: ${(props) => !props.noSpace && 'space-between'};
  box-sizing: border-box;
  height: 62px;
  align-items: center;
  border-radius: 6px;
  padding: 0 9px;
  margin-bottom: 8px;
  background-color: ${COLORS.BG_LIST};
`;

export const FileName = styled.h6`
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: ${COLORS.TEXT_MAIN};
  cursor: pointer;
`;

export const AddButton = styled(Button)`
  position: relative;
  input[type='file'] {
    width: 100%;
    height: 40px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
`;

export const CloseIconButton = styled.div`
  margin-left: 13px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const FieldGroup = styled.div`
  margin-top: 16px;
`;

export const FileListWrapper = styled.div`
  margin-top: 16px;
`;

export const LightItemLabel = styled.div<{
  bold?: boolean;
  noMarginBottom?: boolean;
}>`
  font-size: 14px;
  margin-bottom: ${({ bold, noMarginBottom }) => (noMarginBottom ? '0' : bold ? '12px' : '4px')};
  font-weight: ${({ bold }) => (bold ? '600' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: ${({ bold }) => (bold ? 'normal' : '-0.16px')};
  color: ${COLORS.SUBTEXT};
  display: flex;
  align-items: center;
`;

// ------ Activity Item ------- //

export const ActivityMenuWrap = styled.div`
  margin-right: 20px;
  display: none;
`;

export const ActivityItemWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    div:nth-of-type(2) {
      display: flex;
    }
  }
`;

export const ActivityName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
  background-color: ${COLORS.BG_LIST};
`;

// ---- Goal Name ----- //

export const SuggestionBtnWrap = styled.div<{
  isError?: boolean;
}>`
  display: flex;
  height: 100%;
  flex-direction: column-reverse;
  align-items: center;
  max-width: 115px;

  button {
    margin-bottom: 5px;
  }
`;

export const SkillNameWrap = styled.div`
  background-color: ${COLORS.BG_ELEMENTS};
  display: flex;
  align-items: center;
  padding: 2px 6px 2px 6px;
  border-radius: 6px;
`;

export const SkillNameText = styled.div`
  color: ${COLORS.ICONS_PRIMARY};
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const SkillNameIcon = styled.div`
  margin-left: 16px;
  cursor: pointer;

  svg {
    width: 10px;
    height: 10px;
  }
`;

// ---- suggestions modal ---- //

export const SkillNameRow = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: normal; */
  max-width: 700px;
`;
// ----- goals to contribute -------- //

export const FiltersContainer = styled.div`
  display: felx;
`;

export const FilterGoalCycles = styled(AutocompleteFilterGoalCycles)`
  height: 32px;
  width: 219px;
  margin-left: 7px;
`;

export const FilterTeams = styled(AutocompleteFilterTeams)`
  height: 32px;
  width: 140px;
  margin-left: 7px;
`;

export const FilterOwners = styled(AutocompleteFilter)`
  height: 32px;
  width: 140px;
  margin-left: 7px;
`;

export const FooterSection = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 40px 0px 40px;
`;

export const BackButton = styled(Button)`
  margin-right: 10px;
`;

export const AddContributeButton = styled(Button)`
  width: 150px;
`;

export const ParentGoalName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 350px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
`;

export const ParentGoalTeamName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
`;

export const StyledTop = styled(Top)`
  &:first-child {
    padding: 12px 0 0px 0;
  }
`;

export const TableListContainer = styled.div`
  padding: 0 5px 0 15px;
`;

// ------ contribute section ------ //

export const ContributeSectionWrap = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 0px 40px 0px 40px;
  box-sizing: border-box;
`;
export const ContributeSectionRow = styled.div`
  display: flex;
  justify-content: space-between;
  aligin-items: center;
  margin-bottom: 11px;
`;

export const ContributeGoalItemWrap = styled.div`
  display: flex;
  justify-content: space-between;
  aligin-items: center;
  background-color: ${COLORS.BG_LIST};
  padding: 18px 23px 18px 24px;
  border-radius: 6px;
  box-sizing: border-box;
`;

export const ContributeGoalName = styled.div`
  color: ${COLORS.ICONS_PRIMARY};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
`;

export const ContributeGoalIcon = styled.div`
  cursor: pointer;
  svg {
    color: ${COLORS.ACCENT_ERROR};
  }
  display: flex;
  align-items: center;
`;

export const Error = styled.span`
  color: ${COLORS.ACCENT_ERROR};
  margin-left: 40px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
`;

export const StyledDivider = styled(Divider)<{
  isMargin?: boolean;
}>`
  width: calc(100% - 80px);
  margin: ${({ isMargin }) => (isMargin ? '24px 40px 24px 40px' : '0px 40px 0px 40px')};
  height: 1px;
`;

export const StyledDatePicker = styled(DatePicker)`
  input {
    border: solid 1px ${COLORS.BORDERS};
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    font-family: Poppins;
    line-height: 1.86;
    letter-spacing: normal;
    color: ${COLORS.TEXT_HOVER};

    ::placeholder {
      color: ${COLORS.PLACEHOLDERS};
      opacity: 0.6;
      font-size: 14px;
    }
  }
`;
