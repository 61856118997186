import React, { useState } from 'react';

import { ACTIVITY_PROGRESS_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import LibraryActivitiesModal from '~/components/LibraryActivitiesModal';
import { ActivityModal } from '~/components/Modals/ActivityModal';
import { EditLibraryActivityModal } from '~/components/Modals/EditLibraryActivityModal';
import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';
import { FieldGroup, FieldTitle } from '~/components/Text';

import { CreateUpdateSectionModal } from './CreateUpdateSectionModal';
import PathSection from './PathSection';

import RolesIcon from '~/assets/mdi-ballot.svg';

import useBoolState from '~/hooks/useBoolState';
import { getCurrentCompanyId, getSelectedRole, getUser } from '~/selectors/baseGetters';
import * as currentPathActions from '~/store/currentPath/actions';
import { COLORS, COLOR_PALETTE } from '~/styles';

const SectionsTitle = styled(FieldTitle)`
  font-size: 16px;
`;

const Sections = styled.div`
  transition: background-color 0.2s ease;
  background-color: white;
  border-radius: 6px;
  border-color: ${COLORS.BG_PAGE};
`;

function getNewOrder({
  currentOrder, // current order of activity to change
  sourceOrder = null, // source order of changed activity, null - if item from from another section
  destinationOrder = null, // destination order of changed activity, null - if item replaced to another section
}) {
  let upatedCurrentOrder = currentOrder;

  // apply source section changes
  if (sourceOrder !== null && upatedCurrentOrder > sourceOrder) {
    upatedCurrentOrder = upatedCurrentOrder - 1;
  }

  // apply destination section changes
  if (destinationOrder !== null && upatedCurrentOrder >= destinationOrder) {
    upatedCurrentOrder = upatedCurrentOrder + 1;
  }

  return upatedCurrentOrder;
}

// replaced InnerSections in separate component
// to improve performance and prevent unnecessary rerender on drag&drop
class InnerSections extends React.PureComponent {
  render() {
    const {
      item,
      setSelectedSection,
      openSectionModal,
      deleteSection,
      deleteActivity,
      onClickActivity,
      changeActivityObligation,
      openActivityModal,
      onCreateCustomActivity,

      showErrors,
    } = this.props;

    return sortBy(item.sections, ['order']).map((section) => (
      <PathSection
        key={section.id} // we need section.id for correct identification of component(that have own state - showContent)
        path={item}
        section={section}
        onUpdate={(section) => {
          setSelectedSection(section);
          openSectionModal.on();
        }}
        onDelete={(section) => deleteSection(section)}
        onAddActivity={(section) => {
          setSelectedSection(section);
          openActivityModal.on();
        }}
        onCreateCustomActivity={(section) => {
          setSelectedSection(section);
          onCreateCustomActivity();
        }}
        onDeleteActivity={deleteActivity}
        onClickActivity={onClickActivity}
        changeActivityObligation={changeActivityObligation}
        showErrors={showErrors}
      />
    ));
  }
}

const PathStepContent = ({ showErrors, item, i18n }) => {
  const dispatch = useDispatch();
  const [selectedSection, setSelectedSection] = useState(null);
  const openSectionModal = useBoolState(false);
  const openActivityModal = useBoolState(false);
  const [customActivity, setCustomActivity] = useState(null);
  const [viewActivity, setViewActivity] = useState(null);

  const user = useSelector(getUser);
  const currentRole = useSelector(getSelectedRole);
  const currentCompanyId = useSelector(getCurrentCompanyId);

  const createSection = (section) => {
    dispatch(currentPathActions.createCurrentPathSection(section));
  };
  const updateSection = (section) => dispatch(currentPathActions.updateCurrentPathSection(section));
  const deleteSection = (section) => dispatch(currentPathActions.deleteCurrentPathSection(section));

  const addActivitiesToSection = (activities) => {
    dispatch(
      currentPathActions.addActivitiesToCurrentPathSection({
        section: selectedSection,
        activities,
      }),
    );
    setSelectedSection(null);
  };

  const updateActivityInPath = (activities) => {
    dispatch(
      currentPathActions.updateActivityInPath({
        activities,
      }),
    );
  };

  const onHideEditLibraryActivityModal = () => {
    setCustomActivity(null);
  };

  // we create custom activity before creation of the path
  // and then connect custom activity to path the same way as library activities
  // if path was not saved as draft or published -> activity will be automatically deleted from collection with index
  const onCreateCustomActivity = () => {
    const newActivity = {
      isAvailableInUserLibrary: false, // always false
      isRateAvailable: true,
      createdInRole: currentRole,
      createdBy: user.id,
      company: currentCompanyId,
      progressType: ACTIVITY_PROGRESS_TYPES.IS_DONE,
      progressDetails: {
        min: null,
        max: null,
        currency: null,
      },
    };

    setCustomActivity(newActivity);
  };

  const deleteActivity = (activityId) =>
    dispatch(currentPathActions.deleteActivityFromCurrentPathSection(activityId));

  const onClickActivity = (activity) => setViewActivity(activity);

  const onEditActivity = (customActivity) => {
    setCustomActivity(customActivity);
    setViewActivity(null);
  };

  const changeActivityObligation = (activityId, isObligated) => {
    const path = item;
    let updatedActivitiesProps = { ...path.activitiesProps };
    updatedActivitiesProps[activityId] = { ...updatedActivitiesProps[activityId], isObligated };
    dispatch(currentPathActions.updateCurrentPath({ activitiesProps: updatedActivitiesProps }));
  };

  const onDragEndActivity = (result) => {
    const path = item;
    const { destination, source, draggableId } = result;

    // update order for dragged activity
    const newActivitiesProps = {
      ...path.activitiesProps,
      // change draggable props
      [draggableId]: {
        ...path.activitiesProps[draggableId],
        section: Number(destination.droppableId),
        order: Number(destination.index),
      },
    };

    // update source order
    path.activities.forEach((activity) => {
      if (activity.id === draggableId) {
        return;
      }

      const activityProps = path.activitiesProps[activity.id];

      if (
        activityProps.section === Number(source.droppableId) ||
        activityProps.section === Number(destination.droppableId)
      ) {
        newActivitiesProps[activity.id] = {
          ...activityProps,
          order: getNewOrder({
            currentOrder: activityProps.order,
            sourceOrder: Number(source.droppableId) === activityProps.section ? source.index : null, // if source another section
            destinationOrder:
              Number(destination.droppableId) === activityProps.section ? destination.index : null, // if destination another section
          }),
        };
      }
    });

    dispatch(currentPathActions.updateCurrentPath({ activitiesProps: newActivitiesProps }));
  };

  const onDragEndSection = (result) => {
    const path = item;
    const { destination, source, draggableId } = result;

    // update order for dragged activity
    const newSections = [];
    const newActivitiesProps = {};
    path.sections.forEach((section) => {
      if (section.id === draggableId) {
        // dragged section
        newSections.push({
          ...section,
          order: destination.index,
        });
        map(path.activitiesProps, (activity, key) => {
          if (activity.section === section.order) {
            newActivitiesProps[key] = {
              ...activity,
              section: destination.index,
            };
          }
        });
      } else {
        const newOrder = getNewOrder({
          currentOrder: section.order,
          sourceOrder: source.index,
          destinationOrder: destination.index,
        });
        // other sections
        newSections.push({
          ...section,
          order: newOrder,
        });
        map(path.activitiesProps, (activity, key) => {
          if (activity.section === section.order) {
            newActivitiesProps[key] = {
              ...activity,
              section: newOrder,
            };
          }
        });
      }
    });

    dispatch(
      currentPathActions.updateCurrentPath({
        sections: newSections,
        activitiesProps: newActivitiesProps,
      }),
    );
  };

  const onDragEnd = (result) => {
    const { destination, source, type } = result;

    // do nothing if item dragged outside of droppable section
    if (!destination) {
      return;
    }

    // do nothing if no changes
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    switch (type) {
      case 'ACTIVITY':
        return onDragEndActivity(result);
      case 'SECTION':
        return onDragEndSection(result);
      default:
        break;
    }
  };

  return (
    <>
      <FieldGroup>
        <SectionsTitle>
          <Trans>Sections</Trans>*
        </SectionsTitle>
        {item.sections ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="all-sections" type="SECTION">
              {(provided, snapshot) => (
                <Sections
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  $isDraggingOver={snapshot.isDraggingOver}
                >
                  <InnerSections
                    item={item}
                    setSelectedSection={setSelectedSection}
                    openSectionModal={openSectionModal}
                    deleteSection={deleteSection}
                    deleteActivity={deleteActivity}
                    onClickActivity={onClickActivity}
                    openActivityModal={openActivityModal}
                    changeActivityObligation={changeActivityObligation}
                    onCreateCustomActivity={onCreateCustomActivity}
                    showErrors={showErrors}
                  />
                  {provided.placeholder}
                </Sections>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <Placeholder
            Icon={() => (
              <SvgIcon
                url={RolesIcon}
                width={'50px'}
                height={'50px'}
                isDefaultColor
                defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
              />
            )}
            title={i18n._(t`No sections`)}
            subTitle={i18n._(t`Add section to this path`)}
            isError={showErrors && isEmpty(item.sections)}
          />
        )}
        <Button
          type="primary-border"
          label={i18n._(t`Add section`)}
          styles={{ width: '100%', marginTop: '60px' }}
          onClick={openSectionModal.on}
        />
      </FieldGroup>
      {openSectionModal.value && (
        <CreateUpdateSectionModal
          title={selectedSection ? i18n._(t`Update section`) : i18n._(t`Create section`)}
          namePlaceholder={i18n._(t`Example: Section 1. Leadership`)}
          descriptionPlaceholder={i18n._(t`Type your description`)}
          item={selectedSection}
          onClose={() => {
            openSectionModal.off();
            setSelectedSection(null);
          }}
          onCreate={createSection}
          onUpdate={updateSection}
        />
      )}

      {openActivityModal.value && (
        <LibraryActivitiesModal
          activitiesToHide={(item.activities || []).map((a) => a.id || a)}
          onModalClose={() => {
            openActivityModal.off();
            setSelectedSection(null);
          }}
          onSubmit={addActivitiesToSection}
          type="path"
        />
      )}
      {customActivity && (
        <EditLibraryActivityModal
          isCustom={true} // we need this to do not store activities in library
          currentActivity={customActivity}
          onHideModal={onHideEditLibraryActivityModal}
          onSaveActivity={(activity) =>
            customActivity.id
              ? updateActivityInPath([activity])
              : addActivitiesToSection([activity])
          }
        />
      )}
      {/* view activity, no actions (only edit for custom activity) */}
      {viewActivity && (
        <ActivityModal
          activityId={viewActivity.id}
          onClose={() => setViewActivity(null)}
          onEditActivity={() => onEditActivity(viewActivity)}
          isActionButtonsVisible
          isActionButtonParticipantsVisible={false}
          isActionButtonEditorsVisible={false}
          isUpdateProgress={false}
        />
      )}
    </>
  );
};

export default React.memo(withI18n()(PathStepContent));
