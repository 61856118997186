import qs from 'qs';

import { GOOGLE_CLIENT_ID } from '~/constants/env';
import { addUserIntegrationByCode, deleteUserIntegration } from '~/services/userIntegrations';
import PopupUtils from '~/utils/PopupUtils';

export default class GoogleClient {
  static scopes = [
    'https://www.googleapis.com/auth/calendar.events',
    'https://www.googleapis.com/auth/userinfo.email',
  ];
  static clientId = GOOGLE_CLIENT_ID;
  static API_URI = 'https://accounts.google.com/o/oauth2/v2/auth';

  constructor() {
    // Properly sets this reference for the unload event.
    this.popupUtils = new PopupUtils('googleAuth');
  }

  async login() {
    const url = `${GoogleClient.API_URI}/authorize?${qs.stringify({
      response_type: 'code',
      response_mode: 'query',
      scope: GoogleClient.scopes.join(' '),
      client_id: GoogleClient.clientId,
      redirect_uri: location.origin,
      prompt: 'consent',
      access_type: 'offline', // this need to add refresh_token in auth response
    })}`;
    const popup = this.popupUtils.openPopup(url, 'googleAuth');
    const code = await this.monitorPopupForHash(popup);

    // add integration in DB
    return await addUserIntegrationByCode('google', code);
  }

  /**
   * Monitors a window until it loads a url with a known hash, or hits a specified timeout.
   * @param popupWindow - window that is being monitored
   */
  async monitorPopupForHash(popupWindow) {
    await this.popupUtils.monitorPopupForSameOrigin(popupWindow);
    const query = qs.parse(popupWindow.location.search, { ignoreQueryPrefix: true });

    // prop cleanup if all ok
    this.popupUtils.cleanPopup(popupWindow);
    // close popup
    if (query.error) {
      throw Error(query.error_description);
    }
    return query.code;
  }

  async logout(id) {
    // const auth2 = gapi.auth2.getAuthInstance();
    // auth2.signOut().then(function() {
    //  console.log("User signed out.");
    // });

    if (id) {
      // delete integration from DB
      await deleteUserIntegration(id);
    }

    return id;
  }
}
