import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import AvatarEditor from 'react-avatar-editor';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import { useToasts, TOAST_TYPES } from '~/components/Toast';

import { COLORS } from '~/styles';

import {
  UploaderWrapper,
  FileUploaderInput,
  Editor,
  Footer,
  ScaleButton,
  ScaleSlider,
  ScaleLabel,
  SaveButton,
} from '../design';

interface IImageUploaderProps {
  uploadEndPoint?: (file: File) => Promise<{ url: string }>;
  onUploadImage: (imageUrl: string) => void;
  onClose: () => void;
  imageSrc?: string;
}

const sliderRailStyle = {
  height: '2px',
};

const sliderTrackStyle = {
  height: '2px',
  borderRadius: 'none',
  backgroundColor: `${COLORS.TEXT_MAIN}`,
};

const sliderHandleStyle = {
  width: '15px',
  height: '15px',
  border: `6px solid ${COLORS.TEXT_MAIN}`,
  marginTop: '-6px',
};

const ImageUploader = ({
  uploadEndPoint,
  onUploadImage,
  imageSrc,
  onClose,
}: IImageUploaderProps) => {
  const { i18n } = useLingui();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const editorRef = useRef<AvatarEditor | null>(null);
  const { addToast } = useToasts();
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const [avatarScale, setAvatarScale] = useState(1.1);
  const [scaleLabel, setScaleLabel] = useState(10);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    setImageUrl(imageSrc);
  }, [imageSrc]);

  const onUploadAttachChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const maxAllowedSize = 2 * 1024 * 1024; // 2MB
    const allowedFileTypes = ['image/png', 'image/eps', 'image/jpeg', 'image/jpg'];

    if (file) {
      const isAllowedType = allowedFileTypes.includes(file.type);
      if (file.size > maxAllowedSize || !isAllowedType) {
        addToast({
          title: <Trans>Upload failed ({!isAllowedType ? 'only JPG,PNG,EPS' : 'max 2mb'})</Trans>,
          subtitle: (
            <Trans>
              {!isAllowedType
                ? 'Please choose a valid file type'
                : 'Please choose a smaller file and try again'}
            </Trans>
          ),
          type: TOAST_TYPES.ERROR,
        });
      } else {
        // upload image first and get the image url
        const imageUploadResponse = uploadEndPoint && (await uploadEndPoint(file));
        setImageUrl(imageUploadResponse?.url);
      }
    }
  };

  const onSliderChange = (value: number) => {
    setAvatarScale(value);
    setScaleLabel(Math.round((value - 1) * 100));
  };

  const onZoomIn = () => {
    if (avatarScale < 2) {
      const newScale = avatarScale + 0.1;
      setAvatarScale(newScale);
      setScaleLabel(Math.round((newScale - 1) * 100));
    }
  };

  const onZoomOut = () => {
    if (avatarScale > 1) {
      const newScale = avatarScale - 0.1;
      setAvatarScale(newScale);
      setScaleLabel(Math.round((newScale - 1) * 100));
    }
  };

  const handleSave = () => {
    const editor = editorRef.current;
    if (editor) {
      const canvas = editor.getImageScaledToCanvas();
      canvas.toBlob(async (blob: Blob | null) => {
        if (blob) {
          const formData = new FormData();
          formData.append('image', blob, 'updatedImage.png');

          const avatarEntry = formData.get('image');
          const imageUploadResponse = uploadEndPoint && (await uploadEndPoint(avatarEntry as File));
          onUploadImage(imageUploadResponse?.url as string);
          onClose();
        }
      }, 'image/png');
    }
  };

  const renderUploader = () => {
    return (
      <UploaderWrapper>
        <Icon icon={ICONS.UPLOAD} size={ICON_SIZES.LARGE} />
        <FileUploaderInput
          type="file"
          ref={fileInputRef}
          accept=".jpg,.jpeg,.png,.eps"
          onChange={onUploadAttachChange}
        />
        <Button
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          label={i18n._(t`Upload`)}
          onClick={handleButtonClick}
        />
        <span>
          <Trans>Recommended: JPG, PNG, EPS max 2MB, ratio: 16:9 (1920x1080px)</Trans>
        </span>
      </UploaderWrapper>
    );
  };

  const renderEditor = () => {
    return (
      <>
        <Editor
          image={imageUrl as string}
          width={390}
          height={390}
          border={0}
          color={[255, 255, 255, 0.5]}
          scale={avatarScale}
          rotate={0}
          borderRadius={200}
          ref={editorRef}
          crossOrigin="anonymous"
        />
        <Footer>
          <ScaleButton
            icon={ICONS.SUBTRACT_MINUS}
            variant={ButtonVariant.ICON}
            size={ButtonSize.MEDIUM}
            onClick={onZoomOut}
          />
          <ScaleSlider
            value={avatarScale}
            onChange={(value: number) => onSliderChange(value)}
            disabled={false}
            min={1}
            max={2}
            step={0.1}
            trackStyle={sliderTrackStyle}
            railStyle={sliderRailStyle}
            handleStyle={sliderHandleStyle}
          />
          <ScaleButton
            icon={ICONS.ADD_PLUS}
            variant={ButtonVariant.ICON}
            size={ButtonSize.MEDIUM}
            onClick={onZoomIn}
          />
          <ScaleLabel>{scaleLabel}%</ScaleLabel>
          <SaveButton
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            label={i18n._(t`save`)}
            onClick={handleSave}
          />
        </Footer>
      </>
    );
  };

  return <>{imageUrl ? renderEditor() : renderUploader()}</>;
};

export { ImageUploader };
