import React, { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { connect, useSelector } from 'react-redux';
import { Route, useLocation, useHistory } from 'react-router-dom';

import MainLayout from '~/layouts/MainLayout';
import LoadingPage from '~/pages/LoadingPage';

import { useAuth0 } from '~/auth0';
import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import { updateSelectedRole, updateSelectedTeam } from '~/store/selected/actions';
import { fetchUserData } from '~/store/users/actions';
import getCurrentPath from '~/utils/getCurrentPath';

async function checkRole(allowedRoles, currentRole, currentCompany, user, history, dispatch) {
  if (allowedRoles && !allowedRoles.includes(currentRole)) {
    const connection = (user.connections || []).find((conn) => conn.company === currentCompany);

    // Sentry catch, can't reproduce case "Cannot read property 'roles' of undefined"
    if (!connection) {
      Sentry.withScope(function (scope) {
        scope.setExtra('user', user);
        scope.setExtra('role', currentRole);
        scope.setExtra('company', currentCompany);
        Sentry.captureException(new Error('user has no connection'));
      });
    }

    let roleFound = false;
    for (let i = 0; i < allowedRoles.length; i++) {
      if (
        (connection && connection.roles.includes(allowedRoles[i])) ||
        allowedRoles[i] === ROLES.USER
      ) {
        await dispatch(updateSelectedRole(allowedRoles[i]));

        // Reset team if going to admin. Some shared admin/coach pages filter on selectedTeam
        if (allowedRoles[i] === ROLES.ADMIN) {
          await dispatch(updateSelectedTeam());
        }
        await dispatch(fetchUserData());
        roleFound = true;
        break;
      }
    }

    if (!roleFound) {
      history.push(routes.PERMISSION_DENIED);
    }
  }
}

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  isLayout = true,
  path,
  allowedRoles,
  currentRole,
  currentCompany,
  user,
  dispatch,
  ...rest
}) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
  useLocation();
  const history = useHistory();
  const app = useSelector((state) => state.app);
  const targetUrl = getCurrentPath();

  // Redirect if not authenticated
  useEffect(() => {
    // app.isLogout - do not redirect on logout
    if (loading || isAuthenticated || app.isLogout) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl },
        prompt: 'select_account',
      });
    };
    fn();
    // eslint-disable-next-line
  }, [loading, isAuthenticated, loginWithRedirect, path, targetUrl]);

  // Redirect if not allowed role
  if (user) {
    checkRole(allowedRoles, currentRole, currentCompany, user, history, dispatch);
  }

  const render = (props) => {
    if (!isAuthenticated) {
      return <LoadingPage />;
    }

    let WrapperComponent;
    if (!PrivateRoute.componentsCache[path]) {
      PrivateRoute.componentsCache[path] = Component;
    }
    WrapperComponent = PrivateRoute.componentsCache[path];

    return isLayout ? (
      Layout ? (
        <Layout>
          <WrapperComponent {...props} />
        </Layout>
      ) : (
        <MainLayout>
          <WrapperComponent {...props} />
        </MainLayout>
      )
    ) : (
      <WrapperComponent {...props} />
    );
  };

  // if isAuthenticated === false, routes haven't companyId, and we get error in LinkConstructor
  return !loading && isAuthenticated ? <Route path={path} render={render} {...rest} /> : null;
};

PrivateRoute.componentsCache = {};

const mapStateToProps = (state) => ({
  currentRole: state.selected.role,
  currentCompany: state.selected.company,
  user: state.auth.user,
});

export default connect(mapStateToProps)(PrivateRoute);
