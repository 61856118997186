import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import noResult from '~/assets/group-no-job.svg';

import {
  Title,
  Description,
  PlaceholderWrapper,
  PlaceholderImage,
  PlaceholderInformationRow,
  PlaceholderTitle,
  PlaceholderRectangle,
  PlaceholderFooterWrapper,
} from '../design';

const JobCardPlaceholder = () => {
  const { i18n } = useLingui();

  return (
    <PlaceholderWrapper width={864}>
      <PlaceholderInformationRow>
        <PlaceholderImage>
          <img src={noResult} alt={i18n._(t`No result`)} width={'80%'} />
        </PlaceholderImage>
        <div>
          <PlaceholderTitle>
            <Title>{i18n._(t`No job yet`)}</Title>
          </PlaceholderTitle>
          <Description>
            {i18n._(t`You have not been assigned a job. Check back again later.`)}
          </Description>
        </div>
      </PlaceholderInformationRow>
      <PlaceholderFooterWrapper>
        <PlaceholderRectangle width={257} marginRight={61} />
        <PlaceholderRectangle width={81} marginRight={35} />
        <PlaceholderRectangle width={107} />
      </PlaceholderFooterWrapper>
    </PlaceholderWrapper>
  );
};

export { JobCardPlaceholder };
