import iconFactory from './iconFactory';

const d = 'M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const MenuIcon = iconFactory('Menu', d);

MenuIcon.defaultProps.viewBox = '0 0 24 24';

export default MenuIcon;
