import styled from 'styled-components';

import Title from '~/components/base_components/Title';
import Divider from '~/components/UI/Divider';

import { COLORS } from '~/styles';

export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContentWrap = styled.div``;

export const StyledTitle = styled(Title)`
  color: ${COLORS.TEXT_MAIN};
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
`;

export const ColumnWrap = styled.div`
  display: flex;
`;

export const ColumnTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: ${COLORS.SUBTEXT};
  text-transform: uppercase;

  &:nth-of-type(1) {
    margin-right: 49px;
  }
`;

export const RowWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
`;

export const TitleToggleWrap = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;

export const TitleContainer = styled.div`
  font-family: Poppins;
  font-size: 14px;
  color: ${COLORS.GRAVEL};
`;

export const ToggleContainer = styled.div``;

export const EmailWrap = styled.div`
  width: 41px;
  margin-right: 49px;
`;

export const SystemWrap = styled.div`
  width: 46px;
`;

export const StyledDivider = styled(Divider)`
  margin-top: 21px;
`;
