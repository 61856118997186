import React from 'react';
import type { MouseEventHandler } from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { Icon, ICONS } from '~/components/Icon';

import { COLORS } from '~/styles';

interface IFooterProps {
  onDelete?: MouseEventHandler<HTMLButtonElement>;
  onEdit?: MouseEventHandler<HTMLButtonElement>;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  border-top: 1px solid ${COLORS.BORDER};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--company-color);
  cursor: pointer;
  font-size: 12px;
`;

const Footer = ({ onEdit, onDelete }: IFooterProps) => {
  return (
    <Wrapper>
      <Button type="button" onClick={onDelete}>
        <Icon icon={ICONS.DELETE_BIN} />
        <Trans>Delete from template</Trans>
      </Button>
      <Button type="button" onClick={onEdit}>
        <Icon icon={ICONS.EDIT_PENCIL} />
        <Trans>Edit</Trans>
      </Button>
    </Wrapper>
  );
};

export { Footer };
