import React from 'react';

import { UserAvatar } from '~/components/UserAvatar';
import { UserAvatarGroup } from '~/components/UserAvatar/UserAvatarGroup';

const AvatarsRow = ({
  onClick,
  coaches,
}: {
  onClick: (userId?: string) => void | undefined;
  coaches: string[];
}) => {
  const options = {
    isCounterClickable: true,
  };

  return coaches.length === 1 ? (
    <UserAvatar userId={coaches[0]} onClick={onClick} />
  ) : (
    <UserAvatarGroup
      users={coaches.map((item: string) => ({ userId: item }))}
      onClick={onClick}
      options={options}
      isNewModal
    />
  );
};

export { AvatarsRow };
