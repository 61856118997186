import type { IServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

import type { IIndustry } from '@learned/types';

export const listIndustries = (
  filters: { search?: string } = {},
  options: { skip?: number; limit?: number; sort?: Record<string, number> } = {},
) => {
  const body = {
    filters: {
      search: filters.search,
    },
    options: {
      skip: options.skip,
      limit: options.limit,
      sort: options.sort,
    },
  };

  return cloudRequest(serverRoutes.industries.searchSuperAdmin as IServerRoute, {}, body);
};

export const deleteIndustries = (ids: string[]) => {
  const body = {
    industryIds: ids,
  };

  return cloudRequest(serverRoutes.industries.deleteSuperAdmin as IServerRoute, {}, body);
};

export const insertIndustry = (industry: {
  name: { en_GB: string; nl_NL: string; de_DE: string };
}) => {
  const body = industry;

  return cloudRequest(serverRoutes.industries.insertSuperAdmin as IServerRoute, {}, body);
};

export const updateIndustry = (industry: IIndustry) => {
  const body = industry;

  return cloudRequest(serverRoutes.industries.updateSuperAdmin as IServerRoute, {}, body);
};

export const getIndustries = () => {
  return cloudRequest(
    serverRoutes.industries.getIndustries as IServerRoute,
    {},
    {},
    // @ts-ignore
    { noLogin: true },
  );
};
