import styled from 'styled-components';

import { TableList } from '~/components/TableList';

import { COLORS } from '~/styles';

export const Test = styled.div``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
`;

export const StyledTableList = styled(TableList)`
  padding: 0;
  margin: 0;
`;

export const NameRow = styled.span`
  display: flex;

  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};

  // hide overflow with ellipsis
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
