import styled from 'styled-components';

import { COLORS } from '~/styles';

const GraphOuterCtr = styled.div`
  height: 100%;
  width: 100%;
  flex: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const GraphCtr = styled.div`
  position: relative;
  cursor: default;
  flex: auto;
`;

const Tile = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ColoredCircle = styled.div<{ selectedColor: string }>`
  background: ${({ selectedColor }) => selectedColor};
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;

const GraphFooter = styled.div`
  font-size: 12px;
  color: ${COLORS.TEXT_HOVER};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  margin-top: 20px;
`;

export { GraphFooter, ColoredCircle, Tile, GraphCtr, GraphOuterCtr };
