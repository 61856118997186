import React from 'react';

import { ActivityName } from '~/pages/PassportPage/components/ActivitiesModal/ActivityName';
import { ActivityType } from '~/pages/PassportPage/components/ActivitiesModal/ActivityType';

import type { IColumnTable } from '~/@types/table';

import type { IActivity } from '@learned/types';

const columns: IColumnTable<IActivity>[] = [
  {
    name: 'Activity',
    accessor: 'name',
    renderCell: (item) => {
      return <ActivityName activity={item} />;
    },
  },
  {
    name: 'Type',
    accessor: 'type',
    renderCell: (item) => {
      return <ActivityType activity={item} />;
    },
  },
];

export { columns };
