import React, { useEffect, useRef, useState } from 'react';

import {
  LINK_STATUS,
  CALENDAR_EVENT_LAST_ATTEMPT_STATUS,
  ICalendarEvent,
  IUserIntegration,
} from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';

import { GoogleMeet } from '~/components/DigitalMeeting/components/GoogleMeet';
import { MicrosoftTeams } from '~/components/DigitalMeeting/components/MicrosoftTeams';
import { RotatingSpinner } from '~/components/Spinners';
import SvgIcon from '~/components/SvgIcon';

import ErrorIcon from '~/assets/error.svg';
import SyncIcon from '~/assets/sync.svg';

import { USER_INTEGRATION_TYPES } from '~/constants';
import { renewMeetingLink, getCalendarEvent } from '~/services/calendarEvents';
import { COLOR_PALETTE, COLORS } from '~/styles';

interface IMeeting {
  provider: any;
  lastAttemptStatus: CALENDAR_EVENT_LAST_ATTEMPT_STATUS;
  link: string;
  linkStatus: LINK_STATUS;
  isAuthorized: boolean;
  hasValidLink: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-left: 16px;
  border-radius: 16px;
  background-color: ${COLORS.INFO_LIGHT};
  padding: 4px 14px 4px 16px;
`;

const StyledLink = styled.a`
  display: flex;
  flex-direction: row;
  gap: 10px;
  text-decoration: none !important;
  color: ${COLOR_PALETTE.DARK_GRAY};
  &:hover {
    font-weight: 600;
    color: ${COLOR_PALETTE.BLUE_DARK};
  }
`;

const DisabledLink = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: ${COLOR_PALETTE.GRAY_MIDDLE};
`;

const SyncButton = styled.div`
  cursor: pointer;
`;

const tippyOpts = { modifiers: [{ name: 'computeStyles', options: { gpuAcceleration: false } }] };

const TippyWrapper = styled.div`
  &::after {
    content: '';
    display: block;
  }
`;

const MESSAGES = {
  AWAITING_EVENT_CREATION: t`The event has not been created in the calendar integration yet. This may take some time`,
  PENDING_STATUS: t`The meeting link is being created. This may take some time`,
  FAILURE_STATUS: t`The creation of the meeting link has failed, click the 'Sync' button to attempt to create it again. If this fails then the creator of the conversation can go to the personal settings and press the 'Manual Sync.' button in the Calendar Integration’`,
  INTEGRATION_SYNC: t`Your Learned events are being synced with your integration calendar. This may take some time`,
  INACTIVE_INTEGRATION: t`The creator of this event does not have an active calendar integration anymore`,
};

const DigitalMeetingLink = ({
  event,
  updateCalendarEvent,
}: {
  event: ICalendarEvent;
  updateCalendarEvent: (_ce: ICalendarEvent) => void;
}) => {
  const { i18n } = useLingui();
  const refreshInterval = useRef<number | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [userIntegration, setUserIntegration] = useState<
    IUserIntegration & { isExpire: boolean }
  >();
  const [infoMessage, setInfoMessage] = useState('');
  const [meeting, setMeeting] = useState<IMeeting>();

  // make request to recreate meeting link
  const requestMeetingLink = async () => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      setInfoMessage(MESSAGES.PENDING_STATUS);
      await renewMeetingLink(event);
    } catch (ex: any) {
      const errorCode = ex?.response?.status;
      if (errorCode === 409) {
        setInfoMessage(MESSAGES.INTEGRATION_SYNC);
      } else {
        setInfoMessage(MESSAGES.FAILURE_STATUS);
        setIsLoading(false);
      }
    }
  };

  const refreshData = async () => {
    const { calendarEvent, userIntegration } = await getCalendarEvent(event.id);
    updateCalendarEvent(calendarEvent);
    setUserIntegration(userIntegration);
    setIsLoading(false);
  };

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refreshInterval.current) {
      clearInterval(refreshInterval.current);
    }

    if (isLoading) {
      refreshInterval.current = window.setInterval(async () => {
        await refreshData();
      }, 5000);
    }

    return () => {
      clearInterval(refreshInterval.current);
    };
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    // neither event nor integration, don't show anything
    if (!userIntegration || !event) {
      setMeeting({} as IMeeting);
      setIsLoading(false);
      return;
    }

    let construct: any = {};
    switch (userIntegration?.type) {
      case USER_INTEGRATION_TYPES.google:
        construct = {
          provider: <GoogleMeet link={event.googleConnection?.linkMeeting} />,
          lastAttemptStatus: event.googleConnection?.lastAttemptStatus,
          link: event.googleConnection?.linkMeeting,
          linkStatus: event.googleConnection?.linkStatus ?? LINK_STATUS.FAILED,
        };
        break;
      case USER_INTEGRATION_TYPES.azure:
        construct = {
          provider: <MicrosoftTeams />,
          lastAttemptStatus: event.azureConnection?.lastAttemptStatus,
          link: event.azureConnection?.linkMeeting,
          linkStatus: event.azureConnection?.linkStatus ?? LINK_STATUS.FAILED,
        };
        break;
    }

    construct.isAuthorized =
      !userIntegration?.isRevoked && !userIntegration?.isDeleted && !userIntegration?.isExpire;

    construct.isIntEventCreated = (userIntegration?.type as string) === event.integration;

    construct.hasValidLink =
      construct.lastAttemptStatus === CALENDAR_EVENT_LAST_ATTEMPT_STATUS.SUCCESS &&
      construct.linkStatus === LINK_STATUS.SUCCESS &&
      construct.link &&
      !userIntegration?.syncStatus;

    setMeeting(construct);
    if (!construct.isAuthorized) {
      setIsLoading(false);
      setInfoMessage(MESSAGES.INACTIVE_INTEGRATION);
    } else if (!construct.isIntEventCreated) {
      setIsLoading(true);
      setInfoMessage(MESSAGES.AWAITING_EVENT_CREATION);
    } else if (construct.linkStatus === LINK_STATUS.PENDING) {
      setIsLoading(true);
      setInfoMessage(MESSAGES.PENDING_STATUS);
    } else if (userIntegration?.syncStatus) {
      setIsLoading(true);
      setInfoMessage(MESSAGES.INTEGRATION_SYNC);
    } else if (construct.linkStatus === LINK_STATUS.FAILED) {
      setIsLoading(false);
      setInfoMessage(MESSAGES.FAILURE_STATUS);
    } else {
      setIsLoading(false);
      setInfoMessage('');
    }
  }, [event, userIntegration]);

  return (
    <>
      {meeting && meeting.provider && (
        <Tippy
          trigger="mouseenter"
          theme="light"
          popperOptions={tippyOpts}
          disabled={!infoMessage.length}
          content={i18n._(infoMessage)}
        >
          <TippyWrapper>
            <Container>
              {meeting.isAuthorized && meeting.hasValidLink ? (
                <StyledLink href={meeting.link} target="_blank" rel="noreferrer">
                  {meeting.provider}
                </StyledLink>
              ) : (
                <DisabledLink>{meeting.provider}</DisabledLink>
              )}
              {(!meeting.isAuthorized || !meeting.hasValidLink) && !isLoading && (
                <SvgIcon
                  url={ErrorIcon}
                  width="18px"
                  height="18px"
                  defaultColor={COLORS.ORANGE_RED_CRAYOLA}
                  isDefaultColor={true}
                />
              )}
              {(meeting.linkStatus === LINK_STATUS.FAILED ||
                meeting.linkStatus === LINK_STATUS.PENDING ||
                !!userIntegration?.syncStatus) && // when there is no sync process the syncStatus is null
                meeting.isAuthorized &&
                (isLoading ? (
                  <RotatingSpinner
                    isLoading={isLoading}
                    content={
                      <SvgIcon
                        url={SyncIcon}
                        width="18px"
                        height="18px"
                        defaultColor={COLOR_PALETTE.BLUE_DARK}
                        isDefaultColor={true}
                      />
                    }
                  />
                ) : (
                  <SyncButton
                    onClick={() =>
                      meeting.linkStatus === LINK_STATUS.FAILED && requestMeetingLink()
                    }
                  >
                    <SvgIcon
                      url={SyncIcon}
                      width="18px"
                      height="18px"
                      defaultColor={COLOR_PALETTE.BLUE_DARK}
                      isDefaultColor={true}
                    />
                  </SyncButton>
                ))}
            </Container>
          </TippyWrapper>
        </Tippy>
      )}
    </>
  );
};

export default DigitalMeetingLink;
