import React, { useRef } from 'react';

import Tippy from '@tippyjs/react/headless';
import filter from 'lodash/filter';
import styled from 'styled-components';

import TextField from '~/components/TextField';

import useBoolState from '~/hooks/useBoolState';
import { COLOR_PALETTE, COLORS } from '~/styles';

const Wrap = styled.div`
  position: relative;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 200px;
  list-style: none;
  background: ${COLOR_PALETTE.WHITE};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 3px;
  overflow: hidden;
  :focus {
    outline: none;
  }
`;

const ListItem = styled.li`
  cursor: pointer;
  padding: 10px;
  border-bottom: solid 1px ${COLOR_PALETTE.DARK_GRAY};
  &:hover {
    background: ${COLORS.BG_PAGE};
    color: ${COLOR_PALETTE.WHITE};
  }
  &:last-child {
    border-bottom: none;
  }
`;

const AutocompleteField = ({
  value,
  searchItems,
  searchProp,
  onChange,
  maxItems = 5,
  ...props
}) => {
  const ref = useRef();
  const $open = useBoolState();
  const handleFocus = () => {
    $open.on();
  };

  const handleBlur = () => {
    setTimeout(() => {
      // we need to delay blur for bubble click event
      $open.off();
    }, 100);
  };

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const handleSelect = (item) => {
    onChange(item[searchProp], item);
    $open.off();
  };

  function renderPopover(attrs) {
    const items = filter(searchItems, (item) => {
      const val = item[searchProp] || '';
      return val && val.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    });
    if (!$open.value || items.length === 0) {
      return null;
    }

    const itemsToShow = maxItems === 0 ? items : items.slice(0, maxItems);
    const box = ref.current.getBoundingClientRect();

    return (
      <List tabIndex="-1" {...attrs} style={{ width: box.width }}>
        {itemsToShow.map((item) => (
          <ListItem key={item.id || item.key} onClick={() => handleSelect(item)}>
            {item[searchProp]}
          </ListItem>
        ))}
      </List>
    );
  }

  return (
    <Wrap>
      <TextField
        ref={ref}
        value={value}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        {...props}
      />
      <Tippy
        visible
        placement="bottom-start"
        interactive
        maxWidth={650}
        reference={ref}
        render={renderPopover}
      />
    </Wrap>
  );
};

AutocompleteField.defaultProps = {
  searchProp: 'name',
  searchItems: [],
};

export default React.memo(AutocompleteField);
