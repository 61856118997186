import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { Button, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: auto;
`;

export const TitleWrapper = styled.div`
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 26px;
  line-height: 1;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin: 0px;
  padding: 8px 14px 0;
`;

export const HeaderActions = styled.div`
  display: flex;
  gap: 10px;
  color: ${COLORS.SUBTEXT};
  align-items: center;
`;

export const DeleteButton = styled(Button)`
  svg {
    height: 18px;
    width: 18px;
  }
`;

export const SaveButton = styled(Button)`
  svg {
    height: 16px;
    width: 16px;
  }
`;

export const CloseButton = styled(Button)`
  height: 34px;
  width: 34px;
`;

interface IProps {
  isUpdate: boolean;
  isOriginalMode?: boolean;
  handleOnDeleteClick: () => void;
  handleOnSaveClick: () => void;
  onClose: () => void;
}

export const Header = ({
  isUpdate,
  isOriginalMode,
  handleOnDeleteClick,
  handleOnSaveClick,
  onClose,
}: IProps) => {
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>
          <Trans>{isUpdate ? 'Edit goal' : 'Create a personal goal'}</Trans>
        </Title>
        <HeaderActions>
          {isUpdate && isOriginalMode && (
            <DeleteButton
              icon={ICONS.DELETE_BIN}
              variant={ButtonVariant.ICON}
              onClick={handleOnDeleteClick}
            />
          )}
          {isUpdate && (
            <SaveButton
              icon={ICONS.SAVE}
              variant={ButtonVariant.ICON}
              onClick={handleOnSaveClick}
            />
          )}
          <Button
            iconSize={ICON_SIZES.LARGE}
            icon={ICONS.CLOSE}
            variant={ButtonVariant.ICON}
            onClick={onClose}
          />
        </HeaderActions>
      </TitleWrapper>
    </Wrapper>
  );
};
