import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import CoachTeamsPage from '~/pages/CoachMembersPage';
import TeamOverview from '~/pages/TeamOverview';
import Teams from '~/pages/Teams';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('teams', {
  routesPrivate: {
    user: [],
    coach: [
      <PrivateRoute
        key={routes.TEAM_COACH.routePath(ROLES.COACH)}
        exact
        path={routes.TEAM_COACH.routePath(ROLES.COACH)}
        component={CoachTeamsPage}
        allowedRoles={[ROLES.COACH]}
      />,
    ],
    admin: [
      <PrivateRoute
        key={routes.TEAMS.routePath(ROLES.ADMIN)}
        exact
        path={routes.TEAMS.routePath(ROLES.ADMIN)}
        component={Teams}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.TEAM.routePath(ROLES.ADMIN)}
        exact
        path={routes.TEAM.routePath(ROLES.ADMIN)}
        component={TeamOverview}
        allowedRoles={[ROLES.ADMIN]}
      />,
    ],
  },
  routesPublic: [],
});
