export enum MODEL_TYPE {
  NONE = 'NONE',
  CREATE_SKILL = 'CREATE_SKILL',
  CREATE_CATEGORY_LEVEL = 'CREATE_CATEGORY_LEVEL',
  CREATE_FOCUS_AREA = 'CREATE_FOCUS_AREA',
  EDIT_SKILL_NAME = 'EDIT_SKILL_NAME',
  EDIT_SKILL_CATEGORY = 'EDIT_SKILL_CATEGORY',
  EDIT_FOCUS_AREA = 'EDIT_FOCUS_AREA',
  EDIT_CATEGORY_LEVEL = 'EDIT_CATEGORY_LEVEL',
}

export enum MULTI_LANG {
  en_GB = 'en_GB',
  nl_NL = 'nl_NL',
  de_DE = 'de_DE',
}

export enum MODAL_TYPE {
  NEW_SKILL_PROFILE = 'NEW_SKILL_PROFILE',
  IMPORT_ROLES = 'IMPORT_ROLES',
  CLEANUP_ROLES = 'CLEANUP_ROLES',
  SYNC_ROLES = 'SYNC_ROLES',
  ROLE_RELEVANCIES = 'ROLE_RELEVANCIES',
}

export enum VIEW_TYPE {
  SKILL_VIEW = 'SKILL_VIEW',
  SKILL_EDIT = 'SKILL_EDIT',
}
