import { createReducer } from 'redux-act';

import * as currentRequestActions from './actions';

const INITIAL_STATE = {};

export const currentRequest = createReducer(
  {
    [currentRequestActions.setCurrentRequest]: (_state, payload) => {
      return {
        ...INITIAL_STATE,
        ...(payload || {}),
      };
    },

    [currentRequestActions.updateCurrentRequest]: (state, payload) => {
      return {
        ...state,
        ...(payload || {}),
      };
    },
  },
  INITIAL_STATE,
);
