import FileSaver from 'file-saver';

import type { IServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

import type { IReview } from '@learned/types';

export function fetchReviews(body: {
  filters?: { search?: string };
  options?: { skip?: number; limit?: number };
}) {
  return cloudRequest(serverRoutes.reviews.reportsNineGridFacets as IServerRoute, {}, body);
}

export function fetchReviewNineGridMatrix(reviewId: IReview['id'], filters: object) {
  return cloudRequest(
    serverRoutes.reviews.reportsNineGridMatrix as IServerRoute,
    {},
    { reviewId, filters },
  );
}

export function downloadNineGridCSV({
  reviewIds,
  themes,
  jobProfiles,
  employees,
}: {
  reviewIds: (string | undefined)[];
  themes: string[];
  jobProfiles: string[];
  employees: string[];
}) {
  return cloudRequest(
    serverRoutes.reviews.reportsNineGridExport as IServerRoute,
    {},
    {
      reviewIds,
      filters: {
        themes,
        jobProfiles,
        employees,
      },
    },
  ).then((response) => {
    FileSaver.saveAs(response, 'nine-grid-report.csv');
  });
}
