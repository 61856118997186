import { ACTIVITY_STATUSES } from '@learned/constants';
import { t } from '@lingui/macro';

// eslint-disable-next-line
import { PATH_STATUSES } from '~/constants/index';

import { COLORS } from '~/styles';

export const ACTIVITY_STATUS_STYLES = {
  [ACTIVITY_STATUSES.COMPLETED]: {
    key: ACTIVITY_STATUSES.COMPLETED,
    title: (i18n) => i18n._(t`Completed`),
    color: COLORS.ACCENT_SUCCESS,
  },
  // we need onTrack status to render old activity updates
  [ACTIVITY_STATUSES.ON_TRACK]: {
    key: ACTIVITY_STATUSES.ON_TRACK,
    title: (i18n) => i18n._(t`On track`),
    color: '#005efe',
  },
  [ACTIVITY_STATUSES.TODO]: {
    key: ACTIVITY_STATUSES.TODO,
    title: (i18n) => i18n._(t`To do`),
    color: '#005efe',
  },
  [ACTIVITY_STATUSES.IN_PROGRESS]: {
    key: ACTIVITY_STATUSES.IN_PROGRESS,
    title: (i18n) => i18n._(t`In progess`),
    color: '#005efe',
  },
  [PATH_STATUSES.ACTIVE.key]: {
    key: PATH_STATUSES.ACTIVE.key,
    title: (i18n) => i18n._(t`Active`),
    color: '#005efe',
  },
  [ACTIVITY_STATUSES.PROBLEM]: {
    key: ACTIVITY_STATUSES.PROBLEM,
    title: (i18n) => i18n._(t`Problem`),
    color: COLORS.WARNING_LIGHT,
  },
};
