import React, { useCallback, useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty, sortBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

import FeedbackThanksModal from '~/components/FeedbackThanksModal';
import { HeaderTabs } from '~/components/HeaderTabs';
import OverviewHeading from '~/components/OverviewHeading';
import RTFeedbackAskModal from '~/components/RTFeedbackAskModal';
import { TableList } from '~/components/TableList';
import BaseLayout from '~/layouts/BaseLayout';

import CompanyFeedbackCycleSettings from './components/CompanyFeedbackCycleSettings';
import RTFeedbackCard from './components/RTFeedbackCard';

import type { IColumnTable } from '~/@types/table';
import { ROLES, RT_FEEDBACK_TYPES } from '~/constants';
import routes from '~/constants/routes';
import { usePagination } from '~/hooks/usePagination';
import { getUsers, getUser } from '~/selectors/baseGetters';
import { getAskedRTFeedbacks, getProvidedRTFeedbacks } from '~/services/RTFeedbacks';
import { COLORS } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';

import type { IUser, RTFeedback } from '@learned/types';
import type { I18n } from '@lingui/core';

enum TAB_TYPES {
  ALL = 'all',
  ASKED = 'asked',
  PROVIDED = 'provided',
  CYCLES = 'feedback_cycles',
}

const initialFilters = {
  search: '',
};

const HeaderWrap = styled.div`
  width: fit-content;
  background-color: ${COLORS.WHITE};
  border-radius: 6px 6px 0 0;
`;

const TableWrap = styled.div`
  tr {
    background-color: ${COLORS.BG_LIST};
  }
  th {
    display: none;
  }
`;

const FeedbackCycleWrapper = styled.div`
  background-color: ${COLORS.WHITE};
`;

const RTFeedbacks = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isAskRTFeedbackModal, setIsAskRTFeedbackModal] = useState<boolean>(false);
  const [isThanksModal, setIsThanksModal] = useState<boolean>(false);
  const [thanksModalType, setThanksModalType] = useState<string | null>(null);
  const [thanksModalUser, setThanksModalUser] = useState(null);
  const [rTFeedbacks, setRTFeedbacks] = useState<Array<RTFeedback>>([]);
  const [type, setType] = useState<string>();
  const { i18n } = useLingui();
  const history = useHistory();
  const users = useSelector(getUsers);
  const user = useSelector(getUser);
  const currentUser = useSelector(getUser);
  const { pagination, changePagination, resetPagination } = usePagination(10);
  const [currentFilters, setCurrentFilters] = useState(initialFilters);
  const [filteredFeedbacks, setFilteredFeedbacks] = useState<Array<RTFeedback>>([]);
  const location = useLocation();

  const checkTab = useCallback(() => {
    const url = location.pathname;
    let currentTab = TAB_TYPES.ALL;
    if (url === routes.RTFEEDBACKS.build()) {
      currentTab = TAB_TYPES.ALL;
    }
    if (url === routes.RTFEEDBACKS_ASKED.build()) {
      currentTab = TAB_TYPES.ASKED;
    }
    if (url === routes.RTFEEDBACKS_PROVIDED.build()) {
      currentTab = TAB_TYPES.PROVIDED;
    }
    if (url === routes.FEEDBACK_CYCLES.build()) {
      currentTab = TAB_TYPES.CYCLES;
    }
    return currentTab;
  }, [location]);

  const selectedTab = checkTab();

  const HEADER_TABS = [
    {
      key: TAB_TYPES.ALL,
      label: (i18n: I18n): string => i18n._(t`All feedback`),
    },
    {
      key: TAB_TYPES.ASKED,
      label: (i18n: I18n): string => i18n._(t`Asked`),
    },
    {
      key: TAB_TYPES.PROVIDED,
      label: (i18n: I18n): string => i18n._(t`Provided`),
    },
  ];

  if (user.isAdmin) {
    HEADER_TABS.push({
      label: (i18n: I18n) => i18n._(t`Feedback cycles`),
      key: TAB_TYPES.CYCLES,
    });
  }

  const setActiveTab = (tab: string) => {
    const item = HEADER_TABS.find((i) => i.key === tab);
    switch (item?.key) {
      case TAB_TYPES.ALL:
        // lint-ignore-next-line
        /* @ts-ignore */
        history.replace(routes.RTFEEDBACKS.build({ role: ROLES.USER }));
        break;
      case TAB_TYPES.ASKED:
        // lint-ignore-next-line
        /* @ts-ignore */
        history.replace(routes.RTFEEDBACKS_ASKED.build({ role: ROLES.USER }));
        break;
      case TAB_TYPES.PROVIDED:
        // lint-ignore-next-line
        /* @ts-ignore */
        history.replace(routes.RTFEEDBACKS_PROVIDED.build({ role: ROLES.USER }));
        break;
      case TAB_TYPES.CYCLES:
        // lint-ignore-next-line
        /* @ts-ignore */
        history.replace(routes.FEEDBACK_CYCLES.build({ role: ROLES.ADMIN }));
        break;
    }
  };

  // lint-ignore-next-line
  const fetchData = useCallback(async () => {
    setLoading(true);
    const currentTab = selectedTab || checkTab();
    const [askedRTFeedbacks, providedRTFeedbacks] = await Promise.all([
      (currentTab === TAB_TYPES.ALL || currentTab === TAB_TYPES.ASKED) &&
        getAskedRTFeedbacks(['goal', 'skills']),
      (currentTab === TAB_TYPES.ALL || currentTab === TAB_TYPES.PROVIDED) &&
        getProvidedRTFeedbacks(['goal', 'skills']),
    ]);
    // filter role feedbacks for now
    const filteredRTFeedbacks = [
      ...(Object.values(askedRTFeedbacks || {}) || []),
      ...(Object.values(providedRTFeedbacks || {}) || []),
      /* @ts-ignore */
    ].filter((f: RTFeedback) => f && !f.requestDeletedStatus); // hide RTF deleted by interviewee
    const sortedRTFeedbacks: Array<RTFeedback> = sortBy(
      filteredRTFeedbacks,
      (f: RTFeedback) => f.meta.createdDate,
    ).reverse() as Array<RTFeedback>;

    setRTFeedbacks(sortedRTFeedbacks);
    setFilteredFeedbacks(sortedRTFeedbacks);
    setLoading(false);
  }, [selectedTab, checkTab]);

  const filters = {
    search: currentFilters.search,
    setSearch: (value: string) => {
      setCurrentFilters({ ...currentFilters, search: value });
      resetPagination();
    },
  };

  const isSearchMatch = useCallback(
    (user: IUser) => {
      return (
        getUserFullName(user)?.toLowerCase()?.includes(filters.search.toLowerCase()) ||
        user?.email?.toLowerCase()?.includes(filters.search.toLowerCase())
      );
    },
    [filters.search],
  );

  const onSearch = useCallback(() => {
    const filteredRTFeedbacks = rTFeedbacks.filter((item: RTFeedback) => {
      if (item.users) {
        return (
          item.users.find((u: string) => isSearchMatch(users[u])) ||
          item.emails.find((e: string) => e.toLowerCase().includes(filters.search.toLowerCase()))
        );
      }
      const user = users[currentUser.id === item.createdFor ? item.createdBy : item.createdFor];
      return isSearchMatch(user);
    });
    setFilteredFeedbacks(filteredRTFeedbacks);
  }, [rTFeedbacks, users, currentUser.id, isSearchMatch, filters.search]);

  const onRemoveRTFeedback = (feedbackId: string) => {
    const filteredRTFeedbacks = rTFeedbacks.filter(
      (feedback: RTFeedback) => feedback.id !== feedbackId,
    );
    setRTFeedbacks(filteredRTFeedbacks);
  };

  const onRemoveRequest = (feedbackId: string) => {
    const filteredRTFeedbacks = rTFeedbacks.filter(
      (feedback: RTFeedback) => feedback.id !== feedbackId,
    );
    setRTFeedbacks(filteredRTFeedbacks);
  };

  const onShareFeedback = ({
    feedback,
    feedbackId,
  }: {
    feedback: RTFeedback;
    feedbackId: string;
  }) => {
    const filteredRTFeedbacks: Array<RTFeedback> = rTFeedbacks.map((fb: RTFeedback) => {
      if (fb.id === feedbackId) {
        fb.feedback = feedback;
      }
      return fb;
    });
    const activeRTFB: RTFeedback = rTFeedbacks.find(
      (fb: RTFeedback) => fb.id === feedbackId,
    ) as RTFeedback;
    if (!isEmpty(activeRTFB)) {
      const userFor = users[activeRTFB.createdBy];
      if (!isEmpty(userFor)) {
        setFilteredFeedbacks(filteredRTFeedbacks);
        setIsThanksModal(true);
        setThanksModalType(RT_FEEDBACK_TYPES.GIVE_FEEDBACK.key);
        setThanksModalUser(userFor);
      }
    }
  };

  const COLUMNS: IColumnTable<RTFeedback>[] = [
    {
      name: '',
      accessor: 'name',
      minWidth: '737px',
      renderCell: (RTFeedback) => {
        return (
          /* @ts-ignore */
          <RTFeedbackCard
            key={RTFeedback.id}
            RTFeedback={RTFeedback}
            onShareFeedback={onShareFeedback}
            onRemoveRTFeedback={onRemoveRTFeedback}
            onRemoveRequest={onRemoveRequest}
          />
        );
      },
    },
  ];

  const showAskRTFeedbackModal = (type: string) => {
    setIsAskRTFeedbackModal(true);
    setType(type);
  };

  const giveFeedbackButton = {
    label: 'Give feedback',
    onClick: () => showAskRTFeedbackModal(RT_FEEDBACK_TYPES.GIVE_FEEDBACK.key),
  };

  const askFeedbackButton = {
    label: 'Ask feedback',
    onClick: () => showAskRTFeedbackModal(RT_FEEDBACK_TYPES.ASK_FEEDBACK.key),
  };

  const getActionButton = () => {
    if (selectedTab === TAB_TYPES.PROVIDED) {
      return giveFeedbackButton;
    }
    return askFeedbackButton;
  };

  const getSecondaryButton = () => {
    if (selectedTab === TAB_TYPES.ALL) {
      return giveFeedbackButton;
    }
    return undefined;
  };

  const hideAskRTFeedbackModal = (isShowThanksModal: boolean) => {
    setIsAskRTFeedbackModal(false);
    if (isShowThanksModal) {
      setIsThanksModal(true);
      setThanksModalType(type!);
    }
    fetchData();
  };

  const hideThanksModal = () => {
    setIsThanksModal(false);
    setThanksModalType(null);
    setThanksModalUser(null);
  };

  const handlePagination = useCallback(() => {
    const result = rTFeedbacks.slice(pagination.skip, pagination.limit * pagination.index + 1);
    setFilteredFeedbacks(result);
  }, [pagination, rTFeedbacks]);

  useEffect(() => {
    fetchData();
  }, [selectedTab, fetchData]);

  useEffect(() => {
    onSearch();
  }, [filters.search, onSearch]);

  useEffect(() => {
    handlePagination();
  }, [pagination, handlePagination]);

  return (
    <>
      {/* @ts-ignore */}
      <OverviewHeading title={i18n._(t`Feedback`)} smallWidth />
      <BaseLayout smallWidth>
        <HeaderWrap>
          <HeaderTabs tabs={HEADER_TABS} selectedTab={selectedTab} handleChangeTab={setActiveTab} />
        </HeaderWrap>
        {selectedTab === TAB_TYPES.CYCLES ? (
          <FeedbackCycleWrapper>
            <CompanyFeedbackCycleSettings />
          </FeedbackCycleWrapper>
        ) : (
          <TableWrap>
            <TableList
              data={filteredFeedbacks}
              columns={COLUMNS}
              paginationProps={{
                changePagination,
                pagination,
                totalCount: rTFeedbacks.length,
              }}
              filtersProps={{
                filters,
                isFiltered: false,
              }}
              placeholderProps={{
                emptyStateText: 'No feedback available',
              }}
              isLoading={loading}
              actionButton={getActionButton()}
              secondaryButton={getSecondaryButton()}
            />
          </TableWrap>
        )}
        {isAskRTFeedbackModal && (
          /* @ts-ignore */
          <RTFeedbackAskModal onClose={hideAskRTFeedbackModal} type={type} />
        )}
        {isThanksModal && (
          <FeedbackThanksModal
            onClose={hideThanksModal}
            type={thanksModalType}
            fromUser={thanksModalUser}
          />
        )}
      </BaseLayout>
    </>
  );
};

export { RTFeedbacks };
