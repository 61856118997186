import React, { useState } from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import AddPeopleAutoCompleteModal from '~/components/AddPeopleAutoCompleteModal';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import PaginationBar from '~/components/PaginationBar';
import { Table } from '~/components/Table';
import { TH } from '~/components/Table/design';
import { TablePlaceholder } from '~/components/TablePlaceholder';
import AvatarCard from '~/components/UI/AvatarCard';
import { TableHeader } from '~/pages/Surveys/TableHeader';
import { SectionParticipantsType } from '~/pages/SurveyUpdate/components/StepParticipants/SectionParticipantsType';
import type { IGeneralForm } from '~/pages/SurveyUpdate/types';

import type { IColumnTable } from '~/@types/table';
import { useAutoSaveFunc } from '~/hooks/useAutoSave';
import { usePagination } from '~/hooks/usePagination';
import getUserFullName from '~/utils/getUserFullName';

import type { IUser } from '@learned/types';
import type { I18n } from '@lingui/core';
import type { UseFormReturn } from 'react-hook-form';

const Wrapper = styled.div`
  border: 1px solid #f3f7fd;
  border-radius: 10px;
`;

export enum EMPLOYEE_SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
}

export const RowWrapper = styled.div`
  width: 100%;
`;

const EMPLOYEE_COLUMNS: IColumnTable<IUser>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Selected participants`),
    minWidth: '300px',
    accessor: 'name',
    renderCell: (item) => {
      return (
        <RowWrapper key={item.id}>
          {/* @ts-ignore */}
          <AvatarCard styles={{ paddingLeft: 0 }} type="small" size={32} userLocked={item} />
        </RowWrapper>
      );
    },
    sortBy: {
      asc: {
        key: EMPLOYEE_SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: EMPLOYEE_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
];

const StyledTable = styled(Table)`
  ${TH} {
    max-width: 20px;
    padding-right: 8px;
  }
`;

const initialFilters = {
  search: '',
  sortBy: EMPLOYEE_SORT_OPTIONS.NAME_A_Z,
};

type IFilter = {
  search: string;
  sortBy: EMPLOYEE_SORT_OPTIONS;
};

interface ISectionEmployees {
  formMethods: UseFormReturn<IGeneralForm>;
  onAdd: (ids: { id: string; name: string; url?: string }[]) => void;
  onDelete: (deletedIds: string[]) => void;
  autosave: useAutoSaveFunc;
}
const SectionEmployees = ({ formMethods, onAdd, onDelete, autosave }: ISectionEmployees) => {
  const { i18n } = useLingui();
  const { pagination, changePagination, changePageSize, resetPagination } = usePagination(10);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const { watch } = formMethods;
  const [selectedItems, setSelectedItems] = useState<IUser['id'][]>([]);
  const [currentFilters, setCurrentFilters] = useState<IFilter>(initialFilters);

  let items = watch('participants.ids');

  if (currentFilters.search) {
    items = items.filter((item) => {
      // @ts-ignore
      const userName = getUserFullName(item);
      return userName.toLowerCase().includes(currentFilters.search.toLowerCase());
    });
  }

  const actionButton = {
    label: t`Invite`,
    onClick: () => setIsInviteModalOpen(true),
  };

  const isAllSelected = items.every((item) => {
    return selectedItems.includes(item.id);
  });
  const onSelectAll = () => {
    const selectedItems = isAllSelected ? [] : items.map((item) => item.id);
    setSelectedItems(selectedItems);
  };
  const onSelectItem = (selectedItemId: string) => {
    const isSelected = selectedItems.includes(selectedItemId);
    setSelectedItems(
      isSelected
        ? selectedItems.filter((itemId) => itemId !== selectedItemId)
        : [...selectedItems, selectedItemId],
    );
  };

  const multiSelect = {
    checkedCount: selectedItems.length,
    onCheckAll: onSelectAll,
    onSelectItem: (item: IUser) => onSelectItem(item.id),
    isItemChecked: (item: IUser) => selectedItems.includes(item.id),
    isAllChecked: isAllSelected,
    onDelete: async () => {
      const isConfirmed = (await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Are you sure want to delete user(s)?`),
        description: i18n._(
          t`All selected users will be removed from the survey. This action cannot be undone.`,
        ),
      })) as unknown as boolean;

      if (isConfirmed) {
        onDelete(selectedItems);
        setSelectedItems([]);

        const isEnoughItemsOnPage =
          (items.length - selectedItems.length) / pagination.limit > pagination.index - 1;

        if (!isEnoughItemsOnPage) {
          resetPagination(); // reset pagination if no more items left on the page
        }
      }
    },
  };

  const filters = {
    search: currentFilters.search,
    setSearch: (value: string) => {
      setCurrentFilters({ ...currentFilters, search: value });
      resetPagination();
    },
  };

  const itemsFiltered = _.chain(items)
    .orderBy(currentFilters.sortBy)
    .drop(pagination.skip)
    .take(pagination.limit)
    .value();

  return (
    <Wrapper>
      <TableHeader filters={filters} actionButton={items.length > 0 ? actionButton : undefined} />
      <StyledTable
        data={itemsFiltered}
        columns={EMPLOYEE_COLUMNS}
        sortBy={currentFilters.sortBy}
        setSortBy={(sortBy: EMPLOYEE_SORT_OPTIONS) =>
          setCurrentFilters({ ...currentFilters, sortBy })
        }
        showMultiSelect
        multiSelect={multiSelect}
        showMenu
        showDraftStatus
      />
      {isEmpty(items) ? (
        <SectionParticipantsType
          formMethods={formMethods}
          onInvite={() => setIsInviteModalOpen(true)}
          autosave={autosave}
        />
      ) : (
        isEmpty(itemsFiltered) && (
          /* @ts-ignore */
          <TablePlaceholder
            isFiltered={!!currentFilters.search.length}
            noResultText={i18n._(t`No employees found`)}
            emptyStateText={i18n._(t`No employees yet… Let’s invite one!`)}
          />
        )
      )}
      <PaginationBar
        pagination={pagination}
        changePagination={changePagination}
        changePageSize={changePageSize}
        count={items.length}
        noShadow
        noBorder
        noTopBorder
        showCount
      />
      {isInviteModalOpen && (
        /* @ts-ignore */
        <AddPeopleAutoCompleteModal
          title={i18n._(t`Selecting employees`)}
          usersToHide={items.map((item) => item.id)}
          onModalClose={() => setIsInviteModalOpen(false)}
          onSubmit={onAdd}
        />
      )}
    </Wrapper>
  );
};

export { SectionEmployees };
