import React from 'react';

// eslint-disable-next-line
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { List } from './List';

import type { DraggableProps, Item } from './types';

const reorder = (list: Array<Item>, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DraggableList = ({
  items,
  onChange,
  isDragAreaEverywhere = false,
  isDragDisabled = false,
}: DraggableProps) => {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const itemsReordered = reorder(items, result.source.index, result.destination.index);
    items = itemsReordered;
    onChange(itemsReordered);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <List
              items={items}
              isDragAreaEverywhere={isDragAreaEverywhere}
              isDragDisabled={isDragDisabled}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export { DraggableList };
