import { useLingui } from '@lingui/react';
import { useForm } from 'react-hook-form';

import { createJobFamilySuperAdmin, updateJobFamilySuperAdmin } from '~/services/jobFamilies';

import type { IJobFamilyForm, IUseJobFamiliesModalProps } from './types';

const useJobFamiliesModal = ({ jobFamily, closeModal, refetchData }: IUseJobFamiliesModalProps) => {
  const { i18n } = useLingui();

  const formMethods = useForm<IJobFamilyForm>({
    mode: 'all',
    defaultValues: {
      name: jobFamily?.name,
    },
  });

  const onSubmit = async ({ name }: IJobFamilyForm) => {
    try {
      if (jobFamily) {
        await updateJobFamilySuperAdmin(jobFamily.id, { name });
      } else {
        await createJobFamilySuperAdmin({ name });
      }
    } catch (error) {
      console.error(error);
    } finally {
      refetchData();
      closeModal();
    }
  };

  return { formMethods, i18n, onSubmit };
};

export { useJobFamiliesModal };
