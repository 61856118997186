import React, { useEffect, Fragment, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE, GOAL_STATUSES_NEW, GOAL_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import DashboardHeader from '~/components/DashboardHeader';
import GoalDashboardCard from '~/components/GoalDashboardCard';
import { ICONS } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import ToolTip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import BaseLayout from '~/layouts/BaseLayout';
import { getBackPath } from '~/pages/GoalSetup/getBackPath';

import { ActionsWrap, ActionItemBlock, HeaderButtonWrap, PageContainer } from './design';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { getUser, checkModuleRTFeedbacks, getTeams } from '~/selectors/baseGetters';
import { getCurrentGoal } from '~/selectors/currentGoal';
import { deleteGoal } from '~/services/goals';
import * as currentGoalActions from '~/store/currentGoal/actions';
import { COLORS } from '~/styles';
import { isUserCanEditGoal } from '~/utils/isUserCanEditGoal';

const WARNING_MODAL_TYPES = {
  DELETE: 'delete',
  ARCHIVE: 'archive',
};

const GoalDashboard = () => {
  const $loading = useBoolState(true);
  const [isShowWarningModal, setIsShowWarningModal] = useState(false);
  const [warningType, setWarningType] = useState(null);
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { i18n } = useLingui();
  const goal = useSelector(getCurrentGoal);
  const user = useSelector(getUser);
  const teams = useSelector(getTeams);
  const isModuleRTFeedbacksEnabled = useSelector(checkModuleRTFeedbacks);

  const goalId = get(params, 'goalId');
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const from = get(query, 'from');
  const { addToast } = useToasts();

  const isOwner = goal?.owners?.includes(user.id);
  const isEditor = isUserCanEditGoal({ goal, user, teams });

  useEffect(() => {
    let isMounted = true;
    const fetch = async () => {
      if (goalId && isMounted) {
        dispatch(currentGoalActions.fetchGoal(goalId));
        $loading.off();
      }
    };

    fetch();

    return () => {
      isMounted = false;
      dispatch(currentGoalActions.resetCurrentGoal());
    };
    // eslint-disable-next-line
  }, [goalId]);

  const getBackURL = () => {
    return getBackPath(location.hash.slice(1) ? from + '#' + location.hash.slice(1) : from);
  };

  const editGoal = () => {
    history.push(
      routes.GOAL_UPDATE.build(
        {},
        {
          goalId: goal.id,
          isBackPath: true,
        },
      ),
    );
  };

  const goBack = () => {
    history.push(getBackURL());
  };

  const removeGoal = async () => {
    $loading.on();
    await deleteGoal(goal.id);
    setWarningType(null);
    setIsShowWarningModal(false);
    goBack();
    $loading.off();
    addToast({
      title: i18n._(t`Goal deleted`),
      type: TOAST_TYPES.INFO,
    });
  };

  const onChangeArchiveStatus = async () => {
    $loading.on();
    if (goal.status === GOAL_STATUSES_NEW.ARCHIVED) {
      await dispatch(currentGoalActions.updateGoalArchiveStatus(false));
      addToast({
        title: i18n._(t`Goal unarchived`),
        type: TOAST_TYPES.INFO,
      });
      $loading.off();
    } else {
      await dispatch(currentGoalActions.updateGoalArchiveStatus(true));
      setWarningType(null);
      setIsShowWarningModal(false);
      addToast({
        title: i18n._(t`Goal archived`),
        type: TOAST_TYPES.INFO,
      });
      $loading.off();
    }
  };

  const isShowRTFB =
    (goal?.type === GOAL_TYPES.PERSONAL || goal?.type === GOAL_TYPES.BUSINESS) &&
    isModuleRTFeedbacksEnabled;

  const isArchivedStatus = goal?.status === GOAL_STATUSES_NEW.ARCHIVED;

  return (
    <Fragment>
      <DashboardHeader
        title={goal?.name || ''}
        // @ts-ignore
        onBack={goBack}
        actions={
          isEditor && (
            <ActionsWrap>
              <ActionItemBlock>
                <ToolTip size={TOOLTIP_SIZES.BIG} disabled={false} tooltip={i18n._(t`Delete`)}>
                  <HeaderButtonWrap>
                    <Button
                      label=""
                      size={ButtonSize.MEDIUM}
                      disabled={$loading.value}
                      variant={ButtonVariant.ICON_DELETE}
                      onClick={() => {
                        setWarningType(WARNING_MODAL_TYPES.DELETE);
                        setIsShowWarningModal(true);
                      }}
                    />
                  </HeaderButtonWrap>
                </ToolTip>
              </ActionItemBlock>
              {goal.status !== GOAL_STATUSES_NEW.DRAFT && (
                <ActionItemBlock>
                  <ToolTip
                    size={TOOLTIP_SIZES.BIG}
                    disabled={false}
                    tooltip={isArchivedStatus ? i18n._(t`Unarchive`) : i18n._(t`Archive`)}
                  >
                    <HeaderButtonWrap>
                      <Button
                        label=""
                        size={ButtonSize.MEDIUM}
                        disabled={$loading.value}
                        variant={ButtonVariant.ICON}
                        icon={isArchivedStatus ? ICONS.UNARCHIVE : ICONS.ARCHIVE}
                        onClick={
                          isArchivedStatus
                            ? () => onChangeArchiveStatus()
                            : () => {
                                setWarningType(WARNING_MODAL_TYPES.ARCHIVE);
                                setIsShowWarningModal(true);
                              }
                        }
                      />
                    </HeaderButtonWrap>
                  </ToolTip>
                </ActionItemBlock>
              )}
              {!isArchivedStatus && (
                <ActionItemBlock>
                  <ToolTip size={TOOLTIP_SIZES.BIG} disabled={false} tooltip={i18n._(t`Edit`)}>
                    <HeaderButtonWrap>
                      <Button
                        label=""
                        size={ButtonSize.MEDIUM}
                        disabled={$loading.value}
                        variant={ButtonVariant.ICON}
                        icon={ICONS.EDIT_PENCIL}
                        onClick={editGoal}
                      />
                    </HeaderButtonWrap>
                  </ToolTip>
                </ActionItemBlock>
              )}
            </ActionsWrap>
          )
        }
      />
      <BaseLayout>
        <ShowSpinnerIfLoading loading={$loading.value}>
          <PageContainer>
            <GoalDashboardCard
              goal={goal}
              loading={$loading.value}
              canUpdateActivityProgress={isOwner}
              isOwner={isOwner}
              isShowRTFB={isShowRTFB}
            />
          </PageContainer>
        </ShowSpinnerIfLoading>
      </BaseLayout>
      {isShowWarningModal && (
        <ConfirmationModal
          type={
            warningType === WARNING_MODAL_TYPES.DELETE
              ? CONFIRMATION_MODAL_TYPE.DELETE
              : CONFIRMATION_MODAL_TYPE.WARNING
          }
          description={
            warningType === WARNING_MODAL_TYPES.DELETE
              ? i18n._(t`Are you sure you want to delete this goal?`)
              : i18n._(
                  t`Are you sure you want to archive this goal? It is not possible to update the progress of an archived goal.`,
                )
          }
          onClose={() => {
            setWarningType(null);
            setIsShowWarningModal(false);
          }}
          onSubmit={warningType === WARNING_MODAL_TYPES.DELETE ? removeGoal : onChangeArchiveStatus}
          cancelButtonTextColor={COLORS.TEXT_MAIN}
        />
      )}
    </Fragment>
  );
};

export default GoalDashboard;
