import React from 'react';

import { Trans } from '@lingui/macro';

import { DASHBOARD_TYPE } from '../constants';
import { SubHeaderWrap, MainSubHeaderText, RightSideHeaderText } from '../design';

interface ISubHeader {
  dashboardType: DASHBOARD_TYPE;
}

const SubHeader = ({ dashboardType }: ISubHeader) => {
  return (
    <SubHeaderWrap>
      <MainSubHeaderText>
        <Trans>Draft version</Trans>
      </MainSubHeaderText>
      <RightSideHeaderText>
        <Trans>
          This {dashboardType === DASHBOARD_TYPE.THEME ? 'theme' : 'template'} is not yet visible
          for others
        </Trans>
      </RightSideHeaderText>
    </SubHeaderWrap>
  );
};

export { SubHeader };
