import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import AmbitionsColumn from './AmbitionsColumn';
import AvatarColumn from './AvatarColumn';
import GoalsColumn from './GoalsColumn';
import Last1on1 from './Last1on1';
import LastFeedback from './LastFeedback';

import {
  checkModuleConversations,
  checkModuleGoals,
  checkModuleRTFeedbacks,
  isModuleAmbitionsEnabled,
} from '~/selectors/baseGetters';
import { COLORS, COLOR_SET, COLOR_PALETTE } from '~/styles';

// cols

const UserStatus = styled.div`
  width: 6px;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  position: absolute;
  background: ${(props) => props.$color};
  height: 100%;
`;

const Row = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 300px minmax(200px, 1fr) repeat(3, minmax(120px, 1fr));
  grid-column-gap: 8px;
  border-radius: 6px;
  border: solid 1px ${COLORS.BG_PAGE};
  background-color: ${COLOR_PALETTE.WHITE};
  min-height: 64px;
  margin-bottom: 8px;
`;

const Col = styled.div`
  display: flex;
  align-items: center;
`;

export const Placeholder = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const WaitingLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  white-space: nowrap;
`;

const MemberRow = ({ member, isWaiting }) => {
  const { i18n } = useLingui();
  const isAmbitionsEnabled = useSelector(isModuleAmbitionsEnabled);
  const isModuleGoalsEnabled = useSelector(checkModuleGoals);
  const isModuleConversationsEnabled = useSelector(checkModuleConversations);
  const isModuleRTFeedbacksEnabled = useSelector(checkModuleRTFeedbacks);

  return (
    <Row>
      <UserStatus
        $color={member.completedFirstTimeSetup ? COLOR_SET.CYAN_GREEN : COLOR_SET.ORANGE2}
      />
      <Col>
        <AvatarColumn userId={member.id} isWaiting={isWaiting} />
      </Col>
      {isWaiting ? (
        <Col>
          <WaitingLabel>{i18n._(t`Waiting to accept invite`)}..</WaitingLabel>
        </Col>
      ) : (
        <>
          {isModuleGoalsEnabled && (
            <Col>
              <GoalsColumn userId={member.id} data={member.goals} />
            </Col>
          )}
          {isModuleConversationsEnabled && (
            <Col>
              <Last1on1 userId={member.id} lastConversation={member.lastConversation} />
            </Col>
          )}
          {isModuleRTFeedbacksEnabled && (
            <Col>
              <LastFeedback lastFeedback={member.lastFeedback} />
            </Col>
          )}
          {isAmbitionsEnabled && (
            <Col>
              <AmbitionsColumn userId={member.id} items={member.ambitions} />
            </Col>
          )}
        </>
      )}
    </Row>
  );
};

export default React.memo(MemberRow);
