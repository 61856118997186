import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import emptyState from '~/assets/empty-state.webp';
import noResult from '~/assets/no-result.webp';

import { COLOR_PALETTE } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  background-color: ${COLOR_PALETTE.WHITE};
`;

interface IPlaceholderProps {
  isLoading: boolean;
  isFiltered: boolean;
}

const Placeholder = ({ isLoading, isFiltered }: IPlaceholderProps) => {
  const { i18n } = useLingui();
  return (
    <Wrapper>
      <ShowSpinnerIfLoading height="150px" loading={isLoading}>
        {isFiltered ? (
          <img src={emptyState} alt={i18n._(t`No result`)} width="216px" />
        ) : (
          <img src={noResult} alt={i18n._(t`No data found`)} width="216px" />
        )}
      </ShowSpinnerIfLoading>
    </Wrapper>
  );
};

export { Placeholder };
