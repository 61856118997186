import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

/**
 *
 * @param {'azure' | 'google'} type
 * @param {string} code
 * @returns {Promise<AxiosResponse<*>>}
 */
export function addUserIntegrationByCode(type, code) {
  return cloudRequest(serverRoutes.userIntegrations.addIntegration, {}, { type, code });
}

export function deleteUserIntegration(id) {
  return cloudRequest(serverRoutes.userIntegrations.deleteIntegration(id));
}

export function getUserIntegration(id) {
  return cloudRequest(serverRoutes.userIntegrations.getIntegration(id));
}

export function syncUserIntegration(id) {
  return cloudRequest(serverRoutes.userIntegrations.syncIntegration(id));
}
