import React from 'react';

import styled from 'styled-components';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  padding-top: 24px;

  &:first-child {
    padding-top: 0;
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.TEXT_MAIN};
`;

const Description = styled.div`
  margin-top: 5px;
  font-size: 14px;
  color: ${COLORS.SUBTEXT};
`;

const ChildrenWrapper = styled.div`
  margin-top: 17px;
`;

interface SectionProps {
  title: string;
  description?: string;
  children?: React.ReactNode;
}

const Section = ({ title, description, children }: SectionProps) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Wrapper>
  );
};

export { Section };
