import React, { useState } from 'react';

import { CONFIRMATION_MODAL_TYPE, SURVEY_TYPE } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { addDays } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';

import DatePicker from '~/components/DatePickerDeprecated';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import Switch from '~/components/Switch';

import { COLORS } from '~/styles';

interface IActivityModal {
  onClose: () => void;
  surveyType?: SURVEY_TYPE;
  oldIsEndDateValue?: boolean;
  oldEndDateValue: Date | null;
  onSubmit: (isEndDate: boolean, endDate: Date | null) => void;
}

const Block = styled.div`
  height: 60px;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  color: ${COLORS.BLACK};
  padding-bottom: 8px;
`;

const SwitchLabel = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.BLACK};
`;

const ChildrenContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const SwitchContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
`;

const ErrorText = styled.p`
  font-size: 12px;
  color: ${COLORS.ERROR};
`;

const DatePickerWrapper = styled.div`
  position: fixed;
  z-index: 999;
`;

const UnarchiveSurveyModal = ({
  surveyType,
  oldIsEndDateValue,
  oldEndDateValue,
  onSubmit,
  onClose,
}: IActivityModal) => {
  const isShowEndDateToggle = surveyType === SURVEY_TYPE.PULSE;
  const isRequireEndDate = surveyType === SURVEY_TYPE.TRADITIONAL;

  const { i18n } = useLingui();

  const [isEndDate, setIsEndDate] = useState(!!oldIsEndDateValue || isRequireEndDate);
  const [selectedEndDate, setSelectedEndDate] = useState(
    oldEndDateValue || addDays(new Date(), 28),
  );
  const [dateError, setDateError] = useState<string>();

  const onDateChange = (date: Date) => {
    setDateError(undefined);
    setSelectedEndDate(date);
  };

  const validate = () => {
    if (!isEndDate || (isEndDate && moment(selectedEndDate).isAfter(new Date(), 'day'))) {
      return true;
    } else {
      setDateError(i18n._(t`the end date should be in the future`));
      return false;
    }
  };

  return (
    <ConfirmationModal
      type={CONFIRMATION_MODAL_TYPE.WARNING}
      title={i18n._(t`Are you sure you want to unarchive the survey?`)}
      description={i18n._(
        t`This may cause the survey to be sent out again and you will be asked to set up a new end date.`,
      )}
      validations={validate}
      onSubmit={() => onSubmit(isEndDate, selectedEndDate)}
      onClose={onClose}
    >
      <ChildrenContainer>
        {isShowEndDateToggle && (
          <SwitchContainer>
            <Switch onChange={() => setIsEndDate(!isEndDate)} checked={isEndDate} />
            <SwitchLabel>
              <Trans>End survey on a specific date</Trans>
            </SwitchLabel>
          </SwitchContainer>
        )}

        {isEndDate && (
          <Block>
            <Label>
              <Trans>End date</Trans>
            </Label>
            <DatePickerWrapper>
              <DatePicker
                // eslint-disable-next-line
                // @ts-ignore
                selected={selectedEndDate}
                onChange={onDateChange}
                minDate={addDays(new Date(), 1)} // validation: from tomorrow on
                popperPlacement="top-end"
              />
              {dateError && <ErrorText>{dateError}</ErrorText>}
            </DatePickerWrapper>
          </Block>
        )}
      </ChildrenContainer>
    </ConfirmationModal>
  );
};

export { UnarchiveSurveyModal };
