import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import backIcon from '~/assets/ic-arrow-back-24-px.svg';

const Wrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  position: relative;
  width: ${(props) => props.isWithSidebar && '100%'};
  max-width: ${(props) => props.isWithSidebar && (props.isSideBarOpen ? '100%' : '1000px')};
  justify-content: space-between;
`;

const BlockLeft = styled.div`
  display: flex;
`;

const BlockRight = styled.div`
  display: flex;
  align-items: center;
`;

const BackButton = styled.button`
  border: none;
  background: none;
  padding: 4px;
  cursor: pointer;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  color: #3b3b3b;
  display: flex;
  align-items: center;
`;

class CardTools extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    backPath: PropTypes.string,
    renderActions: PropTypes.func,
  };

  render() {
    const { backPath, title, renderActions, children, isSideBarOpen, isWithSidebar } = this.props;

    return (
      <Wrapper isWithSidebar={isWithSidebar} isSideBarOpen={isSideBarOpen}>
        <BlockLeft>
          {backPath && (
            <BackButton onClick={typeof backPath === 'function' ? backPath : null}>
              {typeof backPath === 'string' ? (
                <Link to={backPath}>
                  <SvgIcon
                    classNameIcon="header-page-name_icon"
                    url={backIcon}
                    width="30px"
                    height="16px"
                    styles={{ marginLeft: '-10px' }}
                  />
                </Link>
              ) : (
                <SvgIcon
                  classNameIcon="header-page-name_icon"
                  url={backIcon}
                  width="30px"
                  height="16px"
                  styles={{ marginLeft: '-10px' }}
                />
              )}
            </BackButton>
          )}
          {title && <Title>{title}</Title>}
        </BlockLeft>

        <BlockRight>
          {renderActions && renderActions()}
          {children}
        </BlockRight>
      </Wrapper>
    );
  }
}

export default withRouter(CardTools);
