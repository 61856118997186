import React from 'react';

import styled from 'styled-components';

import { COLORS } from '~/styles';
import noMultyRun from '~/utils/noMultyRun';

const MAX_PADDING = '5px 28.5px';

const Wrapper = styled.div`
  position: relative;
`;

const SizeDefiner = styled.div<{ $fontSize?: number }>`
  opacity: 0;
  padding: ${MAX_PADDING};
  font-size: ${(props) => props.$fontSize || 14}px;
`;

const Button = styled.button<{
  $isDisabled?: boolean;
  $fontSize?: number;
  $noHaverAnimation?: boolean;
}>`
  color: ${COLORS.WHITE};
  background-color: ${(props) => (props.$isDisabled ? COLORS.INACTIVE : COLORS.BLUE_DARK)};
  border: none;
  cursor: pointer;
  font-size: ${(props) => props.$fontSize || 14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  white-space: nowrap;
  padding: 5px 24.4px 5px 23.9px;
  border-radius: 16px;
  transition: background-color 0.1s, padding 0.3s;

  ${({ $noHaverAnimation }) =>
    !$noHaverAnimation &&
    `
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  `}

  &:hover:enabled {
    ${({ $noHaverAnimation }) =>
      !$noHaverAnimation &&
      `
    padding: ${MAX_PADDING};
    font-weight: 600;
    `}
  }
`;

interface IPrimaryButton {
  label: string;
  onClick: () => void;
  isDisabled?: boolean;
  fontSize?: number;
  type?: 'button' | 'submit';
  noHaverAnimation?: boolean;
  className?: string;
}
/**
 * @deprecated since version 3.0 {@see Button}
 */
export const PrimaryButton = ({
  label,
  onClick,
  isDisabled = false,
  fontSize,
  type = 'button',
  noHaverAnimation = false,
  className,
}: IPrimaryButton) => {
  return (
    <Wrapper>
      {/* We need this to do not change button position on hover (when padding changes)*/}
      {!noHaverAnimation && <SizeDefiner $fontSize={fontSize}>{label}</SizeDefiner>}

      <Button
        type={type}
        $isDisabled={isDisabled}
        onClick={onClick === Function ? noMultyRun(onClick) : onClick}
        disabled={isDisabled}
        $fontSize={fontSize}
        $noHaverAnimation={noHaverAnimation}
        className={className}
      >
        {label}
      </Button>
    </Wrapper>
  );
};
