import { useEffect, useState } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import flatten from 'lodash/flatten';
import orderBy from 'lodash/orderBy';

import { getJobLevelGroups } from '~/services/jobLevelGroups';

import { prepareVirtualJobLevelGroupId } from '../../utils';

import type { IJobLevelGroupPopulated } from '../types';
import type { IJobLevelGroup } from '@learned/types';

const useJobLevelGroups = () => {
  const [jobLevelGroups, setJobLevelGroups] = useState<IJobLevelGroup[]>([]);
  const [jobLevelGroupsPopulated, setJobLevelGroupsPopulated] = useState<IJobLevelGroupPopulated[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);

  const populateJobLevelGroups = (jobLevelGroups: IJobLevelGroup[]) => {
    let realLevel = 0;
    return flatten(
      orderBy(jobLevelGroups, 'order', 'asc').map((jobLevelGroup) => {
        const result: IJobLevelGroupPopulated[] = [];
        const hasOnlyOneLevel = jobLevelGroup.levelsFrom === jobLevelGroup.levelsTo;
        for (let i = jobLevelGroup.levelsFrom; i <= jobLevelGroup.levelsTo; i++) {
          result.push({
            id: prepareVirtualJobLevelGroupId({
              id: jobLevelGroup.id,
              level: i,
            }),
            groupId: jobLevelGroup.id,
            name: jobLevelGroup.name,
            level: i,
            hasOnlyOneLevel,
            realLevel,
          });
          realLevel++;
        }

        return result;
      }),
    );
  };

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);

    const fetchJobLevelGroups = async () => {
      const response = await getJobLevelGroups();
      if (mounted) {
        const jobLevelGroups: IJobLevelGroup[] = Object.values(
          response.data[API_RETURN_FIELDS.JOB_LEVEL_GROUPS],
        );
        setJobLevelGroups(jobLevelGroups);
        setJobLevelGroupsPopulated(populateJobLevelGroups(jobLevelGroups));
        setIsLoading(false);
      }
    };

    fetchJobLevelGroups();

    return () => void (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { jobLevelGroups, jobLevelGroupsPopulated, isLoading };
};

export { useJobLevelGroups };
