import React from 'react';

import { GOAL_SORT_OPTIONS } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { FocusAreaName } from '~/components/FocusAreaName';
import SkillName from '~/components/Skill/SkillName';

import type { IColumnTable } from '~/@types/table';

import { SkillNameRow } from '../../stepsDesign';

import type { IFocusArea } from '@learned/types';

const COLUMNS: IColumnTable<IFocusArea>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'focusArea',
    renderCell: (focusArea: IFocusArea) => {
      return (
        <SkillNameRow>
          <FocusAreaName focusArea={focusArea} />
        </SkillNameRow>
      );
    },
    sortBy: {
      asc: {
        key: GOAL_SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: GOAL_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Skill`),
    accessor: 'skills',
    renderCell: (focusArea: IFocusArea) => {
      return (
        <SkillNameRow>
          <SkillName skill={focusArea.skill} />
        </SkillNameRow>
      );
    },
    sortBy: {
      asc: {
        key: GOAL_SORT_OPTIONS.SKILL_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: GOAL_SORT_OPTIONS.SKILL_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
];

export { COLUMNS };
