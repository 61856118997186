import React, { memo, useEffect } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import Editor from '~/components/Editor';

import useStringState from '~/hooks/useStringState';
import { COLORS, COLOR_PALETTE } from '~/styles';

const SaveButton = styled.div`
  position: absolute;
  bottom: ${(props) => (props.big ? '43px' : '20px')};
  right: ${(props) => (props.big ? '19px' : '20px')};
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  letter-spacing: 0.7px;
`;

const Wrap = styled.div`
  align-items: center;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border-radius: 3px;
  border: solid 1px #c7cfe0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  color: ${COLORS.TEXT_SECONDARY};
  background-color: ${COLOR_PALETTE.WHITE};

  .ql-container.ql-snow {
    border: none;
  }

  .ql-editor {
    padding-right: 95px;
  }

  .editor .ql-toolbar {
    border-top: none;
    border-left: none;
    border-right: none;
  }
`;

const CommentInput = ({ i18n, onSave, comment = '', writingComment }) => {
  const $comment = useStringState(comment);

  const changeComment = (value) => {
    $comment.set(value);
    if (writingComment) {
      if (value) {
        writingComment.on();
      } else {
        writingComment.off();
      }
    }
  };

  useEffect(() => {
    // add warning on refresh page
    const setupBeforeUnloadListener = (ev) => {
      if (!$comment.value) {
        ev.preventDefault();
        ev.returnValue = ''; // for Chrome
      }
    };
    // add warning on refresh page
    window.addEventListener('beforeunload', setupBeforeUnloadListener);

    return () => window.removeEventListener('beforeunload', setupBeforeUnloadListener);
    // eslint-disable-next-line
  }, []);

  return (
    <Wrap>
      <Editor
        placeholder={i18n._(t`Type your note here...`)}
        value={$comment.value}
        compact
        onChange={changeComment}
        big
      />
      <SaveButton>
        <Button
          disabled={!$comment.value}
          onClick={async () => {
            onSave($comment.value);
            $comment.clear();
          }}
          label={i18n._(t`Save`)}
          height={28}
          width={62}
        />
      </SaveButton>
      {/* add warning on leave page */}
      <Prompt
        when={Boolean($comment.value)}
        message={i18n._(
          t`Are you sure you want to exit this screen? Not saved comments will be lost.`,
        )}
      />
    </Wrap>
  );
};

export default memo(withI18n()(CommentInput));
