export default class Module {
  constructor(name, { routesPrivate, routesPublic }) {
    this.routesPrivate = routesPrivate;
    this.routesPublic = routesPublic;
    this.name = name;
  }
  getStatus() {
    return true; // TODO (task is created) add check: do we need to import modules (kinda lazy import) or not (depends on company modules settings)
  }
  getRoutes() {
    if (!this.getStatus()) {
      return { routesPrivate: [], routesPublic: [] };
    }
    return { routesPrivate: this.routesPrivate, routesPublic: this.routesPublic };
  }
}
