import styled from 'styled-components';

import { COLORS } from '~/styles';

const Container = styled.div``;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 40px;
  gap: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
  border-width: 1px;
`;

const TitleContainer = styled.div`
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 26px;
  letter-spacing: -0.27px;
  color: ${COLORS.TEXT_HOVER};
`;

const SubTitle = styled.div`
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export { Container, Footer, Header, Title, ActionContainer, SubTitle, TitleContainer };
