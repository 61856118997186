import React from 'react';

import { useForm } from 'react-hook-form';

import { ReviewDashboardForm } from './ReviewDashboardForm';

import { useLanguageState } from '~/hooks/useLanguageState';

import type { IReviewDashboardCycleForm } from './ReviewDashboardForm/types';

const ReviewDashboardCycle = () => {
  const languageState = useLanguageState();

  const formMethods = useForm<IReviewDashboardCycleForm>({
    mode: 'all',
    defaultValues: {
      // TODO
    },
  });

  return <ReviewDashboardForm formMethods={formMethods} languageState={languageState} />;
};

export { ReviewDashboardCycle };
