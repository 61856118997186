import React from 'react';

import OpenRoute from '~/components/OpenRoute';
import PrivateRoute from '~/components/PrivateRoute';
import ConversationsReportPage from '~/pages/AdminReports/ConversationsReportPage';
import Conversations from '~/pages/Conversations';
import ConversationSetup from '~/pages/ConversationSetup';
import UserConversation from '~/pages/UserConversation';
import ConversationReportPDF from '~/pages/UserConversationReportPDF';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('conversations', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.CONVERSATIONS.routePath(ROLES.USER)}
        exact
        path={routes.CONVERSATIONS.routePath(ROLES.USER)}
        component={Conversations}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.CONVERSATION.routePath(ROLES.USER)}
        exact
        path={routes.CONVERSATION.routePath(ROLES.USER)}
        component={UserConversation}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.CONVERSATION_CREATE.routePath(ROLES.USER)}
        exact
        path={routes.CONVERSATION_CREATE.routePath(ROLES.USER)}
        component={ConversationSetup}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.CONVERSATION_UPDATE.routePath(ROLES.USER)}
        exact
        path={routes.CONVERSATION_UPDATE.routePath(ROLES.USER)}
        component={ConversationSetup}
        allowedRoles={[ROLES.USER]}
      />,
    ],
    coach: [
      <PrivateRoute
        key={routes.CONVERSATION_CREATE.routePath(ROLES.COACH)}
        exact
        path={routes.CONVERSATION_CREATE.routePath(ROLES.COACH)}
        component={ConversationSetup}
        allowedRoles={[ROLES.COACH]}
      />,
      <PrivateRoute
        key={routes.CONVERSATION_UPDATE.routePath(ROLES.COACH)}
        exact
        path={routes.CONVERSATION_UPDATE.routePath(ROLES.COACH)}
        component={ConversationSetup}
        allowedRoles={[ROLES.COACH]}
      />,
      <PrivateRoute
        key={routes.CONVERSATION_SUPERVISION_USER.routePath(ROLES.COACH)}
        exact
        path={routes.CONVERSATION_SUPERVISION_USER.routePath(ROLES.COACH)}
        component={UserConversation}
        allowedRoles={[ROLES.COACH]}
      />,
    ],
    admin: [
      <PrivateRoute
        key={routes.CONVERSATION_UPDATE.routePath(ROLES.ADMIN)}
        exact
        path={routes.CONVERSATION_UPDATE.routePath(ROLES.ADMIN)}
        component={ConversationSetup}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.CONVERSATION_REPORT.routePath(ROLES.ADMIN)}
        exact
        path={routes.CONVERSATION_REPORT.routePath(ROLES.ADMIN)}
        component={ConversationsReportPage}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.CONVERSATION_SUPERVISION_USER.routePath(ROLES.ADMIN)}
        exact
        path={routes.CONVERSATION_SUPERVISION_USER.routePath(ROLES.ADMIN)}
        component={UserConversation}
        allowedRoles={[ROLES.ADMIN]}
      />,
    ],
  },
  routesPublic: [
    <OpenRoute
      key={routes.CONVERSATION_GENERATE_PDF.routePath()}
      exact
      path={routes.CONVERSATION_GENERATE_PDF.routePath()}
      component={ConversationReportPDF}
    />,
  ],
});
