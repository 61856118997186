import React, { useState } from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import RTFeedbackCard from '~/pages/RTFeedbacks/components/RTFeedbackCard';

const ShowMoreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 11px;
`;

const ShowMore = styled.span`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e0863;
  cursor: pointer;
`;

const FeedbacksSection = ({ feedbacks }) => {
  const [isShowMore, setIsShowMore] = useState(true);
  const [feedbacksTosShow, setUpdatesToShow] = useState(feedbacks.slice(0, 2));
  return (
    <>
      {feedbacksTosShow.map((RTFeedback) => (
        <RTFeedbackCard
          key={RTFeedback.id}
          RTFeedback={RTFeedback}
          isHideMenu
          isHideGoal
          isNoCardPadding
        />
      ))}
      {isShowMore && feedbacks.length > 1 && (
        <ShowMoreWrapper>
          <ShowMore
            onClick={() => {
              setUpdatesToShow(feedbacks);
              setIsShowMore(false);
            }}
          >
            <Trans>Show more</Trans>
          </ShowMore>
        </ShowMoreWrapper>
      )}
    </>
  );
};

export { FeedbacksSection };
