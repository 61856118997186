import React, { useMemo } from 'react';
import type { MouseEventHandler } from 'react';

import { REVIEW_QUESTION_EVALUATORS } from '@learned/constants';
import { ILanguageValue } from '@learned/types';
import { Trans } from '@lingui/macro';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import Tooltip from '~/components/Tooltip';

import {
  Header,
  Main,
  Separator,
  Title,
  Wrapper,
  Footer,
  FooterIconContainer,
  ErrorMessage,
} from './styling';

import { QUESTION_TYPES } from '~/constants/questionsTypes';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';

import type { IMultiLangString } from '@learned/types/src/multiLangString';

interface IQuestionProps {
  name: ILanguageValue[] | IMultiLangString;
  type: QUESTION_TYPES;
  index: number;
  onClickTitle?: MouseEventHandler<HTMLDivElement>;
  onClickCard?: MouseEventHandler<HTMLDivElement>;
  languageState: ILanguageStateReturn;
  isActive?: boolean;
  className?: string;
  themeName?: IMultiLangString;
  children?: React.ReactNode;
  isPreselectedLang?: boolean;
  hideIndex?: boolean;
  evaluators?: REVIEW_QUESTION_EVALUATORS[];
  error?: string;
}

const PreviewQuestionCard = ({
  name,
  type,
  index,
  onClickTitle,
  onClickCard,
  languageState,
  isActive,
  className,
  themeName,
  children,
  isPreselectedLang = false,
  hideIndex = false,
  evaluators = [],
  error,
}: IQuestionProps) => {
  const getMultiLangString = useMultiLangString();
  const ANSWER_TYPES = {
    [QUESTION_TYPES.TEXT]: {
      key: QUESTION_TYPES.TEXT,
      icon: <Icon icon={ICONS.TEXT_ANSWER} size={ICON_SIZES.LARGE} />,
    },
    [QUESTION_TYPES.SINGLE]: {
      key: QUESTION_TYPES.SINGLE,
      icon: <Icon icon={ICONS.SINGLE_SELECT} size={ICON_SIZES.LARGE} />,
    },
    [QUESTION_TYPES.MULTIPLE]: {
      key: QUESTION_TYPES.MULTIPLE,
      icon: <Icon icon={ICONS.MULTI_SELECT} size={ICON_SIZES.LARGE} />,
    },
    [QUESTION_TYPES.TRAFFIC]: {
      key: QUESTION_TYPES.TRAFFIC,
      icon: <Icon icon={ICONS.TRAFFIC} size={ICON_SIZES.LARGE} />,
    },
    [QUESTION_TYPES.STARS]: {
      key: QUESTION_TYPES.STARS,
      icon: <Icon icon={ICONS.STAR} size={ICON_SIZES.LARGE} />,
    },
    [QUESTION_TYPES.SMILEYS]: {
      key: QUESTION_TYPES.SMILEYS,
      icon: <Icon icon={ICONS.SMILEY} size={ICON_SIZES.LARGE} />,
    },
    [QUESTION_TYPES.RATING]: {
      key: QUESTION_TYPES.RATING,
      icon: <Icon icon={ICONS.RATING} size={ICON_SIZES.LARGE} />,
    },
    [QUESTION_TYPES.CUSTOM_SKILL]: {
      key: QUESTION_TYPES.CUSTOM_SKILL,
      icon: <Icon icon={ICONS.CUSTOM_SKILL} size={ICON_SIZES.LARGE} />,
    },
    [QUESTION_TYPES.SKILL_CATEGORY]: {
      key: QUESTION_TYPES.SKILL_CATEGORY,
      icon: <Icon icon={ICONS.SKILL} size={ICON_SIZES.LARGE} />,
    },
    [QUESTION_TYPES.GOAL_PLAN]: {
      key: QUESTION_TYPES.GOAL_PLAN,
      icon: <Icon icon={ICONS.PLAN_GOALS} size={ICON_SIZES.LARGE} />,
    },
  };

  const EVALUATORS_TYPES = {
    [REVIEW_QUESTION_EVALUATORS.COACH]: {
      key: REVIEW_QUESTION_EVALUATORS.COACH,
      icon: <Icon icon={ICONS.COACH} size={ICON_SIZES.LARGE} />,
      tooltip: <Trans>Coach review</Trans>,
    },
    [REVIEW_QUESTION_EVALUATORS.PEER]: {
      key: REVIEW_QUESTION_EVALUATORS.PEER,
      icon: <Icon icon={ICONS.PEER} size={ICON_SIZES.LARGE} />,
      tooltip: <Trans>Peer review</Trans>,
    },
    [REVIEW_QUESTION_EVALUATORS.EMPLOYEE]: {
      key: REVIEW_QUESTION_EVALUATORS.EMPLOYEE,
      icon: <Icon icon={ICONS.EMPLOYEE} size={ICON_SIZES.LARGE} />,
      tooltip: <Trans>Self review</Trans>,
    },
  };

  const { companyPrimaryLanguage, languages } = languageState;

  const locale = isPreselectedLang ? languages[0].locale : companyPrimaryLanguage.locale;

  const getTranslatedThemeName = (name: IMultiLangString) => {
    return name[locale] || <Trans>Empty</Trans>;
  };

  const questionName = useMemo(() => {
    return Array.isArray(name)
      ? name.find((t) => locale === t.locale)?.value
      : name[locale] || <Trans>Empty</Trans>;
  }, [locale, name]);

  const translatedThemeName = themeName
    ? isPreselectedLang
      ? getTranslatedThemeName(themeName)
      : getMultiLangString(themeName)
    : null;

  return (
    <Wrapper onClick={onClickCard} className={className} isActive={isActive} error={!!error}>
      {error && (
        <ErrorMessage>
          <Icon icon={ICONS.WARNING} size={ICON_SIZES.MEDIUM} />
          <span>
            <Trans>{error}</Trans>
          </span>
        </ErrorMessage>
      )}
      <Header>
        {themeName && (
          <>
            <span>{translatedThemeName}</span>
            <Separator>|</Separator>
          </>
        )}
        {/* @ts-ignore */}
        <span className="icon">{ANSWER_TYPES[type].icon}</span>
      </Header>
      <Main>
        {!hideIndex && <div className="index">{++index}</div>}
        <Title withBottom={evaluators.length === 0} onClick={onClickTitle}>
          {questionName}
        </Title>
      </Main>
      {children}
      {evaluators.length > 0 && (
        <Footer>
          {evaluators.map((evaluator, idx) => (
            <Tooltip tooltip={EVALUATORS_TYPES[evaluator].tooltip} key={idx}>
              <FooterIconContainer key={idx}>
                <span className="footer-icon">{EVALUATORS_TYPES[evaluator].icon}</span>
              </FooterIconContainer>
            </Tooltip>
          ))}
        </Footer>
      )}
    </Wrapper>
  );
};

export { PreviewQuestionCard };
