import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, PATH_STATUSES, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useRouteMatch, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import DashboardHeader from '~/components/DashboardHeader';
import { ActivityModal } from '~/components/Modals/ActivityModal';
import { ShowParticipantsActivityModal } from '~/components/Modals/ShowParticipantsActivityModal';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import LoadingPage from '~/pages/LoadingPage';

import ActionsButtons from './components/ActionsButtons';
import SideBar from './components/SideBar';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { getUser } from '~/selectors/baseGetters';
import { getPath, addPathUsers, deletePathUsers, copyPath } from '~/services/paths';
import { updateUserActivityProgress } from '~/services/userActivities';
import { getUserPath } from '~/services/userPaths';

import type { IPath, IUserActivity, IUserPath } from '@learned/types';

const ActivityBlockWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0 64px;
`;

const ActivityBlock = styled.div<{
  $isMarginBottom: boolean;
}>`
  width: 100%;
  @media screen and (min-width: 1480px) {
    width: 998px;
  }
  background-color: #fff;
  border-radius: 10px;
  margin-top: 32px;
  height: max-content;
  padding-bottom: ${(props) => props.$isMarginBottom && '60px'};
  box-sizing: border-box;
`;

const PageWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  height: 100%;
`;

const PathDashboard = () => {
  const history = useHistory();
  const location = useLocation();
  const { i18n } = useLingui();
  const { addToast } = useToasts();
  const { pathname } = location;
  const match: { params: { pathId: string; userId?: string } } = useRouteMatch();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isLibrary = query.isLibrary === 'true';
  const isLearningPage = query.isLearningPage === 'true';
  const { pathId, userId } = match.params;
  const isUserPath = !_.isEmpty(userId) || pathname.includes('user-path');
  const $isLoading = useBoolState(true);
  const $isFetchAgain = useBoolState(false);
  const $isFetchActivityAgain = useBoolState(false);
  const [path, setPath] = useState<IPath | IUserPath | null>(null);
  const [activeActivityId, setActiveActivityId] = useState<IUserActivity['id'] | null>(null);
  const [isShowParticipantsModal, setIsShowParticipantsModal] = useState(false);
  const [isShowEditorsModal, setIsShowEditorsModal] = useState(false);
  const $isKeepCurrentActivity = useBoolState(false);
  const user = useSelector(getUser);

  const isDraft = path?.status === PATH_STATUSES.DRAFT;

  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      $isLoading.on();

      const { data } = isUserPath
        ? await getUserPath(pathId, userId)
        : await getPath(pathId, { populate: ['activities'] }, { isOwnUserPath: true });
      const path = isUserPath ? data[API_RETURN_FIELDS.USER_PATH] : data[API_RETURN_FIELDS.PATH];

      if (isMounted && !_.isEmpty(path)) {
        const activitiesProps = _.map(path.activitiesProps, (a, id) => ({ ...a, id }));
        const firstActivityProps = _.find(
          activitiesProps,
          (a) => a.section === path.sections[0].order && a.order === 0,
        );
        const pathActivites =
          isUserPath || !path.subPath ? path.activities : path.subPath.activities;
        const firstActiveActivity = _.find(
          pathActivites,
          (act) =>
            act.originalActivity === firstActivityProps?.id || act?.id === firstActivityProps?.id,
        );
        if (!_.isEmpty(firstActiveActivity) && !$isKeepCurrentActivity.value) {
          setActiveActivityId(firstActiveActivity.id);
          $isKeepCurrentActivity.off();
        }

        $isFetchActivityAgain.toggle();

        setPath(path);
        $isLoading.off();
      }
    };

    fetch();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [pathname, pathId, userId, $isFetchAgain.value]);

  const handleClose = () => {
    const href = window.location.href;
    const url = new URL(href);
    const from =
      url.searchParams.get('from') && location.hash.slice(1)
        ? url.searchParams.get('from') + '#' + location.hash.slice(1)
        : url.searchParams.get('from');

    // @ts-ignore
    const backPath = from || routes.ONBOARD_AND_LEARN.build({ role: ROLES.USER }, { hash: 'all' });
    history.push(backPath);
  };

  const onActivityClick = (activityId: IUserActivity['id']) => setActiveActivityId(activityId);

  const updateActivityProgress = async ({ ...update }) => {
    $isKeepCurrentActivity.on();
    await updateUserActivityProgress(activeActivityId, update);
    $isFetchAgain.toggle();
  };

  const onEdit = () => {
    routes.PATH_UPDATE.go({}, { isBackPath: true, pathId, query: { users: [userId] } });
  };

  const addPathToPlan = async () => {
    await addPathUsers(pathId, [user.id]);
    addToast({
      title: i18n._(t`Successfully added to plan`),
      subtitle: i18n._(t`You can now start to work on the path.`),
      type: TOAST_TYPES.INFO,
    });
    $isFetchAgain.toggle();
  };

  const onRemoveFromPlan = async () => {
    const originalPathId = isUserPath ? (path as IUserPath).originalPath : (path as IPath).id;
    await deletePathUsers(originalPathId, [user.id]);
    if (isUserPath) {
      handleClose();
    } else {
      $isKeepCurrentActivity.off();
      $isFetchAgain.toggle();
    }
  };

  const duplicatePath = async () => {
    await copyPath((path as IPath).id);
    addToast({
      title: i18n._(t`Path duplicated`),
      subtitle: i18n._(t`You can find the path copy in the library.`),
      type: TOAST_TYPES.INFO,
    });
  };

  const closeParticipantsModal = async (editors: string[], isEditor: boolean) => {
    const currentlyAssigned = (path as IPath).users?.includes(user.id);
    const assignedAfterUpdate = editors?.includes(user.id);
    if (!isEditor && currentlyAssigned !== assignedAfterUpdate) {
      $isFetchAgain.toggle();
    }
    setIsShowParticipantsModal(false);
    setIsShowEditorsModal(false);
  };

  if ($isLoading.value) {
    // @ts-ignore
    return <LoadingPage />;
  }

  // @ts-ignore
  const isShowAddToPlanButton = !isDraft && !isUserPath && isLibrary && _.isEmpty(path?.subPath);
  const isShowRemoveFromPlan =
    !isDraft &&
    // @ts-ignore
    ((!_.isEmpty(path?.subPath) &&
      // @ts-ignore
      path?.subPath.createdFor === user.id &&
      // @ts-ignore
      path?.subPath.createdBy === user.id) ||
      // @ts-ignore
      (isUserPath && path.createdFor === user.id && path.createdBy === user.id)) &&
    (isLibrary || isLearningPage);

  const isDuplicateBtnVisible =
    // @ts-ignore
    isLibrary && !isDraft && !isUserPath && (user.isAdmin || path?.owners.includes(user.id));

  const isEditorsBtnVisible =
    !isDraft && (user.isAdmin || (path as IPath).owners?.includes(user.id)) && isLibrary;
  const isParticipantsBtnVisible = !isDraft && (user.isAdmin || user.isCoach) && isLibrary;

  return (
    <>
      <DashboardHeader
        onBack={handleClose}
        title={(path as IPath)?.name}
        actions={
          <ActionsButtons
            isEditBtnVisible={
              (user.isAdmin || (path as IPath).owners?.includes(user.id)) && isLibrary
            }
            onEdit={onEdit}
            isDuplicateBtnVisible={isDuplicateBtnVisible}
            onDuplicate={() => duplicatePath()}
            isParticipantsBtnVisible={isParticipantsBtnVisible}
            onParticipantsClick={() => setIsShowParticipantsModal(true)}
            isEditorsBtnVisible={isEditorsBtnVisible}
            onEditorsClick={() => setIsShowEditorsModal(true)}
            isAddToPlanBtnVisible={isShowAddToPlanButton}
            onAddToPlan={() => addPathToPlan()}
          />
        }
      />
      <PageWrapper>
        {/* @ts-ignore */}
        <SideBar
          isUserPath={isUserPath}
          path={path as IPath}
          onActivityClick={onActivityClick}
          activeActivityId={activeActivityId as string}
          isShowRemoveFromPlan={isShowRemoveFromPlan}
          onRemoveFromPlan={onRemoveFromPlan}
        />
        <ActivityBlockWrapper>
          {/* @ts-ignore */}
          <ActivityBlock $isMarginBottom={!isUserPath && _.isEmpty(path.subPath)}>
            {!_.isEmpty(activeActivityId) && (
              // @ts-ignore
              <ActivityModal
                activityId={activeActivityId as string}
                isPageBlock
                // @ts-ignore
                isUserActivity={isUserPath || !_.isEmpty(path.subPath)}
                onSaveActivity={(update) => updateActivityProgress(update)}
                isRefetchData={$isFetchActivityAgain.value}
              />
            )}
          </ActivityBlock>
        </ActivityBlockWrapper>
      </PageWrapper>
      {(isShowParticipantsModal || isShowEditorsModal) && path && (
        <ShowParticipantsActivityModal
          isEditor={isShowEditorsModal}
          onClose={closeParticipantsModal}
          path={path as IPath}
        />
      )}
    </>
  );
};

export default PathDashboard;
