import React, { memo, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import Comments from '~/components/Comments';
import Tabs from '~/components/Tabs';

import { COLOR_PALETTE } from '~/styles';

const NotesWrapper = styled.div`
  margin-bottom: ${(props) => (props.$isEmpty ? '48px' : '24px')};
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin: 8px 0 0 24px;
`;

const TABS = {
  shared: 'shared',
  private: 'private',
};

const tabItems = [
  {
    key: TABS.shared,
    label: t`Shared notes`,
  },
  {
    key: TABS.private,
    label: t`Private notes`,
  },
];

const ConversationNotes = ({
  conversationComments,
  handleComment,
  readOnly,
  conversationId,
  deleteComment,
  privateNotes,
  handlePrivateNote,
  isPdfView,
  deletePrivateNote,
}) => {
  const { i18n } = useLingui();
  const [activeTab, setActiveTab] = useState(TABS.shared);

  return (
    <NotesWrapper
      $isEmpty={activeTab === TABS.shared ? isEmpty(conversationComments) : isEmpty(privateNotes)}
    >
      {!isPdfView && (
        <Tabs
          noMarginBottom={true}
          currentItem={activeTab}
          marginBetweenTabs="24px"
          items={tabItems}
          handleChangeTab={(tab) => setActiveTab(tab)}
          isSmall
          border
        />
      )}
      {(activeTab === TABS.shared || isPdfView) && (
        <Comments
          borderTopOfComments={false}
          comments={conversationComments}
          commentPlaceHolder={i18n._(t`Type your notes here…`)}
          parentSource="conversation"
          parentSourceId={conversationId}
          saveButton={true}
          onCommentUpdated={(comment) => handleComment(comment, true)}
          onCommentCreated={(comment) => handleComment(comment)}
          onCommentDeleted={(commentId) => deleteComment(commentId)}
          readOnly={readOnly}
          isNotes
          big
        />
      )}
      {activeTab === TABS.private && !isPdfView && (
        <>
          <SubTitle>
            <Trans>Private notes are only visible to you. Not visible on the PDF version. </Trans>
          </SubTitle>
          <Comments
            borderTopOfComments={false}
            comments={privateNotes}
            commentPlaceHolder={i18n._(t`Type your note here...`)}
            parentSource="conversation"
            isPrivateNote
            parentSourceId={conversationId}
            saveButton={true}
            onCommentUpdated={(comment) => handlePrivateNote(comment, true)}
            onCommentCreated={(comment) => handlePrivateNote(comment)}
            onCommentDeleted={(commentId) => deletePrivateNote(commentId)}
            readOnly={readOnly}
            isNotes
            big
          />
        </>
      )}
    </NotesWrapper>
  );
};

export default memo(ConversationNotes);
