import React, { useEffect, useState } from 'react';

import {
  CONFIRMATION_MODAL_TYPE,
  CURRENCY,
  GOAL_PROGRESS_TYPES,
  GOAL_STATUSES,
  GOAL_STATUSES_NEW,
  SYMBOLS,
} from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Dialog from '~/components/Dialog';
import { StatusLabel } from '~/components/GoalsBlock/components/StatusLabel';
import { ICONS, Icon } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import ToolTip from '~/components/Tooltip';

import {
  ActivityModalFooter,
  CurrentProgressContainer,
  ProgressIconContainer,
  ProgressLabelContainer,
  ProgressModalContainer,
  ProgressModalHeader,
  ProgressModalTitle,
  ProgressValueContainer,
  ProgressValueRow,
  ProgressValuesContainer,
  StyledDropdown,
  StyledInput,
} from './design';

import { GOAL_STATUSES_MAP } from '~/constants/goals';
import useBoolState from '~/hooks/useBoolState';
import * as currentGoal from '~/store/currentGoal/actions';
import { formatNumber } from '~/utils/formatNumber';
import { getGoalProgressWithDecimalsForInputs } from '~/utils/getGoalProgressWithDecimals';

import type { IGoal } from '@learned/types';
import type { I18n } from '@lingui/core';

type StatusItem = { key: GOAL_STATUSES_NEW; title: (i18n: I18n) => string };

interface IProps {
  onClose: () => void;
  goal: IGoal;
  isMetric: boolean;
  isPercentage: boolean;
  isCurrency: boolean;
  isProgressEditable: boolean;
  isBinary: boolean;
}

const GoalProgressModal = ({
  onClose,
  goal,
  isMetric,
  isPercentage,
  isCurrency,
  isBinary,
  isProgressEditable,
}: IProps) => {
  const [currentProgress, setCurrentProgress] = useState<number | undefined>(goal.progress || 0);
  const [selectedStatus, setSelectedStatus] = useState(
    Object.values(GOAL_STATUSES_MAP).find(
      // @ts-ignore
      (statusObj) => statusObj.key === (goal?.status as GOAL_STATUSES_NEW),
    )!,
  );
  const [allowedStatuses, setAllowedStatuses] = useState<StatusItem[]>([]);
  const $isShowWarningModal = useBoolState(false);
  const $isUpdatingActivity = useBoolState(false);
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  // adding currency and percentage symbols
  const getValuesWithSymbols = (value: number) => {
    let result = `${value.toFixed(2)}`;
    if (isPercentage) {
      result = `${value.toFixed(2)}%`;
    }
    if (isCurrency) {
      result = `${SYMBOLS[goal.progressDetails?.currency || CURRENCY.DOLLAR]}${value.toFixed(2)}`;
    }
    return result;
  };

  const handleCancel = () => {
    if (currentProgress !== goal?.progress || selectedStatus.key !== goal?.status) {
      $isShowWarningModal.on();
    } else {
      onClose();
    }
  };

  const onUpdateGoal = async () => {
    $isUpdatingActivity.on();
    await dispatch(
      currentGoal.updateGoalProgress({
        progress:
          selectedStatus.key === GOAL_STATUSES_NEW.TODO || !currentProgress ? 0 : currentProgress,
        status: selectedStatus.key,
      }),
    );
    $isUpdatingActivity.off();
    onClose();
  };

  // selecting statuses based on the type of activity
  const allStatsesArray = Object.values(GOAL_STATUSES_MAP);
  const metricStatuses = [
    GOAL_STATUSES_NEW.TODO,
    GOAL_STATUSES_NEW.IN_PROGRESS,
    GOAL_STATUSES_NEW.PROBLEM,
    GOAL_STATUSES_NEW.COMPLETED,
  ];
  const binaryStatuses = metricStatuses.filter(
    (status) => status !== GOAL_STATUSES_NEW.IN_PROGRESS,
  );

  const goalStatuses = isMetric
    ? allStatsesArray.filter((status) => metricStatuses.includes(status.key as GOAL_STATUSES_NEW))
    : allStatsesArray.filter((status) => binaryStatuses.includes(status.key as GOAL_STATUSES_NEW));

  useEffect(() => {
    if (isBinary) {
      setAllowedStatuses(goalStatuses as StatusItem[]);
      return;
    }

    let filteredStatuses = [];
    let currentActivityStatus = selectedStatus;
    const currentProgressPercentage =
      ((currentProgress! - (goal.progressDetails?.min || 0)) /
        ((goal.progressDetails?.max || 0) - (goal.progressDetails?.min || 0))) *
      100;

    switch (true) {
      case currentProgressPercentage <= 0 || !currentProgressPercentage:
        filteredStatuses = goalStatuses.filter((status) =>
          [GOAL_STATUSES_NEW.TODO, GOAL_STATUSES_NEW.PROBLEM].includes(
            status.key as GOAL_STATUSES_NEW,
          ),
        );
        currentActivityStatus = filteredStatuses.find(
          (status) => status.key === GOAL_STATUSES_NEW.TODO,
        ) as StatusItem;
        break;

      case currentProgressPercentage > 0 && currentProgressPercentage < 100:
        filteredStatuses = goalStatuses.filter((status) =>
          [GOAL_STATUSES_NEW.IN_PROGRESS, GOAL_STATUSES_NEW.PROBLEM].includes(
            status.key as GOAL_STATUSES_NEW,
          ),
        );
        currentActivityStatus = filteredStatuses.find(
          (status) => status.key === GOAL_STATUSES_NEW.IN_PROGRESS,
        ) as StatusItem;
        break;

      default:
        filteredStatuses = goalStatuses.filter((status) =>
          [GOAL_STATUSES_NEW.COMPLETED, GOAL_STATUSES_NEW.PROBLEM].includes(
            status.key as GOAL_STATUSES_NEW,
          ),
        );
        currentActivityStatus = filteredStatuses.find(
          (status) => status.key === GOAL_STATUSES_NEW.COMPLETED,
        ) as StatusItem;
        break;
    }

    setAllowedStatuses(filteredStatuses as StatusItem[]);
    setSelectedStatus((prev) =>
      prev?.key === GOAL_STATUSES_NEW.PROBLEM ? prev : currentActivityStatus,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProgress, goal]);

  useEffect(() => {
    setSelectedStatus(
      Object.values(GOAL_STATUSES_MAP).find(
        // @ts-ignore
        (statusObj) => statusObj.key === (goal?.status as GOAL_STATUSES_NEW),
      )!,
    );

    // calculating current value based on current progress percentage
    const currentProgress = goal?.progress || 0;

    setCurrentProgress(Number(currentProgress));
  }, [goal]);

  return (
    <Dialog borderRadius={10} width={750}>
      <ProgressModalContainer>
        <ProgressModalHeader>
          <ToolTip tooltip={goal?.name}>
            <ProgressModalTitle>{goal?.name}</ProgressModalTitle>
          </ToolTip>
          <Button
            variant={ButtonVariant.ICON}
            size={ButtonSize.MEDIUM}
            icon={ICONS.CLOSE}
            onClick={handleCancel}
          />
        </ProgressModalHeader>

        <ProgressValuesContainer>
          {isMetric && (
            <ProgressValueRow>
              <ProgressIconContainer>
                <Icon icon={ICONS.SKILL_CATEGORY} width={'16px'} height={'16px'} />
              </ProgressIconContainer>
              <ProgressLabelContainer>
                <Trans>Start</Trans>
              </ProgressLabelContainer>
              <ProgressValueContainer>
                {getValuesWithSymbols(goal.progressDetails?.min || 0)}
              </ProgressValueContainer>
            </ProgressValueRow>
          )}

          {isMetric && (
            <ProgressValueRow>
              <ProgressIconContainer>
                <Icon icon={ICONS.SUPERADMIN} width={'13px'} height={'13px'} />
              </ProgressIconContainer>
              <ProgressLabelContainer>
                <Trans>Current</Trans>
              </ProgressLabelContainer>
              <ProgressValueContainer>
                <CurrentProgressContainer>
                  {isProgressEditable ? (
                    <StyledInput
                      type="number"
                      min={goal.progressDetails?.min || 0}
                      max={goal.progressDetails?.max || 0}
                      value={currentProgress}
                      onChange={(e) => {
                        setCurrentProgress(
                          formatNumber(
                            getGoalProgressWithDecimalsForInputs(
                              e.target.value,
                              goal?.progressType as GOAL_PROGRESS_TYPES,
                            ),
                          ),
                        );
                      }}
                    />
                  ) : (
                    `${getValuesWithSymbols(currentProgress!)}`
                  )}
                  {` / ${getValuesWithSymbols(goal.progressDetails?.max || 0)}`}
                </CurrentProgressContainer>
              </ProgressValueContainer>
            </ProgressValueRow>
          )}

          <ProgressValueRow>
            <ProgressIconContainer>
              <Icon icon={ICONS.PARTIAL} width={'13px'} height={'13px'} />
            </ProgressIconContainer>
            <ProgressLabelContainer>
              <Trans>Status</Trans>
            </ProgressLabelContainer>
            <ProgressValueContainer>
              {isProgressEditable ? (
                <StyledDropdown
                  items={allowedStatuses}
                  selectedItem={selectedStatus}
                  onChange={(selectedItem) => {
                    // @ts-ignore
                    setSelectedStatus(selectedItem);
                  }}
                  // @ts-ignore
                  stringifyItem={(item) => item.title(i18n)}
                  isSingleSelect={true}
                />
              ) : (
                <StatusLabel
                  status={goal?.status as GOAL_STATUSES}
                  progress={goal?.calculatedProgress}
                />
              )}
            </ProgressValueContainer>
          </ProgressValueRow>
        </ProgressValuesContainer>

        <ActivityModalFooter>
          <Button
            label={i18n._(t`Cancel`)}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
            onClick={handleCancel}
          />
          {isProgressEditable && (
            <Button
              label={i18n._(t`Update`)}
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              onClick={onUpdateGoal}
              isLoading={$isUpdatingActivity.value}
            />
          )}
        </ActivityModalFooter>
        {$isShowWarningModal.value && (
          <ConfirmationModal
            type={CONFIRMATION_MODAL_TYPE.WARNING}
            description={i18n._(t`'Your changes will not be saved.`)}
            title={i18n._(t`Are you sure you want to close without updating?`)}
            cancelButton={i18n._(t`Back`)}
            submitButton={i18n._(t`Yes`)}
            onClose={$isShowWarningModal.off}
            onSubmit={onClose}
          />
        )}
      </ProgressModalContainer>
    </Dialog>
  );
};

export { GoalProgressModal };
