import React from 'react';

import Switch from '~/components/Switch';

import { SettingItemWrapper } from './design';

interface ISettingItemProps {
  title: string;
  checked?: boolean;
  onClick?: (value: boolean) => void;
  isSecondLevel?: boolean;
}

const SettingItem = ({ title, checked, onClick, isSecondLevel }: ISettingItemProps) => {
  return (
    <SettingItemWrapper $isSecondLevel={isSecondLevel}>
      <Switch checked={checked} onChange={onClick} />
      {title}
    </SettingItemWrapper>
  );
};

export { SettingItem };
