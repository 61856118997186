import styled from 'styled-components';

import { ButtonPrimary, ButtonWhite } from '~/components/Button';
import { SearchField } from '~/components/Text';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  justify-content: center;
  border-radius: 6px;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  & > button {
    padding: none;
    width: 22px;
    height: 22px;
  }
`;

export const Title = styled.div`
  font-size: 26px;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
`;

export const SubTitle = styled.div`
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.86;
  color: ${COLORS.INPUT_TITLE};
`;

export const Line = styled.div`
  width: 685px;
  height: 1px;
  margin-top: 16px;
  background-color: ${COLORS.BORDERS};
`;

export const Content = styled.div`
  padding-top: 24px;

  & > div {
    & > div {
      &:first-child {
        margin-bottom: 12px;
      }
    }
    &:first-child {
      & > div {
        margin-bottom: 0px;
      }
    }
  }

  thead {
    tr {
      th:first-of-type {
        div {
          padding-left: 70px;
        }
      }
      div {
        background: ${COLORS.WHITE};
      }
    }
  }

  tbody {
    tr:hover {
      td {
        div {
          background: ${COLORS.HOVER};
        }
      }
    }
  }
`;

export const SearchContainer = styled.div`
  display: flex;
`;

export const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  margin-right: 12px;
  width: 350px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 10px 0 0;
`;

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${COLORS.TEXT_MAIN};
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;
`;

export const StyledFiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  background-color: ${COLORS.WHITE};
`;

export const StyledButtons = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledSpan = styled.span`
  color: ${COLORS.COMPANY};
`;

export const NameRow = styled.div<{ $isSelected: boolean }>`
  display: flex;
  gap: 25px;
  align-items: center;
  box-sizing: border-box;
  background-color: ${({ $isSelected }) => ($isSelected ? COLORS.HOVER : COLORS.BG_LIST)};
  padding: 12px 22px;
  max-width: unset;
  border-radius: 6px;
  height: 100%;
  min-height: 64px;
  color: ${COLORS.TEXT_MAIN};
  font-weight: normal;
  font-size: 14px;

  & .text {
    white-space: normal;
    word-break: break-all;
  }

  & .buttons {
    display: flex;
    margin-right: -20px;
  }

  & .center {
    width: 100%;
    text-align: center;
  }

  & .black {
    color: ${COLORS.TEXT_HOVER};
  }
`;

export const Footer = styled.div`
  margin-top: 35px;
  justify-content: space-between;
  display: flex;
  gap: 20px;
`;

export const RightActions = styled.div`
  display: flex;
  gap: 8px;
`;

export const CancelButton = styled(ButtonWhite)`
  font-size: 12px;
  height: 32px;
  border-radius: 100px;
  min-width: 83px;
  text-align: center;
  border: none;
`;

export const NextButton = styled(ButtonPrimary)`
  font-size: 12px;
  height: 32px;
  border-radius: 100px;
  min-width: 83px;
  text-align: center;
`;

export const RadioContainer = styled.div`
  width: 25px;
`;

export const BackButton = styled(ButtonWhite)`
  height: 32px;
  min-width: 80px;
  padding: 0 14px 0 10px;
  border-radius: 100px;
  font-size: 12px;
  border: none;
  gap: 12px;
  color: ${COLORS.COMPANY};

  &:hover {
    color: ${COLORS.COMPANY};
    background-color: unset;
  }

  & .icon {
    color: ${COLORS.COMPANY};
    margin-right: 4px;
    fill: currentColor;
  }
`;
