import React from 'react';

import { REVIEW_STATUS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Switch from '~/components/Switch';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import {
  Separator,
  SubSectionWrapper,
  SubTitle,
  ToggleContainer,
  ToggleRow,
  ToggleText,
} from '../../design';

import type { GeneralSettingsType, IReviewIndividualForm } from '../../types';
import type { I18n } from '@lingui/core';
import type { UseFormReturn } from 'react-hook-form';

interface GeneralSettingsProps {
  formMethods: UseFormReturn<IReviewIndividualForm>;
}

type ToggleItem = {
  key: GeneralSettingsType;
  text: (i18n: I18n) => string;
  tooltip: (i18n: I18n) => string;
};

const TOGGLE_LIST: Array<ToggleItem> = [
  {
    key: 'isDigitalSign',
    text: (i18n: I18n) => i18n._(t`Digitally sign the review reports`),
    tooltip: (i18n: I18n) =>
      i18n._(
        t`Participants receive a notification 7 days before the end date to sign the review report`,
      ),
  },
];

const GeneralSettings = ({ formMethods }: GeneralSettingsProps) => {
  const { watch, setValue } = formMethods;
  const { i18n } = useLingui();

  const onChangeToggle = (key: GeneralSettingsType) => {
    setValue(`settings.${key}`, !watch(`settings.${key}`), { shouldDirty: true });
  };

  const isPublished = watch('status') !== REVIEW_STATUS.DRAFT;
  const savedStartDate = new Date(watch('fetchedReview').settings.startDate);
  const today = new Date();

  const isDigitalSignDisabled = isPublished && savedStartDate && savedStartDate < today;

  return (
    <SubSectionWrapper>
      <SubTitle>
        <Trans>General</Trans>
      </SubTitle>
      <Separator />
      <ToggleContainer>
        {TOGGLE_LIST.map((item) => (
          <ToolTip
            key={item.key}
            disabled={!isDigitalSignDisabled}
            tooltip={i18n._(
              t`It is not possible to change this setting after the review cycle has started`,
            )}
          >
            <ToggleRow key={item.key}>
              <span>
                <Switch
                  onChange={() => onChangeToggle(item.key)}
                  checked={watch(`settings.${item.key}`)}
                  disabled={isDigitalSignDisabled}
                />
              </span>
              <ToolTip tooltip={item.tooltip(i18n)} placement={TOOLTIP_PLACEMENTS.BOTTOM}>
                <ToggleText>{item.text(i18n)}</ToggleText>
              </ToolTip>
            </ToggleRow>
          </ToolTip>
        ))}
      </ToggleContainer>
    </SubSectionWrapper>
  );
};

export { GeneralSettings };
