import { joiResolver } from '@hookform/resolvers/joi';
import { SURVEY_QUESTION_TYPES } from '@learned/constants';
import { ISurveyQuestion, WithComments } from '@learned/types';
import Joi from 'joi';
import { isNumber } from 'lodash';
import isEmpty from 'lodash/isEmpty';

const Errors = {
  missingAnswer: 'missingAnswer',
  missingComment: 'missingComment',
};

const schema = Joi.object({
  ratings: Joi.when(Joi.ref('isPublishing'), {
    is: true,
    then: Joi.array()
      .items(
        Joi.object({
          ratingId: Joi.string().allow(null),
          company: Joi.string().required(),
          user: Joi.string().required(),
          survey: Joi.string().required(),
          event: Joi.string().required(),
          task: Joi.string().required(),
          question: Joi.string().required(),

          status: Joi.string(),
          meta: Joi.object(),
          questionType: Joi.string(),
          id: Joi.string(),

          answer: Joi.custom((value: string, helpers) => {
            const surveyTask: { questions: ISurveyQuestion[] } =
              helpers?.prefs?.context?.surveyTask;
            const rating = helpers.state.ancestors[0];
            const relevantQuestion = surveyTask?.questions?.find(
              (question) => question.id === rating.question,
            );

            switch (relevantQuestion?.type) {
              case SURVEY_QUESTION_TYPES.TEXT:
                if (isEmpty(value)) {
                  throw new Error('Answer is obligated');
                }
                break;
              case SURVEY_QUESTION_TYPES.SMILEYS:
              case SURVEY_QUESTION_TYPES.TRAFFIC:
              case SURVEY_QUESTION_TYPES.STARS:
              case SURVEY_QUESTION_TYPES.SINGLE:
                if (!isNumber(value)) {
                  throw new Error('Answer is obligated');
                }
                break;
              case SURVEY_QUESTION_TYPES.MULTIPLE:
                if (isEmpty(value)) {
                  throw new Error('Answer is obligated');
                }
                break;
            }

            return value;
          }),
          comment: Joi.custom((value: string, helpers) => {
            const surveyTask: { questions: ISurveyQuestion[] } =
              helpers?.prefs?.context?.surveyTask;
            const rating = helpers.state.ancestors[0];
            const relevantQuestion = surveyTask?.questions?.find(
              (question) => question.id === rating.question,
            );
            if (
              isEmpty(value) &&
              (relevantQuestion?.data as WithComments)?.isCommentsObligated === true
            ) {
              throw new Error('Comment is obligated');
            }

            return value;
          }),
        }),
      )
      .required(),
    otherwise: Joi.array(),
  }),
  isPublishing: Joi.boolean(),
});

const resolver = joiResolver(schema);

export { resolver, Errors };
