import React, { useEffect, useState } from 'react';

import { GOAL_PROGRESS_TYPES, SYMBOLS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import round from 'lodash/round';
import styled from 'styled-components';

import Button from '~/components/Button';
import Progress from '~/components/GoalDashboardCardOld/components/UserMainGoalInfo/Progress';
import UpdatesBlock from '~/components/GoalDashboardSidebar/UpdatesBlock';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import useBoolState from '~/hooks/useBoolState';
import * as goalsServices from '~/services/goals';
import { getGoalsUpdates } from '~/services/goalsUpdates';
import { COLOR_PALETTE } from '~/styles';

const UpdatesLabel = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin-bottom: 16px;
  margin-top: 8px;
`;

const GoalIsNotExists = styled.div`
  margin: 24px 0;
`;

const RowCenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonStyled = styled(Button)`
  margin-bottom: 20px;
`;

const UPDATES_LIMIT = 3;

const GoalPreview = ({ point }) => {
  const { i18n } = useLingui();
  const [goal, setGoal] = useState({});
  const [updates, setUpdates] = useState([]);
  const $loading = useBoolState(true);
  const $isGoalDeleted = useBoolState(false);
  const $isViewAll = useBoolState(false);

  // const
  const goalId = point.target?.id;
  const isAvgType = goal.progressType === GOAL_PROGRESS_TYPES.AVG;
  const isDoneType = goal.progressType === GOAL_PROGRESS_TYPES.IS_DONE;

  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      if (point.target?.id) {
        try {
          const [goal, goalUpdates] = await Promise.all([
            goalsServices.getGoal(goalId, [], {
              isGetProgress: true,
            }),
            getGoalsUpdates({
              goalId,
              populate: ['target'],
            }),
          ]);

          if (isMounted) {
            setGoal(goal);
            setUpdates(Object.values(goalUpdates));
            $loading.off();
          }
        } catch (e) {
          $loading.off();
          $isGoalDeleted.on();
        }
      }
    };

    fetch();

    return () => (isMounted = false);

    // eslint-disable-next-line
  }, [goalId]);

  const updatesToShow =
    $isViewAll.value || updates.length <= UPDATES_LIMIT
      ? updates
      : updates.filter((_u, index) => index < UPDATES_LIMIT);
  const amountToView = updates.length - updatesToShow.length;

  return $isGoalDeleted.value ? (
    <RowCenterWrapper>
      <GoalIsNotExists>
        {i18n._(t`The goal does not exist or you don't have permission to see it.`)}
      </GoalIsNotExists>
    </RowCenterWrapper>
  ) : (
    <ShowSpinnerIfLoading loading={$loading.value}>
      <Progress
        progressBar={round(goal.calculatedProgress, 2)}
        progress={isAvgType ? round(goal.calculatedProgress, 2) : goal.progress}
        status={goal.status}
        min={get(goal, 'progressDetails.min')}
        max={get(goal, 'progressDetails.max')}
        symbol={isAvgType ? ' %' : get(SYMBOLS, `${get(goal, 'progressDetails.currency')}`, '')}
        disabled={true}
        isAvgType={isAvgType}
        isDoneType={isDoneType}
        isChildProgress={goal.isChildProgress}
        isHideUpdateProgress={true}
      />
      <UpdatesLabel>{i18n._(t`Last updates`)}</UpdatesLabel>
      <UpdatesBlock updates={updatesToShow} goalName={get(goal, 'name')} isReadOnly={true} />
      {amountToView > 0 && (
        <RowCenterWrapper>
          <ButtonStyled
            label={i18n._(t`View ${amountToView} more`)}
            onClick={$isViewAll.toggle}
            type={Button.types.linkPrimary}
          />
        </RowCenterWrapper>
      )}
    </ShowSpinnerIfLoading>
  );
};

export default React.memo(GoalPreview);
