import styled from 'styled-components';

import { COLOR_PALETTE, COLORS, COLOR_SET } from '~/styles';

const Card = styled.div`
  background-color: inherit;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 32px;
  padding: ${(props) => (props.isNoCardPadding ? 0 : '0 20px')};
  color: ${COLORS.TEXT_PRIMARY_2};
  font-size: 14px;
  margin-bottom: 28px;
  margin-top: 28px;

  &:hover {
    background-color: inherit;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Date = styled.div`
  margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.67;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const Description = styled.div`
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
`;

const DescriptionBlock = styled.div`
  margin-bottom: 8px;
  position: relative;
`;

const FeedbackBlock = styled.div`
  border-radius: 6px;
  background-color: ${(props) => (props.$background ? COLORS.BG_PAGE : COLOR_SET.CYAN_LIGHT)};
  padding: 16px;
  font-weight: regular;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
`;

const FeedbackBlockWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (!props.$isWithoutBG ? COLOR_SET.CYAN_LIGHT : 'none')};

  ${(props) =>
    props.$isProactive &&
    `
  border-radius: 6px;`}
`;

const HeaderBlock = styled.div`
  display: flex;
  align-items: center;
  min-height: 28px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    padding-left: 5px;
  }
`;

const ProvidedFeedbackWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const SubFBWrap = styled.div`
  display: flex;
  margin-bottom: 16px;
  width: 100%;
  box-sizing: border-box;
`;

const Tags = styled.span`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  display: flex;

  span {
    margin-right: 8px;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  border: none;
  background-color: initial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  color: ${COLORS.TEXT_PRIMARY};
  box-sizing: border-box;
  outline: none;

  &::placeholder {
    color: ${COLORS.TEXT_PRIMARY};
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-bottom: 3px;
  max-height: 52px;
  overflow: auto;
`;

const TopSection = styled.div`
  padding-bottom: ${(props) => (props.isProactive ? 0 : '8px')};
`;

const QuestionContainer = styled.div`
  border: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  padding: 16px;
  border-radius: 6px;
  background-color: ${COLORS.BG_PAGE};
`;

const UserName = styled.div`
  font-weight: bold;
`;

const UserFeedback = styled.div`
  p {
    margin: 0;
  }
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

export {
  Card,
  CardHeader,
  Date,
  Description,
  DescriptionBlock,
  FeedbackBlock,
  FeedbackBlockWrapper,
  HeaderBlock,
  Label,
  ProvidedFeedbackWrap,
  SubFBWrap,
  Tags,
  TextArea,
  Title,
  TopSection,
  UserName,
  UserFeedback,
  QuestionContainer,
};
