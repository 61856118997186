import React from 'react';

import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import Popover from '~/components/Popover';

const Wrapper = styled.div`
  outline: none;
  ${(props) => !props.disabled && 'cursor: pointer;'}

  div {
    outline: none;
  }
`;

/**
 * @deprecated since version 3.0 {@see Dropdown}
 */
const DropdownButton = ({
  className,
  placement = 'bottom-end',
  contentWidth,
  children,
  content,
  minWidth,
  maxWidth,
  popoverRef,
  disabled,
}) => {
  return (
    // do not remove this div, it's with contentWidth fix safary content position
    <Wrapper disabled={disabled} className={className} style={{ width: contentWidth }}>
      <Popover
        ref={popoverRef}
        trigger="click"
        content={content}
        minWidth={minWidth}
        maxWidth={maxWidth}
        placement={placement}
        boxShadow="2px 2px 10px -1px rgba(14, 26, 45, 0.3)"
        noBorder
        disabled={disabled}
      >
        <div tabIndex="0" role="button">
          {children}
        </div>
      </Popover>
    </Wrapper>
  );
};

export default withI18n()(DropdownButton);
