import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import IconButton from '~/components/IconButton';
import SvgIcon from '~/components/SvgIcon';

import ListSelectAll from './ListSelectAll';

import collapseIcon from '~/assets/keyboard_arrow_down-24px.svg';
import expandIcon from '~/assets/keyboard_arrow_right-24px.svg';

import { TALKING_POINT_TYPES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { COLOR_PALETTE } from '~/styles';

const TemplateHeader = styled.div`
  min-height: 56px;
  height: 56px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const TemplateName = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  color: ${COLOR_PALETTE.BLACK};
`;

const TemplateItems = styled.div``;

function TemplateItem({
  template,
  selectedItems,
  disabledItems,
  toggleItem,
  toggleItems,
  openByDefault,
}) {
  const isExpand = useBoolState(openByDefault);
  const talkingPoints = (template.talkingPoints || []).map((tpName) => ({
    name: tpName,
    id: template.id,
    targetUser: null,
  }));

  return (
    <>
      <TemplateHeader onClick={isExpand.toggle}>
        <TemplateName>{template.name}</TemplateName>
        <IconButton size={40} noBorder={true}>
          <SvgIcon
            width="32px"
            height="32px"
            isDefaultColor={true}
            defaultColor={COLOR_PALETTE.BLACK}
            url={isExpand.value ? collapseIcon : expandIcon}
          />
        </IconButton>
      </TemplateHeader>
      {isExpand.value && (
        <TemplateItems>
          <ListSelectAll
            type={TALKING_POINT_TYPES.TEMPLATES.key}
            items={talkingPoints}
            selectedItems={selectedItems}
            disabledItems={disabledItems}
            toggleItem={toggleItem}
            toggleItems={toggleItems}
          />
        </TemplateItems>
      )}
    </>
  );
}

TemplateItem.propTypes = {
  template: PropTypes.object.isRequired,
  selectedItems: PropTypes.object,
  disabledItems: PropTypes.array,
  toggleItem: PropTypes.func,
  toggleItems: PropTypes.func,
  openByDefault: PropTypes.bool,
};

TemplateItem.defaultProps = {
  openByDefault: false,
};

export default TemplateItem;
