import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';

import { TALKING_POINT_TYPES } from '~/constants';
import { COLOR_PALETTE } from '~/styles';

const TalkingPointWrapper = styled.div`
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background-color: ${(props) => (props.$disabled ? COLOR_PALETTE.DARK_GRAY : COLOR_PALETTE.WHITE)};
`;

const TalkingPointName = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const SelectAll = styled(TalkingPointWrapper)`
  border-top: 1px solid ${COLOR_PALETTE.GRAY_MIDDLE};
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_MIDDLE};
`;

const SelectAllLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const createTP = (type, item) => ({
  type,
  name: item.name,
  target: item.id || '',
  targetUser: item.targetUser || item.createdFor,
});

function ListSelectAll({
  i18n,
  type,
  items,
  selectedItems,
  disabledItems,
  toggleItem,
  toggleItems,
}) {
  const isAllChecked =
    !isEmpty(selectedItems) &&
    items.every((tp) =>
      [TALKING_POINT_TYPES.CUSTOM.key, TALKING_POINT_TYPES.TEMPLATES.key].includes(type)
        ? selectedItems[tp.name] || disabledItems.includes(tp.name)
        : selectedItems[tp.id] || disabledItems.includes(tp.id),
    );
  return (
    <>
      <SelectAll
        onClick={() =>
          toggleItems(
            !isAllChecked,
            items
              .map((tp) => createTP(type, tp))
              .filter(
                (tp) =>
                  !disabledItems.includes(
                    [TALKING_POINT_TYPES.CUSTOM.key, TALKING_POINT_TYPES.TEMPLATES.key].includes(
                      type,
                    )
                      ? tp.name
                      : tp.id,
                  ),
              ),
          )
        }
      >
        <SelectAllLabel>{i18n._(t`Select all`)}</SelectAllLabel>
        <CheckBox checked={isAllChecked} size="24px" />
      </SelectAll>
      {items.map((tp, index) => {
        const disabled = disabledItems.includes(
          [TALKING_POINT_TYPES.CUSTOM.key, TALKING_POINT_TYPES.TEMPLATES.key].includes(type)
            ? tp.name
            : tp.id,
        );
        return (
          <TalkingPointWrapper
            key={index}
            $disabled={disabled}
            onClick={() => !disabled && toggleItem(createTP(type, tp))}
          >
            <TalkingPointName>{tp.name}</TalkingPointName>
            <CheckBox
              checked={
                !isEmpty(
                  selectedItems[
                    [TALKING_POINT_TYPES.CUSTOM.key, TALKING_POINT_TYPES.TEMPLATES.key].includes(
                      type,
                    )
                      ? tp.name
                      : tp.id
                  ],
                )
              }
              size="24px"
            />
          </TalkingPointWrapper>
        );
      })}
    </>
  );
}

ListSelectAll.propTypes = {
  type: PropTypes.oneOf(map(TALKING_POINT_TYPES, (type) => type.key)),
  items: PropTypes.arrayOf(PropTypes.object) /* { name - to display name, id - to save targetId }*/,
  selectedItems: PropTypes.object,
  disabledItems: PropTypes.arrayOf(PropTypes.string),
  toggleItem: PropTypes.func,
  toggleItems: PropTypes.func,
};

export default withI18n()(ListSelectAll);
