import styled from 'styled-components';

import { TableList } from '~/components/TableList';

import { COLORS } from '~/styles';

const DateRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  width: 170px;
`;

const Wrapper = styled.div`
  color: ${COLORS.TEXT_MAIN};
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledTableList = styled(TableList)`
  padding: 0;
  margin: 0;
`;

export { DateRow, Wrapper, TableWrapper, StyledTableList };
