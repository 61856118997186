import React from 'react';

import { Trans } from '@lingui/macro';

import { Icon, ICONS } from '~/components/Icon';

import { Container, IconContainer, Text, IconInfoSubtitle, IconInfoTitle } from './design';

function IconInfo({ title, subtitle, icon }: { title: string; subtitle: string; icon: ICONS }) {
  return (
    <Container>
      <IconContainer>
        <Icon icon={icon} />
      </IconContainer>
      <Text>
        <IconInfoTitle>
          <Trans>{title}</Trans>
        </IconInfoTitle>
        <IconInfoSubtitle>{subtitle}</IconInfoSubtitle>
      </Text>
    </Container>
  );
}

export { IconInfo };
