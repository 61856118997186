import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Editor from '~/components/Editor';
import RickTextView from '~/components/RickTextView';

import QuestionCard from './QuestionCard';
import RatingRow from './RatingRow';
import RatingStatusRows from './RatingStatusRow';

import { REVIEW_QUESTIONS_TABS } from '~/constants';
import { getCurrentReview } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';

const Description = styled.div`
  color: ${COLORS.TEXT_SECONDARY};
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  p {
    color: ${COLORS.TEXT_SECONDARY};
  }
`;

const StyledEditor = styled(Editor)`
  .ql-container {
    max-height: 550px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }
`;

const ReviewCustomQuestion = ({
  rating = {},
  isActive = false,
  isError = false,
  question,
  onChange,
  onQuestionClick,
  i18n,
  tabs,
  typeRatings = [],
  review: reviewProp,
  forUser,
  indexCurrent,
  indexTotal,
  isOpenDefault,
  isOpenForce,
  isReadOnly,
}) => {
  const [currentTab, setCurrentTab] = useState(REVIEW_QUESTIONS_TABS.OWN_ANSWER.key);
  const reviewStore = useSelector(getCurrentReview);
  const review = reviewProp || reviewStore;

  const onChangeSkipped = (_e, isSkipped) => {
    const updateData = { isSkipped, ...(isSkipped && { rate: 0 }) };
    return onChange(question.id, updateData);
  };

  const handleClickQuestion = () => {
    if (onQuestionClick) {
      onQuestionClick(question.id);
    }
  };

  return (
    <QuestionCard
      title={question.name}
      isActive={isActive}
      hideFooter
      onClick={handleClickQuestion}
      isError={isError}
      tabs={tabs}
      handleChangeTab={setCurrentTab}
      currentTab={currentTab}
      isNoChildrenPadding={currentTab !== REVIEW_QUESTIONS_TABS.OWN_ANSWER.key}
      indexCurrent={indexCurrent}
      indexTotal={indexTotal}
      isOpenDefault={isOpenDefault}
      isOpenForce={isOpenForce}
    >
      {currentTab === REVIEW_QUESTIONS_TABS.OWN_ANSWER.key ? (
        <>
          {question.description && (
            <Description>
              <RickTextView html={question.description} />
            </Description>
          )}
          {!question.hideRating && (
            <RatingRow
              rate={rating.rate}
              scale={rating.scale}
              scaleLabels={rating.scaleLabels}
              onChange={(rate) => onChange(question.id, { rate })}
              isSkipped={rating.isSkipped}
              isSkippable={question.isSkippable}
              changeSkipped={onChangeSkipped}
              isSingleHover
              disabled={isReadOnly}
            />
          )}
          <StyledEditor
            value={rating.explanation || ''}
            placeholder={`${i18n._(t`Your comment`)}${rating.isCommentObligated ? '*' : ''}`}
            onChange={(explanation) => onChange(question.id, { explanation })}
            compact
            small
            readOnly={isReadOnly}
          />
        </>
      ) : (
        <RatingStatusRows
          ratings={typeRatings}
          requests={review.requests}
          currentTab={currentTab}
          forUser={forUser}
        />
      )}
    </QuestionCard>
  );
};

export default React.memo(withI18n()(ReviewCustomQuestion));
