import isEmpty from 'lodash/isEmpty';
import { createReducer } from 'redux-act';

import * as currentRatingsActions from './actions';

const INITIAL_STATE = {};

export const currentRatings = createReducer(
  {
    [currentRatingsActions.setCurrentRatings]: (_state, payload) => {
      return {
        ...INITIAL_STATE,
        ...(payload || {}),
      };
    },

    [currentRatingsActions.updateCurrentRatings]: (state, payload) => {
      return {
        ...state,
        ...(payload || {}),
      };
    },
    [currentRatingsActions.updateCurrentRating]: (state, { id, data } = {}) => {
      return {
        ...state,
        [id]: {
          ...state[id],
          ...data,
        },
      };
    },
    [currentRatingsActions.setCurrentRatingsIds]: (state, newRatings = []) => {
      if (isEmpty(newRatings)) {
        return state;
      }
      const newState = { ...state };
      newRatings.forEach((newRating) => {
        const ratingStoreId = newRating.skill || newRating.question;
        // rating in store does not have id,
        // add id from created rating
        if (ratingStoreId && newState[ratingStoreId] && !newState[ratingStoreId].id) {
          newState[ratingStoreId].id = newRating.id;
        }
      });
      return newState;
    },
  },
  INITIAL_STATE,
);
