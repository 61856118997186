import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { TablePlaceholder } from '~/components/TablePlaceholder';

import { UpdateBlockContainer } from './design';
import { UpdateItem } from './UpdateItem';

import { getUser, getUsers, getDeletedUsers } from '~/selectors/baseGetters';
import getUserFullName from '~/utils/getUserFullName';

const UpdatesBlock = ({
  updates,
  goalName,
  activityName,
  updateGoalsUpdate,
  updateComments = [],
  updateCommentText,
  updateCommentFunction,
  isReadOnly,
  isLoading,
}) => {
  const { i18n } = useLingui();
  const user = useSelector(getUser);
  const allUsers = useSelector(getUsers);
  const deletedUsers = useSelector(getDeletedUsers);
  const users = { ...allUsers, ...deletedUsers };
  const goalsUpdates = updates.map((u) => {
    const updatedUser = users[u.createdBy];
    const userName = getUserFullName(updatedUser);
    return {
      ...u,
      userName,
      updatedUser,
    };
  });

  return (
    <UpdateBlockContainer>
      {!isEmpty(goalsUpdates) ? (
        goalsUpdates.map((u) => (
          <UpdateItem
            key={u.id}
            update={u}
            goalName={goalName}
            activityName={activityName}
            i18n={i18n}
            isOwner={!isReadOnly && user.id === u.createdBy}
            updateGoalsUpdate={updateGoalsUpdate}
            isEditMode={updateComments.includes(u.id)}
            updateCommentText={updateCommentText}
            updateCommentFunction={updateCommentFunction}
          />
        ))
      ) : (
        <TablePlaceholder
          isFiltered={false}
          emptyStateText={i18n._(t`There are no updates for this goal`)}
          isAllDone={false}
          isLoading={isLoading}
        />
      )}
    </UpdateBlockContainer>
  );
};

export default UpdatesBlock;
