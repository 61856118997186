import styled from 'styled-components';

import { Icon } from '~/components/Icon';

import { COLORS } from '~/styles';

const CompanyBlockWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
  margin-bottom: 32px;
`;

const CompanyBlockItem = styled.div<{}>`
  display: flex;
  align-items: center;
  justify-content: center;
  outline-color: transparent;
  width: 46px;
  height: 46px;
  border-radius: 6px;
  box-sizing: border-box;
`;

const MenuBlocks = styled.div`
  margin: 0 10px;
`;

const MenuFooter = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const MenuTopSection = styled(MenuFooter)``;

const Menu = styled.div<{ $isMenuCollapsed: boolean }>`
  width: ${({ $isMenuCollapsed }) => ($isMenuCollapsed ? '66px' : '257px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  border-right: 1px solid ${COLORS.BORDERS};
  background-color: ${COLORS.BG_PAGE};
  box-sizing: border-box;
  padding-top: 32px;
  overflow: auto;
`;

// secondary menu

const MenuDivider = styled.div<{ isNoMargins?: boolean }>`
  width: 100%;
  height: 1.5px;
  background: ${COLORS.BORDERS};
  margin: ${({ isNoMargins }) => (isNoMargins ? '0 0' : '16px 0')};
`;

const SecondaryMenuTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  overflow: hidden;
  color: ${COLORS.TEXT_MAIN};
  display: flex;
  align-items: center;
  margin: 24px 0 8px 8px;
  height: 36px;
  box-sizing: border-box;
  justify-content: space-between;
`;

const AllCollapsible = styled.div`
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  user-select: none;
`;

const CollapseIcon = styled(Icon)`
  width: 10px;
  height: 10px;
  transform: rotate(275deg);
  margin-bottom: -2px;
  &.active {
    transform: rotate(90deg);
  }
`;

const SecondaryMenuSubTitle = styled.div<{ isActive: boolean }>`
  height: 40px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 12px;
  border-radius: 6px;
  background: ${COLORS.BG_ELEMENTS};
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? COLORS.COMPANY : COLORS.ICONS_PRIMARY)};
  .not-collapsed {
    transform: rotate(90deg);
  }
  &:hover {
    background: ${COLORS.WHITE};
  }
`;

const DropDownIcon = styled(Icon)``;

const SecondaryMenuWrapper = styled.div<{
  isMenuWithIcons?: boolean;
}>`
  background-color: ${COLORS.BG_PAGE};
  min-width: ${({ isMenuWithIcons }) => (isMenuWithIcons ? '257px' : '204px')};
  max-width: ${({ isMenuWithIcons }) => (isMenuWithIcons ? '257px' : '204px')};
  overflow: auto;
  border-right: 1px solid ${COLORS.BORDERS};
  padding: 17px 10px 0;
  box-sizing: border-box;
`;

const MenuGroup = styled.div``;

const SubMenuGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubMenuGroupTitle = styled.div`
  font-size: 12px;
  padding: 0 12px;
  box-sizing: border-box;
  height: 40px;
  display: flex;
  align-items: center;
`;

const SubMenuGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export {
  CompanyBlockItem,
  CompanyBlockWrapper,
  DropDownIcon,
  Menu,
  MenuBlocks,
  MenuDivider,
  MenuFooter,
  MenuTopSection,
  SecondaryMenuSubTitle,
  SecondaryMenuTitle,
  SecondaryMenuWrapper,
  SubMenuGroupContainer,
  SubMenuGroupTitle,
  SubMenuGroup,
  MenuGroup,
  AllCollapsible,
  CollapseIcon,
};
