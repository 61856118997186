import React, { Component } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';

import logo from '~/assets/logo.svg';
import WrongIcon from '~/assets/wrong-icon.svg';

import routes from '~/constants/routes';

const Wrapper = styled.div`
  background: #f8fafb;
  position: relative;
  min-height: 100vh;
`;

const Content = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Logo = styled.img`
  width: 177px;
`;

const Header = styled.div`
  margin-bottom: 40px;
  padding-left: 140px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 140px;
`;

const Footer = styled.div`
  margin-top: 52px;
  padding-left: 140px;
`;

const Title = styled.div`
  font-size: 42px;
  color: #3b3b3b;
  margin-bottom: 20px;
`;

const Description = styled.div`
  font-size: 24px;
  line-height: 1.5;
  color: #333333;
`;

const Icon = styled.img`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

class MyPermissionDeniedPage extends Component {
  goHome = () => {
    const { history } = this.props;
    history.push(routes.HOME);
  };

  render() {
    const { i18n } = this.props;

    return (
      <Wrapper>
        <Content>
          <Header>
            <Logo onClick={this.goHome} alt={i18n._(t`Logo`)} src={logo} />
          </Header>
          <Main>
            <Title>
              <Trans>Permission Denied</Trans>
            </Title>
            <Description>
              <Trans>We are sorry.</Trans>
            </Description>
            <Description>
              <Trans>Click the button to go the main page.</Trans>
            </Description>
            <Icon src={WrongIcon} alt={i18n._(t`wrong icon`)} />
          </Main>
          <Footer>
            <Button onClick={this.goHome} label={i18n._(t`Learned home`)} />
          </Footer>
        </Content>
      </Wrapper>
    );
  }
}

export default withI18n()(withRouter(MyPermissionDeniedPage));
