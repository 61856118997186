import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

const schema = Joi.object({
  name: Joi.array()
    .items(
      Joi.object({
        locale: Joi.string(),
        value: Joi.string().allow(''),
      })
        .required()
        .min(1),
    )
    .custom((items: { locale: string; value: string }[]) => {
      let hasAtLeastOneName = false;
      items?.forEach((name) => {
        if (name.value !== '') {
          hasAtLeastOneName = true;
        }
      });
      if (!hasAtLeastOneName) {
        throw new Error('One translation is required');
      }

      return items;
    }),
  description: Joi.array().items(
    Joi.object({
      locale: Joi.string(),
      value: Joi.string().allow(''),
    }),
  ),

  jobProfiles: Joi.object().pattern(
    Joi.any(),
    Joi.array()
      .items(Joi.object({ id: Joi.string() }).unknown(true))
      .allow(null),
  ),
  skillCategory: Joi.string().required(),
  focusAreas: Joi.array()
    .items(
      Joi.object({
        level: Joi.number().required(),
        values: Joi.array().items(Joi.object()).required(),
      }),
    )
    .required(),
});

const resolver = joiResolver(schema);

export { resolver };
