import { JOB_TEMPLATES_PENDING_SORT_OPTIONS } from '@learned/constants';

import type { ICreateServerRoute, IServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getJobTemplatesPendingSuperAdmin(
  filters: { search?: string },
  options: { skip: number; limit: number; sortBy: JOB_TEMPLATES_PENDING_SORT_OPTIONS },
) {
  const body = {
    filters: {
      search: filters.search,
    },
    options: {
      skip: options.skip,
      limit: options.limit,
      sortBy: options.sortBy,
    },
  };
  return cloudRequest(serverRoutes.jobTemplatesPending.listSuperAdmin as IServerRoute, {}, body);
}

export function deleteJobTemplatesPendingSuperAdmin(jobTemplatePendingIds: string[]) {
  return cloudRequest(
    serverRoutes.jobTemplatesPending.deleteSuperAdmin as IServerRoute,
    {},
    {
      jobTemplatePendingIds,
    },
  );
}

export function getJobTemplatesPendingCountSuperAdmin() {
  return cloudRequest(serverRoutes.jobTemplatesPending.countSuperAdmin as IServerRoute, {});
}

export function getJobTemplatePendingSuperAdmin(jobTemplatePendingId: string) {
  return cloudRequest(
    (serverRoutes.jobTemplatesPending.getSuperAdmin as ICreateServerRoute)(jobTemplatePendingId),
  );
}
