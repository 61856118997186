import React, { ReactNode } from 'react';

import { Trans } from '@lingui/macro';

import { Icon, ICONS } from '~/components/Icon';

import { Container, IconContainer, Subtitle, Text, Title } from './SkillCategory.design';

function SkillCategory({ children }: { children: ReactNode }) {
  return (
    <Container>
      <IconContainer>
        <Icon icon={ICONS.SKILL_CATEGORY} />
      </IconContainer>
      <Text>
        <Title>
          <Trans>Skill category</Trans>
        </Title>
        <Subtitle>{children}</Subtitle>
      </Text>
    </Container>
  );
}

export { SkillCategory };
