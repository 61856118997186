import React from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Button from '~/components/Button';
import RickTextView from '~/components/RickTextView';
import ShowMore from '~/components/ShowMore';

import ExpectedLevelBlock from './ExpectedLevelBlock';

import { COLORS, COLOR_PALETTE } from '~/styles';
import { getSkillDescription } from '~/utils/skillUtils';

const DefinitionBlock = styled.div`
  border-radius: 3px;
  border: solid 1px #dae4f7;
  padding: 12px 22px 14px 17px;
  margin-bottom: 8px;
`;

const DefinitionTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLORS.TEXT_PRIMARY};
`;

const QuestionDescriptionWrapper = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  color: ${COLORS.TEXT_SECONDARY};
  padding: ${(props) => props.$padding || '0 0 10px'};

  span {
    font-weight: 600;
  }

  p {
    margin: 0;
    color: ${COLORS.TEXT_SECONDARY};
  }
`;

const StyledRickTextView = styled(RickTextView)`
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const QuestionDescription = ({
  type,
  description,
  expectedLevel,
  skill,
  lang,
  pdfView,
  openSkillModal,
  padding,
  expectedLevelLabel,
}) => {
  const { i18n } = useLingui();

  switch (type) {
    case QUESTION_TYPES.CUSTOM:
    case QUESTION_TYPES.GOAL_BUSINESS_PLAN:
    case QUESTION_TYPES.GOAL_LEARNING_PLAN:
    case QUESTION_TYPES.GOAL_BUSINESS_EVAL:
    case QUESTION_TYPES.GOAL_LEARNING_EVAL: {
      return (
        description && (
          <QuestionDescriptionWrapper $padding={padding}>
            <RickTextView html={description} />
          </QuestionDescriptionWrapper>
        )
      );
    }
    case QUESTION_TYPES.SKILL:
    case QUESTION_TYPES.JOB_PROFILE: {
      return (
        <QuestionDescriptionWrapper $padding={padding}>
          {(skill?.definitionNL || skill?.definition) && (
            <DefinitionBlock>
              <DefinitionTitle>
                <Trans>Definition:</Trans>
              </DefinitionTitle>
              {pdfView ? (
                <StyledRickTextView html={getSkillDescription(skill, lang)} />
              ) : (
                <ShowMore maxHeight={70}>
                  <StyledRickTextView html={getSkillDescription(skill, lang)} />
                </ShowMore>
              )}
            </DefinitionBlock>
          )}
          <DefinitionBlock>
            {expectedLevel && (
              <ExpectedLevelBlock
                skill={skill}
                lang={lang}
                expectedLevel={expectedLevel}
                expectedLevelLabel={expectedLevelLabel}
              />
            )}
            {!pdfView && (
              <Button
                type="link-primary"
                label={i18n._(t`View all levels`)}
                onClick={openSkillModal}
                styles={{ paddingLeft: 0 }}
              />
            )}
          </DefinitionBlock>
        </QuestionDescriptionWrapper>
      );
    }
    default:
      return null;
  }
};

export default QuestionDescription;
