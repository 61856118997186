import isEmpty from 'lodash/isEmpty';
import { createReducer } from 'redux-act';

import * as teamActions from './actions';

const INITIAL_STATE = {
  data: {},
};

export const teams = createReducer(
  {
    [teamActions.startGetTeams]: (state) => {
      return {
        ...state,
      };
    },
    [teamActions.finishGetTeams]: (state, teams) => {
      return {
        ...state,
        data: teams,
      };
    },
    [teamActions.startCreateTeams]: (state) => {
      return {
        ...state,
      };
    },
    [teamActions.finishCreateTeams]: (state, teams) => {
      const data = {
        ...state.data,
      };

      if (!isEmpty(teams)) {
        teams.forEach((team) => (data[team.id] = team));
      }

      return {
        ...state,
        data,
      };
    },
    [teamActions.startUpdateTeam]: (state) => {
      return state;
    },
    [teamActions.finishUpdateTeam]: (state, team) => {
      return {
        ...state,
        data: {
          ...state.data,
          [team.id]: team,
        },
      };
    },
    [teamActions.finishUpdateTeams]: (state, updatedTeams) => {
      const newTeams = { ...state.data };
      updatedTeams.forEach((updatedTeam) => (newTeams[updatedTeam.id] = updatedTeam));

      return {
        ...state,
        data: updatedTeams,
      };
    },
    [teamActions.startRemoveTeams]: (state) => {
      return {
        ...state,
      };
    },
    [teamActions.finishRemoveTeams]: (state, deletedTeamsIds) => {
      const newTeams = { ...state.data };
      deletedTeamsIds.forEach((deleteTeamId) => delete newTeams[deleteTeamId]);

      return {
        ...state,
        data: newTeams,
      };
    },
  },
  INITIAL_STATE,
);
