import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

const Label = styled.span`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  color: ${COLORS.TEXT_SECONDARY};
  padding: 2px 8px;
  background-color: ${COLORS.BG_PAGE};
  margin-left: 8px;
  white-space: nowrap;
  position: relative;
  border-radius: 3px;
  width: min-content;
  &:before {
    position: absolute;
    content: '';
    border-style: solid;
    border-width: 6px 6px 6px 0;
    border-color: transparent ${COLORS.BG_PAGE} transparent transparent;
    position: absolute;
    left: -6px;
    top: calc(50% - 8px);
    width: 0;
    height: 0;
  }
`;

const SkillCategoriesLabels = ({ skillCategories, allCategories, labels = [] }) => {
  const getMultiLangString = useMultiLangString();

  let result = [...labels];
  (allCategories || []).map((c) => {
    if (skillCategories.includes(c.id) || skillCategories.includes(c.categoryId)) {
      result = [...result, getMultiLangString(c.name)];
    }
  });
  return !isEmpty(result) && result.map((l, i) => <Label key={i}>{l}</Label>);
};

SkillCategoriesLabels.propTypes = {
  skillCategories: PropTypes.arrayOf(PropTypes.string),
  allCategories: PropTypes.arrayOf(PropTypes.object),
};

export default SkillCategoriesLabels;
