import React from 'react';

import { SURVEY_STATUS, SURVEY_TASK_STATUS } from '@learned/constants';
import { Trans } from '@lingui/macro';
import { format } from 'date-fns';
import styled from 'styled-components';

import { DashboardHeader } from '~/components/DashboardHeader';
import { SubmitButton } from '~/components/FillOutSurvey/design';
import { IconOld } from '~/components/IconOld';
import RickTextView from '~/components/RickTextView';
import { Header, Content, ButtonBar, Container } from '~/pages/SurveyTaskView/design';
import type { IPopulatedSurveyTask } from '~/pages/SurveyTaskView/hooks';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

const Information = styled.div`
  display: flex;
  flex-direction: row;
  gap: 42px;
  align-items: center;
  justify-content: center;
`;

const Line = styled.div`
  width: 1px;
  box-sizing: border-box;
  border: solid 1.2px ${COLORS.BORDER_HARD};
  height: 19px;
`;

const InformationItem = styled.span`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.DARK_GRAY};
`;

const Title = styled.h1`
  font-size: 26px;
  letter-spacing: -0.29px;
  margin: 0;
  padding: 0;
  color: ${COLORS.BLACK};
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.BLACK};
  max-height: 45vh;
  overflow-y: auto;
`;

function SurveyIntro({
  goBack,
  surveyTask,
  closeIntro,
}: {
  goBack: () => void;
  closeIntro: () => void;
  surveyTask: IPopulatedSurveyTask;
}) {
  const getMultiLangString = useMultiLangString();

  return (
    <>
      <Header>
        <DashboardHeader
          // @ts-ignore
          title={getMultiLangString(surveyTask.name)}
          onBack={goBack}
          height={'112px'}
        />
      </Header>
      <Content>
        <Container>
          <Title>{getMultiLangString(surveyTask.name)}</Title>
          <Description>
            {/* @ts-ignore */}
            <RickTextView html={getMultiLangString(surveyTask.survey.description)} />
          </Description>
          <Information>
            <InformationItem>
              <Trans>Deadline</Trans>: {format(new Date(surveyTask.deadline), 'dd-MM-yyyy')}
            </InformationItem>
            <Line />
            <InformationItem>
              <Trans>Questions</Trans>: {surveyTask.questions.length}
            </InformationItem>
            <Line />
            <InformationItem>
              <Trans>Anonymous</Trans>:{' '}
              {surveyTask.survey.anonymity.isAnonymous ? <Trans>yes</Trans> : <Trans>no</Trans>}
            </InformationItem>
          </Information>
          <ButtonBar>
            {surveyTask.survey.status !== SURVEY_STATUS.ARCHIVED && (
              <SubmitButton type="button" onClick={closeIntro}>
                {surveyTask.status === SURVEY_TASK_STATUS.TODO ? (
                  <Trans>Start</Trans>
                ) : (
                  <Trans>Continue</Trans>
                )}
                <IconOld name="ChevronForward" width={16} height={16} className="icon" />
              </SubmitButton>
            )}
          </ButtonBar>
        </Container>
      </Content>
    </>
  );
}

export { SurveyIntro };
