import React from 'react';

import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import IconMenu from '~/components/IconMenu';
import RickTextView from '~/components/RickTextView';
import ShowMore from '~/components/ShowMore';
import SkillName from '~/components/Skill/SkillName';
import SvgIcon from '~/components/SvgIcon';
import Avatar from '~/components/UI/Avatar';
import { QuestionContainer } from '~/pages/RTFeedbacks/components/styledComponets';

import {
  CardHeader,
  Date,
  DescriptionBlock,
  HeaderBlock,
  Tags,
  TopSection,
} from './styledComponets';

import goalsIcon from '~/assets/main-menu-icons/goal.svg';

import { COLOR_PALETTE } from '~/styles';

const HeaderInfo = styled.div`
  margin-left: 8px;
`;

const GoalContainer = styled.div`
  padding: 8px;
  border-radius: 6px;
  border: solid 1px ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GoalName = styled.div`
  margin-left: 8px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const SmallTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;

  span {
    font-size: 14px;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
  }
`;

const StyledRickTextView = styled(RickTextView)`
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
`;

const CardTopSection = ({
  cardUser,
  description,
  skills,
  goal,
  titleText,
  createdDate,
  menuItems,
  isProactive = false,
  showMenu = true,
  isHideGoal = false,
  isHideCardHeader = false,
}) => (
  <TopSection isProactive={isProactive}>
    {!isHideCardHeader && (
      <CardHeader>
        <HeaderBlock>
          <Avatar size={28} userId={cardUser} />
          <HeaderInfo>
            <SmallTitle>{titleText}</SmallTitle>
          </HeaderInfo>
        </HeaderBlock>
        {showMenu && <IconMenu items={menuItems} />}
      </CardHeader>
    )}
    {!isProactive && (
      <QuestionContainer>
        {!isEmpty(description) && (
          <DescriptionBlock>
            <ShowMore maxHeight={45}>
              <StyledRickTextView html={description} />
            </ShowMore>
          </DescriptionBlock>
        )}
        {!isEmpty(goal) && !isHideGoal && (
          <GoalContainer>
            <SvgIcon
              width="18px"
              height="18px"
              url={goalsIcon}
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
              isDefaultColor
            />
            <GoalName>{goal.name}</GoalName>
          </GoalContainer>
        )}
        <Date>{createdDate}</Date>
        {!isEmpty(skills) && (
          <Tags>
            {skills.map((skill) => (
              <span key={skill.id}>
                #<SkillName skill={skill} />
              </span>
            ))}
          </Tags>
        )}
      </QuestionContainer>
    )}
  </TopSection>
);

export default React.memo(CardTopSection);
