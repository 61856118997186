import React from 'react';

import { RESPONSE_STATUSES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import uniq from 'lodash/uniq';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import AddPeopleAutoCompleteModal from '~/components/AddPeopleAutoCompleteModal';
import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import { FieldGroup, FieldTitle } from '~/components/Text';
import AvatarCard from '~/components/UI/AvatarCard';

import useBoolState from '~/hooks/useBoolState';
import { getCreatedBy, getId, getParticipants } from '~/selectors/currentConversation';
import {
  updateConversationCreatedBy,
  updateConversationParticipants,
} from '~/services/conversations';
import { setCreatedBy, setParticipants } from '~/store/currentConversation/actions';

const StyledFieldGroup = styled(FieldGroup)`
  margin-bottom: 40px;
`;

const UserSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ParticipantsWrapper = styled.div`
  overflow: auto;
  max-height: 300px;
  background: inherit;
`;

const AvatarRow = styled.div`
  padding: 10px 12px 10px 9px;
  border-radius: 6px;
  border: solid 1px #dbe1ef;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const StyledFieldTitle = styled(FieldTitle)`
  margin-bottom: 8px;
`;

const WideButton = styled(Button)`
  border: 1px solid #dbe1ef;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
`;

const CreatorSection = () => {
  const { i18n } = useLingui();
  const createdBy = useSelector(getCreatedBy);
  const participants = useSelector(getParticipants);
  const dispatch = useDispatch();
  const conversationId = useSelector(getId);
  const $isAddParticipantsModal = useBoolState(false);

  const onChangeCreator = async ([newCreator]) => {
    const confirmOptions = {
      title: i18n._(t`Are you sure?`),
      confirmText: i18n._(t`Submit`),
      cancelText: i18n._(t`Cancel`),
    };

    if (
      await confirm(
        i18n,
        i18n._(
          t`You are about to change creator of the conversation. This changes will be applied immediately. Are you sure you want to change the creator of the conversation?`,
          i18n,
        ),
        confirmOptions,
      )
    ) {
      const response = await updateConversationCreatedBy(conversationId, {
        createdBy: newCreator.id,
      });

      if (response.status === RESPONSE_STATUSES.SUCCESS) {
        dispatch(setCreatedBy(newCreator.id));
      }

      // we need to add createdBy as first of participants,
      // to give access to update 1-1
      const newParticipants = uniq([
        newCreator.id,
        ...(participants.filter((id) => id !== createdBy) || []),
      ]);
      await updateConversationParticipants(conversationId, {
        participants: newParticipants,
      });

      dispatch(setParticipants(newParticipants));
    }
  };

  return (
    <StyledFieldGroup>
      <StyledFieldTitle>
        <Trans>Creator</Trans>
      </StyledFieldTitle>
      <UserSection>
        <ParticipantsWrapper>
          {createdBy && (
            <AvatarRow>
              <AvatarCard type={AvatarCard.TYPES.SMALL} userId={createdBy} size={28} />
            </AvatarRow>
          )}
        </ParticipantsWrapper>
        <WideButton
          label={i18n._(t`Edit creator`)}
          type="primary-border-wide"
          onClick={$isAddParticipantsModal.on}
        />
      </UserSection>
      {$isAddParticipantsModal.value && (
        <AddPeopleAutoCompleteModal
          title={i18n._(t`Select new creator`)}
          usersToHide={[createdBy]}
          onModalClose={$isAddParticipantsModal.off}
          onSubmit={onChangeCreator}
          singleChoice
        />
      )}
    </StyledFieldGroup>
  );
};

export default React.memo(CreatorSection);
