import styled from 'styled-components';

import Dialog from '~/components/Dialog';
import { SearchField } from '~/components/Text';

import { COLORS } from '~/styles';

export const Form = styled.form`
  border-radius: 10px;
  box-sizing: border-box;
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 32px 40px;
`;

export const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 57px auto 0;

  & img {
    margin-right: 20px;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: ${COLORS.SUBTEXT};
  margin-bottom: 34px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 49px;
`;

export const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(151, 151, 151, 0.1);
  padding-top: 19px;
  box-sizing: border-box;
`;

export const StyledModal = styled(Dialog)`
  max-width: 750px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 22px 38px 32px 42px;
  box-shadow: -2px 3px 5px 1px rgba(0, 0, 0, 0.11);
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-size: 26px;
  font-weight: normal;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
  padding: 0;
  margin: 0;
`;

export const SubTitle = styled.span`
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
  font-size: 16px;
  margin: 0;
  padding: 0;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  margin-right: 12px;
  width: 100%;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 30px;
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 0;
`;
