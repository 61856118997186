import React, { Fragment, useMemo, useState, useEffect } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  SURVEY_STATUS,
  SURVEY_TASK_STATUS,
  SURVEY_TYPE,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import styled from 'styled-components';

import { Button } from '~/components/Buttons/Button';
import { ButtonVariant, ButtonSize } from '~/components/Buttons/types';
import DashboardHeader from '~/components/DashboardHeader';
import { FillOutSurvey } from '~/components/FillOutSurvey';
import { ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { UnarchiveSurveyModal } from '~/components/Modals/UnarchiveSurveyModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TabSlider } from '~/components/TabSlider';
import type { ITabSliderItem } from '~/components/TabSlider/types';
import Tooltip from '~/components/Tooltip';

import { SurveyParticipation } from './components/Participation';
import { SurveyResults } from './components/Results';
import { SurveySetup } from './components/Setup';
import { ActionItemBlock, Actions } from './design';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import { useLanguageState } from '~/hooks/useLanguageState';
import { checkModuleSurvey } from '~/selectors/baseGetters';
import {
  archiveSurvey,
  getSurvey,
  updateSurveyIsShared,
  unarchiveSurvey,
} from '~/services/surveys';
import { getSurveyTemplate } from '~/services/surveyTemplates';
import { COLORS } from '~/styles';

import type { PopulatedSurveyTemplate } from '../SurveyTemplateUpdate/types';
import type { ISurvey, ISurveyTemplate, ISurveyQuestion } from '@learned/types';

export const TABS_SLIDER_ENUM = {
  PARTICIPATION: 'participation',
  RESULTS: 'results',
  SET_UP: 'setup',
};

const PreviewWrapper = styled.div`
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  background-color: ${COLORS.BG_PAGE};
`;

const Warning = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.ERROR};
`;

const SurveysDashboard = () => {
  const { i18n } = useLingui();
  const history = useHistory();
  const isModuleSurveyEnabled = useSelector(checkModuleSurvey);
  const params: Record<string, string | undefined> = useParams();
  const [survey, setSurvey] = useState<ISurvey>();
  const languageState = useLanguageState();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowUnarchiveSurveyModal, setIsShowUnarchiveSurveyModal] = useState(false);
  const [previewTemplate, setPreviewTemplate] = useState<PopulatedSurveyTemplate | null>(null);
  const [previewTemplateId, setPreviewTemplateId] = useState<string | null>(null);
  const hasSurveyStarted = moment().isAfter(survey?.dates.startDate);

  useEffect(() => {
    if (!isModuleSurveyEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleSurveyEnabled, history]);

  // fetch template for template preview mode
  useEffect(() => {
    let mounted = true;
    const fetchTemplate = async () => {
      if (!previewTemplateId) {
        return;
      }
      setIsLoading(true);
      const { data } = await getSurveyTemplate(previewTemplateId, {
        populate: ['questions'],
        joinToQuestions: ['themeName'],
      });
      const surveyTemplate: PopulatedSurveyTemplate = data[API_RETURN_FIELDS.SURVEY_TEMPLATE];
      if (mounted && surveyTemplate) {
        setPreviewTemplate(surveyTemplate);
      }
      setIsLoading(false);
    };
    fetchTemplate();
    return () => {
      mounted = false;
    };
  }, [previewTemplateId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = async () => {
    try {
      if (params?.surveyId) {
        setIsLoading(true);
        const { data } = await getSurvey(params.surveyId, { populate: ['template'] });
        setSurvey(data.survey);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const namePrimaryLanguage = useMemo(() => {
    return survey?.name[languageState.companyPrimaryLanguage.locale];
  }, [languageState.companyPrimaryLanguage.locale, survey?.name]);

  const onEdit = () => {
    if (survey) {
      routes.SURVEY_UPDATE.go({}, { surveyId: survey?.id, isBackPath: true });
    }
  };

  const onArchive = async () => {
    if (survey) {
      const isConfirmed = (await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Are you sure you want to archive the survey?`),
        description: i18n._(
          t`This will stop the survey from being sent out and will make participants unable to edit their answers.`,
        ),
      })) as unknown as boolean;

      if (isConfirmed) {
        setIsLoading(true);

        try {
          await archiveSurvey(survey.id);
          await fetchData();
        } catch (e) {
          setIsLoading(false);
        }
      }
    }
  };

  const onUnarchive = async (isEndDate: boolean, endDate: Date | null) => {
    if (survey) {
      const isRequireEndDate = survey.type === SURVEY_TYPE.TRADITIONAL;

      setIsLoading(true);

      try {
        await unarchiveSurvey(survey.id, {
          endDate: isRequireEndDate || isEndDate ? endDate : null,
          isEndDate,
        });
        await fetchData();
      } catch (e) {
        setIsLoading(false);
      }
    }
  };

  const onShare = async (isShared: boolean) => {
    const isConfirmed =
      !isShared ||
      (await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Are you sure you want to share the results?`),
        description: i18n._(t`All participants will have access to the results.`),
      }));

    if (isConfirmed) {
      try {
        if (survey?.id) {
          const { data } = await updateSurveyIsShared(survey.id, { isShared });
          setSurvey((survey) => {
            if (survey) {
              return {
                ...survey,
                isShared: data.survey.isShared,
              };
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const onQuestionEdit = async (questionId: string) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Be aware`),
      description: i18n._(t`Changing this question will affect all surveys that use this question`),
    });
    if (isConfirmed) {
      routes.SURVEY_TEMPLATE_UPDATE.go(
        {},
        { surveyTemplateId: previewTemplate?.id, isBackPath: true, query: { questionId } },
      );
    }
  };

  const onTemplatePreview = (template: ISurveyTemplate) => setPreviewTemplateId(template.id);

  if (previewTemplate) {
    return (
      <PreviewWrapper>
        <FillOutSurvey
          {...(hasSurveyStarted ? {} : { onEdit: onQuestionEdit })}
          isPreview
          surveyTask={{
            status: SURVEY_TASK_STATUS.DRAFT,
            name: previewTemplate.name,
            questions: previewTemplate.questions as unknown as ISurveyQuestion[],
            survey: survey as ISurvey,
          }}
          goBack={() => {
            setPreviewTemplate(null);
            setPreviewTemplateId(null);
          }}
        />
      </PreviewWrapper>
    );
  }

  const TAB_SLIDER_ITEMS: ITabSliderItem[] = [
    {
      key: TABS_SLIDER_ENUM.PARTICIPATION,
      label: t`Participation`,
      content: survey ? <SurveyParticipation survey={survey} /> : <></>,
    },
    {
      key: TABS_SLIDER_ENUM.RESULTS,
      label: t`Results`,
      content: survey ? <SurveyResults survey={survey} /> : <></>,
    },
    {
      key: TABS_SLIDER_ENUM.SET_UP,
      label: t`Set-up`,
      content: survey ? (
        <SurveySetup
          survey={survey}
          languageState={languageState}
          onTemplatePreview={onTemplatePreview}
          onShare={onShare}
        />
      ) : (
        <></>
      ),
    },
  ];

  return (
    <Fragment>
      <DashboardHeader
        title={namePrimaryLanguage}
        onBack={async () => {
          routes.SURVEYS_OVERVIEW.go(
            // @ts-ignore
            { role: ROLES.USER },
            {
              noHash: true,
              hash: 'organisation',
            },
          );
        }}
        actions={
          <Actions>
            {survey?.isInvalid && (
              <Warning>
                {/* @ts-ignore */}
                <Tooltip
                  tooltip={i18n._(
                    t`Survey has invalid data. Please check if survey has participants and questions!`,
                  )}
                  maxWidth={'400px'}
                >
                  <div>{i18n._(t`Not possible to send questions!`)}</div>
                </Tooltip>
              </Warning>
            )}
            {[SURVEY_STATUS.ACTIVE, SURVEY_STATUS.ARCHIVED].includes(
              survey?.status as SURVEY_STATUS,
            ) && (
              <ActionItemBlock>
                {survey?.status === SURVEY_STATUS.ACTIVE && (
                  <Button
                    label={i18n._(t`Stop & archive`)}
                    size={ButtonSize.MEDIUM}
                    isLoading={isLoading}
                    disabled={!survey}
                    variant={ButtonVariant.TEXT_PRIMARY}
                    onClick={onArchive}
                  />
                )}
                {survey?.status === SURVEY_STATUS.ARCHIVED && (
                  <Button
                    label={i18n._(t`Unarchive`)}
                    size={ButtonSize.MEDIUM}
                    isLoading={isLoading}
                    disabled={!survey}
                    variant={ButtonVariant.TEXT_PRIMARY}
                    onClick={() => setIsShowUnarchiveSurveyModal(true)}
                  />
                )}
              </ActionItemBlock>
            )}
            {survey?.status !== SURVEY_STATUS.ARCHIVED && (
              <ActionItemBlock>
                <Button
                  label={i18n._(t`Edit`)}
                  icon={ICONS.EDIT_PENCIL}
                  size={ButtonSize.MEDIUM}
                  variant={ButtonVariant.TEXT_PRIMARY}
                  isLoading={isLoading}
                  disabled={!survey}
                  onClick={onEdit}
                />
              </ActionItemBlock>
            )}
          </Actions>
        }
      />
      {isShowUnarchiveSurveyModal && survey && (
        <UnarchiveSurveyModal
          surveyType={survey.type}
          // @ts-ignore
          oldIsEndDateValue={!!survey.dates.isEndDate}
          oldEndDateValue={survey.dates.endDate}
          onClose={() => setIsShowUnarchiveSurveyModal(false)}
          onSubmit={(isEndDate, endDate) => onUnarchive(isEndDate, endDate)}
        />
      )}
      <ShowSpinnerIfLoading loading={isLoading}>
        {isModuleSurveyEnabled && survey ? <TabSlider items={TAB_SLIDER_ITEMS} /> : <></>}
      </ShowSpinnerIfLoading>
    </Fragment>
  );
};

export { SurveysDashboard };
