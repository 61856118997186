import React from 'react';

import { UseFormReturn } from 'react-hook-form';

import type { ISectionState } from '~/components/SideBar/SectionStateHook';

import { GeneralSummary } from './components/GeneralSummary';
import { ParticipantsSummary } from './components/ParticipantsSummary';
import { SettingsSummary } from './components/SettingsSummary';
import { TimelineSummary } from './components/TimelineSummary';

import type { IReviewIndividualForm } from '../../types';

interface SummarySectionProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewIndividualForm>;
}

const SummarySection = ({ formMethods, sectionState }: SummarySectionProps) => {
  return (
    <>
      <GeneralSummary formMethods={formMethods} sectionState={sectionState} />
      <ParticipantsSummary formMethods={formMethods} sectionState={sectionState} />
      <SettingsSummary formMethods={formMethods} sectionState={sectionState} />
      <TimelineSummary formMethods={formMethods} sectionState={sectionState} />
    </>
  );
};

export { SummarySection };
