import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const CheckedUrlIcon = ({ size, color, ...props }) => (
  <InlineSvg height={size} width={size} {...props} childrenFirst>
    <g fill="none" fillRule="evenodd">
      <circle fill={color || '#29CCAB'} cx="12" cy="12" r="12" />
      <path
        stroke="#FFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.035 12.794 12 16.9l5.84-9.545"
      />
    </g>
  </InlineSvg>
);

CheckedUrlIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

CheckedUrlIcon.defaultProps = {
  size: 24,
  className: '',
};

export default CheckedUrlIcon;
