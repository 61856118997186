import React from 'react';

import { UserAvatar } from '~/components/UserAvatar';

import { TaskStatus } from './TaskStatus';

import { useOnHover } from '~/hooks/useOnHover';

import { ListRow, RowRightBlock, RowLeftBlock } from '../design';

import type { ITask } from '@learned/types';

interface ListRowTaskProps {
  task: ITask;
  onDelete?: (task: ITask) => void;
}

const ListRowProvideFeedback = ({ task, onDelete }: ListRowTaskProps) => {
  const { ref, isHovering } = useOnHover();

  return (
    <ListRow ref={ref} $isHovering={onDelete && isHovering}>
      <RowRightBlock>
        {/* @ts-ignore */}
        <UserAvatar userId={task.userFrom} options={{ showTooltip: true }} />
      </RowRightBlock>
      <RowLeftBlock>
        <TaskStatus
          task={task}
          onDelete={isHovering && onDelete ? () => onDelete(task) : undefined}
        />
      </RowLeftBlock>
    </ListRow>
  );
};

export { ListRowProvideFeedback };
