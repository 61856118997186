import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { ContextMenu, ContextOption } from '~/components/ContextMenu';
import { ICONS, ICON_SIZES } from '~/components/Icon';
import Tooltip, { TOOLTIP_SIZES, TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import {
  Header,
  Title,
  Wrapper,
  Navigator,
  CardBody,
  ForwardIcon,
  Footer,
  ItemCount,
  DimensionsContainer,
  Dimension,
  ColorCircle,
  FooterPlaceHolder,
  ContextMenuWrapper,
  ContextOptionWrapper,
  NavigatorContainer,
  ContextPlaceHolder,
  DraggableIcon,
  GrabIcon,
  DroppableContainer,
  DraggableContainer,
} from './ReportCard.design';

type IProps = {
  title: string;
  children: React.ReactNode;
  dimensions?: {
    color: string;
    name: string;
  }[];
  hiddenItemCount?: number;
  hasExtraSpace?: boolean;
  onGoToReportClick: () => void;
  reportId?: string | null;
  reportType?: string | null;
  reference: string;
  deleteItem: (reportId: string | null, reportType: string | null) => void;
  isDragDisabled?: boolean;
  isAdmin: boolean;
};

function ReportCard({
  reference,
  title,
  children,
  dimensions = [],
  onGoToReportClick,
  hiddenItemCount = 0,
  hasExtraSpace = false,
  reportId = null,
  reportType = null,
  deleteItem,
  isDragDisabled = false,
  isAdmin = false,
}: IProps): JSX.Element {
  const { i18n } = useLingui();
  const [isHovering, setIsHovering] = useState(true);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const getContextMenu = () => {
    return (
      <ContextMenuWrapper>
        <ContextMenu>
          <ContextOptionWrapper>
            <ContextOption action={() => deleteItem(reportId, reportType)} icon={ICONS.DELETE_BIN}>
              {i18n._(t`Delete from dashboard`)}
            </ContextOption>
          </ContextOptionWrapper>
        </ContextMenu>
      </ContextMenuWrapper>
    );
  };

  const capitalizeFirstLetter = (title: string) => {
    if (!title) {
      return '';
    }
    return title.charAt(0).toUpperCase() + title.slice(1);
  };

  return (
    <Droppable droppableId={`droppable-${reference}`} key={`droppable-${reference}`}>
      {(provided, snapshot) => {
        return (
          <DroppableContainer {...provided.droppableProps} ref={provided.innerRef}>
            <Draggable
              draggableId={`draggable-${reference}`}
              index={1}
              key={`draggable-${reference}`}
              isDragDisabled={isDragDisabled}
            >
              {(draggableData) => (
                <DraggableContainer
                  {...draggableData.draggableProps}
                  {...draggableData.dragHandleProps}
                  ref={draggableData.innerRef}
                  isHidden={snapshot.isDraggingOver}
                >
                  <Wrapper onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
                    <Header>
                      {!isDragDisabled && (
                        <DraggableIcon>
                          <GrabIcon icon={ICONS.GRAB_GRID} size={ICON_SIZES.SMALL} />
                        </DraggableIcon>
                      )}
                      <Title>
                        <Tooltip
                          placement={TOOLTIP_PLACEMENTS.BOTTOM}
                          disabled={false}
                          size={TOOLTIP_SIZES.BIG}
                          tooltip={capitalizeFirstLetter(title)}
                          maxWidth="300px"
                        >
                          <span>{title}</span>
                        </Tooltip>
                      </Title>
                      <NavigatorContainer>
                        <Navigator onClick={onGoToReportClick}>
                          {i18n._(t`Go to report`)}
                          <ForwardIcon icon={ICONS.BACK} size={ICON_SIZES.MEDIUM} />
                        </Navigator>
                        <ContextPlaceHolder>
                          {reportId && reportType && isAdmin && isHovering && getContextMenu()}
                        </ContextPlaceHolder>
                      </NavigatorContainer>
                    </Header>
                    <CardBody>{children}</CardBody>

                    {(hiddenItemCount > 0 || dimensions.length > 0) && (
                      <Footer>
                        {hiddenItemCount > 0 && (
                          <ItemCount>
                            +{hiddenItemCount} {i18n._(t`items`)}
                          </ItemCount>
                        )}
                        <DimensionsContainer>
                          {dimensions.map((dimension, index) => (
                            <Dimension key={index}>
                              <ColorCircle color={dimension.color} />
                              {dimension.name}
                            </Dimension>
                          ))}
                        </DimensionsContainer>
                      </Footer>
                    )}

                    {hasExtraSpace && <FooterPlaceHolder />}
                  </Wrapper>
                </DraggableContainer>
              )}
            </Draggable>
          </DroppableContainer>
        );
      }}
    </Droppable>
  );
}

export { ReportCard };
