import React, { useEffect, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import ManageSkillsModal from '~/components/ManageSkillsModal';
import { SkillFullInfoModal } from '~/components/Modals/SkillFullInfoModal';
import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';

import SkillsIcon from '~/assets/mdi-tag-multiple.svg';

import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import getAllUsers from '~/selectors/getAllUsers';
import getLang from '~/selectors/getLang';
import { getSkillCategories } from '~/services/skillCategories';
import { getSkills } from '~/services/skills';
import { getUserSkillsOrder, updateUserSkillsOrder } from '~/services/users';
import { updateUserProfile } from '~/store/auth/actions';
import { COLORS, COLOR_PALETTE } from '~/styles';
import { getSkillName } from '~/utils/skillUtils';

const Wrapper = styled.div``;

const Section = styled.div``;

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  margin-bottom: 12px;
  line-height: 1.14;
  ${(props) => props.$notFirst && 'margin-top: 16px;'}
`;

const SkillsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Skill = styled.div`
  background-color: ${COLORS.BG_PAGE};
  border-radius: 6px;
  color: ${COLOR_PALETTE.BLACK};
  cursor: ${(props) => (props.$readOnly ? 'auto' : 'pointer')};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  height: 24px;
  margin-right: 8px;
  margin-bottom: 8px;
  min-width: 40px;
  padding: 8px 12px;
  text-align: center;
  vertical-align: middle;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const UserProfileSkills = ({
  userId,
  user: viewer,
  readOnly,
  forceUpdateSkills,
  setForceUpdateSkills,
  $manageSkillsModal,
  updateLocalSkillsState,
  finishSkillsLoading,
}) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const lang = useSelector(getLang);
  const users = useSelector(getAllUsers);

  const [populatedSkills, setPopulatedSkills] = useState({});
  const [anotherCompaniesSkillsIds, setAnotherCompaniesSkillsIds] = useState({});
  const [orderedCategories, setOrderedCategories] = useState([]);
  const [userSkillsOrder, setUserSkillsOrder] = useState({});
  const user = userId ? users[userId] : viewer;

  // Selected skill ID
  const [selectedSkill, setSelectedSkill] = useState();

  // Loading
  const $loading = useBoolState(true);

  // Modals and menus
  const skillModalOpen = useBoolState();

  // Global state hooks
  const dispatch = useDispatch();

  // Show skill from url if passed in query params
  const [skillFromUrlShown, setSkillFromUrlShown] = useState(false);
  const skillFromUrl = new URL(window.location).searchParams.get('skill');
  if (skillFromUrl && !skillFromUrlShown) {
    setSelectedSkill(skillFromUrl);
    skillModalOpen.on();
    setSkillFromUrlShown(true);
  }

  // Download skills objects, user ratings and set levels
  useEffect(() => {
    async function retrieveData() {
      $loading.on();
      const [retrievedSkillsResponse, skillCategories, skillsOrder] = await Promise.all([
        getSkills(),
        getSkillCategories(),
        getUserSkillsOrder(user.id),
      ]);
      const retrievedSkills = retrievedSkillsResponse?.data?.skills;
      setOrderedCategories(Object.values(skillCategories));
      setUserSkillsOrder(skillsOrder.data);
      setPopulatedSkills(retrievedSkills);

      // another companies skills
      const anotherCompaniesSkillsIds = (user.skills || []).filter(
        (skillId) => !retrievedSkills[skillId],
      );

      let userSkills = [];

      (user.skills || [])
        .filter((skillId) => retrievedSkills[skillId])
        .forEach((skill) => userSkills.push(retrievedSkills[skill]));

      setAnotherCompaniesSkillsIds(anotherCompaniesSkillsIds);
      if (forceUpdateSkills) {
        setForceUpdateSkills(false);
      }
      if (finishSkillsLoading) {
        finishSkillsLoading();
      }
      $loading.off();
    }
    if (forceUpdateSkills) {
      retrieveData();
    }
    // eslint-disable-next-line
  }, [user.id, user.skills, skillFromUrl, forceUpdateSkills]);

  const onSave = async ({ skills: newSkills, skillsHidden, skillsOrder }) => {
    $loading.on();

    const data = {
      skills: (anotherCompaniesSkillsIds || []).concat(newSkills),
      skillsHidden,
    };

    await dispatch(updateUserProfile(data));
    await updateUserSkillsOrder(user.id, skillsOrder);
    await updateLocalSkillsState();
    $loading.off();
    $manageSkillsModal.off();
  };

  const renderCategory = (category, index) => {
    const categorySkills = (userSkillsOrder[category.id] || []).filter(
      (s) => !(user?.skillsHidden || []).includes(s),
    );

    return categorySkills && categorySkills.length > 0 ? (
      <Section key={index}>
        <Label $notFirst={index !== 0}>
          <Trans>{getMultiLangString(category.name)}</Trans>
        </Label>
        <SkillsRow>{categorySkills.map(renderSkill)}</SkillsRow>
      </Section>
    ) : null;
  };

  const renderSkill = (skillId) => {
    const skill = populatedSkills[skillId];
    if (!skill) {
      return null;
    }
    return (
      <Skill
        onClick={() => {
          if (readOnly) {
            return;
          }
          setSelectedSkill(skill.id);
          skillModalOpen.on();
        }}
        key={skill.id}
        $readOnly={readOnly}
      >
        {getSkillName(skill, lang)}
      </Skill>
    );
  };

  const getPopulatedUserSkills = () => {
    let populatedUserSkills = {};
    if (!isEmpty(userSkillsOrder)) {
      for (const category in userSkillsOrder) {
        if ({}.hasOwnProperty.call(userSkillsOrder, category)) {
          populatedUserSkills[category] = userSkillsOrder[category]
            .map((s) => populatedSkills[s])
            .filter((s) => s);
        }
      }
    }
    return populatedUserSkills;
  };

  const isSkillsToShow = Object.values(userSkillsOrder)
    .flat(1)
    .some((s) => !(user?.skillsHidden || []).includes(s));

  return (
    <Wrapper>
      <ShowSpinnerIfLoading loading={$loading.value}>
        {isSkillsToShow ? (
          orderedCategories.map(renderCategory)
        ) : (
          <Placeholder
            title={i18n._(t`No skills`)}
            Icon={() => (
              <SvgIcon
                url={SkillsIcon}
                width="50px"
                height="50px"
                isDefaultColor
                defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
              />
            )}
          />
        )}
      </ShowSpinnerIfLoading>
      {skillModalOpen.value && selectedSkill && (
        <SkillFullInfoModal
          userId={userId}
          onClose={skillModalOpen.off}
          skillId={selectedSkill}
          isProfile
        />
      )}

      {$manageSkillsModal.value && (
        <ManageSkillsModal
          currentManualAddedSkills={user?.skills || []}
          populatedSkills={populatedSkills}
          onClose={$manageSkillsModal.off}
          onSave={onSave}
          loading={$loading.value}
          currentSkillsHidden={user?.skillsHidden || []}
          categories={orderedCategories}
          populatedUserSkills={getPopulatedUserSkills()}
        />
      )}
    </Wrapper>
  );
};

export default UserProfileSkills;
