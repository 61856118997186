import React, { useEffect, useState, useRef } from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

import { GoalEvalBlock } from '~/components/NewReviewReport/components/GoalEvalBlock';
import { TemplateCardWithFooter } from '~/components/SetupTools/components/TemplateBaseComponents';
import SvgIcon from '~/components/SvgIcon';
import { Header3 } from '~/components/UI/Typographics/headers';
import UpdateGoalsModal from '~/components/UpdateGoalsModal';

import Feedback from './Feedback';
import GoalPlanBlock from './GoalPlanBlock';
import QuestionName from './QuestionName';

import toggleCloseIcon from '~/assets/toggle-close.svg';
import toggleOpenIcon from '~/assets/toggle-open.svg';

import { ROLES, REQUEST_STATUSES, REQUEST_TYPES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { getUser, getSelectedRole } from '~/selectors/baseGetters';
import { updateGoalCycleForUserReview } from '~/services/reviewsOld';
import { COLOR_PALETTE } from '~/styles';

const CardContent = styled.div`
  display: flex;
  padding: 16px 20px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  min-height: 81px;
  box-sizing: border-box;

  ${Header3} {
    margin: 0;
    font-weight: 600;
    line-height: 1.75;
    margin-top: 5px;
  }
`;

const QuestionCounter = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  text-transform: uppercase;
  margin-bottom: 2px;
`;

const Question = styled.div`
  display: block;
  @media print {
    ${(props) => props.$pdfView && 'break-inside: avoid;'};
  }
`;

const TextColumn = styled.div``;

function QuestionCard({
  question,
  isReadOnly,
  skill,
  i18n,
  review,
  isOpen,
  handleOnRatingUpdated,
  isLockedFeedback,
  viewerId,
  pdfView,
  users,
  sectionIndex,
  indexTotal,
  indexCurrent,
}) {
  const [goals, setGoals] = useState(question.goals);
  const $open = useBoolState(isOpen);
  const ref = useRef();
  const currentUser = useSelector(getUser);
  const currentRole = useSelector(getSelectedRole);
  const $isUpdateGoalsModal = useBoolState();
  const history = useHistory();
  const location = useLocation();

  const query = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { isUpdateGoalsModal } = query;
  const queryForModal = `isUpdateGoalsModal=${question.id}&sectionToOpen=${sectionIndex}`;

  const openUpdateGoalsModal = (isUpdateUrl = true) => {
    $isUpdateGoalsModal.on();

    if (isUpdateUrl) {
      // add isUpdateGoalsModal to url query
      history.replace({
        search: location.search ? `${location.search}&${queryForModal}` : `?${queryForModal}`,
        hash: location.hash,
      });
    }
  };

  const closeUpdateGoalsModal = () => {
    $isUpdateGoalsModal.off();

    // remove isUpdateGoalsModal from url query
    const regex = new RegExp(`(&|\\?)${queryForModal}`, 'i');
    history.replace({
      search: location.search.replace(regex, ''),
      hash: location.hash,
    });
  };

  useEffect(() => {
    if (isUpdateGoalsModal && isUpdateGoalsModal === question.id) {
      // open modal, without change url
      openUpdateGoalsModal(false);
    }

    // eslint-disable-next-line
  }, []);

  const updateGoals = async (goals) => {
    setGoals(goals);
    await updateGoalCycleForUserReview(review.id);
  };

  const toggleItem = () => {
    $open.value ? $open.off() : $open.on();
  };

  const getIsUpdatePlanGoal = () => {
    const request = get(review, 'requests', []).find((r) => {
      return (
        r.status === REQUEST_STATUSES.SHARED.key &&
        r.toUser === currentUser.id &&
        r.type === REQUEST_TYPES.FEEDBACK_FROM_COACH
      );
    });
    const isOwnerUpdateGoal =
      currentRole === ROLES.USER &&
      review.createdFor === currentUser.id &&
      review.isSelfFeedbackProvided;
    const isCoachUpdateGoal = [ROLES.USER, ROLES.COACH].includes(currentRole) && !!request;
    return isOwnerUpdateGoal || isCoachUpdateGoal;
  };

  const isUpdatePlanGoal = getIsUpdatePlanGoal();

  function getQuestionContent() {
    switch (question.type) {
      case QUESTION_TYPES.GOAL_BUSINESS_PLAN:
      case QUESTION_TYPES.GOAL_LEARNING_PLAN:
        return (
          <GoalPlanBlock
            review={review}
            goals={goals}
            question={question}
            pdfView={pdfView}
            currentUser={currentUser}
            isUpdatePlanGoal={isUpdatePlanGoal}
            openUpdateGoalsModal={openUpdateGoalsModal}
            isLockedFeedback={isLockedFeedback}
          />
        );

      case QUESTION_TYPES.GOAL_BUSINESS_EVAL:
      case QUESTION_TYPES.GOAL_LEARNING_EVAL:
        return (
          <GoalEvalBlock
            question={question}
            review={review}
            handleOnRatingUpdated={handleOnRatingUpdated}
            isLockedFeedback={isLockedFeedback}
            viewerId={viewerId}
            pdfView={pdfView}
            users={users}
            isReadOnly={isReadOnly}
          />
        );
      default:
        return (
          <Feedback
            review={review}
            skill={skill}
            question={question}
            handleOnRatingUpdated={handleOnRatingUpdated}
            isLockedFeedback={isLockedFeedback}
            viewerId={viewerId}
            pdfView={pdfView}
            users={users}
          />
        );
    }
  }

  return (
    <Question ref={ref} $pdfView={pdfView}>
      <TemplateCardWithFooter $noShadow={pdfView}>
        <CardContent onClick={toggleItem} $isActiv={$open.value}>
          <TextColumn>
            {indexTotal && (
              <QuestionCounter>
                {i18n._(t`Question ${indexCurrent + 1}/${indexTotal}`)}
              </QuestionCounter>
            )}
            <QuestionName
              question={question}
              isReadOnly={isReadOnly}
              pdfView={pdfView}
              skill={skill}
              review={review}
              reviewSkillCategories={review?.skillCategories}
            />
          </TextColumn>
          {!pdfView && (
            <div>
              <SvgIcon
                width="24px"
                height="24px"
                url={$open.value ? toggleOpenIcon : toggleCloseIcon}
              />
            </div>
          )}
        </CardContent>
        {$open.value && getQuestionContent()}
      </TemplateCardWithFooter>
      {$isUpdateGoalsModal.value && (
        <UpdateGoalsModal
          i18n={i18n}
          question={question}
          onClose={closeUpdateGoalsModal}
          review={review}
          onSave={updateGoals}
        />
      )}
    </Question>
  );
}

QuestionCard.propTypes = {
  review: PropTypes.object.isRequired,
  skill: PropTypes.object,
  goal: PropTypes.object,
  question: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  type: PropTypes.string,
  isOpen: PropTypes.bool,
  handleOnRatingUpdated: PropTypes.func,
  isLockedFeedback: PropTypes.bool,
};

export default withI18n()(QuestionCard);
