import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import AdminLogsPage from '~/pages/AdminLogsPage';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('logs', {
  routesPrivate: {
    user: [],
    coach: [],
    admin: [
      <PrivateRoute
        key={routes.LOGS.routePath(ROLES.ADMIN)}
        exact
        path={routes.LOGS.routePath(ROLES.ADMIN)}
        component={AdminLogsPage}
        allowedRoles={[ROLES.ADMIN]}
      />,
    ],
  },
  routesPublic: [],
});
