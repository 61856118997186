import { t } from '@lingui/macro';
import moment from 'moment';

import type { IUserConnection } from '@learned/types';

export const calculateDuration = (userConnection: IUserConnection) => {
  const startDate = userConnection.startDate
    ? moment(userConnection.startDate)
    : moment(userConnection.meta.createdDate);
  const endDate = userConnection.endDate ? moment(userConnection.endDate) : moment();

  const duration = moment.duration(endDate.diff(startDate));
  const years =
    duration.years() === 0
      ? ''
      : `${duration.years()} ${duration.years() === 1 ? t`year` : t`years`}`;
  const months =
    duration.months() === 0
      ? ''
      : `${duration.months()} ${duration.months() === 1 ? t`month` : t`months`}`;
  const days =
    duration.days() === 0 && (years || months)
      ? ''
      : `${duration.days()} ${duration.days() === 1 ? t`day` : t`days`}`;

  return `${years} ${months} ${days}`;
};
