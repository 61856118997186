import React from 'react';

import { t } from '@lingui/macro';
import find from 'lodash/find';
import moment from 'moment';
import styled from 'styled-components';

import type { IColumnTable } from '~/@types/table';
import { COLORS } from '~/styles';

import type { ISkillTemplatePending } from '@learned/types';
import type { I18n } from '@lingui/core';

const NameRow = styled.span`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
`;

export const INDUSTRIES_COLUMNS: IColumnTable<ISkillTemplatePending>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Industry`),
    accessor: 'industry',
    maxWidth: '600px',
    renderCell: (item) => {
      return <NameRow>{item.name.en_GB || item.name.nl_NL || find(item.name)}</NameRow>;
    },
    sortBy: {
      asc: {
        key: JSON.stringify({ 'name.en_GB': 1 }),
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: JSON.stringify({ 'name.en_GB': -1 }),
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Created`),
    accessor: 'crated',
    maxWidth: '300px',
    sortBy: {
      asc: {
        key: JSON.stringify({ 'meta.createdDate': 1 }),
        label: (i18n: I18n) => i18n._(t`Least - Most`),
      },
      desc: {
        key: JSON.stringify({ 'meta.createdDate': -1 }),
        label: (i18n: I18n) => i18n._(t`Most - Least`),
      },
    },
    renderCell: (item) => {
      return <NameRow>{moment(item.meta?.createdDate).format('DD-MM-YYYY')}</NameRow>;
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Updated`),
    accessor: 'modified',
    maxWidth: '200px',
    sortBy: {
      asc: {
        key: JSON.stringify({ 'meta.lastModifiedDate': 1 }),
        label: (i18n: I18n) => i18n._(t`Least - Most`),
      },
      desc: {
        key: JSON.stringify({ 'meta.lastModifiedDate': -1 }),
        label: (i18n: I18n) => i18n._(t`Most - Least`),
      },
    },
    renderCell: (item) => {
      return (
        <NameRow>
          {item.meta?.lastModifiedDate
            ? moment(item.meta?.lastModifiedDate).format('DD-MM-YYYY')
            : moment(item.meta?.createdDate).format('DD-MM-YYYY')}
        </NameRow>
      );
    },
  },
];
