import React from 'react';

import { GOAL_TYPES, GOAL_VIEWERS } from '@learned/constants';
import { Trans } from '@lingui/macro';

import { ICONS } from '~/components/Icon';
import SkillName from '~/components/Skill/SkillName';
import { dataMappingGoalCycles } from '~/pages/GoalSetup/helpers';

import convertToTimeString from '~/utils/convertToTimeString';

import type { IGoal, IGoalCycle } from '@learned/types';

export const getSettingsItems = (goal: IGoal) => {
  const isPersonalGoal = [GOAL_TYPES.BUSINESS, GOAL_TYPES.PERSONAL].includes(
    goal.type as GOAL_TYPES,
  );

  const settingItems = [
    {
      name: <Trans>Goal</Trans>,
      item:
        goal.type === GOAL_TYPES.TEAM ? (
          <Trans>Team goal</Trans>
        ) : isPersonalGoal ? (
          <Trans>Personal goal</Trans>
        ) : (
          <Trans>Company goal</Trans>
        ),
      icon: ICONS.GOALS,
      key: 'goal',
    },
    isPersonalGoal && {
      name: <Trans>Subtype</Trans>,
      item:
        goal.type === GOAL_TYPES.PERSONAL ? (
          <Trans>Learning goal</Trans>
        ) : (
          <Trans>Business goal</Trans>
        ),
      icon: ICONS.TYPE,
      key: 'subtype',
    },
    {
      name: <Trans>Privacy</Trans>,
      item:
        goal.viewers === GOAL_VIEWERS.PUBLIC ? (
          <Trans>Public</Trans>
        ) : goal.viewers === GOAL_VIEWERS.OWNER_AND_COACHES ? (
          <Trans>Owner and coaches</Trans>
        ) : (
          <Trans>Team</Trans>
        ),
      icon: ICONS.INCOGNITO,
      key: 'privacy',
    },
    {
      name: <Trans>Created</Trans>,
      item: convertToTimeString(goal?.meta?.createdDate),
      icon: ICONS.EDIT_PENCIL,
      key: 'created',
    },
    {
      name: <Trans>Editors</Trans>,
      item:
        goal.isCoachesOfOwnerCanEdit && goal.isOwnerCanEdit ? (
          <Trans>Owners, all coaches of owners</Trans>
        ) : goal.isOwnerCanEdit ? (
          <Trans>Owners</Trans>
        ) : goal.isCoachesOfOwnerCanEdit ? (
          <Trans>All coaches of owners</Trans>
        ) : (
          <Trans>N/A</Trans>
        ),
      icon: ICONS.USERS,
      key: 'editors',
    },
    {
      name: <Trans>Timeframe</Trans>,
      item:
        (goal?.goalCycles || []).length > 0 ? (
          <div>
            {goal?.goalCycles
              // @ts-ignore
              .map((item: IGoalCycle, i: number) => (
                <div key={i}>{dataMappingGoalCycles(item)?.fullName || ''}</div>
              ))}
          </div>
        ) : (
          <Trans>N/A</Trans>
        ),
      icon: ICONS.AGENDA,
      key: 'timeframe',
    },
    {
      name: <Trans>Skill:</Trans>,
      item:
        goal?.skills && (goal?.skills || []).length > 0 ? (
          <SkillName skill={goal?.skills[0]} />
        ) : (
          <Trans>N/A</Trans>
        ),
      icon: ICONS.SETTINGS,
      key: 'skill',
    },
  ].filter((i) => i);

  return settingItems;
};
