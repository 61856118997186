import React, { FunctionComponent } from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import Button from '~/components/Button';
import DuplicateIcon from '~/components/Icons/Duplicate';
import Pencil2Icon from '~/components/Icons/Pencil2';
import UserPlusIcon from '~/components/Icons/UserPlus';
import UsersIcon from '~/components/Icons/Users';

import { COLORS } from '~/styles';

const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--company-color);
  position: relative;
  & .oval {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${COLORS.BG_PAGE};
    width: 32px;
    height: 32px;
    border-radius: 100px;
    margin-right: 5px;
    & svg {
      fill: var(--company-color);
    }
  }
  & .title {
    text-transform: uppercase;
    position: absolute;
    left: 42px;
  }

  :hover {
    font-weight: 600;
  }

  margin-right: 20px;
  cursor: pointer;
`;

const InvisibleText = styled.div`
  color: transparent;
  font-weight: 600;
`;

const StyledWhiteButton = styled(Button)`
  height: 64px;
  border: none;
  border-left: 1px solid ${COLORS.BORDER_LIGHT};
  width: 153px;
  min-width: 153px;
  border-radius: 0;
`;

interface IActionsButtons {
  isEditBtnVisible: boolean;
  onEdit: () => void;
  isDuplicateBtnVisible: boolean;
  onDuplicate: () => void;
  isParticipantsBtnVisible: boolean;
  onParticipantsClick: () => void;
  isEditorsBtnVisible: boolean;
  onEditorsClick: () => void;
  isAddToPlanBtnVisible: boolean;
  onAddToPlan: () => void;
}

const ActionsButtons: FunctionComponent<IActionsButtons> = ({
  isEditBtnVisible,
  onEdit,
  isDuplicateBtnVisible,
  onDuplicate,
  isParticipantsBtnVisible,
  onParticipantsClick,
  isEditorsBtnVisible,
  onEditorsClick,
  isAddToPlanBtnVisible,
  onAddToPlan,
}) => {
  return (
    <>
      {isEditBtnVisible && (
        <ActionButton onClick={onEdit}>
          <span className="oval">
            <Pencil2Icon size={20} />
          </span>
          <span className="title">
            <Trans>Edit</Trans>
          </span>
          <InvisibleText>
            <Trans>Edit</Trans>
          </InvisibleText>
        </ActionButton>
      )}
      {isDuplicateBtnVisible && (
        <ActionButton onClick={onDuplicate}>
          <span className="oval">
            <DuplicateIcon size={20} />
          </span>
          <span className="title">
            <Trans>Duplicate</Trans>
          </span>
          <InvisibleText>
            <Trans>Duplicate</Trans>
          </InvisibleText>
        </ActionButton>
      )}
      {isParticipantsBtnVisible && (
        <ActionButton onClick={onParticipantsClick}>
          <span className="oval">
            <UsersIcon size={20} />
          </span>
          <span className="title">
            <Trans>Participants</Trans>
          </span>
          <InvisibleText>
            <Trans>Participants</Trans>
          </InvisibleText>
        </ActionButton>
      )}
      {isEditorsBtnVisible && (
        <ActionButton onClick={onEditorsClick}>
          <span className="oval">
            <UserPlusIcon size={20} />
          </span>
          <span className="title">
            <Trans>Editors</Trans>
          </span>
          <InvisibleText>
            <Trans>Editors</Trans>
          </InvisibleText>
        </ActionButton>
      )}
      {isAddToPlanBtnVisible && (
        <StyledWhiteButton
          // @ts-ignore
          type={Button.types.white}
          label={<Trans>Add to plan</Trans>}
          onClick={onAddToPlan}
        />
      )}
    </>
  );
};

export default ActionsButtons;
