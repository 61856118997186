import React, { useState } from 'react';

import { CONFIRMATION_MODAL_TYPE, REPORT_TYPES, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch, useSelector } from 'react-redux';

import { ContextMenu, ContextOption } from '~/components/ContextMenu';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import Tooltip, { TOOLTIP_PLACEMENTS, TOOLTIP_SIZES } from '~/components/Tooltip';

import {
  LinkStyled,
  MenuBlockIconStyled,
  ContextMenuWrapper,
  ContextOptionWrapper,
  MenuColumn,
  MenuColumnStyled,
  MenuItem,
} from './SubMenuItem.design';

import { getCustomReports } from '~/selectors/baseGetters';
import { ITabConfigurationResponse, TTabResponse } from '~/services/reports';
import { updateSelectedRole } from '~/store/selected/actions';

import { MenuItemTitle, MenuRow } from '../../styles/menuItemDesign';
import { IMenuItem } from '../../types';
import { getReportAttributes } from '../utils';

import type { DraggableProvided } from 'react-beautiful-dnd';

interface ISecondaryMenuItemProps {
  menuItem: IMenuItem;
  pathname: string;
  isSortable?: boolean;
  showContextMenu?: boolean;
  draggableProvided?: DraggableProvided;
  onDeleteItem?: (id: string, isActiveRoute: boolean) => void;
  onDuplicateItem?: (id: string) => void;
  onRenameItem?: (menuItem: IMenuItem) => void;
}

const SubMenuItem = ({
  menuItem,
  pathname,
  isSortable,
  showContextMenu,
  draggableProvided,
  onDeleteItem,
  onDuplicateItem,
  onRenameItem,
}: ISecondaryMenuItemProps) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const customReports: TTabResponse[] = useSelector(getCustomReports);
  const { reportType, reportId } = getReportAttributes(menuItem.key);
  // finds the reportType from customReports
  const selectedReport =
    customReports?.length > 0
      ? customReports.find((cr) => cr.reportType === reportType)?.tabs || []
      : [];
  const filteredConfiguration = selectedReport.filter((report) => report.tabId === reportId);
  // Fix the type of configuration
  const configuration =
    (filteredConfiguration[0] as unknown as ITabConfigurationResponse['controls']) || null;

  const isActiveRoute = menuItem.url === pathname;
  const [isHovering, setIsHovering] = useState(false);
  const isDefaultReports =
    reportType && Object.values(REPORT_TYPES).includes(reportType) && menuItem.role === ROLES.ADMIN;

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const showContext =
    configuration &&
    (configuration.allowDuplicate || configuration.allowDelete || configuration.allowRename);

  const onDeleteAction = async () => {
    if (
      await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        title: i18n._(t`Delete?`),
        description: i18n._(t`Are you sure? This action cannot be undone`),
      })
    ) {
      onDeleteItem?.(menuItem.key, isActiveRoute);
    }
  };

  const getContextMenu = () => {
    return (
      <ContextMenuWrapper>
        <ContextMenu>
          {configuration && configuration.allowDuplicate && onDuplicateItem && (
            <ContextOptionWrapper>
              <ContextOption
                action={() => {
                  onDuplicateItem(menuItem.key);
                }}
                icon={ICONS.DUPLICATE}
              >
                {i18n._(t`Duplicate`)}
              </ContextOption>
            </ContextOptionWrapper>
          )}
          {configuration && configuration.allowDelete && onDeleteItem && (
            <ContextOptionWrapper>
              <ContextOption isWarning action={onDeleteAction} icon={ICONS.DELETE_BIN}>
                {i18n._(t`Delete`)}
              </ContextOption>
            </ContextOptionWrapper>
          )}
          {configuration && configuration.allowRename && onRenameItem && (
            <ContextOptionWrapper>
              <ContextOption
                isWarning
                action={() => {
                  onRenameItem(menuItem);
                }}
                icon={ICONS.EDIT_PENCIL}
              >
                {i18n._(t`Rename`)}
              </ContextOption>
            </ContextOptionWrapper>
          )}
        </ContextMenu>
      </ContextMenuWrapper>
    );
  };

  const wrapWithTooltip = (title: string) => {
    return (
      <Tooltip
        placement={TOOLTIP_PLACEMENTS.RIGHT}
        delay={[500, 0]}
        tooltip={title}
        size={TOOLTIP_SIZES.DEFAULT_TL}
        arrow={false}
      >
        <MenuItemTitle isCurrent={isActiveRoute} isBigLeftMargin>
          {title}
        </MenuItemTitle>
      </Tooltip>
    );
  };

  return (
    <LinkStyled
      onClick={() => {
        dispatch(updateSelectedRole(menuItem.role || null));
      }}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      to={menuItem.url || ''}
    >
      <MenuItem isCurrentRoute={isActiveRoute}>
        <MenuRow>
          {isSortable || showContextMenu ? (
            <>
              {isSortable && draggableProvided && (
                <MenuBlockIconStyled {...draggableProvided.dragHandleProps}>
                  <Icon icon={ICONS.GRAB_GRID} size={ICON_SIZES.SMALL} />
                </MenuBlockIconStyled>
              )}
              <MenuColumnStyled>{wrapWithTooltip(menuItem.title?.(i18n) || '')}</MenuColumnStyled>
              {isHovering && showContextMenu && isDefaultReports && showContext && getContextMenu()}
            </>
          ) : (
            <MenuColumn>{wrapWithTooltip(menuItem.title?.(i18n) || '')}</MenuColumn>
          )}
        </MenuRow>
      </MenuItem>
    </LinkStyled>
  );
};
export { SubMenuItem };
