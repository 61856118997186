import { ROLES } from '~/constants';
import { pageOverview, pageReport } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'engagement';

// overview
export const ENGAGEMENT = new LinkConstructor([ROLES.USER, ROLES.COACH, ROLES.ADMIN], () =>
  pageOverview(routeName),
);

export const ENGAGEMENT_REPORT = new LinkConstructor([ROLES.ADMIN], () => pageReport(routeName));
