import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const CopyIcon = ({ size, ...props }) => (
  <InlineSvg height={size} width={size} {...props} childrenFirst viewBox="0 0 21 21">
    <g fill="none" fillRule="evenodd">
      <path d="M.701.144h20v20h-20z" />
      <path
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.826 13.268h3.75v-10h-10v3.75"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.826 7.019h10v10h-10z"
      />
    </g>
  </InlineSvg>
);

CopyIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

CopyIcon.defaultProps = {
  size: 21,
  className: '',
};

export default CopyIcon;
