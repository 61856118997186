import map from 'lodash/map';
import { createReducer } from 'redux-act';

import * as userActions from './actions';

import { CONNECTION_STATUSES } from '~/constants';

const INITIAL_STATE = {
  data: {},
  inactive: {},
  deleted: {},
  loading: false,
};

export const users = createReducer(
  {
    [userActions.startGetUsers]: (state, _payload) => {
      return {
        ...state,
        loading: true,
      };
    },
    [userActions.updateUser]: (state, payload) => {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.userId]: { ...state.data[payload.userId], ...payload.update },
        },
      };
    },
    [userActions.finishGetUsers]: (state, payload) => {
      map(payload, (u) => {
        u.status = CONNECTION_STATUSES.ACTIVE.key;
      });
      return {
        ...state,
        data: { ...payload },
        loading: false,
      };
    },
    [userActions.finishGetInactiveUsers]: (state, payload) => {
      map(payload, (u) => {
        u.status = CONNECTION_STATUSES.INACTIVE.key;
      });
      return {
        ...state,
        inactive: { ...payload },
        loading: false,
      };
    },
    [userActions.finishSetInactiveUser]: (state, payload) => {
      return {
        ...state,
        inactive: {
          ...state.inactive,
          [payload.id]: {
            ...payload,
            status: CONNECTION_STATUSES.INACTIVE.key,
          },
        },
        loading: false,
      };
    },
    [userActions.finishRemoveInactiveUser]: (state, payload) => {
      const newInactiveUsers = { ...state.inactive };
      delete newInactiveUsers[payload.id];
      return {
        ...state,
        inactive: { ...newInactiveUsers },
        loading: false,
      };
    },
    [userActions.finishSetUserData]: (state, payload) => {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.id]: payload,
        },
      };
    },
    [userActions.finishUpdateMemberProfile]: (state, payload) => {
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      };
    },
    [userActions.finishGetDeletedUsers]: (state, payload) => {
      return {
        ...state,
        deleted: { ...payload },
        loading: false,
      };
    },
    [userActions.removeUser]: (state, payload) => {
      const { [payload]: deletedUser1, ...data } = state.data;
      const { [payload]: deletedUser2, ...inactive } = state.inactive;
      const user = deletedUser1 || deletedUser2;
      if (!user) {
        return state;
      }

      return {
        ...state,
        data,
        inactive,
        deleted: {
          ...state.deleted,
          [user.id]: user,
        },
      };
    },
    [userActions.finishDeleteUsers]: (state, payload) => {
      const ids = (payload || []).map((p) => p.id);
      const newData = { ...state.data };
      const newInactive = { ...state.inactive };
      const newDeleted = { ...state.deleted };
      ids.forEach((userId) => {
        delete newData[userId];
        delete newInactive[userId];
        newDeleted[userId] = payload[userId];
      });

      return {
        ...state,
        data: newData,
        inactive: newInactive,
        deleted: newDeleted,
      };
    },
    [userActions.restoreUser]: (state, payload) => {
      const { [payload]: deletedUser, ...users } = state.deleted;
      if (!deletedUser) {
        return state;
      }

      const newState = {
        ...state,
        deleted: users,
      };

      if (deletedUser.status === CONNECTION_STATUSES.INACTIVE.key) {
        return {
          ...newState,
          inactive: {
            ...state.inactive,
            [deletedUser.id]: deletedUser,
          },
        };
      }

      return {
        ...newState,
        data: {
          ...state.data,
          [deletedUser.id]: deletedUser,
        },
      };
    },
  },
  INITIAL_STATE,
);
