import { DependencyList, useCallback, useEffect, useRef, useState } from 'react';

/**
 * A function that concatenates two arrays of the same type.
 *
 * @template T
 * @param {{ fetch: () => Promise<T>, initialState: T }} obj - An object containing the initialState
 *  and a fetch function.
 * @param {DependencyList} dependencyArray - Array containing the dependencies for the useEffect.
 * @returns {[T, boolean, () => void]} An array with three items; the first item is the response,
 *  the second item is a boolean indicating whether the response is loading, and the third is a
 *  function that will refetch.
 */
function useAsyncFetch<T>(
  {
    fetch,
    initialState,
  }: {
    fetch: () => Promise<T>;
    initialState: T;
  },
  dependencyArray?: DependencyList,
): [T, boolean, () => void] {
  const [response, setResponse] = useState<T>(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const unmountedRef = useRef(false);

  const refetch = useCallback(async () => {
    unmountedRef.current = false;
    setIsLoading(true);

    const response = await fetch();
    if (unmountedRef.current) {
      return;
    }

    setResponse(response);
    setIsLoading(false);

    // The reason we do not put fetch into the dependency array is because we want to avoid having
    // to put every fetch into a useCallback. If we put it into the array and not use useCallback it
    // will go into an infinite loop. If you want it to refresh whenever something changes, you have
    // to pass it in through the dependencyArray prop.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyArray ?? []);

  useEffect(() => {
    refetch();

    return () => void (unmountedRef.current = true);
  }, [refetch]);

  return [response, isLoading, refetch];
}

export { useAsyncFetch };
