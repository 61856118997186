import React from 'react';

import {
  CURRENCY,
  GOALS_UPDATES_TARGET_TYPE,
  GOAL_PROGRESS_TYPES,
  SYMBOLS,
} from '@learned/constants';
import { Trans } from '@lingui/macro';
import { get, isEmpty } from 'lodash';
import moment from 'moment';

import { StatusLabel } from '~/components/GoalsBlock/components/StatusLabel';
import { UserAvatarGroup } from '~/components/UserAvatar/UserAvatarGroup';

import {
  AvatarContainer,
  BottomRow,
  DateTimeContainer,
  HiglightedText,
  ItemContainer,
  StyledRichTextView,
  Title,
  TopRow,
  UpdateCommentContainer,
  UserName,
} from './design';

import type { IGoalsUpdate } from '@learned/types';

type Progress = {
  old?: number;
  new?: number;
  type?: GOAL_PROGRESS_TYPES;
  currency?: CURRENCY;
};

const getSymbol = (progress: Progress) => {
  switch (progress.type) {
    case GOAL_PROGRESS_TYPES.AVG:
    case GOAL_PROGRESS_TYPES.AVG_OF_CONTRIBUTING_GOALS:
    case GOAL_PROGRESS_TYPES.PERCENTAGE: {
      return '%';
    }
    case GOAL_PROGRESS_TYPES.CURRENCY: {
      return SYMBOLS[progress.currency!];
    }
    default: {
      return '';
    }
  }
};

interface IProps {
  update: IGoalsUpdate;
  goalName?: string;
  activityName?: string;
  isOwner: boolean;
  updateGoalsUpdate?: void;
  isEditMode?: boolean;
  updateCommentText?: void;
  updateCommentFunction?: void;
}

const UpdateItem = ({ update, activityName, goalName }: IProps) => {
  const isActivityUpdate = update.targetType === GOALS_UPDATES_TARGET_TYPE.ACTIVITY;

  const updateDate = get(update, 'meta.lastModifiedDate', get(update, 'meta.createdDate'));
  const fromStatus = update?.status?.old;
  const toStatus = update?.status?.new;
  // @ts-ignore
  const name = isActivityUpdate ? activityName || update.target?.name : goalName;

  // progress
  let fromProgress = null;
  let toProgress = null;
  let symbol = null;

  // If it is done type, leave progress fields as null, otherwise fill them
  if (!isEmpty(update.progress) && !(update.progress?.type === GOAL_PROGRESS_TYPES.IS_DONE)) {
    symbol = getSymbol(update.progress);
    fromProgress = update.progress.old;
    toProgress = update.progress.new;
  }
  return (
    <ItemContainer>
      <TopRow>
        <AvatarContainer>
          {update.updatedUser && (
            <UserAvatarGroup users={[{ ...update.updatedUser, userId: update.updatedUser.id }]} />
          )}
          {/* @ts-ignore */}
          <UserName>{update.userName}</UserName>
        </AvatarContainer>
        <DateTimeContainer>
          <Trans>
            {moment(updateDate).format('DD-MM-YYYY')} | {moment(updateDate).format('HH:mm')}
          </Trans>
        </DateTimeContainer>
      </TopRow>

      <BottomRow>
        <Title>
          {fromProgress === null && isEmpty(fromStatus) && !isEmpty(update.comment) ? (
            <Trans>Added a comment to</Trans>
          ) : (
            <Trans>Updated</Trans>
          )}{' '}
          <HiglightedText>{name}</HiglightedText>{' '}
          {fromProgress !== null && (
            <>
              <Trans>from</Trans>{' '}
              <HiglightedText>
                {symbol !== '%' ? symbol : ''}
                {fromProgress}
                {symbol === '%' ? symbol : ''}
              </HiglightedText>{' '}
              <Trans>to</Trans>{' '}
              <HiglightedText>
                {symbol !== '%' ? symbol : ''}
                {toProgress}
                {symbol === '%' ? symbol : ''}
              </HiglightedText>{' '}
            </>
          )}
          {fromProgress !== null && fromStatus && (
            <>
              <Trans>and</Trans>{' '}
            </>
          )}
          {fromStatus && (
            <>
              <Trans>status from</Trans>{' '}
              <StatusLabel status={fromStatus} progress={update.progress?.old} /> <Trans>to</Trans>{' '}
              <StatusLabel status={toStatus!} progress={update.progress?.new} />
            </>
          )}
        </Title>
        <UpdateCommentContainer>
          {update.comment && <StyledRichTextView html={update.comment} />}
        </UpdateCommentContainer>
      </BottomRow>
    </ItemContainer>
  );
};

export { UpdateItem };
