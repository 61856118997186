import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import IconButton from '~/components/IconButton';
import DeleteIcon from '~/components/Icons/Delete';
import StatusIcon from '~/components/StatusIcon';
import AvatarCard from '~/components/UI/AvatarCard';
import TableCard, { TableRow, TableCol } from '~/components/UI/TableCard';

import { CONNECTION_STATUSES, INVITE_STATUSES } from '~/constants';
import { getInvites, cancelInvite } from '~/services/invites';
import { COLORS } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';

class TeamOverviewBoard extends Component {
  constructor(props) {
    super(props);
    this.state = { invites: {} };
  }

  componentDidMount = () => {
    Promise.all([getInvites(INVITE_STATUSES.SENT.key)]).then(([invites]) => {
      this.setState({ invites });
    });
  };

  cancelAndRemoveInvite = async (invite) => {
    this.setState({ loading: true });
    await cancelInvite(invite.id);
    this.setState((prevState) => ({
      ...prevState,
      loading: false,
      invites: filter(prevState.invites, (i) => i.id !== invite.id),
    }));
  };

  getCols = (type, i18n) => {
    if (type === 'coach') {
      return [
        {
          title: i18n._(t`Coach`),
          name: 'user',
          getValue: getUserFullName,
        },
        {
          title: i18n._(t`Status`),
          width: '120px',
        },
        {
          title: '',
          width: '50px',
        },
      ];
    }

    if (type === 'members') {
      return [
        {
          title: i18n._(t`Members`),
          name: 'user',
          getValue: getUserFullName,
        },
        {
          title: i18n._(t`Status`),
          width: '120px',
        },
        {
          title: '',
          width: '50px',
        },
      ];
    }
  };

  renderCoachRow = (item) => {
    const { companyConnections, team } = this.props;

    let isWaiting = item.status === INVITE_STATUSES.SENT.key;
    if (!isEmpty(team.membersRequested)) {
      const memberRequestedId = team.membersRequested.map((member) => {
        return member.id ? String(member.id) : String(member);
      });
      isWaiting = memberRequestedId.includes(String(item.id));
    }

    const userConnectionWithCompany =
      Object.values(companyConnections).find((conn) => conn.user === item.id) || {};

    return (
      <TableRow key={item.id}>
        <TableCol>
          <AvatarCard userId={item.id} />
        </TableCol>
        <TableCol>
          {isWaiting ? (
            <StatusIcon status={CONNECTION_STATUSES.WAITING.key} />
          ) : userConnectionWithCompany.status ? (
            <StatusIcon status={userConnectionWithCompany.status} />
          ) : null}
        </TableCol>
        <TableCol>
          <IconButton
            size={24}
            onClick={() =>
              item.status === INVITE_STATUSES.SENT.key
                ? this.cancelAndRemoveInvite(item)
                : this.props.removeCoachFromTeam(item)
            }
            color={COLORS.SUBTEXT}
          >
            <DeleteIcon size={16} />
          </IconButton>
        </TableCol>
      </TableRow>
    );
  };

  renderUserRow = (item) => {
    const { companyConnections, team } = this.props;

    let isWaiting = item.status === INVITE_STATUSES.SENT.key;
    if (!isEmpty(team.membersRequested)) {
      const memberRequestedId = team.membersRequested.map((member) => {
        return member.id ? String(member.id) : String(member);
      });
      isWaiting = memberRequestedId.includes(String(item.id));
    }

    const userConnectionWithCompany =
      Object.values(companyConnections).find((conn) => conn.user === item.id) || {};

    return (
      <TableRow key={item.id}>
        <TableCol>
          <AvatarCard userId={item.id} />
        </TableCol>
        <TableCol>
          {isWaiting ? (
            <StatusIcon status={CONNECTION_STATUSES.WAITING.key} />
          ) : userConnectionWithCompany.status ? (
            <StatusIcon status={userConnectionWithCompany.status} />
          ) : null}
        </TableCol>
        <TableCol>
          <IconButton
            size={24}
            onClick={() =>
              item.status === INVITE_STATUSES.SENT.key
                ? this.cancelAndRemoveInvite(item)
                : this.props.removeMemberFromTeam(item)
            }
            color={COLORS.SUBTEXT}
          >
            <DeleteIcon size={14} />
          </IconButton>
        </TableCol>
      </TableRow>
    );
  };

  render() {
    const { team, users, i18n } = this.props;
    const { invites } = this.state;
    let allMembers = [];
    if (!isEmpty(team.members)) {
      allMembers = [...team.members];
    }

    if (!isEmpty(team.membersRequested)) {
      allMembers = [...allMembers, ...team.membersRequested];
    }
    // coach invites
    let teamCoachInvites = filter(invites, (companyInvite) => {
      let isTeamInvite = false;
      if (!isEmpty(companyInvite.teamsCoach)) {
        companyInvite.teamsCoach.forEach((teamCoachId) => {
          if (String(teamCoachId) === String(team.id)) {
            isTeamInvite = true;
          }
        });
      }
      return isTeamInvite;
    });

    // member invites
    let teamMemberInvites = filter(invites, (companyInvite) => {
      let isTeamInvite = false;
      if (!isEmpty(companyInvite.teamsMember)) {
        companyInvite.teamsMember.forEach((teamMemberId) => {
          if (String(teamMemberId) === String(team.id)) {
            isTeamInvite = true;
          }
        });
      }
      return isTeamInvite;
    });

    let coachesItems = team.coaches;

    if (!isEmpty(coachesItems)) {
      coachesItems = coachesItems.map((c) => (c.id ? c : users[c])).filter((c) => c);
    }

    if (!isEmpty(teamCoachInvites)) {
      const invites = teamCoachInvites.map((c) => (c.id ? c : users[c])).filter((c) => c);
      coachesItems = coachesItems.concat(invites);
    }

    let allTeamMembers = allMembers;
    if (!isEmpty(allTeamMembers)) {
      allTeamMembers = allTeamMembers.map((m) => (m.id ? m : users[m])).filter((m) => m);
    }

    if (!isEmpty(teamMemberInvites)) {
      const invites = teamMemberInvites.map((m) => (m.id ? m : users[m])).filter((m) => m);
      allTeamMembers = allTeamMembers.concat(invites);
    }

    return (
      <TableCard
        hideHeader={true}
        cols={this.getCols('coach', i18n)}
        title={team.name}
        items={
          coachesItems &&
          coachesItems.sort((a, b) => getUserFullName(a).localeCompare(getUserFullName(b)))
        }
        firstPlaceholder={i18n._(t`Which coach will you assign to this team?`)}
        renderRow={this.renderCoachRow}
        isSecondSections
        searchBy="user"
        secondCols={this.getCols('members', i18n)}
        secondItems={
          allTeamMembers &&
          allTeamMembers.sort((a, b) => getUserFullName(a).localeCompare(getUserFullName(b)))
        }
        renderSecondRow={this.renderUserRow}
        secondPlaceholder={i18n._(t`Invite your first member to this team!`)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    users: state.users.data,
    currentRole: state.selected.role,
    selectedCompany: state.selected.company,
    companyConnections: state.companyConnections,
  };
};

export default withI18n()(connect(mapStateToProps)(TeamOverviewBoard));
