import React, { MouseEvent, useCallback, useEffect, useState } from 'react';

import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';
import ChevronIcon from '~/components/Icons/Chevron';
import SvgIcon from '~/components/SvgIcon';

import dropdownIcon from '~/assets/ic-arrow-drop-down-down.svg';

import type { IColumnTable } from '~/@types/table';
import { COLOR_PALETTE } from '~/styles';

import { THLabel, TH, THead, SortMenu, SortIcons, TRHeader } from '../design';

import type { TMultiSelect } from '../';

const StyledCheckBox = styled(CheckBox)`
  margin: 0;
`;

interface IHeaderProps {
  columns: IColumnTable[];
  showMultiSelect?: boolean;
  multiSelect?: TMultiSelect;
  sortProps?: {
    sortBy?: string;
    handleSortBy: (col: string | undefined) => void;
  };
  showMenu?: boolean;
}

const i18nLabel = (label: string | Function, i18n: any) =>
  typeof label === 'function' ? label(i18n) : label;

const Header = ({
  showMultiSelect = false,
  multiSelect,
  columns,
  sortProps,
  showMenu,
}: IHeaderProps) => {
  const [activeSortByColumn, setActiveSortByColumn] = useState<string | undefined>();
  const { i18n } = useLingui();
  const toggleSortColumnMenu = useCallback(
    (accessor: IColumnTable['accessor']) => {
      if (activeSortByColumn === accessor) {
        setActiveSortByColumn('');
      } else {
        setActiveSortByColumn(accessor);
      }
    },
    [activeSortByColumn],
  );

  const checkActiveSortColumn = (column: IColumnTable) => {
    return activeSortByColumn === column.accessor;
  };

  const handleMouseDown = useCallback(
    (event: MouseEvent) => {
      // @ts-ignore
      const className = event?.target?.className;
      if ((className?.match && className?.match('item')) || className?.baseVal?.match('icon')) {
        return;
      } else {
        activeSortByColumn && toggleSortColumnMenu('');
      }
    },
    [toggleSortColumnMenu, activeSortByColumn],
  );

  useEffect(() => {
    // @ts-ignore
    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      // @ts-ignore
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [handleMouseDown]);

  // @ts-ignore
  const isHideHeader = !!showMultiSelect && multiSelect?.checkedCount > 0;

  return (
    <THead>
      <TRHeader isHideHeader={isHideHeader}>
        {isHideHeader && (
          <>
            <TH key={'th-multi-select'} minWidth="20px" maxWidth="65px" padding="0 0 0 32px" />
            {columns.map((column) => (
              <TH
                key={`th-${column.accessor}`}
                padding="0 24px"
                minWidth={column?.minWidth}
                maxWidth={column?.maxWidth}
              />
            ))}
          </>
        )}
        {/* @ts-ignore */}
        {showMultiSelect && !isHideHeader && (
          <TH key={'th-multi-select'} minWidth="20px" maxWidth="65px" padding="16px 0 16px 32px">
            {/* @ts-ignore */}
            <StyledCheckBox
              size="20px"
              checked={multiSelect?.isAllChecked}
              onChange={multiSelect?.onCheckAll}
              defaultColor={undefined}
            />
          </TH>
        )}
        {!isHideHeader &&
          columns.map((column) => (
            <TH
              key={`th-${column.accessor}`}
              minWidth={column?.minWidth}
              maxWidth={column?.maxWidth}
            >
              <THLabel
                className={
                  activeSortByColumn !== undefined
                    ? checkActiveSortColumn(column)
                      ? 'hover'
                      : 'isFade'
                    : ''
                }
                $isPointer={Boolean(column.sortBy)}
                onClick={() => column.sortBy && toggleSortColumnMenu(column.accessor)}
              >
                <span>
                  {typeof column.name === 'function'
                    ? column.name && column.name(i18n)
                    : column.name}
                </span>
                {column.sortBy && (
                  <>
                    <SortIcons>
                      <SvgIcon
                        className="rotate"
                        width="9px"
                        height="7px"
                        isDefaultColor={sortProps?.sortBy !== column?.sortBy?.asc.key}
                        defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                        url={dropdownIcon}
                      />
                      <SvgIcon
                        width="9px"
                        height="7px"
                        isDefaultColor={sortProps?.sortBy !== column?.sortBy?.desc.key}
                        defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                        url={dropdownIcon}
                      />
                    </SortIcons>
                  </>
                )}
              </THLabel>
              {column.sortBy && checkActiveSortColumn(column) && (
                <SortMenu>
                  <button
                    className={`item ${
                      column?.sortBy?.asc?.key === sortProps?.sortBy ? 'selected' : ''
                    }`}
                    onClick={() => {
                      sortProps?.handleSortBy(column?.sortBy?.asc?.key);
                      setActiveSortByColumn('');
                    }}
                  >
                    <ChevronIcon
                      size={12}
                      className="icon"
                      width="12px"
                      height="15px"
                      color={COLOR_PALETTE.GRAY_MIDDLE}
                    />
                    {i18nLabel(column.sortBy?.asc.label, i18n)}
                  </button>
                  <button
                    className={`item ${
                      column?.sortBy?.desc?.key === sortProps?.sortBy ? 'selected' : ''
                    }`}
                    onClick={() => {
                      sortProps?.handleSortBy(column?.sortBy?.desc?.key);
                      setActiveSortByColumn('');
                    }}
                  >
                    <ChevronIcon
                      size={12}
                      className="icon rotate"
                      width="12px"
                      height="15px"
                      color={COLOR_PALETTE.GRAY_MIDDLE}
                    />

                    {i18nLabel(column.sortBy?.desc.label, i18n)}
                  </button>
                </SortMenu>
              )}
            </TH>
          ))}
        {showMenu && <TH key={'th-menu'} minWidth="40px" />}
      </TRHeader>
    </THead>
  );
};

export { Header };
