import styled from 'styled-components';

import { COLORS } from '~/styles';

export const JobSummaryCardWrapper = styled.div`
  box-sizing: border-box;
  min-width: 344px;
  max-height: 146px;
  min-height: 120px;
  background-color: ${COLORS.WHITE};
  padding: 18px 21px 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: linear-gradient(to left, ${COLORS.WHITE} 50%, rgba(240, 244, 247, 0));
`;

export const GradientOverlay = styled.div`
  position: absolute;
  width: 100px;
  height: 104px;
  transform: rotate(90deg);
  padding: 0px 91px 40px 20px;
  left: 50%;
  top: 0px;
  background-image: linear-gradient(rgb(240, 244, 247) 36%, rgba(240, 244, 247, 0));
  z-index: 1;
`;

export const JobSummaryCardTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${COLORS.TEXT_HOVER};
  line-height: 1.63;
  text-wrap: wrap;
`;

export const Line = styled.div`
  width: 272px;
  border: solid 1px #e5e7ec;
  margin-top: 20px;
  background-image: linear-gradient(to left, ${COLORS.WHITE} 56%, rgba(240, 244, 247, 0));
`;

export const JobSummaryCardFooter = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  background-image: linear-gradient(to left, ${COLORS.WHITE} 56%, rgba(240, 244, 247, 0));

  & > button {
    background-image: linear-gradient(to left, ${COLORS.WHITE} 56%, rgba(240, 244, 247, 0));
  }
`;
