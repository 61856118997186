import { ROLES } from '~/constants';
import {
  pageOverview,
  pageDashboard,
  pageCreateSingle,
  pageUpdateSingle,
  pageSupervisionUser,
  pageOutside,
  pageReport,
} from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'conversations';
const defaultConversationId = ':conversationId';

// overview
const overviewTypes = {
  perRound: 'round',
  perPerson: '',
};
export const CONVERSATIONS = new LinkConstructor(
  [ROLES.USER, ROLES.COACH, ROLES.ADMIN],
  () => `${pageOverview(routeName)}`,
  { type: overviewTypes.perPerson },
);
CONVERSATIONS.types = overviewTypes;

// dashboard
export const CONVERSATION = new LinkConstructor(
  [ROLES.USER, ROLES.COACH, ROLES.ADMIN],
  ({ conversationId = defaultConversationId }) => pageDashboard(routeName, { id: conversationId }),
);

// create
export const CONVERSATION_CREATE = new LinkConstructor([ROLES.USER, ROLES.COACH], () =>
  pageCreateSingle(routeName),
);

// update

export const CONVERSATION_UPDATE = new LinkConstructor(
  [ROLES.USER, ROLES.COACH, ROLES.ADMIN],
  ({ conversationId = defaultConversationId }) =>
    pageUpdateSingle(routeName, { id: conversationId }),
);

// supervision
export const CONVERSATION_SUPERVISION_USER = new LinkConstructor(
  [ROLES.COACH, ROLES.ADMIN],
  ({ conversationId = ':conversationId' }) =>
    pageSupervisionUser(routeName, { id: conversationId }),
);

// report
export const CONVERSATION_REPORT = new LinkConstructor([ROLES.ADMIN], () => pageReport(routeName));

// create pdf
export const CONVERSATION_GENERATE_PDF = new LinkConstructor(
  [],
  ({ conversationId = ':conversationId', tokenId = ':token' }) =>
    `${pageOutside(routeName)}/pdf/${conversationId}/${tokenId}`,
);
