import React, { Fragment, useState } from 'react';

import { ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS, Icon } from '~/components/Icon';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { SubMenu } from './components/SubMenu';
import { SubMenuItem } from './components/SubMenuItem';
import {
  SecondaryMenuWrapper,
  Divider,
  RenameModal,
  ModalTitle,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalInput,
} from './components/SubMenuItem.design';
import { getReportAttributes } from './utils';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import * as customReportActions from '~/store/customReports/actions';

import {
  SecondaryMenuTitle,
  SubMenuGroupContainer,
  SubMenuGroupTitle,
  SubMenuGroup,
  MenuGroup,
  AllCollapsible,
  CollapseIcon,
} from '../styles/design';

import type { IMenuItem } from '../types';

interface ISecondaryMenuProps {
  items: IMenuItem[];
  title: string;
  pathname: string;
  isAdmin: boolean;
  isMenuWithIcons?: boolean;
}

const ReportMenu = ({ items, title, pathname, isAdmin }: ISecondaryMenuProps) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const [openedMenuItems, setOpenedMenuItems] = useState(new Set<string>());
  const [name, setName] = useState('');
  const [id, setId] = useState<string | null>(null);
  const $showRenameModal = useBoolState(false);
  const { addToast } = useToasts();

  const deleteCustomReport = async (id: string, isActiveRoute: boolean) => {
    try {
      const { reportType, reportId } = getReportAttributes(id);
      if (reportType === null || reportId === null) {
        return;
      }
      dispatch(customReportActions.deleteCustomReport(reportId, reportType));
      if (isActiveRoute) {
        routes.REPORTS_ADMIN_DASHBOARD.go({ role: ROLES.ADMIN });
      }
      addToast({
        title: i18n._(t`Successfully deleted`),
        type: TOAST_TYPES.SUCCESS,
      });
    } catch {
      addToast({
        title: i18n._(t`Something went wrong`),
        subtitle: i18n._(t`Something went wrong while deleting. Please try again later.`),
        type: TOAST_TYPES.ERROR,
      });
    }
  };

  const onRenameItem = (menuItem: IMenuItem) => {
    $showRenameModal.set(true);
    setName(menuItem.title?.(i18n) || '');
    setId(menuItem.key);
  };

  const renameCustomReport = async () => {
    $showRenameModal.set(false);
    if (id) {
      const { reportType, reportId } = getReportAttributes(id);
      if (reportType === null || reportId === null) {
        return;
      }
      dispatch(customReportActions.renameCustomReport(reportId, reportType, name));
    }
  };

  const duplicateCustomReport = async (id: string) => {
    const { reportType, reportId } = getReportAttributes(id);
    if (reportType === null || reportId === null) {
      return;
    }
    dispatch(customReportActions.duplicateCustomReport(reportId, reportType));
  };

  const isDefaultReport = (key: string): boolean => {
    return !!key && key.split('|')[0] === 'default';
  };

  const onToggleMenuItem = (itemId: string) => {
    const newList = new Set(openedMenuItems);
    if (newList.has(itemId)) {
      newList.delete(itemId);
    } else {
      newList.add(itemId);
    }
    setOpenedMenuItems(newList);
  };

  const getCollapseIcon = () => {
    if (openedMenuItems.size > 0) {
      setOpenedMenuItems(new Set<string>());
    } else {
      // Menu Structure is messy, so have to map through it to get opened item keys
      const newIdArr = items
        .map((menuItem) => menuItem.children)
        .map((subMenuGroup) => {
          return (subMenuGroup as unknown as IMenuItem[]).map((subItem) => subItem.children);
        })
        .map((subMenuItem) => {
          return (subMenuItem as unknown as IMenuItem[]).map((subItem) => {
            return (subItem as unknown as IMenuItem[]).map(
              (subItemInner) => `${subItemInner.key}-${subItemInner.role}`,
            );
          });
        });
      const newList = new Set(newIdArr.flat(3));
      setOpenedMenuItems(newList);
    }
  };

  return (
    <>
      <SecondaryMenuWrapper>
        <SecondaryMenuTitle>
          {title}
          <AllCollapsible onClick={getCollapseIcon}>
            {openedMenuItems.size > 0 ? i18n._(t`Collapse all`) : i18n._(t`Open all`)}{' '}
            <CollapseIcon
              icon={ICONS.COLLAPSE_MENU}
              className={openedMenuItems.size > 0 ? 'active' : 'inactive'}
              size={ICON_SIZES.SMALL}
            />
          </AllCollapsible>
        </SecondaryMenuTitle>
        <MenuGroup>
          {items.map((menuItem, menuIndex) => {
            return (
              <Fragment key={`${menuIndex}-${menuItem.key}`}>
                {menuItem.title && (
                  <>
                    <SubMenuItem menuItem={menuItem} pathname={pathname} />
                    <Divider halfMargin />
                  </>
                )}
                {menuItem.children?.map((subMenuGroup, subMenuIndex) => {
                  const subMenuGroupItem = subMenuGroup as IMenuItem;
                  return (
                    <Fragment key={`submenu-${menuIndex}-${subMenuIndex}-${menuItem.key}`}>
                      <SubMenuGroup key={subMenuGroupItem.key}>
                        <SubMenuGroupTitle>{subMenuGroupItem.title?.(i18n)}</SubMenuGroupTitle>
                        <SubMenuGroupContainer>
                          {subMenuGroupItem.children?.map((subMenuItem, index) => (
                            <SubMenu
                              key={`SubMen-${index + 1}`}
                              subMenuItem={subMenuItem as IMenuItem}
                              pathname={pathname}
                              deleteCustomReport={deleteCustomReport}
                              duplicateCustomReport={duplicateCustomReport}
                              isDefaultReport={isDefaultReport}
                              isAdmin={isAdmin}
                              onToggleMenuItem={onToggleMenuItem}
                              openedMenuItems={openedMenuItems}
                              onRenameItem={onRenameItem}
                            />
                          ))}
                        </SubMenuGroupContainer>
                      </SubMenuGroup>
                      <Divider />
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })}
        </MenuGroup>
      </SecondaryMenuWrapper>
      {$showRenameModal.value && (
        <RenameModal>
          <ModalContent>
            <ModalTitle>
              {i18n._(t`Rename`)}
              <Icon
                icon={ICONS.CLOSE}
                onClick={() => $showRenameModal.set(false)}
                className="close"
              />
            </ModalTitle>
            <ModalBody>
              {i18n._(t`Name`)}
              <ModalInput onChange={(e) => setName(e.target.value)} value={name} />
            </ModalBody>
            <ModalFooter>
              <Button
                label={i18n._(t`Cancel`)}
                variant={ButtonVariant.SECONDARY}
                onClick={() => $showRenameModal.set(false)}
                size={ButtonSize.MEDIUM}
              />
              <Button
                label={i18n._(t`Save`)}
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.MEDIUM}
                onClick={renameCustomReport}
              />
            </ModalFooter>
          </ModalContent>
        </RenameModal>
      )}
    </>
  );
};

export default ReportMenu;
