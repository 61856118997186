import React from 'react';

import { NameRow } from '~/components/TableWithFixedColumns/Cells';

import { ColumnPosition, IColumnTable } from '~/@types/table';

import type { ISurvey } from '@learned/types';

const TEAMS_COLUMNS: IColumnTable<ISurvey & { tooltip?: string }>[] = [
  {
    name: 'Team',
    accessor: 'team',
    renderCell: ({ name, tooltip }) => {
      return (
        <NameRow className="cell" padding="19px 16px" tooltip={tooltip}>
          {name}
        </NameRow>
      );
    },
    isFixed: true,
    position: ColumnPosition.LEFT,
    padding: 'none',
  },
];

export { TEAMS_COLUMNS };
