import styled from 'styled-components';

import { SearchSelect } from '~/components/SearchSelect';
import { NoOptions } from '~/components/SearchSelect/design';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  margin: 0 auto;
`;

export const StyledSearchSelect = styled(SearchSelect)`
  ${NoOptions} {
    box-sizing: border-box;
    width: 446px;
    padding: 7px 14px;

    font-size: 14px;
    font-style: italic;
    line-height: 1.86;
    letter-spacing: -0.16px;
    color: ${COLORS.SUBTEXT};
  }
` as typeof SearchSelect;
