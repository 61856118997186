import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Container = styled.div`
  display: flex;
  position: relative;
`;

export const CareerPathWrapper = styled.div`
  display: flex;
  max-height: 307px;
  align-items: center;
  margin-bottom: 10px;
`;

export const PathTitleRectangle = styled.div<{
  width: number;
  textColor: string;
  backgroundColor: string;
}>`
  width: ${({ width }) => width}px;
  height: 20px;
  margin: 3px 0 28.5px;
  padding: 1px 7px 1.5px 5px;
  border-radius: 3px;
  border: solid 1px ${({ textColor }) => textColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  box-sizing: border-box;

  & > span {
    height: 17px;
    font-size: 12px;
    color: ${({ textColor }) => textColor};
  }
`;

export const IconWrapper = styled.div<{ textColor: string }>`
  align-content: center;

  & > svg {
    color: ${({ textColor }) => textColor};
    height: 12px;
    width: 12px;
  }
`;

export const PathTitleWrapper = styled.div<{ textColor: string }>`
  display: flex;
  gap: 10px;
  align-content: center;

  & > span {
    font-size: 14px;
    line-height: 1.86;
    color: ${({ textColor }) => textColor};
  }
`;

export const Oval = styled.div`
  width: 20px;
  height: 20px;
  margin: 0 32px 0 0;
  padding: 7px;
  border: solid 1px ${COLORS.BORDERS};
  background-color: var(--white);
`;

export const Line = styled.div<{ width: number }>`
  background-image: linear-gradient(to right, ${COLORS.PLACEHOLDERS} 20%, transparent 0%);
  background-repeat: repeat-x;
  background-position: top;
  background-size: 10px 2px;
  height: 2px;
  width: ${({ width }) => width}px;
`;

export const SummaryLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

export const SummaryLineText = styled.div`
  margin-top: 22px;
  width: 84px;
  height: 17px;
  font-size: 12px;
  color: ${COLORS.TEXT_MAIN};
  align-self: center;

  &:hover {
    cursor: pointer;
  }
`;

export const SummaryLine = styled.div`
  display: flex;
  width: 100%;
  align-content: center;
  align-items: center;
`;

export const SummaryCardContainer = styled.div<{ $showSummary?: boolean }>`
  margin-top: ${({ $showSummary }) => ($showSummary ? '46px' : '0')};
`;

export const ProfileSummaryContainer = styled.div<{ $showSummary?: boolean }>`
  display: flex;
  width: fit-content;
  margin-top: ${({ $showSummary }) => ($showSummary ? '30px' : '36px')};
`;

export const PathContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  -ms-overflow-style: none;

  &::webkit-scrollbar-button {
    display: none;
  }
`;

export const GradientOverlay = styled.div`
  position: absolute;
  height: 400px;
  width: 150px;
  background-image: linear-gradient(to right, transparent, ${COLORS.BG_PAGE} 30%);
  top: 0;
  right: -60px;
`;
