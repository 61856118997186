import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
export default (name, d) => {
  const Icon = ({ size, ...props }) => <InlineSvg height={size} width={size} d={d} {...props} />;

  Icon.displayName = name;

  Icon.propTypes = {
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
  };

  Icon.defaultProps = {
    size: 24,
    className: '',
  };

  return Icon;
};
