import React from 'react';

import styled from 'styled-components';

import { GoalAverageFeedback } from '~/components/NewReviewReport/components/GoalEvalBlock/GoalAverageFeedback';
import { GoalFeedback } from '~/components/NewReviewReport/components/GoalEvalBlock/GoalFeedback';

import { COLORS } from '~/styles';

const GoalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.TEXT_SECONDARY};
  margin-top: -7px;
  padding: 0 24px;

  p {
    color: ${COLORS.TEXT_SECONDARY};
  }
`;

function GoalEvalBlock({
  question,
  review,
  users,
  pdfView,
  viewerId,
  isLockedFeedback,
  handleOnRatingUpdated,
  $isGoalInfoModal,
  isReadOnly,
}) {
  return (
    <>
      {/* Eval questions do not have description instead we use name */}
      {question.name && <Description>{question.name}</Description>}
      <GoalsContainer>
        {question.isAverageQuestionEnabled && (
          <GoalAverageFeedback
            question={question}
            review={review}
            handleOnRatingUpdated={handleOnRatingUpdated}
            isLockedFeedback={isLockedFeedback}
            viewerId={viewerId}
            pdfView={pdfView}
            users={users}
            $isGoalInfoModal={$isGoalInfoModal}
            isReadOnly={isReadOnly}
            isOpenDefault
          />
        )}
        {question.goals.map((goal, index) => {
          return (
            <GoalFeedback
              key={goal.id}
              goal={goal}
              question={question}
              review={review}
              handleOnRatingUpdated={handleOnRatingUpdated}
              isLockedFeedback={isLockedFeedback}
              viewerId={viewerId}
              pdfView={pdfView}
              users={users}
              $isGoalInfoModal={$isGoalInfoModal}
              isReadOnly={isReadOnly}
              isOpenDefault={!question.isAverageQuestionEnabled && index === 0}
            />
          );
        })}
      </GoalsContainer>
    </>
  );
}

export { GoalEvalBlock };
