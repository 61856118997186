import styled from 'styled-components';

import RickTextView from '~/components/RickTextView';

import { COLORS } from '~/styles';

export const ItemContainer = styled.div`
  background-color: ${COLORS.BG_LIST};
  box-sizing: border-box;
  padding: 15px 16px 15px 18px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(183, 183, 183, 0);
  margin-bottom: 16px;
  min-height: 103px;
`;

export const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const BottomRow = styled.div``;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const DateTimeContainer = styled.div`
  height: 26px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.17;
  letter-spacing: -0.13px;
  text-align: right;
  color: ${COLORS.SUBTEXT};
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: normal;
  color: ${COLORS.TEXT_MAIN};

  align-items: center;

  label {
    margin-right: 5px;
    margin-left: 5px;
  }
`;

export const UpdateCommentContainer = styled.div``;

export const StyledRichTextView = styled(RickTextView)`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: normal;
  color: ${COLORS.SUBTEXT};
`;

export const HiglightedText = styled.span`
  font-weight: bold;
`;

export const UserName = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UpdateBlockContainer = styled.div`
  max-height: 630px;
  overflow-y: auto;
`;
