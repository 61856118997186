import { useCallback, useEffect, useState } from 'react';

import { JOB_PROFILE_STATUSES } from '@learned/constants';

import useDebounce from '~/hooks/useDebounce';
import { getJobProfiles } from '~/services/jobProfiles';

import type { IJobProfile } from '@learned/types';

const useAddRoleModal = () => {
  const [search, setSearch] = useState('');
  const [jobProfiles, setJobProfiles] = useState<IJobProfile[]>([]);
  const [selectedJobProfile, setSelectedJobProfile] = useState<IJobProfile>();
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSearch = useDebounce(search, 300);

  const listJobProfiles = useCallback(
    async () => getJobProfiles({ search, status: JOB_PROFILE_STATUSES.ACTIVE }, { limit: 10 }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearch],
  );

  useEffect(() => {
    let mounted = true;

    async function fetch() {
      setIsLoading(true);
      const response = await listJobProfiles();
      if (!mounted && response.code !== 200) {
        return;
      }
      setJobProfiles(Object.values(response));
      setIsLoading(false);
    }

    fetch();
    return () => void (mounted = false);
  }, [listJobProfiles]);

  return {
    jobProfiles,
    selectedJobProfile,
    setSelectedJobProfile,
    setSearch,
    isLoading,
  };
};

export { useAddRoleModal };
