import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';

import SetupCard from '~/components/SetupTools/SetupCard';
import { TextField } from '~/components/Text';

const TalkingPointCard = ({
  i18n,
  talkingPoint,
  handleDelete,
  isActive,
  updateName,
  onClick,
  dragProps,
  error,
  onType,
}) => (
  <SetupCard
    title={i18n._(t`Talking point`)}
    isActive={isActive}
    error={error}
    onClick={onClick}
    dragProps={dragProps}
    handleDelete={() => handleDelete(talkingPoint)}
  >
    <TextField
      value={talkingPoint.name || ''}
      maxLength={500}
      onChange={(e) => updateName(talkingPoint, e.target.value)}
      onFocus={() => onType(talkingPoint)}
      placeholder={i18n._(t`Write your talking point here…`)}
      $accent
      error={error}
    />
  </SetupCard>
);

export default React.memo(withI18n()(TalkingPointCard));
