import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import { LearningReportPage } from '~/pages/AdminReports/LearningReportPage';
import { OnboardAndLearn } from '~/pages/OnboardAndLearn';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('learnings', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.ONBOARD_AND_LEARN.routePath(ROLES.USER)}
        exact
        path={routes.ONBOARD_AND_LEARN.routePath(ROLES.USER)}
        component={OnboardAndLearn}
        allowedRoles={[ROLES.USER]}
      />,
    ],
    admin: [
      <PrivateRoute
        key={routes.LEARNINGS_REPORT.routePath(ROLES.ADMIN)}
        exact
        path={routes.LEARNINGS_REPORT.routePath(ROLES.ADMIN)}
        component={LearningReportPage}
        allowedRoles={[ROLES.ADMIN]}
      />,
    ],
  },
  routesPublic: [],
});
