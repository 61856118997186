import React, { memo, MouseEventHandler } from 'react';

import { USER_REVIEW_SORT_OPTIONS, USER_REVIEW_STATUS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';

import Label from '~/components/Label';
import ToolTip from '~/components/Tooltip';
import { UserAvatar } from '~/components/UserAvatar';

import type { IColumnTable } from '~/@types/table';
import { STATUS_PROVIDER, STATUS } from '~/constants/statusProvider';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import convertToTimeString from '~/utils/convertToTimeString';

import { DateRow, EmployeeRow, FlexRow, TextRow } from '../../design';

import type { IUser, IUserReviewPopulated } from '@learned/types';
import type { I18n } from '@lingui/core';

const EmployeeColumn = ({ item }: { item: IUserReviewPopulated }) => {
  return item.createdFor ? (
    <EmployeeRow>
      <FlexRow>
        <UserAvatar userId={(item.createdFor as IUser).id} options={{ showTooltip: true }} />
      </FlexRow>
    </EmployeeRow>
  ) : null;
};

const StatusLabel = ({ status }: { status: USER_REVIEW_STATUS }) => {
  const { i18n } = useLingui();

  const statusProps =
    STATUS_PROVIDER[status === USER_REVIEW_STATUS.PUBLISHED ? STATUS.UPCOMING : status];
  return <Label {...statusProps}>{statusProps.text(i18n)}</Label>;
};

const NameColumn = memo(
  ({
    item,
    onClick,
  }: {
    item: IUserReviewPopulated;
    onClick?: MouseEventHandler<HTMLDivElement>;
  }) => {
    const getMultiLangString = useMultiLangString();
    const name = !isEmpty(item.name) ? `${getMultiLangString(item.name)}` : '';

    return (
      <TextRow isDraft={item.status === USER_REVIEW_STATUS.DRAFT}>
        {/* @ts-ignore */}
        <FlexRow onClick={() => onClick(item)}>
          <ToolTip tooltip={name}>
            <span>{name}</span>
          </ToolTip>
        </FlexRow>
      </TextRow>
    );
  },
);

const COLUMNS: IColumnTable<IUserReviewPopulated>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Employees`),
    accessor: 'with',
    padding: '0',
    minWidth: '162px',
    maxWidth: '220px',
    renderCell: (item: IUserReviewPopulated) => <EmployeeColumn item={item} />,
    sortBy: {
      asc: {
        key: USER_REVIEW_SORT_OPTIONS.EMPLOYEE_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: USER_REVIEW_SORT_OPTIONS.EMPLOYEE_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'name',
    minWidth: '400px',
    maxWidth: '500px',
    renderCell: (item, onClick: MouseEventHandler<HTMLDivElement> | undefined) => {
      return <NameColumn item={item} onClick={onClick} />;
    },
    sortBy: {
      asc: {
        key: USER_REVIEW_SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: USER_REVIEW_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Start date`),
    accessor: 'date',
    maxWidth: '95px',
    sortBy: {
      asc: {
        key: USER_REVIEW_SORT_OPTIONS.START_DATE_OLD_NEW,
        label: (i18n: I18n) => i18n._(t`Soon - later`),
      },
      desc: {
        key: USER_REVIEW_SORT_OPTIONS.START_DATE_NEW_OLD,
        label: (i18n: I18n) => i18n._(t`Later - soon`),
      },
    },
    renderCell: (item: IUserReviewPopulated) => {
      return (
        <>
          {item.settings?.startDate ? (
            <DateRow>{`${convertToTimeString(item.settings.startDate)}`}</DateRow>
          ) : (
            <DateRow unplanned>
              <Trans>Not planned</Trans>
            </DateRow>
          )}
        </>
      );
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Status`),
    accessor: 'status',
    maxWidth: '95px',
    renderCell: (item) => {
      return item.status ? <StatusLabel status={item.status} /> : null;
    },
    sortBy: {
      asc: {
        key: USER_REVIEW_SORT_OPTIONS.STATUS_ASC,
        label: (i18n: I18n) => i18n._(t`Ascending`),
      },
      desc: {
        key: USER_REVIEW_SORT_OPTIONS.STATUS_DESC,
        label: (i18n: I18n) => i18n._(t`Descending`),
      },
    },
  },
];

export { COLUMNS };
