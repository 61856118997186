import React, { useRef, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Papa from 'papaparse';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import Modal from '~/components/Modal';
import { IMPORT_MEMBERS_TEMPLATE_CSV_TEXT } from '~/components/Modals/ImportMembers/csvTemplate';
import { IMPORT_MEMBERS_HEADER_ROWS, UserCsv } from '~/components/Modals/ImportMembers/types';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import {
  ActionContainer,
  Container,
  FileBox,
  FileName,
  Footer,
  Header,
  ItemActionBox,
  ItemBox,
  ItemDescription,
  ItemTextBox,
  ItemTitle,
  SubTitle,
  Title,
  TitleContainer,
} from './design';

import { createCsvBlob, downloadCsvFile } from '~/utils/csvTools';

type ImportMembersModalProps = {
  closeModal?: () => void;
  onCancel?: () => void;
  onNext?: (users: UserCsv[]) => void;
};

const ImportMembersModal = ({ onCancel, onNext, closeModal }: ImportMembersModalProps) => {
  const { i18n } = useLingui();

  const { addToast } = useToasts();
  const input = useRef<HTMLInputElement | null>(null);
  const [file, setCsv] = useState<File>();
  const [loading, setLoading] = useState(false);

  const onFileUploadHandler = () => {
    if (input && input.current && input.current.files) {
      const file = input.current.files[0] || null;
      setCsv(file);
    }
  };

  const onUploadCsv = () => {
    input.current?.click();
  };

  const processData = (data: Papa.ParseResult<UserCsv>) => {
    // Validate if format is correct
    if (
      data.meta.fields?.every((item) =>
        Object.values(IMPORT_MEMBERS_HEADER_ROWS).includes(item as IMPORT_MEMBERS_HEADER_ROWS),
      )
    ) {
      onNext?.(data.data);
    } else {
      addToast({
        title: i18n._(t`Error parsing CSV`),
        type: TOAST_TYPES.ERROR,
      });
    }

    setLoading(false);
  };

  const parseCSV = () => {
    setLoading(true);
    Papa.parse<UserCsv>(file!, {
      complete: processData,
      header: true,
      skipEmptyLines: true,
      error: (error) => {
        addToast({
          title: i18n._(t`Error parsing CSV`),
          subtitle: error.message,
          type: TOAST_TYPES.ERROR,
        });
        setLoading(false);
      },
      transform: (value: string, field: IMPORT_MEMBERS_HEADER_ROWS) => {
        try {
          switch (field) {
            case IMPORT_MEMBERS_HEADER_ROWS.jobs:
            case IMPORT_MEMBERS_HEADER_ROWS.inTeams:
            case IMPORT_MEMBERS_HEADER_ROWS.coachOfTeams:
              // Separates the value by , or ; in order to transform in an array
              return value ? value.split(/[,;]/).map((item) => item.trim().toLowerCase()) : [];
            default:
              return value ? value.trim().toLowerCase() : '';
          }
        } catch (e) {
          return value;
        }
      },
    });
  };

  return (
    <Modal
      showDivider={false}
      centerModal
      contentStyles={{ padding: '24px 32px', overflow: 'visible' }}
      borderRadius={6}
      hideFooter
      isHideHeader
    >
      <Header>
        <TitleContainer>
          <Title>{i18n._(t`Import members (CSV)`)}</Title>
          <SubTitle>
            {i18n._(t`To easily import a large number of members, use the CSV import`)}
          </SubTitle>
        </TitleContainer>
        <ActionContainer>
          <Button size={ButtonSize.MEDIUM} onClick={closeModal} variant={ButtonVariant.CLOSE} />
        </ActionContainer>
      </Header>

      <Container>
        <ItemBox>
          <ItemTextBox>
            <ItemTitle>
              <Trans>Step 1. Prepare the csv</Trans>
            </ItemTitle>
            <ItemDescription>
              <Trans>
                Download the csv template and fill the template with your member information
              </Trans>
            </ItemDescription>
          </ItemTextBox>
          <ItemActionBox>
            <Button
              icon={ICONS.IMPORT}
              label={i18n._(t`Export csv`)}
              type="button"
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              onClick={() =>
                downloadCsvFile(
                  createCsvBlob(IMPORT_MEMBERS_TEMPLATE_CSV_TEXT),
                  i18n._(t`Import Members Template`),
                )
              }
            />
          </ItemActionBox>
        </ItemBox>

        <ItemBox margin={'45px 0 0 0'}>
          <ItemTextBox>
            <ItemTitle>
              <Trans>Step 2. Upload the csv</Trans>
            </ItemTitle>
          </ItemTextBox>
          <ItemActionBox>
            <Button
              icon={ICONS.EXPORT}
              label={i18n._(t`Upload csv`)}
              type="button"
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              onClick={onUploadCsv}
              disabled={!!file}
            />
            <input
              id="uploadCsv"
              ref={input}
              onChange={onFileUploadHandler}
              type="file"
              accept=".csv"
              style={{ display: 'none' }}
            />
          </ItemActionBox>
        </ItemBox>

        {file && (
          <FileBox>
            <FileName>{file.name}</FileName>
            <Button
              size={ButtonSize.MEDIUM}
              onClick={() => {
                if (input.current) {
                  input.current.value = '';
                }
                setCsv(undefined);
              }}
              variant={ButtonVariant.ICON_DELETE}
            />
          </FileBox>
        )}
      </Container>

      <Footer>
        <Button
          label={i18n._(t`Cancel`)}
          type="button"
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          onClick={onCancel}
        />
        <Button
          label={i18n._(t`Next`)}
          onClick={parseCSV}
          type="button"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          disabled={!file}
          isLoading={loading}
        />
      </Footer>
    </Modal>
  );
};

export { ImportMembersModal };
