import React from 'react';

import get from 'lodash/get';
import styled from 'styled-components';

import ShowMore from '~/components/ShowMore';
import AvatarCard from '~/components/UI/AvatarCard';

import BlockWithIcon from './BlockWithIcon';
import {
  FeedbackBlock,
  FeedbackBlockWrapper,
  SubFBWrap,
  UserFeedback,
  Date,
} from './styledComponets';

import { REQUEST_STATUSES } from '~/constants';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';
import sanitizeHtml from '~/utils/sanitize';

const StyledAvatarCard = styled(AvatarCard)`
  margin-top: 8px;
  flex-direction: row-reverse;
  gap: 8px;
`;

const SubRTFeedback = ({ userRTFeedback, isOutside }) => {
  const requestStatus =
    get(userRTFeedback, 'request.status') || get(userRTFeedback, 'requestDeletedStatus');
  const createdDate = get(
    userRTFeedback,
    'meta.lastModifiedDate',
    get(userRTFeedback, 'meta.createdDate'),
  );

  return (
    <SubFBWrap key={userRTFeedback.id}>
      <FeedbackBlockWrapper $isWithoutBG>
        <FeedbackBlock>
          {requestStatus === REQUEST_STATUSES.ACCEPTED.key && (
            <>
              <ShowMore maxHeight={78}>
                <UserFeedback
                  dangerouslySetInnerHTML={{ __html: sanitizeHtml(userRTFeedback.feedback) }}
                />
              </ShowMore>
              <Date>{convertToTimeString(createdDate, TIME_FORMATS.CLASSIC)}</Date>
            </>
          )}
          {(requestStatus === REQUEST_STATUSES.REJECTED.key ||
            requestStatus === REQUEST_STATUSES.REQUESTED.key) && (
            <BlockWithIcon isRejected={requestStatus === REQUEST_STATUSES.REJECTED.key} />
          )}
        </FeedbackBlock>
        {!isOutside && (
          <StyledAvatarCard
            type="small"
            size={28}
            userId={userRTFeedback.createdFor || userRTFeedback.createdForEmail}
            email={userRTFeedback.createdForEmail}
          />
        )}
      </FeedbackBlockWrapper>
    </SubFBWrap>
  );
};

export default React.memo(SubRTFeedback);
