import React, { useState } from 'react';

import { GOAL_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import Button from '~/components/Button';
import Modal from '~/components/Modal';
import { SelectGoal } from '~/components/SelectGoal';
import { useToasts, TOAST_TYPES } from '~/components/Toast';

import { createUserActivityFromLibrary } from '~/services/userActivities';

import type { IActivity, IGoal } from '@learned/types';

const FooterActions = styled.div`
  display: flex;
  flex-direction: row;
`;

interface ISelectLPModalProps {
  activityId: IActivity['id'];
  onSave?: (value: string) => void;
  setIsShowSelectLPModal: (value: boolean) => void;
  setIsShowAddedToPlanModal: (value: boolean) => void;
}

const SelectLPModal = ({
  activityId,
  onSave,
  setIsShowSelectLPModal,
  setIsShowAddedToPlanModal,
}: ISelectLPModalProps) => {
  const { i18n } = useLingui();
  const { addToast } = useToasts();
  const [selectedGoals, setSelectedGoals] = useState<IGoal['id'][]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    setSelectedGoals([]);
    setIsLoading(false);
    setIsShowSelectLPModal(false);
  };

  const onLPUpdated = (goalIds: IGoal['id'][]) => {
    setSelectedGoals(goalIds);
  };

  const addActivity = async () => {
    setIsLoading(true);
    const {
      data: { userActivity },
      // @ts-ignore
    } = await createUserActivityFromLibrary(activityId, 'goal', {
      targets: selectedGoals,
    });

    if (!isEmpty(userActivity?.goals) && isEmpty(difference(selectedGoals, userActivity.goals))) {
      setIsLoading(false);
      setIsShowSelectLPModal(false);
      setIsShowAddedToPlanModal(false);
      addToast({
        title: i18n._(t`Activity successfully connected to learning goal.`),
        type: TOAST_TYPES.INFO,
      });
      onSave?.(activityId);
    }
  };

  return (
    <Modal
      title={i18n._(t`Select goal`)}
      onClose={closeModal}
      showDivider={false}
      centerModal
      minWidth={750}
      contentStyles={{ padding: '41px 41px 0', overflow: 'visible' }}
      headerStyles={{ padding: '25px 41px 0' }}
      footerStyles={{ padding: '40px 41px 65px' }}
      footerRight={
        <FooterActions>
          <Button
            styles={{ border: 'none', marginRight: '31px' }}
            label={i18n._(t`Close`)}
            type="white"
            onClick={() => setIsShowSelectLPModal(false)}
          />
          <Button
            styles={{ width: '170px' }}
            label={i18n._(t`Add to goal`)}
            loading={isLoading}
            disabled={isEmpty(selectedGoals)}
            onClick={addActivity}
          />
        </FooterActions>
      }
    >
      <SelectGoal
        type={GOAL_TYPES.PERSONAL}
        selectedGoals={selectedGoals}
        updateSelectedGoals={onLPUpdated}
        activityId={activityId}
      />
    </Modal>
  );
};

export { SelectLPModal };
