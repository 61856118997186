import { IPath, IActivity } from '@learned/types';
import { createAction } from 'redux-act';

export const setCurrentPath = createAction<IPath | {}>('set current Path');
export const updateCurrentPath = createAction<Partial<IPath>>('update current Path');

export const createCurrentPathSection = createAction('create currentPath Section');
export const updateCurrentPathSection = createAction('update currentPath Section');
export const deleteCurrentPathSection = createAction('delete currentPath Section');

export const addActivitiesToCurrentPathSection = createAction(
  'add activities to currentPath Section',
);

export const updateActivityInPath = createAction<IActivity[]>('update activity in path');

export const deleteActivityFromCurrentPathSection = createAction(
  'add activity from currentPath Section',
);
