import React, { useEffect, useState } from 'react';

import { REVIEW_TEMPLATE_STATUSES, ROLES, CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import { PopulatedReviewTemplate } from '~/pages/ReviewTemplateView/types';
import { ThemeTemplateDashboard } from '~/pages/ReviewThemeView/components/ThemeTemplateDashboard';
import { DASHBOARD_TYPE } from '~/pages/SurveyThemeView/constants';

import routes from '~/constants/routes';
import { checkModuleReviews } from '~/selectors/baseGetters';
import {
  getTemplate,
  updateReviewTemplateNew,
  setReviewTemplateStatusToDraft,
} from '~/services/reviewTemplates';

const ReviewTemplateView = () => {
  const { addToast } = useToasts();
  const { i18n } = useLingui();
  const params: Record<string, string | undefined> = useParams();
  const history = useHistory();
  const { reviewTemplateId } = params;
  const [isLoading, setIsLoading] = useState(false);
  const [template, setTemplate] = useState<PopulatedReviewTemplate>();
  const [forceUpdateCounter, setForceUpdateCounter] = useState(0);
  const [isEditTemplateModalVisible, setIsEditTemplateModalVisible] = useState(false);
  const isModuleReviewEnabled = useSelector(checkModuleReviews);
  const onForceFetch = () => setForceUpdateCounter(forceUpdateCounter + 1);

  useEffect(() => {
    if (!isModuleReviewEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleReviewEnabled, history]);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      const {
        data: { reviewTemplate },
      } = await getTemplate(reviewTemplateId, { populate: ['questions'] });
      setTemplate(reviewTemplate);
      setIsLoading(false);
    };
    fetch();
  }, [reviewTemplateId, forceUpdateCounter]);

  const isValid = (template: PopulatedReviewTemplate) => {
    if (isEmpty(template.questions)) {
      addToast({
        title: i18n._(t`Minimum 1 question is obligated!`),
        subtitle: i18n._(t`Please add minimum 1 question to the template.`),
        type: TOAST_TYPES.ERROR,
      });
      return false;
    }

    return true;
  };

  const onPublish = async () => {
    if (!isValid(template as unknown as PopulatedReviewTemplate)) {
      return;
    }

    const templateToPublish = {
      ...pick(template, ['name', 'description', 'icon', 'iconColor', 'themeWeights']),
      status: REVIEW_TEMPLATE_STATUSES.PUBLISHED,
      questions: template?.questions.map((q: any) => q.id),
    };

    setIsLoading(true);
    await updateReviewTemplateNew(reviewTemplateId, templateToPublish);
    addToast({ title: 'Review template published', type: TOAST_TYPES.SUCCESS });
    onForceFetch();
  };

  const onEdit = () => {
    if (template?.status === REVIEW_TEMPLATE_STATUSES.PUBLISHED) {
      setIsEditTemplateModalVisible(true);
    } else {
      navigateToEditFlow();
    }
  };

  const onConfirmationToChangeToDraft = async () => {
    setIsLoading(true);
    await setReviewTemplateStatusToDraft(reviewTemplateId);
    navigateToEditFlow();
  };

  const navigateToEditFlow = () => {
    history.push(
      routes.REVIEW_TEMPLATE_UPDATE.build(
        { companyId: undefined, teamId: undefined, role: ROLES.ADMIN },
        // @ts-ignore
        { reviewTemplateId, isBackPath: true },
      ),
    );
  };

  return (
    <>
      <ThemeTemplateDashboard
        isLoading={isLoading}
        onEdit={onEdit}
        item={template}
        dashboardType={DASHBOARD_TYPE.TEMPLATE}
        onPublish={onPublish}
      />
      {isEditTemplateModalVisible && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.WARNING}
          onClose={() => {
            setIsEditTemplateModalVisible(false);
          }}
          title={i18n._(t`Edit template?`)}
          description={i18n._(
            t`Are you sure want to edit template? Template will be moved to draft status. After editing you can publish it again`,
          )}
          onSubmit={onConfirmationToChangeToDraft}
        />
      )}
    </>
  );
};

export { ReviewTemplateView };
