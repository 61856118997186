import React from 'react';

import { REVIEW_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Banner } from '~/components/Banner';
import { BANNER_TYPES } from '~/components/Banner/types';
import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import type { IReviewDashboardCycleForm } from '~/pages/Reviews/DashboardCycle/ReviewDashboardForm/types';

import { SectionGeneral } from './sections/SectionGeneral';
import { SectionParticipants } from './sections/SectionParticipants';
import { SectionSettings } from './sections/SectionSettings';
import { SectionTimeline } from './sections/SectionTimeline';

import { COLORS } from '~/styles';

import type { UseFormReturn } from 'react-hook-form';

export const Wrapper = styled.div`
  max-width: 750px;
  margin: 0 auto 0;
`;

export const WrapperContent = styled.div`
  margin-top: 32px;
  background-color: ${COLORS.WHITE};
  border-radius: 10px;
  padding: 22px 22px 35px 32px;
`;

const BannerWrapper = styled.div`
  margin-top: 32px;
`;

interface ISetupTabProps {
  formMethods: UseFormReturn<IReviewDashboardCycleForm>;
  onArchive: () => void;
  onUnarchive: () => void;
  onShareCalibrationInput: () => void;
  onEdit: (section: string) => void;
  isAllowToArchive: boolean;
  isAllowToUnarchive: boolean;
  isAllowToEdit: boolean;
  isAllowToShareCalibrationInput: boolean;
}

const SetupTab = ({
  formMethods,
  onArchive,
  onUnarchive,
  onEdit,
  onShareCalibrationInput,
  isAllowToArchive,
  isAllowToEdit,
  isAllowToUnarchive,
  isAllowToShareCalibrationInput,
}: ISetupTabProps) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;

  const watchStatus = watch('status');

  const getShareInputDisabledTooltip = () => {
    switch (watchStatus) {
      case REVIEW_STATUS.PUBLISHED:
        return i18n._(t`Review cycle has not started yet`);
      case REVIEW_STATUS.ARCHIVED:
        return i18n._(t`Review cycle has already been archived`);
      default:
        return isAllowToShareCalibrationInput
          ? undefined
          : i18n._(t`Only admins or creator of the review cycle are allowed to share input`);
    }
  };

  return (
    <Wrapper>
      {watch('settings.isCalibrate') && (
        <BannerWrapper>
          <Banner
            type={BANNER_TYPES.INFO}
            title={i18n._(t`Calibration is enabled`)}
            subTitle={i18n._(t`Share calibrated coach input to make it visible for the employees.`)}
            actions={
              <Button
                size={ButtonSize.MEDIUM}
                variant={ButtonVariant.TEXT_PRIMARY}
                label={i18n._(t`Share input`)}
                onClick={onShareCalibrationInput}
                disabled={!isAllowToShareCalibrationInput}
                tooltip={getShareInputDisabledTooltip()}
              />
            }
          />
        </BannerWrapper>
      )}
      <WrapperContent>
        <SectionGeneral
          formMethods={formMethods}
          onArchive={onArchive}
          onUnarchive={onUnarchive}
          onEdit={isAllowToEdit ? () => onEdit('0') : undefined}
          isAllowToArchive={isAllowToArchive}
          isAllowToUnarchive={isAllowToUnarchive}
        />
        <SectionParticipants
          formMethods={formMethods}
          onEdit={isAllowToEdit ? () => onEdit('1') : undefined}
        />
        <SectionSettings
          formMethods={formMethods}
          onEdit={isAllowToEdit ? () => onEdit('2') : undefined}
        />
        <SectionTimeline
          formMethods={formMethods}
          onEdit={isAllowToEdit ? () => onEdit('3') : undefined}
        />
      </WrapperContent>
    </Wrapper>
  );
};

export { SetupTab };
