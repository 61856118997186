import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import GoalActivityItem from '~/components/GoalDashboardActivityItem';
import { ActivityModal } from '~/components/Modals/ActivityModal';

import useBoolState from '~/hooks/useBoolState';

const ItemWrapper = styled.div`
  margin-bottom: 11px;
`;

const RowItem = ({
  activity,
  onUpdate,
  activitiesReadonly,
  updateSidebar,
  isGoalTree,
  isOnClick,
}) => {
  const $updateProgressModal = useBoolState(false);

  const updateActivity = async (data) => {
    await onUpdate(data);

    // no all pages have updateSidebar method (for instance GoalTask)
    if (updateSidebar) {
      updateSidebar(true);
    }
    $updateProgressModal.off();
  };

  return (
    <ItemWrapper>
      <GoalActivityItem
        activity={activity}
        onClick={isOnClick ? $updateProgressModal.on : null}
        isCurrentProgress={true}
        isEditProgress={!activitiesReadonly}
        onEditProgress={$updateProgressModal.on}
        isStatusSection={true}
        isProgressBar={true}
        isGoalTree={isGoalTree}
      />
      {$updateProgressModal.value && (
        <ActivityModal
          isReadOnly={activitiesReadonly}
          activityId={activity.id}
          onSaveActivity={updateActivity}
          onClose={$updateProgressModal.off}
          isUpdateProgress
          isUserActivity
        />
      )}
    </ItemWrapper>
  );
};

RowItem.propTypes = {
  isUpdateActivityProgress: PropTypes.bool,
  isOnClick: PropTypes.bool, // define enable/disable logic on activity name click
  activitiesReadonly: PropTypes.bool,
};

RowItem.defaultProps = {
  activitiesReadonly: true,
};

export default React.memo(RowItem);
