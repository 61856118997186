import React from 'react';

import { ProductStatus } from '@learned/constants';
import styled from 'styled-components';

import BaseLayout from '~/layouts/BaseLayout';

import { AdditionalInformation } from '../tabs/AdditionalInformation';
import { Ambitions } from '../tabs/Ambitions';
import { GoalsTab } from '../tabs/GoalsTab';
import { Jobs } from '../tabs/Jobs';
import LearningTab from '../tabs/LearningTab';
import MeetingsTab from '../tabs/MeetingsTab';
import ReviewsTab from '../tabs/ReviewsTab';
import SettingsTab from '../tabs/SettingsTab';
import Summary from '../tabs/Summary';

import type { ProfileTab, ProfileTabsEnumType } from '../types';
import type { IProducts, IUser } from '@learned/types';

const SectionWrapper = styled.div`
  margin: 0px -32px 16px -32px;
`;

const profileTabBuilder = (
  PROFILE_TABS: ProfileTab[],
  PROFILE_TABS_ENUM: ProfileTabsEnumType,
  products: IProducts,
  user: IUser,
  currentUserId: string,
  _readOnly: boolean,
  _appTheme: { color: string; url: string | undefined },
  isModuleReviewsEnabled: boolean,
  isModuleMeetingsEnabled: boolean,
  isModuleGoalsEnabled: boolean,
  isFetchAgain: boolean,
  isModuleLearningEnabled: boolean,
  isLearningCreateActivityCoachModuleEnabled: boolean,
  isLearningCreatePathCoachModuleEnabled: boolean,
  openActivitiesModal: () => void,
  onOpenCreatePathFlow: () => void,
) => {
  const PASSPORT_TABS = PROFILE_TABS.map((tab) => {
    if (tab.key === PROFILE_TABS_ENUM.DEVELOPMENT) {
      if (
        products?.performance?.status === 'active' ||
        products?.performance?.status === 'trialActive'
      ) {
        return {
          ...tab,
          content: (
            <BaseLayout maxWidth={750}>
              {isModuleReviewsEnabled && (
                <SectionWrapper>
                  <ReviewsTab user={user} />
                </SectionWrapper>
              )}
              {isModuleMeetingsEnabled && (
                <SectionWrapper>
                  <MeetingsTab user={user} />
                </SectionWrapper>
              )}
              {isModuleGoalsEnabled && (
                <SectionWrapper>
                  <GoalsTab isPublicPage={true} forUser={user} currentUserId={currentUserId} />
                </SectionWrapper>
              )}
              {(products?.learningAndOnboarding?.status === ProductStatus.ACTIVE ||
                products?.learningAndOnboarding?.status === ProductStatus.TRIAL_ACTIVE) && (
                <SectionWrapper>
                  <LearningTab
                    user={user}
                    isFetchAgain={isFetchAgain}
                    showLearningActivityModal={
                      isModuleLearningEnabled && isLearningCreateActivityCoachModuleEnabled
                        ? openActivitiesModal
                        : undefined
                    }
                    createLearningPath={
                      isModuleLearningEnabled &&
                      isLearningCreateActivityCoachModuleEnabled &&
                      isLearningCreatePathCoachModuleEnabled
                        ? onOpenCreatePathFlow
                        : undefined
                    }
                  />
                </SectionWrapper>
              )}
            </BaseLayout>
          ),
        };
      }
    } else if (tab.key === PROFILE_TABS_ENUM.CAREER) {
      return {
        ...tab,
        content: (
          <BaseLayout maxWidth={750}>
            <SectionWrapper>
              <Summary user={user} />
            </SectionWrapper>
            <SectionWrapper>
              <Jobs user={user} />
            </SectionWrapper>
            {(products?.career?.status === 'active' ||
              products?.career?.status === 'trialActive') && (
              <SectionWrapper>
                <Ambitions user={user} />
              </SectionWrapper>
            )}
            <SectionWrapper>
              <AdditionalInformation user={user} />
            </SectionWrapper>
          </BaseLayout>
        ),
      };
    } else if (tab.key === PROFILE_TABS_ENUM.SETTINGS) {
      return {
        ...tab,
        content: (
          <BaseLayout maxWidth={750}>
            <SectionWrapper>
              {/* @ts-ignore */}
              <SettingsTab user={user} />
            </SectionWrapper>
          </BaseLayout>
        ),
      };
    }

    return { ...tab };
  });

  return PASSPORT_TABS;
};

export { profileTabBuilder };
