import React from 'react';

import styled from 'styled-components';

import { Icon, ICONS } from '~/components/Icon';

import { COLORS } from '~/styles';

const statuses = {
  todo: 'todo',
  active: 'active',
  warn: 'warn',
  error: 'error',
  done: 'done',
};

const statusColors = {
  [statuses.todo]: COLORS.DISABLED,
  [statuses.active]: COLORS.COMPANY,
  [statuses.done]: COLORS.COMPANY,
  [statuses.warn]: COLORS.WARNING,
  [statuses.error]: COLORS.ERROR,
};

const StyledIcon = styled(Icon)`
  margin: 2px;
  width: 20px;
  height: 20px;
`;

const StepStatus = ({ status, onClick }) => {
  return (
    <StyledIcon
      onClick={onClick}
      icon={ICONS.VALID}
      fill={statusColors[status]}
      stroke={status === statuses.done ? COLORS.WHITE : statusColors[status]}
    />
  );
};

const wrapped = React.memo(StepStatus);

wrapped.statuses = statuses;

export default wrapped;
