import React, { useEffect, useState } from 'react';

import { CONNECTION_STATUSES } from '@learned/constants';
import { IConnection } from '@learned/types';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { useSelector } from 'react-redux';

import useBoolState from '~/hooks/useBoolState';
import { getCompanyConnections, getUser } from '~/selectors/baseGetters';
import { getUserTeams } from '~/services/teams';

import { SIDEBAR_ITEM_TYPES } from '../../constants';
import { SidebarRow } from '../SidebarRow';

const CoachesCard = () => {
  const user = useSelector(getUser);
  const companyConnections = useSelector(getCompanyConnections);
  const [coaches, setCoaches] = useState<string[] | []>([]);
  const $loading = useBoolState(true);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      // @ts-ignore
      const teamsWithCoaches = await getUserTeams(user.id, { isMember: true });
      // @ts-ignore
      const coaches = uniqBy(
        // @ts-ignore
        Object.values(teamsWithCoaches).reduce((arr, team) => arr.concat(team.coaches), []),
      );

      // filter out disabled coaches
      const usersIdsWithActiveConnections = (Object.values(companyConnections) as IConnection[])
        .filter((c: IConnection) => c.status === CONNECTION_STATUSES.ACTIVE)
        .map((c: IConnection) => c.user);

      const coachesWithConnection = filter(coaches, (coachId: string) =>
        usersIdsWithActiveConnections.includes(coachId),
      );

      if (isMounted) {
        setCoaches(coachesWithConnection as string[] | []);
        $loading.off();
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  return isEmpty(coaches) && !$loading.value ? null : (
    <>
      <SidebarRow
        type={SIDEBAR_ITEM_TYPES.COACH}
        onClick={undefined}
        isLoading={$loading.value}
        coaches={coaches}
      />
    </>
  );
};

export default React.memo(CoachesCard);
