import React, { useEffect, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import values from 'lodash/values';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TextBlock } from '~/components/Activity/base_components';
import AutocompleteField from '~/components/AutocompleteField';
import Button from '~/components/Button';
import CheckBox from '~/components/CheckBox';
import DatePicker from '~/components/DatePickerDeprecated';
import Editor from '~/components/Editor';
import Modal from '~/components/Modal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { FieldGroup, FieldTitle, TextField } from '~/components/Text';

import useBoolState from '~/hooks/useBoolState';
import useDataStore from '~/hooks/useDataStore';
import useStringState from '~/hooks/useStringState';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getCareerPlans } from '~/services/careerPlans';
import { getWorkExp } from '~/services/workExps';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

import SkillsPicker from '../../../components/SkillsPicker';

const CurrentSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
  font-size: 14px;
`;

const OneRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ToColl = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;
`;

const WorkExpModal = ({ i18n, current, onClose, onCreate, onUpdate }) => {
  const company = useSelector(getCurrentCompany);
  const isNew = isEmpty(current);
  const [plan, setPlan] = useState(get(current, 'careerPlan'));
  const [from, setFrom] = useState(get(current, 'from'));
  const [to, setTo] = useState(get(current, 'to'));
  const $processing = useBoolState();
  const $current = useBoolState(get(current, 'isCurrentCompany', false));
  const $isPresent = useBoolState(!get(current, 'to'));
  const [skills, setSkills] = useState([]);
  const $employer = useStringState(get(current, 'employer'));
  const [description, setDescription] = useState(get(current, 'description'));
  const $jobName = useStringState(get(current, 'name'));

  const $careerPlans = useDataStore({ fetch: getCareerPlans, noRefetch: true });
  const $workExp = useDataStore({
    startState: {
      loading: !isNew,
    },
    get: getWorkExp,
    onGet: (data) => {
      setSkills(data.skills || []);
    },
  });

  useEffect(() => {
    if (!isNew) {
      $workExp.get(current.id, ['skills']);
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = async () => {
    const data = {
      employer: $employer.value,
      isCurrentCompany: $current.value,
      skills,
      description,
      name: $jobName.value,
      careerPlan: plan,
      from: convertToTimeString(from, TIME_FORMATS.ISO),
      to: convertToTimeString(to || '', TIME_FORMATS.ISO),
    };

    $processing.on();
    if (isNew) {
      await onCreate(data);
    } else {
      await onUpdate(current.id, data);
    }
    $processing.off();
    onClose();
  };

  const isValidated = () => {
    const required = !!$employer.value && !!$jobName.value && !!from && (!!to || $isPresent.value);
    if (isNew) {
      return required;
    }
    const old = pick(current, [
      'employer',
      'isCurrentCompany',
      'skills',
      'description',
      'name',
      'careerPlan',
      'from',
      'to',
    ]);
    const updatedExp = {
      employer: $employer.value,
      isCurrentCompany: $current.value,
      skills: skills.map((s) => s.id),
      description,
      name: $jobName.value,
      careerPlan: plan,
      from: convertToTimeString(from, TIME_FORMATS.ISO),
      to: convertToTimeString(to || '', TIME_FORMATS.ISO),
    };

    return required && !isEqual(old, updatedExp);
  };

  const handleChangeUseCompany = () => {
    if ($current.value) {
      // use
      $current.off();
      $employer.clear();
      setPlan(null);
    } else {
      $current.on();
      $employer.set(company.name);
    }
    $jobName.clear();
  };

  const handleChangeJob = (name, plan) => {
    $jobName.set(name);
    if (plan) {
      setPlan(plan.id);
    }
  };

  const renderFields = () => {
    if ($current.value) {
      $careerPlans.fetch();
    }

    return (
      <>
        <FieldGroup>
          <FieldTitle>
            <Trans>Job profile</Trans>*
          </FieldTitle>
          <AutocompleteField
            value={$jobName.value}
            onChange={handleChangeJob}
            placeholder={i18n._(t`Type to search`)}
            searchItems={values($careerPlans.items)}
          />
        </FieldGroup>
        <FieldGroup>
          <FieldTitle>
            <Trans>Tasks and responsibilities</Trans>
          </FieldTitle>
          <Editor
            placeholder={i18n._(
              t`Provide a description of the main tasks and responsibilities for this role`,
            )}
            value={description}
            onChange={setDescription}
            compact={true}
          />
        </FieldGroup>
        <OneRow>
          <FieldGroup>
            <FieldTitle>
              <Trans>From</Trans>*
            </FieldTitle>
            <DatePicker width={280} selected={from} onChange={setFrom} />
          </FieldGroup>
          <FieldGroup>
            <FieldTitle>
              <Trans>To</Trans>*
            </FieldTitle>
            <DatePicker width={280} selected={to} onChange={setTo} disabled={$isPresent.value} />
            <ToColl>
              <CheckBox
                size={24}
                checked={$isPresent.value}
                onChange={() => {
                  $isPresent.toggle();
                  setTo(null); // clear to date
                }}
              />
              <TextBlock>
                <Trans>Present</Trans>
              </TextBlock>
            </ToColl>
          </FieldGroup>
        </OneRow>
        <SkillsPicker skills={skills} onSkillsChange={setSkills} />
      </>
    );
  };

  return (
    <Modal
      title={<Trans>Add work experience</Trans>}
      onClose={onClose}
      width={625}
      footerRight={
        <Button
          label={isNew ? <Trans>Create</Trans> : <Trans>Update</Trans>}
          disabled={!isValidated()}
          type="primary-border"
          onClick={onSubmit}
          loading={$processing.value}
        />
      }
      footerLeft={<Button label={<Trans>Cancel</Trans>} type="link-primary" onClick={onClose} />}
    >
      <ShowSpinnerIfLoading loading={$workExp.loading}>
        <FieldGroup>
          <FieldTitle>
            <Trans>Employer</Trans>*
          </FieldTitle>
          <TextField
            value={$employer.value}
            onChange={$employer.setForInput}
            disabled={$current.value}
            placeholder={i18n._(t`Type company name`)}
          />
          <CurrentSection>
            <CheckBox checked={$current.value} onChange={handleChangeUseCompany} size={24} />
            <Trans>Current company</Trans>
          </CurrentSection>
        </FieldGroup>
        {$employer.value && renderFields()}
      </ShowSpinnerIfLoading>
    </Modal>
  );
};

export default withI18n()(WorkExpModal);
