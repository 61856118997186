import { HRIS_TOOLS_NAMES, INTEGRATIONS } from '@learned/constants';

const getIntegrationToolName = (integration: any): string => {
  return (
    HRIS_TOOLS_NAMES[
      integration.externalSoftware === INTEGRATIONS.KOMBO
        ? integration.komboData.tool
        : integration.externalSoftware
    ] || ''
  );
};

export default getIntegrationToolName;
