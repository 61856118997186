import React, { Dispatch } from 'react';

import { SURVEY_PARTICIPANTS_TYPE } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import uniq from 'lodash/uniq';
import styled, { css } from 'styled-components';

import { StepFooter } from '~/pages/SurveyUpdate/components/StepFooter';
import { SectionCompany } from '~/pages/SurveyUpdate/components/StepParticipants/SectionCompany';
import { SectionEmployees } from '~/pages/SurveyUpdate/components/StepParticipants/SectionEmployees';
import { SectionHint } from '~/pages/SurveyUpdate/components/StepParticipants/SectionHint';
import { SectionTeams } from '~/pages/SurveyUpdate/components/StepParticipants/SectionTeams';

import { useAutoSaveFunc } from '~/hooks/useAutoSave';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { COLORS } from '~/styles';

import type { IGeneralForm } from '../../types';
import type { UseFormReturn } from 'react-hook-form';

export interface IGeneralProps {
  formMethods: UseFormReturn<IGeneralForm>;
  setCurrentSection: Dispatch<number>;
  languageState: ILanguageStateReturn;
  autosave: useAutoSaveFunc;
}

const ParticipantsBlock = styled.div<{ $isError: boolean }>`
  ${(props) =>
    props.$isError &&
    css`
      border: 1px solid ${COLORS.ERROR};
      border-radius: 6px;
    `}
`;

const ErrorLabel = styled.div`
  margin-left: 8px;
  margin-top: 8px;
  color: ${COLORS.ERROR};
  font-size: 12px;
`;

const Form = styled.form`
  border-radius: 10px;
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 32px 40px;
`;

const Title = styled.h2`
  font-size: 26px;
  color: ${COLORS.BLACK};
  font-weight: normal;
  margin-top: 0;
`;

const StepParticipants = ({ setCurrentSection, formMethods, autosave }: IGeneralProps) => {
  const { i18n } = useLingui();
  const {
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = formMethods;
  const participantsType = watch('participants.type');
  const items = watch('participants.ids');

  const onSubmit = () => setCurrentSection(4);

  const isError = Boolean(errors?.participants?.ids);

  const onAddParticipants = async (addedItems: { id: string; name: string; url?: string }[]) => {
    setValue('participants.ids', uniq(items.concat(addedItems)));
    await trigger('participants.ids');
    autosave.run('participants.ids.add');
  };

  const onDeleteParticipants = async (deletedIds: string[]) => {
    const newItems = items.filter((item) => !deletedIds.includes(item.id));
    setValue('participants.ids', newItems);
    await trigger('participants.ids');
    autosave.run('participants.ids.delete');
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Title>
        <Trans>Participants</Trans>
      </Title>
      <ParticipantsBlock $isError={isError}>
        {participantsType === SURVEY_PARTICIPANTS_TYPE.EMPLOYEES && (
          <SectionEmployees
            formMethods={formMethods}
            onAdd={onAddParticipants}
            onDelete={onDeleteParticipants}
            autosave={autosave}
          />
        )}
        {participantsType === SURVEY_PARTICIPANTS_TYPE.TEAMS && (
          <SectionTeams
            formMethods={formMethods}
            onAdd={onAddParticipants}
            onDelete={onDeleteParticipants}
            autosave={autosave}
          />
        )}
        {participantsType === SURVEY_PARTICIPANTS_TYPE.COMPANY && (
          <SectionCompany formMethods={formMethods} autosave={autosave} />
        )}
      </ParticipantsBlock>
      {isError && (
        <ErrorLabel>
          {i18n._(t`Min 1 participant is required. Please invite min 1 participant`)}
        </ErrorLabel>
      )}
      {items?.length > 0 && <SectionHint formMethods={formMethods} autosave={autosave} />}
      <StepFooter onPrev={() => setCurrentSection(2)} />
    </Form>
  );
};

export { StepParticipants };
