import React from 'react';

import styled, { keyframes, css } from 'styled-components';

interface ISpinnerProps {
  isLoading?: boolean;
  content: any;
}

interface IAnimationProps {
  isLoading?: boolean;
}

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div<IAnimationProps>`
  cursor: pointer;
  ${({ isLoading }: any) =>
    isLoading &&
    css`
      animation: ${rotate360} 1s linear infinite;
      transform: translateZ(0);
      cursor: not-allowed;
      width: 18px;
      height: 18px;
    `}
`;

function RotatingSpinner({ isLoading, content }: ISpinnerProps) {
  return <Spinner isLoading={isLoading}>{content}</Spinner>;
}

export default RotatingSpinner;
