import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

const d =
  'M18.69 3.67h-3.672v-.667c0-1.106-.896-2.003-2.003-2.003h-2.67C9.238 1 8.34 1.897 8.34 3.003v.668H4.67C3.747 3.67 3 4.418 3 5.34v1.335c0 .37.299.668.668.668h16.024a.668.668 0 00.668-.668V5.34c0-.922-.747-1.67-1.67-1.67zm-9.013-.667c0-.368.3-.668.668-.668h2.67c.368 0 .668.3.668.668v.668H9.677v-.668zm-5.41 5.675c-.119 0-.214.1-.208.219l.55 11.561c.051 1.07.93 1.908 2.001 1.908h10.14c1.07 0 1.95-.838 2-1.908l.551-11.561a.2092.2092 0 00-.208-.219H4.267zm10.084 1.67c0-.37.298-.668.667-.668.37 0 .668.299.668.668v8.68c0 .368-.299.667-.668.667a.6668.6668 0 01-.667-.667v-8.68zm-3.339 0c0-.37.299-.668.668-.668.369 0 .668.299.668.668v8.68c0 .368-.3.667-.668.667-.369 0-.668-.299-.668-.667v-8.68zm-3.338 0c0-.37.299-.668.667-.668.37 0 .668.299.668.668v8.68a.6675.6675 0 01-1.335 0v-8.68z';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const DeleteIcon = ({ size, onClick, className, fill }) => (
  <InlineSvg height={size} width={size} d={d} onClick={onClick} className={className} fill={fill} />
);

DeleteIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

DeleteIcon.defaultProps = {
  size: 24,
  className: '',
};

export default DeleteIcon;
