import React, { useState } from 'react';

import { ProductName, ProductStatus } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import qs from 'qs';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { UpgradePlanModal } from '~/components/Modals/UpgradePlanModal';
import OverviewHeading from '~/components/OverviewHeading';
import { ProductBlock } from '~/components/ProductBlock';
import BoxWithBorder from '~/components/UI/BoxWithBorder';
import BaseLayout from '~/layouts/BaseLayout';

import { getCurrentProducts } from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { COLORS } from '~/styles';

const SettingBlock = styled(BoxWithBorder)`
  padding: 24px 40px;
`;

const SubHeading = styled.h4`
  margin-bottom: 24px;
  margin-top: 0px;
  color: ${COLORS.TEXT_HOVER};
  font-size: 18px;
`;

interface Product {
  name: ProductName;
  status: ProductStatus;
  settings: {};
  lastStatusChanged: string;
  upgradeRequest?: {
    message?: string | null;
    dateTime: string;
  };
}

interface ProductInfo {
  name: string;
  description: string;
  link: string;
}

export const dateDiff = (statusChangedOn: string) => {
  const current = moment().startOf('day');
  const trialEnding = moment(statusChangedOn).add(14, 'days').startOf('day');
  return trialEnding.diff(current, 'days');
};

const CompanySubscription = () => {
  const { i18n } = useLingui();
  const { product: productFromQuery, openTrialModal: openTrialModalFromQuery } = qs.parse(
    location.search,
    { ignoreQueryPrefix: true },
  ) as { product: ProductName | undefined; openTrialModal: undefined | boolean };
  const selectedCompany = useSelector(getCurrentCompany);
  const products: Record<ProductName, Product> = useSelector(getCurrentProducts);
  const [isUpgradePlanModalVisible, setIsUpgradePlanModalVisible] = useState(
    openTrialModalFromQuery || false,
  );
  const [selectedProduct, setSelectedProduct] = useState<ProductName[]>(
    productFromQuery && products[productFromQuery] ? [productFromQuery] : [],
  );

  const modifiedProducts: Record<ProductName, ProductInfo> = {
    [ProductName.JOB_MATRIX]: {
      name: i18n._(t`Job matrix`),
      description: i18n._(
        t`With Learned’s AI, you easily generate a modern job library based on market data.`,
      ),
      link: '',
    },
    [ProductName.PERFORMANCE]: {
      name: i18n._(t`Performance`),
      description: i18n._(
        t`Conduct objective evaluations, make sure everyone knows how to develop during the year, feel valued and identify your high performers.`,
      ),
      link: 'https://www.learned.io/performance-management/',
    },
    [ProductName.CAREER]: {
      name: i18n._(t`Career`),
      description: i18n._(
        t`Provide employees with a personalised career path and make career opportunities transparent.`,
      ),
      link: 'https://www.learned.io/carriere-framework/',
    },
    [ProductName.ENGAGEMENT]: {
      name: i18n._(t`Engagement`),
      description: i18n._(
        t`Uncover blindspots to increase employee engagement with science-based traditional and pulse surveys`,
      ),
      link: 'https://www.learned.io/engagement/',
    },
    [ProductName.LEARNING_AND_ONBOARDING]: {
      name: i18n._(t`Onboarding & Learn`),
      description: i18n._(
        t`Remove the barriers to start learning. Easily collect all learning resources in your academy and bundle activities to quickly onboard employees.`,
      ),
      link: 'https://www.learned.io/onboard-learning/',
    },
    [ProductName.INTEGRATIONS]: {
      name: i18n._(t`Integrations`),
      description: i18n._(
        t`With our integrations you can seamlessly import employee data from your HRIS.`,
      ),
      link: 'https://www.learned.io/nl/integraties/',
    },
  };

  const getProductInfo = (productName: ProductName) => {
    return modifiedProducts[productName];
  };

  const disableProductCheck = (key: ProductName, products: Record<ProductName, Product>) => {
    const product = products[key];
    switch (key) {
      case ProductName.JOB_MATRIX:
        // if Career product enabled, not allow to disable jobMatrix
        return (
          [ProductStatus.ACTIVE, ProductStatus.TRIAL_ACTIVE].includes(
            products[ProductName.CAREER]?.status,
          ) && product.status === ProductStatus.ACTIVE
        );
      default:
        return false;
    }
  };

  const productsOrder = Object.keys(modifiedProducts);
  const productsOrdered = sortBy(Object.keys(products), (key) =>
    productsOrder.indexOf(key),
  ) as unknown as ProductName[];

  const allProducts = productsOrdered
    .map((key) => {
      const product = products[key];
      const isHideLearningProduct =
        key === ProductName.LEARNING_AND_ONBOARDING &&
        !selectedCompany?.addons?.learningAndOnboarding;

      const productInfo = getProductInfo(key);
      return isHideLearningProduct
        ? null
        : {
            key,
            name: productInfo.name,
            status: product.status,
            lastStatusChanged: product.lastStatusChanged,
            daysToEnd: dateDiff(product.lastStatusChanged),
            description: productInfo.description,
            moreInfoURL: productInfo.link,
            settings: product.settings,
            upgradeRequest: product.upgradeRequest,
            isDisabled: disableProductCheck(key, products),
          };
    })
    .filter((p) => p);

  return (
    <>
      {/* @ts-ignore */}
      <OverviewHeading title={i18n._(t`Plan`)} />
      <BaseLayout>
        <SettingBlock>
          <SubHeading>
            <Trans>Manage products</Trans>
          </SubHeading>
          {allProducts.map((product, index) => (
            <ProductBlock
              key={index}
              // @ts-ignore
              product={product}
              onRequestUpgradeClick={() => {
                setIsUpgradePlanModalVisible(true);
                setSelectedProduct([product?.key as ProductName]);
              }}
            />
          ))}
          {isUpgradePlanModalVisible && (
            <UpgradePlanModal
              products={Object.values(allProducts)
                .filter(
                  // @ts-ignore
                  (product: Product) =>
                    ![ProductStatus.ACTIVE].includes(product?.status) &&
                    product.name !== ProductName.JOB_MATRIX,
                )
                // @ts-ignore
                .map(({ key, status }) => ({
                  name: key,
                  status,
                }))}
              preselectedProducts={selectedProduct}
              closeModal={() => setIsUpgradePlanModalVisible(false)}
            />
          )}
        </SettingBlock>
      </BaseLayout>
    </>
  );
};

export { CompanySubscription };
