import { REVIEW_INVITATION_TEMPLATES_SORT_OPTIONS } from '@learned/constants';
import { IReviewInvitationTemplate } from '@learned/types';
import { type ICreateServerRoute, IServerRoute, IUpdateServerRoute } from 'src/@types/serverRoute';

import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getReviewInvitationTemplate(body: {
  filters?: { search?: string };
  options?: {
    skip?: number;
    limit?: number;
    sortBy?: REVIEW_INVITATION_TEMPLATES_SORT_OPTIONS;
  };
}) {
  return cloudRequest(serverRoutes.reviewInvitationTemplates.items as IServerRoute, {}, body);
}

export function createReviewInvitationTemplate(
  body: Pick<IReviewInvitationTemplate, 'name' | 'description'>,
) {
  return cloudRequest(
    serverRoutes.reviewInvitationTemplates.createReviewInvitationTemplate as IServerRoute,
    {},
    body,
  );
}

export function editReviewInvitationTemplate(
  reviewInvitationTemplateId: IReviewInvitationTemplate['id'],
  body: Pick<IReviewInvitationTemplate, 'name' | 'description'>,
) {
  return cloudRequest(
    (serverRoutes.reviewInvitationTemplates.editReviewInvitationTemplate as ICreateServerRoute)(
      reviewInvitationTemplateId,
    ),
    {},
    body,
  );
}

export function deleteReviewInvitationTemplate(
  reviewInvitationTemplateId: IReviewInvitationTemplate['id'],
) {
  return cloudRequest(
    (serverRoutes.reviewInvitationTemplates.deleteReviewInvitationTemplate as ICreateServerRoute)(
      reviewInvitationTemplateId,
    ),
  );
}

export function updateReviewInvitationTemplate(
  reviewInvitationTemplateId: IReviewInvitationTemplate['id'],
  body: Pick<IReviewInvitationTemplate, 'name' | 'description'>,
) {
  return cloudRequest(
    (serverRoutes.reviewInvitationTemplates.updateReviewInvitationTemplate as IUpdateServerRoute)(
      reviewInvitationTemplateId,
    ),
    {},
    body,
  );
}
