import { i18n } from '@lingui/core';
import { createAction } from 'redux-act';

import { LANGUAGES } from '~/constants';

export const setLocale = createAction('set locale');

export function changeLang(locale = LANGUAGES.EN) {
  return (dispatch) => {
    dispatch(setLocale(locale));
    i18n.activate(locale);
  };
}
