import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Title from '~/components/base_components/Title';
import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';

import { COLORS } from '~/styles';

const ButtonWrap = styled.div``;

const EditableButtonWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
`;

const HeaderWrap = styled.div`
  display: felx;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledTitle = styled(Title)`
  color: ${COLORS.TEXT_MAIN};
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
`;

interface ISectionHeader {
  title: string;
  isEditable?: boolean;
  isEditMode?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  onEdit?: () => void;
}

const SectionHeader = ({
  title,
  isEditMode = false,
  isEditable = false,
  onCancel,
  onSave,
  onEdit,
}: ISectionHeader) => {
  const { i18n } = useLingui();
  return (
    <HeaderWrap>
      <StyledTitle>{title}</StyledTitle>
      {isEditable && (
        <ButtonWrap>
          {isEditMode ? (
            <EditableButtonWrap>
              <Button
                label={i18n._(t`Cancel`)}
                variant={ButtonVariant.TEXT_PRIMARY}
                size={ButtonSize.MEDIUM}
                onClick={onCancel}
              />
              <Button
                label={i18n._(t`Save`)}
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.MEDIUM}
                onClick={onSave}
              />
            </EditableButtonWrap>
          ) : (
            <Button
              label={i18n._(t`Edit`)}
              variant={ButtonVariant.TEXT_PRIMARY}
              size={ButtonSize.MEDIUM}
              icon={ICONS.EDIT_PENCIL}
              color={COLORS.COMPANY}
              onClick={onEdit}
            />
          )}
        </ButtonWrap>
      )}
    </HeaderWrap>
  );
};
export { SectionHeader };
