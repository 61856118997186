import React, { useEffect, useState } from 'react';

import { GOAL_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import GoalDashboardCardOld from '~/components/GoalDashboardCardOld';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import useBoolState from '~/hooks/useBoolState';
import { checkModuleRTFeedbacks, getDeletedUsers, getUsers } from '~/selectors/baseGetters';
import { getCurrentGoal } from '~/selectors/currentGoal';
import { getGoalForReview } from '~/services/goals';
import * as goalsServices from '~/services/goals';
import { getGoalsUpdates } from '~/services/goalsUpdates';
import { getGoalRTFeedbacks } from '~/services/RTFeedbacks';
import { setCurrentGoal } from '~/store/currentGoal/actions';
import getUserFullName from '~/utils/getUserFullName';

const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
`;

function GoalDashboardModalContent({
  goalId,
  reviewId,
  isNotSharedFeedback,
  className,
  viewerId,
  onGoalChanged,
}) {
  const $loading = useBoolState(true);
  const goal = useSelector(getCurrentGoal);
  const [updates, setUpdates] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const allUsers = useSelector(getUsers);
  const deletedUsers = useSelector(getDeletedUsers);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const users = { ...allUsers, ...deletedUsers };
  const isModuleRTFeedbacksEnabled = useSelector(checkModuleRTFeedbacks);
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let goal;
        if (reviewId) {
          goal = await getGoalForReview(goalId, reviewId, {
            populate: ['skills', 'activities', 'goalCycles', 'teams'],
            isFillParent: true,
            isNotSharedFeedback,
          });
        } else {
          goal = await goalsServices.getGoal(
            goalId,
            ['skills', 'activities', 'goalCycles', 'teams'],
            {
              isFillParent: true,
              childrenMaxOrder: 0,
              isGetProgress: true,
              isParentTeamPopulate: true,
            },
          );
        }

        if (
          isModuleRTFeedbacksEnabled &&
          (goal?.type === GOAL_TYPES.PERSONAL || goal?.type === GOAL_TYPES.BUSINESS)
        ) {
          const RTFB = await getGoalRTFeedbacks({
            goalId,
          });
          setFeedbacks(Object.values(RTFB));
        }
        const goalUpdates = await getGoalsUpdates({ goalId, populate: ['target'] });
        const preparedGoalsUpdates = Object.values(goalUpdates).map((u) => {
          const updateUser = users[u.createdBy];
          const userName = getUserFullName(updateUser);
          return {
            ...u,
            userName,
          };
        });
        dispatch(setCurrentGoal(goal));
        setUpdates(preparedGoalsUpdates);
        $loading.off();
      } catch (e) {
        // handle goal not found
        $loading.off();
      }
    };

    fetchData();

    // eslint-disable-next-line
  }, [refreshTrigger]);

  const handleGoalChanged = () => {
    setRefreshTrigger((v) => !v);
    if (onGoalChanged) {
      onGoalChanged();
    }
  };

  const isShowRTFB =
    (goal?.type === GOAL_TYPES.PERSONAL || goal?.type === GOAL_TYPES.BUSINESS) &&
    isModuleRTFeedbacksEnabled;

  const isOwner = goal?.owners?.includes(viewerId);

  return (
    <ShowSpinnerIfLoading loading={$loading.value}>
      {goal && goal.id ? (
        <GoalDashboardCardOld
          className={className}
          goal={goal}
          isGoalPreview
          updates={updates}
          isShowRTFB={isShowRTFB}
          feedbacks={feedbacks}
          canUpdateActivityProgress={isOwner}
          updateSidebar={handleGoalChanged}
        />
      ) : (
        <NotFound>{i18n._(t`The goal is not found`)}</NotFound>
      )}
    </ShowSpinnerIfLoading>
  );
}

export { GoalDashboardModalContent };
