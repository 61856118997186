import React from 'react';

import { Trans } from '@lingui/macro';

import MicrosoftTeamsIcon from '~/assets/microsoft-teams.svg';

function MicrosoftTeams() {
  return (
    <>
      <img src={MicrosoftTeamsIcon} alt="Microsoft Teams" />
      <Trans>Microsoft Teams</Trans>
    </>
  );
}

export { MicrosoftTeams };
