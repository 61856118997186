import styled from 'styled-components';

import Divider from '~/components/UI/Divider';

export const Block = styled.div``;

export const StyledDivider = styled(Divider)`
  margin-top: 24px;
`;

export const IconCol = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

export const SettingsList = styled.div``;

export const SettingRow = styled.div`
  display: flex;
  margin-bottom: 13px;
`;

export const TextRow = styled.div`
  display: flex;
  align-items: center;
  width: 103px;
  min-width: 103px;
`;
