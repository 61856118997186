import React from 'react';

import { TASK_STATUS } from '@learned/constants';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Icon, ICON_SIZES } from '~/components/Icon';
import Label from '~/components/Label';

import { TASK_STATUS_ITEMS } from '~/constants/tasks';

import type { ITask } from '@learned/types';

const StyledDiv = styled(Label)`
  display: inline-flex;
  align-items: center;
  height: 15px;
  font-size: 12px;
`;

const StyledIcon = styled(Icon)<{
  rotate?: number;
  color: string;
}>`
  box-sizing: border-box;
  margin-right: 5px;
  transform: rotate(${({ rotate }) => (rotate ? rotate : '')}deg);
`;

const TaskStatusLabel = ({ status }: { status: ITask['status'] }) => {
  const { i18n } = useLingui();
  const item = status && TASK_STATUS_ITEMS[status];

  return (
    <StyledDiv color={item.color} backgroundColor={item.backGroundColor}>
      {item?.icon && (
        <StyledIcon
          icon={item.icon}
          size={ICON_SIZES.SMALL}
          rotate={item.key === TASK_STATUS.TODO ? -90 : 0}
          color={item.color}
        />
      )}
      {item.text(i18n)}
    </StyledDiv>
  );
};

export { TaskStatusLabel };
