import React, { useEffect, useRef, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';

import {
  Label,
  RecommendedSkillsActions,
  RecommendedSkillsWrapper,
  ShowMoreButton,
} from '~/components/Modals/AddSkillModal/design';
import { WatchSkill } from '~/components/Modals/AddSkillModal/types';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import type { ISkill, ISkillTemplate } from '@learned/types';

type SkillsSelectorProps = {
  title: string;
  skills: (ISkill | ISkillTemplate)[];
  onSelectSkill?(skill: ISkill | ISkillTemplate): void;
  watchSkills: WatchSkill[];
};

const SkillsSelector = ({ title, skills, onSelectSkill, watchSkills }: SkillsSelectorProps) => {
  const getMultiLangString = useMultiLangString();
  const { i18n } = useLingui();

  const [showMoreButton, setShowMoreButton] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const [filteredSkills, setFilteredSkills] = useState<(ISkill | ISkillTemplate)[]>([]);

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      const isHidingElements = divRef.current.scrollHeight > divRef.current.clientHeight;
      setShowMoreButton(isHidingElements);
    }
  }, [filteredSkills]);

  useEffect(() => {
    const filteredList = skills.filter(
      (skillTemplate) =>
        !watchSkills.some(
          (item) => skillTemplate.id === item.skillTemplate || skillTemplate.id === item.skill,
        ),
    );
    setFilteredSkills(filteredList);
  }, [skills, watchSkills]);

  return !isEmpty(filteredSkills) ? (
    <>
      <Label>{title}</Label>
      <RecommendedSkillsWrapper ref={divRef} showAll={showAll}>
        {filteredSkills.map((skill) => (
          <button
            key={skill.id}
            className="item"
            onClick={() => {
              onSelectSkill?.(skill);
            }}
          >
            {getMultiLangString(skill.name)}
          </button>
        ))}
      </RecommendedSkillsWrapper>
      {showMoreButton && (
        <RecommendedSkillsActions>
          <ShowMoreButton
            onClick={() => {
              setShowAll(!showAll);
            }}
          >
            {showAll ? i18n._(t`Show less`) : i18n._(t`Show more`)}
          </ShowMoreButton>
        </RecommendedSkillsActions>
      )}
    </>
  ) : null;
};

export { SkillsSelector };
