import React from 'react';

import { Icon, ICONS, ICON_SIZES } from '~/components/Icon';

import {
  BarBackground,
  BarLoader,
  Container,
  Content,
  IconContainer,
  Subtitle,
  TextContainer,
  Title,
} from './design';
import { useToasts } from './useToasts';
import { withToasts } from './withToasts';

import { useOnHover } from '~/hooks/useOnHover';
import { COLORS } from '~/styles';

import type { ToastProps } from './types';

/* eslint-disable no-unused-vars */
enum TOAST_TYPES {
  SUCCESS = 'success',
  INFO = 'info',
  INFO_MESSAGE = 'info-message',
  ERROR = 'error',
}
/* eslint-enable no-unused-vars */

const toastIcons: Record<string, { icon: ICONS; color: string }> = {
  [TOAST_TYPES.INFO]: { icon: ICONS.INFO_3, color: COLORS.ACCENT_INFO },
  [TOAST_TYPES.INFO_MESSAGE]: { icon: ICONS.MESSAGE, color: COLORS.ACCENT_INFO },
  [TOAST_TYPES.ERROR]: { icon: ICONS.WARNING_2, color: COLORS.ACCENT_ERROR },
  [TOAST_TYPES.SUCCESS]: { icon: ICONS.CHECKMARK, color: COLORS.ACCENT_SUCCESS },
};

function Toast({
  title,
  subtitle,
  type,
  transitionState,
  dismissDuration = 5000,
  onDismiss,
}: ToastProps) {
  const { ref, isHovering } = useOnHover();
  const { icon, color } = toastIcons[type];
  const loaderWidth = transitionState === 'not-closing' ? '100%' : '0%';

  return (
    <Container $type={type} $animate={transitionState !== 'not-closing'}>
      <Content>
        <IconContainer
          ref={ref}
          $backgroundColor={isHovering ? COLORS.WHITE : color}
          onClick={(e) => {
            e.stopPropagation();
            onDismiss?.();
          }}
        >
          {!isHovering && <Icon icon={icon} size={ICON_SIZES.SMALL} color={COLORS.WHITE} />}
          {isHovering && (
            <Icon icon={ICONS.CLOSE_2} size={ICON_SIZES.SMALL} color={COLORS.TOOLTIP_BACKGROUND} />
          )}
        </IconContainer>
        <TextContainer>
          <Title>
            <span>{title}</span>
          </Title>
          {subtitle && (
            <Subtitle>
              <span>{subtitle}</span>
            </Subtitle>
          )}
        </TextContainer>
      </Content>
      <BarBackground />
      <BarLoader $color={color} $width={loaderWidth} $transitionTime={dismissDuration} />
    </Container>
  );
}

export { Toast, useToasts, withToasts, TOAST_TYPES };
