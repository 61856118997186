import moment from 'moment';

const TIME_FORMATS = {
  ISO: '',
  CLASSIC: 'DD-MM-YYYY',
  CLASSIC_SPACES: 'DD MMM YYYY',
  CLASSIC_FULL: 'DD-MM-YYYY HH:mm',
  CLASSIC_FULL_SLASH: 'DD/MM/YYYY HH:mm',
  CLASSIC_DOT: 'DD.MM.YYYY',
  MONTH_YEAR: 'MMM YYYY',
  DAY_MONTH_YEAR: 'DD MMM YYYY',
  DAY_FULL_MONTH_YEAR: 'DD MMMM YYYY',
  CLASSIC_NO_DATE: 'HH:mm',
  FULL_WITH_WEEK_DAY: 'dddd, DD MMMM YYYY, HH:mm',
};

export { TIME_FORMATS };

export default function (value, format = TIME_FORMATS.CLASSIC) {
  if (value) {
    if (format === TIME_FORMATS.ISO) {
      return moment(value).toISOString();
    }
    return moment(value).format(format);
  }

  return value;
}
