import React, { Component } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import AuthLayout from '~/layouts/AuthLayout';

import successImage from '~/assets/bitmap@3x.png';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import ActiveCampaignPixel from '~/statistic/ActiveCampaignPixel';
import LinkedIn from '~/statistic/LinkedIn';
import { COLOR_PALETTE } from '~/styles';

const Text = styled.p`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6b78a4;
  margin-bottom: 24px;
`;

const Container = styled.div`
  text-align: center;
  padding: 23px 26px 0;
`;

const TextContainer = styled.div``;

const TextHeader = styled.h1`
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1e0863;
  color: ${COLOR_PALETTE.BLUE_DARK};
`;

class CongratulationsPageCompany extends Component {
  onNextStep = () => {
    const { history } = this.props;

    // companyId - created company id
    // get params from url
    let href = window.location.href;
    let url = new URL(href);
    let companyId = url.searchParams.get('companyId');

    history.push(
      routes.ADMIN_DASHBOARD.build(
        { role: ROLES.ADMIN, companyId },
        {
          query: {
            isFirstLogin: true,
          },
        },
      ),
    );
  };

  render() {
    const { i18n } = this.props;

    return (
      <AuthLayout width="360px" marginTop="131px">
        <Container>
          <img src={successImage} width="88px" alt={i18n._(t`Great job!`)} />
          <TextContainer>
            <TextHeader>
              <Trans>Your workspace is created!</Trans>
            </TextHeader>

            <Text>
              <Trans>Great! You can now start exploring Learned.. </Trans>
            </Text>

            <Button
              styles={{
                margin: '0 auto',
                backgroundColor: COLOR_PALETTE.BLUE_DARK,
              }}
              label=" Go to login"
              width={110}
              onClick={this.onNextStep}
            />
          </TextContainer>
        </Container>
        {process.env.REACT_APP_PROJECT_ID === 'learned-production' && <LinkedIn />}
        {process.env.REACT_APP_PROJECT_ID === 'learned-production' && <ActiveCampaignPixel />}
      </AuthLayout>
    );
  }
}

export default withI18n()(withRouter(CongratulationsPageCompany));
