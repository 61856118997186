import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import logo from '~/assets/logo.svg';

import { HEADER, COLOR_PALETTE } from '~/styles';

const HeaderContent = styled.div`
  padding: 0 24px;
  display: flex;
  align-items: center;
  height: ${HEADER.height}px;
  justify-content: center;
  position: fixed;
  top: 0;
  background-color: ${COLOR_PALETTE.WHITE};
  width: 100%;
  z-index: 100 !important;
  box-sizing: border-box;
`;

const Logo = styled.img`
  width: 133px;
`;

class Header extends Component {
  render() {
    const { i18n } = this.props;
    return (
      <HeaderContent>
        <Logo onClick={this.linkToLogin} alt={i18n._(t`Logo`)} src={logo} />
      </HeaderContent>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    app: state.app,
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(Header)));
