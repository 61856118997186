import React from 'react';

import { cloneDeep, isEmpty } from 'lodash';
import styled from 'styled-components';

import Avatar from '~/components/UI/Avatar';

import { Incognito } from './Incognito';

import { COLORS } from '~/styles';

import type { IUser } from '@learned/types';

interface IAvatarsProps {
  users: IUser['id'][];
}

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 5px;
`;

const AvatarWrapper = styled.div<{ left?: string }>`
  border: 1px solid ${COLORS.WHITE};
  border-radius: 10rem;
  position: absolute;
  left: ${({ left }) => left || '0'};
`;

const RemainingNumber = styled.span<{ left?: string }>`
  line-height: 34px;
  position: absolute;
  padding-left: 6px;
  font-size: 14px;
  left: ${({ left }) => left || '0'};
`;

const Avatars = ({ users }: IAvatarsProps) => {
  const otherUsers = cloneDeep(users);
  const avatars = otherUsers.splice(0, 3);
  return (
    <Wrapper>
      {avatars.map((user, index) => {
        return (
          <AvatarWrapper key={user} left={`${index * 12}px`}>
            {/* @ts-ignore */}
            {user === 'anonymous' ? <Incognito /> : <Avatar userId={user} borderRadius="100px" />}
          </AvatarWrapper>
        );
      })}
      {!isEmpty(otherUsers) && (
        <RemainingNumber
          left={`${++avatars.length * 14}px`}
        >{`+${otherUsers.length}`}</RemainingNumber>
      )}
    </Wrapper>
  );
};

export { Avatars };
