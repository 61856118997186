import React, { ComponentType, forwardRef, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { getUser } from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getMultiLangString } from '~/utils/getMultiLangString';

import type { IUser, IConnection } from '@learned/types';

const useMultiLangString = () => {
  const currentUser: IUser & { connections: IConnection[] } = useSelector(getUser);
  const currentCompany = useSelector(getCurrentCompany);
  const currentConnection = currentUser?.connections.find(
    (conn) => conn.company === currentCompany.id,
  ) as IConnection;

  return useCallback(
    (multiLangString: Record<string, string> | string) => {
      return multiLangString
        ? getMultiLangString(multiLangString, currentConnection ?? {}, currentCompany, false)
        : '';
    },
    [currentConnection, currentCompany],
  );
};

function withMultiLangString<
  T extends { getMultiLangString: ReturnType<typeof getMultiLangString> },
>(OriginalComponent: ComponentType<T>) {
  return forwardRef<unknown, Omit<T, 'getMultiLangString'>>((props, ref) => {
    const getMultiLangString = useMultiLangString();

    return (
      <OriginalComponent {...(props as T)} getMultiLangString={getMultiLangString} ref={ref} />
    );
  });
}

export { useMultiLangString, withMultiLangString };
