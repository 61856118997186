import { createAction } from 'redux-act';

export const finishSetApp = createAction('finish set App');

export function setApp(app) {
  return function (dispatch) {
    return dispatch(finishSetApp(app));
  };
}

export const finishUpdateApp = createAction('finish update App');

export function updateApp(app) {
  return function (dispatch) {
    return dispatch(finishUpdateApp(app));
  };
}

export function clearSearch() {
  return function (dispatch, getState) {
    const search = getState().app.search;

    if (search) {
      return dispatch(finishUpdateApp({ search: '' }));
    }
  };
}

export function resetFiledValidation() {
  return function (dispatch, getState) {
    const isFieldsValidated = getState().app.isFieldsValidated;
    if (!isFieldsValidated) {
      dispatch(updateApp({ isFieldsValidated: true }));
    }
  };
}

export const setIsRequestError = createAction('set is request error');
export const setIsErrorInToast = createAction('set is error in toast');
