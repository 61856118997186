import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';

import { COLOR_PALETTE } from '~/styles';

const Content = styled.div`
  max-width: 845px;
  display: block;
  margin-left: 163px;
  margin-top: ${(props) => (props.$marginTop ? props.$marginTop : null)};
`;

const ButtonCol = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${(props) => props.$marginTop || 0};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.div`
  color: ${COLOR_PALETTE.BLACK};
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
`;

const Section = styled.div`
  display: block;
  border-bottom: ${(props) => (props.$isBottomBorder ? '1px solid #ebf1fe' : null)};
  ${(props) =>
    props.$isBottomBorder && 'margin-left: -26px; margin-right: -32px; padding: 0 32px 16px 26px;'};
  margin-bottom: ${(props) =>
    props.$noMarginBottom ? null : props.$marginBottom ? props.$marginBottom : '10px'};
`;

const UpdateProfileButton = styled(Button)`
  font-weight: 600;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
`;

const UserProfileSection = ({
  label,
  marginBottom,
  noMarginBottom,
  action,
  actionLabel,
  readOnly,
  children,
  isAboutSection,
  contentMarginTop,
}) => {
  return (
    <Section
      $marginBottom={marginBottom}
      $noMarginBottom={noMarginBottom}
      $isBottomBorder={isAboutSection}
    >
      <Wrapper>
        <Header>
          <div>
            <Label>{label}</Label>
          </div>
          {!readOnly && action && actionLabel && !isAboutSection && (
            <ButtonCol>
              <UpdateProfileButton
                label={actionLabel}
                onClick={action}
                type="primary-border"
                height={32}
              />
            </ButtonCol>
          )}
        </Header>
        <Content $marginTop={contentMarginTop}>{children}</Content>
      </Wrapper>
      {!readOnly && isAboutSection && (
        <ButtonCol $marginTop="8px">
          <UpdateProfileButton
            label={actionLabel}
            onClick={action}
            type="primary-border"
            height={32}
          />
        </ButtonCol>
      )}
    </Section>
  );
};

UserProfileSection.propTypes = {
  label: PropTypes.string.isRequired,
};

UserProfileSection.defaultProps = {
  label: null,
};

export default UserProfileSection;
