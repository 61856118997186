import React from 'react';

import { withI18n } from '@lingui/react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import ProgressBarWide from '~/components/ProgressBarWide';
import StepIcon from '~/components/QuickStartStepIcon';
import SvgIcon from '~/components/SvgIcon';
import BoxWithBorder from '~/components/UI/BoxWithBorder';

import closeIcon from '~/assets/open-new-down.svg';
import openIcon from '~/assets/open-new.svg';

import { COLORS } from '~/styles';

const Column = styled.div`
  display: flex;
  align-items: center;
`;

const Part = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 58px;
  cursor: pointer;
`;

const Row = styled.div`
  padding: 16px 23px 16px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SetupTitle = styled.div`
  font-size: 16px;
  color: var(--company-color);
  font-weight: normal;
  line-height: normal;
  margin-right: 30px;
`;

const BoldSetupTitle = styled(SetupTitle)`
  font-weight: 600;
  line-height: 1;
  color: ${(props) => !props.isActiv && COLORS.TEXT_PRIMARY};
`;

const StepsWrapper = styled.div``;

const Progress = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: var(--company-color);
  margin: 0 15px;
`;

const Title = styled.div`
  margin-left: 8px;
`;

const Wrapper = styled(BoxWithBorder)`
  width: 100%;
  margin-bottom: 0;
`;

function QuickStartPart({ i18n, isActiv, part, onChangePart, children, partProgress }) {
  return (
    <Wrapper>
      <Part onClick={onChangePart}>
        <Row>
          <Column>
            <StepIcon partKey={part.key} />
            <Title>
              <BoldSetupTitle isActiv={isActiv}>{part.title(i18n)}</BoldSetupTitle>
            </Title>
          </Column>
          <Column>
            <Column style={{ width: '145px' }}>
              <ProgressBarWide
                height="10px"
                value={partProgress}
                isActive
                style={{ borderRadius: '6px' }}
              />
              <Progress>{partProgress}%</Progress>
            </Column>
            <Column>
              <SvgIcon width="24px" height="24px" url={isActiv ? openIcon : closeIcon} />
            </Column>
          </Column>
        </Row>
      </Part>
      {isActiv && <StepsWrapper>{children}</StepsWrapper>}
    </Wrapper>
  );
}

QuickStartPart.propTypes = {
  onChangePart: PropTypes.func.isRequired,
  part: PropTypes.object.isRequired,
  isActiv: PropTypes.bool.isRequired,
  partsAmount: PropTypes.number,
  partNumber: PropTypes.number,
  leftStepsCount: PropTypes.number,
  partProgress: PropTypes.number,
};

QuickStartPart.defaultProps = {
  partsAmount: 1,
  partNumber: 1,
};

export default withI18n()(QuickStartPart);
