import React, { ReactNode } from 'react';

import styled from 'styled-components';

import { IItem } from '~/components/SurveyQuestionReport/SurveyQuestion';

import { Header } from './Header';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  display: flex;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${COLORS.BLACK};
  margin-bottom: 6px;
`;

const TitleNumber = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${COLORS.BLACK};
`;

const InnerWrapper = styled.div`
  width: 100%;
  padding-left: 20px;
`;

interface IQuestionWrapperProps {
  item: IItem;
  children?: ReactNode;
}
const QuestionWrapper = ({ item, children }: IQuestionWrapperProps) => {
  return (
    <Wrapper>
      {item.titleNumber && <TitleNumber>{item.titleNumber}</TitleNumber>}
      <InnerWrapper>
        <Title>{item.title}</Title>
        <Header
          type={item.type}
          themeName={item.themeName}
          isAnswersHidden={item.isAnswersHidden}
        />
        {children}
      </InnerWrapper>
    </Wrapper>
  );
};

export { QuestionWrapper };
