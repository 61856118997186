import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import Modal from '~/components/Modal';

import { ContentWrapper, FooterRight, Header, JobFamiliesWrapper, JobFamilyItem } from './design';

import { getJobFamiliesSuperAdmin } from '~/services/jobFamilies';
import { COLORS } from '~/styles';

import { JobFamiliesModal } from '../JobFamiliesModal';

import type { IManageJobFamiliesModalProps } from './types';
import type { IJobFamily } from '@learned/types';

const ManageJobFamiliesModal = ({ closeModal }: IManageJobFamiliesModalProps) => {
  const { i18n } = useLingui();

  const [isJobFamiliesModalVisible, setIsJobFamiliesModalVisible] = useState(false);
  const [jobFamilies, setJobFamilies] = useState<IJobFamily[]>([]);
  const [jobFamily, setJobFamily] = useState<IJobFamily>();

  const fetchData = async () => {
    const result = await getJobFamiliesSuperAdmin();

    setJobFamilies(Object.values(result.data.jobFamilies));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Modal
        title={i18n._(t`Manage job families`)}
        onClose={closeModal}
        showDivider={false}
        centerModal
        minWidth={750}
        contentStyles={{ padding: '20px 40px 0', overflow: 'visible' }}
        headerStyles={{ padding: '20px 40px', fontSize: '26px', fontWeight: 'normal' }}
        borderRadius={6}
        hideFooter
      >
        <Header>
          <Button
            type="button"
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.PRIMARY}
            label={i18n._(t`+ Job family`)}
            onClick={() => {
              setJobFamily(undefined);
              setIsJobFamiliesModalVisible(true);
            }}
          />
        </Header>
        <ContentWrapper>
          <JobFamiliesWrapper>
            {jobFamilies.map((item) => (
              <JobFamilyItem key={item.id}>
                <span className="content">
                  <span>{item.name.en_GB}</span>
                  <button
                    type="button"
                    className="edit"
                    onClick={() => {
                      setIsJobFamiliesModalVisible(true);
                      setJobFamily(item);
                    }}
                  >
                    <Icon
                      icon={ICONS.EDIT_PENCIL}
                      size={ICON_SIZES.MEDIUM}
                      color={COLORS.ICONS_SECONDARY}
                    />
                  </button>
                </span>
              </JobFamilyItem>
            ))}
          </JobFamiliesWrapper>
        </ContentWrapper>
        <FooterRight>
          <Button
            label={i18n._(t`Cancel`)}
            onClick={closeModal}
            type="button"
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
          />
          <Button
            label={i18n._(t`Save`)}
            onClick={closeModal}
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
          />
        </FooterRight>
      </Modal>
      {isJobFamiliesModalVisible && (
        <JobFamiliesModal
          jobFamily={jobFamily}
          closeModal={() => setIsJobFamiliesModalVisible(false)}
          refetchData={fetchData}
        />
      )}
    </>
  );
};

export { ManageJobFamiliesModal };
