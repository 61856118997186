import React from 'react';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const ExternalLinkIcon = ({ size, className, fill }) => (
  <InlineSvg width={size} height={size} viewBox="0 0 20 20" fill={fill} className={className}>
    {(color) => (
      <>
        <path
          fill={color}
          d="m17 17h-14v-14h5v-2h-5a2 2 0 0 0 -2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5h-2z"
        />
        <path fill={color} d="m19 1h-8l3.29 3.29-5.73 5.73 1.42 1.42 5.73-5.73 3.29 3.29z" />
      </>
    )}
  </InlineSvg>
);

ExternalLinkIcon.defaultProps = {
  size: 24,
};

export default ExternalLinkIcon;
