import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RickTextView from '~/components/RickTextView';

import { COLOR_PALETTE, COLORS } from '~/styles';

const Row = styled.div`
  margin: 16px -24px 0;
  padding: 0 24px 20px;
  border-bottom: 1px solid ${COLORS.BORDER_SOFT};

  p {
    overflow-wrap: anywhere;
  }
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
`;

const Description = ({ description }) => {
  const { i18n } = useLingui();

  return (
    <Row>
      <Label>{i18n._(t`Description`)}</Label>
      <RickTextView html={description} fontColor={COLOR_PALETTE.DARK_GRAY} />
    </Row>
  );
};

Description.proptypes = {
  description: PropTypes.string,
};

export default React.memo(Description);
