import { FocusAreaType } from '@learned/constants';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { ISelectedSkillTemplate } from './components/SkillTemplatesTable';

import type { ISkillCategory } from '@learned/types';

export interface ISkillCategoryColumn {
  id: string;
  skillId?: string;
  name: string | null;
  focusArea: string | null;
  level: number;
  skillCategoryId?: string;
  totalLevels?: number;
  onDelete: () => void;
  onEdit: () => void;
}

interface IFocusArea {
  id: string;
  name: string;
  level: number;
  values: { id: string; name: string }[];
}

export const convertSkillsDataToTable = ({
  skills,
  skillCategory,
  getMultiLangString,
}: {
  skillCategory?: ISkillCategory;
  skills: ISelectedSkillTemplate[];
  getMultiLangString: (multiLangString: string | Record<string, string>) => string;
}) => {
  const result: Omit<ISkillCategoryColumn, 'onDelete' | 'onEdit'>[] = [];

  skills.forEach((skill) => {
    if (!skill) {
      return;
    }

    const skillRows = generateSkillRows(skill, getMultiLangString);

    skillRows.forEach((item: any, index) => {
      result.push({
        id: index === 0 ? skill.skill.id : '',
        skillId: skill.skill?.id,
        name: getMultiLangString(index === 0 ? skill.skill.name || '' : ''),
        focusArea: getMultiLangString(item.focusArea || ''),
        level: item.level + 1,
        totalLevels: skillCategory?.skillLevels.length || 0,
        skillCategoryId: skillCategory?.id,
      });
    });
  });

  return result;
};

const generateSkillRows = (
  skill: ISelectedSkillTemplate,
  getMultiLangString: (multiLangString: string | Record<string, string>) => string,
) => {
  let skillRows: { focusArea: string; level: number | undefined }[] = [];

  skill.selectedFocusAreas.forEach((selectedFocusArea) => {
    const levelFocusAreas = getLevelFocusAreas(skill.skill.focusAreas, selectedFocusArea.level);

    if (selectedFocusArea.type === FocusAreaType.SELECT_ALL) {
      skillRows = generateAllSkillRows(skill.skill.focusAreas, getMultiLangString);
    } else if (
      selectedFocusArea.type === FocusAreaType.SELECT_LEVEL &&
      !isNil(selectedFocusArea.level)
    ) {
      skillRows = generateLevelSkillRows(
        levelFocusAreas,
        selectedFocusArea.level,
        getMultiLangString,
      );
    } else if (selectedFocusArea.type === FocusAreaType.SELECT_FOCUS_AREA) {
      skillRows = generateFocusAreaSkillRows(
        levelFocusAreas,
        selectedFocusArea.focusArea,
        selectedFocusArea.level,
        getMultiLangString,
      );
    }
  });

  return skillRows;
};

const getLevelFocusAreas = (focusAreas: IFocusArea[], level: number | undefined) => {
  return focusAreas.filter((area) => area.level === level)[0];
};

const generateAllSkillRows = (
  focusAreas: IFocusArea[],
  getMultiLangString: (multiLangString: string | Record<string, string>) => string,
) => {
  const skillRows: { focusArea: string; level: number }[] = [];

  focusAreas.forEach((area) => {
    if (isEmpty(area.values)) {
      skillRows.push({
        focusArea: getMultiLangString({ en_GB: '' }),
        level: area.level,
      });
    } else {
      area.values.forEach(({ name }) => {
        skillRows.push({
          focusArea: getMultiLangString(name),
          level: area.level,
        });
      });
    }
  });

  return skillRows;
};

const generateLevelSkillRows = (
  levelFocusAreas: IFocusArea | undefined,
  level: number,
  getMultiLangString: (multiLangString: string | Record<string, string>) => string,
) => {
  const skillRows: { focusArea: string; level: number }[] = [];

  if (!isNil(level) && !isEmpty(levelFocusAreas?.values)) {
    levelFocusAreas?.values.forEach(({ name }) => {
      skillRows.push({
        focusArea: getMultiLangString(name),
        level,
      });
    });
  } else {
    skillRows.push({
      focusArea: getMultiLangString({ name: '' }),
      level: levelFocusAreas?.level || 0,
    });
  }

  return skillRows;
};

const generateFocusAreaSkillRows = (
  levelFocusAreas: IFocusArea | undefined,
  focusArea: string[] | undefined,
  level: number | undefined,
  getMultiLangString: (multiLangString: string | Record<string, string>) => string,
) => {
  const skillRows: { focusArea: string; level: number | undefined }[] = [];

  levelFocusAreas?.values
    ?.filter(({ id }) => focusArea?.includes(id))
    .forEach(({ name }) => {
      skillRows.push({
        focusArea: getMultiLangString(name),
        level,
      });
    });

  return skillRows;
};
