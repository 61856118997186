import { ICompany, IConnection } from '@learned/types';
import isNil from 'lodash/isNil';

export const getMultiLangString = (
  objectToGetStringFrom: { [key: string]: string | undefined | null } | string,
  connection: Pick<IConnection, 'preferredLang'>,
  company: Pick<ICompany, 'languages' | 'primaryLang'>,
  isRequired = true, // isRequired is false when field can be returned as empty string, otherwise true
  defaultLang = 'en_GB',
): string => {
  if (typeof objectToGetStringFrom === 'string') {
    return objectToGetStringFrom;
  }
  const { preferredLang: pref } = connection;
  const { languages: langs, primaryLang: primary } = company;
  const preferredLang = pref?.locale;
  const languages = langs.map((lang) => lang.locale);
  const primaryLang = primary?.locale;

  const mainLangs = [
    ...(preferredLang && languages.includes(preferredLang) ? [preferredLang] : []),
    ...[primaryLang],
    ...[defaultLang],
    ...languages.filter((l) => ![primaryLang, preferredLang, defaultLang].includes(l)),
  ].filter((i) => i);

  mainLangs.forEach((lang) => {
    const potentialString = objectToGetStringFrom[lang];
    if (
      !isNil(potentialString) &&
      potentialString !== '' &&
      (isRequired ? potentialString?.trim() : potentialString)
    ) {
      return potentialString;
    }
  });

  let translation = '';
  let isTranslated = false;

  mainLangs.forEach((lang) => {
    const potentialString = objectToGetStringFrom[lang];
    if (
      !isTranslated &&
      !isNil(potentialString) &&
      potentialString !== '' &&
      (isRequired ? potentialString?.trim() : potentialString)
    ) {
      isTranslated = true;
      translation = potentialString;
    }
  });

  if (!isTranslated) {
    Object.values(objectToGetStringFrom).forEach((potentialString) => {
      if (!isRequired && !isTranslated && !isNil(potentialString) && potentialString !== '') {
        isTranslated = true;
        translation = potentialString;
      }
      if (potentialString?.trim() && !isTranslated) {
        isTranslated = true;
        translation = potentialString;
      }
    });
  }

  return translation;
};
