import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, LEARNING_REPORT_TEAMS_SORT_OPTIONS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import size from 'lodash/size';
import styled from 'styled-components';

import Modal from '~/components/Modal';
import PaginationBar from '~/components/PaginationBar';
import { Table } from '~/components/Table';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import { MEMBERS_COLUMN } from '~/pages/AdminReports/LearningReportPage/config';
import { FiltersRow } from '~/pages/AdminReports/LearningReportPage/TeamItemModal/FiltersRow';
import {
  TIME_FRAMES,
  TIME_FRAMES_IDS,
} from '~/pages/AdminReports/LearningReportPage/TimeFrameSelector';

import useDebounce from '~/hooks/useDebounce';
import { getLearningReportMembers, downloadLearningReportMembersCSV } from '~/services/activities';
import { COLOR_PALETTE } from '~/styles';

import { Placeholder } from '../Placeholder';

const Container = styled.div`
  border: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
`;

export const defaultFilters: {
  startDate: string;
  endDate: string;
  search: string;
  sortBy: string;
  teams: string[];
  skip: number;
  limit: number;
  page: number;
} = {
  startDate: TIME_FRAMES.LAST_WEEK.value.toISOString(), // default value if LAST_WEEK
  endDate: new Date().toISOString(), // always current date, until we implement more flexible UI
  search: '',
  sortBy: LEARNING_REPORT_TEAMS_SORT_OPTIONS.COMPLETED_HIGH_LOW,
  teams: [],
  skip: 0,
  limit: 10, // 10 items per page
  page: 1, // start from first page
};

interface ITeamItemModal {
  team: {
    id: string;
    name: string;
    completed: number;
    members: number;
  };
  onClose: () => void;
  timeFrame: TIME_FRAMES_IDS;
}

const TeamItemModal = ({ team, onClose, timeFrame }: ITeamItemModal) => {
  const preSelectedTimeFrame =
    timeFrame && TIME_FRAMES[timeFrame] ? TIME_FRAMES[timeFrame] : TIME_FRAMES.LAST_YEAR;
  const [filters, setFilters] = useState({
    ...defaultFilters,
    teams: [team.id],
    startDate: preSelectedTimeFrame.value.toISOString(), // set date from props of default
  });
  const [members, setMembers] = useState<
    { id: string; name: string; completed: number; completedPercentage: number }[]
  >([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { i18n } = useLingui();
  const { addToast } = useToasts();
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(preSelectedTimeFrame);

  const debFilters = useDebounce(filters, 300); // to send request not immediately, but with delay

  // init render
  // filters/search change update
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      setIsLoading(true);
      const response = await getLearningReportMembers({
        ...filters,
        sortBy: filters.sortBy,
      });
      if (response?.data[API_RETURN_FIELDS.REPORT] && isMounted) {
        setMembers(response.data[API_RETURN_FIELDS.REPORT]);
        setTotal(response.data[API_RETURN_FIELDS.TOTAL]);
      }
      setIsLoading(false);
    };

    fetchData();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [debFilters]);

  useEffect(() => {
    setIsLoading(true);
  }, [filters.search]);

  const onChangeFilters = (obj: Partial<typeof defaultFilters>) => {
    setFilters({ ...filters, ...obj });
  };

  const onChangeItemsPerPage = ({ limit }: { limit: number }) => {
    onChangeFilters({ limit, skip: 0, page: 1 }); // reset to default pagination (first page)
  };

  const onPageChangeClick = ({ skip, index }: { skip: number; index: number }) => {
    onChangeFilters({ skip, page: index });
  };

  const onChangeTimeFrame = (timeFrame: typeof TIME_FRAMES.LAST_WEEK) => {
    setSelectedTimeFrame(timeFrame);
    onChangeFilters({
      startDate: timeFrame.value.toISOString(),
    });
  };

  const onDownloadCSV = async () => {
    addToast({
      title: i18n._(t`Exporting CSV`),
      subtitle: i18n._(
        t`Your CSV is being downloaded. This can take some time. It will download when it is ready.`,
      ),
      type: TOAST_TYPES.INFO,
    });

    // Request and download reviews CSV data
    // in csv we display all rows;
    const csvFilters = { ...filters };
    // @ts-ignore
    delete csvFilters.skip;
    // @ts-ignore
    delete csvFilters.limit;

    await downloadLearningReportMembersCSV(csvFilters);
  };

  return (
    <Modal
      title={i18n._(t`Team name: ${team.name}`)}
      onClose={onClose}
      centerModal
      minWidth={1008}
      width={1008}
      contentStyles={{ padding: '32px', overflow: 'visible', zIndex: 11 }}
      headerStyles={{ paddingLeft: '40px' }}
      hideFooter
    >
      <Container>
        <FiltersRow
          filters={filters}
          onChangeFilters={onChangeFilters}
          onDownloadCSV={onDownloadCSV}
          timeFrame={selectedTimeFrame}
          onChangeTimeFrame={onChangeTimeFrame}
        />
        <Table
          data={members}
          columns={MEMBERS_COLUMN}
          sortBy={filters.sortBy}
          setSortBy={(value) => onChangeFilters({ sortBy: value })}
        />

        {!size(members) && (
          <Placeholder isFiltered={!!filters.search.length} isLoading={isLoading} />
        )}
        {!!size(members) && (
          <PaginationBar
            pagination={{ index: filters.page, limit: filters.limit, skip: filters.skip }}
            changePagination={onPageChangeClick}
            changePageSize={onChangeItemsPerPage}
            count={total}
            noTopBorder
            showCount
            noBorder
            noShadow
          />
        )}
      </Container>
    </Modal>
  );
};

export { TeamItemModal };
