import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';

import Modal from '~/components/Modal';

import GoalCycles from './GoalCycles';

import { getUser } from '~/selectors/baseGetters';

interface GoalsSettingsModalProps {
  onClose: () => void;
}

const GoalsSettingsModal = ({ onClose }: GoalsSettingsModalProps) => {
  const { i18n } = useLingui();
  const user = useSelector(getUser);
  const isAdmin = user.isAdmin;

  // close modal if it's not an admin
  if (!isAdmin) {
    return null;
  }

  return (
    <Modal
      hideFooter
      title={i18n._(t`Goals Settings`)}
      onClose={onClose}
      showDivider={false}
      centerModal
    >
      <GoalCycles />
    </Modal>
  );
};

export { GoalsSettingsModal };
