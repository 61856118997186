import React, { useState, useEffect } from 'react';

import {
  CONFIRMATION_MODAL_TYPE,
  CURRENCY,
  GOAL_PROGRESS_TYPES,
  SYMBOLS,
  GOAL_STATUSES_NEW,
  GOAL_TYPES,
  ACTIVITY_ROOT,
} from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import nanoid from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { DraggableList } from '~/components/DraggableList';
import type { Item } from '~/components/DraggableList/types';
import { DropdownButtonNew } from '~/components/DropdownButtonNew';
import { ICONS, ICON_SIZES } from '~/components/Icon';
import { Input } from '~/components/Input';
import LibraryActivitiesModal from '~/components/LibraryActivitiesModal';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import Switch from '~/components/Switch';

import { ActivityItem } from './ActivityItem';
import { CustomActivityModal } from './CustomActivityModal';

import {
  CURRENCY_TYPES,
  GOAL_PROGRESS_MEASUREMENT_TYPES,
  METRIC_MEASUREMENT_TYPES,
} from '~/constants/goals';
import useBoolState from '~/hooks/useBoolState';
import { checkModuleLearning, getUser } from '~/selectors/baseGetters';
import {
  getActivities,
  getCurrentGoal,
  getErrors,
  getIsAddActivities,
  getProgressDetails,
  getProgressType,
  getStatus,
} from '~/selectors/currentGoal';
import * as currentGoalActions from '~/store/currentGoal/actions';
import { calculateProgressPercentage } from '~/utils/activityUtils';
import { formatNumber } from '~/utils/formatNumber';
import { getGoalProgressWithDecimalsForInputs } from '~/utils/getGoalProgressWithDecimals';

import {
  Form,
  ItemContainer,
  ItemLabel,
  ItemRow,
  StyledDropdownLong,
  SubTitle,
  Title,
  StyledDropdownShort,
  ActivitySectionContainer,
  ActivityHeaderRow,
  ActivityToggleContainer,
  ActivityToggleText,
  ActivityButtonContainer,
  ActivitiesTitle,
  FooterContainer,
  ActivityListContainer,
  Error,
  StyledDivider,
} from '../stepsDesign';

import type { Activity } from './types';
import type { IGoal } from '@learned/types';
import type { I18n } from '@lingui/core';

type MeasurementItem = { key: GOAL_PROGRESS_TYPES; value: (it18n: I18n) => string };
type CurrencyItem = { key: CURRENCY; value: SYMBOLS };

const wayOfMeasurementItems: Array<MeasurementItem> = Object.values(
  GOAL_PROGRESS_MEASUREMENT_TYPES,
);
const metricMeasurementItems: Array<MeasurementItem> = Object.values(METRIC_MEASUREMENT_TYPES);
const currencies: Array<CurrencyItem> = Object.values(CURRENCY_TYPES);

interface IProps {
  onBack: () => void;
  onPublish: (isDraft: boolean) => void;
  isUpdate: boolean;
}

const StepTwo = ({ onBack, onPublish, isUpdate }: IProps) => {
  const { i18n } = useLingui();
  const [wayOfMeasurement, setWayOfMeasurement] = useState<MeasurementItem>(
    wayOfMeasurementItems[0],
  );
  const [tempWayOfMeasurement, setTempWayOfMeasurement] = useState<MeasurementItem>();
  const [metricMeasurement, setMetricMeasurement] = useState<MeasurementItem>(
    metricMeasurementItems[0],
  );

  const activities = useSelector(getActivities) as Array<Activity>;
  const progressType = useSelector(getProgressType);
  const progressDetails = useSelector(getProgressDetails);
  const errors = useSelector(getErrors);
  const status = useSelector(getStatus);

  const [currencyType, setCurrencyType] = useState<CurrencyItem>(currencies[0]);
  const [activityModalVisible, setActivityModalVisible] = useState<boolean>(false);

  const $isLibraryActivitiesModal = useBoolState(false);
  const $isWarningToggleVisible = useBoolState(false);
  const $isNewActivity = useBoolState(false);

  const [activityToEdit, setActivityToEdit] = useState<Activity>();
  const [activityIndexToEdit, setActivityIndexToEdit] = useState<number>();

  const isLearningAndOnboarding = useSelector(checkModuleLearning);
  const user = useSelector(getUser);
  const isAddGoalActivities = useSelector(getIsAddActivities);
  const goal: IGoal = useSelector(getCurrentGoal);
  const dispatch = useDispatch();

  const isPersonalGoal = [GOAL_TYPES.PERSONAL, GOAL_TYPES.BUSINESS].includes(
    goal.type as GOAL_TYPES,
  );

  const addCustomActivity = (customActivity: Activity) => {
    const progress = !isEmpty(activityToEdit) ? activityToEdit?.progress || 0 : 0;
    const calculatedProgress = calculateProgressPercentage({
      progressDetails: customActivity.progressDetails,
      progress,
      progressType: customActivity.progressType,
    });
    customActivity.calculatedProgress = calculatedProgress;
    if ($isNewActivity.value) {
      // @ts-ignore
      dispatch(currentGoalActions.addCurrentGoalActivity(customActivity));
    } else {
      dispatch(
        // @ts-ignore
        currentGoalActions.updateCurrentGoalActivity({
          ...customActivity,
          activityIndex: activityIndexToEdit,
        }),
      );
    }
  };

  const editActivityFromModal = (activity: Activity, index: number) => {
    setActivityToEdit(activity);
    setActivityIndexToEdit(index);
    $isNewActivity.off();
    setActivityModalVisible(true);
  };

  const transformActivities = (activities: Array<Activity>) => {
    const updatedActivities = activities.map((activity: Activity, index: number) => {
      if (activity.id && activity.root !== ACTIVITY_ROOT.CUSTOM) {
        return {
          id: activity.id,
          item: activity,
          content: (
            <ActivityItem
              index={index}
              activity={activity}
              isCustom={false}
              onEdit={editActivityFromModal}
            />
          ),
        };
      } else {
        return {
          id: nanoid(),
          item: activity,
          content: (
            <ActivityItem
              index={index}
              activity={activity}
              isCustom={true}
              onEdit={editActivityFromModal}
            />
          ),
        };
      }
    });
    return updatedActivities as Array<Item>;
  };

  const reArrangeActivities = (items: Array<Item>) => {
    const reArrangedActivities = items.map((item: Item) => {
      return item.item;
    });
    // @ts-ignore
    dispatch(currentGoalActions.setCurrentGoalActivities(reArrangedActivities));
  };

  const createActivityFromModal = () => {
    $isNewActivity.on();
    setActivityModalVisible(true);
  };

  const onToggleChange = (value: boolean) => {
    if (!value && activities.length > 0) {
      $isWarningToggleVisible.on();
    } else {
      // @ts-ignore
      dispatch(currentGoalActions.setIsAddCurrentGoalActivities(value));
    }
  };

  const resetWayOfMeasurement = () => {
    setWayOfMeasurement(tempWayOfMeasurement!);

    if (tempWayOfMeasurement?.key === GOAL_PROGRESS_TYPES.METRIC) {
      // @ts-ignore
      dispatch(currentGoalActions.setCurrentGoalProgressType(metricMeasurement.key));
      return;
    }
    const details = {
      currency: null,
      min: null,
      max: null,
    };
    // @ts-ignore
    dispatch(currentGoalActions.setCurrentGoalProgressDetails(details));
    // @ts-ignore
    dispatch(currentGoalActions.setCurrentGoalProgressType(tempWayOfMeasurement.key));
    setCurrencyType(currencies[0]);
  };

  const onConfirmTheWarning = () => {
    resetWayOfMeasurement();
    // @ts-ignore
    dispatch(currentGoalActions.setCurrentGoalActivities([]));
    // @ts-ignore
    dispatch(currentGoalActions.setIsAddCurrentGoalActivities(false));
  };

  const onChangeWayOfMeasurement = (selectedItem: MeasurementItem) => {
    if (activities && activities.length > 0) {
      setTempWayOfMeasurement(selectedItem);
      $isWarningToggleVisible.on();
      return;
    } else {
      setWayOfMeasurement(selectedItem);

      if (selectedItem.key === GOAL_PROGRESS_TYPES.METRIC) {
        // @ts-ignore
        dispatch(currentGoalActions.setCurrentGoalProgressType(metricMeasurement.key));
        return;
      }
      const details = {
        currency: null,
        min: null,
        max: null,
      };
      // @ts-ignore
      dispatch(currentGoalActions.setCurrentGoalProgressDetails(details));
      // @ts-ignore
      dispatch(currentGoalActions.setCurrentGoalProgressType(selectedItem.key));
      setCurrencyType(currencies[0]);
    }
  };

  const onChangeMetric = (selectedItem: MeasurementItem) => {
    dispatch(
      // @ts-ignore
      currentGoalActions.setCurrentGoalProgressDetails({
        ...progressDetails,
        min: formatNumber(
          getGoalProgressWithDecimalsForInputs(progressDetails.min, selectedItem.key),
        ),
        max: formatNumber(
          getGoalProgressWithDecimalsForInputs(progressDetails.max, selectedItem.key),
        ),
      }),
    );

    setMetricMeasurement(selectedItem);
    // @ts-ignore
    dispatch(currentGoalActions.setCurrentGoalProgressType(selectedItem.key));
    if (selectedItem.key === GOAL_PROGRESS_TYPES.CURRENCY) {
      const details = {
        ...progressDetails,
        currency: CURRENCY.EURO,
      };
      // @ts-ignore
      dispatch(currentGoalActions.setCurrentGoalProgressDetails(details));
    }
  };

  const onCurrencyChange = (selectedItem: CurrencyItem) => {
    setCurrencyType(selectedItem);
    dispatch(
      // @ts-ignore
      currentGoalActions.setCurrentGoalProgressDetails({
        ...progressDetails,
        currency: selectedItem.key,
      }),
    );
  };

  const onMinValChange = (value: string, progressType: GOAL_PROGRESS_TYPES) => {
    value = getGoalProgressWithDecimalsForInputs(value, progressType);
    dispatch(
      // @ts-ignore
      currentGoalActions.setCurrentGoalProgressDetails({
        ...progressDetails,
        min: formatNumber(value),
      }),
    );
  };

  const onMaxValueChange = (value: string, progressType: GOAL_PROGRESS_TYPES) => {
    value = getGoalProgressWithDecimalsForInputs(value, progressType);
    dispatch(
      // @ts-ignore
      currentGoalActions.setCurrentGoalProgressDetails({
        ...progressDetails,
        max: formatNumber(value),
      }),
    );
  };

  useEffect(() => {
    if (wayOfMeasurement.key === GOAL_PROGRESS_TYPES.AVG || activities.length > 0) {
      // @ts-ignore
      dispatch(currentGoalActions.setIsAddCurrentGoalActivities(true));
    } else {
      // @ts-ignore
      dispatch(currentGoalActions.setIsAddCurrentGoalActivities(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities, wayOfMeasurement]);

  // select default selected items for dropdowns
  useEffect(() => {
    const isMetricType = metricMeasurementItems.some((item) => item.key === progressType);
    if (isMetricType) {
      setWayOfMeasurement(
        wayOfMeasurementItems.find((item) => item.key === GOAL_PROGRESS_TYPES.METRIC)!,
      );
      setMetricMeasurement(
        metricMeasurementItems.find((item) => item.key === progressType) ||
          metricMeasurementItems[0],
      );
    } else {
      setWayOfMeasurement(
        wayOfMeasurementItems.find((item) => item.key === progressType) || wayOfMeasurementItems[0],
      );
    }
    setCurrencyType(
      currencies.find((currency) => currency.key === progressDetails.currency) ||
        currencies.find((currency) => currency.key === CURRENCY.EURO)!,
    );
  }, [progressDetails, progressType]);

  return (
    <Form>
      <Title>
        <Trans>Goal progress</Trans>
      </Title>
      <SubTitle>
        <Trans>Choose how you want to measure the progress of this goal</Trans>
      </SubTitle>
      <ItemRow>
        <ItemContainer>
          <ItemLabel>
            <Trans>Way of measurement</Trans>
          </ItemLabel>
          <StyledDropdownLong
            placeholder={i18n._(t`Way of measurement`)}
            items={wayOfMeasurementItems}
            selectedItem={wayOfMeasurement}
            onChange={(selectedItem) => onChangeWayOfMeasurement(selectedItem as MeasurementItem)}
            stringifyItem={(item) => (item as MeasurementItem).value(i18n)}
            isSingleSelect={true}
          />
        </ItemContainer>
        {wayOfMeasurement.key === GOAL_PROGRESS_TYPES.METRIC && (
          <ItemContainer>
            <ItemLabel>
              <Trans>Choose your metrics</Trans>
            </ItemLabel>
            <StyledDropdownLong
              placeholder={i18n._(t`Choose your metrics`)}
              items={metricMeasurementItems}
              selectedItem={metricMeasurement}
              onChange={(selectedItem) => onChangeMetric(selectedItem as MeasurementItem)}
              stringifyItem={(item) => (item as MeasurementItem).value(i18n)}
              isSingleSelect={true}
            />
          </ItemContainer>
        )}
      </ItemRow>

      <ItemRow>
        {wayOfMeasurement.key === GOAL_PROGRESS_TYPES.METRIC &&
          metricMeasurement.key === METRIC_MEASUREMENT_TYPES.currency.key && (
            <ItemContainer>
              <ItemLabel>
                <Trans>Currency</Trans>
              </ItemLabel>
              <StyledDropdownShort
                placeholder={i18n._(t`Currency`)}
                items={currencies}
                selectedItem={currencyType}
                onChange={(selectedItem) => onCurrencyChange(selectedItem as CurrencyItem)}
                stringifyItem={(item) => (item as CurrencyItem).value}
                isSingleSelect={true}
              />
            </ItemContainer>
          )}

        {wayOfMeasurement.key === GOAL_PROGRESS_TYPES.METRIC && (
          <ItemContainer>
            <ItemLabel>
              <Trans>Start</Trans>
            </ItemLabel>
            <Input
              type="number"
              min={0}
              max={metricMeasurement.key === METRIC_MEASUREMENT_TYPES.percentage.key ? 100 : ''}
              placeholder="0"
              onChange={(e) => onMinValChange(e.target.value, metricMeasurement.key)}
              value={progressDetails.min}
              error={errors && errors.progressDetails && errors.progressDetails.min}
            />
          </ItemContainer>
        )}
        {wayOfMeasurement.key === GOAL_PROGRESS_TYPES.METRIC && (
          <ItemContainer>
            <ItemLabel>
              <Trans>Goal</Trans>
            </ItemLabel>
            <Input
              type="number"
              min={0}
              max={metricMeasurement.key === METRIC_MEASUREMENT_TYPES.percentage.key ? 100 : ''}
              placeholder="100"
              onChange={(e) => onMaxValueChange(e.target.value, metricMeasurement.key)}
              value={progressDetails.max}
              error={errors && errors.progressDetails && errors.progressDetails.max}
            />
          </ItemContainer>
        )}
      </ItemRow>
      <StyledDivider />

      {![GOAL_PROGRESS_TYPES.AVG_OF_CONTRIBUTING_GOALS].includes(wayOfMeasurement.key) && (
        <>
          <div>
            <ActivitySectionContainer isError={errors && errors.activities}>
              <ActivityHeaderRow>
                {wayOfMeasurement.key !== GOAL_PROGRESS_TYPES.AVG ? (
                  <ActivityToggleContainer>
                    <Switch onChange={onToggleChange} checked={isAddGoalActivities} />
                    <ActivityToggleText>
                      <Trans>Add goal activities</Trans>
                    </ActivityToggleText>
                  </ActivityToggleContainer>
                ) : (
                  <ActivitiesTitle>
                    <Trans>Activities</Trans>
                  </ActivitiesTitle>
                )}
                {isAddGoalActivities && (!isLearningAndOnboarding || !isPersonalGoal) && (
                  <Button
                    label={i18n._(t`Add activity`)}
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.MEDIUM}
                    onClick={createActivityFromModal}
                  />
                )}
                {isAddGoalActivities && isLearningAndOnboarding && isPersonalGoal && (
                  <ActivityButtonContainer>
                    <DropdownButtonNew
                      buttonLabel={i18n._(t`Add activity`)}
                      items={[
                        {
                          description: i18n._(
                            t`Determine what activity you need to complete in order to achieve your goal`,
                          ),
                          icon: ICONS.SURVEYS,
                          iconGradient: '#ffe6e6 5%, #fcd5d5 100%',
                          key: 'customActivity',
                          name: i18n._(t`Add custom activity`),
                          onClick: createActivityFromModal,
                        },
                        {
                          description: i18n._(
                            t`Choose an existing activity from the learning library in order to achieve your goal`,
                          ),
                          icon: ICONS.LOGOUT,
                          iconGradient: '#d8f7cd 0%, #ccebc0 100%',
                          key: 'activityFromLibrary',
                          name: i18n._(t`Add activity from library`),
                          onClick: $isLibraryActivitiesModal.on,
                        },
                      ]}
                      hideDropdown={activityModalVisible || $isLibraryActivitiesModal.value}
                    />
                  </ActivityButtonContainer>
                )}
              </ActivityHeaderRow>
              <ActivityListContainer>
                <DraggableList
                  items={transformActivities(activities)}
                  onChange={reArrangeActivities}
                  isDragDisabled={false}
                  isDragAreaEverywhere={false}
                />
              </ActivityListContainer>
            </ActivitySectionContainer>
            {errors && errors.activities && <Error>{errors.activities}</Error>}
          </div>
          <StyledDivider />
        </>
      )}

      <FooterContainer>
        <Button
          label={i18n._(t`Back`)}
          onClick={onBack}
          variant={ButtonVariant.TEXT_PRIMARY}
          icon={ICONS.BACK}
          size={ButtonSize.MEDIUM}
          iconSize={ICON_SIZES.SMALL}
          type="button"
        />
        <Button
          label={
            isUpdate && status !== GOAL_STATUSES_NEW.DRAFT ? i18n._(t`Save`) : i18n._(t`Publish`)
          }
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          onClick={() => onPublish(false)}
        />
      </FooterContainer>
      {activityModalVisible && (
        <CustomActivityModal
          onClose={() => {
            setActivityModalVisible(false);
            setActivityToEdit(undefined);
          }}
          addCustomActivity={addCustomActivity}
          activityToEdit={activityToEdit}
        />
      )}
      {$isLibraryActivitiesModal.value && (
        <LibraryActivitiesModal
          activitiesToHide={[]}
          onModalClose={$isLibraryActivitiesModal.off}
          type="goal"
          onSubmit={() => {}}
          userId={user.id}
        />
      )}

      {$isWarningToggleVisible.value && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.WARNING}
          title={i18n._(t`Warning`)}
          description={i18n._(t`Are you sure you want to remove the created activities?`)}
          onClose={$isWarningToggleVisible.off}
          onSubmit={onConfirmTheWarning}
        />
      )}
    </Form>
  );
};

export { StepTwo };
