import React, { Component } from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FeedbackOthersReport from '~/components/ReviewReport/FeedbackOthersReport';
import FeedbackSelfReport from '~/components/ReviewReport/FeedbackSelfReport';
import SkillName from '~/components/Skill/SkillName';
import SkillCategoriesLabels from '~/components/SkillCategoriesLabels';
import SvgIcon from '~/components/SvgIcon';

import toggleCloseIcon from '~/assets/toggle-close.svg';
import toggleOpenIcon from '~/assets/toggle-open.svg';

import { REQUEST_TYPES, RATING_TYPES } from '~/constants';
import { COLORS, COLOR_PALETTE } from '~/styles';
import getAverageRate, { isShowRate } from '~/utils/getAverageRate';

const BtnToggle = styled.div`
  margin-right: 7px;
`;

const QuestionHeader = styled.div`
  width: 100%;
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #f6f8fc;
  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
  border-radius: 6px;
  box-shadow: 1px 1px 2px 0 rgba(25, 42, 70, 0.2);
  border: solid 1px #e1e5ef;
  background-color: ${COLOR_PALETTE.WHITE};
  padding: 16px;
  box-sizing: border-box;
`;

const QuestionName = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: black;
  display: inline;
`;

const NameWrap = styled.div`
  display: flex;
  align-items: center;
`;

const SkillNameWrapper = styled.span`
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: black;
  font-weight: 800;
  margin: 0 5px;
`;

const QuestionWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  box-sizing: border-box;
`;

const FeedbackTypeWrapper = styled.div`
  margin-bottom: 27px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;

const AvgRow = styled.div`
  display: flex;
  margin: 24px 0;
`;

const AvgRowLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

const AvgRowValue = styled.div`
  margin-left: 8px;
`;

class QuestionReport extends Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    review: PropTypes.object,
    skill: PropTypes.object,
    isReadOnly: PropTypes.bool,
    type: PropTypes.string,
    open: PropTypes.bool,
    toggleQuestion: PropTypes.func,
  };

  static defaultTypes = {
    isReadOnly: false,
  };

  getRateQuestions = () => {
    const { i18n } = this.props;
    return i18n._(t`Rating`);
  };

  renderFeedbackType = ({ index, avg, rating, sum, label, template, hide }, { i18n }) => {
    const scale = get(rating, 'scale', []);
    const max = scale[scale.length - 1];
    let avgSting;
    if (Number(avg) === 0) {
      avgSting = sum ? i18n._(t`(${sum} reviews)`) : '';
    } else {
      avgSting = sum
        ? i18n._(t`(${sum} reviews / ${avg}/${max} avg)`) // for peer/coach review
        : i18n._(t`(${avg}/${max} avg)`); // for self-review
    }

    return (
      !hide && (
        <FeedbackTypeWrapper key={index}>
          <AvgRow>
            <AvgRowLabel>{label}</AvgRowLabel>
            <AvgRowValue>{max && avgSting ? avgSting : i18n._(t`(no ratings)`)}</AvgRowValue>
          </AvgRow>
          {template}
        </FeedbackTypeWrapper>
      )
    );
  };

  renderFeedbacks = () => {
    const { i18n, review, skill, cancelRequest, hideSelf, hidePeers, hideCoaches, question } =
      this.props;

    const questionText = this.getRateQuestions();

    const skillRatings = review.ratings
      ? review.ratings.filter((rating) => {
          if (skill) {
            return String(rating.skill) === String(skill.id);
          }
          return rating.question === question.id;
        })
      : [];

    let skillRatingsUser;
    let skillRatingsOthers = [];
    let skillRatingsCoaches = [];

    skillRatings.forEach((rating) => {
      switch (rating.type) {
        case RATING_TYPES.SELF_RATING:
          skillRatingsUser = rating;
          break;
        case RATING_TYPES.OTHER_RATING:
        case RATING_TYPES.OUTSIDE_RATING:
          skillRatingsOthers.push(rating);
          break;
        case RATING_TYPES.COACH_RATING:
          skillRatingsCoaches.push(rating);
          break;
        default:
          break;
      }
    });

    const requests = get(review, 'requests', []);
    const requestsOthers = requests.filter((request) => {
      return (
        request.type === REQUEST_TYPES.FEEDBACK_FROM_PEER ||
        request.type === REQUEST_TYPES.FEEDBACK_FROM_OUTSIDE_PEER
      );
    });
    const requestsCoaches = requests.filter(
      (request) => request.type === REQUEST_TYPES.FEEDBACK_FROM_COACH,
    );

    const averageRatingUser = review.isSelfFeedbackProvided ? get(skillRatingsUser, 'rate', 0) : 0;
    const averageRatingOthers = getAverageRate(skillRatingsOthers, requestsOthers);
    const averageRatingCoaches = getAverageRate(skillRatingsCoaches, requestsCoaches);

    let filteredSkillRatingsOther = [];
    let filteredSkillRatingsCoach = [];
    if (!isEmpty(skillRatingsOthers)) {
      filteredSkillRatingsOther = skillRatingsOthers.filter((rate) => {
        return isShowRate(rate, requestsOthers);
      });
    }
    if (!isEmpty(skillRatingsCoaches)) {
      filteredSkillRatingsCoach = skillRatingsCoaches.filter((rate) => {
        return isShowRate(rate, requestsCoaches);
      });
    }

    const FEEDBACK_TYPES = [
      {
        index: 0,
        label: i18n._(t`Self review`),
        avg: averageRatingUser,
        rating: skillRatingsUser,
        hide: hideSelf,
        template: (
          <FeedbackSelfReport
            review={review}
            rating={skillRatingsUser}
            questionText={questionText}
          />
        ),
      },
      {
        index: 1,
        label: i18n._(t`Peer review(s)`),
        avg: averageRatingOthers.toFixed(),
        sum: requestsOthers.length,
        rating: filteredSkillRatingsOther[0],
        hide: hidePeers,
        template: (
          <FeedbackOthersReport
            reportType="peer"
            review={review}
            ratings={filteredSkillRatingsOther}
            requests={requestsOthers}
            cancelRequest={cancelRequest}
            questionText={questionText}
          />
        ),
      },
      {
        index: 2,
        label: i18n._(t`Coach review`),
        avg: averageRatingCoaches.toFixed(),
        rating: filteredSkillRatingsCoach[0],
        sum: requestsCoaches.length,
        hide: hideCoaches,
        template: (
          <FeedbackOthersReport
            reportType="coach"
            review={review}
            ratings={filteredSkillRatingsCoach}
            requests={requestsCoaches}
            cancelRequest={cancelRequest}
            questionText={questionText}
          />
        ),
      },
    ];

    return (
      <QuestionWrapper>
        {FEEDBACK_TYPES.map((type) => this.renderFeedbackType(type, { i18n }))}
      </QuestionWrapper>
    );
  };

  render() {
    const { skill, question, isReadOnly, i18n, type, open, toggleQuestion, review } = this.props;
    return (
      <Wrapper>
        <QuestionHeader onClick={() => toggleQuestion(skill ? skill.id : question.id)}>
          {type === QUESTION_TYPES.SKILL && (
            <NameWrap>
              <QuestionName>
                {isReadOnly
                  ? i18n._(t`How did this person perform on the skill: `)
                  : i18n._(t`How did you perform on the skill: `)}
                <SkillNameWrapper>
                  <SkillName skill={skill} />
                </SkillNameWrapper>
                ?
              </QuestionName>
              {!isEmpty(review.skillCategories) && !isEmpty(skill.categories) && (
                <SkillCategoriesLabels
                  skillCategories={skill.categories}
                  allCategories={review.skillCategories}
                />
              )}
            </NameWrap>
          )}
          {type === QUESTION_TYPES.CUSTOM && <QuestionName>{question.name}</QuestionName>}
          <BtnToggle>
            <SvgIcon width="24px" height="24px" url={open ? toggleOpenIcon : toggleCloseIcon} />
          </BtnToggle>
        </QuestionHeader>
        {open && this.renderFeedbacks()}
      </Wrapper>
    );
  }
}

export default withI18n()(QuestionReport);
