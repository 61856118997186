import React, { useEffect } from 'react';

import { initialize, pageview } from 'react-ga';

import getCurrentPath from '~/utils/getCurrentPath';
import history from '~/utils/history';

const GOOGLE_ANALYTICS_ID = 'UA-117617468-3';

function initAnalytics() {
  /* Initialize Google Analitics*/
  initialize(GOOGLE_ANALYTICS_ID, {
    // debug: true,
  });

  pageview(getCurrentPath());

  // update GA
  history.listen((_location, _action) => {
    pageview(getCurrentPath());
  });
}

const GoogleAnalytics = () => {
  useEffect(() => {
    initAnalytics();
  }, []);
  return <div className="google-analytic" />;
};

export default GoogleAnalytics;
