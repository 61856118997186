import React from 'react';

import { TASK_STATUS, USER_REVIEW_STATUS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isNil from 'lodash/isNil';
import moment from 'moment';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import Label from '~/components/Label';
import RickTextView from '~/components/RickTextView';
import Tooltip from '~/components/Tooltip';
import { UserAvatar } from '~/components/UserAvatar';

import { TaskStatusLabel } from './TaskStatusLabel';

import useBoolState from '~/hooks/useBoolState';
import { COLORS } from '~/styles';

import {
  Content,
  EvaluatorLabel,
  Footer,
  ListRow,
  RowLeftBlock,
  RowRightBlock,
  Settings,
  StyledIcon,
} from '../design';

import type { IUser, IUserReview, IUserReviewSignature } from '@learned/types';

const Placeholder = styled.div`
  font-size: 12px;
  color: ${COLORS.PLACEHOLDERS};
`;

const StyledDiv = styled(Label)`
  display: inline-flex;
  align-items: center;
  height: 15px;
  font-size: 12px;
`;

const Wrapper = styled.div<{ isOpened?: boolean }>`
  padding: 16px 26px 16px 0;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: ${COLORS.TRANSPARENT};

  &:hover {
    .icon {
      color: ${COLORS.COMPANY};
    }
  }

  ${({ isOpened }) =>
    isOpened
      ? css`
          border-color: ${COLORS.BORDERS};
        `
      : css`
          &:hover {
            background-color: ${COLORS.HOVER};
          }
        `}
`;

interface ListRowTaskProps {
  userId: IUser['id'];
  isGuest: boolean;
  isCoach: boolean;
  isCurrentUser: boolean;
  isAllGuestsSigned: boolean;
  signature?: IUserReviewSignature;
  reviewStatus: IUserReview['status'];
  isShowButton: boolean;
  onClick: () => void;
}

const ListRowSignature = ({
  userId,
  isGuest,
  isCoach,
  isCurrentUser,
  isAllGuestsSigned,
  signature,
  reviewStatus,
  onClick,
  isShowButton,
}: ListRowTaskProps) => {
  const { i18n } = useLingui();
  const isOpened = useBoolState(false);

  const SignButton = (
    <Button
      label={i18n._(t`Sign review`)}
      size={ButtonSize.MEDIUM}
      variant={ButtonVariant.PRIMARY}
      isLoading={false}
      onClick={onClick}
    />
  );

  const Signed = (
    <StyledDiv
      color={COLORS.STATUS_COMPLETED}
      backgroundColor={transparentize(0.8, COLORS.STATUS_COMPLETED)}
    >
      <StyledIcon icon={ICONS.CHECKMARK} size={ICON_SIZES.SMALL} color={COLORS.STATUS_COMPLETED} />
      {i18n._(t`Signed`)}
    </StyledDiv>
  );

  const getGuestSignatureStatus = () => {
    if (signature) {
      return Signed;
    }

    // if not signed but review archived -> show expired
    if (reviewStatus === USER_REVIEW_STATUS.ARCHIVED) {
      return <TaskStatusLabel status={TASK_STATUS.EXPIRED} />;
    }

    if (isCurrentUser) {
      return SignButton;
    }

    return <TaskStatusLabel status={TASK_STATUS.TODO} />;
  };

  const getEmployeeSignatureStatus = () => {
    if (signature) {
      const NotAgree = (
        <StyledDiv
          color={COLORS.STATUS_EXPIRED}
          backgroundColor={transparentize(0.8, COLORS.STATUS_EXPIRED)}
        >
          <StyledIcon icon={ICONS.EXPIRED} size={ICON_SIZES.SMALL} color={COLORS.STATUS_EXPIRED} />
          {i18n._(t`Not agreed`)}
        </StyledDiv>
      );
      return signature.isAgree ? Signed : NotAgree;
    }

    // if not signed but review archived -> show expired
    if (reviewStatus === USER_REVIEW_STATUS.ARCHIVED) {
      return <TaskStatusLabel status={TASK_STATUS.EXPIRED} />;
    }

    if (isCurrentUser && isShowButton) {
      return SignButton;
    }

    // if all guests signed
    if (isAllGuestsSigned) {
      return <TaskStatusLabel status={TASK_STATUS.TODO} />;
    }

    // no signatures
    return (
      <Tooltip tooltip={i18n._(t`the employee can sign the review if all guests have signed.`)}>
        <Placeholder>{i18n._(t`Waiting for guest…`)}</Placeholder>
      </Tooltip>
    );
  };

  const settings = [
    { title: i18n._(t`I have read the contents of this report.`), value: signature?.isSeen },
    { title: i18n._(t`I agree with the content of this report.`), value: signature?.isAgree },
  ];

  return (
    <Wrapper
      isOpened={isOpened.value}
      onClick={() => {
        if (signature) {
          isOpened.toggle();
        }
      }}
    >
      <ListRow>
        <RowRightBlock>
          <div className="icon">{signature && <Icon icon={ICONS.NEXT} />}</div>
          <UserAvatar userId={userId} options={{ showTooltip: true }} />
          {isCoach && (
            <EvaluatorLabel>
              (<Trans>Coach</Trans>)
            </EvaluatorLabel>
          )}
        </RowRightBlock>

        <RowLeftBlock>
          {isGuest ? getGuestSignatureStatus() : getEmployeeSignatureStatus()}
        </RowLeftBlock>
      </ListRow>
      {isOpened.value && (
        <>
          <Settings>
            {settings.map(({ title, value }) => (
              <>
                {!isNil(value) && (
                  <div className="section">
                    <div className="content">{title}</div>
                    <span className="status">
                      {value ? (
                        <div className="true">
                          <Icon icon={ICONS.CHECKMARK} size={ICON_SIZES.SMALL} />
                        </div>
                      ) : (
                        <div className="false">
                          <Trans>No</Trans>
                        </div>
                      )}
                    </span>
                  </div>
                )}
              </>
            ))}
          </Settings>
          <Content>
            <div>
              <div className="title">
                <Trans>Comment</Trans>
              </div>
              <RickTextView className="comment" html={signature?.comment} />
            </div>
            <Footer>
              <div className="section">
                <div className="title">
                  <Trans>Date signed</Trans>
                </div>
                <div className="date">
                  {moment(signature?.signatureDate).format('DD-MM-YYYY HH:mm')}
                </div>
              </div>
              <div className="section">
                <div className="title">
                  <Trans>Signature</Trans>
                </div>
                <div className="signature">{signature?.signature}</div>
              </div>
            </Footer>
          </Content>
        </>
      )}
    </Wrapper>
  );
};

export { ListRowSignature };
