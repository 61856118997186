import React, { memo } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import HeadingNavigation from '~/components/HeadingNavigation';
import { PageDescription, PageTitle } from '~/components/Page';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

const ItemPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const MainContainer = styled.div`
  width: ${(props) => props.$maxWidth || '1070px'};
  max-width: ${(props) => props.$maxWidth || '1070px'};
  margin-top: ${(props) => (props.$noTopMargin ? '0' : '16px')};
  position: relative;
`;

const TitleContainer = styled(MainContainer)`
  margin: 20px 0 -20px;
`;

const SetupLayout = ({
  children,
  hideHeader,
  title,
  onClose,
  actions,
  className,
  width,
  loading,
  logoUrl,
  noTopMargin = false,
  headerInstructions,
  headerInstructionsUrl,
  pageTitle,
  pageDescription,
}) => (
  <>
    {!hideHeader && (
      <HeadingNavigation
        instructions={headerInstructions}
        instructionsUrl={headerInstructionsUrl}
        label={title}
        onBack={onClose}
        actions={actions}
        logoUrl={logoUrl}
        maxWidth={width}
        noPadding
      />
    )}
    {(pageTitle || pageDescription) && (
      <ItemPage>
        <TitleContainer>
          {pageTitle && <PageTitle>{pageTitle}</PageTitle>}
          {pageDescription && <PageDescription>{pageDescription}</PageDescription>}
        </TitleContainer>
      </ItemPage>
    )}

    <ItemPage className={className}>
      <MainContainer $noTopMargin={noTopMargin} $maxWidth={width}>
        <ShowSpinnerIfLoading loading={loading}>{children}</ShowSpinnerIfLoading>
      </MainContainer>
    </ItemPage>
  </>
);

SetupLayout.propTypes = {
  pageTitle: PropTypes.string,
  pageDescription: PropTypes.string,
};

export default memo(SetupLayout);
