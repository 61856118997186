import React, { Dispatch, SetStateAction } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';

import DatePicker from '~/components/DatePickerDeprecated';
import Placeholder from '~/components/Placeholder';
import RTFeedbackCard from '~/pages/RTFeedbacks/components/RTFeedbackCard';

import { FilterContainer, FilterWrapper } from './design';

import type { RTFeedback } from '@learned/types';

const RTFeedbackBlock = ({
  feedbacks,
  startFilterDate,
  setStartFilterDate,
  endFilterDate,
  setEndFilterDate,
}: {
  feedbacks: RTFeedback[];
  startFilterDate: string;
  setStartFilterDate: Dispatch<SetStateAction<string>>;
  endFilterDate: string;
  setEndFilterDate: Dispatch<SetStateAction<string>>;
}) => {
  const { i18n } = useLingui();
  return (
    <>
      <FilterWrapper>
        <FilterContainer>
          <span>
            <Trans>From</Trans>
          </span>
          <DatePicker
            // @ts-ignore
            width={125}
            selected={startFilterDate}
            onChange={setStartFilterDate}
            popperPlacement="bottom-start"
            isHideIcon
          />
        </FilterContainer>
        <FilterContainer>
          <span>
            <Trans>to</Trans>
          </span>
          <DatePicker
            // @ts-ignore
            width={125}
            selected={endFilterDate}
            onChange={setEndFilterDate}
            popperPlacement="bottom-start"
            isHideIcon
          />
        </FilterContainer>
      </FilterWrapper>
      {!isEmpty(feedbacks) ? (
        feedbacks.map((RTFeedback) => (
          // @ts-ignore
          <RTFeedbackCard
            key={RTFeedback.id}
            RTFeedback={RTFeedback}
            isHideMenu
            isHideGoal
            isNoCardPadding
          />
        ))
      ) : (
        <Placeholder
          title={i18n._(t`No feedback available`)}
          subTitle={i18n._(t`The feedback that you receive or give will show here`)}
        />
      )}
    </>
  );
};

export { RTFeedbackBlock };
