import styled from 'styled-components';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
`;

const DashboardWrapper = styled.div`
  display: flex;
  width: 1001px;
`;

const LeftColumn = styled.div`
  width: 100%;
  max-width: 760px;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 218px;
  margin-left: 23px;
`;

const SectionWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const HeaderWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  width: fit-content;
  border-radius: 6px 6px 0 0;
`;

const Title = styled.div`
  margin-bottom: 14px;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.24px;
  color: ${COLORS.TEXT_HOVER};
`;

export { Title, HeaderWrapper, SectionWrapper, RightColumn, LeftColumn, DashboardWrapper, Wrapper };
