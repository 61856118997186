import { SURVEY_QUESTION_TYPES } from '@learned/constants';

import type { IQuestionForm } from '~/@types/question';

import type { ISurveyRating } from '@learned/types';

function addValuesFromRatingsToQuestionForm(
  rating: Partial<Pick<ISurveyRating, 'answer' | 'comment'>>,
  questionForm: IQuestionForm,
): IQuestionForm {
  if (rating.comment !== undefined) {
    questionForm = { ...questionForm, comment: rating.comment };
  }

  switch (questionForm.type) {
    case SURVEY_QUESTION_TYPES.TEXT:
      return { ...questionForm, answer: rating.answer as string };
    case SURVEY_QUESTION_TYPES.SINGLE:
    case SURVEY_QUESTION_TYPES.TRAFFIC:
    case SURVEY_QUESTION_TYPES.SMILEYS: {
      const options = questionForm.options.map((option, index) => {
        return { ...option, value: index === rating.answer };
      });
      return { ...questionForm, options };
    }
    case SURVEY_QUESTION_TYPES.MULTIPLE: {
      const options = questionForm.options.map((option, index) => {
        return { ...option, value: ((rating.answer ?? []) as number[]).includes(index) };
      });
      return { ...questionForm, options };
    }
    case SURVEY_QUESTION_TYPES.STARS: {
      const options = questionForm.options.map((option, index) => {
        return { ...option, value: index <= (rating.answer ?? -1) };
      });
      return { ...questionForm, options };
    }
  }

  return questionForm;
}

export { addValuesFromRatingsToQuestionForm };
