import { GOAL_PROGRESS_TYPES } from '@learned/constants';
import { createAction } from 'redux-act';

import * as goalsServices from '~/services/goals';
import * as userActivitiesServices from '~/services/userActivities';
import { calculateProgressPercentage } from '~/utils/activityUtils';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

export const resetCurrentGoal = createAction('reset current goal');
export const setCurrentGoal = createAction('set current goal');
export const updateCurrentGoal = createAction('update current goal');
export const updateCurrentGoalActivity = createAction('update current goal activity');
export const addCurrentGoalActivity = createAction('add current goal activity');
export const removeCurrentGoalActivity = createAction('remove current goal activity');

export function syncGoalActivitiesDeadline(oldDeadline = '') {
  return function (dispatch, getStore) {
    const goal = getStore().currentGoal;

    const deadline = convertToTimeString(oldDeadline, TIME_FORMATS.ISO);

    if (goal.deadline) {
      (goal.activities || []).forEach(async (a) => {
        if (!a.deadline || a.deadline === deadline) {
          await dispatch(updateCurrentGoalActivity({ id: a.id, deadline: goal.deadline }));
        }
      });
    }
  };
}

export function fetchGoal(goalId) {
  return async function (dispatch) {
    if (goalId) {
      const goal = await goalsServices.getGoal(
        goalId,
        ['skills', 'activities', 'goalCycles', 'teams'],
        {
          isFillParent: true,
          childrenMaxOrder: 0,
          isGetProgress: true,
          isParentTeamPopulate: true,
        },
      );

      dispatch(setCurrentGoal(goal));
    }
  };
}

export function updateGoal(data) {
  return function (dispatch, getState) {
    const id = getState().currentGoal.id;
    dispatch(updateCurrentGoal(data));
    goalsServices.updateGoal(id, data);
  };
}

export function updateGoalPublished(data) {
  return async function (dispatch, getState) {
    const id = getState().currentGoal.id;
    await goalsServices.updateGoalPublished(id, data);
    dispatch(fetchGoal(id));
  };
}

export function updateGoalProgress(data) {
  return async function (dispatch, getState) {
    const id = getState().currentGoal.id;
    await goalsServices.updateGoalProgress(id, data);
    dispatch(fetchGoal(id));
  };
}

export function updateGoalArchiveStatus(shouldArchive) {
  return async function (dispatch, getState) {
    const id = getState().currentGoal.id;
    await goalsServices.updateGoalArchiveStatus(id, shouldArchive);
    dispatch(fetchGoal(id));
  };
}

export function updateActivity(id, data, isUpdateProgress = false) {
  return async function (dispatch, getState) {
    const response = await userActivitiesServices.updateUserActivityProgress(id, data);
    const currGoal = getState().currentGoal;
    if (currGoal?.progressType === GOAL_PROGRESS_TYPES.AVG) {
      dispatch(fetchGoal(currGoal.id));
    } else {
      if (isUpdateProgress) {
        data.calculatedProgress = calculateProgressPercentage(response?.data?.userActivity);
      }
      dispatch(updateCurrentGoalActivity({ id, ...data }));
    }
  };
}

// new Goal v3
export const setCurrentGoalType = createAction('set current goal type');
export const setCurrentGoalCategory = createAction('set current goal category');
export const setCurrentGoalTeams = createAction('set current goal team');
export const setCurrentGoalOwners = createAction('set current goal owners');
export const setCurrentGoalCreators = createAction('set current goal creators');
export const setCurrentGoalViewers = createAction('set current goal viewers');
export const setCurrentGoalName = createAction('set current goal name');
export const setCurrentGoalDescription = createAction('set current goal description');
export const setCurrentGoalProgressType = createAction('set current goal progress type');
export const setCurrentGoalIsChildProgress = createAction('set current goal is child progress');
export const setCurrentGoalProgressDetails = createAction('set current goal progress details');
export const setCurrentGoalDeadline = createAction('set current goal deadline');
export const setCurrentGoalCycles = createAction('set current goal cycles');
export const setCurrentGoalParent = createAction('set current goal parent');
export const setCurrentGoalActivities = createAction('set current goal activities');
export const setCurrentGoalSkills = createAction('set current goal skill');
export const setCurrentGoalIsOwnerCanEdit = createAction('set current goal isOwnerCanEdit');
export const setCurrentGoalIsCoachesOfOwnerCanEdit = createAction(
  'set current goal isCoachesOfOwnerCanEdit',
);
export const setCurrentGoalErrors = createAction('set current goal errors');
export const setIsAddCurrentGoalActivities = createAction('set is add current goal activities');
