import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import size from 'lodash/size';
import styled from 'styled-components';

import Tabs from '~/components/Tabs';
import { SearchField } from '~/components/Text';

import getIntegrationToolName from '~/utils/getIntegrationToolName';

const TabsWrapper = styled.div`
  width: 100%;
`;

const SearchFieldWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: 24px;
`;

const ContentWrapper = styled.div``;

function TabsForIntegrations({ i18n, integrations, pathToItems, children } = {}) {
  const [search, setSearch] = useState('');

  const tabItems = (integrations || []).map((integration) => {
    const count = size(get(integration, `${pathToItems || 'result'}`, []));

    return {
      key: integration.id,
      label: () => `${integration.name} - ${getIntegrationToolName(integration)} (${count})`,
    };
  });

  const [activeTab, setActiveTab] = useState(tabItems[0].key);

  // add props to children
  const childrenWithProps = React.Children.map(children, (child) => {
    // checking isValidElement is the safe way and avoids a typescript error too
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        search,
        integration: integrations.find((integration) => integration.id === activeTab),
      });
    }
    return child;
  });

  return (
    <>
      <TabsWrapper>
        <Tabs
          currentItem={activeTab}
          items={tabItems}
          handleChangeTab={setActiveTab}
          isSmall
          actions={
            <SearchFieldWrapper>
              <SearchField
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={i18n._(t`Search`)}
                style={{ borderRadius: '6px', maxHeight: '32px', fontSize: '14px' }}
              />
            </SearchFieldWrapper>
          }
        />
      </TabsWrapper>
      <ContentWrapper>{childrenWithProps}</ContentWrapper>
    </>
  );
}

export default withI18n()(TabsForIntegrations);
