import React, { FunctionComponent } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { find } from 'lodash';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import ActivityIcon from '~/assets/activity-inactive.svg';
import PathIcon from '~/assets/path-symbol-inactive.svg';

import { ACTIVITY_TYPES } from '~/constants';
import { COLOR_PALETTE, COLOR_UNIT } from '~/styles';

const Label = styled.div<{ color: string }>`
  width: 112px;
  height: 26px;
  border-radius: 3px;
  text-align: center;
  font-size: 12px;
  background-color: ${(props) => props.color};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const Text = styled.div`
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

interface ILearningItemType {
  isActivity: boolean;
  type: string | null;
}

const LearningItemType: FunctionComponent<ILearningItemType> = ({ isActivity, type }) => {
  const { i18n } = useLingui();

  const typeLabel = (
    find(ACTIVITY_TYPES, (a) => a.key === type) || ACTIVITY_TYPES.OTHER
  ).translated(i18n);

  return (
    <Label color={isActivity ? COLOR_UNIT.ACTIVITY.primary : COLOR_UNIT.PATH.primary}>
      {isActivity ? (
        <SvgIcon
          width="10px"
          height="10px"
          url={ActivityIcon}
          isDefaultColor
          defaultColor={COLOR_PALETTE.DARK_GRAY}
        />
      ) : (
        <SvgIcon
          width="21px"
          height="11px"
          url={PathIcon}
          isDefaultColor
          defaultColor={COLOR_PALETTE.DARK_GRAY}
        />
      )}
      <Text>{isActivity ? typeLabel : i18n._(t`Path`)}</Text>
    </Label>
  );
};

export { LearningItemType };
