import styled from 'styled-components';

import { Button } from '~/components/Buttons';

import { COLORS } from '~/styles';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CloseButton = styled(Button)`
  height: 20px;
  width: 20px;
`;

export const Title = styled.div`
  font-size: 12px;
  line-height: 2.17;
  letter-spacing: -0.13px;
  color: ${COLORS.LABEL_GRAY};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 19px 0 24px;
`;

export const QuestionTitle = styled.span`
  font-size: 22px;
  font-weight: 600;
  line-height: 1.18;
  letter-spacing: -0.24px;
  color: ${COLORS.TEXT_MAIN};
`;

export const QuestionDescription = styled.span`
  p,
  span,
  div {
    font-family: Poppins;
    font-size: 14px;
    margin-top: 5px;
    line-height: 1.71;
    letter-spacing: -0.16px;
    color: ${COLORS.LABEL_GRAY};
  }
`;

export const QuestionWrapper = styled.div`
  margin-top: 18px;

  & > div {
    width: 100%;
  }
`;

export const Footer = styled.div`
  justify-content: flex-end;
  display: flex;
  gap: 10px;
`;
