import { TABS_ENUM } from '~/pages/PassportPage';

import routes from '~/constants/routes';

export default (userId, query = {}, hash = false) => {
  return routes.USER_PUBLIC_SKILL_PASSPORT.build(
    {},
    { userId, isBackPath: true, query, hash: hash || TABS_ENUM.NOTES },
  );
};
