import React, { Dispatch } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import size from 'lodash/size';
import { Controller, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';

import Editor from '~/components/Editor';
import { Input } from '~/components/Input';
import { Errors } from '~/pages/SurveyTemplateUpdate/validation';
import { StepFooter } from '~/pages/SurveyUpdate/components/StepFooter';

import type { useAutoSaveFunc } from '~/hooks/useAutoSave';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';
import { COLORS, COLOR_PALETTE } from '~/styles';

import type { IGeneralForm } from '../../types';

export interface IGeneralProps {
  formMethods: UseFormReturn<IGeneralForm>;
  setCurrentSection: Dispatch<number>;
  languageState: ILanguageStateReturn;
  autosave: useAutoSaveFunc;
}

const Form = styled.form`
  border-radius: 10px;
  width: 750px;
  background-color: ${COLOR_PALETTE.WHITE};
  padding: 32px 40px;
`;

const Title = styled.h2`
  font-size: 26px;
  color: ${COLOR_PALETTE.BLACK};
  font-weight: normal;
  margin-top: 0;
`;

const Label = styled.label`
  font-size: 14px;
  color: ${COLORS.TEXT_BLACK};
  padding-bottom: 4px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 26px;
`;

const StepGeneral = ({
  setCurrentSection,
  formMethods,
  languageState,
  autosave,
}: IGeneralProps) => {
  const { i18n } = useLingui();
  const {
    register,
    unregister,
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = formMethods;

  const nameFieldArray = useMultiLangFieldArray({
    name: 'name',
    control,
    unregister,
    languageState,
  });
  const descriptionFieldArray = useMultiLangFieldArray({
    name: 'description',
    control,
    unregister,
    languageState,
  });

  const onSubmit = () => setCurrentSection(2);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Title>
        <Trans>General</Trans>
      </Title>
      <Label>
        <Trans>Name</Trans>
      </Label>
      <InputContainer>
        {nameFieldArray.fields.map((field) => (
          <Controller
            key={field.id}
            {...register(`name.${field.index}.value`)}
            control={control}
            render={({ field: { onBlur, onChange, value } }) => {
              const primaryLangError =
                errors.name?.message === Errors.missingPrimaryLang &&
                field.locale === languageState.companyPrimaryLanguage.locale;
              return (
                <Input
                  key={field.id}
                  value={value}
                  onBlur={async () => {
                    onBlur();
                    autosave.flush(); // immediately run autosave, if there are any changes
                    await trigger('name');
                  }}
                  onChange={async (...args) => {
                    onChange(...args);
                    autosave.run(field.id);
                    await trigger('name');
                  }}
                  error={primaryLangError ? i18n._(t`This field is required`) : undefined}
                  placeholder={i18n._(t`Example: Annual satisfaction survey`)}
                  width="281px"
                  leftIcon={
                    size(languageState.languages) > 1
                      ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                      : undefined
                  }
                  maxLength={60}
                />
              );
            }}
          />
        ))}
      </InputContainer>
      <DescriptionWrapper>
        <Label>
          <Trans>Description</Trans>
        </Label>
        <InputContainer>
          {descriptionFieldArray.fields.map((field) => (
            <Controller
              key={field.id}
              {...register(`description.${field.index}.value`)}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Editor
                  minHeight={'250px'}
                  placeholder={i18n._(t`Describe the survey`)}
                  value={value}
                  compact
                  // @ts-ignore
                  onChange={(...args) => {
                    onChange(...args);
                    autosave.run(field.id);
                  }}
                  onBlur={() => {
                    onBlur();
                    autosave.flush(); // immediately run autosave, if there are any changes
                  }}
                  big
                  leftIcon={
                    size(languageState.languages) > 1
                      ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                      : undefined
                  }
                />
              )}
            />
          ))}
        </InputContainer>
      </DescriptionWrapper>
      <StepFooter onPrev={() => setCurrentSection(0)} />
    </Form>
  );
};

export { StepGeneral };
