import React, { useRef } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Icon, ICONS } from '~/components/Icon';

import {
  AddButton,
  AttachmentTitleRow,
  CloseIconButton,
  FieldGroup,
  FileContainer,
  FileListWrapper,
  FileName,
} from './design';

import { COLORS } from '~/styles';

import type { Attachment } from '@learned/types';

type Props = {
  files: Array<Attachment>;
  onDownload: (file: Attachment) => void;
  onDelete: (filedId: string) => void;
  isFileUploading: boolean;
  onUpload: (file: File) => void;
};

const Attachments = ({ files, onDownload, onDelete, onUpload }: Props) => {
  const { i18n } = useLingui();
  const input = useRef<HTMLInputElement | null>(null);

  const onUploadChange = () => {
    if (input && input.current && input.current.files) {
      const file = input.current.files[0] || null;
      onUpload(file);
    }
  };

  const handleSelectFiles = () => {
    input.current?.click();
  };

  return (
    <FieldGroup>
      <AttachmentTitleRow>
        <Trans>Attachments</Trans>
        <AddButton
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          type="button"
          label={i18n._(t`Attachment`)}
          onClick={handleSelectFiles}
        />
        <input
          id="uploadNewFile"
          ref={input}
          onChange={onUploadChange}
          type="file"
          style={{ display: 'none' }}
        />
      </AttachmentTitleRow>
      <FileListWrapper>
        {files &&
          files.map((file) => {
            return (
              <FileContainer key={file.id}>
                <FileName onClick={() => onDownload(file)}>{file.name}</FileName>
                <CloseIconButton onClick={() => onDelete(file.id)}>
                  <Icon
                    width="16px"
                    height="16px"
                    icon={ICONS.DELETE_BIN}
                    color={COLORS.ACCENT_WARNING}
                  />
                </CloseIconButton>
              </FileContainer>
            );
          })}
      </FileListWrapper>
    </FieldGroup>
  );
};

export { Attachments };
