import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import brightCape from '~/assets/images/bright_cape.png';
import proxsys from '~/assets/images/proxsys.png';
import tensing from '~/assets/images/tensing.png';
import ultimo from '~/assets/images/ultimo.png';
import logo from '~/assets/images/welcome.png';

import { COLORS } from '~/styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35%;
  max-width: 590px;
  padding: 40px;
  background-image: linear-gradient(33deg, #8f54ee 13%, #d98ef5 85%);
  color: ${COLORS.WHITE};
`;

const Text = styled.div`
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
`;

const CompaniesLogosWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const Block = styled.div`
  margin-bottom: 48px;
`;

const companiesLogos = [
  {
    title: 'Ultimo',
    logo: ultimo,
    height: 14,
  },
  {
    title: 'PROXSYS',
    logo: proxsys,
    height: 17,
  },
  {
    title: 'tensing',
    logo: tensing,
    height: 39,
  },
  {
    title: 'BRIGHT CAPE',
    logo: brightCape,
    height: 50,
  },
];

const InfoSection = () => {
  const { i18n } = useLingui();

  return (
    <Container>
      <Block>
        <img src={logo} alt={i18n._(t`Dashboard`)} width={417} height={332} />
      </Block>
      <Block>
        <Text>
          <Trans>Trusted by 200+ Companies</Trans>
        </Text>
        <CompaniesLogosWrapper>
          {companiesLogos.map(({ title, logo, height }) => (
            <img key={title} src={logo} alt={title} height={height} />
          ))}
        </CompaniesLogosWrapper>
      </Block>
    </Container>
  );
};

export { InfoSection };
