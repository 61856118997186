import React from 'react';

import { REVIEW_QUESTION_EVALUATORS } from '@learned/constants';
import { Trans } from '@lingui/macro';

import { ICONS } from '~/components/Icon';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';

import { NominatePeersSection } from './components/NominatePeersSection';
import { StartEndSection } from './components/StartEndSection';
import { TasksSection } from './components/TasksSection';

import { ILanguageStateReturn } from '~/hooks/useLanguageState';

import { Form, Title } from '../../design';
import { StepFooter } from '../StepFooter';

import type { IReviewCycleForm } from '../../types';
import type { UseFormReturn } from 'react-hook-form';

interface TimelineSectionProps {
  formMethods: UseFormReturn<IReviewCycleForm>;
  sectionState: ISectionState;
  languageState: ILanguageStateReturn;
}

const TimelineSection = ({ formMethods, sectionState, languageState }: TimelineSectionProps) => {
  const { watch } = formMethods;
  const evaluators = watch('evaluators').map(
    (evaluator: { value: REVIEW_QUESTION_EVALUATORS; icon: ICONS; title: string }) =>
      evaluator.value,
  );
  return (
    <Form>
      <Title>
        <Trans>Timeline</Trans>
      </Title>
      <StartEndSection formMethods={formMethods} />
      {evaluators.includes(REVIEW_QUESTION_EVALUATORS.PEER) && (
        <NominatePeersSection formMethods={formMethods} languageState={languageState} />
      )}
      <TasksSection formMethods={formMethods} evaluators={evaluators} />
      <StepFooter
        onNext={() => sectionState.setCurrentSection(++sectionState.currentSection)}
        onPrev={() => sectionState.setCurrentSection(--sectionState.currentSection)}
      />
    </Form>
  );
};

export { TimelineSection };
