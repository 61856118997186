import styled from 'styled-components';

import { StyledPrimaryButton } from '~/components/Buttons/design';
import { SearchField } from '~/components/Text';

import { COLORS } from '~/styles';

export const Container = styled.div`
  padding: 20px 32px 0;
`;

export const Title = styled.div`
  margin: auto;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 48px;
  color: ${COLORS.TEXT_HOVER};
  max-width: 1270px;
`;

export const OverviewContainer = styled.div`
  box-sizing: border-box;
  margin: auto;
  background: ${COLORS.WHITE};
  padding: 24px 32px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 1270px;
  box-shadow: ${COLORS.BOX_SHADOW_COLOR}33 0px 8px 15px 0px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: inherit;
`;

export const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  margin-right: 12px;
  width: 350px;

  & > input {
    border-radius: 16px;
  }
`;

export const HeaderActionWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const TextButton = styled.div`
  width: 174px;
  height: 20px;
  font-size: 14px;
  text-align: center;
  margin-top: 7px;
  color: ${COLORS.COMPANY};

  &:hover {
    cursor: pointer;
    color: ${COLORS.COMPANY_HOVER};
  }
`;

export const AddButton = styled(StyledPrimaryButton)`
  width: 142px;
  height: 32px;
  padding: 8px 21px 7px 22px;
  border-radius: 16px;
  box-shadow: 0 2px 8px 0 rgba(145, 157, 165, 0.12);
  background-color: ${COLORS.COMPANY};
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;

  & > span {
    font-size: 16px;
    line-height: 1.63;
    letter-spacing: -0.18px;
    color: ${COLORS.TEXT_MAIN};
  }
`;

export const NameRow = styled.div<{ isCentered?: boolean; padding?: string }>`
  background-color: inherit;
  padding: ${({ padding }) => padding || 'unset'};
`;

export const TD = styled.div<{
  minWidth?: string;
  maxWidth?: string;
  padding?: string;
  $isPointer?: boolean;
}>`
  min-width: ${({ minWidth }) => minWidth || '120px'};
  width: ${({ maxWidth }) => maxWidth || 'unset'};
  max-width: ${({ maxWidth }) => maxWidth || 'unset'};
  padding: ${({ padding }) => padding || '0'};
  color: ${COLORS.TEXT_HOVER};
  cursor: ${({ $isPointer }) => ($isPointer ? 'pointer' : 'default')};
`;

export const IconImage = styled.img<{ src: string }>`
  width: 40px;
  height: 40px;
  margin: 0 21px 0 0;
  border-radius: 6px;
  object-fit: contain;
`;

export const IconPlaceholder = styled.div<{ src?: string }>`
  width: 40px;
  height: 40px;
  margin: 0 21px 0 0;
  border-radius: 6px;
  background-color: ${COLORS.PROGRESSBAR_BACKGROUND};
`;
