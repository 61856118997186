import React from 'react';

import { IMultiLangString } from '@learned/types';
import { Trans } from '@lingui/macro';

import { ExpectedLevelTitle, FAContainer, FocusAreaLabel, LevelViewButton } from '../design';

interface IProps {
  level?: {
    label: IMultiLangString;
    focusAreas: {
      label: IMultiLangString;
    }[];
  };
  isModal?: boolean;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
  openFocusAreaModal?: () => void;
}

export const FocusAreaSection = ({
  isModal,
  level,
  useMultiLangString,
  openFocusAreaModal,
}: IProps) => {
  const getMultiLangString = useMultiLangString();
  return (
    <FAContainer margin={isModal ? 50 : 10}>
      <ExpectedLevelTitle>
        <Trans>{getMultiLangString(level?.label || '')}</Trans>
      </ExpectedLevelTitle>
      {level?.focusAreas?.map((focusArea, index) => {
        return (
          <FocusAreaLabel key={index}>
            {`- ${getMultiLangString(focusArea.label || '')}`}
          </FocusAreaLabel>
        );
      })}
      {openFocusAreaModal && (
        <LevelViewButton onClick={openFocusAreaModal}>
          <Trans>View all levels</Trans>
        </LevelViewButton>
      )}
    </FAContainer>
  );
};
