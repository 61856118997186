import { SKILL_SORTING } from '@learned/constants';
import FileSaver from 'file-saver';
import isEmpty from 'lodash/isEmpty';

import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getSkill(id, join = []) {
  return cloudRequest(serverRoutes.skills.getSkill, { id, join });
}

/**
 *
 * @param [filters]
 * @param [filters.limit] - pagination limit
 * @param [filters.skip] - pagination offset
 * @param [filters.search] - search skills by name
 * @param [filters.categories] - category ids to filter on
 * @param [filters.sortBy] - sorting option
 * @param [filters.published] - draft or not
 * @returns {Promise<AxiosResponse<object>>}
 */
export function getSkills(
  filters = {
    limit: 0,
    skip: 0,
    search: '',
    categories: [],
    sortBy: SKILL_SORTING.NAME_A_Z,
  },
) {
  return cloudRequest(serverRoutes.skills.getSkills, filters);
}

export function createSkill(skillData, jobProfileIds) {
  return cloudRequest(
    serverRoutes.skills.createSkill,
    {},
    { skill: skillData, jobProfiles: jobProfileIds },
  );
}

export function updateSkill(skillData, jobProfileIds) {
  return cloudRequest(
    serverRoutes.skills.updateSkill,
    { id: skillData.id },
    { jobProfiles: jobProfileIds, skill: skillData },
  );
}

export function deleteSkills(ids) {
  return cloudRequest(serverRoutes.skills.deleteSkills, {}, { skillIds: ids });
}

export function getSkillsById(skillsIds = [], join) {
  return cloudRequest(serverRoutes.skills.getSkillsById, { skills: skillsIds.join(','), join });
}

/**
 *
 * @param skillId - string
 * @param members - string[]
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSkillReport(skillId, { reviewId, isLatest, members = [], pagination = {} }) {
  return cloudRequest(serverRoutes.skills.getSkillReport(skillId), {
    skillId,
    reviewId,
    ...(!isEmpty(members) && { members: JSON.stringify(members) }),
    pagination,
    isLatest,
  });
}

export function downloadSkillReport(skillId, { members = [], isLatest, reviewId }) {
  return cloudRequest(serverRoutes.skills.downloadSkillReportCSV, {
    skillId,
    isLatest,
    ...(!isLatest && { reviewId }),
    ...(!isEmpty(members) && { members: JSON.stringify(members) }),
  }).then((response) => {
    FileSaver.saveAs(response, 'skill_rating_per_member_report.csv');
  });
}

export function downloadSkillsReportCSV({
  isLatest,
  reviewId,
  sectionTypes,
  skills = [],
  members = [],
}) {
  return cloudRequest(serverRoutes.skills.downloadSkillsReportCSV, {
    isLatest,
    reviewId,
    sectionTypes,
    ...(!isEmpty(skills) && { skills: JSON.stringify(skills) }),
    ...(!isEmpty(members) && { members: JSON.stringify(members) }),
  }).then((response) => {
    FileSaver.saveAs(response, 'skill_rating_per_skill_report.csv');
  });
}

export function getSkillsReport({
  pagination = {},
  sectionTypes,
  skills = [],
  members = [],
  reviewId,
  isLatest,
} = {}) {
  return cloudRequest(serverRoutes.skills.getSkillsReport, {
    pagination: JSON.stringify(pagination),
    sectionTypes: sectionTypes ? JSON.stringify(sectionTypes) : null,
    ...(!isEmpty(members) && { members: JSON.stringify(members) }),
    ...(!isEmpty(skills) && { skills: JSON.stringify(skills) }),
    reviewId,
    isLatest,
  });
}

export function downloadSkillsCSV(filters = { search: '', categories: [] }) {
  return cloudRequest(serverRoutes.skills.downloadSkillsCSV, filters).then((response) => {
    FileSaver.saveAs(response, 'skills.csv');
  });
}

/*
  Generate a Job Profile data
  body: { name: string , locales: Locals_all[], skillCategory: string }
 */
export function generateSkill(body) {
  return cloudRequest(serverRoutes.skills.generateSkill, {}, body);
}
