import React, { useState } from 'react';

import { IGoal } from '@learned/types';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';

import { Banner } from '~/components/Banner';
import { BANNER_TYPES } from '~/components/Banner/types';
import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { FeedbackCard } from '~/components/GoalDashboardCard/components/FeedbackCard';
import { GoalGeneralBlock } from '~/components/GoalDashboardCard/components/GoalGeneralBlock';
import { GoalProgressCard } from '~/components/GoalDashboardCard/components/GoalsProgressCard';
import { SettingsCard } from '~/components/GoalDashboardCard/components/SettingsCard';
import { UpdatesCard } from '~/components/GoalDashboardCard/components/UpdatesCard';
import { HeaderWrapper } from '~/components/GoalDashboardCard/design';
import { HeaderTabs } from '~/components/HeaderTabs';
import Modal from '~/components/Modal';

import { Card, Content, Footer, Row } from './design';
import { Header } from './Header';

import useBoolState from '~/hooks/useBoolState';
import { getUser } from '~/selectors/baseGetters';

interface IProps {
  goal: IGoal;
  handleDelete: (goal: IGoal) => void;
  handleArchive: (goal: IGoal) => void;
  handleEdit?: (goal: IGoal) => void;
  onClose: () => void;
  isOriginalMode?: boolean;
}

export const TABS_ENUM = {
  SETTINGS: 'settings',
  UPDATES: 'updates',
  FEEDBACK: 'feedback',
};

export const GoalDashboardModal = ({
  goal,
  handleDelete,
  handleArchive,
  handleEdit,
  onClose,
  isOriginalMode = false,
}: IProps) => {
  const { i18n } = useLingui();
  const currentUser = useSelector(getUser);
  const [currentTab, setCurrentTab] = useState(TABS_ENUM.SETTINGS);
  const $isUpdateSidebar = useBoolState(false);
  const TABS = [
    {
      label: (i18n: I18n) => i18n._(t`Settings`),
      key: TABS_ENUM.SETTINGS,
    },
    {
      label: (i18n: I18n) => i18n._(t`Updates & Notes`),
      key: TABS_ENUM.UPDATES,
    },
    {
      label: (i18n: I18n) => i18n._(t`Feedback`),
      key: TABS_ENUM.FEEDBACK,
    },
  ].filter((t) => t);

  const updateSidebar = async (value: any) => {
    if (value) {
      $isUpdateSidebar.on();
    } else {
      $isUpdateSidebar.off();
    }
  };

  return goal ? (
    <Modal
      width={750}
      contentStyles={{ padding: '0' }}
      hideFooter
      isHideHeader
      centerModal
      showDivider={false}
      borderRadius={10}
    >
      <Header
        onClose={onClose}
        handleDelete={() => handleDelete(goal)}
        handleEdit={handleEdit ? () => handleEdit(goal) : undefined}
        handleArchive={() => handleArchive(goal)}
        isOriginalMode={isOriginalMode}
      />
      <Content>
        {!isOriginalMode && (
          <Row>
            <Banner
              type={BANNER_TYPES.INFO}
              subTitle={i18n._(t`To make changes to this goal, edit this question in the report.`)}
            />
          </Row>
        )}
        <GoalGeneralBlock
          goal={goal}
          isOwner={!!goal.owners?.includes(currentUser?.id)}
          noTopMargin={true}
          hideTitleOverflow
        />
        <GoalProgressCard goal={goal} disableUpdate={!handleEdit} />
        <Card>
          <HeaderWrapper>
            <HeaderTabs tabs={TABS} selectedTab={currentTab} handleChangeTab={setCurrentTab} />
          </HeaderWrapper>
          {currentTab === TABS_ENUM.SETTINGS && <SettingsCard goal={goal} />}
          {currentTab === TABS_ENUM.UPDATES && (
            <UpdatesCard
              goal={goal}
              isUpdateSidebar={$isUpdateSidebar.value}
              updateSidebar={updateSidebar}
            />
          )}
          {currentTab === TABS_ENUM.FEEDBACK && <FeedbackCard isOwner goal={goal} />}
        </Card>
      </Content>
      <Footer>
        <Button
          label={i18n._(t`Cancel`)}
          onClick={onClose}
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
        />
      </Footer>
    </Modal>
  ) : (
    <></>
  );
};
