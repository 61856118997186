import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { ILanguage, IMultiLangString } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { isObject } from 'lodash';
import { SketchPicker } from 'react-color';
import { useSelector } from 'react-redux';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Button } from '~/components/Buttons/Button';
import { ICONS, Icon } from '~/components/Icon';
import { Input } from '~/components/Input';

import {
  AddColorBtn,
  BodyArea,
  ButtonContainer,
  ColorContainer,
  ColorItem,
  ColorItemContainer,
  ColorTitle,
  DeleteBtn,
  Header,
  IconContainer,
  InputContainer,
  SecondaryBtnContainer,
  SecondaryTitle,
  SketchActionContainer,
  SketchActionHeader,
  SketchPickerWrapper,
  Subtitle,
  Title,
  Wrapper,
} from './CommonJobModal.design';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { COLORS } from '~/styles';

import PopOver, { PopOverPlacement } from '../components/PopOver';
import { MODEL_TYPE } from '../constants';

import type { TMeta, TOtherData } from '../types';

type TProps = {
  title: string;
  subtitle?: string;
  onCancel: () => void;
  inputText: string;
  closeBtnAvailable?: boolean;
  selectedValues?: IMultiLangString | null;
  isBigger?: boolean;
  isColorAvailable?: boolean;
  type: MODEL_TYPE;
  jobActionHandler?: (type: MODEL_TYPE, meta?: TMeta) => void;
  setColorArr?: Dispatch<SetStateAction<{ value: string; isSelected: boolean }[]>>;
  colorArr?: { value: string; isSelected: boolean }[];
  otherData?: TOtherData | null;
  setNoColorUpdate?: Dispatch<SetStateAction<boolean>>;
  onDelete?: () => void;
  onJobGroupAdd?: (values: IMultiLangString, color: string) => void;
};
export const CommonJobModal = ({
  title,
  subtitle,
  onCancel,
  inputText,
  closeBtnAvailable = true,
  selectedValues = null,
  isBigger = false,
  isColorAvailable = false,
  type,
  jobActionHandler,
  setColorArr,
  colorArr,
  otherData,
  setNoColorUpdate,
  onJobGroupAdd,
  onDelete,
}: TProps) => {
  const { i18n } = useLingui();
  const [inputs, setInputs] = useState<IMultiLangString>();
  const [newColor, setNewColor] = useState(COLORS.CAREER);
  const [showPicker, setShowPicker] = useState(false);
  const company = useSelector(getCurrentCompany);
  const getMultiLangString = useMultiLangString();

  const languages: ILanguage[] = useMemo(() => {
    const primaryLang = company?.primaryLang || {
      language: 'Dutch',
      country: 'Netherlands',
      locale: 'nl_NL',
    };
    return (
      company?.languages?.sort((a: ILanguage, b: ILanguage) => {
        if (a.locale === primaryLang.locale) {
          return -1;
        }
        if (b.locale === primaryLang.locale) {
          return 1;
        }
        return a.language.toLowerCase().localeCompare(b.language.toLowerCase());
      }) || [primaryLang]
    );
  }, [company?.languages, company?.primaryLang]);

  const flags = useMemo(() => {
    return languages.map((flag) => ({
      key: flag.locale,
      title: getUnicodeFlagIcon(flag.locale.split('_')[1]),
      country: flag.country,
      language: flag.language,
    }));
  }, [languages]);

  const isAddModal =
    type === MODEL_TYPE.ADD_JOB_GROUP ||
    type === MODEL_TYPE.ADD_JOB_FAMILY ||
    type === MODEL_TYPE.ADD_JOB_LEVEL;

  useEffect(() => {
    if (otherData?.selectedColor) {
      setColorArr &&
        setColorArr((val) => {
          return val.map((color) => {
            return { ...color, isSelected: color.value === otherData?.selectedColor };
          });
        });
      setNewColor(otherData?.selectedColor);
    } else {
      setColorArr &&
        setColorArr((val) => {
          return val.map((color) => {
            return { ...color, isSelected: color.value === COLORS.CAREER };
          });
        });
      setNewColor(COLORS.CAREER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherData]);

  useEffect(() => {
    if (flags.length > 0) {
      const inputObject = flags.reduce<Record<string, IMultiLangString | string | null>>(
        (obj, flag) => {
          obj[flag.key] = selectedValues ? getMultiLangString(selectedValues[flag.key] || '') : '';
          return obj;
        },
        {},
      );
      if (isObject(inputObject)) {
        setInputs(inputObject as IMultiLangString);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flags]);

  const isEnabled = inputs && Object.values(inputs).some((val) => val !== '');

  const pickerDisabled = useMemo(() => {
    return !!colorArr?.find((c) => c.value === newColor);
  }, [newColor, colorArr]);

  return (
    <Wrapper minSize={isBigger ? '600px' : '400px'}>
      <Header>
        <Title>
          {title}
          {closeBtnAvailable && (
            <IconContainer onClick={onCancel}>
              <Icon icon={ICONS.CLOSE} size={18} />
            </IconContainer>
          )}
        </Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Header>
      <BodyArea>
        <SecondaryTitle>{inputText}</SecondaryTitle>
        <InputContainer>
          {inputs &&
            Object.entries(inputs).map(([key, value], i) => {
              const [_, country] = key.split('_');
              return (
                <Input
                  className="input-ctr"
                  value={value}
                  name="skillName"
                  placeholder={inputText}
                  onChange={(inputValue) => {
                    if (key) {
                      setInputs((val) => {
                        if (!val) {
                          return val;
                        }
                        return {
                          ...val,
                          [key]: inputValue.target.value || '',
                        };
                      });
                    }
                  }}
                  height="38px"
                  leftIcon={getUnicodeFlagIcon(country || '')}
                  key={`flag-${i + 1}`}
                />
              );
            })}
        </InputContainer>
        {isColorAvailable && (
          <ColorContainer>
            <ColorTitle>{i18n._(t`Color`)}</ColorTitle>
            <ColorItemContainer>
              {colorArr &&
                colorArr.map((item, i) => (
                  <ColorItem
                    key={`color-${i + 1}`}
                    itemColor={item.value}
                    isSelected={item.isSelected}
                    onClick={() => {
                      setNewColor(item.value);
                      setColorArr &&
                        setColorArr((val) => {
                          return val.map((color, index) => {
                            if (index === i) {
                              return { ...color, isSelected: true };
                            }
                            return { ...color, isSelected: false };
                          });
                        });
                    }}
                  />
                ))}

              <PopOver
                placement={PopOverPlacement.TOP}
                arrow={false}
                interactive={true}
                visible={showPicker}
                content={
                  <SketchPickerWrapper>
                    <SketchActionHeader>{i18n._(t`Add color:`)}</SketchActionHeader>
                    <SketchPicker
                      color={newColor}
                      presetColors={[]}
                      onChange={(color) => {
                        setNewColor(color.hex);
                      }}
                      className="color-picker-ctr"
                    />
                    <SketchActionContainer>
                      <Button
                        disabled={false}
                        label={i18n._(t`Cancel`)}
                        variant={ButtonVariant.SECONDARY}
                        onClick={() => {
                          setShowPicker(false);
                        }}
                        size={ButtonSize.MEDIUM}
                      />
                      <Button
                        disabled={pickerDisabled}
                        label={`${i18n._(t`Add color`)}`}
                        variant={ButtonVariant.PRIMARY}
                        onClick={() => {
                          if (newColor && !pickerDisabled) {
                            if (!colorArr?.find((color) => color.value === newColor)) {
                              const noSelectedArr =
                                colorArr?.map((color) => {
                                  return { ...color, isSelected: false };
                                }) || [];
                              setColorArr &&
                                setColorArr([
                                  { value: newColor, isSelected: true },
                                  ...noSelectedArr,
                                ]);
                              setShowPicker(false);
                            }
                          }
                        }}
                        size={ButtonSize.MEDIUM}
                      />
                    </SketchActionContainer>
                  </SketchPickerWrapper>
                }
              >
                <AddColorBtn active={showPicker}>
                  <Icon
                    icon={ICONS.CLOSE_2}
                    width={10}
                    height={10}
                    className="icon-inner"
                    onClick={() => setShowPicker(true)}
                  />
                </AddColorBtn>
              </PopOver>
            </ColorItemContainer>
          </ColorContainer>
        )}

        <ButtonContainer>
          {!isAddModal && (
            <DeleteBtn>
              <Button
                disabled={false}
                label={i18n._(t`Delete`)}
                variant={ButtonVariant.ICON_DELETE}
                onClick={onDelete}
                size={ButtonSize.MEDIUM}
              />
            </DeleteBtn>
          )}
          <SecondaryBtnContainer>
            <Button
              disabled={false}
              label={i18n._(t`Cancel`)}
              variant={ButtonVariant.SECONDARY}
              onClick={onCancel}
              size={ButtonSize.MEDIUM}
            />
            <Button
              disabled={!isEnabled}
              label={i18n._(t`Save`)}
              variant={ButtonVariant.PRIMARY}
              onClick={() => {
                if (!isEnabled) {
                  return;
                }
                if (type === MODEL_TYPE.ADD_JOB_FAMILY) {
                  jobActionHandler && jobActionHandler(type, { values: inputs });
                } else if (type === MODEL_TYPE.EDIT_JOB_GROUP) {
                  const filteredColor = colorArr?.find((color) => color.isSelected);
                  jobActionHandler &&
                    jobActionHandler(type, {
                      values: inputs,
                      color: filteredColor?.value,
                    });
                  setNoColorUpdate && setNoColorUpdate(true);
                } else if (type === MODEL_TYPE.EDIT_JOB_FAMILY) {
                  jobActionHandler &&
                    jobActionHandler(type, {
                      values: inputs,
                      id: otherData?.id,
                    });
                } else if (type === MODEL_TYPE.ADD_JOB_GROUP) {
                  onJobGroupAdd && onJobGroupAdd(inputs, newColor);
                }
                onCancel();
              }}
              size={ButtonSize.MEDIUM}
            />
          </SecondaryBtnContainer>
        </ButtonContainer>
      </BodyArea>
    </Wrapper>
  );
};

export default CommonJobModal;
