import React, { memo } from 'react';

import { USER_REVIEW_STATUS } from '@learned/constants';
import { useSelector } from 'react-redux';

import { Button, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import RickTextView from '~/components/RickTextView';
import { UserAvatar } from '~/components/UserAvatar';
import type {
  IUserReviewQuestionCustomSkillGrouped,
  IUserReviewQuestionCustomSkillV1Grouped,
  IUserReviewQuestionSkillCategoryGrouped,
  IUserReviewQuestionSkillCategoryV1Grouped,
} from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/utils';

import { Arrow, Comment, EditButton, EditButtonWrapper, Name } from './design';
import { RoleName } from './RoleName';
import { Comments, Row } from './SkillAnswersModal.design';
import { SkillRowModal } from './SkillRowModal';

import { getUser } from '~/selectors/baseGetters';

import type { IReviewRating, IReviewTheme, IUser, IUserReview, WithExtends } from '@learned/types';

const LefColumnRows = memo(
  ({
    userReview,
    question,
    setRowHeaders,
    setOpenRows,
    openRows,
    ratingFilter,
  }: {
    userReview: IUserReview;
    setRowHeaders: (
      func: (
        rowHeaders: Record<string, HTMLDivElement | null>,
      ) => Record<string, HTMLDivElement | null>,
    ) => void;
    setOpenRows: (func: (rows: number[]) => number[]) => void;
    question: WithExtends<
      | IUserReviewQuestionSkillCategoryGrouped
      | IUserReviewQuestionCustomSkillGrouped
      | IUserReviewQuestionSkillCategoryV1Grouped
      | IUserReviewQuestionCustomSkillV1Grouped,
      { theme?: IReviewTheme }
    >;
    openRows: number[];
    ratingFilter: (rating?: IReviewRating) => boolean;
  }) => {
    const currentUser = useSelector(getUser);

    return (
      <>
        {question.skills.map((skill, i) => {
          const isOpen = openRows.includes(i);
          return (
            <>
              <SkillRowModal
                ref={(el: HTMLDivElement) =>
                  setRowHeaders((prev) => {
                    return { ...prev, [`skill-${skill.skillId}`]: el };
                  })
                }
                onClick={() =>
                  setOpenRows((prev: number[]) =>
                    prev.includes(i) ? [...prev.filter((value) => value !== i)] : [...prev, i],
                  )
                }
                key={skill.skillId}
                isOpen={isOpen}
                skill={skill}
                ratingFilter={ratingFilter}
              />
              {isOpen && (
                <>
                  <Row
                    ref={(el) =>
                      setRowHeaders((prev) => {
                        return {
                          ...prev,
                          [`${skill.skillId}-comments`]: el,
                        };
                      })
                    }
                  >
                    <Comments>
                      {(skill.questions.at(0)?.reviewRatings ?? [])
                        .filter(ratingFilter)
                        .map((rating) => (
                          <Comment key={rating.id}>
                            <Name>
                              {rating.createdBy.id ? (
                                <UserAvatar userId={rating.createdBy.id as string} />
                              ) : (
                                <UserAvatar
                                  user={
                                    {
                                      firstName: '',
                                      lastName: '',
                                      email: rating.createdBy.email as string,
                                    } as IUser
                                  }
                                />
                              )}{' '}
                              <span className="role">
                                (<RoleName ratingType={rating.type} />)
                              </span>
                            </Name>
                            <EditButtonWrapper>
                              <Arrow />
                              <RickTextView
                                className="comment"
                                html={rating.comment ?? undefined}
                              />
                              {rating.createdBy.id === currentUser.id &&
                                ![
                                  USER_REVIEW_STATUS.PUBLISHED,
                                  USER_REVIEW_STATUS.SIGNING,
                                  USER_REVIEW_STATUS.ARCHIVED,
                                ].includes(userReview.status) && (
                                  <EditButton>
                                    <Button
                                      variant={ButtonVariant.SECONDARY}
                                      icon={ICONS.EDIT_PENCIL}
                                      label={'edit'}
                                    />
                                  </EditButton>
                                )}
                            </EditButtonWrapper>
                          </Comment>
                        ))}
                    </Comments>
                  </Row>
                </>
              )}
            </>
          );
        })}
      </>
    );
  },
);

export { LefColumnRows };
