import React, { Dispatch, SetStateAction, useContext } from 'react';

import { REPORT_TYPES, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { UseFormSetValue } from 'react-hook-form';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Button } from '~/components/Buttons/Button';
import { DropdownIconState } from '~/components/Buttons/types';
import { ICONS } from '~/components/Icon';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { EngagementReportContext } from './EngagementContext';
import { HeaderCtr, HeaderPlaceHolder, Heading, HeadingMenuCtr } from './LayoutStyles';

import { useOutsideClick } from '~/hooks/useOutsideClick';
import { addToDashboard } from '~/services/reports';

import SaveAsMenu, { MENU_ACTIONS } from '../Components/SaveAsMenu';

import type { TForm } from '../types';

type TProps = {
  isLoading: boolean;
  showSaveDropdown: boolean;
  setShowSaveDropdown: Dispatch<SetStateAction<boolean>>;
  setValue: UseFormSetValue<TForm>;
  setShowSaveModal: Dispatch<SetStateAction<boolean>>;
  onUpdateOrCreateReport: (isUpdate: boolean) => void;
};
const EngagementHeader = ({
  isLoading,
  showSaveDropdown,
  setShowSaveDropdown,
  setValue,
  setShowSaveModal,
  onUpdateOrCreateReport,
}: TProps): JSX.Element => {
  const { i18n } = useLingui();
  const { viewAs, tabConfiguration, reportType, showMenu, viewType, isWaiting, reportId } =
    useContext(EngagementReportContext);
  const { addToast } = useToasts();
  const reportTypeName =
    reportType === REPORT_TYPES.ENGAGEMENT ? i18n._(t`Engagement`) : i18n._(t`Performance`);

  const menuRef = useOutsideClick<HTMLDivElement>(() => {
    setShowSaveDropdown(false);
  });
  if (!viewType) {
    return <HeaderPlaceHolder />;
  }
  const title = !isWaiting ? `${reportTypeName}-${tabConfiguration?.name}` : null;

  const addItemToDashboard = async (role: ROLES, target: string) => {
    if (reportId === null || reportType === null) {
      return;
    }
    try {
      await addToDashboard({
        reportId,
        reportType,
        role,
      });
      addToast({
        title: i18n._(t`Successfully Added to ${target} dashboard`),
        type: TOAST_TYPES.SUCCESS,
      });
    } catch {
      addToast({
        title: i18n._(t`Something went wrong`),
        subtitle: i18n._(t`Something went wrong while deleting. Please try again later.`),
        type: TOAST_TYPES.ERROR,
      });
    }
  };

  return (
    <HeaderCtr isFull={!showMenu}>
      <Heading>
        <div>{title}</div>
        <HeadingMenuCtr>
          {viewAs === ROLES.ADMIN && (
            <>
              <Button
                icon={ICONS.SAVE}
                label={`${i18n._(t`Save view`)}`}
                variant={ButtonVariant.SECONDARY}
                isLoading={isWaiting || isLoading}
                onClick={() => {
                  setShowSaveDropdown(true);
                }}
                size={ButtonSize.MEDIUM}
                dropDownIcon={showSaveDropdown ? DropdownIconState.UP : DropdownIconState.DOWN}
              />
            </>
          )}
          {showSaveDropdown && (
            <SaveAsMenu
              onMenuChange={(type: MENU_ACTIONS) => {
                if (type === MENU_ACTIONS.SAVE) {
                  setValue('newName', `${reportTypeName}_copy`);
                  setShowSaveModal(true);
                } else if (type === MENU_ACTIONS.UPDATE) {
                  onUpdateOrCreateReport(true);
                } else if (type === MENU_ACTIONS.ADD_TO_COACH_DASHBOARD) {
                  addItemToDashboard(ROLES.COACH, 'team');
                } else {
                  addItemToDashboard(ROLES.ADMIN, 'organisation');
                }
                setShowSaveDropdown(false);
              }}
              reference={menuRef}
            />
          )}
        </HeadingMenuCtr>
      </Heading>
    </HeaderCtr>
  );
};

export { EngagementHeader };
