export default {
  MISSING_OR_WRONG_PARAMS: '0',
  USER_NOT_FOUND: '1',
  COMPANY_NOT_FOUND: '2',
  COMPANY_DISABLED: '3',
  NO_ACTIVE_CONNECTIONS: '4',
  INVALID_ROLE_FOR_USER: '5',
  TEAM_NOT_FOUND: '6',
  NOT_COACH_OF_TEAM: '7',
  ACCOUNT_DISABLED: '8',
  INVALID_TOKEN: '9',
  NO_APP_SESSION: '10',
};
