import React from 'react';

import { REVIEW_QUESTION_EVALUATORS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';

import { DateInput } from '~/components/DateInput';
import { ICONS, Icon } from '~/components/Icon';
import ToolTip from '~/components/Tooltip';
import { isStartDateDisabled } from '~/pages/Reviews/utils';

import { InputContainerGeneral, Separator, SubTitle } from '../../../design';
import { DateRow, ExplanationText, SubTitleRow } from '../design';

import type { IReviewCycleForm, TasksType } from '../../../types';
import type { I18n } from '@lingui/core';
import type { UseFormReturn } from 'react-hook-form';

interface TasksSectionProps {
  formMethods: UseFormReturn<IReviewCycleForm>;
  evaluators: Array<REVIEW_QUESTION_EVALUATORS>;
}

type TaskItem = {
  title: (i18n: I18n) => string;
  icon: ICONS;
  explanation: (i18n: I18n) => string;
  key: TasksType;
};

const TASKS: Array<TaskItem> = [
  {
    key: 'reviewSelfEvaluate',
    icon: ICONS.EMPLOYEE,
    explanation: (i18n: I18n) =>
      i18n._(
        t`Because the review template includes questions for employees, employees will be given the task of self review.`,
      ),
    title: (i18n: I18n) => i18n._(t`Self review`),
  },
  {
    key: 'reviewPeerEvaluate',
    icon: ICONS.PEER,
    explanation: (i18n: I18n) =>
      i18n._(
        t`Because the review template includes questions for peers, nominated peers will be given the task of self review.`,
      ),
    title: (i18n: I18n) => i18n._(t`Peer review`),
  },
  {
    key: 'reviewCoachEvaluate',
    icon: ICONS.COACH,
    explanation: (i18n: I18n) =>
      i18n._(
        t`Because the review template includes questions for coaches, selected coaches will be given the task of self review.`,
      ),
    title: (i18n: I18n) => i18n._(t`Coach review`),
  },
];

const TasksSection = ({ formMethods, evaluators }: TasksSectionProps) => {
  const { i18n } = useLingui();
  const { setValue, watch, trigger, formState } = formMethods;

  const onChangeStartDate = (key: TasksType, date: Date, isStartDate?: boolean) => {
    if (isStartDate) {
      setValue(`tasks.${key}.startDate`, date, { shouldDirty: true });
    } else {
      setValue(`tasks.${key}.endDate`, date, { shouldDirty: true });
    }
    setValue('settings.isAutoTimeline', false, { shouldDirty: true });

    const taskErrors = formState.errors?.tasks;
    (taskErrors && taskErrors[key]?.endDate) || (taskErrors && taskErrors[key]?.startDate)
      ? trigger(`tasks.${key}`)
      : trigger(`tasks.${key}.${isStartDate ? 'startDate' : 'endDate'}`);
  };

  const getFormattedDateToString = (key: TasksType, isStartDate?: boolean) => {
    const startDate = watch(`tasks.${key}.startDate`);
    const endDate = watch(`tasks.${key}.endDate`);
    const datePattern = 'DD-MM-YYYY';

    if (isStartDate && startDate) {
      return moment(startDate).format(datePattern);
    }

    if (endDate) {
      return moment(endDate).format(datePattern);
    }

    return '';
  };

  const getSubSection = (item: TaskItem) => {
    return (
      <>
        <Separator marginBottom="24px" marginTop="24px" />
        <SubTitleRow key={item.key}>
          <Icon icon={item.icon} />
          <SubTitle>{item.title(i18n)}</SubTitle>
        </SubTitleRow>
        <ExplanationText>{item.explanation(i18n)}</ExplanationText>
        <DateRow>
          <ToolTip
            tooltip={i18n._(t`It is not possible to change this date after the task has started`)}
            disabled={!isStartDateDisabled(watch('fetchedCycle'), item.key)}
          >
            <InputContainerGeneral>
              <DateInput
                value={getFormattedDateToString(item.key, true)}
                label={i18n._(t`Start date`)}
                onChange={(date) => onChangeStartDate(item.key, date as Date, true)}
                error={
                  formState.errors?.tasks && formState.errors?.tasks[item.key]?.startDate?.message
                }
                disabled={isStartDateDisabled(watch('fetchedCycle'), item.key)}
              />
            </InputContainerGeneral>
          </ToolTip>
          <DateInput
            value={getFormattedDateToString(item.key)}
            label={i18n._(t`Deadline`)}
            onChange={(date) => onChangeStartDate(item.key, date as Date)}
            error={formState.errors?.tasks && formState.errors?.tasks[item.key]?.endDate?.message}
          />
        </DateRow>
      </>
    );
  };

  return (
    <>
      {TASKS.map((item) => {
        switch (true) {
          case item.key === 'reviewSelfEvaluate' &&
            evaluators.includes(REVIEW_QUESTION_EVALUATORS.EMPLOYEE):
            return getSubSection(item);
          case item.key === 'reviewPeerEvaluate' &&
            evaluators.includes(REVIEW_QUESTION_EVALUATORS.PEER):
            return getSubSection(item);
          case item.key === 'reviewCoachEvaluate' &&
            evaluators.includes(REVIEW_QUESTION_EVALUATORS.COACH):
            return getSubSection(item);
        }
      })}
    </>
  );
};

export { TasksSection };
