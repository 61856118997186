import React, { useEffect, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import _ from 'lodash';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AutocompleteFilter from '~/components/AutocompleteFilter';
import {
  AutocompleteFilterMembers,
  AutocompleteFilterTeams,
} from '~/components/AutocompleteFilters';
import Button from '~/components/Button';
import SectionTemplate from '~/components/Graphs/components/SectionTemplate';
import PaginationBar from '~/components/PaginationBar';
import Placeholder from '~/components/Placeholder';
import ProgressBarWide from '~/components/ProgressBarWide';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';
import Tooltip from '~/components/Tooltip';
import AvatarCard from '~/components/UI/AvatarCard';
import Table, { TableRow, TableCol } from '~/components/UI/Table';

import SkillsIcon from '~/assets/mdi-tag-multiple.svg';

import useBoolState from '~/hooks/useBoolState';
import { getSkillReport } from '~/services/skills';
import { COLOR_PALETTE, COLORS } from '~/styles';

const ProgressRow = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
`;

const ProgressBarWrapper = styled.div`
  width: 60%;
`;

const TextCol = styled(TableCol)`
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.TEXT_SECONDARY};
`;

const ProgressValue = styled.span`
  color: ${COLORS.TEXT_SECONDARY};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  margin-left: 12px;
`;

const RequiredLevel = styled.div`
  display: flex;
  align-items: baseline;
`;

const RequiredLevelLabel = styled.div``;

const RequiredLevelSubLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin-left: 4px;
`;

const AvgLevel = styled(RequiredLevel)``;
const AvgLevelLabel = styled(RequiredLevelLabel)``;
const AvgLevelSubLabel = styled(RequiredLevelSubLabel)``;

const Filters = styled.div`
  display: flex;
  padding: 17px 24px 16px;
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_MIDDLE};
`;

const FilterReviews = styled(AutocompleteFilter)`
  width: 300px;
  height: 32px;
  margin-right: 8px;
`;

const FilterTeams = styled(AutocompleteFilterTeams)`
  width: 157px;
  height: 32px;
`;

const FilterMembers = styled(AutocompleteFilterMembers)`
  width: 157px;
  height: 32px;
  margin-left: 8px;
`;

const PAGE_SIZE = 12;

const APPLIED_FILTERS_DEFAULT = {
  selectedTeams: [],
  selectedMembers: [],
};

const defaultPagination = {
  skip: 0,
  limit: PAGE_SIZE,
  index: 1,
};

function PerMemberSection({
  i18n,
  skillId,
  selectedTeams,
  selectedMembers,
  selectedReviews,
  setSelectedTeams,
  setSelectedMembers,
  setSelectedReviews,
  reviewsList,
}) {
  const [tableData, setTableData] = useState([]);
  const $fetching = useBoolState(true);
  const [pagination, setPagination] = useState(defaultPagination);
  const [appliedFilters, setAppliedFilters] = useState(APPLIED_FILTERS_DEFAULT); // uses for disable apply button
  const [graphLoading, setGraphLoading] = useState(true);
  const isLatest = false; // now it's always false, for Skill 2 report it was true, but we deleted skill 2 report

  const isFilterSame = () => {
    const currentFilters = {
      selectedTeams,
      selectedMembers,
      selectedReviews,
    };

    return _.isEqual(appliedFilters, currentFilters);
  };

  const fetchData = async (pagination = defaultPagination) => {
    const { skip, limit } = pagination;
    let members = [];

    if (!isEmpty(selectedMembers)) {
      members = [...members, ...selectedMembers.map((m) => m.id)];
    }

    if (!isEmpty(selectedTeams)) {
      members = uniq([...members, ...concat(...selectedTeams.map((t) => t.members))]);
    }

    const data = await getSkillReport(skillId, {
      isLatest,
      reviewId: selectedReviews[0].id,
      ...(!isEmpty(members) && { members }),
      pagination: { skip, limit },
    });

    setTableData(data);
    setAppliedFilters({
      selectedTeams,
      selectedMembers,
      selectedReviews,
    });
    setPagination(pagination);
    $fetching.off();
  };

  useEffect(() => {
    const getData = async () => {
      await fetchData();
      setGraphLoading(false);
    };
    getData();
    // eslint-disable-next-line
  }, []);

  const onPageChangeClick = ({ skip, limit, index }) => {
    return fetchData({ skip, limit, index });
  };

  const getDefaultCols = () => [
    { name: 'members', width: '180px' },
    { name: 'Required lvl', width: '100px' },
    { name: 'self-avg', width: '90px' },
    { name: 'self-coverage', width: '120px' },
    { name: 'peer-avg', width: '90px' },
    { name: 'peer-coverage', width: '120px' },
    { name: 'coach-avg', width: '90px' },
    { name: 'coach-coverage', width: '120px' },
  ];

  const getCols = () => {
    return getDefaultCols().map((c, index) => {
      switch (index) {
        case 0:
          c.title = i18n._(t`Member`);
          break;
        case 1:
          c.title = i18n._(t`Required lvl.`);
          break;
        case 2:
        case 4:
        case 6:
          c.title = i18n._(t`AVG LVL.`);
          break;
        case 3:
        case 5:
        case 7:
          c.title = i18n._(t`Skill coverage`);
          break;
        default:
          break;
      }
      return c;
    });
  };

  const getColsSecond = () => {
    return getDefaultCols().map((c, index) => {
      switch (index) {
        case 2:
          c.title = i18n._(t`SELF REVIEW`);
          break;
        case 4:
          c.title = i18n._(t`PEER REVIEW`);
          break;
        case 6:
          c.title = i18n._(t`COACH REVIEW`);
          break;
        default:
          break;
      }
      return c;
    });
  };

  const renderSkillRow = (item) => {
    return (
      <TableRow key={item.userId} $border>
        <TableCol>
          <AvatarCard userId={item.userId} type={AvatarCard.TYPES.MEDIUM} />
        </TableCol>
        <TextCol>
          <Tooltip
            tooltip={
              item.requiredLevel.label &&
              `${item.requiredLevel.label} ${item.requiredLevel.value}/${item.requiredLevel.max}`
            }
          >
            <RequiredLevel>
              <RequiredLevelLabel>{item.requiredLevel.label}</RequiredLevelLabel>
              <RequiredLevelSubLabel>
                {item.requiredLevel.value &&
                  `${item.requiredLevel.value}/${item.requiredLevel.max}`}
              </RequiredLevelSubLabel>
            </RequiredLevel>
          </Tooltip>
        </TextCol>

        <TextCol>
          <Tooltip
            tooltip={
              item.self.label && `${item.self.label} ${item.self.avg_lvl}/${item.requiredLevel.max}`
            }
          >
            <AvgLevel>
              <AvgLevelLabel>{item.self.label}</AvgLevelLabel>
              <AvgLevelSubLabel>
                {item.self.label && `${item.self.avg_lvl}/${item.requiredLevel.max}`}
              </AvgLevelSubLabel>
            </AvgLevel>
          </Tooltip>
        </TextCol>
        <TableCol>
          <ProgressRow>
            <ProgressBarWrapper>
              <ProgressBarWide
                value={item.self.avg}
                backgroundColor={COLORS.SELF_COVERAGE_BG}
                customStatusColor={COLORS.SELF_COVERAGE_STATUS}
                rounded
                isActive
                height="12px"
              />
            </ProgressBarWrapper>
            <ProgressValue>{item.self.avg || 0}%</ProgressValue>
          </ProgressRow>
        </TableCol>

        <TextCol>
          <Tooltip
            tooltip={
              item.peer.label && `${item.peer.label} ${item.peer.avg_lvl}/${item.requiredLevel.max}`
            }
          >
            <AvgLevel>
              <AvgLevelLabel>{item.peer.label}</AvgLevelLabel>
              <AvgLevelSubLabel>
                {item.peer.label && `${item.peer.avg_lvl}/${item.requiredLevel.max}`}
              </AvgLevelSubLabel>
            </AvgLevel>
          </Tooltip>
        </TextCol>
        <TableCol>
          <ProgressRow>
            <ProgressBarWrapper>
              <ProgressBarWide
                value={item.peer.avg}
                backgroundColor={COLORS.PEER_COVERAGE_BG}
                customStatusColor={COLORS.PEER_COVERAGE_STATUS}
                rounded
                isActive
                height="12px"
              />
            </ProgressBarWrapper>
            <ProgressValue>{item.peer.avg || 0}%</ProgressValue>
          </ProgressRow>
        </TableCol>

        <TextCol>
          <Tooltip
            tooltip={
              item.coach.label &&
              `${item.coach.label} ${item.coach.avg_lvl}/${item.requiredLevel.max}`
            }
          >
            <AvgLevel>
              <AvgLevelLabel>{item.coach.label}</AvgLevelLabel>
              <AvgLevelSubLabel>
                {item.coach.label && `${item.coach.avg_lvl}/${item.requiredLevel.max}`}
              </AvgLevelSubLabel>
            </AvgLevel>
          </Tooltip>
        </TextCol>
        <TableCol>
          <ProgressRow>
            <ProgressBarWrapper>
              <ProgressBarWide
                value={item.coach.avg}
                backgroundColor={COLORS.COACH_COVERAGE_BG}
                customStatusColor={COLORS.COACH_COVERAGE_STATUS}
                rounded
                isActive
                height="12px"
              />
            </ProgressBarWrapper>
            <ProgressValue>{item.coach.avg || 0}%</ProgressValue>
          </ProgressRow>
        </TableCol>
      </TableRow>
    );
  };

  return (
    <SectionTemplate
      title={i18n._(t`Per member`)}
      description={i18n._(t`The skill coverage per member for the selected review`)}
    >
      <Filters>
        {/* show only for per review report (Skill report)*/}
        {!isEmpty(reviewsList) && (
          <FilterReviews
            showTooltip
            placeholder={<Trans>Reviews</Trans>}
            fetch={() => reviewsList}
            checkedList={selectedReviews}
            onChange={(arr) => setSelectedReviews(arr.slice(-1))} // only 1 review can be selected
            isDeleteDisabled={true}
          />
        )}
        <FilterTeams checkedList={selectedTeams} onChange={setSelectedTeams} />
        <FilterMembers checkedList={selectedMembers} onChange={setSelectedMembers} />
        <Button
          label={i18n._(t`Apply`)}
          onClick={(_e) => fetchData()}
          styles={{ marginLeft: 'auto', width: '83px', height: '32px' }}
          disabled={isFilterSame()}
        />
      </Filters>
      <ShowSpinnerIfLoading loading={graphLoading}>
        {!isEmpty(tableData.data) ? (
          <Table
            cols={getCols()}
            colsSecond={getColsSecond()}
            items={tableData.data}
            renderRow={renderSkillRow}
            hideHeader
            noTopBorder
          />
        ) : (
          <Placeholder
            title={i18n._(t`No data available yet`)}
            subTitle={i18n._(
              t`Start with measuring skill coverage in your company by linking skill coverage to questions in a review template. `,
            )}
            Icon={() => (
              <SvgIcon
                url={SkillsIcon}
                width={'50px'}
                height={'50px'}
                isDefaultColor
                defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
              />
            )}
            subTitleStyles={{
              width: '435px',
              color: COLOR_PALETTE.DARK_GRAY,
              fontWeight: 600,
              lineHeight: 1.57,
            }}
          />
        )}
        <PaginationBar
          pagination={pagination}
          count={tableData.total || 0}
          changePagination={onPageChangeClick}
          showCount
          noTopBorder
        />
      </ShowSpinnerIfLoading>
    </SectionTemplate>
  );
}

PerMemberSection.propTypes = {
  skillId: PropTypes.string,
  selectedTeams: PropTypes.arrayOf(PropTypes.object),
  selectedMembers: PropTypes.arrayOf(PropTypes.object),
  selectedReviews: PropTypes.arrayOf(PropTypes.object),
  setSelectedTeams: PropTypes.func,
  setSelectedMembers: PropTypes.func,
  setSelectedReviews: PropTypes.func,
};

export default withI18n()(PerMemberSection);
