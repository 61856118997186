import React from 'react';

import { CustomOption } from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import type { IJobTemplate } from '@learned/types';

function CustomOptionComponent({
  item,
  handleNewItemSelected,
}: {
  item: IJobTemplate;
  handleNewItemSelected: (item: IJobTemplate) => void;
}) {
  const getMultiLangString = useMultiLangString();

  return (
    <CustomOption onClick={() => handleNewItemSelected(item)}>
      <span>{getMultiLangString(item.name)}</span>
    </CustomOption>
  );
}

export { CustomOptionComponent };
