import { LANGUAGES } from '~/constants';

import type { ISkill } from '@learned/types';

export const getSkillName = (skill: ISkill, lang: string): string =>
  lang === LANGUAGES.NL && skill.name?.nl_NL ? skill.name?.nl_NL : skill.name?.en_GB;

export const getSkillDescription = (skill: ISkill, lang: string) =>
  lang === LANGUAGES.NL && skill.description?.nl_NL
    ? skill.description?.nl_NL
    : skill.description?.en_GB;
