import { FocusAreaType, REVIEW_STATUS } from '@learned/constants';
import { isPast, isToday } from 'date-fns';
import _ from 'lodash';

import type { TasksType } from '~/pages/Reviews/EditCycle/ReviewCycleForm/types';

import type { IJobProfile, IMultiLangString, ISkill, IReview } from '@learned/types';

export const transformToISOString = (date: Date | string | null) => {
  if (date === null) {
    return null;
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  // set day from date in UTC
  date.setUTCDate(date.getDate());
  // set 0 UTC hours to keep same Day (not yesterday)
  date.setUTCHours(0, 0, 0, 0);

  return date.toISOString();
};

export const getSkillFocusAreas = (
  skill: ISkill,
  filters?: { levels?: number[] | null; ids?: string[] | null },
) => {
  return _.chain(skill.focusAreas)
    .filter((item) => !filters?.levels || filters?.levels.includes(item.level)) // filter by levels, only if levels exist
    .map((level) => Object.values(level.values))
    .flatten()
    .filter((focusArea) => !filters?.ids || filters?.ids.includes(focusArea.id)) // filter by ids, only if ids exist
    .uniq()
    .value();
};

export const skillWithFocusAreaCheck = ({
  job,
  skills,
}: {
  job: IJobProfile;
  skills: ISkill[];
}) => {
  const skillsWithoutFocusArea: ISkill[] = [];

  job.skills.forEach(({ skill, selectedFocusAreas }) => {
    const skillData = skills.find((item: ISkill) => item.id === skill);
    if (skillData) {
      const focusAreas: Array<{ id: string; name: IMultiLangString }>[] = [];
      selectedFocusAreas.forEach((item) => {
        switch (item.type) {
          case FocusAreaType.SELECT_ALL: {
            focusAreas.push(getSkillFocusAreas(skillData));
            break;
          }
          case FocusAreaType.SELECT_LEVEL: {
            focusAreas.push(
              getSkillFocusAreas(skillData, {
                levels: [item.level as unknown as number],
              }),
            );
            break;
          }
          case FocusAreaType.SELECT_FOCUS_AREA: {
            focusAreas.push(
              getSkillFocusAreas(skillData, {
                ids: [item.focusArea as unknown as string],
              }),
            );
            break;
          }
          default:
            break;
        }
      });

      if (!_.isEmpty(focusAreas.filter((item) => _.isEmpty(item)))) {
        skillsWithoutFocusArea.push(skillData);
      }
    }
  });

  return skillsWithoutFocusArea;
};

// startDate NOT possible to edit when
// review PUBLISHED or ACTIVE
// AND startDate today or in the past
export const isStartDateDisabled = (review: IReview, key: TasksType) => {
  const startDateString = review.tasks ? review.tasks[key]?.startDate : null;
  const startDate = startDateString ? new Date(startDateString) : null;

  const isPublished = review.status === REVIEW_STATUS.PUBLISHED;
  const isActive = review.status === REVIEW_STATUS.ACTIVE;

  return Boolean(
    (isPublished || isActive) && startDate && (isToday(startDate) || isPast(startDate)),
  );
};

// startDate NOT possible to edit when
// review PUBLISHED or ACTIVE
// AND startDate today or in the past
export const isReviewStartDateDisabled = (review: IReview) => {
  const startDateString = review.settings.startDate;
  const startDate = startDateString ? new Date(startDateString) : null;

  const isPublished = review.status === REVIEW_STATUS.PUBLISHED;
  const isActive = review.status === REVIEW_STATUS.ACTIVE;

  return Boolean(
    (isPublished || isActive) && startDate && (isToday(startDate) || isPast(startDate)),
  );
};
