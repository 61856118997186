import React from 'react';

import styled from 'styled-components';

import SkillName from '~/components/Skill/SkillName';

const Skill = styled.div`
  height: 40px;
  border-radius: 21px;
  background-color: #f8f8f8;
  box-sizing: border-box;
  padding: 8px;
  margin: 0 0 7px 7px;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  color: var(--company-color);
  position: relative;
`;

const SkillNameWrapper = styled.div`
  cursor: pointer;
`;

function SkillItem({ skill }) {
  return (
    <Skill key={skill.id}>
      <SkillNameWrapper>
        <SkillName skill={skill} />
      </SkillNameWrapper>
    </Skill>
  );
}

export default React.memo(SkillItem);
