import React, { useState } from 'react';

import { isEmpty } from 'lodash';
import styled from 'styled-components';

import { ContextMenu, ContextOption } from '~/components/ContextMenu';
import { TablePlaceholder } from '~/components/TablePlaceholder';

import { TD, TR, TableWrapper, IconMenuWrapper, IsVisible } from './design';
import { Header } from './Header';

import { COLORS } from '~/styles';

import type { IIntegrationsGridProps } from './types';

const Wrapper = styled.div`
  position: relative;
  background-color: ${COLORS.WHITE};
  border-radius: 0 10px 10px 10px;
  padding: 16px;
`;

const IntegrationsGrid = ({
  columns,
  data,
  onRowClick,
  menuProps: { isMenuVisible, createMenuItems } = { isMenuVisible: false },
  isLoading,
  placeholderProps: { noResultText, emptyStateText } = {},
  className,
  isAllDone = false,
  isFiltered,
  onResetSearch,
}: IIntegrationsGridProps) => {
  const [hovered, setHovered] = useState(-1);

  return (
    <Wrapper className={className}>
      <TableWrapper>
        <Header columns={columns} isMenuVisible={isMenuVisible} />
        {!isEmpty(data) && (
          <tbody>
            {data.map((item, i) => {
              return (
                <TR
                  onMouseEnter={() => setHovered(i)}
                  onMouseLeave={() => setHovered(-1)}
                  key={item?.id}
                  onClick={() => onRowClick && onRowClick(item)}
                  role="link"
                >
                  {columns.map((column) => {
                    return column.renderCell ? (
                      <TD
                        key={`td-${column.accessor}`}
                        maxWidth={column.maxWidth}
                        padding={column.padding}
                        isHovered={hovered === i}
                      >
                        {column.renderCell(item, hovered === i)}
                      </TD>
                    ) : (
                      <TD
                        key={`td-${column.accessor}`}
                        minWidth={column.minWidth}
                        maxWidth={column.maxWidth}
                        isHovered={hovered === i}
                      >
                        {/* @ts-ignore */}
                        {item?.[column.accessor]}
                      </TD>
                    );
                  })}
                  {isMenuVisible && (
                    <TD minWidth="26px" padding="16px 26px 16px 0">
                      <IconMenuWrapper>
                        {isMenuVisible && createMenuItems && !isEmpty(createMenuItems(item)) && (
                          <IsVisible isVisible={hovered === i}>
                            <ContextMenu>
                              {createMenuItems(item).map((option, i) => (
                                <ContextOption
                                  key={i}
                                  action={option.action}
                                  icon={option.icon}
                                  isWarning={option.isWarning}
                                >
                                  {option.label}
                                </ContextOption>
                              ))}
                            </ContextMenu>
                          </IsVisible>
                        )}
                      </IconMenuWrapper>
                    </TD>
                  )}
                </TR>
              );
            })}
          </tbody>
        )}
      </TableWrapper>
      {isEmpty(data) && (
        <TablePlaceholder
          isLoading={isLoading}
          noResultText={noResultText}
          emptyStateText={emptyStateText}
          isAllDone={isAllDone}
          isFiltered={isFiltered}
          onResetSearch={onResetSearch}
        />
      )}
    </Wrapper>
  );
};

export { IntegrationsGrid };
