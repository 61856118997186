import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import PaginationBar from '~/components/PaginationBar';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TablePlaceholder } from '~/components/TablePlaceholder';
import BoxWithBorder from '~/components/UI/BoxWithBorder';
import { TableHeader } from '~/pages/Conversations/components/TableHeader';

import { Columns, SORT_OPTIONS } from './columns';

import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { usePagination } from '~/hooks/usePagination';
import { getUser } from '~/selectors/baseGetters';
import { deleteLike, retrieveLikesForUser } from '~/services/likes';

import { Wrapper, StyledTable } from '../../design';
import TabContainer from '../components/TabContainer';

import type { IUser } from '@learned/types';

interface IOptions {
  options: {
    sortBy: {
      jobProfileName: number;
    };
    skip: number;
    limit: number;
  };
}

interface IAmbition {
  user: string;
  company: string;
  meta: {
    createdDate: string;
    lastModifiedDate: string;
  };
  createdDate: string;
  jobProfileName: string;
  jobProfileId: string;
  id: string;
}

const INITIAL_OPTIONS = {
  options: {
    sortBy: { jobProfileName: 1 },
    skip: 0,
    limit: 3,
  },
};

const LS_KEY = 'LS_CAREER_AMBITIONS_USER_PUBLIC';

const Ambitions = ({ user }: { user: IUser }) => {
  const { i18n } = useLingui();

  const [ambitions, setAmbitions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentFilters, setCurrentFilters] = useState<IOptions>(INITIAL_OPTIONS);
  const [currentSortOption, setCurrentSortOption] = useState(SORT_OPTIONS.NAME_A_Z);
  const { pagination, changePagination } = usePagination(3);
  const $loading = useBoolState(false);
  const currentUser = useSelector(getUser);
  const getMultiLangString = useMultiLangString();

  const isAdmin = currentUser.isAdmin;

  // @ts-ignore
  const findSortOptionKey = (sortOption) => {
    switch (JSON.stringify(sortOption)) {
      case '{"jobProfileName":1}':
        return SORT_OPTIONS.NAME_A_Z;
      case '{"jobProfileName":-1}':
        return SORT_OPTIONS.NAME_Z_A;
      case '{"savedDate":1}':
        return SORT_OPTIONS.SAVED_DATE_SOON_LATER;
      case '{"savedDate":-1}':
        return SORT_OPTIONS.SAVED_DATE_LATER_SOON;
      case '{"skillMatch":1}': // this is to change once skill match is implemented
        return SORT_OPTIONS.SKILL_MATCH_MIN_MAX;
      case '{"skillMatch":-1}': // this is to change once skill match is implemented
        return SORT_OPTIONS.SKILL_MATCH_MAX_MIN;
    }
  };

  // first render
  useEffect(() => {
    // get filters from localStorage
    const localStorageData = localStorage.getItem(LS_KEY);
    const isLocalStorageData = !isEmpty(localStorageData);

    if (isLocalStorageData) {
      const sortOption = findSortOptionKey(JSON.parse(localStorageData as string).options.sortBy);
      setCurrentFilters(JSON.parse(localStorageData as string));
      // @ts-ignore
      setCurrentSortOption(sortOption);
    } else {
      setCurrentSortOption(SORT_OPTIONS.NAME_A_Z);
      setCurrentFilters(INITIAL_OPTIONS);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      $loading.on();
      const ambitions = await retrieveLikesForUser(user.id, {
        ...currentFilters,
        options: { ...currentFilters?.options, skip: pagination.skip, limit: pagination.limit },
      });
      Object.values(ambitions.data.ambitions).forEach((amb: any) => {
        amb.jobProfileName = getMultiLangString(amb.jobProfileName);
      });
      setAmbitions(Object.values(ambitions.data.ambitions));
      setTotalCount(ambitions.data.total);
      $loading.off();
    };
    fetchData();
    // eslint-disable-next-line
  }, [currentFilters, pagination, user]);

  const findTheSortOption = (sortOption: string) => {
    switch (sortOption) {
      case SORT_OPTIONS.NAME_A_Z:
        return { jobProfileName: 1 };
      case SORT_OPTIONS.NAME_Z_A:
        return { jobProfileName: -1 };
      case SORT_OPTIONS.SAVED_DATE_SOON_LATER:
        return { savedDate: 1 };
      case SORT_OPTIONS.SAVED_DATE_LATER_SOON:
        return { savedDate: -1 };
      case SORT_OPTIONS.SKILL_MATCH_MIN_MAX:
        return { jobProfileName: 1 }; // this is to change once skill match is implemented
      case SORT_OPTIONS.SKILL_MATCH_MAX_MIN:
        return { jobProfileName: -1 }; // this is to change once skill match is implemented
      default:
        return { name: 1 };
    }
  };

  const onDelete = async (item: IAmbition) => {
    await deleteLike(item.id);
    const ambitions = await retrieveLikesForUser(user.id, {
      ...currentFilters,
      options: { ...currentFilters?.options, skip: pagination.skip, limit: pagination.limit },
    });
    Object.values(ambitions.data.ambitions).forEach((amb: any) => {
      amb.jobProfileName = getMultiLangString(amb.jobProfileName);
    });
    setAmbitions(Object.values(ambitions.data.ambitions));
    setTotalCount(ambitions.data.total);
  };

  const onCurrentFiltersChange = (sortBy: string) => {
    // @ts-ignore
    setCurrentSortOption(sortBy);
    const newFilters = {
      ...currentFilters,
      options: {
        ...currentFilters?.options,
        sortBy: findTheSortOption(sortBy),
      },
    };
    // @ts-ignore
    setCurrentFilters(newFilters);

    localStorage.setItem(LS_KEY, JSON.stringify(newFilters));
  };

  const onPageChangeClick = async ({ index, skip }: { index: number; skip: number }) => {
    const newPagination = {
      ...pagination,
      skip,
      index,
    };
    onPaginationChange(newPagination);
  };

  const onPaginationChange = (newPagination: typeof pagination) => {
    changePagination(newPagination);

    localStorage.setItem(
      LS_KEY,
      JSON.stringify({
        ...currentFilters,
        options: {
          ...currentFilters?.options,
          limit: newPagination.limit,
          skip: newPagination.skip,
        },
      }),
    );
  };

  const handleChangeItemsPerPage = ({ limit }: { limit: number }) => {
    const newPagination = {
      ...pagination,
      limit,
    };
    onPaginationChange(newPagination);
  };

  return (
    <BoxWithBorder>
      <TabContainer>
        <Wrapper>
          <TableHeader
            filters={{ search: '', setSearch: () => {} }}
            headerTitle={i18n._(t`Ambitions`)}
          />

          <ShowSpinnerIfLoading loading={$loading.value}>
            <StyledTable
              data={ambitions}
              columns={Columns({ onDelete, isAdmin })}
              sortBy={currentSortOption}
              setSortBy={(sortBy: SORT_OPTIONS) => onCurrentFiltersChange(sortBy)}
              hideHeaders={false}
            />

            {isEmpty(ambitions) && (
              <TablePlaceholder
                isLoading={false}
                isFiltered={false}
                noResultText={i18n._(t`No ambitions found`)}
                emptyStateText={i18n._(t`No ambitions found`)}
              />
            )}

            {pagination && (
              <PaginationBar
                pagination={pagination}
                changePagination={onPageChangeClick}
                changePageSize={handleChangeItemsPerPage}
                count={totalCount}
                noShadow
                noBorder
                noTopBorder
                showCount
                itemLabel={i18n._(t`Ambitions`)}
              />
            )}
          </ShowSpinnerIfLoading>
        </Wrapper>
      </TabContainer>
    </BoxWithBorder>
  );
};

export { Ambitions };
