import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment/moment';
import styled from 'styled-components';

import SearchSelectButton from '~/components/SearchSelectButton';

const TimeSelect = styled(SearchSelectButton)`
  width: 185px;
  padding: 4px 12px 4px 6px;
  z-index: 100;
  ...${(props) => props.$styles || {}},
`;

export enum TIME_FRAMES_IDS {
  LAST_WEEK = 'LAST_WEEK',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_6_MONTHS = 'LAST_6_MONTHS',
  LAST_YEAR = 'LAST_YEAR',
}
export const TIME_FRAMES = {
  LAST_WEEK: {
    id: TIME_FRAMES_IDS.LAST_WEEK,
    label: (i18n: any) => i18n._(t`Last week`),
    value: moment(new Date()).subtract(1, 'weeks'),
  },
  LAST_30_DAYS: {
    id: TIME_FRAMES_IDS.LAST_30_DAYS,
    label: (i18n: any) => i18n._(t`Last 30 days`),
    value: moment(new Date()).subtract(30, 'days'),
  },
  LAST_6_MONTHS: {
    id: TIME_FRAMES_IDS.LAST_6_MONTHS,
    label: (i18n: any) => i18n._(t`Last 6 months`),
    value: moment(new Date()).subtract(6, 'months'),
  },
  LAST_YEAR: {
    id: TIME_FRAMES_IDS.LAST_YEAR,
    label: (i18n: any) => i18n._(t`Last year`),
    value: moment(new Date()).subtract(1, 'years'),
  },
};

interface ITimeFrameSelector {
  timeFrameId: TIME_FRAMES_IDS;
  onChange: (timeframe: (typeof TIME_FRAMES)[TIME_FRAMES_IDS.LAST_WEEK]) => void;
  styles?: object;
}

export const TimeFrameSelector = ({ timeFrameId, onChange, styles }: ITimeFrameSelector) => {
  const { i18n } = useLingui();

  return (
    <TimeSelect
      title={i18n._(t`Time filter`)}
      $styles={styles}
      checkedList={[timeFrameId]}
      // @ts-ignore
      handleChange={(timeframeId) => onChange(TIME_FRAMES[timeframeId])}
      options={Object.values(TIME_FRAMES)}
      minWidth="80px"
      isRadio
      noHeader
    />
  );
};
