import React, { Component } from 'react';

import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';
import Radio from '~/components/Radio';
import AvatarCard from '~/components/UI/AvatarCard';

import { COLORS, COLOR_PALETTE } from '~/styles';

const UserItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 56px;
  padding: 12px 24px;
  box-sizing: border-box;
  background-color: ${COLOR_PALETTE.WHITE};
  &:hover {
    background: ${COLORS.BG_PAGE};
  }
  cursor: pointer;
  border-bottom: 1px solid ${COLORS.BG_PAGE};

  &:first-of-type {
    border-top: 1px solid ${COLORS.BG_PAGE};
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

const UserItems = styled.div`
  display: flex;
  align-items: center;
`;

const UserTeams = styled.div`
  display: flex;
  max-width: 550px;
  flex-wrap: wrap;
`;

const TeamLabel = styled.div`
  align-items: center;
  background-color: ${COLORS.BG_PAGE};
  font-size: 14px;
  font-weight: 600;
  height: 22px;
  padding: 2px 10px 0;
  margin-right: 8px;
`;

const AvatarCardWrapper = styled(AvatarCard)`
  margin-right: 32px;
  width: 200px;
`;

class UserItem extends Component {
  static propTypes = {
    user: PropTypes.object,
    isSelectMode: PropTypes.bool,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    onRemove: PropTypes.func,
    disabled: PropTypes.bool,
    singleChoice: PropTypes.bool,
    userTeams: PropTypes.array,
  };

  static defaultProps = {
    onSelect: () => {},
    onRemove: () => {},
    isSelectMode: false,
    disabled: false,
    singleChoice: false,
    userTeams: [],
  };

  render = () => {
    const { user, selected, onSelect, isSelectMode, onRemove, disabled, singleChoice, userTeams } =
      this.props;
    return (
      user && (
        <UserItemWrapper onClick={() => (isSelectMode ? onSelect(user) : null)}>
          <UserItems>
            <AvatarCardWrapper type="small" size={28} userId={user.id} />
            <UserTeams>
              {userTeams.map((team) => (
                <TeamLabel key={team.id}>{team.name}</TeamLabel>
              ))}
            </UserTeams>
          </UserItems>
          {!isSelectMode ? (
            !disabled && singleChoice ? (
              <Radio checked={selected} onChange={() => onRemove(user)} size={'24px'} />
            ) : (
              <CheckBox checked={selected} onChange={() => onRemove(user)} size={'24px'} />
            )
          ) : !disabled && singleChoice ? (
            <Radio checked={selected} size={'24px'} />
          ) : (
            <CheckBox checked={selected} size={'24px'} />
          )}
        </UserItemWrapper>
      )
    );
  };
}

export default withI18n()(UserItem);
