import { ProductName, ProductStatus } from '@learned/constants';
import { ICompany } from '@learned/types';

export function isProductEnabled(
  company: Pick<ICompany, 'products' | 'addons'>,
  product: ProductName,
) {
  let isAddonCheckPassed = true;

  // only for "learningAndOnboarding" - we check also if addon is enabled
  if ([ProductName.LEARNING_AND_ONBOARDING].includes(product)) {
    isAddonCheckPassed = isAddonEnabled(
      company,
      product as unknown as ProductName.LEARNING_AND_ONBOARDING,
    );
  }

  return (
    isAddonCheckPassed &&
    (company.products?.[product]?.status === ProductStatus.ACTIVE ||
      company.products?.[product]?.status === ProductStatus.TRIAL_ACTIVE)
  );
}

function isAddonEnabled(
  company: Pick<ICompany, 'addons'>,
  product: ProductName.LEARNING_AND_ONBOARDING,
) {
  return company.addons?.[product] || false;
}

export function isProductSettingEnabled(company: ICompany, product: ProductName, setting: string) {
  return (
    isProductEnabled(company, product) &&
    // eslint-disable-next-line
    // @ts-ignore
    (company.products?.[product]?.settings?.[setting]?.isEnabled || false)
  );
}
