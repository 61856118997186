import React from 'react';

import { CONFIRMATION_MODAL_TYPE, SURVEY_PARTICIPANTS_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import type { IGeneralForm } from '~/pages/SurveyUpdate/types';

import { items } from './SectionParticipantsType';

import { useAutoSaveFunc } from '~/hooks/useAutoSave';
import { COLORS } from '~/styles';

import type { UseFormReturn } from 'react-hook-form';

const Wrapper = styled.span``;

const Text = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const CurrentMode = styled(Text)`
  color: ${COLORS.BLACK};
`;

const Separator = styled(Text)`
  margin: 0 5px;
`;

const SwitchButton = styled(Text)`
  color: ${COLORS.DARK_GRAY};
  text-decoration: underline;
  cursor: pointer;
`;

interface ISectionHint {
  formMethods: UseFormReturn<IGeneralForm>;
  autosave: useAutoSaveFunc;
}
const SectionHint = ({ formMethods, autosave }: ISectionHint) => {
  const { i18n } = useLingui();
  const { watch, setValue } = formMethods;

  const type = watch('participants.type');
  const types = Object.values(SURVEY_PARTICIPANTS_TYPE).filter((i) => i !== type);
  const defineTranslation = (type: SURVEY_PARTICIPANTS_TYPE) => items[type].title(i18n);

  const onClick = async () => {
    const isConfirmed = (await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Are you sure want to change current mode?`),
      description: i18n._(
        t`Switching to another mode will deselect all current selections and has impact on survey results. This action cannot be undone.`,
      ),
    })) as unknown as boolean;

    if (isConfirmed) {
      setValue('participants.type', SURVEY_PARTICIPANTS_TYPE.EMPLOYEES); // set default value for participants type
      setValue('participants.ids', []);
      autosave.run('participants.type');
    }
  };

  return (
    <Wrapper>
      <CurrentMode>{i18n._(t`Current mode: ${defineTranslation(type)}`)}</CurrentMode>
      <Separator>|</Separator>
      <SwitchButton onClick={onClick}>
        {i18n._(
          t`Switching to "${defineTranslation(types[0])}" or "${defineTranslation(
            types[1],
          )}" mode will deselect all current selections and has impact on survey results`,
        )}
      </SwitchButton>
    </Wrapper>
  );
};

export { SectionHint };
