import React, { useEffect, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import styled from 'styled-components';

import AutocompleteFilter from '~/components/AutocompleteFilter';
import Button from '~/components/Button';
import SearchSelectButton from '~/components/SearchSelectButton';
import { SearchField } from '~/components/Text';
import TextField from '~/components/TextField';

import { LOGS_TYPES, LOGS_CATEGORIES } from '~/constants';
import { getLogsAdmins } from '~/services/logs';
import { getAdminValue } from '~/utils/logsUtils';

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  height: 32px;
`;

const NameSearch = styled(SearchField)`
  width: 248px;
  margin-right: 8px;
  ${TextField} {
    border-radius: 6px;
    font-size: 14px;
    height: 32px;
  }
`;

const SearchSelect = styled(SearchSelectButton)`
  height: 30px;
  width: auto;
  margin-right: 8px;
`;

const ApplyButton = styled(Button)`
  margin-left: auto;
  height: 20px;
`;

const AutocompleteFilterLogsAdmins = ({ i18n, ...props }) => {
  const fetchLogsAdmins = async (search) => {
    const filters = { limit: 20 };
    if (search) {
      filters.search = search;
    }
    const admins = await getLogsAdmins(filters);

    const result = {};
    Object.values(admins).map((admin) => {
      result[admin] = { key: admin, id: admin, name: getAdminValue(i18n, admin) };
    });

    return result;
  };

  return (
    <AutocompleteFilter placeholder={<Trans>Admin</Trans>} fetch={fetchLogsAdmins} {...props} />
  );
};

const LogsAdminsFilter = styled(AutocompleteFilterLogsAdmins)`
  height: 32px;
  width: auto;
  margin-right: 8px;
  padding-right: 18px;
`;

const typeFilters = map(LOGS_TYPES);
const categoryFilters = map(LOGS_CATEGORIES);

const GraphFiltersBar = ({ filters, disabled = false, applyFilters = async () => {}, i18n }) => {
  const [newFilters, setNewFilters] = useState(filters);
  const [filtersChanged, setFiltersChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFiltersChanged(!isEqual(filters, newFilters));
  }, [filters, newFilters]);

  useEffect(() => {
    setNewFilters(filters);
  }, [filters]);

  const onApplyClick = async () => {
    setLoading(true);
    await applyFilters(newFilters);
    setLoading(false);
  };

  const setSelectedAdmins = (filter) => {
    setNewFilters({ ...newFilters, selectedAdmins: filter });
  };

  const setSelectedCategories = (filter) => {
    setNewFilters({ ...newFilters, selectedCategories: filter });
  };

  const setSelectedTypes = (filter) => {
    setNewFilters({ ...newFilters, selectedTypes: filter });
  };

  return (
    <FiltersContainer>
      <NameSearch
        onChange={(e) => setNewFilters({ ...newFilters, search: e.target.value })}
        placeholder={i18n._(t`Search item`)}
        value={newFilters.search}
      />
      <LogsAdminsFilter
        checkedList={newFilters.selectedAdmins}
        title={i18n._(t`Admin`)}
        onChange={setSelectedAdmins}
        i18n={i18n}
      />
      <SearchSelect
        checkedList={newFilters.selectedCategories}
        options={categoryFilters}
        title={i18n._(t`Category`)}
        handleChange={setSelectedCategories}
      />
      <SearchSelect
        checkedList={newFilters.selectedTypes}
        options={typeFilters}
        title={i18n._(t`Type`)}
        handleChange={setSelectedTypes}
      />
      <ApplyButton
        disabled={!filtersChanged || disabled}
        loading={loading}
        onClick={onApplyClick}
        styles={{
          height: '32px',
          fontSize: '14px',
          width: 'auto',
        }}
        label={<Trans>Apply filters</Trans>}
      />
    </FiltersContainer>
  );
};

export default withI18n()(GraphFiltersBar);
