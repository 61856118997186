import styled from 'styled-components';

import { COLORS } from '~/styles';

const Wrapper = styled.div<{ expanded: boolean }>`
  width: ${({ expanded }) => (expanded ? '100%' : '1000px')};
  margin: 0 auto;
  .skill-button {
    cursor: pointer;
  }
`;

const HeaderWrapper = styled.div`
  flex: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  background-color: ${COLORS.WHITE};
  border-radius: 6px 6px 0 0;
`;

const BodyWrapper = styled.div<{ isHavingPadding: boolean }>`
  background-color: ${COLORS.WHITE};
  padding: ${({ isHavingPadding }) => (isHavingPadding ? '20px' : '0px')};
  font-size: 14px;
  height: auto;
  box-sizing: border-box;
  border-radius: 6px;
`;

export { HeaderWrapper, BodyWrapper, Wrapper };
