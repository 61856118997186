import React, { useEffect } from 'react';

import { API_RETURN_FIELDS, ROLES } from '@learned/constants';
import { IPath, IActivity } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import qs from 'qs';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import Button, { ButtonTypes } from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import OverviewHeading from '~/components/OverviewHeading';
import SetupFlow from '~/components/SetupFlow';
import PathStepContent from '~/pages/PathSetup/steps/PathStepContent';
import { PathStepSetup } from '~/pages/PathSetup/steps/PathStepSetup';

import { PATH_STATUSES } from '~/constants';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { getSettingsRole, getUser } from '~/selectors/baseGetters';
import { getCurrentPath } from '~/selectors/currentPath';
import { getPath, createPath, updatePath, deletePath, updatePathPublished } from '~/services/paths';
import * as currentPathActions from '~/store/currentPath/actions';
import { COLOR_SET } from '~/styles';

const ButtonWrapper = styled.div`
  margin-right: 24px;
  display: flex;
`;

const NoPermissionPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
`;

const PAGE_WIDTH = '998px';

const STEPS = [
  {
    key: 'setup',
    component: PathStepSetup,
    title: (i18n: any) => i18n._(t`Set-up the path`),
    requiredProps: ['name'],
  },
  {
    key: 'content',
    component: PathStepContent,
    title: (i18n: any) => i18n._(t`Determine the content`),
    requiredProps: [
      (path: IPath) => get(path, 'sections', []).length > 0,
      (path: IPath) => get(path, 'activities', []).length > 0,
    ],
  },
];

const PathSetup = () => {
  const $isLoading = useBoolState(true);
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const history = useHistory();
  const match: { params: { pathId?: string } } = useRouteMatch();
  const location = useLocation();
  const path = useSelector(getCurrentPath);
  const currentUser = useSelector(getUser);
  const settingsRole = useSelector(getSettingsRole);
  const { pathId } = match.params;
  const { search } = location;
  const query = qs.parse(search, { ignoreQueryPrefix: true });
  const { copiedPath } = query;
  const isDraft = path.status === PATH_STATUSES.DRAFT.key;
  const isOwner =
    !path.id || (path.owners || []).includes(currentUser.id) || settingsRole === ROLES.ADMIN;

  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      // update path
      if (pathId) {
        const { data } = await getPath(pathId, { populate: ['activities'] });

        if (isMounted) {
          dispatch(currentPathActions.setCurrentPath(data[API_RETURN_FIELDS.PATH]));
          $isLoading.off();
        }
        return;
      }

      // copy path
      if (copiedPath) {
        const { data } = await getPath(query.copiedPath, { populate: ['activities'] });
        const path = data[API_RETURN_FIELDS.PATH];
        if (path) {
          const copy = pick(path, [
            'name',
            'description',
            'image',
            'activities',
            'activitiesProps',
            'sections',
          ]);
          if (isMounted) {
            dispatch(currentPathActions.setCurrentPath(copy));
            $isLoading.off();
          }
        }
        return;
      }

      // create path
      if (isMounted) {
        dispatch(currentPathActions.setCurrentPath({}));
        $isLoading.off();
      }
    };

    fetch();

    return () => {
      isMounted = false;

      // clear redux-store
      dispatch(currentPathActions.setCurrentPath({}));
    };

    // eslint-disable-next-line
  }, []);

  const onSubmit = async (isDraft = false) => {
    const { pathId } = match.params;
    $isLoading.on();
    const { users: preSelectedUsersIds } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const isUpdatePublished = pathId && path.status === PATH_STATUSES.ACTIVE.key; // update published path

    const dataForStore = {
      name: path.name,
      description: path.description,
      image: path.image,
      activities: (path.activities || []).map((a: IActivity) => a.id || a),
      activitiesProps: path.activitiesProps || {},
      // @ts-ignore
      sections: (path.sections || []).map(({ id: _id, ...s }) => s),
      status: isDraft ? PATH_STATUSES.DRAFT.key : PATH_STATUSES.ACTIVE.key,
      isAvailableInUserLibrary: path.isAvailableInUserLibrary,

      // add users only for create or update (not published)
      ...(!isUpdatePublished && { users: isDraft ? [] : preSelectedUsersIds || [] }), // draft should not have preselected users
    };

    try {
      if (isUpdatePublished) {
        // update published path
        await updatePathPublished(pathId, dataForStore);
      } else {
        // create path or update draft
        pathId ? await updatePath(pathId, dataForStore) : await createPath(dataForStore);
      }
    } finally {
      $isLoading.off();
    }

    handleClose();
  };

  const validate = () => {
    return STEPS.every((step) => {
      const required = step.requiredProps || [];
      if (required.length === 0) {
        return true;
      }

      // @ts-ignore
      return required.every((prop) => {
        if (typeof prop === 'function') {
          return prop(path);
        }
        return !isEmpty(path[prop]);
      });
    });
  };

  const handleSaveDraft = () => onSubmit(true);
  const handleSubmit = () => onSubmit(false);

  const handleDeleteDraft = async () => {
    const confirmMessage = i18n._(
      t`Are you sure? This will delete this path for all participants. This action cannot be undone.`,
    );
    if (await confirm(i18n, confirmMessage)) {
      try {
        $isLoading.on();
        await deletePath(pathId);
        // eslint-disable-next-line
        // @ts-ignore
        handleClose(routes.ONBOARD_AND_LEARN.build({ role: ROLES.USER }, { hash: 'all' }));
      } finally {
        $isLoading.off();
      }
    }
  };

  const handleClose = (route?: string) => {
    const href = window.location.href;
    const url = new URL(href);

    const from = location.hash.slice(1)
      ? url.searchParams.get('from') + '#' + location.hash.slice(1)
      : url.searchParams.get('from');

    const backPath =
      // @ts-ignore
      route || from || routes.ONBOARD_AND_LEARN.build({ role: ROLES.USER }, { hash: 'all' });

    history.push(backPath);
    dispatch(currentPathActions.setCurrentPath({}));
  };

  return (
    <>
      {/* @ts-ignore */}
      <OverviewHeading
        title={`${i18n._(t`Paths`)}`}
        onBack={handleClose}
        noPadding
        maxWidth={PAGE_WIDTH}
      >
        {isOwner && (
          <>
            {pathId && (
              <ButtonWrapper>
                <Button
                  type={ButtonTypes.linkPrimary}
                  label={i18n._(t`Delete`)}
                  onClick={handleDeleteDraft}
                  loading={$isLoading.value}
                  styles={{ color: COLOR_SET.ORANGE2 }}
                />
              </ButtonWrapper>
            )}
            {(isDraft || (path && !path.id)) && (
              <ButtonWrapper>
                <Button
                  type="primary-border"
                  label={i18n._(t`Save draft`)}
                  onClick={handleSaveDraft}
                  loading={$isLoading.value}
                  disabled={!path.name}
                  tooltip={i18n._(
                    t`Click 'Save as draft' if you want to complete the path at a later stage`,
                  )}
                />
              </ButtonWrapper>
            )}

            <Button
              label={i18n._(isDraft || (path && !path.id) ? t`Publish` : t`Save`)}
              onClick={handleSubmit}
              loading={$isLoading.value}
              disabled={!validate()}
              tooltip={
                isDraft || (path && !path.id)
                  ? i18n._(t` Click 'Complete' if you want to start assigning the path to members`)
                  : i18n._(t`Click 'Update' If you want to save the changes`)
              }
            />
          </>
        )}
      </OverviewHeading>

      {isOwner ? (
        // @ts-ignore
        <SetupFlow
          onSubmit={handleSubmit}
          item={path}
          steps={STEPS}
          hideHeader={true}
          loading={$isLoading.value}
          setupLayoutWidth={PAGE_WIDTH}
          isHidePublishButton
        />
      ) : (
        !$isLoading.value && (
          <NoPermissionPlaceholder>
            {i18n._(t`You do not have permission to edit path!`)}
          </NoPermissionPlaceholder>
        )
      )}
    </>
  );
};

export default PathSetup;
