import React from 'react';

import styled from 'styled-components';

import BoxWithBorder from '~/components/UI/BoxWithBorder';

import { Goals } from './Goals';

import type { IGoalsProps } from '../types';

const TabContainer = styled.div`
  margin: 19px 39px;

  .user-public-meetings_header {
    margin-bottom: 24px;
  }
`;

const GoalsTab = ({ isPublicPage, forUser, currentUserId }: IGoalsProps) => {
  return (
    <BoxWithBorder>
      <TabContainer>
        <Goals isPublicPage={isPublicPage} forUser={forUser} currentUserId={currentUserId} />
      </TabContainer>
    </BoxWithBorder>
  );
};

export { GoalsTab };
