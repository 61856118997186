import styled from 'styled-components';

import BoxWithBorder from '~/components/UI/BoxWithBorder';

import { COLORS } from '~/styles';

export const TemplateCard = styled(BoxWithBorder)`
  padding: 24px 24px 32px;
  margin-bottom: 16px;
  h3 {
    margin-block-start: 0;
  }

  h2 {
    margin-block-start: 0;
    margin-block-end: 10px;
  }

  input {
    font-weight: 600;
    line-height: 1.38;
    border-radius: 3px;
    border: 1px solid ${(props) => (props.$error ? COLORS.ERROR : COLORS.BORDER_HARD)};
    box-sizing: border-box;
    height: 48px;

    &::placeholder {
      font-weight: 500;
      color: ${COLORS.TEXT_SECONDARY};
    }
  }
`;

export const TemplateCardWithFooter = styled(TemplateCard)`
  padding: 0;
  position: relative;
  overflow: hidden;
  border-top: ${(props) => props.$cropBorder && props.$isActive && 'none'};
  border-top-left-radius: ${(props) =>
    (props.$cropBorder && props.$isActive) || props.$removeTopLeftRadius ? '0' : ''};
  border-left: ${(props) => (props.$isActive ? '8px solid var(--company-color)' : '')};
  border-color: ${(props) => (props.$isError ? COLORS.ERROR : '')};
  border: ${(props) => (props.$isSelected ? '1px solid var(--company-color)' : '')};
`;

export const TemplateQuestionCardWithFooter = styled(TemplateCard)`
  padding: 0;
  position: relative;
  border-color: ${(props) =>
    props.$isError ? COLORS.ERROR : props.$isActive ? 'var(--company-color)' : ''};
  border-width: 2px;
  border-left-width: ${(props) => (props.$isError && props.$isOpen ? '4px' : '2px')};
`;

export const CardContent = styled.div`
  padding: ${(props) => (props.$isActive ? '24px 24px 41px 16px' : '24px 24px 41px')};
`;

export const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  color: #abb8ce;
`;
