import React from 'react';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const SortDownIcon = ({ size, className, fill }) => (
  <InlineSvg
    width={size}
    height={size}
    className={className}
    fill={fill}
    d="M10 12.998v-2L18 11v2l-8-.002zm0 6v-2L14 17v2l-4-.002zM10 7V4.998h12V7H10zm-4 9.998h2.5l-3.5 3.5-3.5-3.5H4V4h2v12.998z"
  />
);

SortDownIcon.defaultProps = {
  size: 24,
};

export default SortDownIcon;
