import styled from 'styled-components';

import { COLORS, COLOR_PALETTE } from '~/styles';

export const Header1 = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.67;
  color: ${COLORS.TEXT_BLACK};
`;

export const Header2 = styled.h2`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${COLORS.TEXT_BLACK};
`;

// ***** H3 *******

export const Header3 = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.63;
  color: ${COLORS.TEXT_BLACK};
`;

export const Header3Dark = styled(Header3)`
  color: ${COLORS.TEXT_SECONDARY};
`;

export const Header3Active = styled(Header3)`
  color: ${COLORS.TEXT_ACTIVE};
`;

export const Header3Light = styled(Header3)`
  color: ${COLORS.TEXT_SECONDARY};
`;

export const Header3White = styled(Header3)`
  color: ${COLOR_PALETTE.WHITE};
`;

// ***** H4 *******

export const Header4 = styled.h4`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${COLORS.TEXT_BLACK};
`;

export const Header4Dark = styled(Header4)`
  color: ${COLORS.TEXT_SECONDARY};
`;

export const Header4Active = styled(Header4)`
  color: ${COLORS.TEXT_ACTIVE};
`;

export const Header4Light = styled(Header4)`
  color: ${COLORS.TEXT_SECONDARY};
`;

export const Header4White = styled(Header4)`
  color: ${COLOR_PALETTE.WHITE};
`;

// ***** H5 *******

export const Header5 = styled.h5`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.57;
  color: ${COLORS.TEXT_BLACK};
`;

export const Header5Dark = styled(Header5)`
  color: ${COLORS.TEXT_SECONDARY};
`;

export const Header5Active = styled(Header5)`
  color: ${COLORS.TEXT_ACTIVE};
`;

export const Header5Light = styled(Header5)`
  color: ${COLORS.TEXT_SECONDARY};
`;

export const Header5White = styled(Header5)`
  color: ${COLOR_PALETTE.WHITE};
`;

// ***** H6 *******

export const Header6 = styled.h6`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  color: ${COLORS.TEXT_BLACK};
`;

export const Header6Dark = styled(Header6)`
  color: ${COLORS.TEXT_SECONDARY};
`;

export const Header6Active = styled(Header6)`
  color: ${COLORS.TEXT_ACTIVE};
`;

export const Header6Light = styled(Header6)`
  color: ${COLORS.TEXT_SECONDARY};
`;

export const Header6White = styled(Header6)`
  color: ${COLOR_PALETTE.WHITE};
`;

// ***** H7(h6) *******

export const Header7 = styled.h6`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  color: ${COLORS.TEXT_BLACK};
`;

export const Header7Dark = styled(Header7)`
  color: ${COLORS.TEXT_SECONDARY};
`;

export const Header7Active = styled(Header7)`
  color: ${COLORS.TEXT_ACTIVE};
`;

export const Header7Light = styled(Header7)`
  color: ${COLORS.TEXT_SECONDARY};
`;

export const Header7White = styled(Header7)`
  color: ${COLOR_PALETTE.WHITE};
`;
