import React from 'react';

import { CONFIRMATION_MODAL_TYPE, ROLES, TASK_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useParams } from 'react-router-dom';

import RocketIcon from '~/components/Icons/Rocket';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import { useFromQuery } from '~/hooks/useFromQuery';
import { useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { RoleGuard } from '~/hooks/useRoleGuard';
import { changeTaskStatus } from '~/services/tasks';

import { GiveReview } from '../components/GiveReview';
import { InputNoLongerPossible } from '../components/InputNoLongerPossible';
import { useReviewTask } from '../hooks';

function PeerReview() {
  const params: Record<string, string> = useParams();
  const languageState = useLanguageState();
  const { goBack } = useFromQuery({ includeHash: true });
  const { i18n } = useLingui();
  const reviewTaskId = params.taskId;

  const { reviewTask, userReview, isLoading } = useReviewTask({ reviewTaskId });
  const introTitle = i18n._(t`Provide peer review`);

  const onDecline = async () => {
    if (
      await confirm({
        title: i18n._(t`Decline request?`),
        description: i18n._(
          t`Are you sure you want to decline this request? This action cannot be undone.`,
        ),
        type: CONFIRMATION_MODAL_TYPE.WARNING,
      })
    ) {
      await changeTaskStatus(reviewTaskId, TASK_STATUS.DECLINED);
      goBack();
    }
  };

  return (
    <RoleGuard role={ROLES.USER}>
      <ShowSpinnerIfLoading loading={isLoading}>
        {reviewTask && userReview && (
          <GiveReview
            key={reviewTask.id}
            reviewTask={reviewTask}
            userReview={userReview}
            introTitle={introTitle}
            onDecline={onDecline}
            userFrom={reviewTask.userFrom}
            languageState={languageState}
            useMultiLangString={useMultiLangString}
            InputNoLongerPossibleScreen={<InputNoLongerPossible />}
          />
        )}
        {(!reviewTask || !userReview) && (
          <Placeholder title={i18n._(t`No review task`)} Icon={RocketIcon} />
        )}
      </ShowSpinnerIfLoading>
    </RoleGuard>
  );
}

export { PeerReview };
