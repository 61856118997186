import React, { ReactNode } from 'react';

import styled from 'styled-components';

import Switch from '~/components/Switch';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  min-height: 64px;
  padding: 0px 20px;
  border-radius: 6px;
  background-color: ${COLORS.BG_LIST};
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LeftBlock = styled.div`
  display: flex;
  align-items: flex-start;

  .optionalDescription {
    margin-left: 50px;
    font-size: 14px;
  }
`;

const RightBlock = styled.div`
  display: flex;
  align-items: flex-end;
`;

const SwitchWrapper = styled.div`
  margin-right: 10px;
`;

interface ISectionRowProps {
  title: string;
  description?: string;
  toggle?: {
    value: boolean;
    onChange: (value: boolean) => void;
  };
  actions?: ReactNode;
}
const SectionRow = ({ title, toggle, actions, description }: ISectionRowProps) => {
  return (
    <Wrapper>
      <LeftBlock>
        {toggle && (
          <SwitchWrapper>
            <Switch onChange={toggle.onChange} checked={toggle.value} />
          </SwitchWrapper>
        )}
        <div>
          {title} <span className="optionalDescription">{description}</span>
        </div>
      </LeftBlock>
      <RightBlock>{actions}</RightBlock>
    </Wrapper>
  );
};

export { SectionRow };
