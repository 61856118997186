import React from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { COLOR_SET } from '~/styles';

const Label = styled.span`
  font-size: 14px;
  border-radius: 3px;
  padding: 3px 7px;
  color: black;
  font-weight: 600;
`;

const CycleLabel = styled(Label)`
  background-color: #ebf1fe;
  color: #6b78a4;
`;

const BusinessLabel = styled(Label)`
  background: linear-gradient(
    85deg,
    ${COLOR_SET.BUSINESS_DARK_GRADIENT} 27%,
    ${COLOR_SET.BUSINESS_LIGHT_GRADIENT} 88%
  );
`;
const LearningLabel = styled(Label)`
  background: linear-gradient(
    83deg,
    ${COLOR_SET.LEARNING_DARK_GRADIENT} 14%,
    ${COLOR_SET.LEARNING_LIGHT_GRADIENT} 87%
  );
`;

function GoalCycleTitle({ isSelf, questionType, cycleName, userName }) {
  if (isSelf) {
    if (questionType === QUESTION_TYPES.GOAL_BUSINESS_EVAL) {
      return (
        <div>
          <Trans
            id="How did you perform on the <0>Business goals</0> in <1>{cycle}</1>"
            components={[<BusinessLabel key={0} />, <CycleLabel key={1} />]}
            values={{ cycle: cycleName }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <Trans
            id="How did you perform on the <0>Learning goals</0> in <1>{cycle}</1>"
            components={[<LearningLabel key={0} />, <CycleLabel key={1} />]}
            values={{ cycle: cycleName }}
          />
        </div>
      );
    }
  } else {
    if (questionType === QUESTION_TYPES.GOAL_BUSINESS_EVAL) {
      return (
        <div>
          <Trans
            id="How did {name} perform on the <0>Business goals</0> in <1>{cycle}</1>"
            components={[<BusinessLabel key={0} />, <CycleLabel key={1} />]}
            values={{ name: userName, cycle: cycleName }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <Trans
            id="How did {name} perform on the <0>Learning goals</0> in <1>{cycle}</1>"
            components={[<LearningLabel key={0} />, <CycleLabel key={1} />]}
            values={{ name: userName, cycle: cycleName }}
          />
        </div>
      );
    }
  }
}

export { GoalCycleTitle };
