import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';

import SelectUsersModal from '~/components/SelectUsersModal';

import useBoolState from '~/hooks/useBoolState';
import { getUser } from '~/selectors/baseGetters';
import getCurrentTeam from '~/selectors/getCurrentTeam';
import { inviteMembersToTeam } from '~/services/teams';
import * as teamActions from '~/store/teams/actions';

const InviteTeamModal = ({ onClose }) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const $loading = useBoolState(false);
  const user = useSelector(getUser);
  const users = useSelector((state) => state.users.data);
  const currentTeam = useSelector(getCurrentTeam);

  const inviteUsers = async (selectedUsers) => {
    const inviteUsers = selectedUsers.map((u) => (u.id ? u.id : u));
    const teamId = currentTeam.id;

    if (!isEmpty(inviteUsers)) {
      $loading.on();
      await inviteMembersToTeam(inviteUsers, teamId);
      $loading.off();
    }

    // update teams in store
    dispatch(teamActions.getTeams());
    onClose();
  };

  const freeUsers = !isEmpty(users)
    ? Object.values(users).filter(
        (u) =>
          u.id !== user.id &&
          !currentTeam.members.includes(u.id) &&
          !currentTeam.membersRequested.includes(u.id) &&
          !currentTeam.coaches.includes(u.id),
      )
    : [];

  return (
    <SelectUsersModal
      items={freeUsers}
      onModalClose={onClose}
      onSubmit={inviteUsers}
      loading={$loading.value}
      title={i18n._(t`Invite members`)}
    />
  );
};

export default InviteTeamModal;
