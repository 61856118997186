import { useEffect, useState } from 'react';

import useBoolState from '~/hooks/useBoolState';

import type { UseDraggableModalProps } from './types';

export const useDraggableModal = ({ initSize }: UseDraggableModalProps) => {
  const isFullSize = useBoolState(false);
  const isDragging = useBoolState(false);
  const [position, setPosition] = useState({ x: initSize.x, y: initSize.y });
  const [size, setSize] = useState({ width: initSize.width, height: initSize.height });
  const [history, setHistory] = useState(initSize);

  useEffect(() => {
    const fullWidth = window.innerWidth - 140;
    const fullHeight = window.innerHeight - 140;

    if (isFullSize.value) {
      setSize({
        width: fullWidth,
        height: fullHeight,
      });
      setPosition({
        x: 70,
        y: 70,
      });
      setHistory({ ...size, ...position });
    } else {
      setSize({
        width: history.width,
        height: history.height,
      });
      setPosition({
        x: history.x,
        y: history.y,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initSize.height, initSize.width, initSize.x, initSize.y, isFullSize.value]);

  return { isFullSize, position, setPosition, size, setSize, isDragging };
};
