import React, { forwardRef, useEffect } from 'react';
import type { ComponentType, ReactNode } from 'react';

import { ROLES } from '@learned/constants';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import routes from '~/constants/routes';
import { getCurrentCompanyId, getUser } from '~/selectors/baseGetters';

import type { IConnection } from '@learned/types';

function useRoleGuard(role: ROLES) {
  const history = useHistory();
  const user = useSelector(getUser);
  const currentCompanyId = useSelector(getCurrentCompanyId);

  const currentCompanyConnection = user.connections.find(
    (connection: IConnection) => connection.company === currentCompanyId,
  );

  useEffect(() => {
    if (
      currentCompanyConnection === undefined ||
      ![ROLES.USER, ...currentCompanyConnection.roles].includes(role)
    ) {
      history.push(routes.HOME);
    }
  }, [currentCompanyConnection, history, role]);

  return;
}

function withRoleGuard<T>(OriginalComponent: ComponentType<T>, role: ROLES) {
  return forwardRef<unknown, T>((props, ref) => {
    useRoleGuard(role);

    return <OriginalComponent {...(props as T)} ref={ref} />;
  });
}

function RoleGuard({ children, role }: { children: ReactNode; role: ROLES }) {
  useRoleGuard(role);

  return <>{children}</>;
}

export { useRoleGuard, withRoleGuard, RoleGuard };
