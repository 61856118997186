import React from 'react';

import { Dot, ErrorDot, InactiveDot, NoCircle } from '~/components/TableOfContents/design';
import { SECTION_STATUS } from '~/components/TableOfContents/types';

function ChildCircle({ status }: { status?: SECTION_STATUS }) {
  return (
    <>
      {status === SECTION_STATUS.DONE && (
        <NoCircle>
          <Dot />
        </NoCircle>
      )}
      {status === SECTION_STATUS.CURRENT && (
        <NoCircle>
          <Dot />
        </NoCircle>
      )}
      {status === SECTION_STATUS.ERROR && (
        <NoCircle>
          <ErrorDot />
        </NoCircle>
      )}
      {status === SECTION_STATUS.LOCKED && (
        <NoCircle>
          <InactiveDot />
        </NoCircle>
      )}
      {!status && (
        <NoCircle>
          <InactiveDot />
        </NoCircle>
      )}
    </>
  );
}

export { ChildCircle };
