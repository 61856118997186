import React from 'react';

import { useForm } from 'react-hook-form';

import { ReviewEmployeeOverviewForm } from './ReviewEmployeeOverviewForm';

import { useLanguageState } from '~/hooks/useLanguageState';

import type { IReviewEmployeeOverviewForm } from './ReviewEmployeeOverviewForm/types';
import type { IUserReview, IReview, ITask } from '@learned/types';

interface IReviewEmployeeOverviewModalProps {
  tasks: IReview['tasks'];
  userReviewId: IUserReview['id'];
  onClose: () => void;
}

const ReviewEmployeeOverviewModal = ({
  tasks,
  onClose,
  userReviewId,
}: IReviewEmployeeOverviewModalProps) => {
  const languageState = useLanguageState();

  const formMethods = useForm<IReviewEmployeeOverviewForm>({
    mode: 'all',
    defaultValues: {
      tasksSelf: [],
      tasksReceivedPeers: [],
      tasksReceivedCoaches: [],
      taskNominatePeers: null,
      tasksProvidedPeers: [],
      tasksProvidedCoaches: [],
      guests: [],
      coaches: [],
      signatures: [],
      settings: {},
    },
  });

  const tasksTypes = Object.keys(tasks || {}) as ITask['type'][];

  return (
    <ReviewEmployeeOverviewForm
      userReviewId={userReviewId}
      tasksTypes={tasksTypes}
      formMethods={formMethods}
      languageState={languageState}
      onClose={onClose}
    />
  );
};

export { ReviewEmployeeOverviewModal };
