import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { TextField } from '~/components/Text';

import { COLOR_PALETTE, COLORS } from '~/styles';

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  white-space: nowrap;
`;

const LabelBefore = styled(Label)`
  margin-right: 8px;
`;

const LabelAfter = styled(Label)`
  margin-left: 8px;
`;

const ErrorLabel = styled.div`
  background: ${COLORS.ERROR};
  color: white;
  padding: 2px 5px;
  margin-left: 8px;
  border-radius: 6px;
`;

const RowWithField = ({ labelBefore, labelAfter, value, onChange, onBlur, isError, tooltip }) => {
  return (
    <Row>
      <LabelBefore>{labelBefore}</LabelBefore>
      <TextField
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={(e) => !isError && onBlur(e.target.value)}
        style={{ width: '44px' }}
        error={isError}
      />
      <LabelAfter>{labelAfter}</LabelAfter>
      {isError && tooltip && <ErrorLabel>{tooltip}</ErrorLabel>}
    </Row>
  );
};

RowWithField.propTypes = {
  labelBefore: PropTypes.string,
  labelAfter: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  tooltip: PropTypes.string,
  isError: PropTypes.bool,
};

export default RowWithField;
