import React from 'react';

import { ISurveyTemplate } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { StepSection } from '~/pages/SurveyUpdate/components';
import { TemplateRow } from '~/pages/SurveyUpdate/components/StepChooseTemplate/TemplateRow';
import type { IGeneralForm } from '~/pages/SurveyUpdate/types';

import type { UseFormReturn } from 'react-hook-form';

const Wrapper = styled.div``;

interface ISectionTemplates {
  formMethods: UseFormReturn<IGeneralForm>;
  onEdit: () => void;
  onTemplatePreview: (item: ISurveyTemplate) => void;
}

const SectionTemplates = ({ formMethods, onEdit, onTemplatePreview }: ISectionTemplates) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;
  const selectedTemplate = watch('template') as ISurveyTemplate;

  return (
    <StepSection title={i18n._(t`Templates`)} onEdit={onEdit}>
      <Wrapper>
        {selectedTemplate ? (
          <TemplateRow
            item={selectedTemplate}
            isFirst={true}
            isLast={true}
            isSelected={true}
            onPreview={onTemplatePreview}
          />
        ) : (
          i18n._(t`Template is not selected. Please select template`)
        )}
      </Wrapper>
    </StepSection>
  );
};

export { SectionTemplates };
