import React, { useState } from 'react';

import { Trans } from '@lingui/macro';
import nanoid from 'nanoid';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useFieldArray } from 'react-hook-form';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import {
  AddNewFocusAreaButton,
  Count,
  DroppableContainer,
  FocusArea,
  IconWrapper,
  Level,
  LevelHeader,
  LevelName,
  StyledIcon,
} from './design';
import { FocusAreaModal } from './FocusAreaModal';

import type { IBaseLanguageStateReturn } from '~/hooks/useLanguageState';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { getSuperAdminMultiLangString } from '~/utils/superAdmin';

import type { IGeneralForm } from '../types';
import type { IMultiLangString } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

type FocusAreasProps = {
  levelName: IMultiLangString;
  index: number;
  formMethods: UseFormReturn<IGeneralForm>;
  languageState: IBaseLanguageStateReturn;
  readonly?: boolean;
};

function FocusAreas({
  levelName,
  index,
  formMethods,
  languageState,
  readonly = false,
}: FocusAreasProps) {
  const { control, watch } = formMethods;
  const skillName = watch('name');
  const focusAreasArray = useFieldArray({ control, name: `focusAreas.${index}.values` });
  const { fields, remove, insert, update } = focusAreasArray;
  const [editingFocusArea, setEditingFocusArea] = useState<{
    name: IMultiLangString;
    _id: string;
  }>();
  const [creatingFocusArea, setCreatingFocusArea] = useState(false);
  const [opened, setOpened] = useState(false);

  const createFocusArea = (newValues: { name: IMultiLangString }) => {
    insert(fields.length, { name: newValues.name, _id: nanoid() });
  };

  const updateFocusArea = (newValues: { name: IMultiLangString; _id: string }) => {
    const aliasIndex = fields.findIndex((focusArea) => focusArea._id === newValues._id);
    update(aliasIndex, newValues);
  };

  const deleteFocusArea = (id: string) => {
    const aliasIndex = fields.findIndex((focusArea) => focusArea.id === id);
    remove(aliasIndex);
  };

  return (
    <>
      <Level>
        <LevelHeader onClick={() => setOpened((prev) => !prev)}>
          <LevelName>{getSuperAdminMultiLangString(levelName, languageState)}</LevelName>
          <Count>
            <Trans>{fields.length} focus area&apos;s</Trans>
            <StyledIcon icon={ICONS.DROPDOWN} size={ICON_SIZES.SMALL} $opened={opened} />
          </Count>
        </LevelHeader>
        {opened && (
          <>
            <Droppable droppableId={`${index}`}>
              {(provided) => (
                <DroppableContainer
                  isEmpty={fields.length === 0}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {fields.map((field, idx) => {
                    return (
                      <Draggable
                        key={field.id}
                        draggableId={field.id}
                        index={idx}
                        isDragDisabled={readonly}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <FocusArea
                              onClick={!readonly ? () => setEditingFocusArea(field) : undefined}
                            >
                              {getSuperAdminMultiLangString(field.name, languageState)}
                            </FocusArea>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}

                  {provided.placeholder}
                </DroppableContainer>
              )}
            </Droppable>

            {!readonly && (
              <AddNewFocusAreaButton type="button" onClick={() => setCreatingFocusArea(true)}>
                <IconWrapper>
                  <Icon icon={ICONS.ADD_PLUS} size={ICON_SIZES.SMALL} />
                </IconWrapper>
              </AddNewFocusAreaButton>
            )}
          </>
        )}
        {!readonly && editingFocusArea && (
          <FocusAreaModal
            title={<Trans>Edit focus area</Trans>}
            onClose={() => setEditingFocusArea(undefined)}
            onSave={updateFocusArea}
            onDelete={deleteFocusArea}
            languageState={languageState}
            focusArea={editingFocusArea}
            skillName={getSuperAdminMultiLangString(convertLanguageValue(skillName), languageState)}
          />
        )}
        {!readonly && creatingFocusArea && (
          <FocusAreaModal
            title={<Trans>Create focus area</Trans>}
            onClose={() => setCreatingFocusArea(false)}
            onCreate={createFocusArea}
            onDelete={deleteFocusArea}
            languageState={languageState}
            skillName={getSuperAdminMultiLangString(convertLanguageValue(skillName), languageState)}
          />
        )}
      </Level>
    </>
  );
}

export { FocusAreas };
