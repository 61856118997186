import React, { Component } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import CompanyAvatar from '~/components/CompanyAvatar';
import SkillsRateModal from '~/components/SkillsRateModal';
import SpinnerStart from '~/components/SpinnerStart';
import SvgIcon from '~/components/SvgIcon';
import Tooltip from '~/components/Tooltip';
import Avatar from '~/components/UI/Avatar';
import LangDropdown from '~/components/UI/LangDropdown';
import BaseLayout from '~/layouts/BaseLayout';
import SetupLayout from '~/layouts/SetupLayout';
import RTFeedbackCard from '~/pages/RTFeedbacks/components/RTFeedbackCard';

import CheckIcon from '~/assets/icon-checkmark-rounded.svg';

import { REQUEST_STATUSES, OUTSIDE_PAGES, RATING_TYPES } from '~/constants';
import getAppThemeUrl from '~/selectors/getAppThemeUrl';
import { axiosCloud } from '~/server_config';
import { getCompanyOutside } from '~/services/companies';
import * as requestService from '~/services/requests';
import { getRTFeedback, giveRTFeedback } from '~/services/RTFeedbacks';
import { COLOR_PALETTE } from '~/styles';

const ExpiredBlockWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.35);
`;

const ExpiredBlock = styled.div`
  width: 700px;
  height: 187px;
  border-radius: 10px;
  box-shadow: 5px 10px 14px 0 rgba(55, 70, 95, 0.08);
  border: solid 0.5px #ecedf0;
  background-color: ${COLOR_PALETTE.WHITE};
  font-size: 18px;
  text-align: center;
  color: #4d4d4d;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ThanksForFeedbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ThanksForFeedbackAvatars = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const ThanksForFeedbackTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  line-height: 1.82;
  color: ${COLOR_PALETTE.BLACK};
  margin: 24px 0 16px 0;
`;

const ThanksForFeedbackSubtitle = styled.div`
  font-size: 16px;
  margin: 16px 0;
  line-height: 1.5;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LanguageSelector = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`;

const INITIAL_STATE = {
  open: false,
  loading: true,
  RTFeedback: {},
  fromUser: null,
  email: null,
  isExpiredOnOpening: false,
  isDone: false,
  feedback: '',
};

class UserRTFeedbackOutside extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    const { match } = this.props;
    const { rtfeedbackId } = match.params;

    let href = window.location.href;
    let url = new URL(href);
    let email = url.searchParams.get('email');

    axiosCloud.defaults.headers.common.page = OUTSIDE_PAGES.RT_FEEDBACK;
    axiosCloud.defaults.headers.common.email = email;
    axiosCloud.defaults.headers.common['token-outside'] = rtfeedbackId;
    this.fetchRTFeedback(this.props);
  }

  componentWillUnmount() {
    delete axiosCloud.defaults.headers.common.page;
    delete axiosCloud.defaults.headers.common.email;
    delete axiosCloud.defaults.headers.common['token-outside'];
  }

  fetchRTFeedback = (props) => {
    const { match } = props;

    // get params from url
    let { rtfeedbackId } = match.params;

    this.setState({ loading: true });
    if (rtfeedbackId) {
      getRTFeedback(rtfeedbackId, {
        isQuestions: true,
        populate: ['createdBy', 'request'],
      })
        .then(async (RTFeedback) => {
          const open = get(RTFeedback, 'request.status') !== REQUEST_STATUSES.REJECTED.key;
          const isExpired = ![
            REQUEST_STATUSES.REQUESTED.key,
            REQUEST_STATUSES.STARTED.key,
          ].includes(get(RTFeedback, 'request.status'));
          this.setState({
            RTFeedback,
            fromUser: RTFeedback.createdBy,
            open,
            isExpiredOnOpening: isExpired || !open,
            isDone: isExpired || !open,
            loading: false,
          });
          this.getCompanyLogo();
        })
        .catch((_e) => {
          this.setState({
            isExpiredOnOpening: true,
            isDone: true,
            loading: false,
          });
        });
    }
  };

  getCompanyLogo = async () => {
    const { RTFeedback } = this.state;
    const { logoUrl } = await getCompanyOutside(RTFeedback.company);
    this.setState({ logoUrl });
  };

  hideAnswerModal = () => {
    this.setState({ open: false, showMultiRateModal: false, isDone: true });
  };

  hideMultiRateModal = () => {
    this.setState({ showMultiRateModal: false });
  };

  declineRequest = async () => {
    const { RTFeedback } = this.props;

    this.setState({ loading: true });
    if (RTFeedback?.request) {
      const requestId = get(RTFeedback, 'request.id', RTFeedback.request);
      if (requestId) {
        await requestService.declineRequest(requestId);
      }
    }
    this.setState({ loading: false });
    this.hideAnswerModal();
  };

  onRated = async (isShared = false) => {
    const { RTFeedback } = this.state;

    await requestService.acceptRequest(
      RTFeedback.request.id,
      isShared ? REQUEST_STATUSES.ACCEPTED.key : null,
    );
    this.hideAnswerModal();
  };

  onSubmit = async () => {
    const { RTFeedback, feedback } = this.state;

    await giveRTFeedback(RTFeedback.id, feedback);
    this.setState({
      isDone: true,
    });
  };

  setFeedback = (feedback) => {
    this.setState({
      feedback,
    });
  };

  render() {
    const {
      fromUser,
      RTFeedback,
      isExpiredOnOpening,
      showMultiRateModal,
      feedback,
      logoUrl,
      isDone,
      open,
      loading,
    } = this.state;
    const { i18n } = this.props;

    return (
      <SetupLayout
        loading={loading}
        width={1270}
        logoUrl={!isDone && logoUrl}
        actions={
          !loading &&
          !isDone && (
            <ButtonContainer>
              <LanguageSelector>
                <LangDropdown />
              </LanguageSelector>
              <Tooltip
                tooltip={i18n._(
                  t`Ready with your feedback? Click here to share it. You cannot update your feedback afterwards.`,
                )}
              >
                <div>
                  <Button type="primary" label={i18n._(t`Share`)} onClick={this.onSubmit} />
                </div>
              </Tooltip>
            </ButtonContainer>
          )
        }
      >
        <BaseLayout>
          {isExpiredOnOpening || !open ? (
            <ExpiredBlockWrapper>
              <ExpiredBlock>
                {open ? (
                  <Trans>This feedback request expired</Trans>
                ) : (
                  <Trans>You have rejected this feedback</Trans>
                )}
              </ExpiredBlock>
            </ExpiredBlockWrapper>
          ) : (
            <div>
              {isEmpty(RTFeedback) && <SpinnerStart message={i18n._(t`Feedback request`)} />}
              {!isEmpty(RTFeedback) &&
                !isEmpty(fromUser) &&
                (isDone ? (
                  <ThanksForFeedbackWrapper>
                    <ThanksForFeedbackAvatars>
                      <CompanyAvatar logoUrl={logoUrl} size={56} />
                      <Avatar user={fromUser} size={56} />
                    </ThanksForFeedbackAvatars>
                    <ThanksForFeedbackTitle>
                      <Trans>Thanks for your feedback</Trans>
                    </ThanksForFeedbackTitle>
                    <SvgIcon width="48px" height="48px" url={CheckIcon} />
                    <ThanksForFeedbackSubtitle>
                      <Trans>Your feedback has been sent</Trans>
                    </ThanksForFeedbackSubtitle>
                  </ThanksForFeedbackWrapper>
                ) : (
                  <RTFeedbackCard
                    RTFeedback={RTFeedback}
                    onShareFeedback={this.onSubmit}
                    onRemoveRequest={this.declineRequest}
                    isOutside={true}
                    outsideFeedback={feedback}
                    setOutsideFeedback={this.setFeedback}
                  />
                ))}
            </div>
          )}
          {showMultiRateModal && (
            <SkillsRateModal
              email={RTFeedback.createdForEmail}
              type={RATING_TYPES.OUTSIDE_RATING}
              onClose={this.hideMultiRateModal}
              onRated={this.onRated}
              forUser={fromUser}
              review={RTFeedback}
              isRealTime
            />
          )}
        </BaseLayout>
      </SetupLayout>
    );
  }
}

export default withI18n()(
  connect((state) => ({
    logoUrl: getAppThemeUrl(state),
  }))(withRouter(UserRTFeedbackOutside)),
);
