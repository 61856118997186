import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import RickTextView from '~/components/RickTextView';
import AvatarCard from '~/components/UI/AvatarCard';
import Divider from '~/components/UI/Divider';

import { COLORS, COLOR_PALETTE } from '~/styles';
import convertToTimeString from '~/utils/convertToTimeString';
import getUserFullName from '~/utils/getUserFullName';

const Title = styled.div`
  color: ${COLORS.TEXT_BLACK};
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1;
  font-style: normal;
`;

const ConversationDate = styled.span`
  margin-left: 16px;
  color: ${COLORS.TEXT_SECONDARY};
  font-weight: 600;
  font-size: 16px;
`;

const Description = styled.div`
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  margin-top: 8px;
`;

const Top = styled.div`
  padding: 24px 24px 19px;
`;

const Bottom = styled.div`
  padding: 12px 20px 24px;
`;

const ReviewInfoSection = styled.div`
  display: flex;
  flex-direction: row;

  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  color: ${COLORS.TEXT_SECONDARY};
`;

const Uppercase = styled.div`
  text-transform: uppercase;
`;

const CreatedDateContainer = styled.div`
  margin-left: 27px;
  min-width: 120px;
`;

const DateContainer = styled.div`
  line-height: 1.7;
  font-size: 14px;
  color: ${COLORS.TEXT_SECONDARY};
  margin-top: 6px;
`;

const ParticipantsContainer = styled.div``;

const CoachAvatars = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 12px;
`;

const CoachAvatarCard = styled(AvatarCard)`
  margin-right: 12px;
`;

const OwnerName = styled.div`
  font-weight: normal;
  font-size: 14px;
`;

const RenderParticipants = (conversation) => {
  return (
    <ParticipantsContainer>
      <Uppercase>
        <Trans>Participants</Trans>
      </Uppercase>
      <CoachAvatars>
        {(conversation.participants || []).map((p) => {
          return (
            <CoachAvatarCard
              key={p?.id}
              userId={p?.id}
              type={AvatarCard.TYPES.SMALL}
              userFullname={getUserFullName(p)}
              userAvatar={p?.avatarUrl}
            />
          );
        })}
      </CoachAvatars>
    </ParticipantsContainer>
  );
};

const Header = ({ conversation }) => {
  return (
    <>
      <Top>
        <Title>
          {conversation.name}
          {conversation.startDate && (
            <ConversationDate>{convertToTimeString(conversation.startDate)}</ConversationDate>
          )}
        </Title>
        {conversation.description && (
          <Description>
            <RickTextView html={conversation.description} />
          </Description>
        )}
      </Top>
      <Divider $color={COLORS.BORDER_HARD} />
      <Bottom>
        <ReviewInfoSection>
          {RenderParticipants(conversation)}
          <CreatedDateContainer>
            <Uppercase>
              <Trans>Created at</Trans>
            </Uppercase>
            <DateContainer>
              {conversation &&
                conversation.meta &&
                convertToTimeString(conversation.meta.createdDate)}
            </DateContainer>
          </CreatedDateContainer>
          <CreatedDateContainer>
            <Uppercase>
              <Trans>Created by</Trans>
            </Uppercase>
            <OwnerName>{getUserFullName(conversation.createdBy)}</OwnerName>
          </CreatedDateContainer>
        </ReviewInfoSection>
      </Bottom>
    </>
  );
};

export default Header;
