import debounce from 'lodash/debounce';

export default function (func, wait = 1000) {
  const memory = {};

  return (...args) => {
    // use first argument as a key
    // its possible to use all args as a key - e.g JSON.stringify(args) or hash(args)
    const searchType = JSON.stringify(args);

    if (typeof memory[searchType] === 'function') {
      return memory[searchType](...args);
    }

    memory[searchType] = debounce(func, wait, { trailing: false, leading: true }); // leading required for return promise
    return memory[searchType](...args);
  };
}
