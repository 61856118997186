import React from 'react';

import { SURVEY_STATUS, SURVEY_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';

import Checkbox from '~/components/CheckBox';
import { StepSection } from '~/pages/SurveyUpdate/components';
import type { IGeneralForm } from '~/pages/SurveyUpdate/types';

import { useAutoSaveFunc } from '~/hooks/useAutoSave';
import { COLORS } from '~/styles';

import type { UseFormReturn } from 'react-hook-form';

const Wrapper = styled.div``;

const CheckboxRow = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  ${(props) =>
    !props.$disabled &&
    css`
      cursor: pointer;
    `}
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const CheckboxLabel = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.BLACK};
  margin-left: 20px;
`;

interface ISectionNotifications {
  formMethods: UseFormReturn<IGeneralForm>;
  autosave: useAutoSaveFunc;
}

const SectionNotifications = ({ formMethods, autosave }: ISectionNotifications) => {
  const { i18n } = useLingui();
  const { watch, setValue } = formMethods;
  const type = watch('type');
  // @ts-ignore
  const { isOnStartDate, isOn2DaysBeforeEndDate, isOnEndDate } = watch('notifications');
  const isLockedToEdit =
    watch('type') === SURVEY_TYPE.TRADITIONAL && watch('status') !== SURVEY_STATUS.DRAFT;

  const items = [
    {
      onClick: () => {
        setValue('notifications.isOnStartDate', !isOnStartDate);
        autosave.run('notifications.isOnStartDate');
      },
      value: isOnStartDate,
      label: i18n._(t`On start date`),
    },
    type === SURVEY_TYPE.TRADITIONAL
      ? {
          onClick: () => {
            setValue('notifications.isOn2DaysBeforeEndDate', !isOn2DaysBeforeEndDate);
            autosave.run('notifications.isOn2DaysBeforeEndDate');
          },
          value: isOn2DaysBeforeEndDate,
          label: i18n._(t`2 days before deadline`),
        }
      : {},
    {
      onClick: () => {
        setValue('notifications.isOnEndDate', !isOnEndDate);
        autosave.run('notifications.isOnEndDate');
      },
      value: isOnEndDate,
      label: i18n._(t`On the day of the deadline`),
    },
  ].filter((i) => !isEmpty(i));

  return (
    <StepSection
      title={i18n._(t`Notifications`)}
      description={i18n._(
        t`Send a notification to remind participants who haven't completed the survey:`,
      )}
    >
      <Wrapper>
        {items.map(({ value, onClick, label }, key) => (
          <CheckboxRow
            key={key}
            $disabled={isLockedToEdit}
            onClick={isLockedToEdit ? undefined : onClick}
          >
            {/* @ts-ignore */}
            <Checkbox size={24} checked={value} disabled={isLockedToEdit} />
            <CheckboxLabel>{label}</CheckboxLabel>
          </CheckboxRow>
        ))}
      </Wrapper>
    </StepSection>
  );
};

export { SectionNotifications };
