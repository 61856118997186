import React, { useEffect, useMemo } from 'react';

import { IConnection } from '@learned/types';
import { useFieldArray, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';

import { Comment } from './Comment';

import type { IQuestionForm } from '~/@types/question';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';
import { getMultiLangString as getMultiLangStringOriginal } from '~/utils/getMultiLangString';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import type { ICompany, ILanguage, ISurveyRating } from '@learned/types';

interface IQuestionViewSmileysProps {
  defaultValues: IQuestionForm;
  languageState: ILanguageStateReturn;
  isPreselectedLang?: boolean;
  error?: boolean;
  commentError?: boolean;
  isPreferredLanguage?: boolean;
  onChange?: (data: {
    answer?: ISurveyRating['answer'];
    comment?: ISurveyRating['comment'];
  }) => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;

  & .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const Title = styled.div`
  font-size: 26px;
  color: ${COLORS.BLACK};
  text-align: center;
  margin-bottom: 48px;
`;

const AnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconWrapper = styled.div<{ color?: string; error?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-width: 3px;
  border-style: solid;
  border-color: transparent;
  border-radius: 100px;
  padding: 0;
  margin: 0 auto;
  width: 48px;
  height: 48px;

  & .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 4px;
    width: 44px;
    height: 44px;
    font-size: 54px;
  }
`;

const Option = styled.button<{ isSelected?: boolean }>`
  font-size: 14px;
  cursor: pointer;
  padding-right: min(20, 5%);
  padding-left: min(20, 5%);
  width: 105px;

  & .head {
    height: 70px;
  }

  & .label {
    white-space: pre-wrap;
    word-wrap: break-word;
    min-height: 100px;
  }

  &:hover .label {
    font-weight: bold;
  }

  ${({ isSelected }) =>
    isSelected
      ? css`
          & .label {
            color: var(--company-color);
            font-weight: 600;
          }

          ${IconWrapper} {
            border-color: var(--company-color);
            background-color: var(--company-color);
          }
        `
      : css`
          color: ${COLORS.BLACK};
        `};
`;

const Options = styled.div<{ error?: boolean }>`
  display: flex;
  align-items: start;
  justify-content: space-between;

  ${(props) =>
    props.error
      ? css`
          border: solid 2px ${COLORS.ERROR};
        `
      : css`
          border: solid 2px transparent;
        `}
`;

const QuestionViewSmileys = ({
  languageState,
  defaultValues,
  isPreselectedLang = false,
  isPreferredLanguage = false,
  onChange,
  error,
  commentError,
}: IQuestionViewSmileysProps) => {
  const { companyPrimaryLanguage, languages } = languageState;
  const formMethods = useForm<IQuestionForm>({ defaultValues });
  const { reset, control, watch } = formMethods;
  const { fields, update } = useFieldArray({ name: 'options', control });
  const getMultiLangString = useMultiLangString();

  const nameWatch = watch('name');

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const locale = isPreselectedLang ? languages[0].locale : companyPrimaryLanguage.locale;

  const questionTitle = useMemo(() => {
    const multiLang = turnArrayIntoMultiLang(nameWatch);
    if (isPreferredLanguage) {
      return getMultiLangString(multiLang);
    } else {
      return getMultiLangStringOriginal(
        multiLang,
        { preferredLang: { locale } as ILanguage } as IConnection,
        {
          primaryLang: companyPrimaryLanguage,
          languages,
        } as ICompany,
      );
    }
  }, [
    isPreferredLanguage,
    getMultiLangString,
    nameWatch,
    locale,
    companyPrimaryLanguage,
    languages,
  ]);

  const selectOption = (index: number) => {
    fields.map((item, i) => {
      update(i, { ...item, value: i === index });
    });
    onChange?.({ answer: index });
  };

  return (
    <Wrapper>
      <Title>{questionTitle}</Title>
      <AnswerWrapper>
        <Options error={error}>
          {fields.map(({ id, icon, label, value }, index) => {
            const translatedLabel = isPreferredLanguage
              ? getMultiLangString(turnArrayIntoMultiLang(label))
              : label.find((item) => item.locale === locale)?.value;

            return (
              <Option type="button" isSelected={value} key={id} onClick={() => selectOption(index)}>
                <div className="head">
                  <IconWrapper>
                    <div className="icon">{icon}</div>
                  </IconWrapper>
                </div>
                {!!translatedLabel?.length && <div className="label">{translatedLabel}</div>}
              </Option>
            );
          })}
        </Options>

        <Comment error={commentError} formMethods={formMethods} onChange={onChange} />
      </AnswerWrapper>
    </Wrapper>
  );
};

export { QuestionViewSmileys };
