import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import RickTextView from '~/components/RickTextView';
import SvgIcon from '~/components/SvgIcon';
import Tooltip from '~/components/Tooltip';
import AvatarCard from '~/components/UI/AvatarCard';

import ConversationIcon from '~/assets/main-menu-icons/conversation.svg';

import { COLOR_PALETTE, COLOR_SET, COLORS } from '~/styles';

const FeedbackRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  margin-bottom: 7px;
`;

const FeedbackTextRow = styled(RickTextView)`
  padding: 8px 12px 8px 12px;
  border-radius: 6px;
  background-color: ${COLORS.BG_PAGE};
  margin-top: 7px;
`;

const AvatarRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
`;

const RatingInfo = styled.span`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  align-self: center;
  white-space: nowrap;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const ConversationInfo = styled.span`
  display: flex;
  align-self: flex-end;
  overflow: hidden;
`;

const ReviewName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ConversationCycle = styled.span`
  display: flex;
  align-items: center;
  font-size: 10px;
  color: ${COLOR_SET.MIDNICHT_BLUE};
  font-weight: 500;
  overflow: hidden;
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin-right: 3px;
`;

const SkillInfo = styled.span``;

const FeedbackItem = ({ rating, expectedLevel }) => {
  const currentLevel = rating.scale.indexOf(rating.rate) + 1;
  const expectedLevelFromScale = rating.scale.indexOf(expectedLevel) + 1;
  const totalLevels = rating.scale.length;

  return (
    <FeedbackRow>
      <AvatarRow>
        <UserInfo>
          <AvatarCard
            styles={{ padding: 0, marginRight: '7px' }}
            type={AvatarCard.TYPES.SMALL}
            userId={rating.user}
            size={28}
            subTitle={
              <RatingInfo>
                <Trans>Rated</Trans> {rating.scaleLabels[currentLevel - 1]}{' '}
                {`${currentLevel}/${totalLevels}`}{' '}
                {!!expectedLevelFromScale && (
                  <SkillInfo>
                    {`${Math.round((currentLevel / expectedLevelFromScale) * 100)}%`}{' '}
                    <Trans>skill coverage</Trans>
                  </SkillInfo>
                )}
              </RatingInfo>
            }
          />
        </UserInfo>
        {rating?.review?.name && (
          <Tooltip tooltip={rating.review.name}>
            <ConversationInfo>
              <ConversationCycle>
                <StyledSvgIcon
                  url={ConversationIcon}
                  width="15px"
                  height="15px"
                  isDefaultColor
                  defaultColor={COLOR_SET.MIDNICHT_BLUE}
                />
                <ReviewName>{rating.review.name}</ReviewName>
              </ConversationCycle>
            </ConversationInfo>
          </Tooltip>
        )}
      </AvatarRow>
      <FeedbackTextRow html={rating.explanation} />
    </FeedbackRow>
  );
};

export { FeedbackItem };
