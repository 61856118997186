import React from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TableCard, { TableCol, TableRow } from '~/components/UI/TableCard';

import { COLOR_PALETTE } from '~/styles';

const WrapperScroll = styled.div`
  max-height: 400px;
  overflow: auto;
`;

const Wrapper = styled.div`
  padding: 0 24px;
`;

const Info = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin-bottom: 24px;
  margin-top: 16px;
`;

const RowText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

function integrationContent({
  cols,
  getIntegrationRowData,
  integration,
  integrationPlaceholder,
  integrationExplanation,
  pathToItems,
  search,
  searchByField = 'oldValue',
}) {
  let items = get(integration, pathToItems, []);

  if (search) {
    items = items.filter((item) =>
      (item[`${searchByField}`] || '').toLowerCase().includes(search.toLowerCase()),
    );
  }

  const renderRow = (item, index) => {
    const rowCols = getIntegrationRowData(item) || [];

    return (
      <TableRow key={index} height="48px" $border noHover>
        {rowCols.map((item, index) => (
          <TableCol key={index} height="48px">
            <RowText>{item}</RowText>
          </TableCol>
        ))}
      </TableRow>
    );
  };

  return isEmpty(integration) ? null : (
    <WrapperScroll>
      <Wrapper>
        <Info>{integrationExplanation}</Info>
        {isEmpty(items) ? (
          integrationPlaceholder
        ) : (
          <TableCard cols={cols} items={items} renderRow={renderRow} hideHeader noShadow />
        )}
      </Wrapper>
    </WrapperScroll>
  );
}

integrationContent.propTypes = {
  integration: PropTypes.object.isRequired,
  integrationPlaceholder: PropTypes.node.isRequired,
  getIntegrationRowData: PropTypes.func.isRequired,
  search: PropTypes.string,
  pathToItems: PropTypes.string.isRequired,
};

integrationContent.defaultProps = {
  getIntegrationRowData: () => {},
};

export default integrationContent;
