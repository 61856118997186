import styled from 'styled-components';

import Switch from '~/components/Switch';

import { COLORS } from '~/styles';

export const HeaderWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  width: fit-content;
  border-radius: 6px 6px 0 0;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
`;

export const Header = styled.div`
  padding-top: 24px;
  padding-left: 10px;
`;

export const Title = styled.h2`
  font-size: 26px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: normal;
  margin-top: 0;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  line-height: 1.43;
  color: ${COLORS.SUBTEXT};
  margin-top: -14px;
`;

export const Description = styled.div`
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
  border-top: 1px solid ${COLORS.BORDERS};
  line-height: 1.86;
  padding: 12px 0;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

export const JobAlias = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  width: 100%;
`;

export const SectionHeader = styled.span`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_HOVER};
`;

export const FilterForQualityWrapper = styled.div`
  margin-left: 20px;
  display: flex;
`;

export const FilterForQualityTitle = styled.div`
  font-size: 14px;
`;

export const StyledSwitch = styled(Switch)`
  margin-left: 0px;
  margin-right: 10px;
`;
