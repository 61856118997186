import React, { useState } from 'react';

import { GOAL_PROGRESS_TYPES, SYMBOLS, GOALS_UPDATES_TARGET_TYPE } from '@learned/constants';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import styled from 'styled-components';

import RickTextView from '~/components/RickTextView';
import Avatar from '~/components/UI/Avatar';

import { GOAL_STATUSES } from '~/constants';
import { COLOR_PALETTE, COLORS } from '~/styles';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ProgressStatus = styled.span`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${(props) => props.color};
`;

const RichTextViewWrapper = styled(RickTextView)`
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
`;

const SectionWrapper = styled.div``;

const ShowMoreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 11px;
`;

const ShowMore = styled.span`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e0863;
  cursor: pointer;
`;

const Title = styled.div`
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLORS.TEXT_BLACK};
  margin-left: 9px;

  span {
    font-weight: 600;
  }
`;

const UpdateCommentBlock = styled.div`
  background-color: ${COLORS.BG_PAGE};
  margin-top: 8px;
  padding: 12px 12px 9px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 3px;
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.25px;
`;

const UpdateDate = styled.span`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.25px;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin-left: 4px;
`;

const UpdateInfo = styled.div`
  display: flex;
  align-items: center;
`;

const UpdateWrapper = styled.div`
  margin-bottom: 20px;
  height: fit-content;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const Update = ({ update, goalName, i18n }) => {
  // user and update item name
  const userName = update.userName;
  const isActivityUpdate = update.targetType === GOALS_UPDATES_TARGET_TYPE.ACTIVITY;
  const name = isActivityUpdate ? update.target?.name : goalName;

  // status
  const statuses = Object.values(GOAL_STATUSES);
  const fromStatus = statuses.find((s) => s.key === update?.status?.old);
  const toStatus = statuses.find((s) => s.key === update?.status?.new);

  // progress
  let fromProgress = null;
  let toProgress = null;
  let symbol = null;

  // If it is done type, leave progress fields as null, otherwise fill them
  if (!isEmpty(update.progress) && !(update.progress.type === GOAL_PROGRESS_TYPES.IS_DONE)) {
    const isAvgType =
      update.progress.type === GOAL_PROGRESS_TYPES.AVG ||
      update.progress.type === GOAL_PROGRESS_TYPES.PERCENTAGE;
    symbol = isAvgType ? '%' : SYMBOLS[update.progress.currency] || '';
    fromProgress = update.progress.old;
    toProgress = update.progress.new;
  }

  // update date
  const updateDate = get(update, 'meta.lastModifiedDate', get(update, 'meta.createdDate'));
  const currentDate = moment().startOf('day');
  const lastUpdateDate = moment(updateDate).startOf('day');
  const days = currentDate.diff(lastUpdateDate, 'days');
  const isToday = days === 0;

  return (
    <UpdateWrapper>
      <Header>
        <UpdateInfo>
          <Avatar userId={update.createdBy} />
          <Title>
            <span>{userName}</span>{' '}
            {fromProgress === null && isEmpty(fromStatus) && !isEmpty(update.comment) ? (
              <Trans>added a comment to</Trans>
            ) : (
              <Trans>updated</Trans>
            )}{' '}
            <span>{name}</span>{' '}
            {fromProgress !== null && (
              <>
                <Trans>from</Trans>{' '}
                <span>
                  {fromProgress}
                  {symbol}
                </span>{' '}
                <Trans>to</Trans>{' '}
                <span>
                  {toProgress}
                  {symbol}
                </span>{' '}
              </>
            )}
            {fromProgress !== null && fromStatus && (
              <>
                <Trans>and</Trans>{' '}
              </>
            )}
            {fromStatus && (
              <>
                <Trans>status from</Trans>{' '}
                <ProgressStatus color={fromStatus?.color}>{fromStatus?.title(i18n)}</ProgressStatus>{' '}
                <Trans>to</Trans>{' '}
                <ProgressStatus color={toStatus?.color}>{toStatus?.title(i18n)}</ProgressStatus>
              </>
            )}
            <UpdateDate>{isToday ? <Trans>Today</Trans> : <Trans>{days}d ago</Trans>}</UpdateDate>
          </Title>
        </UpdateInfo>
      </Header>
      {update.comment && (
        <UpdateCommentBlock>
          <RichTextViewWrapper html={update.comment} />
        </UpdateCommentBlock>
      )}
    </UpdateWrapper>
  );
};

const UpdatesSection = ({ updates, goalName }) => {
  const { i18n } = useLingui();
  const [isShowMore, setIsShowMore] = useState(true);

  return (
    <SectionWrapper>
      {(isShowMore ? updates.slice(0, 2) : updates).map((u) => (
        <Update key={u.id} i18n={i18n} goalName={goalName} update={u} />
      ))}
      {isShowMore && updates.length > 2 && (
        <ShowMoreWrapper>
          <ShowMore onClick={() => setIsShowMore(false)}>
            <Trans>Show more</Trans>
          </ShowMore>
        </ShowMoreWrapper>
      )}
    </SectionWrapper>
  );
};

export { UpdatesSection };
