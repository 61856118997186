import { useEffect, useRef } from 'react';

export function useOutsideClick<T extends HTMLElement>(callback: () => void) {
  const ref = useRef<T>(null);
  const refCB = useRef(callback);

  useEffect(() => {
    const handler = (ev: UIEvent) => {
      const element = ref.current;
      if (element && !element.contains(ev.target as HTMLElement)) {
        refCB.current();
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, []);

  return ref;
}
