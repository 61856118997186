import { ROLES } from '~/constants';
import { pageOverview, pageReport } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'members';

// overview
export const MEMBERS = new LinkConstructor([ROLES.ADMIN], () => pageOverview(routeName));

export const MEMBERS_REPORT = new LinkConstructor([ROLES.ADMIN], () => pageReport(routeName));
