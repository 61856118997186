import React from 'react';

import { GOAL_SORT_OPTIONS } from '@learned/constants';
import { t } from '@lingui/macro';

import { UserAvatarGroup } from '~/components/UserAvatar/UserAvatarGroup';

import type { IColumnTable } from '~/@types/table';

import { ParentGoalName } from '../design';

import type { IGoal } from '@learned/types';
import type { I18n } from '@lingui/core';

const PERSONAL_COLUMNS: IColumnTable<IGoal>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'goalName',
    renderCell: (goal: IGoal) => {
      return <ParentGoalName>{goal.name}</ParentGoalName>;
    },
    sortBy: {
      asc: {
        key: GOAL_SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: GOAL_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Owner(s)`),
    accessor: 'owners',
    renderCell: (goal: IGoal) => {
      return (
        <UserAvatarGroup
          users={goal.owners?.map((item: string) => ({ userId: item })) || []}
          options={{ isCounterClickable: true }}
        />
      );
    },
  },
];

export { PERSONAL_COLUMNS };
