import React from 'react';

import styled from 'styled-components';

import { CommonReportsDashboard } from '../CommonReportsDashboard.tsx';
import { PAGE_TYPE } from '../CommonReportsDashboard.tsx/types';

const DashWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const AdminDashboard = () => {
  return (
    <DashWrapper>
      <CommonReportsDashboard type={PAGE_TYPE.ADMIN} />
    </DashWrapper>
  );
};

export { AdminDashboard };
