import React, { useEffect, useState } from 'react';

import { NOTIFICATION_PREFERENCES } from '@learned/constants';
import { useLingui } from '@lingui/react';

import CheckBox from '~/components/CheckBox';
import Switch from '~/components/Switch';

import {
  RowWrap,
  ToggleContainer,
  TitleToggleWrap,
  TitleContainer,
  ColumnWrap,
  EmailWrap,
  SystemWrap,
} from './design';

import { NOTIFICATION_SETTINGS } from '~/constants/notificationSettings';

import type { NotificationPreference } from '@learned/types';

type RowProps = {
  type: NOTIFICATION_PREFERENCES;
  preference: NotificationPreference;
  onChange: Function;
};

const NotificationRow = ({ type, preference, onChange }: RowProps) => {
  const { i18n } = useLingui();
  const [emailsEnabled, setEmailsEnabled] = useState<boolean>(false);
  const [systemEnabled, setSystemEnabled] = useState<boolean>(false);
  const [toggleChecked, setToggleChecked] = useState<boolean>(false);

  useEffect(() => {
    setEmailsEnabled(preference?.email?.isEnabled || false);
    setSystemEnabled(preference?.system?.isEnabled || false);
    setToggleChecked(preference?.email?.isEnabled || preference?.system?.isEnabled);
  }, [preference]);

  const onToggleChange = async (value: boolean) => {
    setToggleChecked(value);
    if (value) {
      await onChange(type, { email: { isEnabled: true }, system: { isEnabled: true } });
      setEmailsEnabled(true);
      setSystemEnabled(true);
    } else {
      await onChange(type, { email: { isEnabled: false }, system: { isEnabled: false } });
      setEmailsEnabled(false);
      setSystemEnabled(false);
    }
  };

  const onEmailCheckBoxChanged = async () => {
    await onChange(type, {
      email: { isEnabled: !emailsEnabled },
      system: { isEnabled: systemEnabled },
    });
    setEmailsEnabled((prev) => !prev);
  };

  const onSystemCheckBoxChanged = async () => {
    await onChange(type, {
      email: { isEnabled: emailsEnabled },
      system: { isEnabled: !systemEnabled },
    });
    setSystemEnabled((prev) => !prev);
  };

  useEffect(() => {
    if (!emailsEnabled && !systemEnabled) {
      setToggleChecked(false);
    } else {
      setToggleChecked(true);
    }
  }, [emailsEnabled, systemEnabled]);

  return (
    <RowWrap>
      <TitleToggleWrap>
        <ToggleContainer>
          <Switch checked={toggleChecked} onChange={onToggleChange} />
        </ToggleContainer>
        <TitleContainer>{NOTIFICATION_SETTINGS[type].title(i18n)}</TitleContainer>
      </TitleToggleWrap>

      <ColumnWrap>
        <EmailWrap>
          {/* @ts-ignore */}
          <CheckBox
            size={20}
            checked={emailsEnabled}
            onChange={onEmailCheckBoxChanged}
            disabled={!toggleChecked}
            isSecondary={true}
          />
        </EmailWrap>
        <SystemWrap>
          {/* @ts-ignore */}
          <CheckBox
            size={20}
            checked={systemEnabled}
            onChange={onSystemCheckBoxChanged}
            disabled={!toggleChecked}
            isSecondary={true}
          />
        </SystemWrap>
      </ColumnWrap>
    </RowWrap>
  );
};

export { NotificationRow };
