import React from 'react';

import { GOAL_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { DropdownButtonNew } from '~/components/DropdownButtonNew';

import { GOAL_CREATE_MENU_TYPES } from '~/constants/goals';
import routes from '~/constants/routes';
import {
  checkModuleGoalsInOrganisation,
  checkModuleGoalsInTeam,
  getUser,
} from '~/selectors/baseGetters';
import * as currentGoalActions from '~/store/currentGoal/actions';

const CreateGoalButton = ({
  preselectedUsers,
  isResetCurrentGoal,
}: {
  preselectedUsers?: string[];
  isResetCurrentGoal?: boolean;
}) => {
  const { i18n } = useLingui();
  const user = useSelector(getUser);
  const history = useHistory();
  const dispatch = useDispatch();
  const isTeamGoalsEnabled = useSelector(checkModuleGoalsInTeam);
  const isCompanyGoalsEnabled = useSelector(checkModuleGoalsInOrganisation);

  const isOnlyUserRole = !user.isAdmin && !user.isCoach;

  const goToCreateGoalPage = (type: GOAL_TYPES) => {
    if (isResetCurrentGoal) {
      dispatch(currentGoalActions.resetCurrentGoal());
    }
    history.push(
      routes.GOAL_CREATE.build(
        // @ts-ignore
        {},
        {
          isBackPath: true,
          type,
          query: {
            type,
            // @ts-ignore
            ...(isOnlyUserRole && {
              users: [user.id],
              isDeleteOwnersDisabled: true,
            }),
            ...(!isEmpty(preselectedUsers) && {
              users: preselectedUsers,
              isDeleteOwnersDisabled: true,
            }),
          },
        },
      ),
    );
  };

  const goalItems = isOnlyUserRole
    ? []
    : Object.values(GOAL_CREATE_MENU_TYPES)
        .filter((item) => {
          return (
            (item.key === GOAL_TYPES.COMPANY && user.isAdmin && isCompanyGoalsEnabled) ||
            (item.key === GOAL_TYPES.TEAM &&
              (user.isAdmin || user.isCoach) &&
              isTeamGoalsEnabled) ||
            item.key === GOAL_TYPES.PERSONAL
          );
        })
        .map((item) => {
          return {
            ...item,
            name: item.name(i18n),
            description: item.description(i18n),
            onClick: () => goToCreateGoalPage(item.key),
          };
        });

  return isOnlyUserRole ? (
    <Button
      variant={ButtonVariant.PRIMARY}
      size={ButtonSize.MEDIUM}
      label={i18n._(t`Create goal`)}
      onClick={() => goToCreateGoalPage(GOAL_TYPES.PERSONAL)}
    />
  ) : (
    <DropdownButtonNew items={goalItems} buttonLabel={i18n._(t`Create goal`)} />
  );
};

export { CreateGoalButton };
