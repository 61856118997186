import React from 'react';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AvatarCard from '~/components/UI/AvatarCard';
import { Header6 } from '~/components/UI/Typographics/headers';

import { COLORS } from '~/styles';

const AvatarRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const RatingRow = styled.div`
  border-top: 1px solid ${COLORS.BG_PAGE};
  padding: 24px;
  box-sizing: border-box;
`;

const RateText = styled(Header6)`
  color: ${COLORS.TEXT_SECONDARY};
  margin: 0;
  margin-left: 20px;

  span {
    margin-left: 8px;
  }
`;

const RejectedRequest = ({ request }) => (
  <RatingRow>
    <AvatarRow>
      <AvatarCard userId={request.toUser} email={request.toEmail} type="small" />
      <RateText>
        <Trans>Rejected your nomination</Trans>
      </RateText>
    </AvatarRow>
  </RatingRow>
);

RejectedRequest.propTypes = {
  request: PropTypes.object.isRequired,
};

export default RejectedRequest;
