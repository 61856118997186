import styled from 'styled-components';

import { ButtonShadow } from '~/components/Button';

import { COLORS } from '~/styles';

export const SettingItemWrapper = styled.div<{ $isSecondLevel?: boolean }>`
  display: flex;
  padding: ${(props) => (props?.$isSecondLevel ? '23px 20px 23px 90px' : '23px 20px')};
  border-radius: 6px;
`;

export const RatingScaleItemWrapper = styled.div`
  display: flex;
  padding: 23px 20px;
  margin-top: 8px;
  border-radius: 6px;
  flex-direction: column;
  background-color: ${COLORS.BG_LIST};
`;

export const Title = styled.div`
  font-size: 14px;
  color: ${COLORS.ICONS_PRIMARY};
`;

export const SubTitle = styled.div`
  font-size: 14px;
  color: ${COLORS.INPUT_TITLE};
  margin-bottom: 10px;
`;

export const ItemsWrapper = styled.div`
  & ${SettingItemWrapper}:nth-child(odd) {
    background-color: ${COLORS.BG_LIST};
  }
`;

export const FooterRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  width: 100%;
  margin: 30px 0;
`;

export const RatingHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const IndexWrapper = styled.div`
  height: 38px;
  width: 68px;
  align-items: center;
  display: flex;
  text-align: center;
  font-size: 14px;
  color: ${COLORS.ICONS_PRIMARY};
`;

export const IndexWrapperSubstitute = styled.div`
  width: 68px;
`;

export const InputGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FieldWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const RatingHeader = styled.div`
  flex: 1;
  height: 38px;
  font-size: 14px;
  color: ${COLORS.INPUT_TITLE};
`;

export const TrashWrapper = styled.div`
  height: 38px;
  width: 38px;
  align-items: center;
  display: flex;
  text-align: center;
`;

export const TrashWrapperSubstitute = styled.div`
  width: 38px;
`;

export const Row = styled.div`
  display: flex;
  align-items: start;
  color: var(--company-color);
`;

export const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  height: 38px;
  width: 38px;

  &.delete {
    cursor: pointer;
    color: ${COLORS.ACCENT_ERROR};
  }
  &.disabled {
    color: ${COLORS.BORDER_HARD};
    cursor: default;
  }
`;

export const InputGroups = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 8px;
  gap: 16px;
`;

export const AddOption = styled(ButtonShadow)`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 32px;
  padding: 8px 14px 7px;
  &.selected {
    background-color: var(--company-color);
    color: ${COLORS.WHITE};
  }
  margin-top: 20px;
  margin-right: auto;
  margin-left: 24px;
`;
