import styled from 'styled-components';

export const MainView = styled.div`
  display: flex;
  position: relative;
  min-height: 100%;
  min-width: 100%;
  z-index: 1;
`;

export const TableAndQuestions = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
`;

export const QuestionWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  position: absolute;
  left: 50%;
  max-height: 100%;
  transform: translateX(-50%);
`;
