import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Dropdown } from '~/components/Dropdown';
import { TextField } from '~/components/Text';

import { Section } from './Section';
import { SectionRow } from './SectionRow';

import { getIndustries } from '~/services/industries';
import { COLORS } from '~/styles';

import type { IIndustry } from '@learned/types';

const IndustryDropdown = styled(Dropdown)`
  width: 222px;

  & > div:first-child {
    height: 48px;
    font-size: 16px;
    border-color: ${COLORS.BORDERS};
    color: ${COLORS.TEXT_HOVER};
    background-color: transparent;

    ::before {
      background-color: ${COLORS.WHITE};
      opacity: 0;
    }
  }
`;

const CompanyData = ({
  companyName,
  handleChange,
  saveCompany,
  handleIndustryChange,
  companyIndustry,
}: {
  companyName: string;
  handleChange: (_e: React.ChangeEvent<HTMLInputElement>) => void;
  saveCompany: (_e?: React.FormEvent) => Promise<void>;
  handleIndustryChange: (industry: IIndustry) => void;
  companyIndustry: string | undefined;
}) => {
  const { i18n } = useLingui();
  const [industries, setIndustries] = useState<IIndustry[]>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<IIndustry>();
  useEffect(() => {
    const fetch = async () => {
      const response = await getIndustries();

      if (response?.code === 200) {
        setIndustries(response?.data?.industries ?? []);
        if (companyIndustry) {
          setSelectedIndustry(
            response?.data?.industries.find((item: IIndustry) => item.id === companyIndustry) ??
              null,
          );
        }
      }
    };

    fetch();
  }, [companyIndustry]);

  const onIndustryChange = (item: IIndustry) => {
    setSelectedIndustry(item);
    handleIndustryChange(item);
  };

  const fields = [
    {
      title: i18n._(t`Company name`),
      description: i18n._(t`Visible for employees`),
      actions: (
        <TextField
          type="text"
          name="name"
          value={companyName}
          onChange={handleChange}
          onBlur={saveCompany}
        />
      ),
    },
    {
      title: i18n._(t`Industry`),
      actions: (
        <IndustryDropdown
          isSingleSelect
          items={industries}
          stringifyItem={(item) => (item as IIndustry).name.en_GB}
          hashItem={(item) => (item as IIndustry).id}
          selectedItem={selectedIndustry}
          onChange={(item) => onIndustryChange(item as IIndustry)}
          isSearchable
          placeholder={i18n._(t`Select industry`)}
        />
      ),
    },
  ];

  return (
    <Section title={i18n._(t`Company data`)}>
      {fields.map((item, key) => (
        <SectionRow key={key} {...item} />
      ))}
    </Section>
  );
};

export { CompanyData };
