import React, { useState, useEffect } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import ManageSkillsModal from '~/components/ManageSkillsModal';
import { ProfileSettingsBlock } from '~/components/ProfileSettingsComponents/MainDashboard/ProfileSettingsBlock';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser } from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import getLang from '~/selectors/getLang';
import { getSkillCategories } from '~/services/skillCategories';
import { getSkills, getSkillsById } from '~/services/skills';
import { getUserSkillsOrder, updateUserSkillsOrder } from '~/services/users';
import { updateUserProfile } from '~/store/auth/actions';
import { COLORS } from '~/styles';
import { getSkillName } from '~/utils/skillUtils';

import { SectionHeader } from '../SectionHeader';

import type { ISkill, ISkillCategory } from '@learned/types';

const SkillWrapper = styled.div`
  margin-top: 16px;
  padding-bottom: 8px;
`;

const CategoryName = styled.span`
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
`;

const Skills = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
  margin-top: 8px;
`;

const Skill = styled.div`
  padding: 8px 16px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
  background-color: ${COLORS.BG_LIST};
  border-radius: 18px;
  margin-bottom: 8px;
`;

const SkillsBlock = () => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const lang = useSelector(getLang);
  const company = useSelector(getCurrentCompany);
  const $loading = useBoolState(true);
  const $manageSkillsModal = useBoolState();
  const getMulitLangString = useMultiLangString();

  const [populatedSkills, setPopulatedSkills] = useState<Record<string, ISkill>>({});
  const [categories, setCategories] = useState([]);
  const [userSkillsOrder, setUserSkillsOrder] = useState<Record<string, string[]>>({});
  const [anotherCompaniesSkillsIds, setAnotherCompaniesSkillsIds] = useState([]);
  const [refreshLocalState, setRefreshLocalState] = useState(true);
  const [manualAddedSkills, setManualAddedSkills] = useState<ISkill[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      $loading.on();
      const [retrievedSkillsResponse, skillCategories, skillsOrder, manualSkills] =
        await Promise.all([
          getSkills(),
          getSkillCategories(),
          getUserSkillsOrder(user.id),
          getSkillsById(user.skills),
        ]);
      const retrievedSkills = retrievedSkillsResponse?.data?.skills;
      setCategories(Object.values(skillCategories));
      setUserSkillsOrder(skillsOrder.data);
      setPopulatedSkills(retrievedSkills);
      setManualAddedSkills(manualSkills);

      const anotherCompaniesSkillsIds = (user.skills || []).filter(
        (skillId: string) => !retrievedSkills[skillId],
      );

      setAnotherCompaniesSkillsIds(anotherCompaniesSkillsIds);
      setRefreshLocalState(false);
      $loading.off();
    };

    if (refreshLocalState) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [company.id, user.id, user.skills, refreshLocalState]);

  // @ts-ignore
  const onSave = async ({ skills: newSkillIds, skillsHidden, skillsOrder }) => {
    $loading.on();

    const data = {
      skills: (anotherCompaniesSkillsIds || []).concat(newSkillIds),
      skillsHidden,
    };

    await dispatch(updateUserProfile(data));
    await updateUserSkillsOrder(user.id, skillsOrder);

    setRefreshLocalState(true);
    $loading.off();
    $manageSkillsModal.off();
  };

  const getPopulatedUserSkills = () => {
    const populatedUserSkills: Record<string, unknown> = {};
    if (!isEmpty(userSkillsOrder)) {
      for (const category in userSkillsOrder) {
        if ({}.hasOwnProperty.call(userSkillsOrder, category)) {
          populatedUserSkills[category] = userSkillsOrder[category]
            .map((skillId: string) => populatedSkills[skillId])
            .filter(Boolean);
        }
      }
    }
    return populatedUserSkills;
  };

  const renderSkill = (skillId: string) => {
    const skill = populatedSkills[skillId];
    if (!skill) {
      return null;
    }
    return <Skill key={skill.id}>{getSkillName(skill, lang)}</Skill>;
  };

  const renderCategory = (category: ISkillCategory, index: number) => {
    const categorySkills = (userSkillsOrder[category.id] || []).filter(
      (skillId: string) => !(user?.skillsHidden || []).includes(skillId),
    );
    const categorySkillsManual =
      groupBy(manualAddedSkills, (item) => item.skillCategory)?.[category.id]?.map(
        (item) => item.id,
      ) || [];

    const skills = [...categorySkills, ...categorySkillsManual];

    return skills && skills.length > 0 ? (
      <SkillWrapper key={index}>
        <CategoryName>{getMulitLangString(category.name)}</CategoryName>
        <Skills>{skills.map(renderSkill)}</Skills>
      </SkillWrapper>
    ) : null;
  };

  const Header = (
    <SectionHeader title={i18n._(t`Skills`)} isEditable={true} onEdit={$manageSkillsModal.on} />
  );

  const Content = (
    <ShowSpinnerIfLoading loading={$loading.value}>
      {categories.map(renderCategory)}
    </ShowSpinnerIfLoading>
  );

  return (
    <>
      <ProfileSettingsBlock header={Header} content={Content} />

      {$manageSkillsModal.value && (
        <ManageSkillsModal
          currentManualAddedSkills={user?.skills || []}
          populatedSkills={populatedSkills}
          onClose={$manageSkillsModal.off}
          onSave={onSave}
          loading={$loading.value}
          currentSkillsHidden={user?.skillsHidden || []}
          categories={categories}
          populatedUserSkills={getPopulatedUserSkills()}
        />
      )}
    </>
  );
};

export { SkillsBlock };
