import React, { PureComponent } from 'react';

import find from 'lodash/find';
import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import SearchButton from '~/components/SearchButton';
import { SearchField } from '~/components/Text';
import BoxWithBorder from '~/components/UI/BoxWithBorder';
import Divider from '~/components/UI/Divider';
import Table, { TableRow, TableCol } from '~/components/UI/Table';

const MainContainer = styled.div`
  min-width: 625px;
  margin-bottom: ${(props) => (props.$noMarginBottom ? null : props.$marginBottom || '56px')};
`;

const TableCardHeader = styled.div`
  display: flex;
  padding: 20px 16px;
  box-sizing: border-box;
  justify-content: space-between;
  ${(props) =>
    props.out &&
    css`
      padding: 0 0 16px;
      ${TableCardTitle} {
        font-size: 16px;
      }
    `}
`;

const TableCardBody = styled.div`
  ${(props) => props.xScroll && 'overflow-x: scroll;'}
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) => (props.bottomSpace ? 15 : 0)}px;
  padding-top: ${(props) => (props.topSpace ? 15 : 0)}px;
`;

const TableCardTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  color: #3b3b3b;
`;

class TableCard extends PureComponent {
  static propTypes = {
    separateHeader: PropTypes.bool,
    loading: PropTypes.bool,
    scrollMaxItems: PropTypes.number,
    maxHeight: PropTypes.number,
    title: PropTypes.string || PropTypes.element,
    className: PropTypes.string,
    renderRow: PropTypes.func,
    sortFn: PropTypes.func,
    firstPlaceholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    secondPlaceholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    xScroll: PropTypes.bool,
  };

  static defaultProps = {
    items: [],
    searchBy: 'name',
  };

  constructor() {
    super();
    this.state = {
      searchValue: '',
    };
  }

  handleChange = (e) => {
    let value = e.target.value;

    this.setState({
      searchValue: value,
    });
  };

  filterData(items) {
    const { searchValue } = this.state;
    const { searchBy, cols, searchValueOutside } = this.props;
    const col = find(cols, (c) => c.name === searchBy);
    const getValue = col
      ? col.getValue || ((item) => item[col.property])
      : (item) => item[searchBy];

    if (searchValue) {
      return items.filter((item) => {
        return (getValue(item) || '').toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
      });
    }

    if (searchValueOutside) {
      return items.filter((item) => {
        return (
          (getValue(item) || '').toLowerCase().indexOf(searchValueOutside.toLowerCase()) !== -1
        );
      });
    }

    return items;
  }

  render() {
    const { searchValue } = this.state;
    const {
      title,
      cols,
      firstPlaceholder,
      renderRow,
      className,
      separateHeader,
      loading,
      maxHeight,
      hideHeader,
      hideSearch,
      scrollMaxItems,
      isSecondSections,
      items,
      secondItems,
      secondCols,
      renderSecondRow,
      secondPlaceholder,
      noTopBorder,
      noBottomBorder,
      noMarginBottom,
      marginBottom,
      noBorder,
      bottomButtons,
      searchBox = false,
      noShadow,
      xScroll,
    } = this.props;

    const header = !hideHeader && (
      <TableCardHeader out={separateHeader}>
        <TableCardTitle>{title}</TableCardTitle>
        {!hideSearch && searchBox ? (
          <SearchField value={searchValue} onChange={this.handleChange} placeholder="Search" />
        ) : (
          <SearchButton value={searchValue} onChange={this.handleChange} />
        )}
      </TableCardHeader>
    );

    const filteredItems = this.filterData(items);

    let filteredSecondItems = [];
    if (isSecondSections) {
      filteredSecondItems = this.filterData(secondItems);
    }

    const filteredCols = (cols || []).filter((x) => !x.disabled);

    let filteredSecondCols = [];
    if (isSecondSections) {
      filteredSecondCols = secondCols.filter((x) => !x.disabled);
    }

    return (
      <MainContainer
        className={className}
        $noMarginBottom={noMarginBottom}
        $marginBottom={marginBottom}
      >
        {separateHeader && header}
        <BoxWithBorder
          $noTopBorder={noTopBorder}
          $noBorder={noBorder}
          $noBottomBorder={noBottomBorder}
          $noShadow={noShadow}
        >
          {!separateHeader && header}
          <TableCardBody
            bottomSpace={maxHeight || (scrollMaxItems && scrollMaxItems < filteredItems.length)}
            topSpace={separateHeader}
            xScroll={xScroll}
          >
            {!separateHeader && header && <Divider />}
            <Table
              cols={filteredCols}
              items={filteredItems}
              renderRow={renderRow}
              textPlaceholder={firstPlaceholder}
              loading={loading}
              maxHeight={maxHeight}
              scrollMaxItems={scrollMaxItems}
            />
            {isSecondSections && (
              <Table
                cols={filteredSecondCols}
                items={filteredSecondItems}
                renderRow={renderSecondRow}
                textPlaceholder={secondPlaceholder}
                loading={loading}
                maxHeight={maxHeight}
                scrollMaxItems={scrollMaxItems}
              />
            )}
          </TableCardBody>
          {bottomButtons}
        </BoxWithBorder>
      </MainContainer>
    );
  }
}

export { TableRow, TableCol };
export default TableCard;
