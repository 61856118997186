import { useState } from 'react';

import { SKILL_TEMPLATE_SORT_OPTIONS } from '@learned/constants';

import { useAsyncFetch } from '~/hooks/useAsyncFetch';
import useDebounce from '~/hooks/useDebounce';
import { listSkillTemplates } from '~/services/skillTemplates';

import type { ISkillTemplate } from '@learned/types';

function useSkillTemplates() {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);
  const [skillTemplates] = useAsyncFetch<ISkillTemplate[]>(
    {
      fetch: async () => {
        const response = await listSkillTemplates(
          { search: debouncedSearch },
          {
            skip: 0,
            limit: 10,
            sortBy: SKILL_TEMPLATE_SORT_OPTIONS.NAME_A_Z,
          },
        );
        return response?.data?.skillTemplates ?? [];
      },
      initialState: [],
    },
    [debouncedSearch],
  );

  return { skillTemplates, search, setSearch };
}

export { useSkillTemplates };
