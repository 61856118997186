import React, { useState, useRef } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import AvatarEditor from 'react-avatar-editor';

import { ButtonVariant, ButtonSize } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import Modal from '~/components/Modal';

import {
  Header,
  Title,
  DeleteButton,
  CloseButton,
  Editor,
  Footer,
  ScaleSlider,
  ScaleLabel,
  ScaleButton,
  SaveButton,
} from './design';

import { COLORS } from '~/styles';

export interface AvatarProps {
  url: string;
  onSave: (avatarEntry: FormDataEntryValue | null) => void;
  onDelete: () => void;
  onClose: () => void;
}

const AvatarModal = ({ url, onSave, onDelete, onClose }: AvatarProps) => {
  const { i18n } = useLingui();
  const editorRef = useRef<AvatarEditor | null>(null);
  const [avatarScale, setAvatarScale] = useState(1.1);
  const [scaleLabel, setScaleLabel] = useState(10);

  const sliderRailStyle = {
    height: '2px',
  };

  const sliderTrackStyle = {
    height: '2px',
    borderRadius: 'none',
    backgroundColor: `${COLORS.COMPANY}`,
  };

  const sliderHandleStyle = {
    width: '15px',
    height: '15px',
    border: `6px solid ${COLORS.COMPANY}`,
    marginTop: '-6px',
  };

  const modalContentStyle = {
    padding: '8px 16px',
    overflow: 'visible',
  };

  const modalHeaderStyle = {
    padding: '20px 40px',
    fontSize: '26px',
    fontWeight: 'normal',
  };

  const onSliderChange = (value: number) => {
    setAvatarScale(value);
    setScaleLabel(Math.round((value - 1) * 100));
  };

  const onZoomIn = () => {
    if (avatarScale < 2) {
      const newScale = avatarScale + 0.1;
      setAvatarScale(newScale);
      setScaleLabel(Math.round((newScale - 1) * 100));
    }
  };

  const onZoomOut = () => {
    if (avatarScale > 1) {
      const newScale = avatarScale - 0.1;
      setAvatarScale(newScale);
      setScaleLabel(Math.round((newScale - 1) * 100));
    }
  };

  const handleSave = () => {
    const editor = editorRef.current;
    if (editor) {
      const canvas = editor.getImageScaledToCanvas();

      canvas.toBlob((blob: Blob | null) => {
        if (blob) {
          const formData = new FormData();
          formData.append('avatar', blob, 'edited_avatar.png');

          const avatarEntry = formData.get('avatar');
          onSave(avatarEntry);
        }
      }, 'image/png');
    }
  };

  return (
    <Modal
      title={i18n._(t`Upgrade plan`)}
      onClose={onClose}
      showDivider={false}
      width={366}
      minWidth={366}
      contentStyles={modalContentStyle}
      headerStyles={modalHeaderStyle}
      borderRadius={6}
      hideFooter
      isHideHeader
      isCloseOnOutsideClick
    >
      <Header>
        <CloseButton
          size={ButtonSize.MEDIUM}
          icon={ICONS.CLOSE}
          variant={ButtonVariant.ICON}
          onClick={onClose}
        />
        <Title>Edit profile picture</Title>
        <DeleteButton
          variant={ButtonVariant.ICON_DELETE}
          size={ButtonSize.MEDIUM}
          onClick={onDelete}
        />
      </Header>
      <Editor
        image={url}
        width={334}
        height={334}
        border={0}
        color={[255, 255, 255, 0.5]}
        scale={avatarScale}
        rotate={0}
        borderRadius={200}
        ref={editorRef}
        crossOrigin="anonymous"
      />
      <Footer>
        <ScaleButton
          icon={ICONS.SUBTRACT_MINUS}
          variant={ButtonVariant.ICON}
          size={ButtonSize.MEDIUM}
          onClick={onZoomOut}
        />
        <ScaleSlider
          value={avatarScale}
          onChange={(value: number) => onSliderChange(value)}
          disabled={false}
          min={1}
          max={2}
          step={0.1}
          trackStyle={sliderTrackStyle}
          railStyle={sliderRailStyle}
          handleStyle={sliderHandleStyle}
        />
        <ScaleButton
          icon={ICONS.ADD_PLUS}
          variant={ButtonVariant.ICON}
          size={ButtonSize.MEDIUM}
          onClick={onZoomIn}
        />
        <ScaleLabel>{scaleLabel}%</ScaleLabel>
        <SaveButton
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          label="save"
          onClick={handleSave}
        />
      </Footer>
    </Modal>
  );
};

export { AvatarModal };
