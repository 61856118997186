import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

const d = 'M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const PlusIcon = ({ size, ...props }) => <InlineSvg height={size} width={size} d={d} {...props} />;

PlusIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

PlusIcon.defaultProps = {
  size: 24,
  className: '',
};

export default PlusIcon;
