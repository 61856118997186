import React from 'react';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const SortUpIcon = ({ size, className, fill }) => (
  <InlineSvg
    width={size}
    height={size}
    className={className}
    fill={fill}
    d="M10 11.002v2L18 13v-2l-8 .002zm0-6v2L14 7V5l-4 .002zM10 17v2.002h12V17H10zM6 7.002h2.5L5 3.502l-3.5 3.5H4V20h2V7.002z"
  />
);

SortUpIcon.defaultProps = {
  size: 24,
};

export default SortUpIcon;
