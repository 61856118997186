import styled from 'styled-components';

import { COLORS } from '~/styles';

export const BoldSpan = styled.span`
  font-weight: bold;
`;
export const RequiredError = styled.span`
  margin-top: 2px;
  font-size: 12px;
  color: ${COLORS.ACCENT_ERROR};
  font-style: italic;
  display: block;
  margin-left: 5px;
`;
