import React from 'react';

import { IUser } from '@learned/types';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import AvatarPlaceholder from '~/assets/images/shape.png';

import routes from '~/constants/routes';
import getAppThemeColor from '~/selectors/getAppThemeColor';
import { COLORS } from '~/styles';

const Banner = styled.div<{
  R?: number;
  G?: number;
  B?: number;
}>`
  background-image: linear-gradient(
    85deg,
    var(--company-color),
    rgba(${({ R }) => R}, ${({ G }) => G}, ${({ B }) => B}, 0.75)
  );
  width: 100%;
  display: flex;
  height: 129px;
  align-items: center;
  margin-bottom: 30px;
  justify-content: center;
  border-bottom-left-radius: 6px;
`;

const OnHoverBlock = styled.div`
  display: none;
  justify-content: center;
  position: absolute;
  box-sizing: border-box;
  padding-top: 14px;
  top: 55%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: gray;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.WHITE};
  width: 105px;
  height: 47px;
  opacity: 0.5;
  background-color: ${COLORS.TOOLTIP_BACKGROUND};
  cursor: pointer;

  span {
    margin-left: 4px;
  }

  svg {
    margin-top: 3px;
  }
`;

const ProfileImageWrapper = styled.div<{
  isShowBg: boolean;
}>`
  width: 105px;
  height: 105px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  ${({ isShowBg }) =>
    isShowBg
      ? css`
          background-color: ${COLORS.BG_PAGE};
        `
      : ''}

  &:hover {
    div {
      display: flex;
    }
  }
`;

const ProfileImage = styled.img`
  width: 105px;
  height: 105px;
  border-radius: 50%;
  background-color: ${COLORS.BG_PAGE};
  position: relative;
  object-fit: cover;
  overflow: hidden;
`;

const WelcomeMessage = styled.span`
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.WHITE};
  margin-left: 22px;
  margin-top: 27px;
`;

const Wrapper = styled.div`
  width: 1001px;
  display: flex;
  align-items: center;
  margin-top: 43px;
`;

const Header = ({ user }: { user: IUser }) => {
  const history = useHistory();
  const companyColor = useSelector(getAppThemeColor);
  const R = parseInt(companyColor.substring(1, 3), 16);
  const G = parseInt(companyColor.substring(3, 5), 16);
  const B = parseInt(companyColor.substring(5, 7), 16);

  return (
    <Banner R={R} G={G} B={B}>
      <Wrapper>
        <ProfileImageWrapper isShowBg={!user?.avatarUrl}>
          <ProfileImage src={user?.avatarUrl || AvatarPlaceholder} />
          <OnHoverBlock
            // @ts-ignore
            onClick={() => history.push(routes.PROFILE_SETTINGS.build({}, { isBackPath: true }))}
          >
            <Icon icon={ICONS.SETTINGS} size={ICON_SIZES.SMALL} color={COLORS.WHITE} />
            <span>
              <Trans>Profile</Trans>
            </span>
          </OnHoverBlock>
        </ProfileImageWrapper>
        <WelcomeMessage>
          <Trans>Welcome,</Trans> {user?.firstName || user?.email} 👋
        </WelcomeMessage>
      </Wrapper>
    </Banner>
  );
};

export { Header };
