import { ROLES } from '~/constants';
import { pageUpdate, pageDashboard } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'themes';
const defaultThemeId = ':themeId';

// update
export const THEME_UPDATE = new LinkConstructor(
  [ROLES.ADMIN, ROLES.COACH, ROLES.USER],
  ({ themeId = defaultThemeId }) => pageUpdate(routeName, { id: themeId }),
);

export const THEME_VIEW = new LinkConstructor(
  [ROLES.ADMIN, ROLES.COACH, ROLES.USER],
  ({ themeId = defaultThemeId }) => pageDashboard(routeName, { id: themeId }),
);
