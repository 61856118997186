import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Button } from '~/components/Buttons/Button';
import { ICONS } from '~/components/Icon';

import { USER_INTEGRATION_TYPES } from '~/constants';
import { useUserCalendarIntegration } from '~/hooks/useUserHasIntegration';
import { updateUserProfile } from '~/store/auth/actions';
import { COLORS } from '~/styles';

const AddIntegrationContainer = styled.div`
  background-color: var(--company-color);
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
  padding: 32px 28px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

const AddIntegrationText = styled.div`
  margin-bottom: 4px;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.WHITE};
`;

const AddIntegrationSubTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.WHITE};
`;

const AddIntegrationButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

const ButtonWrapper = styled.div`
  margin-right: 8px;
`;

const Dismiss = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
  color: ${COLORS.WHITE};
`;

const INTEGRATIONS = [
  {
    type: USER_INTEGRATION_TYPES.google,
    label: t`Google calendar`,
    icon: ICONS.GOOGLE_LOGO,
  },
  {
    type: USER_INTEGRATION_TYPES.azure,
    label: t`Outlook calendar`,
    icon: ICONS.OUTLOOK_LOGO,
  },
];

const NoCalendarIntegrationWarning = () => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const { hasCalendarIntegration, showIntegrationQuestion, goToCalendarIntegrations } =
    useUserCalendarIntegration();

  const isShow = !hasCalendarIntegration && showIntegrationQuestion;

  const hideCalendarIntegrations = () => {
    dispatch(updateUserProfile({ showIntegrationQuestion: false }));
  };

  return isShow ? (
    <AddIntegrationContainer>
      <TextContainer>
        <AddIntegrationText>{i18n._(t`Sync your agenda!`)}</AddIntegrationText>
        <AddIntegrationSubTitle>
          {i18n._(
            t`By connecting Learned with Google- or Outlook, all your appointments will automatically appear in your agenda`,
          )}
        </AddIntegrationSubTitle>
      </TextContainer>

      <AddIntegrationButtons>
        {INTEGRATIONS.map((integration, key) => (
          <ButtonWrapper key={key}>
            <Button
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              label={i18n._(integration.label)}
              onClick={goToCalendarIntegrations}
              icon={integration.icon}
            />
          </ButtonWrapper>
        ))}
        <Dismiss onClick={hideCalendarIntegrations}>{i18n._(t`Maybe later`)}</Dismiss>
      </AddIntegrationButtons>
    </AddIntegrationContainer>
  ) : null;
};

export default React.memo(NoCalendarIntegrationWarning);
