import React from 'react';

import OpenRoute from '~/components/OpenRoute';
import PrivateRoute from '~/components/PrivateRoute';
import FocusLayout from '~/layouts/FocusLayout';
import FocusLayoutWithAbsoluteFooter from '~/layouts/FocusLayoutWithAbsoluteFooter';
import { CoachReview } from '~/pages/ReviewGiveFeedback/Coach';
import { OutsideReview } from '~/pages/ReviewGiveFeedback/Outside';
import { PeerReview } from '~/pages/ReviewGiveFeedback/Peer';
import { SelfReview } from '~/pages/ReviewGiveFeedback/SelfReview';
import ReviewReportPDF from '~/pages/ReviewReportPDF';
import ReviewRequestReport from '~/pages/ReviewRequestReport';
/* REVIEW 3.0 */
import Reviews from '~/pages/Reviews';
import { ReviewDashboardCycle } from '~/pages/Reviews/DashboardCycle';
import { ReviewDashboardUser } from '~/pages/Reviews/DashboardUser';
import { ReviewCycleCreate } from '~/pages/Reviews/EditCycle';
import { ReviewIndividualCreate } from '~/pages/Reviews/EditIndividual';
import { ReviewSelfCreate } from '~/pages/Reviews/EditSelf';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('reviews', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.REVIEWS.routePath(ROLES.USER)}
        exact
        path={routes.REVIEWS.routePath(ROLES.USER)}
        component={Reviews}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.UPDATE_REVIEW_SELF.routePath(ROLES.USER)}
        exact
        path={routes.UPDATE_REVIEW_SELF.routePath(ROLES.USER)}
        component={ReviewSelfCreate}
        allowedRoles={[ROLES.USER]}
        layout={FocusLayout}
      />,
      <PrivateRoute
        key={routes.USER_REVIEW_DASHBOARD.routePath(ROLES.USER)}
        exact
        path={routes.USER_REVIEW_DASHBOARD.routePath(ROLES.USER)}
        component={ReviewDashboardUser}
        allowedRoles={[ROLES.USER]}
        layout={FocusLayout}
      />,
      <PrivateRoute
        key={routes.REVIEW_DASHBOARD.routePath(ROLES.USER)}
        exact
        path={routes.REVIEW_DASHBOARD.routePath(ROLES.USER)}
        component={ReviewDashboardCycle}
        allowedRoles={[ROLES.USER]}
        layout={FocusLayout}
      />,
      <PrivateRoute
        key={routes.REVIEW_GIVE_FEEDBACK_PEER.routePath(ROLES.USER)}
        path={routes.REVIEW_GIVE_FEEDBACK_PEER.routePath(ROLES.USER)}
        component={PeerReview}
        layout={FocusLayoutWithAbsoluteFooter}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.REVIEW_GIVE_FEEDBACK_SELF.routePath(ROLES.USER)}
        exact
        path={routes.REVIEW_GIVE_FEEDBACK_SELF.routePath(ROLES.USER)}
        component={SelfReview}
        layout={FocusLayoutWithAbsoluteFooter}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.REVIEWS_INCOMING_REPORT.routePath(ROLES.USER)}
        exact
        path={routes.REVIEWS_INCOMING_REPORT.routePath(ROLES.USER)}
        component={ReviewRequestReport}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.UPDATE_REVIEW_CYCLE.routePath(ROLES.USER)}
        exact
        path={routes.UPDATE_REVIEW_CYCLE.routePath(ROLES.USER)}
        component={ReviewCycleCreate}
        layout={FocusLayout}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.UPDATE_REVIEW_INDIVIDUAL.routePath(ROLES.USER)}
        exact
        path={routes.UPDATE_REVIEW_INDIVIDUAL.routePath(ROLES.USER)}
        component={ReviewIndividualCreate}
        allowedRoles={[ROLES.USER]}
        layout={FocusLayout}
      />,
      <PrivateRoute
        key={routes.REVIEW_GIVE_FEEDBACK_COACH.routePath(ROLES.USER)}
        path={routes.REVIEW_GIVE_FEEDBACK_COACH.routePath(ROLES.USER)}
        component={CoachReview}
        layout={FocusLayoutWithAbsoluteFooter}
        allowedRoles={[ROLES.USER]}
      />,
    ],
  },
  routesPublic: [
    <OpenRoute
      key={routes.REVIEW_GENERATE_PDF.routePath()}
      exact
      path={routes.REVIEW_GENERATE_PDF.routePath()}
      component={ReviewReportPDF}
    />,
    <OpenRoute
      key={routes.REVIEW_GIVE_FEEDBACK_OUTSIDE.routePath()}
      exact
      path={routes.REVIEW_GIVE_FEEDBACK_OUTSIDE.routePath()}
      layout={FocusLayout}
      component={OutsideReview}
    />,
  ],
});
