import React from 'react';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import ToolTip from '~/components/Tooltip';

import { Title, Row, RowName, ConvoName, NameWrap, RowWrap, Placeholder } from './design';

import { SIDEBAR_ITEMS, SIDEBAR_ITEM_TYPES } from '../../constants';
import { AvatarsRow } from '../AvatarsRow';
import { SidebarRowIcon } from '../SidebarRowIcon';

type SidebarRowProps = {
  name?: string;
  type: SIDEBAR_ITEM_TYPES;
  onClick: (() => void) | ((userId?: string) => void) | undefined;
  isShowPlaceholder?: boolean;
  nameCount?: number | null;
  isLoading: boolean;
  date?: Date | null;
  coaches?: string[];
};

const SidebarRow = ({
  type,
  name,
  onClick,
  isShowPlaceholder = false,
  nameCount,
  isLoading,
  coaches,
}: SidebarRowProps) => {
  const { i18n } = useLingui();

  const item = Object.values(SIDEBAR_ITEMS).find((i) => i.key === type);

  return (
    <ShowSpinnerIfLoading loading={isLoading}>
      <RowWrap>
        <Title>
          <Trans>{item?.title(i18n)}</Trans>
        </Title>
        <Row
          onClick={type !== SIDEBAR_ITEM_TYPES.COACH ? (onClick as () => void) : undefined}
          isAlignCenter={isShowPlaceholder}
          enableHover={!isShowPlaceholder}
        >
          {item?.icon && item?.iconColors && (
            <SidebarRowIcon
              icon={item?.icon}
              size={item?.iconSize}
              iconColors={item?.iconColors}
              active={!isShowPlaceholder}
              isMirrored={type === SIDEBAR_ITEM_TYPES.NEXT_STEPS}
            />
          )}
          {isShowPlaceholder ? (
            <Placeholder>{item?.placeholder?.(i18n)}</Placeholder>
          ) : type !== SIDEBAR_ITEM_TYPES.COACH ? (
            type !== SIDEBAR_ITEM_TYPES.NEXT_REVIEW ? (
              <RowName className="row-name">
                {name && name.length > 16 ? (
                  <NameWrap>
                    <ToolTip tooltip={name} disabled={false}>
                      <div>{name}</div>
                    </ToolTip>
                  </NameWrap>
                ) : (
                  name
                )}
                {!!nameCount && <span>+{nameCount}</span>}
              </RowName>
            ) : (
              <ConvoName>
                <ToolTip tooltip={name} disabled={name ? name.length < 16 : true}>
                  <div>{name}</div>
                </ToolTip>
              </ConvoName>
            )
          ) : (
            <AvatarsRow
              onClick={onClick as (userId?: string) => void}
              coaches={coaches as string[]}
            />
          )}
        </Row>
      </RowWrap>
    </ShowSpinnerIfLoading>
  );
};

export { SidebarRow };
