import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

const d =
  'M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06zM17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 0 0 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const EditIcon = ({ size, onClick, className, fill }) => (
  <InlineSvg height={size} width={size} d={d} onClick={onClick} className={className} fill={fill} />
);

EditIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

EditIcon.defaultProps = {
  size: 24,
  className: '',
};

export default EditIcon;
