import styled, { css, type FlattenSimpleInterpolation } from 'styled-components';

import { CareerHeader } from '~/pages/ReviewGiveFeedback/components/Questions/design';

import { COLORS } from '~/styles';

export const Scale = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: ${COLORS.SUBTEXT};
`;

export const SelfCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 9px;
  background-image: linear-gradient(
    to bottom,
    ${COLORS.REVIEW_SELF_GRADIENT},
    ${COLORS.REVIEW_SELF}
  );
`;

export const CoachCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 9px;
  background-image: linear-gradient(
    to bottom,
    ${COLORS.REVIEW_COACH_GRADIENT},
    ${COLORS.REVIEW_COACH}
  );
`;

export const PeerCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 8px;
  background-image: linear-gradient(
    to bottom,
    ${COLORS.PEER_REVIEW_LEGEND_GRADIENT},
    ${COLORS.PEER_REVIEW_LEGEND}
  );
`;

export const StyledCareerHeader = styled(CareerHeader)`
  margin: 0;
`;

export const Header = styled.div<{ $isZeroState: boolean }>`
  display: flex;
  align-items: start;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 21px;

  .right {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 10px;
  }

  ${({ $isZeroState }) =>
    $isZeroState
      ? css`
          div {
            color: ${COLORS.INACTIVE};
          }
        `
      : null}
`;

export const Table = styled.div<{ $isZeroState: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 3px;

  ${({ $isZeroState }) =>
    $isZeroState
      ? css`
          div {
            color: ${COLORS.INACTIVE};
          }
        `
      : null}
`;

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  gap: 3px;
`;

export const Comments = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  width: 100%;
  gap: 17px;
  margin-top: 17px;
  margin-bottom: 17px;
`;

export const EditButton = styled.div`
  position: absolute;
  top: 16px;
  right: 20px;
`;

export const Arrow = styled.div`
  width: 0;
  height: 0;
  margin-right: auto;
  margin-left: 9px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
`;

export const EditButtonWrapper = styled.div`
  position: relative;

  ${EditButton} {
    display: none;
  }
  :hover {
    ${EditButton} {
      display: block;
    }
  }
`;

export const CommentWrapper = styled.div`
  margin-left: 17px;
  font-size: 14px;
  background-color: ${COLORS.BG_LIST};
  color: ${COLORS.TEXT_MAIN};
`;

export const Answer = styled.div`
  padding-top: 10px;
  font-size: 14px;
  background-color: ${COLORS.BG_LIST};
  color: ${COLORS.TEXT_MAIN};
  height: 54px;
  width: 100%;
  display: flex;
  gap: 7px;
  flex-direction: column;

  p {
    color: ${COLORS.TEXT_MAIN};
  }
`;

export const Label = styled.span`
  margin-left: 17px;
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};

  .light {
    margin-left: unset;
    color: ${COLORS.PLACEHOLDERS};
  }

  span {
    margin-left: 16px;
  }
`;

export const Comment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;

  ${Arrow} {
    border-bottom: 8px solid ${COLORS.BG_LIST};
  }

  :hover {
    ${Arrow} {
      border-bottom: 8px solid ${COLORS.HOVER};
    }
  }

  .comment {
    border-radius: 1px;
    background-color: ${COLORS.BG_LIST};
    padding: 15px 16px 15px 17px;

    :hover {
      background-color: ${COLORS.HOVER};

      div {
        background-color: ${COLORS.HOVER};

        p {
          weight: 500;
          color: ${COLORS.TEXT_HOVER};
        }
      }
    }
  }
`;

export const Name = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
  text-align: center;
  align-items: center;

  .role {
    color: ${COLORS.SUBTEXT};
    margin-left: 4px;
    text-transform: lowercase;
  }

  svg {
    margin-right: 11px;
  }
`;

export const TableItem = styled.div<{ limitWidth?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
  background-color: ${COLORS.BG_LIST};
  color: ${COLORS.TEXT_MAIN};
`;

export const RatingItem = styled(TableItem)<{ gradient?: FlattenSimpleInterpolation }>`
  position: relative;
  max-width: 118px;
  justify-content: center;
  height: auto;

  button {
    right: 5%;
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  :hover {
    button {
      opacity: 1;
      pointer-events: auto;

      :hover {
        background: transparent;
      }
    }
  }
  ${(props) => {
    return props.gradient && props.gradient;
  }}
`;

export const SkillItem = styled(TableItem)<{ isOpen?: boolean }>`
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :hover {
    background-color: ${COLORS.HOVER};

    ${(props) =>
      props.isOpen &&
      css`
        background-color: ${COLORS.BG_ELEMENTS};
      `}
  }

  > svg {
    min-width: 18px;
    margin-right: 20px;
  }

  .commentCount {
    display: flex;
    flex-direction: row;
    color: ${COLORS.PLACEHOLDERS};
    > svg {
      margin-left: 10px;
      margin-right: 7px;
    }
  }

  .skillName {
    white-space: nowrap;
    display: inline-block;
    flex-direction: row;
    text-overflow: ellipsis;
    align-items: center;
    overflow: hidden;
  }

  ${(props) =>
    props.isOpen &&
    css`
      background-color: ${COLORS.BG_ELEMENTS};
    `}
`;

export const FocusAreaItem = styled(TableItem)`
  justify-content: flex-start;
  padding: 10px 14px 16px;
  margin-left: 20px;
  font-size: 12px;
  height: auto;
  box-sizing: border-box;
`;

export const RatingHeader = styled(TableItem)`
  display: flex;
  flex-direction: row;
  background-color: ${COLORS.GRAY_LIGHT_BLUE};
  color: ${COLORS.SUBTEXT};
  max-width: 118px;
  justify-content: center;
  gap: 5px;
  text-transform: capitalize;
  font-weight: 600;
  > span {
    font-size: 12px;
    color: ${COLORS.PLACEHOLDERS};
    font-weight: 400;
  }
  > svg {
    cursor: pointer;
  }
  font-size: 12px;
`;

export const FocusAreaHeader = styled(TableItem)`
  background-color: ${COLORS.GRAY_LIGHT_BLUE};
  color: ${COLORS.SUBTEXT};
  justify-content: flex-start;
  padding-left: 20px;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
`;

export const FocusAreas = styled.div`
  font-size: 14px;
  border-radius: 6px;
  background-color: ${COLORS.BG_SWITCH};
  padding: 16px;
  color: ${COLORS.LABEL_GRAY};

  .expectedLevel {
    font-weight: 600;
  }

  .content {
    margin-bottom: 10px;
    * {
      max-width: 90%;
    }
  }

  .viewAllLevels {
    color: ${COLORS.COMPANY};
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
  }
`;

export const Definition = styled.div`
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
  margin: 5px 0;

  div {
    display: inline;
  }

  .bold {
    font-weight: 600;
  }
`;

export const GroupButtons = styled.div<{ $isZeroState: boolean }>`
  border-radius: 6px;
  overflow: hidden;
  button {
    border-style: solid;
    border-color: ${COLORS.BORDERS};
    border-width: 1px;
    padding: 7px 10px;
    color: ${COLORS.SUBTEXT};
    font-size: 12px;
    cursor: pointer;

    &.active {
      border-color: ${COLORS.COMPANY};
      color: ${COLORS.COMPANY};
    }
  }

  button:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    margin-right: -1px;
    border-right-color: ${COLORS.COMPANY};
  }

  button:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left-color: ${COLORS.COMPANY};
  }

  ${({ $isZeroState }) =>
    $isZeroState
      ? css`
          button {
            opacity: 50%;
          }
        `
      : null}
`;

export const SkillCategoryTitle = styled.div`
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.TEXT_MAIN};
  display: flex;
  align-items: flex-end;
  box-sizing: border-box;
  padding-bottom: 8px;
`;
