import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import IconButton from '~/components/IconButton';
import ExpandMoreIcon from '~/components/Icons/ArrowButtonTall';
import { TemplateQuestionCardWithFooter } from '~/components/SetupTools/components/TemplateBaseComponents';
import Tabs from '~/components/Tabs';
import Tooltip from '~/components/Tooltip';
import { Header4 } from '~/components/UI/Typographics/headers';

import useBoolState from '~/hooks/useBoolState';
import { COLOR_PALETTE } from '~/styles';

const TemplateCardWithFooterStyled = styled(TemplateQuestionCardWithFooter)`
  box-shadow: none;
  margin-bottom: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 55px; // to display toggle button
  cursor: pointer;
`;

const Row = styled.div`
  width: 100%;
  ${Header4} {
    margin: 0 40px 0 0;
    max-width: 240px;
    min-width: 240px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  display: flex;
  align-items: center;
`;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.$isOpen ? '38px 16px 18px' : '4px 16px')};
`;

const QuestionCounter = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  text-transform: uppercase;
  margin-bottom: 8px;
`;

const ToggleWrapper = styled.div`
  position: absolute;
  right: 24px;
  top: calc(50% - 12px);
`;

const SubQuestionCard = ({
  onClick,
  isError,
  children,
  title,
  titleTooltip,
  tabs,
  handleChangeTab,
  currentTab,
  indexCurrent,
  indexTotal,
  isOpenDefault,
  rowContent,
}) => {
  const $isOpen = useBoolState(isOpenDefault);
  const { i18n } = useLingui();

  const headerTitle = <Header4>{title}</Header4>;

  return (
    <TemplateCardWithFooterStyled onClick={onClick} $isError={isError} $isOpen={$isOpen.value}>
      <HeaderContainer $isOpen={$isOpen.value}>
        {indexTotal && (
          <QuestionCounter>{i18n._(t`Question ${indexCurrent}/${indexTotal}`)}</QuestionCounter>
        )}
        <Header onClick={$isOpen.toggle}>
          <Row>
            {titleTooltip ? <Tooltip tooltip={titleTooltip}>{headerTitle}</Tooltip> : headerTitle}
            <Row onClick={(e) => $isOpen.value && e.stopPropagation()}>
              {rowContent && rowContent({ isOpen: $isOpen.value })}
            </Row>
          </Row>
        </Header>
        <ToggleWrapper>
          <IconButton size={24} noBorder onClick={$isOpen.toggle} style={{ marginLeft: '24px' }}>
            {$isOpen.value ? (
              <ExpandMoreIcon size={24} style={{ transform: 'rotate(180deg)' }} />
            ) : (
              <ExpandMoreIcon size={24} />
            )}
          </IconButton>
        </ToggleWrapper>
      </HeaderContainer>

      {/* CONTENT */}
      {$isOpen.value && (
        <>
          {!isEmpty(tabs) && (
            <Tabs items={tabs} isSmall currentItem={currentTab} handleChangeTab={handleChangeTab} />
          )}
          {children}
        </>
      )}
    </TemplateCardWithFooterStyled>
  );
};

SubQuestionCard.propTypes = {
  isOpenDefault: PropTypes.bool,
  indexCurrent: PropTypes.number,
  indexTotal: PropTypes.number,
};

SubQuestionCard.defaultProps = {
  isOpenDefault: false,
};

export default React.memo(SubQuestionCard);
