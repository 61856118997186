import styled from 'styled-components';

import { COLORS } from '~/styles';

const MessageContainer = styled.div`
  margin-top: 14px;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MessageItemContainer = styled.div`
  margin-top: 14px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
`;

const Title = styled.div`
  font-size: 26px;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  width: 100%;
  margin-top: 40px;
`;

export { MessageContainer, Header, Title, MessageItemContainer, ActionContainer, Footer };
