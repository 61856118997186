import { DefaultToastContainer } from 'react-toast-notifications';
import styled, { css } from 'styled-components';

import { Icon } from '~/components/Icon';
import { TOAST_TYPES } from '~/components/Toast';

import { COLORS } from '~/styles';

export const BarBackground = styled.div`
  position: absolute;
  display: flex;
  background-color: ${COLORS.WHITE};
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 4px;
  min-height: 4px;
  width: 100%;
`;

export const BarLoader = styled(BarBackground)<{
  $width: string;
  $transitionTime: number;
  $color: string;
}>`
  ${(props) => css`
    background-color: ${props.$color};
    transition: ${props.$transitionTime}ms linear;
    width: ${props.$width};
  `}
`;

export const Container = styled.div<{ $type: TOAST_TYPES; $animate: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  box-sizing: border-box;

  padding: 10px 16px 14px;
  min-height: 58px;
  min-width: 312px;
  max-width: 312px;
  border-radius: 6px;
  background-color: ${COLORS.WHITE};
  box-shadow: 0 4px 5px 0 rgba(30, 8, 99, 0.15);
  transition: 0.3s ease-in-out;
  transform: ${(props) => (props.$animate ? 'translate(0, 0)' : 'translate(400px, 0)')};

  ${(props) =>
    (props.$type === TOAST_TYPES.INFO || props.$type === TOAST_TYPES.INFO_MESSAGE) &&
    css`
      background-image: linear-gradient(
        259deg,
        rgba(175, 184, 232, 0.1) 100%,
        rgba(86, 107, 250, 0.1) 0%
      );
    `}

  ${(props) =>
    props.$type === TOAST_TYPES.ERROR &&
    css`
      background-image: linear-gradient(
        259deg,
        rgba(255, 138, 138, 0.1) 100%,
        rgba(255, 82, 82, 0.2) 0%
      );
    `}

  ${(props) =>
    props.$type === TOAST_TYPES.SUCCESS &&
    css`
      background-image: linear-gradient(
        259deg,
        rgba(121, 247, 231, 0.1) 100%,
        rgba(60, 255, 215, 0.15) 0%
      );
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const IconContainer = styled.div<{ $backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  min-width: 26px;
  min-height: 26px;
  box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);

  ${(props) =>
    css`
      background-color: ${props.$backgroundColor};
    `}
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Title = styled.span`
  display: flex;
  flex-direction: row;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_HOVER};
  line-height: 1.25;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const Subtitle = styled.span`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  letter-spacing: -0.13px;
  line-height: 1.1;
  color: ${COLORS.SUBTEXT};
`;

export const CloseIcon = styled(Icon)`
  background-color: ${COLORS.WHITE};
  color: ${COLORS.WHITE};
`;

// z-index: 1001 !important; - to display Toast over Modal background layer
export const ToastContainer = styled(DefaultToastContainer)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 80px;
  margin-right: 30px;
  z-index: 1002 !important;
`;
