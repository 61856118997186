import { serverRoutes } from '~/server_config';

import cloudRequest from '../cloudRequest';

export function getUserRatings(userId) {
  return cloudRequest(serverRoutes.ratings.getUserRatings, { userId });
}

export function getUserReviewRatings({ userId, reviewId }) {
  return cloudRequest(serverRoutes.ratings.getUserReviewRatings(userId, reviewId));
}

export function getUserRatingsByCompany() {
  return cloudRequest(serverRoutes.ratings.getUserRatingsByCompany);
}

export function getSkillsRatings({ skills = [], createdFor }) {
  return cloudRequest(serverRoutes.ratings.getSkillsRatings, { skills, createdFor });
}

export function getSkillRatings({ skill, limit, skip, sortField, sortDirection }) {
  return cloudRequest(serverRoutes.ratings.getSkillRatings, {
    skill,
    limit,
    skip,
    sortField,
    sortDirection,
  });
}

export function createOrUpdateFeedbackRatings(
  {
    ratings,
    reviewId,
    share = false,
    isSelfReview = false,
    isAutoSave = false,
    isCalibrate = false,
  },
  { forceRole } = {},
) {
  return cloudRequest(
    serverRoutes.ratings.setRatings,
    null,
    {
      ratings,
      reviewId,
      share,
      isSelfReview,
      isAutoSave,
      isCalibrate,
    },
    {
      // set user role for coach (if needed)
      // when coach update single feedback from review report page
      ...(forceRole && { forceRole }),
    },
  );
}

export function getSkillCoverage({ jobProfileId, reviewId, userId }) {
  return cloudRequest(serverRoutes.ratings.getSkillCoverage, {
    jobProfileId,
    reviewId,
    userId,
  });
}
