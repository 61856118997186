import React, { ChangeEvent, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Spinner from '~/components/Spinner';

import { COLOR_PALETTE } from '~/styles';

export interface IButtonFileProps {
  label: string;
  onChange: (file: File) => Promise<void>;
}

const UploadButton = styled.div`
  width: 123px;
  height: 40px;
  border-radius: 6px;
  border: solid 1px var(--company-color);
  background-color: ${COLOR_PALETTE.WHITE};
  padding: 12px 16px;
  white-space: nowrap;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  text-align: center;
  color: var(--company-color);
  cursor: pointer;

  > * {
    cursor: pointer;
  }
`;

const UploadButtonFile = styled.input`
  width: 0.1%;
  height: 0.1%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -100000000;
`;

function ButtonFile({ label, onChange }: IButtonFileProps) {
  const [loading, setLoading] = useState(false);
  const { i18n } = useLingui();

  return (
    <UploadButton>
      {loading ? (
        <Spinner type="button-primary" />
      ) : (
        <label>
          {label || i18n._(t`Upload logo`)}
          <UploadButtonFile id="uploadFile" name="logo" onChange={handleChange} type="file" />
        </label>
      )}
    </UploadButton>
  );

  async function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target?.files?.[0];

    if (file) {
      setLoading(true);
      await onChange(file);
      setLoading(false);
    }
  }
}

export default ButtonFile;
