import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import size from 'lodash/size';
import { Controller } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Input } from '~/components/Input';
import Modal from '~/components/Modal';
import { SearchSelect } from '~/components/SearchSelect';

import { useConnectToExistingJobModal } from './useConnectToExistingJobModal';
import { useManageJobTemplates } from './useManageJobTemplates';

import { getSuperAdminMultiLangString } from '~/utils/superAdmin';

import {
  Inputs,
  JobAlias,
  SectionHeader,
  Description,
  Footer,
  Header,
  Subtitle,
  Title,
} from '../../design';

import type { IJobTemplatePending } from '@learned/types';

const ConnectToExistingJobModal = ({
  closeModal,
  jobTemplatePending,
  refetchJobTemplates,
}: {
  closeModal: () => void;
  jobTemplatePending?: IJobTemplatePending;
  refetchJobTemplates: () => void;
}) => {
  const { i18n } = useLingui();
  const { jobTemplates, selectedJobTemplate, setSelectedJobTemplate, setSearch } =
    useManageJobTemplates();

  const {
    step,
    setStep,
    languageState,
    formMethods,
    onSubmit,
    aliasFieldArray,
    isAliasesCompleted,
  } = useConnectToExistingJobModal({
    jobTemplatePending,
    closeModal,
    selectedJobTemplate,
    refetchJobTemplates,
  });
  const { control, register, trigger, handleSubmit } = formMethods;

  return (
    <Modal
      width={500}
      contentStyles={{ padding: '0 30px 30px', overflow: 'unset' }}
      footerStyles={{ padding: '0 32px 32px', height: 'unset', minHeight: 'unset' }}
      centerModal
      showDivider={false}
      title={
        <Header>
          <Title>
            <Trans>Connect to existing job {step}/2</Trans>
          </Title>
          <Subtitle>
            <Trans>Make a alias of: {jobTemplatePending?.name.en_GB}</Trans>
          </Subtitle>
        </Header>
      }
      footerRight={
        <Footer>
          <Button
            onClick={closeModal}
            label={<Trans>Cancel</Trans>}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
          />
          {step === 1 && (
            <Button
              onClick={() => {
                if (isAliasesCompleted) {
                  handleSubmit(onSubmit)();
                } else {
                  setStep(2);
                }
              }}
              label={<Trans>Next</Trans>}
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              disabled={!selectedJobTemplate}
            />
          )}
          {step === 2 && (
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              label={<Trans>Save</Trans>}
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              disabled={!isAliasesCompleted}
            />
          )}
        </Footer>
      }
      onClose={closeModal}
    >
      {step === 1 && (
        <>
          <Description>
            <Trans>
              If this job closely matches an existing job in the Learned database, you have the
              option to create an alias for this job. By creating an alias, Learned users can easily
              search for this job name in the job templates and utilize its details from the
              connected job.
            </Trans>
          </Description>
          <SearchSelect
            placeholder={i18n._(t`Connect to job`)}
            items={jobTemplates}
            hashItem={(item) => item.id}
            selectedItem={selectedJobTemplate}
            onChange={setSelectedJobTemplate}
            onSearchChange={setSearch}
            stringifyItem={(item) => getSuperAdminMultiLangString(item.name, languageState)}
            isSingleSelect
          />
        </>
      )}
      {step === 2 && (
        <JobAlias>
          <SectionHeader>
            <Trans>Job alias</Trans>
          </SectionHeader>
          <Inputs>
            {aliasFieldArray.fields.map((field) => (
              <Controller
                key={field.id}
                {...register(`alias.${field.index}.value`)}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Input
                      value={value}
                      error={!!formMethods.formState.errors?.alias}
                      onChange={(args) => {
                        onChange(args);
                        trigger('alias');
                      }}
                      key={field.id}
                      width="100%"
                      height="38px"
                      leftIcon={
                        size(languageState.languages) > 1
                          ? getUnicodeFlagIcon(
                              field.locale.substring(field.locale.indexOf('_') + 1),
                            )
                          : undefined
                      }
                    />
                  );
                }}
              />
            ))}
          </Inputs>
        </JobAlias>
      )}
    </Modal>
  );
};

export { ConnectToExistingJobModal };
