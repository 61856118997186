import { ILanguageValue } from '@learned/types';
import { IMultiLangString } from '@learned/types/src/multiLangString';
import isEmpty from 'lodash/isEmpty';

import { ILanguage } from '~/constants/languages';

function turnMultiLangIntoArray(
  multiLang: IMultiLangString,
  companyLanguages: ILanguage[],
): ILanguageValue[] {
  const tempMultiLang: Record<string, string> = {};
  companyLanguages.forEach(({ locale }) => {
    tempMultiLang[locale] = '';
  });
  Object.entries(multiLang).forEach(([key, value]) => {
    tempMultiLang[key] = value;
  });

  return Object.entries(tempMultiLang).map(([key, value]) => ({ locale: key, value }));
}

function turnArrayIntoMultiLang(items: ILanguageValue[]): IMultiLangString {
  const tempMultiLang: IMultiLangString = {};

  if (!isEmpty(items)) {
    items.forEach((item) => (tempMultiLang[item.locale] = item.value));
  }

  return tempMultiLang;
}

export { turnMultiLangIntoArray, turnArrayIntoMultiLang };
