import React, { FunctionComponent } from 'react';

import Tippy from '@tippyjs/react';
import styled from 'styled-components';

import ArrowIcon from '~/components/Icons/Arrow';
import Avatar from '~/components/UI/Avatar';

import { COLOR_SET } from '~/styles';

import { IListItem } from '../types';

const TableRow = styled.tr`
  height: 64px;
  border-bottom: 1px solid #ecf1ff;

  & td {
    font-size: 14px;
    padding: 12px 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${COLOR_SET.TOTAL_BLACK};

    & .icon {
      margin-left: 10px;
      color: #29ccab;
    }

    & .rotate {
      rotate: 180deg;
      color: #ff5252;
    }

    &.isNull {
      color: #dcdcdc;
    }
  }
`;

const UserInfo = styled.td`
  font-size: 14px;
  cursor: pointer;
  color: ${COLOR_SET.TOTAL_BLACK};

  display: flex;
  align-items: center;
  & .name {
    margin-left: 20px;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const TippyName = styled.div`
  word-wrap: break-word;
  white-space: pre-wrap;
`;

interface IRow {
  item: IListItem;
  isSecondReviewSelected?: boolean;
}

const tippyOpts = { modifiers: [{ name: 'computeStyles', options: { gpuAcceleration: false } }] };

const ListViewRow: FunctionComponent<IRow> = ({ item, isSecondReviewSelected }) => {
  if (!item) {
    return <></>;
  }
  const name = item.user?.firstName
    ? `${item.user?.firstName} ${item.user?.lastName ?? ''}`
    : `${item.user?.email}`;
  return (
    <TableRow>
      <UserInfo>
        {/* @ts-ignore */}
        <Avatar borderRadius="3px" showTooltip size={32} userId={item.user?.id} />
        <Tippy
          trigger="mouseenter"
          theme="light"
          popperOptions={tippyOpts}
          content={<TippyName>{name}</TippyName>}
        >
          <span className="name">{name}</span>
        </Tippy>
      </UserInfo>
      <td>{item?.primaryReview?.name || ''}</td>

      <td className={item.primaryReview?.xTheme.average ? '' : 'isNull'}>
        {item.primaryReview?.xTheme.average || '-'}
        {item.primaryReview?.xTheme.rlc ? ` / ${item.primaryReview?.xTheme.rlc}` : ''}
      </td>

      <td className={item.primaryReview?.yTheme.average ? '' : 'isNull'}>
        {item.primaryReview?.yTheme.average || '-'}
        {item.primaryReview?.yTheme.rlc ? ` / ${item.primaryReview?.yTheme.rlc}` : ''}
      </td>

      {isSecondReviewSelected && (
        <>
          <td>{item?.secondaryReview?.name || '-'}</td>
          <td className={item.secondaryReview?.xTheme.average ? '' : 'isNull'}>
            {item.secondaryReview?.xTheme.average || '-'}
            {item.secondaryReview?.xTheme.rlc ? ` / ${item.secondaryReview?.xTheme.rlc}` : ''}
            {item.secondaryReview?.xTheme.trend === 'UP' ? (
              <ArrowIcon size={8} className="icon" />
            ) : item.secondaryReview?.xTheme.trend === 'DOWN' ? (
              <ArrowIcon size={8} className="icon rotate" />
            ) : (
              <></>
            )}
          </td>

          <td className={item.secondaryReview?.yTheme.average ? '' : 'isNull'}>
            {item.secondaryReview?.yTheme.average || '-'}
            {item.secondaryReview?.yTheme.rlc ? ` / ${item.secondaryReview?.yTheme.rlc}` : ''}
            {item.secondaryReview?.yTheme.trend === 'UP' ? (
              <ArrowIcon size={8} className="icon" />
            ) : item.secondaryReview?.yTheme.trend === 'DOWN' ? (
              <ArrowIcon size={8} className="icon rotate" />
            ) : (
              <></>
            )}
          </td>
        </>
      )}
    </TableRow>
  );
};

export { ListViewRow };
