export enum ACTIVITY_STATUSES {
  PROBLEM = 'problem',
  COMPLETED = 'completed',
  TODO = 'todo',
  IN_PROGRESS = 'in_progress',
  // TODO: ON_TRACK will be deleted after migration
  ON_TRACK = 'onTrack',
}

export enum ACTIVITY_UPDATES_STATUSES {
  PROBLEM = 'problem',
  COMPLETED = 'completed',
  TODO = 'todo',
  IN_PROGRESS = 'in_progress',
  ON_TRACK = 'onTrack', // old updates can have onTrack status
}

export enum ACTIVITY_TYPES {
  VIDEO = 'video',
  BOOK = 'book',
  ARTICLE = 'article',
  COURSE = 'course',
  OTHER = 'other',
  PODCAST = 'podcast',
  WEBINAR = 'webinar',
}

export enum ACTIVITY_PROGRESS_TYPES {
  NUMBERS = 'numbers',
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
  IS_DONE = 'isDone',
  METRIC = 'metric'
}

export enum ACTIVITY_SOURCE {
  LEARNED = 'learned',
  COMPANY = 'company',
}

export enum ACTIVITY_ROOT {
  LIBRARY = 'library',
  CUSTOM = 'custom',
}

export enum LIBRARY_ITEM_COLLECTIONS {
  ACTIVITIES = 'activities',
  PATHS = 'paths',
}
