export enum SURVEY_RATING_STATUS {
  TODO = 'todo',
  PUBLISHED = 'published',
}

export enum MissingAverageRatingReason {
  THRESHOLD_NOT_MET = 'thresholdNotMet',
  NO_RATINGS = 'noRatings',
  NOT_SHARED = 'notShared',
}
