import AvatarEditor from 'react-avatar-editor';
import styled from 'styled-components';

import { Button } from '~/components/Buttons';
import Slider from '~/components/Slider';

import { COLORS } from '~/styles';

export const Header = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.SUBTEXT};
  flex: auto;
`;

export const DeleteButton = styled(Button)`
  color: ${COLORS.SUBTEXT};
`;

export const CloseButton = styled(Button)`
  margin-right: 5px;
`;

export const Editor = styled(AvatarEditor)`
  margin: 5px 0px 10px;
  border-radius: 6px;
`;

export const Footer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ScaleSlider = styled(Slider)`
  padding: 0px;
  height: auto;
`;

export const ScaleLabel = styled.span`
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.SUBTEXT};
`;

export const ScaleButton = styled(Button)`
  color: ${COLORS.SUBTEXT};
`;

export const SaveButton = styled(Button)`
  width: 66px;
  min-width: 66px;
  margin-left: 20px;
`;
