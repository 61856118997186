import React, { useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import cloneDeep from 'lodash/cloneDeep';
import size from 'lodash/size';
import { Controller } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Input } from '~/components/Input';

import { Footer, NameModal, NameModalContent, SkillCategoryName, Title } from './design';

import type { IBaseLanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';

import type { IGeneralForm } from './types';
import type { UseFormReturn } from 'react-hook-form';

function SkillCategoryNameModal({
  formMethods,
  categoryIndex,
  languageState,
  onClose,
}: {
  languageState: IBaseLanguageStateReturn;
  formMethods: UseFormReturn<IGeneralForm>;
  categoryIndex: number;
  onClose: () => void;
}) {
  const { i18n } = useLingui();
  const { register, unregister, control, getValues, setValue, trigger } = formMethods;
  const nameFieldArray = useMultiLangFieldArray({
    name: `skillCategories.${categoryIndex}.name`,
    control,
    unregister,
    languageState,
  });

  const [oldValues] = useState(cloneDeep(getValues(`skillCategories.${categoryIndex}.name`)));

  const cancel = () => {
    setValue(`skillCategories.${categoryIndex}.name`, oldValues);
    onClose();
  };

  return (
    <NameModal
      width={500}
      title={
        <Title>
          <Trans>Edit name</Trans>
        </Title>
      }
      onClose={cancel}
      showDivider={false}
      footerStyles={{ padding: 0 }}
      headerStyles={{ padding: 0 }}
      contentStyles={{ padding: 0 }}
      footerRight={
        <Footer>
          <Button
            onClick={cancel}
            label={<Trans>Cancel</Trans>}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
          />
          <Button
            onClick={() => {
              trigger(`skillCategories.${categoryIndex}.name`);
              onClose();
            }}
            label={<Trans>Save</Trans>}
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
          />
        </Footer>
      }
    >
      <NameModalContent>
        <SkillCategoryName>
          <span>
            <Trans>Skill category</Trans>
          </span>
          {nameFieldArray.fields.map((field) => {
            return (
              <Controller
                key={field.id}
                {...register(`skillCategories.${categoryIndex}.name.${field.index}.value`)}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Input
                      key={field.id}
                      value={value}
                      onChange={onChange}
                      placeholder={i18n._(t`Industry name`)}
                      width="281px"
                      leftIcon={
                        size(languageState.languages) > 1
                          ? getUnicodeFlagIcon(
                              field.locale.substring(field.locale.indexOf('_') + 1),
                            )
                          : undefined
                      }
                      maxLength={60}
                    />
                  );
                }}
              />
            );
          })}
        </SkillCategoryName>
      </NameModalContent>
    </NameModal>
  );
}

export { SkillCategoryNameModal };
