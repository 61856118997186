import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const Title = styled.span`
  font-size: 26px;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
`;

export const Subtitle = styled.span`
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${COLORS.SUBTEXT};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  border-bottom: solid 1px ${COLORS.BORDERS};
  padding-bottom: 7px;
  margin-bottom: 20px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
`;

export const Wrapper = styled.div<{ $hideSeparator: boolean }>`
  display: flex;
  justify-content: space-between;
  ${(props) =>
    !props.$hideSeparator &&
    css`
      border-top: solid 1px ${COLORS.BORDERS};
    `}
  padding-top: 37px;
  margin-top: ${(props) => (!props.$hideSeparator ? 37 : 0)}px;
  > div {
    flex: 1;
    display: flex;
  }
`;

export const RightActionContainer = styled.div`
  justify-content: end;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: flex-start;
  justify-content: center;
  margin-right: 85px;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 100%;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 46px;
  min-height: 46px;
  max-width: 46px;
  max-height: 46px;
  border-radius: 6px;
  background-color: ${COLORS.BG_LIST};
`;

export const IconInfoTitle = styled.span`
  font-size: 14px;
  line-height: 1.86;
  color: ${COLORS.ICONS_PRIMARY};
`;

export const IconInfoSubtitle = styled.div`
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
  white-space: nowrap;
`;

export const ItemBoxHorizontal = styled.div`
  box-sizing: border-box;
  content: '';
  width: 24px;
  height: 24px;
  border-radius: 6px;
  background: ${COLORS.HOVER};
  border: 2px solid ${COLORS.BORDERS};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  display: none;
  .plus_icon {
    cursor: pointer;
    color: ${COLORS.PLACEHOLDERS};
  }
  &:hover {
    .plus_icon {
      color: ${COLORS.COMPANY};
    }
  }
`;

export const AddItemHorizontal = styled.div<{ isFirst: boolean }>`
  position: absolute;
  width: 26px;
  height: 100%;
  top: ${({ isFirst }) => (isFirst ? '-6px' : '0')};
  left: ${({ isFirst }) => (isFirst ? '4px' : 'initial')};
  right: ${({ isFirst }) => (isFirst ? 'initial' : '-2px')};
  transform: ${({ isFirst }) => (isFirst ? 'translateX(-50%)' : 'translateX(50%)')};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    width: 4px;
    height: 80%;
    border-radius: 10px;
    transform: translateX(-50%);
    background: ${COLORS.PLACEHOLDERS};
    display: none;
  }
  &:hover {
    .plus_icon {
      display: block;
    }
    &::before {
      display: block;
    }
    ${ItemBoxHorizontal} {
      display: flex;
    }
  }
`;
export const CardWrapper = styled.div<{ isDragging: boolean }>`
  position: relative;
`;
