import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';
// import PerformanceChart from '~/pages/PassportPage/components/PassportHeader/PerformanceChart';

import { COLORS } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';

import {
  HeaderContainer,
  MemberInfoSection,
  MemberAvatar,
  MemberInfo,
  MemberName,
  MemberDetail,
  // MemberPerformance,
  DuplicateIcon,
} from '../../design';

import type { IPassportHeaderProps } from '../../types';

const copyTextToClipboard = (text: string) => () => {
  navigator.clipboard.writeText(text);
};

const PassportHeader = ({ user, isPDF = false }: IPassportHeaderProps) => {
  const { i18n } = useLingui();

  return (
    <>
      <HeaderContainer>
        <MemberInfoSection>
          {/* @ts-ignore */}
          <MemberAvatar
            user={user}
            size={105}
            showTooltip={false}
            noBorder={true}
            isPrintAvatar={isPDF}
          />
          <MemberInfo>
            <MemberName>{getUserFullName(user)}</MemberName>
            <MemberDetail>
              <Icon icon={ICONS.MAIL} color={COLORS.PLACEHOLDERS} size={ICON_SIZES.SMALL} />
              <ToolTip
                tooltip={
                  <span>
                    <DuplicateIcon icon={ICONS.DUPLICATE} size={ICON_SIZES.SMALL} />{' '}
                    {i18n._(t`click to copy`)}
                  </span>
                }
                placement={TOOLTIP_PLACEMENTS.BOTTOM}
                arrow={false}
              >
                {/* eslint-disable-next-line */}
                <span onClick={copyTextToClipboard(user.email)}>{user.email}</span>
              </ToolTip>
            </MemberDetail>
            {user.phone && (
              <MemberDetail>
                <Icon icon={ICONS.PHONE} color={COLORS.PLACEHOLDERS} size={ICON_SIZES.SMALL} />
                <ToolTip
                  tooltip={
                    <span>
                      <DuplicateIcon icon={ICONS.DUPLICATE} size={ICON_SIZES.SMALL} />{' '}
                      {i18n._(t`click to copy`)}
                    </span>
                  }
                  placement={TOOLTIP_PLACEMENTS.BOTTOM}
                  arrow={false}
                >
                  {/* eslint-disable-next-line */}
                  <span onClick={copyTextToClipboard(user.phone)}>{user.phone}</span>
                </ToolTip>
              </MemberDetail>
            )}
          </MemberInfo>
        </MemberInfoSection>
        {/* hide performance section for now LR-8202 */}
        {/* <MemberPerformance>
          <PerformanceChart memberId={user.id} teamId={team?.id} />
        </MemberPerformance> */}
      </HeaderContainer>
    </>
  );
};

export { PassportHeader };
