import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getLikes(filters = {}) {
  const data = {
    ...filters,
  };
  return cloudRequest(serverRoutes.likes.getLikes, { ...data });
}

export function getCompanyLikes() {
  return cloudRequest(serverRoutes.likes.getCompanyLikes);
}

export function getUserLikes(userId, filters = {}) {
  return cloudRequest(serverRoutes.likes.getUserLikes(userId), { ...filters });
}

export function createLikes(likes) {
  const data = { likes };
  return cloudRequest(serverRoutes.likes.createLikes, {}, data);
}

export function retrieveLikesForUser(userId, options) {
  return cloudRequest(serverRoutes.likes.retrieveLikesForUser(userId), {}, options);
}

export function deleteLike(likeId) {
  return cloudRequest(serverRoutes.likes.deleteLike(likeId), {}, {});
}
