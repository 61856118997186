import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { Button, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { List } from './components/List';
import { ListRowPlaceholder } from './components/ListRowPlaceholder';
import { ListRowSignature } from './components/ListRowSignature';
import { Divider, Header, IconWrapper, Reset, Title, Wrapper } from './design';

import { getUser } from '~/selectors/baseGetters';
import { isNotNil } from '~/utils/typePredicates';

import type { IReviewDashboardUserForm } from '../../types';
import type { IUser } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface ISignaturesSectionProps {
  formMethods: UseFormReturn<IReviewDashboardUserForm>;
  isAllowToSign: (userId: IUser['id']) => boolean;
  onSign: () => void;
  onReset: () => void;
  showResetAll: boolean;
}

const Signatures = ({
  formMethods,
  onSign,
  onReset,
  isAllowToSign,
  showResetAll,
}: ISignaturesSectionProps) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;
  const employee = watch('employee');
  const guests = watch('guests');
  const signatures = watch('signatures');
  const status = watch('status');
  const currentUser = useSelector(getUser);

  const usersToSign = [employee?.id, ...guests].filter(isNotNil);
  const isAllGuestsSigned =
    !isEmpty(guests) &&
    isEmpty(
      difference(
        guests,
        signatures.map((s) => s.userId),
      ),
    );

  return (
    <Wrapper>
      <Header>
        <Title>
          <IconWrapper>
            <Icon icon={ICONS.SIGNATURES} size={ICON_SIZES.SMALL} />
          </IconWrapper>
          <Trans>Signatures</Trans>
        </Title>
      </Header>
      <List>
        {isEmpty(usersToSign) ? (
          <ListRowPlaceholder text={i18n._(t`No guests selected.`)} />
        ) : (
          usersToSign.map((userId, key) => {
            const signature = signatures.find((item) => item.userId === userId);

            return (
              <ListRowSignature
                key={key}
                userId={userId}
                isGuest={guests.includes(userId)}
                isCoach={watch('coaches').includes(userId)}
                isCurrentUser={currentUser.id === userId}
                isAllGuestsSigned={isAllGuestsSigned}
                signature={signature}
                reviewStatus={status}
                isShowButton={isAllowToSign(userId)}
                onClick={onSign}
              />
            );
          })
        )}
      </List>
      {showResetAll && !isEmpty(formMethods.watch('signatures')) && (
        <>
          <Divider />
          <Reset>
            <div className="title">
              <Trans>Remove all signatures and open review again?</Trans>
            </div>
            <div className="divider" />
            <Button
              variant={ButtonVariant.TEXT_DELETE}
              label={i18n._(t`Reset all`)}
              onClick={onReset}
            />
          </Reset>
        </>
      )}
    </Wrapper>
  );
};

export { Signatures };
