import React from 'react';

import { SURVEY_PARTICIPANTS_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Button from '~/components/Button';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { IconBlock } from '~/components/IconBlock';

import { StepSection } from './StepSection';

import routes from '~/constants/routes';
import { COLORS } from '~/styles';

import type { ISurvey } from '@learned/types';

interface ISectionParticipantsProps {
  survey: ISurvey;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SectionParticipants = ({ survey }: ISectionParticipantsProps) => {
  const { i18n } = useLingui();
  const participantsType = survey.participants.type;
  const participantsIds = survey.participants.ids;

  const TYPES = {
    [SURVEY_PARTICIPANTS_TYPE.EMPLOYEES]: i18n._(t`Employees`),
    [SURVEY_PARTICIPANTS_TYPE.TEAMS]: i18n._(t`Teams`),
    [SURVEY_PARTICIPANTS_TYPE.COMPANY]: i18n._(t`Company`),
  };

  return (
    <StepSection title={i18n._(t`Participants`)}>
      <Wrapper>
        <IconBlock
          label={TYPES[participantsType]}
          value={
            participantsType === SURVEY_PARTICIPANTS_TYPE.COMPANY
              ? undefined
              : participantsIds.length
          }
          icon={<Icon color={COLORS.ICONS_PRIMARY} icon={ICONS.USERS} size={ICON_SIZES.LARGE} />}
        />
        <Button
          type={'shadow'}
          label={i18n._(t`Manage participants`)}
          styles={{ fontSize: '12px' }}
          onClick={() => {
            routes.SURVEY_UPDATE.go(
              {},
              { isBackPath: true, surveyId: survey?.id, query: { sectionStep: '3' } },
            );
          }}
        />
      </Wrapper>
    </StepSection>
  );
};

export { SectionParticipants };
