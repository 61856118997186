import React from 'react';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { IconWrap, IconCircle } from './design';

import { COLORS } from '~/styles';

type SidebarRowIconProps = {
  active?: boolean;
  isMirrored?: boolean;
  icon: ICONS;
  size?: ICON_SIZES;
  iconColors: string;
};

const SidebarRowIcon = ({
  active = true,
  isMirrored = false,
  icon,
  size = ICON_SIZES.SMALL,
  iconColors,
}: SidebarRowIconProps) => {
  return (
    <IconWrap>
      <IconCircle colors={iconColors} isMirrored={isMirrored} isActive={active}>
        <Icon icon={icon} size={size} color={active ? COLORS.WHITE : COLORS.PLACEHOLDERS} />
      </IconCircle>
    </IconWrap>
  );
};

export { SidebarRowIcon };
