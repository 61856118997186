import React from 'react';

import SVG from 'react-inlinesvg';
import { Props as InlineSvgProps } from 'react-inlinesvg/src/types';

import { ICONS, IconList } from './icons';

export interface IconProps extends Omit<InlineSvgProps, 'src' | 'color'> {
  /**
   * Specify which icon to render
   */
  icon: ICONS;
  /**
   * Change the outline color of the svg. For filling the svg with a color see fill
   */
  color?: string;
  /**
   * Change the height and width of the svg. Default is Medium (18px x 18px)
   */
  size?: ICON_SIZES;
  /**
   * Change the height of the svg, only for special cases. Try to use size instead.
   */
  height?: number | string;
  /**
   * Change the width of the svg, only for special cases. Try to use size instead.
   */
  width?: number | string;
  /**
   * Change the fill of the svg, doesn't do anything for icons that cannot be filled.
   */
  fill?: string;
}

enum ICON_SIZES {
  SMALL = 12,
  MEDIUM = 18,
  LARGE = 22,
}

/*
  When using the Icon component use color to set the color of the outline of the icon and fill to set the color of the fill.
  For some specialized icons it is not possible to easily change the colors with just one or two params. Check the individual svg on how to change the style
 */
function Icon({ icon, color, size = ICON_SIZES.MEDIUM, ...props }: IconProps) {
  const src = IconList[icon];
  const pixelSize = size ?? ICON_SIZES.MEDIUM;
  const colorProps = color ? { color } : {};

  return <SVG src={src} height={pixelSize} width={pixelSize} {...colorProps} {...props} />;
}

export { Icon, ICON_SIZES, ICONS };
