export enum MENU_ITEMS {
  ACCOUNT = 'account',
  CAREER = 'career',
}

export enum SECOND_MENU_ITEMS {
  RESUME = 'my-profile',
}

export enum RIGHT_MENU_TABS {
  SETTINGS = 'settings',
  ABOUT = 'about',
}
