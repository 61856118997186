// SelectUsersModal

import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import every from 'lodash/every';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import {
  AutocompleteFilterSkills,
  AutocompleteFilterTeams,
} from '~/components/AutocompleteFilters';
import { ICONS } from '~/components/Icon';
import { UserAvatar } from '~/components/UserAvatar';

import { StyledSearchSelectModal } from './design';

import type { IColumnTable } from '~/@types/table';
import useDebounce from '~/hooks/useDebounce';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { usePagination } from '~/hooks/usePagination';
import useStringState from '~/hooks/useStringState';
import { getTeams } from '~/selectors/baseGetters';
import { getCompanyUsers } from '~/services/users';
import { COLORS } from '~/styles';

import type { IJobProfile, IMultiLangString, ISkill, ITeam, IUser } from '@learned/types';
import type { I18n } from '@lingui/core';

export enum SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
}

const getColumns = (
  getMultiLangString: (multiLangString: IMultiLangString) => string,
): IColumnTable<IUser & { jobProfiles: IJobProfile[] }>[] => [
  {
    name: 'Members',
    accessor: 'name',
    renderCell: (item, _onClick, isHovered, isSelected) => {
      return (
        <UserAvatar
          userId={item.id}
          options={{
            customColor: isSelected
              ? COLORS.COMPANY
              : isHovered
              ? COLORS.TEXT_HOVER
              : COLORS.TEXT_MAIN,
          }}
        />
      );
    },
    sortBy: {
      asc: {
        key: SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: 'role',
    accessor: 'role',
    renderCell: (item) => {
      const firstProfile = first(item.jobProfiles);
      const firstJobProfileName =
        firstProfile && firstProfile.name ? getMultiLangString(firstProfile.name) : '';
      if (item?.jobProfiles?.length > 1) {
        return `${firstJobProfileName} +${item.jobProfiles.length - 1}`;
      } else {
        return firstJobProfileName;
      }
    },
  },
];

function SelectUsersModal({
  onSave,
  title,
  subtitle,
  onSaveLabelFactory,
  menuProps,
  secondaryButtonProps,
  onClose,
  isHideSkillsFilter = false,
  usersToHide = [],
  isSingleSelect,
  isOnlyCoachTeamMembers,
  isCheckboxVisible = false,
  checkboxProps,
}: {
  title: ReactNode;
  subtitle?: ReactNode;
  onSave: (selectedUsers: string[]) => void;
  onSaveLabelFactory: (total: number) => ReactNode;
  onClose: () => void;
  isHideSkillsFilter?: boolean;
  secondaryButtonProps?: {
    label: ReactNode;
    onClick: () => void;
  };
  isSingleSelect?: boolean;
  usersToHide?: string[];
  isOnlyCoachTeamMembers?: boolean;
  menuProps?: {
    isMenuVisible?: boolean;
    createMenuItems?: (item: IUser) => {
      icon: ICONS;
      action: () => void;
      isWarning?: boolean | undefined;
      label: React.ReactNode;
    }[];
  };
  isCheckboxVisible?: boolean;
  checkboxProps?: {
    label: string;
    onChange: () => void;
    checked: boolean;
  };
}) {
  const { i18n } = useLingui();
  const teamsFromStore = useSelector(getTeams);

  const [total, setTotal] = useState<number | undefined>();
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedSkills, setSelectedSkills] = useState<ISkill[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<IJobProfile[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<ITeam[]>([]);
  const [sortBy, setSortBy] = useState(SORT_OPTIONS.NAME_A_Z);
  const getMultiLangString = useMultiLangString();

  const [loading, setLoading] = useState(false);
  const { pagination, resetPagination, changePagination } = usePagination(10);
  const { value: search, set: setSearch } = useStringState();
  const debSearch = useDebounce(search, 300);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setLoading(true);
      const sortOption =
        sortBy === SORT_OPTIONS.NAME_A_Z ? { fullName: 1 } : sortBy === SORT_OPTIONS.NAME_Z_A;

      const { data } = await getCompanyUsers(
        {
          search: debSearch,
          limit: pagination.limit,
          skip: pagination.skip,
          skills: selectedSkills.map((s) => s.id),
          jobProfiles: selectedRoles.map((r) => r.id),
          teams: !isEmpty(selectedTeams)
            ? selectedTeams.map((t) => t.id)
            : isOnlyCoachTeamMembers
            ? // @ts-ignore
              (Object.values(teamsFromStore) || []).map((t: ITeam) => t.id)
            : [],
          sort: sortOption,
          usersToHide,
        },
        ['teams', 'jobProfiles'],
      );
      if (!isMounted) {
        return;
      }

      setUsers(Object.values(data?.users ?? {}));
      setTotal(data?.total ?? 0);
      setLoading(false);
    };

    fetchData();

    return () => void (isMounted = false);
  }, [
    sortBy,
    debSearch,
    selectedSkills,
    selectedRoles,
    selectedTeams,
    pagination,
    usersToHide,
    teamsFromStore,
    isOnlyCoachTeamMembers,
  ]);

  const onCheckAll = useCallback(() => {
    return every(users.map((user) => selectedUsers.includes(user.id)))
      ? setSelectedUsers((prev) =>
          prev.filter((userId) => !users.map((user) => user.id).includes(userId)),
        )
      : setSelectedUsers((prev) => [...prev, ...users.map((user) => user.id)]);
  }, [selectedUsers, users]);

  const isItemChecked = useCallback(
    (item: IUser) => selectedUsers.includes(item.id),
    [selectedUsers],
  );

  const onSelectItem = useCallback(
    (item: IUser) =>
      setSelectedUsers((prevState) => {
        if (prevState.includes(item.id)) {
          return isSingleSelect ? [] : prevState.filter((value) => value !== item.id);
        }
        return isSingleSelect ? [item.id] : [...prevState, item.id];
      }),
    [isSingleSelect],
  );

  const resetFilters = () => {
    setSelectedTeams([]);
    setSelectedRoles([]);
    setSelectedSkills([]);
  };

  return (
    <StyledSearchSelectModal
      title={title}
      subtitle={subtitle}
      onPrimaryAction={() => onSave(selectedUsers)}
      onClose={onClose}
      isSingleSelect={isSingleSelect}
      primaryActionLabel={onSaveLabelFactory(selectedUsers.length)}
      disablePrimary={selectedUsers.length === 0}
      isCheckboxVisible={isCheckboxVisible}
      checkboxProps={checkboxProps}
      tableListProps={{
        isLoading: loading,
        columns: getColumns(getMultiLangString),
        menuProps,
        data: users,
        secondaryButton: secondaryButtonProps,
        multiSelectProps: {
          multiSelect: {
            checkedCount: selectedUsers.length,
            isAllChecked: every(users.map((user) => selectedUsers.includes(user.id))),
            onSelectItem,
            isItemChecked,
            onCheckAll,
          },
        },
        sortProps: { sortBy, setSortBy },
        placeholderProps: {
          noResultText: i18n._(t`No users found`),
          emptyStateText: i18n._(t`No users available`),
        },
        filtersProps: {
          filters: {
            search,
            setSearch,
          },
          resetFilters,
          isFiltered: false,
          isToggleHideFilterVisible: true,
          filterComponents: (
            <>
              {!isHideSkillsFilter && (
                <AutocompleteFilterSkills
                  checkedList={selectedSkills}
                  onChange={(skills) => {
                    setSelectedSkills(skills);
                    resetPagination();
                  }}
                />
              )}
              <AutocompleteFilterTeams
                checkedList={selectedTeams}
                onChange={(teams) => setSelectedTeams(teams)}
                isPermissionsCheck={isOnlyCoachTeamMembers}
              />
            </>
          ),
        },
        paginationProps: {
          pagination,
          changePagination,
          totalCount: total,
        },
      }}
    />
  );
}

export { SelectUsersModal };
