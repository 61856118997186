import React, { Component } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import Button from '~/components/Button';
import { TextHeader } from '~/components/Text';
import AuthLayout from '~/layouts/AuthLayout';

import { Auth0Context } from '~/auth0';

const Container = styled.div`
  text-align: center;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
  margin-bottom: 32px;
`;

class DisabledAccountPage extends Component {
  static contextType = Auth0Context;

  redirectToLogin = async () => {
    this.context.logout();
  };

  render() {
    const { i18n } = this.props;
    const email = new URL(window.location.href).searchParams.get('email');

    return (
      <AuthLayout>
        <Container>
          <TextHeader>Account disabled!</TextHeader>
          {email ? (
            <Text>
              <Trans>
                It appears that your Learned account is disabled for this e-mail address:
              </Trans>
              <br />
              <br />
              <strong>{email}</strong>
              <br />
              <br />
              <Trans>Please contact your HR manager for support</Trans>
            </Text>
          ) : (
            <Text>
              <Trans>It appears that you do not have a Learned account.</Trans>
              <br />
              <br />
              <Trans>Please contact your HR manager for support</Trans>
            </Text>
          )}
          <Button
            label={i18n._(t`Login with a different account`)}
            onClick={this.redirectToLogin}
            styles={{
              margin: '32px auto 0 auto',
              padding: '0 32px',
            }}
          />
        </Container>
      </AuthLayout>
    );
  }
}

export default withI18n()(DisabledAccountPage);
