import React, { useState } from 'react';

import { GOAL_PROGRESS_TYPES, GOAL_STATUSES, GOAL_STATUSES_NEW } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';

import UpdateProgressSection from '~/components/Activity/UpdateProgressSection';
import Button from '~/components/Button';
import Modal from '~/components/Modal';

import { GOAL_STATUSES as GOAL_STATUSES_STYLED } from '~/constants';
import useBoolState from '~/hooks/useBoolState';

const GoalProgressModal = ({ item, onClose, onSubmit }) => {
  const { i18n } = useLingui();
  const [progress, setProgress] = useState(item.progress);
  const [status, setStatus] = useState(item.status);
  const [comment, setComment] = useState('');
  const $loading = useBoolState(false);
  const goalStatuses = Object.values(GOAL_STATUSES_STYLED).filter(
    (i) => i.key !== GOAL_STATUSES.DRAFT,
  );

  const handleChangeProgress = (value) => {
    setProgress(value);

    // Changes status to completed or on track depending if the value selected is below the max
    if (item.progressType !== GOAL_PROGRESS_TYPES.IS_DONE) {
      const { max } = item.progressDetails;
      if (!isNull(max) && value >= max) {
        setStatus(GOAL_STATUSES.COMPLETED);
      } else if (status !== GOAL_STATUSES.PROBLEM) {
        value === 0 ? setStatus(GOAL_STATUSES_NEW.TODO) : setStatus(GOAL_STATUSES_NEW.IN_PROGRESS);
      }
    }
  };

  const handleSubmit = async () => {
    $loading.on();
    await onSubmit({ progress, status, comment });
    $loading.off();
  };

  return (
    <Modal
      width={661}
      minWidth={661}
      title={i18n._(t`Update goal progress`)}
      onClose={onClose}
      footerRight={
        <Button
          label={i18n._(t`Update`)}
          onClick={handleSubmit}
          disabled={
            (item.progress === progress &&
              item.status === status &&
              String(item.comment || '') === String(comment || '')) ||
            $loading.value
          }
        />
      }
    >
      <UpdateProgressSection
        listStatuses={goalStatuses}
        progressType={item.progressType}
        status={status}
        progress={progress}
        comment={comment}
        progressDetails={item.progressDetails}
        onChangeProgress={handleChangeProgress}
        onChangeStatus={setStatus}
        onCommentChange={setComment}
      />
    </Modal>
  );
};

GoalProgressModal.propTypes = {
  item: PropTypes.object,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default GoalProgressModal;
