import React from 'react';

import { REVIEW_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import type { IReviewDashboardCycleForm } from '~/pages/Reviews/DashboardCycle/ReviewDashboardForm/types';
import { SectionHeader } from '~/pages/Reviews/DashboardCycle/tabs/Setup/components/SectionHeader';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import { StatusLabel } from '../../../ReviewDashboardForm/components/StatusLabel';
import { SectionField } from '../components/SectionField';
import { SectionGrid } from '../design';

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface ISectionGeneralProps {
  formMethods: UseFormReturn<IReviewDashboardCycleForm>;
  onArchive: () => void;
  onUnarchive: () => void;
  onEdit?: () => void;
  isAllowToArchive: boolean;
  isAllowToUnarchive: boolean;
}

const SectionGeneral = ({
  formMethods,
  onArchive,
  onUnarchive,
  onEdit,
  isAllowToArchive,
  isAllowToUnarchive,
}: ISectionGeneralProps) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;
  const getMultiLangString = useMultiLangString();

  const watchName = watch('name') || '';
  const watchTemplateName = watch('reviewTemplateName');
  const watchStatus = watch('status');

  return (
    <>
      <SectionHeader title={i18n._(t`General`)} onEdit={onEdit} />
      <SectionGrid>
        <SectionField
          value={getMultiLangString(turnArrayIntoMultiLang(watchName))}
          label={i18n._(t`Name`)}
        />
        <SectionField
          value={
            <LabelWrapper>
              <StatusLabel status={watchStatus} />
              {(isAllowToArchive || watchStatus === REVIEW_STATUS.PUBLISHED) && (
                <Button
                  size={ButtonSize.MEDIUM}
                  icon={ICONS.ARCHIVE}
                  variant={ButtonVariant.TEXT_PRIMARY}
                  label={i18n._(t`Archive`)}
                  onClick={onArchive}
                  disabled={watchStatus === REVIEW_STATUS.PUBLISHED}
                  tooltip={
                    watchStatus === REVIEW_STATUS.PUBLISHED
                      ? i18n._(t`Review cycle has not started yet`)
                      : undefined
                  }
                />
              )}
              {isAllowToUnarchive && (
                <Button
                  size={ButtonSize.MEDIUM}
                  icon={ICONS.UNARCHIVE}
                  variant={ButtonVariant.TEXT_PRIMARY}
                  label={i18n._(t`Unarchive`)}
                  onClick={onUnarchive}
                />
              )}
            </LabelWrapper>
          }
          label={i18n._(t`Status`)}
        />
        <SectionField
          value={getMultiLangString(turnArrayIntoMultiLang(watchTemplateName))}
          label={i18n._(t`Review template`)}
        />
        <SectionField
          value={i18n._(
            t`When a review cycle is archived, changes to the reviews are no longer allowed and coaches are no longer able to unarchive reviews.`,
          )}
        />
      </SectionGrid>
    </>
  );
};

export { SectionGeneral };
