enum IMPORT_MEMBERS_HEADER_ROWS {
  admin = 'admin',
  coachOfTeams = 'coach_of_team(s)',
  email = 'email',
  firstName = 'first_name',
  inTeams = 'in_team(s)',
  jobs = 'job(s)',
  lastName = 'last_name',
}

type UserCsv = {
  [IMPORT_MEMBERS_HEADER_ROWS.admin]: string;
  [IMPORT_MEMBERS_HEADER_ROWS.coachOfTeams]: string[];
  [IMPORT_MEMBERS_HEADER_ROWS.email]: string;
  [IMPORT_MEMBERS_HEADER_ROWS.firstName]: string;
  [IMPORT_MEMBERS_HEADER_ROWS.inTeams]: string[];
  [IMPORT_MEMBERS_HEADER_ROWS.jobs]: string[];
  [IMPORT_MEMBERS_HEADER_ROWS.lastName]: string;
};

export { IMPORT_MEMBERS_HEADER_ROWS };
export type { UserCsv };
