import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import IconMenu from '~/components/IconMenu';
import RickTextView from '~/components/RickTextView';

import { COLORS, COLOR_PALETTE } from '~/styles';
import getAbbr from '~/utils/abbr';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

const Wrapper = styled.div`
  display: block;
  margin-top: 12px;
  margin-bottom: 32px;
`;

const ExperienceItem = styled.div`
  border-radius: 3px;
  border: solid 1px ${COLORS.BORDER_LIGHT};
  display: block;
  padding: 16px;
  margin-bottom: 16px;
  @media print {
    break-inside: avoid;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
  }
`;

const Flex = styled.div`
  display: flex;
  width: 100%;
`;

const Info = styled(Flex)`
  flex-direction: column;
`;

const TopBar = styled(Flex)`
  align-items: center;
`;

const Title = styled.div`
  color: ${COLOR_PALETTE.BLACK};
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  margin-right: 7px;
`;

const Employer = styled.div`
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
`;

const Period = styled(Employer)`
  margin-top: 6px;
`;

const Description = styled(RickTextView)`
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  margin-top: 15px;
`;

const ExperienceImage = styled.div`
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  border-radius: 3px;
  background-color: ${COLORS.BG_PAGE};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.68px;
  text-align: center;
  color: var(--company-color);
  text-transform: uppercase;
`;

const Actions = styled.div`
  margin-left: auto;
`;

const UserExperience = ({ experience, handleUpdate, handleDelete, readOnly, i18n }) => {
  const getPeriod = (item) => {
    if (item.completedDate) {
      return convertToTimeString(item.completedDate, TIME_FORMATS.MONTH_YEAR);
    }
    const startDate = convertToTimeString(item.from, TIME_FORMATS.MONTH_YEAR);
    const endDate = !item.to
      ? i18n._(t`Present`)
      : convertToTimeString(item.to, TIME_FORMATS.MONTH_YEAR);
    return `${startDate} - ${endDate}`;
  };

  const getLocation = (item) => item.employer || item.school;

  return (
    <Wrapper>
      {experience
        .sort((a, b) => new Date(b.from) - new Date(a.from))
        .map((e) => {
          const menuItems = [
            {
              label: i18n._(t`Update`),
              action: () => handleUpdate(e),
            },
            {
              label: i18n._(t`Remove`),
              action: () => handleDelete(e),
            },
          ];
          return (
            <ExperienceItem key={e.id}>
              <Flex>
                <ExperienceImage>{getAbbr(getLocation(e), 1)}</ExperienceImage>
                <Info>
                  <TopBar>
                    <Title>{e.name}</Title>
                    <Employer>{getLocation(e)}</Employer>
                    {!readOnly && (
                      <Actions>
                        <IconMenu items={menuItems} />
                      </Actions>
                    )}
                  </TopBar>
                  <Period>{getPeriod(e)}</Period>
                </Info>
              </Flex>
              <Description html={e.description} />
            </ExperienceItem>
          );
        })}
    </Wrapper>
  );
};

export default withI18n()(UserExperience);
