import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function createEducation(body) {
  return cloudRequest(serverRoutes.educations.createEducation, {}, body);
}

export function getUserEducations(userId) {
  return cloudRequest(serverRoutes.educations.getUserEducations(userId));
}

export function getEducation(id, { populate = [] } = {}) {
  return cloudRequest(serverRoutes.educations.getEducation(id), { populate });
}

export function updateEducation(id, body) {
  return cloudRequest(serverRoutes.educations.updateEducation(id), {}, body);
}

export function deleteEducation(id) {
  return cloudRequest(serverRoutes.educations.deleteEducation(id));
}
