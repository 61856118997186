import React from 'react';

import { GOAL_STATUSES } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import styled from 'styled-components';

import { StatusLabel } from '~/components/GoalsBlock/components/StatusLabel';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import ToolTip from '~/components/Tooltip';

import { IColumnTable } from '~/@types/table';
import { COLORS } from '~/styles';

interface IGoalItem {
  id: string;
  type: string;
  name: string;
  calculatedProgress?: number;
  status: GOAL_STATUSES;
  icon: ICONS;
  iconColor: string | undefined;
}

export const FlexRow = styled.div<{
  $isHidePointer?: boolean;
}>`
  width: min-content;
  display: flex;
  align-items: left;
  cursor: ${({ $isHidePointer }) => (!$isHidePointer ? 'pointer' : 'default')};
`;

export const NameRow = styled.div<{ isCentered?: boolean; padding?: string }>`
  background-color: inherit;
`;

export const Label = styled.span`
  max-width: 330px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TD = styled.div<{
  minWidth?: string;
  maxWidth?: string;
  padding?: string;
  isGrayColor?: boolean;
  $isPointer?: boolean;
}>`
  min-width: 120px;
  max-width: 150px;
`;

export const TH = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${COLORS.SUBTEXT};
  padding: 16px;
`;

const GOALS_GRID_COLUMNS: IColumnTable<IGoalItem>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Type`),
    accessor: 'type',
    maxWidth: '20px',
    renderCell: (item) => {
      return (
        <NameRow>
          <FlexRow $isHidePointer>
            <Icon icon={item.icon} size={ICON_SIZES.MEDIUM} color={item.iconColor} />
          </FlexRow>
        </NameRow>
      );
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'name',
    maxWidth: '350px',
    renderCell: (item) => {
      return (
        <NameRow>
          {/* @ts-ignore */}
          <FlexRow>
            <ToolTip tooltip={item.name}>
              <Label>{item.name}</Label>
            </ToolTip>
          </FlexRow>
        </NameRow>
      );
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Progress`),
    accessor: 'progress',
    maxWidth: '100px',
    renderCell: (item) => {
      return (
        <NameRow>
          <FlexRow $isHidePointer>
            <TD>{`${
              item.calculatedProgress
                ? Math.round((item.calculatedProgress + Number.EPSILON) * 100) / 100
                : 0
            }%`}</TD>
          </FlexRow>
        </NameRow>
      );
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Status`),
    accessor: 'status',
    maxWidth: '150px',
    renderCell: (item) => {
      return (
        <NameRow>
          <FlexRow $isHidePointer>
            <TD>
              <StatusLabel status={item.status} progress={item?.calculatedProgress} />
            </TD>
          </FlexRow>
        </NameRow>
      );
    },
  },
];

const GOALS_GRID_COLUMNS_ONLY_NAME: IColumnTable<IGoalItem>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'name',
    maxWidth: '100%',
    renderCell: (item) => {
      return (
        <NameRow>
          {/* @ts-ignore */}
          <FlexRow>
            <ToolTip tooltip={item.name}>
              <Label>{item.name}</Label>
            </ToolTip>
          </FlexRow>
        </NameRow>
      );
    },
  },
];

export { GOALS_GRID_COLUMNS, GOALS_GRID_COLUMNS_ONLY_NAME };
