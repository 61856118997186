import React, { Fragment } from 'react';

import { Trans } from '@lingui/macro';

import { Icon, ICONS } from '~/components/Icon';
import { JobProfileCard } from '~/components/JobCards/JobProfileCard';
import { JobProfileSummaryCard } from '~/components/JobCards/JobProfileSummaryCard';
import { OvalCard } from '~/components/JobCards/Oval';

import {
  Container,
  GradientOverlay,
  IconWrapper,
  Line,
  PathContainer,
  PathTitleRectangle,
  PathTitleWrapper,
  ProfileSummaryContainer,
  CareerPathWrapper,
  SummaryCardContainer,
  SummaryLine,
  SummaryLineText,
  SummaryLineWrapper,
} from './design';

import useBoolState from '~/hooks/useBoolState';

interface ILike {
  company: string;
  id: string;
  jobProfile: string;
  user: string;
}

interface IJobProfile {
  coverImage?: string;
  skillMatch?: number;
  name: string;
  id: string;
  like?: ILike;
}

interface ICareerPathComponentProps {
  title: string;
  subtitle: string;
  companyColor?: string;
  careerPaths: IJobProfile[];
  backgroundColor: string;
  textColor: string;
  onClickViewDetails: (roleId: string) => void;
  handleLikeButtonClick: (jobProfileId: string, likeId?: string) => Promise<void>;
}

const CareerPathComponent = ({
  title,
  subtitle,
  companyColor,
  careerPaths,
  backgroundColor,
  textColor,
  onClickViewDetails,
  handleLikeButtonClick,
}: ICareerPathComponentProps) => {
  const $showSummaryCard = useBoolState(false);

  const handleSummaryTextClick = () => {
    $showSummaryCard.toggle();
  };

  return (
    <Container>
      <PathContainer>
        <PathTitleWrapper textColor={textColor}>
          <PathTitleRectangle width={110} textColor={textColor} backgroundColor={backgroundColor}>
            <span>
              <Trans>{title}</Trans>
            </span>
          </PathTitleRectangle>
          <PathTitleRectangle width={36} textColor={textColor} backgroundColor={backgroundColor}>
            <IconWrapper textColor={textColor}>
              <Icon icon={ICONS.SHAPE} />
            </IconWrapper>
            <span>{careerPaths?.length}</span>
          </PathTitleRectangle>
          <span>
            <Trans>{subtitle}</Trans>
          </span>
        </PathTitleWrapper>
        <CareerPathWrapper>
          <JobProfileCard
            companyColor={companyColor}
            jobProfile={careerPaths[0]}
            onClickViewDetails={onClickViewDetails}
            handleLikeButtonClick={handleLikeButtonClick}
          />
          <ProfileSummaryContainer $showSummary={$showSummaryCard.value}>
            <SummaryLineWrapper>
              <SummaryLine>
                {careerPaths.length - 2 > 0 &&
                  Array.from(Array(careerPaths.length - 2)).map((_, i) => {
                    return $showSummaryCard.value ? (
                      <Fragment key={i}>
                        <Line width={66} />
                        <JobProfileSummaryCard
                          jobProfile={careerPaths[i + 1]}
                          onClickViewDetails={onClickViewDetails}
                        />
                      </Fragment>
                    ) : (
                      <Fragment key={i}>
                        <Line width={i ? 42 : 66} />
                        <OvalCard />
                      </Fragment>
                    );
                  })}
                {careerPaths.length - 1 > 0 && <Line width={66} />}
              </SummaryLine>
              {careerPaths.length - 2 > 0 && (
                <SummaryLineText onClick={handleSummaryTextClick}>
                  <Trans>
                    {$showSummaryCard.value ? 'Hide' : 'Show'} +{careerPaths.length - 2} roles
                  </Trans>
                </SummaryLineText>
              )}
            </SummaryLineWrapper>
            {careerPaths.length - 1 > 0 && (
              <SummaryCardContainer $showSummary={$showSummaryCard.value}>
                <JobProfileSummaryCard
                  jobProfile={careerPaths?.[careerPaths.length - 1]}
                  onClickViewDetails={onClickViewDetails}
                />
              </SummaryCardContainer>
            )}
          </ProfileSummaryContainer>
        </CareerPathWrapper>
      </PathContainer>
      <GradientOverlay />
    </Container>
  );
};

export { CareerPathComponent };
