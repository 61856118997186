import styled from 'styled-components';

import { COLORS } from '~/styles';

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  background: ${COLORS.WHITE};
  color: ${COLORS.TEXT_MAIN};
  border-radius: 6px;
  font-size: 12px;
  gap: 4px;
  width: 100%;
  height: 100%;
  min-height: 150px;
  display: flex;
`;

const TableCtr = styled.div<{
  maxCharacters: number;
}>`
  display: grid;
  grid-template-columns: ${({ maxCharacters }) => `${maxCharacters}ch`} auto;
  align-items: start;
  align-content: start;
  width: 100%;
`;

const RowTitle = styled.div<{
  isActive: boolean;
  mHeight: string;
  isClickable: boolean;
  maxCharacters: number;
}>`
  padding: 5px 10px;
  border-radius: 6px 0 0 6px;
  box-sizing: border-box;
  background: ${({ isActive }) => (isActive ? `${COLORS.HOVER}` : 'transparent')};
  max-width: ${({ maxCharacters }) => `${maxCharacters}ch`};
  height: ${({ mHeight }) => mHeight};
  display: flex;
  align-items: center;
  & > span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const PrimaryDimension = styled.div`
  position: absolute;
  left: 10px;
  top: -20px;
  transform: translateY(-100%);
  display: flex;
  gap: 5px;
  align-items: center;
  z-index: 1;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
`;

const SecondaryDimension = styled.div`
  position: absolute;
  left: 0;
  top: -20px;
  transform: translateY(-100%);
  display: flex;
  gap: 5px;
  align-items: center;
  z-index: 1;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
`;

const SortIcons = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemNr = styled.div<{ isActive: boolean }>`
  color: ${COLORS.WHITE};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;

const Numbers = styled.div<{ leftAlign: string }>`
  position: absolute;
  left: ${(props) => props.leftAlign};
  bottom: -20px;
  line-height: 16px;
  text-align: center;
  z-index: 1;
  box-sizing: border-box;
  transform: translateX(-50%);
`;

const Line = styled.div<{ leftAlign: string }>`
  border-sizing: border-box;
  position: absolute;
  left: ${(props) => props.leftAlign};
  top: 0;
  bottom: 0;
  border-left: 1px solid ${COLORS.BORDERS};
  z-index: -1;
`;

const GraphLineContainer = styled.div<{
  maxCharacters: number;
}>`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: calc(100% - ${({ maxCharacters }) => `${maxCharacters}ch`});
  isolation: isolate;
`;

export {
  GraphLineContainer,
  ItemNr,
  Container,
  TableCtr,
  RowTitle,
  PrimaryDimension,
  SecondaryDimension,
  SortIcons,
  Numbers,
  Line,
};
