import DateIcon from '~/assets/calendar-1@3x.png';

import { CONVERSATION_COLLECTION_TYPES } from '~/constants';
import { COLOR_PALETTE, COLOR_SET, COLOR_UNIT } from '~/styles';

export const CONVERSATION_STYLES = {
  [CONVERSATION_COLLECTION_TYPES.REVIEW.key]: {
    color: COLOR_UNIT.GOAL_BUSINESS.primary,
    background: COLOR_PALETTE.WHITE,
    icon: DateIcon,
    iconBackground: 'linear-gradient(66deg, #e1d9f8 31%, #f6f3ff 80%)',
    iconColor: COLOR_SET.MIDNICHT_BLUE,
  },
  [CONVERSATION_COLLECTION_TYPES.CONVERSATION.key]: {
    color: COLOR_UNIT.GOAL_BUSINESS.primary,
    background: COLOR_PALETTE.WHITE,
    icon: DateIcon,
    iconBackground: 'linear-gradient(66deg, #f6cac1 24%, #fff2ef 79%)',
    iconColor: COLOR_SET.MIDNICHT_BLUE,
  },
};
