import React, { useState, useEffect, useCallback } from 'react';

import { showKomboConnect } from '@kombo-api/connect';
import { CONFIRMATION_MODAL_TYPE, INTEGRATIONS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';

import { ButtonSize } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { getIntegrationOverviewColumns } from './components/columns';
import { IntegrationsGrid } from './components/IntegrationsGrid';
import { UpdateIntegrationModal } from './components/UpdateIntegrationModal';
import {
  Container,
  OverviewContainer,
  HeaderActionWrapper,
  HeaderWrapper,
  SearchFieldWrapper,
  Title,
  TextButton,
  AddButton,
} from './design';
import { HRIS_INTEGRATION_STATUS, type IIntegration } from './types';

import useBoolState from '~/hooks/useBoolState';
import {
  activateIntegration,
  initializeIntegration,
  relinkIntegration,
} from '~/services/integrations';
import {
  getCompanyIntegrationSettings,
  deleteIntegrationSettings,
  testIntegrationSettings,
  updateIntegrationSettings,
} from '~/services/integrationSettings';
import * as userActions from '~/store/users/actions';
import { COLORS } from '~/styles';

const WAITING_TIME = 2000;

const CompanyHRISIntegrationSettings = () => {
  const { i18n } = useLingui();
  const [integrations, setIntegrations] = useState<IIntegration[]>([]);
  const [filteredIntegrations, setFilteredIntegrations] = useState<IIntegration[]>([]);
  const [updateIntegration, setUpdateIntegration] = useState<IIntegration | null>(null);
  const [deleteIntegration, setDeleteIntegration] = useState<IIntegration | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const $filtered = useBoolState(false);
  const $loading = useBoolState(true);
  const $testing = useBoolState(false);
  const $showUpdateIntegrationModal = useBoolState();
  const $showDeleteIntegrationModal = useBoolState();
  const $showWarningModal = useBoolState();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  useEffect(() => {
    if (searchTerm) {
      $filtered.on();
      setFilteredIntegrations(
        integrations.filter((integration) =>
          integration.name.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    } else {
      $filtered.off();
      setFilteredIntegrations(integrations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$filtered.value, integrations, searchTerm]);

  const getColumns = useCallback(
    () => getIntegrationOverviewColumns($testing.value),
    [$testing.value],
  );

  const testIntegrations = async () => {
    $testing.on();
    try {
      const res = await Promise.all([
        ...integrations.map((integration) =>
          testIntegrationSettings({ id: integration.id, externalSoftware: INTEGRATIONS.KOMBO }),
        ),
        new Promise((resolve) => setTimeout(resolve, WAITING_TIME)),
      ]);
      setIntegrations(
        integrations.map((integration, index) => {
          return {
            ...integration,
            status: res[index].isValid
              ? HRIS_INTEGRATION_STATUS.ACTIVE
              : HRIS_INTEGRATION_STATUS.INACTIVE,
          };
        }),
      );
    } finally {
      $testing.off();
    }
  };

  const fetchIntegrationSettings = async (isReturnFetchedData?: boolean) => {
    const integrations = await getCompanyIntegrationSettings(true);
    const integrationsList = Object.values(integrations).map((item) => {
      const integration = item as unknown as IIntegration;
      return {
        ...integration,
        status: integration?.komboData?.isActive
          ? HRIS_INTEGRATION_STATUS.ACTIVE
          : HRIS_INTEGRATION_STATUS.INACTIVE,
      };
    });
    setIntegrations(integrationsList);
    setFilteredIntegrations(integrationsList);
    return isReturnFetchedData && integrationsList;
  };

  useEffect(() => {
    fetchIntegrationSettings().then(() => {
      $loading.off();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = async () => {
    if (deleteIntegration) {
      await deleteIntegrationSettings(deleteIntegration.id);

      // Refresh user store
      dispatch(userActions.fetchUserData());

      setIntegrations(
        Object.values(integrations).filter((i: IIntegration) => i.id !== deleteIntegration.id),
      );
      $showDeleteIntegrationModal.off();
    }
  };

  const handleDeleteClick = (integration: IIntegration) => {
    setDeleteIntegration(integration);
    $showDeleteIntegrationModal.on();
  };

  async function getKomboConnectLink() {
    const response = await initializeIntegration();

    return response?.data?.link;
  }

  async function activateKomboIntegration(token: string) {
    const response = await activateIntegration(token);

    // Re-fetch kombo integrations. Get results as setting the state is not fast enough for the next logic
    $loading.on();
    const fetchedIntegrations = await fetchIntegrationSettings(true);
    $loading.off();

    // Auto open settings modal for newly created integration
    const integrationSettingsId = response?.data?.integrationSettingsId;
    if (integrationSettingsId && fetchedIntegrations) {
      const newIntegration = fetchedIntegrations.find(
        (integration: IIntegration) => integration.id === integrationSettingsId,
      );

      setUpdateIntegration(newIntegration || null);

      $showUpdateIntegrationModal.on();
    }
  }

  async function openKomboModal(link: string) {
    try {
      const activationToken = await showKomboConnect(link);
      await activateKomboIntegration(activationToken);
    } catch (e) {
      // Caught when the user cancels the flow TODO: add warning modal. Will be added in a later MR
    }
  }

  async function connectHRIS() {
    const link = await getKomboConnectLink();
    await openKomboModal(link);
  }

  async function relinkHRIS(integrationSettingsId: string) {
    const response = await relinkIntegration(integrationSettingsId);
    await openKomboModal(response?.data?.link);
  }

  const createMenuItems = (item: IIntegration) => {
    return [
      {
        label: i18n._(t`Edit`),
        action: () => {
          relinkHRIS(item.id);
        },
        icon: ICONS.EDIT_PENCIL,
      },
      {
        label: i18n._(t`Delete`),
        action: () => handleDeleteClick(item),
        icon: ICONS.DELETE_BIN,
        isWarning: true,
        hoverIconColor: COLORS.ACCENT_ERROR,
      },
      {
        label: i18n._(t`Settings`),
        action: () => {
          setUpdateIntegration(item);
          $showUpdateIntegrationModal.on();
        },
        icon: ICONS.SETTINGS,
      },
    ];
  };

  const onCloseSettingsModal = (
    integration: IIntegration,
    integrationSettings: {
      [key: string]: {
        automatic: boolean;
        enabled: boolean;
      };
    },
  ) => {
    if (isEqual(integration.integrationModules, integrationSettings)) {
      fetchIntegrationSettings().then(() => {
        $showUpdateIntegrationModal.off();
      });
    } else {
      $showWarningModal.on();
    }
  };

  const onUpdateSettings = async (
    integration: IIntegration,
    integrationSettings: {
      [key: string]: {
        automatic: boolean;
        enabled: boolean;
      };
    },
  ) => {
    await updateIntegrationSettings(integration.id, {
      externalSoftware: integration.externalSoftware,
      integrationModules: integrationSettings,
    });
    $showUpdateIntegrationModal.off();
    addToast({
      title: i18n._(t`Integration settings saved`),
      type: TOAST_TYPES.INFO,
    });
    fetchIntegrationSettings(true);
  };

  const onCloseWarningModal = () => {
    $showWarningModal.off();
    $showUpdateIntegrationModal.off();
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      if (newValue !== searchTerm) {
        setSearchTerm(newValue);
      }
    },
    [searchTerm],
  );

  return (
    <Container>
      <Title>
        <Trans>Integrations</Trans>
      </Title>
      <OverviewContainer>
        <HeaderWrapper>
          <SearchFieldWrapper
            value={searchTerm}
            placeholder={i18n._('Search...')}
            onChange={handleChange}
          />
          <HeaderActionWrapper>
            {filteredIntegrations.length > 0 && (
              <TextButton onClick={testIntegrations}>
                <Trans>Test integration settings</Trans>
              </TextButton>
            )}
            <AddButton size={ButtonSize.MEDIUM} onClick={() => connectHRIS()}>
              <Trans>Add integration</Trans>
            </AddButton>
          </HeaderActionWrapper>
        </HeaderWrapper>
        <IntegrationsGrid
          data={filteredIntegrations}
          columns={getColumns()}
          isLoading={$loading.value}
          placeholderProps={{
            noResultText: i18n._(t`No integrations found for you`),
            emptyStateText: i18n._(
              t`No integrations yet. Add your first integration to get started.`,
            ),
          }}
          menuProps={{
            createMenuItems,
            isMenuVisible: true,
          }}
          isFiltered={$filtered.value}
          onResetSearch={() => setSearchTerm('')}
        />
      </OverviewContainer>
      {$showUpdateIntegrationModal.value && updateIntegration && (
        <UpdateIntegrationModal
          integration={updateIntegration}
          onClose={onCloseSettingsModal}
          onSave={onUpdateSettings}
        />
      )}
      {$showDeleteIntegrationModal.value && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.DELETE}
          description={i18n._(t`Are you sure you want to delete this integration?`)}
          onClose={() => {
            $showDeleteIntegrationModal.off();
          }}
          onSubmit={onDelete}
          cancelButtonTextColor={COLORS.TEXT_MAIN}
        />
      )}
      {$showWarningModal.value && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.WARNING}
          title={i18n._(t`Close without saving?`)}
          description={i18n._(t`Are you sure you want to close? Your changes will not be saved.`)}
          onClose={$showWarningModal.off}
          onSubmit={onCloseWarningModal}
        />
      )}
    </Container>
  );
};

export { CompanyHRISIntegrationSettings };
