import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { SURVEY_QUESTION_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Dialog from '~/components/Dialog';
import { ICONS } from '~/components/Icon';
import type { IconNames } from '~/components/IconOld';
import { MultiLayerList } from '~/components/MultiLayerList';
import { SearchField } from '~/components/Text';

import useDebounce from '~/hooks/useDebounce';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { usePagination } from '~/hooks/usePagination';
import { importQuestions } from '~/services/surveyTemplates';
import { getSurveyThemes } from '~/services/surveyThemes';
import { COLORS } from '~/styles';

import type { ISurveyQuestion, ISurveyTheme } from '@learned/types';

const StyledModal = styled(Dialog)`
  max-width: 750px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 22px 38px 32px 42px;
  box-shadow: -2px 3px 5px 1px rgba(0, 0, 0, 0.11);
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: 26px;
  font-weight: normal;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
  padding: 0;
  margin: 0;
`;

const SubTitle = styled.span`
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
  font-size: 16px;
  margin: 0;
  padding: 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  margin-right: 12px;
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 30px;
`;

type PopulatedSurveyTheme = Omit<ISurveyTheme, 'questions'> & {
  questions: ISurveyQuestion[];
  showQuestions: boolean;
};

const ANSWER_TYPES: Record<
  Exclude<SURVEY_QUESTION_TYPES, SURVEY_QUESTION_TYPES.DROPDOWN>,
  { key: string; icon: ICONS }
> = {
  [SURVEY_QUESTION_TYPES.TEXT]: {
    key: SURVEY_QUESTION_TYPES.TEXT,
    icon: ICONS.TEXT_ANSWER,
  },
  [SURVEY_QUESTION_TYPES.SINGLE]: {
    key: SURVEY_QUESTION_TYPES.SINGLE,
    icon: ICONS.SINGLE_SELECT,
  },
  [SURVEY_QUESTION_TYPES.MULTIPLE]: {
    key: SURVEY_QUESTION_TYPES.MULTIPLE,
    icon: ICONS.MULTI_SELECT,
  },
  [SURVEY_QUESTION_TYPES.TRAFFIC]: {
    key: SURVEY_QUESTION_TYPES.TRAFFIC,
    icon: ICONS.TRAFFIC,
  },
  [SURVEY_QUESTION_TYPES.STARS]: {
    key: SURVEY_QUESTION_TYPES.STARS,
    icon: ICONS.STAR,
  },
  [SURVEY_QUESTION_TYPES.SMILEYS]: {
    key: SURVEY_QUESTION_TYPES.SMILEYS,
    icon: ICONS.SMILEY,
  },
};

function ImportQuestionsModal({
  questionsToHide,
  onClose,
  onSubmit,
  surveyTemplateId,
  index,
  createNewQuestion,
}: {
  questionsToHide: string[];
  surveyTemplateId: string;
  index?: number;
  languageState: ILanguageStateReturn;
  onClose: () => void;
  onSubmit: () => void;
  createNewQuestion: (index?: number) => void;
}) {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const [search, setSearch] = useState<string | undefined>();
  const debouncedSearch = useDebounce(search, 300);

  const [themes, setThemes] = useState<PopulatedSurveyTheme[]>([]);
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const { pagination, changePagination } = usePagination(10);
  const questionsFilter = useCallback(
    (question) => !questionsToHide.includes(question.id),
    [questionsToHide],
  );

  useEffect(() => {
    let mounted = true;
    const getThemes = async () => {
      const result: {
        data: { surveyThemes: Record<string, PopulatedSurveyTheme>; total: number };
      } = await getSurveyThemes(
        {
          search: debouncedSearch,
          skip: pagination.skip,
          limit: pagination.limit,
          onlyWithQuestions: true,
        },
        ['questions'],
      );

      if (!mounted) {
        return;
      }

      setThemes(
        Object.values(result.data.surveyThemes).map((theme) => ({
          ...theme,
          questions: theme.questions.filter(questionsFilter),
          showQuestions: false,
        })),
      );
      setTotalCount(result.data.total);
    };

    getThemes();

    return () => {
      mounted = false;
    };
  }, [questionsFilter, debouncedSearch, pagination.skip, pagination.limit]);

  const questionIds: string[] = [];
  themes.forEach((theme) => {
    theme.questions.forEach((q) => {
      if (!questionsToHide.includes(q.id)) {
        questionIds.push(q.id);
      }
    });
  });

  const handleOnSubmit = async () => {
    const result = await importQuestions(surveyTemplateId, {
      position: index,
      questions: selectedQuestions,
    });
    if (result.status === 'success') {
      onSubmit();
    }
  };

  return (
    <StyledModal minWidth={0} borderRadius={6}>
      <Header>
        <div>
          <Title>
            <Trans>Import questions</Trans>
          </Title>
          <SubTitle>
            <Trans>Select the theme(s) you want to cover in this survey template.</Trans>
          </SubTitle>
        </div>
        <Button variant={ButtonVariant.CLOSE} size={ButtonSize.MEDIUM} onClick={onClose} />
      </Header>
      <Content>
        <SearchFieldWrapper
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.currentTarget.value)}
          value={search}
          placeholder={i18n._(t`Search...`)}
          style={{
            borderRadius: '10rem',
            fontSize: '14px',
            fontWeight: 600,
          }}
        />
        <Button
          label={i18n._(t`Create new questions`)}
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          onClick={(e) => {
            onClose();
            createNewQuestion(index);
            e.preventDefault();
          }}
        />
      </Content>
      <MultiLayerList
        columnName={i18n._(t`Themes & questions`)}
        selectedCounterLabel={i18n._(t`selected questions`)}
        counterLabel={i18n._(t`questions`)}
        toggles={{
          hideItems: i18n._(t`Hide all questions`),
          showItems: i18n._(t`Show all questions`),
        }}
        data={themes.map((theme) => ({
          id: theme.id,
          icon: (theme?.icon || 'Chatbubble') as IconNames,
          iconColor: theme?.iconColor || undefined,
          name: getMultiLangString(theme.name),
          showSubItems: theme.showQuestions,
          subItems: theme.questions?.map((question) => ({
            id: question.id,
            name: getMultiLangString(question.name),
            icon: ANSWER_TYPES[
              question.type as Exclude<SURVEY_QUESTION_TYPES, SURVEY_QUESTION_TYPES.DROPDOWN>
            ].icon,
          })),
        }))}
        selectedItems={selectedQuestions}
        setSelectedItems={setSelectedQuestions}
        paginationProps={{
          pagination,
          changePagination,
          totalCount,
        }}
      />
      <Footer>
        <Button
          label={i18n._(t`Close`)}
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          onClick={onClose}
        />
        <Button
          label={i18n._(t`Import ${selectedQuestions.length || ''} questions`)}
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          disabled={selectedQuestions.length <= 0}
          onClick={handleOnSubmit}
        />
      </Footer>
    </StyledModal>
  );
}

export { ImportQuestionsModal };
