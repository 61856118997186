import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { FillOutSurvey } from '~/components/FillOutSurvey';
import RocketIcon from '~/components/Icons/Rocket';
import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SurveyIntro } from '~/pages/SurveyTaskView/components/intro';
import { useFetchSurveyTask } from '~/pages/SurveyTaskView/hooks';

import routes from '~/constants/routes';
import { useFromQuery } from '~/hooks/useFromQuery';
import { checkModuleSurvey } from '~/selectors/baseGetters';

const SurveyTaskView = () => {
  const { i18n } = useLingui();
  const { goBack } = useFromQuery({ includeHash: true });
  const isModuleSurveyEnabled = useSelector(checkModuleSurvey);
  const history = useHistory();
  const { surveyTask, ratings, isLoading } = useFetchSurveyTask();
  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    if (!isModuleSurveyEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleSurveyEnabled, history]);

  useEffect(() => {
    setShowIntro(surveyTask?.isFirstTask ?? false);
  }, [surveyTask]);

  return (
    <>
      {isModuleSurveyEnabled && (
        // @ts-ignore
        <ShowSpinnerIfLoading height="49px" loading={isLoading}>
          {surveyTask && showIntro && (
            <SurveyIntro
              closeIntro={() => setShowIntro(false)}
              surveyTask={surveyTask}
              goBack={goBack}
            />
          )}
          {surveyTask && !showIntro && (
            <FillOutSurvey surveyTask={surveyTask} ratings={ratings} goBack={goBack} />
          )}
          {/* @ts-ignore */}
          {!surveyTask && <Placeholder title={i18n._(t`No survey task`)} Icon={RocketIcon} />}
        </ShowSpinnerIfLoading>
      )}
    </>
  );
};

export { SurveyTaskView };
