import React, { FunctionComponent } from 'react';

import { ROLES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Editor from '~/components/Editor';
import ImageUploadCover from '~/components/ImageUploadCover';
import Switch from '~/components/Switch';
import { FieldGroup, FieldTitle, TextField } from '~/components/Text';

import { getUser } from '~/selectors/baseGetters';
import * as currentPathActions from '~/store/currentPath/actions';
import { COLOR_PALETTE } from '~/styles';

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchLabel = styled.div`
  font-size: 14px;
  color: ${COLOR_PALETTE.BLUE_DARK};
  margin-left: 12px;
`;

interface IPathStepSetup {
  showErrors: boolean;
  item: any;
}

const PathStepSetup: FunctionComponent<IPathStepSetup> = ({ showErrors, item }) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  // settings role - the highest user role in company
  const settingsRole = user.isAdmin ? ROLES.ADMIN : user.isCoach ? ROLES.COACH : ROLES.USER;

  // 1. Settings visible only for Admin and coach
  // 2. If path was created by User -> Admin/Coach does not see settings
  const isSettingsVisible = item.createdInRole // on creation createdInRole is undefined
    ? [ROLES.ADMIN, ROLES.COACH].includes(item.createdInRole)
    : [ROLES.ADMIN, ROLES.COACH].includes(settingsRole);

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(currentPathActions.updateCurrentPath({ name: e.target.value }));

  const handleChangeDescription = (value: string) =>
    dispatch(currentPathActions.updateCurrentPath({ description: value }));

  const handleChangeImage = (image: string) =>
    dispatch(currentPathActions.updateCurrentPath({ image }));

  const handleChangeIsAvailable = (isAvailableInUserLibrary: boolean) =>
    dispatch(currentPathActions.updateCurrentPath({ isAvailableInUserLibrary }));

  return (
    <>
      <FieldGroup>
        <FieldTitle>
          <Trans>Name</Trans>*
        </FieldTitle>
        <TextField
          value={item.name || ''}
          onChange={handleChangeName}
          // @ts-ignore
          error={showErrors && !item.name}
          placeholder={i18n._(t`Example: Learning path from Jr to Mr consultant`)}
        />
      </FieldGroup>
      <FieldGroup>
        <FieldTitle>
          <Trans>Description</Trans>
        </FieldTitle>
        <Editor
          placeholder={i18n._(t`Example: explain the duration, purpose and contents of the path`)}
          value={item.description}
          onChange={handleChangeDescription}
          compact={true}
        />
      </FieldGroup>
      <FieldGroup>
        <FieldTitle>
          <Trans>Image</Trans>
        </FieldTitle>
        <ImageUploadCover
          imageUrl={item.image || ''}
          onUpload={handleChangeImage}
          placeholder={i18n._(t`Upload image (1920px wide and 1080px height)`)}
        />
      </FieldGroup>
      {isSettingsVisible && (
        <FieldGroup>
          <FieldTitle>
            <Trans>Settings</Trans>
          </FieldTitle>
          <Row>
            <Switch onChange={handleChangeIsAvailable} checked={item.isAvailableInUserLibrary} />
            <SwitchLabel>
              <Trans>
                Make learning path available for all users in library (if disabled only available to
                assign)
              </Trans>
            </SwitchLabel>
          </Row>
        </FieldGroup>
      )}
    </>
  );
};

export { PathStepSetup };
