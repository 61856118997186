import React, { useEffect, useState } from 'react';

import { ROLES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import ColoredRow from '~/components/ColoredRow';
import { confirm } from '~/components/ConfirmDialog';
import GoalDashboardCardModal from '~/components/GoalDashboardCardModal';
import IconMenu from '~/components/IconMenu';
import ImportGoalsModal from '~/components/ImportGoalsModal';
import AvatarGroup from '~/components/UI/AvatarGroup';

import QuestionCard from './QuestionCard';

import { GOAL_STYLES } from '~/constants/goals';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { getTeams, getUser } from '~/selectors/baseGetters';
import { deleteGoal, getGoals, updateGoalCycles } from '~/services/goals';
import { getUserTeams } from '~/services/teams';
import { COLOR_PALETTE } from '~/styles';
import { isUserCanEditGoal } from '~/utils/isUserCanEditGoal';

const ImportGoalButton = styled(Button)`
  width: 100%;
  height: 48px;
  font-weight: 600;
`;

const AddGoalButton = styled(ImportGoalButton)`
  margin-top: 16px;
  margin-bottom: 8px;
`;

const CreatedBy = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  color: ${COLOR_PALETTE.DARK_GRAY};
  display: flex;
  flex-direction: row;
  margin-right: 16px;
`;

const CreatedByLabel = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const RightCol = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-right: 24px;
`;

const StyledAvatarGroup = styled(AvatarGroup)`
  margin-left: 8px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
`;

const ReviewGoalPlanning = ({
  title,
  goalTitle,
  description,
  type,
  typeLabel,
  goalCycle,
  i18n,
  forUser,
  saveAsDraft,
  callBeforeRedirect,
  goalsToSave,
  isLearning,
  isCoach,
  review,
  indexCurrent,
  indexTotal,
  isOpenDefault,
  isNotSharedFeedback,
  isReadOnly,
}) => {
  const [goals, setGoals] = useState([]);
  const [isGoalInfoModal, setIsGoalInfoModal] = useState(false);
  const user = useSelector(getUser);
  const teams = useSelector(getTeams);
  const $showImportGoalModal = useBoolState(false);
  const [team, setTeam] = useState(null);
  const [activeGoal, setActiveGoal] = useState();
  // only existing coaches can create new goals
  const isAddGoalEnabled = isCoach ? !!team : true;

  useEffect(() => {
    const fetch = async () => {
      const fetchedGoals = await getGoals({
        types: [type],
        goalCycles: [goalCycle],
        forUser: forUser.id,
        isMyGoalsOnly: true,
      });

      setGoals(fetchedGoals?.data || []);
      goalsToSave && goalsToSave(fetchedGoals?.data || []);

      // search for coach team
      if (isCoach) {
        const teams = await getUserTeams(user.id, { isCoach: true });
        if (!isEmpty(teams)) {
          const team = Object.values(teams).find((t) => {
            const members = get(t, 'members', []);
            return members.includes(forUser.id);
          });

          if (team) {
            setTeam(team);
          }
        }
      }
    };
    fetch();

    // eslint-disable-next-line
  }, [isLearning, goalsToSave, forUser.id, goalCycle, type]);

  const addToGoalsArray = (newGoals) => {
    const tempGoals = [...goals, ...newGoals];
    setGoals(tempGoals);
    goalsToSave && goalsToSave(tempGoals);
  };

  const addGoal = async () => {
    const users = [forUser.id];
    if (saveAsDraft) {
      await saveAsDraft();
    }
    if (callBeforeRedirect) {
      await callBeforeRedirect();
    }

    routes.GOAL_CREATE.go(
      {
        role: ROLES.USER,
      },
      {
        type,
        isBackPath: true,
        noHash: true,
        isBackPathEncoded: true, // need this because we have 2 from in query, and isUpdateGoalModal is missing in back path
        query: {
          goalCycle,
          users,
          type,
          isHideNotSelectedTypes: true,
          isDeleteOwnersDisabled: true,
        },
      },
    );
  };

  const updateGoal = async (goal) => {
    if (saveAsDraft) {
      await saveAsDraft();
    }
    if (callBeforeRedirect) {
      await callBeforeRedirect();
    }

    routes.GOAL_UPDATE.go(
      {
        role: ROLES.USER,
      },
      {
        goalId: goal.id,
        users: [forUser.id],
        isBackPath: true,
      },
    );
  };

  const removeGoal = async (goal) => {
    const textConfirm = i18n._(
      t`Are you sure you want to remove this goal from this goal cycle? This will remove this goal from this review report but not remove the goal from your account`,
    );
    if (await confirm(i18n, textConfirm)) {
      const { goalCycles } = goal;
      let updatedGoalCycles = goalCycles
        .filter((cycle) => cycle.id !== goalCycle)
        .map((g) => g.id || g);
      let updatedGoals = goals.filter((g) => g.id !== goal.id);

      setGoals(updatedGoals);
      goalsToSave && goalsToSave(updatedGoals);
      // remove cycle from goal
      await updateGoalCycles(goal.id, { goalCycles: updatedGoalCycles });
    }
  };

  const deleteGoalCall = async (goal) => {
    const textConfirm = i18n._(
      t`Are you sure you want to delete this goal? This will delete this goal, its key results and all progress. This cannot be undone.`,
    );

    if (await confirm(i18n, textConfirm)) {
      const updatedGoals = goals.filter((g) => g.id !== goal.id);
      setGoals(updatedGoals);
      goalsToSave && goalsToSave(updatedGoals);
      await deleteGoal(goal.id);
    }
  };

  const renderRow = (goal) => {
    const goalStyles = GOAL_STYLES[goal.type];
    const isEditor = isUserCanEditGoal({ goal, user, teams });
    const menuItems =
      isEditor &&
      [
        isAddGoalEnabled && {
          label: i18n._(t`Update`),
          action: () => updateGoal(goal),
        },
        {
          label: i18n._(t`Remove from cycle`),
          action: async () => await removeGoal(goal),
        },
        isAddGoalEnabled && {
          label: i18n._(t`Delete goal`),
          action: async () => await deleteGoalCall(goal),
        },
      ].filter((i) => i);

    return (
      <ColoredRow
        key={goal.id}
        title={goal.name}
        rowIcon={goalStyles.icon}
        rowStartColor={goalStyles.color}
        rowColor={goalStyles.background}
        rowIconColor={goalStyles?.iconColor}
        rowIconBackground={goalStyles?.iconBackground}
        onClick={() => {
          setActiveGoal(goal.id);
          setIsGoalInfoModal(true);
        }}
        maxWidth={400}
      >
        <RightCol>
          {goal.creators?.includes(user.id) ? (
            <CreatedBy>
              <Trans>Created by you</Trans>
            </CreatedBy>
          ) : (
            <CreatedBy>
              <CreatedByLabel>
                <Trans>Created by</Trans>
              </CreatedByLabel>
              <StyledAvatarGroup users={goal.creators} showTooltip={false} size={28} type="small" />
            </CreatedBy>
          )}
          <IconMenu items={menuItems || []} disabled={isReadOnly} />
        </RightCol>
      </ColoredRow>
    );
  };

  return (
    <QuestionCard
      title={title}
      labels={[goalTitle]}
      isActive={false}
      hideFooter
      description={description}
      htmlDescription={true}
      isError={false}
      indexCurrent={indexCurrent}
      indexTotal={indexTotal}
      isOpenDefault={isOpenDefault}
    >
      {goals.map((goal) => renderRow(goal))}
      <AddGoalButton
        onClick={addGoal}
        label={i18n._(t`Create goal`)}
        type="primary"
        disabled={!isAddGoalEnabled || isReadOnly}
        tooltip={
          !isAddGoalEnabled ? i18n._(t`Only existing coach can create new ${typeLabel}`) : ''
        }
      />
      <ImportGoalButton
        onClick={$showImportGoalModal.on}
        label={i18n._(t`Import goal`)}
        type="primary-border"
        disabled={isReadOnly}
      />
      {$showImportGoalModal.value && (
        <ImportGoalsModal
          user={forUser}
          onClose={$showImportGoalModal.off}
          i18n={i18n}
          goalCycle={goalCycle}
          preSelectedGoals={goals}
          addToGoalsArray={addToGoalsArray}
          type={type}
          isLearning={isLearning}
          review={review}
        />
      )}
      {isGoalInfoModal && (
        <GoalDashboardCardModal
          onClose={() => {
            setIsGoalInfoModal(false);
            setActiveGoal(null);
          }}
          goalId={activeGoal}
          reviewId={review.id}
          isNotSharedFeedback={isNotSharedFeedback}
        />
      )}
    </QuestionCard>
  );
};

export default ReviewGoalPlanning;
