import styled from 'styled-components';

import Shadow from '~/components/UI/Shadow';

import { COLOR_PALETTE } from '~/styles';

const BoxWithShadow = styled(Shadow)`
  background-color: ${COLOR_PALETTE.WHITE};
  box-sizing: border-box;
  ${(props) => (props.$noShadow || props.$noBorder) && 'border: none;'}
  ${(props) => props.$noShadow && 'box-shadow: none;'}
  ${(props) => props.$noShadow && 'border-radius: none;'}
  ${(props) => props.$noBorderBottom && 'border-bottom: none;'}
  ${(props) => props.$noBorderTop && 'border-top: none;'}
  ${(props) => props.$noRadius && 'border-radius: 0;'}
`;

export default BoxWithShadow;
