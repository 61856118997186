import { LEARNING_LIBRARY_SORT_FILTER_OPTIONS } from '@learned/constants';
import { t } from '@lingui/macro';

export const LEARNING_LIBRARY_SORT_BY = {
  LAST_UPDATED: {
    id: LEARNING_LIBRARY_SORT_FILTER_OPTIONS.LAST_UPDATED,
    key: LEARNING_LIBRARY_SORT_FILTER_OPTIONS.LAST_UPDATED,
    name: 'Last updated',
    translated: (i18n: any) => i18n._(t`Last updated`),
  },
  A_Z: {
    id: LEARNING_LIBRARY_SORT_FILTER_OPTIONS.A_Z,
    key: LEARNING_LIBRARY_SORT_FILTER_OPTIONS.A_Z,
    name: 'A-Z Alphabetic',
    translated: (i18n: any) => i18n._(t`A-Z Alphabetic`),
  },
  Z_A: {
    id: LEARNING_LIBRARY_SORT_FILTER_OPTIONS.Z_A,
    key: LEARNING_LIBRARY_SORT_FILTER_OPTIONS.Z_A,
    name: 'Z-A Alphabetic',
    translated: (i18n: any) => i18n._(t`Z-A Alphabetic`),
  },
  NEW_OLD: {
    id: LEARNING_LIBRARY_SORT_FILTER_OPTIONS.NEW_OLD,
    key: LEARNING_LIBRARY_SORT_FILTER_OPTIONS.NEW_OLD,
    name: 'Created new - old',
    translated: (i18n: any) => i18n._(t`Created new - old`),
  },
  OLD_NEW: {
    id: LEARNING_LIBRARY_SORT_FILTER_OPTIONS.OLD_NEW,
    key: LEARNING_LIBRARY_SORT_FILTER_OPTIONS.OLD_NEW,
    name: 'Created old - New',
    translated: (i18n: any) => i18n._(t`Created old - New`),
  },
};
