import React from 'react';

import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { I18nProvider } from '@lingui/react';
import { confirmable, createConfirmation } from 'react-confirm';
import styled from 'styled-components';

import Button from '~/components/Button';
import Dialog from '~/components/Dialog';
import ExternalInstructions from '~/components/UI/ExternalInstructions';

import { COLOR_PALETTE } from '~/styles';

const ModalWrap = styled.div`
  padding: 24px;
`;

const Title = styled.h5`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  text-align: center;
  color: ${COLOR_PALETTE.BLACK};
  margin: 0;
`;

const Description = styled.div`
  margin: 24px 0px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;

  & button {
    min-width: 83px;
    padding: 0 16px;
    margin: 0 6px;

    & > div {
      display: inline-block;
    }
  }
`;

const ConfirmDialog = confirmable(
  ({ proceed, confirmation, title, confirmText, cancelText, isNoCancelButton, link }) => {
    // <I18nProvider i18n={i18n}> need for correct work of buttons
    return (
      <I18nProvider i18n={i18n}>
        <Dialog width="500px" minWidth="500px" centerModal>
          <ModalWrap>
            <Title>{title || t`Confirm`}</Title>
            <Description>{confirmation}</Description>
            {link && (
              <ExternalInstructions instructionsUrl={link} instructions={i18n._(t`Read more`)} />
            )}
            <Footer>
              {!isNoCancelButton && (
                <Button
                  type="primary-border"
                  label={cancelText || t`Cancel`}
                  onClick={() => proceed(false)}
                />
              )}
              <Button type="primary" label={confirmText || t`OK`} onClick={() => proceed(true)} />
            </Footer>
          </ModalWrap>
        </Dialog>
      </I18nProvider>
    );
  },
);

export default ConfirmDialog;

/**
 * @deprecated since version 3.0
 */
const confirm = (_i18n, confirmation, options = {}) =>
  createConfirmation(ConfirmDialog, 0)({ confirmation, ...options });

export { confirm };
