import { createReducer } from 'redux-act';

import * as AppActions from './actions';

const INITIAL_STATE = {
  isFieldsValidated: true,
  isRequestError: false,
  requestErrorCode: null,
  isErrorInToast: false,
  error: {},
  search: '',
  filters: {},
  isAssignedActivities: false,
  isAssignedUserToPath: false,
  isRightSideBarOpen: false,
  isLogout: false,
  maintenanceTitle: '',
  maintenanceBody: '',
};

export const app = createReducer(
  {
    [AppActions.finishSetApp]: (_state, payload) => {
      return payload;
    },

    [AppActions.finishUpdateApp]: (state, payload) => {
      return {
        ...state,
        ...payload,
      };
    },

    [AppActions.setIsRequestError]: (state, payload) => {
      const code = payload.value ? payload.code || null : null;
      return {
        ...state,
        isRequestError: payload.value,
        requestErrorCode: code,
        error: payload.error || {},
      };
    },
    [AppActions.setIsErrorInToast]: (state, payload = { value: false, error: {} }) => {
      return {
        ...state,
        isErrorInToast: payload.value,
        error: payload.error,
      };
    },
  },
  INITIAL_STATE,
);
