import React, { useRef, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { confirm } from '~/components/ConfirmDialog';
import DropdownButton from '~/components/DropdownButton';
import IconButton from '~/components/IconButton';
import TrashIcon from '~/components/Icons/Delete';
import SvgIcon from '~/components/SvgIcon';

import { LanguageSelectModal } from './LanguageSelectModal';

import DropdownArrow from '~/assets/ic-keyboard-arrow-down.svg';

import { INSTRUCTIONS } from '~/constants/instructions';
import { ILanguage, languages as SystemLanguages } from '~/constants/languages';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import {
  addCompanyLanguages,
  addCompanyPrimaryLanguage,
  removeCompanyLanguage,
  setCompanyPrimaryLanguage,
} from '~/store/companies/actions';
import { COLOR_PALETTE, COLOR_SET } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';

const Wrapper = styled.div`
  background-color: white;
  width: calc(100%-52px);
  margin-bottom: 32px;
  padding: 26px;
  border-radius: 6px;
  box-shadow: 0 8px 15px 0 rgb(149 157 165 / 20%);
  border: solid 1px ${COLOR_PALETTE.GRAY_SEMI_SOFT};
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  color: #3b3b3b;
  margin-bottom: 24px;
`;

const ContentBox = styled.div`
  padding: 4px 12px;
  border-radius: 6px;
  background-color: #f6f8fc;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LanguageWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  flex-wrap: wrap;
`;

const Button = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  margin-right: 16px;
  align-self: flex-start;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--company-color);
  cursor: pointer;
`;

const ContentTitle = styled.div`
  margin: 6px 0;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  flex: 1;
`;

const AvailableLanguagesTitle = styled.div`
  margin: 6px 0;
  font-family: Poppins;
  color: #000000;
  flex: 1;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  margin: 24px 0 4px 0;
`;

const StyledDropdownButton = styled(DropdownButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Items = styled.div`
  width: 182px;
`;

const Item = styled.div`
  display: flex;
  width: 182px;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  padding: 0 10px 0 14px;
  border-bottom: solid 1px #f3f2ef;
  cursor: pointer;
`;

const ItemName = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_SET.BLACK};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 8px 16px;
  align-self: center;
  background-color: white;
  justify-content: center;
  min-width: 150px;
  border-radius: 6px;
  border: solid 1px ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  margin: 0 4px 4px 4px;
`;

const SelectedLang = styled.div<{ isPrimaryLang?: boolean }>`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => (props.isPrimaryLang ? '#dcdcdc' : COLOR_SET.BLACK)};
  flex: 1;
`;

const CompanyLanguage = () => {
  const { i18n } = useLingui();
  const popoverRef = useRef<any>();
  const company = useSelector(getCurrentCompany);
  const dispatch = useDispatch();
  const [primaryLang, setPrimaryLang] = useState<ILanguage>(
    company?.primaryLang || {
      language: 'Dutch',
      country: 'Netherlands',
      locale: 'nl_NL',
    },
  );
  const [languages, setLanguages] = useState<ILanguage[]>(
    company?.languages?.sort((a: ILanguage, b: ILanguage) => {
      if (a.locale === primaryLang.locale) {
        return -1;
      }
      if (b.locale === primaryLang.locale) {
        return 1;
      }
      return a.language.toLowerCase().localeCompare(b.language.toLowerCase());
    }) || [primaryLang],
  );
  const [isLanguageSelectModalActive, setIsLanguageSelectModalActive] = useState<boolean>(false);
  const [singleSelect, setSingleSelect] = useState<boolean>(false);

  const updatePrimaryLang = async (lang: ILanguage) => {
    if (
      await confirm(i18n, i18n._(t`You're about to change the company primary language`), {
        confirmText: i18n._(t`I understand`),
      })
    ) {
      dispatch(setCompanyPrimaryLanguage(lang));
      setPrimaryLang(lang);
    }
  };

  const renderMenu = () => {
    return (
      <Items>
        {languages.map((lang: ILanguage) => {
          return (
            <Item
              onClick={() => {
                updatePrimaryLang(lang);
                popoverRef?.current?._tippy?.hide();
              }}
              key={lang.locale}
            >
              <ItemName>{lang.language}</ItemName>
            </Item>
          );
        })}
        <Item
          onClick={() => {
            setIsLanguageSelectModalActive(true);
            setSingleSelect(true);
            popoverRef?.current?._tippy?.hide();
          }}
        >
          <ItemName>{i18n._(t`Other`)}</ItemName>
        </Item>
      </Items>
    );
  };

  const renderButton = () => {
    return (
      <Row>
        <SelectedLang>
          {`${getUnicodeFlagIcon(
            primaryLang.locale.substring(primaryLang.locale.indexOf('_') + 1),
          )} ${primaryLang.language}`}
        </SelectedLang>
        <SvgIcon
          url={DropdownArrow}
          width="16px"
          height="13px"
          isDefaultColor
          defaultColor="var(--company-color)"
        />
      </Row>
    );
  };

  const removeLanguage = async (lang: ILanguage) => {
    if (
      await confirm(
        i18n,
        i18n._(
          t`Are you sure you want to delete this language as an input language. The language will no longer be available for members`,
        ),
        {
          link: getInstructionUrl(
            INSTRUCTIONS.HOW_TO_SETUP_MULTIPLE_LANGUAGES_WITHIN_COMPANY_ACCOUNT,
          ),
        },
      )
    ) {
      dispatch(removeCompanyLanguage(lang));
      setLanguages(languages.filter((i) => i.locale !== lang.locale));
    }
  };

  const addPrimaryLanguage = async (lang: ILanguage) => {
    if (
      await confirm(i18n, i18n._(t`You're about to change the company primary language`), {
        confirmText: i18n._(t`I understand`),
      })
    ) {
      dispatch(addCompanyPrimaryLanguage(lang));
      setLanguages([...languages, lang]);
      setPrimaryLang(lang);
    }
  };

  const addLanguages = (langs: ILanguage[]) => {
    dispatch(addCompanyLanguages(langs));
    setLanguages([...languages, ...langs]);
  };

  return (
    <Wrapper>
      <Title>{i18n._(t`Languages`)}</Title>
      <ContentBox>
        <ContentTitle>{i18n._(t`Default language`)}</ContentTitle>
        {/* @ts-ignore */}
        <StyledDropdownButton popoverRef={popoverRef} content={renderMenu()}>
          {renderButton()}
        </StyledDropdownButton>
      </ContentBox>
      <AvailableLanguagesTitle>
        {i18n._(t`Languages available for translations`)}
      </AvailableLanguagesTitle>
      <ContentBox>
        <LanguageWrapper>
          <Row>
            <SelectedLang isPrimaryLang>
              {`${getUnicodeFlagIcon(
                primaryLang.locale.substring(primaryLang.locale.indexOf('_') + 1),
              )} ${primaryLang.language} (${i18n._(t`default`)})`}
            </SelectedLang>
          </Row>
          {languages.map((lang: ILanguage) => {
            return (
              lang.locale !== primaryLang.locale && (
                <Row key={lang.locale}>
                  <SelectedLang>{`${getUnicodeFlagIcon(
                    lang.locale.substring(primaryLang.locale.indexOf('_') + 1),
                  )} ${lang.language}`}</SelectedLang>
                  {/* @ts-ignore */}
                  <IconButton
                    onClick={() => {
                      removeLanguage(lang);
                    }}
                    size={14}
                    noBorder
                  >
                    <TrashIcon onClick={undefined} fill="var(--company-color)" size={14} />
                  </IconButton>
                </Row>
              )
            );
          })}
        </LanguageWrapper>
        <Button
          onClick={() => {
            setIsLanguageSelectModalActive(true);
            setSingleSelect(false);
          }}
        >
          {i18n._(t`+ Add language`)}
        </Button>
      </ContentBox>
      {isLanguageSelectModalActive && (
        <LanguageSelectModal
          onClose={() => {
            setIsLanguageSelectModalActive(false);
            setSingleSelect(false);
          }}
          onSave={
            singleSelect
              ? // eslint-disable-next-line
                (addPrimaryLanguage as (arg0: ILanguage) => void)
              : // eslint-disable-next-line
                (addLanguages as (arg0: ILanguage[]) => void)
          }
          singleSelect={singleSelect}
          languages={SystemLanguages.filter(
            (lang) => !languages.find((i) => lang.locale === i.locale),
          )}
        />
      )}
    </Wrapper>
  );
};

export { CompanyLanguage };
