import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from '~/components/Button';
import OverviewHeading from '~/components/OverviewHeading';
import SelectUsersModal from '~/components/SelectUsersModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import TeamOverviewBoard from '~/components/TeamOverviewBoard';
import BaseLayout from '~/layouts/BaseLayout';

import routes from '~/constants/routes';
import {
  getTeam,
  removeCoachFromTeam,
  removeMemberFromTeam,
  addCoaches,
  addMembersToTeam,
} from '~/store/teams/actions';

const flexStyles = { flex: '1' };

class TeamOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isShowAddCoachesModal: false,
      isShowAddMembersModal: false,
    };
  }

  componentDidMount = async () => {
    const { match, history, dispatch } = this.props;
    const id = match.params.teamId;
    try {
      await dispatch(getTeam(id));
      this.setState({ loading: false });
    } catch (e) {
      history.push(routes.TEAMS.build());
    }
  };

  removeCoachFromTeam = async (selectedUser) => {
    const { dispatch, team } = this.props;

    // Remove coach from team
    await dispatch(removeCoachFromTeam(team.id, selectedUser.id ? selectedUser.id : selectedUser));
  };

  removeMemberFromTeam = async (selectedUser) => {
    const { dispatch, team } = this.props;

    const selectedUserId = selectedUser.id ? selectedUser.id : selectedUser;
    await dispatch(removeMemberFromTeam(team.id, selectedUserId));
  };

  showAddCoachesModal = () => {
    this.setState({
      isShowAddCoachesModal: true,
    });
  };

  hideAddCoachesModal = () => {
    this.setState({
      isShowAddCoachesModal: false,
    });
  };

  showAddMembersModal = () => {
    this.setState({
      isShowAddMembersModal: true,
    });
  };

  hideAddMembersModal = () => {
    this.setState({
      isShowAddMembersModal: false,
    });
  };

  addCoaches = async (selectedCoaches) => {
    const { team, dispatch } = this.props;

    this.setState({ loading: true });
    await dispatch(
      addCoaches(
        team.id,
        selectedCoaches.map((coach) => coach.id),
      ),
    );
    this.setState({ loading: false });

    this.hideAddCoachesModal();
  };

  addMembers = async (selectedMembers) => {
    const { team, dispatch } = this.props;

    this.setState({ loading: true });
    await dispatch(
      addMembersToTeam(
        team.id,
        selectedMembers.map((u) => u.id),
      ),
    );
    this.setState({ loading: false });

    this.hideAddMembersModal();
  };

  render() {
    const { i18n, users, team } = this.props;
    const { loading, isShowAddCoachesModal, isShowAddMembersModal } = this.state;

    const backPath = routes.TEAMS.build();

    let usersForAddingToTeam = [];
    if (!isEmpty(team)) {
      usersForAddingToTeam = Object.values(users).filter(
        (u) =>
          (!team.coaches || !team.coaches.includes(u.id)) &&
          (!team.members || !team.members.includes(u.id)),
      );
    }

    return (
      <>
        <ShowSpinnerIfLoading loading={loading} styles={flexStyles}>
          <OverviewHeading title={team && team.name} backPath={backPath}>
            <Button
              label={i18n._(t`Invite coach`)}
              loading={loading}
              type="primary-border"
              onClick={this.showAddCoachesModal}
            />
            <Button
              label={i18n._(t`Invite member`)}
              loading={loading}
              onClick={this.showAddMembersModal}
            />
          </OverviewHeading>
          <BaseLayout>
            <div>
              <TeamOverviewBoard
                team={team}
                removeCoachFromTeam={this.removeCoachFromTeam}
                removeMemberFromTeam={this.removeMemberFromTeam}
              />
            </div>
            {isShowAddCoachesModal && (
              <SelectUsersModal
                items={usersForAddingToTeam}
                onModalClose={this.hideAddCoachesModal}
                onSubmit={this.addCoaches}
                loading={loading}
                title={i18n._(t`Add coach to team`)}
              />
            )}
            {isShowAddMembersModal && (
              <SelectUsersModal
                items={usersForAddingToTeam}
                onModalClose={this.hideAddMembersModal}
                onSubmit={this.addMembers}
                loading={loading}
                title={i18n._(t`Add member to team`)}
              />
            )}
          </BaseLayout>
        </ShowSpinnerIfLoading>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    users: state.users.data,
    team: state.teams.data[ownProps.match.params.teamId] || {},
  };
};

export default withI18n()(withRouter(connect(mapStateToProps)(TeamOverview)));
