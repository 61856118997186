import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import size from 'lodash/size';
import styled from 'styled-components';

import RickTextView from '~/components/RickTextView';

import { StepSection } from './StepSection';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { COLORS } from '~/styles';

import type { IMultiLangString } from '@learned/types';

const Wrapper = styled.div``;

const Block = styled.div`
  margin-bottom: 16px;
`;

const Name = styled.div`
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  color: ${COLORS.BLACK};
  margin-bottom: 16px;
`;

const Description = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.BLACK};
`;

interface ISectionNameAndDescription {
  name: IMultiLangString;
  description: IMultiLangString;
  languageState: ILanguageStateReturn;
}

const SectionNameAndDescription = ({
  languageState,
  name,
  description,
}: ISectionNameAndDescription) => {
  const { i18n } = useLingui();

  const renderBlock = (field: { locale: string }) => {
    const nameLocale = name[field.locale];
    const descriptionLocale = description[field.locale];
    const icon =
      size(languageState.languages) > 1
        ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
        : undefined;
    return (
      <Block key={field.locale}>
        <Name>
          {nameLocale}
          {nameLocale && icon}
        </Name>
        <Description>
          {/* @ts-ignore*/}
          <RickTextView html={descriptionLocale} />
          {descriptionLocale && icon}
        </Description>
      </Block>
    );
  };

  return (
    <StepSection title={i18n._(t`Name and description`)}>
      <Wrapper>{languageState.languages.map((language) => renderBlock(language))}</Wrapper>
    </StepSection>
  );
};

export { SectionNameAndDescription };
