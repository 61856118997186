import FileSaver from 'file-saver';

import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

/**
 * @param options
 * @param [filters.search]
 * @param [filters.published]
 * @param [filters.status]
 * @param [options.limit]
 * @param [options.projection]
 * @param {string[]} populate
 * @param {string[]} [fields]
 * @returns {Promise<AxiosResponse<*>>}
 */
export function getJobProfiles(filters = {}, options = {}, populate = [], fields = []) {
  return cloudRequest(
    serverRoutes.jobProfiles.getJobProfiles,
    {},
    {
      filters,
      populate,
      fields,
      options,
    },
  );
}

export function downloadJobProfilesCSV(filters = {}, options = {}) {
  return cloudRequest(
    serverRoutes.jobProfiles.downloadJobProfilesCSV,
    {},
    {
      ...filters,
      ...options,
    },
  ).then((response) => {
    FileSaver.saveAs(response, 'jobprofiles.csv');
  });
}

export function updateJobProfile(id, data) {
  return cloudRequest(serverRoutes.jobProfiles.updateJobProfile(id), {}, data);
}

export function createJobProfiles(jobProfiles) {
  return cloudRequest(serverRoutes.jobProfiles.createJobProfiles, {}, jobProfiles);
}

export function updateJobProfile_(id, data) {
  return cloudRequest(serverRoutes.jobProfiles.updateJobProfile_, { id }, data);
}

export function copyJobProfile(jobProfileId) {
  return cloudRequest(serverRoutes.jobProfiles.copyJobProfile(jobProfileId));
}

export function getJobProfile(id, populate = []) {
  return cloudRequest(serverRoutes.jobProfiles.getJobProfile, { id, populate });
}

/*
 * jobProfileIds array of ids
 * for instance:
 * ['jobProfile1Id', 'jobProfile2Id', ...]
 * */
export function deleteJobProfiles(jobProfileIds) {
  return cloudRequest(serverRoutes.jobProfiles.deleteJobProfiles, {}, jobProfileIds);
}

/*
 * data example [[JobProfile1Id, updateData1{}], [JobProfile2Id, updateData2{}], ...]
 * */
export function updateJobProfiles(data) {
  return cloudRequest(serverRoutes.jobProfiles.updateJobProfiles, {}, data);
}

export function getJobProfileRelevanciesProgress(jobProfileId, userId) {
  return cloudRequest(
    serverRoutes.jobProfiles.getJobProfileRelevanciesProgress(jobProfileId, userId),
  );
}

export function deleteJobProfileCoverImage(jobProfileId) {
  return cloudRequest(serverRoutes.jobProfiles.deleteJobProfileCoverImage(jobProfileId));
}

/*
  Generate a Job Profile data
  body: { name: string; locales: Locals_all[] }
 */
export function autoCompleteJobProfile(body) {
  return cloudRequest(serverRoutes.jobProfiles.autoCompleteJobProfile, {}, body);
}

/*
  Generate Skills data for a certain category
  categoryId: string
  body: { name: string; locales: Locals_all[], existingSkillNames: string[] }
 */
export function generateSkillsForJobProfileCategory(categoryId, body) {
  return cloudRequest(
    serverRoutes.jobProfiles.generateSkillsForJobProfileCategory(categoryId),
    {},
    body,
  );
}
