import React from 'react';

import styled from 'styled-components';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  padding: 35px 42px 34px 34px;
  border-radius: 6px;
  background-color: ${COLORS.WHITE};
  margin-bottom: 43px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_HOVER};
  margin-bottom: 20px;
`;

interface ISectionProps {
  title: string;
  children: any;
}
const Section = ({ title, children }: ISectionProps) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {children}
    </Wrapper>
  );
};

export { Section };
