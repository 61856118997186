import React from 'react';

import { GOAL_STATUSES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import Tooltip from '~/components/Tooltip';

import { Placeholder } from './MemberRow';

import routes from '~/constants/routes';
import { COLOR_PALETTE, COLOR_SET } from '~/styles';

const Wrapper = styled.div`
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const ProgressLine = styled.div`
  width: 112px;
  height: 8px;
  border-radius: 4px;
  display: flex;
`;

const ProgressSector = styled.div`
  width: ${(props) => props.$width};
  background: ${(props) => props.$color};
  height: 100%;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const TotalProgress = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin-left: 12px;
`;

const TotalAmount = styled(TotalProgress)`
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const LastUpdate = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #454c66;
`;

const TooltipRow = styled.div`
  display: flex;
  align-items: center;
`;

const TooltipCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.$color};
`;

const TooltipLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #fff;
  margin-left: 8px;
`;

const GoalsColumn = ({ userId, data }) => {
  const { i18n } = useLingui();
  const history = useHistory();

  const lastUpdateDays =
    data?.lastUpdate &&
    moment().startOf('day').diff(moment(data.lastUpdate).startOf('day'), 'days');
  const path = routes.USER_PUBLIC_SKILL_PASSPORT.build(
    {},
    { userId, isBackPath: true, hash: 'goals' },
  );

  const getStatusValue = (status) => get(data, `perStatus[${status}]`, 0);

  // This component no longer in use, so removed the logic related to on_track status
  const items = [
    {
      value: getStatusValue(GOAL_STATUSES.PROBLEM),
      color: COLOR_SET.ORANGE2,
      label: (i18n) => i18n._(t`${getStatusValue(GOAL_STATUSES.PROBLEM)} problem`),
    },
    {
      value: getStatusValue(GOAL_STATUSES.COMPLETED),
      color: COLOR_SET.CYAN_GREEN,
      label: (i18n) => i18n._(t`${getStatusValue(GOAL_STATUSES.COMPLETED)} completed`),
    },
  ];

  const renderProgress = () => (
    <ProgressLine>
      {items.map((item, key) => {
        const percent = (item.value * 100) / data.total;
        return !!percent && <ProgressSector key={key} $width={`${percent}%`} $color={item.color} />;
      })}
    </ProgressLine>
  );

  return (
    <Wrapper onClick={() => history.push(path)}>
      {data?.total ? (
        <>
          <Row>
            <Tooltip
              maxWidth="140px"
              tooltip={
                <>
                  {items.map((item, key) => (
                    <TooltipRow key={key}>
                      <TooltipCircle $color={item.color} />
                      <TooltipLabel>{item.label(i18n)}</TooltipLabel>
                    </TooltipRow>
                  ))}
                </>
              }
            >
              {renderProgress()}
            </Tooltip>
            <TotalAmount>{data.total}</TotalAmount>
            <TotalProgress>{`${data.totalProgress}%`}</TotalProgress>
          </Row>
          {!isNull(lastUpdateDays) && (
            <Row>
              <LastUpdate>
                {lastUpdateDays
                  ? i18n._(t`Last update: ${lastUpdateDays}d ago`)
                  : i18n._(t`Last update: today`)}
              </LastUpdate>
            </Row>
          )}
        </>
      ) : (
        <Placeholder>{i18n._(t`No goal(s)`)}</Placeholder>
      )}
    </Wrapper>
  );
};

GoalsColumn.propTypes = {
  data: PropTypes.shape({
    total: PropTypes.number,
    totalProgress: PropTypes.number,
    lastUpdate: PropTypes.string,
    perStatus: PropTypes.shape({
      onTrack: PropTypes.number,
      completed: PropTypes.number,
      problem: PropTypes.number,
    }),
  }),
};

export default React.memo(GoalsColumn);
