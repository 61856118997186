import React from 'react';

import { TASK_STATUS } from '@learned/constants';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';

import { TaskDeadline } from './TaskDeadline';
import { TaskStatusLabel } from './TaskStatusLabel';

import type { ITask } from '@learned/types';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface ITaskStatusProps {
  task: ITask;
  onDelete?: () => void;
  isDeadlineView?: boolean;
}

const TaskStatus = ({ task, onDelete, isDeadlineView = false }: ITaskStatusProps) => {
  const taskStatusView = isDeadlineView ? (
    [TASK_STATUS.COMPLETED, TASK_STATUS.DECLINED, TASK_STATUS.EXPIRED].includes(task.status) ? (
      <TaskStatusLabel status={task.status} />
    ) : (
      <TaskDeadline task={task} />
    )
  ) : (
    <TaskStatusLabel status={task.status} />
  );

  return (
    <Wrapper>
      {taskStatusView}

      {onDelete && (
        <Button
          label=""
          size={ButtonSize.MEDIUM}
          variant={ButtonVariant.ICON_DELETE}
          isLoading={false}
          onClick={onDelete}
        />
      )}
    </Wrapper>
  );
};

export { TaskStatus };
