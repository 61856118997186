import React from 'react';

import { DraggableItem } from './DraggableItem';

import type { Item } from './types';

type Props = {
  items: Array<Item>;
  isDragAreaEverywhere: boolean;
  isDragDisabled: boolean;
};

const List = ({ items, isDragAreaEverywhere, isDragDisabled }: Props) => {
  return (
    <>
      {items.map((item, index) => (
        <DraggableItem
          item={item}
          index={index}
          key={item.id}
          isDragAreaEverywhere={isDragAreaEverywhere}
          isDragDisabled={isDragDisabled}
        />
      ))}
    </>
  );
};

export { List };
