import { serverRoutes } from '~/server_config';

import cloudRequest from '../cloudRequest';

export function getNotifications(filters = {}) {
  const params = {
    ...filters,
  };
  return cloudRequest(serverRoutes.notifications.getNotifications, params);
}

export function readNotification(id) {
  return cloudRequest(serverRoutes.notifications.readNotification(id));
}

export function readAllNotifications() {
  return cloudRequest(serverRoutes.notifications.readAllNotifications);
}

export function deleteNotification(id) {
  return cloudRequest(serverRoutes.notifications.deleteNotification(id));
}

export function sendUserPathReminder(pathId) {
  return cloudRequest(serverRoutes.notifications.sendUserPathReminder, {}, { pathId });
}

export function sendReviewFeedbackReminder(reviewId, flags) {
  return cloudRequest(
    serverRoutes.notifications.sendReviewFeedbackReminder,
    {},
    { reviewId, ...flags },
  );
}

export function sendConversationReminder(conversationId, type) {
  return cloudRequest(
    serverRoutes.notifications.sendConversationReminder,
    {},
    { conversationId, type },
  );
}
