import React, { useEffect, useMemo } from 'react';

import { Trans } from '@lingui/macro';
import { useFieldArray, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';

import Radio from '~/components/Radio';

import { Comment } from './Comment';

import type { IQuestionForm } from '~/@types/question';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';
import { getMultiLangString as getMultiLangStringOriginal } from '~/utils/getMultiLangString';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import type { ICompany, IConnection, ILanguage, ISurveyRating } from '@learned/types';

interface IQuestionViewSingleProps {
  defaultValues: IQuestionForm;
  languageState: ILanguageStateReturn;
  isPreselectedLang?: boolean;
  error?: boolean;
  commentError?: boolean;
  isPreferredLanguage?: boolean;
  onChange?: (data: {
    answer?: ISurveyRating['answer'];
    comment?: ISurveyRating['comment'];
  }) => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;

  & .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const Title = styled.div`
  font-size: 26px;
  color: ${COLORS.BLACK};
  text-align: center;
  margin-bottom: 48px;
`;

const AnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Option = styled.button<{ isSelected?: boolean; error?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 10px 14px;
  gap: 7px;
  background: rgba(255, 255, 255, 0.3);
  width: 250px;
  min-height: 40px;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected
      ? css`
          color: var(--company-color);
          border-color: var(--company-color);
          background: ${COLORS.WHITE};
        `
      : css`
          &:hover {
            background: ${COLORS.HOVER};
          }
          color: ${COLORS.BLACK};
        `};

  ${(props) =>
    props.error
      ? css`
          border-color: ${COLORS.ERROR};
        `
      : css``}
  & span {
    text-align: left;
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  color: ${COLORS.MIDDLE_GRAY};
  padding-bottom: 6px;
`;

const QuestionViewSingle = ({
  languageState,
  defaultValues,
  isPreselectedLang = false,
  onChange,
  error,
  commentError,
  isPreferredLanguage = false,
}: IQuestionViewSingleProps) => {
  const formMethods = useForm<IQuestionForm>({ defaultValues });
  const { reset, control, watch } = formMethods;
  const nameWatch = watch('name');
  const { fields, update } = useFieldArray({ name: 'options', control });
  const { companyPrimaryLanguage, languages } = languageState;
  const getMultiLangString = useMultiLangString();

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const locale = isPreselectedLang ? languages[0].locale : companyPrimaryLanguage.locale;

  const questionTitle = useMemo(() => {
    const multiLang = turnArrayIntoMultiLang(nameWatch);
    if (isPreferredLanguage) {
      return getMultiLangString(multiLang);
    } else {
      return getMultiLangStringOriginal(
        multiLang,
        { preferredLang: { locale } as ILanguage } as IConnection,
        {
          primaryLang: companyPrimaryLanguage,
          languages,
        } as ICompany,
      );
    }
  }, [
    isPreferredLanguage,
    getMultiLangString,
    nameWatch,
    locale,
    companyPrimaryLanguage,
    languages,
  ]);

  const selectOption = (index: number) => {
    fields.map((item, i) => {
      update(i, { ...item, value: i === index });
    });
    onChange?.({ answer: index });
  };

  return (
    <Wrapper>
      <Title>{questionTitle}</Title>
      <AnswerWrapper>
        <Label>
          <Trans>Select one answer</Trans>
        </Label>
        <Options>
          {fields.map(({ id, label, value }, index) => {
            const multiLangLabel = turnArrayIntoMultiLang(label);
            const translatedLabel = isPreferredLanguage
              ? getMultiLangString(multiLangLabel)
              : getMultiLangStringOriginal(
                  multiLangLabel,
                  { preferredLang: { locale } as ILanguage } as IConnection,
                  {
                    primaryLang: companyPrimaryLanguage,
                    languages,
                  } as ICompany,
                );

            return (
              <Option
                error={error}
                type="button"
                isSelected={value}
                key={id}
                onClick={() => selectOption(index)}
              >
                <Radio checked={!!value} size={20} />
                <span>{translatedLabel}</span>
              </Option>
            );
          })}
        </Options>
        <Comment error={commentError} formMethods={formMethods} onChange={onChange} />
      </AnswerWrapper>
    </Wrapper>
  );
};

export { QuestionViewSingle };
