import React, { useContext, useRef, useState, useEffect } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import AutocompleteDropdown from '~/components/AutocompleteDropdown';
import SvgIcon from '~/components/SvgIcon';
import { FieldGroup, FieldTitle } from '~/components/Text';
import { ConversationSetupContext } from '~/pages/ConversationSetup';

import starIcon from '~/assets/main-menu-icons/star.svg';

import { CONVERSATION_STATUSES } from '~/constants';
import { getId, getStatus } from '~/selectors/currentConversation';
import * as conversationRequests from '~/services/conversationTemplates';
import {
  addTalkingPoints,
  removeTalkingPoints,
  setDescription,
  setName,
} from '~/store/currentConversation/actions';
import { COLOR_PALETTE, COLORS } from '~/styles';

const DropdownContainer = styled.div`
  width: 312px;
`;

const NoReviewTemplateWrapper = styled.div`
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px -1px rgba(14, 26, 45, 0.3);
  border: solid 1px #f6f8fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 25px;
  padding-bottom: 25px;
  justify-content: center;
`;

const NoTemplateHeader = styled.div`
  font-size: 16px;
  margin-top: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${COLORS.TEXT_BLACK};
`;

const NoTemplateText = styled.div`
  font-size: 14px;
  line-height: 1.57;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const TemplateSection = () => {
  const { i18n } = useLingui();
  const [templates, setTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const conversationId = useSelector(getId);
  const status = useSelector(getStatus);
  const dispatch = useDispatch();
  const prevTemplate = usePrevious(selectedTemplate);
  const { error, isAdmin } = useContext(ConversationSetupContext);
  const isFetchTemplates = !conversationId || status === CONVERSATION_STATUSES.DRAFT.key;

  const onChangeTemplate = (template) => {
    if (error.value) {
      error.off();
    }

    setSelectedTemplate(template);
    dispatch(setName(template.name));
    dispatch(setDescription(template.description));
  };

  const onChangeTemplateTalkingPoints = async (
    oldTalkingPoints,
    newTalkingPoints,
    newTalkingPointId,
  ) => {
    if (oldTalkingPoints && oldTalkingPoints.length) {
      dispatch(removeTalkingPoints(oldTalkingPoints));
    }
    dispatch(
      addTalkingPoints(
        newTalkingPoints.map((point) => {
          return { type: 'templates', name: point, target: newTalkingPointId, targetUser: null };
        }),
      ),
    );
  };

  // fetch templates list
  useEffect(() => {
    async function fetchData() {
      const templates = isFetchTemplates ? await conversationRequests.getTemplatesOld() : {};
      const sortedTemplates = Object.values(templates).sort((a, b) =>
        b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1,
      );
      setTemplates(sortedTemplates);
      setFilteredTemplates(sortedTemplates);
    }
    fetchData();
  }, [isFetchTemplates]);

  // delete talking point on template change
  useEffect(() => {
    if (prevTemplate && prevTemplate.id === selectedTemplate.id) {
      return;
    }
    selectedTemplate &&
      onChangeTemplateTalkingPoints(
        prevTemplate ? prevTemplate.talkingPoints : [],
        selectedTemplate.talkingPoints || [],
        selectedTemplate.id,
      );

    // eslint-disable-next-line
  }, [selectedTemplate, prevTemplate]);

  const handleSearchChange = (search) => {
    const filtered = templates.filter((template) =>
      template.name.toLowerCase().includes(search.toLowerCase()),
    );
    setFilteredTemplates(filtered);
  };

  const noTemplatesPlaceholder = (
    <NoReviewTemplateWrapper>
      <SvgIcon
        width="28px"
        height="28px"
        isDefaultColor
        defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
        url={starIcon}
      />
      <NoTemplateHeader>
        <Trans>No templates available</Trans>
      </NoTemplateHeader>
      <NoTemplateText>
        <Trans>Ask your HR manager to create a template</Trans>
      </NoTemplateText>
    </NoReviewTemplateWrapper>
  );

  return (
    <FieldGroup>
      <FieldTitle>
        <Trans>Template</Trans>
      </FieldTitle>
      <DropdownContainer>
        <AutocompleteDropdown
          items={filteredTemplates}
          selectedItem={selectedTemplate}
          onChange={onChangeTemplate}
          searchField
          onSearchChange={handleSearchChange}
          noItemsPlaceholder={noTemplatesPlaceholder}
          showNoItemsPlaceholder={templates.length <= 0}
          disabled={isAdmin}
          placeholder={i18n._(t`Select template`)}
        />
      </DropdownContainer>
    </FieldGroup>
  );
};

export default React.memo(TemplateSection);
