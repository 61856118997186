import React from 'react';

import styled from 'styled-components';

import Shadow from '~/components/UI/Shadow';

const Container = styled(Shadow)`
  background-color: ${(props) => (props.$noBackgroundColor ? null : 'white')};
  border-radius: ${(props) => (props.$noBorderRadius ? null : '6px 6px 0 0')};
  ${(props) => props.$noBorder && 'border: none;'}
  ${(props) => props.$noShadow && 'box-shadow: none;'}
  ${(props) => props.$noBorderBottom && 'border-bottom: none;'}
  ${(props) => (props.$noBorderRadius ? 'border-radius: none;' : 'border-radius: 6px 6px 0 0;')}
  margin-bottom: ${(props) => props.$marginBottom && props.$marginBottom};
  display: inline-block;
`;

const TabsContainer = ({
  children,
  noBorder,
  noBorderBottom,
  noBackgroundColor,
  noBorderRadius,
  marginBottom,
  noShadow,
}) => {
  return (
    <Container
      $noBorder={noBorder}
      $noBackgroundColor={noBackgroundColor}
      $noBorderBottom={noBorderBottom}
      $noBorderRadius={noBorderRadius}
      $marginBottom={marginBottom}
      $noShadow={noShadow}
    >
      {children}
    </Container>
  );
};

export default React.memo(TabsContainer);
