import React, { useEffect, useState } from 'react';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import RickTextView from '~/components/RickTextView';
import { TemplateCardWithFooter } from '~/components/SetupTools/components/TemplateBaseComponents';
import SvgIcon from '~/components/SvgIcon';
import { Header3, Header7 } from '~/components/UI/Typographics/headers';

import toggleCloseIcon from '~/assets/toggle-close.svg';
import toggleOpenIcon from '~/assets/toggle-open.svg';
import viewIcon from '~/assets/view-1.svg';

import { REVIEW_SECTION_THEMES_STYLES } from '~/constants/reviews';
import { useScrollEffect } from '~/hooks/useScrollEffect';
import { COLOR_PALETTE, COLORS } from '~/styles';
import pageBreak from '~/utils/pageBreak';

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  ${Header3} {
    margin: 0;
  }
`;

const ColoredBlock = styled.div`
  background: linear-gradient(
    56deg,
    ${(props) => props.darkGradient},
    ${(props) => props.lightGradient}
  );
  width: 58px;
  min-width: 58px;
  height: 70px;
  border-top-left-radius: 6px;
  ${(props) => !props.$open && 'border-bottom-left-radius: 6px;'}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 24px 12px 14px;
  width: 100%;
  align-items: center;
  ${(props) =>
    props.$isOpen &&
    css`
      border-bottom: 1px solid ${COLORS.BG_PAGE};
    `};
`;

const HeaderDescription = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  margin-bottom: 12px;
  color: ${COLOR_PALETTE.DARK_GRAY};
  p {
    color: ${COLOR_PALETTE.DARK_GRAY};
  }
`;

const HeaderSection = styled.div`
  display: flex;
  width: 100%;
  outline: none;
  cursor: pointer;
`;

const HeaderText = styled.div`
  ${Header7} {
    color: ${COLORS.TEXT_SECONDARY};
    margin: 0;
    text-transform: uppercase;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  flex: 1;
`;

const Section = styled.div`
  scroll-margin-top: 110px;
  display: block;
  @media print {
    margin-top: 40px;
    ${(props) => props.$break && 'page-break-before: always;'};
    ${(props) => props.$pdfView && 'break-inside: avoid;'};
  }
`;

const SectionInfo = styled.div`
  margin: 12px 20px 16px;
  box-sizing: border-box;
`;

const ViewerText = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  margin-left: 10px;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const ViewRow = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div``;

const getSectionProviderText = (isYourReview, templateSection, forUserName) => {
  if (!templateSection) {
    return '';
  }
  const userName = isYourReview ? <Trans>You</Trans> : forUserName;
  const coachesName = !templateSection.isCoachesReview ? (
    ''
  ) : isYourReview ? (
    <Trans>your coach(es)</Trans>
  ) : (
    <Trans>his/her coach(es)</Trans>
  );
  const commonText = <Trans>asked to provide input for these questions</Trans>;
  const verb =
    (templateSection.isCoachesReview &&
      !templateSection.isSelfReview &&
      !templateSection.isUsersReview) ||
    (!templateSection.isCoachesReview &&
      templateSection.isSelfReview &&
      !templateSection.isUsersReview &&
      !isYourReview) ||
    (!templateSection.isCoachesReview &&
      !templateSection.isSelfReview &&
      templateSection.isUsersReview) ? (
      <Trans>is</Trans>
    ) : (
      <Trans>are</Trans>
    );

  if (
    templateSection.isCoachesReview &&
    templateSection.isSelfReview &&
    templateSection.isUsersReview
  ) {
    return (
      <>
        {userName}
        {', '}
        <Trans>the nominated peers and</Trans> {coachesName} {verb} {commonText}
      </>
    );
  } else if (templateSection.isCoachesReview && templateSection.isSelfReview) {
    return (
      <>
        {userName} <Trans>and</Trans> {coachesName} {verb} {commonText}
      </>
    );
  } else if (templateSection.isSelfReview && templateSection.isUsersReview) {
    return (
      <>
        {userName} <Trans>and the nominated peers</Trans> {verb} {commonText}
      </>
    );
  } else if (templateSection.isCoachesReview && templateSection.isUsersReview) {
    return (
      <>
        <Trans>The nominated peers and</Trans> {coachesName} {verb} {commonText}
      </>
    );
  } else if (templateSection.isCoachesReview) {
    return (
      <>
        {coachesName} {verb} {commonText}
      </>
    );
  } else if (templateSection.isUsersReview) {
    return (
      <>
        <Trans>The nominated peers</Trans> {verb} {commonText}
      </>
    );
  } else if (templateSection.isSelfReview) {
    return (
      <>
        {userName} {verb} {commonText}
      </>
    );
  } else {
    return '';
  }
};

function SectionCard({
  title,
  description,
  sectionIndex,
  sectionCount,
  children,
  isOpen,
  review,
  pdfView,
  theme,
  toggleSection,
  isYourReview,
  userFirstName,
}) {
  const { ref, scrollToRef } = useScrollEffect();
  const [breakPoint, setBreakPoint] = useState();

  useEffect(() => {
    const top = ref.current.getBoundingClientRect().top;
    const bottom = ref.current.getBoundingClientRect().bottom;
    setBreakPoint(pageBreak(top, bottom));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sectionTheme = Object.values(REVIEW_SECTION_THEMES_STYLES).find((t) => t.key === theme);

  const templateSection = review?.template?.sections[sectionIndex - 1];
  const sectionProviderText = isOpen
    ? getSectionProviderText(isYourReview, templateSection, userFirstName)
    : '';

  const handleToggleSection = () => {
    toggleSection();

    if (!isOpen) {
      scrollToRef();
    }
  };

  return (
    <Wrapper $pdfView={pdfView}>
      <Section ref={ref} $break={breakPoint} $pdfView={pdfView}>
        <TemplateCardWithFooter $noShadow={pdfView}>
          <CardContent>
            <HeaderSection onClick={handleToggleSection}>
              <ColoredBlock
                darkGradient={sectionTheme.darkGradient}
                lightGradient={sectionTheme.lightGradient}
                color={sectionTheme.color}
                $open={open}
              >
                <SvgIcon
                  url={sectionTheme.icon}
                  width={'22px'}
                  height={'22px'}
                  isDefaultColor
                  defaultColor={sectionTheme.color}
                />
              </ColoredBlock>
              <HeaderContent $isOpen={isOpen}>
                <HeaderText>
                  <Header7>
                    <Trans>Section</Trans> {sectionIndex}/{sectionCount}
                  </Header7>
                  <HeaderTitle>
                    <Header3>{title}</Header3>
                  </HeaderTitle>
                </HeaderText>
                {!pdfView && (
                  <div>
                    <SvgIcon
                      width="24px"
                      height="24px"
                      url={isOpen ? toggleOpenIcon : toggleCloseIcon}
                    />
                  </div>
                )}
              </HeaderContent>
            </HeaderSection>

            {isOpen && (
              <SectionInfo>
                {description && (
                  <HeaderDescription>
                    <RickTextView html={description} />
                  </HeaderDescription>
                )}
                {sectionProviderText && (
                  <ViewRow>
                    <SvgIcon
                      url={viewIcon}
                      width={'14px'}
                      height={'11px'}
                      isDefaultColor
                      defaultColor={COLOR_PALETTE.DARK_GRAY}
                    />
                    <ViewerText>{sectionProviderText}</ViewerText>
                  </ViewRow>
                )}
              </SectionInfo>
            )}
          </CardContent>
        </TemplateCardWithFooter>
      </Section>
      {isOpen && children}
    </Wrapper>
  );
}

SectionCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  sectionIndex: PropTypes.number.isRequired,
  sectionCount: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  theme: PropTypes.string,
};

export default SectionCard;
