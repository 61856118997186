import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const TH = styled.th<{
  minWidth?: string;
  maxWidth?: string;
  padding?: string;
}>`
  min-width: ${({ minWidth }) => minWidth || '120px'};
  width: ${({ maxWidth }) => maxWidth || 'unset'};
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}
`;

export const THLabel = styled.div<{
  $isPointer?: boolean;
  minWidth?: string;
  maxWidth?: string;
  padding?: string;
}>`
  cursor: ${({ $isPointer }) => ($isPointer ? 'pointer' : 'default')};
  padding: ${({ padding }) => padding || '4px 10px'};
  border-radius: 6px;
  min-width: ${({ minWidth }) => minWidth || '120px'};
  width: ${({ maxWidth }) => maxWidth || 'unset'};
  margin: 8px 14px;

  @keyframes fade {
    0% {
      background-color: #f5f7fd;
    }
    100% {
      background-color: unset;
    }
  }

  &:hover,
  &.hover {
    background-color: #f5f7fd;
  }

  &.isFade {
    animation: fade 0.2s ease-in-out;
  }
`;

export const TableWrapper = styled.table<{ $isPointer: boolean }>`
  border-collapse: collapse;
  background-color: ${COLORS.WHITE};
  width: 100%;

  & tr {
    cursor: ${(props) => (props.$isPointer ? 'pointer' : 'default')};
  }

  & th,
  td {
    position: relative;
    text-align: left;
    margin: 8px 14px;
  }

  & th {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;

    & ${THLabel} {
      display: inline-flex;
      align-items: center;
    }
  }

  & td {
    font-size: 14px;
    cursor: ${(props) => (props.$isPointer ? 'pointer' : 'default')};

    & .withPercentage {
      display: inline-flex;
      align-items: center;
      gap: 12px;
      width: 80px;
    }
  }
`;

export const TD = styled.td<{
  minWidth?: string;
  maxWidth?: string;
  padding?: string;
  isGrayColor?: boolean;
  $isPointer?: boolean;
}>`
  min-width: ${({ minWidth }) => minWidth || '120px'};
  width: ${({ maxWidth }) => maxWidth || 'unset'};
  max-width: ${({ maxWidth }) => maxWidth || 'unset'};
  padding: ${({ padding }) => padding || '16px 24px'};
  color: ${({ isGrayColor }) => (isGrayColor ? COLORS.DARK_GRAY : COLORS.BLACK)};
  cursor: ${({ $isPointer }) => ($isPointer ? 'pointer' : 'default')};
`;

export const THead = styled.thead`
  & .rotate {
    rotate: 180deg;
  }
`;

export const TR = styled.tr<{
  isSelected?: boolean;
}>`
  border-bottom: 1px solid ${COLORS.BORDER_HARD};

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${COLORS.BG_PAGE};
    `}

  &:hover {
    background-color: #f5f7fd;
  }
`;

export const TRHeader = styled(TR)<{
  isHideHeader?: boolean;
}>`
  ${({ isHideHeader }) =>
    isHideHeader &&
    css`
      height: 0 !important;
      background-color: var(--company-color);
    `};

  ${({ isHideHeader }) =>
    isHideHeader
      ? css`
          &:hover {
            background-color: var(--company-color);
          }
        `
      : css`
          &:hover {
            background-color: ${COLORS.WHITE};
          }
        `};
`;

export const SortMenu = styled.div`
  position: absolute;
  top: 40px;
  left: 15px;
  background-color: ${COLORS.WHITE};
  border-radius: 6px;
  box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
  border: solid 1px #dae4f7;
  width: 150px;
  padding: 4px;
  z-index: 2;

  & .item {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: normal;
    height: 32px;
    border-radius: 6px;
    color: ${COLORS.DARK_GRAY};
    cursor: pointer;

    &:first-child {
      margin-bottom: 4px;
    }

    & .icon {
      width: 32px;
    }

    &.selected {
      background-color: var(--company-color);
      color: ${COLORS.WHITE};
      & .icon {
        color: ${COLORS.WHITE};
      }
    }

    &:not(.selected):hover {
      background-color: #f5f7fd;
    }
  }
`;

export const SortIcons = styled.span`
  margin-left: 4px;
  display: inline-flex;
  flex-direction: column;
`;

export const DraftLabel = styled.div`
  position: absolute;
  right: -70px;
  top: -10px;
`;

export const DraftWrapp = styled.div`
  position: relative;
`;

export const IconMenuWrapp = styled.div`
  display: flex;
  align-items: center;
`;
