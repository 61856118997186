import type { IMultiLangString } from '@learned/types';

export const removeEmptyValues = (value: IMultiLangString) => {
  return Object.entries(value).reduce((acc, [key, value]) => {
    if (value === '') {
      return acc;
    }
    return { ...acc, [key]: value };
  }, {});
};
