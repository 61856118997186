import React, { Fragment, useState } from 'react';

import { KPI_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import SkillCoverageGraph from '~/components/Graphs/SkillCoverageGraph';
import OverviewHeading from '~/components/OverviewHeading';
import BaseLayout from '~/layouts/BaseLayout';

import PerSkillSection from './components/PerSkillSection';

import { INSTRUCTIONS } from '~/constants/instructions';
import routes from '~/constants/routes';
import { downloadSkillsReportCSV } from '~/services/skills';
import getInstructionUrl from '~/utils/getInstructionUrl';

const PerSkillWrapper = styled.div`
  margin-top: 24px;
`;

function SkillsReportPage({ i18n }) {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedReviews, setSelectedReviews] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [reviewsList, setReviewList] = useState([]);
  const history = useHistory();
  const href = window.location.href;
  const url = new URL(href);
  const review = url.searchParams.get('review');
  const isLatest = false; // now it's always false, for Skill 2 report it was true, but we deleted skill 2 report

  const setDefaultReview = (data) => {
    // we get reviews list from graph
    // choose the latest (it's the last)
    if (_.isEmpty(data)) {
      setReviewList([]);
      setSelectedReviews([]);
    } else {
      {
        const reviews = data.map((r) => ({ id: r.id, name: r.name }));
        const latestReview = reviews[reviews.length - 1];
        const selectedReview = review ? JSON.parse(review) : latestReview;
        setSelectedReviews([selectedReview]);
        setReviewList(reviews);
        if (review) {
          history.replace(routes.SKILLS_REPORT);
        }
      }
    }
  };

  const downloadCSV = async () => {
    let skillsFilter = [];
    let membersFilter = [];

    if (!_.isEmpty(selectedSkills)) {
      skillsFilter = skillsFilter.concat(selectedSkills.map((r) => r.id));
    }
    if (!_.isEmpty(selectedReviews)) {
      selectedReviews.map((r) => {
        if (_.hasIn(r, 'users')) {
          membersFilter = membersFilter.concat(r.users);
        }
      });
    }
    if (!_.isEmpty(selectedTeams)) {
      membersFilter = membersFilter.concat(selectedTeams.map((t) => t.members));
    }
    membersFilter = membersFilter.flat();

    await downloadSkillsReportCSV({
      isLatest,
      reviewId: selectedReviews[0].id,
      sectionTypes: [KPI_TYPES.SKILLS],
      ...(!_.isEmpty(skillsFilter) && { skills: _.uniq(skillsFilter) }),
      ...(!_.isEmpty(membersFilter) && { members: _.uniq(membersFilter) }),
    });
  };

  return (
    <Fragment>
      <OverviewHeading
        title={i18n._(t`Skills I`)}
        description={i18n._(t`The skill coverage per review`)}
        instructions={i18n._(t`How the skill report works`)}
        instructionsUrl={getInstructionUrl(INSTRUCTIONS.HOW_SKILL_REPORT_WORKS)}
      >
        <Button label={i18n._(t`Export csv`)} onClick={downloadCSV} />
      </OverviewHeading>

      <BaseLayout>
        <SkillCoverageGraph
          description={i18n._(t`The skill coverage per review`)}
          onFetchFinish={setDefaultReview}
        />
        <PerSkillWrapper>
          {!_.isEmpty(selectedReviews) && (
            <PerSkillSection
              selectedSkills={selectedSkills}
              setSelectedSkills={setSelectedSkills}
              selectedTeams={selectedTeams}
              setSelectedTeams={setSelectedTeams}
              reviewsList={reviewsList}
              selectedReviews={selectedReviews}
              setSelectedReviews={setSelectedReviews}
            />
          )}
        </PerSkillWrapper>
      </BaseLayout>
    </Fragment>
  );
}

export default withI18n()(SkillsReportPage);
