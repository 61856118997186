import React, { useCallback, useEffect, useState } from 'react';

import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';

import { Container, SearchPeers } from './design';

import { getCompanyUsers } from '~/services/users';
import getUserFullName from '~/utils/getUserFullName';

import type { IUser } from '@learned/types';

type InvitePeersProps = {
  selectedIds?: IUser['id'][];
  excludeIds?: IUser['id'][];
  onSelectedPeersIds?(ids: IUser['id'][]): void;
};

const InvitePeers = ({
  selectedIds = [],
  onSelectedPeersIds,
  excludeIds = [],
}: InvitePeersProps) => {
  const { i18n } = useLingui();

  const [items, setItems] = useState<IUser[]>([]);
  const [selectedItems, setSelectedItems] = useState<IUser[]>([]);
  const [search, setSearch] = useState('');

  const [users, setUsers] = useState<IUser[]>([]);

  const fetchUsers = useCallback(async () => {
    const { data } = await getCompanyUsers();
    const usersDB = Object.values(data.users) as IUser[];
    setUsers(usersDB);
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    const filteredUsers = users.filter((user: IUser) => !excludeIds?.includes(user.id));
    const selectedUsers = users.filter((user: IUser) => selectedIds?.includes(user.id));
    setItems(filteredUsers);
    setSelectedItems(selectedUsers);
  }, [excludeIds, selectedIds, users]);

  const filteredItems = !isEmpty(search)
    ? items.filter(
        // Search word accepting special chars
        (item) => getUserFullName(item).toLowerCase().indexOf(search.trim().toLowerCase()) !== -1,
      )
    : items;

  return items ? (
    <Container>
      <SearchPeers
        selectedItems={selectedItems}
        items={filteredItems}
        onChange={(selectedItems: IUser[]) => {
          setSelectedItems(selectedItems);
          onSelectedPeersIds?.(selectedItems.map((item) => item.id));
        }}
        stringifyItem={(item: IUser) => getUserFullName(item)}
        placeholder={i18n._('Search peers')}
        hashItem={(item: IUser) => item.id}
        isExpandable
        onSearchChange={setSearch}
        showAvatar
        minCharsToShowDropdown={2}
      />
    </Container>
  ) : null;
};
export { InvitePeers };
