import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import { AdminDashboard } from '~/pages/AdminDashboard';
import QuickStart from '~/pages/QuickStart';
import UserDashboard from '~/pages/UserDashboard';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('dashboards', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.DASHBOARD.routePath(ROLES.USER)}
        exact
        path={routes.DASHBOARD.routePath(ROLES.USER)}
        component={UserDashboard}
        allowedRoles={[ROLES.USER]}
      />,
    ],
    admin: [
      <PrivateRoute
        key={routes.ADMIN_DASHBOARD.routePath(ROLES.ADMIN)}
        exact
        path={routes.ADMIN_DASHBOARD.routePath(ROLES.ADMIN)}
        component={AdminDashboard}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.REPORTS_ADMIN_DASHBOARD.routePath(ROLES.ADMIN)}
        exact
        path={routes.REPORTS_ADMIN_DASHBOARD.routePath(ROLES.ADMIN)}
        component={AdminDashboard}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.QUICK_START.routePath(ROLES.ADMIN)}
        exact
        path={routes.QUICK_START.routePath(ROLES.ADMIN)}
        component={QuickStart}
        allowedRoles={[ROLES.ADMIN]}
      />,
    ],
  },
  routesPublic: [],
});
