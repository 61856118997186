import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';

import { JobSummaryCardFooter, JobSummaryCardTitle, JobSummaryCardWrapper, Line } from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';

interface IJobProfile {
  coverImage?: string;
  skillMatch?: number;
  name: string;
  id: string;
}

interface IJobProfileSummaryProps {
  jobProfile: IJobProfile;
  onClickViewDetails: (roleId: string) => void;
}

const JobProfileSummaryCard = ({ jobProfile, onClickViewDetails }: IJobProfileSummaryProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  return (
    <JobSummaryCardWrapper>
      <JobSummaryCardTitle>{getMultiLangString(jobProfile?.name)}</JobSummaryCardTitle>
      <Line />
      <JobSummaryCardFooter>
        <Button
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          label={i18n._(t`View details`)}
          onClick={() => onClickViewDetails(jobProfile.id)}
        />
      </JobSummaryCardFooter>
    </JobSummaryCardWrapper>
  );
};

export { JobProfileSummaryCard };
