import { v4 as uuidv4 } from 'uuid';

import type { IChildTransformed, IRow, IRowTransformed } from './types';

// the goal of this process is to expand all the children by giving children a nested value
// only for 1 nested level
export const transformRowsToItems = (rows: IRow[]) => {
  const transformedRows: (IRowTransformed | IChildTransformed)[] = [];

  rows.forEach((row, index) => {
    const isFirstTeam = !index;
    const parentTemporalUniqueId = uuidv4();

    transformedRows.push({
      ...row,
      isCollapsed: !isFirstTeam, // open only first Team by default
      temporalUniqueId: parentTemporalUniqueId,
    });
    if (row?.children?.length) {
      row?.children.forEach((child) => {
        const childTemporalUniqueId = uuidv4();
        transformedRows.push({
          ...child,
          isVisible: isFirstTeam, // open only first Team by default
          parent: parentTemporalUniqueId,
          temporalUniqueId: childTemporalUniqueId,
        });
      });
    }
  });

  return transformedRows;
};
