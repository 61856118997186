import React, { useState } from 'react';

import { REVIEW_QUESTION_TYPES } from '@learned/constants';
import { IMultiLangString } from '@learned/types';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Icon, ICONS } from '~/components/Icon';
import Divider from '~/components/UI/Divider';

import {
  Title,
  HeaderContainer,
  ExpandContainer,
  Container,
  SubTitle,
  Question,
  ViewAll,
  QuestionsContainer,
  QuestionStrong,
} from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

export type PeerQuestion = {
  question?: IMultiLangString;
  type: REVIEW_QUESTION_TYPES;
  skill?: IMultiLangString;
  skillId?: string;
  jobName?: IMultiLangString;
  jobId?: string;
};

type QuestionsForPeersProps = {
  questions?: PeerQuestion[];
};

const QuestionsForPeers = ({ questions = [] }: QuestionsForPeersProps) => {
  const QUESTIONS_LIMIT = 7;

  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const [showQuestions, setShowQuestions] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const getQuestion = (question: PeerQuestion) => {
    switch (question.type) {
      case REVIEW_QUESTION_TYPES.SKILL_CATEGORY:
        return (
          <Question>
            <Trans
              id="You will be evaluated on the expected behavior for skill <0>{skill}</0> in your job <0>{jobName}</0>."
              // @ts-ignore
              components={[<QuestionStrong key={0} />]}
              values={{
                skill: getMultiLangString(question.skill || ''),
                jobName: getMultiLangString(question.jobName || ''),
              }}
            />
          </Question>
        );
      case REVIEW_QUESTION_TYPES.CUSTOM_SKILL:
        return (
          <Question>
            <Trans
              id="You will be evaluated on the expected behavior for skill <0>{skill}</0>."
              // @ts-ignore
              components={[<QuestionStrong key={0} />]}
              values={{
                skill: getMultiLangString(question.skill || ''),
              }}
            />
          </Question>
        );
      default:
        return <Question>{getMultiLangString(question.question || '')}</Question>;
    }
  };

  return (
    <Container>
      <HeaderContainer>
        <ExpandContainer onClick={() => setShowQuestions(!showQuestions)}>
          <Icon
            icon={ICONS.BACK}
            transform={showQuestions ? 'rotate(270)' : 'rotate(180)'}
            color={showQuestions ? COLORS.COMPANY : COLORS.PLACEHOLDERS}
            size={16}
          />
        </ExpandContainer>
        <Title margin={'0 0 0 13px'}>{i18n._(t`Questions for peers`)}</Title>
        <Title
          margin={'0 0 0 4px'}
          color={COLORS.SUBTEXT}
          fontWeight={100}
        >{`(${questions.length})`}</Title>
      </HeaderContainer>
      {showQuestions && (
        <QuestionsContainer>
          <SubTitle>{i18n._(t`Selected peers will answer the following questions:`)}</SubTitle>
          {(questions.length > 7 ? (showAll ? questions : questions.slice(0, 7)) : questions)?.map(
            (item) => getQuestion(item),
          )}
          {questions.length > QUESTIONS_LIMIT && (
            <ViewAll>
              <Button
                color={COLORS.COMPANY}
                label={showAll ? i18n._(t`View less`) : i18n._(t`View All (${questions?.length})`)}
                variant={ButtonVariant.TEXT_PRIMARY}
                size={ButtonSize.MEDIUM}
                onClick={() => {
                  setShowAll(!showAll);
                }}
              />
            </ViewAll>
          )}
        </QuestionsContainer>
      )}

      {!showQuestions && <Divider color={COLORS.BORDERS} margin={'24px 0 0 0'} />}
    </Container>
  );
};

export { QuestionsForPeers };
