import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { UseFormReturn } from 'react-hook-form';

import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import { UserAvatar } from '~/components/UserAvatar';

import { SubHeader } from './SubHeader';

import { AvatarContainer } from '../../../design';
import { SummaryFieldLabel } from '../design';

import type { IReviewIndividualForm } from '../../../types';

interface ParticipantsSummaryProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewIndividualForm>;
}

const ParticipantsSummary = ({ formMethods, sectionState }: ParticipantsSummaryProps) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;

  const navigateToSection = () => sectionState.setCurrentSection(1);

  return (
    <>
      <SubHeader title={i18n._(t`Select employee`)} onEdit={navigateToSection} />
      <SummaryFieldLabel>{i18n._(t`Employee`)}</SummaryFieldLabel>
      <AvatarContainer marginBottom={'33px'}>
        <UserAvatar userId={watch('employees')[0]?.id} />
      </AvatarContainer>
    </>
  );
};

export { ParticipantsSummary };
