import React, { useCallback, useEffect, useState } from 'react';

import { ACTIVITY_PROGRESS_TYPES } from '@learned/constants';
import { IActivity, IActivityCategory, ISkill } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import map from 'lodash/map';
import values from 'lodash/values';
import { useSelector } from 'react-redux';

import {
  AutocompleteFilterActivityCategories,
  AutocompleteFilterActivityTypes,
  AutocompleteFilterSkills,
} from '~/components/AutocompleteFilters';
import { EditLibraryActivityModal } from '~/components/Modals/EditLibraryActivityModal';
import { SearchSelectModal } from '~/components/SearchSelectModal';
import { columns } from '~/pages/PassportPage/components/ActivitiesModal/columns';

import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import { getCurrentCompanyId, getSelectedRole, getUser } from '~/selectors/baseGetters';
import { getUserLibraryActivities } from '~/services/activities';
import { createUserActivityFromLibrary } from '~/services/userActivities';

interface IFilters {
  skills: ISkill[];
  activityCategories: IActivityCategory[];
  activityTypes: { key: string }[];
  search: string;
}

function ActivitiesModal({
  userId,
  onClose,
}: {
  userId: string;
  onClose: (isSubmit?: boolean) => void;
}) {
  const { i18n } = useLingui();
  const currentRole = useSelector(getSelectedRole);
  const currentCompanyId = useSelector(getCurrentCompanyId);
  const currentUser = useSelector(getUser);

  const [selectedActivities, setSelectedActivities] = useState<IActivity[]>([]);
  const [activities, setActivities] = useState<IActivity[]>([]);
  const [total, setTotal] = useState<number>();
  const [showCreateActivityModal, setShowCreateActivityModal] = useState(false);
  const [newActivity, setNewActivity] = useState<IActivity | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentFilters, setCurrentFilters] = useState<IFilters>({
    skills: [],
    activityCategories: [],
    activityTypes: [],
    search: '',
  });

  const { pagination, resetPagination, changePagination } = usePagination(10);
  const debCurrentFilters: IFilters = useDebounce(currentFilters, 300); // to send request not immediately, but with delay

  async function addActivities() {
    setLoading(true);
    await Promise.all(
      map(
        selectedActivities,
        // @ts-ignore
        async (a) => await createUserActivityFromLibrary(a.id, 'personal', { forUser: userId }),
      ),
    );
    setLoading(false);
    setSelectedActivities([]);
    onClose(true);
  }

  const fetchActivities = useCallback(
    () =>
      getUserLibraryActivities(userId, {
        pagination,
        search: debCurrentFilters.search,
        types: (debCurrentFilters.activityTypes ?? []).map((t) => t.key),
        skills: (debCurrentFilters.skills ?? []).map((t) => t.id),
        activityCategories: (debCurrentFilters.activityCategories || []).map((t) => t.id),
      }),
    [userId, pagination, debCurrentFilters],
  );

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    async function fetch() {
      const response = await fetchActivities();

      if (isMounted) {
        setActivities(values(response?.data?.activities ?? {}));
        setTotal(response.data.total);
        setLoading(false);
      }
    }

    fetch();

    return () => void (isMounted = false);
  }, [fetchActivities]);

  function changeFilters(filters: Partial<IFilters>) {
    setCurrentFilters((prev) => ({ ...prev, ...filters }));
    resetPagination();
  }

  const openCreateActivityModal = () => {
    const newActivity = {
      isAvailableInUserLibrary: true,
      isRateAvailable: true,
      createdInRole: currentRole,
      createdBy: currentUser.id,
      company: currentCompanyId,
      progressType: ACTIVITY_PROGRESS_TYPES.IS_DONE,
      progressDetails: {
        min: null,
        max: null,
        currency: null,
      },
    };
    setNewActivity(newActivity as IActivity);
    setShowCreateActivityModal(true);
  };

  return (
    <>
      <SearchSelectModal
        title={i18n._(t`Connect learning`)}
        onPrimaryAction={() => addActivities()}
        primaryActionLabel={i18n._(t`Connect learning`)}
        tableListProps={{
          columns,
          menuProps: {
            isMenuVisible: false,
          },
          isLoading: loading,
          data: activities,
          secondaryButton: {
            label: 'Create new activity',
            // eslint-disable-next-line no-console
            onClick: openCreateActivityModal,
          },
          multiSelectProps: {
            multiSelect: {
              checkedCount: selectedActivities.length,
              isAllChecked: selectedActivities.length === activities.length,
              onSelectItem: (item) =>
                setSelectedActivities((prevState) => {
                  if (prevState.includes(item)) {
                    return prevState.filter((value) => value !== item);
                  }
                  return [...prevState, item];
                }),
              isItemChecked: (item) => selectedActivities.includes(item),
              onCheckAll: () =>
                selectedActivities.length !== activities.length
                  ? setSelectedActivities(activities)
                  : setSelectedActivities([]),
            },
          },
          filtersProps: {
            filters: {
              search: currentFilters.search,
              setSearch: (value) => changeFilters({ search: value }),
            },
            isFiltered: false,
            isToggleHideFilterVisible: true,
            filterComponents: (
              <>
                <AutocompleteFilterSkills
                  onChange={(skills) => changeFilters({ skills })}
                  checkedList={currentFilters.skills}
                />
                <AutocompleteFilterActivityCategories
                  onChange={(activityCategories) => changeFilters({ activityCategories })}
                  checkedList={currentFilters.activityCategories}
                />
                <AutocompleteFilterActivityTypes
                  onChange={(activityTypes) => changeFilters({ activityTypes })}
                  checkedList={currentFilters.activityTypes}
                />
              </>
            ),
          },
          paginationProps: {
            pagination,
            changePagination,
            totalCount: total,
          },
        }}
        onClose={onClose}
      />
      {showCreateActivityModal && newActivity && (
        <EditLibraryActivityModal
          currentActivity={newActivity}
          onHideModal={() => setShowCreateActivityModal(false)}
          onSaveActivity={async () => {
            setLoading(true);
            setNewActivity(undefined);

            const response = await fetchActivities();

            setActivities(values(response?.data?.activities ?? {}));
            setShowCreateActivityModal(false);
            setTotal(response.data.total);
            setLoading(false);
          }}
        />
      )}
    </>
  );
}

export { ActivitiesModal };
