import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Dropdown } from '~/components/Dropdown';

import { SUPER_ADMIN_LANGUAGES } from '~/constants/languages';
import { listIndustries } from '~/services/industries';
import { getSuperAdminMultiLangString } from '~/utils/superAdmin';

import type { IIndustry } from '@learned/types';

export const FilterIndustries = ({
  selectedItems,
  onChange,
}: {
  onChange: (selectedItems: IIndustry[]) => void;
  selectedItems: IIndustry[];
}) => {
  const { i18n } = useLingui();
  const [industries, setIndustries] = useState<IIndustry[]>([]);

  useEffect(() => {
    let isMounted = true;
    async function fetch() {
      const result = await listIndustries();
      if (isMounted && result?.code === 200) {
        setIndustries(result?.data?.[API_RETURN_FIELDS.INDUSTRIES]);
      }
    }

    fetch();

    return () => void (isMounted = false);
  }, []);

  return (
    <Dropdown
      items={industries}
      selectedItems={selectedItems}
      placeholder={i18n._(t`Industry`)}
      stringifyItem={(item) =>
        getSuperAdminMultiLangString(item.name, {
          languages: SUPER_ADMIN_LANGUAGES,
          primaryLanguage: SUPER_ADMIN_LANGUAGES[0],
        })
      }
      onChange={onChange}
    />
  );
};
