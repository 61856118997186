import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { HeaderTabs } from '~/components/HeaderTabs';
import Card, { ETypes, TScores, TChartData } from '~/components/Reports/DashboardCharts/Card';
import { CardWrapper } from '~/components/Reports/DashboardCharts/CardStyles';
import BaseLayout from '~/layouts/BaseLayout';

import { MembersOverview } from './MembersOverview';
import { TeamsOverview } from './TeamsOverview';

import routes from '~/constants/routes';
import { getUser, checkModuleSurvey } from '~/selectors/baseGetters';
import { getSurveyParticipationChart } from '~/services/surveys';
import { COLORS } from '~/styles';

import type { ISurvey } from '@learned/types';
import type { I18n } from '@lingui/core';

interface SurveyResultProps {
  survey: ISurvey;
}

const HeaderWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  width: fit-content;
  border-radius: 6px 6px 0 0;
`;

const HeaderCardWrapper = styled.div<{ addBottomMargin: boolean }>`
  margin-bottom: ${(props) => (props.addBottomMargin ? '20px' : '0')};
`;

export const TABS_ENUM = {
  TEAMS: 'teams',
  MEMBERS: 'members',
};

export const TABS_SLIDER_ENUM = {
  PARTICIPATION: 'participation',
  RESULTS: 'results',
  SET_UP: 'setup',
};

const SurveyParticipation = ({ survey }: SurveyResultProps) => {
  const history = useHistory();
  const user = useSelector(getUser);
  const { i18n } = useLingui();
  const isModuleSurveyEnabled = useSelector(checkModuleSurvey);
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState<{
    average: number;
    series: TChartData[];
    teamRank: TScores;
  }>();
  const [currentTab, setCurrentTab] = useState(
    // @ts-ignore
    TABS_ENUM[window.location.hash.substring(1).toUpperCase()] || TABS_ENUM.TEAMS,
  );

  const isAnonymous = !!survey?.anonymity.isAnonymous;

  if ((currentTab === TABS_ENUM.MEMBERS && isAnonymous) || !isModuleSurveyEnabled) {
    history.push(routes.HOME);
  }

  const handleChangeTab = (key: string) => {
    window.location.hash = key;
    setCurrentTab(key);
  };

  const TABS = [
    {
      label: (i18n: I18n) => i18n._(t`Teams`),
      key: TABS_ENUM.TEAMS,
    },
  ];

  if (!isAnonymous) {
    TABS.push({
      label: (i18n: I18n) => i18n._(t`Members`),
      key: TABS_ENUM.MEMBERS,
    });
  }

  const fetchData = async () => {
    if (survey.id) {
      setIsLoading(true);

      const { data } = await getSurveyParticipationChart(survey.id);
      if (data && data.surveyParticipation) {
        setChartData({
          teamRank: {
            bestScore: data.surveyParticipation.teamRank.top,
            lowestScore: data.surveyParticipation.teamRank.bottom,
          },
          average: data.surveyParticipation.averageSeries.average,
          series: data.surveyParticipation.averageSeries.series.map(
            (s: { date: string; value: number }) => ({ key: s.date, value: s.value }),
          ),
        });
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BaseLayout smallWidth>
        {user.isAdmin && (
          /* add margin bottom when the header is not visible */
          <HeaderCardWrapper addBottomMargin={TABS.length < 2}>
            <CardWrapper>
              <Card
                title={i18n._(t`Average participation`)}
                chartData={chartData?.series}
                average={chartData?.average}
                isLoading={isLoading}
              />
              <Card
                title={i18n._(t`Teams with highest participation`)}
                type={ETypes.BAR_CHART}
                data={chartData?.teamRank?.bestScore}
                isLoading={isLoading}
              />
              <Card
                title={i18n._(t`Teams with lowest participation`)}
                type={ETypes.BAR_CHART}
                data={chartData?.teamRank?.lowestScore}
                oppositeGradient
                bColor={COLORS.CONFIRMATION_MODAL_DELETE}
                isLoading={isLoading}
              />
            </CardWrapper>
            {/* show only teams tab by default, therefore hide header when only teams tab is shown */}
            {TABS.length > 1 && (
              <HeaderWrapper>
                {/* @ts-ignore */}
                <HeaderTabs
                  tabs={TABS}
                  selectedTab={currentTab}
                  handleChangeTab={handleChangeTab}
                />
              </HeaderWrapper>
            )}
          </HeaderCardWrapper>
        )}
        {currentTab === TABS_ENUM.TEAMS && isModuleSurveyEnabled && (
          <TeamsOverview survey={survey} />
        )}
        {currentTab === TABS_ENUM.MEMBERS && !isAnonymous && isModuleSurveyEnabled && (
          <MembersOverview />
        )}
      </BaseLayout>
    </>
  );
};

export { SurveyParticipation };
