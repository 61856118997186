import React, { useContext } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Tooltip, { TOOLTIP_SIZES, TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import { COLORS } from '~/styles';

import { EngagementReportContext } from '../engagement/EngagementContext';

const Container = styled.div`
  position: absolute;
  right: 0;
  bottom: -2px;
  transform: translateY(100%);
  min-width: 220px;
  z-index: 100;
  cursor: pointer;
  background: ${COLORS.WHITE};
  display: flex;
  flex-direction: column;
  padding: 2px;
  font-weight: normal;
  border-radius: 6px;
  border: 1px solid ${COLORS.BORDERS};
`;

const TextCtr = styled.button<{ isDisabled?: boolean }>`
  all: unset;
  font-size: 12px;
  padding: 11px 16px;
  color: ${({ isDisabled }) => (isDisabled ? COLORS.SUBTEXT : COLORS.TEXT_MAIN)};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background: ${({ isDisabled }) => (isDisabled ? COLORS.TRANSPARENT : COLORS.HOVER)};
    color: ${({ isDisabled }) => (isDisabled ? COLORS.SUBTEXT : 'var(--company-color)')};
  }
  &:first-child {
    border-radius: 6px 6px 0 0;
  }
  &:last-child {
    border-radius: 0 0 6px 6px;
  }
`;

export enum MENU_ACTIONS {
  SAVE = 'SAVE',
  UPDATE = 'UPDATE',
  ADD_TO_ADMIN_DASHBOARD = 'ADD_TO_ADMIN_DASHBOARD',
  ADD_TO_COACH_DASHBOARD = 'ADD_TO_COACH_DASHBOARD',
}

type TProps = {
  onMenuChange: (type: MENU_ACTIONS) => void;
  reference: React.RefObject<HTMLDivElement>;
};
function SaveAsMenu({ onMenuChange, reference }: TProps): JSX.Element | null {
  const { i18n } = useLingui();
  const { tabConfiguration } = useContext(EngagementReportContext);

  if (!tabConfiguration?.controls.allowSaveAs && !tabConfiguration?.controls.allowSave) {
    return null;
  }
  return (
    <Container ref={reference}>
      {tabConfiguration?.controls.allowSaveAs && (
        <TextCtr onClick={() => onMenuChange(MENU_ACTIONS.SAVE)}>
          {i18n._(t`Save as new view`)}
        </TextCtr>
      )}
      {tabConfiguration?.controls.allowSave && (
        <TextCtr onClick={() => onMenuChange(MENU_ACTIONS.UPDATE)}>
          {i18n._(t`Update current view`)}
        </TextCtr>
      )}
      {tabConfiguration?.controls.allowAdminDashboard && (
        <Tooltip
          placement={TOOLTIP_PLACEMENTS.BOTTOM}
          disabled={!tabConfiguration?.isOnAdminDashboard}
          size={TOOLTIP_SIZES.BIG}
          tooltip={i18n._(t`Added to organization dashboard`)}
          maxWidth="300px"
        >
          <TextCtr
            isDisabled={tabConfiguration?.isOnAdminDashboard}
            onClick={() =>
              !tabConfiguration?.isOnAdminDashboard &&
              onMenuChange(MENU_ACTIONS.ADD_TO_ADMIN_DASHBOARD)
            }
          >
            {i18n._(t`Add to organization dashboard`)}
          </TextCtr>
        </Tooltip>
      )}

      {tabConfiguration?.controls.allowCoachDashboard && (
        <Tooltip
          placement={TOOLTIP_PLACEMENTS.BOTTOM}
          disabled={!tabConfiguration?.isOnCoachDashboard}
          size={TOOLTIP_SIZES.BIG}
          tooltip={i18n._(t`Added to team dashboard`)}
          maxWidth="300px"
        >
          <TextCtr
            isDisabled={tabConfiguration?.isOnCoachDashboard}
            onClick={() =>
              !tabConfiguration?.isOnCoachDashboard &&
              onMenuChange(MENU_ACTIONS.ADD_TO_COACH_DASHBOARD)
            }
          >
            {i18n._(t`Add to team dashboard`)}
          </TextCtr>
        </Tooltip>
      )}
      {!tabConfiguration?.controls.allowCoachDashboard && !tabConfiguration?.isDefault && (
        <Tooltip
          placement={TOOLTIP_PLACEMENTS.BOTTOM}
          size={TOOLTIP_SIZES.BIG}
          tooltip={i18n._(t`Custom reports cannot be added to the coach dashboard`)}
          maxWidth="300px"
        >
          <TextCtr isDisabled={true}>{i18n._(t`Add to team dashboard`)}</TextCtr>
        </Tooltip>
      )}
    </Container>
  );
}

export default SaveAsMenu;
