import { useState } from 'react';

export default function useBoolState(
  initialState = false,
  { onTrigger, offTrigger, setTrigger } = {},
) {
  const [value, set] = useState(initialState);

  const setVal = (val) => {
    set(val);
    setTrigger && setTrigger(val);
  };

  return {
    value,
    set: setVal,
    on: () => {
      setVal(true);
      onTrigger && onTrigger();
    },
    off: () => {
      setVal(false);
      offTrigger && offTrigger();
    },
    toggle: () => setVal((val) => !val),
  };
}
