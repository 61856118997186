import React from 'react';

import Tippy from '@tippyjs/react';

export enum PopOverPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  AUTO = 'auto',
  BOTTOM_START = 'bottom-start',
  BOTTOM_END = 'bottom-end',
  TOP_START = 'top-start',
  TOP_END = 'top-end',
  LEFT_START = 'left-start',
  LEFT_END = 'left-end',
  RIGHT_START = 'right-start',
  RIGHT_END = 'right-end',
  AUTO_START = 'auto-start',
  AUTO_END = 'auto-end',
}

type TProps = {
  visible: boolean;
  content: JSX.Element;
  children: JSX.Element;
  placement: PopOverPlacement;
  arrow?: boolean;
  interactive?: boolean;
  offset?: [number, number];
  onClickOutside?: () => void;
  reference?: React.RefObject<Element>;
};
function PopOver({
  visible,
  content,
  children,
  placement,
  arrow = true,
  interactive = false,
  offset = [0, 0],
  onClickOutside,
  reference,
}: TProps): JSX.Element {
  return (
    <Tippy
      placement={placement}
      arrow={arrow}
      interactive={interactive}
      visible={visible}
      content={content}
      offset={offset}
      onClickOutside={onClickOutside}
      zIndex={9999}
      ref={reference}
    >
      {children}
    </Tippy>
  );
}

export default PopOver;
