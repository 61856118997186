import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';
import Modal from '~/components/Modal';

import useBoolState from '~/hooks/useBoolState';

const FooterActions = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ConfirmModal = ({ onModalClose, onSubmit, text, title, labelYes, labelNo }) => {
  const $loading = useBoolState(false);
  const { i18n } = useLingui();

  return (
    <Modal
      onClose={onModalClose}
      title={title}
      footerRight={
        <FooterActions>
          <Button
            styles={{
              height: '40px',
              marginLeft: '10px',
              textTransform: 'uppercase',
              padding: '0px 15px',
              fontSize: '12px',
              minWidth: '120px',
            }}
            type="primary"
            label={labelYes || i18n._(t`Update`)}
            onClick={async () => {
              $loading.on();
              await onSubmit();
              $loading.off();
            }}
            loading={$loading.value}
          />
          <Button
            styles={{
              height: '40px',
              marginLeft: '10px',
              textTransform: 'uppercase',
              padding: '0px 15px',
              fontSize: '12px',
              minWidth: '120px',
            }}
            type="primary-border"
            label={labelNo || i18n._(t`Cancel`)}
            onClick={onModalClose}
            loading={$loading.value}
          />
        </FooterActions>
      }
    >
      {text}
    </Modal>
  );
};

ConfirmModal.propTypes = {
  onModalClose: PropTypes.func,
  onSubmit: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string,
  labelYes: PropTypes.string,
  labelNo: PropTypes.string,
};
