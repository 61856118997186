import React from 'react';

import { Dropdown } from '~/components/Dropdown';

import { getTeams } from '~/services/teams';

import type { ITeam } from '@learned/types';

interface ITeamsFilterProps {
  selectedItems?: ITeam[];
  onChange: (items: ITeam[]) => void;
  isPermissionsCheck?: boolean;
  placeholder?: string;
}

const LIMIT = 20;

const AutocompleteFilterTeams = React.memo((props: ITeamsFilterProps) => {
  const [items, setItems] = React.useState<ITeam[]>([]);
  const [selectedItems, setSelectedItems] = React.useState<ITeam[]>(props.selectedItems || []);

  const fetchTeams = async (search: string) => {
    const data = await getTeams({
      limit: LIMIT,
      search,
      ...(props.isPermissionsCheck && { isPermissionsCheck: props.isPermissionsCheck }),
    });

    // @ts-ignore
    const teams = Object.values(data).map((item: ITeam) => ({ ...item, key: item.id }));
    setItems(teams);
  };

  const handleOnChange = (items: ITeam[]) => {
    setSelectedItems(items);
    props.onChange(items);
  };

  return (
    <Dropdown
      items={items}
      isSearchable
      selectedItems={selectedItems}
      onChange={handleOnChange}
      isSingleSelect={false}
      stringifyItem={(item: ITeam) => item.name}
      // @ts-ignore
      placeholder={props.placeholder}
      onSearchChange={fetchTeams}
    />
  );
});

export { AutocompleteFilterTeams };
