import React from 'react';
import './Footer.scss';

const Footer = () => (
  <div className="footer">
    <div className="copyright">{`Ⓒ Copyright Learned B.V. 2019-${new Date().getFullYear()}`}</div>
  </div>
);

export default Footer;
