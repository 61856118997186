import React, { useEffect, useState } from 'react';

import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';

import SectionAdminRights from './components/UserSettings/SectionAdminRights';
import { SectionCoachTeams } from './components/UserSettings/SectionCoachTeams';
import { SectionDetails } from './components/UserSettings/SectionDetails';
import { SectionUserTeams } from './components/UserSettings/SectionUserTeams';

import { ROLES } from '~/constants';
import { getSelectedRole, getUser } from '~/selectors/baseGetters';
import { getTeams } from '~/store/teams/actions';
import { COLORS } from '~/styles';

const Container = styled.div`
  margin: 0px;
`;

const SectionWrapper = styled.div`
  margin-bottom: 20px;
  background-color: ${COLORS.WHITE};
  border-radius: 6px;
  padding: 20px 40px 30px 40px;

  & > div:first-child {
    padding: 0px;
    margin-bottom: 0px;
  }
`;

const SettingsTab = ({ user, teams, dispatch }) => {
  const selectedRole = useSelector(getSelectedRole);
  const [teamsState, setTeamsState] = useState({});
  const [fetching, setFetching] = useState(true);
  const currentUser = useSelector(getUser);
  const isAdmin = selectedRole === ROLES.ADMIN || currentUser?.isAdmin;

  useEffect(() => {
    setFetching(true);
    dispatch(getTeams());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(teams)) {
      setTeamsState(teams);
    }
    setFetching(false);
  }, [teams]);

  return (
    <Container>
      <SectionWrapper>
        <SectionDetails userId={user.id} />
      </SectionWrapper>
      <SectionWrapper>
        <SectionUserTeams
          userId={user.id}
          teamsState={teamsState}
          fetching={fetching}
          isAdmin={isAdmin}
        />
      </SectionWrapper>
      <SectionWrapper>
        <SectionCoachTeams
          user={user}
          userId={user.id}
          teamsState={teamsState}
          fetching={fetching}
          isAdmin={isAdmin}
        />
      </SectionWrapper>
      {isAdmin && (
        <SectionWrapper>
          <SectionAdminRights userId={user.id} fromCoachScreen={selectedRole === ROLES.COACH} />
        </SectionWrapper>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({ teams: state.teams.data || {} });

export default withI18n()(connect(mapStateToProps)(SettingsTab));
