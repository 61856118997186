import React from 'react';

import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { StyledHeaderTabs, TabsWrapper } from '../design';

interface IProps {
  currentTab: TABS_ENUM;
  handleChangeTab: (tab: string) => void;
  tabsRightBlock?: React.ReactNode;
}

enum TABS_ENUM {
  MY_GOALS = 'my_goals',
  ALL_GOALS = 'all_goals',
}

function GoalTabs({ currentTab, handleChangeTab }: IProps) {
  const TABS = [
    {
      label: (i18n: I18n) => i18n._(t`My goals`),
      key: TABS_ENUM.MY_GOALS,
    },
    {
      label: (i18n: I18n) => i18n._(t`All goals`),
      key: TABS_ENUM.ALL_GOALS,
    },
  ];

  return (
    <TabsWrapper>
      <StyledHeaderTabs tabs={TABS} selectedTab={currentTab} handleChangeTab={handleChangeTab} />
    </TabsWrapper>
  );
}

export { GoalTabs, TABS_ENUM };
