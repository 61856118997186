import React, { useRef, ReactNode, useState, useEffect } from 'react';

import { Icon, ICONS, ICON_SIZES } from '~/components/Icon';

import {
  DropdownContainer,
  OptionContainer,
  Options,
  SliderAnimation,
  Wrapper,
  IconBlock,
  TextCol,
  Name,
  Description,
} from './design';

import { useOutsideClick } from '~/hooks/useOutsideClick';
import { COLORS } from '~/styles';

export interface IOption {
  name: ReactNode | string;
  key: string;
  onClick?: () => void;
  description?: ReactNode | string;
  iconGradient?: string;
  iconBlockColor?: string;
  iconColor?: string;
  icon: ICONS;
}

interface BaseDropdownProps {
  children: ReactNode;
  showDropdown: boolean;
  hideDropdown: () => void;
  items: IOption[];
}

const Dropdown = ({ items, children, showDropdown, hideDropdown }: BaseDropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const outsideClickRef = useOutsideClick<HTMLDivElement>(() => {
    hideDropdown();
  });
  const [height, setHeight] = useState<number | undefined>(0);
  const optionsRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setHeight(optionsRef?.current?.clientHeight);
  }, [optionsRef]);

  return (
    <Wrapper ref={outsideClickRef}>
      {children}
      <SliderAnimation $height={height || 180} $showDropdown={showDropdown}>
        <DropdownContainer ref={dropdownRef}>
          <Options ref={optionsRef}>
            {items.map((item, i) => (
              <OptionContainer
                key={i}
                $noBorderBottom={i === items.length - 1}
                onClick={item.onClick}
              >
                <IconBlock colors={item?.iconGradient} color={item?.iconBlockColor}>
                  {/* @ts-ignore */}
                  <Icon
                    icon={item?.icon}
                    size={ICON_SIZES.LARGE}
                    color={item.iconColor || COLORS.TOOLTIP_BACKGROUND}
                  />
                </IconBlock>
                <TextCol>
                  <Name>{item.name}</Name>
                  {item.description && <Description>{item.description}</Description>}
                </TextCol>
              </OptionContainer>
            ))}
          </Options>
        </DropdownContainer>
      </SliderAnimation>
    </Wrapper>
  );
};

export { Dropdown };
