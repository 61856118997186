import React from 'react';

import { Trans } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import DashboardCard from '~/components/DashboardCard';
import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';

import { FeedbacksSection } from './components/FeedbacksSection';
import GoalAlignment from './components/GoalAlignment';
import { UpdatesSection } from './components/UpdatesSection';
import UserGoalActivities from './components/UserGoalActivities';
import UserMainGoalInfo from './components/UserMainGoalInfo';

import ActivitiesIcon from '~/assets/collections_bookmark.svg';

import { COLOR_PALETTE } from '~/styles';

const SectionName = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin-top: 32px;
  margin-bottom: 24px;
`;

const StyledUserDashboardCard = styled(DashboardCard)`
  padding-bottom: 40px;
`;

const ActivityIcon = () => (
  <SvgIcon
    url={ActivitiesIcon}
    width={'50px'}
    height={'50px'}
    isDefaultColor
    defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
  />
);

const GoalDashboardCardOld = ({
  className,
  canUpdateActivityProgress,
  goal,
  loading,
  updateSidebar,
  width,
  isGoalPreview,
  updates,
  isShowRTFB,
  feedbacks,
}) => {
  return (
    <StyledUserDashboardCard className={className} width={width}>
      {!isEmpty(goal) && (
        <UserMainGoalInfo
          goal={goal}
          readOnly={!canUpdateActivityProgress}
          updateSidebar={updateSidebar}
        />
      )}
      {!isEmpty(goal) && <GoalAlignment goal={goal} />}
      {!isEmpty(goal.activities) ? (
        <UserGoalActivities
          loading={loading}
          activitiesReadonly={!canUpdateActivityProgress}
          activities={goal.activities}
          updateSidebar={updateSidebar}
        />
      ) : (
        <Placeholder
          Icon={ActivityIcon}
          title={<Trans>No activities available</Trans>}
          subTitle={<Trans>There are no activities available</Trans>}
        />
      )}
      {isGoalPreview && (
        <>
          <SectionName>
            <Trans>Updates</Trans>
          </SectionName>
          {!isEmpty(updates) ? (
            <UpdatesSection updates={updates} goalName={goal.name} />
          ) : (
            <Placeholder
              title={<Trans>No updates</Trans>}
              subTitle={<Trans>A log of your activity and goal updates will show here</Trans>}
            />
          )}
          {isShowRTFB && (
            <>
              <SectionName>
                <Trans>Feedback</Trans>
              </SectionName>
              {!isEmpty(feedbacks) ? (
                <FeedbacksSection feedbacks={feedbacks} />
              ) : (
                <Placeholder
                  title={<Trans>No feedback available</Trans>}
                  subTitle={<Trans>The feedback that you receive or give will show here</Trans>}
                />
              )}
            </>
          )}
        </>
      )}
    </StyledUserDashboardCard>
  );
};

export default React.memo(GoalDashboardCardOld);
