import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { StepSection } from './StepSection';
import { TemplateRow } from './TemplateRow';

import type { ISurvey, ISurveyTemplate } from '@learned/types';

const Wrapper = styled.div``;

interface ISectionTemplate {
  survey: ISurvey;
  onTemplatePreview: (item: ISurveyTemplate) => void;
}

const SectionTemplate = ({ survey, onTemplatePreview }: ISectionTemplate) => {
  const { i18n } = useLingui();
  const selectedTemplate = survey?.template as unknown as ISurveyTemplate;

  return (
    <StepSection title={i18n._(t`Template`)}>
      <Wrapper>
        {selectedTemplate ? (
          <TemplateRow item={selectedTemplate} onPreview={onTemplatePreview} />
        ) : (
          i18n._(t`Template is not selected. Please select template`)
        )}
      </Wrapper>
    </StepSection>
  );
};

export { SectionTemplate };
