import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { confirm } from '~/components/ConfirmDialog';
import Placeholder from '~/components/Placeholder';
import RickTextView from '~/components/RickTextView';
import ShowMore from '~/components/ShowMore';
import Skeleton, { TextBlock } from '~/components/Skeleton';
import SvgIcon from '~/components/SvgIcon';

import CertificateModal from './CertificateModal';
import EducationModal from './EducationModal';
import UserExperience from './UserExperience';
import UserProfileSection from './UserProfileSection';
import UserProfileSkills from './UserProfileSkills';
import WorkExpModal from './WorkExpModal.js';

import EducationIcon from '~/assets/education-icon.svg';
import CertificateIcon from '~/assets/main-menu-icons/cap.svg';
import BusinessIcon from '~/assets/work.svg';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import useDataStore from '~/hooks/useDataStore';
import {
  getUserCertificates,
  createCertificate,
  updateCertificate,
  deleteCertificate,
} from '~/services/certificates';
import {
  createEducation,
  updateEducation,
  deleteEducation,
  getUserEducations,
} from '~/services/educations';
import { createWorkExp, getUserWorkExps, deleteWorkExp, updateWorkExp } from '~/services/workExps';
import { fetchUserData } from '~/store/users/actions';
import { COLOR_PALETTE } from '~/styles';

const ContentWrapper = styled.div`
  margin-top: -16px;
`;

const Wrapper = styled.div`
  display: block;
  margin: 24px 32px 24px 26px;
`;

const UserProfile = ({
  user,
  readOnly,
  i18n,
  className,
  isPDF = false,
  cvSettings = {},
  finishSkillsLoading,
}) => {
  const dispatch = useDispatch();
  const $manageSkillsModal = useBoolState(false);
  const $manageWorkModal = useBoolState(false);
  const $manageEducationModal = useBoolState(false);
  const $manageCertificateModal = useBoolState(false);
  const [forceUpdateSkills, setForceUpdateSkills] = useState(true);
  const [workingExperience, setWorkingExperience] = useState({});
  const [education, setEducation] = useState({});
  const [certificate, setCertificate] = useState({});
  const history = useHistory();

  const $worksExps = useDataStore({
    startState: {
      loading: true,
    },
    fetch: () => getUserWorkExps(user.id),
    create: async (data) => {
      const createdWorkExp = await createWorkExp({
        ...data,
        skills: (data.skills || []).map((s) => s.id || s),
      });
      // fetch user data
      await dispatch(fetchUserData());
      setForceUpdateSkills(true);
      return createdWorkExp;
    },
    update: async (id, data) => {
      const updatedWorkExp = await updateWorkExp(id, {
        ...data,
        skills: (data.skills || []).map((s) => s.id || s),
      });
      // fetch user data
      await dispatch(fetchUserData());
      setForceUpdateSkills(true);
      return updatedWorkExp;
    },
    remove: deleteWorkExp,
  });
  const $educationsStore = useDataStore({
    startState: {
      loading: true,
    },
    fetch: () => getUserEducations(user.id),
    create: async (data) => {
      const createdEducation = await createEducation(data);

      // fetch user data
      await dispatch(fetchUserData());
      setForceUpdateSkills(true);
      return createdEducation;
    },
    update: async (id, data) => {
      const updatedEducation = await updateEducation(id, data);

      // fetch user data
      await dispatch(fetchUserData());
      setForceUpdateSkills(true);
      return updatedEducation;
    },
    remove: (id) => deleteEducation(id),
  });
  const $certificatesStore = useDataStore({
    startState: {
      loading: true,
    },
    fetch: () => getUserCertificates(user.id),
    create: async (data) => {
      const createdCertificate = await createCertificate(data);

      // fetch user data
      await dispatch(fetchUserData());
      setForceUpdateSkills(true);
      return createdCertificate;
    },
    update: async (updateData) => {
      const updatedCertificate = await updateCertificate(updateData.id, updateData.update);

      // fetch user data
      await dispatch(fetchUserData());
      setForceUpdateSkills(true);
      return updatedCertificate;
    },
    remove: (id) => deleteCertificate(id),
  });

  useEffect(() => {
    $worksExps.fetch();
    $certificatesStore.fetch();
    $educationsStore.fetch();

    // eslint-disable-next-line
  }, [user]);

  const handleUpdateWorkingExperience = (item) => {
    setWorkingExperience(item);
    $manageWorkModal.on();
  };

  const handleDeleteWorkingExperience = async (item) => {
    if (
      await confirm(
        i18n,
        i18n._(t`This will delete the working experience. This cannot be undone.`),
        { title: i18n._(t`Are you sure?`) },
      )
    ) {
      $worksExps.remove(item.id);
    }
  };

  const handleUpdateEducation = (item) => {
    setEducation(item);
    $manageEducationModal.on();
  };

  const handleDeleteEducation = async (item) => {
    if (
      await confirm(i18n, i18n._(t`This will delete the education. This cannot be undone.`), {
        title: i18n._(t`Are you sure?`),
      })
    ) {
      await $educationsStore.remove(item.id);
    }
  };

  const closeManageWorkModal = () => {
    $manageWorkModal.off();
    setWorkingExperience(null);
  };

  const closeManageEducationModal = () => {
    $manageEducationModal.off();
    setEducation({});
  };

  const handleUpdateCertificate = (item) => {
    setCertificate(item);
    $manageCertificateModal.on();
  };

  const handleDeleteCertificate = async (item) => {
    if (
      await confirm(i18n, i18n._(t`This will delete the certificate? This cannot be undone.`), {
        title: i18n._(t`Are you sure?`),
      })
    ) {
      $certificatesStore.remove(item.id);
    }
  };

  const closeManageCertificateModal = () => {
    $manageCertificateModal.off();
    setCertificate({});
  };

  return (
    <Wrapper className={className}>
      {(!isPDF || (isPDF && cvSettings.isAbout)) && (
        <UserProfileSection
          label={i18n._(t`About`)}
          action={() =>
            history.push(routes.PROFILE_SETTINGS.build({ role: ROLES.USER }, { isBackPath: true }))
          }
          actionLabel={i18n._(t`Edit`)}
          readOnly={readOnly}
          marginBottom={isPDF ? '18px' : '16px'}
          isAboutSection={!readOnly || isPDF}
        >
          {user && (
            <Skeleton
              ready={!!user.bio}
              customPlaceholder={
                <div>
                  <TextBlock rows={3} color="#d8e1ed" />
                </div>
              }
            >
              {isPDF ? (
                <RickTextView html={user.bio} />
              ) : (
                <ShowMore maxHeight={78}>
                  <RickTextView html={user.bio} />
                </ShowMore>
              )}
            </Skeleton>
          )}
        </UserProfileSection>
      )}
      {(!isPDF || (isPDF && cvSettings.isSkills)) && (
        <UserProfileSection
          label={i18n._(t`Skills`)}
          action={$manageSkillsModal.on}
          actionLabel={i18n._(t`Edit`)}
          marginBottom={'32px'}
          readOnly={readOnly}
          contentMarginTop={isPDF && '36px'}
        >
          <ContentWrapper>
            <UserProfileSkills
              user={user}
              $manageSkillsModal={$manageSkillsModal}
              forceUpdateSkills={forceUpdateSkills}
              updateLocalSkillsState={() => setForceUpdateSkills(true)}
              setForceUpdateSkills={setForceUpdateSkills}
              readOnly={readOnly}
              finishSkillsLoading={finishSkillsLoading}
            />
          </ContentWrapper>
        </UserProfileSection>
      )}
      {(!isPDF || (isPDF && !isEmpty(cvSettings.work))) && (
        <UserProfileSection
          label={i18n._(t`Work experience`)}
          action={$manageWorkModal.on}
          actionLabel={i18n._(t`Add`)}
          readOnly={readOnly}
          marginBottom={isPDF ? '16px' : '10px'}
          contentMarginTop={isPDF && '40px'}
        >
          {Object.values($worksExps.items).length > 0 ? (
            <UserExperience
              experience={
                isPDF
                  ? Object.values($worksExps.items).filter((i) => cvSettings.work.includes(i.id))
                  : Object.values($worksExps.items)
              }
              handleUpdate={handleUpdateWorkingExperience}
              handleDelete={handleDeleteWorkingExperience}
              readOnly={readOnly}
            />
          ) : (
            <Placeholder
              Icon={() => (
                <SvgIcon
                  url={BusinessIcon}
                  width={'48px'}
                  height={'48px'}
                  isDefaultColor
                  defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                />
              )}
              title={i18n._(readOnly ? t`No work experience` : t`Add your work experience`)}
              subTitle={i18n._(
                readOnly
                  ? t`This user has not added an work experience to his/her profile`
                  : t`Based on your experience we deduct your skills. Skills are used to match you with
                internal opportunities`,
              )}
            />
          )}
        </UserProfileSection>
      )}
      {(!isPDF || (isPDF && !isEmpty(cvSettings.education))) && (
        <UserProfileSection
          label={i18n._(t`Education`)}
          action={$manageEducationModal.on}
          actionLabel={i18n._(t`Add`)}
          readOnly={readOnly}
          marginBottom={isPDF ? '16px' : '10px'}
          contentMarginTop={isPDF && '40px'}
        >
          {Object.values($educationsStore.items).length > 0 ? (
            <UserExperience
              experience={
                isPDF
                  ? Object.values($educationsStore.items).filter((i) =>
                      cvSettings.education.includes(i.id),
                    )
                  : Object.values($educationsStore.items)
              }
              handleUpdate={handleUpdateEducation}
              handleDelete={handleDeleteEducation}
              readOnly={readOnly}
            />
          ) : (
            <Placeholder
              Icon={() => (
                <SvgIcon
                  url={EducationIcon}
                  width={'48px'}
                  height={'48px'}
                  isDefaultColor
                  defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                />
              )}
              title={i18n._(readOnly ? t`No educational experience` : t`Add your education`)}
              subTitle={i18n._(
                readOnly
                  ? t`This user has not added any educational experience to his/her profile`
                  : t`Based on your education we deduct your skills. Skills are used to match you with internal opportunities`,
              )}
            />
          )}
        </UserProfileSection>
      )}
      {(!isPDF || (isPDF && !isEmpty(cvSettings.certificates))) && (
        <UserProfileSection
          label={i18n._(t`Certificates`)}
          action={$manageCertificateModal.on}
          actionLabel={i18n._(t`Add`)}
          readOnly={readOnly}
          noMarginBottom
          contentMarginTop={isPDF && '40px'}
        >
          {Object.values($certificatesStore.items).length > 0 ? (
            <UserExperience
              experience={
                isPDF
                  ? Object.values($certificatesStore.items).filter((i) =>
                      cvSettings.certificates.includes(i.id),
                    )
                  : Object.values($certificatesStore.items)
              }
              handleUpdate={handleUpdateCertificate}
              handleDelete={handleDeleteCertificate}
              readOnly={readOnly}
            />
          ) : (
            <Placeholder
              Icon={() => (
                <SvgIcon
                  url={CertificateIcon}
                  width={'48px'}
                  height={'48px'}
                  isDefaultColor
                  defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                />
              )}
              title={i18n._(readOnly ? t`No certificates` : t`Add your certificates`)}
              subTitle={i18n._(
                readOnly
                  ? t`This user has not added an certificates to his/her profile`
                  : t`Based on your certificates we derive your skills. Skills are used to match you with internal opportunities`,
              )}
            />
          )}
        </UserProfileSection>
      )}
      {$manageWorkModal.value && (
        <WorkExpModal
          onClose={closeManageWorkModal}
          onCreate={$worksExps.create}
          onUpdate={$worksExps.update}
          current={workingExperience}
        />
      )}
      {$manageEducationModal.value && (
        <EducationModal
          itemId={education.id}
          onCreate={$educationsStore.create}
          onUpdate={$educationsStore.update}
          onClose={closeManageEducationModal}
        />
      )}
      {$manageCertificateModal.value && (
        <CertificateModal
          itemId={certificate.id}
          onCreate={$certificatesStore.create}
          onUpdate={(id, update) => $certificatesStore.update({ id, update })}
          onClose={closeManageCertificateModal}
        />
      )}
    </Wrapper>
  );
};

export default withI18n()(UserProfile);
