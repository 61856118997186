import React, { ReactNode } from 'react';

import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import WarningIcon from '~/assets/warning.svg';

const WarningContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ChildrenContainer = styled.div`
  display: flex;
  margin-left: 16px;
`;

function GenericWarning({ children, className }: { children: ReactNode; className?: string }) {
  return (
    <WarningContainer className={className}>
      <SvgIcon url={WarningIcon} isDefaultColor defaultColor={'#EE404C'} />
      <ChildrenContainer>{children}</ChildrenContainer>
    </WarningContainer>
  );
}

export { GenericWarning };
