import React, { useEffect, useRef, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled, { css } from 'styled-components';

import Tooltip from '~/components/Tooltip';

import { COLORS } from '~/styles';

const Name = styled.div<{
  isGrayColor?: boolean;
}>`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  ${({ isGrayColor }) =>
    isGrayColor &&
    css`
      color: ${COLORS.SUBTEXT};
    `}
`;

const SubText = styled.div<{
  isGrayColor?: boolean;
}>`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: -0.13px;
  ${({ isGrayColor }) =>
    isGrayColor &&
    css`
      color: ${COLORS.SUBTEXT};
    `}
`;

const Wrap = styled.div<{ isLeftMargin?: boolean }>`
  margin-left: ${(props) => (props.isLeftMargin ? '15px' : '0px')};
`;

interface INameWithQuestions {
  name: string;
  questionsTotal?: number | undefined;
  isGrayColor?: boolean;
  isSubTextGrayColor?: boolean;
  isLeftMargin?: boolean;
  isTooltipOnLongName?: boolean;
}

const NameWithQuestions = ({
  name,
  questionsTotal,
  isGrayColor,
  isSubTextGrayColor,
  isLeftMargin = true,
  isTooltipOnLongName,
}: INameWithQuestions) => {
  const [overflowActive, setOverflowActive] = useState<boolean>(false);
  const overflowingText = useRef(null);
  const checkOverflow = (textContainer: HTMLSpanElement | null): boolean => {
    if (textContainer) {
      return (
        textContainer.offsetHeight < textContainer.scrollHeight ||
        textContainer.offsetWidth < textContainer.scrollWidth
      );
    }
    return false;
  };
  useEffect(() => {
    if (checkOverflow(overflowingText.current)) {
      setOverflowActive(true);
      return;
    }

    setOverflowActive(false);
  }, [overflowActive]);

  const { i18n } = useLingui();
  const content = (
    <Wrap isLeftMargin={isLeftMargin}>
      <Name ref={overflowingText} isGrayColor={isGrayColor}>
        {name}
      </Name>
      {questionsTotal !== undefined && (
        <SubText isGrayColor={isGrayColor || isSubTextGrayColor}>
          {i18n._(t`Includes ${questionsTotal} questions`)}
        </SubText>
      )}
    </Wrap>
  );
  return overflowActive && isTooltipOnLongName ? (
    <Tooltip tooltip={name}>{content}</Tooltip>
  ) : (
    content
  );
};

export { NameWithQuestions };
