import styled from 'styled-components';

import { StyledTop } from '~/components/TableList/design';

import { COLORS } from '~/styles';

import { Button } from '../Buttons';
import { TableList } from '../TableList';

export const Header = styled.div`
  display: flex;
  flex-direction: row;

  font-size: 26px;
  line-height: 1;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
  border-bottom: solid 1px ${COLORS.BORDERS};
  padding-bottom: 21px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;

export const StyledButton = styled(Button)`
  box-shadow: 0 2px 5px 0 rgba(145, 157, 165, 0.12);
`;

export const StyledTableList = styled(TableList)`
  padding: 0;

  ${StyledTop} {
    padding: 20px 32px 0;
  }

  table tbody {
    display: block;
    max-height: 360px;
    overflow-y: scroll;
  }

  table thead,
  table tbody tr {
    display: table;
    width: 100%;
    table-layout: auto;
  }
`;
