import { useState } from 'react';

import find from 'lodash/find';

export default function useListState(initialState = [], { unique = true } = {}) {
  const [items, setList] = useState(initialState);

  function includes(item) {
    return items.indexOf(item) !== -1;
  }
  function findItem(searchFn) {
    return find(items, searchFn);
  }

  function remove(rule) {
    let item = typeof rule === 'function' ? findItem(rule) : rule;
    const newList = items.filter((i) => i !== item);
    if (items.length !== newList.length) {
      setList(newList);
    }
  }

  return {
    items,
    includes,
    find: findItem,
    push(item) {
      if (!unique || !includes(item)) {
        setList((items) => items.concat([item]));
      }
    },
    reset() {
      setList([]);
    },
    update(item, newItem) {
      const check = (i) => (typeof item === 'function' ? item(i) : item === i);
      setList((items) =>
        items.map((i) => {
          if (check(i)) {
            return typeof newItem === 'function' ? newItem(i) : newItem;
          }
          return i;
        }),
      );
    },
    remove,
    removeBy(itemId, key = 'id') {
      remove((i) => i[key] === itemId);
    },
    set: setList,
  };
}
