import { ProductName } from '@learned/constants';
import { t } from '@lingui/macro';

import type { I18n } from '@lingui/core';

export const PRODUCTS_WITH_NAMES_UI = [
  {
    key: ProductName.JOB_MATRIX,
    name: (i18n: I18n) => i18n._(t`Job matrix`),
  },
  {
    key: ProductName.CAREER,
    name: (i18n: I18n) => i18n._(t`Career`),
  },
  {
    key: ProductName.ENGAGEMENT,
    name: (i18n: I18n) => i18n._(t`Engagement surveys`),
  },
  {
    key: ProductName.INTEGRATIONS,
    name: (i18n: I18n) => i18n._(t`Integrations`),
  },
  {
    key: ProductName.LEARNING_AND_ONBOARDING,
    name: (i18n: I18n) => i18n._(t`Onboarding & Learning`),
  },
  {
    key: ProductName.PERFORMANCE,
    name: (i18n: I18n) => i18n._(t`Performance management`),
  },
];
