import React, { useState } from 'react';

import { t } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';

import { HeaderTabs } from '~/components/HeaderTabs';

import { FeedbackCard } from './components/FeedbackCard';
import { GoalGeneralBlock } from './components/GoalGeneralBlock';
import { GoalProgressCard } from './components/GoalsProgressCard';
import { SettingsCard } from './components/SettingsCard';
import { UpdatesCard } from './components/UpdatesCard';
import { DashboardWrapper, Card, HeaderWrapper } from './design';

import useBoolState from '~/hooks/useBoolState';

export const TABS_ENUM = {
  SETTINGS: 'settings',
  UPDATES: 'updates',
  FEEDBACK: 'feedback',
};

const GoalDashboardCard = ({
  goal,
  isShowRTFB,
  isOwner,
  isUpdatesAndNotes = false,
  isOpenAsModal = false,
}) => {
  const [currentTab, setCurrentTab] = useState(
    isUpdatesAndNotes ? TABS_ENUM.UPDATES : TABS_ENUM.SETTINGS,
  );
  const $isUpdateSidebar = useBoolState(false);
  const TABS = [
    {
      label: (i18n) => i18n._(t`Settings`),
      key: TABS_ENUM.SETTINGS,
    },
    {
      label: (i18n) => i18n._(t`Updates`),
      key: TABS_ENUM.UPDATES,
    },
    isShowRTFB && {
      label: (i18n) => i18n._(t`Feedback`),
      key: TABS_ENUM.FEEDBACK,
    },
  ].filter((t) => t);

  const updateSidebar = (value) => {
    if (value) {
      $isUpdateSidebar.on();
    } else {
      $isUpdateSidebar.off();
    }
  };

  return (
    !isEmpty(goal) && (
      <DashboardWrapper>
        <GoalGeneralBlock goal={goal} isOwner={isOwner} noTopMargin={isOpenAsModal} />
        <Card marginTop="0px" noFrontPadding={isOpenAsModal}>
          <GoalProgressCard goal={goal} />
        </Card>
        <Card noFrontPadding={isOpenAsModal}>
          <HeaderWrapper>
            <HeaderTabs tabs={TABS} selectedTab={currentTab} handleChangeTab={setCurrentTab} />
          </HeaderWrapper>
          {currentTab === TABS_ENUM.SETTINGS && <SettingsCard goal={goal} />}
          {currentTab === TABS_ENUM.UPDATES && (
            <UpdatesCard
              goal={goal}
              isUpdateSidebar={$isUpdateSidebar.value}
              updateSidebar={updateSidebar}
            />
          )}
          {currentTab === TABS_ENUM.FEEDBACK && <FeedbackCard isOwner={isOwner} goal={goal} />}
        </Card>
      </DashboardWrapper>
    )
  );
};

export default React.memo(GoalDashboardCard);
