import React from 'react';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import DefaultSlider from 'rc-slider';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';
import Tooltip from '~/components/Tooltip';
import { Body14 } from '~/components/UI/Typographics/text';

import { COLOR_PALETTE } from '~/styles';

const DefaultSliderStyled = styled(DefaultSlider)`
  background-color: unset;
  border-radius: 6px;

  & .rc-slider-track {
    width: ${(props) => props.$trackWidth}% !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    ${(props) => (props.$isTrackOnFullWidth ? 'border-radius: 6px;' : '')}
    background: #b6c5e4;
    height: 10px;
  }

  & .rc-slider-rail {
    background: #ebf1fe;
    height: 10px;
    border-radius: 12px;
    border: 1px solid #dbe1ef;
  }

  & .rc-slider-handle {
    width: 7px;
    height: 22px;
    background: ${(props) => props.$color};
    border: none;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 2.9px;
  }

  & .rc-slider-dot {
    display: none;
  }
`;

const Label = styled.div`
  position: relative;
  top: -46px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};

  // cut with ...
  max-width: ${(props) => props.$maxWidth + 'px'};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const CheckBoxBlock = styled.div`
  display: flex;
  margin-left: 25px;
`;

export const CheckBoxLabel = styled(Body14)`
  margin-left: 11px;
`;

const maxWidthOptions = {
  5: 100,
  6: 90,
  7: 80,
  8: 70,
  9: 60,
  10: 50,
};

const RatingSlider = ({
  value,
  valueExpected,
  onChange,
  scale,
  scaleLabels,
  isHideLabels,
  isDisabled,
  color,
  isSkippable,
  isSkipped,
  isSkippedDisabled = false,
  onChangeSkipped,
  isOnlySelectedLabel,
}) => {
  const valueIndex = scale.indexOf(value);
  const valuePercentage = Math.round(((valueIndex + 1) / scale.length) * 100);
  const maxWidth = maxWidthOptions[scale?.length] || 100;
  const marks = scale.reduce(
    (obj, value, index) => {
      const progress = Math.round(((index + 1) / scale.length) * 100);
      const isSelected = valuePercentage === progress;
      return {
        ...obj,
        [value]:
          isHideLabels || (isOnlySelectedLabel && !isSelected) ? (
            ''
          ) : (
            <Tooltip tooltip={scaleLabels[index]}>
              <Label $maxWidth={maxWidth}>{scaleLabels[index]}</Label>
            </Tooltip>
          ),
      };
    },
    {
      0: '', // no value position
    },
  );

  const min = 0;
  const max = scale[scale.length - 1];
  const valueExpectedIndex = scale.indexOf(valueExpected);
  const expectedPercentage = ((valueExpectedIndex + 1) / scale.length) * 100;

  return (
    <Row>
      <DefaultSliderStyled
        value={value}
        defaultValue={0}
        min={min}
        max={max}
        step={null}
        onChange={onChange}
        disabled={isDisabled}
        marks={marks}
        dots={false}
        $trackWidth={expectedPercentage}
        $isTrackOnFullWidth={valueExpected === max}
        $color={color}
      />
      {isSkippable && (
        <CheckBoxBlock>
          <CheckBox
            disabled={isSkippedDisabled}
            checked={isSkipped}
            onChange={onChangeSkipped}
            size={24}
          />
          <CheckBoxLabel>
            <Trans>N/A</Trans>
          </CheckBoxLabel>
        </CheckBoxBlock>
      )}
    </Row>
  );
};

RatingSlider.propTypes = {
  value: PropTypes.number,
  valueExpected: PropTypes.number,
  onChange: PropTypes.func,
  scale: PropTypes.arrayOf(PropTypes.number),
  scaleLabels: PropTypes.arrayOf(PropTypes.string),
  isHideLabels: PropTypes.bool,
  isDisabled: PropTypes.bool,
  color: PropTypes.string,
  isSkippable: PropTypes.bool,
  isSkipped: PropTypes.bool,
  isSkippedDisabled: PropTypes.bool,
  onChangeSkipped: PropTypes.func,
  isOnlySelectedLabel: PropTypes.bool,
};

export default RatingSlider;
