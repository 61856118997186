import React from 'react';

import styled from 'styled-components';

import { ColorPicker } from './ColorPicker';
import { IconPicker } from './IconPicker';

import { COLOR_PALETTE } from '~/styles';

import type { IGeneralForm } from '../types';
import type { UseFormReturn } from 'react-hook-form';

interface IconColorPickerProps {
  formMethods: UseFormReturn<IGeneralForm>;
}

const Wrapper = styled.div`
  display: flex;
  gap: 7px;
  padding: 9px 21px;
  border: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  border-radius: 10px;
  background-color: ${COLOR_PALETTE.WHITE};
  z-index: 100;
`;

const IconColorPicker = ({ formMethods }: IconColorPickerProps) => {
  return (
    <Wrapper>
      <IconPicker formMethods={formMethods} />
      <ColorPicker formMethods={formMethods} />
    </Wrapper>
  );
};

export { IconColorPicker };
