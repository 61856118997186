import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from '~/components/Button';
import CleanUpTeamsModal from '~/components/CleanUpTeamsModal';
import CreateNewTeamModal from '~/components/CreateNewTeamModal';
import DeleteModal from '~/components/DeleteModal';
import DropdownButton from '~/components/DropdownButton';
import FiltersHeading from '~/components/FiltersHeading';
import HeadingNavigation from '~/components/HeadingNavigation';
import ExpandMoreIcon from '~/components/Icons/ExpandMore';
import ImportTeamsModal from '~/components/ImportTeamsModal';
import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';
import SyncTeamsModal from '~/components/SyncTeamsModal';
import ActionsContainer from '~/components/UI/ActionsContainer';
import TableCard from '~/components/UI/TableCard';
import BaseLayout from '~/layouts/BaseLayout';

import TeamItem from './components/TeamItem';
import TeamOptions from './components/TeamOptions';

import TeamsIcon from '~/assets/mdi-account-group.svg';

import { INSTRUCTIONS } from '~/constants/instructions';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getTeams } from '~/services/teams';
import { removeTeams, updateTeams } from '~/store/teams/actions';
import { COLOR_PALETTE } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';
import getModulesStatuses from '~/utils/getModulesStatuses';
import sortCollectionByName from '~/utils/sortCollectionByName';

import { getCompanyIntegrationSettings } from '../../services/integrationSettings';

class Teams extends Component {
  constructor() {
    super();
    this.state = {
      teams: {},
      integrationSettings: {},
      teamToDelete: null,
      loading: true,
      parentTeam: null,
      isShowCreateNewTeamModal: false,
      showDeleteModal: false,
      isShowImportTeamsModal: false,
      isShowCleanUpTeamsModal: false,
      modulesStatuses: {},
    };
  }

  handleFiltersChange = (filter) => {
    this.setState({ teamsFilter: filter });
  };

  componentDidMount() {
    getTeams().then((teams) => {
      const sortedTeams = sortCollectionByName(teams);
      this.setState({ teams: sortedTeams, loading: false });
    });
    getCompanyIntegrationSettings().then((integrationSettings) => {
      this.setState({
        integrationSettings,
        modulesStatuses: getModulesStatuses(integrationSettings),
      });
    });
  }

  showNewTeamModal = (parentTeam) => {
    const newState = { isShowCreateNewTeamModal: true, teamToUpdate: {} };
    if (parentTeam) {
      newState.parentTeam = parentTeam;
    }
    this.setState(newState);
  };

  hideNewTeamModal = (createdTeams = []) => {
    const { teams } = this.state;
    const newState = { isShowCreateNewTeamModal: false };

    if (!isEmpty(createdTeams)) {
      const newTeams = { ...teams };
      createdTeams.forEach((team) => (newTeams[team.id] = team));
      const sortedTeams = sortCollectionByName(newTeams);
      newState.teams = sortedTeams;
    }
    this.setState(newState);
  };

  hideImportTeamsModal = (importedTeams = []) => {
    const { teams } = this.state;
    const newState = { isShowImportTeamsModal: false };

    if (!isEmpty(importedTeams)) {
      const newTeams = { ...teams };
      importedTeams.forEach((team) => (newTeams[team.id] = team));
      const sortedTeams = sortCollectionByName(newTeams);
      newState.teams = sortedTeams;
    }
    this.setState(newState);
  };

  showUpdateTeamModal = (team) => {
    this.setState({
      isShowCreateNewTeamModal: true,
      teamToUpdate: team,
    });
  };

  showImportTeamModal = () => {
    this.setState({ isShowImportTeamsModal: true });
  };

  showSyncTeamModal = () => {
    this.setState({ isShowSyncTeamsModal: true });
  };

  showCleanupTeamModal = () => {
    this.setState({ isShowCleanUpTeamsModal: true });
  };

  deleteTeams = async (teamsToDelete) => {
    const { dispatch } = this.props;
    const { teams } = this.state;
    if (!isEmpty(teamsToDelete)) {
      this.setState({ loading: true });
      await dispatch(removeTeams(teamsToDelete));

      const newTeams = { ...teams };
      teamsToDelete.forEach((deleteTeam) => delete newTeams[deleteTeam.id]);

      this.setState({
        teams: newTeams,
        showDeleteModal: false,
        isShowCleanUpTeamsModal: false,
        isShowSyncTeamsModal: false,
        loading: false,
      });
    }
  };

  // for now we update only names
  updateTeams = async (teamsToUpdate) => {
    const { dispatch } = this.props;
    const { teams } = this.state;

    if (!isEmpty(teamsToUpdate)) {
      this.setState({ loading: true });
      const preparedTeams = [];
      teamsToUpdate.forEach((t) => preparedTeams.push([t.teamId, { name: t.newValue }]));

      // update DB + redux-store
      const updatedTeams = await dispatch(updateTeams(preparedTeams));

      // update state
      const stateTeams = { ...teams };
      updatedTeams.forEach((t) => (stateTeams[t.id] = t));

      this.setState({
        teams: stateTeams,
        isShowSyncTeamsModal: false,
        loading: false,
      });
    }
  };

  toggleDeleteModal = (team) => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal,
      teamToDelete: team ? team : null,
    });
  };

  renderCompanyTeam = (team, teamLevel) => {
    const { teams, integrationSettings } = this.state;
    const { i18n } = this.props;

    // menuItems
    const menuItems = [
      {
        label: i18n._(t`Rename`),
        action: () => this.showUpdateTeamModal(team),
      },
      {
        label: i18n._(t`Add Sub Team`),
        action: () => this.showNewTeamModal(team),
      },
      {
        label: i18n._(t`Remove`),
        action: () => this.toggleDeleteModal(team),
      },
    ];

    // check child team
    const childTeams = [];

    for (let teamId in teams) {
      if ({}.hasOwnProperty.call(teams, teamId)) {
        const childTeam = teams[teamId];
        if (!isEmpty(childTeam) && String(childTeam.parent) === String(team.id)) {
          childTeams.push(childTeam);
        }
      }
    }

    const inactiveUsersList = Object.values(this.props?.inactiveUsers).map((user) => user.id);
    return (
      <TeamItem
        key={team.id}
        team={team}
        menuItems={menuItems}
        teamLevel={teamLevel}
        integrationSettings={integrationSettings}
        inactiveUsers={inactiveUsersList}
      >
        {!isEmpty(childTeams) &&
          childTeams.map((team) => this.renderCompanyTeam(team, teamLevel + 1))}
      </TeamItem>
    );
  };

  getCols = (i18n) => {
    return [
      {
        title: i18n._(t`Team`),
        width: '650px',
      },
      {
        title: i18n._(t`Created`),
      },
      {
        title: i18n._(t`Coaches`),
        width: '120px',
      },
      {
        title: i18n._(t`Members`),
        width: '120px',
      },
      {
        title: '',
        width: '50px',
      },
    ];
  };

  render() {
    const { i18n, currentCompany } = this.props;
    const {
      parentTeam,
      isShowCreateNewTeamModal,
      isShowImportTeamsModal,
      isShowCleanUpTeamsModal,
      isShowSyncTeamsModal,
      teams,
      teamToUpdate,
      teamToDelete,
      loading,
      showDeleteModal,
      teamsFilter,
    } = this.state;

    let companyTeams = Object.values(teams || []).filter((team) => !team.parent);

    if (teamsFilter) {
      companyTeams = companyTeams.filter(teamsFilter);
    }
    const teamLevel = 0;

    return (
      <>
        <HeadingNavigation
          label={i18n._(t`Teams`)}
          description={i18n._(t`An overview of all teams`)}
          instructions={i18n._(t`How teams work`)}
          instructionsUrl={getInstructionUrl(INSTRUCTIONS.HOW_TEAMS_WORK_ADMIN)}
          actions={
            <DropdownButton
              minWidth={248}
              maxWidth={526}
              popoverRef={this.popoverRef}
              content={
                <TeamOptions
                  createTeam={this.showNewTeamModal}
                  importTeams={this.showImportTeamModal}
                  synchroniseTeams={this.showSyncTeamModal}
                  cleanupTeams={this.showCleanupTeamModal}
                  isDeleteTeamsEnabled={this.state.modulesStatuses.isDeleteTeamsEnabled}
                  isImportTeamsEnabled={this.state.modulesStatuses.isImportTeamsEnabled}
                  isSyncTeamsEnabled={this.state.modulesStatuses.isSyncTeamsEnabled}
                />
              }
            >
              <Button
                label={
                  <>
                    {i18n._(t`Options`)}
                    <ExpandMoreIcon fill="#fff" />
                  </>
                }
              />
            </DropdownButton>
          }
        />
        <BaseLayout>
          <ActionsContainer noBottomBorder>
            <FiltersHeading onFilterChange={this.handleFiltersChange} />
          </ActionsContainer>
          {companyTeams && companyTeams.length > 0 ? (
            <TableCard
              hideHeader={true}
              firstPlaceholder={i18n._(t`You have not created your first team yet.`)}
              cols={this.getCols(i18n)}
              items={companyTeams}
              loading={loading}
              renderRow={(team) => this.renderCompanyTeam(team, teamLevel)}
              noTopBorder
            />
          ) : (
            <Placeholder
              Icon={() => (
                <SvgIcon
                  url={TeamsIcon}
                  width={'50px'}
                  height={'50px'}
                  isDefaultColor
                  defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                />
              )}
              title={i18n._(t`No teams available`)}
              subTitle={i18n._(t`There are no teams created yet`)}
            />
          )}
        </BaseLayout>

        {showDeleteModal && (
          <DeleteModal
            title="team"
            content={i18n._(t`Do you really want to remove this team and all teams in this team?`)}
            onClose={() => this.toggleDeleteModal()}
            loading={loading}
            onDelete={() => this.deleteTeams([teamToDelete])}
          />
        )}

        {isShowCreateNewTeamModal && (
          <CreateNewTeamModal
            onModalClose={this.hideNewTeamModal}
            company={currentCompany}
            currentTeam={teamToUpdate}
            parentTeam={parentTeam}
            teams={Object.values(teams || {})}
          />
        )}

        {isShowImportTeamsModal && (
          <ImportTeamsModal onClose={this.hideImportTeamsModal} teams={Object.values(teams)} />
        )}
        {isShowCleanUpTeamsModal && (
          <CleanUpTeamsModal
            teams={teams}
            onClose={() => this.setState({ isShowCleanUpTeamsModal: false })}
            onSubmit={this.deleteTeams}
          />
        )}

        {isShowSyncTeamsModal && (
          <SyncTeamsModal
            onClose={() => this.setState({ isShowSyncTeamsModal: false })}
            onSubmit={this.updateTeams}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companies: state.companies.data,
    currentCompany: getCurrentCompany(state),
    inactiveUsers: state.users.inactive,
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(Teams)));
