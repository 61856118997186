import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  background-color: ${COLORS.WHITE};
  max-width: 1000px;
  border-radius: 0 10px 10px 10px;
  padding: 30px 34px 21px;
`;

export const Title = styled.span`
  font-size: 16px;
  color: ${COLORS.TEXT_HOVER};
  padding: 0;
  margin-bottom: 5px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`;

export const Clickable = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Link = styled.a`
  text-decoration: none;
  font-size: 14px;
  text-align: center;
  color: var(--company-color);
`;

export const ActionItemBlock = styled.div`
  display: flex;
  align-items: center;
  border-left: solid 1.2px ${COLORS.BORDER_HARD};
  justify-content: center;
  padding-left: 17px;
  padding-right: 17px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TippyName = styled.div`
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 12px;
  text-align: center;
  padding: 7px 12px 7px 12px;
`;

export const Dash = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;

  & .icon {
    opacity: 0.33;
  }
`;
