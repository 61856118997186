import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import BoxWithBorder from '~/components/UI/BoxWithBorder';

import { Reviews } from './Reviews';

import routes from '~/constants/routes';
import { checkModuleReviews } from '~/selectors/baseGetters';

import type { IUser } from '@learned/types';

const TabContainer = styled.div`
  margin: 19px 39px;

  .user-public-conversations_header {
    margin-bottom: 24px;
  }
`;

const ReviewsTab = ({ user }: { user: IUser }) => {
  const history = useHistory();
  const isModuleReviewsEnabled = useSelector(checkModuleReviews);

  useEffect(() => {
    if (!isModuleReviewsEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleReviewsEnabled, history]);

  return (
    <BoxWithBorder>
      <TabContainer>
        <Reviews userTempId={user.id} />
      </TabContainer>
    </BoxWithBorder>
  );
};

export default ReviewsTab;
