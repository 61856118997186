import { serverRoutes } from '~/server_config';

import cloudRequest from '../cloudRequest';

export function getSuperAdmins() {
  return cloudRequest(serverRoutes.superAdmin.getSuperAdmins);
}

export function updateSuperAdminStatus(userId, isSuperAdmin) {
  return cloudRequest(serverRoutes.superAdmin.updateSuperAdminStatus, { userId }, { isSuperAdmin });
}
