import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import { createSelector } from 'reselect';

import { getUsers, getUser } from '~/selectors/baseGetters';
import getCoachTeams from '~/selectors/getCoachTeams';
import getTeamMembers from '~/utils/getTeamMembers';

export default createSelector([getCoachTeams, getUsers, getUser], getCoachUsers);

function getCoachUsers(teams, users, currentUser) {
  let members = [];
  map(teams, (team) => {
    const teamMembers = getTeamMembers(users, team);
    members = uniqBy(members.concat(teamMembers)).filter((m) => m.id !== currentUser.id);
  });
  return members;
}
