import React, { Fragment } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { CloseIconButton, TextBlock, TextBox } from '~/components/Activity/base_components';
import Button from '~/components/Button';
import SkillName from '~/components/Skill/SkillName';
import SvgIcon from '~/components/SvgIcon';
import { FieldMediumTitle, FieldGroup } from '~/components/Text';

import closeIcon from '~/assets/close-icn.svg';

import type { ISkill, IActivityCategory } from '@learned/types';

interface ISkillsAndCategories {
  selectedSkills: ISkill[];
  selectedCategories: IActivityCategory[];
  unSelectSkill: (skill: ISkill) => void;
  showSkillsModal: () => void;
  unSelectCategory: (category: IActivityCategory) => void;
  showCategoryModal: () => void;
}

const SkillsAndCategories = ({
  selectedSkills,
  unSelectSkill,
  showSkillsModal,
  selectedCategories,
  unSelectCategory,
  showCategoryModal,
}: ISkillsAndCategories) => {
  const { i18n } = useLingui();

  return (
    <Fragment>
      {/* @ts-ignore */}
      <FieldGroup marginBottom="33px">
        <FieldMediumTitle>
          <Trans>Relevant skills</Trans>
        </FieldMediumTitle>
        {selectedSkills &&
          selectedSkills.map((skill) => {
            return (
              <TextBox key={skill.id}>
                <SkillName skill={skill} />
                <CloseIconButton onClick={() => unSelectSkill(skill)}>
                  <SvgIcon
                    width="16px"
                    height="16px"
                    url={closeIcon}
                    // @ts-ignore
                    alt={i18n._(t`delete`)}
                  />
                </CloseIconButton>
              </TextBox>
            );
          })}
        <Button
          type="primary-border"
          onClick={showSkillsModal}
          styles={{
            marginTop: '16px',
            height: '48px',
            width: '100%',
          }}
          label={'+ ' + i18n._(t`Skills`)}
        />
      </FieldGroup>
      {/* @ts-ignore */}
      <FieldGroup marginBottom="33px">
        <FieldMediumTitle>
          <Trans>Categories</Trans>
        </FieldMediumTitle>
        {selectedCategories &&
          selectedCategories.map((category) => {
            return (
              <TextBox key={category.id}>
                <TextBlock>{category.name}</TextBlock>
                <CloseIconButton onClick={() => unSelectCategory(category)}>
                  <SvgIcon
                    width="16px"
                    height="16px"
                    url={closeIcon}
                    // @ts-ignore
                    alt={i18n._(t`delete`)}
                  />
                </CloseIconButton>
              </TextBox>
            );
          })}
        <Button
          type="primary-border"
          onClick={showCategoryModal}
          styles={{
            marginTop: '16px',
            height: '48px',
            width: '100%',
          }}
          label={'+ ' + i18n._(t`Category`)}
        />
      </FieldGroup>
    </Fragment>
  );
};

export { SkillsAndCategories };
