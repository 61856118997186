import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 32px;
  margin-top: 46px;
`;

export const Header = styled.div`
  width: 1040px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;

  & > span {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.24px;
    color: ${COLORS.TEXT_HOVER};
    flex: auto;
  }
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 15% 85%;

  .placeholder:nth-last-child(2) .vertical-line {
    height: calc(50% - 52px);
  }

  .placeholder:first-child .horizontal-line {
    top: 52px;
    background-image: linear-gradient(to right, ${COLORS.PLACEHOLDERS} 100%, transparent 0%);
    background-position: top;
    background-size: 10px 2px;
    background-repeat: repeat-x;
  }
`;

export const UserAvatarWrapper = styled.div`
  height: auto;
  position: relative;
`;

export const UserAvatar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  & > img {
    width: 105px;
    height: 105px;
    border-radius: 50%;
  }
`;

export const VerticalLine = styled.div`
  z-index: 0;
  position: absolute;
  left: 52px;
  top: 10px;
  height: 100%;
  width: 2px;
  background-image: linear-gradient(to bottom, ${COLORS.PLACEHOLDERS} 50%, transparent 0%);
  background-repeat: repeat-y;
  background-position: left;
  background-size: 2px 6px;
`;

export const HorizontalLine = styled.div<{ top?: number }>`
  background-image: linear-gradient(to right, ${COLORS.PLACEHOLDERS} 20%, transparent 0%);
  background-repeat: repeat-x;
  background-position: top;
  background-size: 10px 2px;
  z-index: 0;
  position: absolute;
  left: 52px;
  top: calc(50% - ${({ top }) => (top ? top : 20)}px);
  height: 2px;
  width: 100%;
`;

export const Content = styled.div`
  height: auto;
  padding-bottom: 84px;
  z-index: 1;
`;
