import React, { useState } from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import Button from '~/components/Button';
import { Body } from '~/components/GiveFeedback/components/Section/components/ReviewGoalQuestion/Body';
import { Header } from '~/components/GiveFeedback/components/Section/components/ReviewGoalQuestion/Header';
import { GoalsOverview } from '~/components/Modals/GoalsOverview';
import { TemplateCardWithFooter } from '~/components/SetupTools/components/TemplateBaseComponents';
import Tabs from '~/components/Tabs';

import { GOAL_TYPES, RATING_TYPES, REVIEW_QUESTIONS_TABS } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { COLOR_SET, COLORS } from '~/styles';

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.$spaceBetween ? 'space-between' : 'flex-end')};
  border-bottom: 1px solid ${COLORS.BORDER_HARD};
`;

const StyledButton = styled(Button)`
  height: 32px;
  margin: 9px 24px;
  font-weight: bold;
  border-color: ${COLOR_SET.MIDNICHT_BLUE};
  color: ${COLOR_SET.MIDNICHT_BLUE};

  &:hover {
    background-color: ${COLOR_SET.MIDNICHT_BLUE};
  }
`;

const Container = styled.div`
  border-top: solid 1px ${COLORS.BG_PAGE};
`;

const ReviewGoalAverageQuestion = React.memo(
  ({
    isSelf,
    ratings,
    fetchedRatings,
    question,
    onChange,
    review,
    forUser,
    isReadOnly,
    tabs,
    cycle,
    viewerId,
    ratingType,
    isOpenDefault = false,
  }) => {
    const { i18n } = useLingui();
    const $isOpen = useBoolState(isOpenDefault);
    const $showGoalsOverview = useBoolState(false);
    const [currentTab, setCurrentTab] = useState(REVIEW_QUESTIONS_TABS.OWN_ANSWER.key);

    const averageRatingId = `${question.id}-${question.type}`;
    const rating = ratings[averageRatingId] || {};
    const typeRatings =
      fetchedRatings.filter(
        (fetchedRating) =>
          fetchedRating.questionId === question.id &&
          fetchedRating.questionType === rating.questionType,
      ) || [];

    const goalType =
      question.type === QUESTION_TYPES.GOAL_BUSINESS_EVAL
        ? GOAL_TYPES.BUSINESS.key
        : GOAL_TYPES.PERSONAL.key;

    let title;
    if (isSelf && question.type === QUESTION_TYPES.GOAL_BUSINESS_EVAL) {
      title = <Trans>What is your Average Business performance?</Trans>;
    } else if (isSelf && question.type === QUESTION_TYPES.GOAL_LEARNING_EVAL) {
      title = <Trans>What is your Average Learning performance?</Trans>;
    } else if (!isSelf && question.type === QUESTION_TYPES.GOAL_BUSINESS_EVAL) {
      title = <Trans>What is {forUser.firstName} his/her Average Business performance?</Trans>;
    } else if (!isSelf && question.type === QUESTION_TYPES.GOAL_LEARNING_EVAL) {
      title = <Trans>What is {forUser.firstName} his/her Average Learning performance?</Trans>;
    }

    return (
      <TemplateCardWithFooter $noShadow $isOpen={$isOpen.value}>
        <Header $isOpen={$isOpen} goalType={goalType}>
          {title}
        </Header>
        {$isOpen.value && (
          <Container>
            {ratingType !== RATING_TYPES.OUTSIDE_RATING && (
              <TabsContainer $spaceBetween={!isEmpty(tabs)}>
                {!isEmpty(tabs) && (
                  <Tabs
                    items={tabs}
                    isSmall
                    currentItem={currentTab}
                    handleChangeTab={setCurrentTab}
                    borderColor={COLORS.BORDER_LIGHT}
                    noBorder
                  />
                )}
                <StyledButton
                  type={Button.types.white}
                  label={i18n._(t`View goals`)}
                  onClick={$showGoalsOverview.on}
                />
              </TabsContainer>
            )}
            <Body
              review={review}
              question={question}
              rating={rating}
              typeRatings={typeRatings}
              onChange={(updateData) => onChange(averageRatingId, updateData)}
              forUser={forUser}
              isReadOnly={isReadOnly}
              currentTab={currentTab}
            />
          </Container>
        )}
        {$showGoalsOverview.value && (
          <GoalsOverview
            onModalClose={$showGoalsOverview.off}
            forUser={forUser.id}
            preSelectedGoalType={goalType}
            preSelectedGoalCycle={cycle}
            reviewId={review.id}
            viewerId={viewerId}
          />
        )}
      </TemplateCardWithFooter>
    );
  },
);

export { ReviewGoalAverageQuestion };
