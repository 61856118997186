import styled from 'styled-components';

import { ButtonPrimary, ButtonWhite } from '~/components/Button';

import { COLORS } from '~/styles';

export const ActionsWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionItemBlock = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  min-width: 130px;
  height: 20px;
  border-left: solid 1.2px ${COLORS.BORDERS};
  justify-content: center;
`;

export const PublishButton = styled(ButtonPrimary)`
  min-width: 154px;
  height: 48px;
  padding: 0 10px 0 14px;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 80px;
`;

export const SubmitButton = styled(ButtonPrimary)`
  height: 32px;
  min-width: 80px;
  padding: 0 10px 0 14px;
  border-radius: 100px;
  font-size: 12px;
  & .icon {
    margin-left: 4px;
  }
`;

export const BackButton = styled(ButtonWhite)`
  height: 32px;
  min-width: 80px;
  padding: 0 14px 0 10px;
  border-radius: 100px;
  font-size: 12px;
  border: none;
  color: var(--company-color);

  &:hover {
    color: var(--company-color);
    background-color: unset;
  }

  & .icon {
    color: var(--company-color);
    margin-right: 4px;
    fill: currentColor;
  }
`;

export const Error = styled.span`
  color: ${COLORS.ERROR};
  font-size: 12px;
`;
