import React from 'react';

import { SURVEY_PARTICIPANTS_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { IconBlock } from '~/components/IconBlock';
import SvgIcon from '~/components/SvgIcon';
import { StepSection } from '~/pages/SurveyUpdate/components';
import type { IGeneralForm } from '~/pages/SurveyUpdate/types';

import UsersIcon from '~/assets/users.svg';

import type { UseFormReturn } from 'react-hook-form';

interface ISectionTemplates {
  formMethods: UseFormReturn<IGeneralForm>;
  onEdit: () => void;
}

const SectionParticipants = ({ formMethods, onEdit }: ISectionTemplates) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;
  const participantsType = watch('participants.type');
  const participantsIds = watch('participants.ids');

  const TYPES = {
    [SURVEY_PARTICIPANTS_TYPE.EMPLOYEES]: i18n._(t`Employees`),
    [SURVEY_PARTICIPANTS_TYPE.TEAMS]: i18n._(t`Teams`),
    [SURVEY_PARTICIPANTS_TYPE.COMPANY]: i18n._(t`Company`),
  };

  return (
    <StepSection title={i18n._(t`Participants`)} onEdit={onEdit}>
      <IconBlock
        label={TYPES[participantsType]}
        value={
          participantsType === SURVEY_PARTICIPANTS_TYPE.COMPANY ? undefined : participantsIds.length
        }
        icon={<SvgIcon width="22px" height="22px" url={UsersIcon} />}
      />
    </StepSection>
  );
};

export { SectionParticipants };
