import React from 'react';

import CareerCard from './Cards/CareerCard';
import CoachesCard from './Cards/CoachesCard';
import { NextReviewCard } from './Cards/NextReviewCard';
import { NextStepsCard } from './Cards/NextStepsCard';
import { TeamsCard } from './Cards/TeamsCard';

type DashBoardSideBarProps = {
  isModuleReviewsEnabled: boolean;
  isModuleMeetingsEnabled: boolean;
  isModuleJobMatrixEnabled: boolean;
};

const UserDashboardSidebar = ({
  isModuleMeetingsEnabled,
  isModuleReviewsEnabled,
  isModuleJobMatrixEnabled,
}: DashBoardSideBarProps) => {
  return (
    <div>
      {isModuleReviewsEnabled && <NextReviewCard />}
      {(isModuleMeetingsEnabled || isModuleReviewsEnabled) && <NextStepsCard />}
      {isModuleJobMatrixEnabled && <CareerCard />}
      <CoachesCard />
      <TeamsCard />
    </div>
  );
};

export { UserDashboardSidebar };
