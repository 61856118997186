import React from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Button from '~/components/Button';
import { Header } from '~/components/GiveFeedback/components/Section/components/ReviewGoalQuestion/Header';
import { GoalsOverview } from '~/components/Modals/GoalsOverview';
import Feedback from '~/components/NewReviewReport/components/Feedback';
import {
  FeedbackTitle,
  StyledButton,
  Container,
} from '~/components/NewReviewReport/components/GoalEvalBlock/styledComponents';
import { TemplateCardWithFooter } from '~/components/SetupTools/components/TemplateBaseComponents';

import { GOAL_TYPES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';

const StyledTemplateCardWithFooter = styled(TemplateCardWithFooter)`
  margin-bottom: 8px;
`;

function GoalAverageFeedback({
  question,
  review,
  users,
  pdfView,
  viewerId,
  isLockedFeedback,
  handleOnRatingUpdated,
  isOpenDefault = false,
}) {
  const { i18n } = useLingui();
  const $isOpen = useBoolState(pdfView || isOpenDefault);
  const $showGoalsOverview = useBoolState(false);

  const goalType =
    question.type === QUESTION_TYPES.GOAL_BUSINESS_EVAL
      ? GOAL_TYPES.BUSINESS.key
      : GOAL_TYPES.PERSONAL.key;

  let title;
  if (question.type === QUESTION_TYPES.GOAL_BUSINESS_EVAL) {
    title = <Trans>What is your Average Business performance?</Trans>;
  } else if (question.type === QUESTION_TYPES.GOAL_LEARNING_EVAL) {
    title = <Trans>What is your Average Learning performance?</Trans>;
  }

  const preSelectedGoalCycle =
    question.type === QUESTION_TYPES.GOAL_BUSINESS_EVAL
      ? review.goalCyclesBusinessEval[0]
      : review.goalCyclesLearningEval[0];

  return (
    <Container $pdfView={pdfView}>
      <StyledTemplateCardWithFooter $noShadow $isOpen={$isOpen.value}>
        <Header $isOpen={$isOpen} goalType={goalType} pdfView={pdfView}>
          {title}
        </Header>
        {$isOpen.value && (
          <>
            <FeedbackTitle>
              <Trans>Feedback</Trans>
              {!pdfView && (
                <StyledButton
                  type={Button.types.white}
                  label={i18n._(t`View goals`)}
                  onClick={$showGoalsOverview.on}
                />
              )}
            </FeedbackTitle>
            <Feedback
              review={review}
              question={question}
              handleOnRatingUpdated={handleOnRatingUpdated}
              isLockedFeedback={isLockedFeedback}
              viewerId={viewerId}
              pdfView={pdfView}
              users={users}
            />
          </>
        )}
      </StyledTemplateCardWithFooter>
      {$showGoalsOverview.value && (
        <GoalsOverview
          reviewId={review.id}
          onModalClose={$showGoalsOverview.off}
          forUser={review.createdFor}
          preSelectedGoalType={goalType}
          preSelectedGoalCycle={preSelectedGoalCycle}
          viewerId={viewerId}
        />
      )}
    </Container>
  );
}

export { GoalAverageFeedback };
