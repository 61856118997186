import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const Dot6Icon = ({ size, ...props }) => (
  <InlineSvg height={size} width={size} {...props} childrenFirst viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <circle fill="currentColor" fillRule="nonzero" cx="11.5" cy="7.5" r="1.5" />
      <circle fill="currentColor" fillRule="nonzero" cx="20.5" cy="7.5" r="1.5" />
      <circle fill="currentColor" fillRule="nonzero" cx="11.5" cy="16" r="1.5" />
      <circle fill="currentColor" fillRule="nonzero" cx="20.5" cy="16" r="1.5" />
      <circle fill="currentColor" fillRule="nonzero" cx="11.5" cy="24.5" r="1.5" />
      <circle fill="currentColor" fillRule="nonzero" cx="20.5" cy="24.5" r="1.5" />
    </g>
  </InlineSvg>
);

Dot6Icon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

Dot6Icon.defaultProps = {
  size: 32,
  className: '',
};

export default Dot6Icon;
