import qs from 'qs';

import { AZURE_CLIENT_ID } from '~/constants/env';
import { addUserIntegrationByCode, deleteUserIntegration } from '~/services/userIntegrations';
import PopupUtils from '~/utils/PopupUtils';

export default class AzureClient {
  static scopes = [
    'user.read',
    'openid',
    'offline_access',
    'calendars.readwrite',
    'mailboxSettings.read',
  ];
  static clientId = AZURE_CLIENT_ID;
  static API_URI = 'https://login.microsoftonline.com/common/oauth2/v2.0';

  constructor() {
    // Properly sets this reference for the unload event.
    this.popupUtils = new PopupUtils('azureOauth');
  }

  async login(email) {
    const url = `${AzureClient.API_URI}/authorize?${qs.stringify({
      response_type: 'code',
      response_mode: 'query',
      scope: AzureClient.scopes.join(' '),
      client_id: AzureClient.clientId,
      redirect_uri: location.origin,
      prompt: 'select_account',
      ...(email && { login_hint: email, prompt: '' }),
    })}`;
    const popup = this.popupUtils.openPopup(url, 'azureAuth');
    const code = await this.monitorPopupForHash(popup);

    // add integration in DB
    return await addUserIntegrationByCode('azure', code);
  }

  /**
   * Monitors a window until it loads a url with a known hash, or hits a specified timeout.
   * @param popupWindow - window that is being monitored
   */
  async monitorPopupForHash(popupWindow) {
    await this.popupUtils.monitorPopupForSameOrigin(popupWindow);
    const query = qs.parse(popupWindow.location.search, { ignoreQueryPrefix: true });

    // prop cleanup if all ok
    this.popupUtils.cleanPopup(popupWindow);
    // close popup
    if (query.error) {
      throw Error(query.error_description);
    }
    return query.code;
  }

  async logout(id) {
    // logout problem in that not possible to get for witch account was logout
    // const url = `${AzureClient.API_URI}/logout?post_logout_redirect_uri=${location.origin}`;
    // const popupWindow = this.popupUtils.openPopup(url, 'azureAuth');
    // await this.popupUtils.monitorPopupForSameOrigin(popupWindow);
    // this.popupUtils.cleanPopup(popupWindow);

    if (id) {
      // delete integration from DB
      await deleteUserIntegration(id);
    }

    return id;
  }
}
