import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Switcher from 'rc-switch';

import 'rc-switch/assets/index.css';
import Tooltip from '~/components/Tooltip';
import './styles.scss';

export default class Switch extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
    tooltipPlacement: PropTypes.string,
  };

  render() {
    const { onChange, checked, disabled, className, tooltip, tooltipPlacement } = this.props;

    const toggleBtn = (
      <Switcher className={className} onChange={onChange} checked={checked} disabled={disabled} />
    );

    return tooltip ? (
      <Tooltip tooltip={tooltip} placement={tooltipPlacement} maxWidth="250px">
        <div>{toggleBtn}</div>
      </Tooltip>
    ) : (
      toggleBtn
    );
  }
}
