import React from 'react';

import { Icon, ICON_SIZES } from '~/components/Icon';

import { COLORS } from '~/styles';

import { MenuBlockIcon } from '../styles/menuItemDesign';
import { IMenuItem } from '../types';

interface IMenuIconProps {
  menuBlock: IMenuItem;
  isCurrent?: boolean;
}

const MenuIcon = ({ menuBlock, isCurrent }: IMenuIconProps) => {
  return (
    <MenuBlockIcon>
      {menuBlock.icon && (
        // @ts-ignore
        <Icon
          icon={menuBlock.icon}
          size={ICON_SIZES.MEDIUM}
          color={isCurrent ? COLORS.WHITE : 'var(--company-color)'}
        />
      )}
      {menuBlock.iconNode && menuBlock.iconNode} {/* for Account icon */}
    </MenuBlockIcon>
  );
};

export default MenuIcon;
