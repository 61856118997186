import React, { Dispatch, Fragment, SetStateAction, useState } from 'react';

import { IMultiLangString } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Tippy from '@tippyjs/react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Loader } from '~/components/Buttons/components/Loader';
import { ICONS, Icon } from '~/components/Icon';

import { ACTION_TYPE, ActionBtn } from './ActionBtn';
import { BoldMissingText } from './AllSkills.design';
import { ContextMenu } from './ContextMenu';
import {
  TableGridLeft,
  TableGridRight,
  TableGridContainer,
  FirstCell,
  SecondCell,
  OtherCell,
  DraggableBox,
  ExtendedHeader,
  HeaderItem,
  IconContainer,
  EmptyHolder,
  HeaderItemInner,
  FirstCellInner,
  SpaceDivider,
  IconWrapper,
  SortingContainer,
  LoaderContainer,
  CenterItem,
  SkillGroupPlaceholder,
  EmptyPlaceHolder,
  AddSkillSquare,
  AddInitialSkillFamily,
  EmptyHeaderItem,
} from './NewSkillTable.design';

import routes from '~/constants/routes';
import { useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';
import sanitizeHtml from '~/utils/sanitize';

import { MODAL_TYPE, MODEL_TYPE } from '../constants';
import { addToSkills, removeFromSkills, removeHTMLTags } from '../SkillEdit/utils';

import type { TCell, TCellData, TFamilyData, TOtherData, TTableData, TTableMeta } from '../types';

type TProps = {
  columnData: TFamilyData[];
  isEditMode?: boolean;
  skillData: TTableData[];
  onItemToggle?: (index: number) => void;
  onTableItemClick?: (selected: MODEL_TYPE, meta?: TTableMeta) => void;
  openedItems?: number[];
  otherData?: TOtherData | null;
  otherDataChange?: (data: TOtherData) => void;
  setColIndex?: Dispatch<SetStateAction<number>>;
  setSkillData: Dispatch<SetStateAction<TTableData[]>>;
  setRowIndex?: Dispatch<SetStateAction<number>>;
  unAssignedSkills: TCell[];
  setUnAssignedSkills: Dispatch<SetStateAction<TCell[]>>;
  onOrderChange?: () => void;
  ascending?: boolean;
  cellLoader?: string | null;
  onDragEndAction?: (updatedSkillData: TTableData[], updatedSkillIds: (string | null)[]) => void;
  onSkillLevelAdd?: (row: number, level: number) => void;
  onSkillLevelDelete?: (row: number, level: number) => void;
  onSkillFamilyDelete?: (col: number) => void;
  onSkillGroupDelete?: (row: number) => void;
  setIsLastSkill?: Dispatch<SetStateAction<boolean>>;
  setOpenedModal?: Dispatch<SetStateAction<MODAL_TYPE | null>>;
  setLevelTo?: Dispatch<SetStateAction<number>>;
  pagination?: {
    skip: number;
    limit: number;
    index: number;
  };
  onSkillDelete?: (skill: TTableData) => void;
  onSkillEdit?: (skill: TTableData) => void;
  onFocusDelete?: (skill: TTableData, focusLevelIndex: number, focusValueIndex: number) => void;
  onFocusEdit?: (skill: TTableData, focusLevelIndex: number, focusValueIndex: number) => void;
  onEditSkillDescription?: (skill: TTableData) => void;
  onSkillCategoryLevelDelete?: (skillCategoryLevelIndex: number) => void;
};

const SkillTable = ({
  onDragEndAction,
  setSkillData,
  columnData,
  isEditMode = false,
  skillData,
  onItemToggle,
  openedItems,
  setRowIndex,
  setColIndex,
  onOrderChange,
  ascending = true,
  cellLoader = null,
  setIsLastSkill,
  onTableItemClick,
  onSkillDelete,
  onSkillEdit,
  onFocusDelete,
  onFocusEdit,
  onEditSkillDescription,
  onSkillCategoryLevelDelete,
}: TProps) => {
  const [opened, setOpened] = useState<null | string>(null);
  const [selectedCell, setSelectedCell] = useState<null | string>(null);
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const languageState = useLanguageState();
  const primaryLanguage = languageState.companyPrimaryLanguage;

  const MIN_LEFT_COLS = 2;
  const MIN_RIGHT_COLS = isEditMode ? 4 : 3;
  const MIN_COLS = MIN_LEFT_COLS + MIN_RIGHT_COLS;
  const isSpecialCase = columnData.length <= MIN_COLS;
  const RIGHT_COLS = isSpecialCase ? MIN_RIGHT_COLS : columnData.length - MIN_LEFT_COLS;
  const TOTAL_COLS = MIN_LEFT_COLS + RIGHT_COLS;
  const topHeaderRows = 1;
  const headerRows = 1;

  const isFullyOpened = (index: number) => {
    return openedItems?.includes(index);
  };

  const isEmpty = (name: string) => name.trim().length === 0;

  const getSkillName = (jsxReturned: boolean, name?: IMultiLangString) => {
    const languages = Object.keys(name || {});
    const isTruthy = (value: string | undefined) => !!value && value.trim().length > 0;
    if (
      !languages.includes(primaryLanguage.locale) ||
      !isTruthy(name && name[primaryLanguage.locale])
    ) {
      return jsxReturned ? (
        <>
          <BoldMissingText>{`"${i18n._(t`Missing translation`)}" - `}</BoldMissingText>
          {`${getMultiLangString(name || '')}`}
        </>
      ) : (
        `${i18n._(t`Missing translation`)} - ${getMultiLangString(name || '')}`
      );
    }
    return jsxReturned ? <>{getMultiLangString(name || '')}</> : getMultiLangString(name || '');
  };

  const totalRows = skillData.length + topHeaderRows + headerRows + (isEditMode ? 4 : 0);

  const getNumOfFilledLevels = (item: TCellData | null) => {
    if (!item) {
      return null;
    }
    const counter = (item?.values || []).length || 0;
    if (counter === 0) {
      return `${i18n._(t`No skills yet`)}`;
    }
    return `${counter} ${i18n._(t`Focus area(s)`)}`;
  };

  const secondColumnText = (rowNumber: number, item: TTableData) => {
    if (!openedItems?.includes(rowNumber)) {
      return i18n._(t`Expand skill to read description`);
    }
    const name = getMultiLangString(item.description || '');
    if (isEmpty(name)) {
      return (
        <CenterItem
          onClick={() => {
            onEditSkillDescription && onEditSkillDescription(item);
          }}
        >
          {i18n._(t`No description`)}
        </CenterItem>
      );
    }
    return (
      <Tippy content={removeHTMLTags(name)} offset={[18, 10]}>
        <CenterItem
          onClick={() => {
            onEditSkillDescription && onEditSkillDescription(item);
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(name) }} />
        </CenterItem>
      </Tippy>
    );
  };

  const onDeleteContext = async (item: TTableData) => {
    onSkillDelete?.(item);
    setOpened(null);
  };

  const onEditContext = (item: TTableData) => {
    onSkillEdit?.(item);
    setOpened(null);
  };

  const onCellUpdate = (
    cell: TTableData,
    focusLevelIndex: number,
    focusValueIndex: number,
    isEdit: boolean,
  ) => {
    if (isEdit) {
      onFocusEdit?.(cell, focusLevelIndex, focusValueIndex);
    } else {
      onFocusDelete?.(cell, focusLevelIndex, focusValueIndex);
    }
    setSelectedCell(null);
  };

  return (
    <>
      <DragDropContext
        onDragEnd={(result) => {
          const { destination, draggableId } = result;
          if (!destination) {
            return;
          }
          const [skillRemovedArray, skillToMove, movedFrom] = removeFromSkills(
            skillData,
            draggableId,
          );
          const updatedSkillData = addToSkills(skillRemovedArray, skillToMove, destination);

          const destinationData = skillData
            .map((skill) => skill.levels)
            .flat()
            .map((level) => level?.columns)
            .flat();

          const movedTo = destinationData.find((column) => column?.id === destination.droppableId);
          onDragEndAction?.(updatedSkillData, [movedFrom, movedTo?.skillId || null]);
          setSkillData?.(updatedSkillData);
        }}
      >
        <TableGridContainer
          rowSpan={totalRows}
          colSpan={TOTAL_COLS}
          calculatedWidth={TOTAL_COLS > 4 ? '100%' : `${TOTAL_COLS * 250}px`}
        >
          <ExtendedHeader rowSpan={1} colSpan={MIN_LEFT_COLS}>
            {i18n._(t`Skill`)}
          </ExtendedHeader>
          <ExtendedHeader rowSpan={1} colSpan={RIGHT_COLS}>
            {i18n._(t`Levels`)}
          </ExtendedHeader>
          <TableGridLeft rowSpan={totalRows} colSpan={MIN_LEFT_COLS}>
            {columnData
              .filter((_, index) => index < MIN_LEFT_COLS)
              .map((col, j) => (
                <HeaderItem key={`header-${j + 1}`} rowSpan={1} colSpan={1}>
                  <HeaderItemInner>{getMultiLangString(col.name)}</HeaderItemInner>
                  {j === 0 && (
                    <SortingContainer onClick={onOrderChange} isSelected={!ascending}>
                      <Icon icon={ICONS.DROPDOWN} className={'up'} width={12} height={12} />
                      <Icon icon={ICONS.DROPDOWN} className={'down'} width={12} height={12} />
                    </SortingContainer>
                  )}
                </HeaderItem>
              ))}
            {skillData.length === 0 && (
              <SkillGroupPlaceholder
                rowSpan={1}
                colSpan={MIN_LEFT_COLS}
                onClick={() => {
                  setRowIndex?.(0);
                  setIsLastSkill?.(false);
                  onTableItemClick?.(MODEL_TYPE.CREATE_SKILL);
                }}
              >
                {isEditMode && i18n._(t`Create Skill`)}
                {isEditMode && (
                  <Icon
                    icon={ICONS.ADD_PLUS}
                    width={16}
                    height={16}
                    color={COLORS.PLACEHOLDERS}
                    className="add_job_icon"
                  />
                )}
              </SkillGroupPlaceholder>
            )}
            {skillData.map((item, index) => {
              return (
                <Fragment key={`item-${index + 1}`}>
                  <FirstCell rowSpan={0} colSpan={1} isEditMode={isEditMode}>
                    {isEditMode && (
                      <ActionBtn
                        type={ACTION_TYPE.VERTICAL}
                        isFirst
                        onActionClicked={() => {
                          onTableItemClick?.(MODEL_TYPE.CREATE_SKILL);
                        }}
                      />
                    )}

                    <FirstCellInner
                      onClick={() =>
                        routes.SKILL_VIEW.go({}, { skillId: item.id, isBackPath: true })
                      }
                    >
                      {isEditMode && <SpaceDivider heightVal="20px" widthVal="20px" />}
                      {getSkillName(false, item?.name)}
                    </FirstCellInner>
                    <IconWrapper>
                      <IconContainer
                        onClick={() => {
                          onItemToggle?.(index);
                        }}
                      >
                        <Icon
                          icon={ICONS.DROPDOWN}
                          className={
                            openedItems?.includes(index) ? 'plus_icon' : 'plus_icon_rotate'
                          }
                          width={12}
                          height={12}
                        />
                      </IconContainer>
                      {isEditMode && (
                        <ContextMenu
                          heightVal="36px"
                          widthVal="36px"
                          itemOpened={`${index}`}
                          opened={opened}
                          setOpened={setOpened}
                          onEdit={() => onEditContext(item)}
                          onDelete={() => onDeleteContext(item)}
                        />
                      )}
                    </IconWrapper>
                    {isEditMode && (
                      <ActionBtn
                        type={ACTION_TYPE.VERTICAL}
                        isFirst={false}
                        onActionClicked={() => {
                          onTableItemClick?.(MODEL_TYPE.CREATE_SKILL);
                        }}
                      />
                    )}
                  </FirstCell>
                  <SecondCell
                    key={`level-${index}-${0}-empty`}
                    rowHeight={1}
                    isEditMode={isEditMode}
                  >
                    {isEditMode && <SpaceDivider heightVal="20px" widthVal="20px" />}
                    {secondColumnText(index, item)}
                    {isEditMode && <SpaceDivider heightVal="20px" widthVal="20px" />}
                  </SecondCell>
                </Fragment>
              );
            })}
          </TableGridLeft>
          <TableGridRight rowSpan={totalRows} colSpan={RIGHT_COLS} isEditMode={isEditMode}>
            {columnData
              .filter((_, index) => index > 1)
              .map((col, row) => {
                return (
                  <Fragment key={`header-${row + 1}`}>
                    <HeaderItem rowSpan={1} colSpan={1}>
                      <HeaderItemInner>{getMultiLangString(col.name)}</HeaderItemInner>
                      {isEditMode && (
                        <ContextMenu
                          heightVal="18px"
                          widthVal="18px"
                          itemOpened={`col-${row}`}
                          opened={selectedCell}
                          setOpened={setSelectedCell}
                          onEdit={() => {
                            setColIndex && setColIndex(row);
                            onTableItemClick?.(MODEL_TYPE.EDIT_CATEGORY_LEVEL, {
                              values: col.name,
                              categoryId: col.categoryId,
                              skillCategoryLevelIndex: row,
                            });
                            setSelectedCell(null);
                          }}
                          onDelete={async () => {
                            onSkillCategoryLevelDelete?.(row);
                            setSelectedCell(null);
                          }}
                        />
                      )}
                      {isEditMode && row === 0 && (
                        <ActionBtn
                          type={ACTION_TYPE.POPOVER}
                          isFirst={true}
                          onActionClicked={() => {
                            onTableItemClick?.(MODEL_TYPE.CREATE_CATEGORY_LEVEL, {
                              skillCategoryLevelIndex: row,
                            });
                          }}
                        />
                      )}
                      {isEditMode && row + MIN_LEFT_COLS < columnData.length - 1 && (
                        <ActionBtn
                          type={ACTION_TYPE.HORIZONTAL}
                          isFirst={false}
                          onActionClicked={() => {
                            onTableItemClick?.(MODEL_TYPE.CREATE_CATEGORY_LEVEL, {
                              skillCategoryLevelIndex: row + 1,
                            });
                          }}
                        />
                      )}
                      {isEditMode &&
                        row + MIN_LEFT_COLS === columnData.length - 1 &&
                        columnData.length >= MIN_COLS && (
                          <ActionBtn
                            type={ACTION_TYPE.POPOVER}
                            isFirst={false}
                            onActionClicked={() => {
                              onTableItemClick?.(MODEL_TYPE.CREATE_CATEGORY_LEVEL, {
                                skillCategoryLevelIndex: row + 1,
                              });
                            }}
                          />
                        )}
                    </HeaderItem>
                  </Fragment>
                );
              })}
            {columnData.length > MIN_LEFT_COLS && columnData.length < MIN_COLS && (
              <EmptyHeaderItem
                rowSpan={1}
                colSpan={MIN_COLS - columnData.length}
                isHeader
                onClick={() => {
                  setColIndex && setColIndex(columnData.length - MIN_LEFT_COLS);
                  onTableItemClick &&
                    onTableItemClick(MODEL_TYPE.CREATE_CATEGORY_LEVEL, {
                      skillCategoryLevelIndex: columnData.length,
                    });
                }}
              >
                {isEditMode && i18n._(t`Add Skill Level`)}
                {isEditMode && (
                  <Icon
                    icon={ICONS.ADD_PLUS}
                    width={16}
                    height={16}
                    color={COLORS.PLACEHOLDERS}
                    className="add_job_icon"
                  />
                )}
              </EmptyHeaderItem>
            )}
            {columnData.length === MIN_LEFT_COLS && (
              <AddInitialSkillFamily
                onClick={() => {
                  setColIndex && setColIndex(0);
                  onTableItemClick && onTableItemClick(MODEL_TYPE.CREATE_CATEGORY_LEVEL);
                }}
                rowSpan={1}
                colSpan={MIN_COLS - columnData.length}
              >
                {isEditMode && i18n._(t`Add Skill Level`)}
                {isEditMode && (
                  <Icon
                    icon={ICONS.ADD_PLUS}
                    width={16}
                    height={16}
                    color={COLORS.PLACEHOLDERS}
                    className="add_job_icon"
                  />
                )}
              </AddInitialSkillFamily>
            )}
            {skillData.map((item, rowIndex) => {
              return (item?.levels || []).map((level, columnIndex) => {
                const currentRow = rowIndex % skillData.length;
                const columnLength = level.columns?.length || 0;
                return (
                  <Fragment key={`group-level-${rowIndex}-${columnIndex + 1}`}>
                    {level.columns &&
                      level.columns.map((column, groupIndex) => {
                        const key = `${rowIndex}-${columnIndex}-${groupIndex + 1}`;
                        if (!isFullyOpened(currentRow)) {
                          return (
                            <Fragment key={`empty-${key}`}>
                              <EmptyHolder rowSpan={1} colSpan={1}>
                                {getNumOfFilledLevels(column || null)}
                              </EmptyHolder>
                              {columnLength - 1 === groupIndex && columnLength < MIN_RIGHT_COLS && (
                                <EmptyHeaderItem
                                  rowSpan={1}
                                  colSpan={MIN_RIGHT_COLS - columnLength}
                                  isHeader={false}
                                />
                              )}
                            </Fragment>
                          );
                        }
                        return (
                          <Fragment key={`droppable-ctr-${key}`}>
                            <Droppable
                              droppableId={column.id}
                              key={`droppable-${key}`}
                              isDropDisabled={!isEditMode}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <OtherCell
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    isDraggingOver={snapshot.isDraggingOver}
                                    isEmpty={column.values.length === 0}
                                  >
                                    {column.values.map((cell, groupItemIndex) => {
                                      return (
                                        <Draggable
                                          draggableId={cell.id}
                                          index={groupItemIndex}
                                          key={cell.id}
                                          isDragDisabled={!isEditMode}
                                        >
                                          {(draggableData) => (
                                            <DraggableBox
                                              {...draggableData.draggableProps}
                                              {...draggableData.dragHandleProps}
                                              ref={draggableData.innerRef}
                                              className="draggable-box"
                                              isEditMode={isEditMode}
                                            >
                                              {isFullyOpened(rowIndex) && (
                                                <>
                                                  {cellLoader === cell.id ? (
                                                    <LoaderContainer>
                                                      <Loader />
                                                    </LoaderContainer>
                                                  ) : (
                                                    <>
                                                      {isEmpty(getMultiLangString(cell?.name)) ? (
                                                        <></>
                                                      ) : (
                                                        <Tippy
                                                          content={getMultiLangString(cell?.name)}
                                                          offset={[18, 10]}
                                                        >
                                                          <CenterItem>
                                                            {getMultiLangString(cell?.name)}
                                                          </CenterItem>
                                                        </Tippy>
                                                      )}

                                                      {isEditMode && (
                                                        <ContextMenu
                                                          heightVal="36px"
                                                          widthVal="36px"
                                                          itemOpened={`${cell.id}`}
                                                          opened={selectedCell}
                                                          setOpened={setSelectedCell}
                                                          onEdit={() =>
                                                            onCellUpdate(
                                                              item,
                                                              groupIndex,
                                                              groupItemIndex,
                                                              true,
                                                            )
                                                          }
                                                          onDelete={() =>
                                                            onCellUpdate(
                                                              item,
                                                              groupIndex,
                                                              groupItemIndex,
                                                              false,
                                                            )
                                                          }
                                                        />
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                              {isEditMode && (
                                                <ActionBtn
                                                  type={ACTION_TYPE.VERTICAL}
                                                  isFirst={false}
                                                  onActionClicked={() => {
                                                    setRowIndex?.(groupItemIndex + 1);
                                                    setColIndex?.(groupIndex);
                                                    onTableItemClick?.(
                                                      MODEL_TYPE.CREATE_FOCUS_AREA,
                                                      {
                                                        skillId: column?.skillId || null,
                                                        categoryId: column?.categoryId || null,
                                                      },
                                                    );
                                                  }}
                                                />
                                              )}
                                            </DraggableBox>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                    {isEditMode && column.values.length === 0 && (
                                      <EmptyPlaceHolder>
                                        <AddSkillSquare
                                          onClick={() => {
                                            setRowIndex?.(0);
                                            setColIndex?.(groupIndex);
                                            onTableItemClick?.(MODEL_TYPE.CREATE_FOCUS_AREA, {
                                              skillId: column?.skillId || null,
                                              categoryId: column?.categoryId || null,
                                            });
                                          }}
                                        >
                                          <Icon
                                            icon={ICONS.ADD_PLUS}
                                            className={'plus_icon'}
                                            width={14}
                                            height={14}
                                            color={COLORS.PLACEHOLDERS}
                                          />
                                        </AddSkillSquare>
                                      </EmptyPlaceHolder>
                                    )}
                                    {provided.placeholder}
                                  </OtherCell>
                                );
                              }}
                            </Droppable>
                          </Fragment>
                        );
                      })}
                    {isFullyOpened(currentRow) &&
                      columnLength === 0 &&
                      columnLength < MIN_RIGHT_COLS && (
                        <EmptyHeaderItem
                          rowSpan={1}
                          colSpan={MIN_RIGHT_COLS - columnLength}
                          isHeader={false}
                        />
                      )}
                    {isFullyOpened(currentRow) && columnLength < MIN_RIGHT_COLS && (
                      <EmptyHeaderItem
                        rowSpan={1}
                        colSpan={MIN_RIGHT_COLS - columnLength}
                        isHeader={false}
                      />
                    )}
                  </Fragment>
                );
              });
            })}
          </TableGridRight>
        </TableGridContainer>
      </DragDropContext>
    </>
  );
};

export { SkillTable };
