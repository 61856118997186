import React, { useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Modal from '~/components/Modal';

import {
  ConnectModalContent,
  Footer,
  Header,
  StyledButton,
  Title,
  TitleWrapper,
  Label,
} from './design';
import { useAddRoleModal } from './useAddRoleModal';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { isNotNil } from '~/utils/typePredicates';

import { StyledSearchSelect } from '../../design';

import type { IGeneralForm } from '../../JobForm/types';
import type { IJobProfile } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

const AddRoleModal = ({
  onClose,
  onSubmit,
  formMethods,
}: {
  onClose: () => void;
  onSubmit: (jobProfile: IJobProfile) => void;
  formMethods: UseFormReturn<IGeneralForm>;
}) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const { jobProfiles, setSearch } = useAddRoleModal();
  const [selectedJobProfile, setSelectedJobProfile] = useState<IJobProfile>();

  const { watch } = formMethods;
  const hiddenJobProfiles = [
    watch('id'),
    ...watch('recommendedJobs').map((item) => item.id),
  ].filter(isNotNil);

  return (
    <Modal
      width={500}
      onClose={onClose}
      showDivider={false}
      isHideHeader
      footerStyles={{ padding: '0 24px 32px 25px', height: 'unset', minHeight: 'unset' }}
      contentStyles={{ padding: '9px 24px 32px 25px', overflowY: 'unset' }}
      footerRight={
        <Footer>
          <Button
            onClick={onClose}
            label={<Trans>Cancel</Trans>}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
          />

          <Button
            onClick={() => {
              selectedJobProfile && onSubmit(selectedJobProfile);
              onClose();
            }}
            label={<Trans>Add</Trans>}
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            disabled={!selectedJobProfile}
          />
        </Footer>
      }
    >
      <Header>
        <TitleWrapper>
          <Title>
            <Trans>Add role to recommended career path</Trans>
          </Title>
          <StyledButton onClick={onClose} variant={ButtonVariant.CLOSE} size={ButtonSize.MEDIUM} />
        </TitleWrapper>
      </Header>
      <ConnectModalContent>
        <div>
          <Label>
            <Trans>Search roles</Trans>
          </Label>
          <StyledSearchSelect
            placeholder={i18n._(t`Data manager`)}
            noResultsPlaceholder={i18n._(t`No Jobs were found`)}
            items={jobProfiles.filter((jobProfile) => !hiddenJobProfiles.includes(jobProfile.id))}
            hashItem={(item) => item.id}
            selectedItem={selectedJobProfile}
            onChange={setSelectedJobProfile}
            onSearchChange={setSearch}
            stringifyItem={(item) => getMultiLangString(item.name)}
            isSingleSelect
          />
        </div>
      </ConnectModalContent>
    </Modal>
  );
};

export { AddRoleModal };
