export enum JOB_PROFILE_STATUSES {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum JOB_SORTING {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
  JOB_FAMILY_A_Z = 'JOB_FAMILY_A_Z',
  JOB_FAMILY_Z_A = 'JOB_FAMILY_Z_A',
  LEVEL_A_Z = 'LEVEL_A_Z',
  LEVEL_Z_A = 'LEVEL_Z_A',
  CREATED_BY_A_Z = 'CREATED_BY_A_Z',
  CREATED_BY_Z_A = 'CREATED_BY_Z_A',
  MEMBERS_HIGH_TO_LOW = 'MEMBERS_HIGH_TO_LOW',
  MEMBERS_LOW_TO_HIGH = 'MEMBERS_LOW_TO_HIGH',
}
