import React, { useState } from 'react';

import { IActivity } from '@learned/types';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { LearningItemType } from '~/components/LearningItemType';
import { ActivityModal } from '~/components/Modals/ActivityModal';

const StyledButton = styled(Button)``;

const Label = styled.span``;
const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 112px;

  ${StyledButton} {
    display: none;
  }
  ${Label} {
    display: block;
  }

  :hover {
    ${StyledButton} {
      display: block;
    }
    ${Label} {
      display: none;
    }
  }
`;

function ActivityType({ activity }: { activity: IActivity }) {
  const [showActivityPreview, setShowActivityPreview] = useState(false);

  return (
    <OuterContainer>
      <Container>
        <Label>
          <LearningItemType isActivity={true} type={activity.type} />
        </Label>
        <StyledButton
          type="button"
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          label={<Trans>Preview</Trans>}
          onClick={() => setShowActivityPreview(true)}
        />
        {showActivityPreview && (
          <ActivityModal
            activityId={activity.id}
            onClose={() => setShowActivityPreview(false)}
            isReadOnly
            isHideProgress
          />
        )}
      </Container>
    </OuterContainer>
  );
}

export { ActivityType };
