import React from 'react';

import { USER_REVIEW_STATUS } from '@learned/constants';
import { useLingui } from '@lingui/react';

import Label from '~/components/Label';

import { STATUS, STATUS_PROVIDER } from '~/constants/statusProvider';

const StatusLabel = ({ status }: { status: string }) => {
  const { i18n } = useLingui();
  const statusProps =
    STATUS_PROVIDER[status === USER_REVIEW_STATUS.PUBLISHED ? STATUS.UPCOMING : status];
  return statusProps ? <Label {...statusProps}>{statusProps.text(i18n)}</Label> : null;
};

export { StatusLabel };
