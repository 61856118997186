import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import IconButton from '~/components/IconButton';
import IconMenu from '~/components/IconMenu';
import PlusIcon from '~/components/Icons/Plus';
import AvatarCard from '~/components/UI/AvatarCard';

import { ROLES } from '~/constants';
import { createComment, deleteComment, updateComment } from '~/services/comments';
import { COLORS, COLOR_PALETTE } from '~/styles';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';
import sanitizeHtml from '~/utils/sanitize';

import Editor from '../Editor';

const CommentsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  .tp-placeholder {
    padding-top: 15px;
    padding-bottom: 40px;
  }
`;

const CommentExistBlock = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  margin-top: 16px;
`;

const CommentNew = styled.li`
  margin: 0 24px;
  box-sizing: border-box;
  list-style: none;
  border-top: ${(props) => props.$borderTopOfComments && `1px solid ${COLORS.BG_PAGE}`};
`;

const CommentsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-block-start: 5px;

  li {
    &:first-child {
      border-top: none;
    }
  }
`;

const CommentTextNew = styled.div`
  p {
    margin: 0;
  }

  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  word-break: break-word;
  line-height: 1.71;
  color: ${COLOR_PALETTE.BLACK};
  padding-right: 33px;
  margin: 4px 0;
`;

const CommentInput = styled.div`
  align-items: center;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border-radius: 3px;
  border: solid 1px #c7cfe0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  color: ${COLORS.TEXT_SECONDARY};

  .ql-container.ql-snow {
    border: none;
  }

  .ql-editor {
    padding-right: ${({ saveButton }) => (saveButton ? '95px' : '30px')};
  }

  .editor .ql-toolbar {
    border-top: none;
    border-left: none;
    border-right: none;
  }

  .ql-editor.ql-blank::before {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    color: ${COLORS.TEXT_SECONDARY};
  }
`;

const CommentInputWrap = styled.div`
  width: 100%;
  ${({ saveButton }) => !saveButton && 'display: flex;'}
  margin: 16px 0 24px 0;
  ${(props) => props.$isNewCommentOnTop && 'margin-bottom: 0;'}
`;

const CommentMainInfo = styled.div`
  ${(props) =>
    props.isNotes &&
    `
    background-color: ${COLORS.BG_PAGE};
    width: 100%;
    border-radius: 6px;
    padding: 12px;
    box-sizing: border-box;
    margin-top: 8px;
  `}
`;

const CommentExistRow = styled.div`
  width: 100%;
`;

const CommentExistInfo = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const SaveButton = styled.div`
  position: absolute;
  bottom: ${(props) => (props.big ? '43px' : '20px')};
  right: ${(props) => (props.big ? '19px' : '20px')};
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  letter-spacing: 0.7px;
`;

const IconMenuWrap = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
`;

class Comments extends Component {
  static propTypes = {
    comments: PropTypes.array,
    source: PropTypes.string,
    sourceId: PropTypes.string,
    parentSource: PropTypes.string,
    parentSourceId: PropTypes.string,
    onCommentCreated: PropTypes.func,
    onCommentDeleted: PropTypes.func,
    onCommentUpdated: PropTypes.func,
    readOnly: PropTypes.bool,
    isApprovalFlow: PropTypes.bool,
    isNewCommentOnTop: PropTypes.bool,
    big: PropTypes.bool,
  };

  static defaultProps = {
    comments: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      newCommentText: '',
      editComment: null,
    };
    this.renderComment = this.renderComment.bind(this);
  }

  setupBeforeUnloadListener = (ev) => {
    const { newCommentText } = this.state;
    if (!newCommentText) {
      ev.preventDefault();
      ev.returnValue = ''; // for Chrome
    }
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.setupBeforeUnloadListener);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.setupBeforeUnloadListener);
  }

  handleEditCommentChange = (newValue) => {
    this.setState((prevState) => {
      const editComment = prevState.editComment;
      editComment.comment = newValue;
      return { editComment };
    });
  };

  handleNewCommentChange = (newValue) => {
    this.setState({ newCommentText: newValue });
  };

  triggereditComment = (comment) => {
    const { editComment } = this.state;
    if (!editComment || editComment.id !== comment.id) {
      this.setState({ editComment: comment });
    } else {
      this.setState({ editComment: null });
    }
  };

  createComment = async () => {
    const {
      source,
      sourceId,
      parentSourceId,
      parentSource,
      isApprovalFlow = false,
      isPrivateNote,
    } = this.props;
    const { newCommentText } = this.state;

    let comment = {
      comment: newCommentText,
      ...(source && { source }),
      ...(sourceId && { sourceId }),
      ...(parentSource && { parentSource }),
      ...(parentSourceId && { parentSourceId }),
      ...(isPrivateNote && { isPrivate: true }),
      isApprovalFlow,
    };
    this.setState({ working: true });

    if (!newCommentText || /^\s*$/.test(newCommentText)) {
      return;
    }
    const createdComment = await createComment(comment);
    if (this.props.onCommentCreated) {
      this.props.onCommentCreated(createdComment);
    }

    this.setState({ newCommentText: '', working: false });
  };

  deleteComment = async (commentId) => {
    await deleteComment(commentId);
    if (this.props.onCommentDeleted) {
      this.props.onCommentDeleted(commentId);
    }
  };

  updateComment = async () => {
    const { editComment } = this.state;
    const commentId = editComment.id;
    await updateComment(commentId, editComment.comment);
    if (this.props.onCommentUpdated) {
      this.props.onCommentUpdated(editComment);
    }
    this.setState({ editComment: null });
  };

  render() {
    const { selectedRole, readOnly, comments, isNewCommentOnTop, listStyles } = this.props;
    return (
      <CommentsContainer>
        {selectedRole !== ROLES.ADMIN && !readOnly && isNewCommentOnTop && this.renderNewComment()}
        <CommentsList style={listStyles}>{comments.map(this.renderComment)}</CommentsList>
        {selectedRole !== ROLES.ADMIN && !readOnly && !isNewCommentOnTop && this.renderNewComment()}
      </CommentsContainer>
    );
  }

  renderComment(comment, key) {
    const { authUser, readOnly, i18n, borderTopOfComments, isNotes } = this.props;
    const { editComment } = this.state;
    const menuItems = [];
    if (comment.author === authUser.id && !readOnly) {
      menuItems.push({
        label: i18n._(t`Update`),
        action: () => this.triggereditComment(comment),
      });
      menuItems.push({
        label: i18n._(t`Remove`),
        action: () => this.deleteComment(comment.id),
      });
    }

    return (
      <CommentNew borderTopOfComments={borderTopOfComments} key={key}>
        {editComment && editComment.id === comment.id ? (
          this.renderCommentInput(editComment)
        ) : (
          <CommentExistBlock>
            <CommentExistRow>
              <AvatarCard userId={comment.author} type="medium" />
              <CommentMainInfo isNotes={isNotes}>
                <CommentTextNew
                  dangerouslySetInnerHTML={{ __html: sanitizeHtml(comment.comment) }}
                />
                {!isEmpty(menuItems) && (
                  <IconMenuWrap>
                    <IconMenu items={menuItems} />
                  </IconMenuWrap>
                )}
                <CommentExistInfo>
                  {convertToTimeString(
                    comment.date || get(comment, 'meta.createdDate'),
                    TIME_FORMATS.CLASSIC_FULL,
                  )}
                </CommentExistInfo>
              </CommentMainInfo>
            </CommentExistRow>
          </CommentExistBlock>
        )}
      </CommentNew>
    );
  }

  renderNewComment() {
    return <CommentNew>{this.renderCommentInput()}</CommentNew>;
  }

  renderCommentInput(comment) {
    const { commentPlaceHolder, saveButton, i18n, isNewCommentOnTop } = this.props;
    const { newCommentText, editComment, working = false, big } = this.state;

    const addButtonDisabled =
      working || (comment ? editComment.comment.trim() === '' : newCommentText.trim() === '');

    return (
      <CommentInputWrap saveButton={saveButton} $isNewCommentOnTop={isNewCommentOnTop}>
        <CommentInput saveButton={saveButton}>
          <Editor
            placeholder={commentPlaceHolder}
            hideToolbarOnStart={!comment}
            value={comment ? comment.comment : newCommentText}
            compact={true}
            onChange={comment ? this.handleEditCommentChange : this.handleNewCommentChange}
            big={big}
          />

          {saveButton ? (
            <SaveButton>
              <Button
                disabled={comment ? !editComment : !newCommentText}
                onClick={comment ? this.updateComment : this.createComment}
                label={i18n._(t`Save`)}
                height={28}
                width={62}
              />
            </SaveButton>
          ) : (
            <IconButton
              size={32}
              style={{ position: 'absolute', bottom: '43px', right: '19px' }}
              disabled={addButtonDisabled}
              noBorder
              onClick={comment ? this.updateComment : this.createComment}
            >
              <PlusIcon />
            </IconButton>
          )}
        </CommentInput>
        <Prompt
          when={Boolean(newCommentText)}
          message={i18n._(
            t`Are you sure you want to exit this screen? Not saved comments will be lost.`,
          )}
        />
      </CommentInputWrap>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
    selectedRole: state.selected.role,
  };
};

export default withI18n()(connect(mapStateToProps)(Comments));
