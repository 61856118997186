import React from 'react';

import RickTextView from '~/components/RickTextView';

import { Container, Text } from './design';

import { COLORS } from '~/styles';

type InfoBoxProps = {
  value: string;
  isRichText?: boolean;
  margin?: string;
};

const InfoBox = ({ value, isRichText = false, margin }: InfoBoxProps) => (
  <Container margin={margin}>
    {!isRichText && <Text>{value}</Text>}
    {isRichText && (
      <RickTextView
        html={value}
        color={COLORS.TEXT_MAIN}
        isHtmlWithoutStyles={undefined}
        className={undefined}
      />
    )}
  </Container>
);

export default InfoBox;
