import styled from 'styled-components';

import { TableGrid } from '~/components/TableGrid';

import { COLORS } from '~/styles';

export const StyledTableGrid = styled(TableGrid)`
  margin-top: 23px;
`;

export const NameRow = styled.div<{
  maxWidth?: string;
}>`
  display: flex;
  align-items: start;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: ${COLORS.BG_LIST};
  padding: 17px 14px;
  max-width: ${({ maxWidth }) => maxWidth || 'unset'};
  border-radius: 6px;
  height: 100%;
  color: ${COLORS.TEXT_MAIN};
  font-size: 14px;
  word-break: break-all;
`;

export const FocusAreaName = styled(NameRow)`
  align-items: center;
`;
