import { createReducer } from 'redux-act';

import * as notificationsActions from './actions';

const INITIAL_STATE = {
  fetchNotifications: true,
  notificationsCount: 0,
};

export const notifications = createReducer(
  {
    [notificationsActions.finishSetNotifications]: (state, payload) => {
      if (!payload) {
        return { ...INITIAL_STATE };
      }
      return {
        ...state,
        ...payload,
      };
    },
  },
  INITIAL_STATE,
);
