import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Icons
import Comments from '~/components/Comments';
import InfoIcon from '~/components/Icons/Info';
import SvgIcon from '~/components/SvgIcon';
import SwitchYesNo from '~/components/SwitchYesNo';
import AvatarCard from '~/components/UI/AvatarCard';

import { ROLES } from '~/constants';
import { getSelectedRole } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';

const Row = styled.div`
  border: solid 1px #e4e8ef;
  background-color: #f6f8fc;
  padding: 16px 15px;
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: 245px 1fr;
`;

const RowContent = styled.div`
  display: flex;
  align-items: center;
`;

const BlockContent = styled.div``;

const IconWrapper = styled.div`
  width: 52px;
`;

const InfoBlock = styled.div``;

const InfoBlockStatus = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_PRIMARY_2};
  margin-bottom: 4px;
`;

const InfoBlockDescription = styled.div`
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

const CommentsWrapper = styled.div`
  margin-top: 12px;
`;

const ApprovalSection = styled.div`
  margin: 32px 0 28px;
`;

const ApproveRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  border: ${(props) => (props.isWarning ? 'solid 1px #e94d3c' : 'none')}
  padding: ${(props) => (props.isWarning ? '7px' : '0px')}
  margin-left: ${(props) => (props.isWarning ? '-7px' : '0px')}
  margin-right: ${(props) => (props.isWarning ? '-7px' : '0px')}

`;

const ApproveRowLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

const Warning = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
`;

const WarningLabel = styled.div`
  margin-left: 5px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #e94d3c;
`;

const ApproveSectionRow = ({
  i18n,
  conversation,
  userId,
  icon,
  label,
  description,
  isOwner,
  isOwnerCompleted,
  comments,
  reFetchConversation,
  isApproveUserWarning,
}) => {
  const role = useSelector(getSelectedRole);
  const isReadOnly = true;

  return (
    <Row>
      <AvatarCard userId={userId} />
      <BlockContent>
        <RowContent>
          {icon && (
            <IconWrapper>
              <SvgIcon url={icon} width="32px" height="32px" />
            </IconWrapper>
          )}

          <InfoBlock>
            <InfoBlockStatus>{label}</InfoBlockStatus>
            <InfoBlockDescription>{description}</InfoBlockDescription>
          </InfoBlock>
        </RowContent>
        {isOwner && role === ROLES.USER && conversation.isCoachCompleted && (
          <ApprovalSection>
            <ApproveRow isWarning={isApproveUserWarning}>
              <div>
                <ApproveRowLabel>{i18n._(t`I have seen the content of this form`)}</ApproveRowLabel>
                {isApproveUserWarning && (
                  <Warning>
                    <InfoIcon error={true} />
                    <WarningLabel>
                      {i18n._(t`You have to agree to having seen the content of this form`)}
                    </WarningLabel>
                  </Warning>
                )}
              </div>

              <SwitchYesNo
                value={conversation.isUserSeen}
                disabled={isReadOnly || isOwnerCompleted}
              />
            </ApproveRow>
            <ApproveRow>
              <ApproveRowLabel>{i18n._(t`I agree with the content of this form`)}</ApproveRowLabel>
              <SwitchYesNo
                value={conversation.isUserApproved}
                disabled={isReadOnly || isOwnerCompleted}
              />
            </ApproveRow>
          </ApprovalSection>
        )}
        {(isOwner || isOwnerCompleted) && (role === ROLES.COACH || conversation.isCoachCompleted) && (
          <CommentsWrapper>
            <Comments
              comments={comments.filter((c) => c.isApprovalFlow)}
              commentPlaceHolder={i18n._(t`Type your comment`)}
              parentSource="conversation"
              parentSourceId={conversation.id}
              onCommentCreated={() => reFetchConversation(false)}
              onCommentDeleted={() => reFetchConversation(false)}
              readOnly={isReadOnly || isOwnerCompleted}
              isApprovalFlow={true}
            />
          </CommentsWrapper>
        )}
      </BlockContent>
    </Row>
  );
};

export default React.memo(withI18n()(ApproveSectionRow));
