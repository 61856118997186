import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import RatingScaleBlocks from './RatingScaleBlocks';

const RatingScaleWrapper = styled.div`
  width: 100%;
  ${(props) => props.labelRight && 'display: flex;'}
  ${(props) => props.labelRight && 'align-items: center;'}
`;

const RatingScaleBlocksWrapper = styled.div`
  width: ${(props) => (props.width ? props.width : '100%')};
`;

const RatingScaleTexts = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  width: 100%;
`;

const RatingScaleText = styled.p`
  justify-self: center;
  font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
`;

const RatingScaleTextCompact = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  color: darkslategray;
  text-align: right;
  ${(props) => !props.$labelRight && 'margin-top: 8px;'};
  ${(props) => props.$labelRight && 'margin-left: 24px;'}
`;

function RatingScale({
  selectedRate,
  onChange,
  compact,
  readonly,
  width,
  isBig,
  isSingleHover = false,
  labelRight = false,
  scale = [],
  onChangeHoverLevel,
  scaleLabels,
  expectedRate,
  disabled = false,
}) {
  return (
    <RatingScaleWrapper labelRight={labelRight}>
      <RatingScaleBlocksWrapper width={width}>
        <RatingScaleBlocks
          selectedRate={selectedRate}
          onChange={onChange}
          blockGap={compact ? '2px' : isBig ? '0px' : '16px'}
          blockHeight={compact ? '8px' : isBig ? '60px' : '24px'}
          expectedRate={expectedRate}
          readonly={readonly}
          scaleLabels={scaleLabels}
          isBig={isBig}
          scale={scale}
          isSingleHover={isSingleHover}
          onChangeHoverLevel={onChangeHoverLevel}
          disabled={disabled}
        />
      </RatingScaleBlocksWrapper>
      {compact ? (
        <RatingScaleTextCompact $labelRight={labelRight}>
          {selectedRate}/{scale[scale.length - 1]}
        </RatingScaleTextCompact>
      ) : (
        !isBig && (
          <RatingScaleTexts>
            {scale.map((r, idx) => (
              <RatingScaleText key={r} isSelected={r <= selectedRate}>
                {scaleLabels[idx]}
              </RatingScaleText>
            ))}
          </RatingScaleTexts>
        )
      )}
    </RatingScaleWrapper>
  );
}

RatingScale.propTypes = {
  scale: PropTypes.arrayOf(PropTypes.number),
  readonly: PropTypes.bool,
};

export default React.memo(RatingScale);
