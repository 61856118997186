import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isExists from 'date-fns/isExists';
import get from 'lodash/get';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ButtonVariant } from '~/components/Buttons';
import { Dropdown } from '~/components/Dropdown';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import { Input } from '~/components/Input';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import type { SectionBodyProps } from '~/pages/PassportPage/types';

import { SectionHeader } from './SectionHeader';

import useBoolState from '~/hooks/useBoolState';
import { getUser } from '~/selectors/baseGetters';
import { getUserData } from '~/services/users';
import { updateUserProfile } from '~/store/auth/actions';
import { updateMembersProfiles } from '~/store/users/actions';
import { COLORS } from '~/styles';
import { EMAIL_PATTERN } from '~/utils/isValidEmail';

import type { I18n } from '@lingui/core';

interface DropdownOptionType {
  key: string;
  name: (i18n: I18n) => string;
}

interface FormDataType {
  firstName: string;
  lastName: string;
  gender: DropdownOptionType;
  month: DropdownOptionType;
  date: string;
  year: string;
  phone: string;
  email: string;
  dob: string;
}

interface History {
  firstName: string;
  lastName: string;
  gender?: DropdownOptionType;
  date: string;
  year: string;
  month: DropdownOptionType;
  email: string;
  phone: string;
}

const InputSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  row-gap: 8px;
  margin-bottom: 20px;
`;

const FieldLabel = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
  margin-bottom: 0px;
  display: flex;
  gap: 5px;
  align-items: center;
`;

const OptionalFieldLabel = styled.span`
  flex: auto;
`;

const OptionalText = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.17;
  letter-spacing: -0.13px;
  color: ${COLORS.TEXT_MAIN};
`;

const Field = styled(Input)`
  & > div {
    margin-top: 8px;
  }

  & > label {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: -0.16px;
    color: ${COLORS.SUBTEXT};
  }

  & input:disabled {
    border: 1px solid ${(props) => props.disabled && 'transparent'};
    color: ${COLORS.TEXT_HOVER};
    padding-left: 0px;
    cursor: inherit;
  }
`;

const DropdownField = styled(Dropdown)<{ isClickable: boolean; error: boolean }>`
  width: 100%;
  margin-top: 8px;

  & > div:first-child {
    ::before {
      background-color: transparent;
    }
    color: ${COLORS.TEXT_HOVER};
    height: 38px;
    font-size: 14px;
    border: 1px solid
      ${(props) =>
        props.isClickable ? (props.error ? COLORS.ACCENT_ERROR : COLORS.BORDERS) : 'transparent'};
    background-color: transparent;
    padding-left: ${(props) => (props.isClickable ? '9px' : '0px')};
  }
`;

const DobWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const MonthDropDown = styled(Dropdown)<{ error: boolean; isClickable: boolean }>`
  margin-top: 8px;
  width: 100%;
  flex: auto;

  & > div:first-child {
    ::before {
      background-color: transparent;
    }

    color: ${COLORS.TEXT_HOVER};
    height: 38px;
    font-size: 14px;
    border: 1px solid
      ${(props) =>
        props.isClickable ? (props.error ? COLORS.ACCENT_ERROR : COLORS.BORDERS) : 'transparent'};
    background-color: transparent;
    padding-left: ${(props) => (props.isClickable ? '9px' : '0px')};
  }
`;

const DateOfBirthBlock = styled.div`
  margin-top: 8px;
  font-size: 14px;
  padding: 9px 9px 9px 0px;
  color: ${COLORS.TEXT_HOVER};

  span {
    color: ${COLORS.PLACEHOLDERS};
  }
`;

const ErrorMessage = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.ACCENT_ERROR};
`;

const Error = styled.span`
  color: ${COLORS.ACCENT_ERROR};
  font-size: 12px;
  position: relative;
  top: -4px;
`;

const months = [
  {
    key: '1',
    name: (i18n: I18n) => i18n._(t`January`),
  },
  {
    key: '2',
    name: (i18n: I18n) => i18n._(t`February`),
  },
  {
    key: '3',
    name: (i18n: I18n) => i18n._(t`March`),
  },
  {
    key: '4',
    name: (i18n: I18n) => i18n._(t`April`),
  },
  {
    key: '5',
    name: (i18n: I18n) => i18n._(t`May`),
  },
  {
    key: '6',
    name: (i18n: I18n) => i18n._(t`June`),
  },
  {
    key: '7',
    name: (i18n: I18n) => i18n._(t`July`),
  },
  {
    key: '8',
    name: (i18n: I18n) => i18n._(t`August`),
  },
  {
    key: '9',
    name: (i18n: I18n) => i18n._(t`September`),
  },
  {
    key: '10',
    name: (i18n: I18n) => i18n._(t`October`),
  },
  {
    key: '11',
    name: (i18n: I18n) => i18n._(t`November`),
  },
  {
    key: '12',
    name: (i18n: I18n) => i18n._(t`December`),
  },
];

const genderList = [
  {
    key: 'man',
    name: (i18n: I18n) => i18n._(t`Man`),
  },
  {
    key: 'woman',
    name: (i18n: I18n) => i18n._(t`Woman`),
  },
  {
    key: 'other',
    name: (i18n: I18n) => i18n._(t`Other`),
  },
];

const SectionDetails = ({ userId }: SectionBodyProps) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const [dateOfBirth, setDateOfBirth] = useState<string | null>(null);
  const [authUserUid, setAuthUserUid] = useState();
  const [userUid, setUserUid] = useState();
  const [history, setHistory] = useState<History>({
    firstName: '',
    lastName: '',
    gender: { key: '', name: () => '' },
    date: '',
    year: '',
    month: { key: '', name: () => '' },
    email: '',
    phone: '',
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [isShowWarningModal, setIsShowWarningModal] = useState(false);
  const [submittedFormData, setSubmittedFormData] = useState<FormDataType>();
  const $loading = useBoolState(true);
  const $showAccountExistsError = useBoolState(false);
  const currentUser = useSelector(getUser);

  const formMethods = useForm<FormDataType>({
    mode: 'all',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      date: '',
      year: '',
      dob: '',
    },
  });
  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors },
    setValue,
    watch,
    setError,
    clearErrors,
  } = formMethods;

  useEffect(() => {
    const fetchData = async () => {
      $loading.on();
      const userData = await getUserData();
      const authUser = get(userData, 'user');
      const users = get(userData, 'users');
      const user = get(users, userId);
      const splittedDate = get(user, 'dateOfBirth') && get(user, 'dateOfBirth').split('-');

      const genderVal: DropdownOptionType =
        get(user, 'gender') && genderList.find((gender) => gender.key === get(user, 'gender'));
      const monthOfDob: DropdownOptionType =
        splittedDate && months.find((month) => month.key === Number(splittedDate[1]).toString());

      splittedDate && formatDate(splittedDate[0], monthOfDob, splittedDate[2]);

      setValue('firstName', get(user, 'firstName'));
      setValue('lastName', get(user, 'lastName'));
      setValue('gender', genderVal);
      setValue('date', splittedDate && splittedDate[2]);
      setValue('year', splittedDate && splittedDate[0]);
      setValue('month', monthOfDob);
      setValue('email', get(user, 'email'));
      setValue('phone', get(user, 'phone'));

      setHistory({
        firstName: get(user, 'firstName'),
        lastName: get(user, 'lastName'),
        gender: genderVal,
        date: splittedDate && splittedDate[2],
        year: splittedDate && splittedDate[0],
        month: monthOfDob,
        email: get(user, 'email'),
        phone: get(user, 'phone'),
      });
      setAuthUserUid(get(authUser, 'uid'));
      setUserUid(get(user, 'uid'));
      $loading.off();
    };
    fetchData();
    // eslint-disable-next-line
  }, [userId]);

  const day = watch('date');
  const month = watch('month');
  const year = watch('year');

  const isValidDate = useCallback(
    (date: string, month: DropdownOptionType, year: string) => {
      if (isExists(Number(year), Number(month?.key) - 1, Number(date)) && year.length === 4) {
        clearErrors('dob');
      } else {
        setError('dob', {
          type: 'manual',
          message: i18n._(t`Invalid date of birth`),
        });
      }
    },
    [setError, clearErrors, i18n],
  );

  useEffect(() => {
    isValidDate(day, month, year);
  }, [day, month, year, isValidDate]);

  const handleEdit = () => {
    setIsDisabled(!isDisabled);
    if (!isDisabled) {
      reAssignHistory();
    }
  };

  const onSaveIfEmailChanged = async (formData: FormDataType) => {
    $loading.on();
    const data = [
      {
        userId,
        firstName: formData.firstName,
        lastName: formData.lastName,
        dateOfBirth: formatDate(formData.date, formData.month, formData.year),
        gender: formData.gender?.key,
        phone: formData.phone,
        email: formData.email,
      },
    ];

    const updatedUsersList = await dispatch(updateMembersProfiles(data));
    if (get(updatedUsersList, 'emailExistsError')) {
      $showAccountExistsError.on();
      setTimeout(() => {
        reAssignHistory();
        $showAccountExistsError.off();
      }, 5000);
    } else {
      // @ts-ignore
      updateStateIfUserIsTheMember(data);
      saveFormDataInHistory(formData);
    }
    $loading.off();
    setIsDisabled(!isDisabled);
  };

  // @ts-ignore
  const updateStateIfUserIsTheMember = (data) => {
    if (authUserUid === userUid) {
      const user = {
        firstName: data[0].firstName,
        lastName: data[0].lastName,
        gender: data[0].gender,
        dateOfBirth: data[0].dateOfBirth,
        email: data[0].email,
        phone: data[0].phone,
      };
      dispatch(updateUserProfile(user));
    }
  };

  const saveFormDataInHistory = (formData: FormDataType) =>
    setHistory({
      firstName: formData.firstName,
      lastName: formData.lastName,
      gender: formData.gender,
      date: formData.date,
      year: formData.year,
      month: formData.month,
      email: formData.email,
      phone: formData.phone,
    });

  const onSaveValidationPass = async (formData: FormDataType) => {
    setSubmittedFormData(formData);
    if (formData.email !== history.email) {
      setIsShowWarningModal(true);
    } else {
      $loading.on();
      const data = [
        {
          userId,
          firstName: formData.firstName,
          lastName: formData.lastName,
          dateOfBirth: formatDate(formData.date, formData.month, formData.year),
          gender: formData.gender?.key || null,
          phone: formData.phone,
        },
      ];

      await dispatch(updateMembersProfiles(data));
      // @ts-ignore
      updateStateIfUserIsTheMember(data);
      saveFormDataInHistory(formData);
      $loading.off();
      setIsDisabled(!isDisabled);
    }
  };

  const reAssignHistory = () => {
    reset(history);
  };

  const formatDate = (
    date?: string | null,
    month?: DropdownOptionType | null,
    year?: string | null,
  ) => {
    if (date && month && year) {
      const modifiedMonth = month?.key.toString().padStart(2, '0');
      const modifiedDate = date.toString().padStart(2, '0');
      const modifiedDateOfBirth = `${year}-${modifiedMonth}-${modifiedDate}`;

      setDateOfBirth(modifiedDateOfBirth);
      return modifiedDateOfBirth;
    } else {
      setDateOfBirth(null);
      return null;
    }
  };

  const formattedDateOfBirth = useMemo(() => {
    if (dateOfBirth) {
      const [year, month, date] = dateOfBirth.split('-');
      return `${date}/${month}/${year}`;
    }

    return undefined;
  }, [dateOfBirth]);

  return (
    <div>
      <SectionHeader
        title={i18n._(t`Personal information`)}
        description={''}
        actionLabel={i18n._(t`edit`)}
        actionOnClick={currentUser.isAdmin ? handleEdit : undefined}
        actionIcon={ICONS.EDIT_PENCIL}
        actionIconSize={ICON_SIZES.MEDIUM}
        actionButtonVariant={ButtonVariant.TEXT_PRIMARY}
        isEditable={!isDisabled}
        onSave={handleSubmit((formData) => onSaveValidationPass(formData as FormDataType))}
        onCancel={handleEdit}
      />
      <ShowSpinnerIfLoading loading={$loading.value}>
        <InputSection>
          <div>
            <Field
              type="text"
              height="38px"
              label={i18n._(t`First name`)}
              disabled={isDisabled}
              placeholder={i18n._(t`First name`)}
              register={register('firstName', {
                required: { value: true, message: i18n._(t`First name is required`) },
                maxLength: {
                  value: 50,
                  message: i18n._(t`Exceeds maximum length of 50 characters`),
                },
                pattern: {
                  value: /^[^\s]+(?:$|.*[^\s]+$)/,
                  message: i18n._(t`Whitespace not allowed`),
                },
              })}
              error={errors.firstName?.message}
            />
          </div>
          <div>
            <Field
              type="text"
              height="38px"
              label={i18n._(t`Last name`)}
              disabled={isDisabled}
              placeholder={i18n._(t`Last name`)}
              register={register('lastName', {
                required: { value: true, message: i18n._(t`Last name is required`) },
                maxLength: {
                  value: 50,
                  message: i18n._(t`Exceeds maximum length of 50 characters`),
                },
                pattern: {
                  value: /^[^\s]+(?:$|.*[^\s]+$)/,
                  message: i18n._(t`Whitespace not allowed`),
                },
              })}
              error={errors.lastName?.message}
            />
          </div>
        </InputSection>
        <InputSection>
          <div>
            <FieldLabel>
              <OptionalFieldLabel>{i18n._(t`Gender`)}</OptionalFieldLabel>
              {!isDisabled ? (
                <OptionalText>
                  <Trans>(optional)</Trans>
                </OptionalText>
              ) : (
                <></>
              )}
            </FieldLabel>
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <DropdownField
                  items={genderList}
                  error={!!errors.gender?.message}
                  placeholder={i18n._(t`Gender`)}
                  onChange={(item) => field.onChange(item ?? null)}
                  selectedItem={field.value}
                  stringifyItem={(item) => (item as DropdownOptionType).name(i18n)}
                  hashItem={(item) => (item as DropdownOptionType).key}
                  skipSort
                  isSingleSelect
                  isClickable={!isDisabled}
                  isClearOption
                />
              )}
            />
          </div>
          <div>
            <FieldLabel>{i18n._(t`Date of birth`)}</FieldLabel>
            <DobWrapper>
              {isDisabled ? (
                <DateOfBirthBlock>
                  {formattedDateOfBirth === undefined ? (
                    <span>{i18n._(t`Date of birth`)}</span>
                  ) : (
                    formattedDateOfBirth
                  )}
                </DateOfBirthBlock>
              ) : (
                <>
                  <Controller
                    name="month"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <MonthDropDown
                        items={months}
                        error={!!errors.dob?.message}
                        placeholder={i18n._(t`Month`)}
                        onChange={onChange}
                        selectedItem={value}
                        stringifyItem={(item) => (item as DropdownOptionType).name(i18n)}
                        hashItem={(item) => (item as DropdownOptionType).key}
                        skipSort
                        isSingleSelect
                        isClickable={!isDisabled}
                      />
                    )}
                  />
                  <Field
                    type="number"
                    height="38px"
                    disabled={isDisabled}
                    placeholder={i18n._(t`DD`)}
                    register={register('date')}
                    error={!!errors.dob?.message}
                  />
                  <Field
                    type="number"
                    height="38px"
                    disabled={isDisabled}
                    placeholder={i18n._(t`YYYY`)}
                    register={register('year')}
                    error={!!errors.dob?.message}
                  />
                </>
              )}
            </DobWrapper>
            {!isDisabled && <>{errors.dob?.message && <Error>{errors.dob?.message}</Error>}</>}
          </div>
        </InputSection>
        <InputSection>
          <div>
            <Field
              type="email"
              height="38px"
              label={i18n._(t`E-mail`)}
              disabled={isDisabled}
              placeholder={i18n._(t`E-mail`)}
              register={register('email', {
                required: { value: true, message: i18n._(t`E-mail is required`) },
                pattern: {
                  value: EMAIL_PATTERN,
                  message: i18n._(t`Invalid e-mail`),
                },
              })}
              error={errors.email?.message}
            />
          </div>
          <div>
            <Field
              type="tel"
              height="38px"
              label={i18n._(t`Phone`)}
              disabled={isDisabled}
              placeholder={i18n._(t`Phone`)}
              register={register('phone', {
                maxLength: {
                  value: 10,
                  message: i18n._(t`Exceeds maximum length of 10 characters`),
                },
                pattern: {
                  value: /^\d{10}$/,
                  message: i18n._(t`Invalid phone`),
                },
              })}
              error={errors.phone?.message}
            />
          </div>
        </InputSection>

        {$showAccountExistsError.value && (
          <ErrorMessage>{i18n._(t`A Learned account exists for this email`)}</ErrorMessage>
        )}

        {isShowWarningModal && (
          <ConfirmationModal
            type={CONFIRMATION_MODAL_TYPE.WARNING}
            description={i18n._(
              t`This will transfer all this user data to the new user name. The old user name will be deactivated. An email will be sent to inform both the old username and the new username.`,
            )}
            onClose={() => setIsShowWarningModal(false)}
            onSubmit={() => onSaveIfEmailChanged(submittedFormData as FormDataType)}
            cancelButtonTextColor={COLORS.TEXT_MAIN}
          />
        )}
      </ShowSpinnerIfLoading>
    </div>
  );
};

export { SectionDetails };
