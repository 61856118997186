import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Dropdown } from '~/components/Dropdown';

import { SKILL_TEMPLATE_STATUS_WITH_NAMES } from '~/constants/skillTemplate';
import type { ISkillTemplateStatus } from '~/constants/skillTemplate';

export const FilterSurveyStatus = ({
  selectedItems,
  onChange,
}: {
  onChange: (selectedItems: ISkillTemplateStatus[]) => void;
  selectedItems: ISkillTemplateStatus[];
}) => {
  const { i18n } = useLingui();

  const statuses: ISkillTemplateStatus[] = Object.values(SKILL_TEMPLATE_STATUS_WITH_NAMES);

  return (
    <Dropdown
      items={statuses}
      selectedItems={selectedItems}
      placeholder={i18n._(t`Status`)}
      stringifyItem={(item) => item.translated(i18n)}
      onChange={onChange}
    />
  );
};
