import React from 'react';

import {
  ACTIVITY_PROGRESS_TYPES,
  ACTIVITY_STATUSES,
  PATH_STATUSES,
  SYMBOLS,
} from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Editor from '~/components/Editor';
import SelectDropDown from '~/components/SelectDropDown';
import { FieldGroup, FieldTitle, TextField } from '~/components/Text';

import { ACTIVITY_STATUS_STYLES } from '~/constants/activities';
import { COLOR_PALETTE, COLORS } from '~/styles';

const Content = styled.div`
  background: ${COLORS.BG_PAGE};
  padding: 16px 16px 0;
  border-radius: 6px;
  border: solid 1px ${COLOR_PALETTE.GRAY_SEMI_SOFT};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const ValueWrapper = styled.div`
  width: 52px;

  & input {
    text-align: center;
  }
`;

const StatusWrapper = styled.div`
  width: 130px;
`;

const MaxValue = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin: 0px 20px 0 8px;
`;

const EditorWrapper = styled.div`
  background: #fff;
`;

const WithColor = styled.div`
  color: ${(props) => props.color};
`;

const addPercentage = (label) => `${label}%`;

const UpdateProgressSection = ({
  status,
  listStatuses,
  progress,
  progressType,
  progressDetails,
  onChangeProgress,
  onChangeStatus,
  comment,
  onCommentChange,
  isDisabled,
  isShowWrapper = true,
}) => {
  const { i18n } = useLingui();
  const currency = get(progressDetails, 'currency');
  const max = get(progressDetails, 'max');

  const isDoneProgressType = progressType === ACTIVITY_PROGRESS_TYPES.IS_DONE; // progress should calc depends on status (complete = 100, other status = 0)
  const isPercentageProgressType = progressType === ACTIVITY_PROGRESS_TYPES.PERCENTAGE;

  const handleValueChange = (newValue, isFromHandleStatusChange = false) => {
    const value = Number(newValue);
    onChangeProgress(isNaN(value) ? 0 : value);
    if (value >= progressDetails.max && !isFromHandleStatusChange) {
      handleStatusChange(ACTIVITY_STATUSES.COMPLETED, true);
    }
  };

  const handleStatusChange = (status, isHandleValueChange = false) => {
    onChangeStatus(status);
    if (!isHandleValueChange) {
      let newValue = isDoneProgressType ? 0 : progress;
      if (status === ACTIVITY_STATUSES.COMPLETED) {
        newValue = Math.max(progressDetails.max, progress);
        handleValueChange(newValue, true);
      }
    }
  };

  const renderValue = () => {
    const valueString = `/${SYMBOLS[currency] || ''}${max}`;

    return (
      <FieldGroup>
        <FieldTitle>
          <Trans>New value</Trans>
        </FieldTitle>
        <Row>
          <ValueWrapper>
            <TextField
              value={String(progress)}
              $accent
              onChange={(e) => handleValueChange(e.target.value)}
              disabled={isDisabled}
            />
          </ValueWrapper>
          <MaxValue>{isPercentageProgressType ? addPercentage(valueString) : valueString}</MaxValue>
        </Row>
      </FieldGroup>
    );
  };

  const renderStatus = () => {
    return (
      <FieldGroup>
        <FieldTitle>
          <Trans>Status</Trans>
        </FieldTitle>
        <StatusWrapper>
          <SelectDropDown
            options={listStatuses}
            value={status}
            onChange={handleStatusChange}
            keyName="key"
            renderLabel={(item) => <WithColor color={item.color}>{item.title(i18n)}</WithColor>}
            disabled={isDisabled}
          />
        </StatusWrapper>
      </FieldGroup>
    );
  };

  const renderComment = () => {
    return (
      <FieldGroup>
        <FieldTitle>
          <Trans>Comment</Trans>
        </FieldTitle>
        <EditorWrapper>
          <Editor
            compact
            onChange={onCommentChange}
            value={comment || ''}
            placeholder={i18n._(t`Describe your progress...`)}
            readOnly={isDisabled}
          />
        </EditorWrapper>
      </FieldGroup>
    );
  };

  const content = (
    <>
      <Row>
        {!isDoneProgressType && renderValue()}
        {renderStatus()}
      </Row>
      {renderComment()}
    </>
  );

  return isShowWrapper ? <Content>{content}</Content> : content;
};

UpdateProgressSection.propTypes = {
  status: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  progressType: PropTypes.oneOf(Object.values(ACTIVITY_PROGRESS_TYPES)).isRequired,
  progressDetails: PropTypes.object.isRequired,
  onChangeProgress: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  comment: PropTypes.string,
  onCommentChange: PropTypes.func,
};

UpdateProgressSection.defaultProps = {
  listStatuses: Object.values(ACTIVITY_STATUS_STYLES).filter((s) => s.key !== PATH_STATUSES.ACTIVE),
};

export default UpdateProgressSection;
