import React, { ChangeEvent, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

import { IconOld, IconNames, IconsList } from '~/components/IconOld';
import { SearchField } from '~/components/Text';

import { COLORS } from '~/styles';

import type { IGeneralForm } from '../types';
import type { UseFormReturn } from 'react-hook-form';

interface IconPickerProps {
  formMethods: UseFormReturn<IGeneralForm>;
}

const IconsWrapper = styled.div`
  & .grid {
    width: 335px;
    max-height: 243px;
    overflow-y: scroll;
    margin-top: 9px;
    padding-right: 10px;

    display: flex;
    flex-direction: row-reverse;
    justify-content: end;
    flex-wrap: wrap;
    gap: 10px;

    & .iconWrapper {
      transform: rotateY(180deg);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      width: 32px;
      height: 32px;
      border-radius: 4px;
      background-color: ${COLORS.BG_PAGE};
      border: 1px solid ${COLORS.BG_PAGE};

      &:hover {
        background-image: linear-gradient(#dce4f3, #b6c5e4);
      }

      &.selected {
        border: 1px solid var(--company-color);
      }
    }

    &::-webkit-scrollbar {
      width: 8px;
      position: absolute;
      left: 0;
      top: -5px;
    }

    &::-webkit-scrollbar-track {
      background: ${COLORS.BG_PAGE};
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--company-color);
      border-radius: 100px;
    }

    &::-webkit-scrollbar:hover {
      cursor: pointer;
    }

    transform: rotateY(180deg);
  }
`;

const IconPicker = ({ formMethods }: IconPickerProps) => {
  const { i18n } = useLingui();
  const [search, setSearch] = useState('');
  const { control } = formMethods;
  const {
    field: { value, onChange },
  } = useController({ name: 'icon', control, rules: { required: true } });

  // @ts-ignore
  const icons: IconNames[] = Object.keys(IconsList).filter((key) =>
    search?.length ? key.match(new RegExp(search, 'i')) : true,
  );

  return (
    <div>
      {/* @ts-ignore */}
      <SearchField
        placeholder={i18n._(t`Search for icon`)}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
        value={search}
      />
      <IconsWrapper>
        <div className="grid">
          {icons.map((icon) => (
            <button
              type="button"
              className={`iconWrapper ${icon === value ? 'selected' : ''}`}
              key={icon}
              onClick={() => onChange(icon)}
            >
              <IconOld
                className="icon"
                name={icon}
                color={COLORS.MIDDLE_GRAY}
                width={24}
                height={24}
              />
            </button>
          ))}
        </div>
      </IconsWrapper>
    </div>
  );
};

export { IconPicker };
