export enum REVIEW_QUESTION_TYPES {
  TEXT = 'text',
  RATING = 'rating',
  CUSTOM_SKILL = 'custom-skill',
  SKILL_CATEGORY = 'skill-category',
  GOAL_PLAN = 'goal-plan',

  // TODO in v2
  // TRAFFIC = 'traffic',
  // STARS = 'stars',
  // SMILEYS = 'smileys',
}

// old types of questions, we maintain them only in old reviews
export enum REVIEW_QUESTION_TYPES_V1 {
  CUSTOM_SKILL_V1 = 'custom-skill-v1',
  JOB_PROFILE_V1 = 'job-profile-v1',
  GOAL_EVAL_BUSINESS_V1 = 'goal-eval-business-v1', // we use this only inside existing reviews (outside the review question will be transformed to RATING type per goal
  GOAL_EVAL_BUSINESS_AVG_V1 = 'goal-eval-business-avg-v1', // we use this only inside existing reviews (outside the review question will be transformed to RATING type per goal
  GOAL_EVAL_LEARNING_V1 = 'goal-eval-learning-v1', // we use this only inside existing reviews (outside the review question will be transformed to RATING type per goal
  GOAL_EVAL_LEARNING_AVG_V1 = 'goal-eval-learning-avg-v1', // we use this only inside existing reviews (outside the review question will be transformed to RATING type per goal
  GOAL_PLAN_BUSINESS_V1 = 'goal-plan-business-v1', // we use this only inside existing reviews (outside the review question will be transformed to GOAL_PLAN type per goal
  GOAL_PLAN_LEARNING_V1 = 'goal-plan-learning-v1', // we use this only inside existing reviews (outside the review question will be transformed to GOAL_PLAN type per goal
}

export enum REVIEW_QUESTION_EVALUATORS {
  EMPLOYEE = 'employee',
  PEER = 'peer',
  COACH = 'coach',
}
