import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import AvatarCard from '~/components/UI/AvatarCard';

import {
  PositionRelative,
  NoResultPlaceholder,
  SearchContainer,
  SearchRow,
} from './styledComponets';

const StyledAvatarCard = styled(AvatarCard)`
  margin-left: 12px;
`;

const SearchBlock = ({ userSearchResult, onClick }) => (
  <PositionRelative>
    <SearchContainer>
      {userSearchResult.length ? (
        userSearchResult.map((user) => (
          <SearchRow key={user.id} onClick={() => onClick(user)}>
            <StyledAvatarCard size={28} type="small" userId={user.id} />
          </SearchRow>
        ))
      ) : (
        <NoResultPlaceholder>
          <Trans>No results found</Trans>
        </NoResultPlaceholder>
      )}
    </SearchContainer>
  </PositionRelative>
);

export default SearchBlock;
