import React, { useEffect } from 'react';

import { REVIEW_QUESTION_EVALUATORS, REVIEW_STATUS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { compact, isEmpty, orderBy } from 'lodash';
import flatten from 'lodash/flatten';
import size from 'lodash/size';
import uniq from 'lodash/uniq';
import { Controller, type UseFormReturn } from 'react-hook-form';

import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import { Input } from '~/components/Input';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import ToolTip from '~/components/Tooltip';

import { StepFooter } from './components/StepFooter';
import {
  Form,
  InputContainer,
  Label,
  Section,
  StyledReviewTemplatesDropdown,
  Title,
  Description,
} from './design';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getSkillCategories } from '~/services/skillCategories';
import { getSkills } from '~/services/skills';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';
import { isNotNil } from '~/utils/typePredicates';

import type { IReviewIndividualForm } from './types';
import type {
  IMultiLangString,
  IReviewQuestion,
  IReviewTemplate,
  WithEvaluators,
} from '@learned/types';

interface StepGeneralProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewIndividualForm>;
  languageState: ILanguageStateReturn;
  reviewTemplates: IReviewTemplate[];
}

const StepGeneral = ({
  sectionState,
  formMethods,
  languageState,
  reviewTemplates,
}: StepGeneralProps) => {
  const { i18n } = useLingui();
  const { control, register, unregister, formState, trigger, watch, setValue } = formMethods;
  const getMultiLangString = useMultiLangString();
  const nameFieldArray = useMultiLangFieldArray({
    name: 'name',
    control,
    unregister,
    languageState,
  });

  const watchReviewTemplate = watch('reviewTemplate');
  const watchEvaluators = watch('evaluators');
  const watchFetchedCycle = watch('fetchedReview');
  const isDraft = watch('status') === REVIEW_STATUS.DRAFT;

  // @ts-ignore
  const reviewTemplateFromBackup = watchFetchedCycle?.backup?.reviewTemplate;
  // @ts-ignore
  const reviewQuestionsFromBackup = watchFetchedCycle?.backup?.reviewQuestions;

  const setSkillCategories = async (questions: IReviewQuestion[]) => {
    const categories = questions
      // @ts-ignore
      ?.map((question) => question.settings.skillCategory)
      .filter(isNotNil);
    const skillCategories = await getSkillCategories(categories);
    // @ts-ignore
    const result = await getSkills({ categories: compact(categories) });
    setValue('skills', Object.values(result.data.skills));
    setValue('skillCategories', skillCategories);
  };

  useEffect(() => {
    let uniqEvaluators: REVIEW_QUESTION_EVALUATORS[] = [];
    let uniqQuestionTypes: IReviewQuestion['type'][] = [];
    if (watchReviewTemplate) {
      // if backup exist (review not in DRAFT) - we get template from backup
      // because it's not possible to change anymore
      const selectedTemplate: IReviewTemplate | undefined = isDraft
        ? reviewTemplates?.find((item) => item.id === watchReviewTemplate)
        : reviewTemplateFromBackup;
      const questions: IReviewQuestion[] = isDraft
        ? (selectedTemplate?.questions as unknown as IReviewQuestion[])
        : Object.values(reviewQuestionsFromBackup || {});

      setSkillCategories(questions);

      uniqEvaluators = uniq(
        flatten(questions?.map((item) => (item?.settings as WithEvaluators)?.evaluators)),
      );

      uniqQuestionTypes = uniq(questions?.map((item) => item?.type));
      setValue(
        'reviewTemplateName',
        turnMultiLangIntoArray(
          (selectedTemplate?.name as IMultiLangString) || '',
          languageState.companyLanguages,
        ),
      );
    }

    const evaluators = orderBy(
      uniqEvaluators
        .map((item) => {
          switch (item) {
            case REVIEW_QUESTION_EVALUATORS.EMPLOYEE:
              return {
                value: REVIEW_QUESTION_EVALUATORS.EMPLOYEE,
                icon: ICONS.EMPLOYEE,
                title: i18n._(t`Employees`),
                rank: 1,
              };
            case REVIEW_QUESTION_EVALUATORS.COACH:
              return {
                value: REVIEW_QUESTION_EVALUATORS.COACH,
                icon: ICONS.COACH,
                title: i18n._(t`Coaches`),
                rank: 2,
              };
            case REVIEW_QUESTION_EVALUATORS.PEER:
              return {
                value: REVIEW_QUESTION_EVALUATORS.PEER,
                icon: ICONS.PEER,
                title: i18n._(t`Peers`),
                rank: 3,
              };
            default:
              return null;
          }
        })
        .filter(isNotNil),
      ['rank'],
    );

    setValue('evaluators', evaluators);
    setValue('reviewQuestionTypes', uniqQuestionTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewTemplates, watchReviewTemplate]);

  return (
    <Form>
      <Title>
        <Trans>General</Trans>
      </Title>
      <Section>
        <Label>
          <Trans>Name</Trans>
        </Label>
        <InputContainer>
          {nameFieldArray.fields.map((field) => (
            <Controller
              key={field.id}
              {...register(`name.${field.index}.value`)}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    error={!!formState.errors?.name}
                    onChange={(args) => {
                      onChange(args);
                      trigger('name');
                    }}
                    key={field.id}
                    width="540px"
                    height="38px"
                    leftIcon={
                      size(languageState.languages) > 1
                        ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                        : undefined
                    }
                    placeholder={i18n._(t`Example: Review Q1`)}
                  />
                );
              }}
            />
          ))}
        </InputContainer>
      </Section>
      <Section $marginTop="14px">
        <Label>
          <Trans>Select a review template</Trans>
        </Label>
        <ToolTip
          tooltip={i18n._(
            t`The selected template cannot be changed after the review has been published`,
          )}
          disabled={isDraft}
        >
          <InputContainer width="fit-content">
            <Controller
              {...register('reviewTemplate')}
              control={control}
              render={({ field: { onChange, value } }) => (
                <StyledReviewTemplatesDropdown
                  $error={!!formState.errors?.reviewTemplate}
                  placeholder={i18n._(t`Select review template`)}
                  // @ts-ignore
                  selectedItem={
                    isDraft
                      ? reviewTemplates.find((item) => value && item.id === value)
                      : reviewTemplateFromBackup
                  }
                  items={reviewTemplates}
                  onChange={(item) => item && onChange(item.id)}
                  stringifyItem={(item) => getMultiLangString(item.name)}
                  isSingleSelect
                  isSearchable={size(reviewTemplates) > 7}
                  isClickable={isDraft}
                />
              )}
            />
          </InputContainer>
        </ToolTip>
      </Section>
      {!!watchReviewTemplate && !isEmpty(watchEvaluators) && (
        <Section $marginTop="14px">
          <Label>
            <Trans>This template contains questions for</Trans>
          </Label>
          {watchEvaluators.map(({ title, icon }) => (
            <Description key={title}>
              <Icon icon={icon} size={ICON_SIZES.LARGE} />
              <span className="title">{title}</span>
            </Description>
          ))}
          <Description>
            <Trans>They will receive a task to provide input for this review.</Trans>
          </Description>
        </Section>
      )}
      <StepFooter
        isPrev={false}
        onNext={() => sectionState.setCurrentSection(++sectionState.currentSection)}
      />
    </Form>
  );
};

export { StepGeneral };
