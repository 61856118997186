import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { UseFormReturn } from 'react-hook-form';

import type { IReviewDashboardCycleForm } from '~/pages/Reviews/DashboardCycle/ReviewDashboardForm/types';
import { SectionHeader } from '~/pages/Reviews/DashboardCycle/tabs/Setup/components/SectionHeader';

import { SectionField } from '../components/SectionField';

interface ISectionGeneralProps {
  formMethods: UseFormReturn<IReviewDashboardCycleForm>;
  onEdit?: () => void;
}

const SectionParticipants = ({ formMethods, onEdit }: ISectionGeneralProps) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;

  const watchEmployeesTotal = watch('employeesTotal');
  return (
    <>
      <SectionHeader title={i18n._(t`Participants`)} onEdit={onEdit} />
      <SectionField value={watchEmployeesTotal} label={i18n._(t`Employees`)} />
    </>
  );
};

export { SectionParticipants };
