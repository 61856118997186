import React, { useContext, useEffect, useRef, useState } from 'react';

import { LUCA_INSIGHT_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { LucaSummaryUI } from './LucaSummaryUI';

import useBoolState from '~/hooks/useBoolState';
import * as reportsService from '~/services/reports';

import { EngagementReportContext } from '../EngagementContext';

type TProps = { id: string; type?: string };

const initialInsight = {
  id: null,
  status: LUCA_INSIGHT_STATUS.PENDING,
  message: '',
};
const LucaSummary = ({ id, type = 'REPORT' }: TProps): JSX.Element => {
  const { i18n } = useLingui();
  const { viewAs, reportType } = useContext(EngagementReportContext);
  const refreshInterval = useRef<number | undefined>();
  const $isLoading = useBoolState(false);
  const [insight, setInsight] = useState({ ...initialInsight });
  const { addToast } = useToasts();

  // TODO
  // The response should be received within 30 seconds
  // If not
  // TERMINATE THE polling after 35 seconds,
  // Show message to the user to try again in 30 minutes
  useEffect(() => {
    if (refreshInterval.current) {
      clearInterval(refreshInterval.current);
    }

    if (insight.status !== LUCA_INSIGHT_STATUS.SUCCEEDED && id.trim().length > 0) {
      const insightTarget = `${reportType.toUpperCase()}_REPORT_${id}`;
      refreshInterval.current = window.setInterval(async () => {
        try {
          const { data } = await reportsService.getAIInsight(reportType, viewAs, insightTarget);
          if (
            data.status === LUCA_INSIGHT_STATUS.READY ||
            data.status === LUCA_INSIGHT_STATUS.FAILED ||
            data.status === LUCA_INSIGHT_STATUS.NO_DATA
          ) {
            setInsight({
              id: data.id,
              status: LUCA_INSIGHT_STATUS.SUCCEEDED,
              message: data.responses[insightTarget],
            });
          }
        } catch {
          setInsight({
            ...initialInsight,
            status: LUCA_INSIGHT_STATUS.FAILED,
            message:
              'Afraid Luca could not come up with suggestions at the moment, please try again later',
          });
        }
      }, 5000);
    }

    return () => {
      clearInterval(refreshInterval.current);
    };
  }, [insight.status, id, type, viewAs, reportType]);

  const onRefresh = async () => {
    if (insight.id) {
      try {
        $isLoading.on();
        const response = await reportsService.deleteAIInsight(
          reportType,
          viewAs,
          insight.id,
          `${reportType}_REPORT_${id}`,
        );
        if (response.status === 'fail') {
          addToast({
            title: i18n._(t`Try again later`),
            subtitle: i18n._(t`You can only refresh the data once every 6 hours`),
            type: TOAST_TYPES.ERROR,
          });
        }
        if (refreshInterval.current) {
          clearInterval(refreshInterval.current);
        }
      } catch {
        addToast({
          title: i18n._(t`Something went wrong!`),
          type: TOAST_TYPES.ERROR,
        });
      } finally {
        $isLoading.off();
        setInsight({ ...initialInsight });
      }
    }
  };

  const isSucceeded = insight.status === LUCA_INSIGHT_STATUS.SUCCEEDED;

  return (
    <LucaSummaryUI
      isSucceeded={isSucceeded}
      onRefresh={onRefresh}
      insightResponse={insight.status}
      content={insight.message}
      showActionButton
      isScrollable={false}
    />
  );
};

export { LucaSummary };
