import React from 'react';

import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import secondaryCheckBoxChecked from '~/assets/3.0/checkbox-checked.svg';
import secondaryCheckBoxUnchecked from '~/assets/3.0/checkbox.svg';
import checkboxCheckedRound from '~/assets/checkbox-checked-round.svg';
import checkboxChecked from '~/assets/checkbox-checked.svg';
import checkboxUncheckedRound from '~/assets/checkbox-unchecked-round.svg';
import checkboxUnchecked from '~/assets/checkbox-unchecked.svg';

import { COLORS } from '~/styles';

const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
`;

const CheckBox = ({
  checked,
  onChange,
  className,
  size,
  disabled,
  isDefaultColor,
  defaultColor,
  roundCheckbox,
  isSecondary = false,
}) => {
  const calculateSize = () => {
    if (typeof size === 'number') {
      return size + 'px';
    }
    return size;
  };

  const svgSize = calculateSize();

  return (
    <CheckboxWrap
      aria-disabled={disabled}
      $disabled={disabled}
      onClick={(e) => !disabled && onChange(e, !checked)}
      className={className}
    >
      {checked ? (
        <SvgIcon
          width={svgSize}
          height={svgSize}
          url={
            roundCheckbox
              ? checkboxCheckedRound
              : isSecondary
              ? secondaryCheckBoxChecked
              : checkboxChecked
          }
          isDefaultColor={!!defaultColor}
          defaultColor={disabled ? COLORS.DISABLED : defaultColor}
        />
      ) : (
        <SvgIcon
          width={svgSize}
          height={svgSize}
          url={
            roundCheckbox
              ? checkboxUncheckedRound
              : isSecondary
              ? secondaryCheckBoxUnchecked
              : checkboxUnchecked
          }
          isDefaultColor={isDefaultColor}
          defaultColor={disabled ? COLORS.DISABLED : defaultColor || COLORS.BORDER}
        />
      )}
    </CheckboxWrap>
  );
};

CheckBox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  isDefaultColor: PropTypes.bool,
  roundCheckbox: PropTypes.bool,
  defaultColor: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  isSecondary: PropTypes.bool,
};

CheckBox.defaultProps = {
  size: 18,
  onChange: () => {},
  isDefaultColor: true,
  roundCheckbox: false,
};

export default CheckBox;
