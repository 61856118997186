import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const DateRow = styled.div<{
  marginTop?: string;
  marginBottom?: string;
}>`
  display: flex;
  gap: 16px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0px')};

  label {
    color: ${COLORS.SUBTEXT};
  }
`;

export const SubTitleRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ExplanationText = styled.div<{
  marginTop?: string;
  marginBottom?: string;
}>`
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${COLORS.SUBTEXT};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '10px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '16px')};
`;

export const AdvancedSettingsWrapper = styled.div<{ isCollapsed?: boolean }>`
  & .head {
    padding: 22px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    user-select: none;
    border-top: 1px solid ${COLORS.BORDERS};
    border-bottom: 1px solid ${COLORS.BORDERS};

    & .title {
      font-size: 16px;
      font-weight: 600;
      color: ${COLORS.TEXT_HOVER};
    }

    & .icon {
      display: flex;
      align-items: center;
      transform: ${({ isCollapsed }) => (isCollapsed ? 'rotate(-90deg)' : 'unset')};
    }
  }

  & .content {
    display: flex;
    flex-direction: column;
    display: ${({ isCollapsed }) => (isCollapsed ? 'flex' : 'none')};
  }

  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      padding-bottom: 10px;
    `};
`;
