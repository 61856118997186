import React from 'react';

import { ICompany } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '~/components/Button';
import ButtonFile from '~/components/ButtonFile';

import { CompanyAvatarPreview, WrapperPad, CompanyAvatar } from '../design';

const UploadLogo = ({
  deleteLogo,
  company,
  onUploadChange,
}: {
  company: ICompany;
  onUploadChange: (file: File | null) => void;
  deleteLogo: () => void;
}) => {
  const { i18n } = useLingui();

  return (
    <>
      <WrapperPad>
        <CompanyAvatar
          style={{
            backgroundImage: company.logoUrl ? `url(${company.logoUrl})` : '',
          }}
        >
          {!company.logoUrl && <CompanyAvatarPreview>Preview</CompanyAvatarPreview>}
        </CompanyAvatar>
      </WrapperPad>

      <WrapperPad>
        <Button
          label={i18n._(t`Reset`)}
          type="primary-border"
          onClick={deleteLogo}
          disabled={!company.logoUrl}
        />
      </WrapperPad>

      <WrapperPad>
        {/* @ts-ignore */}
        <ButtonFile onChange={onUploadChange} />
      </WrapperPad>
    </>
  );
};

export { UploadLogo };
