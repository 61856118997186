import { ROLES } from '~/constants';
import { pageDashboardREST } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'goal-tasks';
const defaultGoalTaskId = ':goalTaskId';

// dashboard
export const GOAL_TASK = new LinkConstructor(
  [ROLES.USER],
  ({ goalTaskId = defaultGoalTaskId }) => `${pageDashboardREST(routeName, { id: goalTaskId })}`,
);
