import React, { memo, useState } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import ConversationAttachments from '~/components/ConversationAttachments';
import DigitalMeetingLink from '~/components/DigitalMeetingLink';
import IconButton from '~/components/IconButton';
import RickTextView from '~/components/RickTextView';
import SvgIcon from '~/components/SvgIcon';
import Tabs from '~/components/Tabs';
import Tooltip from '~/components/Tooltip';
import AvatarGroup from '~/components/UI/AvatarGroup';

import TalkingPointTab from './components/TalkingPointTab';

import previousDateIcon from '~/assets/arrow-left.svg';
import upcomingDateIcon from '~/assets/arrow-right.svg';
import lockIcon from '~/assets/conversation-lock.svg';
import penIcon from '~/assets/mdi-pencil-outline.svg';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { getSelectedRole } from '~/selectors/baseGetters';
import { COLOR_PALETTE, COLORS } from '~/styles';

import { CONVERS_TOAST_TYPES } from '../../userConversationConstants';

const AttachmentsWrapper = styled.div`
  padding: 20px;
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};

  &:hover {
    ${(props) => props.$clickeable && 'cursor: pointer;'}
  }
`;

const HeaderNavigation = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const MainHeader = styled.div`
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  font-weight: bold;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin-right: 16px;
  display: inline;
`;

const MainHeaderWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
`;

const StyledSvg = styled(SvgIcon)`
  margin-left: 12px;
`;

const SubHeader = styled(RickTextView)`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin: -12px 0 8px 0;
  padding: 0 24px;
`;

const TitleWrapp = styled.div`
  display: inline-block;
  margin-left: 16px;
`;

const TitleInfoWrapp = styled.div`
  display: inline-flex;
  padding-top: 6px;
`;

const TABS = {
  talkingPoints: 'point',
  attachments: 'attachments',
};

const tabItems = [
  {
    key: TABS.talkingPoints,
    label: t`Talking Points`,
  },
  {
    key: TABS.attachments,
    label: t`Attachments`,
  },
];

const ConversationContent = ({
  name,
  talkingPoints,
  doneTP,
  openTPModal,
  readOnly,
  conversationId,
  attachments,
  onAddAttachment,
  onDeleteAttachment,
  currentUserId,
  description,
  $conversation,
  currentRole,
  i18n,
  date,
  participants,
  eventModal,
  events,
  toast,
  backpath,
  coachTeams,
}) => {
  const [activeTab, setActiveTab] = useState(TABS.talkingPoints);
  const role = useSelector(getSelectedRole);
  const $isWritingComment = useBoolState(false);
  const isOwner = get($conversation, 'createdBy.id') === currentUserId && role !== ROLES.ADMIN;
  const $upcomingEvents = [];
  const $passedEvents = [];

  let upcoming = false;
  const tempEvents = events.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
  tempEvents.forEach((event) => {
    if (upcoming) {
      $upcomingEvents.push(event);
    } else {
      if (event.id === conversationId) {
        upcoming = true;
      } else {
        $passedEvents.push(event);
      }
    }
  });

  const changeActiveTab = (tab) => {
    if ($isWritingComment.value) {
      if (
        confirm(
          i18n._(t`Are you sure you want to exit this screen? Not saved comments will be lost.`),
        )
      ) {
        setActiveTab(tab);
        $isWritingComment.off();
      }
    } else {
      setActiveTab(tab);
    }
  };

  const goToConversation = (eventId) => {
    toast(CONVERS_TOAST_TYPES.SWITCH_1_1);
    const settings = {
      conversationId: eventId,
      isBackPath: true,
      backPathDefault: backpath,
    };
    if (currentRole === ROLES.USER) {
      return routes.CONVERSATION.go({}, settings);
    }
    routes.CONVERSATION_SUPERVISION_USER.go({}, settings);
  };

  const goToPassedEvent = () => {
    const eventId = $passedEvents[$passedEvents.length - 1].id;
    goToConversation(eventId);
  };

  const goToUpcomingEvent = () => {
    const eventId = $upcomingEvents[0].id;
    goToConversation(eventId);
  };

  return (
    <div>
      <HeaderWrapper>
        <MainHeaderWrapper>
          <AvatarGroup maxUsersToShow={5} users={participants} />
          <TitleWrapp>
            <MainHeader>{name}</MainHeader>
            <TitleInfoWrapp>
              {events.length > 1 && (
                <>
                  <IconButton
                    size={20}
                    onClick={() => {
                      if ($passedEvents.length > 0) {
                        goToPassedEvent();
                      }
                    }}
                    noBorder={true}
                    disabled={$passedEvents.length === 0}
                  >
                    <SvgIcon
                      width="20px"
                      height="20px"
                      isDefaultColor
                      defaultColor={
                        $passedEvents.length > 0 ? 'var(--company-color)' : COLORS.DISABLED
                      }
                      url={previousDateIcon}
                    />
                  </IconButton>
                  <IconButton
                    size={20}
                    onClick={() => {
                      if ($upcomingEvents.length > 0) {
                        goToUpcomingEvent();
                      }
                    }}
                    noBorder={true}
                    disabled={$upcomingEvents.length === 0}
                  >
                    <SvgIcon
                      width="20px"
                      height="20px"
                      isDefaultColor
                      defaultColor={
                        $upcomingEvents.length === 0 ? COLORS.DISABLED : 'var(--company-color)'
                      }
                      url={upcomingDateIcon}
                    />
                  </IconButton>
                </>
              )}
              <DateContainer
                $clickeable={isOwner}
                onClick={() => {
                  isOwner && eventModal.on();
                }}
              >
                {date}
                {isOwner && (
                  <StyledSvg
                    width="20px"
                    height="20px"
                    isDefaultColor
                    defaultColor="var(--company-color)"
                    url={penIcon}
                  />
                )}
              </DateContainer>
              {$conversation.originalConversation?.includeLinkMeeting && (
                <DigitalMeetingLink
                  event={$conversation.calendarEvent}
                  updateCalendarEvent={$conversation.updateCalendarEvent}
                />
              )}
            </TitleInfoWrapp>
          </TitleWrapp>
          <HeaderNavigation>
            {!$conversation.canComplete && false && !$conversation.isAdminLockedConversation && (
              <Tooltip
                tooltip={i18n._(
                  $conversation.isCompleted ? t`Unlock conversation.` : t`Lock conversation.`,
                )}
              >
                <div>
                  <SvgIcon
                    width="28px"
                    height="28px"
                    isDefaultColor
                    defaultColor={
                      $conversation.isCompleted ? 'var(--company-color)' : COLOR_PALETTE.GRAY_MIDDLE
                    }
                    url={lockIcon}
                    onClick={
                      $conversation.isApprovalFlow
                        ? () => {}
                        : $conversation.toggleConversationStatus
                    }
                  />
                </div>
              </Tooltip>
            )}
          </HeaderNavigation>
        </MainHeaderWrapper>
        <SubHeader html={description} />
      </HeaderWrapper>
      <Tabs
        noMarginBottom={true}
        currentItem={activeTab}
        marginBetweenTabs="24px"
        items={tabItems}
        handleChangeTab={changeActiveTab}
        isSmall
        border
      />
      {activeTab === TABS.talkingPoints && (
        <TalkingPointTab
          talkingPoints={talkingPoints}
          doneTP={doneTP}
          openTPModal={openTPModal}
          readOnly={readOnly}
          currentUserId={currentUserId}
          conversationId={conversationId}
          TPOrder={$conversation.TPOrder}
          reorderTalkingPoints={$conversation.reorderTalkingPoints}
          writingComment={$isWritingComment}
          $conversation={$conversation}
          coachTeams={coachTeams}
        />
      )}
      {activeTab === TABS.attachments && (
        <AttachmentsWrapper>
          <ConversationAttachments
            conversationId={conversationId}
            files={attachments}
            onAdd={onAddAttachment}
            onDelete={onDeleteAttachment}
            readOnly={readOnly}
          />
        </AttachmentsWrapper>
      )}
    </div>
  );
};

export default memo(withI18n()(ConversationContent));
