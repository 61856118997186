import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import DashboardCard from '~/components/DashboardCard';
import FeedbackReport from '~/components/FeedbackReport';
import FeedbackThanksModal from '~/components/FeedbackThanksModal';
import IconMenu from '~/components/IconMenu';
import SkillsRateModal from '~/components/SkillsRateModal';
import BaseLayout from '~/layouts/BaseLayout';

import { RATING_TYPES, REQUEST_STATUSES } from '~/constants';
import routes from '~/constants/routes';
import { acceptRequest } from '~/services/requests';
import { getRTFeedback, deleteRTFeedback } from '~/services/RTFeedbacks';
import getRTFeedbackUsers from '~/utils/getRTFeedbackUsers';

const IconMenuWrapper = styled.div`
  margin-bottom: -16px;
`;

class RTFeedbackSkills extends Component {
  constructor() {
    super();
    this.state = {
      RTFeedback: {},
      showMultiRateModal: false,
      isRTFeedbackThankYouModal: false,
    };
  }

  componentDidMount = () => {
    this.fetchRTFeedback(this.props);
  };

  fetchRTFeedback = (props) => {
    const { match } = props;
    const { RTFeedback } = this.state;

    const { rtfeedbackId } = match.params;
    if (rtfeedbackId && isEmpty(RTFeedback)) {
      getRTFeedback(rtfeedbackId, {
        isSubRTFeedbacks: true,
        isQuestions: true,
        join: ['ratings'],
        populate: ['skills'],
      }).then((RTFeedback) => {
        this.setState({ RTFeedback });
      });
    }
  };

  showThankYouModal = () => {
    this.setState({ isRTFeedbackThankYouModal: true, showMultiRateModal: false });
  };
  hideThankYouModal = () => {
    this.setState({ isRTFeedbackThankYouModal: false });
  };
  showMultiRateModal = () => {
    this.setState({ showMultiRateModal: true });
  };
  hideMultiRateModal = () => {
    this.setState({ showMultiRateModal: false });
  };

  onRated = async (isShared = false) => {
    const { RTFeedback } = this.state;
    const review = get(RTFeedback.RTFeedbacks, 0, {});
    await acceptRequest(
      review.request.id,
      isShared ? REQUEST_STATUSES.ACCEPTED.key : REQUEST_STATUSES.STARTED.key,
    );
    this.fetchRTFeedback(this.props);
    if (isShared) {
      this.showThankYouModal();
    } else {
      this.setState({
        showMultiRateModal: false,
      });
    }
  };

  removeRTFeedback = () => {
    const { match, history } = this.props;

    let backPath = routes.RTFEEDBACKS.build();
    let href = window.location.href;
    let url = new URL(href);
    let from = url.searchParams.get('from');
    const { rtfeedbackId } = match.params;

    if (from) {
      backPath = from;
    }

    deleteRTFeedback(rtfeedbackId).then(() => {
      history.push(backPath);
    });
  };

  renderFeedbackReport = () => {
    const { users } = this.props;
    const { RTFeedback } = this.state;
    const createdDate = get(RTFeedback, 'meta.createdDate', '');
    const reviewName = get(RTFeedback, 'name', '');
    const items = get(RTFeedback, 'subRTFeedbacks', []);
    const requestEndDate = get(RTFeedback, 'request.meta.lastModifiedDate');
    const RTFeedbackUsers = getRTFeedbackUsers(RTFeedback, users);

    return (
      <FeedbackReport
        name={reviewName}
        endDate={requestEndDate}
        usersFor={RTFeedbackUsers}
        description={get(RTFeedback, 'description')}
        type={get(RTFeedback, 'subType')}
        isFor={true}
        createdDate={createdDate}
        items={items}
        original={RTFeedback}
      />
    );
  };

  render() {
    const { i18n, users } = this.props;
    const { RTFeedback, showMultiRateModal, isRTFeedbackThankYouModal } = this.state;

    let backPath = routes.RTFEEDBACKS.build();
    let href = window.location.href;
    let url = new URL(href);
    let from = url.searchParams.get('from');
    const fromUser = RTFeedback && RTFeedback.createdBy ? users[RTFeedback.createdBy] : '';
    const review = get(RTFeedback.RTFeedbacks, 0, {});
    if (from) {
      backPath = from;
    }

    const menuItems = [
      {
        order: 0,
        label: i18n._(t`Delete feedback request`),
        action: this.removeRTFeedback,
      },
    ];
    if (RTFeedback.isSelf && !isEmpty(review)) {
      menuItems.push({
        order: 0,
        label: review.feedback || review.ratings ? i18n._(t`Update`) : i18n._(t`Provide`),
        action: this.showMultiRateModal,
      });
    }

    return (
      <BaseLayout>
        <DashboardCard
          title={i18n._(t`Feedback report`)}
          backPath={backPath}
          renderActions={() => (
            <IconMenuWrapper>
              <IconMenu items={menuItems} size={48} iconSize={24} />
            </IconMenuWrapper>
          )}
        >
          {this.renderFeedbackReport()}
        </DashboardCard>

        {showMultiRateModal && (
          <SkillsRateModal
            type={
              RTFeedback.createdBy === RTFeedback.createdFor
                ? RATING_TYPES.SELF_RATING
                : RATING_TYPES.OTHER_RATING
            }
            onClose={this.hideMultiRateModal}
            onRated={this.onRated}
            forUser={fromUser}
            review={review}
            isRealTime
          />
        )}
        {isRTFeedbackThankYouModal && (
          <FeedbackThanksModal fromUser={fromUser} onClose={this.hideThankYouModal} isSelfReview />
        )}
      </BaseLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users.data,
    currentRole: state.selected.role,
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(RTFeedbackSkills)));
