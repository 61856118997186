import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Card = styled.div<{
  marginTop?: string;
  noFrontPadding?: boolean;
}>`
  width: 750px;
  margin-top: ${({ marginTop }) => marginTop || '16px'};
  background-color: ${COLORS.WHITE};
  padding: ${({ noFrontPadding }) => (noFrontPadding ? '30px 30px 40px 0px' : '30px 40px')};
  border-radius: 10px;
  box-sizing: border-box;
`;

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 750px;
  max-width: 750px;
`;

export const HeaderWrapper = styled.div`
  width: fit-content;
  margin-top: -10px;
  margin-bottom: 25px;
`;

export const CardTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: ${COLORS.TEXT_MAIN};
  margin-bottom: 12px;
`;

export const Text = styled.div<{
  isBold?: boolean;
}>`
  font-size: 14px;
  font-weight: ${({ isBold }) => (isBold ? '500' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
`;

export const PlaceholderText = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${COLORS.SUBTEXT};
  margin-bottom: 24px;
`;
