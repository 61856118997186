import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const Plus2Icon = ({ size, ...props }) => (
  <InlineSvg width={size} height={size} viewBox="0 0 13 13" {...props}>
    <g
      stroke="currentColor"
      strokeWidth="1.2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M1.5 6.5h10M6.5 1.5v10" />
    </g>
  </InlineSvg>
);

Plus2Icon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

Plus2Icon.defaultProps = {
  size: 24,
  className: '',
};

export default Plus2Icon;
