import React from 'react';

import { COMPANY_DELETE_REQUEST_STATUSES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import RickTextView from '~/components/RickTextView';
import Tooltip from '~/components/Tooltip';
import AvatarCard from '~/components/UI/AvatarCard';
import { TableCol, TableRow } from '~/components/UI/Table';

import ProcessRequestModal from './ProcessRequestModal';

import useBoolState from '~/hooks/useBoolState';
import { getUser } from '~/selectors/baseGetters';
import { COLOR_PALETTE, COLORS } from '~/styles';

const StyledRow = styled(TableRow)`
  padding: 0 16px;
  align-items: center;
`;

const CompanyName = styled(TableCol)`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${COLOR_PALETTE.BLACK};
`;

const CompanyText = styled(TableCol)`
  font-size: 14px;
  line-height: 1.57;
  color: ${(props) => props.$color || COLOR_PALETTE.DARK_GRAY};
`;

const ButtonGroup = styled(TableCol)`
  display: flex;
  align-items: center;
`;

const TooltipWhiteText = styled(RickTextView)`
  color: ${COLOR_PALETTE.WHITE};
`;

const RequestRow = ({ deleteRequest, forceFetch }) => {
  const { i18n } = useLingui();
  const user = useSelector(getUser);
  const $isResponseModal = useBoolState(false);

  const renderActions = () => {
    switch (deleteRequest.status) {
      case COMPANY_DELETE_REQUEST_STATUSES.ACCEPTED: {
        return (
          <CompanyText $color="var(--company-color)">
            <Tooltip
              tooltip={<TooltipWhiteText html={deleteRequest.processedComment} />}
              disabled={!deleteRequest.processedComment}
            >
              <span>{deleteRequest.status}</span>
            </Tooltip>
          </CompanyText>
        );
      }
      case COMPANY_DELETE_REQUEST_STATUSES.REJECTED: {
        return (
          <CompanyText $color={COLORS.ERROR}>
            <Tooltip
              tooltip={<TooltipWhiteText html={deleteRequest.processedComment} />}
              disabled={!deleteRequest.processedComment}
            >
              <span>{deleteRequest.status}</span>
            </Tooltip>
          </CompanyText>
        );
      }
      case COMPANY_DELETE_REQUEST_STATUSES.REQUESTED: {
        return user?.superAdminLevel !== undefined && user?.superAdminLevel >= 1 ? (
          <ButtonGroup>
            <Button
              label={i18n._(t`Handle`)}
              onClick={$isResponseModal.on}
              type={Button.types.primaryBorder}
            />
          </ButtonGroup>
        ) : (
          <CompanyText>{deleteRequest.status}</CompanyText>
        );
      }
      default:
        return;
    }
  };

  return (
    <StyledRow key={deleteRequest.id}>
      <CompanyName>{deleteRequest.name}</CompanyName>
      <CompanyText>
        {deleteRequest.createdBy && (
          <AvatarCard
            showTooltip
            userLocked={deleteRequest.createdBy}
            type={AvatarCard.TYPES.MEDIUM}
          />
        )}
      </CompanyText>
      <CompanyText>
        {deleteRequest?.meta?.createdDate &&
          moment(deleteRequest?.meta?.createdDate).format('DD/MM/YYYY HH:mm:ss')}
      </CompanyText>
      <CompanyText>
        {deleteRequest.processedBy && (
          <AvatarCard
            showTooltip
            userLocked={deleteRequest.processedBy}
            type={AvatarCard.TYPES.MEDIUM}
          />
        )}
      </CompanyText>
      <CompanyText>
        {deleteRequest.processedDate &&
          moment(deleteRequest.processedDate).format('DD/MM/YYYY HH:mm:ss')}
      </CompanyText>
      {renderActions()}
      {$isResponseModal.value && (
        <ProcessRequestModal
          deleteRequest={deleteRequest}
          onClose={$isResponseModal.off}
          forceFetch={forceFetch}
        />
      )}
    </StyledRow>
  );
};

export default React.memo(RequestRow);
