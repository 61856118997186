import { joiResolver } from '@hookform/resolvers/joi';
import {
  SURVEY_FREQUENCY,
  SURVEY_PARTICIPANTS_TYPE,
  SURVEY_STATUS,
  SURVEY_TYPE,
} from '@learned/constants';
import Joi from 'joi';

const Errors = {
  missingPrimaryLang: 'missingPrimaryLang',
};

const status = Joi.string().valid(...Object.values(SURVEY_STATUS));
const template = Joi.when('status', {
  is: SURVEY_STATUS.DRAFT,
  then: Joi.object().allow(null).required(),
  otherwise: Joi.object().required(),
});
const type = Joi.string().valid(...Object.values(SURVEY_TYPE));
const anonymity = Joi.object().keys({
  isAnonymous: Joi.boolean().required(),
  reportingThreshold: Joi.number().required(),
});
const isShared = Joi.boolean();
const participants = Joi.object().keys({
  type: Joi.string()
    .valid(...Object.values(SURVEY_PARTICIPANTS_TYPE))
    .required(),
  ids: Joi.when(Joi.ref('status', { ancestor: 2 }), {
    is: SURVEY_STATUS.DRAFT,
    then: Joi.array()
      .items(
        Joi.when(Joi.ref('...type'), {
          is: SURVEY_PARTICIPANTS_TYPE.COMPANY,
          then: Joi.string(),
          otherwise: Joi.object(),
        }),
      )
      .min(0)
      .required(),
    otherwise: Joi.array()
      .items(
        Joi.when(Joi.ref('...type'), {
          is: SURVEY_PARTICIPANTS_TYPE.COMPANY,
          then: Joi.string(),
          otherwise: Joi.object(),
        }),
      )
      .min(1)
      .required(),
  }),
});
const dates = Joi.when('type', {
  is: SURVEY_TYPE.TRADITIONAL,
  then: Joi.object()
    .keys({
      startDate: Joi.date().iso().required(),
      endDate: Joi.date().iso().greater(Joi.ref('startDate')).required(),
    })
    .required(),
  otherwise: Joi.object().keys({
    startDate: Joi.date().iso().required(),
    endDate: Joi.date().iso().greater(Joi.ref('startDate')).allow(null).required(),
    isEndDate: Joi.boolean().required(),
  }),
});
const notifications = Joi.when('type', {
  is: SURVEY_TYPE.TRADITIONAL,
  then: Joi.object()
    .keys({
      isOnStartDate: Joi.boolean().required(),
      isOnEndDate: Joi.boolean().required(),
      isOn2DaysBeforeEndDate: Joi.boolean().required(),
    })
    .required(),
  otherwise: Joi.object()
    .keys({
      isOnStartDate: Joi.boolean().required(),
      isOnEndDate: Joi.boolean().required(),
    })
    .required(),
});
const pulse = Joi.when('type', {
  is: SURVEY_TYPE.PULSE,
  then: Joi.object()
    .keys({
      frequency: Joi.string()
        .valid(...Object.values(SURVEY_FREQUENCY))
        .required(),
      questionsPerParticipant: Joi.number().min(1).required(),
    })
    .required(),
});

const schema = Joi.object({
  name: Joi.array()
    .items(
      Joi.object({
        locale: Joi.string(),
        value: Joi.string().allow(''),
      }),
    )
    .custom((items: { locale: string; value: string }[], helpers) => {
      const companyPrimaryLanguage: string | undefined =
        helpers?.prefs?.context?.companyPrimaryLanguage;
      if (
        companyPrimaryLanguage &&
        !items
          .filter((item) => !!item.value)
          .map((item) => item.locale)
          .includes(companyPrimaryLanguage)
      ) {
        return helpers.message({ custom: Errors.missingPrimaryLang });
      }
      return items;
    }),
  description: Joi.array().items(
    Joi.object({
      locale: Joi.string(),
      value: Joi.string().allow(''),
    }),
  ),
  template: template.required(),
  anonymity: anonymity.required(),
  isShared: isShared.required(),
  participants: participants.required(),
  type: type.required(),
  status: status.required(),

  // difference depends on type
  dates: dates.required(),
  notifications: notifications.required(),
  pulse,
});

const resolver = joiResolver(schema);

export { resolver };
