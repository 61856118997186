import FileSaver from 'file-saver';
import isEmpty from 'lodash/isEmpty';

import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

/**
 * @deprecated since Review 3.0 (check reviews)
 */
// isGrouped - works only for COACH role
export function getReviews(body, { signal } = {}) {
  return cloudRequest(serverRoutes.reviews.items, {}, body, { signal });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function getUserReviews(userId, { filters = {}, isJoinDraft = false } = {}) {
  return cloudRequest(serverRoutes.reviews.getUserReviews, { ...filters, userId, isJoinDraft });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function getReview(
  id,
  {
    populate = [],
    join = [],
    isSubReviews = false,
    isQuestions = false,
    isConversations = false,
    isAvg = false,
    isInterviewee = false,
  } = {},
) {
  return cloudRequest(serverRoutes.reviews.getReview, {
    id,
    populate,
    join,
    isSubReviews,
    isQuestions,
    isConversations,
    isAvg,
    isInterviewee,
  });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function getFilteredReview(
  id,
  {
    populate = [],
    join = [],
    isSubReviews = false,
    isSubReviewsTotalCount = false,
    isQuestions = false,
    isConversations = false,
    isAvg = false,
    isWithCalibrateRatings = false,
    isDontGetSkillsJobProfiles = false,
  } = {},
  { filters = {}, sort = {}, pagination = {}, KPIs } = {},
) {
  return cloudRequest(
    serverRoutes.reviews.getFilteredReview,
    {
      id,
      populate,
      join,
      isSubReviews,
      isSubReviewsTotalCount,
      isQuestions,
      isConversations,
      isAvg,
      isWithCalibrateRatings,
      isDontGetSkillsJobProfiles,
    },
    { filters, sort, pagination, KPIs },
  );
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function deleteReview(reviewId) {
  return cloudRequest(serverRoutes.reviews.deleteReview, { id: reviewId });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function setSelfReviewStatus(reviewId, isDraft) {
  return cloudRequest(serverRoutes.reviews.setSelfReviewStatus, { id: reviewId }, { isDraft });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function getNineGridReviews() {
  return cloudRequest(serverRoutes.reviews.getNineGridReviews);
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function getReviewLogs(reviewId) {
  return cloudRequest(serverRoutes.reviews.getReviewLogs(reviewId));
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
/**
 *
 * @param pagination = { skip: number, limit: number }
 * @param sectionTypes = ['skills', 'potential', 'performance'], by default empty - show all
 * @param reviews = ['reviewId1', 'reviewId2'], by default empty - show all
 * @param members = ['userId1', 'userId2'], by default empty - show all
 * @param skills = ['skillId1', 'skillId2'], by default empty - show all
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getReviewsReport({
  pagination = {},
  sectionTypes,
  reviews = [],
  members = [],
  skills = [],
} = {}) {
  return cloudRequest(serverRoutes.reviews.getReviewsReport, {
    pagination: JSON.stringify(pagination),
    sectionTypes: sectionTypes ? JSON.stringify(sectionTypes) : null,
    ...(!isEmpty(reviews) && { reviews: JSON.stringify(reviews) }),
    ...(!isEmpty(members) && { members: JSON.stringify(members) }),
    ...(!isEmpty(skills) && { skills: JSON.stringify(skills) }),
  });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function getReviewsFilter({ search, limit = 0 } = {}) {
  return cloudRequest(serverRoutes.reviews.getReviewsFilter, { search, limit });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function downloadNinegridCSV({ reviewIds, KPIs, jobProfiles, users } = {}) {
  return cloudRequest(serverRoutes.reviews.downloadNinegridCSV, {
    reviewIds,
    KPIs,
    jobProfiles,
    users,
  }).then((response) => {
    FileSaver.saveAs(response, 'ninegridreport.csv');
  });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function updateGoalCycleForUserReview(reviewId) {
  return cloudRequest(serverRoutes.reviews.updateGoalCycleForUserReview, { reviewId });
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function addSignature(reviewId, body) {
  return cloudRequest(serverRoutes.reviews.addSignature(reviewId), null, body);
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function deleteSignatures(reviewId) {
  return cloudRequest(serverRoutes.reviews.deleteSignatures(reviewId));
}

/**
 * @deprecated since Review 3.0 (check reviews)
 */
export function getReviewsForDevelopmentCycle(filters = {}) {
  return cloudRequest(serverRoutes.reviews.getReviewsForDevelopmentCycle, filters);
}
