import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

const Container = styled.div<{
  isActive?: boolean;
  hasPlaceholder?: boolean;
  enablePadding?: boolean;
  isInnerHovered?: boolean;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDERS};
  min-height: 38px;
  max-height: 114px;
  border-color: ${({ isActive }) => (isActive ? COLORS.COMPANY : COLORS.BORDERS)};
  padding: ${({ enablePadding }) => (enablePadding ? '5px' : 0)}
    ${({ hasPlaceholder }) => (hasPlaceholder ? 21 : 5)}px;
  overflow-y: auto;
  ${({ isInnerHovered }) =>
    !isInnerHovered &&
    css`
      &:hover {
        background-color: ${COLORS.HOVER};
        input {
          background-color: ${COLORS.HOVER};
        }
      }
    `}

  align-items: center;
  row-gap: 5px;

  &:focus-within {
    border-color: ${COLORS.COMPANY};
  }
`;

const TextInput = styled.input`
  flex: 1;
  font-size: 14px;
  border: none;
  z-index: 2;
  height: 28px;
  ::placeholder {
    font-size: 14px;
    color: ${COLORS.PLACEHOLDERS};
  }
  min-width: 200px;
`;

const Label = styled.span`
  display: flex;
  flex-direction: row;
  padding: 0 8px;
  height: 28px;
  margin-right: 4px;
  border-radius: 6px;
  background-color: ${COLORS.BG_ELEMENTS};
  color: ${COLORS.TEXT_MAIN};
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 14px;

  :hover {
    color: ${COLORS.TEXT_HOVER};
    background-color: ${COLORS.HOVER};
    cursor: pointer;
  }

  svg {
    :hover {
      color: ${COLORS.ACCENT_ERROR};
    }
  }
`;

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownContent = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  min-width: 227px;
  border-radius: 6px;
  box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
  border: solid 1px ${COLORS.BORDERS};
  background-color: ${COLORS.BG_LIST};
`;

const DropdownOption = styled.div<{ isValid?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  background-color: ${COLORS.WHITE};
  color: ${({ isValid }) => (isValid ? COLORS.COMPANY : COLORS.ICONS_PRIMARY)};
  padding: 10px 18px;
  user-select: none;
  cursor: pointer;
  border-radius: 6px;

  :not(:first-child) {
    border-top: 1px solid ${COLORS.BORDERS};
  }

  :hover {
    background-color: ${COLORS.HOVER};
  }

  svg {
    margin-left: 24px;
  }
`;

export { Container, TextInput, Label, DropdownContainer, DropdownContent, DropdownOption };
