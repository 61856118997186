import React, { useState } from 'react';

import { REVIEW_QUESTION_EVALUATORS, REVIEW_RATING_TYPE } from '@learned/constants';
import { IReviewQuestionDefaultData, IReviewRating, ITask, IUserReview } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';

import { ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { StyledRickTextView } from '~/components/SurveyQuestionComments/design';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';
import type { IQuestionDefaultData } from '~/pages/ReviewGiveFeedback/types';

import {
  Answer,
  AnswerContainer,
  AnswerPlaceholder,
  AnswerWrapper,
  RatingWrapper,
  Row,
  UserContainer,
  Rating,
  Oval,
  Arrow,
  ExpandedWrapper,
  Label,
  Comment,
  LabelWrapper,
  EditButton,
} from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';

import { AnswerType } from '../../../types';
import { mapRatingsList, showCalibratedLabel, showCalibratePlaceholder } from '../../../utils';
import { AvatarWrapper } from '../Avatar';
import { NotSharedLabel } from '../NotSharedLabel';

function RatingAnswers({
  userReview,
  onEdit,
  isEditable,
  question,
  isZeroState,
}: {
  userReview: IUserReview & { tasks?: ITask[] };
  isZeroState: boolean;
  onEdit: (rating: AnswerType, question: IQuestionDefaultData) => void;
  isEditable: (rating: AnswerType) => boolean;
  question: IQuestionDefaultData & {
    reviewRatings?: (IReviewRating & { reviewType: string })[];
    settings?: IReviewQuestionDefaultData;
  };
}) {
  const currentUser = useSelector(getUser);
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  const [answers, setAnswers] = useState<AnswerType[]>(
    mapRatingsList(question.reviewRatings || []),
  );

  const checkIsQuestionAllowed = (type: REVIEW_RATING_TYPE) => {
    let evaluatorType: REVIEW_QUESTION_EVALUATORS;

    switch (type) {
      case REVIEW_RATING_TYPE.SELF:
        evaluatorType = REVIEW_QUESTION_EVALUATORS.EMPLOYEE;
        break;
      case REVIEW_RATING_TYPE.COACH:
        evaluatorType = REVIEW_QUESTION_EVALUATORS.COACH;
        break;
      case REVIEW_RATING_TYPE.PEER:
      case REVIEW_RATING_TYPE.PEER_EMAIL:
        evaluatorType = REVIEW_QUESTION_EVALUATORS.PEER;
        break;
    }

    return question.settings?.evaluators.includes(evaluatorType);
  };

  const options = (question?.settings as IReviewQuestionDefaultData)?.options || [];

  const onClick = (questionId: number) => {
    setAnswers(
      answers.map((answer, index) => {
        if (questionId === index) {
          return {
            ...answer,
            isOpened: !answer.isOpened,
          };
        }
        return answer;
      }),
    );
  };

  const getRatingColor = (type: REVIEW_RATING_TYPE) => {
    switch (type) {
      case REVIEW_RATING_TYPE.SELF:
        return {
          dark: COLORS.REVIEW_SELF,
          light: COLORS.BG_LIST,
          default: COLORS.SELF_REVIEW,
        };
      case REVIEW_RATING_TYPE.COACH:
        return {
          dark: COLORS.REVIEW_COACH,
          light: COLORS.BG_LIST,
          default: COLORS.COACH_REVIEW,
        };
      case REVIEW_RATING_TYPE.PEER:
      case REVIEW_RATING_TYPE.PEER_EMAIL:
        return {
          dark: COLORS.PEER_REVIEW_DARK,
          light: COLORS.BG_LIST,
          default: COLORS.PEER_REVIEW,
        };
    }
  };

  const getMarginRight = (answer?: string | number | null | undefined) => {
    return options.length > 4
      ? 25 * (options.length - (answer as number)) + 10
      : (130 / options.length) * (options.length - (answer as number));
  };

  return (
    <>
      {answers?.map((rating, index) => {
        const ratingColor = getRatingColor(rating.type);
        const isAllowedRating = checkIsQuestionAllowed(rating.type);
        return (
          isAllowedRating && (
            <Row key={index}>
              <AnswerContainer clickable onClick={() => onClick(index)} $isZeroState={isZeroState}>
                <UserContainer>
                  <Icon
                    transform={rating.isOpened ? 'rotate(90)' : 'rotate(0)'}
                    size={ICON_SIZES.MEDIUM}
                    icon={ICONS.NEXT}
                    color={rating.isOpened ? COLORS.COMPANY : COLORS.INACTIVE}
                  />
                  <div>
                    <AvatarWrapper
                      key={index}
                      id={rating.createdBy?.id}
                      answer={rating?.answer !== -1 ? (rating?.answer as string) : undefined}
                      email={rating.createdBy?.email}
                      reviewType={rating.reviewType}
                    />
                  </div>
                </UserContainer>

                <>
                  <ToolTip
                    key={index}
                    placement={TOOLTIP_PLACEMENTS.TOP}
                    tooltip={i18n._(t`This input is not yet shared with the employee`)}
                  >
                    <LabelWrapper $answered={!!rating?.answer}>
                      {showCalibratedLabel(rating, userReview, currentUser) && <NotSharedLabel />}
                    </LabelWrapper>
                  </ToolTip>
                </>
                <RatingWrapper
                  $answered={!!rating?.answer}
                  maxWidth={options.length > 4 ? options.length * 25 : 130}
                >
                  {options.map((_, index) => (
                    <Rating
                      key={index}
                      selected={index + 1 === rating.answer}
                      default={ratingColor?.default}
                      dark={ratingColor?.dark}
                    >
                      {index + 1 === rating.answer && <Oval />}
                    </Rating>
                  ))}
                </RatingWrapper>
              </AnswerContainer>
              {rating.isOpened && (
                <AnswerWrapper>
                  {rating?.answer && Number(rating?.answer) >= 0 ? (
                    <ExpandedWrapper>
                      <Arrow
                        background={ratingColor.light}
                        marginRight={getMarginRight(rating.answer)}
                      />
                      {isEditable(rating) && (
                        <EditButton
                          variant={ButtonVariant.SECONDARY}
                          label={i18n._(t`Edit`)}
                          onClick={() => onEdit(rating, question)}
                        />
                      )}
                      <Answer background={ratingColor.light}>
                        <Label>
                          <Trans>
                            {rating.answer}
                            <span>/{options.length}</span> -{' '}
                            {getMultiLangString(options?.[Number(rating.answer) - 1]?.label || '')}
                          </Trans>
                        </Label>
                        {rating.comment && (
                          <Comment>
                            <StyledRickTextView html={getMultiLangString(rating.comment || '')} />
                          </Comment>
                        )}
                      </Answer>
                    </ExpandedWrapper>
                  ) : (
                    <AnswerPlaceholder>
                      {rating?.answer === null ? (
                        <Trans>Answered this question with N/A</Trans>
                      ) : (
                        <Trans>{`${
                          showCalibratePlaceholder(rating.task, userReview, currentUser)
                            ? 'Answer not yet shared'
                            : 'No answer provided yet'
                        }`}</Trans>
                      )}
                    </AnswerPlaceholder>
                  )}
                </AnswerWrapper>
              )}
            </Row>
          )
        );
      })}
    </>
  );
}

export { RatingAnswers };
