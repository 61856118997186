import React, { useState } from 'react';

import { QUESTION_TYPES, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import ReviewGoalPlanning from '~/components/GiveFeedback/components/Section/components/ReviewGoalPlanning';
import Modal from '~/components/Modal';

import { GOAL_TYPES } from '~/constants';
import { getSelectedRole, getUser } from '~/selectors/baseGetters';

const StyledButton = styled(Button)`
  margin-left: 8px;
`;

const UpdateGoalsModal = ({ onClose, review, i18n, question, onSave }) => {
  const currentUser = useSelector(getUser);
  const currentRole = useSelector(getSelectedRole);
  const isLearning = question.type === QUESTION_TYPES.GOAL_LEARNING_PLAN;
  const goal = review.goalCyclesBusinessPlan[0];
  const [goalsToSave, setGoalsToSave] = useState([]);
  const requestedBy = review.createdFor;

  const isCoach =
    [ROLES.USER, ROLES.COACH].includes(currentRole) &&
    get(review, 'coaches', []).includes(currentUser.id);

  return (
    <Modal
      onClose={onClose}
      footerRight={
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <StyledButton
            label={'Close'}
            onClick={() => {
              onClose();
            }}
            type="primary-border"
          />
          <StyledButton
            label={'Save'}
            onClick={() => {
              onSave(goalsToSave);
              onClose();
            }}
            type="primary"
          />
        </div>
      }
    >
      <ReviewGoalPlanning
        i18n={i18n}
        title={question.name}
        description={question.description}
        goalTitle={isLearning ? GOAL_TYPES.PERSONAL.title(i18n) : GOAL_TYPES.BUSINESS.title(i18n)}
        goalCycle={goal && goal.id}
        forUser={{ id: requestedBy }}
        type={isLearning ? GOAL_TYPES.PERSONAL.key : GOAL_TYPES.BUSINESS.key}
        typeLabel={
          isLearning ? i18n._(t`LearningKeyForPlanning`) : i18n._(t`BusinessKeyForPlanning`)
        }
        isLearning={isLearning}
        goalsToSave={setGoalsToSave}
        review={review}
        isCoach={isCoach}
        isOpenDefault
      />
    </Modal>
  );
};

export default UpdateGoalsModal;
