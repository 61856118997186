function generateEvenlyWeightedNumbers(n: number, { sum = 100 } = {}) {
  const tempTotal = sum * 100;
  // the biggest number that fits into sum n times but does exceed it
  const k = Math.floor(tempTotal / n);

  // the remainder that's left over after subtracting k n times from sum
  let remainder = isNaN(tempTotal % k) ? tempTotal : tempTotal % k;

  const result: number[] = [];
  for (let i = 0; i < n; i++) {
    // add one to every number until remainder is 0
    const integer = (k + (remainder-- > 0 ? 1 : 0)) / 100;
    result.push(integer);
  }
  return result;
}

function generateEvenThemeWeights(themeIds: string[]) {
  if (themeIds.length === 0) {
    return {};
  }

  const numbers = generateEvenlyWeightedNumbers(themeIds.length);
  const themeWeights: Record<string, number> = {};
  themeIds.forEach((themeId) => {
    themeWeights[themeId] = numbers.pop() as number;
  });
  return themeWeights;
}

export { generateEvenThemeWeights };
