import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';

import { ButtonContainer, Description, Placeholder } from './design';

import emptyState from '~/assets/no-result.webp';

const QuestionsEmpty = ({
  createNewQuestion,
  importQuestions,
}: {
  createNewQuestion: (index?: number) => void;
  importQuestions: (index?: number) => void;
}) => {
  const { i18n } = useLingui();

  return (
    <Placeholder>
      <Description>
        <span>
          <Trans>No questions yet…</Trans>
        </span>
        <span>
          <Trans>Start importing questions!</Trans>
        </span>
      </Description>
      <img src={emptyState} alt={i18n._(t`No data found`)} width="216px" />
      <ButtonContainer>
        <Button
          label={i18n._(t`Create new`)}
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          type="button"
          onClick={() => createNewQuestion()}
        />
        <Button
          label={i18n._(t`Import question(s)`)}
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          type="button"
          onClick={() => importQuestions()}
        />
      </ButtonContainer>
    </Placeholder>
  );
};

export { QuestionsEmpty };
