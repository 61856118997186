import { MissingAverageRatingReason } from '@learned/constants';
import { t } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';

import { ICONS } from '~/components/Icon';

import { IColumnTable } from '~/@types/table';
import { COLORS } from '~/styles';

import type { I18n } from '@lingui/core';

const isValueAllowed = (
  value: number | null,
  selectedItems: string[],
): number | null | undefined => {
  if (value === null || value === undefined) {
    return value;
  }
  if (value >= 80) {
    if (!selectedItems.includes('80-100')) {
      value = null;
    }
  } else if (value >= 60) {
    if (!selectedItems.includes('60-79')) {
      value = null;
    }
  } else if (value >= 40) {
    if (!selectedItems.includes('40-59')) {
      value = null;
    }
  } else if (value >= 20) {
    if (!selectedItems.includes('20-39')) {
      value = null;
    }
  } else if (value >= 0) {
    if (!selectedItems.includes('0-19')) {
      value = null;
    }
  } else {
    value = null;
  }

  return value;
};

const checkEmptyData = <T>({
  data,
  column,
  field,
  skipColumns,
  reportingThreshold,
  i18n,
}: {
  data: T[];
  column: string;
  field: string;
  skipColumns: string[];
  reportingThreshold: number;
  i18n: I18n;
}) => {
  if (!isEmpty(data)) {
    let isEmpty = true;

    map(data, (row) => {
      // @ts-ignore
      map(row?.[column], (item) => {
        // @ts-ignore
        if (!skipColumns.includes(item?.[field]) && item.value && item?.value?.[0] !== null) {
          isEmpty = false;
        }
      });
    });

    if (isEmpty) {
      // @ts-ignore
      switch (data[0]?.[column]?.[0]?.value?.[0]) {
        case MissingAverageRatingReason.THRESHOLD_NOT_MET:
          return {
            title: i18n._(
              t`Threshold not met yet. Needs a minimum of ${reportingThreshold} answers`,
            ),
            icon: ICONS.LOCK,
            isVisible: true,
          };
        default:
        case MissingAverageRatingReason.NOT_SHARED:
          return {
            title: i18n._(t`Results are not yet shared`),
            icon: ICONS.TIME,
            isVisible: true,
          };
      }
    }
  }
};

const isKeyTheSame = (
  item1: { [key: string]: unknown } | undefined | null,
  item2: { [key: string]: unknown } | undefined | null,
  key: string,
) => !isNil(item1?.[key]) && item2?.[key] === item1?.[key];

/**
 * Checks if a column is not fixed.
 *
 * @param {IColumnTable} column - The column to check.
 * @returns {boolean} - Returns true if the column is not fixed, otherwise false.
 */
const isNotFixedFilter = (column: IColumnTable) => !column.isFixed;

const getHeatmapColors = (value?: number | null, ratingLabelsCount = 100) => {
  if (value === undefined || value === null) {
    return [];
  }

  const thresholds = [20, 40, 60, 80];
  const gradients = [
    COLORS.TABLE_WARNING_GRADIENT,
    COLORS.TABLE_ROMANTIC_GRADIENT,
    COLORS.TABLE_HALF_DUTCH_GRADIENT,
    COLORS.TABLE_ICE_COLD_GRADIENT,
    COLORS.TABLE_SUCCESS_GRADIENT,
  ];

  for (let i = 0; i < thresholds.length; i++) {
    if (value * (100 / ratingLabelsCount) < thresholds[i]) {
      return gradients[i].split('-');
    }
  }

  return gradients[gradients.length - 1].split('-');
};

export { isNotFixedFilter, isValueAllowed, checkEmptyData, isKeyTheSame, getHeatmapColors };
