import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import CardTools from '~/components/CardTools';
import BoxWithBorder from '~/components/UI/BoxWithBorder';

const Wrapper = styled.div`
  ${(props) => props.width && `width: ${props.width};`}
`;

const CardBody = styled(BoxWithBorder)`
  margin-bottom: 35px;
  padding: 24px;
  min-width: 625px;
  min-height: ${(props) => props.minHeight}px;
`;

class DashboardCard extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    backPath: PropTypes.string,
    renderActions: PropTypes.func,
    minHeight: PropTypes.number,
  };

  static defaultProps = {
    minHeight: 300,
  };

  render() {
    const { title, backPath, renderActions, className, minHeight, width } = this.props;

    return (
      <Wrapper width={width}>
        {(title || backPath || renderActions) && (
          <CardTools title={title} backPath={backPath} renderActions={renderActions} />
        )}
        <CardBody className={className} minHeight={minHeight}>
          {this.props.children}
        </CardBody>
      </Wrapper>
    );
  }
}

export default DashboardCard;
