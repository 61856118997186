import React, { useMemo } from 'react';

import { withI18n } from '@lingui/react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import range from 'lodash/range';
import truncate from 'lodash/truncate';
import { Radar } from 'react-chartjs-2';
import styled from 'styled-components';

import { Header4 } from '~/components/UI/Typographics/headers';

import { COLORS } from '~/styles';
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);
const ChartWrap = styled.div`
  text-align: center;
  margin: 0 auto;
  ${Header4} {
    margin: 0 0 20px;
  }
`;

const oneLineLabel = (label, rowSize = 30) => truncate(label, { length: rowSize });

const options = (maxValue) => ({
  aspectRatio: 2,
  plugins: {
    tooltip: {
      callbacks: {
        labelColor: (tooltipItem) => {
          return {
            backgroundColor: tooltipItem.dataset.borderColor || '',
          };
        },
      },
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        font: {
          size: 13,
        },
        color: COLORS.TEXT_PRIMARY_2,
        padding: 20,
        boxWidth: 13,
      },
    },
  },
  scales: {
    r: {
      title: {
        display: false,
      },
      min: 0,
      max: maxValue + 1,
      ticks: {
        display: false,
        showLabelBackdrop: false,
        stepSize: 1,
      },
      grid: {
        circular: true,
        color: [...range(0, maxValue + 1).map(() => '#ddd'), 'transparent'],
      },
      pointLabels: {
        display: true,
        padding: 5,
        color: COLORS.TEXT_PRIMARY_2,
        font: { size: 14 },
        callback(item) {
          if (typeof item === 'string') {
            return oneLineLabel(item);
          }
          if (Array.isArray(item) && typeof item[0] === 'string') {
            return oneLineLabel(item[0]);
          }
          return item;
        },
      },
    },
  },
});

const plugins = [
  {
    beforeDraw(chart) {
      const legends = chart.legend.legendItems;
      legends.forEach((e) => {
        e.fillStyle = e.strokeStyle;
      });
    },
  },
];

const Spider = ({
  data = [],
  maxWidth = 800,
  placeholder = 'No items',
  showPlaceholder = false,
  title,
}) => {
  const maxValue = useMemo(
    () =>
      data.datasets.reduce((prevValue, currentValue) => {
        const max = Math.max(...currentValue.data);
        if (max > prevValue) {
          return max;
        }
        return prevValue;
      }, 0),
    [data.datasets],
  );

  const content = showPlaceholder ? (
    placeholder
  ) : (
    <Radar
      data={{
        labels: data.labels,
        datasets: data.datasets.map((item) => {
          return {
            ...item,
            backgroundColor: `${item.backgroundColor}15`,
            pointBorderColor: 'transparent',
            data: item.data.map((item) => item ?? 0),
            borderWidth: 2,
            fill: true,
          };
        }),
      }}
      plugins={plugins}
      options={options(maxValue)}
      height={400}
      width={maxWidth}
    />
  );

  return (
    <ChartWrap>
      <Header4>{title}</Header4>
      {content}
    </ChartWrap>
  );
};

export default withI18n()(Spider);
