import React, { useRef, useState } from 'react';

import Tippy from '@tippyjs/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SearchList from '~/components/AutocompleteDropdown/SearchList';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import useBoolState from '~/hooks/useBoolState';
import { COLORS, COLOR_PALETTE } from '~/styles';

const Box = styled.div`
  height: 48px;
  border-radius: 6px;
  background-color: ${COLOR_PALETTE.WHITE};
  border: solid 1px ${COLORS.BORDER};
  color: ${COLOR_PALETTE.DARK_GRAY};
  min-width: 150px;
  padding: 10px 10px;
  box-sizing: border-box;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const PoperWrap = styled.div`
  outline: none;
  width: 300px;
`;

const SelectedItem = styled.div`
  color: ${COLORS.TEXT_BLACK};
  margin-right: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
`;

const ChevronIcon = styled(Icon)`
  min-width: 12px;
  min-height: 12px;
  margin-left: auto;
`;

const AutocompleteDropdown = ({
  items,
  selectedItem,
  onChange,
  noItemsPlaceholder,
  searchField,
  placeholder,
  searchFieldPlaceholder,
  showNoItemsPlaceholder,
  onSearchChange,
  disabled,
  loading,
}) => {
  const ref = useRef();
  const $open = useBoolState();
  const [search, setSearch] = useState('');

  const handleSearchChange = (value) => {
    onSearchChange(value);
    setSearch(value);
  };

  const handleSelectItem = (item) => {
    onChange(item);
    $open.off();
  };

  const renderPopover = () => {
    return $open.value ? (
      <PoperWrap tabIndex="0">
        <SearchList
          list={items}
          loading={loading}
          selectedItem={selectedItem}
          onSelect={handleSelectItem}
          search={search}
          onChangeSearch={handleSearchChange}
          searchPlaceholder={searchFieldPlaceholder}
          noItemsPlaceholder={noItemsPlaceholder}
          showNoItemsPlaceholder={showNoItemsPlaceholder}
          disableSearch={!searchField}
          showTooltip={true}
        />
      </PoperWrap>
    ) : null;
  };

  return (
    <>
      <Box onClick={!disabled ? $open.on : undefined} ref={ref}>
        {!selectedItem && <SelectedItem>{placeholder}</SelectedItem>}
        {selectedItem && <SelectedItem>{selectedItem.name}</SelectedItem>}
        {!disabled && (
          <ChevronIcon
            icon={ICONS.DROPDOWN}
            size={ICON_SIZES.SMALL}
            color={isEmpty(selectedItem) ? COLORS.SUBTEXT : COLORS.COMPANY}
          />
        )}
      </Box>
      <Tippy
        visible
        placement="bottom-start"
        onClickOutside={$open.off}
        interactive
        maxWidth={650}
        offset={[0, 3]}
        reference={ref}
        render={renderPopover}
      />
    </>
  );
};

AutocompleteDropdown.propTypes = {
  items: PropTypes.array,
  selectedItem: PropTypes.object,
  onChange: PropTypes.func,
  searchField: PropTypes.bool,
  onSearchChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  noItemsPlaceholder: PropTypes.object,
  showNoItemsPlaceholder: PropTypes.bool,

  labelProperty: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

export default React.memo(AutocompleteDropdown);
