import styled from 'styled-components';

import { COLORS } from '~/styles';

export const FilterWrapper = styled.div`
  padding-bottom: 16px;
  display: flex;
  align-items: center;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.GRAY_MIDDLE};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  span {
    margin-right: 8px;
  }
  &:last-child {
    margin-left: 8px;
  }
`;
