import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import BoxWithBorder from '~/components/UI/BoxWithBorder';
import Divider from '~/components/UI/Divider';
import { Header3 } from '~/components/UI/Typographics/headers';
import PDFLayout from '~/layouts/PDFLayout';
import TalkingPointTab from '~/pages/UserConversation/components/ConversationContent/components/TalkingPointTab';
import ConversationNotes from '~/pages/UserConversation/components/ConversationNotes';
import useConversationState from '~/pages/UserConversation/useConversationState';

import Header from './components/Header';
import NextStepsPdf from './components/NextStepsPdf';

import { COLORS, COLOR_PALETTE } from '~/styles';

const Wrapper = styled(BoxWithBorder)`
  background: ${COLOR_PALETTE.WHITE};
  @media print {
    ${(props) => props.$isAvoidBreak && 'break-inside: avoid;'};
    ${(props) => props.$isMarginTop && 'margin-top: 24px;'};
  }
`;

const Content = styled.div`
  margin-top: 28px;
`;

const TPTitle = styled(Header3)`
  padding: 0 24px;
  margin: 32px 0 11px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
`;

const ConversationReportPDF = ({ match, location }) => {
  const urlParams = new URLSearchParams(location.search);
  const role = urlParams.get('role');
  const userId = urlParams.get('userId');

  const $conversation = useConversationState(
    match.params.conversationId,
    { id: userId },
    role,
    true,
  );

  return (
    <PDFLayout loading={$conversation.loading}>
      <Wrapper>
        <Header conversation={$conversation} />
        <Divider $color={COLORS.BORDER_HARD} />
        <Content>
          <TPTitle>
            <Trans>Talking points</Trans>
          </TPTitle>
          <Divider $color={COLORS.BORDER_HARD} />
          <TalkingPointTab
            talkingPoints={$conversation.talkingPoints}
            doneTP={$conversation.donePoints}
            readOnly
            currentUserId={userId}
            conversationId={$conversation.id}
            TPOrder={$conversation.TPOrder}
            reorderTalkingPoints={$conversation.reorderTalkingPoints}
            writingComment={false}
            isPdfView
            $conversation={$conversation}
          />
          {$conversation.conversationComments.length > 0 && (
            <>
              <TPTitle>
                <Trans>Shared notes</Trans>
              </TPTitle>
              <Divider $color={COLORS.BORDER_HARD} />
              <ConversationNotes
                conversationComments={$conversation.conversationComments || []}
                deleteComment={$conversation.deleteConversationComment}
                readOnly
                conversationId={$conversation.id}
                isPdfView
              />
            </>
          )}
        </Content>
      </Wrapper>
      {$conversation.nextSteps &&
        $conversation.nextSteps.currentConvSteps &&
        $conversation.nextSteps.currentConvSteps.length > 0 && (
          <Wrapper $isAvoidBreak $isMarginTop>
            <Content>
              <>
                <TPTitle>
                  <Trans>Next steps</Trans>
                </TPTitle>
                <Divider $color={COLORS.BORDER_HARD} />
                <NextStepsPdf currentSteps={$conversation.nextSteps.currentConvSteps} />
              </>
            </Content>
          </Wrapper>
        )}
    </PDFLayout>
  );
};

export default ConversationReportPDF;
