import React, { ReactNode } from 'react';

import { SURVEY_TYPE } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Modal from '~/components/Modal';
import SvgIcon from '~/components/SvgIcon';

import CloseIcon from '~/assets/ic-close-18-px.svg';
import SurveyPulseIcon from '~/assets/survey-pulse.svg';
import SurveyTraditionalIcon from '~/assets/survey-traditional.svg';

import { COLORS } from '~/styles';

import type { I18n } from '@lingui/core';

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  & .closeButton {
    position: absolute;
    top: 10px;
    left: 0px;
    cursor: pointer;
  }

  & .title {
    color: ${COLORS.BLACK};
    margin: 8px 0 20px;
  }
`;

const Column = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 214px;
  border-radius: 6px;
  border: solid 1px #dae4f7;
  background-color: ${COLORS.WHITE};
  padding-bottom: 25px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px var(--company-color);
    background-color: ${COLORS.BG_PAGE};
  }

  & .main {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`;

const IconBlock = styled.div<{
  $colors: string[];
}>`
  width: 100%;
  min-height: 64px;
  box-sizing: border-box;
  padding: 8px 11px;
  background: ${({ $colors }) =>
    $colors.length ? `linear-gradient(to bottom, ${$colors[0]}, ${$colors[1]})` : 'unset'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Description = styled.ul`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: normal;
  font-style: italic;
  text-align: center;
  padding: 0 15px;
  color: ${COLORS.MIDDLE_GRAY};
  margin: 0 0 23px 0;
  list-style-position: inside;

  & li {
    margin-bottom: 4px;
    white-space: nowrap;
    text-align: left;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${COLORS.BLACK};
  margin-top: 24px;
  margin-bottom: 3px;
`;

const SubTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.BLACK};
  max-width: 180px;
`;

const LineHorizontalStyled = styled.div`
  background: ${COLORS.BORDER_HARD};
  height: 1px;
  width: 47px;
  margin: 22px 0;
`;

const ContinueButton = styled.button`
  width: 113px;
  padding: 8px 18px 7px 22px;
  background-color: var(--company-color);
  border-radius: 100px;
  color: ${COLORS.WHITE};
  cursor: pointer;
`;

export interface ICreateSurveyModalProps {
  onClose: () => void;
  onSubmit: (type: SURVEY_TYPE) => void;
}

function CreateSurveyModal({ onClose, onSubmit }: ICreateSurveyModalProps) {
  const { i18n } = useLingui();

  const data = [
    {
      type: SURVEY_TYPE.TRADITIONAL,
      colors: ['#e6f3ff', '#d5dffc'],
      icon: (
        <SvgIcon
          width="24px"
          height="24px"
          url={SurveyTraditionalIcon}
          isDefaultColor
          defaultColor={COLORS.MIDDLE_GRAY}
        />
      ),
      title: (i18n: I18n) => i18n._(t`Traditional survey`),
      subTitle: (i18n: I18n) => i18n._(t`All survey questions are sent to all participants.`),
      descriptions: [
        (i18n: I18n) => i18n._(t`Done at once`),
        (i18n: I18n) => i18n._(t`High quantity`),
        (i18n: I18n) => i18n._(t`Takes time and focus`),
        (i18n: I18n) => i18n._(t`Snapshot of sentiment`),
      ],
    },
    {
      type: SURVEY_TYPE.PULSE,
      colors: ['#ffe6e6', '#fcd5d5'],
      icon: (
        <SvgIcon
          width="22px"
          height="22px"
          url={SurveyPulseIcon}
          isDefaultColor
          defaultColor={COLORS.MIDDLE_GRAY}
        />
      ),
      title: (i18n: I18n) => i18n._(t`Pulse survey`),
      subTitle: (i18n: I18n) => i18n._(t`The questions are distributed among the participants.`),
      descriptions: [
        (i18n: I18n) => i18n._(t`Spread over time`),
        (i18n: I18n) => i18n._(t`Results per topic`),
        (i18n: I18n) => i18n._(t`Minimal effort to fill in`),
        (i18n: I18n) => i18n._(t`Perfect for realtime feedback`),
      ],
    },
  ];
  const renderCard = (item: {
    type: SURVEY_TYPE;
    title: Function;
    subTitle: Function;
    colors: string[];
    icon: ReactNode;
    descriptions: Function[];
  }) => {
    return (
      <Column
        onClick={() => {
          onSubmit(item.type);
          onClose();
        }}
      >
        <IconBlock $colors={item.colors}>{item.icon}</IconBlock>
        <div className="main">
          <Title>{item.title(i18n)}</Title>
          <SubTitle>{item.subTitle(i18n)}</SubTitle>
          <LineHorizontalStyled />
          <Description>
            {item.descriptions.map((description, index) => (
              <li key={index}>{description(i18n)}</li>
            ))}
          </Description>
          <ContinueButton>
            <Trans>Continue</Trans>
          </ContinueButton>
        </div>
      </Column>
    );
  };

  return (
    <Modal
      contentStyles={{ padding: '15px 24px 44px 24px' }}
      width={500}
      hideFooter
      isHideHeader
      showDivider={false}
    >
      <Header>
        <button onClick={onClose} className="closeButton">
          <SvgIcon
            url={CloseIcon}
            width={'17px'}
            height={'17px'}
            isDefaultColor
            defaultColor={COLORS.BLACK}
          />
        </button>
        <span className="title">
          <Trans>Create survey</Trans>
        </span>
      </Header>
      <Row>{data.map(renderCard)}</Row>
    </Modal>
  );
}

export { CreateSurveyModal };
