import React from 'react';

import styled from 'styled-components';

import BoxWithBorder from '~/components/UI/BoxWithBorder';

import { LearningAndOnBoard } from './LearningAndOnBoard';

import type { ILearningProps } from '../types';

const TabContainer = styled.div`
  margin: 19px 39px;

  .user-public-learning_header {
    margin-bottom: 24px;
  }
`;

const LearningTab = ({
  user,
  isFetchAgain,
  showLearningActivityModal,
  createLearningPath,
}: ILearningProps) => {
  return (
    <BoxWithBorder>
      <TabContainer>
        <LearningAndOnBoard
          user={user}
          createLearningPath={createLearningPath}
          showLearningActivityModal={showLearningActivityModal}
          isFetchAgain={isFetchAgain}
        />
      </TabContainer>
    </BoxWithBorder>
  );
};

export default LearningTab;
