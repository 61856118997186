import React, { Dispatch } from 'react';

import { ISurveyTemplate } from '@learned/types';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { StepFooter } from '~/pages/SurveyUpdate/components/StepFooter';
import { SectionNameAndDescription } from '~/pages/SurveyUpdate/components/StepSummary/SectionNameAndDescription';
import { SectionParticipants } from '~/pages/SurveyUpdate/components/StepSummary/SectionParticipants';
import { SectionSettings } from '~/pages/SurveyUpdate/components/StepSummary/SectionSettings';
import { SectionTemplates } from '~/pages/SurveyUpdate/components/StepSummary/SectionTemplates';

import { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { COLORS } from '~/styles';

import type { IGeneralForm } from '../../types';
import type { UseFormReturn } from 'react-hook-form';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormWrapper = styled.div`
  margin-top: 32px;
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  margin-bottom: 24px;
`;

export interface IGeneralProps {
  formMethods: UseFormReturn<IGeneralForm>;
  setCurrentSection: Dispatch<number>;
  languageState: ILanguageStateReturn;
  onPublish: () => void;
  onTemplatePreview: (item: ISurveyTemplate) => void;
}

const Form = styled.form`
  border-radius: 10px;
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 32px 40px;
`;

const Title = styled.h2`
  font-size: 26px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: normal;
  margin-top: 0;
`;

const StepSummary = ({
  setCurrentSection,
  formMethods,
  languageState,
  onPublish,
  onTemplatePreview,
}: IGeneralProps) => {
  const { i18n } = useLingui();
  const { handleSubmit } = formMethods;

  const onSubmit = () => setCurrentSection(4);

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title>
          <Trans>Summary</Trans>
        </Title>
        <SectionTemplates
          formMethods={formMethods}
          onEdit={() => setCurrentSection(0)}
          onTemplatePreview={onTemplatePreview}
        />
        <SectionNameAndDescription
          formMethods={formMethods}
          languageState={languageState}
          onEdit={() => setCurrentSection(1)}
        />
        <SectionSettings formMethods={formMethods} onEdit={() => setCurrentSection(2)} />
        <SectionParticipants formMethods={formMethods} onEdit={() => setCurrentSection(3)} />
      </Form>

      <FormWrapper>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title>
            <Trans>Final Check</Trans>
          </Title>
          <SubTitle>
            <Trans>
              Click “Schedule survey” to send out this survey on the start date. You can edit the
              survey content anytime, but changes will not affect completed surveys.
            </Trans>
          </SubTitle>
          <StepFooter isNext={false} onPrev={() => setCurrentSection(3)}>
            <Button
              variant={ButtonVariant.PRIMARY}
              label={i18n._(t`Publish survey`)}
              onClick={onPublish}
              size={ButtonSize.BIG}
            />
          </StepFooter>
        </Form>
      </FormWrapper>
    </Wrapper>
  );
};

export { StepSummary };
