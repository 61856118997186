import React, { useEffect, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE, ROLES, SURVEY_THEME_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';

import { Button } from '~/components/Buttons/Button';
import { ButtonSize, ButtonVariant } from '~/components/Buttons/types';
import DashboardHeader from '~/components/DashboardHeader';
import { MultiLangComponent } from '~/components/Dropdown/MultiLangualDropdown';
import { ICONS } from '~/components/Icon';
import { IconNames } from '~/components/IconOld';
import { LastSaved } from '~/components/LastSaved';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { SECTION_TYPE, SideBar } from '~/components/SideBar';
import { useSectionState } from '~/components/SideBar/SectionStateHook';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import { General } from './components/General';
import { Questions } from './components/Questions';
import { ActionItemBlock, ActionsWrap } from './design';
import { resolver } from './validation';

import type { IQuestionForm } from '~/@types/question';
import routes from '~/constants/routes';
import { useAutoSaveState } from '~/hooks/useAutoSaveState';
import { useFromQuery } from '~/hooks/useFromQuery';
import { useLanguageState } from '~/hooks/useLanguageState';
import { checkModuleSurvey } from '~/selectors/baseGetters';
import { deleteSurveyThemes, getSurveyTheme, updateSurveyTheme } from '~/services/surveyThemes';
import { convertQuestionOptions } from '~/utils/questions';
import { turnArrayIntoMultiLang, turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import type { IGeneralForm } from './types';
import type { PopulatedSurveyTheme } from '../SurveyThemeView/types';
import type { ISurveyQuestionDefaultData } from '@learned/types';

const Wrapper = styled.div`
  display: flex;
  gap: 151px;
  margin-top: 49px;
`;

const Main = styled.div``;

const UpdateTheme = () => {
  const { goBack } = useFromQuery({ includeHash: true });
  const params: Record<string, string | undefined> = useParams();
  const surveyThemeId = params.themeId;
  const history = useHistory();
  const isModuleSurveyEnabled = useSelector(checkModuleSurvey);
  const { addToast } = useToasts();
  const { i18n } = useLingui();
  const languageState = useLanguageState();
  const {
    lastSavedTime,
    lastSavedStatus,
    lastSavedErrorMessage,
    setLastSaveSuccess,
    setLastSaveError,
    setLastSavedErrorMessage,
  } = useAutoSaveState({ errorMessage: i18n._(t`Please fill all obligated fields`) });
  const [isDeleteThemeModalVisible, setIsDeleteThemeModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [oldStatus, setOldStatus] = useState<SURVEY_THEME_STATUS>(SURVEY_THEME_STATUS.DRAFT);

  useEffect(() => {
    if (!isModuleSurveyEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleSurveyEnabled, history]);

  const generalFormMethods = useForm<IGeneralForm>({
    defaultValues: {
      name: languageState.companyLanguages.map(({ locale }) => ({ locale, value: '' })),
      description: languageState.companyLanguages.map(({ locale }) => ({ locale, value: '' })),
      icon: null,
      iconColor: '#f7f9ff-#ebf1fe',
      isHideOnUserDashboard: false,
    },
    context: { companyPrimaryLanguage: languageState.companyPrimaryLanguage.locale },
    resolver,
    mode: 'all',
  });
  const {
    control,
    trigger,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = generalFormMethods;
  const questionsFormMethods = useFieldArray({
    control,
    name: 'questions',
    keyName: 'key',
  });

  const fetchTheme = async (themeId?: string) => {
    if (!themeId) {
      return;
    }

    const { data } = await getSurveyTheme(themeId, ['questions']);
    const surveyTheme: PopulatedSurveyTheme = data.surveyTheme;
    return surveyTheme;
  };

  const handleFetchedTheme = async (surveyTheme: PopulatedSurveyTheme) => {
    const themeQuestions: IQuestionForm[] = [];
    surveyTheme.questions.forEach((q) => {
      themeQuestions.push({
        id: q.id,
        name: turnMultiLangIntoArray(q.name, languageState.companyLanguages),
        type: q.type,
        settings: {
          isCommentsAllowed: (q.data as ISurveyQuestionDefaultData).isCommentsAllowed,
          isCommentsObligated: (q.data as ISurveyQuestionDefaultData).isCommentsObligated,
          isMeasurementReversed: (q.data as ISurveyQuestionDefaultData).isMeasurementReversed,
        },
        options: convertQuestionOptions(q, languageState.companyLanguages),
      });
    });

    const name = turnMultiLangIntoArray(surveyTheme.name, languageState.companyLanguages);
    const description = turnMultiLangIntoArray(
      surveyTheme.description,
      languageState.companyLanguages,
    );

    generalFormMethods.setValue('name', name);
    generalFormMethods.setValue('description', description);
    generalFormMethods.setValue('icon', (surveyTheme.icon as IconNames) || null);
    generalFormMethods.setValue('iconColor', surveyTheme.iconColor || '#f7f9ff-#ebf1fe');
    generalFormMethods.setValue('isHideOnUserDashboard', surveyTheme.isHideOnUserDashboard);
    generalFormMethods.setValue('status', surveyTheme.status);
    generalFormMethods.setValue('questions', themeQuestions);

    setOldStatus(surveyTheme.status);

    await trigger();
    setIsLoading(false);
  };

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);
    const safeFetchTheme = async () => {
      const surveyTheme = await fetchTheme(surveyThemeId);

      if (mounted && surveyTheme) {
        await handleFetchedTheme(surveyTheme);
      }
    };

    safeFetchTheme();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [surveyThemeId]);

  const deleteTheme = async () => {
    try {
      setIsLoading(true);
      await deleteSurveyThemes([surveyThemeId]);
      addToast({
        title: i18n._(t`Theme deleted!`),
        subtitle: i18n._(t`Theme is deleted from the survey themes.`),
        type: TOAST_TYPES.SUCCESS,
      });
    } finally {
      routes.SURVEYS_OVERVIEW.go(
        { role: ROLES.USER },
        {
          noHash: true,
          hash: 'themes',
        },
      );
      setIsLoading(false);
    }
  };

  const handleUpdateTheme = async (status?: SURVEY_THEME_STATUS) => {
    if (!navigator.onLine) {
      setLastSaveError();
      setLastSavedErrorMessage(i18n._(t`Attempting to reconnect. Please check your connection.`));
      return;
    }

    const questionsIds = (watch('questions') || [])?.map((q) => q.id).filter((q) => q);
    const body = {
      name: turnArrayIntoMultiLang(watch('name').filter((langValue) => langValue.value !== '')),
      description: turnArrayIntoMultiLang(
        watch('description').filter((langValue) => langValue.value !== ''),
      ),
      isHideOnUserDashboard: watch('isHideOnUserDashboard'),
      icon: watch('icon'),
      iconColor: watch('iconColor'),
      questions: questionsIds,
      status: status || watch('status'),
    };

    await updateSurveyTheme(surveyThemeId, body);

    setLastSaveSuccess();
  };

  const nameInPrimaryLanguage = (watch('name') || [])?.find(
    (name) => name.locale === languageState.companyPrimaryLanguage.locale,
  )?.value;

  const isDraft = watch('status') === SURVEY_THEME_STATUS.DRAFT;

  const sectionState = useSectionState([
    {
      title: i18n._(t`General`),
      fields: [i18n._(t`Theme Name`), i18n._(t`Icon & color`), i18n._(t`Description`)],
      isTouched: true,
    },
    {
      title: i18n._(t`Questions`),
    },
  ]);

  useEffect(() => {
    if (!!errors?.name || !!errors?.icon) {
      sectionState.setErrorSection(0, true);
    } else {
      sectionState.setTypeSection(0, SECTION_TYPE.DONE);
    }
    // eslint-disable-next-line
  }, [errors?.name, errors?.icon]);

  useEffect(() => {
    sectionState.setErrorSection(1, !!errors?.questions);
    // eslint-disable-next-line
  }, [errors?.questions]);

  const onFail = (isAutosave = false) => {
    if (!isAutosave) {
      sectionState.setTriedToSubmit();
      addToast({
        title: i18n._(t`Warning`),
        subtitle: i18n._(t`Please fill all obligated fields`),
        type: TOAST_TYPES.INFO,
      });
      setTimeout(() => sectionState.goToFirstErrorSection(), 100);
    } else {
      setLastSaveError();
    }

    setValue('status', oldStatus);
    trigger();
  };

  const onSubmit = async (status?: SURVEY_THEME_STATUS, isAutoSave = false) => {
    await handleUpdateTheme(status);

    if (!status) {
      if (!isAutoSave) {
        addToast({
          title: i18n._(t`Theme saved as draft`),
          type: TOAST_TYPES.INFO,
        });
        goBack();
      }
    } else {
      addToast({
        title: i18n._(t`Theme published`),
        type: TOAST_TYPES.SUCCESS,
      });
      goBack();
    }
  };

  const onSave = async (e?: React.BaseSyntheticEvent) => {
    const submit = handleSubmit(
      () => {
        return onSubmit();
      },
      () => onFail(),
    );

    return submit(e);
  };

  const onPublish = async (e?: React.BaseSyntheticEvent) => {
    setValue('status', SURVEY_THEME_STATUS.PUBLISHED);
    const submit = handleSubmit(
      () => {
        return onSubmit(SURVEY_THEME_STATUS.PUBLISHED);
      },
      () => onFail(),
    );

    return submit(e);
  };

  const onAutosave = async (e?: React.BaseSyntheticEvent) => {
    const submit = handleSubmit(
      () => {
        return onSubmit(undefined, true);
      },
      () => onFail(true),
    );
    return submit(e);
  };

  return (
    <>
      <DashboardHeader
        title={`${i18n._(t`Creating a new theme`)}: ${nameInPrimaryLanguage}`}
        onBack={goBack}
        actions={
          <ActionsWrap>
            <LastSaved
              time={lastSavedTime}
              status={lastSavedStatus}
              errorMessage={lastSavedErrorMessage}
            />
            <ActionItemBlock>
              <MultiLangComponent languageState={languageState} />
            </ActionItemBlock>
            <ActionItemBlock>
              <Tooltip size={TOOLTIP_SIZES.BIG} disabled={false} tooltip={i18n._(t`Delete`)}>
                <span>
                  <Button
                    label=""
                    size={ButtonSize.MEDIUM}
                    variant={ButtonVariant.ICON_DELETE}
                    isLoading={isLoading}
                    onClick={() => setIsDeleteThemeModalVisible(true)}
                  />
                </span>
              </Tooltip>
              <Tooltip
                size={TOOLTIP_SIZES.BIG}
                disabled={false}
                tooltip={isDraft ? i18n._(t`Save draft`) : i18n._(t`Save`)}
              >
                <span>
                  <Button
                    label=""
                    icon={ICONS.SAVE}
                    size={ButtonSize.MEDIUM}
                    variant={ButtonVariant.ICON}
                    isLoading={isLoading}
                    onClick={isDraft ? onSave : onPublish}
                  />
                </span>
              </Tooltip>
            </ActionItemBlock>
            {isDraft && (
              <ActionItemBlock>
                <Button
                  label={i18n._(t`Publish`)}
                  size={ButtonSize.MEDIUM}
                  variant={ButtonVariant.PRIMARY}
                  isLoading={isLoading}
                  onClick={onPublish}
                />
              </ActionItemBlock>
            )}
          </ActionsWrap>
        }
      />
      {surveyThemeId && (
        <Wrapper>
          <SideBar
            sections={sectionState.sections}
            currentSection={sectionState.currentSection}
            setCurrentSection={sectionState.setCurrentSection}
            hideErrorState={!sectionState.triedToSubmit}
          />
          <Main>
            {sectionState.currentSection === 0 && (
              <General
                isLoading={isLoading}
                themeId={surveyThemeId}
                languageState={languageState}
                formMethods={generalFormMethods}
                setCurrentSection={sectionState.setCurrentSection}
                handleUpdateTheme={onAutosave}
                triedToSubmit={sectionState.triedToSubmit}
              />
            )}
            {sectionState.currentSection === 1 && (
              <Questions
                themeId={surveyThemeId}
                languageState={languageState}
                generalFormMethods={generalFormMethods}
                formMethods={questionsFormMethods}
                setCurrentSection={sectionState.setCurrentSection}
                onSubmit={onSubmit}
                onPublish={onPublish}
                isPublishDisabled={isLoading}
                updateLastSavedTime={setLastSaveSuccess}
                handleUpdateTheme={onAutosave}
                triedToSubmit={sectionState.triedToSubmit}
              />
            )}
          </Main>
          {isDeleteThemeModalVisible && (
            <ConfirmationModal
              type={CONFIRMATION_MODAL_TYPE.DELETE}
              title={i18n._(t`Delete theme?`)}
              description={i18n._(
                t`Deleting this theme will delete it from the survey report. You can no longer view the data for this theme and the related question. This action cannot be undone.`,
              )}
              onClose={() => setIsDeleteThemeModalVisible(false)}
              onSubmit={() => deleteTheme()}
            />
          )}
        </Wrapper>
      )}
    </>
  );
};

export { UpdateTheme };
