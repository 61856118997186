import React from 'react';

import styled from 'styled-components';

import { Icon, ICONS } from '~/components/Icon';
import Tooltip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import { COLORS } from '~/styles';

export const Wrapper = styled.div<{ color: string | undefined; isVisible?: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'inline-flex' : 'none')};
  align-items: center;
  cursor: pointer;

  &:hover {
    display: inline-flex;
  }

  svg {
    min-width: 20px;
    min-height: 20px;
    stroke: ${(props) => (props.color ? props.color : COLORS.GRAY_MIDDLE)};
  }
`;

interface RadioButtonProps {
  isSelected?: boolean;
  onChange?: (e: { stopPropagation: () => void }) => void;
  color?: string;
  isHovered?: boolean;
  isDisabled?: boolean;
  tooltip?: string;
}

export const RadioButton = ({
  onChange,
  isSelected = false,
  color,
  isHovered = false,
  isDisabled,
  tooltip,
}: RadioButtonProps) => {
  const content = (
    <Wrapper
      onClick={!isDisabled ? onChange : undefined}
      color={color}
      isVisible={isSelected || isHovered}
    >
      <Icon
        icon={isSelected ? ICONS.RADIO_BUTTON_SELECTED : ICONS.RADIO_BUTTON}
        stroke={COLORS.BORDERS}
        fill={COLORS.COMPANY}
      />
    </Wrapper>
  );

  return tooltip ? (
    <Tooltip tooltip={tooltip} placement={TOOLTIP_PLACEMENTS.BOTTOM}>
      <div>{content}</div>
    </Tooltip>
  ) : (
    content
  );
};
