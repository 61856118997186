import React, { useState } from 'react';

import { GOAL_TYPES, CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Icon, ICONS } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { SelectUsersModal } from '~/components/Modals/SelectUsersModal';
import ToolTip from '~/components/Tooltip';
import { UserAvatar } from '~/components/UserAvatar';

import { getUser } from '~/selectors/baseGetters';
import { getOwners, getType, getErrors, getSkills } from '~/selectors/currentGoal';
import { setCurrentGoalOwners, setCurrentGoalSkills } from '~/store/currentGoal/actions';
import { COLORS } from '~/styles';

import {
  ItemLabel,
  ItemRow,
  GrayRow,
  ItemLabelRow,
  CloseIconButton,
  Error,
} from '../../stepsDesign';

const OwnersSection = ({ isUpdate }: { isUpdate?: boolean }) => {
  const dispatch = useDispatch();
  const { i18n } = useLingui();
  const owners = useSelector(getOwners);
  const user = useSelector(getUser);
  const type = useSelector(getType);
  const skills = useSelector(getSkills);
  const isSingleChoice = [GOAL_TYPES.BUSINESS, GOAL_TYPES.PERSONAL].includes(type);
  const [showUsersModal, setShowUsersModal] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [ownersToDelete, setOwnersToDelete] = useState<string[]>([]);
  const errors = useSelector(getErrors);
  const isDisableEditOwner = isUpdate && !user.isCoach && !user.isAdmin;

  // coach, without admin role
  const isOnlyCoach = user.isCoach && !user.isAdmin;

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { users: preSelectedUsersIds, isDeleteOwnersDisabled } = query;

  const onAddOwners = (newOwners: string[]) => {
    const update = uniq([...owners, ...newOwners]);
    // @ts-ignore
    dispatch(setCurrentGoalOwners(update));
    setShowUsersModal(false);
  };

  const onDeleteOwners = (deletedOwners?: string[]) => {
    const filteredOwners = !isEmpty(deletedOwners) ? deletedOwners : ownersToDelete;
    const update = owners.filter((id: string) => !(filteredOwners as string[]).includes(id));
    // @ts-ignore
    dispatch(setCurrentGoalOwners(update));
    setOwnersToDelete([]);
    if ([GOAL_TYPES.PERSONAL, GOAL_TYPES.BUSINESS].includes(type)) {
      // reset skills
      // @ts-ignore
      dispatch(setCurrentGoalSkills([]));
    }
  };

  const renderOwner = (owner: string) => {
    const isHideDeleteBtn =
      (isDeleteOwnersDisabled && ((preSelectedUsersIds as string[]) || [])?.includes(owner)) ||
      isDisableEditOwner;

    return (
      <GrayRow key={owner} isSpaceBetween height="62px" marginTop="8px">
        <UserAvatar userId={owner} />
        {!isHideDeleteBtn && (
          <CloseIconButton
            onClick={
              [GOAL_TYPES.BUSINESS, GOAL_TYPES.PERSONAL].includes(type) &&
              !isEmpty(skills) &&
              skills.length > 0
                ? () => {
                    setOwnersToDelete([owner]);
                    setShowWarningModal(true);
                  }
                : () => onDeleteOwners([owner])
            }
          >
            {/* @ts-ignore */}
            <Icon size="20px" icon={ICONS.DELETE_BIN} color={COLORS.ACCENT_WARNING} />
          </CloseIconButton>
        )}
      </GrayRow>
    );
  };

  return (
    <div>
      <ItemRow
        marginTop="12px"
        marginBottom="5px"
        paddingTop="12px"
        flexColumn
        isError={errors && errors.owners}
      >
        <ItemLabelRow marginBottom="6px">
          <ItemLabel bold noMarginBottom>
            {[GOAL_TYPES.PERSONAL, GOAL_TYPES.BUSINESS].includes(type) ? (
              <Trans>Owner</Trans>
            ) : (
              <Trans>Owner(s)</Trans>
            )}
          </ItemLabel>
          {isSingleChoice && !isEmpty(owners) ? (
            <ToolTip tooltip={i18n._(t`A personal goal can only have one owner`)}>
              <div>
                <Button
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.MEDIUM}
                  onClick={() => setShowUsersModal(true)}
                  label={i18n._(t`Add owner`)}
                  disabled={(isSingleChoice && !isEmpty(owners)) || isDisableEditOwner}
                />
              </div>
            </ToolTip>
          ) : (
            <Button
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              onClick={() => setShowUsersModal(true)}
              label={i18n._(t`Add owner`)}
              disabled={(isSingleChoice && !isEmpty(owners)) || isDisableEditOwner}
            />
          )}
        </ItemLabelRow>
        {(owners || []).map((owner: string) => renderOwner(owner))}
        {showUsersModal && (
          <SelectUsersModal
            onSave={onAddOwners}
            title={
              [GOAL_TYPES.PERSONAL, GOAL_TYPES.BUSINESS].includes(type)
                ? i18n._(t`Add owner`)
                : i18n._(t`Add owner(s)`)
            }
            onSaveLabelFactory={() => i18n._(t`Add owner`)}
            onClose={() => setShowUsersModal(false)}
            isHideSkillsFilter
            isSingleSelect={[GOAL_TYPES.PERSONAL, GOAL_TYPES.BUSINESS].includes(type)}
            usersToHide={owners}
            isOnlyCoachTeamMembers={
              [GOAL_TYPES.PERSONAL, GOAL_TYPES.BUSINESS].includes(type) && isOnlyCoach
            }
          />
        )}
        {showWarningModal && (
          <ConfirmationModal
            type={CONFIRMATION_MODAL_TYPE.WARNING}
            description={i18n._(
              t`Are you sure you want to change the goal owner? The selected goal suggestion will be removed`,
            )}
            onClose={() => {
              setShowWarningModal(false);
              if (!isEmpty(ownersToDelete)) {
                setOwnersToDelete([]);
              }
            }}
            onSubmit={onDeleteOwners}
            cancelButtonTextColor={COLORS.TEXT_MAIN}
          />
        )}
      </ItemRow>
      <Error>{errors && errors.owners}</Error>
    </div>
  );
};

export { OwnersSection };
