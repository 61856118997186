import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import InfoIcon from '~/components/Icons/Info';
import Tooltip from '~/components/Tooltip';

const Container = styled.div`
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  padding: 0 4px;
  cursor: pointer;
  text-transform: lowercase;
`;

const Hint = ({ children, className, maxWidth, placement, error }) => (
  <Tooltip maxWidth={maxWidth} tooltip={children} placement={placement}>
    <Container className={className}>
      <InfoIcon error={error} />
    </Container>
  </Tooltip>
);

Hint.propTypes = {
  className: PropTypes.string,
  maxWidth: PropTypes.string,
};

Hint.defaultProps = {
  maxWidth: '200px',
};

export default Hint;
