import React from 'react';

import styled, { css } from 'styled-components';

import { IconOld } from '~/components/IconOld';

import { COLORS } from '~/styles';

const IconPreview = styled.div<{ colors: string[] }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  box-sizing: border-box;
  ${({ colors }) =>
    colors?.length && colors.length > 1
      ? css`
          background: linear-gradient(to bottom, ${colors[0]}, ${colors[1]});
        `
      : css`
          background-color: ${colors[0]};
        `}
  border-radius: 6px;
`;

interface IIconWithGradientBlock {
  iconColor: string | null;
  icon: string | null;
}

const IconWithGradientBlock = ({ iconColor, icon }: IIconWithGradientBlock) => {
  return (
    <IconPreview colors={iconColor !== null ? iconColor?.split('-') : '#f7f9ff-#ebf1fe'.split('-')}>
      {icon !== null && (
        // @ts-ignore
        <IconOld name={icon} width={26} height={26} color={COLORS.MIDDLE_GRAY} />
      )}
    </IconPreview>
  );
};

export { IconWithGradientBlock };
