import React, { useState } from 'react';

import { COMPANY_DELETE_REQUEST_STATUSES, RESPONSE_STATUSES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import Editor from '~/components/Editor';
import Modal from '~/components/Modal';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import { TableCol } from '~/components/UI/Table';

import useBoolState from '~/hooks/useBoolState';
import { deleteCompanyAllDataSuperAdmin } from '~/services/companies';
import { handleCompanyDeleteRequest } from '~/services/companyDeleteRequests';
import { COLOR_PALETTE } from '~/styles';

const LabelGrey = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: ${COLOR_PALETTE.BLACK};
  margin: 20px 0 8px;
`;

const ButtonGroup = styled(TableCol)`
  display: flex;
  align-items: center;

  .reject-btn {
    margin-right: 16px;
    border: solid 1px #767e94;
    color: #767e94;
  }

  .invite-btn {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    text-align: center;
    padding: 12px 17.5px;
    height: 40px;
    min-width: 83px;
  }
`;

const ProcessRequestModal = ({ deleteRequest, onClose, forceFetch }) => {
  const { i18n } = useLingui();
  const [comment, setComment] = useState('');
  const $loading = useBoolState(false);
  const { addToast } = useToasts();

  const doubleConfirm = async (submitFn) => {
    if (
      await confirm(
        i18n,
        i18n._(
          t`Are you sure you want to PERMANENTLY delete the company ${deleteRequest.name} and ALL OF ITS USERDATA with email address ${deleteRequest.email} from the database? THIS ACTION CANNOT BE UNDONE!`,
        ),
      )
    ) {
      if (
        await confirm(
          i18n,
          i18n._(
            t`Are you ENTIRELY SURE of PERMANENTLY DELETING company ${deleteRequest.name} with all of its userdata?`,
          ),
        )
      ) {
        await submitFn();
      } else {
        alert(i18n._(t`Company ${deleteRequest.name} will be retained. Phew!`));
      }
    } else {
      alert(i18n._(t`Company ${deleteRequest.name} will be retained.`));
    }
  };

  const handleRequestAPI = async (status, comment) => {
    const isAccepted = status === COMPANY_DELETE_REQUEST_STATUSES.ACCEPTED;
    // change status of delete company request
    const response = await handleCompanyDeleteRequest(deleteRequest.id, {
      comment,
      status,
    });

    // re-fetch requests
    forceFetch();

    // show toast if response success
    // error handle general error handler
    if (response.status === RESPONSE_STATUSES.SUCCESS) {
      addToast({
        title: i18n._(t`The DELETE company request is ${status}!`),
        subtitle: isAccepted && i18n._(t`We are deleting all company data now.`),
        type: TOAST_TYPES.INFO,
      });
    }
  };

  const onSubmit = async (status) => {
    try {
      $loading.on();

      if (status === COMPANY_DELETE_REQUEST_STATUSES.ACCEPTED) {
        await doubleConfirm(async () => {
          // update request status
          await handleRequestAPI(status, comment);

          // close Modal
          onClose();

          // !! important, this logic deletes company in DB
          await deleteCompanyAllDataSuperAdmin(deleteRequest.company);

          addToast({
            title: i18n._(t`Company: ${deleteRequest.name} is DELETED!`),
            type: TOAST_TYPES.INFO,
          });
        });
      } else {
        // update request status
        await handleRequestAPI(status, comment);
      }

      // close modal in the end
      onClose();
    } finally {
      $loading.off();
    }
  };

  const onAccept = () => onSubmit(COMPANY_DELETE_REQUEST_STATUSES.ACCEPTED);
  const onReject = () => onSubmit(COMPANY_DELETE_REQUEST_STATUSES.REJECTED);

  const renderComment = () => {
    return (
      <>
        <LabelGrey>{i18n._(t`Comment`)}</LabelGrey>
        <Editor
          onChange={(value) => setComment(value)}
          value={comment}
          placeholder={i18n._(t`Type your comment here…`)}
          className="activity_description"
          compact={true}
        />
      </>
    );
  };

  return (
    <Modal
      title={i18n._(t`Process delete company request`)}
      onClose={onClose}
      width={680}
      minWidth={680}
      minHeight="200px"
      footerRight={
        <ButtonGroup>
          <Button
            label={i18n._(t`Reject`)}
            onClick={onReject}
            className="reject-btn invite-btn"
            type="primary-border"
            loading={$loading.value}
          />
          <Button
            label={i18n._(t`Accept`)}
            onClick={onAccept}
            className="invite-btn"
            type="primary-border"
            loading={$loading.value}
          />
        </ButtonGroup>
      }
    >
      {renderComment()}
    </Modal>
  );
};

export default ProcessRequestModal;
