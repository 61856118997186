import Tippy from '@tippyjs/react';
import styled from 'styled-components';

import Button from '~/components/Button';
import { SearchField, StyledTextField } from '~/components/Text';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  background-color: ${COLORS.WHITE};
  max-width: 1000px;
  border-radius: 0 10px 10px 10px;
  padding: 30px 34px 21px;
`;

export const Title = styled.span`
  font-size: 16px;
  color: ${COLORS.BLACK};
  padding: 0;
  margin-bottom: 5px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
`;

export const Clickable = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Link = styled.a`
  text-decoration: none;
  font-size: 14px;
  text-align: center;
  color: var(--company-color);
`;

export const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
`;

export const ActionItemBlock = styled.div`
  display: flex;
  align-items: center;
  border-left: solid 1.2px ${COLORS.BORDER_HARD};
  justify-content: center;
  padding-left: 17px;
  padding-right: 17px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ActionButton = styled(Button)`
  white-space: nowrap;
  height: 32px;
  box-shadow: 0 2px 4px 0 rgba(191, 191, 191, 0.5);

  &:hover {
    font-weight: 600;
    box-shadow: unset;
  }
`;

export const TippyName = styled.div`
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 12px;
  text-align: center;
  padding: 7px 12px 7px 12px;
`;

export const StyledTippy = styled(Tippy)`
  border-radius: 6px;
  background-color: ${COLORS.MIDDLE_GRAY};
  .tippy-content {
    padding: 0;
  }

  .tippy-arrow {
    color: ${COLORS.MIDDLE_GRAY};
  }
`;

export const Dash = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
`;

export const StyledSearchField = styled(SearchField)<{
  maxWidth?: string;
}>`
  flex-grow: 1;
  height: 32px;

  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}

  ${StyledTextField} {
    border-radius: 16px;
  }
`;
