import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AutocompleteFilterTeams } from '~/components/AutocompleteFilters';
import Button from '~/components/Button';
import DatePicker from '~/components/DatePickerDeprecated';
import SortDownIcon from '~/components/Icons/SortDown';
import SortUpIcon from '~/components/Icons/SortUp';
import SearchSelectButton from '~/components/SearchSelectButton';

import { SORT_DIRECTION } from '~/constants';

const FilterWrap = styled.div`
  margin-right: 8px;
`;

const FiltersRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  margin-right: 24px;

  .datepicker-new {
    height: 32px;
  }
`;

const FilterTeams = styled(AutocompleteFilterTeams)`
  width: 157px;
  height: 32px;
  margin-right: 8px;
`;

const MainRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #d8dfed;
  padding: 16px 24px;
`;

const SortBySelect = styled(SearchSelectButton)`
  height: 32px;
  width: 80px;
  margin-right: 6px;
`;

const SortContainer = styled.span`
  height: 20px;
`;

const sortIcon = {
  [SORT_DIRECTION.ASC]: SortUpIcon,
  [SORT_DIRECTION.DESC]: SortDownIcon,
};

const ConversationFiltersRow = ({
  i18n,
  applyFilters,
  handleFrom,
  handleTo,
  dateFrom,
  dateTo,
  handleTeams,
  selectedTeams,
  sortOptions,
  handleSortBy,
  handleSortDirection,
  sortDirection,
  sortBy,
  filtersChanged,
}) => {
  const SortIcon = sortIcon[sortDirection];

  return (
    <MainRow>
      <FiltersRow>
        <FilterWrap>
          <DatePicker
            onChange={handleFrom}
            placeholder={i18n._(t`From`)}
            dateFormat="MMM yyyy"
            showMonthYearPicker={true}
            showFullMonthYearPicker={true}
            showTwoColumnMonthYearPicker={true}
            selected={dateFrom}
            isHideIcon={true}
            width={140}
            height={32}
          />
        </FilterWrap>
        <FilterWrap>
          <DatePicker
            onChange={handleTo}
            placeholder={i18n._(t`To`)}
            dateFormat="MMM yyyy"
            showMonthYearPicker={true}
            showFullMonthYearPicker={true}
            showTwoColumnMonthYearPicker={true}
            selected={dateTo}
            isHideIcon={true}
            width={140}
            height={32}
            minDate={dateFrom}
          />
        </FilterWrap>
        <FilterTeams onChange={handleTeams} checkedList={selectedTeams} />
        {!isEmpty(sortOptions) && (
          <>
            <SortBySelect
              title={i18n._(t`Sort`)}
              checkedList={sortBy}
              handleChange={handleSortBy}
              options={sortOptions}
              width="200px"
              minWidth="80px"
              isRadio
              noHeader
            />
            <SortContainer onClick={handleSortDirection}>
              <SortIcon fill={'var(--company-color)'} size={18} />
            </SortContainer>
          </>
        )}
      </FiltersRow>
      <Button
        label={i18n._(t`Apply`)}
        onClick={applyFilters}
        disabled={!filtersChanged}
        height={32}
        width={82}
      />
    </MainRow>
  );
};

ConversationFiltersRow.propTypes = {
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  applyFilters: PropTypes.func,
  handleFrom: PropTypes.func,
  handleTo: PropTypes.func,
  minDate: PropTypes.string,
  handleSortBy: PropTypes.func,
  handleSortDirection: PropTypes.func,
  sortDirection: PropTypes.string,
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  sortBy: PropTypes.arrayOf(PropTypes.string),
};

export default withI18n()(ConversationFiltersRow);
