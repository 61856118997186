import React from 'react';

import styled from 'styled-components';

import { ICONS, Icon } from '~/components/Icon';

const IconWrapper = styled.button`
  display: inline;
  margin-left: 5px;
  margin-right: 5px;

  .collapsed {
    transform: rotate(-90deg);
  }
`;

type TCollapseIndicatorProps = {
  isCollapsed: boolean;
  handleOnClick: () => void;
};

const CollapseIndicator: React.FC<TCollapseIndicatorProps> = ({ isCollapsed, handleOnClick }) => {
  return (
    <IconWrapper
      onClick={(event) => {
        event.stopPropagation();
        handleOnClick();
      }}
    >
      <Icon
        icon={ICONS.DROPDOWN}
        width={'12px'}
        className={isCollapsed ? 'collapsed' : 'not-collapsed'}
      />
    </IconWrapper>
  );
};

export default CollapseIndicator;
