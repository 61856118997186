import isEmpty from 'lodash/isEmpty';

export default (users, team) => {
  let allMembers = [];

  if (!isEmpty(team.members)) {
    allMembers = [...team.members]
      .map((m) => (m.id ? m : users[m] ? users[m] : null))
      .filter((m) => m);
  }

  return allMembers;
};
