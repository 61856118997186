import { ROLES } from '~/constants';
import { pageOverview, pageReport } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'learnings';

// overview
export const ONBOARD_AND_LEARN = new LinkConstructor([ROLES.USER], () =>
  pageOverview('onboard-and-learn'),
);

export const LEARNINGS_REPORT = new LinkConstructor([ROLES.ADMIN], () => pageReport(routeName));
