import React from 'react';
import type { ReactNode, FunctionComponent } from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
  overflow: hidden;
  width: 100%;
`;

const InfiniteRow = styled.div<{ maxWidth?: string }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth};`}
`;

interface IStringInfinite {
  children: ReactNode;
  maxWidth?: string;
}

const StringInfinite: FunctionComponent<IStringInfinite> = ({ children, maxWidth }) => {
  return (
    <Wrapper>
      <InfiniteRow maxWidth={maxWidth}>{children}</InfiniteRow>
    </Wrapper>
  );
};

export default StringInfinite;
