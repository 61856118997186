import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';

import {
  CONFIRMATION_MODAL_TYPE,
  ProductName,
  REVIEW_QUESTION_TYPES,
  REVIEW_STATUS,
  ROLES,
} from '@learned/constants';
import {
  IReviewQuestionCustomSkill,
  IReviewQuestionSkillCategory,
  IReviewQuestionRating,
  ICompany,
} from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { ButtonVariant } from '~/components/Buttons';
import { HeaderFocusMode } from '~/components/Headers/HeaderFocusMode';
import { HeaderIconButtons } from '~/components/Headers/HeaderFocusMode/types';
import { ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { ProductSettingsModal, AUTO_SCROLL } from '~/components/Modals/ProductSettingsModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SideBar } from '~/components/SideBar';
import { useSectionState } from '~/components/SideBar/SectionStateHook';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import { LoadingModal } from '~/pages/Reviews/components/LoadingModal';
import { useReview } from '~/pages/Reviews/EditIndividual/ReviewIndividualForm/hooks/useReview';

import { Wrapper } from './design';
import { useReviewInvitation } from './hooks/useReviewInvitation';
import { useReviewTasks } from './hooks/useReviewTasks';
import { StepEmployee } from './StepEmployee';
import { StepFinalCheck } from './StepFinalCheck';
import { StepGeneral } from './StepGeneral';
import { StepSettings } from './StepSettings';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { useFromQuery } from '~/hooks/useFromQuery';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser } from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import type { IReviewIndividualForm } from './types';
import type { UseFormReturn } from 'react-hook-form';

interface ReviewIndividualFormProps {
  formMethods: UseFormReturn<IReviewIndividualForm>;
  languageState: ILanguageStateReturn;
  setUserId: Dispatch<SetStateAction<string | undefined>>;
}

const ReviewIndividualForm = ({
  formMethods,
  languageState,
  setUserId,
}: ReviewIndividualFormProps) => {
  const history = useHistory();
  const getMultiLangString = useMultiLangString();
  const { i18n } = useLingui();
  const sectionState = useSectionState([]);
  const performanceSettingsModal = useBoolState(false);
  const { goBack } = useFromQuery({ includeHash: true });
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isCreatingNew = query.isCreatingNew;
  const params: Record<string, string | undefined> = useParams();
  const reviewId = params.reviewId as string;

  const { addToast } = useToasts();
  const currentCompany = useSelector(getCurrentCompany);
  const currentUser = useSelector(getUser);

  const {
    products: {
      performance: {
        settings: {
          labels: { ratingLabels, skillLabels },
        },
      },
    },
  } = currentCompany as ICompany;

  const {
    deleteReview,
    isAllowToDelete,
    isReviewLoading,
    saveReview,
    isReviewSaving,
    reviewTemplates,
  } = useReview({
    formMethods,
    reviewId,
    setUserId,
  });
  const { autoGenerateDates } = useReviewTasks({ formMethods });
  const { autoSelectInvitationTemplate, invitationTemplates, refreshInvitationTemplates } =
    useReviewInvitation({ formMethods, languageState });

  const {
    watch,
    trigger,
    formState: { dirtyFields, errors },
    handleSubmit,
    setValue,
  } = formMethods;
  const isAutoGenerate = watch('settings.isAutoTimeline');
  const cycleStartDate = watch('settings.startDate');
  const watchStatus = watch('status');
  const watchReviewTemplate = watch('reviewTemplate');
  const isDraft = watchStatus === REVIEW_STATUS.DRAFT;

  useEffect(() => {
    sectionState.setSections([
      {
        title: i18n._(t`General`),
      },
      {
        title: i18n._(t`Select job and participants`),
      },
      {
        title: i18n._(t`Settings`),
        fields: [
          i18n._(t`General`),
          i18n._(t`Notifications`),
          i18n._(t`Privacy`),
          i18n._(t`Invitation`),
          i18n._(t`Timeline`),
        ],
      },
      {
        title: i18n._(t`Final check`),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isReviewLoading) {
      autoSelectInvitationTemplate();
    }
    if (isCreatingNew) {
      autoGenerateDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReviewLoading]);

  useEffect(() => {
    if (!watch('employees')?.[0]?.id) {
      setValue('employees', [], { shouldDirty: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watch('employees'))]);

  // Validation
  useEffect(() => {
    sectionState.setErrorSection(0, !!errors.name || !!errors.reviewTemplate);
    sectionState.setErrorSection(1, !!errors.employees);
    sectionState.setErrorSection(
      2,
      !!errors.settings ||
        !!errors.tasks ||
        !!errors.reviewInvitationTemplate ||
        !!errors.description,
    );
    if (errors) {
      sectionState.goToFirstErrorSection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (isAutoGenerate) {
      autoGenerateDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoGenerate, cycleStartDate]);

  const onCloseButton = async () => {
    if (isEmpty(dirtyFields)) {
      goBack();
    } else {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.INFO,
        title: i18n._(t`Close without saving?`),
        description: i18n._(
          t`Are you sure you want to close without saving? This action cannot be undone.`,
        ),
        submitButton: !isDraft && i18n._(t`Save changes`),
      });
      if (isConfirmed) {
        onSaveReview();
      } else {
        goBack();
      }
    }
  };

  const goToAllTab = () =>
    history.push(
      routes.REVIEWS.build(
        // @ts-ignore
        { role: ROLES.USER },
        { hash: 'all' },
      ),
    );

  const onPublishReview = async () => {
    if (!isEmpty(errors)) {
      return;
    }

    const response = await saveReview(isDraft ? REVIEW_STATUS.PUBLISHED : undefined);
    if (response.code === 200) {
      addToast({
        title: isDraft ? i18n._(t`Review published`) : i18n._(t`Review saved`),
        type: TOAST_TYPES.SUCCESS,
      });
    }
    goBack();
  };

  const ratingScaleValidation = useMemo(() => {
    if (watchReviewTemplate) {
      const questions = reviewTemplates.find(({ id }) => id === watchReviewTemplate)?.questions;
      const ratingQuestions = (questions as unknown as IReviewQuestionRating[])?.filter(
        ({ settings, type }) => !settings.isManualScale && type === REVIEW_QUESTION_TYPES.RATING,
      );
      const skillQuestions = (
        questions as unknown as Array<IReviewQuestionCustomSkill | IReviewQuestionSkillCategory>
      )?.filter(
        ({ settings, type }) =>
          !settings.isManualScale &&
          [REVIEW_QUESTION_TYPES.CUSTOM_SKILL, REVIEW_QUESTION_TYPES.SKILL_CATEGORY].includes(type),
      );

      // if there's no rating labels or there is empty field
      if (
        !isEmpty(ratingQuestions) &&
        (isEmpty(ratingLabels) ||
          !ratingLabels.every((item) => Object.values(item.name).find((value) => value)))
      ) {
        return false;
      }

      // if there's no skill labels or there is empty field
      if (
        !isEmpty(skillQuestions) &&
        (isEmpty(skillLabels) ||
          !skillLabels.every((item) => Object.values(item.name).find((value) => value)))
      ) {
        return false;
      }

      return true;
    }

    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify({ watchReviewTemplate, ratingLabels, skillLabels })]);

  const onClickPublish = async () => {
    if (ratingScaleValidation) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Publish review?`),
        description: i18n._(
          t`Are you sure you want to publish the review? From the start date, the review will be available to all participants.`,
        ),
      });
      if (isConfirmed) {
        sectionState.setTriedToSubmit();
        handleSubmit(onPublishReview)();
      }
    } else {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: currentUser.isAdmin
          ? i18n._(t`Rating scale incomplete`)
          : i18n._(t`Not possible to create review`),
        description: currentUser.isAdmin
          ? i18n._(
              t`It is not possible to publish this review because the rating scale is not complete. 
                Press 'continue' to go to the performance settings, where you can fill in the blanc fields of the rating scale.`,
            )
          : i18n._(
              t`It is not possible to publish this review because the rating scale is not complete.
                Contact HR to manage this in the performance settings.
                Press 'Continue' to save the review as draft.`,
            ),
      });

      if (isConfirmed) {
        if (currentUser.isAdmin) {
          performanceSettingsModal.on();
        } else {
          onSaveReview();
        }
      }
    }
  };

  const onDelete = async () => {
    const confirmResult = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Delete review?`),
      description: i18n._(
        t`Are you sure you want to delete this review? This action cannot be undone.`,
      ),
    });

    if (confirmResult) {
      await deleteReview();
      addToast({
        title: i18n._(t`Review deleted`),
        type: TOAST_TYPES.SUCCESS,
      });
      goToAllTab();
    }
  };

  const onSaveReview = async () => {
    trigger('name');
    if (errors.name) {
      return;
    }

    const response = await saveReview();
    if (response.code === 200) {
      addToast({ title: i18n._(t`Review saved as draft`), type: TOAST_TYPES.SUCCESS });
    }
    goBack();
  };

  const nameMultiLang = turnArrayIntoMultiLang(watch('name'));

  const onSaveNotDraftCycle = () => {
    sectionState.setTriedToSubmit();
    handleSubmit(onPublishReview)();
  };

  const iconsButtons: HeaderIconButtons[] = [
    ...(isAllowToDelete
      ? [
          {
            icon: ICONS.DELETE_BIN,
            tooltip: i18n._(t`Delete`),
            onClick: async () => {
              await onDelete();
            },
          } as HeaderIconButtons,
        ]
      : []),
    {
      icon: ICONS.SAVE,
      tooltip: i18n._(t`${isDraft ? 'Save draft' : 'Save'}`),
      onClick: isDraft ? onSaveReview : onSaveNotDraftCycle,
    },
  ];

  return (
    <>
      <HeaderFocusMode
        title={i18n._(
          t`${isCreatingNew ? 'Create' : 'Edit'} review: ${getMultiLangString(nameMultiLang)}`,
        )}
        goBack={onCloseButton}
        languageState={languageState}
        submitButton={
          isDraft
            ? {
                title: i18n._(t`Publish`),
                type: ButtonVariant.PRIMARY,
                onClick: onClickPublish,
              }
            : undefined
        }
        iconButtons={iconsButtons}
      />
      <Wrapper>
        <SideBar
          sections={sectionState.sections}
          currentSection={sectionState.currentSection}
          setCurrentSection={sectionState.setCurrentSection}
        />
        <ShowSpinnerIfLoading loading={isReviewLoading}>
          {sectionState.currentSection === 0 && (
            <StepGeneral
              sectionState={sectionState}
              formMethods={formMethods}
              reviewTemplates={reviewTemplates}
              languageState={languageState}
            />
          )}
          {sectionState.currentSection === 1 && (
            <StepEmployee
              sectionState={sectionState}
              formMethods={formMethods}
              languageState={languageState}
            />
          )}
          {sectionState.currentSection === 2 && (
            <StepSettings
              sectionState={sectionState}
              formMethods={formMethods}
              languageState={languageState}
              refreshInvitationTemplates={refreshInvitationTemplates}
              invitationTemplates={invitationTemplates}
            />
          )}
          {sectionState.currentSection === 3 && (
            <StepFinalCheck
              sectionState={sectionState}
              formMethods={formMethods}
              onPublish={isDraft ? onClickPublish : onSaveNotDraftCycle}
              publishLabel={isDraft ? i18n._(t`Publish`) : i18n._(t`Save`)}
            />
          )}
        </ShowSpinnerIfLoading>
      </Wrapper>
      {isReviewSaving && <LoadingModal title={i18n._(t`Saving, please wait...`)} />}
      {performanceSettingsModal.value && (
        <ProductSettingsModal
          name={ProductName.PERFORMANCE}
          closeModal={performanceSettingsModal.off}
          autoScroll={AUTO_SCROLL.LABELS}
        />
      )}
    </>
  );
};

export { ReviewIndividualForm };
