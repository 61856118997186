import { useEffect, useState } from 'react';

import { useParams } from 'react-router';

import { getJobProfile } from '~/services/jobProfiles';

import type { IJobProfile } from '@learned/types';

const useJobProfile = () => {
  const params: Record<string, string | undefined> = useParams();
  const jobProfileId = params.roleId;
  const [jobProfile, setJobProfile] = useState<IJobProfile>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);

    async function fetchJobProfile() {
      if (!jobProfileId) {
        return;
      }

      const response = await getJobProfile(jobProfileId, [
        'skills',
        'jobTemplate',
        'recommendedJobs',
      ]);
      if (!mounted || response.code !== 200) {
        setIsLoading(false);
        return;
      }

      setJobProfile(response.data.jobProfile);
      setIsLoading(false);
    }

    fetchJobProfile();

    return () => void (mounted = false);
  }, [jobProfileId]);

  return { jobProfile, isLoading };
};

export { useJobProfile };
