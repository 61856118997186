import React, { ChangeEvent } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Button from '~/components/Button';
import ExportIcon from '~/components/Icons/Export';
import { SearchField } from '~/components/Text';
import { defaultFilters } from '~/pages/AdminReports/LearningReportPage/TeamItemModal';

import { TIME_FRAMES, TimeFrameSelector } from './../TimeFrameSelector';

import { COLOR_PALETTE } from '~/styles';

const Wrapper = styled.div`
  padding: 12px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const SearchFieldWrapper = styled(SearchField)`
  margin-right: 8px;
  height: 32px;
  width: 100%;
`;

const ActionButton = styled(Button)`
  margin-right: 18px;
  white-space: nowrap;
  height: 32px;
  box-shadow: 0 2px 4px 0 rgba(191, 191, 191, 0.5);
`;

const SearchWrapper = styled.div`
  width: 305px;
`;

const MarginRight = styled.div`
  margin-right: 8px;
`;

interface IFiltersRow {
  filters: typeof defaultFilters;
  onChangeFilters: (obj: object) => void;
  onDownloadCSV: () => void;

  timeFrame: typeof TIME_FRAMES.LAST_WEEK;

  onChangeTimeFrame: (timeFrame: typeof TIME_FRAMES.LAST_WEEK) => void;
}

export const FiltersRow = ({
  filters,
  onChangeFilters,
  onDownloadCSV,
  timeFrame,
  onChangeTimeFrame,
}: IFiltersRow) => {
  const { i18n } = useLingui();

  return (
    <Wrapper>
      <Row>
        <SearchWrapper>
          <SearchFieldWrapper
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChangeFilters({ search: e.target.value.toLowerCase() })
            }
            value={filters.search}
            placeholder={i18n._(t`Search`)}
          />
        </SearchWrapper>
      </Row>
      <Row>
        <MarginRight>
          <ActionButton
            // @ts-ignore
            type={'shadow'}
            label={i18n._(t`Export csv`)}
            disabled={!onDownloadCSV}
            iconLeft={<ExportIcon size={20} />}
            onClick={onDownloadCSV}
          />
        </MarginRight>
        <MarginRight>
          <TimeFrameSelector
            timeFrameId={timeFrame.id}
            onChange={onChangeTimeFrame}
            styles={{ width: '148px', height: '32px', boxSizing: 'border-box' }}
          />
        </MarginRight>
      </Row>
    </Wrapper>
  );
};
