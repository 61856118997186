export enum REVIEW_TYPES {
  SELF = 'self',
  INDIVIDUAL = 'individual',
  REVIEW_CYCLE = 'review-cycle',
}

export enum SIGNATURE_STATUSES {
  WAITING = 'waiting',
  ACTIVE = 'active',
  SIGNED = 'signed',
}

export enum SIGNATURE_VIEWS {
  USER = 'user',
  COACH = 'coach',
}

export enum REVIEW_SECTION_THEMES {
  DEFAULT = 'default',
  BUSINESS_GOALS = 'businessGoals',
  LEARNING_GOALS = 'learningGoals',
  SKILLS = 'skills',
  CAREER = 'career',
  WELL_BEING = 'wellBeing',
  AMBITIONS = 'ambitions',
}

export enum REVIEW_SECTIONS {
  NONE = 'none',
  PERFORMANCE = 'performance',
  POTENTIAL = 'potential',
  SKILLS = 'skills',
}

export enum REVIEW_STATUS {
  DRAFT = 'draft', // not published
  PUBLISHED = 'published', // as upcoming - start date in future
  ACTIVE = 'active', // start date today or before today
  COMPLETED = 'completed', // All reviews in this cycle are completed
  ARCHIVED = 'archived',
}

export enum REVIEW_LAST_STATUS_METHOD {
  MANUAL = 'manual',
  AUTO = 'auto',
}

export enum REVIEW_CREATED_BY {
  ADMIN = 'admin',
  COACH = 'coach',
  EMPLOYEE = 'employee',
}

export enum REVIEW_SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
  START_DATE_NEW_OLD = 'START_DATE_NEW_OLD',
  START_DATE_OLD_NEW = 'START_DATE_OLD_NEW',
  EMPLOYEE_A_Z = 'EMPLOYEE_A_Z',
  EMPLOYEE_Z_A = 'EMPLOYEE_Z_A',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
}

export enum REVIEW_PARTICIPATION_COLUMNS {
  SELF_REVIEW = 'self-review',
  PEER_REVIEW = 'peer-review',
  COACH_REVIEW = 'coach-review',
  NOMINATED_PEERS = 'nominated-peers',
  COMPLETED_REVIEW = 'completed-review',
  PEER_REVIEW_PROVIDED = 'peer-review-provided',
  COACH_REVIEW_PROVIDED = 'coach-review-provided',
  SIGNED = 'signed',
}

export const REVIEW_PARTICIPATION_COLUMNS_ORDER = [
  REVIEW_PARTICIPATION_COLUMNS.NOMINATED_PEERS,
  REVIEW_PARTICIPATION_COLUMNS.SELF_REVIEW,
  REVIEW_PARTICIPATION_COLUMNS.PEER_REVIEW,
  REVIEW_PARTICIPATION_COLUMNS.COACH_REVIEW,
  REVIEW_PARTICIPATION_COLUMNS.SIGNED,
  REVIEW_PARTICIPATION_COLUMNS.COMPLETED_REVIEW,
  REVIEW_PARTICIPATION_COLUMNS.PEER_REVIEW_PROVIDED,
  REVIEW_PARTICIPATION_COLUMNS.COACH_REVIEW_PROVIDED,
];

export enum REVIEW_PARTICIPATION_SORT_BY {
  NAME_ASC = 'name__asc',
  NAME_DESC = 'name__desc',
  NOMINATED_PEERS_ASC = 'nominated-peers__asc',
  NOMINATED_PEERS_DESC = 'nominated-peers__desc',
  SELF_REVIEW_ASC = 'self-review__asc',
  SELF_REVIEW_DESC = 'self-review__desc',
  PEER_REVIEW_ASC = 'peer-review__asc',
  PEER_REVIEW_DESC = 'peer-review__desc',
  COACH_REVIEW_ASC = 'coach-review__asc',
  COACH_REVIEW_DESC = 'coach-review__desc',
  SIGNED_ASC = 'signed__asc',
  SIGNED_DESC = 'signed__desc',
  COMPLETED_REVIEW_ASC = 'completed-review__asc',
  COMPLETED_REVIEW_DESC = 'completed-review__desc',
  PEER_REVIEW_PROVIDED_ASC = 'peer-review-provided__asc',
  PEER_REVIEW_PROVIDED_DESC = 'peer-review-provided__desc',
  COACH_REVIEW_PROVIDED_ASC = 'coach-review-provided__asc',
  COACH_REVIEW_PROVIDED_DESC = 'coach-review-provided__desc',
}

export const BRIDGE_SORT_BY_WITH_COLUMN = {
  [REVIEW_PARTICIPATION_SORT_BY.NOMINATED_PEERS_ASC]: REVIEW_PARTICIPATION_COLUMNS.NOMINATED_PEERS,
  [REVIEW_PARTICIPATION_SORT_BY.NOMINATED_PEERS_DESC]: REVIEW_PARTICIPATION_COLUMNS.NOMINATED_PEERS,
  [REVIEW_PARTICIPATION_SORT_BY.SELF_REVIEW_ASC]: REVIEW_PARTICIPATION_COLUMNS.SELF_REVIEW,
  [REVIEW_PARTICIPATION_SORT_BY.SELF_REVIEW_DESC]: REVIEW_PARTICIPATION_COLUMNS.SELF_REVIEW,
  [REVIEW_PARTICIPATION_SORT_BY.PEER_REVIEW_ASC]: REVIEW_PARTICIPATION_COLUMNS.PEER_REVIEW,
  [REVIEW_PARTICIPATION_SORT_BY.PEER_REVIEW_DESC]: REVIEW_PARTICIPATION_COLUMNS.PEER_REVIEW,
  [REVIEW_PARTICIPATION_SORT_BY.COACH_REVIEW_ASC]: REVIEW_PARTICIPATION_COLUMNS.COACH_REVIEW,
  [REVIEW_PARTICIPATION_SORT_BY.COACH_REVIEW_DESC]: REVIEW_PARTICIPATION_COLUMNS.COACH_REVIEW,
  [REVIEW_PARTICIPATION_SORT_BY.SIGNED_ASC]: REVIEW_PARTICIPATION_COLUMNS.SIGNED,
  [REVIEW_PARTICIPATION_SORT_BY.SIGNED_DESC]: REVIEW_PARTICIPATION_COLUMNS.SIGNED,
  [REVIEW_PARTICIPATION_SORT_BY.COMPLETED_REVIEW_ASC]:
    REVIEW_PARTICIPATION_COLUMNS.COMPLETED_REVIEW,
  [REVIEW_PARTICIPATION_SORT_BY.COMPLETED_REVIEW_DESC]:
    REVIEW_PARTICIPATION_COLUMNS.COMPLETED_REVIEW,
  [REVIEW_PARTICIPATION_SORT_BY.PEER_REVIEW_PROVIDED_ASC]:
    REVIEW_PARTICIPATION_COLUMNS.PEER_REVIEW_PROVIDED,
  [REVIEW_PARTICIPATION_SORT_BY.PEER_REVIEW_PROVIDED_DESC]:
    REVIEW_PARTICIPATION_COLUMNS.PEER_REVIEW_PROVIDED,
  [REVIEW_PARTICIPATION_SORT_BY.COACH_REVIEW_PROVIDED_ASC]:
    REVIEW_PARTICIPATION_COLUMNS.COACH_REVIEW_PROVIDED,
  [REVIEW_PARTICIPATION_SORT_BY.COACH_REVIEW_PROVIDED_DESC]:
    REVIEW_PARTICIPATION_COLUMNS.COACH_REVIEW_PROVIDED,
};

export enum REVIEW_PARTICIPATION_ROW_TYPE {
  TEAM = 'team',
  EMPLOYEE = 'employee',
}

export enum REVIEW_JOIN {
  EMPLOYEES_TOTAL = 'employeesTotal',
}

export enum REVIEW_VERSION {
  V0_1 = '0.1', // all other reviews (structure unknown)
  V1_0 = '1.0', // review without review.template but with review.sections and questions
  V2_0 = '2.0', // review with review.template
  V3_0 = '3.0', // review created after learned 3-0 release (01.11.2024)
}

// if no digital sign -> create complete task only for reviews created after 23.08.2022
export const COMPLETE_REVIEW_FEATURE_START = '2022-08-23';
