import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Modal from '~/components/Modal';
import Conversations from '~/components/UserDevelopmentCycleModal/Conversations';
import OtherActivities from '~/components/UserDevelopmentCycleModal/OtherActivities';

import { COLOR_PALETTE } from '~/styles';

const Content = styled.div`
  display: flex;
`;

const Column = styled.div``;

const ColumnLeft = styled(Column)`
  min-width: 70%;
`;

const ColumnRight = styled(Column)`
  min-width: 30%;
`;

const ColumnTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin-bottom: 14px;
`;

const UserDevelopmentCycleModal = ({ onClose }) => {
  const { i18n } = useLingui();

  return (
    <Modal
      width={1000}
      minWidth={1000}
      title={i18n._(t`Development cycle`)}
      greyDescription={i18n._(t`An overview of your conversations and other activities`)}
      onClose={onClose}
      hideFooter
      headerStyles={{ padding: '24px 24px 8px' }}
      greyDescriptionStyles={{ padding: '0 24px 0 24px' }}
      contentStyles={{ padding: '24px' }}
      showDivider={false}
    >
      <Content>
        <ColumnLeft>
          <ColumnTitle>{i18n._(t`Conversations`)}</ColumnTitle>
          <Conversations />
        </ColumnLeft>
        <ColumnRight>
          <ColumnTitle>{i18n._(t`Other activities`)}</ColumnTitle>
          <OtherActivities />
        </ColumnRight>
      </Content>
    </Modal>
  );
};

UserDevelopmentCycleModal.propTypes = {
  onClose: PropTypes.func,
};

export default UserDevelopmentCycleModal;
