import React from 'react';

import Item from './Item';

const List = React.memo(function ItemsList({
  items,
  isDragAreaEverywhere,
  isDragDisabled,
  hasCustomDragBehavior,
}) {
  return items.map((item, index) => (
    <Item
      item={item}
      index={index}
      key={item.id}
      isDragAreaEverywhere={isDragAreaEverywhere}
      isDragDisabled={isDragDisabled}
      hasCustomDragBehavior={hasCustomDragBehavior}
    />
  ));
});

export default List;
