import styled from 'styled-components';

export const LikeButtonWrapper = styled.div<{
  backgroundColor: string;
  iconColor: string;
  textColor?: string;
  hoverBackgroundColor: string;
  hoverIconColor: string;
  hoverTextColor: string;
  isSaved?: boolean;
}>`
  width: fit-content;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 10px;
  font-size: 12px;

  & button {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    box-shadow: 0 2px 8px 0 rgba(43, 133, 103, 0.08);
    background: ${({ backgroundColor }) => backgroundColor};
    color: ${({ iconColor }) => iconColor};
  }

  &:hover {
    button {
      background: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
      box-shadow: 0 2px 8px 0 rgba(43, 133, 103, 0.08);
      color: ${({ hoverIconColor }) => hoverIconColor};
    }

    .btn-text {
      opacity: 1;
      color: ${({ hoverTextColor }) => hoverTextColor};
      text-decoration: ${({ isSaved }) => (isSaved ? 'line-through' : 'none')};
    }

    button:hover {
      background: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
      box-shadow: 0 2px 8px 0 rgba(43, 133, 103, 0.08);
      color: ${({ hoverIconColor }) => hoverIconColor};
    }
  }
`;
