import React from 'react';

import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import ExternalLinkIcon from '~/components/Icons/ExternalLink';

import { COLORS, COLOR_PALETTE, COLOR_SET } from '~/styles';

const ArticleLink = styled(ExternalLinkIcon)`
  margin-left: 10px;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  min-width: 128px;
`;

const Step = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  padding-left: 50px;
  box-sizing: border-box;
`;

const Text = styled.div`
  color: ${(props) => (props.isDone ? COLOR_SET.CYAN_GREEN : 'var(--company-color)')};
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  padding-right: 35px;
  text-align: center;
  width: 100px;
`;

const LinkButton = styled.div`
  color: var(--company-color);
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;

  a {
    color: var(--company-color);
    text-decoration: none;
    white-space: nowrap;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${(props) => (props.isDone ? COLOR_PALETTE.GRAY_MIDDLE : COLORS.TEXT_PRIMARY_2)};
  text-decoration: ${(props) => props.isDone && 'line-through'};
`;

function QuickStartStep({ i18n, step, isDone, onClickArticle }) {
  return (
    <Step>
      <Column>
        <Title isDone={isDone}>{step.title(i18n)}</Title>
        <LinkButton noBorder onClick={onClickArticle}>
          <a href={step.article} target="_blank" rel="noopener noreferrer">
            <ArticleLink size={16} />
          </a>
        </LinkButton>
      </Column>
      <Column>
        <LinkButton noBorder onClick={onClickArticle}>
          <a href={step.article} target="_blank" rel="noopener noreferrer">
            <Text isDone={isDone}>{isDone ? <Trans>Completed</Trans> : <Trans>Start</Trans>}</Text>
          </a>
        </LinkButton>
      </Column>
    </Step>
  );
}

export default withI18n()(withRouter(QuickStartStep));
