import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const TH = styled.th<{
  minWidth?: string;
  maxWidth?: string;
  padding?: string;
  isActive?: boolean;
}>`
  min-width: ${({ minWidth }) => minWidth || '120px'};
  width: ${({ maxWidth }) => maxWidth || 'unset'};
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}
  .sortIcons {
    opacity: 0;
  }
  .sortIconsActive {
    opacity: 1;
  }
  div {
    &:hover {
      .sortIcons {
        opacity: 1;
      }
    }
  }
`;

export const THLabel = styled.div<{
  minWidth?: string;
  maxWidth?: string;
  padding?: string;
}>`
  padding: ${({ padding }) => padding || '4px 10px'};
  border-radius: 6px;
  min-width: ${({ minWidth }) => minWidth || 'unset'};
  width: ${({ maxWidth }) => maxWidth || 'unset'};
  margin: 8px 14px;

  @keyframes fade {
    0% {
      background-color: ${COLORS.HOVER};
    }
    100% {
      background-color: unset;
    }
  }

  &:hover,
  &.hover {
    background-color: ${COLORS.INFO_LIGHT};
  }

  &.isFade {
    animation: fade 0.2s ease-in-out;
  }
`;

export const TableWrapper = styled.table`
  border-collapse: separate;
  border-spacing: 0 3px;
  background-color: ${COLORS.WHITE};
  width: 100%;
  padding: 0 10px;

  & tr {
    border-radius: 6px;
    overflow: hidden;

    & td:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    & td:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  & tbody {
    & tr {
      &:nth-child(odd) {
        background-color: ${COLORS.BG_LIST};
      }

      &:hover {
        background-color: ${COLORS.HOVER};
      }
    }
  }

  & th,
  td {
    position: relative;
    text-align: left;
    margin: 8px 14px;
  }

  & th {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;

    & ${THLabel} {
      display: inline-flex;
      align-items: center;
    }
  }

  & td {
    font-size: 14px;

    & .withPercentage {
      display: inline-flex;
      align-items: center;
      gap: 12px;
      width: 80px;
    }

    &.checkbox {
      width: 45px !important;
      min-width: 45px !important;
    }
  }
`;

export const SelectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TD = styled.td<{
  minWidth?: string;
  maxWidth?: string;
  padding?: string;
  isGrayColor?: boolean;
  isSelected?: boolean;
  isHovered?: boolean;
}>`
  min-width: ${({ minWidth }) => minWidth || '120px'};
  width: ${({ maxWidth }) => maxWidth || 'unset'};
  max-width: ${({ maxWidth }) => maxWidth || 'unset'};
  padding: ${({ padding }) => padding || '0 24px'};
  color: ${({ isGrayColor, isSelected, isHovered }) =>
    isGrayColor
      ? COLORS.SUBTEXT
      : isSelected
      ? COLORS.COMPANY
      : isHovered
      ? COLORS.TEXT_HOVER
      : COLORS.TEXT_MAIN};
  height: 64px;
  box-sizing: border-box;
`;

export const THead = styled.thead`
  & .rotate {
    rotate: 180deg;
  }
`;

export const TR = styled.tr<{
  isSelected?: boolean;
}>`
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${COLORS.HOVER};
    `}
`;

export const TRHeader = styled(TR)<{
  isHideHeader?: boolean;
}>`
  ${({ isHideHeader }) =>
    isHideHeader &&
    css`
      height: 0 !important;
    `};

  ${({ isHideHeader }) =>
    isHideHeader
      ? css`
          display: none;
          &:hover {
            background-color: transparent;
          }
        `
      : css`
          &:hover {
            background-color: ${COLORS.WHITE};
          }
        `};
`;

export const IconMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    color: ${COLORS.COMPANY};
  }
`;

export const IsVisible = styled.div<{ isVisible?: boolean }>`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;
