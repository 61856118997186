import { ProductName } from './companies';

export enum COMPANY_SIZE {
  S_1_10 = '1-10',
  S_11_50 = '11-50',
  S_51_100 = '51-100',
  S_101_250 = '101-250',
  S_251_500 = '251-500',
  S_500_PLUS = '500+',
}

export const PAID_PRODUCTS = [
  ProductName.CAREER,
  ProductName.PERFORMANCE,
  ProductName.ENGAGEMENT,
  ProductName.LEARNING_AND_ONBOARDING,
];
