import styled from 'styled-components';

import { Icon } from '~/components/Icon';

import { COLORS } from '~/styles';

export const SelectWrapper = styled.button`
  display: flex;
  align-items: center;
  border: 1px solid ${COLORS.BORDER_HARD};
  color: ${COLORS.DARK_GRAY};
  padding: 0 10px;
  border-radius: 6px;
  width: 136px;
  height: 32px;
  cursor: pointer;

  & .value {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const ChevronIcon = styled(Icon)`
  margin-left: auto;
  min-height: 12px;
  min-width: 12px;
`;

export const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  max-height: 38px;
  padding: 8px 14px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.HOVER};
  }
  border-bottom: 1px solid ${COLORS.WHITE};
`;
