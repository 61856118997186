import React, { useEffect, useMemo } from 'react';

import {
  CONFIRMATION_MODAL_TYPE,
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
  REVIEW_QUESTION_TYPES_V1,
  REVIEW_STATUS,
} from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _, { difference, isEmpty } from 'lodash';
import qs from 'qs';
import { type UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { SearchSelect } from '~/components/SearchSelect';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import ToolTip from '~/components/Tooltip';
import { UserAvatar } from '~/components/UserAvatar';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUsers } from '~/selectors/baseGetters';
import getUserFullName from '~/utils/getUserFullName';
import { isOldReview } from '~/utils/reviewUtils';

import {
  ParticipantSection,
  UserAvatarWrapper,
  Label,
  EmployeeHeader,
  AvatarContainer,
} from '../../design';
import { useEditEmployee } from '../../hooks/useEditEmployee';
import { errors as errorTypes } from '../../hooks/useResolver';
import { StepFooter } from '../StepFooter';

import type { IReviewIndividualForm, PopulatedCareerPlan } from '../../types';
import type { IMultiLangString, ISkill, IUser } from '@learned/types';

interface StepParticipantsProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewIndividualForm>;
}

const Wrapper = styled.div`
  padding-bottom: 2px;
  margin-bottom: -4px;
`;

const ParticipantsSection = ({ sectionState, formMethods }: StepParticipantsProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const {
    watch,
    setValue,
    formState: { errors },
  } = formMethods;
  const watchEmployees = watch('employees');
  const { formMethods: employeeFormMethods } = useEditEmployee({
    employee: watchEmployees[0],
  });

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const companyUsers: IUser[] = useSelector(getUsers);
  const userId = query.user;

  const user = Object.values(companyUsers).find((user) => user.id === userId);

  const skills = watch('skills');
  const skillCategories = watch('skillCategories');
  const careerPlans = watch('employees')[0]?.careerPlans || [];
  const coaches = watch('employees')[0]?.coaches || [];
  const isDraft = watch('status') === REVIEW_STATUS.DRAFT;

  useEffect(() => {
    const formData = employeeFormMethods.getValues();
    const selectedData = {
      ...watchEmployees[0],
      careerPlans: formData.careerPlans.selected,
      coaches: formData.coaches.selected?.map((item) => item.id),
      guests: formData.guests.selected?.map((item) => item.id),
    };

    setValue('employees', [selectedData], { shouldDirty: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(employeeFormMethods?.getValues())]);

  const onDeleteEmployee = async () => {
    if (isDraft) {
      setValue('employees', []);
    }
    const isConfirm = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Delete employee?`),
      description: i18n._(
        t`Are you sure you want to delete this employee from the review? The review, including provided input, will be lost. This cannot be undone.`,
      ),
    });
    if (isConfirm) {
      setValue('employees', []);
    }
  };

  const options = useMemo(() => {
    return {
      showCoaches: !!watch('evaluators')
        ?.map((item) => item.value)
        ?.includes(REVIEW_QUESTION_EVALUATORS.COACH),
      showJobs: !!watch('reviewQuestionTypes')?.some((type) =>
        [REVIEW_QUESTION_TYPES.SKILL_CATEGORY, REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1].includes(
          type,
        ),
      ),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify([watch('evaluators'), watch('reviewQuestionTypes')])]);

  const generateErrorMessage = () => {
    const categorizedSkills = {} as Record<
      string,
      { skillCategory: string; skillCategoryName: IMultiLangString; skills: Array<string> }
    >;

    Object.values(skillCategories).forEach((skillCategory) => {
      categorizedSkills[skillCategory.id] = {
        skillCategory: skillCategory.id,
        skillCategoryName: skillCategory.name,
        skills: [],
      };
    });

    skills?.forEach((skill: ISkill) => {
      const { id, skillCategory } = skill;
      categorizedSkills[skillCategory].skills.push(id);
    });
    const categorizedSkillsArray = Object.values(categorizedSkills);

    const jobs = careerPlans.map((plan: PopulatedCareerPlan) => plan.jobProfile);
    const errors: string[] = [];

    jobs.forEach((job) => {
      const jobSkillIds = job.skills.map((item) => item.skill);
      categorizedSkillsArray.forEach((category) => {
        if (!category.skills.some((skill) => jobSkillIds.includes(skill))) {
          const message = `${getMultiLangString(job.name)} ${i18n._(
            t`has no skills in category`,
          )} ${getMultiLangString(category.skillCategoryName)}`;
          errors.push(message);
        }
      });
    });
    return !isEmpty(errors) ? errors : undefined;
  };

  const getJobsErrorMessage = () => {
    const careerPlansError = errors?.employees?.[0]?.careerPlans;
    const noFocusAreaError =
      careerPlansError?.type === errorTypes.noFocusArea ? careerPlansError?.message : undefined;

    if (noFocusAreaError) {
      return noFocusAreaError
        .split('|')
        .map((skill) => i18n._(t`Skill ${skill} does not contain focus areas`));
    } else {
      const hasErrors = errors?.employees && errors.employees[0]?.careerPlans;
      const hasCareerPlans = !isEmpty(careerPlans);

      if (hasErrors && hasCareerPlans) {
        return generateErrorMessage();
      }
      if (hasErrors && !hasCareerPlans) {
        return i18n._(t`Select at least one job`);
      }
    }
  };

  const getCoachesErrorMessage = () => {
    const hasErrors = errors?.employees && errors.employees[0]?.coaches;
    const hasCoaches = !isEmpty(coaches);

    if (hasErrors && !hasCoaches) {
      return i18n._(t`Select at least one coach`);
    }
  };

  const onCoachesChange = async (selected: IUser[]) => {
    const removedItems = difference(employeeFormMethods.watch('coaches.selected'), selected);

    if (removedItems.length > 0 && !isDraft) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Delete coach?`),
        description: i18n._(
          t`Are you sure you want to delete this coach from the review? Provided input will be lost. This cannot be undone.`,
        ),
      });
      if (!isConfirmed) {
        return;
      }
    }

    employeeFormMethods.setValue('coaches.selected', selected);
  };

  return (
    <>
      <Wrapper>
        <ParticipantSection>
          <EmployeeHeader className="title">
            <Trans>Employee</Trans>
            {!isOldReview(watch('fetchedReview.version')) && (
              <Button
                variant={ButtonVariant.PRIMARY}
                label={i18n._(t`Add`)}
                disabled={!isEmpty(watchEmployees)}
              />
            )}
          </EmployeeHeader>
          <UserAvatarWrapper>
            <AvatarContainer>
              <UserAvatar userId={watchEmployees[0]?.id} />
            </AvatarContainer>
            {!isOldReview(watch('fetchedReview.version')) && (
              <Button
                variant={ButtonVariant.ICON_DELETE}
                icon={ICONS.DELETE_BIN}
                onClick={onDeleteEmployee}
                disabled={!isEmpty(user)}
              />
            )}
          </UserAvatarWrapper>
        </ParticipantSection>
        {options.showJobs && (
          <ParticipantSection>
            <div className="title">
              <Trans>Jobs</Trans>
            </div>
            <div className="inputWrapper">
              <Label>
                <Trans>Select job(s) to review</Trans>
              </Label>
              <ToolTip
                disabled={isDraft}
                tooltip={i18n._(
                  t`After a review has been published, the selected job(s) cannot be changed`,
                )}
              >
                <div>
                  <SearchSelect
                    selectedItems={employeeFormMethods.watch('careerPlans.selected')}
                    onChange={(selected) =>
                      employeeFormMethods.setValue('careerPlans.selected', selected)
                    }
                    onSearchChange={(search) =>
                      employeeFormMethods.setValue('careerPlans.search', search)
                    }
                    items={employeeFormMethods.watch('careerPlans.filtered')}
                    stringifyItem={(careerPlan) => getMultiLangString(careerPlan.jobProfile.name)}
                    placeholder={i18n._(t`Search job(s)`)}
                    error={getJobsErrorMessage()}
                    isExpandable
                    disabled={!isDraft}
                  />
                </div>
              </ToolTip>
            </div>
          </ParticipantSection>
        )}
        {options.showCoaches && (
          <ParticipantSection>
            <div className="title">
              <Trans>Coaches</Trans>
            </div>
            <div className="inputWrapper">
              <Label>
                <Trans>Select coach(es) who will provide</Trans>
              </Label>
              <SearchSelect
                selectedItems={employeeFormMethods.watch('coaches.selected')}
                onChange={onCoachesChange}
                onSearchChange={(search) => employeeFormMethods.setValue('coaches.search', search)}
                items={employeeFormMethods.watch('coaches.filtered')}
                stringifyItem={(user) => getUserFullName(user)}
                placeholder={i18n._(t`Search coach(es)`)}
                error={getCoachesErrorMessage()}
                isExpandable
                showAvatar
              />
            </div>
          </ParticipantSection>
        )}
        <ParticipantSection>
          <div className="title">
            <Trans>Guests</Trans>
          </div>
          <div className="inputWrapper">
            <Label>
              <Trans>Select guests invited to the review</Trans>
            </Label>
            <SearchSelect
              selectedItems={employeeFormMethods.watch('guests.selected')}
              onChange={(selected) => employeeFormMethods.setValue('guests.selected', selected)}
              onSearchChange={(search) => employeeFormMethods.setValue('guests.search', search)}
              items={employeeFormMethods.watch('guests.filtered')}
              stringifyItem={(user) => getUserFullName(user)}
              placeholder={i18n._(t`Search guests`)}
              minCharsToShowDropdown={2}
              isExpandable
              showAvatar
            />
          </div>
        </ParticipantSection>
      </Wrapper>

      <StepFooter
        onNext={() => sectionState.setCurrentSection(++sectionState.currentSection)}
        onPrev={() => sectionState.setCurrentSection(--sectionState.currentSection)}
      />
    </>
  );
};

export { ParticipantsSection };
