import React, { useEffect, useState } from 'react';

import { GOAL_STATUSES, GOAL_TYPES } from '@learned/constants';
import filter from 'lodash/filter';

import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import { SelectList } from './components/SelectList';

import useBoolState from '~/hooks/useBoolState';
import { getGoals } from '~/services/goals';

import type { IActivity, IGoal, IUserActivity } from '@learned/types';

interface ISelectGoalProps {
  type: GOAL_TYPES.PERSONAL | GOAL_TYPES.BUSINESS;
  selectedGoals: IGoal['id'][];
  className?: string;
  updateSelectedGoals: (items: any[]) => void;
  activityId: IActivity['id'];
}

const SelectGoal = ({
  activityId,
  type,
  selectedGoals,
  className,
  updateSelectedGoals,
}: ISelectGoalProps) => {
  const [goals, setGoals] = useState<IGoal[]>([]);
  const isLoading = useBoolState(true);

  useEffect(() => {
    const fetchGoals = async () => {
      // @ts-ignore
      const { data } = await getGoals({ types: [type], isMyGoalsOnly: true, join: ['children'] });

      const filteredGoals = filter(data, (goal) => {
        return (
          goal.status !== GOAL_STATUSES.COMPLETED &&
          goal.status !== GOAL_STATUSES.DRAFT &&
          !goal?.activities?.find((item: IUserActivity) => item.originalActivity === activityId)
        );
      });

      setGoals(filteredGoals);
      isLoading.off();
    };

    fetchGoals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // @ts-ignore
    <ShowSpinnerIfLoading loading={isLoading.value}>
      <SelectList
        isSearchable
        isMultiCheck
        items={goals}
        selectedItems={selectedGoals}
        className={className}
        onChange={updateSelectedGoals}
      />
    </ShowSpinnerIfLoading>
  );
};

export { SelectGoal };
