import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

const Divider = styled.div<{
  $color?: string;
  $verticalBorder?: string;
  margin?: string;
}>`
  width: ${({ $verticalBorder }) => ($verticalBorder ? 'calc(100% - 2px)' : '100%')};
  height: 1.5px;
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
  background: ${(props) => props.$color || COLORS.BG_PAGE};
  ${({ $verticalBorder }) =>
    $verticalBorder &&
    css`
      border-left: 1px solid ${$verticalBorder};
      border-right: 1px solid ${$verticalBorder};
    `}
`;

export default Divider;
