import styled from 'styled-components';

import { COLORS } from '~/styles';

export const CellWrapper = styled.div<{ clickable: boolean; $margin?: string; $padding?: string }>`
  display: flex;
  align-items: center;
  height: 60px;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  background: ${COLORS.BG_LIST};
  margin: ${({ $margin }) => $margin || 'auto'};
  padding: ${({ $padding }) => $padding || 'auto'};
`;

export const CellContent = styled.div`
  height: auto;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 230px;
`;

export const OneLineWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CellViewWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.BG_LIST};
`;
