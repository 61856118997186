import { useState } from 'react';

import { useSelector } from 'react-redux';

import { getNumberOfItemsPerPage } from '~/selectors/baseGetters';

const DEFAULT_PAGINATION = { skip: 0, index: 1 };

type Pagination = {
  pagination: { skip: number; limit: number; index: number };
  resetPagination: () => void;
  changePagination: (pagination: { skip: number; limit: number; index: number }) => void;
  changePageSize: ({ limit }: { limit: number }) => void;
};

const usePagination = (pageSize?: number): Pagination => {
  const itemsPerPage = useSelector(getNumberOfItemsPerPage);
  const limit = pageSize ?? itemsPerPage;
  const [pagination, setPagination] = useState({
    ...DEFAULT_PAGINATION,
    limit,
  });

  const resetPagination = () => {
    setPagination((oldState) => ({ ...oldState, ...DEFAULT_PAGINATION }));
  };

  const changePagination = (pagination: { skip?: number; limit?: number; index?: number }) => {
    setPagination((prev) => ({ ...prev, ...pagination }));
  };

  const changePageSize = ({ limit }: { limit: number }) => {
    setPagination({
      skip: 0,
      limit,
      index: 1,
    });
  };

  return {
    pagination,
    resetPagination,
    changePagination,
    changePageSize,
  };
};

export { usePagination };
export type { Pagination };
