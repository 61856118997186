import React, { useEffect, useRef } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import styled from 'styled-components';

import SearchListItem from '~/components/AutocompleteDropdown/SearchListItem';
import Search from '~/components/Search';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import { COLOR_SET, COLOR_PALETTE } from '~/styles';

const OptionList = styled(Scrollbars)``;

const DropDownBox = styled.div`
  padding: 10px 12px;
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
`;

const Wrap = styled.div`
  background: ${COLOR_PALETTE.WHITE};
  border-radius: 6px;
  overflow: hidden;
  border: solid 1px ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  outline: none;
`;

const NoResultTitle = styled.div`
  height: 100%;
  box-sizing: border-box;
  color: ${COLOR_SET.GRAY};
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const searchStyles = {
  padding: '0 8px',
  minHeight: '20px',
  height: '20px',
  boxSizing: 'border-box',
  width: '100%',
  fontSize: '12px',
  border: 'none',
  fontFamily: 'Open Sans, sans-serif',
  color: COLOR_PALETTE.DARK_GRAY,
};

const List = ({
  list,
  onSelect,
  search,
  onChangeSearch,
  loading,
  noItemsPlaceholder,
  searchPlaceholder,
  showNoItemsPlaceholder,
  disableSearch = false,
  showTooltip,
}) => {
  const { i18n } = useLingui();
  const input = useRef();
  useEffect(() => {
    if (input.current) {
      input.current.focus();
    }
    // eslint-disable-next-line
  }, [input.current]);
  const handleFocus = () => {};

  const handleChangeSearch = (e) => {
    onChangeSearch(e.target.value);
  };

  const noItemsMessage = showNoItemsPlaceholder ? (
    noItemsPlaceholder
  ) : (
    <NoResultTitle>
      <Trans>No results</Trans>
    </NoResultTitle>
  );

  return (
    <Wrap>
      {!disableSearch && (
        <DropDownBox>
          <Search
            name="searchValue"
            value={search}
            onChange={handleChangeSearch}
            onFocus={handleFocus}
            style={searchStyles}
            placeholder={searchPlaceholder || i18n._(t`Search...`)}
            ref={input}
            iconPrepend
            defaultColor={COLOR_PALETTE.GRAY_SEMI_SOFT}
          />
        </DropDownBox>
      )}
      <ShowSpinnerIfLoading loading={loading}>
        <OptionList autoHeightMax={199} autoHeight>
          {list.length > 0
            ? list.map((o, index) => (
                <SearchListItem
                  key={o.id ?? index}
                  option={o}
                  onChange={onSelect}
                  showTooltip={showTooltip}
                />
              ))
            : noItemsMessage}
        </OptionList>
      </ShowSpinnerIfLoading>
    </Wrap>
  );
};

export default React.memo(List);
