import React, { Dispatch } from 'react';

import { SURVEY_TYPE } from '@learned/constants';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { StepFooter } from '~/pages/SurveyUpdate/components/StepFooter';
import { SectionAnonymity } from '~/pages/SurveyUpdate/components/StepSettings/SectionAnonymity';
import { SectionDates } from '~/pages/SurveyUpdate/components/StepSettings/SectionDates';
import { SectionNotifications } from '~/pages/SurveyUpdate/components/StepSettings/SectionNotifications';
import { SectionPulse } from '~/pages/SurveyUpdate/components/StepSettings/SectionPulse';

import { useAutoSaveFunc } from '~/hooks/useAutoSave';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { COLORS } from '~/styles';

import type { IGeneralForm } from '../../types';
import type { UseFormReturn } from 'react-hook-form';

export interface IGeneralProps {
  formMethods: UseFormReturn<IGeneralForm>;
  setCurrentSection: Dispatch<number>;
  languageState: ILanguageStateReturn;
  autosave: useAutoSaveFunc;
}

const Form = styled.form`
  border-radius: 10px;
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 32px 40px;
`;

const Title = styled.h2`
  font-size: 26px;
  color: ${COLORS.BLACK};
  font-weight: normal;
  margin-top: 0;
`;

const StepSettings = ({ setCurrentSection, formMethods, autosave }: IGeneralProps) => {
  const { handleSubmit, watch } = formMethods;
  const type = watch('type');

  const onSubmit = () => setCurrentSection(3);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Title>
        <Trans>Settings</Trans>
      </Title>
      <SectionDates formMethods={formMethods} autosave={autosave} />
      {type === SURVEY_TYPE.PULSE && <SectionPulse formMethods={formMethods} autosave={autosave} />}
      <SectionNotifications formMethods={formMethods} autosave={autosave} />
      <SectionAnonymity formMethods={formMethods} autosave={autosave} />
      <StepFooter onPrev={() => setCurrentSection(1)} />
    </Form>
  );
};

export { StepSettings };
