import { createReducer } from 'redux-act';

import * as appThemeActions from './actions';

import { PRIMARY_COLOR } from '~/styles';

const INITIAL_STATE = {
  data: {
    color: PRIMARY_COLOR,
  },
  loading: false,
};

export const appTheme = createReducer(
  {
    [appThemeActions.finishSetTheme]: (state, payload) => {
      if (!payload) {
        return { ...INITIAL_STATE };
      }
      return {
        ...state,
        data: {
          url: payload.logoUrl,
          color: payload.color || PRIMARY_COLOR,
        },
        loading: false,
      };
    },
  },
  INITIAL_STATE,
);
