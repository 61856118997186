import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

/**
 * @param forUser - userId, to take self work exp, put current id of this user
 * @returns {Promise<AxiosResponse<Object>>}
 */
export function getUserWorkExps(forUser) {
  return cloudRequest(serverRoutes.workExps.getUserWorkExps, { user: forUser });
}

export function getWorkExp(id, populate = []) {
  return cloudRequest(serverRoutes.workExps.getWorkExp(id), { populate });
}

export function createWorkExp(data) {
  return cloudRequest(serverRoutes.workExps.createWorkExp, {}, { ...data, extra: 'ddd' });
}

export function updateWorkExp(id, data) {
  return cloudRequest(serverRoutes.workExps.updateWorkExp(id), {}, data);
}

export function deleteWorkExp(id) {
  return cloudRequest(serverRoutes.workExps.deleteWorkExp(id));
}
