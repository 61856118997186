import styled from 'styled-components';

import { InputWrapper } from '~/components/Input/design';

import { COLORS } from '~/styles';

export const MultiSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;

  ${InputWrapper} {
    & input {
      border-radius: 19px;
    }
  }
`;

export const SearchInput = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  .rightIcon {
    cursor: pointer;
  }
`;

export const OptionsContainer = styled.div`
  overflow: hidden;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 275px;
  position: absolute;
  top: 100%;
  z-index: 99999;

  border-radius: 6px;
  box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
  border: solid 1px ${COLORS.BORDERS};
  background-color: ${COLORS.BG_LIST};
`;

export const Option = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  width: 228px;
  color: ${COLORS.ICONS_PRIMARY};
  padding: 15px 18px;

  svg {
    visibility: hidden;
  }

  :hover {
    color: ${COLORS.COMPANY};
    background-color: ${COLORS.HOVER};

    svg {
      visibility: visible;
    }
  }
`;

export const NoOptions = styled(Option)`
  :hover {
    color: ${COLORS.ICONS_PRIMARY};
    background-color: inherit;
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 28px;
  min-height: 28px;
  padding-left: 7px;
  padding-right: 10px;
  gap: 5px;

  border-radius: 6px;
  background-color: ${COLORS.BG_ELEMENTS};
  color: ${COLORS.ICONS_PRIMARY};
  font-size: 14px;
  line-height: -1.86;
  letter-spacing: -0.16px;

  overflow: hidden;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    min-width: 12px;
    min-height: 12px;
    :hover {
      color: ${COLORS.ACCENT_ERROR};
    }
    cursor: pointer;
  }
`;

export const SingleSelectLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  position: absolute;
  padding-left: 9px;
  width: 100%;
  height: 100%;
`;

export const MultiSelectLabels = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 452px;
  gap: 10px;
`;

export const Error = styled.div`
  color: ${COLORS.ACCENT_ERROR};
  font-size: 14px;
  font-style: italic;
  padding-top: 6px;
`;
