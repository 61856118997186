import React, { useEffect, useState } from 'react';

import { INotification, IUser } from '@learned/types';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { cloneDeep, find, orderBy } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { ICONS } from '~/components/Icon';
import getNotificationPath from '~/components/Notifications/getNotificationPath';
import OverviewHeading from '~/components/OverviewHeading';
import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';
import { TableList } from '~/components/TableList';

import { Container, Label, NotificationWrapper, PlaceholderWrapper } from './design';

import NotificationIcon from '~/assets/ic-notifications-24-px.svg';

import { IColumnTable } from '~/@types/table';
import { NOTIFICATION_TYPES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { getUsers } from '~/selectors/baseGetters';
import { deleteNotification, getNotifications } from '~/services/notifications';
import { setNotifications } from '~/store/notifications/actions';
import { COLORS } from '~/styles';

const NotificationsPage = () => {
  const { i18n } = useLingui();
  const [notificationsList, setNotificationsList] = useState<INotification[]>([]);
  const history = useHistory();
  const $loading = useBoolState(true);
  const users = useSelector(getUsers);
  const dispatch = useDispatch();

  const COLUMNS: IColumnTable<INotification>[] = [
    {
      name: (i18n: I18n) => i18n._(t`Name`),
      accessor: 'name',
      padding: '0',
      minWidth: '200px',
      renderCell: (item: INotification) => <Label>{getNotificationText(item)}</Label>,
    },
    {
      name: (i18n: I18n) => i18n._(t`Date`),
      minWidth: '40px',
      maxWidth: '40px',
      padding: '0 0 0 30px',
      renderCell: (item: INotification) => {
        const daysSinceNotification = moment().diff(moment(item.meta.createdDate), 'days');
        return <span>{daysSinceNotification > 0 && daysSinceNotification + 'd'}</span>;
      },
    },
  ];

  const handleCancel = async (notification: INotification) => {
    setNotificationsList(notificationsList.filter((n) => n.id !== notification.id));
    await deleteNotification(notification.id);
    dispatch(
      setNotifications({
        fetchNotifications: true,
        notificationsCount: notificationsList.length - 1,
      }),
    );
  };

  const getUserFullName = (user: IUser) => {
    if (user) {
      return String(`${user.firstName || ''} ${user.lastName || ''}`).trim() || user.email;
    }

    return '';
  };

  const onNotificationClick = async (notification: INotification) => {
    const path = getNotificationPath(notification);
    if (path) {
      history.push(path);
      await handleCancel(notification);
    }
  };

  const populateNotificationOptions = (notification: INotification, users: IUser[]) => {
    const populatedNotification = cloneDeep(notification);

    if (notification.options.fromUser) {
      // @ts-ignore
      const user = users[notification.options.fromUser];
      if (user) {
        // If we have a user id, show the user's full name
        populatedNotification.options.fromUser = getUserFullName(user);
      } else {
        // Else, show the ID of the user that is in the fromUser field
        populatedNotification.options.fromUser = notification.options.fromUser;
      }
    } else if (notification.options.fromEmail) {
      // If we have an email address, use this for the user name
      populatedNotification.options.fromUser = notification.options.fromEmail;
    }
    return populatedNotification;
  };

  const getNotificationText = (notification: INotification) => {
    const notificationType = find(NOTIFICATION_TYPES, (n) => n.key === notification.type) || {
      text: () => {},
    };

    if (!notification.options) {
      return 'text_alt' in notificationType
        ? notificationType.text_alt(i18n)
        : i18n._(t`Unknown message`);
    }

    const populatedNotification = populateNotificationOptions(notification, users);
    return 'text' in notificationType ? notificationType.text(populatedNotification, i18n) : '';
  };

  useEffect(() => {
    const fetch = async () => {
      const notificationsList = await getNotifications();
      const notifications = Object.values(notificationsList);
      const notificationsSorted = orderBy(notifications, ['meta.createdDate'], ['desc']);
      dispatch(
        setNotifications({
          fetchNotifications: true,
          notificationsCount: notificationsList.length - 1,
        }),
      );
      setNotificationsList(notificationsSorted as INotification[]);
      $loading.off();
    };

    fetch();

    // eslint-disable-next-line
  }, []);

  const menuProps = {
    isMenuVisible: true,
    createMenuItems: (item: INotification) => {
      return [
        {
          label: i18n._(t`Delete`),
          action: async () => {
            await handleCancel(item);
          },
          icon: ICONS.CLOSE_2,
          isWarning: false,
        },
      ];
    },
  };

  return (
    <>
      <OverviewHeading title={i18n._(t`Notifications`)} smallWidth />
      <Container>
        <NotificationWrapper>
          {$loading.value || notificationsList.length > 0 ? (
            <TableList
              data={notificationsList || []}
              columns={COLUMNS}
              isLoading={$loading.value}
              menuProps={menuProps}
              onRowClick={onNotificationClick}
            />
          ) : (
            <PlaceholderWrapper>
              <Placeholder
                Icon={() => (
                  <SvgIcon
                    url={NotificationIcon}
                    width={'60px'}
                    height={'60px'}
                    isDefaultColor
                    defaultColor={COLORS.GRAY_MIDDLE}
                  />
                )}
                title={i18n._(t`All done`)}
                subTitle={i18n._(t`You have no notifications`)}
              />
            </PlaceholderWrapper>
          )}
        </NotificationWrapper>
      </Container>
    </>
  );
};

export { NotificationsPage };
