import React from 'react';

import RickTextView from '~/components/RickTextView';

import { SummaryFieldLabel, SummaryFieldValue, SummaryFieldWrapper } from '../design';

interface SummaryFieldProps {
  label: string;
  value: string;
  marginTop?: string;
  marginBottom?: string;
  isRichText?: boolean;
}

const SummaryField = ({ label, value, marginBottom, marginTop, isRichText }: SummaryFieldProps) => {
  return (
    <SummaryFieldWrapper marginTop={marginTop} marginBottom={marginBottom}>
      <SummaryFieldLabel>{label}</SummaryFieldLabel>
      <SummaryFieldValue>
        {isRichText ? <RickTextView html={value} isHtmlWithoutStyles={true} /> : value}
      </SummaryFieldValue>
    </SummaryFieldWrapper>
  );
};

export { SummaryField };
