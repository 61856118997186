import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from '~/components/Button';
import { Input } from '~/components/Input';
import LearnMore from '~/components/LearnMore';
import Modal from '~/components/Modal';

import { INSTRUCTIONS } from '~/constants/instructions';
import { createTeams, updateTeamName } from '~/store/teams/actions';
import getInstructionUrl from '~/utils/getInstructionUrl';

import './styles.scss';

const INITIAL_STATE = {
  loading: false,
  teamName: '',
};

class CreateNewTeamModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      teamName: get(props, 'currentTeam.name', ''),
      isUpdate: !isEmpty(props.currentTeam),
    };
  }

  static propTypes = {
    company: PropTypes.object,
    onModalClose: PropTypes.func,
    currentTeam: PropTypes.object,
    parentTeam: PropTypes.object,
    teams: PropTypes.array,
  };

  createNewTeam = async () => {
    const { dispatch, parentTeam } = this.props;
    const { teamName } = this.state;

    let parent;
    if (!isEmpty(parentTeam) && parentTeam.id) {
      parent = parentTeam.id;
    }

    this.setState({ loading: true });
    const newTeam = {
      name: teamName,
      parent,
    };
    const createdTeam = await dispatch(createTeams([newTeam]));
    this.setState({ loading: false });

    this.closeModal(createdTeam);
  };

  updateName = async () => {
    const { dispatch, currentTeam } = this.props;
    const { teamName } = this.state;

    this.setState({ loading: true });
    const updatedTeam = await dispatch(updateTeamName(currentTeam.id, teamName));
    this.setState({ loading: false });

    this.closeModal([updatedTeam]);
  };

  onChangeTeamName = (e) => {
    e.preventDefault();
    this.setState({
      teamName: e.target.value,
    });
  };

  closeModal = (team) => {
    const { onModalClose } = this.props;
    if (onModalClose) {
      onModalClose(team);
    }
  };

  render() {
    const { i18n, teams } = this.props;
    const { teamName, loading, isUpdate } = this.state;

    return (
      <Modal
        className="new-team-modal"
        title={isUpdate ? i18n._(t`Update team`) : i18n._(t`Create team`)}
        onClose={() => this.closeModal()}
        footerRight={
          <Button
            label={isUpdate ? i18n._(t`Update`) : i18n._(t`Create`)}
            disabled={teams.map((t) => t.name).includes(teamName) || !teamName}
            onClick={isUpdate ? this.updateName : this.createNewTeam}
            loading={loading}
            type="primary"
          />
        }
        width={500}
        minWidth={500}
      >
        <div>
          <div className="modal_subtitle">
            <LearnMore
              label={i18n._(t`Manually created teams are not synchronised or deleted via an API`)}
              LearnMoreLink={getInstructionUrl(INSTRUCTIONS.HOW_TEAMS_WORK_ADMIN)}
            />
          </div>
          <Input
            name="teamName"
            label={i18n._(t`Name`)}
            placeholder={i18n._(t`Enter name ...`)}
            onChange={this.onChangeTeamName}
            height="38px"
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(CreateNewTeamModal)));
