import React, { useState, useEffect } from 'react';

import { ACTIVITY_PROGRESS_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import values from 'lodash/values';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  AutocompleteFilterActivityCategories,
  AutocompleteFilterActivityTypes,
  AutocompleteFilterSkills,
} from '~/components/AutocompleteFilters';
import Button from '~/components/Button';
import CheckBox from '~/components/CheckBox';
import Modal from '~/components/Modal';
import { EditLibraryActivityModal } from '~/components/Modals/EditLibraryActivityModal';
import PaginationBar from '~/components/PaginationBar';
import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';
import { SearchField } from '~/components/Text';

import ActivityItem from './ActivityItem';

import ActivitiesIcon from '~/assets/collections_bookmark.svg';

import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { getSelectedRole, getUser, getCurrentCompanyId } from '~/selectors/baseGetters';
import { getCurrentGoal } from '~/selectors/currentGoal';
import { getLibraryItems, getUserLibraryActivities } from '~/services/activities';
import { createUserActivityFromLibrary } from '~/services/userActivities';
import * as appActions from '~/store/app/actions';
import * as currentGoalActions from '~/store/currentGoal/actions';
import { COLOR_PALETTE, COLORS, COLOR_SET } from '~/styles';

import { ActivityModal } from '../Modals/ActivityModal';

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const AutoFiltersCol = styled.div`
  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  flex: 1;
  .libary-activity-modal {
    flex-wrap: nowrap;
    margin: 0 17px 12px 25px;
  }
  border: solid 1px ${COLOR_SET.LIGHT_BLUE};
  border-radius: 10px;
  padding: 4px 0 20px;
  box-sizing: border-box;
`;

const FilterContainer = styled.div`
  margin-right: 10px;
`;

const FilterRow = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
`;

const Footer = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  height: 143px;
  padding: 31px 24px 64px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: auto;
  z-index: 10;
`;

const HeaderRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 9px;
  box-sizing: border-box;
  padding: 0 25px;
  height: 40px;
  background-color: ${(props) => (props.$isActive ? 'var(--company-color)' : COLORS.BG_PAGE)};
  align-items: center;
  color: ${(props) => (props.$isActive ? COLOR_PALETTE.WHITE : COLORS.TEXT_SECONDARY)};
`;

const List = styled.div``;

const RowText = styled.div`
  margin-left: 17px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  ${(props) => props.$isUpperCase && 'text-transform: uppercase;'}
`;

const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  margin-right: 6px;
  width: 100%;
`;

const headerStyles = {
  padding: '25px 24px 20px 41px',
  borderBottom: `solid 1px ${COLORS.BG_PAGE}`,
  fontSize: '22px',
  fontWeight: '600',
  lineHeight: '1.18',
  letterSpacing: '-0.24px',
  height: '78px',
};

const modalContentStyle = {
  flex: '1',
  padding: '19px 35px 0',
};

const MODAL_TYPE = {
  USER_PUBLIC: 'user-public',
  GOAL: 'goal',
  PATH: 'path',
};

const PAGE_SIZE = 10;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };
const DEBOUNCE_TIME = 300;

const initialFilters = {
  search: '',
  skills: [],
  activityCategories: [],
  activityTypes: [],
  pagination: DEFAULT_PAGINATION,
};

const LibraryActivitiesModal = ({ type, onModalClose, userId, activitiesToHide, onSubmit }) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const currentGoal = useSelector(getCurrentGoal);
  const user = useSelector(getUser);
  const currentRole = useSelector(getSelectedRole);
  const currentCompanyId = useSelector(getCurrentCompanyId);
  const [currentFilters, setCurrentFilters] = useState(initialFilters);
  const [selectedActivities, setSelectedActivities] = useState({});
  const [activities, setActivities] = useState({});
  const [newActivity, setNewActivity] = useState(null);
  const [currentActivity, setCurrentActivity] = useState(null);
  const [totalActivities, setTotalActivities] = useState(0);
  const $loading = useBoolState();
  const $showEditLibraryActivityModal = useBoolState();
  const isUserPublic = type === MODAL_TYPE.USER_PUBLIC;

  // debounce filters
  const debCurrentFilters = useDebounce(currentFilters, DEBOUNCE_TIME); // to send request not immediately, but with delay

  useEffect(() => {
    let isMounted = true;
    const fetchItems = async () => {
      $loading.on();
      const {
        data: { activities: fetchedActivities, total: fetchedTotalActivities },
      } = isUserPublic
        ? await getUserLibraryActivities(userId, {
            pagination: currentFilters.pagination,
            search: currentFilters.search,
            types: (currentFilters.activityTypes || []).map((t) => t.key),
            skills: (currentFilters.skills || []).map((t) => t.id),
            activityCategories: (currentFilters.activityCategories || []).map((t) => t.id),
          })
        : await getLibraryItems(
            {},
            {
              pagination: currentFilters.pagination,
              search: currentFilters.search,
              types: (currentFilters.activityTypes || []).map((t) => t.key),
              collections: ['activities'],
              skills: (currentFilters.skills || []).map((t) => t.id),
              activityCategories: (currentFilters.activityCategories || []).map((t) => t.id),
              ...(!isEmpty(activitiesToHide) && { activitiesToHide }),
            },
          );

      if (isMounted) {
        setActivities(fetchedActivities);
        setTotalActivities(fetchedTotalActivities);
        $loading.off();
      }
    };

    fetchItems();
    // eslint-disable-next-line
  }, [debCurrentFilters]);

  const addActivities = async () => {
    if (isEmpty(selectedActivities)) {
      return;
    }

    // USER PUBLIC
    if (type === MODAL_TYPE.USER_PUBLIC) {
      try {
        $loading.on();
        await Promise.all(
          map(
            selectedActivities,
            async (a) => await createUserActivityFromLibrary(a.id, 'personal', { forUser: userId }),
          ),
        );
        setSelectedActivities({});
        $loading.off();

        const href = window.location.href;
        if (href.includes('development')) {
          await dispatch(appActions.updateApp({ isAssignedActivities: true }));
        }
        onModalClose(true);
      } catch (error) {
        console.error(error);
        $loading.off();
      }
    }

    // GOAL
    if (type === MODAL_TYPE.GOAL) {
      try {
        $loading.on();
        dispatch(
          currentGoalActions.updateCurrentGoal({
            activities: (currentGoal.activities || []).concat(values(selectedActivities)),
          }),
        );
        setSelectedActivities({});
        $loading.off();
        onModalClose(true);
      } catch (error) {
        console.error(error);
        $loading.off();
      }
    }

    // onSubmit
    if (onSubmit) {
      try {
        $loading.on();
        onSubmit(values(selectedActivities));
        setSelectedActivities({});
        $loading.off();
        onModalClose(true);
      } catch (error) {
        console.error(error);
        $loading.off();
      }
    }
  };

  const onCheckActivity = (isSelected, activity) => {
    const newSelectedActivities = { ...selectedActivities };
    isSelected
      ? (newSelectedActivities[activity.id] = activity)
      : delete newSelectedActivities[activity.id];
    setSelectedActivities(newSelectedActivities);
  };

  const openActivity = (activity) => {
    setCurrentActivity(activity);
  };

  const handleCloseActivityModal = () => {
    setCurrentActivity(null);
  };

  const openEditLibraryActivityModal = () => {
    const newActivity = {
      isAvailableInUserLibrary: true,
      isRateAvailable: true,
      createdInRole: currentRole,
      createdBy: user.id,
      company: currentCompanyId,
      progressType: ACTIVITY_PROGRESS_TYPES.IS_DONE,
      progressDetails: {
        min: null,
        max: null,
        currency: null,
      },
    };
    setNewActivity(newActivity);
    $showEditLibraryActivityModal.on();
  };

  const onHideEditLibraryActivityModal = () => {
    $showEditLibraryActivityModal.off();
  };

  const onSaveActivity = (activity) => {
    if (activity && activity.id) {
      setActivities({ [activity.id]: activity, ...activities });
      setTotalActivities(totalActivities + 1);
    }
    $showEditLibraryActivityModal.off();
    setNewActivity(null);
  };

  const changeFilters = async (filters) => {
    // reset pagination on filters change (so we always start from 1 page)
    setCurrentFilters({ ...currentFilters, ...filters, pagination: DEFAULT_PAGINATION });
  };

  const onPageChangeClick = async ({ index, skip }) => {
    const newPagination = {
      ...currentFilters.pagination,
      skip,
      index,
    };

    setCurrentFilters({ ...currentFilters, pagination: newPagination });
  };

  const selectedActivitiesSize = values(selectedActivities).length;
  const selectedActivitiesIds = values(selectedActivities)
    .map((a) => a.id)
    .filter((a) => a);
  const isAllChecked = !Object.values(activities).find((activity) => {
    return !selectedActivitiesIds.includes(activity.id);
  });

  const onCheckAll = () => {
    const checkedActivities = isAllChecked ? [] : activities;
    setSelectedActivities(checkedActivities);
  };

  return (
    <Modal
      title={type === MODAL_TYPE.USER_PUBLIC ? i18n._(t`Assign activity`) : i18n._(t`Add activity`)}
      onClose={onModalClose}
      minWidth={1009}
      width={1009}
      minHeight="600px"
      headerStyles={headerStyles}
      contentStyles={modalContentStyle}
      showDivider={false}
      hideFooter
    >
      <Content>
        <ShowSpinnerIfLoading loading={$loading.value}>
          <FilterRow>
            <SearchFieldWrapper
              onChange={(e) => changeFilters({ search: e.currentTarget.value })}
              value={currentFilters.search}
              placeholder={i18n._(t`Search`)}
              style={{
                borderRadius: '6px',
                fontSize: '14px',
                fontWeight: 600,
              }}
            />
            <AutoFiltersCol>
              <FilterContainer>
                <AutocompleteFilterSkills
                  onChange={(e) => changeFilters({ skills: e })}
                  checkedList={currentFilters.skills}
                />
              </FilterContainer>
              <FilterContainer>
                <AutocompleteFilterActivityCategories
                  onChange={(e) => changeFilters({ activityCategories: e })}
                  checkedList={currentFilters.activityCategories}
                />
              </FilterContainer>
              <FilterContainer>
                <AutocompleteFilterActivityTypes
                  onChange={(e) => changeFilters({ activityTypes: e })}
                  checkedList={currentFilters.activityTypes}
                />
              </FilterContainer>
            </AutoFiltersCol>
          </FilterRow>
          {!isEmpty(activities) ? (
            <>
              <List>
                <HeaderRow $isActive={!isEmpty(selectedActivities)}>
                  <CheckBox
                    checked={isAllChecked}
                    onChange={onCheckAll}
                    size="24px"
                    isDefaultColor={!isEmpty(selectedActivities)}
                    defaultColor={COLOR_PALETTE.WHITE}
                  />
                  <RowText $isUpperCase={isEmpty(selectedActivities)}>
                    {!isEmpty(selectedActivities) ? (
                      i18n._(t`${Object.values(selectedActivities).length} selected`)
                    ) : (
                      <Trans>Activities</Trans>
                    )}
                  </RowText>
                </HeaderRow>
                {values(activities).map((activity) => (
                  <ActivityItem
                    key={activity.id}
                    activity={activity}
                    checked={!!selectedActivities[activity.id]}
                    onCheckActivity={onCheckActivity}
                    onClick={openActivity}
                  />
                ))}
              </List>
              <PaginationBar
                pagination={currentFilters.pagination}
                changePagination={onPageChangeClick}
                count={totalActivities}
                noShadow
                noBorder
                showCount
              />
            </>
          ) : (
            <Placeholder
              Icon={() => (
                <SvgIcon
                  url={ActivitiesIcon}
                  width="50px"
                  height="50px"
                  isDefaultColor
                  defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                />
              )}
              title={i18n._(t`No activities match your criteria`)}
              subTitle={i18n._(t`There are no learning activities available`)}
            />
          )}
          {!!currentActivity && (
            <ActivityModal
              activityId={currentActivity.id}
              onClose={handleCloseActivityModal}
              isReadOnly
              isHideProgress
            />
          )}

          {$showEditLibraryActivityModal.value && (
            <EditLibraryActivityModal
              currentActivity={newActivity}
              onHideModal={onHideEditLibraryActivityModal}
              onSaveActivity={onSaveActivity}
            />
          )}
        </ShowSpinnerIfLoading>
      </Content>
      <Footer>
        <ButtonWrap>
          {type !== MODAL_TYPE.GOAL ? (
            <Button
              label={i18n._(t`Create new activity`)}
              onClick={openEditLibraryActivityModal}
              type="primary-border"
              width={207}
              height={48}
            />
          ) : (
            <Button
              label={i18n._(t`Cancel`)}
              height={48}
              type="primary-border"
              onClick={onModalClose}
            />
          )}
          <Button
            label={
              type === MODAL_TYPE.USER_PUBLIC
                ? i18n._(t`Assign ${selectedActivitiesSize} activities`)
                : i18n._(t`Add ${selectedActivitiesSize} activities`)
            }
            disabled={selectedActivitiesSize === 0}
            type="primary"
            width={207}
            height={48}
            styles={{ padding: '0', marginLeft: '32px' }}
            onClick={addActivities}
          />
        </ButtonWrap>
      </Footer>
    </Modal>
  );
};

export default LibraryActivitiesModal;
