import React from 'react';
import type { ReactNode } from 'react';

import Tippy from '@tippyjs/react';
import styled from 'styled-components';

const Wrapper = styled.div<{ isCentered?: boolean; padding?: string; maxWidth?: string }>`
  background-color: inherit;
  padding: ${({ padding }) => padding || '19px 16px'};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: ${({ maxWidth }) => maxWidth || '200px'};
`;

const TippyName = styled.div`
  word-wrap: break-word;
  white-space: pre-wrap;
`;

interface INameRow {
  children: ReactNode;
  className?: string;
  isCentered?: boolean;
  padding?: string;
  maxWidth?: string;
  tooltip?: string;
}

const tippyOpts = { modifiers: [{ name: 'computeStyles', options: { gpuAcceleration: false } }] };

const NameRow = ({ children, className, padding, isCentered, maxWidth, tooltip }: INameRow) => {
  return (
    <Tippy
      trigger="mouseenter"
      theme="light"
      popperOptions={tippyOpts}
      content={<TippyName>{tooltip || children}</TippyName>}
    >
      <Wrapper className={className} padding={padding} isCentered={isCentered} maxWidth={maxWidth}>
        {children}
      </Wrapper>
    </Tippy>
  );
};

export { NameRow };
