import { TCellData, TDestination, TItemToMove, TTableData } from '../types';

import type { ISkillCategory, IJobProfile, ISkillPopulated } from '@learned/types';

export function parseJobProfiles(skill: ISkillPopulated, skillCategory: ISkillCategory) {
  // set an empty array for each level
  const acc: Record<number, IJobProfile[]> = {};
  skillCategory.skillLevels.forEach((_, index) => {
    acc[index] = [];
  });

  return (skill.jobProfiles ?? []).reduce((acc, jobProfile) => {
    jobProfile.skills
      .filter((skills) => skills.skill === skill.id)
      .map((skills) => skills.selectedFocusAreas)
      .flat()
      .forEach((focusArea) => {
        if (focusArea.type === 'SELECT_ALL') {
          // add the job to every level
          skill.focusAreas
            .filter((fa) => fa.level < skillCategory.skillLevels.length)
            .forEach((skillFocusArea) => {
              acc[skillFocusArea.level].push(jobProfile);
            });
        } else if (
          focusArea.type === 'SELECT_LEVEL' &&
          focusArea.level < skillCategory.skillLevels.length
        ) {
          // add the job to the specific level
          acc[focusArea.level!].push(jobProfile);
        }
      });

    return acc;
  }, acc);
}

export const removeFromSkills = (
  skillDataArray: TTableData[],
  draggableId: string,
): [TTableData[], TItemToMove, string | null] => {
  let itemToMove: TItemToMove = null;
  let movedFrom = null;
  const valueRemovedArr = skillDataArray?.map((row) => ({
    ...row,
    levels: row?.levels?.map((level) => ({
      ...level,
      columns: level?.columns?.map((column) => ({
        ...column,
        values: column?.values?.filter((cell) => {
          if (cell?.id === draggableId) {
            itemToMove = cell;
            movedFrom = row.id;
            return false;
          }
          return true;
        }),
      })),
    })),
  }));
  return [valueRemovedArr, itemToMove, movedFrom];
};

const moveRow = (val: TCellData[], itemToMove: TItemToMove, destination: TDestination) => {
  if (itemToMove === null) {
    return val;
  }
  const newArr = val.map((item) => {
    if (destination.droppableId.includes(item.id)) {
      const itemList = [...item.values];
      const newValues = [
        ...itemList.slice(0, destination.index),
        itemToMove,
        ...itemList.slice(destination.index),
      ];
      return {
        ...item,
        values: newValues,
      };
    }
    return item;
  });
  return newArr;
};

export const addToSkills = (
  jobDataArr: TTableData[],
  itemToMove: TItemToMove,
  destination: TDestination,
): TTableData[] => {
  const addToSkills = jobDataArr?.map((row) => ({
    ...row,
    levels: row?.levels?.map((level) => ({
      ...level,
      columns: moveRow(level.columns || [], itemToMove, destination),
    })),
  }));
  return addToSkills;
};

export const removeHTMLTags = (str: string) => str.replace(/<[^>]*>?/gm, '');
