import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export function getBackPath(from?: string, goalId?: string) {
  /* @ts-ignore */
  const defaultRoute = routes.GOALS.build({ role: ROLES.USER }, { hash: 'my_goals', noHash: true });
  if (!from) {
    return defaultRoute;
  } else if (goalId) {
    const regex = new RegExp(`/goals/${goalId}(?:/.*)?(?:\\?from=([^&]+))?`);

    const match = from.match(regex);
    if (match) {
      const fromValue = match[1] || defaultRoute;

      return fromValue;
    } else {
      // '/goals/${goalId}' not found in the string
      return from;
    }
  } else {
    return from;
  }
}
