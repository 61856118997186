import React from 'react';

import styled from 'styled-components';

import { FOOTER } from '~/styles';

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - ${FOOTER.height}px);
`;

const Roadmap = () => {
  return (
    <Wrapper>
      <iframe
        title="roadmap"
        src="https://portal.productboard.com/odyepeo1lppft1qzanceh1uz"
        frameBorder="0"
        width="100%"
        height="100%"
      />
    </Wrapper>
  );
};

export { Roadmap };
