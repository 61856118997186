import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import IconButton from '~/components/IconButton';
import ExpandMoreIcon from '~/components/Icons/ArrowButtonTall';
import RickTextView from '~/components/RickTextView';
import { TemplateQuestionCardWithFooter } from '~/components/SetupTools/components/TemplateBaseComponents';
import SkillCategoriesLabels from '~/components/SkillCategoriesLabels';
import Tabs from '~/components/Tabs';
import { Header4 } from '~/components/UI/Typographics/headers';

import { COLOR_PALETTE, COLORS } from '~/styles';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 40px; // to display toggle button
`;

const Row = styled.div`
  ${Header4} {
    margin: 0;
    line-height: 1.75;
    ${(props) => props.isInlineTitleStyle && 'width: 100%;'}
  }
  display: flex;
  align-items: center;
  width: 100%;
`;

const CardContent = styled.div`
  padding: ${(props) => (props.isNoPadding ? '0' : '20px 24px 24px')};
  .ql-editor.ql-blank::before {
    font-size: 16px;
  }
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.TEXT_SECONDARY};
  margin-top: -7px;
  padding: 0 24px;

  p {
    color: ${COLORS.TEXT_SECONDARY};
  }
`;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px 24px;
  ${(props) => props.isBorderBottom && `border-bottom: 1px solid ${COLORS.BORDER_LIGHT}`};
  cursor: pointer;
`;

const QuestionCounter = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  text-transform: uppercase;
  margin-bottom: 2px;
`;

const ToggleWrapper = styled.div`
  position: absolute;
  right: 24px;
  top: calc(50% - 12px);
`;

const QuestionCard = ({
  isActive,
  onClick,
  isError,
  children,
  title,
  skillCategories,
  labels,
  reviewSkillCategories,
  description,
  htmlDescription = false,
  tabs,
  handleChangeTab,
  currentTab,
  isNoChildrenPadding,
  indexCurrent,
  indexTotal,
  isOpenDefault,
  isOpenForce = null,
  isInlineTitleStyle,
  cardContentStyles,
  hideBorder = false,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenDefault);
  const { i18n } = useLingui();

  useEffect(() => {
    if (isOpenForce !== null) {
      if (isOpen !== isOpenForce) {
        return !!isOpenForce;
      }
    }

    // eslint-disable-next-line
  }, [isOpenForce]);

  return (
    <TemplateQuestionCardWithFooter
      $isActive={isActive}
      onClick={onClick}
      $isError={isError}
      $isOpen={isOpen}
    >
      <HeaderContainer
        onClick={() => setIsOpen((prevState) => !prevState)}
        isBorderBottom={isOpen && isEmpty(tabs) && isEmpty(description) && !hideBorder}
      >
        {indexTotal && (
          <QuestionCounter>{i18n._(t`Question ${indexCurrent + 1}/${indexTotal}`)}</QuestionCounter>
        )}
        <Header>
          <Row isInlineTitleStyle={isInlineTitleStyle}>
            <Header4>{title}</Header4>
            {((!isEmpty(skillCategories) && !isEmpty(reviewSkillCategories)) ||
              !isEmpty(labels)) && (
              <SkillCategoriesLabels
                skillCategories={skillCategories}
                allCategories={reviewSkillCategories}
                labels={labels}
              />
            )}
          </Row>
        </Header>
        <ToggleWrapper>
          <IconButton size={24} noBorder style={{ marginLeft: '24px' }}>
            {isOpen ? (
              <ExpandMoreIcon size={24} style={{ transform: 'rotate(180deg)' }} />
            ) : (
              <ExpandMoreIcon size={24} />
            )}
          </IconButton>
        </ToggleWrapper>
      </HeaderContainer>

      {/* CONTENT */}
      {isOpen && (
        <>
          {description && (
            <Description>
              {htmlDescription ? <RickTextView html={description} /> : description}
            </Description>
          )}
          {!isEmpty(tabs) && (
            <Tabs
              items={tabs}
              isSmall
              currentItem={currentTab}
              handleChangeTab={handleChangeTab}
              borderColor={COLORS.BORDER_LIGHT}
            />
          )}
          <CardContent
            $isActive={isActive}
            isNoPadding={isNoChildrenPadding}
            style={cardContentStyles}
          >
            {children}
          </CardContent>
        </>
      )}
    </TemplateQuestionCardWithFooter>
  );
};

QuestionCard.propTypes = {
  isOpenDefault: PropTypes.bool,
  indexCurrent: PropTypes.number,
  indexTotal: PropTypes.number,
  isInlineTitleStyle: PropTypes.bool,
  isHideDivider: PropTypes.bool,
};

QuestionCard.defaultProps = {
  isOpenDefault: false,
  isHideDivider: false,
};

export default React.memo(QuestionCard);
