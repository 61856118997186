import styled from 'styled-components';

import { Button } from '~/components/Buttons';

import { COLORS } from '~/styles';

export const CompleteButton = styled(Button)`
  margin-right: 16px;
`;

export const GoalsContainer = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: 10px;
  margin: 80px 216px 0 224px;
  min-width: 1000px;
  max-width: 1080px;
`;

export const InnerContainer = styled.div`
  padding: 32px 40px 0px 40px;
  box-sizing: border-box;
`;

export const HeaderContainer = styled.div``;

export const Title = styled.div`
  height: 36px;
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
`;

export const SubTitle = styled.div`
  height: 26px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
  margin-top: 5px;
`;

export const ListContainer = styled.div`
  > div:first-child {
    padding: 0;
  }

  td {
    padding-left: 0;
    padding-right: 0;
    background-color: ${COLORS.WHITE};

    > div:first-child {
      margin-left: 0;
    }
  }

  table {
    padding-left: 0;
    padding-right: 0;

    tbody {
      tr {
        td {
          > div:first-child > div:first-child > div:first-child {
            background-color: ${COLORS.WHITE};
            border: solid 1px ${COLORS.BORDERS};

            &:hover {
              background-color: ${COLORS.HOVER};
            }

            > div:first-child {
              div:nth-child(3) {
                max-width: 400px;
              }
            }
          }
        }
      }
      tr:nth-child(odd) {
        td {
          > div:first-child > div:first-child > div:first-child {
            background-color: ${COLORS.BG_LIST};
            border: solid 1px ${COLORS.BG_LIST};

            &:hover {
              background-color: ${COLORS.HOVER};
            }
          }
        }
      }
    }
  }

  thead {
    display: none;
  }
`;

export const WrongStatusLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  font-size: 20px;
`;

export const ListHeader = styled.div`
  th:nth-child(1) {
    padding: 0;
    div {
      padding: 0;
      margin-left: 0px;
      color: ${COLORS.SUBTEXT};
    }
  }
  th:nth-child(2) {
    padding: 0;
    div {
      padding: 0;
      margin-left: 25px;
      color: ${COLORS.SUBTEXT};
    }
  }
  th:nth-child(3) {
    padding: 0;
    div {
      padding: 0;
      margin-left: 27px;
      color: ${COLORS.SUBTEXT};
    }
  }
  th:nth-child(4) {
    padding: 0;
    div {
      padding: 0;
      margin-left: 32px;
      color: ${COLORS.SUBTEXT};
    }
  }
  th:nth-child(5) {
    padding: 0;
    div {
      padding: 0;
      margin-left: 27px;
      color: ${COLORS.SUBTEXT};
    }
  }
`;

export const ContentContainer = styled.div`
  margin-top: 15px;
  width: 1000px;
`;
