import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export const getGoalCycles = (filters) => {
  return cloudRequest(serverRoutes.goalCycles.getGoalCycles, { ...filters });
};

export const createGoalCycle = (data, { forceRole } = {}) => {
  return cloudRequest(serverRoutes.goalCycles.createGoalCycle, {}, data, {
    ...(forceRole && { forceRole }),
  });
};

export const updateGoalCycle = (id, data, { forceRole } = {}) => {
  return cloudRequest(serverRoutes.goalCycles.updateGoalCycle(id), {}, data, {
    ...(forceRole && { forceRole }),
  });
};

export const getGoalCycle = (id) => {
  return cloudRequest(serverRoutes.goalCycles.getGoalCycle(id), {});
};

export const deleteGoalCycle = (id, { forceRole } = {}) => {
  return cloudRequest(serverRoutes.goalCycles.deleteGoalCycle(id), null, null, {
    ...(forceRole && { forceRole }),
  });
};

export const getGoalCyclesByIds = (ids) => {
  return cloudRequest(serverRoutes.goalCycles.getGoalCyclesByIds, {}, { ids });
};
