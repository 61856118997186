import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import DashboardCard from '~/components/DashboardCard';
import FeedbackReport from '~/components/FeedbackReport';
import FeedbackRequestModal from '~/components/FeedbackRequestModal';
import FeedbackThanksModal from '~/components/FeedbackThanksModal';
import IconMenu from '~/components/IconMenu';
import RTFeedbackAnswerModal from '~/components/RTFeedbackAnswerModal';
import SkillsRateModal from '~/components/SkillsRateModal';
import BaseLayout from '~/layouts/BaseLayout';

import { RATING_TYPES, REQUEST_STATUSES, RT_FEEDBACK_SUBTYPES } from '~/constants';
import routes from '~/constants/routes';
import * as requestService from '~/services/requests';
import {
  getRTFeedback,
  deleteRTFeedback,
  deleteRTFeedbackByInterviewee,
} from '~/services/RTFeedbacks';

const IconMenuWrapper = styled.div`
  margin-bottom: -16px;
`;

class RequestRTFeedback extends Component {
  constructor() {
    super();
    this.state = {
      RTFeedback: null,
      isRTFeedbackAnswerModal: false,
      isRTFeedbackThankYouModal: false,
      showMultiRateModal: false,
    };
  }

  async componentDidMount() {
    const RTFeedback = await this.fetchRTFeedback(this.props);
    const isSkills = get(RTFeedback, 'subType') === RT_FEEDBACK_SUBTYPES.SKILLS.key;
    const isRequested = get(RTFeedback, 'request.status') === REQUEST_STATUSES.REQUESTED.key;
    if (RTFeedback && (isSkills ? isRequested : !RTFeedback.feedback)) {
      this.setState({ isRTFeedbackAnswerModal: true });
    }
  }

  fetchRTFeedback = async (props) => {
    const { match } = props;

    const { rtfeedbackId } = match.params;
    if (rtfeedbackId) {
      const RTFeedback = await getRTFeedback(rtfeedbackId, {
        isQuestions: true,
        join: ['ratings'],
      });
      if (!isEmpty(RTFeedback)) {
        this.setState({
          RTFeedback,
        });
      }
      return RTFeedback;
    }
    return;
  };

  showAnswerModal = () => {
    this.setState({ isRTFeedbackAnswerModal: true });
  };

  hideFeedbackModal = () => {
    this.setState({ isRTFeedbackAnswerModal: false });
  };

  hideAnswerModal = (isFirstSubmit) => {
    this.hideFeedbackModal();
    this.fetchRTFeedback(this.props);
    if (isFirstSubmit) {
      this.showThankYouModal();
    }
  };

  showThankYouModal = () => {
    this.setState({ isRTFeedbackThankYouModal: true, showMultiRateModal: false });
  };

  hideThankYouModal = () => {
    this.setState({ isRTFeedbackThankYouModal: false });
  };

  showMultiRateModal = () => {
    this.setState({ showMultiRateModal: true, isRTFeedbackAnswerModal: false });
  };

  hideMultiRateModal = () => {
    this.setState({ showMultiRateModal: false });
  };

  onRated = async (isShared = false) => {
    const { RTFeedback } = this.state;

    await requestService.acceptRequest(
      RTFeedback.request.id,
      isShared ? REQUEST_STATUSES.ACCEPTED.key : REQUEST_STATUSES.STARTED.key,
    );
    this.fetchRTFeedback(this.props);
    if (isShared) {
      this.showThankYouModal();
    } else {
      this.setState({
        showMultiRateModal: false,
      });
    }
  };

  declineRequest = async () => {
    const { i18n, history } = this.props;
    const { RTFeedback: userRTFeedback } = this.state;
    let backPath = routes.REVIEWS_INCOMING_REQUESTS.build();
    let href = window.location.href;
    let url = new URL(href);
    let from = url.searchParams.get('from');

    if (from) {
      backPath = from;
    }

    if (!isEmpty(userRTFeedback)) {
      const requestId =
        userRTFeedback.request && userRTFeedback.request.id
          ? userRTFeedback.request.id
          : userRTFeedback.request;
      await requestService.declineRequest(requestId);
      window.alert(i18n._(t`Real-time feedback rejected`));
      await this.fetchRTFeedback(this.props);
      history.push(backPath);
    }
  };

  removeRequest = async () => {
    const { history, match } = this.props;
    const { RTFeedback } = this.state;
    let backPath = routes.REVIEWS_INCOMING_REQUESTS.build();
    let href = window.location.href;
    let url = new URL(href);
    let from = url.searchParams.get('from');

    if (from) {
      backPath = from;
    }

    const { rtfeedbackId } = match.params;
    const requestId = get(RTFeedback, 'request.id');
    const requestStatus = get(RTFeedback, 'request.status');

    if (requestId && requestStatus) {
      await requestService.deleteRequest(requestId);
      const isSelfRTFeedback =
        RTFeedback.originalRTFeedback && RTFeedback.createdBy === RTFeedback.createdFor;
      isSelfRTFeedback
        ? await deleteRTFeedback(RTFeedback.originalRTFeedback) // totally delete self review
        : await deleteRTFeedbackByInterviewee(rtfeedbackId); // remove only request
      history.push(backPath);
    }
  };

  renderFeedbackReport() {
    const { RTFeedback } = this.state;

    return isEmpty(RTFeedback) ? null : (
      <FeedbackReport
        name={get(RTFeedback, 'name', '')}
        endDate={get(RTFeedback, 'request.meta.lastModifiedDate')}
        createdDate={get(RTFeedback, 'meta.createdDate', '')}
        userFor={RTFeedback.createdBy}
        content={RTFeedback.feedback}
        description={RTFeedback.description}
        type={RTFeedback.subType}
        item={RTFeedback}
        onItemClick={this.showAnswerModal}
        isProvideFeedback
        isSelfReview={RTFeedback.createdBy === RTFeedback.createdFor}
        isFrom
      />
    );
  }

  render() {
    const { i18n, users } = this.props;
    const { isRTFeedbackAnswerModal, RTFeedback, isRTFeedbackThankYouModal, showMultiRateModal } =
      this.state;

    let href = window.location.href;
    let url = new URL(href);
    const backPath = url.searchParams.get('from') || routes.REVIEWS_INCOMING_REQUESTS.build();

    const userRequestStatus = get(RTFeedback, 'request.status');
    const fromUser = RTFeedback && RTFeedback.createdBy ? users[RTFeedback.createdBy] : '';
    const feedback = get(RTFeedback, 'feedback');
    const ratings = get(RTFeedback, 'ratings');
    const isSelfRTFeedback =
      RTFeedback && RTFeedback.originalRTFeedback && RTFeedback.createdBy === RTFeedback.createdFor;

    const menuItems = [];
    if (
      (userRequestStatus === REQUEST_STATUSES.ACCEPTED.key ||
        userRequestStatus === REQUEST_STATUSES.REJECTED.key ||
        isSelfRTFeedback) &&
      !isEmpty(RTFeedback)
    ) {
      menuItems.push({
        order: 1,
        label: i18n._(t`Delete`),
        action: this.removeRequest,
      });
    }

    // Skip FeedbackRequestModal for user Self review
    const condition =
      (feedback || ratings) && RTFeedback.subType === RT_FEEDBACK_SUBTYPES.SKILLS.key;
    const isSelfReview = (feedback || ratings) && RTFeedback.createdBy === RTFeedback.createdFor;
    menuItems.push({
      order: 0,
      label: feedback || ratings ? i18n._(t`Update`) : i18n._(t`Provide`),
      action: condition ? this.showMultiRateModal : this.showAnswerModal,
    });

    if (userRequestStatus === REQUEST_STATUSES.REQUESTED.key) {
      menuItems.push({
        order: 2,
        label: i18n._(t`Decline`),
        action: this.declineRequest,
      });
    }

    return (
      <BaseLayout>
        <DashboardCard
          title={i18n._(t`Feedback report`)}
          backPath={backPath}
          renderActions={() =>
            RTFeedback ? (
              <IconMenuWrapper>
                <IconMenu items={menuItems} size={48} iconSize={24} />
              </IconMenuWrapper>
            ) : null
          }
        >
          {this.renderFeedbackReport()}
        </DashboardCard>

        {isRTFeedbackAnswerModal &&
          RTFeedback &&
          (RTFeedback.subType === RT_FEEDBACK_SUBTYPES.SKILLS.key ? (
            <FeedbackRequestModal
              user={fromUser}
              deadline={''}
              onClose={this.hideFeedbackModal}
              onStart={this.showMultiRateModal}
              onDecline={this.declineRequest}
              request={RTFeedback.request}
            />
          ) : (
            <RTFeedbackAnswerModal
              RTFeedback={RTFeedback}
              onClose={this.hideAnswerModal}
              onDecline={this.declineRequest}
            />
          ))}
        {isRTFeedbackThankYouModal && (
          <FeedbackThanksModal fromUser={fromUser} onClose={this.hideThankYouModal} />
        )}
        {showMultiRateModal && (
          <SkillsRateModal
            type={isSelfReview ? RATING_TYPES.SELF_RATING : RATING_TYPES.OTHER_RATING}
            onClose={this.hideMultiRateModal}
            onRated={this.onRated}
            forUser={fromUser}
            review={RTFeedback}
            isRealTime
          />
        )}
      </BaseLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    users: state.users.data,
    currentRole: state.selected.role,
    app: state.app,
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(RequestRTFeedback)));
