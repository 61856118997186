import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import BoxWithBorder from '~/components/UI/BoxWithBorder';

import TabHeader from './components/TabHeader';
import UserProfile from './components/UserProfile';

const TabHeaderWrapper = styled(TabHeader)`
  margin: 16px 24px;
`;

const UserProfileWrapper = styled(UserProfile)`
  box-shadow: none;
`;

const ProfileTab = ({
  i18n,
  user,
  readOnly,
  watchUserProfile,
  isPDF = false,
  cvSettings = {},
  finishSkillsLoading,
  exportCsvModal,
}) => {
  return (
    <BoxWithBorder
      $noBorder={!readOnly || watchUserProfile || isPDF}
      $noShadow={!readOnly || watchUserProfile || isPDF}
    >
      {readOnly && !isPDF && (
        <TabHeaderWrapper
          title={i18n._(t`Profile`)}
          action={exportCsvModal}
          actionLabel={i18n._(t`Export PDF`)}
        />
      )}
      <UserProfileWrapper
        user={user}
        readOnly={readOnly}
        isPDF={isPDF}
        cvSettings={cvSettings}
        finishSkillsLoading={finishSkillsLoading}
      />
    </BoxWithBorder>
  );
};

export default withI18n()(ProfileTab);
