import React, { ChangeEvent, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import SVG from 'react-inlinesvg';

import { IconNames, IconsList } from '~/components/IconOld';

import { ColorPicker } from './ColorPicker';

import { COLORS } from '~/styles';

import { IconsWrapper, IconColorPickerWrapper, SearchInputField } from '../design';

export interface IIconPickerProps {
  onClose: () => void;
  isColorPickerEnabled: boolean;
  onChangeIcon: (color: string, icon: string) => void;
}

const IconPicker = ({ isColorPickerEnabled, onChangeIcon, onClose }: IIconPickerProps) => {
  const { i18n } = useLingui();
  const [search, setSearch] = useState('');
  const [selectedIcon, setSelectedIcon] = useState<IconNames>();
  const [currentColor, setCurrentColor] = useState<string>(COLORS.WHITE);

  // @ts-ignore
  const icons: IconNames[] = Object.keys(IconsList).filter((key) =>
    search?.length ? key.match(new RegExp(search, 'i')) : true,
  );

  const onSelectIcon = (icon: IconNames) => {
    setSelectedIcon(icon);
    onChangeIcon(currentColor, icon);
  };

  const onChangeColor = (color: string) => {
    setCurrentColor(color);
    if (selectedIcon) {
      onChangeIcon(color, selectedIcon);
    }
  };

  return (
    <IconColorPickerWrapper>
      <div>
        {/* @ts-ignore */}
        <SearchInputField
          placeholder={i18n._(t`Search for icon`)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          value={search}
        />
        <IconsWrapper currentColor={currentColor}>
          <div className="grid">
            {icons.map((icon) => (
              <button
                type="button"
                className={`iconWrapper ${icon === selectedIcon ? 'selected' : ''}`}
                key={icon}
                onClick={() => onSelectIcon(icon)}
              >
                {/* current Icon component is not compatible. To be implement a new component with Pexels integration which supports these icons */}
                <SVG
                  className="icon"
                  src={IconsList[icon]}
                  color={COLORS.ICONS_PRIMARY}
                  width={32}
                  height={32}
                  uniqueHash="a1f8d1"
                />
              </button>
            ))}
          </div>
        </IconsWrapper>
      </div>
      <div>
        <ColorPicker
          onClose={onClose}
          onChangeColor={onChangeColor}
          toggleColorTray={isColorPickerEnabled}
          isVisibleInitially={false}
        />
      </div>
    </IconColorPickerWrapper>
  );
};

export { IconPicker };
