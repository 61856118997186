import styled from 'styled-components';

import { TextField } from '~/components/Text';

import { COLOR_PALETTE } from '~/styles';

const AddExternalPeople = styled.div`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.25px;
  color: var(--company-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: 24px;
`;

const EmailRow = styled.div`
  height: 64px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
`;

const EmailText = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  color: ${COLOR_PALETTE.BLACK};
  flex: 1;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid ${COLOR_PALETTE.GRAY_MIDDLE};
  border-radius: ${(props) => (props.$searching ? '6px 6px 0 0' : '6px')};
  margin-top: 12px;
`;

const NoResultPlaceholder = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.67;
  color: ${COLOR_PALETTE.DARK_GRAY};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
`;

const PositionRelative = styled.div`
  position: relative;
`;

const SearchContainer = styled.div`
  position: absolute;
  top: 0;
  width: calc(100% - 2px);
  border-radius: 0 0 6px 6px;
  height: fit-content;
  z-index: 999;
  border: 1px solid ${COLOR_PALETTE.GRAY_MIDDLE};
  background-color: ${COLOR_PALETTE.WHITE};
`;

const SearchRow = styled(EmailRow)`
  &:hover {
    background-color: ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  }
  cursor: pointer;
`;

const SectionContainer = styled.div`
  margin-bottom: 24px;
`;

const StyledInput = styled(TextField)`
  border: none !important;
  border-radius: 6px;
`;

const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${COLOR_PALETTE.BLACK};
`;

export {
  AddExternalPeople,
  EmailRow,
  EmailText,
  InputWrapper,
  NoResultPlaceholder,
  PositionRelative,
  SearchContainer,
  SectionContainer,
  SearchRow,
  Subtitle,
  StyledInput,
  Title,
};
