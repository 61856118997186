import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { ButtonWhite } from '~/components/Button';
import { ImportQuestionButton } from '~/pages/SurveyTemplateUpdate/design';

import emptyState from '~/assets/no-result.webp';

const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 57px auto 0;

  & img {
    margin-right: 20px;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: #909cc2;
  margin-bottom: 34px;
`;

const StyledImportQuestionsButton = styled(ImportQuestionButton)`
  margin: 68px auto 0;
`;

const CreateNewButton = styled(ButtonWhite)`
  height: 32px;
  border-radius: 100px;
  font-size: 12px;
  margin: 68px auto 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const QuestionsEmpty = ({
  createNewQuestion,
  importQuestions,
}: {
  createNewQuestion: (index?: number) => void;
  importQuestions: (index?: number) => void;
}) => {
  const { i18n } = useLingui();

  return (
    <Placeholder>
      <Description>
        <span>
          <Trans>No questions yet…</Trans>
        </span>
        <span>
          <Trans>Start importing or creating questions!</Trans>
        </span>
      </Description>
      <img src={emptyState} alt={i18n._(t`No data found`)} width="216px" />
      <ButtonContainer>
        <CreateNewButton type="button" onClick={() => createNewQuestion()}>
          <Trans>Create new</Trans>
        </CreateNewButton>
        <StyledImportQuestionsButton type="button" onClick={() => importQuestions()}>
          <Trans>Import question(s)</Trans>
        </StyledImportQuestionsButton>
      </ButtonContainer>
    </Placeholder>
  );
};

export { QuestionsEmpty };
