import React from 'react';

import { Trans } from '@lingui/macro';

import { Button, ButtonVariant } from '~/components/Buttons';

import { Container, Subtitle, Text, Actions, Card } from './InputNoLongerPossible.design';

import Image from '~/assets/3.0/placeholders/traffic-cone.png';

import { DASHBOARD } from '~/constants/routes/dashboards';

const InputNoLongerPossible = ({ isActionButton = true }: { isActionButton?: boolean }) => {
  return (
    <Container>
      <Card>
        <Text>
          <Trans>Input is no longer possible</Trans>
        </Text>
        <Subtitle>
          <Trans>
            This review request is canceled, rejected, or expired. If you need further assistance,
            please contact the sender.
          </Trans>
        </Subtitle>
        <img src={Image} alt="traffic-cone" height="191px" width="173.3px" />
        {isActionButton && (
          <Actions>
            <Button
              onClick={() => DASHBOARD.go()}
              variant={ButtonVariant.PRIMARY}
              label={<Trans>Go to home</Trans>}
            />
          </Actions>
        )}
      </Card>
    </Container>
  );
};

export { InputNoLongerPossible };
