import React, { useEffect, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import nanoId from 'nanoid';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import NameCard from '~/components/SetupTools/NameCard';
import ToolBox from '~/components/SetupTools/ToolBox';
import SetupLayout from '~/layouts/SetupLayout';

import TalkingPointCard from './components/TalkingPointCard';

import useBoolState from '~/hooks/useBoolState';
import useListState from '~/hooks/useListState';
import {
  createTemplate,
  updateTemplate,
  getTemplate,
  deleteTemplate,
} from '~/services/conversationTemplates';
import reorderArray from '~/utils/reorderArray';

const SectionCol = styled.div`
  margin-bottom: 24px;
`;

const useTemplateState = (templateId) => {
  const history = useHistory();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [activePoint, setActivePoint] = useState();
  const $talkingPoints = useListState([]);
  const $loading = useBoolState(true);

  useEffect(() => {
    async function fetch() {
      if (templateId) {
        const template = await getTemplate(templateId);
        setName(template.name);
        setDescription(template.description);
        $talkingPoints.set((template.talkingPoints || []).map((p) => ({ id: nanoId(), name: p })));
      }
      $loading.off();
    }

    fetch();
    // eslint-disable-next-line
  }, [templateId]);
  function prepareTemplate() {
    return {
      name,
      description,
      talkingPoints: $talkingPoints.items.map((p) => p.name.trim()).filter((name) => name),
    };
  }

  return {
    name,
    setName,
    $loading,
    description,
    setDescription,
    talkingPoints: $talkingPoints.items,
    createNewTalkingPoint() {
      $talkingPoints.push({ id: nanoId(), name: '' });
    },
    changeActivePoint: (point) => setActivePoint(point.id),
    updateTalkingPointName(point, name) {
      $talkingPoints.update(point, { id: point.id, name });
    },
    deleteTalkingPoint: $talkingPoints.remove,
    isActivePoint(id) {
      return activePoint === id;
    },
    setActivePoint,
    switchPoints(sIndex, dIndex) {
      $talkingPoints.set(reorderArray($talkingPoints.items, sIndex, dIndex));
    },
    async createTemplate() {
      $loading.on();
      await createTemplate(prepareTemplate());
      $loading.off();
      history.goBack();
    },
    async updateTemplate() {
      $loading.on();
      await updateTemplate(templateId, prepareTemplate());
      $loading.off();
      history.goBack();
    },
    async deleteTemplate() {
      $loading.on();
      await deleteTemplate(templateId);
      $loading.off();
      history.goBack();
    },
  };
};

const ConversationTemplateSetup = ({
  i18n,
  match: {
    params: { templateId },
  },
}) => {
  const history = useHistory();
  const $template = useTemplateState(templateId);
  const handleDrag = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.source.index !== result.destination.index) {
      $template.switchPoints(result.source.index, result.destination.index);
    }
  };
  const onSubmit = async () => {
    !templateId ? await $template.createTemplate() : await $template.updateTemplate();
    history.goBack();
  };
  const onDelete = async () => {
    if (
      await confirm(
        i18n,
        i18n._(
          t`Are you sure you want to delete this template? This will be permanent and can’t be undone.`,
        ),
      )
    ) {
      await $template.deleteTemplate();
    }
  };
  const toolBoxItems = [
    {
      label: i18n._(t`Talking point`),
      text: i18n._(t`Formulate a custom talking point`),
      action: () => $template.createNewTalkingPoint(),
    },
  ];

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      <SetupLayout
        title={<Trans>Templates</Trans>}
        onClose={goBack}
        width="1070px"
        actions={
          <>
            {templateId && (
              <Button
                label={<Trans>Delete</Trans>}
                type="primary-border"
                width={96}
                onClick={onDelete}
                loading={$template.$loading.value}
              />
            )}
            <Button
              label={<Trans>Close</Trans>}
              type="primary-border"
              width={83}
              onClick={goBack}
              loading={$template.$loading.value}
            />
            <Button
              label={i18n._(templateId ? t`Update` : t`Save`)}
              type="primary"
              width={88}
              onClick={onSubmit}
              loading={$template.$loading.value}
              disabled={
                !$template.name ||
                isEmpty(($template.talkingPoints || []).filter((p) => p.name && p.name.trim()))
              }
            />
          </>
        }
      >
        <ToolBox items={toolBoxItems}>
          <NameCard
            title={<Trans>Create 1-1 template</Trans>}
            name={$template.name}
            updateName={$template.setName}
            description={$template.description}
            updateDescription={$template.setDescription}
            emailBody={false}
          />
          <DragDropContext onDragEnd={handleDrag}>
            <Droppable droppableId="sections">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {$template.talkingPoints.map((point, idx) => (
                    <Draggable key={point.id} draggableId={point.id} index={idx}>
                      {(provided) => (
                        <SectionCol ref={provided.innerRef} {...provided.draggableProps}>
                          <TalkingPointCard
                            key={point.id}
                            talkingPoint={point}
                            dragProps={provided.dragHandleProps}
                            updateName={$template.updateTalkingPointName}
                            handleDelete={$template.deleteTalkingPoint}
                            isActive={$template.isActivePoint(point.id)}
                            onType={$template.changeActivePoint}
                          />
                        </SectionCol>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ToolBox>
      </SetupLayout>
    </>
  );
};

export default React.memo(withI18n()(ConversationTemplateSetup));
