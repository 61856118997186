import { ACTIVITY_PROGRESS_TYPES, GOAL_PROGRESS_TYPES } from '@learned/constants';

const getGoalProgressWithDecimalsForInputs = (
  progress: string,
  progressType: GOAL_PROGRESS_TYPES | ACTIVITY_PROGRESS_TYPES,
) => {
  if (
    progressType === GOAL_PROGRESS_TYPES.CURRENCY ||
    progressType === ACTIVITY_PROGRESS_TYPES.CURRENCY
  ) {
    return parseFloat(progress).toFixed(2);
  }

  return parseFloat(progress).toFixed(0);
};

const getGoalProgressWithDecimals = (
  progress: string,
  progressType: GOAL_PROGRESS_TYPES | ACTIVITY_PROGRESS_TYPES,
) => {
  if (
    [
      GOAL_PROGRESS_TYPES.CURRENCY,
      GOAL_PROGRESS_TYPES.AVG,
      GOAL_PROGRESS_TYPES.AVG_OF_CONTRIBUTING_GOALS,
    ].includes(progressType as GOAL_PROGRESS_TYPES)
  ) {
    const decimalPart = progress.split('.')[1];
    if (!decimalPart) {
      return parseFloat(progress).toFixed(0);
    }

    const result = parseFloat(progress).toFixed(2);

    if (result.toString().includes('.00')) {
      return parseFloat(result).toFixed(0);
    }

    return result;
  }

  return parseFloat(progress).toFixed(0);
};

export { getGoalProgressWithDecimalsForInputs, getGoalProgressWithDecimals };
