// TypeSection

import React from 'react';

import { GOAL_TYPES, GOAL_STATUSES } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown } from '~/components/Dropdown';
import ToolTip, { TOOLTIP_PLACEMENTS, TOOLTIP_SIZES } from '~/components/Tooltip';

import { getType, getStatus } from '~/selectors/currentGoal';
import { setCurrentGoalType } from '~/store/currentGoal/actions';

import { ItemContainer, ItemLabel } from '../design';

import type { I18n } from '@lingui/core';

const GOAL_TYPE_OPTIONS = [
  {
    value: GOAL_TYPES.PERSONAL,
    name: (i18n: I18n) => i18n._(t`Learning goal`),
  },
  {
    value: GOAL_TYPES.BUSINESS,
    name: (i18n: I18n) => i18n._(t`Business goal`),
  },
];

const TypeSection = ({ subTypes }: { subTypes: (GOAL_TYPES.BUSINESS | GOAL_TYPES.PERSONAL)[] }) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const type = useSelector(getType);
  const status = useSelector(getStatus);

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { isHideNotSelectedTypes } = query;

  const onChangeType = (newType: GOAL_TYPES) => {
    // @ts-ignore
    dispatch(setCurrentGoalType(newType));
  };

  return (
    <ToolTip
      maxWidth="600px"
      size={TOOLTIP_SIZES.MEDIUM}
      placement={TOOLTIP_PLACEMENTS.BOTTOM}
      disabled={!isHideNotSelectedTypes && (status === GOAL_STATUSES.DRAFT || !status)}
      tooltip={<Trans>This field cannot be edited after the goal is published</Trans>}
    >
      <div>
        <ItemContainer>
          <ItemLabel>
            <Trans>Subtype</Trans>
          </ItemLabel>
          {/* @ts-ignore */}
          <Dropdown
            // @ts-ignore
            items={GOAL_TYPE_OPTIONS.filter((item) => subTypes.includes(item.value))}
            selectedItem={GOAL_TYPE_OPTIONS.find((item) => item.value === type)}
            // @ts-ignore
            onChange={(selectedItem) => onChangeType(selectedItem.value)}
            stringifyItem={(item: any) => item.name(i18n)}
            isSingleSelect
            isClickable={
              !isHideNotSelectedTypes &&
              (status === GOAL_STATUSES.DRAFT || !status) &&
              subTypes?.length > 1
            }
            className="goal-setup-dropdown"
            selectHeight="38px"
            skipSort
          />
        </ItemContainer>
      </div>
    </ToolTip>
  );
};

export { TypeSection };
