import { ITeam, IUser } from '@learned/types';

export const isUserInAnyTeam = (owners: string[], teams: Record<string, ITeam>, user: IUser) => {
  let isInAnyTeam = false;
  Object.values(teams).forEach((team) => {
    team.members.forEach((member) => {
      if (owners.includes(member) && team.coaches.includes(user.id)) {
        isInAnyTeam = true;
      }
    });
  });
  return isInAnyTeam;
};
