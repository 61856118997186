import React from 'react';

import { Trans } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { Body14 } from '~/components/UI/Typographics/text';

import getFocusAreaName from '~/utils/getFocusAreaName';

const ExpectedLevelBlock = ({ skill, lang, expectedLevel, expectedLevelLabel }) => {
  const focusAreas = skill.focusAreas.filter((fa) => Number(fa.level) === expectedLevel);

  return (
    !isEmpty(focusAreas) && (
      <Body14>
        {expectedLevelLabel ? (
          <span>
            <Trans>Expected for</Trans> {expectedLevelLabel}
          </span>
        ) : (
          <span>
            <Trans>Expected for level</Trans>
          </span>
        )}
        {focusAreas.map((f) => (
          <li key={f.id}>{getFocusAreaName(f, lang, true)}</li>
        ))}
      </Body14>
    )
  );
};

ExpectedLevelBlock.propTypes = {
  skill: PropTypes.object.isRequired,
  lang: PropTypes.string,
  expectedLevel: PropTypes.number,
};

export default ExpectedLevelBlock;
