import { useEffect } from 'react';

import {
  useFieldArray,
  UseFieldArrayProps,
  FieldValues,
  UseFormReturn,
  FieldPath,
  FieldArrayWithId,
  UseFieldArrayReturn,
  FieldArrayPath,
} from 'react-hook-form';

import { ILanguage } from '~/constants/languages';

type UseFieldArrayReturnWithIndex<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
> = Omit<UseFieldArrayReturn<TFieldValues, TFieldArrayName, TKeyName>, 'fields'> & {
  fields: (FieldArrayWithId<TFieldValues, TFieldArrayName, TKeyName> & { index: number })[];
};

function useMultiLangFieldArray<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
>({
  languageState,
  unregister,
  ...fieldArrayProps
}: UseFieldArrayProps<TFieldValues, TFieldArrayName, TKeyName> & {
  languageState: { languages: ILanguage[] };
  unregister: UseFormReturn<TFieldValues>['unregister'];
}): UseFieldArrayReturnWithIndex<TFieldValues, TFieldArrayName, TKeyName> {
  const companyLocales = languageState.languages.map((value) => value.locale);
  const fieldArray = useFieldArray({ ...fieldArrayProps });

  useEffect(() => {
    const fieldAndIndex = fieldArray.fields.map((field, index) => ({ field, index }));

    // @ts-ignore
    const fields = fieldAndIndex.filter(({ field }) => !companyLocales.includes(field.locale));

    fields.forEach(({ index }) => {
      unregister(`${fieldArrayProps.name}.${index}.value` as FieldPath<TFieldValues>, {
        keepValue: true,
      });
    });
    // eslint-disable-next-line
  }, [languageState.languages]);

  const filteredFields = fieldArray.fields
    ?.map((field, index) => ({ ...field, index }))
    // @ts-ignore
    .filter((field) => companyLocales.includes(field.locale));

  return { ...fieldArray, fields: filteredFields };
}

export { useMultiLangFieldArray };
