import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { InlineMultipleInput } from '~/components/InlineMultipleInput';

import { Container, NoteTitle, NotePoints, NotesContainer } from './design';

import isValidEmail from '~/utils/isValidEmail';

type InviteExternalPeersProps = {
  selectedItems?: string[];
  excludedEmails?: string[];
  onSelectedEmails?(emails: string[]): void;
};

const InviteExternalPeers = ({
  selectedItems = [],
  onSelectedEmails,
  excludedEmails,
}: InviteExternalPeersProps) => {
  const { i18n } = useLingui();

  return (
    <Container>
      <InlineMultipleInput
        placeholder={i18n._('Enter email address and press enter')}
        validator={isValidEmail}
        noSpace
        onSelectedItems={onSelectedEmails}
        excludeItems={excludedEmails}
        selectedItems={selectedItems}
      />
      <NotesContainer>
        <NoteTitle>{i18n._(t`Please note`)}</NoteTitle>
        <NotePoints>
          {i18n._(t`• External peers do not require login credentials for the Learned platform`)}
        </NotePoints>
        <NotePoints>
          {i18n._(
            t`• External peers will receive an invitation to provide input on the e-mail address above`,
          )}
        </NotePoints>
      </NotesContainer>
    </Container>
  );
};
export { InviteExternalPeers };
