import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import InfoMessage from '~/components/InfoMessage';
import Modal from '~/components/Modal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import TabsForIntegrations from '~/components/TabsForIntegrations';

import IntegrationContent from './IntegrationContent';

const Wrapper = styled.div``;

const inviteModalHeaderStyles = {
  padding: '0 24px',
};

const inviteModalContentStyles = {
  padding: '0',
};

function SyncModal({
  cols,
  getIntegrationRowData,
  i18n,
  title,
  loading,
  buttonLoading,
  integrations,
  onClose,
  onSubmit,
  placeholder,
  infoMessage,
  infoUrl,
  confirmMessage,
  isSubmitDisabled,
  pathToItems,
  searchByField,
  integrationPlaceholder,
  integrationExplanation,
}) {
  return (
    <Modal
      title={title}
      onClose={onClose}
      minWidth={1100}
      width={1100}
      headerStyles={inviteModalHeaderStyles}
      contentStyles={inviteModalContentStyles}
      footerRight={
        <Button
          label={i18n._(t`Synchronise`)}
          onClick={async () => {
            if (await confirm(i18n, confirmMessage)) {
              onSubmit();
            }
          }}
          disabled={isSubmitDisabled || buttonLoading}
          loading={loading || buttonLoading}
          type="primary"
        />
      }
    >
      <ShowSpinnerIfLoading loading={loading}>
        {isEmpty(integrations) ? (
          placeholder
        ) : (
          <Wrapper>
            <InfoMessage infoMessage={infoMessage} infoUrl={infoUrl} />
            <TabsForIntegrations integrations={integrations} pathToItems={pathToItems}>
              {/* selected integration will be added in TabsForIntegrations component*/}
              <IntegrationContent
                cols={cols}
                getIntegrationRowData={getIntegrationRowData}
                searchByField={searchByField}
                pathToItems={pathToItems}
                integrationPlaceholder={integrationPlaceholder}
                integrationExplanation={integrationExplanation}
              />
            </TabsForIntegrations>
          </Wrapper>
        )}
      </ShowSpinnerIfLoading>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    jobProfiles: state.users.jobProfiles,
  };
};

SyncModal.propTypes = {
  title: PropTypes.string.isRequired,
  integrations: PropTypes.array.isRequired, // each integration should have items param to display items
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.node.isRequired,
  pathToItems: PropTypes.string.isRequired,
  integrationPlaceholder: PropTypes.node.isRequired,
  integrationExplanation: PropTypes.string.isRequired,
  infoMessage: PropTypes.string,
  infoUrl: PropTypes.string,
  confirmMessage: PropTypes.string,
  isSubmitDisabled: PropTypes.bool,
};

export default withI18n()(connect(mapStateToProps)(SyncModal));
