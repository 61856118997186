import React, { useState } from 'react';

import { useSingleSelectState } from '~/components/Dropdown/hooks';

import { BaseSelectSearch } from './BaseSelectSearch';
import { Label, SingleSelectLabel } from './design';

import { Icon, ICON_SIZES, ICONS } from '../Icon';

import type { SingleSearchSelectProps } from './types';
import type { WithRequired } from '@learned/types';

function SingleSearchSelect<T>({
  onChange,
  stringifyItem,
  selectedItem,
  placeholder,
  hashItem,
  error,
  ...props
}: WithRequired<SingleSearchSelectProps<T>, 'stringifyItem'>) {
  const [search, setSearch] = useState('');
  const { hasSelectedItem, handleNewItemSelected, reset } = useSingleSelectState({
    selectedItem,
    onChange,
    hashItem,
  });

  return (
    <BaseSelectSearch
      stringifyItem={stringifyItem}
      hasSelectedItem={hasSelectedItem}
      handleNewItemSelected={handleNewItemSelected}
      showRightIcon={!hasSelectedItem}
      placeholder={!hasSelectedItem ? placeholder : ''}
      isHideOptionsOnSelect
      search={search}
      setSearch={setSearch}
      error={error}
      {...props}
    >
      {selectedItem && (
        <SingleSelectLabel>
          <Label>
            {stringifyItem(selectedItem)}{' '}
            <Icon
              icon={ICONS.CLOSE_2}
              size={ICON_SIZES.SMALL}
              onClick={() => {
                setSearch('');
                reset();
              }}
            />
          </Label>
        </SingleSelectLabel>
      )}
    </BaseSelectSearch>
  );
}

export { SingleSearchSelect };
