import React, { useMemo, useState } from 'react';

import {
  REVIEW_QUESTION_TYPES,
  REVIEW_QUESTION_TYPES_V1,
  REVIEW_RATING_TYPE,
} from '@learned/constants';
import { Trans } from '@lingui/macro';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import {
  IconBackground,
  IconContainer,
} from '~/pages/ReviewGiveFeedback/components/Questions/design';
import type { IQuestionDefaultData } from '~/pages/ReviewGiveFeedback/types';
import type {
  IUserReviewQuestionCustomSkillV1Grouped,
  IUserReviewQuestionSkillCategoryV1Grouped,
} from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/utils';

import {
  CoachCircle,
  FocusAreaHeader,
  GroupButtons,
  Header,
  PeerCircle,
  RatingHeader,
  Scale,
  SelfCircle,
  StyledCareerHeader,
  Table,
  TableRow,
  SkillCategoryTitle,
} from './design';
import { SkillAnswersModal } from './SkillAnswersModal';
import { SkillRow } from './SkillRow';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import type {
  IJobProfile,
  IReviewRating,
  IReviewTheme,
  IUserReview,
  WithExtends,
} from '@learned/types';

export enum ViewMode {
  SKILL_COVERAGE = 'skill_coverage',
  RATING_SCALE = 'rating_scale',
}

function SkillAnswersV1({
  onEdit,
  question,
  isZeroState,
  userReview,
  questionNumber,
}: {
  onEdit: (
    skill:
      | IUserReviewQuestionSkillCategoryV1Grouped['skills'][0]
      | IUserReviewQuestionCustomSkillV1Grouped['skills'][0],
    question: IQuestionDefaultData,
  ) => void;
  isZeroState: boolean;
  questionNumber: number;
  question: WithExtends<
    IUserReviewQuestionSkillCategoryV1Grouped | IUserReviewQuestionCustomSkillV1Grouped,
    { theme?: IReviewTheme }
  >;
  userReview: IUserReview;
}) {
  const getMultiLangString = useMultiLangString();
  const [showCoachesModal, setShowCoachesModal] = useState(false);
  const [showPeersModal, setShowPeersModal] = useState(false);
  const [viewMode, setViewMode] = useState(ViewMode.SKILL_COVERAGE);

  const relevantQuestion = question?.skills?.at(0)?.questions?.at(0);
  const options = relevantQuestion?.settings.options;

  const jobNames = useMemo(() => {
    if (
      !(
        relevantQuestion &&
        [REVIEW_QUESTION_TYPES.SKILL_CATEGORY, REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1].includes(
          relevantQuestion.type,
        )
      )
    ) {
      return '';
    }
    // @ts-ignore
    const jobs = [getMultiLangString((relevantQuestion.settings.jobProfile as IJobProfile)?.name)];
    if (jobs.length === 1) {
      return jobs[0];
    } else {
      return jobs.join(', ');
    }
  }, [relevantQuestion, getMultiLangString]);

  const totalCoaches = useMemo(() => {
    const ratings = question.skills?.flatMap((skill) =>
      skill.questions?.flatMap((q) =>
        (q.reviewRatings ?? []).filter((rating) => rating.type === REVIEW_RATING_TYPE.COACH),
      ),
    );
    const uniqueCoaches = new Set(
      ratings?.map((rating) => rating.createdBy.id ?? rating.createdBy.email),
    );
    return uniqueCoaches.size;
  }, [question]);

  const totalPeers = useMemo(() => {
    const ratings = question.skills?.flatMap((skill) =>
      skill.questions?.flatMap((q) =>
        (q.reviewRatings ?? []).filter(
          (rating) =>
            rating.type === REVIEW_RATING_TYPE.PEER ||
            rating.type === REVIEW_RATING_TYPE.PEER_EMAIL,
        ),
      ),
    );
    const uniqueCoaches = new Set(
      ratings?.map((rating) => rating.createdBy.id ?? rating.createdBy.email),
    );
    return uniqueCoaches.size;
  }, [question]);

  const JobNamesAndLabels = (
    <Header $isZeroState={isZeroState}>
      <div className="right">
        <GroupButtons $isZeroState={isZeroState}>
          <button
            onClick={() => setViewMode(ViewMode.SKILL_COVERAGE)}
            className={viewMode === ViewMode.SKILL_COVERAGE ? 'active' : ''}
          >
            <Trans>Skill coverage</Trans>
          </button>
          <button
            onClick={() => setViewMode(ViewMode.RATING_SCALE)}
            className={viewMode === ViewMode.RATING_SCALE ? 'active' : ''}
          >
            <Trans>Rating scale</Trans>
          </button>
        </GroupButtons>
        {viewMode === ViewMode.RATING_SCALE ? (
          <>
            {options && (
              <Scale>
                1 {getMultiLangString(options[0].label)}
                {' - '}
                {options.length} {getMultiLangString(options.at(options.length - 1)!.label)}
              </Scale>
            )}
          </>
        ) : (
          <>
            {options && (
              <Scale>
                1%
                {' - '}
                {/*  @ts-ignore */}
                {((options.length / relevantQuestion.settings.expectedLevel || 1) * 100).toFixed(0)}
                %
              </Scale>
            )}
          </>
        )}
      </div>
      {jobNames && (
        <StyledCareerHeader>
          <IconContainer>
            <IconBackground backgroundColor={isZeroState ? COLORS.INACTIVE : COLORS.COMPANY} />
            <Icon
              size={ICON_SIZES.SMALL}
              icon={ICONS.CAREER}
              color={isZeroState ? COLORS.INACTIVE : COLORS.COMPANY}
            />
          </IconContainer>
          <div>{jobNames}</div>
        </StyledCareerHeader>
      )}
    </Header>
  );

  return (
    <>
      {JobNamesAndLabels}
      <Table $isZeroState={isZeroState}>
        <TableRow>
          <FocusAreaHeader>
            <Trans>Behavior and results</Trans>
          </FocusAreaHeader>
          <RatingHeader>
            {!isZeroState && <SelfCircle />}
            <Trans>SELF</Trans>
          </RatingHeader>
          <RatingHeader>
            {!isZeroState && <CoachCircle />}
            <Trans>COACH</Trans>
            {!isZeroState && totalCoaches > 1 && (
              <>
                <span>({totalCoaches})</span>{' '}
                <Icon
                  icon={ICONS.EXTERNAL_LINK}
                  size={ICON_SIZES.SMALL}
                  onClick={() => setShowCoachesModal(true)}
                />
              </>
            )}
          </RatingHeader>
          <RatingHeader>
            {!isZeroState && <PeerCircle />}
            <Trans>PEERS</Trans>
            {!isZeroState && totalPeers > 1 && (
              <>
                <span>({totalPeers})</span>{' '}
                <Icon
                  icon={ICONS.EXTERNAL_LINK}
                  size={ICON_SIZES.SMALL}
                  onClick={() => setShowPeersModal(true)}
                />
              </>
            )}
          </RatingHeader>
        </TableRow>
        {question.skills?.map((skill, index) => {
          // skills already grouped by skill-category (in migration for userReviewQuestions)
          // they go one-by-one
          const skillCategoryIdCurrent = skill.questions[0].settings.skillCategory;
          const skillCategoryIdPrev =
            index && question.skills[index - 1].questions[0].settings.skillCategory;
          const isSkillCategoryTitle =
            !skillCategoryIdPrev || skillCategoryIdCurrent !== skillCategoryIdPrev;
          const skillCategory = userReview?.backup?.skillCategories[skillCategoryIdCurrent];
          return (
            <>
              {isSkillCategoryTitle && (
                <SkillCategoryTitle>
                  {getMultiLangString(skillCategory?.name || {})}
                </SkillCategoryTitle>
              )}
              <SkillRow
                key={skill.skillId}
                skill={skill}
                userReview={userReview}
                onEdit={(ratings: IReviewRating[]) =>
                  onEdit(
                    {
                      ...skill,
                      // @ts-ignore
                      reviewRating: ratings,
                      type: relevantQuestion?.type,
                    },
                    question,
                  )
                }
                viewMode={viewMode}
              />
            </>
          );
        })}
      </Table>
      {showCoachesModal && (
        <SkillAnswersModal
          key="coaches"
          themeName={getMultiLangString((question.theme as IReviewTheme)?.name)}
          questionNumber={questionNumber}
          userReview={userReview}
          question={question}
          onClose={() => setShowCoachesModal(false)}
          ratingFilter={(rating?: IReviewRating) => rating?.type === REVIEW_RATING_TYPE.COACH}
          jobNamesHeader={JobNamesAndLabels}
          header={
            <>
              <CoachCircle />
              <Trans>COACHES</Trans>
              <span>({totalCoaches})</span>
            </>
          }
          viewMode={viewMode}
          onEdit={onEdit}
        />
      )}
      {showPeersModal && (
        <SkillAnswersModal
          key="peers"
          themeName={getMultiLangString((question.theme as IReviewTheme)?.name)}
          questionNumber={questionNumber}
          jobNamesHeader={JobNamesAndLabels}
          userReview={userReview}
          question={question}
          onClose={() => setShowPeersModal(false)}
          ratingFilter={(rating?: IReviewRating) =>
            rating?.type === REVIEW_RATING_TYPE.PEER ||
            rating?.type === REVIEW_RATING_TYPE.PEER_EMAIL
          }
          header={
            <>
              <PeerCircle />
              <Trans>PEERS</Trans>
              <span>({totalPeers})</span>
            </>
          }
          viewMode={viewMode}
          onEdit={onEdit}
        />
      )}
    </>
  );
}

export { SkillAnswersV1 };
