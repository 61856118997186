import { useRef, useEffect, useState } from 'react';

function useScrollEffect<T extends HTMLElement>(options: ScrollIntoViewOptions = {}) {
  const ref = useRef<T>(null);
  const [shouldScrollToRef, setShouldScrollToRef] = useState(false);

  useEffect(() => {
    if (shouldScrollToRef) {
      ref?.current?.scrollIntoView(options);
    }

    setShouldScrollToRef(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldScrollToRef]);

  const scrollToRef = () => {
    setShouldScrollToRef(true);
  };

  return { scrollToRef, ref };
}

export { useScrollEffect };
