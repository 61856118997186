import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import RowItem from './RowItem';

import * as currentGoalActivities from '~/store/currentGoal/actions';
import { COLOR_PALETTE } from '~/styles';

const Row = styled.div``;

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin: 16px 0;
`;

const UserGoalActivities = ({
  activities,
  loading,
  openActivity,
  activitiesReadonly,
  updateSidebar,
}) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  const updateActivity = async (id, data) => {
    await dispatch(currentGoalActivities.updateActivity(id, data, true));
  };

  return (
    <Row>
      <Label>{i18n._(t`Activities`)}</Label>
      <ShowSpinnerIfLoading loading={loading}>
        {!isEmpty(activities) &&
          activities.map((activity, index) => (
            <RowItem
              key={index}
              activity={activity}
              onUpdate={(data) => updateActivity(activity.id, data)}
              openActivity={openActivity}
              activitiesReadonly={activitiesReadonly}
              updateSidebar={updateSidebar}
              isOnClick={true}
            />
          ))}
      </ShowSpinnerIfLoading>
    </Row>
  );
};

UserGoalActivities.propTypes = {
  loading: PropTypes.bool,
  activities: PropTypes.array,
  activitiesReadonly: PropTypes.bool,
  openActivity: PropTypes.func,
};

export default UserGoalActivities;
