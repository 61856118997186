import filter from 'lodash/filter';
import { createSelector } from 'reselect';

import { ROLES, CONNECTION_STATUSES } from '~/constants';
import { getSelectedRole } from '~/selectors/baseGetters';
import getAllUsers from '~/selectors/getAllUsers';
import getTeamMembers from '~/selectors/getTeamMembers';
import zipArray from '~/utils/zipArray';

const getUsersByRole = createSelector(
  [getSelectedRole, getTeamMembers, getAllUsers],
  (role, teamUsers, allUsers) => {
    if (role === ROLES.COACH) {
      return teamUsers;
    }
    return allUsers;
  },
);

const getActiveUsersByRole = createSelector([getUsersByRole], (users) => {
  return zipArray(filter(users, (u) => u.status !== CONNECTION_STATUSES.INACTIVE.key));
});

export default (onlyActive = false) => {
  if (onlyActive) {
    return getActiveUsersByRole;
  }
  return getUsersByRole;
};
