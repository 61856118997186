import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { HeaderTabs } from '~/components/HeaderTabs';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import OverviewHeading from '~/components/OverviewHeading';
import BaseLayout from '~/layouts/BaseLayout';

import { SuperAdminAllJobLibrary } from './components/All/SuperAdminAllJobTemplates';
import { SuperAdminPendingJobLibrary } from './components/Pending/SuperAdminPendingJobTemplates';
import { HeaderWrapper, TitleWrapper } from './design';
import { JobTemplatesTab } from './types';

import { getJobTemplatesPendingCountSuperAdmin } from '~/services/jobTemplatesPending';
import { COLORS } from '~/styles';

import type { I18n } from '@lingui/core';

function SuperAdminJobLibrary() {
  const { i18n } = useLingui();
  const [currentTab, setCurrentTab] = useState(
    // @ts-ignore
    JobTemplatesTab[window.location.hash.substring(1).toUpperCase()] || JobTemplatesTab.ALL,
  );
  const [totalPending, setTotalPending] = useState(0);

  const fetchTotalPending = async () => {
    const result = await getJobTemplatesPendingCountSuperAdmin();
    setTotalPending(result?.data?.total ?? 0);
  };

  useEffect(() => {
    fetchTotalPending();
  }, []);

  const tabs = [
    {
      label: (i18n: I18n) => i18n._(t`All`),
      key: JobTemplatesTab.ALL,
    },
    {
      label: (i18n: I18n) => i18n._(t`Pending (${totalPending})`),
      key: JobTemplatesTab.PENDING,
    },
  ];

  function handleChangeTab(key: string) {
    window.location.hash = key;
    setCurrentTab(key as JobTemplatesTab);
  }

  return (
    <>
      <OverviewHeading
        title={
          <TitleWrapper>
            <span>{i18n._(t`Job Library`)}</span>
            <Icon icon={ICONS.SUPERADMIN} size={ICON_SIZES.MEDIUM} stroke={COLORS.ACTIVITY_GREY} />
          </TitleWrapper>
        }
      />
      <BaseLayout>
        <HeaderWrapper>
          <HeaderTabs tabs={tabs} selectedTab={currentTab} handleChangeTab={handleChangeTab} />
        </HeaderWrapper>
        {currentTab === JobTemplatesTab.ALL && <SuperAdminAllJobLibrary />}
        {currentTab === JobTemplatesTab.PENDING && (
          <SuperAdminPendingJobLibrary reFetchTotalPending={fetchTotalPending} />
        )}
      </BaseLayout>
    </>
  );
}

export { SuperAdminJobLibrary };
