import React, { MouseEventHandler } from 'react';

import { t } from '@lingui/macro';
import _ from 'lodash';

import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';
import { StatusLabel } from '~/pages/OnboardAndLearn/tabs/PersonalLearningsTab/StatusLabel';

import type { IColumnTable } from '~/@types/table';

import { Name, TypeName, ActivityIcon, PathIcon } from '../../design';

import type { IActivity, IPath } from '@learned/types';
import type { I18n } from '@lingui/core';

export enum SORT_OPTIONS {
  NAME_A_Z = 'name_asc',
  NAME_Z_A = 'name_desc',
  PROGRESS_MIN_MAX = 'progress_asc',
  PROGRESS_MAX_MIN = 'progress_desc',
  STATUS_ASC = 'status_asc',
  STATUS_DESC = 'status_desc',
}

const COLUMNS: IColumnTable<IActivity | IPath>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'name',
    maxWidth: '300px',
    renderCell: (item, onClick: MouseEventHandler<HTMLDivElement> | undefined) => {
      const element = (item as IActivity).type ? (
        <ActivityIcon>
          <Icon icon={ICONS.LEARNING_ACTIVITY} size={ICON_SIZES.MEDIUM} />
        </ActivityIcon>
      ) : (
        <PathIcon>
          <Icon icon={ICONS.PATH_SYMBOL_INACTIVE} size={ICON_SIZES.MEDIUM} />
        </PathIcon>
      );
      return (
        // @ts-ignore
        <Name onClick={() => onClick(item)}>
          {/* @ts-ignore */}
          {element}
          <ToolTip tooltip={item.name} placement={TOOLTIP_PLACEMENTS.BOTTOM} arrow={false}>
            <span>{item.name}</span>
          </ToolTip>
        </Name>
      );
    },
    sortBy: {
      asc: {
        key: SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Progress`),
    accessor: 'progress',
    minWidth: '100px',
    renderCell: (item) => {
      return <TypeName>{(item as IActivity).progress}%</TypeName>;
    },
    sortBy: {
      asc: {
        key: SORT_OPTIONS.PROGRESS_MIN_MAX,
        label: (i18n: I18n) => i18n._(t`Min-Max`),
      },
      desc: {
        key: SORT_OPTIONS.PROGRESS_MAX_MIN,
        label: (i18n: I18n) => i18n._(t`Max-Min`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Status`),
    accessor: 'status',
    minWidth: '100px',
    renderCell: (item) => {
      // TO DO: Fix type
      // @ts-ignore
      return (
        <TypeName>
          <StatusLabel status={item.status} />
        </TypeName>
      );
    },
    sortBy: {
      asc: {
        key: SORT_OPTIONS.STATUS_ASC,
        label: (i18n: I18n) => i18n._(t`Status Asc`),
      },
      desc: {
        key: SORT_OPTIONS.STATUS_DESC,
        label: (i18n: I18n) => i18n._(t`Status Desc`),
      },
    },
  },
];

export { COLUMNS };
