import React from 'react';

import { CONFIRMATION_MODAL_TYPE, SURVEY_STATUS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { UseFormReturn, Controller } from 'react-hook-form';
import styled from 'styled-components';

import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import Select from '~/components/Select';
import Switch from '~/components/Switch';
import { StepSection } from '~/pages/SurveyUpdate/components';
import type { IGeneralForm } from '~/pages/SurveyUpdate/types';

import { SURVEY_REPORTING_THRESHOLD } from '~/constants/survey';
import { useAutoSaveFunc } from '~/hooks/useAutoSave';
import { COLORS } from '~/styles';

import { Label } from '../../design';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 14px;
`;

const Block = styled.div`
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchLabel = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.BLACK};
`;

const Warning = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.13px;
  color: ${COLORS.WARNING};
`;

interface ISectionDates {
  formMethods: UseFormReturn<IGeneralForm>;
  autosave: useAutoSaveFunc;
}

const SectionAnonymity = ({ formMethods, autosave }: ISectionDates) => {
  const { i18n } = useLingui();
  const { watch, setValue, register, control } = formMethods;
  const { isAnonymous } = watch('anonymity');
  const isLockedToEdit = watch('status') !== SURVEY_STATUS.DRAFT;

  const onChangeAnonymity = async (value: boolean) => {
    let isConfirmed = true;
    if (!value) {
      isConfirmed = (await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Are you sure want to disable anonymity?`),
        description: i18n._(
          t`This would mean, that response will be not anonymous and team-level results will be displayed from 1 response.`,
        ),
      })) as unknown as boolean;
    }

    if (isConfirmed) {
      setValue('anonymity.isAnonymous', value);

      // additional logic
      // on enable/disable reset threshold to 3 (default value)
      setValue('anonymity.reportingThreshold', 3);
      autosave.run('anonymity.reportingThreshold');
    }
  };

  return (
    <StepSection
      title={i18n._(t`Anonymity`)}
      description={i18n._(
        t`Select whether to keep responses anonymous. Team-level results will be displayed if the reporting threshold isn't reached.`,
      )}
    >
      <Wrapper>
        <Block>
          <Row>
            <Controller
              {...register('anonymity.isAnonymous')}
              control={control}
              render={({ field: { value } }) => (
                <Switch onChange={onChangeAnonymity} checked={value} disabled={isLockedToEdit} />
              )}
            />
            <SwitchLabel>
              <Trans>Answers are anonymously</Trans>
            </SwitchLabel>
          </Row>
          {!isAnonymous && (
            <Warning>
              <Trans>
                Heads up: Collecting identifiable personal data is prohibited in certain cases
              </Trans>
            </Warning>
          )}
        </Block>

        {isAnonymous && (
          <Block>
            <Label>
              <Trans>Reporting threshold</Trans>
            </Label>
            <Row>
              <Controller
                {...register('anonymity.reportingThreshold')}
                control={control}
                render={({ field: { value, onChange } }) => (
                  /* @ts-ignore */
                  <Select
                    value={value}
                    options={SURVEY_REPORTING_THRESHOLD}
                    onChange={(value) => {
                      onChange(value);
                      autosave.run('anonymity.reportingThreshold');
                    }}
                    disabled={isLockedToEdit}
                    width="100%"
                    height="38px"
                  />
                )}
              />
            </Row>
          </Block>
        )}
      </Wrapper>
    </StepSection>
  );
};

export { SectionAnonymity };
