import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import StringInfinite from '~/components/StringInfinite';
import SvgIcon from '~/components/SvgIcon';

import arrowLeft from '~/assets/arrow-left.svg';
import arrowRight from '~/assets/arrow-right.svg';
import arrowDown from '~/assets/ic-arrow-drop-down-down.svg';

import { COLORS } from '~/styles';

const SelectSize = {
  big: 48,
  small: 35,
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SelectDropdownIcon = styled(SvgIcon)`
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 10px;
`;

const SelectContainer = styled.div`
  position: relative;
`;

export const SelectElement = styled.button`
  height: ${(props) => (props.small ? SelectSize.small : SelectSize.big)}px;
  min-height: ${(props) => (props.small ? SelectSize.small : SelectSize.big)}px;
  padding: 3px 21px 3px 13px;
  border-radius: 6px;
  background-color: ${COLORS.WHITE};
  border: solid 1px ${(props) => (props.error ? COLORS.ERROR : COLORS.BORDER)};
  width: ${(props) => props.width || '100%'};
  font-size: ${(props) => (props.small ? 14 : 16)}px;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  color: ${(props) => (props.disabled ? COLORS.SUBTEXT : '#333333')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
`;

export const OptionList = styled.div`
  width: ${(props) => (props.type === 'white' ? 'auto' : '200px')};
  min-width: 100%;
  margin-top: ${(props) => props.$marginTop || 0};
  max-width: ${(props) => (props.type === 'white' ? '100%' : '200px')};
  max-height: ${(props) => (props.listHeight ? props.listHeight : '200px')};
  overflow: auto;
  border-radius: 3px;
  ${(props) => (props.type === 'white' ? 'border: 1px solid #babdc7' : 'border: none')};
  ${(props) => props.borderColor && `border-color: ${props.borderColor}`};
  background-color: ${COLORS.WHITE};
  position: absolute;
  top: ${(props) =>
    props.isMenuAlignRight ? '100%' : `${props.small ? SelectSize.small : SelectSize.big}px;`};
  ${(props) => (props.isMenuAlignRight ? 'right: 0' : 'left: 0')};
  z-index: 2;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
`;

export const OptionListItem = styled.div`
  height: 44px;
  box-sizing: border-box;
  padding: 10px 10px 10px 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #333333;
  &:hover {
    background-color: rgba(161, 166, 180, 0.3);
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  ${(props) =>
    props.$isClickable &&
    css`
      &:hover {
        cursor: pointer;

        & > div {
          width: 32px;
          height: 32px;
          transition: width 0.1s, height 0.1s;
        }
      }
    `}
`;

/**
 * @deprecated since version 3.0 {@see Dropdown}
 */
class SelectDropDown extends Component {
  static propTypes = {
    keyName: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    renderLabel: PropTypes.func,
    placeholder: PropTypes.node,
    error: PropTypes.bool,
    width: PropTypes.string,
    isSteps: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    keyName: 'value',
    type: 'white',
    renderLabel: (option) => option.name,
  };

  state = {
    open: false,
  };

  closeList = () => {
    if (this.state.open) {
      this.setState({ open: false });
    }
  };

  toggleList = () => {
    if (!this.props.disabled) {
      this.setState({ open: !this.state.open });
    }
  };

  handleBlur = () => {
    this.closeList();
  };

  handleChange = (e, val) => {
    const { onChange, value } = this.props;
    e.stopPropagation();

    if (value !== val) {
      onChange(val);
    }
    this.closeList();
  };

  renderItem = (option) => {
    const { value, renderLabel, keyName, optionStyles } = this.props;
    const active = option[keyName] === value;
    return (
      <OptionListItem
        style={optionStyles}
        active={active}
        onMouseDown={(e) => e.preventDefault()}
        onMouseUp={(e) => this.handleChange(e, option[keyName])}
        key={option[keyName]}
      >
        <StringInfinite>{renderLabel(option)}</StringInfinite>
      </OptionListItem>
    );
  };

  stop(e) {
    e.stopPropagation();
  }

  onNextClick = (e, nextOption) => {
    const { keyName } = this.props;
    this.handleChange(e, nextOption[keyName]);
  };

  onPrevClick = (e, prevOption) => {
    const { keyName } = this.props;
    this.handleChange(e, prevOption[keyName]);
  };

  render() {
    const {
      options,
      value,
      isMenuAlignRight,
      label,
      type,
      disabled,
      i18n,
      small,
      renderLabel,
      className,
      listHeight,
      placeholder,
      keyName,
      error,
      width,
      labelStyle,
      listMarginTop,
      borderColor,
      isSteps,
    } = this.props;

    const { open } = this.state;
    const currentOptionIndex = options.map((o) => o[keyName]).indexOf(value);
    const nextOption = options[currentOptionIndex + 1];
    const prevOption = options[currentOptionIndex - 1];

    return (
      <Wrapper>
        {/* arrow prev*/}
        {isSteps && (
          <ArrowWrapper>
            <Arrow
              $isClickable={prevOption}
              onClick={(e) => prevOption && this.onPrevClick(e, prevOption)}
            >
              <SvgIcon
                isDefaultColor
                defaultColor={prevOption ? 'var(--company-color)' : COLORS.BORDER_LIGHT}
                width="28px"
                height="28px"
                url={arrowLeft}
                alt={i18n._(t`Prev`)}
              />
            </Arrow>
          </ArrowWrapper>
        )}

        {/* selected item*/}
        <SelectContainer onClick={this.stop} className={className}>
          <SelectElement
            onClick={this.toggleList}
            onBlur={this.handleBlur}
            disabled={disabled}
            small={small}
            error={error}
            width={width}
            style={labelStyle}
          >
            {value && currentOptionIndex !== -1
              ? renderLabel(options[currentOptionIndex])
              : label || i18n._(t`Not selected`)}
            <SelectDropdownIcon
              url={arrowDown}
              width="11px"
              height="6px"
              isDefaultColor
              defaultColor={COLORS.PLACEHOLDER}
            />
          </SelectElement>
          {open && (
            <OptionList
              listHeight={listHeight}
              isMenuAlignRight={isMenuAlignRight}
              type={type}
              small={small}
              borderColor={borderColor}
              $marginTop={listMarginTop}
            >
              {isEmpty(options) ? placeholder || i18n._(t`Empty`) : options.map(this.renderItem)}
            </OptionList>
          )}
        </SelectContainer>

        {/* arrow next*/}
        {isSteps && (
          <ArrowWrapper>
            <Arrow
              $isClickable={nextOption}
              onClick={(e) => nextOption && this.onNextClick(e, nextOption)}
            >
              <SvgIcon
                isDefaultColor
                defaultColor={nextOption ? 'var(--company-color)' : COLORS.BORDER_LIGHT}
                width="28px"
                height="28px"
                url={arrowRight}
                alt={i18n._(t`Next`)}
              />
            </Arrow>
          </ArrowWrapper>
        )}
      </Wrapper>
    );
  }
}

export default withI18n()(SelectDropDown);
