import { ROLES } from '~/constants';
import {
  pageUserPublic,
  pageUserPublicSubPage,
  pageOutside,
} from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'user-public';
const defaultUserId = ':userId';
const defaultPathId = ':pathId';

export const USER_PUBLIC_SKILL_PASSPORT = new LinkConstructor(
  [ROLES.USER, ROLES.COACH, ROLES.ADMIN],
  ({ userId = defaultUserId }) =>
    pageUserPublic(routeName, {
      id: userId,
      subPage: 'skill-passport',
    }),
);

export const USER_PUBLIC_PATH = new LinkConstructor(
  [ROLES.COACH, ROLES.ADMIN],
  ({ userId = defaultUserId, pathId = defaultPathId }) =>
    pageUserPublicSubPage(routeName, {
      id: userId,
      subPage: 'development/user-path',
      subPageId: pathId,
    }),
);

// create pdf
export const USER_PUBLIC_GENERATE_PDF = new LinkConstructor(
  [],
  ({ userId = defaultUserId, tokenId = ':token' }) =>
    `${pageOutside(routeName)}/pdf/${userId}/${tokenId}`,
);
