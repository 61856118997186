import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import MobileBroken from '~/assets/3.0/placeholders/mobile-broken.png';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  box-sizing: border-box;
  background: ${COLORS.BG_PAGE};
  min-height: 100vh;
`;

const Image = styled.img`
  margin-bottom: 86px;
  width: 189px;
  height: 216px;
`;

const Title = styled.div`
  color: ${COLORS.TEXT_HOVER};
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 9.5px;
`;

const Description = styled.div`
  color: ${COLORS.SUBTEXT};
  font-size: 14px;
  text-align: center;
`;

const MobileOutsideFeedbackNotAvailable = () => {
  return (
    <Wrapper>
      <Image src={MobileBroken} />
      <Title>
        <Trans>Something went wrong</Trans>
      </Title>
      <Description>
        <Trans>
          Unfortunately external requests to provide input cannot be opened on a mobile device.
          Please open this link on a desktop computer.
        </Trans>
      </Description>
    </Wrapper>
  );
};

export { MobileOutsideFeedbackNotAvailable };
