export enum SKILL_TEMPLATE_STATUS {
  PUBLISHED = 'published',
  DRAFT = 'draft',
  ARCHIVED = 'archived',
}

export enum SKILL_TEMPLATE_SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
  LAST_UPDATED_NEW_OLD = 'LAST_UPDATED_NEW_OLD',
  LAST_UPDATED_OLD_NEW = 'LAST_UPDATED_OLD_NEW',
  STATUS_A_Z = 'STATUS_A_Z',
  STATUS_Z_A = 'STATUS_Z_A',
}
