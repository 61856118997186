import styled from 'styled-components';

import { COLORS } from '~/styles';

const ModalDimensionsRow = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.SUBTEXT};
  font-size: 16px;
`;

export { ModalDimensionsRow };
