import React from 'react';

import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import starIcon from '~/assets/main-menu-icons/star.svg';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  color: ${COLORS.BLACK};
`;

const AboveDone = styled.div`
  display: flex;
  z-index: 1; // we need this to show answer above "done" background
`;

const Label = styled.div``;

const Box = styled.div`
  position: relative;
  width: 100%;
  max-width: 68px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 10px;
`;

const Done = styled.div<{ percentage?: number | string }>`
  position: absolute;
  bottom: 0;
  left: 0;
  height: ${(props) => props.percentage || 0}%;
  width: 100%;
  background-color: ${COLORS.GREEN_LIGHT};
`;

const Details = styled.div<{ isTopPosition?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 9px;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: center;

  // define position
  position: relative;
  top: ${(props) => (props.isTopPosition ? '-50px' : 0)};
  z-index: 1;
`;

const Legend = styled.div`
  position: absolute;
  bottom: -50px;
  height: 50px; // same as bottom
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > div:first-child {
    margin-top: 5px; // top margin
  }

  & > div:nth-child(2) {
    margin-top: 2px; // margin between label and number
  }
`;

interface IOptionProps {
  label?: string;
  labelNumber?: string | number;
  isValue: boolean;
  value?: string | number;
  percentage?: string | number;
  icon?: string;
  light?: string;
}

const StarAnswer = ({ label, isValue, value, percentage, labelNumber }: IOptionProps) => {
  return (
    <Wrapper>
      <Box>
        <AboveDone>
          <SvgIcon
            width="32px"
            height="32px"
            isDefaultColor
            defaultColor={COLORS.DARK_HOVER}
            url={starIcon}
          />
        </AboveDone>
        <Done percentage={percentage}>
          {/* hide details if value 0 */}
          {/* if percentage > 30 -> show details inside Done */}
          {/* if percentage < 30 -> show details above Done  */}
          {isValue && Boolean(value) && (
            <Details isTopPosition={Number(percentage) < 30}>
              <div>{value}</div>
              <div>{`(${percentage}%)`}</div>
            </Details>
          )}
        </Done>
      </Box>
      <Legend>
        <div>{labelNumber}</div>
        {label && <Label>{label}</Label>}
      </Legend>
    </Wrapper>
  );
};

export { StarAnswer };
