import React, { Dispatch, SetStateAction, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';
import IconMenu from '~/components/IconMenu';
import { Marker } from '~/components/Marker';

import { TD, TR, TableWrapper, DraftLabel, DraftWrapp, IconMenuWrapp } from './design';
import { Header } from './Header';
import { SelectedHeader } from './SelectedHeader';

import type { IColumnTable } from '~/@types/table';

export type TMultiSelect = {
  checkedCount: number;
  isAllChecked: boolean;
  onCheckAll: () => void;
  onSelectItem: (item: any) => void;
  isItemChecked: (item: any) => void;
  onDuplicate?: () => void;
  onDelete?: () => void;
};

const STATUS = {
  DRAFT: 'draft',
};

interface ITableProps {
  columns: IColumnTable<any>[];
  // TODO: improve data type
  data: Array<object & { id: string; status?: string }>;
  onRowClick?: (item: any) => void;
  // TODO: improve data type
  sortBy?: string;
  setSortBy?: Dispatch<SetStateAction<string | any>>;
  showMultiSelect?: boolean;
  multiSelect?: TMultiSelect;
  showMenu?: boolean;
  showDraftStatus?: boolean;
  createMenuItems?: (item: any) => void;
  onColClick?: {
    column: string;
    onClick: (item: any) => void;
  };
  isScrollbarVisible?: boolean;
  className?: string;
  hideHeaders?: boolean;
}

const StyledCheckBox = styled(CheckBox)`
  margin: 0;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Table = ({
  columns,
  data,
  onRowClick,
  sortBy,
  setSortBy,
  showMultiSelect = false,
  multiSelect,
  showMenu,
  showDraftStatus,
  createMenuItems,
  onColClick,
  className,
  hideHeaders = false,
}: ITableProps) => {
  const { i18n } = useLingui();
  const [hovered, setHovered] = useState(-1);
  const handleSortBy = (key?: string) => {
    setSortBy && setSortBy(key || '');
  };

  const sortProps = {
    sortBy,
    handleSortBy,
  };

  return (
    <Wrapper className={className}>
      {showMultiSelect && (multiSelect as TMultiSelect)?.checkedCount > 0 && (
        <SelectedHeader multiSelect={multiSelect as TMultiSelect} />
      )}
      <TableWrapper $isPointer={Boolean(onRowClick)}>
        {!hideHeaders && (
          <Header
            multiSelect={multiSelect}
            showMultiSelect={showMultiSelect}
            columns={columns}
            sortProps={sortProps}
            showMenu={showMenu}
          />
        )}
        <tbody>
          {data.map((item, i) => {
            const isItemChecked = showMultiSelect && multiSelect?.isItemChecked(item);
            return (
              <TR
                onMouseEnter={() => setHovered(i)}
                onMouseLeave={() => setHovered(-1)}
                key={item?.id}
                onClick={() => onRowClick && onRowClick(item)}
                role="link"
                // @ts-ignore
                isSelected={showMultiSelect && isItemChecked}
              >
                {showMultiSelect && (
                  <TD key={`td-${i}`} $isPointer minWidth="20px" padding="16px 0 16px 32px">
                    {/* @ts-ignore */}
                    <StyledCheckBox
                      size="20px"
                      checked={isItemChecked}
                      onChange={() => multiSelect?.onSelectItem(item)}
                      defaultColor={undefined}
                    />
                  </TD>
                )}
                {columns.map((column) => {
                  const isCallWithClick = !!onColClick && onColClick.column === column.accessor;
                  return column.renderCell ? (
                    <TD
                      key={`td-${column.accessor}`}
                      maxWidth={column.maxWidth}
                      padding={column.padding}
                    >
                      {column.renderCell(
                        item,
                        isCallWithClick ? onColClick.onClick : undefined,
                        hovered === i,
                      )}
                    </TD>
                  ) : (
                    <TD
                      key={`td-${column.accessor}`}
                      minWidth={column.minWidth}
                      maxWidth={column.maxWidth}
                    >
                      {/* @ts-ignore */}
                      {item?.[column.accessor]}
                    </TD>
                  );
                })}
                {(showMenu || showDraftStatus) && (
                  <TD minWidth="26px" padding="16px 26px 16px 0">
                    <IconMenuWrapp>
                      {showMenu && Boolean(createMenuItems) && (
                        <IconMenu
                          // @ts-ignore
                          items={createMenuItems(item)}
                        />
                      )}
                      {showDraftStatus && item?.status === STATUS.DRAFT && (
                        <DraftWrapp>
                          <DraftLabel>
                            <Marker label={i18n._(t`Draft`)} />
                          </DraftLabel>
                        </DraftWrapp>
                      )}
                    </IconMenuWrapp>
                  </TD>
                )}
              </TR>
            );
          })}
        </tbody>
      </TableWrapper>
    </Wrapper>
  );
};

export { Table };
