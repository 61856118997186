import React, { Component } from 'react';

import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { COLOR_SET } from '~/styles';

const UserAvatarDiv = styled.div`
  display: flex;
  border-radius: 50%;
  background-color: #999999;
  margin-right: 16px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('~/assets/user-default.svg');
`;

const UserInfo = styled.div`
  height: 48px;
  min-width: 144px;
  margin-right: 35px;
  padding: 5px;
  margin-left: -15px;
  box-sizing: border-box;
  display: flex;

  .new-tooltip-username {
    font-size: 14.4px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.11;
    color: #3b3b3b;
    text-transform: uppercase;
    margin: 0;
    white-space: nowrap;
  }
  .new-tooltip-jobtitle {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    color: #70747f;
    margin: 0;
    z-index: 1000;
    white-space: nowrap;
  }
`;

const StateMark = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  bottom: 0px;
  z-index: 1;
  right: 35px;
  background-color: ${(props) => (props.isShare ? COLOR_SET.BLUE : '#f27171')};
`;

const ToolTip = styled.div`
  position: absolute;
  top: 50px;
  font-size: 14px;
  width: auto;
  right: -10px;
  padding: 8px;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(238, 81, 81, 0.5);
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  z-index: 10;
`;
const ToolTipName = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #292929;
  margin: 0;
  white-space: nowrap;
`;

const UserAvatarWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: -30px;
  &:first-child {
    margin-left: 0px;
  }
`;

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTooltipOpen: false,
    };
  }

  static propTypes = {
    showTooltip: PropTypes.bool,
  };

  showTooltip = () => {
    this.setState({ isTooltipOpen: true });
  };

  hideTooltip = () => {
    this.setState({ isTooltipOpen: false });
  };

  shouldComponentUpdate = () => {
    return true;
  };

  render = () => {
    const { user, width, height, isRequestState, onClick, selectedUser, companyColor } = this.props;
    const { isTooltipOpen } = this.state;
    let userName = user && user.firstName ? user.firstName : '';

    const isSelected =
      user &&
      user.id &&
      (user.id === selectedUser ||
        (selectedUser && selectedUser.id && user.id === selectedUser.id));

    return (
      <UserAvatarWrap>
        <UserAvatarDiv
          companyColor={companyColor}
          isSelected={isSelected}
          onClick={() => onClick(user.id)}
          style={{
            backgroundImage: user && user.avatarUrl && 'url(' + user.avatarUrl + ')',
            width: width ? width + 'px' : 'inherit',
            height: height ? height + 'px' : 'inherit',
            minWidth: width ? width + 'px' : 'inherit',
          }}
          width={width}
          height={height}
          onMouseOver={this.showTooltip}
          onFocus={this.showTooltip}
          onMouseOut={this.hideTooltip}
          onBlur={this.hideTooltip}
        />
        {isRequestState && <StateMark isShare={user.userRequestIsShared} />}
        {isSelected && (
          <UserInfo>
            <p className="new-tooltip-username">{userName}</p>
          </UserInfo>
        )}
        {isTooltipOpen && (
          <ToolTip>
            <ToolTipName>{userName}</ToolTipName>
          </ToolTip>
        )}
      </UserAvatarWrap>
    );
  };
}

export default connect()(withI18n()(User));
