import React, { FunctionComponent, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { IActivity } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import StringInfinite from '~/components/StringInfinite';
import SvgIcon from '~/components/SvgIcon';
import ActionsPopover from '~/components/UI/ActionsPopover';
import { ActivityImage } from '~/pages/PathSetup/steps/PathStepContent/ActivityImage';

import MenuIcon from '~/assets/menu.svg';

import { COLOR_PALETTE, COLORS } from '~/styles';

const ROW_HEIGHT = 64;

const ActionsTextWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100px;
  margin-right: 20px;
  padding-left: 20px;
  height: 28px;
  border-left: solid 1px ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  cursor: pointer;

  p {
    padding: 0;
    margin-right: 8px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ActivityItem = styled.div`
  min-height: ${ROW_HEIGHT}px;
  max-height: ${ROW_HEIGHT}px;
  background: white;
  width: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.2s ease;
  border: solid 1px ${COLOR_PALETTE.GRAY_SEMI_SOFT};
`;

const LeftBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ActivityDragWrapper = styled.div`
  min-width: 45px;
  max-width: 45px;
  cursor: pointer;
`;

const ActivityNameWrapper = styled.div`
  margin-left: 12px;
  max-width: 500px;
`;

const ActivityName = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

const DeleteButton = styled.div`
  cursor: pointer;
  min-width: 56px;
  max-width: 56px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: solid 1px ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  & svg {
    color: ${COLOR_PALETTE.DARK_GRAY};
  }

  &:hover {
    & svg {
      color: #ff5252;
    }
  }
`;

const OneRow = styled.div`
  display: flex;
  align-items: center;
`;

interface IPathActivity {
  activity: IActivity;
  onDelete: (activityId: string) => void;
  onClick: (activity: IActivity) => void;
  activityProps: any;
  isDragDisabled: boolean;
  changeActivityObligation: (activityId: string, isObligated: boolean) => void;
}

const PathActivity: FunctionComponent<IPathActivity> = ({
  activity,
  onDelete,
  onClick,
  activityProps,
  isDragDisabled,
  changeActivityObligation,
}) => {
  const { i18n } = useLingui();
  const obligatedOptions = [
    { label: i18n._(t`Optional`), action: () => changeActivityObligation(activity.id, false) },
    { label: i18n._(t`Obligated`), action: () => changeActivityObligation(activity.id, true) },
  ];

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  return (
    <Draggable
      draggableId={activity.id}
      index={activityProps.order}
      isDragDisabled={isDragDisabled}
    >
      {(provided: any, snapshot: any) => (
        <Wrapper
          ref={provided.innerRef}
          {...provided.draggableProps}
          $isDragging={snapshot.isDragging}
        >
          {!isDragDisabled && (
            <ActivityDragWrapper {...provided.dragHandleProps}>
              <SvgIcon
                width="21px"
                height="16px"
                url={MenuIcon}
                defaultColor="var(--company-color)"
                isDefaultColor={true}
              />
            </ActivityDragWrapper>
          )}
          <ActivityItem>
            <LeftBlock onClick={() => onClick(activity)}>
              <ActivityImage
                activity={activity}
                width="100px"
                height={`${ROW_HEIGHT}px`}
                styles={{ borderRadius: '6px 0 0 6px' }}
              />
              <ActivityNameWrapper>
                <ActivityName>
                  <StringInfinite>{activity.name}</StringInfinite>
                </ActivityName>
              </ActivityNameWrapper>
            </LeftBlock>
            <div>
              <OneRow>
                <ActionsPopover items={obligatedOptions} isRows>
                  <ActionsTextWrapper>
                    <p>{activityProps.isObligated ? i18n._(t`Obligated`) : i18n._(t`Optional`)}</p>
                    <Icon
                      icon={ICONS.DROPDOWN}
                      size={ICON_SIZES.SMALL}
                      color="var(--company-color)"
                    />
                  </ActionsTextWrapper>
                </ActionsPopover>
                <DeleteButton
                  onClick={() =>
                    activity?.isAvailableInUserLibrary === false
                      ? setIsDeleteModalVisible(true)
                      : onDelete(activity.id)
                  }
                >
                  <Icon icon={ICONS.DELETE_BIN} color="var(--company-color)" />
                </DeleteButton>
              </OneRow>
            </div>
          </ActivityItem>
          {isDeleteModalVisible && (
            <ConfirmationModal
              type={CONFIRMATION_MODAL_TYPE.DELETE}
              onClose={() => setIsDeleteModalVisible(false)}
              description={i18n._(
                t`Are you sure you want to delete this activity from this path? This will
                  delete all progress from all participants. This action cannot be undone.`,
              )}
              onSubmit={() => onDelete(activity.id)}
            />
          )}
        </Wrapper>
      )}
    </Draggable>
  );
};

export { PathActivity };
