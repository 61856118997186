import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const OptionsIcon = ({ size, ...props }) => (
  <InlineSvg height={size} width={size} {...props} childrenFirst viewBox="0 0 18 14">
    <g
      transform="translate(1.214 1.021)"
      stroke="currentColor"
      strokeWidth="1.2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M9.818 9.823H0M16 9.823h-2.545" />
      <circle cx="11.636" cy="9.818" r="1.818" />
      <path d="M4 1.823H0M16 1.823H7.636" />
      <circle cx="5.818" cy="1.818" r="1.818" />
    </g>
  </InlineSvg>
);

OptionsIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

OptionsIcon.defaultProps = {
  size: 18,
  className: '',
};

export default OptionsIcon;
