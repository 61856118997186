import { createAction } from 'redux-act';

import { getCareerPlans as getCareerPlansFromDB } from '~/services/careerPlans';

export const startGetCareerPlans = createAction('start get career plans');
export const finishGetCareerPlans = createAction('finish get career plans');

export function getCareerPlans() {
  return function (dispatch) {
    dispatch(startGetCareerPlans());

    return getCareerPlansFromDB().then((careerPlans) => {
      dispatch(finishGetCareerPlans(careerPlans));
      return careerPlans;
    });
  };
}
