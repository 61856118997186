import { createReducer } from 'redux-act';

import { finishGetCompanyProducts } from '~/store/products/actions';

const INITIAL_STATE = {};

export const products = createReducer({}, INITIAL_STATE).on(
  finishGetCompanyProducts,
  (state, payload) => {
    return {
      ...state,
      ...payload,
    };
  },
);
