import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import find from 'lodash/find';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ICONS } from '~/components/Icon';
import { ProfileSettingsBlock } from '~/components/ProfileSettingsComponents/MainDashboard/ProfileSettingsBlock';
import { useToasts, TOAST_TYPES } from '~/components/Toast';

import { USER_INTEGRATION_TYPES } from '~/constants';
import AzureClient from '~/integrations/azureClient';
import GoogleClient from '~/integrations/googleClient';
import { getUser } from '~/selectors/baseGetters';
import { getUserIntegration, syncUserIntegration } from '~/services/userIntegrations';
import * as authActions from '~/store/auth/actions';
import { updateUserProfile as updateUserProfileAction } from '~/store/auth/actions';

import IntegrationRow from '../../IntegrationRow';
import { SectionHeader } from '../SectionHeader';

const ContentWrap = styled.div`
  padding-bottom: 10px;
`;

const azureClient = new AzureClient();
const googleClient = new GoogleClient();

const INTEGRATIONS = [
  {
    type: USER_INTEGRATION_TYPES.google,
    description: t`Sync. your Learned conversations with your Google Calendar`,
    icon: ICONS.GOOGLE_LOGO,
  },
  {
    type: USER_INTEGRATION_TYPES.azure,
    description: t`Sync. your Learned conversations with your Office Calendar`,
    icon: ICONS.OUTLOOK_LOGO,
  },
];

const IntegrationsBlock = React.forwardRef((_props, ref) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const { addToast } = useToasts();
  const [showIntegrationPopup, setShowIntegrationPopup] = useState(false);

  const handleChangeIntegration = async (integration: any, isExist: boolean) => {
    try {
      setShowIntegrationPopup(true);
      if (integration.type === USER_INTEGRATION_TYPES.azure) {
        if (isExist) {
          await azureClient.logout(integration.id);
          // update local store
          dispatch(updateUserProfileAction({ showIntegrationQuestion: true }));
          // @ts-ignore
          dispatch(authActions.deleteIntegration(integration.id));
        } else {
          const integ = await azureClient.login(integration.email);
          // update local store
          // @ts-ignore
          dispatch(authActions.addIntegration(integ));
        }
      }

      if (integration.type === USER_INTEGRATION_TYPES.google) {
        if (isExist) {
          await googleClient.logout(integration.id);
          // update local store
          dispatch(updateUserProfileAction({ showIntegrationQuestion: true }));
          // @ts-ignore
          dispatch(authActions.deleteIntegration(integration.id));
        } else {
          const integration = await googleClient.login();

          // update local store
          // @ts-ignore
          dispatch(authActions.addIntegration(integration));
        }
      }
    } catch (e: any) {
      // if e is undefined or an axios error (to prevent double toasts since we already catch axios errors ourselves) just close window
      if (e && e.name !== 'AxiosError') {
        addToast({
          title: i18n._(t`Outlook integration error`),
          subtitle: e && e.message,
          type: TOAST_TYPES.ERROR,
        });
      }
    } finally {
      setShowIntegrationPopup(false);
    }
  };

  const initIntegrationSync = async ({ id }: { id: string }) => {
    syncUserIntegration(id);
  };

  /**
   * Fetch the User integration from API
   * Update the store
   */
  const refreshUserIntegration = async ({ id }: { id: string }) => {
    const integration = await getUserIntegration(id);
    // @ts-ignore
    dispatch(authActions.updateIntegration(integration));
  };

  const Content = (
    <ContentWrap>
      {INTEGRATIONS.map((integration: any) => {
        const integrationProfile = find(user.integrations, (i) => i && i.type === integration.type);

        return (
          <IntegrationRow
            key={integration.type}
            type={integration.type}
            icon={integration.icon}
            description={integration.description}
            integration={integrationProfile}
            onIntegrationChange={handleChangeIntegration}
            isBlocked={!integrationProfile && user.integrations.length > 0}
            disabled={showIntegrationPopup}
            refreshUserIntegration={refreshUserIntegration}
            initIntegrationSync={initIntegrationSync}
          />
        );
      })}
    </ContentWrap>
  );

  return (
    <ProfileSettingsBlock
      ref={ref}
      header={<SectionHeader title="Integrations" />}
      content={Content}
    />
  );
});

export { IntegrationsBlock };
