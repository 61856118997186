import React, { useEffect, useState } from 'react';

import {
  USER_REVIEW_REQUEST_TYPE,
  USER_REVIEW_SORT_OPTIONS,
  USER_REVIEW_STATUS,
} from '@learned/constants';
import { IUserReview } from '@learned/types';
import isEmpty from 'lodash/isEmpty';

import routes from '~/constants/routes';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUserReviews } from '~/services/userReviews';

import { SIDEBAR_ITEM_TYPES } from '../../constants';
import { SidebarRow } from '../SidebarRow';

const NextReviewCard = () => {
  const [userReview, setUserReview] = useState<IUserReview>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getMultiLangString = useMultiLangString();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const {
        data: { userReviews: items, total },
      } = await getUserReviews({
        filters: {
          status: [USER_REVIEW_STATUS.PUBLISHED, USER_REVIEW_STATUS.ACTIVE],
        },
        options: {
          limit: 1,
          skip: 0,
          sortBy: USER_REVIEW_SORT_OPTIONS.START_DATE_OLD_NEW,
        },
        type: USER_REVIEW_REQUEST_TYPE.PERSONAL,
      });

      if (total) {
        const userReviews: IUserReview[] = Object.values(items);
        setUserReview(userReviews[0]);
      } else {
        setUserReview(undefined);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const onCardClick = () => {
    if (userReview) {
      routes.USER_REVIEW_DASHBOARD.go({}, { userReviewId: userReview.id, isBackPath: true });
    }
  };

  return (
    <>
      <SidebarRow
        type={SIDEBAR_ITEM_TYPES.NEXT_REVIEW}
        onClick={onCardClick}
        isShowPlaceholder={isEmpty(userReview)}
        isLoading={isLoading}
        name={userReview ? getMultiLangString(userReview.name) : ''}
      />
    </>
  );
};

export { NextReviewCard };
