import React, { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import map from 'lodash/map';
import styled from 'styled-components';

import Button from '~/components/Button';
import Select from '~/components/Select';
import { TextField } from '~/components/Text';

import useBoolState from '~/hooks/useBoolState';

import { SDWORX_URLS } from '../../../constants';

const SettingLabel = styled.div``;
const SettingBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin: 4px;
  margin-left: 8px;
  margin-top: 8px;
`;

const SettingTextField = styled(TextField)`
  width: 100%;
  height: 48px;
`;

const SettingChoiceField = styled(Select)`
  width: 100%;
  height: 48px;
`;

function SdworxConnectionInfo({
  i18n,
  testHook: [_, setTestState],
  settingsHook: [integrationSettings, setIntegrationSettings],
  updateMode,
}) {
  const $editingCliendId = useBoolState();
  const $editingClientSecret = useBoolState();
  const $editingClientURL = useBoolState();
  const [selectedUrl, setSelectedUrl] = useState('');

  return (
    <>
      <SettingBlock>
        <SettingLabel>
          <Trans>Client ID</Trans>
        </SettingLabel>
        {!updateMode || $editingCliendId.value ? (
          <SettingTextField
            placeholder={i18n._(t`Enter client ID...`)}
            onChange={(e) => {
              setTestState({ tested: false, isValid: false });
              setIntegrationSettings({
                ...integrationSettings,
                connectionInfo: {
                  ...integrationSettings.connectionInfo,
                  clientId: e.target.value,
                },
              });
            }}
          />
        ) : (
          <Button onClick={$editingCliendId.on} label={i18n._(t`Click to update`)} />
        )}
      </SettingBlock>
      <SettingBlock>
        <SettingLabel>
          <Trans>Client secret</Trans>
        </SettingLabel>
        {!updateMode || $editingClientSecret.value ? (
          <SettingTextField
            placeholder={i18n._(t`Enter client secret...`)}
            onChange={(e) => {
              setTestState({ tested: false, isValid: false });
              setIntegrationSettings({
                ...integrationSettings,
                connectionInfo: {
                  ...integrationSettings.connectionInfo,
                  clientSecret: e.target.value,
                },
              });
            }}
          />
        ) : (
          <Button onClick={$editingClientSecret.on} label={i18n._(t`Click to update`)} />
        )}
      </SettingBlock>
      <SettingBlock>
        <SettingLabel>
          <Trans>API URL</Trans>
        </SettingLabel>
        {!updateMode || $editingClientURL.value ? (
          <SettingChoiceField
            options={map(SDWORX_URLS, (url) => ({
              value: url.key,
              name: url.name(i18n),
            }))}
            onChange={(e) => {
              setTestState({ tested: false, isValid: false });
              setSelectedUrl(e);
              setIntegrationSettings({
                ...integrationSettings,
                connectionInfo: {
                  ...integrationSettings.connectionInfo,
                  clientPortal: e,
                },
              });
            }}
            value={selectedUrl}
          />
        ) : (
          <Button onClick={$editingClientURL.on} label={i18n._(t`Click to update`)} />
        )}
      </SettingBlock>
    </>
  );
}

export default withI18n()(SdworxConnectionInfo);
