import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

const d =
  'M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const CloseIcon = ({ size, ...props }) => <InlineSvg height={size} width={size} d={d} {...props} />;

CloseIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

CloseIcon.defaultProps = {
  size: 24,
  className: '',
};

export default CloseIcon;
