import React, { useEffect, useRef, useState } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LineController,
  LinearScale,
  PointElement,
  Filler,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import map from 'lodash/map';
import max from 'lodash/max';
import { Chart } from 'react-chartjs-2';

import type { ChartData, ChartOptions } from 'chart.js';

ChartJS.register(
  CategoryScale,
  LineController,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

const COLORS = [
  ['rgba(0, 94, 254, 1)', 'rgba(0, 94, 254, 0)'],
  ['rgba(254, 152, 0, 1)', 'rgba(254, 152, 0, 0)'],
  ['rgba(242, 69, 201, 1)', 'rgba(242, 69, 201, 0)'],
];

const createGradient = (
  canvas: HTMLCanvasElement,
  height: number,
  color1: string,
  color2: string,
) => {
  const ctx = canvas.getContext('2d');
  if (ctx) {
    const gradient = ctx.createLinearGradient(0, 0, 0, height);
    gradient.addColorStop(0, color1);
    gradient.addColorStop(1, color2);
    return gradient;
  }
};

interface LineChartProps {
  labels?: string[];
  dataSets?: any[];
  isPercent: boolean;
  spanGaps: boolean | number;
  responsive: boolean;
}

const LineChart = ({ labels, dataSets, isPercent, spanGaps, responsive }: LineChartProps) => {
  const chartRef = useRef<ChartJS<'line'>>(null);
  const [chartData, setChartData] = useState<ChartData<'line'>>({ labels: [], datasets: [] });

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const height = 309;

    const GRADIENTS = COLORS.map((color) =>
      createGradient(chart.canvas, height, color[0], color[1]),
    );

    const chartData = {
      labels,
      datasets: dataSets?.map((d, index) => ({
        ...d,
        fill: true,
        backgroundColor: GRADIENTS[index],
        borderColor: COLORS[index][0],
      })),
    };

    if (chartData.datasets?.length && chartData.labels?.length) {
      // @ts-ignore
      setChartData(chartData);
    }

    // eslint-disable-next-line
  }, [chartRef]);

  let options: ChartOptions<'line'> = {
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        enabled: true,
        callbacks: {
          // review name
          beforeTitle: (tooltipItems) => {
            return tooltipItems.map((tooltipItem) =>
              get(tooltipItem, `dataset.names[${tooltipItem.dataIndex}]`, ''),
            )[0];
          },

          // date
          title: (tooltipItems) => {
            return tooltipItems.map((tooltipItem) => get(tooltipItem, 'label', ''))[0];
          },

          // value
          label: (tooltipItem) => {
            const dataset = get(tooltipItem, 'dataset');
            const value = get(dataset, `data[${tooltipItem.dataIndex}]`);
            const maxValue = get(dataset, `maxValues[${tooltipItem.dataIndex}]`);
            if (isPercent) {
              return `${value}%`; // for skills
            }

            // for potential/performance
            return `${value} / ${maxValue}`; // 4/5
          },
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        suggestedMax: 100,
        suggestedMin: 0,
        max: isPercent ? undefined : max(flatten(map(dataSets, (d) => d.maxValues))),
        beginAtZero: true,
        ticks: {
          precision: 2,
          stepSize: isPercent ? 25 : 1,
          callback: (label, _index, labels) => {
            return isPercent
              ? `${label}%` // for skills
              : `${label} / ${labels.length - 1}`; // for potential/performance
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    elements: {
      line: {
        tension: 0, // to have straight lines
      },
    },
    spanGaps,
  };

  if (responsive) {
    options = {
      ...options,
      responsive,
    };
  }

  return (
    <Chart
      ref={chartRef}
      type="line"
      width={undefined}
      height={undefined}
      data={chartData}
      options={options}
    />
  );
};

LineChart.defaultProps = {
  isPercent: true,
  responsive: false,
};

export default LineChart;
