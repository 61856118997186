import React from 'react';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AvatarCard from '~/components/UI/AvatarCard';

import { REQUEST_STATUSES } from '~/constants';
import { COLORS } from '~/styles';

const AvatarRow = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
`;

const Text = styled.div`
  margin-left: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.67;
  letter-spacing: normal;
  color: ${COLORS.TEXT_SECONDARY};
`;

const StyledAvatarCard = styled(AvatarCard)`
  padding: 4px 0;
`;

const RequestRow = ({ request }) => {
  return (
    <AvatarRow>
      <StyledAvatarCard userId={request.toUser} email={request.toEmail} type="small" />
      <Text>
        {request.status === REQUEST_STATUSES.REJECTED.key ? (
          <Trans>Rejected</Trans>
        ) : (
          <Trans>Waiting for review…</Trans>
        )}
      </Text>
    </AvatarRow>
  );
};

RequestRow.propTypes = {
  request: PropTypes.object.isRequired,
  userId: PropTypes.string,
};

export default RequestRow;
