import React from 'react';

import { GOAL_PROGRESS_TYPES, GOAL_STATUSES, SYMBOLS } from '@learned/constants';
import get from 'lodash/get';
import round from 'lodash/round';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import GoalProgressModal from '~/components/GoalDashboardCardOld/components/GoalProgressModal';

import Description from './Description';
import Header from './Header';
import Info from './Info';
import Progress from './Progress';

import useBoolState from '~/hooks/useBoolState';
import * as currentGoal from '~/store/currentGoal/actions';

const UserMainGoalInfo = ({ goal, readOnly, updateSidebar }) => {
  const dispatch = useDispatch();
  const $updateProgressModal = useBoolState(false);
  const isDraft = goal.status === GOAL_STATUSES.DRAFT;
  const isAvgType = goal.progressType === GOAL_PROGRESS_TYPES.AVG;
  const isDoneType = goal.progressType === GOAL_PROGRESS_TYPES.IS_DONE;

  const onUpdateProgress = async (data) => {
    await dispatch(currentGoal.updateGoalProgress(data));
    if (updateSidebar) {
      updateSidebar(true);
    }
    $updateProgressModal.off();
  };

  return (
    <>
      <Header
        type={goal.type}
        name={goal.name}
        viewers={goal.viewers}
        team={get(goal, 'team.name')}
      />
      <Info
        owners={goal.owners}
        creators={goal.creators}
        goalCycles={goal.goalCycles}
        deadline={goal.deadline}
        createdAt={get(goal, 'meta.createdDate')}
      />
      <Description description={goal.description} />
      <Progress
        progressBar={round(goal.calculatedProgress, 2)}
        progress={isAvgType ? round(goal.calculatedProgress, 2) : goal.progress}
        status={goal.status}
        min={get(goal, 'progressDetails.min')}
        max={get(goal, 'progressDetails.max')}
        symbol={isAvgType ? ' %' : get(SYMBOLS, `${get(goal, 'progressDetails.currency')}`, '')}
        disabled={readOnly || isDraft}
        onUpdate={$updateProgressModal.on}
        isHideUpdateProgress={readOnly || isAvgType}
        isAvgType={isAvgType}
        isDoneType={isDoneType}
        isChildProgress={goal.isChildProgress}
      />
      {$updateProgressModal.value && (
        <GoalProgressModal
          item={goal}
          onClose={$updateProgressModal.off}
          onSubmit={onUpdateProgress}
        />
      )}
    </>
  );
};

UserMainGoalInfo.propTypes = {
  goal: PropTypes.object,
  readOnly: PropTypes.bool,
};

export default React.memo(UserMainGoalInfo);
