export enum JOB_TEMPLATES_SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
  JOB_FAMILY_NAME_A_Z = 'JOB_FAMILY_NAME_A_Z',
  JOB_FAMILY_NAME_Z_A = 'JOB_FAMILY_NAME_Z_A',
  USED_MOST_LEAST = 'USED_MOST_LEAST',
  USED_LEAST_MOST = 'USED_LEAST_MOST',
  CREATED_NEW_OLD = 'CREATED_NEW_OLD',
  CREATED_OLD_NEW = 'CREATED_OLD_NEW',
}

export enum JOB_TEMPLATE_STATUS {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
}
