import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const TextQuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 10px;

  & .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .disabled {
    pointer-events: none;

    label {
      color: ${COLORS.INACTIVE} !important;
    }
  }

  & .editor-disabled {
    pointer-events: none;
    svg,
    svg * {
      stroke: ${COLORS.INACTIVE} !important;
    }
    p {
      color: ${COLORS.PLACEHOLDERS} !important;
    }

    & .ql-container {
      border: 1px solid ${COLORS.INACTIVE} !important;
    }
  }
`;

export const QuestionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  & > button {
    width: fit-content;
  }

  .buttons {
    display: flex;
    gap: 10px;
  }
`;

export const TextLabel = styled.label<{ hasError?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.16px;
  margin-bottom: 10px;
  ${(props) =>
    props.hasError
      ? css`
          color: ${COLORS.ACCENT_ERROR};
        `
      : css`
          color: ${COLORS.ICONS_PRIMARY};
        `}
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const OldSkillQuestionContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  flex-direction: column;
`;

export const QuestionWrapper = styled.div<{ $isDashboard?: boolean }>`
  width: ${({ $isDashboard }) => ($isDashboard ? '100%' : '750px')};
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 10px;
  height: fit-content;
  box-sizing: border-box;

  & .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .disabled {
    pointer-events: none;

    label {
      color: ${COLORS.INACTIVE} !important;
    }

    span {
      color: ${COLORS.INACTIVE} !important;
    }

    p {
      color: ${COLORS.INACTIVE} !important;
    }

    div {
      color: ${COLORS.INACTIVE} !important;
    }
  }
`;

export const RatingQuestionLabel = styled.label<{ hasError?: boolean }>`
  font-size: 14px;
  letter-spacing: -0.16px;
  margin-bottom: 10px;
  ${(props) =>
    props.hasError
      ? css`
          color: ${COLORS.ACCENT_ERROR};
        `
      : css`
          color: ${COLORS.ICONS_PRIMARY};
        `}
`;

export const RatingLabelContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  padding-top: 20px;
`;

export const SkillLabelContainer = styled.div<{ $isPlaceHolderExpectedLevel?: boolean }>`
  width: 100%;
  display: flex;
  gap: 10px;
  margin-bottom: 35px;
`;

export const FocusAreaHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-size: 12px;
  font-weight: 500;
  color: ${COLORS.SUBTEXT};
  border-bottom: solid 1px ${COLORS.BORDERS};
  padding-bottom: 2px;
`;

export const Scale = styled.div`
  display: flex;
  flex-direction: row;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  align-items: center;
  justify-content: space-between;

  div {
    max-width: 116px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const CareerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 11px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.ICONS_PRIMARY};
  margin-bottom: 14px;
`;

export const IconBackground = styled.div<{ backgroundColor?: string }>`
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  border-radius: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor || COLORS.COMPANY};
  opacity: 0.15;
  position: absolute;
`;

export const IconContainer = styled.div`
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  position: relative;
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
`;

export const Answers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const AllAnswersPerFocusArea = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
  gap: 11px;
`;

export const Comments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  color: ${COLORS.TEXT_MAIN};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin-top: 20px;
  line-height: normal;
`;

export const AllAnswers = styled.div`
  display: flex;
  width: 750px;
  margin-top: 30px;
  flex-direction: column;
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
  gap: 11px;
`;

export const DefinitionContainer = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};

  & > span {
    font-weight: 600;
  }
`;

export const JobNameContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;

  & > span {
    font-size: 14px;
    line-height: 1.86;
    letter-spacing: -0.16px;
    color: ${COLORS.TEXT_MAIN};
  }
`;

export const JobIconWrapper = styled.div`
  display: flex;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  background: color-mix(in srgb, ${COLORS.COMPANY}, transparent 60%);
  justify-content: center;
  align-items: center;
`;

export const FAContainer = styled.div<{ margin?: number }>`
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin ?? 10}px 0 0;
  padding: 16px;
  border-radius: 6px;
  min-height: 110px;
  background-color: ${COLORS.BG_LIST};
`;

export const ExpectedLevelTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.LABEL_GRAY};
`;

export const FocusAreaLabel = styled.div`
  font-size: 14px;
  color: ${COLORS.SUBTEXT};
`;

export const LevelViewButton = styled.span`
  margin: 10px 0 0;
  font-size: 12px;
  font-weight: 600;
  color: ${COLORS.COMPANY};

  :hover {
    cursor: pointer;
  }
`;

export const OldJobSkillQuestionLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.TEXT_MAIN};
  margin-bottom: 11px;
`;
