import styled from 'styled-components';

import { COLOR_PALETTE } from '~/styles';

export const PageTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
`;

export const PageDescription = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin: 0 0 20px;
`;
