import React from 'react';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ExpandMoreIcon from '~/components/Icons/ArrowButtonTall';
import RickTextView from '~/components/RickTextView';
import { TemplateCardWithFooter } from '~/components/SetupTools/components/TemplateBaseComponents';
import SvgIcon from '~/components/SvgIcon';
import { Header3, Header7 } from '~/components/UI/Typographics/headers';

import viewIcon from '~/assets/view-1.svg';

import { COLOR_PALETTE, COLORS } from '~/styles';

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  ${Header3} {
    margin: 0;
  }
`;

const ColoredBlock = styled.div`
  background: linear-gradient(
    56deg,
    ${(props) => props.darkGradient},
    ${(props) => props.lightGradient}
  );
  width: 58px;
  min-width: 58px;
  height: 70px;
  border-top-left-radius: 6px;
  ${(props) => !props.$open && 'border-bottom-left-radius: 6px;'}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorCounter = styled.div`
  color: ${COLOR_PALETTE.WHITE};
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
  margin-left: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  line-height: 1;
  justify-content: center;
  background: ${COLORS.ERROR};
  font-size: 10px;
  font-weight: bold;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 24px 12px 14px;
  width: 100%;
  align-items: center;
`;

const HeaderSection = styled.div`
  display: flex;
  width: 100%;
  outline: none;
  cursor: pointer;
  ${(props) => props.$open && `border-bottom: 1px solid ${COLORS.BG_PAGE};`}
`;

const HeaderText = styled.div`
  ${Header7} {
    color: ${COLORS.TEXT_SECONDARY};
    margin: 0;
    text-transform: uppercase;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const HeaderDescription = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  margin-bottom: 12px;
  color: ${COLOR_PALETTE.DARK_GRAY};
  & p {
    color: ${COLOR_PALETTE.DARK_GRAY};
  }
`;

const SectionInfo = styled.div`
  box-sizing: border-box;
  margin: 12px 30px 24px 20px;
`;

const ViewerText = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  margin-left: 10px;
  margin-right: 24px;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const ViewRow = styled.div`
  display: flex;
  align-items: center;
`;

const SectionCard = ({
  sectionIndex,
  sectionCount,
  changeActiveSection,
  title,
  description,
  isError,
  errorsCount = 0,
  theme,
  open,
  sectionProviderText,
}) => {
  return (
    <TemplateCardWithFooter $error={isError} $cropBorder>
      <CardContent>
        <HeaderSection
          onClick={() => {
            changeActiveSection(sectionIndex);
          }}
          role="button"
          tabIndex={0}
          onKeyDown={() => changeActiveSection(sectionIndex)}
          $open={open}
        >
          <ColoredBlock
            darkGradient={theme.darkGradient}
            lightGradient={theme.lightGradient}
            color={theme.color}
            $open={open}
          >
            <SvgIcon
              url={theme.icon}
              width={'22px'}
              height={'22px'}
              isDefaultColor
              defaultColor={theme.color}
            />
          </ColoredBlock>
          <HeaderContent>
            <HeaderText>
              <Header7>
                <Trans>Section</Trans> {sectionIndex + 1}/{sectionCount}
              </Header7>
              <HeaderTitle>
                <Header3>{title}</Header3>
                {errorsCount > 0 && <ErrorCounter>{errorsCount}</ErrorCounter>}
              </HeaderTitle>
            </HeaderText>
            {open ? (
              <ExpandMoreIcon size={24} style={{ transform: 'rotate(180deg)' }} />
            ) : (
              <ExpandMoreIcon size={24} />
            )}
          </HeaderContent>
        </HeaderSection>
        {open && (
          <SectionInfo>
            {description && (
              <HeaderDescription>
                <RickTextView html={description} />
              </HeaderDescription>
            )}
            {sectionProviderText && (
              <ViewRow>
                <SvgIcon
                  url={viewIcon}
                  width={'14px'}
                  height={'11px'}
                  isDefaultColor
                  defaultColor={COLOR_PALETTE.DARK_GRAY}
                />
                <ViewerText>{sectionProviderText}</ViewerText>
              </ViewRow>
            )}
          </SectionInfo>
        )}
      </CardContent>
    </TemplateCardWithFooter>
  );
};

SectionCard.propTypes = {
  open: PropTypes.bool,
  theme: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.func,
    color: PropTypes.string,
    // icon: PropTypes.node,
  }),
};

export default React.memo(SectionCard);
