import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { ICONS } from '~/components/Icon';

import { COLORS } from '~/styles';

export const STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  UPCOMING: 'upcoming',
  ACTIVE: 'active',
  SIGNING: 'signing',
  COMPLETED: 'completed',
  ARCHIVED: 'archived',
};

const STATUS_PROVIDER = {
  [STATUS.DRAFT]: {
    color: COLORS.STATUS_DRAFT,
    backgroundColor: COLORS.STATUS_DRAFT,
    backgroundByPercentage: 30,
    text: (i18n: I18n) => i18n._(t`Draft`),
  },
  [STATUS.PUBLISHED]: {
    color: COLORS.STATUS_PUBLISHED,
    backgroundColor: COLORS.STATUS_PUBLISHED,
    backgroundByPercentage: 8,
    text: (i18n: I18n) => i18n._(t`Published`),
  },
  [STATUS.UPCOMING]: {
    color: COLORS.STATUS_UPCOMING,
    backgroundColor: COLORS.STATUS_UPCOMING,
    backgroundByPercentage: 14,
    text: (i18n: I18n) => i18n._(t`Upcoming`),
  },
  [STATUS.ACTIVE]: {
    color: COLORS.STATUS_ACTIVE,
    backgroundColor: COLORS.STATUS_ACTIVE,
    backgroundByPercentage: 14,
    text: (i18n: I18n) => i18n._(t`Active`),
  },
  [STATUS.SIGNING]: {
    color: COLORS.STATUS_SIGNING,
    backgroundColor: COLORS.STATUS_SIGNING,
    backgroundByPercentage: 7,
    text: (i18n: I18n) => i18n._(t`Signing`),
  },
  [STATUS.COMPLETED]: {
    color: COLORS.STATUS_COMPLETED,
    backgroundColor: COLORS.STATUS_COMPLETED,
    backgroundByPercentage: 8,
    text: (i18n: I18n) => i18n._(t`Completed`),
    icon: ICONS.CHECKMARK,
  },
  [STATUS.ARCHIVED]: {
    color: COLORS.STATUS_ARCHIVED,
    backgroundColor: COLORS.ANY_OTHER_PRODUCTS,
    text: (i18n: I18n) => i18n._(t`Archived`),
    icon: ICONS.ARCHIVE_2,
  },
};

export { STATUS_PROVIDER };
