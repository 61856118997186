import { useState, useEffect } from 'react';

import type { ITabSliderItem } from '../types';

interface IUseTabSlider {
  items: ITabSliderItem[];
}

const useTabSlider = ({ items }: IUseTabSlider) => {
  const [currentItem, setCurrentItem] = useState<ITabSliderItem>(
    items.find((item) => item.isDefault) || items[0],
  );

  // the currentItem keeps a copy of the tab item and did not reload when the content was modified,
  // therefore I have added a useEffect on items to update the currentItem
  useEffect(() => {
    const key = currentItem.key;
    const newCurrentItem = items.find((item) => item.key === key);
    setCurrentItem(newCurrentItem || items.find((item) => item.isDefault) || items[0]);
    // eslint-disable-next-line
  }, [items]);

  return { currentItem, setCurrentItem };
};

export { useTabSlider };
