import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';

import { Banner } from '~/components/Banner';
import { ACTIONS_POSITION, BANNER_TYPES } from '~/components/Banner/types';
import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { DigitalMeeting } from '~/components/DigitalMeeting';
import DigitalMeetingLink from '~/components/DigitalMeetingLink';
import { IPlanCalendarEventForm } from '~/pages/Reviews/Modals/PlanCalendarEventModal/PlanCalendarEventForm/types';

import { USER_INTEGRATION_TYPES } from '~/constants';
import { useUserCalendarIntegration } from '~/hooks/useUserHasIntegration';

import type { UseFormReturn } from 'react-hook-form';

interface IntegrationSectionProps {
  formMethods: UseFormReturn<IPlanCalendarEventForm>;
  onSelect: (key: string, value: any) => void;
}

const IntegrationSection = ({ formMethods, onSelect }: IntegrationSectionProps) => {
  const { i18n } = useLingui();
  const { goToCalendarIntegrations } = useUserCalendarIntegration();

  const { watch } = formMethods;

  const calendarEvent = watch('calendarEvent');
  const calendarIntegration = watch('calendarIntegration');
  const selectedIncludeLinkMeeting = watch('selectedIncludeLinkMeeting');
  const hasIntegration = !isEmpty(calendarIntegration);

  return (
    <div>
      {calendarEvent ? (
        <DigitalMeetingLink event={calendarEvent} updateCalendarEvent={() => {}} />
      ) : (
        calendarIntegration && (
          <DigitalMeeting
            tippyProps={{
              content:
                calendarIntegration.type === USER_INTEGRATION_TYPES.azure &&
                !calendarIntegration.hasOnlineMeetingProvider
                  ? i18n._(
                      t`Your integration email account does not have access to create Microsoft Teams meetings`,
                    )
                  : calendarIntegration.type === USER_INTEGRATION_TYPES.azure &&
                    // @ts-ignore
                    calendarEvent?.linkMeeting
                  ? t`Microsoft Teams does not allow to remove meeting links once created`
                  : null,
            }}
            checked={selectedIncludeLinkMeeting}
            onChange={(value) => onSelect('selectedIncludeLinkMeeting', value)}
            calendarType={calendarIntegration.type}
            disabled={
              !calendarIntegration.hasOnlineMeetingProvider ||
              (calendarIntegration.type === USER_INTEGRATION_TYPES.azure &&
                // @ts-ignore
                !!calendarEvent?.linkMeeting)
            }
          />
        )
      )}
      {!calendarEvent && !hasIntegration && (
        <Banner
          type={BANNER_TYPES.WARNING}
          subTitle={i18n._(
            t`Your calendar is not connected with Learned. Your conversation will not be synchronized with your calendar or the calendars of the participants.`,
          )}
          actionsPosition={ACTIONS_POSITION.COLUMN}
          actions={
            <Button
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.SECONDARY}
              label={i18n._(t`Connect calendar`)}
              onClick={goToCalendarIntegrations}
            />
          }
        />
      )}
    </div>
  );
};

export { IntegrationSection };
