import styled from 'styled-components';

import { COLORS } from '~/styles';

export const DateRow = styled.div<{
  marginTop?: string;
  marginBottom?: string;
}>`
  display: flex;
  gap: 16px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0px')};

  label {
    color: ${COLORS.SUBTEXT};
  }
`;

export const SubTitleRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ExplanationText = styled.div<{
  marginTop?: string;
  marginBottom?: string;
}>`
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${COLORS.SUBTEXT};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '10px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '16px')};
`;
