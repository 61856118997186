import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getActivityCategories(filters = {}) {
  return cloudRequest(serverRoutes.activityCategories.getCategories, {}, filters);
}

export function getActivityCategory(id) {
  return cloudRequest(serverRoutes.activityCategories.getCategory(id));
}

export function createActivityCategory(name) {
  const data = { name };
  return cloudRequest(serverRoutes.activityCategories.createCategory, {}, data);
}

export function updateActivityCategory(id, name) {
  return cloudRequest(serverRoutes.activityCategories.updateCategory(id), {}, { name });
}

export function deleteActivityCategory(id) {
  return cloudRequest(serverRoutes.activityCategories.deleteCategory(id));
}

export function manageActivityCategories({ add = [], remove = [], update = [] }) {
  return cloudRequest(
    serverRoutes.activityCategories.manageCategories,
    {},
    { add, remove, update },
  );
}
