import styled from 'styled-components';

import Label from '~/components/Label';

import { COLORS } from '~/styles';

export const Container = styled.div`
  box-sizing: border-box;
`;

export const HeaderRow = styled.div`
  display: flex;
  gap: 10px;
  box-sizing: border-box;
`;

export const RoleLabel = styled(Label)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const HeaderText = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.LEARNING};
`;

export const CardsContainer = styled.div`
  box-sizing: border-box;
  padding-bottom: 10px;
  padding-right: 10px;
  display: flex;
  gap: 40px;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  -ms-overflow-style: none;

  &::webkit-scrollbar-button {
    display: none;
  }
`;

export const DottedCard = styled.div`
  border: 2px dotted ${COLORS.PLACEHOLDERS};
  padding: 74px 48px 74px 48px;
  box-sizing: border-box;
  border-radius: 6px;
  transform: translateY(6px);
  transition: all 300ms ease-out;
  background-color: ${COLORS.BG_PAGE};

  .browse-jobs {
    color: ${COLORS.PLACEHOLDERS};
  }

  &:hover {
    border: 0;
    background-color: ${COLORS.HOVER};
    transition: box-shadow;
    box-shadow: 0 2px 8px 0 ${COLORS.SHADOW};
    transform: translateY(0);
    transition: all 300ms ease-out;

    .browse-jobs {
      color: ${COLORS.COMPANY};
    }
  }
`;

export const InnerContainer = styled.div`
  width: 174px;
  height: 119px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`;

export const PlaceholderText = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.PLACEHOLDERS};
  margin-top: 10px;
`;
