import React from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import AvatarCard from '~/components/UI/AvatarCard';

import { COLOR_PALETTE, COLORS } from '~/styles';

const Wrapper = styled.div`
  margin-top: 24px;
  border-radius: 6px;
`;

const TopHeader = styled.div`
  padding: 12px 16px;
  background-color: ${COLOR_PALETTE.GRAY_MIDDLE};
  font-size: 14px;
  border-radius: 6px 6px 0 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.WHITE};
`;

const HeaderTitle = styled.div`
  float: left;
  font-weight: bold;
  margin-right: 5px;
`;

const Body = styled.div`
  border-radius: 0 0 6px 6px;
  border: solid 1px ${COLOR_PALETTE.GRAY_MIDDLE};
  background-color: #fff;
`;

const Header = styled.div`
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.63;
  color: ${COLOR_PALETTE.BLACK};
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_MIDDLE};
`;

const ParticipantsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 24px;
  gap: 24px;
`;

const ParticipantCardWrapper = styled.div`
  opacity: 0.76;
  border-radius: 6px;
  border: solid 1px ${COLOR_PALETTE.GRAY_MIDDLE};
  background-color: ${COLOR_PALETTE.WHITE};
  flex: 1;
`;

const ParticipantHeader = styled.div`
  padding: 12px 16px 16px 16px;
  border-radius: 6px 6px 0 0;
  background-color: ${COLORS.BG_PAGE};
`;

const ParticipantBody = styled.div`
  border-radius: 0 0 6px 6px;
  display: flex;
  flex-direction: column;
  padding: 20px 0 28px 0;
  align-items: center;
`;

const FakeButton = styled.div`
  padding: 16px;
  opacity: 0.5;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  letter-spacing: 0.7px;
  color: ${COLOR_PALETTE.WHITE};
  background-color: var(--company-color);
`;

const DigitallySignPreview = ({ i18n }) => {
  const getParticipantCard = (title) => {
    return (
      <ParticipantCardWrapper>
        <ParticipantHeader>
          <AvatarCard userId={null} userFullname={title} />
        </ParticipantHeader>
        <ParticipantBody>
          <FakeButton>
            <Trans>Complete conversation</Trans>
          </FakeButton>
        </ParticipantBody>
      </ParticipantCardWrapper>
    );
  };

  return (
    <Wrapper>
      <TopHeader>
        <HeaderTitle>
          <Trans>Complete conversation:</Trans>
        </HeaderTitle>
        <Trans>
          All participants and coaches will be asked to complete the conversation. Optionally, they
          can add a final comment..
        </Trans>
      </TopHeader>
      <Body>
        <Header>
          <Trans>Complete conversation</Trans>
        </Header>
        <ParticipantsContainer>
          {getParticipantCard(i18n._(t`Participant 1`))}
          {getParticipantCard(i18n._(t`Coach 1`))}
        </ParticipantsContainer>
      </Body>
    </Wrapper>
  );
};

export default withI18n()(DigitallySignPreview);
