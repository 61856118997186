import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Row = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 10px;
  margin: 14px 0 10px;
  color: ${COLORS.COMPANY};
`;

export const IndexWrapper = styled.div`
  height: 38px;
  width: 72px;
  align-items: center;
  display: flex;
  text-align: center;
  font-size: 14px;
  color: ${COLORS.ICONS_PRIMARY};
`;

export const InputGroups = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 8px;
  gap: 10px;
`;

export const IconWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  cursor: pointer;
`;

export const QuestionDescription = styled.div`
  font-size: 14px;
  line-height: 1.86;
  color: ${COLORS.INPUT_TITLE};

  & > span {
    font-weight: 600;
  }
`;

export const RatingLabels = styled.div`
  margin-top: 14px;
  display: flex;
  width: 100%;
  font-size: 14px;
  gap: 19px;
`;
