import React from 'react';

import styled from 'styled-components';

import { Icon, ICONS } from '~/components/Icon';
import TextArea from '~/components/TextArea';
import TextField from '~/components/TextField';

import { COLORS, COLOR_SET, COLOR_PALETTE } from '~/styles';

const TextHeader = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

const TextHeader2 = styled.h2`
  font-size: 18px;
  font-weight: bold;
  line-height: 0.89;
  margin: 0 0 16px 0;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

const TextHeader3 = styled.h3`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  margin: 7px 0 13px;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

const TextHeader4 = styled.h4`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin: 0 0 8px 0;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

const Description = styled.p`
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

const TallDescription = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 2;
  color: ${COLOR_SET.DARK_GRAY};
`;

const FieldGroup = styled.div`
  margin-bottom: ${(props) => props.marginBottom || '24px'};
  margin-top: ${(props) => props.marginTop || 'unset'};
`;

const FieldTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLOR_PALETTE.BLACK};
  margin-bottom: 18px;
`;

const FieldMediumTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  color: ${COLORS.TEXT_PRIMARY};
  margin-bottom: 8px;
`;

const FieldNotice = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${COLOR_SET.DARK_GRAY2};
  margin-bottom: 8px;
`;

const FieldMediumTitleAccent = styled(FieldMediumTitle)`
  font-weight: 600;
  line-height: normal;
  margin-bottom: 12px;
`;

const FieldSubTitle = styled.div`
  font-size: 10px;
  line-height: 1.6;
  color: ${COLORS.TEXT_PRIMARY};
  margin-bottom: 8px;
`;

const FieldWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  left: 8px;
`;

export const StyledTextField = styled(TextField)`
  padding: 4px 9px 4px 40px;
  height: 100%;

  &::placeholder {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: ${COLORS.BORDER_HARD};
  }
`;

const SearchField = ({ className, fieldClassName, ...props }) => (
  <FieldWrap className={className}>
    <StyledIcon icon={ICONS.SEARCH} color={COLORS.BORDER} />
    <StyledTextField type="text" {...props} className={fieldClassName} />
  </FieldWrap>
);

export {
  TextHeader,
  TextHeader2,
  TextHeader3,
  TextHeader4,
  Description,
  TallDescription,
  FieldGroup,
  FieldTitle,
  FieldMediumTitle,
  FieldMediumTitleAccent,
  FieldSubTitle,
  FieldNotice,
  TextField,
  TextArea,
  SearchField,
  StyledIcon,
};
