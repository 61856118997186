import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import Button from '~/components/Button';
import FeedbackIcon from '~/components/Icons/Feedback';
import Placeholder from '~/components/Placeholder';

import { GoalPlanRow } from './GoalPlanRow';
import QuestionDescription from './QuestionDescription';

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 16px;
`;

const GoalWrapper = styled.div`
  margin: 0 20px 20px;
`;

const GoalPlanBlock = ({
  goals,
  question,
  pdfView,
  currentUser,
  isUpdatePlanGoal,
  openUpdateGoalsModal,
  isLockedFeedback,
  review,
}) => {
  const { i18n } = useLingui();
  return (
    <GoalWrapper>
      {isEmpty(goals) ? (
        <Placeholder
          Icon={FeedbackIcon}
          title={i18n._(t`Nothing here yet`)}
          subTitle={i18n._(t`No answers to this question yet`)}
        />
      ) : (
        <>
          <QuestionDescription
            type={question.type}
            description={question?.description}
            pdfView={pdfView}
            padding="0 0 20px"
          />
          {goals.map((goal) => (
            <GoalPlanRow
              key={goal.id}
              goal={goal}
              question={question}
              review={review}
              pdfView={pdfView}
              currentUser={currentUser}
            />
          ))}
        </>
      )}
      {isUpdatePlanGoal && !pdfView && (
        <ButtonContainer>
          <Button
            type={Button.types.primaryBorder}
            label={i18n._(t`Update`)}
            onClick={openUpdateGoalsModal}
            disabled={isLockedFeedback}
            height={32}
          />
        </ButtonContainer>
      )}
    </GoalWrapper>
  );
};

export default GoalPlanBlock;
