import React from 'react';

import { ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import StringInfinite from '~/components/StringInfinite';
import { Placeholder } from '~/pages/CoachMembersPage/components/MemberRow';

import routes from '~/constants/routes';
import { COLOR_PALETTE, COLOR_SET } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Ambition = styled.div`
  max-width: 108px;
  max-height: 32px;
  background: ${COLOR_SET.TURQUOISE};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.WHITE};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding: 4px 8px;
  box-sizing: border-box;
`;

const MoreLabel = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #454c66;
  margin-left: 5px;
`;

const List = styled.ul`
  margin: 0;
  padding-left: 18px;
  word-break: break-word;

  & > li {
    color: ${COLOR_PALETTE.DARK_GRAY};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    margin-bottom: 4px;
  }

  & > li:last-child {
    margin-bottom: 0;
  }
`;

const AmbitionsColumn = ({ userId, items, role = ROLES.COACH, onlyText = false }) => {
  const { i18n } = useLingui();
  const history = useHistory();
  const path = routes.USER_PUBLIC_SKILL_PASSPORT.build(
    { role },
    { userId, isBackPath: true, hash: 'career' },
  );

  return onlyText ? (
    <List>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </List>
  ) : (
    <Wrapper onClick={() => history.push(path)}>
      {isEmpty(items) ? (
        <Placeholder>{i18n._(t`No ambition(s)`)}</Placeholder>
      ) : (
        <>
          <Ambition>
            <StringInfinite>{items[0]}</StringInfinite>
          </Ambition>
          {items.length - 1 > 0 && <MoreLabel>{`+ ${items.length - 1}`}</MoreLabel>}
        </>
      )}
    </Wrapper>
  );
};

export default React.memo(AmbitionsColumn);
