import React from 'react';

import { Trans } from '@lingui/macro';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import RickTextView from '~/components/RickTextView';
import Avatar from '~/components/UI/Avatar';
import Divider from '~/components/UI/Divider';

import { getUsers } from '~/selectors/baseGetters';
import { COLOR_PALETTE } from '~/styles';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';
import getUserFullName from '~/utils/getUserFullName';

const NotesWrapper = styled.div`
  border-radius: 6px;
  border: solid 1px ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  background-color: ${COLOR_PALETTE.WHITE};
  flex: 1;
  display: flex;
  flex-direction: column;
  @media print {
    break-inside: avoid;
  }
`;

const NotesHeader = styled.div`
  padding: 16px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.63;
  color: ${COLOR_PALETTE.BLACK};
`;

const NotesContainer = styled.div`
  flex: 1;
`;
const NoteWrapper = styled.div`
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  height: fit-content;
  padding: 16px;
  @media print {
    break-inside: avoid;
  }
`;

const NoteHeader = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
`;

const NoteText = styled(RickTextView)`
  font-size: 14px;
  line-height: 1.71;
  color: ${COLOR_PALETTE.BLACK};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
`;

const NoteAuthorName = styled.div`
  margin: 0 12px;
  flex: 1;
`;

const NoteTimeStamp = styled.div`
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.67;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const ReviewNotesPDF = ({ review }) => {
  const { comments } = review;
  const users = useSelector(getUsers);

  const renderNote = (note, index) => {
    return (
      <NoteWrapper key={index}>
        <NoteHeader>
          <Avatar userId={note.author} size={24} />
          <NoteAuthorName>{getUserFullName(users[note.author])}</NoteAuthorName>
        </NoteHeader>
        <NoteText key={index} html={note.comment} />
        <NoteTimeStamp>
          {convertToTimeString(get(note, 'meta.createdDate'), TIME_FORMATS.CLASSIC_FULL)}
        </NoteTimeStamp>
      </NoteWrapper>
    );
  };

  return comments.length > 0 ? (
    <NotesWrapper>
      <NotesHeader>
        <Trans>Shared notes</Trans>
      </NotesHeader>
      <Divider />
      <NotesContainer>{comments.map(renderNote)}</NotesContainer>
    </NotesWrapper>
  ) : (
    <div />
  );
};

export default ReviewNotesPDF;
