import React, { ChangeEvent, useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import styled from 'styled-components';

import HandPointingIcon from '~/components/Icons/HandPointing';
import PaginationBar from '~/components/PaginationBar';
import Placeholder from '~/components/Placeholder';
import SearchSelectButton from '~/components/SearchSelectButton';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SearchField } from '~/components/Text';

import { ListViewRow } from './ListViewRow';
import { ListViewRowHeader } from './ListViewRowHeader';

import { usePagination } from '~/hooks/usePagination';
import { COLORS } from '~/styles';

import type { IListItem, INineGridReviewTheme } from '../types';

interface IListComponentProps {
  items: IListItem[];
  themes: INineGridReviewTheme[];
  isSecondReviewSelected?: boolean;
  isLoading: boolean;
}

const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  margin-right: 12px;
  width: 340px;
`;

const SortBySelect = styled(SearchSelectButton)`
  width: 160px;
`;

const Wrapper = styled.div`
  position: relative;

  & .placeholder {
    margin: 184px auto;
    width: 255px;
  }
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  box-sizing: content-box;
  position: relative;
  transform: rotateX(180deg);

  &::-webkit-scrollbar {
    height: 8px;
    position: absolute;
    top: 0;
  }

  &::-webkit-scrollbar-track {
    background: ${COLORS.BG_PAGE};
    border-radius: 100px;
    margin: 0 24px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--company-color);
    border-radius: 100px;
  }

  &::-webkit-scrollbar:hover {
    cursor: pointer;
  }

  & table {
    transform: rotateX(180deg);
  }
`;

const FilterRow = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 48px);
`;

const Table = styled.table`
  transform: rotateX(180deg);
  border-collapse: collapse;
  width: 100%;
`;

const PAGE_SIZE = 10;

const ListViewComponent = ({
  items,
  themes,
  isLoading,
  isSecondReviewSelected,
}: IListComponentProps) => {
  const { i18n } = useLingui();
  const { pagination, changePagination, changePageSize } = usePagination(PAGE_SIZE);
  const [search, setSearch] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);
  const [paginatedItems, setPaginatedItems] = useState(filteredItems);

  useEffect(() => {
    setPaginatedItems(filteredItems.slice(pagination.skip, pagination.index * pagination.limit));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify({ filteredItems, pagination })]);

  const filterSearch = (item: IListItem) => {
    if (search) {
      return (`${item.user.firstName} ${item.user.lastName}` || '')
        .toLowerCase()
        .includes(search.toLowerCase());
    }

    return item;
  };

  const NINE_GRID_SORT_BY = {
    A_Z: {
      id: 'a_z',
      key: 'a_z',
      name: 'A-Z Alphabetic',
      label: (i18n: any) => i18n._(t`A-Z Alphabetic`),
    },
    CONVERSATION_1_KPI_1: {
      id: `conversation_1${themes[0].id}`,
      key: 'conversation_1_kpi_1',
      name: 'A-Z Alphabetic',
      label: (i18n: any) => i18n._(t`REV.1 ${themes[0].name.toUpperCase()}`),
    },
    CONVERSATION_1_KPI_2: {
      id: `conversation_1${themes[1].id}`,
      key: 'conversation_1_kpi_2',
      name: 'A-Z Alphabetic',
      label: (i18n: any) => i18n._(t`REV.1 ${themes[1].name.toUpperCase()}`),
    },
    CONVERSATION_2_KPI_1: {
      id: `conversation_2${themes[0].id}`,
      key: 'conversation_2_kpi_1',
      name: 'A-Z Alphabetic',
      label: (i18n: any) => i18n._(t`REV.2 ${themes[0].name.toUpperCase()}`),
    },
    CONVERSATION_2_KPI_2: {
      id: `conversation_2${themes[1].id}`,
      key: 'conversation_2_kpi_2',
      name: 'A-Z Alphabetic',
      label: (i18n: any) => i18n._(t`REV.2 ${themes[1].name.toUpperCase()}`),
    },
  };

  const [sortedBy, setSortedBy] = useState([NINE_GRID_SORT_BY.A_Z.id]);

  // Sort by
  useEffect(() => {
    switch (sortedBy?.[0]) {
      case NINE_GRID_SORT_BY.A_Z.id:
        setFilteredItems(orderBy(filteredItems, (item) => item.user.firstName));
        break;
      case NINE_GRID_SORT_BY.CONVERSATION_1_KPI_1.id:
        setFilteredItems(
          orderBy(filteredItems, (item) => item?.primaryReview?.xTheme.average || 0, ['desc']),
        );
        break;
      case NINE_GRID_SORT_BY.CONVERSATION_1_KPI_2.id:
        setFilteredItems(
          orderBy(filteredItems, (item) => item?.primaryReview?.yTheme.average || 0, ['desc']),
        );
        break;
      case NINE_GRID_SORT_BY.CONVERSATION_2_KPI_1.id:
        setFilteredItems(
          orderBy(filteredItems, (item) => item?.secondaryReview?.xTheme.average || 0, ['desc']),
        );
        break;
      case NINE_GRID_SORT_BY.CONVERSATION_2_KPI_2.id:
        setFilteredItems(
          orderBy(filteredItems, (item) => item?.secondaryReview?.yTheme.average || 0, ['desc']),
        );
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify({ items, sortedBy })]);

  useEffect(() => {
    setFilteredItems(items.filter(filterSearch));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify({ search, items })]);

  return (
    <>
      <FilterRow>
        <SearchFieldWrapper
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.currentTarget.value)}
          value={search}
          placeholder={i18n._(t`Search`)}
          style={{
            borderRadius: '6px',
            fontSize: '14px',
            fontWeight: 600,
          }}
        />
        {/* @ts-ignore */}
        <SortBySelect
          title={i18n._(t`Sort by`)}
          checkedList={sortedBy}
          handleChange={(e: any) => setSortedBy(e ? e : [])}
          options={Object.values(NINE_GRID_SORT_BY).filter((item) => {
            if (!isSecondReviewSelected) {
              return !['conversation_2_kpi_1', 'conversation_2_kpi_2'].includes(item.key);
            }

            return true;
          })}
          minWidth="80px"
          isRadio
          noHeader
        />
      </FilterRow>
      <ShowSpinnerIfLoading loading={isLoading}>
        <Wrapper>
          <TableWrapper>
            <Table>
              <thead>
                <ListViewRowHeader
                  themes={themes}
                  isSecondReviewSelected={isSecondReviewSelected}
                />
              </thead>
              {!isEmpty(paginatedItems) && (
                <tbody>
                  {paginatedItems?.map((item, index) => (
                    <ListViewRow
                      key={item?.primaryReview?.id || index}
                      item={item}
                      isSecondReviewSelected={isSecondReviewSelected}
                    />
                  ))}
                </tbody>
              )}
            </Table>
          </TableWrapper>
          {!isEmpty(paginatedItems) ? (
            <PaginationBar
              pagination={pagination}
              changePagination={changePagination}
              changePageSize={changePageSize}
              count={filteredItems.length}
              paginationIndexAlternatives={[
                { id: 10, label: 10 },
                { id: 20, label: 20 },
                { id: 30, label: 30 },
              ]}
              noShadow
              noBorder
              noTopBorder
              showCount
            />
          ) : (
            <Placeholder
              className="placeholder"
              Icon={() => <HandPointingIcon size={32} />}
              subTitle={i18n._(t`Select review(s), values and members to set up your 9-grid.`)}
            />
          )}
        </Wrapper>
      </ShowSpinnerIfLoading>
    </>
  );
};

export { ListViewComponent };
