import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import Modal from '~/components/Modal';
import SelectSkills from '~/components/SelectSkillsModal/SelectSkills';
import SvgIcon from '~/components/SvgIcon';

import CloseIcon from '~/assets/ic-close-18-px.svg';

const HeaderActions = styled.div`
  display: flex;
`;

const CloseIconWrapper = styled.div`
  margin-left: 13px;
  cursor: pointer;
`;

const ContentWrapper = styled.div``;

const INITIAL_STATE = {
  selectedSkills: [],
  selectedLevels: {},
  loading: false,
};

class SkillsLevelsModal extends Component {
  static defaultProps = {
    selectedSkills: [],
    selectedLevels: {},
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount = () => {
    const { selectedSkills, selectedLevels } = this.props;
    this.setState({ selectedSkills, selectedLevels });
  };

  closeModal = () => {
    this.setState({ ...INITIAL_STATE });
    this.props.closeModal();
  };

  toggleSkill =
    (skill) =>
    (e, { level, isUpdate } = {}) => {
      e.stopPropagation();
      const { selectedSkills, selectedLevels } = this.state;

      if (isUpdate) {
        // update level in selectedSkill
        let newSelectedLevels = selectedLevels ? { ...selectedLevels } : {};
        newSelectedLevels[skill.id] = level;
        this.setState({
          selectedLevels: newSelectedLevels,
        });
      } else {
        // update selectedSkills
        let newSelectedSkills = selectedSkills ? [...selectedSkills] : [];
        let newSelectedLevels = selectedLevels ? { ...selectedLevels } : {};
        let index = newSelectedSkills.map((u) => u.id).indexOf(skill.id);
        if (index !== -1) {
          newSelectedSkills = newSelectedSkills.filter((_, i) => i !== index);
          delete newSelectedLevels[skill.id];
        } else {
          newSelectedSkills.push(skill);
          newSelectedLevels[skill.id] = level;
        }

        this.setState({
          selectedSkills: newSelectedSkills,
          selectedLevels: newSelectedLevels,
        });
      }
    };

  saveSkills = () => {
    const { selectedSkills, selectedLevels } = this.state;

    this.props.saveSelectedSkills(selectedSkills, selectedLevels);
    this.props.closeModal();
  };

  render() {
    const { loading, selectedSkills, selectedLevels } = this.state;

    const { i18n, withoutLevels } = this.props;

    return (
      <Modal
        title={i18n._(t`Select skills`)}
        onClose={this.closeModal}
        width={625}
        minWidth={625}
        minHeight="600px"
        contentStyles={{ background: '#fff', height: '100%', padding: '24px' }}
        headerStyles={{ borderBottom: 'solid 1px #ecedf0', padding: '0 22px' }}
        hideHeaderClose={true}
        hideFooter={true}
        showDivider={false}
        headerActions={() => (
          <HeaderActions>
            <Button
              styles={{
                height: '40px',
                marginLeft: '10px',
                textTransform: 'uppercase',
                padding: '0px 15px',
                fontSize: '12px',
                minWidth: '81px',
              }}
              label={i18n._(t`Save`)}
              loading={loading}
              disabled={isEmpty(selectedSkills)}
              type="white"
              onClick={this.saveSkills}
            />
            <CloseIconWrapper onClick={this.closeModal}>
              <SvgIcon width="16px" height="16px" url={CloseIcon} />
            </CloseIconWrapper>
          </HeaderActions>
        )}
      >
        <ContentWrapper>
          <SelectSkills
            selectedSkills={selectedSkills}
            selectedLevels={selectedLevels}
            toggleSkill={this.toggleSkill}
            withoutLevels={withoutLevels}
            isShowPreloadedSkills
          />
        </ContentWrapper>
      </Modal>
    );
  }
}

export default withI18n()(withRouter(SkillsLevelsModal));
