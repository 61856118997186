import React, { useState, useEffect } from 'react';

import { IReviewLog } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useParams } from 'react-router';

import { COLUMNS } from './columns';
import { Wrapper, TableListStyled } from './design';
import { IFilters } from './filters';

import useDebounce from '~/hooks/useDebounce';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import { fetchReviewLogs } from '~/services/reviews';

const PAGE_SIZE = 50;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };
const LS_KEY = LS_KEYS.LS_REVIEW_CYCLE_DASHBOARD_LOGS;

const initialFilters: IFilters = {
  pagination: DEFAULT_PAGINATION,
};

const LogsTab = () => {
  const { i18n } = useLingui();
  const params: Record<string, string | undefined> = useParams();
  const reviewId = params.reviewId as string;
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<IReviewLog[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentFilters, setCurrentFilters] = useLocalStorage<IFilters>(LS_KEY, initialFilters);
  const { isShowFilters: _isShowFilters, ...debCurrentFilters } = useDebounce(currentFilters, 300); // isShowFilters does not affect on reFetch

  const fetchData = async () => {
    setIsLoading(true);

    const {
      data: { logs, total },
    } = await fetchReviewLogs(reviewId, {
      options: {
        skip: currentFilters.pagination.skip,
        limit: currentFilters.pagination.limit,
      },
    });

    setItems(logs);
    setTotalCount(total);
    setIsLoading(false);
  };

  // change filters fetch
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [JSON.stringify(debCurrentFilters)]);

  return (
    <Wrapper>
      <TableListStyled
        data={items}
        columns={COLUMNS}
        paginationProps={{
          pagination: currentFilters.pagination,
          changePagination: ({
            skip,
            limit,
            index,
          }: {
            skip: number;
            limit: number;
            index: number;
          }) =>
            setCurrentFilters({
              ...currentFilters,
              pagination: { ...currentFilters.pagination, skip, limit, index },
            }),
          totalCount,
        }}
        isLoading={isLoading}
        placeholderProps={{
          noResultText: i18n._(t`No review logs`),
          emptyStateText: i18n._(t`Let's invite somebody to the review! `),
        }}
      />
    </Wrapper>
  );
};

export { LogsTab };
