import { ProductName } from '@learned/constants';

export enum AUTO_SCROLL {
  LABELS = 'labels',
}

export interface IProductSettingsProps {
  name: ProductName;
  closeModal: () => void;
  autoScroll?: AUTO_SCROLL;
}
