import React from 'react';

import NewReviewReport from '~/components/NewReviewReport';
import ReviewReport from '~/components/ReviewReport';

const ReviewContent = ({ review, userId, users, isYourReview, userFirstName }) => {
  return review.isRateWithLevel ? (
    <NewReviewReport
      review={review}
      viewerId={userId}
      users={users}
      userFirstName={userFirstName}
      isReadOnly
      pdfView
      isYourReview={isYourReview}
    />
  ) : (
    <ReviewReport review={review} isReadOnly pdfView />
  );
};

export default ReviewContent;
