import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function uploadSingle(file, name) {
  return cloudRequest(
    serverRoutes.files.uploadSingle,
    {},
    { name, file },
    { sendAsFormData: true },
  );
}

export function uploadMultiple(files, names) {
  return cloudRequest(
    serverRoutes.files.uploadMultiple,
    {},
    { names, files },
    { sendAsFormData: true },
  );
}
