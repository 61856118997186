import React, { ChangeEvent } from 'react';

import { FocusAreaType } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import uniqBy from 'lodash/uniqBy';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Icon, ICONS } from '~/components/Icon';

import type { ISelectedSkillTemplate } from '~/@types/job';
import { useMultiLangString } from '~/hooks/useMultiLangString';

import { SkillsSelector } from '../components/SkillsSelector';
import {
  StyledSearchField,
  SuggestedSkillsWrapper,
  StyledSearchFieldWrapper,
  Title,
  Header,
  Content,
  Actions,
  Subtitle,
  Footer,
  TitleContainer,
  HeaderActionContainer,
} from '../design';

import type { IForm, SearchSkillProps } from '../types';
import type { ISkill, ISkillTemplate } from '@learned/types';

function SearchSkill<T extends IForm>({
  onClose,
  skillCategoryName,
  skillCategoryId,
  searchInputValue,
  setSearchInputValue,
  skillNames,
  skills,
  defaultFocusAreaLevel,
  isDefaultFocusAreaLevelEnabled,
  setIsSelectLevelAndFocusAreasVisible,
  setSource,
  formMethods,
  setSkillTemplates,
  setSkills,
}: SearchSkillProps<T>) {
  const { i18n } = useLingui();
  const { setValue, watch } = formMethods;
  const getMultiLangString = useMultiLangString();

  // @ts-ignore
  const watchSkills = watch(`skills.${skillCategoryId}.skills`) as ISelectedSkillTemplate['skills'];

  const addSkill = (source: ISkillTemplate | ISkill) => {
    const focusAreas = source.focusAreas;
    if (defaultFocusAreaLevel && isDefaultFocusAreaLevelEnabled) {
      let level = defaultFocusAreaLevel;

      if (focusAreas.length < defaultFocusAreaLevel) {
        level = focusAreas.length;
      }

      if (focusAreas.length) {
        // @ts-ignore
        if (source?.company) {
          setSkills?.((skill) => uniqBy(skill.concat(source as ISkill), ({ id }) => id));
        } else {
          setSkillTemplates?.((prevSkillTemplates) =>
            uniqBy(prevSkillTemplates.concat(source as ISkillTemplate), ({ id }) => id),
          );
        }
        // @ts-ignore
        setValue(`skills.${skillCategoryId}.skills`, [
          ...watchSkills
            .filter(
              // @ts-ignore
              ({ skillTemplate, skill }) => ![skill, skillTemplate].includes(source.id),
            )
            .filter(
              // @ts-ignore
              ({ skillTemplate, skill }) =>
                (skillTemplate && (source as ISkill)?.skillTemplate !== skillTemplate) ||
                (skill && (source as ISkill)?.id !== skill),
            ),
          {
            selectedFocusAreas: [
              {
                type: FocusAreaType.SELECT_LEVEL,
                level: level - 1,
              },
            ],
            // @ts-ignore
            ...(source?.company ? { skill: source.id } : { skillTemplate: source.id }),
          },
        ]);
      }
    }
  };

  return (
    <>
      <Header>
        <TitleContainer>
          <Title>
            <Trans>Add skill</Trans>
          </Title>
          <Subtitle>
            <Trans>To skill category: {skillCategoryName}</Trans>
          </Subtitle>
        </TitleContainer>
        <HeaderActionContainer>
          <Button size={ButtonSize.MEDIUM} onClick={onClose} variant={ButtonVariant.CLOSE} />
        </HeaderActionContainer>
      </Header>
      <Content>
        <StyledSearchFieldWrapper>
          <StyledSearchField
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchInputValue(e.currentTarget.value)
            }
            value={searchInputValue}
            placeholder={i18n._(t`Search skills`)}
            style={{
              width: '100%',
              borderRadius: '10rem',
              fontSize: '14px',
            }}
          />
          {!isNil(skillNames) && (
            <SuggestedSkillsWrapper>
              {!isEmpty(skillNames) ? (
                skillNames.map((skillTemplate) => (
                  <button
                    key={skillTemplate.id}
                    className="item"
                    onClick={() => {
                      if (!isDefaultFocusAreaLevelEnabled) {
                        setIsSelectLevelAndFocusAreasVisible(true);
                        setSource(skillTemplate);
                      } else {
                        addSkill(skillTemplate);
                        onClose();
                      }
                    }}
                  >
                    <span>{getMultiLangString(skillTemplate.name)}</span>
                    <Icon icon={ICONS.NEXT} className="icon" />
                  </button>
                ))
              ) : (
                <div className="notFound">
                  <Trans>No results...</Trans>
                </div>
              )}
            </SuggestedSkillsWrapper>
          )}
        </StyledSearchFieldWrapper>

        <SkillsSelector
          title={i18n._(t`Recommended skills for this job`)}
          skills={skills}
          watchSkills={watchSkills}
          onSelectSkill={(skill) => {
            if (!isDefaultFocusAreaLevelEnabled) {
              setIsSelectLevelAndFocusAreasVisible(true);
              setSource(skill);
            } else {
              addSkill(skill);
              onClose();
            }
          }}
        />
      </Content>
      <Footer>
        <Actions>
          <Button
            label={i18n._(t`Cancel`)}
            type="button"
            variant={ButtonVariant.TEXT_PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={onClose}
          />
        </Actions>
      </Footer>
    </>
  );
}

export { SearchSkill };
