import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { UseFormReturn } from 'react-hook-form';

import type { ISectionState } from '~/components/SideBar/SectionStateHook';

import { SubHeader } from './SubHeader';
import { SummaryField } from './SummaryField';

import { ExplanationText } from '../../TimelineSection/design';
import { SectionGrid, SectionTitle } from '../design';

import type { IReviewCycleForm } from '../../../types';

interface SettingsSummaryProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewCycleForm>;
}

const SettingsSummary = ({ formMethods, sectionState }: SettingsSummaryProps) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;

  const navigateToSection = () => sectionState.setCurrentSection(2);

  const getOnOffStatus = (value: boolean) => {
    return value ? i18n._(t`ON`) : i18n._(t`OFF`);
  };

  return (
    <>
      <SubHeader title={i18n._(t`Settings`)} onEdit={navigateToSection} />
      <>
        <SectionTitle marginBottom="16px">
          <Trans>General</Trans>
        </SectionTitle>
        <SectionGrid>
          <SummaryField
            value={getOnOffStatus(watch('settings.isDigitalSign'))}
            label={i18n._(t`Sign digitally`)}
          />
          <SummaryField
            value={getOnOffStatus(watch('settings.isCoachesAskedToScheduleReview'))}
            label={i18n._(t`Coaches schedule review`)}
          />
          <SummaryField
            value={getOnOffStatus(watch('settings.isCalibrate'))}
            label={i18n._(t`Calibration`)}
          />
          <SummaryField
            value={getOnOffStatus(watch('settings.isAutoArchive'))}
            label={i18n._(t`Archive review cycle automatically`)}
          />
        </SectionGrid>
      </>

      <>
        <SectionTitle marginBottom="16px" marginTop="32px">
          <Trans>Privacy</Trans>
        </SectionTitle>
        <ExplanationText>
          {!watch('privacy.isAllEmployeeCoachesCanSeeReview')
            ? i18n._(t`Employees, guests and coaches selected for coach review can see the review.`)
            : i18n._(
                t`Employees, guests and all current and future coaches of the employee can view the review.`,
              )}
        </ExplanationText>
      </>

      <>
        <SectionTitle marginBottom="16px" marginTop="32px">
          <Trans>Task notifications</Trans>
        </SectionTitle>
        <SectionGrid>
          <SummaryField
            value={getOnOffStatus(watch('notifications.isOnTaskStartDate'))}
            label={i18n._(t`On start date`)}
          />
          <SummaryField
            value={getOnOffStatus(watch('notifications.isOn7DaysBeforeTaskDeadline'))}
            label={i18n._(t`7 days before deadline`)}
          />
          <SummaryField
            value={getOnOffStatus(watch('notifications.isOnTaskDeadline'))}
            label={i18n._(t`On the day of the deadline`)}
          />
        </SectionGrid>
      </>
    </>
  );
};

export { SettingsSummary };
