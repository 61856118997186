import React from 'react';

import { ICompany } from '@learned/types';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';

import Hint from '~/components/Hint';
import SettingBlock from '~/components/ProfileSettingsComponents/MainDashboard/SettingBlock';
import SwitchYesNo from '~/components/SwitchYesNo';

import { checkModuleIntegrations } from '~/selectors/baseGetters';

import {
  BlockHeaderRow,
  HeaderRowLabel,
  BlockRow,
  RowName,
  RowDescription,
  RowAction,
} from '../design';

const SecuritySettings = ({
  company,
  updateForceMFA,
}: {
  company: ICompany;
  updateForceMFA: (force_mfa: boolean) => Promise<void>;
}) => {
  const { i18n } = useLingui();
  const isIntegrationsModuleEnabled = useSelector(checkModuleIntegrations);

  const securitySettings = [
    {
      key: 'force_mfa',
      title: i18n._(t`Multi-factor authentication`),
      description: i18n._(t`Enforce multi-factor authentication for your employees`),
      value: company.force_mfa,
      onChange: updateForceMFA,
      disabled: !isIntegrationsModuleEnabled,
    },
  ];

  return (
    // @ts-ignore
    <SettingBlock
      title={i18n._(t`Multi-factor Authentication`)}
      subTitle={i18n._(t`Force multi factor authentication for all users in your account.`)}
      contentHeader={
        <BlockHeaderRow>
          <HeaderRowLabel>{i18n._(t`Module`)}</HeaderRowLabel>
          <div />
          <HeaderRowLabel>{i18n._(t`Enabled`)}</HeaderRowLabel>
        </BlockHeaderRow>
      }
    >
      {securitySettings.map((setting) => (
        <BlockRow key={setting.key}>
          <RowName>{setting.title}</RowName>
          <RowDescription>
            {setting.description}
            {setting.disabled && (
              // @ts-ignore
              <Hint>
                <Trans>
                  This is a premium feature. Please contact Learned to discuss your payment plan.
                </Trans>
              </Hint>
            )}
          </RowDescription>
          <RowAction>
            <SwitchYesNo
              // @ts-ignore
              width={116}
              value={setting.value}
              onChange={setting.onChange}
              disabled={setting.disabled}
            />
          </RowAction>
        </BlockRow>
      ))}
    </SettingBlock>
  );
};

export { SecuritySettings };
