import React, { useEffect, useMemo, useRef, useState } from 'react';

import { CATEGORY_SKILL_RATING_KEYS, QUESTION_TYPES } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import uniq from 'lodash/uniq';
import styled from 'styled-components';

import CategoriesSection from '~/components/CategoriesSection';
import FeedbackIcon from '~/components/Icons/Feedback';
import Placeholder from '~/components/Placeholder';
import { TemplateCardWithFooter } from '~/components/SetupTools/components/TemplateBaseComponents';
import SvgIcon from '~/components/SvgIcon';
import { Header3 } from '~/components/UI/Typographics/headers';
import { attachInfoForRatings } from '~/pages/UserCareer/utils';

import JobProfileReportTabs from './JobProfileReportTabs';
import JobProfileSkillsList from './JobProfileSkillsList';
import NewSpiderReview from './NewSpiderReview';
import QuestionName from './QuestionName';
import { getFeedbackRatings } from './utils';

import toggleCloseIcon from '~/assets/toggle-close.svg';
import toggleOpenIcon from '~/assets/toggle-open.svg';

import { RATING_TYPES, REQUEST_STATUSES } from '~/constants';
import { GIVE_FEEDBACK_LEGEND } from '~/constants/reviews';
import useBoolState from '~/hooks/useBoolState';
import { getSkillCoverage } from '~/services/ratings';
import { COLOR_PALETTE } from '~/styles';

const CardContent = styled.div`
  display: flex;
  padding: 16px 20px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  min-height: 81px;
  box-sizing: border-box;

  ${Header3} {
    margin: 0;
    font-weight: 600;
    line-height: 1.75;
    margin-top: 5px;
  }
`;

const QuestionContent = styled.div`
  padding: 0 20px 20px;
`;

const QuestionCounter = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  text-transform: uppercase;
  margin-bottom: 2px;
`;

const Question = styled.div`
  display: block;
  @media print {
    ${(props) => props.$pdfView && 'break-inside: avoid;'};
  }
`;

const ReportRow = styled.div`
  display: flex;
  justify-content: space-between;

  ${Header3} {
    line-height: 1.13;
    margin-top: 0;
  }
`;

const SpiderWrapper = styled.div`
  box-sizing: border-box;
`;

const TextColumn = styled.div``;

const TABS = {
  list: 'list',
  spider: 'spider',
};

const JobProfileQuestionCard = ({
  pdfView,
  isOpen,
  indexCurrent,
  indexTotal,
  isReadOnly,
  jobProfile,
  questions,
  hideCoaches,
  hidePeers,
  hideSelf,
  review,
  viewerId,
  users,
  isLockedFeedback,
  handleOnRatingUpdated,
  sectionIndex,
  isRightSideBarOpen,
}) => {
  const { i18n } = useLingui();
  const $open = useBoolState(isOpen);
  const [activeTab, setActiveTab] = useState(TABS.list);
  const [skillCoverage, setSkillCoverage] = useState(null);
  const ref = useRef();

  const toggleItem = () => {
    $open.value ? $open.off() : $open.on();
  };

  const tabs = [
    {
      key: TABS.list,
      label: i18n._(t`List`),
    },
    {
      key: TABS.spider,
      label: i18n._(t`Spider`),
    },
  ];

  // show only rates for shared requests
  const sharedRequestsUsers = (review?.requests || [])
    .filter(
      (r) =>
        r.status === REQUEST_STATUSES.SHARED.key || r.status === REQUEST_STATUSES.CALIBRATE.key,
    )
    .map((r) => (r.toEmail ? r.toEmail : r.toUser));

  const feedbackRatings = getFeedbackRatings({
    ratings: review.ratings,
    reviewUser: review.createdFor,
    roleSkills: jobProfile?.skills,
    question: { type: QUESTION_TYPES.JOB_PROFILE },
    hideSelf,
    hidePeers,
    hideCoaches,
    sharedRequestsUsers,
  });

  const questionsForList = questions.filter(({ skill } = {}) =>
    jobProfile?.skills?.includes(skill?.id),
  );

  const skillCategories = questionsForList.reduce((arr, { skill }) => {
    return [...arr, ...(skill.categories || [])];
  }, []);

  const categoriesUniq = uniq(
    skillCategories
      .map((categoryId) =>
        (review?.skillCategories || []).find(
          (category) => categoryId === category.id || categoryId === category.categoryId, // for company skills // for learned skills, they have categoryId = soft_skills
        ),
      )
      .filter((c) => c),
  );

  useEffect(() => {
    const fetchData = async (jobProfileId) =>
      await getSkillCoverage({ jobProfileId, reviewId: review.id });
    if (jobProfile?.id) {
      fetchData(jobProfile.id).then((res) => {
        setSkillCoverage(res.data);
      });
    }
  }, [jobProfile, review.id]);

  const total = useMemo(
    () =>
      skillCoverage?.total ? attachInfoForRatings({ ratings: skillCoverage?.total, i18n }) : null,
    [i18n, skillCoverage?.total],
  );

  const { categories, legend } = useMemo(() => {
    const categories = skillCoverage?.categories.map((category) => {
      return {
        ...category,
        ratings: attachInfoForRatings({ ratings: category.ratings, i18n }),
      };
    });

    const legend = [
      {
        key: CATEGORY_SKILL_RATING_KEYS.AVG_SELF_RATING,
        color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.SELF_RATING].color,
        label: GIVE_FEEDBACK_LEGEND[RATING_TYPES.SELF_RATING].label(i18n),
      },
      {
        key: CATEGORY_SKILL_RATING_KEYS.AVG_PEERS_RATING,
        color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.OTHER_RATING].color,
        label: GIVE_FEEDBACK_LEGEND[RATING_TYPES.OTHER_RATING].label(i18n),
      },
      {
        key: CATEGORY_SKILL_RATING_KEYS.AVG_COACHES_RATING,
        color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.COACH_RATING].color,
        label: GIVE_FEEDBACK_LEGEND[RATING_TYPES.COACH_RATING].label(i18n),
      },
      {
        key: CATEGORY_SKILL_RATING_KEYS.AVG_EXPECTED_LEVEL,
        color: COLOR_PALETTE.GRAY_MIDDLE,
        label: i18n._(t`Expected level for role`),
      },
    ].filter(({ key }) => {
      let isFilter = false;
      categories &&
        Object.values(categories).map((item) => {
          if (item.ratings?.[key].progress) {
            isFilter = true;
          }
        });

      return isFilter;
    });

    return { categories, legend };

    // eslint-disable-next-line
  }, [i18n, jobProfile.skills, skillCoverage?.categories]);

  return (
    <Question ref={ref} $pdfView={pdfView}>
      <TemplateCardWithFooter $noShadow={pdfView}>
        <CardContent onClick={toggleItem} $isActiv={$open.value}>
          <TextColumn>
            {indexTotal && (
              <QuestionCounter>
                {i18n._(t`Question ${indexCurrent + 1}/${indexTotal}`)}
              </QuestionCounter>
            )}
            <QuestionName
              question={{ type: QUESTION_TYPES.JOB_PROFILE }}
              isReadOnly={isReadOnly}
              jobProfileName={jobProfile?.name}
              pdfView={pdfView}
            />
          </TextColumn>
          {!pdfView && (
            <div>
              <SvgIcon
                width="24px"
                height="24px"
                url={$open.value ? toggleOpenIcon : toggleCloseIcon}
              />
            </div>
          )}
        </CardContent>
        {$open.value &&
          (isEmpty(feedbackRatings) ? (
            <Placeholder
              Icon={FeedbackIcon}
              title={i18n._(t`Nothing here yet`)}
              subTitle={i18n._(t`No answers to this question yet`)}
            />
          ) : (
            <QuestionContent>
              <CategoriesSection
                title={i18n._(t`Skills & Competencies`)}
                description={i18n._(
                  t`Your average skill match with the required skills for this role.`,
                )}
                legend={legend}
                categories={categories}
                total={total}
              />
              {!pdfView && (
                <ReportRow>
                  <Header3>
                    <Trans>The report</Trans>
                  </Header3>
                  <JobProfileReportTabs tabs={tabs} activeTab={activeTab} onChange={setActiveTab} />
                </ReportRow>
              )}
              {pdfView ? (
                <>
                  <SpiderWrapper>
                    <NewSpiderReview
                      review={review}
                      sectionIndex={sectionIndex}
                      pdfView={pdfView}
                      role={jobProfile}
                    />
                  </SpiderWrapper>
                  <JobProfileSkillsList
                    feedbackRatings={feedbackRatings}
                    legend={legend}
                    categoriesUniq={categoriesUniq}
                    questionsForList={questionsForList}
                    review={review}
                    pdfView={pdfView}
                    viewerId={viewerId}
                    users={users}
                    isReadOnly={isReadOnly}
                    isLockedFeedback={isLockedFeedback}
                    handleOnRatingUpdated={handleOnRatingUpdated}
                    hideSelf={hideSelf}
                    hidePeers={hidePeers}
                    hideCoaches={hideCoaches}
                    isRightSideBarOpen={isRightSideBarOpen}
                  />
                </>
              ) : activeTab === TABS.list ? (
                <JobProfileSkillsList
                  feedbackRatings={feedbackRatings}
                  legend={legend}
                  categoriesUniq={categoriesUniq}
                  questionsForList={questionsForList}
                  review={review}
                  pdfView={pdfView}
                  viewerId={viewerId}
                  users={users}
                  isReadOnly={isReadOnly}
                  isLockedFeedback={isLockedFeedback}
                  handleOnRatingUpdated={handleOnRatingUpdated}
                  hideSelf={hideSelf}
                  hidePeers={hidePeers}
                  hideCoaches={hideCoaches}
                  isRightSideBarOpen={isRightSideBarOpen}
                />
              ) : (
                <SpiderWrapper>
                  <NewSpiderReview
                    review={review}
                    sectionIndex={sectionIndex}
                    pdfView={pdfView}
                    role={jobProfile}
                  />
                </SpiderWrapper>
              )}
            </QuestionContent>
          ))}
      </TemplateCardWithFooter>
    </Question>
  );
};

export default JobProfileQuestionCard;
