import React, { Component } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import appStoreBadge from '~/assets/app-store-badge.svg';
import example1 from '~/assets/example_mobile_app_1.png';
import example2 from '~/assets/example_mobile_app_2.png';

import { COLOR_PALETTE } from '~/styles';

const Wrapper = styled.div`
  background: ${COLOR_PALETTE.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const Content = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Title = styled.div`
  font-size: 20px;
  color: 'black';
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 1.43;
  color: #333333;
  margin-bottom: 20px;
  margin-top: 24px;
`;

const DownloadButtons = styled.div`
  width: 100%;
  display: flex;
`;

const DownloadButton = styled.div`
  width: 50%;
`;

const GooglePlayImage = styled.img`
  width: 100%;
  margin-left: -12px;
`;

const AppStoreLink = styled.a`
  width: 100%;
  display: flex;
  margin-left: -32px;
  justify-content: center;
`;

const AppStoreImage = styled.img`
  padding-top: 10px;
  width: 78%;
`;

const Screenshots = styled.div`
  width: 100%;
  margin-top: 36px;
  display: flex;
`;

const Screenshot = styled.img`
  width: 50%;
  display: block;
`;

class MobileNotSupported extends Component {
  render() {
    const { i18n } = this.props;
    return (
      <Wrapper>
        <Content>
          <Main>
            <Title>
              <Trans>Download the Learned app</Trans>
            </Title>
            <Description>
              <Trans>
                Download the Learned app to exchange feedback and track the feedback you have
                received.
              </Trans>
            </Description>
            <DownloadButtons>
              <DownloadButton>
                <a href="https://play.google.com/store/apps/details?id=com.learnednative&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                  <GooglePlayImage
                    alt={i18n._(t`Get it on Google Play`)}
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  />
                </a>
              </DownloadButton>
              <DownloadButton>
                <AppStoreLink href="https://apps.apple.com/us/app/learned-io/id1509184709?ls=1">
                  <AppStoreImage alt={i18n._(t`Download on the App Store`)} src={appStoreBadge} />
                </AppStoreLink>
              </DownloadButton>
            </DownloadButtons>
            <Screenshots>
              <Screenshot src={example1} alt={i18n._(t`screenshot`)} />
              <Screenshot src={example2} alt={i18n._(t`screenshot`)} />
            </Screenshots>
          </Main>
        </Content>
      </Wrapper>
    );
  }
}

export default withI18n()(withRouter(MobileNotSupported));
