import React from 'react';

import { TASK_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { ICON_SIZES, ICONS } from '~/components/Icon';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { StatusIcon } from '~/pages/PassportPage/design';
import { ICell } from '~/pages/Reviews/DashboardCycle/tabs/Participation/PerTeamSection/types';

import { CellViewTaskStatus } from './CellViewTaskStatus';

import { COLORS } from '~/styles';

import { CellViewWrapper } from '../designs';

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Value = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: ${COLORS.TEXT_HOVER};
`;

const WarningWrapper = styled.div`
  display: flex;
  margin-left: 10px;
`;

type ICellViewSignedProps = {
  cell: ICell;
};

const CellViewSigned = ({ cell }: ICellViewSignedProps) => {
  const { i18n } = useLingui();
  const isCompleted = cell.all && cell.completed && cell.all === cell.completed;
  return (
    <CellViewWrapper>
      {isCompleted ? (
        <CellViewTaskStatus
          columnId={cell.columnId}
          status={TASK_STATUS.COMPLETED}
          warningTooltip={i18n._(t`Not agreed`)}
          withWarning={cell.withWarning}
        />
      ) : (
        <Row>
          <Value>{cell.value}</Value>
          {Boolean(cell.withWarning) && (
            <Tooltip tooltip={i18n._(t`Not agreed`)} size={TOOLTIP_SIZES.MEDIUM}>
              <WarningWrapper>
                <Row>
                  <StatusIcon
                    icon={ICONS.WARNING_2}
                    size={ICON_SIZES.SMALL}
                    color={COLORS.ACCENT_WARNING}
                  />
                </Row>
              </WarningWrapper>
            </Tooltip>
          )}
        </Row>
      )}
    </CellViewWrapper>
  );
};

export { CellViewSigned };
