import React, { ReactNode } from 'react';

import { GOAL_PROGRESS_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';
import { FieldGroup, FieldTitle } from '~/components/Text';

import IsDoneIcon from '~/assets/noun-done-1543597.svg';

import { COLOR_PALETTE, COLOR_SET } from '~/styles';

type TProgressData = {
  progressType: string;
  progressDetails?: { min: number; max: number; currency: null } | {};
};

interface IMeasurement {
  value: string;
  onChange: (progressData: TProgressData) => void;
  itemWidth?: string;
}

interface IProgressItem {
  value: string;
  symbol?: string;
  icon?: string;
  name: (i18n: any) => ReactNode;
}

const ProgressItems = styled.div<{ itemsSize?: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.itemsSize}, 1fr);
  grid-column-gap: 8px;
`;

const ProgressItem = styled.div<{ $isActive?: boolean; itemWidth?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-height: 48px;
  height: 48px;
  ${(props) => props.itemWidth && `width: ${props.itemWidth};`}
  box-sizing: border-box;
  border-radius: 28px;
  border: 1px solid ${(props) => (props.$isActive ? 'var(--company-color)' : COLOR_SET.GRAY_BLUE)};
`;

const ProgressSymbol = styled.div<{ $isActive?: boolean }>`
  font-size: 20px;
  font-weight: ${(props) => (props.$isActive ? '600' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: ${(props) => (props.$isActive ? 'var(--company-color)' : '#6d7aa6')};
`;

const ProgressName = styled.div<{ $isActive?: boolean }>`
  font-size: 14px;
  font-weight: ${(props) => (props.$isActive ? '600' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => (props.$isActive ? 'var(--company-color)' : '#6d7aa6')};
  margin-left: 8px;
`;

const StyledFieldTitle = styled(FieldTitle)`
  margin-bottom: 15px;
`;

const ITEMS = [
  {
    value: GOAL_PROGRESS_TYPES.IS_DONE,
    name: (i18n: any) => i18n._(t`Done or not done`),
    icon: IsDoneIcon,
  },
  {
    value: GOAL_PROGRESS_TYPES.PERCENTAGE,
    name: (i18n: any) => i18n._(t`Percentages`),
    symbol: '%',
  },
];

const Measurement = ({ value, onChange, itemWidth }: IMeasurement) => {
  const { i18n } = useLingui();

  const renderItem = (item: IProgressItem) => {
    const isActive: boolean = item.value === value;

    return (
      <ProgressItem
        key={item.value}
        $isActive={isActive}
        itemWidth={itemWidth}
        onClick={() =>
          onChange({
            progressType: item.value,
            progressDetails: GOAL_PROGRESS_TYPES.PERCENTAGE
              ? {
                  min: 0,
                  max: 100,
                  currency: null,
                }
              : {},
          })
        }
      >
        {item.icon && (
          <SvgIcon
            isDefaultColor={!isActive}
            defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            width="20px"
            height="20px"
            url={item.icon}
          />
        )}
        {item.symbol && <ProgressSymbol $isActive={isActive}>{item.symbol}</ProgressSymbol>}
        <ProgressName $isActive={isActive}>{item.name(i18n)}</ProgressName>
      </ProgressItem>
    );
  };

  return (
    // @ts-ignore
    <FieldGroup marginBottom="33px">
      <StyledFieldTitle>
        <Trans>Progress measurement</Trans>
      </StyledFieldTitle>
      <ProgressItems itemsSize={_.size(ITEMS)}>{ITEMS.map(renderItem)}</ProgressItems>
    </FieldGroup>
  );
};

export default React.memo(Measurement);
