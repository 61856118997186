import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import { getAppTheme, getCurrentProducts, getUser } from '~/selectors/baseGetters';
import { COLORS, PRIMARY_COLOR } from '~/styles';

const Banner = styled.div<{ $companyColor?: string }>`
  display: flex;
  align-items: center;
  padding: 7px 34px 7px 32px;
  background-image: linear-gradient(
    88deg,
    ${COLORS.COMPANY} 49%,
    ${(props) => (props.$companyColor !== PRIMARY_COLOR ? COLORS.COMPANY : COLORS.SECONDARY)} 99%
  ); // make second color the same, if company color is not default color.
  font-size: 14px;
  min-height: 32px;
  color: ${COLORS.WHITE};
`;

const TrialTextWrapper = styled.span`
  display: flex;
  flex: auto;
  align-items: center;
  gap: 10px;
`;

const TrialLinkWrapper = styled.span`
  display: flex;
  gap: 30px;
  align-items: center;
`;

const Link = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

interface Product {
  status: string;
  lastStatusChanged: string;
}

const TrialBanner = () => {
  const history = useHistory();
  const { i18n } = useLingui();
  const user = useSelector(getUser);
  const products: Record<string, Product> = useSelector(getCurrentProducts);
  const location = useLocation();
  const appTheme = useSelector(getAppTheme);

  const dateDiff = (statusChangedOn: string) => {
    const current = moment();
    const trialEnding = moment(statusChangedOn).add(14, 'days');

    /* adding 1 to the difference to show the trial available days
       ex: when trial starts it shows 13 days instead 14 days
    */
    return trialEnding.diff(current, 'days') + 1;
  };

  const trialProducts = Object.entries(products)
    .filter(([_, v]) => v.status === 'trialActive')
    .map(([k, v]) => ({
      name: k,
      status: v.status,
      lastStatusChanged: v.lastStatusChanged,
      daysToEnd: dateDiff(v.lastStatusChanged),
    }));

  const leastNoOfDaysToEnd = Math.min(...trialProducts.map((obj) => obj.daysToEnd));
  const productWithLeastDaysOfTrial = trialProducts.filter(
    (obj) => obj.daysToEnd === leastNoOfDaysToEnd,
  );

  const handleRedirect = (element: string) => {
    const secondArg =
      element === 'link'
        ? { isBackPath: false }
        : {
            isBackPath: false,
            query: { product: productWithLeastDaysOfTrial[0].name, openTrialModal: true },
          };
    // @ts-ignore
    const route = routes.SETTINGS_MODULES.build({ role: ROLES.ADMIN }, secondArg);
    history.push(route);
  };

  // check if the user is already on billing page
  const isOnPlanPage = location.pathname.includes(
    // eslint-disable-next-line
    // @ts-ignore
    routes.SETTINGS_MODULES.build({ role: ROLES.ADMIN }),
  );
  {
    if (trialProducts.length > 0 && user.isAdmin) {
      return (
        <Banner $companyColor={appTheme.color}>
          <TrialTextWrapper>
            <Icon icon={ICONS.LOG} size={ICON_SIZES.LARGE} />
            <Trans>Trial {leastNoOfDaysToEnd < 0 ? 0 : leastNoOfDaysToEnd} days left</Trans>
          </TrialTextWrapper>
          {!isOnPlanPage && (
            <TrialLinkWrapper>
              <Link onClick={() => handleRedirect('link')}>
                <Trans>View plan</Trans>
              </Link>
              <Button
                label={i18n._(t`Request upgrade`)}
                size={ButtonSize.MEDIUM}
                variant={ButtonVariant.PRIMARY}
                onClick={() => handleRedirect('button')}
              />
            </TrialLinkWrapper>
          )}
        </Banner>
      );
    } else {
      return <></>;
    }
  }
};

export { TrialBanner };
