import styled from 'styled-components';

import { Icon } from '~/components/Icon';

import { COLORS } from '~/styles';

const MenuCtr = styled.div`
  background-color: ${COLORS.BG_PAGE};
  min-width: 257px;
  max-width: 400px;
  width: 300px;
  height: 100vh;
  border-right: 1px solid ${COLORS.BORDERS};
  display: flex;
  flex-direction: column;
  gap: 2px;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-size: 14px;
  margin-top: 32px;
  height: 46px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex: none;
  padding: 0 20px 0 16px;
  gap: 5px;
  justify-content: space-between;
`;

const SearchCtr = styled.div`
  flex: none;
  height: auto;
  position: relative;
  box-sizing: border-box;
  margin: 0 0 10px;
  padding: 0 16px;
`;

const SearchPlaceholder = styled.input`
  border: none;
  border-radius: 100px;
  background-color: ${COLORS.WHITE};
  height: 32px;
  padding-left: 40px;
  padding-right: 15px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${COLORS.BORDERS};
  ::placeholder {
    color: ${COLORS.BORDERS};
  }
`;

const SearchIcon = styled(Icon)`
  width: 20px;
  height: auto;
  position: absolute;
  left: 31px;
  top: 50%;
  transform: translateY(-50%);
  color: ${COLORS.BORDERS};
`;

const TitleInner = styled.div`
  font-weight: 600;
  color: ${COLORS.TEXT_MAIN};
`;

const Collapse = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: ${COLORS.TIPPY_BACKGROUND};
  &:hover {
    color: var(--company-color);
  }
`;

const CollapseIcon = styled(Icon)`
  width: 12px;
  height: 12px;
  transform: rotate(275deg);
  margin-bottom: -2px;
  &.active {
    transform: rotate(90deg);
  }
`;

const MenuItemCtr = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: auto;
  overflow-y: auto;
  padding: 0 16px 16px 16px;
`;

const MenuItem = styled.button<{ isSelected: boolean }>`
  all: unset;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: ${({ isSelected }) =>
    isSelected ? 'var(--company-color)' : COLORS.BG_ELEMENTS};
  color: ${({ isSelected }) => (isSelected ? COLORS.WHITE : COLORS.TEXT_HOVER)};
  padding: 12px 14px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20ch;
  }

  &.member {
    font-weight: 500;
    background-color: ${COLORS.BG_ELEMENTS};
    color: var(--company-color);
  }
  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? 'var(--company-color)' : COLORS.WHITE)};
    color: ${({ isSelected }) => (isSelected ? COLORS.WHITE : COLORS.TEXT_HOVER)};
  }
  &.member:hover {
    background-color: ${COLORS.BG_ELEMENTS};
    color: var(--company-color);
  }
`;

const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const MemberCtr = styled.button<{ isSelected: boolean }>`
  all: unset;
  cursor: pointer;
  padding: 12px 14px;
  font-size: 14px;
  border-radius: 6px;
  background-color: ${({ isSelected }) => (isSelected ? 'var(--company-color)' : COLORS.BG_PAGE)};
  color: ${({ isSelected }) => (isSelected ? COLORS.WHITE : COLORS.TEXT_HOVER)};
  display: flex;
  align-items: center;
  gap: 5px;
  box-sizing: border-box;
  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? 'var(--company-color)' : COLORS.WHITE)};
    color: ${({ isSelected }) => (isSelected ? COLORS.WHITE : COLORS.TEXT_HOVER)};
    text-shadow: 0 0 0 ${COLORS.TEXT_HOVER}, 0 0 0 ${COLORS.TEXT_HOVER};
  }
`;

const MemberIconCtr = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${COLORS.CAREER_LIGHT};
  flex: none;
`;

const MemberGrayIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${COLORS.INACTIVE};
  flex: none;
`;

const MemberNameCtr = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
`;

const ChevronIcon = styled(Icon)`
  width: 12px;
  height: 12px;
  transform: rotate(90deg);
  &.active {
    transform: rotate(0deg);
    color: ${COLORS.TIPPY_BACKGROUND};
  }
`;

const Footer = styled.div`
  font-size: 14px;
  padding: 0 14px;
  box-sizing: border-box;
  flex: none;
  cursor: pointer;
  min-height: 54px;
  display: flex;
  align-items: center;
  padding: 0 4px 0 16px;
  display: flex;
  gap: 10px;
  align-items: center;
  &:hover {
    color: var(--company-color);
  }
`;

const InviteMemberCtr = styled.button`
  font-size: 14px;
  padding: 0 14px;
  box-sizing: border-box;
  flex: none;
  color: ${COLORS.TIPPY_BACKGROUND};
  cursor: pointer;
  min-height: 48px;
  display: flex;
  align-items: center;
  padding: 0 4px 0 12px;
  display: flex;
  gap: 10px;
  align-items: center;
  &:hover {
    color: var(--company-color);
  }
`;

const PlusIcon = styled(Icon)`
  width: 12px;
  height: 12px;
  padding-left: 20px;
  flex: none;
`;

export {
  MenuCtr,
  Title,
  SearchCtr,
  SearchPlaceholder,
  SearchIcon,
  TitleInner,
  Collapse,
  CollapseIcon,
  MenuItemCtr,
  MenuItem,
  MenuItemWrapper,
  MemberCtr,
  MemberIconCtr,
  MemberNameCtr,
  ChevronIcon,
  MemberGrayIcon,
  Footer,
  PlusIcon,
  InviteMemberCtr,
};
