import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import FocusLayout from '~/layouts/FocusLayout';
import GoalTask from '~/pages/GoalTask';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('goals', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.GOAL_TASK.routePath(ROLES.USER)}
        exact
        path={routes.GOAL_TASK.routePath(ROLES.USER)}
        component={GoalTask}
        allowedRoles={[ROLES.USER]}
        layout={FocusLayout}
      />,
    ],
    coach: [],
    admin: [],
  },
  routesPublic: [],
});
