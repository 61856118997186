import React, { useState } from 'react';

import { GOAL_TYPES } from '@learned/constants';
import { Trans } from '@lingui/macro';
import { isEmpty, map } from 'lodash';
import slice from 'lodash/slice';

import { ICONS, Icon } from '~/components/Icon';
import { TeamsModal } from '~/components/Modals/TeamsModal';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';
import { UserAvatar } from '~/components/UserAvatar';
import { UserAvatarGroup } from '~/components/UserAvatar/UserAvatarGroup';

import {
  GeneralBlockWrapper,
  GoalName,
  GeneralBlockRow,
  GrayTextRow,
  GrayText,
  Counter,
  NameContainer,
} from './design';

import { COLORS } from '~/styles';
import convertToTimeString from '~/utils/convertToTimeString';

import type { IGoal, ITeam } from '@learned/types';

const GoalGeneralBlock = ({
  goal,
  isOwner,
  noTopMargin = false,
  hideTitleOverflow = false,
}: {
  goal: IGoal;
  isOwner: boolean;
  noTopMargin: boolean;
  hideTitleOverflow?: boolean;
}) => {
  const [showTeamsModal, setShowTeamsModal] = useState<boolean>(false);

  const groupOptions = {
    isCounterClickable: true,
  };

  const avatarOptions = {
    ...(isOwner &&
      [GOAL_TYPES.BUSINESS, GOAL_TYPES.PERSONAL].includes(goal.type as GOAL_TYPES) && {
        customName: <Trans>(You)</Trans>,
      }),
  };

  const renderTeams = () => {
    const teamsLength = (goal?.teams || [])?.length;
    const additionalTeamsCount = teamsLength - 3;

    const teamsToDisplay = slice(goal?.teams as ITeam[], 0, 3)
      .map((team) => team?.name)
      .filter((t) => t);

    const restOfTeamsToDisplay = slice(goal?.teams as ITeam[], 3, goal?.teams?.length)
      .map((team) => team?.name)
      .filter((t) => t);

    const allTeamsToDisplay = map(goal?.teams as ITeam[], 'name');

    const teamsToDisplayString = teamsToDisplay.join(', ');
    const allTeamsString = allTeamsToDisplay.join(', ');
    const restOfTeamsToDisplayString = restOfTeamsToDisplay.join(', ');

    const isTeamsToDisplayStringLonger = teamsToDisplayString.length > 30;
    const textToDisplay = isTeamsToDisplayStringLonger
      ? `${teamsToDisplayString.substring(0, 30)}...`
      : teamsToDisplayString;

    return !isEmpty(teamsToDisplay) ? (
      additionalTeamsCount > 0 ? (
        <GrayText>
          {textToDisplay}
          <ToolTip
            tooltip={isTeamsToDisplayStringLonger ? allTeamsString : restOfTeamsToDisplayString}
            placement={TOOLTIP_PLACEMENTS.BOTTOM}
          >
            <Counter
              onClick={() => setShowTeamsModal(true)}
            >{` +${additionalTeamsCount} `}</Counter>
          </ToolTip>
        </GrayText>
      ) : (
        <ToolTip tooltip={allTeamsString} placement={TOOLTIP_PLACEMENTS.BOTTOM}>
          <GrayText>{textToDisplay}</GrayText>
        </ToolTip>
      )
    ) : null;
  };

  return (
    <GeneralBlockWrapper noTopMargin={noTopMargin}>
      <GoalName $hideOverflow={hideTitleOverflow}>{goal.name}</GoalName>
      <GeneralBlockRow>
        <NameContainer>
          {goal.owners?.length === 1 ? (
            <UserAvatar userId={goal.owners[0]} options={avatarOptions} />
          ) : (
            <UserAvatarGroup
              users={((goal.owners as string[]) || [])?.map((item: string) => ({ userId: item }))}
              options={groupOptions}
              isNewModal
            />
          )}
        </NameContainer>
        {goal.type === GOAL_TYPES.TEAM && (goal?.teams || [])?.length > 0 && (
          <GrayTextRow>{renderTeams()}</GrayTextRow>
        )}
        <GrayTextRow>
          <GrayText marginRight="13px">
            <Trans>Deadline:</Trans>
          </GrayText>
          {/* @ts-ignore */}
          <Icon icon={ICONS.CALENDAR} size={14} color={COLORS.TIPPY_BACKGROUND} />
          <GrayText isThin marginLeft="8px">
            {goal.deadline ? convertToTimeString(goal.deadline) : <Trans>N/A</Trans>}
          </GrayText>
        </GrayTextRow>
      </GeneralBlockRow>
      {showTeamsModal && (
        <TeamsModal onClose={() => setShowTeamsModal(false)} teams={goal.teams as ITeam[]} />
      )}
    </GeneralBlockWrapper>
  );
};

export { GoalGeneralBlock };
