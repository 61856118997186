export enum NOTIFICATION_TYPES {
  CALENDAR_INTEGRATION_DISCONNECTED = 'calendar_integration_disconnected',
  REQUEST_RTFEEDBACK = 'request_rtfeedback',
  REQUEST_REVIEW = 'request_review',
  COACH_REVIEW_REQUEST = 'coach_request_review',
  REQUEST_CONVERSATION = 'request_conversation',
  CONVERSATION_PLANNED = 'conversation_planned',
  CONVERSATION_COMPLETE_REMINDER = 'conversation_complete_reminder',
  CONVERSATION_SCHEDULE_REMINDER = 'conversation_schedule_reminder',
  CONVERSATION_USER_COMPLETED = 'conversation_user_completed',
  CONVERSATION_COACH_COMPLETED = 'conversation_coach_completed',
  CONVERSATION_COACH_UNCOMPLETED = 'conversation_coach_uncompleted',
  GOAL_CREATED = 'goal_created',
  GOAL_TASK_REMINDER = 'goal_task_reminder',
  GOAL_TASK_DEADLINE_REMINDER = 'goal_task_deadline_reminder',
  GOAL_USER_COMPLETED = 'goal_user_completed',
  ASK_FEEDBACK_TASK_DEADLINE_REMINDER = 'ask_feedback_task_deadline_reminder',
  GIVE_FEEDBACK_TASK_DEADLINE_REMINDER = 'give_feedback_task_deadline_reminder',
  ASK_FEEDBACK_TASK_REMINDER = 'ask_feedback_task_reminder',
  GIVE_FEEDBACK_TASK_REMINDER = 'give_feedback_task_reminder',
  FEEDBACK_PROVIDED = 'feedback_provided',
  RTFEEDBACK_PROVIDED = 'rt_feedback_provided',
  REVIEW_INVITE = 'review_invite',
  TEAM_INVITE = 'team_invite',
  REVIEW_NOMINATE_REMINDER = 'review_nominate_reminder',
  REVIEW_FEEDBACK_REMINDER = 'review_feedback_reminder',
  REVIEW_COACH_REMINDER = 'review_coach_reminder',
  REVIEW_PEER_REMINDER = 'review_peer_reminder',
  REVIEW_SELF_FEEDBACK_REMINDER = 'review_self_feedback_reminder',
  REVIEW_USER_SELF_COMPLETED = 'review_user_self_completed',
  REVIEW_CONVERSATION_SET_DATE = 'review_conversation_set_date',
  REVIEW_CONVERSATION_UPDATE_DATE = 'review_conversation_update_date',
  REVIEW_CONVERSATION_REMINDER = 'review_conversation_reminder',
  REVIEW_DIGITAL_SIGN_REMINDER = 'review_digital_sign_reminder',
  REVIEW_DIGITAL_SIGN = 'review_digital_sign',
  REVIEW_SELF_REVIEW_START_DATE = 'review_self_review_start_date',
  REVIEW_NOMINATE_START_DATE = 'review_nominate_start_date',
  REVIEW_PLAN_CONVERSATION_START_DATE = 'review_plan_conversation_start_date',
  REVIEW_DIGITAL_SIGN_START_DATE = 'review_digital_sign_start_date',
  REVIEW_PEER_START_DATE = 'review_peer_start_date',
  CONVERSATION_COMPLETED = 'conversation_completed',
  USER_PATH_REMINDER = 'user_path_reminder',
  USER_PATH_CREATED = 'user_path_created',
  ACTIVITY_ASSIGNED = 'activity_assigned',
  ACTIVITY_USER_COMPLETED = 'activity_user_completed',
  COACH_CALIBRATED_INPUT_SHARED = 'coach_calibrated_input_shared',
  SURVEY_REMINDER = 'survey_reminder',
  TRIAL_EXPIRED = 'trial_expired',
}
