import React, { useState } from 'react';

import { Locals_all } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Modal from '~/components/Modal';
import { IAutoCompleteJobTemplate } from '~/pages/SuperAdminDashboard/SuperAdminJobTemplateForm/types';

import { CreateFromLuca } from './Templates/CreateFromLuca';

import { SUPER_ADMIN_LANGUAGES } from '~/constants/languages';
import { useGenericLanguageState } from '~/hooks/useLanguageState';
import { LS_KEYS } from '~/hooks/useLocalStorage';
import { generateJobTemplate } from '~/services/jobTemplates';

type CreateJobTemplateModalProps = {
  onClose?(): void;
  jobName: string;
  onClickManually?(): void;
  onClickUsingAI?(data: IAutoCompleteJobTemplate): void;
};

const CreateJobTemplateFromLucaModal = ({
  onClose,
  jobName,
  onClickManually,
  onClickUsingAI,
}: CreateJobTemplateModalProps) => {
  const { i18n } = useLingui();

  const [loading, setLoading] = useState(false);

  const languageState = useGenericLanguageState({
    defaultLanguages: SUPER_ADMIN_LANGUAGES,
    primaryLanguage: SUPER_ADMIN_LANGUAGES[0],
    localStorageKey: LS_KEYS.LS_SUPER_ADMIN_LANGUAGES,
  });

  const handleAICreation = async () => {
    setLoading(true);

    const locales = languageState.defaultLanguages.map((item) => item.locale);

    const { data }: { data: IAutoCompleteJobTemplate } = await generateJobTemplate(
      jobName,
      locales as Locals_all[],
    );

    onClickUsingAI?.(data);
    setLoading(false);
  };

  return (
    <Modal
      showDivider={false}
      centerModal
      contentStyles={{ padding: '24px 32px', overflow: 'visible' }}
      width={500}
      borderRadius={6}
      hideFooter
      isHideHeader
    >
      <CreateFromLuca
        title={i18n._(t`Create a job`)}
        subtitle={jobName}
        description={i18n._(t`Auto generate your Job with Luca AI?`)}
        loadingMessage={i18n._(t`Generating your job, this might take a minute…`)}
        onClose={onClose}
        loading={loading}
        onClickManually={onClickManually}
        onClickUsingAI={handleAICreation}
      />
    </Modal>
  );
};

export { CreateJobTemplateFromLucaModal };
