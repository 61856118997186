import React from 'react';

import styled from 'styled-components';

import { DropdownItem } from '~/components/Dropdown/design';
import { IAction } from '~/components/Dropdown/types';
import { Icon, ICON_SIZES } from '~/components/Icon';

import { COLORS } from '~/styles';

export const StyledDropdownItem = styled(DropdownItem)`
  width: 100%;
  cursor: pointer;
  padding-left: 8px;
  font-size: 12px;
  color: ${COLORS.TEXT_MAIN};
  border-top: solid 1px ${COLORS.BORDERS};

  :hover {
    color: ${COLORS.COMPANY};
    background-color: ${COLORS.HOVER};
  }

  svg {
    margin: 10px;
  }
`;

const Action = ({ action, hideDropdown }: { action: IAction; hideDropdown: () => void }) => {
  return (
    <StyledDropdownItem
      onClick={() => {
        action.handler();
        hideDropdown();
      }}
    >
      <Icon icon={action.icon} size={ICON_SIZES.SMALL} color={COLORS.COMPANY} /> {action.name}
    </StyledDropdownItem>
  );
};

export { Action };
