import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 9px;
  padding: 20px 0 0;
  border-top: 1px solid ${COLORS.BORDERS};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 30px 0;

  & .delete {
    margin-left: -28px;
  }

  & div {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;
