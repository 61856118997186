import { REPORT_TYPES } from '@learned/constants';

export const getReportAttributes = (id: string) => {
  if (!id) {
    return { reportType: null, reportId: null };
  }
  const reportType = id.split('-')[0] as REPORT_TYPES;
  const reportId = id.split(`${reportType}-`)[1];
  return { reportType, reportId };
};
