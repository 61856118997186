import React, { Component } from 'react';

import { CONNECTION_STATUSES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import AddPeopleModal from '~/components/AddPeopleModal';
import CheckBox from '~/components/CheckBox';
import { confirm } from '~/components/ConfirmDialog';
import PeopleIcon from '~/components/Icons/People';
import Placeholder from '~/components/Placeholder';
import SearchSelectButton from '~/components/SearchSelectButton';
import { SearchField } from '~/components/Text';

import UserItem from './components/UserItem';

import store from '~/store';
import * as teamActions from '~/store/teams/actions';
import { COLOR_PALETTE, COLORS } from '~/styles';
import getCoachTeams from '~/utils/getCoachTeams';
import getUserTeams from '~/utils/getUserTeams';

const ContentMainWrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400px;
`;

const ContentWrapper = styled.div`
  margin: 5px 0 0px;
  max-height: ${(props) => props.isSelectMode && '300px'};
  overflow: auto;
`;

const SelectAll = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px 24px 12px;
  cursor: pointer;
  height: 20px;
`;

const SelectAllLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const ModalFilters = styled.div`
  display: flex;
  margin: 12px 0 0;
  border-bottom: 1px solid ${COLORS.BORDER_HARD};
  padding: 0 24px 12px;
`;

const SearchFieldWrapper = styled(SearchField)`
  height: 36px;
  margin-right: 12px;
  width: 100%;
`;

const SearchSelectButtonWrapper = styled(SearchSelectButton)`
  box-sizing: border-box;
`;

class SelectUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      isModalOpen: false,
      isSelectMode: false,
      isShowFilter: false,
      selectedTeams: [],
    };
  }

  static propTypes = {
    users: PropTypes.array,
    selectedUsers: PropTypes.array,
    updateSelectedUsers: PropTypes.func,
    hideTeamFilter: PropTypes.bool,
    disabled: PropTypes.bool,
    isValidated: PropTypes.bool,
    singleChoice: PropTypes.bool,
    searchCoaches: PropTypes.bool,
  };

  static defaultProps = {
    isValidated: true,
    hideTeamFilter: false,
    singleChoice: false,
    searchCoaches: false,
  };

  componentDidMount() {
    // update teams
    store.dispatch(teamActions.getTeams());
  }

  selectAll = (allUsers) => {
    const { updateSelectedUsers } = this.props;

    updateSelectedUsers(allUsers);
  };

  deselectAll = () => {
    const { updateSelectedUsers } = this.props;

    updateSelectedUsers([]);
  };

  selectUser = (user) => {
    const { selectedUsers, updateSelectedUsers, singleChoice } = this.props;

    // single choice
    if (singleChoice) {
      updateSelectedUsers([user]);
      return;
    }

    // multiple choice
    let newSelectedUsers = [];
    if (!isEmpty(selectedUsers)) {
      newSelectedUsers = [...selectedUsers];
    }
    let index = newSelectedUsers.map((u) => u.id).indexOf(user.id);
    if (index !== -1) {
      newSelectedUsers = newSelectedUsers.filter((_, i) => i !== index);
    } else {
      newSelectedUsers.push(user);
    }

    updateSelectedUsers(newSelectedUsers);
  };

  removeUser = async (user) => {
    const { selectedUsers, updateSelectedUsers, i18n } = this.props;

    let newSelectedUsers = [];
    if (!isEmpty(selectedUsers)) {
      newSelectedUsers = [...selectedUsers];
    }
    let index = newSelectedUsers.map((u) => u.id).indexOf(user.id);
    if (index !== -1) {
      if (
        await confirm(
          i18n,
          i18n._(
            t`Are you sure you want to delete this person? This will delete all progress made by this user and can’t be undone.`,
          ),
        )
      ) {
        newSelectedUsers = newSelectedUsers.filter((_, i) => i !== index);
      }
    }

    this.setState({
      search: '',
    });

    updateSelectedUsers(newSelectedUsers);
  };

  getUserTeams = (user) => {
    const { teams, searchCoaches } = this.props;

    return searchCoaches ? getCoachTeams(user.id, teams) : getUserTeams(user.id, teams);
  };

  getUsersTeams = () => {
    const { users, teams, searchCoaches } = this.props;

    const usersTeams = {};

    if (!isEmpty(users)) {
      users.forEach((user) => {
        if (!isEmpty(user) && !isEmpty(teams)) {
          const userTeams = searchCoaches ? getCoachTeams(user.id, teams) : this.getUserTeams(user);
          if (!isEmpty(userTeams)) {
            userTeams.forEach((userTeam) => (usersTeams[userTeam.id] = userTeam));
          }
        }
      });
    }

    return Object.values(usersTeams);
  };

  isUserSelected = (user) => {
    const { selectedUsers } = this.props;

    let isSelected = false;
    if (!isEmpty(selectedUsers)) {
      selectedUsers.forEach((selectedUser) => {
        if (String(selectedUser.id) === String(user.id)) {
          isSelected = true;
        }
      });
    }
    return isSelected;
  };

  renderUser = (user) => {
    const { isSelectMode, disabled, singleChoice } = this.props;
    const isSelected = this.isUserSelected(user);

    return (
      user && (
        <UserItem
          key={user.id}
          user={user}
          selected={isSelected}
          onSelect={this.selectUser}
          onRemove={this.removeUser}
          isSelectMode={isSelectMode}
          disabled={disabled}
          singleChoice={singleChoice}
          userTeams={this.getUserTeams(user)}
        />
      )
    );
  };

  handleClose = () => this.setState({ isModalOpen: false });

  handleChangeUsers = (addedUsers) => {
    const { selectedUsers, updateSelectedUsers, singleChoice } = this.props;

    if (singleChoice) {
      updateSelectedUsers([...addedUsers]);
    } else {
      updateSelectedUsers([...(selectedUsers || []), ...addedUsers]);
    }
  };

  handleChangeTeams = (selectedTeams) => {
    this.setState({ selectedTeams });
  };

  render() {
    const { i18n, selectedUsers, users, isSelectMode, singleChoice, hideDisabledUsers } =
      this.props;
    const { search, selectedTeams, isModalOpen } = this.state;
    const availableUsers = hideDisabledUsers
      ? users.filter((user) => user.status !== CONNECTION_STATUSES.INACTIVE)
      : users;
    let filteredUsers = isSelectMode ? availableUsers : selectedUsers || [];

    // search
    if (search) {
      if (!isEmpty(filteredUsers)) {
        filteredUsers = filteredUsers.filter((user) => {
          return (
            (user.firstName && user.firstName.toLowerCase().includes(search.toLowerCase())) ||
            (user.lastName && user.lastName.toLowerCase().includes(search.toLowerCase())) ||
            (user.email && user.email.toLowerCase().includes(search.toLowerCase()))
          );
        });
      }
    }

    // teams filter
    if (!isEmpty(selectedTeams) && !isEmpty(filteredUsers)) {
      filteredUsers = filteredUsers.filter((user) => {
        const linkedTeams = this.getUserTeams(user);
        return linkedTeams.some((linkedTeam) => selectedTeams.includes(linkedTeam.id));
      });
    }

    return (
      <ContentMainWrap>
        <ModalFilters>
          <SearchFieldWrapper
            onChange={(e) => this.setState({ search: e.target.value })}
            value={search}
            placeholder={i18n._(t`Search for people`)}
            style={{
              borderRadius: '6px',
              fontSize: '14px',
              fontWeight: 600,
            }}
          />
          <SearchSelectButtonWrapper
            title={i18n._(t`Select teams`)}
            options={uniqBy(map(this.getUsersTeams()), 'id')}
            checkedList={selectedTeams}
            handleChange={this.handleChangeTeams}
            style={{
              borderRadius: '6px',
              fontFamily: 'OpenSans',
              fontSize: '14px',
              fontWeight: 600,
            }}
            width="100%"
            height="34px"
          />
        </ModalFilters>

        {/* Do not display select all row for single choice */}
        {!singleChoice && (
          <SelectAll
            onClick={() =>
              selectedUsers.length === filteredUsers.length
                ? this.deselectAll(filteredUsers)
                : this.selectAll(filteredUsers)
            }
          >
            <SelectAllLabel>
              <Trans>Select all</Trans>
            </SelectAllLabel>
            <CheckBox checked={selectedUsers.length === filteredUsers.length} size={'24px'} />
          </SelectAll>
        )}

        <ContentWrapper isSelectMode={isSelectMode}>
          {!isEmpty(filteredUsers) ? (
            <div>{filteredUsers.map(this.renderUser)}</div>
          ) : (
            <Placeholder title={i18n._(t`No people available`)} Icon={PeopleIcon} />
          )}
        </ContentWrapper>

        {isModalOpen && (
          <AddPeopleModal
            users={
              !isEmpty(selectedUsers)
                ? availableUsers.filter((user) => {
                    // show for select only NOT ADDED users
                    return (
                      selectedUsers
                        .filter((u) => u)
                        .map((u) => u.id)
                        .indexOf(user.id) === -1
                    );
                  })
                : availableUsers
            }
            onModalClose={this.handleClose}
            title={i18n._(t`Lets add some people`)}
            onSubmit={this.handleChangeUsers}
            singleChoice={singleChoice}
          />
        )}
      </ContentMainWrap>
    );
  }
}

const mapStateToProps = (state, props) => {
  // TODO (task is created) refactor use reselect
  let selectedUsers = [];
  if (!isEmpty(props.selectedUsers)) {
    selectedUsers = [...props.selectedUsers]
      .map((userId) => (userId.id ? userId : state.users.data[userId]))
      .filter((u) => u);
  }

  let users = [];
  if (props.users) {
    users = map(props.users, (userId) => (userId.id ? userId : state.users.data[userId])).filter(
      (u) => u,
    );
  }

  return {
    user: state.auth.user,
    allUsers: state.users.data,
    teams: state.teams.data,
    selectedUsers,
    users,
  };
};

export default withI18n()(connect(mapStateToProps)(SelectUsers));
