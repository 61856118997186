import React, { useState, useRef } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { BlockPicker } from 'react-color';
import styled from 'styled-components';

import { COLOR_COLLECTION, COLOR_SET, COLOR_PALETTE, COLORS } from '~/styles';

const ColorPickerWrapper = styled.div`
  position: relative;
`;

const ColorContainer = styled.div`
  width: 185px;
  height: 40px;
  border-radius: 6px;
  border: solid 1px #c7cfe0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
`;

const ColorPopover = styled.div`
  position: absolute;
  z-index: 2;
`;
const ColorCover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
const ColorName = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.BLACK};
`;
const ColorBlock = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 3px;
  border: 1px solid ${COLORS.SUBTEXT};
  background: ${(props) => props.selectedColor};
`;

function ColorPicker({ i18n, onChange, color }) {
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedColor, setSelectedColor] = useState(color);
  const popupRef = useRef(null);

  return (
    <ColorPickerWrapper ref={popupRef}>
      <ColorContainer onClick={onOpenPopup}>
        <ColorName>{selectedColor || i18n._(t`Hex number`)}</ColorName>
        <ColorBlock selectedColor={selectedColor || COLOR_PALETTE.WHITE} />
      </ColorContainer>
      {openPopup && (
        <ColorPopover>
          <ColorCover onClick={onClosePopup} />
          <BlockPicker
            colors={COLOR_COLLECTION}
            color={selectedColor || COLOR_SET.BLUE}
            onChange={handleChangeColor}
          />
        </ColorPopover>
      )}
    </ColorPickerWrapper>
  );

  function handleChangeColor(color) {
    setSelectedColor(color.hex);
    onChange(color);
  }

  function onOpenPopup() {
    setOpenPopup(true);
    scrollToRef(popupRef);
  }

  function onClosePopup() {
    setOpenPopup(false);
  }

  function scrollToRef(ref) {
    // EI and EDGE do not support scrollTo()
    document.getElementById('main-content').style.scrollBehavior = 'smooth';
    document.getElementById('main-content').scrollTop = ref.current.offsetTop - 300;
    document.getElementById('main-content').style.scrollBehavior = '';
  }
}

export default withI18n()(ColorPicker);
