import React, { useEffect, useState } from 'react';

import {
  ACTIVITY_PROGRESS_TYPES,
  ACTIVITY_STATUSES,
  CONFIRMATION_MODAL_TYPE,
  CURRENCY,
  SYMBOLS,
} from '@learned/constants';
import { I18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { StatusLabel } from '~/components/GoalsBlock/components/StatusLabel';
import { ICONS, Icon } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import ToolTip from '~/components/Tooltip';

import {
  ActivityCardContainer,
  ActivityHeaderContainer,
  ActivityModalFooter,
  ActivityTitle,
  AttachmentItem,
  AttachmentsContainer,
  AttachmentsList,
  CurrentProgressContainer,
  DescriptionContainer,
  DescriptionText,
  DownloadIconContainer,
  FileName,
  ProgressIconContainer,
  ProgressLabelContainer,
  ProgressValueContainer,
  ProgressValueRow,
  ProgressValuesContainer,
  StyledDropdown,
  StyledInput,
  SubTitle,
} from './design';

import { ACTIVITY_STATUS_STYLES } from '~/constants/activities';
import useBoolState from '~/hooks/useBoolState';
import { getUserActivity } from '~/services/userActivities';
import { formatNumber } from '~/utils/formatNumber';
import { getGoalProgressWithDecimalsForInputs } from '~/utils/getGoalProgressWithDecimals';

import type { IActivity } from '@learned/types';

interface IProps {
  activityId: string;
  onClose: () => void;
  isLibrary?: boolean;
  isProgressEditable?: boolean;
  updateActivity: (activityId: string, data: IActivity) => void;
  onChangeActivityProgress?: (data: IActivity) => void;
}

type StatusItem = { key: string; title: (i18n: I18n) => string; color: string };

const ActivityProgressCard = ({
  activityId,
  onClose,
  isLibrary = false,
  isProgressEditable,
  updateActivity,
  onChangeActivityProgress,
}: IProps) => {
  const { i18n } = useLingui();
  const [activity, setActivity] = useState<IActivity>();
  const $isLoadingActivity = useBoolState(false);
  const $isUpdatingActivity = useBoolState(false);
  const $isShowWarningModal = useBoolState(false);
  const [selectedStatus, setSelectedStatus] = useState(
    Object.values(ACTIVITY_STATUS_STYLES).find(
      (statusObj) => statusObj.key === (activity?.status as ACTIVITY_STATUSES),
    )!,
  );
  const [currentProgress, setCurrentProgress] = useState<number>();
  const [allowedStatuses, setAllowedStatuses] = useState<StatusItem[]>([]);
  const [calculatedProgress, setCalculatedProgress] = useState<number>(0);

  const isMetric = [
    ACTIVITY_PROGRESS_TYPES.CURRENCY,
    ACTIVITY_PROGRESS_TYPES.NUMBERS,
    ACTIVITY_PROGRESS_TYPES.PERCENTAGE,
  ].includes(activity?.progressType as ACTIVITY_PROGRESS_TYPES);
  const isPercentage = activity?.progressType === ACTIVITY_PROGRESS_TYPES.PERCENTAGE;
  const isCurrency = activity?.progressType === ACTIVITY_PROGRESS_TYPES.CURRENCY;
  const isBinary = activity?.progressType === ACTIVITY_PROGRESS_TYPES.IS_DONE;

  // adding currency and percentage symbols
  const getValuesWithSymbols = (value: number) => {
    let result = `${value.toFixed(2)}`;
    if (isPercentage) {
      result = `${value.toFixed(2)}%`;
    }
    if (isCurrency) {
      result = `${SYMBOLS[activity.progressDetails.currency as CURRENCY]}${value.toFixed(2)}`;
    }
    return result;
  };

  const downloadFile = (file: string) => () => {
    setTimeout(() => {
      window.open(file);
    }, 100);
  };

  const onUpdateActivity = async (activity: IActivity) => {
    $isUpdatingActivity.on();
    await updateActivity(activityId, {
      ...activity,
      status: selectedStatus.key as ACTIVITY_STATUSES,
      progress: currentProgress!,
    });
    $isUpdatingActivity.off();
    onClose();
  };

  const handleCancel = () => {
    if (currentProgress !== activity?.progress || selectedStatus.key !== activity?.status) {
      $isShowWarningModal.on();
    } else {
      onClose();
    }
  };

  useEffect(() => {
    const join: string[] = [];
    const populate = ['skills', 'categories', 'files', 'goals'];
    join.push('goalsUpdates');

    const fetchData = async () => {
      $isLoadingActivity.on();
      const { data }: AxiosResponse<{ userActivity: IActivity }> = await getUserActivity(
        activityId,
        populate,
        join,
      );
      setActivity(data.userActivity);
      $isLoadingActivity.off();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityId]);

  // selecting statuses based on the type of activity
  const allStatsesArray = Object.values(ACTIVITY_STATUS_STYLES);
  const metricStatuses = [
    ACTIVITY_STATUSES.TODO,
    ACTIVITY_STATUSES.IN_PROGRESS,
    ACTIVITY_STATUSES.PROBLEM,
    ACTIVITY_STATUSES.COMPLETED,
  ];
  const binaryStatuses = metricStatuses.filter(
    (status) => status !== ACTIVITY_STATUSES.IN_PROGRESS,
  );

  const activityStatuses = isMetric
    ? allStatsesArray.filter((status) => metricStatuses.includes(status.key as ACTIVITY_STATUSES))
    : allStatsesArray.filter((status) => binaryStatuses.includes(status.key as ACTIVITY_STATUSES));

  useEffect(() => {
    setSelectedStatus(
      Object.values(ACTIVITY_STATUS_STYLES).find(
        (statusObj) => statusObj.key === (activity?.status as ACTIVITY_STATUSES),
      )!,
    );

    // calculating current value based on current progress percentage
    const currentProgress = activity?.progress || 0;

    const progressPercentage =
      (currentProgress / (activity?.progressDetails.max || 0) -
        (activity?.progressDetails.min || 0)) *
      100;

    setCurrentProgress(Number(currentProgress));
    setCalculatedProgress(Number(progressPercentage) || 0);
  }, [activity]);

  useEffect(() => {
    if (isBinary) {
      setAllowedStatuses(activityStatuses);
      return;
    }

    let filteredStatuses = [];
    let currentActivityStatus = selectedStatus;
    const currentProgressPercentage =
      ((currentProgress! - (activity?.progressDetails.min || 0)) /
        ((activity?.progressDetails.max || 0) - (activity?.progressDetails.min || 0))) *
      100;

    switch (true) {
      case currentProgressPercentage <= 0 || !currentProgress:
        filteredStatuses = activityStatuses.filter((status) =>
          [ACTIVITY_STATUSES.TODO, ACTIVITY_STATUSES.PROBLEM].includes(
            status.key as ACTIVITY_STATUSES,
          ),
        );
        currentActivityStatus = filteredStatuses.find(
          (status) => status.key === ACTIVITY_STATUSES.TODO,
        ) as StatusItem;
        break;

      case currentProgressPercentage > 0 && currentProgressPercentage < 100:
        filteredStatuses = activityStatuses.filter((status) =>
          [ACTIVITY_STATUSES.IN_PROGRESS, ACTIVITY_STATUSES.PROBLEM].includes(
            status.key as ACTIVITY_STATUSES,
          ),
        );
        currentActivityStatus = filteredStatuses.find(
          (status) => status.key === ACTIVITY_STATUSES.IN_PROGRESS,
        ) as StatusItem;
        break;

      default:
        filteredStatuses = activityStatuses.filter((status) =>
          [ACTIVITY_STATUSES.COMPLETED, ACTIVITY_STATUSES.PROBLEM].includes(
            status.key as ACTIVITY_STATUSES,
          ),
        );
        currentActivityStatus = filteredStatuses.find(
          (status) => status.key === ACTIVITY_STATUSES.COMPLETED,
        ) as StatusItem;
        break;
    }

    setAllowedStatuses(filteredStatuses);
    setSelectedStatus((prev) =>
      prev?.key === ACTIVITY_STATUSES.PROBLEM ? prev : currentActivityStatus,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProgress, activity]);

  useEffect(() => {
    onChangeActivityProgress &&
      onChangeActivityProgress({
        ...activity,
        status: selectedStatus?.key as ACTIVITY_STATUSES,
        progress: currentProgress,
      } as IActivity);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus, currentProgress]);

  return (
    <ActivityCardContainer isLibrary={isLibrary}>
      <ShowSpinnerIfLoading loading={$isLoadingActivity.value}>
        {!isLibrary && (
          <ActivityHeaderContainer>
            <ToolTip tooltip={activity?.name}>
              <ActivityTitle>{activity?.name}</ActivityTitle>
            </ToolTip>
            <Button
              variant={ButtonVariant.ICON}
              size={ButtonSize.MEDIUM}
              icon={ICONS.CLOSE}
              onClick={handleCancel}
            />
          </ActivityHeaderContainer>
        )}

        <ProgressValuesContainer>
          {isMetric && (
            <ProgressValueRow>
              <ProgressIconContainer>
                <Icon icon={ICONS.SKILL_CATEGORY} width={'16px'} height={'16px'} />
              </ProgressIconContainer>
              <ProgressLabelContainer>
                <Trans>Start</Trans>
              </ProgressLabelContainer>
              <ProgressValueContainer>
                {getValuesWithSymbols(activity?.progressDetails.min || 0)}
              </ProgressValueContainer>
            </ProgressValueRow>
          )}

          {isMetric && (
            <ProgressValueRow>
              <ProgressIconContainer>
                <Icon icon={ICONS.SUPERADMIN} width={'13px'} height={'13px'} />
              </ProgressIconContainer>
              <ProgressLabelContainer>
                <Trans>Current</Trans>
              </ProgressLabelContainer>
              <ProgressValueContainer>
                <CurrentProgressContainer>
                  {isProgressEditable ? (
                    <StyledInput
                      type="number"
                      min={activity?.progressDetails.min as number}
                      max={activity?.progressDetails.max as number}
                      value={currentProgress}
                      onChange={(e) => {
                        setCurrentProgress(
                          formatNumber(
                            getGoalProgressWithDecimalsForInputs(
                              e.target.value,
                              activity?.progressType as ACTIVITY_PROGRESS_TYPES,
                            ),
                          ),
                        );
                      }}
                    />
                  ) : (
                    `${getValuesWithSymbols(currentProgress!)}`
                  )}
                  {` / ${getValuesWithSymbols(activity?.progressDetails.max || 0)}`}
                </CurrentProgressContainer>
              </ProgressValueContainer>
            </ProgressValueRow>
          )}

          <ProgressValueRow isEdit={isProgressEditable}>
            <ProgressIconContainer>
              <Icon icon={ICONS.PARTIAL} width={'13px'} height={'13px'} />
            </ProgressIconContainer>
            <ProgressLabelContainer>
              <Trans>Status</Trans>
            </ProgressLabelContainer>
            <ProgressValueContainer>
              {isProgressEditable ? (
                <StyledDropdown
                  items={allowedStatuses}
                  selectedItem={selectedStatus}
                  onChange={(selectedItem) => {
                    // @ts-ignore
                    setSelectedStatus(selectedItem);
                  }}
                  // @ts-ignore
                  stringifyItem={(item) => item.title(i18n)}
                  isSingleSelect={true}
                />
              ) : (
                <StatusLabel
                  status={activity?.status as ACTIVITY_STATUSES}
                  progress={calculatedProgress}
                />
              )}
            </ProgressValueContainer>
          </ProgressValueRow>
        </ProgressValuesContainer>

        {activity?.description && !isLibrary && (
          <DescriptionContainer isEdit={isProgressEditable}>
            <SubTitle>
              <Trans>Description</Trans>
            </SubTitle>
            <DescriptionText html={activity.description} />
          </DescriptionContainer>
        )}

        {!isEmpty(activity?.files) && (
          <AttachmentsContainer>
            <SubTitle>
              <Trans>Attachments</Trans>
            </SubTitle>
            <AttachmentsList>
              {activity?.files.map((file, index) => (
                <AttachmentItem key={index}>
                  {/* @ts-ignore */}
                  <FileName>{file.name}</FileName>
                  <DownloadIconContainer>
                    <Button
                      variant={ButtonVariant.ICON}
                      size={ButtonSize.MEDIUM}
                      icon={ICONS.IMPORT}
                      /* @ts-ignore */
                      onClick={downloadFile(file.url as string)}
                    />
                  </DownloadIconContainer>
                </AttachmentItem>
              ))}
            </AttachmentsList>
          </AttachmentsContainer>
        )}

        {!isLibrary && (
          <ActivityModalFooter>
            <Button
              label={i18n._(t`Cancel`)}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              onClick={handleCancel}
            />
            {isProgressEditable && (
              <Button
                label={i18n._(t`Update`)}
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.MEDIUM}
                onClick={() => {
                  onUpdateActivity(activity as IActivity);
                }}
                isLoading={$isUpdatingActivity.value}
              />
            )}
          </ActivityModalFooter>
        )}
      </ShowSpinnerIfLoading>
      {$isShowWarningModal.value && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.WARNING}
          description={i18n._(t`'Your changes will not be saved.`)}
          title={i18n._(t`Are you sure you want to close without updating?`)}
          cancelButton={i18n._(t`Back`)}
          submitButton={i18n._(t`Yes`)}
          onClose={$isShowWarningModal.off}
          onSubmit={onClose}
        />
      )}
    </ActivityCardContainer>
  );
};

export { ActivityProgressCard };
