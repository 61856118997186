import React from 'react';

import styled from 'styled-components';

import BoxWithBorder from '~/components/UI/BoxWithBorder';

import { SummarySection } from './SummarySection';

import type { IUser } from '@learned/types';

const TabContainer = styled.div`
  margin: 19px 39px;

  .user-public-conversations_header {
    margin-bottom: 24px;
  }
`;

interface IProps {
  user: IUser;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Summary = ({ user }: IProps) => {
  return (
    <BoxWithBorder>
      <TabContainer>
        {/* <UserCareer userId={user.id} /> */}
        <SummarySection user={user} />
      </TabContainer>
    </BoxWithBorder>
  );
};

export default Summary;
