import { createReducer } from 'redux-act';

import { finishGetCompanyAddons } from '~/store/addons/actions';

const INITIAL_STATE = {};

export const addons = createReducer({}, INITIAL_STATE).on(
  finishGetCompanyAddons,
  (state, payload) => {
    return {
      ...state,
      ...payload,
    };
  },
);
