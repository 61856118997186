import React from 'react';

import styled from 'styled-components';

import ColoredRow, { IconCol } from '~/components/ColoredRow';
import ExpandMoreIcon from '~/components/Icons/ArrowButtonTall';

import { GOAL_STYLES } from '~/constants/goals';
import { COLOR_PALETTE } from '~/styles';

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-right: 20px;
  justify-content: space-between;
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
`;

const StyledColoredRow = styled(ColoredRow)`
  margin-bottom: 0;
  border: none;
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }

  ${IconCol} {
    border-bottom-left-radius: 0;
  }
`;

const ExpandMore = ({ isOpen }) => {
  return isOpen ? (
    <ExpandMoreIcon size={24} style={{ transform: 'rotate(180deg)' }} />
  ) : (
    <ExpandMoreIcon size={24} />
  );
};

const Header = ({ children, $isOpen, goalType, pdfView }) => {
  const goalStyles = GOAL_STYLES[goalType];

  return (
    <StyledColoredRow
      rowIcon={goalStyles.icon}
      rowStartColor={goalStyles.color}
      rowColor={goalStyles.background}
      rowIconColor={goalStyles.iconColor}
      rowIconBackground={goalStyles.iconBackground}
    >
      <HeaderContainer onClick={$isOpen.toggle} isBorderBottom={$isOpen.value}>
        <TitleWrapper>
          <Title>{children}</Title>
        </TitleWrapper>
        {!pdfView && <ExpandMore isOpen={$isOpen.value} />}
      </HeaderContainer>
    </StyledColoredRow>
  );
};

export { Header };
