import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import IconMenu from '~/components/IconMenu';
import OverviewHeading from '~/components/OverviewHeading';
import { SearchField } from '~/components/Text';
import ActionsContainer from '~/components/UI/ActionsContainer';
import Divider from '~/components/UI/Divider';
import Table, { TableCol, TableRow } from '~/components/UI/Table';
import BaseLayout from '~/layouts/BaseLayout';
import LoadingPage from '~/pages/LoadingPage';

import { CONNECTION_STATUSES } from '~/constants';
import { getCompanies, getUser } from '~/selectors/baseGetters';
import { getCompanySuperAdmin } from '~/services/companies';
import {
  addSuperAdminAsCompanyAdmin,
  downloadUsersCSVSuperAdmin,
  getUsersSuperAdmin,
  removeUserFromCompanySuperAdmin,
  resendWelcomeApiEmailSuperAdmin,
  switchUserDisabledSuperAdmin,
} from '~/services/users';
import { fetchUserData } from '~/store/users/actions';
import { COLOR_PALETTE, COLOR_SET } from '~/styles';
import convertToTimeString from '~/utils/convertToTimeString';
import getUserFullName from '~/utils/getUserFullName';

const ActionsContainerWrapper = styled(ActionsContainer)`
  height: 48px;
  align-items: center;
  border: unset;
  display: flex;
  flex-direction: row;
`;

const Wrapper = styled.div`
  border-radius: 6px;
  border: solid 1px ${COLOR_PALETTE.GRAY_MIDDLE};
`;

const CompanyNameContainer = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.63;
  color: ${COLOR_PALETTE.BLACK};
  flex: 1;
`;

const StyledSearch = styled(SearchField)`
  height: 32px;
  width: 248px;
  & > input {
    border-radius: 6px;
    font-size: 14px;
    height: 32px;
  }
`;

const StyledTable = styled(Table)`
  background-color: white;
`;

const StyledButton = styled(Button)`
  background-color: ${COLOR_SET.WARNING_RED};

  &:hover {
    background-color: ${COLOR_SET.LIGHT_RED};
  }
`;

const StyledRow = styled(TableRow)`
  padding: 0 16px;
`;

const CompanyName = styled(TableCol)`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${COLOR_PALETTE.BLACK};
`;

const CompanyText = styled(TableCol)`
  font-size: 14px;
  line-height: 1.57;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const StyledUL = styled.ul`
  padding-left: 20px;
`;

const CompanyEnabled = styled(TableCol)`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: #${(props) => (props.$enabled ? '29ccab' : 'e93c3c')};
`;

const SuperAdminCompanyMembers = ({ i18n }) => {
  const [selectedCompany, setSelectedCompany] = useState({});
  const [allUsers, setAllUsers] = useState({});
  const [search, setSearch] = useState('');
  const [isCompanyAdmin, setIsCompanyAdmin] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const superAdminUser = useSelector(getUser);
  const superAdminCompanies = useSelector(getCompanies);
  const { companyId } = location.state;

  const cols = [
    { title: i18n._(t`Name`), width: '196px' },
    { title: i18n._(t`E-mail`), width: '166px' },
    { title: i18n._(t`Invited`), width: '136px' },
    { title: i18n._(t`Set-up`), width: '116px' },
    { title: i18n._(t`Login L/M`), width: '116px' },
    { title: i18n._(t`Roles`), width: '122px' },
    { title: i18n._(t`Status`), width: '112px' },
    { width: '40px' },
  ];

  const fetchCompanyData = async () => {
    const company = await getCompanySuperAdmin(companyId);
    const users = await getUsersSuperAdmin(companyId);

    setSelectedCompany(company);
    setAllUsers(users);
  };

  useEffect(() => {
    fetchCompanyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsCompanyAdmin(!!allUsers[superAdminUser.id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsers]);

  let userList;

  if (selectedCompany) {
    // Filter user list by selected company.
    const filteredUserIds = map(selectedCompany.connections, (x) => x.user);
    userList = filteredUserIds.map((user) => allUsers[user]).filter((i) => i);
  }

  const renderRow = (user) => {
    const { roles, status } = Object.values(selectedCompany.connections).find(
      (i) => i.user === user.id,
    );
    const numberOfSessions = selectedCompany.lastMonthUserSessionCount[user.id];
    const menuItems = [
      {
        label: i18n._(status === 'active' ? t`Disable` : t`Enable`),
        action: async () => {
          await switchUserDisabledSuperAdmin(
            user.id,
            companyId,
            status === CONNECTION_STATUSES.ACTIVE.key,
          );
          const company = await getCompanySuperAdmin(companyId);
          setSelectedCompany(company);
        },
      },
      {
        label: i18n._(t`Delete`),
        action: async () => {
          if (
            await confirm(
              i18n,
              i18n._(
                t`Are you sure you want to delete the user ${getUserFullName(
                  user,
                )} with email address ${user.email} and all of its userdata from the company ${
                  selectedCompany ? selectedCompany.name : ''
                }? THIS ACTION CANNOT BE UNDONE!`,
              ),
            )
          ) {
            if (
              await confirm(
                i18n,
                i18n._(
                  t`Permanently deleting user ${user.email} from ${selectedCompany.name}...Are you sure?`,
                ),
              )
            ) {
              await removeUserFromCompanySuperAdmin(user.id, selectedCompany.id);
              await fetchCompanyData();
            } else {
              alert(i18n._(t`User ${getUserFullName(user)} will be retained. Phew!`));
            }
          } else {
            alert(i18n._(t`User ${getUserFullName(user)} will be retained.`));
          }
        },
      },
    ];

    if (!user.completedFirstTimeSetup) {
      menuItems.push({
        label: i18n._(t`Resend welcome e-mail`),
        action: async () => {
          if (
            await confirm(
              i18n,
              i18n._(
                t`Are you sure you want to resend a welcome email to ${user.email} from the company ${selectedCompany.name}?`,
              ),
            )
          ) {
            await resendWelcomeApiEmailSuperAdmin(user.id, companyId);
            alert(i18n._(t`Welcome email sent to ${user.email}`));
          }
        },
      });
    }

    return user ? (
      <StyledRow key={user.id}>
        <CompanyName>{getUserFullName(user)}</CompanyName>
        <CompanyText>{user.email}</CompanyText>
        <CompanyText>{convertToTimeString(user.meta.createdDate)}</CompanyText>
        <CompanyEnabled $enabled={user.completedFirstTimeSetup}>
          {user.completedFirstTimeSetup.toString()}
        </CompanyEnabled>
        <CompanyText>{numberOfSessions ?? 0}</CompanyText>
        <CompanyText>
          <StyledUL>
            <li>
              {' '}
              <strong>User</strong>{' '}
            </li>
            {roles &&
              roles.map((role) => (
                <li key={role}>
                  <strong>{role}</strong>
                </li>
              ))}
          </StyledUL>
        </CompanyText>
        <CompanyText>{status}</CompanyText>
        <TableCol>
          <IconMenu items={menuItems} />
        </TableCol>
      </StyledRow>
    ) : undefined;
  };

  if (!selectedCompany) {
    return <LoadingPage />;
  }

  const searchUser = (user) => {
    return (
      !search ||
      getUserFullName(user).toLowerCase().includes(search.toLowerCase()) ||
      user.email.toLowerCase().includes(search.toLowerCase())
    );
  };

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleAddSuperAdminToCompany = async () => {
    if (
      await confirm(
        i18n,
        i18n._(
          t`Are you sure you want to add yourself as an admin of the company ${selectedCompany.name}?`,
        ),
      )
    ) {
      await addSuperAdminAsCompanyAdmin(selectedCompany.id);
      await fetchCompanyData();
      dispatch(fetchUserData());
    }
  };

  const handleRemoveSuperAdminFromCompany = async () => {
    // If the super admin user belongs to only one company
    if (
      Object.values(superAdminCompanies).length === 1 &&
      (await confirm(
        i18n,
        i18n._(
          t`You are trying to remove yourself from the last company your user is connected to, you must always belong to at least one company.`,
        ),
        { isNoCancelButton: true, title: i18n._(t`Not allowed`) },
      ))
    ) {
      return;
    }

    // If the super admin user belongs to multiple companies, the user can be removed from the selected company
    if (
      await confirm(
        i18n,
        i18n._(
          t`Are you sure you want to remove yourself from the company ${selectedCompany.name}?`,
        ),
      )
    ) {
      await removeUserFromCompanySuperAdmin(superAdminUser.id, selectedCompany.id);
      await fetchCompanyData();
      dispatch(fetchUserData());
    }
  };

  return (
    <div>
      <OverviewHeading onBack={history.goBack} title={i18n._(t`Companies`)}>
        {isCompanyAdmin ? (
          <StyledButton
            label={i18n._(t`Remove me from the company`)}
            onClick={handleRemoveSuperAdminFromCompany}
            type="primary"
            loading={isEmpty(userList)}
          />
        ) : (
          <Button
            label={i18n._(t`Make me admin`)}
            onClick={handleAddSuperAdminToCompany}
            loading={isEmpty(userList)}
          />
        )}
        {user?.superAdminLevel !== undefined && user?.superAdminLevel >= 1 && (
          <Button
            label={i18n._(t`Export users as CSV`)}
            onClick={() => downloadUsersCSVSuperAdmin(companyId)}
          />
        )}
      </OverviewHeading>

      <BaseLayout>
        <Wrapper>
          <ActionsContainerWrapper noBottomBorder>
            <CompanyNameContainer>{selectedCompany.name}</CompanyNameContainer>
            <StyledSearch
              placeholder={i18n._(t`Search`)}
              value={search}
              onChange={handleChangeSearch}
            />
          </ActionsContainerWrapper>
          <Divider />
          <StyledTable
            loading={isEmpty(userList)}
            cols={cols}
            items={Object.values(userList).filter(searchUser)}
            renderRow={renderRow}
          />
        </Wrapper>
      </BaseLayout>
    </div>
  );
};

export default withI18n()(SuperAdminCompanyMembers);
