import type { ISkill } from '@learned/types';

function getFocusAreaValuesByLevels(focusAreas: ISkill['focusAreas'], ...levels: number[]) {
  const focusAreasValues = [];
  for (const focusArea of focusAreas) {
    if (levels.includes(focusArea.level)) {
      focusAreasValues.push(...focusArea.values);
    }
  }
  return focusAreasValues;
}

export { getFocusAreaValuesByLevels };
