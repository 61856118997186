import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';

import useBoolState from '~/hooks/useBoolState';

import { DatePicker } from '../DatePicker';
import { ICONS, Icon } from '../Icon';
import { Input } from '../Input';

import type { IInputProps } from '../Input/types';

export type DateInputProps = Omit<IInputProps, 'onChange'> & {
  onChange: (date?: Date | null) => void;
};

const DateInput = (props: DateInputProps) => {
  const { i18n } = useLingui();
  const $isOpenPicker = useBoolState(false);
  const today = moment().format('DD-MM-YYYY');
  const [date, setDate] = useState<string | null>(props.value as string);

  const { onChange, ...restOfProps } = props;

  const handlePickerClose = (date?: string | null) => {
    const formattedDate = moment(date).format('DD-MM-YYYY');
    setDate(date ? formattedDate : null);
    onChange && onChange(date ? new Date(date) : null);
    $isOpenPicker.off();
  };

  useEffect(() => {
    setDate(props.value as string);
  }, [props.value]);

  return (
    <>
      <Input
        leftIcon={<Icon icon={ICONS.CALENDAR} />}
        placeholder={i18n._(t`Select date`)}
        {...restOfProps}
        onClick={$isOpenPicker.on}
        value={date as string}
      />
      {$isOpenPicker.value && (
        /* @ts-ignore */
        <DatePicker onClose={handlePickerClose} initialDate={moment(date || today, 'DD-MM-YYYY')} />
      )}
    </>
  );
};

export { DateInput };
