import React from 'react';

import { DateRangePicker } from '~/components/DateRangePicker';

export type IDatePickerProps = {
  initialDate?: string | undefined;
  onClose: (date?: string | null) => void;
};

function DatePicker({ onClose, initialDate }: IDatePickerProps) {
  return <DateRangePicker onClose={onClose} initialFrom={initialDate} isSingleDate />;
}

export { DatePicker };
