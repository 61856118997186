import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import NineGridReportsPage from '~/pages/AdminReports/NineGridReportsPage';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('nineGrid', {
  routesPrivate: {
    admin: [
      <PrivateRoute
        key={routes.NINE_GRID_REPORT.routePath(ROLES.ADMIN)}
        exact
        path={routes.NINE_GRID_REPORT.routePath(ROLES.ADMIN)}
        component={NineGridReportsPage}
        allowedRoles={[ROLES.ADMIN]}
      />,
    ],
  },
  routesPublic: [],
});
