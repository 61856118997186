import React, { useState, useEffect } from 'react';

import { t } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import { AutocompleteFilterGoalCycles } from '~/components/AutocompleteFilters';
import Button from '~/components/Button';
import CheckBox from '~/components/CheckBox';
import Modal from '~/components/Modal';
import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';
import { SearchField } from '~/components/Text';

import GoalsIcon from '~/assets/main-menu-icons/goal.svg';

import { GOAL_STYLES } from '~/constants/goals';
import { getGoalsForReviewImport, addGoalCycles } from '~/services/goals';
import { COLOR_PALETTE } from '~/styles';

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_MIDDLE};
`;

const StyledSearch = styled(SearchField)`
  margin-right: 8px;
  width: 314px;
`;

const RowWrapper = styled.div`
  height: 24px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_MIDDLE};
`;

const GoalTitle = styled.div`
  margin: 0 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: black;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  max-width: 90%;
  text-overflow: ellipsis;
`;

const GoalCheckBox = styled(CheckBox)``;

const ImportGoalsModal = ({
  onClose,
  i18n,
  user,
  goalCycle,
  preSelectedGoals,
  addToGoalsArray,
  type,
  isLearning,
  review,
}) => {
  const [userGoals, setUserGoals] = useState([]);
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [goalCycleFilter, setGoalCycleFilter] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetch = async () => {
      const goals = await getGoalsForReviewImport({
        forUser: user.id,
        goalType: type,
        reviewId: review.id,
      });
      const preSelectedGoalIds = preSelectedGoals.map((goal) => goal.id);
      setUserGoals(Object.values(goals).filter((goal) => !preSelectedGoalIds.includes(goal.id)));
    };
    fetch();

    // eslint-disable-next-line
  }, [isLearning, user.id, preSelectedGoals, type]);

  const selectGoals = async () => {
    const goalsToAdd = [];
    await Promise.all(
      selectedGoals.map(async (selectedGoal) => {
        const goal = userGoals.find((g) => g.id === selectedGoal);
        const goalId = goal.id;
        const goalCycles = [...(goal.goalCycles || []), goalCycle].filter((i) => i);
        await addGoalCycles(goalId, goalCycles);

        goalsToAdd.push({
          ...goal,
          goalCycles,
        });
      }),
    );
    addToGoalsArray(goalsToAdd);
    onClose();
  };

  const checkGoal = (goalId) => {
    const goals = selectedGoals.filter((goal) => goal !== goalId);
    if (!selectedGoals.includes(goalId)) {
      goals.push(goalId);
    }
    setSelectedGoals(goals);
  };

  const renderRow = (goal) => {
    const isChecked = selectedGoals.includes(goal.id);
    const goalStyles = GOAL_STYLES[goal.type];
    return (
      <RowWrapper key={goal.id}>
        {goalStyles && (
          <SvgIcon
            classNameIcon="img"
            isDefaultColor
            defaultColor={goalStyles?.iconColor}
            width="20px"
            height="20px"
            url={goalStyles.icon}
          />
        )}
        <GoalTitle>{goal.name}</GoalTitle>
        <GoalCheckBox checked={isChecked} onChange={() => checkGoal(goal.id)} />
      </RowWrapper>
    );
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const searchGoals = (goal) => {
    if (search && !goal.name.includes(search)) {
      return false;
    }
    if (goalCycleFilter.length) {
      return goalCycleFilter.filter(
        (filter) => goal && goal.goalCycles && goal.goalCycles.includes(filter.id),
      ).length;
    }
    return true;
  };

  const items = userGoals.filter(searchGoals);

  return (
    <Modal
      contentStyles={{
        padding: 0,
      }}
      title={i18n._(t`Import existing goals`)}
      onClose={onClose}
      footerRight={
        <Button
          label={`${i18n._(t`Import`)} ${selectedGoals.length}`}
          type="primary-border"
          disabled={!selectedGoals.length}
          onClick={selectGoals}
        />
      }
    >
      <FilterRow>
        <StyledSearch placeholder={i18n._(t`Search`)} value={search} onChange={handleSearch} />
        <AutocompleteFilterGoalCycles checkedList={goalCycleFilter} onChange={setGoalCycleFilter} />
      </FilterRow>
      {isEmpty(items) ? (
        <Placeholder
          Icon={() => (
            <SvgIcon
              url={GoalsIcon}
              width={'30px'}
              height={'30px'}
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
          )}
          title={i18n._(t`No goals`)}
          subTitle={i18n._(
            t`Either this person has no goals or all goals have already been imported `,
          )}
        />
      ) : (
        items.map((goal) => renderRow(goal))
      )}
    </Modal>
  );
};

export default ImportGoalsModal;
