import styled from 'styled-components';

import Modal from '~/components/Modal';
import OverviewHeading from '~/components/OverviewHeading';

export const Header = styled(OverviewHeading)`
  display: flex;
  gap: 10px;
  align-items: center;
  & > div:first-child {
    flex: none;
  }

  & > div:last-child {
    display: flex;
    align-items: center;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
`;

export const ModalWrapper = styled(Modal)`
  & > div:first-child {
    padding: 23px 32px 0px 32px;
  }

  & > div:nth-child(2) {
    padding: 0px 32px 10px 32px;
  }
`;

export const ModalContent = styled.div`
  padding: 20px 0px 32px 0px;
`;

export const ModalFooter = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
`;

export const InputBlock = styled.div`
  margin-bottom: 20px;
`;
