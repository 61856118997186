import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Controller } from 'react-hook-form';
import styled, { css } from 'styled-components';

import Editor from '~/components/Editor';

import type { IQuestionForm } from '~/@types/question';
import { COLORS } from '~/styles';

import type { ISurveyRating } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface ICommentProps {
  formMethods: UseFormReturn<IQuestionForm>;
  error?: boolean;
  onChange?: (data: {
    answer?: ISurveyRating['answer'];
    comment?: ISurveyRating['comment'];
  }) => void;
}

const Label = styled.label<{ isObligated: boolean }>`
  font-size: 14px;
  color: ${COLORS.MIDDLE_GRAY};
  padding-bottom: 6px;
  text-align: center;
  ${({ isObligated }) =>
    !isObligated &&
    css`
      cursor: pointer;
    `}
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  width: 100%;
`;

const Comment = ({ formMethods, onChange, error }: ICommentProps) => {
  const { control, watch } = formMethods;
  const isCommentsObligated = watch('settings.isCommentsObligated');
  const isCommentsAllowed = watch('settings.isCommentsAllowed');

  const [isCommentVisible, setIsCommentVisible] = useState(isCommentsObligated);

  useEffect(() => {
    setIsCommentVisible(isCommentsObligated);
  }, [isCommentsObligated]);

  return (
    <>
      {isCommentsAllowed && (
        <DescriptionWrapper>
          <Label
            isObligated={!!isCommentsObligated}
            onClick={() => !isCommentsObligated && setIsCommentVisible((prevState) => !prevState)}
          >
            <Trans id={isCommentsObligated ? t`leave a comment*` : t`leave a comment`} />
          </Label>
          {isCommentVisible && (
            <Controller
              name="comment"
              control={control}
              rules={{ required: isCommentsObligated }}
              render={({ field }) => (
                <Editor
                  error={error}
                  value={field.value}
                  compact
                  onChange={(value: string) => {
                    field.onChange(value);
                    const comment = isCommentsAllowed ? value : undefined;
                    onChange?.({ comment });
                  }}
                  big
                />
              )}
            />
          )}
        </DescriptionWrapper>
      )}
    </>
  );
};

export { Comment };
