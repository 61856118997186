import React, { Dispatch } from 'react';

import styled from 'styled-components';

import { Section } from './Section';

import { COLOR_PALETTE } from '~/styles';

export enum SECTION_TYPE {
  CURRENT = 'current',
  DONE = 'done',
  ERROR = 'error',
  LOCKED = 'locked',
}

export interface ISection {
  title: string;
  id?: string;
  type?: SECTION_TYPE;
  isTouched?: boolean;
  fields?: string[];
}

interface ISideBarProps {
  sections: ISection[];
  currentSection: number;
  hideErrorState?: boolean;
  setCurrentSection: Dispatch<number>;
}

const Wrapper = styled.div`
  position: relative;
`;

const getLineColors = (sections: ISection[], index: number) => {
  let topLineColor;
  let bottomLineColor;

  if (index) {
    switch (sections[index].type) {
      case SECTION_TYPE.DONE:
        if (sections[index - 1].type === SECTION_TYPE.DONE) {
          topLineColor = 'var(--company-color)';
        } else {
          topLineColor = '#dcdcdc';
        }
        break;
      case SECTION_TYPE.CURRENT:
        if (sections[index - 1].type === SECTION_TYPE.DONE) {
          topLineColor = COLOR_PALETTE.DARK_GRAY;
        } else {
          topLineColor = '#dcdcdc';
        }
        break;
      case SECTION_TYPE.ERROR:
      default:
        topLineColor = '#dcdcdc';
        break;
    }
  }

  if (sections.length - 1 > index) {
    switch (sections[index].type) {
      case SECTION_TYPE.DONE:
        if (sections[index + 1].type === SECTION_TYPE.DONE) {
          bottomLineColor = 'var(--company-color)';
        } else if (sections[index + 1].type === SECTION_TYPE.CURRENT) {
          bottomLineColor = COLOR_PALETTE.DARK_GRAY;
        } else {
          bottomLineColor = '#dcdcdc';
        }
        break;
      case SECTION_TYPE.CURRENT:
      case SECTION_TYPE.ERROR:
      default:
        bottomLineColor = '#dcdcdc';
        break;
    }
  }

  return { topLineColor, bottomLineColor };
};

const SideBar = ({
  sections,
  currentSection,
  setCurrentSection,
  hideErrorState,
}: ISideBarProps) => {
  return (
    <Wrapper>
      {sections.map((section, index) => (
        <Section
          key={`${section.title}-${index}`}
          {...section}
          topLineColor={getLineColors(sections, index).topLineColor}
          bottomLineColor={getLineColors(sections, index).bottomLineColor}
          index={index}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          hideErrorState={hideErrorState}
        />
      ))}
    </Wrapper>
  );
};

export { SideBar };
