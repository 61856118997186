import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function createCertificate(body) {
  return cloudRequest(serverRoutes.certificates.createCertificate, {}, body);
}

// TODO is no longer used, can it be removed?
export function getCertificates() {
  return cloudRequest(serverRoutes.certificates.getCertificates);
}

export function getUserCertificates(userId) {
  return cloudRequest(serverRoutes.certificates.getUserCertificates(userId));
}

export function getCertificate(id, { populate = [] } = {}) {
  return cloudRequest(serverRoutes.certificates.getCertificate(id), { populate });
}

export function updateCertificate(id, body) {
  return cloudRequest(serverRoutes.certificates.updateCertificate(id), {}, body);
}

export function deleteCertificate(id) {
  return cloudRequest(serverRoutes.certificates.deleteCertificate(id));
}
