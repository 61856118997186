import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, VIRTUAL_SURVEY_TASK_STATUS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import map from 'lodash/map';
import size from 'lodash/size';
import values from 'lodash/values';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { ButtonVariant } from '~/components/Buttons';
import { HeatmapFilter } from '~/components/HeatmapFilter';
import { useHeatmapFilter } from '~/components/HeatmapFilter/useHeatmapFilter';
import { ICONS } from '~/components/Icon';
import { TableGrid } from '~/components/TableGrid';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import type { IPrepareColumnProps } from '~/pages/SurveyDashboard/types';

import { Cell, VIRTUAL_SURVEY_TASK_STATUS_STYLES } from './Cell';
import { MEMBER_COLUMNS } from './MemberColumn';

import type { IColumnTable } from '~/@types/table';
import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import useSearchState from '~/hooks/useSearchState';
import { downloadSurveyMembersCSV, getSurveyMembers } from '~/services/surveys';
import convertToTimeString from '~/utils/convertToTimeString';

import { Footer, Header, Link, Title, Wrapper } from '../../../design';

import type { ISurveyMember, ISurveyParticipation } from '../../../types';
import type { ISurveyEvent, IUser } from '@learned/types';

export const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 24px;
`;

const prepareColumn = ({
  id,
  name,
  surveyMembers,
  selectedItems,
}: IPrepareColumnProps & {
  surveyMembers: ISurveyMember[];
  selectedItems: string[];
}): IColumnTable => {
  return {
    accessor: id,
    name,
    // @ts-ignore
    renderCell: (data: {
      user: string | IUser;
      isDeleted: boolean;
      events: { eventId: string; status: VIRTUAL_SURVEY_TASK_STATUS }[];
    }) => {
      return (
        <Cell
          user={(data.user as IUser).id || (data.user as string)}
          surveyMembers={surveyMembers}
          eventId={id}
          selectedItems={selectedItems}
        />
      );
    },
  };
};

const createColumns = (
  surveyMembers: ISurveyMember[],
  events: ISurveyEvent[],
  selectedItems: string[],
) => {
  return [
    MEMBER_COLUMNS[0],
    ...map(events, ({ id, startDate }: ISurveyEvent) =>
      prepareColumn({
        id,
        name: convertToTimeString(startDate),
        surveyMembers,
        selectedItems,
      }),
    ),
  ];
};

const HEATMAP_ITEMS = [
  {
    label: VIRTUAL_SURVEY_TASK_STATUS_STYLES[VIRTUAL_SURVEY_TASK_STATUS.COMPLETED].title,
    value: '80-100',
    color: '#52dbb9',
  },
  {
    label: VIRTUAL_SURVEY_TASK_STATUS_STYLES[VIRTUAL_SURVEY_TASK_STATUS.ARCHIVED].title,
    value: '60-79',
    color: '#adeee3',
  },
  {
    label: VIRTUAL_SURVEY_TASK_STATUS_STYLES[VIRTUAL_SURVEY_TASK_STATUS.DRAFT].title,

    value: '40-59',
    color: '#f6efd1',
  },
  {
    label: VIRTUAL_SURVEY_TASK_STATUS_STYLES[VIRTUAL_SURVEY_TASK_STATUS.TODO].title,
    value: '0-19',
    color: '#fd8e73',
  },
];

const MembersOverview = () => {
  const [data, setData] = useState<ISurveyParticipation[]>([]);
  const [columns, setColumns] = useState(MEMBER_COLUMNS);
  const [events, setEvents] = useState<ISurveyEvent[] | undefined>();
  const $search = useSearchState();
  const [totalCount, setTotalCount] = useState(0);
  const { pagination, changePagination, resetPagination } = usePagination(10);
  const [isLoading, setIsLoading] = useState(true);
  const { addToast } = useToasts();
  const { i18n } = useLingui();
  const { onClickItem, selectedItems } = useHeatmapFilter({
    defaultValues: ['80-100', '60-79', '40-59', '0-19'],
  });

  const params: Record<string, string | undefined> = useParams();

  const debSearch = useDebounce($search.value, 300);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [pagination.limit, pagination.skip, params.surveyId, debSearch, selectedItems]);

  const fetchData = async () => {
    if (params.surveyId) {
      setIsLoading(true);

      const { data } = await getSurveyMembers(
        params.surveyId,
        {
          search: $search.value,
        },
        {
          skip: pagination.skip,
          limit: pagination.limit,
        },
      );

      const membersData = data[API_RETURN_FIELDS.SURVEY_MEMBERS];
      const events = data[API_RETURN_FIELDS.SURVEY_EVENTS];
      setEvents(events);

      if (!events) {
        setIsLoading(false);
        return;
      }

      setColumns(createColumns(membersData, Object.values(events), selectedItems));
      setData(membersData);
      setTotalCount(data[API_RETURN_FIELDS.TOTAL]);
      setIsLoading(false);
    }
  };

  const exportCSV = async () => {
    addToast({
      title: i18n._(t`Exporting CSV`),
      subtitle: i18n._(
        t`Your CSV is being downloaded. This can take some time. It will download when it is ready.`,
      ),
      type: TOAST_TYPES.INFO,
    });

    if (params.surveyId) {
      await downloadSurveyMembersCSV(params.surveyId, {
        ...(!isEmpty($search.value) && { search: $search.value }),
      });
    }
  };

  const filters = {
    search: $search.value,
    setSearch: (value: string) => {
      $search.set(value.toLowerCase());
      resetPagination();
    },
  };

  const actionButton = {
    label: t`Export csv`,
    variant: ButtonVariant.SECONDARY,
    icon: ICONS.EXPORT,
    disabled: !size(data),
    onClick: exportCSV,
  };

  const mainTablePlaceholder = {
    isVisible: false,
    title: '',
    icon: ICONS.LOCK,
  };

  if (!size(data)) {
    mainTablePlaceholder.title = i18n._(t`Results are not yet shared`);
    mainTablePlaceholder.icon = ICONS.TIME;
    mainTablePlaceholder.isVisible = true;
  }

  return (
    <Wrapper>
      <Header>
        <Title>
          <Trans id="Participation per member" />
        </Title>
      </Header>

      <TableGrid
        data={data}
        columns={columns}
        isScrollbarVisible
        isLeftColumnsStriped
        visibleColumn={last(values(events))?.id}
        leftMinWidth="194px"
        isLoading={isLoading}
        actionButton={actionButton}
        placeholderProps={{
          noResultText: i18n._(t`No surveys found`),
          emptyStateText: i18n._(t`No surveys yet… Let’s create one!`),
          mainTable: mainTablePlaceholder,
        }}
        paginationProps={{
          pagination,
          changePagination,
          totalCount,
        }}
        filtersProps={{
          filters,
          isFiltered: false,
          isToggleHideFilterVisible: true,
          filterComponents: (
            <>
              <HeatmapFilter
                onClickItem={onClickItem}
                selectedItems={selectedItems}
                items={HEATMAP_ITEMS}
                label={i18n._(t`Heatmap`)}
              />
            </>
          ),
        }}
      />

      <Footer>
        <Link
          href="https://intercom.help/learned/en/articles/7986435-how-do-these-scores-work"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Trans>How do these scores work?</Trans>
        </Link>
      </Footer>
    </Wrapper>
  );
};

export { MembersOverview };
