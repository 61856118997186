import React, { Component, Fragment } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import emptyFn from 'emptyfunction';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';
import StatusIcon from '~/components/StatusIcon';
import AvatarCard from '~/components/UI/AvatarCard';
import AvatarGroup from '~/components/UI/AvatarGroup';

import Skill from './components/Skill';
import SkillMultiple from './components/SkillMultiple';

import { REQUEST_STATUSES, RT_FEEDBACK_SUBTYPES } from '~/constants';
import { COLOR_SET } from '~/styles';
import { getReviewQuestionsWithSkills } from '~/utils/helper';
import isValidEmail from '~/utils/isValidEmail';
import sanitizeHtml from '~/utils/sanitize';

const FeedbackTitle = styled.div`
  width: 625px;
  height: 32px;
  font-size: 16px;
  font-weight: 500;
  padding-top: 10px;
  line-height: 1;
  text-align: center;
  color: #3b3b3b;
`;

const LabelDate = styled.div`
  height: 16px;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #70747f;
`;

const FeedbackAbout = styled.div`
  border-radius: 10px;
  background-color: #f8f8f8;
  padding: 8px 16px 6px 8px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  margin-bottom: 8px;
  color: #838383;
`;

const FeedbackProvided = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #616161;
  padding: 8px;
  border-radius: 10px;
  background-color: #f8f8f8;
  margin-bottom: 8px;
  & > p {
    margin: 0;
  }
`;

const UserRTFeedback = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const UserWrapper = styled.div`
  min-width: 184px;
  margin-right: 10px;
`;

const FeedbackWrapper = styled.div`
  width: 100%;
`;

const UserFeedback = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #616161;
  padding: 8px;
  border-radius: 10px;
  background-color: #f8f8f8;
  width: 100%;
  margin-bottom: 8px;
  box-sizing: border-box;
`;

const FeedbackBlock = styled.div`
  margin-bottom: 23px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    text-transform: uppercase;
    margin-top: 23px;
    font-size: 12px;
    line-height: 1.33;
    color: ${COLOR_SET.BLUE};
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  color: #434343;
  margin-top: 23px;
  margin-bottom: 8px;
`;

const NoticeBox = styled.div`
  border-radius: 10px;
  background-color: #f8f8f8;
  padding: 8px;
  line-height: 1.5;
  color: #616161;
  margin-bottom: 10px;
`;

class FeedbackReport extends Component {
  constructor(props) {
    super(props);
    this.state = { isAllExpanded: null };
  }
  static propTypes = {
    isProvideFeedback: PropTypes.bool,
    hideQuestions: PropTypes.bool,
  };

  static defaultProps = {
    isProvideFeedback: false,
    hideQuestions: false,
    onItemClick: emptyFn,
  };

  renderSkill(question, item, isExpandedByDefault) {
    const { isAllExpanded } = this.state;
    return (
      <Fragment>
        {question.skills.map((skill, index) => (
          <Skill
            key={skill.id}
            skill={skill}
            reviewRatings={item.ratings}
            type="skill"
            skillLevel={question.levels[skill.id]}
            isExpandedByDefault={isExpandedByDefault && index === 0}
            isAllExpanded={isAllExpanded}
          />
        ))}
      </Fragment>
    );
  }

  renderQuestions(item) {
    const questions = getReviewQuestionsWithSkills(item);

    return (
      <Fragment>
        {questions.map((question, index) => this.renderSkill(question, item, index === 0))}
      </Fragment>
    );
  }

  expandItems = (isAllExpanded) => {
    this.setState({ isAllExpanded });
  };

  renderSubRTFeedbackByQuestions = (original, RTFeedbacks) => {
    const { isAllExpanded } = this.state;
    const questions = getReviewQuestionsWithSkills(original);
    const question = questions[0];

    return (
      question && (
        <Fragment>
          {question.skills.map((skill, index) => (
            <SkillMultiple
              key={skill.id}
              skill={skill}
              items={RTFeedbacks}
              type="skill"
              skillLevel={question.levels[skill.id]}
              isExpandedByDefault={index === 0}
              isAllExpanded={isAllExpanded}
            />
          ))}
        </Fragment>
      )
    );
  };

  renderSubRTFeedback = (userRTFeedback) => {
    if (isEmpty(userRTFeedback)) {
      return null;
    }

    const requestEndDate = get(userRTFeedback, 'request.meta.lastModifiedDate');
    const requestStatus =
      get(userRTFeedback, 'request.status') || get(userRTFeedback, 'requestDeletedStatus');
    return !isEmpty(userRTFeedback) ? (
      <UserRTFeedback key={userRTFeedback.id}>
        <UserWrapper>
          <AvatarCard userId={userRTFeedback.createdFor} email={userRTFeedback.createdForEmail} />
        </UserWrapper>
        <FeedbackWrapper>
          {requestStatus &&
            (requestStatus === REQUEST_STATUSES.ACCEPTED.key ? (
              <UserFeedback
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(userRTFeedback.feedback) }}
              />
            ) : (
              <StatusIcon status={requestStatus} />
            ))}
          {requestStatus === REQUEST_STATUSES.ACCEPTED.key && (
            <LabelDate>
              <Trans>Completed at: </Trans>
              {requestEndDate ? moment(requestEndDate).format('DD-MM-YYYY') : ''}
            </LabelDate>
          )}
        </FeedbackWrapper>
      </UserRTFeedback>
    ) : null;
  };

  render() {
    const {
      endDate,
      i18n,
      createdDate,
      isProvideFeedback,
      userFor,
      usersFor,
      content,
      items,
      name,
      isFrom,
      isFor,
      onItemClick,
      description,
      type,
      item,
      isSelfReview,
      original,
      hideQuestions,
    } = this.props;

    return (
      <div>
        <FeedbackTitle>
          {isSelfReview
            ? i18n._(t`Self review`)
            : isProvideFeedback
            ? i18n._(t`The feedback you have provided`)
            : i18n._(t`The feedback you have asked and received`)}
        </FeedbackTitle>
        {!isSelfReview && (
          <FeedbackBlock>
            {isFrom && (
              <Title>
                <Trans>From</Trans>
              </Title>
            )}
            {isFor && (
              <Title>
                <Trans>For</Trans>
              </Title>
            )}
            {userFor && <AvatarCard userId={userFor} />}
            {usersFor && (
              <AvatarGroup
                users={usersFor.map((u) => u.id).filter((uId) => !isValidEmail(uId))}
                emails={usersFor.map((u) => u.id).filter((uId) => isValidEmail(uId))}
              />
            )}
          </FeedbackBlock>
        )}
        <FeedbackBlock>
          <Title>
            <Trans>About</Trans>
          </Title>
          <FeedbackAbout>{name}</FeedbackAbout>
          {description && (
            <Fragment>
              <Title>
                <Trans>The message</Trans>
              </Title>
              <NoticeBox>{description}</NoticeBox>
            </Fragment>
          )}
          {!isSelfReview && (
            <LabelDate>
              <Trans>Requested at: </Trans>
              {createdDate ? moment(createdDate).format('DD-MM-YYYY') : ''}
            </LabelDate>
          )}
        </FeedbackBlock>
        <FeedbackBlock>
          <TitleWrapper>
            <Title>
              {isProvideFeedback
                ? i18n._(t`The feedback you have provided`)
                : i18n._(t`The feedback you have received`)}
            </Title>
            <div>
              {isSelfReview && (
                <Button
                  styles={{ fontWeight: 400 }}
                  type={Button.types.linkSecondary}
                  label={i18n._(t`Expand all`)}
                  onClick={() => this.expandItems(true)}
                />
              )}
              {isSelfReview && (
                <Button
                  styles={{ fontWeight: 400 }}
                  type={Button.types.linkSecondary}
                  label={i18n._(t`Collapse all`)}
                  onClick={() => this.expandItems(false)}
                />
              )}
            </div>
          </TitleWrapper>
          {!isEmpty(items) &&
            (type !== RT_FEEDBACK_SUBTYPES.SKILLS.key
              ? items.map(this.renderSubRTFeedback)
              : this.renderSubRTFeedbackByQuestions(original, items))}
          {isProvideFeedback &&
            !hideQuestions &&
            (type !== RT_FEEDBACK_SUBTYPES.SKILLS.key ? (
              <Fragment>
                <FeedbackProvided
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(content) || i18n._(t`Still waiting for feedback.`),
                  }}
                  onClick={onItemClick}
                />
                <LabelDate>
                  <Trans>Completed at: </Trans>
                  {endDate ? moment(endDate).format('DD-MM-YYYY') : ''}
                </LabelDate>
              </Fragment>
            ) : (
              this.renderQuestions(item)
            ))}
        </FeedbackBlock>
      </div>
    );
  }
}

export default withI18n()(FeedbackReport);
