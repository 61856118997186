import React, { useRef } from 'react';

import styled from 'styled-components';

import { uploadSingle } from '~/services/files';

const Cover = styled.div`
  position: relative;
`;

const HiddenInput = styled.input`
  display: none;
`;

const OverlayLabel = styled.label`
  flex: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
`;

const UploadArea = ({
  className,
  children,
  id,
  style,
  onStartUpload,
  onFinishUpload,
  onUpload,
  onError,
  uploadHandler,
  disabled,
}) => {
  const inputRef = useRef();
  const onUploadChange = async () => {
    let file = inputRef.current.files[0];
    if (file) {
      onStartUpload();
      try {
        onUpload(await uploadHandler(file));
        onFinishUpload();
      } catch (e) {
        onError(e);
      }
    }
  };

  return (
    <Cover className={className} style={style}>
      {children}
      <OverlayLabel>
        <HiddenInput
          id={id}
          ref={inputRef}
          onChange={onUploadChange}
          type="file"
          disabled={disabled}
          accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*,.pdf,.doc,.docx,.xml,.pages"
        />
      </OverlayLabel>
    </Cover>
  );
};

UploadArea.defaultProps = {
  id: 'upload-area',
  onStartUpload: () => {},
  onUpload: () => {},
  onError: () => {},
  uploadHandler: (file) => uploadSingle(file, file.name),
  disabled: false,
};

export default React.memo(UploadArea);
