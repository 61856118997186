import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import styled from 'styled-components';

import AvatarCard from '~/components/UI/AvatarCard';
import AvatarGroup from '~/components/UI/AvatarGroup';

import { COLOR_PALETTE, COLORS } from '~/styles';
import convertToTimeString from '~/utils/convertToTimeString';

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 16px -24px 0;
  padding: 0 24px 20px;
  border-bottom: 1px solid ${COLORS.BORDER_SOFT};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr repeat(3, 1fr);
  grid-column-gap: 24px;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColumnContent = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: ${COLOR_PALETTE.BLACK};
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Info = ({ owners, creators, goalCycles, deadline, createdAt }) => {
  const { i18n } = useLingui();

  const renderOwner = (userIds) => {
    return (
      <ColumnContent>
        {size(userIds) === 1 ? (
          <AvatarCard type={AvatarCard.TYPES.MEDIUM} userId={userIds[0]} />
        ) : (
          <AvatarGroup users={userIds} />
        )}
      </ColumnContent>
    );
  };

  const renderList = (items) => {
    return (
      <ColumnContent>
        <List>
          {!isEmpty(items) && items.map((item) => <Label key={item.id}>{item.name}</Label>)}
        </List>
      </ColumnContent>
    );
  };

  const renderSingle = (name) => {
    return (
      <ColumnContent>
        <List>
          <Label>{name}</Label>
        </List>
      </ColumnContent>
    );
  };

  const createColumn = (title, node) => {
    return (
      <Column>
        <Title>{title}</Title>
        {node}
      </Column>
    );
  };

  return (
    <Row>
      <Grid>
        {createColumn(i18n._(t`Owner(s)`), renderOwner(owners))}
        {createColumn(i18n._(t`Creator(s)`), renderOwner(creators))}
        {createColumn(i18n._(t`Cycle`), renderList(goalCycles))}
        {createColumn(i18n._(t`Deadline`), renderSingle(convertToTimeString(deadline)))}
        {createColumn(i18n._(t`Created`), renderSingle(convertToTimeString(createdAt)))}
      </Grid>
    </Row>
  );
};

export default React.memo(Info);
