import React, { useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import Editor from '~/components/Editor';
import RatingSlider from '~/components/RatingSlider';
import RickTextView from '~/components/RickTextView';
import ShowMore from '~/components/ShowMore';
import SkillDefinitionModal from '~/components/SkillDefinitionModal';
import Tabs from '~/components/Tabs';

import RatingStatusRows from './RatingStatusRow';
import { SkillFocusAreas } from './SkillFocusAreas';
import SubQuestionCard from './SubQuestionCard';

import { REVIEW_QUESTIONS_TABS } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { getIsRightSideBarOpen } from '~/selectors/app';
import { getCurrentReview } from '~/selectors/baseGetters';
import getLang from '~/selectors/getLang';
import { COLOR_PALETTE, COLORS } from '~/styles';
import { getFocusAreaValuesByLevels } from '~/utils/focusAreas';
import { getSkillName, getSkillDescription } from '~/utils/skillUtils';

const DefinitionBlock = styled.div`
  border-radius: 3px;
  border: solid 1px #dae4f7;
  padding: 12px 22px 14px 17px;
  margin-bottom: 8px;
`;

const DefinitionTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLORS.TEXT_PRIMARY};
`;

const StyledEditor = styled(Editor)`
  margin-top: 13px;
  margin-bottom: 16px;
  .ql-container {
    font-size: 16px;
    max-height: 550px;
  }

  // editor styles
  .ql-editor {
    line-height: 1.71;
  }

  // placeholder styles
  .ql-editor.ql-blank:before {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: ${COLOR_PALETTE.DARK_GRAY};
  }
`;

const FocusWrap = styled.div`
  box-sizing: border-box;
  margin-bottom: 12px;
  min-height: 120px;
  overflow: auto;
  background: ${COLORS.BG_PAGE};
  padding: ${(props) => (props.$isFA ? '0' : '12px 22px 12px 17px')};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.$isFA ? 'left' : 'center')};
  align-items: ${(props) => (props.$isFA ? 'top' : 'center')};
  border-radius: 3px;

  & > div {
    margin-bottom: 4px;
    padding-bottom: 4px;
  }
  & > button {
    padding: 18px;
  }
`;

const Content = styled.div`
  margin: 0 16px;
`;

const StyledRickTextView = styled(RickTextView)`
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
`;

const ReviewJobProfileSkillQuestion = ({
  i18n,
  question,
  skill,
  rating = {},
  review: reviewProp,
  forUser,
  isError = false,
  expectedLevel,
  onChange,
  tabs,
  typeRatings = [],
  onQuestionClick,
  isOpenDefault,
  color,
  isReadOnly,
}) => {
  const [currentTab, setCurrentTab] = useState(REVIEW_QUESTIONS_TABS.OWN_ANSWER.key);
  const reviewStore = useSelector(getCurrentReview);
  const lang = useSelector(getLang);
  const isRightSideBarOpen = useSelector(getIsRightSideBarOpen);
  const review = reviewProp || reviewStore;

  const onChangeSkipped = (_e, isSkipped) => {
    const updateData = { isSkipped, ...(isSkipped && { rate: 0 }) };
    return onChange(skill.id, updateData);
  };

  const handleClickQuestion = () => {
    if (onQuestionClick) {
      onQuestionClick(skill.id);
    }
  };

  const skillName = getSkillName(skill, lang);
  const indexLabel = rating.scale.indexOf(rating.rate);

  const focusAreas = getFocusAreaValuesByLevels(skill.focusAreas, rating.rate);
  const showSkillModal = useBoolState();

  return (
    <SubQuestionCard
      title={skillName}
      onClick={handleClickQuestion}
      hideFooter
      isError={isError}
      isOpenDefault={isOpenDefault}
      rowContent={({ isOpen }) =>
        !question.hideRating && (
          <RatingSlider
            value={rating.rate}
            valueExpected={expectedLevel}
            onChange={(rate) => onChange(skill.id, { rate })}
            scale={rating.scale}
            scaleLabels={rating.scaleLabels}
            isHideLabels={!isOpen}
            isDisabled={rating.isSkipped || !isOpen || isReadOnly}
            color={rating.isSkipped ? COLOR_PALETTE.DARK_GRAY : color}
            isSkippable={question.isSkippable}
            isSkipped={rating.isSkipped}
            onChangeSkipped={onChangeSkipped}
            isOnlySelectedLabel={isRightSideBarOpen} // not enough space for labels, so we display only selected rate labels (when sidebar is opened)
          />
        )
      }
    >
      <Content>
        {(skill?.definitionNL || skill?.definition) && (
          <DefinitionBlock>
            <DefinitionTitle>
              <Trans>Definition:</Trans>
            </DefinitionTitle>
            <ShowMore maxHeight={70}>
              <StyledRickTextView html={getSkillDescription(skill, lang)} />
            </ShowMore>
          </DefinitionBlock>
        )}
        <FocusWrap $isFA={focusAreas.length > 0}>
          {focusAreas.length > 0 ? (
            <SkillFocusAreas
              focusAreas={focusAreas}
              skillLabel={rating.scaleLabels[indexLabel]}
              isExpectedLevel={expectedLevel === rating.rate}
            />
          ) : (
            <Trans>The expected focus areas for the selected level level will show here</Trans>
          )}
          <Button
            type="link-primary"
            label={i18n._(t`View all levels`)}
            onClick={showSkillModal.on}
          />
        </FocusWrap>
      </Content>
      {showSkillModal.value && (
        <SkillDefinitionModal
          onModalClose={showSkillModal.off}
          skill={skill}
          scaleLabels={question.scaleLabels}
          expectedLevel={expectedLevel}
        />
      )}
      {!isEmpty(tabs) && (
        <Tabs items={tabs} isSmall currentItem={currentTab} handleChangeTab={setCurrentTab} />
      )}
      {currentTab === REVIEW_QUESTIONS_TABS.OWN_ANSWER.key ? (
        <Content>
          <StyledEditor
            value={rating.explanation || ''}
            placeholder={`${i18n._(t`Please provide your feedback`)}${
              rating.isCommentObligated ? '*' : ''
            }`}
            onChange={(explanation) => onChange(skill.id, { explanation })}
            compact
            small
            readOnly={isReadOnly}
          />
        </Content>
      ) : (
        <RatingStatusRows
          ratings={typeRatings}
          requests={review.requests}
          currentTab={currentTab}
          forUser={forUser}
        />
      )}
    </SubQuestionCard>
  );
};

export default withI18n()(ReviewJobProfileSkillQuestion);
