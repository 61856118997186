/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';

import { FocusAreaType } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import styled from 'styled-components';

import { TableList } from '~/components/TableList';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import { useSkillCategories } from '../../SkillForm/hooks';
import { SORT_OPTIONS } from '../../types';
import { createJobProfilesColumns } from '../columns/JobProfiles.columns';
import { sortJobProfiles } from '../utils';

import type { ISkillPopulated } from '@learned/types';

export const Wrapper = styled.div<{ marginTop?: string }>`
  & .tableList {
    padding: 0;
    table {
      padding: 0;
    }
    & .tableHeader {
      padding: 4px 0 10px 0;
    }
  }
  margin-top: ${({ marginTop }) => marginTop || '0'};
  margin-bottom: 32px;
`;

interface ISkillTemplateTable {
  skill: ISkillPopulated;
}

const DEFAULT_PAGINATION = 10;

const initialFilters = {
  search: '',
  sortBy: SORT_OPTIONS.NAME_A_Z,
  pagination: {
    skip: 0,
    limit: DEFAULT_PAGINATION,
    index: 1,
  },
};

const JobProfilesTable = ({ skill }: ISkillTemplateTable) => {
  const { jobProfiles } = skill;
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const { columns } = createJobProfilesColumns();
  const { skillCategories } = useSkillCategories();
  const [currentFilters, setCurrentFilters] = useState(initialFilters);

  const data = useMemo(() => {
    const skillCategory = skillCategories?.find((item) => item.id === skill.skillCategory);

    let result = jobProfiles.map((jobProfile) => {
      let requiredSkillLevel = i18n._(t`Not Selected`);
      let level = -1;
      const selectedFocusAreas = jobProfile.skills.find(
        (item) => skill.id === item.skill,
      )?.selectedFocusAreas;

      if (
        _.size(selectedFocusAreas) === 1 &&
        selectedFocusAreas?.[0].type === FocusAreaType.SELECT_ALL
      ) {
        requiredSkillLevel = i18n._(t`All levels`);
        level = Infinity;
      } else if (_.size(selectedFocusAreas) > 0) {
        const index = selectedFocusAreas?.[0]?.level;
        if (!_.isNil(index)) {
          level = Number(index);
          requiredSkillLevel = getMultiLangString(skillCategory?.skillLevels[index] || '');
        }
      }

      return {
        id: jobProfile.id,
        name: getMultiLangString(jobProfile.name),
        requiredSkillLevel,
        level,
      };
    });

    if (!_.isEmpty(currentFilters.search)) {
      result = result.filter((item) =>
        item.name.match(new RegExp(currentFilters.search.replaceAll('\\', ''), 'ig')),
      );
    }

    result = sortJobProfiles(result, currentFilters.sortBy);

    const total = _.size(result);

    const items = _.chain(result)
      .drop(currentFilters.pagination.skip || 0)
      .take(currentFilters.pagination.limit || DEFAULT_PAGINATION)
      .value();

    return { items, total };
  }, [JSON.stringify([jobProfiles, skillCategories, currentFilters])]);

  return (
    <Wrapper>
      <TableList
        className="tableList"
        topClassName="tableHeader"
        data={data.items}
        columns={columns}
        sortProps={{
          sortBy: currentFilters.sortBy,
          setSortBy: (sortBy: SORT_OPTIONS) => setCurrentFilters({ ...currentFilters, sortBy }),
        }}
        paginationProps={{
          pagination: currentFilters.pagination,
          changePagination: ({ skip, limit, index }) =>
            setCurrentFilters({
              ...currentFilters,
              pagination: { ...currentFilters.pagination, skip, limit, index },
            }),
          totalCount: data.total,
        }}
        filtersProps={{
          filters: {
            search: currentFilters.search,
            setSearch: (value: string) =>
              setCurrentFilters((prevState) => ({
                ...prevState,
                search: value,
              })),
          },
          isFiltered: !!currentFilters.search.length,
        }}
      />
    </Wrapper>
  );
};

export { JobProfilesTable };
