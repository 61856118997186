import React from 'react';
import type { ReactNode } from 'react';

import { Sticky } from 'react-sticky';

import { Button } from '~/components/Buttons/Button';
import { ButtonVariant, ButtonSize } from '~/components/Buttons/types';
import {
  Wrapper,
  Box,
  BoxRight,
  BoxRightContainer,
  NavBlock,
  Row,
  Actions,
  StickContainer,
  Title,
  TitleWrapper,
  Logo,
} from '~/components/DashboardHeader/design';
import StringInfinite from '~/components/StringInfinite';
import Tooltip, { TOOLTIP_SIZES, TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import { ICONS } from '../Icon';

export interface IDashboardHeaderProps {
  onBack?: () => void;
  actions?: ReactNode;
  title?: ReactNode;
  titleMaxWidth?: string;
  description?: ReactNode;
  className?: string;
  logoUrl?: string;
  instructions?: string;
  instructionsUrl?: string;
  maxWidth?: string | number;
  smallWidth?: boolean;
  noPadding?: boolean;
  subHeader?: ReactNode;
  status?: ReactNode;
  height?: string;
  isBoxShadow?: boolean;
  isBackIcon?: boolean;
}

export const DashboardHeader = React.memo<IDashboardHeaderProps>(
  ({
    logoUrl,
    onBack,
    actions,
    title,
    subHeader,
    status,
    isBoxShadow = false,
    isBackIcon,
    titleMaxWidth,
  }) => {
    return (
      <>
        <Wrapper isBoxShadow={isBoxShadow}>
          <Row>
            <Box>
              {logoUrl && (
                <NavBlock>
                  <Logo logoUrl={logoUrl} />
                </NavBlock>
              )}
              {onBack && (
                <NavBlock>
                  {isBackIcon ? (
                    <Button
                      label={''}
                      size={ButtonSize.MEDIUM}
                      variant={ButtonVariant.ICON}
                      icon={ICONS.BACK}
                      onClick={onBack}
                    />
                  ) : (
                    <Button
                      label={''}
                      size={ButtonSize.MEDIUM}
                      variant={ButtonVariant.CLOSE}
                      onClick={onBack}
                    />
                  )}
                </NavBlock>
              )}
              {title && (
                <TitleWrapper $hideBorder={!!logoUrl}>
                  <Tooltip
                    placement={TOOLTIP_PLACEMENTS.BOTTOM}
                    disabled={false}
                    size={TOOLTIP_SIZES.BIG}
                    tooltip={title}
                    maxWidth="300px"
                  >
                    <Title>
                      <StringInfinite maxWidth={titleMaxWidth}>{title}</StringInfinite>
                    </Title>
                  </Tooltip>
                </TitleWrapper>
              )}
              {status && status}
            </Box>
            <BoxRight>
              <BoxRightContainer>{actions && <Actions>{actions}</Actions>}</BoxRightContainer>
            </BoxRight>
          </Row>
        </Wrapper>
        {subHeader}
      </>
    );
  },
);

DashboardHeader.displayName = 'DashboardHeader';

const Navigation = React.memo((props: IDashboardHeaderProps) => {
  /* added disableHardwareAcceleration to prevent header popup overflow by page content*/
  const content = <DashboardHeader {...props} />;

  return (
    <Sticky relative disableHardwareAcceleration disableCompensation={false} topOffset={12}>
      {({ style, isSticky }) => {
        return (
          <StickContainer style={style} $isSticky={isSticky} height={props.height}>
            {content}
          </StickContainer>
        );
      }}
    </Sticky>
  );
});

export { Navigation };
export default Navigation;
