import styled, { css } from 'styled-components';

import { StyledPrimaryButton, StyledSecondaryButton } from '~/components/Buttons/design';
import { Dropdown } from '~/components/Dropdown';
import { Option, Select, SliderAnimation } from '~/components/Dropdown/design';
import Modal, { ModalContent } from '~/components/Modal';

import { COLORS } from '~/styles';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 28px;
`;

export const LeftHeader = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${COLORS.TEXT_HOVER};
`;

export const RightHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  font-size: 12px;
  color: ${COLORS.SUBTEXT};
`;

export const LeftBody = styled.div`
  max-width: 256px;
  min-width: 256px;
  border-right: 1px solid ${COLORS.BORDERS};
  padding-right: 28px;
`;

export const RightBody = styled.div`
  padding-left: 31px;
  padding-right: 31px;
  display: flex;
  flex-direction: column;
`;

export const FromToContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: ${COLORS.TEXT_HOVER};
  margin-bottom: 10px;
`;

export const FromToInput = styled.input`
  width: 184px;
  height: 32px;
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDERS};
  font-size: 14px;
  line-height: 1.86;
  color: ${COLORS.TEXT_MAIN};
  padding: 3px 10px 0;
`;

export const SelectedNumberOfDays = styled.div`
  font-size: 12px;
  color: ${COLORS.SUBTEXT};
  margin-top: 8px;
`;

export const SelectToday = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  &:hover {
    cursor: pointer;
    color: ${COLORS.COMPANY};
  }
`;

export const ResetDates = styled(SelectToday)`
  .mirror {
    transform: rotate(240deg) scaleX(-1);
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: auto;
  gap: 10px;

  ${StyledPrimaryButton} {
    max-width: 90px;
    min-width: 90px;
  }
  ${StyledSecondaryButton} {
    max-width: 90px;
    min-width: 90px;
  }
`;

export const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TimePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: black;
  font-size: 12px;
  gap: 2px;
`;

export const StyledModal = styled(Modal)`
  ${ModalContent} {
    overflow-y: visible;
  }
`;

export const StyledSelect = styled(Select)`
  width: 84px;
  border-radius: 6px;
  justify-content: unset;
  align-items: center;
  gap: 8px;
  padding: 10px;
  font-size: 14px;
  color: #46474e;
  text-align: center;
  line-height: 0;

  ${(props) =>
    props.$clicked &&
    css`
      color: #46474e;
      background-color: rgba(255, 255, 255, 0.1);
      border: solid 1px #9055ee;
    `}

  svg {
    margin: 0;
  }
`;

export const StyledDropdown = styled(Dropdown<number>)`
  width: unset;

  ${SliderAnimation} {
    width: inherit;
  }

  ${Option} {
    height: 39px;
    padding: 0 11px 0 11px;
  }
`;
