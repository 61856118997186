import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FooterRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  width: 100%;
  margin: 30px 0;
`;

export const ContentWrapper = styled.div`
  overflow: auto;
  height: 450px;
  margin-top: 28px;
`;

export const JobFamiliesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const JobFamilyItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  height: 64px;
  padding: 0 34px 0 18px;
  border-radius: 6px;
  color: ${COLORS.TEXT_MAIN};
  background-color: ${COLORS.BG_LIST};

  & .edit {
    visibility: hidden;
  }

  &:hover .edit {
    visibility: visible;
    cursor: pointer;
  }

  & .content {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;
