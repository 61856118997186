import styled from 'styled-components';

import { SearchField } from '~/components/Text';

import { COLORS } from '~/styles';

const MainCtr = styled.div`
  font-size: 14px;
`;

const CustomTooltip = styled.div`
  position: absolute;
  left: -5px;
  top: 0;
  transform: translateX(-100%);
  border: 1px solid ${COLORS.BORDERS};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  background: ${COLORS.WHITE};
  display: none;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  font-size: 10px;
  border-radius: 6px;
  z-index: 100;
`;

const TooltipHeader = styled.div`
  font-size: 12px;
  font-weight: 600;
  max-width: 200px;
`;

const TipTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
`;

const ToolTipBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const TipCircle = styled.div<{ bgClr: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ bgClr }) => bgClr};
`;

const TooltipBarCtr = styled.div`
  display: flex;
  gap: 5px;
`;

const TipValue = styled.div``;

const TipSpan = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;

const FilterCtr = styled.div`
  width: 100%;
`;

const FilterBtnCtr = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding-bottom: 20px;
  & > .icon {
    cursor: pointer;
  }
`;

const SearchCtr = styled.div`
  flex: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  & > .active:hover {
    border-color: ${COLORS.COMPANY};
    color: ${COLORS.COMPANY};
  }
  & > .active {
    border-color: ${COLORS.COMPANY};
    color: ${COLORS.COMPANY};
  }
`;

const DropDownCtr = styled.div`
  position: relative;
`;

const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  width: 320px;
  font-size: 14px;
  font-weight: 600;
  & input {
    border-radius: 10rem;
  }
`;

const PrimaryDimensionHolder = styled.div`
  height: 40px;
`;

const LoaderCtr = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  box-sizing: border-box;
  flex-direction: column;
  gap: 10px;
`;

const SortCtr = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  background: ${COLORS.WHITE};
  height: auto;
  border-radius: 6px;
  border: 1px solid ${COLORS.BORDERS};
  box-sizing: border-box;
  padding: 5px;
  font-weight: 400;
  text-transform: capitalize;
  color: ${COLORS.SUBTEXT};
`;

const SortRow = styled.div`
  height: auto;
  width: 200px;
  box-sizing: border-box;
  padding: 5px 10px;
  border-radius: 6px;
  display: flex;
  gap: 5px;
  align-items: center;
  &:hover {
    background: ${COLORS.HOVER};
  }
  & > .rotate {
    rotate: 180deg;
  }
`;

const PaginationCtr = styled.div`
  margin-top: 40px;
`;

const NoDataTextContainer = styled.span`
  color: ${COLORS.PLACEHOLDERS};
`;

export {
  MainCtr,
  SearchFieldWrapper,
  SearchCtr,
  FilterBtnCtr,
  FilterCtr,
  DropDownCtr,
  PrimaryDimensionHolder,
  LoaderCtr,
  SortCtr,
  SortRow,
  CustomTooltip,
  TooltipHeader,
  ToolTipBody,
  TooltipBarCtr,
  TipValue,
  TipTitle,
  TipCircle,
  TipSpan,
  PaginationCtr,
  NoDataTextContainer,
};
