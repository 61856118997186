import React from 'react';

import { GOAL_SORT_OPTIONS } from '@learned/constants';
import { IGoal } from '@learned/types';
import { t } from '@lingui/macro';

import { StatusLabel } from '~/components/GoalsBlock/components/StatusLabel';
import ToolTip from '~/components/Tooltip';

import type { IColumnTable } from '~/@types/table';

import { FlexRow, Label, NameRow, TD } from '../PlanGoals/columns';

import type { I18n } from '@lingui/core';

export const createColumns = (
  i18n: I18n,
  onRowClick: (item: IGoal) => void,
): IColumnTable<IGoal>[] => [
  {
    accessor: 'name',
    name: i18n._(t`Name`),
    maxWidth: '350px',
    renderCell: (item) => {
      return (
        <NameRow>
          {/* @ts-ignore */}
          <FlexRow>
            <ToolTip tooltip={item.name}>
              <Label onClick={() => onRowClick?.(item)}>{item.name}</Label>
            </ToolTip>
          </FlexRow>
        </NameRow>
      );
    },
    sortBy: {
      asc: {
        key: GOAL_SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: GOAL_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Progress`),
    accessor: 'progress',
    maxWidth: '100px',
    renderCell: (item) => {
      return (
        <NameRow>
          <FlexRow $isHidePointer>
            <TD>{`${
              item.calculatedProgress
                ? Math.round((item.calculatedProgress + Number.EPSILON) * 100) / 100
                : 0
            }%`}</TD>
          </FlexRow>
        </NameRow>
      );
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Status`),
    accessor: 'status',
    maxWidth: '150px',
    renderCell: (item) => {
      return (
        <NameRow>
          <FlexRow $isHidePointer>
            <TD>
              <StatusLabel status={item.status} progress={item?.calculatedProgress} />
            </TD>
          </FlexRow>
        </NameRow>
      );
    },
  },
];
