import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Text = styled.span`
  width: 500px;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  color: ${COLORS.TEXT_MAIN};
  margin-top: 200px;
`;

export const Subtitle = styled.span`
  font-size: 14px;
  text-align: center;
  color: ${COLORS.SUBTEXT};
  width: 449px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
`;
