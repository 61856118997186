import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';
import AboutRoleTab from '~/pages/RolePage/Tabs/AboutRoleTab';

import RolesIcon from '~/assets/mdi-ballot.svg';

import useBoolState from '~/hooks/useBoolState';
import { COLOR_PALETTE } from '~/styles';

// we use it in RolePage
// and also in JobProfileModal
const Content = ({ jobProfile }) => {
  const { i18n } = useLingui();
  const $showSkillModal = useBoolState(false);
  const [skillFromUrlShown, setSkillFromUrlShown] = useState(false);

  // Show skill from url if passed in query params
  const skillFromUrl = new URL(window.location).searchParams.get('skill');
  if (skillFromUrl && !skillFromUrlShown) {
    setSkillFromUrlShown(true);
    $showSkillModal.on();
  }

  return isEmpty(jobProfile) ? (
    <Placeholder
      title={i18n._(t`No role`)}
      subTitle={i18n._(t`We could not find role.`)}
      Icon={() => (
        <SvgIcon
          url={RolesIcon}
          width="50px"
          height="50px"
          isDefaultColor
          defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
        />
      )}
    />
  ) : (
    <AboutRoleTab jobProfile={jobProfile} onSkillClick={() => $showSkillModal.on()} />
  );
};

Content.propTypes = {
  jobProfile: PropTypes.object,
};

export default Content;
