import React from 'react';

import { REVIEW_STATUS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { UseFormReturn } from 'react-hook-form';

import Switch from '~/components/Switch';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import {
  Separator,
  SubSectionWrapper,
  SubTitle,
  ToggleContainer,
  ToggleRow,
  ToggleText,
} from '../design';

import type { GeneralSettingsType, IReviewCycleForm } from '../types';
import type { I18n } from '@lingui/core';

interface GeneralSettingsProps {
  formMethods: UseFormReturn<IReviewCycleForm>;
}

type ToggleItem = {
  key: GeneralSettingsType;
  text: (i18n: I18n) => string;
  tooltip: (i18n: I18n) => string;
};

const TOGGLE_LIST: Array<ToggleItem> = [
  {
    key: 'isCoachesAskedToScheduleReview',
    text: (i18n: I18n) => i18n._(t`Participants are asked to schedule the review in Learned`),
    tooltip: (i18n: I18n) =>
      i18n._(t`Participants receive a reminder 7 days before the end date to schedule the review`),
  },
  {
    key: 'isCalibrate',
    text: (i18n: I18n) => i18n._(t`Calibrate this review cycle`),
    tooltip: (i18n: I18n) =>
      i18n._(t`Admins and coaches can calibrate ratings before sharing them with employees`),
  },
  {
    key: 'isDigitalSign',
    text: (i18n: I18n) => i18n._(t`Digitally sign the review reports`),
    tooltip: (i18n: I18n) =>
      i18n._(
        t`Participants receive a notification 7 days before the end date to sign the review report`,
      ),
  },
  {
    key: 'isAutoArchive',
    text: (i18n: I18n) =>
      i18n._(t`Archive this review cycle automatically 30 days after the end date`),
    tooltip: (i18n: I18n) =>
      i18n._(
        t`When a review cycle is archived, it is no longer possible to make changes to reviews. Coaches can unarchive reviews if necessary`,
      ),
  },
];

const GeneralSettings = ({ formMethods }: GeneralSettingsProps) => {
  const { watch, setValue } = formMethods;
  const { i18n } = useLingui();
  const isPublished = watch('status') !== REVIEW_STATUS.DRAFT;

  const savedStartDate = new Date(watch('fetchedCycle').settings.startDate);
  const today = new Date();
  const watchCalibrate = watch('settings.isCalibrate');

  const onChangeToggle = (key: GeneralSettingsType) => {
    setValue(`settings.${key}`, !watch(`settings.${key}`), { shouldDirty: true });
  };

  const isDigitalSignDisabled = isPublished && savedStartDate && savedStartDate < today;
  const isCalibrateDisabled =
    isPublished && savedStartDate && savedStartDate <= today && watchCalibrate;

  const isDisabled = (key: GeneralSettingsType) => {
    if (key === 'isCalibrate') {
      return isCalibrateDisabled;
    }
    if (key === 'isDigitalSign') {
      return isDigitalSignDisabled;
    }
    return false;
  };

  return (
    <SubSectionWrapper>
      <SubTitle>
        <Trans>General</Trans>
      </SubTitle>
      <Separator />
      <ToggleContainer>
        {TOGGLE_LIST.map((item) => (
          <ToggleRow key={item.key}>
            <ToolTip
              tooltip={i18n._(
                t`It is not possible to change this setting after the review cycle has started`,
              )}
              disabled={!isDisabled(item.key)}
            >
              <span>
                <Switch
                  disabled={isDisabled(item.key)}
                  onChange={() => onChangeToggle(item.key)}
                  checked={watch(`settings.${item.key}`)}
                />
              </span>
            </ToolTip>
            <ToolTip tooltip={item.tooltip(i18n)} placement={TOOLTIP_PLACEMENTS.BOTTOM}>
              <ToggleText>{item.text(i18n)}</ToggleText>
            </ToolTip>
          </ToggleRow>
        ))}
      </ToggleContainer>
    </SubSectionWrapper>
  );
};

export { GeneralSettings };
