import { REQUEST_STATUSES, NOMINATE_TYPE } from '~/constants';
import getUserCoaches from '~/utils/getUserCoaches';
import { getRequestsUsers } from '~/utils/requestsUtils';

export function getAvailableForNominateUsers(users, teams, review, requestType) {
  const nominatedUsers = getRequestsUsers(
    review.requests,
    (r) => [REQUEST_STATUSES.CANCELLED.key, REQUEST_STATUSES.REJECTED.key].indexOf(r.status) === -1,
  );

  const availableUserIds = Object.values(users)
    .map((u) => u.id)
    .filter(
      (u) =>
        !nominatedUsers.peers.concat(nominatedUsers.coaches).includes(u) && u !== review.createdFor,
    );

  const userCoaches = getUserCoaches(review.createdFor, teams, { companyId: review.company });

  let idsForNominate = [];

  switch (requestType) {
    case NOMINATE_TYPE.conversationCoach:
      idsForNominate = userCoaches.filter((u) => !review.conversationCoaches.includes(u));
      break;
    case NOMINATE_TYPE.coach:
      idsForNominate = availableUserIds.filter(
        (u) => userCoaches.includes(u) && !nominatedUsers.peers.includes(u),
      );
      break;
    case NOMINATE_TYPE.peer:
      idsForNominate = availableUserIds;
      break;
    default:
      break;
  }

  return idsForNominate.map((id) => users[id]).filter((user) => user);
}
