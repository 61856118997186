import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { HeaderTabs } from '~/components/HeaderTabs';
import NoCalendarIntegrationWarning from '~/components/Warnings/NoCalendarIntegrationWarning';
import BaseLayout from '~/layouts/BaseLayout';

import { AllTab } from './tabs/AllTab';
import { PersonalTab } from './tabs/Personal';
import { ReviewCycleTab } from './tabs/ReviewCycle';
import { TemplatesTab } from './tabs/Templates';
import { ThemesTab } from './tabs/Themes';

import { getUser } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';

import type { I18n } from '@lingui/core';

const HeaderWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  width: fit-content;
  border-radius: 6px 6px 0 0;
`;

export const TABS_ENUM = {
  ABOUT_YOU: 'about-you',
  ALL: 'all',
  REVIEW_CYCLES: 'review-cycles',
  TEMPLATES: 'templates',
  THEMES: 'themes',
};

const Overview = () => {
  const user = useSelector(getUser);

  // define tabs
  const TABS = [
    {
      label: (i18n: I18n) => i18n._(t`About you`),
      key: TABS_ENUM.ABOUT_YOU,
    },
    {
      label: (i18n: I18n) => i18n._(t`All`),
      key: TABS_ENUM.ALL,
    },
    (user.isAdmin || user.isCoach) && {
      label: (i18n: I18n) => i18n._(t`Review cycles`),
      key: TABS_ENUM.REVIEW_CYCLES,
    },
    user.isAdmin && {
      label: (i18n: I18n) => i18n._(t`Templates`),
      key: TABS_ENUM.TEMPLATES,
    },
    user.isAdmin && {
      label: (i18n: I18n) => i18n._(t`Themes`),
      key: TABS_ENUM.THEMES,
    },
  ].filter((i) => i);

  const currentHash = window.location.hash || TABS_ENUM.ABOUT_YOU;
  const defaultTab = TABS.find((item) => item.key === currentHash);
  const [currentTab, setCurrentTab] = useState(defaultTab?.key as string);

  const handleChangeTab = (key: string) => {
    window.location.hash = key;
    setCurrentTab(key);
  };

  useEffect(() => {
    setCurrentTab(window.location.hash.split('#')[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash]);

  return (
    <>
      <BaseLayout smallWidth>
        <NoCalendarIntegrationWarning />
        <HeaderWrapper>
          <HeaderTabs tabs={TABS} selectedTab={currentTab} handleChangeTab={handleChangeTab} />
        </HeaderWrapper>
        {currentTab === TABS_ENUM.ABOUT_YOU && <PersonalTab />}
        {currentTab === TABS_ENUM.ALL && <AllTab />}
        {currentTab === TABS_ENUM.REVIEW_CYCLES && <ReviewCycleTab />}
        {currentTab === TABS_ENUM.TEMPLATES && <TemplatesTab />}
        {currentTab === TABS_ENUM.THEMES && <ThemesTab />}
      </BaseLayout>
    </>
  );
};

export { Overview };
