import React from 'react';

import styled from 'styled-components';

import { IItem } from '~/components/SurveyQuestionReport/SurveyQuestion';

import { StarAnswer } from './StarAnswer';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  height: 253px;
  margin: 3px 0 60px 0px; // 60px space for legend
  padding: 10px 0 0; // 10px padding from top
  border-radius: 6px;
  background-color: ${COLORS.HOVER};
`;

const Answers = styled.div<{ size: number }>`
  width: 100%;
  align-items: flex-end;
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: ${(props) => `repeat(${props.size}, minmax(50px, 1fr))`};
`;

interface IStarsGraphProps {
  item: IItem;
}

const StarsGraph = ({ item }: IStarsGraphProps) => {
  return (
    <Wrapper>
      <Answers size={item.answers.length}>
        {item.answers.map((answer, key) => (
          <StarAnswer key={key} {...answer} labelNumber={key + 1} />
        ))}
      </Answers>
    </Wrapper>
  );
};

export { StarsGraph };
