import React from 'react';

import { Trans } from '@lingui/macro';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import SvgLoader from '~/components/SvgLoader';

import InfoIcon from '~/assets/icons-24-px-info-duo-color.svg';

import { COLOR_PALETTE } from '~/styles';

const LearnMoreWrapper = styled.div`
  align-items: center;
  display: flex;
  margin: 12px 0 24px;

  > * {
    margin-right: 8px;
  }
`;

const LearnMoreLinkWrapper = styled.a`
  color: var(--company-color);
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-decoration: none;
  margin-left: 8px;
`;

const LearnMoreTextWrapper = styled.div`
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
`;

const LearnMore = ({ label, LearnMoreLink }) => {
  return (
    <LearnMoreWrapper>
      <SvgLoader width="24px" height="24px" url={InfoIcon} />
      <LearnMoreTextWrapper>
        {label}
        <LearnMoreLinkWrapper href={LearnMoreLink} target="_blank" rel="noopener noreferrer">
          <Trans>Learn more</Trans>
        </LearnMoreLinkWrapper>
      </LearnMoreTextWrapper>
    </LearnMoreWrapper>
  );
};

LearnMore.propTypes = {
  label: PropTypes.string,
  LearnMoreUrl: PropTypes.string,
};

export default LearnMore;
