import React, { useEffect, useState } from 'react';

import { LEARNING_REPORT_MEMBERS_SORT_OPTIONS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import styled from 'styled-components';

import PaginationBar from '~/components/PaginationBar';
import { Table } from '~/components/Table';
import { useToasts, TOAST_TYPES } from '~/components/Toast';

import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import { downloadLearningReportMembersCSV, getLearningReportMembers } from '~/services/activities';

import { MEMBERS_COLUMN } from '../config';
import { Header } from '../Header';
import { Placeholder } from '../Placeholder';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
`;

interface ITeamsProps {
  startDate?: string;
}

const Members = ({ startDate }: ITeamsProps) => {
  const [reportData, setReportData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { pagination, changePagination, changePageSize, resetPagination } = usePagination(10);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState(LEARNING_REPORT_MEMBERS_SORT_OPTIONS.COMPLETED_HIGH_LOW);
  const { addToast } = useToasts();
  const { i18n } = useLingui();

  // do not send request per each symbol, add some delay
  // to let admin type till the end
  const debSearch = useDebounce(search, 300);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const { data } = await getLearningReportMembers({
        startDate,
        endDate: new Date(),
        search: isEmpty(search) ? undefined : search,
        sortBy,
        skip: pagination.skip,
        limit: pagination.limit,
      });

      setReportData(data.report);
      setTotalCount(data.total);
      setIsLoading(false);
    };

    fetchData();

    // eslint-disable-next-line
  }, [pagination.limit, pagination.skip, debSearch, sortBy, startDate]);

  useEffect(() => {
    setIsLoading(true);
  }, [search]);

  const onDownloadMembersCSV = async () => {
    addToast({
      title: i18n._(t`Exporting CSV`),
      subtitle: i18n._(
        t`Your CSV is being downloaded. This can take some time. It will download when it is ready.`,
      ),
      type: TOAST_TYPES.INFO,
    });

    // Request and download reviews CSV data
    // add row (without pagination)
    await downloadLearningReportMembersCSV({
      startDate,
      endDate: new Date(),
      search: isEmpty(search) ? undefined : search,
      sortBy,
    });
  };

  return (
    <Wrapper>
      <Header
        search={search}
        setSearch={(value) => {
          setSearch(value);
          resetPagination(); // go to first page
        }}
        exportCSV={onDownloadMembersCSV}
      />

      <Table
        data={reportData}
        // @ts-ignore
        columns={MEMBERS_COLUMN}
        sortBy={sortBy}
        setSortBy={setSortBy}
      />

      {!size(reportData) ? (
        <Placeholder isFiltered={!!search.length} isLoading={isLoading} />
      ) : (
        <PaginationBar
          pagination={pagination}
          changePagination={changePagination}
          changePageSize={changePageSize}
          count={totalCount}
          noShadow
          noBorder
          noTopBorder
          showCount
        />
      )}
    </Wrapper>
  );
};

export { Members };
