import React from 'react';

import { useHistory } from 'react-router';
import styled from 'styled-components';

import AvatarCard from '~/components/UI/AvatarCard';

import getUserPublicProfileURL from '~/utils/getUserPublicProfileURL';

const AvatarWrapper = styled.div`
  padding-left: 14px;
  display: flex;
  align-items: center;
`;

const ColInner = styled.div`
  display: flex;
  height: 100%;
  & > div {
    max-width: 250px;
  }
`;

const AvatarColumn = ({ userId, isWaiting }) => {
  const history = useHistory();

  return (
    <AvatarWrapper>
      <ColInner>
        <AvatarCard
          userId={userId}
          size={28}
          isEmailStyles
          type={AvatarCard.TYPES.SMALL}
          onClick={isWaiting ? undefined : () => history.push(getUserPublicProfileURL(userId))}
          isDisabled={isWaiting}
        />
      </ColInner>
    </AvatarWrapper>
  );
};

export default React.memo(AvatarColumn);
