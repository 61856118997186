import React from 'react';

import { REVIEW_PARTICIPATION_COLUMNS, TASK_STATUS, USER_REVIEW_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { CellViewReviewStatus } from './CellViewReviewStatus';
import { CellViewSigned } from './CellViewSigned';
import { CellViewTaskStatus } from './CellViewTaskStatus';

import type { ICell } from '../types';

interface ICellEmployeeProps {
  cell: ICell;
}

const CellEmployee = ({ cell }: ICellEmployeeProps) => {
  const { i18n } = useLingui();

  switch (cell?.columnId) {
    case REVIEW_PARTICIPATION_COLUMNS.SELF_REVIEW:
    case REVIEW_PARTICIPATION_COLUMNS.PEER_REVIEW:
    case REVIEW_PARTICIPATION_COLUMNS.COACH_REVIEW:
    case REVIEW_PARTICIPATION_COLUMNS.NOMINATED_PEERS:
    case REVIEW_PARTICIPATION_COLUMNS.PEER_REVIEW_PROVIDED:
    case REVIEW_PARTICIPATION_COLUMNS.COACH_REVIEW_PROVIDED:
      return (
        <CellViewTaskStatus columnId={cell.columnId} status={cell.value as TASK_STATUS | null} />
      );
    case REVIEW_PARTICIPATION_COLUMNS.COMPLETED_REVIEW:
      return (
        <CellViewReviewStatus
          columnId={cell.columnId}
          status={cell.value as unknown as USER_REVIEW_STATUS | null}
          withWarning={cell.withWarning}
          warningTooltip={i18n._(t`No guests`)}
        />
      );
    case REVIEW_PARTICIPATION_COLUMNS.SIGNED:
      return <CellViewSigned cell={cell} />;
    default:
      return null;
  }
};

export { CellEmployee };
