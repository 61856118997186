import React, { FunctionComponent } from 'react';

import { IActivity } from '@learned/types';
import YouTube from 'react-youtube';
import styled from 'styled-components';

import { ACTIVITY_TYPES } from '~/constants';
import abbr from '~/utils/abbr';

const Image = styled.div``;

const CoverImg = styled.div<{
  $width?: string;
  $height?: string;
  $styles?: { borderRadius?: string };
}>`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  background-color: #edf0f7;
  border-radius: 6px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var(--company-color);
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;

  & iframe.youtube-video-cover {
    border-radius: 6px;
    margin-top: 5px; // we need this to align video
    ${(props) => props?.$styles?.borderRadius && `border-radius: ${props?.$styles?.borderRadius}`}
  }
`;

interface IActivityImage {
  activity: IActivity;
  width?: string;
  height?: string;
  styles?: object;
}

const ActivityImage: FunctionComponent<IActivityImage> = ({
  activity,
  width,
  height,
  styles = {},
}) => {
  const shortName = abbr(activity.name, 2);
  const isYoutubeVideo = activity.type === ACTIVITY_TYPES.VIDEO.key && activity.link;

  return (
    <Image>
      {!isYoutubeVideo && (
        <CoverImg
          $width={width}
          $height={height}
          style={{
            backgroundImage: activity.coverUrl ? 'url(' + activity.coverUrl + ')' : 'none',
            ...styles,
          }}
        >
          {!activity.coverUrl && shortName}
        </CoverImg>
      )}

      {/* YOUTUBE */}
      {isYoutubeVideo && (
        <CoverImg $width={width} $height={height} style={styles} $styles={styles}>
          <YouTube
            className="youtube-video-cover"
            videoId={activity.link}
            opts={{
              width: width || '100%',
              height: height || '100%',
            }}
          />
        </CoverImg>
      )}
    </Image>
  );
};

export { ActivityImage };
