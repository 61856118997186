import React from 'react';

import emptyFn from 'emptyfunction';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import Tooltip from '~/components/Tooltip';

import { COLOR_PALETTE } from '~/styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Button = styled.button`
  height: ${(props) => (props.$overrideHeight ? props.$overrideHeight : props.$size)}px;
  width: ${(props) => (props.$overrideWidth ? props.$overrideWidth : props.$size)}px;
  border-radius: ${(props) => props.$size / 4}px;
  flex: ${(props) => props.$size}px 0 0;
  box-shadow: ${(props) =>
    props.$size >= 36 && props.$shadow && !props.$noBorder
      ? '2px 8px 8px 0 rgba(0, 0, 0, 0.08)'
      : 'none'};
  border: solid ${(props) => (props.$noBorder ? '0px' : '1px')} ${(props) => props.$color};
  background-color: ${(props) => (props.$fill ? props.$color : 'transparent')};
  color: ${(props) => (props.$fill ? '#fff' : props.$color)};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  font-size: 28px;
  font-weight: 600;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: relative;

  span {
    display: none;
  }

  &:hover span {
    width: 148px;
    max-height: 56.5px;
    border-radius: 8px;
    padding: 8px;
    box-sizing: border-box;
    box-shadow: 1px 4px 8px 0 rgba(25, 42, 70, 0.08);
    border: solid 0.5px #ecedf0;
    background-color: ${COLOR_PALETTE.WHITE};
    display: block;
    z-index: 1000;
    left: 0px;
    margin: 10px;
    position: absolute;
    top: 35px;
    text-decoration: none;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    text-align: center;
    color: #3b3b3b;
  }

  p {
    font-size: 10px;
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: ${(props) => (props.$fill ? '#fff' : props.$color)};
  }

  &:disabled {
    cursor: no-drop;
  }
`;

const IconButton = ({
  children,
  onClick,
  fill,
  disabled,
  color,
  colorIcon,
  className,
  style,
  size,
  tabIndex,
  onBlur,
  loading,
  title,
  overrideHeight,
  overrideWidth,
  shadow,
  noBorder,
  tooltip,
}) => {
  const button = (
    <Button
      onClick={disabled ? null : onClick}
      onBlur={onBlur}
      $fill={fill}
      disabled={disabled}
      $color={disabled ? '#ecedf0' : color || 'var(--company-color)'}
      className={className}
      $loading={loading}
      style={style}
      $size={size}
      $overrideHeight={overrideHeight}
      $overrideWidth={overrideWidth}
      tabIndex={tabIndex}
      $title={title}
      $shadow={shadow}
      $noBorder={noBorder}
    >
      {typeof children === 'string'
        ? children
        : React.cloneElement(children, {
            fill: disabled
              ? COLOR_PALETTE.GRAY_MIDDLE
              : colorIcon || color || 'var(--company-color)',
          })}
    </Button>
  );

  return tooltip ? <Tooltip tooltip={tooltip}>{button}</Tooltip> : button;
};

IconButton.propTypes = {
  disabled: PropTypes.bool,
  fill: PropTypes.bool,
  size: PropTypes.number,
  className: PropTypes.string,
  color: PropTypes.string,
  colorIcon: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  loading: PropTypes.bool,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  noBorder: PropTypes.bool,
};

IconButton.defaultProps = {
  fill: false,
  disabled: false,
  shadow: true,
  className: '',
  color: '',
  colorIcon: '',
  size: 48,
  onClick: emptyFn,
  onBlur: emptyFn,
  loading: false,
  title: '',
};

export default React.memo(IconButton);
