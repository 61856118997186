import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import StringInfinite from '~/components/StringInfinite';
import SvgIcon from '~/components/SvgIcon';
import Tooltip from '~/components/Tooltip';

import { COLOR_PALETTE, COLORS } from '~/styles';

export const IconCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  min-width: 48px;
  background: ${(props) => props.$iconBackground};
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  margin: -1px 0 -1px -1px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  color: ${COLOR_PALETTE.DARK_GRAY};
  background-color: ${COLORS.BG_PAGE};
  padding: 2px 12px;
  position: relative;
  border-radius: 3px;
  margin-left: 22px;
  max-width: 120px;
  &:before {
    position: absolute;
    content: '';
    border-style: solid;
    border-width: 6px 6px 6px 0;
    border-color: transparent ${COLORS.BG_PAGE} transparent transparent;
    position: absolute;
    left: -6px;
    top: 6px;
    width: 0;
    height: 0;
  }
`;

const MainRow = styled.div`
  display: flex;
  height: auto;
  min-height: ${(props) => props.height}px;
  width: ${(props) => (props.width ? props.width : 100)}%;
  box-sizing: border-box;
  background-color: ${(props) => props.background};
  margin-bottom: 8px;
  border: 1px solid ${COLORS.BG_PAGE};
  border-radius: 6px;

  &:hover {
    box-shadow: ${(props) => (props.hideShadow ? 'none' : '0 2px 6px 0 rgba(107, 120, 164, 0.3)')};
  }
`;

const InfoCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 12px;
  width: 100%;
  overflow: hidden;
`;

const ButtonsCol = styled.div`
  display: flex;
  align-items: center;
  margin: 0 16px 0 auto;
`;

const Title = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${COLOR_PALETTE.BLACK};
  max-width: ${(props) => `${props.maxWidth}px`};
  cursor: ${(props) => (props.isPointer ? 'pointer' : 'default')};
  width: 100%;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) =>
    props.$isLabel ? 'calc(100% - 160px)' : '100%'}; // 160 label width + padding
`;

const Subtitle = styled.div`
  font-size: 14px;
  color: ${COLOR_PALETTE.DARK_GRAY};
  max-width: 400px;
`;

const ColoredRow = ({
  title,
  subtitle,
  rowColor = COLORS.BG_PAGE,
  rowStartColor = COLOR_PALETTE.BLUE_DARK,
  rowIcon,
  rowIconBackground,
  rowIconColor,
  buttons,
  children,
  onClick,
  height,
  label,
  width,
  maxWidth,
  styles,
  hideShadow,
  infoColStyles,
  ...extraProps
}) => {
  return (
    <MainRow
      background={rowColor}
      color={rowStartColor}
      height={height}
      style={styles}
      hideShadow={hideShadow}
      width={width}
      {...extraProps}
    >
      {rowIcon && (
        <IconCol $iconBackground={rowIconBackground}>
          <SvgIcon
            classNameIcon="img"
            isDefaultColor
            defaultColor={rowIconColor}
            width="20px"
            height="20px"
            url={rowIcon}
          />
        </IconCol>
      )}
      {(title || label || subtitle) && (
        <InfoCol style={infoColStyles}>
          <TitleRow $isLabel={Boolean(label)}>
            <Tooltip tooltip={title} maxWidth="400px">
              <Title
                onClick={onClick ? onClick : () => {}}
                isPointer={Boolean(onClick)}
                maxWidth={maxWidth}
              >
                <StringInfinite>{title}</StringInfinite>
              </Title>
            </Tooltip>
            {label && (
              <Label>
                <StringInfinite>{label}</StringInfinite>
              </Label>
            )}
          </TitleRow>
          {subtitle && (
            <Subtitle>
              <StringInfinite>{subtitle}</StringInfinite>
            </Subtitle>
          )}
        </InfoCol>
      )}
      {children}
      {buttons && <ButtonsCol>{buttons}</ButtonsCol>}
    </MainRow>
  );
};

ColoredRow.propTypes = {
  onClick: PropTypes.func,
  height: PropTypes.number,
  styles: PropTypes.object,
  rowStartColor: PropTypes.string,
  rowColor: PropTypes.string,
  infoColStyles: PropTypes.object,
};

ColoredRow.defaultProps = {
  height: 64,
};

export default ColoredRow;
