import React, { memo, MouseEventHandler } from 'react';

import { IReviewInvitationTemplate } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';

import ToolTip from '~/components/Tooltip';
import { DateRow, FlexRow, TextRow } from '~/pages/Conversations/design';

import type { IColumnTable } from '~/@types/table';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import convertToTimeString from '~/utils/convertToTimeString';

import type { I18n } from '@lingui/core';

const NameColumn = memo(
  ({
    item,
    onClick,
  }: {
    item: IReviewInvitationTemplate;
    onClick?: MouseEventHandler<HTMLDivElement>;
  }) => {
    const getMultiLangString = useMultiLangString();
    const name = !isEmpty(item.name) ? `${getMultiLangString(item.name)}` : '';

    return (
      <TextRow>
        {/* @ts-ignore */}
        <FlexRow onClick={() => onClick(item)}>
          <ToolTip tooltip={name}>
            <span>{name}</span>
          </ToolTip>
        </FlexRow>
      </TextRow>
    );
  },
);

const COLUMNS: IColumnTable<IReviewInvitationTemplate>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'name',
    minWidth: '400px',
    maxWidth: '500px',
    renderCell: (item, onClick: MouseEventHandler<HTMLDivElement> | undefined) => {
      return <NameColumn item={item} onClick={onClick} />;
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Last Updated`),
    accessor: 'date',
    minWidth: '95px',
    renderCell: (item: IReviewInvitationTemplate) => {
      const lastUpdate = item.meta?.lastModifiedDate || item.meta?.createdDate;
      return (
        <>
          {lastUpdate ? (
            <DateRow>{`${convertToTimeString(lastUpdate)}`}</DateRow>
          ) : (
            <DateRow unplanned>
              <Trans>Not planned</Trans>
            </DateRow>
          )}
        </>
      );
    },
  },
];

export { COLUMNS };
