import { useState } from 'react';

export default function useStringState(def = '') {
  const [value, set] = useState(def);

  return {
    value,
    set: (val) => set(val),
    setForInput: (e) => set(e.target.value),
    has: (source) => (source || '').toLowerCase().indexOf((value || '').toLowerCase()) !== -1,
    clear: () => set(''),
  };
}
