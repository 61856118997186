import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import IconButton from '~/components/IconButton';
import TextField from '~/components/TextField';
import Tooltip from '~/components/Tooltip';

import { COLOR_PALETTE } from '~/styles';

const TextFieldWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const IconButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const TextFieldWithIconPadding = styled(TextField)`
  padding-right: 48px;
`;

function TextFieldWithButton({ placeholder, onSubmit, disabled, noDuplicateList }) {
  const [name, setName] = useState('');
  const isDisabled = disabled || name.trim().length === 0;
  const isDuplicate = noDuplicateList.includes(name);
  const { i18n } = useLingui();

  const onAdd = (e) => {
    onSubmit(name, e);
    setName('');
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      !isDisabled && !isDuplicate && onAdd(event);
    }
  };

  return (
    <TextFieldWrapper>
      <TextFieldWithIconPadding
        value={name}
        onChange={(e) => setName(e.target.value)}
        maxLength={500}
        placeholder={placeholder}
        disabled={disabled}
        onKeyPress={handleKeyPress}
      />
      <IconButtonWrapper>
        <Tooltip
          tooltip={isDuplicate && i18n._(t`Talking point with such name is already exists in 1:1`)}
          disabled={!isDuplicate}
        >
          <div>
            <IconButton
              size={48}
              onClick={onAdd}
              noBorder={true}
              disabled={isDisabled || isDuplicate}
              color="none"
            >
              <div>
                <Icon
                  icon={ICONS.ADD_PLUS}
                  size={ICON_SIZES.LARGE}
                  color={
                    isDisabled || isDuplicate ? COLOR_PALETTE.GRAY_MIDDLE : 'var(--company-color)'
                  }
                />
              </div>
            </IconButton>
          </div>
        </Tooltip>
      </IconButtonWrapper>
    </TextFieldWrapper>
  );
}

export default TextFieldWithButton;
