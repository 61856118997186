import React from 'react';

import styled from 'styled-components';

import { UserAvatar } from '~/components/UserAvatar';

import { ColumnPosition } from '~/@types/table';
import type { IColumnTable } from '~/@types/table';

import type { IUser } from '@learned/types';

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 59px;
  padding: 0 16px;
  max-width: 200px;
`;

const MEMBER_COLUMNS: IColumnTable<{ user: IUser | string; isDeleted: boolean }>[] = [
  {
    name: 'Members',
    accessor: 'member',
    renderCell: (cell) => {
      return (
        <Row>
          <UserAvatar
            userId={(cell?.user as IUser)?.id ?? cell.user}
            options={{ isDeleted: cell.isDeleted }}
          />
        </Row>
      );
    },
    isFixed: true,
    position: ColumnPosition.LEFT,
    padding: 'none',
  },
];

export { MEMBER_COLUMNS };
