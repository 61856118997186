import React from 'react';

import { Trans } from '@lingui/macro';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import DashboardHeader from '~/components/DashboardHeader';
import { ICONS } from '~/components/Icon';
import RickTextView from '~/components/RickTextView';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SkillCategory } from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateForm/components/SkillCategory';

import { FocusAreas } from './components/FocusAreas';
import { JobProfilesTable } from './components/JobProfilesTable';
import {
  Definition,
  Details,
  Divider,
  Header,
  Levels,
  StyledButton,
  Title,
  Wrapper,
} from './design';

import { SKILL_EDIT } from '~/constants/routes/skills';
import { useFromQuery } from '~/hooks/useFromQuery';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import { useSkill } from '../SkillEdit/hooks';
import { useSkillCategories } from '../SkillForm/hooks';

function SkillView() {
  const { goBack } = useFromQuery({ includeHash: true });
  const { skill, isLoading } = useSkill();
  const { skillCategories, isLoading: isSkillCategoriesLoading } = useSkillCategories();
  const getMultiLangString = useMultiLangString();

  const skillCategory = skillCategories.find((category) => category.id === skill?.skillCategory);

  return (
    <>
      {skill && (
        <DashboardHeader
          title={getMultiLangString(skill.name)}
          onBack={goBack}
          actions={
            <StyledButton
              type="button"
              label={<Trans>Edit</Trans>}
              variant={ButtonVariant.TEXT_PRIMARY}
              icon={ICONS.EDIT_PENCIL}
              size={ButtonSize.MEDIUM}
              onClick={() => SKILL_EDIT.go({}, { skillId: skill?.id, isBackPath: true })}
            />
          }
        />
      )}
      <Wrapper>
        <ShowSpinnerIfLoading loading={isLoading || isSkillCategoriesLoading}>
          <>
            {skill && skillCategory && (
              <>
                <Details>
                  <Header>
                    <Title>{getMultiLangString(skill.name)}</Title>
                    <SkillCategory>{getMultiLangString(skillCategory.name)}</SkillCategory>
                  </Header>
                  <Definition>
                    <h2>
                      <Trans>Description</Trans>
                    </h2>
                    <RickTextView
                      html={getMultiLangString(skill.description)}
                      color={COLORS.ICONS_PRIMARY}
                      isHtmlWithoutStyles={undefined}
                      className={undefined}
                    />
                  </Definition>
                </Details>
                <Divider />

                <Levels>
                  <h2>
                    <Trans>Levels & Focus area&apos;s</Trans>
                  </h2>
                  {skillCategory.skillLevels.map((skillLevel, index) => {
                    const focusAreas = skill.focusAreas.find((area) => area.level === index);
                    return focusAreas ? (
                      <FocusAreas focusAreas={focusAreas} key={index} levelName={skillLevel} />
                    ) : null;
                  })}
                </Levels>

                <Divider />
                <Levels>
                  <h2>
                    <Trans>Jobs that require this skill</Trans>
                  </h2>
                </Levels>
                <JobProfilesTable skill={skill} />
              </>
            )}
          </>
        </ShowSpinnerIfLoading>
      </Wrapper>
    </>
  );
}

export { SkillView };
