import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import Tooltip from '~/components/Tooltip';

import { COLORS, COLOR_PALETTE } from '~/styles';

const FooterRow = styled.div`
  display: flex;
  border-top: 1px solid ${COLORS.BG_PAGE};
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  padding: ${(props) => (props.$isActive ? '20px 24px 20px 16px' : '20px 24px')};
  div {
    display: flex;
    cursor: pointer;
    outline: none;
  }
`;

const RightActionsWrap = styled.div`
  display: flex;
  align-items: center;
  height: 47px;
  margin: 0 24px;
  padding-right: 25px;
  box-sizing: border-box;
  border-right: solid 1px ${COLORS.BORDER_HARD};
`;

const LeftActionsWrap = styled.div`
  margin-right: auto;
`;

const ButtonContainer = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLOR_PALETTE.GRAY_MIDDLE};

  :hover svg {
    color: ${COLORS.TEXT_ACTIVE};
  }
`;

const Footer = ({
  removeItem,
  updateItem,
  footerRightActions,
  footerLeftActions,
  isActive,
  tooltip,
  copyItem,
  copyTooltip,
}) => {
  return (
    <FooterRow $isActive={isActive}>
      {footerLeftActions && <LeftActionsWrap>{footerLeftActions}</LeftActionsWrap>}
      {footerRightActions && <RightActionsWrap>{footerRightActions}</RightActionsWrap>}
      {updateItem && (
        <ButtonContainer>
          <div onClick={updateItem} role="button" tabIndex={0} onKeyDown={updateItem}>
            <Icon icon={ICONS.EDIT_PENCIL} size={ICON_SIZES.LARGE} />
          </div>
        </ButtonContainer>
      )}
      {copyItem && (
        <Tooltip tooltip={copyTooltip} disabled={!copyTooltip}>
          <ButtonContainer onClick={copyItem} role="button" tabIndex={0}>
            <Icon icon={ICONS.DUPLICATE} size={ICON_SIZES.LARGE} />
          </ButtonContainer>
        </Tooltip>
      )}
      {removeItem && (
        <Tooltip tooltip={tooltip} disabled={!tooltip}>
          <ButtonContainer>
            <div onClick={removeItem} role="button" tabIndex={0} onKeyDown={removeItem}>
              <Icon icon={ICONS.DELETE_BIN} size={ICON_SIZES.LARGE} />
            </div>
          </ButtonContainer>
        </Tooltip>
      )}
    </FooterRow>
  );
};

Footer.propTypes = {
  footerLeftActions: PropTypes.node,
  footerRightActions: PropTypes.node,
  removeItem: PropTypes.func,
  updateItem: PropTypes.func,
  isActive: PropTypes.bool,
};

export default Footer;
