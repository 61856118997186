import React from 'react';

import * as PropTypes from 'prop-types';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const InlineSvg = ({
  children,
  viewBox,
  d,
  height,
  width,
  fill,
  style,
  className,
  opacity,
  childrenFirst,
}) => {
  return (
    <svg viewBox={viewBox} height={height} width={width} style={style} className={className}>
      {childrenFirst &&
        (typeof children === 'function' ? children(fill || 'var(--company-color)') : children)}
      {d && <path fill={fill || 'var(--company-color)'} d={d} opacity={opacity} />}
      {!childrenFirst &&
        (typeof children === 'function' ? children(fill || 'var(--company-color)') : children)}
    </svg>
  );
};

InlineSvg.propTypes = {
  viewBox: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fill: PropTypes.string,
  className: PropTypes.string,
};

InlineSvg.defaultProps = {
  viewBox: '0 0 24 24',
  className: '',
  height: 18,
  width: 18,
  fill: '',
};

export default React.memo(InlineSvg);
