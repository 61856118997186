export enum SURVEY_TYPE {
  TRADITIONAL = 'traditional',
  PULSE = 'pulse',
}

export enum SURVEY_STATUS {
  DRAFT = 'draft',
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export enum SURVEY_FREQUENCY {
  WEEK = '1week', // important to have first number for sorting (from rare -> ofter or often -> rare)
  TWO_WEEK = '2week',
  FOUR_WEEK = '4week',
  DAY = '1day', // we use this only on stage and live
}

export enum SURVEY_PARTICIPANTS_TYPE {
  EMPLOYEES = 'employees',
  TEAMS = 'teams',
  COMPANY = 'company',
}

export enum SURVEY_SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
  REPEAT_OFTEN_RARE = 'REPEAT_OFTEN_RARE',
  REPEAT_RARE_OFTEN = 'REPEAT_RARE_OFTEN',
  END_DATE_SOON_LATER = 'END_DATE_SOON_LATER',
  END_DATE_LATER_SOON = 'END_DATE_LATER_SOON',
}
