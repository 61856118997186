/**
 * Enum skill type
 * @readonly
 * @enum {string}
 */
export const SKILL_TYPE = {
  soft: 'soft',
  hard: 'hard',
};

export const CATEGORY_SKILL_RATING_KEYS = {
  AVG_EXPECTED_LEVEL: 'avgExpectedLevel',
  AVG_SELF_RATING: 'avgSelfRating',
  AVG_PEERS_RATING: 'avgPeersRating',
  AVG_COACHES_RATING: 'avgCoachesRating',
};
