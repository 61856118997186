import styled from 'styled-components';

import { ButtonShadow } from '~/components/Button';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;

  & .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: start;
  color: ${COLORS.COMPANY};
`;

export const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  height: 38px;
  color: ${COLORS.ACCENT_ERROR};

  &.delete {
    cursor: pointer;
  }
  &.disabled {
    color: ${COLORS.DISABLED_ICON};
    cursor: default;
  }
`;

export const InputGroups = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 8px;
`;

export const AddOption = styled(ButtonShadow)`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 32px;
  padding: 8px 14px 7px;
  &.selected {
    background-color: ${COLORS.COMPANY};
    color: ${COLORS.WHITE};
  }
`;

export const RatingLabels = styled.div`
  margin-top: 14px;
  display: flex;
  width: 100%;
  font-size: 14px;
  gap: 19px;
`;

export const IndexWrapper = styled.div`
  height: 38px;
  width: 72px;
  align-items: center;
  display: flex;
  text-align: center;
  font-size: 14px;
  color: ${COLORS.ICONS_PRIMARY};
`;

export const InputLabels = styled.div`
  box-sizing: border-box
  height: 34px;
  font-size: 14px;
  color: ${COLORS.TEXT_HOVER};
  padding: 1px 1px;
  margin-bottom: 5px;
  margin-right: 20px;
  border-radius: 6px;

  & > span {
    color: ${COLORS.COMPANY};
    margin-right: 10px;
  }
`;
