import React, { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { addDays } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import DatePicker from '~/components/DatePickerDeprecated';
import Modal from '~/components/Modal';
import { FieldGroup, FieldTitle, TextField } from '~/components/Text';

import useBoolState from '~/hooks/useBoolState';
import { COLORS } from '~/styles';

const InputGroup = styled.div`
  display: flex;
`;

const FieldColumn = styled.div`
  width: ${(props) => props.$width || '100%'};
  margin-right: 16px;
`;

const StyledFieldGroup = styled(FieldGroup)`
  margin-bottom: 24px;
`;

const StyledFieldTitle = styled(FieldTitle)`
  color: ${COLORS.TEXT_BLACK};
  margin-bottom: 12px;
`;

const StyledTextField = styled(TextField)`
  height: 48px;
`;

function CreateGoalCycleModal({ i18n, cycle, onClose, onSave }) {
  const [goalCycle, setGoalCycle] = useState(
    cycle || { name: '', startDate: new Date(), endDate: new Date() },
  );
  const $loading = useBoolState();
  const $isUpdate = !isEmpty(cycle);

  const handleSave = async () => {
    if ($isUpdate) {
      const textConfirm = i18n._(t`Are you sure you want to update the goal cycle?`);
      if (await confirm(i18n, textConfirm)) {
        $loading.on();
        onSave(goalCycle);
      }
    } else {
      $loading.on();
      onSave(goalCycle);
    }
  };

  const isValid = () => {
    return (
      !isEmpty(goalCycle.name) &&
      !!goalCycle.startDate &&
      !!goalCycle.endDate &&
      new Date(goalCycle.startDate) < new Date(goalCycle.endDate)
    );
  };

  return (
    <Modal
      title={$isUpdate ? i18n._(t`Edit goal cycle`) : i18n._(t`Create goal cycle`)}
      onClose={onClose}
      width={432}
      minWidth={432}
      showDivider={false}
      greyDescription={i18n._(t`Set a time frame for your goals`)}
      contentStyles={{ overflow: 'inherit' }}
      footerStyles={{
        margin: '12px 0',
      }}
      footerRight={
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            styles={{
              height: '48px',
              minWidth: '83px',
            }}
            loading={$loading.value}
            onClick={onClose}
            label={i18n._(t`Cancel`)}
            type="white"
          />
          <Button
            styles={{
              height: '48px',
              marginLeft: '16px',
              minWidth: '83px',
            }}
            loading={$loading.value}
            disabled={!isValid()}
            onClick={handleSave}
            label={$isUpdate ? i18n._(t`Save`) : i18n._(t`Create`)}
            type="primary"
          />
        </div>
      }
    >
      <div>
        <StyledFieldGroup>
          <StyledFieldTitle>
            <Trans>Name</Trans>*
          </StyledFieldTitle>
          <StyledTextField
            value={goalCycle.name}
            onChange={(e) => setGoalCycle({ ...goalCycle, name: e.target.value })}
            placeholder={i18n._(t`Example: 2020 - Q2`)}
          />
        </StyledFieldGroup>
        <InputGroup>
          <FieldColumn $width="124px">
            <StyledFieldTitle>
              <Trans>Start</Trans>*
            </StyledFieldTitle>
            <DatePicker
              width={124}
              isHideIcon
              placeholder={i18n._(t`29-06-2021`)}
              selected={goalCycle.startDate}
              minDate={new Date()}
              onChange={(value) => setGoalCycle({ ...goalCycle, startDate: value })}
            />
          </FieldColumn>
          <FieldColumn>
            <StyledFieldTitle>
              <Trans>End</Trans>*
            </StyledFieldTitle>
            <DatePicker
              width={124}
              isHideIcon
              placeholder={i18n._(t`29-06-2021`)}
              selected={goalCycle.endDate}
              onChange={(value) => setGoalCycle({ ...goalCycle, endDate: value })}
              minDate={goalCycle.startDate ? new Date(goalCycle.startDate) : addDays(new Date(), 1)} // validation: from tomorrow on
            />
          </FieldColumn>
        </InputGroup>
      </div>
    </Modal>
  );
}

export default withI18n()(CreateGoalCycleModal);
