import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import FocusLayout from '~/layouts/FocusLayout';
import PathDashboard from '~/pages/PathDashboard';
import PathSetup from '~/pages/PathSetup';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('paths', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.PATH.routePath(ROLES.USER)}
        exact
        path={routes.PATH.routePath(ROLES.USER)}
        component={PathDashboard}
        allowedRoles={[ROLES.USER]}
        layout={FocusLayout}
      />,
      <PrivateRoute
        key={routes.PATH_CREATE.routePath(ROLES.USER)}
        exact
        path={routes.PATH_CREATE.routePath(ROLES.USER)}
        component={PathSetup}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.PATH_UPDATE.routePath(ROLES.USER)}
        exact
        path={routes.PATH_UPDATE.routePath(ROLES.USER)}
        component={PathSetup}
        allowedRoles={[ROLES.USER]}
      />,
    ],
    coach: [
      <PrivateRoute
        key={routes.PATH.routePath(ROLES.COACH)}
        exact
        path={routes.PATH.routePath(ROLES.COACH)}
        component={PathDashboard}
        allowedRoles={[ROLES.COACH]}
        layout={FocusLayout}
      />,
      <PrivateRoute
        key={routes.PATH_CREATE.routePath(ROLES.COACH)}
        exact
        path={routes.PATH_CREATE.routePath(ROLES.COACH)}
        component={PathSetup}
        allowedRoles={[ROLES.COACH]}
      />,
      <PrivateRoute
        key={routes.PATH_UPDATE.routePath(ROLES.COACH)}
        exact
        path={routes.PATH_UPDATE.routePath(ROLES.COACH)}
        component={PathSetup}
        allowedRoles={[ROLES.COACH]}
      />,
    ],
    admin: [
      <PrivateRoute
        key={routes.PATH.routePath(ROLES.ADMIN)}
        exact
        path={routes.PATH.routePath(ROLES.ADMIN)}
        component={PathDashboard}
        allowedRoles={[ROLES.ADMIN]}
        layout={FocusLayout}
      />,
      <PrivateRoute
        key={routes.PATH_CREATE.routePath(ROLES.ADMIN)}
        exact
        path={routes.PATH_CREATE.routePath(ROLES.ADMIN)}
        component={PathSetup}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.PATH_UPDATE.routePath(ROLES.ADMIN)}
        exact
        path={routes.PATH_UPDATE.routePath(ROLES.ADMIN)}
        component={PathSetup}
        allowedRoles={[ROLES.ADMIN]}
      />,
    ],
  },
  routesPublic: [],
});
