import type { IGetServerRoute, IServerRoute, IUpdateServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

import type { IJobFamily } from '@learned/types';

export function getJobFamilies() {
  return cloudRequest(serverRoutes.jobFamilies.getJobFamilies as IServerRoute, {});
}

export function createJobFamily(body: { name: IJobFamily['name'] }) {
  return cloudRequest(serverRoutes.jobFamilies.createJobFamily as IServerRoute, {}, body);
}

export function updateJobFamily(jobFamilyId: string, body: { name: IJobFamily['name'] }) {
  return cloudRequest(
    (serverRoutes.jobFamilies.updateJobFamily as IUpdateServerRoute)(jobFamilyId),
    {},
    body,
  );
}

export function deleteJobFamilies(jobFamilyIds: string[]) {
  return cloudRequest(
    serverRoutes.jobFamilies.deleteJobFamilies as IServerRoute,
    {},
    {
      jobFamilyIds,
    },
  );
}

export function getJobFamily(jobFamilyId: string) {
  return cloudRequest((serverRoutes.jobFamilies.getJobFamily as IGetServerRoute)(jobFamilyId));
}

// super admin
export function getJobFamiliesSuperAdmin() {
  return cloudRequest(serverRoutes.jobFamilies.getJobFamiliesSuperAdmin as IServerRoute, {});
}

export function createJobFamilySuperAdmin(body: { name: IJobFamily['name'] }) {
  return cloudRequest(serverRoutes.jobFamilies.createJobFamilySuperAdmin as IServerRoute, {}, body);
}

export function updateJobFamilySuperAdmin(jobFamilyId: string, body: { name: IJobFamily['name'] }) {
  return cloudRequest(
    (serverRoutes.jobFamilies.updateJobFamilySuperAdmin as IUpdateServerRoute)(jobFamilyId),
    {},
    body,
  );
}

export function deleteJobFamiliesSuperAdmin(jobFamilyIds: string[]) {
  return cloudRequest(
    serverRoutes.jobFamilies.deleteJobFamiliesSuperAdmin as IServerRoute,
    {},
    {
      jobFamilyIds,
    },
  );
}
