import React from 'react';

import { MissingAverageRatingReason } from '@learned/constants';
import { Trans } from '@lingui/macro';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';

import { ICONS, Icon } from '~/components/Icon';

import { ColorByPercentage } from './ColorByPercentage';

import { COLORS } from '~/styles';

import { isValueAllowed } from '../../utils';

const tippyOpts = { modifiers: [{ name: 'computeStyles', options: { gpuAcceleration: false } }] };

const TippyName = styled.div`
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 12px;
  text-align: center;
  padding: 7px 12px 7px 12px;
`;

const StyledTippy = styled(Tippy)`
  border-radius: 6px;
  background-color: ${COLORS.MIDDLE_GRAY};
  .tippy-content {
    padding: 0;
  }

  .tippy-arrow {
    color: ${COLORS.MIDDLE_GRAY};
  }
`;

const Clickable = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const Dash = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
`;

function GradientAverageCell({
  onClick,
  reportingThreshold,
  average,
  selectedItems,
  error,
}: {
  onClick?: () => void;
  reportingThreshold: number;
  average?: number | null;
  error?: MissingAverageRatingReason | null;
  selectedItems: string[];
}) {
  if (!average) {
    return (
      <ColorByPercentage>
        {error ? (
          <StyledTippy
            trigger="mouseenter"
            popperOptions={tippyOpts}
            content={
              <TippyName>
                {error === MissingAverageRatingReason.THRESHOLD_NOT_MET && (
                  <Trans>Anonymity Threshold ({reportingThreshold} answers) not met</Trans>
                )}
                {error === MissingAverageRatingReason.NO_RATINGS && (
                  <Trans>No measurable answers yet</Trans>
                )}
                {error === MissingAverageRatingReason.NOT_SHARED && (
                  <Trans>Survey has not been shared</Trans>
                )}
              </TippyName>
            }
          >
            <Dash>
              {error === MissingAverageRatingReason.THRESHOLD_NOT_MET && (
                <Icon icon={ICONS.INCOGNITO} color={COLORS.PLACEHOLDERS} />
              )}
              {error === MissingAverageRatingReason.NOT_SHARED && (
                <Icon icon={ICONS.LOCK} color={COLORS.PLACEHOLDERS} />
              )}
              {error === MissingAverageRatingReason.NO_RATINGS && '-'}
            </Dash>
          </StyledTippy>
        ) : (
          <Dash />
        )}
      </ColorByPercentage>
    );
  }

  average = average * 100;

  const cellComponent = (
    <ColorByPercentage value={isValueAllowed(average, selectedItems)}>
      {average && `${average.toFixed(2)}%`}
    </ColorByPercentage>
  );

  return onClick ? <Clickable onClick={onClick}>{cellComponent}</Clickable> : cellComponent;
}

export { GradientAverageCell };
