import React, { Component } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import Editor from '~/components/Editor';
import Modal from '~/components/Modal';
import AvatarCard from '~/components/UI/AvatarCard';

import { giveRTFeedback } from '~/services/RTFeedbacks';
import { COLOR_PALETTE } from '~/styles';

const HeaderActions = styled.div`
  display: flex;
`;

const Block = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 40px;
  &:last-child {
    margin-bottom: 58px;
  }
`;

const BlockTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #3b3b3b;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  color: #3b3b3b;
`;

const MessageArea = styled.div`
  width: 100%;
  height: 150px;
  border-radius: 5px;
  border: solid 1px #ccced5;
  background-color: ${COLOR_PALETTE.WHITE};
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.71;
  color: #70747f;
`;

const RTFeedbackName = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #3b3b3b;
`;

const DeclineWrapper = styled.div`
  display: flex;
  margin-right: 25px;
`;

const INITIAL_STATE = {
  loading: false,
  feedback: '',
};

class RTFeedbackAnswerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      feedback: props.RTFeedback.feedback || '',
    };
  }

  static propTypes = {
    RTFeedback: PropTypes.object,
    onClose: PropTypes.func,
    onDecline: PropTypes.func,
    hideHeaderClose: PropTypes.bool,
    isOutside: PropTypes.bool,
  };

  static defaultProps = {
    isOutside: false,
    onClose: () => {},
    onDecline: () => {},
  };

  closeModal = (isFirstSubmit) => {
    this.setState({ ...INITIAL_STATE });
    this.props.onClose(isFirstSubmit);
  };

  handleChange = (newText) => {
    this.setState({
      feedback: newText,
    });
  };

  onSubmit = async () => {
    const { RTFeedback } = this.props;
    const { feedback } = this.state;
    const isFirstSubmit = !RTFeedback.feedback;

    this.setState({ loading: true });

    await giveRTFeedback(RTFeedback.id, feedback);
    this.closeModal(isFirstSubmit);
  };

  render() {
    const { feedback, loading } = this.state;
    const { i18n, RTFeedback, hideHeaderClose, onDecline, isOutside } = this.props;

    return (
      <Modal
        className="new-team-modal"
        title={
          <Title>
            <Trans>Provide feedback</Trans>
          </Title>
        }
        onClose={() => this.closeModal(false)}
        width={625}
        minWidth={625}
        minHeight="600px"
        contentStyles={{ background: '#fff', height: '100%', padding: '0px 16px' }}
        headerStyles={{ padding: '0 24px', borderBottom: 'solid 0.5px #ecedf0' }}
        footerRight={
          <HeaderActions>
            {!feedback && (
              <DeclineWrapper>
                <Button
                  label={i18n._(t`Decline`)}
                  type={Button.types.linkSecondary}
                  onClick={onDecline}
                  loading={loading}
                />
              </DeclineWrapper>
            )}
            <Button
              styles={{ marginLeft: '10px' }}
              label={i18n._(t`Send`)}
              disabled={!feedback}
              type="primary-border"
              onClick={this.onSubmit}
              loading={loading}
            />
          </HeaderActions>
        }
        hideHeaderClose={hideHeaderClose}
      >
        <Block>
          <BlockTitle>
            <Trans>For</Trans>
          </BlockTitle>
          <AvatarCard
            {...{
              [isOutside ? 'userLocked' : 'userId']: RTFeedback.createdBy,
            }}
          />
        </Block>

        <Block>
          <BlockTitle>
            <Trans>About</Trans>
          </BlockTitle>
          <RTFeedbackName>{RTFeedback.name}</RTFeedbackName>
        </Block>

        <Block>
          <BlockTitle>
            <Trans>What is your feedback?</Trans>
          </BlockTitle>
          <MessageArea>
            <Editor
              placeholder={i18n._(t`Try to provide the receiver with actionable feedback.`)}
              value={feedback}
              compact={true}
              onChange={this.handleChange}
            />
          </MessageArea>
        </Block>
      </Modal>
    );
  }
}

export default withI18n()(withRouter(RTFeedbackAnswerModal));
