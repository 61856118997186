import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment/moment';
import styled from 'styled-components';

import Button from '~/components/Button';
import ColoredRow from '~/components/ColoredRow';

import { CONVERSATION_COLLECTION_TYPES, REVIEW_STATUSES } from '~/constants';
import { CONVERSATION_STYLES } from '~/constants/conversations';
import routes from '~/constants/routes';
import { COLOR_PALETTE } from '~/styles';

const TIMELINE_WIDTH = 3;
const TIMELINE_CIRCLE_TOP = 15;

const TimelineCircle = styled.div`
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  right: 0px;
  left: ${TIMELINE_WIDTH / 2}px;
  background-color: var(--company-color);
  top: ${TIMELINE_CIRCLE_TOP}px;
  border-radius: 50%;
`;

const RowWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const StartDate = styled.div`
  min-width: 32px;
  max-width: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 11px;
  margin-left: 26px; // space for timeline
`;

const StartMonth = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
`;

const StartYear = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const ConversationsRow = ({ propsRef, item, startDate }) => {
  const { i18n } = useLingui();
  const style = CONVERSATION_STYLES[CONVERSATION_COLLECTION_TYPES.REVIEW.key];
  const isView = item.status !== REVIEW_STATUSES.DRAFT.key;

  const goToReview = () => routes.REVIEW.go({}, { reviewId: item.id, isBackPath: true });

  return (
    <RowWrapper ref={propsRef}>
      <TimelineCircle />
      <StartDate>
        {startDate && (
          <>
            <StartMonth>{moment(startDate).format('MMM')}</StartMonth>
            <StartYear>{moment(startDate).format('YYYY')}</StartYear>
          </>
        )}
      </StartDate>
      <ColoredRow
        width={89}
        title={item.name}
        rowIcon={style.icon}
        rowStartColor={style.color}
        rowColor={style.background}
        rowIconColor={style.iconColor}
        rowIconBackground={style.iconBackground}
        onClick={isView ? goToReview : undefined}
        buttons={
          isView && (
            <Button type={Button.types.linkPrimary} label={i18n._(t`View`)} onClick={goToReview} />
          )
        }
      />
    </RowWrapper>
  );
};

export default React.memo(ConversationsRow);
