import React from 'react';

import styled from 'styled-components';

import { ICONS, Icon } from '~/components/Icon';

import { COLORS } from '~/styles';

export const StyledIcon = styled(Icon)<{ color?: string }>`
  stroke: ${COLORS.BORDERS};

  .checkmark {
    stroke: ${({ color }) => color || COLORS.COMPANY};
  }
`;

function MultiSelectCheckBox({ selected, color }: { selected: boolean; color?: string }) {
  return <StyledIcon icon={selected ? ICONS.CHECKBOX_CHECKED : ICONS.CHECKBOX} color={color} />;
}

export { MultiSelectCheckBox };
