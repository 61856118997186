import React, { useCallback, useEffect, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import debounce from 'lodash/debounce';
import intersection from 'lodash/intersection';
import { useFieldArray, useWatch } from 'react-hook-form';
import styled, { css } from 'styled-components';

import { ButtonPrimary } from '~/components/Button';
import { IconOld } from '~/components/IconOld';
import type { IconNames } from '~/components/IconOld';
import { Marker } from '~/components/Marker';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import Switch from '~/components/Switch';
import { Errors } from '~/pages/SurveyTemplateUpdate/validation';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLOR_PALETTE, COLORS } from '~/styles';

import { BackButton, Footer, Form, IconPreview, Title } from '../design';

import type { ITheme, IGeneralForm } from '../types';
import type { UseFormReturn } from 'react-hook-form';

export interface IThemeWeightProps {
  formMethods: UseFormReturn<IGeneralForm>;
  themesMap: Record<string, ITheme>;
  sectionState: ISectionState;
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  autosave: () => void;
  equalizeWeights: () => void;
}

const SubmitButton = styled(ButtonPrimary)`
  height: 48px;
  padding: 0 50px;
  border-radius: 100px;
  font-size: 14px;
  text-align: center;
  gap: 4px;
  font-weight: bold;
  box-shadow: 0 1px 8px 3px rgba(166, 166, 166, 0.4);
`;

const SubTitle = styled.span`
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const StyledTitle = styled(Title)`
  margin-bottom: 0;
`;

const Content = styled.div<{ customizing: boolean }>`
  padding-top: 27px;
  padding-bottom: ${(props) => (props.customizing ? '33px' : '80px')};
`;

const StyledInput = styled.input<{ error: boolean }>`
  width: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  border-color: ${COLORS.BORDER};
  border-radius: 6px;
  background-color: ${COLORS.WHITE};
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  font-style: normal;
  font-stretch: normal;
  color: ${COLORS.BLACK};
  line-height: 1.86;
  letter-spacing: -0.16px;
  height: 38px;
  box-sizing: border-box;
  padding: 0;
  padding-right: 15px;
  padding-top: 2px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    appearance: none;
  }
  &:focus {
    ${({ error }) =>
      !error &&
      css`
        border-color: var(--company-color);
      `}
  }
  &:hover {
    background-color: ${COLORS.HOVER};
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${COLORS.ERROR};
    `}
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  font-size: 14px;
  color: ${COLOR_PALETTE.BLACK};
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const ThemeWeightRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;
`;

const ThemeWeightsContainer = styled.div`
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  gap: 31px;
`;

const Percentage = styled.span`
  position: absolute;
  right: 12px;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
`;

const StyledFooter = styled(Footer)`
  margin-top: 0;
`;

const WeightTotal = styled.div<{ error: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 74px;
  border-top: solid 2.4px ${COLORS.BORDER_HARD};
  padding-top: 19px;

  ${({ error }) =>
    error &&
    css`
      color: ${COLORS.ERROR};
    `}
`;

const StyledMarker = styled(Marker)`
  position: absolute;
  left: 100px;
`;

const RowHeader = styled.span`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.33;
  color: ${COLORS.MIDDLE_GRAY};
`;

const InputWrapper = styled.div`
  display: flex;
  position: relative;
  width: 74px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

const EqualizeButton = styled(BackButton)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ThemeWeight = ({
  formMethods,
  themesMap,
  sectionState,
  onSubmit,
  autosave,
  equalizeWeights,
}: IThemeWeightProps) => {
  const { i18n } = useLingui();
  const {
    register,
    control,
    trigger,
    formState: { errors },
  } = formMethods;
  const { triedToSubmit, setCurrentSection } = sectionState;
  const getMultiLangString = useMultiLangString();
  const [isCustomizingWeights, setIsCustomizingWeights] = useState(false);
  const themeWeightsFieldArray = useFieldArray({
    name: 'themeWeights',
    control,
  });
  const themeWeightsWatch = useWatch({ control, name: 'themeWeights' });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdateTemplate = useCallback(
    debounce(() => autosave(), 10_000),
    [],
  );

  useEffect(() => {
    const currentThemes = themeWeightsFieldArray.fields.map((field) => field.themeId);
    const themeIds = Object.keys(themesMap);
    const themeIntersection = intersection(currentThemes, themeIds);
    const hasNewTheme =
      themeIntersection.length !== themeIds.length ||
      themeIntersection.length !== currentThemes.length;
    if (hasNewTheme) {
      equalizeWeights();
      autosave();
    }

    // eslint-disable-next-line
  }, [themesMap]);

  const createLabel = (missingAmount: number): string => {
    if (missingAmount > 0) {
      return `${missingAmount.toFixed(2)}% ${i18n._(t`over`)}`;
    } else {
      return `${(missingAmount * -1).toFixed(2)}% ${i18n._(t`short`)}`;
    }
  };

  const currentThemeTotal = themeWeightsWatch.reduce((acc, field) => acc + field.weight, 0);
  const missingAmount = currentThemeTotal - 100;

  return (
    <Form onSubmit={onSubmit}>
      <StyledTitle>
        <Trans>Theme Weight</Trans>
      </StyledTitle>
      <SubTitle>
        <Trans>
          Determine the weight of the themes for inclusion in the reporting. By default, each theme
          weighs equally.
        </Trans>
      </SubTitle>
      <Content customizing={isCustomizingWeights}>
        <ToggleContainer>
          <Switch
            onChange={() => setIsCustomizingWeights((prevValue) => !prevValue)}
            checked={isCustomizingWeights}
          />
          <Trans>Customise weight per theme</Trans>
        </ToggleContainer>
        {isCustomizingWeights && (
          <ThemeWeightsContainer>
            <ThemeWeightRow>
              <RowHeader>
                <Trans>Theme</Trans>
              </RowHeader>
              <RowHeader>
                <Trans>Weighting</Trans>
              </RowHeader>
            </ThemeWeightRow>
            {themeWeightsFieldArray.fields.map((f, index) => {
              const theme = themesMap[f.themeId];
              const icon = (theme.icon as IconNames) ?? 'Chatbubbles';
              const colors = (theme.iconColor ?? '#f7f9ff-#ebf1fe').split('-');
              const registeredField = register(`themeWeights.${index}.weight`, {
                valueAsNumber: true,
              });
              const fieldError =
                errors.themeWeights
                  ?.map?.((themeWeight) => themeWeight?.weight?.ref?.name)
                  .find((name) => name === registeredField.name) !== undefined;
              return (
                <ThemeWeightRow key={f.id}>
                  <NameContainer>
                    <IconPreview colors={colors}>
                      <IconOld name={icon} width={23} height={23} />
                    </IconPreview>
                    {getMultiLangString(theme.name)}
                  </NameContainer>
                  <InputWrapper>
                    <StyledInput
                      error={fieldError && triedToSubmit}
                      type="number"
                      inputMode="numeric"
                      height="38px"
                      width="54px"
                      {...registeredField}
                      onChange={async (e) => {
                        await registeredField.onChange(e);
                        await trigger('themeWeights');
                        debounceUpdateTemplate();
                      }}
                      onBlur={async (e) => {
                        await registeredField.onBlur(e);
                        await trigger('themeWeights');
                        autosave();
                      }}
                      step="0.01"
                    />
                    <Percentage>%</Percentage>
                  </InputWrapper>
                </ThemeWeightRow>
              );
            })}
            <ThemeWeightRow>
              <EqualizeButton type="button" onClick={() => equalizeWeights()}>
                <IconOld name="Arrows" width={16} height={16} className="icon" />
                <Trans>Make all themes even</Trans>
              </EqualizeButton>
              <WeightTotal
                error={
                  errors.themeWeights?.message === Errors.themeWeightsTotalIncorrect &&
                  triedToSubmit
                }
              >
                {!isNaN(currentThemeTotal) && parseFloat(currentThemeTotal.toFixed(2))}%
                {errors.themeWeights?.message === Errors.themeWeightsTotalIncorrect &&
                  triedToSubmit &&
                  !isNaN(missingAmount) && (
                    <StyledMarker
                      label={createLabel(missingAmount)}
                      backgroundColor={COLORS.ERROR}
                    />
                  )}
              </WeightTotal>
            </ThemeWeightRow>
          </ThemeWeightsContainer>
        )}
      </Content>
      <StyledFooter>
        <BackButton onClick={() => setCurrentSection(1)}>
          <IconOld name="ChevronBack" width={16} height={16} className="icon" />
          <Trans>Back</Trans>
        </BackButton>
        <SubmitButton type="submit">
          <Trans>Publish</Trans>
        </SubmitButton>
      </StyledFooter>
    </Form>
  );
};

export { ThemeWeight };
