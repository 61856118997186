import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import qs from 'qs';
import { useSelector } from 'react-redux';

import OverviewHeading from '~/components/OverviewHeading';
import { MainDashboard } from '~/components/ProfileSettingsComponents';
import BaseLayout from '~/layouts/BaseLayout';

import { getUser } from '~/selectors/baseGetters';

const ProfileSettings = () => {
  const user = useSelector(getUser);
  const { i18n } = useLingui();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { scrollToCalendars } = params;

  return (
    <>
      <OverviewHeading title={i18n._(t`Profile settings`)} smallWidth />
      <BaseLayout maxWidth="750px">
        <MainDashboard user={user} scrollToCalendars={scrollToCalendars} />
      </BaseLayout>
    </>
  );
};

export default ProfileSettings;
