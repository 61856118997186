import React, { useMemo } from 'react';

import styled from 'styled-components';

import { TableGrid } from '~/components/TableGrid';
import {
  IQuestionForm,
  ISelectedSkill,
  ISkill,
  ISkillCategory,
} from '~/pages/ReviewThemeSetup/types';

import { createSkillCategoryColumns } from './SkillCategory.columns';
import { convertSkillsDataToTable } from './utils';

import type { UseFormReturn } from 'react-hook-form';

const Wrapper = styled.div`
  margin-bottom: 32px;
`;

interface ISkillTable {
  skills: ISkill[];
  selectedSkills: ISelectedSkill[] | undefined;
  formMethods: UseFormReturn<IQuestionForm>;
  setSelectedSkill?: (skill: ISkill) => void;
  setIsSkillModalOpen: (open: boolean) => void;
  skillCategories: ISkillCategory[];
  isEditMode?: boolean;
  setCurrentStep?: (step: number) => void;
  setSelectedSkills: (skills: ISelectedSkill[]) => void;
}

const SkillTable = ({
  skills,
  selectedSkills,
  skillCategories,
  setSelectedSkill,
  setIsSkillModalOpen,
  setCurrentStep,
  setSelectedSkills,
}: ISkillTable) => {
  const { columns } = createSkillCategoryColumns();

  const data = useMemo(() => {
    if (!selectedSkills || !selectedSkills?.length) {
      return [];
    }

    return convertSkillsDataToTable({
      skills,
      selectedSkills,
      setIsSkillModalOpen,
      skillCategories,
      setSelectedSkill,
      setCurrentStep,
      setSelectedSkills,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify([skills, selectedSkills])]);

  return (
    <Wrapper>
      <TableGrid thColumnPadding="16px 19px" data={data} columns={columns} />
    </Wrapper>
  );
};

export { SkillTable };
