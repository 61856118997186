import React from 'react';

import { SURVEY_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';
import styled from 'styled-components';

import { IconBlock } from '~/components/IconBlock';
import SvgIcon from '~/components/SvgIcon';
import { StepSection } from '~/pages/SurveyUpdate/components';
import type { IGeneralForm } from '~/pages/SurveyUpdate/types';

import CalendarCheckCopy from '~/assets/calendar-check-copy.svg';
import IncognitoIcon from '~/assets/incognito.svg';
import SurveyPulseIcon from '~/assets/survey-pulse.svg';

import {
  SURVEY_FREQUENCY_WITH_NAMES,
  SURVEY_QUESTIONS_PER_PARTICIPANT_WITH_NAMES,
} from '~/constants/survey';
import { COLORS } from '~/styles';

import type { UseFormReturn } from 'react-hook-form';

const Wrapper = styled.div``;

const Block = styled.div`
  margin-bottom: 26px;
`;

const Row = styled.div`
  display: flex;
`;

const RowColumn = styled.div`
  width: 100%;
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.BLACK};
`;

const NotificationsList = styled.ul`
  padding: 0 0 0 16px;
  margin: 0;
`;

interface ISectionTemplates {
  formMethods: UseFormReturn<IGeneralForm>;
  onEdit: () => void;
}

const SectionSettings = ({ formMethods, onEdit }: ISectionTemplates) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;
  const type = watch('type');
  const startDate = watch('dates.startDate');
  const endDate = watch('dates.endDate');
  const isEndDate = watch('dates.isEndDate');
  const frequency = watch('pulse.frequency');
  const questionsPerParticipant = watch('pulse.questionsPerParticipant');
  const isOnStartDate = watch('notifications.isOnStartDate');
  const isOn2DaysBeforeEndDate = watch('notifications.isOn2DaysBeforeEndDate');
  const isOnEndDate = watch('notifications.isOnEndDate');
  const isAnonymous = watch('anonymity.isAnonymous');
  const reportingThreshold = watch('anonymity.reportingThreshold');

  return (
    <StepSection title={i18n._(t`Settings`)} onEdit={onEdit}>
      <Wrapper>
        {/* Dates*/}
        <Block>
          <SubTitle>{i18n._(t`Dates`)}</SubTitle>
          <Row>
            <RowColumn>
              <IconBlock
                label={i18n._(t`Start Date`)}
                value={moment(startDate).format('DD-MM-YYYY')}
                icon={<SvgIcon width="22px" height="22px" url={CalendarCheckCopy} />}
              />
            </RowColumn>
            <RowColumn>
              {(type === SURVEY_TYPE.TRADITIONAL || isEndDate) && (
                <IconBlock
                  label={i18n._(t`End Date`)}
                  value={moment(endDate).format('DD-MM-YYYY')}
                  icon={<SvgIcon width="22px" height="22px" url={CalendarCheckCopy} />}
                />
              )}
            </RowColumn>
          </Row>
        </Block>

        {/* Pulse*/}
        {type === SURVEY_TYPE.PULSE && (
          <Block>
            <SubTitle>{i18n._(t`Pulse`)}</SubTitle>
            <Row>
              <RowColumn>
                <IconBlock
                  label={i18n._(t`Repeat frequency of pulse`)}
                  value={SURVEY_FREQUENCY_WITH_NAMES[frequency]?.name(i18n)}
                  icon={<SvgIcon width="22px" height="22px" url={SurveyPulseIcon} />}
                />
              </RowColumn>
              <RowColumn>
                <IconBlock
                  label={i18n._(t`Amount of questions per participants`)}
                  value={SURVEY_QUESTIONS_PER_PARTICIPANT_WITH_NAMES[questionsPerParticipant].name(
                    i18n,
                  )}
                  icon={<SvgIcon width="22px" height="22px" url={SurveyPulseIcon} />}
                />
              </RowColumn>
            </Row>
          </Block>
        )}

        {/* Notifications*/}
        <Block>
          <SubTitle>{i18n._(t`Notifications`)}</SubTitle>
          <NotificationsList>
            {isOnStartDate && <li>{i18n._(t`on start date`)}</li>}
            {isOn2DaysBeforeEndDate && <li>{i18n._(t`2 days before deadline`)}</li>}
            {isOnEndDate && <li>{i18n._(t`on the day of the deadline`)}</li>}
          </NotificationsList>
        </Block>

        {/* Anonymity*/}
        <Block>
          <SubTitle>{i18n._(t`Anonymity`)}</SubTitle>
          <Row>
            <RowColumn>
              <IconBlock
                label={i18n._(t`Comments are anonymously`)}
                value={isAnonymous ? i18n._(t`on`).toUpperCase() : i18n._(t`off`).toUpperCase()}
                icon={<SvgIcon width="22px" height="22px" url={IncognitoIcon} />}
              />
            </RowColumn>
            <RowColumn>
              {isAnonymous && (
                <IconBlock
                  label={i18n._(t`Anonymously threshold`)}
                  value={`${reportingThreshold} answers`}
                  icon={<SvgIcon width="22px" height="22px" url={IncognitoIcon} />}
                />
              )}
            </RowColumn>
          </Row>
        </Block>
      </Wrapper>
    </StepSection>
  );
};

export { SectionSettings };
