import React, { forwardRef } from 'react';

import { TooltipCtr } from './GraphTooltip.design';

interface IGraphTooltipProps {
  tooltipTitle?: string;
  ref: React.Ref<HTMLDivElement>;
  additionalDataGroupsLength: number;
  // this will be used to swap primary title and the secondary title
  swapTitles?: boolean;
}

const GraphTooltip: React.FC<IGraphTooltipProps> = forwardRef<HTMLDivElement, IGraphTooltipProps>(
  ({ tooltipTitle, swapTitles = false, additionalDataGroupsLength }, toolRef) => {
    return (
      <TooltipCtr ref={toolRef}>
        {/* using id to target element for data modifications, using classes for css styling */}
        <div
          className="tooltip-primary-title"
          id={swapTitles ? 'tooltip-secondary-title' : 'tooltip-title'}
        >
          {tooltipTitle}
        </div>
        <div
          className="tooltip-secondary-title"
          id={swapTitles ? 'tooltip-title' : 'tooltip-secondary-title'}
        >
          {tooltipTitle}
        </div>
        <>
          <div className="tooltip-secondary">
            <div>
              <span id="primary-label"> &nbsp; </span>
            </div>
            <div className="value-display">
              <div id="primary-bg-color-chip" className="color-chip" />
              <span id="primary-value"> &nbsp; </span>
              <span id="primary-deviation"> &nbsp; </span>
            </div>
          </div>
          <hr id="extra-rows-separator" />
          {Array(additionalDataGroupsLength)
            .fill(null)
            .map((_, i) => {
              return (
                <div className="tooltip-secondary hidden" id={`excess-row-${i + 1}`} key={i}>
                  <div>
                    <span id={`values-${i + 2}-label`}> &nbsp; </span>
                  </div>
                  <div className="value-display">
                    <div id={`values-${i + 2}-bg-color-chip`} className="color-chip" />
                    <span id={`values-${i + 2}-value`}> &nbsp;</span>
                    <span id={`values-${i + 2}-deviation`}> &nbsp; </span>
                  </div>
                </div>
              );
            })}
        </>
      </TooltipCtr>
    );
  },
);

export { GraphTooltip };
