import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

class HomePage extends Component {
  checkUserRole = (selectedRole) => {
    const { history } = this.props;
    switch (selectedRole) {
      case ROLES.USER:
      case ROLES.COACH:
        history.push(routes.DASHBOARD.build({ role: ROLES.USER }));
        break;
      case ROLES.ADMIN:
        history.push(routes.ADMIN_DASHBOARD.build({ role: ROLES.ADMIN }));
        break;
      default:
        break;
    }
  };

  componentDidMount = async () => {
    const { selectedRole, user } = this.props;
    if (selectedRole && user) {
      this.checkUserRole(selectedRole);
    }
  };

  componentDidUpdate = async () => {
    const { selectedRole, user } = this.props;
    if (selectedRole && user) {
      this.checkUserRole(selectedRole);
    }
  };

  render() {
    return <div />;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    selectedRole: state.selected.role,
  };
};

export default connect(mapStateToProps)(withRouter(HomePage));
