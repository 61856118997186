export const getTemplateCopyName = (template, templates) => {
  let i = 1;
  if (!templates.find((t) => t.name === `Copy of ${template.name}`)) {
    return `Copy of ${template.name}`;
  }
  while (templates.find((t) => t.name === `Copy of ${template.name} (${i})`)) {
    i += 1;
  }
  return `Copy of ${template.name} (${i})`;
};
