import React, { memo } from 'react';

import { t } from '@lingui/macro';

import ToolTip from '~/components/Tooltip';
import { UserAvatar } from '~/components/UserAvatar';

import { DateRow, EmployeeRow, FlexRow, TextRow } from './design';

import type { IColumnTable } from '~/@types/table';
import convertToTimeString from '~/utils/convertToTimeString';

import type { IReviewLog } from '@learned/types';
import type { I18n } from '@lingui/core';

const EditorColumn = ({ item }: { item: IReviewLog }) => {
  return item.createdBy ? (
    <EmployeeRow>
      <FlexRow $isHidePointer={true}>
        <UserAvatar userId={item.createdById} options={{ showTooltip: true }} />
      </FlexRow>
    </EmployeeRow>
  ) : null;
};

const ActivityColumn = memo(({ item }: { item: IReviewLog }) => {
  return (
    <TextRow>
      {/* @ts-ignore */}
      <FlexRow>
        <ToolTip tooltip={item.target}>
          <span>{item.target}</span>
        </ToolTip>
      </FlexRow>
    </TextRow>
  );
});

const COLUMNS: IColumnTable<IReviewLog>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Date`),
    accessor: 'date',
    maxWidth: '95px',
    renderCell: (item) => {
      const createdDate = item.meta?.createdDate;
      return <DateRow>{createdDate && `${convertToTimeString(createdDate)}`}</DateRow>;
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Editor`),
    accessor: 'editor',
    padding: '0',
    minWidth: '162px',
    maxWidth: '220px',
    renderCell: (item) => <EditorColumn item={item} />,
  },
  {
    name: (i18n: I18n) => i18n._(t`Activity`),
    accessor: 'activity',
    minWidth: '400px',
    maxWidth: '500px',
    renderCell: (item) => {
      return <ActivityColumn item={item} />;
    },
  },
];

export { COLUMNS };
