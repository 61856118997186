import styled from 'styled-components';

import { SearchSelectModal } from '~/components/SearchSelectModal';
import { TD } from '~/components/TableList/design';

export const StyledSearchSelectModal = styled(SearchSelectModal)`
  ${TD} {
    height: 48px;
    max-width: 163px;
  }
`;
