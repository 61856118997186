import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import Modal from '~/components/Modal';
import Select from '~/components/Select';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SearchField } from '~/components/Text';
import AvatarCard from '~/components/UI/AvatarCard';

import { SSO_METHODS, ROLES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { updateSSOMulti, getUsers } from '~/services/users';
import getUserFullName from '~/utils/getUserFullName';
import zipArray from '~/utils/zipArray';

const MainContent = styled.div``;

const FiltersSection = styled.div`
  display: flex;
  justify-content: right;
  justify-items: right;
`;

const UsersSection = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  height: calc(50vh);
`;

const UserListItem = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  background-color: #f6f8fc;
  border-radius: 4px;
  padding: 6px;
  margin: 4px;
`;

const LoginMethodSelect = styled(Select)`
  align-self: center;
`;

const SelectWrapper = styled.div`
  padding: 8px;
`;

const SearchWrapper = styled.div`
  padding: 8px;
`;

const SearchBox = styled(SearchField)`
  height: 32px;
`;

const TeamSelect = styled(Select)`
  height: 32px;
`;

function UpdateLoginMethodsModal({ onClose, i18n, teams }) {
  const $loading = useBoolState(true);
  const $actionLoading = useBoolState();
  const [users, setUsers] = useState({});
  // Dictionary containing user IDs + SSO settings
  const [selectedLoginMethods, setSelectedLoginMethods] = useState({});
  useEffect(() => {
    async function fetchUsers() {
      const companyUsers = await getUsers();
      setUsers(companyUsers);
      setSelectedLoginMethods(
        zipArray(
          Object.values(companyUsers).map((user) => ({
            id: user.id,
            disabled: (user.roles || []).indexOf(ROLES.OWNER) !== -1,
            sso_method: get(user, 'security.sso_method', SSO_METHODS.ALL.key),
          })),
        ),
      );
      $loading.off();
    }
    fetchUsers();
    // eslint-disable-next-line
  }, []);
  // Options for dropdown of login methods
  const loginMethodOptions = map(SSO_METHODS, (sso_method) => ({
    value: sso_method.key,
    name: sso_method.name,
  }));

  // Options for team filter
  const noTeamItem = { value: 'none', name: 'Team' };
  const teamsOptions = [
    noTeamItem,
    ...Object.values(teams).map((team) => ({ value: team.id, name: team.name })),
  ];

  // Filters state (search + team)
  const [filterTeam, setFilterTeam] = useState(noTeamItem.value);
  const [filterSearch, setFilterSearch] = useState('');

  // Function to apply team + search filter in login methods
  function filtersFunction(loginMethod) {
    // Determine string to search in
    const user = users[loginMethod.id];

    const userName = getUserFullName(user);
    const userEmail = user.email || '';

    // Filter on team
    const currentTeam = teams[filterTeam];
    const isInTeam = currentTeam && currentTeam.members.includes(user.id);
    if (currentTeam && !isInTeam) {
      return false;
    }

    // Filter on search string
    return (
      userName.toLowerCase().includes(filterSearch) ||
      userEmail.toLowerCase().includes(filterSearch)
    );
  }

  return (
    <Modal
      title={i18n._(t`Manage login methods per user`)}
      onClose={onClose}
      isCloseOnOutsideClick={true}
      footerLeft={<Button type="primary-border" label={i18n._(t`Cancel`)} onClick={onClose} />}
      footerRight={
        <Button
          type="primary"
          width="auto"
          styles={{ minWidth: '67px' }}
          label={i18n._(t`Save`)}
          loading={$actionLoading.value}
          onClick={async () => {
            // Only send wasModified == true to reduce server load
            const usersToUpdate = Object.values(selectedLoginMethods).filter((x) => x.wasModified);

            if (!isEmpty(usersToUpdate)) {
              // On save click, update SSO on server
              $actionLoading.on();

              // Only send wasModified == true to reduce server load
              await updateSSOMulti(usersToUpdate);

              $actionLoading.off();
            }

            onClose();
          }}
        />
      }
    >
      <MainContent>
        <FiltersSection>
          <SelectWrapper>
            <TeamSelect
              options={teamsOptions}
              onChange={(e) => setFilterTeam(e)}
              value={filterTeam}
              height="32px"
            />
          </SelectWrapper>
          <SearchWrapper>
            <SearchBox
              name="searchValue"
              value={filterSearch}
              onChange={(ev) => setFilterSearch(ev.target.value)}
              placeholder={i18n._(t`Search`)}
            />
          </SearchWrapper>
        </FiltersSection>
        {selectedLoginMethods && (
          <ShowSpinnerIfLoading loading={$loading.value}>
            <UsersSection>
              {Object.values(selectedLoginMethods)
                .filter(filtersFunction)
                .map((loginMethod) => (
                  <UserListItem key={loginMethod.id}>
                    <AvatarCard userId={loginMethod.id} />
                    <SelectWrapper>
                      <LoginMethodSelect
                        options={loginMethodOptions}
                        onChange={(e) => {
                          // On change, replace value in login methods list
                          // Mark this one as modified (used later to only send modified values to server)
                          setSelectedLoginMethods({
                            ...selectedLoginMethods,
                            [loginMethod.id]: {
                              ...loginMethod,
                              sso_method: e,
                              wasModified: true,
                            },
                          });
                        }}
                        value={loginMethod.sso_method}
                        disabled={loginMethod.disabled}
                        height="36px"
                      />
                    </SelectWrapper>
                  </UserListItem>
                ))}
            </UsersSection>
          </ShowSpinnerIfLoading>
        )}
      </MainContent>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    teams: state.teams.data,
  };
};

export default withI18n()(connect(mapStateToProps)(UpdateLoginMethodsModal));
