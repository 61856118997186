import React, { useState } from 'react';

import { t, Trans } from '@lingui/macro';
import styled from 'styled-components';

import Button from '~/components/Button';
import CheckBox from '~/components/CheckBox';
import Modal from '~/components/Modal';

import { COLOR_PALETTE } from '~/styles';

const ModalBody = styled.div``;

const DeleteConversationText = styled.div`
  font-size: 14px;
  line-height: 1.71;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin-bottom: 12px;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  letter-spacing: 0.29px;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const StyledCheckBox = styled(CheckBox)`
  margin: 4px 12px 4px 0;
`;

const DeleteConversationModal = ({
  deleteOptions,
  i18n,
  onClose,
  deleteConversation,
  showDeleteAll,
  showDeleteFuture,
  loadDeleteConversation,
}) => {
  const [deleteOption, setDeleteOption] = useState(deleteOptions.SINGLE);

  return (
    <Modal
      title={
        showDeleteAll
          ? i18n._(t`You are about to delete a repeating conversation`)
          : i18n._(t`Are you sure?`)
      }
      onClose={onClose}
      width={360}
      footerRight={
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            onClick={onClose}
            loading={loadDeleteConversation}
            label={i18n._(t`Cancel`)}
            type="white"
          />
          <Button
            styles={{
              height: '40px',
              marginLeft: '10px',
              textTransform: 'uppercase',
              padding: '0px 15px',
              fontSize: '12px',
              minWidth: '81px',
            }}
            loading={loadDeleteConversation}
            onClick={() => deleteConversation(deleteOption)}
            label={i18n._(t`Delete`)}
            type="primary"
          />
        </div>
      }
    >
      <ModalBody>
        <div>
          <DeleteConversationText>
            {showDeleteAll ? (
              <>
                <Trans>
                  You are about to delete a repeating conversation. Do you want to delete only this
                  event, all future events (from today), or the entire sequence of events? Please
                  keep in mind: this action cannot be undone.
                </Trans>
                .
              </>
            ) : (
              <>
                <Trans>You are about to delete this conversation. This cannot be undone</Trans>.
              </>
            )}
          </DeleteConversationText>
          {showDeleteAll && (
            <>
              <CheckBoxContainer>
                <StyledCheckBox
                  roundCheckbox
                  checked={deleteOption === deleteOptions.SINGLE}
                  onChange={() => {
                    setDeleteOption(deleteOptions.SINGLE);
                  }}
                  size={24}
                />
                <Trans>Only this conversation</Trans>
              </CheckBoxContainer>
              {showDeleteFuture && (
                <CheckBoxContainer>
                  <StyledCheckBox
                    roundCheckbox
                    checked={deleteOption === deleteOptions.FUTURE}
                    onChange={() => {
                      setDeleteOption(deleteOptions.FUTURE);
                    }}
                    size={24}
                  />
                  All future conversations (from today)
                </CheckBoxContainer>
              )}
              <CheckBoxContainer>
                <StyledCheckBox
                  roundCheckbox
                  checked={deleteOption === deleteOptions.ALL}
                  onChange={() => {
                    setDeleteOption(deleteOptions.ALL);
                  }}
                  size={24}
                />
                <Trans>All conversations</Trans>
              </CheckBoxContainer>
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteConversationModal;
