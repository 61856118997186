import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import ProgressBar from '~/components/ProgressBar';
import Tooltip from '~/components/Tooltip';

import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';

import {
  SkillMatch,
  ProgressContainer,
  JobCardWrapper,
  JobCardImage,
  JobCardTitle,
  JobCardSkillMatchWrapper,
  JobCardDivider,
  JobCardInfo,
  LikeButtonWrapper,
  DeleteButtonWrapper,
  ImagePlaceholder,
} from '../design';
import { LikeButton } from '../LikeButton';

import type { ICareerPlan } from '@learned/types';

interface ILike {
  company: string;
  id: string;
  jobProfile: string;
  user: string;
}

interface IJobProfile {
  id: string;
  coverImage?: string;
  skillMatch?: number;
  name: string;
  like?: ILike;
}

interface IJobCardProps {
  jobProfile: IJobProfile;
  hideSaveBtn?: boolean;
  showDeleteBtn?: boolean;
  companyColor?: string;
  onDelete?: (item: ICareerPlan) => void;
  handleLikeButtonClick?: (jobProfileId: string, likeId?: string) => Promise<void>;
  onClickViewDetails: (roleId: string) => void;
}

const JobProfileCard = ({
  jobProfile,
  hideSaveBtn,
  showDeleteBtn,
  companyColor,
  onDelete,
  handleLikeButtonClick,
  onClickViewDetails,
}: IJobCardProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const $disableLikeButton = useBoolState(false);

  const onLikeButtonClick = () => {
    $disableLikeButton.on();
    handleLikeButtonClick &&
      handleLikeButtonClick(jobProfile.id, jobProfile?.like?.id).finally(() =>
        $disableLikeButton.off(),
      );
  };

  return (
    <>
      <JobCardWrapper $isSaved={Boolean(jobProfile?.like)}>
        <JobCardImage>
          <DeleteButtonWrapper className="delete-btn">
            {showDeleteBtn && (
              <Button
                type="button"
                variant={ButtonVariant.ICON}
                size={ButtonSize.MEDIUM}
                onClick={() => onDelete && onDelete(jobProfile as ICareerPlan)}
                icon={ICONS.DELETE_BIN}
              />
            )}
          </DeleteButtonWrapper>
          {jobProfile?.coverImage ? (
            <img alt={getMultiLangString(jobProfile?.name || '')} src={jobProfile?.coverImage} />
          ) : (
            <ImagePlaceholder backgroundColor={companyColor} height="100%" />
          )}
        </JobCardImage>
        <div>
          <JobCardTitle>{getMultiLangString(jobProfile?.name || '')}</JobCardTitle>
        </div>
        <JobCardSkillMatchWrapper>
          <SkillMatch>
            {jobProfile?.skillMatch && (
              <ProgressContainer>
                <span>
                  <Trans>Skill match</Trans>
                </span>
                <ProgressBar isHideValue value={jobProfile?.skillMatch} height={6} width={150} />
                <span className="percentage">{jobProfile?.skillMatch}</span>
              </ProgressContainer>
            )}
          </SkillMatch>
        </JobCardSkillMatchWrapper>
        <JobCardDivider />
        <JobCardInfo>
          <Tooltip
            tooltip={
              !jobProfile?.like
                ? i18n._(t`Show HR you are interested in the job`)
                : i18n._(t`No longer show HR you are interested in the job`)
            }
          >
            <LikeButtonWrapper className="like-btn">
              {!hideSaveBtn && (
                <LikeButton
                  isSaved={Boolean(jobProfile?.like)}
                  onSave={onLikeButtonClick}
                  isDisabled={$disableLikeButton.value}
                />
              )}
            </LikeButtonWrapper>
          </Tooltip>
          <Button
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
            label={i18n._(t`View details`)}
            onClick={() => onClickViewDetails(jobProfile.id)}
          />
        </JobCardInfo>
      </JobCardWrapper>
    </>
  );
};

export { JobProfileCard };
