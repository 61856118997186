import styled from 'styled-components';

import { COLORS } from '~/styles';

const Content = styled.p`
  white-space: pre-line;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: normal;
  color: ${COLORS.TEXT_MAIN};
`;

const SectionTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  color: ${COLORS.TEXT_MAIN};
`;

const GraphicContent = styled.div`
  pointer-events: none;
  > div {
    padding: 32px;
    margin: 0px;
    min-height: unset;
    > div {
      > div:first-child {
        display: none;
      }
    }
  }
`;

export { SectionTitle, Content, GraphicContent };
