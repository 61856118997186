import React from 'react';

import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';

import { TaskStatusLabel } from './TaskStatusLabel';

import type { ITask } from '@learned/types';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface ITaskStatusProps {
  task: ITask;
  onDelete?: () => void;
}

const TaskStatus = ({ task, onDelete }: ITaskStatusProps) => {
  return (
    <Wrapper>
      <TaskStatusLabel status={task.status} />
      {onDelete && (
        <Button
          label=""
          size={ButtonSize.MEDIUM}
          variant={ButtonVariant.ICON_DELETE}
          isLoading={false}
          onClick={onDelete}
        />
      )}
    </Wrapper>
  );
};

export { TaskStatus };
