import { JOB_TEMPLATE_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';

import type { IJobTemplate } from '@learned/types';
import type { I18n } from '@lingui/core';

export interface IJobTemplateStatus {
  id: IJobTemplate['status'];
  key: IJobTemplate['status'];
  name: string;
  translated: (i18n: I18n) => string;
}

export const JOB_TEMPLATE_STATUS_WITH_NAMES = {
  DRAFT: {
    id: JOB_TEMPLATE_STATUS.DRAFT,
    key: JOB_TEMPLATE_STATUS.DRAFT,
    name: 'Draft',
    translated: (i18n: I18n) => i18n._(t`Draft`),
  },
  PUBLISHED: {
    id: JOB_TEMPLATE_STATUS.PUBLISHED,
    key: JOB_TEMPLATE_STATUS.PUBLISHED,
    name: 'Published',
    translated: (i18n: I18n) => i18n._(t`Published`),
  },
  ARCHIVED: {
    id: JOB_TEMPLATE_STATUS.ARCHIVED,
    key: JOB_TEMPLATE_STATUS.ARCHIVED,
    name: 'Archived',
    translated: (i18n: I18n) => i18n._(t`Archived`),
  },
};
