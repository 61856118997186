import { IGetUserReviewsRequest, IUser } from '@learned/types';

import { ICreateServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';

import cloudRequest from '../cloudRequest';

export function getUserReviewsByUser(userId: IUser['id'], body: IGetUserReviewsRequest) {
  return cloudRequest((serverRoutes.publicProfile.items as ICreateServerRoute)(userId), {}, body);
}
