import { createSelector } from 'reselect';

export const getCompanySettings = (state) => state.companySettings;
export const getGoalUpdatesSettings = createSelector(
  [getCompanySettings],
  (settings) => settings.goalUpdates,
);
export const getGiveFeedbackSettings = createSelector(
  [getCompanySettings],
  (settings) => settings.giveFeedbackCycle,
);
export const getAskFeedbackSettings = createSelector(
  [getCompanySettings],
  (settings) => settings.askFeedbackCycle,
);
