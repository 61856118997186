import React, { MouseEventHandler } from 'react';

import {
  IReviewTemplate,
  IReviewTemplateSection,
  IReviewTemplateOld,
  REVIEW_STATUSES,
} from '@learned/types';
import { t, Trans } from '@lingui/macro';
import styled, { css } from 'styled-components';

import { StatusLabel } from '~/components/TableList/design';
import { NameWithQuestions } from '~/pages/Surveys/NameWithQuestions';

import type { IColumnTable } from '~/@types/table';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';
import convertToTimeString from '~/utils/convertToTimeString';

import { FlexRow, NameRow } from '../../design';

import type { I18n } from '@lingui/core';

const DateRow = styled.div<{ unplanned?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  width: 170px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  ${({ unplanned }) =>
    unplanned &&
    css`
      color: ${COLORS.CONFIRMATION_MODAL_DELETE};
    `}
`;

export enum SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
  CREATED_SOON_LATER = 'CREATED_OLD_NEW',
  CREATED_LATER_SOON = 'CREATED_NEW_OLD',
  UPDATED_SOON_LATER = 'UPDATED_OLD_NEW',
  UPDATED_LATER_SOON = 'UPDATED_NEW_OLD',
}

const STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
};

const createColumns: (
  getMultiLangString: ReturnType<typeof useMultiLangString>,
) => IColumnTable<IReviewTemplate>[] = (getMultiLangString) => [
  {
    name: (i18n: I18n) => i18n._(t`Templates`),
    accessor: 'name',
    maxWidth: '312px',
    renderCell: (
      item: IReviewTemplate | IReviewTemplateOld,
      onClick: MouseEventHandler<HTMLDivElement> | undefined,
    ) => {
      const numberOfQuestions = (item as IReviewTemplate).questions
        ? (item as IReviewTemplate).questions.length
        : (item as IReviewTemplateOld).sections
            ?.map((i: IReviewTemplateSection) => i.questions.length)
            .reduce((partialSum: number, a: number) => partialSum + a, 0);

      return (
        <NameRow>
          {/* @ts-ignore */}
          <FlexRow onClick={() => onClick(item)}>
            <NameWithQuestions
              name={getMultiLangString(item.name)}
              questionsTotal={numberOfQuestions}
              isGrayColor={item?.status === REVIEW_STATUSES.DRAFT}
              isLeftMargin={false}
              isSubTextGrayColor={true}
              isTooltipOnLongName={true}
            />
          </FlexRow>
        </NameRow>
      );
    },
    sortBy: {
      asc: {
        key: SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Created`),
    accessor: 'created',
    minWidth: '116px',
    sortBy: {
      asc: {
        key: SORT_OPTIONS.CREATED_SOON_LATER,

        label: (i18n: I18n) => i18n._(t`Soon - later`),
      },
      desc: {
        key: SORT_OPTIONS.CREATED_LATER_SOON,
        label: (i18n: I18n) => i18n._(t`Later - soon`),
      },
    },
    renderCell: (item: any) => {
      return <DateRow>{convertToTimeString(item.meta.createdDate)}</DateRow>;
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Updated`),
    accessor: 'updated',
    minWidth: '116px',
    sortBy: {
      asc: {
        key: SORT_OPTIONS.UPDATED_SOON_LATER,

        label: (i18n: I18n) => i18n._(t`Soon - later`),
      },
      desc: {
        key: SORT_OPTIONS.UPDATED_LATER_SOON,
        label: (i18n: I18n) => i18n._(t`Later - soon`),
      },
    },
    renderCell: (item: any) => {
      return (
        <DateRow>
          {convertToTimeString(item.meta.lastModifiedDate || item.meta.createdDate)}
        </DateRow>
      );
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Status`),
    accessor: 'status',
    minWidth: '162px',
    renderCell: (item) => {
      const { status } = item;
      const labelProps =
        status === STATUS.PUBLISHED
          ? {
              color: COLORS.STATUS_PUBLISHED,
              backgroundColor: COLORS.STATUS_PUBLISHED,
              backgroundByPercentage: 8,
            }
          : {
              color: COLORS.STATUS_DRAFT,
              backgroundColor: COLORS.STATUS_DRAFT,
              backgroundByPercentage: 30,
            };
      return (
        <StatusLabel {...labelProps}>
          {status === STATUS.PUBLISHED ? <Trans>Published</Trans> : <Trans>Draft</Trans>}
        </StatusLabel>
      );
    },
  },
];

export { createColumns };
