import React, { KeyboardEvent } from 'react';

import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS, ICON_SIZES } from '~/components/Icon';
import { Input } from '~/components/Input';

import { Label, Tag, Tags } from './design';
import { EditItemModal } from './EditItemModal';
import { useAddMultipleItems } from './useAddMultipleItems';

import type { AddMultipleItemsProps, Item } from './types';
import type { IMultiLangString } from '@learned/types';

function AddMultipleItems<T extends object>({
  field,
  title,
  modal,
  formMethods,
  languageState,
  placeholder,
}: AddMultipleItemsProps<T>) {
  const { item, setItem, editItem, setEditItem, editItemForm, addItem, updateItem, deleteItem } =
    useAddMultipleItems({
      field,
      formMethods,
      languageState,
    });

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !isEmpty(item)) {
      addItem();
    }
  };

  return (
    <div>
      <Label>{title}</Label>
      <Input
        placeholder={placeholder}
        type="text"
        value={item}
        onChange={(e) => setItem(e.target.value)}
        onKeyUp={onKeyUp}
        width="281px"
        height="38px"
        rightIcon={
          <Button
            type="button"
            variant={ButtonVariant.ICON}
            size={ButtonSize.MEDIUM}
            icon={ICONS.ADD_PLUS}
            iconSize={ICON_SIZES.SMALL}
            onClick={(event) => {
              addItem();
              event.preventDefault();
            }}
            disabled={isEmpty(item)}
          />
        }
      />
      <Tags>
        {(formMethods.getValues(field) as unknown as Array<IMultiLangString & Item>)?.map(
          (item, index) => (
            <Tag onClick={() => setEditItem(item.id)} key={index}>
              {item[languageState.primaryLanguage.locale] || find(item)}
              <Button
                type="button"
                variant={ButtonVariant.ICON}
                size={ButtonSize.MEDIUM}
                icon={ICONS.CLOSE_2}
                iconSize={ICON_SIZES.SMALL}
                onClick={(e) => {
                  deleteItem(item.id);
                  e.stopPropagation();
                }}
              />
            </Tag>
          ),
        )}
      </Tags>
      {editItem && (
        <EditItemModal
          modal={modal}
          item={editItemForm}
          languageState={languageState}
          onSave={(newValues) => updateItem({ ...newValues, id: editItem })}
          onDelete={(id) => deleteItem(id)}
          onClose={() => setEditItem('')}
        />
      )}
    </div>
  );
}

export { AddMultipleItems };
