import React from 'react';

import { Trans } from '@lingui/macro';

import { Button, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';

import {
  ArchiveButton,
  DeleteButton,
  HeaderActions,
  SaveButton,
  Title,
  TitleWrapper,
  Wrapper,
} from './design';

interface IProps {
  handleDelete: () => void;
  handleArchive: () => void;
  handleEdit?: () => void;
  onClose: () => void;
  isOriginalMode?: boolean;
}

export const Header = ({
  handleDelete,
  handleArchive,
  handleEdit,
  onClose,
  isOriginalMode = false,
}: IProps) => {
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>
          <Trans>Goal preview</Trans>
        </Title>
        <HeaderActions>
          {isOriginalMode && (
            <>
              <DeleteButton
                icon={ICONS.DELETE_BIN}
                variant={ButtonVariant.ICON}
                onClick={handleDelete}
              />
              <ArchiveButton
                icon={ICONS.ARCHIVE}
                variant={ButtonVariant.ICON}
                onClick={handleArchive}
              />
              {handleEdit && (
                <SaveButton
                  icon={ICONS.EDIT_PENCIL}
                  variant={ButtonVariant.ICON}
                  onClick={handleEdit}
                />
              )}
            </>
          )}

          <Button iconSize={ICON_SIZES.LARGE} variant={ButtonVariant.CLOSE} onClick={onClose} />
        </HeaderActions>
      </TitleWrapper>
    </Wrapper>
  );
};
