import React from 'react';

import { DAY_OF_WEEK } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SelectDropDown from '~/components/SelectDropDown';

import { COLOR_PALETTE } from '~/styles';

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: ${(props) => (props.$isBold ? 'bold' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin-right: 8px;
`;

const options = [
  {
    key: DAY_OF_WEEK.MONDAY,
    title: (i18n) => i18n._(t`Monday`),
  },
  {
    key: DAY_OF_WEEK.TUESDAY,
    title: (i18n) => i18n._(t`Tuesday`),
  },
  {
    key: DAY_OF_WEEK.WEDNESDAY,
    title: (i18n) => i18n._(t`Wednesday`),
  },
  {
    key: DAY_OF_WEEK.THURSDAY,
    title: (i18n) => i18n._(t`Thursday`),
  },
  {
    key: DAY_OF_WEEK.FRIDAY,
    title: (i18n) => i18n._(t`Friday`),
  },
  {
    key: DAY_OF_WEEK.SATURDAY,
    title: (i18n) => i18n._(t`Saturday`),
  },
  {
    key: DAY_OF_WEEK.SUNDAY,
    title: (i18n) => i18n._(t`Sunday`),
  },
];

const DeadlineRow = ({ label, value, onChange, isBold }) => {
  const { i18n } = useLingui();

  return (
    <Row>
      <Label $isBold={isBold}>{label}</Label>
      <SelectDropDown
        options={options}
        value={value}
        onChange={onChange}
        keyName="key"
        renderLabel={(item) => <div>{item.title(i18n)}</div>}
        width="150px"
      />
    </Row>
  );
};

DeadlineRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  isBold: PropTypes.bool,
};

export default DeadlineRow;
