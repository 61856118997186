import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
`;

export const FlexRow = styled.div<{
  $isHidePointer?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: ${({ $isHidePointer }) => (!$isHidePointer ? 'pointer' : 'default')};
`;

export const NameRow = styled.div<{ isCentered?: boolean; padding?: string }>`
  background-color: inherit;
  padding: ${({ padding }) => padding || 'unset'};
`;

export const TD = styled.div<{
  minWidth?: string;
  maxWidth?: string;
  padding?: string;
  isGrayColor?: boolean;
  $isPointer?: boolean;
}>`
  min-width: ${({ minWidth }) => minWidth || '120px'};
  width: ${({ maxWidth }) => maxWidth || 'unset'};
  max-width: ${({ maxWidth }) => maxWidth || 'unset'};
  padding: ${({ padding }) => padding || '0'};
  color: ${({ isGrayColor }) => (isGrayColor ? COLORS.DARK_GRAY : COLORS.BLACK)};
  cursor: ${({ $isPointer }) => ($isPointer ? 'pointer' : 'default')};
`;
