import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Dropdown } from '~/components/Dropdown';
import { CalendarDropdown, DateOption } from '~/pages/Conversations/components/CalendarDropdown';

import {
  IMultiSelectOption,
  USER_REVIEW_CREATED_BY_OPTIONS,
  USER_REVIEW_STATUS_OPTIONS,
} from '~/constants/userReviews';

export interface IFilters {
  createdBy: IMultiSelectOption[];
  isShowFilters: boolean;
  search: string;
  setSearch: (value: string) => void;
  onChangeFilter: (key: string, value: any) => void;
  resetFilters: () => void;
  selectedStatus: string | null;
  selectedDateOption?: DateOption;
  statuses: IMultiSelectOption[];
}

const Filters = ({ filters }: { filters: IFilters }) => {
  const { i18n } = useLingui();
  return (
    <>
      <Dropdown
        placeholder={i18n._(t`Status`)}
        selectedItems={filters.statuses || []}
        items={USER_REVIEW_STATUS_OPTIONS}
        onChange={(selectedItems?: IMultiSelectOption[]) => {
          filters.onChangeFilter('statuses', selectedItems || []);
        }}
        stringifyItem={(item) => item.translated(i18n)}
        isSingleSelect={false}
        skipSort={true}
      />

      <Dropdown
        placeholder={i18n._(t`Created by`)}
        selectedItems={filters.createdBy || []}
        items={USER_REVIEW_CREATED_BY_OPTIONS}
        onChange={(selectedItems?: IMultiSelectOption[]) => {
          filters.onChangeFilter('createdBy', selectedItems || []);
        }}
        stringifyItem={(item) => item.translated(i18n)}
        isSingleSelect={false}
        skipSort={true}
      />

      <CalendarDropdown
        disabled={false}
        selectedItem={filters.selectedDateOption}
        // @ts-ignore
        setSelectedItem={(value: IType[]) => filters.onChangeFilter('selectedDateOption', value)}
        filterOptions={[2, 1, 5, 6, 9]}
      />
    </>
  );
};

export { Filters };
