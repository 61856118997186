import React, { memo } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';
import Tooltip from '~/components/Tooltip';

import LockIcon from '~/assets/lock-24px.svg';

const Container = styled.div`
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  padding: 0 4px;
  cursor: pointer;
  text-transform: lowercase;
`;

const LockedHint = ({ tooltip }) => (
  <Tooltip tooltip={tooltip}>
    <Container>
      <SvgIcon
        width="24px"
        height="24px"
        isDefaultColor
        defaultColor="var(--company-color)"
        url={LockIcon}
      />
    </Container>
  </Tooltip>
);

LockedHint.propTypes = {
  tooltip: PropTypes.string.isRequired,
};

export default memo(LockedHint);
