import React, { useEffect, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import SearchSelectButton from '~/components/SearchSelectButton';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SkillName from '~/components/Skill/SkillName';
import SvgIcon from '~/components/SvgIcon';

import SearchIcon from '~/assets/ic-search-24-px.svg';
import PlaceholderIcon from '~/assets/mdi-tag-multiple.svg';

import useDebounce from '~/hooks/useDebounce';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import getLang from '~/selectors/getLang';
import { getSkillCategories } from '~/services/skillCategories';
import { getSkills, getSkill } from '~/services/skills';
import { COLOR_PALETTE, COLORS } from '~/styles';
import getSkillLevels from '~/utils/getSkillLevels';
import { getSkillName } from '~/utils/skillUtils';

import SelectLevelModal from '../SelectLevelModal';

const GoalsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GoalSkill = styled.div`
  margin: 10px 8px 8px 8px;
  padding: 8px 36px 8px 12px;
  border-radius: 6px;
  background-color: ${COLORS.BG_PAGE};
  font-weight: bold;
  line-height: 1.57;
  letter-spacing: 0.25px;
  box-sizing: border-box;
  font-size: 14px;
  color: var(--company-color);
  position: relative;
  &:hover .skill-remove-btn {
    display: block;
    background-color: ${COLOR_PALETTE.WHITE};
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }
  &:hover .skill-level {
    display: none;
  }
`;

const FilterWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;

  .filter-left {
    margin-right: 8px;
  }
`;

const GoalSkillRemoveButton = styled.button`
  display: none;
  background: inherit;
  border: none;
  color: var(--company-color);
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  cursor: pointer;
  margin: 0;
  padding: 0;
`;

const Level = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLOR_PALETTE.WHITE};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: var(--company-color);
`;

const PlaceholderContainer = styled.div`
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PlaceholderTitle = styled.div`
  margin-top: 8px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #000000;
`;

const PlaceholderText = styled.div`
  font-size: 14px;
  margin-top: 8px;
  line-height: 1.57;
  text-align: center;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const GoalSkillsBox = styled.div`
  min-height: 48px;
  border-radius: 6px;
  background-color: ${COLOR_PALETTE.WHITE};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-wrap: wrap;
  padding-top: ${(props) => props.isTopPadding && '7px'};
  border: 1px solid ${(props) => (props.error ? COLORS.ERROR : COLORS.BORDER_HARD)};

  .img-search {
    display: block;
    margin-left: 8px;
  }
`;

const ResultSkillsBox = styled.div`
  min-height: 48px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-wrap: wrap;
  margin-top: ${(props) => (props.$isSmallMargin ? '6px' : '26px')};
  height: auto;
  overflow-y: auto;

  p {
    margin: 10px 8px 8px 0;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.57;
    letter-spacing: 0.25px;
    color: #000000;
    background-color: ${COLORS.BG_PAGE};
    box-sizing: border-box;
  }
`;

const SearchInput = styled.input`
  border: none;
  flex: 1;
  background: transparent;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  color: #70747f;
  margin: 0 0 7px 7px;
  box-sizing: border-box;

  &::placeholder {
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    vertical-align: center;
    color: #70747f;
  }
`;

const Skill = styled.p`
  background-color: green;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const SkillActions = styled.div`
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  background: inherit;
`;

const SkillNameWrapper = styled.div`
  cursor: pointer;
`;

const ErrorText = styled.p`
  font-size: 16px;
  color: ${COLORS.ERROR};
  margin-left: 4px;
`;

const FILTERED_SKILLS_LIMIT = 20;

const sortSkills = (skills, getMultiLangString) =>
  skills.sort((a, b) => getMultiLangString(a.name).localeCompare(getMultiLangString(b.name)));

const getFilteredSkills = async ({
  categories,
  search,
  prioritisedSkills,
  hiddenSkills,
  preSelectedSkills,
  limit = FILTERED_SKILLS_LIMIT,
  getMultiLangString,
}) => {
  if (!search && isEmpty(categories)) {
    return Object.values(prioritisedSkills);
  }

  // Holds only one skill per skill name to prevent duplicates
  let prioritisedSkillsObj = { ...prioritisedSkills };

  if (!isEmpty(categories)) {
    prioritisedSkillsObj = Object.fromEntries(
      Object.entries(prioritisedSkillsObj).filter(
        ([_key, s]) => !isEmpty(intersection(categories, s.categories)),
      ),
    );
  }

  // Delete from the object the skills with the same name as then ones that should be hidden
  if (hiddenSkills) {
    hiddenSkills.forEach((sk) => {
      if (prioritisedSkillsObj[sk.name]) {
        delete prioritisedSkillsObj[sk.name];
      }
    });
  }

  // Filter out preselected skills and skills that don't match the search string
  let resultSkills = Object.values(prioritisedSkillsObj).filter((item) => {
    if (preSelectedSkills && preSelectedSkills[item.id]) {
      return false;
    }

    if (
      item.name &&
      getMultiLangString(item.name).toLowerCase().indexOf(search.toLowerCase()) !== -1
    ) {
      return true;
    }

    return false;
  });

  // Sort skills by name and slice them to get the desired length
  return sortSkills(resultSkills.filter(Boolean), getMultiLangString).slice(0, limit);
};

const SelectSkills = ({
  hiddenSkills,
  preSelectedSkills,
  toggleSkill,
  withoutLevels,
  selectedSkills,
  selectedLevels,
  error,
  errorText,
  isShowPreloadedSkills,
  isCategoriesFilter,
  preSelectedCategories,
}) => {
  const { i18n } = useLingui();
  const [skill, setSkill] = useState({});
  const [prioritisedSkills, setPrioritisedSkills] = useState({});
  const [filteredSkills, setFilteredSkills] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [isLevelModalOpen, setIsLevelModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdateSkill, setIsUpdateSkill] = useState(false);
  const [skillCategories, setSkillCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(preSelectedCategories ?? []);
  const lang = useSelector(getLang);
  const search = useDebounce(searchInputValue, 300);
  const getMultiLangString = useMultiLangString();

  useEffect(() => {
    const fetchSkills = async () => {
      const [skillsResponse, skillCategories] = await Promise.all([
        getSkills({ enabledSkillsOnly: true, hideDeleted: true }),
        getSkillCategories(),
      ]);

      const skillValues = values(skillsResponse.data.skills);

      // Holds only one skill per skill name, based on skill set priority, to prevent duplicates
      const prioritisedSkillsObj = {};

      skillValues.forEach((sk) => {
        const skillName = getSkillName(sk, lang);

        // it needs for filter
        const categories = [sk.skillCategory];

        prioritisedSkillsObj[skillName] = { ...sk, categories };
      });

      setSkillCategories(Object.values(skillCategories));
      setPrioritisedSkills(prioritisedSkillsObj);

      // If it should load existing skills at the beggining
      if (isShowPreloadedSkills) {
        const returnedSkills = await getFilteredSkills({
          categories: selectedCategories,
          hiddenSkills,
          preSelectedSkills,
          search: '',
          prioritisedSkills: prioritisedSkillsObj,
          limit: 50,
          getMultiLangString,
        });
        setFilteredSkills(returnedSkills);
      }

      setIsLoading(false);
    };

    fetchSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    getFilteredSkills({
      categories: selectedCategories,
      hiddenSkills,
      preSelectedSkills,
      search,
      prioritisedSkills,
      getMultiLangString,
    }).then((skills) => setFilteredSkills(skills));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenSkills, preSelectedSkills, search, selectedCategories]);

  const handleSearch = (e) => {
    e.preventDefault();
    let value = e.target.value;
    setSearchInputValue(value);
  };

  const onOpenLevelModal = (skill, isUpdate) => {
    setSkill(skill);
    setIsUpdateSkill(isUpdate);
    setIsLevelModalOpen(true);
  };

  const handleSelect = async (e, selectedSkill, isUpdate) => {
    let skill = { ...selectedSkill };

    if (withoutLevels) {
      toggleSkill(skill)(e);
    } else {
      const skillPopulated = await getSkill(skill.id);

      const skillLevels = getSkillLevels(skillPopulated);
      const levelsEnabled = skillPopulated.levelsEnabled.filter(Boolean);

      if (skillLevels.filter(Boolean).length > 1 || levelsEnabled.length > 1) {
        onOpenLevelModal(skillPopulated, isUpdate);
      } else {
        const selectedLevel = skillLevels.findIndex((level) => !isEmpty(level));
        toggleSkill(skillPopulated)(e, { level: selectedLevel > 0 ? selectedLevel : 1, isUpdate });
      }
    }
  };

  const categoriesList = skillCategories.map((c) => {
    return {
      id: c.categoryId || c.id,
      label: getMultiLangString(c.name),
    };
  });

  const onCloseLevelModal = () => {
    setIsLevelModalOpen(false);
    setSkill({});
    setIsUpdateSkill(false);
  };

  const getPlaceHolder = () => (
    <PlaceholderContainer>
      <SvgIcon
        width="40px"
        height="40px"
        url={PlaceholderIcon}
        defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
        isDefaultColor
      />
      <PlaceholderTitle>
        {search ? <Trans>No results</Trans> : <Trans>Type to search</Trans>}
      </PlaceholderTitle>
      <PlaceholderText>
        {search ? (
          <Trans>We could not find any skills..</Trans>
        ) : (
          <Trans>Type the name of the skill to search</Trans>
        )}
      </PlaceholderText>
    </PlaceholderContainer>
  );

  sortSkills(selectedSkills, getMultiLangString);

  return (
    <ShowSpinnerIfLoading loading={isLoading}>
      <GoalsContainer>
        <div className="modal_input-group">
          <GoalSkillsBox error={error} isTopPadding={isEmpty(selectedSkills)}>
            <SvgIcon
              classNameIcon="img-search"
              width="24px"
              height="24px"
              isDefaultColor
              defaultColor={COLORS.SUBTEXT}
              url={SearchIcon}
            />
            {!isEmpty(selectedSkills) &&
              selectedSkills.map((skill, index) => {
                const skillLevel = get(selectedLevels, skill.id, null);
                return (
                  <GoalSkill onClick={(e) => handleSelect(e, skill, true)} key={index}>
                    <SkillNameWrapper>
                      <SkillName skill={skill} />
                    </SkillNameWrapper>
                    <SkillActions>
                      <GoalSkillRemoveButton
                        className="skill-remove-btn"
                        onClick={toggleSkill(skill)}
                      >
                        &#10005;
                      </GoalSkillRemoveButton>
                      {skillLevel && <Level className="skill-level">{skillLevel}</Level>}
                    </SkillActions>
                  </GoalSkill>
                );
              })}
            <SearchInput
              type="text"
              placeholder={i18n._(t`Search`)}
              value={searchInputValue}
              onChange={handleSearch}
            />
          </GoalSkillsBox>
          {isCategoriesFilter && (
            <FilterWrapper>
              {isCategoriesFilter && (
                <SearchSelectButton
                  checkedList={selectedCategories}
                  title={i18n._(t`Category`)}
                  options={categoriesList}
                  handleChange={(categories) => setSelectedCategories(categories)}
                />
              )}
            </FilterWrapper>
          )}
          {error && <ErrorText> {errorText} </ErrorText>}
          {!isEmpty(filteredSkills) ? (
            <ResultSkillsBox $isSmallMargin={isCategoriesFilter}>
              {filteredSkills.map((skill, index) => {
                const isSelected = selectedSkills
                  ? selectedSkills.map((g) => g.id).indexOf(skill.id) !== -1
                  : false;
                return (
                  !isSelected && (
                    <Skill onClick={(e) => handleSelect(e, skill, false)} key={index}>
                      <SkillName skill={skill} />
                    </Skill>
                  )
                );
              })}
            </ResultSkillsBox>
          ) : (
            getPlaceHolder()
          )}
        </div>

        {isLevelModalOpen && (
          <SelectLevelModal
            skill={skill}
            selectedLevel={get(selectedLevels, skill.id)}
            onClose={onCloseLevelModal}
            onSubmit={toggleSkill(skill)}
            isUpdate={isUpdateSkill}
            categories={skillCategories}
          />
        )}
      </GoalsContainer>
    </ShowSpinnerIfLoading>
  );
};

SelectSkills.propTypes = {
  withoutLevels: PropTypes.bool,
  selectedLevels: PropTypes.object,
  selectedSkills: PropTypes.array,
  hiddenSkills: PropTypes.array,
};

SelectSkills.defaultProps = { hiddenSkills: [] };

export default SelectSkills;
