import React, { useContext } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import Editor from '~/components/Editor';
import MinusIcon from '~/components/Icons/Minus';
import PlusIcon from '~/components/Icons/Plus';
import { FieldGroup, FieldTitle } from '~/components/Text';
import { ConversationSetupContext } from '~/pages/ConversationSetup';

import useBoolState from '~/hooks/useBoolState';
import { getDescription } from '~/selectors/currentConversation';
import { setDescription } from '~/store/currentConversation/actions';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DescriptionSection = () => {
  const { i18n } = useLingui();
  const description = useSelector(getDescription);
  const dispatch = useDispatch();
  const $isDescription = useBoolState(false);
  const { error, isAdmin } = useContext(ConversationSetupContext);

  const onChangeDescription = (value) => {
    if (error.value) {
      error.off();
    }
    dispatch(setDescription(value));
  };

  return (
    <FieldGroup>
      <Row>
        <FieldTitle>{$isDescription.value && <Trans>Description</Trans>}</FieldTitle>
        <Button
          type="link-primary"
          label={$isDescription.value ? i18n._(t`Hide description`) : i18n._(t`Show description`)}
          iconLeft={$isDescription.value ? <MinusIcon size={18} /> : <PlusIcon size={24} />}
          onClick={$isDescription.toggle}
        />
      </Row>
      {$isDescription.value && (
        <Editor
          placeholder={i18n._(t`Explain the purpose for this 1-1 meeting.`)}
          minRows={6}
          value={description}
          onChange={(value) => onChangeDescription(value)}
          readOnly={isAdmin}
          compact={true}
        />
      )}
    </FieldGroup>
  );
};

DescriptionSection.propTypes = {
  error: PropTypes.object,
};

export default React.memo(DescriptionSection);
