import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import FocusLayout from '~/layouts/FocusLayout';
import GoalDashboard from '~/pages/GoalDashboard';
import { Goals } from '~/pages/Goals';
import { GoalSetup } from '~/pages/GoalSetup';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('goals', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.GOALS.routePath(ROLES.USER)}
        exact
        path={routes.GOALS.routePath(ROLES.USER)}
        component={Goals}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.GOAL.routePath(ROLES.USER)}
        exact
        path={routes.GOAL.routePath(ROLES.USER)}
        component={GoalDashboard}
        allowedRoles={[ROLES.USER]}
        layout={FocusLayout}
      />,
      <PrivateRoute
        layout={FocusLayout}
        key={routes.GOAL_CREATE.routePath(ROLES.USER)}
        exact
        path={routes.GOAL_CREATE.routePath(ROLES.USER)}
        component={GoalSetup}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        layout={FocusLayout}
        key={routes.GOAL_UPDATE.routePath(ROLES.USER)}
        exact
        path={routes.GOAL_UPDATE.routePath(ROLES.USER)}
        component={GoalSetup}
        allowedRoles={[ROLES.USER]}
      />,
    ],
    coach: [
      <PrivateRoute
        layout={FocusLayout}
        key={routes.GOAL_CREATE.routePath(ROLES.COACH)}
        exact
        path={routes.GOAL_CREATE.routePath(ROLES.COACH)}
        component={GoalSetup}
        allowedRoles={[ROLES.COACH]}
      />,
      <PrivateRoute
        layout={FocusLayout}
        key={routes.GOAL_UPDATE.routePath(ROLES.COACH)}
        exact
        path={routes.GOAL_UPDATE.routePath(ROLES.COACH)}
        component={GoalSetup}
        allowedRoles={[ROLES.COACH]}
      />,
      <PrivateRoute
        key={routes.GOAL.routePath(ROLES.COACH)}
        exact
        path={routes.GOAL.routePath(ROLES.COACH)}
        component={GoalDashboard}
        allowedRoles={[ROLES.COACH]}
        layout={FocusLayout}
      />,
    ],
    admin: [
      <PrivateRoute
        layout={FocusLayout}
        key={routes.GOAL_CREATE.routePath(ROLES.ADMIN)}
        exact
        path={routes.GOAL_CREATE.routePath(ROLES.ADMIN)}
        component={GoalSetup}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        layout={FocusLayout}
        key={routes.GOAL_UPDATE.routePath(ROLES.ADMIN)}
        exact
        path={routes.GOAL_UPDATE.routePath(ROLES.ADMIN)}
        component={GoalSetup}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.GOAL.routePath(ROLES.ADMIN)}
        exact
        path={routes.GOAL.routePath(ROLES.ADMIN)}
        component={GoalDashboard}
        allowedRoles={[ROLES.ADMIN]}
        layout={FocusLayout}
      />,
    ],
  },
  routesPublic: [],
});
