import { useEffect, useState } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { useSelector } from 'react-redux';

import { getCurrentCompanyId } from '~/selectors/baseGetters';
import { getJobFamilies } from '~/services/jobFamilies';

import type { IJobFamilyNew } from '../types';

const useJobFamilies = () => {
  const [jobFamilies, setJobFamilies] = useState<IJobFamilyNew[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const companyId = useSelector(getCurrentCompanyId);

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);

    const fetchJobFamilies = async () => {
      const response = await getJobFamilies();
      if (mounted) {
        setJobFamilies(Object.values(response.data[API_RETURN_FIELDS.JOB_FAMILIES]));
        setIsLoading(false);
      }
    };

    fetchJobFamilies();

    return () => void (mounted = false);
  }, [companyId]);

  return { jobFamilies, isLoading };
};

export { useJobFamilies };
