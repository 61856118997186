import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Title = styled.div<{ marginBottom: number }>`
  font-size: 22px;
  font-weight: 600;
  color: ${COLORS.TEXT_MAIN};
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;

export const BigButtonTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 500;

  svg {
    margin-right: 10.8px;
  }
  margin-bottom: 10px;
`;

export const BigButtonSubtitle = styled.div`
  font-size: 12px;
  color: ${COLORS.SUBTEXT};
`;

export const OutroContainer = styled.div<{ marginTop: number }>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ marginTop }) => marginTop}px;
  min-width: 750px;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 750px;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 23.5px 24px 27.5px 23px;
  border-radius: 6px;
  background-color: ${COLORS.WHITE};
  box-sizing: border-box;
`;

export const BigButton = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  width: 452px;
  box-sizing: border-box;
  height: 74px;
  margin: 0 0 10px 0;
  padding: 10px 16px 8px 16px;
  border-radius: 6px;
  border: solid 1px ${(props) => (props.isSelected ? COLORS.COMPANY : COLORS.BG_LIST)};
  color: ${(props) => (props.isSelected ? COLORS.COMPANY : COLORS.TEXT_MAIN)};
  background-color: ${COLORS.BG_LIST};
  cursor: pointer;

  :hover {
    background-color: ${COLORS.HOVER};
    color: ${COLORS.TEXT_HOVER};
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 14px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 0 24px 27.5px 23px;
  box-sizing: border-box;

  & > span {
    font-size: 14px;
    line-height: 1.21;
    text-align: center;
    color: ${COLORS.LABEL_GRAY};
  }
`;

export const CompletedImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  & > img {
    margin-top: 40px;
    width: 200px;
  }
`;
