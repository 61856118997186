import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import NineGridBox from '~/components/NineGridBox';
import OverviewHeading from '~/components/OverviewHeading';
import BaseLayout from '~/layouts/BaseLayout';

import { INSTRUCTIONS } from '~/constants/instructions';
import getInstructionUrl from '~/utils/getInstructionUrl';

const NineGridReportsPage = () => {
  const { i18n } = useLingui();
  return (
    <div>
      <OverviewHeading
        title={i18n._(t`9-grid`)}
        description={i18n._(t`Monitor your organization's performance and developments`)}
        instructions={i18n._(t`How a 9-grid works`)}
        instructionsUrl={getInstructionUrl(INSTRUCTIONS.HOW_NINE_GRID_WORKS_ADMIN)}
      />
      <BaseLayout>
        <NineGridBox />
      </BaseLayout>
    </div>
  );
};

export default NineGridReportsPage;
