import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { COLORS } from '~/styles';

export const LabelWrapper = styled.div`
  padding: 1px 5px 2px 7px;
  border-radius: 3px;
  border: solid 1px ${COLORS.ACCENT_WARNING};
  background-color: rgba(255, 130, 82, 0.1);
  font-size: 12px;
  letter-spacing: -0.13px;
  color: ${COLORS.ACCENT_WARNING};
`;

export const NotSharedLabel = () => {
  return (
    <LabelWrapper>
      <Trans>Not shared</Trans>
    </LabelWrapper>
  );
};
