import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: center;
  justify-content: center;
  margin-right: 85px;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 46px;
  height: 46px;
  border-radius: 6px;
  background-color: ${COLORS.BG_LIST};
`;

export const Title = styled.span`
  font-size: 14px;
  line-height: 1.86;
  color: ${COLORS.SUBTEXT};
`;

export const Subtitle = styled.div`
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLORS.ICONS_PRIMARY};
`;
