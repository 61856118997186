import React, { memo } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import DragDropList from '~/components/DragDropList';
import ConversationIcon from '~/components/Icons/Conversation';
import Placeholder from '~/components/Placeholder';

import TalkingPoint from './TalkingPoint';

import { getUser } from '~/selectors/baseGetters';
import { COLOR_PALETTE } from '~/styles';

const titleStyles = {
  fontSize: '14px',
  fontWeight: 'normal',
  color: COLOR_PALETTE.DARK_GRAY,
  marginTop: '8px',
};

const PointsList = styled.div`
  padding: 24px;
`;

const WideButton = styled(Button)`
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  border-color: ${COLOR_PALETTE.GRAY_MIDDLE};
`;

const TalkingPointTab = ({
  talkingPoints = [],
  openTPModal,
  doneTP = [],
  readOnly,
  currentUserId,
  reorderTalkingPoints,
  TPOrder = [],
  coachTeams,
  writingComment,
  isPdfView = false,
  i18n,
  $conversation,
}) => {
  const user = useSelector(getUser);

  if (talkingPoints.length === 0) {
    return (
      <>
        <Placeholder
          titleStyles={titleStyles}
          action={!readOnly && openTPModal}
          actionText={<Trans>Add new talking point</Trans>}
          title={<Trans>No talking points yet</Trans>}
          Icon={ConversationIcon}
          iconSize={32}
        />
      </>
    );
  }

  // order talking points
  const talkingPointsLocal = [];
  TPOrder.forEach((id) => {
    const TP = talkingPoints.find((TP) => TP.id === id);
    if (TP) {
      talkingPointsLocal.push(talkingPoints.find((TP) => TP.id === id));
    }
  });
  talkingPoints.forEach((TP) => {
    if (!TPOrder.includes(TP.id)) {
      talkingPointsLocal.push(TP);
    }
  });

  const orderItems = () => {
    return talkingPointsLocal.map((point, index) => {
      return {
        id: String(index),
        item: point, // any name, we use it only in onDragAndDrop handler
        content: (
          <TalkingPoint
            index={index}
            key={point.id}
            point={point}
            isDone={doneTP.indexOf(point.id) !== -1}
            isCanDelete={!readOnly && point.createdBy === user.id && !point.original}
            isCanCheck={!readOnly}
            isCanComments={!readOnly}
            isPdfView={isPdfView}
            currentUserId={currentUserId}
            writingComment={writingComment}
            coachTeams={coachTeams}
            $conversation={$conversation}
            isTargetAvailable={
              _.isObject(point.target) && !_.isNull(point.target) && !_.isUndefined(point.target)
            }
          />
        ),
      };
    });
  };

  const onDragAndDrop = (items) => {
    const talkingPoints = items.map(({ item }) => item);
    const TPOrder = talkingPoints.map((TP) => TP.id);
    reorderTalkingPoints(TPOrder);
  };

  const isDisabled = talkingPointsLocal.length < 2 || readOnly;
  return (
    <PointsList>
      <DragDropList
        items={orderItems()}
        onChange={onDragAndDrop}
        isDragAreaEverywhere={true}
        isDragDisabled={isDisabled}
        hasCustomDragBehavior={true}
      />
      {!readOnly && (
        <WideButton
          label={`+ ${i18n._(t`Talking point`)}`}
          type={Button.types.primaryBorderWide}
          onClick={openTPModal}
        />
      )}
    </PointsList>
  );
};

export default memo(withI18n()(TalkingPointTab));
