import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
// import qs from 'qs';

import { confirm } from '~/components/ConfirmDialog';
import FirstLoginModal from '~/components/FirstLoginModal';
import ExternalLinkIcon from '~/components/Icons/ExternalLink';
import OverviewHeading from '~/components/OverviewHeading';
import ProgressBarWide from '~/components/ProgressBarWide';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import AvatarGroup from '~/components/UI/AvatarGroup';
import BaseLayout from '~/layouts/BaseLayout';

import QuickStartPart from './QuickStartPart';
import QuickStartStep from './QuickStartStep';

import Paul from '~/assets/paul-icon.png';

import { ROLES } from '~/constants';
import {
  CUSTOMER_STORIES_ARTICLE,
  HELPCENTER_ARTICLE,
  QUICK_START_PARTS,
} from '~/constants/quickStartSteps';
import {
  getQuickStartByCompany,
  updateQuickStart,
  resetQuickStartProgress,
} from '~/services/quickStarts';
import { COLORS } from '~/styles';
import getQuickStartPartProgress from '~/utils/getQuickStartPartProgress';
import getQuickStartTotalProgress from '~/utils/getQuickStartTotalProgress';
import getUserFullName from '~/utils/getUserFullName';

const Section = styled.div`
  margin-bottom: 13px;
  min-height: 58px;
`;

const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const IntroContainer = styled.div`
  display: flex;
`;

const IntroTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

const GreetingsText = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.63;
  color: ${COLORS.TEXT_BLACK};
`;

const IntroText = styled.div`
  width: 420px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.86;
  color: ${COLORS.TEXT_SECONDARY};
`;

const ProgressHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px;
`;

const Progress = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: var(--company-color);
  margin: 0 15px;
`;

const AdminProgressWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ProgressContainer = styled.div`
  display: flex;
  width: 125px;
  margin-left: 17px;
  align-items: center;
`;

const ResetButton = styled.button`
  display: flex;
  font-size: 12px;
  font-weight: 600;
  color: var(--company-color);
  align-items: center;
  cursor: pointer;
  align-self: flex-end;
`;

const Footer = styled.div`
  display: flex;
  margin-top: 22px;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 49px;
`;

const LinkTitleContainer = styled.div`
  display: flex;
`;

const LinkTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.44;
  color: ${COLORS.TEXT_BLACK};
`;

const LinkButton = styled.div`
  display: flex;
  color: var(--company-color);
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  align-items: center;
  margin-left: 12px;
`;

const LinkDescription = styled.div`
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.86;
  color: ${COLORS.TEXT_SECONDARY};
`;

class QuickStart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePart: QUICK_START_PARTS.BASICS.key,
      activeStep: {},
      loading: true,
      quickStart: {},
      showVideoModal: false,
      showFirstLoginModal: false,
      adminsUsers: [],
    };
  }

  componentDidMount = async () => {
    // Temporally keep video modal hidden
    /*
     const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
     const { isFirstLogin } = query;
     if (isFirstLogin) this.setState({ showFirstLoginModal: true });
    */

    const urlParams = new URLSearchParams(window.location.search);
    const part = urlParams.get('part');

    const { companyConnections = {} } = this.props;

    const quickStart = await getQuickStartByCompany();
    const adminsUsersIds = Object.values(companyConnections)
      .filter((conn) => conn.roles && conn.roles.includes(ROLES.ADMIN))
      .map((conn) => conn.user);

    const newState = { quickStart, loading: false, adminsUsersIds };
    if (part) {
      newState.activePart = part;
    }

    this.setState(newState);
  };

  changeActivePart = (newActivePart) => {
    const { activePart } = this.state;
    const nextPart = newActivePart === activePart ? '' : newActivePart;
    this.setState({ activePart: nextPart });
  };

  hideFirstLoginModal = () => {
    this.setState({ showFirstLoginModal: false });
  };

  onClickArticle = async (step) => {
    const { quickStart } = this.state;

    // don't update completed step
    if (quickStart[step.key]) {
      return;
    }

    await updateQuickStart(quickStart.id, { [step.key]: true });
    const updatedQuickStart = { ...quickStart, [step.key]: true };
    this.setState({ quickStart: updatedQuickStart });
  };

  onClickResetProgress = async () => {
    const { i18n } = this.props;
    const textConfirm = i18n._(
      t`Are you sure? This action cannot be undone and all quick start progress will be lost.`,
    );
    if (await confirm(i18n, textConfirm)) {
      const quickStart = await resetQuickStartProgress();
      this.setState({ quickStart });
    }
  };

  renderPart = (part) => {
    const { activePart, quickStart } = this.state;
    const partProgress = getQuickStartPartProgress(part, quickStart);

    return (
      <QuickStartPart
        key={part.key}
        isActiv={activePart === part.key}
        part={part}
        onChangePart={() => this.changeActivePart(part.key)}
        partProgress={partProgress || 0}
      >
        {part.steps.map(this.renderStep)}
      </QuickStartPart>
    );
  };

  renderStep = (step) => {
    const { quickStart } = this.state;
    const isDone = quickStart[step.key];

    return (
      <QuickStartStep
        key={step.key}
        step={step}
        onClickArticle={() => this.onClickArticle(step)}
        isDone={isDone}
      />
    );
  };

  render() {
    const { i18n, user } = this.props;
    const { showFirstLoginModal, loading, adminsUsersIds, quickStart } = this.state;

    const quickStartTotalProgress = getQuickStartTotalProgress(quickStart);

    return (
      <>
        <OverviewHeading
          title={i18n._(t`Quickstart`)}
          description={i18n._(t`Set-up Learned like a pro`)}
          smallWidth
        />

        <BaseLayout smallWidth>
          <Header>
            <IntroContainer>
              <img src={Paul} alt="Paul" width="56px" height="56px" />
              <IntroTextContainer>
                <GreetingsText>{`${i18n._(t`Hi`)}, ${getUserFullName(user)}`}</GreetingsText>
                <IntroText>
                  {i18n._(t`I’m Paul from Learned. I am here to help you set-up Learned and create a
                  continuous dialog for your company.`)}
                </IntroText>
              </IntroTextContainer>
            </IntroContainer>
            <ProgressHeader>
              <AdminProgressWrapper>
                <AvatarGroup users={adminsUsersIds} size={26} />
                <ProgressContainer>
                  <ProgressBarWide
                    height="10px"
                    value={quickStartTotalProgress}
                    isActive={true}
                    style={{ borderRadius: '6px' }}
                  />
                  <Progress>{quickStartTotalProgress}%</Progress>
                </ProgressContainer>
              </AdminProgressWrapper>
              <ResetButton onClick={this.onClickResetProgress}>
                {i18n._(t`Reset progress`)}
              </ResetButton>
            </ProgressHeader>
          </Header>

          <ShowSpinnerIfLoading loading={loading}>
            <SectionsWrapper>
              {Object.values(QUICK_START_PARTS).map((part) => (
                <Section key={`${part.key}`}>{this.renderPart(part)}</Section>
              ))}
            </SectionsWrapper>
          </ShowSpinnerIfLoading>

          <Footer>
            <LinkContainer>
              <LinkTitleContainer>
                <LinkTitle>{i18n._(t`Helpcenter`)}</LinkTitle>
                <LinkButton noBorder>
                  <a href={HELPCENTER_ARTICLE} target="_blank" rel="noopener noreferrer">
                    <ExternalLinkIcon size={16} />
                  </a>
                </LinkButton>
              </LinkTitleContainer>
              <LinkDescription>{i18n._(t`Check out all articles and videos`)}</LinkDescription>
            </LinkContainer>
            <LinkContainer>
              <LinkTitleContainer>
                <LinkTitle>{i18n._(t`Customer stories`)}</LinkTitle>
                <LinkButton noBorder>
                  <a href={CUSTOMER_STORIES_ARTICLE} target="_blank" rel="noopener noreferrer">
                    <ExternalLinkIcon size={16} />
                  </a>
                </LinkButton>
              </LinkTitleContainer>
              <LinkDescription>{i18n._(t`Learn from other companies`)}</LinkDescription>
            </LinkContainer>
          </Footer>
          {showFirstLoginModal && <FirstLoginModal onClose={this.hideFirstLoginModal} />}
        </BaseLayout>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyConnections: state.companyConnections,
    user: state.auth.user,
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(QuickStart)));
