import React from 'react';

import { StyledIcon } from '~/components/Dropdown/design';
import { ICONS } from '~/components/Icon';

function MultiSelectCheckBox({ selected }: { selected: boolean }) {
  return <StyledIcon icon={selected ? ICONS.CHECKBOX_CHECKED : ICONS.CHECKBOX} />;
}

export { MultiSelectCheckBox };
