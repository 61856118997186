import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { COLORS } from '~/styles';

enum JOB_STATUS_LABELS {
  ACTIVE = 'active',
  PREVIOUS = 'previous',
  AMBITION = 'ambition',
}

const JOB_STATUS_PROVIDER = {
  [JOB_STATUS_LABELS.PREVIOUS]: {
    color: COLORS.STATUS_DRAFT,
    backgroundColor: COLORS.STATUS_DRAFT,
    backgroundByPercentage: 30,
    text: (i18n: I18n) => i18n._(t`Previous`),
  },
  [JOB_STATUS_LABELS.AMBITION]: {
    color: COLORS.STATUS_PUBLISHED,
    backgroundColor: COLORS.STATUS_PUBLISHED,
    backgroundByPercentage: 8,
    text: (i18n: I18n) => i18n._(t`Ambition`),
  },

  [JOB_STATUS_LABELS.ACTIVE]: {
    color: COLORS.STATUS_ACTIVE,
    backgroundColor: COLORS.STATUS_ACTIVE,
    backgroundByPercentage: 14,
    text: (i18n: I18n) => i18n._(t`Active`),
  },
};

export { JOB_STATUS_PROVIDER, JOB_STATUS_LABELS };
