import React from 'react';

import { UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';

import { SectionParticipantsType } from '~/pages/SurveyUpdate/components/StepParticipants/SectionParticipantsType';
import { IGeneralForm } from '~/pages/SurveyUpdate/types';

import { useAutoSaveFunc } from '~/hooks/useAutoSave';

const Wrapper = styled.div`
  border: 1px solid #f3f7fd;
  border-radius: 10px;
`;

interface ISectionCompany {
  formMethods: UseFormReturn<IGeneralForm>;
  autosave: useAutoSaveFunc;
}
const SectionCompany = ({ formMethods, autosave }: ISectionCompany) => {
  return (
    <Wrapper>
      <SectionParticipantsType formMethods={formMethods} autosave={autosave} />
    </Wrapper>
  );
};

export { SectionCompany };
