import { useEffect, useMemo, useState } from 'react';

import {
  CAREER_PLAN_STATUSES,
  CONFIRMATION_MODAL_TYPE,
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
  REVIEW_QUESTION_TYPES_V1,
  REVIEW_STATUS,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import uniqBy from 'lodash/uniqBy';
import { type UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { useInactiveUsers } from '~/pages/Reviews/hooks/useInactiveUsers';

import { usePagination } from '~/hooks/usePagination';
import { getSettingsRole } from '~/selectors/baseGetters';
import { getCareerPlansAsAdminCoach } from '~/services/careerPlans';
import { isNotNil } from '~/utils/typePredicates';

import { sortEmployees } from '../utils';

import type { IEmployee, IReviewCycleForm, SORT_OPTIONS } from '../types';
import type { ICareerPlan, IJobProfile, ITeam } from '@learned/types';

const DEFAULT_PAGINATION = 50;

interface UseStepEmployees {
  formMethods: UseFormReturn<IReviewCycleForm>;
}

export const useStepEmployees = ({ formMethods }: UseStepEmployees) => {
  const { i18n } = useLingui();
  const { watch, setValue } = formMethods;
  const [primaryCareerPlans, setPrimaryCareerPlans] = useState<ICareerPlan[]>([]);
  const [careerPlans, setCareerPlans] = useState<ICareerPlan[]>([]);
  const [showStepEmployeesModal, setShowStepEmployeesModal] = useState(false);
  const [editEmployeeItem, setEditEmployeeItem] = useState<IEmployee>();
  const { pagination, changePagination } = usePagination(DEFAULT_PAGINATION);
  const [search, setSearch] = useState('');
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<IJobProfile[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<ITeam[]>([]);
  const [sortBy, setSortBy] = useState<SORT_OPTIONS>();
  const watchEmployees = watch('employees');
  const { removeInactiveUsers } = useInactiveUsers();
  const userRole = useSelector(getSettingsRole);

  useEffect(() => {
    const fetchCareerPlans = async () => {
      const res = await getCareerPlansAsAdminCoach(
        {
          status: CAREER_PLAN_STATUSES.CURRENT,
        },
        {},
        userRole,
      );

      setPrimaryCareerPlans(
        Object.values(res as Record<string, ICareerPlan>).filter((item) => !!item.primary),
      );
      setCareerPlans(Object.values(res || {}));
    };
    fetchCareerPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = useMemo(() => {
    let result: IEmployee[] = [];
    const regex = new RegExp(search.replaceAll('\\', ''), 'ig');
    if (search) {
      result = watchEmployees
        .map((item, index) => ({ ...item, index }))
        .filter(
          (item) =>
            item?.firstName?.match(regex) ||
            item?.lastName?.match(regex) ||
            item?.email?.match(regex),
        );
    } else {
      result = watchEmployees.map((item, index) => ({ ...item, index }));
    }

    if (!_.isEmpty(selectedRoles)) {
      result = result.filter((employee) =>
        _.some(
          selectedRoles.map((item) => item.id),
          (roleId) => employee.jobProfiles.map((item) => item.id).includes(roleId),
        ),
      );
    }

    if (!_.isEmpty(selectedTeams)) {
      result = result.filter((employee) =>
        _.some(
          selectedTeams.map((item) => item.id),
          (teamId) => employee.teams.map((item) => item.id).includes(teamId),
        ),
      );
    }

    result = sortEmployees(result, sortBy);

    const total = _.size(result);

    const items = _.chain(result)
      .drop(pagination.skip || 0)
      .take(pagination.limit || DEFAULT_PAGINATION)
      .value();

    return { items, total };
  }, [
    pagination.limit,
    pagination.skip,
    search,
    selectedRoles,
    selectedTeams,
    sortBy,
    watchEmployees,
  ]);

  const resetFilters = () => {
    setSearch('');
    setSelectedTeams([]);
    setSelectedRoles([]);
  };

  const actionButton = {
    label: i18n._(t`Add`),
    onClick: () => setShowStepEmployeesModal(true),
    variant: ButtonVariant.PRIMARY,
  };

  const isAllChecked = watchEmployees?.every((item) => {
    return selectedEmployees.includes(item.id);
  });

  const onCheckAll = () => {
    const itemsToSelect = isAllChecked ? [] : watchEmployees.map(({ id }) => id);
    setSelectedEmployees(itemsToSelect);
  };

  const onSelectItem = (selectedItemId: string) => {
    const isSelected = selectedEmployees.includes(selectedItemId);
    setSelectedEmployees(
      isSelected
        ? selectedEmployees.filter((id) => id !== selectedItemId)
        : [...selectedEmployees, selectedItemId],
    );
  };

  const onDelete = async (id?: string) => {
    const isMultiSelected = selectedEmployees.length > 1;
    const isPublished = watch('status') !== REVIEW_STATUS.DRAFT;
    let isConfirmed;

    if (isPublished) {
      isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: isMultiSelected ? i18n._(t`Delete employees?`) : i18n._(t`Delete employee?`),
        description: isMultiSelected
          ? i18n._(
              t`Are you sure you want to remove these employees? The reviews including provided input will be lost. This cannot be undone. `,
            )
          : i18n._(
              t`Are you sure you want to delete this employee from the review cycle? The review, including provided input, will be lost. This cannot be undone.`,
            ),
      });

      if (!isConfirmed) {
        return;
      }
    }

    setValue(
      'employees',
      watchEmployees.filter(
        (item) => ![...selectedEmployees, id].filter(isNotNil).includes(item.id),
      ),
      { shouldDirty: true },
    );

    setValue('isDirtyEmployees', true, { shouldDirty: true });

    setSelectedEmployees([]);
  };

  const rowButtons = (item: IEmployee) => {
    return [
      {
        action: () => onDelete(item.id),
        icon: ICONS.DELETE_BIN,
      },
    ];
  };

  const options = useMemo(() => {
    return {
      showCoaches: !!watch('evaluators')
        ?.map((item) => item.value)
        ?.includes(REVIEW_QUESTION_EVALUATORS.COACH),
      showJobs: !!watch('reviewQuestionTypes')?.some((type) =>
        [REVIEW_QUESTION_TYPES.SKILL_CATEGORY, REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1].includes(
          type,
        ),
      ),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify([watch('evaluators'), watch('reviewQuestionTypes')])]);

  const addEmployees = async (employees: IEmployee[]) => {
    setValue(
      'employees',
      uniqBy(
        [
          ...watchEmployees,
          ...employees.map((employee) => {
            const careerPlan = primaryCareerPlans.find((item) => item.createdFor === employee.id);
            const plansForUser = careerPlans.filter((item) => item.createdFor === employee.id);
            const jobProfile = employee.jobProfiles.find(
              (item) => item.id === careerPlan?.jobProfile,
            );

            return {
              ...employee,
              coaches: removeInactiveUsers(employee.coaches),
              guests: options.showCoaches ? removeInactiveUsers(employee.coaches) : [],
              availableCoaches: removeInactiveUsers(employee.coaches) || [],
              availableJobProfiles: employee.jobProfiles || [],
              jobProfiles: jobProfile ? [jobProfile] : [],
              careerPlans: plansForUser || [],
            };
          }),
        ],
        'id',
      ),
      { shouldDirty: true },
    );
  };

  const editEmployee = (employee: IEmployee) => {
    setValue(
      'employees',
      watchEmployees.map((item) => {
        if (item.id !== employee.id) {
          return item;
        } else {
          return employee;
        }
      }),
      { shouldDirty: true },
    );
  };

  const filterCount = useMemo(() => {
    let count = 0;

    if (selectedTeams.length) {
      count += 1;
    }
    if (selectedRoles.length) {
      count += 1;
    }

    return count;
  }, [selectedRoles.length, selectedTeams.length]);

  return {
    search,
    setSearch,
    sortBy,
    setSortBy,
    setShowStepEmployeesModal,
    showStepEmployeesModal,
    editEmployeeItem,
    setEditEmployeeItem,
    data,
    resetFilters,
    actionButton,
    onCheckAll,
    isAllChecked,
    onSelectItem,
    onDelete,
    rowButtons,
    addEmployees,
    editEmployee,
    filterCount,
    selectedEmployees,
    setSelectedRoles,
    selectedRoles,
    setSelectedTeams,
    selectedTeams,
    pagination,
    changePagination,
    options,
  };
};
