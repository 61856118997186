import styled from 'styled-components';

import { Button } from '~/components/Buttons';

import { COLORS } from '~/styles';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const NotificationWrapper = styled.div`
  margin-top: 32px;
  width: 1000px;
  max-height: 80vh;
  overflow: auto;
  background: ${COLORS.WHITE};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  & > div {
    padding-bottom: 32px;
    padding-top: 10px;
  }
`;

export const Label = styled.span`
  padding-left: 32px;
`;

export const DeleteButton = styled(Button)`
  height: 12px;
  width: 12px;
`;

export const PlaceholderWrapper = styled.div`
  display: flex;
  height: 300px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: ${COLORS.WHITE};
`;
