import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import { COLORS } from '~/styles';

import type { IMultiLangString } from '@learned/types';

interface IProps {
  option: {
    label: IMultiLangString;
  };
  isExpected?: boolean;
  isSelected?: boolean;
  onChange?: () => void;
  onReset?: () => void;
  onHover?: () => void;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const ExpectedLabelContainer = styled.div`
  position: absolute;
  top: -40%;
  height: 25px;
  border-radius: 6px;
  width: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & span {
    display: inline-block;
    text-align: center;
    padding-top: 10px;
    height: 25px;
    width: 130px;
    background-color: ${COLORS.HOVER};
    color: ${COLORS.LABEL_GRAY};
    font-size: 12px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  height: fit-content;
  top: 25px;
`;

const DownwardArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 11px solid ${COLORS.HOVER};
`;

const ExpectedLabel = styled.div`
  width: 100%;
  display: flex;
  border-radius: 6px;
  background-color: ${COLORS.HOVER};
  justify-content: center;

  & > span {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: ${COLORS.SUBTEXT};
  }
`;

const LabelContainer = styled.div`
  width: 100%;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 6px;

  & .selected {
    background: color-mix(in srgb, ${COLORS.COMPANY}, transparent 90%);
    color: ${COLORS.COMPANY};
    font-weight: 600;
  }

  & .expected {
    background: color-mix(in srgb, ${COLORS.COMPANY_HOVER}, transparent 95%);
    color: ${COLORS.COMPANY};
  }
`;

const LabelWrapper = styled.div<{ $isSelected: boolean }>`
  min-width: 130px;
  height: 50px;
  background-color: ${COLORS.BG_LIST};
  flex-base: 130px;
  flex-grow: 1;
  text-align: center;
  align-content: center;
  padding: 0 20px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: ${COLORS.BG_LIST};
  color: ${COLORS.ACTIVITY_GREY};

  &:hover {
    background: color-mix(in srgb, ${COLORS.COMPANY_HOVER}, transparent 95%);
    font-size: 14px;
    line-height: 1.36;
    text-align: center;
    color: ${COLORS.COMPANY};
  }
`;

const Label = styled.span`
  width: 100%;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-wrap: anywhere;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 2.5em;
  line-height: 1.25em;
`;

const SkillQuestionRatingLabel = ({
  onHover,
  option,
  isSelected,
  isExpected,
  onChange,
  onReset,
  useMultiLangString,
}: IProps) => {
  const getMultiLangString = useMultiLangString();

  return (
    <Container>
      {isExpected && (
        <ExpectedLabelContainer>
          <ExpectedLabel>
            <span>
              <Trans>Expected level</Trans>
            </span>
          </ExpectedLabel>
          <DownwardArrow />
        </ExpectedLabelContainer>
      )}
      <Tooltip size={TOOLTIP_SIZES.DEFAULT} tooltip={getMultiLangString(option.label || {})}>
        <Wrapper>
          <LabelContainer>
            <LabelWrapper
              $isSelected={!!isSelected}
              onMouseOver={() => onHover?.()}
              onMouseOut={() => onReset?.()}
              onClick={() => onChange?.()}
              className={isSelected ? 'selected' : isExpected ? 'expected' : ''}
            >
              <Label>{getMultiLangString(option.label || {})}</Label>
            </LabelWrapper>
          </LabelContainer>
        </Wrapper>
      </Tooltip>
    </Container>
  );
};

export { SkillQuestionRatingLabel };
