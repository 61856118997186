import styled, { css } from 'styled-components';

import { COLORS, COLOR_PALETTE } from '~/styles';

export default styled.input.attrs({
  autocomplete: 'off',
})`
  width: 100%;
  border: 1px solid ${(props) => (props.error ? COLORS.ERROR : COLORS.BORDER_LIGHT)};
  border-radius: 6px;
  background-color: ${COLOR_PALETTE.WHITE};
  font-size: ${(props) => props.textSize || '16px'};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  color: ${COLORS.TEXT_BLACK};
  padding: 12px 9px;
  height: 48px;
  ${(props) =>
    props.$accent &&
    css`
      font-size: 16px;
      font-weight: normal;
      padding: 11px 9px;
    `};
  box-sizing: border-box;

  &::placeholder {
    color: ${(props) => props.placeholderColor || COLORS.PLACEHOLDERS};
  }

  &:focus {
    border: 1px solid ${(props) => (props.error ? COLORS.ERROR : 'var(--company-color)')};
  }

  &:not(:disabled):hover {
    background-color: #f5f7fd;
  }

  &:disabled {
    border: 1px solid #dcdcdc;
    color: #dcdcdc;
    cursor: not-allowed;
  }
`;
