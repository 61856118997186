import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import AdminMembersPage from '~/pages/AdminMembersPage';
import EmployeeReportPage from '~/pages/AdminReports/EmployeeReportPage';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('members', {
  routesPrivate: {
    user: [],
    coach: [],
    admin: [
      <PrivateRoute
        key={routes.MEMBERS.routePath(ROLES.ADMIN)}
        exact
        path={routes.MEMBERS.routePath(ROLES.ADMIN)}
        component={AdminMembersPage}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.MEMBERS_REPORT.routePath(ROLES.ADMIN)}
        exact
        path={routes.MEMBERS_REPORT.routePath(ROLES.ADMIN)}
        component={EmployeeReportPage}
        allowedRoles={[ROLES.ADMIN]}
      />,
    ],
  },
  routesPublic: [],
});
