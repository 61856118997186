import React, { ReactNode } from 'react';

import { ROLES, USER_REVIEW_SORT_OPTIONS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { AutocompleteFilterMembers } from '~/components/AutoCompleteDropdownFilters';
import { Dropdown } from '~/components/Dropdown';
import { CalendarDropdown, DateOption } from '~/pages/Conversations/components/CalendarDropdown';

import {
  IMultiSelectOption,
  USER_REVIEW_CREATED_BY_OPTIONS,
  USER_REVIEW_STATUS_OPTIONS,
} from '~/constants/userReviews';

export interface IType {
  id: string;
  key: string;
  name: string;
  translated: (i18n: any) => ReactNode;
}

export interface IPagination {
  skip: number;
  limit: number;
  index: number;
}

export interface IFilters {
  createdBy?: IMultiSelectOption[];
  isShowFilters?: boolean;
  search?: string;
  selectedDateOption?: DateOption;
  statuses?: IMultiSelectOption[];
  selectedCoaches?: IType[];
  pagination?: IPagination;
  sortBy: USER_REVIEW_SORT_OPTIONS;
}

type FilterProps = {
  filters: IFilters;
  selectedRole: ROLES;
  onChangeFilter?(key: string, value: IType[]): void;
};

const Filters = ({ filters, selectedRole, onChangeFilter }: FilterProps) => {
  const { i18n } = useLingui();
  return (
    <>
      {selectedRole === ROLES.USER && (
        <AutocompleteFilterMembers
          placeholder={i18n._(t`Employees`)}
          selectedItemsIds={filters.selectedCoaches?.map(({ id }: IType) => id)}
          onChange={(employees) =>
            onChangeFilter?.(
              'selectedCoaches',
              employees.map(
                ({ id }) =>
                  ({
                    id,
                  } as IType),
              ),
            )
          }
        />
      )}

      <Dropdown
        placeholder={i18n._(t`Status`)}
        selectedItems={filters.statuses || []}
        items={USER_REVIEW_STATUS_OPTIONS}
        onChange={(selectedItems?: IMultiSelectOption[]) => {
          onChangeFilter?.('statuses', selectedItems || []);
        }}
        stringifyItem={(item) => item.translated(i18n)}
        isSingleSelect={false}
        skipSort={true}
      />

      <CalendarDropdown
        disabled={false}
        selectedItem={filters.selectedDateOption}
        // @ts-ignore
        setSelectedItem={(value: IType[]) => onChangeFilter?.('selectedDateOption', value)}
        filterOptions={[2, 1, 5, 6, 9]}
      />

      <Dropdown
        placeholder={i18n._(t`Created by`)}
        selectedItems={filters.createdBy || []}
        items={USER_REVIEW_CREATED_BY_OPTIONS}
        onChange={(selectedItems?: IMultiSelectOption[]) => {
          onChangeFilter?.('createdBy', selectedItems || []);
        }}
        stringifyItem={(item) => item.translated(i18n)}
        isSingleSelect={false}
        skipSort={true}
      />
    </>
  );
};

export { Filters };
