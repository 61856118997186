import styled, { css } from 'styled-components';

import DropdownButton from '~/components/DropdownButton';

import { COLORS } from '~/styles';

export const Frame = styled.div`
  width: 100%;
  padding-bottom: 12px;
  border-radius: 6px;
  background-color: ${COLORS.WHITE};
`;

export const CaretButton = styled.div`
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${COLORS.DARK_GRAY};

  &:hover {
    color: ${COLORS.COMPANY};
  }
`;

export const CaretButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: auto;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 16px;
  font-weight: 500;

  flex-direction: row;
  border-bottom: solid 1px ${COLORS.BORDERS};
  padding-bottom: 13px;
`;

export const MonthInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100px;
  justify-content: space-between;
  gap: 9px;
  padding: 6px 10px;
`;

export const MonthSelector = styled.div`
  display: flex;
  position: relative;
  color: ${COLORS.TEXT_HOVER};
  text-align: center;
  border-radius: 6px;
  cursor: pointer;

  ${CaretButtonContainer} {
    visibility: hidden;
  }

  :hover {
    ${CaretButtonContainer} {
      visibility: visible;
    }
  }

  &:hover {
    background-color: ${COLORS.HOVER};
  }
`;

export const AbsoluteCarets = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: end;
  align-items: center;
  pointer-events: none;

  ${CaretButtonContainer} {
    margin-right: 6px;
  }
`;

export const StyledDropdownButton = styled(DropdownButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 32px;

  font-size: 14px;
  font-weight: 500;
  line-height: 1.38;
`;

export const MonthHeader = styled.div`
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Items = styled.div`
  max-height: 240px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDERS};
`;

export const Item = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  font-size: 12px;
  height: 40px;
  padding: 0 10px 0 14px;
  gap: 8px;
  cursor: pointer;
  color: ${COLORS.BLACK};
  ${(props) =>
    props.selected &&
    css`
      background-color: ${COLORS.COMPANY};
      color: ${COLORS.WHITE};
    `}
  &:hover {
    background-color: ${COLORS.HOVER};
    color: ${COLORS.COMPANY};
  }
`;

export const YearHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 62px;
  height: 32px;

  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.38;
  color: ${COLORS.TEXT_HOVER};

  border: unset;
  border-radius: 6px;
  padding-left: 6px;
  padding-right: 6px;

  &:hover {
    background-color: ${COLORS.HOVER};
  }

  &:focus-within {
    height: 30px;
    width: 60px;
    border: 1px solid ${COLORS.BORDERS};
    background-color: ${COLORS.WHITE};
  }

  ${CaretButtonContainer} {
    visibility: hidden;
  }

  :hover {
    ${CaretButtonContainer} {
      visibility: visible;
    }
  }
`;

export const YearInput = styled.input`
  width: 38px;
  height: 32px;
  border-radius: 6px;
  border: unset;
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  line-height: 1.38;
  color: ${COLORS.BLACK};
  text-align: center;
  padding-left: 6px;
  padding-right: 6px;
`;

export const SelectMonthHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SelectMonthButton = styled.div`
  margin-left: 16px;
  cursor: pointer;
  color: ${COLORS.DARK_GRAY};
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:first-of-type {
    margin-left: 0;
  }
  &:hover {
    background-color: ${COLORS.HOVER};
    color: ${COLORS.COMPANY};
  }
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  color: black;
`;

export const DayHeader = styled.div`
  font-size: 14px;
  color: ${COLORS.SUBTEXT};
  line-height: 1.67;
`;

export const Day = styled.div<{ $currentMonth?: boolean }>`
  position: relative;
  width: ${100 / 7}%;
  height: 34px;
  display: flex;
  cursor: pointer;
  font-size: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => !props.$currentMonth && COLORS.PLACEHOLDERS};
`;

export const DayInCalendar = styled.div<{
  $hasBorder: string | boolean;
  $isInRange: boolean;
  $isHovered: boolean;
  $isMarked: boolean;
  $isToday: boolean;
  $isActive: boolean;
}>`
  width: ${(props) =>
    props.$isInRange && !props.$hasBorder && !props.$isHovered ? '100%' : '31px'};
  z-index: 5;
  height: 90%;
  border-radius: ${(props) =>
    props.$isInRange && props.$hasBorder !== 'both' && !props.$isHovered ? '' : '50%'};
  ${(props) => {
    return (
      props.$hasBorder &&
      props.$hasBorder !== 'both' &&
      `border-top-${props.$hasBorder}-radius: 6px; border-bottom-${props.$hasBorder}-radius: 6px;`
    );
  }}
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${(props) => props.$isMarked && (props.$isActive ? COLORS.COMPANY : COLORS.WHITE)};
  background-color: ${(props) => {
    if (props.$isActive) {
      return COLORS.WHITE;
    }
    if (props.$isMarked) {
      return COLORS.COMPANY;
    }
    if (props.$isHovered) {
      return COLORS.INFO_LIGHT;
    }
    if (props.$isInRange) {
      return COLORS.INFO_LIGHT;
    }
    return COLORS.WHITE;
  }};
  ${(props) =>
    props.$isToday &&
    css`
      text-decoration: underline;
      font-weight: 600;
      text-underline-offset: 4px;
    `}
  ${(props) =>
    props.$isActive &&
    css`
      border: 1px solid ${COLORS.COMPANY};
    `}
  ${(props) => (props.$isMarked || props.$isActive) && css`border-radius: 50%;!important`}
`;

export const ClearButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
`;

export const ClearButton = styled.div<{ $isActive: boolean }>`
  margin: 4px 12px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  cursor: ${(props) => props.$isActive && 'pointer'};
  color: ${(props) => (props.$isActive ? COLORS.COMPANY : COLORS.BG_PAGE)};
`;

export const Mask = styled.div<{ $isHovered: boolean; $hasBorder: boolean }>`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 90%;
  background: ${(props) => {
    return props.$isHovered && !props.$hasBorder
      ? COLORS.BG_PAGE
      : `linear-gradient(
    to ${props.$hasBorder},
    ${COLORS.BG_PAGE} 50%,
    white 0%
  );`;
  }};
`;

export const Mark = styled.div<{ $hasOneOnOne: boolean; $isMarked: boolean }>`
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: ${(props) =>
    props.$hasOneOnOne && (props.$isMarked ? COLORS.WHITE : COLORS.COMPANY)};
`;
