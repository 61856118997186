import React, { ChangeEvent, useEffect, useState } from 'react';

import {
  ACTIVITY_PROGRESS_TYPES,
  ACTIVITY_ROOT,
  ACTIVITY_TYPES,
  CURRENCY,
  SYMBOLS,
} from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Editor from '~/components/Editor';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import { Input } from '~/components/Input';

import { Attachments } from './Attachments';

import {
  ACTIVITY_MEASUREMENT_TYPES,
  CURRENCY_TYPES,
  METRIC_MEASUREMENT_TYPES_ACTIVITY,
} from '~/constants/goals';
import useBoolState from '~/hooks/useBoolState';
import { uploadSingle } from '~/services/files';
import { removeUserActivityAttachment } from '~/services/userActivities';
import { formatNumber } from '~/utils/formatNumber';
import { getGoalProgressWithDecimalsForInputs } from '~/utils/getGoalProgressWithDecimals';

import {
  ActivityModalFooter,
  ActivityModalTitle,
  ActivityNameInput,
  AdvanceSettingsContent,
  AdvanceSettingsRow,
  AdvanceSettingsTitle,
  ArrowSingle,
  AttachmentsSection,
  ContentWrapper,
  DescriptionTitleRow,
  DescriptionWrap,
  HeaderWrap,
  ItemContainer,
  ItemLabel,
  ItemRow,
  LightItemLabel,
  MeasurementSection,
  OptionalLabel,
  SectionWrapper,
  StyledDialogBox,
  StyledDivider,
  StyledDropdownLong,
  StyledDropdownShort,
} from '../stepsDesign';
import { ActivityError } from '../types';

import type { Activity } from './types';
import type { Attachment } from '@learned/types';
import type { I18n } from '@lingui/core';

type MeasurementItem = { key: ACTIVITY_PROGRESS_TYPES; value: (it18n: I18n) => string };
type CurrencyItem = { key: CURRENCY; value: SYMBOLS };
type Props = {
  onClose: () => void;
  addCustomActivity: (customActivity: Activity) => void;
  activityToEdit?: Activity;
};

const metricMeasurementTypes: Array<MeasurementItem> = Object.values(
  METRIC_MEASUREMENT_TYPES_ACTIVITY,
);
const wayOfMeasurementTypes: Array<MeasurementItem> = Object.values(ACTIVITY_MEASUREMENT_TYPES);
const currencies: Array<CurrencyItem> = Object.values(CURRENCY_TYPES);

const CustomActivityModal = ({ onClose, addCustomActivity, activityToEdit }: Props) => {
  const { i18n } = useLingui();
  const isEditActivity = !!activityToEdit;
  const [wayOfMeasurement, setWayOfMeasurement] = useState<MeasurementItem>(
    wayOfMeasurementTypes[0],
  );
  const [metricMeasurement, setMetricMeasurement] = useState<MeasurementItem>(
    metricMeasurementTypes[0],
  );
  const [isAdvanceSettingsEnabled, setIsAdvanceSettingsEnabled] = useState<boolean>(false);
  const [currencyType, setCurrencyType] = useState<CurrencyItem>(currencies[0]);

  const [activity, setActivity] = useState<Activity>({});
  const $fileUploading = useBoolState(false);

  const [activityName, setActivityName] = useState<string>('');
  const [activityDescription, setActivityDescription] = useState<string>('');
  const [minValue, setMinValue] = useState<number | undefined>(0);
  const [maxValue, setMaxValue] = useState<number | undefined>(100);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [errors, setErrors] = useState<any>({});

  const handleClose = () => {
    onClose();
    setActivityName('');
    setActivityDescription('');
    setMinValue(undefined);
    setMaxValue(undefined);
    setErrors({});
  };

  const downloadFile = (file: Attachment) => () => {
    window.open(file.link);
  };

  const updateActivity = (data = {}) => {
    setActivity({
      ...activity,
      ...data,
    });
  };

  const onUploadChange = async (file: File) => {
    if (file) {
      $fileUploading.on();

      const result = await uploadSingle(file, file.name);
      const link = result.url;
      const id = result.id;
      const name = result.name;
      const f = { link, id, name };
      updateActivity({
        files: [...(activity.files || []), f],
      });
      // @ts-ignore
      setAttachments([...(activity.files || []), f]);
      $fileUploading.off();
    }
  };

  const removeAttachment = async (fileId: string) => {
    const activityFiles = activity.files?.filter((file: Attachment) => file.id !== fileId);

    if (activity.id && fileId && activity.files && !isEmpty(activity.files)) {
      const isFileInActivity = activity.files.filter((file: Attachment) => file.id === fileId);
      if (isFileInActivity) {
        await removeUserActivityAttachment(activity.id, fileId);
      }
    }
    setAttachments(activityFiles || []);
    updateActivity({ files: activityFiles });
  };

  const validateActivity = (customActivity: Activity) => {
    let error: ActivityError = {};
    if (!customActivity.name) {
      error.name = i18n._(t`Activity name is required`);
    }

    if (
      [ACTIVITY_PROGRESS_TYPES.CURRENCY, ACTIVITY_PROGRESS_TYPES.NUMBERS].includes(
        customActivity.progressType!,
      )
    ) {
      if (
        customActivity.progressDetails?.min === undefined ||
        customActivity.progressDetails?.min < 0 ||
        customActivity.progressDetails?.min > (customActivity.progressDetails?.max || 0)
      ) {
        error = {
          ...error,
          progressDetails: { ...error.progressDetails, min: i18n._(t`Invalid min value`) },
        };
      }
      if (
        !customActivity.progressDetails?.max ||
        customActivity.progressDetails?.max < 0 ||
        customActivity.progressDetails?.max < (customActivity.progressDetails?.min || 0)
      ) {
        error = {
          ...error,
          progressDetails: { ...error.progressDetails, max: i18n._(t`Invalid max value`) },
        };
      }
    }

    if (ACTIVITY_PROGRESS_TYPES.PERCENTAGE === customActivity.progressType) {
      if (
        customActivity.progressDetails?.min === undefined ||
        customActivity.progressDetails?.min < 0 ||
        customActivity.progressDetails?.min > (customActivity.progressDetails?.max || 0)
      ) {
        error = {
          ...error,
          progressDetails: { ...error.progressDetails, min: i18n._(t`Invalid min value`) },
        };
      }
      if (
        !customActivity.progressDetails?.max ||
        customActivity.progressDetails?.max < 0 ||
        customActivity.progressDetails?.max < (customActivity.progressDetails?.min || 0)
      ) {
        error = {
          ...error,
          progressDetails: { ...error.progressDetails, max: i18n._(t`Invalid max value`) },
        };
      }
    }
    setErrors(error);
    return isEmpty(error);
  };

  const onAddActivity = () => {
    const customActivity: Activity = {
      ...activity,
      name: activityName,
      description: activityDescription,
      progressType:
        wayOfMeasurement.key === ACTIVITY_PROGRESS_TYPES.METRIC
          ? metricMeasurement.key
          : wayOfMeasurement.key,
      progressDetails:
        wayOfMeasurement.key === ACTIVITY_PROGRESS_TYPES.IS_DONE
          ? {}
          : {
              currency:
                metricMeasurement.key === ACTIVITY_PROGRESS_TYPES.CURRENCY
                  ? currencyType.key
                  : null,
              min: minValue,
              max: maxValue,
            },
      root: ACTIVITY_ROOT.CUSTOM,
      type: ACTIVITY_TYPES.OTHER,
    };
    if (!validateActivity(customActivity)) {
      return;
    }
    addCustomActivity(customActivity);
    handleClose();
  };

  useEffect(() => {
    if (activityToEdit) {
      setActivityName(activityToEdit.name || '');
      setActivityDescription(activityToEdit.description || '');
      setAttachments(activityToEdit.files || []);
      const isMetricType = metricMeasurementTypes.some(
        (item) => item.key === activityToEdit.progressType,
      );
      if (isMetricType) {
        setWayOfMeasurement(
          wayOfMeasurementTypes.find((item) => item.key === ACTIVITY_PROGRESS_TYPES.METRIC)!,
        );
        setMetricMeasurement(
          metricMeasurementTypes.find((item) => item.key === activityToEdit.progressType) ||
            metricMeasurementTypes[0],
        );
        if (activityToEdit.progressDetails) {
          setMinValue(activityToEdit.progressDetails.min || 0);
          setMaxValue(activityToEdit.progressDetails.max || 0);
          setCurrencyType(
            currencies.find(
              (currency) => currency.key === activityToEdit?.progressDetails?.currency,
            ) || currencies.find((currency) => currency.key === CURRENCY.EURO)!,
          );
        }
      } else {
        setWayOfMeasurement(
          wayOfMeasurementTypes.find((item) => item.key === activityToEdit.progressType) ||
            wayOfMeasurementTypes[0],
        );
      }
    }
  }, [activityToEdit]);

  return (
    <StyledDialogBox borderRadius={10} width={750}>
      <ContentWrapper>
        <HeaderWrap>
          <ActivityModalTitle>
            {isEditActivity ? (
              <Trans>Edit custom activity</Trans>
            ) : (
              <Trans>Create custom acitivity</Trans>
            )}
          </ActivityModalTitle>
          <Button
            icon={ICONS.CLOSE}
            variant={ButtonVariant.ICON}
            onClick={handleClose}
            size={ButtonSize.MEDIUM}
          />
        </HeaderWrap>
        <SectionWrapper>
          <ItemContainer isPadding>
            <ItemLabel>
              <Trans>Name of the activity</Trans>
            </ItemLabel>
            <ActivityNameInput
              placeholder={i18n._(t`Name of the activity`)}
              onChange={(e) => setActivityName(e.target.value)}
              value={activityName}
              error={errors && errors.name}
            />
          </ItemContainer>
          <StyledDivider isMargin />
          <AdvanceSettingsRow
            isCollapsed={isAdvanceSettingsEnabled}
            onClick={() => setIsAdvanceSettingsEnabled((prev) => !prev)}
            marginTop="24px"
            marginBottom="24px"
          >
            <AdvanceSettingsTitle>
              <Trans>Advanced settings</Trans>
            </AdvanceSettingsTitle>
            <ArrowSingle>
              <Icon icon={ICONS.BACK} size={ICON_SIZES.MEDIUM} />
            </ArrowSingle>
          </AdvanceSettingsRow>
          <StyledDivider />
          {isAdvanceSettingsEnabled && (
            <AdvanceSettingsContent>
              <DescriptionWrap>
                <DescriptionTitleRow>
                  <ItemLabel>
                    <Trans>Description</Trans>
                  </ItemLabel>
                  <OptionalLabel>
                    <Trans>(optional)</Trans>
                  </OptionalLabel>
                </DescriptionTitleRow>
                <Editor
                  placeholder={i18n._(
                    t`Example: Get in 10 new leads or have a meeting with my team every week`,
                  )}
                  onChange={(description: string) => setActivityDescription(description)}
                  value={activityDescription}
                  compact={true}
                />
              </DescriptionWrap>

              <MeasurementSection>
                <ItemRow>
                  <ItemContainer>
                    <ItemLabel>
                      <Trans>Way of measurement</Trans>
                    </ItemLabel>
                    <StyledDropdownLong
                      placeholder={i18n._(t`Way of measurement`)}
                      items={wayOfMeasurementTypes}
                      selectedItem={wayOfMeasurement}
                      onChange={(selectedItem) =>
                        setWayOfMeasurement(selectedItem as MeasurementItem)
                      }
                      stringifyItem={(item) => (item as MeasurementItem).value(i18n)}
                      isSingleSelect={true}
                    />
                  </ItemContainer>
                  {wayOfMeasurement.key === ACTIVITY_PROGRESS_TYPES.METRIC && (
                    <ItemContainer>
                      <LightItemLabel>
                        <Trans>Choose your metrics</Trans>
                      </LightItemLabel>
                      <StyledDropdownLong
                        placeholder={i18n._(t`Choose your metrics`)}
                        items={metricMeasurementTypes}
                        selectedItem={metricMeasurement}
                        onChange={(selectedItem) => {
                          setMetricMeasurement(selectedItem as MeasurementItem);
                          setMinValue((prev) =>
                            formatNumber(
                              getGoalProgressWithDecimalsForInputs(
                                String(prev),
                                (selectedItem as MeasurementItem).key,
                              ),
                            ),
                          );
                          setMaxValue((prev) =>
                            formatNumber(
                              getGoalProgressWithDecimalsForInputs(
                                String(prev),
                                (selectedItem as MeasurementItem).key,
                              ),
                            ),
                          );
                        }}
                        stringifyItem={(item) => (item as MeasurementItem).value(i18n)}
                        isSingleSelect={true}
                      />
                    </ItemContainer>
                  )}
                </ItemRow>

                <ItemRow>
                  {wayOfMeasurement.key === ACTIVITY_PROGRESS_TYPES.METRIC &&
                    metricMeasurement.key === METRIC_MEASUREMENT_TYPES_ACTIVITY.currency.key && (
                      <ItemContainer>
                        <LightItemLabel>
                          <Trans>Currency</Trans>
                        </LightItemLabel>
                        <StyledDropdownShort
                          placeholder={i18n._(t`Currency`)}
                          items={currencies}
                          selectedItem={currencyType}
                          onChange={(selectedItem) => setCurrencyType(selectedItem as CurrencyItem)}
                          stringifyItem={(item) => (item as CurrencyItem).value}
                          isSingleSelect={true}
                        />
                      </ItemContainer>
                    )}

                  {wayOfMeasurement.key === ACTIVITY_PROGRESS_TYPES.METRIC && (
                    <ItemContainer>
                      <LightItemLabel>
                        <Trans>Start</Trans>
                      </LightItemLabel>
                      <Input
                        type="number"
                        min={0}
                        max={
                          metricMeasurement.key === METRIC_MEASUREMENT_TYPES_ACTIVITY.percentage.key
                            ? 100
                            : ''
                        }
                        placeholder="0"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setMinValue(
                            formatNumber(
                              getGoalProgressWithDecimalsForInputs(
                                e.target.value,
                                metricMeasurement.key,
                              ),
                            ),
                          )
                        }
                        value={minValue}
                        error={errors && errors.progressDetails && errors.progressDetails.min}
                        width="169px"
                      />
                    </ItemContainer>
                  )}
                  {wayOfMeasurement.key === ACTIVITY_PROGRESS_TYPES.METRIC && (
                    <ItemContainer>
                      <LightItemLabel>
                        <Trans>Goal</Trans>
                      </LightItemLabel>
                      <Input
                        type="number"
                        min={0}
                        max={
                          metricMeasurement.key === METRIC_MEASUREMENT_TYPES_ACTIVITY.percentage.key
                            ? 100
                            : ''
                        }
                        placeholder="100"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setMaxValue(
                            formatNumber(
                              getGoalProgressWithDecimalsForInputs(
                                e.target.value,
                                metricMeasurement.key,
                              ),
                            ),
                          )
                        }
                        value={maxValue}
                        error={errors && errors.progressDetails && errors.progressDetails.max}
                        width="169px"
                      />
                    </ItemContainer>
                  )}
                </ItemRow>
              </MeasurementSection>
              <StyledDivider />
              <AttachmentsSection>
                <Attachments
                  files={attachments || []}
                  onDelete={removeAttachment}
                  onUpload={onUploadChange}
                  onDownload={downloadFile}
                  isFileUploading={$fileUploading.value}
                />
              </AttachmentsSection>
            </AdvanceSettingsContent>
          )}

          {isAdvanceSettingsEnabled && <StyledDivider />}

          <ActivityModalFooter>
            <Button
              label={i18n._(t`Cancel`)}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              iconSize={ICON_SIZES.SMALL}
              type="button"
              onClick={handleClose}
            />
            <Button
              label={isEditActivity ? i18n._(t`Save`) : i18n._(t`Add activity`)}
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              onClick={onAddActivity}
            />
          </ActivityModalFooter>
        </SectionWrapper>
      </ContentWrapper>
    </StyledDialogBox>
  );
};

export { CustomActivityModal };
