import styled from 'styled-components';

import { COLORS } from '~/styles';

export const AddSkillButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 18px;
  padding-bottom: 8px;

  & > img {
    margin-top: 24px;
  }

  & > button {
    background-color: ${COLORS.COMPANY};
    color: ${COLORS.WHITE};
    border-radius: 50px;
    padding: 12px 24px;
    font-size: 16px;
    letter-spacing: 0.5px;
  }

  & > span {
    margin-top: 5px;
    font-size: 12px;
    color: ${COLORS.ACCENT_ERROR};
  }
`;
