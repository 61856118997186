import React from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { GoalCycleTitle } from '~/components/GiveFeedback/components/Section/components/GoalCycleTitle';
import SkillName from '~/components/Skill/SkillName';
import SkillCategoriesLabels from '~/components/SkillCategoriesLabels';
import { Header3 } from '~/components/UI/Typographics/headers';

import { checkModuleIsShowRolesInReview } from '~/selectors/baseGetters';
import { COLOR_PALETTE, COLORS, COLOR_SET } from '~/styles';

const FlexColumn = styled.div`
  display: flex;
  align-items: center;
  word-wrap: break-word;

  h3 {
    ${(props) => !props.$isNotInlineHeader && 'display: inline-flex;'}
  }
`;

const NameWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const QuestionTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.63;
  color: ${COLORS.TEXT_BLACK};
  display: inline-flex;
`;

const Role = styled.span`
  min-height: 26px;
  height: 100%;
  background: ${COLOR_SET.TURQUOISE};
  padding: 2px 9px 2px 7px;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 8px;
  white-space: break-spaces;
  box-sizing: border-box;

  // text
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};

  &:last-child {
    margin-right: 0;
  }
`;

const SkillNameWrapper = styled.span`
  font-weight: 600;
  margin-left: 4px;
`;

const QuestionName = ({
  question,
  isReadOnly,
  skill,
  review,
  reviewSkillCategories,
  jobProfileName,
  pdfView,
}) => {
  const { i18n } = useLingui();
  const isShowRolesInReview = useSelector(checkModuleIsShowRolesInReview);

  switch (question.type) {
    case QUESTION_TYPES.CUSTOM:
    case QUESTION_TYPES.GOAL_BUSINESS_PLAN:
    case QUESTION_TYPES.GOAL_LEARNING_PLAN:
      return <Header3>{question.name}</Header3>;
    case QUESTION_TYPES.JOB_PROFILE:
      return (
        <NameWrap>
          <FlexColumn $isNotInlineHeader>
            <Header3>
              <span>
                {isReadOnly
                  ? i18n._(
                      t`How did this person perform at the relevant skills & competenties for the role`,
                    )
                  : i18n._(
                      t`How did you perform at the relevant skills & competenties for the role`,
                    )}
              </span>
              {jobProfileName && isShowRolesInReview && <Role>{jobProfileName}</Role>}
            </Header3>
          </FlexColumn>
        </NameWrap>
      );
    case QUESTION_TYPES.SKILL:
      return (
        <NameWrap>
          <FlexColumn>
            <QuestionTitle>
              {isReadOnly
                ? i18n._(t`How did this person perform on the skill: `)
                : i18n._(t`How did you perform on the skill: `)}
              <SkillNameWrapper>
                <SkillName skill={skill} />
              </SkillNameWrapper>
              ?
              {!isEmpty(skill.categories) && !isEmpty(reviewSkillCategories) && (
                <SkillCategoriesLabels
                  skillCategories={skill.categories}
                  allCategories={reviewSkillCategories}
                />
              )}
            </QuestionTitle>
          </FlexColumn>
        </NameWrap>
      );
    case QUESTION_TYPES.GOAL_BUSINESS_EVAL:
    case QUESTION_TYPES.GOAL_LEARNING_EVAL: {
      const cycleName =
        question.type === QUESTION_TYPES.GOAL_LEARNING_EVAL
          ? review.goalCyclesLearningEval[0].name
          : review.goalCyclesBusinessEval[0].name;
      return (
        <NameWrap>
          <FlexColumn $isNormalWeight>
            <QuestionTitle>
              <GoalCycleTitle
                isSelf={!isReadOnly || pdfView}
                questionType={question.type}
                cycleName={cycleName}
              />
            </QuestionTitle>
          </FlexColumn>
        </NameWrap>
      );
    }
  }
};

export default QuestionName;
