import React from 'react';

import styled from 'styled-components';

import BoxWithBorder from '~/components/UI/BoxWithBorder';
import Divider from '~/components/UI/Divider';

interface SettingBlockProps {
  header: React.ReactNode;
  content: React.ReactNode;
}

type ContentBoxProps = {
  $noPadding?: string;
  $noBackGround?: boolean;
};

const Box = styled(BoxWithBorder)`
  margin-bottom: 32px;
  padding: 0 38px;
  box-sizing: border-box;
  border: 0;
  box-shadow: none;
`;

const BoxContent = styled.div<ContentBoxProps>`
  margin: 0 0 26px;
`;

const BoxHeader = styled.div`
  height: 26px;
  margin: 27px 0 11px;
`;

const ProfileSettingsBlock = React.forwardRef(({ header, content }: SettingBlockProps, ref) => {
  return (
    // @ts-ignore
    <Box ref={ref}>
      <BoxHeader>{header}</BoxHeader>
      <Divider />
      <BoxContent>{content}</BoxContent>
    </Box>
  );
});

export { ProfileSettingsBlock };
