import React, { useState } from 'react';

import { QUESTION_TYPES, RATING_STATUSES } from '@learned/constants';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import ReviewCustomQuestion from '~/components/GiveFeedback/components/Section/components/ReviewCustomQuestion';
import ReviewJobProfileSkillQuestion from '~/components/GiveFeedback/components/Section/components/ReviewJobProfileSkillQuestion';
import ReviewSkillQuestion from '~/components/GiveFeedback/components/Section/components/ReviewSkillQuestion';
import Modal from '~/components/Modal';

import { ROLES, RATING_TYPES } from '~/constants';
import { GIVE_FEEDBACK_LEGEND } from '~/constants/reviews';
import useBoolState from '~/hooks/useBoolState';
import getUserFromAllPossibleUsers from '~/selectors/getUserFromAllPossibleUsers';
import { createOrUpdateFeedbackRatings } from '~/services/ratings';

const Actions = styled.div`
  display: flex;
`;

const ActionWrapper = styled.div`
  margin-left: 12px;
`;

const UpdateRatingModal = ({ i18n, rating: ratingProps, question, skill, review, onClose }) => {
  const [rating, setRating] = useState(ratingProps);
  const $loading = useBoolState(false);
  const isCalibrate = rating.status === RATING_STATUSES.CALIBRATE;

  const forUser = useSelector((state) => getUserFromAllPossibleUsers(state, rating.createdFor));

  const onChange = (_questionId, data) => {
    setRating({ ...rating, ...data });
  };

  const onSubmit = async () => {
    if (!isEmpty(rating)) {
      $loading.on();
      try {
        const [updatedRating] = await createOrUpdateFeedbackRatings(
          {
            ratings: [rating],
            reviewId: review.id,
            share: !isCalibrate,
            isCalibrate,
            isSelfReview: rating.type === RATING_TYPES.SELF_RATING,
          },
          { forceRole: ROLES.USER },
        );

        if (!isEmpty(updatedRating)) {
          onClose(updatedRating);
        }
      } finally {
        $loading.off();
      }
    }
  };

  return (
    <Modal
      title={i18n._(t`Update question`)}
      onClose={onClose}
      width={1000}
      footerRight={
        <Actions>
          <Button
            label={i18n._(t`Cancel`)}
            type="primary-border"
            onClick={onClose}
            loading={$loading.value}
          />
          <ActionWrapper>
            <Button
              label={i18n._(t`Update`)}
              type="primary"
              onClick={onSubmit}
              loading={$loading.value}
            />
          </ActionWrapper>
        </Actions>
      }
    >
      {rating.skill ? (
        question.type === QUESTION_TYPES.JOB_PROFILE ? (
          <ReviewJobProfileSkillQuestion
            review={review}
            question={question}
            skill={skill}
            forUser={forUser}
            rating={rating}
            ratingType={rating.type}
            expectedLevel={rating.skillLevel}
            onChange={onChange}
            isOpenDefault={true}
            color={GIVE_FEEDBACK_LEGEND[rating?.type]?.color}
          />
        ) : (
          <ReviewSkillQuestion
            review={review}
            question={question}
            skill={skill}
            forUser={forUser}
            rating={rating}
            ratingType={rating.type}
            expectedLevel={rating.skillLevel}
            onChange={onChange}
            isOpenDefault={true}
          />
        )
      ) : (
        <ReviewCustomQuestion
          question={question}
          rating={rating}
          ratingType={rating.type}
          onChange={onChange}
          isOpenDefault={true}
        />
      )}
    </Modal>
  );
};

UpdateRatingModal.propTypes = {
  review: PropTypes.object,
  rating: PropTypes.object,
  question: PropTypes.object,
  skill: PropTypes.object,
  onClose: PropTypes.func,
};

export default React.memo(withI18n()(UpdateRatingModal));
