export enum DAY_OF_WEEK {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export const ISO_DAY_OF_WEEK = {
  [DAY_OF_WEEK.MONDAY]: 1,
  [DAY_OF_WEEK.TUESDAY]: 2,
  [DAY_OF_WEEK.WEDNESDAY]: 3,
  [DAY_OF_WEEK.THURSDAY]: 4,
  [DAY_OF_WEEK.FRIDAY]: 5,
  [DAY_OF_WEEK.SATURDAY]: 6,
  [DAY_OF_WEEK.SUNDAY]: 7,
};
