import styled, { css } from 'styled-components';

import TabHeader from '~/pages/PassportPage/tabs/components/TabHeader';

import { COLORS } from '~/styles';

import { Button } from '../Buttons';
import { HeaderTabs } from '../HeaderTabs';
import { Icon } from '../Icon';
import Label from '../Label';
import PaginationBar from '../PaginationBar';
import { Top } from '../TableList/components/Top';

// ----- tree item ----- //

export const TreeItemWrapper = styled.div`
  margin-left: 68px; // margin for level structure
`;

export const TreeItemRow = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const TreeItemToggleWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -39px;
`;

export const TreeItemActivities = styled.div`
  margin-left: 32px;
`;

// ----- goal item ----- //

export const AvatarGroupWrapper = styled.div``;

export const Content = styled.div`
  display: flex;
`;

export const Text = styled.div`
  color: ${COLORS.ACTIVITY_GREY};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

export const Status = styled.div`
  color: ${(props) => props.color};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

export const Column = styled.div`
  display: flex;
  align-items: center;
`;

export const Progress = styled(Text)`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.14px;
  color: ${COLORS.SUBTEXT};
`;

// --- row item --- //

export const RowItemWrapper = styled.div<{
  isDashboardItem?: boolean;
}>`
  box-sizing: border-box;
  border-radius: 6px;
  margin: 0 0 8px;
  width: 100%;
  display: flex;
  height: 60px;
  align-items: center;

  background-color: ${COLORS.WHITE};
  border: solid 1px ${COLORS.BORDERS};

  ${({ isDashboardItem }) =>
    isDashboardItem &&
    css`
      background-color: ${COLORS.BG_LIST};
      border: solid 1px ${COLORS.BG_LIST};
    `}

  &:hover {
    background-color: ${COLORS.HOVER};
  }

  .goal-row-context-menu {
    display: none;
  }

  &:hover {
    .goal-row-context-menu {
      display: block;
    }
  }
`;

export const InfoColumn = styled.div<{
  isDashboardItem?: boolean;
  isPublicPage?: boolean;
}>`
  display: flex;
  align-items: center;
  flex: auto;
  width: ${({ isDashboardItem, isPublicPage }) =>
    isDashboardItem ? '323px' : isPublicPage ? 'calc(100% - 250px)' : 'calc(100% - 490px)'};
  ${({ isDashboardItem }) =>
    isDashboardItem &&
    css`
      min-width: 323px;
    `}
`;

export const CollapseIconWrapper = styled.div<{
  rotate?: boolean;
}>`
  margin-left: 10px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transition: transform 0.3s ease-in-out;
    transform: ${({ rotate }) => (rotate ? 'rotate(0deg)' : 'rotate(-90deg)')};
    cursor: pointer;
    color: ${COLORS.ACTIVITY_GREY};
  }
`;

export const GoalName = styled.div<{
  isHidden?: boolean;
  level?: number;
  isDashboardItem?: boolean;
}>`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${COLORS.ICONS_PRIMARY};
  box-sizing: border-box;
  padding-right: 14px;
  margin-left: ${({ isDashboardItem }) => (isDashboardItem ? '20px' : '16px')};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
  ${({ isHidden }) =>
    isHidden &&
    css`
      width: 314px;
      height: 17px;
      background-color: ${COLORS.GOAL_HIDDEN};
      border-radius: 2px;
    `}
`;

export const GoalIconWrapper = styled.div`
  margin-left: 15px;
  display: flex;
  align-items: center;
`;

// ----- status label ----- //

export const StyledDiv = styled(Label)`
  display: inline-flex;
  align-items: center;
  height: 15px;
  font-size: 12px;
  margin-right: 10px;
  flex: none;
`;

export const StyledIcon = styled(Icon)<{
  rotate?: number;
  color: string;
}>`
  box-sizing: border-box;
  margin-right: 5px;
  transform: rotate(${({ rotate }) => (rotate ? rotate : '')}deg);
`;

// ----- Activity item ----- //
export const ActivityItemWrapper = styled.div`
  margin-bottom: 11px;
`;

export const ActivityText = styled.div`
  color: ${COLORS.ACTIVITY_GREY};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

export const ActivityRow = styled.div<{
  $isHover?: boolean;
}>`
  width: 100%;
  border-radius: 6px;
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.ACTIVITY_BORDER};
  min-height: 64px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  ${(props) => props.$isHover && 'cursor: pointer'};

  ${(props) =>
    props.$isHover &&
    `
    &:hover {
      box-shadow: 0 2px 6px 0 rgba(107, 120, 164, 0.3);
    }
  `};
`;

export const ActivityContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 58px;
`;

export const ActivityIconWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
`;

export const ActivityInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-left: 12px;
  overflow: hidden;
`;

export const ActivityName = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.17;
  letter-spacing: -0.13px;
  color: ${COLORS.ICONS_PRIMARY};
`;

export const ActivityTippyWrapper = styled.div`
  display: flex;
  & span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 314px;
  }
`;

export const ActivityColumn = styled.div`
  display: flex;
  align-items: center;
`;

export const ActivityStatus = styled.div<{
  color: string;
  isGoalTree?: boolean;
}>`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.color};
  ${(props) =>
    props.isGoalTree &&
    `
    min-width: 90px;
    margin-right: 26px;
  `};
`;

export const ActivityUpdateProgressButton = styled(Button)`
  height: 32px;
  padding: 0 8px;
  margin-right: 32px;
`;

export const ActivityProgress = styled(ActivityText)`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.14px;
  color: ${COLORS.ACTIVITY_GREY};
`;

export const StyledHeaderTabs = styled(HeaderTabs)``;

export const TabsWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  display: flex;
  width: fit-content;
  border-radius: 6px 6px 0 0;
`;

export const ListHeader = styled.div`
  th:nth-child(2) {
    margin-left: 0px;
    div {
      margin-left: 0px;
    }
  }
  th:nth-child(3) {
    div {
      margin-left: 0px;
    }
  }
  th:nth-child(4) {
    div:first-child {
      margin-left: 16px;
    }
  }
`;

export const GoalItemsWrapper = styled.div`
  width: 100%;
  margin-top: 4px;

  > div {
    margin-left: 0px;

    > div:first-child > div:first-child {
      background-color: ${COLORS.WHITE};
      border: solid 1px ${COLORS.BORDERS};

      &:hover {
        background-color: ${COLORS.HOVER};
      }
    }
  }

  > div:nth-child(even) {
    > div:first-child > div:first-child {
      background-color: ${COLORS.BG_LIST};
      border: 0;

      &:hover {
        background-color: ${COLORS.HOVER};
      }
    }
  }

  table {
    margin-bottom: 2px;
    padding: 0;
  }
`;

export const TabHeaderWrapper = styled(TabHeader)`
  margin: 16px 24px;
  div {
    margin-top: 10px;
  }
`;

export const Section = styled.div<{
  $isBorderBottom?: boolean;
}>`
  padding: 0 23px 0 0;
  border-bottom: ${({ $isBorderBottom }) =>
    $isBorderBottom ? `1px solid ${COLORS.BG_PAGE}` : 'none'};
`;

export const StyledPaginationBar = styled(PaginationBar)`
  border-bottom: 1px solid ${COLORS.BG_PAGE};
  padding: 0 32px 0px 30px;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 12px 0 4px;
`;

export const RowFilters = styled(Row)<{
  $noMarginTop?: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ $noMarginTop }) => ($noMarginTop ? '0' : '12px 0 4px')};
`;

export const SectionCtr = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const RowItem = styled.div`
  margin-bottom: 0px;
  width: 137px;
  & > div:first-child {
    width: 100%;
  }
`;

export const RightContainer = styled.div<{
  isPublicPage?: boolean;
}>`
  width: ${({ isPublicPage }) => (isPublicPage ? '210px' : '450px')};
  padding: 0 0 0 20px;
  box-sizing: border-box;
  margin-left: 40px;
`;

export const LeftActivityColumn = styled.div<{
  isPublicPage?: boolean;
}>`
  display: flex;
  align-items: center;
  width: ${({ isPublicPage }) => (isPublicPage ? 'calc(100% - 250px)' : 'calc(100% - 490px)')};
  padding: 0 20px;
  box-sizing: border-box;
`;

export const RightColumnCtr = styled.div<{
  isPublicPage?: boolean;
}>`
  display: flex;
  align-items: center;
  width: ${({ isPublicPage }) => (isPublicPage ? '200px' : '450px')};
  padding: 0 20px;
  box-sizing: border-box;
  margin-left: 40px;
`;

export const ColumnGrow = styled.div<{
  isDashboardItem?: boolean;
  isPublicPage?: boolean;
}>`
  flex: auto;
  margin-left: ${({ isDashboardItem, isPublicPage }) =>
    isDashboardItem ? '32px' : isPublicPage ? '40px' : '90px'};
  display: flex;
  align-items: center;
`;

export const AvatarCtr = styled.div<{
  isDashboardItem?: boolean;
}>`
  width: ${({ isDashboardItem }) => (isDashboardItem ? '110px' : '110px')};
  flex: none;
  display: flex;
`;

export const ColumnProgress = styled.div<{
  isDashboardItem?: boolean;
}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${({ isDashboardItem }) => (isDashboardItem ? '40px' : '30px')};
`;

export const ColumnProgressActivity = styled.div`
  display: flex;
  width: 30px;
  justify-content: flex-start;
  align-items: center;
`;

export const ColumnGrowActivity = styled.div<{
  isPublicPage?: boolean;
}>`
  flex: auto;
  margin-left: ${({ isPublicPage }) => (isPublicPage ? '40px' : '90px')};
  display: flex;
`;

export const GoalOverviewContainer = styled.div<{
  isPublicPage?: boolean;
}>`
  width: 1056px;
  > div:nth-child(2) {
    > div:first-child {
      > div:nth-child(1) {
        padding: 24px 32px 5px 32px;
      }
      > div:nth-child(2) {
        padding: 0px 32px 0 32px;
      }
      > div:nth-child(3) {
        padding: 0px 32px 0 32px;
        margin-top: 24px;
      }
    }
  }

  ${({ isPublicPage }) =>
    isPublicPage &&
    css`
      width: 700px;
      > div:nth-child(2) {
        > div:first-child {
          > div:nth-child(1) {
            padding: 24px 32px 5px 32px;
          }
          > div:nth-child(2) {
            padding: 0px 32px 0 32px;
          }
          > div:nth-child(3) {
            padding: 0px 32px 0 32px;
            margin-top: 24px;
          }
        }
      }
    `}
`;

export const HiddenIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTop = styled(Top)`
  padding-top: 24px;
`;
