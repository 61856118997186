import React, { Fragment } from 'react';

import styled from 'styled-components';

import { COLORS } from '~/styles';

const AvatarContainer = styled.div<{ placement: string }>`
  display: flex;
  align-items: center;
  gap: 4px;
  flex: none;
  justify-content: ${({ placement }) => placement};
`;

const UserImage = styled.div<{ itemNr: number; avatarUrl: string }>`
  box-sizing: border-box;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  position: relative;
  transform: translateX(-${({ itemNr }) => itemNr * 100}%);
  border: 2px solid ${COLORS.WHITE};
  background-color: ${COLORS.PLACEHOLDERS};
  flex: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ avatarUrl }) => `url("${avatarUrl}")`};
`;

const UserPlaceHolder = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: transparent;
  opacity: 0.5;
  box-sizing: border-box;
  flex: none;
`;

const AvatarCount = styled.div`
  color: ${COLORS.PLACEHOLDERS};
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  flex: none;
  max-width: 62px;
`;

type TProps = {
  avatarArr?: {
    avatarUrl: string;
  }[];
  placement?: string;
};

function AvatarComponent({ avatarArr = [], placement = 'flex-end' }: TProps): JSX.Element {
  const filteredEditors = [...avatarArr].slice(0, 3);
  const avatarCount = avatarArr.length - filteredEditors.length;
  return (
    <>
      {avatarArr.length > 0 ? (
        <AvatarContainer placement={placement}>
          <ImageContainer>
            {filteredEditors.map((avatar, index) => (
              <UserImage
                itemNr={index / filteredEditors.length || 0}
                avatarUrl={avatar.avatarUrl}
                key={`avatar-${index + 1}`}
              />
            ))}
          </ImageContainer>
          {avatarCount > 0 && <AvatarCount>+{avatarCount}</AvatarCount>}
        </AvatarContainer>
      ) : (
        <UserPlaceHolder />
      )}
    </>
  );
}

export default AvatarComponent;
