import React, { useEffect, useRef, useState } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

import { GraphDataType } from '../types';

import type { ChartData, ChartOptions } from 'chart.js';

const COLORS = [['rgba(161, 109, 251, 1)', 'rgba(104, 59, 246, 0)']];
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, LineController);

export enum GRAPHS_TYPES {
  engagement = 'engagement',
  time = 'time',
}

export interface IEngagementGraphProps {
  chartData: GraphDataType[];
  ratingLabelsCount?: number;
}

const createGradient = (
  canvas: HTMLCanvasElement,
  height: number,
  color1: string,
  color2: string,
) => {
  const ctx = canvas.getContext('2d');
  const gradient = ctx?.createLinearGradient(0, 0, 0, height);
  gradient?.addColorStop(0, color1);
  gradient?.addColorStop(1, color2);
  return gradient;
};

const getChartOptions = (ratingLabelsCount?: number): ChartOptions<'line'> => {
  return {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        beginAtZero: true,
        min: 0,
        max: ratingLabelsCount || 100,
      },
    },
  };
};

function DashboardLineGraph({ chartData, ratingLabelsCount }: IEngagementGraphProps) {
  const labels = chartData.map((d) => d.value);
  const dataSets = [
    {
      data: labels,
      borderWidth: 0,
      tension: 0.5,
      pointRadius: 0,
    },
  ];
  const chartRef = useRef<ChartJS<'line'>>(null);
  const [chartDataSet, setChartDataSet] = useState<ChartData<'line'>>({ labels: [], datasets: [] });

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const height = 150;
    const GRADIENTS = COLORS.map((color) =>
      createGradient(chart.canvas, height, color[0], color[1]),
    );

    const chartDataSet = {
      labels,
      datasets: dataSets.map((d, index) => ({
        ...d,
        fill: true,
        backgroundColor: GRADIENTS[index],
        borderColor: COLORS[index][0],
      })),
    };

    if (chartDataSet.datasets?.length && chartDataSet.labels?.length) {
      // @ts-ignore
      setChartDataSet(chartDataSet);
    }

    // eslint-disable-next-line
  }, [chartRef]);

  return (
    <Chart
      ref={chartRef}
      type="line"
      data={chartDataSet}
      options={getChartOptions(ratingLabelsCount)}
    />
  );
}

export default DashboardLineGraph;
