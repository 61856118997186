import { REPORT_CHART_TYPES } from '@learned/constants';

import { HeatmapResponse, TEngagementReportResponse } from '~/services/reports';

import type { I18n } from '@lingui/core';

export type SavedDataType = {
  chartData: TEngagementReportResponse | HeatmapResponse | null;
  viewType: REPORT_CHART_TYPES;
  reportType: string;
  reportId: string;
  id: string;
  total?: number;
};

export enum LOADER_STATE {
  DASHBOARD_LOADING = 'DASHBOARD_LOADING',
  DASHBOARD_LOADED = 'DASHBOARD_LOADED',
  TILE_LOADING = 'TILE_LOADING',
  TILE_LOADED = 'TILE_LOADED',
}

export enum PRIMARY_OPTIONS_KEYS {
  ORGANIZATION = 'ORGANIZATION',
  TEAM = 'TEAM',
}

export enum PAGE_TYPE {
  ADMIN = 'admin',
  COACH = 'coach',
}

export type OptionTypes = {
  key: string;
  title: (i18n: I18n) => string;
};

export type DashboardItemType = { reportType: string; reportId: string };
