import React from 'react';

import styled from 'styled-components';

import { SectionNameAndDescription } from './SectionNameAndDescription';
import { SectionParticipants } from './SectionParticipants';
import { SectionSettings } from './SectionSettings';
import { SectionTemplate } from './SectionTemplate';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { COLORS } from '~/styles';

import type { ISurvey, ISurveyTemplate } from '@learned/types';

export const Wrapper = styled.div`
  background-color: ${COLORS.WHITE};
  max-width: 750px;
  border-radius: 6px;
  padding: 21px 34px 1px;
  margin: 23px auto 0;
`;

interface ISetupProps {
  languageState: ILanguageStateReturn;
  survey: ISurvey;
  onTemplatePreview: (item: ISurveyTemplate) => void;
  onShare: (isShared: boolean) => void;
}

const SurveySetup = ({ survey, languageState, onTemplatePreview, onShare }: ISetupProps) => {
  return (
    <Wrapper>
      <SectionTemplate survey={survey} onTemplatePreview={onTemplatePreview} />
      <SectionNameAndDescription
        name={survey.name}
        description={survey.description}
        languageState={languageState}
      />
      <SectionSettings survey={survey} onShare={onShare} />
      <SectionParticipants survey={survey} />
    </Wrapper>
  );
};

export { SurveySetup };
