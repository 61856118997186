import React from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import Button from '~/components/Button';
import { TextField } from '~/components/Text';

import useBoolState from '~/hooks/useBoolState';

const SettingLabel = styled.div``;
const SettingBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin: 4px;
  margin-left: 8px;
  margin-top: 8px;
`;

const SettingTextField = styled(TextField)`
  width: 100%;
  height: 48px;
`;

function NmbrsConnectionInfo({
  i18n,
  testHook: [_, setTestState],
  settingsHook: [integrationSettings, setIntegrationSettings],
  updateMode,
}) {
  const $editingUsername = useBoolState();
  const $editingToken = useBoolState();
  const $editingDomain = useBoolState();

  return (
    <>
      <SettingBlock>
        <SettingLabel>
          <Trans>Username</Trans>
        </SettingLabel>
        {!updateMode || $editingUsername.value ? (
          <SettingTextField
            placeholder={i18n._(t`Enter your NMBRS username...`)}
            onChange={(e) => {
              setTestState({ tested: false, isValid: false });
              setIntegrationSettings({
                ...integrationSettings,
                connectionInfo: {
                  ...integrationSettings.connectionInfo,
                  username: e.target.value,
                },
              });
            }}
          />
        ) : (
          <Button onClick={$editingUsername.on} label={i18n._(t`Click to update`)} />
        )}
      </SettingBlock>
      <SettingBlock>
        <SettingLabel>
          <Trans>Token</Trans>
        </SettingLabel>
        {!updateMode || $editingToken.value ? (
          <SettingTextField
            placeholder={i18n._(t`Enter NMBRS API Token...`)}
            onChange={(e) => {
              setTestState({ tested: false, isValid: false });
              setIntegrationSettings({
                ...integrationSettings,
                connectionInfo: {
                  ...integrationSettings.connectionInfo,
                  token: e.target.value,
                },
              });
            }}
          />
        ) : (
          <Button onClick={$editingToken.on} label={i18n._(t`Click to update`)} />
        )}
      </SettingBlock>
      <SettingBlock>
        <SettingLabel>
          <Trans>Domain</Trans>
        </SettingLabel>
        {!updateMode || $editingDomain.value ? (
          <SettingTextField
            placeholder={i18n._(t`Enter your NMBRS domain...`)}
            onChange={(e) => {
              setTestState({ tested: false, isValid: false });
              setIntegrationSettings({
                ...integrationSettings,
                connectionInfo: {
                  ...integrationSettings.connectionInfo,
                  domain: e.target.value,
                },
              });
            }}
          />
        ) : (
          <Button onClick={$editingDomain.on} label={i18n._(t`Click to update`)} />
        )}
      </SettingBlock>
    </>
  );
}

export default withI18n()(NmbrsConnectionInfo);
