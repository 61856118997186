import React from 'react';

import { ACTIVITY_STATUSES, PATH_STATUSES } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { ICON_SIZES, Icon, ICONS } from '~/components/Icon';
import Label from '~/components/Label';

export const USER_LIBRARY_STATUSES = {
  [PATH_STATUSES.ON_TRACK]: {
    key: PATH_STATUSES.ON_TRACK,
    text: (i18n: I18n) => i18n._(t`On track`),
    icon: ICONS.DROPDOWN,
    color: '#5c76ff',
    backGroundColor: '#e8ecff',
  },
  [ACTIVITY_STATUSES.TODO]: {
    key: ACTIVITY_STATUSES.TODO,
    text: (i18n: I18n) => i18n._(t`To do`),
    icon: ICONS.DROPDOWN,
    color: '#5c76ff',
    backGroundColor: '#e8ecff',
  },
  [ACTIVITY_STATUSES.IN_PROGRESS]: {
    key: ACTIVITY_STATUSES.IN_PROGRESS,
    text: (i18n: I18n) => i18n._(t`In progress`),
    icon: ICONS.IN_PROGRESS,
    color: '#95a100',
    backGroundColor: '#f2f4d5',
  },
  [PATH_STATUSES.ACTIVE]: {
    key: PATH_STATUSES.ACTIVE,
    text: (i18n: I18n) => i18n._(t`Active`),
    icon: ICONS.IN_PROGRESS,
    color: '#95a100',
    backGroundColor: '#f2f4d5',
  },
  [PATH_STATUSES.COMPLETED]: {
    key: PATH_STATUSES.COMPLETED,
    text: (i18n: I18n) => i18n._(t`Completed`),
    icon: ICONS.CHECKMARK,
    color: '#29ccab',
    backGroundColor: '#cef1ea',
  },
  [PATH_STATUSES.PROBLEM]: {
    key: PATH_STATUSES.PROBLEM,
    text: (i18n: I18n) => i18n._(t`Problem`),
    icon: ICONS.WARNING_2,
    color: '#ff5252',
    backGroundColor: '#f6d0d0',
  },
  // not possible to have
  [PATH_STATUSES.DRAFT]: {
    key: PATH_STATUSES.DRAFT,
    text: (i18n: I18n) => i18n._(t`Draft`),
    icon: ICONS.ARCHIVE_2,
    color: '#c2c9dd',
    backGroundColor: '#eaecf2',
  },
};

const StyledDiv = styled(Label)`
  display: inline-flex;
  align-items: center;
  height: 15px;
  font-size: 12px;
  margin-right: 10px;
`;

const StyledIcon = styled(Icon)<{
  rotate?: number;
  color: string;
}>`
  box-sizing: border-box;
  margin-right: 5px;
  transform: rotate(${({ rotate }) => (rotate ? rotate : '')}deg);
`;

type LabelProps = {
  status: PATH_STATUSES | ACTIVITY_STATUSES;
};

const StatusLabel = ({ status }: LabelProps) => {
  const { i18n } = useLingui();
  const item = USER_LIBRARY_STATUSES[status];

  if (!item) {
    return null;
  }

  return (
    <StyledDiv color={item.color} backgroundColor={item.backGroundColor}>
      <StyledIcon
        icon={item.icon}
        size={ICON_SIZES.SMALL}
        rotate={item.key === PATH_STATUSES.ON_TRACK ? -90 : 0}
        color={item.color}
      />
      {item.text(i18n)}
    </StyledDiv>
  );
};

export { StatusLabel };
