import React from 'react';

import styled from 'styled-components';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { COLORS } from '~/styles';

import { ListRow, RowRightBlock, RowLeftBlock } from '../design';

const Text = styled.div`
  font-size: 12px;
  letter-spacing: -0.13px;
  color: ${COLORS.PLACEHOLDERS};
`;

interface ListRowPlaceholderProps {
  text: string;
  isWarning?: boolean;
}

const ListRowPlaceholder = ({ text, isWarning = true }: ListRowPlaceholderProps) => {
  return (
    <ListRow>
      <RowRightBlock>
        <Text>{text}</Text>
      </RowRightBlock>
      <RowLeftBlock>
        {isWarning && (
          <Icon icon={ICONS.WARNING_2} size={ICON_SIZES.SMALL} color={COLORS.TABLE_WARNING} />
        )}
      </RowLeftBlock>
    </ListRow>
  );
};

export { ListRowPlaceholder };
