import React, { useEffect } from 'react';

import { t } from '@lingui/macro';
import { Trans, useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { FieldErrors, useFieldArray, UseFormReturn } from 'react-hook-form';

import TrashIcon from '~/components/Icons/Trash';
import { Input } from '~/components/Input';

import {
  AddOption,
  FieldWrapper,
  IconWrapper,
  IndexWrapper,
  InputGroups,
  InputGroupWrapper,
  RatingHeader,
  RatingHeaderWrapper,
  RatingScaleItemWrapper,
  Row,
  Title,
  SubTitle,
  IndexWrapperSubstitute,
  TrashWrapperSubstitute,
} from './design';

import { IQuestion, IQuestionForm } from '~/@types/question';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useScrollEffect } from '~/hooks/useScrollEffect';

interface IRatingScaleItemProps {
  ratingFormMethods: UseFormReturn<IQuestionForm>;
  skillFormMethods: UseFormReturn<IQuestionForm>;
  languageState: ILanguageStateReturn;
  errorOnSave?: boolean;
  isAutoScroll?: boolean;
}

const RatingScaleItem = ({
  ratingFormMethods,
  skillFormMethods,
  languageState,
  errorOnSave = false,
  isAutoScroll = false,
}: IRatingScaleItemProps) => {
  const { i18n } = useLingui();
  const companyLanguages = languageState.companyLanguages;
  const { ref, scrollToRef } = useScrollEffect<HTMLDivElement>({ behavior: 'smooth' });

  const {
    control: ratingControl,
    register: ratingRegister,
    formState: { errors: ratingErrors },
  } = ratingFormMethods;
  const {
    control: skillControl,
    register: skillRegister,
    formState: { errors: skillErrors },
  } = skillFormMethods;

  const {
    fields: ratingFields,
    append: ratingAppend,
    remove: ratingRemove,
  } = useFieldArray({
    name: 'options',
    control: ratingControl,
  });
  const {
    fields: skillFields,
    append: skillAppend,
    remove: skillRemove,
  } = useFieldArray({
    name: 'options',
    control: skillControl,
  });

  const isFieldError = ({
    error,
    labelIndex,
    optionIndex,
  }: {
    error?: FieldErrors<IQuestion>;
    labelIndex: number;
    optionIndex: number;
  }): boolean => {
    if (!error) {
      return false;
    }

    const optionObj = error.options?.[optionIndex];
    const labelObj = optionObj?.label?.[labelIndex];

    return !isEmpty(labelObj);
  };

  useEffect(() => {
    if (isAutoScroll) {
      scrollToRef();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RatingScaleItemWrapper ref={ref}>
      <Title>{i18n._(t`Default rating scale`)}</Title>
      <SubTitle>{i18n._(t`This rating scale is used as default for all questions`)}</SubTitle>
      <RatingHeaderWrapper>
        <IndexWrapperSubstitute />
        <RatingHeader>{i18n._(t`Question related to skills`)}</RatingHeader>
        <RatingHeader>{i18n._(t`KPIs and other questions`)}</RatingHeader>
        <TrashWrapperSubstitute />
      </RatingHeaderWrapper>
      <InputGroupWrapper>
        <FieldWrapper>
          {skillFields.map(({ icon, label }, index) => (
            <Row key={`traffic-light-${icon}-${index}`}>
              <IndexWrapper>{index + 1}.</IndexWrapper>
              <InputGroups>
                {label.map(({ locale }, i) => {
                  const companyLanguage = companyLanguages.find((lang) => lang.locale === locale);
                  return (
                    companyLanguage && (
                      <div className="inputWrapper" key={`${icon}-${locale}`}>
                        <Input
                          name={`options.${index}.label.${i}.value`}
                          width="100%"
                          height="38px"
                          register={skillRegister(`options.${index}.label.${i}.value`, {
                            required: true,
                          })}
                          placeholder={i18n._(t`item ${index + 1}`)}
                          leftIcon={
                            size(companyLanguages) > 1
                              ? getUnicodeFlagIcon(locale.substring(locale.indexOf('_') + 1))
                              : undefined
                          }
                          error={
                            isFieldError({
                              error: skillErrors,
                              optionIndex: index,
                              labelIndex: i,
                            }) && errorOnSave
                          }
                        />
                      </div>
                    )
                  );
                })}
              </InputGroups>
            </Row>
          ))}
        </FieldWrapper>
        <FieldWrapper>
          {ratingFields.map(({ icon, label }, index) => (
            <Row key={`traffic-light-${icon}-${index}`}>
              <InputGroups>
                {label.map(({ locale }, i) => {
                  const companyLanguage = companyLanguages.find((lang) => lang.locale === locale);
                  return (
                    companyLanguage && (
                      <div className="inputWrapper" key={`${icon}-${locale}`}>
                        <Input
                          name={`options.${index}.label.${i}.value`}
                          width="100%"
                          height="38px"
                          register={ratingRegister(`options.${index}.label.${i}.value`, {
                            required: true,
                          })}
                          placeholder={i18n._(t`item ${index + 1}`)}
                          leftIcon={
                            size(companyLanguages) > 1
                              ? getUnicodeFlagIcon(locale.substring(locale.indexOf('_') + 1))
                              : undefined
                          }
                          error={
                            isFieldError({
                              error: ratingErrors,
                              optionIndex: index,
                              labelIndex: i,
                            }) && errorOnSave
                          }
                        />
                      </div>
                    )
                  );
                })}
              </InputGroups>
              <IconWrapper
                className={size(skillFields) > 2 ? 'delete' : 'disabled'}
                onClick={() => {
                  if (size(skillFields) <= 2) {
                    return;
                  }
                  skillRemove(index);
                  ratingRemove(index);
                }}
              >
                <TrashIcon size={16} />
              </IconWrapper>
            </Row>
          ))}
        </FieldWrapper>
      </InputGroupWrapper>
      {size(skillFields) < 10 && (
        <AddOption
          type="button"
          onClick={() => {
            ratingAppend({
              label: companyLanguages.map(({ locale }) => ({ locale, value: '' })),
            });
            skillAppend({
              label: companyLanguages.map(({ locale }) => ({ locale, value: '' })),
            });
          }}
        >
          <Trans id="+ Add option" />
        </AddOption>
      )}
    </RatingScaleItemWrapper>
  );
};

export { RatingScaleItem };
