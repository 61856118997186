import { useEffect } from 'react';

import size from 'lodash/size';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import type { IEmployee } from '~/pages/Reviews/EditCycle/ReviewCycleForm/types';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import getAllUsers from '~/selectors/getAllUsers';
import getUserFullName from '~/utils/getUserFullName';

import type { IEditEmployeeForm } from './types';
import type { IJobProfile, IUser } from '@learned/types';

interface UseEditEmployeeModalProps {
  employee: IEmployee;
}

const initValues = {
  search: '',
  filtered: [],
};

export const useEditEmployeeModal = ({ employee }: UseEditEmployeeModalProps) => {
  const allUsers: Record<string, IUser> = useSelector(getAllUsers);

  const formMethods = useForm<IEditEmployeeForm>({
    defaultValues: {
      jobProfiles: { ...initValues, selected: employee.jobProfiles },
      coaches: {
        ...initValues,
        selected: employee.coaches.map((userId) => allUsers[userId]),
      },
      guests: {
        ...initValues,
        selected: employee.guests.map((userId) => allUsers[userId]),
      },
    },
  });
  const { watch, setValue } = formMethods;

  const getMultiLangString = useMultiLangString();

  const watchJobProfilesSearch = watch('jobProfiles.search');
  const watchCoachesSearch = watch('coaches.search');
  const watchGuestsSearch = watch('guests.search');

  useEffect(() => {
    let filtered: IJobProfile[] = [];
    if (size(watchJobProfilesSearch) > 2) {
      const regex = new RegExp(watchJobProfilesSearch.replaceAll('\\', ''), 'ig');
      filtered = employee.availableJobProfiles.filter((item) =>
        getMultiLangString(item.name).match(regex),
      );
    } else {
      filtered = employee.availableJobProfiles;
    }

    setValue('jobProfiles.filtered', filtered);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee.availableJobProfiles, watchJobProfilesSearch]);

  useEffect(() => {
    let filtered: IUser[] = [];
    const populatedCoaches = employee.availableCoaches.map((userId) => allUsers[userId]);
    if (size(watchCoachesSearch) > 0) {
      const regex = new RegExp(watchCoachesSearch.replaceAll('\\', ''), 'ig');
      filtered = populatedCoaches.filter((user) => getUserFullName(user).match(regex));
    } else {
      filtered = populatedCoaches;
    }

    setValue('coaches.filtered', filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee.availableCoaches, watchCoachesSearch]);

  useEffect(() => {
    let filtered: IUser[] = [];
    const populatedGuests = Object.values(allUsers).filter((user) => user.id !== employee.id);
    if (size(watchGuestsSearch) > 2) {
      const regex = new RegExp(watchGuestsSearch.replaceAll('\\', ''), 'ig');
      filtered = populatedGuests.filter((user) => getUserFullName(user).match(regex));
    } else {
      filtered = populatedGuests.slice(0, 50);
    }

    setValue('guests.filtered', filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee.guests, watchGuestsSearch]);

  return {
    formMethods,
  };
};
