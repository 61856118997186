import React from 'react';

import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';
import UserAvatar from '~/components/UI/Avatar';
import { Header4Dark } from '~/components/UI/Typographics/headers';

import { getUsers } from '~/selectors/baseGetters';
import { COLOR_PALETTE } from '~/styles';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';
import getUserFullName from '~/utils/getUserFullName';

const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  .pdf-checkbox {
    margin-left: 12px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const NameField = styled(Header4Dark)`
  width: 100%;
  margin: 2px 8px 0;

  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  color: ${(props) => (props.$isDone ? '#99a5be' : COLOR_PALETTE.BLACK)};
  text-decoration: ${(props) => props.$isDone && 'line-through'};
`;

const StepContainer = styled.div`
  margin: 0 24px 24px 24px;
`;

const StepInfo = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin-top: 4px;
`;

const NextStepsPdf = ({ currentSteps }) => {
  const allUsers = useSelector(getUsers);

  const renderSteps = (steps = []) => {
    return steps.map((step) => {
      const createdDate = convertToTimeString(step.meta.createdDate, TIME_FORMATS.DAY_MONTH_YEAR);
      const createdBy = allUsers[step.createdBy];

      return (
        <Box key={step.id}>
          <Header>
            <UserAvatar size={28} userId={step.createdFor} />
            <CheckBox
              size={18}
              checked={step.isCompleted}
              isDefaultColor={false}
              className="pdf-checkbox"
            />
            <NameField $isDone={step.isCompleted}>{step.comment}</NameField>
          </Header>
          <StepInfo>
            {!createdBy ? (
              <>
                <Trans>Created on</Trans> {createdDate}
              </>
            ) : (
              <>
                <Trans>Created by</Trans> {getUserFullName(createdBy)}
                {', '}
                {createdDate}
              </>
            )}
          </StepInfo>
        </Box>
      );
    });
  };

  return <StepContainer>{renderSteps(currentSteps)}</StepContainer>;
};

export default NextStepsPdf;
