import React, { useRef } from 'react';

import size from 'lodash/size';

import CheckBox from '~/components/CheckBox';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import Popover from '~/components/Popover';

import { ChevronIcon, Dropdown, Item, SelectWrapper } from './design';

import { COLORS } from '~/styles';

interface IFilterProps {
  label: string;
  items: { label: string; value: string }[];
  onClickItem: (value: string) => void;
  selectedItems: string[];
}

const FilterDropdown = ({ label, items, onClickItem, selectedItems }: IFilterProps) => {
  const popoverRef = useRef();

  return (
    // @ts-ignore
    <Popover
      ref={popoverRef}
      trigger="click"
      content={
        <Dropdown>
          {items.map(({ label, value }) => (
            <Item key={value} onClick={() => onClickItem(value)}>
              <CheckBox size={'20px'} checked={selectedItems.includes(value)} />
              <span>{label}</span>
            </Item>
          ))}
        </Dropdown>
      }
      minWidth={140}
      maxWidth={140}
      boxShadow="0 4px 4px 0 rgba(145, 157, 165, 0.12)"
      placement="bottom"
      $border={6}
      appendTo={document.body}
    >
      <SelectWrapper>
        <span className="value">
          {size(selectedItems)
            ? selectedItems
                .map((selectedItem) => items.find((item) => item.value === selectedItem)?.label)
                .join(', ')
            : label}
        </span>
        <ChevronIcon icon={ICONS.DROPDOWN} color={COLORS.ICONS_SECONDARY} size={ICON_SIZES.SMALL} />
      </SelectWrapper>
    </Popover>
  );
};

export { FilterDropdown };
