import React, { Fragment, useEffect, useState } from 'react';

import { ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import OverviewHeading from '~/components/OverviewHeading';

import { MeetingsSection } from './sections/Meetings';

import { INSTRUCTIONS } from '~/constants/instructions';
import routes from '~/constants/routes';
import { checkModuleReviews, checkModuleConversations } from '~/selectors/baseGetters';
import getInstructionUrl from '~/utils/getInstructionUrl';

export const TABS_SLIDER_ENUM = {
  MEETINGS: 'meetings',
};

const Conversations = () => {
  const { i18n } = useLingui();
  const history = useHistory();
  const isModuleReviewsEnabled = useSelector(checkModuleReviews);
  const isModuleMeetingsEnabled = useSelector(checkModuleConversations);
  const [activeTab, setActiveTab] = useState(window.location.hash);

  // TEMP solution for old reviews pages (redirected from calendar event)
  // Redirect wrong url to UserReview Dashboard
  const {
    userReview: userReviewFromQuery,
    originalConversation: originalConversationFromQuery,
  }: { userReview?: string; originalConversation?: string } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  // redirect to new conversation page (for old url from calendar events)
  if (userReviewFromQuery) {
    routes.REVIEW.go({ role: ROLES.USER }, { reviewId: userReviewFromQuery, isBackPath: true });
  }

  // redirect to new meetings page (for old url from calendar events
  if (originalConversationFromQuery && window.location.hash !== '#meetings') {
    routes.CONVERSATIONS.go(
      { role: ROLES.USER },
      { hash: 'meetings', query: { originalConversation: originalConversationFromQuery } },
    );
  }

  useEffect(() => {
    if (!isModuleReviewsEnabled && !isModuleMeetingsEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleReviewsEnabled, isModuleMeetingsEnabled, history]);

  useEffect(() => {
    setActiveTab(window.location.hash.substring(1).split('-')[0]); // grab parent tab hash (before "-")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash]);

  return (
    <Fragment>
      {/* @ts-ignore */}
      <OverviewHeading
        title={i18n._(t`1:1 & Team meetings`)}
        instructions={i18n._(t`How 1:1s work`)}
        instructionsUrl={getInstructionUrl(INSTRUCTIONS.HOW_1_ON_1S_WORK)}
        smallWidth
      />
      {activeTab === TABS_SLIDER_ENUM.MEETINGS && isModuleMeetingsEnabled && (
        <MeetingsSection parentHash={TABS_SLIDER_ENUM.MEETINGS} />
      )}
    </Fragment>
  );
};

export default Conversations;
