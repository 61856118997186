const addQueryParam = (search, extraParams = {}) => {
  const newParams = new URLSearchParams(search);
  Object.entries(extraParams).forEach(([key, value]) => {
    newParams.delete(key);
    newParams.append(key, value);
  });
  const newParamsString = newParams.toString();
  return newParamsString ? `?${newParamsString}` : '';
};

export { addQueryParam };
