import React from 'react';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';

import { ICONS } from '~/components/Icon';
import { ImageWithAlt } from '~/components/ImageWithAlt';
import RickTextView from '~/components/RickTextView';
import { useJobLevelGroups } from '~/pages/JobProfilesOverview/JobForm/hooks/useJobLevelGroups';
import { IconInfo } from '~/pages/JobProfileViewAdmin/tabs/IconInfo';
import type { IJobProfilePopulated } from '~/pages/JobProfileViewAdmin/types';

import { RequiredSkills } from './RequiredSkills';
import {
  Description,
  HeaderBlock,
  Image,
  InfoIcons,
  JobMatrix,
  JobRequirements,
  RecommendedCareerPath,
  RoleBlock,
  RoleContainer,
  SectionTitle,
  Subtitle,
  Title,
  TitleBlock,
} from './Setup.design';

import { EDUCATION_LEVELS, WORK_EXPERIENCE_LEVELS } from '~/constants';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';
import { convertMarkdownStarsToStrong } from '~/utils/markdown';

function Setup({ jobProfile }: { jobProfile: IJobProfilePopulated }) {
  const getMultiLangString = useMultiLangString();
  const { i18n } = useLingui();
  const { jobLevelGroupsPopulated } = useJobLevelGroups();

  const jobLevelGroup = jobLevelGroupsPopulated.find(
    (group) => group.id === `${jobProfile.jobLevelGroup?.id}-${jobProfile.jobLevelGroup?.level}`,
  );

  return (
    <div>
      <TitleBlock>
        <Title>{getMultiLangString(jobProfile.name)}</Title>
      </TitleBlock>
      {jobProfile.coverImage && (
        <Image>
          <ImageWithAlt src={jobProfile.coverImage} alt={getMultiLangString(jobProfile.name)} />
        </Image>
      )}

      <Description>
        <SectionTitle>
          <Trans>Description</Trans>
        </SectionTitle>
        {/* @ts-ignore */}
        <RickTextView
          html={convertMarkdownStarsToStrong(getMultiLangString(jobProfile.description))}
          color={COLORS.TEXT_MAIN}
        />
      </Description>

      <RequiredSkills jobProfile={jobProfile} />

      <JobRequirements>
        <SectionTitle>
          <Trans>Job requirements</Trans>
        </SectionTitle>
        <InfoIcons>
          <IconInfo
            icon={ICONS.WORK_EXPERIENCE}
            title={<Trans>Work Experience</Trans>}
            subtitle={
              jobProfile.careerLevel ? (
                WORK_EXPERIENCE_LEVELS[
                  jobProfile.careerLevel as keyof typeof WORK_EXPERIENCE_LEVELS
                ].shortName(i18n)
              ) : (
                <Trans>N/A</Trans>
              )
            }
          />
          <IconInfo
            icon={ICONS.EDUCATION}
            title={<Trans>Education</Trans>}
            subtitle={
              jobProfile.careerLevel ? (
                EDUCATION_LEVELS[jobProfile.educationLevel as keyof typeof EDUCATION_LEVELS]?.name(
                  i18n,
                )
              ) : (
                <Trans>N/A</Trans>
              )
            }
          />
        </InfoIcons>
      </JobRequirements>

      <JobMatrix>
        <SectionTitle>
          <Trans>Job Matrix</Trans>
        </SectionTitle>

        <InfoIcons>
          <IconInfo
            icon={ICONS.JOB_FAMILY}
            title={<Trans>Job Group</Trans>}
            subtitle={
              jobProfile.jobFamily ? (
                getMultiLangString(jobProfile.jobFamily.name)
              ) : (
                <Trans>N/A</Trans>
              )
            }
          />
          <IconInfo
            icon={ICONS.JOB_LEVEL}
            title={<Trans>Job level</Trans>}
            subtitle={
              jobLevelGroup && jobProfile.jobLevelGroup ? (
                `${getMultiLangString(jobProfile.jobLevelGroup.name)} (${
                  jobLevelGroup.realLevel + 1
                })`
              ) : (
                <Trans>N/A</Trans>
              )
            }
          />
        </InfoIcons>
      </JobMatrix>

      {!isEmpty(jobProfile.recommendedJobs) && (
        <RecommendedCareerPath>
          <SectionTitle>
            <Trans>Recommended career path</Trans>
          </SectionTitle>
          <Subtitle>
            <Trans>
              Add recommended career path. Select max 10 roles in a particular order (drag and drop)
            </Trans>
          </Subtitle>
          <HeaderBlock>
            <Trans>Recommended career path</Trans>
          </HeaderBlock>
          <RoleContainer>
            {jobProfile.recommendedJobs.map((role) => (
              <RoleBlock key={role.id} isCurrentRole={role.id === jobProfile.id}>
                {role.id === jobProfile.id ? (
                  <Trans>Current role</Trans>
                ) : (
                  getMultiLangString(role.name)
                )}
              </RoleBlock>
            ))}
          </RoleContainer>
        </RecommendedCareerPath>
      )}
    </div>
  );
}

export { Setup };
