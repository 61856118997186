import { BaseSyntheticEvent, useMemo, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useForm, type UseFormReturn } from 'react-hook-form';

import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import useBoolState from '~/hooks/useBoolState';
import { addSignature, deleteSignatures } from '~/services/userReviews';

import type { IReviewDashboardUserForm, ISignatureForm } from '../types';

const useSignatures = ({
  userReviewId,
  formMethods,
  isEmployee,
  forceReFetch,
}: {
  userReviewId: string;
  formMethods: UseFormReturn<IReviewDashboardUserForm>;
  isEmployee: boolean;
  forceReFetch: () => void;
}) => {
  const { i18n } = useLingui();
  const isVisible = useBoolState(false);
  const [editStepId, setEditStepId] = useState('');
  const { addToast } = useToasts();
  const signatureFormMethods = useForm<ISignatureForm>({
    defaultValues: {
      isSeen: false,
      isAgree: false,
    },
  });

  const role = useMemo(() => {
    return isEmployee ? ROLES.USER : ROLES.COACH;
  }, [isEmployee]);

  const onSubmit = async (e: BaseSyntheticEvent) => {
    if (userReviewId) {
      const submit = signatureFormMethods.handleSubmit(
        async ({ comment, signature, isAgree, isSeen }) => {
          const isConfirmed = await confirm({
            type: CONFIRMATION_MODAL_TYPE.WARNING,
            title: i18n._(t`Warning`),
            description: isEmployee
              ? i18n._(t`You cannot make any changes to your signature and comment.`)
              : i18n._(
                  t`By signing your review the review and all tasks will become locked. In addition, you cannot change your comment.`,
                ),
          });

          if (isConfirmed) {
            isVisible.off();
            await addSignature(userReviewId, {
              comment,
              signature,
              signatureDate: new Date().toISOString(),
              userId: formMethods.watch('employee.id'),
              role,
              ...(isEmployee
                ? {
                    isAgree,
                    isSeen,
                  }
                : undefined),
            });
            forceReFetch();
            addToast({ title: 'Review successfully signed.', type: TOAST_TYPES.SUCCESS });
          }
        },
      );

      return submit(e);
    }
  };

  const onReset = async () => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Warning`),
      description: i18n._(
        t` Resetting all signatures will delete all signatures and will require all guests and the employee to sign the review again. All comments made while signing will also be removed. This action cannot be undone.`,
      ),
    });
    if (isConfirmed) {
      const { code } = await deleteSignatures(userReviewId);
      if (code === 200) {
        forceReFetch();
        addToast({ title: 'Review signatures reset.', type: TOAST_TYPES.SUCCESS });
      }
    }
  };

  return {
    isVisible,
    editStepId,
    setEditStepId,
    onSubmit,
    onReset,
    signatureFormMethods,
  };
};

export { useSignatures };
