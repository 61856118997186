import React from 'react';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Header7Dark, Header6Dark } from '~/components/UI/Typographics/headers';

import { COLORS } from '~/styles';

const AllProgress = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  height: 14px;
  overflow: hidden;
  border-radius: 6px;
  border: solid 0.9px ${COLORS.BORDER_HARD};
`;

const DoneProgress = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 14px;
  width: ${(props) => props.$width}%;
  border-radius: 6px;
  background-color: ${(props) => props.$color || COLORS.BORDER_HARD};
  ${(props) =>
    !props.$isFullWidth &&
    `border-top-right-radius: 0;
  border-bottom-right-radius: 0;`}
`;

const Dot = styled.span`
  height: 10px;
  width: 10px;
  background-color: ${(props) => props.$color};
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
`;

const DotWrapp = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled(Header7Dark)`
  min-width: ${(props) => (props.$isWideLabel ? '170px' : '140px')};
  width: ${(props) => (props.$isWideLabel ? '170px' : '140px')};
  margin: 0;
  margin-right: 24px;
  white-space: nowrap;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 9px;
`;

const ScaleLabel = styled(Header6Dark)`
  white-space: nowrap;
  min-width: 150px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  margin-left: 16px;
`;

const Wrapper = styled.div`
  margin: 24px 24px 0;
`;

const ProgressBarList = ({ items, isWideLabel, maxScale }) => (
  <Wrapper>
    {items.map((i, idx) => (
      <Row key={idx}>
        <DotWrapp>
          <Dot $color={i.color} />
        </DotWrapp>
        <Label $isWideLabel={isWideLabel}>
          <Trans id={i.label} />
        </Label>
        <AllProgress>
          <DoneProgress
            $width={i.progress}
            $color={i.color}
            $isFullWidth={Number(i.progress) === 100}
          />
        </AllProgress>
        <ScaleLabel>
          {i.scaleLabel} {i.rate}/{maxScale}
        </ScaleLabel>
      </Row>
    ))}
  </Wrapper>
);

ProgressBarList.propTypes = {
  isWideLabel: PropTypes.bool,
  maxScale: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      scaleLabel: PropTypes.string,
      progress: PropTypes.number,
    }),
  ).isRequired,
};

export default ProgressBarList;
