import React from 'react';

import Tippy from '@tippyjs/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import 'tippy.js/dist/tippy.css';

import 'tippy.js/themes/light.css';

import UserDefaultAvatar from '~/assets/user-default.svg';

import getUserFromAllPossibleUsers from '~/selectors/getUserFromAllPossibleUsers';
import getUserFullName from '~/utils/getUserFullName';

const AvatarImage = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  border-radius: ${({ borderRadius }) => borderRadius || '6px'};
  box-sizing: border-box;
  border: ${({ $noBorder }) => ($noBorder ? null : '1px solid #fff')};
  display: flex;
  opacity: ${({ $isDisabled }) => ($isDisabled ? '0.4' : '1')};
  ${(props) => props.$isClickable && 'cursor: pointer;'}
  background-color: #999999;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ avatarUrl, isGray }) =>
    isGray
      ? `linear-gradient( rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) ), url('${
          avatarUrl || UserDefaultAvatar
        }')`
      : `url('${avatarUrl || UserDefaultAvatar}')`};

  @media print {
    & {
      ${(props) => !props.$isPrintAvatar && 'background-image: none; display: none;'}
    }
  }
`;

const Text = styled.div`
  word-break: break-word;
  white-space: pre-wrap;
  max-width: ${(props) => props.$maxWidth || '250px'};
`;

const TooltipContent = styled.div`
  display: flex;
  padding-right: 16px;
`;

const TooltipText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
`;

const TooltipUsername = styled.div`
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #3b3b3b;
  word-break: break-word;
`;

const tippyOpts = { modifiers: [{ name: 'computeStyles', options: { gpuAcceleration: false } }] };

const Avatar = ({
  size = 32,
  showTooltip = true,
  isGray = false,
  isDisabled = false,
  noBorder = false,
  user,
  userId,
  userAvatar,
  email,
  customTooltip,
  onClick,
  tooltipMaxWidth,
  isPrintAvatar = false,
  borderRadius,
  className,
}) => {
  const storeUser = useSelector((state) => getUserFromAllPossibleUsers(state, userId));

  // If the user is sent, returns it, otherwise gets it from the store if it exists
  const getUser = () => {
    if (user) {
      return user;
    }
    if (userId) {
      return storeUser;
    }
    return null;
  };

  const renderAvatar = (user) => {
    return (
      <AvatarImage
        className={className}
        size={size}
        avatarUrl={userAvatar || (user && user.avatarUrl)}
        isGray={isGray}
        $isDisabled={isDisabled}
        $noBorder={noBorder}
        onClick={onClick ? () => onClick(user) : () => {}}
        $isClickable={Boolean(onClick)}
        $isPrintAvatar={isPrintAvatar}
        borderRadius={borderRadius}
      />
    );
  };

  // Get user object
  const obtainedUser = getUser();

  let userName = '';
  if (obtainedUser) {
    userName = getUserFullName(obtainedUser);
  } else if (email) {
    userName = email;
  }

  const renderedAvatar = renderAvatar(obtainedUser);

  return (
    <Tippy
      trigger="mouseenter"
      theme="light"
      popperOptions={tippyOpts}
      disabled={!showTooltip}
      content={
        <Text $maxWidth={tooltipMaxWidth}>
          {customTooltip || (
            <TooltipContent>
              {renderedAvatar}
              <TooltipText>
                <TooltipUsername>{userName}</TooltipUsername>
              </TooltipText>
            </TooltipContent>
          )}
        </Text>
      }
    >
      {renderedAvatar}
    </Tippy>
  );
};

export default React.memo(Avatar);
