import FileSaver from 'file-saver';

import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function createPath(path) {
  return cloudRequest(serverRoutes.paths.createPath, {}, path);
}

export function createUsersPath(pathId, users) {
  return cloudRequest(serverRoutes.paths.createUsersPath(pathId), {}, { users });
}

// update from draft to draft
// update from draft to published
export function updatePath(pathId, path) {
  return cloudRequest(serverRoutes.paths.updatePath(pathId), {}, path);
}

/*
* Possible params to update
* {
* name,
  description,
  image,
  sections,
  activities,
  activitiesProps,
  isAvailableInUserLibrary,
* }
* */
// update from published => published
export function updatePathPublished(pathId, path) {
  return cloudRequest(serverRoutes.paths.updatePathPublished(pathId), {}, path);
}

export function getPathsToAssign(userId) {
  return cloudRequest(serverRoutes.paths.getPathsToAssign(userId));
}

export function getCoachDraftPaths() {
  return cloudRequest(serverRoutes.paths.getCoachDraftPaths);
}

export function getPath(
  id,
  { populate = [] } = {},
  { isSubPaths = false, isSubPathsActivities = false, isOwnUserPath = false } = {},
) {
  return cloudRequest(serverRoutes.paths.getPath(id), {
    populate,
    isSubPaths,
    isSubPathsActivities,
    isOwnUserPath,
  });
}

export function deletePath(pathId) {
  return cloudRequest(serverRoutes.paths.deletePath(pathId));
}

export function copyPath(pathId) {
  return cloudRequest(serverRoutes.paths.copyPath(pathId));
}

export function downloadLearningPathCSV(pathId, pathName) {
  return cloudRequest(serverRoutes.paths.downloadLearningPathCSV(pathId)).then((response) => {
    FileSaver.saveAs(response, pathName ? pathName + '_learning_path.csv' : 'learning_path.csv');
  });
}

export function getSubLibraryPaths(id, getProgress = true) {
  return cloudRequest(serverRoutes.paths.getSubLibraryPaths(id), { getProgress });
}

export function addPathUsers(id, usersToAdd) {
  return cloudRequest(serverRoutes.paths.addPathUsers(id), {}, { usersToAdd });
}

export function deletePathUsers(id, usersToDelete) {
  return cloudRequest(serverRoutes.paths.deletePathUsers(id), {}, { usersToDelete });
}

export function addPathOwners(id, newOwners) {
  return cloudRequest(serverRoutes.paths.addPathOwners(id), {}, { newOwners });
}

export function deletePathOwners(id, removedOwners) {
  return cloudRequest(serverRoutes.paths.deletePathOwners(id), {}, { removedOwners });
}
