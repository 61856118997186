import { createAction } from 'redux-act';

import type { Dispatch } from 'redux';

export const updateNumberOfItemsPerPageAction = createAction<number>(
  'update number of items per page',
);

export const updateNumberOfItemsPerPage = (numOfItems: number) => {
  return async function (dispatch: Dispatch) {
    dispatch(updateNumberOfItemsPerPageAction(numOfItems));
    return numOfItems;
  };
};
