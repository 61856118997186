import { REVIEW_QUESTION_EVALUATORS, REVIEW_QUESTION_TYPES } from '@learned/constants';
import { ILanguage, IReviewQuestion } from '@learned/types';

import { IQuestionForm, IQuestionOption } from './types';

import { QUESTION_TYPES } from '~/constants/questionsTypes';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

const ANSWER_TYPES = [
  {
    id: QUESTION_TYPES.RATING,
    key: QUESTION_TYPES.RATING,
    title: 'Rating',
  },
  {
    id: QUESTION_TYPES.TEXT,
    key: QUESTION_TYPES.TEXT,
    title: 'Text answer',
  },
  {
    id: QUESTION_TYPES.GOAL_PLAN,
    key: QUESTION_TYPES.GOAL_PLAN,
    title: 'Plan goals',
  },
  {
    id: QUESTION_TYPES.SKILL_CATEGORY,
    key: QUESTION_TYPES.SKILL_CATEGORY,
    title: 'Skills',
  },
  {
    id: QUESTION_TYPES.CUSTOM_SKILL,
    key: QUESTION_TYPES.CUSTOM_SKILL,
    title: 'Custom skill',
  },
];

export const getQuestionTypeObject = (answerType: string) => {
  const answer = ANSWER_TYPES.find((answer) => answer.id === answerType);
  if (!answer) {
    return;
  }
  return {
    key: answer.id,
    value: answer.title,
  };
};

export const getQuestionType = (
  question: IQuestionForm | IReviewQuestion,
): { id: string; key: string; title: string } => {
  const type = typeof question.type === 'string' ? question.type : question.type?.key;
  if (type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY) {
    return {
      id: QUESTION_TYPES.SKILL_CATEGORY,
      key: QUESTION_TYPES.SKILL_CATEGORY,
      title: 'Skill or KPI from job profile',
    };
  }
  return ANSWER_TYPES.find((answer) => answer.id === type)!;
};

export const getEvaluators = (evaluatorsList: REVIEW_QUESTION_EVALUATORS[]) => {
  const evaluators: { [key in REVIEW_QUESTION_EVALUATORS]: boolean } = {} as any;

  // Initialize evaluators object with false for all enum values
  Object.values(REVIEW_QUESTION_EVALUATORS).forEach((value) => {
    evaluators[value] = false;
  });

  if (!evaluatorsList?.length) {
    return evaluators;
  }
  // Set evaluatorsList values to true
  evaluatorsList.forEach((evaluator) => {
    evaluators[evaluator] = true;
  });

  return evaluators;
};

export function convertQuestionOptions(
  question: IReviewQuestion,
  companyLanguages: ILanguage[],
): IQuestionForm['options'] {
  switch (question.type) {
    case REVIEW_QUESTION_TYPES.RATING:
    case REVIEW_QUESTION_TYPES.CUSTOM_SKILL:
    case REVIEW_QUESTION_TYPES.SKILL_CATEGORY:
      return question.settings.options?.map((option) => ({
        label: turnMultiLangIntoArray(option.label, companyLanguages),
      }));
    case REVIEW_QUESTION_TYPES.TEXT:
    case REVIEW_QUESTION_TYPES.GOAL_PLAN:
    default:
      return [];
  }
}

export const handleEnterKeyPressOnFields = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
};

export const getRatingLabels = (
  languageState: ILanguageStateReturn,
  options?: IQuestionOption[],
) => {
  if (!options) {
    return null;
  }
  const enabledLanguages = languageState.languages.map((language) => language.locale);

  const filteredOptions = options?.map((option) => {
    return {
      ...option,
      label: option.label.filter(({ locale }) => enabledLanguages.includes(locale)),
    };
  });

  return filteredOptions.map(({ icon, label }) => ({
    icon,
    label: convertLanguageValue(label),
  }));
};

export const getLabelsForAvailableLanguages = (
  languageState: ILanguageStateReturn,
  options: { locale: string; value: string }[],
) => {
  const enabledLanguages = languageState.languages.map((language) => language.locale);
  return options.filter((option) => enabledLanguages.includes(option.locale));
};
