import React from 'react';

import styled from 'styled-components';

import { COLOR_PALETTE } from '~/styles';

const Segment = styled.div`
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => (props.$isActive ? COLOR_PALETTE.WHITE : COLOR_PALETTE.DARK_GRAY)};
  background: ${(props) => (props.$isActive ? 'var(--company-color)' : COLOR_PALETTE.WHITE)};
  padding: 0 8px;
  height: 100%;
  border-top-left-radius: ${(props) => (props.isFirst ? '6px' : '0px')};
  border-bottom-left-radius: ${(props) => (props.isFirst ? '6px' : '0px')};
  border-top-right-radius: ${(props) => (props.isLast ? '6px' : '0px')};
  border-bottom-right-radius: ${(props) => (props.isLast ? '6px' : '0px')};
  border-top: solid 1px var(--company-color);
  border-bottom: solid 1px var(--company-color);
  border-left: solid 1px var(--company-color);
  border-right: ${(props) => (props.isLast ? 'solid 1px var(--company-color)' : 'none')};
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
`;

const Box = styled.div`
  height: 32px;
  display: flex;
  box-sizing: border-box;
`;

const JobProfileReportTabs = ({ tabs, activeTab, onChange }) => (
  <Box>
    {tabs.map((t, index) => (
      <Segment
        isFirst={index === 0}
        isLast={index === tabs.length - 1}
        key={t.key}
        $isActive={activeTab === t.key}
        onClick={() => onChange(t.key)}
      >
        {t.label}
      </Segment>
    ))}
  </Box>
);

export default JobProfileReportTabs;
