import React from 'react';

import { ROLES } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Button from '~/components/Button';

import routes from '~/constants/routes';
import { COLORS } from '~/styles';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Title = styled.div`
  color: ${COLORS.BLACK};
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  margin-bottom: 28px;
`;

const Description = styled.div`
  color: ${COLORS.DARK_GRAY};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin-bottom: 32px;
`;

const BackText = styled(Description)`
  font-style: italic;
  margin-bottom: 12px;
`;

const NoAccess = () => {
  const { i18n } = useLingui();
  const goToSurveys = () => routes.SURVEYS_OVERVIEW.go({ role: ROLES.USER });
  return (
    <Content>
      <Title>
        <Trans>Oops!</Trans>
      </Title>
      <Description>
        <Trans>You do not have access to the survey</Trans>
      </Description>
      <BackText>
        <Trans>Click the button to view your surveys.</Trans>
      </BackText>
      <Button onClick={goToSurveys} label={i18n._(t`View surveys`)} />
    </Content>
  );
};

export { NoAccess };
