import isEmpty from 'lodash/isEmpty';

export default function (skill = {}) {
  const levels = [];
  if (!isEmpty(skill.focusAreas)) {
    (skill.focusAreas || []).forEach((focusArea) => {
      if (!isEmpty(focusArea) && focusArea.level) {
        if (isEmpty(levels[focusArea.level])) {
          levels[focusArea.level] = [focusArea];
        } else {
          levels[focusArea.level].push(focusArea);
        }
      }
    });
  }

  return levels;
}
