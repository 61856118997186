import { useEffect, useState, Dispatch, SetStateAction } from 'react';

import {
  API_RETURN_FIELDS,
  CAREER_PLAN_STATUSES,
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
  REVIEW_STATUS,
  REVIEW_TYPES,
  CONNECTION_STATUSES,
  REVIEW_QUESTION_TYPES_V1,
  USER_REVIEW_STATUS,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _, { orderBy } from 'lodash';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import { useSelector } from 'react-redux';

import { ICONS } from '~/components/Icon';
import { useInactiveUsers } from '~/pages/Reviews/hooks/useInactiveUsers';
import { useReviewTemplates } from '~/pages/Reviews/hooks/useReviewTemplates';
import { transformToISOString } from '~/pages/Reviews/utils';

import { useReviewTasks } from './useReviewTasks';

import useBoolState from '~/hooks/useBoolState';
import { useLanguageState } from '~/hooks/useLanguageState';
import { getUser, getSettingsRole } from '~/selectors/baseGetters';
import { getCareerPlansAsAdminCoach } from '~/services/careerPlans';
import { deleteReviewById, fetchReviewById, updateReviewById } from '~/services/reviews';
import { getUserReviews } from '~/services/userReviews';
import { getCompanyUsers } from '~/services/users';
import { turnArrayIntoMultiLang, turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';
import { isNotNil } from '~/utils/typePredicates';

import type { IEmployee, IReviewIndividualForm, PopulatedCareerPlan } from '../types';
import type {
  IReview,
  IUser,
  IUserReview,
  IReviewTemplate,
  IReviewQuestion,
  WithEvaluators,
  IMultiLangString,
} from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface UseReviewProps {
  formMethods: UseFormReturn<IReviewIndividualForm>;
  reviewId: IReview['id'];
  setUserId: Dispatch<SetStateAction<string | undefined>>;
}

export const useReview = ({ formMethods, reviewId, setUserId }: UseReviewProps) => {
  const { i18n } = useLingui();
  const {
    setValue,
    watch,
    formState: { dirtyFields },
  } = formMethods;
  const languageState = useLanguageState(true);

  const [item, setItem] = useState<IReview>();
  const user = useSelector(getUser);

  const [isAllowToDelete, setIsAllowToDelete] = useState(false);
  const $isReviewLoading = useBoolState(true);
  const $isReviewSaving = useBoolState(false);

  const userRole = useSelector(getSettingsRole);
  const { setTasksDates } = useReviewTasks({ formMethods });

  const { removeInactiveUsers } = useInactiveUsers();
  const { reviewTemplates } = useReviewTemplates();

  useEffect(() => {
    setIsAllowToDelete(item && (user.isAdmin || item?.createdBy === user.id));
  }, [item, user]);

  const fetchReview = async () => {
    const result = await fetchReviewById(reviewId);
    const review: IReview = result.data[API_RETURN_FIELDS.REVIEW];
    setItem(review);
    return review;
  };

  const fetchCareerPlans = async () => {
    const res = await getCareerPlansAsAdminCoach(
      { status: CAREER_PLAN_STATUSES.CURRENT },
      { populate: ['jobProfile'] },
      userRole,
    );
    return Object.values(res as Record<string, PopulatedCareerPlan>);
  };

  const fetchCompanyUsers = async (users: string[]): Promise<IUser[]> => {
    const { data } = await getCompanyUsers(
      {
        statuses: [CONNECTION_STATUSES.ACTIVE],
        users: users?.filter(Boolean) || [],
      },
      ['teams', 'jobProfiles', 'coaches'],
    );

    return Object.values(data?.users ?? {});
  };

  const getEmployeesFromUserReviews = async (userReviews: IUserReview[]) => {
    const careerPlans = await fetchCareerPlans();
    const users = await fetchCompanyUsers(userReviews.map((review) => review.createdFor));
    const employees: Array<IEmployee> = [];

    userReviews.forEach((userReview) => {
      const createdForUser = users.find((user: IUser) => user.id === userReview.createdFor);

      // define careerPlans default (for first run) and for other cases (from backup)
      // careerPlans should be populated with jobProfile
      const careerPlansDefault = careerPlans.filter(
        (item) => item.createdFor === userReview.createdFor && item.primary,
      );
      const careerPlansBackup = userReview.careerPlans.map((careerPlanId) => {
        const careerPlan = userReview?.backup?.careerPlans[careerPlanId];
        const jobProfileId = careerPlan?.jobProfile;
        return {
          ...careerPlan,

          // populate jobProfile
          jobProfile: jobProfileId && userReview?.backup?.jobProfiles[jobProfileId],
        };
      }) as unknown as PopulatedCareerPlan[];

      const isEditFlow = userReview.meta.lastModifiedDate;
      // @ts-ignore
      const defaultCoaches = createdForUser?.coaches || [];

      // @ts-ignore
      employees.push({
        ...createdForUser,
        id: userReview.createdFor, // !important we need this in case user does not exist in the company anymore, but he still present in review cycle
        coaches: isEditFlow ? userReview.coaches : defaultCoaches,
        guests: isEditFlow ? userReview.guests : defaultCoaches,
        userReview: userReview.id,
        careerPlans: isEditFlow ? careerPlansBackup : careerPlansDefault,
        // eslint-disable-next-line
        // @ts-ignore
        availableCoaches: createdForUser?.coaches || [],
        // eslint-disable-next-line
        // @ts-ignore
        availableJobProfiles: createdForUser?.jobProfiles || [],
      });
    });

    return employees;
  };

  const addEmployees = async (employees: IEmployee[], isPreparedUsers = false) => {
    // if employees prepared (from userReviews
    // we do not need to transform them, they are ready to add
    if (isPreparedUsers) {
      setValue('employees', employees);
      return;
    }

    const careerPlans = await fetchCareerPlans();

    setValue(
      'employees',
      employees.map((employee) => {
        const careerPlansAvailable = careerPlans.filter((item) => item.createdFor === employee.id);
        const careerPlansSelected = careerPlansAvailable.filter((item) =>
          employee.careerPlanIds?.includes(item.id),
        );

        const jobProfileIds = careerPlansSelected?.map((plan) => plan.jobProfile.id);
        const jobProfiles = employee?.jobProfiles?.filter((item) =>
          jobProfileIds.includes(item.id),
        );

        return {
          ...employee,
          coaches: removeInactiveUsers(employee.coaches),
          guests: removeInactiveUsers(employee.guests) || [],
          availableCoaches: watch('employees')?.[0]?.availableCoaches || [],
          availableJobProfiles: employee.jobProfiles || [],
          jobProfiles: jobProfiles || [],
          careerPlans: careerPlansSelected,
          availableCareerPlans: careerPlansAvailable,
        };
      }),
    );
  };

  const fetchUserReview = async () => {
    const result = await getUserReviews({ filters: { review: [reviewId] }, options: {} });
    const userReview: IUserReview = result.data[API_RETURN_FIELDS.USER_REVIEWS]?.[0];
    setValue('userReview', { id: userReview.id, createdFor: userReview.createdFor });
    setUserId(userReview.createdFor);
    const employees = await getEmployeesFromUserReviews([userReview]);

    await addEmployees(employees, true);
    return userReview;
  };

  const setFormValues = async () => {
    const review = await fetchReview();
    const userReview = await fetchUserReview();

    const isDraft = userReview.status === USER_REVIEW_STATUS.DRAFT;

    setValue('notifications', review.notifications);
    setValue('name', turnMultiLangIntoArray(review.name, languageState.companyLanguages));
    setValue('reviewTemplate', review.reviewTemplate);
    setValue('reviewInvitationTemplate', review.reviewInvitationTemplate);
    setValue('privacy', review.privacy);
    setValue('settings', {
      // @ts-ignore
      isAutoTimeline: true,
      ...review.settings,
      startDate: new Date(review.settings.startDate),
      endDate: review.settings.endDate ? new Date(review.settings.endDate) : null,
    });
    setValue(
      'description',
      turnMultiLangIntoArray(review.description, languageState.companyLanguages),
    );
    setValue('status', review.status);
    setValue('userReview', userReview);
    setValue('fetchedReview', review);
    setTasksDates(review);

    // define question types in template
    // @ts-ignore
    const reviewTemplateFromBackup = review?.backup?.reviewTemplate;
    // @ts-ignore
    const reviewQuestionsFromBackup = review?.backup?.reviewQuestions;
    // if backup exist (review not in DRAFT) - we get template from backup
    // because it's not possible to change anymore
    const selectedTemplate: IReviewTemplate | undefined = isDraft
      ? reviewTemplates.find((item) => item.id === review.reviewTemplate)
      : reviewTemplateFromBackup;
    const questions: IReviewQuestion[] = isDraft
      ? (selectedTemplate?.questions as unknown as IReviewQuestion[])
      : Object.values(reviewQuestionsFromBackup || {});
    const uniqQuestionTypes = uniq(questions?.map((item) => item?.type));
    const uniqEvaluators: REVIEW_QUESTION_EVALUATORS[] = uniq(
      flatten(questions?.map((item) => (item?.settings as WithEvaluators)?.evaluators)),
    );

    // define evaluators
    const evaluators = orderBy(
      uniqEvaluators
        .map((item) => {
          switch (item) {
            case REVIEW_QUESTION_EVALUATORS.EMPLOYEE:
              return {
                value: REVIEW_QUESTION_EVALUATORS.EMPLOYEE,
                icon: ICONS.EMPLOYEE,
                title: i18n._(t`Employees`),
                rank: 1,
              };
            case REVIEW_QUESTION_EVALUATORS.COACH:
              return {
                value: REVIEW_QUESTION_EVALUATORS.COACH,
                icon: ICONS.COACH,
                title: i18n._(t`Coaches`),
                rank: 2,
              };
            case REVIEW_QUESTION_EVALUATORS.PEER:
              return {
                value: REVIEW_QUESTION_EVALUATORS.PEER,
                icon: ICONS.PEER,
                title: i18n._(t`Peers`),
                rank: 3,
              };
            default:
              return null;
          }
        })
        .filter(isNotNil),
      ['rank'],
    );
    setValue('evaluators', evaluators);

    setValue('reviewQuestionTypes', uniqQuestionTypes);
    setValue(
      'reviewTemplateName',
      turnMultiLangIntoArray(
        (selectedTemplate?.name as IMultiLangString) || '',
        languageState.companyLanguages,
      ),
    );

    $isReviewLoading.off();
  };

  const saveReview = async (status?: REVIEW_STATUS) => {
    $isReviewSaving.on();
    const evaluators = watch('evaluators').map(
      (evaluator: { value: REVIEW_QUESTION_EVALUATORS; icon: ICONS; title: string }) =>
        evaluator.value,
    );

    const review = {
      name: turnArrayIntoMultiLang(watch('name')),
      notifications: watch('notifications'),
      reviewTemplate: watch('reviewTemplate') || null,
      privacy: watch('privacy'),
      settings: {
        ...watch('settings'),
        startDate: transformToISOString(watch('settings.startDate')),
        endDate: transformToISOString(watch('settings.endDate')),
      },
      status: status ? status : watch('status'),
      description: turnArrayIntoMultiLang(watch('description') || []),
      tasks: {},
      type: REVIEW_TYPES.SELF,
      reviewInvitationTemplate: watch('reviewInvitationTemplate') || null,
    };

    if (evaluators.includes(REVIEW_QUESTION_EVALUATORS.PEER)) {
      // @ts-ignore
      review.tasks.reviewPeerEvaluate = {
        startDate: transformToISOString(watch('tasks.reviewPeerEvaluate.startDate')),
        endDate: transformToISOString(watch('tasks.reviewPeerEvaluate.endDate')),
      };
      // @ts-ignore
      review.tasks.reviewPeerNominate = {
        startDate: transformToISOString(watch('tasks.reviewPeerNominate.startDate')),
        endDate: transformToISOString(watch('tasks.reviewPeerNominate.endDate')),
        description: turnArrayIntoMultiLang(watch('tasks.reviewPeerNominate.description') || []),
      };
    }

    if (evaluators.includes(REVIEW_QUESTION_EVALUATORS.COACH)) {
      // @ts-ignore
      review.tasks.reviewCoachEvaluate = {
        startDate: transformToISOString(watch('tasks.reviewCoachEvaluate.startDate')),
        endDate: transformToISOString(watch('tasks.reviewCoachEvaluate.endDate')),
      };
    }

    if (evaluators.includes(REVIEW_QUESTION_EVALUATORS.EMPLOYEE)) {
      // @ts-ignore
      review.tasks.reviewSelfEvaluate = {
        startDate: transformToISOString(watch('tasks.reviewSelfEvaluate.startDate')),
        endDate: transformToISOString(watch('tasks.reviewSelfEvaluate.endDate')),
      };
    }

    const employee = watch('employees')?.[0];
    const userReview = watch('userReview');

    const editUserReviews = [];
    const addUserReviews = [];
    const deleteUserReviews = [];

    const isCoachesVisible = evaluators.includes(REVIEW_QUESTION_EVALUATORS.COACH);
    const isJobsVisible = watch('reviewQuestionTypes').some((type) =>
      [REVIEW_QUESTION_TYPES.SKILL_CATEGORY, REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1].includes(
        type,
      ),
    );

    if (employee) {
      const coaches = isCoachesVisible ? employee.coaches : [];
      const careerPlans = employee.careerPlans?.map((careerPlan) => careerPlan.id) || [];

      if (userReview?.createdFor && userReview?.createdFor === employee.id) {
        editUserReviews.push({
          id: userReview.id,
          coaches,
          guests: employee.guests,
          careerPlans: isJobsVisible ? careerPlans : [],
        });
      } else {
        deleteUserReviews.push(userReview?.id);
        addUserReviews.push({
          createdFor: employee.id,
          coaches,
          guests: employee.guests,
          careerPlans: isJobsVisible ? careerPlans : [],
        });
      }
    }

    let result;
    try {
      result = await updateReviewById(reviewId, {
        // @ts-ignore
        review,
        editUserReviews: dirtyFields.employees ? editUserReviews : [],
        addUserReviews,
        deleteUserReviews,
      });
    } finally {
      // turn off loading modal if error or success
      $isReviewSaving.off();
    }
    return result;
  };

  const deleteReview = async () => {
    if (item && isAllowToDelete) {
      await deleteReviewById(item.id);
    }
  };

  useEffect(() => {
    setFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewId]);

  return {
    deleteReview,
    saveReview,
    isAllowToDelete,
    isReviewSaving: $isReviewSaving.value,
    isReviewLoading: $isReviewLoading.value,
    reviewTemplates,
  };
};
