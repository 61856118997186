import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import { CONVERSATION_STATUSES, CONVERSATION_COLLECTION_TYPES, ROLES } from '~/constants';
import routes from '~/constants/routes';

export const getPlanButtonColor = (conversationDeadline) => {
  let companyColor = 'var(--company-color)';

  const deadlineDate = conversationDeadline && moment(conversationDeadline);
  const timeTillPlan = deadlineDate ? deadlineDate.diff(moment(), 'days') : 10;

  if (timeTillPlan < 1) {
    companyColor = 'red';
  }
  if (timeTillPlan >= 1 && timeTillPlan < 2) {
    companyColor = 'yellow';
  }
  return companyColor;
};

export const getConversationOverviewDate = ({ conversation = {}, isReview = false }) => {
  if (!isReview) {
    if (!conversation.startDate) {
      return [];
    }
    let eventDate = [];
    if (conversation.startDate) {
      eventDate.push(moment(conversation.startDate).format('DD/MM'));
      eventDate.push(moment(conversation.startDate).format('HH:mm'));
    }
    return eventDate;
  } else {
    let eventDate = [];
    if (conversation.dateOfConversation) {
      eventDate.push(moment(conversation.dateOfConversation).format('DD/MM'));
      eventDate.push(moment(conversation.dateOfConversation).format('HH:mm'));
    }
    return eventDate;
  }
};

export const getConversationDate = ({
  conversation = {},
  isReview = false,
  isDone = false,
  isExpired = false,
}) => {
  if (!isReview) {
    if (!conversation.startDate || isExpired || isDone) {
      return false;
    }
    let eventDate = '';
    if (conversation.startDate) {
      eventDate += moment(conversation.startDate).format('DD-MM-YY, HH:mm');
    }
    if (conversation.endDate) {
      eventDate += '-' + moment(conversation.endDate).format('HH:mm');
    }
    return eventDate;
  } else {
    let eventDate = '';
    if (conversation.dateOfConversation) {
      eventDate += moment(conversation.dateOfConversation).format('DD-MM-YY | HH:mm');
    }
    if (conversation.dateOfConversationEnd) {
      eventDate += '-' + moment(conversation.dateOfConversationEnd).format('HH:mm');
    }
    return eventDate;
  }
};

const getUserReviewPath = ({ status, reviewId, teamId = null }) => {
  const path =
    status !== CONVERSATION_STATUSES.DRAFT.key
      ? routes.REVIEW_SUPERVISION_USER.build(
          {},
          {
            reviewId,
            isBackPath: true,
          },
        )
      : routes.REVIEW_UPDATE.build(teamId ? { role: ROLES.COACH, teamId } : {}, {
          reviewId,
          isBackPath: true,
        });
  return path;
};

export const getConversationPath = ({ conversation, selectedRole, userId, user, teams }) => {
  let teamId = null;
  switch (conversation.collection) {
    case CONVERSATION_COLLECTION_TYPES.REVIEW.key:
      // user review created for this user
      if (
        conversation.status !== CONVERSATION_STATUSES.DRAFT.key &&
        [ROLES.USER, ROLES.COACH].includes(selectedRole) &&
        [
          ...conversation.conversationCoaches,
          ...conversation.coaches,
          conversation.createdFor,
        ].includes(user.id) &&
        conversation.originalReview
      ) {
        return routes.REVIEW.build({}, { reviewId: conversation.id, isBackPath: true });
      }
      // draft reviews for user role
      if (selectedRole === ROLES.USER) {
        // need to get teamId only for user role
        // for other roles teamId from state
        const conversationTeam = find(teams, (t) => {
          return (
            t.coaches.includes(user.id) &&
            (conversation.status !== CONVERSATION_STATUSES.DRAFT.key
              ? t.members.includes(conversation.createdFor)
              : t.members.includes(conversation.users[0]))
          );
        });
        teamId = conversationTeam ? conversationTeam.id : null;
      }
      if (selectedRole === ROLES.ADMIN || conversation.status === CONVERSATION_STATUSES.DRAFT.key) {
        return getUserReviewPath({
          status: conversation.status,
          reviewId: conversation.id,
          teamId,
        });
      }
      return;

    case CONVERSATION_COLLECTION_TYPES.CONVERSATION.key:
      switch (conversation.status) {
        // draft conversations
        case CONVERSATION_STATUSES.DRAFT.key:
          return routes.CONVERSATION_UPDATE.build(
            {},
            {
              conversationId: conversation.id,
              isBackPath: true,
              ...(selectedRole !== ROLES.USER && {
                hash: 'meetings',
                query: {
                  // some old drafts has no users, to prevent crash on BE,
                  // add current user on update draft conversation, if conversation has no one
                  users: isEmpty(conversation.participants) ? [userId] : [],
                },
              }),
            },
          );
        // other conversations
        default:
          return selectedRole === ROLES.USER
            ? routes.CONVERSATION.build(
                {},
                {
                  conversationId: conversation.id,
                  isBackPath: true,
                },
              )
            : routes.CONVERSATION_SUPERVISION_USER.build(
                {},
                {
                  conversationId: conversation.id,
                  hash: 'meetings',
                  isBackPath: true,
                },
              );
      }
    default:
      return;
  }
};
