import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CONNECTION_STATUSES, JOB_PROFILE_STATUSES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Dropdown } from '~/components/Dropdown';
import { SearchSelectModal } from '~/components/SearchSelectModal';

import useDebounce from '~/hooks/useDebounce';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { usePagination } from '~/hooks/usePagination';
import { getJobProfiles } from '~/services/jobProfiles';
import { getTeams } from '~/services/teams';
import { getCompanyUsers } from '~/services/users';
import { isNotNil } from '~/utils/typePredicates';

import { createColumns } from '../columns/SelectEmployeesModal.columns';
import { type IEmployee, SORT_OPTIONS } from '../types';

import type { IJobProfile, ITeam, IUser } from '@learned/types';

interface SelectEmployeesModalProps {
  onClose: () => void;
  onSave: (employees: IEmployee[]) => void;
  isOnlyCoachTeamMembers?: boolean;
  usersToHide?: IUser['id'][];
}

const Wrapper = styled.div`
  .tableList {
    padding: 10px 0 0 0;
  }
`;

const SelectEmployeesModal = ({
  onClose,
  onSave,
  isOnlyCoachTeamMembers,
  usersToHide = [],
}: SelectEmployeesModalProps) => {
  const { i18n } = useLingui();
  const teamsFromStore = useSelector(getTeams);

  const [total, setTotal] = useState<number | undefined>();
  const [fetchedUsers, setFetchedUsers] = useState<Record<string, IEmployee>>({});
  const [users, setUsers] = useState<IEmployee[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<IJobProfile[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<ITeam[]>([]);
  const [teams, setTeams] = useState<ITeam[]>([]);
  const [roles, setRoles] = useState<IJobProfile[]>([]);
  const [sortBy, setSortBy] = useState(SORT_OPTIONS.NAME_A_Z);
  const getMultiLangString = useMultiLangString();

  const [isLoading, setIsLoading] = useState(false);
  const { pagination, changePagination } = usePagination(10);
  const [search, setSearch] = useState('');
  const debSearch = useDebounce(search, 300);

  useEffect(() => {
    const fetchTeams = async () => {
      const res = await getTeams({
        limit: 20,
        search: '',
        ...(isOnlyCoachTeamMembers && { isPermissionsCheck: true }),
      });
      setTeams(Object.values(res));
    };

    fetchTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchJobProfiles = async () => {
      const jobProfiles = await getJobProfiles(
        {
          search: '',
          status: JOB_PROFILE_STATUSES.ACTIVE,
        },
        { limit: 20 },
      );
      setRoles(Object.values(jobProfiles));
    };

    fetchJobProfiles();
  }, []);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setIsLoading(true);
      const sortOption = sortBy === SORT_OPTIONS.NAME_A_Z ? { fullName: 1 } : { fullName: -1 };
      const { data } = await getCompanyUsers(
        {
          search: debSearch,
          limit: pagination.limit,
          skip: pagination.skip,
          jobProfiles: selectedRoles.map((r) => r.id),
          teams: !isEmpty(selectedTeams)
            ? selectedTeams.map((t) => t.id)
            : isOnlyCoachTeamMembers
            ? // @ts-ignore
              (Object.values(teams) || []).map((t: ITeam) => t.id)
            : [],
          sort: sortOption,
          usersToHide,
          statuses: [CONNECTION_STATUSES.ACTIVE],
        },
        ['teams', 'jobProfiles', 'coaches'],
      );
      if (!isMounted) {
        return;
      }
      const usersData: IEmployee[] = Object.values(data?.users ?? {});

      setUsers(usersData);
      setFetchedUsers((prevState) => ({
        ...prevState,
        ...data?.users,
      }));
      setTotal(data?.total ?? 0);
      setIsLoading(false);
    };

    fetchData();

    return () => void (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debSearch,
    isOnlyCoachTeamMembers,
    pagination.limit,
    pagination.skip,
    selectedRoles,
    selectedTeams,
    sortBy,
    teamsFromStore,
    teams,
  ]);

  const filterCount = useMemo(() => {
    let count = 0;

    if (selectedTeams.length) {
      count += 1;
    }
    if (selectedRoles.length) {
      count += 1;
    }

    return count;
  }, [selectedRoles.length, selectedTeams.length]);

  const onCheckAll = useCallback(() => {
    return every(users.map((user) => selectedUsers.includes(user.id)))
      ? setSelectedUsers((prev) =>
          prev.filter((userId) => !users.map((user) => user.id).includes(userId)),
        )
      : setSelectedUsers((prev) => [...prev, ...users.map((user) => user.id)]);
  }, [selectedUsers, users]);

  const isItemChecked = useCallback(
    (item: IUser) => selectedUsers.includes(item.id),
    [selectedUsers],
  );

  const onSelectItem = useCallback(
    (item: IUser) =>
      setSelectedUsers((prevState) => {
        if (prevState.includes(item.id)) {
          return prevState.filter((value) => value !== item.id);
        }
        return [...prevState, item.id];
      }),
    [],
  );

  const resetFilters = () => {
    setSearch('');
    setSelectedTeams([]);
    setSelectedRoles([]);
  };

  return (
    <Wrapper>
      <SearchSelectModal
        title={i18n._(t`Select employees`)}
        disablePrimary={isEmpty(selectedUsers)}
        primaryActionLabel={i18n._(t`Add`)}
        onPrimaryAction={() => {
          // @ts-ignore
          onSave(selectedUsers.map((id) => fetchedUsers[id]).filter(isNotNil));
          onClose();
        }}
        onClose={onClose}
        tableListProps={{
          className: 'tableList',
          isLoading,
          columns: createColumns(getMultiLangString),
          data: users,
          multiSelectProps: {
            multiSelect: {
              checkedCount: selectedUsers.length,
              isAllChecked: every(users.map((user) => selectedUsers.includes(user.id))),
              onSelectItem,
              isItemChecked,
              onCheckAll,
            },
          },
          sortProps: { sortBy, setSortBy },
          placeholderProps: {
            noResultText: i18n._(t`This search did not produce any results`),
            emptyStateText: i18n._(t`No employees`),
          },
          filtersProps: {
            filters: {
              search,
              setSearch,
              filterCount: filterCount || undefined,
            },
            resetFilters,
            isFiltered: !!filterCount || !!search.length,
            isToggleHideFilterVisible: true,
            filterComponents: (
              <>
                <Dropdown
                  onChange={(teams) => setSelectedTeams(teams)}
                  items={teams}
                  selectedItems={selectedTeams}
                  stringifyItem={(item) => getMultiLangString(item.name)}
                  hashItem={(item) => item.id}
                  placeholder={i18n._(t`Teams`)}
                  isSingleSelect={false}
                />
                <Dropdown
                  onChange={(roles) => setSelectedRoles(roles)}
                  items={roles}
                  selectedItems={selectedRoles}
                  stringifyItem={(item) => getMultiLangString(item.name)}
                  hashItem={(item) => item.id}
                  placeholder={i18n._(t`Jobs`)}
                  isSingleSelect={false}
                />
              </>
            ),
          },
          paginationProps: {
            pagination,
            changePagination,
            totalCount: total,
            paginationItemLabel: i18n._(t`members`),
          },
        }}
      />
    </Wrapper>
  );
};

export { SelectEmployeesModal };
