import React from 'react';

import { SURVEY_STATUS, SURVEY_TASK_STATUS } from '@learned/constants';
import { IMultiLangString, ISurveyQuestion } from '@learned/types';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { ButtonPrimary } from '~/components/Button';
import { ButtonBar } from '~/pages/SurveyTaskView/design';

import { COLORS } from '~/styles';

const Title = styled.h1`
  font-size: 26px;
  letter-spacing: -0.29px;
  margin: 0;
  padding: 0;
  color: ${COLORS.BLACK};
  font-weight: 500;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.MIDDLE_GRAY};
`;

const SubmitButton = styled(ButtonPrimary)`
  height: 48px;
  min-width: 154px;
  padding: 4px 16px;
  border-radius: 24px;
  box-shadow: 0 1px 8px 3px rgba(166, 166, 166, 0.4);
  background-color: var(--company-color);
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  color: ${COLORS.WHITE};
`;

function SurveyOutro({
  surveyTask,
  onSubmit,
}: {
  onSubmit?: () => void;
  surveyTask: { status?: SURVEY_TASK_STATUS; name: IMultiLangString; questions: ISurveyQuestion[] };
}) {
  return (
    <>
      <Title>
        <Trans>Thanks for filling in this survey</Trans>
      </Title>
      <Description>
        <Trans>Once you press finish your answers will be shared.</Trans>
      </Description>
      {/* @ts-ignore */}
      {surveyTask?.survey.status !== SURVEY_STATUS.ARCHIVED && (
        <ButtonBar>
          <SubmitButton type="button" disabled={onSubmit === undefined} onClick={onSubmit}>
            {surveyTask.status === SURVEY_TASK_STATUS.COMPLETED ? (
              <Trans>Save</Trans>
            ) : (
              <Trans>Publish</Trans>
            )}
          </SubmitButton>
        </ButtonBar>
      )}
    </>
  );
}

export { SurveyOutro };
