import { ROLES } from '~/constants';
import {
  pageDashboard,
  pageOutside,
  pageOverview,
  pageOverviewFilter,
} from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'feedbacks';
const defaultRTFId = ':rtfeedbackId';

// overview
export const RTFEEDBACKS = new LinkConstructor([ROLES.USER], () => pageOverview(routeName));

export const RTFEEDBACK = new LinkConstructor([ROLES.USER], ({ rtfeedbackId = defaultRTFId }) =>
  pageDashboard(routeName, { id: rtfeedbackId }),
);

export const RTFEEDBACK_SKILLS_OUTGOING = new LinkConstructor(
  [ROLES.USER],
  ({ rtfeedbackId = defaultRTFId }) => `feedback-skill-outgoing/${rtfeedbackId}`,
);

export const RTFEEDBACKS_ASKED = new LinkConstructor([ROLES.USER], () =>
  pageOverviewFilter(routeName, { filter: 'asked' }),
);

export const RTFEEDBACKS_PROVIDED = new LinkConstructor([ROLES.USER], () =>
  pageOverviewFilter(routeName, { filter: 'provided' }),
);

export const RTFEEDBACKS_GIVE_FEEDBACK = new LinkConstructor(
  [ROLES.USER],
  ({ rtfeedbackId = defaultRTFId, requestId = ':requestId' }) =>
    `${pageDashboard(routeName, { id: rtfeedbackId })}/request/${requestId}/give-feedback`,
);

export const RTFEEDBACKS_GIVE_FEEDBACK_OUTSIDE = new LinkConstructor(
  [],
  ({ rtfeedbackId = defaultRTFId }) =>
    `${pageOutside(routeName)}/${rtfeedbackId}/give-feedback-outside`,
);

export const RTFEEDBACKS_PROVIDED_REPORT = new LinkConstructor(
  [ROLES.USER],
  ({ rtfeedbackId = defaultRTFId, requestId = ':requestId' }) =>
    `${pageDashboard(routeName, { id: rtfeedbackId })}/request/${requestId}/report`,
);

export const RTFEEDBACK_PROVIDED = new LinkConstructor(
  [ROLES.USER],
  ({ rtfeedbackId = defaultRTFId }) =>
    `${pageOverviewFilter(routeName, { filter: 'provided' })}/${rtfeedbackId}`,
);
