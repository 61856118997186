import React from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import YouTube from 'react-youtube';
import styled from 'styled-components';

import Button from '~/components/Button';
import Dialog from '~/components/Dialog';

import { COLORS } from '~/styles';
import getVideoId from '~/utils/getVideoId';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 23px 32px 42px;
`;

const StyledButton = styled(Button)`
  font-weight: 600;
  line-height: 1.14;
`;

const Text = styled.div`
  color: ${COLORS.TEXT_PRIMARY_2};
  width: 100%;
  margin: 24px 0;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: center;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: ${COLORS.TEXT_PRIMARY_2};
  width: 100%;
`;

const YouTubeWrap = styled.div`
  margin-bottom: 32px;
`;

function FirstLoginModal({ i18n, onClose }) {
  const videoId = getVideoId('https://youtu.be/AqZtpj3WtJE');
  return (
    <Dialog
      className={''}
      onClose={onClose}
      width={550}
      minWidth={550}
      isCloseOnOutsideClick={true}
    >
      <Content>
        <YouTubeWrap>
          <YouTube
            style={{ marginBottom: '24px' }}
            videoId={videoId}
            opts={{
              width: '486px',
              height: '260px',
            }}
          />
        </YouTubeWrap>
        <Title>
          <Trans>Welcome!</Trans>
        </Title>
        <Text>
          <Trans>
            You have succesfully created a company workspace. Watch the introduction video to learn
            how Learned will help you optimise your talent management processes.
          </Trans>
        </Text>
        <StyledButton
          type="primary"
          onClick={onClose}
          label={i18n._(t`Continue`)}
          width={150}
          height={46}
        />
      </Content>
    </Dialog>
  );
}

export default withI18n()(FirstLoginModal);
