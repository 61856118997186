import React from 'react';

import { REVIEW_PARTICIPATION_ROW_TYPE, REVIEW_PARTICIPATION_SORT_BY } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { CellEmployee } from '~/pages/Reviews/DashboardCycle/tabs/Participation/PerTeamSection/components/CellEmployee';
import { CellTeam } from '~/pages/Reviews/DashboardCycle/tabs/Participation/PerTeamSection/components/CellTeam';
import { FirstCellEmployee } from '~/pages/Reviews/DashboardCycle/tabs/Participation/PerTeamSection/components/FirstCellEmployee';
import { FirstCellTeam } from '~/pages/Reviews/DashboardCycle/tabs/Participation/PerTeamSection/components/FirstCellTeam';

import { ColumnPosition } from '~/@types/table';

import type { ICell, IColumn, IRow, IRowTransformed, IChildTransformed } from './types';

const createFirstColumn = () => {
  return {
    id: 'name',
    name: (i18n: I18n) => i18n._(t`Team`),
    accessor: 'name',
    isFixed: true,
    centerAlign: true,
    position: ColumnPosition.LEFT,
    maxWidth: '304px',
    minWidth: '40px',
    padding: '7px',
    showHeaderTooltip: true,
    sortBy: {
      asc: {
        key: REVIEW_PARTICIPATION_SORT_BY.NAME_ASC,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: REVIEW_PARTICIPATION_SORT_BY.NAME_DESC,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
    renderCell: (item: IRowTransformed | IChildTransformed, onClick: () => void) => {
      return item.type === REVIEW_PARTICIPATION_ROW_TYPE.TEAM ? (
        <FirstCellTeam item={item as IRowTransformed} onClick={onClick} />
      ) : (
        <FirstCellEmployee item={item as IChildTransformed} onClick={onClick} />
      );
    },
  };
};

const createOtherColumns = (columns: IColumn[]) => {
  return columns.map((column) => {
    return {
      id: column.id,
      name: column.name,
      accessor: column.id,
      renderCell: (row: IRow) => {
        const cell = row.cells.find((cell) => cell.columnId === column.id) as unknown as ICell;
        return row.type === REVIEW_PARTICIPATION_ROW_TYPE.TEAM ? (
          <CellTeam cell={cell} />
        ) : (
          <CellEmployee cell={cell} />
        );
      },
      isFixed: false,
      position: ColumnPosition.RIGHT,
      maxWidth: '172px',
      minWidth: '172px',
      padding: '7px',
      centerAlign: true,
      showHeaderTooltip: true,
      sortBy: {
        asc: {
          key: `${column.id}__asc`,
          label: (i18n: I18n) => i18n._(t`Low - high`),
        },
        desc: {
          key: `${column.id}__desc`,
          label: (i18n: I18n) => i18n._(t`High - low`),
        },
      },
    };
  });
};

const createColumns = (columns: IColumn[]) => {
  return [createFirstColumn(), ...createOtherColumns(columns)];
};

export { createColumns };
