import React from 'react';

import { GOAL_TYPES } from '@learned/constants';
import { Trans } from '@lingui/macro';

import { StyledDivider } from '~/components/GoalDashboardModal/design';
import { IPopulatedReviewTask } from '~/pages/ReviewGiveFeedback/types';

import { AdvancedSettingsSection } from './AdvancedSettingsSection';
import { Title, Form } from './design';
import { NameSection } from './NameSection';
import { OwnersSection } from './OwnersSection';
import { PrivacyAndDeadlineSection } from './PrivacyAndDeadlineSection';

interface IProps {
  isUpdate: boolean;
  reviewTask?: IPopulatedReviewTask;
  subTypes: (GOAL_TYPES.BUSINESS | GOAL_TYPES.PERSONAL)[];
}

export const StepOne = ({ subTypes }: IProps) => {
  return (
    <Form>
      <Title>
        <Trans>Goal details</Trans>
      </Title>
      <NameSection />
      <PrivacyAndDeadlineSection />
      <StyledDivider />
      <OwnersSection />
      <AdvancedSettingsSection subTypes={subTypes} />
    </Form>
  );
};
