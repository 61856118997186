import { GOAL_STATUSES_NEW } from '@learned/constants';
import { IActivity, ICreateGoal } from '@learned/types';
import isEmpty from 'lodash/isEmpty';

import * as goalsServices from '~/services/goals';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';
import objectDiff from '~/utils/objectDiff';

function prepareGoalObject(goal = {} as ICreateGoal) {
  return {
    ...goal,
    deadline: convertToTimeString(goal.deadline, TIME_FORMATS.ISO),
    goalCycles: (goal.goalCycles || []).map((c) => c.id || c),
    parent: goal.parent?.id || goal.parent,
    skills: (goal.skills || []).map((s) => s.id || s),
    // @ts-ignore
    teams: (goal.teams || []).map((t) => t.id || t),
  };
}

function prepareActivities(activities = [] as IActivity[]) {
  return activities.map((act) => {
    if (typeof act === 'string') {
      return act;
    }
    const newAct = { ...act };

    if (act.deadline) {
      newAct.deadline = convertToTimeString(act.deadline, TIME_FORMATS.ISO);
    }

    if (act.startDate) {
      newAct.startDate = convertToTimeString(act.startDate, TIME_FORMATS.ISO);
    }

    if (Object.hasOwnProperty.call(newAct, 'calculatedProgress')) {
      delete newAct.calculatedProgress;
    }

    if (act.files) {
      // @ts-ignore
      newAct.files = act.files.map((f) => f.id || f);
    }

    return newAct;
  });
}

export async function createGoal(newGoal: ICreateGoal, toPublish: boolean) {
  const goal = prepareGoalObject({
    type: newGoal.type,
    teams: newGoal.teams,
    owners: newGoal.owners,
    creators: newGoal.creators,
    viewers: newGoal.viewers,
    name: newGoal.name,
    description: newGoal.description,
    progressType: newGoal.progressType,
    progressDetails: newGoal.progressDetails,
    isChildProgress: newGoal.isChildProgress,
    activities: newGoal.activities,
    goalCycles: newGoal.goalCycles,
    deadline: newGoal.deadline,
    parent: newGoal.parent,
    status: toPublish ? GOAL_STATUSES_NEW.TODO : GOAL_STATUSES_NEW.DRAFT,
    isCoachesOfOwnerCanEdit: newGoal.isCoachesOfOwnerCanEdit,
    isOwnerCanEdit: newGoal.isOwnerCanEdit,
    skills: newGoal.skills,
  });
  const activities = prepareActivities(newGoal.activities);
  return await goalsServices.createGoal(goal, activities);
}

export async function updateGoalPublished({ activities, id, ...currentGoal }: ICreateGoal) {
  const goal = await goalsServices.getGoal(id, ['activities']);
  if (!isEmpty(goal)) {
    // @ts-ignore
    const updateOriginGoal = prepareGoalObject({
      teams: (currentGoal as ICreateGoal).teams,
      owners: (currentGoal as ICreateGoal).owners,
      viewers: (currentGoal as ICreateGoal).viewers,
      name: (currentGoal as ICreateGoal).name,
      description: (currentGoal as ICreateGoal).description,
      progressType: (currentGoal as ICreateGoal).progressType,
      progressDetails: (currentGoal as ICreateGoal).progressDetails,
      isChildProgress: (currentGoal as ICreateGoal).isChildProgress,
      activities: (currentGoal as ICreateGoal).activities,
      goalCycles: (currentGoal as ICreateGoal).goalCycles,
      deadline: (currentGoal as ICreateGoal).deadline,
      skills: (currentGoal as ICreateGoal).skills,
      // @ts-ignore
      parent: (currentGoal as ICreateGoal).parent || null,
      isCoachesOfOwnerCanEdit: (currentGoal as ICreateGoal).isCoachesOfOwnerCanEdit,
      isOwnerCanEdit: (currentGoal as ICreateGoal).isOwnerCanEdit,
    });

    const goalUpdate = objectDiff(
      goal,
      updateOriginGoal,
      [
        'teams',
        'owners',
        'viewers',
        'name',
        'description',
        'progressType',
        'progressDetails',
        'isChildProgress',
        'goalCycles',
        'deadline',
        'parent',
        'skills',
        'isOwnerCanEdit',
        'isCoachesOfOwnerCanEdit',
        'description',
      ],
      true,
    );

    await goalsServices.updateGoalPublished(id, goalUpdate, prepareActivities(activities));
  } else {
    throw Error('no data');
  }
}

export async function updateGoal(
  { activities, id, ...currentGoal }: ICreateGoal,
  toPublish: boolean,
) {
  const updateData = prepareGoalObject({
    type: currentGoal.type,
    teams: currentGoal.teams,
    owners: currentGoal.owners,
    creators: currentGoal.creators,
    viewers: currentGoal.viewers,
    name: currentGoal.name,
    description: currentGoal.description,
    progressType: currentGoal.progressType,
    progressDetails: currentGoal.progressDetails,
    isChildProgress: currentGoal.isChildProgress,
    // @ts-ignore
    activities: currentGoal.activities,
    goalCycles: currentGoal.goalCycles,
    deadline: currentGoal.deadline,
    // @ts-ignore
    parent: currentGoal.parent || null,
    status: toPublish ? GOAL_STATUSES_NEW.TODO : GOAL_STATUSES_NEW.DRAFT,
    skills: (currentGoal as ICreateGoal).skills,
    isCoachesOfOwnerCanEdit: (currentGoal as ICreateGoal).isCoachesOfOwnerCanEdit,
    isOwnerCanEdit: (currentGoal as ICreateGoal).isOwnerCanEdit,
  });

  // @ts-ignore
  await goalsServices.updateGoal(id, updateData, prepareActivities(activities), toPublish);
}
