import React, { memo } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';

import Label from '~/components/Label';
import ToolTip from '~/components/Tooltip';

import { FlexRow, TextRow } from './design';
import { JobProfileBasic } from './types';

import type { IColumnTable } from '~/@types/table';
import { JOB_STATUS_PROVIDER, JOB_STATUS_LABELS } from '~/constants/jobStatusProvider';
import { useMultiLangString } from '~/hooks/useMultiLangString';

import type { I18n } from '@lingui/core';

const StatusLabel = ({ status }: { status: JOB_STATUS_LABELS }) => {
  const { i18n } = useLingui();

  const statusProps = JOB_STATUS_PROVIDER[status];
  return <Label {...statusProps}>{statusProps.text(i18n)}</Label>;
};

const NameColumn = memo(({ item }: { item: JobProfileBasic }) => {
  const getMultiLangString = useMultiLangString();
  const name = !isEmpty(item.name) ? `${getMultiLangString(item.name)}` : '';

  return (
    <TextRow>
      <FlexRow>
        <ToolTip tooltip={name} disabled={name.length < 25}>
          <span>{name}</span>
        </ToolTip>
      </FlexRow>
    </TextRow>
  );
});

const COLUMNS: IColumnTable<JobProfileBasic>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Job`),
    accessor: 'name',
    minWidth: '200px',
    maxWidth: '270px',
    renderCell: (item) => {
      return <NameColumn item={item} />;
    },
  },

  {
    name: (i18n: I18n) => i18n._(t`Status`),
    accessor: 'status',
    maxWidth: '96px',
    renderCell: (item) => {
      return item.status ? <StatusLabel status={item.status} /> : null;
    },
  },
];

export { COLUMNS };
