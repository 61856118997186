import React, { Component } from 'react';

import { withI18n } from '@lingui/react';
import { isTablet } from 'react-device-detect';
import { withRouter } from 'react-router-dom';

import MobileView from './index.mobile.js';
import TabletView from './index.tablet.js';

class MobileNotSupported extends Component {
  render() {
    return isTablet ? <TabletView /> : <MobileView />;
  }
}

export default withI18n()(withRouter(MobileNotSupported));
