import React, { Component } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import Dialog from '~/components/Dialog';
import SvgIcon from '~/components/SvgIcon';

import { COLOR_PALETTE } from '~/styles';
import noMultyRun from '~/utils/noMultyRun';

import closeIcon from '../../assets/close-icn.svg';

const CloseIconButton = styled.div`
  cursor: pointer;
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
`;

const Header = styled.div`
  box-sizing: border-box;
  position: relative;
  min-height: 72px;
  height: 72px;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  color: #3b3b3b;
  border-bottom: 1px solid #ecedf0;
  z-index: 10;
`;

const HeaderRight = styled.div`
  display: flex;
`;

const HeaderTitle = styled.h5`
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  color: #3b3b3b;
  margin: 0;
`;

const ModalWrap = styled.div`
  display: flex;
  width: 500px;
  border-radius: 10px;
  box-shadow: 5px 10px 14px 0 rgba(55, 70, 95, 0.08);
  border: solid 0.5px #ecedf0;
  background-color: ${COLOR_PALETTE.WHITE};
  box-sizing: border-box;
  flex-direction: column;
  position: relative;
  z-index: 1000;
`;

const Footer = styled.div`
  text-align: right;
  padding: 16px;
  border-top: 1px solid #ecedf0;
  display: flex;

  & button {
    margin-left: auto;
    display: inline-block;
  }
  display: flex;
  justify-content: flex-end;
`;

class DeleteModal extends Component {
  static propTypes = {
    onCreate: PropTypes.func,
    onClose: PropTypes.func,
    jobProfileId: PropTypes.string,
  };

  constructor() {
    super();
  }

  render() {
    const { onClose, i18n, onDelete, loading, title, content } = this.props;

    return (
      <Dialog width="500px" minWidth="500px" centerModal>
        <ModalWrap>
          <Header>
            <HeaderTitle>
              <Trans>Delete {title}?</Trans>
            </HeaderTitle>
            <HeaderRight>
              <CloseIconButton onClick={onClose}>
                <SvgIcon width="16px" height="16px" url={closeIcon} alt={i18n._(t`Close`)} />
              </CloseIconButton>
            </HeaderRight>
          </Header>
          <ContentWrap>{content ? content : i18n._(t`This cannot be undone.`)}</ContentWrap>
          <Footer>
            <Button
              type="delete"
              label={i18n._(t`Delete`)}
              onClick={noMultyRun(onDelete)}
              loading={loading}
              styles={{ fontSize: '14px' }}
            />
            <Button
              styles={{ backgroundColor: 'lightgrey', marginLeft: '8px' }}
              label={i18n._(t`Cancel`)}
              onClick={onClose}
            />
          </Footer>
        </ModalWrap>
      </Dialog>
    );
  }
}

export default withI18n()(withRouter(DeleteModal));
