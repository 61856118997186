import React from 'react';

import Tippy from '@tippyjs/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

import IconButton from '~/components/IconButton';
import SvgIcon from '~/components/SvgIcon';
import Avatar from '~/components/UI/Avatar';
import { Header5 } from '~/components/UI/Typographics/headers';

import DeleteIcon from '~/assets/icons-14-px-close-active.svg';

import getUserFromAllPossibleUsers from '~/selectors/getUserFromAllPossibleUsers';
import { COLOR_PALETTE, COLORS } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';

const Text = styled.div`
  word-break: break-word;
  white-space: pre-wrap;
  max-width: ${(props) => props.$maxWidth || '250px'};
`;

const TooltipContent = styled.div`
  display: flex;
  padding-right: 16px;
`;

const TooltipText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
`;

const TooltipUsername = styled.div`
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #3b3b3b;
`;

const UserCard = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.$background || 'none'};
  padding: 4px 6px;
  border-radius: 6px;
  ${(props) => props.$isClickable && 'cursor: pointer;'}
`;

const UserInfo = styled.div`
  margin-left: 8px;
  @media print {
    & {
      margin-left: 0;
    }
  }
`;

const tippyOpts = { modifiers: [{ name: 'computeStyles', options: { gpuAcceleration: false } }] };

const UserInfoLarge = styled(UserInfo)`
  ${(props) =>
    props.$isEmailStyles && {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}
`;
const UserInfoSmall = styled(UserInfo)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${(props) =>
    props.$isEmailStyles && {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }};
`;

export const UserName = styled(Header5)`
  color: ${({ $isDisabled, $userNameColor }) =>
    $userNameColor ? $userNameColor : $isDisabled ? COLOR_PALETTE.DARK_GRAY : COLOR_PALETTE.BLACK};
  margin: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  word-break: break-word;

  ${(props) =>
    props.$isEmailStyles && {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}

  ${(props) => props.$isCursorPointer && 'cursor: pointer;'}
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  background-color: ${COLORS.BG_PAGE};
  position: relative;
  margin-left: 20px;
  height: 20px;
  padding: 4px 8px 4px 8px;
  border-radius: 3px;
  align-self: center;
  &:before {
    position: absolute;
    content: '';
    border-style: solid;
    border-width: 6px 6px 6px 0;
    border-color: transparent ${COLORS.BG_PAGE} transparent transparent;
    left: -6px;
    top: 8px;
    width: 0;
    height: 0;
  }
`;

const TYPES = { LARGE: 'large', MEDIUM: 'medium', SMALL: 'small' };

const AvatarCard = ({
  type = TYPES.LARGE,
  showTooltip = false,
  isDisabled = false,
  disableOnDeleteFor = [],
  email,
  customTooltip,
  className,
  size,
  label,
  userFullname,
  userAvatar,
  isEmailStyles,
  noBorder,
  userNameColor,
  onDelete,
  background,
  userId,
  userLocked,
  onClick,
  styles,
  showNameTooltip,
  tooltipMaxWidth,
  subTitle,
  isGray,
  borderRadius,
}) => {
  const storeUser = useSelector((state) => getUserFromAllPossibleUsers(state, userId));

  const renderUser = (user) => {
    const userName = userFullname || getUserFullName(user);

    const getAvatarSize = (type) => {
      if (type === TYPES.MEDIUM) {
        return 32;
      }
      if (type === TYPES.SMALL) {
        return 24;
      }
      return 40;
    };

    const avatarSize = size || getAvatarSize(type);

    return (
      <UserCard
        className={className}
        $background={background}
        onClick={onClick ? () => onClick(user || email) : () => {}}
        $isClickable={Boolean(onClick)}
        style={styles}
      >
        <Avatar
          userId={user && (user.user || user.id)} // user.user to display avatar for deleted users (regular user does not have user.user)
          email={email}
          isGray={isGray}
          size={avatarSize}
          showTooltip={showTooltip}
          customTooltip={customTooltip}
          isDisabled={isDisabled}
          userAvatar={userAvatar}
          tooltipMaxWidth={tooltipMaxWidth}
          borderRadius={borderRadius}
          noBorder={noBorder}
          onClick={onClick ? () => {} : undefined} // To display cursor pointer, but logic on click on UserCard
        />

        {type === TYPES.LARGE && (
          <UserInfoLarge $isEmailStyles={isEmailStyles}>
            <UserName
              $isDisabled={isDisabled}
              $isEmailStyles={isEmailStyles}
              $userNameColor={userNameColor}
            >
              {userName || email}
            </UserName>
          </UserInfoLarge>
        )}

        {(type === TYPES.SMALL || type === TYPES.MEDIUM) && (
          <Tippy
            trigger="mouseenter"
            theme="light"
            maxWidth={tooltipMaxWidth}
            popperOptions={tippyOpts}
            disabled={!showNameTooltip}
            content={
              <Text $maxWidth={tooltipMaxWidth}>
                {customTooltip || (
                  <TooltipContent>
                    <TooltipText>
                      <TooltipUsername>{userName}</TooltipUsername>
                    </TooltipText>
                  </TooltipContent>
                )}
              </Text>
            }
          >
            <UserInfoSmall $isEmailStyles={isEmailStyles}>
              <UserName
                $isDisabled={isDisabled}
                $isEmailStyles={isEmailStyles}
                $userNameColor={userNameColor}
                $isCursorPointer={showNameTooltip}
              >
                {userName || email}
              </UserName>
              {subTitle}
            </UserInfoSmall>
          </Tippy>
        )}

        {label && <Label>{label}</Label>}

        {onDelete && (
          <IconButton
            onClick={() => onDelete(userId || email)}
            size={24}
            noBorder
            disabled={disableOnDeleteFor.includes(userId || email)}
          >
            <SvgIcon
              url={DeleteIcon}
              width={'14px'}
              height={'14px'}
              isDefaultColor={disableOnDeleteFor.includes(userId || email)}
              defaultColor={COLOR_PALETTE.GRAY_LIGHT}
            />
          </IconButton>
        )}
      </UserCard>
    );
  };

  // Render user from object (without formatting)
  // This logic is used for outside feedback where we don't have users
  if (!isEmpty(userLocked)) {
    return renderUser(userLocked);
  }

  // Render user/users active/inactive/deleted
  return renderUser(storeUser);
};

const ExportAvatarCard = AvatarCard;
ExportAvatarCard.TYPES = TYPES;

export default ExportAvatarCard;
