import React, { Component } from 'react';

import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import routes from '~/constants/routes';
import { COLOR_PALETTE } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 95px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: ${COLOR_PALETTE.BLACK};
`;

const Description = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  text-align: center;
  color: ${COLOR_PALETTE.BLACK};
  margin-top: 26px;
`;

const Start = styled.div`
  margin-right: 5px;
`;

const End = styled.div`
  margin-left: 5px;
`;

class LogoutPage extends Component {
  render() {
    return (
      <Wrapper>
        <Title>
          <Trans>You logged out successfully</Trans>
        </Title>
        <Description>
          <Start>
            <Trans>Click</Trans>
          </Start>
          <Link to={routes.HOME}>
            <Trans>here</Trans>
          </Link>
          <End>
            <Trans>to log in</Trans>
          </End>
        </Description>
      </Wrapper>
    );
  }
}

export default withI18n()(withRouter(LogoutPage));
