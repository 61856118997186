import React, { useCallback, useEffect, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import Modal from '~/components/Modal';

import { getColumns } from './columns';
import { Header, Footer, StyledButton, StyledTableList } from './design';

import { usePagination } from '~/hooks/usePagination';

import type { ITeam } from '@learned/types';

type Props = {
  teams: ITeam[];
  onClose: () => void;
};

const TeamsModal = ({ teams = [], onClose }: Props) => {
  const [search, setSearch] = useState<string>('');
  const { pagination, changePagination, resetPagination } = usePagination(5);
  const [totalTeams, _setTotalTeams] = useState<Array<ITeam>>(teams || []);
  const [filteredTeams, setFilteredTeams] = useState<Array<ITeam>>([]);

  const { i18n } = useLingui();

  const paginateTeams = useCallback((): void => {
    let sortedTeams = [...totalTeams];

    if (search) {
      sortedTeams = sortedTeams.filter((team) =>
        team?.name?.toLowerCase().includes(search.toLowerCase()),
      );
    }

    const startIndex = (pagination.index - 1) * pagination.limit;
    sortedTeams = slice(sortedTeams, startIndex, startIndex + pagination.limit);

    setFilteredTeams(sortedTeams);
  }, [pagination, totalTeams, search]);

  useEffect(() => {
    paginateTeams();
  }, [pagination, totalTeams, paginateTeams, search]);

  const handleResetFilter = (): void => {
    setSearch('');
    resetPagination();
  };

  return (
    <Modal
      onClose={onClose}
      width={500}
      minWidth={'500px'}
      title={<Trans>Teams</Trans>}
      minHeight={'448px'}
      contentStyles={{ padding: '25px 25px 32px 25px', margin: '0' }}
      isHideHeader
      hideFooter
      showDivider={false}
    >
      <Header>
        <Trans>Teams</Trans>
      </Header>
      {/* @ts-ignore */}
      <StyledTableList
        columns={getColumns()}
        data={filteredTeams}
        filtersProps={{
          filters: {
            search,
            setSearch,
          },
          isFiltered: !!search,
          resetFilters: handleResetFilter,
        }}
        paginationProps={{
          pagination,
          changePagination,
          totalCount: totalTeams.length,
        }}
      />
      <Footer>
        <StyledButton
          onClick={onClose}
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          label={i18n._(t`Close`)}
        />
      </Footer>
    </Modal>
  );
};

export { TeamsModal };
