import styled from 'styled-components';

import { COLOR_PALETTE } from '~/styles';

const Info = styled.span`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 6px;
  border: solid 1px ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  background-color: #fff;
  font-size: 14px;
  margin-bottom: 16px;
`;

const Title = styled.span`
  font-weight: 600;
  color: black;
`;

const PlaceholderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Info, InfoBlock, Title, PlaceholderWrapper };
