import React, { Fragment } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import PerformanceGraph from '~/components/Graphs/PerformanceGraph';
import OverviewHeading from '~/components/OverviewHeading';
import BaseLayout from '~/layouts/BaseLayout';

import { INSTRUCTIONS } from '~/constants/instructions';
import getInstructionUrl from '~/utils/getInstructionUrl';

function ReportPerformancePage() {
  const { i18n } = useLingui();

  return (
    <Fragment>
      <OverviewHeading
        title={i18n._(t`Performance`)}
        description={i18n._(t`Analyse the performance of your company over time`)}
        instructions={i18n._(t`How the performance report works`)}
        instructionsUrl={getInstructionUrl(INSTRUCTIONS.HOW_PERFORMANCE_REPORT_WORKS)}
      />

      <BaseLayout>
        <PerformanceGraph />
      </BaseLayout>
    </Fragment>
  );
}

export default ReportPerformancePage;
