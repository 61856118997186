import React, { useState } from 'react';

import { useMultiSelectState } from '~/components/Dropdown/hooks';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { BaseSelectSearch } from '~/components/SearchSelect/BaseSelectSearch';
import { Label, MultiSelectLabels, MultiSelectContainer } from '~/components/SearchSelect/design';

import type { MultiSearchSelectProps } from './types';
import type { WithRequired } from '@learned/types';

function MultiSearchSelect<T>({
  items,
  onChange,
  stringifyItem,
  selectedItems,
  hashItem,
  ...props
}: WithRequired<MultiSearchSelectProps<T>, 'stringifyItem'>) {
  const [search, setSearch] = useState('');
  const { isItemSelected, hasSelectedItem, handleNewItemSelected } = useMultiSelectState({
    selectedItems,
    onChange,
    hashItem,
  });

  return (
    <MultiSelectContainer>
      <BaseSelectSearch
        items={items.filter((item) => !isItemSelected(item))}
        stringifyItem={stringifyItem}
        hasSelectedItem={false}
        handleNewItemSelected={handleNewItemSelected}
        search={search}
        setSearch={setSearch}
        showRightIcon
        {...props}
      />

      {hasSelectedItem && (
        <MultiSelectLabels>
          {selectedItems.map((item) => (
            <Label key={stringifyItem(item)}>
              <span>{stringifyItem(item)}</span>
              <Icon
                icon={ICONS.CLOSE_2}
                size={ICON_SIZES.SMALL}
                onClick={() => handleNewItemSelected(item)}
              />
            </Label>
          ))}
        </MultiSelectLabels>
      )}
    </MultiSelectContainer>
  );
}

export { MultiSearchSelect };
