import { createReducer } from 'redux-act';

import * as customReportActions from './actions';

const INITIAL_STATE = {
  data: null,
};

export const customReports = createReducer(
  {
    [customReportActions.startGetCustomReports]: (state) => {
      return {
        ...state,
      };
    },
    [customReportActions.finishGetCustomReports]: (state, reports) => {
      return {
        ...state,
        data: reports,
      };
    },
    [customReportActions.resetCustomReportsStore]: (state) => {
      return {
        ...state,
        data: null,
      };
    },
  },
  INITIAL_STATE,
);
