import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

import { SORT_OPTIONS } from './columns';

import type { ICareerPlan } from '@learned/types';

const fillStartDate = (jobs: ICareerPlan[]) => {
  const updatedJobs = cloneDeep(jobs);
  updatedJobs.forEach((job) => {
    if (!job.startDate) {
      job.startDate = moment(job.meta.createdDate).format();
    }
  });
  return updatedJobs;
};

const sortJobs = (jobs: ICareerPlan[], sortBy: SORT_OPTIONS) => {
  const updatedJobs = fillStartDate(jobs);

  switch (sortBy) {
    case SORT_OPTIONS.NAME_A_Z:
      updatedJobs.sort((a, b) => a.name.localeCompare(b.name));
      break;
    case SORT_OPTIONS.NAME_Z_A:
      updatedJobs.sort((a, b) => b.name.localeCompare(a.name));
      break;
    case SORT_OPTIONS.START_DATE_SOON_LATER:
      updatedJobs.sort((a, b) => moment(a.startDate).valueOf() - moment(b.startDate).valueOf());
      break;
    case SORT_OPTIONS.START_DATE_LATER_SOON:
      updatedJobs.sort((a, b) => moment(b.startDate).valueOf() - moment(a.startDate).valueOf());
      break;
    case SORT_OPTIONS.END_DATE_SOON_LATER:
      updatedJobs.sort((a, b) => {
        const aEndDate = a.endDate ? moment(a.endDate).valueOf() : moment().unix();
        const bEndDate = b.endDate ? moment(b.endDate).valueOf() : moment().unix();
        return aEndDate - bEndDate;
      });
      break;
    case SORT_OPTIONS.END_DATE_LATER_SOON:
      updatedJobs.sort((a, b) => {
        const bEndDate = b.endDate ? moment(b.endDate).valueOf() : moment().unix();
        const aEndDate = a.endDate ? moment(a.endDate).valueOf() : moment().unix();
        return bEndDate - aEndDate;
      });
      break;
    case SORT_OPTIONS.DURATION_SHORT_LONG:
      updatedJobs.sort(
        (a, b) =>
          moment
            .duration(
              moment(a.endDate ? a.endDate : new Date()).diff(
                moment(a.startDate ? a.startDate : a.meta.createdDate),
              ),
            )
            .asMilliseconds() -
          moment
            .duration(
              moment(b.endDate ? b.endDate : new Date()).diff(
                moment(b.startDate ? b.startDate : b.meta.createdDate),
              ),
            )
            .asMilliseconds(),
      );
      break;
    case SORT_OPTIONS.DURATION_LONG_SHORT:
      updatedJobs.sort(
        (a, b) =>
          moment
            .duration(
              moment(b.endDate ? b.endDate : new Date()).diff(
                moment(b.startDate ? b.startDate : b.meta.createdDate),
              ),
            )
            .asMilliseconds() -
          moment
            .duration(
              moment(a.endDate ? a.endDate : new Date()).diff(
                moment(a.startDate ? a.startDate : a.meta.createdDate),
              ),
            )
            .asMilliseconds(),
      );
      break;
    default:
      break;
  }
  return updatedJobs;
};

export { sortJobs };
