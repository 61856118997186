export enum REQUEST_STATUSES {
  REQUESTED = 'requested',
  CANCELLED = 'cancelled',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  STARTED = 'started',
  SHARED = 'shared',
  EXPIRED = 'expired',
  CALIBRATE = 'calibrate',
}

export enum REQUEST_SUBTYPES {
  FEEDBACK = 'feedback',
}

export enum REQUEST_TYPES {
  INVITE_TEAM = 'invite_team',
  FEEDBACK_FROM_PEER = 'feedbackFromPeer',
  FEEDBACK_FROM_COACH = 'feedbackFromCoach',
  FEEDBACK_FROM_OUTSIDE_PEER = 'feedbackFromOutsidePeer',
  RT_FEEDBACK = 'rt_feedback',
  RT_FEEDBACK_OUTSIDE = 'rt_feedback_outside',
  NOMINATE_CONVERSATION_COACH = 'nominateConversationCoach',
}
