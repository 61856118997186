import React, { useEffect, useMemo } from 'react';

import { Trans } from '@lingui/macro';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import Editor from '~/components/Editor';

import type { IQuestionForm } from '~/@types/question';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';
import { getMultiLangString as getMultiLangStringOriginal } from '~/utils/getMultiLangString';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import type { ICompany, IConnection, ILanguage, ISurveyRating } from '@learned/types';

interface IQuestionViewTextProps {
  defaultValues: IQuestionForm;
  languageState: ILanguageStateReturn;
  isPreselectedLang?: boolean;
  error?: boolean;
  isPreferredLanguage?: boolean;
  onChange?: (data: {
    answer?: ISurveyRating['answer'];
    comment?: ISurveyRating['comment'];
  }) => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;

  & .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const Title = styled.div`
  font-size: 26px;
  color: ${COLORS.BLACK};
  margin-bottom: 48px;
  text-align: center;
`;

const Label = styled.label`
  font-size: 14px;
  color: ${COLORS.MIDDLE_GRAY};
  padding-bottom: 4px;
  text-align: center;
`;

const DescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const QuestionViewText = ({
  languageState,
  defaultValues,
  isPreselectedLang = false,
  onChange,
  error,
  isPreferredLanguage = false,
}: IQuestionViewTextProps) => {
  const { reset, control, watch } = useForm<IQuestionForm>({ defaultValues });
  const nameWatch = watch('name');
  const { companyPrimaryLanguage, languages } = languageState;
  const getMultiLangString = useMultiLangString();

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const locale = isPreselectedLang ? languages[0].locale : companyPrimaryLanguage.locale;

  const questionTitle = useMemo(() => {
    const multiLang = turnArrayIntoMultiLang(nameWatch);
    if (isPreferredLanguage) {
      return getMultiLangString(multiLang);
    } else {
      return getMultiLangStringOriginal(
        multiLang,
        { preferredLang: { locale } as ILanguage } as IConnection,
        {
          primaryLang: companyPrimaryLanguage,
          languages,
        } as ICompany,
      );
    }
  }, [
    isPreferredLanguage,
    getMultiLangString,
    nameWatch,
    locale,
    companyPrimaryLanguage,
    languages,
  ]);

  return (
    <Wrapper>
      <Title>{questionTitle}</Title>
      <DescriptionWrapper>
        <Label>
          <Trans>Type your answer:</Trans>
        </Label>
        <Controller
          name="answer"
          control={control}
          render={({ field }) => (
            <Editor
              error={error}
              value={field.value}
              compact
              onChange={(value: string) => {
                field.onChange(value);
                onChange?.({ answer: value });
              }}
              big
            />
          )}
        />
      </DescriptionWrapper>
    </Wrapper>
  );
};

export { QuestionViewText };
