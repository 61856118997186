import { SURVEY_TEMPLATES_SORT_OPTIONS } from '@learned/constants';
import { ISurveyTemplate } from '@learned/types';
import { IUpdateItem } from '@learned/types/src/generic';
import isEmpty from 'lodash/isEmpty';

import type { ICreateServerRoute, IServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function createSurveyTemplate() {
  return cloudRequest(serverRoutes.surveyTemplates.createSurveyTemplate as IServerRoute);
}

export function getSurveyTemplates(
  filters: { search?: string; statuses?: string[] },
  options: { skip?: number; limit?: number; sortBy?: SURVEY_TEMPLATES_SORT_OPTIONS },
) {
  const body = {
    filters: {
      search: filters.search,
      ...(!isEmpty(filters.statuses) && { statuses: filters.statuses }),
    },
    options: {
      skip: options.skip,
      limit: options.limit,
      sortBy: options.sortBy,
    },
  };
  return cloudRequest(serverRoutes.surveyTemplates.getSurveyTemplates as IServerRoute, {}, body);
}

export function getSurveyTemplate(
  surveyTemplateId: string,
  { populate = [], joinToQuestions = [] }: { populate?: string[]; joinToQuestions?: string[] } = {},
) {
  const body = { populate, joinToQuestions };
  return cloudRequest(
    (serverRoutes.surveyTemplates.getSurveyTemplate as ICreateServerRoute)(surveyTemplateId),
    {},
    body,
  );
}

export function updateSurveyTemplate(surveyTemplateId: string, data: IUpdateItem<ISurveyTemplate>) {
  return cloudRequest(
    (serverRoutes.surveyTemplates.updateSurveyTemplate as ICreateServerRoute)(surveyTemplateId),
    {},
    data,
  );
}

export function deleteSurveyTemplates(surveyTemplateIds: string[]) {
  return cloudRequest(
    serverRoutes.surveyTemplates.deleteSurveyTemplates as IServerRoute,
    {},
    {
      surveyTemplates: surveyTemplateIds,
    },
  );
}

export function importQuestions(
  surveyTemplateId: string,
  data: { position?: number; questions: string[] },
) {
  return cloudRequest(
    (serverRoutes.surveyTemplates.importQuestions as ICreateServerRoute)(surveyTemplateId),
    {},
    data,
  );
}
