import React, { useEffect, useState } from 'react';

import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';

import Switch from '~/components/Switch';
import { Row, Block, Title } from '~/pages/CompanySettings/components/NotificationSettings.design';

import {
  getCompanyUserNotifications,
  updateCompanyUserNotifications,
} from '~/services/companySettings';
import { updateCompanySettingsNotificationSettings } from '~/store/companySettings/actions';

const NotificationSettings = ({ companyId }: { companyId: string }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!companyId) {
      return;
    }
    setLoading(true);
    let mounted = true;
    const fetch = async () => {
      const response = await getCompanyUserNotifications(companyId);
      if (!mounted) {
        return;
      }
      setChecked(
        response?.data?.companySettings?.notificationSettings
          ?.userIsAllowedToSetNotificationPreferences ?? false,
      );
      setLoading(false);
    };
    fetch();

    return () => void (mounted = false);
  }, [companyId]);

  async function onChange(value: boolean) {
    setLoading(true);
    const response = await updateCompanyUserNotifications(companyId, {
      userIsAllowedToSetNotificationPreferences: value,
    });
    const notificationSettings = response?.data?.companySettings?.notificationSettings;
    dispatch(updateCompanySettingsNotificationSettings(notificationSettings));
    setChecked(notificationSettings?.userIsAllowedToSetNotificationPreferences ?? false);
    setLoading(false);
  }

  return (
    <Block>
      <Title>
        <Trans>Notifications</Trans>
      </Title>
      <Row>
        <Switch disabled={loading} onChange={onChange} checked={checked} />
        <Trans>Users are allowed to enable/disable their notifications</Trans>
      </Row>
    </Block>
  );
};

export { NotificationSettings };
