import React, { useEffect } from 'react';

import { Trans } from '@lingui/macro';
import nanoid from 'nanoid';
import { useFieldArray, useForm } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Modal from '~/components/Modal';

import { Categories, Footer, Title } from './design';
import { useManageSkillCategories } from './hooks';
import { SkillCategoryLevels } from './SkillCategoryLevels';

import { SUPER_ADMIN_LANGUAGES } from '~/constants/languages';
import { useGenericLanguageState } from '~/hooks/useLanguageState';
import { LS_KEYS } from '~/hooks/useLocalStorage';
import { turnMultiLangIntoArray, turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import type { IGeneralForm } from './types';

function SkillCategoriesAndLevelsModal({ onClose }: { onClose: () => void }) {
  const languageState = useGenericLanguageState({
    defaultLanguages: SUPER_ADMIN_LANGUAGES,
    primaryLanguage: SUPER_ADMIN_LANGUAGES[0],
    localStorageKey: LS_KEYS.LS_SUPER_ADMIN_LANGUAGES,
  });
  const { skillCategories, updateSkillCategories } = useManageSkillCategories();
  const generalFormMethods = useForm<IGeneralForm>({
    mode: 'all',
  });
  const { control, setValue, getValues } = generalFormMethods;
  const skillCategoryFields = useFieldArray({ control, name: 'skillCategories' });

  useEffect(() => {
    setValue(
      'skillCategories',
      skillCategories.map((category) => {
        return {
          _id: category.id,
          name: turnMultiLangIntoArray(category.name, languageState.defaultLanguages),
          skillLevels: category.skillLevels.map((skillLevel) => ({
            _id: nanoid(),
            name: turnMultiLangIntoArray(skillLevel, languageState.defaultLanguages),
          })),
        };
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillCategories]);

  const saveCategories = async () => {
    const values = getValues();

    await updateSkillCategories(
      values.skillCategories.map((value) => ({
        id: value._id,
        name: turnArrayIntoMultiLang(value.name.filter((lang) => lang.value !== '')),
        skillLevels: value.skillLevels.map((skillLevel) =>
          turnArrayIntoMultiLang(skillLevel.name.filter((lang) => lang.value !== '')),
        ),
      })),
    );
    onClose();
  };

  return (
    <Modal
      title={
        <Title>
          <Trans>Manage skill categories and levels</Trans>
        </Title>
      }
      width={750}
      onClose={onClose}
      showDivider={false}
      footerRight={
        <Footer>
          <Button
            onClick={() => onClose()}
            label={<Trans>Cancel</Trans>}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
          />
          <Button
            onClick={saveCategories}
            label={<Trans>Save</Trans>}
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
          />
        </Footer>
      }
    >
      <Categories>
        {skillCategoryFields.fields.map((field, index) => (
          <SkillCategoryLevels
            languageState={languageState}
            formMethods={generalFormMethods}
            key={field.id}
            categoryIndex={index}
          />
        ))}
      </Categories>
    </Modal>
  );
}

export { SkillCategoriesAndLevelsModal };
