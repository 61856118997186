import { useEffect, useState } from 'react';

import findIndex from 'lodash/findIndex';
import sortBy from 'lodash/sortBy';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { checkModuleSurvey } from '~/selectors/baseGetters';
import { getSurveyTask } from '~/services/surveyTasks';

import type {
  ISurvey,
  ISurveyEvent,
  ISurveyQuestion,
  ISurveyRating,
  ISurveyTask,
} from '@learned/types';

export type IPopulatedSurveyTask = Omit<ISurveyTask, 'questions' | 'event' | 'survey'> & {
  questions: ISurveyQuestion[];
  event: ISurveyEvent;
  survey: ISurvey;
};

const useFetchSurveyTask = () => {
  const params: Record<string, string> = useParams();
  const isModuleSurveyEnabled = useSelector(checkModuleSurvey);
  const [surveyTask, setSurveyTask] = useState<IPopulatedSurveyTask>();
  const [isLoading, setIsLoading] = useState(false);
  const [ratings, setRatings] = useState<ISurveyRating[]>([]);

  const surveyTaskId = params.surveyTaskId;

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    const fetchData = async () => {
      if (isMounted && isModuleSurveyEnabled) {
        const result = await getSurveyTask(surveyTaskId);
        setSurveyTask(result?.data?.surveyTask);
        // !important
        // sort ratings according to questions order
        const sortedRatings = sortBy(result?.data?.surveyRatings, (rating) =>
          findIndex(
            result?.data?.surveyTask?.questions,
            (question: { id: string }) => question.id === rating.question,
          ),
        );

        setRatings(sortedRatings ?? []);
      }
      setIsLoading(false);
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [surveyTaskId, isModuleSurveyEnabled]);

  return { ratings, surveyTask, isLoading };
};

export { useFetchSurveyTask };
