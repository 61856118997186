import { GOAL_TYPES, GOAL_VIEWERS, GOAL_PROGRESS_TYPES, GOAL_STATUSES } from '@learned/constants';
import { IGoalCycle, IUser } from '@learned/types';
import isEmpty from 'lodash/isEmpty';

export default async ({
  currentUser,
  type,
  status,
  goalId,
  preSelectedUsersIds,
  deadline,
  goalCycles,
  teams,
}: {
  currentUser: IUser;
  type?: GOAL_TYPES;
  status?: GOAL_STATUSES;
  goalId?: string;
  preSelectedUsersIds?: string[];
  deadline?: Date;
  goalCycles?: IGoalCycle[];
  teams?: string[];
}) => {
  return {
    ...(goalId && { id: goalId }), // need this when user change type in update goal
    status: status || GOAL_STATUSES.DRAFT,
    type,
    teams,
    owners: !isEmpty(preSelectedUsersIds)
      ? preSelectedUsersIds
      : type === GOAL_TYPES.TEAM
      ? []
      : [currentUser.id],
    creators: [],
    viewers:
      type === GOAL_TYPES.COMPANY
        ? GOAL_VIEWERS.PUBLIC
        : type === GOAL_TYPES.TEAM
        ? GOAL_VIEWERS.TEAMS
        : GOAL_VIEWERS.OWNER_AND_COACHES,
    name: '',
    description: '',
    progressType: GOAL_PROGRESS_TYPES.AVG,
    isChildProgress: true,
    progressDetails: {
      min: 0,
      max: 100,
      currency: null,
    },
    activities: [],
    deadline: deadline || null,
    goalCycles: goalCycles || [],
    parent: null,
    isCoachesOfOwnerCanEdit: true,
    isOwnerCanEdit: true,
    skills: [],
    errors: {},
    isCurrentGoalAddActivities: false,
  };
};
