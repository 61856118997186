import styled, { css } from 'styled-components';

import { SearchField } from '~/components/Text';

import { COLORS } from '~/styles';

export const StyledSearchFieldWrapper = styled.div`
  position: relative;
`;

export const StyledSearchField = styled(SearchField)`
  height: 32px;
  margin-right: 12px;
`;

export const Label = styled.label`
  color: ${COLORS.SUBTEXT};
  margin-top: 22px;
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
`;

export const SuggestedSkillsWrapper = styled.div`
  position: absolute;
  top: 32px;
  left: 20px;
  z-index: 1000;
  border-radius: 6px;
  box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
  border: solid 1px ${COLORS.BORDERS};
  background-color: ${COLORS.WHITE};
  max-height: 200px;
  min-width: 226px;
  overflow-y: auto;

  & .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 16px;
    width: 100%;
    height: 40px;
    color: ${COLORS.TEXT_MAIN};
    background-color: ${COLORS.WHITE};
    box-sizing: border-box;
    font-size: 12px;
    gap: 10px;

    & .icon {
      visibility: hidden;
    }

    &:hover {
      color: ${COLORS.COMPANY};
      cursor: pointer;
      background-color: ${COLORS.BG_LIST};

      & .icon {
        visibility: visible;
      }
    }
  }

  & .notFound {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 16px;
    width: 100%;
    height: 40px;
    color: ${COLORS.TEXT_MAIN};
    background-color: ${COLORS.WHITE};
    box-sizing: border-box;
    font-size: 12px;
    gap: 10px;
    user-select: none;
  }
`;

export const RecommendedSkillsWrapper = styled.div<{ showAll?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  ${({ showAll }) =>
    showAll
      ? css`
          overflow-y: auto;
        `
      : css`
          overflow-y: hidden;
          max-height: 82px;
        `}
  margin-top: 10px;

  & .item {
    display: flex;
    padding: 8px 17px;
    color: ${COLORS.TEXT_MAIN};
    height: 36px;
    border-radius: 18px;
    font-size: 14px;
    letter-spacing: -0.16px;
    background-color: ${COLORS.BG_LIST};

    &:hover {
      color: ${COLORS.COMPANY};
      cursor: pointer;
      background-color: ${COLORS.HOVER};
    }
  }
`;

export const RecommendedSkillsActions = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ShowMoreButton = styled.button`
  margin: 13px 7px 0 3px;
  font-size: 12px;
  text-align: center;
  color: ${COLORS.COMPANY};
  cursor: pointer;

  background-color: ${COLORS.WHITE};
`;

export const Title = styled.span`
  font-size: 26px;
  line-height: 1;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
`;

export const Subtitle = styled.span`
  font-size: 16px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 20px;
  border-bottom: solid 1px ${COLORS.BORDERS};
  justify-content: space-between;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding-bottom: 8px;
`;

export const HeaderActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
