export enum NEXT_STEP_TYPES {
  CONVERSATION = 'conversation',
  REVIEW = 'review',
}

export enum NEXT_STEP_SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
  CREATED_NEW_OLD = 'CREATED_NEW_OLD',
  CREATED_OLD_NEW = 'CREATED_OLD_NEW',
}
