import isEmpty from 'lodash/isEmpty';

import { REQUEST_STATUSES } from '~/constants';

/**
 *
 * @param ratings
 * @param requests
 * @returns {number}
 */

export default function (ratings, requests = []) {
  if (isEmpty(ratings)) {
    return 0;
  }

  let clearRatings = 0; // ratings without skipped status
  const result =
    ratings.reduce((acc, el) => {
      if (el.status !== 'skipped' && el.rate !== 0 && isShowRate(el, requests)) {
        clearRatings += 1;
        return acc + el.rate;
      }
      return acc;
    }, 0) / clearRatings;
  return result || 0;
}

export const isShowRate = (rate, requests) => {
  if (!isEmpty(requests)) {
    return requests.some(
      (request) =>
        ((request.toUser && request.toUser === rate.user) ||
          (request.toEmail && request.toEmail === rate.email)) &&
        request.status === REQUEST_STATUSES.SHARED.key,
    );
  } else {
    return true; // for rateYourself activity
  }
};
