import React, { Component } from 'react';

import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StickyContainer } from 'react-sticky';
import styled from 'styled-components';

import Header from '~/components/HeaderLogoSingle';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
`;

const Main = styled(StickyContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: red;
  overflow: auto;
  position: relative;
  background: ${COLORS.BG_PAGE};
`;

class LoginLayout extends Component {
  static propTypes = {
    hideHeader: PropTypes.bool,
  };

  static defaultProps = {
    hideHeader: false,
  };

  render() {
    const { hideHeader } = this.props;
    return (
      <Wrapper>
        {hideHeader && <Header />}
        <Main id="main-content">{this.props.children}</Main>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    app: state.app,
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(LoginLayout)));
