import React, { ChangeEvent, useEffect, useState } from 'react';

import { API_RETURN_FIELDS, CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { IPath, IPopulatedUserPath, ITeam, IUser, IUserActivity, IUserPath } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  AutocompleteFilterSortBy,
  AutocompleteFilterTeams,
} from '~/components/AutocompleteFilters';
import Button from '~/components/Button';
import CheckBox from '~/components/CheckBox';
import IconMenu from '~/components/IconMenu';
import TrashIcon from '~/components/Icons/Trash';
import UsersIcon from '~/components/Icons/Users';
import Modal from '~/components/Modal';
import { StatisticCard } from '~/components/Modals/ShowParticipantsActivityModal/components/StatisticCard';
import PaginationBar from '~/components/PaginationBar';
import SelectUsersModal from '~/components/SelectUsersModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';
import { SearchField } from '~/components/Text';
import Avatar from '~/components/UI/Avatar';

import usersIcon from '~/assets/a-users-icn.svg';
import emptyState from '~/assets/empty-state.webp';
import exportCsvIcon from '~/assets/export-csv.svg';
import rocketIcon from '~/assets/images/rocket.png';
import trashIcon from '~/assets/item-menu-icons/delete-menu.svg';

import { SORT_BY } from '~/constants';
import { ACTIVITY_STATUS_STYLES } from '~/constants/activities';
import { checkModuleReviewQuickSelect, getTeams, getUser } from '~/selectors/baseGetters';
import getAllUsers from '~/selectors/getAllUsers';
import getCoachUsers from '~/selectors/getCoachUsers';
import {
  addActivityOwners,
  addActivityUsers,
  deleteActivityOwners,
  deleteActivityUsers,
} from '~/services/activities';
import {
  getPath,
  addPathOwners,
  addPathUsers,
  deletePathOwners,
  deletePathUsers,
  downloadLearningPathCSV,
  getSubLibraryPaths,
} from '~/services/paths';
import { getSubLibraryActivities } from '~/services/userActivities';
import { COLOR_PALETTE, COLOR_SET, COLORS } from '~/styles';
import convertToTimeString from '~/utils/convertToTimeString';
import getUserFullName from '~/utils/getUserFullName';

import { ConfirmationModal } from '../ConfirmationModal';

const Statistics = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  margin-bottom: 28px;
  padding: 0 8px;
`;

const FooterActions = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 18px;
`;

const RowHeader = styled.tr<{ color: string }>`
  position: relative;
  background-color: ${(props) => props.color};
`;

const AbsoluteDeleteContainer = styled.div`
  position: absolute;
  right: 30px;
  top: 12px;
`;

const RowTD = styled.td<{ width?: number; isActive?: boolean }>`
  text-transform: uppercase;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  ${(props) => props.width && `width: ${props.width};`}
  color: ${(props) => (props.isActive ? COLOR_PALETTE.WHITE : COLOR_PALETTE.DARK_GRAY)};
`;

const DeleteContainer = styled.div`
  display: flex;
  text-transform: none;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.WHITE};
  cursor: pointer;
`;

const StyledCheckBox = styled(CheckBox)`
  margin: 0 22px;
`;

const TableCheckBoxHeader = styled.td`
  width: 64px;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

const Row = styled.tr`
  border: 1px solid #f3f7fd;
  vertical-align: center;
`;

const TableCheckBox = styled.td`
  height: 64px;
  width: 16px;
  padding: 0 24px;
`;

const ParticipantsTD = styled.td`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  color: ${COLOR_SET.TOTAL_BLACK};
`;

const ParticipantContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const FilterRow = styled.div`
  padding: 12px 24px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const FilterContainer = styled.div`
  border: 1px solid #f3f7fd;
`;

const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  width: 100%;
`;

const StyledAutocompleteFilterTeams = styled(AutocompleteFilterTeams)`
  height: 32px;
`;

const StyledAutocompleteFilterSortBy = styled(AutocompleteFilterSortBy)`
  height: 32px;
`;

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  padding: 0px 9px 0px 19px;
  width: 144px;
  height: 32px;
  border-radius: 160px;
  box-shadow: 0 2px 4px 0 rgba(145, 157, 165, 0.12);
  background-color: #fff;
  cursor: pointer;
`;

const ExportCsvText = styled.div`
  font-size: 12px;
  color: var(--company-color);
  white-space: nowrap;
`;

const PlaceholderWrapper = styled.div`
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

interface IShowParticipantsActivityModal {
  onClose: (editors: string[], isEditor: boolean) => void;
  isEditor: boolean;
  activity?: IUserActivity;
  path?: IPath | IUserPath;
}

const ShowParticipantsActivityModal = ({
  onClose,
  isEditor,
  activity,
  path,
}: IShowParticipantsActivityModal) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedSort, setSelectedSort] = useState<{ id: string }[]>([SORT_BY.A_Z]);
  const { i18n } = useLingui();
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IUser['id'][]>([]);
  const [search, setSearch] = useState<string>('');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [isAddParticipantsShowing, setIsAddParticipantsShowing] = useState(false);
  const [userActivities, setUserActivities] = useState<IUserActivity[]>([]);
  const [userPaths, setUserPaths] = useState<IUserPath[]>([]);
  const [avgProgress, setAvgProgress] = useState<number>(0);
  const [numberOfParticipants, setNumberOfParticipants] = useState<number>(0);
  const user = useSelector(getUser);
  const coachUsers = useSelector(getCoachUsers);
  const teams = useSelector(getTeams);
  const isQuickSelectModule = useSelector(checkModuleReviewQuickSelect);
  const usersInCompany: { [key: string]: IUser } = useSelector(getAllUsers);
  const coachUserIds = coachUsers.map((i) => i.id);
  const [editors, setEditors] = useState<Array<string>>([]);
  const [selectedTeams, setSelectedTeams] = useState<Array<ITeam>>([]);
  const [isRemoveParticipantsModalVisible, setIsRemoveParticipantsModalVisible] = useState(false);
  const [removeParticipantsIds, setRemoveParticipantsIds] = useState<IUser['id'][]>([]);
  const [forceUpdateCounter, setForceUpdateCounter] = useState(0);

  // use this method to force update, for instance after item is deleted from library and
  const onForceFetch = () => setForceUpdateCounter(forceUpdateCounter + 1);

  const handleChange = ({ limit }: { limit: number }) => {
    setItemsPerPage(limit);
    setPaginationIndex(0);
  };

  const changeSort = (newSelectedSort: any[]) => {
    setSelectedSort(newSelectedSort.length ? [newSelectedSort.pop()] : []);
  };

  const getFetchedPathsOrActivities = ({
    userPaths,
    usersToRemove,
    userActivities,
  }: {
    userPaths?: IUserPath[];
    userActivities?: IUserActivity[];
    usersToRemove?: string[];
  }) => {
    const relevantUserIds = coachUsers.map((i) => i.id);
    if (!userPaths && !userActivities) {
      return {
        fetched: [],
        sumProgress: 0,
      };
    }
    // One of them will always exist
    // @ts-ignore
    const fetched = (userPaths || userActivities)
      .map((i: any) => {
        const userId = userPaths ? i.createdFor : i.owners[0];
        return usersInCompany[userId] &&
          (usersToRemove?.length ? usersToRemove.includes(userId) : true) &&
          (user.isAdmin ? true : relevantUserIds.includes(userId))
          ? i
          : undefined;
      })
      .filter((i) => i);
    let sumProgress = 0;
    fetched.forEach((a: any) => {
      if (a.progress && a.progress > 0) {
        sumProgress += a.progress;
      }
    });
    return {
      fetched,
      sumProgress,
    };
  };

  useEffect(() => {
    let loaded = true;
    const fetch = async () => {
      if (activity) {
        const {
          data: { userActivities },
        } = await getSubLibraryActivities(activity.originalActivity || activity.id);
        if (loaded) {
          const { fetched: fetchedActivities, sumProgress } = getFetchedPathsOrActivities({
            userActivities: Object.values(userActivities),
          });
          if (!isEditor) {
            setAvgProgress(sumProgress / fetchedActivities.length);
            setNumberOfParticipants(fetchedActivities.length);
          }
          setEditors(
            activity
              ? isEditor
                ? forceUpdateCounter
                  ? editors
                  : activity.owners
                : fetchedActivities.map((i) => i.owners[0])
              : [],
          );
          setUserActivities(Object.values(userActivities));
          setIsLoading(false);
        }
      }
      if (path) {
        const c = await getSubLibraryPaths((path as IUserPath).originalPath || path.id);
        const {
          data: { userPaths },
        } = c;
        if (loaded) {
          const { fetched: fetchedPaths, sumProgress } = getFetchedPathsOrActivities({
            userPaths: Object.values(userPaths),
          });
          if (!isEditor) {
            setAvgProgress(sumProgress / fetchedPaths.length);
            setNumberOfParticipants(fetchedPaths.length);
          }
          if (isEditor) {
            const { data } = await getPath(path.id, {}, { isOwnUserPath: true });

            const newPath = data[API_RETURN_FIELDS.PATH];

            setEditors((newPath as IPath).owners);
          } else {
            setEditors(fetchedPaths.map((i) => i.createdFor));
          }
          setUserPaths(Object.values(userPaths));
          setIsLoading(false);
        }
      }
    };
    fetch();
    return () => {
      loaded = false;
    };
    // eslint-disable-next-line
  }, [forceUpdateCounter]);

  useEffect(() => {
    setUsers(
      isEditor || (user as IUser).isAdmin
        ? Object.values(usersInCompany)
        : (user as IUser).isCoach
        ? coachUsers
        : [],
    );
  }, [isEditor, usersInCompany, coachUsers, user]);

  const updateEditors = async (usersToAdd: Array<IUser>) => {
    setIsLoading(true);
    if (activity) {
      await addActivityOwners(
        activity.id,
        usersToAdd.map((i) => i.id),
      );
    } else if (path) {
      await addPathOwners(
        path.id,
        usersToAdd.map((i) => i.id),
      );
    }
    setEditors([...editors, ...usersToAdd.map((i) => i.id)]);
    setIsAddParticipantsShowing(false);
    setIsLoading(false);
  };

  const updateParticipants = async (usersToAdd: Array<IUser>) => {
    setIsLoading(true);
    if (activity) {
      await addActivityUsers(activity.id, usersToAdd);
    } else if (path) {
      await addPathUsers(
        path.id,
        usersToAdd.map((i) => i.id),
      );
    }
    if (!isEditor) {
      const { fetched: fetchedPaths, sumProgress } = getFetchedPathsOrActivities(
        path ? { userPaths } : { userActivities },
      );
      setAvgProgress(sumProgress / (fetchedPaths.length + usersToAdd.length));
      setNumberOfParticipants(numberOfParticipants + usersToAdd.length);
    }
    setEditors([...editors, ...usersToAdd.map((i) => i.id)]);
    setIsAddParticipantsShowing(false);
    setNumberOfParticipants(numberOfParticipants + usersToAdd.length);
    onForceFetch();
    setIsLoading(false);
  };

  const removeEditors = async (usersToRemove: Array<string>) => {
    if (activity) {
      await deleteActivityOwners(activity.id, usersToRemove);
    } else if (path) {
      await deletePathOwners(path.id, usersToRemove);
    }
    setSelectedUsers([]);
    setEditors(editors.filter((u) => !usersToRemove.includes(u)));
    onForceFetch();
  };

  const removeParticipants = async (usersToRemove: Array<string>) => {
    if (activity) {
      await deleteActivityUsers(activity.id, usersToRemove);
    }
    if (path) {
      await deletePathUsers(path.id, usersToRemove);
    }
    if (!isEditor) {
      const { fetched: fetchedPaths, sumProgress } = getFetchedPathsOrActivities({
        ...(path ? { userPaths } : { userActivities }),
        usersToRemove,
      });
      setAvgProgress(sumProgress / fetchedPaths.length);
      setNumberOfParticipants(numberOfParticipants - usersToRemove.length);
    }
    setEditors(editors.filter((u) => !usersToRemove.includes(u)));
    setSelectedUsers([]);
    onForceFetch();
  };

  const renderHeader = () => {
    const activeHeader = selectedUsers.length;
    const checked =
      !Object.values(editors).find((editor) => {
        return !selectedUsers.includes(editor);
      }) && activeHeader;

    return (
      <RowHeader color={activeHeader ? 'var(--company-color)' : COLORS.BG_PAGE}>
        <TableCheckBoxHeader>
          <StyledCheckBox
            size="20px"
            checked={checked}
            onChange={() => {
              setSelectedUsers(checked ? [] : Object.values(editors));
            }}
            className={undefined}
            disabled={false}
            defaultColor={activeHeader ? COLOR_PALETTE.WHITE : undefined}
          />
        </TableCheckBoxHeader>
        <RowTD isActive={!!activeHeader}>
          {activeHeader
            ? i18n._(
                t`${
                  selectedUsers.filter((i) =>
                    user.isAdmin ? true : coachUsers.find((coachUser) => coachUser.id === i),
                  ).length
                } selected`,
              )
            : i18n._(isEditor ? t`Active editors` : t`Active participants`)}
        </RowTD>
        {isEditor ? (
          <>
            <RowTD width={180}>
              {activeHeader ? (
                <DeleteContainer onClick={() => removeEditors(selectedUsers)}>
                  <TrashIcon size={14} onClick={undefined} fill={COLOR_PALETTE.WHITE} />
                  {i18n._(t`Remove as editor`)}
                </DeleteContainer>
              ) : (
                i18n._(t`Team`)
              )}
            </RowTD>
            <RowTD width={40} />
          </>
        ) : (
          <>
            <RowTD width={168}>{!activeHeader && i18n._(t`Status`)}</RowTD>
            <RowTD width={168}>{!activeHeader && i18n._(t`Assigned`)}</RowTD>
            <RowTD width={92}>{!activeHeader && i18n._(t`Progress`)}</RowTD>
            <RowTD width={54} />
            {!!activeHeader && (
              <AbsoluteDeleteContainer>
                <DeleteContainer
                  onClick={() => {
                    setRemoveParticipantsIds(selectedUsers);
                    setIsRemoveParticipantsModalVisible(true);
                  }}
                >
                  <TrashIcon size={14} onClick={undefined} fill={COLOR_PALETTE.WHITE} />
                  {i18n._(t`Remove participants`)}
                </DeleteContainer>
              </AbsoluteDeleteContainer>
            )}
          </>
        )}
      </RowHeader>
    );
  };

  const renderRow = (rowUser: string) => {
    const currentUser = usersInCompany[rowUser];
    const userActivity = activity
      ? userActivities.find((i) => i.owners.includes(rowUser))
      : userPaths.find((i) => i.createdFor === rowUser);
    const checked = selectedUsers.includes(rowUser);
    const userTeams = Object.values(teams)
      .map((team: any) => (team.members.includes(rowUser) ? team.name : undefined))
      .filter((i) => i);

    const menuItems = [
      {
        order: 2,
        label: isEditor ? i18n._(t`Remove editor`) : i18n._(t`Remove participant`),
        action: () => {
          setRemoveParticipantsIds([rowUser]);
          setIsRemoveParticipantsModalVisible(true);
        },
        icon: trashIcon,
      },
    ];
    let title;
    if (userActivity) {
      title = path
        ? ACTIVITY_STATUS_STYLES[(userActivity as IPopulatedUserPath).status].title
        : ACTIVITY_STATUS_STYLES[(userActivity as IUserActivity).status].title;
    }
    return (
      <Row>
        <TableCheckBox>
          <CheckBox
            size="20px"
            checked={checked}
            onChange={() => {
              setSelectedUsers(
                checked
                  ? selectedUsers.filter((i: string) => i !== currentUser.id)
                  : [...selectedUsers, currentUser.id],
              );
            }}
            className={undefined}
            defaultColor={undefined}
          />
        </TableCheckBox>
        {isEditor ? (
          <>
            <td>{getUserFullName(currentUser)}</td>
            <td>
              {userTeams.length > 1
                ? `${userTeams[0]} + ${userTeams.length - 1}`
                : userTeams.length === 1
                ? userTeams[0]
                : i18n._(t`None`)}
            </td>
            <ParticipantsTD>
              <IconMenu items={menuItems} />
            </ParticipantsTD>
          </>
        ) : (
          <>
            <ParticipantsTD>
              <ParticipantContainer>
                {/* @ts-ignore */}
                <Avatar user={currentUser} size={32} />
                {getUserFullName(currentUser)}
              </ParticipantContainer>
            </ParticipantsTD>
            <ParticipantsTD>{title ? title(i18n) : i18n._(t`None`)}</ParticipantsTD>
            <ParticipantsTD>
              {convertToTimeString(userActivity?.meta?.createdDate || new Date())}
            </ParticipantsTD>
            <ParticipantsTD>{userActivity?.progress || 0}%</ParticipantsTD>
            <ParticipantsTD>
              <IconMenu items={menuItems} />
            </ParticipantsTD>
          </>
        )}
      </Row>
    );
  };

  const onPageChangeClick = (props: any) => {
    setPaginationIndex(props.index - 1);
  };

  const statistics = [
    {
      icon: (
        <SvgIcon
          url={usersIcon}
          width={'28px'}
          height={'26px'}
          isDefaultColor
          defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
        />
      ),
      title: i18n._('Participants total'),
      content: numberOfParticipants,
    },
    {
      icon: (
        <img
          src={rocketIcon}
          width={'28px'}
          height={'26px'}
          alt={i18n._('Average progress icon')}
        />
      ),
      title: i18n._('Average progress'),
      content: `${Math.round(avgProgress) || 0}%`,
    },
  ];

  const sortEditors = (a: any, b: any) => {
    const editorA = usersInCompany[a];
    const editorB = usersInCompany[b];
    if (!selectedSort.length) {
      return 1;
    }
    switch (selectedSort[0].id) {
      case 'a_z':
        return getUserFullName(editorA).localeCompare(getUserFullName(editorB), 'en', {
          sensitivity: 'base',
        });
      case 'z_a':
        return getUserFullName(editorB).localeCompare(getUserFullName(editorA), 'en', {
          sensitivity: 'base',
        });
    }
  };
  const editorsToShow = [...editors];

  const downloadCSV = async () => {
    if (path) {
      await downloadLearningPathCSV(path.id);
    }
  };

  const filteredItems = editorsToShow
    .filter((i) => {
      const user = usersInCompany[i];
      if (!user) {
        return false;
      }
      const searchHit =
        getUserFullName(user).toLowerCase().includes(search) ||
        user.email.toLowerCase().includes(search);
      return (
        searchHit &&
        (!isEmpty(selectedTeams)
          ? selectedTeams.find((t) => {
              return t.members.includes(i);
            })
          : true)
      );
    })
    .sort(sortEditors)
    .splice(paginationIndex * itemsPerPage, itemsPerPage)
    .map((i) => renderRow(i));

  return (
    <Modal
      title={i18n._(isEditor ? t`Manage editors` : t`Manage participants`)}
      onClose={() => onClose(editors, isEditor)}
      centerModal
      minWidth={1008}
      width={1008}
      contentStyles={{ padding: '32px', overflow: 'visible', zIndex: 11 }}
      headerStyles={{ paddingLeft: '40px' }}
      footerRight={
        <FooterActions>
          <Button
            styles={{
              border: 'none',
              width: '126px',
              height: '32px',
              padding: '8px 26px 7px 27px',
              borderRadius: '16px',
              textAlign: 'center',
              boxShadow: '0 2px 5px 0 rgba(145, 157, 165, 0.12)',
            }}
            label={i18n._(t`Close`)}
            type="white"
            onClick={() => onClose(editors, isEditor)}
          />
        </FooterActions>
      }
    >
      <ShowSpinnerIfLoading loading={isLoading}>
        {!isEditor && (
          <Statistics>
            {statistics.map((statistic) => {
              return (
                <StatisticCard key={statistic.title} icon={statistic.icon} title={statistic.title}>
                  {statistic.content}
                </StatisticCard>
              );
            })}
          </Statistics>
        )}
        <FilterContainer>
          <FilterRow>
            <SearchFieldWrapper
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value.toLowerCase())
              }
              value={search}
              placeholder={i18n._(t`Search for people`)}
              style={{
                borderRadius: '6px',
                fontSize: '14px',
                fontWeight: 600,
              }}
            />
            <StyledAutocompleteFilterTeams
              checkedList={selectedTeams}
              onChange={setSelectedTeams}
            />
            <StyledAutocompleteFilterSortBy
              checkedList={selectedSort}
              onChange={changeSort}
              activeFilters={['a_z', 'z_a']}
            />
            {user.isAdmin && !isEditor && path && (
              <StyledButton onClick={downloadCSV}>
                <SvgIcon
                  width="20px"
                  height="20px"
                  url={exportCsvIcon}
                  defaultColor="var(--company-color)"
                />
                <ExportCsvText>{i18n._(t`Export csv`)}</ExportCsvText>
              </StyledButton>
            )}
            {(isEditor ? user.isAdmin || !!isQuickSelectModule : true) && (
              <Button
                styles={{
                  minWidth: '170px',
                  height: '32px',
                  padding: '0px 17px 0px 23.7px',
                  borderRadius: '16px',
                  fontSize: '12px',
                }}
                disabled={selectedUsers.length}
                iconLeft={<UsersIcon size={20} />}
                label={i18n._(isEditor ? t`Add editors` : t`Add participants`)}
                type="primary"
                onClick={() => setIsAddParticipantsShowing(true)}
              />
            )}
          </FilterRow>
          {(activity
            ? !!(userActivities.length || editorsToShow.length)
            : !!(userPaths.length || editorsToShow.length)) && (
            <StyledTable>
              {renderHeader()}
              {filteredItems}
            </StyledTable>
          )}
          {!size(filteredItems) && (
            <PlaceholderWrapper>
              <img src={emptyState} alt={i18n._(t`No data found`)} width="216px" />
            </PlaceholderWrapper>
          )}
          <PaginationBar
            pagination={{ index: paginationIndex, limit: itemsPerPage, skip: 0 }}
            changePagination={onPageChangeClick}
            changePageSize={editors.length > itemsPerPage ? handleChange : undefined}
            count={editors.length}
            noTopBorder
            showCount
            noBorder
            noShadow
          />
        </FilterContainer>
        {isAddParticipantsShowing && (
          <SelectUsersModal
            title={i18n._(isEditor ? t`Add editors` : t`Add participants`)}
            items={Object.values(users).filter(
              (i) =>
                !editors.includes(i.id) &&
                (isEditor || (user.isAdmin ? true : coachUserIds.includes(i.id))),
            )}
            onModalClose={() => setIsAddParticipantsShowing(false)}
            onSubmit={isEditor ? updateEditors : updateParticipants}
            submitLabel={i18n._(isEditor ? t`Add editors` : t`Add participants`)}
            loading={isLoading}
            hideDisabledUsers
          />
        )}
      </ShowSpinnerIfLoading>
      {isRemoveParticipantsModalVisible && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.DELETE}
          description={i18n._(
            t`Are you sure you want to do this? This will delete all progress this activity has made on this activity and delete it from their profile.`,
          )}
          onSubmit={() => {
            if (isEditor) {
              removeEditors(removeParticipantsIds);
            } else {
              removeParticipants(removeParticipantsIds);
            }
          }}
          onClose={() => setIsRemoveParticipantsModalVisible(false)}
        />
      )}
    </Modal>
  );
};

export { ShowParticipantsActivityModal };
