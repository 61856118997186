import styled from 'styled-components';

const MessageContainer = styled.div`
  margin-top: 14px;
`;

const MessageItemContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: baseline;
  margin-top: 4px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
`;

export { MessageContainer, MessageItemContainer, Footer };
