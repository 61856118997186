import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ButtonSize, ButtonVariant, Button } from '~/components/Buttons';
import Modal from '~/components/Modal';

import { COLUMNS } from './columns';
import { Container, Footer, StyledTable } from './design';
import { JobProfileBasic } from './types';

type JobsModalProps = {
  closeModal?: () => void;
  jobs?: JobProfileBasic[];
  onRowClick?: (item: JobProfileBasic) => void;
};

const JobsModal = ({ closeModal, jobs = [], onRowClick }: JobsModalProps) => {
  const { i18n } = useLingui();
  return (
    <Modal
      showDivider={false}
      centerModal
      headerStyles={{ padding: '24px 26px 8px 26px', overflow: 'visible' }}
      contentStyles={{
        padding: '0 26px 24px 26px',
        overflow: 'visible',
      }}
      borderRadius={6}
      hideFooter
      onClose={closeModal}
      title={i18n._(t`Jobs`)}
      width={500}
    >
      <Container>
        <StyledTable data={jobs} columns={COLUMNS} onRowClick={(item) => onRowClick?.(item)} />
        <Footer>
          <Button
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
            onClick={closeModal}
            label={i18n._(t`Cancel`)}
          />
        </Footer>
      </Container>
    </Modal>
  );
};

export { JobsModal };
