export default {
  FORCE_SSO: {
    // error added in auth0 -> authPipeline -> Rules -> Force SSO for selected users
    NOT_ALLOW_CONNECTION: {
      key: 'force_sso_not_allow_connection',
      description: 'User has not permission to connect with current connection',
    },
    // error added in auth0 -> authPipeline -> Rules -> Force SSO for selected users
    ALLOWED_CONNECTIONS_UNDEFINED: {
      key: 'force_sso_allowed_connections_undefined',
      description: 'User sso enabled, but he does not have allowed connections',
    },
  },
};
