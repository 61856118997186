import React, { useState } from 'react';

import { ROLES, ProductName } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS, ICON_SIZES } from '~/components/Icon';
import { disableProductCheck } from '~/pages/RegisterWithCompany/components/utils';

import { Product } from './Product';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { useQueryURL } from '~/hooks/useQueryURL';
import { createCompany } from '~/services/companies';
import { COLORS } from '~/styles';

import {
  ButtonsWrapper,
  FormWrapper,
  Subtitle,
  Title,
  SectionWrapper,
  Description,
  LeftItems,
  ProductsWrapper,
  FreeTrial,
} from '../design';

import type { IRegisterWithCompanyForm, LocalProductName, ProductInfo } from '../types';
import type { UseFormReturn } from 'react-hook-form';

interface IProductSectionProps {
  formMethods: UseFormReturn<IRegisterWithCompanyForm>;
}

const ProductsSection = ({ formMethods }: IProductSectionProps) => {
  const { i18n } = useLingui();
  const { watch, setValue, handleSubmit } = formMethods;
  const loading = useBoolState(false);
  const { values } = useQueryURL({ keys: ['utm_campaign', 'utm_source', 'utm_medium'] });
  const products = watch('products'); // enabled products
  const [productsList, setProductsList] = useState<Record<LocalProductName, ProductInfo>>({
    [ProductName.JOB_MATRIX]: {
      name: i18n._(t`Job matrix`),
      description: i18n._(
        t`With Learned’s AI, you easily generate a skill-based job library based on market data. This enables object evaluations transparent career.`,
      ),
      link: '',
      icon: ICONS.JOB_MATRIX,
      color: '',
      isFree: true,
      isDescriptionVisible: true,
    },
    [ProductName.PERFORMANCE]: {
      name: i18n._(t`Performance Management`),
      description: i18n._(
        t`Conduct objective evaluations, make sure everyone knows how to develop during the year, feel valued and identify your high performers.`,
      ),
      link: 'https://www.learned.io/performance-management/',
      icon: ICONS.CONVERSATIONS,
      color: COLORS.PERFORMANCE,
      isDescriptionVisible: false,
    },
    [ProductName.CAREER]: {
      name: i18n._(t`Career Framework`),
      description: i18n._(
        t`With Learned’s AI, you easily generate a modern job library based on market data. This makes evaluations objective and career opportunities transparent.`,
      ),
      link: 'https://www.learned.io/carriere-framework/',
      icon: ICONS.CAREER,
      color: COLORS.CAREER,
      isDescriptionVisible: false,
    },
    [ProductName.ENGAGEMENT]: {
      name: i18n._(t`Engagement surveys`),
      description: i18n._(
        t`Uncover blindspots to increase employee engagement with science-based traditional and pulse surveys`,
      ),
      link: 'https://www.learned.io/engagement/',
      icon: ICONS.SURVEYS,
      color: COLORS.ENGAGEMENT,
      isDescriptionVisible: false,
    },
  });

  const history = useHistory();

  const handleCreateCompany = async (formData: IRegisterWithCompanyForm) => {
    try {
      const {
        name,
        industry,
        size,
        phone,
        email,
        password,
        lang,
        products,
        job,
        includeDemoContent,
      } = formData;

      loading.on();

      const createdCompany = await createCompany({
        name,
        industry,
        selectedJob: job,
        size,
        phone,
        email,
        password,
        lang,
        requestedProducts: products,
        includeDemoContent,
        // attr for HubSpot
        utm_campaign: values.utm_campaign,
        utm_source: values.utm_source,
        utm_medium: values.utm_medium,
        hutk: Cookies.get('hubspotutk'), // hubspotutk value creates by GoogleTagManager in cookies when user enter this page
      });

      if (createdCompany.id) {
        // eslint-ignore-next-line
        // @ts-ignore
        history.push(routes.DASHBOARD.build({ companyId: createdCompany.id, role: ROLES.USER }));
      }
    } catch (_e) {
      // error text from API with be in toast
    }
    loading.off();
  };

  const handleReadMore = (key: LocalProductName) =>
    setProductsList((productsList) => ({
      ...productsList,
      [key]: {
        ...productsList[key],
        isDescriptionVisible: !productsList[key]?.isDescriptionVisible,
      },
    }));
  const handleSwitch = (productName: LocalProductName, value: boolean) => {
    if (value) {
      const newProducts = [...products, productName];

      // apply side effects
      switchSideEffects(productName, value, newProducts);

      setValue('products', newProducts);
    } else {
      setValue(
        'products',
        watch('products').filter((product) => product !== productName),
      );
    }
  };

  const switchSideEffects = (
    productName: LocalProductName,
    value: boolean,
    newProducts: LocalProductName[],
  ) => {
    switch (productName) {
      // if career enabled -> auto-enable jobMatrix (if that is not enabled yet)
      case ProductName.CAREER:
        if (value) {
          !newProducts.includes(ProductName.JOB_MATRIX) && newProducts.push(ProductName.JOB_MATRIX);
        }
        break;
      default:
        break;
    }
  };

  return (
    <SectionWrapper>
      <LeftItems>
        <Subtitle marginBottom="-25px" textAlign="left">
          <Trans>Step 5/5</Trans>
        </Subtitle>
        <Title>
          <Trans>What do you want to do with Learned?</Trans>
        </Title>
        <Description>
          <Trans>No worries, you can change this later.</Trans>
        </Description>
      </LeftItems>
      <FormWrapper width="690px" padding="0 0 100px">
        <form onSubmit={handleSubmit(handleCreateCompany)}>
          <ProductsWrapper>
            {Object.entries(productsList).map(([key, product]) => {
              const isDisabled = disableProductCheck(key as LocalProductName, products);
              const productExtended = {
                ...product,
                onSwitch: (value: boolean) => handleSwitch(key as LocalProductName, value),
                onReadMore: () => handleReadMore(key as LocalProductName),
                isDisabled,
                isSelected: products.includes(key as LocalProductName),
                ...(key === ProductName.JOB_MATRIX &&
                  isDisabled && {
                    tooltip: i18n._(
                      t`Job matrix can only be disabled when the career product is disabled`,
                    ),
                  }),
              };

              return (
                <div key={key}>
                  <Product product={productExtended} />
                  {key === ProductName.JOB_MATRIX && (
                    <FreeTrial>
                      <Trans>14 days free trial</Trans>
                    </FreeTrial>
                  )}
                </div>
              );
            })}
          </ProductsWrapper>
          <ButtonsWrapper>
            <Button
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.NAVIGATION_PRIMARY}
              label={i18n._(t`Complete`)}
              isLoading={loading.value}
            />
            <Button
              className="back"
              label={i18n._(t`Back`)}
              type="button"
              variant={ButtonVariant.TEXT_PRIMARY}
              icon={ICONS.BACK}
              size={ButtonSize.MEDIUM}
              iconSize={ICON_SIZES.SMALL}
              onClick={() => setValue('step', 4)}
              isLoading={loading.value}
            />
          </ButtonsWrapper>
        </form>
      </FormWrapper>
    </SectionWrapper>
  );
};

export { ProductsSection };
