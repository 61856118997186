import { IMultiLangString } from '@learned/types';

export const removeEmptyValues = (
  value: Record<string, string | undefined | null>,
): IMultiLangString => {
  return Object.entries(value).reduce((acc, [key, value]) => {
    if (value === '' || value === null || value === undefined) {
      return acc;
    }
    return { ...acc, [key]: value };
  }, {});
};
