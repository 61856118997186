import React, { useCallback } from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import ReviewGoalPlanning from '~/components/GiveFeedback/components/Section/components/ReviewGoalPlanning';

import JobProfileQuestion from './components/JobProfileQuestion';
import ReviewCustomQuestion from './components/ReviewCustomQuestion';
import { ReviewGoalsQuestion } from './components/ReviewGoalsQuestion';
import ReviewSkillQuestion from './components/ReviewSkillQuestion';
import SectionCard from './components/SectionCard';

import { GOAL_TYPES, RATING_TYPES, REVIEW_QUESTIONS_TABS } from '~/constants';
import { REVIEW_SECTION_THEMES_STYLES } from '~/constants/reviews';
import { useScrollEffect } from '~/hooks/useScrollEffect';
import { getCurrentRatings, getCurrentReview } from '~/selectors/baseGetters';

import { getErrorsCount, sortQuestionsByCategories, validateRating, setRef } from '../../utils';

const ScrollElement = styled.div`
  scroll-margin-top: 110px;
`;

const getSectionProviderText = (ratingType, templateSection, forUserName) => {
  if (!templateSection) {
    return '';
  }
  const userName = ratingType === RATING_TYPES.SELF_RATING ? <Trans>You</Trans> : forUserName;
  const coachesName = !templateSection.isCoachesReview ? (
    ''
  ) : ratingType === RATING_TYPES.SELF_RATING ? (
    <Trans>your coach(es)</Trans>
  ) : (
    <Trans>his/her coach(es)</Trans>
  );
  const commonText = <Trans>asked to provide input for these questions</Trans>;
  const verb =
    (templateSection.isCoachesReview &&
      !templateSection.isSelfReview &&
      !templateSection.isUsersReview) ||
    (!templateSection.isCoachesReview &&
      templateSection.isSelfReview &&
      !templateSection.isUsersReview &&
      ratingType !== RATING_TYPES.SELF_RATING) ||
    (!templateSection.isCoachesReview &&
      !templateSection.isSelfReview &&
      templateSection.isUsersReview) ? (
      <Trans>is</Trans>
    ) : (
      <Trans>are</Trans>
    );

  if (
    templateSection.isCoachesReview &&
    templateSection.isSelfReview &&
    templateSection.isUsersReview
  ) {
    return (
      <>
        {userName}
        {', '}
        <Trans>the nominated peers and</Trans> {coachesName} {verb} {commonText}
      </>
    );
  } else if (templateSection.isCoachesReview && templateSection.isSelfReview) {
    return (
      <>
        {userName} <Trans>and</Trans> {coachesName} {verb} {commonText}
      </>
    );
  } else if (templateSection.isSelfReview && templateSection.isUsersReview) {
    return (
      <>
        {userName} <Trans>and the nominated peers</Trans> {verb} {commonText}
      </>
    );
  } else if (templateSection.isCoachesReview && templateSection.isUsersReview) {
    return (
      <>
        <Trans>The nominated peers and</Trans> {coachesName} {verb} {commonText}
      </>
    );
  } else if (templateSection.isCoachesReview) {
    return (
      <>
        {coachesName} {verb} {commonText}
      </>
    );
  } else if (templateSection.isUsersReview) {
    return (
      <>
        <Trans>The nominated peers</Trans> {verb} {commonText}
      </>
    );
  } else if (templateSection.isSelfReview) {
    return (
      <>
        {userName} {verb} {commonText}
      </>
    );
  } else {
    return '';
  }
};

const Section = React.forwardRef(
  (
    {
      sectionCount,
      sectionIndex,
      ratingType,
      requestedBy,
      isActive,
      changeActiveSection,
      activeQuestionStep,
      changeActiveQuestionStep,
      showErrors,
      errorQuestion,
      skillCategories,
      skillLevels = {},
      goalCyclesLearningPlan,
      goalCyclesBusinessPlan,
      goalCyclesLearningEval,
      goalCyclesBusinessEval,
      saveAsDraft,
      callBeforeRedirect,
      onRatingChange,
      fetchedRatings = [],
      isReviewCoach,
      forUserName,
      section,
      isNotSharedFeedback,
      isReadOnly,
      viewerId,
    },
    itemsRefs,
  ) => {
    const { i18n } = useLingui();
    const { ref, scrollToRef } = useScrollEffect();
    const ratings = useSelector(getCurrentRatings);
    const review = useSelector(getCurrentReview);
    const changeQuestionStep = useCallback(
      (questionStep) => changeActiveQuestionStep(sectionIndex, questionStep),
      [changeActiveQuestionStep, sectionIndex],
    );

    let sortedQuestions = [];
    if (skillCategories) {
      sortedQuestions = sortQuestionsByCategories(
        skillCategories,
        section.questions,
        sortedQuestions,
      );
    } else {
      sortedQuestions = section.questions;
    }
    // filter out goal eval questions without goals and an average question
    sortedQuestions = sortedQuestions.filter(
      ({ question }) =>
        !(
          [QUESTION_TYPES.GOAL_BUSINESS_EVAL, QUESTION_TYPES.GOAL_LEARNING_EVAL].includes(
            question?.type,
          ) &&
          !question?.isAverageQuestionEnabled &&
          isEmpty(question?.goals)
        ),
    );

    const rolesQuestions = sortedQuestions.filter(
      ({ question } = {}) => question?.type === QUESTION_TYPES.JOB_PROFILE,
    );

    let tabs = {};
    const templateSection = section;

    if (isReviewCoach) {
      tabs = { ...REVIEW_QUESTIONS_TABS };

      // If there is only one coach, don't show the coaches tab
      if ((review.coaches || []).length <= 1) {
        delete tabs.COACH_REVIEW;
      }

      // If the section has any disabled review option, don't show the corresponding tabs
      if (templateSection) {
        if (!templateSection.isCoachesReview) {
          delete tabs.COACH_REVIEW;
        }
        if (!templateSection.isSelfReview) {
          delete tabs.SELF_REVIEW;
        }
        if (!templateSection.isUsersReview) {
          delete tabs.PEER_REVIEW;
        }
      }
    }
    const sectionTheme = Object.values(REVIEW_SECTION_THEMES_STYLES).find(
      (t) => t.key === section.theme,
    );
    const sectionProviderText = isActive
      ? getSectionProviderText(ratingType, templateSection, forUserName)
      : '';

    const roles =
      !isEmpty(review.jobProfiles) && !isEmpty(rolesQuestions)
        ? review.jobProfiles
        : !isEmpty(rolesQuestions)
        ? [
            {
              id: '0',
              skills: rolesQuestions?.map(({ skill } = {}) => skill.id),
            },
          ]
        : [];

    const indexTotal = sortedQuestions
      .filter((q) => q.question.type !== QUESTION_TYPES.JOB_PROFILE)
      .concat(roles).length;

    let isRoleQuestionsRendered = false;

    const handleChangeActiveSection = (sectionIndex) => {
      changeActiveSection(sectionIndex);

      // Only scroll to section when the section is not active already
      if (!isActive) {
        scrollToRef();
      }
    };

    return (
      <ScrollElement ref={ref}>
        <SectionCard
          sectionCount={sectionCount}
          title={section.name}
          description={section.description}
          sectionIndex={sectionIndex}
          sectionProviderText={sectionProviderText}
          theme={sectionTheme}
          hideFooter
          changeActiveSection={handleChangeActiveSection}
          errorsCount={showErrors ? getErrorsCount(ratings, section.questions) : 0}
          open={isActive}
        />
        {isActive &&
          !isEmpty(sortedQuestions) &&
          sortedQuestions.map(({ question, skill }, questionIndex) => {
            const indexCurrent = isRoleQuestionsRendered
              ? questionIndex + size(roles) - size(rolesQuestions)
              : questionIndex;

            const isOpenDefault =
              (sectionIndex === 0 && questionIndex === 0) || // first question in first section open by default
              (activeQuestionStep === null && questionIndex === 0); // first question in active section be default
            const isOpenForce = isActive && errorQuestion === questionIndex;

            // render all roles questions
            if (
              !isRoleQuestionsRendered &&
              question.type === QUESTION_TYPES.JOB_PROFILE &&
              !isEmpty(rolesQuestions)
            ) {
              isRoleQuestionsRendered = true; // render roles questions only ones
              return roles.map((jobProfile, questionRoleIndex) => {
                const isOpenForce = isActive && errorQuestion === questionRoleIndex;
                return (
                  <div
                    key={jobProfile.id}
                    ref={(el) =>
                      setRef({ itemsRefs, el, sectionIndex, questionIndex: questionRoleIndex })
                    }
                  >
                    <JobProfileQuestion
                      key={jobProfile.id}
                      jobProfile={jobProfile}
                      questions={rolesQuestions}
                      forUser={requestedBy}
                      ratingType={ratingType}
                      ratings={ratings}
                      review={review}
                      tabs={tabs}
                      fetchedRatings={fetchedRatings}
                      changeQuestionStep={changeQuestionStep}
                      showErrors={showErrors}
                      skillLevels={skillLevels}
                      onRatingChange={onRatingChange}
                      indexCurrent={indexCurrent + questionRoleIndex}
                      indexTotal={indexTotal}
                      isOpenDefault={isOpenDefault}
                      isOpenForce={isOpenForce}
                      isReadOnly={isReadOnly}
                    />
                  </div>
                );
              });
            }

            switch (question.type) {
              case QUESTION_TYPES.CUSTOM: {
                const rating = ratings[question.id] || {};
                const typeRatings = fetchedRatings.filter(
                  (rating) => rating.question === question.id,
                );
                return (
                  <div
                    key={question.id}
                    ref={(el) =>
                      setRef({ itemsRefs, el, sectionIndex, questionIndex: indexCurrent })
                    }
                  >
                    <ReviewCustomQuestion
                      question={question}
                      rating={rating}
                      forUser={requestedBy}
                      ratingType={ratingType}
                      typeRatings={typeRatings}
                      isActive={activeQuestionStep === question.id}
                      onQuestionClick={changeQuestionStep}
                      isError={showErrors && validateRating(rating)}
                      onChange={onRatingChange}
                      tabs={tabs}
                      indexCurrent={indexCurrent}
                      indexTotal={indexTotal}
                      isOpenDefault={isOpenDefault}
                      isOpenForce={isOpenForce}
                      isReadOnly={isReadOnly}
                    />
                  </div>
                );
              }
              case QUESTION_TYPES.SKILL: {
                const rating = ratings[skill.id] || {};
                const typeRatings = fetchedRatings.filter((rating) => rating.skill === skill.id);
                return (
                  <div
                    key={skill.id}
                    ref={(el) =>
                      setRef({ itemsRefs, el, sectionIndex, questionIndex: indexCurrent })
                    }
                  >
                    <ReviewSkillQuestion
                      question={question}
                      skill={skill}
                      forUser={requestedBy}
                      rating={rating}
                      ratingType={ratingType}
                      typeRatings={typeRatings}
                      isActive={activeQuestionStep === skill.id}
                      onQuestionClick={changeQuestionStep}
                      isError={showErrors && validateRating(rating)}
                      expectedLevel={skillLevels[skill.id]}
                      skillCategories={skillCategories}
                      onChange={onRatingChange}
                      tabs={tabs}
                      indexCurrent={indexCurrent}
                      indexTotal={indexTotal}
                      isOpenDefault={isOpenDefault}
                      isOpenForce={isOpenForce}
                      isReadOnly={isReadOnly}
                    />
                  </div>
                );
              }
              case QUESTION_TYPES.GOAL_BUSINESS_EVAL:
              case QUESTION_TYPES.GOAL_LEARNING_EVAL: {
                const isLearning = question.type === QUESTION_TYPES.GOAL_LEARNING_EVAL;
                const cycle = isLearning ? goalCyclesLearningEval[0] : goalCyclesBusinessEval[0];
                return cycle ? (
                  <div
                    key={question.type}
                    ref={(el) =>
                      setRef({ itemsRefs, el, sectionIndex, questionIndex: indexCurrent })
                    }
                  >
                    <ReviewGoalsQuestion
                      ratings={ratings}
                      fetchedRatings={fetchedRatings}
                      question={question}
                      forUser={requestedBy}
                      ratingType={ratingType}
                      activeQuestionStep={activeQuestionStep}
                      showErrors={showErrors}
                      onQuestionClick={changeQuestionStep}
                      onChange={onRatingChange}
                      tabs={tabs}
                      indexCurrent={indexCurrent}
                      indexTotal={indexTotal}
                      isOpenDefault={isOpenDefault}
                      isOpenForce={isOpenForce}
                      isReadOnly={isReadOnly}
                      isSelf
                      cycle={cycle}
                      review={review}
                      viewerId={viewerId}
                    />
                  </div>
                ) : undefined;
              }
              case QUESTION_TYPES.GOAL_LEARNING_PLAN:
              case QUESTION_TYPES.GOAL_BUSINESS_PLAN: {
                const isLearning = question.type === QUESTION_TYPES.GOAL_LEARNING_PLAN;
                const cycle = isLearning ? goalCyclesLearningPlan[0] : goalCyclesBusinessPlan[0];
                return cycle ? (
                  <div
                    key={question.type}
                    ref={(el) =>
                      setRef({ itemsRefs, el, sectionIndex, questionIndex: indexCurrent })
                    }
                  >
                    <ReviewGoalPlanning
                      i18n={i18n}
                      title={question.name}
                      description={question.description}
                      goalTitle={cycle.name}
                      goalCycle={cycle.id}
                      forUser={requestedBy}
                      isNotSharedFeedback={isNotSharedFeedback}
                      type={isLearning ? GOAL_TYPES.PERSONAL.key : GOAL_TYPES.BUSINESS.key}
                      typeLabel={
                        isLearning
                          ? i18n._(t`LearningKeyForPlanning`)
                          : i18n._(t`BusinessKeyForPlanning`)
                      }
                      isLearning={isLearning}
                      saveAsDraft={saveAsDraft}
                      callBeforeRedirect={callBeforeRedirect}
                      isCoach={ratingType === RATING_TYPES.COACH_RATING}
                      review={review}
                      indexCurrent={indexCurrent}
                      indexTotal={indexTotal}
                      isOpenDefault={isOpenDefault}
                      isOpenForce={isOpenForce}
                      isReadOnly={isReadOnly}
                    />
                  </div>
                ) : undefined;
              }
              default:
                return null;
            }
          })}
      </ScrollElement>
    );
  },
);

Section.propTypes = {
  onRatingChange: PropTypes.func,
};

Section.defaultProps = {
  onRatingChange: () => {},
  changeActiveSection: () => {},
  changeActiveQuestionStep: () => {},
};

export default React.memo(Section);
