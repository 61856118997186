import React, { useMemo } from 'react';

import { Trans } from '@lingui/macro';

import { Button, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { getGradient } from '~/pages/ReviewGiveFeedback/utils';

import { RatingItem } from './design';

import { isNotNil } from '~/utils/typePredicates';

import { ViewMode } from '.';

import type { IReviewRating } from '@learned/types';

function AverageRating({
  ratings,
  total,
  onEdit,
  viewMode,
}: {
  ratings: IReviewRating[];
  total: number;
  onEdit?: () => void;
  viewMode: ViewMode;
}) {
  const isRated = ratings.length > 0;
  const isNA = ratings.length === 1 && ratings[0].answer === null;

  // not null answers
  const validAnswers = ratings?.filter((item) => isNotNil(item.answer)) || [];
  const validOldAnswers = ratings?.filter((item) => isNotNil(item.oldAnswer)) || [];

  const average = isRated
    ? validAnswers.reduce((acc, rating) => acc + (rating.answer as number), 0) / validAnswers.length
    : 0;

  const oldAverage = isRated
    ? validOldAnswers.reduce((acc, rating) => acc + (rating.oldAnswer as number), 0) /
      validOldAnswers.length
    : 0;
  const oldExpectedLevel = ratings?.[0]?.oldExpectedLevel || total;
  const oldResult = oldAverage / oldExpectedLevel;
  const oldResultByPercentage = (oldResult * 100).toFixed(0);

  const gradient = useMemo(() => {
    if (viewMode === ViewMode.RATING_SCALE) {
      return average && average >= 0 ? getGradient(average as number, oldExpectedLevel) : undefined;
    } else {
      return oldAverage && oldAverage >= 0
        ? getGradient(oldAverage as number, oldExpectedLevel)
        : undefined;
    }
  }, [average, oldExpectedLevel, oldAverage, viewMode]);

  return (
    <RatingItem gradient={gradient}>
      {isNA ? (
        <Trans>N/A</Trans>
      ) : (
        <>
          {viewMode === ViewMode.RATING_SCALE ? (
            <>
              {isRated
                ? average.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                  })
                : ' '}
            </>
          ) : (
            <>{isRated ? `${oldResultByPercentage}%` : ' '}</>
          )}
        </>
      )}
      {onEdit && ratings.length === 1 && (
        <Button variant={ButtonVariant.ICON} icon={ICONS.EDIT_PENCIL} onClick={() => onEdit?.()} />
      )}
    </RatingItem>
  );
}

export { AverageRating };
