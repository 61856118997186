import {
  ACTIVITY_PROGRESS_TYPES,
  CURRENCY,
  GOAL_PROGRESS_TYPES,
  GOAL_STATUSES,
  GOAL_STATUSES_NEW,
  GOAL_TYPES,
  SYMBOLS,
  VIRTUAL_GOAL_TYPES,
} from '@learned/constants';
import { t } from '@lingui/macro';

import { ICONS } from '~/components/Icon';

import BusinessIcon from '~/assets/goal_business_icon.svg';
import LearnIcon from '~/assets/goal_learning_icon.svg';
import CompanyIcon from '~/assets/goal_organisation_icon.svg';
import TeamIcon from '~/assets/goal_team_icon.svg';

import { COLORS, COLOR_PALETTE, COLOR_SET, COLOR_UNIT } from '~/styles';

import type { I18n } from '@lingui/core';

/**
 * @deprecated Should not be used anymore
 */

export const GOAL_STYLES = {
  [GOAL_TYPES.BUSINESS]: {
    color: COLOR_UNIT.GOAL_BUSINESS.primary,
    background: COLOR_PALETTE.WHITE,
    icon: BusinessIcon,
    iconBackground: 'linear-gradient(46deg, #c7cded -15%, #eff2ff 74%)',
    iconColor: COLOR_SET.CORNFLOWER_BLUE,
  },
  [GOAL_TYPES.PERSONAL]: {
    color: COLOR_UNIT.GOAL_LEARNING.primary,
    background: COLOR_PALETTE.WHITE,
    icon: LearnIcon,
    iconBackground: 'linear-gradient(52deg, #f4b6e6 -9%, #feedfa 75%)',
    iconColor: COLOR_SET.FUCHSIA,
  },
  [GOAL_TYPES.TEAM]: {
    color: COLOR_UNIT.GOAL_TEAM.primary,
    background: COLOR_PALETTE.WHITE,
    icon: TeamIcon,
    iconBackground: 'linear-gradient(227deg, #fff2ef 31%, #f6cac1 78%)',
    iconColor: COLOR_SET.CORAL,
  },
  [GOAL_TYPES.COMPANY]: {
    color: COLOR_UNIT.GOAL_COMPANY.primary,
    background: COLOR_PALETTE.WHITE,
    icon: CompanyIcon,
    iconBackground: 'linear-gradient(62deg, #e1d9f8 31%, #f6f3ff 78%)',
    iconColor: COLOR_SET.MIDNICHT_BLUE,
  },
};

export const GOAL_CREATE_MENU_TYPES = {
  [GOAL_TYPES.PERSONAL]: {
    icon: ICONS.GOALS,
    iconColor: COLORS.PERSONAL,
    iconBlockColor: COLORS.WARNING_LIGHT,
    name: (i18n: I18n) => i18n._(t`Personal goal`),
    key: GOAL_TYPES.PERSONAL,
    description: (i18n: I18n) => i18n._(t`Use goal suggestions or create your own goal`),
  },
  [GOAL_TYPES.TEAM]: {
    icon: ICONS.TEAM,
    iconColor: COLORS.CAREER,
    iconBlockColor: COLORS.CAREER_LIGHT,
    name: (i18n: I18n) => i18n._(t`Team goal`),
    key: GOAL_TYPES.TEAM,
    description: (i18n: I18n) => i18n._(t`Work with one or multiple teams on the same goal`),
  },
  [GOAL_TYPES.COMPANY]: {
    icon: ICONS.ORGANISATION,
    iconColor: COLORS.TIPPY_BACKGROUND,
    iconBlockColor: COLORS.GOALS_OKRS_LIGHT,
    name: (i18n: I18n) => i18n._(t`Company goal`),
    key: GOAL_TYPES.COMPANY,
    description: (i18n: I18n) => i18n._(t`Align everyone around your vision`),
  },
};

export const GOAL_PROGRESS_MEASUREMENT_TYPES = {
  [GOAL_PROGRESS_TYPES.AVG]: {
    key: GOAL_PROGRESS_TYPES.AVG,
    value: (i18n: I18n) => i18n._(t`Average of activities`),
  },
  [GOAL_PROGRESS_TYPES.AVG_OF_CONTRIBUTING_GOALS]: {
    key: GOAL_PROGRESS_TYPES.AVG_OF_CONTRIBUTING_GOALS,
    value: (i18n: I18n) => i18n._(t`Average of contributing goals`),
  },
  [GOAL_PROGRESS_TYPES.METRIC]: {
    key: GOAL_PROGRESS_TYPES.METRIC,
    value: (i18n: I18n) => i18n._(t`Metric`),
  },
  [GOAL_PROGRESS_TYPES.IS_DONE]: {
    key: GOAL_PROGRESS_TYPES.IS_DONE,
    value: (i18n: I18n) => i18n._(t`Done or not done`),
  },
};

export const METRIC_MEASUREMENT_TYPES_ACTIVITY = {
  [ACTIVITY_PROGRESS_TYPES.NUMBERS]: {
    key: ACTIVITY_PROGRESS_TYPES.NUMBERS,
    value: (i18n: I18n) => i18n._(t`Number`),
  },
  [ACTIVITY_PROGRESS_TYPES.CURRENCY]: {
    key: ACTIVITY_PROGRESS_TYPES.CURRENCY,
    value: (i18n: I18n) => i18n._(t`Currency`),
  },
  [ACTIVITY_PROGRESS_TYPES.PERCENTAGE]: {
    key: ACTIVITY_PROGRESS_TYPES.PERCENTAGE,
    value: (i18n: I18n) => i18n._(t`Percentage`),
  },
};

export const METRIC_MEASUREMENT_TYPES = {
  [GOAL_PROGRESS_TYPES.NUMBERS]: {
    key: GOAL_PROGRESS_TYPES.NUMBERS,
    value: (i18n: I18n) => i18n._(t`Number`),
  },
  [GOAL_PROGRESS_TYPES.CURRENCY]: {
    key: GOAL_PROGRESS_TYPES.CURRENCY,
    value: (i18n: I18n) => i18n._(t`Currency`),
  },
  [GOAL_PROGRESS_TYPES.PERCENTAGE]: {
    key: GOAL_PROGRESS_TYPES.PERCENTAGE,
    value: (i18n: I18n) => i18n._(t`Percentage`),
  },
};

export const CURRENCY_TYPES = {
  [CURRENCY.EURO]: {
    key: CURRENCY.EURO,
    value: SYMBOLS.euro,
  },
  [CURRENCY.DOLLAR]: {
    key: CURRENCY.DOLLAR,
    value: SYMBOLS.dollar,
  },
  [CURRENCY.POUNDS]: {
    key: CURRENCY.POUNDS,
    value: SYMBOLS.pounds,
  },
};

export const ACTIVITY_MEASUREMENT_TYPES = {
  [ACTIVITY_PROGRESS_TYPES.IS_DONE]: {
    key: ACTIVITY_PROGRESS_TYPES.IS_DONE,
    value: (i18n: I18n) => i18n._(t`Done or not done`),
  },

  [ACTIVITY_PROGRESS_TYPES.METRIC]: {
    key: ACTIVITY_PROGRESS_TYPES.METRIC,
    value: (i18n: I18n) => i18n._(t`Metric`),
  },
};

export const GOALS_STYLES = {
  [GOAL_TYPES.PERSONAL]: {
    icon: ICONS.GOALS,
    iconColor: COLORS.PERSONAL,
  },
  [GOAL_TYPES.BUSINESS]: {
    icon: ICONS.GOALS,
    iconColor: COLORS.PERSONAL,
  },
  [GOAL_TYPES.TEAM]: {
    icon: ICONS.TEAM,
    iconColor: COLORS.CAREER,
  },
  [GOAL_TYPES.COMPANY]: {
    icon: ICONS.ORGANISATION,
    iconColor: COLORS.SECONDARY,
  },
  [VIRTUAL_GOAL_TYPES.HIDDEN]: {
    icon: ICONS.INCOGNITO,
    iconColor: COLORS.TOOLTIP_BACKGROUND,
  },
};

export const GOAL_STATUSES_MAP = {
  [GOAL_STATUSES_NEW.COMPLETED]: {
    key: GOAL_STATUSES_NEW.COMPLETED,
    title: (i18n: I18n) => i18n._(t`Completed`),
  },
  // we need onTrack status to render old goal updates
  [GOAL_STATUSES.ON_TRACK]: {
    key: GOAL_STATUSES.ON_TRACK,
    title: (i18n: I18n) => i18n._(t`On track`),
  },
  [GOAL_STATUSES_NEW.TODO]: {
    key: GOAL_STATUSES_NEW.TODO,
    title: (i18n: I18n) => i18n._(t`To do`),
  },
  [GOAL_STATUSES_NEW.IN_PROGRESS]: {
    key: GOAL_STATUSES_NEW.IN_PROGRESS,
    title: (i18n: I18n) => i18n._(t`In progess`),
  },
  [GOAL_STATUSES_NEW.PROBLEM]: {
    key: GOAL_STATUSES_NEW.PROBLEM,
    title: (i18n: I18n) => i18n._(t`Problem`),
  },
  [GOAL_STATUSES.ARCHIVED]: {
    key: GOAL_STATUSES.ARCHIVED,
    title: (i18n: I18n) => i18n._(t`Archived`),
  },
};
