import { createReducer } from 'redux-act';

import * as actions from './actions';

export default createReducer(
  {
    [actions.setCompanySettings]: (state, payload) => {
      return {
        ...state,
        ...payload,
      };
    },
    [actions.updateCompanySettings]: (state, payload) => {
      return {
        ...state,
        [payload.id]: payload,
      };
    },
    [actions.updateCompanySettingsSkillLabels]: (state, payload) => {
      return {
        ...state,
        skillLabels: payload,
      };
    },
    [actions.updateCompanySettingsNotificationSettings]: (state, payload) => {
      return {
        ...state,
        notificationSettings: { ...state.notificationSettings, ...payload },
      };
    },
    [actions.updateCompanySettingsRoleRelevancies]: (state, payload) => {
      return {
        ...state,
        roleRelevancies: payload,
      };
    },
    [actions.updateCompanySettingsSkillCategories]: (state, payload) => {
      return {
        ...state,
        skillCategories: payload,
      };
    },
  },
  {},
);
