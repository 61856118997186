import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import MaintenanceIcon from '~/assets/maintenance.png';

import { getMaintenanceTitle, getMaintenanceBody } from '~/selectors/app';
import { COLOR_PALETTE, COLORS } from '~/styles';

const Wrapper = styled.div`
  background: ${COLORS.BG_PAGE};
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Block = styled.div`
  width: 512px;
  min-height: 271px;
  padding: 51px 96px 60px 95px;
  border-radius: 6px;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
  border: solid 1px ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  background-color: ${COLOR_PALETTE.WHITE};
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin-bottom: 10px;
`;

const Description = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const MaintenanceMode = () => {
  const { i18n } = useLingui();
  const maintenanceTitle = useSelector(getMaintenanceTitle);
  const maintenanceBody = useSelector(getMaintenanceBody);

  return (
    <Wrapper>
      <Block>
        <IconWrapper>
          <SvgIcon
            classNameIcon="img"
            isDefaultColor={true}
            defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            width="56px"
            height="56px"
            url={MaintenanceIcon}
          />
        </IconWrapper>
        <Title>{maintenanceTitle || i18n._(t`Under construction..`)}</Title>
        <Description>
          {maintenanceBody ||
            i18n._(t`Learned is temporarily unavailable due to scheduled maintenance.`)}
        </Description>
      </Block>
    </Wrapper>
  );
};

export default MaintenanceMode;
