import moment from 'moment';

export const formatDateForFilter = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  // Added 0's before month and date so Safari doesn't crash
  return `${moment(
    `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`,
  ).format('YYYY-MM-DDT00:00:00.000')}`;
};
