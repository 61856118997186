import { useEffect, useState } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { useSelector } from 'react-redux';

import getCurrentCompany from '~/selectors/getCurrentCompany';
import { listSkillCategoriesSuperAdmin } from '~/services/skillCategories';

import type { ISkillCategory } from '@learned/types';

const useSkillCategories = () => {
  const [skillCategories, setSkillCategories] = useState<ISkillCategory[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const company = useSelector(getCurrentCompany);

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);

    const fetchSkillCategories = async () => {
      const response = await listSkillCategoriesSuperAdmin();
      if (mounted) {
        setSkillCategories(response.data[API_RETURN_FIELDS.SKILL_CATEGORIES]);
        setIsLoading(false);
      }
    };

    fetchSkillCategories();

    return () => void (mounted = false);
  }, [company.id]);

  return { skillCategories, isLoading };
};

export { useSkillCategories };
