import React from 'react';

import { GradientTaskCell } from '~/components/TableGrid';

import type { IColumnTable } from '~/@types/table';

import type { IPrepareColumnProps, ISurveyParticipation } from '../../../types';

export const prepareColumn = ({
  id,
  name,
  isFixed,
  position,
  reportingThreshold,
  selectedItems,
}: Omit<IPrepareColumnProps, 'survey'> & {
  reportingThreshold: number;
  selectedItems: string[];
}): IColumnTable => {
  return {
    accessor: id,
    name,
    // @ts-ignore
    renderCell: ({ events }: ISurveyParticipation) => {
      const event = events.find(({ event }) => event === id);

      return (
        <GradientTaskCell
          selectedItems={selectedItems}
          event={event}
          reportingThreshold={reportingThreshold}
          id={id}
        />
      );
    },
    isFixed,
    position,
    padding: 'none',
  };
};
