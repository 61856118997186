import React, { useState, useEffect } from 'react';

import { Trans } from '@lingui/macro';
import Tippy from '@tippyjs/react';
import { isEmpty } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import styled from 'styled-components';

import Button from '~/components/Button';
import Modal from '~/components/Modal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import Switch from '~/components/Switch';

import { OPTIONS, DEFAULT_SECTIONS } from './constants';

import useBoolState from '~/hooks/useBoolState';
import { getUserCertificates } from '~/services/certificates';
import { saveCvSettings } from '~/services/cvSettings';
import { getUserEducations } from '~/services/educations';
import { getUserSkillsOrder } from '~/services/users';
import { getUserWorkExps } from '~/services/workExps';
import { COLORS } from '~/styles';

const FooterRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  margin-right: ${(props) => props.$marginRight};
`;

const OptionBlock = styled.div`
  min-height: 48px;
  margin-bottom: ${(props) => (props.$isLast ? 0 : '8px')};
  padding: 12px 22px 12px 19px;
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDER_LIGHT};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`;

const OptionTitle = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.TEXT_BLACK};
`;

const Section = styled.div`
  margin-bottom: ${(props) => (!props.$isFirst ? '32px' : '24px')};
`;

const SectionTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.TEXT_BLACK};
`;

const SubText = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.TEXT_SECONDARY};
}
`;

const tippyOpts = { modifiers: [{ name: 'computeStyles', options: { gpuAcceleration: false } }] };

const arrayOptions = [OPTIONS.WORK, OPTIONS.EDUCATION, OPTIONS.CERTIFICATES];

const Option = ({ isLast, title, isDisabled = false, isChecked = true, onChange }) => (
  <OptionBlock $isLast={isLast}>
    <OptionTitle>
      <Trans>{title}</Trans>
    </OptionTitle>
    <Tippy
      trigger="mouseenter"
      theme="light"
      popperOptions={tippyOpts}
      maxWidth={250}
      disabled={!isDisabled}
      content={<Trans>No data available</Trans>}
    >
      <div>
        <Switch onChange={onChange} checked={isChecked} disabled={isDisabled} />
      </div>
    </Tippy>
  </OptionBlock>
);

const ExportPDFOptionsModal = ({ onClose, downloadCV, user }) => {
  const $loading = useBoolState(true);
  const [sections, setSections] = useState(DEFAULT_SECTIONS);

  useEffect(() => {
    let isMounted = true;
    const fetch = async () => {
      const [userSkillsOrder, workExperiences, educations, certificates] = await Promise.all([
        getUserSkillsOrder(user.id),
        getUserWorkExps(user.id),
        getUserEducations(user.id),
        getUserCertificates(user.id),
      ]);

      // update sections
      const updatedSections = cloneDeep(sections);

      const updateOptions = (sectionKey, key) => {
        updatedSections[sectionKey].options[key].isAvailable = false;
        updatedSections[sectionKey].options[key].isVisible = false;
      };

      if (isEmpty(user.phone)) {
        updateOptions(OPTIONS.ABOUT_SECTION, OPTIONS.PHONE);
      }
      if (isEmpty(user.email)) {
        updateOptions(OPTIONS.ABOUT_SECTION, OPTIONS.EMAIL);
      }
      if (isEmpty(user.bio)) {
        updateOptions(OPTIONS.ABOUT_SECTION, OPTIONS.ABOUT);
      }
      const isSkillsToShow = Object.values(userSkillsOrder)
        .flat(1)
        .some((s) => !(user?.skillsHidden || []).includes(s));
      if (!isSkillsToShow) {
        updateOptions(OPTIONS.SKILLS_SECTION, OPTIONS.SKILLS);
      }

      const updateArrayOptions = (key, items) => {
        updatedSections[key].options = Object.values(items).map((i) => {
          return {
            key: i.id,
            title: `${i.name}, ${i.employer || i.school}`,
          };
        });
        updatedSections[key].visibleOptions = Object.values(items).map((i) => i.id);
      };

      if (!isEmpty(workExperiences)) {
        updateArrayOptions(OPTIONS.WORK, workExperiences);
      }
      if (!isEmpty(educations)) {
        updateArrayOptions(OPTIONS.EDUCATION, educations);
      }
      if (!isEmpty(certificates)) {
        updateArrayOptions(OPTIONS.CERTIFICATES, certificates);
      }

      if (isMounted) {
        setSections(updatedSections);
      }

      $loading.off();
    };
    fetch();

    return () => (isMounted = false);
    // eslint-disable-next-line
  }, [user]);

  const onSubmit = async () => {
    const cvSettings = {
      user: user.id,
      isAbout: sections[OPTIONS.ABOUT_SECTION].options[OPTIONS.ABOUT].isVisible,
      isMainInfo: sections[OPTIONS.ABOUT_SECTION].options[OPTIONS.MAININFO].isVisible,
      isPhone: sections[OPTIONS.ABOUT_SECTION].options[OPTIONS.PHONE].isVisible,
      isEmail: sections[OPTIONS.ABOUT_SECTION].options[OPTIONS.EMAIL].isVisible,
      isSkills: sections[OPTIONS.SKILLS_SECTION].options[OPTIONS.SKILLS].isVisible,
      work: sections[OPTIONS.WORK].visibleOptions,
      education: sections[OPTIONS.EDUCATION].visibleOptions,
      certificates: sections[OPTIONS.CERTIFICATES].visibleOptions,
    };
    await saveCvSettings({ cvSettings });
    await downloadCV();
    onClose();
  };

  const onChangeSectionVisibility = (key, value) => {
    const updatedSections = cloneDeep(sections);
    updatedSections[key].visibleOptions = value
      ? updatedSections[key].options.map((o) => o.key)
      : [];
    setSections(updatedSections);
  };

  const onChangeOptionVisibility = (key, sectionKey, value) => {
    const updatedSections = cloneDeep(sections);
    updatedSections[sectionKey].options[key].isVisible = value;
    setSections(updatedSections);
  };

  const onChangeArrayOptionVisibility = (key, sectionKey, value) => {
    const updatedSections = cloneDeep(sections);
    updatedSections[sectionKey].visibleOptions = value
      ? [...updatedSections[sectionKey].visibleOptions, key]
      : updatedSections[sectionKey].visibleOptions.filter((i) => i !== key);
    setSections(updatedSections);
  };

  const renderOption = (option, i, totalLength, sectionKey) => {
    const isLast = totalLength === i + 1;
    return (
      <Option
        key={option.key}
        isLast={isLast}
        title={option.title}
        isChecked={option.isVisible}
        isDisabled={!option.isAvailable}
        onChange={(value) => onChangeOptionVisibility(option.key, sectionKey, value)}
      />
    );
  };

  const renderArrayOptions = (options, visibleOptions, sectionKey) => {
    return options.map((option, i) => {
      const isLast = options.length === i + 1;
      const isChecked = visibleOptions.includes(option.key);
      return (
        <Option
          key={option.key}
          isLast={isLast}
          title={option.title}
          isChecked={isChecked}
          onChange={(value) => onChangeArrayOptionVisibility(option.key, sectionKey, value)}
        />
      );
    });
  };

  const renderSection = (section, i) => {
    const isOptionWithArray = arrayOptions.includes(section.key);
    return (
      <Section $isFirst={i === 0}>
        <Header $marginRight={section.key === OPTIONS.ABOUT_SECTION ? '38px' : '23px'}>
          <SectionTitle>
            <Trans>{section.title}</Trans>
          </SectionTitle>
          {section.key === OPTIONS.ABOUT_SECTION && (
            <SubText>
              <Trans>Visible</Trans>
            </SubText>
          )}
          {[OPTIONS.WORK, OPTIONS.EDUCATION, OPTIONS.CERTIFICATES].includes(section.key) && (
            <Tippy
              trigger="mouseenter"
              theme="light"
              popperOptions={tippyOpts}
              maxWidth={250}
              disabled={!isEmpty(section.options)}
              content={<Trans>No data available</Trans>}
            >
              <div>
                <Switch
                  onChange={(value) => onChangeSectionVisibility(section.key, value)}
                  checked={!isEmpty(section.visibleOptions)}
                  disabled={isEmpty(section.options)}
                />
              </div>
            </Tippy>
          )}
        </Header>
        {isOptionWithArray
          ? renderArrayOptions(section.options, section.visibleOptions, section.key)
          : Object.values(section.options).map((option, i) =>
              renderOption(option, i, Object.values(section.options).length, section.key),
            )}
      </Section>
    );
  };

  return (
    <Modal
      title={<Trans>Export pdf</Trans>}
      onClose={onClose}
      width={750}
      showDivider={false}
      headerStyles={{ padding: '0 20px 0 24px' }}
      greyDescriptionStyles={{ padding: '0 20px 24px 24px' }}
      contentStyles={{ padding: '12px 22px 26px 24px' }}
      greyDescription={<Trans>Custom the pdf export</Trans>}
      hideFooter
    >
      <ShowSpinnerIfLoading loading={$loading.value}>
        {Object.values(sections).map(renderSection)}
      </ShowSpinnerIfLoading>
      <FooterRow>
        <Button label={<Trans>Download</Trans>} type="primary" onClick={onSubmit} />
      </FooterRow>
    </Modal>
  );
};

export { ExportPDFOptionsModal };
