import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { CompletedImage, ConfirmationContainer, TextWrapper, Title } from './ReviewOutro.design';

import CompletedStatePlaceholder from '~/assets/3.0/placeholders/completed-state-round.png';

function ReviewConfirmation() {
  const { i18n } = useLingui();

  return (
    <ConfirmationContainer>
      <Title marginBottom={10}>
        <Trans>Thank you! Your answers have been shared.</Trans>
      </Title>

      <TextWrapper>
        <CompletedImage>
          <img alt={i18n._(t`Review completed`)} src={CompletedStatePlaceholder} />
        </CompletedImage>
      </TextWrapper>
    </ConfirmationContainer>
  );
}

export { ReviewConfirmation };
