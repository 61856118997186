import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { ICONS } from '~/components/Icon';
import { TableList } from '~/components/TableList';

import { GOALS_GRID_COLUMNS_ONLY_NAME } from './columns';

import { isNotNil } from '~/utils/typePredicates';

import type { IGoal } from '@learned/types';

interface IProps {
  goals: IGoal[];
  originalGoals: IGoal[];
  isLoading: boolean;
  handleRowClick: (item: IGoal) => void;
  isDashboard?: boolean;
  onRemove: (goal: IGoal) => void;
  onEdit: (goal: IGoal) => void;
}

const GoalsGridContainer = styled.div`
  width: 100%;

  & > div {
    padding: unset;
  }
`;

export const GoalsGrid = ({
  goals,
  originalGoals,
  isLoading,
  handleRowClick,
  isDashboard,
  onRemove,
  onEdit,
}: IProps) => {
  const { i18n } = useLingui();

  const [goalsData, setGoalsData] = useState(goals);

  useEffect(() => {
    // show the original name if it's not user dashboard
    if (!isDashboard) {
      setGoalsData((prevGoals) =>
        prevGoals.map((item) => {
          const originalGoal = originalGoals?.find((goal) => goal.id === item.id);
          const name = originalGoal?.name || item.name;

          return { ...item, name };
        }),
      );
    }
  }, [isDashboard, originalGoals]);

  const createMenuItems = (item: IGoal) => {
    const originalGoal = originalGoals?.find((goal) => goal.id === item.id);

    return [
      originalGoal && {
        label: i18n._(t`Edit`),
        action: () => onEdit(item),
        icon: ICONS.EDIT_PENCIL,
      },
      {
        label: i18n._(t`Remove`),
        action: () => onRemove(item),
        icon: ICONS.SUBTRACT_MINUS,
        isWarning: true,
      },
    ].filter(isNotNil);
  };

  return (
    <GoalsGridContainer>
      <TableList
        data={goalsData}
        columns={GOALS_GRID_COLUMNS_ONLY_NAME}
        isLoading={isLoading}
        onRowClick={handleRowClick}
        placeholderProps={{
          noResultText: i18n._(t`No items found for you`),
          emptyStateText: i18n._(t`No goals planned yet`),
        }}
        menuProps={{
          createMenuItems,
          isMenuVisible: !isDashboard,
        }}
      />
    </GoalsGridContainer>
  );
};
