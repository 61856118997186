import React, { type ReactNode } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import RickTextView from '~/components/RickTextView';
import { UserAvatar } from '~/components/UserAvatar';

import {
  Card,
  Content,
  Description,
  Footer,
  Header,
  ReviewIntroWrapper,
  Text,
  ButtonContainer,
  PeerMessage,
} from './ReviewIntro.design';

import { COLORS } from '~/styles';

import type { IMultiLangString, IUser } from '@learned/types';

function ReviewIntro({
  reviewTitle,
  reviewMessage,
  onClose,
  onStart,
  userFrom,
  userFromObject,
  onDecline,
  useMultiLangString,
  isExternalPeer,
}: {
  reviewTitle: ReactNode;
  reviewMessage: IMultiLangString | string;
  onClose: () => void;
  onStart: () => void;
  userFrom?: IUser['id'];
  userFromObject?: IUser;
  onDecline?: () => void;
  companyLogo?: string;
  isExternalPeer?: boolean;
  useMultiLangString(): (multiLangString: Record<string, string> | string) => string;
}) {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  return (
    <ReviewIntroWrapper>
      <Card>
        <Header>{reviewTitle}</Header>
        {(!isEmpty(getMultiLangString(reviewMessage)) || userFrom) && (
          <Content>
            {userFrom && (
              <PeerMessage>
                <Text>
                  <Trans>You are requested to provide input for the review of:</Trans>
                </Text>
                {userFromObject ? (
                  <UserAvatar user={userFromObject} />
                ) : (
                  <UserAvatar userId={userFrom} />
                )}
              </PeerMessage>
            )}
            {reviewMessage && !isEmpty(getMultiLangString(reviewMessage)) && (
              <Description>
                <RickTextView
                  fontColor={COLORS.TEXT_MAIN}
                  html={getMultiLangString(reviewMessage)}
                />
              </Description>
            )}
          </Content>
        )}
        <Footer>
          <div>
            {onDecline && (
              <Button
                onClick={onDecline}
                label={isExternalPeer ? i18n._(t`Decline request`) : i18n._(t`Decline`)}
                size={ButtonSize.MEDIUM}
                variant={ButtonVariant.TEXT_DELETE}
              />
            )}
          </div>
          <ButtonContainer>
            {!isExternalPeer && (
              <Button
                label={i18n._(t`Start later`)}
                onClick={onClose}
                size={ButtonSize.MEDIUM}
                variant={ButtonVariant.TEXT_PRIMARY}
              />
            )}
            <Button
              label={i18n._(t`Start now`)}
              onClick={onStart}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.PRIMARY}
            />
          </ButtonContainer>
        </Footer>
      </Card>
    </ReviewIntroWrapper>
  );
}

export { ReviewIntro };
