import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Header6Dark } from '~/components/UI/Typographics/headers';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLOR_SET, COLORS } from '~/styles';

const QuestionName = styled.div`
  margin: 0 0 16px 0;
  border-radius: 3px;
  background-color: ${COLORS.BG_PAGE};
  padding: 12px 18px;
  min-height: 69px;
  width: 100%;
  box-sizing: border-box;

  ${Header6Dark} {
    margin: 0;
  }
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLORS.TEXT_SECONDARY};
`;

const FocusArea = styled.li`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLORS.TEXT_SECONDARY};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const ExpectedLevel = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: ${COLOR_SET.MIDNICHT_BLUE};
  margin-left: 8px;
`;

const SkillFocusAreas = ({ focusAreas = [], skillLabel, isExpectedLevel = false }) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  if (focusAreas.length === 0) {
    return null;
  }

  return (
    <QuestionName>
      <Row>
        <Text>{skillLabel}</Text>
        {isExpectedLevel && <ExpectedLevel>{i18n._(t`Expected level`)}</ExpectedLevel>}
      </Row>
      {focusAreas.map((focusArea) => (
        <FocusArea key={focusArea.id}>{getMultiLangString(focusArea.name)}</FocusArea>
      ))}
    </QuestionName>
  );
};

export { SkillFocusAreas };
