import React, { ReactChild } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';

import { Option, PlaceholderContainer, Select } from '~/components/Dropdown/design';
import { useSingleSelectState } from '~/components/Dropdown/hooks';
import type { DropdownSingleSelectProps } from '~/components/Dropdown/types';
import { Icon, ICONS } from '~/components/Icon';

import { BaseDropdown } from './BaseDropdown';

import { COLORS } from '~/styles';

const PlaceholderWrapper = styled.div`
  color: ${COLORS.SUBTEXT};
`;

const tippyOpts = { modifiers: [{ name: 'computeStyles', options: { gpuAcceleration: false } }] };

function SingleSelectDropdown<T>({
  selectedItem,
  onChange,
  placeholder,
  renderItem,
  CustomDropdownComponent,
  CustomOptionComponent,
  hashItem,
  isClickable = true,
  isClearOption = false,
  selectHeight,
  ...props
}: DropdownSingleSelectProps<T> & {
  renderItem: (item: T) => ReactChild;
}) {
  const { i18n } = useLingui();
  const {
    hasSelectedItem,
    showDropdown,
    isItemSelected,
    toggleDropdown,
    hideDropdown,
    handleNewItemSelected,
    reset,
  } = useSingleSelectState({ selectedItem, onChange, hashItem });

  const resetAction = {
    handler: reset,
    name: i18n._(t`Clear`),
    icon: ICONS.CLOSE,
  };

  const label = (
    <span>
      {selectedItem ? (
        renderItem(selectedItem)
      ) : placeholder ? (
        <PlaceholderWrapper>{placeholder}</PlaceholderWrapper>
      ) : (
        <PlaceholderWrapper>
          <Trans>Dropdown</Trans>
        </PlaceholderWrapper>
      )}
    </span>
  );

  function renderOption(item: T) {
    const itemIsSelected = isItemSelected(item);

    return CustomOptionComponent ? (
      <CustomOptionComponent
        item={item}
        isSelected={itemIsSelected}
        handleNewItemSelected={handleNewItemSelected}
      />
    ) : (
      <Option $selected={itemIsSelected} onClick={() => handleNewItemSelected(item)}>
        <span>{renderItem(item)}</span>
      </Option>
    );
  }

  return (
    <BaseDropdown
      {...props}
      actions={
        isClearOption && selectedItem ? [...(props.actions ?? []), resetAction] : props.actions
      }
      showDropdown={showDropdown}
      renderOption={renderOption}
      hideDropdown={hideDropdown}
    >
      {CustomDropdownComponent ? (
        <CustomDropdownComponent
          clicked={showDropdown}
          active={hasSelectedItem}
          onClick={toggleDropdown}
          selectedItem={selectedItem}
        />
      ) : (
        <Select
          $clicked={showDropdown}
          $active={hasSelectedItem}
          onClick={isClickable ? toggleDropdown : () => {}}
          selectHeight={selectHeight}
        >
          <PlaceholderContainer>
            <Tippy
              trigger="mouseenter"
              theme="dark"
              popperOptions={tippyOpts}
              // @ts-ignore
              content={selectedItem?.tooltip || label}
              delay={[300, 0]}
            >
              {label}
            </Tippy>
          </PlaceholderContainer>
          {isClickable && (
            <Icon color={COLORS.DROPDOWN_ICON} height="11" width="11" icon={ICONS.DROPDOWN} />
          )}
        </Select>
      )}
    </BaseDropdown>
  );
}

export { SingleSelectDropdown };
