import React, { PureComponent } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import classNames from 'classnames';
import nl from 'date-fns/locale/nl';
import moment from 'moment';
import PropTypes from 'prop-types';
import DatePickerCustom, { registerLocale } from 'react-datepicker';
import { connect } from 'react-redux';
import styled from 'styled-components';

import DatePickerHeader from '~/components/DatePickerDeprecated/DatePickerHeader';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';

registerLocale('nl', nl);

const DatePickerWrapper = styled(DatePickerCustom)`
  padding: ${(props) =>
    props.$padding !== undefined
      ? props.$padding
      : props.$isHideIcon
      ? '13px 18px'
      : '13px 18px 13px 40px'};
  text-align: ${(props) => props.$textAlign || 'start'};
  width: ${(props) => (typeof props.width === 'string' ? props.width : `${props.width}px`)};
  height: ${(props) => (typeof props.height === 'string' ? props.height : `${props.height}px`)};
`;

/**
 * @deprecated since version 3.0 {@see components/DatePicker}
 */
class DatePicker extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    selected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    width: PropTypes.number,
    showTimeSelect: PropTypes.bool,
    showMonthYearPicker: PropTypes.bool,
    showFullMonthYearPicker: PropTypes.bool,
    showTimeSelectOnly: PropTypes.bool,
    timeFormat: PropTypes.string,
    isValidated: PropTypes.bool,
    isHideIcon: PropTypes.bool,
    lang: PropTypes.string,
    padding: PropTypes.string,
    textAlign: PropTypes.string,
  };

  static defaultProps = {
    dateFormat: 'dd-MM-yyyy',
    isValidated: true,
    isHideIcon: false,
    height: '40px',
  };

  static popperPlacementPositions = {
    ['auto']: 'auto',
    ['auto-left']: 'auto-left',
    ['auto-right']: 'auto-right',
    ['bottom']: 'bottom',
    ['bottom-end']: 'bottom-end',
    ['bottom-start']: 'bottom-start',
    ['left']: 'left',
    ['left-end']: 'left-end',
    ['left-start']: 'left-start',
    ['right']: 'right',
    ['right-end']: 'right-end',
    ['right-start']: 'right-start',
    ['top']: 'top',
    ['top-end']: 'top-end',
    ['top-start']: 'top-start',
  };

  render() {
    const {
      i18n,
      onChange,
      inlined,
      name,
      width,
      height,
      startDate,
      endDate,
      selected,
      minDate,
      maxDate,
      minTime,
      maxTime,
      showTimeSelect,
      showTimeSelectOnly,
      showFullMonthYearPicker,
      showTwoColumnMonthYearPicker,
      showMonthYearPicker,
      timeIntervals,
      timeCaption,
      timeFormat,
      dateFormat,
      disabled,
      isValidated,
      className,
      placeholder,
      isHideIcon,
      popperPlacement,
      lang,
      padding,
      textAlign,
    } = this.props;

    const selectedFormatted = selected ? moment(selected).toDate() : null;
    const showMonthYearDropdown = !(
      showFullMonthYearPicker ||
      showMonthYearPicker ||
      showTimeSelectOnly
    );

    return (
      <label
        className={classNames(
          'custom-date-picker',
          { 'custom-date-picker-empty': selected === '' },
          { 'custom-date-picker-inlined': inlined },
          className,
        )}
        style={{ width: `${width}px`, position: 'relative', height: `${height}px` }}
      >
        {!isHideIcon && (
          <button type="button" style={{ display: 'flex', alignItems: 'center' }}>
            <Icon icon={ICONS.AGENDA} color="#ccced5" size={ICON_SIZES.LARGE} />
          </button>
        )}
        <DatePickerWrapper
          placeholderText={placeholder || i18n._(t`Example: 01-04-2018`)}
          className={`datepicker-new ${isValidated ? '' : 'datepicker-new_error'}`}
          name={name}
          minDate={minDate}
          maxDate={maxDate}
          minTime={minTime}
          maxTime={maxTime}
          width={width}
          height={height}
          $padding={padding}
          $textAlign={textAlign}
          startDate={startDate}
          endDate={endDate}
          locale={lang}
          key={selected}
          selected={selectedFormatted || null}
          dateFormat={dateFormat}
          onChange={onChange}
          showMonthYearPicker={showMonthYearPicker}
          showFullMonthYearPicker={showFullMonthYearPicker}
          showTwoColumnMonthYearPicker={showTwoColumnMonthYearPicker}
          showTimeSelect={showTimeSelect}
          showTimeSelectOnly={showTimeSelectOnly}
          timeIntervals={timeIntervals}
          timeCaption={timeCaption}
          timeFormat={timeFormat}
          disabled={disabled}
          popperPlacement={popperPlacement}
          $isHideIcon={isHideIcon}
          calendarStartDay={1}
          renderCustomHeader={
            showMonthYearDropdown
              ? ({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <DatePickerHeader
                    date={date}
                    changeYear={changeYear}
                    changeMonth={changeMonth}
                    decreaseMonth={decreaseMonth}
                    increaseMonth={increaseMonth}
                    prevMonthButtonDisabled={prevMonthButtonDisabled}
                    nextMonthButtonDisabled={nextMonthButtonDisabled}
                    locale={lang}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                )
              : undefined
          }
        />
      </label>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.locale.lang,
  };
};

export default withI18n()(connect(mapStateToProps)(DatePicker));
