import React from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import ColoredRow from '~/components/ColoredRow';
import GoalDashboardCardModal from '~/components/GoalDashboardCardModal';
import AvatarGroup from '~/components/UI/AvatarGroup';

import { GOAL_TYPES } from '~/constants';
import { GOAL_STYLES } from '~/constants/goals';
import useBoolState from '~/hooks/useBoolState';
import { COLOR_PALETTE } from '~/styles';

const CreatedBy = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  color: ${COLOR_PALETTE.LIGHT_GRAY};
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 16px;
`;

const RightCol = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 24px;
`;

const StyledAvatarGroup = styled(AvatarGroup)`
  margin-left: 8px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
`;

export const GoalPlanRow = ({ goal, question, review, pdfView, currentUser }) => {
  const $isGoalInfoModal = useBoolState(false);

  const goalStyles =
    GOAL_STYLES[
      question.type === QUESTION_TYPES.GOAL_BUSINESS_PLAN
        ? GOAL_TYPES.BUSINESS.key
        : GOAL_TYPES.PERSONAL.key
    ];

  return (
    <>
      <ColoredRow
        key={goal.id}
        title={goal.name}
        rowIcon={goalStyles.icon}
        rowStartColor={goalStyles.color}
        rowColor={goalStyles.background}
        rowIconColor={goalStyles?.iconColor}
        rowIconBackground={goalStyles?.iconBackground}
        onClick={$isGoalInfoModal.on}
        maxWidth={400}
      >
        <RightCol>
          <CreatedBy>
            {!pdfView && goal.creators.includes(currentUser.id) ? (
              <Trans>Created by you</Trans>
            ) : (
              <StyledAvatarGroup users={goal.creators} showTooltip={false} size={28} type="small" />
            )}
          </CreatedBy>
        </RightCol>
      </ColoredRow>
      {$isGoalInfoModal.value && (
        <GoalDashboardCardModal
          onClose={$isGoalInfoModal.off}
          goalId={goal.id}
          reviewId={review.id}
        />
      )}
    </>
  );
};
