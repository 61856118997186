import React from 'react';
import type { ReactNode } from 'react';

import styled from 'styled-components';

import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';

import { COLORS } from '~/styles';

const Wrapper = styled.div<{ isCentered?: boolean; padding?: string; maxWidth?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  text-align: center;
  margin-top: 6px;
  font-size: 16px;
  color: ${COLORS.SUBTEXT};
  max-width: 269px;
`;

interface IPlaceholderProps {
  className?: string;
  icon: ICONS;
  title: ReactNode;
}

const Placeholder = ({ icon, title }: IPlaceholderProps) => {
  return (
    <Wrapper>
      <Icon icon={icon} size={ICON_SIZES.LARGE} color={COLORS.ICONS_SECONDARY} />
      <Title>{title}</Title>
    </Wrapper>
  );
};

export { Placeholder };
