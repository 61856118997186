import React, {
  useEffect,
  useState,
  useMemo,
  ChangeEvent,
  KeyboardEvent,
  MutableRefObject,
  useRef,
} from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { orderBy } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import size from 'lodash/size';
import styled, { css } from 'styled-components';

import Button from '~/components/Button';
import CheckBox from '~/components/CheckBox';
import Pencil2Icon from '~/components/Icons/Pencil2';
import TrashIcon from '~/components/Icons/Trash';
import SearchSelectButton from '~/components/SearchSelectButton';
import { FieldGroup, SearchField, TextField } from '~/components/Text';

import { SORT_BY } from '~/constants';
import { usePagination } from '~/hooks/usePagination';
import {
  createActivityCategory,
  manageActivityCategories,
  updateActivityCategory,
} from '~/services/activityCategories';
import { COLORS, COLOR_PALETTE, COLOR_SET } from '~/styles';

import { Mode } from '..';
import PaginationBar from '../../../PaginationBar';
import { ConfirmationModal } from '../../ConfirmationModal';

import type { IActivityCategory } from '@learned/types';

interface ISelectListProps {
  items: IActivityCategory[];
  selectedItems: IActivityCategory['id'][];
  setSelectedCategories: (categories: IActivityCategory['id'][]) => void;
  isMultiCheck?: boolean;
  isSearchable?: boolean;
  className?: string;
  setMode: (value: Mode) => void;
  mode: Mode;
  onChange: (items: any[]) => void;
  cancelButtonRef: MutableRefObject<HTMLButtonElement | undefined>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: solid 1px #f3f7fd;
  overflow-x: visible;

  ${FieldGroup} {
    margin: 12px 24px;
  }
`;

const FilterGroups = styled(FieldGroup)`
  display: flex;
  gap: 12px;
  & .search {
    height: 32px;
  }
`;

const SortBySelect = styled(SearchSelectButton)`
  width: 160px;
  z-index: 100;
`;

const EmptyText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: ${COLOR_SET.BLACK};
  height: 60px;
  width: 100%;
`;

const Row = styled.div<{ isSelected?: boolean; isFade?: boolean }>`
  box-sizing: border-box;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: ${COLOR_SET.BLACK};
  display: flex;
  justify-content: flex-start;
  margin: 0;

  &:hover {
    background-color: ${COLORS.BG_PAGE};
    cursor: pointer;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${COLORS.BG_PAGE};
    `}

  @keyframes fade {
    0% {
      background-color: var(--company-color);
    }
    100% {
      background-color: unset;
    }
  }

  ${({ isFade }) =>
    isFade &&
    css`
      animation: fade 1s ease-in-out;
    `}

  & .name {
    margin-left: 16px;
  }
`;

const Header = styled.div<{ isSelected?: boolean }>`
  box-sizing: border-box;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  margin: 0;
  color: ${({ isSelected }) => (isSelected ? COLORS.TEXT_WHITE : COLOR_PALETTE.DARK_GRAY)};
  background-color: ${({ isSelected }) => (isSelected ? 'var(--company-color)' : COLORS.BG_PAGE)};

  & .action {
    color: inherit;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  & .name {
    margin-left: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  & .left {
    display: flex;
    justify-content: flex-start;
    padding-left: 0px;
    color: inherit;
    font-weight: 500;
  }

  & .right {
    display: flex;
    justify-content: flex-start;
  }
`;

const StyledTextField = styled(TextField)`
  height: unset;
  font-size: 14px;
  padding: 6px 36px;
`;

const SmallText = styled.span`
  display: flex;
  align-items: center;
  color: ${COLOR_SET.MIDDLE_GRAY_BLUE};
  font-size: 12px;
  white-space: nowrap;
  margin-left: 12px;
`;

const SelectList = ({
  isMultiCheck = false,
  isSearchable = false,
  items,
  selectedItems,
  onChange,
  className,
  setMode,
  mode,
  setSelectedCategories,
  cancelButtonRef,
}: ISelectListProps) => {
  const { i18n } = useLingui();
  const { pagination, changePagination } = usePagination(10);
  const [search, setSearch] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);
  const [paginatedItems, setPaginatedItems] = useState(filteredItems);
  const [sortedBy, setSortedBy] = useState<string[]>([]);
  const [renameItem, setRenameItem] = useState<IActivityCategory>();
  const [fadeItem, setFadeItem] = useState<IActivityCategory>();
  const [inputValue, setInputValue] = useState('');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  // Sort by
  useEffect(() => {
    switch (sortedBy?.[0]) {
      case SORT_BY.LAST_UPDATED.id:
        setFilteredItems(
          orderBy(filteredItems, (item) => item.meta?.lastModifiedDate || item.meta?.createdDate, [
            'desc',
          ]),
        );
        break;
      case SORT_BY.A_Z.id:
        setFilteredItems(orderBy(filteredItems, (item) => item.name));
        break;
      case SORT_BY.Z_A.id:
        setFilteredItems(orderBy(filteredItems, (item) => item.name, ['desc']));
        break;
      case SORT_BY.CREATED_NEW_OLD.id:
        setFilteredItems(orderBy(filteredItems, (item) => item.meta?.createdDate, ['desc']));
        break;
      case SORT_BY.CREATED_OLD_NEW.id:
        setFilteredItems(orderBy(filteredItems, (item) => item.meta?.createdDate));
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, sortedBy]);

  useEffect(() => {
    setPaginatedItems(filteredItems.slice(pagination.skip, pagination.index * pagination.limit));
  }, [filteredItems, pagination]);

  const filterSearch = (item: IActivityCategory) => {
    if (search) {
      return (item.name || '').toLowerCase().includes(search.toLowerCase());
    }

    return item;
  };

  useEffect(() => {
    setFilteredItems(items.filter(filterSearch));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify({ search, items })]);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSortByChange = (value: string[]) => {
    setSortedBy(value);
  };

  const rename = (id: IActivityCategory['id']) => {
    const item = filteredItems.find((i) => i.id === id);
    const name = item?.name || '';
    setRenameItem(item);
    setInputValue(name);
    setMode(Mode.RENAME);
  };

  const checkItem = (item: IActivityCategory) => {
    setFadeItem(undefined);
    if (isMultiCheck) {
      if (selectedItems?.indexOf(item.id) !== -1) {
        onChange(selectedItems?.filter((s) => s !== item.id));
      } else {
        onChange(selectedItems?.concat([item.id]));
      }
    } else {
      if (selectedItems?.indexOf(item.id) !== -1) {
        onChange([]);
      } else {
        onChange([item.id]);
      }
    }
  };

  const checkAllItems = () => {
    if (selectedItems.length === filteredItems.length) {
      onChange([]);
    } else {
      onChange(filteredItems.map((item) => item.id));
    }
  };

  const isAllItemsSelected = selectedItems.length === items.length;
  const isRenameVisible = useMemo(
    () => selectedItems.length === 1 && !renameItem,
    [renameItem, selectedItems.length],
  );
  const isDeleteVisible = useMemo(() => !isEmpty(selectedItems), [selectedItems]);

  const inputRef = useRef<HTMLButtonElement>();

  const handleMouseDown = (event: MouseEvent) => {
    if (inputRef?.current?.contains(event.target as Node)) {
      return;
    }

    if (!cancelButtonRef?.current?.contains(event.target as Node)) {
      modifyItem();
    } else {
      setInputValue('');
      setRenameItem(undefined);
      setFadeItem(undefined);
    }
  };

  useEffect(() => {
    if (mode !== Mode.VIEW) {
      document.addEventListener('mousedown', handleMouseDown);
    }

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, inputValue]);

  const onKeyUpInput = async (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (mode === Mode.ADD) {
        modifyItem(Mode.ADD);
      } else {
        modifyItem();
      }
    }
  };

  const modifyItem = async (nextMode: Mode = Mode.VIEW) => {
    switch (mode) {
      case Mode.RENAME:
        renameCategory(renameItem?.id, inputValue);
        break;
      case Mode.ADD:
        addNewCategory(inputValue);
        break;
    }
    setMode(nextMode);
    setInputValue('');
  };

  const onChangeInput = async (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const addNewCategory = async (name: string) => {
    if (name) {
      setSelectedCategories([]);
      const { data } = await createActivityCategory(name);
      setFadeItem(data.activityCategory);
      setFilteredItems((prevState) => [data?.activityCategory, ...prevState]);
    }
  };

  const renameCategory = async (id?: string, name?: string) => {
    if (!id || !name) {
      return;
    }

    setFilteredItems((prevState) =>
      prevState.map((i) => {
        if (i.id === id) {
          return {
            ...i,
            name,
          };
        }

        return i;
      }),
    );
    const item = filteredItems.find((i) => i.id === id);
    setFadeItem(item);
    setRenameItem(undefined);

    await updateActivityCategory(id, name);
  };

  const inputComponent = (
    <StyledTextField
      placeholder={i18n._(t`Name your category`)}
      type="text"
      onKeyUp={onKeyUpInput}
      onChange={onChangeInput}
      value={inputValue}
      // @ts-ignore
      ref={inputRef}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
    />
  );

  const onDelete = async (categories: IActivityCategory['id'][]) => {
    setSelectedCategories([]);
    setFilteredItems((prevState) => prevState.filter((item) => !categories.includes(item.id)));
    await manageActivityCategories({ remove: categories });
  };

  return (
    <Container className={className}>
      {isSearchable && (
        <FilterGroups>
          {/* @ts-ignore */}
          <SearchField
            data-mqdid="select-goal_input"
            value={search}
            onChange={onSearch}
            placeholder={i18n._(t`Search`)}
            className="search"
          />
          <SortBySelect
            title={i18n._(t`Sort by`)}
            checkedList={sortedBy}
            handleChange={onSortByChange}
            options={Object.values(SORT_BY).map((item) => ({ ...item, label: item.label(i18n) }))}
            minWidth="80px"
            isRadio
            noHeader
          />
          {mode !== Mode.VIEW && inputValue.length > 0 ? (
            <SmallText>{i18n._(t`↵ Enter to save`)}</SmallText>
          ) : (
            <Button
              type="shadow"
              styles={{
                width: '173px',
                fontSize: '12px',
                whiteSpace: 'nowrap',
                padding: '8px 21px 7px 20px',
              }}
              label={i18n._(t`Add category`)}
              onClick={async () => {
                setMode(Mode.ADD);
              }}
            />
          )}
        </FilterGroups>
      )}
      {isEmpty(paginatedItems) ? (
        <>
          {mode === Mode.ADD && <Header isSelected>{inputComponent}</Header>}
          <EmptyText>
            <span>
              <Trans>Sorry! Nothing available.</Trans>
            </span>
          </EmptyText>
        </>
      ) : (
        <div>
          <Header isSelected={!isEmpty(selectedItems)}>
            <button className="left" onClick={checkAllItems}>
              {/* @ts-ignore */}
              <CheckBox
                checked={isAllItemsSelected}
                size={20}
                defaultColor={!isEmpty(selectedItems) && COLORS.TEXT_WHITE}
              />
              <span className="name">
                {isEmpty(selectedItems)
                  ? i18n._(t`NAME CATEGORY`)
                  : i18n._(t`${size(selectedItems)} SELECTED`)}
              </span>
            </button>
            <div className="right">
              {isRenameVisible && (
                <button
                  className="action"
                  onClick={() => {
                    rename(selectedItems[0]);
                  }}
                >
                  <Pencil2Icon size={14} />
                  <Trans>Rename</Trans>
                </button>
              )}
              {isDeleteVisible && (
                <button className="action" onClick={() => setIsDeleteModalVisible(true)}>
                  <TrashIcon size={14} />
                  <Trans>Delete</Trans>
                </button>
              )}
            </div>
          </Header>
          {mode === Mode.ADD && <Header isSelected>{inputComponent}</Header>}
          {map(paginatedItems, (item) => {
            const isSelected = selectedItems?.indexOf(item.id) !== -1;

            return renameItem?.id === item.id ? (
              <Header key={item.id} isSelected>
                {inputComponent}
              </Header>
            ) : (
              <Row
                onClick={() => checkItem(item)}
                key={item.id}
                isSelected={isSelected}
                isFade={fadeItem?.id === item.id}
              >
                {/* @ts-ignore */}
                <CheckBox checked={isSelected} size={20} />
                <span className="name">{item.name}</span>
              </Row>
            );
          })}
        </div>
      )}
      <PaginationBar
        pagination={pagination}
        changePagination={changePagination}
        count={filteredItems.length}
        showCount
        noBorder
        noShadow
      />
      {isDeleteModalVisible && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.DELETE}
          onClose={() => setIsDeleteModalVisible(false)}
          description={i18n._(
            selectedItems.length > 1
              ? t`Are you sure you want to delete these categories. This will remove these categories label from all connected activities. This action cannot be undone.`
              : t`Are you sure you want to delete this category. This will remove this category label from all connected activities. This action cannot be undone.`,
          )}
          onSubmit={() => onDelete(selectedItems)}
        />
      )}
    </Container>
  );
};

export { SelectList };
