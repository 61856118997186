import React from 'react';

import Tippy from '@tippyjs/react';
import _, { isEmpty } from 'lodash';
import styled from 'styled-components';

import Tooltip from '~/components/Tooltip';

import { COLORS, COLOR_PALETTE } from '~/styles';

const AllProgress = styled.div`
  display: flex;
  width: 100%;
  max-width: ${(props) => (props.$maxWidth ? props.$maxWidth : props.isSmall ? '283px' : '100%')};
  position: absolute;
  left: 0;
  top: 0;
  height: ${(props) => (props.isSmall ? '8px' : '10px')};
  overflow: hidden;
  box-sizing: border-box;
  border-radius: ${(props) => (props.isSmall ? '4px' : '12.7px')};
  ${(props) => !props.isSmall && `border: solid 1px ${COLORS.BORDER_HARD};`}
  background-color: ${(props) =>
    props.$backgroundColor
      ? props.$backgroundColor
      : props.isSmall
      ? COLOR_PALETTE.WHITE
      : COLORS.BG_PAGE};
`;

const TooltipWrapper = styled.div`
  padding: 7px 4px;

  & .title {
    font-weight: 600;
  }
`;

const TooltipItem = styled.span`
  display: flex;
  align-items: center;
  margin: 8px 0 0;
`;

const TooltipItemPercentage = styled.span`
  font-weight: 600;
  font-size: 14px;
  margin: 0 10px;
`;

const TooltipItemTitle = styled.span`
  font-size: 14px;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const DoneProgress = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  height: ${(props) => (props.isSmall ? '8px' : '10px')};
  width: ${(props) => props.$width}%;
  ${(props) => !props.isSmall && `border: solid 1px ${COLORS.BORDER_HARD};`}
  border-radius: ${(props) => (props.isSmall ? '4px' : '12.7px')};
  background-color: ${COLORS.BORDER_HARD};
  ${(props) =>
    !props.$isFullWidth &&
    `border-top-right-radius: 0;
  border-bottom-right-radius: 0;`}
`;

const DotRow = styled.div`
  position: absolute;
  left: ${(props) => (props.$left ? `${props.$left}px` : 0)};
  top: -6px;
  width: ${(props) => props.$width}%;
  text-align: right;
`;

const Dot = styled.span`
  background-color: ${(props) => props.$color};
  display: inline-block;
  width: ${(props) => (props.isSmall ? '6px' : '8px')};
  height: ${(props) => (props.isSmall ? '19px' : '20px')};
  border-radius: 21px;
  ${(props) => !props.isSmall && 'box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);'}
  -webkit-print-color-adjust: exact;
  filter: opacity(1);
  -webkit-filter: opacity(1);
`;

const Label = styled.div`
  position: relative;
  top: -46px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};

  // cut with ...
  max-width: ${(props) => props.$maxWidth + 'px'};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const LabelsRow = styled.div`
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
`;

const LabelWrap = styled.span`
  transform: translateX(-50%);
  left: ${(props) => props.progress}%;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
`;

const Rates = styled.div`
  display: flex;
  width: 100%;
  max-width: ${(props) => (props.$maxWidth ? props.$maxWidth : props.isSmall ? '283px' : '100%')};
  position: absolute;
  left: 0;
  top: 0;
`;

const Row = styled.div`
  display: flex;
  position: relative;
  ${(props) => props.isSmall && 'margin-top: 9px;'}
`;

const Wrapper = styled.div``;

const maxWidthOptions = {
  5: 100,
  6: 90,
  7: 80,
  8: 70,
  9: 60,
  10: 50,
};

const SkillProgressBar = ({
  title,
  items,
  isSmall,
  isShowLabels,
  scale,
  scaleLabels,
  isOnlySelectedLabel,
  $backgroundAllProgress,
  $maxWidth,
  expectedLevel,
  isForceProgress = false, // just use what you got, without any manipulations
}) => {
  // expected rate doesn't have color
  const rates = Object.values(items).filter((i) => i.color);
  const ratesGroup = _.groupBy(rates, 'progress');
  const ratesWithoutExpectedLevel = rates.map((item) => {
    if (ratesGroup[item.progress].length > 1) {
      return {
        ...item,
        $left:
          ratesGroup[item.progress].findIndex((i) => i.color === item.color) * (isSmall ? 6 : 8),
      };
    }

    return item;
  });

  // labels
  const maxWidth = maxWidthOptions[scale?.length] || 100;
  const marks =
    isShowLabels &&
    !isEmpty(scale) &&
    scale.map((_value, index) => {
      const progress = Math.round(((index + 1) / scale.length) * 100);
      const isSelected = Object.keys(ratesGroup).includes(String(progress));
      return {
        progress,
        label:
          isOnlySelectedLabel && !isSelected ? (
            ''
          ) : (
            <Tooltip tooltip={scaleLabels[index]}>
              <Label $maxWidth={maxWidth}>{scaleLabels[index]}</Label>
            </Tooltip>
          ),
      };
    });

  const tipOptions = {
    modifiers: [
      {
        name: 'computeStyles',
        options: {
          gpuAcceleration: false,
        },
      },
    ],
  };

  const ProgressBarTooltip = (
    <TooltipWrapper>
      <div className="title">{title}</div>
      {ratesWithoutExpectedLevel.map(
        (item) =>
          item.progress > 0 && (
            <TooltipItem key={Math.random()}>
              <Dot $color={item.color} isSmall={isSmall} />
              <TooltipItemPercentage>
                {isForceProgress
                  ? `${item?.progress}%`
                  : `${((item?.progress / items.avgExpectedLevel?.progress) * 100).toFixed(0)}%`}
              </TooltipItemPercentage>
              <TooltipItemTitle>{item.title}</TooltipItemTitle>
            </TooltipItem>
          ),
      )}
    </TooltipWrapper>
  );

  const isTooltipDisabled = ratesWithoutExpectedLevel.reduce((prev, curr) => {
    if (curr.progress) {
      prev = false;
    }

    return prev;
  }, true);

  return (
    <Tippy
      popperOptions={tipOptions}
      theme="light"
      maxWidth={206}
      content={ProgressBarTooltip}
      disabled={!isSmall || isTooltipDisabled}
    >
      <Wrapper>
        {!isSmall && isShowLabels && !isEmpty(marks) && (
          <Row>
            <LabelsRow>
              {marks.map((mark, i) => (
                <LabelWrap key={i} progress={mark.progress}>
                  {mark.label}
                </LabelWrap>
              ))}
            </LabelsRow>
          </Row>
        )}
        <Row isSmall={isSmall}>
          <AllProgress
            $maxWidth={$maxWidth}
            $backgroundColor={$backgroundAllProgress}
            isSmall={isSmall}
          >
            {(items.avgExpectedLevel || expectedLevel) && (
              <DoneProgress
                isSmall={isSmall}
                $width={items.avgExpectedLevel.progress || expectedLevel}
                $isFullWidth={Number(items.avgExpectedLevel.progress || expectedLevel) === 100}
              />
            )}
          </AllProgress>
          <Rates $maxWidth={$maxWidth} isSmall={isSmall}>
            {ratesWithoutExpectedLevel.map((item, idx) => {
              let width = item?.progress;

              // calc right position on force depends on max possible width
              if (isForceProgress) {
                const max = items?.maxPossibleLevel?.progress;
                width = Math.round((item.progress / max) * 100).toFixed(0);
              }

              return item.progress ? (
                <DotRow key={idx} $width={width} $left={item.$left}>
                  <Dot $color={item.color} isSmall={isSmall} />
                </DotRow>
              ) : (
                <></>
              );
            })}
          </Rates>
        </Row>
      </Wrapper>
    </Tippy>
  );
};

export default SkillProgressBar;
