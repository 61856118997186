import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getUserActivities() {
  return cloudRequest(serverRoutes.userActivities.getUserActivities);
}

export function getUserActivity(id, populate = [], join = []) {
  return cloudRequest(serverRoutes.userActivities.getUserActivity(id), { populate, join });
}

export function getUserActivityByParentId(id) {
  return cloudRequest(serverRoutes.userActivities.getUserActivityByParentId(id));
}

export function getSubLibraryActivities(id) {
  return cloudRequest(serverRoutes.userActivities.getSubLibraryActivities(id));
}

export function getPersonalUserActivities(filters = {}) {
  return cloudRequest(serverRoutes.userActivities.getPersonalUserActivities, filters);
}

export function createUserActivityFromLibrary(id, type, { targets, forUser } = {}) {
  return cloudRequest(serverRoutes.userActivities.createUserActivityFromLibrary(id), {
    type,
    targetIds: targets,
    forUser,
  });
}

export function deleteUserActivity(id, type) {
  return cloudRequest(serverRoutes.userActivities.deleteUserActivity(id), { type });
}

export function updateUserActivityProgress(id, { progress = 0, status = '', comment = '' }) {
  return cloudRequest(
    serverRoutes.userActivities.updateUserActivityProgress(id),
    {},
    { progress, status, comment },
  );
}

export function removeUserActivityAttachment(id, fileId) {
  return cloudRequest(serverRoutes.userActivities.removeUserActivityAttachment(id), { fileId });
}

export function removeUserActivityGoals(id, goalId) {
  return cloudRequest(serverRoutes.userActivities.removeUserActivityGoals(id), {}, { goalId });
}
