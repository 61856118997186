import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Button from '~/components/Button';
import CheckBox from '~/components/CheckBox';
import SvgIcon from '~/components/SvgIcon';

import DeleteIcon from '~/assets/delete-icon.svg';
import DuplicateIcon from '~/assets/item-menu-icons/duplicate-menu.svg';

import { COLORS } from '~/styles';

import type { TMultiSelect } from '../';

const ActionButton = styled(Button)`
  color: ${COLORS.WHITE};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const CheckBoxContainer = styled.div`
  min-width: 20px;
  padding: 16px 0 16px 32px;
`;

const SelectedContainer = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--company-color);
`;

const SelectText = styled.div`
  margin-left: 20px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-transform: uppercase;
  color: ${COLORS.WHITE};
`;

const SelectActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  padding-right: 24px;
`;

interface ISelectedHeader {
  multiSelect: TMultiSelect;
}

const SelectedHeader = ({ multiSelect }: ISelectedHeader) => {
  const { i18n } = useLingui();

  return (
    <SelectedContainer>
      <CheckBoxContainer>
        <CheckBox
          size="20px"
          defaultColor={COLORS.WHITE}
          isDefaultColor={true}
          checked={multiSelect.isAllChecked}
          onChange={multiSelect.onCheckAll}
          className={undefined}
        />
      </CheckBoxContainer>
      <SelectText>{i18n._(t`${multiSelect.checkedCount} selected`)}</SelectText>
      <SelectActions>
        {Boolean(multiSelect.onDuplicate) && (
          <ActionButton
            /* @ts-ignore */
            type={Button.types.linkPrimary}
            iconLeft={
              <SvgIcon
                width="14px"
                height="14px"
                url={DuplicateIcon}
                isDefaultColor
                defaultColor={COLORS.WHITE}
              />
            }
            iconLeftStyles={{ marginRight: '4px' }}
            onClick={multiSelect.onDuplicate}
            label={i18n._(t`Duplicate`)}
            styles={{ marginRight: '8px' }}
          />
        )}
        {Boolean(multiSelect.onDelete) && (
          <ActionButton
            /* @ts-ignore */
            type={Button.types.linkPrimary}
            iconLeft={
              <SvgIcon
                width="14px"
                height="14px"
                url={DeleteIcon}
                isDefaultColor
                defaultColor={COLORS.WHITE}
              />
            }
            iconLeftStyles={{ marginRight: '4px' }}
            onClick={multiSelect.onDelete}
            label={i18n._(t`Delete`)}
          />
        )}
      </SelectActions>
    </SelectedContainer>
  );
};

export { SelectedHeader };
