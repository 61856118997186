import React, { FunctionComponent, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { IPopulatedUserPath, IPath, IUserActivity } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';

import PathSections from './PathSections';
import TopSideBarBlock from './TopSideBarBlock';

import { COLORS } from '~/styles';

const Wrapp = styled.div`
  min-width: 312px;
  max-width: 312px;
  box-sizing: border-box;
  border-right: solid 1px ${COLORS.BORDER_LIGHT};
  min-height: 100%;

  .show-more-path {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.13px;
    margin-top: -5px;
  }
`;

interface ISideBar {
  isUserPath: boolean;
  path: IPopulatedUserPath | IPath;
  onActivityClick: (activityId: IUserActivity['id']) => void;
  activeActivityId: string;
  isShowRemoveFromPlan: boolean;
  onRemoveFromPlan: () => void;
}

const SideBar: FunctionComponent<ISideBar> = ({
  path,
  isUserPath,
  onActivityClick,
  activeActivityId,
  isShowRemoveFromPlan,
  onRemoveFromPlan,
}) => {
  const { i18n } = useLingui();
  const [isShowWarningModal, setIsShowWarningModal] = useState(false);
  const pathProgress = isUserPath
    ? (path as IPopulatedUserPath).progress
    : // @ts-ignore
    path?.subPath
    ? // @ts-ignore
      path.subPath?.progress
    : 0;

  // @ts-ignore
  const pathActivites = path?.subPath ? path.subPath.activities : path.activities;
  // @ts-ignore
  const isOriginalPathWithoutSubPath = !isUserPath && !path?.subPath;
  return (
    <Wrapp>
      <TopSideBarBlock
        image={path.image}
        description={path.description}
        progress={pathProgress}
        status={path.status}
      />
      <PathSections
        sections={path.sections}
        activities={pathActivites as IUserActivity[]}
        activitiesProps={_.map(
          path.activitiesProps as Record<
            string,
            {
              order: number;
              isObligated: boolean;
              section: number;
              userActivity: IUserActivity;
            }
          >,
          (a, id) => ({ ...a, id }),
        )}
        onActivityClick={onActivityClick}
        activeActivityId={activeActivityId}
        isOriginalPathWithoutSubPath={isOriginalPathWithoutSubPath}
      />
      {isShowRemoveFromPlan && (
        <Button
          variant={ButtonVariant.TEXT_DELETE}
          size={ButtonSize.MEDIUM}
          label={i18n._(t`Remove path from my plan`)}
          onClick={() => setIsShowWarningModal(true)}
          icon={ICONS.DELETE_BIN}
        />
      )}
      {isShowWarningModal && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.WARNING}
          description={i18n._(t`This will remove all your progress. This action cannot be undone.`)}
          onClose={() => setIsShowWarningModal(false)}
          onSubmit={() => onRemoveFromPlan()}
        />
      )}
    </Wrapp>
  );
};

export default SideBar;
