import styled from 'styled-components';

import { COLOR_PALETTE } from '~/styles';

const Shadow = styled.div`
  border-radius: 6px;
  box-shadow: 0 8px 15px 0 rgba(149, 157, 165, 0.2);
  border: solid 1px ${COLOR_PALETTE.GRAY_SEMI_SOFT};
`;

export default Shadow;
