import { FocusAreaType } from '@learned/constants';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import type { ISelectedSkillTemplate, ISkillCategoryColumn } from '~/@types/job';

import type { IMultiLangString, ISkillCategory, ISkillTemplate } from '@learned/types';

export const convertSkillsDataToTable = ({
  skillTemplates,
  skills,
  skillCategory,
}: {
  skillTemplates: ISkillTemplate[];
  skillCategory?: ISkillCategory;
  skills: ISelectedSkillTemplate['skills'];
}) => {
  const result: Omit<ISkillCategoryColumn, 'onDelete' | 'onEdit'>[] = [];

  skills?.forEach((skill) => {
    const skillTemplate = skillTemplates.find(({ id }) => id === skill.skillTemplate);

    if (skillTemplate) {
      const skillRows: { focusArea: IMultiLangString; level: number }[] = [];

      skill.selectedFocusAreas.forEach((s) => {
        const levelFocusAreas = skillTemplate?.focusAreas?.filter(
          ({ level }) => s.level === level,
        )?.[0];

        if (s.type === FocusAreaType.SELECT_ALL) {
          // push all focus area ids
          skillTemplate.focusAreas.forEach((item) => {
            if (isEmpty(item?.values)) {
              skillRows.push({
                focusArea: { en_GB: '' },
                level: item.level,
              });
            } else {
              item?.values.forEach(({ name }) => {
                skillRows.push({
                  focusArea: name,
                  level: item.level,
                });
              });
            }
          });
        } else if (s.type === FocusAreaType.SELECT_LEVEL) {
          if (!isNil(s.level)) {
            if (s.type === FocusAreaType.SELECT_LEVEL && isEmpty(levelFocusAreas?.values)) {
              skillRows.push({
                focusArea: { name: '' },
                level: s.level,
              });
            } else {
              // push all focus area ids for a specific level
              levelFocusAreas?.values.forEach(({ name }) => {
                skillRows.push({
                  focusArea: name,
                  level: s.level!,
                });
              });
            }
          }
        } else if (s.type === FocusAreaType.SELECT_FOCUS_AREA) {
          // push specific focus area ids for a level
          levelFocusAreas?.values
            ?.filter(({ id }) => s.focusArea?.includes(id))
            .forEach(({ name }) => {
              skillRows.push({
                focusArea: name,
                level: s.level!,
              });
            });
        }
      });

      // push selected rows to the final result
      skillRows.forEach((item, index) => {
        result.push({
          id: skillTemplate.id,
          // put the name just for first row of skill template
          name: index === 0 ? skillTemplate.name : null,
          focusArea: item.focusArea,
          level: item?.level + 1,
          totalLevels: skillCategory?.skillLevels.length,
        });
      });
    }
  });

  return result;
};
