import React, { useEffect, useState } from 'react';

import { KPI_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import AutocompleteFilter from '~/components/AutocompleteFilter';
import {
  AutocompleteFilterTeams,
  AutocompleteFilterSkills,
} from '~/components/AutocompleteFilters';
import Button from '~/components/Button';
import SectionTemplate from '~/components/Graphs/components/SectionTemplate';
import PaginationBar from '~/components/PaginationBar';
import Placeholder from '~/components/Placeholder';
import ProgressBarWide from '~/components/ProgressBarWide';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';
import Tooltip from '~/components/Tooltip';
import Table, { TableRow, TableCol } from '~/components/UI/Table';
import { Header5 } from '~/components/UI/Typographics/headers';

import SkillsIcon from '~/assets/mdi-tag-multiple.svg';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import getLang from '~/selectors/getLang';
import { getSkillsReport } from '~/services/skills';
import { COLORS, COLOR_PALETTE } from '~/styles';
import { replacerForURL } from '~/utils/replacerForURL';
import { getSkillName } from '~/utils/skillUtils';

const Filters = styled.div`
  display: flex;
  padding: 17px 24px 16px;
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_MIDDLE};
`;

const FilterTeams = styled(AutocompleteFilterTeams)`
  width: 157px;
  height: 32px;
`;

const FilterSkills = styled(AutocompleteFilterSkills)`
  width: 157px;
  height: 32px;
  margin-left: 8px;
`;

const FilterReviews = styled(AutocompleteFilter)`
  width: 300px;
  height: 32px;
  margin-right: 8px;
`;

const ProgressRow = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
`;

const ProgressBarWrapper = styled.div`
  width: 60%;
`;

const PaginationWrapper = styled.div`
  border-top: 1px solid ${COLORS.BG_PAGE};
`;

const TextCol = styled(TableCol)`
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.TEXT_SECONDARY};
  overflow: hidden;
  white-space: nowrap;
`;

const AvgLevel = styled.div`
  display: flex;
  align-items: baseline;
`;

const AvgLevelLabel = styled.div``;

const AvgLevelSubLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin-left: 4px;
`;

const ProgressValue = styled.span`
  color: ${COLORS.TEXT_SECONDARY};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  margin-left: 12px;
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin-top: 31px;
`;

const APPLIED_FILTERS_DEFAULT = {
  selectedReviews: [],
  selectedTeams: [],
  selectedSkills: [],
};

const PAGE_SIZE = 25;

const defaultPagination = {
  skip: 0,
  limit: PAGE_SIZE,
  index: 1,
};

function PerSkillSection({
  i18n,
  selectedSkills,
  setSelectedSkills,
  reviewsList,
  selectedReviews,
  setSelectedReviews,
  selectedTeams,
  setSelectedTeams,
}) {
  const [pagination, setPagination] = useState(defaultPagination);
  const [skillCount, setSkillsCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState(APPLIED_FILTERS_DEFAULT); // uses for disable apply button
  const isLatest = false; // now it's always false, for Skill 2 report it was true, but we deleted skill 2 report

  const $fetching = useBoolState(true);
  const lang = useSelector(getLang);

  const isFilterSame = () => {
    const currentFilters = {
      selectedReviews,
      selectedTeams,
      selectedSkills,
    };

    return isEqual(appliedFilters, currentFilters);
  };

  const fetchData = async (pagination = defaultPagination) => {
    $fetching.on();
    let skillsFilter = [];
    let membersFilter = [];
    const { skip, limit } = pagination;

    if (!isEmpty(selectedSkills)) {
      skillsFilter = skillsFilter.concat(selectedSkills.map((r) => r.id));
    }

    if (!isEmpty(selectedTeams)) {
      membersFilter = membersFilter.concat(selectedTeams.map((t) => t.members));
    }
    membersFilter = membersFilter.flat();

    const res = await getSkillsReport({
      isLatest,
      reviewId: selectedReviews[0].id,
      pagination: { skip, limit },
      sectionTypes: [KPI_TYPES.SKILLS],
      ...(!isEmpty(skillsFilter) && { skills: uniq(skillsFilter) }),
      ...(!isEmpty(membersFilter) && { members: uniq(membersFilter) }),
    });

    setSkillsCount(res.count);
    setTableData(res.data);
    setAppliedFilters({
      selectedReviews,
      selectedTeams,
      selectedSkills,
    });
    setPagination(pagination);
    $fetching.off();
  };

  const onPageChangeClick = ({ skip, limit, index }) => {
    return fetchData({ skip, limit, index });
  };

  useEffect(() => {
    const getData = async () => {
      await fetchData();
    };
    getData();
    // eslint-disable-next-line
  }, []);

  const getDefaultCols = () => [
    { name: 'skill', width: '200px' },
    { name: 'people', width: '50px' },
    { name: 'self-avg', width: '100px' },
    { name: 'self-coverage', width: '120px' },
    { name: 'peer-avg', width: '100px' },
    { name: 'peer-coverage', width: '120px' },
    { name: 'coach-avg', width: '100px' },
    { name: 'coach-coverage', width: '120px' },
  ];

  const getCols = () => {
    return getDefaultCols().map((c, index) => {
      switch (index) {
        case 0:
          c.title = i18n._(t`Skill`);
          break;
        case 1:
          c.title = i18n._(t`People`);
          break;
        case 2:
        case 4:
        case 6:
          c.title = i18n._(t`AVG LVL.`);
          break;
        case 3:
        case 5:
        case 7:
          c.title = i18n._(t`Skill coverage`);
          break;
        default:
          break;
      }
      return c;
    });
  };

  const getColsSecond = () => {
    return getDefaultCols().map((c, index) => {
      switch (index) {
        case 2:
          c.title = i18n._(t`SELF REVIEW`);
          break;
        case 4:
          c.title = i18n._(t`PEER REVIEW`);
          break;
        case 6:
          c.title = i18n._(t`COACH REVIEW`);
          break;
        default:
          break;
      }
      return c;
    });
  };

  const renderSkillRow = (item) => {
    return (
      <TableRow
        key={item.id}
        onClick={() =>
          routes.SKILL_REPORT.go(
            {},
            {
              skillId: item.id,
              query: { review: replacerForURL(JSON.stringify(get(selectedReviews, '[0]'))) },
            },
          )
        }
        $border
      >
        <TableCol pointer>
          <Header5>{getSkillName(item, lang)}</Header5>
        </TableCol>
        <TextCol pointer>
          {item.members} ({item.percent}%)
        </TextCol>

        <TextCol pointer>
          <Tooltip
            tooltip={
              item.self.label && `${item.self.label} ${item.self.avg_lvl}/${item.requiredLevel.max}`
            }
          >
            <AvgLevel>
              <AvgLevelLabel>{item.self.label}</AvgLevelLabel>
              <AvgLevelSubLabel>
                {item.self.label && `${item.self.avg_lvl}/${item.requiredLevel.max}`}
              </AvgLevelSubLabel>
            </AvgLevel>
          </Tooltip>
        </TextCol>
        <TableCol pointer>
          <ProgressRow>
            <ProgressBarWrapper>
              <ProgressBarWide
                value={item.self.avg}
                backgroundColor={COLORS.SELF_COVERAGE_BG}
                customStatusColor={COLORS.SELF_COVERAGE_STATUS}
                rounded
                isActive
                height="12px"
              />
            </ProgressBarWrapper>
            <ProgressValue>{item.self.avg || 0}%</ProgressValue>
          </ProgressRow>
        </TableCol>

        <TextCol pointer>
          <Tooltip
            tooltip={
              item.peer.label && `${item.peer.label} ${item.peer.avg_lvl}/${item.requiredLevel.max}`
            }
          >
            <AvgLevel>
              <AvgLevelLabel>{item.peer.label}</AvgLevelLabel>
              <AvgLevelSubLabel>
                {item.peer.label && `${item.peer.avg_lvl}/${item.requiredLevel.max}`}
              </AvgLevelSubLabel>
            </AvgLevel>
          </Tooltip>
        </TextCol>
        <TableCol pointer>
          <ProgressRow>
            <ProgressBarWrapper>
              <ProgressBarWide
                value={item.peer.avg}
                backgroundColor={COLORS.PEER_COVERAGE_BG}
                customStatusColor={COLORS.PEER_COVERAGE_STATUS}
                rounded
                isActive
                height="12px"
              />
            </ProgressBarWrapper>
            <ProgressValue>{item.peer.avg || 0}%</ProgressValue>
          </ProgressRow>
        </TableCol>

        <TextCol pointer>
          <Tooltip
            tooltip={
              item.coach.label &&
              `${item.coach.label} ${item.coach.avg_lvl}/${item.requiredLevel.max}`
            }
          >
            <AvgLevel>
              <AvgLevelLabel>{item.coach.label}</AvgLevelLabel>
              <AvgLevelSubLabel>
                {item.coach.label && `${item.coach.avg_lvl}/${item.requiredLevel.max}`}
              </AvgLevelSubLabel>
            </AvgLevel>
          </Tooltip>
        </TextCol>
        <TableCol pointer>
          <ProgressRow>
            <ProgressBarWrapper>
              <ProgressBarWide
                value={item.coach.avg}
                backgroundColor={COLORS.COACH_COVERAGE_BG}
                customStatusColor={COLORS.COACH_COVERAGE_STATUS}
                rounded
                isActive
                height="12px"
              />
            </ProgressBarWrapper>
            <ProgressValue>{item.coach.avg || 0}%</ProgressValue>
          </ProgressRow>
        </TableCol>
      </TableRow>
    );
  };

  return (
    <SectionTemplate
      title={i18n._(t`Per skill`)}
      description={i18n._(t`Avg level and Skill coverage per skill for the selected review`)}
    >
      <Filters>
        {!isEmpty(reviewsList) && (
          <FilterReviews
            placeholder={<Trans>Reviews</Trans>}
            fetch={() => reviewsList}
            checkedList={selectedReviews}
            onChange={(arr) => setSelectedReviews(arr.slice(-1))} // only 1 review can be selected
            isDeleteDisabled={true}
            showTooltip
          />
        )}
        <FilterTeams checkedList={selectedTeams} onChange={setSelectedTeams} />
        <FilterSkills checkedList={selectedSkills} onChange={setSelectedSkills} />
        <Button
          label={i18n._(t`Apply`)}
          onClick={(_e) => fetchData()}
          styles={{ marginLeft: 'auto', width: '83px', height: '32px' }}
          disabled={isFilterSame()}
          loading={$fetching.value}
        />
      </Filters>
      <ShowSpinnerIfLoading loading={$fetching.value}>
        {!isEmpty(tableData) ? (
          <Table
            cols={getCols()}
            colsSecond={getColsSecond()}
            items={tableData}
            renderRow={renderSkillRow}
            hideHeader
            noTopBorder
          />
        ) : (
          <Placeholder
            title={i18n._(t`No data available yet`)}
            subTitle={i18n._(
              t`Start with measuring skill coverage in your company by linking skill coverage to questions in a review template. `,
            )}
            Icon={() => (
              <StyledSvgIcon
                url={SkillsIcon}
                width={'32px'}
                height={'32px'}
                isDefaultColor
                defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
              />
            )}
            subTitleStyles={{
              width: '435px',
              color: COLOR_PALETTE.DARK_GRAY,
              fontWeight: 600,
              lineHeight: 1.57,
            }}
          />
        )}
        <PaginationWrapper>
          <PaginationBar
            pagination={pagination}
            changePagination={onPageChangeClick}
            noBorder
            noTopBorder
            count={skillCount}
            showCount
          />
        </PaginationWrapper>
      </ShowSpinnerIfLoading>
    </SectionTemplate>
  );
}

PerSkillSection.propTypes = {
  selectedSkills: PropTypes.arrayOf(PropTypes.object),
  setSelectedSkills: PropTypes.func,
  selectedReviews: PropTypes.arrayOf(PropTypes.object),
  setSelectedReviews: PropTypes.func,
  selectedTeams: PropTypes.arrayOf(PropTypes.object),
  setSelectedTeams: PropTypes.func,
};

export default withI18n()(PerSkillSection);
