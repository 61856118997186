import { SURVEY_QUESTION_TYPES } from '@learned/constants';

import { turnMultiLangIntoArray } from './turnMultiLangIntoArray';

import type { IQuestionForm } from '~/@types/question';
import { COLORS } from '~/styles';

import type {
  ILanguage,
  IReviewQuestion,
  ISurveyQuestion,
  ISurveyQuestionDefaultData,
  ISurveyQuestionSmileysData,
  ISurveyQuestionStarsData,
} from '@learned/types';

const LIGHTS = [{ color: COLORS.ERROR }, { color: COLORS.ORANGE }, { color: COLORS.GREEN }];

export function convertQuestionOptions(
  question: ISurveyQuestion | IReviewQuestion,
  companyLanguages: ILanguage[],
): IQuestionForm['options'] {
  switch (question.type) {
    case SURVEY_QUESTION_TYPES.SINGLE:
    case SURVEY_QUESTION_TYPES.MULTIPLE:
      return (question.data as ISurveyQuestionDefaultData).options.map((option) => ({
        label: turnMultiLangIntoArray(option.label, companyLanguages),
      }));
    case SURVEY_QUESTION_TYPES.TRAFFIC:
      return (question.data as ISurveyQuestionDefaultData).options.map((option, index) => ({
        icon: LIGHTS[index].color,
        label: turnMultiLangIntoArray(option.label, companyLanguages),
      }));
    case SURVEY_QUESTION_TYPES.SMILEYS:
      return (question.data as ISurveyQuestionSmileysData).options.map((option) => ({
        icon: option.icon,
        label: turnMultiLangIntoArray(option.label, companyLanguages),
      }));
    case SURVEY_QUESTION_TYPES.STARS: {
      const data = question.data as ISurveyQuestionStarsData;
      const options: IQuestionForm['options'] = new Array(data.size).fill(null);

      options.map((_, index) => {
        if (index === 0) {
          options[index] = {
            label: turnMultiLangIntoArray(data.firstLabel, companyLanguages),
          };
        } else if (index === options.length - 1) {
          options[options.length - 1] = {
            label: turnMultiLangIntoArray(data.lastLabel, companyLanguages),
          };
        } else {
          options[index] = {
            label: [],
          };
        }
      });

      return options;
    }
    case SURVEY_QUESTION_TYPES.TEXT:
    default:
      return [];
  }
}
