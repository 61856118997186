import React, { ReactNode } from 'react';

import { ICompany } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { LogoForEmail } from '~/pages/CompanySettings/sections/Branding/LogoForEmail';

import { Section } from '../../components/Section';
import { SectionRow } from '../../components/SectionRow';

interface IBrandingSectionProps {
  company: ICompany;
  uploadLogoActions: () => ReactNode;
  updatePrimaryColorActions: () => ReactNode;
  onChange: (key: string, value: any) => void;
  onUploadFile: (key: string, file: File | null) => void;
}

const BrandingSection = ({
  company,
  uploadLogoActions,
  updatePrimaryColorActions,
  onChange,
  onUploadFile,
}: IBrandingSectionProps) => {
  const { i18n } = useLingui();

  const fields = [
    {
      title: i18n._(t`Logo`),
      actions: uploadLogoActions(),
    },
    {
      title: i18n._(t`Logo for email notifications`),
      toggle: {
        value: company.emailLogoEnabled as boolean,
        onChange: (value: boolean) => onChange('emailLogoEnabled', value),
      },
      actions: (
        <LogoForEmail
          value={company?.logoEmailUrl}
          onChange={(file) => onUploadFile('logoEmailUrl', file)}
          onReset={() => onUploadFile('logoEmailUrl', null)}
        />
      ),
    },
    {
      title: i18n._(t`Customize primary color`),
      actions: updatePrimaryColorActions(),
    },
  ];

  return (
    <Section title={i18n._(t`Branding`)}>
      {fields.map((item, key) => (
        <SectionRow key={key} {...item} />
      ))}
    </Section>
  );
};

export { BrandingSection };
