import { FocusAreaType } from '@learned/constants';
import { isEmpty } from 'lodash';
import isNil from 'lodash/isNil';

import {
  type ISkillCategoryColumn,
  ISelectedSkill,
  ISkillCategory,
  ISkill,
} from '~/pages/ReviewThemeSetup/types';

import type { IMultiLangString } from '@learned/types';

export const convertSkillsDataToTable = ({
  skills,
  selectedSkills,
  setIsSkillModalOpen,
  skillCategories,
  setSelectedSkill,
  setCurrentStep,
  setSelectedSkills,
}: {
  skills: ISkill[];
  selectedSkills: ISelectedSkill[];
  setIsSkillModalOpen: (open: boolean) => void;
  skillCategories: ISkillCategory[];
  setSelectedSkill?: (skill: ISkill) => void;
  setCurrentStep?: (step: number) => void;
  setSelectedSkills: (skills: ISelectedSkill[]) => void;
}) => {
  const result: ISkillCategoryColumn[] = [];

  selectedSkills?.forEach((selectedSkill) => {
    const skill = skills.find(({ id }) => id === selectedSkill.skill);
    const skillCategory = skillCategories.find(
      (skillCategory) => skillCategory.value === skill?.skillCategory,
    );

    if (skill) {
      const skillRows: { focusArea: IMultiLangString; level: number }[] = [];
      const baseResultItem = {
        id: skill.id,
        onDelete: () => {
          setSelectedSkills(
            selectedSkills.filter((selectedSkill) => selectedSkill.skill !== skill.id),
          );
        },
        onEdit: () => {
          if (skill) {
            setSelectedSkill?.(skill);
            setCurrentStep?.(1);
            setIsSkillModalOpen(true);
          }
        },
      };

      selectedSkill.selectedFocusAreas.forEach((s) => {
        const levelFocusAreas = skill?.focusAreas?.filter(({ level }) => s.level === level)?.[0];

        if ((s as any).type === FocusAreaType.SELECT_ALL) {
          // push all focus area ids
          skill.focusAreas.forEach((item) => {
            if (isEmpty(item?.values)) {
              skillRows.push({
                focusArea: { en_GB: '' },
                level: item.level,
              });
            } else {
              item?.values.forEach(({ name }) => {
                skillRows.push({
                  focusArea: name,
                  level: item.level,
                });
              });
            }
          });
        } else if ((s as any).type === FocusAreaType.SELECT_LEVEL) {
          if (!isNil(s.level)) {
            if (
              (s as any).type === FocusAreaType.SELECT_LEVEL &&
              isEmpty(levelFocusAreas?.values)
            ) {
              skillRows.push({
                focusArea: { name: '' },
                level: s.level,
              });
            } else {
              // push all focus area ids for a specific level
              levelFocusAreas?.values.forEach(({ name }) => {
                skillRows.push({
                  focusArea: name,
                  level: s.level!,
                });
              });
            }
          }
        } else if ((s as any).type === FocusAreaType.SELECT_FOCUS_AREA) {
          // push specific focus area ids for a level
          levelFocusAreas?.values
            .filter(({ id }) => (s as any).focusArea?.includes(id))
            .forEach(({ name }) => {
              skillRows.push({
                focusArea: name,
                level: s.level!,
              });
            });
        }
      });

      // push selected rows to the final result
      skillRows.forEach((item, index) => {
        result.push({
          ...baseResultItem,
          // put the name just for first row of skill template
          name: index === 0 ? skill.name : null,
          focusArea: item.focusArea,
          level: item?.level + 1,
          totalLevels: skillCategory?.levels,
        });
      });
    }
  });

  return result;
};
