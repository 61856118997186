import React, { ReactNode, useMemo } from 'react';

import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

interface IColorByPercentageProps {
  children: ReactNode;
  value?: number | null;
  isFixed?: boolean;
}

const Wrapper = styled.div`
  position: relative;
  height: 59px;
`;

const Color = styled.div<{ backgroundColors?: string[]; opacity?: number }>`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  ${({ backgroundColors }) =>
    backgroundColors?.length === 2
      ? css`
          background: linear-gradient(to bottom, ${backgroundColors[0]}, ${backgroundColors[1]});
        `
      : backgroundColors?.length === 1
      ? css`
          background-color: ${backgroundColors[0]};
        `
      : css``};

  opacity: ${({ opacity }) => opacity || 1};
`;

const Content = styled.div<{ color?: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ color }) => color || COLORS.BLACK};
  width: 100%;
`;

const ColorByPercentage = ({ children, value, isFixed }: IColorByPercentageProps) => {
  const backgroundColors = useMemo(() => {
    if (value === undefined || value === null) {
      return [];
    }

    if (value < 20) {
      return ['#ffb7a1', '#ffa68f'];
    }
    if (value >= 20 && value < 40) {
      // TODO: put the second color when UI is ready
      return ['#fed0c4'];
    }
    if (value >= 40 && value < 60) {
      return ['#fff7d4', '#ffdfc4'];
    }
    if (value >= 60 && value < 80) {
      return ['#c0ece5', '#8cd5c6'];
    }
    if (value >= 80) {
      return ['#53e8d5', '#29ccab'];
    }
  }, [value]);

  return (
    <Wrapper>
      <Color backgroundColors={backgroundColors} opacity={isFixed ? 1 : 0.33} />
      <Content color={!backgroundColors?.length ? COLORS.INACTIVE : COLORS.BLACK}>
        {children}
      </Content>
    </Wrapper>
  );
};

export { ColorByPercentage };
