import React, { useEffect, useState } from 'react';

import { FocusAreaType } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { flatten, intersection, isEmpty, isNil } from 'lodash';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import { MultiLayerList } from '~/components/MultiLayerList';

import {
  CancelButton,
  Content,
  Footer,
  Header,
  HeaderContainer,
  Line,
  SubTitle,
  NextButton,
  Title,
  Wrapper,
  BackButton,
  RightActions,
} from './design';

import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';

import type { ISelectedFocusArea, ISelectedSkill } from '../../types';
import type { ISkill } from '@learned/types';

interface IProps {
  selectedSkill: ISkill;
  selectedSkills: ISelectedSkill[];
  onClose: () => void;
  setStep: (step: number) => void;
  onSubmit: (selectedFocusAreas: ISelectedSkill) => void;
}
const FocusAreasStep = ({ selectedSkill, onClose, setStep, onSubmit, selectedSkills }: IProps) => {
  const { i18n } = useLingui();
  const $isValid = useBoolState(false);

  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedLevels, setSelectedLevels] = useState<string[]>([]);
  const getMultiLangString = useMultiLangString();

  const focusAreas = selectedSkill?.focusAreas || [];

  const handleSubmit = () => {
    let selectedFocusAreas: ISelectedFocusArea[] = [];
    if (selectedValues.length || selectedLevels.length) {
      // select all levels
      if (selectedLevels.length === focusAreas.length) {
        selectedFocusAreas = [{ type: FocusAreaType.SELECT_ALL }];
      } else {
        focusAreas.forEach((focusArea, index) => {
          const ids = focusArea.values?.map(({ id }) => id);
          const commonIds = intersection(ids, selectedValues);

          // select level
          if (selectedLevels.includes(index.toString())) {
            selectedFocusAreas.push({
              type: FocusAreaType.SELECT_LEVEL,
              level: index,
            });
          } else {
            // select focus areas
            if (commonIds.length) {
              selectedFocusAreas.push(
                ...commonIds.map((id: string) => {
                  return {
                    type: FocusAreaType.SELECT_FOCUS_AREA,
                    level: index,
                    focusArea: id,
                  };
                }),
              );
            }
          }
        });
      }
    }

    onSubmit({ skill: selectedSkill.id, selectedFocusAreas });
  };

  useEffect(() => {
    selectedLevels.length > 0 || selectedValues.length > 0 ? $isValid.on() : $isValid.off();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLevels, selectedValues]);

  useEffect(() => {
    const focusAreasIds: string[] = [];
    const levelIds: string[] = [];

    const currentSkill = selectedSkills.find((skill) => skill.skill === selectedSkill.id);

    currentSkill?.selectedFocusAreas?.forEach((item) => {
      if (
        item.type === FocusAreaType.SELECT_FOCUS_AREA &&
        !isEmpty(item.focusArea) &&
        !isNil(item.focusArea)
      ) {
        focusAreasIds.push(item.focusArea);
      } else if (item.type === FocusAreaType.SELECT_LEVEL && !isNil(item.level)) {
        levelIds.push(item.level.toString());
        focusAreasIds.push(...selectedSkill.focusAreas[item.level].values.map(({ id }) => id));
      } else if (item.type === FocusAreaType.SELECT_ALL) {
        levelIds.push(...selectedSkill.focusAreas.map(({ level }) => level.toString()));
        focusAreasIds.push(
          ...flatten(selectedSkill.focusAreas.map(({ values }) => values.map(({ id }) => id))),
        );
      }
    });
    setSelectedValues(focusAreasIds);
    setSelectedLevels(levelIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSkills, selectedSkill.focusAreas]);

  return (
    <Wrapper>
      <HeaderContainer>
        <Header>
          <Title>{i18n._(t`Add skill`)}</Title>
          <Button
            label={''}
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.CLOSE}
            onClick={onClose}
          />
        </Header>
        <SubTitle>{`Select level and focus area for skill: ${getMultiLangString(
          selectedSkill.name || '',
        )}`}</SubTitle>
        <Line />
      </HeaderContainer>
      <Content>
        <MultiLayerList
          columnName={i18n._(t`Levels & focus areas`)}
          selectedCounterLabel={i18n._(t`selected focus areas`)}
          selectedCounterParentLabel={i18n._(t`selected levels`)}
          counterLabel={i18n._(t`all focus areas`)}
          toggles={{
            hideItems: i18n._(t`Hide focus areas`),
            showItems: i18n._(t`Show focus areas`),
          }}
          data={
            selectedSkill?.focusAreas?.map((item, index) => ({
              id: `${index}`,
              name: `Level ${++index}`,
              subItems: item.values?.map((value: any) => ({
                id: value.id,
                name: getMultiLangString(value.name),
              })),
            })) as any
          }
          selectedItems={selectedValues}
          setSelectedItems={setSelectedValues}
          selectedParentItems={selectedLevels}
          setSelectedParentItems={setSelectedLevels}
          subItemsFontSize="14px"
        />
      </Content>
      <Footer>
        <BackButton type="button" onClick={() => setStep(0)}>
          <Icon icon={ICONS.BACK} size={ICON_SIZES.SMALL} />
          <span>{i18n._(t`Back`)}</span>
        </BackButton>
        <RightActions>
          <CancelButton type="button" onClick={onClose}>
            {i18n._(t`Cancel`)}
          </CancelButton>
          <NextButton className="submitButton" onClick={handleSubmit} disabled={!$isValid.value}>
            {i18n._(t`Add skill`)}
          </NextButton>
        </RightActions>
      </Footer>
    </Wrapper>
  );
};

export { FocusAreasStep };
