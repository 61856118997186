import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Avatar from '~/components/UI/Avatar';
import { Header5 } from '~/components/UI/Typographics/headers';
import UserDashboardUsersModal from '~/components/UserDashboardUsersModal';

import { COLOR_PALETTE } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarWrapper = styled.div`
  margin-left: ${(props) => (props.$isOverlappedAvatars ? '-10px' : 0)};
  &:first-child {
    margin-left: 0;
  }
`;

const MoreUsersNumber = styled(Header5)`
  display: flex;
  margin: 0;
  height: 32px;
  width: 28px;
  justify-content: center;
  align-items: center;

  color: ${COLOR_PALETTE.DARK_GRAY};

  ${(props) =>
    props.onClick && {
      cursor: 'pointer',
    }};
`;

class AvatarGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDetailsModal: false,
    };
  }

  static propTypes = {
    users: PropTypes.arrayOf(PropTypes.string),
    emails: PropTypes.arrayOf(PropTypes.string),
    usersGray: PropTypes.arrayOf(PropTypes.string), // for review, to display users with NOT done feedback
    maxUsersToShow: PropTypes.number,
    isGray: PropTypes.bool,
    size: PropTypes.number,
    onUserClick: PropTypes.func,
    isOverlappedAvatars: PropTypes.bool,
  };

  static defaultProps = {
    maxUsersToShow: 3,
    users: [],
    emails: [],
    usersGray: [],
    isGray: false,
    size: 32,
    isOverlappedAvatars: true,
  };

  renderUser = ({ userId, email } = {}, index) => {
    const { usersGray, isGray, size, onUserClick, isOverlappedAvatars } = this.props;

    return (
      <AvatarWrapper key={userId || email || index} $isOverlappedAvatars={isOverlappedAvatars}>
        <Avatar
          userId={userId}
          email={email}
          size={size}
          showTooltip={true}
          isGray={usersGray.includes(userId) || usersGray.includes(email) || isGray}
          onClick={onUserClick}
        />
      </AvatarWrapper>
    );
  };

  onMoreUsersClick = () => {
    const { onClick } = this.props;
    return onClick ? onClick() : this.setState({ isDetailsModal: true });
  };

  render = () => {
    const { maxUsersToShow, users, emails, i18n, onUserClick } = this.props;
    const { isDetailsModal } = this.state;

    let showUsers = users;
    let showEmails = emails;
    const moreUsers = users.length + emails.length - maxUsersToShow;

    if (maxUsersToShow > 0) {
      showUsers = showUsers.filter((_, index) => {
        return maxUsersToShow >= index + 1;
      });

      showEmails = showEmails.filter((_, index) => {
        return maxUsersToShow >= users.length + index + 1;
      });
    }

    return (
      <Wrapper>
        {showUsers.map((userId, index) => this.renderUser({ userId }, index))}
        {showEmails.map((email, index) => this.renderUser({ email }, index))}
        {moreUsers > 0 && (
          <MoreUsersNumber onClick={this.onMoreUsersClick}>{`+${moreUsers}`}</MoreUsersNumber>
        )}
        {isDetailsModal && (
          <UserDashboardUsersModal
            onClose={() => this.setState({ isDetailsModal: false })}
            users={users}
            title={i18n._(t`Users`)}
            onClick={onUserClick}
          />
        )}
      </Wrapper>
    );
  };
}

export default withI18n()(AvatarGroup);
