import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';

export default (collection) => {
  if (isEmpty(collection)) {
    return {};
  }
  return values(collection)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((a) => ({ [a.id]: a }))
    .reduce((a, b) => Object.assign(a, b), {});
};
