import React, { memo } from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Header6Active } from '~/components/UI/Typographics/headers';

import { COLORS, COLOR_PALETTE } from '~/styles';

const Action = styled(Header6Active)`
  cursor: pointer;
  margin: 0;
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;

  a {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    font-size: 12px;
    text-decoration: none;
    color: var(--company-color);
  }
`;

const Title = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  text-align: center;
  color: ${COLOR_PALETTE.BLACK};
`;

const SubTitle = styled.div`
  font-size: 14px;
  line-height: 1.71;
  text-align: center;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const Container = styled.div`
  padding: 25px;
  border: ${(props) => (props.isError ? `1px solid ${COLORS.ERROR}` : 'none')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
  margin: 0 auto 16px auto;
`;

const Placeholder = ({
  className,
  Icon,
  iconSize = 50,
  title,
  subTitle,
  titleStyles = {},
  subTitleStyles = {},
  styles = {},
  isError = false,
  link,
  action,
  actionText,
  linkText,
  iconColor = COLORS.BG_PAGE,
}) => (
  <Container className={className} isError={isError} style={styles}>
    {Icon && (
      <IconWrapper>
        <Icon size={iconSize} fill={iconColor} />
      </IconWrapper>
    )}
    <Title style={titleStyles}>{title}</Title>
    <SubTitle style={subTitleStyles}>{subTitle}</SubTitle>
    {(link || action) && (
      <LinkWrapper onClick={action}>
        {link && <Link to={link}>{linkText}</Link>}
        {action && <Action>{actionText}</Action>}
      </LinkWrapper>
    )}
  </Container>
);

Placeholder.propTypes = {
  className: PropTypes.string,
  Icon: PropTypes.func,
  iconSize: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleStyles: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  subTitleStyles: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  styles: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  isError: PropTypes.bool,
  link: PropTypes.string,
  action: PropTypes.func,
  actionText: PropTypes.string,
  linkText: PropTypes.string,
  iconColor: PropTypes.string,
};

export default memo(Placeholder);
