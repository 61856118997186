import { ProductName } from '@learned/constants';
import capitalize from 'lodash/capitalize';

export const getProductName = (name: ProductName) => {
  if (name === ProductName.LEARNING_AND_ONBOARDING) {
    return 'Learning & Onboarding';
  } else {
    return capitalize(name);
  }
};
