import { FEEDBACK_TYPE } from '@learned/constants';

import { NOTIFICATION_TYPES, ROLES } from '~/constants';
import routes from '~/constants/routes';

export default (notification) => {
  // Based on the type of notification we have clicked on, choose an action to carry out.
  switch (notification.type) {
    case NOTIFICATION_TYPES.CONVERSATION_PLANNED.key:
    case NOTIFICATION_TYPES.CONVERSATION_COMPLETED.key:
    case NOTIFICATION_TYPES.CONVERSATION_COMPLETE_REMINDER.key:
    case NOTIFICATION_TYPES.CONVERSATION_SCHEDULE_REMINDER.key:
    case NOTIFICATION_TYPES.CONVERSATION_USER_COMPLETED.key:
    case NOTIFICATION_TYPES.CONVERSATION_COACH_COMPLETED.key:
    case NOTIFICATION_TYPES.CONVERSATION_COACH_UNCOMPLETED.key:
    case NOTIFICATION_TYPES.REQUEST_CONVERSATION.key:
      return notification.role === ROLES.COACH
        ? routes.CONVERSATION_SUPERVISION_USER.build(
            { role: ROLES.COACH, teamId: notification.team },
            { conversationId: notification.target },
          )
        : routes.CONVERSATION.build({ role: ROLES.USER }, { conversationId: notification.target });
    case NOTIFICATION_TYPES.COACH_REVIEW_REQUEST.key:
    case NOTIFICATION_TYPES.REVIEW_PEER_REMINDER.key:
    case NOTIFICATION_TYPES.REVIEW_COACH_REMINDER.key:
    case NOTIFICATION_TYPES.REVIEW_PEER_START_DATE.key:
    case NOTIFICATION_TYPES.REVIEW_DIGITAL_SIGN_START_DATE.key:
    case NOTIFICATION_TYPES.REVIEW_PLAN_CONVERSATION_START_DATE.key:
      return routes.DASHBOARD.build(
        { role: ROLES.USER },
        { query: { search: notification.options.reviewName } },
      );
    case NOTIFICATION_TYPES.REQUEST_RTFEEDBACK.key:
      return routes.RTFEEDBACK_PROVIDED.build(
        { role: ROLES.USER },
        { rtfeedbackId: notification.target },
      );
    case NOTIFICATION_TYPES.REQUEST_REVIEW.key:
      // Navigate to the incoming feedback page, to answer the feedback request for the review round.
      return routes.REVIEW_GIVE_FEEDBACK_PEER.build(
        {},
        {
          reviewId: notification.target,
          requestId: notification.options.requestId,
          isBackPath: true,
        },
      );
    case NOTIFICATION_TYPES.TEAM_INVITE.key:
      return routes.INVITES.build({ role: ROLES.USER });
    case NOTIFICATION_TYPES.REVIEW_DIGITAL_SIGN_REMINDER.key:
    case NOTIFICATION_TYPES.REVIEW_DIGITAL_SIGN.key:
    case NOTIFICATION_TYPES.REVIEW_USER_SELF_COMPLETED.key:
    case NOTIFICATION_TYPES.REVIEW_CONVERSATION_SET_DATE.key:
    case NOTIFICATION_TYPES.REVIEW_CONVERSATION_UPDATE_DATE.key:
    case NOTIFICATION_TYPES.REVIEW_INVITE.key:
      return routes.REVIEW.build(
        { role: ROLES.USER },
        {
          reviewId: notification.target,
          ...([
            NOTIFICATION_TYPES.REVIEW_DIGITAL_SIGN_REMINDER.key,
            NOTIFICATION_TYPES.REVIEW_DIGITAL_SIGN.key,
          ].includes(notification.type) && {
            query: { isDigitalSignModal: true },
          }),
        },
      );
    case NOTIFICATION_TYPES.GOAL_CREATED.key:
      return routes.GOAL.build({ role: ROLES.USER }, { goalId: notification.target });
    case NOTIFICATION_TYPES.GOAL_TASK_REMINDER.key:
    case NOTIFICATION_TYPES.GOAL_TASK_DEADLINE_REMINDER.key:
      // Navigate to the page where the user sees active goals
      return routes.GOAL_TASK.build(
        { role: ROLES.USER },
        { goalTaskId: notification.options.goalTaskId },
      );
    case NOTIFICATION_TYPES.ASK_FEEDBACK_TASK_DEADLINE_REMINDER.key:
    case NOTIFICATION_TYPES.ASK_FEEDBACK_TASK_REMINDER.key:
      // Navigate to the rtfb page with open ask feedback modal
      return routes.RTFEEDBACKS.build(
        { role: ROLES.USER },
        { query: { type: FEEDBACK_TYPE.ASK_FEEDBACK, isAskRTFeedbackModal: true } },
      );
    case NOTIFICATION_TYPES.GIVE_FEEDBACK_TASK_DEADLINE_REMINDER.key:
    case NOTIFICATION_TYPES.GIVE_FEEDBACK_TASK_REMINDER.key:
      // Navigate to the rtfb page with open give feedback modal
      return routes.RTFEEDBACKS.build(
        { role: ROLES.USER },
        { query: { type: FEEDBACK_TYPE.GIVE_FEEDBACK, isAskRTFeedbackModal: true } },
      );
    case NOTIFICATION_TYPES.GOAL_USER_COMPLETED.key:
      // Navigate to the page where the coach see the goal the user completed
      return routes.USER_PUBLIC_SKILL_PASSPORT.build(
        { role: ROLES.COACH, teamId: notification.team },
        {
          userId: notification.options.fromUser,
          hash: 'goals',
          query: { tab: 'completed' },
        },
      );
    case NOTIFICATION_TYPES.FEEDBACK_PROVIDED.key:
      return routes.REVIEW.build({ role: ROLES.USER }, { reviewId: notification.target });
    case NOTIFICATION_TYPES.RTFEEDBACK_PROVIDED.key:
      // Navigate to the real-time feedback page, to view the provided feedback.
      return routes.RTFEEDBACKS_ASKED.build({ role: ROLES.USER });
    case NOTIFICATION_TYPES.REVIEW_NOMINATE_REMINDER.key:
    case NOTIFICATION_TYPES.REVIEW_NOMINATE_START_DATE.key:
      // Navigate to the second page of the review
      return routes.REVIEW.build(
        { role: ROLES.USER },
        { reviewId: notification.target, query: { isNominateModal: true } },
      );
    case NOTIFICATION_TYPES.REVIEW_SELF_FEEDBACK_REMINDER.key:
    case NOTIFICATION_TYPES.REVIEW_SELF_REVIEW_START_DATE.key:
      // Navigate to the third page of the review
      return routes.REVIEW_GIVE_FEEDBACK_SELF.build(
        { role: ROLES.USER },
        { reviewId: notification.target },
      );
    case NOTIFICATION_TYPES.REVIEW_FEEDBACK_REMINDER.key:
      // Navigate to the page where the user can provide the RT feedback.
      return routes.RTFEEDBACKS_PROVIDED.build({ role: ROLES.USER });
    case NOTIFICATION_TYPES.REVIEW_CONVERSATION_REMINDER.key:
      // Navigate to the tasks page with pre-select review name in search
      return routes.DASHBOARD.build(
        { role: ROLES.USER },
        {
          query: {
            search: notification.options.reviewName,
          },
        },
      );
    case NOTIFICATION_TYPES.USER_PATH_REMINDER.key:
    case NOTIFICATION_TYPES.USER_PATH_CREATED.key:
      // Navigate to the personal path page.
      return routes.USER_PATH.build({ role: ROLES.USER }, { pathId: notification.target });
    case NOTIFICATION_TYPES.ACTIVITY_ASSIGNED.key:
      // Navigate to the page where the user see assigned activity.
      return routes.ONBOARD_AND_LEARN.build({ role: ROLES.USER });
    case NOTIFICATION_TYPES.CALENDAR_INTEGRATION_DISCONNECTED.key:
      // Navigate to the page where user can connect integration.
      return routes.SETTINGS.build({ role: ROLES.USER }, { query: { scrollToCalendars: true } });
    case NOTIFICATION_TYPES.ACTIVITY_USER_COMPLETED.key:
      // Navigate to the page where the coach see the activity the user completed
      return routes.USER_PUBLIC_SKILL_PASSPORT.build(
        { role: ROLES.COACH, teamId: notification.team },
        {
          userId: notification.options.fromUser,
          hash: 'learning',
          query: { tab: 'completed' },
        },
      );
    case NOTIFICATION_TYPES.SURVEY_REMINDER.key:
      return routes.USER_SURVEY_DASHBOARD.build(
        { role: ROLES.USER },
        { userSurveyId: notification.target },
      );
    default:
      return null;
  }
};
