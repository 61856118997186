import React, { useState } from 'react';

import { ICompany } from '@learned/types';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import map from 'lodash/map';

import Button from '~/components/Button';
import SettingBlock from '~/components/ProfileSettingsComponents/MainDashboard/SettingBlock';

import { SSO_METHODS } from '~/constants';

import {
  SelectWrapper,
  ButtonWrapper,
  BlockRow,
  RowName,
  RowDescription,
  RowAction,
  LoginMethodSelect,
} from '../design';
import UpdateLoginMethodsModal from '../UpdateLoginMethodsModal';

const SSOSettings = ({
  company,
  updateDefaultSSOMethod,
}: {
  company: ICompany;
  updateDefaultSSOMethod: (defaultSSOMethod: string) => Promise<void>;
}) => {
  const { i18n } = useLingui();
  const [showUpdateLoginMethodsModal, setShowUpdateLoginMethodsModal] = useState(false);

  const loginMethodOptions = map(SSO_METHODS, (sso_method) => ({
    value: sso_method.key,
    name: sso_method.name,
  }));

  return (
    <>
      {/* @ts-ignore */}
      <SettingBlock
        title={i18n._(t`Login methods`)}
        subTitle={i18n._(t`Enable or disable login methods per user.`)}
      >
        <BlockRow>
          <RowName>
            <Trans>Default login method</Trans>
          </RowName>
          <RowDescription>
            <Trans>Set the default allowed login method for new users</Trans>
          </RowDescription>

          <RowAction>
            <SelectWrapper>
              {/* @ts-ignore */}
              <LoginMethodSelect
                options={loginMethodOptions}
                onChange={updateDefaultSSOMethod}
                value={company?.defaultSSOMethod || SSO_METHODS.ALL.key}
                height="36px"
              />
            </SelectWrapper>
          </RowAction>
        </BlockRow>
        <BlockRow>
          <RowName>
            <Trans>Manage login methods per user</Trans>
          </RowName>
          <RowDescription />

          <RowAction>
            <ButtonWrapper>
              <Button
                type="primary-border"
                onClick={() => setShowUpdateLoginMethodsModal(true)}
                label={i18n._(t`Update`)}
              />
            </ButtonWrapper>
          </RowAction>
        </BlockRow>
      </SettingBlock>
      {showUpdateLoginMethodsModal && (
        <UpdateLoginMethodsModal onClose={() => setShowUpdateLoginMethodsModal(false)} />
      )}
    </>
  );
};

export { SSOSettings };
