import React, { MutableRefObject } from 'react';

import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import { Mode } from '..';
import { SelectList } from '../SelectList';

import type { IActivityCategory } from '@learned/types';

interface ISelectCategoriesProps {
  selectedCategories: IActivityCategory['id'][];
  className?: string;
  updateSelectedCategories: (items: IActivityCategory['id'][]) => void;
  setSelectedCategories: (categories: IActivityCategory['id'][]) => void;
  setMode: (value: Mode) => void;
  mode: Mode;
  isLoading?: boolean;
  categories: IActivityCategory[];
  cancelButtonRef: MutableRefObject<HTMLButtonElement | undefined>;
}

const SelectCategories = ({
  selectedCategories,
  className,
  updateSelectedCategories,
  setMode,
  mode,
  categories,
  isLoading,
  setSelectedCategories,
  cancelButtonRef,
}: ISelectCategoriesProps) => {
  return (
    // @ts-ignore
    <ShowSpinnerIfLoading loading={isLoading}>
      <SelectList
        isSearchable
        isMultiCheck
        items={categories}
        selectedItems={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        className={className}
        onChange={updateSelectedCategories}
        setMode={setMode}
        mode={mode}
        cancelButtonRef={cancelButtonRef}
      />
    </ShowSpinnerIfLoading>
  );
};

export { SelectCategories };
