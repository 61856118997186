import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Dropdown } from '~/components/Dropdown';

import { IJobTemplateStatus, JOB_TEMPLATE_STATUS_WITH_NAMES } from '~/constants/jobTemplate';

export const FilterJobTemplateStatus = ({
  selectedItems,
  onChange,
}: {
  onChange: (selectedItems: IJobTemplateStatus[]) => void;
  selectedItems: IJobTemplateStatus[];
}) => {
  const { i18n } = useLingui();

  const statuses: IJobTemplateStatus[] = Object.values(JOB_TEMPLATE_STATUS_WITH_NAMES);

  return (
    <Dropdown
      items={statuses}
      selectedItems={selectedItems}
      placeholder={i18n._(t`Status`)}
      stringifyItem={(item) => item.translated(i18n)}
      onChange={onChange}
    />
  );
};
