import React from 'react';

import { Trans } from '@lingui/macro';
import { UseFormReturn } from 'react-hook-form';

import type { ISectionState } from '~/components/SideBar/SectionStateHook';

import { GeneralSettings } from './components/GeneralSettings';
import { NotificationSettings } from './components/NotificationSettings';
import { PrivacySettings } from './components/PrivacySettings';
import { StepFooter } from './components/StepFooter';
import { Form, Title } from './design';

import type { IReviewCycleForm } from './types';

interface StepSettingsProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewCycleForm>;
}

const StepSettings = ({ formMethods, sectionState }: StepSettingsProps) => {
  return (
    <>
      <Form>
        <Title>
          <Trans>Settings</Trans>
        </Title>
        <GeneralSettings formMethods={formMethods} />
        <NotificationSettings formMethods={formMethods} />
        <PrivacySettings formMethods={formMethods} />
        <StepFooter
          onNext={() => sectionState.setCurrentSection(++sectionState.currentSection)}
          onPrev={() => sectionState.setCurrentSection(--sectionState.currentSection)}
        />
      </Form>
    </>
  );
};

export { StepSettings };
