import {
  QUESTION_TYPES,
  RATING_QUESTION_TYPES,
  RATING_STATUSES,
  KPI_TYPES,
} from '@learned/constants';
import { t } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import { RATING_TYPES } from '~/constants';
import { GIVE_FEEDBACK_LEGEND } from '~/constants/reviews';

const avgRate = (ratings) => {
  return (
    Math.round(
      (ratings.reduce((acc, el) => {
        const rate = el.rate ? el.rate : el;
        return acc + rate;
      }, 0) /
        ratings.length) *
        10,
    ) / 10
  );
};

const avgRateForSkills = (ratings) => {
  return Math.round(
    (ratings.reduce((acc, el) => {
      if (el.skillLevel) {
        return acc + el.rate / el.skillLevel;
      }
      return acc + el.rate / el.scale[el.scale.length - 1];
    }, 0) /
      ratings.length) *
      100,
  );
};

export const getAverageRatesForSkill = ({
  i18n,
  feedbackRatings,
  expectedLevel,
  hideCoaches,
  hidePeers,
  hideSelf,
}) => {
  let skillAvgRates = {};

  const selfRatedRating =
    !hideSelf && feedbackRatings.find((r) => r.type === RATING_TYPES.SELF_RATING && r.rate !== 0);
  const peersRatedRatings =
    !hidePeers &&
    feedbackRatings.filter(
      (r) =>
        (r.type === RATING_TYPES.OTHER_RATING || r.type === RATING_TYPES.OUTSIDE_RATING) &&
        r.rate !== 0,
    );
  const coachesRatedRatings =
    !hideCoaches &&
    feedbackRatings.filter((r) => r.type === RATING_TYPES.COACH_RATING && r.rate !== 0);

  const scale = !isEmpty(feedbackRatings) ? feedbackRatings[0].scale : [];

  if (expectedLevel && feedbackRatings) {
    const expectedLevelIdx = scale.indexOf(expectedLevel);
    const expectedProgress =
      expectedLevelIdx !== -1
        ? Math.round(((expectedLevelIdx + 1) / scale.length) * 100)
        : Math.round((expectedLevel / scale[scale.length - 1]) * 100);
    skillAvgRates = {
      ...skillAvgRates,
      avgExpectedLevel: { progress: expectedProgress },
    };
  }

  if (!isEmpty(selfRatedRating)) {
    const selfRateIdx = scale.indexOf(selfRatedRating.rate);
    skillAvgRates = {
      ...skillAvgRates,
      avgSelfRating: {
        progress: Math.round(((selfRateIdx + 1) / scale.length) * 100),
        color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.SELF_RATING].color,
        title: i18n._(t`Your input`),
      },
    };
  }

  if (!isEmpty(peersRatedRatings)) {
    const avgPeersRate = avgRate(peersRatedRatings);

    skillAvgRates = {
      ...skillAvgRates,
      avgPeersRating: {
        progress: Math.round((avgPeersRate / scale[scale.length - 1]) * 100),
        color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.OTHER_RATING].color,
        title: i18n._(t`Peer input`),
      },
    };
  }

  if (!isEmpty(coachesRatedRatings)) {
    const avgCoachesRate = avgRate(coachesRatedRatings);

    skillAvgRates = {
      ...skillAvgRates,
      avgCoachesRating: {
        progress: Math.round((avgCoachesRate / scale[scale.length - 1]) * 100),
        color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.COACH_RATING].color,
        title: i18n._(t`Coach input`),
      },
    };
  }

  return skillAvgRates;
};

export const getAverageRatesForCategories = ({
  i18n,
  feedbackRatings,
  categories,
  review,
  questionsForList,
  hideSelf,
  hidePeers,
  hideCoaches,
}) => {
  let categoriesWithRates = {};
  let feedbackRatingsAnsScalesPerSkill = {};

  const skillsIds = uniq(questionsForList.map(({ skill }) => skill.id));
  skillsIds.forEach((skillId) => {
    const skillRatings = feedbackRatings.filter((r) => r.skill === skillId);

    feedbackRatingsAnsScalesPerSkill[skillId] = {
      feedbackRatings: skillRatings,
      scale: !isEmpty(skillRatings) ? skillRatings[0].scale : [],
    };
  });

  categories.forEach((category) => {
    const categoriesSkillsIds = questionsForList
      // filter questions only for current category
      .filter(
        ({ skill }) =>
          (skill.categories || []).includes(category.id) || // for company skills
          (skill.categories || []).includes(category.categoryId), // for learned skills
      )
      .map(({ skill }) => skill.id);

    // expectedLevels for all categories skills
    const expectedLevels = categoriesSkillsIds.map((skillId) => {
      const expectedLevel = review?.skillsJobProfileLevels[skillId];
      const scale = feedbackRatingsAnsScalesPerSkill[skillId].scale;
      const expectedLevelIdx = scale.indexOf(expectedLevel);
      return expectedLevelIdx !== -1
        ? Math.round(((expectedLevelIdx + 1) / scale.length) * 100)
        : Math.round((expectedLevel / scale[scale.length - 1]) * 100);
    });

    const selfRatedRatings =
      !hideSelf &&
      categoriesSkillsIds
        .map((skillId) => {
          const scale = feedbackRatingsAnsScalesPerSkill[skillId].scale;
          const skillSelfRate = feedbackRatingsAnsScalesPerSkill[skillId].feedbackRatings.find(
            (r) => r.type === RATING_TYPES.SELF_RATING && r.rate !== 0,
          );
          if (isEmpty(skillSelfRate)) {
            return null;
          }
          const selfRateIdx = scale.indexOf(skillSelfRate?.rate);
          return Math.round(((selfRateIdx + 1) / scale.length) * 100);
        })
        .filter((r) => r);

    const peersRatedRatings =
      !hidePeers &&
      categoriesSkillsIds
        .map((skillId) => {
          const scale = feedbackRatingsAnsScalesPerSkill[skillId].scale;
          const skillPeersFRates = feedbackRatingsAnsScalesPerSkill[skillId].feedbackRatings.filter(
            (r) =>
              (r.type === RATING_TYPES.OTHER_RATING || r.type === RATING_TYPES.OUTSIDE_RATING) &&
              r.rate !== 0,
          );
          if (isEmpty(skillPeersFRates)) {
            return null;
          }
          const avgPeersRate = avgRate(skillPeersFRates);
          return Math.round((avgPeersRate / scale[scale.length - 1]) * 100);
        })
        .filter((r) => r);

    const coachesRatedRatings =
      !hideCoaches &&
      categoriesSkillsIds
        .map((skillId) => {
          const scale = feedbackRatingsAnsScalesPerSkill[skillId].scale;
          const skillCoachesRates = feedbackRatingsAnsScalesPerSkill[
            skillId
          ].feedbackRatings.filter((r) => r.type === RATING_TYPES.COACH_RATING && r.rate !== 0);
          if (isEmpty(skillCoachesRates)) {
            return null;
          }
          const avgCoachesRate = avgRate(skillCoachesRates);
          return Math.round((avgCoachesRate / scale[scale.length - 1]) * 100);
        })
        .filter((r) => r);

    categoriesWithRates[category.id] = {
      ...(!isEmpty(expectedLevels) && { avgExpectedLevel: { progress: avgRate(expectedLevels) } }),
      ...(!isEmpty(selfRatedRatings) && {
        avgSelfRating: {
          progress: avgRate(selfRatedRatings),
          color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.SELF_RATING].color,
          title: i18n._(t`Your input`),
        },
      }),
      ...(!isEmpty(peersRatedRatings) && {
        avgPeersRating: {
          progress: avgRate(peersRatedRatings),
          color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.OTHER_RATING].color,
          title: i18n._(t`Peer input`),
        },
      }),
      ...(!isEmpty(coachesRatedRatings) && {
        avgCoachesRating: {
          progress: avgRate(coachesRatedRatings),
          color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.COACH_RATING].color,
          title: i18n._(t`Coach input`),
        },
      }),
    };
  });

  return categoriesWithRates;
};

export const getAverageRatesTotal = ({
  i18n,
  feedbackRatings,
  hideSelf = false,
  hidePeers = false,
  hideCoaches = false,
}) => {
  const filterRatingsPerType = (ratingTypes = []) => {
    return feedbackRatings.filter((r) => ratingTypes.includes(r.type) && r.rate !== 0);
  };

  const selfRatedRatings = !hideSelf && filterRatingsPerType([RATING_TYPES.SELF_RATING]);
  const peersRatedRatings =
    !hidePeers && filterRatingsPerType([RATING_TYPES.OTHER_RATING, RATING_TYPES.OUTSIDE_RATING]);
  const coachesRatedRatings = !hideCoaches && filterRatingsPerType([RATING_TYPES.COACH_RATING]);

  // calc expected level avg
  const expectedLevels = feedbackRatings.map((rating) => {
    const expectedLevel = rating.skillLevel;
    const scale = rating.scale;
    const expectedLevelIdx = scale.indexOf(expectedLevel);
    return expectedLevelIdx !== -1
      ? Math.round(((expectedLevelIdx + 1) / scale.length) * 100)
      : Math.round((expectedLevel / scale[scale.length - 1]) * 100);
  });

  // calc max progress
  // skillLevelProgress -> 100%
  // 100% -> x
  // so (100 * 100) / skillLevelProgress
  const maxPossibleLevel = expectedLevels.map((expectedLevel) => {
    return Math.round((100 * 100) / expectedLevel).toFixed(0);
  });

  // calc max progress

  const total = {
    ...(!isEmpty(expectedLevels) && { avgExpectedLevel: { progress: avgRate(expectedLevels) } }),
    ...(!isEmpty(maxPossibleLevel) && {
      maxPossibleLevel: { progress: Math.max(...(maxPossibleLevel || [])) },
    }),
    ...(!isEmpty(selfRatedRatings) && {
      avgSelfRating: {
        progress: avgRateForSkills(selfRatedRatings),
        color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.SELF_RATING].color,
        title: i18n._(t`Your input`),
      },
    }),
    ...(!isEmpty(peersRatedRatings) && {
      avgPeersRating: {
        progress: avgRateForSkills(peersRatedRatings),
        color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.OTHER_RATING].color,
        title: i18n._(t`Peer input`),
      },
    }),
    ...(!isEmpty(coachesRatedRatings) && {
      avgCoachesRating: {
        progress: avgRateForSkills(coachesRatedRatings),
        color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.COACH_RATING].color,
        title: i18n._(t`Coach input`),
      },
    }),
  };

  return total;
};

export const getFeedbackRatings = ({
  ratings = [],
  reviewUser,
  skill,
  goal,
  roleSkills,
  question,
  hideSelf,
  hidePeers,
  hideCoaches,
  sharedRequestsUsers,
}) => {
  let feedbackRatings = ratings.filter((rating) => {
    // do not display ratings in DRAFT
    if (rating.status === RATING_STATUSES.TODO) {
      return false;
    }

    if (
      sharedRequestsUsers.includes(rating.user) ||
      sharedRequestsUsers.includes(rating.email) ||
      rating.user === reviewUser
    ) {
      switch (question.type) {
        case QUESTION_TYPES.SKILL:
          return String(rating.skill) === String(skill.id);
        case QUESTION_TYPES.JOB_PROFILE:
          return roleSkills.includes(String(rating.skill));
        case QUESTION_TYPES.GOAL_BUSINESS_EVAL:
          if (question.isAverageQuestionEnabled && !goal) {
            return (
              String(rating.questionId) === String(question.id) &&
              rating.questionType === RATING_QUESTION_TYPES.GOAL_BUSINESS_EVAL_AVERAGE
            );
          }
          return String(rating.goal) === String(goal.id);
        case QUESTION_TYPES.GOAL_LEARNING_EVAL:
          if (question.isAverageQuestionEnabled && !goal) {
            return (
              String(rating.questionId) === String(question.id) &&
              rating.questionType === RATING_QUESTION_TYPES.GOAL_LEARNING_EVAL_AVERAGE
            );
          }
          return String(rating.goal) === String(goal.id);
        case QUESTION_TYPES.GOAL_BUSINESS_PLAN:
        case QUESTION_TYPES.GOAL_LEARNING_PLAN:
          return true;
        case QUESTION_TYPES.CUSTOM:
        default:
          return rating.question === question.id;
      }
    }
    return false;
  });

  if (hideSelf) {
    feedbackRatings = feedbackRatings.filter((r) => r.type !== RATING_TYPES.SELF_RATING);
  }
  if (hideCoaches) {
    feedbackRatings = feedbackRatings.filter((r) => r.type !== RATING_TYPES.COACH_RATING);
  }
  if (hidePeers) {
    feedbackRatings = feedbackRatings.filter(
      (r) => r.type !== RATING_TYPES.OTHER_RATING && r.type !== RATING_TYPES.OUTSIDE_RATING,
    );
  }

  // sort ratings by type: self, peer, coach
  const ratingTypes = [
    RATING_TYPES.SELF_RATING,
    RATING_TYPES.OTHER_RATING,
    RATING_TYPES.OUTSIDE_RATING,
    RATING_TYPES.COACH_RATING,
  ];
  feedbackRatings = feedbackRatings.slice().sort(function (a, b) {
    return ratingTypes.indexOf(a.type) - ratingTypes.indexOf(b.type);
  });
  return feedbackRatings;
};

export function getSpiderItems(review, { sectionType, activeSectionIndex, role }) {
  if (isEmpty(review)) {
    return [];
  }

  // get skills per role for JP questions
  if (role) {
    return review.skillsJobProfile.filter((s) => (role?.skills || []).includes(s.id));
  }

  switch (sectionType) {
    case KPI_TYPES.SKILLS: {
      return review.skillsJobProfile;
    }
    default: {
      const items = [];

      // add custom questions
      review.questions
        .filter((q) => {
          const questionRate = review.ratings.find((r) => r.question === q.id);
          return (
            q.type === QUESTION_TYPES.CUSTOM &&
            q.sectionIndex === activeSectionIndex &&
            !q.hideRating &&
            questionRate &&
            questionRate.rate !== 0
          );
        })
        .forEach((q) => items.push(q));

      // add goal questions
      review.questions.filter((q) => {
        switch (q.type) {
          case QUESTION_TYPES.GOAL_BUSINESS_EVAL: {
            review.goalsBusinessEval.forEach((g) => {
              const questionRate = review.ratings.find((r) => r.goal === g.id);
              if (
                q.sectionIndex === activeSectionIndex &&
                !q.hideRating &&
                questionRate &&
                questionRate.rate !== 0
              ) {
                items.push(g);
              }
            });
            break;
          }
          case QUESTION_TYPES.GOAL_LEARNING_EVAL: {
            review.goalsLearningEval.forEach((g) => {
              const questionRate = review.ratings.find((r) => r.goal === g.id);
              if (
                q.sectionIndex === activeSectionIndex &&
                !q.hideRating &&
                questionRate &&
                questionRate.rate !== 0
              ) {
                items.push(g);
              }
            });
          }
        }
      });

      return items;
    }
  }
}
