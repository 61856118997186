import React, { useState, useContext, useEffect, useMemo } from 'react';

import { ROLES, ENGAGEMENT_REPORT_CHART_DIMENSIONS, REPORT_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';

import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import PaginationBar from '~/components/PaginationBar';

import { LucaSummaryContent } from './LucaSummaryContent';

import useBoolState from '~/hooks/useBoolState';
import { usePagination } from '~/hooks/usePagination';
import * as reportsService from '~/services/reports';
import { TLucaModalData } from '~/services/reports';
import * as reportsUtil from '~/utils/reports';
import {
  prepareDimensionFilters,
  prepareReportFilters,
  prepareReportOptionsFilters,
} from '~/utils/reports';

import { TTimeFrame } from '../../types';
import { EngagementReportContext } from '../EngagementContext';
import DimensionsStrip from '../Luca/DimensionsStrip';
import {
  CloseIcon,
  DescriptionWrapper,
  Footer,
  Header,
  HeaderInnerWrapper,
  LucaDescriptionContainer,
  LucaModalContainer,
  ModalTitleRow,
  ModalContent,
  PaginationContainer,
  Title,
  ModalTitleRowActions,
} from '../Luca/Luca.design';
import { LucaDescription } from '../Luca/LucaDescription';
import { LucaRatingsTable } from '../Luca/LucaRatingsTable';

// mini-luca Modal
const InformationModal = ({
  baseRef,
  data,
  onClose,
}: {
  baseRef: React.RefObject<HTMLDivElement>;
  data: TLucaModalData;
  onClose(v: boolean): void;
}) => {
  const { i18n } = useLingui();
  const { filters, viewAs, dimensions, reportType, options } = useContext(EngagementReportContext);

  const { primary, secondary, secondDimension, thirdDimension, fourthDimension, measure } =
    dimensions as {
      primary: string;
      secondary: string;
      secondDimension: string;
      thirdDimension: string;
      fourthDimension: string;
      measure: ENGAGEMENT_REPORT_CHART_DIMENSIONS;
    };
  const {
    primaryDimensionValue,
    secondaryDimensionValue,
    additionalDimensionValues,
    measureValue,
  } = data.filters;

  let timeFrame: TTimeFrame;

  const shouldOverrideDefaultTimeFilters =
    [
      ENGAGEMENT_REPORT_CHART_DIMENSIONS.QUARTER,
      ENGAGEMENT_REPORT_CHART_DIMENSIONS.YEAR,
      ENGAGEMENT_REPORT_CHART_DIMENSIONS.MONTH,
      ENGAGEMENT_REPORT_CHART_DIMENSIONS.WEEK,
    ].includes(measure) &&
    measureValue &&
    measureValue !== 'average';

  if (shouldOverrideDefaultTimeFilters) {
    timeFrame = reportsUtil.getStartAndEndTimeFrame(measure, measureValue);
  } else {
    timeFrame = reportsUtil.getDateForTimeFrame(filters.monthSelected);
  }

  const [ratings, setRatings] = useState<
    {
      date: string;
      pName: string;
      rating: number;
      comment: string;
      user_name: string;
      question_name: string;
      user_id: string;
      pId: string;
    }[]
  >();
  const $isLucaOpen = useBoolState(false);
  const $isLoading = useBoolState(false);
  const [total, setTotal] = useState(0);
  const { pagination, changePagination } = usePagination(10);

  const shouldShowAISection = !!data.value && [ROLES.ADMIN, ROLES.COACH].includes(viewAs);

  const combinedFilters = useMemo(() => {
    const reportOptionsFilters = data.reviewer
      ? { reviewerTypes: [data.reviewer.toLocaleLowerCase()] }
      : prepareReportOptionsFilters(options);
    return {
      ...prepareReportFilters(filters),
      ...prepareDimensionFilters({
        primary,
        secondary,
        additionalDimensionKeys: [secondDimension, thirdDimension, fourthDimension],
        additionalDimensionValues,
        measure,
        primaryDimensionValue,
        secondaryDimensionValue,
        measureValue,
        reviewer: data.reviewer,
      }),
      ...reportOptionsFilters,
    };
  }, [
    data.reviewer,
    options,
    filters,
    primary,
    secondary,
    secondDimension,
    thirdDimension,
    fourthDimension,
    additionalDimensionValues,
    measure,
    primaryDimensionValue,
    secondaryDimensionValue,
    measureValue,
  ]);

  const themeId = primary === ENGAGEMENT_REPORT_CHART_DIMENSIONS.THEME ? primaryDimensionValue : '';

  const fetchRatings = React.useCallback(
    async (newPagination: { skip: number; limit: number; index: number } | null) => {
      try {
        $isLoading.on();
        const res = await reportsService.getRatings(REPORT_TYPES.ENGAGEMENT, {
          pagination: {
            limit: newPagination ? newPagination.limit : pagination.limit,
            skip: newPagination ? newPagination.skip : pagination.skip,
          },
          viewAs,
          reportType,
          dateRange: {
            start: moment(timeFrame.start).format('YYYY-MM-DD'),
            end: moment(timeFrame.end).format('YYYY-MM-DD'),
          },
          filters: combinedFilters,
        });
        setTotal(res.data.total);
        setRatings(res.data.rows);
        $isLoading.off();
      } catch (error) {
        setRatings([]);
        $isLoading.off();
      }
    },
    [
      $isLoading,
      pagination.limit,
      pagination.skip,
      viewAs,
      timeFrame.start,
      timeFrame.end,
      combinedFilters,
      reportType,
    ],
  );

  useEffect(() => {
    if (!ratings && $isLoading.value === false) {
      // if there is a fetch error, stop refetching,
      fetchRatings(null);
    }
  }, [$isLoading.value, fetchRatings, ratings]);

  return (
    <LucaModalContainer>
      <ModalContent ref={baseRef}>
        <Header>
          <ModalTitleRow>
            <HeaderInnerWrapper>
              <Title>{i18n._(t`Report details`)}</Title>
            </HeaderInnerWrapper>

            <ModalTitleRowActions>
              <CloseIcon
                onClick={() => {
                  setRatings(undefined);
                  return onClose(false);
                }}
              >
                <Icon icon={ICONS.CLOSE} size={ICON_SIZES.MEDIUM} />
              </CloseIcon>
            </ModalTitleRowActions>
          </ModalTitleRow>
          <DimensionsStrip
            primaryDimensionName={data.primaryDimensionName}
            secondaryDimensionName={
              // if no secondary dimension value, don't show the dimension name
              data.filters.secondaryDimensionValue && data.secondaryDimensionName
            }
            additionalDimensionNames={data.additionalDimensionNames}
            reviewer={data.reviewer}
            measureName={data.measureName}
            timeFrame={timeFrame}
          />
        </Header>
        <LucaDescriptionContainer>
          <DescriptionWrapper>
            <LucaDescription primaryDimensionValue={primaryDimensionValue} />
            <LucaRatingsTable ratings={ratings} isLoading={$isLoading.value} />
            <Footer>
              {ratings && ratings.length > 0 && (
                <PaginationContainer>
                  <PaginationBar
                    pagination={pagination}
                    changePagination={(data) => {
                      fetchRatings(data);
                      changePagination(data);
                    }}
                    count={total}
                    showCount
                    noBorder
                    noShadow
                  />
                </PaginationContainer>
              )}
            </Footer>
          </DescriptionWrapper>
          {/* CAUTION: we need to make sure Luca summary content can exist without a themeId */}
          {shouldShowAISection && themeId && (
            <LucaSummaryContent
              isLucaOpen={$isLucaOpen.value}
              onLucaOpen={(value) => $isLucaOpen.set(value)}
              themeID={themeId}
            />
          )}
        </LucaDescriptionContainer>
      </ModalContent>
    </LucaModalContainer>
  );
};

export { InformationModal };
