import React, { useMemo } from 'react';

import { MultiSelectDropdown } from '~/components/Dropdown/components/MultiSelectDropdown';
import { SingleSelectDropdown } from '~/components/Dropdown/components/SingleSelectDropdown';

import { sortItems } from './utils';

import type { DropdownProps } from './types';

function Dropdown<T = unknown>(props: DropdownProps<T>) {
  return useMemo(
    () => <DropdownTemp {...props} />,
    // eslint-disable-next-line
    [JSON.stringify(props)],
  );
}

function DropdownTemp<T = unknown>({ items, ...props }: DropdownProps<T>) {
  const renderItem = (item: T) =>
    props.stringifyItem ? props.stringifyItem(item) : (item as unknown as string);

  items = !props.skipSort ? sortItems(items, { stringifyItem: props.stringifyItem }) : items;

  return props.isSingleSelect ? (
    <SingleSelectDropdown<T> {...props} renderItem={renderItem} items={items} />
  ) : (
    <MultiSelectDropdown<T> {...props} renderItem={renderItem} items={items} />
  );
}

export { Dropdown, DropdownTemp };
