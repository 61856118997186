import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getRTFeedbacks(filters = {}, populate = []) {
  return cloudRequest(serverRoutes.RTFeedbacks.getRTFeedbacks, { ...filters, populate });
}

export function getLearningReportRTFeedbacks() {
  return cloudRequest(serverRoutes.RTFeedbacks.getLearningReportRTFeedbacks);
}

export function getAskedRTFeedbacks(populate = [], startDate, endDate, excludedSubtypes = []) {
  return cloudRequest(serverRoutes.RTFeedbacks.getAskedRTFeedbacks, {
    populate,
    startDate,
    endDate,
    excludedSubtypes,
  });
}

export function getProvidedRTFeedbacks(populate = [], startDate, endDate, excludedSubtypes = []) {
  return cloudRequest(serverRoutes.RTFeedbacks.getProvidedRTFeedbacks, {
    populate,
    startDate,
    endDate,
    excludedSubtypes,
  });
}

export function getGoalRTFeedbacks({ goalId, startDate, endDate, isTotal = false }) {
  return cloudRequest(serverRoutes.RTFeedbacks.getGoalRTFeedbacks, {
    goalId,
    startDate,
    endDate,
    isTotal,
  });
}

export function getRTFeedback(
  id,
  { populate = [], join = [], isSubRTFeedbacks, isQuestions, isSkills } = {},
) {
  return cloudRequest(serverRoutes.RTFeedbacks.getRTFeedback, {
    id,
    populate,
    join,
    ...(isSubRTFeedbacks && { isSubRTFeedbacks }),
    ...(isQuestions && { isQuestions }),
    ...(isSkills && { isSkills }),
  });
}

export function getRTFeedbackForTalkingPoint(id) {
  return cloudRequest(serverRoutes.RTFeedbacks.getRTFeedbackForTalkingPoint, {
    id,
  });
}

export function createRTFeedback(data) {
  return cloudRequest(serverRoutes.RTFeedbacks.createRTFeedback, {}, data);
}

export function giveRTFeedback(id, feedback) {
  return cloudRequest(serverRoutes.RTFeedbacks.giveRTFeedback, { id }, { feedback });
}

export function deleteRTFeedback(id) {
  return cloudRequest(serverRoutes.RTFeedbacks.deleteRTFeedback, { id });
}

export function deleteRTFeedbackByInterviewee(id) {
  return cloudRequest(serverRoutes.RTFeedbacks.deleteRTFeedbackByInterviewee, { id });
}

export function updateRTFeedbackStatus(id, status) {
  return cloudRequest(serverRoutes.RTFeedbacks.updateRTFeedbackStatus, { id }, { status });
}
