import React from 'react';

import InlineSvg from '~/components/InlineSvg';

import { COLORS } from '~/styles';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const InfoIcon = ({ height, width, className, error, backgroundColor }) => (
  <InlineSvg width={height} height={width} className={className}>
    {(color) => (
      <g fill="none" fillRule="evenodd">
        <circle cx="12" cy="12" r="10" fill={error ? COLORS.ERROR : backgroundColor} />
        <text fill={error ? '#fff' : color} fontSize="16" fontWeight="bold" letterSpacing=".8">
          <tspan x="10" y="18">
            i
          </tspan>
        </text>
      </g>
    )}
  </InlineSvg>
);

InfoIcon.defaultProps = {
  width: 24,
  height: 24,
  backgroundColor: '#F3F3F3',
};

export default InfoIcon;
