import React from 'react';

import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import { COLOR_PALETTE } from '~/styles';

const RowWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const MainRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  background-color: ${(props) => props.background};
  border-radius: 6px;
  padding: 16px;
`;

const IconWrapper = styled.div`
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const Title = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin-bottom: 8px;
`;

const Subtitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const OtherActivitiesRow = ({ item }) => {
  const { i18n } = useLingui();

  return (
    <RowWrapper>
      <MainRow background={item.background} color={item.color}>
        <Title>
          <IconWrapper>
            <SvgIcon
              classNameIcon="img"
              isDefaultColor
              defaultColor={item.color}
              width="20px"
              height="20px"
              url={item.icon}
            />
          </IconWrapper>
          {item.title(i18n)}
        </Title>
        <Subtitle>{item.description(i18n)}</Subtitle>
      </MainRow>
    </RowWrapper>
  );
};

export default React.memo(OtherActivitiesRow);
