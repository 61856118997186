import { createSelector } from 'reselect';

export const getCurrentGoal = (state) => state.currentGoal;
export const getType = createSelector([getCurrentGoal], (goal) => goal.type);
export const getId = createSelector([getCurrentGoal], (goal) => goal.id);
export const getName = createSelector([getCurrentGoal], (goal) => goal.name);
export const getDescription = createSelector([getCurrentGoal], (goal) => goal.description);
export const getStatus = createSelector([getCurrentGoal], (goal) => goal.status);
export const getCategory = createSelector([getCurrentGoal], (goal) => goal.category);
export const getTeams = createSelector([getCurrentGoal], (goal) => goal.teams);
export const getOwners = createSelector([getCurrentGoal], (goal) => goal.owners);
export const getCreators = createSelector([getCurrentGoal], (goal) => goal.creators);
export const getViewers = createSelector([getCurrentGoal], (goal) => goal.viewers);
export const getProgressType = createSelector([getCurrentGoal], (goal) => goal.progressType);
export const getIsChildProgress = createSelector([getCurrentGoal], (goal) => goal.isChildProgress);
export const getProgressDetails = createSelector([getCurrentGoal], (goal) => goal.progressDetails);
export const getDeadline = createSelector([getCurrentGoal], (goal) => goal.deadline);
export const getGoalCycles = createSelector([getCurrentGoal], (goal) => goal.goalCycles);
export const getActivities = createSelector([getCurrentGoal], (goal) => goal.activities);
export const getActivitiesOrder = createSelector([getCurrentGoal], (goal) => goal.activities);
export const getParent = createSelector([getCurrentGoal], (goal) => goal.parent);
export const getSkills = createSelector([getCurrentGoal], (goal) => goal.skills);
export const getIsCoachesOfOwnerCanEdit = createSelector(
  [getCurrentGoal],
  (goal) => goal.isCoachesOfOwnerCanEdit,
);
export const getIsOwnerCanEdit = createSelector([getCurrentGoal], (goal) => goal.isOwnerCanEdit);
export const getErrors = createSelector([getCurrentGoal], (goal) => goal.errors);
export const getIsAddActivities = createSelector(
  [getCurrentGoal],
  (goal) => goal.isCurrentGoalAddActivities,
);
