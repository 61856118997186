import React from 'react';

import { LUCA_INSIGHT_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Tippy from '@tippyjs/react';
import { useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';

import {
  ActionContainer,
  LegalDisclaimer,
  LoadingText,
  LucaDescription,
  Summary,
  SummaryActions,
  SummaryContainer,
  SummaryDetails,
  UserName,
} from './Luca.design';
import { LucaInsights } from './LucaInsights';

import { getUser } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';

type TProps = {
  isSucceeded: boolean;
  onRefresh: () => void;
  insightResponse: LUCA_INSIGHT_STATUS;
  content: string | null;
  showActionButton: boolean;
  isScrollable: boolean;
};

const LucaSummaryUI = ({
  isSucceeded,
  onRefresh,
  insightResponse,
  content,
  showActionButton,
  isScrollable,
}: TProps): JSX.Element => {
  const { i18n } = useLingui();
  const user = useSelector(getUser);
  const getLucaStatus = () => {
    switch (insightResponse) {
      case LUCA_INSIGHT_STATUS.PENDING:
        return <LoadingText isScrollable={isScrollable}>{i18n._(t`Writing`) + '...'}</LoadingText>;
      case LUCA_INSIGHT_STATUS.FAILED:
        return (
          i18n._(t`Afraid Luca could not come up with suggestions at the moment, please try again
        later`) + '...'
        );
      default:
        return (
          <>
            {showActionButton && (
              <Summary isScrollable={isScrollable}>
                {i18n._(t`Summary`)}
                <Icon icon={ICONS.LUCA} size={ICON_SIZES.LARGE} color={COLORS.COMPANY} />
              </Summary>
            )}
            <SummaryDetails isScrollable={isScrollable}>
              <LucaInsights content={content} status={insightResponse} />
            </SummaryDetails>
          </>
        );
    }
  };

  return (
    <SummaryContainer>
      {!isSucceeded && (
        <LucaDescription isScrollable={isScrollable}>
          <UserName>
            <div>Hi {user.firstName}!</div>
          </UserName>
          <div>
            {i18n._(t`My name is Luca. I am your AI powered assistant. I am here to help you crunch the
              numbers, analyze your results and provide you with tips based on best practices`)}
          </div>
          <div>({i18n._(t`I am going as fast as I can, but it can take a couple of minutes`)})</div>
        </LucaDescription>
      )}
      {getLucaStatus()}
      {showActionButton && (
        <SummaryActions isScrollable={isScrollable}>
          {isSucceeded && (
            <Tippy
              content={i18n._(
                t`The information provided herein has been produced by an independently developed Generative Pre-trained Transformer (GPT) model. While efforts have been made to ensure the accuracy of the data generated by this artificial intelligence (AI) system, it is important to acknowledge that inaccuracies may occur. Please be advised that the personal data submitted for processing by this AI model is not utilized for the purpose of training or enhancing the capabilities of the GPT model.`,
              )}
            >
              <LegalDisclaimer>{i18n._(t`Legal Disclaimer`)}</LegalDisclaimer>
            </Tippy>
          )}
          <ActionContainer>
            <Button
              label={i18n._(t`Refresh`)}
              type="button"
              variant={ButtonVariant.TEXT_PRIMARY}
              size={ButtonSize.MEDIUM}
              onClick={() => onRefresh()}
              isLoading={insightResponse === LUCA_INSIGHT_STATUS.PENDING}
            />
          </ActionContainer>
        </SummaryActions>
      )}
    </SummaryContainer>
  );
};

export { LucaSummaryUI };
