import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const Header = styled.div`
  display: flex;
  align-items: start;
  padding: 16px 20px;
  font-size: 12px;
  background: ${COLORS.BG_PAGE};
  color: ${COLORS.SUBTEXT};
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: 600;
`;

export const Table = styled.div`
  position: relative;
`;

export const Row = styled.div`
  display: flex;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  gap: 4px;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  padding-bottom: 3px;

  &::-webkit-scrollbar {
    height: 8px;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
    background: ${COLORS.BG_ELEMENTS};
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.PLACEHOLDERS};
    border-radius: 100px;
  }

  &::-webkit-scrollbar:hover {
    cursor: pointer;
  }
`;

export const CellWrapper = styled.div`
  position: relative;
  display: flex;
`;

export const Cell = styled.div<{ isDraggingOver: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 205px;
  height: 64px;
  background-color: ${COLORS.BG_LIST};
  color: ${COLORS.TEXT_HOVER};
  padding: 24px 16px;
  box-sizing: border-box;
  font-weight: 600;
  border-radius: 6px;
  white-space: nowrap;

  & .title {
    width: 100%;
  }

  & button {
    margin-right: -10px;
  }
`;

export const AddNewCell = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  height: 64px;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
  background-color: transparent;
  color: ${COLORS.COMPANY};
  border: 1.2px dashed ${COLORS.BORDERS};
  :hover {
    background-color: ${COLORS.HOVER};
  }

  & .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 24px;
    border-radius: 6px;
    border: 1px solid ${COLORS.BORDERS};
  }
`;
