import React, { MouseEventHandler } from 'react';

import {
  SURVEY_STATUS,
  SURVEY_SORT_OPTIONS,
  SURVEY_TYPE,
  SURVEY_FREQUENCY,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';
import { FlexRow, NameRow, TD } from '~/pages/Surveys/design';
import { NameWithQuestions } from '~/pages/Surveys/NameWithQuestions';

import SurveyPulseIcon from '~/assets/survey-pulse.svg';
import SurveyTraditionalIcon from '~/assets/survey-traditional.svg';

import type { IColumnTable } from '~/@types/table';
import { isShowPulseEveryDayFrequency } from '~/constants/survey';
import { COLORS } from '~/styles';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

import type { ISurvey } from '@learned/types';
import type { I18n } from '@lingui/core';

const IconBlock = styled.div<{
  $colors: string[];
}>`
  width: 46px;
  min-height: 46px;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 8px 11px;
  background: ${({ $colors }) =>
    $colors.length ? `linear-gradient(to bottom, ${$colors[0]}, ${$colors[1]})` : 'unset'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const data = [
  {
    type: SURVEY_TYPE.TRADITIONAL,
    colors: ['#e6f3ff', '#d5dffc'],
    icon: (
      <SvgIcon
        width="24px"
        height="24px"
        url={SurveyTraditionalIcon}
        isDefaultColor
        defaultColor={COLORS.MIDDLE_GRAY}
      />
    ),
  },
  {
    type: SURVEY_TYPE.PULSE,
    colors: ['#ffe6e6', '#fcd5d5'],
    icon: (
      <SvgIcon
        width="22px"
        height="22px"
        url={SurveyPulseIcon}
        isDefaultColor
        defaultColor={COLORS.MIDDLE_GRAY}
      />
    ),
  },
];

const RepeatColumn = ({ item }: { item: ISurvey }) => {
  const { i18n } = useLingui();
  const transform = {
    [SURVEY_FREQUENCY.WEEK]: i18n._(t`Weekly`),
    [SURVEY_FREQUENCY.TWO_WEEK]: i18n._(t`Bi-Weekly`),
    [SURVEY_FREQUENCY.FOUR_WEEK]: i18n._(t`Monthly`),
    ...(isShowPulseEveryDayFrequency && {
      [SURVEY_FREQUENCY.DAY]: i18n._(t`Daily (only for stage/live)`),
    }),
  };

  return (
    <TD isGrayColor={!item.pulse?.frequency}>
      <span>{item.pulse?.frequency ? transform[item.pulse?.frequency] : 'N/A'}</span>
    </TD>
  );
};

const SURVEYS_COLUMNS: IColumnTable<ISurvey>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'name',
    renderCell: (survey, onClick: MouseEventHandler<HTMLDivElement> | undefined) => {
      const { local, type, status } = survey;
      const item = data.find((item) => item.type === type) || data[0];
      return (
        <NameRow>
          {/* @ts-ignore */}
          <FlexRow onClick={() => onClick(survey)}>
            <IconBlock $colors={item?.colors}>{item?.icon}</IconBlock>
            <NameWithQuestions
              name={local?.name as string}
              isGrayColor={status === SURVEY_STATUS.DRAFT}
            />
          </FlexRow>
        </NameRow>
      );
    },
    sortBy: {
      asc: {
        key: SURVEY_SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: SURVEY_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Repeat`),
    accessor: 'pulse.frequency',
    minWidth: '162px',
    sortBy: {
      asc: {
        key: SURVEY_SORT_OPTIONS.REPEAT_OFTEN_RARE,

        label: (i18n: I18n) => i18n._(t`Often - rare`),
      },
      desc: {
        key: SURVEY_SORT_OPTIONS.REPEAT_RARE_OFTEN,
        label: (i18n: I18n) => i18n._(t`Rare - often`),
      },
    },
    renderCell: (item: ISurvey) => <RepeatColumn item={item} />,
  },
  {
    name: (i18n: I18n) => i18n._(t`End date`),
    accessor: 'dates.endDate',
    minWidth: '162px',
    sortBy: {
      asc: {
        key: SURVEY_SORT_OPTIONS.END_DATE_SOON_LATER,

        label: (i18n: I18n) => i18n._(t`Soon - later`),
      },
      desc: {
        key: SURVEY_SORT_OPTIONS.END_DATE_LATER_SOON,
        label: (i18n: I18n) => i18n._(t`Later - soon`),
      },
    },
    renderCell: ({ dates, status }) => {
      return (
        <TD isGrayColor={status === SURVEY_STATUS.DRAFT}>
          <span>{convertToTimeString(dates?.endDate, TIME_FORMATS.CLASSIC)}</span>
        </TD>
      );
    },
  },
];

export { SURVEYS_COLUMNS };
