import React, { useState } from 'react';

import { CONFIRMATION_MODAL_TYPE, SURVEY_PARTICIPANTS_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import type { IGeneralForm } from '~/pages/SurveyUpdate/types';

import { useAutoSaveFunc } from '~/hooks/useAutoSave';
import { getCurrentCompanyId } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';

import type { I18n } from '@lingui/core';
import type { UseFormReturn } from 'react-hook-form';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 94px 0 192px;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.MIDDLE_GRAY};
  margin-bottom: 30px;
  max-width: 350px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 32px;
`;

const RowItem = styled.button<{ $isActive: boolean }>`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border-radius: 6px;
  color: ${COLORS.BLUE_DARK};
  border: 1px solid ${(props) => (props.$isActive ? COLORS.BLUE_DARK : COLORS.BG_PAGE)};
  margin-right: 10px;
  cursor: pointer;
  padding: 6px 8px 6px;
  min-height: 32px;

  &:hover {
    background-color: ${COLORS.BG_PAGE};
  }
`;

export const items = {
  [SURVEY_PARTICIPANTS_TYPE.EMPLOYEES]: {
    type: SURVEY_PARTICIPANTS_TYPE.EMPLOYEES,
    title: (i18n: I18n) => i18n._(t`Employees`),
    description: (i18n: I18n) =>
      i18n._(
        t`When you add or remove employees, Learned will automatically include them in the next survey invite`,
      ),
  },
  [SURVEY_PARTICIPANTS_TYPE.TEAMS]: {
    type: SURVEY_PARTICIPANTS_TYPE.TEAMS,
    title: (i18n: I18n) => i18n._(t`Teams`),
    description: (i18n: I18n) =>
      i18n._(
        t`When you add or remove members to your team, Learned will automatically include them in the next survey invite`,
      ),
  },
  [SURVEY_PARTICIPANTS_TYPE.COMPANY]: {
    type: SURVEY_PARTICIPANTS_TYPE.COMPANY,
    title: (i18n: I18n) => i18n._(t`Everyone in company`),
    description: (i18n: I18n) =>
      i18n._(
        t`When you add or remove members to your company, Learned will automatically include them in the next survey invite`,
      ),
  },
};

interface ISectionParticipantsType {
  formMethods: UseFormReturn<IGeneralForm>;
  onInvite?: () => void;
  autosave: useAutoSaveFunc;
}
const SectionParticipantsType = ({ formMethods, onInvite, autosave }: ISectionParticipantsType) => {
  const { i18n } = useLingui();
  const [hoverType, setHoverType] = useState<SURVEY_PARTICIPANTS_TYPE | null>(null);
  const { watch, setValue } = formMethods;
  const selectedType = watch('participants.type');
  const selectedIds = watch('participants.ids');
  const companyId = useSelector(getCurrentCompanyId);

  const typeInDescription = hoverType || selectedType;

  const onClick = async (type: SURVEY_PARTICIPANTS_TYPE) => {
    let isConfirmed = true;

    if (selectedIds.length > 0) {
      isConfirmed = (await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Are you sure want to change current mode?`),
        description: i18n._(
          t`Switching to another mode will deselect all current selections and has impact on survey results. This action cannot be undone.`,
        ),
      })) as unknown as boolean;
    }

    if (isConfirmed) {
      setValue('participants.type', type);
      setValue('participants.ids', type === SURVEY_PARTICIPANTS_TYPE.COMPANY ? [companyId] : []);
      autosave.run('participants.type');
    }
  };

  return (
    <Wrapper>
      <Description>{items[typeInDescription].description(i18n)}</Description>
      <Row>
        {Object.values(items).map((item) => (
          <RowItem
            type="button"
            key={item.type}
            $isActive={selectedType === item.type}
            onClick={() => onClick(item.type)}
            onMouseOver={() => setHoverType(item.type)}
            onMouseLeave={() => setHoverType(null)}
          >
            {item.title(i18n)}
          </RowItem>
        ))}
      </Row>

      {onInvite && (
        <Button
          type="button"
          variant={ButtonVariant.PRIMARY}
          label={
            selectedType === SURVEY_PARTICIPANTS_TYPE.TEAMS
              ? i18n._(t`Invite teams`)
              : i18n._(t`Invite`)
          }
          size={ButtonSize.MEDIUM}
          onClick={onInvite}
        />
      )}
    </Wrapper>
  );
};

export { SectionParticipantsType };
