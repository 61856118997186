import React, { useState, Fragment } from 'react';

import { ICompany } from '@learned/types';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import CompanyAvatar from '~/components/CompanyAvatar';
import { CompanyDropdown } from '~/components/Dropdown/CompanyDropdown';

import { UploadLogoButton } from './UploadLogoButton';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import {
  getCurrentCompanyId,
  getCompanies,
  getSelectedRole,
  getSelectedTeam,
} from '~/selectors/baseGetters';
import { cancelAllRequests } from '~/services/cloudRequest';
import { updateCompanyLogo } from '~/services/companies';
import * as appThemeActions from '~/store/appTheme/actions';
import { resetCustomReportsStore } from '~/store/customReports/actions';
import { setInvites } from '~/store/invites/actions';
import { setNotifications } from '~/store/notifications/actions';
import {
  updateSelectedRole,
  updateSelectedCompany,
  updateSelectedTeam,
} from '~/store/selected/actions';
import { fetchUserData } from '~/store/users/actions';

import { CompanyBlockItem, CompanyBlockWrapper } from '../styles/design';

const CompanySwitchMenu = ({
  isMenuCollapsed,
  isUserHasAdminRole,
}: {
  isMenuCollapsed: boolean;
  isUserHasAdminRole: boolean;
}) => {
  const currentCompanyId = useSelector(getCurrentCompanyId);
  const companies: Record<string, ICompany> = useSelector(getCompanies);
  const selectedRole = useSelector(getSelectedRole);
  const selectedTeam = useSelector(getSelectedTeam);
  const dispatch = useDispatch();
  const history = useHistory();
  const availableCompanies = (Object.values(companies) || []).filter(
    (company: ICompany) => company.id !== currentCompanyId,
  );
  const [companiesToSelect, setCompaniesToSelect] = useState(availableCompanies || []);
  const [search, setSearch] = useState('');

  const onCompanyChange = (company: ICompany) => {
    const companyId = company.id;
    cancelAllRequests();
    if (companyId === currentCompanyId) {
      return;
    }
    if (selectedRole !== ROLES.USER) {
      // eslint-disable-next-line
      // @ts-ignore
      dispatch(updateSelectedRole(ROLES.USER));
    }
    if (selectedTeam) {
      dispatch(updateSelectedTeam(null));
    }
    // eslint-disable-next-line
    // @ts-ignore
    dispatch(updateSelectedCompany(companyId));
    dispatch(fetchUserData());
    dispatch(setInvites({ fetchInvites: true }));
    dispatch(setNotifications({ fetchNotifications: true }));
    dispatch(resetCustomReportsStore());
    history.push(routes.HOME);
  };

  const onSearchChange = (value: string) => {
    setSearch(value);
    const filteredCompanies = availableCompanies.filter((company: ICompany) => {
      return Boolean(company?.name?.toLowerCase().includes(value.toLowerCase()));
    });
    setCompaniesToSelect(filteredCompanies);
  };

  const onUploadChange = async (e: any) => {
    e.stopPropagation();
    const file = e?.target?.files[0];

    if (file) {
      const updatedCompany = await updateCompanyLogo(currentCompanyId, file);
      dispatch(appThemeActions.setTheme(updatedCompany));
    }
  };

  const isShowDropdown = availableCompanies.length > 0;
  const isShowUploadLogoButton = !companies[currentCompanyId]?.logoUrl && isUserHasAdminRole;
  return (
    <Fragment>
      <CompanyBlockWrapper>
        {isMenuCollapsed ? (
          <CompanyBlockItem tabIndex={0}>
            {isShowUploadLogoButton ? (
              <UploadLogoButton onUploadChange={onUploadChange} />
            ) : (
              // eslint-disable-next-line
              // @ts-ignore
              <CompanyAvatar logoUrl={companies[currentCompanyId]?.logoUrl} size={46} />
            )}
          </CompanyBlockItem>
        ) : (
          <CompanyDropdown
            companies={companiesToSelect}
            selectedCompany={companies[currentCompanyId]}
            // eslint-disable-next-line
            // @ts-ignore
            onChange={onCompanyChange}
            onSearchChange={onSearchChange}
            isSearchable={companiesToSelect.length > 7 || !isEmpty(search)}
            isShowUploadLogoButton={isShowUploadLogoButton}
            onUploadChange={onUploadChange}
            isClickable={isShowDropdown}
          />
        )}
      </CompanyBlockWrapper>
    </Fragment>
  );
};

export default CompanySwitchMenu;
