import React from 'react';

import { ProductName, ProductStatus } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Modal from '~/components/Modal';
import { useToasts, TOAST_TYPES } from '~/components/Toast';

import { StyledTextArea, Description, Title, ProductsWrapper, FooterRight } from './design';
import { ProductItem } from './ProductItem';

import { getCurrentCompanyId } from '~/selectors/baseGetters';
import { productsUpgrade } from '~/services/companies';
import { updateCompanyProducts } from '~/store/companies/actions';

import type { IUpgradePlanModalProps, IUpgradePlanForm, Product } from './types';

const UpgradePlanModal = ({
  products,
  preselectedProducts = [],
  closeModal,
}: IUpgradePlanModalProps) => {
  const { i18n } = useLingui();
  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const currentCompanyId = useSelector(getCurrentCompanyId);
  const { watch, setValue, control, handleSubmit } = useForm<IUpgradePlanForm>({
    mode: 'all',
    defaultValues: {
      selectedProducts: preselectedProducts,
    },
  });

  const selectedProducts = watch('selectedProducts');

  const handleClickOnProduct = (name: ProductName) => {
    if (selectedProducts.includes(name)) {
      setValue(
        'selectedProducts',
        selectedProducts.filter((product) => product !== name),
      );
    } else {
      setValue('selectedProducts', [...selectedProducts, name]);
    }
  };

  const sortProducts = (product: Product) => {
    switch (product.status) {
      case ProductStatus.TRIAL_ENDED:
        return -1;
      case ProductStatus.TRIAL_ACTIVE:
        return 0;
      default:
        return 1;
    }
  };

  const onSubmit = async ({ selectedProducts, message }: IUpgradePlanForm) => {
    try {
      const upgradedProducts = await productsUpgrade({
        products: selectedProducts.map((name) => ({ name, message })),
        companyId: currentCompanyId,
      });
      if (upgradedProducts.data) {
        addToast({
          title: i18n._(t`Your message has been sent`),
          subtitle: i18n._(t`A colleague will contact you within 1 work day`),
          type: TOAST_TYPES.SUCCESS,
        });
      }

      dispatch(updateCompanyProducts(upgradedProducts.data.products));
    } catch (error) {
      console.error(error);
    } finally {
      closeModal();
    }
  };

  return (
    <Modal
      title={i18n._(t`Upgrade plan`)}
      onClose={closeModal}
      showDivider={false}
      centerModal
      minWidth={750}
      contentStyles={{ padding: '20px 40px 0', overflow: 'visible' }}
      headerStyles={{ padding: '20px 40px', fontSize: '26px', fontWeight: 'normal' }}
      borderRadius={6}
      hideFooter
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ProductsWrapper>
          <Title>
            <Trans>Selected products ({selectedProducts.length})</Trans>
          </Title>
          {products
            ?.filter((product) => selectedProducts.includes(product.name))
            .sort(sortProducts)
            ?.map(({ name, status }) => (
              <ProductItem
                key={name}
                name={name}
                status={status}
                selected={selectedProducts.includes(name)}
                onClick={() => handleClickOnProduct(name)}
              />
            ))}
          {products
            ?.filter((product) => !selectedProducts.includes(product.name))
            .sort(sortProducts)
            ?.map(({ name, status }) => (
              <ProductItem
                key={name}
                name={name}
                status={status}
                selected={selectedProducts.includes(name)}
                onClick={() => handleClickOnProduct(name)}
              />
            ))}
        </ProductsWrapper>
        <Title>
          <Trans>We will get in touch!</Trans>
        </Title>
        <Description>
          <Trans>
            One of our colleagues will contact you within 1 working day about discussing your
            account. You can leave a message with any questions you would like to discuss.
          </Trans>
        </Description>

        <Controller
          name="message"
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <StyledTextArea
              value={field.value}
              compact
              placeholder={i18n._(t`Leave a message (optional)…`)}
              onChange={(value: string) => {
                field.onChange(value);
              }}
              big
            />
          )}
        />
        <FooterRight>
          <Button
            label={i18n._(t`Cancel`)}
            onClick={closeModal}
            type="button"
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
          />
          <Button
            label={i18n._(t`Confirm request`)}
            type="submit"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            disabled={!watch('selectedProducts').length}
          />
        </FooterRight>
      </form>
    </Modal>
  );
};

export { UpgradePlanModal };
