import React from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import filter from 'lodash/filter';
import styled from 'styled-components';

import { COLOR_PALETTE, COLORS } from '~/styles';

// 600px is max, with bigger height position wrong, because height > screen height
const MemberOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 526px;
  max-height: ${(props) => props.$maxHeight || 600}px;
  overflow-y: auto;
`;

const MemberOptionsActions = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReviewOptionsAction = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLOR_PALETTE.BLACK};
  cursor: pointer;
  padding: 12px 24px;

  &:hover {
    background-color: ${COLORS.BG_PAGE};
    color: var(--company-color);
  }

  &:active {
    background-color: ${COLORS.HOVER_ACTIVE};
  }
`;

const MembersOptions = ({
  inviteMembers,
  synchroniseMembers,
  cleanupMembers,
  integrationSettings,
  isImportUsersEnabled,
  exportCSV,
}) => {
  const dropdownOptions = [
    {
      label: t`Invite member(s)`,
      action: () => inviteMembers,
      visibility: () => true,
    },
    {
      label: t`Synchronise member(s)`,
      action: () => synchroniseMembers,
      visibility: () => isImportUsersEnabled,
    },
    {
      label: t`Cleanup members`,
      action: () => cleanupMembers,
      visibility: () =>
        filter(integrationSettings, (is) => is.integrationModules.deleteUsers.enabled).length > 0,
    },
    {
      label: t`Export CSV`,
      action: () => exportCSV,
      visibility: () => true,
    },
  ];

  const $maxHeight = window.innerHeight - 100;
  return (
    <MemberOptionsWrapper $maxHeight={$maxHeight}>
      <MemberOptionsActions>
        {dropdownOptions.map((action, i) => {
          if (action.visibility()) {
            return (
              <ReviewOptionsAction onClick={action.action()} key={i}>
                <Trans id={action.label} />
              </ReviewOptionsAction>
            );
          }
        })}
      </MemberOptionsActions>
    </MemberOptionsWrapper>
  );
};

export default withI18n()(MembersOptions);
