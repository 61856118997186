import React, { ReactNode } from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled, { css } from 'styled-components';

import { ButtonShadow } from '~/components/Button';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import Modal from '~/components/Modal';
import { Header6 } from '~/components/UI/Typographics/headers';

import { COLORS } from '~/styles';

import type { I18n } from '@lingui/core';

export interface IConfirmationModelProps {
  title?: string;
  description?: string;
  type: CONFIRMATION_MODAL_TYPE;
  onClose?: () => void;
  onSubmit?: () => void;
  cancelButton?: ReactNode;
  submitButton?: ReactNode;
  proceed?: (value?: boolean) => void; // to use modal with confirm method
  children?: any; // ReactNode;
  validations?: () => boolean;
  cancelButtonTextColor?: string;
  isNoAction?: boolean;
}

const Wrapper = styled.div<{ type: CONFIRMATION_MODAL_TYPE }>`
  justify-content: center;
  border-radius: 6px;

  .modal-footer-info button {
    padding: 10px 8px;
  }

  ${({ type }) =>
    type &&
    type === CONFIRMATION_MODAL_TYPE.DELETE &&
    css`
      & .header {
        background-color: ${COLORS.CONFIRMATION_MODAL_DELETE};
      }

      & .submitButton {
        background-color: ${COLORS.CONFIRMATION_MODAL_DELETE};
      }
    `}

  ${({ type }) =>
    type &&
    type === CONFIRMATION_MODAL_TYPE.WARNING &&
    css`
      & .header {
        background-color: ${COLORS.CONFIRMATION_MODAL_WARNING};
      }

      & .submitButton {
        background-color: ${COLORS.CONFIRMATION_MODAL_WARNING};
      }
    `}

  ${({ type }) =>
    type &&
    type === CONFIRMATION_MODAL_TYPE.INFO &&
    css`
      & .header {
        background-color: ${COLORS.CONFIRMATION_MODAL_INFO};
      }

      & .submitButton {
        background-color: ${COLORS.CONFIRMATION_MODAL_INFO};
      }
    `}

    ${({ type }) =>
    type &&
    type === CONFIRMATION_MODAL_TYPE.SUCCESS &&
    css`
      & .header {
        background-color: ${COLORS.CONFIRMATION_MODAL_SUCCESS};
      }

      & .submitButton {
        background-color: ${COLORS.CONFIRMATION_MODAL_SUCCESS};
      }
    `}
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  border-radius: 6px 6px 0 0;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding: 20px 32px 32px;

  & h6 {
    text-align: center;
    margin: 0;
  }
`;

const Description = styled.p`
  font-size: 12px;
  line-height: 1.42;
  text-align: center;
  color: ${COLORS.TEXT_HOVER};
  margin: 8px 0 32px;
`;

const Footer = styled.div`
  display: flex;
  gap: 10px;
`;

const CancelButton = styled(ButtonShadow)`
  padding: 8px 25.7px 7px 26.7px;
  font-size: 12px;
  width: 100%;
  color: ${(props) => props.color ?? props.color};
  white-space: nowrap;
`;

const SubmitButton = styled(ButtonShadow)`
  padding: 8px 25.7px 7px 26.7px;
  font-size: 12px;
  color: ${COLORS.WHITE};
  width: 100%;
`;

const getSubmitButtonText = (type: CONFIRMATION_MODAL_TYPE, i18n: I18n) => {
  switch (type) {
    case CONFIRMATION_MODAL_TYPE.DELETE:
      return i18n._(t`Delete`);
    case CONFIRMATION_MODAL_TYPE.INFO:
      return i18n._(t`Save as draft`);
    case CONFIRMATION_MODAL_TYPE.SUCCESS:
    case CONFIRMATION_MODAL_TYPE.WARNING:
      return i18n._(t`Continue`);
    default:
      return '';
  }
};

const getTitleText = (type: CONFIRMATION_MODAL_TYPE, i18n: I18n) => {
  switch (type) {
    case CONFIRMATION_MODAL_TYPE.DELETE:
      return i18n._(t`Delete?`);
    case CONFIRMATION_MODAL_TYPE.WARNING:
      return i18n._(t`Warning`);
    case CONFIRMATION_MODAL_TYPE.INFO:
      return i18n._(t`Info`);
    case CONFIRMATION_MODAL_TYPE.SUCCESS:
      return i18n._(t`Success`);
    default:
      return '';
  }
};

const HeaderIconProps = {
  size: ICON_SIZES.LARGE,
  color: COLORS.WHITE,
  width: 32,
  height: 32,
};

const ConfirmationModal = ({
  title,
  description,
  type,
  onClose,
  onSubmit,
  cancelButton,
  submitButton,
  proceed,
  children,
  validations,
  cancelButtonTextColor,
  isNoAction,
}: IConfirmationModelProps) => {
  const { i18n } = useLingui();

  const closeModal = () => {
    if (proceed) {
      proceed(false);
    } else {
      onClose?.();
    }
  };

  const submitModal = () => {
    if (proceed) {
      proceed(true);
    } else if (validations) {
      if (validations()) {
        onSubmit?.();
        onClose?.();
      }
    } else {
      onSubmit?.();
      onClose?.();
    }
  };

  return (
    <Modal
      isHideHeader
      hideFooter
      onClose={closeModal}
      showDivider={false}
      centerModal
      width={300}
      borderRadius={6}
      boxShadow={'0 8px 8px 0 rgba(30, 8, 99, 0.21)'}
      backgroundColor={'rgba(131, 137, 160, 0.5)'}
      contentStyles={{ padding: '0', overflow: 'hidden' }}
    >
      <Wrapper type={type}>
        <Header className="header">
          {type === CONFIRMATION_MODAL_TYPE.DELETE && (
            <Icon icon={ICONS.DELETE_BIN_FILLED} {...HeaderIconProps} />
          )}
          {type === CONFIRMATION_MODAL_TYPE.WARNING && (
            <Icon icon={ICONS.WARNING} {...HeaderIconProps} />
          )}
          {type === CONFIRMATION_MODAL_TYPE.INFO && (
            <Icon icon={ICONS.INFO_3} {...HeaderIconProps} />
          )}
          {type === CONFIRMATION_MODAL_TYPE.SUCCESS && (
            <Icon icon={ICONS.VALID} {...HeaderIconProps} />
          )}
        </Header>
        <Main>
          <Header6>{title || getTitleText(type, i18n)}</Header6>
          {description && <Description>{description}</Description>}
          {children}
          <Footer className={`modal-footer modal-footer-${type}`}>
            <CancelButton onClick={closeModal} color={cancelButtonTextColor}>
              {cancelButton ||
                (type === CONFIRMATION_MODAL_TYPE.INFO && i18n._(t`Discard changes`)) ||
                i18n._(t`Cancel`)}
            </CancelButton>
            {!isNoAction && (
              <SubmitButton className="submitButton" onClick={submitModal}>
                {submitButton || getSubmitButtonText(type, i18n)}
              </SubmitButton>
            )}
          </Footer>
        </Main>
      </Wrapper>
    </Modal>
  );
};

export { ConfirmationModal };
