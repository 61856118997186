import { QUESTION_TYPES, KPI_TYPES } from '@learned/constants';

/**
 * @deprecated since version 3.0 {@see reviewTemplates.ts}
 * still in use, no replacement yet
 */
interface IReviewTemplateQuestionBase {
  name?: string;
  description?: string;
  skills?: string[];
  hideRating?: boolean;
  isSkippable?: boolean;
  isAverageQuestionEnabled?: boolean;
}

/**
 * @deprecated since version 3.0 {@see reviewTemplates.ts}
 * still in use, no replacement yet
 */
interface IReviewTemplateQuestionCustom extends IReviewTemplateQuestionBase {
  type: QUESTION_TYPES.CUSTOM;
  scaleLabels: string[];
}

/**
 * @deprecated since version 3.0 {@see reviewTemplates.ts}
 * still in use, no replacement yet
 */
interface IReviewTemplateQuestionEval extends IReviewTemplateQuestionBase {
  type: QUESTION_TYPES.GOAL_BUSINESS_EVAL | QUESTION_TYPES.GOAL_LEARNING_EVAL;
  isAverageQuestionEnabled: boolean;
}

/**
 * @deprecated since version 3.0 {@see reviewTemplates.ts}
 * still in use, no replacement yet
 */
export interface IReviewTemplateQuestionJobProfile extends IReviewTemplateQuestionBase {
  type: QUESTION_TYPES.JOB_PROFILE;
  skillCategories: string[];
}

/**
 * @deprecated since version 3.0 {@see reviewTemplates.ts}
 * still in use, no replacement yet
 */
interface IReviewTemplateQuestionSkill extends IReviewTemplateQuestionBase {
  type: QUESTION_TYPES.SKILL;
}

/**
 * @deprecated since version 3.0 {@see reviewTemplates.ts}
 * still in use, no replacement yet
 */
export type IReviewTemplateQuestion =
  | IReviewTemplateQuestionEval
  | IReviewTemplateQuestionCustom
  | IReviewTemplateQuestionJobProfile
  | IReviewTemplateQuestionSkill;

/**
 * @deprecated since version 3.0 {@see reviewTemplates.ts}
 * still in use, no replacement yet
 */
interface IReviewTemplateSectionBase {
  name: string;
  questions: IReviewTemplateQuestion[];
  scaleLabels?: string[];
  isSkippable?: boolean;
  isSelfReview?: boolean;
  isUsersReview?: boolean;
  isCoachesReview?: boolean;
}

/**
 * @deprecated since version 3.0 {@see reviewTemplates.ts}
 * still in use, no replacement yet
 */
interface IReviewTemplateSectionPotential extends IReviewTemplateSectionBase {
  type: KPI_TYPES.POTENTIAL;
  kpi: string;
  isSkippable: boolean;
  scaleLabels: string[];
}

/**
 * @deprecated since version 3.0 {@see reviewTemplates.ts}
 * still in use, no replacement yet
 */
interface IReviewTemplateSectionPerformance extends IReviewTemplateSectionBase {
  type: KPI_TYPES.PERFORMANCE;
  kpi: string;
  isSkippable: boolean;
  scaleLabels: string[];
}

/**
 * @deprecated since version 3.0 {@see reviewTemplates.ts}
 * still in use, no replacement yet
 */
interface IReviewTemplateSectionSkills extends IReviewTemplateSectionBase {
  type: KPI_TYPES.SKILLS;
  kpi: string;
}

/**
 * @deprecated since version 3.0 {@see reviewTemplates.ts}
 * still in use, no replacement yet
 */
interface IReviewTemplateSectionNone extends IReviewTemplateSectionBase {
  type: null;
  kpi: null;
}

/**
 * @deprecated since version 3.0 {@see reviewTemplates.ts}
 * still in use, no replacement yet
 */
// kpi created by admin
interface IReviewTemplateSectionCustom extends IReviewTemplateSectionBase {
  type: null;
  kpi: string;
}

/**
 * @deprecated since version 3.0 {@see reviewTemplates.ts}
 * still in use, no replacement yet
 */
export type IReviewTemplateSection =
  | IReviewTemplateSectionPotential
  | IReviewTemplateSectionPerformance
  | IReviewTemplateSectionSkills
  | IReviewTemplateSectionNone
  | IReviewTemplateSectionCustom;

/**
 * @deprecated since version 3.0 {@see reviewTemplates.ts}
 * still in use, no replacement yet
 */
export enum REVIEW_STATUSES {
  DRAFT = 'draft',
  ACTIVE = 'active',
  DONE = 'done',
  ARCHIVED = 'archived',
}

/**
 * @deprecated since version 3.0 {@see reviewTemplates.ts}
 * still in use, no replacement yet
 */
export interface IReviewTemplateOld {
  id: string;
  name: string;
  description: string;
  sections: IReviewTemplateSection[];
  status: REVIEW_STATUSES;
  company: string;
  createdBy?: string;
  meta: {
    createdDate: string;
    lastModifiedDate: string;
  };
}
