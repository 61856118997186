import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { REVIEW_QUESTION_EVALUATORS, REVIEW_QUESTION_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { size, uniq, values } from 'lodash';
import { useFieldArray, UseFormReturn, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import { QuestionModal } from '~/pages/ReviewThemeSetup/components/QuestionModal';
import { SkillModal } from '~/pages/ReviewThemeSetup/components/SkillModal';
import {
  IQuestionForm,
  ISelectedSkill,
  ISkill,
  IThemeQuestionDefaultData,
} from '~/pages/ReviewThemeSetup/types';
import {
  convertQuestionOptions,
  getEvaluators,
  getLabelsForAvailableLanguages,
  getQuestionTypeObject,
  getRatingLabels,
} from '~/pages/ReviewThemeSetup/utils';

import { Footer, Form, StyledTitle } from './design';
import { ImportQuestionsModal } from './ImportQuestionsModal';
import { QuestionsEmpty } from './QuestionsEmpty';
import { QuestionsList } from './QuestionsList';

import useBoolState from '~/hooks/useBoolState';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { createReviewQuestion, updateReviewQuestion } from '~/services/reviewQuestions';
import { getReviewTheme } from '~/services/reviewThemes';
import { getSkillCategories } from '~/services/skillCategories';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import type { IGeneralForm, PopulatedQuestion } from '../../types';
import type {
  IMultiLangString,
  IReviewQuestion,
  IReviewQuestionCustomSkillSettings,
  IReviewQuestionDefaultData,
  IReviewQuestionGoalPlanSettings,
  IReviewQuestionSkillCategorySettings,
} from '@learned/types';

interface IQuestionsProps {
  invalidOptionalQuestions?: Record<string, PopulatedQuestion>;
  setCurrentSection: Dispatch<number>;
  formMethods: UseFormReturn<IGeneralForm>;
  languageState: ILanguageStateReturn;
  questions: Record<IReviewQuestion['id'], IReviewQuestion & { themeName?: IMultiLangString }>;
  setQuestions: Dispatch<SetStateAction<Record<string, IReviewQuestion>>>;
  reviewTemplateId: string;
  refreshTemplate: () => void;
  questionIdToOpen?: string;
  setEditQuestion: (questionId?: string) => void;
  autoSave: () => void;
  equalizeWeights: (themeIds?: string[]) => void;
  themeIds: string[];
  getWeightedThemes: (themeIds: string[], questions?: IReviewQuestion[]) => string[];
}

const Questions = ({
  invalidOptionalQuestions,
  formMethods,
  setCurrentSection,
  languageState,
  refreshTemplate,
  reviewTemplateId,
  autoSave,
  equalizeWeights,
  themeIds,
  setQuestions,
  questions,
  getWeightedThemes,
}: IQuestionsProps) => {
  const { control } = formMethods;
  const { i18n } = useLingui();
  const $isImportQuestion = useBoolState(false);
  // Todo: implement
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addQuestion, setAddQuestion] = useState<boolean>(false);
  const [index, setIndex] = useState<number | undefined>();
  const indexItemToAdd = 0;
  const $isSkillModalOpen = useBoolState(false);

  const [updatedQuestions, setUpdatedQuestions] = useState<IReviewQuestion[]>([]);
  const [skillCategories, setSkillCategories] = useState<
    { value: string; label: Record<string, string>; type: string }[]
  >([]);
  const [selectedSkills, setSelectedSkills] = useState<ISelectedSkill[]>([]);
  const [selectedQuestionToEdit, setSelectedQuestionToEdit] = useState<
    IQuestionForm | null | undefined
  >(null);
  const [skills, setSkills] = useState<ISkill[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedSkill, setSelectedSkill] = useState<ISkill | null>(null);

  const questionIdsWatch = useWatch({ control, name: 'questions' });

  const questionIdsField = useFieldArray({ name: 'questions', control });

  const goBack = () => setCurrentSection(0);
  const goNext = () => setCurrentSection(2);

  const createNewQuestion = (index?: number) => {
    setIndex(index);
    setAddQuestion(true);
  };

  const reEvaluateWeights = (
    currentThemeIds: string[],
    updatedThemeIds: string[],
    questions: IReviewQuestion[],
  ) => {
    const existingWeightedThemes = getWeightedThemes(currentThemeIds);
    const currentWeightedThemes = getWeightedThemes(updatedThemeIds, questions);

    if (existingWeightedThemes.length !== currentWeightedThemes.length) {
      equalizeWeights(updatedThemeIds);
    }
  };

  useEffect(() => {
    reEvaluateWeights(
      themeIds,
      [...themeIds, ...uniq(updatedQuestions.map((q) => q.theme))],
      updatedQuestions,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions]);

  const addQuestions = (...questions: IReviewQuestion[]) => {
    const questionIds = questions.map((question) => ({ questionId: question.id }));
    const questionsMap = questions.reduce(
      (acc, question) => ({ ...acc, [question.id]: question }),
      {},
    );
    setQuestions((prevState) => ({ ...prevState, ...questionsMap }));
    questionIdsField.insert(
      index !== undefined ? index + 1 : size(questionIdsField.fields),
      questionIds,
    );

    setUpdatedQuestions(questions);

    autoSave();
  };

  const updateQuestions = (...questions: IReviewQuestion[]) => {
    const questionsMap = questions.reduce(
      (acc, question) => ({ ...acc, [question.id]: question }),
      {},
    );

    setQuestions((prevState) => ({ ...prevState, ...questionsMap }));
    setUpdatedQuestions(questions);
    autoSave();
  };

  const removeQuestion = (index: number, item: IReviewQuestion) => {
    questionIdsField.remove(index);
    setQuestions((prevState) => {
      const newState = { ...prevState };
      delete newState[item.id];
      return newState;
    });
    const themeRemoved =
      values(questions)
        .filter(
          (question) =>
            question.theme === item.theme &&
            (question.settings as IReviewQuestionDefaultData).isAnswerObligated &&
            (question.settings as IReviewQuestionDefaultData)?.isManualScale === false,
        )
        .map((q) => q.theme).length === 1;
    if (themeRemoved) {
      equalizeWeights(themeIds.filter((id) => id !== item.theme));
    }
    autoSave();
  };

  const reorderQuestions = (oldIndex: number, newIndex: number) => {
    questionIdsField.move(oldIndex, newIndex);
  };

  const handleSubmit = (value: IQuestionForm, reviewThemeId?: string) => {
    if (!reviewThemeId) {
      return;
    }
    if (selectedQuestionToEdit) {
      editQuestion(value, reviewThemeId);
    } else {
      createQuestion(value, reviewThemeId);
    }
  };

  const createQuestion = async (value: IQuestionForm, reviewThemeId?: string) => {
    if (reviewThemeId) {
      const evaluators = Object.keys(value.settings.evaluators)
        .map((key: string) =>
          value.settings?.evaluators?.[key as keyof typeof value.settings.evaluators] ? key : null,
        )
        .filter(Boolean);

      const {
        data: { reviewQuestion },
      } = await createReviewQuestion({
        position: indexItemToAdd,
        // @ts-ignore
        question: {
          theme: reviewThemeId === 'OTHER' ? '' : reviewThemeId,
          type: value.type.key as REVIEW_QUESTION_TYPES,
          name: convertLanguageValue(value.name),
          description: convertLanguageValue(value.description),
          settings: {
            options: value?.settings?.isManualScale
              ? getRatingLabels(languageState, value?.options)
              : null,
            evaluators:
              value.type.key === REVIEW_QUESTION_TYPES.GOAL_PLAN
                ? [REVIEW_QUESTION_EVALUATORS.EMPLOYEE, REVIEW_QUESTION_EVALUATORS.COACH] // for plan-goal - always employee and coach (not editable)
                : evaluators,
            isCommentsAllowed: value.settings.isCommentsAllowed,
            isCommentsObligated: value.settings.isCommentsObligated,
            isAnswerObligated: !value.settings.isAnswerObligated,
            isMeasurementReversed: value.settings.isMeasurementReversed,
            isManualScale: value.settings.isManualScale,
            skillCategory:
              value.type.key === REVIEW_QUESTION_TYPES.SKILL_CATEGORY &&
              value.skillOrKpiCategory?.id,
            skills: value.type.key === REVIEW_QUESTION_TYPES.CUSTOM_SKILL && value.settings.skills,
            subTypes: value.settings.subTypes,
          },
        },
      });
      const reviewQuestionWithTheme = await mapThemeToNewQuestion(reviewThemeId, reviewQuestion);
      addQuestions(reviewQuestionWithTheme);
      autoSave();
    }
  };

  const mapThemeToNewQuestion = async (themeId: string, reviewQuestion: IReviewQuestion) => {
    if (reviewQuestion.themeName && reviewQuestion.themeIcon && reviewQuestion.themeColor) {
      return reviewQuestion;
    }
    const themeData = await getReviewTheme(themeId);
    reviewQuestion.themeName = themeData.data.reviewTheme.name;
    reviewQuestion.themeIcon = themeData.data.reviewTheme.icon;
    reviewQuestion.themeColor = themeData.data.reviewTheme.iconColor;
    return reviewQuestion;
  };

  const editQuestion = async (value: IQuestionForm, reviewThemeId: string) => {
    if (selectedQuestionToEdit) {
      const evaluators = Object.keys(value.settings.evaluators)
        .map((key: string) =>
          value.settings?.evaluators?.[key as keyof typeof value.settings.evaluators] ? key : null,
        )
        .filter(Boolean);

      const {
        data: { reviewQuestion },
      } = await updateReviewQuestion(selectedQuestionToEdit.id!, {
        type: value.type.key as REVIEW_QUESTION_TYPES,
        theme: reviewThemeId === 'OTHER' ? '' : reviewThemeId,
        name: convertLanguageValue(value.name),
        description: convertLanguageValue(value.description),
        settings: {
          options: value?.settings?.isManualScale
            ? getRatingLabels(languageState, value?.options)
            : null,
          evaluators:
            value.type.key === REVIEW_QUESTION_TYPES.GOAL_PLAN
              ? [REVIEW_QUESTION_EVALUATORS.EMPLOYEE, REVIEW_QUESTION_EVALUATORS.COACH] // for plan-goal - always employee and coach (not editable)
              : evaluators,
          isCommentsAllowed: value.settings.isCommentsAllowed,
          isCommentsObligated: value.settings.isCommentsObligated,
          isAnswerObligated: !value.settings.isAnswerObligated,
          isMeasurementReversed: value.settings.isMeasurementReversed,
          isManualScale: value.settings.isManualScale,
          skillCategory:
            value.type.key === REVIEW_QUESTION_TYPES.SKILL_CATEGORY && value.skillOrKpiCategory?.id,
          skills: value.type.key === REVIEW_QUESTION_TYPES.CUSTOM_SKILL && value.settings.skills,
          subTypes: value.settings.subTypes,
        },
      });
      const reviewQuestionWithTheme = await mapThemeToNewQuestion(reviewThemeId, reviewQuestion);
      updateQuestions(reviewQuestionWithTheme);
      autoSave();
    }
  };

  const handleSelectedSkillSubmit = (selectedSkill: ISelectedSkill) => {
    setSelectedSkills([
      ...selectedSkills.filter(({ skill }: ISelectedSkill) => skill !== selectedSkill.skill),
      selectedSkill,
    ]);
    $isSkillModalOpen.off();
  };

  const handleEdit = (questionId: string) => {
    setAddQuestion(true);
    const question = questions[questionId];

    if (question?.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
      setSelectedSkills((question?.settings?.skills as ISelectedSkill[]) || []);
    }

    const questionType = getQuestionTypeObject(question.type);

    const nameLabels = turnMultiLangIntoArray(question.name, languageState.companyLanguages);
    const descriptionLabels = turnMultiLangIntoArray(
      question.description || {},
      languageState.companyLanguages,
    );

    setSelectedQuestionToEdit({
      id: question.id,
      name: getLabelsForAvailableLanguages(languageState, nameLabels),
      description: getLabelsForAvailableLanguages(languageState, descriptionLabels),
      theme: question?.theme,
      type: questionType!,
      settings: {
        isCommentsAllowed:
          (question.settings as IThemeQuestionDefaultData)?.isCommentsAllowed ?? false,
        isCommentsObligated:
          (question.settings as IThemeQuestionDefaultData)?.isCommentsObligated ?? false,
        isMeasurementReversed:
          (question.settings as IThemeQuestionDefaultData)?.isMeasurementReversed ?? false,
        isManualScale: (question.settings as IThemeQuestionDefaultData)?.isManualScale ?? false,
        evaluators: getEvaluators(
          (question.settings as IThemeQuestionDefaultData)
            .evaluators as REVIEW_QUESTION_EVALUATORS[],
        ),
        skillCategory:
          (question.settings as IReviewQuestionSkillCategorySettings)?.skillCategory || '',
        skills: (question.settings as IReviewQuestionCustomSkillSettings)?.skills,
        isAnswerObligated:
          !(question.settings as IReviewQuestionDefaultData)?.isAnswerObligated || false,
        subTypes: (question.settings as IReviewQuestionGoalPlanSettings)?.subTypes || undefined,
      },
      options: convertQuestionOptions(question, languageState.companyLanguages),
    });
  };

  const currentCompany = useSelector(getCurrentCompany);

  useEffect(() => {
    const fetchSkillCategories = async () => {
      const response = await getSkillCategories();
      setSkillCategories(
        Object.values(response).map((skillCatgory) => {
          const category = skillCatgory as {
            id: string;
            name: Record<string, string>;
            skillLevels: Record<string, string>[];
            type: string;
          };
          return {
            value: category.id,
            label: category.name,
            levels: category.skillLevels?.length,
            type: category.type,
          };
        }),
      );
    };

    fetchSkillCategories();
  }, [currentCompany]);

  const handleQuestionModalClose = () => {
    setAddQuestion(false);
    setSelectedQuestionToEdit(null);
  };

  const handleDeleteFromQuestionModal = (id: IQuestionForm['id']) => {
    if (!id) {
      return;
    }
    const index = questionIdsField.fields.findIndex((question) => question.questionId === id);
    const question = questions[id];

    if (!question || index === -1) {
      return;
    }
    removeQuestion(index, question);
    handleQuestionModalClose();
  };

  return (
    <Form>
      <StyledTitle>
        <Trans>Questions</Trans>
      </StyledTitle>
      {questionIdsField.fields.length === 0 ? (
        <QuestionsEmpty
          createNewQuestion={createNewQuestion} // Todo: implement
          importQuestions={$isImportQuestion.on}
        />
      ) : (
        <QuestionsList
          invalidOptionalQuestions={invalidOptionalQuestions}
          items={questionIdsField.fields.map((field) => questions[field.questionId])}
          createNewQuestion={createNewQuestion}
          importQuestions={(index) => {
            setIndex(index);
            $isImportQuestion.on();
          }}
          languageState={languageState}
          onReorderQuestions={reorderQuestions}
          setEditQuestion={handleEdit}
          removeQuestion={removeQuestion}
          autosave={autoSave}
        />
      )}
      <Footer>
        <Button
          label={i18n._(t`Back`)}
          size={ButtonSize.MEDIUM}
          variant={ButtonVariant.TEXT_PRIMARY}
          onClick={goBack}
          icon={ICONS.BACK}
          iconSize={ICON_SIZES.SMALL}
        />
        <Button
          label={i18n._(t`Next`)}
          size={ButtonSize.MEDIUM}
          variant={ButtonVariant.NAVIGATION_PRIMARY}
          onClick={goNext}
        />
      </Footer>
      {$isImportQuestion.value && (
        <ImportQuestionsModal
          questionsToHide={questionIdsWatch.map((field) => field.questionId)}
          languageState={languageState}
          onSubmit={async () => {
            $isImportQuestion.off();
            await refreshTemplate();
            equalizeWeights();
          }}
          onClose={$isImportQuestion.off}
          index={index}
          createNewQuestion={createNewQuestion}
          reviewTemplateId={reviewTemplateId}
        />
      )}
      {addQuestion && (
        <QuestionModal
          languageState={languageState}
          onClose={handleQuestionModalClose}
          onDelete={handleDeleteFromQuestionModal}
          onSubmit={handleSubmit}
          setIsSkillModalOpen={$isSkillModalOpen.set}
          skillCategories={skillCategories}
          selectedSkills={selectedSkills}
          setSelectedSkills={setSelectedSkills}
          selectTheme={true}
          selectedQuestionToEdit={selectedQuestionToEdit}
          setCurrentStep={setCurrentStep}
        />
      )}

      {$isSkillModalOpen.value && (
        <SkillModal
          onClose={() => $isSkillModalOpen.off()}
          onSubmit={handleSelectedSkillSubmit}
          setSelectedSkill={setSelectedSkill}
          selectedSkill={selectedSkill}
          skills={skills}
          setSkills={setSkills}
          selectedSkills={selectedSkills}
          currentStep={currentStep}
        />
      )}
    </Form>
  );
};

export { Questions };
