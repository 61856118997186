import isEmpty from 'lodash/isEmpty';

export default (RTFeedback, users) => {
  if (isEmpty(RTFeedback) || isEmpty(users)) {
    return [];
  }

  return [
    ...(!isEmpty(RTFeedback.users) ? RTFeedback.users.map((userId) => users[userId]) : []).filter(
      (u) => u,
    ),
    ...(!isEmpty(RTFeedback.emails)
      ? RTFeedback.emails.map((email) => ({
          email,
          id: email,
        }))
      : []),
  ];
};
