import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import Spinner from '~/components/Spinner';

import { COLORS } from '~/styles';

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 470px;
  justify-content: center;
  align-items: center;
`;

const LoaderTitle = styled.div`
  margin-top: 16px;
  font-size: 22px;
  letter-spacing: -0.24px;
  text-align: center;
  color: ${COLORS.TEXT_HOVER};
`;

const LoaderSubTitle = styled.div`
  max-width: 265px;
  font-size: 14px;
  letter-spacing: -0.16px;
  text-align: center;
  color: ${COLORS.LABEL_GRAY};
`;

const ProgressBarContainer = styled.div`
  margin-top: 16px;
`;

type InviteMembersLoadingProps = {
  showInvitesProgress?: boolean;
  invitesSent?: number;
  totalInvites?: number;
};

const InviteMembersLoading = ({
  showInvitesProgress = false,
  invitesSent = 0,
  totalInvites = 0,
}: InviteMembersLoadingProps) => {
  return (
    <LoaderContainer>
      <Spinner height={'24px'} />
      <LoaderTitle>
        <Trans>Inviting members</Trans>
      </LoaderTitle>
      <LoaderSubTitle>
        <Trans>Inviting a large number of members can take several minutes</Trans>
      </LoaderSubTitle>

      {showInvitesProgress && (
        <ProgressBarContainer>
          <LoaderSubTitle>{`${invitesSent} / ${totalInvites}`}</LoaderSubTitle>{' '}
        </ProgressBarContainer>
      )}
    </LoaderContainer>
  );
};

export { InviteMembersLoading };
