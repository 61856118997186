import React from 'react';

import isEmpty from 'lodash/isEmpty';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Sticky } from 'react-sticky';
import styled, { css } from 'styled-components';

import { ButtonPrimary, ButtonPrimaryBorder } from '~/components/Button';
import CompanyAvatar from '~/components/CompanyAvatar';
import SvgIcon from '~/components/SvgIcon';
import ExternalInstructions from '~/components/UI/ExternalInstructions';

import ArrowBackSingleIcon from '~/assets/arrow-back-single.svg';

import { COLORS, COLOR_PALETTE } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Box = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  box-sizing: border-box;
  height: 100%;
`;

const CompanyAvatarWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const BoxRight = styled(Box)`
  align-items: flex-start;
  flex: none;
  justify-content: flex-end;
`;

const BoxRightContainer = styled.div`
  align-items: center;
  display: flex;
`;

const Label = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-size: ${(props) => (props.$small ? '16px' : '20px')};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  overflow: hidden;
  margin-left: ${(props) => (props.$small ? '10px' : null)};
  color: ${COLORS.TEXT_PRIMARY_2};
`;

const Description = styled.span`
  margin-top: 8px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${COLORS.TEXT_SECONDARY};
`;

const NavBlock = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 6px 0 0 6px;
  height: 24px;
  a {
    display: flex;
  }
  :hover {
    background: ${COLOR_PALETTE.WHITE};
  }
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;
  flex: 1;
  justify-content: space-between;
  box-sizing: border-box;
`;

const Row = styled.div`
  margin: 20px 0 0;
  padding: ${(props) => (!props.$noPadding ? '0 32px' : null)};
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  background: ${COLORS.BG_PAGE};
  width: ${(props) => props.$width || '1270px'};
  max-width: ${(props) => props.$width || '1270px'};
  ${(props) =>
    props.$isFullWidth && {
      width: '100%',
    }};
`;

const Actions = styled.div`
  display: flex;
  margin-left: 12px;
  ${ButtonPrimary}, ${ButtonPrimaryBorder} {
    height: 48px;
    font-size: 14px;
    margin-left: 8px;
  }
`;

const StickContainer = styled.div`
  z-index: 99;
  transition: 0.2s;
  height: ${(props) => props.$isSticky && (props.$minimal ? '24px' : '68px')};

  ${(props) =>
    props.$isSticky &&
    css`
      &:after {
        content: '';
        background-color: ${COLORS.BG_PAGE};
        position: absolute;
        width: 100%;
        top: 0;
        height: ${(props) => (props.$minimal ? '66px' : '96px')};
        z-index: -1;
        box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.5);
      }
    `};
`;

const HeadingNavigation = React.memo(
  ({
    label,
    description,
    className,
    backPath,
    onBack,
    instructions,
    instructionsUrl,
    actions,
    logoUrl,
    maxWidth,
    smallWidth,
    noPadding,
  }) => {
    const calcWidth = () => {
      if (maxWidth) {
        if (typeof maxWidth === 'number') {
          return maxWidth + 'px';
        }
        return maxWidth;
      }
      if (smallWidth) {
        return '1134px;';
      } // 1070 + padding left/right
      return '1334px'; // 1270 + padding left/right
    };
    const width = calcWidth();

    const ArrowBackIcon = (
      <SvgIcon
        url={ArrowBackSingleIcon}
        width={'11px'}
        height={'16px'}
        isDefaultColor
        defaultColor={'var(--company-color)'}
      />
    );
    return (
      <Wrapper>
        <Row className={className} $width={width} $noPadding={noPadding}>
          <Box>
            {logoUrl && (
              <CompanyAvatarWrapper>
                <CompanyAvatar logoUrl={logoUrl} size={48} />
              </CompanyAvatarWrapper>
            )}
            {(backPath || onBack) && (
              <NavBlock
                onClick={() => {
                  if (onBack) {
                    onBack();
                  }
                }}
              >
                {typeof backPath === 'string' ? (
                  <Link to={backPath}>{ArrowBackIcon}</Link>
                ) : (
                  ArrowBackIcon
                )}
              </NavBlock>
            )}
            <Content>
              <Label>
                {label && <Title $small={typeof backPath === 'string' || onBack}>{label}</Title>}
                {description && <Description>{description}</Description>}
              </Label>
            </Content>
          </Box>
          <BoxRight>
            <BoxRightContainer>
              {instructions && instructionsUrl && (
                <ExternalInstructions
                  instructions={instructions}
                  instructionsUrl={instructionsUrl}
                />
              )}
              {actions && <Actions>{actions}</Actions>}
            </BoxRightContainer>
          </BoxRight>
        </Row>
      </Wrapper>
    );
  },
);

HeadingNavigation.propTypes = {
  onBack: PropTypes.func,
  label: PropTypes.node,
  description: PropTypes.node,
  className: PropTypes.string,
  logoUrl: PropTypes.string,
  instructions: PropTypes.string,
  instructionsUrl: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  smallWidth: PropTypes.bool,
  noPadding: PropTypes.bool,
};

HeadingNavigation.defaultProps = {
  label: '',
  className: '',
  smallWidth: false,
  noPadding: false,
};

HeadingNavigation.displayName = 'HeadingNavigation';

const Navigation = (props) => {
  const minimal = isEmpty(props.description) && isEmpty(props.actions);
  /* added disableHardwareAcceleration to prevent header popup overflow by page content*/
  const content = <HeadingNavigation {...props} />;

  return props.isDisabledSticky ? (
    content
  ) : (
    <Sticky relative disableHardwareAcceleration disableCompensation={false} topOffset={12}>
      {({ style, isSticky }) => {
        return (
          <StickContainer style={style} $isSticky={isSticky} $minimal={minimal}>
            {content}
          </StickContainer>
        );
      }}
    </Sticky>
  );
};

export default React.memo(Navigation);
