import styled, { css } from 'styled-components';

import { Icon } from '~/components/Icon';

import { COLORS } from '~/styles';

export const Text = styled.span<{ hasError?: boolean }>`
  ${(props) =>
    props.hasError
      ? css`
          color: ${COLORS.ACCENT_ERROR};
        `
      : css`
          color: ${COLORS.ICONS_PRIMARY};
        `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px ${COLORS.BORDERS};
  padding-top: 20px;
  padding-bottom: 10px;
`;

export const IconContainer = styled.div<{ starSize: number }>`
  display: flex;
  width: ${(props) => props.starSize}px;
  height: ${(props) => props.starSize}px;
`;

export const FocusAreaRatings = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StarContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const StyledIcon = styled(Icon)<{ isSelected: boolean }>`
  ${(props) => css`
    fill: ${props.isSelected ? COLORS.COMPANY : COLORS.INACTIVE};
    color: ${props.isSelected ? COLORS.COMPANY : COLORS.INACTIVE};
  `}

  :hover {
    fill: ${COLORS.COMPANY};
    color: ${COLORS.COMPANY};

    ${(props) => css`
      opacity: ${props.isSelected ? 1 : 0.5};
    `}
  }

  cursor: pointer;
`;
