import React, { useMemo } from 'react';

import { REPORT_TYPES, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { AreaGraph } from '~/components/Graphs/custom/AreaGraph';
import { ELabelType, ILineGraphPoint } from '~/components/Graphs/types';

import getCurrentCompany from '~/selectors/getCurrentCompany';
import { COLORS, PRIMARY_COLOR } from '~/styles';

import { getLabelText, processGraphData } from '../Reports/engagement/tabs/Overtime';

type IProps = {
  chartData: {
    benchmark: number;
    deviation: number;
    month: string;
    team: number;
    value: number;
    peer: number;
    self: number;
  }[];
  includeCompanyAverage: boolean;
  includeBenchmark: boolean;
  includeTeamAverage: boolean;
  includeSelfReviewAverage: boolean;
  includePeerReviewAverage: boolean;
  viewAs: ROLES;
  reportType?: REPORT_TYPES;
  isTooltipDisabled?: boolean;
  rlc?: number;
};

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  background: ${COLORS.WHITE};
  color: ${COLORS.TEXT_MAIN};
  border-radius: 6px;
  font-size: 14px;
  gap: 4px;
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
`;

function AreaChartContainer({
  chartData,
  includeCompanyAverage,
  includeBenchmark,
  includeTeamAverage,
  includeSelfReviewAverage,
  includePeerReviewAverage,
  reportType,
  isTooltipDisabled,
  rlc,
}: IProps): JSX.Element {
  const { i18n } = useLingui();
  const currentCompany = useSelector(getCurrentCompany);
  const companyColor = currentCompany.color || PRIMARY_COLOR;

  const companyAverage = useMemo(() => {
    return processGraphData(chartData, 'value');
  }, [chartData]);

  const teamAverage = useMemo(() => {
    return processGraphData(chartData, 'team');
  }, [chartData]);

  const benchmark = useMemo(() => {
    return processGraphData(chartData, 'benchmark');
  }, [chartData]);

  const selfReviewAverage = useMemo(() => {
    return processGraphData(chartData, 'self');
  }, [chartData]);

  const peerReviewAverage = useMemo(() => {
    return processGraphData(chartData, 'peer');
  }, [chartData]);

  const timeData: ILineGraphPoint[] = [
    {
      data: companyAverage,
      colors: [COLORS.CAREER, COLORS.WHITE],
      label: getLabelText(reportType, i18n),
      type: ELabelType.FILL,
      key: 'primary',
    },
    ...(includeCompanyAverage
      ? [
          {
            data: companyAverage,
            colors: [COLORS.CONFIRMATION_MODAL_SUCCESS],
            label: `${i18n._(t`Company`)}`,
            type: ELabelType.BORDER,
            key: 'company',
          },
        ]
      : []),
    ...(includeTeamAverage
      ? [
          {
            data: teamAverage,
            colors: [COLORS.PERFORMANCE],
            label: `${i18n._(t`Team`)}`,
            type: ELabelType.BORDER,
            key: 'team',
          },
        ]
      : []),

    ...(includeBenchmark
      ? [
          {
            data: benchmark,
            colors: [COLORS.SUBTEXT],
            label: `${i18n._(t`Benchmark`)}`,
            type: ELabelType.BORDER,
            key: 'benchmark',
          },
        ]
      : []),

    ...(includePeerReviewAverage
      ? [
          {
            data: peerReviewAverage,
            colors: [COLORS.PEER_REVIEW_LEGEND],
            label: `${i18n._(t`Peer Review`)}`,
            type: ELabelType.BORDER,
            key: 'peer_review',
          },
        ]
      : []),
    ...(includeSelfReviewAverage
      ? [
          {
            data: selfReviewAverage,
            colors: [COLORS.REVIEW_SELF],
            label: `${i18n._(t`Self Review`)}`,
            type: ELabelType.BORDER,
            key: 'self_review',
          },
        ]
      : []),
  ];

  return (
    <Container>
      <AreaGraph
        timeData={timeData}
        stepSize={20}
        colorRatio={1}
        tooltipTitle={
          reportType === REPORT_TYPES.ENGAGEMENT
            ? `${i18n._(t`Average engagement`)}`
            : `${i18n._(t`Average performance`)}`
        }
        bgColor={companyColor}
        isTooltipDisabled={isTooltipDisabled}
        ratingLabelsCount={rlc}
      />
    </Container>
  );
}

export { AreaChartContainer };
