import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import GoalsBlock from '~/components/GoalsBlock';
import Divider from '~/components/UI/Divider';
import { TableHeader } from '~/pages/Conversations/components/TableHeader';

import { COLORS } from '~/styles';

import { Wrapper } from '../../design';

import type { IGoalsProps } from '../../types';

const StyledDivider = styled(Divider)`
  background-color: ${COLORS.BORDERS};
`;

const Goals = ({ isPublicPage, forUser, currentUserId }: IGoalsProps) => {
  const { i18n } = useLingui();
  return (
    <Wrapper>
      <TableHeader headerTitle={i18n._(t`Goals`)} />
      <StyledDivider />
      <GoalsBlock forUser={forUser} currentUserId={currentUserId} isPublicPage={isPublicPage} />
    </Wrapper>
  );
};

export { Goals };
