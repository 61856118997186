import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, COMPANY_DELETE_REQUEST_STATUSES } from '@learned/constants';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';
import OverviewHeading from '~/components/OverviewHeading';
import PaginationBar from '~/components/PaginationBar';
import { SearchField } from '~/components/Text';
import Tooltip from '~/components/Tooltip';
import ActionsContainer from '~/components/UI/ActionsContainer';
import Divider from '~/components/UI/Divider';
import Table from '~/components/UI/Table';
import BaseLayout from '~/layouts/BaseLayout';

import RequestRow from './RequestRow';

import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { getCompanyDeleteRequests } from '~/services/companyDeleteRequests';
import { COLOR_PALETTE, COLORS } from '~/styles';

const ActionsContainerWrapper = styled(ActionsContainer)`
  height: 48px;
  justify-content: space-between;
  align-items: center;
  border: unset;
  display: flex;
  flex-direction: row;
`;

const Wrapper = styled.div`
  border-radius: 6px;
  border: solid 1px ${COLOR_PALETTE.GRAY_MIDDLE};
`;

const StyledSearch = styled(SearchField)`
  height: 32px;
  width: 248px;
  & > input {
    border-radius: 6px;
    font-size: 14px;
    height: 32px;
  }
`;

const StyledTable = styled(Table)`
  background-color: white;
`;

const CheckBoxBlock = styled.div`
  display: flex;
  align-items: center;
`;

const CheckBoxText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.TEXT_BLACK};
  margin-left: 8px;
`;

const PAGE_SIZE = 20;

const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };

const SuperAdminRequests = ({ i18n }) => {
  const $loading = useBoolState(true);
  const [deleteRequests, setDeleteRequests] = useState([]);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);
  const $isProcessedRequests = useBoolState(false);

  const fetch = async (isMounted = true) => {
    try {
      const response = await getCompanyDeleteRequests({
        filters: {
          search,
          statuses: $isProcessedRequests.value ? [] : [COMPANY_DELETE_REQUEST_STATUSES.REQUESTED],
        },
        options: {
          skip: pagination.skip,
          limit: pagination.limit,
        },
      });

      if (isMounted) {
        setDeleteRequests(response.data[API_RETURN_FIELDS.COMPANY_DELETE_REQUESTS]);
        setTotal(response.data[API_RETURN_FIELDS.TOTAL]);
      }
    } finally {
      $loading.off();
    }
  };

  // Executes on
  // - init fetch
  // - re-fetch on page change (pagination)
  // - search change
  // - only requested change
  useEffect(() => {
    let isMounted = true;

    fetch(isMounted);

    return () => (isMounted = true);

    // eslint-disable-next-line
  }, [pagination, debouncedSearch, $isProcessedRequests.value]);

  const cols = [
    {
      title: i18n._(t`Company`),
    },
    {
      title: i18n._(t`Created_By`),
      width: '166px',
    },
    {
      title: i18n._(t`Created_Date`),
      width: '166px',
    },
    {
      title: i18n._(t`Processed_By`),
      width: '166px',
    },
    {
      title: i18n._(t`Processed_Date`),
      width: '166px',
    },
    {
      title: i18n._(t`Status`),
      width: '100px',
    },
  ];

  const onPageChangeClick = ({ skip, limit, index }) => {
    setPagination({ skip, limit, index });
  };

  const onToggleCheckbox = () => {
    $isProcessedRequests.value ? $isProcessedRequests.off() : $isProcessedRequests.on();

    // also change pagination to default values;
    onPageChangeClick(DEFAULT_PAGINATION);
  };

  return (
    <div>
      <OverviewHeading
        onBack={history.goBack}
        title={i18n._(t`Delete company requests`)}
        description={i18n._(t`Manage requests to delete company`)}
      />
      <BaseLayout>
        <Wrapper>
          <ActionsContainerWrapper noBottomBorder>
            <StyledSearch
              placeholder={i18n._(t`Search`)}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <CheckBoxBlock onClick={onToggleCheckbox}>
              <CheckBox checked={$isProcessedRequests.value} size={24} />
              <Tooltip tooltip={i18n._(t`Display also requests with accepted/rejected statuses`)}>
                <CheckBoxText>{i18n._(t`Display handled requests`)}</CheckBoxText>
              </Tooltip>
            </CheckBoxBlock>
          </ActionsContainerWrapper>
          <Divider />
          <StyledTable
            loading={$loading.value}
            cols={cols}
            items={deleteRequests}
            renderRow={(deleteRequest) => (
              <RequestRow deleteRequest={deleteRequest} forceFetch={fetch} />
            )}
          />

          {total > PAGE_SIZE && (
            <>
              <Divider />
              <PaginationBar
                pagination={pagination}
                changePagination={onPageChangeClick}
                count={total}
                noBorder
                showCount
              />
            </>
          )}
        </Wrapper>
      </BaseLayout>
    </div>
  );
};

export default withI18n()(SuperAdminRequests);
