import React, { useEffect } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button } from '~/components/Buttons/Button';
import { ButtonSize, ButtonVariant } from '~/components/Buttons/types';
import DashboardHeader from '~/components/DashboardHeader';
import { MultiLangComponent } from '~/components/Dropdown/MultiLangualDropdown';
import { ICONS } from '~/components/Icon';
import { LastSaved } from '~/components/LastSaved';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { SubHeader } from '~/pages/ReviewThemeView/components/SubHeader';
import { DASHBOARD_TYPE } from '~/pages/ReviewThemeView/constants';

import { LastSavedStatus } from '~/constants/lastSaved';
import useBoolState from '~/hooks/useBoolState';
import { useFromQuery } from '~/hooks/useFromQuery';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';

import { ActionItemBlock, ActionsWrap, IconButtonContainer } from '../design';

interface IProps {
  title: string;
  isDraft: boolean;
  lastSavedTime?: Date;
  lastSavedStatus?: LastSavedStatus;
  lastSavedErrorMessage?: string;
  isLoading: boolean;
  languageState: ILanguageStateReturn;
  onSave: (e?: React.BaseSyntheticEvent) => Promise<void>;
  onPublish: (onSave?: boolean) => Promise<void>;
  onDelete?(): void;
}

const Header = ({
  title,
  isDraft,
  lastSavedTime,
  lastSavedStatus,
  lastSavedErrorMessage,
  isLoading,
  languageState,
  onSave,
  onPublish,
  onDelete,
}: IProps) => {
  const $showDraftBanner = useBoolState();
  const { i18n } = useLingui();
  const { goBack } = useFromQuery({ includeHash: true });

  const onDeleteClick = () => {
    onDelete?.();
  };

  useEffect(() => {
    $showDraftBanner.set(isDraft);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDraft]);

  const handleSaveClick = (event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    event.preventDefault();
    isDraft ? onSave() : onPublish(true);
  };

  return (
    <DashboardHeader
      title={title}
      onBack={goBack}
      actions={
        <ActionsWrap>
          <LastSaved
            time={lastSavedTime}
            status={lastSavedStatus}
            errorMessage={lastSavedErrorMessage}
          />
          <ActionItemBlock>
            <MultiLangComponent languageState={languageState} />
          </ActionItemBlock>
          <ActionItemBlock>
            <IconButtonContainer>
              <Tooltip size={TOOLTIP_SIZES.BIG} tooltip={i18n._(t`Delete theme`)} delay={[500, 0]}>
                <span>
                  <Button
                    className="delete-btn"
                    label=""
                    icon={ICONS.DELETE_BIN}
                    size={ButtonSize.MEDIUM}
                    variant={ButtonVariant.ICON}
                    isLoading={isLoading}
                    onClick={onDeleteClick}
                  />
                </span>
              </Tooltip>
              <Tooltip
                size={TOOLTIP_SIZES.BIG}
                disabled={false}
                tooltip={isDraft ? i18n._(t`Save as draft`) : i18n._(t`Save`)}
              >
                <span>
                  <Button
                    label=""
                    icon={ICONS.SAVE}
                    size={ButtonSize.MEDIUM}
                    variant={ButtonVariant.ICON}
                    isLoading={isLoading}
                    onClick={handleSaveClick}
                  />
                </span>
              </Tooltip>
            </IconButtonContainer>
          </ActionItemBlock>
          {isDraft && (
            <ActionItemBlock>
              <Button
                label={i18n._(t`Publish`)}
                size={ButtonSize.MEDIUM}
                variant={ButtonVariant.PRIMARY}
                isLoading={isLoading}
                onClick={() => onPublish()}
              />
            </ActionItemBlock>
          )}
        </ActionsWrap>
      }
      subHeader={$showDraftBanner.value && <SubHeader dashboardType={DASHBOARD_TYPE.THEME} />}
    />
  );
};

export { Header };
