import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';

import Item from './Item';

import SkillsIcon from '~/assets/mdi-tag-multiple.svg';

import { COLORS, COLOR_PALETTE } from '~/styles';

const SectionHeader = styled.div`
  padding-bottom: 11px;
  display: flex;
  justify-content: space-between;
`;

const SectionTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.TEXT_PRIMARY};
`;

const SectionSkills = styled.div`
  transition: background-color 0.2s ease;
  background-color: ${(props) => (props.$isDraggingOver ? COLORS.BG_PAGE : COLOR_PALETTE.WHITE)};
  border-radius: 5px;
`;

const VisibleColumnText = styled.div`
  margin-right: 71px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.TEXT_SECONDARY};
`;

const Wrapper = styled.div`
  margin-bottom: 26px;
`;
export default function Section({
  section,
  onItemDelete,
  onItemDrag,
  onChangeHiddenSkills,
  skillsHidden,
  manualAddedSkills,
}) {
  const { i18n } = useLingui();
  return (
    <Wrapper>
      <SectionHeader>
        <SectionTitle>{section.title}</SectionTitle>
        {!isEmpty(section.skills) && (
          <VisibleColumnText>
            <Trans>Visible</Trans>
          </VisibleColumnText>
        )}
      </SectionHeader>
      <div>
        {!isEmpty(section.skills) ? (
          <DragDropContext onDragEnd={onItemDrag}>
            <Droppable droppableId={section.id} type="SKILLS">
              {(provided, snapshot) => (
                <SectionSkills
                  ref={provided.innerRef}
                  $isDraggingOver={snapshot.isDraggingOver}
                  {...provided.droppableProps}
                >
                  {section.skills.map((skill, index) => (
                    <Item
                      key={skill.id}
                      skill={skill}
                      index={index}
                      onDelete={onItemDelete}
                      onChangeHiddenSkills={onChangeHiddenSkills}
                      isShowSkill={!skillsHidden.includes(skill.id)}
                      isManuallyAddedSkill={manualAddedSkills.includes(skill.id)}
                      {...provided.dragHandleProps}
                    />
                  ))}
                  {provided.placeholder}
                </SectionSkills>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <Placeholder
            title={i18n._(t`No skills`)}
            subTitle={i18n._(t`There are no ${section.title}`)}
            Icon={() => (
              <SvgIcon
                url={SkillsIcon}
                width="40px"
                height="40px"
                isDefaultColor
                defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
              />
            )}
          />
        )}
      </div>
    </Wrapper>
  );
}

Section.propTypes = {
  section: PropTypes.object,
  onItemDelete: PropTypes.func,
  skillsHidden: PropTypes.array,
  onChangeHiddenSkills: PropTypes.func,
  manualAddedSkills: PropTypes.array,
};
