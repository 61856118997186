import { RATING_STATUSES } from '@learned/constants';
import filter from 'lodash/filter';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import moment from 'moment';

import { RATING_TYPES } from '~/constants';
import { average } from '~/utils/math';

function groupRatingsBySkill(userRatings) {
  const items = userRatings.filter((item) => item.skill);

  // group by skill type
  return groupBy(items, (item) => item.skill);
}

function groupRatingsByQuestion(userRatings) {
  const items = userRatings.filter((item) => item.question);

  // group by question
  return groupBy(items, (item) => item.question);
}

function groupRatingsByGoal(userRatings) {
  const items = userRatings.filter((item) => item.goal);

  // group by skill type
  return groupBy(items, (item) => item.goal);
}

function filterRates(rates = []) {
  return rates.filter(
    (rate) =>
      !rate.isSkipped &&
      (rate.status === RATING_STATUSES.RATED || rate.status === RATING_STATUSES.CALIBRATE),
  );
}

function getAverageRating(rates) {
  return average(rates.map((r) => r.rate));
}

function getSelfRatings(ratings = []) {
  return filterRates(filter(ratings, (rating) => rating.type === RATING_TYPES.SELF_RATING));
}

function getPeerRations(ratings = []) {
  return filterRates(
    filter(
      ratings,
      (rating) =>
        rating.type === RATING_TYPES.OTHER_RATING || rating.type === RATING_TYPES.OUTSIDE_RATING,
    ),
  );
}

function getCoachRations(ratings = []) {
  return filterRates(filter(ratings, (rating) => rating.type === RATING_TYPES.COACH_RATING));
}

function getAverageSkillsList(ratings) {
  return map(groupRatingsBySkill(ratings), (rates, skillId) => {
    return {
      id: skillId,
      avg: getAverageRating(rates),
      scale: rates[0].scale,
    };
  });
}
function getAverageCustomList(ratings) {
  return map(groupRatingsByQuestion(ratings), (rates, questionId) => ({
    id: questionId,
    avg: getAverageRating(rates),
  }));
}
function getAverageGoalList(ratings) {
  return map(groupRatingsByGoal(ratings), (rates, goalId) => ({
    id: goalId,
    avg: getAverageRating(rates),
  }));
}
function getExpectedLevels(skillLevels) {
  return map(skillLevels, (level, skillId) => ({
    id: skillId,
    avg: level,
  }));
}

function filterLastRates(ratings) {
  const grouped = groupBy(
    ratings,
    (item) => (item.skill || item.goal || item.question) + item.user + item.email,
  );
  return map(grouped, (g) => {
    g.sort((a, b) => {
      const dateA = get(a, 'meta.lastModifiedDate', get(a, 'meta.createdDate'));
      const dateB = get(b, 'meta.lastModifiedDate', get(b, 'meta.createdDate'));
      if (!dateA) {
        if (!dateB) {
          return 0;
        }
        return 1;
      }
      return moment(dateA).diff(dateB);
    });

    return g[g.length - 1];
  });
}

export {
  getAverageRating,
  groupRatingsBySkill,
  getSelfRatings,
  getPeerRations,
  getCoachRations,
  getAverageSkillsList,
  getAverageCustomList,
  getAverageGoalList,
  getExpectedLevels,
  filterLastRates,
};
