import React from 'react';

import { SURVEY_STATUS, SURVEY_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';
import styled from 'styled-components';

import Button from '~/components/Button';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { IconBlock } from '~/components/IconBlock';
import SvgIcon from '~/components/SvgIcon';

import { StepSection } from './StepSection';

import GraphIcon from '~/assets/graph.svg';
import IncognitoIcon from '~/assets/incognito.svg';

import {
  SURVEY_FREQUENCY_WITH_NAMES,
  SURVEY_QUESTIONS_PER_PARTICIPANT_WITH_NAMES,
} from '~/constants/survey';
import { COLORS } from '~/styles';

import type { ISurvey } from '@learned/types';

const Wrapper = styled.div``;

const Block = styled.div`
  margin-bottom: 26px;
`;

const Row = styled.div`
  display: flex;
`;

const RowColumn = styled.div`
  width: 100%;
`;

const RowColumnFitContent = styled.div`
  width: fit-content;
  display: inline-flex;
  align-items: center;
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.BLACK};
`;

const NotificationsList = styled.ul`
  padding: 0 0 0 16px;
  margin: 0;
  color: ${COLORS.BLACK};
`;

interface ISectionSettingsProps {
  survey: ISurvey;
  onShare: (isShared: boolean) => void;
}

const SectionSettings = ({ survey, onShare }: ISectionSettingsProps) => {
  const { i18n } = useLingui();
  const type = survey.type;
  const startDate = survey.dates.startDate;
  const endDate = survey.dates.endDate;
  const isEndDate = survey.type === SURVEY_TYPE.PULSE && survey.dates.isEndDate;
  const frequency = survey?.pulse?.frequency;
  const questionsPerParticipant = survey?.pulse?.questionsPerParticipant;
  const isOnStartDate = survey.notifications.isOnStartDate;
  const isOn2DaysBeforeEndDate =
    survey.type === SURVEY_TYPE.TRADITIONAL && survey.notifications.isOn2DaysBeforeEndDate;
  const isOnEndDate = survey.notifications.isOnEndDate;
  const isAnonymous = survey.anonymity.isAnonymous;
  const reportingThreshold = survey.anonymity.reportingThreshold;
  const isShared = survey.isShared;

  return (
    <StepSection title={i18n._(t`Settings`)}>
      <Wrapper>
        {/* Dates*/}
        <Block>
          <SubTitle>{i18n._(t`Dates`)}</SubTitle>
          <Row>
            <RowColumn>
              <IconBlock
                label={i18n._(t`Start Date`)}
                value={moment(startDate).format('DD-MM-YYYY')}
                icon={
                  <Icon icon={ICONS.AGENDA} color={COLORS.ICONS_PRIMARY} size={ICON_SIZES.LARGE} />
                }
              />
            </RowColumn>
            <RowColumn>
              {(type === SURVEY_TYPE.TRADITIONAL || isEndDate) && (
                <IconBlock
                  label={i18n._(t`End Date`)}
                  value={moment(endDate).format('DD-MM-YYYY')}
                  icon={
                    <Icon
                      icon={ICONS.AGENDA}
                      color={COLORS.ICONS_PRIMARY}
                      size={ICON_SIZES.LARGE}
                    />
                  }
                />
              )}
            </RowColumn>
          </Row>
        </Block>

        {/* Pulse*/}
        {type === SURVEY_TYPE.PULSE && (
          <Block>
            <SubTitle>{i18n._(t`Pulse`)}</SubTitle>
            <Row>
              <RowColumn>
                <IconBlock
                  label={i18n._(t`Repeat frequency of pulse`)}
                  value={frequency && SURVEY_FREQUENCY_WITH_NAMES[frequency]?.name(i18n)}
                  icon={
                    <Icon
                      icon={ICONS.PULSE_SURVEY}
                      color={COLORS.ICONS_PRIMARY}
                      size={ICON_SIZES.LARGE}
                    />
                  }
                />
              </RowColumn>
              <RowColumn>
                <IconBlock
                  label={i18n._(t`Amount of questions per participants`)}
                  value={
                    questionsPerParticipant &&
                    SURVEY_QUESTIONS_PER_PARTICIPANT_WITH_NAMES[questionsPerParticipant].name(i18n)
                  }
                  icon={
                    <Icon
                      icon={ICONS.PULSE_SURVEY}
                      color={COLORS.ICONS_PRIMARY}
                      size={ICON_SIZES.LARGE}
                    />
                  }
                />
              </RowColumn>
            </Row>
          </Block>
        )}

        {/* Notifications*/}
        <Block>
          <SubTitle>{i18n._(t`Notifications`)}</SubTitle>
          <NotificationsList>
            {isOnStartDate && <li>{i18n._(t`On start date`)}</li>}
            {isOn2DaysBeforeEndDate && <li>{i18n._(t`2 days before deadline`)}</li>}
            {isOnEndDate && <li>{i18n._(t`On the day of the deadline`)}</li>}
          </NotificationsList>
        </Block>

        {/* Anonymity*/}
        <Block>
          <SubTitle>{i18n._(t`Anonymity`)}</SubTitle>
          <Row>
            <RowColumn>
              <IconBlock
                label={i18n._(t`Comments are anonymously`)}
                value={isAnonymous ? i18n._(t`on`).toUpperCase() : i18n._(t`off`).toUpperCase()}
                icon={
                  <SvgIcon
                    defaultColor={COLORS.DARK_GRAY}
                    isDefaultColor
                    width="22px"
                    height="22px"
                    url={IncognitoIcon}
                  />
                }
              />
            </RowColumn>
            <RowColumn>
              {isAnonymous && (
                <IconBlock
                  label={i18n._(t`Anonymously threshold`)}
                  value={`From ${reportingThreshold} comments`}
                  icon={
                    <SvgIcon
                      defaultColor={COLORS.DARK_GRAY}
                      isDefaultColor
                      width="22px"
                      height="22px"
                      url={IncognitoIcon}
                    />
                  }
                />
              )}
            </RowColumn>
          </Row>
        </Block>

        {/* Reporting*/}
        <Block>
          <SubTitle>{i18n._(t`Reporting`)}</SubTitle>
          <Row>
            <RowColumn>
              <IconBlock
                label={i18n._(t`Results are shared with the participants`)}
                value={isShared ? i18n._(t`Yes`) : i18n._(t`No`)}
                icon={
                  <SvgIcon
                    defaultColor={COLORS.DARK_GRAY}
                    isDefaultColor
                    width="22px"
                    height="22px"
                    url={GraphIcon}
                  />
                }
              />
            </RowColumn>
            <RowColumnFitContent>
              <Button
                disabled={
                  survey.status !== SURVEY_STATUS.ACTIVE && survey.status !== SURVEY_STATUS.ARCHIVED
                }
                type={'shadow'}
                label={isShared ? i18n._(t`Hide all results`) : i18n._(t`Share results`)}
                styles={{ fontSize: '12px', whiteSpace: 'nowrap' }}
                onClick={() => {
                  onShare(!isShared);
                }}
              />
            </RowColumnFitContent>
          </Row>
        </Block>
      </Wrapper>
    </StepSection>
  );
};

export { SectionSettings };
