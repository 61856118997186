import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch, useSelector } from 'react-redux';

import Editor from '~/components/Editor';

import { getDescription } from '~/selectors/currentGoal';
import { setCurrentGoalDescription } from '~/store/currentGoal/actions';

import { ItemContainer, ItemLabel, ItemRow, ItemSubLabel, ItemLabelRow } from '../../stepsDesign';

const DescriptionSection = () => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const description = useSelector(getDescription);

  const onChange = (description: string) => {
    // @ts-ignore
    dispatch(setCurrentGoalDescription(description));
  };

  return (
    <ItemRow marginTop="16px">
      <ItemContainer width="540px">
        <ItemLabelRow>
          <ItemLabel>
            <Trans>Description</Trans>
          </ItemLabel>
          <ItemSubLabel>
            <Trans>(optional)</Trans>
          </ItemSubLabel>
        </ItemLabelRow>
        <Editor
          placeholder={i18n._(
            t`Why do you want to work on this goal? Describe what result you expect to achieve.`,
          )}
          value={description}
          onChange={onChange}
          compact={true}
        />
      </ItemContainer>
    </ItemRow>
  );
};

export { DescriptionSection };
