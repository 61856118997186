import React, { useState, useEffect } from 'react';

import { IUser } from '@learned/types';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import { PrimaryButton, InlineButton } from '~/components/Buttons';
import Modal from '~/components/Modal';
import PaginationBar from '~/components/PaginationBar';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { Table } from '~/components/Table';
import { TH } from '~/components/Table/design';
import { TablePlaceholder } from '~/components/TablePlaceholder';
import { TableHeader } from '~/pages/Surveys/TableHeader';
import { RowWrapper } from '~/pages/SurveyUpdate/components/StepParticipants/SectionTeams';

import { IColumnTable } from '~/@types/table';
import { usePagination } from '~/hooks/usePagination';
import { getTeams } from '~/services/teams';
import { COLORS } from '~/styles';

import type { ITeam } from '@learned/types';

const FooterActions = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  border: 1px solid #f3f7fd;
  border-radius: 10px;
  margin: 32px;
`;

const TeamName = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  color: ${COLORS.BLACK};
`;

const StyledTable = styled(Table)`
  ${TH} {
    max-width: 20px;
    padding-right: 8px;
  }
`;

const TEAM_COLUMNS: IColumnTable<IUser>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Teams`),
    minWidth: '300px',
    accessor: 'name',
    renderCell: (item) => {
      return (
        <RowWrapper>
          {/* @ts-ignore */}
          <TeamName>{item.name}</TeamName>
        </RowWrapper>
      );
    },
  },
];

const initialFilters = {
  search: '',
};

type IFilter = {
  search: string;
};

interface IAddTeamsModal {
  onClose: () => void;
  onSubmit: (teams: ITeam[]) => void;
  itemsToHide?: string[];
}
const AddTeamsModal = ({ onClose, onSubmit, itemsToHide }: IAddTeamsModal) => {
  const [selectedItems, setSelectedItems] = useState<ITeam[]>([]);
  const [items, setItems] = useState<ITeam[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentFilters, setCurrentFilters] = useState<IFilter>(initialFilters);
  const { pagination, changePagination, changePageSize, resetPagination } = usePagination(10);

  const { i18n } = useLingui();
  const submitLabel = `${i18n._(t`Add`)} ${selectedItems.length || 0} ${i18n._(t`team(s)`)}`;

  useEffect(() => {
    let isMounted = true;
    const fetch = async () => {
      try {
        setIsLoading(true);
        const response = await getTeams();

        if (isMounted) {
          setItems(Object.values(response));
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetch();

    return () => {
      isMounted = false;
    };
  }, []);

  let itemsSearch = items;

  // hide already selected items
  if (itemsToHide && itemsToHide.length > 0) {
    itemsSearch = itemsSearch.filter((item) => !itemsToHide.includes(item.id));
  }

  if (currentFilters.search) {
    itemsSearch = itemsSearch.filter((item) =>
      item.name.toLowerCase().includes(currentFilters.search.toLowerCase()),
    );
  }

  const itemsFiltered = _.chain(itemsSearch)
    .orderBy(['name'], ['asc'])
    .drop(pagination.skip)
    .take(pagination.limit)
    .value();

  const isAllSelected = itemsSearch.every((item) => {
    return selectedItems.map((item) => item.id).includes(item.id);
  });
  const onSelectAll = () => {
    const selectedItems = isAllSelected ? [] : itemsSearch;
    setSelectedItems(selectedItems);
  };

  const onSelectItem = (selectedItem: ITeam) => {
    const isSelected = selectedItems.map((item) => item.id).includes(selectedItem.id);
    setSelectedItems(
      isSelected
        ? selectedItems.filter((item) => item.id !== selectedItem.id)
        : [...selectedItems, selectedItem],
    );
  };

  const multiSelect = {
    checkedCount: selectedItems.length,
    onCheckAll: onSelectAll,
    onSelectItem: (item: ITeam) => onSelectItem(item),
    isItemChecked: (item: ITeam) => selectedItems.map((item) => item.id).includes(item.id),
    isAllChecked: isAllSelected,
  };

  const filters = {
    search: currentFilters.search,
    setSearch: (value: string) => {
      setCurrentFilters({ ...currentFilters, search: value });
      resetPagination();
    },
  };

  return (
    <Modal
      title={i18n._(t`Selecting teams`)}
      onClose={onClose}
      width={800}
      minWidth={800}
      minHeight="600px"
      contentStyles={{
        background: COLORS.WHITE,
        height: '100%',
        padding: '0',
      }}
      hideFooter={false}
      footerRight={
        <FooterActions>
          <InlineButton label={i18n._(t`Cancel`)} onClick={onClose} />
          <PrimaryButton
            label={submitLabel}
            onClick={() => {
              onSubmit(selectedItems);
              onClose();
            }}
            isDisabled={selectedItems.length === 0}
          />
        </FooterActions>
      }
    >
      <Wrapper>
        <TableHeader filters={filters} />
        <ShowSpinnerIfLoading loading={isLoading}>
          <StyledTable
            data={itemsFiltered}
            columns={TEAM_COLUMNS}
            showMultiSelect
            multiSelect={multiSelect}
            showMenu
            showDraftStatus
          />
        </ShowSpinnerIfLoading>
        {isEmpty(itemsFiltered) && (
          /* @ts-ignore */
          <TablePlaceholder
            isFiltered={!!currentFilters.search.length}
            noResultText={i18n._(t`No teams found`)}
            emptyStateText={i18n._(t`No teams yet… Let’s create one!`)}
          />
        )}
        <PaginationBar
          pagination={pagination}
          changePagination={changePagination}
          changePageSize={changePageSize}
          count={itemsSearch.length}
          noShadow
          noBorder
          noTopBorder
          showCount
        />
      </Wrapper>
    </Modal>
  );
};

export { AddTeamsModal };
