import React from 'react';

import styled, { css } from 'styled-components';

import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import { COLORS } from '~/styles';

import type { IMultiLangString } from '@learned/types';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  position: relative;
  height: fit-content;

  & .selected {
    background: ${COLORS.COMPANY};
  }
`;

const Content = styled.div<{ $canAnswer?: boolean }>`
  display: flex;
  height: 50px;
  width: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: ${COLORS.INACTIVE};

  ${({ $canAnswer }) => {
    return (
      $canAnswer &&
      css`
        &:hover {
          cursor: pointer;
          background: color-mix(in srgb, ${COLORS.COMPANY}, transparent 60%);
        }
      `
    );
  }}
`;

const OpenCircle = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  padding: 5px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: solid 1px ${COLORS.WHITE};
`;

const FilledCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${COLORS.WHITE};
`;

const LabelWrapper = styled.div`
  position: absolute;
  text-align: center;
  left: -50px;
  width: 150px;
`;

const ScoreLabel = styled.span`
  font-size: 12px;
  color: ${COLORS.COMPANY};
`;

const Label = styled.span`
  display: inline-block;
  font-size: 12px;
  width: 150px;
  color: ${COLORS.COMPANY};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface IProps {
  canAnswer?: boolean;
  onChange?: () => void;
  isSelected?: boolean;
  index: number;
  isExpected?: boolean;
  option: {
    label: IMultiLangString;
  };
  options: {
    label: IMultiLangString;
  }[];
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
}

export const RatingLabelPlaceholder = ({
  canAnswer,
  onChange,
  isSelected,
  index,
  option,
  options,
  useMultiLangString,
}: IProps) => {
  const getMultiLangString = useMultiLangString();
  return (
    <Container>
      <Tooltip
        disabled={isSelected}
        size={TOOLTIP_SIZES.DEFAULT}
        tooltip={`${index}/${options.length} - ${getMultiLangString(option?.label || {})}`}
      >
        <Wrapper>
          <Content
            className={isSelected ? 'selected' : ''}
            onClick={() => onChange?.()}
            $canAnswer={canAnswer}
          >
            {isSelected && (
              <OpenCircle>
                <FilledCircle />
              </OpenCircle>
            )}
          </Content>
          {isSelected && (
            <LabelWrapper>
              <ScoreLabel>{`${index}/${options.length}`}</ScoreLabel>
              <Label>{`${getMultiLangString(option?.label || {})} `}</Label>
            </LabelWrapper>
          )}
        </Wrapper>
      </Tooltip>
    </Container>
  );
};
