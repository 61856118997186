import styled from 'styled-components';

import { Icon } from '~/components/Icon';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLORS.WHITE};
  border-radius: 6px;
  font-size: 14px;
  min-height: 200px;
  height: 100%;
`;

const DroppableContainer = styled.div``;

const DraggableContainer = styled.div<{ isHidden: boolean }>`
  opacity: ${({ isHidden }) => (isHidden ? '0.5' : '1')};
  position: relative;
  z-index: 1;
  flex: none;
  height: 100%;
`;

const DraggableIcon = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  left: 10px;
  display: none;
  cursor: grab;
  & > svg {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
`;

const Header = styled.div`
  padding: 24px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${COLORS.TEXT_MAIN};
  font-size: 14px;
  box-sizing: border-box;
  position: relative;
  &:hover ${DraggableIcon} {
    display: block;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  & > span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30ch;
  }
`;

const ForwardIcon = styled(Icon)`
  color: ${COLORS.COMPANY} !important;
  transform: rotate(180deg);
`;

const GrabIcon = styled(Icon)`
  color: ${COLORS.COMPANY} !important;
`;

const Navigator = styled.div`
  font-size: 12px;
  color: ${COLORS.SUBTEXT};
  cursor: pointer;
  border-radius: 20px;
  padding: 8px 12px 8px 16px;
  display: flex;
  align-items: center;
  gap: 2px;
  flex: none;
  &:hover {
    background: ${COLORS.HOVER};
    color: ${COLORS.COMPANY};
  }
  &:hover ${ForwardIcon} {
    color: ${COLORS.SUBTEXT} !important;
  }
`;

const NavigatorContainer = styled.div`
  display: flex;
  flex: auto;
  justify-content: flex-end;
  position: relative;
`;

const CardBody = styled.div`
  padding: 0 32px 24px;
  box-sizing: border-box;
  flex: auto;
`;

const Footer = styled.div`
  padding: 0 32px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
`;

const FooterPlaceHolder = styled.div`
  height: 24px;
`;

const ItemCount = styled.div`
  color: ${COLORS.SUBTEXT};
`;

const DimensionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  flex: auto;
`;

const Dimension = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const ColorCircle = styled.div<{ color: string }>`
  background: ${(props) => props.color};
  border-radius: 50%;
  width: 12px;
  height: 12px;
  flex: none;
`;

const ContextMenuWrapper = styled.div``;

const ContextPlaceHolder = styled.div`
  width: 32px;
  position: absolute;
  right: 5px;
  top: 0;
  transform: translateX(100%);
  z-index: 999;
`;

const ContextOptionWrapper = styled.div`
  & svg {
    color: ${COLORS.TEXT_MAIN} !important;
  }
`;

const DroppableWrapper = styled.div``;

export {
  DroppableWrapper,
  DroppableContainer,
  DraggableIcon,
  ContextPlaceHolder,
  Wrapper,
  Header,
  Title,
  Navigator,
  CardBody,
  ForwardIcon,
  Footer,
  ItemCount,
  DimensionsContainer,
  Dimension,
  ColorCircle,
  FooterPlaceHolder,
  ContextMenuWrapper,
  ContextOptionWrapper,
  NavigatorContainer,
  GrabIcon,
  DraggableContainer,
};
