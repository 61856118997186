import React, { forwardRef } from 'react';

import Tippy from '@tippyjs/react';
import styled from 'styled-components';

import { COLORS, COLOR_PALETTE } from '~/styles';

const Tip = styled(Tippy)`
  background: ${COLOR_PALETTE.WHITE};
  box-shadow: ${(props) =>
    props.$boxShadow ? props.$boxShadow : '2px 2px 2px -1px rgba(14, 26, 45, 0.3)'};
  border: ${(props) => (props.$noBorder ? 'none' : `solid 1px ${COLORS.BG_PAGE}`)};
  color: #000;
  border-radius: ${(props) => props.$border}px;
  font-size: 14px;
  font-weight: normal;
  min-width: ${(props) => props.$minWidth}px;
  overflow: hidden;
  .tippy-content {
    padding: 0;
  }
`;

const settings = {
  modifiers: [
    {
      name: 'computeStyles',
      options: {
        gpuAcceleration: false,
      },
    },
  ],
};

const Popover = forwardRef(
  (
    {
      children,
      minWidth,
      maxWidth,
      placement = 'top',
      content,
      disabled,
      onHide = () => {},
      border,
      boxShadow,
      noBorder,
      ...props
    },
    ref,
  ) => (
    <Tip
      maxWidth={maxWidth}
      content={content}
      $border={border}
      onHide={onHide}
      placement={placement}
      interactive
      arrow={false}
      ref={ref}
      disabled={disabled}
      popperOptions={settings}
      zIndex={1001} // because modal has z-index: 1000
      $boxShadow={boxShadow}
      $noBorder={noBorder}
      $minWidth={minWidth}
      {...props}
    >
      {children}
    </Tip>
  ),
);

Popover.displayName = 'Popover';

Popover.defaultProps = {
  border: 3,
  disabled: false,
};

export default Popover;
