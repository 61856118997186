import React, { useEffect, useRef, useState, useContext } from 'react';

import { LUCA_INSIGHT_STATUS, REPORT_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';

import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import useBoolState from '~/hooks/useBoolState';
import getAppThemeColor from '~/selectors/getAppThemeColor';
import * as reportsService from '~/services/reports';
import { COLORS } from '~/styles';

const initialInsight = {
  id: null,
  status: LUCA_INSIGHT_STATUS.PENDING,
  message: '',
};
import { EngagementReportContext } from '../EngagementContext';
import {
  CollapsedBody,
  CollapsedContainer,
  CollapsedText,
  LegalDisclaimer,
  Spacer,
  Summary,
  SummaryActionContainer,
  SummaryWrapper,
} from '../Luca/Luca.design';
import { LucaActions } from '../Luca/LucaActions';
import { LucaSummaryUI } from '../Luca/LucaSummaryUI';

type TProps = { isLucaOpen: boolean; onLucaOpen: (isOpen: boolean) => void; themeID: string };
enum STEPS {
  ASK_LUCA = 'ask_luca',
  LUCA_SUMMARY = 'luca_summary',
}

function LucaSummaryContent({ isLucaOpen, onLucaOpen, themeID }: TProps): JSX.Element {
  const companyColor = useSelector(getAppThemeColor);
  const { i18n } = useLingui();
  const [step, setStep] = useState<STEPS>(STEPS.ASK_LUCA);
  const { viewAs, reportType } = useContext(EngagementReportContext);
  const refreshInterval = useRef<number | undefined>();
  const $isLoading = useBoolState(false);
  const [insight, setInsight] = useState({ ...initialInsight });
  const { addToast } = useToasts();

  // TODO
  // The response should be received within 30 seconds
  // If not
  // TERMINATE THE polling after 35 seconds,
  // Show message to the user to try again in 30 minutes
  useEffect(() => {
    if (refreshInterval.current) {
      clearInterval(refreshInterval.current);
    }

    if (insight.status !== LUCA_INSIGHT_STATUS.SUCCEEDED && themeID.trim().length > 0) {
      const insightTarget = `${reportType.toUpperCase()}_LEARNED_THEME_${themeID}`;
      refreshInterval.current = window.setInterval(async () => {
        try {
          const { data } = await reportsService.getAIInsight(reportType, viewAs, insightTarget, []);
          if (
            data.status === LUCA_INSIGHT_STATUS.READY ||
            data.status === LUCA_INSIGHT_STATUS.FAILED ||
            data.status === LUCA_INSIGHT_STATUS.NO_DATA
          ) {
            setInsight({
              id: data.id,
              status: LUCA_INSIGHT_STATUS.SUCCEEDED,
              message: data.responses[insightTarget],
            });
          }
        } catch {
          setInsight({
            ...initialInsight,
            status: LUCA_INSIGHT_STATUS.FAILED,
            message:
              'Afraid Luca could not come up with suggestions at the moment, please try again later',
          });
        }
      }, 5000);
    }

    return () => {
      clearInterval(refreshInterval.current);
    };
    // eslint-disable-next-line
  }, [insight.status, themeID, viewAs]);

  const onRefresh = async () => {
    if (insight.id) {
      try {
        $isLoading.on();
        const response = await reportsService.deleteAIInsight(
          REPORT_TYPES.ENGAGEMENT,
          viewAs,
          insight.id,
          `${reportType.toUpperCase()}_LEARNED_THEME_${themeID}`,
        );
        if (response.status === 'fail') {
          addToast({
            title: i18n._(t`Try again later`),
            subtitle: i18n._(t`You can only refresh the data once every 6 hours`),
            type: TOAST_TYPES.ERROR,
          });
        }
        if (refreshInterval.current) {
          clearInterval(refreshInterval.current);
        }
      } catch {
        addToast({
          title: i18n._(t`Something went wrong!`),
          type: TOAST_TYPES.ERROR,
        });
      } finally {
        $isLoading.off();
        setInsight({ ...initialInsight });
      }
    }
  };

  const isSucceeded = insight.status === LUCA_INSIGHT_STATUS.SUCCEEDED;
  return (
    <SummaryWrapper companyColor={companyColor}>
      {isLucaOpen ? (
        <CollapsedContainer minWidth="380px">
          <CollapsedBody isScrollable>
            {step === STEPS.LUCA_SUMMARY && (
              <>
                {isSucceeded && (
                  <Summary isScrollable>
                    {i18n._(t`Summary`)}
                    <Icon icon={ICONS.LUCA} size={ICON_SIZES.LARGE} color={COLORS.COMPANY} />
                  </Summary>
                )}
                <LucaSummaryUI
                  isSucceeded={isSucceeded}
                  onRefresh={onRefresh}
                  insightResponse={insight.status}
                  content={insight.message}
                  showActionButton={false}
                  isScrollable={true}
                />
                {isSucceeded && (
                  <LegalDisclaimer>
                    <b>{i18n._(t`Legal Disclaimer: `)}</b>

                    {i18n._(t` The information provided herein has been produced by an independently
                    developed Generative Pre-trained Transformer (GPT) model. While efforts have
                    been made to ensure the accuracy of the data generated by this artificial
                    intelligence (AI) system, it is important to acknowledge that inaccuracies may
                    occur. Please be advised that the personal data submitted for processing by this
                    AI model is not utilized for the purpose of training or enhancing the
                    capabilities of the GPT model.`)}
                  </LegalDisclaimer>
                )}
              </>
            )}
            {step === STEPS.ASK_LUCA && (
              <LucaActions
                onLucaButtonClick={() => {
                  setStep(STEPS.LUCA_SUMMARY);
                }}
                maxWidth={'200px'}
              />
            )}
          </CollapsedBody>
          <SummaryActionContainer onClick={() => onLucaOpen(false)} isOpen>
            <Icon icon={ICONS.COLLAPSE_MENU} size={ICON_SIZES.SMALL} />
            <span>{i18n._(t`Hide summary by Luca`)}</span>
          </SummaryActionContainer>
        </CollapsedContainer>
      ) : (
        <CollapsedContainer>
          <CollapsedBody isScrollable>
            <Spacer />
            <Icon icon={ICONS.LUCA} size={ICON_SIZES.LARGE} />
            <CollapsedText>{i18n._(t`Summary`)}</CollapsedText>
            <Spacer />
          </CollapsedBody>
          <SummaryActionContainer onClick={() => onLucaOpen(true)} isOpen={false}>
            <Icon icon={ICONS.COLLAPSE_MENU} size={ICON_SIZES.SMALL} />
          </SummaryActionContainer>
        </CollapsedContainer>
      )}
    </SummaryWrapper>
  );
}

export { LucaSummaryContent };
