import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';

import getUserFullName from '~/utils/getUserFullName';
import zipArray from '~/utils/zipArray';

export default function (activeUsers, inactiveUsers, deletedUsers) {
  return {
    ...zipArray([
      ...sortBy(filter(activeUsers, Boolean), getUserFullName),
      ...sortBy(filter(inactiveUsers, Boolean), getUserFullName),
    ]),
    ...zipArray(sortBy(filter(deletedUsers, Boolean), getUserFullName), 'user'),
  };
}
