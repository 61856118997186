import React from 'react';

import { ICONS, Icon } from '~/components/Icon';

import {
  AddItemHorizontal,
  AddItemVertical,
  ItemBoxHorizontal,
  ItemBoxVertical,
  PopOverOuterContainer,
  SquareBox,
} from './ActionBtn.design';

import { COLORS } from '~/styles';

export enum ACTION_TYPE {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
  POPOVER = 'popover',
}

type TProps = {
  type: ACTION_TYPE;
  isFirst: boolean;
  onActionClicked?: () => void;
};

function ActionBtn({ type, isFirst, onActionClicked }: TProps): JSX.Element | null {
  if (type === ACTION_TYPE.VERTICAL) {
    return (
      <AddItemVertical isFirst={isFirst}>
        <ItemBoxVertical onClick={onActionClicked}>
          <Icon
            icon={ICONS.ADD_PLUS}
            className={'plus_icon'}
            width={12}
            height={12}
            color={COLORS.PLACEHOLDERS}
          />
        </ItemBoxVertical>
      </AddItemVertical>
    );
  } else if (type === ACTION_TYPE.HORIZONTAL) {
    return (
      <AddItemHorizontal isFirst={isFirst}>
        <ItemBoxHorizontal onClick={onActionClicked}>
          <Icon
            icon={ICONS.ADD_PLUS}
            className={'plus_icon'}
            width={12}
            height={12}
            color={COLORS.PLACEHOLDERS}
          />
        </ItemBoxHorizontal>
      </AddItemHorizontal>
    );
  } else if (type === ACTION_TYPE.POPOVER) {
    return (
      <PopOverOuterContainer isFirst={isFirst}>
        <SquareBox onClick={onActionClicked}>
          <Icon
            icon={ICONS.ADD_PLUS}
            className={'plus_icon'}
            width={12}
            height={12}
            color={COLORS.PLACEHOLDERS}
          />
        </SquareBox>
      </PopOverOuterContainer>
    );
  }
  return null;
}

export { ActionBtn };
