import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Text = styled.span`
  color: ${COLORS.TEXT_HOVER};
  font-size: 24px;
  text-align: center;
  margin-top: 103px;
`;

export const Subtitle = styled.span`
  font-size: 14px;
  text-align: center;
  color: ${COLORS.SUBTEXT};
  width: 449px;
  margin-bottom: 43px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
