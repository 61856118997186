import { createReducer } from 'redux-act';

import * as careerPlansActions from './actions';

const INITIAL_STATE = {
  data: [],
  loading: false,
};

export const careerPlans = createReducer(
  {
    [careerPlansActions.startGetCareerPlans]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [careerPlansActions.finishGetCareerPlans]: (state, payload) => {
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
        loading: false,
      };
    },
  },
  INITIAL_STATE,
);
