import React from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const Ball = styled.div`
  position: absolute;
  display: block;
  color: inherit;
  background-color: currentColor;
  width: 4px;
  height: 4px;
  border-radius: 6px;
`;

const First = styled(Ball)`
  animation-timing-function: cubic-bezier(0.5, 0.3, 0.9, 0.9);
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: 2px 8px;
  animation-timing-function: cubic-bezier(0.5, 0.3, 0.9, 0.9);
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: 2px 8px;
`;

const Second = styled(Ball)`
  animation-timing-function: cubic-bezier(0.5, 0.5, 0.9, 0.9);
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: 2px 8px;
  animation-timing-function: cubic-bezier(0.5, 0.5, 0.9, 0.9);
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: 2px 8px;
`;
const Third = styled(Ball)`
  animation-timing-function: cubic-bezier(0.5, 0.7, 0.9, 0.9);
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: 2px 8px;
  animation-timing-function: cubic-bezier(0.5, 0.7, 0.9, 0.9);
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: 2px 8px;
`;

const Spinner = styled.div`
  position: relative;
  z-index: 1;
  top: -8px;
  left: -2px;

  @keyframes rotate {
    0% {
      transform: rotate(0deg) scale(1);
    }
    100% {
      transform: rotate(1440deg) scale(1);
    }
  }
`;

export const Loader = () => {
  return (
    <Wrapper>
      <Spinner className="loader">
        <First />
        <Second />
        <Third />
      </Spinner>
    </Wrapper>
  );
};
