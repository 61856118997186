import React, { useEffect, useRef, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import moment from 'moment';
import styled from 'styled-components';

import Button from '~/components/Button';
import DatePicker from '~/components/DatePickerDeprecated';
import DropdownButton from '~/components/DropdownButton';
import ExpandMoreIcon from '~/components/Icons/ExpandMore';
import Modal from '~/components/Modal';
import SvgIcon from '~/components/SvgIcon';

import DeleteIcon from '~/assets/trash.svg';

import useListState from '~/hooks/useListState';
import { COLOR_PALETTE, COLORS } from '~/styles';

const CONVERSATION_REPEAT_TYPES = {
  NO_REPEAT: 'no-repeat',
  WEEK: 'week',
  YEAR: 'year',
  CUSTOM: 'custom',
};

const ModalWrapper = styled.div`
  min-heigth: 2000px;
`;

const IntervalDiv = styled.div`
  padding: 0 16px;
  width: 382px;
  height: 44px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${COLORS.BG_PAGE};
    color: var(--company-color);
    cursor: pointer;
  }
`;

const IntervalWrapper = styled.div`
  width: 382px;
`;

const IntervalButtonLabel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 350px;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
`;

const ButtonText = styled.div`
  display: flex;
  color: black;
  width: 100%;
  flex: 1;
  align-items: center;
`;

const NonCustomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CustomWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateColumn = styled.div`
  width: 114px;
  margin-right: 32px;
`;

const TimeColumn = styled.div`
  width: 80px;
  margin-right: 8px;
`;

const TrashColumn = styled.div`
  width: 24px;
  align-items: center;
  display: flex;
  margin-left: 8px;
`;

const StyledSvgIcon = styled(SvgIcon)`
  background-color: ${COLOR_PALETTE.DARK_GRAY};
  &:hover {
    cursor: pointer;
    background-color: var(--company-color);
  }
`;

const CustomTitle = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  letter-spacing: 0.25px;
  margin: 8px 0;
`;

const StyledInput = styled.input`
  width: 80px;
  height: 48px;
  margin: 0 16px;
  border-radius: 6px;
  border: solid 1px #ccced5;
  background-color: ${COLOR_PALETTE.WHITE};
  padding: 0 12px;
  font-size: 16px;
  line-height: 1.75;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const RepeatTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  letter-spacing: 0.25px;
`;

const DatePickerWraper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4px 0;
`;

const AddDateButton = styled.div`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.7px;
  color: var(--company-color);
  cursor: pointer;
`;

const intervalButtonStyles = {
  width: 382,
  marginBottom: 12,
  height: 48,
  borderRadius: 6,
  border: 'solid 1px #c7cfe0',
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: COLOR_PALETTE.WHITE,
  justifyContent: 'center',
};

const timeLabels = {
  weeks: (i18n) => i18n._(t`weeks`),
  years: (i18n) => i18n._(t`years`),
};

const CustomDatesModal = ({
  onClose,
  i18n,
  repeat,
  setRepeat,
  setEvery,
  interval,
  deadLine,
  setDeadline,
  every,
}) => {
  const intervalRef = useRef();
  const [customRepeat, setCustomRepeat] = useState(deadLine.length > 1 ? repeat : interval[0]);
  const [customEvery, setCustomEvery] = useState(every || 1);
  const [forceUpdate, setForceUpdate] = useState(1);
  const [isFormValid, setIsFormValid] = useState(true);
  const $customDeadline = useListState(deadLine.length > 1 ? deadLine : [deadLine]);

  const onSubmit = () => {
    setRepeat(customRepeat);
    setEvery(customEvery);
    setDeadline($customDeadline.items);
    onClose();
  };

  useEffect(() => {
    setIsFormValid(true);
    $customDeadline.items.map((item) => {
      if ([item.startDate, item.endDate].includes(null)) {
        setIsFormValid(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify($customDeadline.items)]);

  const renderInterval = () => {
    return (
      <IntervalWrapper>
        {interval.map((i) => {
          return (
            <IntervalDiv
              key={i.value}
              onClick={() => {
                intervalRef.current._tippy.hide();
                setCustomRepeat(i);
              }}
            >
              {i.label}
            </IntervalDiv>
          );
        })}
      </IntervalWrapper>
    );
  };

  const clickHandle = async () => {
    const dates = $customDeadline.items;
    dates.push({ startDate: null, endDate: null });
    setForceUpdate(forceUpdate + 1);
    $customDeadline.set(dates);
  };

  const handleFrom = (value, index) => {
    const conversations = $customDeadline.items;
    const conversation = conversations[index];
    const endTime = conversation.endDate && moment(conversation.endDate);
    let time = moment(value);
    if (!conversation.startDate) {
      time = moment(
        moment(value).format('YYYY-MM-DD') +
          ' ' +
          moment(conversations[0].startDate).format('HH:mm'),
      );
    }
    if (time && endTime && moment.min(time, endTime).isSame(endTime)) {
      conversation.startDate = time.format();
      conversation.endDate = time.clone().add('30', 'minutes').format();
    } else {
      conversation.startDate = time.format();
      if (!conversation.endDate) {
        conversation.endDate = moment(
          moment(value).format('YYYY-MM-DD') +
            ' ' +
            moment(conversations[0].endDate).format('HH:mm'),
        ).format();
      }
    }
    conversations[index] = conversation;
    setForceUpdate(forceUpdate + 1);
    $customDeadline.set(conversations);
  };

  const handleTo = (value, index) => {
    const conversations = $customDeadline.items;
    const conversation = conversations[index];
    const endHours = moment(value).hours();
    const endMinutes = moment(value).minutes();
    let endDate = moment(value);
    // set year/month/date from startDate
    if (conversation.startDate) {
      const start = moment(conversation.startDate);
      endDate = start.clone().hours(endHours).minutes(endMinutes);
      if (moment.max(start, endDate).isSame(start)) {
        conversation.startDate = endDate.clone().subtract('15', 'minutes').format();
      }
    }
    conversation.endDate = endDate.format();
    conversations[index] = conversation;
    setForceUpdate(forceUpdate + 1);
    $customDeadline.set(conversations);
  };

  const getContent = () => {
    if (customRepeat.value !== CONVERSATION_REPEAT_TYPES.CUSTOM) {
      return (
        <NonCustomWrapper>
          <RepeatTitle>
            <Trans>Every</Trans>
          </RepeatTitle>
          <StyledInput
            type="number"
            value={customEvery}
            onChange={(e) => {
              const numberOfWeeks = Math.round(+e.target.value);
              setCustomEvery(numberOfWeeks <= 1 ? 1 : numberOfWeeks);
            }}
            step={1}
            min={1}
          />
          <RepeatTitle>{timeLabels[customRepeat.repeatInterval](i18n)}</RepeatTitle>
        </NonCustomWrapper>
      );
    }
    return (
      <CustomWrapper>
        <CustomTitle>
          <DateColumn>
            <Trans>Date</Trans>
          </DateColumn>
          <TimeColumn>
            <Trans>From</Trans>
          </TimeColumn>
          <TimeColumn>
            <Trans>To</Trans>
          </TimeColumn>
        </CustomTitle>
        {$customDeadline.items.map((dl, index) => {
          return (
            <DatePickerWraper key={index}>
              <DateColumn>
                <DatePicker
                  onChange={(value) => {
                    handleFrom(value, index);
                  }}
                  width={125}
                  placeholder="12-02-2020"
                  selected={dl.startDate && moment(dl.startDate)}
                  isHideIcon={true}
                />
              </DateColumn>
              <TimeColumn>
                <DatePicker
                  onChange={(value) => {
                    handleFrom(value, index);
                  }}
                  width={80}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  placeholder="11:00"
                  isHideIcon={true}
                  selected={dl.startDate && moment(dl.startDate)}
                />
              </TimeColumn>
              <TimeColumn>
                <DatePicker
                  onChange={(value) => {
                    handleTo(value, index);
                  }}
                  width={80}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  placeholder="11:00"
                  selected={dl.endDate && moment(dl.endDate)}
                  isHideIcon={true}
                />
              </TimeColumn>
              {index > 0 && (
                <TrashColumn
                  onClick={() => {
                    const dates = $customDeadline.items;
                    dates.splice(index, 1);
                    setForceUpdate(forceUpdate + 1);
                    $customDeadline.set(dates);
                  }}
                >
                  <StyledSvgIcon width="24px" height="24px" url={DeleteIcon} />
                </TrashColumn>
              )}
            </DatePickerWraper>
          );
        })}
        <AddDateButton onClick={clickHandle}>
          + <Trans>Add date</Trans>
        </AddDateButton>
      </CustomWrapper>
    );
  };

  return (
    <Modal
      onClose={onClose}
      contentStyles={{ overflow: 'inherit' }}
      title={i18n._(t`Custom repeat`)}
      footerRight={
        <Button disabled={!isFormValid} label={i18n._(t`Save`)} type="primary" onClick={onSubmit} />
      }
      width={428}
      minWidth={428}
    >
      <ModalWrapper>
        <DropdownButton
          contentWidth={382}
          content={renderInterval()}
          maxWidth={382}
          popoverRef={intervalRef}
          placement="bottom"
        >
          <Button
            styles={intervalButtonStyles}
            label={
              <IntervalButtonLabel>
                <ButtonText>{customRepeat && customRepeat.label}</ButtonText>
                <div>
                  <ExpandMoreIcon fill="#000000" />
                </div>
              </IntervalButtonLabel>
            }
          />
        </DropdownButton>
        {getContent()}
      </ModalWrapper>
    </Modal>
  );
};

export default CustomDatesModal;
