import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { GenericWarning } from '~/components/Warnings/GenericWarning';

import { useUserCalendarIntegration } from '~/hooks/useUserHasIntegration';
import { COLOR_SET } from '~/styles';

const Link = styled.a`
  font-weight: bold;
  color: ${COLOR_SET.MIDNICHT_BLUE};
  cursor: pointer;
`;

const Text = styled.span`
  font-size: 14px;
`;

function NoCalendarIntegrationCreateWarning({ className }: { className?: string }) {
  const { goToCalendarIntegrations } = useUserCalendarIntegration();

  return (
    <GenericWarning className={className}>
      <Text>
        <Trans>
          You did not connect your calendar with Learned. Your conversation will not be synchronised
          with your calendar or the calendar(s) of the participant(s).
        </Trans>
        <Link onClick={goToCalendarIntegrations}>
          {' '}
          <Trans>Connect calendar.</Trans>
        </Link>
      </Text>
    </GenericWarning>
  );
}

export { NoCalendarIntegrationCreateWarning };
