import iconFactory from './iconFactory';

const d =
  'M12 3.5a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7zM5 6c.559 0 1.082.153 1.53.419a5.51 5.51 0 0 0 1.133 3.964A3 3 0 1 1 5 6zm14 0a3 3 0 1 1-2.663 4.383 5.509 5.509 0 0 0 1.134-3.964A2.978 2.978 0 0 1 19 6zM5.5 16.25c0-2.071 2.91-3.75 6.5-3.75s6.5 1.679 6.5 3.75V18h-13v-1.75zM0 18v-1.5c0-1.389 1.887-2.557 4.45-2.899-.587.678-.95 1.615-.95 2.649V18H0zm24 0h-3.5v-1.75c0-1.034-.363-1.97-.95-2.649 2.563.342 4.45 1.51 4.45 2.899V18z';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const PeopleIcon = iconFactory('PeopleIcon', d);
export default PeopleIcon;
