import { useState } from 'react';

interface UseHeatmapFilterProps {
  defaultValues?: string[];
}

const useHeatmapFilter = (props?: UseHeatmapFilterProps) => {
  const defaultValues = props?.defaultValues || [];

  const [selectedItems, setSelectedItems] = useState<string[]>(defaultValues);

  const onClickItem = (value: string) => {
    if (selectedItems.includes(value)) {
      setSelectedItems(selectedItems.filter((item) => item !== value));
    } else {
      setSelectedItems([...selectedItems, value]);
    }
  };

  return { selectedItems, setSelectedItems, onClickItem };
};

export { useHeatmapFilter };
