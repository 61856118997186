import isEqual from 'lodash/isEqual';

export default function objectDiff(before, after, keys = [], skipUndefined = false) {
  if (!before || !after) {
    throw new Error('Incorrect arg');
  }
  const d = {};

  keys.forEach((key) => {
    const oldVal = before[key];
    const newVal = after[key];
    if (!isEqual(oldVal, newVal)) {
      if (typeof newVal !== 'undefined' || !skipUndefined) {
        d[key] = newVal;
      }
    }
  });

  return d;
}
