import React, { Component } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import map from 'lodash/map';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';

import BrowserNotSupportedIcon from '~/assets/browser-not-supported-icon.svg';
import logo from '~/assets/logo.svg';

import { BROWSERS_SUPPORTED } from '~/constants';

const Wrapper = styled.div`
  background: #f8fafb;
  position: relative;
  min-height: 100vh;
`;

const Content = styled.div`
  width: 730px;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Logo = styled.img`
  width: 177px;
`;

const Header = styled.div`
  margin-bottom: 40px;
  padding-left: 170px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 170px;
`;

const Footer = styled.div`
  margin-top: 52px;
  padding-left: 170px;
`;

const Title = styled.div`
  font-size: 42px;
  color: #3b3b3b;
  margin-bottom: 20px;
`;

const Description = styled.div`
  font-size: 24px;
  line-height: 1.5;
  color: #333333;
`;

const Icon = styled.img`
  position: absolute;
  left: 0;
  top: 15px;
  width: 124px;
  height: 100px;
`;

const Browsers = styled.ul`
  padding-left: 22px;
`;

const Browser = styled.li`
  font-size: 24px;
  line-height: 1.5;
  color: #333333;
`;

class BrowserNotSupported extends Component {
  goBrowserPage = () => {
    if (window && window.location) {
      window.location.href = 'https://www.google.com/chrome/';
    }
  };

  render() {
    const { i18n } = this.props;

    return (
      <Wrapper>
        <Content>
          <Header>
            <Logo onClick={this.goHome} alt={i18n._(t`Logo`)} src={logo} />
          </Header>
          <Main>
            <Title>
              <Trans>Your browser is not supported</Trans>
            </Title>
            <Description>
              <Trans>Learned currently supports:</Trans>
            </Description>
            <Browsers>
              {map(BROWSERS_SUPPORTED, (browser) => (
                <Browser key={browser.name}>{browser.name}</Browser>
              ))}
            </Browsers>
            <Description>
              <Trans>Please update your browser.</Trans>
            </Description>
            <Icon src={BrowserNotSupportedIcon} alt={i18n._(t`browser not supported icon`)} />
          </Main>
          <Footer>
            <Button onClick={this.goBrowserPage} label={i18n._(t`Get new browser`)} />
          </Footer>
        </Content>
      </Wrapper>
    );
  }
}

export default withI18n()(withRouter(BrowserNotSupported));
