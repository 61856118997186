import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const Container = styled.div<{ extraSpace: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props) =>
    css`
      height: calc(100% - 64px - ${props.extraSpace}px);
    `}
`;

export const CoachBanner = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 46px;
  max-height: 46px;
  background-color: ${COLORS.SUBTEXT};

  font-size: 16px;
  font-weight: 600;
  line-height: 1.63;
  letter-spacing: -0.18px;
  text-align: center;
  color: ${COLORS.WHITE};
`;
