import React, { useState } from 'react';

import { InputWrapper, Label, Wrapper, Error, EyeButtonWrapper } from './design';
import { IInputProps } from './types';

import { Button, ButtonSize, ButtonVariant } from '../Buttons';
import { ICONS } from '../Icon';

const EyeButton = ({ isVisible, onClick }: { isVisible: boolean; onClick: () => void }) => {
  return (
    <EyeButtonWrapper>
      <Button
        type="button"
        variant={ButtonVariant.ICON}
        icon={isVisible ? ICONS.SHOW : ICONS.HIDE}
        onClick={onClick}
        size={ButtonSize.MEDIUM}
      />
    </EyeButtonWrapper>
  );
};

const Input = ({
  name,
  label,
  type,
  error,
  disabled,
  width,
  height,
  register,
  leftIcon,
  rightIcon,
  className,
  ...rest
}: IInputProps) => {
  const [inputType, setInputType] = useState(type);

  return (
    <Wrapper
      error={error}
      disabled={disabled}
      width={width}
      height={height}
      isLeftIcon={!!leftIcon}
      isRightIcon={!!rightIcon}
      className={className}
    >
      {label && <Label htmlFor={name}>{label}</Label>}
      <InputWrapper>
        {leftIcon && <span className="leftIcon">{leftIcon}</span>}
        <input type={inputType} name={name} disabled={disabled} {...register} {...rest} />
        {type === 'password' && (
          <EyeButton
            isVisible={inputType !== 'password'}
            onClick={() =>
              setInputType((prevType) => (prevType === 'password' ? 'text' : 'password'))
            }
          />
        )}
        {rightIcon && <span className="rightIcon">{rightIcon}</span>}
      </InputWrapper>
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};

export { Input };
