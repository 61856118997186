import { useParams } from 'react-router';

import { useAsyncFetch } from '~/hooks/useAsyncFetch';
import { getSkill } from '~/services/skills';

import type { ISkillPopulated } from '@learned/types';

const useSkill = () => {
  const params: Record<string, string | undefined> = useParams();
  const skillId = params?.skillId as string | undefined;

  const [skill, isLoading] = useAsyncFetch<ISkillPopulated | undefined>(
    {
      fetch: async () => {
        if (!skillId) {
          return undefined;
        }

        const result = await getSkill(skillId, ['jobProfiles']);

        const skill = result.data.skill as ISkillPopulated;
        return skill;
      },
      initialState: undefined,
    },
    [skillId],
  );

  return { skill, isLoading };
};

export { useSkill };
