import React, { Component, Fragment } from 'react';

import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SkillName from '~/components/Skill/SkillName';
import StatusIcon from '~/components/StatusIcon';
import SvgIcon from '~/components/SvgIcon';
import AvatarCard from '~/components/UI/AvatarCard';

import collapseIcon from '~/assets/ic-unfold-less-24.svg';
import expandIcon from '~/assets/ic-unfold-more-24.svg';

import { FOCUSAREA_LEVELS, REQUEST_STATUSES } from '~/constants';
import { COLOR_PALETTE } from '~/styles';
import convertToTimeString from '~/utils/convertToTimeString';

const BtnToggle = styled.div`
  margin-right: 7px;
`;

const Column = styled.div`
  display: flex;
  width: ${(props) => props.width};
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
`;

const RateWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const FeedbacksTable = styled.div`
  display: flex;
  width: 100%;
`;

const LevelRateAvg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 24px;
  border-radius: 6px;
  background-color: var(--company-color);
  color: ${COLOR_PALETTE.WHITE};
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  text-align: center;
  color: ${COLOR_PALETTE.WHITE};
`;

const LevelRate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  text-align: right;
  color: #3b3b3b;
  text-align: center;
  position: absolute;
  right: 8px;
  top: 8px;
`;

const LevelTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #70747f;
  text-transform: uppercase;
  margin-bottom: 6px;
`;

const LevelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const RateExplanation = styled.div`
  word-break: break-word;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  color: #3b3b3b;
  border-radius: 10px;
  background-color: #f8f8f8;
  padding: 8px 32px 8px 8px;
  min-height: 24px;
`;

const SkillHeader = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 20px 0px;
  box-sizing: border-box;
  border-bottom: solid 0.5px #ecedf0;
`;

const SkillInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 0px;
  box-sizing: border-box;
`;

const SkillNameWrapper = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #3b3b3b;
`;

const SkillWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  box-sizing: border-box;
`;

const LabelDate = styled.div`
  height: 16px;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #70747f;
  margin-top: 8px;
`;

class Skill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.isExpandedByDefault,
    };
  }

  UNSAFE_componentWillReceiveProps = (newProps) => {
    if (newProps.isAllExpanded !== this.props.isAllExpanded) {
      this.setState({
        open: newProps.isAllExpanded,
      });
    }
  };

  static propTypes = {
    isExpandedByDefault: PropTypes.bool,
    isAllExpanded: PropTypes.bool,
  };

  static defaultTypes = {
    isExpandedByDefault: false,
    isAllExpanded: false,
  };

  toggleSkill = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
    });
  };

  renderFeedback = (userRTFeedback, index) => {
    const { type, skill } = this.props;

    const skillRating =
      (userRTFeedback.ratings || []).find((rating) => {
        let isRatingForSkill = false;
        if (rating) {
          if (type === 'skill' && String(rating.skill) === String(skill.id)) {
            isRatingForSkill = true;
          }
          if (type === 'question' && rating.question === skill.id) {
            isRatingForSkill = true;
          }
        }
        return isRatingForSkill;
      }) || {};

    const maxValue = 4;
    const lastUpdate =
      get(skillRating, 'meta.lastModifiedDate') || get(skillRating, 'meta.createdDate');
    const requestStatus = get(userRTFeedback, 'request.status');
    const isDone = requestStatus !== REQUEST_STATUSES.REQUESTED.key;

    return (
      <SkillInfoWrapper key={index}>
        <FeedbacksTable>
          <Column width="30%">
            <AvatarCard userId={userRTFeedback.createdFor} email={userRTFeedback.createdForEmail} />
          </Column>
          <Column width="70%">
            {isDone ? (
              <Fragment>
                <RateWrapper>
                  <RateExplanation>{skillRating.explanation || ''}</RateExplanation>
                  <LevelRate>
                    {skillRating.rate ? `${skillRating.rate.toFixed()}/${maxValue}` : 'N/A'}
                  </LevelRate>
                </RateWrapper>
                {lastUpdate && (
                  <LabelDate>
                    <Trans>Completed at: </Trans>
                    {convertToTimeString(lastUpdate)}
                  </LabelDate>
                )}
              </Fragment>
            ) : (
              <StatusIcon type="review" status={requestStatus} />
            )}
          </Column>
        </FeedbacksTable>
      </SkillInfoWrapper>
    );
  };

  renderQuestions = () => {
    const { type, skillLevel, skill, i18n, items } = this.props;

    const questionRatings = [];
    let isMinOneDone = false;

    items.forEach((userRTFeedback) => {
      const requestStatus = get(userRTFeedback, 'request.status');
      if (requestStatus !== REQUEST_STATUSES.REQUESTED.key) {
        isMinOneDone = true;
      }
      (userRTFeedback.ratings || []).forEach((rating) => {
        if (rating) {
          if (type === 'skill' && String(rating.skill) === String(skill.id)) {
            questionRatings.push(rating);
          }
          if (type === 'question' && rating.question === skill.id) {
            questionRatings.push(rating);
          }
        }
      });
    });

    const sum = questionRatings.reduce((sum, rating) => sum + rating.rate, 0);
    const amount = questionRatings.length;
    const avg = sum / amount || 0;
    const max = 4;

    return (
      <SkillInfoWrapper>
        {type === 'skill' && skillLevel && (
          <LevelWrapper>
            <LevelTitle>
              <Trans>Behavior at level </Trans>
              {FOCUSAREA_LEVELS[String(skillLevel)].label(i18n)}
            </LevelTitle>
            <LevelRateAvg>{isMinOneDone ? `${avg.toFixed()}/${max}` : 'N/A'}</LevelRateAvg>
          </LevelWrapper>
        )}
        {items.map(this.renderFeedback)}
      </SkillInfoWrapper>
    );
  };

  render() {
    const { skill, type } = this.props;
    const { open } = this.state;

    return (
      <SkillWrapper key={skill.id}>
        <SkillHeader>
          <BtnToggle onClick={this.toggleSkill}>
            <SvgIcon
              width="24px"
              height="24px"
              isDefaultColor={!open}
              defaultColor="#ccced5"
              url={open ? collapseIcon : expandIcon}
            />
          </BtnToggle>
          {type === 'skill' && (
            <SkillNameWrapper>
              <Trans>How did this person perform on </Trans>
              <SkillName skill={skill} />?
            </SkillNameWrapper>
          )}
          {type === 'question' && <SkillNameWrapper>{skill.name}</SkillNameWrapper>}
        </SkillHeader>
        {open && this.renderQuestions()}
      </SkillWrapper>
    );
  }
}

export default withI18n()(Skill);
