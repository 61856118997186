import React from 'react';

import styled from 'styled-components';

import Shadow from '~/components/UI/Shadow';

import { COLOR_PALETTE } from '~/styles';

const Container = styled(Shadow)`
  background-color: ${COLOR_PALETTE.WHITE};
  ${(props) => props.$noBorders && 'border: none;'}
  ${(props) => props.$noBottomBorder && 'border-radius: 6px 6px 0 0;'}
  ${(props) => props.$noHorizontalBorders && 'border-top: none;'}
  ${(props) => props.$noHorizontalBorders && 'border-bottom: none;'}
  ${(props) => props.$noBorderRadiusTop && 'border-radius: 0 0 6px 6px;'}
  ${(props) => props.$noBorderRadiusBottom && 'border-radius: 6px 6px 0 0;'}
  ${(props) => props.$noBorderRadiusTop && props.$noBorderRadiusBottom && 'border-radius: initial;'}
  display: flex;
  padding: 4px 24px 4px 18px;
`;

const ActionsContainer = ({
  children,
  noHorizontalBorders,
  noBottomBorder,
  noBorders,
  noBorderRadiusTop,
  noBorderRadiusBottom,
  className,
}) => {
  return (
    <Container
      className={className}
      $noHorizontalBorders={noHorizontalBorders || noBorders}
      $noBottomBorder={noBottomBorder || noBorders}
      $noBorders={noBorders}
      $noBorderRadiusTop={noBorderRadiusTop}
      $noBorderRadiusBottom={noBorderRadiusBottom}
    >
      {children}
    </Container>
  );
};

export default ActionsContainer;
