import React, { useEffect, useState } from 'react';

import { CAREER_PLAN_STATUSES, CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { ButtonVariant } from '~/components/Buttons';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import PaginationBar from '~/components/PaginationBar';
import SelectJobProfileModal from '~/components/SelectJobProfileModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TablePlaceholder } from '~/components/TablePlaceholder';
import BoxWithBorder from '~/components/UI/BoxWithBorder';
import { TableHeader } from '~/pages/Conversations/components/TableHeader';

import { Columns, SORT_OPTIONS } from './columns';
import { sortJobs } from './utils';

import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { usePagination } from '~/hooks/usePagination';
import { getUser } from '~/selectors/baseGetters';
import {
  changePrimaryCareerPlanForUser,
  createCareerPlans,
  removeCareerPlan,
  retrieveCareerPlans,
  updateCareerPlan,
} from '~/services/careerPlans';

import { Wrapper, StyledTableJobs } from '../../design';
import { DateEditModal } from '../components/DateEditModal';
import TabContainer from '../components/TabContainer';

import type { ActiveRole, FormDataType } from '../../types';
import type { ICareerPlan, IUser } from '@learned/types';

interface IOptions {
  options: {
    sortBy: SORT_OPTIONS;
    skip: number;
    limit: number;
  };
}

const INITIAL_OPTIONS = {
  options: {
    sortBy: SORT_OPTIONS.NAME_A_Z,
    skip: 0,
    limit: 3,
  },
};

const LS_KEY = 'LS_CAREER_JOBS_USER_PUBLIC';

const Jobs = ({ user }: { user: IUser }) => {
  const { i18n } = useLingui();
  const [jobs, setJobs] = useState<ICareerPlan[]>([]);
  const [paginatedJobs, setPaginatedJobs] = useState<ICareerPlan[]>([]);
  const $isShowCreateJobModal = useBoolState(false);
  const $isShowEditDateModal = useBoolState(false);
  const $isShowDeleteModal = useBoolState(false);
  const formMethods = useForm<FormDataType>({
    mode: 'all',
    defaultValues: {
      startDate: '',
      endDate: '',
    },
  });
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = formMethods;
  const [jobToEdit, setJobToEdit] = useState<ICareerPlan>();
  const [jobToDelete, setJobToDelete] = useState<ICareerPlan>();
  const [currentFilters, setCurrentFilters] = useState<IOptions>(INITIAL_OPTIONS);
  const [currentSortOption, setCurrentSortOption] = useState(SORT_OPTIONS.NAME_A_Z);
  const { pagination, changePagination, resetPagination } = usePagination(3);
  const [totalCount, setTotalCount] = useState(0);
  const $loading = useBoolState(false);
  const currentUser = useSelector(getUser);
  const getMultiLangString = useMultiLangString();

  const isAdmin = currentUser.isAdmin;

  const calcDuration = (items: ICareerPlan[]) => {
    const updatedCareerPlans = items.map((item) => {
      const startDate = item.startDate
        ? moment(item.startDate, 'YYYY-MM-DD')
        : moment(item.meta.createdDate, 'YYYY-MM-DD');
      const endDate = item.endDate ? moment(item.endDate, 'YYYY-MM-DD') : moment();
      const duration = moment.duration(endDate.diff(startDate));

      if (duration.asMilliseconds() < 0) {
        return { ...item, duration: '-' };
      }

      if (duration.years() >= 1) {
        return {
          ...item,
          duration:
            `${duration.years()} ${duration.years() === 1 ? t`year` : t`years`}` +
            (duration.months() >= 1
              ? ` ${duration.months()} ${duration.months() === 1 ? t`month` : t`months`}`
              : ''),
        };
      }

      if (duration.months() >= 1) {
        return {
          ...item,
          duration: `${duration.months()} ${duration.months() === 1 ? t`month` : t`months`}`,
        };
      }

      return {
        ...item,
        duration: `${duration.days()} ${duration.days() === 1 ? t`day` : t`days`}`,
      };
    });
    return updatedCareerPlans;
  };

  // first render
  useEffect(() => {
    // get filters from localStorage
    const localStorageData = localStorage.getItem(LS_KEY);
    const isLocalStorageData = !isEmpty(localStorageData);

    if (isLocalStorageData) {
      const sortOption = JSON.parse(localStorageData as string).options.sortBy;
      setCurrentFilters(JSON.parse(localStorageData as string));
      // @ts-ignore
      setCurrentSortOption(sortOption);
    } else {
      setCurrentSortOption(SORT_OPTIONS.NAME_A_Z);
      setCurrentFilters(INITIAL_OPTIONS);
    }

    const fetchData = async () => {
      $loading.on();
      const roles = await retrieveCareerPlans(user.id);
      setTotalCount(roles.total);
      setJobs(Object.values(roles.data));
      const updatedCareerPlans = calcDuration(Object.values(roles.data));
      updatedCareerPlans.forEach((cp) => {
        cp.name = getMultiLangString(cp.name);
      });

      const paginatedItems = updatedCareerPlans.slice(
        pagination.skip,
        pagination.skip + pagination.limit,
      );
      setPaginatedJobs(paginatedItems as ICareerPlan[]);
      $loading.off();
    };
    fetchData();
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      const updatedCareerPlans = calcDuration(jobs);
      updatedCareerPlans.forEach((cp) => {
        cp.name = getMultiLangString(cp.name);
      });

      const sortedItems = sortJobs(updatedCareerPlans, currentFilters.options.sortBy);
      const paginatedItems = sortedItems.slice(pagination.skip, pagination.skip + pagination.limit);
      setPaginatedJobs(paginatedItems as ICareerPlan[]);
    };
    fetchData();
    // eslint-disable-next-line
  }, [pagination, currentFilters, jobs]);

  const actionButton = {
    label: t`Assign`,
    onClick: $isShowCreateJobModal.on,
  };

  const onEdit = (job: ICareerPlan) => {
    setJobToEdit(job);
    setValue('startDate', job.startDate ? moment(job.startDate).format('DD-MM-YYYY') : '');
    setValue('endDate', job.endDate ? moment(job.endDate).format('DD-MM-YYYY') : '');
    $isShowEditDateModal.on();
  };

  const promptDelete = (job: ICareerPlan) => {
    setJobToDelete(job);
    $isShowDeleteModal.on();
  };

  const onDelete = async (job: ICareerPlan) => {
    $loading.on();
    await removeCareerPlan(job.id);
    const roles = await retrieveCareerPlans(user.id);
    setJobs(Object.values(roles.data));
    setTotalCount(roles.total);
    resetPagination();
    $loading.off();
  };

  const onChangePrimaryJob = async (job: ICareerPlan) => {
    $loading.on();
    const newPrimaryJob = await changePrimaryCareerPlanForUser(job.id);
    const updatedJobs = jobs.map((job) =>
      job.id === newPrimaryJob.data.id ? { ...job, primary: true } : { ...job, primary: false },
    );
    setJobs(updatedJobs);
    $loading.off();
  };

  const addUserJob = async (selectedJobProfiles: ActiveRole[]) => {
    const jobsData = selectedJobProfiles.map((job) => ({
      jobProfileId: job.id,
      status: CAREER_PLAN_STATUSES.CURRENT,
      userId: user.id,
    }));

    await createCareerPlans(jobsData);

    const roles = await retrieveCareerPlans(user.id);
    setJobs(Object.values(roles.data));
    setTotalCount(roles.total);
  };

  const onSaveDates = async (formData: FormDataType) => {
    $loading.on();

    const data = {
      startDate:
        formData.startDate === ''
          ? undefined
          : moment(formData.startDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      endDate:
        formData.endDate === ''
          ? undefined
          : moment(formData.endDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      status: moment(formData.endDate, 'DD-MM-YYYY').isBefore(moment())
        ? CAREER_PLAN_STATUSES.INACTIVE
        : jobToEdit?.status,
    };

    await updateCareerPlan(jobToEdit?.id, data);
    const roles = await retrieveCareerPlans(user.id);

    reset();
    $isShowEditDateModal.off();
    setJobs(Object.values(roles.data));
    $loading.off();
  };

  const onCancel = () => {
    reset();
    $isShowEditDateModal.off();
  };

  const onCurrentFiltersChange = (sortBy: string) => {
    // @ts-ignore
    setCurrentSortOption(sortBy);
    const newFilters = {
      ...currentFilters,
      options: {
        ...currentFilters?.options,
        sortBy,
      },
    };
    // @ts-ignore
    setCurrentFilters(newFilters);

    localStorage.setItem(LS_KEY, JSON.stringify(newFilters));
  };

  const onPageChangeClick = async ({ index, skip }: { index: number; skip: number }) => {
    const newPagination = {
      ...pagination,
      skip,
      index,
    };
    onPaginationChange(newPagination);
  };

  const onPaginationChange = (newPagination: typeof pagination) => {
    changePagination(newPagination);

    localStorage.setItem(
      LS_KEY,
      JSON.stringify({
        ...currentFilters,
        options: {
          ...currentFilters?.options,
          limit: newPagination.limit,
          skip: newPagination.skip,
        },
      }),
    );
  };

  const handleChangeItemsPerPage = ({ limit }: { limit: number }) => {
    const newPagination = {
      ...pagination,
      limit,
    };
    onPaginationChange(newPagination);
  };

  return (
    <BoxWithBorder>
      <TabContainer>
        <Wrapper>
          <TableHeader
            filters={{ search: '', setSearch: () => {} }}
            actionButton={isAdmin ? actionButton : null}
            actionButtonVariant={ButtonVariant.SECONDARY}
            headerTitle={i18n._(t`Jobs`)}
            actionButtonIcon={undefined}
          />

          <ShowSpinnerIfLoading loading={$loading.value}>
            <StyledTableJobs
              data={paginatedJobs}
              columns={Columns({ onEdit, promptDelete, onChangePrimaryJob, isAdmin })}
              sortBy={currentSortOption}
              setSortBy={(sortBy: SORT_OPTIONS) => onCurrentFiltersChange(sortBy)}
              hideHeaders={false}
            />

            {isEmpty(paginatedJobs) && (
              <TablePlaceholder
                isLoading={false}
                isFiltered={false}
                noResultText={i18n._(t`No jobs found`)}
                emptyStateText={i18n._(t`No jobs yet… Let's create one!`)}
              />
            )}

            {pagination && (
              <PaginationBar
                pagination={pagination}
                changePagination={onPageChangeClick}
                changePageSize={handleChangeItemsPerPage}
                count={totalCount}
                noShadow
                noBorder
                noTopBorder
                showCount
                itemLabel={i18n._(t`Jobs`)}
              />
            )}
          </ShowSpinnerIfLoading>

          {$isShowCreateJobModal.value && (
            <SelectJobProfileModal
              title={i18n._(t`Assign to role profile`)}
              onModalClose={$isShowCreateJobModal.off}
              selectedUserId={user.id}
              onSubmit={addUserJob}
            />
          )}

          {$isShowEditDateModal.value && (
            <DateEditModal
              onClose={onCancel}
              onSave={onSaveDates}
              submit={handleSubmit}
              register={register}
              getValues={getValues}
              setValue={setValue}
              errors={errors}
            />
          )}

          {$isShowDeleteModal.value && (
            <ConfirmationModal
              title={i18n._(t`Delete job`)}
              description={i18n._(t`Are you sure you want to delete this job?`)}
              type={CONFIRMATION_MODAL_TYPE.WARNING}
              onClose={$isShowDeleteModal.off}
              onSubmit={() => onDelete(jobToDelete as ICareerPlan)}
            />
          )}
        </Wrapper>
      </TabContainer>
    </BoxWithBorder>
  );
};

export { Jobs };
