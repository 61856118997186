import React, { ReactNode } from 'react';

import { Toast, TOAST_TYPES } from '.';

import type { ToastProps } from 'react-toast-notifications';

// Adapter for react-toast-notifications package
function ToastAdapter({
  children: title,
  type,
  subtitle,
  transitionState,
  autoDismissTimeout,
  onDismiss,
}: ToastProps & { type: TOAST_TYPES; subtitle: ReactNode }) {
  const state = ['entering', 'exited'].includes(transitionState) ? 'not-closing' : 'closing';

  return (
    <Toast
      title={title}
      subtitle={subtitle}
      type={type}
      transitionState={state}
      dismissDuration={autoDismissTimeout}
      onDismiss={onDismiss}
    />
  );
}

export { ToastAdapter };
