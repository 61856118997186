import React from 'react';

import { ROLES, RATING_STATUSES, KPI_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import LockedHint from '~/components/LockedHint';
import RickTextView from '~/components/RickTextView';
import RoleLabel from '~/components/RoleLabel';
import SvgIcon from '~/components/SvgIcon';
import Tooltip from '~/components/Tooltip';
import AvatarCard from '~/components/UI/AvatarCard';
import { Header6 } from '~/components/UI/Typographics/headers';

import viewIcon from '~/assets/view-1.svg';

import { RATING_TYPES } from '~/constants';
import { getSelectedRole, getUser } from '~/selectors/baseGetters';
import { COLORS, COLOR_SET } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';

const AvatarRow = styled.div`
  display: flex;
  align-items: center;
  margin-left: -6px;
`;

const CalibrateText = styled.div`
  margin-left: 16px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_SET.FUCHSIA};
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    margin-left: 8px;
  }
`;

const RateText = styled(Header6)`
  color: ${COLORS.TEXT_SECONDARY};
  margin: 0 0 0 20px;
  display: flex;
`;

const FlexDiv = styled.div`
  display: flex;
`;

const RatingRow = styled.div`
  padding: ${({ isSingleLine }) => (isSingleLine ? '16px 20px' : '20px 20px 20px 20px')};
  box-sizing: border-box;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const SkippedText = styled.div`
  margin-left: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.67;
  letter-spacing: normal;
  color: ${COLORS.TEXT_SECONDARY};
`;

const StyledButton = styled(Button)`
  height: 32px;
  font-weight: bold;
  border-color: ${COLORS.COMPANY};
  color: ${COLORS.COMPANY};

  &:hover {
    background-color: ${(props) => (props.disabled ? COLORS.INACTIVE : COLORS.COMPANY)};
    color: ${(props) => (props.disabled ? COLORS.COMPANY : COLORS.WHITE)};
  }
`;

const RateTextItem = styled.span`
  margin-left: 8px;
`;

const RateRow = ({
  i18n,
  rating,
  userId,
  onUpdate,
  viewerId,
  isLockedFeedback,
  pdfView,
  users,
  className,
}) => {
  const viewer = useSelector(getUser);
  const currentRole = useSelector(getSelectedRole);
  const rateIdx = rating.scale.indexOf(rating.rate);
  const scaleLabel = rating.scaleLabels[rateIdx];
  const idOfViewer = viewerId || viewer.id;
  const isOwner =
    userId && userId === idOfViewer && [ROLES.USER, ROLES.COACH].includes(currentRole); // update should be able for owner user or coach
  const user = get(users, `[${userId}]`);
  const isSkippedQuestion = !rating.rate && !rating.explanation;

  const rate = rating.rate;
  const totalScale = rating.scale[rating.scale.length - 1];

  return (
    <RatingRow className={className} isSingleLine={isSkippedQuestion}>
      <AvatarRow isSingleLine={isSkippedQuestion}>
        <AvatarCard
          userId={userId}
          email={rating.email}
          userFullname={getUserFullName(user)}
          userAvatar={user?.avatarUrl}
          type="small"
          styles={{ maxWidth: '500px' }}
        />
        {!!rating.rate && (
          <RateText>
            <FlexDiv>
              <Trans>Rated level</Trans> {rate}/{totalScale}
              <RateTextItem>{scaleLabel} </RateTextItem>
            </FlexDiv>
            {(rating.sectionType === KPI_TYPES.SKILLS || rating.skillLevel) && (
              <FlexDiv style={{ marginLeft: 16 }}>
                <span style={{ marginRight: 8 }}>
                  {((rating.rate / rating.skillLevel) * 100).toFixed(0)}%
                </span>
                <Trans>skill coverage</Trans>
              </FlexDiv>
            )}
          </RateText>
        )}
        {rating.type === RATING_TYPES.COACH_RATING && <RoleLabel value={i18n._(t`Coach`)} />}
        {isSkippedQuestion && (
          <SkippedText>
            <Trans>Did not answer this question</Trans>
          </SkippedText>
        )}
        {rating.status === RATING_STATUSES.CALIBRATE && (
          <Tooltip
            tooltip={i18n._(
              t`This input is not yet shared and therefore not visible to the employee`,
            )}
          >
            <CalibrateText>
              <SvgIcon
                url={viewIcon}
                width={'20px'}
                height={'13px'}
                isDefaultColor
                defaultColor={COLOR_SET.FUCHSIA}
              />
              <span>
                <Trans>Not shared</Trans>
              </span>
            </CalibrateText>
          </Tooltip>
        )}
        {!pdfView && isOwner && (
          <ActionWrapper>
            <StyledButton
              type={Button.types.white}
              label={i18n._(t`Update`)}
              onClick={onUpdate}
              disabled={isLockedFeedback}
              height={32}
            />
            {isLockedFeedback && (
              <LockedHint
                tooltip={i18n._(
                  t`It is no longer possible to give or update feedback. Contact your HR manager for support.`,
                )}
              />
            )}
          </ActionWrapper>
        )}
      </AvatarRow>
      {!!rating.explanation && (
        <RickTextView html={rating.explanation} fontWeight="normal" fontColor={COLORS.TEXT_BLACK} />
      )}
    </RatingRow>
  );
};

RateRow.propTypes = {
  rating: PropTypes.object.isRequired,
  userId: PropTypes.string,
  onUpdate: PropTypes.func,
  isLockedFeedback: PropTypes.bool,
};

export default RateRow;
