/* eslint-disable react/no-unescaped-entities */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import flatten from 'lodash/flatten';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { ImageWithAlt } from '~/components/ImageWithAlt';
import RickTextView from '~/components/RickTextView';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';

import { IconInfo } from './components/IconInfo';
import { RecommendedCareerPathTable } from './components/RecommendedCareerPathTable';
import { SkillTemplateTable } from './components/SkillTemplatesTable';
import {
  Card,
  Container,
  Description,
  Divider,
  Footer,
  Form,
  Image,
  JobDetails,
  SectionHeader,
  SectionTitle,
  Subtitle,
  SummarySection,
  Title,
} from './design';
import { useJobFamilies } from './hooks/useJobFamilies';

import { EDUCATION_LEVELS, WORK_EXPERIENCE_LEVELS } from '~/constants';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getSkillsById } from '~/services/skills';
import { COLORS } from '~/styles';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { convertMarkdownStarsToStrong } from '~/utils/markdown';
import { isNotNil } from '~/utils/typePredicates';

import type { IGeneralForm, IJobLevelGroupPopulated } from './types';
import type { ISkill, ISkillCategory, ISkillTemplate } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface StepSummaryProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IGeneralForm>;
  onPublish: (data: IGeneralForm) => void;
  onSave: (data: IGeneralForm) => void;
  onFail: () => void;
  skillCategories: ISkillCategory[];
  setIsAdvanceSettingsVisible: Dispatch<SetStateAction<boolean>>;
  jobLevelGroupsPopulated: IJobLevelGroupPopulated[];
  skillTemplates: ISkillTemplate[];
  isSaveLoading?: boolean;
  isPublishLoading?: boolean;
}

function StepSummary({
  sectionState,
  formMethods,
  onPublish,
  onSave,
  onFail,
  skillCategories,
  setIsAdvanceSettingsVisible,
  jobLevelGroupsPopulated,
  skillTemplates,
  isSaveLoading = false,
  isPublishLoading = false,
}: StepSummaryProps) {
  const { i18n } = useLingui();
  const { jobFamilies } = useJobFamilies();
  const { handleSubmit, getValues, watch } = formMethods;
  const [skills, setSkills] = useState<ISkill[]>([]);
  const getMultiLangString = useMultiLangString();

  const watchSkills = watch('skills');

  useEffect(() => {
    const fetchData = async () => {
      const skillIds = flatten(
        Object.values(watchSkills).map((skillCategory) =>
          skillCategory.skills.map((skill) => skill.skill),
        ),
      ).filter(isNotNil);

      const skillsResponse = await getSkillsById(skillIds);

      setSkills(skillsResponse);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watchSkills)]);

  const jobFamily = jobFamilies.filter((jobFamily) => {
    if (jobFamily.id === watch('jobFamily')) {
      return jobFamily;
    }
  })?.[0];

  const name = getMultiLangString(convertLanguageValue(getValues('name')));

  const description = getMultiLangString(convertLanguageValue(getValues('description')));

  const workExperienceLevels = Object.values(WORK_EXPERIENCE_LEVELS).map(({ value, name }) => ({
    id: value,
    name,
  }));

  const educationLevels = Object.values(EDUCATION_LEVELS).map(({ value, name }) => ({
    id: value,
    name,
  }));

  const jobLevelGroupData = jobLevelGroupsPopulated.find(
    (item) => item.id === watch('jobLevelGroup.id'),
  );

  return (
    <Container>
      <Form>
        <Title>
          <Trans>Final check</Trans>
        </Title>
        <Subtitle>
          <Trans>
            Congratulations! You have successfully created a new job. Take a moment to review the
            job details below. Once you are satisfied, you can proceed to publish the job and start
            attracting qualified candidates.
          </Trans>
        </Subtitle>
        <Footer>
          <Button
            type="button"
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.SECONDARY}
            label={<Trans>Save as draft</Trans>}
            onClick={handleSubmit(onSave, onFail)}
            isLoading={isSaveLoading}
            disabled={isSaveLoading}
          />
          <Button
            type="button"
            size={ButtonSize.BIG}
            variant={ButtonVariant.PRIMARY}
            label={<Trans>Publish job</Trans>}
            onClick={handleSubmit(onPublish, onFail)}
            isLoading={isPublishLoading}
            disabled={isPublishLoading}
          />
        </Footer>
      </Form>
      <Card>
        <SummarySection>
          <SectionHeader>
            <SectionTitle>
              <Icon icon={ICONS.VALID} size={ICON_SIZES.MEDIUM} fill={COLORS.COMPANY} />
              <Trans>1. Job details</Trans>
            </SectionTitle>
            <Button
              onClick={() => {
                setIsAdvanceSettingsVisible(false);
                sectionState.setCurrentSection(0);
              }}
              icon={ICONS.EDIT_PENCIL}
              label={<Trans>Edit</Trans>}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              color={COLORS.ICONS_PRIMARY}
            />
          </SectionHeader>
          <JobDetails>
            {getValues('coverImage') && (
              <Image height="327px" altFontSize="32px">
                <ImageWithAlt src={getValues('coverImage')} alt={name.substring(0, 2)} />
              </Image>
            )}
            <Title>{name}</Title>
            <Description>
              <h1>
                <Trans>Description</Trans>
              </h1>
              {/* @ts-ignore */}
              <RickTextView
                color={COLORS.TEXT_MAIN}
                html={convertMarkdownStarsToStrong(description)}
              />
            </Description>
          </JobDetails>
        </SummarySection>
        <Divider />
        <SummarySection>
          <div className="columns">
            <div>
              <Description>
                <h1>
                  <Trans>Job matrix</Trans>
                </h1>
              </Description>
              <div className="iconsSection">
                <IconInfo
                  title={i18n._(t`Job Group`)}
                  subtitle={jobFamily?.name?.en_GB || i18n._(t`N/A`)}
                  icon={ICONS.JOB_FAMILY}
                />
              </div>
              <div className="iconsSection">
                <IconInfo
                  title={i18n._(t`Job Level`)}
                  subtitle={
                    jobLevelGroupData
                      ? getMultiLangString(jobLevelGroupData.name) +
                        (!jobLevelGroupData.hasOnlyOneLevel
                          ? `(${jobLevelGroupData.level + 1})`
                          : '')
                      : i18n._(t`N/A`)
                  }
                  icon={ICONS.JOB_LEVEL}
                />
              </div>
            </div>
            <div>
              <Description>
                <h1>
                  <Trans>Background</Trans>
                </h1>
              </Description>
              <div className="iconsSection">
                <IconInfo
                  title={i18n._(t`Work Experience`)}
                  subtitle={
                    workExperienceLevels
                      .find((item) => item.id === watch('careerLevel'))
                      ?.name(i18n) || i18n._(t`N/A`)
                  }
                  icon={ICONS.WORK_EXPERIENCE}
                />
              </div>
              <div className="iconsSection">
                <IconInfo
                  title={i18n._(t`Education`)}
                  subtitle={
                    educationLevels
                      .find((item) => item.id === watch('educationLevel'))
                      ?.name(i18n) || i18n._(t`N/A`)
                  }
                  icon={ICONS.EDUCATION}
                />
              </div>
            </div>
          </div>
        </SummarySection>
        {Object.keys(watchSkills).map((skillCategoryId, index) => (
          <SummarySection key={skillCategoryId}>
            <SectionHeader>
              <SectionTitle>
                <Icon icon={ICONS.VALID} size={ICON_SIZES.MEDIUM} fill={COLORS.COMPANY} />
                <Trans>
                  {index + 2}. {sectionState.sections[index + 1].title}
                </Trans>
              </SectionTitle>
              <Button
                onClick={() => sectionState.setCurrentSection(index + 1)}
                icon={ICONS.EDIT_PENCIL}
                label={<Trans>Edit</Trans>}
                variant={ButtonVariant.SECONDARY}
                size={ButtonSize.MEDIUM}
                color={COLORS.ICONS_PRIMARY}
              />
            </SectionHeader>
            <SkillTemplateTable
              skillCategoryId={skillCategoryId}
              formMethods={formMethods}
              skillTemplates={skillTemplates}
              skills={skills}
              skillCategories={skillCategories}
            />
          </SummarySection>
        ))}
        <SummarySection>
          <SectionHeader>
            <SectionTitle>
              <Icon icon={ICONS.VALID} size={ICON_SIZES.MEDIUM} fill={COLORS.COMPANY} />
              <Trans>Recommended career path</Trans>
            </SectionTitle>
            <Button
              onClick={() => {
                setIsAdvanceSettingsVisible(true);
                sectionState.setCurrentSection(0);
              }}
              icon={ICONS.EDIT_PENCIL}
              label={<Trans>Edit</Trans>}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              color={COLORS.ICONS_PRIMARY}
            />
          </SectionHeader>
          <RecommendedCareerPathTable formMethods={formMethods} />
        </SummarySection>
      </Card>
      <Form>
        <Title>
          <Trans>Final check</Trans>
        </Title>
        <Subtitle>
          <Trans>
            Congratulations! You have successfully created a new job. Take a moment to review the
            job details below. Once you are satisfied, you can proceed to publish the job and start
            attracting qualified candidates.
          </Trans>
        </Subtitle>
        <Footer>
          <Button
            type="button"
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.SECONDARY}
            label={<Trans>Save as draft</Trans>}
            onClick={handleSubmit(onSave, onFail)}
            isLoading={isSaveLoading}
            disabled={isSaveLoading}
          />
          <Button
            type="button"
            size={ButtonSize.BIG}
            variant={ButtonVariant.PRIMARY}
            label={<Trans>Publish job</Trans>}
            onClick={handleSubmit(onPublish, onFail)}
            isLoading={isPublishLoading}
            disabled={isPublishLoading}
          />
        </Footer>
      </Form>
    </Container>
  );
}

export { StepSummary };
