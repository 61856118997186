import React, { useMemo } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import AutocompleteFilter from '~/components/AutocompleteFilter';
import { Dropdown } from '~/components/Dropdown';

import {
  SURVEY_THEME_STATUS_WITH_NAMES,
  ISurveyThemesStatus,
  SURVEY_STATUS_WITH_NAMES,
  ISurveyStatus,
} from '~/constants/survey';
import { IUserSurveyStatus, USER_SURVEY_STATUS_WITH_NAMES } from '~/constants/userSurveys';

export const FilterThemeStatus = (props: any) => {
  const { i18n } = useLingui();

  const getStatus = (search: string) => {
    return {
      ...Object.values(SURVEY_THEME_STATUS_WITH_NAMES).filter((i) => {
        return i.translated(i18n).toLowerCase().includes(search.toLowerCase());
      }),
    };
  };

  return (
    <AutocompleteFilter
      placeholder={props.placeholder || <Trans>Status</Trans>}
      fetch={getStatus}
      labelProperty={(i: ISurveyThemesStatus) => (i.translated ? i.translated(i18n) : i.name)}
      labelPropertyReserve={'name'}
      isSingleSelect
      {...props}
    />
  );
};

export const FilterSurveyStatus = ({
  selectedItems,
  onChange,
  type,
}: {
  onChange: (selectedItems: (IUserSurveyStatus | ISurveyStatus)[]) => void;
  type?: string;
  selectedItems: (IUserSurveyStatus | ISurveyStatus)[];
}) => {
  const { i18n } = useLingui();

  const statuses: (IUserSurveyStatus | ISurveyStatus)[] = useMemo(() => {
    return type === 'user-survey'
      ? Object.values(USER_SURVEY_STATUS_WITH_NAMES)
      : Object.values(SURVEY_STATUS_WITH_NAMES);
  }, [type]);

  return (
    <Dropdown
      items={statuses}
      selectedItems={selectedItems}
      placeholder={i18n._(t`Status`)}
      stringifyItem={(item) => item.translated(i18n)}
      onChange={onChange}
    />
  );
};
