import styled from 'styled-components';

import { Button } from '~/components/Buttons';

import { COLORS } from '~/styles';

export const CustomOption = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 446px;
  align-items: center;
  justify-content: space-between;
  padding: 7px 14px;
  box-sizing: border-box;
  background-color: ${COLORS.WHITE};

  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.ICONS_PRIMARY};

  :hover {
    color: ${COLORS.TEXT_HOVER};
    background-color: ${COLORS.HOVER};
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ConnectModalContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  padding-top: 12px;
  border-top: solid 1px ${COLORS.BORDERS};
  gap: 20px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

export const Label = styled.div`
  color: ${COLORS.TEXT_HOVER};
  margin-bottom: 6px;
`;

export const Title = styled.h2`
  font-size: 26px;
  line-height: 1;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin: 0px;
`;

export const StyledButton = styled(Button)`
  top: 5px;
  width: 34px;
  height: 34px;
  padding: 0px;
  margin-right: 7px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  padding: 8px 0;
`;
