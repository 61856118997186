import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import { COLORS } from '~/styles';

import type { IMultiLangString } from '@learned/types';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  position: relative;
  height: fit-content;
  top: 25px;

  & .selected {
    background: ${COLORS.COMPANY};
  }

  & .expected {
    background: color-mix(in srgb, ${COLORS.COMPANY}, transparent 95%);
  }
`;

const ExpectedLabelContainer = styled.div`
  position: absolute;
  top: -40%;
  left: -80%;
  height: 25px;
  border-radius: 6px;
  width: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & span {
    display: inline-block;
    text-align: center;
    padding-top: 10px;
    height: 25px;
    width: 130px;
    background-color: ${COLORS.HOVER};
    color: ${COLORS.LABEL_GRAY};
    font-size: 12px;
  }
`;

const DownwardArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 11px solid ${COLORS.HOVER};
`;

const ExpectedLabel = styled.div`
  width: 100%;
  display: flex;
  border-radius: 6px;
  background-color: ${COLORS.HOVER};
  justify-content: center;

  & > span {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: ${COLORS.SUBTEXT};
  }
`;

const Content = styled.div`
  display: flex;
  height: 50px;
  width: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: ${COLORS.INACTIVE};

  &:hover {
    cursor: pointer;
    background: color-mix(in srgb, ${COLORS.COMPANY}, transparent 60%);
  }
`;

const OpenCircle = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  padding: 5px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: solid 1px ${COLORS.WHITE};
`;

const FilledCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${COLORS.WHITE};
`;

const LabelWrapper = styled.div`
  position: absolute;
  text-align: center;
  left: -50px;
  width: 150px;
`;

const ScoreLabel = styled.span`
  font-size: 12px;
  color: ${COLORS.COMPANY};
`;

const Label = styled.span`
  display: inline-block;
  font-size: 12px;
  width: 150px;
  color: ${COLORS.COMPANY};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface IProps {
  onHover?: () => void;
  onReset?: () => void;
  onChange?: () => void;
  isSelected?: boolean;
  index: number;
  isExpected?: boolean;
  option: {
    label: IMultiLangString;
  };
  options: {
    label: IMultiLangString;
  }[];
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
}

export const SkillQuestionLabelPlaceholder = ({
  onHover,
  onReset,
  onChange,
  isSelected,
  isExpected,
  index,
  option,
  options,
  useMultiLangString,
}: IProps) => {
  const getMultiLangString = useMultiLangString();
  return (
    <Container>
      {isExpected && (
        <ExpectedLabelContainer>
          <ExpectedLabel>
            <span>
              <Trans>Expected level</Trans>
            </span>
          </ExpectedLabel>
          <DownwardArrow />
        </ExpectedLabelContainer>
      )}
      <Tooltip
        disabled={isSelected}
        size={TOOLTIP_SIZES.DEFAULT}
        tooltip={`${index}/${options.length} - ${getMultiLangString(option?.label || {})}`}
      >
        <Wrapper>
          <Content
            className={isSelected ? 'selected' : isExpected ? 'expected' : ''}
            onMouseOver={() => onHover?.()}
            onMouseOut={() => onReset?.()}
            onClick={() => onChange?.()}
          >
            {isSelected && (
              <OpenCircle>
                <FilledCircle />
              </OpenCircle>
            )}
          </Content>
          {(isSelected || isExpected) && (
            <LabelWrapper>
              <ScoreLabel>{`${index}/${options.length}`}</ScoreLabel>
              <Label>{`${getMultiLangString(option?.label || {})} `}</Label>
            </LabelWrapper>
          )}
        </Wrapper>
      </Tooltip>
    </Container>
  );
};
