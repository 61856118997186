import React from 'react';

import { SURVEY_QUESTION_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { QuestionType } from '~/components/SurveyQuestionReport/components/QuestionWrapper/QuestionType';
import Tooltip from '~/components/Tooltip';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ThemeName = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.13px;
  color: ${COLORS.DARK_GRAY};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Separator = styled.div`
  margin: 0 8px;
`;

const AnswersAmount = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.13px;
  color: ${COLORS.DARK_GRAY};
`;

const ThresholdLabel = styled.div`
  border-radius: 6px;
  margin-left: 4px;
  background: ${COLORS.DARK_GRAY};
  color: ${COLORS.WHITE};
  padding: 0 6px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.13px;
`;

interface IHeaderProps {
  type: SURVEY_QUESTION_TYPES;
  themeName: string;
  isAnswersHidden: boolean;
}

const Header = ({ type, themeName, isAnswersHidden }: IHeaderProps) => {
  const { i18n } = useLingui();

  return (
    <Wrapper>
      <Row>
        <AnswersAmount>answers</AnswersAmount>
        {isAnswersHidden && (
          /* @ts-ignore */
          <Tooltip
            tooltip={i18n._(t`Answers will be hidden until anonymity threshold is be met.`)}
            maxWidth="400px"
          >
            <ThresholdLabel>{i18n._(t`Anonymity threshold is not met!`)}</ThresholdLabel>
          </Tooltip>
        )}
      </Row>

      <Row>
        {themeName && (
          <>
            <ThemeName>{themeName}</ThemeName>
            <Separator>|</Separator>
          </>
        )}

        <QuestionType type={type} />
      </Row>
    </Wrapper>
  );
};

export { Header };
