import styled from 'styled-components';

import { COLORS } from '~/styles';
export const EditThemes = styled.button`
  display: flex;
  align-items: center;
  gap: 9px;
  padding: 11px 12px;
  font-size: 12px;
  color: ${COLORS.COMPANY};
  border-top: 1px solid ${COLORS.BORDER_HARD};
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.COMPANY};
    color: ${COLORS.WHITE};
  }
`;
