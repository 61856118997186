export enum LEARNING_LIBRARY_SHOW_FILTER_OPTIONS {
  I_CAN_EDIT = 'i-can-edit',
  NO_EDITORS = 'no-editors',
  ALL_USERS = 'all-users',
}

export enum LEARNING_LIBRARY_SORT_FILTER_OPTIONS {
  LAST_UPDATED = 'last-updated',
  A_Z = 'a-z',
  Z_A = 'z-a',
  NEW_OLD = 'new-old',
  OLD_NEW = 'old-new',
}

export enum LEARNING_REPORT_ACTIVITIES_SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
  PARTICIPANTS_HIGH_LOW = 'PARTICIPANTS_HIGH_LOW',
  PARTICIPANTS_LOW_HIGH = 'PARTICIPANTS_LOW_HIGH',
  AVG_PROGRESS_HIGH_LOW = 'AVG_PROGRESS_HIGH_LOW',
  AVG_PROGRESS_LOW_HIGH = 'AVG_PROGRESS_LOW_HIGH',
  COMPLETED_HIGH_LOW = 'COMPLETED_HIGH_LOW',
  COMPLETED_LOW_HIGH = 'COMPLETED_LOW_HIGH',
}

export enum LEARNING_REPORT_TEAMS_SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
  MEMBERS_HIGH_LOW = 'MEMBERS_HIGH_LOW',
  MEMBERS_LOW_HIGH = 'MEMBERS_LOW_HIGH',
  COMPLETED_AVG_HIGH_LOW = 'COMPLETED_AVG_HIGH_LOW',
  COMPLETED_AVG_LOW_HIGH = 'COMPLETED_AVG_LOW_HIGH',
  COMPLETED_HIGH_LOW = 'COMPLETED_HIGH_LOW',
  COMPLETED_LOW_HIGH = 'COMPLETED_LOW_HIGH',
}

export enum LEARNING_REPORT_MEMBERS_SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
  COMPLETED_HIGH_LOW = 'COMPLETED_HIGH_LOW',
  COMPLETED_LOW_HIGH = 'COMPLETED_LOW_HIGH',
}

export enum LEARNING_REPORT_PATHS_SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
  PARTICIPANTS_HIGH_LOW = 'PARTICIPANTS_HIGH_LOW',
  PARTICIPANTS_LOW_HIGH = 'PARTICIPANTS_LOW_HIGH',
  AVG_HIGH_LOW = 'AVG_HIGH_LOW',
  AVG_LOW_HIGH = 'AVG_LOW_HIGH',
  COMPLETED_HIGH_LOW = 'COMPLETED_HIGH_LOW',
  COMPLETED_LOW_HIGH = 'COMPLETED_LOW_HIGH',
}
