import React from 'react';

import { Trans } from '@lingui/macro';

import { Container, Subtitle, Text } from './OutsideExpired.design';

import Image from '~/assets/3.0/placeholders/traffic-cone.png';

function OutsideExpired() {
  return (
    <Container>
      <Text>
        <Trans>Input is no longer possible</Trans>
      </Text>
      <Subtitle>
        <Trans>
          This review request is canceled or has expired. If you need further assistance, please
          contact the sender.
        </Trans>
      </Subtitle>
      <img src={Image} alt="traffic-cone" height="191px" width="173.3px" />
    </Container>
  );
}

export { OutsideExpired };
