import React, { useEffect } from 'react';

import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { Controller, useForm } from 'react-hook-form';

import Editor from '~/components/Editor';

import { NAOption } from './Components';
import { TextAnswers } from './Components/TextAnswers';
import {
  AllAnswers,
  Answers,
  ContentWrapper,
  QuestionHeader,
  TextLabel,
  TextQuestionWrapper,
} from './design';

import useBoolState from '~/hooks/useBoolState';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';

import { IQuestionDefaultData } from '../../types';

import type { IUserReviewQuestionText } from '@learned/types';

interface IQuestionViewTextProps {
  question: IQuestionDefaultData;
  questionData: IUserReviewQuestionText;
  languageState: ILanguageStateReturn;
  onChange?: (data: {
    questionId: string;
    answer?: string;
    oldAnswer?: number | null;
    comment?: string;
    isNotApplicable?: boolean;
  }) => void;
  onBlur?: () => void;
  defaultValues: IQuestionTextForm;
  canAnswer: boolean;
  hasError?: boolean;
  showOtherRatings?: boolean;
}

type IQuestionTextForm = {
  answer: string;
  isNotApplicable: boolean;
};

const TextAreaQuestion = ({
  question,
  showOtherRatings,
  questionData,
  defaultValues,
  onChange,
  onBlur,
  canAnswer,
  hasError,
}: IQuestionViewTextProps) => {
  const { control, watch } = useForm<IQuestionTextForm>({ defaultValues });

  const notApplicableWatch = watch('isNotApplicable');
  const $isNotApplicable = useBoolState(notApplicableWatch);
  const displayAnswers = !!(
    question.otherCoachRatings?.length ||
    question.otherPeerRatings?.length ||
    question.otherSelfRating
  );

  useEffect(() => {
    $isNotApplicable.set(notApplicableWatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notApplicableWatch]);

  return (
    <TextQuestionWrapper>
      <ContentWrapper className={$isNotApplicable.value ? 'disabled' : ''}>
        {canAnswer && (
          <>
            <QuestionHeader>
              <TextLabel hasError={hasError}>
                <Trans>Your answer</Trans>
              </TextLabel>
              {!questionData?.settings?.isAnswerObligated && (
                <Controller
                  name="isNotApplicable"
                  control={control}
                  render={({ field }) => (
                    <NAOption
                      isChecked={field.value}
                      onChange={(value: boolean) => {
                        field.onChange(value);
                        onChange?.({ questionId: questionData.id, isNotApplicable: value });
                      }}
                    />
                  )}
                />
              )}
            </QuestionHeader>
            <Controller
              name="answer"
              control={control}
              render={({ field }) => (
                <Editor
                  error={hasError}
                  className={$isNotApplicable.value ? 'editor-disabled' : ''}
                  readOnly={$isNotApplicable.value}
                  value={
                    $isNotApplicable.value && field.value === ''
                      ? i18n._(t`Deselect N/A to type an answer.`)
                      : field.value
                  }
                  compact
                  onChange={(value: string) => {
                    if (!$isNotApplicable.value) {
                      field.onChange(value);
                      onChange?.({ questionId: questionData.id, answer: value });
                    }
                  }}
                  onBlur={onBlur}
                  placeholder={i18n._(t`Type here...`)}
                  minHeight="150px"
                  big
                />
              )}
            />
          </>
        )}
        {showOtherRatings && displayAnswers && (
          <AllAnswers>
            <Trans>All answers</Trans>
            <Answers>
              <TextAnswers question={question} />
            </Answers>
          </AllAnswers>
        )}
      </ContentWrapper>
    </TextQuestionWrapper>
  );
};

export { TextAreaQuestion };
