import styled from 'styled-components';

import { Table } from '~/components/Table';

import { COLORS } from '~/styles';

export const StyledTableReviews = styled(Table)`
  border-top: 1px solid ${COLORS.BORDERS};
  padding-top: 14px;

  tr {
    border: none;
  }
`;
