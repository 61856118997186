import React, { Fragment, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import OverviewHeading from '~/components/OverviewHeading';
import Tabs from '~/components/Tabs';
import TabsContainer from '~/components/UI/TabsContainer';
import BaseLayout from '~/layouts/BaseLayout';
import { TimeFrameSelector } from '~/pages/AdminReports/LearningReportPage/TimeFrameSelector';

import { Activities } from './Activities';
import { Members } from './Members';
import { Paths } from './Paths';
import { Teams } from './Teams';
import { TIME_FRAMES } from './TimeFrameSelector';
import { TopKPIs } from './TopKPIs';

import { COLOR_PALETTE } from '~/styles';

const Section = styled.div`
  margin-bottom: 48px;
`;

const TabsWrapper = styled(Tabs)`
  margin-bottom: 0;
  border: none;
`;

const TABS_KEYS = {
  TEAMS: 'teams',
  MEMBERS: 'members',
  ACTIVITIES: 'activities',
  PATHS: 'paths',
};

const LearningReportPage = () => {
  const { i18n } = useLingui();
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(TIME_FRAMES.LAST_YEAR);
  const [currentTab, setCurrentTab] = useState(TABS_KEYS.TEAMS);
  const TABS = {
    [TABS_KEYS.TEAMS]: {
      label: t`Teams`,
      key: TABS_KEYS.TEAMS,
      content: (
        <Teams
          startDate={selectedTimeFrame?.value?.toISOString()}
          timeFrame={selectedTimeFrame.id}
        />
      ),
    },
    [TABS_KEYS.MEMBERS]: {
      label: t`Members`,
      key: TABS_KEYS.MEMBERS,
      content: <Members startDate={selectedTimeFrame?.value?.toISOString()} />,
    },
    [TABS_KEYS.ACTIVITIES]: {
      label: t`Activities`,
      key: TABS_KEYS.ACTIVITIES,
      content: <Activities startDate={selectedTimeFrame?.value?.toISOString()} />,
    },
    [TABS_KEYS.PATHS]: {
      label: t`Paths`,
      key: TABS_KEYS.PATHS,
      content: <Paths startDate={selectedTimeFrame?.value?.toISOString()} />,
    },
  };

  return (
    <Fragment>
      {/* @ts-ignore */}
      <OverviewHeading title={i18n._(t`Learning`)}>
        <TimeFrameSelector
          timeFrameId={selectedTimeFrame.id}
          onChange={(timeFrame) => setSelectedTimeFrame(timeFrame)}
        />
      </OverviewHeading>

      <BaseLayout>
        <Section>
          <TopKPIs startDate={selectedTimeFrame?.value?.toISOString()} />
        </Section>
        {/* @ts-ignore */}
        <TabsContainer noBorder noShadow>
          {/* @ts-ignore */}
          <TabsWrapper
            items={Object.values(TABS)}
            isSmall
            borderColor={COLOR_PALETTE.GRAY_SEMI_SOFT}
            currentItem={currentTab}
            handleChangeTab={(key: keyof typeof TABS_KEYS) => setCurrentTab(key)}
          />
        </TabsContainer>
        {TABS[currentTab].content}
      </BaseLayout>
    </Fragment>
  );
};

export { LearningReportPage };
