import type { IServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export const searchPexels = (query = '', options: { skip?: number; limit?: number } = {}) => {
  const body = {
    query,
    options: {
      skip: options.skip,
      limit: options.limit,
    },
  };

  return cloudRequest(serverRoutes.pexels.searchPexels as IServerRoute, {}, body);
};
