import React, { Component, Fragment } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withToasts } from '~/components/Toast';

import './styles.scss';
import { CareerBlock } from './Sections/CareerBlock';
import { InformationBlock } from './Sections/InformationBlock';
import { IntegrationsBlock } from './Sections/IntegrationsBlock';
import { LanguagesBlock } from './Sections/LanguagesBlock';
import { NotificationBlock } from './Sections/NotificationBlock';
import { SecurityBlock } from './Sections/SecurityBlock';
import { SkillsBlock } from './Sections/SkillsBlock';

import { checkUserIsAllowedToSetNotificationPreferences } from '~/selectors/baseGetters';

class MainDashboard extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.state = {
      avatarUploading: false,
      update: {},
      saved: false,
    };
  }

  componentDidMount() {
    const { scrollToCalendars } = this.props;

    if (scrollToCalendars) {
      this.scrollRef?.current?.scrollIntoView();
    }
  }

  render() {
    const { i18n, user, userIsAllowedToSetNotificationPreferences } = this.props;

    return (
      <Fragment>
        <InformationBlock />
        <CareerBlock title={i18n._(t`Career information`)} userId={user.id} layout="1fr 1fr 10%" />
        <SkillsBlock title={i18n._(t`Skills`)} userId={user.id} />
        <LanguagesBlock />
        {userIsAllowedToSetNotificationPreferences && <NotificationBlock />}
        <SecurityBlock onLogout={this.context.logout} />
        <IntegrationsBlock ref={this.scrollRef} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    userIsAllowedToSetNotificationPreferences:
      checkUserIsAllowedToSetNotificationPreferences(state),
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(withToasts(MainDashboard))));
