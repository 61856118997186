import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import addMonths from 'date-fns/addMonths';
import setDateYear from 'date-fns/setYear';
import subMonths from 'date-fns/subMonths';
import isNil from 'lodash/isNil';
import moment from 'moment';
import PropTypes from 'prop-types';

import { IconOld } from '~/components/IconOld';

import {
  Day,
  Item,
  Items,
  Body,
  DayHeader,
  Header,
  DayInCalendar,
  Mask,
  Mark,
  MonthHeader,
  SelectMonthHeader,
  SelectMonthButton,
  CaretButton,
  YearHeader,
  ClearButton,
  ClearButtonWrapper,
  CaretButtonContainer,
  YearInput,
  StyledDropdownButton,
  Frame,
  MonthInput,
  MonthSelector,
  AbsoluteCarets,
} from './design';

import { LANGUAGES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { regexDateValidator } from '~/utils/dates';
import { formatDateForFilter } from '~/utils/FormatDateForFilter';

function Calendar({
  markedDays = undefined,
  onSelectMonth = undefined,
  className = undefined,
  onSetRange,
  disableBackButton = false,
  preSelectMonth = false,
  initialFrom,
  initialTo,
  externalClear = false,
  changingToDate = false,
  changingFromDate = false,
  externalReset,
  externalResetCalendar,
  externalToFromChange,
  externalFrom,
  externalTo,
  isSingleDate = false,
  $isChangingToDate,
  $isChangingFromDate,
}) {
  const { i18n } = useLingui();
  const isNL = i18n.language === LANGUAGES.NL;
  const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const DAYS_OF_THE_WEEK = isNL
    ? ['M', 'D', 'W', 'D', 'V', 'Z', 'Z']
    : ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  const MONTHS = [
    i18n._(t`January`),
    i18n._(t`February`),
    i18n._(t`March`),
    i18n._(t`April`),
    i18n._(t`May`),
    i18n._(t`June`),
    i18n._(t`July`),
    i18n._(t`August`),
    i18n._(t`September`),
    i18n._(t`October`),
    i18n._(t`November`),
    i18n._(t`December`),
  ];

  const today = useMemo(() => new Date(), []);

  const getStartDayOfMonth = useCallback(
    (date = today) => {
      const start = new Date(date.getFullYear(), date.getMonth(), 1);
      return start.getDay() ? start.getDay() : 7;
    },
    [today],
  );

  const [date, setDate] = useState(today);
  const [day, setDay] = useState(today.getDay());
  const [month, setMonth] = useState(today.getMonth());
  const [year, setYear] = useState(today.getFullYear());
  const [yearString, setYearString] = useState(today.getFullYear().toString());
  const [startDay, setStartDay] = useState(getStartDayOfMonth(date));
  const [markedInCalendar, setMarked] = useState([]);
  const backEnabled = useBoolState(false);
  const [rangeStart, setRangeStart] = useState(initialFrom ? initialFrom : null);
  const [rangeEnd, setRangeEnd] = useState(initialTo ? initialTo : null);
  const [hovered, setHovered] = useState();
  const monthSelected = useBoolState(preSelectMonth);
  const popoverRef = useRef();

  useEffect(() => {
    setDay(date.getDate());
    setMonth(date.getMonth());
    setYear(date.getFullYear());
    setYearString(date.getFullYear().toString());
    setStartDay(getStartDayOfMonth(date));
    if (date > today) {
      backEnabled.on();
    } else {
      backEnabled.off();
    }
  }, [date, backEnabled, getStartDayOfMonth, today]);

  useEffect(() => {
    if (externalReset.value) {
      monthSelected.off();
      changeRange(undefined, undefined);
    }
    if (externalResetCalendar.value) {
      changeDate(today);
      changeRange(changingFromDate ? today : rangeStart, changingToDate ? today : rangeEnd);
      externalResetCalendar.off();
    }
    let newFromDate;
    let newToDate;
    if (
      externalFrom &&
      externalFrom.length === 10 &&
      externalToFromChange.value &&
      externalFrom.match(regexDateValidator)
    ) {
      newFromDate = new Date(
        externalFrom?.substring(6, 10),
        externalFrom?.substring(3, 5) - 1,
        externalFrom?.substring(0, 2),
      );
    }
    if (newFromDate && rangeStart && !compareDates(newFromDate, rangeStart)) {
      changeRange(newFromDate, rangeEnd);
    }
    if (
      externalTo &&
      externalTo.length === 10 &&
      externalToFromChange.value &&
      externalTo.match(regexDateValidator)
    ) {
      const firstIndex = externalTo.indexOf('-');
      const secondIndex = externalTo.lastIndexOf('-');
      newToDate = new Date(
        externalTo?.substring(secondIndex + 1),
        externalTo?.substring(firstIndex + 1, secondIndex) - 1,
        externalTo?.substring(0, firstIndex),
      );
    }
    if (newToDate && rangeEnd && !compareDates(newToDate, rangeEnd)) {
      changeRange(rangeStart, newToDate);
    }
    // eslint-disable-next-line
  }, [externalReset, externalResetCalendar, externalFrom, externalTo]);

  useEffect(() => {
    const marked = [];
    (markedDays ?? []).map((day) => {
      const dayItem = day && new Date(day);
      if (dayItem && dayItem.getMonth() === month && dayItem.getFullYear() === year) {
        marked.push(dayItem.getDate());
      }
    });
    setMarked(marked);
  }, [month, year, markedDays]);

  useEffect(() => {
    onSelectMonth && onSelectMonth(new Date(year, month, 1));
    monthSelected.on();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year]);

  function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  const days = isLeapYear(year) ? DAYS_LEAP : DAYS;

  const changeDate = (date) => {
    setDate(date);
    if (preSelectMonth && onSelectMonth) {
      onSelectMonth(
        new Date(date.getFullYear(), date.getMonth(), 1),
        new Date(date.getFullYear(), date.getMonth() + 1, 0),
      );
    }
  };

  const changeRange = (start, end) => {
    // When selecting a date range and you click the first date, we check if this date is before/after today
    // and autofill the range with today's date
    if (!isSingleDate && isNil(end) && !isNil(start)) {
      const now = new Date();
      if (moment(start).isBefore(now)) {
        end = now;
      } else {
        $isChangingFromDate.off();
        $isChangingToDate.on();
        end = start;
        start = now;
      }
    }

    const rightOrder = !end || moment(start).isBefore(moment(end));
    setRangeStart(rightOrder ? start : end);
    if (!isSingleDate) {
      setRangeEnd(rightOrder ? end : start);
    }
    onSetRange(start ? formatDateForFilter(start) : null, end ? formatDateForFilter(end) : null);
  };

  const setRange = (date) => {
    changeRange(changingFromDate ? date : rangeStart, changingToDate ? date : rangeEnd);
  };

  const compareDates = (dateA, dateB) => {
    return (
      dateA &&
      dateB &&
      dateA.getDate() === dateB.getDate() &&
      dateA.getMonth() === dateB.getMonth() &&
      dateA.getFullYear() === dateB.getFullYear()
    );
  };

  const getHasBorder = (individualDate, isInRange, index, isMarked) => {
    let hasBorder;
    if (
      rangeStart &&
      ((rangeEnd &&
        compareDates(individualDate, rangeStart) &&
        !compareDates(rangeStart, rangeEnd)) ||
        (hovered && compareDates(hovered, individualDate) && hovered < rangeStart) ||
        (hovered && compareDates(individualDate, rangeStart) && hovered > rangeStart) ||
        (isInRange && index % 7 === 0))
    ) {
      hasBorder = 'left';
    }
    if (
      (rangeStart &&
        rangeEnd &&
        compareDates(individualDate, rangeEnd) &&
        !compareDates(rangeStart, rangeEnd)) ||
      (rangeStart &&
        !rangeEnd &&
        hovered &&
        compareDates(hovered, individualDate) &&
        hovered > rangeStart) ||
      (rangeStart &&
        !rangeEnd &&
        hovered &&
        compareDates(individualDate, rangeStart) &&
        hovered < rangeStart) ||
      (isInRange && index % 7 === 6)
    ) {
      hasBorder = hasBorder ? 'both' : 'right';
    }
    if (
      isMarked &&
      ((index % 7 === 6 && hasBorder === 'left') || (index % 7 === 0 && hasBorder === 'right'))
    ) {
      hasBorder = 'both';
    }
    if (hovered && compareDates(hovered, individualDate) && !hasBorder) {
      hasBorder = 'both';
    }
    if (
      hovered &&
      rangeEnd &&
      compareDates(hovered, individualDate) &&
      (rangeEnd < hovered || rangeStart > hovered)
    ) {
      hasBorder = 'both';
    }
    if (
      hovered &&
      rangeEnd &&
      compareDates(hovered, individualDate) &&
      rangeEnd > hovered &&
      rangeStart < hovered &&
      index % 7 !== 6 &&
      index % 7 !== 0
    ) {
      hasBorder = false;
    }

    return hasBorder;
  };

  const renderMenu = () => {
    return (
      <Items>
        {MONTHS.map((month, index) => {
          return (
            <Item
              key={index}
              onClick={() => {
                changeDate(new Date(year, index, day));
                popoverRef.current._tippy.hide();
              }}
              selected={index === date.getMonth()}
            >
              {month}
            </Item>
          );
        })}
      </Items>
    );
  };

  const increaseMonth = (skipYear = false) => {
    if (skipYear) {
      const newDate = addMonths(new Date(year, month, day), 1);
      changeDate(setDateYear(newDate, year));
    } else {
      changeDate(addMonths(new Date(year, month, day), 1));
    }
  };

  const decreaseMonth = (skipYear = false) => {
    if (skipYear) {
      const newDate = subMonths(new Date(year, month, day), 1);
      changeDate(setDateYear(newDate, year));
    } else {
      changeDate(subMonths(new Date(year, month, day), 1));
    }
  };

  const renderButton = () => {
    return <MonthHeader>{MONTHS[month]}</MonthHeader>;
  };

  const CaretButtons = ({ upOnChange, downOnChange }) => {
    return (
      <CaretButtonContainer>
        <CaretButton onClick={upOnChange}>
          <IconOld name="CaretUp" width={8} height={8} />
        </CaretButton>
        <CaretButton onClick={downOnChange}>
          <IconOld name="CaretDown" width={8} height={8} />
        </CaretButton>
      </CaretButtonContainer>
    );
  };

  return (
    <Frame className={className}>
      <Header>
        <SelectMonthHeader>
          <SelectMonthButton
            onClick={() => {
              if (backEnabled.value || !disableBackButton) {
                decreaseMonth();
              }
            }}
          >
            <IconOld name="ChevronBack" width={18} height={18} />
          </SelectMonthButton>
        </SelectMonthHeader>
        <MonthSelector>
          <StyledDropdownButton placement="bottom" popoverRef={popoverRef} content={renderMenu()}>
            <MonthInput>{renderButton()}</MonthInput>
          </StyledDropdownButton>
          <AbsoluteCarets>
            <CaretButtons
              downOnChange={(e) => {
                decreaseMonth(true);
                e.stopPropagation();
              }}
              upOnChange={(e) => {
                increaseMonth(true);
                e.stopPropagation();
              }}
            />
          </AbsoluteCarets>
        </MonthSelector>

        <YearHeader>
          <YearInput
            type="text"
            value={yearString}
            onChange={(e) => {
              // filter out non-numeric characters from value and set as yearString
              const value = e.target.value.replace(/\D/g, '');
              setYearString(value);
              if (value.length === 4) {
                changeDate(new Date(value, month, day));
              }
            }}
            onBlur={() => {
              // if yearString is not valid year, reset to current year
              if (yearString.length !== 4) {
                setYearString(year.toString());
              }
            }}
          />
          <CaretButtons
            downOnChange={() => {
              changeDate(new Date(year - 1, month, day));
              setYearString((year - 1).toString());
            }}
            upOnChange={() => {
              changeDate(new Date(year + 1, month, day));
              setYearString((year + 1).toString());
            }}
          />
        </YearHeader>
        <SelectMonthHeader>
          <SelectMonthButton onClick={() => increaseMonth()}>
            <IconOld name="ChevronForward" width={18} height={18} />
          </SelectMonthButton>
        </SelectMonthHeader>
      </Header>
      <Body
        onMouseLeave={() => {
          setHovered();
        }}
      >
        {DAYS_OF_THE_WEEK.map((d, index) => (
          <Day key={d + index}>
            <DayHeader>{d}</DayHeader>
          </Day>
        ))}
        {Array(days[month] + (42 - days[month]))
          .fill(null)
          .map((_, index) => {
            const d = index - (startDay - 2);
            const individualDate = new Date(year, month, d);
            const isInRange =
              rangeEnd && hovered
                ? individualDate < rangeEnd && individualDate > rangeStart
                : rangeStart &&
                  ((individualDate > rangeStart && individualDate <= hovered) ||
                    (individualDate < rangeStart && individualDate >= hovered) ||
                    (individualDate > rangeStart && individualDate < rangeEnd));
            const isMarked =
              (rangeStart && compareDates(individualDate, rangeStart)) ||
              (rangeEnd && compareDates(individualDate, rangeEnd));
            const isActive =
              (rangeStart && compareDates(individualDate, rangeStart) && changingFromDate) ||
              (rangeEnd && compareDates(individualDate, rangeEnd) && changingToDate);
            const isHovered = hovered && compareDates(individualDate, hovered);
            const hasBorder = getHasBorder(individualDate, isInRange, index, isMarked);
            return (
              <Day
                key={index}
                $currentMonth={d > 0 && d <= days[month]}
                $hasOneOnOne={markedInCalendar.includes(d)}
                onMouseEnter={
                  onSetRange &&
                  (() => {
                    setHovered(new Date(year, month, d));
                  })
                }
                onClick={
                  onSetRange &&
                  (() => {
                    const date =
                      d > 0 && d <= days[month]
                        ? individualDate
                        : d > 0
                        ? new Date(year, month + 1, d - days[month])
                        : new Date(year, month - 1, days[(month + 11) % 12] + d);
                    setRange(date);
                    setHovered();
                  })
                }
                onMouseLeave={() => {
                  setHovered();
                }}
              >
                {!isSingleDate && <Mask $hasBorder={hasBorder} $isHovered={isHovered} />}
                <DayInCalendar
                  $isToday={
                    d === today.getDate() &&
                    month === today.getMonth() &&
                    year === today.getFullYear()
                  }
                  $isMarked={isMarked}
                  $isActive={!isSingleDate && isActive}
                  $hasBorder={!isSingleDate && hasBorder}
                  $isInRange={!isSingleDate && isInRange}
                  $isHovered={isHovered}
                >
                  <>
                    {d > 0 && d <= days[month]
                      ? d
                      : d > 0
                      ? d - days[month]
                      : days[(month + 11) % 12] + d}
                  </>
                  {!!(markedDays ?? []).length && (
                    <Mark $isMarked={isMarked} $hasOneOnOne={markedInCalendar.includes(d)} />
                  )}
                </DayInCalendar>
              </Day>
            );
          })}
      </Body>
      {!externalClear && (onSetRange || onSelectMonth) && (
        <ClearButtonWrapper>
          <ClearButton
            $isActive={!!rangeStart || monthSelected.value}
            onClick={() => {
              monthSelected.off();
              changeRange(null, null);
            }}
          >
            <Trans>Clear</Trans>
          </ClearButton>
        </ClearButtonWrapper>
      )}
    </Frame>
  );
}

Calendar.propTypes = {
  markedDays: PropTypes.array,
  onSelectMonth: PropTypes.func,
  disableBackButton: PropTypes.bool,
  onSetRange: PropTypes.func,
  className: PropTypes.string,
  preSelectMonth: PropTypes.bool,
};

export default Calendar;
