import React, { Dispatch, useState, SetStateAction } from 'react';

import { Trans } from '@lingui/macro';
import { some } from 'lodash';
import size from 'lodash/size';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import { useFieldArray, useWatch } from 'react-hook-form';
import styled from 'styled-components';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { AddQuestionModal } from '~/pages/SurveyTemplateUpdate/components/AddQuestionModal';
import { EditQuestionModal } from '~/pages/SurveyTemplateUpdate/components/EditQuestionModal';
import { ImportQuestionsModal } from '~/pages/SurveyTemplateUpdate/components/ImportQuestionsModal';

import { QuestionsEmpty } from './QuestionsEmpty';
import { QuestionsList } from './QuestionsList';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { turnSurveyQuestionIntoQuestionForm } from '~/utils/turnSurveyQuestionIntoQuestionForm';

import { BackButton, Footer, Form, SubmitButton, Title } from '../design';

import type { IGeneralForm } from '../types';
import type { ISurveyQuestion, IMultiLangString } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

const StyledTitle = styled(Title)`
  margin-bottom: 0;
`;

interface IQuestionsProps {
  setCurrentSection: Dispatch<number>;
  formMethods: UseFormReturn<IGeneralForm>;
  languageState: ILanguageStateReturn;
  questions: Record<ISurveyQuestion['id'], ISurveyQuestion & { themeName?: IMultiLangString }>;
  setQuestions: Dispatch<SetStateAction<Record<string, ISurveyQuestion>>>;
  surveyTemplateId: string;
  refreshTemplate: () => void;
  questionIdToOpen?: string;
  setEditQuestion: (questionId?: string) => void;
  autosave: () => void;
  equalizeWeights: (themeIds?: string[]) => void;
  themeIds: string[];
}

const Questions = ({
  questions,
  languageState,
  formMethods,
  setCurrentSection,
  setQuestions,
  questionIdToOpen,
  surveyTemplateId,
  refreshTemplate,
  setEditQuestion,
  autosave,
  equalizeWeights,
  themeIds,
}: IQuestionsProps) => {
  const { control } = formMethods;

  const onSubmit = () => setCurrentSection(2);
  const questionIdsField = useFieldArray({ name: 'questions', control });
  const [addQuestion, setAddQuestion] = useState<boolean>(false);
  const [importQuestion, setImportQuestion] = useState<boolean>(false);
  const [index, setIndex] = useState<number | undefined>();
  const questionIdsWatch = useWatch({ control, name: 'questions' });
  const editQuestion = questionIdToOpen ? questions[questionIdToOpen] : undefined;

  const createNewQuestion = (index?: number) => {
    setIndex(index);
    setAddQuestion(true);
  };

  const importQuestions = (index?: number) => {
    autosave();
    setIndex(index);
    setImportQuestion(true);
  };

  const reorderQuestions = (oldIndex: number, newIndex: number) => {
    questionIdsField.move(oldIndex, newIndex);
  };

  const addQuestions = (...questions: ISurveyQuestion[]) => {
    const newThemeAdded = some(questions.map((q) => !themeIds.includes(q.theme)));
    if (newThemeAdded) {
      equalizeWeights([...themeIds, ...uniq(questions.map((q) => q.theme))]);
    }

    const questionIds = questions.map((question) => ({ questionId: question.id }));
    const questionsMap = questions.reduce(
      (acc, question) => ({ ...acc, [question.id]: question }),
      {},
    );
    setQuestions((prevState) => ({ ...prevState, ...questionsMap }));
    questionIdsField.insert(
      index !== undefined ? index + 1 : size(questionIdsField.fields),
      questionIds,
    );
    autosave();
  };

  const updateQuestion = (question: ISurveyQuestion) => {
    setQuestions((prevState) => ({
      ...prevState,
      [question.id]: { ...prevState[question.id], ...question },
    }));
  };

  const removeQuestion = (index: number, item: ISurveyQuestion) => {
    questionIdsField.remove(index);
    setQuestions((prevState) => {
      const newState = { ...prevState };
      delete newState[item.id];
      return newState;
    });
    const themeRemoved =
      values(questions)
        .map((q) => q.theme)
        .filter((theme) => theme === item.theme).length === 1;
    if (themeRemoved) {
      equalizeWeights(themeIds.filter((id) => id !== item.theme));
    }
    autosave();
  };

  return (
    <>
      <Form>
        <StyledTitle>
          <Trans>Questions</Trans>
        </StyledTitle>
        {questionIdsField.fields.length === 0 && (
          <QuestionsEmpty createNewQuestion={createNewQuestion} importQuestions={importQuestions} />
        )}
        {questionIdsField.fields.length !== 0 && (
          <QuestionsList
            items={questionIdsField.fields.map((field) => questions[field.questionId])}
            createNewQuestion={createNewQuestion}
            importQuestions={importQuestions}
            languageState={languageState}
            onReorderQuestions={reorderQuestions}
            setEditQuestion={setEditQuestion}
            removeQuestion={removeQuestion}
            autosave={autosave}
          />
        )}
        <Footer>
          <BackButton onClick={() => setCurrentSection(0)}>
            <Icon icon={ICONS.BACK} size={ICON_SIZES.SMALL} />
            <Trans>Back</Trans>
          </BackButton>
          <SubmitButton type="button" onClick={onSubmit}>
            <Trans>Next</Trans>
            <Icon icon={ICONS.NEXT} size={ICON_SIZES.SMALL} />
          </SubmitButton>
        </Footer>
      </Form>

      {addQuestion && (
        <AddQuestionModal
          languageState={languageState}
          onSubmit={(newQuestion) => {
            addQuestions(newQuestion);
            setAddQuestion(false);
          }}
          onClose={() => setAddQuestion(false)}
        />
      )}

      {importQuestion && (
        <ImportQuestionsModal
          questionsToHide={questionIdsWatch.map((field) => field.questionId)}
          languageState={languageState}
          onSubmit={async () => {
            setImportQuestion(false);
            await refreshTemplate();
          }}
          onClose={() => setImportQuestion(false)}
          surveyTemplateId={surveyTemplateId}
          index={index}
          createNewQuestion={createNewQuestion}
        />
      )}
      {editQuestion && (
        <EditQuestionModal
          languageState={languageState}
          itemToEdit={{
            id: editQuestion.id,
            ...turnSurveyQuestionIntoQuestionForm(editQuestion, languageState),
          }}
          onSubmit={(updatedQuestion) => {
            updateQuestion(updatedQuestion);
            setEditQuestion(undefined);
          }}
          onClose={() => setEditQuestion(undefined)}
        />
      )}
    </>
  );
};

export { Questions };
