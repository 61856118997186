import React, { FunctionComponent } from 'react';

import Spinner from '~/components/Spinner';

interface IShowSpinnerIfLoading {
  loading: boolean;
  height?: string;
  type?: 'button-primary' | 'action' | 'square';
  styles?: object;
  children: React.ReactNode;
}

const ShowSpinnerIfLoading: FunctionComponent<IShowSpinnerIfLoading> = (props) =>
  props.loading ? (
    <Spinner height={props.height} type={props.type} styles={props.styles} />
  ) : typeof props.children === 'function' ? (
    props.children()
  ) : (
    props.children
  );

export default React.memo(ShowSpinnerIfLoading);
