import React from 'react';

import Tippy from '@tippyjs/react';
import { groupBy } from 'lodash';
import styled from 'styled-components';

import { COLORS, COLOR_PALETTE } from '~/styles';

import type { TRatingsData } from '.';

export interface ISkillCoverageBarProps {
  title: string;
  items: TRatingsData;
  isSmall?: boolean;
  $maxWidth?: string;
  $backgroundAllProgress?: string;
}

const AllProgress = styled.div<{
  isSmall?: boolean;
  $maxWidth?: string;
  $backgroundColor?: string;
}>`
  display: flex;
  width: 100%;
  max-width: ${(props) => (props.$maxWidth ? props.$maxWidth : props.isSmall ? '283px' : '100%')};
  position: absolute;
  left: 0;
  top: 0;
  height: ${(props) => (props.isSmall ? '8px' : '10px')};
  overflow: hidden;
  box-sizing: border-box;
  border-radius: ${(props) => (props.isSmall ? '4px' : '12.7px')};
  ${(props) => !props.isSmall && `border: solid 1px ${COLORS.BORDER_HARD};`}
  background-color: ${(props) =>
    props.$backgroundColor
      ? props.$backgroundColor
      : props.isSmall
      ? COLOR_PALETTE.WHITE
      : COLORS.BG_PAGE};
`;

const TooltipWrapper = styled.div`
  padding: 7px 4px;

  & .title {
    font-weight: 600;
  }
`;

const TooltipItem = styled.span`
  display: flex;
  align-items: center;
  margin: 8px 0 0;
`;

const TooltipItemPercentage = styled.span`
  font-weight: 600;
  font-size: 14px;
  margin: 0 10px;
`;

const TooltipItemTitle = styled.span`
  font-size: 14px;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const DoneProgress = styled.div<{
  isSmall?: boolean;
  $width: number;
  $isFullWidth?: boolean;
}>`
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  height: ${(props) => (props.isSmall ? '8px' : '10px')};
  width: ${(props) => props.$width}%;
  ${(props) => !props.isSmall && `border: solid 1px ${COLORS.BORDER_HARD};`}
  border-radius: ${(props) => (props.isSmall ? '4px' : '12.7px')};
  background-color: ${COLORS.BORDER_HARD};
  ${(props) =>
    !props.$isFullWidth &&
    `border-top-right-radius: 0;
  border-bottom-right-radius: 0;`}
`;

const DotRow = styled.div<{
  $width: number;
  $left?: number;
}>`
  position: absolute;
  left: ${(props) => (props.$left ? `${props.$left}px` : 0)};
  top: -6px;
  width: ${(props) => props.$width}%;
  text-align: right;
`;

const Dot = styled.span<{
  isSmall?: boolean;
  $color?: string;
}>`
  background-color: ${(props) => props.$color};
  display: inline-block;
  width: ${(props) => (props.isSmall ? '6px' : '8px')};
  height: ${(props) => (props.isSmall ? '19px' : '20px')};
  border-radius: 21px;
  ${(props) => !props.isSmall && 'box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);'}
  -webkit-print-color-adjust: exact;
  filter: opacity(1);
  -webkit-filter: opacity(1);
`;

const Rates = styled.div<{
  $maxWidth?: string;
  isSmall?: boolean;
}>`
  display: flex;
  width: 100%;
  max-width: ${(props) => (props.$maxWidth ? props.$maxWidth : props.isSmall ? '283px' : '100%')};
  position: absolute;
  left: 0;
  top: 0;
`;

const Row = styled.div<{
  isSmall?: boolean;
}>`
  display: flex;
  position: relative;
  ${(props) => props.isSmall && 'margin-top: 9px;'}
`;

const SkillCoverageBar = ({
  title,
  items,
  isSmall,
  $maxWidth,
  $backgroundAllProgress,
}: ISkillCoverageBarProps) => {
  // expected rate doesn't have color
  const rates = Object.values(items).filter((i) => i.color);
  const ratesGroup = groupBy(rates, 'position');
  const ratesWithoutExpectedLevel = rates.map((item) => {
    if (ratesGroup[item.position].length > 1) {
      return {
        ...item,
        $left:
          ratesGroup[item.position].findIndex((i) => i.color === item.color) * (isSmall ? 6 : 8),
      };
    }

    return item;
  });

  const tipOptions = {
    modifiers: [
      {
        name: 'computeStyles',
        options: {
          gpuAcceleration: false,
        },
      },
    ],
  };

  const ProgressBarTooltip = (
    <TooltipWrapper>
      <div className="title">{title}</div>
      {ratesWithoutExpectedLevel.map(
        (item) =>
          item.progress > 0 && (
            <TooltipItem key={Math.random()}>
              <Dot $color={item.color} isSmall={isSmall} />
              <TooltipItemPercentage>{item.progress.toFixed(0)}%</TooltipItemPercentage>
              <TooltipItemTitle>{item.label}</TooltipItemTitle>
            </TooltipItem>
          ),
      )}
    </TooltipWrapper>
  );

  const isTooltipDisabled = ratesWithoutExpectedLevel.reduce((prev, curr) => {
    if (curr.progress) {
      prev = false;
    }

    return prev;
  }, true);

  return (
    <Tippy
      popperOptions={tipOptions}
      theme="light"
      maxWidth={206}
      content={ProgressBarTooltip}
      disabled={!isSmall || isTooltipDisabled}
    >
      <div>
        <Row isSmall={isSmall}>
          <AllProgress
            $maxWidth={$maxWidth}
            $backgroundColor={$backgroundAllProgress}
            isSmall={isSmall}
          >
            {items.avgExpectedLevel && (
              <DoneProgress
                isSmall={isSmall}
                $width={items.avgExpectedLevel.position}
                $isFullWidth={Number(items.avgExpectedLevel.position) === 100}
              />
            )}
          </AllProgress>
          <Rates $maxWidth={$maxWidth} isSmall={isSmall}>
            {ratesWithoutExpectedLevel.map((item, idx) => {
              const width = item?.position;

              return item.position ? (
                <DotRow key={idx} $width={width} $left={item.$left}>
                  <Dot $color={item.color} isSmall={isSmall} />
                </DotRow>
              ) : null;
            })}
          </Rates>
        </Row>
      </div>
    </Tippy>
  );
};

export { SkillCoverageBar };
