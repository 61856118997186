import { LOGS_FIXED_ADMINS } from '~/constants';

export const getAdminValue = (i18n, createdBy) => {
  // Check if it is a fixed user, return its label
  let adminUser = Object.values(LOGS_FIXED_ADMINS).find((admin) => admin.key === createdBy);
  if (adminUser) {
    return adminUser.label(i18n);
  }
  return createdBy;
};
