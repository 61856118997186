import React from 'react';

import { Trans } from '@lingui/macro';

import AutocompleteFilter from '~/components/AutocompleteFilter';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { fetchReviews } from '~/services/reviews/reports/ninegrid';

import type { IReview } from '@learned/types';

const LIMIT = 20;

/**
 *
 * @param props
 * @returns
 */
export const AutoCompleteReviewsFilter = (props: any) => {
  const getMultiLangString = useMultiLangString();

  const autoCompleteFetch = async (search: string) => {
    const { data } = await fetchReviews({
      filters: {
        search,
      },
      options: {
        limit: LIMIT,
        skip: 0,
      },
    });
    return data?.reviews;
  };

  return (
    <AutocompleteFilter
      showTooltip
      labelProperty={(i: IReview) => getMultiLangString(i.name)}
      labelPropertyReserve={'name'}
      placeholder={props.placeholder || <Trans>Review</Trans>}
      fetch={autoCompleteFetch}
      {...props}
    />
  );
};
