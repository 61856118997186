import styled from 'styled-components';

import { COLORS } from '~/styles';

const TableCtr = styled.div`
  display: flex;
`;

const CustomTooltip = styled.div`
  position: absolute;
  left: -5px;
  top: 0;
  transform: translateX(-100%);
  border: 1px solid ${COLORS.BORDERS};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  background: ${COLORS.WHITE};
  display: none;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  font-size: 10px;
  border-radius: 6px;
  z-index: 100;
`;

const LeftCtr = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-width: 200px;
  row-gap: 10px;
`;

const DataRaw = styled.div<{ isActive: boolean; mHeight: string; isClickable?: boolean }>`
  padding: 5px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  background: ${({ isActive }) => (isActive ? `${COLORS.HOVER}` : 'transparent')};
  border-radius: 0 6px 6px 0;
  box-sizing: border-box;
  height: ${({ mHeight }) => mHeight};
`;

const NumberLineBorder = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid ${COLORS.BORDERS};
`;

const RowTitle = styled.div<{ isActive: boolean; mHeight: string; isClickable: boolean }>`
  padding: 5px 40px 5px 10px;
  border-radius: 6px 0 0 6px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: ${({ isActive }) => (isActive ? `${COLORS.HOVER}` : 'transparent')};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 35ch;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  height: ${({ mHeight }) => mHeight};
`;

const RightCtr = styled.div`
  position: relative;
  isolation: isolate;
  flex: auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const PrimaryDimension = styled.div`
  position: absolute;
  left: 10px;
  top: -20px;
  transform: translateY(-100%);
  display: flex;
  gap: 5px;
  align-items: center;
  z-index: 1;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
`;

const SecondaryDimension = styled.div`
  position: absolute;
  left: 0;
  top: -20px;
  transform: translateY(-100%);
  display: flex;
  gap: 5px;
  align-items: center;
  z-index: 1;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
`;

const SortIcons = styled.div`
  display: flex;
  flex-direction: column;
`;

const DataCtr = styled.div<{
  barWidth: string;
  mHeight: string;
  deviation: number;
  isActive: boolean;
}>`
  position: relative;
  width: ${({ barWidth }) => barWidth};
  border-radius: 6px;
  height: calc(${({ mHeight }) => mHeight} - 10px);
  box-sizing: border-box;
  padding: 5px 0;
  &::after {
    content: ${({ deviation }) =>
      deviation === 0 ? '' : deviation > 0 ? `+${deviation}%` : `${deviation}%`};
    color: ${({ deviation }) =>
      deviation > 0 ? COLORS.CONFIRMATION_MODAL_SUCCESS : COLORS.CONFIRMATION_MODAL_DELETE};
    position: absolute;
    right: -10px;
    top: 0;
    transform: translateX(100%);
    height: 100%;
    align-items: center;
    justify-content: center;
    display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
    font-size: 12px;
  }
  &:hover ${CustomTooltip} {
    display: flex;
  }
`;

const ItemNr = styled.div<{ isActive: boolean }>`
  color: ${COLORS.WHITE};
  position: absolute;
  font-size: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;

const DataCtrBackground = styled.div<{ isActive: boolean; bgClr: string }>`
  position: absolute;
  inset: 0;
  background: ${({ bgClr }) => bgClr || COLORS.CAREER};
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  border-radius: 6px;
  overflow: hidden;
`;

const DataMultiCtr = styled.div<{
  barWidth: string;
}>`
  width: ${(props) => props.barWidth};
  height: 20%;
  position: relative;
  border-radius: 20px;
`;

const DataMultiCtrBackground = styled.div<{ isActive: boolean; bgClr: string }>`
  position: absolute;
  inset: 0;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  background: ${({ bgClr }) => bgClr};
  border-radius: 20px;
  overflow: hidden;
  box-sizing: border-box;
`;

const DataMultiOuterCtr = styled.div<{ mHeight: string }>`
  position: relative;
  border-radius: 6px;
  height: calc(${({ mHeight }) => mHeight} - 10px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  width: 100%;
  &:hover ${CustomTooltip} {
    display: flex;
  }
`;

const Numbers = styled.div<{ leftAlign: string }>`
  position: absolute;
  left: ${(props) => props.leftAlign};
  bottom: -20px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  z-index: 1;
  box-sizing: border-box;
  transform: translateX(-50%);
`;

const Line = styled.div<{ leftAlign: string }>`
  box-sizing: border-box;
  position: absolute;
  left: ${(props) => props.leftAlign};
  top: 0;
  bottom: 0;
  border-left: 1px solid ${COLORS.BORDERS};
  z-index: -1;
`;

export {
  TableCtr,
  LeftCtr,
  DataRaw,
  NumberLineBorder,
  RowTitle,
  RightCtr,
  PrimaryDimension,
  SecondaryDimension,
  SortIcons,
  DataCtr,
  ItemNr,
  DataCtrBackground,
  DataMultiCtr,
  DataMultiCtrBackground,
  DataMultiOuterCtr,
  Numbers,
  Line,
};
