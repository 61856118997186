import React, { useState, useCallback } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Tooltip from '~/components/Tooltip';

import RatingBlock from './RatingBlock';

import { COLOR_PALETTE } from '~/styles';

const RateBlock = styled(RatingBlock)``;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  border: ${(props) => (props.$isWrap ? `1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT}` : 'none')};
  border-radius: ${(props) => (props.$isWrap ? '6px' : '')};
  ${RateBlock}:last-child {
    margin-right: 0;
    border-right: ${(props) => (props.$isWrap ? 'none' : '')};
  }
`;

function RatingScaleBlocks({
  scale = [],
  selectedRate,
  onChange,
  blockHeight,
  blockGap,
  readonly,
  isBig,
  isShowToolTip,
  isHighlightLowerRates = true,
  isSingleHover = false,
  scaleLabels = [],
  onChangeHoverLevel,
  expectedRate,
  disabled = false,
}) {
  const [hoverValue, setHoverValue] = useState(expectedRate);

  const onChangeHover = useCallback(
    (hoverLevel) => {
      const selectedLevel = hoverLevel || expectedRate;
      setHoverValue(selectedLevel);
      if (onChangeHoverLevel) {
        onChangeHoverLevel(selectedLevel);
      }
    },
    [setHoverValue, onChangeHoverLevel, expectedRate],
  );

  const isHovered = (r) => {
    if (isSingleHover) {
      return hoverValue === r;
    }
    return hoverValue && (isHighlightLowerRates ? hoverValue >= r : hoverValue === r);
  };

  const isSelected = (r) => {
    if (isSingleHover) {
      return selectedRate === r;
    }
    return isHighlightLowerRates ? selectedRate >= r : selectedRate === r;
  };

  return (
    <Wrapper $isWrap={blockGap === '0px'}>
      {scale.map((r, idx) => {
        const label = scaleLabels[idx];
        const Block = (
          <RateBlock
            rate={r}
            key={r}
            label={label}
            readonly={readonly}
            blockGap={blockGap}
            isHovered={isHovered(r)}
            isSelected={isSelected(r)}
            blockHeight={blockHeight}
            isBig={isBig}
            onChange={onChange}
            setHoverValue={onChangeHover}
            isExpected={expectedRate === r}
            disabled={disabled}
          />
        );

        return isShowToolTip ? <Tooltip tooltip={label}>{Block}</Tooltip> : Block;
      })}
    </Wrapper>
  );
}

RatingScaleBlocks.propTypes = {
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeHoverLevel: PropTypes.func,
};

RatingScaleBlocks.defaultProps = {
  readonly: false,
  onChange: () => {},
  onChangeHoverLevel: () => {},
};

export default React.memo(RatingScaleBlocks);
