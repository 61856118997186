import { REVIEW_THEME_STATUS } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

export interface IReviewThemesStatus {
  id: string;
  key: string;
  name: string;
  translated: (i18n: I18n) => string;
}

export const THEME_STATUS: IReviewThemesStatus[] = [
  {
    id: REVIEW_THEME_STATUS.DRAFT,
    key: REVIEW_THEME_STATUS.DRAFT,
    name: 'Draft',
    translated: (i18n: I18n) => i18n._(t`Draft`),
  },
  {
    id: REVIEW_THEME_STATUS.PUBLISHED,
    key: REVIEW_THEME_STATUS.PUBLISHED,
    name: 'Published',
    translated: (i18n: I18n) => i18n._(t`Published`),
  },
];
