import React from 'react';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const LinkIcon = ({ width, height }) => (
  <InlineSvg width={width} height={height} viewBox="0 0 17 16">
    <path
      d="M13 8.6v4.8a1.6 1.6 0 0 1-1.6 1.6H2.6A1.6 1.6 0 0 1 1 13.4V4.6A1.6 1.6 0 0 1 2.6 3h4.8M12 1h4v4m-9 5 9-9"
      stroke="currentColor"
      strokeWidth="1.2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </InlineSvg>
);

LinkIcon.defaultProps = {
  width: 17,
  height: 17,
};

export default LinkIcon;
