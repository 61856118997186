import { useEffect, useState } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';

import { getJobFamiliesSuperAdmin } from '~/services/jobFamilies';

import type { IJobFamilyNew } from '../types';

const useJobFamilies = () => {
  const [jobFamilies, setJobFamilies] = useState<IJobFamilyNew[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);

    const fetchJobFamilies = async () => {
      const response = await getJobFamiliesSuperAdmin();
      if (mounted) {
        setJobFamilies(Object.values(response.data[API_RETURN_FIELDS.JOB_FAMILIES]));
        setIsLoading(false);
      }
    };

    fetchJobFamilies();

    return () => void (mounted = false);
  }, []);

  return { jobFamilies, isLoading };
};

export { useJobFamilies };
