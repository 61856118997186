import React, { ReactNode } from 'react';

import { REVIEW_QUESTION_TYPES_V1 } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';

import RickTextView from '~/components/RickTextView';

import { AnswerView, Container, Description, HeaderWrapper, ThemeTitle, Title } from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import type { IMultiLangString, IReviewQuestion } from '@learned/types';

interface IQuestionWrapperProps {
  isZeroState: boolean;
  children?: ReactNode;
  questionNumber: number;
  themeName: string;
  question: {
    name: IMultiLangString;
    description?: IMultiLangString;
    type: IReviewQuestion['type'];
  };
}

export const QuestionWrapper = ({
  isZeroState,
  children,
  questionNumber,
  themeName,
  question,
}: IQuestionWrapperProps) => {
  const getMultiLangString = useMultiLangString();
  const { i18n } = useLingui();

  return (
    <Container>
      <HeaderWrapper $isZeroState={isZeroState}>
        <ThemeTitle>{`Question: ${questionNumber} | ${getMultiLangString(themeName)}`}</ThemeTitle>
        <Title>
          {[
            REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1,
            REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1,
            // @ts-ignore
          ].includes(question.type)
            ? i18n._(t`How did you/the employee perform on this skill?`)
            : getMultiLangString(question.name)}
        </Title>
        {!isEmpty(question.description) && (
          <Description>
            <RickTextView html={getMultiLangString(question.description || '')} />
          </Description>
        )}
      </HeaderWrapper>
      <AnswerView>{children}</AnswerView>
    </Container>
  );
};
