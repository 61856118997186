import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import FocusLayout from '~/layouts/FocusLayout';
import PathDashboard from '~/pages/PathDashboard';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('userPaths', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.USER_PATH.routePath(ROLES.USER)}
        exact
        path={routes.USER_PATH.routePath(ROLES.USER)}
        component={PathDashboard}
        allowedRoles={[ROLES.USER]}
        layout={FocusLayout}
      />,
    ],
    coach: [
      <PrivateRoute
        key={routes.USER_PATH_SUPERVISION_USER.routePath(ROLES.COACH)}
        exact
        path={routes.USER_PATH_SUPERVISION_USER.routePath(ROLES.COACH)}
        component={PathDashboard}
        allowedRoles={[ROLES.COACH]}
        layout={FocusLayout}
      />,
    ],
    admin: [
      <PrivateRoute
        key={routes.USER_PATH_SUPERVISION_USER.routePath(ROLES.ADMIN)}
        exact
        path={routes.USER_PATH_SUPERVISION_USER.routePath(ROLES.ADMIN)}
        component={PathDashboard}
        allowedRoles={[ROLES.ADMIN]}
        layout={FocusLayout}
      />,
    ],
  },
  routesPublic: [],
});
