import React from 'react';

import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import styled from 'styled-components';

import { Button, ButtonVariant, ButtonSize } from '~/components/Buttons';

import type { IColumnTable } from '~/@types/table';
import type { IJobProfile } from '~/constants/jobProfiles';
import { COLORS } from '~/styles';

import type { I18n } from '@lingui/core';

const JobName = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  & > span {
    max-width: 600px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const CoverImg = styled.div`
  min-width: 99px;
  width: 99px;
  height: 63px;
  background-color: ${COLORS.BG_PAGE};
  border-radius: 6px 0 0 6px;
  margin-right: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: ${COLORS.COMPANY};
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
`;

const createColumns = ({ previewRole }: { previewRole: (item: IJobProfile) => void }) => {
  return [
    {
      name: (i18n: I18n) => i18n._(t`ROLES`),
      accessor: 'role',
      renderCell: (item) => {
        return (
          <JobName>
            <CoverImg
              style={{ backgroundImage: item.coverImage ? `url(${item.coverImage})` : 'none' }}
            />
            <span>{item.name}</span>
          </JobName>
        );
      },
    },
    {
      name: '',
      accessor: 'button',
      maxWidth: '150px',
      minWidth: '100px',
      renderCell: (item: IJobProfile) => (
        <Button
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          label={i18n._(t`Preview`)}
          onClick={() => previewRole(item)}
        />
      ),
    },
  ] as IColumnTable<IJobProfile>[];
};

export { createColumns };
