import React, { PureComponent } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import CloudUploadIcon from '~/components/Icons/CloudUpload';
import Spinner from '~/components/Spinner';
import UploadArea from '~/components/UploadArea';

import { COLOR_SET } from '~/styles';

const StyledUploadArea = styled(UploadArea)`
  background: #ecedf0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 145px;
  border-radius: 6px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 36px;
  box-sizing: border-box;
`;

const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  text-align: center;
  color: ${COLOR_SET.MIDDLE_GRAY_BLUE};
  width: 210px;
`;

const ImageWrapper = styled.div`
  background: #f6f8fc;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border-radius: 6px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  span {
    color: var(--company-color);
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
  }
`;

class ImageUploadCover extends PureComponent {
  state = {
    loading: false,
  };

  handleStart = () => {
    const { onUploadStart } = this.props;
    this.setState({ loading: true });
    onUploadStart && onUploadStart();
  };

  handleError = () => {
    const { onUploadError } = this.props;
    this.setState({ loading: false });
    onUploadError && onUploadError();
  };

  handleUpload = (data) => {
    this.setState({ loading: false });
    this.props.onUpload(data.url);
  };

  render() {
    const { placeholder, i18n, imageUrl, placeholderNode, readOnly, className, shortName } =
      this.props;
    const { loading } = this.state;
    if (loading) {
      return <Spinner type="button-primary" />;
    }

    if (readOnly) {
      return (
        <ImageWrapper
          style={{
            backgroundImage: imageUrl ? 'url(' + imageUrl + ')' : 'none',
          }}
        >
          {!imageUrl && <span>{shortName}</span>}
        </ImageWrapper>
      );
    }

    return (
      <StyledUploadArea
        className={className}
        onStartUpload={this.handleStart}
        oneError={this.handleError}
        onUpload={this.handleUpload}
        style={{
          backgroundImage: imageUrl ? 'url(' + imageUrl + ')' : 'none',
          height: this.props.height,
        }}
      >
        {!imageUrl &&
          (placeholderNode || (
            <Placeholder>
              <CloudUploadIcon fill="var(--company-color)" size="40px" />
              <Title>{placeholder || i18n._(t`Upload image`)}</Title>
            </Placeholder>
          ))}
      </StyledUploadArea>
    );
  }
}

export default withI18n()(ImageUploadCover);
