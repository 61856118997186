import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { getCompanySettings } from '~/selectors/companySettings';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getSkillCategories } from '~/services/skillCategories';

import type { ISkillCategory } from '@learned/types';

const useSkillCategories = () => {
  const [skillCategories, setSkillCategories] = useState<ISkillCategory[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const company = useSelector(getCurrentCompany);
  const companySettings = useSelector(getCompanySettings);

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);

    const fetchSkillCategories = async () => {
      const response = await getSkillCategories();
      if (mounted) {
        setSkillCategories(
          companySettings.skillCategories.map((id: string) => response[id]).filter(Boolean),
        );
        setIsLoading(false);
      }
    };

    fetchSkillCategories();

    return () => void (mounted = false);
  }, [company.id, companySettings.skillCategories]);

  return { skillCategories, isLoading };
};

export { useSkillCategories };
