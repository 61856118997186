import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import size from 'lodash/size';
import styled from 'styled-components';

import RickTextView from '~/components/RickTextView';
import ShowMore from '~/components/ShowMore';
import { StepSection } from '~/pages/SurveyUpdate/components';
import type { IGeneralForm } from '~/pages/SurveyUpdate/types';

import { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { COLORS } from '~/styles';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import type { UseFormReturn } from 'react-hook-form';

const Wrapper = styled.div``;

const Block = styled.div`
  margin-bottom: 16px;
`;

const Name = styled.div`
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  color: ${COLORS.BLACK};
  margin-bottom: 16px;
`;

const Description = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.BLACK};
`;
interface ISectionNameAndDescription {
  formMethods: UseFormReturn<IGeneralForm>;
  onEdit: () => void;
  languageState: ILanguageStateReturn;
}

const SectionNameAndDescription = ({
  formMethods,
  languageState,
  onEdit,
}: ISectionNameAndDescription) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;
  const multiName = turnArrayIntoMultiLang(watch('name'));
  const multiDescription = turnArrayIntoMultiLang(watch('description'));

  const renderBlock = (field: { locale: string }) => {
    const name = multiName[field.locale];
    const description = multiDescription[field.locale];
    const icon =
      size(languageState.languages) > 1
        ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
        : undefined;
    return (
      <Block key={field.locale}>
        <Name>
          {name}
          {name && icon}
        </Name>
        <Description>
          {/* @ts-ignore*/}
          <ShowMore maxHeight={50}>
            {/* @ts-ignore*/}
            <RickTextView html={description} />
          </ShowMore>
          {description && icon}
        </Description>
      </Block>
    );
  };

  return (
    <StepSection title={i18n._(t`Name and description`)} onEdit={onEdit}>
      <Wrapper>{languageState.languages.map((language) => renderBlock(language))}</Wrapper>
    </StepSection>
  );
};

export { SectionNameAndDescription };
