import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { AutocompleteFilterTeams } from '~/components/AutocompleteFilters';
import Modal from '~/components/Modal';
import { SearchField } from '~/components/Text';
import AvatarCard from '~/components/UI/AvatarCard';
import Divider from '~/components/UI/Divider';

import { getInactiveUsers, getUsers } from '~/selectors/baseGetters';
import { COLOR_PALETTE, COLORS } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';

const UserWrapper = styled.div`
  padding: 12px;
  border: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  &:hover {
    cursor: pointer;
    background-color: ${COLORS.BG_PAGE};
  }
`;

const SearchWrapper = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: row;
`;

const StyledSearchField = styled(SearchField)`
  flex: 1;
  margin-right: 8px;
  height: 40px;
`;

const StyledAutocompleteFilterTeams = styled(AutocompleteFilterTeams)`
  width: 188px;
`;

const UsersContainer = styled.div`
  height: 462px;
  overflow: auto;
`;

const StyledAvatarCard = styled(AvatarCard)`
  cursor: pointer;
`;

const UserDashboardUsersModal = ({
  users,
  title,
  onClose,
  onClick,
  i18n,
  isTeamsSelector = false,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const allUsers = { ...useSelector(getUsers), ...useSelector(getInactiveUsers) };

  useEffect(() => {
    let newMembers = [];
    selectedTeams.forEach((team) => {
      newMembers = newMembers.concat(team.members);
    });
    setTeamMembers(newMembers);
  }, [selectedTeams]);

  const search = (e) => {
    setSearchValue(e.target.value);
  };

  const changeTeam = (e) => setSelectedTeams(e);
  const modalUsers = users.map((u) => (u.id || u.userId ? u : allUsers[u])).filter((u) => u);

  return (
    <Modal
      onClose={onClose}
      contentStyles={{
        padding: 0,
      }}
      width={475}
      hideFooter={true}
      title={title}
    >
      <SearchWrapper>
        <StyledSearchField placeholder={i18n._(t`Search`)} value={searchValue} onChange={search} />
        {isTeamsSelector && (
          <StyledAutocompleteFilterTeams onChange={changeTeam} checkedList={selectedTeams} />
        )}
      </SearchWrapper>
      <Divider />
      <UsersContainer>
        {modalUsers
          .filter((user) => {
            if (
              (selectedTeams.length && !teamMembers.includes(user.userId || user.id)) ||
              !allUsers[user.userId || user.id]
            ) {
              return false;
            }
            return searchValue
              ? getUserFullName(allUsers[user.userId || user.id])
                  .toLowerCase()
                  .indexOf(searchValue.toLowerCase()) >= 0
              : true;
          })
          .sort((a, b) => {
            return getUserFullName(allUsers[a.userId || a.id])
              .toLowerCase()
              .localeCompare(getUserFullName(allUsers[b.userId || b.id]).toLowerCase());
          })
          .map((user) => {
            return (
              <UserWrapper
                onClick={() => (onClick ? onClick(user) : {})}
                key={user.userId || user.id}
              >
                <StyledAvatarCard userId={user.userId || user.id} size={40} />
              </UserWrapper>
            );
          })}
      </UsersContainer>
    </Modal>
  );
};

export default withI18n()(UserDashboardUsersModal);
