import React, { useCallback, useEffect, useState } from 'react';

import { GOAL_SORT_OPTIONS, GOAL_TYPES, NEW_GOAL_ID } from '@learned/constants';
import { I18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Dialog from '~/components/Dialog';
import { HeaderTabs } from '~/components/HeaderTabs';
import { ICONS } from '~/components/Icon';
import { TableList } from '~/components/TableList';

import { PERSONAL_COLUMNS } from './personalColumns';
import { TEAM_COLUMNS } from './teamColumns';

import { usePagination } from '~/hooks/usePagination';
import { getUser, getUsers } from '~/selectors/baseGetters';
import { getAvailableParentGoals } from '~/services/goals';

import {
  ContentWrapper,
  FilterGoalCycles,
  FilterOwners,
  FilterTeams,
  FiltersContainer,
  ItemContainer,
  ItemRow,
  Title,
  FooterSection,
  AddContributeButton as AddButton,
  BackButton,
  StyledTop,
  TableListContainer,
  HeaderRow,
} from '../design';

import type { IGoal, IGoalCycle, ITeam, IUser } from '@learned/types';

interface IProps {
  type: string;
  onClose: () => void;
  onSelectGoal: (goal: IGoal) => void;
}

const tabs = [
  {
    key: GOAL_TYPES.PERSONAL,
    label: (i18n: I18n) => i18n._(t`Personal goal`),
  },
  {
    key: GOAL_TYPES.TEAM,
    label: (i18n: I18n) => i18n._(t`Team goal`),
  },
  {
    key: GOAL_TYPES.COMPANY,
    label: (i18n: I18n) => i18n._(t`Company goal`),
  },
];

const GoalsToContributeModal = ({ type, onClose, onSelectGoal }: IProps) => {
  const [selectedTab, setSelectedTab] = useState(
    type === GOAL_TYPES.TEAM ? GOAL_TYPES.TEAM : GOAL_TYPES.PERSONAL,
  );
  const [selectedTeams, setSelectedTeams] = useState<Array<ITeam>>([]);
  const [selectedOwners, setSelectedOwners] = useState<Array<IUser>>([]);
  const [selectedCycles, setSelectedCycles] = useState<Array<IGoalCycle>>([]);
  const [goals, setGoals] = useState<Array<IGoal>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedGoal, setSelectedGoal] = useState<IGoal>();
  const [sortBy, setSortBy] = useState<GOAL_SORT_OPTIONS>(GOAL_SORT_OPTIONS.NAME_A_Z);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [currentTabs, setCurrentTabs] =
    useState<Array<{ key: GOAL_TYPES; label: (i18n: I18n) => void }>>(tabs);

  const users: Array<IUser> = useSelector(getUsers) || [];
  const currentUser = useSelector(getUser);
  const { pagination, changePagination, resetPagination } = usePagination(10);
  const { i18n } = useLingui();

  const fetchCompanyUsers = (search: string) => {
    return Object.values(users).filter((user: IUser) => {
      const fullName = `${user.firstName}${user.lastName}`.toLowerCase();
      return fullName.includes(search) && fullName !== '';
    });
  };

  const fetchGoalsToContribute = useCallback(async () => {
    setLoading(true);
    const result = await getAvailableParentGoals(NEW_GOAL_ID, {
      type,
      tabType: selectedTab,
      populate: ['teams', 'goalCycles'],
      filters: {
        search,
        teams: isEmpty(selectedTeams) ? undefined : selectedTeams.map((team) => team.id),
        goalCycles: isEmpty(selectedCycles) ? undefined : selectedCycles.map((cycle) => cycle.id),
        owners:
          selectedTab === GOAL_TYPES.PERSONAL
            ? [currentUser.id]
            : isEmpty(selectedOwners)
            ? undefined
            : selectedOwners.map((user) => user.id),
      },
      options: {
        limit: pagination.limit,
        skip: pagination.skip,
        sortBy,
      },
    });
    setGoals(Object.values(result.data.parentGoals) || []);
    setTotalCount(result.data.totalCount || 0);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCycles,
    selectedTab,
    selectedTeams,
    selectedOwners,
    sortBy,
    pagination.limit,
    pagination.skip,
    type,
    search,
  ]);

  const resetFilters = () => {
    setSelectedOwners([]);
    setSelectedCycles([]);
    setSelectedTeams([]);
  };

  useEffect(() => {
    fetchGoalsToContribute();
  }, [selectedTab, selectedCycles, selectedOwners, selectedTeams, fetchGoalsToContribute]);

  useEffect(() => {
    resetPagination();
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, selectedCycles, selectedOwners, selectedTeams]);

  useEffect(() => {
    resetFilters();
  }, [selectedTab]);

  useEffect(() => {
    if (type === GOAL_TYPES.TEAM) {
      setCurrentTabs([tabs[1], tabs[2]]);
      setSelectedTab(GOAL_TYPES.TEAM);
    }
  }, [type]);

  const isFiltersEmpty = () => {
    return (
      selectedCycles.length === 0 &&
      selectedOwners.length === 0 &&
      selectedTeams.length === 0 &&
      search === ''
    );
  };

  return (
    <Dialog borderRadius={10}>
      <ContentWrapper>
        <HeaderRow>
          <Title>
            <Trans>Contributes to goal</Trans>
          </Title>
          <Button
            icon={ICONS.CLOSE}
            variant={ButtonVariant.ICON}
            size={ButtonSize.MEDIUM}
            onClick={onClose}
          />
        </HeaderRow>

        <ItemRow>
          <ItemContainer>
            <StyledTop
              filters={{ search, setSearch }}
              resetFilters={resetFilters}
              filterComponents={
                <FiltersContainer>
                  <FilterGoalCycles
                    /* @ts-ignore */
                    onChange={(selectedItems) => {
                      setSelectedCycles(selectedItems);
                    }}
                    checkedList={selectedCycles}
                    placeholder={i18n._(t`Timeframe`)}
                  />
                  {selectedTab === GOAL_TYPES.TEAM && (
                    <FilterTeams
                      /* @ts-ignore */
                      onChange={(selectedItems) => {
                        setSelectedTeams(selectedItems);
                      }}
                      checkedList={selectedTeams}
                    />
                  )}
                  {selectedTab === GOAL_TYPES.COMPANY && (
                    /* @ts-ignore */
                    <FilterOwners
                      fetch={fetchCompanyUsers}
                      checkedList={selectedOwners}
                      placeholder={<Trans>Owner</Trans>}
                      onChange={(selectedItems: Array<IUser>) => {
                        setSelectedOwners(selectedItems);
                      }}
                      /* @ts-ignore */
                      labelProperty={(user: IUser) => `${user.firstName} ${user.lastName}`}
                    />
                  )}
                </FiltersContainer>
              }
              isToggleHideFilterVisible
            />
          </ItemContainer>
        </ItemRow>

        <ItemRow marginBottom="0px">
          <ItemContainer>
            <HeaderTabs
              tabs={currentTabs}
              handleChangeTab={(key) => setSelectedTab(key as GOAL_TYPES)}
              selectedTab={selectedTab}
            />
          </ItemContainer>
        </ItemRow>

        <TableListContainer>
          <TableList
            isLoading={loading}
            data={goals}
            columns={selectedTab === GOAL_TYPES.TEAM ? TEAM_COLUMNS : PERSONAL_COLUMNS}
            filtersProps={{
              filters: { search, setSearch },
              isFiltered: !isFiltersEmpty(),
            }}
            paginationProps={{
              pagination,
              changePagination,
              totalCount,
              paginationItemLabel: i18n._(t`goals`),
            }}
            isHideTop={true}
            multiSelectProps={{
              isMultiSelectVisible: true,
              isSelectedCountVisible: false,
              isSelectAllVisible: false,
              isSingleSelect: true,
              multiSelect: {
                checkedCount: selectedGoal ? 1 : 0,
                onSelectItem: (item) =>
                  setSelectedGoal((prevState) => {
                    if (prevState?.id === item.id) {
                      return null;
                    }
                    return item;
                  }),
                isItemChecked: (item) => selectedGoal?.id === item.id,
              },
            }}
            placeholderProps={{
              emptyStateText: i18n._(t`No goals found`),
            }}
            sortProps={{
              sortBy,
              setSortBy: (sortOption: GOAL_SORT_OPTIONS) => setSortBy(sortOption),
            }}
          />
        </TableListContainer>
        <FooterSection>
          <BackButton
            variant={ButtonVariant.SECONDARY}
            label={i18n._(t`Cancel`)}
            size={ButtonSize.MEDIUM}
            onClick={onClose}
          />
          <AddButton
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            label={i18n._(t`Add goal`)}
            onClick={() => selectedGoal && onSelectGoal(selectedGoal)}
          />
        </FooterSection>
      </ContentWrapper>
    </Dialog>
  );
};

export { GoalsToContributeModal };
