import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const DuplicateIcon = ({ size, ...props }) => (
  <InlineSvg height={size} width={size} {...props} childrenFirst viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        stroke="currentColor"
        strokeWidth="1.24"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.874 14.375V3.125H5.624"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.24"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.124 5.625h11.25v11.25H3.124z"
      />
    </g>
  </InlineSvg>
);

DuplicateIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

DuplicateIcon.defaultProps = {
  size: 20,
  className: '',
};

export default DuplicateIcon;
