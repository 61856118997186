import React from 'react';

import { ICompany } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '~/components/Button';
import ColorPicker from '~/components/ColorPicker';

import { WrapperPad } from '../design';

const PrimaryColorActions = ({
  saveCompany,
  company,
  handleColorChange,
  isDisabled,
}: {
  company: ICompany;
  saveCompany: (e: React.FormEvent) => Promise<void>;
  handleColorChange: (color: any) => void;
  isDisabled: boolean;
}) => {
  const { i18n } = useLingui();

  return (
    <>
      <WrapperPad>
        <ColorPicker color={company.color} onChange={handleColorChange} />
      </WrapperPad>
      <WrapperPad>
        <Button
          label={i18n._(t`Save`)}
          disabled={isDisabled}
          type="primary-border"
          onClick={saveCompany}
        />
      </WrapperPad>
    </>
  );
};

export { PrimaryColorActions };
