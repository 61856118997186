import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import closeIcon from '~/assets/close-icn.svg';
import waitIcon from '~/assets/conversation-wait.svg';

import { COLOR_PALETTE } from '~/styles';

const BlockTitle = styled.div`
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
`;

const RejectedContent = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin: 0 12px 0 0;
`;

const RejectedText = styled.span`
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 12px;
`;

const BlockWithIcon = ({ isRejected }) => (
  <RejectedContent>
    <StyledSvgIcon
      width="20px"
      height="20px"
      url={isRejected ? closeIcon : waitIcon}
      defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
      isDefaultColor
    />
    <div>
      <BlockTitle>{isRejected ? <Trans>Rejected</Trans> : <Trans>Waiting...</Trans>}</BlockTitle>
      <RejectedText>
        {isRejected ? (
          <Trans>This user has rejected your feedback request</Trans>
        ) : (
          <Trans>This user has not yet answered your request</Trans>
        )}
      </RejectedText>
    </div>
  </RejectedContent>
);

export default React.memo(BlockWithIcon);
