import React, { useState, useEffect } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import Modal from '~/components/Modal';
import SelectUsers from '~/components/SelectUsers';

import useBoolState from '~/hooks/useBoolState';
import * as teamActions from '~/store/teams/actions';
import { COLOR_PALETTE } from '~/styles';

const FooterActions = styled.div`
  display: flex;
`;

const AddPeopleModal = ({ onModalClose, onSubmit, isCoachTasks, title, users, singleChoice }) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const $loading = useBoolState();

  useEffect(() => {
    // we always need relevant teams values
    dispatch(teamActions.getTeams());

    // eslint-disable-next-line
  }, []);

  const onChangeSelectedUsers = (selectedUsers) => {
    setSelectedUsers(selectedUsers);
  };

  const closeModal = () => {
    onModalClose();
  };

  const addUsers = async () => {
    if (!isEmpty(selectedUsers)) {
      $loading.on();
      await onSubmit(selectedUsers);
      $loading.off();

      if (isCoachTasks) {
        return;
      }
    }
    closeModal();
  };

  return (
    <Modal
      title={title}
      onClose={closeModal}
      width={625}
      minWidth={625}
      minHeight="600px"
      contentStyles={{ background: COLOR_PALETTE.WHITE, height: '100%', padding: '0' }}
      hideFooter={false}
      footerCenter={false}
      footerLeft={true}
      footerRight={
        <FooterActions>
          <Button
            styles={{
              height: '40px',
              marginLeft: '10px',
              textTransform: 'uppercase',
              padding: '0px 15px',
              fontSize: '12px',
              minWidth: '120px',
            }}
            label={i18n._(t`Add ${selectedUsers.length || 0} users`)}
            disabled={!(selectedUsers.length || 0)}
            type="white"
            onClick={addUsers}
            loading={$loading.value}
          />
        </FooterActions>
      }
    >
      <div>
        <SelectUsers
          isSelectMode={true}
          users={users}
          selectedUsers={selectedUsers}
          updateSelectedUsers={onChangeSelectedUsers}
          singleChoice={singleChoice}
        />
      </div>
    </Modal>
  );
};

AddPeopleModal.propTypes = {
  onModalClose: PropTypes.func,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  users: PropTypes.array,
  singleChoice: PropTypes.bool,
  isCoachTasks: PropTypes.bool,
};

AddPeopleModal.defaultProps = {
  users: [],
  singleChoice: false,
};

export default AddPeopleModal;
