import React from 'react';

import styled from 'styled-components';

import { COLORS } from '~/styles';

const Wrapper = styled.div``;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.TEXT_MAIN};
  margin-top: 18px;
`;

const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface ListProps {
  title?: string;
  children?: React.ReactNode;
}

const List = ({ title, children }: ListProps) => {
  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Wrapper>
  );
};

export { List };
