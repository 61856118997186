import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const ErrorUrlIcon = ({ size, ...props }) => (
  <InlineSvg height={size} width={size} {...props} childrenFirst>
    <g fill="none" fillRule="evenodd">
      <circle fill="#E93C3C" cx="12" cy="12" r="12" />
      <g stroke="#FFF" strokeLinecap="round" strokeWidth="2">
        <path d="m7.354 16.546 9.192-9.193M7.354 7.354l9.192 9.192" />
      </g>
    </g>
  </InlineSvg>
);

ErrorUrlIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

ErrorUrlIcon.defaultProps = {
  size: 24,
  className: '',
};

export default ErrorUrlIcon;
