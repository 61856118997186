import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function saveCvSettings(body) {
  return cloudRequest(serverRoutes.cvSettings.saveCvSettings, {}, body);
}

export function getCvSettingsByUserId(userId) {
  return cloudRequest(serverRoutes.cvSettings.getCvSettingsByUserId(userId));
}
