import React from 'react';

import { CAREER_PLAN_STATUSES } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';

import { ContextMenu, ContextOption } from '~/components/ContextMenu';
import { ICONS } from '~/components/Icon';
import Label from '~/components/Label';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import type { IColumnTable } from '~/@types/table';
import { COLORS } from '~/styles';

import {
  GoalName,
  JobsTableCell1,
  JobsTableCell3,
  JobsTableCell4,
  JobsTableCell5,
  JobsTableCellText,
  JobsTableCell6,
  JobsTableCell5Text,
} from '../../design';

import type { ICareerPlan } from '@learned/types';
import type { I18n } from '@lingui/core';

export enum SORT_OPTIONS {
  NAME_A_Z = 'name_asc',
  NAME_Z_A = 'name_desc',
  START_DATE_SOON_LATER = 'startDate_asc',
  START_DATE_LATER_SOON = 'startDate_desc',
  END_DATE_SOON_LATER = 'endDate_asc',
  END_DATE_LATER_SOON = 'endDate_desc',
  DURATION_SHORT_LONG = 'duration_asc',
  DURATION_LONG_SHORT = 'duration_desc',
}

const Columns = ({
  onEdit,
  promptDelete,
  onChangePrimaryJob,
  isAdmin,
}: {
  onEdit: (item: ICareerPlan) => void;
  promptDelete: (item: ICareerPlan) => void;
  onChangePrimaryJob: (item: ICareerPlan) => void;
  isAdmin: boolean;
}) => {
  const COLUMNS: IColumnTable<ICareerPlan>[] = [
    {
      name: (i18n: I18n) => i18n._(t`Job`),
      accessor: 'job',
      minWidth: '10px',
      renderCell: (item) => {
        return (
          <JobsTableCell1>
            <ToolTip tooltip={item.name} placement={TOOLTIP_PLACEMENTS.BOTTOM} arrow={false}>
              <GoalName>
                <JobsTableCellText>{item.name}</JobsTableCellText>
                {item.primary && (
                  <Label
                    backgroundColor={COLORS.ACCENT_SUCCESS_LIGHT}
                    color={COLORS.ACCENT_SUCCESS}
                  >{t`Primary`}</Label>
                )}
              </GoalName>
            </ToolTip>
          </JobsTableCell1>
        );
      },
      sortBy: {
        asc: {
          key: SORT_OPTIONS.NAME_A_Z,
          label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
        },
        desc: {
          key: SORT_OPTIONS.NAME_Z_A,
          label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
        },
      },
    },

    {
      name: (i18n: I18n) => i18n._(t`Start date`),
      accessor: 'startDate',
      minWidth: '10px',
      renderCell: (item) => {
        return (
          <JobsTableCell3>
            {item.startDate
              ? format(parse(item.startDate.split('T')[0], 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy')
              : format(parseISO(item.meta.createdDate), 'dd-MM-yyyy')}
          </JobsTableCell3>
        );
      },
      sortBy: {
        asc: {
          key: SORT_OPTIONS.START_DATE_SOON_LATER,
          label: (i18n: I18n) => i18n._(t`Soon - later`),
        },
        desc: {
          key: SORT_OPTIONS.START_DATE_LATER_SOON,
          label: (i18n: I18n) => i18n._(t`Later - soon`),
        },
      },
    },
    {
      name: (i18n: I18n) => i18n._(t`End date`),
      accessor: 'endDate',
      minWidth: '10px',
      renderCell: (item) => {
        return (
          <JobsTableCell4>
            {item.endDate
              ? format(parse(item.endDate.split('T')[0], 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy')
              : 'N/A'}
          </JobsTableCell4>
        );
      },
      sortBy: {
        asc: {
          key: SORT_OPTIONS.END_DATE_SOON_LATER,
          label: (i18n: I18n) => i18n._(t`Soon - later`),
        },
        desc: {
          key: SORT_OPTIONS.END_DATE_LATER_SOON,
          label: (i18n: I18n) => i18n._(t`Later - soon`),
        },
      },
    },
    {
      name: (i18n: I18n) => i18n._(t`Duration`),
      accessor: 'duration',
      minWidth: '10px',
      renderCell: (item) => {
        return (
          <JobsTableCell5>
            <JobsTableCell5Text> {item.duration}</JobsTableCell5Text>
          </JobsTableCell5>
        );
      },
      sortBy: {
        asc: {
          key: SORT_OPTIONS.DURATION_SHORT_LONG,
          label: (i18n: I18n) => i18n._(t`Short - long`),
        },
        desc: {
          key: SORT_OPTIONS.DURATION_LONG_SHORT,
          label: (i18n: I18n) => i18n._(t`Long - short`),
        },
      },
    },
    {
      name: '',
      accessor: 'context',
      minWidth: '10px',
      renderCell: (item) => {
        return (
          isAdmin && (
            <JobsTableCell6>
              <ContextMenu className="ctxMenu">
                {item.status === CAREER_PLAN_STATUSES.CURRENT && !item.primary && (
                  <ContextOption action={() => onChangePrimaryJob(item)} icon={ICONS.STAR}>
                    <Trans>Make primary</Trans>
                  </ContextOption>
                )}
                <ContextOption action={() => onEdit(item)} icon={ICONS.EDIT_PENCIL}>
                  <Trans>Edit dates</Trans>
                </ContextOption>
                <ContextOption isWarning action={() => promptDelete(item)} icon={ICONS.DELETE_BIN}>
                  <Trans>Delete</Trans>
                </ContextOption>
              </ContextMenu>
            </JobsTableCell6>
          )
        );
      },
    },
  ];

  return COLUMNS;
};

export { Columns };
