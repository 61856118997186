import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { RT_FEEDBACK_TYPES } from '~/constants';
import { getUsers, getUser } from '~/selectors/baseGetters';
import { COLOR_PALETTE } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';

const NormalTitleText = styled.span`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${(props) =>
    props.isActive ? 'var(--company-color)' : props.$isDone ? '#99a5be' : COLOR_PALETTE.BLACK};
  ${(props) =>
    props.$isTPTitle && {
      fontWeight: '600',
    }};
`;

const BoldTitleText = styled.span`
  font-weight: 600;
`;

const RTFeedbackName = ({
  i18n,
  isOutside = false,
  RTFeedback,
  TPUser,
  isTPTitle,
  isDone,
  isActive,
}) => {
  const users = useSelector(getUsers);
  const user = useSelector(getUser);

  const isAsked =
    !RTFeedback.originalRTFeedback && RTFeedback.type === RT_FEEDBACK_TYPES.ASK_FEEDBACK.key;
  const isAskedFor =
    RTFeedback.originalRTFeedback && RTFeedback.type === RT_FEEDBACK_TYPES.ASK_FEEDBACK.key;
  const isProactiveBy =
    RTFeedback.type === RT_FEEDBACK_TYPES.GIVE_FEEDBACK.key &&
    RTFeedback.createdBy === (TPUser || user.id);
  const isProactiveFor =
    RTFeedback.type === RT_FEEDBACK_TYPES.GIVE_FEEDBACK.key &&
    RTFeedback.createdFor === (TPUser || user.id);

  const RTFeedbackUsersName = isOutside
    ? getUserFullName(RTFeedback.createdBy)
    : isAsked
    ? [
        ...(RTFeedback.users.map((user) => getUserFullName(users[user])) || []),
        ...(RTFeedback.emails || []),
      ]
    : isAskedFor
    ? getUserFullName(users[RTFeedback.createdBy])
    : isProactiveFor
    ? getUserFullName(users[RTFeedback.createdBy])
    : getUserFullName(users[RTFeedback.createdFor]);

  let titleText;
  if (isAsked) {
    titleText =
      TPUser && TPUser !== user.id ? (
        <NormalTitleText $isTPTitle={isTPTitle} $isDone={isDone} isActive={isActive}>
          <BoldTitleText $isDone={isDone} isActive={isActive}>
            {getUserFullName(users[RTFeedback.createdBy])}
          </BoldTitleText>
          {` ${i18n._(t`has asked feedback from`)} `}
          {RTFeedbackUsersName.length > 1 ? (
            RTFeedbackUsersName.map((RTFBUserName) => (
              <>
                {` ${i18n._(t`and`)} `}
                <BoldTitleText $isDone={isDone} isActive={isActive}>
                  {RTFBUserName}
                </BoldTitleText>
              </>
            ))
          ) : (
            <BoldTitleText $isDone={isDone} isActive={isActive}>
              {RTFeedbackUsersName[0]}
            </BoldTitleText>
          )}
        </NormalTitleText>
      ) : (
        <NormalTitleText $isTPTitle={isTPTitle} $isDone={isDone} isActive={isActive}>
          {`${i18n._(t`You have asked feedback from`)} `}
          {RTFeedbackUsersName.length > 1 ? (
            RTFeedbackUsersName.map((RTFBUserName) => (
              <>
                {` ${i18n._(t`and`)} `}
                <BoldTitleText $isDone={isDone} isActive={isActive}>
                  {RTFBUserName}
                </BoldTitleText>
              </>
            ))
          ) : (
            <BoldTitleText $isDone={isDone} isActive={isActive}>
              {RTFeedbackUsersName[0]}
            </BoldTitleText>
          )}
        </NormalTitleText>
      );
    // i18n._(t`You have asked feedback from ${RTFeedbackUsersName[0]}`);
  } else if (isProactiveFor) {
    titleText =
      TPUser && TPUser !== user.id ? (
        <NormalTitleText $isTPTitle={isTPTitle} $isDone={isDone} isActive={isActive}>
          <BoldTitleText $isDone={isDone} isActive={isActive}>
            {RTFeedbackUsersName}
          </BoldTitleText>
          {` ${i18n._(t`has provided`)} `}
          <BoldTitleText $isDone={isDone} isActive={isActive}>
            {getUserFullName(users[TPUser])}
          </BoldTitleText>
          {` ${i18n._(t`with feedback`)}`}
        </NormalTitleText>
      ) : (
        <NormalTitleText $isTPTitle={isTPTitle} $isDone={isDone} isActive={isActive}>
          <BoldTitleText $isDone={isDone} isActive={isActive}>
            {RTFeedbackUsersName}
          </BoldTitleText>
          {` ${i18n._(t`has provided you with feedback`)}`}
        </NormalTitleText>
      );
  } else if (isProactiveBy) {
    titleText =
      TPUser && TPUser !== user.id ? (
        <NormalTitleText $isTPTitle={isTPTitle} $isDone={isDone} isActive={isActive}>
          <BoldTitleText $isDone={isDone} isActive={isActive}>
            {getUserFullName(users[TPUser])}
          </BoldTitleText>
          {` ${i18n._(t`has provided feedback to`)} `}
          <BoldTitleText $isDone={isDone} isActive={isActive}>
            {RTFeedbackUsersName}
          </BoldTitleText>
        </NormalTitleText>
      ) : (
        <NormalTitleText $isTPTitle={isTPTitle} $isDone={isDone} isActive={isActive}>
          {`${i18n._(t`You have provided feedback to`)} `}
          <BoldTitleText $isDone={isDone} isActive={isActive}>
            {RTFeedbackUsersName}
          </BoldTitleText>
        </NormalTitleText>
      );
  } else {
    titleText =
      TPUser && TPUser !== user.id ? (
        <NormalTitleText $isTPTitle={isTPTitle} $isDone={isDone} isActive={isActive}>
          <BoldTitleText $isDone={isDone} isActive={isActive}>
            {RTFeedbackUsersName}
          </BoldTitleText>
          {` ${i18n._(t` has asked`)} `}
          <BoldTitleText $isDone={isDone} isActive={isActive}>
            {getUserFullName(users[TPUser])}
          </BoldTitleText>
          {` ${i18n._(t` for feedback`)} `}
        </NormalTitleText>
      ) : (
        <NormalTitleText $isTPTitle={isTPTitle} $isDone={isDone} isActive={isActive}>
          <BoldTitleText $isDone={isDone} isActive={isActive}>
            {RTFeedbackUsersName}
          </BoldTitleText>
          {` ${i18n._(t` has asked you for feedback`)}`}
        </NormalTitleText>
      );
  }

  return titleText;
};

export default React.memo(withI18n()(RTFeedbackName));
