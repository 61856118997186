import React, { useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import ExpandMoreIcon from '~/components/Icons/ArrowButtonTall';
import { FeedbackItem } from '~/components/Modals/SkillFullInfoModal/components/FeedbackItem';
import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';

import { InfoBlock, PlaceholderWrapper, Title } from './styledComponents';

import ConversationIcon from '~/assets/main-menu-icons/conversation.svg';

import useBoolState from '~/hooks/useBoolState';
import { COLOR_PALETTE } from '~/styles';

const FeedbackItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
`;

const ShowMoreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 11px;
`;

const ShowMore = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: #1e0863;
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
`;

const FeedbackBlock = ({ skillRatings, skillRatingsTotal, expectedLevel, getAllRatings }) => {
  const { i18n } = useLingui();
  const [isShowMore, setIsShowMore] = useState(true);
  const $loading = useBoolState();
  const $isExpanded = useBoolState(true);

  return (
    <InfoBlock>
      <Header onClick={() => ($isExpanded.value ? $isExpanded.off() : $isExpanded.on())}>
        <Title>
          <Trans>Ratings</Trans>
        </Title>
        {$isExpanded.value ? (
          <ExpandMoreIcon size={14} style={{ transform: 'rotate(180deg)' }} />
        ) : (
          <ExpandMoreIcon size={14} />
        )}
      </Header>
      {$isExpanded.value && (
        <>
          {!isEmpty(skillRatings) && (
            <>
              <FeedbackItems>
                {Object.values(skillRatings).map((rating) => (
                  <FeedbackItem key={rating.id} rating={rating} expectedLevel={expectedLevel} />
                ))}
              </FeedbackItems>
              {isShowMore && skillRatingsTotal > 2 && (
                <ShowMoreWrapper>
                  <ShowSpinnerIfLoading type="button-primary" loading={$loading.value}>
                    <ShowMore
                      onClick={async () => {
                        $loading.on();
                        await getAllRatings();
                        setIsShowMore(false);
                        $loading.off();
                      }}
                    >
                      <Trans>Show more</Trans>
                    </ShowMore>
                  </ShowSpinnerIfLoading>
                </ShowMoreWrapper>
              )}
            </>
          )}
          {isEmpty(skillRatings) && (
            <PlaceholderWrapper>
              <Placeholder
                Icon={() => (
                  <SvgIcon
                    url={ConversationIcon}
                    width="32px"
                    height="32px"
                    isDefaultColor
                    defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                  />
                )}
                title={i18n._(t`No feedback yet`)}
                subTitle={i18n._(t`You did not receive any feedback for this skill`)}
              />
            </PlaceholderWrapper>
          )}
        </>
      )}
    </InfoBlock>
  );
};

export { FeedbackBlock };
