import { COLORS } from '~/styles';

/**
 * Converts a hex color code to an RGBA color string with the specified opacity.
 *
 * @param {string} hexColor - The hex color code (e.g., '#RRGGBB').
 * @param {number} [opacity=0.01] - The opacity value for the RGBA color (default is 0.01).
 * @returns {string} The RGBA color string (e.g., 'rgba(255, 255, 255, 0.01)').
 */
const getRgba = (hexColor: string, opacity = 0.01) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor) || [];
  if (result.length < 4) {
    return hexColor;
  }
  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const contrastColors = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex) || [];
  if (result.length < 4) {
    return COLORS.TEXT_HOVER;
  }
  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  const textColor = luminance < 128 ? COLORS.WHITE : COLORS.TEXT_HOVER;
  return textColor;
};

const getLaminatedColor = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex) || [];
  if (result.length < 4) {
    return hex;
  }
  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);
  const lighten = (color: number) => Math.min(255, Math.floor(color + 255 * 0.25));

  return `rgb(${lighten(r)}, ${lighten(g)}, ${lighten(b)})`;
};

export { getRgba, contrastColors, getLaminatedColor };
