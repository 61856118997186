import React from 'react';

import { Trans } from '@lingui/macro';
import Tippy from '@tippyjs/react';
import styled, { css } from 'styled-components';

import { GoogleMeet } from '~/components/DigitalMeeting/components/GoogleMeet';
import { MicrosoftTeams } from '~/components/DigitalMeeting/components/MicrosoftTeams';
import Switch from '~/components/Switch';

import { USER_INTEGRATION_TYPES } from '~/constants';

import type { TippyProps } from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SwitchContainer = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  ${({ restricted }: any) =>
    restricted &&
    css`
      max-width: fit-content;
      cursor: not-allowed;
      filter: grayscale(0.8);
    `}
`;

const tippyOpts = { modifiers: [{ name: 'computeStyles', options: { gpuAcceleration: false } }] };

function DigitalMeeting({
  className,
  onChange,
  checked,
  calendarType,
  disabled,
  tippyProps = { trigger: 'mouseenter', theme: 'light', popperOptions: tippyOpts, disabled: true },
}: {
  className?: string;
  onChange: (_value: boolean) => void;
  checked: boolean;
  calendarType: keyof typeof USER_INTEGRATION_TYPES;
  disabled?: boolean;
  tippyProps?: TippyProps;
}) {
  return (
    <Container className={className}>
      <Trans>Digital Meeting</Trans>
      <Tippy {...tippyProps} disabled={!tippyProps.content || tippyProps.disabled}>
        <SwitchContainer restricted={disabled}>
          <Switch onChange={onChange} checked={checked} disabled={disabled} />
          {calendarType === USER_INTEGRATION_TYPES.google && <GoogleMeet />}
          {calendarType === USER_INTEGRATION_TYPES.azure && <MicrosoftTeams />}
        </SwitchContainer>
      </Tippy>
    </Container>
  );
}

export { DigitalMeeting };
