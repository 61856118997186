import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import MenuIcon from '~/components/Icons/Menu';
import { Header7 } from '~/components/UI/Typographics/headers';

import Footer from './components/Footer';
import { TemplateCardWithFooter, CardContent } from './components/TemplateBaseComponents';

import { COLORS, COLOR_PALETTE } from '~/styles';

const HeaderTitle = styled(Header7)`
  text-transform: uppercase;
  margin: 0 0 12px 0;
  color: ${COLORS.TEXT_SECONDARY};
`;

const DragBlock = styled.div`
  display: flex;
  height: 24px;
  justify-content: center;
`;

const SetupCard = ({
  title,
  handleUpdate,
  handleDelete,
  footerRightActions,
  footerLeftActions,
  isActive,
  onClick,
  dragProps,
  error,
  cropBorder = false,
  children,
  hideFooter = false,
  removeTopLeftRadius = false,
  tooltip,
  copyItem,
  copyTooltip,
  isSelected,
}) => (
  <TemplateCardWithFooter
    $isActive={isActive}
    onClick={onClick}
    $error={error}
    $isSelected={isSelected}
    $cropBorder={cropBorder}
    $removeTopLeftRadius={removeTopLeftRadius}
  >
    <CardContent $isActive={isActive}>
      {dragProps && (
        <DragBlock {...dragProps}>
          <MenuIcon fill={COLOR_PALETTE.GRAY_MIDDLE} />
        </DragBlock>
      )}

      {title ? <HeaderTitle>{title}</HeaderTitle> : null}
      {children}
    </CardContent>
    {!hideFooter && (
      <Footer
        tooltip={tooltip}
        copyItem={copyItem}
        removeItem={handleDelete}
        updateItem={handleUpdate}
        isActive={isActive}
        footerRightActions={footerRightActions}
        footerLeftActions={footerLeftActions}
        copyTooltip={copyTooltip}
      />
    )}
  </TemplateCardWithFooter>
);

SetupCard.propTypes = {
  footerRightActions: PropTypes.node,
  footerLeftActions: PropTypes.node,
  handleDelete: PropTypes.func,
  handleUpdate: PropTypes.func,
  isActive: PropTypes.bool,
};

export default React.memo(SetupCard);
