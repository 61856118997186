import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { COLOR_PALETTE, COLORS } from '~/styles';

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${(props) => props.$color};
  background-color: ${(props) =>
    props.$backgroundColor ? props.$backgroundColor : COLORS.BG_PAGE};
  padding: 2px 12px;
  position: relative;
  border-radius: 3px;
  margin-left: 22px;
  ${(props) => props.isInline && 'display: inline-block;'}
  &:before {
    position: absolute;
    content: '';
    border-style: solid;
    border-width: 6px 6px 6px 0;
    border-color: transparent
      ${(props) => (props.$backgroundColor ? props.$backgroundColor : COLORS.BG_PAGE)} transparent
      transparent;
    position: absolute;
    left: -6px;
    top: 6px;
    width: 0;
    height: 0;
  }
`;

const RoleLabel = ({ value, color, backgroundColor, children, isInline }) => {
  return (
    <Label $backgroundColor={backgroundColor} $color={color} isInline={isInline}>
      {value}
      {children}
    </Label>
  );
};

RoleLabel.propTypes = {
  value: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  isInline: PropTypes.bool,
};

RoleLabel.default = {
  backgroundColor: COLORS.BG_PAGE,
  color: COLOR_PALETTE.DARK_GRAY,
};

export default RoleLabel;
