import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Loader } from '~/components/Buttons/components/Loader';
import DashboardLineGraph from '~/components/Graphs/custom/DashboardLineGraph';
import { ICONS, ICON_SIZES } from '~/components/Icon';

import {
  ToolTipCtr,
  Highlight,
  ChartContainer,
  Title,
  LoaderCtr,
  WaveCtr,
  AverageCtr,
  ChangeCtr,
  Average,
  BarContainer,
  BarCtr,
  BarData,
  BackgroundCtr,
  BarTitle,
  Value,
  IconCtr,
  CustomIcon,
  TooltipWrapper,
  NoDataSpan,
} from './CardStyles';

import noResult from '~/assets/no-result.webp';

import { COLORS } from '~/styles';
import { toFixed } from '~/utils/math';

export enum ETypes {
  WAVE_CHART = 'chart',
  BAR_CHART = 'bar',
}

export enum ETrend {
  UP = 'up',
  DOWN = 'down',
  NO_CHANGE = 'noChange',
}

export type TBarChart = {
  title: string;
  value: number | string;
  rlc?: number;
  trend?: ETrend;
  deviation?: number;
};

export type TChartData = { key: string; value: number; deviation: number };

export type TScores = {
  bestScore: TBarChart[];
  lowestScore: TBarChart[];
};

type TProps = {
  title?: string;
  type?: ETypes;
  data?: TBarChart[];
  oppositeGradient?: boolean;
  bColor?: string;
  chartData?: TChartData[];
  deviation?: number;
  average?: number;
  isLoading?: boolean;
  setBarBgColor?: (item: TBarChart) => string;
};

function Card({
  title,
  type = ETypes.WAVE_CHART,
  data,
  oppositeGradient = false,
  bColor = COLORS.ACCENT_SUCCESS,
  chartData,
  deviation = 0,
  average = 0,
  isLoading = false,
  setBarBgColor,
}: TProps): JSX.Element {
  const { i18n } = useLingui();
  const newChartData =
    chartData && chartData.length === 1 ? [...chartData, { ...chartData[0] }] : chartData;

  const noDataText = i18n._(t`There is no data available`);
  const getToolTip = (dev: number) => {
    const devVal = toFixed(dev, 2);
    if (devVal === 0) {
      return null;
    }
    return (
      <ToolTipCtr>
        <Highlight color={devVal > 0 ? COLORS.ACCENT_SUCCESS : COLORS.ACCENT_ERROR}>
          {`${devVal > 0 ? '+' : '-'} ${Math.abs(devVal)}`}
          <span>%</span>
        </Highlight>
        {i18n._(t`during the selected timeframe`)}
      </ToolTipCtr>
    );
  };

  if (type === ETypes.WAVE_CHART) {
    return (
      <ChartContainer>
        <Title>{title}</Title>
        {isLoading ? (
          <LoaderCtr>
            <NoDataSpan>
              <Loader />
            </NoDataSpan>
          </LoaderCtr>
        ) : (
          <>
            {newChartData && newChartData.length === 0 ? (
              <LoaderCtr>
                <img src={noResult} alt={i18n._(t`No result`)} width="50%" />
                <NoDataSpan>{noDataText}</NoDataSpan>
              </LoaderCtr>
            ) : (
              <WaveCtr>
                <DashboardLineGraph chartData={newChartData as TChartData[]} />
                {(average > 0 || Number(deviation) !== 0) && (
                  <AverageCtr>
                    {average > 0 && <Average>{toFixed(average, 2)}%</Average>}
                    {Number(deviation) !== 0 && (
                      <ChangeCtr positive={deviation > 0}>
                        {deviation > 0 ? '+' : '-'}
                        {Math.abs(toFixed(deviation, 2))}%
                      </ChangeCtr>
                    )}
                  </AverageCtr>
                )}
              </WaveCtr>
            )}
          </>
        )}
      </ChartContainer>
    );
  }

  return (
    <BarContainer>
      <Title>{title}</Title>
      {isLoading ? (
        <LoaderCtr>
          <NoDataSpan>
            <Loader />
          </NoDataSpan>
        </LoaderCtr>
      ) : (
        <>
          {data && data.length === 0 ? (
            <LoaderCtr>
              <img src={noResult} alt={i18n._(t`No result`)} width="50%" />
              <NoDataSpan>{noDataText}</NoDataSpan>
            </LoaderCtr>
          ) : (
            <BarCtr>
              {data?.map((item, i) => (
                <BarData key={`bar-${i + 1}`} oppositeGradient={oppositeGradient}>
                  <BackgroundCtr
                    className={setBarBgColor ? '' : 'bgCtr'}
                    bColor={setBarBgColor ? setBarBgColor(item) : bColor}
                  />
                  <BarTitle>{item.title}</BarTitle>
                  {Number(item?.deviation) === 0 ? (
                    <Value trend={item.trend && item.trend !== ETrend.NO_CHANGE}>
                      {item.trend && item.trend !== ETrend.NO_CHANGE && (
                        <IconCtr trend={item.trend}>
                          <CustomIcon icon={ICONS.NEXT} size={ICON_SIZES.SMALL} />
                        </IconCtr>
                      )}
                      {item.rlc ? `${item.value}/${item.rlc}` : `${item.value}%`}
                    </Value>
                  ) : (
                    <TooltipWrapper tooltip={getToolTip(item?.deviation || 0)} maxWidth={'180px'}>
                      <Value trend={item.trend && item.trend !== ETrend.NO_CHANGE}>
                        {item.trend && item.trend !== ETrend.NO_CHANGE && (
                          <IconCtr trend={item.trend}>
                            <CustomIcon icon={ICONS.NEXT} size={ICON_SIZES.SMALL} />
                          </IconCtr>
                        )}
                        {item.rlc ? `${item.value}/${item.rlc}` : `${item.value}%`}
                      </Value>
                    </TooltipWrapper>
                  )}
                </BarData>
              ))}
            </BarCtr>
          )}
        </>
      )}
    </BarContainer>
  );
}

export default Card;
