import React from 'react';

import useScript from '~/hooks/useScript';

const innerHTML = `
  // This will initiate Upscope connection. It's important it is added to all pages, even when the user is not logged in.
  (function(w, u, d){var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};var l = function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://code.upscope.io/Qva9uxyT9N.js';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(typeof u!=="function"){w.Upscope=i;l();}})(window, window.Upscope, document);

  Upscope('init');
`;

// Upscope connection
// Upscope - intercom plugin to share screen
const IntercomUpscope = () => {
  useScript({
    type: 'text/javascript',
    innerHTML,
  });

  return <div className="intercom-upscope" />;
};

export default IntercomUpscope;
