import React from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import AddSkillModalNew from '~/components/AddSkillModalNew';
import Button from '~/components/Button';
import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';
import { FieldTitle } from '~/components/Text';

import SkillItem from './SkillItem';

import SkillsIcon from '~/assets/mdi-tag-multiple.svg';

import useBoolState from '~/hooks/useBoolState';
import { COLORS, COLOR_PALETTE } from '~/styles';

const LabelSkills = styled.div`
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

const Skills = styled.div`
  display: flex;
  margin-top: 8px;
  flex-wrap: wrap;
`;

const FieldGroup = styled.div`
  margin-bottom: 24px;
`;

const SkillRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const SkillsPicker = ({ skills, i18n, onSkillsChange }) => {
  const $addSkillsModal = useBoolState();

  return (
    <>
      <FieldGroup>
        <SkillRow>
          <FieldTitle>
            <Trans>Top skills</Trans>
          </FieldTitle>
          <Button label={i18n._(t`Add skills`)} onClick={$addSkillsModal.on} />
        </SkillRow>
        <LabelSkills>
          <Trans>
            These skills will be added to your skill profile and increase your match with career
            opportunities
          </Trans>
        </LabelSkills>
        {skills.length > 0 ? (
          <Skills>
            {skills.map((skill) => (
              <SkillItem key={skill.id} skill={skill} />
            ))}
          </Skills>
        ) : (
          <Placeholder
            Icon={() => (
              <SvgIcon
                url={SkillsIcon}
                width={'50px'}
                height={'50px'}
                isDefaultColor
                defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
              />
            )}
            title={<Trans>No skills</Trans>}
            subTitle={<Trans>Please add skills</Trans>}
          />
        )}
      </FieldGroup>
      {$addSkillsModal.value && (
        <AddSkillModalNew
          selectedSkills={skills}
          withoutLevels
          canSaveEmpty
          onAddSkill={({ selectedSkills }) => onSkillsChange(selectedSkills)}
          onModalClose={$addSkillsModal.off}
          hiddenSkills={Object.values(skills)}
        />
      )}
    </>
  );
};

export default React.memo(withI18n()(SkillsPicker));
