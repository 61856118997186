import { SKILL_TEMPLATES_PENDING_SORT_OPTIONS } from '@learned/constants';

import type { ICreateServerRoute, IServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function listSkillTemplatesPendingSuperAdmin(
  filters: { search?: string },
  options: { skip: number; limit: number; sortBy: SKILL_TEMPLATES_PENDING_SORT_OPTIONS },
) {
  const body = {
    filters: {
      search: filters.search,
    },
    options: {
      skip: options.skip,
      limit: options.limit,
      sortBy: options.sortBy,
    },
  };
  return cloudRequest(serverRoutes.skillTemplatesPending.listSuperAdmin as IServerRoute, {}, body);
}

export function deleteSkillTemplatesPendingSuperAdmin(skillTemplatePendingIds: string[]) {
  return cloudRequest(
    serverRoutes.skillTemplatesPending.deleteSuperAdmin as IServerRoute,
    {},
    { skillTemplatePendingIds },
  );
}

export function getSkillTemplatesPendingCountSuperAdmin() {
  return cloudRequest(serverRoutes.skillTemplatesPending.countSuperAdmin as IServerRoute, {});
}

export function getSkillTemplatePendingSuperAdmin(skillTemplatePendingId: string) {
  return cloudRequest(
    (serverRoutes.skillTemplatesPending.getSuperAdmin as ICreateServerRoute)(
      skillTemplatePendingId,
    ),
  );
}
