import React, { useCallback } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import emptyFn from 'emptyfunction';
import isEmpty from 'lodash/isEmpty';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import IconMenu from '~/components/IconMenu';
import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';
import Avatar from '~/components/UI/Avatar';
import UploadArea from '~/components/UploadArea';

import AttachmentsIcon from '~/assets/icons-32-px-attachments.svg';

import useBoolState from '~/hooks/useBoolState';
import { getUser } from '~/selectors/baseGetters';
import {
  uploadConversationAttachment,
  deleteConversationAttachment,
} from '~/services/userConversations';
import { COLOR_PALETTE } from '~/styles';

const AttachmentRow = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 10px;
  padding: 15px 21px 15px 28px;
  border-radius: 3px;
  border: solid 1px ${COLOR_PALETTE.GRAY_MIDDLE};
  background-color: ${COLOR_PALETTE.WHITE};
`;

const AttachmentName = styled.a`
  flex: 1;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const AvatarWrapper = styled.div`
  margin-right: 20px;
`;

const Attachment = withI18n()(({ file, user, onDelete, i18n, conversationId }) => {
  const $loading = useBoolState();
  const deleteAction = async () => {
    $loading.on();
    await deleteConversationAttachment(file.id, conversationId);
    onDelete(file.id);
  };

  return (
    <AttachmentRow>
      <AttachmentName href={file.url} target="_blank" rel="noopener noreferrer">
        {file.name}
      </AttachmentName>
      <AvatarWrapper>
        <Avatar userId={file.user} />
      </AvatarWrapper>
      {file.user === user.id && (
        <IconMenu
          items={[{ label: i18n._(t`Delete`), action: deleteAction }]}
          disabled={$loading.value}
        />
      )}
    </AttachmentRow>
  );
});

const ConversationAttachments = ({
  i18n,
  onAdd,
  files,
  conversationId,
  talkingPointId,
  onDelete,
  readOnly = false,
}) => {
  const user = useSelector(getUser);
  const $loading = useBoolState();
  const uploadHandler = useCallback(
    (file) => uploadConversationAttachment(file, conversationId, talkingPointId),
    [conversationId, talkingPointId],
  );

  const handleUpload = useCallback(
    (file) => {
      $loading.off();
      onAdd(file, talkingPointId);
    },
    [$loading, onAdd, talkingPointId],
  );

  return (
    <>
      {isEmpty(files) ? (
        <Placeholder
          Icon={() => (
            <SvgIcon
              url={AttachmentsIcon}
              width={'50px'}
              height={'50px'}
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
          )}
          title={i18n._(t`No attachments here `)}
          subTitle={i18n._(t`Add an attachment`)}
        />
      ) : (
        files.map((file) => (
          <Attachment
            key={file.id}
            file={file}
            user={user}
            onDelete={onDelete}
            conversationId={conversationId}
          />
        ))
      )}
      <UploadArea
        onStartUpload={$loading.on}
        onUpload={handleUpload}
        uploadHandler={uploadHandler}
        onFinishUpload={$loading.off}
        onError={$loading.off}
        disabled={readOnly}
      >
        <Button
          label={i18n._(t`Upload attachment`)}
          type="primary-border-wide"
          loading={$loading.value}
          disabled={readOnly}
        />
      </UploadArea>
    </>
  );
};

ConversationAttachments.defaultProps = {
  files: [],
  onAdd: emptyFn,
  onDelete: emptyFn,
};

ConversationAttachments.propTypes = {
  conversationId: PropTypes.string.isRequired,
};

export default React.memo(withI18n()(ConversationAttachments));
