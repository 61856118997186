import React, { useContext } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { FieldGroup, FieldTitle, TextField } from '~/components/Text';
import { ConversationSetupContext } from '~/pages/ConversationSetup';

import { getName } from '~/selectors/currentConversation';
import store from '~/store';
import { setName } from '~/store/currentConversation/actions';

const NameSection = () => {
  const name = useSelector(getName);
  const { i18n } = useLingui();
  const { error, isAdmin } = useContext(ConversationSetupContext);

  const onChangeName = (name) => {
    if (error?.value) {
      error.off();
    }
    store.dispatch(setName(name));
  };

  return (
    <FieldGroup>
      <FieldTitle>
        <Trans>Name</Trans>*
      </FieldTitle>
      <TextField
        value={name}
        placeholder={i18n._(t`Example: Weekly 1-1`)}
        onChange={(e) => onChangeName(e.target.value)}
        error={error?.value && !name}
        disabled={isAdmin}
      />
    </FieldGroup>
  );
};

NameSection.propTypes = {
  error: PropTypes.object,
};

export default React.memo(NameSection);
