import React from 'react';

import { t } from '@lingui/macro';
import first from 'lodash/first';
import styled from 'styled-components';

import { UserAvatar } from '~/components/UserAvatar';

import type { IColumnTable } from '~/@types/table';
import { COLORS } from '~/styles';

import { SORT_OPTIONS } from '../types';

import type { IJobProfile, IMultiLangString, IUser } from '@learned/types';
import type { I18n } from '@lingui/core';

const Container = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 350px;
`;

export const createColumns = (
  getMultiLangString: (multiLangString: IMultiLangString) => string,
): IColumnTable<IUser & { jobProfiles: IJobProfile[] }>[] => [
  {
    name: 'Employees',
    accessor: 'name',
    renderCell: (item, _onClick, isHovered, isSelected) => {
      return (
        <Container>
          <UserAvatar
            userId={item.id}
            options={{
              customColor: isSelected
                ? COLORS.COMPANY
                : isHovered
                ? COLORS.TEXT_HOVER
                : COLORS.TEXT_MAIN,
              showTooltip: true,
            }}
          />
        </Container>
      );
    },
    sortBy: {
      asc: {
        key: SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: 'Jobs',
    accessor: 'role',
    renderCell: (item) => {
      const firstProfile = first(item.jobProfiles);
      const firstJobProfileName =
        firstProfile && firstProfile.name ? getMultiLangString(firstProfile.name) : '';
      if (item?.jobProfiles?.length > 1) {
        return `${firstJobProfileName} +${item.jobProfiles.length - 1}`;
      } else {
        return firstJobProfileName;
      }
    },
  },
];
