import React, { ReactNode, useEffect, useState } from 'react';

import {
  ACTIVITY_STATUSES,
  ACTIVITY_TYPES,
  ACTIVITY_ROOT,
  ROLES,
  CONFIRMATION_MODAL_TYPE,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import YouTube from 'react-youtube';
import styled, { css } from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import DeleteModal from '~/components/DeleteModal';
import { ICONS } from '~/components/Icon';
import CopyIcon from '~/components/Icons/Copy';
import DuplicateIcon from '~/components/Icons/Duplicate';
import LinkIcon from '~/components/Icons/Link';
import MinusIcon from '~/components/Icons/Minus';
import Pencil2Icon from '~/components/Icons/Pencil2';
import Plus2Icon from '~/components/Icons/Plus2';
import UserPlusIcon from '~/components/Icons/UserPlus';
import UsersIcon from '~/components/Icons/Users';
import Modal from '~/components/Modal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import type { ILocalActivity } from '~/pages/OnboardAndLearn/tabs/AllLearningsTab/types';

import { Progress } from './components/Progress';

import CloseIcon from '~/assets/ic-close-18-px.svg';

import type { IActivityProgressProps } from '~/@types/activity';
import { LANGUAGES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { getUser } from '~/selectors/baseGetters';
import getLang from '~/selectors/getLang';
import getUsersBasedOnRole from '~/selectors/getUsersBasedOnRole';
import { getActivity } from '~/services/activities';
import { updateGoalsUpdate } from '~/services/goalsUpdates';
import {
  createUserActivityFromLibrary,
  getUserActivityByParentId,
  getUserActivity,
  removeUserActivityGoals,
  deleteUserActivity,
} from '~/services/userActivities';
import * as currentGoalActivities from '~/store/currentGoal/actions';
import { COLORS, COLOR_PALETTE, COLOR_SET } from '~/styles';
import { copyTextToClipboard } from '~/utils/copyTextToClipboard';
import isValidYoutubeUrl from '~/utils/isValidYoutubeUrl';
import sanitizeHtml from '~/utils/sanitize';

import { AddToPlanModal } from '../AddToPlanModal';
import { ConfirmationModal } from '../ConfirmationModal';
import { SelectLPModal } from '../SelectLPModal';
import { ShowParticipantsActivityModal } from '../ShowParticipantsActivityModal';

import type {
  IUserActivity,
  ISkill,
  ISkillCategory,
  IGoal,
  IUser,
  IActivity,
  IGoalsUpdate,
} from '@learned/types';
import type { AxiosResponse } from 'axios';

interface IActivityModal {
  onClose: () => void;
  activityId: ILocalActivity['id'];
  onSaveActivity?: (data: IActivityProgressProps, activity?: IUserActivity) => void;
  isUserActivity?: boolean;
  onEditActivity?: () => void;
  isUpdateProgress?: boolean;
  isReadOnly?: boolean;
  selectedRole: ROLES;
  user: IUser;
  duplicateActivity?: (activityId: string) => Promise<void>;
  setActivityToEnrolledInState?: (value: string) => void;
  onDeleteUserActivity?: (activity?: IUserActivity) => void;
  isActionButtonsVisible?: boolean;
  isPlanningButtonsVisible?: boolean;
  isPageBlock?: boolean;
  isRefetchData?: boolean;
  isActionButtonParticipantsVisible?: boolean;
  isActionButtonEditorsVisible?: boolean;
  isHideProgress?: boolean;
  isGoalActivity?: boolean;
}

interface IActionButton {
  title: string;
  icon: ReactNode;
  action: () => void;
  width?: string;
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};

  & .closeButton {
    padding: 26px;
    border-right: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
    cursor: pointer;
  }
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  padding: 14px 30px 14px 0;
  width: 100%;
`;

const ActionButton = styled.button<{ width?: string }>`
  display: inline-flex;
  align-items: center;
  color: var(--company-color);
  width: ${({ width }) => width || '120px'};
  & .oval {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 100px;
    margin-right: 5px;
    & svg {
      fill: var(--company-color);
    }
  }
  & .title {
    text-transform: uppercase;
  }

  &:hover {
    .title {
      font-weight: 600;
    }
  }

  cursor: pointer;
`;

const Wrapper = styled(ShowSpinnerIfLoading)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Cover = styled.div`
  height: 561px;
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #edf0f7;
  color: var(--company-color);
  font-size: 19.6px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  & .youtube-video-cover {
    width: 100%;
  }
`;

const Image = styled.div<{ background?: string }>`
  background: ${({ background }) => (background ? `url(${background})` : 'unset')};
  background-position: center;
  background-size: cover;
  height: 561px;
`;

const Main = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 32px 32px 0;
  display: flex;
  justify-content: space-around;
`;

const Content = styled.div<{ $isFullWidth?: boolean }>`
  width: ${({ $isFullWidth }) => ($isFullWidth ? '100%' : '70%')};
  margin-right: ${({ $isFullWidth }) => ($isFullWidth ? '0' : '32px')};

  padding-bottom: 64px;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: -0.24px;
  color: ${COLORS.TEXT_BLACK};
`;

const Description = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.18px;
  color: ${COLORS.TEXT_BLACK};
`;

const Sidebar = styled.div`
  width: 30%;
  padding-left: 32px;
  border-left: 1px solid #f7f9fe;
  & .actionButton {
    margin-bottom: 32px;
  }
`;

const Section = styled.div<{ isInline?: boolean; isBorderVisible?: boolean }>`
  ${({ isBorderVisible = false }) =>
    isBorderVisible &&
    css`
      border-top: 1px solid ${COLORS.BG_PAGE};
    `};

  padding-bottom: 8px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;

  ${({ isInline }) =>
    isInline &&
    css`
      padding-bottom: 6px;
      flex-direction: row;
      justify-content: space-between;
    `}
`;

const SectionTitle = styled.span<{ color?: string; isCapitalize?: boolean }>`
  color: ${({ color }) => color || COLORS.TEXT_BLACK};
  padding-bottom: 4px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  ${({ isCapitalize }) =>
    isCapitalize &&
    css`
      text-transform: capitalize;
    `}
`;

const SectionLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 8px 10px 13px;
  border-radius: 6px;
  margin-bottom: 5px;
  background-color: ${COLORS.BG_PAGE};
  &:hover {
    background: #f5f7fd;
  }
  & .link {
    width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: ${COLORS.TEXT_SECONDARY};
    &:hover {
      color: var(--company-color);
    }
  }

  & svg {
    &:hover {
      color: var(--company-color);
    }
    color: ${COLORS.TEXT_SECONDARY};
  }
`;

const IconButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 4px;
`;

const IconLink = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 4px;
  color: var(--company-color);
`;

const BadgeWrapper = styled.div<{ view?: 'wrap' | 'list' }>`
  display: flex;
  gap: 10px;
  ${({ view }) =>
    view === 'list'
      ? css`
          flex-direction: column;
          width: 100%;
        `
      : css`
          flex-wrap: wrap;
        `}
`;

const Badge = styled.span<{ isSmall?: boolean }>`
  ${({ isSmall }) =>
    isSmall
      ? css`
          padding: 9px 20px;
        `
      : css`
          padding: 16px 13px;
        `}

  border-radius: 6px;
  background-color: ${COLORS.BG_PAGE};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: ${COLORS.TEXT_SECONDARY};

  &:hover {
    background: linear-gradient(to left, #f7f9ff, #ebf1fe);
  }

  & .content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    & .name {
      display: flex;
      align-items: center;
    }
    & button {
      display: flex;
      align-items: flex-start;
      padding: 0;
      & .icon {
        cursor: pointer;
        min-width: 13px;
        margin-top: 2px;
        color: var(--company-color);
        &:hover {
          color: ${COLOR_SET.RED_3};
        }
      }
    }
  }
`;

const AddToPlanButton = styled.button`
  width: 100%;
  padding: 12px 0;
  border-radius: 8px;
  background-color: var(--company-color);
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  text-align: center;
  color: ${COLORS.TEXT_WHITE};
  cursor: pointer;
`;

const ConnectToGoalButton = styled.button`
  width: 100%;
  padding: 12px 0;
  border-radius: 8px;
  color: var(--company-color);
  border: 1px solid var(--company-color);
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  text-align: center;
  background-color: ${COLORS.TEXT_WHITE};
  cursor: pointer;
`;

const ConnectToMoreGoal = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 6px 15px;
  margin-left: auto;
  font-size: 14px;
  cursor: pointer;
  color: var(--company-color);
  box-shadow: 0 2px 5px 0 rgba(145, 157, 165, 0.12);
  border-radius: 100px;
  & .icon {
    color: var(--company-color);
  }
`;

const BlockWrapp = styled.div`
  height: max-content;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 30px;
  box-sizing: border-box;
  padding: 0 27px 27px 27px;
`;

const ActivityModalComponent = ({
  activityId,
  selectedRole,
  user,
  onClose,
  isUserActivity = false,
  isReadOnly,
  onEditActivity,
  isUpdateProgress = true,
  onSaveActivity,
  duplicateActivity,
  setActivityToEnrolledInState,
  isActionButtonsVisible = false,
  isActionButtonParticipantsVisible = true,
  isActionButtonEditorsVisible = true,
  isPlanningButtonsVisible = false,
  onDeleteUserActivity,
  isPageBlock = false,
  isRefetchData = false,
  isHideProgress = false,
  isGoalActivity = false,
}: IActivityModal) => {
  const { i18n } = useLingui();
  const [skills, setSkills] = useState<ISkill[]>([]);
  const [categories, setCategories] = useState<ISkillCategory[]>([]);
  const [activity, setActivity] = useState<ILocalActivity>();
  const [userActivity, setUserActivity] = useState<IUserActivity>();
  const [comment, setComment] = useState('');
  const [updateComments, setUpdateComments] = useState<string[]>([]);
  const [goalsUpdates, setGoalsUpdates] = useState<IGoalsUpdate[]>();
  const loading = useBoolState(true);
  const currentUser = useSelector(getUser);
  const { addToast } = useToasts();

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isShowSelectLPModal, setIsShowSelectLPModal] = useState(false);
  const [isShowAddedToPlanModal, setIsShowAddedToPlanModal] = useState(false);
  const [isShowEditorsActivityModal, setIsShowEditorsActivityModal] = useState(false);
  const [isShowParticipantsActivityModal, setIsShowParticipantsActivityModal] = useState(false);
  const [isDeleteGoalModalVisible, setIsDeleteGoalModalVisible] = useState(false);
  const [deleteGoalId, setDeleteGoalId] = useState('');
  const lang = useSelector(getLang);
  const dispatch = useDispatch();
  const $isUpdatingProgress = useBoolState(false);
  const [updatedActivity, setUpdatedActivity] = useState<IActivity>();
  const $isShowWarningModal = useBoolState(false);

  useEffect(() => {
    const fetchData = async () => {
      loading.on();
      const join: string[] = [];
      const populate = ['skills', 'categories', 'files', 'goals'];

      if (isUserActivity) {
        join.push('goalsUpdates');
        const { data }: AxiosResponse<{ userActivity: IUserActivity }> = await getUserActivity(
          activityId,
          populate,
          join,
        );

        setSkills(data.userActivity?.skills as ISkill[]);
        setCategories(data.userActivity?.categories as ISkillCategory[]);
        setUserActivity(data.userActivity);
        // @ts-ignore
        setActivity(data.userActivity);

        setGoalsUpdates(data.userActivity?.goalsUpdates?.reverse());
      }

      if (!isUserActivity) {
        const { data }: AxiosResponse<{ activity: ILocalActivity }> = await getActivity(
          activityId,
          populate,
          join,
        );

        if (data.activity) {
          setActivity(data.activity);
          // @ts-ignore
          setSkills(data.activity?.skills as ISkill[]);
          // @ts-ignore
          setCategories(data.activity?.categories as ISkillCategory[]);
        }

        const {
          data: { userActivity },
        } = await getUserActivityByParentId(activityId);

        if (!_.isEmpty(userActivity) && !isPageBlock) {
          // @ts-ignore
          setUserActivity(userActivity);
          setGoalsUpdates(userActivity?.goalsUpdates?.reverse());
        }
      }
      loading.off();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityId, isUserActivity, setIsShowSelectLPModal, isShowSelectLPModal, isRefetchData]);

  const actionButtons: IActionButton[] = [
    // display edit option only when activity open from library page
    isActionButtonsVisible &&
      onEditActivity &&
      !activity?.originalActivity &&
      (currentUser.isAdmin || activity?.owners?.includes(currentUser.id)) && {
        title: i18n._(t`edit`),
        width: '80px',
        icon: <Pencil2Icon size={20} />,
        action: onEditActivity,
      },
    isActionButtonsVisible &&
      duplicateActivity &&
      !activity?.originalActivity &&
      (currentUser?.isAdmin || activity?.owners?.includes(currentUser.id)) && {
        title: i18n._(t`duplicate`),
        width: '125px',
        icon: <DuplicateIcon size={20} />,
        action: duplicateActivity,
      },
    isActionButtonsVisible &&
      isActionButtonParticipantsVisible &&
      (currentUser?.isAdmin || currentUser?.isCoach) && {
        title: i18n._(t`participants`),
        width: '150px',
        icon: <UsersIcon size={20} />,
        action: () => setIsShowParticipantsActivityModal?.(true),
      },
    isActionButtonsVisible &&
      isActionButtonEditorsVisible &&
      (activity?.owners?.includes(currentUser.id) || currentUser?.isAdmin) && {
        title: i18n._(t`editors`),
        width: '100px',
        icon: <UserPlusIcon size={20} />,
        action: () => setIsShowEditorsActivityModal?.(true),
      },
  ].filter((i) => i) as IActionButton[];

  const prepareActionButtons = (items: IActionButton[]) => {
    return (
      <ActionButtonWrapper>
        {items.map(({ title, icon, action, width }) => (
          <ActionButton key={`action-button-${title}`} width={width} onClick={action}>
            <span className="oval">{icon}</span>
            <span className="title">{title}</span>
          </ActionButton>
        ))}
      </ActionButtonWrapper>
    );
  };

  const [videoId, setVideoId] = useState<string | null>(null);

  useEffect(() => {
    if (activity?.link && isValidYoutubeUrl(activity.link)) {
      const rx = new RegExp(
        // prettier-ignore
        // eslint-disable-next-line
        '^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*',
      );
      const output = activity?.link.match(rx);
      if (output) {
        setVideoId(output[1]);
      }
    } else {
      // reset videoId if activity does not have video
      if (videoId) {
        setVideoId(null);
      }
    }

    // eslint-disable-next-line
  }, [activity?.link]);

  useEffect(() => {
    setUpdatedActivity(activity);
  }, [activity]);

  const typeLabel =
    _.find(ACTIVITY_TYPES, (a: ACTIVITY_TYPES) => a === activity?.type) || ACTIVITY_TYPES.OTHER;

  const link = activity?.link;

  const onAddToPlan = async (activityId: string) => {
    const { data } = await createUserActivityFromLibrary(activityId, 'personal');
    setUserActivity(data.userActivity);
    setActivityToEnrolledInState?.(activityId);
    setIsShowAddedToPlanModal(true);
    addToast({
      title: i18n._(t`Successfully added to plan`),
      subtitle: i18n._(t`Activity was added to plan`),
      type: TOAST_TYPES.INFO,
    });
  };

  const handleAddToPlan = async () => {
    if (activity) {
      onAddToPlan?.(activity.id);
    }
  };

  const handleConnectToGoal = async () => {
    setIsShowSelectLPModal(true);
  };

  const handleRemoveGoalFromUserActivity = async (goalId: string) => {
    const { data } = await removeUserActivityGoals(userActivity?.id, goalId);

    if (!data.userActivity?.goals?.includes(goalId)) {
      // @ts-ignore
      setUserActivity((prevState: IUserActivity) => ({
        ...prevState,
        goals: (prevState.goals as IGoal[])?.filter((goal: IGoal) => goal.id !== goalId),
      }));
    }
  };

  const updateUserActivity = (data: { progress?: number; status?: ACTIVITY_STATUSES }) => {
    setUserActivity((prevState) => prevState && { ...prevState, ...data });
  };

  const onCommentChange = (value: string) => {
    setComment(value);
  };

  const updateCommentText = (e: string, updateId: string) => {
    setGoalsUpdates(
      goalsUpdates?.map((update) =>
        update.id === updateId
          ? {
              ...update,
              comment: e,
            }
          : update,
      ),
    );
  };

  const updateCommentFunction = async (updateId: string) => {
    setUpdateComments(updateComments.filter((comment) => comment !== updateId));
    const goalUpdate = goalsUpdates?.find((u) => u.id === updateId);
    if (goalUpdate) {
      await updateGoalsUpdate(updateId, goalUpdate.comment);
    }
  };

  const onUpdateGoalsUpdate = (id: string) =>
    setUpdateComments((prevState) => (prevState ? [...prevState, id] : [id]));

  const isOriginLibraryActivity =
    userActivity?.root === ACTIVITY_ROOT.LIBRARY && !userActivity?.originalActivity;
  const canChangeProgress =
    selectedRole === ROLES.USER &&
    !isOriginLibraryActivity &&
    userActivity?.owners[0] === user.id &&
    !isReadOnly;

  const isCustomActivity =
    userActivity?.root === ACTIVITY_ROOT.CUSTOM ||
    (activity?.root as ACTIVITY_ROOT) === ACTIVITY_ROOT.CUSTOM;

  const handleSaveActivity = () => {
    onSaveActivity?.(
      {
        progress: userActivity?.progress,
        // @ts-ignore
        status: userActivity?.status,
        comment,
      },
      userActivity,
    );
    if (onClose) {
      onClose();
    }
  };

  const onHideShowParticipantsActivityModal = (editors: string[], isEditor: boolean) => {
    // @ts-ignore
    setActivity?.({
      ...activity,
      ...(isEditor ? { owners: editors } : { users: editors }),
    });

    setIsShowParticipantsActivityModal(false);
    setIsShowEditorsActivityModal(false);
  };

  const deleteActivity = async (activity: IUserActivity) => {
    await deleteUserActivity(activity.id, 'personal');

    setIsDeleteModalVisible(false);
    onDeleteUserActivity?.(activity);
    onClose();
  };

  const onChangeActivityProgress = (data: IActivity) => {
    setUpdatedActivity(data);
  };

  const handleUpdateActivity = async (activityId: string, data: IActivity) => {
    $isUpdatingProgress.on();
    if (isGoalActivity) {
      await dispatch(currentGoalActivities.updateActivity(activityId, data, true));
    } else {
      await onSaveActivity?.(
        {
          progress: data.progress || 0,
          // @ts-ignore
          status: data.status,
        },
        data,
      );
    }
    $isUpdatingProgress.off();
    onClose && onClose();
  };

  const handleCancel = () => {
    if (
      updatedActivity?.progress !== activity?.progress ||
      updatedActivity?.status !== activity?.status
    ) {
      $isShowWarningModal.on();
    } else {
      onClose();
    }
  };

  const renderActivityBlock = () => (
    <Wrapper loading={loading.value}>
      {videoId ? (
        <Cover>
          <YouTube
            className={'youtube-video-cover'}
            videoId={videoId}
            opts={{
              height: '561px',
            }}
          />
        </Cover>
      ) : (
        activity?.coverUrl && (
          <Cover>
            <Image background={activity?.coverUrl} />
          </Cover>
        )
      )}
      <Main>
        <Content $isFullWidth={isCustomActivity}>
          <Title>{activity?.name}</Title>
          <Description dangerouslySetInnerHTML={{ __html: sanitizeHtml(activity?.description) }} />
          {userActivity && isUpdateProgress && !isHideProgress && (
            <Progress
              // @ts-ignore
              activity={userActivity}
              onChange={updateUserActivity}
              onCommentChange={onCommentChange}
              comment={comment}
              onSaveActivity={handleSaveActivity}
              isDisabled={!canChangeProgress}
              goalsUpdates={goalsUpdates}
              updateComments={updateComments}
              updateCommentText={updateCommentText}
              updateCommentFunction={updateCommentFunction}
              onUpdateGoalsUpdate={onUpdateGoalsUpdate}
              isProgressEditable={isUpdateProgress}
              onChangeActivityProgress={onChangeActivityProgress}
            />
          )}
        </Content>
        {!isCustomActivity && (
          <Sidebar>
            {isPlanningButtonsVisible && (
              <div className="actionButton">
                {!userActivity && (
                  <AddToPlanButton onClick={handleAddToPlan}>
                    {i18n._(t`Add to plan`)}
                  </AddToPlanButton>
                )}
                {userActivity &&
                  (_.isEmpty(userActivity?.goals) ? (
                    <ConnectToGoalButton onClick={handleConnectToGoal}>
                      {i18n._(t`Connect to goal`)}
                    </ConnectToGoalButton>
                  ) : (
                    <Section>
                      <SectionTitle>{i18n._(t`Connected Goals`)}</SectionTitle>
                      <BadgeWrapper view="list">
                        {(userActivity?.goals as IGoal[])?.map((goal) => (
                          <Badge key={goal.id}>
                            <div className="content">
                              <span className="name">{goal.name}</span>
                              <button
                                onClick={() => {
                                  setIsDeleteGoalModalVisible(true);
                                  setDeleteGoalId(goal.id);
                                }}
                              >
                                <MinusIcon size={13} className="icon" />
                              </button>
                            </div>
                          </Badge>
                        ))}
                        <div>
                          <ConnectToMoreGoal onClick={handleConnectToGoal}>
                            <span>{i18n._(t`Connect to goal`)}</span>
                            <Plus2Icon size={13} className="icon" />
                          </ConnectToMoreGoal>
                        </div>
                      </BadgeWrapper>
                    </Section>
                  ))}
              </div>
            )}
            {link && (
              <Section>
                <SectionTitle>{i18n._(t`Link`)}</SectionTitle>
                <SectionLink>
                  <span className="link">{link}</span>
                  <IconButton onClick={() => copyTextToClipboard(link)}>
                    <CopyIcon size={21} />
                  </IconButton>
                  <IconLink target="_blank" href={link}>
                    <LinkIcon width={17} height={16} />
                  </IconLink>
                </SectionLink>
              </Section>
            )}

            {!_.isEmpty(categories) && (
              <Section>
                <SectionTitle>{i18n._(t`Categories:`)}</SectionTitle>
                <BadgeWrapper>
                  {categories.map((category) => (
                    <Badge isSmall key={category.id}>
                      {category.name}
                    </Badge>
                  ))}
                </BadgeWrapper>
              </Section>
            )}
            {!_.isEmpty(skills) && (
              <Section>
                <SectionTitle>{i18n._(t`Skills:`)}</SectionTitle>
                <BadgeWrapper>
                  {skills?.map((skill) => {
                    return (
                      <Badge isSmall key={skill.id}>
                        {lang === LANGUAGES.NL && skill.name.nl_NL
                          ? skill.name.nl_NL
                          : skill.name.en_GB}
                      </Badge>
                    );
                  })}
                </BadgeWrapper>
              </Section>
            )}
            <Section>
              <SectionTitle>{i18n._(t`Content type:`)}</SectionTitle>
              <SectionTitle color={COLOR_SET.DARK_GRAY} isCapitalize>
                {i18n._(t`${typeLabel}`)}
              </SectionTitle>
            </Section>
            <Section isBorderVisible>
              {isPlanningButtonsVisible &&
                userActivity &&
                userActivity.createdBy === user.id &&
                onDeleteUserActivity && (
                  <Button
                    variant={ButtonVariant.TEXT_DELETE}
                    size={ButtonSize.MEDIUM}
                    label={i18n._(t`Remove from my plan`)}
                    onClick={() => setIsDeleteModalVisible(true)}
                    icon={ICONS.DELETE_BIN}
                  />
                )}
            </Section>
          </Sidebar>
        )}
      </Main>
      {userActivity && isUpdateProgress && !isHideProgress && (
        <Footer>
          {!!onClose && (
            <Button
              label={i18n._(t`Cancel`)}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              onClick={handleCancel}
            />
          )}
          {isUpdateProgress && (
            <Button
              label={i18n._(t`Update`)}
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              onClick={() => handleUpdateActivity(activityId, updatedActivity!)}
              isLoading={$isUpdatingProgress.value}
            />
          )}
        </Footer>
      )}
    </Wrapper>
  );

  return (
    <>
      {isPageBlock ? (
        <BlockWrapp>{renderActivityBlock()}</BlockWrapp>
      ) : (
        <Modal
          centerModal
          minWidth={750}
          contentStyles={{ padding: '0' }}
          isHideHeader
          hideFooter
          marginTop="64px"
          showDivider={false}
        >
          <Header>
            <button onClick={onClose} className="closeButton">
              <SvgIcon
                url={CloseIcon}
                width={'16px'}
                height={'16px'}
                isDefaultColor
                defaultColor={COLOR_PALETTE.BLACK}
              />
            </button>{' '}
            {prepareActionButtons(actionButtons)}
          </Header>
          {renderActivityBlock()}
        </Modal>
      )}
      {(isShowParticipantsActivityModal || isShowEditorsActivityModal) && activity && (
        <ShowParticipantsActivityModal
          isEditor={isShowEditorsActivityModal}
          onClose={onHideShowParticipantsActivityModal}
          // @ts-ignore
          activity={activity as IActivity}
        />
      )}
      {isShowSelectLPModal && (activity?.id || userActivity?.id) && (
        <SelectLPModal
          setIsShowSelectLPModal={setIsShowSelectLPModal}
          setIsShowAddedToPlanModal={setIsShowAddedToPlanModal}
          // @ts-ignore
          activityId={isUserActivity ? userActivity?.originalActivity : activity?.id}
        />
      )}
      {isShowAddedToPlanModal && (
        <AddToPlanModal
          setIsShowAddedToPlanModal={setIsShowAddedToPlanModal}
          setIsShowSelectLPModal={setIsShowSelectLPModal}
        />
      )}
      {isDeleteModalVisible && userActivity && (
        <DeleteModal
          // @ts-ignore
          title="activity"
          content={i18n._(
            t`Are you sure? Removing this activity from your list will also remove the activity from your connected goals.`,
          )}
          onClose={() => setIsDeleteModalVisible(false)}
          onDelete={() => deleteActivity(userActivity)}
        />
      )}
      {isDeleteGoalModalVisible && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.DELETE}
          description={i18n._(
            t`The activity will no longer be connected to the goal. Are you sure?`,
          )}
          onClose={() => setIsDeleteGoalModalVisible(false)}
          onSubmit={() => handleRemoveGoalFromUserActivity(deleteGoalId)}
        />
      )}
      {$isShowWarningModal.value && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.WARNING}
          description={i18n._(t`'Your changes will not be saved.`)}
          title={i18n._(t`Are you sure you want to close without updating?`)}
          cancelButton={i18n._(t`Back to update`)}
          submitButton={i18n._(t`Yes`)}
          onClose={$isShowWarningModal.off}
          onSubmit={onClose}
        />
      )}
    </>
  );
};

// @ts-ignore
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    users: getUsersBasedOnRole()(state),
    selectedRole: state.selected.role,
    teams: state.teams.data,
  };
};

const ActivityModal = connect(mapStateToProps)(ActivityModalComponent);

export { ActivityModal };
