import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';
import Switch from '~/components/Switch';
import Tooltip from '~/components/Tooltip';

import MenuIcon from '~/assets/menu.svg';
import trashIcon from '~/assets/trash.svg';

import getLang from '~/selectors/getLang';
import { COLORS, COLOR_PALETTE } from '~/styles';
import { getSkillName } from '~/utils/skillUtils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDER_LIGHT};
  min-height: 48px;
  padding: 12px 18px 12px 10px;
  box-sizing: border-box;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
  background-color: ${(props) => (props.$isDragging ? COLORS.BG_PAGE : null)};
`;

const OneRow = styled.div`
  display: flex;
  align-items: center;
`;

const DragWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 34px;
  max-width: 34px;
  cursor: pointer;
`;

const SkillName = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.TEXT_PRIMARY};
  margin-left: 8px;
`;

const DeleteButton = styled.div`
  background: transparent;
  border: none;
  cursor: pointer;
  margin-left: 17px;
`;

const Item = ({
  skill,
  index,
  onDelete,
  onChangeHiddenSkills,
  isShowSkill,
  isManuallyAddedSkill = false,
}) => {
  const lang = useSelector(getLang);
  const { i18n } = useLingui();
  return (
    <Draggable draggableId={String(skill.id)} index={index}>
      {(provided, snapshot) => (
        <Wrapper
          ref={provided.innerRef}
          $isDragging={snapshot.isDragging}
          {...provided.draggableProps}
        >
          <OneRow>
            <DragWrapper {...provided.dragHandleProps}>
              <SvgIcon
                width="16px"
                height="16px"
                url={MenuIcon}
                defaultColor={COLOR_PALETTE.GRAY_SEMI_SOFT}
                isDefaultColor={true}
              />
            </DragWrapper>
            <SkillName>{getSkillName(skill, lang)}</SkillName>
          </OneRow>
          <Tooltip
            tooltip={i18n._(
              t`This skill is part of your current role and/or has multiple ratings. Therefore, it cannot be deleted. You can hide it from your profile.`,
            )}
            disabled={isManuallyAddedSkill}
          >
            <OneRow>
              <Switch onChange={() => onChangeHiddenSkills(skill.id)} checked={isShowSkill} />
              <DeleteButton onClick={isManuallyAddedSkill ? () => onDelete(skill.id) : null}>
                <SvgIcon
                  width="24px"
                  height="24px"
                  url={trashIcon}
                  isDefaultColor={true}
                  defaultColor={
                    !isManuallyAddedSkill ? COLOR_PALETTE.GRAY_SEMI_SOFT : COLOR_PALETTE.GRAY_MIDDLE
                  }
                />
              </DeleteButton>
            </OneRow>
          </Tooltip>
        </Wrapper>
      )}
    </Draggable>
  );
};

Item.propTypes = {
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  isShowSkill: PropTypes.bool,
  onChangeShowSkill: PropTypes.func,
  isManuallyAddedSkill: PropTypes.bool,
};

export default React.memo(Item);
