import React from 'react';

import { ACTIVITY_STATUSES, GOAL_STATUSES, GOAL_STATUSES_NEW } from '@learned/constants';
import { useLingui } from '@lingui/react';

import { ICONS, ICON_SIZES } from '~/components/Icon';

import { GOAL_STATUSES_MAP } from '../consts';
import { StyledDiv, StyledIcon } from '../design';

type LabelProps = {
  status: GOAL_STATUSES | ACTIVITY_STATUSES;
  progress?: number | null;
};

const StatusLabel = ({ status, progress }: LabelProps) => {
  const { i18n } = useLingui();

  const getItem = () => {
    switch (status) {
      // need on track to render status labels in goal updates
      case GOAL_STATUSES.ON_TRACK: {
        if (progress && progress > 0) {
          return GOAL_STATUSES_MAP[GOAL_STATUSES_NEW.IN_PROGRESS];
        }
        return GOAL_STATUSES_MAP[GOAL_STATUSES_NEW.TODO];
      }
      case ACTIVITY_STATUSES.TODO: {
        return GOAL_STATUSES_MAP[GOAL_STATUSES_NEW.TODO];
      }
      case ACTIVITY_STATUSES.IN_PROGRESS: {
        return GOAL_STATUSES_MAP[GOAL_STATUSES_NEW.IN_PROGRESS];
      }
      case GOAL_STATUSES.COMPLETED: {
        return GOAL_STATUSES_MAP[GOAL_STATUSES_NEW.COMPLETED];
      }
      case GOAL_STATUSES.PROBLEM: {
        return GOAL_STATUSES_MAP[GOAL_STATUSES_NEW.PROBLEM];
      }
      case GOAL_STATUSES.DRAFT: {
        return GOAL_STATUSES_MAP[GOAL_STATUSES_NEW.DRAFT];
      }
      case GOAL_STATUSES.ARCHIVED: {
        return GOAL_STATUSES_MAP[GOAL_STATUSES_NEW.ARCHIVED];
      }
    }
  };

  const item = getItem()!;
  if (!item) {
    return null;
  }

  return (
    <StyledDiv color={item.color} backgroundColor={item.backGroundColor}>
      {item.icon && (
        <StyledIcon
          icon={item.icon as ICONS}
          size={ICON_SIZES.SMALL}
          rotate={item.key === GOAL_STATUSES_NEW.TODO ? -90 : 0}
          color={item.color}
        />
      )}
      {item.label(i18n)}
    </StyledDiv>
  );
};

export { StatusLabel };
