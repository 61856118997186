import React from 'react';

import CompanyAvatar from '~/components/CompanyAvatar';
import { Icon, ICONS } from '~/components/Icon';
import { UploadLogoButton } from '~/components/SideMenu/CompanySwitchMenu/UploadLogoButton';

import defaultCompanyAvatar from '~/assets/learned-logo/learned-iconic.png';

import { Select, CompanyLabel } from '../CompanyDropdown.design';

import type { ICompany } from '@learned/types';

function CompanySelect({
  onClick,
  selectedItem,
  clicked,
  isShowUploadLogoButton = false,
  onUploadChange,
  isClickable = true,
}: {
  clicked?: boolean;
  active?: boolean;
  onClick?: () => void;
  selectedItem?: ICompany;
  isShowUploadLogoButton?: boolean;
  onUploadChange?: (e: any) => void;
  isClickable?: boolean;
}) {
  return (
    <Select $clicked={clicked} onClick={isClickable ? onClick : () => {}} isWithoutLeftPadding>
      <CompanyLabel isSmallImage={isShowUploadLogoButton}>
        {(selectedItem?.logoUrl || (!selectedItem?.logoUrl && !isShowUploadLogoButton)) && (
          // @ts-ignore
          <CompanyAvatar logoUrl={selectedItem?.logoUrl || defaultCompanyAvatar} size={46} />
        )}
        {isShowUploadLogoButton && !!onUploadChange && (
          <UploadLogoButton onUploadChange={onUploadChange} />
        )}
        <span>{selectedItem?.name}</span>
      </CompanyLabel>
      {isClickable && <Icon height="11" width="11" icon={ICONS.DROPDOWN} />}
    </Select>
  );
}

export { CompanySelect };
