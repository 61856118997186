import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { SectionHeaderProps } from '~/pages/PassportPage/types';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  margin-bottom: 18px;
  border-bottom: 1px solid ${COLORS.BORDERS};
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 0px 0px 6px 0px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_MAIN};
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_MAIN};
`;

const HeaderInfo = styled.div`
  flex: auto;
`;

function SectionHeader({
  title,
  description,
  actionLabel,
  actionOnClick,
  actionIcon,
  actionIconSize,
  actionButtonVariant = ButtonVariant.PRIMARY,
  isEditable,
  onSave,
  onCancel,
}: SectionHeaderProps) {
  const { i18n } = useLingui();

  return (
    <Wrapper>
      <HeaderInfo>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </HeaderInfo>
      {actionOnClick && (
        <>
          {isEditable ? (
            <>
              <Button
                label={i18n._(t`Cancel`)}
                variant={ButtonVariant.TEXT_PRIMARY}
                size={ButtonSize.MEDIUM}
                onClick={onCancel}
              />
              <Button
                label={i18n._(t`Save`)}
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.MEDIUM}
                onClick={onSave}
              />
            </>
          ) : (
            <Button
              label={actionLabel}
              size={ButtonSize.MEDIUM}
              variant={actionButtonVariant}
              onClick={actionOnClick}
              icon={actionIcon}
              iconSize={actionIconSize}
            />
          )}
        </>
      )}
    </Wrapper>
  );
}

export { SectionHeader };
