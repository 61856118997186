import React from 'react';

import OpenRoute from '~/components/OpenRoute';
import PrivateRoute from '~/components/PrivateRoute';
import LoginLayout from '~/layouts/LoginLayout';
import RequestRTFeedback from '~/pages/RequestRTFeedback';
import RTFeedbackProvided from '~/pages/RTFeedbackProvided';
import { RTFeedbacks } from '~/pages/RTFeedbacks/rtfeedback';
import RTFeedbackSkills from '~/pages/RTFeedbackSkills';
import UserRTFeedback from '~/pages/UserRTFeedback';
import UserRTFeedbackOutside from '~/pages/UserRTFeedbackOutside';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('feedbacks', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.RTFEEDBACKS.routePath(ROLES.USER)}
        exact
        path={routes.RTFEEDBACKS.routePath(ROLES.USER)}
        component={RTFeedbacks}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.RTFEEDBACKS_GIVE_FEEDBACK.routePath(ROLES.USER)}
        exact
        path={routes.RTFEEDBACKS_GIVE_FEEDBACK.routePath(ROLES.USER)}
        component={UserRTFeedback}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.RTFEEDBACKS_ASKED.routePath(ROLES.USER)}
        exact
        path={routes.RTFEEDBACKS_ASKED.routePath(ROLES.USER)}
        component={RTFeedbacks}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.RTFEEDBACKS_PROVIDED.routePath(ROLES.USER)}
        exact
        path={routes.RTFEEDBACKS_PROVIDED.routePath(ROLES.USER)}
        component={RTFeedbacks}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.RTFEEDBACK_PROVIDED.routePath(ROLES.USER)}
        exact
        path={routes.RTFEEDBACK_PROVIDED.routePath(ROLES.USER)}
        component={RTFeedbackProvided}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.RTFEEDBACKS_GIVE_FEEDBACK.routePath(ROLES.USER)}
        exact
        path={routes.RTFEEDBACKS_GIVE_FEEDBACK.routePath(ROLES.USER)}
        component={RequestRTFeedback}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.RTFEEDBACKS_PROVIDED_REPORT.routePath(ROLES.USER)}
        exact
        path={routes.RTFEEDBACKS_PROVIDED_REPORT.routePath(ROLES.USER)}
        component={RequestRTFeedback}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.RTFEEDBACK_SKILLS_OUTGOING.routePath(ROLES.USER)}
        exact
        path={routes.RTFEEDBACK_SKILLS_OUTGOING.routePath(ROLES.USER)}
        component={RTFeedbackSkills}
        allowedRoles={[ROLES.USER]}
      />,
    ],
    coach: [],
    admin: [
      <PrivateRoute
        key={routes.FEEDBACK_CYCLES.routePath(ROLES.ADMIN)}
        exact
        path={routes.FEEDBACK_CYCLES.routePath(ROLES.ADMIN)}
        component={RTFeedbacks}
        allowedRoles={[ROLES.ADMIN]}
      />,
    ],
  },
  routesPublic: [
    <OpenRoute
      key={routes.RTFEEDBACKS_GIVE_FEEDBACK_OUTSIDE.routePath()}
      exact
      path={routes.RTFEEDBACKS_GIVE_FEEDBACK_OUTSIDE.routePath()}
      layout={LoginLayout}
      component={UserRTFeedbackOutside}
    />,
  ],
});
