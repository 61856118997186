import React from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import Editor from '~/components/Editor';
import Hint from '~/components/Hint';
import { TextField } from '~/components/Text';
import Tooltip from '~/components/Tooltip';
import { Header6, Header2 } from '~/components/UI/Typographics/headers';

import { TemplateCard, SubTitle } from './components/TemplateBaseComponents';

const StyledHeader6 = styled(Header6)`
  margin-block-start: 30px;
  margin-block-end: 10px;
`;

const DescriptionContainer = styled.div`
  width: fit-content;
`;

const StyledSubTitle = styled(SubTitle)`
  margin-top: 10px;
`;

const StyledTemplateCard = styled(TemplateCard)`
  margin-top: 26px;
`;

const NameCard = ({
  title,
  subTitle,
  name,
  updateName,
  placeholder,
  description,
  updateDescription,
  i18n,
  hint,
  isShowErrors,
  emailBody = true,
  descriptionTooltip,
  onClick,
}) => (
  <StyledTemplateCard onClick={onClick}>
    <Header2>{title}</Header2>
    <SubTitle>{subTitle}</SubTitle>
    <StyledHeader6>
      <Trans>Name</Trans>*
    </StyledHeader6>
    <TextField
      value={name}
      onChange={(e) => updateName(e.target.value)}
      maxLength={100}
      placeholder={placeholder}
      $accent
      error={isShowErrors && !name}
    />
    {updateDescription && (
      <>
        <StyledHeader6>
          {emailBody ? (
            <>
              <Trans>Email body</Trans>*
              <Hint>
                <Trans>
                  The email body is used as an input when you use this template for a review. You
                  can customize the email body text while setting up the review.
                </Trans>
              </Hint>
            </>
          ) : (
            <>
              <Tooltip
                tooltip={
                  descriptionTooltip ||
                  i18n._(
                    t`The description is used as a default description in setting up a conversation with this template`,
                  )
                }
              >
                <DescriptionContainer>
                  <Trans>Description</Trans>
                </DescriptionContainer>
              </Tooltip>
            </>
          )}
        </StyledHeader6>
        <Editor
          value={description || ''}
          onChange={(value) => updateDescription(value)}
          placeholder={i18n._(t`Add description…`)}
          resize={true}
          error={isShowErrors && !description}
          compact={true}
        />
      </>
    )}
    {hint && <StyledSubTitle>{hint}</StyledSubTitle>}
  </StyledTemplateCard>
);

export default React.memo(withI18n()(NameCard));
