import React, { useEffect, useState } from 'react';

import { IGoalCycle } from '@learned/types';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown } from '~/components/Dropdown';
import ToolTip, { TOOLTIP_PLACEMENTS, TOOLTIP_SIZES } from '~/components/Tooltip';
import { dataMappingGoalCycles } from '~/pages/GoalSetup/helpers';

import { getGoalCycles as getGoalCyclesStore } from '~/selectors/currentGoal';
import { getGoalCycles } from '~/services/goalCycles';
import { setCurrentGoalCycles } from '~/store/currentGoal/actions';

import { ItemContainer, ItemLabel } from '../design';

const TimeFrameSection = () => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const goalCycles = useSelector(getGoalCyclesStore) ?? [];
  const [search, setSearch] = useState<string>('');
  const [allGoalCycles, setAllGoalCycles] = useState<IGoalCycle[] | []>([]);

  useEffect(() => {
    let isMounted = true;
    const fetch = async () => {
      const fetchedGoalCycles = await getGoalCycles({ search });
      if (isMounted) {
        setAllGoalCycles(Object.values(fetchedGoalCycles) ?? []);
      }
    };

    fetch();

    return () => {
      isMounted = false;
    };
  }, [search]);

  // pre-selected
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { goalCycle: preSelectedGoalCycle } = query;

  const handleChangeGoalCycles = (newGoalCycles: IGoalCycle[]) => {
    // @ts-ignore
    dispatch(setCurrentGoalCycles(newGoalCycles));
  };

  return (
    <ToolTip
      maxWidth="750px"
      size={TOOLTIP_SIZES.MEDIUM}
      placement={TOOLTIP_PLACEMENTS.BOTTOM}
      tooltip={
        <Trans>This specifies the timeframe during which you are going to work on your goal</Trans>
      }
    >
      <div>
        <ItemContainer marginRight="21px">
          <ItemLabel>
            <Trans>Timeframe</Trans>
          </ItemLabel>
          {/* @ts-ignore */}
          <Dropdown
            items={allGoalCycles}
            selectedItems={goalCycles}
            // @ts-ignore
            isItemSelected={(item: IGoalCycle) =>
              Boolean(allGoalCycles.find((cycle) => cycle.id === item.id))
            }
            placeholder={i18n._(t`Select timeframe`)}
            onChange={(items) => handleChangeGoalCycles(items)}
            stringifyItem={(item: IGoalCycle) => dataMappingGoalCycles(item)?.fullName || ''}
            className="goal-setup-dropdown"
            selectHeight="38px"
            skipSort
            isSearchable
            onSearchChange={setSearch}
            isClickable={!preSelectedGoalCycle}
          />
        </ItemContainer>
      </div>
    </ToolTip>
  );
};

export { TimeFrameSection };
