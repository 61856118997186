/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent, useState } from 'react';

import { Controller } from 'react-hook-form';
import styled, { css } from 'styled-components';

import { IconOld } from '~/components/IconOld';
import { Input } from '~/components/Input';

import { COLORS, COLOR_PALETTE } from '~/styles';

import type { IGeneralForm } from '../types';
import type { UseFormReturn } from 'react-hook-form';

interface ColorPickerProps {
  formMethods: UseFormReturn<IGeneralForm>;
}

const Wrapper = styled.div``;

const ColorsWrapper = styled.div`
  & .grid {
    width: 82px;
    max-height: 243px;
    overflow-y: scroll;
    margin-top: 9px;
    padding-right: 10px;

    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    gap: 10px;

    & .customColor,
    & .colorWrapper {
      transform: rotateY(180deg);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      width: 32px;
      height: 32px;
      border-radius: 4px;
      background-color: ${COLORS.BG_PAGE};

      &:hover {
        background-image: linear-gradient(#dce4f3, #b6c5e4);
      }

      &.selected {
        border: 1px solid var(--company-color);
      }
    }

    & .customColor {
      border: 1px solid #dae4f7;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      overflow: hidden;
      input[type='color'] {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        -webkit-appearance: none;
        border: none;
        width: 100%;
        height: 100%;
        transform: scale(1.2);
      }
      input[type='color']::-webkit-color-swatch-wrapper {
        padding: 0;
        border: none;
      }
      input[type='color']::-webkit-color-swatch {
        border: none;
        padding: 0;
      }

      & .icon {
        cursor: pointer;
        z-index: 100;
      }
    }

    & .colorWrapper {
      overflow: hidden;
    }

    &::-webkit-scrollbar {
      width: 8px;
      position: absolute;
      left: 0;
      top: -5px;
    }

    &::-webkit-scrollbar-track {
      background: ${COLORS.BG_PAGE};
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--company-color);
      border-radius: 100px;
    }

    &::-webkit-scrollbar:hover {
      cursor: pointer;
    }

    transform: rotateY(180deg);
  }
`;

const ColorInputWrapper = styled.div`
  position: relative;
  &:before {
    position: absolute;
    content: '#';
    font-size: 14px;
    top: 7px;
    left: 8px;
  }
`;

const ColorInput = styled(Input)`
  height: 34px;
  padding-left: 22px !important;
`;

const Color = styled.div<{ colors: string[] }>`
  min-width: 32px;
  min-height: 32px;
  ${({ colors }) =>
    colors.length && colors.length > 1
      ? css`
          background: linear-gradient(to bottom, ${colors[0]}, ${colors[1]});
        `
      : css`
          background-color: ${colors[0]};
        `}
`;

const ColorPicker = ({ formMethods }: ColorPickerProps) => {
  const [customColor, setCustomColor] = useState('ffffff');
  const { setValue, control, trigger } = formMethods;

  const colors: string[] = [
    '#f7f9ff-#ebf1fe',
    '#c6e0e7-#94beca',
    '#e9ebfd-#ced3f9',
    '#c0f8f0-#8ceddb',
    '#e5f3d6-#c6e3ad',
    '#ffedd6-#ffd6ac',
    '#fcd8a7-#f8af6f',
    '#fbe991-#f5ce58',
    '#f9bab7-#f08480',
    '#d9bebb-#b18985',
    '#f2b2b5-#e17a7e',
    '#f9d6f8-#f0aded',
    '#ecbee5-#d489c7',
    '#ded7ff-#bbaeff',
    '#e7c7ff-#ca96ff',
    '#f0f0f0-#dddddd',
    '#f0f1fd-#dddefb',
  ];

  const handleTextInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCustomColor(value);
    if (value.length === 6) {
      setValue('iconColor', `#${value}`);
    }
  };

  const handleColorPicker = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCustomColor(value.replace('#', ''));
    setValue('iconColor', value);
  };

  return (
    <Wrapper>
      <ColorInputWrapper>
        <ColorInput
          name="color"
          placeholder="ffffff"
          onChange={handleTextInput}
          value={customColor}
        />
      </ColorInputWrapper>
      <ColorsWrapper>
        <Controller
          name="iconColor"
          rules={{ required: true }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className="grid">
              <button
                type="button"
                className={`customColor ${`#${customColor}` === value ? 'selected' : ''}`}
                onClick={() => {
                  onChange(`#${customColor}`);
                }}
              >
                <label htmlFor="colorPicker" className="icon">
                  <IconOld
                    name="AddCircle"
                    color={COLOR_PALETTE.BLUE_DARK}
                    width={12}
                    height={12}
                  />
                </label>
                <input
                  id="colorPicker"
                  type="color"
                  value={`#${customColor}`}
                  onChange={handleColorPicker}
                />
              </button>
              {colors.map((color) => (
                <button
                  type="button"
                  className={`colorWrapper ${color === value ? 'selected' : ''}`}
                  key={color}
                  onClick={async () => {
                    onChange(color);
                    await trigger(['name', 'icon', 'iconColor']);
                  }}
                >
                  <Color className="color" colors={color.split('-')} />
                </button>
              ))}
            </div>
          )}
        />
      </ColorsWrapper>
    </Wrapper>
  );
};

export { ColorPicker };
