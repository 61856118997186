import React from 'react';

import { Trans, t } from '@lingui/macro';

import { SettingsRow, SettingsText } from '~/components/Activity/base_components';
import Switch from '~/components/Switch';
import { FieldMediumTitle, FieldGroup } from '~/components/Text';

interface ISettings {
  onToggleAvailableInLibrary: () => void;
  isAvailableInUserLibrary: boolean;
}

const Settings = ({ onToggleAvailableInLibrary, isAvailableInUserLibrary }: ISettings) => {
  return (
    // @ts-ignore
    <FieldGroup marginBottom="64px">
      <FieldMediumTitle>
        <Trans>Settings</Trans>
      </FieldMediumTitle>

      <SettingsRow>
        <Switch onChange={onToggleAvailableInLibrary} checked={isAvailableInUserLibrary} />
        {/* @ts-ignore */}
        <SettingsText isActive={isAvailableInUserLibrary}>
          <Trans
            id={t`Make learning activity available for all users in library (if disabled only available to assign)`}
          />
        </SettingsText>
      </SettingsRow>

      {/* hidden for now */}
      {/* <SettingsRow onClick={onToggleRateAvailable}>
        <Switch onChange={onToggleRateAvailable} checked={activity.isRateAvailable} />
  
      <SettingsText isActive={activity.isRateAvailable}>
      <Trans>Ask the participant to rate this activity after completion</Trans>
       </SettingsText>
      </SettingsRow>*/}
    </FieldGroup>
  );
};

export { Settings };
