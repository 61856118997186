import React, { useState, useEffect } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { TextBlock } from '~/components/Activity/base_components';
import Button from '~/components/Button';
import CheckBox from '~/components/CheckBox';
import DatePicker from '~/components/DatePickerDeprecated';
import Modal from '~/components/Modal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { FieldGroup, FieldTitle, TextField } from '~/components/Text';

import useBoolState from '~/hooks/useBoolState';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getCertificate } from '~/services/certificates';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

import SkillsPicker from '../../../components/SkillsPicker';

const OneRow = styled.div`
  display: flex;
`;

function CertificateModal({ i18n, itemId, onCreate, onUpdate, onClose }) {
  const [name, setName] = useState('');
  const [school, setSchool] = useState('');
  const [url, setUrl] = useState('');
  const [completedDate, setCompletedDate] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const $doesNotExpire = useBoolState(false);
  const [skills, setSkills] = useState([]);

  const $isFetching = useBoolState(Boolean(itemId));
  const $isLoading = useBoolState(false);
  const isCreate = !itemId;

  useEffect(() => {
    async function fetchData() {
      const item = await getCertificate(itemId, { populate: ['skills'] });

      if (!isEmpty(item)) {
        setName(item.name);
        setSchool(item.school);
        setUrl(item.url);
        setCompletedDate(item.completedDate);
        setExpiryDate(item.expiryDate);
        $doesNotExpire.set(item.doesNotExpire);
        setSkills(item.skills);
      }
      $isFetching.off();
    }
    if (itemId) {
      fetchData();
    }

    // eslint-disable-next-line
  }, []);

  const onSubmit = async () => {
    const data = {
      name,
      school,
      url,
      completedDate: convertToTimeString(completedDate, TIME_FORMATS.ISO),
      expiryDate: convertToTimeString(expiryDate, TIME_FORMATS.ISO),
      doesNotExpire: $doesNotExpire.value,
      skills: skills.map((s) => s.id),
    };
    $isLoading.on();
    isCreate ? await onCreate(data) : await onUpdate(itemId, data);
    $isLoading.off();
    onClose();
  };

  const isValidated = () => {
    const isExpiryDateChecked = expiryDate || $doesNotExpire.value;
    return (
      name && school && completedDate && isExpiryDateChecked && !isEmpty(name) && !isEmpty(school)
    );
  };

  return (
    <Modal
      title={isCreate ? i18n._(t`Add certificate`) : i18n._(t`Update certificate`)}
      onClose={onClose}
      width={625}
      minWidth={625}
      contentStyles={{ background: '#fff', height: '100%', padding: '16px 16px 0px' }}
      headerStyles={{ padding: '0 16px', borderBottom: 'solid 0.5px #ecedf0' }}
      footerRight={
        <Button
          label={isCreate ? i18n._(t`Create`) : i18n._(t`Update`)}
          disabled={!isValidated()}
          loading={$isLoading.value}
          type="primary-border"
          onClick={onSubmit}
        />
      }
      footerLeft={
        <Button
          label={i18n._(t`Cancel`)}
          type="link-primary"
          onClick={onClose}
          loading={$isLoading.value}
        />
      }
    >
      <ShowSpinnerIfLoading loading={$isFetching.value}>
        <FieldGroup>
          <FieldTitle>
            <Trans>Title</Trans>*
          </FieldTitle>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={i18n._(t`Type the title here...`)}
            disabled={$isLoading.value}
          />
        </FieldGroup>
        <FieldGroup>
          <FieldTitle>
            <Trans>School/institute</Trans>*
          </FieldTitle>
          <TextField
            value={school}
            onChange={(e) => setSchool(e.target.value)}
            placeholder={i18n._(t`Type the school here...`)}
            disabled={$isLoading.value}
          />
        </FieldGroup>
        <FieldGroup>
          <FieldTitle>
            <Trans>Certificate URL</Trans>
          </FieldTitle>
          <TextField
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder={i18n._(t`Type the URL here...`)}
            disabled={$isLoading.value}
          />
        </FieldGroup>
        <OneRow style={{ justifyContent: 'space-between' }}>
          <FieldGroup>
            <FieldTitle>
              <Trans>Completed</Trans>*
            </FieldTitle>
            <DatePicker
              width={280}
              selected={completedDate}
              onChange={setCompletedDate}
              disabled={$isLoading.value}
              popperPlacement="bottom-start"
            />
          </FieldGroup>
          <FieldGroup>
            <FieldTitle>
              <Trans>Expiration date</Trans>*
            </FieldTitle>
            <DatePicker
              width={280}
              selected={expiryDate}
              onChange={setExpiryDate}
              disabled={$doesNotExpire.value || $isLoading.value}
              popperPlacement="bottom-start"
            />
            <OneRow style={{ marginTop: '8px', alignItems: 'center' }}>
              <CheckBox
                size={24}
                checked={$doesNotExpire.value}
                onChange={() => {
                  $doesNotExpire.toggle();
                  setExpiryDate(null); // clear to date
                }}
                disabled={$isLoading.value}
              />
              <TextBlock>
                <Trans>Does not expire</Trans>
              </TextBlock>
            </OneRow>
          </FieldGroup>
        </OneRow>
        <SkillsPicker skills={skills} onSkillsChange={setSkills} />
      </ShowSpinnerIfLoading>
    </Modal>
  );
}

CertificateModal.propTypes = {
  itemId: PropTypes.string,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onClose: PropTypes.func,
};

CertificateModal.defaultProps = {
  itemId: null,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    currentCompany: getCurrentCompany(state),
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(CertificateModal)));
