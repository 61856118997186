import { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, USER_REVIEW_STATUS } from '@learned/constants';
import { IUserReview, JOIN_USER_REVIEW_BY_ID } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment, { Moment } from 'moment/moment';
import { useSelector } from 'react-redux';

import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser, getUsers } from '~/selectors/baseGetters';
import { getUserReview, planConversation } from '~/services/userReviews';
import getUserFullName from '~/utils/getUserFullName';

import type { IPlanCalendarEventForm } from '../types';
import type { UseFormReturn } from 'react-hook-form';

interface IUseReviewProps {
  formMethods: UseFormReturn<IPlanCalendarEventForm>;
  userReviewId: IUserReview['id'];
  onClose: (isRefresh?: boolean) => void;
}

export const useUserReview = ({ formMethods, userReviewId, onClose }: IUseReviewProps) => {
  const { i18n } = useLingui();
  const { addToast } = useToasts();
  const { setValue } = formMethods;
  const user = useSelector(getUser);
  const users = useSelector(getUsers);
  const getMultiLangString = useMultiLangString();
  const [isLoading, setIsLoading] = useState(false);

  const [item, setItem] = useState<IUserReview>();

  const { watch } = formMethods;

  // init values
  const initDate = watch('initDate');
  const initStartTime = watch('initStartTime');
  const initEndTime = watch('initEndTime');
  const initIncludeLinkMeeting = watch('initIncludeLinkMeeting');

  // selected values
  const selectedDate = watch('selectedDate');
  const selectedStartTime = watch('selectedStartTime');
  const selectedEndTime = watch('selectedEndTime');
  const selectedIncludeLinkMeeting = watch('selectedIncludeLinkMeeting');

  const isArchived = item?.status === USER_REVIEW_STATUS.ARCHIVED;

  const isReviewBlockedForChanges = isArchived;

  const isValuesChanged =
    !moment(initDate).isSame(moment(selectedDate)) ||
    !moment(initStartTime).isSame(moment(selectedStartTime)) ||
    !moment(initEndTime).isSame(moment(selectedEndTime)) ||
    initIncludeLinkMeeting !== selectedIncludeLinkMeeting;

  const isAllInputHasData = selectedDate && selectedStartTime && selectedEndTime;
  const isAllowToSubmit =
    isValuesChanged && !isReviewBlockedForChanges && isAllInputHasData && !isArchived;

  const fetchUserReview = async () => {
    const result = await getUserReview(userReviewId, {
      join: [JOIN_USER_REVIEW_BY_ID.CALENDAR_EVENT],
    });
    const userReview: IUserReview & { calendarEvent: any } =
      result.data[API_RETURN_FIELDS.USER_REVIEW];
    setItem(userReview);
    return userReview;
  };

  const setFormValues = async () => {
    setIsLoading(true);
    const userReview = await fetchUserReview();
    const employeeId = userReview.createdFor;
    const employee = users[employeeId];
    const reviewName = getMultiLangString(userReview.name);
    setValue('employee', employee);
    setValue('userFullName', getUserFullName(employee));
    setValue('reviewName', reviewName);

    setValue('initIncludeLinkMeeting', userReview.includeLinkMeeting as boolean);
    setValue('selectedIncludeLinkMeeting', userReview.includeLinkMeeting as boolean);

    setValue('initDate', userReview.dateOfConversation as unknown as string);
    setValue('selectedDate', userReview.dateOfConversation as unknown as string);

    setValue('initStartTime', userReview.dateOfConversation as unknown as string);
    setValue('selectedStartTime', userReview.dateOfConversation as unknown as string);

    setValue('initEndTime', userReview.dateOfConversationEnd as unknown as string);
    setValue('selectedEndTime', userReview.dateOfConversationEnd as unknown as string);

    // calendar event
    const calendarIntegration = user?.integrations?.find(
      (integration: any) => !integration.isRevoked && !integration.isDeleted,
    );

    setValue('calendarIntegration', calendarIntegration);
    setValue('calendarEvent', userReview.calendarEvent);

    setIsLoading(false);
  };

  useEffect(() => {
    setFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReviewId]);

  const calcTime = () => {
    const initialDate = moment(selectedDate).format('DD/MM/YYYY');
    const initialTime = moment(selectedStartTime).format('HH:mm');
    const fullDate = moment(initialDate + ' ' + initialTime, 'DD/MM/YYYY HH:mm');
    const initialDate2 = moment(selectedDate).format('DD/MM/YYYY');
    const initialTime2 = moment(selectedEndTime).format('HH:mm');
    const fullDate2 = moment(initialDate2 + ' ' + initialTime2, 'DD/MM/YYYY HH:mm');

    return { startTime: fullDate.toISOString(), endTime: fullDate2.toISOString() };
  };

  const onSelect = (key: any, value: any) => setValue(key, value);

  const onSubmit = async () => {
    if (isAllowToSubmit) {
      setIsLoading(true);

      const time = calcTime();

      try {
        // Create/update event
        await planConversation(
          userReviewId,
          {
            startDate: time.startTime,
            endDate: time.endTime,
            includeLinkMeeting: selectedIncludeLinkMeeting,
          },
          false,
        );

        addToast({
          title: i18n._(t`Conversation scheduled`),
          subtitle: i18n._(t`You can find the scheduled conversation in your calendar`),
          type: TOAST_TYPES.SUCCESS,
        });
        onClose(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onChangeDate = (value: string | null) => {
    setValue('selectedDate', convertDateToISOString(moment(value)));
  };

  const onChangeStartTime = (value: string | null) => {
    const selectedEndTime = watch('selectedEndTime');

    const startHours = moment(value).hours();
    const startMinutes = moment(value).minutes();
    const startTime = moment().hours(startHours).minutes(startMinutes);

    // set startTime
    setValue('selectedStartTime', convertDateToISOString(startTime));

    // set endTime = startTime + 30 min, when
    //  - endTime does not exist
    //  - startTime > endTime
    const isStartTimeLaterEndTime =
      startTime &&
      selectedEndTime &&
      moment(startTime).format('HH:mm') >= moment(selectedEndTime).format('HH:mm');
    if (!selectedEndTime || isStartTimeLaterEndTime) {
      setValue('selectedEndTime', convertDateToISOString(startTime.clone().add(30, 'minute')));
    }
  };

  const onChangeEndTime = (value: string | null) => {
    const selectedStartTime = watch('selectedStartTime');

    const endHours = moment(value).hours();
    const endMinutes = moment(value).minutes();
    const endTime = moment().hours(endHours).minutes(endMinutes);

    // set endTime
    setValue('selectedEndTime', convertDateToISOString(endTime));

    // set startTime = endTime - 30 min, when
    // - startTime does not exist
    // - startTime > endTime
    const isStartTimeLaterEndTime =
      selectedStartTime &&
      endTime &&
      moment(selectedStartTime).format('HH:mm') >= moment(endTime).format('HH:mm');

    if (!selectedStartTime || isStartTimeLaterEndTime) {
      setValue('selectedStartTime', convertDateToISOString(endTime.clone().add(-30, 'minute')));
    }
  };

  const convertDateToISOString = (date: Moment) => {
    return date.startOf('minute').toISOString();
  };

  return {
    isLoading,
    isAllowToSubmit,
    onSubmit,
    onSelect,
    onChangeDate,
    onChangeStartTime,
    onChangeEndTime,
  };
};
