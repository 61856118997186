import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const UserPlusIcon = ({ size, ...props }) => (
  <InlineSvg height={size} width={size} {...props} childrenFirst viewBox="0 0 20 18">
    <title>UserPlus</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="editors-icon" transform="translate(-6.000000, -7.000000)">
        <g id="UserPlus" transform="translate(6.000000, 7.000000)">
          <rect id="Rectangle" x="0" y="0" width="18" height="18" />
          <line
            x1="15"
            y1="9"
            x2="19"
            y2="9"
            id="Path"
            stroke="currentColor"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="17"
            y1="7"
            x2="17"
            y2="11"
            id="Path"
            stroke="currentColor"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle id="Oval" stroke="currentColor" strokeWidth="1.2" cx="8" cy="7" r="4" />
          <path
            d="M2,13.9999505 C3.48837744,12.0982363 5.68490935,11 8.00001076,11 C10.3151122,11 12.5116362,12.0982735 14,14"
            id="Path"
            stroke="currentColor"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
  </InlineSvg>
);

UserPlusIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

UserPlusIcon.defaultProps = {
  size: 20,
  className: '',
};

export default UserPlusIcon;
