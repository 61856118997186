import React from 'react';

import { GOAL_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import round from 'lodash/round';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ColoredRow from '~/components/ColoredRow';
import RoleLabel from '~/components/RoleLabel';
import StringInfinite from '~/components/StringInfinite';
import AvatarGroup from '~/components/UI/AvatarGroup';

import { GOAL_STATUSES, ROLES } from '~/constants';
import { GOAL_STYLES } from '~/constants/goals';
import routes from '~/constants/routes';
import { COLOR_PALETTE, COLORS } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -24px;
  padding: 0 24px 20px;
  border-bottom: 1px solid ${COLORS.BORDER_SOFT};
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin: 16px 0px 8px;
`;

const Progress = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  white-space: nowrap;
`;

const Status = styled.div`
  min-width: 62px;
  color: ${(props) => props.color};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  margin-left: 18px;
  margin-right: 10px;
`;

const RoleLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Team = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  background: #e4eefd;
  margin-left: 4px;
  margin-right: 16px;
  min-height: 26px;
  padding: 0px 8px;
  display: flex;
  align-items: center;
`;

const AvatarGroupWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GoalAlignment = ({ goal }) => {
  const children = orderBy(goal.children, [(item) => item?.name?.trim().toLowerCase()], 'asc');

  const { i18n } = useLingui();

  const goToGoal = (id) => {
    routes.GOAL.go({ role: ROLES.USER }, { goalId: id, isBackPath: true });
  };

  const renderItem = (item) => {
    const goalStyles = GOAL_STYLES[item.type];
    const goalStatusStyles = Object.values(GOAL_STATUSES).find((i) => i.key === item.status);

    return (
      <ColoredRow
        key={item.id}
        title={item.name}
        buttons={
          <AvatarGroupWrapper>
            <AvatarGroup users={item.owners} />
          </AvatarGroupWrapper>
        }
        rowIcon={goalStyles?.icon}
        rowStartColor={goalStyles?.color}
        rowColor={goalStyles?.background}
        rowIconColor={goalStyles?.iconColor}
        rowIconBackground={goalStyles?.iconBackground}
        onClick={() => goToGoal(item.id)}
      >
        <RoleLabelWrapper>
          <RoleLabel backgroundColor={COLOR_PALETTE.WHITE}>
            <Row>
              <Progress>{`${round(item.calculatedProgress, 2)}%`}</Progress>
              <Status color={goalStatusStyles?.color}>{goalStatusStyles?.title(i18n)}</Status>
            </Row>
          </RoleLabel>
          {item.type === GOAL_TYPES.TEAM && (
            <Team>
              <StringInfinite maxWidth="120px">{get(item, 'team.name')}</StringInfinite>
            </Team>
          )}
        </RoleLabelWrapper>
      </ColoredRow>
    );
  };

  return (
    <Wrapper>
      <Label>{i18n._(t`Contributes to`)}</Label>
      {goal.parent ? renderItem(goal.parent) : <Trans>No goal</Trans>}
      <Label>{i18n._(t`Impacted by`)}</Label>
      {!isEmpty(children) ? children.map(renderItem) : <Trans>No goals</Trans>}
    </Wrapper>
  );
};

GoalAlignment.propTypes = { goal: PropTypes.object };

export default React.memo(GoalAlignment);
