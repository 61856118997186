import React from 'react';

import { TASK_TYPE } from '@learned/constants';
import { ITask } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { UseFormReturn } from 'react-hook-form';

import { ICONS } from '~/components/Icon';
import type { IReviewDashboardCycleForm } from '~/pages/Reviews/DashboardCycle/ReviewDashboardForm/types';
import { SectionHeader } from '~/pages/Reviews/DashboardCycle/tabs/Setup/components/SectionHeader';

import { COLUMNS } from './columns';
import { StyledTableList, TableWrapper } from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import { SectionField } from '../../components/SectionField';

import type { I18n } from '@lingui/core';

const TASKS_DETAILS = [
  {
    key: TASK_TYPE.REVIEW_PEER_NOMINATE,
    icon: ICONS.NOMINATE_PEER,
    title: (i18n: I18n) => i18n._(t`Ask peers for input`),
  },
  {
    key: TASK_TYPE.REVIEW_SELF_EVALUATE,
    icon: ICONS.EMPLOYEE,
    title: (i18n: I18n) => i18n._(t`Prepare self review`),
  },
  {
    key: TASK_TYPE.REVIEW_PEER_EVALUATE,
    icon: ICONS.PEER,
    title: (i18n: I18n) => i18n._(t`Provide peer review`),
  },
  {
    key: TASK_TYPE.REVIEW_COACH_EVALUATE,
    icon: ICONS.COACH,
    title: (i18n: I18n) => i18n._(t`Provide coach review`),
  },
];

interface ISectionGeneralProps {
  formMethods: UseFormReturn<IReviewDashboardCycleForm>;
  onEdit?: () => void;
}

const SectionTimeline = ({ formMethods, onEdit }: ISectionGeneralProps) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;
  const getMultiLangString = useMultiLangString();

  const watchInvitationMessage = watch('description') || '';
  const watchTasks = watch('tasks');

  const existingTasksIds = Object.keys(watchTasks);
  const tasks = TASKS_DETAILS.filter((item) => existingTasksIds.includes(item.key)).map((item) => ({
    id: item.key,
    type: item.key,
    ...watchTasks[item.key as ITask['type']],
    ...item,
  }));

  const onLockTask = (_task: any) => {
    // TODO
  };

  return (
    <>
      <SectionHeader title={i18n._(t`Timeline`)} onEdit={onEdit} />
      <SectionField
        value={
          watchInvitationMessage
            ? getMultiLangString(turnArrayIntoMultiLang(watchInvitationMessage))
            : ''
        }
        label={i18n._(t`Invitation message`)}
        isRichText={true}
      />
      <TableWrapper>
        <StyledTableList
          data={tasks}
          columns={COLUMNS}
          onColClick={{ column: 'action', onClick: onLockTask }}
        />
      </TableWrapper>
    </>
  );
};

export { SectionTimeline };
