import { IUser } from '@learned/types';
import styled from 'styled-components';

import { SearchSelect } from '~/components/SearchSelect';

const Container = styled.div`
  margin: 16px 0 36px 0;
`;

const SearchPeers = styled(SearchSelect<IUser>)`
  max-width: 100% !important;
`;

export { Container, SearchPeers };
