import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const UsersIcon = ({ size, ...props }) => (
  <InlineSvg height={size} width={size} {...props} childrenFirst viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <circle stroke="currentColor" strokeWidth="1.5" cx="6.875" cy="8.438" r="4.063" />
      <path
        d="M12.142 4.526a4.063 4.063 0 1 1 1.102 7.974M1.25 15.422a6.876 6.876 0 0 1 11.25 0"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.244 12.5a6.867 6.867 0 0 1 5.625 2.921"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </InlineSvg>
);

UsersIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

UsersIcon.defaultProps = {
  size: 20,
  className: '',
};

export default UsersIcon;
