import React from 'react';

import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import InlineSvg from '~/components/InlineSvg';

const Wrapper = styled.div`
  outline: none;
`;

const checkedD =
  'M12 7c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zm0-5C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z';
const uncheckedD =
  'M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z';

const Radio = ({ checked, style, className, onChange, size, fill }) => (
  <Wrapper
    style={style}
    className={className}
    onClick={(e) => onChange(!checked, e)}
    onKeyUp={(e) => onChange(!checked, e)}
    role="button"
    tabIndex={0}
  >
    <InlineSvg
      d={checked ? checkedD : uncheckedD}
      opcity={checked ? 1 : 0.54}
      height={size}
      width={size}
      fill={fill}
    />
  </Wrapper>
);

Radio.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  fill: PropTypes.string,
};

Radio.defaultProps = {
  className: '',
  onChange: () => {},
  style: {
    display: 'flex',
  },
};

export default Radio;
