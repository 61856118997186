import React, { useState, useEffect } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import RTFeedbackAskModal from '~/components/RTFeedbackAskModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import Divider from '~/components/UI/Divider';

import { ButtonRow } from './design';

import { RT_FEEDBACK_TYPES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { checkModuleReviewQuickSelect } from '~/selectors/baseGetters';
import { getGoalRTFeedbacks } from '~/services/RTFeedbacks';

import { RTFeedbackBlock } from '../RTFeedbackBlock';

import type { FEEDBACK_TYPE } from '@learned/constants';
import type { IGoal } from '@learned/types';

const FeedbackCard = ({ isOwner, goal }: { isOwner: boolean; goal: IGoal }) => {
  const { i18n } = useLingui();
  const isModuleReviewQuickSelect = useSelector(checkModuleReviewQuickSelect);
  const [rtFbType, setRtFbType] = useState<FEEDBACK_TYPE | null>(null);
  const $isRTFBModal = useBoolState(false);
  const $isUpdateFeedbacks = useBoolState(false);
  const [feedbacks, setFeedbacks] = useState([]);
  const currentDate = new Date();
  const [startFilterDate, setStartFilterDate] = useState(
    new Date(
      currentDate.getFullYear() - 1,
      currentDate.getMonth(),
      currentDate.getDate(),
    ).toISOString(),
  );
  const [endFilterDate, setEndFilterDate] = useState(currentDate.toISOString());
  const [isLoading, setIsLoading] = useState(false);

  const fetchFeedbacks = async (isMounted: boolean) => {
    if (goal.id) {
      setIsLoading(true);
      const RTFB = await getGoalRTFeedbacks({
        goalId: goal.id,
        startDate: startFilterDate,
        endDate: endFilterDate,
      });
      if (isMounted) {
        setFeedbacks(Object.values(RTFB));
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (goal?.id) {
      fetchFeedbacks(isMounted);
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [goal, startFilterDate, endFilterDate]);

  useEffect(() => {
    let isMounted = true;

    if ($isUpdateFeedbacks.value) {
      fetchFeedbacks(isMounted);
      $isUpdateFeedbacks.off();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [$isUpdateFeedbacks.value]);

  const showAskRTFeedbackModal = (type: FEEDBACK_TYPE) => {
    setRtFbType(type);
    $isRTFBModal.on();
  };

  const hideRTFBModal = () => {
    $isUpdateFeedbacks.on();
    $isRTFBModal.off();
    setRtFbType(null);
  };

  const isShowGiveFbButton = isModuleReviewQuickSelect && !isOwner;

  return (
    <>
      <Divider />
      {(isOwner || isShowGiveFbButton) && (
        <ButtonRow>
          <Button
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            disabled={isLoading}
            onClick={() =>
              showAskRTFeedbackModal(
                isOwner
                  ? (RT_FEEDBACK_TYPES.ASK_FEEDBACK.key as FEEDBACK_TYPE)
                  : (RT_FEEDBACK_TYPES.GIVE_FEEDBACK.key as FEEDBACK_TYPE),
              )
            }
            label={isOwner ? i18n._(t`Ask feedback`) : i18n._(t`Give feedback`)}
          />
        </ButtonRow>
      )}
      <ShowSpinnerIfLoading loading={isLoading}>
        <RTFeedbackBlock
          feedbacks={feedbacks}
          startFilterDate={startFilterDate}
          setStartFilterDate={setStartFilterDate}
          endFilterDate={endFilterDate}
          setEndFilterDate={setEndFilterDate}
        />
      </ShowSpinnerIfLoading>
      {$isRTFBModal.value && (
        <RTFeedbackAskModal
          onClose={hideRTFBModal}
          type={rtFbType}
          goal={goal}
          userId={
            rtFbType === RT_FEEDBACK_TYPES.GIVE_FEEDBACK.key && goal?.owners && goal?.owners[0]
          }
        />
      )}
    </>
  );
};

export { FeedbackCard };
