import React, { Component } from 'react';

import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import arrowLeft from '~/assets/arrow-left.svg';
import arrowRight from '~/assets/arrow-right.svg';

import { COLOR_PALETTE } from '~/styles';

const ModalBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${COLOR_PALETTE.WHITE};
  z-index: 1000;
  border: solid 0.5px #c7cfe0;
`;

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  z-index: 1001;
  overflow-y: auto;
`;

const OverlayContainer = styled.div`
  position: relative;
  margin: ${(props) =>
    props.centerModal ? 'auto' : props.marginTop ? '50px auto auto' : props.margin || 'auto'};
`;

const Arrow = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 10px;
  box-shadow: 5px 10px 14px 0 rgba(55, 70, 95, 0.08);
  border: solid 0.5px #ecedf0;
  background-color: ${COLOR_PALETTE.WHITE};

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props) => (props.left ? 'left: -88px' : 'right: -88px')};

  &:hover {
    background-color: #f8f8f8;
    cursor: pointer;
  }

  & div {
    margin: 16px;
  }
`;

class Dialog extends Component {
  static propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func,
    borderRadius: PropTypes.number,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // number or Auto
    minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // number or Auto
    minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // number or Auto,
    maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // number or Auto,
    isCloseOnOutsideClick: PropTypes.bool,
    onArrowClick: PropTypes.func,
    marginTop: PropTypes.string,
    margin: PropTypes.string,
    centerModal: PropTypes.bool,
    ignoreModalStyle: PropTypes.bool,
  };

  static defaultProps = {
    borderRadius: 4,
    width: 888,
    minWidth: 888,
    minHeight: 'auto',
    maxHeight: 'auto',
    hideHeaderClose: false,
    isCloseOnOutsideClick: false,
    ignoreModalStyle: false,
    onClose: () => {},
  };

  static count = 0;

  getModalStyle = () => {
    const { width, minWidth, minHeight, maxHeight, height, boxShadow, borderRadius, marginTop } =
      this.props;

    return {
      width: width > 0 ? `${width}px` : width,
      minWidth: minWidth > 0 ? `${minWidth > width ? width : minWidth}px` : minWidth,
      minHeight: minHeight > 0 ? `${minHeight}px` : minHeight,
      maxHeight: minHeight > 0 ? `${maxHeight}px` : maxHeight,
      height: height > 0 ? `${height}px` : height,
      borderRadius: `${borderRadius}px`,
      boxShadow,
      marginTop,
    };
  };

  stop = (e) => e.stopPropagation();

  prev = () => this.props.onArrowClick(-1);

  next = () => this.props.onArrowClick(1);

  render() {
    const {
      onClose,
      children,
      className,
      isCloseOnOutsideClick,
      onArrowClick,
      isAlreadyAPlan,
      centerModal,
      backgroundColor,
      marginTop,
      ignoreModalStyle,
      margin,
    } = this.props;

    return ReactDOM.createPortal(
      <Overlay
        backgroundColor={backgroundColor ? backgroundColor : 'rgba(12, 51, 51, 0.5)'}
        onClick={(e) => (isCloseOnOutsideClick ? onClose(e) : null)}
      >
        <OverlayContainer centerModal={centerModal} marginTop={marginTop} margin={margin}>
          <ModalBlock
            className={className}
            style={ignoreModalStyle ? undefined : this.getModalStyle()}
            onClick={this.stop}
          >
            {onArrowClick && !isAlreadyAPlan && (
              <Arrow left onClick={this.prev}>
                <SvgIcon width="24px" height="24px" url={arrowLeft} />
              </Arrow>
            )}
            {children}
            {onArrowClick && !isAlreadyAPlan && (
              <Arrow onClick={this.next}>
                <SvgIcon width="24px" height="24px" url={arrowRight} />
              </Arrow>
            )}
          </ModalBlock>
        </OverlayContainer>
      </Overlay>,
      document.body,
    );
  }
}

export default Dialog;
