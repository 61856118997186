import React, { PureComponent } from 'react';

import { withI18n } from '@lingui/react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import Spinner from '~/components/Spinner';
import Tooltip from '~/components/Tooltip';

import { COLOR_PALETTE, COLORS } from '~/styles';

export const BaseButton = styled.button`
  height: 40px;
  border-radius: 6px;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  position: relative;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  white-space: nowrap;

  &:disabled {
    cursor: unset;
    background-color: #ccced5;
    font-weight: normal;
  }

  &.button-new_small {
    padding: 7px 16px;
    height: 32px;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const ButtonPrimary = styled(BaseButton)`
  background-color: var(--company-color);
  color: ${COLOR_PALETTE.WHITE};
  border-radius: 6px;
  border: none;

  &:hover {
    background-color: ${(props) => (props.disabled ? '#ccced5' : 'var(--company-color)')};
    opacity: 0.9;
  }
`;

export const ButtonWhite = styled(BaseButton)`
  border-style: solid;
  border-width: 1px;
  border-color: var(--company-color);
  color: var(--company-color);
  background-color: ${COLOR_PALETTE.WHITE};

  &:hover {
    background-color: ${(props) => (props.disabled ? '#ccced5' : 'var(--company-color)')};
    color: ${(props) => (props.disabled ? 'var(--company-color)' : COLOR_PALETTE.WHITE)};
  }
`;

const ButtonPrimaryLight = styled(BaseButton)`
  color: var(--company-color);
  border: none;
  background-color: rgba(41, 204, 171, 0.15);
  width: 96px;
  min-width: 96px;
  padding: 0;
  height: 32px;
  box-sizing: border-box;

  &:hover {
    background-color: ${(props) => (props.disabled ? '#ccced5' : COLORS.COMPANY)};
    color: ${COLOR_PALETTE.WHITE};
  }
`;

export const ButtonPrimaryBorder = styled(BaseButton)`
  color: var(--company-color);
  border: 1px solid var(--company-color);
  border-radius: 6px;
  background-color: ${COLOR_PALETTE.WHITE};
  width: auto;
  min-width: auto;
  padding: 0 16px;
  height: 40px;
  box-sizing: border-box;
  &:disabled {
    color: ${COLORS.SUBTEXT};
    background: none;
    border: 1px solid ${COLORS.SUBTEXT};
    font-weight: normal;
  }
  ${(props) =>
    props.isWhite && {
      backgroundColor: '${COLOR_PALETTE.WHITE}',
      border: 'none',
    }}

  &:hover {
    background-color: ${(props) => (props.disabled ? 'none' : COLORS.COMPANY)};
    color: ${(props) => (props.disabled ? COLORS.SUBTEXT : COLORS.WHITE)};
    border-color: ${(props) => (props.disabled ? COLORS.SUBTEXT : COLORS.COMPANY)};
  }

  &:disabled {
    cursor: no-drop;
  }
`;

export const ButtonPrimaryShadow = styled.button`
  color: ${COLOR_PALETTE.WHITE};
  background-color: var(--company-color);
  border-radius: 50rem;
  border: none;

  box-shadow: 0 2px 4px 0 rgba(191, 191, 191, 0.5);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  height: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  &:disabled {
    color: #dcdcdc;
  }
`;

export const ButtonPrimaryBorderWide = styled(ButtonPrimaryBorder)`
  border-radius: 3px;
  width: 100%;
  height: 48px;
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`;

const IconRightWrapper = styled.div`
  margin: 0 5px;
  display: flex;
  align-items: center;
`;

const ButtonSecondaryLink = styled.button`
  background: none;
  color: #70747f;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
`;

const ButtonPrimaryLink = styled.button`
  background: none;
  color: ${(props) => (props.disabled ? COLOR_PALETTE.GRAY_LIGHT : 'var(--company-color)')};
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
`;

const ButtonDelete = styled.button`
  background: none;
  min-width: 120px;
  color: #e97677;
  border: 3px solid #e97677;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
  border-radius: 6px;
  font-size: 12px;
`;

const ButtonBlue = styled(ButtonPrimary)`
  background-color: #3971e9;
  border: solid 1px #3971e9;

  &:hover {
    background-color: #3971e9;
    opacity: 0.9;
  }
`;

export const ButtonShadow = styled.button`
  color: var(--company-color);
  background-color: ${COLOR_PALETTE.WHITE};
  border-radius: 50rem;
  border: none;

  &:not(:hover) {
    box-shadow: 0 2px 4px 0 rgba(145, 157, 165, 0.12);
  }
  padding: 6.5px 23px 8.5px;
  cursor: pointer;

  &:disabled {
    color: #dcdcdc;
  }
`;

const SpinnerHolder = styled.div`
  background: none;
  min-width: 120px;
  text-align: left;
  margin-top: 10px;
`;

const noMultiRun = (fn) => {
  return typeof fn === 'function' ? debounce(fn, 500, { leading: true, trailing: false }) : fn;
};

/**
 * @deprecated since version 3.0 {@see components/Buttons/index}
 */
class Button extends PureComponent {
  static types = {
    primary: 'primary',
    primaryLight: 'primary-light',
    white: 'white',
    primaryBorder: 'primary-border',
    primaryBorderWide: 'primary-border-wide',
    linkPrimary: 'link-primary',
    linkSecondary: 'link-secondary',
    delete: 'delete',
    blue: 'blue',
    shadow: 'shadow',
    primaryShadow: 'primary-shadow',
  };

  static propTypes = {
    tabIndex: PropTypes.number,
    label: PropTypes.any,
    type: PropTypes.oneOf(values(Button.types)),
    width: PropTypes.number,
    height: PropTypes.number,
    loading: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    styles: PropTypes.object,
    buttonContentStyles: PropTypes.object,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    iconLeft: PropTypes.node,
    iconLeftStyles: PropTypes.object,
    iconRight: PropTypes.node,
    small: PropTypes.bool,
    tooltip: PropTypes.string,
  };

  static defaultProps = {
    type: 'primary',
    loading: false,
    className: '',
    onClick: () => {},
    onFocus: () => {},
    onBlur: () => {},
  };

  getComponent() {
    const { isWhite, className, type, small } = this.props;
    let addClass = '';
    if (small) {
      addClass = 'button-new_small';
    }

    switch (type) {
      case Button.types.white: {
        return {
          CurrentButton: ButtonWhite,
          props: { className: classNames(addClass, className) },
        };
      }
      case Button.types.primaryLight: {
        return {
          CurrentButton: ButtonPrimaryLight,
          props: { className },
        };
      }
      case Button.types.primaryBorder: {
        return {
          CurrentButton: ButtonPrimaryBorder,
          props: { isWhite, className: classNames(addClass, className) },
        };
      }
      case Button.types.primaryBorderWide: {
        return {
          CurrentButton: ButtonPrimaryBorderWide,
          props: { isWhite, className },
        };
      }
      case Button.types.linkPrimary: {
        return {
          CurrentButton: ButtonPrimaryLink,
          props: { className },
        };
      }
      case Button.types.linkSecondary: {
        return {
          CurrentButton: ButtonSecondaryLink,
          props: { className },
        };
      }
      case Button.types.delete: {
        return {
          CurrentButton: ButtonDelete,
          props: { className },
        };
      }
      case Button.types.blue: {
        return {
          CurrentButton: ButtonBlue,
          props: { className },
        };
      }
      case Button.types.primaryShadow: {
        return {
          CurrentButton: ButtonPrimaryShadow,
          props: { className },
        };
      }
      case Button.types.shadow: {
        return {
          CurrentButton: ButtonShadow,
          props: { className },
        };
      }
      default: {
        return {
          CurrentButton: ButtonPrimary,
          props: { className: classNames(addClass, className) },
        };
      }
    }
  }

  render() {
    const {
      label,
      type,
      width,
      height,
      marginLeft,
      marginRight,
      onClick,
      loading,
      disabled,
      styles,
      buttonContentStyles,
      onFocus,
      onBlur,
      tabIndex,
      iconRight,
      iconLeft,
      iconLeftStyles,
      buttonRef,
      tooltip,
      i18n,
      buttonType,
    } = this.props;

    let buttonStyles = { ...styles } || {};
    if (width) {
      buttonStyles.width = `${width}px`;
      buttonStyles.minWidth = `${width}px`;
    }
    if (height) {
      buttonStyles.height = `${height}px`;
    }
    if (marginLeft) {
      buttonStyles.marginLeft = `${marginLeft}px`;
    }

    if (marginRight) {
      buttonStyles.marginRight = `${marginRight}px`;
    }

    if (loading && type === 'delete') {
      return (
        <SpinnerHolder>
          <Spinner type="button-primary" />
        </SpinnerHolder>
      );
    }

    const { CurrentButton, props } = this.getComponent();

    const button = (
      <CurrentButton
        type={buttonType || 'button'}
        tabIndex={tabIndex}
        style={buttonStyles}
        onClick={noMultiRun(onClick)}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={loading || disabled}
        ref={buttonRef}
        {...props}
      >
        <ShowSpinnerIfLoading type="button-primary" loading={loading}>
          <ButtonContent style={buttonContentStyles}>
            {iconLeft && (
              <IconRightWrapper
                style={{ marginLeft: '0', marginRight: '8px', ...(iconLeftStyles || {}) }}
              >
                {iconLeft}
              </IconRightWrapper>
            )}
            {typeof label === 'string' ? i18n._(label) : label}
            {iconRight && <IconRightWrapper>{iconRight}</IconRightWrapper>}
          </ButtonContent>
        </ShowSpinnerIfLoading>
      </CurrentButton>
    );

    return tooltip ? (
      <Tooltip tooltip={typeof tooltip === 'string' ? i18n._(tooltip) : tooltip}>
        <div>{button}</div>
      </Tooltip>
    ) : (
      button
    );
  }
}

const ExportButton = withI18n()(Button);
ExportButton.types = Button.types;
ExportButton.displayName = 'Button';

export const ButtonTypes = Button.types; // for TS

export default ExportButton;
