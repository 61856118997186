import React from 'react';

import { TASK_STATUS } from '@learned/constants';
import isNull from 'lodash/isNull';
import styled from 'styled-components';

import { ICON_SIZES, Icon, ICONS } from '~/components/Icon';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { ICell } from '~/pages/Reviews/DashboardCycle/tabs/Participation/PerTeamSection/types';

import { Placeholder } from './Placeholder';
import { TaskStatusLabel } from './TaskStatusLabel';

import { COLORS } from '~/styles';

import { CellViewWrapper } from '../../designs';

const WarningWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const WarningNumber = styled.div`
  font-size: 12px;
  line-height: 1.5;
  color: ${COLORS.ACCENT_WARNING};
`;

export const StatusIcon = styled(Icon)`
  margin-right: 4px;
`;

type ICellViewTaskStatusProps = {
  status: TASK_STATUS | null;
  columnId: ICell['columnId'];
  withWarning?: ICell['withWarning'];
  warningTooltip?: string;
  isWarningCounter?: boolean;
};

const CellViewTaskStatus = ({
  columnId,
  status,
  withWarning,
  warningTooltip,
  isWarningCounter = false,
}: ICellViewTaskStatusProps) => {
  const isWarning = Boolean(withWarning); // amount of warnings

  return (
    <CellViewWrapper>
      {isNull(status) || !status ? (
        <Placeholder columnId={columnId} />
      ) : (
        <TaskStatusLabel status={status} />
      )}
      {isWarning && (
        <Tooltip tooltip={warningTooltip} size={TOOLTIP_SIZES.MEDIUM}>
          {/* added span to display tooltip, without span tooltip does not work for icon */}
          <WarningWrapper>
            <StatusIcon
              icon={ICONS.WARNING_2}
              size={ICON_SIZES.SMALL}
              color={COLORS.ACCENT_WARNING}
            />
            {isWarningCounter && <WarningNumber>{withWarning}</WarningNumber>}
          </WarningWrapper>
        </Tooltip>
      )}
    </CellViewWrapper>
  );
};

export { CellViewTaskStatus };
