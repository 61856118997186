import React, { ReactNode } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import allDone from '~/assets/all-done-state.webp';
import emptyState from '~/assets/empty-state.webp';
import noResult from '~/assets/no-result.webp';

import { COLORS } from '~/styles';

import { Button, ButtonSize, ButtonVariant } from '../Buttons';

const ImageWrapper = styled.div<{
  imgPadding?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    ${({ imgPadding }) => imgPadding && `padding: ${imgPadding};`}
  }
`;

const PlaceholderText = styled.div`
  max-width: 300px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #909cc2;
  margin-top: 30px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  background-color: ${COLORS.WHITE};
`;

const EmptyButtonWrapper = styled.div`
  margin-top: 20px;
`;

interface ITablePlaceholderProps {
  isLoading?: boolean;
  isFiltered?: boolean;
  noResultText?: ReactNode;
  emptyStateText?: ReactNode;
  onResetSearch?: () => void;
  isAllDone?: boolean;
  emptyButtonText?: string;
  onClickEmptyButton?: () => void;
}

const TablePlaceholder = ({
  isLoading,
  isFiltered,
  noResultText,
  emptyStateText,
  onResetSearch,
  isAllDone = false,
  emptyButtonText,
  onClickEmptyButton,
}: ITablePlaceholderProps) => {
  const { i18n } = useLingui();
  return (
    <Wrapper>
      <ShowSpinnerIfLoading height="150px" loading={!!isLoading}>
        {isFiltered && !isAllDone ? (
          <ImageWrapper>
            <img src={emptyState} alt={i18n._(t`No result`)} width="216px" />
            <PlaceholderText>
              {noResultText || i18n._(t`No results found bases on your search…`)}
            </PlaceholderText>
            <Button
              type="button"
              label={i18n._(t`Reset search`)}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.TEXT_PRIMARY}
              onClick={onResetSearch}
            />
          </ImageWrapper>
        ) : (
          <ImageWrapper imgPadding="0 20px 0 0">
            {isAllDone ? (
              <img src={allDone} alt={i18n._(t`No data found`)} width="216px" />
            ) : (
              <img src={noResult} alt={i18n._(t`No data found`)} width="216px" />
            )}
            {emptyStateText && <PlaceholderText>{emptyStateText}</PlaceholderText>}
            {emptyButtonText && (
              <EmptyButtonWrapper>
                <Button
                  type="button"
                  label={emptyButtonText}
                  size={ButtonSize.MEDIUM}
                  variant={ButtonVariant.PRIMARY}
                  onClick={onClickEmptyButton}
                />
              </EmptyButtonWrapper>
            )}
          </ImageWrapper>
        )}
      </ShowSpinnerIfLoading>
    </Wrapper>
  );
};

export { TablePlaceholder };
