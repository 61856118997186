const createCsvBlob = (csvText: string) => {
  return new Blob([csvText], { type: 'text/csv;charset=utf-8;' });
};

const downloadCsvFile = (csvBlob: Blob, fileName: string) => {
  const url = URL.createObjectURL(csvBlob);

  const link = document.createElement('a');

  link.href = url;
  link.download = `${fileName}.csv`;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

export { createCsvBlob, downloadCsvFile };
