import { DAY_OF_WEEK } from './dates';

export const DEFAULT_GOAL_UPDATE_SETTINGS = {
  isEnabled: false,
  weekDay: DAY_OF_WEEK.FRIDAY,
  repeat: 1,
  showTaskDaysBefore: 1,
  hideTaskDaysAfter: 2,
  isReminderOnTaskCreate: true,
  isReminderInDeadlineDay: true,
}