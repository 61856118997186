import React from 'react';

import { Trans, t } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';

import OtherActivitiesRow from './OtherActivitiesRow';

import CardDevelopmentCycleIcon from '~/assets/card-development-cycle.svg';

import { checkModuleConversations } from '~/selectors/baseGetters';
import {
  getGiveFeedbackSettings,
  getAskFeedbackSettings,
  getGoalUpdatesSettings,
} from '~/selectors/companySettings';
import { COLOR_PALETTE, COLOR_UNIT } from '~/styles';

const Wrapper = styled.div``;

const OtherActivities = () => {
  const isConversationModule = useSelector(checkModuleConversations);
  const goalUpdatesSettings = useSelector(getGoalUpdatesSettings);
  const giveFeedbackSettings = useSelector(getGiveFeedbackSettings);
  const askFeedbackSettings = useSelector(getAskFeedbackSettings);

  const feedbackDescription = (i18n) => {
    if (askFeedbackSettings.isEnabled && giveFeedbackSettings.isEnabled) {
      return i18n._(
        t`Ask someone for feedback every ${askFeedbackSettings.repeat} week(s) and give someone a compliment every ${giveFeedbackSettings.repeat} week(s).`,
      );
    } else if (askFeedbackSettings.isEnabled) {
      return i18n._(t`Ask someone for feedback every ${askFeedbackSettings.repeat} week(s).`);
    } else if (giveFeedbackSettings.isEnabled) {
      return i18n._(t`Give someone a compliment every ${giveFeedbackSettings.repeat} week(s).`);
    }
    return '';
  };

  const items = [
    isConversationModule && {
      title: (i18n) => i18n._(t`1:1’s`),
      description: (i18n) => i18n._(t`Schedule a (by)weekly 1:1 with your coach or colleagues.`),
      icon: COLOR_UNIT.CONVERSATION.icon,
      color: COLOR_UNIT.CONVERSATION.primary,
      background: COLOR_UNIT.CONVERSATION.secondary,
    },
    (giveFeedbackSettings.isEnabled || askFeedbackSettings.isEnabled) && {
      title: (i18n) => i18n._(t`Ask Feedback`),
      description: (i18n) => feedbackDescription(i18n),
      icon: COLOR_UNIT.FEEDBACK.icon,
      color: COLOR_UNIT.FEEDBACK.primary,
      background: COLOR_UNIT.FEEDBACK.secondary,
    },
    goalUpdatesSettings.isEnabled && {
      title: (i18n) => i18n._(t`Goal checkins`),
      description: (i18n) =>
        i18n._(
          t`Let your coach know how you are doing. Update your goals every ${goalUpdatesSettings.repeat} week(s).`,
        ),
      icon: COLOR_UNIT.GOAL.icon,
      color: COLOR_UNIT.GOAL.primary,
      background: COLOR_UNIT.GOAL.secondary,
    },
  ].filter((i) => i);

  return (
    <div>
      {isEmpty(items) ? (
        <Placeholder
          title={<Trans>Nothing here yet..</Trans>}
          subTitle={<Trans>Your other development activities will show here</Trans>}
          Icon={() => (
            <SvgIcon
              url={CardDevelopmentCycleIcon}
              width="32px"
              height="32px"
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
          )}
        />
      ) : (
        <Wrapper>
          {items.map((item, key) => (
            <OtherActivitiesRow key={key} item={item} />
          ))}
        </Wrapper>
      )}
    </div>
  );
};

export default React.memo(OtherActivities);
