import { ROLES } from '~/constants';
import { pageOverview } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'reports';

// overview
export const NINE_GRID = new LinkConstructor([ROLES.USER, ROLES.COACH, ROLES.ADMIN], () =>
  pageOverview(routeName),
);

export const NINE_GRID_REPORT = new LinkConstructor(
  [ROLES.ADMIN],
  () => `${routeName}/performance/nine-grid`,
);
