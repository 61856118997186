import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import InfoMessage from '~/components/InfoMessage';
import Modal from '~/components/Modal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import TabsForIntegrations from '~/components/TabsForIntegrations';

import IntegrationContent from './IntegrationContent';

const Wrapper = styled.div``;

const WarningMessage = styled.div`
  padding: 19px 19px 18px 16px;
  margin: 0 24px;
  border-radius: 3px;
  border: solid 1px #f9fafc;
  background-color: #ffe8e8;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #e93c3c;
`;

const inviteModalHeaderStyles = {
  padding: '0 24px',
};

const inviteModalContentStyles = {
  padding: '0',
};

function CleanUpModal({
  i18n,
  title,
  loading,
  integrations,
  onClose,
  onSubmit,
  placeholder,
  warningMessage,
  confirmMessage,
  integrationExplanation,
  integrationPlaceholder,
  getIntegrationCols,
  getIntegrationRowData,
  isSubmitDisabled,
  infoMessage,
  infoUrl,
  pathToItems,
}) {
  return (
    <Modal
      title={title}
      onClose={onClose}
      minWidth={1100}
      width={1100}
      headerStyles={inviteModalHeaderStyles}
      contentStyles={inviteModalContentStyles}
      footerRight={
        <Button
          label={i18n._(t`Clean-up`)}
          onClick={async () => {
            if (await confirm(i18n, confirmMessage)) {
              onSubmit();
            }
          }}
          disabled={isSubmitDisabled}
          loading={loading}
          type="primary"
        />
      }
    >
      <ShowSpinnerIfLoading loading={loading}>
        {isEmpty(integrations) ? (
          placeholder
        ) : (
          <Wrapper>
            <InfoMessage infoMessage={infoMessage} infoUrl={infoUrl} />
            {warningMessage && <WarningMessage>{warningMessage}</WarningMessage>}
            <TabsForIntegrations integrations={integrations} pathToItems={pathToItems}>
              <IntegrationContent
                integration={{}} // selected integration will be added in TabsForIntegrations component
                pathToItems={pathToItems}
                integrationPlaceholder={integrationPlaceholder}
                integrationExplanation={integrationExplanation}
                getIntegrationCols={getIntegrationCols}
                getIntegrationRowData={getIntegrationRowData}
              />
            </TabsForIntegrations>
          </Wrapper>
        )}
      </ShowSpinnerIfLoading>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    jobProfiles: state.users.jobProfiles,
  };
};

CleanUpModal.propTypes = {
  title: PropTypes.string.isRequired,
  integrations: PropTypes.array.isRequired, // each integration should have items param to display items
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.node.isRequired,
  pathToItems: PropTypes.string.isRequired,
  warningMessage: PropTypes.string,
  confirmMessage: PropTypes.string,
  integrationExplanation: PropTypes.string,
  integrationPlaceholder: PropTypes.node.isRequired,
  getIntegrationCols: PropTypes.func.isRequired,
  getIntegrationRowData: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
  infoMessage: PropTypes.string,
  infoUrl: PropTypes.string,
};

export default withI18n()(connect(mapStateToProps)(CleanUpModal));
