import React from 'react';

import { ICONS, Icon } from '~/components/Icon';

import { SubSectionTitle, SectionHeaderWithIconRow } from '../../design';

interface SectionHeaderWithIconProps {
  title: string;
  icon: ICONS;
  marginTop?: string;
  marginBottom?: string;
}

const SectionHeaderWithIcon = ({
  title,
  icon,
  marginBottom,
  marginTop,
}: SectionHeaderWithIconProps) => {
  return (
    <>
      <SectionHeaderWithIconRow marginBottom={marginBottom} marginTop={marginTop}>
        <Icon icon={icon} />
        <SubSectionTitle>{title}</SubSectionTitle>
      </SectionHeaderWithIconRow>
    </>
  );
};

export { SectionHeaderWithIcon };
