import styled from 'styled-components';

import { Button } from '~/components/Buttons';
import { TableList } from '~/components/TableList';

import { COLORS } from '~/styles';

export const Title = styled.h2`
  font-size: 26px;
  color: ${COLORS.BLACK};
  font-weight: normal;
  margin-top: 4px;
  margin-bottom: 0;
  line-height: 1;
  letter-spacing: -0.29px;
`;

export const ContentWrapper = styled.div`
  margin: 23px 37px 26px 38px;
`;

export const ListContainer = styled.div`
  margin-top: 14px;
`;

export const FooterSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const BackButton = styled(Button)`
  margin-right: 10px;
`;

export const AddButton = styled(Button)`
  width: 126px;
`;

export const StyledTableList = styled(TableList)`
  padding: 0;

  table {
    padding: 0;
  }

  &:first-child {
    > div:first-child {
      padding: 0 0 20px 0;
    }
  }
`;
