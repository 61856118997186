import styled, { css } from 'styled-components';

import { Icon } from '~/components/Icon';

import { COLORS } from '~/styles';

export const Title = styled.span`
  font-size: 26px;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
`;

export const Subtitle = styled.span`
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${COLORS.SUBTEXT};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px ${COLORS.BORDERS};
  padding-bottom: 7px;
  margin-bottom: 20px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
`;

export const Level = styled.div<{ isEmpty: boolean }>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.isEmpty
      ? css`
          gap: 1px;
        `
      : css`
          gap: 3px;
        `}
  \`;
`;

export const DroppableContainer = styled.div<{ isEmpty: boolean }>`
  ${(props) =>
    props.isEmpty &&
    css`
      min-height: 1px;
    `}
`;

export const LevelHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 22px;
  border-radius: 6px;
  background-color: ${COLORS.INFO_LIGHT};
  justify-content: space-between;
  align-items: center;
`;

export const FocusArea = styled.div`
  padding: 15px 15px;
  min-height: 20px;
  border-radius: 6px;
  background-color: ${COLORS.BG_LIST};
  font-size: 12px;
  color: ${COLORS.TEXT_MAIN};
  margin-top: 3px;

  :hover {
    background-color: ${COLORS.HOVER};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  padding: 6px 10px;
  border-radius: 6px;
  border: solid 1.2px ${COLORS.BORDERS};
`;

export const AddNewFocusAreaButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
  background-color: ${COLORS.BG_LIST};
  color: ${COLORS.BORDERS};
  box-sizing: border-box;
  border: dashed 1px transparent;
  :hover {
    background-color: ${COLORS.HOVER};
    border: dashed 1px ${COLORS.BORDERS};
    color: ${COLORS.COMPANY};
  }
`;

export const Count = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  font-size: 12px;
  color: ${COLORS.SUBTEXT};
`;

export const LevelName = styled.span`
  font-size: 12px;
  align-items: center;
  font-weight: 600;
  color: ${COLORS.SUBTEXT};
  text-transform: uppercase;
`;

export const StyledIcon = styled(Icon)<{ $opened: boolean }>`
  transform: ${({ $opened }) => ($opened ? 'rotate(0deg)' : 'rotate(90deg)')};
`;

export const Wrapper = styled.div<{ $hideSeparator: boolean }>`
  display: flex;
  justify-content: space-between;
  ${(props) =>
    !props.$hideSeparator &&
    css`
      border-top: solid 1px ${COLORS.BORDERS};
    `}
  padding-top: 37px;
  margin-top: ${(props) => (!props.$hideSeparator ? 37 : 0)}px;
  > div {
    flex: 1;
    display: flex;
  }
`;

export const RightActionContainer = styled.div`
  justify-content: end;
`;
