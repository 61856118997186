import React from 'react';

import { REVIEW_QUESTION_EVALUATORS } from '@learned/constants';
import { I18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';
import { UseFormReturn } from 'react-hook-form';

import { ICONS } from '~/components/Icon';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';

import { SectionHeaderWithIcon } from './SectionHeaderWithIcon';
import { SummaryField } from './SummaryField';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import { SectionGrid, SectionTitle } from '../design';

import type { IReviewIndividualForm, TasksType } from '../../../types';

interface TimelineSummaryProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewIndividualForm>;
}

type TaskItem = {
  title: (i18n: I18n) => string;
  icon: ICONS;
  key: TasksType;
};

const TASKS: Array<TaskItem> = [
  {
    key: 'reviewPeerNominate',
    icon: ICONS.NOMINATE_PEER,
    title: (i18n: I18n) => i18n._(t`Ask peers for input`),
  },
  {
    key: 'reviewSelfEvaluate',
    icon: ICONS.EMPLOYEE,
    title: (i18n: I18n) => i18n._(t`Self review`),
  },
  {
    key: 'reviewPeerEvaluate',
    icon: ICONS.PEER,
    title: (i18n: I18n) => i18n._(t`Peer review`),
  },
  {
    key: 'reviewCoachEvaluate',
    icon: ICONS.COACH,
    title: (i18n: I18n) => i18n._(t`Coach review`),
  },
];

const TimelineSummary = ({ formMethods }: TimelineSummaryProps) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;
  const getMultiLangString = useMultiLangString();

  const getFormattedDate = (date: Date | null) => {
    const datePattern = 'DD-MM-YYYY';
    return date ? moment(date).format(datePattern) : i18n._(t``);
  };

  const multiLangInvitationName = turnArrayIntoMultiLang(watch('description') || []);

  const getTaskSection = (task: TaskItem) => (
    <>
      <SectionHeaderWithIcon
        title={task.title(i18n)}
        icon={task.icon}
        marginTop="19px"
        marginBottom="14px"
      />
      <SectionGrid>
        <SummaryField
          label={i18n._(t`Start date`)}
          value={getFormattedDate(watch(`tasks.${task.key}.startDate`))}
        />
        <SummaryField
          label={i18n._(t`Deadline`)}
          value={getFormattedDate(watch(`tasks.${task.key}.endDate`))}
        />
      </SectionGrid>
    </>
  );

  const evaluators = watch('evaluators').map(
    (evaluator: { value: REVIEW_QUESTION_EVALUATORS; icon: ICONS; title: string }) =>
      evaluator.value,
  );

  return (
    <>
      <>
        <SectionTitle marginBottom="16px">
          <Trans>Invitation</Trans>
        </SectionTitle>
        <SummaryField
          label={i18n._(t`Invitation message`)}
          value={getMultiLangString(multiLangInvitationName)}
          isRichText
        />
      </>

      <>
        <SectionTitle marginBottom="16px" marginTop="32px">
          <Trans>Timeline</Trans>
        </SectionTitle>
        <SectionGrid>
          <SummaryField
            label={i18n._(t`Start date`)}
            value={getFormattedDate(watch('settings.startDate'))}
          />
          <SummaryField
            label={i18n._(t`Deadline`)}
            value={getFormattedDate(watch('settings.endDate'))}
          />
        </SectionGrid>

        {TASKS.map((item) => {
          switch (true) {
            case item.key === 'reviewPeerNominate' &&
              evaluators.includes(REVIEW_QUESTION_EVALUATORS.PEER):
              return getTaskSection(item);
            case item.key === 'reviewSelfEvaluate' &&
              evaluators.includes(REVIEW_QUESTION_EVALUATORS.EMPLOYEE):
              return getTaskSection(item);
            case item.key === 'reviewPeerEvaluate' &&
              evaluators.includes(REVIEW_QUESTION_EVALUATORS.PEER):
              return getTaskSection(item);
            case item.key === 'reviewCoachEvaluate' &&
              evaluators.includes(REVIEW_QUESTION_EVALUATORS.COACH):
              return getTaskSection(item);
          }
        })}
      </>
    </>
  );
};

export { TimelineSummary };
