import { createAction } from 'redux-act';

import { getCompany } from '~/services/companies';

import type { ICompany, IAddons } from '@learned/types';
import type { Dispatch } from 'redux';

export const finishGetCompanyAddons = createAction<IAddons>('finish get company addons');

export const setCompanyAddonsToReduxStore = (selectedCompany: ICompany) => {
  return async function (dispatch: Dispatch) {
    dispatch(finishGetCompanyAddons(selectedCompany.addons));
    return selectedCompany.addons;
  };
};

// TODO we will use this for SuperAdmin to apply changes locally after enable/disable addons
export const updateCompanyAddonsToReduxStore = (companyId: string) => {
  return async function (dispatch: Dispatch) {
    const company = await getCompany(companyId);
    dispatch(finishGetCompanyAddons(company.addons));
    return company.addons;
  };
};
