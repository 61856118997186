import React, { ChangeEvent, ReactNode, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import styled from 'styled-components';

import { Button } from '~/components/Buttons/Button';
import { ButtonSize, ButtonVariant } from '~/components/Buttons/types';
import { ICONS, Icon } from '~/components/Icon';
import { SearchField } from '~/components/Text';

import { COLORS } from '~/styles';

import type { ITableGridProps } from '../../types';

const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  margin-right: 12px;
  width: 350px;
`;

const LeftSideActions = styled.div`
  display: flex;
`;

const RightSideActions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 10px 0 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${COLORS.WHITE};
  padding-bottom: 10px;
  border-radius: 0 10px 0 0;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${COLORS.TEXT_MAIN};
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;
`;

const ResetButton = styled(StyledButton)`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${COLORS.COMPANY};
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;
  margin-left: 8px;
`;

const StyledFiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  background-color: ${COLORS.WHITE};
  flex: auto;
`;

interface ITopProps {
  actionButton?: ITableGridProps['actionButton'];
  filters: {
    search: string;
    setSearch: (value: string) => void;
    resetFilters?: () => void;
  };
  filterComponents?: ReactNode;
  isToggleHideFilterVisible?: boolean;
  resetFilters?: () => void;
}

const Top = ({
  actionButton,
  filters,
  filterComponents,
  isToggleHideFilterVisible,
  resetFilters,
}: ITopProps) => {
  const { i18n } = useLingui();
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  return (
    <Wrapper>
      <HeaderWrapper>
        <LeftSideActions>
          {_.has(filters, 'search') && (
            <SearchFieldWrapper
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                filters.setSearch(e.currentTarget.value)
              }
              value={filters.search}
              placeholder={i18n._(t`Search...`)}
              style={{
                width: '350px',
                borderRadius: '10rem',
                fontSize: '14px',
                fontWeight: 600,
              }}
            />
          )}
        </LeftSideActions>
        <RightSideActions>
          {isToggleHideFilterVisible ? (
            <StyledButton onClick={() => setIsFiltersVisible((prevState) => !prevState)}>
              <Icon icon={isFiltersVisible ? ICONS.HIDE : ICONS.SHOW} />
              {i18n._(isFiltersVisible ? t`Hide filters` : t`Show filters`)}
            </StyledButton>
          ) : (
            <StyledFiltersWrapper>{filterComponents}</StyledFiltersWrapper>
          )}
          {actionButton && (
            <Button
              label={actionButton.label}
              onClick={actionButton.onClick}
              icon={actionButton.icon}
              variant={actionButton.variant || ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
            />
          )}
        </RightSideActions>
      </HeaderWrapper>
      {isToggleHideFilterVisible && filterComponents && isFiltersVisible && (
        <StyledFiltersWrapper>
          {filterComponents}{' '}
          {!!resetFilters && (
            <ResetButton
              onClick={() => {
                resetFilters();
                setIsFiltersVisible(false);
              }}
            >
              <Icon size={12} icon={ICONS.CLOSE} />
              {i18n._(t`Reset all filters`)}
            </ResetButton>
          )}
        </StyledFiltersWrapper>
      )}
    </Wrapper>
  );
};

export { Top };
