import iconFactory from './iconFactory';

const d =
  'M12 2c5.523 0 10 3.582 10 8s-4.477 8-10 8c-1.241 0-2.43-.18-3.527-.512C5.553 20 2 20 2 20c2.327-2.327 2.699-3.902 2.748-4.491C3.045 14.074 2 12.135 2 10c0-4.418 4.477-8 10-8zm5 9V9h-2v2h2zm-4 0V9h-2v2h2zm-4 0V9H7v2h2z';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const FeedbackIcon = iconFactory('FeedbackIcon', d);
export default FeedbackIcon;
