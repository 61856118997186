import { createAction } from 'redux-act';

import { getCompany } from '~/services/companies';

import type { ICompany, IProducts } from '@learned/types';
import type { Dispatch } from 'redux';

export const finishGetCompanyProducts = createAction<IProducts>('finish get company products');

export const setCompanyProductsToReduxStore = (selectedCompany: ICompany) => {
  return async function (dispatch: Dispatch) {
    dispatch(finishGetCompanyProducts(selectedCompany.products));
    return selectedCompany.products;
  };
};

export const updateCompanyProductsToReduxStore = (companyId: string) => {
  return async function (dispatch: Dispatch) {
    const company = await getCompany(companyId);
    dispatch(finishGetCompanyProducts(company.products));
    return company.products;
  };
};
