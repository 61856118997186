import { createReducer } from 'redux-act';

import * as actions from './actions';

import { ROLES } from '~/constants';

export default createReducer(
  {
    [actions.updateSelectedCompany]: (state, payload) => {
      if (state.company === payload && payload !== 'undefined') {
        return state;
      }
      if (payload && payload !== 'undefined') {
        localStorage.setItem('selectedCompany', payload);
      } else {
        localStorage.removeItem('selectedCompany');
      }

      return {
        ...state,
        company: payload,
      };
    },
    [actions.updateSelectedTeam]: (state, payload) => {
      if (state.team === payload && payload !== 'undefined') {
        return state;
      }
      if (payload && payload !== 'undefined') {
        localStorage.setItem('selectedTeam', payload);
      } else {
        localStorage.removeItem('selectedTeam');
      }
      return {
        ...state,
        team: payload,
      };
    },
    [actions.updateSelectedRole]: (state, payload) => {
      if (state.role === payload) {
        return state;
      }
      if (payload) {
        localStorage.setItem('selectedRole', payload !== 'undefined' ? payload : ROLES.USER);
      } else {
        localStorage.removeItem('selectedRole');
      }

      if (payload !== ROLES.COACH) {
        localStorage.removeItem('selectedTeam');
      }

      return {
        ...state,
        role: payload,
        team: payload === ROLES.COACH ? state.team : '',
      };
    },
  },
  {
    company: '',
    role: '',
    team: '',
  },
);
