import React from 'react';

import { GOAL_TYPES, GOAL_VIEWERS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RoleLabel from '~/components/RoleLabel';
import SvgIcon from '~/components/SvgIcon';

import EyeIcon from '~/assets/eye.svg';

import { GOAL_STYLES } from '~/constants/goals';
import { COLOR_PALETTE, COLORS } from '~/styles';

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const TitleRow = styled.div`
  margin-right: 10px;
`;

const Row2Columns = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -24px;
  padding: 0 24px 20px;
  border-bottom: 1px solid ${COLORS.BORDER_SOFT};
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

const PrivacyLabel = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin-left: 9px;
`;

const PRIVACY = {
  [GOAL_VIEWERS.OWNER_AND_COACHES]: (i18n) => i18n._(t`Private`),
  [GOAL_VIEWERS.TEAMS]: (i18n) => i18n._(t`Team(s)`),
  [GOAL_VIEWERS.PUBLIC]: (i18n) => i18n._(t`Public`),
};

const LABELS = {
  [GOAL_TYPES.BUSINESS]: (i18n) => i18n._(t`Business goal`),
  [GOAL_TYPES.PERSONAL]: (i18n) => i18n._(t`Learning goal`),
  [GOAL_TYPES.TEAM]: (i18n, team) => i18n._(t`Team: ${team}`),
  [GOAL_TYPES.COMPANY]: (i18n) => i18n._(t`Organisation`),
};

const Header = ({ name, type, viewers, team }) => {
  const { i18n } = useLingui();
  const getPrivacyLabel = PRIVACY[viewers];
  const goalStyle = GOAL_STYLES[type];
  const goalLabel = LABELS[type];

  return (
    <Row2Columns>
      <TitleRow>
        <Title>{name}</Title>
        <RoleLabel
          value={goalLabel && goalLabel(i18n, team)}
          backgroundColor={goalLabel && goalStyle.color}
          color={COLOR_PALETTE.WHITE}
          isInline
        />
      </TitleRow>
      <Row>
        {getPrivacyLabel && (
          <>
            <SvgIcon
              width="14px"
              height="12px"
              url={EyeIcon}
              alt={i18n._(t`privacy`)}
              isDefaultColor={true}
              defaultColor={COLOR_PALETTE.DARK_GRAY}
            />
            <PrivacyLabel>{getPrivacyLabel(i18n)}</PrivacyLabel>
          </>
        )}
      </Row>
    </Row2Columns>
  );
};

Header.proptypes = {
  name: PropTypes.string,
  type: PropTypes.oneOf(Object.values(GOAL_TYPES)),
  viewers: PropTypes.oneOf(Object.values(GOAL_VIEWERS)),
};

export default React.memo(Header);
