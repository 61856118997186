import React, { type ReactNode, useState } from 'react';

import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { Button, ButtonVariant } from '~/components/Buttons';
import RickTextView from '~/components/RickTextView';
import Tooltip from '~/components/Tooltip';
import Divider from '~/components/UI/Divider';
import { IQuestionDefaultData } from '~/pages/ReviewGiveFeedback/types';
import { Title } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/QuestionWrapper/design';
import {
  isUserReviewEditable,
  type IUserReviewQuestionCustomSkillGrouped,
  type IUserReviewQuestionSkillCategoryGrouped,
} from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/utils';

import { AverageRating } from './AverageRating';
import { LefColumnRows } from './LefColumnRows';
import {
  Footer,
  Header,
  HeaderWrapper,
  LeftColumn,
  RightColumn,
  RightColumnHeader,
  StyledModal,
  SubTable,
  ColumnHeader,
  ExpandableColumnHeader,
  OverflowText,
  Row,
  Table,
  Description,
} from './SkillAnswersModal.design';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser, getUsers } from '~/selectors/baseGetters';
import getUserFullName from '~/utils/getUserFullName';

import type {
  IReviewRating,
  IReviewTheme,
  IUser,
  IUserReview,
  IUserReviewQuestionSkillCategoryWithJobProfile,
  WithExtends,
} from '@learned/types';

function SkillAnswersModal({
  onEdit,
  userReview,
  question,
  onClose,
  header,
  ratingFilter,
  jobNamesHeader,
  themeName,
  questionNumber,
}: {
  onEdit?: (
    skill: {
      skillId: string;
      questions: (IUserReviewQuestionSkillCategoryWithJobProfile & {
        reviewRatings?: IReviewRating[];
      })[];
    },
    ratings: IReviewRating[],
    question: IQuestionDefaultData,
  ) => void;
  question: WithExtends<
    IUserReviewQuestionSkillCategoryGrouped | IUserReviewQuestionCustomSkillGrouped,
    { theme?: IReviewTheme }
  >;
  userReview: IUserReview;
  onClose: () => void;
  header: ReactNode;
  jobNamesHeader: ReactNode;
  themeName: string;
  questionNumber: number;
  ratingFilter: (rating?: IReviewRating) => boolean;
}) {
  const currentUser = useSelector(getUser);
  const users: Record<string, IUser> = useSelector(getUsers);
  const getMultiLangString = useMultiLangString();
  const [openRows, setOpenRows] = useState<number[]>([]);
  const [rowHeaders, setRowHeaders] = useState<Record<string, HTMLDivElement | null>>({});

  const userIds = (question.skills.at(0)?.questions?.at(0)?.reviewRatings ?? [])
    .filter(ratingFilter)
    .map((rating) => rating.createdBy)
    .filter(Boolean) as { email?: string; id?: string }[];

  const userFilterFactory =
    ({ id, email }: { id?: string; email?: string }) =>
    (rating?: IReviewRating) =>
      (rating?.createdBy.id !== undefined && rating?.createdBy.id === id) ||
      (rating?.createdBy.email !== undefined && rating?.createdBy.email === email);

  const hasScrollbar = userIds.length > 3;

  return (
    <StyledModal onClose={onClose} ignoreModalStyle isHideHeader hideFooter showDivider={false}>
      <Header>
        <HeaderWrapper>
          <span>{`Question: ${questionNumber} | ${themeName}`}</span>
          <Title>{getMultiLangString(question.name)}</Title>
          {!isEmpty(question.description) && (
            <Description>
              <RickTextView html={getMultiLangString(question.description || '')} />
            </Description>
          )}
        </HeaderWrapper>
        <Button variant={ButtonVariant.CLOSE} onClick={onClose} />
      </Header>

      {jobNamesHeader}
      <Table>
        <LeftColumn>
          <ExpandableColumnHeader hasScrollbar={hasScrollbar}>
            <Trans>Behavior and results</Trans>
          </ExpandableColumnHeader>
          <LefColumnRows
            userReview={userReview}
            openRows={openRows}
            setOpenRows={setOpenRows}
            setRowHeaders={setRowHeaders}
            question={question}
            ratingFilter={ratingFilter}
          />
        </LeftColumn>
        <RightColumn>
          <RightColumnHeader>{header}</RightColumnHeader>
          <SubTable hasScrollbar={hasScrollbar}>
            <div>
              <Row>
                {userIds.map(({ id, email }) => (
                  <ColumnHeader key={id ?? email}>
                    {/* @ts-ignore */}
                    <Tooltip tooltip={getUserFullName(id ? users[id] : { email })}>
                      {/* @ts-ignore */}
                      <OverflowText>{getUserFullName(id ? users[id] : { email })}</OverflowText>
                    </Tooltip>
                  </ColumnHeader>
                ))}
              </Row>

              {question.skills.map((skill, i) => {
                const isOpen = openRows.includes(i);
                const reviewRatings = skill.questions.flatMap(
                  (question) => question.reviewRatings ?? [],
                );
                const total = skill.questions?.at(0)?.settings.options.length ?? 0;
                return (
                  <>
                    <Row height={rowHeaders[`skill-${skill.skillId}`]?.clientHeight}>
                      {userIds.map(({ id, email }) => (
                        <AverageRating
                          key={id ?? email}
                          total={total}
                          ratings={reviewRatings
                            .filter(ratingFilter)
                            .filter(userFilterFactory({ id, email }))}
                          onEdit={
                            currentUser.id === id && onEdit && isUserReviewEditable(userReview)
                              ? () =>
                                  onEdit(
                                    skill as any,
                                    reviewRatings
                                      .filter(ratingFilter)
                                      .filter(userFilterFactory({ id, email })),
                                    question as unknown as IQuestionDefaultData,
                                  )
                              : undefined
                          }
                        />
                      ))}
                    </Row>

                    {isOpen && (
                      <>
                        {skill.questions.map((question) => {
                          const reviewRatings = question.reviewRatings ?? [];
                          return (
                            <Row
                              height={
                                rowHeaders[
                                  `${skill.skillId}-${question.id}-${question.settings.focusArea.id}`
                                ]?.clientHeight
                              }
                              key={question.id}
                            >
                              {userIds.map(({ id, email }) => (
                                <AverageRating
                                  key={id ?? email}
                                  total={total}
                                  ratings={reviewRatings
                                    .filter(ratingFilter)
                                    .filter(userFilterFactory({ id, email }))}
                                />
                              ))}
                            </Row>
                          );
                        })}

                        <Row height={rowHeaders[`${skill.skillId}-comments`]?.clientHeight}> </Row>
                      </>
                    )}
                  </>
                );
              })}
            </div>
          </SubTable>
        </RightColumn>
      </Table>
      <Divider />
      <Footer>
        <Button variant={ButtonVariant.SECONDARY} label={<Trans>Cancel</Trans>} onClick={onClose} />
      </Footer>
    </StyledModal>
  );
}

export { SkillAnswersModal };
