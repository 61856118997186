import { useState } from 'react';

import map from 'lodash/map';
import size from 'lodash/size';

import useBoolState from '~/hooks/useBoolState';

export default function useDataStore(options) {
  const {
    // actions
    fetch,
    create,
    update,
    get,
    remove,
    // settings
    noRefetch = false,
    startState = {},
    // handlers
    onGet,
  } = options;
  const $loaded = useBoolState(startState.loaded || false);
  const $loading = useBoolState(startState.loading || false);
  const [items, setItems] = useState(startState.items || {});
  const [item, setItem] = useState(startState.item || null);
  const isLoaded = $loaded.value;

  const data = {
    loading: $loading.value,
  };

  if (fetch) {
    data.items = items;
    data.isLoaded = isLoaded;
    data.size = size(items);
    data.map = (fn) => map(items, fn);
    data.fetch = async (...arg) => {
      if (noRefetch && (isLoaded || $loading.value)) {
        return items;
      }
      if (!$loading.value) {
        $loading.on();
      }
      const data = await fetch(...arg);
      setItems(data);
      $loading.off();
      $loaded.on();
      return data;
    };
  }

  if (get) {
    data.item = item;
    data.get = async (...arg) => {
      if (!$loading.value) {
        $loading.on();
      }
      const data = await get(...arg);
      if (onGet) {
        onGet(data);
      } else {
        setItem(data);
      }
      $loading.off();
      // add property to replace item in items;
      return data;
    };
  }

  if (create) {
    data.create = async (...arg) => {
      const data = await create(...arg);
      const newItems = {
        ...items,
        [data.id]: data,
      };
      setItems(newItems);
      return data;
    };
  }

  if (update) {
    data.update = async (...arg) => {
      const data = await update(...arg);
      const newItems = {
        ...items,
        [data.id]: data,
      };
      setItems(newItems);
      return data;
    };
  }

  if (remove) {
    data.remove = async (id, ...arg) => {
      await remove(id, ...arg);
      const { [id]: _item, ...newItems } = items;

      setItems(newItems);
    };
  }

  return data;
}
