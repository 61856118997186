import { ROLES } from '~/constants';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'reports';

export const REPORT_PERFORMANCE = new LinkConstructor(
  [ROLES.ADMIN],
  () => `${routeName}/performance`,
);

// company/<companyId>/admin/reports/dashboard
export const REPORTS_ADMIN_DASHBOARD = new LinkConstructor(
  [ROLES.ADMIN],
  () => `${routeName}/dashboard`,
);

/**
 * @deprecated ROUTE - since new reports menu
 * Use const REPORTS_ADMIN_CUSTOM_REPORT or REPORTS_ADMIN_DASHBOARD
 */
export const REPORTS_ADMIN_ENGAGEMENT = new LinkConstructor(
  [ROLES.ADMIN],
  () => `${routeName}/engagement`,
);

// company/<companyId>/admin/reports/<reportType>/<reportId>
export const REPORTS_ADMIN_CUSTOM_REPORT = new LinkConstructor(
  [ROLES.ADMIN],
  ({ reportId = ':reportId', reportType = ':reportType' }) =>
    `${routeName}/${reportType}/${reportId}`,
);

// TODO: <REPORT DELETE BELOW>
/**
 * @deprecated ROUTE - since new reports menu
 * Use const REPORTS_COACH_CUSTOM_REPORT
 */
export const REPORTS_COACH_TEAMS_ENGAGEMENT = new LinkConstructor(
  [ROLES.USER],
  () => `coach/${routeName}/engagement`,
);

export const REPORTS_COACH_CUSTOM_REPORT = new LinkConstructor(
  [ROLES.USER],
  ({ reportId = ':reportId', reportType = ':reportType' }) =>
    `coach/${routeName}/${reportType}/${reportId}`,
);

export const REPORTS_USER_CUSTOM_REPORT = new LinkConstructor(
  [ROLES.USER],
  ({ reportId = ':reportId', reportType = ':reportType' }) =>
    `${routeName}/${reportType}/${reportId}`,
);

// TODO: <REPORT DELETE BELOW>
/**
 * @deprecated ROUTE - since new reports menu
 * Use const REPORTS_USER_CUSTOM_REPORT
 */
export const REPORTS_USER_ENGAGEMENT = new LinkConstructor(
  [ROLES.USER],
  () => `${routeName}/engagement`,
);
