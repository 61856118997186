import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import Button from '~/components/Button';
import StepStatus from '~/components/StepStatus';
import CardWithActions from '~/components/UI/CardWithActions';

import { COLOR_PALETTE } from '~/styles';

const Title = styled.div`
  margin-left: 8px;
`;

const SubTitle = styled.div`
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.21px;
  color: #505672;
`;

const LowerSubTitle = styled.div`
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  margin-top: 2px;
`;

const SetupTitle = styled.div`
  margin-top: 4px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  & span {
    font-weight: 300;
  }
`;

const StyledCard = styled(CardWithActions)`
  margin-bottom: 24px;
`;

const submitStyles = { textTransform: 'uppercase' };

const SetupCard = ({
  title,
  subTitle,
  i18n,
  stepNumber,
  stepCount,
  children,
  loading,
  hideFooter,
  backDisabled,
  onEdit,
  nextDisabled,
  backLabel,
  submitLabel,
  nextLabel,
  onBackAction,
  onNextAction,
  status,
  className,
  active,
  nextLabelTooltip,
  backLabelTooltip,
  lowerSubtitle,
  isHidePublishButton,
}) => {
  const last = stepNumber === stepCount;
  const stepsAmount = stepCount || 1;

  return (
    <StyledCard
      className={className}
      noFooter={hideFooter}
      collapsed={!active}
      title={
        <>
          <StepStatus status={status} />
          <Title>
            <SubTitle>
              {subTitle ? subTitle : i18n._(t`Step ${stepNumber} of ${stepsAmount}`)}
            </SubTitle>
            <SetupTitle>{title}</SetupTitle>
            {lowerSubtitle &&
              (typeof lowerSubtitle === 'function' ? (
                <LowerSubTitle>{lowerSubtitle(i18n)}</LowerSubTitle>
              ) : (
                lowerSubtitle
              ))}
          </Title>
        </>
      }
      headerActions={
        !active &&
        status !== StepStatus.statuses.todo && (
          <Button
            type="primary-border"
            label={i18n._(t`Edit`)}
            styles={submitStyles}
            onClick={() => onEdit(stepNumber)}
          />
        )
      }
      loading={loading}
      leftFooterActions={
        onBackAction && (
          <Button
            type="primary-border"
            label={backLabel || i18n._(t`Back`)}
            tooltip={backLabelTooltip}
            onClick={onBackAction}
            disabled={backDisabled}
          />
        )
      }
      rightFooterActions={
        !last && (
          <Button
            type="primary-border"
            label={nextLabel || i18n._(t`Next`)}
            tooltip={nextLabelTooltip}
            loading={loading}
            styles={submitStyles}
            onClick={onNextAction}
            disabled={nextDisabled || loading}
          />
        )
      }
      centerFooterActions={
        last &&
        !isHidePublishButton && (
          <Button
            label={submitLabel || i18n._(t`Publish`)}
            tooltip={nextLabelTooltip}
            loading={loading}
            styles={submitStyles}
            onClick={onNextAction}
            disabled={nextDisabled || loading}
          />
        )
      }
    >
      {children}
    </StyledCard>
  );
};

export default React.memo(withI18n()(SetupCard));
