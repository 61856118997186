import { GOAL_STATUSES_NEW } from '@learned/constants';
import { t } from '@lingui/macro';

import { ICONS } from '~/components/Icon';

import { COLORS } from '~/styles';

import type { I18n } from '@lingui/core';

export const GOAL_STATUSES_MAP = {
  [GOAL_STATUSES_NEW.TODO]: {
    key: GOAL_STATUSES_NEW.TODO,
    label: (i18n: I18n) => i18n._(t`Todo`),
    backGroundColor: COLORS.TODO_BLUE_LIGHT,
    color: COLORS.TODO_BLUE,
    icon: ICONS.DROPDOWN,
  },
  [GOAL_STATUSES_NEW.IN_PROGRESS]: {
    key: GOAL_STATUSES_NEW.IN_PROGRESS,
    label: (i18n: I18n) => i18n._(t`In progress`),
    backGroundColor: COLORS.IN_PROGRESS_LIGHT,
    color: COLORS.IN_PROGRESS,
    icon: ICONS.IN_PROGRESS,
  },
  [GOAL_STATUSES_NEW.PROBLEM]: {
    key: GOAL_STATUSES_NEW.PROBLEM,
    label: (i18n: I18n) => i18n._(t`Problem`),
    backGroundColor: COLORS.ACCENT_ERROR_LIGHT,
    color: COLORS.ACCENT_ERROR,
    icon: ICONS.WARNING_2,
  },
  [GOAL_STATUSES_NEW.DRAFT]: {
    key: GOAL_STATUSES_NEW.DRAFT,
    label: (i18n: I18n) => i18n._(t`Draft`),
    backGroundColor: COLORS.DRAFT_LABEL,
    color: COLORS.ACCENT_INFO,
    icon: '',
  },
  [GOAL_STATUSES_NEW.COMPLETED]: {
    key: GOAL_STATUSES_NEW.COMPLETED,
    label: (i18n: I18n) => i18n._(t`Completed`),
    backGroundColor: COLORS.ACCENT_SUCCESS_LIGHT,
    color: COLORS.ACCENT_SUCCESS,
    icon: ICONS.CHECKMARK,
  },
  [GOAL_STATUSES_NEW.ARCHIVED]: {
    key: GOAL_STATUSES_NEW.ARCHIVED,
    label: (i18n: I18n) => i18n._(t`Archived`),
    backGroundColor: COLORS.ANY_OTHER_PRODUCTS,
    color: COLORS.SUBTEXT,
    icon: ICONS.ARCHIVE_2,
  },
};
