import React from 'react';

import { SURVEY_QUESTION_TYPES } from '@learned/constants';
import last from 'lodash/last';
import size from 'lodash/size';

import { QuestionModal } from '~/components/Modals/QuestionModal';

import type { IQuestion, IQuestionForm } from '~/@types/question';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { updateSurveyQuestion } from '~/services/surveyQuestions';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import type { ISurveyQuestion } from '@learned/types';

function EditQuestionModal({
  languageState,
  itemToEdit,
  onClose,
  onSubmit,
}: {
  itemToEdit?: IQuestion;
  languageState: ILanguageStateReturn;
  onClose: () => void;
  onSubmit: (data: ISurveyQuestion) => void;
}) {
  async function handleSubmit(value: IQuestionForm) {
    const starsData =
      value.type === SURVEY_QUESTION_TYPES.STARS
        ? {
            firstLabel: convertLanguageValue(
              value.options[0].label.filter((langValue) => !!langValue?.value),
            ),
            lastLabel: convertLanguageValue(
              last(value.options)?.label.filter((langValue) => !!langValue?.value),
            ),
            size: size(value.options),
          }
        : undefined;

    const result = await updateSurveyQuestion(value.id as string, {
      type: value.type as SURVEY_QUESTION_TYPES,
      name: turnArrayIntoMultiLang(value.name.filter((langValue) => langValue.value !== '')),
      data:
        value.type !== SURVEY_QUESTION_TYPES.TEXT
          ? {
              options: value?.options?.map(({ icon, label }) => ({
                icon,

                label: turnArrayIntoMultiLang(label.filter((langValue) => langValue.value !== '')),
              })),
              ...starsData,
              isCommentsAllowed: value.settings.isCommentsAllowed,
              isCommentsObligated: value.settings.isCommentsObligated,
              isMeasurementReversed: value.settings.isMeasurementReversed,
            }
          : undefined,
    });
    if (result.code === 200) {
      onSubmit(result.data.surveyQuestion);
    }
  }

  return (
    <QuestionModal
      languageState={languageState}
      defaultValues={itemToEdit}
      onClose={onClose}
      isEditMode
      onSubmit={handleSubmit}
    />
  );
}

export { EditQuestionModal };
