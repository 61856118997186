import React, { useState } from 'react';

import { ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useSelector } from 'react-redux';

import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import CardTopSection from './CardTopSection';
import ProvidedFeedback from './ProvidedFeedback';
import RTFeedbackName from './RTFeedbackName';
import { Card } from './styledComponets';
import SubRTFeedback from './SubRTFeedback';

import { REQUEST_STATUSES, RT_FEEDBACK_TYPES, RT_FEEDBACK_STATUSES } from '~/constants';
import { getUsers, getUser, getSelectedRole } from '~/selectors/baseGetters';
import * as requestService from '~/services/requests';
import {
  giveRTFeedback,
  deleteRTFeedback,
  deleteRTFeedbackByInterviewee,
  updateRTFeedbackStatus,
} from '~/services/RTFeedbacks';
import getUserFullName from '~/utils/getUserFullName';

const RTFeedbackCard = ({
  RTFeedback,
  onShareFeedback,
  onRemoveRTFeedback,
  onRemoveRequest,
  isOutside = false,
  outsideFeedback,
  setOutsideFeedback,
  TPUser,
  isHideMenu,
  isHideGoal,
  isHideCardHeader,
  isNoCardPadding,
}) => {
  const { i18n } = useLingui();
  const users = useSelector(getUsers);
  const user = useSelector(getUser);
  const currentRole = useSelector(getSelectedRole);
  const [loading, setLoading] = useState(false);
  const [activeRTFeedback, setActiveRTFeedback] = useState(null);

  // logic
  const shareFeedback = async (feedback, user) => {
    if (isOutside) {
      onShareFeedback && onShareFeedback({ feedback, RTFeedback });
    } else {
      setLoading(true);
      await giveRTFeedback(RTFeedback.id, feedback);
      onShareFeedback && onShareFeedback({ feedback, RTFeedbackId: RTFeedback.id, user });
      setActiveRTFeedback(null);
      setLoading(false);
    }
  };

  const removeRTFeedback = async () => {
    setLoading(true);
    await deleteRTFeedback(RTFeedback.id);
    setLoading(false);
    onRemoveRTFeedback(RTFeedback.id);
  };

  // change status on DELETED
  const deleteReceivedRTFeedback = async () => {
    setLoading(true);
    await updateRTFeedbackStatus(RTFeedback.id, RT_FEEDBACK_STATUSES.DELETED.key);
    setLoading(false);
    onRemoveRTFeedback(RTFeedback.id);
  };

  const removeRequest = async () => {
    const requestId = get(RTFeedback, 'request.id');
    const requestStatus = get(RTFeedback, 'request.status');

    if (requestId && requestStatus) {
      const isSelfRTFeedback =
        RTFeedback.originalRTFeedback && RTFeedback.createdBy === RTFeedback.createdFor;
      if (isSelfRTFeedback) {
        await requestService.deleteRequest(requestId); // TODO (task is created) replace delete request from front-end to back-end
      }
      isSelfRTFeedback
        ? await deleteRTFeedback(RTFeedback.originalRTFeedback) // totally delete self review
        : await deleteRTFeedbackByInterviewee(RTFeedback.id); // remove only request
    }

    onRemoveRequest(RTFeedback.id);
  };

  const declineRequest = async () => {
    const requestId =
      RTFeedback.request && RTFeedback.request.id ? RTFeedback.request.id : RTFeedback.request;
    await requestService.declineRequest(requestId);
    window.alert(i18n._(t`Real-time feedback rejected`));
    onRemoveRequest(RTFeedback.id);
  };

  const isActiv = RTFeedback.id === activeRTFeedback;
  const isAsked =
    !RTFeedback.originalRTFeedback && RTFeedback.type === RT_FEEDBACK_TYPES.ASK_FEEDBACK.key;
  const isProactiveBy =
    RTFeedback.type === RT_FEEDBACK_TYPES.GIVE_FEEDBACK.key &&
    RTFeedback.createdBy === (TPUser || user.id);
  const isProactiveFor =
    RTFeedback.type === RT_FEEDBACK_TYPES.GIVE_FEEDBACK.key &&
    RTFeedback.createdFor === (TPUser || user.id);

  // Card user, date and title
  const cardUser = isAsked || isProactiveBy ? RTFeedback.createdFor : RTFeedback.createdBy;

  const createdDate = moment(RTFeedback.meta.createdDate).format('DD-MM-YYYY');

  // SubRTFeedbacks
  let subRTFeedbacks = get(RTFeedback, 'subRTFeedbacks', []);
  if (!isEmpty(subRTFeedbacks)) {
    subRTFeedbacks = subRTFeedbacks.map((fb) => {
      if (fb.createdForEmail) {
        return fb;
      }
      fb.fullUserName = getUserFullName(users[fb.createdFor]);
      return fb;
    });
  }

  // Menu items
  let menuItems = [];
  if (isAsked || isProactiveBy) {
    menuItems = [
      {
        order: 0,
        label: isAsked ? i18n._(t`Delete feedback request`) : i18n._(t`Delete`),
        action: () => removeRTFeedback(RTFeedback.id),
      },
    ];
  } else if (isProactiveFor) {
    menuItems = [
      {
        order: 0,
        label: i18n._(t`Delete`),
        action: () => deleteReceivedRTFeedback(RTFeedback.id),
      },
    ];
  } else {
    if (
      RTFeedback.status === REQUEST_STATUSES.ACCEPTED.key ||
      RTFeedback.status === REQUEST_STATUSES.REJECTED.key
    ) {
      menuItems.push({
        order: 1,
        label: i18n._(t`Delete`),
        action: () => removeRequest(RTFeedback),
      });
    }
    // Skip update for user Self review or if user has deleted request
    if (RTFeedback.feedback && RTFeedback.request) {
      menuItems.push({
        order: 0,
        label: i18n._(t`Update`),
        action: () => setActiveRTFeedback(RTFeedback.id),
      });
    }

    if (
      RTFeedback.status === REQUEST_STATUSES.REQUESTED.key ||
      (RTFeedback.request && RTFeedback.request.status === REQUEST_STATUSES.REQUESTED.key)
    ) {
      menuItems.push({
        order: 2,
        label: i18n._(t`Decline`),
        action: declineRequest,
      });
    }
  }

  return (
    <Card isNoCardPadding={isNoCardPadding}>
      <ShowSpinnerIfLoading loading={loading}>
        <CardTopSection
          cardUser={cardUser}
          createdDate={createdDate}
          description={RTFeedback.description}
          menuItems={!isHideMenu && menuItems}
          goal={RTFeedback.goal}
          skills={RTFeedback.skills}
          isHideGoal={isHideGoal}
          isHideCardHeader={isHideCardHeader}
          titleText={
            <RTFeedbackName RTFeedback={RTFeedback} TPUser={TPUser} isOutside={isOutside} />
          }
          isProactive={isProactiveBy || isProactiveFor}
          isWhite
          showMenu={!(TPUser && TPUser !== user.id) && !isHideMenu}
        />
        <>
          {/* Admin can't give feedback*/}
          {currentRole !== ROLES.ADMIN &&
            !isAsked &&
            RTFeedback.status !== REQUEST_STATUSES.REJECTED.key && (
              <ProvidedFeedback
                isAskRTFB={RTFeedback.type === RT_FEEDBACK_TYPES.ASK_FEEDBACK.key}
                feedback={RTFeedback.feedback}
                user={
                  isProactiveBy || isProactiveFor
                    ? users[RTFeedback.createdBy]
                    : users[RTFeedback.createdFor]
                }
                dateStamp={RTFeedback.meta.lastModifiedDate || RTFeedback.meta.createdDate}
                isActiv={isActiv}
                i18n={i18n}
                isProactive={isProactiveBy || isProactiveFor}
                shareFeedback={shareFeedback}
                isOutside={isOutside}
                outsideFeedback={outsideFeedback}
                setOutsideFeedback={setOutsideFeedback}
              />
            )}
          {isAsked && !isEmpty(subRTFeedbacks) && (
            <div>
              {subRTFeedbacks.map((RTF, i) => (
                <SubRTFeedback key={i} userRTFeedback={RTF} isOutside={isOutside} />
              ))}
            </div>
          )}
        </>
      </ShowSpinnerIfLoading>
    </Card>
  );
};

export default React.memo(RTFeedbackCard);
