import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import CardWithRows from '~/components/CardWithRows';
import Row from '~/components/CardWithRows/Row';
import OverviewHeading from '~/components/OverviewHeading';
import BaseLayout from '~/layouts/BaseLayout';

import { NOTIFICATION_TYPES, INVITE_STATUSES } from '~/constants';
import { getInvites, acceptInvite, cancelInvite } from '~/services/invites';
import { getNotifications } from '~/services/notifications';
import { acceptTeamRequest, declineTeamRequest } from '~/services/requests';
import { setInvites } from '~/store/invites/actions';

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;

  .reject-btn {
    margin-right: 16px;
    border: solid 1px #767e94;
    color: #767e94;
  }

  .invite-btn {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    text-align: center;
    padding: 12px 17.5px;
    height: 40px;
    min-width: 83px;
  }
`;

const Container = styled.div`
  & > * {
    margin-bottom: 32px;
  }
`;

const StyledRow = styled(Row)`
  justify-content: space-between;
`;

const TeamName = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

class InvitesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      notifications: [],
      invites: {},
    };
  }

  componentDidMount = () => {
    this.fetchNotifications();
    this.fetchInvites();
  };

  fetchNotifications() {
    this.setState({ loading: true });
    getNotifications({ type: NOTIFICATION_TYPES.TEAM_INVITE.key }).then((notifications) => {
      let notificationsArr = Object.values(notifications);

      notificationsArr.sort((a, b) => {
        const AcreatedDate = get(a, 'meta.createdDate');
        const BcreatedDate = get(b, 'meta.createdDate');
        if (AcreatedDate && BcreatedDate && moment(AcreatedDate).isBefore(moment(BcreatedDate))) {
          return 1;
        } else {
          return -1;
        }
      });

      this.setState({
        notifications: notificationsArr,
        loading: false,
      });
    });
  }

  fetchInvites = () => {
    getInvites(INVITE_STATUSES.SENT.key).then((invites) => {
      this.setState({ invites });
    });
  };

  acceptTeamRequest = async (notification) => {
    const { i18n, dispatch } = this.props;
    this.setState({ loading: true });
    await acceptTeamRequest(notification.options.requestId);
    this.setState({ loading: false });
    this.setState((prevState) => ({
      ...prevState,
      notifications: prevState.notifications.filter((n) => n.id !== notification.id),
    }));
    dispatch(setInvites({ fetchInvites: true }));
    alert(i18n._(t`Team joined successfully!`));
  };

  declineTeamRequest = async (notification) => {
    const { i18n, dispatch } = this.props;
    await declineTeamRequest(notification.options.requestId);
    this.setState((prevState) => ({
      ...prevState,
      notifications: prevState.notifications.filter((n) => n.id !== notification.id),
    }));
    dispatch(setInvites({ fetchInvites: true }));
    alert(i18n._(t`Invite to team - rejected!`));
  };

  renderNotificationRow = (notification) => {
    const { i18n } = this.props;

    return (
      <StyledRow>
        <TeamName>{notification.options.teamName}</TeamName>
        <ButtonGroup>
          <Button
            label={i18n._(t`Reject`)}
            onClick={() => this.declineTeamRequest(notification)}
            className="reject-btn invite-btn"
            type="primary-border"
          />
          <Button
            label={i18n._(t`Accept`)}
            onClick={() => this.acceptTeamRequest(notification)}
            className="invite-btn"
            type="primary-border"
          />
        </ButtonGroup>
      </StyledRow>
    );
  };

  renderInviteRow = (invite) => {
    const { i18n, dispatch } = this.props;
    return (
      <StyledRow>
        <TeamName>{invite.companyName}</TeamName>
        <ButtonGroup>
          <Button
            label={i18n._(t`Reject`)}
            onClick={() => {
              cancelInvite(invite.id).then(this.fetchInvites);
              dispatch(setInvites({ fetchInvites: true }));
            }}
            className="reject-btn invite-btn"
            type="primary-border"
          />
          <Button
            label={i18n._(t`Accept`)}
            onClick={() => {
              acceptInvite(invite.id).then(() => {
                window.location.reload();
              });
              dispatch(setInvites({ fetchInvites: true }));
            }}
            className="invite-btn"
            type="primary-border"
          />
        </ButtonGroup>
      </StyledRow>
    );
  };

  render() {
    const { i18n } = this.props;
    const { notifications, invites } = this.state;
    return (
      <>
        <OverviewHeading title={i18n._(t`Invites`)} />
        <Wrapper>
          <BaseLayout>
            <Container>
              <CardWithRows
                title={i18n._(t`Team`)}
                placeholderTitle={i18n._(t`You have no team invites`)}
                placeholderText={i18n._(
                  t`If a coach invites you for his/her team the invite will show here`,
                )}
                items={notifications}
                renderRow={this.renderNotificationRow}
                minHeight="220px"
              />
              <CardWithRows
                title={i18n._(t`Company`)}
                placeholderTitle={i18n._(t`You have no company invites`)}
                placeholderText={i18n._(
                  t`If an admin invites you for his/her company the invite will show here`,
                )}
                items={Object.values(invites)}
                renderRow={this.renderInviteRow}
                minHeight="220px"
              />
            </Container>
          </BaseLayout>
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(InvitesPage)));
