import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const MinusIcon = ({ size, ...props }) => (
  <InlineSvg width={size} height={size} viewBox="0 0 13 3" {...props}>
    <path
      d="M1.5 1.5h10"
      stroke="currentColor"
      strokeWidth="1.2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </InlineSvg>
);

MinusIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

MinusIcon.defaultProps = {
  size: 24,
  className: '',
};

export default MinusIcon;
