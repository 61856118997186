export enum COMPANIES_SORT_FILTER_OPTIONS {
  A_Z = 'a-z',
  Z_A = 'z-a',
}

export enum ProductStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  TRIAL_ACTIVE = 'trialActive',
  TRIAL_NOT_STARTED = 'trialNotStarted',
  TRIAL_ENDED = 'trialEnded',
}

export enum ProductName {
  JOB_MATRIX = 'jobMatrix',
  CAREER = 'career',
  PERFORMANCE = 'performance',
  ENGAGEMENT = 'engagement',
  LEARNING_AND_ONBOARDING = 'learningAndOnboarding',
  INTEGRATIONS = 'integrations',
}

// This id is for the data belonging to the super admin templates, like skill category and others
export const SUPER_ADMIN_TEMPLATES_COMPANY_ID = 'super-admin';

// This id is for an existing company for which we take its data and copy it to every new company during creation
export const COMPANY_CREATION_TEMPLATES_COMPANY_ID = 'super-admin-company';
