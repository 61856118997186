import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getSkillCategories(skillCategoryIds) {
  const query = skillCategoryIds?.length
    ? {
        skillCategoryIds: skillCategoryIds.join(','),
      }
    : {};

  return cloudRequest(serverRoutes.skillCategories.getSkillCategories, query);
}

export function updateSkillCategory(id, body) {
  return cloudRequest(serverRoutes.skillCategories.updateSkillCategory(id), {}, body);
}

export function deleteSkillCategory(id, body) {
  return cloudRequest(serverRoutes.skillCategories.deleteSkillCategory(id), {}, body);
}

export function createSkillCategory(body) {
  return cloudRequest(serverRoutes.skillCategories.createSkillCategory, {}, body);
}

export function listSkillCategoriesSuperAdmin() {
  return cloudRequest(serverRoutes.skillCategories.listSkillCategoriesSuperAdmin);
}

export function updateSkillCategoriesSuperAdmin(body) {
  return cloudRequest(serverRoutes.skillCategories.updateSkillCategoriesSuperAdmin, {}, body);
}
