import React from 'react';

import { useDispatch } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';

import { Activity } from './types';

import * as currentGoalActions from '~/store/currentGoal/actions';

import { ActivityItemWrap, ActivityMenuWrap, ActivityName } from '../stepsDesign';

interface IProps {
  activity: Activity;
  isCustom: boolean;
  onEdit: (activity: Activity, indexToEdit: number) => void;
  index: number;
}

const ActivityItem = ({ activity, isCustom, onEdit, index }: IProps) => {
  const dispatch = useDispatch();

  const removeActivity = (activity: Activity) => {
    // @ts-ignore
    dispatch(currentGoalActions.removeCurrentGoalActivity(activity));
  };

  const onDeleteButtonClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <ActivityItemWrap
      onClick={
        isCustom
          ? () => {
              onEdit(activity, index);
            }
          : () => {}
      }
    >
      <ActivityName>{activity.name}</ActivityName>
      <ActivityMenuWrap onClick={onDeleteButtonClick}>
        <Button
          variant={ButtonVariant.ICON_DELETE}
          icon={ICONS.DELETE_BIN}
          size={ButtonSize.MEDIUM}
          onClick={() => removeActivity(activity)}
        />
      </ActivityMenuWrap>
    </ActivityItemWrap>
  );
};

export { ActivityItem };
