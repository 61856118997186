import React from 'react';

import { SURVEY_QUESTION_TYPES } from '@learned/constants';
import last from 'lodash/last';
import size from 'lodash/size';

import { QuestionModal } from '~/components/Modals/QuestionModal';

import type { IQuestionForm } from '~/@types/question';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { createSurveyQuestion } from '~/services/surveyQuestions';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';

import type { ISurveyQuestion } from '@learned/types';

function AddQuestionModal({
  languageState,
  onClose,
  onSubmit,
}: {
  languageState: ILanguageStateReturn;
  onClose: () => void;
  onSubmit: (newQuestion: ISurveyQuestion) => void;
}) {
  async function handleSubmit(value: IQuestionForm) {
    const starsData =
      value.type === SURVEY_QUESTION_TYPES.STARS
        ? {
            firstLabel: convertLanguageValue(value.options[0].label),
            lastLabel: convertLanguageValue(last(value.options)?.label),
            size: size(value.options),
          }
        : undefined;

    const questionData = {
      theme: value.theme?.id,
      type: value.type,
      name: convertLanguageValue(value.name),
      data: {
        options: value?.options?.map(({ icon, label }) => ({
          icon,
          label: convertLanguageValue(label),
        })),
        ...starsData,
        isCommentsAllowed: value.settings.isCommentsAllowed,
        isCommentsObligated: value.settings.isCommentsObligated,
        isMeasurementReversed: value.settings.isMeasurementReversed,
      },
    };
    const result = await createSurveyQuestion({
      question: questionData as ISurveyQuestion,
      position: 0,
    });
    if (result.code === 200) {
      onSubmit({
        ...result.data.surveyQuestion,
        themeName: value.theme?.name,
        themeIcon: value.theme?.icon,
        themeIconColor: value.theme?.iconColor,
      });
    }
  }

  return (
    <QuestionModal
      selectTheme
      languageState={languageState}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
}

export { AddQuestionModal };
