import { serverRoutes } from '~/server_config';

import cloudRequest from '../cloudRequest';

/* request data */
/* const data = {
  name, description, deadline, talkingPoints, users = [], coaches = {}, isDraft = false,
} */
export function createConversation(data) {
  return cloudRequest(serverRoutes.conversations.createConversation, {}, data);
}

export function updateConversation(conversationId, data) {
  return cloudRequest(serverRoutes.conversations.updateConversation(conversationId), {}, data);
}

// for ADMIN only
export function updateConversationParticipants(conversationId, body) {
  return cloudRequest(
    serverRoutes.conversations.updateConversationParticipants(conversationId),
    null,
    body,
  );
}

// for ADMIN only
export function updateConversationCreatedBy(conversationId, body) {
  return cloudRequest(
    serverRoutes.conversations.updateConversationCreatedBy(conversationId),
    null,
    body,
  );
}

export function deleteConversation(conversationId) {
  return cloudRequest(serverRoutes.conversations.deleteConversation(conversationId));
}

export function deleteConversationFutureEvents(conversationId) {
  return cloudRequest(serverRoutes.conversations.deleteConversationFutureEvents(conversationId));
}

/* request example */
/*
* getConversation(conversationId, {
        populate: ['users'],
        join: ['talkingPoints'],
        isSub: true, // join subConversations (only for originalConversation)
      }),
* */

/* response example */
/* {
      ...conversation,
      talkingPoints: [],
      comments: [],
      subConversations: [],
}; */
export function getConversation(id, { populate = [], join = [], isSub } = {}) {
  return cloudRequest(serverRoutes.conversations.getConversation(id), {
    id,
    populate,
    join,
    isSub,
  });
}

export function getConversations(filters) {
  return cloudRequest(serverRoutes.conversations.getConversations, { ...filters });
}

export function getConversationsFilter(filters) {
  return cloudRequest(serverRoutes.conversations.getConversationsFilter, { ...filters });
}

export function updateConversationPublished(conversationId, data) {
  return cloudRequest(
    serverRoutes.conversations.updateConversationPublished(conversationId),
    null,
    data,
  );
}
