import { joiResolver } from '@hookform/resolvers/joi';
import { FocusAreaType } from '@learned/constants';
import Joi from 'joi';

const schema = Joi.object({
  name: Joi.array()
    .items(
      Joi.object({
        locale: Joi.string(),
        value: Joi.string().allow(''),
      })
        .required()
        .min(1),
    )
    .custom((items: { locale: string; value: string }[]) => {
      let hasAtLeastOneName = false;
      items?.forEach((name) => {
        if (name.value !== '') {
          hasAtLeastOneName = true;
        }
      });
      if (!hasAtLeastOneName) {
        throw new Error('One translation is required');
      }

      return items;
    }),
  description: Joi.array().items(
    Joi.object({
      locale: Joi.string(),
      value: Joi.string().allow(''),
    }),
  ),
  aliases: Joi.array().items(Joi.object()),
  jobFamily: Joi.string().optional(),
  skills: Joi.object().pattern(
    Joi.string(),
    Joi.object()
      .keys({
        defaultFocusAreaLevel: Joi.number().required(),
        isDefaultFocusAreaLevelEnabled: Joi.boolean().required(),
        skills: Joi.array()
          .default([])
          .items(
            Joi.object().keys({
              skillTemplate: Joi.string().required(),
              selectedFocusAreas: Joi.array()
                .default([])
                .items(
                  Joi.object().keys({
                    type: Joi.string()
                      .valid(...Object.values(FocusAreaType))
                      .required(),
                    level: Joi.number().optional(),
                    focusArea: Joi.array().default([]).items(Joi.string()),
                  }),
                ),
            }),
          ),
      })
      .optional(),
  ),
  coverImage: Joi.string().optional(),
});

const resolver = joiResolver(schema);

export { resolver };
