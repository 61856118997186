import { ROLES } from '~/constants';
import { pageOverview, pageDashboard } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'teams';
const defaultTeamId = ':teamId';

// overview
export const TEAMS = new LinkConstructor([ROLES.ADMIN], () => pageOverview(routeName));

// dashboard
export const TEAM = new LinkConstructor([ROLES.ADMIN], ({ teamId = defaultTeamId }) =>
  pageDashboard(routeName, { id: teamId }),
);

export const TEAM_COACH = new LinkConstructor([ROLES.COACH], () => 'members');
