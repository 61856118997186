import { useCallback, useEffect, useState } from 'react';

import { JOB_TEMPLATES_SORT_OPTIONS } from '@learned/constants';

import useDebounce from '~/hooks/useDebounce';
import { getJobTemplatesSuperAdmin } from '~/services/jobTemplates';

import type { IJobTemplate } from '@learned/types';

const useManageJobTemplates = () => {
  const [search, setSearch] = useState('');
  const [jobTemplates, setJobTemplates] = useState<IJobTemplate[]>([]);
  const [selectedJobTemplate, setSelectedJobTemplate] = useState<IJobTemplate>();
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSearch = useDebounce(search, 300);

  const listJobTemplates = useCallback(
    async () =>
      getJobTemplatesSuperAdmin(
        {
          search: debouncedSearch,
        },
        {
          skip: 0,
          limit: 10,
          sortBy: JOB_TEMPLATES_SORT_OPTIONS.NAME_A_Z,
        },
      ),
    [debouncedSearch],
  );

  useEffect(() => {
    let mounted = true;

    async function fetch() {
      setIsLoading(true);
      const response = await listJobTemplates();
      if (!mounted && response.code !== 200) {
        return;
      }
      setJobTemplates(response.data.jobTemplates.items);
      setIsLoading(false);
    }

    fetch();
    return () => void (mounted = false);
  }, [listJobTemplates]);

  return {
    jobTemplates,
    selectedJobTemplate,
    setSelectedJobTemplate,
    setSearch,
    search,
    isLoading,
  };
};

export { useManageJobTemplates };
