import React from 'react';

import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

import { COLORS, COLOR_PALETTE } from '~/styles';

const StyledTextArea = styled(TextareaAutosize)`
  width: 100%;
  border: 1px solid ${(props) => (props.$error ? COLORS.ERROR : COLORS.BORDER)};
  border-radius: 3px;
  background-color: ${COLOR_PALETTE.WHITE};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  color: ${COLORS.TEXT_PRIMARY_2};
  font-family: 'Poppins', sans-serif;
  padding: 12px 9px;
  box-sizing: border-box;
  resize: ${(props) => (props.resize ? 'vertical' : 'none')};
  min-height: 48px;
  &::placeholder {
    color: ${COLORS.PLACEHOLDER};
  }
`;

const TextArea = ({ error, ...newProps }) => {
  return <StyledTextArea $error={error} {...newProps} />;
};

export default TextArea;
