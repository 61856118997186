import iconFactory from './iconFactory';

const d =
  'M2.808 13.121l2.828-2.828 2.537-.507c3.217-4.376 9.38-6.564 11.605-6.564 0 2.225-2.188 8.388-6.563 11.605l-.508 2.537-2.828 2.828-.707-3.535c-1.415 0-1.415 0-2.122-.707-.707-.707-.707-.707-.707-2.122l-3.535-.707zm2.828 2.829l1.414 1.414-2.664 2.664H2.972v-1.414l2.664-2.664zm-1.414-1.414l1.237.176-2.444 2.445v-1.414l1.207-1.207zm4.066 3.005l.176 1.237-1.207 1.207H5.843l2.445-2.444zM13 8.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const RocketIcon = iconFactory('RocketIcon', d);
export default RocketIcon;
