import React, { memo } from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { Header7Dark } from '~/components/UI/Typographics/headers';

import Comment from './Comment';
import CommentInput from './CommentInput';

import { COLOR_PALETTE } from '~/styles';

const BlockName = styled(Header7Dark)`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin: 12px 0;
`;

const ChatInputWrap = styled.div`
  margin-top: 16px;
`;

const CommentsBlock = ({
  isCanComments,
  comments,
  pointId,
  deleteComment,
  updateComment,
  writingComment,
  onCreateTalkingPoint,
}) => (
  <>
    <BlockName>
      <Trans>Notes</Trans>
    </BlockName>
    {comments.length > 0 &&
      comments.map((comment) => (
        <Comment
          key={comment.id}
          talkingPointId={pointId}
          comment={comment}
          deleteComment={deleteComment}
          updateComment={updateComment}
          readOnly={!isCanComments}
        />
      ))}
    {isCanComments && (
      <>
        <ChatInputWrap>
          <CommentInput writingComment={writingComment} onSave={onCreateTalkingPoint} />
        </ChatInputWrap>
      </>
    )}
  </>
);

export default memo(CommentsBlock);
