import React, { Component } from 'react';

import { UPDATE_OPTION } from '@learned/types';
import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import CheckBox from '~/components/CheckBox';
import DatePicker from '~/components/DatePickerDeprecated';
import { DigitalMeeting } from '~/components/DigitalMeeting';
import InfoIcon from '~/components/Icons/Info';
import Modal from '~/components/Modal';
import { NoCalendarIntegrationCreateWarning } from '~/components/Warnings/NoCalendarIntegrationCreateWarning';

import { ROLES, USER_INTEGRATION_TYPES } from '~/constants';
import { getCurrentCompanyId } from '~/selectors/baseGetters';
import { updateUserConversationDate } from '~/services/userConversations';
import { COLOR_PALETTE } from '~/styles';

const InputLabel = styled.h5`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #3b3b3b;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 8px;
`;

const InputGroup = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  color: #3b3b3b;
  width: ${(props) => props.width};
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  min-height: auto;
`;

const UpdateNotification = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  display: flex;
  align-items: center;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const StyledIcon = styled(InfoIcon)`
  margin-right: 8px;
`;

const UpdateAllOrSingleText = styled.div`
  font-size: 14px;
  line-height: 1.71;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin-bottom: 12px;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  letter-spacing: 0.29px;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const StyledCheckBox = styled(CheckBox)`
  margin: 4px 12px 4px 0;
`;

const StyledNoCalendarIntegrationCreateWarning = styled(NoCalendarIntegrationCreateWarning)`
  margin-bottom: 23px;
`;

const StyledDigitalMeeting = styled(DigitalMeeting)`
  margin-bottom: 15px;
`;

class EvalPlanModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventDate: '',
      eventTime: '',
      endTime: '',
      loading: false,
      isEdit: false,
      invitation: true,
      updateAll: false,
      setUpdateAll: false,
      includeLinkMeeting: false,
      initialValues: undefined,
    };
  }

  async componentDidMount() {
    const { conversation } = this.props;

    const initialValues = {
      eventDate: conversation.startDate,
      eventTime: conversation.startDate,
      endTime: conversation.endDate,
      includeLinkMeeting:
        conversation.includeLinkMeeting ||
        conversation.originalConversation?.includeLinkMeeting ||
        false, // we need false to prevent undefined
    };

    this.setState({
      isEdit: true,
      ...initialValues,
      initialValues,
    });
  }

  calcTime() {
    const { eventTime, endTime, eventDate } = this.state;

    const initialDate = moment(eventDate).format('DD/MM/YYYY');
    const initialTime = moment(eventTime).format('HH:mm');
    const fullDate = moment(initialDate + ' ' + initialTime, 'DD/MM/YYYY HH:mm');
    const initialDate2 = moment(eventDate).format('DD/MM/YYYY');
    const initialTime2 = moment(endTime).format('HH:mm');
    const fullDate2 = moment(initialDate2 + ' ' + initialTime2, 'DD/MM/YYYY HH:mm');

    return { startTime: fullDate, endTime: fullDate2 };
  }

  onSave = async () => {
    const { conversation, closeModal, onSubmit, isDigitalMeeting } = this.props;
    const { updateAll, setUpdateAll } = this.state;

    if (
      ['week', 'year'].includes(get(conversation, 'originalConversation.repeat.type')) &&
      !setUpdateAll
    ) {
      return this.setState({
        setUpdateAll: true,
      });
    }

    this.setState({ loading: true });
    const time = this.calcTime();

    let isError = false;

    // Update event
    if (onSubmit) {
      const result = await onSubmit({
        id: conversation.id,
        startDate: time.startTime,
        endDate: time.endTime,
        updateAll: updateAll ? UPDATE_OPTION.THIS_AND_FUTURE_EVENTS : UPDATE_OPTION.ONLY_THIS_EVENT,
        includeLinkMeeting: isDigitalMeeting && this.state.includeLinkMeeting,
      });

      isError = !result;
    } else {
      await updateUserConversationDate(
        conversation.id,
        time.startTime,
        time.endTime,
        updateAll ? UPDATE_OPTION.THIS_AND_FUTURE_EVENTS : UPDATE_OPTION.ONLY_THIS_EVENT,
        isDigitalMeeting && this.state.includeLinkMeeting,
      );
    }

    this.setState({ loading: false });
    if (isError) {
      return;
    }

    closeModal(
      {
        ...conversation,
        startDate: time.startTime,
        endDate: time.endTime,
        includeLinkMeeting: this.state.includeLinkMeeting,
      },
      updateAll,
    );
  };

  updateSelectedDate = (value) => {
    this.updateDateTime(value, 'date');
  };

  updateIncludeLinkMeeting = (value) =>
    void this.setState({
      includeLinkMeeting: value,
    });

  updateSelectedTime = (value) => {
    this.updateDateTime(value, 'time');
  };

  updateSelectedEndTime = (value) => {
    this.updateDateTime(value, 'endTime');
  };

  updateDateTime = (value, type) => {
    const { endTime, eventTime } = this.state;
    if (!value) {
      if (type === 'date') {
        this.setState({ eventDate: '' });
      }
      if (type === 'time') {
        this.setState({ eventTime: '' });
      }
      if (type === 'endTime') {
        this.setState({ endTime: '' });
      }
      return;
    }

    if (type === 'date') {
      this.setState({ eventDate: this.convertDateToISOString(moment(value)) });
    }
    if (type === 'time') {
      const startTime = moment(value);
      const start = moment().hours(startTime.hours()).minutes(startTime.minutes());

      if (start && endTime && moment(start).format('HH:mm') >= moment(endTime).format('HH:mm')) {
        this.setState({
          eventTime: this.convertDateToISOString(start),
          endTime: this.convertDateToISOString(start.clone().add(15, 'minute')),
        });
      } else if (!endTime) {
        this.setState({
          eventTime: this.convertDateToISOString(start),
          endTime: this.convertDateToISOString(start.clone().add(30, 'minute')),
        });
      } else {
        this.setState({ eventTime: this.convertDateToISOString(start) });
      }
    } else if (type === 'endTime') {
      const endTimeHM = moment(value);
      const end = moment().hours(endTimeHM.hours()).minutes(endTimeHM.minutes());

      if (eventTime && end && moment(eventTime).format('HH:mm') >= moment(end).format('HH:mm')) {
        this.setState({
          eventTime: this.convertDateToISOString(end.clone().add(-15, 'minute')),
          endTime: this.convertDateToISOString(end),
        });
      } else {
        this.setState({ endTime: this.convertDateToISOString(end) });
      }
    }
  };

  convertDateToISOString = (date) => {
    return date.startOf('minute').toISOString();
  };

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    const {
      i18n,
      users,
      selectedRole,
      user,
      readOnly,
      isDigitalMeeting,
      conversation,
      reviewName,
      userFirstName,
    } = this.props;
    const {
      eventDate,
      eventTime,
      endTime,
      loading,
      updateAll,
      setUpdateAll,
      initialValues,
      includeLinkMeeting,
    } = this.state;

    let calendarEvent = {
      title: conversation.name,
      description: conversation.description,
    };

    const createdForId = get(conversation, 'createdFor.id', conversation.createdFor);
    const coachId = get(conversation, 'coach.id', conversation.coach);
    const coach = users[createdForId] || {};
    const createdFor = users[coachId] || {};
    const calendarIntegration = user?.integrations?.find(
      (integration) => !integration.isRevoked && !integration.isDeleted,
    );
    const hasIntegration = !isEmpty(calendarIntegration);

    let calendarAttendees;
    if (selectedRole === ROLES.USER && user.id === conversation.forUser) {
      calendarAttendees = coach.email || '';
    } else {
      calendarAttendees = createdFor.email || '';
    }

    calendarEvent.linkMeeting =
      conversation?.calendarEvent?.[`${calendarIntegration?.type}Connection`]?.linkMeeting;
    calendarEvent.attendees = calendarAttendees;

    const isValuesChanged =
      initialValues &&
      (!moment(initialValues.eventDate).isSame(moment(eventDate)) ||
        !moment(initialValues.eventTime).isSame(moment(eventTime)) ||
        !moment(initialValues.endTime).isSame(moment(endTime)) ||
        initialValues.includeLinkMeeting !== includeLinkMeeting);

    return (
      <Modal
        title={
          reviewName
            ? userFirstName
              ? i18n._(t`Set a date and time for ${userFirstName}'s ${reviewName}`)
              : i18n._(t`Set a date and time for your ${reviewName}`)
            : i18n._(t`Set date & time `)
        }
        onClose={this.closeModal}
        width={462}
        minWidth={462}
        contentStyles={{ overflow: 'inherit' }}
        footerStyles={{
          margin: '12px 0',
        }}
        footerRight={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              loading={loading}
              onClick={this.closeModal}
              label={i18n._(t`Cancel`)}
              type="white"
            />
            <Button
              styles={{
                height: '40px',
                marginLeft: '10px',
                textTransform: 'uppercase',
                padding: '0px 15px',
                fontSize: '12px',
                minWidth: '81px',
              }}
              loading={loading}
              disabled={readOnly || !eventTime || !endTime || !eventDate || !isValuesChanged}
              onClick={this.onSave}
              label={i18n._(t`Update`)}
              type="primary"
            />
          </div>
        }
      >
        {!setUpdateAll ? (
          <div>
            <ModalContent>
              <InputGroup style={{ justifyContent: 'space-between' }}>
                <InputColumn width="220px">
                  <InputLabel>
                    <Trans>Date</Trans>
                  </InputLabel>
                  <DatePicker
                    width={179}
                    disabled={readOnly}
                    selected={eventDate}
                    onChange={this.updateSelectedDate}
                  />
                </InputColumn>
                <InputColumn width="104px">
                  <InputLabel>
                    <Trans>From</Trans>
                  </InputLabel>
                  <DatePicker
                    showTimeSelect={true}
                    showTimeSelectOnly={true}
                    placeholder={i18n._(t`11:30`)}
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    width={80}
                    disabled={readOnly}
                    timeIntervals={15}
                    selected={eventTime}
                    onChange={this.updateSelectedTime}
                    timeCaption="time"
                    isHideIcon={true}
                  />
                </InputColumn>
                <InputColumn width="80px">
                  <InputLabel>
                    <Trans>To</Trans>
                  </InputLabel>
                  <DatePicker
                    showTimeSelect={true}
                    showTimeSelectOnly={true}
                    placeholder={i18n._(t`12:30`)}
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    width={80}
                    disabled={readOnly}
                    timeIntervals={15}
                    selected={endTime}
                    onChange={this.updateSelectedEndTime}
                    timeCaption="time"
                    isHideIcon
                  />
                </InputColumn>
              </InputGroup>
              {!hasIntegration && <StyledNoCalendarIntegrationCreateWarning />}

              {hasIntegration && isDigitalMeeting && (
                <StyledDigitalMeeting
                  tippyProps={{
                    content:
                      calendarIntegration.type === USER_INTEGRATION_TYPES.azure &&
                      !calendarIntegration.hasOnlineMeetingProvider
                        ? i18n._(
                            t`Your integration email account does not have access to create Microsoft Teams meetings`,
                          )
                        : calendarIntegration.type === USER_INTEGRATION_TYPES.azure &&
                          calendarEvent.linkMeeting
                        ? t`Microsoft Teams does not allow to remove meeting links once created`
                        : null,
                  }}
                  checked={this.state.includeLinkMeeting}
                  onChange={this.updateIncludeLinkMeeting}
                  calendarType={calendarIntegration.type}
                  disabled={
                    !calendarIntegration.hasOnlineMeetingProvider ||
                    (calendarIntegration.type === USER_INTEGRATION_TYPES.azure &&
                      !!calendarEvent.linkMeeting)
                  }
                />
              )}
            </ModalContent>
            <UpdateNotification>
              <StyledIcon />
              <Trans>An email with update will be sent to all participants</Trans>
            </UpdateNotification>
          </div>
        ) : (
          <div>
            <UpdateAllOrSingleText>
              <Trans>
                You are about to update the date and/or time of a repeating conversation. Do you
                want to only update the date and/or time of this event or off all future
                conversation. This will delete all future conversation and generate new
                conversation.
              </Trans>
            </UpdateAllOrSingleText>
            <CheckBoxContainer>
              <StyledCheckBox
                roundCheckbox
                checked={!updateAll}
                onChange={() => this.setState({ updateAll: false })}
                size={24}
              />
              <Trans>Only this conversation</Trans>
            </CheckBoxContainer>
            <CheckBoxContainer>
              <StyledCheckBox
                roundCheckbox
                checked={updateAll}
                onChange={() => this.setState({ updateAll: true })}
                size={24}
              />
              <Trans>All future conversations</Trans>
            </CheckBoxContainer>
          </div>
        )}
      </Modal>
    );
  }
}

EvalPlanModal.propTypes = {
  conversation: PropTypes.object,
  onSubmit: PropTypes.func,
  readOnly: PropTypes.bool,
};

EvalPlanModal.defaultProps = {
  conversation: {},
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    users: state.users.data,
    selectedRole: state.selected.role,
    teams: state.teams.data,
    companyId: getCurrentCompanyId(state),
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(EvalPlanModal)));
