import React from 'react';

import Modal from '~/components/Modal';

import { useAddSkillSuperAdminModal } from './hooks/useAddSkillSuperAdminModal';
import { SearchSkill } from './SearchSkill';
import { SelectLevelAndFocusAreas } from './SelectLevelAndFocusAreas';
import { AddSkillModalResource, type AddSkillModalProps, type IForm } from './types';

function AddSkillSuperAdminModal<T extends IForm>({
  onClose,
  skillCategoryName,
  defaultFocusAreaLevel,
  isDefaultFocusAreaLevelEnabled,
  formMethods,
  skillCategoryId,
  skillCategoryTemplateId,
  skillCategoryLevels,
  initSource,
  resource = AddSkillModalResource.SKILL_TEMPLATES,
  setSkillTemplates,
  setSkills,
}: AddSkillModalProps<T>) {
  const {
    searchInputValue,
    setSearchInputValue,
    skillNames,
    skills,
    isSelectLevelAndFocusAreasVisible,
    setIsSelectLevelAndFocusAreasVisible,
    source,
    setSource,
  } = useAddSkillSuperAdminModal({
    skillCategoryId,
    skillCategoryTemplateId,
    initSource,
    resource,
  });

  return (
    <Modal
      width={isSelectLevelAndFocusAreasVisible ? 800 : 750}
      contentStyles={{ padding: '30px' }}
      hideFooter
      isHideHeader
      centerModal
      showDivider={false}
    >
      {!isSelectLevelAndFocusAreasVisible ? (
        <SearchSkill
          onClose={onClose}
          skillCategoryName={skillCategoryName}
          skillCategoryId={skillCategoryId}
          searchInputValue={searchInputValue}
          setSearchInputValue={setSearchInputValue}
          skillNames={skillNames}
          skills={skills}
          defaultFocusAreaLevel={defaultFocusAreaLevel}
          isDefaultFocusAreaLevelEnabled={isDefaultFocusAreaLevelEnabled}
          setIsSelectLevelAndFocusAreasVisible={setIsSelectLevelAndFocusAreasVisible}
          setSource={setSource}
          formMethods={formMethods}
          setSkillTemplates={setSkillTemplates}
          setSkills={setSkills}
        />
      ) : (
        <SelectLevelAndFocusAreas
          onClose={onClose}
          skillCategoryName={skillCategoryName}
          skillCategoryId={skillCategoryId}
          skillCategoryLevels={skillCategoryLevels}
          setIsSelectLevelAndFocusAreasVisible={setIsSelectLevelAndFocusAreasVisible}
          source={source!}
          formMethods={formMethods}
          setSkillTemplates={setSkillTemplates}
          setSkills={setSkills}
        />
      )}
    </Modal>
  );
}

export { AddSkillSuperAdminModal };
