import React, { memo } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';
import IconMenu from '~/components/IconMenu';
import Tooltip from '~/components/Tooltip';
import UserAvatar from '~/components/UI/Avatar';
import { Header4Dark } from '~/components/UI/Typographics/headers';

import { COLOR_PALETTE } from '~/styles';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';
import getUserFullName from '~/utils/getUserFullName';

const Box = styled.div`
  display: flex;
  flex-direction: column;
  &:first-child {
    border: 0;
  }
`;

const NameField = styled(Header4Dark)`
  width: 100%;
  margin: 2px 8px 0;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  justify-self: flex-end;
  align-items: center;

  > * {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px 15px 15px;
  box-sizing: border-box;
  min-height: 58px;
`;

const Menu = styled.div`
  position: relative;
`;

const NameCtr = styled.div`
  flex: auto;
`;

const Step = ({
  step,
  menu,
  showMenu,
  isCanCheck,
  i18n,
  createdBy,
  onChangeCheckbox = async () => {},
}) => {
  const { meta, isCompleted, id, createdFor, comment } = step;
  const createdDate = convertToTimeString(meta.createdDate, TIME_FORMATS.DAY_MONTH_YEAR);

  let tooltipMessage;
  if (!createdBy) {
    tooltipMessage = `${i18n._(t`Created on`)} ${createdDate}`;
  } else {
    tooltipMessage = `${i18n._(t`Created by`)} ${getUserFullName(createdBy)}, ${createdDate}`;
  }

  return (
    <Box>
      <Header>
        <CheckBox
          size={22}
          checked={isCompleted}
          isDefaultColor={!isCanCheck}
          disabled={!isCanCheck}
          onChange={(_e, value) => onChangeCheckbox(id, value)}
        />
        <NameCtr>
          <Tooltip tooltip={tooltipMessage} maxWidth="260px">
            <NameField>{comment}</NameField>
          </Tooltip>
        </NameCtr>
        <Actions>
          <UserAvatar size={28} userId={createdFor} />
          {showMenu && (
            <Menu>
              <IconMenu items={menu} />
            </Menu>
          )}
        </Actions>
      </Header>
    </Box>
  );
};

export default memo(withI18n()(Step));
