import _ from 'lodash';
import orderBy from 'lodash/orderBy';

import { SORT_OPTIONS } from './types';

import type { IEmployee } from './types';

enum ORDERS {
  ASC = 'asc',
  DESC = 'desc',
}

export const sortOptionsForEmployees = (sortBy: SORT_OPTIONS | undefined) => {
  switch (sortBy) {
    default:
    case SORT_OPTIONS.NAME_A_Z:
      return {
        fields: [
          (item: IEmployee) => `${item.firstName?.toLowerCase()} ${item.lastName?.toLowerCase()}`,
        ],
        orders: [ORDERS.ASC],
      };
    case SORT_OPTIONS.NAME_Z_A:
      return {
        fields: [
          (item: IEmployee) => `${item.firstName?.toLowerCase()} ${item.lastName?.toLowerCase()}`,
        ],
        orders: [ORDERS.DESC],
      };
  }
};

export const sortEmployees = (
  items: IEmployee[],
  sortBy: SORT_OPTIONS | undefined,
): IEmployee[] => {
  const { fields, orders } = sortOptionsForEmployees(sortBy);
  return orderBy(items, fields, orders).map((item) => {
    return item;
  });
};
