import { ROLES } from '@learned/constants';
import { t } from '@lingui/macro';

import { ICONS } from '~/components/Icon';

import routes from '~/constants/routes';

import type { I18n } from '@lingui/core';

const mainMenuStructure = ({
  isModuleCareerEnabled,
  isModuleGoalsEnabled,
  isModuleLearningEnabled,
  isModuleSurveyEnabled,
  isModuleReviewsEnabled,
  isModuleMeetingsEnabled,
  isModuleRTFeedbacksEnabled,
}: {
  isModuleCareerEnabled: boolean;
  isModuleGoalsEnabled: boolean;
  isModuleLearningEnabled: boolean;
  isModuleSurveyEnabled: boolean;
  isModuleReviewsEnabled: boolean;
  isModuleMeetingsEnabled: boolean;
  isModuleRTFeedbacksEnabled: boolean;
}) =>
  [
    {
      children: [
        {
          title: (i18n: I18n) => i18n._(t`Home`),
          url: routes.DASHBOARD.build({ role: ROLES.USER }),
          role: ROLES.USER,
        },
      ],
      title: (i18n: I18n) => i18n._(t`Home`),
      icon: ICONS.HOME,
    },
    isModuleCareerEnabled && {
      children: [
        {
          title: (i18n: I18n) => i18n._(t`Career paths`),
          url: routes.USER_CAREER.build({ role: ROLES.USER }),
          role: ROLES.USER,
          aliases: [routes.USER_CAREER.build({ role: ROLES.USER })],
        },
      ],
      title: (i18n: I18n) => i18n._(t`Career`),
      icon: ICONS.CAREER,
    },

    isModuleReviewsEnabled && {
      children: [
        {
          title: (i18n: I18n) => i18n._(t`Reviews`),
          url: routes.REVIEWS.build({ role: ROLES.USER }, { hash: 'about-you', noHash: true }),
          role: ROLES.USER,
          aliases: [routes.REVIEWS.build({ role: ROLES.USER })],
        },
      ],
      title: (i18n: I18n) => i18n._(t`Reviews`),
      icon: ICONS.STAR,
    },

    isModuleMeetingsEnabled && {
      children: [
        {
          title: (i18n: I18n) => i18n._(t`1:1 & Team meetings`),
          url: routes.CONVERSATIONS.build({ role: ROLES.USER }, { hash: 'meetings', noHash: true }),
          role: ROLES.USER,
          aliases: [routes.CONVERSATIONS.build({ role: ROLES.USER })],
        },
      ],
      title: (i18n: I18n) => i18n._(t`1:1s`),
      icon: ICONS.CONVERSATIONS,
    },

    isModuleGoalsEnabled && {
      children: [
        {
          title: (i18n: I18n) => i18n._(t`Goals`),
          url: routes.GOALS.build({ role: ROLES.USER }, { hash: 'my_goals', noHash: true }),
          role: ROLES.USER,
          aliases: [
            routes.GOALS.build({ role: ROLES.USER }),
            routes.GOALS.build({ role: ROLES.USER }),
          ],
        },
      ],
      title: (i18n: I18n) => i18n._(t`Goals`),
      icon: ICONS.GOALS,
    },

    isModuleRTFeedbacksEnabled && {
      children: [
        {
          title: (i18n: I18n) => i18n._(t`Feedback`),
          url: routes.RTFEEDBACKS.build({ role: ROLES.USER }),
          role: ROLES.USER,
          aliases: [
            routes.RTFEEDBACKS.build({ role: ROLES.USER }),
            routes.RTFEEDBACKS_ASKED.build({ role: ROLES.USER }),
            routes.RTFEEDBACKS_PROVIDED.build({ role: ROLES.USER }),
            routes.FEEDBACK_CYCLES.build({ role: ROLES.ADMIN }),
            routes.RTFEEDBACKS_ASKED.build({ role: ROLES.ADMIN }),
            routes.RTFEEDBACKS_PROVIDED.build({ role: ROLES.ADMIN }),
          ],
        },
      ],
      title: (i18n: I18n) => i18n._(t`Feedback`),
      icon: ICONS.THUMB,
    },

    isModuleLearningEnabled && {
      title: (i18n: I18n) => i18n._(t`Onboard & Learn`),
      icon: ICONS.LEARNING,
      children: [
        {
          title: (i18n: I18n) => i18n._(t`Onboard & Learn`),
          url: routes.ONBOARD_AND_LEARN.build({ role: ROLES.USER }),
          role: ROLES.USER,
        },
      ],
    },

    isModuleSurveyEnabled && {
      children: [
        {
          title: (i18n: I18n) => i18n._(t`Survey’s`),
          url: routes.SURVEYS_OVERVIEW.build(
            // @ts-ignore
            { role: ROLES.USER },
            {
              noHash: true,
              hash: 'personal',
            },
          ),
          role: ROLES.USER,
          // @ts-ignore
          aliases: [routes.SURVEYS_OVERVIEW.build({ role: ROLES.USER })],
        },
      ].filter((c) => c),
      title: (i18n: I18n) => i18n._(t`Survey’s`),
      icon: ICONS.SURVEYS,
    },
  ].filter((c) => c);

export default mainMenuStructure;
