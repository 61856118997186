import type { IUpdateServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export const updateUserConnection = (
  connectionId: string,
  data: { startDate: Date | null; endDate: Date | null },
) => {
  const body = data;

  return cloudRequest(
    (serverRoutes.userConnections.updateUserConnection as IUpdateServerRoute)(connectionId),
    {},
    body,
  );
};
