import React, { Fragment, useState, useEffect } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import concat from 'lodash/concat';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import SkillCoverageGraph from '~/components/Graphs/SkillCoverageGraph';
import OverviewHeading from '~/components/OverviewHeading';
import SkillName from '~/components/Skill/SkillName';
import Shadow from '~/components/UI/Shadow';
import BaseLayout from '~/layouts/BaseLayout';

import PerMemberSection from './components/PerMemberSection';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { getSkill, downloadSkillReport } from '~/services/skills';
import { COLOR_PALETTE } from '~/styles';
import { replacerForURL } from '~/utils/replacerForURL';

const PerMemberWrapper = styled.div`
  margin-top: 24px;
`;

const TitleSection = styled(Shadow)`
  display: flex;
  padding: 17px 24px 16px;
  background-color: ${COLOR_PALETTE.WHITE};
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
`;

function SkillReportPage({ i18n }) {
  const match = useRouteMatch();
  const $fetching = useBoolState(true);
  const [skill, setSkill] = useState();
  const isLatest = false; // now it's always false, for Skill 2 report it was true, but we deleted skill 2 report

  // filters for per member table
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [reviewsList, setReviewList] = useState([]);
  const [selectedReviews, setSelectedReviews] = useState([]);
  const skillId = get(match, 'params.skillId');

  useEffect(() => {
    const fetchData = async () => {
      if (skillId) {
        const skill = await getSkill(skillId);

        if (skill) {
          setSkill(skill);
        }
      }

      // get selectedReview from URL
      const href = window.location.href;
      const url = new URL(href);
      const review = url.searchParams.get('review');
      if (review) {
        setSelectedReviews([JSON.parse(review)]);
      }
      $fetching.off();
    };

    fetchData();

    // eslint-disable-next-line
  }, []);

  const goBack = () => {
    const href = window.location.href;
    const url = new URL(href);
    const review = url.searchParams.get('review');
    routes.SKILLS_REPORT.go(
      {},
      {
        query: { review: replacerForURL(review) },
      },
    );
  };

  const setDefaultReview = (data) => {
    // we get reviews list from graph
    // choose the latest (it's the last)
    if (!isEmpty(data)) {
      const reviews = data.map((r) => ({ id: r.id, name: r.name }));
      // add review from url to reviews list
      const href = window.location.href;
      const url = new URL(href);
      const review = url.searchParams.get('review');
      setReviewList(uniq(reviews.concat([review])));
    }
  };

  const downloadCSV = async () => {
    let members = [];

    if (!isEmpty(selectedMembers)) {
      members = [...members, ...selectedMembers.map((m) => m.id)];
    }

    if (!isEmpty(selectedTeams)) {
      members = uniq([...members, ...concat(...selectedTeams.map((t) => t.members))]);
    }
    await downloadSkillReport(skillId, {
      isLatest,
      reviewId: selectedReviews[0].id,
      ...(!isEmpty(members) && { members }),
    });
  };

  return (
    <Fragment>
      <OverviewHeading title={i18n._(t`Per skill`)} onBack={goBack}>
        <Button label={i18n._(t`Export csv`)} onClick={downloadCSV} />
      </OverviewHeading>

      <BaseLayout>
        <TitleSection>
          <Title>{skill && <SkillName skill={skill} />}</Title>
        </TitleSection>
        <SkillCoverageGraph
          skills={[skillId]}
          description={i18n._(t`The skill coverage for this skill per review over time`)}
          onFetchFinish={setDefaultReview}
          isHideUI={true} // we can't delete component because we use logic inside it to display reviews in table filter
        />
        <PerMemberWrapper>
          {!isEmpty(selectedReviews) && (
            <PerMemberSection
              skillId={skillId}
              selectedTeams={selectedTeams}
              setSelectedTeams={setSelectedTeams}
              selectedMembers={selectedMembers}
              setSelectedMembers={setSelectedMembers}
              reviewsList={reviewsList}
              selectedReviews={selectedReviews}
              setSelectedReviews={setSelectedReviews}
            />
          )}
        </PerMemberWrapper>
      </BaseLayout>
    </Fragment>
  );
}

export default withI18n()(SkillReportPage);
