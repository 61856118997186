import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import NotebookModal from '~/components/NotebookModal';

import { MENU_ITEMS } from './constants';
import accountMenuStructure from './menuStructures/accountMenuStructure';
import footerMenuStructure from './menuStructures/footerMenuStructure';
import mainMenuStructure from './menuStructures/mainMenuStructure';
import { superAdminMenuItems } from './menuStructures/superAdminMenuStructure';
import PrimaryMenu from './PrimaryMenu';
import ReportMenu from './ReportMenu';
import SecondaryMenu from './SecondaryMenu';
import TeamMenu from './TeamMenu';
import { findMenuBlock, isUserAdminInSelectedCompany } from './utils';

import { useAuth0 } from '~/auth0';
import { NOTIFICATION_TYPES, INVITE_STATUSES } from '~/constants';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { useLanguageState } from '~/hooks/useLanguageState';
import {
  checkModuleCareer,
  checkModuleGoals,
  checkModuleReviews,
  checkModuleConversations,
  checkModuleLearning,
  checkModulePerformance,
  checkModuleSurvey,
  getUser,
  getUsers,
  getTeams,
  getCurrentCompanyId,
  getSelectedTeam,
  getInactiveUsers,
  getInvitesSet,
  getNotificationsSet,
  checkModuleRTFeedbacks,
  getCustomReports,
  checkModuleIntegrations,
  checkModuleJobMatrix,
} from '~/selectors/baseGetters';
import { getInvites } from '~/services/invites';
import { getNotifications, readAllNotifications } from '~/services/notifications';
import * as customReportActions from '~/store/customReports/actions';
import { setInvites } from '~/store/invites/actions';
import { setNotifications } from '~/store/notifications/actions';
import * as teamActions from '~/store/teams/actions';
import * as userActions from '~/store/users/actions';

import type { IMenuItem } from './types';

const SideMenuWrapper = styled.div`
  display: flex;
  overflow: hidden;
`;

const SideMenu = () => {
  const { i18n } = useLingui();
  const { logout } = useAuth0();
  const { pathname } = useLocation();
  const $isNotebookOpen = useBoolState(false);
  const dispatch = useDispatch();
  const currentCompanyId = useSelector(getCurrentCompanyId);
  const user = useSelector(getUser);
  const teams = useSelector(getTeams);
  const users = useSelector(getUsers);
  const inactiveUsers = useSelector(getInactiveUsers);
  const selectedTeam = useSelector(getSelectedTeam);
  const invitesSet = useSelector(getInvitesSet);
  const isFetchInvites = invitesSet.fetchInvites;
  const notificationsSet = useSelector(getNotificationsSet);
  const isFetchNotifications = notificationsSet.fetchNotifications;
  const isModuleJobMatrixEnabled = useSelector(checkModuleJobMatrix);
  const isModuleCareerEnabled = useSelector(checkModuleCareer);
  const isModuleGoalsEnabled = useSelector(checkModuleGoals);
  const isModuleReviewsEnabled = useSelector(checkModuleReviews);
  const isModuleMeetingsEnabled = useSelector(checkModuleConversations);
  const isModuleLearningEnabled = useSelector(checkModuleLearning);
  const isModulePerformanceEnabled = useSelector(checkModulePerformance);
  const isModuleIntegrationsEnabled = useSelector(checkModuleIntegrations);
  const isModuleEngagementEnabled = useSelector(checkModuleSurvey);
  const isModuleRTFeedbacksEnabled = useSelector(checkModuleRTFeedbacks);
  const languageState = useLanguageState();
  const [numberOfUnreadNotifications, setNumberOfUnreadNotifications] = useState(
    notificationsSet.notificationsCount || 0,
  );
  const [numberOfActiveInvites, setNumberOfActiveInvites] = useState(invitesSet.invitesCount || 0);
  const customReports = useSelector(getCustomReports);

  const isUserHasAdminRole = isUserAdminInSelectedCompany(
    currentCompanyId,
    get(user, 'connections', []),
  );

  useEffect(() => {
    let isMounted = true;

    const fetchUnreadNotifications = async () => {
      const unreadNotifications = await getNotifications({ isRead: false, onlyCount: true });

      if (isMounted) {
        setNumberOfUnreadNotifications(unreadNotifications.count);
        dispatch(
          setNotifications({
            fetchNotifications: false,
            notificationsCount: unreadNotifications.count || 0,
          }),
        );
      }
    };

    if (isFetchNotifications) {
      fetchUnreadNotifications();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [currentCompanyId, isFetchNotifications]);

  useEffect(() => {
    let isMounted = true;

    const fetchUnreadInvites = async () => {
      const [unreadTeamInvites, unreadCompanyInvites] = await Promise.all([
        getNotifications({
          type: NOTIFICATION_TYPES.TEAM_INVITE.key,
          isRead: false,
          onlyCount: true,
        }),
        getInvites(INVITE_STATUSES.SENT.key),
      ]);

      if (isMounted) {
        const invitesCount = unreadTeamInvites.count + Object.values(unreadCompanyInvites).length;
        setNumberOfActiveInvites(invitesCount);
        dispatch(setInvites({ fetchInvites: false, invitesCount }));
      }
    };

    if (isFetchInvites) {
      fetchUnreadInvites();
    }

    dispatch(customReportActions.getCustomReports(false));

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [currentCompanyId]);

  const onReadAllNotifications = async () => {
    // mark all notifications as read
    if (numberOfUnreadNotifications > 0) {
      setNumberOfUnreadNotifications(0);
      dispatch(setNotifications({ fetchNotifications: false, notificationsCount: 0 }));
      await readAllNotifications();
    }
  };

  const onOpenNotebook = () => {
    $isNotebookOpen.on();
  };

  const mainMenuBlocks = mainMenuStructure({
    isModuleCareerEnabled,
    isModuleGoalsEnabled,
    isModuleLearningEnabled,
    isModuleSurveyEnabled: isModuleEngagementEnabled,
    isModuleReviewsEnabled,
    isModuleMeetingsEnabled,
    isModuleRTFeedbacksEnabled,
  });

  const footerMenuWithURL = footerMenuStructure({
    user,
    teams,
    languageState,
    selectedCompany: currentCompanyId,
    isModuleLearningEnabled,
    isUserHasAdminRole,
    isModuleEngagementEnabled,
    isModulePerformanceEnabled,
    isModuleJobMatrixEnabled,
    customReports: customReports?.length ? customReports : [],
  });

  const accountMenuWithUrl = accountMenuStructure({
    user,
    isUserHasAdminRole,
    isModuleIntegrationsEnabled,
    logout,
    onOpenNotebook,
    numberOfActiveInvites,
    numberOfUnreadNotifications,
    onReadAllNotifications,
    isModuleReviewsEnabled,
    isModuleMeetingsEnabled,
  });

  const menuStructure = [...mainMenuBlocks, ...footerMenuWithURL, ...accountMenuWithUrl].filter(
    (item) => item,
  );

  const location = useLocation();

  const selectedMenuBlock = findMenuBlock(menuStructure as IMenuItem[], pathname);
  const superAdminLocations = [
    routes.SUPERADMIN_COMPANY_DASHBOARD.url,
    routes.SUPERADMIN_COMPANY_MEMBERS.url,
    routes.SUPERADMIN_OVERVIEW.url,
    routes.SUPERADMIN_REQUESTS.url,
    routes.SUPERADMIN_SKILL_MATRIX.url,
    routes.SUPERADMIN_INDUSTRIES.url,
    routes.SUPERADMIN_JOB_LIBRARY.url,
  ];
  const superAdminRoute = superAdminLocations.includes(location.pathname);

  const showTeamMenu = selectedMenuBlock?.key === 'coach-menu';
  const fetchData = async () => {
    dispatch(teamActions.getTeams());
    dispatch(userActions.getUsers());
  };
  useEffect(() => {
    if (showTeamMenu) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [showTeamMenu]);

  const secondaryMenu = (
    <SecondaryMenu
      selectedTeam={selectedTeam}
      pathname={pathname}
      hash={location?.hash || ''}
      // eslint-disable-next-line
      // @ts-ignore
      title={superAdminRoute ? i18n._(t`Super admin`) : selectedMenuBlock?.title(i18n)}
      // eslint-disable-next-line
      // @ts-ignore
      items={superAdminRoute ? superAdminMenuItems : selectedMenuBlock?.children}
      isMenuWithIcons={selectedMenuBlock?.key === MENU_ITEMS.ACCOUNT && !superAdminRoute}
    />
  );

  return (
    <SideMenuWrapper>
      <PrimaryMenu
        // eslint-disable-next-line
        // @ts-ignore
        mainMenuBlocks={mainMenuBlocks}
        footerMenuWithURL={footerMenuWithURL}
        accountMenuWithUrl={accountMenuWithUrl}
        isSecondaryMenuOpen={(selectedMenuBlock?.children || [])?.length > 1}
        numberOfUnreadNotifications={numberOfUnreadNotifications}
        onReadAllNotifications={onReadAllNotifications}
        isUserHasAdminRole={isUserHasAdminRole}
      />
      {(() => {
        if (superAdminRoute) {
          return secondaryMenu;
        }

        if (!selectedMenuBlock) {
          return;
        }

        switch (selectedMenuBlock.key) {
          case 'coach-menu':
            return (
              <TeamMenu
                title={i18n._(t`Teams`)}
                teams={teams}
                users={users}
                menuItems={selectedMenuBlock}
                inactiveUsers={inactiveUsers}
                isEngagementModuleEnabled={isModuleEngagementEnabled}
              />
            );
          case 'reports':
            return (
              <ReportMenu
                pathname={pathname}
                // eslint-disable-next-line
                // @ts-ignore
                title={selectedMenuBlock.title(i18n)}
                // eslint-disable-next-line
                // @ts-ignore
                items={selectedMenuBlock.children}
                isAdmin={isUserHasAdminRole}
              />
            );
          default:
            if (selectedMenuBlock?.children && selectedMenuBlock?.children?.length > 1) {
              return secondaryMenu;
            } else {
              return;
            }
        }
      })()}
      {/* eslint-disable-next-line */}
      {/* @ts-ignore */}
      {$isNotebookOpen.value && <NotebookModal onClose={$isNotebookOpen.off} />}
    </SideMenuWrapper>
  );
};

export default React.memo(SideMenu);
