import React, { MouseEventHandler } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';
import { FlexRow, NameRow } from '~/pages/Conversations/design';

import type { IColumnTable } from '~/@types/table';
import { COLORS } from '~/styles';
import convertToTimeString from '~/utils/convertToTimeString';

import { Name, DateRow } from '../../design';

import type { IReviewOld } from '@learned/types';
import type { I18n } from '@lingui/core';

export enum SORT_OPTIONS {
  NAME_A_Z = 'name_asc',
  NAME_Z_A = 'name_desc',
  DATE_SOON_LATER = 'date_asc',
  DATE_LATER_SOON = 'date_desc',
}
// @ts-ignore
const RepeatColumn = ({ item }) => {
  const { i18n } = useLingui();
  const getRepeatLabelText = () => {
    if (!item.isRepeated) {
      return null;
    }
    if (item.repeat.every !== 1) {
      return i18n._(t`Repeated Custom`);
    }
    if (item.repeat.type === 'week') {
      return i18n._(t`Repeated Weekly`);
    }
    if (item.repeat.type === 'year') {
      return i18n._(t`Repeated Yearly`);
    }
  };

  const getRepeatLabel = () => {
    if (!item.isRepeated) {
      return null;
    } else {
      return (
        <ToolTip tooltip={getRepeatLabelText()} placement={TOOLTIP_PLACEMENTS.RIGHT} arrow={false}>
          <span>
            <Icon icon={ICONS.REFRESH} size={ICON_SIZES.MEDIUM} color={COLORS.TOOLTIP_BACKGROUND} />
          </span>
        </ToolTip>
      );
    }
  };

  return <>{getRepeatLabel()}</>;
};

const COLUMNS: IColumnTable<IReviewOld>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'name',
    maxWidth: '500px',
    renderCell: (item, onClick: MouseEventHandler<HTMLDivElement> | undefined) => {
      const conversationName = item.name;
      return (
        <NameRow>
          {/* @ts-ignore */}
          <FlexRow onClick={() => onClick(item)}>
            <ToolTip tooltip={conversationName} placement={TOOLTIP_PLACEMENTS.BOTTOM} arrow={false}>
              <Name>
                {conversationName} <RepeatColumn item={item} />
              </Name>
            </ToolTip>
          </FlexRow>
        </NameRow>
      );
    },
    sortBy: {
      asc: {
        key: SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Date`),
    accessor: 'date',
    maxWidth: '100px',
    renderCell: (item: any) => {
      return (
        <DateRow>
          {item.dateOfConversation || item.startDate ? (
            `${convertToTimeString(item.dateOfConversation || item.startDate)}`
          ) : (
            <Trans>Not planned</Trans>
          )}
        </DateRow>
      );
    },
    sortBy: {
      asc: {
        key: SORT_OPTIONS.DATE_SOON_LATER,
        label: (i18n: I18n) => i18n._(t`Soon - later`),
      },
      desc: {
        key: SORT_OPTIONS.DATE_LATER_SOON,
        label: (i18n: I18n) => i18n._(t`Later - soon`),
      },
    },
  },
];

export { COLUMNS };
