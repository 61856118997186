import React, { useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  SURVEY_STATUS,
  SURVEY_TASK_STATUS,
  SURVEY_TEMPLATE_STATUSES,
} from '@learned/constants';
import { ISurvey } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import styled from 'styled-components';

import { FillOutSurvey } from '~/components/FillOutSurvey';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import type { PopulatedSurveyTemplate } from '~/pages/SurveyTemplateUpdate/types';
import { ThemeTemplateDashboard } from '~/pages/SurveyThemeView/components/ThemeTemplateDashboard';
import { DASHBOARD_TYPE } from '~/pages/SurveyThemeView/constants';

import routes from '~/constants/routes';
import { useLanguageState } from '~/hooks/useLanguageState';
import { checkModuleSurvey } from '~/selectors/baseGetters';
import { getSurveyTemplate, updateSurveyTemplate } from '~/services/surveyTemplates';
import { COLORS } from '~/styles';

import type { ISurveyQuestion, ISurveyTemplate } from '@learned/types';

const PreviewWrapper = styled.div`
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  background-color: ${COLORS.BG_PAGE};
`;

const SurveyTemplateView = () => {
  const params: Record<string, string | undefined> = useParams();
  const surveyTemplateId = params.surveyTemplateId;
  const [template, setTemplate] = useState<PopulatedSurveyTemplate | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const languageState = useLanguageState();
  const { i18n } = useLingui();
  const history = useHistory();
  const isModuleSurveyEnabled = useSelector(checkModuleSurvey);

  useEffect(() => {
    if (!isModuleSurveyEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleSurveyEnabled, history]);

  useEffect(() => {
    let mounted = true;

    const fetchTemplate = async () => {
      if (!surveyTemplateId) {
        return;
      }
      setIsLoading(true);
      const { data } = await getSurveyTemplate(surveyTemplateId, {
        populate: ['questions'],
        joinToQuestions: ['themeName'],
      });

      const surveyTemplate: PopulatedSurveyTemplate = data[API_RETURN_FIELDS.SURVEY_TEMPLATE];
      if (mounted && surveyTemplate) {
        setTemplate(surveyTemplate);
      }
      setIsLoading(false);
    };
    fetchTemplate();

    return () => {
      mounted = false;
    };
  }, [surveyTemplateId]);

  const onEdit = async () => {
    const isConfirmed =
      (template && template.status === SURVEY_TEMPLATE_STATUSES.draft) ||
      (await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Be aware`),
        description: i18n._(
          t`Changing this template will affect all surveys that use this template`,
        ),
      }));
    if (isConfirmed) {
      routes.SURVEY_TEMPLATE_UPDATE.go({}, { surveyTemplateId, isBackPath: true });
    }
  };
  const onQuestionEdit = async (questionId: string) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Be aware`),
      description: i18n._(t`Changing this question will affect all surveys that use this question`),
    });
    if (isConfirmed) {
      routes.SURVEY_TEMPLATE_UPDATE.go(
        {},
        { surveyTemplateId, isBackPath: true, query: { questionId } },
      );
    }
  };

  if (template && showPreview && isModuleSurveyEnabled) {
    return (
      <PreviewWrapper>
        <FillOutSurvey
          onEdit={onQuestionEdit}
          isPreview
          surveyTask={{
            status: SURVEY_TASK_STATUS.DRAFT,
            name: template.name,
            questions: template.questions,
            survey: { status: SURVEY_STATUS.ACTIVE } as ISurvey,
          }}
          goBack={() => setShowPreview(false)}
        />
      </PreviewWrapper>
    );
  }

  const onPublish = async () => {
    if (!template) {
      return;
    }

    setIsLoading(true);
    const data = {
      ...pick(template, ['name', 'description', 'themeWeights']),
      status: SURVEY_TEMPLATE_STATUSES.active,
      questions: template?.questions.map((q: ISurveyQuestion) => q.id),
    } as ISurveyTemplate;
    try {
      const result = await updateSurveyTemplate(template.id, data);
      if (result.code === 200) {
        setTemplate(
          (prev) =>
            ({ ...prev, status: result.data.surveyTemplate.status } as PopulatedSurveyTemplate),
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isTemplateValid =
    isEmpty(template?.name[languageState.companyPrimaryLanguage.locale]) ||
    (template?.questions || [])?.map((q) => q.id).filter((q) => q).length < 1;

  return (
    <>
      {isModuleSurveyEnabled && (
        <ThemeTemplateDashboard
          isLoading={isLoading}
          onEdit={onEdit}
          item={template}
          dashboardType={DASHBOARD_TYPE.TEMPLATE}
          showPreview={() => setShowPreview(true)}
          isPublishDisabled={isTemplateValid}
          onPublish={onPublish}
        />
      )}
    </>
  );
};

export { SurveyTemplateView };
