import React, { useState, useEffect } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';
import ConversationIcon from '~/components/Icons/Conversation';
import Modal from '~/components/Modal';
import Placeholder from '~/components/Placeholder';
import Radio from '~/components/Radio';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import { Body16 } from '~/components/UI/Typographics/text';

import { TALKING_POINT_TYPES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { createTalkingPoints } from '~/services/talkingPoints';
import { getUserConversations } from '~/services/userConversations';
import { COLORS } from '~/styles';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 24px;
  border-bottom: 1px solid ${COLORS.BORDER_SOFT};
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${COLORS.BORDER_SOFT};
  }
`;

const ConversationsContainer = styled.div`
  height: 448px;
  overflow: auto;
`;

const ItemName = styled(Body16)`
  font-weight: 600;
`;

function AddTalkingPointInConversationModal({ targetId, targetName, targetUser, onClose }) {
  const { i18n } = useLingui();
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const { addToast } = useToasts();
  const $loading = useBoolState(true);

  useEffect(() => {
    const fetchData = async () => {
      const userConversations = await getUserConversations();
      $loading.off();

      setItems(Object.values(userConversations));
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const renderUserConversation = (c) => {
    const isChecked = selectedItem?.id === c.id;
    return (
      <Item key={c.id} onClick={() => setSelectedItem(c)}>
        <ItemName>
          {c.startDate && (
            <span>{convertToTimeString(c.startDate, TIME_FORMATS.CLASSIC_FULL)} - </span>
          )}
          {c.name}
        </ItemName>
        <Radio checked={isChecked} />
      </Item>
    );
  };

  const onSubmit = async () => {
    const newTalkingPoint = {
      name: targetName,
      conversation: selectedItem?.id,
      type: TALKING_POINT_TYPES.GOALS.key,
      target: targetId,
      targetUser,
      comments: [],
    };

    await createTalkingPoints({ talkingPoints: [newTalkingPoint] });

    addToast({
      title: i18n._(t`Talking point is created`),
      subtitle: i18n._(t`You can find the talking point in: ${selectedItem?.name}`),
      type: TOAST_TYPES.INFO,
    });

    onClose();
  };

  return (
    <Modal
      title={<Trans>Add goal as talking point to 1-1</Trans>}
      width={509}
      minWidth={509}
      onClose={onClose}
      contentStyles={{ padding: 0 }}
      footerStyles={{ padding: '0 24px', height: '86px', minHeight: '86px' }}
      headerStyles={{
        padding: '0 24px',
        height: '56px',
        minHeight: '56px',
        fontSize: '16px',
        lineHeight: '1.63',
      }}
      footerRight={
        <Button
          styles={{
            height: '40px',
            padding: '0px 19px',
            minWidth: '91px',
          }}
          label={i18n._(t`Add`)}
          type="primary"
          onClick={onSubmit}
          disabled={!selectedItem}
        />
      }
    >
      <ShowSpinnerIfLoading loading={$loading.value}>
        {!isEmpty(items) ? (
          <ConversationsContainer>{items.map(renderUserConversation)}</ConversationsContainer>
        ) : (
          <Placeholder
            Icon={ConversationIcon}
            title={i18n._(t`No conversations`)}
            subTitle={i18n._(t`There have not been created any conversations`)}
          />
        )}
      </ShowSpinnerIfLoading>
    </Modal>
  );
}

AddTalkingPointInConversationModal.propTypes = {
  onClose: PropTypes.func,
  targetId: PropTypes.string,
  targetName: PropTypes.string,
  targetUser: PropTypes.arrayOf(PropTypes.string),
};

export default AddTalkingPointInConversationModal;
