export enum APP_PLATFORM {
  IOS = 'ios',
  ANDROID = 'android',
}

export enum RESPONSE_STATUSES {
  SUCCESS = 'success',
  FAIL = 'fail',
  ERROR = 'error',
}
