import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ICONS, Icon } from '~/components/Icon';
import { JobProfileCard } from '~/components/JobCards/JobProfileCard';
import { AddRoleModal } from '~/components/Modals/AddRoleModal';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import {
  CardsContainer,
  Container,
  DottedCard,
  HeaderRow,
  HeaderText,
  RoleLabel,
  InnerContainer,
  PlaceholderText,
} from './design';

import type { IJobProfile } from '~/constants/jobProfiles';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { createLikes, deleteLike } from '~/services/likes';
import { COLORS } from '~/styles';

type Item = {
  coverImage?: string;
  jobProfileName: string;
  id: string;
  jobProfileId: string;
};

interface IProps {
  companyColor?: string;
  items: Item[];
  refresh: () => void;
  jobProfiles: { [key: string]: IJobProfile };
}

const CustomPathSection = ({ items, refresh, jobProfiles, companyColor }: IProps) => {
  const getMultiLangString = useMultiLangString();
  const { addToast } = useToasts();
  const { i18n } = useLingui();

  const $isShowBrowseJobs = useBoolState(false);
  const $isLoading = useBoolState(false);

  const onRemoveLikedJob = async (id: string) => {
    await deleteLike(id);
    addToast({
      type: TOAST_TYPES.SUCCESS,
      title: i18n._(t`You have removed your like`),
      subtitle: i18n._(t`HR and your manager will no longer see that you are interested. `),
    });
    refresh();
  };

  const likeJobProfiles = async (jobProfileIds: string[]) => {
    $isLoading.on();
    await createLikes(jobProfileIds.map((id) => ({ jobProfileId: id })));
    addToast({
      type: TOAST_TYPES.SUCCESS,
      title: i18n._(t`You liked the role`),
      subtitle: i18n._(t`The HR team and your manager see that you are interested.`),
    });
    $isLoading.off();
    refresh();
  };

  const previewRole = (roleId: string) => {
    routes.JOB_PROFILE.go(
      {},
      {
        roleId,
        isBackPath: true,
      },
    );
  };

  return (
    <Container>
      <HeaderRow>
        <RoleLabel color={COLORS.LEARNING} backgroundColor={COLORS.LEARNING_LIGHT}>
          <Trans>Custom path</Trans>
        </RoleLabel>
        <RoleLabel color={COLORS.LEARNING} backgroundColor={COLORS.LEARNING_LIGHT}>
          <Icon icon={ICONS.SHAPE} width={12} height={12} />
          {items.length || 0}
        </RoleLabel>
        <HeaderText>
          <Trans>Create your own career path</Trans>
        </HeaderText>
      </HeaderRow>

      <CardsContainer>
        {items.map((item) => {
          return (
            <JobProfileCard
              companyColor={companyColor}
              jobProfile={{
                coverImage: item.coverImage,
                name: getMultiLangString(item.jobProfileName),
                id: item.id,
              }}
              onClickViewDetails={() => previewRole(item.jobProfileId)}
              key={item.id}
              onDelete={() => onRemoveLikedJob(item.id)}
              showDeleteBtn
              hideSaveBtn
            />
          );
        })}
        <DottedCard>
          <InnerContainer onClick={$isShowBrowseJobs.on}>
            <Icon icon={ICONS.PLUS} width={24} height={24} className="browse-jobs" />
            <PlaceholderText className="browse-jobs">
              <Trans>Browse jobs</Trans>
            </PlaceholderText>
          </InnerContainer>
        </DottedCard>
      </CardsContainer>
      {$isShowBrowseJobs.value && (
        <AddRoleModal
          jobProfiles={jobProfiles}
          onClose={$isShowBrowseJobs.off}
          save={likeJobProfiles}
          modalTitle={i18n._(t`Browse jobs`)}
          confirmText={i18n._(t`Like job`)}
          isLoading={$isLoading.value}
          isSingleSelect={true}
        />
      )}
    </Container>
  );
};

export { CustomPathSection };
