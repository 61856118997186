import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { createAction } from 'redux-act';

import * as teamsService from '~/services/teams';
import * as usersService from '~/services/users';
import { updateCompanyConnection } from '~/store/companyConnections/actions';
import { fetchUserData } from '~/store/users/actions';
import * as userActions from '~/store/users/actions';

export const startGetTeams = createAction('start get teams');
export const finishGetTeams = createAction('finish get teams');

export const startCreateTeams = createAction('start create teams');
export const finishCreateTeams = createAction('finish create teams');

export const startUpdateTeam = createAction('start update teams');
export const finishUpdateTeam = createAction('finish update teams');

export const startUpdateTeams = createAction('start update teams');
export const finishUpdateTeams = createAction('finish update teams');

export const startRemoveTeams = createAction('start remove teams');
export const finishRemoveTeams = createAction('finish remove teams');

export function getTeams() {
  return function (dispatch) {
    dispatch(startGetTeams());
    return teamsService.getTeams().then((teams) => {
      dispatch(finishGetTeams(teams));
      return teams;
    });
  };
}

export function getTeam(id) {
  return function (dispatch) {
    return teamsService.getTeam(id).then((team) => {
      dispatch(finishUpdateTeam(team));
      return team;
    });
  };
}

export function createTeams(teams) {
  return function (dispatch) {
    dispatch(startCreateTeams());
    return teamsService.createTeams(teams).then((teams) => {
      dispatch(finishCreateTeams(teams));
      return teams;
    });
  };
}

export function removeTeams(deleteTeams = []) {
  return function (dispatch) {
    dispatch(startRemoveTeams());
    const teamsIds = deleteTeams.map((team) => team.id);
    return teamsService.deleteTeams(teamsIds).then(async () => {
      dispatch(finishRemoveTeams(teamsIds));

      // update connection to change ROLE
      const deleteCoaches = [];
      deleteTeams.forEach((deleteTeam) => deleteCoaches.push(...(deleteTeam.coaches || [])));
      const deleteCoachesUniq = uniq(deleteCoaches);

      await Promise.all(
        deleteCoachesUniq.map(async (coachId) => {
          const connection = await usersService.getUserConnection(coachId);
          if (!isEmpty(connection)) {
            dispatch(updateCompanyConnection(connection));
          }
        }),
      );

      return deleteTeams;
    });
  };
}

export function updateTeamName(id, name) {
  return function (dispatch) {
    dispatch(startUpdateTeam());
    return teamsService.updateTeamName(id, name).then((team) => {
      dispatch(finishUpdateTeam(team));
      return team;
    });
  };
}

/*
 * data example [[Team1Id, updateTeam1Data{}], [Team2Id, updateTeam2Data{}], ...]
 * */
export function updateTeams(data) {
  return function (dispatch) {
    dispatch(startUpdateTeams());
    return teamsService.updateTeams(data).then((teams) => {
      dispatch(finishUpdateTeams(teams));
      return teams;
    });
  };
}

export function removeCoachFromTeam(teamId, coachToRemove, { sendRequestAsAdmin = false } = {}) {
  return async function (dispatch, getStore) {
    dispatch(startUpdateTeam());
    const user = getStore().auth.user;
    const updatedTeam = await teamsService.removeCoachFromTeam(coachToRemove, teamId, {
      sendRequestAsAdmin,
    });
    dispatch(finishUpdateTeam(updatedTeam));
    if (String(coachToRemove) === String(user.id)) {
      await dispatch(fetchUserData());
      dispatch(finishUpdateTeam(updatedTeam));
    } else {
      // update connection to change ROLE
      const connection = await usersService.getUserConnection(coachToRemove);
      if (!isEmpty(connection)) {
        dispatch(updateCompanyConnection(connection));
      }
    }
    return updatedTeam;
  };
}

export function removeMemberFromTeam(teamId, memberId, { sendRequestAsAdmin = false } = {}) {
  return async function (dispatch) {
    const updatedTeam = await teamsService.removeMemberFromTeam(memberId, teamId, {
      sendRequestAsAdmin,
    });
    dispatch(finishUpdateTeam(updatedTeam));

    await dispatch(userActions.getUsers());
  };
}

export function addCoaches(teamId, coaches, { sendRequestAsAdmin = false } = {}) {
  return async function (dispatch, getStore) {
    const user = getStore().auth.user;
    const updatedTeam = await teamsService.addCoachesToTeam(coaches, teamId, {
      sendRequestAsAdmin,
    });
    dispatch(finishUpdateTeam(updatedTeam));

    await Promise.all([
      // if delete current user - update user data
      coaches.includes(user.id) && dispatch(fetchUserData()),

      dispatch(userActions.getUsers()),

      // update connection to change ROLE
      Promise.all(
        coaches.map(async (coach) => {
          const connection = await usersService.getUserConnection(coach);
          if (!isEmpty(connection)) {
            dispatch(updateCompanyConnection(connection));
          }
        }),
      ),
    ]);

    return updatedTeam;
  };
}

export function addMembersToTeam(
  teamId,
  members,
  isPermissionsCheck = true,
  { sendRequestAsAdmin = false } = {},
) {
  return async function (dispatch) {
    const updatedTeam = await teamsService.addMembersToTeam(members, teamId, isPermissionsCheck, {
      sendRequestAsAdmin,
    });
    dispatch(finishUpdateTeam(updatedTeam));

    await dispatch(userActions.getUsers());
  };
}
