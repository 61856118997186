import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import { List } from '~/pages/Reviews/Modals/ReviewEmployeeOverviewModal/ReviewEmployeeOverviewForm/components/List';
import { ListRowPlaceholder } from '~/pages/Reviews/Modals/ReviewEmployeeOverviewModal/ReviewEmployeeOverviewForm/components/ListRowPlaceholder';

import { ListRowGuest } from '../components/ListRowGuest';
import { Section } from '../components/Section';

import type { IUserReview } from '@learned/types';

const ButtonWrapper = styled.div`
  margin-top: 12px;
`;

interface GuestsSectionProps {
  guests: IUserReview['guests'];
  coaches: IUserReview['guests'];
  onDeleteGuest: (guestId: string) => void;
  onAddGuests: () => void;
  isAllowToDeleteGuests: boolean;
  isAllowToAddGuests: boolean;
}

const GuestsSection = ({
  guests,
  coaches,
  onDeleteGuest,
  onAddGuests,
  isAllowToDeleteGuests,
  isAllowToAddGuests,
}: GuestsSectionProps) => {
  const { i18n } = useLingui();

  return (
    <Section
      title={i18n._(t`Guests`)}
      description={i18n._(t`Guests are invited to the review but do not have to provide input.`)}
    >
      <div>
        <List>
          {isEmpty(guests) ? (
            <ListRowPlaceholder text={i18n._(t`No guests selected.`)} />
          ) : (
            guests.map((guestId, key) => (
              <ListRowGuest
                key={key}
                guestId={guestId}
                isCoach={coaches.includes(guestId)}
                onDelete={isAllowToDeleteGuests ? onDeleteGuest : undefined}
              />
            ))
          )}
        </List>
        <ButtonWrapper>
          {isAllowToAddGuests && (
            <Button
              variant={ButtonVariant.TEXT_PRIMARY}
              size={ButtonSize.MEDIUM}
              label={i18n._(t`Add guests`)}
              onClick={onAddGuests}
              icon={ICONS.ADD_USER}
              iconSize={ICON_SIZES.SMALL}
            />
          )}
        </ButtonWrapper>
      </div>
    </Section>
  );
};

export { GuestsSection };
