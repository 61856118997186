import React from 'react';

import { SURVEY_QUESTION_TYPES } from '@learned/constants';

import { SurveyStarsQuestion } from '~/components/SurveyQuestionReport/SurveyStarsQuestion';

import { SurveyQuestion, IItem } from './SurveyQuestion';

// example
/* const item = {
  title: 'How are you? (TEXT)',
  titleNumber: '1.1',
  themeName: 'Theme name',
  type: SURVEY_QUESTION_TYPES.TEXT,
  answers: [
    // text question
    {
      label: 'bad',
      isValue: false,
    },

    // dropdown, singleSelect and multiSelect questions
    {
      label: 'bad',
      isValue: true,
      value: 5,
      percentage: 43,
    },

    // smileys question
    {
      icon: '😟',
      label: 'normal',
      isValue: true,
      value: 5,
      percentage: 0,
    },

    // trafficLight question
    {
      light: 'red',
      label: 'good',
      isValue: true,
      value: 5,
      percentage: 73,
    },

    // stars
    {
      label: 'best', // only for first and last item
      isValue: true,
      value: 57,
      percentage: 45,
    },
  ],
};*/

interface ISurveyQuestionReportProps {
  item: IItem;
}

const SurveyQuestionReport = ({ item }: ISurveyQuestionReportProps) => {
  switch (item.type) {
    case SURVEY_QUESTION_TYPES.TEXT:
    case SURVEY_QUESTION_TYPES.DROPDOWN:
    case SURVEY_QUESTION_TYPES.SINGLE:
    case SURVEY_QUESTION_TYPES.MULTIPLE:
    case SURVEY_QUESTION_TYPES.SMILEYS:
    case SURVEY_QUESTION_TYPES.TRAFFIC:
      return <SurveyQuestion item={item} />;
    case SURVEY_QUESTION_TYPES.STARS:
      return <SurveyStarsQuestion item={item} />;
    default:
      return null;
  }
};

export { SurveyQuestionReport };
