import * as Sentry from '@sentry/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { createAction } from 'redux-act';

import getAllUsers from '~/selectors/getAllUsers';
import * as usersService from '~/services/users';
import * as appThemeActions from '~/store/appTheme/actions';
import * as companyActions from '~/store/companies/actions';
import * as companySettingsActions from '~/store/companySettings/actions';
import { changeLang } from '~/store/locale/actions';
import * as selectedActions from '~/store/selected/actions';

import { setCompanyAddonsToReduxStore } from '../addons/actions';
import * as authActions from '../auth/actions';
import { updateUserProfile } from '../auth/actions';
import * as companyConnectionActions from '../companyConnections/actions';
import { setCompanyProductsToReduxStore } from '../products/actions';
import * as teamActions from '../teams/actions';

export const startGetUsers = createAction('start get users');
export const finishGetUsers = createAction('finish get users');
export const startUpdateMemberProfile = createAction('start updating member profile');
export const finishUpdateMemberProfile = createAction('finish updating member profile');
export const removeUser = createAction('remove user');
export const restoreUser = createAction('restore removed user');
export const updateUser = createAction('update existing user');
export const finishGetInactiveUsers = createAction('finish get inactive users');
export const finishGetDeletedUsers = createAction('finish get deleted users');

export const finishSetInactiveUser = createAction('finish set inactive user');
export const finishRemoveInactiveUser = createAction('finish remove inactive user');

export const startDeleteUsers = createAction('start delete users');
export const finishDeleteUsers = createAction('finish delete users');

export function getUsers(params = {}) {
  return async function (dispatch) {
    try {
      dispatch(startGetUsers());
      const users = await usersService.getUsers(params);
      dispatch(finishGetUsers(users));
      return users;
    } catch (e) {
      dispatch(finishGetUsers({}));
    }
  };
}

export function deleteUserFromCompany(userId) {
  return async function (dispatch) {
    try {
      dispatch(removeUser(userId));
      await usersService.removeUserFromCompany(userId);
    } catch (e) {
      dispatch(restoreUser(userId));
    }
  };
}

/* usersIds - [userId1, userId2]*/
export function deleteUsers(usersIds) {
  return async function (dispatch, getState) {
    try {
      dispatch(startDeleteUsers());
      await usersService.deleteUsers(usersIds);

      // prepare users objects for redux online update
      const allUsers = getAllUsers(getState());
      const deletedUsers = usersIds.map((userId) => allUsers[userId]);
      dispatch(finishDeleteUsers(deletedUsers));
    } catch (e) {
      // eslint-disable-next-line
      console.log('e', e);
    }
  };
}

export const finishSetUserData = createAction('finishSetUserData');
export function fetchUserData({ userCompaniesData = {}, avatarUrl } = {}) {
  return async function (dispatch, getState) {
    // for login
    let selectedCompanyId = getState().selected.company;
    if (!selectedCompanyId && !isEmpty(userCompaniesData)) {
      const userConnections = get(userCompaniesData, 'connections');
      selectedCompanyId = !isEmpty(userConnections) && userConnections[0].company;
      await dispatch(selectedActions.updateSelectedCompany(selectedCompanyId));
    }
    const userData = await usersService.getUserData();
    if (!isEmpty(userData)) {
      Sentry.setUser(userData.user);
      dispatch(changeLang(userData.user.locale));
      dispatch(
        appThemeActions.setTheme(selectedCompanyId && userData.companies[selectedCompanyId]),
      );
      if (
        !userData.user?.integrations?.find((integration) => !integration.isRevoked) &&
        !userData.user?.showBecauseOfRevoke
      ) {
        dispatch(updateUserProfile({ showIntegrationQuestion: true, showBecauseOfRevoke: true }));
      } else if (
        !userData.user?.integrations?.find((integration) => integration.isRevoked) &&
        userData.user?.showBecauseOfRevoke
      ) {
        dispatch(updateUserProfile({ showBecauseOfRevoke: false }));
      }
      dispatch(finishGetUsers(userData.users));
      dispatch(finishGetInactiveUsers(userData.inactiveUsers));
      dispatch(finishGetDeletedUsers(userData.deletedUsers));
      dispatch(companyActions.finishGetCompanies(userData.companies));
      dispatch(teamActions.finishGetTeams(userData.teams));
      dispatch(companyConnectionActions.setCompanyConnections(userData.companyConnections));
      dispatch(companySettingsActions.setCompanySettings(userData.companySettings));
      dispatch(authActions.setAuthUser(userData.user));
      // set modules
      const selectedCompany = get(userData, `companies[${selectedCompanyId}]`, {});
      dispatch(setCompanyProductsToReduxStore(selectedCompany));
      dispatch(setCompanyAddonsToReduxStore(selectedCompany));

      const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const userTimeZone = userData?.user?.timeZone;
      if (
        currentTimeZone &&
        userTimeZone &&
        userTimeZone.toLowerCase() !== currentTimeZone.toLowerCase()
      ) {
        dispatch(authActions.updateUserProfile({ timeZone: currentTimeZone }));
      }
      if (!userData.user.avatarUrl && avatarUrl) {
        dispatch(authActions.setAuthUser(await usersService.updateUserProfile({ avatarUrl })));
      }

      return userData;
    }
  };
}

export function updateMembersProfiles(data = {}) {
  return async function (dispatch) {
    try {
      dispatch(startUpdateMemberProfile());
      const updatedMembersList = await usersService.updateMembersProfiles(data);

      if (get(updatedMembersList, 'emailExistsError')) {
        return updatedMembersList;
      }

      const updatedMembers = {};
      for (const m of updatedMembersList) {
        updatedMembers[m.id] = m;
      }
      dispatch(finishUpdateMemberProfile(updatedMembers));
      return updatedMembersList;
    } catch (e) {
      dispatch(finishGetUsers({}));
    }
  };
}
