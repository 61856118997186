import React, { useEffect, useState } from 'react';

import { KPI_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  AutocompleteFilterTeams,
  AutocompleteFilterReviews,
  AutocompleteFilterMembers,
  AutocompleteFilterSkills,
} from '~/components/AutocompleteFilters';
import Button from '~/components/Button';
import ChartLine from '~/components/ChartLine';
import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';

import SectionTemplate from './components/SectionTemplate';

import SkillsIcon from '~/assets/mdi-tag-multiple.svg';

import useBoolState from '~/hooks/useBoolState';
import { getReviewsReport } from '~/services/reviewsOld';
import { COLOR_PALETTE } from '~/styles';

const Filters = styled.div`
  display: flex;
  padding: 17px 24px 16px;
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_MIDDLE};
`;

const FilterReviews = styled(AutocompleteFilterReviews)`
  width: 157px;
  height: 32px;
`;

const FilterTeams = styled(AutocompleteFilterTeams)`
  width: 157px;
  height: 32px;
  margin-left: 8px;
`;

const FilterMembers = styled(AutocompleteFilterMembers)`
  width: 157px;
  height: 32px;
  margin-left: 8px;
`;

const FilterSkills = styled(AutocompleteFilterSkills)`
  width: 157px;
  height: 32px;
  margin-left: 8px;
`;

const ChartWrapper = styled.div`
  height: 309px;
  padding: 17px 24px 16px;
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin-top: 31px;
`;

const APPLIED_FILTERS_DEFAULT = {
  reviews: [],
  members: [],
  skills: [],
};

function SkillCoverageGraph({ i18n, description, skills = [], onFetchFinish, isHideUI }) {
  const [graphData, setGraphData] = useState([]);
  const [selectedReviews, setSelectedReviews] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState(APPLIED_FILTERS_DEFAULT); // uses for disable apply button
  const $fetching = useBoolState(true);

  const isFilterSame = () => {
    let members = [];

    if (!isEmpty(selectedMembers)) {
      members = [...members, ...selectedMembers.map((m) => m.id)];
    }

    if (!isEmpty(selectedTeams)) {
      members = uniq([...members, ...concat(...selectedTeams.map((t) => t.members))]);
    }

    let skillsJoin = [];
    if (!isEmpty(selectedSkills)) {
      skillsJoin = [...skills, ...selectedSkills.map((m) => m.id)];
    }

    const currentFilters = {
      selectedReviews,
      members,
      skills: skillsJoin,
    };

    return isEqual(appliedFilters, currentFilters);
  };

  const fetchData = async () => {
    let members = [];

    if (!isEmpty(selectedMembers)) {
      members = [...members, ...selectedMembers.map((m) => m.id)];
    }

    if (!isEmpty(selectedTeams)) {
      members = uniq([...members, ...concat(...selectedTeams.map((t) => t.members))]);
    }

    let skillsJoin = [];
    if (!isEmpty(selectedSkills)) {
      skillsJoin = [...skills, ...selectedSkills.map((m) => m.id)];
    }

    const data = await getReviewsReport({
      pagination: { limit: 12 },
      sectionTypes: [KPI_TYPES.SKILLS],
      ...(!isEmpty(selectedReviews) && { reviews: selectedReviews.map((r) => r.id) }),
      ...(!isEmpty(members) && { members }),
      ...(!isEmpty(skillsJoin) && { skills: skillsJoin }),
    });

    setGraphData(data);
    setAppliedFilters({
      selectedReviews,
      members,
      skills: skillsJoin,
    });
    $fetching.off();

    // onFetchFinish
    if (onFetchFinish) {
      onFetchFinish(data);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, []);

  const labels = graphData.map((i) => moment(i.date).format('DD MMM YYYY'));
  const dataSets = [
    {
      label: i18n._(t`Self review`),
      data: graphData.map((i) => i.self),
      names: graphData.map((i) => i.name),
    },
    {
      label: i18n._(t`Peer review`),
      data: graphData.map((i) => i.peer),
      names: graphData.map((i) => i.name),
    },
    {
      label: i18n._(t`Coach review`),
      data: graphData.map((i) => i.coach),
      names: graphData.map((i) => i.name),
    },
  ];

  return (
    !isHideUI && (
      <SectionTemplate title={i18n._(t`Skill coverage`)} description={description}>
        <Filters>
          <FilterReviews checkedList={selectedReviews} onChange={setSelectedReviews} showTooltip />
          <FilterTeams checkedList={selectedTeams} onChange={setSelectedTeams} />
          <FilterMembers checkedList={selectedMembers} onChange={setSelectedMembers} />
          <FilterSkills checkedList={selectedSkills} onChange={setSelectedSkills} />
          <Button
            label={i18n._(t`Apply`)}
            onClick={fetchData}
            styles={{ marginLeft: 'auto', width: '83px', height: '32px' }}
            disabled={isFilterSame()}
          />
        </Filters>
        <ShowSpinnerIfLoading loading={$fetching.value}>
          {!isEmpty(graphData) ? (
            <ChartWrapper>
              <ChartLine labels={labels} dataSets={dataSets} spanGaps />
            </ChartWrapper>
          ) : (
            <Placeholder
              title={i18n._(t`No data available yet`)}
              subTitle={i18n._(
                t`Start with measuring skill coverage in your company by linking skill coverage to questions in a review template. `,
              )}
              Icon={() => (
                <StyledSvgIcon
                  url={SkillsIcon}
                  width={'32px'}
                  height={'32px'}
                  isDefaultColor
                  defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                />
              )}
              subTitleStyles={{
                width: '435px',
                color: COLOR_PALETTE.DARK_GRAY,
                fontWeight: 600,
                lineHeight: 1.57,
              }}
            />
          )}
        </ShowSpinnerIfLoading>
      </SectionTemplate>
    )
  );
}

SkillCoverageGraph.propTypes = {
  description: PropTypes.string,
  skills: PropTypes.arrayOf(PropTypes.string),
  onFetchFinish: PropTypes.func, // function executes after first fetch
  isHideUI: PropTypes.bool, // hide component but onFetchFinish still working
};

export default withI18n()(SkillCoverageGraph);
