import React, { useState, useEffect } from 'react';

import UpdatesBlock from './UpdatesBlock';

import useBoolState from '~/hooks/useBoolState';
import { getGoalsUpdates, updateGoalsUpdate } from '~/services/goalsUpdates';

// TODO: this component will be fully updated in UPDATES & NOTES epic

const UpdatesCard = ({ goal, isUpdateSidebar, updateSidebar }) => {
  const [updates, setUpdates] = useState([]);
  const [updateComments, setUpdateComments] = useState([]);

  const $isLoading = useBoolState(false);

  const fetchUpdates = async (isMounted) => {
    $isLoading.on();
    const goalUpdates = await getGoalsUpdates({ goalId: goal.id, populate: ['target'] });
    if (isMounted) {
      const preparedGoalsUpdates = Object.values(goalUpdates);
      setUpdates(preparedGoalsUpdates);
    }
    $isLoading.off();
  };

  useEffect(() => {
    let isMounted = true;
    if (goal.id) {
      fetchUpdates(isMounted);
    }

    return () => (isMounted = false);
    // eslint-disable-next-line
  }, [goal]);

  useEffect(() => {
    let isMounted = true;

    if (isUpdateSidebar) {
      fetchUpdates(isMounted);
      updateSidebar(false);
    }

    return () => (isMounted = false);
    // eslint-disable-next-line
  }, [isUpdateSidebar]);

  const updateUpdate = (goalsUpdateId) => {
    setUpdateComments([...updateComments, goalsUpdateId]);
  };

  const updateCommentText = (e, updateId) => {
    setUpdates(
      updates.map((update) =>
        update.id === updateId
          ? {
              ...update,
              comment: e,
            }
          : update,
      ),
    );
  };

  const updateCommentFunction = (updateId) => {
    setUpdateComments(updateComments.filter((comment) => comment !== updateId));
    updateGoalsUpdate(updateId, updates.find((u) => u.id === updateId).comment);
  };

  return (
    <UpdatesBlock
      updates={updates}
      goalName={goal.name}
      updateGoalsUpdate={updateUpdate}
      updateComments={updateComments}
      updateCommentText={updateCommentText}
      updateCommentFunction={updateCommentFunction}
      isLoading={$isLoading.value}
    />
  );
};

export { UpdatesCard };
