import React, { ReactNode } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS, ICON_SIZES } from '~/components/Icon';

const Wrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;

  > div {
    flex: 1;
    display: flex;
  }
`;

const RightActionContainer = styled.div`
  justify-content: end;
`;

interface IFooter {
  isPrev?: boolean;
  isNext?: boolean;
  onPrev?: () => void;
  children?: ReactNode;
}
const StepFooter = ({ isPrev = true, isNext = true, onPrev, children }: IFooter) => {
  const { i18n } = useLingui();

  return (
    <Wrapper>
      <div>
        {isPrev && (
          <Button
            label={i18n._(t`Back`)}
            type="button"
            variant={ButtonVariant.TEXT_PRIMARY}
            icon={ICONS.BACK}
            size={ButtonSize.MEDIUM}
            iconSize={ICON_SIZES.SMALL}
            onClick={onPrev}
          />
        )}
      </div>
      {children}
      <RightActionContainer>
        {isNext && (
          <Button
            label={i18n._(t`Next`)}
            variant={ButtonVariant.NAVIGATION_PRIMARY}
            size={ButtonSize.MEDIUM}
            type="submit"
          />
        )}
      </RightActionContainer>
    </Wrapper>
  );
};

export { StepFooter };
