export function toFixed(value, numbersAfterDot = 1) {
  const multiplier = Math.pow(10, numbersAfterDot);

  return Math.floor(value * multiplier) / multiplier;
}

export function average(list = [], numbersAfterDot = 1) {
  if (list.length === 0) {
    return 0;
  }
  const sum = list.reduce((com, curr) => curr + com);

  return toFixed(sum / list.length, numbersAfterDot);
}
