import styled from 'styled-components';

import { COLORS } from '~/styles';

const ItemBoxHorizontal = styled.div`
  box-sizing: border-box;
  content: '';
  width: 24px;
  height: 24px;
  border-radius: 6px;
  background: ${COLORS.HOVER};
  border: 2px solid ${COLORS.BORDERS};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  display: none;
  .plus_icon {
    cursor: pointer;
    color: ${COLORS.PLACEHOLDERS};
  }
  &:hover {
    .plus_icon {
      color: ${COLORS.COMPANY};
    }
  }
`;

const AddItemHorizontal = styled.div<{ isFirst: boolean }>`
  position: absolute;
  width: 26px;
  height: 100%;
  top: 0;
  left: ${({ isFirst }) => (isFirst ? '-2px' : 'initial')};
  right: ${({ isFirst }) => (isFirst ? 'initial' : '-2px')};
  transform: ${({ isFirst }) => (isFirst ? 'translateX(-50%)' : 'translateX(50%)')};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    width: 4px;
    height: 80%;
    border-radius: 10px;
    transform: translateX(-50%);
    background: ${COLORS.PLACEHOLDERS};
    display: none;
  }
  &:hover {
    .plus_icon {
      display: block;
    }
    &::before {
      display: block;
    }
    ${ItemBoxHorizontal} {
      display: flex;
    }
  }
`;

const ItemBoxVertical = styled.div`
  box-sizing: border-box;
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  width: 32px;
  height: 24px;
  border-radius: 6px;
  transform: translateX(-50%);
  background: ${COLORS.HOVER};
  border: 2px solid ${COLORS.BORDERS};
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  .plus_icon {
    display: none;
    cursor: pointer;
    color: ${COLORS.PLACEHOLDERS};
  }
  &:hover {
    .plus_icon {
      color: ${COLORS.COMPANY};
    }
  }
`;

const AddItemVertical = styled.div<{ isFirst: boolean }>`
  position: absolute;
  width: 100%;
  height: 26px;
  left: 0;
  top: ${({ isFirst }) => (isFirst ? '-2px' : 'initial')};
  bottom: ${({ isFirst }) => (isFirst ? 'initial' : '-2px')};
  transform: ${({ isFirst }) => (isFirst ? 'translateY(-50%)' : 'translateY(50%)')};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    width: 80%;
    height: 4px;
    border-radius: 10px;
    transform: translateX(-50%);
    background: ${COLORS.PLACEHOLDERS};
    display: none;
  }
  &:hover {
    .plus_icon {
      display: block;
    }
    &::before {
      display: block;
    }
    ${ItemBoxVertical} {
      display: flex;
    }
  }
`;

const SquareBox = styled.div`
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  background: ${COLORS.HOVER};
  border: 2px solid ${COLORS.BORDERS};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  .plus_icon {
    cursor: pointer;
    color: ${COLORS.PLACEHOLDERS};
  }
  &:hover {
    .plus_icon {
      color: ${COLORS.COMPANY};
    }
  }
`;

const PopOverOuterContainer = styled.div<{ isFirst: boolean }>`
  position: absolute;
  width: 26px;
  height: 100%;
  left: ${({ isFirst }) => (isFirst ? '0px' : 'initial')};
  right: ${({ isFirst }) => (isFirst ? 'initial' : '0px')};
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: '';
    position: absolute;
    left: ${({ isFirst }) => (isFirst ? '0px' : 'initial')};
    right: ${({ isFirst }) => (isFirst ? 'initial' : '0px')};
    width: 4px;
    height: 80%;
    border-radius: 10px;
    background: ${COLORS.PLACEHOLDERS};
    display: none;
  }
  &:hover {
    &::before {
      display: block;
    }
    ${SquareBox} {
      display: flex;
    }
  }
`;

export {
  AddItemHorizontal,
  AddItemVertical,
  ItemBoxHorizontal,
  ItemBoxVertical,
  PopOverOuterContainer,
  SquareBox,
};
