import React, { useState } from 'react';

import { Trans } from '@lingui/macro';

import { ICON_SIZES, ICONS } from '~/components/Icon';
import {
  Count,
  Level,
  FocusArea,
  LevelName,
  StyledIcon,
} from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateForm/components/design';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import { StyledLevelHeader } from '../design';

import type { IMultiLangString } from '@learned/types';

function FocusAreas({
  levelName,
  focusAreas,
}: {
  focusAreas: {
    level: number;
    values: { id: string; name: IMultiLangString }[];
  };
  levelName: IMultiLangString;
}) {
  const [opened, setOpened] = useState(false);

  const getMultiLangString = useMultiLangString();

  return (
    <Level>
      <StyledLevelHeader onClick={() => setOpened((prev) => !prev)}>
        <LevelName>{getMultiLangString(levelName)}</LevelName>
        <Count>
          <Trans>{focusAreas.values.length} focus area&apos;s</Trans>
          <StyledIcon icon={ICONS.DROPDOWN} size={ICON_SIZES.SMALL} $opened={opened} />
        </Count>
      </StyledLevelHeader>
      {opened && (
        <>
          {focusAreas.values.map((focusArea) => (
            <FocusArea key={focusArea.id}>{getMultiLangString(focusArea.name)}</FocusArea>
          ))}
        </>
      )}
    </Level>
  );
}

export { FocusAreas };
