import { REVIEW_CREATED_BY, REVIEW_SECTION_THEMES, REVIEW_STATUS } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

import BusinessGoalsThemeIcon from '~/assets/themes/ThemeIcons/BusinessGoalThemeIcon.svg';
import CareerThemeIcon from '~/assets/themes/ThemeIcons/CareerThemeIcon.svg';
import DefaultThemeIcon from '~/assets/themes/ThemeIcons/DefaultThemeIcon.svg';
import LearningGoalThemeIcon from '~/assets/themes/ThemeIcons/LearningGoalThemeIcon.svg';
import SkillsThemeIcon from '~/assets/themes/ThemeIcons/SkillsThemeIcon.svg';
import WellBeingThemeIcon from '~/assets/themes/ThemeIcons/WellBeingThemeIcon.svg';

import { RATING_TYPES } from '~/constants';
import { COLOR_SET, COLOR_PALETTE } from '~/styles';

export interface IMultiSelectOption {
  id: string;
  key: string;
  name: string;
  translated: (i18n: I18n) => string;
}

export const REVIEW_SECTION_THEMES_STYLES = {
  [REVIEW_SECTION_THEMES.DEFAULT]: {
    key: REVIEW_SECTION_THEMES.DEFAULT,
    title: (i18n: I18n) => i18n._(t`Default`),
    color: COLOR_PALETTE.DARK_GRAY,
    darkGradient: COLOR_PALETTE.GRAY_MIDDLE,
    lightGradient: COLOR_SET.BUSINESS_LIGHT_GRADIENT,
    icon: DefaultThemeIcon,
  },
  [REVIEW_SECTION_THEMES.BUSINESS_GOALS]: {
    key: REVIEW_SECTION_THEMES.BUSINESS_GOALS,
    title: (i18n: I18n) => i18n._(t`Business goals`),
    color: COLOR_SET.CORNFLOWER_BLUE,
    darkGradient: COLOR_SET.BUSINESS_DARK_GRADIENT,
    lightGradient: COLOR_SET.BUSINESS_LIGHT_GRADIENT,
    icon: BusinessGoalsThemeIcon,
  },
  [REVIEW_SECTION_THEMES.LEARNING_GOALS]: {
    key: REVIEW_SECTION_THEMES.LEARNING_GOALS,
    title: (i18n: I18n) => i18n._(t`Learning goals`),
    color: COLOR_SET.FUCHSIA,
    darkGradient: COLOR_SET.LEARNING_DARK_GRADIENT,
    lightGradient: COLOR_SET.LEARNING_LIGHT_GRADIENT,
    icon: LearningGoalThemeIcon,
  },
  [REVIEW_SECTION_THEMES.SKILLS]: {
    key: REVIEW_SECTION_THEMES.SKILLS,
    title: (i18n: I18n) => i18n._(t`Skills`),
    color: COLOR_SET.MIDNICHT_BLUE,
    darkGradient: COLOR_SET.SKILLS_DARK_GRADIENT,
    lightGradient: COLOR_SET.SKILLS_LIGHT_GRADIENT,
    icon: SkillsThemeIcon,
  },
  [REVIEW_SECTION_THEMES.CAREER]: {
    key: REVIEW_SECTION_THEMES.CAREER,
    title: (i18n: I18n) => i18n._(t`Career`),
    color: COLOR_SET.TURQUOISE,
    darkGradient: COLOR_SET.AMBITIONS_DARK_GRADIENT,
    lightGradient: COLOR_SET.AMBITIONS_LIGHT_GRADIENT,
    icon: CareerThemeIcon,
  },
  [REVIEW_SECTION_THEMES.WELL_BEING]: {
    key: REVIEW_SECTION_THEMES.WELL_BEING,
    title: (i18n: I18n) => i18n._(t`Well being`),
    color: COLOR_SET.GOLDENROD,
    darkGradient: COLOR_SET.WELLBEING_DARK_GRADIENT,
    lightGradient: COLOR_SET.WELLBEING_LIGHT_GRADIENT,
    icon: WellBeingThemeIcon,
  },
  [REVIEW_SECTION_THEMES.AMBITIONS]: {
    key: REVIEW_SECTION_THEMES.AMBITIONS,
    title: (i18n: I18n) => i18n._(t`Ambitions`),
    color: COLOR_SET.TURQUOISE,
    darkGradient: COLOR_SET.AMBITIONS_DARK_GRADIENT,
    lightGradient: COLOR_SET.AMBITIONS_LIGHT_GRADIENT,
    icon: CareerThemeIcon,
  },
};

export const GIVE_FEEDBACK_LEGEND = {
  [RATING_TYPES.SELF_RATING]: {
    color: COLOR_SET.ULTRAMARINE_BLUE,
    label: (i18n: I18n) => i18n._(t`Your input`),
  },
  [RATING_TYPES.OTHER_RATING]: {
    color: COLOR_SET.CYBER_YELLOW,
    label: (i18n: I18n) => i18n._(t`Peer input`),
  },
  [RATING_TYPES.OUTSIDE_RATING]: {
    color: COLOR_SET.CYBER_YELLOW,
    label: (i18n: I18n) => i18n._(t`Peer input`),
  },
  [RATING_TYPES.COACH_RATING]: {
    color: COLOR_SET.FUCHSIA,
    label: (i18n: I18n) => i18n._(t`Coach input`),
  },
};

export const REVIEW_STATUS_OPTIONS: IMultiSelectOption[] = [
  {
    id: REVIEW_STATUS.DRAFT,
    key: REVIEW_STATUS.DRAFT,
    name: 'Draft',
    translated: (i18n: I18n) => i18n._(t`Draft`),
  },
  {
    id: REVIEW_STATUS.PUBLISHED,
    key: REVIEW_STATUS.PUBLISHED,
    name: 'Published',
    translated: (i18n: I18n) => i18n._(t`Published`),
  },
  {
    id: REVIEW_STATUS.COMPLETED,
    key: REVIEW_STATUS.COMPLETED,
    name: 'Completed',
    translated: (i18n: I18n) => i18n._(t`Completed`),
  },
  {
    id: REVIEW_STATUS.ARCHIVED,
    key: REVIEW_STATUS.ARCHIVED,
    name: 'Archived',
    translated: (i18n: I18n) => i18n._(t`Archived`),
  },
  // fake status (does not exist in DB)
  {
    id: 'upcoming',
    key: 'upcoming',
    name: 'Upcoming',
    translated: (i18n: I18n) => i18n._(t`Upcoming`),
  },
];

export const REVIEW_CREATED_BY_OPTIONS: IMultiSelectOption[] = [
  {
    id: REVIEW_CREATED_BY.ADMIN,
    key: REVIEW_CREATED_BY.ADMIN,
    name: 'Admin',
    translated: (i18n: I18n) => i18n._(t`Admin`),
  },
  {
    id: REVIEW_CREATED_BY.COACH,
    key: REVIEW_CREATED_BY.COACH,
    name: 'Coach',
    translated: (i18n: I18n) => i18n._(t`Coach`),
  },
  {
    id: REVIEW_CREATED_BY.EMPLOYEE,
    key: REVIEW_CREATED_BY.EMPLOYEE,
    name: 'Employee',
    translated: (i18n: I18n) => i18n._(t`Employee`),
  },
];

export const REVIEW_CYCLE_STATUS_OPTIONS: IMultiSelectOption[] = [
  {
    id: REVIEW_STATUS.DRAFT,
    key: REVIEW_STATUS.DRAFT,
    name: 'Draft',
    translated: (i18n: I18n) => i18n._(t`Draft`),
  },
  {
    id: REVIEW_STATUS.PUBLISHED,
    key: REVIEW_STATUS.PUBLISHED,
    name: 'Upcoming',
    translated: (i18n: I18n) => i18n._(t`Upcoming`),
  },
  {
    id: REVIEW_STATUS.ACTIVE,
    key: REVIEW_STATUS.ACTIVE,
    name: 'Active',
    translated: (i18n: I18n) => i18n._(t`Active`),
  },
  {
    id: REVIEW_STATUS.COMPLETED,
    key: REVIEW_STATUS.COMPLETED,
    name: 'Completed',
    translated: (i18n: I18n) => i18n._(t`Completed`),
  },
  {
    id: REVIEW_STATUS.ARCHIVED,
    key: REVIEW_STATUS.ARCHIVED,
    name: 'Archived',
    translated: (i18n: I18n) => i18n._(t`Archived`),
  },
];
