import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { COLORS } from '~/styles';

const Container = styled.div`
  position: absolute;
  right: 0;
  bottom: -2px;
  transform: translateY(100%);
  min-width: 150px;
  z-index: 1000;
  cursor: pointer;
  background: ${COLORS.WHITE};
  display: flex;
  flex-direction: column;
  padding: 2px;
  font-weight: normal;
  border-radius: 6px;
  border: 1px solid ${COLORS.BORDERS};
`;

const TextCtr = styled.button`
  all: unset;
  font-size: 12px;
  padding: 6px 16px;
  &:hover {
    background: ${COLORS.HOVER};
    color: ${COLORS.COMPANY};
  }
  &:first-child {
    border-radius: 6px 6px 0 0;
  }
  &:last-child {
    border-radius: 0 0 6px 6px;
  }
`;

type TProps = {
  onMenuChange: (type: 'save' | 'update') => void;
  reference: React.RefObject<HTMLDivElement>;
  isDefault: boolean;
};
function SaveAsMenu({ onMenuChange, reference, isDefault }: TProps): JSX.Element {
  const { i18n } = useLingui();
  return (
    <Container ref={reference}>
      <TextCtr onClick={() => onMenuChange('save')}>{i18n._(t`Save as new message`)}</TextCtr>
      {isDefault && (
        <TextCtr onClick={() => onMenuChange('update')}>
          {i18n._(t`Overwrite existing message `)}
        </TextCtr>
      )}
    </Container>
  );
}

export default SaveAsMenu;
