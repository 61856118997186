import React, { useMemo } from 'react';

import {
  CAREER_PLAN_STATUSES,
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
  REVIEW_QUESTION_TYPES_V1,
  ROLES,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { flatten, uniq } from 'lodash';
import { UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';

import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import { TablePlaceholder } from '~/components/TablePlaceholder';
import { useInactiveUsers } from '~/pages/Reviews/hooks/useInactiveUsers';

import useBoolState from '~/hooks/useBoolState';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { getSettingsRole } from '~/selectors/baseGetters';
import { getCareerPlansAsAdminCoach } from '~/services/careerPlans';
import { getUserTeams } from '~/services/teams';

import { SelectEmployeeModal } from '../SelectEmployeeModal';
import { StepFooter } from '../StepFooter';

import type { IEmployee, IReviewIndividualForm, PopulatedCareerPlan } from '../../types';
import type { ITeam, IUser } from '@learned/types';

interface EmployeePlaceHolderProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewIndividualForm>;
  languageState: ILanguageStateReturn;
}

const EmployeePlaceHolder = ({ formMethods, sectionState }: EmployeePlaceHolderProps) => {
  const { setValue, watch } = formMethods;
  const { i18n } = useLingui();
  const $isShowSelectModal = useBoolState(false);
  const userRole = useSelector(getSettingsRole);
  const { removeInactiveUsers } = useInactiveUsers();

  const fetchCareerPlans = async (userId: string) => {
    const res = await getCareerPlansAsAdminCoach(
      { status: CAREER_PLAN_STATUSES.CURRENT, createdFor: userId },
      { populate: ['jobProfile'] },
      userRole,
    );
    return Object.values(res as Record<string, PopulatedCareerPlan>);
  };

  const fetchTeamsInfo = async (user: IUser) => {
    // @ts-ignore
    const res = await getUserTeams(user.id, { isMember: true, isCoach: user.isCoach });
    return uniq(flatten(Object.values(res as Record<string, ITeam>).map((item) => item.coaches)));
  };

  const options = useMemo(() => {
    return {
      showCoaches: !!watch('evaluators')
        ?.map((item) => item.value)
        ?.includes(REVIEW_QUESTION_EVALUATORS.COACH),
      showJobs: watch('reviewQuestionTypes').some((type) =>
        [REVIEW_QUESTION_TYPES.SKILL_CATEGORY, REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1].includes(
          type,
        ),
      ),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify([watch('evaluators'), watch('reviewQuestionTypes')])]);

  const addEmployee = async (user: IEmployee) => {
    const [coaches, careerPlans] = await Promise.all([
      fetchTeamsInfo(user),
      fetchCareerPlans(user.id),
    ]);

    const primaryPlan = careerPlans?.find((plan) => !!plan.primary);

    setValue(
      'employees',
      [
        {
          ...user,
          coaches: removeInactiveUsers(coaches),
          guests: options.showCoaches ? removeInactiveUsers(coaches) : [],
          careerPlans: primaryPlan ? [primaryPlan] : [],
          availableCoaches: removeInactiveUsers(coaches) || [],
          availableCareerPlans: careerPlans || [],
        },
      ],
      { shouldDirty: true },
    );
  };

  return (
    <>
      <TablePlaceholder
        isLoading={false}
        isFiltered={false}
        emptyStateText={i18n._(t`No employee selected…`)}
        onResetSearch={() => {}}
        emptyButtonText={i18n._(t`Add`)}
        onClickEmptyButton={$isShowSelectModal.on}
      />
      {$isShowSelectModal.value && (
        <SelectEmployeeModal
          onSave={addEmployee}
          onClose={$isShowSelectModal.off}
          usersToHide={[]}
          isOnlyCoachTeamMembers={userRole === ROLES.COACH}
        />
      )}
      <StepFooter
        onNext={() => sectionState.setCurrentSection(++sectionState.currentSection)}
        onPrev={() => sectionState.setCurrentSection(--sectionState.currentSection)}
      />
    </>
  );
};

export { EmployeePlaceHolder };
