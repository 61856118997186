import { isNaN } from 'lodash';

const formatNumber = (value: string) => {
  if (value === '') {
    return undefined;
  }
  return isNaN(Number(value)) ? undefined : Number(value);
};

export { formatNumber };
