import React from 'react';

import { ICalendarEvent } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import DigitalMeetingLink from '~/components/DigitalMeetingLink';

import { Block } from '../components/Block';

interface ICalendarBlockProps {
  isAllowedToAccessMeetingLink: boolean;
  calendarEvent: ICalendarEvent;
}

const CalendarBlock = ({ calendarEvent, isAllowedToAccessMeetingLink }: ICalendarBlockProps) => {
  const { i18n } = useLingui();

  return (
    calendarEvent && (
      <Block title={i18n._(t`Calendar`)}>
        {isAllowedToAccessMeetingLink && (
          <DigitalMeetingLink event={calendarEvent} updateCalendarEvent={() => {}} />
        )}
      </Block>
    )
  );
};

export { CalendarBlock };
