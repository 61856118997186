import React, { useEffect, useState } from 'react';

import { FocusAreaType } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import flatten from 'lodash/flatten';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import uniqBy from 'lodash/uniqBy';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { MultiLayerList } from '~/components/MultiLayerList';

import type { ISelectedFocusArea, ISelectedSkillTemplate } from '~/@types/job';
import { useMultiLangString } from '~/hooks/useMultiLangString';

import { Title, Header, Actions, Subtitle, Footer, TitleContainer } from '../design';

import type { IForm, SelectLevelAndFocusAreasProps } from '../types';
import type { IMultiLangString, ISkill, ISkillTemplate } from '@learned/types';

type PopulatedFocusAreas = {
  name: string;
  values: { id: string; name: IMultiLangString }[];
  showValues: boolean;
};

function SelectLevelAndFocusAreas<T extends IForm>({
  onClose,
  skillCategoryName,
  skillCategoryId,
  skillCategoryLevels = [],
  source,
  setIsSelectLevelAndFocusAreasVisible,
  formMethods,
  setSkillTemplates,
  setSkills,
}: SelectLevelAndFocusAreasProps<T>) {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const [focusAreas, setFocusAreas] = useState<PopulatedFocusAreas[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedLevels, setSelectedLevels] = useState<string[]>([]);
  const { setValue, watch } = formMethods;

  const selectedFocusAreas =
    // @ts-ignore
    (watch(`skills.${skillCategoryId}.skills`) as ISelectedSkillTemplate['skills']).find((item) =>
      [item.skill, item.skillTemplate].includes(source.id),
    )?.selectedFocusAreas;

  useEffect(() => {
    const focusAreasIds: string[] = [];
    const levelIds: string[] = [];

    selectedFocusAreas?.forEach((item) => {
      if (
        item.type === FocusAreaType.SELECT_FOCUS_AREA &&
        !isEmpty(item.focusArea) &&
        !isNil(item.focusArea) &&
        !isNil(item.level) &&
        item.level < skillCategoryLevels.length
      ) {
        focusAreasIds.push(...item.focusArea);
      } else if (
        item.type === FocusAreaType.SELECT_LEVEL &&
        !isNil(item.level) &&
        item.level < skillCategoryLevels.length
      ) {
        levelIds.push(item.level.toString());
        focusAreasIds.push(...source.focusAreas[item.level].values.map(({ id }) => id));
      } else if (item.type === FocusAreaType.SELECT_ALL) {
        const focusAreas = source.focusAreas.filter((fa) => fa.level < skillCategoryLevels.length);
        levelIds.push(...focusAreas.map(({ level }) => level.toString()));
        focusAreasIds.push(...flatten(focusAreas.map(({ values }) => values.map(({ id }) => id))));
      }
    });
    setSelectedValues(focusAreasIds);
    setSelectedLevels(levelIds);
  }, [focusAreas, selectedFocusAreas, source.focusAreas, skillCategoryLevels]);

  useEffect(() => {
    setFocusAreas(
      source.focusAreas
        .filter((focusArea) => focusArea.level < skillCategoryLevels.length)
        .map((focusArea) => ({
          ...focusArea,
          name: skillCategoryLevels[focusArea.level]
            ? getMultiLangString(skillCategoryLevels[focusArea.level])
            : `Level ${focusArea.level}`,
          showValues: false,
        })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source.focusAreas, skillCategoryLevels]);

  const onSave = () => {
    let selectedFocusAreas: ISelectedFocusArea[] = [];
    if (selectedValues.length || selectedLevels.length) {
      // select all levels
      if (selectedLevels.length === focusAreas.length) {
        selectedFocusAreas = [{ type: FocusAreaType.SELECT_ALL }];
      } else {
        focusAreas.forEach((focusArea, index) => {
          const ids = focusArea.values?.map(({ id }) => id);
          const commonIds = intersection(ids, selectedValues);

          // select level
          if (selectedLevels.includes(index.toString())) {
            selectedFocusAreas.push({
              type: FocusAreaType.SELECT_LEVEL,
              level: index,
            });
          } else {
            // select focus areas
            if (commonIds.length) {
              selectedFocusAreas.push({
                type: FocusAreaType.SELECT_FOCUS_AREA,
                level: index,
                focusArea: commonIds,
              });
            }
          }
        });
      }
    }

    // @ts-ignore
    if (source?.company) {
      setSkills?.((skill) => uniqBy(skill.concat(source as ISkill), ({ id }) => id));
    } else {
      setSkillTemplates?.((prevSkillTemplates) =>
        uniqBy(prevSkillTemplates.concat(source as ISkillTemplate), ({ id }) => id),
      );
    }

    // @ts-ignore
    setValue(`skills.${skillCategoryId}.skills`, [
      // @ts-ignore
      ...watch(`skills.${skillCategoryId}.skills`)
        .filter(
          // @ts-ignore
          ({ skillTemplate, skill }) => ![skill, skillTemplate].includes(source.id),
        )
        .filter(
          // @ts-ignore
          ({ skillTemplate, skill }) =>
            (skillTemplate && (source as ISkill)?.skillTemplate !== skillTemplate) ||
            (skill && (source as ISkill)?.id !== skill),
        ),
      {
        selectedFocusAreas,
        // @ts-ignore
        ...(source?.company ? { skill: source.id } : { skillTemplate: source.id }),
      },
    ]);
  };

  return (
    <>
      <Header>
        <TitleContainer>
          <Title>
            <Trans>Add skill</Trans>
          </Title>
          <Subtitle>
            <Trans>Select level and focus area for skill: {skillCategoryName}</Trans>
          </Subtitle>
        </TitleContainer>
      </Header>
      <MultiLayerList
        columnName={i18n._(t`Levels & focus areas`)}
        selectedCounterLabel={i18n._(t`selected focus areas`)}
        selectedCounterParentLabel={i18n._(t`selected levels`)}
        counterLabel={i18n._(t`all focus areas`)}
        toggles={{
          hideItems: i18n._(t`Hide focus areas`),
          showItems: i18n._(t`Show focus areas`),
        }}
        data={focusAreas?.map((item, index) => ({
          id: `${index}`,
          name: item.name,
          showSubItems: item.showValues,
          subItems: item.values?.map((value) => ({
            id: value.id,
            name: getMultiLangString(value.name),
          })),
        }))}
        selectedItems={selectedValues}
        setSelectedItems={setSelectedValues}
        selectedParentItems={selectedLevels}
        setSelectedParentItems={setSelectedLevels}
        subItemsFontSize="14px"
      />
      <Footer>
        <Actions>
          <Button
            label={i18n._(t`Cancel`)}
            type="button"
            variant={ButtonVariant.TEXT_PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={onClose}
          />
          <Button
            label={i18n._(t`Add skill`)}
            type="button"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={() => {
              onSave();
              onClose();
            }}
            disabled={!selectedValues.length && !selectedLevels.length}
          />
        </Actions>
        <Actions>
          <Button
            label={i18n._(t`Back`)}
            type="button"
            variant={ButtonVariant.TEXT_PRIMARY}
            icon={ICONS.BACK}
            size={ButtonSize.MEDIUM}
            onClick={() => setIsSelectLevelAndFocusAreasVisible(false)}
          />
        </Actions>
      </Footer>
    </>
  );
}

export { SelectLevelAndFocusAreas };
