import React, { MouseEventHandler } from 'react';

import { SURVEY_THEMES_SORT_OPTIONS, SURVEY_THEME_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';

import { IconWithGradientBlock } from '~/components/IconWithGradientBlock';

import { FlexRow, NameRow, TD } from './design';
import { NameWithQuestions } from './NameWithQuestions';

import type { IColumnTable } from '~/@types/table';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

import type { ISurveyThemeWithMultiLangName } from '@learned/types';
import type { I18n } from '@lingui/core';

const THEMES_COLUMNS: IColumnTable[] = [
  {
    name: (i18n: I18n) => i18n._(t`Themes`),
    accessor: 'name',
    // @ts-ignore
    renderCell: (
      item: ISurveyThemeWithMultiLangName,
      onClick: MouseEventHandler<HTMLDivElement> | undefined,
    ) => {
      return (
        <NameRow>
          {/* @ts-ignore */}
          <FlexRow onClick={() => onClick(item)}>
            <IconWithGradientBlock icon={item.icon} iconColor={item.iconColor} />
            <NameWithQuestions
              name={item.local.name}
              questionsTotal={(item.questions || []).length}
              isGrayColor={item.status === SURVEY_THEME_STATUS.DRAFT}
            />
          </FlexRow>
        </NameRow>
      );
    },
    sortBy: {
      asc: {
        key: SURVEY_THEMES_SORT_OPTIONS.NAME_A_Z,

        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: SURVEY_THEMES_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Created`),
    accessor: 'meta.createdDate',
    minWidth: '162px',
    sortBy: {
      asc: {
        key: SURVEY_THEMES_SORT_OPTIONS.CREATED_NEW_OLD,

        label: (i18n: I18n) => i18n._(t`New - old`),
      },
      desc: {
        key: SURVEY_THEMES_SORT_OPTIONS.CREATED_OLD_NEW,
        label: (i18n: I18n) => i18n._(t`Old - new`),
      },
    },
    // @ts-ignore
    renderCell: ({ meta, status }) => {
      return (
        <TD isGrayColor={status === SURVEY_THEME_STATUS.DRAFT}>
          <span>{convertToTimeString(meta.createdDate, TIME_FORMATS.CLASSIC)}</span>
        </TD>
      );
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Updated`),
    accessor: 'meta.lastModifiedDate',
    minWidth: '162px',
    sortBy: {
      asc: {
        key: SURVEY_THEMES_SORT_OPTIONS.UPDATED_NEW_OLD,

        label: (i18n: I18n) => i18n._(t`New - old`),
      },
      desc: {
        key: SURVEY_THEMES_SORT_OPTIONS.UPDATED_OLD_NEW,
        label: (i18n: I18n) => i18n._(t`Old - new`),
      },
    },
    // @ts-ignore
    renderCell: ({ meta, status }) => {
      return (
        <TD isGrayColor={status === SURVEY_THEME_STATUS.DRAFT}>
          <span>{convertToTimeString(meta.lastModifiedDate, TIME_FORMATS.CLASSIC)}</span>
        </TD>
      );
    },
  },
];

export { THEMES_COLUMNS };
