import React, { PureComponent } from 'react';

import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import styled from 'styled-components';

import Popover from '~/components/Popover';
import SvgIcon from '~/components/SvgIcon';
import Tooltip from '~/components/Tooltip';

import { COLORS, COLOR_PALETTE } from '~/styles';

const MenuItems = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: ${(props) => (props.$isRow ? 'column' : 'row')};
  width: ${(props) => (props.$maxWidth ? `${props.$maxWidth}px` : 'auto')};
  flex-wrap: wrap;
  width: 100%;
`;

const MenuItem = styled.li`
  display: flex;
  flex-direction: column;
  width: 98px;
  height: 78px;
  justify-content: center;
  align-items: center;
  background: none;
  cursor: pointer;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  text-align: center;
  color: ${COLORS.TEXT_PRIMARY};
  box-sizing: border-box;

  &:hover {
    color: var(--company-color);
  }
  :nth-child(odd) {
    border-right: 1px solid ${COLORS.BORDER};
    border-bottom: 1px solid ${COLORS.BORDER};
  }
  :nth-child(even) {
    border-bottom: 1px solid ${COLORS.BORDER};
  }
  :last-child,
  :nth-last-of-type(2) {
    border-bottom: none;
  }
`;

const MenuRowItem = styled.li`
  display: flex;
  width: 100%;
  height: ${(props) => props.$height || null};
  box-sizing: border-box;
  align-items: center;
  background: ${(props) => (props.$isDisabled ? COLOR_PALETTE.GRAY_MIDDLE : COLOR_PALETTE.WHITE)};
  white-space: nowrap;
  border-bottom: 1px solid ${COLORS.BG_PAGE};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: ${(props) => (props.$isDisabled ? COLOR_PALETTE.GRAY_MIDDLE : COLORS.BG_PAGE)};
    color: ${(props) => (props.$isDisabled ? COLORS.TEXT_PRIMARY : 'var(--company-color)')};
    cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};
  }

  h4 {
    padding: 0;
    margin: 0;
  }

  svg {
    margin-right: 11px;
  }
`;

const MenuItemLabel = styled.div`
  margin-left: 8px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--company-color);
`;

const Title = styled.div`
  display: flex;
  width: 165px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_PRIMARY};
  border-bottom: solid 1px ${COLORS.BORDER};
  text-transform: uppercase;
  padding: 8px;
`;

const SVGIcon = styled(SvgIcon)`
  margin-right: 11px;
`;

const Wrapper = styled.div`
  outline: none;

  div {
    outline: none;
  }
`;

/**
 * @deprecated since version 3.0
 */
class ActionsPopover extends PureComponent {
  static defaultProps = {
    items: [],
    isRows: false,
    contentWidth: 'auto',
    className: '',
  };

  state = {
    hoveredItem: null,
  };

  popover = React.createRef();

  onItemClick = (e, item) => {
    if (item.isDisabled) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();
    this.popover.current._tippy.hide();
    item.action();
  };

  renderItem = (item, index) => {
    const { i18n } = this.props;
    const { hoveredItem } = this.state;

    if (!item) {
      return <MenuItem key={index} />;
    }

    return (
      <MenuItem
        onMouseDown={(e) => e.preventDefault()}
        onMouseUp={(e) => this.onItemClick(e, item)}
        key={index}
        onMouseEnter={() => this.setState({ hoveredItem: index })}
        onMouseLeave={() => this.setState({ hoveredItem: null })}
      >
        <SvgIcon
          width="12px"
          height="12px"
          url={item.icon}
          defaultColor="var(--company-color)"
          isDefaultColor={hoveredItem !== index}
        />
        <MenuItemLabel>
          {typeof item.label === 'string' ? item.label : i18n._(item.label)}
        </MenuItemLabel>
      </MenuItem>
    );
  };

  renderRowItem = (item, index) => {
    const { i18n } = this.props;

    const label = (
      <MenuItemLabel $isDisabled={item.isDisabled}>
        {get(item.label, 'id') ? i18n._(item.label) : item.label}
      </MenuItemLabel>
    );

    return (
      <MenuRowItem
        onMouseDown={(e) => e.preventDefault()}
        onMouseUp={(e) => this.onItemClick(e, item)}
        onMouseEnter={() => this.setState({ hoveredItem: index })}
        onMouseLeave={() => this.setState({ hoveredItem: null })}
        key={index}
        $height="32px"
        $isDisabled={item.isDisabled}
      >
        {item.icon &&
          (typeof item.icon === 'string' ? (
            <SVGIcon
              style={{ margin: '12px 0 12px 8px' }}
              width="12px"
              height="12px"
              defaultColor="var(--company-color)"
              isDefaultColor
              url={item.icon}
            />
          ) : (
            <item.icon style={{ margin: '12px 8px 12px 0' }} size={12} fill="var(--company-color" />
          ))}
        {item.tooltip ? (
          <Tooltip maxWidth="400px" tooltip={item.tooltip}>
            {label}
          </Tooltip>
        ) : (
          label
        )}
      </MenuRowItem>
    );
  };

  render() {
    const {
      children,
      isRows,
      items,
      popoverTitle,
      placement,
      className,
      contentWidth,
      maxWidth,
      minWidth,
      disabled,
    } = this.props;

    const elements = !isRows && items.length % 2 > 0 ? items.concat(null) : items;

    return (
      // do not remove this div, it's with contentWidth fix safary content position
      <Wrapper className={className} style={{ width: contentWidth }}>
        <Popover
          ref={this.popover}
          minWidth={minWidth || 160}
          maxWidth={isRows ? maxWidth : 200}
          placement={placement}
          trigger="click"
          disabled={disabled || items.length === 0}
          border={isRows ? 3 : 10}
          content={
            <MenuItems $isRow={isRows} $maxWidth={isRows ? maxWidth : 197}>
              {popoverTitle && <Title>{popoverTitle}</Title>}
              {elements.map(isRows ? this.renderRowItem : this.renderItem)}
            </MenuItems>
          }
          appendTo={document.body}
        >
          <div tabIndex="0" role="button">
            {children}
          </div>
        </Popover>
      </Wrapper>
    );
  }
}

export default withI18n()(ActionsPopover);
