import React, { useState, useEffect } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import SelectModal from '~/components/SelectModal';

import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

const CopyTalkingPointSection = ({ $conversation }) => {
  const { i18n } = useLingui();
  const [futureConversations, setFutureConversations] = useState([]);

  const {
    isCopyTalkingPointModal,
    closeCopyTalkingPointModal,
    onCopyTalkingPoint,
    repeatedConversations,
    id: conversationId,
  } = $conversation;

  useEffect(() => {
    // Map and sort repeated conversations dates
    const events = repeatedConversations.map((conv) => ({
      id: conv.id,
      label: convertToTimeString(conv.startDate, TIME_FORMATS.DAY_FULL_MONTH_YEAR),
      startDate: conv.startDate,
    }));
    const sortedEvents = events.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

    // Leave only future conversations
    const upcomingEvents = [];
    let upcoming = false;
    sortedEvents.forEach((event) => {
      if (upcoming) {
        upcomingEvents.push(event);
      } else if (event.id === conversationId) {
        upcoming = true;
      }
    });
    setFutureConversations(upcomingEvents);
  }, [conversationId, repeatedConversations]);

  return (
    <>
      {isCopyTalkingPointModal && (
        <SelectModal
          singleChoice
          onModalClose={closeCopyTalkingPointModal}
          onSubmit={onCopyTalkingPoint}
          title={i18n._(t`Copy to next 1:1`)}
          buttonLabel={i18n._(t`Save`)}
          emptySetPlaceholder={i18n._(t`There are no next events`)}
          items={futureConversations}
          width={325}
          minHeight={450}
        />
      )}
    </>
  );
};

export default React.memo(CopyTalkingPointSection);
