import React from 'react';

import { ROLES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { InfoBlock, Info, Title } from './styledComponents';

import routes from '~/constants/routes';
import { getSelectedRole } from '~/selectors/baseGetters';
import { COLOR_SET } from '~/styles';
import { addQueryParam } from '~/utils/addQueryParam';

const LinkStyled = styled(Link)`
  text-decoration: none;
  font-weight: 600;
  color: ${COLOR_SET.MIDNICHT_BLUE};
  cursor: pointer;
`;

const LearningBlock = ({ skillId, activityCount, className }) => {
  const { i18n } = useLingui();
  const history = useHistory();
  const selectedRole = useSelector(getSelectedRole);

  const search = addQueryParam(history.location.search, { skill: skillId });
  const userLibraryLink = routes.ONBOARD_AND_LEARN.build(
    {},
    {
      query: { skillId },
      isBackPath: true,
      hash: 'all',
      backPathDefault: history.location.pathname + search + history.location.hash,
    },
  );

  return (
    <InfoBlock className={className}>
      <Title>
        <Trans>Learning</Trans>
      </Title>
      <Info>
        {activityCount > 0
          ? i18n._(t`${activityCount} recommended activities for this skill`)
          : i18n._(t`There are no recommended learning activities for this skill`)}
        {selectedRole === ROLES.USER && activityCount > 0 && (
          <LinkStyled to={userLibraryLink}>
            <Trans>View library</Trans>
          </LinkStyled>
        )}
      </Info>
    </InfoBlock>
  );
};

export { LearningBlock };
