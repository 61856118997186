import React from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Button from '~/components/Button';
import { Header } from '~/components/GiveFeedback/components/Section/components/ReviewGoalQuestion/Header';
import GoalDashboardCardModal from '~/components/GoalDashboardCardModal';
import Feedback from '~/components/NewReviewReport/components/Feedback';
import {
  FeedbackTitle,
  StyledButton,
} from '~/components/NewReviewReport/components/GoalEvalBlock/styledComponents';
import { TemplateCardWithFooter } from '~/components/SetupTools/components/TemplateBaseComponents';

import { GOAL_TYPES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';

const StyledTemplateCardWithFooter = styled(TemplateCardWithFooter)`
  margin-bottom: 8px;
`;

function GoalFeedback({
  question,
  goal,
  review,
  users,
  pdfView,
  viewerId,
  isLockedFeedback,
  handleOnRatingUpdated,
  isOpenDefault = false,
}) {
  const { i18n } = useLingui();
  const $isGoalInfoModal = useBoolState();
  const $isOpen = useBoolState(pdfView || isOpenDefault);

  const goalType =
    question.type === QUESTION_TYPES.GOAL_BUSINESS_EVAL
      ? GOAL_TYPES.BUSINESS.key
      : GOAL_TYPES.PERSONAL.key;

  return (
    <>
      <StyledTemplateCardWithFooter $noShadow $isOpen={$isOpen.value}>
        <Header $isOpen={$isOpen} goalType={goalType} pdfView={pdfView}>
          {goal.name}
        </Header>
        {$isOpen.value && (
          <>
            <FeedbackTitle>
              <Trans>Feedback</Trans>
              {!pdfView && (
                <StyledButton
                  type={Button.types.white}
                  label={i18n._(t`View goal`)}
                  onClick={$isGoalInfoModal.on}
                />
              )}
            </FeedbackTitle>
            <Feedback
              goal={goal}
              review={review}
              question={question}
              handleOnRatingUpdated={handleOnRatingUpdated}
              isLockedFeedback={isLockedFeedback}
              viewerId={viewerId}
              pdfView={pdfView}
              users={users}
            />
          </>
        )}
      </StyledTemplateCardWithFooter>

      {$isGoalInfoModal.value && (
        <GoalDashboardCardModal
          onClose={$isGoalInfoModal.off}
          goalId={goal.id}
          reviewId={review.id}
          viewerId={viewerId}
        />
      )}
    </>
  );
}

export { GoalFeedback };
