import React, { memo } from 'react';

import { t } from '@lingui/macro';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import IconMenu from '~/components/IconMenu';
import AvatarCard from '~/components/UI/AvatarCard';

import CommentInput from './CommentInput';

import useBoolState from '~/hooks/useBoolState';
import { getUser } from '~/selectors/baseGetters';
import { COLORS, COLOR_PALETTE } from '~/styles';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';
import sanitizeHtml from '~/utils/sanitize';

const Box = styled.div`
  margin-top: 8px;
`;

const CommentText = styled.div`
  p {
    margin: 0;
  }

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  box-sizing: border-box;
  width: 100%;
  word-break: break-word;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin: 4px 0;
`;

const Date = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const Layout = styled.div`
  display: flex;
  padding: 8px 12px;
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDER_HARD};
  box-sizing: border-box;
  background-color: ${COLORS.BG_PAGE};
`;

const LeftSide = styled.div`
  flex: 1;
`;
const Actions = styled.div`
  flex: 32px 0 0;
  padding: 4px 0 0 8px;
  box-sizing: border-box;
`;

const Comment = ({ talkingPointId, comment, updateComment, deleteComment, readOnly }) => {
  const currentUser = useSelector(getUser);
  const $editMode = useBoolState();
  const handleSave = (text) => {
    updateComment(talkingPointId, comment.id, { comment: text });
    $editMode.off();
  };
  const isReadOnly = comment.author !== currentUser.id || readOnly;

  const content = $editMode.value ? (
    <CommentInput comment={comment.comment} onSave={handleSave} />
  ) : (
    <Layout>
      <LeftSide>
        <AvatarCard userId={comment.author} type="medium" styles={{ padding: 0 }} />
        <CommentText dangerouslySetInnerHTML={{ __html: sanitizeHtml(comment.comment) }} />
        <Date>
          {convertToTimeString(get(comment, 'meta.createdDate'), TIME_FORMATS.CLASSIC_FULL)}
        </Date>
      </LeftSide>

      {!isReadOnly && (
        <Actions>
          <IconMenu
            items={[
              { label: t`Update`, action: $editMode.on },
              { label: t`Remove`, action: () => deleteComment(talkingPointId, comment.id) },
            ]}
          />
        </Actions>
      )}
    </Layout>
  );

  return <Box>{content}</Box>;
};

export default memo(Comment);
