import React, { PureComponent } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import SvgIcon from '~/components/SvgIcon';

import SearchSelect from './SearchSelect';

import filterIcon from '~/assets/filter.svg';

import { COLOR_PALETTE, COLORS } from '~/styles';

const IconWrap = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 6px;
  width: 100%;
`;

const FilterWrap = styled.div`
  position: absolute;
  top: 34px;
  right: ${(props) => (props.$position === 'left' ? '' : '0')};
  left: ${(props) => (props.$position === 'left' ? '0' : '')};
  width: 100%;
  z-index: 6;
  &:focus {
    outline: none;
  }
`;

const MainWrap = styled.div`
  position: relative;
  display: flex;
  background-color: ${(props) => (props.$disabled ? COLOR_PALETTE.GRAY_SOFT : COLOR_PALETTE.WHITE)};
  padding-left: 4px;
  padding-right: 16px;
  border: 1px solid #c7cfe0;
  border-radius: 6px;
  outline: none;
  min-width: ${(props) => props.$minWidth || '148px'};
  ${(props) => props.$width && { width: props.$width }};
  ${(props) => props.$height && { height: props.$height }};
`;

const Title = styled.div`
  margin-right: 8px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: ${(props) => props.companyColor};
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${(props) => props.$maxWidth && `${props.$maxWidth}px`};
`;

const ChevronIcon = styled(Icon)`
  margin-left: auto;
`;

class SearchSelectButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShowFilter: false,
    };
  }

  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    isRadio: PropTypes.bool,
    position: PropTypes.oneOf(['left', 'right']),
    checkedList: PropTypes.array,
    width: PropTypes.string,
    height: PropTypes.string,
    noHeader: PropTypes.bool,
  };

  static defaultProps = {
    value: '',
    isRadio: false,
    position: 'right',
    height: '30px',
    noHeader: false,
  };

  static currentOpenedList;

  static removeFromOpenedList(item) {
    if (this.currentOpenedList === item) {
      this.currentOpenedList = null;
    }
  }

  componentWillUnmount() {
    SearchSelectButton.removeFromOpenedList(this);
  }

  showFilter = () => {
    const { isShowFilter } = this.state;
    if (isShowFilter) {
      SearchSelectButton.removeFromOpenedList(this);
      this.setState({ isShowFilter: false });
    } else {
      if (SearchSelectButton.currentOpenedList) {
        SearchSelectButton.currentOpenedList.hideFilter();
      }
      SearchSelectButton.currentOpenedList = this;
      this.setState({ isShowFilter: true });
    }
  };

  hideFilter = () => {
    if (this.state.isShowFilter) {
      SearchSelectButton.removeFromOpenedList(this);
      this.setState({
        isShowFilter: false,
      });
    }
  };

  getMaxWidth = () => {
    const { width } = this.props;
    if (!width) {
      return null;
    }
    const numberOfWidth = Number(width.slice(0, -2));
    return numberOfWidth ? numberOfWidth - 25 : null;
  };

  getCheckedListLabel = () => {
    const { checkedList, options, i18n } = this.props;
    const firstCheckedItem = options.find(
      (o) => o && (o.id === checkedList[0] || o === checkedList[0]),
    );
    if (!firstCheckedItem) {
      return null;
    }
    let label;
    if (firstCheckedItem.label && typeof firstCheckedItem.label === 'function') {
      label = firstCheckedItem.label(i18n);
    } else if (firstCheckedItem.name && typeof firstCheckedItem.name === 'function') {
      label = firstCheckedItem.name(i18n);
    } else if (firstCheckedItem.label) {
      label = firstCheckedItem.label;
    } else if (firstCheckedItem.name) {
      label = firstCheckedItem.name;
    } else {
      label = firstCheckedItem.key;
    }
    return `${label} ${checkedList.length > 1 ? `+ ${checkedList.length - 1}` : ''}`;
  };

  render() {
    const {
      i18n,
      checkedList,
      options,
      addNewItem,
      handleChange,
      title,
      removeItem,
      updateItem,
      isRadio,
      position,
      width,
      minWidth,
      height,
      className,
      isScroll,
      isSearchAll,
      disabled,
    } = this.props;
    const { isShowFilter } = this.state;

    return (
      <MainWrap
        className={className}
        onBlur={this.hideFilter}
        $width={width}
        $minWidth={minWidth}
        $height={height}
        $disabled={disabled}
        tabIndex={options.length < 4 ? '1' : 'false'}
      >
        <IconWrap onClick={() => !disabled && this.showFilter()}>
          {!title && isEmpty(checkedList) && !disabled && (
            <SvgIcon
              width="16px"
              height="16px"
              isDefaultColor={isEmpty(checkedList)}
              defaultColor={COLORS.SUBTEXT}
              url={filterIcon}
            />
          )}
          {(title || !isEmpty(checkedList)) && (
            <>
              <Title
                $maxWidth={this.getMaxWidth()}
                companyColor={
                  isEmpty(checkedList) ? COLOR_PALETTE.DARK_GRAY : 'var(--company-color)'
                }
              >
                {!isEmpty(checkedList) && this.getCheckedListLabel()
                  ? this.getCheckedListLabel()
                  : title}
              </Title>
              {!disabled && (
                <ChevronIcon
                  icon={ICONS.DROPDOWN}
                  size={ICON_SIZES.SMALL}
                  color={isEmpty(checkedList) ? COLORS.SUBTEXT : COLORS.COMPANY}
                />
              )}
            </>
          )}
        </IconWrap>
        {isShowFilter && (
          <FilterWrap $position={position}>
            <SearchSelect
              type="main-board"
              placeholder={i18n._(t`Search...`)}
              options={options}
              addNewItem={addNewItem}
              removeItem={removeItem}
              updateItem={updateItem}
              checkedList={checkedList}
              onChange={handleChange}
              isRadio={isRadio}
              width={width}
              noHeader
              isScroll={isScroll}
              isSearchAll={isSearchAll}
            />
          </FilterWrap>
        )}
      </MainWrap>
    );
  }
}

export default withI18n()(SearchSelectButton);
