import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

const IconCircle = styled.div<{
  colors: string;
  isMirrored?: boolean;
  isActive?: boolean;
}>`
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isActive, colors }) =>
    isActive
      ? css`
          box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.08);
          background-image: linear-gradient(to bottom, ${colors});
        `
      : css`
          border: solid 1px ${COLORS.BORDERS};
        `}

  transform: ${({ isMirrored }) => (isMirrored ? 'scaleX(-1)' : '')};
`;

const IconWrap = styled.div`
  display: flex;
`;

export { IconCircle, IconWrap };
