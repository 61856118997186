import React from 'react';

import { Trans } from '@lingui/macro';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Modal from '~/components/Modal';
import RickTextView from '~/components/RickTextView';

import { Definition, Details, Divider, Header, Levels, Title, Wrapper } from './design';
import { FocusAreas } from './FocusAreas';
import { SkillCategory } from './SkillCategory';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import type { ISkill, ISkillCategory, WithReplace } from '@learned/types';

function SkillModal({
  onClose,
  skill,
}: {
  onClose: () => void;
  skill: WithReplace<ISkill, { skillCategory: ISkillCategory }>;
}) {
  const getMultiLangString = useMultiLangString();

  return (
    <Modal
      hideFooter
      isHideHeader
      contentStyles={{ padding: 0 }}
      ignoreModalStyle
      showDivider={false}
    >
      <Wrapper>
        <Details>
          <Header>
            <Title>{getMultiLangString(skill?.name || '')}</Title>
            <div>
              <SkillCategory>{getMultiLangString(skill?.skillCategory?.name || '')}</SkillCategory>
              <Button onClick={onClose} size={ButtonSize.MEDIUM} variant={ButtonVariant.CLOSE} />
            </div>
          </Header>
          <Definition>
            <h2>
              <Trans>Description</Trans>
            </h2>
            <RickTextView
              html={getMultiLangString(skill?.description || '')}
              color={COLORS.ICONS_PRIMARY}
              isHtmlWithoutStyles={undefined}
              className={undefined}
            />
          </Definition>
        </Details>
        <Divider />
        <Levels>
          <h2>
            <Trans>Levels</Trans>
          </h2>
          {skill?.skillCategory?.skillLevels?.map((skillLevel, index) => {
            const focusAreas = skill?.focusAreas?.find((area) => area.level === index);
            return focusAreas ? (
              <FocusAreas focusAreas={focusAreas} key={index} levelName={skillLevel} />
            ) : null;
          })}
        </Levels>
      </Wrapper>
    </Modal>
  );
}

export { SkillModal };
