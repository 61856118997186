import React from 'react';

import { Trans } from '@lingui/macro';

import { Button, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { getGradient } from '~/pages/ReviewGiveFeedback/utils';

import { RatingItem } from './design';

import { isNotNil } from '~/utils/typePredicates';

import type { IReviewRating } from '@learned/types';

function AverageRating({
  ratings,
  total,
  onEdit,
}: {
  ratings: IReviewRating[];
  total: number;
  onEdit?: () => void;
}) {
  // not null answers
  const validAnswers = ratings?.filter((item) => isNotNil(item.answer)) || [];
  const average =
    ratings.length > 0
      ? validAnswers.reduce((acc, rating) => acc + (rating.answer as number), 0) /
        validAnswers.length
      : 0;

  const displayAverage =
    ratings.length > 0
      ? average.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 })
      : ' ';

  const isNa =
    ratings.length > 0 &&
    ratings.filter((rating) => rating.answer === null).length === ratings.length;

  return (
    <RatingItem
      gradient={average && average >= 0 ? getGradient(average as number, total) : undefined}
    >
      {isNa ? <Trans>N/A</Trans> : displayAverage}
      {onEdit && (
        <Button variant={ButtonVariant.ICON} icon={ICONS.EDIT_PENCIL} onClick={() => onEdit?.()} />
      )}
    </RatingItem>
  );
}

export { AverageRating };
