import styled from 'styled-components';

import { StyledIconButton } from '~/components/Buttons/design';

import { COLORS } from '~/styles';

export const Label = styled.label`
  color: ${COLORS.TEXT_HOVER};
  padding-bottom: 7px;
  font-size: 14px;
  letter-spacing: -0.16px;
`;

export const Title = styled.span`
  font-size: 26px;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
`;

export const Subtitle = styled.span`
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${COLORS.SUBTEXT};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  border-bottom: solid 1px ${COLORS.BORDERS};
  padding-bottom: 7px;
  margin-bottom: 20px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLORS.HOVER};
  padding: 6px 12px;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.ICONS_PRIMARY};
  cursor: pointer;

  gap: 12px;

  ${StyledIconButton} {
    width: 14px;
    height: 14px;
    color: ${COLORS.INACTIVE};

    svg {
      width: 14px;
      height: 14px;
    }

    :hover {
      color: ${COLORS.ACCENT_ERROR};
    }
  }
`;

export const Tags = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
`;
