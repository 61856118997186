import { ROLES } from '~/constants';
import {
  pageCreate,
  pageOverview,
  pageDashboard,
  pageUpdate,
} from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'paths';

const defaultPathId = ':pathId';

// Paths overview page is in routes.ONBOARD_AND_LEARN

// dashboard
export const PATH = new LinkConstructor(
  [ROLES.USER, ROLES.COACH, ROLES.ADMIN],
  ({ pathId = defaultPathId }) => pageDashboard(routeName, { id: pathId }),
);

// overview
export const PATH_OVERVIEW = new LinkConstructor([ROLES.ADMIN, ROLES.COACH], () =>
  pageOverview(routeName),
);

// create
export const PATH_CREATE = new LinkConstructor([ROLES.ADMIN, ROLES.COACH, ROLES.USER], () =>
  pageCreate(routeName),
);

// update
export const PATH_UPDATE = new LinkConstructor(
  [ROLES.ADMIN, ROLES.COACH, ROLES.USER],
  ({ pathId = defaultPathId }) => pageUpdate(routeName, { id: pathId }),
);
