import React, { useState } from 'react';
import type { MouseEventHandler } from 'react';

import { Trans } from '@lingui/react';
import { Controller } from 'react-hook-form';
import styled, { css } from 'styled-components';

import { Icon, ICONS } from '~/components/Icon';
import OptionsIcon from '~/components/Icons/Options';
import Switch from '~/components/Switch';

import type { IQuestionForm } from '~/@types/question';
import { COLORS } from '~/styles';

import type { UseFormReturn } from 'react-hook-form';

interface IFooterProps {
  onDelete?: MouseEventHandler<HTMLButtonElement>;
  onDuplicate?: MouseEventHandler<HTMLButtonElement>;
  onEdit?: MouseEventHandler<HTMLButtonElement>;
  formMethods?: UseFormReturn<IQuestionForm>;
  isEditButtonVisible?: boolean;
  isSettingsVisible?: boolean;
  isLeftSideVisible?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  border-top: 1px solid ${COLORS.BORDER_HARD};

  & .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const Settings = styled.div`
  padding: 10px 0 16px;
  border-top: 1px solid ${COLORS.BORDER_HARD};
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Button = styled.button<{ hoverBackgroundColor?: string; hoverTextColor?: string }>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--company-color);
  cursor: pointer;
  &:hover:enabled {
    background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor || COLORS.BG_PAGE};
    ${({ hoverTextColor }) =>
      hoverTextColor &&
      css`
        color: ${hoverTextColor};
      `};
  }
  padding: 7px 10px 7px 13.1px;
  margin-bottom: -8px;
  border-radius: 10rem;
`;
/* padding: ${MAX_PADDING}; */
/* background-color: ${(props) => props.$hoverColor || COLORS.BG_PAGE}; */

const Title = styled.div`
  color: ${COLORS.BLACK};
  font-size: 14px;
`;
const SettingItem = styled.div<{ isActive?: boolean }>`
  display: flex;
  justify-content: space-between;

  color: ${({ isActive }) => (isActive ? 'var(--company-color)' : COLORS.INACTIVE_HARD)};
  font-size: 14px;

  & .rc-switch {
    margin: 0;
  }
`;

const Footer = ({
  isEditButtonVisible,
  isSettingsVisible = true,
  isLeftSideVisible = true,
  onEdit,
  onDelete,
  onDuplicate,
  formMethods,
}: IFooterProps) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  return (
    <>
      {isSettingsVisible && isSettingsOpen && formMethods && (
        <Settings>
          <Title>
            <Trans id="Settings" />
          </Title>
          <Controller
            name="settings.isCommentsAllowed"
            control={formMethods.control}
            render={({ field: { onChange, value } }) => (
              <SettingItem isActive={value}>
                <span className="item">
                  <Trans id="Allow comments" />
                </span>
                <Switch
                  onChange={(v: boolean) => {
                    if (!v) {
                      formMethods.setValue('settings.isCommentsObligated', false);
                    }
                    onChange(v);
                  }}
                  checked={value}
                />
              </SettingItem>
            )}
          />
          <Controller
            name="settings.isCommentsObligated"
            control={formMethods.control}
            render={({ field: { onChange, value } }) => (
              <SettingItem isActive={value}>
                <span className="item">
                  <Trans id="Comments are obligated" />
                </span>
                <Switch
                  disabled={!formMethods.watch('settings.isCommentsAllowed')}
                  onChange={onChange}
                  checked={value}
                />
              </SettingItem>
            )}
          />
          <Controller
            name="settings.isMeasurementReversed"
            control={formMethods.control}
            render={({ field: { onChange, value } }) => (
              <SettingItem isActive={value}>
                <span className="item">
                  <Trans id="Reverse answer measurement (low is positive, high is negative)" />
                </span>
                <Switch onChange={onChange} checked={value} />
              </SettingItem>
            )}
          />
        </Settings>
      )}
      {(isSettingsVisible || (isLeftSideVisible && (onDelete || onDuplicate))) && (
        <Wrapper>
          <div className="section">
            {isLeftSideVisible && (onDelete || onDuplicate) && (
              <>
                {onDuplicate && (
                  <Button type="button" onClick={onDuplicate}>
                    <Icon icon={ICONS.DUPLICATE} />
                    <span>
                      <Trans id="Duplicate" />
                    </span>
                  </Button>
                )}
                {onDelete && (
                  <Button
                    type="button"
                    onClick={onDelete}
                    hoverTextColor={COLORS.ERROR}
                    hoverBackgroundColor={COLORS.ERROR_HOVER}
                  >
                    <Icon icon={ICONS.DELETE_BIN} />
                    <span>
                      <Trans id="Delete" />
                    </span>
                  </Button>
                )}
              </>
            )}
          </div>
          <div className="section">
            {isSettingsVisible && formMethods && (
              <Button onClick={() => setIsSettingsOpen((prevState) => !prevState)} type="button">
                {isSettingsOpen ? (
                  <span>
                    <Trans id="Hide Settings" />
                  </span>
                ) : (
                  <span>
                    <Trans id="Show Settings" />
                  </span>
                )}
                <OptionsIcon size={18} />
              </Button>
            )}
            {isEditButtonVisible && (
              <Button type="button" onClick={onEdit}>
                <Icon icon={ICONS.EDIT_PENCIL} />
                <span>
                  <Trans id="Test" />
                </span>
              </Button>
            )}
          </div>
        </Wrapper>
      )}
    </>
  );
};

export { Footer };
